import { Button } from "@mui/material";
import React from "react";

export default function InquiresPagination({
  page,
  count,
  setPage,
  total,
}: any) {
  return (
    <div className="sticky bottom-0 right-0 items-center w-full shadow rounded-b-lg p-4 bg-secondary dark:bg-dark-secondary border-t border-[#F4F5FA] dark:border-[#28183D] sm:flex sm:justify-between  ">
      <div className="flex items-center mb-4 sm:mb-0">
        <button
          onClick={() => {
            setPage(page === 0 ? page : page - 1);
          }}
          className="inline-flex justify-center p-1  rounded cursor-pointer text-primary dark:text-dark-primary hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            className="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          onClick={() => {
            setPage(page < total / 20 - 1 ? page + 1 : page);
          }}
          className="inline-flex justify-center p-1 mr-2 text-primary dark:text-dark-primary rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            className="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <span className="text-sm font-normal text-primary dark:text-dark-primary">
          Showing{" "}
          <span className="font-semibold text-basic  ">
            {page + 1}-{count}
          </span>{" "}
          of <span className="font-semibold text-basic ">{total}</span>
        </span>
      </div>
      <div className="flex items-center space-x-3">
        <Button
          disabled={page === 0 ? true : false}
          onClick={() => {
            setPage(page === 0 ? page : page - 1);
          }}
          variant="contained"
        >
          <svg
            className="w-5 h-5 mr-1 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
          Previous
        </Button>
        <Button
          onClick={() => {
            setPage(page < total / 20 - 1 ? page + 1 : page);
          }}
          disabled={page < total / 20 - 1 ? false : true}
          variant="contained"
        >
          Next
          <svg
            className="w-5 h-5 ml-1 -mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </Button>
      </div>
    </div>
  );
}
