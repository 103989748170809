import { gql } from "@apollo/client";

export const INSERT_SERVICE_TIP = gql`
  mutation insertServicesTip(
    $title: String!
    $description: String!
    $brd_service_id: uuid!
  ) {
    insert_brd_services_tips(
      objects: {
        title: $title
        description: $description
        brd_service_id: $brd_service_id
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SERVICE_TIP = gql`
  mutation MyMutation2($id: uuid!, $title: String!, $description: String!) {
    update_brd_services_tips(
      where: { id: { _eq: $id } }
      _set: { title: $title, description: $description }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_SERVICE_TIP = gql`
  mutation MyMutation($id: uuid!) {
    delete_brd_services_tips(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
