import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { DELETE_CAMPUS_COURSE_ADM_REQ_WITH_ID } from "~/graphql/course_adm_req/Mutation";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditAdmissionRequirement from "../EditAdmissionRequirement";
import { FaTrash, FaEdit } from "react-icons/fa";
import ConfirmPopup from "../ConfirmPopup";

const AdmissionRequirementData = ({ campusCoursesData, courseType }: any) => {

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRequirement, setSelectedRequirement] = useState<any>(null);
  const client = useApolloClient();
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [reqToDelete, setReqToDelete] = useState(null);

  const [deleteAdmissionRequirement] = useMutation(
    DELETE_CAMPUS_COURSE_ADM_REQ_WITH_ID
  );

  const handleDeleteAdmissionRequirements = async (req_id: any) => {
    setConfirmPopup(true)
    setReqToDelete(req_id)

  };

  const confirmReqDelete = async () => {
    if (reqToDelete) {
      const res = await deleteAdmissionRequirement({
        variables: {
          reqId: reqToDelete,
        },
      });
      if (res?.data?.delete_course_adm_req?.affected_rows > 0) {
        toast.success("Admission Requirement Deleted Successfully!");
        client.refetchQueries({
          include: "all",
        });
      }
      setConfirmPopup(false);
      setReqToDelete(null);
    }
  };

  const handleEditUniversityCourse = (requirement: any) => {
    setSelectedRequirement(requirement);
    setOpenPopup(true);
  };
  return (
    <div>
      <h1 className="text-xl font-semibold mb-4 dark:text-dark-primary">{campusCoursesData?.uni_campus_courses?.[0]?.course_detail?.name}</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Qualification</TableCell>
              <TableCell>Grade Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Gap Allowed</TableCell>
              <TableCell>Scholarship Available</TableCell>
              <TableCell>ELT</TableCell>
              {campusCoursesData?.uni_campus_courses?.[0]?.course_detail?.course_type === "PG" &&
                <TableCell>MOI</TableCell>
              }
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campusCoursesData?.uni_campus_courses?.[0]?.adm_req?.map((requirement: any) => (
              <TableRow key={requirement.id}>
                <TableCell>{requirement.qualification}</TableCell>
                <TableCell>{requirement.grade_type}</TableCell>
                <TableCell>{requirement.grades}</TableCell>
                <TableCell>{requirement.gap_allowed}</TableCell>
                <TableCell>{requirement.scholarship_available}</TableCell>
                <TableCell>{requirement.elt}</TableCell>
                {campusCoursesData?.uni_campus_courses?.[0]?.course_detail?.course_type === "PG" &&
                  <TableCell className="capitalize">{requirement.moi}</TableCell>
                }
                <TableCell>
                  <div className="flex justify-end gap-5">
                    <FaEdit
                      onClick={() => {
                        handleEditUniversityCourse(requirement);
                      }}
                      className="cursor-pointer"
                      size={18}
                    />
                    <FaTrash
                      onClick={() => {
                        handleDeleteAdmissionRequirements(requirement.id);
                      }}
                      className="cursor-pointer"
                      size={18}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditAdmissionRequirement
              selectedRequirement={selectedRequirement}
              courseType={courseType}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
      {confirmPopup && (
        <ConfirmPopup confirmPopup={confirmPopup} setConfirmPopup={setConfirmPopup} onConfirm={confirmReqDelete} />
      )}
    </div>
  );
};

export default AdmissionRequirementData;
