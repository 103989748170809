// Insert New notifications

import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ALL_USERS, GET_ROLE_USERS } from "./graphql/Query";
import { useApolloClient } from "@apollo/client";

// get all users id
const getUserId = async (data: any, client: any) => {
  try {
    if (data?.role) {
      const response = await client.query({
        query: GET_ROLE_USERS,
        variables: data,
      });
      return response.data.brd_users;
    } else {
      const response = await client.query({
        query: GET_ALL_USERS,
        variables: data,
      });
      return response.data.brd_users;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

//Make notification data
const makeNotificationData = async (
  user_id: any,
  brand_id: any,
  client: any
) => {
  let users: any = [];
  if (user_id?.length === 36) {
    users.push(user_id);
  } else {
    let req: any = { th_id: brand_id };
    if (user_id !== "all") {
      req = { th_id: brand_id, role: user_id };
    }
    const res = await getUserId(req, client);
    res.map((user: any) => {
      users.push(user.user_id);
    });
  }
  return users;
};

const useNotifications = () => {
  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  // Define All notification function inside notification object
  const notifications = {
    // New inquery function
    newNotification: async (
      user_id: any,
      headline: any,
      description: any,
      url: any
    ) => {
      const users = await makeNotificationData(user_id, travelHouse.id, client);
    },
  };
  return notifications;
};

export default useNotifications;
