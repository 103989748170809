import { gql } from "@apollo/client";

export const GET_SALARY = gql`
  query GetSalary($user_id: uuid!, $month: Int, $year: Int) {
    acc_transactions(
      where: {
        user_id: { _eq: $user_id }
        month: { _eq: $month }
        year: { _eq: $year }
        type: { _eq: "debit" }
      }
    ) {
      transactions_no
      amount
    }
  }
`;
