import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Typography,
  FormLabel,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface Room {
  adults: number;
  children: number;
}

const RoomForm: React.FC<{
  value: Room[];
  onChange: (newValue: Room[]) => void;
  errors: any;
  setValue?: any;
  fieldName?: string;
  label?: string;
}> = ({
  value = [{ adults: 1, children: 0 }],
  onChange,
  errors,
  setValue,
  fieldName,
  label,
}) => {
  const [rooms, setRooms] = useState<Room[]>(value); // Sync with react-hook-form

  // Sync internal state if the parent form value changes
  useEffect(() => {
    setRooms(value);
  }, [value]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const id = "room-popover";
  const popoverWidth = "300px";

  const handleRoomClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleRoomClose = () => setOpen(false);

  const handleAddRoom = () => {
    const newRooms = [...rooms, { adults: 1, children: 0 }];
    setRooms(newRooms);
    onChange(newRooms);
  };

  const handleRemoveRoom = (index: number) => {
    const newRooms = rooms.filter((_, i) => i !== index);
    setRooms(newRooms);
    onChange(newRooms);
  };

  const handleRoomChange = (
    index: number,
    type: "adults" | "children",
    value: number
  ) => {
    const newRooms = rooms.map((room, i) =>
      i === index ? { ...room, [type]: value } : room
    );
    setRooms(newRooms);
    onChange(newRooms);
  };

  return (
    <div className="w-full">
      <TextField
        aria-describedby={id}
        label={label || "Travelers"}
        value={`Rooms: ${rooms.length}`}
        onClick={handleRoomClick}
        aria-readonly
        fullWidth
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleRoomClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { width: popoverWidth } }}
      >
        <Box className="p-4 max-w-md w-full md:max-w-xl overflow-auto max-h-96">
          {rooms.map((room, index) => (
            <Box key={index} className="mb-4 p-4 rounded-lg mx-w-md w-full">
              <FormLabel sx={{ fontWeight: "bold" }}>{`Room ${
                index + 1
              }`}</FormLabel>

              <Box className="flex gap-4 mt-2 justify-between">
                <Typography>Adults:</Typography>
                <div className="flex items-center justify-center gap-2">
                  <IconButton
                    onClick={() =>
                      handleRoomChange(
                        index,
                        "adults",
                        Math.max(1, rooms[index].adults - 1)
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>

                  <span className="text-center w-12 text-lg font-medium">
                    {rooms[index].adults}
                  </span>

                  <IconButton
                    onClick={() =>
                      handleRoomChange(index, "adults", rooms[index].adults + 1)
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Box>

              <Box className="flex gap-4 mt-2 justify-between">
                <Typography>Children:</Typography>
                <div className="flex items-center justify-center gap-2">
                  <IconButton
                    onClick={() =>
                      handleRoomChange(
                        index,
                        "children",
                        Math.max(0, rooms[index].children - 1)
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>

                  <span className="text-center w-12 text-lg font-medium">
                    {rooms[index].children}
                  </span>

                  <IconButton
                    onClick={() =>
                      handleRoomChange(
                        index,
                        "children",
                        rooms[index].children + 1
                      )
                    }
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "9999px",
                      padding: "4px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Box>

              {rooms.length > 1 && (
                <div className="flex w-full justify-end mt-2">
                  <Button onClick={() => handleRemoveRoom(index)}>
                    Remove Room
                  </Button>
                </div>
              )}
            </Box>
          ))}
          <Button onClick={handleAddRoom}>Add Room</Button>
        </Box>
      </Popover>
    </div>
  );
};

export default RoomForm;
