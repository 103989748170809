import WhatsAppLogIn from "./components/WhatsAppLogin";
import TwitterLogin from "./components/TwitterLogin";
import LinkedInUserLogIn from "./components/LinkedInUserLogIn";
import LinkedInOrganizationLogIn from "./components/LinkedInOrganizationLogin";
import FacebookPageLogin from "../../Facebook/FacebookPageLogin";
import TikTokProfileLogin from "../../TikTok";
import InstagramLogIn from "../../Instagram/InstagramLogin";

const SocialPopup = () => {
  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
        Connect Social Accounts
      </h1>
      <div className="flex flex-col gap-2">
        <WhatsAppLogIn />
        <FacebookPageLogin />
        <InstagramLogIn />
        <TikTokProfileLogin />
        <TwitterLogin />
        <LinkedInUserLogIn />
        <LinkedInOrganizationLogIn />
      </div>
    </div>
  );
};

export default SocialPopup;
