import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CustomList from "../CustomList";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const ServicesSideBar = ({ role, activeDropdown, setActiveDropdown }: any) => {
  return (
    <>
      {role === "admin" ? (
        <div className="text-primary mt-[70px] z-30">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Leads",
                icon: <TouchAppIcon />,
                link: "/admin/service/leads",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Customers",
                icon: <GroupOutlinedIcon />,
                link: "/admin/customers",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Vendors",
                icon: <GroupOutlinedIcon />,
                link: "/admin/vendors",
              },
            ]}
          />
        </div>
      ) : role === "consultant" ? (
        <div className="">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Customers",
                icon: <GroupOutlinedIcon />,
                link: "/consultant/customers",
              },
            ]}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ServicesSideBar;
