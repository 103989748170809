import { useWavesurfer } from "@wavesurfer/react";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";

// import { RecordPlugin } from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  const options: any = {
    audioBitsPerSecond: 128000,
    mimeType: "audio/webm",
  };
  return {
    worker: new MediaRecorder(stream, options),
    master: stream,
  };
}

export const useRecorder = () => {
  let [stream, setStream] = useState<any>(null);
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState<any>(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [fileBlob, setFile] = useState("");

  const waveSurferRef = useRef<any>(null);

  // const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
  //   container: waveSurferRef.current,
  //   waveColor: "darkgray",
  //   progressColor: "darkgray",
  //   height: 40,
  // });
  // wavesurfer.microphone = master;
  // wavesurfer.startRecording();

  let wavesurfer, record;
  let scrollingWaveform = false;
  useEffect(() => {
    // First time we are recording
    if (recorder === null) {
      if (recording) {
        requestRecorder().then(({ worker, master }: any) => {
          setRecorder(worker);
          setStream(master);
          // const wavesurfer: any = WaveSurfer.create({
          //   container: waveSurferRef.current,
          //   waveColor: "violet",
          //   progressColor: "purple",
          //   cursorWidth: 1,
          //   cursorColor: "#000",
          //   barWidth: 2,
          //   height: 100,
          //   barRadius: 3,
          //   plugins: [
          //     RecordPlugin.create({
          //       microphone: worker,
          //       scrollingWaveform,
          //       renderRecordedAudio: false,
          //     }),
          //   ],
          // });
          // record = wavesurfer.registerPlugin(
          //   RecordPlugin.create({
          //     scrollingWaveform,
          //     renderRecordedAudio: false,
          //   })
          // );
          // wavesurfer.microphone = worker;
          // wavesurfer.startRecording();
          // wavesurfer.startRecording();
        }, console.error);
      }
      return () => {
        if (waveSurferRef.current) {
          // waveSurferRef.current.destroy();
        }
      };
    }

    // Handle recording state
    if (recording) recorder.start();
    else recorder.stop();

    // Get audio when ready
    const handleData = (e: any) => {
      //   e.data
      setAudioUrl(URL.createObjectURL(e.data));
      setRecorder(null);

      const audioData = e.data;

      setFile(audioData);
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => {
      recorder.removeEventListener("dataavailable", handleData);
    };
  }, [recording, recorder]);

  function startRecording() {
    setRecording(true);
  }

  function stopRecording() {
    if (stream) {
      setRecording(false);
      stream.getAudioTracks().forEach((track: any) => {
        track.stop();
      });
    } else {
    }
  }

  return {
    audioUrl,
    recording,
    fileBlob,
    startRecording,
    stopRecording,
    waveSurferRef,
  };
};
