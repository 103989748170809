import { useMutation, useQuery } from "@apollo/client";
import { Divider, Box, TextField, Autocomplete, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AirlineBox from "~/components/FormInputs/AirlineBox/AirlineBox";
import LocationBox from "~/components/FormInputs/LocationBox/LocationBox";
import useBrdSeasons from "~/hooks/useBrdSeasons";
import { UPDATE_BRD_SEAS_FARES } from "~/graphql/brd_seasonal_fares/Mutation";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GET_BRD_SEASONAL_FARES_BY_ID } from "~/graphql/brd_seasonal_fares/Query";
import { useEffect, useState } from "react";

const UpdateSeasFaresTable = ({ setOpenMod, refetch, id }: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { brd_id } = useParams();

  const { brdSeasons } = useBrdSeasons(brd_id);

  const [updateDefSeasFares] = useMutation(UPDATE_BRD_SEAS_FARES);

  const { data: season, loading } = useQuery(GET_BRD_SEASONAL_FARES_BY_ID, {
    variables: { id },
  });

  const [seasValue, setSeasValue] = useState(null);

  useEffect(() => {
    const seasFunction = () => {
      const data = brdSeasons?.find(
        (option: any) =>
          option?.int_id === season?.brd_seasonal_fares?.[0]?.brd_season_id
      );
      return data;
    };

    const seasDefValue = seasFunction();

    const seasIndex: any = seasDefValue
      ? brdSeasons?.indexOf(seasDefValue)
      : null;

    setSeasValue(brdSeasons?.[seasIndex]);
  }, [brdSeasons, season]);

  const typeOptions = [
    { value: "oneWay", name: "One Way" },
    { value: "roundTrip", name: "Round Trip" },
  ];

  const [typeValue, setTypeValue] = useState<any>(null);

  useEffect(() => {
    const seasFunction = () => {
      const data = typeOptions?.find(
        (option: any) => option?.value === season?.brd_seasonal_fares?.[0]?.type
      );
      return data;
    };

    const typeDefValue = seasFunction();

    const typeIndex: any = typeDefValue
      ? typeOptions?.indexOf(typeDefValue)
      : null;

    setTypeValue(typeOptions?.[typeIndex]);

    setValue("season", season?.brd_seasonal_fares?.[0]?.brd_season_id);
    setValue("type", season?.brd_seasonal_fares?.[0]?.type);
    setValue("destination", season?.brd_seasonal_fares?.[0]?.destination);
    setValue("departure", season?.brd_seasonal_fares?.[0]?.departure);
    setValue("airline", season?.brd_seasonal_fares?.[0]?.airline);
  }, [season]);

  const onSubmit = async (formData: any) => {
    const payload = {
      brd_season_id: formData?.season,
      destination: formData?.destination,
      departure: formData?.departure,
      fares: formData?.fares,
      airline: formData?.airline,
      type: formData?.type,
      stops: formData?.stops,
      brd_id,
    };

    // console.log("jshfjsdf", formData);

    const res = await updateDefSeasFares({
      variables: {
        data: payload,
        id,
      },
    });

    if (res?.data?.update_brd_seasonal_fares?.affected_rows) {
      toast.success("Seasonal Fares Updated SuccessFully");
      setOpenMod(false);
      refetch();
    }
  };

  if (loading || !seasValue) return <div>Loading...</div>;

  return (
    <div className="">
      <h1 className="text-center text-3xl text-basic">Update Seasonal Fares</h1>
      <Divider />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <div className="col-span-2">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("season", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.int_id);
                    setSeasValue(data);
                  }}
                  value={seasValue}
                  options={brdSeasons ? brdSeasons : []}
                  getOptionLabel={(option: any) => option.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Season"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["season"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("type", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                value={typeValue}
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.value);
                  setTypeValue(data);
                }}
                options={typeOptions}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Type"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["type"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <LocationBox
            control={control}
            register={register}
            errors={errors}
            fieldName="destination"
            label="Destination"
            defaultValue={season?.brd_seasonal_fares?.[0]?.destination}
          />
          <LocationBox
            control={control}
            register={register}
            errors={errors}
            fieldName="departure"
            label="Departure"
            defaultValue={season?.brd_seasonal_fares?.[0]?.departure}
          />
          <AirlineBox
            control={control}
            register={register}
            errors={errors}
            fieldName="airline"
            label="Airline"
            defaultValue={season?.brd_seasonal_fares?.[0]?.airline}
          />
          <TextField
            error={errors["fares"] ? true : false}
            {...register("fares", { required: true })}
            label="Fares"
            type="number"
            defaultValue={season?.brd_seasonal_fares?.[0]?.fares}
          />
          <TextField
            error={errors["stops"] ? true : false}
            {...register("stops", { required: true })}
            label="Stops"
            type="number"
            defaultValue={season?.brd_seasonal_fares?.[0]?.stops}
          />
        </div>
        <div className="flex items-center justify-center mt-10">
          <Button type="submit" variant="contained">
            Update Seaonal Fares
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default UpdateSeasFaresTable;
