import { useSubscription } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";
import { useState } from "react";
import { GET_CONVERSATION_DETAIL } from "../../../../graphql/Query";
import { GET_EMAIL_CAMP_CONVERSATION } from "~/graphql/brd_email_campaigns/Query";
import DetailRow from "./component/DetailRow";

const UserInfoBar = ({
  showUserInfo,
  setShowUserInfo,
  conversation_id,
}: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const {
    loading: cl,
    data: cd,
    error: ce,
  } = useSubscription(GET_CONVERSATION_DETAIL, {
    variables: { id: conversation_id },
  });

  const {
    loading: ecl,
    data: ecd,
    error: ece,
  } = useSubscription(GET_EMAIL_CAMP_CONVERSATION, {
    variables: {
      condition: {
        id: { _eq: conversation_id },
      },
    },
  });

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div
      className={`flex flex-col h-full transition-all ease-in-out duration-300 ${
        showUserInfo ? "w-full" : "w-0"
      }`}
    >
      {showUserInfo && (
        <>
          <div className="flex items-center text-black dark:text-white px-[25px] text-[16px] font-medium py-[15px]">
            <CloseIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowUserInfo(false);
              }}
            />
            <p className="ml-5">Contact info</p>
          </div>
          <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary p-[30px] flex flex-col items-center justify-center">
            <Avatar
              sx={{ width: "150px", height: "150px", marginBottom: "10px" }}
              alt="whatsappAvator"
              src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
            />
            <p className="text-[25px] text-black dark:text-white mt-1">
              {cd?.brd_gmail_conversation?.[0]?.user_name ||
                ecd?.brd_email_campaigns?.[0]?.user_name}
            </p>
          </div>
          <h1 className="p-3 text-lg font-bold">
            {cd?.brd_gmail_conversation?.[0]?.user_email
              ? "Email:"
              : "Members:"}
          </h1>
          <div className="bg-whatsappSecondaryBg flex-grow overflow-scroll">
            {cd?.brd_gmail_conversation?.[0]?.user_email && (
              <DetailRow name={cd?.brd_gmail_conversation?.[0]?.user_email} />
            )}
            {ecd?.brd_email_campaigns?.[0]?.user_email?.length > 0 &&
              ecd?.brd_email_campaigns?.[0]?.user_email?.map(
                (item: any, ind: any) => <DetailRow key={ind} name={item} />
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserInfoBar;
