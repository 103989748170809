import { gql } from "@apollo/client";

export const GET_ALL_INQUIRIES = gql`
  subscription GetInquiries($condition: inq_list_bool_exp!) {
    inq_list(where: $condition) {
      brd_id
      status
      created_at
      from
      to
      source
      source_place
      source_position
      source_add_id
      users {
        email
        displayName
        phoneNumber
        id
      }
      thp_inquiry_flag {
        name
        id
      }
      picked_by
      picked_user {
        displayName
        thp_users_profile {
          pseudo_name
        }
        id
      }
    }
  }
`;

export const GET_ALL_INQUIRIES_QUERY = gql`
  query GetInquiries($condition: inq_list_bool_exp!) {
    inq_list(where: $condition) {
      brd_id
      status
      created_at
      from
      to
      source
      source_place
      source_position
      source_add_id
      users {
        email
        displayName
        phoneNumber
        id
      }
      thp_inquiry_flag {
        name
        id
      }
      picked_by
      picked_user {
        displayName
        thp_users_profile {
          pseudo_name
        }
        id
      }
    }
  }
`;
