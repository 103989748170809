import React from "react";
import Layout from "~/components/common/Layout";
import WhatsappModule from "~/modules/WhatsappModule";
import WhatsappWindow from "~/modules/WhatsappModule/segments/WhatsappWindow";

export default function WhatsappPageCommunity() {
  return (
    <div>
      <Layout>
        <WhatsappModule>
          <WhatsappWindow community={true} />
        </WhatsappModule>
      </Layout>
    </div>
  );
}
