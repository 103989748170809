import { useApolloClient, useMutation } from "@apollo/client";
import { Button, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { INSERT_CAMPUS_COURSE } from "~/graphql/brd_uni_campus_courses/Mutation";
import { GET_UNIVERSITY_COURSES } from "~/graphql/brd_uni_courses/Query";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { DELETE_CAMPUS_COURSE_WITH_ID } from "~/graphql/brd_uni_campus_courses/Mutation";

const AddCampusCourse = ({ campusId, uniId, currentCourses, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const client = useApolloClient();
  const [insertCampusCourse] = useMutation(INSERT_CAMPUS_COURSE);
  const [deleteCampusCourse] = useMutation(DELETE_CAMPUS_COURSE_WITH_ID);
  const [selectedCoursesIds, setSelectedCoursesIds] = useState<any>([]);

  const { data: brdUniCourses, loading: coursesLoading } = useQuery(GET_UNIVERSITY_COURSES, {
    variables: {
      universityId: uniId,
    },
  });

  useEffect(() => {
    const currentCourseIds = currentCourses?.brd_university_campuses?.[0]?.campus_courses?.map((item: any) => item.course_id) || [];
    setSelectedCoursesIds(currentCourseIds);
  }, [currentCourses]);

  if (coursesLoading) {
    return (
      <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">Add Campus Courses</h1>
        <p>Loading Courses...</p>
      </div>
    )
  }

  const handleCourseChange = (course_id: any) => {
    setSelectedCoursesIds((prevSelected: any) =>
      prevSelected.includes(course_id)
        ? prevSelected.filter((id: any) => id !== course_id)
        : [...prevSelected, course_id]
    );
  };

  const onSubmit = async () => {
    const currentCourseIds = currentCourses?.brd_university_campuses?.[0]?.campus_courses?.map((item: any) => item.course_id) || [];

    try {
      // Handle course additions
      const coursesToAdd = selectedCoursesIds.filter((id: any) => !currentCourseIds.includes(id));
      await Promise.all(
        coursesToAdd.map(async (course_id: string) => {
          return insertCampusCourse({
            variables: { campusId, courseId: course_id },
          });
        })
      );

      // Handle course removals
      const coursesToRemove = currentCourseIds.filter((id: any) => !selectedCoursesIds.includes(id));
      await Promise.all(
        coursesToRemove.map(async (course_id: string) => {
          const campusCourse = currentCourses.brd_university_campuses[0].campus_courses.find(
            (course: any) => course.course_id === course_id
          );
          return deleteCampusCourse({
            variables: { courseId: campusCourse.id }, // Use the ID of the course to delete
          });
        })
      );

      // Refetch queries after successful operations
      client.refetchQueries({
        include: "all",
      });

      toast.success("Changes applied successfully!");
      setSidePopup(false);
    } catch (error) {
      toast.error("Failed to manage courses.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">Add Campus Courses</h1>
      {brdUniCourses?.brd_universities?.[0]?.brd_uni_courses?.length > 0 ? (
        <h2 className="text-lg dark:text-dark-basic mb-2">Available Courses</h2>
      ) : (
        <h2 className="text-lg dark:text-dark-basic mb-2">First, add courses to university.</h2>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {brdUniCourses?.brd_universities?.[0]?.brd_uni_courses?.length > 0 && (
          <>
            <FormGroup>
              {brdUniCourses.brd_universities[0].brd_uni_courses.map((course: any) => (
                <FormControlLabel
                  key={course.id}
                  control={
                    <Checkbox
                      checked={selectedCoursesIds.includes(course.id)}
                      onChange={() => handleCourseChange(course.id)}
                    />
                  }
                  label={`${course.name}, Type - ${course.course_type}, Intake - ${course.intake}`}
                />
              ))}
            </FormGroup>
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: "20px" }}>
              Apply
            </Button>
          </>
        )}
      </form>
    </div>
  );
};

export default AddCampusCourse;
