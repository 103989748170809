import Layout from "~/components/common/Layout";
import ServicesModule from "~/modules/ServicesModule";
import PageHeadings from "~/modules/ServicesModule/segments/HeadingSettings";

const PageHeadingsPage = () => {
  return (
    <div>
      <Layout>
        <ServicesModule>
          <PageHeadings />
        </ServicesModule>
      </Layout>
    </div>
  );
};

export default PageHeadingsPage;
