import { gql } from "@apollo/client";

export const GET_BRD_PARTNERS = gql`
  query MyQuery($brdId: uuid = "") {
    brd_partners(where: { brd_id: { _eq: $brdId } }) {
      name
      logo_id
      id
      brd_id
    }
  }
`;
