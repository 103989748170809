import { gql } from "@apollo/client";

export const ADD_PAYMENT_METHOD = gql`
  mutation AddPaymentMethod(
    $paymentMethod: [acc_payment_methods_insert_input!]!
  ) {
    insert_acc_payment_methods(objects: $paymentMethod) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_PAYMENT_METHOD = gql`
  mutation EditPaymentMethod(
    $paymentMethod: acc_payment_methods_set_input!
    $id: uuid!
  ) {
    update_acc_payment_methods_by_pk(
      pk_columns: { id: $id }
      _set: $paymentMethod
    ) {
      id
    }
  }
`;
