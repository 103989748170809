import { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField
} from "@mui/material";
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";

import useBrdUniversitiesData from "~/hooks/useBrdUniversitiesData";
import { DELETE_UNIVERSITY_WITH_ID } from "~/graphql/brd_universities/Mutation";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditUniversity from "../EditUniversity";
import { useNavigate } from "react-router-dom";
import { GET_BRAND_UNIVERSITIES_WITH_NAME } from "~/graphql/brd_universities/Query";
import ConfirmPopup from "../ConfirmPopup";

const UniversitiesData = ({ brdId }: any) => {

  const { brdUniversitiesData } = useBrdUniversitiesData(brdId);
  const [openPopup, setOpenPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState<any>(null);
  const [deleteUniversity] = useMutation(DELETE_UNIVERSITY_WITH_ID);
  const [searchUniversities, { data: searchResults }] = useLazyQuery(GET_BRAND_UNIVERSITIES_WITH_NAME);
  const client = useApolloClient();
  const navigate = useNavigate();
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [universityToDelete, setUniversityToDelete] = useState(null);

  const handleDeleteUniversity = async (uni_id: any) => {
    setUniversityToDelete(uni_id);
    setConfirmPopup(true)
  };

  const handleEditUniversity = (uni: any) => {
    setSelectedUniversity(uni);
    setOpenPopup(true);
  };

  const handleViewCourses = (uni_id: any) => {
    navigate(`/admin/brands/universities/${uni_id}/courses`)
  };

  const handleViewCampuses = (uni_id: any) => {
    navigate(`/admin/brands/universities/${uni_id}/campuses`)
  };

  const confirmDelete = async () => {
    if (universityToDelete) {
      const res = await deleteUniversity({
        variables: {
          uniId: universityToDelete,
        },
      });
      if (res?.data?.delete_brd_universities?.affected_rows > 0) {
        toast.success("University Deleted Successfully!");
        client.refetchQueries({
          include: "all",
        });
      }
      setConfirmPopup(false);
      setUniversityToDelete(null);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      searchUniversities({
        variables: {
          name: `%${searchTerm}%`,
          brdId: brdId,
        }
      });
    }
  }, [searchTerm, brdId]);

  const displayedUniversities = searchTerm ? (searchResults?.brd_universities || []) : (brdUniversitiesData?.brd_universities || []);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold dark:text-dark-primary">Universities and Campuses</h1>
        <TextField
          id="outlined-basic"
          label="Search University"
          variant="outlined"
          name="university_name"
          value={searchTerm}
          className="w-[25%]"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Universities</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedUniversities?.map((uni: any) => (
              <TableRow key={uni.id}>
                <TableCell>{uni.name}</TableCell>
                <TableCell>
                  <div className="flex items-center justify-between">
                    <div className="flex gap-3">
                      <Button
                        color="success"
                        onClick={() => handleViewCampuses(uni.id)}
                      >
                        Campuses
                      </Button>
                      <Button
                        color="info"
                        onClick={() => handleViewCourses(uni.id)}
                      >
                        Courses
                      </Button>
                    </div>
                    <div className="flex gap-5">
                      <FaEdit
                        onClick={() => {
                          handleEditUniversity(uni);
                        }}
                        color="primary"
                        size={18}
                        className="cursor-pointer"
                      />
                      <FaTrash
                        onClick={() => {
                          handleDeleteUniversity(uni.id);
                        }}
                        size={18}
                        color="error"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditUniversity
              selectedUniversity={selectedUniversity}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
      {confirmPopup && (
        <ConfirmPopup confirmPopup={confirmPopup} setConfirmPopup={setConfirmPopup} onConfirm={confirmDelete} />
      )}
    </div>
  );
};

export default UniversitiesData;
