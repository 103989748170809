import { gql } from "@apollo/client";

export const GET_BRD_SERVICES_TIPS_WITH_SERVICE_ID = gql`
  query GetServiceTips($brd_service_id: uuid!) {
    brd_services_tips(where: { brd_service_id: { _eq: $brd_service_id } }) {
      title
      description
      id
      brd_service_id
    }
  }
`;
