import React from "react";

export default function TickBox() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.73572 1.22222C1.58227 1.22222 1.43511 1.28661 1.32661 1.40121C1.2181 1.51582 1.15715 1.67126 1.15715 1.83333V9.16667C1.15715 9.32874 1.2181 9.48418 1.32661 9.59879C1.43511 9.71339 1.58227 9.77778 1.73572 9.77778H8.6786C8.83205 9.77778 8.97921 9.71339 9.08771 9.59879C9.19621 9.48418 9.25717 9.32874 9.25717 9.16667V5.5C9.25717 5.33792 9.31813 5.18248 9.42663 5.06788C9.53513 4.95327 9.6823 4.88889 9.83574 4.88889C9.98919 4.88889 10.1364 4.95327 10.2449 5.06788C10.3534 5.18248 10.4143 5.33792 10.4143 5.5V9.16667C10.4143 9.6529 10.2314 10.1192 9.90594 10.463C9.58043 10.8068 9.13894 11 8.6786 11H1.73572C1.27538 11 0.833891 10.8068 0.508381 10.463C0.18287 10.1192 0 9.6529 0 9.16667V1.83333C0 1.3471 0.18287 0.880788 0.508381 0.536971C0.833891 0.193154 1.27538 0 1.73572 0H7.52145C7.6749 0 7.82206 0.0643847 7.93056 0.17899C8.03907 0.293596 8.10003 0.449034 8.10003 0.611111C8.10003 0.773188 8.03907 0.928626 7.93056 1.04323C7.82206 1.15784 7.6749 1.22222 7.52145 1.22222H1.73572ZM10.0053 0.790167C10.0586 0.731799 10.1225 0.685244 10.1931 0.653216C10.2637 0.621188 10.3396 0.60433 10.4164 0.603625C10.4932 0.60292 10.5694 0.618382 10.6405 0.649109C10.7116 0.679836 10.7762 0.725213 10.8305 0.782592C10.8849 0.839971 10.9278 0.908203 10.9569 0.983306C10.986 1.05841 11.0006 1.13888 11 1.22002C10.9993 1.30117 10.9833 1.38136 10.953 1.45591C10.9227 1.53047 10.8786 1.5979 10.8234 1.65428L5.61621 7.15428C5.50771 7.26884 5.36058 7.3332 5.20716 7.3332C5.05374 7.3332 4.90661 7.26884 4.79811 7.15428L3.06239 5.32094C3.00713 5.26457 2.96305 5.19714 2.93273 5.12258C2.90241 5.04802 2.88645 4.96783 2.88578 4.88669C2.88511 4.80555 2.89975 4.72508 2.92884 4.64997C2.95793 4.57487 3.00089 4.50664 3.05522 4.44926C3.10954 4.39188 3.17414 4.3465 3.24524 4.31578C3.31635 4.28505 3.39253 4.26959 3.46936 4.27029C3.54618 4.271 3.6221 4.28785 3.69269 4.31988C3.76328 4.35191 3.82712 4.39847 3.88049 4.45683L5.20716 5.85811L10.0053 0.790167Z"
        fill="#393939"
      />
    </svg>
  );
}
