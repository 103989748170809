import { gql } from "@apollo/client";

export const INSERT_BRAND = gql`
  mutation InsertBrand($data: brd_list_insert_input!) {
    insert_brd_list_one(object: $data) {
      id
    }
  }
`;

export const ADD_SUB_DOMAIN_VERCEL_CLOUDFLARE = gql`
  query AddDomainVercelCloudflare($subdomain: String!) {
    addSubDomainVercelCloudflare(arg1: { subdomain: $subdomain }) {
      cloudflare {
        errors
        messages
        result {
          comment
          content
          created_on
          id
          locked
          meta {
            auto_added
            managed_by_apps
            managed_by_argo_tunnel
            source
          }
          modified_on
          name
          tags
          proxiable
          proxied
          ttl
          type
          zone_id
          zone_name
        }
        success
      }
      vercel {
        apexName
        createdAt
        gitBranch
        name
        redirect
        projectId
        redirectStatusCode
        updatedAt
        verified
      }
    }
  }
`;
