import Layout from "~/components/common/Layout";
import DefaultEmailTemplatesModules from "~/modules/DefaultEmailTemplatesModule";
import AddTemplates from "~/modules/DefaultEmailTemplatesModule/segments/EmailTemplates/components/AddTemplates";

export default function AddEmailTemplates() {
  return (
    <div>
      <Layout>
        <DefaultEmailTemplatesModules>
          <AddTemplates />
        </DefaultEmailTemplatesModules>
      </Layout>
    </div>
  );
}