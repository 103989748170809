import React from "react";
import Layout from "../../components/common/Layout";
import UsersModule from "~/modules/UsersModule";
import CustomerList from "~/modules/UsersModule/segments/CustomerList";
export default function Customers() {
  return (
    <div>
      <Layout>
        <UsersModule>
          <CustomerList />
        </UsersModule>
      </Layout>
    </div>
  );
}
