import React from "react";
import Timeline from "./components/Timeline";
import Detail from "./components/Detail";

export default function Activity() {
  return (
    <div className="flex gap-8 w-full justify-stretch">
      <Timeline />
      <Detail />
    </div>
  );
}
