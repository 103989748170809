import React from "react";
import { FaTimes } from "react-icons/fa";
import EditSuggestionForm from "./components/EditSuggestionForm";
import "moment-timezone/builds/moment-timezone-with-data";
import { formatFormData } from "../../../AddSuggestion/utils/formatFormData";
import SuggestionRenderer from "../../../SuggestionsTypes/component/EditComponent";

export default function EditSuggestion({
  tabLabel,
  suggestions, // Get suggestions from props
  inquiry,
  active,
  setActive,
  setShowAddModal,
  inquiry_id,
  user_id,
  arrival,
  passengers,
  stayPassengers,
  trainPassengers,
  carPassengers,
}: any) {
  return (
    <div className="fixed left-0 top-0 w-full h-screen overflow-y-scroll flex justify-end items-start z-50 bg-[#00000070]">
      <div className="modal-container min-h-screen relative bg-white dark:bg-dark-secondary shadow-lg p-6 w-full overflow-y-auto py-4 max-w-[1024px] z-[60] justify-center items-start text-black-100">
        <div>
          <h2 className="text-xl font-bold mb-4 text-primary dark:text-dark-primary text-center w-full">
            Edit Suggestion
          </h2>
          <hr className="border-gray-300  border-dashed my-4" />
          <span
            onClick={() => setShowAddModal(false)}
            className="absolute right-[10px] top-[5px] text-primary dark:text-dark-primary cursor-pointer "
          >
            <FaTimes />
          </span>
        </div>
        <SuggestionRenderer
          tabLabel={tabLabel}
          suggestions={suggestions}
          inquiry={inquiry}
          setShowAddModal={setShowAddModal}
          inquiry_id={inquiry_id}
          user_id={user_id}
          arrival={arrival}
          passengers={passengers}
          stayPassengers={stayPassengers}
          trainPassengers={trainPassengers}
          carPassengers={carPassengers}
        />

        {/* <EditSuggestionForm
          suggestion={suggestion}
          suggestion_cost={suggestion?.inq_suggestion_costs}
          defaultValue={defaultValue}
          inquiry={inquiry}
          inquiry_id={inquiry_id}
          passengers={passengers}
          user_id={user_id}
          setShowAddModal={setShowAddModal}
        /> */}
      </div>
    </div>
  );
}
