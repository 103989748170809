let isTyping = false;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const typingEffect = async (setText: any, text: any) => {
  if (isTyping) {
    // If the function is already running, stop the current execution
    isTyping = false;
    return;
  }

  isTyping = true; // Mark the function as running
  setText(""); // Clear the text before starting

  for (let i = 0; i < text.length; i++) {
    if (!isTyping) return; // Exit early if the typing was canceled
    setText((prev: any) => prev + text[i]);
    await sleep(100); // Adjust the delay as needed
  }

  isTyping = false; // Mark the function as not running
};
