import React from "react";
import Layout from "~/components/common/Layout";
import WhatsappModule from "~/modules/WhatsappModule";
import WhatsappTemplates from "~/modules/WhatsappModule/segments/WhatsappTemplates";

export default function WhatsappTemplatesPage() {
  return (
    <div>
      <Layout>
        <WhatsappModule>
          <WhatsappTemplates />
        </WhatsappModule>
      </Layout>
    </div>
  );
}
