import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Autocomplete, Box, Popover, TextField } from "@mui/material";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import { useState } from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import NewGroup from "../..";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { Controller, useForm } from "react-hook-form";
import EmojiPicker from "emoji-picker-react";
import { useFileUpload } from "@nhost/react";
import { URLs } from "~/config/enums";

const CreateGroup = ({ createGroupBar, setCreateGroupBar, type }: any) => {
  const [showGroupBar, setShowGroupBar] = useState(false);

  const [groupDetails, setGroupDetails] = useState([]);

  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [imageID, setImageID] = useState<any>();
  const [brandID, setBrandId] = useState<any>();

  const { upload } = useFileUpload();

  const { brands }: any = useAssigedBrands();

  const handleOnInputChange = (e: any) => {
    setIsTyping(e.target.value.trim() === "" ? false : true);
  };

  const handleInputChange = (event: any): any => {
    setInputValue(event.target.value);
  };

  const handleEmojiClick = (event: any) => {
    setInputValue(inputValue + event.emoji);
  };

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files;
    uploadFile(file[0]);
  };
  const uploadFile = async (file: any) => {
    const res = await upload({ file });
    // ;
    setImageID(res?.id);
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-whatsappPrimaryBg dark:bg-dark-primary transition-all ease-in-out duration-300 h-full flex flex-col overflow-scroll ${
          createGroupBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] ${
            type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
          }`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setCreateGroupBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">New Community</p>
          </div>
        </div>
        <div className="px-[2rem] flex flex-col items-center justify-center gap-8 py-[1.75rem] bg-whatsappSecondaryBg dark:bg-dark-secondary">
          <div className="relative h-[180px] w-[180px] rounded-full bg-[#54656FCC] flex items-center justify-center cursor-pointer overflow-hidden">
            <input
              type="file"
              className="absolute w-full h-full opacity-0"
              accept="image/*"
              onChange={handleLogoUpload}
            />
            {imageID ? (
              <img
                src={URLs.FILE_URL + imageID}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-[80px] flex flex-col  items-center justify-center text-whatsappSecondaryBg gap-2">
                <CameraAltOutlinedIcon />
                <p className="text-[13px] text-center">ADD ICON</p>
              </div>
            )}
          </div>
          <div className="w-full">
            <Autocomplete
              disablePortal
              onChange={(_, data: any) => {
                setBrandId(data?.id);
              }}
              options={brands ? brands : []}
              getOptionLabel={(option: any) => option?.name}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Brand"
                  {...params}
                  color="success"
                  variant="standard"
                />
              )}
              noOptionsText=""
            />

            <div className="flex items-center relative">
              <TextField
                sx={{ marginTop: "40px" }}
                fullWidth
                placeholder="Group Subject (Optional)"
                variant="standard"
                color="success"
                onChange={handleInputChange}
                onInput={handleOnInputChange}
                value={inputValue}
              />
              <SentimentSatisfiedAltOutlinedIcon
                sx={{
                  position: "absolute",
                  right: "3px",
                  top: "40px",
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                }}
                onClick={(e: any) => {
                  setShowEmojis(!showEmojis);
                  setAnchorEl(e.currentTarget);
                }}
              />
              <Popover
                sx={{
                  backgroundColor: "transparent",
                  top: "-15px",
                  cursor: "pointer",
                }}
                anchorEl={anchorEl}
                onClose={() => {
                  setShowEmojis(!showEmojis);
                }}
                open={showEmojis}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </Popover>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-10">
          {brandID && (
            <button
              className={`p-[13px] text-white rounded-full cursor-pointer ${
                type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
              }`}
            >
              <ArrowForwardOutlinedIcon
                onClick={() => {
                  setShowGroupBar(true);
                }}
              />
            </button>
          )}
        </div>
      </div>
      {showGroupBar && (
        <NewGroup
          showGroupBar={showGroupBar}
          setShowGroupBar={setShowGroupBar}
          setCreateGroupBar={setCreateGroupBar}
          groupDetails={{
            brandID: brandID,
            label: inputValue,
            imgaeId: imageID,
          }}
          type={type}
        />
      )}
    </>
  );
};

export default CreateGroup;
