import { gql } from "@apollo/client";

export const GET_SERVICES_FAQS = gql`
  query MyQuery($brd_id: uuid!) {
    services_faqs(where: { brd_id: { _eq: $brd_id } }) {
      question
      description
      id
      brd_id
      service_id
    }
  }
`;
