import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import MainCaseStudy from "~/modules/ServicesModule/segments/MainCaseStudy";

const MainCaseStudyPage = () => {
  return (
    <div>
      <Layout>
        <BrandModule>
          <MainCaseStudy />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default MainCaseStudyPage;
