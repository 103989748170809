import { useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { GET_BRAND_CONSULTANTS } from "~/graphql/users/Query";
import ConsultantRow from "./component/ConsultantRow";

const ConsultantList = ({ showConsultantBar, setShowConsultantBar }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const [consultantID, setConsultantID] = useState<any>(
    showConsultantBar?.consultant
  );

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const { loading, data, error } = useQuery(GET_BRAND_CONSULTANTS, {
    variables: { brdId: showConsultantBar?.brdId },
  });

  return (
    <div
      className={` h-full transition-all flex flex-col ease-in-out duration-300 bg-whatsappSecondaryBg dark:bg-dark-secondary border-l dark:border-dark-secondary ${
        showConsultantBar ? "w-full z-20" : "w-[0]"
      }`}
    >
      <div className="flex items-center text-black dark:text-white bg-whatsappPrimaryBg dark:bg-dark-primary px-[25px] text-[16px] font-medium py-[19px] mb-2">
        <CloseIcon
          sx={{
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            cursor: "pointer",
            fontWeight: "bolder",
          }}
          onClick={() => {
            setShowConsultantBar(false);
          }}
        />
        <p className="ml-5 font-bold">Brand Consultants</p>
      </div>
      {data?.users?.map((item: any) => {
        return (
          <ConsultantRow
            item={item}
            consultantID={consultantID}
            setConsultantID={setConsultantID}
            convID={showConsultantBar?.convId}
          />
        );
      })}
    </div>
  );
};

export default ConsultantList;
