import { gql } from "@apollo/client";

export const INSERT_UNIVERSITY_CAMPUS = gql`
  mutation InsertUniCampus(
    $universityId: uuid!
    $campusName: String!
    $city: String!
    $country: String!
    $address: String!
  ) {
    insert_brd_university_campuses(
      objects: {
        def_university_id: $universityId
        name: $campusName
        city: $city
        country: $country
        address: $address
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_CAMPUS_WITH_ID = gql`
  mutation DeleteUniCampus($campusId: uuid!) {
    delete_brd_university_campuses(where: {id: {_eq: $campusId}}) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_CAMPUS_WITH_ID = gql`
  mutation UpdateUniCampus(
    $campusId: uuid!,
    $name: String!,
    $country: String!,
    $city: String!
    $address: String!
  ) {
    update_brd_university_campuses(
      where: { id: { _eq: $campusId } },
      _set: { name: $name, country: $country, city: $city, address: $address }
    ) {
      affected_rows
    }
  }
`;

