import { gql } from "@apollo/client";

export const UPDATE_AIRLINE = gql`
  mutation UpdateAirlinePage($id: uuid!, $data: brd_airlines_pages_set_input!) {
    update_brd_airlines_pages_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const REMOVE_FILE = gql`
  mutation MyMutation($id: uuid!) {
    deleteFile(id: $id) {
      id
    }
  }
`;
