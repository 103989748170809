import Layout from "~/components/common/Layout";
import ServicesModule from "~/modules/ServicesModule";
import Category from "~/modules/ServicesModule/segments/Category";

const CategoryPage = () => {
  return (
    <div>
      <Layout>
        <ServicesModule>
          <Category />
        </ServicesModule>
      </Layout>
    </div>
  );
};

export default CategoryPage;
