import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import ConfirmationDialog from "~/components/common/ResponsiveFullScreen";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import { Link } from "react-router-dom";
import {
  useSignOut,
  useUserAvatarUrl,
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  UPDATE_THP_USER_ATTENDENCE,
  UPDATE_THP_USER_OUT_TIME,
} from "~/graphql/thp_user_attendance/Mutation";
import moment from "moment";
import { GET_THP_USER_ATTENDANCE } from "~/graphql/thp_user_attendance/Query";
import { URLs } from "~/config/enums";

const Profile = ({ userData, stringAvatar }: any) => {
  const { signOut } = useSignOut();
  const role = useUserDefaultRole();
  const name = useUserDisplayName();

  const userId = useUserId();

  const client = useApolloClient();

  // my popup code testing:
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    // Ensure this is only called when the user closes the dialog
    setDialogOpen(false);
  };

  const handleConfirmAction = () => {
    // Perform your confirm logic here
    console.log("Action confirmed!");
    setDialogOpen(false); // Close dialog after confirmation
  };

  const [updateThpUserOutTime] = useMutation(UPDATE_THP_USER_OUT_TIME);

  const userAvatarUrl = useUserAvatarUrl();

  const logout = async () => {
    sessionStorage.clear();
    await signOut();
    // window.location.reload();
  };

  const storedData = localStorage.getItem("darkMode");

  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );

  const updateThpUserAttend = async () => {
    const res = await updateThpUserOutTime({
      variables: {
        userID: userId,
        attendanceDate: moment().format("YYYY-MM-DD"),
        outTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ"),
      },
    });
  };

  const [updateThpUserAtendance] = useMutation(UPDATE_THP_USER_ATTENDENCE);
  const checkHiddenDuration = async () => {
    if (userId) {
      await updateThpUserAtendance({
        variables: {
          userID: userId,
          attendanceDate: moment().format("YYYY-MM-DD"),
          currentBreakTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ"),
          nullBreakTime: true,
          nullOutTime: true,
        },
      });
    }
  };

  return (
    <Box
      sx={
        darkMode
          ? {
              bgcolor: "#312D4B",
              boxShadow:
                "rgba(58, 53, 65, 0.2) 0px 5px 5px -3px, rgba(58, 53, 65, 0.14) 0px 8px 10px 1px, rgba(58, 53, 65, 0.12) 0px 3px 14px 2px;",
              borderRadius: "5px",
              width: "230px",
              paddingBottom: "8px",
            }
          : {
              bgcolor: "white",
              boxShadow:
                "rgba(58, 53, 65, 0.2) 0px 5px 5px -3px, rgba(58, 53, 65, 0.14) 0px 8px 10px 1px, rgba(58, 53, 65, 0.12) 0px 3px 14px 2px;",
              borderRadius: "5px",
              width: "230px",
              paddingBottom: "8px",
            }
      }
    >
      <div className="flex items-center p-3">
        {!userData?.avatarUrl ? (
          <Avatar
            style={{
              fontSize: "17px",
              fontWeight: "bolder",
              width: "30px",
              height: "30px",
            }}
            {...stringAvatar(userData?.displayName?.toUpperCase())}
          />
        ) : (
          <img
            className="h-[30px] w-[30px] rounded-full object-cover"
            src={URLs.FILE_URL + userData?.avatarUrl}
          />
        )}
        {/* <AccountCircleIcon className="text-basic" sx={{ fontSize: "2.5rem" }} /> */}

        <div className="ml-3">
          <h1 className="font-[600] text-[1rem] text-primary dark:text-dark-primary capitalize">
            {name}
          </h1>
          <p className="text-[0.8rem] font-[400] text-secondary dark:text-dark-secondary capitalize">
            {role}
          </p>
        </div>
        {/*  */}
      </div>
      <Divider
        sx={
          darkMode
            ? { borderColor: "#E7E3FC61" }
            : { borderColor: "rgba(0, 0, 0, 0.12)" }
        }
      />
      <MenuItem
        component={Link}
        to={`/${role}/profile`}
        sx={{ margin: "3px 0px" }}
      >
        <div className="flex  text-primary dark:text-dark-primary">
          <PersonOutlinedIcon />
          <p className="ml-2">Profile</p>
        </div>
      </MenuItem>
      {/* <MenuItem
        component={Link}
        to="/admin/subscriptions"
        sx={{ marginBottom: "3px" }}
      >
        <div className="flex text-[#3A3541DE] dark:text-dark-primary">
          <SubscriptionsOutlinedIcon />
          <p className="ml-2">Subscription</p>
        </div>
      </MenuItem> */}
      <Divider
        sx={
          darkMode
            ? { borderColor: "#E7E3FC61" }
            : { borderColor: "rgba(0, 0, 0, 0.12)" }
        }
      />

      {role === "admin" && (
        <>
          <MenuItem
            component={Link}
            to="/admin/teams"
            sx={{ marginBottom: "3px" }}
          >
            <div className="flex text-primary dark:text-dark-primary">
              <GroupOutlinedIcon />
              <p className="ml-2">Team Members</p>
            </div>
          </MenuItem>
          <Divider
            sx={
              darkMode
                ? { borderColor: "#E7E3FC61" }
                : { borderColor: "rgba(0, 0, 0, 0.12)" }
            }
          />
          <MenuItem
            component={Link}
            to="/admin/profileAccount"
            sx={{ margin: "3px 0px" }}
          >
            <div className="flex text-primary dark:text-dark-primary">
              <AccountBoxIcon />
              <p className="ml-2">Account</p>
            </div>
          </MenuItem>
          <Divider
            sx={
              darkMode
                ? { borderColor: "#E7E3FC61" }
                : { borderColor: "rgba(0, 0, 0, 0.12)" }
            }
          />
          <MenuItem
            component={Link}
            to="/admin/attendance"
            sx={{ margin: "3px 0px" }}
          >
            <div className="flex text-primary dark:text-dark-primary">
              <AccessTimeOutlinedIcon />
              <p className="ml-2">Attendance</p>
            </div>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/admin/brands"
            sx={{ marginBottom: "3px" }}
          >
            <div className="flex text-primary dark:text-dark-primary">
              <BrandingWatermarkOutlinedIcon />
              <p className="ml-2">Brands</p>
            </div>
          </MenuItem>
          <MenuItem
            component={Link}
            to="/admin/domains"
            sx={{ marginBottom: "3px" }}
          >
            <div className="flex text-primary dark:text-dark-primary">
              <DnsOutlinedIcon />
              <p className="ml-2">Portal Domain</p>
            </div>
          </MenuItem>
          <MenuItem
            component={Link}
            to="/admin/profit-slabs"
            sx={{ marginBottom: "3px" }}
          >
            <div className="flex text-primary dark:text-dark-primary">
              <MonetizationOnOutlinedIcon />
              <p className="ml-2">Profit Slabs</p>
            </div>
          </MenuItem>
          <Divider
            sx={
              darkMode
                ? { borderColor: "#E7E3FC61" }
                : { borderColor: "rgba(0, 0, 0, 0.12)" }
            }
          />
        </>
      )}

      {/* <MenuItem
      onClick={async () => {
        await updateThpUserAttend();
        logout();
      }}
      >
        <div className="flex text-primary dark:text-dark-primary">
          <LogoutOutlinedIcon />
          <p className="ml-2">Logout</p>
        </div>
        
      </MenuItem> */}
      {/* <button onClick={handleOpenDialog}>Open Confirmation Dialog</button>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
      /> */}

      <MenuItem
        onClick={async () => {
          const { data } = await client.query({
            query: GET_THP_USER_ATTENDANCE,
            variables: {
              userId: userId,
              attendanceDate: moment().format("YYYY-MM-DD"),
            },
          });
          // console.log("yeh le", data?.thp_user_attendance?.[0].out_time);
          // if (
          //   !data?.thp_user_attendance?.[0]?.out_time &&
          //   data?.thp_user_attendance?.[0]?.start_time
          // ) {
          //   const confirmed = window.confirm(
          //     "Are you sure you want to CheckOut?"
          //   );

          //   // If user confirms, proceed with logout
          //   if (confirmed) {
          //     await updateThpUserAttend();
          //   } else {
          //     // If user cancels, execute checkHiddenDuration
          //     await checkHiddenDuration();
          //   }
          // }
          if (
            !data?.thp_user_attendance?.[0]?.out_time &&
            data?.thp_user_attendance?.[0]?.start_time
          ) {
            try {
              await updateThpUserAttend();
            } catch (error) {
              console.error("Error updating attendance:", error);
            }
          }
          // Show confirmation dialog

          await logout();
        }}
      >
        <div className="flex text-primary dark:text-dark-primary">
          <LogoutOutlinedIcon />
          <p className="ml-2">Logout</p>
        </div>
      </MenuItem>
    </Box>
  );
};

export default Profile;
