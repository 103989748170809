import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { DELETE_EMAIL_TEMPLATE } from "~/graphql/def_email_templates/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const ConfirmAction = ({
  setOpenConfirmMod,
  buttonName,
  templateId,
  handleSubmit,
  onSubmit,
}: any) => {
  const [deleteTemp] = useMutation(DELETE_EMAIL_TEMPLATE);

  const client = useApolloClient();

  const confirmDelete = async () => {
    const resDeleteTemp = await deleteTemp({
      variables: {
        temp_id: templateId,
      },
    });

    if (resDeleteTemp?.data?.delete_def_emails_templates?.affected_rows) {
      toast.success("Template Deleted Successfully");
      client.refetchQueries({
        include: "all",
      });
    }
  };

  const confirmUpdate = async () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className="flex flex-col items-center">
      <Typography variant="h6">
        Are you sure you want to {buttonName.toLowerCase()} this template?
      </Typography>
      <div className="flex w-1/2 justify-between mt-5">
        <Button
          onClick={() => {
            if (buttonName === "Delete") {
              confirmDelete();
            } else if (buttonName === "Update") {
              confirmUpdate();
            }
            setOpenConfirmMod({ isOpen: false });
          }}
        >
          Yes
        </Button>
        <Button onClick={() => setOpenConfirmMod({ isOpen: false })}>No</Button>
      </div>
    </div>
  );
};

export default ConfirmAction;
