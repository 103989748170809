import { gql } from "@apollo/client";

export const GET_BRD_SEASONS = gql`
  query getBrdSeasons($brdId: uuid!) {
    brd_seasons(where: { brd_id: { _eq: $brdId } }) {
      id
      int_id
      name
      start_date
      end_date
    }
  }
`;

export const GET_SEASONS_WITH_ID = gql`
  query getBrdSeasons($id: uuid!) {
    brd_seasons(where: { id: { _eq: $id } }) {
      id
      int_id
      name
      start_date
      end_date
    }
  }
`;
