import { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField
} from "@mui/material";
import { DELETE_CAMPUS_COURSE_WITH_ID } from "~/graphql/brd_uni_campus_courses/Mutation";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GET_CAMPUS_COURSES_WITH_NAME_OR_INTAKE_OR_TYPE } from "~/graphql/brd_uni_campus_courses/Query";
import { FaTrash } from "react-icons/fa";

const CampusCoursesData = ({ campusId, coursesData }: any) => {

  const [search, setSearch] = useState("");
  const [intake, setIntake] = useState("");
  const [courseType, setCourseType] = useState("");
  const [deleteCampusCourse] = useMutation(DELETE_CAMPUS_COURSE_WITH_ID);
  const [searchCourses, { data: searchResults }] = useLazyQuery(GET_CAMPUS_COURSES_WITH_NAME_OR_INTAKE_OR_TYPE, { fetchPolicy: 'network-only' });
  const client = useApolloClient();
  const navigate = useNavigate();

  const handleDeleteCampusCourse = async (course_id: any) => {
    const res = await deleteCampusCourse({
      variables: { courseId: course_id },
    });

    if (res?.data?.delete_uni_campus_courses?.affected_rows > 0) {
      toast.success("Campus Course Deleted Successfully!");
      client.refetchQueries({ include: "all" });
    }
  };

  const handleViewRequirements = (course_id: any) => {
    navigate(`/admin/brands/universities/campus/course/${course_id}/admission_requirements`);
  };

  useEffect(() => {
    if (search || intake || courseType) {
      searchCourses({
        variables: {
          campusId,
          courseName: search ? `%${search}%` : "",
          intake: intake ? `%${intake}%` : "",
          courseType: courseType ? `%${courseType}%` : ""
        },
      });;
    }
  }, [search, intake, courseType]);

  const displayedCourses = search || intake || courseType ? (searchResults?.uni_campus_courses || []) : (coursesData?.brd_university_campuses?.[0]?.campus_courses || []);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-semibold dark:text-dark-primary">{coursesData?.brd_university_campuses?.[0]?.name}</h1>
        <div className="flex space-x-3">
          <TextField
            id="search-course"
            label="Search Course"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <TextField
            id="search-intake"
            label="Search Intake"
            variant="outlined"
            value={intake}
            onChange={(e) => setIntake(e.target.value)}
          />
          <TextField
            id="search-courseType"
            label="Search Type"
            variant="outlined"
            value={courseType}
            onChange={(e) => setCourseType(e.target.value)}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Course Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Tuition Fees</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Intake</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedCourses?.map((course: any) => (
              <TableRow key={course.id}>
                <TableCell>{course.course_detail.name}</TableCell>
                <TableCell>{course.course_detail.course_type}</TableCell>
                <TableCell>{course.course_detail.tuition_fees}</TableCell>
                <TableCell>{course.course_detail.duration}</TableCell>
                <TableCell>{course.course_detail.intake}</TableCell>
                <TableCell>
                  <div className="flex items-center justify-between">
                    <Button
                      color="info"
                      onClick={() => handleViewRequirements(course.id)}
                    >
                      View Admission Requirements
                    </Button>
                    <FaTrash
                      onClick={() => handleDeleteCampusCourse(course.id)}
                      className="cursor-pointer"
                      size={18}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CampusCoursesData;

