import { gql } from "@apollo/client";

export const UPDATE_BRAND = gql`
  mutation MyMutation($brd_id: uuid, $data: brd_list_set_input!) {
    update_brd_list(where: { id: { _eq: $brd_id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
