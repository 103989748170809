import ShowSEOCasestudy from "./components/ShowSEOCasestudy";

const CaseStudySEOSettings = () => {
  return (
    <>
      <div className="bg-secondary mt-5 dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl text-basic dark:text-dark-primary">
            SEO Settings
          </h1>
        </div>
        <ShowSEOCasestudy />
      </div>
    </>
  );
};

export default CaseStudySEOSettings;
