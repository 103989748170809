import { gql } from "@apollo/client";

export const UPDATE_CONVERSATION_MESSAGE = gql`
  mutation MyMutation($id: uuid, $timestamp: timestamptz, $text: String) {
    update_brd_whatsapp_conversations(
      where: { id: { _eq: $id } }
      _set: { last_message_time: $timestamp, last_message: $text }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_MESSAGES = gql`
  mutation MyMutation($data: [brd_whatsapp_messages_insert_input!]!) {
    insert_brd_whatsapp_messages(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
