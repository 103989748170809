import { Button, TextField, InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import { nhost } from "~/lib/nhost";
import { useEffect, useState } from "react";
import { UPDATE_BRD_SERVICES } from "~/graphql/brd_services/Mutation";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GET_SERVICE_BY_ID } from "~/graphql/brd_services/Query";
import ContentEditor from "~/components/common/ContentEditor";
import { Autocomplete } from "@mui/material";
import { GET_BRD_SERVICES_CATEGORY } from "~/graphql/brd_services_category/Query";
import brandsStore from "~/store/brandsStore/brandsStore";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

interface FormData {
  title: string;
  title_color: string;
  description: string;
  price: string;
  image: any;
  bg_color: string
  category_id: any
  slug: any
}

const EditServices = () => {
  const { service_id } = useParams();
  const client = useApolloClient();
  const { brands } = brandsStore();
  const brdIds = brands.length > 0 ? brands.map((brand: any) => brand.id) : [];
  const [selectedCategoryId, setSelectedCategoryId] = useState<any>("");
  const [Loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, setValue } = useForm<FormData>();
  const [content, setContent] = useState<any>("")
  const [title, setTitle] = useState<any>("")
  const [description, setDescription] = useState<any>("")

  const { data: serviceData } = useQuery(GET_SERVICE_BY_ID, {
    variables: { id: service_id },
  });

  const service = serviceData?.brd_services?.[0];

  const [updateService] = useMutation(UPDATE_BRD_SERVICES);
  const [NewImageId, setNewImageId] = useState<any>(null);

  useEffect(() => {
    if (service) {
      reset({
        // title: service.title,
        // description: service.description,
        price: service.price,
        bg_color: service.bg_color,
        title_color: service.title_color,
        slug: service.slug
      });
      setContent(service.detailed_description || "")
      setTitle(service.title || "")
      setDescription(service.description || "")
    }
  }, [service, reset]);

  const { data: serviceCategories } = useQuery(GET_BRD_SERVICES_CATEGORY, {
    variables: { brdIds },
  });

  const categoryFunc = () => {
    const data = serviceCategories?.brd_services_category.find(
      (item: any) =>
        item.id === serviceData?.brd_services?.[0]?.service_category_id
    );

    return data;
  };

  const defCategory = categoryFunc();

  const defCategoryInd: any = defCategory
    ? serviceCategories?.brd_services_category?.indexOf(defCategory)
    : -1;

  useEffect(() => {
    setSelectedCategoryId(
      defCategoryInd !== -1
        ? serviceCategories?.brd_services_category?.[defCategoryInd]
        : null
    );
    setValue("category_id", defCategory?.id);
  }, [defCategoryInd]);

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (!file) {
      console.warn("No file selected.");
      return;
    }

    try {
      const webpFile = (await convertImageToWebP(file)) as File;
      const response = await nhost.storage.upload({ file: webpFile });
      if (response.error) {
        console.error("Upload error:", response.error.message);
        throw new Error(response.error.message);
      }
      setNewImageId(response.fileMetadata.id);
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const onSubmit = async (formData: FormData) => {
    setLoading(true);
    try {
      if (NewImageId && service?.img_id) {
        const { error: imageDeleteError } = await nhost.storage.delete({
          fileId: service.img_id,
        });
        if (imageDeleteError) {
          console.error("Failed to delete old image:", imageDeleteError);
          return;
        }
      }

      const variables = {
        data: {
          title: title,
          title_color: formData.title_color,
          description: description,
          price: parseFloat(formData.price),
          img_id: NewImageId ? NewImageId : service?.img_id,
          detailed_description: content,
          service_category_id: formData.category_id,
        },
        _eq: service.id,
      };

      const response = await updateService({ variables });

      if (response.data.update_brd_services.affected_rows > 0) {
        toast.success("Service Updated Successfully!");
        client.refetchQueries({ include: "all" });
      } else {
        console.error("No service updated.");
      }
    } catch (error) {
      console.error("Error updating service:", error);
      toast.error("Error updating service. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  console.log("ndscnd", selectedCategoryId);

  const imgUrl = NewImageId
    ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${NewImageId}`
    : `${process.env.REACT_APP_NHOST_STORAGE_URL}${service?.img_id}`;

  return (
    <div className="w-full rounded-lg p-5 bg-secondary dark:bg-dark-secondary">
      <h1 className="text-xl text-primary dark:text-dark-primary mb-6">
        Edit Service
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          <Autocomplete
            options={serviceCategories?.brd_services_category || []}
            getOptionLabel={(option: any) => option.name}
            fullWidth
            value={selectedCategoryId}
            onChange={(event, newValue) => {
              setSelectedCategoryId(newValue || "");
              setValue("category_id", newValue?.id || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Category"
                InputLabelProps={{ className: "dark:text-dark-primary" }}
                variant="outlined"
                fullWidth
              />
            )}
            className="dark:text-dark-primary"
          />
          <TextField
            label="Title"
            type="text"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AutoFixHighIcon
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={async () => {
                      const res = await getAiData(
                        `Write an alternative title for ${title} without qoutes.`
                      );
                      await typingEffect(setTitle, res);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TextField
            label="Slug"
            variant="outlined"
            fullWidth
            required
            {...register("slug")}
            defaultValue={service?.slug || ""}
          />
          <TextField
            label="Title Color"
            variant="outlined"
            fullWidth
            {...register("title_color")}
            defaultValue={service?.title_color || ""}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TextField
            label="Background Color"
            variant="outlined"
            fullWidth
            {...register("bg_color")}
            defaultValue={service?.bg_color || ""}
          />
          <TextField
            label="Price"
            variant="outlined"
            fullWidth
            required
            {...register("price")}
            defaultValue={service?.price || ""}
          />
        </div>
        <TextField
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={3}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AutoFixHighIcon
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={async () => {
                    const res = await getAiData(
                      `Write an alternative description like previous description as "${description}" without qoutes and to the point.`
                    );
                    await typingEffect(setDescription, res);
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <div className="col-span-6 sm:col-full ck-editor relative">
          <label htmlFor="" className="text-primary dark:text-dark-primary">Detail Content</label>
          <ContentEditor content={content} setContent={setContent} />
          <AutoFixHighIcon
            color="primary"
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "100px",
              right: "20px",
            }}
            onClick={async () => {
              setLoading(true);
              setContent("Generating...");
              const res = await getAiData(
                `Write best alternative detail content like previous content which is "${content}": Proper formatted: with proper spacing and headings in inner HTML.`
              );
              setContent(res);
              setLoading(false);
              setContent(res);
              // await typingEffect(setContent, res);
            }}
          />
        </div>
        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary"
        >
          Replace Image
          <input
            type="file"
            onChange={handleImageChange}
            hidden
            accept="image/*"
          />
        </Button>
        {imgUrl && (
          <img
            src={imgUrl}
            alt={service?.title || "Service Image"}
            className="max-h-[200px] rounded-lg shadow-md mt-4"
          />
        )}
        <div className="flex justify-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // disabled={loading}
            style={{ alignSelf: "flex-end" }}
          >
            {Loading ? "Updating..." : "Update"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditServices;
