import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { UPDATE_SERVICE_TIP } from "~/graphql/brd_services_tips/Mutation";
interface FormData {
  title: string;
  description: string;
}

const EditServicesTips = ({ setSidePopup, tip }: any) => {
  const { register, handleSubmit, reset } = useForm<FormData>();
  const { service_id } = useParams();
  const client = useApolloClient();
  const [updateTip] = useMutation(UPDATE_SERVICE_TIP);
  const onSubmit = async (data: FormData) => {
    try {
      const variables = {
        id: tip.id,
        title: data.title,
        description: data.description,
        brdServiceID: service_id,
      };
      const response = await updateTip({ variables });

      if (response.data.update_brd_services_tips.affected_rows > 0) {
        toast.success("Tip updated.");
        await client.refetchQueries({
          include: "all",
        });
        reset();
        setSidePopup(false);
      } else {
        toast.error("No tip updated.");
      }
    } catch (error) {
      toast.error("Error updating tip");
    }
  };

  return (
    <>
      <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
          Edit Tip
        </h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            required
            {...register("title")}
            defaultValue={tip?.title}
            style={{ marginBottom: "16px" }}
          />

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={3}
            {...register("description")}
            defaultValue={tip?.description}
            style={{ marginBottom: "16px" }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Update
          </Button>
        </form>
      </div>
    </>
  );
};

export default EditServicesTips;
