import { gql } from "@apollo/client";

export const GET_AUTO_REPLY_SETTING = gql`
  subscription MySubscription($brd_id: uuid) {
    brd_whatsapp_setting(where: { brd_id: { _eq: $brd_id } }) {
      auto_reply
      auto_reply_type
      id
    }
  }
`;
