import { Box, Button, TextField } from "@mui/material";
import { UPDATE_BRAND_DETAIL } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const SeoSettings = ({ brand }: any) => {
  const seo = brand?.brd_details?.[0];

  const [updateSeo] = useMutation(UPDATE_BRAND_DETAIL);
  const client = useApolloClient();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    const res = await updateSeo({
      variables: {
        id: {
          id: seo?.id,
        },
        brdData: {
          author_company: inpData?.author_company,
          twitter_card_type: inpData?.twitter_card_type,
          twitter_site_handle: inpData?.twitter_site_handle,
          twitter_creator_handle: inpData?.twitter_creator_handle,
          locale: inpData?.locale,
        },
      },
    });

    if (res?.data?.update_brd_detail_by_pk?.id) {
      toast.success("SEO updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">SEO Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              defaultValue={seo?.author_company}
              error={errors["author_company"] ? true : false}
              {...register("author_company", { required: true })}
              label="Author Company"
              type="text"
            />
            <TextField
              defaultValue={seo?.twitter_card_type}
              error={errors["twitter_card_type"] ? true : false}
              {...register("twitter_card_type", { required: true })}
              label="Twitter Card Type"
              type="text"
            />
            <TextField
              defaultValue={seo?.twitter_site_handle}
              error={errors["twitter_site_handle"] ? true : false}
              {...register("twitter_site_handle", { required: true })}
              label="Twitter Site Handle"
              type="text"
            />
            <TextField
              defaultValue={seo?.twitter_creator_handle}
              error={errors["twitter_creator_handle"] ? true : false}
              {...register("twitter_creator_handle", { required: true })}
              label="Twitter Creator Handle"
              type="text"
            />
            <TextField
              defaultValue={seo?.locale}
              error={errors["locale"] ? true : false}
              {...register("locale", { required: true })}
              label="Locale"
              type="text"
            />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save SEO Settings
          </Button>
        </Box>
      </div>
    </>
  );
};

export default SeoSettings;
