import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_BRAND_DETAILS } from "./graphql/Query";
import AirlineDetail from "./components/AirlineDetail";

const AirlinesAdd = () => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_BRAND_DETAILS, {
    variables: { brd_id: brd_id },
  });
  if (loading) return <p>Loading ...</p>;
  // ;
  const brandData = data?.brd_list?.at(0);
  if (brandData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 ">
        <div className="mb-4 col-span-full xl:mb-2">
          <AirlineDetail brand={brandData} />
          {/* <SeoSettings brand={brandData} /> */}
        </div>
      </div>
    );
  } else {
    return <p>No brand details found</p>;
  }
};

export default AirlinesAdd;
