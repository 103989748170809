import { gql } from "@apollo/client";

export const ADD_USER = gql`
  mutation MyMutation2($user: [users_insert_input!]!) {
    insertUsers(objects: $user) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
