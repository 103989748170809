import { gql } from "@apollo/client";

export const GET_FLIGHT_PAGES = gql`
  query MyQuery($id: uuid) {
    brd_flights_pages(where: { brd_id: { _eq: $id } }) {
      id
      title
      destination_name
      destination_iata
    }
  }
`;
