import { useState } from "react";
import Consultantfilter from "../../../InquiryFilterButtons/components/Consultantfilter";
import { UPDATE_CONSULTANT } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useUserDefaultRole } from "@nhost/react";
import { Button } from "@mui/material";

export default function InquiryConsultant({ inquiry }: any) {
  const [edit, setEdit] = useState<any>(false);
  const userRole = useUserDefaultRole();
  const [selectedConsultant, setSelectedConsultant] = useState<any>(false);

  const client = useApolloClient();

  const [updateCon] = useMutation(UPDATE_CONSULTANT);
  const updateConsultant = async () => {
    const res = await updateCon({
      variables: {
        id: inquiry?.id,
        data: {
          picked_by: selectedConsultant?.id,
        },
      },
    });

    if (res?.data?.update_inq_list?.affected_rows) {
      await client.refetchQueries({
        include: "active",
      });
      toast.success("Consultant Updated");
      setEdit(false);
    }
  };
  return (
    <div className="text-base font-semibold text-primary dark:text-dark-primary  capitalize">
      {edit ? (
        <div className="mb-2">
          <Consultantfilter
            setSelectedConsultant={setSelectedConsultant}
            unset={true}
          />
        </div>
      ) : (
        <span className="block">
          {inquiry?.picked_user?.thp_users_profile?.[0]?.pseudo_name ||
            inquiry?.picked_user?.displayName}
        </span>
      )}
      {userRole === "admin" && (
        <>
          {!edit ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setEdit(true);
              }}
            >
              {inquiry?.picked_by ? "Transfer" : "Assign"}
            </Button>
          ) : (
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  updateConsultant();
                }}
              >
                Update
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
