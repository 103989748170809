import React from "react";
import DomainsBoxTHP from "./components/DomainsBoxTHP";

const DomainsCardTHP = ({ brands, travelHouse, adminUsers }: any) => {
  return (
    <div>
      <DomainsBoxTHP
        brands={brands}
        travelHouse={travelHouse}
        adminUsers={adminUsers}
      />
    </div>
  );
};

export default DomainsCardTHP;
