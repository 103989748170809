import { gql } from "@apollo/client";

export const GET_VENDORS = gql`
  query GetVendors($th_id: uuid!) {
    users(
      where: {
        brd_users: {
          thp_id: { _eq: $th_id }
          users: { defaultRole: { _eq: "vendor" } }
        }
      }
    ) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
    }
  }
`;
