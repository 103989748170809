import InputField from "../../../components/FormInputs/InputField";
import { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { stripe } from "~/lib/stripe";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#000000",
      fontSize: "14px",
      "::placeholder": {
        color: "#ACB1C6",
      },
      width: "100%",
      border: "2px solid #ACB1C6",
      paddingLeft: "15px",
      paddingTop: "15px",
      borderRadius: "5px",
    },
  },
};

export default function PaymentDetail({
  travelHouse,
  getCustomerPayDetail,
  setOpenMod,
}: any) {
  const elements = useElements();
  const stripeByElem = useStripe();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [stripeValue, setStripeValue] = useState<any>({
    number: false,
    cvc: false,
    expiry: false,
  });

  const handleCardNumberChange = (event: any) => {
    setStripeValue({ ...stripeValue, number: event.complete });
    if (!event.complete) {
      if (event?.error?.message) {
        setError("cardNumber", {
          type: "manual",
          message: event?.error?.message,
        });
      } else {
        clearErrors("cardNumber");
      }
    } else {
      clearErrors("cardNumber");
    }
  };

  const handleExpiryChange = (event: any) => {
    setStripeValue({ ...stripeValue, expiry: event.complete });
    if (!event.complete) {
      if (event?.error?.message) {
        setError("expiry", {
          type: "manual",
          message: event?.error?.message,
        });
      } else {
        clearErrors("expiry");
      }
    } else {
      clearErrors("expiry");
    }
  };

  const handleCVCChange = (event: any) => {
    setStripeValue({ ...stripeValue, cvc: event.complete });
    if (!event.complete) {
      if (event?.error?.message) {
        setError("cvc", {
          type: "manual",
          message: event?.error?.message,
        });
      } else {
        clearErrors("cvc");
      }
    } else {
      clearErrors("cvc");
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!stripeByElem || !elements) {
      toast.error("Transaction Error");
      setLoading(false);
    } else {
      const card = elements.getElement(CardNumberElement);
      const { paymentMethod }: any = await stripeByElem.createPaymentMethod({
        type: "card",
        card: card!,
      });

      const updatePayCust = await stripe.paymentMethods.attach(
        paymentMethod?.id,
        {
          customer: travelHouse?.subscription_user_ref,
        }
      );

      if (
        updatePayCust?.customer === travelHouse?.subscription_user_ref &&
        updatePayCust?.id === paymentMethod?.id
      ) {
        await getCustomerPayDetail(travelHouse?.subscription_user_ref);
        setLoading(false);
        setOpenMod(false);
        toast.success("Payment Added Successfully");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-[20px] text-[#393939] font-[600] text-center my-[20px]">
        Payment Details
      </h3>

      {/* <div className="w-full grid grid-cols-1 gap-[20px] py-2">
        <InputField
          register={register}
          errors={errors}
          fieldName="cardHolderName"
          required={true}
          placeHolder="Enter Name on Card"
          label="Enter Name on Card"
          inputClasses="placeholder:text-[#ACB1C6] text-[14px] text-black w-full br-gray h-[44px] px-[15px]  rounded-[5px]"
        />
      </div> */}

      <div className="w-full grid grid-cols-1 gap-[20px] py-2">
        <div className="flex flex-col gap-[10px]">
          <label className="text-[14px] text-[#393939]" htmlFor="">
            Card Number
          </label>
          <div
            style={{
              border: "1px solid #ACB1C6",
            }}
            className="placeholder:text-[#ACB1C6] text-[14px] text-black w-full br-gray h-[50px] px-[15px]  rounded-[5px] pt-[17px] "
          >
            <CardNumberElement
              onChange={handleCardNumberChange}
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          {errors["cardNumber"] && (
            <span
              role="alert"
              className={`text-red-500 m-1 text-[12px] normal-case `}
            >
              {errors["cardNumber"].message}
            </span>
          )}
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-[20px] py-2">
        <div className="flex flex-col gap-[10px] mr-5">
          <label className="text-[14px] text-[#393939]" htmlFor="">
            Expiration
          </label>
          <div
            style={{
              border: "1px solid #ACB1C6",
            }}
            className="pt-[17px] placeholder:text-[#ACB1C6] text-[14px] text-black w-full br-gray h-[50px] px-[15px]  rounded-[5px]"
          >
            <CardExpiryElement
              onChange={handleExpiryChange}
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          {errors["expiry"] && (
            <span
              role="alert"
              className={`text-red-500 m-1 text-[12px] normal-case `}
            >
              {errors["expiry"].message}
            </span>
          )}
        </div>

        <div className="flex flex-col gap-[10px]">
          <label className="text-[14px] text-[#393939]" htmlFor="">
            CVV
          </label>
          <div
            style={{
              border: "1px solid #ACB1C6",
            }}
            className="pt-[17px] placeholder:text-[#ACB1C6] text-[14px] text-black w-full br-gray h-[50px] px-[15px]  rounded-[5px]"
          >
            <CardCvcElement
              onChange={handleCVCChange}
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>

          {errors["cvc"] && (
            <span
              role="alert"
              className={`text-red-500 m-1 text-[12px] normal-case `}
            >
              {errors["cvc"].message}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-7">
        <Button type="submit" variant="contained">
          {loading ? "Adding..." : "Add Payment Method"}
        </Button>
      </div>
    </form>
  );
}
