import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useState } from "react";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import { Avatar, Popover } from "@mui/material";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import MenuItems from "../TextMessage/component/MenuItems";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Reactions from "../Reactions";
import ReplyMessage from "../ReplyMessage";

const VoiceMessage = ({ type, setShowReplyMessage, messageType }: any) => {
  const [showPauseIcon, setShowPauseIcon] = useState(false);
  const [showAdditional, setShowAdditional] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showReaction, setShowReactions] = useState(false);
  const [reactionEmoji, setReactionEmoji] = useState("");

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div
      className={`flex items-center w-full my-1 ${
        reactionEmoji.length > 0 && "mb-6"
      } ${type === "recieve" ? "justify-start" : "justify-end"}`}
      onMouseEnter={() => setShowAdditional(true)}
      onMouseLeave={() => setShowAdditional(false)}
    >
      <div className="md:max-w-[70%] relative flex items-center">
        <div
          className={`rounded-lg shadow ${
            messageType === "messageReply" && "px-1 pt-1"
          }  ${
            type === "recieve"
              ? "bg-whatsappSecondaryBg dark:bg-dark-primary"
              : "bg-whatsappMessagebg"
          }`}
        >
          {messageType === "messageReply" && (
            <ReplyMessage
              replyType="messageReply"
              message="voiceMessage"
              type={type}
            />
          )}
          <div className="flex items-center">
            <div
              className={`py-[7px] relative ${
                type === "recieve" ? "order-2 pr-[13px]" : "pl-[13px]"
              }`}
            >
              <Avatar
                alt="whatsappAvator"
                src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
              />
              <MicOutlinedIcon
                sx={{
                  color: "#3A3541DE",
                  position: "absolute",
                  bottom: "3px",
                  ...(type === "recieve"
                    ? { left: "-2px" }
                    : { right: "-2px" }),
                }}
              />
            </div>
            <div>
              <div className="flex items-center gap-3 mx-3 mt-3">
                {showPauseIcon ? (
                  <PauseOutlinedIcon
                    sx={{
                      color:
                        type === "recieve" && darkMode
                          ? "#E7E3FCDE"
                          : "#3A3541DE",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                    onClick={() => setShowPauseIcon(false)}
                  />
                ) : (
                  <PlayArrowIcon
                    sx={{
                      color:
                        type === "recieve" && darkMode
                          ? "#E7E3FCDE"
                          : "#3A3541DE",
                      cursor: "pointer",
                      fontSize: "30px",
                    }}
                    onClick={() => setShowPauseIcon(true)}
                  />
                )}
                <div className="flex items-center">
                  <div className="w-3 h-3 bg-whatsappBasic rounded-full"></div>
                  <div className="w-[150px] border-[#8696a0] border-t-2 border-dotted"></div>
                </div>
              </div>
              <div
                className={`flex items-center justify-between pl-12 text-primary ${
                  type === "recieve" && "dark:text-dark-primary"
                }`}
              >
                <p className="text-[11px] mr-2 ">1:15</p>
                <p className="text-[11px] mr-2 ">
                  1:17 PM{" "}
                  {!(type === "recieve") && (
                    <DoneAllOutlinedIcon
                      sx={{ fontSize: "15px", color: "#009de2" }}
                    />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        {showReaction && (
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                backgroundColor: "transparent",
                borderRadius: "30px",
              },
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowReactions(!showReaction);
            }}
            open={showReaction}
            anchorOrigin={{
              vertical: "center",
              horizontal: type === "recieve" ? "left" : "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: type === "recieve" ? "left" : "right",
            }}
          >
            <Reactions
              setShowReactions={setShowReactions}
              setReactionEmoji={setReactionEmoji}
            />
          </Popover>
        )}
        {reactionEmoji.length > 0 && (
          <div
            className="absolute right-[18px] bottom-[-20px] bg-whatsappSecondaryBg h-[25px] w-[28px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer text-sm shadow"
            onClick={() => {
              setReactionEmoji("");
            }}
          >
            {reactionEmoji}
          </div>
        )}
        {showAdditional && (
          <>
            <div
              className={`absolute  bg-black/30 h-[22px] w-[22px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer ${
                type === "recieve" ? " right-[-30px]" : "left-[-30px]"
              }`}
              onClick={(e: any) => {
                setShowReactions(!showReaction);
                setAnchorEl(e.currentTarget);
              }}
            >
              <EmojiEmotionsIcon sx={{ color: "white", fontSize: "16px" }} />
            </div>
            <div
              className={`absolute h-[22px] w-[22px] rounded-full  flex items-center justify-center font-bold cursor-pointer ${
                type === "recieve"
                  ? messageType === "messageReply"
                    ? "top-1 right-1 text-primary"
                    : "top-0 right-14 text-primary"
                  : messageType === "messageReply"
                  ? "top-1 right-1 text-primary"
                  : "top-0 right-0 bg-whatsappMessagebg text-primary"
              }`}
              onClick={(e: any) => {
                setAnchorEl(e.currentTarget);
                setShowMenu(!showMenu);
              }}
            >
              <KeyboardArrowDownIcon sx={{ color: "gray", fontSize: "25px" }} />
            </div>
            <Popover
              sx={{
                background: "transparent",
              }}
              anchorEl={anchorEl}
              onClose={() => {
                setShowMenu(!showMenu);
              }}
              open={showMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItems
                setShowReplyMessage={setShowReplyMessage}
                setShowReactions={setShowReactions}
                setShowMenu={setShowMenu}
              />
            </Popover>
          </>
        )}
      </div>
    </div>
  );
};

export default VoiceMessage;
