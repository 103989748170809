import { gql } from "@apollo/client";

export const EDIT_TRANSACTION = gql`
  mutation EditTransaction($id: uuid, $status: String, $receipt: String) {
    update_inq_transection(
      where: { id: { _eq: $id } }
      _set: { status: $status, receipt: $receipt }
    ) {
      returning {
        id
      }
    }
  }
`;

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { status: "inprocess" }
    ) {
      returning {
        id
      }
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation MyMutation($transactions: [acc_transactions_insert_input!]!) {
    insert_acc_transactions(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation EditTransaction($id: uuid!, $status: String, $refundAmount: float8) {
    update_inq_transection(
      where: { id: { _eq: $id } }
      _set: { status: $status, refund_amount: $refundAmount }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction($id: uuid!) {
    delete_inq_transection(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
