import { gql } from "@apollo/client";

export const GET_TRAVEL_HOUSES = gql`
  query GetTravelHouses {
    thp_list(order_by: { created_at: desc }) {
      disabled
      domain
      email_refresh_token
      name
      subdomain
      subscription_ref
      activated_at
      created_at
      expiry
      suspended_at
      updated_at
      id
      plan_id
      def_subscription_plan {
        plan_name
      }
    }
  }
`;
