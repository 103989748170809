import { gql } from "@apollo/client";

export const IMPORT_PAGE = gql`
  mutation MyMutation($data: [brd_flights_pages_insert_input!]!) {
    insert_brd_flights_pages(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const IMPORT_PAGE_TIPS = gql`
  mutation MyMutation($tips: [brd_flights_pages_tips_insert_input!]!) {
    insert_brd_flights_pages_tips(objects: $tips) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
