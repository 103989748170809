import { gql } from "@apollo/client";

export const GET_FACEBOOK_PROFILE_PAGES = gql`
query GetFacebookProfilePages($access_token: String!) {
  get_fb_profile_pages(arg1: { access_token: $access_token }) {
    facebookOrgData {
      access_token
      category
      category_list {
        id
        name
      }
      name
      id
      tasks
      logoUrl
    }
  }
}

`