import { gql } from "@apollo/client";

export const ADD_BRD_SEAS_FARES = gql`
  mutation addBrdSeasFares($data: [brd_seasonal_fares_insert_input!]!) {
    insert_brd_seasonal_fares(objects: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_SEAS_FARES = gql`
  mutation updateBrdSeasFares(
    $id: uuid!
    $data: brd_seasonal_fares_set_input!
  ) {
    update_brd_seasonal_fares(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_SEAS_FARES = gql`
  mutation deleteBrdSeasFares($id: uuid!) {
    delete_brd_seasonal_fares(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
