import Subscriptions from "./components/Subscriptions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PK } from "~/config/enums";

const ProfileAccComp = () => {
  const stripePromise: any = loadStripe(STRIPE_PK.KEY);

  return (
    <div>
      <Elements stripe={stripePromise}>
        <Subscriptions />
      </Elements>
    </div>
  );
};

export default ProfileAccComp;
