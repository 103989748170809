import React from "react";
import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import CampusCourses from "~/modules/BrandModule/segments/Universities/Campuses/CampusCourses";

export default function CampusCoursesPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <CampusCourses />
        </BrandModule>
      </Layout>
    </div>
  );
}
