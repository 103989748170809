import { gql } from "@apollo/client";

export const GET_ALL_INQUIRIES = gql`
  subscription GetInquiries(
    $limit: Int!
    $offset: Int!
    $condition: inq_list_bool_exp!
  ) {
    inq_list(
      where: $condition
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      direct_flight
      flexible_arrival
      flexible_departure
      approx_budget
      from
      inquiry_no
      preferred_airline
      source
      status
      to
      trip_type
      arrival_datetime
      created_at
      departure_datetime
      updated_at
      brd_id
      id
      source
      source_place
      source_position
      source_keyword
      source_add_id
      thp_inquiry_flag {
        color
        id
        name
      }
      inq_comments(order_by: { created_at: desc }, limit: 1) {
        comment
        thp_inquiry_flag {
          color
          id
          name
        }
        created_at
        id
        flag_id
        timestamp
        reminder_time
      }
      payment_method_id
      picked_by
      suggestion_id
      thp_id
      user_id
      brd_list {
        name
        domain
        subdomain
      }
      users {
        id
        email
        displayName
        metadata
        phoneNumber
        avatarUrl
        brd_users {
          user_id
          brd_id
          thp_id
        }
        inq_lists {
          picked_user {
            metadata
            thp_users_profile {
              pseudo_name
            }
          }
        }
      }
      picked_user {
        id
        avatarUrl
        metadata
        email
        displayName
        phoneNumber
        thp_users_profile {
          pseudo_name
        }
      }
      inq_passengers {
        type
      }
    }
  }
`;

export const GET_UNIQUE_INQUIRIES = gql`
  query GetInquiries(
    $limit: Int!
    $offset: Int!
    $condition: inq_list_unique_bool_exp!
  ) {
    inq_list_unique(
      where: $condition
      order_by: { main_inq: { created_at: desc } }
      limit: $limit
      offset: $offset
    ) {
      inquiry_no
      main_inq {
        direct_flight
        flexible_arrival
        flexible_departure
        approx_budget
        from
        inquiry_no
        preferred_airline
        source
        status
        to
        trip_type
        arrival_datetime
        created_at
        departure_datetime
        updated_at
        brd_id
        id
        source
        source_place
        source_position
        source_keyword
        source_add_id
        thp_inquiry_flag {
          color
          id
          name
        }
        inq_comments(order_by: { created_at: desc }, limit: 1) {
          comment
          thp_inquiry_flag {
            color
            id
            name
          }
          created_at
          id
          flag_id
          timestamp
          reminder_time
        }
        payment_method_id
        picked_by
        suggestion_id
        thp_id
        user_id
        brd_list {
          name
          domain
          subdomain
        }
        users {
          id
          email
          displayName
          metadata
          phoneNumber
          avatarUrl
          brd_users {
            user_id
            brd_id
            thp_id
          }
          inq_lists {
            picked_user {
              metadata
              thp_users_profile {
                pseudo_name
              }
            }
          }
        }
        picked_user {
          id
          avatarUrl
          metadata
          email
          displayName
          phoneNumber
          thp_users_profile {
            pseudo_name
          }
        }
        inq_passengers {
          type
        }
      }
    }
  }
`;

export const GET_ALL_INQUIRIES_COUNT = gql`
  subscription GetInquiriesCount($condition: inq_list_bool_exp!) {
    inq_list_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_UNIQUE_INQUIRIES_COUNT = gql`
  query GetInquiriesCount($condition: inq_list_unique_bool_exp!) {
    inq_list_unique_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SUB_UNIQUE_INQUIRIES_COUNT = gql`
  subscription GetInquiriesCount($condition: inq_list_unique_bool_exp!) {
    inq_list_unique_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;
