import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { GET_INQ_FLAGS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useEffect } from "react";

const InquiryFlagFilter = ({
  selectedFlags,
  setSelectedFlags,
  travelHouse,
}: any) => {
  const { loading, data, error } = useQuery(GET_INQ_FLAGS, {
    variables: {
      id: travelHouse?.id,
    },
  });

  const handleFlagChange = (event: any, newValue: any) => {
    setSelectedFlags([newValue]);
  };

  if (loading) return <>Loading..</>;
  if (error) return <>Err..${error.message}</>;

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data?.thp_inquiry_flags}
        onChange={handleFlagChange}
        getOptionLabel={(option: any) => option.name}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Flag" />}
      />
    </>
  );
};

export default InquiryFlagFilter;
