import { gql } from "@apollo/client";

export const GET_COMMENTS = gql`
  subscription MyQuery($inq_id: uuid!) {
    inq_comments(
      where: { inq_id: { _eq: $inq_id } }
      order_by: { created_at: desc }
    ) {
      comment
      created_at
      flag_id
      inq_id
      reminder_time
      timestamp
      user {
        email
        displayName
        defaultRole
      }
      thp_inquiry_flag {
        name
        id
        color
      }
    }
  }
`;
