import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WhatsappNew from "../../components/Whatsapp/WhatsappNew";
import SingleUser from "../../components/SingleUser";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_MESSAGES, GET_STREAM_MESSAGES } from "../../graphql/Query";
import GetTime from "~/components/common/header/GetTime";
import commonStore from "~/store/commonStore/commonStore";

export default function WAMessenger({
  brand,
  sideOnly = false,
  lastTime,
}: any) {
  const [activeUser, setActiveUser] = useState<any>(null);
  const [chats, setChat] = useState<any>([]);

  const { lastActive, setLastActive } = commonStore();

  const sortMessages = () => {
    const chatNew: any = [...chats];
    chatNew.sort((a: any, b: any) => {
      if (a?.timestamp && b?.timestamp) {
        const timestampA = new Date(a.timestamp).getTime();
        const timestampB = new Date(b.timestamp).getTime();
        return timestampB - timestampA;
      }
    });
    setChat(chatNew);
  };

  const addTimeText = (chat: any) => {
    const chatNew: any = [...chats];

    chatNew.filter(
      (ch: any) => ch.user_phone === chat.user_phone
    )[0].timestamp = chat.timestamp;

    chatNew.filter((ch: any) => ch.user_phone === chat.user_phone)[0].text =
      chat.text;

    setChat(chatNew);
    sortMessages();
  };

  const { loading, data, error } = useQuery(GET_MESSAGES, {
    variables: { brd_phone: brand?.phoneNumberId },
  });

  let lastActiveTime = lastActive ? lastActive : lastTime;

  const {
    loading: l,
    data: d,
    error: e,
  } = useSubscription(GET_STREAM_MESSAGES, {
    variables: {
      brd_phone: brand?.phoneNumberId,
      cursor: lastActiveTime,
    },
  });

  useEffect(() => {
    if (data?.brd_whatsapp_chat?.length > 0) {
      let newChat: any = [];
      data?.brd_whatsapp_chat.map((singleChat: any) => {
        newChat.push({
          ...singleChat,
          timestamp: null,
          text: null,
          unread: 0,
        });
      });
      setChat(newChat);
    }
  }, [data?.brd_whatsapp_chat]);

  useEffect(() => {
    if (d?.brd_whatsapp_chat_stream.length > 0) {
      // const lastTime =
      //   d?.brd_whatsapp_chat_stream[d?.brd_whatsapp_chat_stream?.length - 1]
      //     ?.created_at;
      // setLastActive(lastTime);
      const chatNew: any = [...chats];
      d?.brd_whatsapp_chat_stream.map((streamChat: any) => {
        if (chatNew?.length > 0) {
          chatNew.filter(
            (ch: any) => ch.user_phone === streamChat.user_phone
          )[0].timestamp = streamChat.timestamp;

          chatNew.filter(
            (ch: any) => ch.user_phone === streamChat.user_phone
          )[0].text = streamChat.text;

          chatNew.filter(
            (ch: any) => ch.user_phone === streamChat.user_phone
          )[0].unread += 1;
        }
      });

      setChat(chatNew);
      sortMessages();
    }
  }, [d?.brd_whatsapp_chat_stream]);

  return (
    <div>
      <div>
        <div className="container mx-auto">
          <div className="">
            <div className="flex  h-[80vh]">
              <div
                className={` ${sideOnly ? "w-full" : "w-1/3"}  flex flex-col`}
              >
                <div className="bg-secondary shadow rounded-lg border-none dark:bg-dark-secondary flex-1 overflow-auto">
                  {chats?.length > 0 && (
                    <>
                      {chats?.map((chat: any) => {
                        return (
                          <div
                            onClick={() => {
                              setActiveUser({
                                phone: chat.user_phone,
                                name: chat.user_name,
                              });
                            }}
                          >
                            <SingleUser
                              chat={chat}
                              addTimeText={addTimeText}
                              brd_phone={brand?.phoneNumberId}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>

              {!sideOnly && activeUser && (
                <WhatsappNew
                  brd_phone={brand?.phoneNumberId}
                  toNumber={activeUser.phone}
                  name={activeUser.name}
                  token={brand?.whatsapp_token}
                  user=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
