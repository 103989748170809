import { useQuery } from "@apollo/client";
import React from "react";
import { GET_USER_DETAIL } from "~/graphql/users/Query";
export default function UserName({ user_id }: any) {
  const { loading, error, data } = useQuery(GET_USER_DETAIL, {
    variables: { id: user_id },
  });
  if (loading)
    return (
      <span className=" text-[gray] text-sm w-full text-right">loading..</span>
    );
  if (error)
    return (
      <span className=" text-[gray] text-sm w-full text-right">
        {error?.message}
      </span>
    );
  return (
    <span className=" text-[gray] text-sm w-full text-right">
      {data?.users?.[0]?.displayName}
    </span>
  );
}
