import { gql } from "@apollo/client";

export const GET_DEF_EMAIL_TEMPLATES = gql`
  query GetDefEamilTemplates($template_ids: [Int!]!) {
    def_emails_templates(where: { id: { _nin: $template_ids } }) {
      id
      content
      for_role
      name
      subject
      variables_allowed
      created_at
      updated_at
      notification_content
    }
  }
`;
