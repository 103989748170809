import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useApolloClient, useMutation } from "@apollo/client";
import { INSERT_BRD_UNIVERSITY } from "~/graphql/brd_universities/Mutation";
import { toast } from "react-toastify";

const AddUniversity = ({brdId, setSidePopup }: any) => {

  const { register, handleSubmit } = useForm();
  const [insertUniversity] = useMutation(INSERT_BRD_UNIVERSITY);
  const client = useApolloClient();

  const onSubmit = async (data: any) => {
    const result = await insertUniversity({
      variables: {
        universityName: data.university,
        brdId: brdId,
      },
    });

    if (result?.data?.insert_brd_universities?.affected_rows > 0) {
      client.refetchQueries({
        include: "all",
      });
      toast.success("University Added Successfully!");
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add University
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="University Name"
          variant="outlined"
          fullWidth
          required
          {...register("university")}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddUniversity;
