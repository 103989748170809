import React from "react";

export default function Detail() {
  return (
    <div className="flex-1">
      <h2 className="mb-2 text-[16px] font-[500]">Details</h2>
      <hr />
    </div>
  );
}
