import { gql } from "@apollo/client";

export const GET_SERVICE_TESTIMONIALS = gql`
  query GetTestimonials($brdId: uuid!) {
    brd_services_testimonials(where: { brd_id: { _eq: $brdId } }) {
      name
      rating
      feedback
      id
      brd_id
    }
  }
`;
