import { useQuery } from "@apollo/client";
import { GET_CAMPUS_COURSES } from "~/graphql/brd_uni_campus_courses/Query";

export default function useCampusCoursesData(campus_id: any) {
  const { data } = useQuery(GET_CAMPUS_COURSES, {
    variables: {
      campusId: campus_id,
    },
  });

  return { campusCoursesData: data };
}
