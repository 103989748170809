import { gql } from "@apollo/client";

export const GET_ACTIVITY = gql`
  subscription GetBrandsQuery($user_id: uuid) {
    brd_notifications(
      where: { activity_user_id: { _eq: $user_id } }
      distinct_on: unique_trigger
    ) {
      created_at
      content
      payload
      user_id
      template {
        id
        name
      }
      activity_user {
        email
        phoneNumber
      }
    }
  }
`;
