import { gql } from "@apollo/client";

export const ADD_USER = gql`
  mutation MyMutation($data: [users_insert_input!]!) {
    insertUsers(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_BRD_USERS = gql`
  mutation MyMutation($data: [brd_users_insert_input!]!) {
    insert_brd_users(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_INQ = gql`
  mutation MyMutation($data: [inq_list_insert_input!]!) {
    insert_inq_list(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
