import React from "react";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import UnReadCount from "./components/UnReadCount";
import { Link, useParams } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function SingleConversation({ conversation }: any) {
  const { conversation_id } = useParams();
  const role = useUserDefaultRole();
  return (
    <Link to={`/${role}/emails/${conversation.id}`}>
      <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer">
        <div className="pl-[15px] py-[13px]">
          <AccountCircleIcon
            sx={{
              color: "#9155FD",
              fontSize: "50px",
            }}
          />
        </div>
        <div className="py-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
          <div className="flex items-center justify-between">
            <h1 className="text-[16px] text-black dark:text-white">
              {conversation.user_name}
            </h1>
            <p className="text-[12px] dark:text-dark-primary">
              {getTimeReadableFormat(conversation.last_email_time)}
            </p>
          </div>
          <div className="flex items-center justify-between w-full">
            <p className="text-[14px] h-[18px] overflow-hidden truncate w-[320px] dark:text-dark-primary">
              {conversation.last_email_subject}
            </p>
            <UnReadCount
              conversation_id={conversation.id}
              last_view_time={
                conversation.last_view_time || conversation.created_at
              }
              active={conversation_id === conversation.id ? true : false}
            />
          </div>
        </div>
      </div>
    </Link>
  );
}
