import { Skeleton } from "@mui/material";
import ChatRowSekeleton from "./component/ChatRowSekeleton";

const SideSekeleton = () => {
  return (
    <div className="border dark:border-dark-secondary md:max-w-[460px] w-full relative h-full flex flex-col">
      <div className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex justify-between items-center">
        <Skeleton variant="circular" width={40} height={40} />
        <div className="flex items-center gap-5">
          <Skeleton variant="text" sx={{ fontSize: "25px", width: "25px" }} />
          <Skeleton variant="text" sx={{ fontSize: "25px", width: "25px" }} />
        </div>
      </div>
      <div className="flex items-center gap-3 py-[8px] px-3 bg-whatsappSecondaryBg dark:bg-dark-secondary border-b dark:border-dark-secondary">
        <div className="flex-grow">
          <Skeleton variant="rounded" height={35} />
        </div>
        <Skeleton variant="text" sx={{ fontSize: "30px", width: "30px" }} />
      </div>
      <div className="text-primary dark:text-dark-primary overflow-hidden flex-grow">
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
        <ChatRowSekeleton />
      </div>
    </div>
  );
};

export default SideSekeleton;
