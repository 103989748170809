export const faqDestinations = [
  {
    id: 0,
    title: "General",
    value: "general",
  },
  {
    id: 1,
    title: "Destination",
    value: "destination",
  },
  {
    id: 2,
    title: "Booking",
    value: "booking",
  },
  {
    id: 3,
    title: "Cancelation",
    value: "cancelation",
  },
  {
    id: 4,
    title: "Refund",
    value: "refund",
  },
];
