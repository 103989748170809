import { Autocomplete, TextField, Typography } from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { useClickOutside } from "@mantine/hooks";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useMutation } from "@apollo/client";
import { ADD_AD } from "~/graphql/brd_google_ads/Mutation";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { ADD_AD_UPDATE } from "~/graphql/brd_google_ad_update/Mutation";

const startOfQ12022 = dayjs("2022-01-01T00:00:00.000");
const endOfQ12022 = dayjs("2022-03-31T23:59:59.999");

export default function AdUpdatesAdd({ ad_id, setSidePopup }: any) {
  const [selectedBrand, setSelectedBrand] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [cost, setCost] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const { brands } = useAssigedBrands();
  const [add] = useMutation(ADD_AD_UPDATE);

  function handleClick() {
    setLoading(true);
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    await add({
      variables: {
        data: {
          id: generateRandom10DigitCode(),
          ad_id: ad_id,
          date: data.date,
          impression: data.impression,
          click: data.click,
          cost: data.cost,
        },
      },
    });
    setLoading(false);
    setSidePopup(false);
  };

  if (!brands) return <></>;
  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl pt-[8px] pl-2  mt-[15px]">
        Add ad cost
      </h1>
      {/* <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
                <Controller
                    control={control}
                    rules={{
                        required: true,
                    }}
                    {...register("brd_id", { required: true })}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            disablePortal
                            ref={field.ref}
                            id="combo-box-demo"
                            options={brands}
                            onChange={(_, data) => field.onChange(data?.id)}
                            getOptionLabel={(option: any) => option?.name}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField ref={field.ref} inputRef={(el) => {
                                field.ref(el);
                                el && errors["brd_id"] && el.focus();
                            }} error={errors["brd_id"] ? true : false} {...params} label="Brands" />}
                        />
                    )}
                />

            </div> */}

      <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("date", { required: true })}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                value={value}
                inputRef={ref}
                onChange={(date) => {
                  onChange(date?.$d);
                }}
                // maxDate={new Date()}
                label="Date"
                className="w-full"
                slotProps={{
                  textField: {
                    error: errors["date"] ? true : false,
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>

      <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
        <TextField
          error={errors["impression"] ? true : false}
          id="outlined-basic"
          label="Impression"
          {...register("impression", { required: true })}
          variant="outlined"
          type="number"
          className="w-full"
        />
      </div>

      <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
        <TextField
          error={errors["click"] ? true : false}
          id="outlined-basic"
          label="click"
          {...register("click", { required: true })}
          variant="outlined"
          type="number"
          className="w-full"
        />
      </div>

      <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
        <TextField
          error={errors["cost"] ? true : false}
          id="outlined-basic"
          label="cost"
          {...register("cost", { required: true })}
          variant="outlined"
          type="float"
          className="w-full"
        />
      </div>

      {/* <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
                <TextField
                    id="outlined-basic"
                    error={errors["daily_budget"] ? true : false}
                    label="Daily Budget"
                    type='number'
                    variant="outlined"
                    {...register("daily_budget", { required: true })}
                    className="w-full"
                />
            </div> */}

      <div className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px]  mt-[15px] justify-end">
        <LoadingButton
          color="secondary"
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          type="submit"
        >
          <span>Save</span>
        </LoadingButton>
      </div>
    </form>
  );
}
