import { useApolloClient, useMutation, useSubscription } from "@apollo/client";
import { GET_ALL_INQUIRIES } from "~/modules/DashboardModule/segments/DashboardStats/components/Charts/graphql/Query";
import SearchBar from "../../../SearchBar";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { generateRandomId } from "~/utils/generateRandomId";
import SingleConversation from "../../../SingleConversation";
import { GET_EMAIL_CAMP_CONVERSATION } from "~/graphql/brd_email_campaigns/Query";
import {
  INSERT_EMAIL_CAMP_CONVERSATION,
  UPDATE_EMAIL_CAMP_CONVERSATION,
} from "~/graphql/brd_email_campaigns/Mutation";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import CsvComp from "./component/CsvComp";

const Destinations = ({ setShowDestinations, showDestinations }: any) => {
  const { brands, brd_ids }: any = useAssigedBrands();

  const userId = useUserId();
  const defaultRole = useUserDefaultRole();

  const [brandIDs, setBrandIds] = useState<any>(brd_ids);
  const [conversations, setConversations] = useState<any>();
  const [inquiries, setInquiries] = useState<any>([]);

  const client = useApolloClient();

  const [insertConversations] = useMutation(INSERT_EMAIL_CAMP_CONVERSATION);
  const [updateConversations] = useMutation(UPDATE_EMAIL_CAMP_CONVERSATION);

  useEffect(() => {
    if (!brandIDs?.[0]) {
      setBrandIds(brd_ids);
    }
  }, [brd_ids, brandIDs]);

  const condition = {
    brd_id: { _in: brandIDs },
  };

  const { data, loading, error } = useSubscription(GET_ALL_INQUIRIES, {
    variables: {
      condition,
    },
  });

  useEffect(() => {
    if (defaultRole === "admin") {
      setInquiries(data?.inq_list);
    } else {
      const filteredInqs = data?.inq_list?.filter(
        (item: any) => item.picked_by === userId
      );
      setInquiries(filteredInqs);
    }
  }, [data?.inq_list]);

  const uniqueToByBrdId: { [key: string]: Set<any> } = {};

  // Iterate over inquiries to populate unique 'to' values for each 'brd_id'
  inquiries?.forEach((item: any) => {
    const { to, brd_id } = item;
    if (!uniqueToByBrdId[brd_id]) {
      uniqueToByBrdId[brd_id] = new Set();
    }
    if (!uniqueToByBrdId[brd_id].has(to)) {
      // Check if 'to' is not already present for this 'brd_id'
      uniqueToByBrdId[brd_id].add(to);
    }
  });

  // Initialize an object to keep track of 'to' values already added
  const addedToValues: { [key: string]: Set<any> } = {};

  // Initialize an array to store unique 'to' values for each 'brd_id'
  const uniqueToForEachBrdId: { to: any; brd_id: any }[] = [];

  // Iterate over inquiries again to construct the result array
  inquiries?.forEach((item: any) => {
    const { to, brd_id } = item;
    if (!addedToValues[to]) {
      addedToValues[to] = new Set();
    }
    // Check if 'to' value is not already added for a different 'brd_id'
    if (!addedToValues[to].has(brd_id)) {
      uniqueToForEachBrdId.push({ to, brd_id });
      addedToValues[to].add(brd_id);
    }
  });

  useEffect(() => {
    if (uniqueToForEachBrdId?.length > 0) {
      uniqueToForEachBrdId?.map((item: any, index: any) => {
        const condition = {
          to: { _eq: item?.to },
          _and: { brd_id: { _eq: item?.brd_id } },
        };

        client
          .subscribe({
            query: GET_EMAIL_CAMP_CONVERSATION,
            variables: {
              condition: {
                iata: { _eq: item?.to },
                _and: { brd_id: { _eq: item?.brd_id } },
              },
            },
          })
          .subscribe({
            next(emailCamp) {
              client
                .subscribe({
                  query: GET_ALL_INQUIRIES,
                  variables: {
                    condition,
                  },
                })
                .subscribe({
                  next(data: any) {
                    const toEmails = data?.data?.inq_list?.map((item: any) => {
                      return item?.users?.email;
                    });

                    const uniqueEmails = Array.from(new Set(toEmails));

                    const payload = {
                      user_email: uniqueEmails,
                      user_name: `${item?.to} - ${uniqueEmails?.length}`,
                      brd_id: item?.brd_id,
                      iata: item?.to,
                      emails_length: uniqueEmails?.length,
                    };

                    const addConv = async () => {
                      await insertConversations({
                        variables: { data: payload },
                      });
                    };

                    const updateConv = async () => {
                      await updateConversations({
                        variables: {
                          iata: item?.to,
                          brdID: item?.brd_id,
                          data: payload,
                        },
                      });
                    };

                    if (
                      emailCamp?.data?.brd_email_campaigns &&
                      emailCamp?.data?.brd_email_campaigns?.length < 1
                    ) {
                      addConv();
                    }
                    updateConv();
                  },
                });
            },
          });
      });
    }
  }, [showDestinations]);

  if (brandIDs) {
    client
      .subscribe({
        query: GET_EMAIL_CAMP_CONVERSATION,
        variables: {
          condition: {
            brd_id: { _in: brandIDs },
          },
        },
      })
      .subscribe({
        next(data) {
          const sortedConv = data?.data?.brd_email_campaigns?.sort(function (
            a: any,
            b: any
          ) {
            return b?.emails_length - a?.emails_length;
          });

          const filteredConversations = sortedConv?.filter((conv: any) =>
            inquiries?.some((inq: any) => conv.iata === inq.to)
          );

          if (defaultRole === "admin") {
            setConversations(sortedConv);
          } else {
            setConversations(filteredConversations);
          }
        },
      });
  }

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showDestinations ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] bg-[#9155FD]`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowDestinations(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Destinations</p>
          </div>
        </div>
        <SearchBar />
        <div className="p-[20px] pb-0 w-full">
          <Autocomplete
            disablePortal
            onChange={(_, data: any) => {
              setBrandIds([data?.id]);
            }}
            options={brands ? brands : []}
            getOptionLabel={(option: any) => option?.name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option?.name}
              </Box>
            )}
            renderInput={(params) => <TextField label="Brand" {...params} />}
            noOptionsText=""
          />
        </div>
        <div className="text-primary dark:text-dark-primary bg-whatsappSecondaryBg dark:bg-dark-secondary overflow-scroll flex-grow">
          {defaultRole === "admin" && (
            <div className="p-[20px] w-full">
              <CsvComp brdId={brandIDs} />
            </div>
          )}

          {conversations?.map((conversation: any) => {
            return <SingleConversation conversation={conversation} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Destinations;
