import Layout from "~/components/common/Layout";
import PostScheduler from "~/modules/BrandModule/modules/SocialConnectModule/LinkedIn/PostSchedulerPage";

export default function PostSchedulePage() {
  return (
    <div>
      <Layout>
        {/* <BrandModule> */}

        <PostScheduler />

        {/* </BrandModule> */}
      </Layout>
    </div>
  );
}
