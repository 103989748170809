import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  STAY_ADD_SUGGESTION_COST,
  STAY_EDIT_SUGGESTION_COST,
  STAY_UPDATE_SUGGESTION,
} from "../../EditSuggestion/components/EditSuggestionForm/graphql/Mutation";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { FormControl } from "@mui/material";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HotelNames from "~/components/FormInputs/HotelsName";
import dayjs, { Dayjs } from "dayjs";
import NewPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost";
import useEmail from "~/hooks/emails/useEmail";
import { useUserDisplayName, useUserId } from "@nhost/react";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { toast } from "react-toastify";
import { RoomTypeSelector } from "../../../../AddSuggestion/components/SuggestionForm/Stay/components/RoomType";
import StayPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost/StayPassengerEditCost";

const EditStaySuggestionBox = ({
  defaultValue,
  inquiry_id,
  inquiry,
  suggestion,
  user_id,
  passengers,
  suggestion_cost,
  setShowAddModal,
  tab,
}: any) => {
  const [editSuggestion] = useMutation(STAY_UPDATE_SUGGESTION);
  const [editSuggestionCost] = useMutation(STAY_EDIT_SUGGESTION_COST);
  const [addSuggestionCost] = useMutation(STAY_ADD_SUGGESTION_COST);
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  console.log("snfbmnasfsbfanm", suggestion);
  const client = useApolloClient();
  //button loading state
  const [isLoading, setIsloading] = useState<any>(false);
  // Define react hooks form
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    unregister,
  } = useForm({
    defaultValues: defaultValue,
  });
  console.log("asdnasmdnasm", inquiry, passengers);

  // Use effect to set the room type if defaultValues are provided
  React.useEffect(() => {
    if (defaultValue[0]?.room_type) {
      setValue("room_type", defaultValue[0]?.room_type); // Set the room_type value
    }
  });
  React.useEffect(() => {
    if (defaultValue[0]?.start_date && defaultValue[0]?.end_date) {
      setValue("stay.dates", [
        dayjs(defaultValue[0]?.start_date),
        dayjs(defaultValue[0]?.end_date),
      ]);
    }
  }, [defaultValue, setValue]);

  // Submit form function
  const onSubmit = async (formData: any) => {
    console.log("Form Data: ", formData);

    setIsloading(true);
    if (!isLoading) {
      // Add inquiry id to formData
      formData["id"] = suggestion.id;

      try {
        // Prepare suggestion data for update
        const startDate =
          formData?.stay?.dates && formData.stay.dates[0]
            ? formData.stay.dates[0]
            : defaultValue.start_date;

        const endDate =
          formData?.stay?.dates && formData.stay.dates[1]
            ? formData.stay.dates[1]
            : defaultValue.end_date;
        const inq_suggestion: any = {
          where_to: formData.where_to,
          hotel_name: formData.hotel_name,
          room_type: formData.room_type,
          start_date: startDate,
          end_date: endDate,
        };

        // Update the suggestion itself
        const resSuggestion = await editSuggestion({
          variables: { id: formData.id, suggestion: inq_suggestion },
        });
        console.log("Suggestion update response: ", resSuggestion);

        // Check if there is an existing suggestion cost
        if (
          !formData.suggestion_cost ||
          formData.suggestion_cost.length === 0
        ) {
          // No existing cost: add new suggestion cost
          console.log("No suggestion cost found. Adding new suggestion cost.");
          let suggestion_cost_add: any = [];

          // Prepare new cost data
          formData.suggestion_cost_add.map((sca: any) => {
            sca["stay_suggestion_id"] = formData.id; // Add the suggestion ID
            suggestion_cost_add.push(sca);
          });

          // Add new suggestion cost
          const resSuggestionCostAdd = await addSuggestionCost({
            variables: { suggestion_cost: suggestion_cost_add },
          });
          console.log("Added suggestion cost response: ", resSuggestionCostAdd);
        } else {
          // Existing cost: update the suggestion cost
          console.log(
            "Existing suggestion cost found. Updating suggestion cost."
          );
          formData.suggestion_cost.map(async (cost: any) => {
            const suggestionCost = { ...cost };
            const id = suggestionCost["id"];

            // Update existing cost
            const resSuggestionCost = await editSuggestionCost({
              variables: { id: id, suggestion_cost: suggestionCost },
            });
            console.log("Suggestion cost update response: ", resSuggestionCost);
          });
        }

        // Reset the Apollo Client store and send confirmation email
        await client.resetStore();

        const variables: any = {
          inquiryNo: inquiry?.inquiry_no,
          brandName: inquiry?.brd_list?.name,
          userFirstName: inquiry?.users?.displayName,
          consultantName: userName,
        };

        await emailSend(
          4,
          inquiry.brd_id,
          inquiry.users?.id,
          inquiry.users?.email,
          inquiry.users?.phoneNumber,
          variables
        );

        // Success toast notification
        toast.success(`Suggestions edited successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        // Notification logic for users
        await alert.newNotification(
          userId,
          `Your consultant updated a suggestion. Please review it in the portal.`,
          "",
          ``
        );

        setIsloading(false);
        setShowAddModal(false);
      } catch (error) {
        console.error("Error updating suggestion:", error);
        toast.error(`Failed to edit suggestions`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setIsloading(false);
      }
    }
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    const [start, end] = newValue;
    // Update the form state only if both dates are provided
    if (start && end) {
      setValue("stay.dates", [start, end]); // Update using ISO string format
    } else {
      setValue("stay.dates", []); // Set to an empty array if either date is null
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!inquiry?.invoice_id && (
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            {/* Where To Field */}
            <FormControl className="mx-3 mt-3" fullWidth>
              <Controller
                control={control}
                name="where_to"
                rules={{ required: true }}
                render={({ field }) => (
                  <CityBox
                    {...field}
                    label="Where to"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue}
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>

            {/* Dates Field */}
            <Controller
              control={control}
              name="stay.dates"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const startDate = dayjs(defaultValue?.start_date);
                const endDate = dayjs(defaultValue?.end_date);

                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["SingleInputDateRangeField"]}
                      sx={{ margin: 0, padding: 0 }}
                    >
                      <DateRangePicker
                        value={[startDate, endDate]} // Controlled value
                        onChange={(newValue) => {
                          onChange(newValue); // Update the form value
                          handleDateRangeChange(newValue); // Call the custom handler
                        }}
                      />
                      {error && <span className="error">{error.message}</span>}{" "}
                    </DemoContainer>
                  </LocalizationProvider>
                );
              }}
            />

            {/* Hotel Name Field */}
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="hotel_name"
                rules={{ required: true }}
                render={({ field }) => (
                  <HotelNames
                    {...field}
                    label="Hotel Name"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue} // Use the new function
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>

            {/* Room Type Selector */}
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="room_type"
                rules={{ required: true }}
                render={({ field }) => (
                  <RoomTypeSelector
                    {...field}
                    label="Room Type"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue} // Use the new function
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
      )}

      <hr className="border-gray-300 border-dashed my-4" />

      {/* Passengers Cost */}

      <StayPassengerEditCost
        passengers={passengers}
        suggestion_cost={suggestion_cost}
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        inquiry={inquiry}
        type={tab}
      />

      {/* Submit button */}
      <button
        className={` ${
          isLoading ? "bg-basic cursor-wait" : "bg-basic"
        } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? "Updating.." : "Update Suggestion"}
      </button>

      {/* {error && <p className="text-red-500 text-sm">{error.message}</p>} */}
    </form>
  );
};

export default EditStaySuggestionBox;
