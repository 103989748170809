import { useFileUpload, useNhostClient } from "@nhost/react";
import { useEffect, useState } from "react";
// import { formatFileSize } from "~/utils/FileSizeReadable";
// import { ADD_ORDER_FILE, DELETE_ORDER_FILE } from "./Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
// import { ALLOWED_IMAGE_FORMATS } from "~/config/enums";
// import doc from "~/assets/doc.png";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { formatFileSize } from "~/utils/FileSizeReadAble";
// import { formatFileSize } from "~/utils/FileSizeReadAble";
// import TrashIcon from "./TrashIcon";
// import EyeIcon from "./EyeIcon";

const SingleFile = ({
  removeFile,
  file,
  from = "user",
  userId = null,
  setFilesId,
}: any) => {
  const client = useApolloClient();
  const { upload, progress, name } = useFileUpload();
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState<any>(null);
  const [fileId, setFileId] = useState<any>(null);
  const [orderFileId, setOrderFileId] = useState<any>(null);
  const nhost = useNhostClient();
  //   const [addOrderFile] = useMutation(ADD_ORDER_FILE);
  //   const [deleteOrderFile] = useMutation(DELETE_ORDER_FILE);

  const uploadFile = async () => {
    const res = await upload({ file });
    // const payload = {
    //   name: file.name,
    //   size: file.size,
    //   from: from,
    //   file_id: res.id,
    //   order_id,
    // };
    setFileId(res.id);
    const fileName = file.name.slice(0, file.name.lastIndexOf("."));
    setFilesId((prv: any) => [...prv, { id: res.id, name: fileName }]);
    // const orderRes: any = await addOrderFile({
    //   variables: {
    //     data: [payload],
    //   },
    // });

    // ;

    // if (orderRes.data.insert_order_files.affected_rows > 0) {
    //   await client.resetStore();
    //   setSuccess(orderRes.data.insert_order_files.returning[0].created_at);
    //   setOrderFileId(orderRes.data.insert_order_files.returning[0].id);
    // } else {
    //   setError("Something went worng");
    // }
  };

  const deleteFile = async () => {
    let file_id: any = "";
    if (file?.file_id) {
      file_id = file?.file_id;
    } else {
      file_id = fileId;
    }
    const res = await nhost.storage.delete({ fileId: file_id });

    setFilesId((prv: any) => [...prv.filter((file: any) => file !== file_id)]);
    let order_file_id: any = "";
    if (file?.file_id) {
      order_file_id = file?.id;
    } else {
      order_file_id = orderFileId;
    }
    if (order_file_id) {
      //   const orderRes: any = await deleteOrderFile({
      //     variables: {
      //       id: order_file_id,
      //     },
      //   });
      //   await client.resetStore();
    }

    removeFile(file);
  };

  useEffect(() => {
    if (file?.file_id) {
      setSuccess(file?.created_at);
    } else {
      uploadFile();
    }
    // uploadFile();
    // else if (
    //   !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    // ) {
    //   setError("Invalid File format");
    // }
  }, []);

  //
  return (
    <div className="flex mt-2 gap-4 w-full">
      <div className="bg-gray-200 flex p-2 rounded-[6px] w-full gap-4 justify-between">
        <div className="flex gap-4">
          <FileCopyIcon />
          <div className="flex flex-col">
            <span className="text-[12px">{file.name}</span>
            <span className="text-[10px] text-[gray]">
              Size: {formatFileSize(file.size)}
            </span>
          </div>
        </div>
        <div className="flex gap-4">
          <div>
            {error && <div>{error}</div>}

            {progress && progress !== 100 && (
              <div>
                <div className="w-[150px]">
                  <span
                    className={`h-[6px] rounded-[4px] bg-[green] w-[${progress}%] block`}
                  ></span>
                </div>
                {progress}
              </div>
            )}
            {success && (
              <div className="text-[12px] text-[gray]">
                {getTimeReadableFormat(success)}
              </div>
            )}
          </div>
          <div className="gap-2 flex">
            <button type="button">
              <VisibilityIcon />
            </button>
            {removeFile && (
              <button
                type="button"
                onClick={() => {
                  deleteFile();
                }}
              >
                <DeleteIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFile;
