import { gql } from "@apollo/client";

export const GET_FLIGHT_PAGES = gql`
  query MyQuery {
    def_flights_pages {
      id
      destination_iata
      destination_name
      featured_image
      origin_iata
      origin_name
      slug
      data {
        content
        heading
        hero_section_image
        summary
        tip_1_description
        tip_1_heading
        tip_2_description
        tip_2_heading
        tip_3_description
        tip_3_heading
        tip_4_description
        tip_4_heading
      }
    }
  }
`;
