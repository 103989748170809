export default function RoleCategory({
  setRole,
  role,
  setDropdown,
  roles = ["user", "consultant", "admin", "accountant"],
}: any) {
  return (
    <div>
      <div className="mb-4 text-primary dark:text-dark-primary  w-[80%] mt-[20px]">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium flex-col w-full"
          id="default-tab"
          data-tabs-toggle="#default-tab-content"
          role="tablist"
        >
          {roles.map((singleRole: any) => (
            <li className="me-2 w-full" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg w-full text-left capitalize ${
                  role === singleRole ? " text-basic border-basic" : ""
                }`}
                onClick={() => {
                  setDropdown(null);
                  setRole(singleRole);
                }}
                id="profile-tab"
                data-tabs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                {singleRole}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
