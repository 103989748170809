import SidePopup from "~/components/common/SidePopup";

import AddServices from "./components/AddServices";
import ShowServices from "./components/ShowServices";
import { useParams } from "react-router-dom";

const Services = () => {

  const { brd_id } = useParams()

  return (
    <>
      <ShowServices brdId={brd_id} />
      {/* <SidePopup component={<AddServices />} /> */}
    </>
  );
};

export default Services;
