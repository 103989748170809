import AddMainCaseStudy from "./component/AddMainCaseStudy";
import ShowSEOMainCaseStudy from "./component/ShowSEOMainCaseStudy";

const MainCaseStudy = () => {
  return (
    <div className=" mt-5  rounded-lg shadow  text-primary dark:text-dark-primary">
      <AddMainCaseStudy />
      <ShowSEOMainCaseStudy />
    </div>
  );
};

export default MainCaseStudy;
