import { Divider, TextField } from "@mui/material";

const WeightDetail = ({ register, errors, name, defData }: any) => {
  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-3">
        <TextField
          sx={{ marginTop: "10px" }}
          error={errors[`${name}.handCary`] ? true : false}
          {...register(`${name}.handCary`, {
            required: true,
          })}
          label="Hand Carry (KG)"
          type="number"
          defaultValue={defData?.handCary}
        />
        <TextField
          sx={{ marginTop: "10px" }}
          error={errors[`${name}.luggage`] ? true : false}
          {...register(`${name}.luggage`, {
            required: true,
          })}
          label="Luggage (KG)"
          type="number"
          defaultValue={defData?.luggage}
        />
      </div>
    </>
  );
};

export default WeightDetail;
