import { Autocomplete, Box, FormLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

import _airportsData from "../../../../FormInputs/LocationBox/data/airports.json";
import _airlineData from "../../../../FormInputs/AirlineBox/data/airlines.json";

import { useState } from "react";

const Form = ({
  register,
  errors,
  field1,
  field2,
  field3,
  field4,
  field5,
  control,
}: any) => {
  const dataFunction = () => {
    const data = field2?.options?.find(
      (option: any) =>
        option?.value?.toLowerCase() === field2?.defaultValue?.toLowerCase()
    );
    return data;
  };

  const data = dataFunction(); // Call the function to get the actual value
  const index = data ? field2?.options?.indexOf(data) : null; // Check if data is not null/undefined

  const [category, setCategory] = useState(field2?.options?.[index]);

  return (
    <div className="grid grid-cols-1 mt-10 gap-5">
      <TextField
        {...register(field1?.name, { required: true })}
        error={errors[field1?.name] ? true : false}
        label={field1?.label}
        type="text"
        defaultValue={field1?.defaultValue}
      />
      {field2?.name === "rating" ? (
        <TextField
          {...register(field2?.name, { required: true })}
          error={errors[field2?.name] ? true : false}
          label={field2?.label}
          type="number"
          defaultValue={field2?.defaultValue}
        />
      ) : (
        field2?.name === "category" && (
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register(field2?.name, { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field?.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.title);
                  setCategory(data);
                }}
                value={category}
                options={field2?.options}
                getOptionLabel={(option: any) => option?.title}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label={field2?.label}
                    ref={field?.ref}
                    inputRef={(el) => {
                      field?.ref(el);
                    }}
                    error={errors[field2?.name] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
        )
      )}

      <Controller
        control={control}
        {...register(field3?.name)}
        render={({ field }: any) => (
          <Autocomplete
            {...field}
            disablePortal
            ref={field.ref}
            onChange={(_, data: any) => {
              field.onChange(data);
            }}
            filterOptions={(options, { inputValue }: any) => {
              // If input is empty, don't show any suggestions
              if (!inputValue.trim()) {
                return [];
              }

              // Filter options to only include those that start with the user's input
              const inputValueLowerCase = inputValue.toLowerCase();
              return options.filter(
                (option) =>
                  option.city_name
                    .toLowerCase()
                    .startsWith(inputValueLowerCase) ||
                  option.iata_code.toLowerCase().startsWith(inputValueLowerCase)
              );
            }}
            options={_airportsData ? _airportsData : []}
            getOptionLabel={(option: any) =>
              `${option.city_name || ""}-${option.iata_code}`
            }
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.city_name}-{option.iata_code}
              </Box>
            )}
            defaultValue={() => {
              const data = _airportsData?.find(
                (option: any) => option.iata_code === field3?.defaultValue
              );
              field.onChange(data);
              return data;
            }}
            renderInput={(params) => (
              <TextField
                label={field3?.label}
                ref={field.ref}
                inputRef={(el) => {
                  field.ref(el);
                }}
                {...params}
              />
            )}
            noOptionsText=""
          />
        )}
      />
      <Controller
        control={control}
        {...register(field4?.name)}
        render={({ field }: any) => (
          <Autocomplete
            {...field}
            disablePortal
            ref={field.ref}
            onChange={(_, data: any) => {
              field.onChange(data);
            }}
            filterOptions={(options, { inputValue }: any) => {
              // If input is empty, don't show any suggestions
              if (!inputValue.trim()) {
                return [];
              }

              // Filter options to only include those that start with the user's input
              const inputValueLowerCase = inputValue.toLowerCase();
              return options.filter((option: any) =>
                option.marketing_name
                  .toLowerCase()
                  .startsWith(inputValueLowerCase)
              );
            }}
            options={_airlineData ? _airlineData : []}
            getOptionLabel={(option: any) => option.marketing_name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.marketing_name}
              </Box>
            )}
            defaultValue={() => {
              const data = _airlineData?.find(
                (option: any) => option.iata === field4?.defaultValue
              );
              field.onChange(data);
              return data;
            }}
            renderInput={(params) => (
              <TextField
                label={field4?.label}
                ref={field.ref}
                inputRef={(el) => {
                  field.ref(el);
                }}
                {...params}
              />
            )}
            noOptionsText=""
          />
        )}
      />
      <div>
        <FormLabel>{field5?.label}</FormLabel>
        <textarea
          {...register(field5?.name, { required: true })}
          className={`${
            errors[field5?.name]
              ? "border-red-600 border bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-40"
              : "bg-transparent border rounded w-full border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-40 hover:border-black focus:outline-basic"
          }`}
          defaultValue={field5?.defaultValue}
        />
      </div>
    </div>
  );
};

export default Form;
