import DashboardLogo from "../../../../assets/svg/DsahboardLogo.svg";
import DashboardLogoWhite from "../../../../assets/svg/DashboardLogoWhite.svg";
import LoginImage from "../../../../assets/img/login_screen.png";
import loginImageDarkMode from "../../../../assets/img/loginScreenDarkMode.png";

import { useState } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useProviderLink, useSignInEmailPasswordless } from "@nhost/react";
import { useApolloClient } from "@apollo/client";
import { GET_USER } from "./graphql/Query";
import { getCurrentUrl } from "~/utils/getCurrentUrl";
import { URLs } from "~/config/enums";

import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { GetCustomUrl } from "~/utils/GetCustomUrl";

const LoginPage = () => {
  const storedData = localStorage.getItem("darkMode");
  const root = document.documentElement;
  if (storedData && JSON.parse(storedData) === true) {
    root.classList.add("dark");
  }

  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  const handleSystemColorChange = (e: any) => {
    if (e.matches) {
      root.classList.add("dark");
      localStorage.setItem("darkMode", JSON.stringify(true));
      window.dispatchEvent(new Event("storage"));
    } else {
      root.classList.remove("dark");
      localStorage.setItem("darkMode", JSON.stringify(false));
      window.dispatchEvent(new Event("storage"));
    }
  };
  mediaQuery.addEventListener("change", handleSystemColorChange);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [searchParams] = useSearchParams();
  const { apple } = useProviderLink();

  const client = useApolloClient();
  const { signInEmailPasswordless } = useSignInEmailPasswordless();

  // Display Error Toast
  if (searchParams.get("error")) {
    toast.error(
      `${searchParams.get("error")?.replace("-", " ")}: ${searchParams.get(
        "errorDescription"
      )}`
    );
    searchParams.delete("error");
    searchParams.delete("errorDescription");
  }

  const customUrl = GetCustomUrl(window.location.host);

  // Submit form function
  const onSubmit = async (e: any) => {
    e.preventDefault(); // prevent
    setLoading(true); // set loading state true
    const { data, error } = await client.query({
      query: GET_USER,
      variables: { email, custom_url: customUrl },
    });

    if (data?.thp_list?.length > 0) {
      const res: any = await signInEmailPasswordless(email, {
        redirectTo: getCurrentUrl() + "/verify",
      });
      if (res.isSuccess) {
        toast.success("A magic link has been sent to your email address");
      } else if (res.isError) {
        toast.error(res?.error?.message);
      } else {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("No user found");
    }
    if (error) {
      toast.error(`Error in the query ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex h-[100vh]">
        <div className="w-full shadow bg-primary dark:bg-dark-primary relative hidden lg:flex flex-col">
          <img
            className="w-[170px] ml-8 mt-8"
            alt="Dashboard Logo"
            src={
              storedData && JSON.parse(storedData) === true
                ? DashboardLogoWhite
                : DashboardLogo
            }
          />
          <div className="flex items-center justify-center px-8">
            <img
              className="w-[750px] z-10 mt-20 xl:mt-28"
              alt="Dashboard Logo"
              src={
                storedData && JSON.parse(storedData) === true
                  ? loginImageDarkMode
                  : LoginImage
              }
            />
          </div>
          <div className="absolute w-full h-[40vh] xl:h-[50vh] bottom-0 bg-[#EFF0F5] dark:bg-[#322b55a5] rounded-tl-[90%]"></div>
        </div>
        <div className="lg:w-[560px] w-full shadow text-primary dark:text-dark-primary bg-white dark:bg-dark-secondary flex justify-center flex-col gap-y-12 p-[3rem]">
          <img
            className="flex lg:hidden absolute w-[170px] left-8 top-8"
            alt="Dashboard Logo"
            src={
              storedData && JSON.parse(storedData) === true
                ? DashboardLogoWhite
                : DashboardLogo
            }
          />
          <div>
            <h1 className="font-[600] text-[1.8rem]">Welcome to Gogency</h1>
            <p className="font-[400] text-[0.90rem] mt-3">
              Please sign-in to your account and start the adventure
            </p>
          </div>
          <form onSubmit={onSubmit} className="flex flex-col">
            <TextField
              id="email"
              label="Enter your email"
              type="email"
              fullWidth
              inputProps={{
                autoComplete: "off",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              sx={{ marginTop: "35px" }}
              variant="contained"
            >
              {loading ? "Sending..." : "Send Email"}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
