import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_FLIGHT_PAGE_CONTENT } from "./graphql/Mutation";
import { toast } from "react-toastify";
import ContentEditor from "~/components/common/ContentEditor";
import { Button } from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";

const DestinationPageContent = ({ page }: any) => {
  const [content, setContent] = useState(page?.page_content || "");
  const [secondaryContent, setSecondaryContent] = useState(
    page?.secondary_page_content || ""
  );

  const [updatePageContent] = useMutation(UPDATE_FLIGHT_PAGE_CONTENT);
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    const res = await updatePageContent({
      variables: {
        id: { id: page?.id },
        data: { page_content: content },
      },
    });

    if (res?.data?.update_brd_flights_pages_by_pk?.id) {
      toast.success("Content updated successfully");
      await client.resetStore();
    } else {
      toast.error("Someting went wrong");
    }
  };
  const submitSecondary = async (inpData: any) => {
    // ;
    const res = await updatePageContent({
      variables: {
        id: { id: page?.id },
        data: { secondary_page_content: secondaryContent },
      },
    });

    if (res?.data?.update_brd_flights_pages_by_pk?.id) {
      toast.success("Content updated successfully");
      await client.resetStore();
    } else {
      toast.error("Someting went wrong");
    }
  };
  return (
    <>
      <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  sm:p-6 ">
        <h3 className="mb-4 text-xl font-semibold ">Page Content</h3>
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-full ck-editor relative">
              <ContentEditor
                key={"primary"}
                content={content}
                setContent={setContent}
                id={"t1"}
              />
              <AutoFixHighIcon
                color="primary"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "70px",
                  right: "20px",
                }}
                onClick={async () => {
                  setLoading(true);
                  setContent("Generating...");
                  const res = await getAiData(
                    `Write a best SEO opimized Content For Destination Page Travelling Website: Proper formatted: with proper spacing and headings: : in innerHTML.`
                  );
                  setLoading(false);
                  setContent(res);
                }}
              />
            </div>
            <div className="col-span-6 sm:col-full">
              <Button type="submit" variant="contained">
                Save Content
              </Button>
              {/* <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"></button> */}
            </div>
          </div>
        </form>
      </div>

      <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  sm:p-6 ">
        <h3 className="mb-4 text-xl font-semibold ">Secondary Page Content</h3>
        <form onSubmit={handleSubmit(submitSecondary)} action="#">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-full ck-editor relative">
              <ContentEditor
                key="secondary"
                content={secondaryContent}
                setContent={setSecondaryContent}
                id={"t2"}
              />
              <AutoFixHighIcon
                color="primary"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "70px",
                  right: "20px",
                }}
                onClick={async () => {
                  setLoading(true);
                  setSecondaryContent("Generating...");
                  const res = await getAiData(
                    "Write the best SEO optimized content for a secondary page of a traveling website: in innerHTML"
                  );
                  setLoading(false);
                  setSecondaryContent(res);
                  await typingEffect(setContent, res);
                }}
              />
            </div>
            <div className="col-span-6 sm:col-full">
              <Button type="submit" variant="contained">
                Save Content
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DestinationPageContent;
