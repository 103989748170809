import { gql } from "@apollo/client";

export const INSERT_BRD_UNIVERSITY = gql`
  mutation InsertUniversity($universityName: String!, $brdId: uuid!) {
    insert_brd_universities(objects: { name: $universityName, brd_id: $brdId }) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_WITH_ID = gql`
  mutation DeleteUniversityWithId($uniId: uuid!) {
    delete_brd_universities(where: { id: { _eq: $uniId } }) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_WITH_ID = gql`
  mutation UpdateUniversity($universityId: uuid!, $name: String!) {
    update_brd_universities(
      where: { id: { _eq: $universityId } }
      _set: { name: $name }
    ) {
      affected_rows
    }
  }
`;

