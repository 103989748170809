import { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const PopUpModal = ({ setShowModal, showModal, children }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div
      className={`flex flex-col transition-all ease-in-out duration-300  ${
        showModal ? "h-full" : "h-0"
      }`}
    >
      <CloseOutlinedIcon
        sx={{
          marginLeft: "20px",
          marginTop: "20px",
          color: darkMode ? "white" : "black",
          cursor: "pointer",
        }}
        onClick={() => setShowModal(false)}
      />
      {children}
    </div>
  );
};

export default PopUpModal;
