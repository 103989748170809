import { gql } from "@apollo/client";

export const GET_POSTS = gql`
  query GetPosts($brd_id: uuid!) {
    brd_posts(where: { brd_id: { _eq: $brd_id } }) {
      id
      brd_id
      author_id
      slug
      post_title
      featured_image
      content
      created_at
      updated_at
      show_in_flight_page
      country
    }
  }
`;
