import { gql } from "@apollo/client";

export const GET_BRD_SEASONAL_FARES = gql`
  query getBrdSeasonalFares($brdId: uuid!) {
    brd_seasonal_fares(where: { brd_id: { _eq: $brdId } }) {
      id
      stops
      fares
      destination
      departure
      airline
      type
      brd_season {
        start_date
        end_date
        name
      }
    }
  }
`;

export const GET_BRD_SEASONAL_FARES_BY_ID = gql`
  query getBrdSeasonalFares($id: uuid!) {
    brd_seasonal_fares(where: { id: { _eq: $id } }) {
      stops
      fares
      destination
      departure
      airline
      type
      brd_season_id
      brd_season {
        start_date
        end_date
        name
      }
    }
  }
`;
