import { GET_TIKTOK_USER_INFO } from "~/graphql/get_tiktok_userInfo/Query";

export const getTikTokProfileData = async (accessToken: any, client: any) => {
  const res = await client.query({
    query: GET_TIKTOK_USER_INFO,
    variables: { access_token: accessToken },
  });

  const profile_picture =
    res?.data?.get_tiktok_userInfo?.data?.user?.avatar_url;
  const display_name = res?.data?.get_tiktok_userInfo?.data?.user?.display_name;
  const profile_id = res?.data?.get_tiktok_userInfo?.data?.user?.open_id;

  return {
    profile_picture,
    display_name,
    profile_id,
  };
};
