import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { UPDATE_CONV_CONSULTANT } from "~/graphql/brd_whatsapp_conversations/Mutation";

const ConsultantRow = ({
  item,
  consultantID,
  convID,
  setConsultantID,
}: any) => {
  const [updateConsultantID] = useMutation(UPDATE_CONV_CONSULTANT);

  const [loading, setLoading] = useState(false);

  const updateConsultant = async () => {
    setLoading(true);
    if (item.id === consultantID) {
      const res = await updateConsultantID({
        variables: {
          id: convID,
          ConsID: null,
          type: "conversation",
        },
      });
      if (res?.data?.update_brd_whatsapp_conversations?.affected_rows) {
        setConsultantID(null);
        toast.success("Consultant is UnAssigned");
        setLoading(false);
      }
    } else {
      const res = await updateConsultantID({
        variables: {
          id: convID,
          ConsID: item.id,
          type: "assigned",
        },
      });
      if (res?.data?.update_brd_whatsapp_conversations?.affected_rows) {
        setConsultantID(item.id);
        toast.success("Consultant is Assigned");
        setLoading(false);
      }
    }
  };

  return (
    <div
      onClick={updateConsultant}
      className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer"
    >
      <div className="py-[13px] px-[20px] border-b dark:border-dark-secondary w-full">
        <div className="flex items-center justify-between">
          <h1 className="text-[16px] text-black dark:text-white font-bold">
            {item.displayName}
          </h1>
        </div>
        <div className="flex items-center justify-between mt-1">
          <p className="text-[12px] dark:text-dark-primary text-right">Email</p>
          <p className="text-[12px] text-green-600 text-right">
            {item.id === consultantID
              ? loading
                ? "UnAssigning"
                : "Assigned"
              : loading && "Assigning"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsultantRow;
