import React from "react";
import { useParams } from "react-router-dom";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import CustomerTimeline from "~/modules/BrandModule/modules/CustomerModule/segments/CustomerTimeline";

export default function CustomerTimelinePage() {
  const { brd_id, user_id } = useParams();
  return (
    <div>
      <Layout>
        <BrandModule>
          <CustomerTimeline brd_id={brd_id} user_id={user_id} />
          {/* <InAppAlertsTemplates brd_id={brd_id} /> */}
        </BrandModule>
      </Layout>
    </div>
  );
}
