import { TextField, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Button, Paper } from "@mui/material"
import useBrdUniCoursesData from "~/hooks/useBrdUniCoursesData"
import { DELETE_UNIVERSITY_COURSE_WITH_ID } from "~/graphql/brd_uni_courses/Mutation"
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { useState } from "react"
import SideDrawer from "~/components/common/sideDrawer"
import EditCourse from "../EditCourse"
import { GET_FILTERED_UNIVERSITY_COURSES } from "~/graphql/brd_uni_courses/Query"
import { useEffect } from "react"
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import ConfirmPopup from "../ConfirmPopup"

const CoursesData = ({ uniId }: any) => {

    const { brdUniCoursesData } = useBrdUniCoursesData(uniId)
    const [deleteUniCourse] = useMutation(DELETE_UNIVERSITY_COURSE_WITH_ID)
    const client = useApolloClient()
    const [selectedCourse, setSelectedCourse] = useState<any>(null)
    const [openPopup, setOpenPopup] = useState(false)
    const [courseName, setCourseName] = useState("");
    const [courseIntake, setCourseIntake] = useState("");
    const [courseType, setCourseType] = useState("");
    const [searchCourses, { data: searchResults }] = useLazyQuery(GET_FILTERED_UNIVERSITY_COURSES, { fetchPolicy: 'network-only' });
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [courseToDelete, setCourseToDelete] = useState(null);

    const handleDeleteUniversityCourse = async (course_id: any) => {
        setConfirmPopup(true)
        setCourseToDelete(course_id)

    }

    const handleEditUniversityCourse = (course: any) => {
        setSelectedCourse(course);
        setOpenPopup(true);
    }

    const confirmCourseDelete = async () => {
        if (courseToDelete) {
            const res = await deleteUniCourse({
                variables: {
                    courseId: courseToDelete,
                }
            })

            if (res?.data?.delete_brd_university_courses?.affected_rows > 0) {
                toast.success("University Course Deleted Successfully!")
                client.refetchQueries({
                    include: "all"
                })
            }
            setConfirmPopup(false);
            setCourseToDelete(null);
        }
    };

    useEffect(() => {
        if (courseName || courseIntake || courseType) {
            searchCourses({
                variables: {
                    universityId: uniId,
                    courseName: courseName ? `%${courseName}%` : "",
                    intake: courseIntake ? `%${courseIntake}%` : "",
                    courseType: courseType ? `%${courseType}%` : ""
                },
            });;
        }
    }, [courseName, courseIntake, courseType]);

    const displayedCourses = courseName || courseIntake || courseType ? (searchResults?.brd_university_courses || []) : (brdUniCoursesData?.brd_universities?.[0]?.brd_uni_courses || []);

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold dark:text-dark-primary">{brdUniCoursesData?.brd_universities?.[0]?.name}</h1>
                <div className="flex space-x-3">
                    <TextField
                        id="search-course"
                        label="Search Course"
                        variant="outlined"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                    />
                    <TextField
                        id="search-intake"
                        label="Search Intake"
                        variant="outlined"
                        value={courseIntake}
                        onChange={(e) => setCourseIntake(e.target.value)}
                    />
                    <TextField
                        id="search-courseType"
                        label="Search Type"
                        variant="outlined"
                        value={courseType}
                        onChange={(e) => setCourseType(e.target.value)}
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Course Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Tuition Fees</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Intake</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedCourses.map((course: any) => (
                            <TableRow key={course.id}>
                                <TableCell>{course.name}</TableCell>
                                <TableCell>{course.course_type}</TableCell>
                                <TableCell>{course.tuition_fees}</TableCell>
                                <TableCell>{course.duration}</TableCell>
                                <TableCell>{course.intake}</TableCell>
                                <TableCell>
                                    <div className="flex gap-5 justify-end">
                                        <FaEdit
                                            size={18}
                                            onClick={() => handleEditUniversityCourse(course)}
                                            className="cursor-pointer"
                                        />
                                        <FaTrash
                                            size={18}
                                            onClick={() => handleDeleteUniversityCourse(course.id)}
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {openPopup && (
                <SideDrawer
                    component={
                        <EditCourse
                            selectedCourse={selectedCourse}
                            setSidePopup={setOpenPopup}
                        />
                    }
                    sidePopup={openPopup}
                    setSidePopup={setOpenPopup}
                />
            )}
            {confirmPopup && (
                <ConfirmPopup confirmPopup={confirmPopup} setConfirmPopup={setConfirmPopup} onConfirm={confirmCourseDelete} />
            )}
        </div>
    )
}

export default CoursesData