import React, { useEffect } from "react";
import Layout from "~/components/common/Layout";
import DashboardModule from "~/modules/DashboardModule";
import DashboardStats from "~/modules/DashboardModule/segments/DashboardStats";

export default function Dashboard() {
  return (
    <div>
      <Layout>
        <DashboardModule>
          <DashboardStats />
        </DashboardModule>
      </Layout>
    </div>
  );
}
