import { TeamsModuleProps } from "./Interface";
import BreadCrumbs from "./components/BreadCrumbs";

function BrandModule({ children }: TeamsModuleProps) {
  return (
    <div>
      <BreadCrumbs />
      {children}
    </div>
  );
}

export default BrandModule;
