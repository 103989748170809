import SidePopup from "~/components/common/SidePopup";
import AddUniversity from "./components/AddUniversity";
import UniversitiesData from "./components/UniversitiesData";
import { useParams } from "react-router-dom";

const Universities = () => {

  const { brd_id } = useParams();

  return (
    <>
      <UniversitiesData brdId={brd_id} />
      <SidePopup component={<AddUniversity brdId={brd_id} />} />
    </>
  );
};

export default Universities;
