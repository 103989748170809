import { useMutation } from "@apollo/client";
import { MenuItem } from "@mui/material";
import { useUserDefaultRole } from "@nhost/react";
import { toast } from "react-toastify";
import { UPDATE_WHATSAPP_MESSAGE_STATUS } from "~/graphql/brd_whatsapp_messages_status/Mutation";

const MenuItems = ({
  message,
  setShowReplyMessage,
  setShowReactions,
  setShowMenu,
  setShowMessageInfoBar,
}: any) => {
  const userRole = useUserDefaultRole();

  const [messageStatusUpdate] = useMutation(UPDATE_WHATSAPP_MESSAGE_STATUS);

  const unReadMessage = async () => {
    const res = await messageStatusUpdate({
      variables: {
        id: message?.brd_whatsapp_messages_statuses?.[0]?.id,
        data: {
          key: "delivered",
        },
      },
    });
    if (res?.data?.update_brd_whatsapp_messages_status?.affected_rows) {
      toast.success("Message Status Updated to Unread");
    }
  };

  return (
    <>
      <div className="shadow py-[9px] ">
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowReplyMessage(message);
            setShowMenu(false);
          }}
        >
          Reply
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowReactions(true);
            setShowMenu(false);
          }}
        >
          React
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMessageInfoBar(message);
            setShowMenu(false);
          }}
        >
          Message Info
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Forward
        </MenuItem>
        {message?.brd_whatsapp_messages_statuses?.[0]?.key === "read" &&
          userRole === "admin" && (
            <MenuItem
              onClick={() => {
                unReadMessage();
                setShowMenu(false);
              }}
              sx={{
                paddingRight: "58px",
                paddingLeft: "24px",
                fontSize: "15px",
              }}
            >
              Unread
            </MenuItem>
          )}
      </div>
    </>
  );
};

export default MenuItems;
