import React from "react";
import { useForm } from "react-hook-form";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";

const CalendarBoxPopUp = ({ isOpen, onClose, onSubmit }: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({});

  // If isOpen is false, do not render the popup
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/70"
      style={{ zIndex: 50 }}
    >
      <div className="relative bg-white dark:bg-dark-primary text-primary dark:text-dark-primary rounded-lg shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times; {/* Close icon */}
        </button>

        <h2 className="text-lg font-semibold mb-4">Select a Date</h2>
        <CalendarBox
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="invoiceDueDate"
          label="Invoice Due Date"
          required={true}
          inputClasses="text-primary dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic"
          labelClasses="text-primary dark:text-dark-primary"
        />

        <div className="flex justify-end mt-6">
          <button
            className="block border border-basic font-medium rounded mx-7 px-6 py-1 cursor-pointer text-white dark:hover:bg-dark-primary hover:text-basic bg-basic hover:bg-white"
            onClick={handleSubmit((data) => onSubmit(data.invoiceDueDate))} // Call onSubmit prop with selected date
          >
            OK
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarBoxPopUp;
