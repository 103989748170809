import React, { useEffect, useState } from "react";
import Authenticate from "../../components/Authenticate";
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
  SEND_EMAIL,
} from "./graphql/Query";
import { GET_ALIASES } from "~/components/UserCard/components/UserAdd/graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import ShowEmailsNew from "../../components/ShowEmailsNew";
import Loader from "~/components/common/loader/Loader";
import { useUserData } from "@nhost/react";
import { Button } from "@mui/material";

export default function Inbox({ defaultEmail }: any) {
  const user: any = useUserData();
  const client = useApolloClient();
  const [reloading, setReloading] = useState(false);
  const [loadingNew, setLoading] = useState(false);
  const { travelHouse, setTravelHouse }: any = travelHouseStore();
  const [selectedAlias, setSelectedAlias] = useState<string | null>(null);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [emails, setEmails] = useState<any>(null);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [page, setPage] = useState(0);

  const [getEmails, { data: newEmailsData, loading: newEmailsLoading }] =
    useLazyQuery(GET_EMAILS);

  useEffect(() => {
    if (user.defaultRole !== "admin" && user?.metadata?.alias) {
      setSelectedAlias(user?.metadata?.alias);
    }
  }, [user]);

  const loadMoreEmails = () => {
    if (nextPageToken) {
      getEmails({
        variables: {
          [variableName]: tokenToUse,
          th_id: travelHouse.id,
          page: page.toString(),
          nextPageToken: nextPageToken,
          pageSize: "50",
          body: "",
          sendEmail: false,
          subject: "",
          to: "",
          getNewEmailsFlag: false,
          historyId: "",
          aliasDisplayName: "",
          aliasEmail: selectedAlias,
          createAlias: false,
          from: "",
          getAlias: false,
          alias: selectedAlias !== null,
          replyFlag: false,
          messageId: "",
          query: false,
          queryData: "",
        },
      });
      setPage(page + 1);
    }
  };

  const loadPreviousEmails = () => {
    if (page > 1) {
      getEmails({
        variables: {
          [variableName]: tokenToUse,
          th_id: travelHouse.id,
          page: (page - 2).toString(),
          pageSize: "50",
          body: "",
          nextPageToken: "",
          sendEmail: false,
          subject: "",
          to: "",
          getNewEmailsFlag: false,
          historyId: "",
          aliasDisplayName: "",
          aliasEmail: "",
          createAlias: false,
          from: "",
          getAlias: false,
          alias: false,
          replyFlag: false,
          messageId: "",
          query: false,
          queryData: "",
        },
      });
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (newEmailsData?.getGmailMessages) {
      setEmails((prevEmails: any) => {
        return {
          ...prevEmails,
          getGmailMessages: {
            ...prevEmails.getGmailMessages,
            emails: {
              ...prevEmails.getGmailMessages.emails,
              messages: [
                ...prevEmails.getGmailMessages.emails.messages,
                ...newEmailsData.getGmailMessages.emails.messages,
              ],
            },
          },
        };
      });
      setNextPageToken(newEmailsData.getGmailMessages.nextPageToken);
    }
  }, [newEmailsData]);

  const handleAuthenticationCode = async (code: string) => {
    await refetchTHP();
    setAuthCode(code);
  };
  const resetSendLoading = () => {
    return true;
  };

  function isValidEmail(email: any) {
    // Regular expression to validate email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSearchEmail = async (data: any) => {
    setLoading(true);
    let queryData;
    if (isValidEmail(data.search)) {
      queryData = `from:${data.search} OR to:${data.search}`;
    } else {
      queryData = `subject:${data.search}`;
    }

    const response = await client.query({
      query: GET_EMAILS,
      variables: {
        [variableName]: tokenToUse,
        th_id: travelHouse.id,
        pageSize: "50",
        page: "",
        body: "",
        nextPageToken: "",
        sendEmail: false,
        subject: "",
        to: "",
        getNewEmailsFlag: false,
        historyId: "",
        aliasDisplayName: "",
        aliasEmail: "",
        createAlias: false,
        from: "",
        getAlias: false,
        alias: false,
        replyFlag: false,
        messageId: "",
        query: true,
        queryData: queryData, // Set queryData based on the condition
      },
    });
    if (response?.data?.getGmailMessages) {
      setEmails(response?.data);
      setNextPageToken(response?.data?.getGmailMessages?.nextPageToken);
    }
    setLoading(false);
  };

  const handleSendEmail = async (data: any) => {
    try {
      let replyFlag = false;
      let messageId = null;

      if (data && data.messageId !== null) {
        replyFlag = true;
        messageId = data.messageId;
      }

      const response = await client.query({
        query: SEND_EMAIL,
        variables: {
          [variableName]: tokenToUse,
          sendEmail: !replyFlag, // Set sendEmail to true if it's not a reply
          replyFlag: replyFlag,
          messageId: messageId,
          to: data?.to,
          subject: data?.subject,
          body: data?.body,
          from: data?.from,
        },
      });

      // Refetch the GET_EMAILS query after sending the email
      const result = await client.refetchQueries({
        include: [GET_EMAILS],
      });
      setEmails(result[0]?.data);
    } catch (error) {
      console.error("Error sending and/or refetching emails:", error);
    } finally {
      resetSendLoading(); // Reset the sendLoading state in ShowEmails
    }
  };

  // Query to get the travel house data
  const {
    loading: travelHouseLoading,
    data: travelHouseData,
    refetch: refetchTHP,
  } = useQuery(GET_TRAVEL_HOUSE_BY_ID, {
    variables: { id: travelHouse?.id },
    fetchPolicy: "network-only",
  });

  const { data: getAliasData, loading: getAliasLoading } = useQuery(
    GET_ALIASES,
    {
      variables: {
        refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
        th_id: travelHouse.id,
        page: "",
        nextPageToken: "",
        pageSize: "1",
        body: "",
        sendEmail: false,
        subject: "",
        to: "",
        getNewEmailsFlag: false,
        historyId: "",
        aliasDisplayName: "",
        aliasEmail: "",
        createAlias: false,
        getAlias: true,
        from: "",
        alias: false,
        replyFlag: false,
        messageId: "",
        query: false,
        queryData: "",
      },
    }
  );
  const tokenToUse =
    travelHouseData?.thp_list[0]?.email_refresh_token || authCode;
  const variableName = travelHouseData?.thp_list[0]?.email_refresh_token
    ? "refresh_token"
    : "code";

  // Query to get the emails based on the refresh token or code
  const { loading, data, error } = useQuery(GET_EMAILS, {
    variables: {
      [variableName]: tokenToUse,
      th_id: travelHouse.id,
      pageSize: "50",
      page: "",
      body: "",
      nextPageToken: "",
      sendEmail: false,
      subject: "",
      to: "",
      getNewEmailsFlag: false,
      historyId: "",
      aliasDisplayName: "",
      aliasEmail: selectedAlias,
      createAlias: false,
      from: "",
      getAlias: false,
      alias: selectedAlias !== null,
      replyFlag: false,
      messageId: "",
      query: defaultEmail ? true : false,
      queryData: defaultEmail
        ? `from:${defaultEmail} OR to:${defaultEmail}`
        : "",
    },
    skip: !tokenToUse, // Skip if both authCode and refresh_token are null
  });

  useEffect(() => {
    if (travelHouseData?.thp_list[0]) {
      setTravelHouse(travelHouseData?.thp_list[0]);
    }
  }, [travelHouseData, setTravelHouse]);

  useEffect(() => {
    if (data?.getGmailMessages) {
      setEmails(data);
      setNextPageToken(data.getGmailMessages.nextPageToken);
    }
  }, [data]);

  const handleReloadClick = async () => {
    try {
      setReloading(true);

      // Refetch the GET_EMAILS query
      const result = await client.refetchQueries({
        include: [GET_EMAILS],
      });

      if (result && result.length > 0) {
        for (let res of result) {
          if (
            res?.data?.getGmailMessages?.emails &&
            res.data.getGmailMessages.emails.length > 0
          ) {
            setEmails(res.data);
            break;
          }
        }
      }

      setReloading(false);
    } catch (error) {
      setReloading(false);
      console.error("Error refetching emails:", error);
    }
  };

  const handleAliasChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLoading(true);
    const newSelectedAlias = event.target.value;
    setSelectedAlias(newSelectedAlias === "all" ? null : newSelectedAlias);
    const selectedAlias = event.target.value;
    if (selectedAlias !== "all") {
      const response = await client.query({
        query: GET_EMAILS,
        variables: {
          [variableName]: tokenToUse,
          th_id: travelHouse.id,
          page: page.toString(),
          nextPageToken: nextPageToken,
          pageSize: "50",
          body: "",
          sendEmail: false,
          subject: "",
          to: "",
          getNewEmailsFlag: false,
          historyId: "",
          aliasDisplayName: "",
          createAlias: false,
          from: "",
          getAlias: false,
          alias: true,
          aliasEmail: selectedAlias,
          replyFlag: false,
          messageId: "",
          query: false,
          queryData: "",
        },
      });
      if (response?.data?.getGmailMessages) {
        setEmails(response?.data);
        setNextPageToken(response?.data?.getGmailMessages?.nextPageToken);
      }
      setLoading(false);
    } else {
      setLoading(true);
      const response = await client.query({
        query: GET_EMAILS,
        variables: {
          [variableName]: tokenToUse,
          th_id: travelHouse.id,
          pageSize: "50",
          page: "",
          body: "",
          nextPageToken: "",
          sendEmail: false,
          subject: "",
          to: "",
          getNewEmailsFlag: false,
          historyId: "",
          aliasDisplayName: "",
          aliasEmail: "",
          createAlias: false,
          from: "",
          getAlias: false,
          alias: false,
          replyFlag: false,
          messageId: "",
          query: false,
          queryData: "",
        },
      });
      if (response?.data?.getGmailMessages) {
        setEmails(response?.data);
        setNextPageToken(response?.data?.getGmailMessages?.nextPageToken);
      }
      setLoading(false);
    }
  };

  async function showSentEmails() {
    setLoading(true);

    const response = await client.query({
      query: GET_EMAILS,
      variables: {
        [variableName]: tokenToUse,
        th_id: travelHouse.id,
        pageSize: "50",
        page: "",
        body: "",
        nextPageToken: "",
        sendEmail: false,
        subject: "",
        to: "",
        getNewEmailsFlag: false,
        historyId: "",
        aliasDisplayName: "",
        aliasEmail: selectedAlias,
        createAlias: false,
        from: "",
        getAlias: false,
        alias: selectedAlias !== null,
        replyFlag: false,
        messageId: "",
        query: true,
        queryData: "label:SENT", // Set queryData based on the condition
      },
    });
    if (response?.data?.getGmailMessages) {
      setEmails(response?.data);
      setNextPageToken(response?.data?.getGmailMessages?.nextPageToken);
    }
    setLoading(false);
  }

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     handleReloadClick();
  //   }, 10000);
  //   return () => clearInterval(intervalId);
  // }, []);

  if (loading || travelHouseLoading) return <Loader width="200px" />;
  if (error) return <div>Error in the query {error.message}</div>;

  const aliases = [{ sendAsEmail: user?.metadata?.alias }];

  return (
    <>
      {emails ? (
        <>
          <div className="flex justify-between items-center">
            {user.defaultRole === "admin" &&
              getAliasData?.getGmailMessages?.aliases.length !== 1 &&
              (getAliasLoading ? (
                <Loader width="200px" />
              ) : (
                <select
                  onChange={handleAliasChange}
                  className="bg-primary dark:bg-dark-primary mr-[10px] mb-4 w-[25%] text-primary dark:text-dark-primary border-none mt-5 text-sm rounded-lg "
                  value={selectedAlias || "all"} // Bind the value to selectedAlias
                >
                  <option value="all">All Aliases</option>
                  {getAliasData?.getGmailMessages?.aliases?.map(
                    (email: any) => (
                      <option key={email.sendAsEmail} value={email.sendAsEmail}>
                        {email.sendAsEmail}
                      </option>
                    )
                  )}
                </select>
              ))}
            <Button onClick={showSentEmails} variant="contained">
              Sent Emails
            </Button>
            <Button
              sx={{ marginLeft: "auto" }}
              onClick={handleReloadClick}
              variant="contained"
            >
              {reloading ? "Reloading..." : "Reload Emails"}
            </Button>
          </div>

          {/* <button onClick={handleReloadClick}>
            {reloading ? "Reloading" : "Reload Emails"}
          </button> */}
          {/* <ShowEmails
            emails={emails}
            loadMoreEmails={loadMoreEmails}
            loadPreviousEmails={loadPreviousEmails}
            isPreviousDisabled={page === 1}
            isLoading={newEmailsLoading}
            handleSendEmail={handleSendEmail}
            resetSendLoading={resetSendLoading}
          /> */}
          {loadingNew ? (
            <Loader width="200px" />
          ) : (
            <ShowEmailsNew
              aliases={
                user.defaultRole !== "admin" && user?.metadata?.alias
                  ? aliases
                  : getAliasData?.getGmailMessages?.aliases
              }
              emails={emails}
              loadMoreEmails={loadMoreEmails}
              nextPageToken={nextPageToken}
              loadPreviousEmails={loadPreviousEmails}
              isPreviousDisabled={page === 1}
              isLoading={newEmailsLoading}
              handleSendEmail={handleSendEmail}
              handleSearchEmail={handleSearchEmail}
              resetSendLoading={resetSendLoading}
            />
          )}
        </>
      ) : (
        <Authenticate onCodeReceived={handleAuthenticationCode} />
      )}
    </>
  );
}
