import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import InsertBrand from "~/modules/BrandModule/segments/InsertBrand";

const AddBrandPage = () => {
  return (
    <Layout>
      <BrandModule>
        <InsertBrand />
      </BrandModule>
    </Layout>
  );
};

export default AddBrandPage;
