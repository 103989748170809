import React, { useState } from "react";
import { useUserData } from "@nhost/react";
import UserCard from "~/components/UserCard";
import { useQuery } from "@apollo/client";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import SidePopup from "~/components/common/SidePopup";
import SingleMessageDetail from "./components/SingleMessageDetail";
import SidePopupBar from "~/components/common/SidePopup/components/SidePopupBar";
import { Drawer } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function SingleMessage({ message, user_email, token }: any) {
  const [sidePopup, setSidePopup] = useState(false);
  const files = Object.values(message?.files);

  return (
    <>
      <div
        onClick={() => {
          setSidePopup(true);
        }}
        className={`flex items-center w-full my-1 ${
          user_email === message.from ? "justify-start" : "justify-end"
        }`}
      >
        <div className="md:max-w-[80%] relative flex items-center">
          <div
            className={`w-full rounded-lg shadow px-1 pt-1 ${
              user_email === message.from
                ? "bg-whatsappSecondaryBg dark:bg-dark-primary dark:text-white"
                : "bg-basic text-white"
            }`}
          >
            <div className="pl-2 px-1 flex flex-col ">
              <h1 className="text-[16px] font-bold pt-1">{message.subject}</h1>
              <p
                className="text-[14px] text-wrap font-medium pt-1"
                style={{ wordWrap: "break-word" }}
                dangerouslySetInnerHTML={{ __html: message.content }}
              />
              {/* <div>{message.content}</div> */}
              <div
                className={`flex justify-between w-full  ${
                  user_email === message.from
                    ? "text-primary dark:text-dark-primary"
                    : "text-dark-primary"
                }`}
              >
                <p className="text-[11px] w-full ">
                  {files?.length > 0 && `${files?.length} Attachments`}
                </p>
                <p className="text-[11px] w-full flex justify-end">
                  {getTimeReadableFormat(message.timestamp)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sidePopup && (
        <Drawer anchor="right" open={sidePopup}>
          <CancelIcon
            onClick={() => setSidePopup(!sidePopup)}
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
          />
          <div className="w-[750px] p-[40px]">
            <SingleMessageDetail
              message={message}
              token={token}
              user_email={user_email}
            />
          </div>
        </Drawer>
        // <SidePopupBar setShow={setSidePopup} width="250px">
        //   <SingleMessageDetail message={message} token={token} />
        // </SidePopupBar>
      )}
    </>
  );
}
