import { gql } from "@apollo/client";

export const ADD_INVOICE = gql`
  mutation MyMutation($invoice: [inq_invoices_insert_input!]!) {
    insert_inq_invoices(objects: $invoice) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $invoice_id: uuid, $status: String!) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { invoice_id: $invoice_id, status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;
