import { Button } from "@mui/material";
import Bar from "../Bar";
import { useState } from "react";

export default function TimeBar({ inquiries }: any) {
  const [hour, setHour] = useState<any>(1);

  const uniqueConsultantSet: any = new Set(
    inquiries?.map((item: any) => item?.picked_user?.id || null)
  );

  const uniqueConsultants: any[] = [...uniqueConsultantSet];

  const consultantData: any = [];
  let totalCount: number = 0;

  inquiries.map((inq: any) => {});
  for (let j: any = 0; j <= 23; j = j + hour) {
    const i = j;

    const nextHour = i + hour;
    const prevHour = nextHour - hour;

    const count = {
      Inquires: inquiries?.filter(
        (inq: any) =>
          new Date(inq?.created_at).getHours() >= prevHour &&
          new Date(inq?.created_at).getHours() < nextHour
      )?.length,
      //   follow_up: inquiries?.filter(
      //     (inq: any) =>
      //       inq?.status === "follow_up" &&
      //       new Date(inq?.created_at).getHours() === i
      //   )?.length,
      //   not_interested: inquiries?.filter(
      //     (inq: any) =>
      //       inq?.status === "not_interested" &&
      //       new Date(inq?.created_at).getHours() === i
      //   )?.length,
      //   inprogress: inquiries?.filter(
      //     (inq: any) =>
      //       inq?.status === "inprogress" &&
      //       new Date(inq?.created_at).getHours() === i
      //   )?.length,
      //   completed: inquiries?.filter(
      //     (inq: any) =>
      //       inq?.status === "completed" &&
      //       new Date(inq?.created_at).getHours() === i
      //   )?.length,
    };

    totalCount += count.Inquires;

    consultantData.push({
      ...count,
      month: `${prevHour.toString().length < 2 ? "0" : ""}${prevHour} to ${
        nextHour.toString().length < 2 ? "0" : ""
      }${nextHour === 24 ? "00" : nextHour}`,
    });
  }

  return (
    <>
      <div className="pt-4 w-full border-b-[1px] border-[#ccc] flex justify-between">
        <h2>Time Comparison - {totalCount}</h2>
      </div>
      <div className="flex w-full justify-end ">
        <div className="flex gap-2 mt-2">
          <Button
            onClick={() => {
              setHour(1);
            }}
            variant={hour === 1 ? "contained" : "outlined"}
            size="small"
          >
            1h
          </Button>
          <Button
            onClick={() => {
              setHour(3);
            }}
            variant={hour === 3 ? "contained" : "outlined"}
            size="small"
          >
            3h
          </Button>
          <Button
            onClick={() => {
              setHour(8);
            }}
            variant={hour === 8 ? "contained" : "outlined"}
            size="small"
          >
            8h
          </Button>
        </div>
      </div>
      {inquiries.length > 0 ? (
        <Bar
          dataset={consultantData}
          keysArr={[{ dataKey: "Inquires", label: "Inquires" }]}
        />
      ) : (
        <div className="w-full flex items-center justify-center h-[100%]">
          <span>No record found!</span>
        </div>
      )}
    </>
  );
}
