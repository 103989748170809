import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchBar from "../../../SearchBar";
import { Avatar, Button } from "@mui/material";
import SettingOptions from "./component/SettingOptions";
import AutoReply from "./component/AutoReply";
import { useState } from "react";

const Settings = ({ showSettingsBar, setShowSettingsBar, type }: any) => {
  const [showAutoReplyBar, setShowAutoReplyBar] = useState(false);

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showSettingsBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] ${
            type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
          }`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowSettingsBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Settings</p>
          </div>
        </div>
        <SearchBar searchType="contacts" />
        <div className="flex items-center px-[16px] py-[8px] gap-[16px]">
          <Avatar
            sx={{ width: "70px", height: "70px" }}
            alt="whatsappAvator"
            src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
          />
          <div>
            <h1 className="text-[16px] text-black dark:text-white">M Usman</h1>
            <p className="text-[14px] text-primary dark:text-dark-primary">
              Web Developer || Javascript || React JS
            </p>
          </div>
        </div>
        <SettingOptions setShowAutoReplyBar={setShowAutoReplyBar} />
      </div>
      <AutoReply
        setShowAutoReplyBar={setShowAutoReplyBar}
        showAutoReplyBar={showAutoReplyBar}
        type={type}
      />
    </>
  );
};

export default Settings;
