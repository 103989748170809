import { useMutation } from "@apollo/client";
import Authenticate from "~/modules/InboxModule/components/Authenticate";
import { UPDATE_BRAND } from "../../graphql/Mutation";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";

const GoogleAuthenticate = ({ brand }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("code");
  // const { token } = useParams();
  const navigate = useNavigate();
  const role = useUserDefaultRole();
  const [isLoading, setIsloading] = useState(false);
  const handleAuthenticationCode = async (code: string) => {};

  const [updateBrand] = useMutation(UPDATE_BRAND);

  useEffect(() => {
    setIsloading(false);
  }, [brand?.active_email_service]);

  const updateToken = async (token: any) => {
    await updateBrand({
      variables: {
        brd_id: brand?.id,
        data: { gmail_refresh_token: token },
      },
    });
    navigate(`/${role}/brands/${brand.id}/email-setting`);
  };

  // const unAuth = async () => {};

  useEffect(() => {
    if (token) {
      updateToken(token);
    }
  }, [token]);

  return (
    <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  sm:p-6 w-full">
      <div className="flex justify-between">
        <h3 className="mb-4 text-xl font-semibold ">Gmail Authenticate</h3>
        <div>
          {brand?.active_email_service === "gmail" ? (
            <span className="text-[green] font-bold">Activated</span>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={async () => {
                  setIsloading(true);
                  await updateBrand({
                    variables: {
                      brd_id: brand?.id,
                      data: { active_email_service: "gmail" },
                    },
                  });
                }}
              >
                {isLoading ? "Activating.." : "Active"}
              </Button>
            </>
          )}
        </div>
      </div>

      {!brand?.gmail_refresh_token ? (
        <Authenticate onCodeReceived={handleAuthenticationCode} />
      ) : (
        <Button
          onClick={() => {
            updateToken(null);
          }}
          variant="outlined"
        >
          UnAuthenticate
        </Button>
      )}
    </div>
  );
};

export default GoogleAuthenticate;
