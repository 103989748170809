import { MenuItem } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import GifBoxOutlinedIcon from "@mui/icons-material/GifBoxOutlined";
import { MdOutlineAudiotrack } from "react-icons/md";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import ArticleIcon from "@mui/icons-material/Article";
import AudioFileIcon from "@mui/icons-material/AudioFile";

const Add = ({
  setAttachmentType,
  setFiles,
  setShowMenu,
  setShowModal,
  footerType,
}: any) => {
  const handleLogoUpload = async (event: any) => {
    const files = event.target.files;
    // ;
    uploadFiles(files);
    setShowMenu(false);
  };
  const uploadFiles = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      let file = files?.[i];
      file["id"] = generateRandom10DigitCode();
      setFiles((prev: any) => [...prev, file]);
    }
  };
  return (
    <>
      <div className="py-[9px] px-1 text-[16px]">
        <input
          style={{ opacity: 0, position: "absolute" }}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-[#ccc] dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="hero_image_help"
          id="uploadFile"
          type="file"
          multiple={footerType !== "autoReply"}
          onChange={handleLogoUpload}
        />
        <MenuItem
          sx={{ paddingLeft: "8px", paddingRight: "32px" }}
          onClick={() => {
            setAttachmentType("document");
          }}
        >
          <label htmlFor="uploadFile" className="flex ">
            <DescriptionOutlinedIcon sx={{ color: "purple" }} />
            <p className="ml-[12px]">Document</p>
          </label>
        </MenuItem>
        <MenuItem
          sx={{ paddingLeft: "8px", paddingRight: "32px" }}
          onClick={() => {
            setAttachmentType("image");
          }}
        >
          <label htmlFor="uploadFile" className="flex ">
            <CollectionsOutlinedIcon sx={{ color: "blue" }} />
            <p className="ml-[12px]">Photos</p>{" "}
          </label>
        </MenuItem>
        <MenuItem
          sx={{ paddingLeft: "8px", paddingRight: "32px" }}
          onClick={() => {
            setAttachmentType("video");
          }}
        >
          <label htmlFor="uploadFile" className="flex ">
            <CameraAltOutlinedIcon sx={{ color: "purple" }} />
            <p className="ml-[12px]">Videos</p>
          </label>
        </MenuItem>
        <MenuItem
          sx={{ paddingLeft: "8px", paddingRight: "32px" }}
          onClick={() => {
            setAttachmentType("audio");
          }}
        >
          <label htmlFor="uploadFile" className="flex ">
            {/* <MdOutlineAudiotrack /> */}
            <AudioFileIcon />
            <p className="ml-[12px]">Audios</p>{" "}
          </label>
        </MenuItem>
        {footerType !== "autoReply" && (
          <MenuItem
            sx={{ paddingLeft: "8px", paddingRight: "32px" }}
            onClick={() => {
              setShowModal(true);
              setAttachmentType("templates");
            }}
          >
            <ArticleIcon />
            <p className="ml-[12px]">Templates</p>
          </MenuItem>
        )}
      </div>
    </>
  );
};

export default Add;
