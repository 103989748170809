import React, { useEffect, useState } from "react";
// import EditFAQs from "./components/EditTestimonial";
// import { GET_FLIGHTS_PAGES } from "./graphql/Query";
import { NavLink, useParams } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
// import AddTestimonials from "./components/AddTestimonial";
// import TestimonialRow from "./components/DestinationRow";
// import DestinationRow from "./components/DestinationRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle } from "react-icons/fa";
import SidePopup from "~/components/common/SidePopup";
import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import AddAds from "./components/AddAds";
import { GET } from "~/graphql/brd_google_ads/Query";

// import DestinationSettings from "./components/DestinationSettings";

const Ads = () => {
  const { campaign_id } = useParams();
  const [rows, setRows] = useState<any>([]);
  // const [settings, setSettings] = useState(false);
  // const [addTsm, setAddTsm] = useState(false);
  const { loading, data, error } = useSubscription(GET, {
    variables: {
      campaign_id: campaign_id,
    },
  });
  const columns: GridColDef[] = [
    { field: "sr", headerName: "SR.", width: 90 },
    {
      field: "id",
      headerName: "Ad Id",
      // type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: "name",
      headerName: "Ad Name",
      width: 150,
      editable: true,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      editable: true,
    },
    {
      field: "end_date",
      headerName: "Stop Date",
      width: 150,
      editable: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 90,
      editable: true,
    },
    {
      field: "click",
      headerName: "Click",
      width: 90,
      editable: true,
    },
    {
      field: "impression",
      headerName: "impression",
      width: 90,
      editable: true,
    },
    {
      field: "cpc",
      headerName: "CPC",
      width: 90,
      editable: true,
    },
    {
      field: "cpi",
      headerName: "CPI",
      width: 90,
      editable: true,
    },
    {
      field: "cpb",
      headerName: "CPB",
      width: 90,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
      renderCell: (params: any) => (
        <NavLink to={`/admin/google-campaigns/${campaign_id}/${params.row.id}`}>
          <Button variant="contained">Detail</Button>
        </NavLink>
      ),
    },
  ];

  useEffect(() => {
    if (data?.brd_google_ads?.length > 0) {
      let dataRows: any = [];
      data.brd_google_ads.map((item: any, index: number) => {
        dataRows.push({
          id: item.id,
          sr: index + 1,
          name: item.name,
          start_date: item.start_date,
          end_date: item.end_date || "N/A",
          cost: item.brd_google_ad_updates_aggregate.aggregate.sum.cost,
          impression:
            item.brd_google_ad_updates_aggregate.aggregate.sum.impression,
          click: item.brd_google_ad_updates_aggregate.aggregate.sum.click,
          cpc: (
            item.brd_google_ad_updates_aggregate.aggregate.sum.cost /
            item.brd_google_ad_updates_aggregate.aggregate.sum.click
          ).toFixed(2),
          cpi: 0,
          cpb: 0,
        });
      });
      setRows([...dataRows]);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>error...{error.message}</p>;
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5  ">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-primary dark:text-dark-primary sm:text-2xl ">
              All Ads
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <Box sx={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <SidePopup
        component={<AddAds campaign_id={campaign_id} />}
        text="Add Ad"
      />
    </>
  );
};

export default Ads;
