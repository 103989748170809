import { useState } from "react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Popover } from "@mui/material";
import Reactions from "../Reactions";
import MenuItems from "../TextMessage/component/MenuItems";
import VideocamIcon from "@mui/icons-material/Videocam";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplyMessage from "../../../ReplyMessage";
import { URLs } from "~/config/enums";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import MessageStatus from "../MessageStatus";
import DialogueBox from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Messages/component/DialogueBox";

const ImageMessage = ({
  messageType,
  type,
  setShowReplyMessage,
  video,
  message,
  setShowMessageInfoBar,
  scrollToMessage,
}: any) => {
  const [showAdditional, setShowAdditional] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showReaction, setShowReactions] = useState(false);
  const [reactionEmoji, setReactionEmoji] = useState("");
  const [showDialogueBox, setShowDialogueBox] = useState(false);

  return (
    <>
      <div
        id={message?.id}
        className={`flex items-center w-full py-[2px] px-[60px] ${
          reactionEmoji.length > 0 && "mb-6"
        } ${type === "recieve" ? "justify-start" : "justify-end"}`}
        onMouseEnter={() => setShowAdditional(true)}
        onMouseLeave={() => setShowAdditional(false)}
      >
        <div className="md:max-w-[70%] relative flex items-center">
          <div
            className={`rounded-lg shadow p-1 cursor-pointer ${
              type === "recieve"
                ? "bg-whatsappSecondaryBg dark:bg-dark-primary"
                : "bg-whatsappMessagebg"
            }`}
          >
            {messageType === "messageReply" && (
              <ReplyMessage
                scrollToMessage={scrollToMessage}
                setShowDialogueBox={setShowDialogueBox}
                replyType="messageReply"
                message={message?.reply}
                type={type}
              />
            )}
            <div
              className={`flex items-center ${
                messageType === "messageReply" && "mt-1"
              }`}
            >
              <div
                className="relative max-w-[280px] max-h-[350px] overflow-hidden rounded-lg"
                onClick={() => {
                  setShowDialogueBox(true);
                }}
              >
                {video ? (
                  <>
                    <video className="rounded-lg ">
                      <source
                        src={URLs.FILE_URL + message.text}
                        type="video/mp4"
                      />
                    </video>
                    <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                      <div className="bg-black/35 rounded-full p-2">
                        <PlayArrowIcon
                          sx={{ color: "#FFFFFF" }}
                          fontSize="large"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <img
                    src={URLs.FILE_URL + message.text}
                    className="w-full h-full object-cover"
                  />
                )}
                {messageType !== "autoReply" && (
                  <div
                    className={`absolute bottom-0 left-0 px-3 pt-1 flex items-center w-full text-[11px] text-white bg-black/10 rounded-b-lg shadow-2xl ${
                      video ? "justify-between" : "justify-end"
                    }`}
                  >
                    {/* {video && (
                  <div className="flex items-center">
                    <VideocamIcon
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        margin: 0,
                        padding: 0,
                      }}
                    />
                    <p className="ml-1">1:06</p>
                  </div>
                )} */}
                    {getTimeReadableFormat(message.timestamp)}{" "}
                    {type === "send" && `(${message?.sender?.displayName})`}{" "}
                    {!(type === "recieve") && (
                      <MessageStatus message={message} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {showReaction && (
            <Popover
              sx={{
                backgroundColor: "transparent",
                cursor: "pointer",
                "& .MuiPopover-paper": {
                  backgroundColor: "transparent",
                  borderRadius: "30px",
                },
              }}
              anchorEl={anchorEl}
              onClose={() => {
                setShowReactions(!showReaction);
              }}
              open={showReaction}
              anchorOrigin={{
                vertical: "center",
                horizontal: type === "recieve" ? "left" : "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: type === "recieve" ? "left" : "right",
              }}
            >
              <Reactions
                setShowReactions={setShowReactions}
                setReactionEmoji={setReactionEmoji}
              />
            </Popover>
          )}
          {reactionEmoji.length > 0 && (
            <div
              className="absolute right-[18px] bottom-[-20px] bg-whatsappSecondaryBg h-[25px] w-[28px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer text-sm shadow"
              onClick={() => {
                setReactionEmoji("");
              }}
            >
              {reactionEmoji}
            </div>
          )}
          {showAdditional && messageType !== "autoReply" && (
            <>
              <div
                className={`absolute  bg-black/30 h-[22px] w-[22px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer ${
                  type === "recieve" ? " right-[-30px]" : "left-[-30px]"
                }`}
                onClick={(e: any) => {
                  setShowReactions(!showReaction);
                  setAnchorEl(e.currentTarget);
                }}
              >
                <EmojiEmotionsIcon sx={{ color: "white", fontSize: "16px" }} />
              </div>
              <div
                className={`absolute h-[22px] w-[22px] rounded-full  flex items-center justify-center font-bold cursor-pointer ${
                  type === "recieve"
                    ? messageType === "messageReply"
                      ? "top-1 right-1 text-primary "
                      : "top-2 right-2 bg-black/10 text-white"
                    : messageType === "messageReply"
                    ? "top-1 right-1 text-primary"
                    : "top-2 right-2 bg-black/10 text-white"
                }`}
                onClick={(e: any) => {
                  setAnchorEl(e.currentTarget);
                  setShowMenu(!showMenu);
                }}
              >
                <KeyboardArrowDownIcon
                  sx={{ color: "white", fontSize: "25px" }}
                />
              </div>
              <Popover
                sx={{
                  background: "transparent",
                }}
                anchorEl={anchorEl}
                onClose={() => {
                  setShowMenu(!showMenu);
                }}
                open={showMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItems
                  setShowMessageInfoBar={setShowMessageInfoBar}
                  setShowReplyMessage={setShowReplyMessage}
                  setShowReactions={setShowReactions}
                  setShowMenu={setShowMenu}
                  message={message}
                />
              </Popover>
            </>
          )}
        </div>
      </div>
      <DialogueBox
        showDialogueBox={showDialogueBox}
        setShowDialogueBox={setShowDialogueBox}
        video={video}
        setShowReactions={setShowReactions}
        setAnchorEl={setAnchorEl}
        showReaction={showReaction}
        setReactionEmoji={setReactionEmoji}
        reactionEmoji={reactionEmoji}
        message={message}
      />
    </>
  );
};

export default ImageMessage;
