const TemplateRow = ({ template }: any) => {
  return (
    <>
      <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer">
        <div className="py-[13px] px-[20px] border-b dark:border-dark-secondary w-full">
          <div className="flex items-center justify-between">
            <h1 className="text-[16px] text-black dark:text-white">
              {template?.name}
            </h1>
            <p
              className={`text-[12px] ${
                template?.status?.toLowerCase() === "approved" &&
                "text-green-500"
              } ${
                template?.status?.toLowerCase() === "rejected" && "text-red-500"
              } ${
                template?.status?.toLowerCase() === "pending" &&
                "text-orange-500"
              }`}
            >
              {template?.status}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-[12px] dark:text-dark-primary">
              {template?.category}
            </p>
            <p className="text-[12px] dark:text-dark-primary">
              {template?.language}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateRow;
