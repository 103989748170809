import { gql } from "@apollo/client";

export const WHATSAPP_TEMPLATE_CREATE = gql`
  mutation MyMutation($data: whatsapp_templates_create_input!) {
    whatsapp_templates_create(arg1: $data) {
      category
      id
      status
    }
  }
`;
