import { GET_INSTAGRAM_USER_INFO } from "~/graphql/get_ig_profile_info/Query";

export const getInstagramProfileData = async (accessToken: any, client: any) => {
  const res = await client.query({
    query: GET_INSTAGRAM_USER_INFO,
    variables: { accessToken: accessToken },
  });

  const name = res?.data?.get_ig_profile_info?.name
  const profilePictureURL = res?.data?.get_ig_profile_info?.profile_picture_url

  return {
    name: name,
    image: profilePictureURL,
  };
};