import React from "react";
import AddComment from "./components/AddComment";
import CommentList from "./components/CommentsList";

const InquiryComments = ({ inquiry }: any) => {
  return (
    <div>
      <AddComment inquiry={inquiry} />
      <CommentList inquiry={inquiry} />
    </div>
  );
};

export default InquiryComments;
