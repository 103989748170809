import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import { useApolloClient, useMutation, useSubscription } from "@apollo/client";
import useEmail from "~/hooks/emails/useEmail";
import ConfrimPopup from "~/components/common/ConfirmPopup";
import moment from "moment";
import { URLs } from "~/config/enums";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { nhost } from "~/lib/nhost";
import { SEND_EMAIL } from "~/graphql/actions/gmail/Mutation";

import { pdf } from "@react-pdf/renderer";
import {
  UPDATE_INQ_STATUS,
  UPDATE_INQ_UPDATE_USER,
} from "~/graphql/inq_list/Mutation";

import { UPDATE_INQ_INVOICE_PDF } from "~/graphql/inq_invoice_items/Mutation";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

import { UPDATE_THP_HOUSE } from "~/graphql/thp_list/Mutation";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import { ADD_INVOICE, EDIT_INQUIRY } from "../graphql/Mutation";
import NewPDFComponent from "../components/InvoiceCard/components/InvoiceDetail/NewpdfDocument";
import InvoiceCard from "../components/InvoiceCard";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";
import { useForm } from "react-hook-form";
import { Modal } from "@mui/material";
import CalendarBoxPopUp from "~/components/common/CalenderBoxPopup";
import { EDIT_SUGGESTION } from "../../SuggestionCard/components/SuggestionBox/components/EditSuggestion/components/EditSuggestionForm/graphql/Mutation";
import { GET_INVOICE } from "../../../graphql/InvoiceQuery";

export default function NewInvoice({ inquiry, tab, inquiry_id }: any) {
  const [hideForm, setHideForm] = useState(false);
  const [addInvoice] = useMutation(ADD_INVOICE);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVoid, setIsLoadingVoid] = useState(false);
  const client = useApolloClient();
  const [voidConfirm, setVoidConfirm] = useState(false);
  const [generateConfirm, setGenerateConfirm] = useState(false);
  const [runEmailFunc, setRunEmailFunc] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [editSuggestion] = useMutation(EDIT_SUGGESTION);
  const { loading, data, error } = useSubscription(GET_INVOICE, {
    variables: { inquiry_no: inquiry_id },
  });

  const invoice = data?.inq_list?.[0]?.inq_invoice || [];
  const invoices = data?.inq_list[0]?.inq_invoices || [];
  const user_id = useUserId();

  const customUrl = GetCustomUrl(window.location.host);

  const [updateThpHouse] = useMutation(UPDATE_THP_HOUSE);

  const { thpHouse } = useThpListDetail(customUrl);

  const runFunc1 = async () => {};
  // runFunc1();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const [updateInqInvoicePdf] = useMutation(UPDATE_INQ_INVOICE_PDF);

  const emailSend = useEmail();

  const userDefaultRole = useUserDefaultRole();

  const [sendEmail] = useMutation(SEND_EMAIL);
  const [updateInqStatus] = useMutation(UPDATE_INQ_STATUS);

  const handleGenerateClick = () => {
    setIsLoading(true); // Change loading state to true
    setCalendarOpen(true); // Open the calendar popup

    // Simulate a loading process
    setTimeout(() => {
      // You can replace this with your actual generation logic
      setIsLoading(false); // Change loading state back to false after process is complete
    }, 2000); // Adjust time as needed for your generation process
  };
  const handleCalendarOpen = () => {
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000000); // Adjust the range as needed
  }

  const [logo, setLogo] = useState<any>();
  const [signedImage, setSignedImage] = useState<any>();
  const handleDateSubmit = async (date: any) => {
    console.log("Selectsds:", inquiry);
    setIsLoading(true); // Set loading state
    try {
      const due_date = moment(date).format("YYYY-MM-DD"); // Format the date
      setSelectedDate(due_date);
      setGenerateConfirm(true); // Open confirmation popup
      setCalendarOpen(false); // Close calendar popup
    } catch (error) {
      console.error("Error updating suggestion:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };
  async function convertLogo() {
    try {
      const response = await fetch(
        URLs.FILE_URL + inquiry?.brd_list?.brd_details?.[0]?.fav_icon
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  async function convertSignedImage() {
    try {
      const response = await fetch(
        URLs.FILE_URL + invoice?.inq_invoice_items?.[0]?.sign_invoice
      );
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignedImage(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  useEffect(() => {
    if (invoice?.inq_invoice_items?.[0]?.sign_invoice) {
      convertSignedImage();
    }
  }, [invoice?.inq_invoice_items?.[0]?.sign_invoice]);

  useEffect(() => {
    if (inquiry?.brd_list?.brd_details?.[0]?.fav_icon) {
      convertLogo();
    }
  }, [inquiry?.brd_list?.brd_details?.[0]?.fav_icon]);

  const sendEmailtoUser = async () => {
    try {
      const res = await sendEmail({
        variables: {
          data: {
            toEmails: [inquiry?.users?.email],
            subject: "Sign Your Invoice",
            content: `Sign Your inovoice <a target="_blank" href="https://www.${inquiry?.brd_list?.domain}/sign-invoice/${inquiry?.id}">Click here to Sign Invoice</a>`,
            from: "admin@etravelo.co.uk",
            refresh_token: inquiry?.brd_list?.gmail_refresh_token,
            attachments: [],
            iata: "No iata",
          },
        },
      });
      if (res?.data?.sendEmail?.status) {
        toast.success("Invoice Successfully Send to Email");
        await updateInqStatus({
          variables: {
            invoiceId: inquiry?.invoice_id,
          },
        });
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const sendInvoiceToEmail = () => {
    try {
      sendEmailtoUser();
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const uploadPdfToNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({
        file,
      });

      if (response.error) {
        console.error("Upload error:", response.error.message);
        throw new Error(response.error.message);
      }

      return response.fileMetadata.id;
    } catch (error) {
      console.error("Failed to upload PDF:", error);
      throw error;
    }
  };

  const updatePDF = async () => {
    const blob = await pdf(
      <NewPDFComponent
        inquiry={inquiry}
        invoice={invoice}
        logo={logo}
        signedImage={signedImage}
      />
    ).toBlob();

    // Step 2: Convert Blob to File
    const file = new File([blob], "invoice.pdf", {
      type: "application/pdf",
    });

    const pdfId = await uploadPdfToNhost(file);

    await updateInqInvoicePdf({
      variables: {
        invoiceId: inquiry?.invoice_id,
        pdfId: pdfId,
      },
    });
  };

  useEffect(() => {
    if (runEmailFunc) {
      // sendInvoiceToEmail();
      updatePDF();
    }
  }, [inquiry?.invoice_id]);

  const generateInvoice = async () => {
    const invoiceSuggestion = [];

    if (inquiry?.car_inq_details?.length > 0) {
      const carSuggestion = inquiry.car_inq_details[0].selected_car_suggestion;

      if (carSuggestion?.car_inq_suggestion_costs) {
        invoiceSuggestion.push({
          suggestion_id: inquiry.car_inq_details[0].selected_car_suggestion_id,
          type: "car",
          sales: carSuggestion.car_inq_suggestion_costs.reduce(
            (sum: number, obj: any) => sum + parseFloat(obj.sale_price || 0),
            0
          ),
        });
      }
    }

    if (inquiry?.stay_inq_details?.length > 0) {
      const staySuggestion =
        inquiry.stay_inq_details[0].selected_stay_suggestion;

      if (staySuggestion?.stay_inq_suggestion_costs) {
        invoiceSuggestion.push({
          suggestion_id:
            inquiry.stay_inq_details[0].selected_stay_suggestion_id,
          type: "stay",
          sales: staySuggestion.stay_inq_suggestion_costs.reduce(
            (sum: number, obj: any) => sum + parseFloat(obj.sale_price || 0),
            0
          ),
        });
      }
    }

    if (inquiry?.train_inq_details?.length > 0) {
      const trainSuggestion =
        inquiry.train_inq_details[0].selected_train_suggestion;

      if (trainSuggestion?.train_inq_suggestion_costs) {
        invoiceSuggestion.push({
          suggestion_id:
            inquiry.train_inq_details[0].selected_train_suggestion_id,
          type: "train",
          sales: trainSuggestion.train_inq_suggestion_costs.reduce(
            (sum: number, obj: any) => sum + parseFloat(obj.sale_price || 0),
            0
          ),
        });
      }
    }

    if (inquiry?.suggestion_id) {
      const flightSuggestion = inquiry.selected_suggestion;

      if (flightSuggestion?.inq_suggestion_costs) {
        invoiceSuggestion.push({
          suggestion_id: inquiry.suggestion_id,
          type: "flights",
          sales:
            flightSuggestion.inq_suggestion_costs.reduce(
              (sum: number, obj: any) => sum + parseFloat(obj.sale_price || 0),
              0
            ) + parseFloat(inquiry?.selected_suggestion?.bookingFee || 0),
        });
      }
    }

    if (invoiceSuggestion.length === 0) {
      toast.error("No suggestions selected!");
      return; // Exit the function early if no suggestions
    }

    // Use `map` to generate the invoiceItems dynamically
    const invoiceItems = invoiceSuggestion.map((suggestion: any) => ({
      suggestion_id: suggestion.suggestion_id,
      type: suggestion.type,
      // sales: suggestion.sales,
    }));
    const totalAmount = invoiceSuggestion.reduce(
      (sum: number, obj: any) => sum + parseFloat(obj.sales || 0),
      0
    );

    if (inquiry) {
      setIsLoading(true);

      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: user_id },
      });

      const invoice = {
        inquiry_id: inquiry.id,
        invoice_no: `'${generateRandomNumber()}'`,
        due_date: selectedDate || null,
        invoice_suggestions: {
          data: invoiceItems,
        },
        brd_id: inquiry.brd_id,
        suggestion_id: null,
        amount: totalAmount,
      };

      const invoiceRes = await addInvoice({
        variables: { invoice },
      });

      if (invoiceRes.data.insert_inq_invoices.affected_rows > 0) {
        await updateThpHouse({
          variables: {
            custom_url: customUrl,
            user_id: user_id,
            data: {
              invoice_credits: thpHouse[0].invoice_credits - 1,
            },
          },
        });

        const editInquiryRes = await editInquiry({
          variables: {
            id: inquiry.id,
            invoice_id: invoiceRes.data.insert_inq_invoices.returning[0].id,
            status: "awaiting_for_sign",
          },
        });
        const dateIssued = moment(invoices?.created_at).format(
          "DD MMM YYYY hh:mm a"
        );

        await client.resetStore();
        setIsLoading(false);
        toast.success("Invoice generated");
      }
    } else {
      toast.error("Suggestion not selected!");
    }
  };

  const voidInvoice = async () => {
    setIsLoadingVoid(true);

    await updateInqListUser({
      variables: { id: inquiry.id, updated_by: user_id },
    });

    const editInquiryRes = await editInquiry({
      variables: {
        id: inquiry.id,
        invoice_id: null,
        status: "assigned",
      },
    });

    const invoiceAmount = invoice?.inq_invoice_items?.reduce(
      (sum: any, obj: any) => sum + parseFloat(obj.amount),
      0
    );
    const dateIssued: any = moment(invoice?.created_at).format(
      "DD MMM YYYY hh:mm a"
    );

    await client.resetStore();
    setIsLoadingVoid(false);
    toast.success("Invoice void");
  };
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          INVOICES
        </span>
        <hr className="w-full dark:border-[#E7E3FC61]" />
        {(user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
        inquiry?.status !== "refunded" &&
        thpHouse?.[0]?.invoice_credits > 0 &&
        inquiry?.status !== "completed" ? (
          <div className="min-w-fit pl-2">
            {!inquiry.invoice_id ? (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={handleGenerateClick}
                  className="block border border-basic font-medium rounded-full px-3 py-1 text-white hover:text-basic dark:hover:bg-dark-primary cursor-pointer bg-basic hover:bg-white"
                >
                  {isLoading ? "Generating..." : "Generate Invoice"}
                </button>
              </div>
            ) : (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => {
                    setVoidConfirm(true);
                  }}
                  className="block border border-basic font-medium rounded-full px-3 py-1 cursor-pointer text-white dark:hover:bg-dark-primary hover:text-basic bg-basic hover:bg-white"
                >
                  {isLoadingVoid ? "Voiding..." : "Void Invoice"}
                </button>
              </div>
            )}
          </div>
        ) : (
          thpHouse?.[0]?.invoice_credits < 1 && (
            <p className="text-red-500 font-bold">
              You have used all your invoice credits
            </p>
          )
        )}
      </div>

      {voidConfirm && (
        <ConfrimPopup
          closeModal={() => setVoidConfirm(false)}
          confirm={() => {
            setRunEmailFunc(false);
            voidInvoice();
          }}
          headingText="Do you want to void invoice?"
        />
      )}
      {generateConfirm && (
        <ConfrimPopup
          closeModal={() => setGenerateConfirm(false)}
          confirm={() => {
            setRunEmailFunc(true);
            generateInvoice();
          }}
          headingText="Do you want to Generate invoice?"
        />
      )}
      {isCalendarOpen && (
        <CalendarBoxPopUp
          isOpen={isCalendarOpen}
          onClose={handleCalendarClose}
          onSubmit={handleDateSubmit} // Pass onSubmit handler
        />
      )}

      <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 items-start justify-start gap-[30px] text-mini">
        {invoices?.map((invoice: any) => (
          <InvoiceCard
            isActive={inquiry.invoice_id === invoice.id ? true : false}
            invoice={invoice}
            inquiry={inquiry}
            tab={tab}
          />
        ))}
      </div>
    </div>
  );
}
