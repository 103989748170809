import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Filter from "./components/Filters";

const SearchBar = ({
  searchType,
  setSearch,
  showFilters,
  setShowFilters,
  setConversations,
  userRole,
  setSelectedConsultant,
  setErrorMessage,
  setBrandPhones,
  setOffset,
  brdPhones,
  brands,
  dates,
  setDates,
  whatsappLabels,
  setFilterLabel,
}: any) => {
  const [showBackArrow, setShowBackArrow] = useState(false);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div
        className={`py-[8px] bg-whatsappSecondaryBg dark:bg-dark-secondary ${
          searchType === "contacts"
            ? "border-none"
            : "border-b dark:border-dark-secondary"
        }`}
      >
        <div className="px-3 flex items-center ">
          <div className="relative flex items-center w-full">
            {!showBackArrow && (
              <SearchOutlinedIcon
                fontSize="small"
                sx={{
                  position: "absolute",
                  left: "15px",
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                  transition: "all 1s ease-in-out",
                }}
              />
            )}
            {showBackArrow && (
              <ArrowBackOutlinedIcon
                sx={{
                  position: "absolute",
                  left: "15px",
                  color: "#00a884",
                  cursor: "pointer",
                  transition: "all 1s ease-in-out",
                }}
              />
            )}

            <input
              onFocus={() => setShowBackArrow(true)}
              onBlur={() => setShowBackArrow(false)}
              type="text"
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
              placeholder="Search or start new chat"
              className="border-none w-full focus:ring-0 bg-whatsappPrimaryBg dark:text-dark-primary dark:bg-dark-primary shadow rounded h-[35px] pr-[32px] pl-[65px] text-[15px] focus:placeholder:text-transparent"
            />
          </div>
          {!(searchType === "contacts") && (
            <FilterListOutlinedIcon
              onClick={() => setShowFilters(true)}
              sx={{
                paddingLeft: "12px",
                width: "40px",
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      <Filter
        whatsappLabels={whatsappLabels}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        setConversations={setConversations}
        userRole={userRole}
        setSelectedConsultant={setSelectedConsultant}
        setErrorMessage={setErrorMessage}
        setOffset={setOffset}
        dates={dates}
        setDates={setDates}
        setFilterLabel={setFilterLabel}
      />
    </>
  );
};

export default SearchBar;
