import moment from "moment";
import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { splitLocation } from "~/utils/splitLocation";
import vector81 from "../../../images/dottedImg.svg";
import TrainIcon from "@mui/icons-material/Train";
import { getStyles } from "../../../pdfDocument/styles";
import train from "../../../images/train.png";
const TrainInvoicePDF = ({ invoice, inquiry }: any) => {
  const trainSuggestion = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "train"
  )?.[0]?.train_inq_suggestion;

  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(trainSuggestion?.origin);
  const { cityName: destcity, remainingAddress: destAddress } = splitLocation(
    trainSuggestion?.destination
  );

  console.log("Filtered stay suggestions:", trainSuggestion);

  if (!trainSuggestion) return <div>No stay suggestions found</div>;
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  return (
    <View style={styles.inquiryDetailContainer}>
      <View>
        <View style={styles.flightDetailHeader}>
          <Text style={styles.flightTypeText}>Train Invoice</Text>
          <View style={styles.flightCabinContainer}>
            <Text style={styles.flightCabinText}>
              {trainSuggestion?.cabin_class}
            </Text>
          </View>
        </View>
        <View style={styles.flightDetailBody}>
          <View style={styles.flightLegsParentContainer}>
            <View style={styles.flightLegs}>
              <View style={styles.flightLegContainers}>
                <View style={styles.flightLegSubContainers}>
                  <Text style={styles.flightLegArrDep}>{originCity}</Text>
                  <Text>({originAddress})</Text>
                </View>
                <Text style={styles.flightLegDateTime}>
                  {moment(trainSuggestion?.start_date).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                  {moment(trainSuggestion?.time).format("HH:mm")}
                </Text>
              </View>

              <Image style={styles.dottedImg} src={vector81} />
              <View style={styles.flightLegContainerRight}>
                <View style={styles.flightLegSubContainerRight}>
                  <Text style={styles.flightLegArrDep}>{destcity}</Text>
                  <Text>({destAddress})</Text>
                </View>
                <Text style={styles.flightLegDateTime}>
                  {moment(trainSuggestion?.end_date).format("DD-MM-YYYY HH:mm")}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.parallelContainer}>
            <View style={styles.luggageContainer}>
              <View style={styles.luggageSubContainer}>
                <Image style={styles.iconSize} src={train} />
                {/* <TrainIcon fontSize="medium" style={{ color: "black" }} /> */}
                <Text style={styles.luggageText}>
                  {trainSuggestion?.train_name}
                </Text>
              </View>
            </View>
            <View style={styles.flightCabinContainer}>
              {trainSuggestion?.train_inq_suggestion_costs?.map(
                (cost: any, idx: any) => (
                  <Text key={idx} style={styles.flightCabinText}>
                    Sale Price: £ {cost?.sale_price}
                  </Text>
                )
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default TrainInvoicePDF;
