import React from "react";
import Bar from "../Bar";

export default function Consultants({ inquiries }: any) {
  const uniqueConsultantSet: any = new Set(
    inquiries.map((item: any) => item?.picked_user?.id || null)
  );

  const uniqueConsultants: any[] = [...uniqueConsultantSet];

  const consultantData: any = [];

  uniqueConsultants?.map((picked_by_id: any, index: any) => {
    if (picked_by_id) {
      const count = {
        no_answer: inquiries?.filter(
          (inq: any) =>
            inq?.status === "no_answer" && inq?.picked_by === picked_by_id
        )?.length,
        follow_up: inquiries?.filter(
          (inq: any) =>
            inq?.status === "follow_up" && inq?.picked_by === picked_by_id
        )?.length,
        not_interested: inquiries?.filter(
          (inq: any) =>
            inq?.status === "not_interested" && inq?.picked_by === picked_by_id
        )?.length,
        inprocess: inquiries?.filter(
          (inq: any) =>
            inq?.status === "inprocess" && inq?.picked_by === picked_by_id
        )?.length,
        completed: inquiries?.filter(
          (inq: any) =>
            inq?.status === "completed" && inq?.picked_by === picked_by_id
        )?.length,
      };

      const consultant = inquiries?.filter(
        (inq: any) => inq?.picked_by === picked_by_id
      )[0];

      let name = consultant?.picked_user?.thp_users_profile?.[0]?.pseudo_name
        ? consultant?.picked_user?.thp_users_profile?.[0]?.pseudo_name
        : consultant?.picked_user?.displayName;

      name =
        name?.split(" ")?.[0] + " " + name?.split(" ")?.[1]?.charAt(0) + ".";

      consultantData.push({
        ...count,
        month: name,
      });
    }
  });

  return (
    <>
      <div className="pt-4 w-full border-b-[1px] border-[#ccc] flex justify-between">
        <h2>Consultants Progress</h2>
      </div>
      {consultantData.length > 0 ? (
        <Bar
          dataset={consultantData}
          keysArr={[
            { dataKey: "not_interested", label: "Not Interested" },
            { dataKey: "follow_up", label: "Follow Up" },
            { dataKey: "no_answer", label: "No Answer" },
            { dataKey: "inprocess", label: "In Process" },
            { dataKey: "completed", label: "Completed" },
          ]}
        />
      ) : (
        <div className="w-full flex items-center justify-center h-[100%]">
          <span>No record found!</span>
        </div>
      )}
    </>
  );
}
