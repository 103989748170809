import { Button } from "@mui/material";
import React, { useState } from "react";

import AddServicesTips from "./components/addServicesTips";
import SideDrawer from "~/components/common/sideDrawer";
import ShowServicesTips from "./components/ShowServicesTips";

import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate, useParams } from "react-router-dom";

const ServicesTips = () => {
  const { brd_id } = useParams();
  const navigate = useNavigate();

  const [openPopup, setOpenPopup] = useState(false);
  return (
    <>
      <div className="rounded-lg shadow p-5 mb-5 bg-secondary dark:bg-dark-secondary">
        <div className="flex items-center justify-between  mb-8">
          <h1 className="text-2xl text-basic dark:text-dark-primary text-center">
            Add Services Tips
          </h1>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenPopup(true)}
          >
            Add Tip
          </Button>
        </div>
        <ShowServicesTips />
      </div>
      {openPopup && (
        <SideDrawer
          component={<AddServicesTips setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ServicesTips;
