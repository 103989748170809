import { Button } from "@mui/material";
import React, { useState } from "react";

import SideDrawer from "~/components/common/sideDrawer";
import AddServicesFaqs from "./components/AddServicesFaqs";
import ShowServicesFaqs from "./components/ShowServicesFaqs";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate, useParams } from "react-router-dom";

const ServicesFaqs = () => {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <>
      <div className="rounded-lg shadow p-5 mb-5 mt-5 bg-secondary dark:bg-dark-secondary">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl text-basic dark:text-dark-primary ">
            Add FAQS
          </h1>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenPopup(true)}
          >
            Add Faqs
          </Button>
        </div>
        <ShowServicesFaqs />
      </div>
      {openPopup && (
        <SideDrawer
          component={<AddServicesFaqs setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ServicesFaqs;
