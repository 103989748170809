import { useQuery, useSubscription } from "@apollo/client";
import { GET_BRAND_WHATSAPP_LABELS } from "~/graphql/brd_whatsapp_labels/Query";

export default function useBrandWhatsappLabels(brd_ids: any) {
  const { data } = useSubscription(GET_BRAND_WHATSAPP_LABELS, {
    variables: {
      brd_ids: brd_ids,
    },
  });

  return {
    whatsappLabels: data?.brd_whatsapp_labels,
  };
}
