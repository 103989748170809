import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Popover } from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";

const Reactions = ({ setShowReactions, setReactionEmoji }: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEmojis, setShowEmojis] = useState(false);

  const handleEmojiClick = (event: any) => {
    // ;
    setReactionEmoji(event.emoji);
    setShowEmojis(false);
    setShowReactions(false);
  };

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div className="bg-whatsappSecondaryBg dark:bg-dark-primary inline-block py-2 px-3 text-3xl rounded-full z-20">
        <div className="flex items-center gap-3 cursor-pointer">
          <p
            onClick={() => {
              setShowReactions(false);
              setReactionEmoji("👍");
            }}
          >
            👍
          </p>
          <p
            onClick={() => {
              setShowReactions(false);
              setReactionEmoji("❤️");
            }}
          >
            ❤️
          </p>
          <p
            onClick={() => {
              setShowReactions(false);
              setReactionEmoji("😂");
            }}
          >
            😂
          </p>
          <p
            onClick={() => {
              setShowReactions(false);
              setReactionEmoji("😮");
            }}
          >
            😮
          </p>
          <p
            onClick={() => {
              setShowReactions(false);
              setReactionEmoji("😥");
            }}
          >
            😥
          </p>
          <p
            onClick={() => {
              setShowReactions(false);
              setReactionEmoji("🙏");
            }}
          >
            🙏
          </p>
          <p
            className="bg-[#EFEAE2] h-[30px] w-[30px] rounded-full flex items-center justify-center font-bold cursor-pointer shadow"
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setShowEmojis(true);
            }}
          >
            <AddOutlinedIcon sx={{ color: "#3A3541DE", fontSize: "24px" }} />
          </p>
          <Popover
            sx={{
              top: "15px",
              left: "10px",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowEmojis(false);
            }}
            open={showEmojis}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <EmojiPicker
              searchDisabled
              style={{
                backgroundColor: darkMode && "#28183D",
              }}
              onEmojiClick={handleEmojiClick}
            />
          </Popover>
        </div>
      </div>
    </>
  );
};

export default Reactions;
