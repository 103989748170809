import { useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_DEFAULT_SETTINGS } from "./graphql/Query";
import { useParams } from "react-router-dom";
import {
  DELETE_FEATURE,
  INSERT_DEFAULT_SETTING,
  INSERT_FEATURES,
  UPDATED_FEATURES,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import { useFileUpload } from "@nhost/react";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import TipsSectionCard from "~/components/common/BrandTipSection/components/TipsSectionCard";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import { useState } from "react";

const EditFeatureSection = ({ page }: any) => {

  const { brd_id } = useParams();
  const { upload, progress: logoUploadProgress } = useFileUpload();
  const { loading, data: headingsData } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brd_id },
  });
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateFeature] = useMutation(UPDATED_FEATURES);
  const [insertFeature] = useMutation(INSERT_FEATURES);
  const [deleteFeature] = useMutation(DELETE_FEATURE);
  const client = useApolloClient();

  const [featHead, setFeatHead] = useState<any>(page?.features_heading || "");
  const [featSubHead, setFeatSubHead] = useState<any>(
    page?.features_sub_heading || ""
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  if (loading) return <p>Loading...</p>;
  const settings = headingsData?.brd_default_setting?.at(0);
  const features = page?.brd_list?.brd_features;
  const onSubmit = async (inpData: any) => {
    console.log("xwt inpData test =>", inpData);

    // return 0;
    inpData?.tips?.map(async (ft: any) => {
      if (ft?.id) {
        if (ft?.title) {
          const finalObj: any = {
            title: ft?.title,
            content: ft?.content,
          };
          if (ft?.icon?.length > 0) {
            const iconRes = await upload({ file: ft?.icon[0] });
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await updateFeature({
            variables: {
              id: { id: ft.id },
              ftData: finalObj,
            },
          });
        } else if (ft?.title === "" && ft?.content === "") {
          const res = await deleteFeature({
            variables: { id: ft?.id },
          });
        }
      } else {
        if (ft?.title) {
          const finalObj: any = {
            title: ft?.title,
            content: ft?.content,
            brd_id: brd_id,
          };
          if (ft?.icon?.length > 0) {
            // ;
            const iconRes = await upload({ file: ft?.icon[0] });
            // ;
            finalObj["icon_url"] = iconRes?.id || null;
          }

          const res = await insertFeature({
            variables: {
              data: finalObj,
            },
          });
        }
      }
    });
    // ;
    // return 0;
    if (settings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: settings?.id },
          data: {
            features_heading: inpData?.features_heading,
            features_sub_heading: inpData?.features_sub_heading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Features data updated successfully");
        await client.resetStore();
      }
    } else {
      const res = await insertSettings({
        variables: {
          data: {
            features_heading: inpData?.features_heading,
            features_sub_heading: inpData?.features_sub_heading,
            brd_id: brd_id,
          },
        },
      });
      // ;
      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Features data updated successfully");
        await client.resetStore();
      }
    }
  };
  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Our Values Section</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              error={errors["features_heading"] ? true : false}
              {...register("features_heading", { required: true })}
              label="Heading"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo optimized feature Heading for "${page?.brd_list?.def_brand_type?.name}" type, value section 3 to 5 words: unique and without quotes`
                        );
                        await typingEffect(setFeatHead, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={featHead}
              onChange={(e: any) => setFeatHead(e.target.value)}
            />
            <TextField
              error={errors["features_sub_heading"] ? true : false}
              {...register("features_sub_heading", { required: true })}
              label="Sub Heading"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo optimized feature sub heading for ${
                            featHead || page?.brd_list?.def_brand_type?.name
                          } value section 6 to 8 words: unique and without quotes`
                        );
                        await typingEffect(setFeatSubHead, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={featSubHead}
              onChange={(e: any) => setFeatSubHead(e.target.value)}
            />
          </div>
          <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-7">
            <TipsSectionCard
              cardType="featuredCard"
              index={0}
              ft={features[0]}
              register={register}
              setValue={setValue}
              errors={errors}
              page={page}
            />
            <TipsSectionCard
              cardType="featuredCard"
              index={1}
              ft={features[1]}
              register={register}
              setValue={setValue}
              errors={errors}
              page={page}
            />
            <TipsSectionCard
              cardType="featuredCard"
              index={2}
              ft={features[2]}
              register={register}
              setValue={setValue}
              errors={errors}
              page={page}
            />
            <TipsSectionCard
              cardType="featuredCard"
              index={3}
              ft={features[3]}
              register={register}
              setValue={setValue}
              errors={errors}
              page={page}
            />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save Value Section
          </Button>
        </Box>
      </div>
    </>
  );
};

export default EditFeatureSection;
