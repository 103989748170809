import { useApolloClient, useQuery } from "@apollo/client";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { GET_ACC_TRANS_WITH_INQ_NO } from "~/graphql/acc_transactions/Query";
import { GET_ALL_BRANDS_CONS } from "~/graphql/users/Query";
import OverallStats from "./components/OverallStats";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import dayjs from "dayjs";

const LeaderBoard = ({
  brd_ids,
  startDateTime,
  endDateTime,
  setSumOfLeaderBoard,
}: any) => {
  const client = useApolloClient();

  const { data } = useQuery(GET_ALL_BRANDS_CONS, {
    variables: {
      brd_ids,
    },
  });

  const [canProfitArr, setCanProfitArr] = useState<any>([]);

  const cancellationProfit = (inq: Array<{ id: string }>, userId: string) => {
    const inqIds = inq
      .filter((item: any) => {
        const completedDate = moment(item.completed_date).toISOString();
        return (
          item.status === "refunded" &&
          completedDate >= startDateTime &&
          completedDate <= endDateTime
        );
      })
      .map((item) => item.id);

    client
      .query({
        query: GET_ACC_TRANS_WITH_INQ_NO,
        variables: {
          inq_id: inqIds,
        },
      })
      .then((res: any) => {
        if (!res?.data?.acc_transactions) {
          console.error("No transactions found");
          return;
        }

        const creditAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "credit"
            )
            .reduce((sum, trans) => sum - trans.amount, 0);
        };

        const debitAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "debit"
            )
            .reduce((sum, trans) => sum + trans.amount, 0);
        };

        const typesToSum = ["income", "expense"];

        const credits = creditAmount(res.data.acc_transactions, typesToSum);
        const debits = debitAmount(res.data.acc_transactions, typesToSum);

        const amount = credits - debits;

        setCanProfitArr(
          (prevArr: Array<{ userId: string; amount: number }>) => [
            ...prevArr,
            { userId: userId, amount: amount },
          ]
        );
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });

    return;
  };

  const [comProfitArr, setComProfitArr] = useState<any>([]);

  const completedProfit = (inq: Array<{ id: string }>, userId: string) => {
    const inqIds = inq
      .filter((item: any) => {
        const completedDate = moment(item.completed_date).toISOString();
        return (
          item.status === "completed" &&
          completedDate >= startDateTime &&
          completedDate <= endDateTime
        );
      })
      .map((item) => item.id);

    client
      .query({
        query: GET_ACC_TRANS_WITH_INQ_NO,
        variables: {
          inq_id: inqIds,
        },
      })
      .then((res: any) => {
        if (!res?.data?.acc_transactions) {
          console.error("No transactions found");
          return;
        }

        const creditAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "credit"
            )
            .reduce((sum, trans) => sum - trans.amount, 0);
        };

        const debitAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "debit"
            )
            .reduce((sum, trans) => sum + trans.amount, 0);
        };

        const typesToSum = ["income", "expense"];

        const credits = creditAmount(res.data.acc_transactions, typesToSum);
        const debits = debitAmount(res.data.acc_transactions, typesToSum);

        const amount = credits - debits;

        setComProfitArr(
          (prevArr: Array<{ userId: string; amount: number }>) => [
            ...prevArr,
            { userId: userId, amount: amount },
          ]
        );
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });

    return;
  };

  useEffect(() => {
    if (data?.users) {
      data.users.forEach((user: any) => {
        completedProfit(user?.picked_inqs, user?.id);

        cancellationProfit(user?.picked_inqs, user?.id);
      });
    }
  }, [data, brd_ids, startDateTime, endDateTime]);

  const dueBalance = (inq: any) => {
    const filteredInqs = inq?.filter(
      (inq: any) =>
        inq.created_at >= startDateTime &&
        inq.created_at <= endDateTime &&
        inq?.inq_tickets?.some((ticket: any) => ticket?.status === "issued")
    );

    const sumOfDueBalances = filteredInqs?.reduce(
      (totalSum: number, inq: any) => {
        const totalInvoiceAmount =
          inq?.inq_invoice?.inq_invoice_items?.reduce(
            (total: number, invoice: any) => total + invoice.amount,
            0
          ) + inq?.selected_suggestion?.bookingFee;

        const totalPaidAmount = inq?.inq_transections
          ?.filter((transaction: any) =>
            ["paid", "partiallyRefunded"].includes(transaction.status)
          )
          ?.map((transaction: any) =>
            transaction.status === "partiallyRefunded"
              ? transaction.amount - (transaction.refund_amount || 0)
              : transaction.amount
          )
          ?.reduce((sum: number, amount: number) => sum + amount, 0);

        const dueBalance = totalInvoiceAmount - totalPaidAmount;

        return totalSum + dueBalance;
      },
      0
    );

    return sumOfDueBalances || 0;
  };

  const userId = useUserId();
  const userRole = useUserDefaultRole();

  const rows = data?.users
    ? [
        ...data?.users
          ?.filter((item: any) =>
            userRole === "consultant" ? item.id === userId : item.id !== 0
          )
          ?.map((user: any, index: any) => ({
            id: index + 1,
            user: user,
            name: user?.thp_users_profile?.[0]?.pseudo_name,
            totalPending:
              user?.picked_inqs?.filter(
                (inq: any) =>
                  inq?.status === "inprocess" &&
                  inq.created_at >= startDateTime &&
                  inq.created_at <= endDateTime &&
                  inq?.inq_transections?.some(
                    (transaction: any) =>
                      transaction?.status === "paid" ||
                      transaction?.status === "partiallyRefunded"
                  )
              )?.length || 0,
            inquiries: user?.picked_inqs.filter(
              (inq: any) =>
                inq.created_at >= startDateTime && inq.created_at <= endDateTime
            )?.length,
            todayBookings:
              user?.picked_inqs?.filter(
                (inq: any) =>
                  inq?.status === "inprocess" &&
                  inq?.inq_transections?.some(
                    (transaction: any) =>
                      moment(transaction?.updated_at).format("YYYY-MM-DD") ===
                        moment(new Date()).format("YYYY-MM-DD") &&
                      (transaction?.status === "paid" ||
                        transaction?.status === "partiallyRefunded")
                  )
              )?.length || 0,
            totalBookings:
              user?.picked_inqs?.filter(
                (inq: any) =>
                  inq.created_at >= startDateTime &&
                  inq.created_at <= endDateTime &&
                  inq?.inq_transections?.some(
                    (transaction: any) =>
                      transaction?.status === "paid" ||
                      transaction?.status === "partiallyRefunded"
                  )
              )?.length || 0,
            completeBookings:
              user?.picked_inqs?.filter(
                (inq: any) =>
                  inq?.status === "completed" &&
                  inq.completed_date >= startDateTime &&
                  inq.completed_date <= endDateTime
              )?.length || 0,
            issuedBookings:
              user?.picked_inqs?.filter(
                (inq: any) =>
                  inq.created_at >= startDateTime &&
                  inq.created_at <= endDateTime &&
                  inq?.inq_tickets?.some(
                    (ticket: any) => ticket?.status === "issued"
                  )
              )?.length || 0,
            dueBalance: dueBalance(user?.picked_inqs),

            completedProfit: comProfitArr
              .find((item: any) => item.userId === user.id)
              ?.amount?.toFixed(2),
            cancellationProfit: canProfitArr
              .find((item: any) => item.userId === user.id)
              ?.amount?.toFixed(2),
            totalProfit: (
              comProfitArr.find((item: any) => item.userId === user.id)
                ?.amount +
              canProfitArr.find((item: any) => item.userId === user.id)?.amount
            ).toFixed(2),
          })),
      ]
        ?.sort((a, b) => b.totalProfit - a.totalProfit)
        ?.map((row, index) => ({
          ...row,
          id: index + 1, // Assign ID based on the sorted index
        }))
    : [];

  // Calculate sums for each column
  const sumColumns = {
    totalPending: rows.reduce((sum, row) => sum + row.totalPending, 0),
    inquiries: rows.reduce((sum, row) => sum + row.inquiries, 0),
    todayBookings: rows.reduce((sum, row) => sum + row.todayBookings, 0),
    totalBookings: rows.reduce((sum, row) => sum + row.totalBookings, 0),
    completeBookings: rows.reduce((sum, row) => sum + row.completeBookings, 0),
    issuedBookings: rows.reduce((sum, row) => sum + row.issuedBookings, 0),
    dueBalance: rows.reduce((sum, row) => sum + row.dueBalance, 0),
    completedProfit: rows
      .reduce((sum, row) => sum + parseFloat(row.completedProfit), 0)
      .toFixed(2),
    cancellationProfit: rows
      .reduce((sum, row) => sum + parseFloat(row.cancellationProfit), 0)
      .toFixed(2),
    totalProfit: rows
      .reduce((sum, row) => sum + parseFloat(row.totalProfit), 0)
      .toFixed(2),
  };

  useEffect(() => {
    setSumOfLeaderBoard(sumColumns);
  }, [sumColumns, rows]);

  const role = useUserDefaultRole();

  const today = dayjs();

  const columns: GridColDef[] = [
    { field: "id", headerName: "Rank", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "totalPending",
      headerName: `Total Pending`,
      width: 110,
      renderCell: (params) => {
        return (
          <Link
            to={`/${role}/inquiries?startTime=${startDateTime}&endTime=${endDateTime}&consultant=${params.row.user.id}&status=inprocess`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="hover:text-basic hover:font-bold p-3">
              {params.row.totalPending}
            </span>
          </Link>
        );
      },
    },
    {
      field: "inquiries",
      headerName: `Inquiries`,
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            to={`/${role}/inquiries?startTime=${startDateTime}&endTime=${endDateTime}&consultant=${params.row.user.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="hover:text-basic hover:font-bold p-3">
              {params.row.inquiries}
            </span>
          </Link>
        );
      },
    },
    {
      field: "todayBookings",
      headerName: `Today Bookings`,
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            to={`/${role}/inquiries?startTime=${today
              .startOf("year")
              .subtract(1, "year")}&endTime=${today.endOf(
              "month"
            )}&consultant=${
              params.row.user.id
            }&status=inprocess&type=todayBookings`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="hover:text-basic hover:font-bold p-3">
              {params.row.todayBookings}
            </span>
          </Link>
        );
      },
    },
    {
      field: "totalBookings",
      headerName: `Total Bookings`,
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            to={`/${role}/inquiries?startTime=${startDateTime}&endTime=${endDateTime}&consultant=${params.row.user.id}&type=totalBookings`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="hover:text-basic hover:font-bold p-3">
              {params.row.totalBookings}
            </span>
          </Link>
        );
      },
    },
    {
      field: "completeBookings",
      headerName: `Completed Bookings`,
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            to={`/${role}/inquiries?startTime=${startDateTime}&endTime=${endDateTime}&consultant=${params.row.user.id}&status=completed`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="hover:text-basic hover:font-bold p-3">
              {params.row.completeBookings}
            </span>
          </Link>
        );
      },
    },
    {
      field: "issuedBookings",
      headerName: `Issued Bookings`,
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            to={`/${role}/inquiries?startTime=${startDateTime}&endTime=${endDateTime}&consultant=${params.row.user.id}&type=issuedBookings`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="hover:text-basic hover:font-bold p-3">
              {params.row.issuedBookings}
            </span>
          </Link>
        );
      },
    },
    {
      field: "dueBalance",
      headerName: `Due Balance`,
      width: 100,
    },
    {
      field: "completedProfit",
      headerName: `Completed Profit`,
      width: 130,
    },
    {
      field: "cancellationProfit",
      headerName: `Cancellation Profit`,
      width: 130,
    },
    {
      field: "totalProfit",
      headerName: `Total Profit`,
      width: 120,
    },
  ];

  return (
    <>
      <div className="p-5">
        <div className="w-full border-b-[1px] border-[#ccc] flex justify-between mb-8">
          <h2>Leader Board</h2>
        </div>

        <div className="shadow">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Agents Summary
            </AccordionSummary>
            <AccordionDetails>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="p-5 flex items-center justify-center">
        <OverallStats sumOfLeaderBoard={sumColumns} />
      </div>
    </>
  );
};

export default LeaderBoard;
