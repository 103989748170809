import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Button, Modal, Box } from "@mui/material";
import { useMutation } from "@apollo/client";
import { DELETE_BRD_SEASONS } from "~/graphql/brd_seasons/Mutation";
import { toast } from "react-toastify";
import { useState } from "react";
import UpdateSeasons from "./components/UpdateSeasons";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

export default function SeasonsTable({ brdSeasons, refetch }: any) {
  const [deleteSeason] = useMutation(DELETE_BRD_SEASONS);

  const [openMod, setOpenMod] = useState<any>(false);

  const deleteFunc = async (id: any) => {
    const res = await deleteSeason({
      variables: {
        id,
      },
    });

    if (res?.data?.delete_brd_seasons?.affected_rows) {
      toast.success("Season Deleted SuccessFully");
      refetch();
    }
  };

  return (
    <div>
      <h1 className="ml-2 mb-2 font-bold">SEASONS</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell align="center">START DATE</TableCell>
              <TableCell align="center">END DATE</TableCell>
              <TableCell align="center">ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brdSeasons?.map((row: any, ind: any) => (
              <TableRow key={ind}>
                <TableCell>{row?.int_id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">
                  {moment(row?.start_date).format("MMM-DD")}
                </TableCell>
                <TableCell align="center">
                  {moment(row?.end_date).format("MMM-DD")}
                </TableCell>
                <TableCell align="center">
                  <div className="flex items-center justify-center gap-5">
                    <Button
                      variant="outlined"
                      onClick={() => setOpenMod(row.id)}
                    >
                      EDIT
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        deleteFunc(row.id);
                      }}
                    >
                      DELETE
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <UpdateSeasons
            setOpenSeasMod={setOpenMod}
            id={openMod}
            refetch={refetch}
          />
        </Box>
      </Modal>
    </div>
  );
}
