import { Autocomplete, Box, TextField } from "@mui/material";
import countries from "./data/countries.json";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";

const CountryBox = ({
  register,
  control,
  errors,
  fieldName,
  label,
  defaultValue,
  setValue,
}: any) => {
  const countryFunc = () => {
    const data = countries.find((option: any) => option?.code === defaultValue);
    return data;
  };

  const defCountry = countryFunc(); // Call the function to get the actual value
  const defCountryindex = defCountry ? countries.indexOf(defCountry) : -1; // Check if data is not null/undefined

  useEffect(() => {
    setCountryValue(defCountryindex !== -1 ? countries[defCountryindex] : null);
    setValue(fieldName, defCountry);
  }, [defCountryindex]);

  const [countryValue, setCountryValue] = useState<any>(null);

  return (
    <>
      <Controller
        control={control}
        {...register(fieldName)}
        render={({ field }: any) => (
          <Autocomplete
            {...field}
            disablePortal
            ref={field.ref}
            onChange={(_, data: any) => {
              field.onChange(data);
              setCountryValue(data);
            }}
            value={countryValue}
            filterOptions={(options, { inputValue }: any) => {
              // If input is empty, don't show any suggestions
              if (!inputValue.trim()) {
                return [];
              }
              // Filter options to only include those that start with the user's input
              const inputValueLowerCase = inputValue.toLowerCase();
              return options.filter((option) =>
                option.label.toLowerCase().startsWith(inputValueLowerCase)
              );
            }}
            options={countries || []}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                label={label}
                ref={field.ref}
                inputRef={(el) => {
                  field.ref(el);
                }}
                error={errors[fieldName] ? true : false}
                {...params}
              />
            )}
            noOptionsText=""
          />
        )}
      />
    </>
  );
};

export default CountryBox;
