import React, { useEffect, useState } from "react";
import { SEND_GRID_DOMAINS } from "./graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import InputField from "~/components/FormInputs/InputField";
import { useForm } from "react-hook-form";
import { UPDATE_BRAND } from "../../graphql/Mutation";
import { Button } from "@mui/material";

const SendGridVerify = ({ brand }: any) => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [updateBrand] = useMutation(UPDATE_BRAND);
  const {
    loading: sendGridDomainLoading,
    data: sendGridDomainData,
    error: sendGridDomainError,
    refetch: sendGridDomainRefetch,
  } = useQuery(SEND_GRID_DOMAINS, {
    variables: {
      domain: brand?.sendgrid_domain_id,
    },
  });
  const {
    register,
    formState: { errors },
  } = useForm();
  const SGDomains = sendGridDomainData?.sendGridDomainsVerificationCheck;
  // const getEmailDomainInfo = (domainName: string) => {
  //   return SGDomains.find((d: any) => d.domain === domainName);
  // };

  const domainInfo = SGDomains;

  const handleRefresh = async () => {
    setRefreshLoading(true);
    await sendGridDomainRefetch();
    setRefreshLoading(false);
  };

  useEffect(() => {
    setIsloading(false);
  }, [brand?.active_email_service]);

  return (
    <>
      <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  sm:p-6 w-full">
        <div className="flex justify-between">
          <h3 className="mb-4 text-xl font-semibold ">Custom Domain</h3>
          <div>
            {brand?.active_email_service === "sendgrid" ? (
              <span className="text-[green] font-bold">Activated</span>
            ) : (
              <>
                <Button
                  onClick={async () => {
                    setIsloading(true);
                    await updateBrand({
                      variables: {
                        brd_id: brand?.id,
                        data: { active_email_service: "sendgrid" },
                      },
                    });
                  }}
                  variant="contained"
                >
                  {isLoading ? "Activating.." : "Active"}
                </Button>
              </>
            )}
          </div>
        </div>
        <form action="#">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="domain_not_verified"
                className={`block mb-2 text-sm font-medium ${
                  domainInfo && domainInfo.valid
                    ? "text-green-700 dark:text-green-500"
                    : "text-red-700 dark:text-red-500"
                } `}
              >
                Custom Domain
              </label>
              {domainInfo && domainInfo.valid ? (
                <>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-green-900 bg-green-500 border border-r-0 border-green-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-900 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                      >
                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
                      </svg>
                    </span>
                    <InputField
                      register={register}
                      inputClasses="bg-green-50 border border-green-500 text-green-600 dark:text-green-500 placeholder-green-700 text-sm rounded-none rounded-r-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-green-100 dark:border-green-400"
                      defaultValue={brand?.domain}
                      fieldName="domain_verified"
                      errors={errors}
                    />
                    {/* <input
                      type="text"
                      id="domain_verified"

                    /> */}
                  </div>
                  <p className="mt-2 text-sm text-green-600 dark:text-green-500">
                    <span className="font-medium">Domain Verified!</span>{" "}
                    Successfully
                  </p>
                </>
              ) : (
                <div>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-red-900 bg-red-500 border border-r-0 border-red-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-red-900 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                      >
                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="domain_not_verified"
                      disabled
                      className="bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-none rounded-r-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400"
                      placeholder={brand.domain}
                    />
                  </div>
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className="font-medium">Domain Not Verified!</span>{" "}
                    Please Copy Below Given CNAME record and paste in Your DNS
                    settings. We will use defult domain for notifications until
                    you verify. You can also use Gmail for email notifications
                    to do so{" "}
                    <a className="text-blue-500" href="inbox.html">
                      click here
                    </a>{" "}
                    and connect you google admin account
                  </p>
                  <div className="w-full overflow-x-auto md:overflow-x-visible">
                    {domainInfo && (
                      <>
                        <h3 className="text-lg font-bold mb-2">
                          Add all of these records to Your domain provider in
                          DNS section.
                        </h3>
                        <table className="w-[200%] border-collapse">
                          <thead>
                            <tr>
                              <th className="border p-2">Type</th>
                              <th className="border p-2">Name</th>
                              <th className="border p-2"></th>
                              <th className="border p-2">Value</th>
                              <th className="border p-2"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {domainInfo?.dns && (
                              <RenderDNSRecord records={domainInfo.dns} />
                            )}
                            {/* {domainInfo.dns.dkim1 &&
                              renderDNSRecord(domainInfo.dns.dkim1)}
                            {domainInfo.dns.dkim2 &&
                              renderDNSRecord(domainInfo.dns.dkim2)}
                            {domainInfo.dns.mail_cname &&
                              renderDNSRecord(domainInfo.dns.mail_cname)} */}
                          </tbody>
                        </table>
                      </>
                    )}
                    <div className="mt-4 mx-auto">
                      <p className="text-center text-sm">
                        <span className="inline-block align-middle mr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-5 w-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 14v2m0-8v4m0 12a9 9 0 110-18 9 9 0 010 18z"
                            ></path>
                          </svg>
                        </span>
                        Not sure how to do this?
                        <a
                          href="tel:+447700080781"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          Call Us
                        </a>
                      </p>
                    </div>
                    <hr className="my-4" />
                  </div>
                </div>
              )}
            </div>
            {domainInfo && domainInfo.valid ? (
              <></>
            ) : (
              <div className="col-span-6 sm:col-full">
                <button
                  type="button"
                  onClick={handleRefresh}
                  className="text-white bg-blue-700 cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  {refreshLoading ? "Refreshing..." : "Refresh"}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

const RenderDNSRecord = ({ records }: any) => {
  const [clickedButton, setClickedButton] = useState<string | null>(null);

  const copyToClipboard = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // The text has been copied to the clipboard
        // Set the clicked button to change its background
        setClickedButton(text);

        // Reset the clicked button after 1 second
        setTimeout(() => {
          setClickedButton(null);
        }, 1000);
      })
      .catch((error: any) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <>
      <tr className={`${records.dkim1.valid && `bg-green-500`}`}>
        <td className="border p-2">{records.dkim1.type}</td>
        <td className="border p-2 break-all max-w-xs">{records.dkim1.host}</td>
        <td className="border p-2 flex justify-center">
          <button
            type="button"
            onClick={() => copyToClipboard(records.dkim1.host)}
            className={`text-white rounded px-2 py-1 ${
              clickedButton === records.dkim1.host
                ? "bg-[#125635]"
                : "bg-gray-300"
            } `}
          >
            Copy
          </button>
        </td>
        <td className="border p-2 break-all max-w-xs">{records.dkim1.data}</td>
        <td className="border p-2 flex justify-center">
          <button
            type="button"
            onClick={() => copyToClipboard(records.dkim1.data)}
            className={`text-white rounded px-2 py-1 ${
              clickedButton === records.dkim1.data
                ? "bg-[#125635]"
                : "bg-gray-300"
            } `}
          >
            Copy
          </button>
        </td>
      </tr>
      <tr className={`${records.dkim2.valid && `bg-green-500`}`}>
        <td className="border p-2">{records.dkim2.type}</td>
        <td className="border p-2 break-all max-w-xs">{records.dkim2.host}</td>
        <td className="border p-2 flex justify-center">
          <button
            type="button"
            onClick={() => copyToClipboard(records.dkim2.host)}
            className={`text-white rounded px-2 py-1 ${
              clickedButton === records.dkim2.host
                ? "bg-[#125635]"
                : "bg-gray-300"
            } `}
          >
            Copy
          </button>
        </td>
        <td className="border p-2 break-all max-w-xs">{records.dkim2.data}</td>
        <td className="border p-2 flex justify-center">
          <button
            type="button"
            onClick={() => copyToClipboard(records.dkim2.data)}
            className={`text-white rounded px-2 py-1 ${
              clickedButton === records.dkim2.data
                ? "bg-[#125635]"
                : "bg-gray-300"
            } `}
          >
            Copy
          </button>
        </td>
      </tr>
      <tr className={`${records.mail_cname.valid && `bg-green-500`}`}>
        <td className="border p-2">{records.mail_cname.type}</td>
        <td className="border p-2 break-all max-w-xs">
          {records.mail_cname.host}
        </td>
        <td className="border p-2 flex justify-center">
          <button
            type="button"
            onClick={() => copyToClipboard(records.mail_cname.host)}
            className={`text-white rounded px-2 py-1 ${
              clickedButton === records.mail_cname.host
                ? "bg-[#125635]"
                : "bg-gray-300"
            } hover-bg-gray-400 transition-colors duration-1000`}
          >
            Copy
          </button>
        </td>
        <td className="border p-2 break-all max-w-xs">
          {records.mail_cname.data}
        </td>
        <td className="border p-2 flex justify-center">
          <button
            type="button"
            onClick={() => copyToClipboard(records.mail_cname.data)}
            className={`text-white rounded px-2 py-1 ${
              clickedButton === records.mail_cname.data
                ? "bg-[#125635]"
                : "bg-gray-300"
            } `}
          >
            Copy
          </button>
        </td>
      </tr>
    </>
  );
};

export default SendGridVerify;
