import SidePopup from "~/components/common/SidePopup";
import AddCampusCourse from "./components/AddCampusCourse";
import CampusCoursesData from "./components/CampusCoursesData";
import { useParams } from "react-router-dom";
import useCampusCoursesData from "~/hooks/useCampusCoursesData";

const CampusCourses = () => {

  const { campus_id } = useParams()
  const { uni_id } = useParams()
  const { campusCoursesData } = useCampusCoursesData(campus_id);

  return (
    <>
      <CampusCoursesData campusId={campus_id} coursesData={campusCoursesData} />
      <SidePopup component={<AddCampusCourse campusId={campus_id} uniId={uni_id} currentCourses={campusCoursesData} />} />
    </>
  );
};

export default CampusCourses;
