import { useSubscription } from "@apollo/client";
import React from "react";
import { GET_MESSAGES_STATUS } from "~/graphql/brd_whatsapp_messages_status/Query";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DangerousIcon from "@mui/icons-material/Dangerous";
import ErrorIcon from "@mui/icons-material/Error";

export default function MessageStatus({ message }: any) {
  const statusAr = ["sent", "delivered", "read", "failed"];
  let status = "sending";
  statusAr.map((st: any) => {
    if (
      message?.brd_whatsapp_messages_statuses?.filter(
        (msg: any) => msg.key === st
      )?.[0]
    ) {
      status = st;
    }
  });
  if (!message?.brd_whatsapp_messages_statuses) return <div />; // Ensure a valid JSX element is returned

  return (
    <div>
      {status === "read" ? (
        <div className="flex items-center gap-1">
          <p className="15px font-semibold">Read</p>
          <DoneAllOutlinedIcon sx={{ fontSize: "15px", color: "#009de2" }} />
        </div>
      ) : status === "delivered" ? (
        <div className="flex items-center gap-1">
          <p className="15px font-semibold">Delivered</p>
          <DoneAllOutlinedIcon sx={{ fontSize: "15px" }} />
        </div>
      ) : status === "sent" ? (
        <div className="flex items-center gap-1">
          <p className="15px font-semibold">Sent</p>
          <DoneIcon sx={{ fontSize: "15px" }} />
        </div>
      ) : status === "sending" ? (
        <div className="flex items-center gap-1">
          <p className="15px font-semibold">Sending</p>
          <AccessTimeIcon sx={{ fontSize: "15px" }} />
        </div>
      ) : status === "failed" ? (
        <div className="flex items-center gap-1">
          <p className="15px font-semibold">Failed</p>
          <ErrorIcon sx={{ fontSize: "15px" }} />
        </div>
      ) : (
        <AccessTimeIcon sx={{ fontSize: "15px" }} />
      )}
    </div>
  );
}
