import { useApolloClient, useMutation } from "@apollo/client";
import { GET_TEMPLATE, SEND_GMAIL_EMAIL, SEND_WHATSAPP } from "./graphql/Query";
import { useUserData, useUserId } from "@nhost/react";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  SEND_EMAIL,
  SEND_NOTIFICATION,
  SEND_WHATSAPP_CHAT,
} from "./graphql/Mutation";
import moment from "moment";
import { GET_ROLE_USERS } from "../graphql/Query";
import { sendIntimation } from "./subFunctions/sendIntimation";

const getUserId = async (data: any, client: any) => {
  try {
    if (data?.role) {
      const response = await client.query({
        query: GET_ROLE_USERS,
        variables: data,
      });
      return response.data.brd_users;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export default function useEmail() {
  const client = useApolloClient();
  const userData = useUserData();
  const sender_id = useUserId();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [sendNotification] = useMutation(SEND_NOTIFICATION);
  const [sendWhatsappChat] = useMutation(SEND_WHATSAPP_CHAT);

  const send: any = async (
    templateId: any,
    brdId: any,
    user_Id: any,
    to: any,
    toNumber: any,
    variables: any
  ) => {
    // const templates = {
    //   template: [
    //     {
    //       id: templateId,
    //       brdId: brdId,
    //       to: {
    //         emails: [to],
    //         phone: toNumber,
    //       },
    //       from: {},
    //       variables,
    //     },
    //   ],
    // };
    // const response = await sendIntimation(templates);

    // ;

    let userIds: any = [];

    if (user_Id?.length === 36) {
      userIds.push(user_Id);
    } else {
      const payload = {
        brd_id: brdId,
        role: user_Id,
      };
      const res = await getUserId(payload, client);
      res.map((user: any) => {
        userIds.push(user.user_id);
      });
    }

    const GetTemplateResponse = await client.query({
      query: GET_TEMPLATE,
      variables: {
        templateId,
        brdId,
      },
    });

    const template = GetTemplateResponse.data.brd_emails_templates[0];

    // const templateFrom = template?.from;
    const refresh_token = template?.brd_list?.thp_list?.email_refresh_token;

    const thp_id = template?.brd_list?.thp_list?.id;

    const domain = template?.brd_list?.domain;

    const whatsapp_token = template?.brd_list?.whatsapp_token;
    const phoneNumberId = template?.brd_list?.phoneNumberId;

    let emailBody = template.content;
    let notification_content = template.notification_content;
    let emailSubject = template.subject;

    const variablesAllowed: any =
      template?.def_emails_template?.variables_allowed?.split(",");

    const whatsapp_parameters: any = template?.whatsapp_parameters?.split(",");

    const whatsapp_template_name: any = template?.whatsapp_template_name;

    const whatsapp_notifications: any = template?.whatsapp_active;

    const notification_active: any = template?.notification_active;

    const email_active: any = template?.email_active;

    if (variablesAllowed) {
      variablesAllowed.map((variable: any) => {
        notification_content = notification_content.replace(
          new RegExp(`{{${variable}}}`, "g"),
          variables?.[variable]
        );

        emailBody = emailBody.replace(
          new RegExp(`{{${variable}}}`, "g"),
          variables?.[variable]
        );
        emailSubject = emailSubject.replace(
          `{{${variable}}}`,
          variables?.[variable]
        );
      });
    }

    if (whatsapp_token && phoneNumberId && whatsapp_notifications) {
      const parameters: any = [];
      let fileLink = "";
      let fileName = "";

      let inquiryNo = variables?.["inquiryNo"] || "NA";

      whatsapp_parameters?.map((variable: any) => {
        if (variable === "fileName") {
          fileName = String(variables?.[variable]) || "Decument";
        } else if (variable === "fileLink") {
          fileLink =
            String(variables?.[variable]) ||
            "https://www.africau.edu/images/default/sample.pdf";
        } else {
          parameters.push(String(variables?.[variable]));
        }
      });
      try {
        const response = await client.query({
          query: SEND_WHATSAPP,
          variables: {
            phoneNumberId: phoneNumberId,
            templateName: whatsapp_template_name,
            toNumber: toNumber,
            token: whatsapp_token,
            type: "template",
            filename: fileName,
            textMessage: "",
            url: fileLink,
            parameters: parameters,
          },
        });
        console.log(
          "WhatsappResponse",
          response?.data?.whatsappSendMessage?.messaging_product
        );

        if (response?.data?.whatsappSendMessage?.messaging_product !== null) {
          const payloadDB: any = [
            {
              brand_phone: phoneNumberId,
              user_phone: toNumber,
              text: `System Message against INQ #${inquiryNo}, Triger: ${whatsapp_template_name}`,
              sender: "brand",
              timestamp: moment(new Date()).toISOString(),
            },
          ];
          try {
            const res = await sendWhatsappChat({
              variables: {
                data: payloadDB,
              },
            });
          } catch (e) {
            console.error("error", e);
          }
        } else {
          console.log(
            "whatsappResponse:",
            response?.data?.whatsappSendMessage?.messaging_product
          );
        }
      } catch (error) {
        console.error("Error sending whatsapp:", error);
      }
    }

    if (email_active) {
      try {
        if (refresh_token) {
          const response = await client.query({
            query: SEND_GMAIL_EMAIL,
            variables: {
              refresh_token: refresh_token,
              sendEmail: true,
              to,
              subject: emailSubject,
              body: emailBody,
              from: userData?.metadata?.alias,
              th_id: thp_id,
              alias: false,
            },
          });
        } else {
          const emailData = {
            emails: to,
            body: emailBody,
            subject: emailSubject,
            from: "info@" + domain,
          };
          const emailResp = await sendGenericEmail(emailData, sendEmail);
        }

        // Handle success or do any additional logic here
      } catch (error) {
        // Handle error
        console.error("Error sending email:", error);
      }
    }

    if (notification_active) {
      try {
        const NotifcationPayload: any = [];

        userIds.map((userId: any) => {
          NotifcationPayload.push({
            sender_user_id: sender_id,
            content: notification_content,
            user_id: userId,
            brd_id: brdId,
          });
        });

        const NotificationResponse = await sendNotification({
          variables: {
            data: NotifcationPayload,
          },
        });
        // Handle success or do any additional logic here
      } catch (error) {
        // Handle error
        console.error("Error sending email:", error);
      }
    }
  };

  return send;
}
