import { useQuery } from "@apollo/client";
import { GET_EMAIL_DETAIL_FILE } from "~/graphql/actions/Query";
import Button from "@mui/material/Button";

export default function SingleFile({ file, message_id, refresh_token }: any) {
  const { data, loading, error } = useQuery(GET_EMAIL_DETAIL_FILE, {
    variables: { id: file.attachmentId, messageId: message_id, refresh_token },
  });
  const handleDownload = (data: any) => {
    var binaryData = atob(data.replace(/-/g, "+").replace(/_/g, "/"));
    var uint8Array = new Uint8Array(binaryData.length);
    for (var i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    var blob = new Blob([uint8Array], { type: "application/pdf" });
    const urlLink = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = urlLink;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(urlLink);
  };

  return (
    <div className="flex gap-4 justify-between w-full py-4 items-center bg-[#ccc] px-4 rounded-md">
      <div>
        <div>
          <div>{file.name}</div>
        </div>
      </div>
      <div>
        {loading && "Downloading.."}
        {error && error.message}
        {data?.getGmailFile?.data && (
          <>
            <Button
              variant="contained"
              onClick={() => {
                handleDownload(data.getGmailFile.data);
              }}
            >
              Download
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
