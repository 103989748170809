import { gql } from "@apollo/client";

export const GET_LINKEDIN_USER_INFO = gql`
  query GetLinkedInUserInfo($access_token: String!) {
    get_linkedin_u_info(arg1: { access_token: $access_token }) {
      id
      firstName {
        localized {
          en_US
        }
      }
      lastName {
        localized {
          en_US
        }
      }
      profilePicture
    }
  }
`;
