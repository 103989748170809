import { gql } from "@apollo/client";

export const INSERT_THP_USER_ATTENDANCE = gql`
  mutation insertThpUserAttenedance($userID: uuid!) {
    insert_thp_user_attendance(objects: { user_id: $userID }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_THP_USER_OUT_TIME = gql`
  mutation updateThpUserAttendance(
    $userID: uuid!
    $attendanceDate: date!
    $outTime: timestamptz!
  ) {
    update_thp_user_attendance(
      where: {
        user_id: { _eq: $userID }
        attendance_date: { _eq: $attendanceDate }
      }
      _set: { out_time: $outTime }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_THP_USER_ATTENDENCE = gql`
  mutation updateThpUserAttendance(
    $userID: uuid!
    $attendanceDate: date!
    $currentBreakTime: timestamptz
    $nullBreakTime: Boolean!
    $nullOutTime: Boolean!
  ) {
    update_thp_user_attendance(
      where: {
        user_id: { _eq: $userID }
        attendance_date: { _eq: $attendanceDate }
        out_time: { _is_null: $nullOutTime }
        current_breaktime: { _is_null: $nullBreakTime }
      }
      _set: { current_breaktime: $currentBreakTime }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_USER_BREAK = gql`
  mutation updateThpUserAttendance(
    $userID: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
    $breakTime: Int!
  ) {
    insert_thp_user_breaktime(
      objects: {
        user_id: $userID
        start_time: $startDate
        end_time: $endDate
        break_time_minutes: $breakTime
      }
    ) {
      returning {
        id
      }
    }
  }
`;
