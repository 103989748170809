import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ContentEditor from "~/components/common/ContentEditor";
import { SEND_EMAIL } from "~/graphql/actions/gmail/Mutation";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Files from "./components/Files";
import { URLs } from "~/config/enums";
import { useNhostClient, useUserId } from "@nhost/react";
import { OAuth2Client } from "google-auth-library";
import { google } from "googleapis";
import { toast } from "react-toastify";
import moment from "moment";
import { SEND_WHATSAPP_MESSAGE } from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/Query";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NotesIcon from "@mui/icons-material/Notes";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import AudioIcon from "@mui/icons-material/MusicNote";
import MicExternalOff from "@mui/icons-material/Mic";
import { INSERT_MESSAGES } from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/graphql/Mutation";
import {
  INSERT_CONVERSATION,
  UPDATE_CONVERSATION_MESSAGE,
} from "~/graphql/brd_whatsapp_conversations/Mutation";
import AudioRecording from "./components/AudioRecording";
import { CONVERT_FILE } from "~/graphql/actions/convert/Query";

export default function WhatsappSend({
  user_phone,
  brand_token,
  brand_phone,
  setSidePopup,
}: any) {
  const nhost = useNhostClient();
  const client = useApolloClient();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sendEmail] = useMutation(SEND_EMAIL);

  const [updateMessage] = useMutation(UPDATE_CONVERSATION_MESSAGE);
  const [insertConversation] = useMutation(INSERT_CONVERSATION);

  const [insertMessages] = useMutation(INSERT_MESSAGES);
  const [content, setContent] = useState<any>("");
  const [fileId, setFileId] = useState<any>("");

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const userId = useUserId();

  const saveMsg = async (text: any, type: any) => {
    // Get the current time in UTC
    const currentTime = moment().utc();
    // Format the current time in the desired format
    const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
    const resConversationUpdate: any = await updateMessage({
      variables: {
        user_phone: user_phone,
        brand_phone: brand_phone,
        timestamp: formattedTime,
        text: text,
        type: type,
      },
    });

    let conversation_id =
      resConversationUpdate?.data?.update_brd_whatsapp_conversations
        ?.returning?.[0]?.id;

    if (!conversation_id) {
      const resConversationInsert: any = await insertConversation({
        variables: {
          data: {
            id: brand_phone + "_" + user_phone,
            user_phone: user_phone,
            brand_phone: brand_phone,
            whatsapp_name: "",
            last_message: text,
            last_message_time: formattedTime,
          },
        },
      });
      conversation_id =
        resConversationInsert?.data?.insert_brd_whatsapp_conversations
          ?.returning?.[0]?.id;
    }

    const payload: any = [
      {
        conversation_id,
        sender_role: "brand",
        sender_id: userId,
        text: text,
        type: type,
        timestamp: formattedTime,
      },
    ];
    const resMessagesInsert = await insertMessages({
      variables: { data: payload },
    });
    // setMessageData("");
  };

  const handleSendEmail = async (data: any) => {
    setLoading(true);

    const type =
      value == 0
        ? "text"
        : value == 1
        ? "image"
        : value == 2
        ? "video"
        : value == 3
        ? "document"
        : value == 4
        ? "audio"
        : "audio";

    if (value === 0) {
      await saveMsg(data.text, type);
    } else if (value === 5) {
      const convertFileResponse = await client.query({
        query: CONVERT_FILE,
        variables: {
          url: URLs.FILE_URL + fileId,
        },
      });

      const fileBase64 = convertFileResponse?.data?.convertFile?.data;
      const base64Data = fileBase64.split(";base64,").pop();

      // Convert the base64 data to a binary array
      const binaryData = atob(base64Data);
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const file = new File([uint8Array], "filename.aac", {
        type: "audio/aac",
      });
      const response: any = await nhost.storage.upload({ file });
      const fileIdD = response?.fileMetadata?.id;
      await saveMsg(fileIdD, "audio");
    } else {
      await Promise.all(
        files.map(async (file: any) => {
          await saveMsg(file.id, type);
        })
      );
    }

    setLoading(false);

    toast.success(
      "Message Sent Successfully, It will take few seconds to fetch"
    );
    setSidePopup(false);
  };

  return (
    <div className="mt-12 mx-12">
      <form onSubmit={handleSubmit(handleSendEmail)}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon label tabs example"
            >
              <Tab icon={<NotesIcon />} label="Text" />
              <Tab icon={<PhotoLibraryIcon />} label="Photos" />
              <Tab icon={<PhotoLibraryIcon />} label="Videos" />
              <Tab icon={<FilePresentIcon />} label="Documents" />
              <Tab icon={<AudioIcon />} label="Audio" />
              <Tab icon={<MicExternalOff />} label="Voice Message" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className="grid grid-cols-6 gap-6 mb-4">
              <div className="col-span-6 sm:col-full ck-editor">
                <TextField
                  error={errors["id"] ? true : false}
                  id="outlined-basic"
                  label="Message"
                  {...register("text")}
                  variant="outlined"
                  type="text"
                  className="w-full"
                />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-full ck-editor">
                <Files setFilesId={setFiles} />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-full ck-editor">
                <Files setFilesId={setFiles} />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-full ck-editor">
                <Files setFilesId={setFiles} />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-full ck-editor">
                <Files setFilesId={setFiles} />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-full ck-editor">
                <AudioRecording setFileId={setFileId} />
              </div>
            </div>
          </CustomTabPanel>
        </Box>

        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-full">
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
              disabled={value === 5 ? (fileId === "" ? true : false) : false}
            >
              <span>Send</span>
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
