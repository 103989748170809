import React from "react";

import SidePopup from "~/components/common/SidePopup";
import AddCategory from "./components/AddCategory";
import ShowCategory from "./components/ShowCategory";

const Category = () => {
  return (
    <>
      <ShowCategory />
      <SidePopup component={<AddCategory />} />
    </>
  );
};

export default Category;
