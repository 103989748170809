import { gql } from "@apollo/client";

export const GET_ASSIGNED_BRANDS = gql`
  query GetAssignedBrands($id: uuid!, $thp_id: uuid, $brdTypeId: uuid) {
    brd_users(
      where: {
        user_id: { _eq: $id }
        thp_id: { _eq: $thp_id }
        brd_list: { brand_type: { _eq: $brdTypeId } }
      }
      distinct_on: brd_id
    ) {
      brd_id
      brd_list {
        name
        gmail_refresh_token
        whatsapp_token
        phoneNumberId
      }
    }
    thp_list(
      where: {
        primary_user_id: { _eq: $id }
        brd_lists: { brand_type: { _eq: $brdTypeId } }
      }
    ) {
      brd_lists {
        id
        gmail_refresh_token
        phoneNumberId
        name
        whatsapp_token
      }
    }
  }
`;
