import { gql } from "@apollo/client";

export const REPORT_QUERIES: any = {
  picked_inquiry: {
    query: gql`
      query MyQuery(
        $th_id: uuid
        $user_id: uuid
        $from: timestamptz
        $to: timestamptz
      ) {
        inquiries(
          where: {
            th_id: { _eq: $th_id }
            picked_by: { _eq: $user_id }
            picked_datetime: { _gte: $from, _lte: $to }
          }
        ) {
          adults
          approx_budget
          arrival
          cabinClass
          cancelation_charges
          childs
          cost
          created_at
          departure
          direct_flight
          from
          id
          to
          picked_datetime
          picked_by
        }
      }
    `,
  },
  issued_tickets: {
    query: gql`
      query MyQuery(
        $th_id: uuid
        $user_id: uuid
        $from: timestamptz
        $to: timestamptz
      ) {
        inquiries(
          where: {
            th_id: { _eq: "e18ce2ee-6ef5-4dba-af3b-063267f9ab58" }
            picked_by: { _eq: "ee982518-5dbe-4452-b412-3fcaada5842d" }
            status: { _eq: "issued" }
            ticket_issued_at: { _gte: "2023-08-20", _lte: "2023-08-22" }
          }
        ) {
          id
          inquiry_created_by
          inquiry_no
          inquiry_source
          inquiry_type
          picked_by
          picked_datetime
          pnr_no
          preferred_airline
          status
          ticket_cost
          ticket_issued_at
          ticket_issued_by
          ticket_no
          ticket_url
        }
      }
    `,
  },
  paid_confirmations: {
    query: gql`
      query MyQuery {
        transactions(
          where: {
            confirmed_by: { _eq: "f864134f-a9cb-4802-8cfd-99f334950a23" }
            th_id: { _eq: "e18ce2ee-6ef5-4dba-af3b-063267f9ab58" }
            confirmed_at: { _gte: "2023-08-23", _lte: "2023-08-24" }
          }
        ) {
          id
          created_at
          confirmed_by
          amount
          confirmed_at
          inquiry {
            from
            id
            to
          }
          receipt
          ref_no
        }
      }
    `,
  },
  vendor_issued_tickets: {
    query: gql`
      query MyQuery(
        $th_id: uuid
        $user_id: uuid
        $from: timestamptz
        $to: timestamptz
      ) {
        inquiries(
          where: {
            th_id: { _eq: "e18ce2ee-6ef5-4dba-af3b-063267f9ab58" }
            status: { _eq: "issued" }
            ticket_issued_at: { _gte: "2023-08-20", _lte: "2023-08-22" }
            vendor_id: { _eq: "e0104a7f-6084-4106-ad3a-5f12e0f271c5" }
          }
        ) {
          id
          inquiry_created_by
          inquiry_no
          inquiry_source
          inquiry_type
          picked_by
          picked_datetime
          pnr_no
          preferred_airline
          status
          ticket_cost
          ticket_issued_at
          ticket_issued_by
          ticket_no
          ticket_url
        }
      }
    `,
  },
  opened_inquiries: {
    query: gql`
      query MyQuery(
        $th_id: uuid
        $user_id: uuid
        $from: timestamptz
        $to: timestamptz
      ) {
        inquiries(
          where: {
            th_id: { _eq: "e18ce2ee-6ef5-4dba-af3b-063267f9ab58" }
            created_at: { _gte: "2023-08-20", _lte: "2023-08-22" }
            user_id: { _eq: "e0104a7f-6084-4106-ad3a-5f12e0f271c5" }
            picked_by: { _is_null: true }
          }
        ) {
          id
          inquiry_created_by
          inquiry_no
          inquiry_source
          inquiry_type
          picked_by
          picked_datetime
          pnr_no
          preferred_airline
          status
          ticket_cost
          ticket_issued_at
          ticket_issued_by
          ticket_no
          ticket_url
        }
      }
    `,
  },
  picked_inquiries: {
    query: gql`
      query MyQuery(
        $th_id: uuid
        $user_id: uuid
        $from: timestamptz
        $to: timestamptz
      ) {
        inquiries(
          where: {
            th_id: { _eq: "e18ce2ee-6ef5-4dba-af3b-063267f9ab58" }
            picked_datetime: { _gte: "2023-08-20", _lte: "2023-08-22" }
            user_id: { _eq: "e0104a7f-6084-4106-ad3a-5f12e0f271c5" }
            picked_by: { _is_null: false }
          }
        ) {
          id
          inquiry_created_by
          inquiry_no
          inquiry_source
          inquiry_type
          picked_by
          picked_datetime
          pnr_no
          preferred_airline
          status
          ticket_cost
          ticket_issued_at
          ticket_issued_by
          ticket_no
          ticket_url
        }
      }
    `,
  },
};
