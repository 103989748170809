import { gql } from "@apollo/client";

export const GET_BRD_PAGES_HEADINGS = gql`
  query MyQuery($brd_id: uuid!, $pageType: String!) {
    brd_pages_headings(
      where: { brd_id: { _eq: $brd_id }, page_name: { _eq: $pageType } }
    ) {
      subheading
      section_name
      page_name
      heading
      id
      brd_id
    }
  }
`;
