import { gql } from "@apollo/client";

export const INSERT_MAILJET_CREDENTIALS = gql`
    mutation UpdateMailJetCredentials(
        $brdId: uuid!, 
        $apiKey: String!, 
        $apiSecret: String!
    ) {
        update_brd_detail(
            where: { brd_id: { _eq: $brdId } },
            _set: { mailjet_api_key: $apiKey, mailjet_api_secret: $apiSecret }
        ) {
            affected_rows
        }
    }
`;