import React from "react";
import Layout from "~/components/common/Layout";
import TravelHousesModule from "~/modules/TravelHousesModule";
import TravelHouseList from "~/modules/TravelHousesModule/segments/TravelHouseList";

export default function TravelHouses() {
  return (
    <Layout>
      <TravelHousesModule>
        <TravelHouseList />
      </TravelHousesModule>
    </Layout>
  );
}
