import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const carTypeOptions = [
  { value: "sedan", label: "Sedan" },
  { value: "hatchback", label: "Hatchback" },
  { value: "suv", label: "SUV" },
  { value: "crossover", label: "Crossover" },
  { value: "coupe", label: "Coupe" },
  { value: "convertible", label: "Convertible" },
  { value: "minivan", label: "Minivan" },
  { value: "pickup", label: "Pickup Truck" },
  { value: "station_wagon", label: "Station Wagon" },
  { value: "luxury", label: "Luxury Car" },
  { value: "sports_car", label: "Sports Car" },
  { value: "electric_vehicle", label: "Electric Vehicle (EV)" },
  { value: "hybrid", label: "Hybrid" },
  { value: "off_road", label: "Off-Road Vehicle" },
];

export const CarTypeSelector = ({
  name,
  register,
  errors,
  value, // The current value of the select input
  onChange,
  setValue,
  fieldName,
  defaultValue,
  required,
  label,
}: any) => {
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue); // Set the initial value for the room type select
      onChange(defaultValue); // Set the initial value for the input field
    }
  }, [defaultValue, fieldName, setValue, onChange]);
  return (
    <>
      <FormControl fullWidth error={Boolean(errors.carType)}>
        <InputLabel id="carType-label">Car Type</InputLabel>
        <Select
          labelId="carType-label"
          id="carType"
          label="Car Type"
          defaultValue={value || ""}
          {...register(name, { required: "Car type is required" })}
          onChange={(event) => {
            onChange(event.target.value); // Update the value on change
          }}
        >
          <MenuItem value="">
            <em>Select a car type</em>
          </MenuItem>
          {carTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {errors.carType && (
          <FormHelperText>{errors.carType.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};
