import { gql } from "@apollo/client";

export const GET_TRAVEL_HOUSE = gql`
  query GetThpList($thp_id: uuid!) {
    thp_list(where: { id: { _eq: $thp_id } }) {
      disabled
      domain
      email_refresh_token
      name
      subdomain
      subscription_ref
      activated_at
      created_at
      expiry
      suspended_at
      updated_at
      id
      plan_id
      def_subscription_plan {
        plan_name
        price_monthly
        price_yearly
        metadata
        id
        currency
      }
    }
  }
`;
