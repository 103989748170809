import React from "react";
import Layout from "~/components/common/Layout";
import AccountModule from "~/modules/AccountModule";
import AccountsList from "~/modules/AccountModule/segments/AccountsList";
import GoogleAds from "~/modules/AccountModule/segments/GoogleAds";
import BrandModule from "~/modules/BrandModule";
import Campaigns from "~/modules/BrandModule/modules/MarketingModule/segments/Google";
import Ads from "~/modules/BrandModule/modules/MarketingModule/segments/Google/Campaigns/Ads";

export default function GoogleCampaignDetailPage() {
    return (
        <div>
            <Layout>
                {/* <BrandModule> */}
                <Ads />
                {/* </BrandModule> */}
            </Layout>
        </div>
    );
}
