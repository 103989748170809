import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $selected_suggestion_id: uuid) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { suggestion_id: $selected_suggestion_id }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_SUGGESTION = gql`
  mutation DeleteSuggestion($id: uuid!) {
    delete_inq_suggestion_by_pk(id: $id) {
      id
    }
  }
`;
export const STAY_EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $selected_suggestion_id: uuid) {
    update_stay_inq_detail(
      where: { id: { _eq: $id } }
      _set: { selected_stay_suggestion_id: $selected_suggestion_id }
    ) {
      returning {
        id
      }
    }
  }
`;
export const STAY_DELETE_SUGGESTION = gql`
  mutation DeleteSuggestion($id: uuid!) {
    delete_stay_inq_suggestion_by_pk(id: $id) {
      id
    }
  }
`;
export const CAR_EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $selected_suggestion_id: uuid) {
    update_car_inq_detail(
      where: { id: { _eq: $id } }
      _set: { selected_car_suggestion_id: $selected_suggestion_id }
    ) {
      returning {
        id
      }
    }
  }
`;
export const CAR_DELETE_SUGGESTION = gql`
  mutation DeleteSuggestion($id: uuid!) {
    delete_car_inq_suggestion_by_pk(id: $id) {
      id
    }
  }
`;

export const TRAIN_EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $selected_suggestion_id: uuid) {
    update_train_inq_detail(
      where: { id: { _eq: $id } }
      _set: { selected_train_suggestion_id: $selected_suggestion_id }
    ) {
      returning {
        id
      }
    }
  }
`;
export const TRAIN_DELETE_SUGGESTION = gql`
  mutation DeleteSuggestion($id: uuid!) {
    delete_train_inq_suggestion_by_pk(id: $id) {
      id
    }
  }
`;
