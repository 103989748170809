import { gql } from "@apollo/client";

export const EDIT_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: inq_suggestion_set_input!) {
    update_inq_suggestion(where: { id: { _eq: $id } }, _set: $suggestion) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_SUGGESTION_STOP = gql`
  mutation MyMutation(
    $id: uuid
    $inq_suggestion_leg_stops: inq_suggestion_leg_stops_set_input!
  ) {
    update_inq_suggestion_leg_stops(
      where: { id: { _eq: $id } }
      _set: $inq_suggestion_leg_stops
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_SUGGESTION_COST = gql`
  mutation MyMutation(
    $id: uuid
    $suggestion_cost: inq_suggestion_cost_set_input!
  ) {
    update_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: $suggestion_cost
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_SUGGESTION_LEG = gql`
  mutation MyMutation(
    $id: uuid
    $inq_suggestion_leg: inq_suggestion_leg_set_input!
  ) {
    update_inq_suggestion_leg(
      where: { id: { _eq: $id } }
      _set: $inq_suggestion_leg
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_SUGGESTION_COST = gql`
  mutation MyMutation($suggestion_cost: [inq_suggestion_cost_insert_input!]!) {
    insert_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const STAY_UPDATE_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: stay_inq_suggestion_set_input!) {
    update_stay_inq_suggestion(where: { id: { _eq: $id } }, _set: $suggestion) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const STAY_ADD_SUGGESTION_COST = gql`
  mutation MyMutation(
    $suggestion_cost: [stay_inq_suggestion_cost_insert_input!]!
  ) {
    insert_stay_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
        # stay_suggestion_id
      }
    }
  }
`;

export const STAY_EDIT_SUGGESTION_COST = gql`
  mutation MyMutation(
    $id: uuid
    $suggestion_cost: stay_inq_suggestion_cost_set_input!
  ) {
    update_stay_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: $suggestion_cost
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CAR_UPDATE_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: car_inq_suggestion_set_input!) {
    update_car_inq_suggestion(where: { id: { _eq: $id } }, _set: $suggestion) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CAR_ADD_SUGGESTION_COST = gql`
  mutation MyMutation(
    $suggestion_cost: [car_inq_suggestion_cost_insert_input!]!
  ) {
    insert_car_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
        # car_suggestion_id
      }
    }
  }
`;

export const CAR_EDIT_SUGGESTION_COST = gql`
  mutation MyMutation(
    $id: uuid
    $suggestion_cost: car_inq_suggestion_cost_set_input!
  ) {
    update_car_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: $suggestion_cost
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const TRAIN_UPDATE_SUGGESTION = gql`
  mutation MyMutation($id: uuid, $suggestion: train_inq_suggestion_set_input!) {
    update_train_inq_suggestion(
      where: { id: { _eq: $id } }
      _set: $suggestion
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const TRAIN_ADD_SUGGESTION_COST = gql`
  mutation MyMutation(
    $suggestion_cost: [train_inq_suggestion_cost_insert_input!]!
  ) {
    insert_train_inq_suggestion_cost(objects: $suggestion_cost) {
      affected_rows
      returning {
        id
        # train_suggestion_id
      }
    }
  }
`;

export const TRAIN_EDIT_SUGGESTION_COST = gql`
  mutation MyMutation(
    $id: uuid
    $suggestion_cost: train_inq_suggestion_cost_set_input!
  ) {
    update_train_inq_suggestion_cost(
      where: { id: { _eq: $id } }
      _set: $suggestion_cost
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
