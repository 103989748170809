import moment from "moment";
import { HASURA_KEYS, URLs } from "~/config/enums";

export const getAmadeusFlights = async (inpData: any, airline: any = "") => {
  const {
    from,
    to,
    start,
    end,
    adults,
    children,
    infants,
    tripType,
    cabinClass,
  } = inpData;

  if (airline === null) {
    airline = "";
  }
  let endDateT: any = moment().format("YYYY-MM-DD");
  // endDateT = moment(endDateT).toDate();
  let endDateNew: any = null;
  if (end) {
    if (end !== "Invalid date") {
      if (end !== "null") {
        endDateNew = end;
      } else {
        endDateNew = endDateT;
      }
    } else endDateNew = endDateT;
  } else {
    endDateNew = endDateT;
  }

  if (endDateNew === null) {
    endDateNew = endDateT;
  }

  const toDate =
    endDateNew !== "null"
      ? new Date(endDateNew)?.toISOString()?.split("T")[0]
      : "";

  const gql = JSON.stringify({
    query: `query FlightSearch {
          getAmadeusFlights(arg1: {tripType: "${
            tripType === "roundTrip" ? "round" : "oneway"
          }", cabinClass:"${cabinClass}", airline: "${airline}", direct: false, from: "${from}", fromDate: "${
      new Date(start)?.toISOString()?.split("T")[0]
    }", passengers: {adt: ${adults}, chd: ${children}, inf: ${infants}}, to: "${to}", toDate: "${toDate}"}) {
            flights {
              baggageInfo
              currency
              departure {
                legs {
                  airlineName
                  arrivalAirport
                  arrivalTime
                  cabin
                  airlineCode
                  departureTime
                  departureAirport
                  flightNumber
                  duration
                }
                stops
                duration
              }
              individualPricing {
                passengerType
                totalPrice
                totalTax
              }
              return {
                legs {
                  airlineName
                  arrivalAirport
                  arrivalTime
                  cabin
                  airlineCode
                  departureAirport
                  duration
                  flightNumber
                  departureTime
                }
                stops
                duration
              }
              source
              totalPrice
              numberOfBookableSeats
              lastTicketingDate
            }
          }
        }
        `,
  });

  var myHeaders = new Headers();
  myHeaders.append("x-hasura-admin-secret", HASURA_KEYS.SECRET);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: gql,
    redirect: "follow",
  };

  const result = await fetch(URLs.SERVER_URL, requestOptions);
  // const result = await fetch(
  //   "https://hasura.travelhouseportal.com/v1/graphql",
  //   requestOptions
  // );

  const resp = await result.json();
  // try {
  //   const resp = await result.json();

  return resp?.data?.getAmadeusFlights;
  // } catch (err: any) {
  //   ;
  //   return false;
  // }
};
