import React from "react";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BiCalendarEvent, BiChat, BiHome, BiUser } from "react-icons/bi";
import { useState } from "react";
import { BsCircle } from "react-icons/bs";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

import { Link } from "react-router-dom";

export default function CustomList({
  heading,
  link,
  listItems,
  setActiveDropdown,
  activeDropdown,
}: any) {
  const navigate = useNavigate();

  const storedData = localStorage.getItem("darkMode");

  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );

  const activeItem = {
    borderTopRightRadius: "100px",
    borderBottomRightRadius: "100px",
    backgroundImage:
      "linear-gradient(98deg, rgb(198, 167, 254), rgb(145, 85, 253) 94%)",
    boxShadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px",
    color: "white",
    paddingTop: "7px",
    paddingBottom: "7px",
  };

  const defaultItemStyle = {
    borderTopRightRadius: "100px",
    borderBottomRightRadius: "100px",
    width: "100%",
    paddingTop: "7px",
    paddingBottom: "7px",
  };

  const dropDownHeadActive = darkMode
    ? {
        "&:hover": {
          backgroundColor: "rgba(231, 227, 252, 0.04)",
        },
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
        backgroundColor: "rgba(231, 227, 252, 0.04)",
        paddingTop: "7px",
        paddingBottom: "7px",
      }
    : {
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
        backgroundColor: "rgba(58, 53, 65, 0.04)",
        paddingTop: "7px",
        paddingBottom: "7px",
      };

  return (
    <>
      <List sx={{ margin: "0px", padding: "0px", marginTop: "5px" }}>
        {heading && (
          <div className="flex items-center dark:text-primary mt-5 mb-1">
            <hr className="w-[10%] dark:border-[#E7E3FC61]" />
            <span className="text-[12px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
              {heading}
            </span>
            <hr className="w-full dark:border-[#E7E3FC61]" />
          </div>
        )}
        <ListItem disablePadding sx={{ display: "list-item" }}>
          {listItems.map((list: any) => {
            return (
              <div className="dark:text-dark-primary">
                <ListItemButton
                  component={Link}
                  to={list.link}
                  onClick={() => {
                    if (list.link === activeDropdown) {
                      setActiveDropdown("");
                    } else {
                      setActiveDropdown(list.link);
                    }
                  }}
                  sx={
                    activeDropdown === list.link ||
                    list?.subMenu?.filter(
                      (menuItem: any) => menuItem?.link === activeDropdown
                    ).length > 0
                      ? list.subMenu
                        ? dropDownHeadActive
                        : activeItem
                      : defaultItemStyle
                  }
                >
                  <span
                    className={`${
                      activeDropdown === list.link ||
                      list?.subMenu?.filter(
                        (menuItem: any) => menuItem?.link === activeDropdown
                      ).length > 0
                        ? list.subMenu
                          ? "text-primary dark:text-dark-primary"
                          : "text-white"
                        : defaultItemStyle
                    }
                    //   (list?.subMenu?.filter(
                    //     (menuItem: any) => menuItem?.link === activeDropdown
                    //   ).length > 0 &&
                    //     list.subMenu)
                    //     ? "text-white"
                    //     : "text-black"
                    // } mr-3 pl-1`}
                  >
                    {list.icon}
                  </span>
                  <ListItemText primary={list.name} />
                  {list.subMenu &&
                    (activeDropdown === list.link ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    ))}
                </ListItemButton>

                {list.subMenu && (
                  <>
                    <Collapse
                      in={
                        activeDropdown === list.link ||
                        list.subMenu.filter(
                          (menuItem: any) => menuItem.link === activeDropdown
                        ).length > 0
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      {list.subMenu.map((menuItem: any) => {
                        return (
                          <ListItemButton
                            component={Link}
                            to={list.link}
                            onClick={() => {
                              setActiveDropdown(menuItem.link);
                            }}
                            sx={
                              menuItem.link === activeDropdown
                                ? activeItem
                                : defaultItemStyle
                            }
                          >
                            <div className="dark:text-dark-primary ml-2">
                              <BsCircle
                                className={`mr-3 ${
                                  menuItem.link === activeDropdown &&
                                  "text-white"
                                } `}
                                size={12}
                              />
                            </div>
                            <div className="dark:text-dark-primary">
                              <ListItemText primary={menuItem.name} />
                            </div>
                          </ListItemButton>
                        );
                      })}
                    </Collapse>
                  </>
                )}
              </div>
            );
          })}
        </ListItem>
      </List>
    </>
  );
}
