import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import AirlinesAdd from "~/modules/BrandModule/segments/AirlinesAdd";
export default function BrandAirlinesAddPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <AirlinesAdd />
        </BrandModule>
      </Layout>
    </div>
  );
}
