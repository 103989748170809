import _airportsData from "../data/airports.json";

export function customSearch(query: string) {
  query = query.toLowerCase();
  return _airportsData.filter((item: any) => {
    if (query.length === 3) {
      // If the query has exactly three letters, match against iata_code and city_iata
      return (
        item?.iata_code?.toLowerCase() === query ||
        item?.city_iata?.toLowerCase() === query
      );
    } else {
      // If the query has more than three letters, match against name, city_name, or country
      return (
        item?.name?.toLowerCase().includes(query) ||
        item?.city_name?.toLowerCase().includes(query) ||
        item?.country?.toLowerCase().includes(query)
      );
    }
  });
}
