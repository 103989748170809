import { gql } from "@apollo/client";

export const DELETE_ACC_TRANS = gql`
  mutation deleteAccTrans($id: uuid!) {
    delete_acc_transactions(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
