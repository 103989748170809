import React from "react";
import StayInvoiceDetails from "../StayDetails";
import CarInvoiceDetails from "../CarDetails";
import TrainInvocieDetails from "../TrainDetails";
import FlightInvoiceSuggestion from "../FlightDetails";

const InvoiceDetailPage = ({ invoice }: any) => {
  const staySuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "stay"
  );
  const carSuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "car"
  );
  const trainSuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "train"
  );
  const flightSuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "flights"
  );

  return (
    <>
      <>
        {staySuggestions && staySuggestions.length > 0 && (
          <section className="break-after-page rounded-xl bg-white box-border flex flex-col items-start justify-start py-[19px] px-3.5 max-w-full text-left text-base text-gray-900 font-inter border-[0.5px] border-solid border-gray-100 w-full">
            <b className="w-full relative inline-block max-w-full m-3">STAY</b>

            {/* Map through staySuggestions to display each one */}
            {staySuggestions.map((index: number) => (
              <StayInvoiceDetails key={index} invoice={invoice} />
            ))}
          </section>
        )}
      </>
      <>
        {carSuggestions && carSuggestions.length > 0 && (
          <section className="break-after-page rounded-xl bg-white box-border flex flex-col items-start justify-start py-[19px] px-3.5 max-w-full text-left text-base text-gray-900 font-inter border-[0.5px] border-solid border-gray-100 w-full">
            <b className="w-full relative inline-block max-w-full m-3">CAR</b>

            {/* Map through staySuggestions to display each one */}
            {carSuggestions.map((index: number) => (
              <CarInvoiceDetails key={index} invoice={invoice} />
            ))}
          </section>
        )}
      </>
      <>
        {trainSuggestions && trainSuggestions.length > 0 && (
          <section className="break-after-page rounded-xl bg-white box-border flex flex-col items-start justify-start py-[19px] px-3.5 max-w-full text-left text-base text-gray-900 font-inter border-[0.5px] border-solid border-gray-100 w-full">
            <b className="w-full relative inline-block max-w-full m-3">TRAIN</b>

            {/* Map through staySuggestions to display each one */}
            {trainSuggestions.map((index: number) => (
              <TrainInvocieDetails key={index} invoice={invoice} />
            ))}
          </section>
        )}
      </>
      <>
        {flightSuggestions && flightSuggestions.length > 0 && (
          <section className="break-after-page rounded-xl bg-white box-border flex flex-col items-start justify-start py-[19px] px-3.5 max-w-full text-left text-base text-gray-900 font-inter border-[0.5px] border-solid border-gray-100 w-full">
            <b className="w-full relative inline-block max-w-full mb-5">
              FLIGHT SUGGESTION
            </b>

            {/* Map through staySuggestions to display each one */}
            {flightSuggestions.map((index: number) => (
              <FlightInvoiceSuggestion key={index} invoice={invoice} />
            ))}
          </section>
        )}
      </>
    </>
  );
};

export default InvoiceDetailPage;
