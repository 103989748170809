import { useEffect, useState } from "react";
import { Button, LinearProgress } from "@mui/material"
import {
    ADD_COMMUNITIES,
    ADD_COMMUNITIES_USER,
} from "~/graphql/brd_communities/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import {
    GET_ALL_COMMUNTITIES_WITHOUT_OFFSET,
    GET_COMMUNTITIES_USER,
} from "~/graphql/brd_communities/Subscription";
import { GET_ALL_INQUIRIES_QUERY } from "~/modules/DashboardModule/segments/DashboardStats/components/Charts/graphql/Query";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { toast } from "react-toastify";
import { ADD_MAILJET_CONTACT_LIST } from "~/graphql/add_mailjet_contactList/Mutation";

const GetContactsList = ({ setGenerating, setProgress, setGenText }: any) => {

    const { brands }: any = brandsStore();
    const [addContacts] = useMutation(ADD_MAILJET_CONTACT_LIST)
    const client = useApolloClient();
    const [uniqueDestAndBrdIds, setUniqueDestAndBrdIds] = useState<any>([]);
    const [insertCommunities] = useMutation(ADD_COMMUNITIES);
    const [insertCommunitieUser] = useMutation(ADD_COMMUNITIES_USER);
    const defaultRole = useUserDefaultRole();
    const userId = useUserId();

    useEffect(() => {
        setProgress(0);
        const brdIds = brands.map((item: any) => item.id);

        client
            .query({
                query: GET_ALL_INQUIRIES_QUERY,
                variables: {
                    condition: {
                        brd_id: { _in: brdIds },
                        ...(defaultRole === "consultant" && { picked_by: { _eq: userId } }),
                    },
                },
                fetchPolicy: "network-only",
            })
            .then((inquiryData) => {
                const inquiriesList = inquiryData?.data?.inq_list || [];

                const addedToValues: any = {};
                inquiriesList.forEach(({ to, brd_id }: any) => {
                    if (!addedToValues[to]) {
                        addedToValues[to] = new Set();
                    }
                    if (!addedToValues[to].has(brd_id)) {
                        setUniqueDestAndBrdIds((prev: any) => [...prev, { to, brd_id }]);
                        addedToValues[to].add(brd_id);
                    }
                });
            });

    }, [brands]);


    const reGenerateComu = async () => {
        if (brands.length !== 1) {
            toast.error("Select only One Brand!");
            return;
        }
        setGenerating(true);
        const totalItems = uniqueDestAndBrdIds.length;

        for (const [index, item] of uniqueDestAndBrdIds.entries()) {
            try {
                const communities = await client.query({
                    query: GET_ALL_COMMUNTITIES_WITHOUT_OFFSET,
                    variables: {
                        condition: {
                            _and: [
                                { name: { _eq: item?.to } },
                                { brd_id: { _eq: item?.brd_id } },
                            ],
                        },
                    },
                    fetchPolicy: "network-only",
                });

                const inqData = await client.query({
                    query: GET_ALL_INQUIRIES_QUERY,
                    variables: {
                        condition: {
                            to: { _eq: item?.to },
                            _and: { brd_id: { _eq: item?.brd_id } },
                        },
                    },
                    fetchPolicy: "network-only",
                });

                const userIDs =
                    inqData?.data?.inq_list?.map((item: any) => item?.users?.id) || [];
                const uniqueUserIDs = Array.from(new Set(userIDs));

                const addConv = async () => {
                    const communityData = await insertCommunities({
                        variables: {
                            data: {
                                name: item?.to,
                                brd_id: item?.brd_id,
                            },
                        },
                    });

                    const communityId =
                        communityData?.data?.insert_brd_communities?.returning?.[0]?.id;

                    if (communityId) {
                        await client.refetchQueries({ include: "all" });

                        await Promise.all(
                            uniqueUserIDs.map(async (userId) => {
                                const addUsers = await insertCommunitieUser({
                                    variables: {
                                        data: {
                                            community_id: communityId,
                                            user_id: userId,
                                        },
                                    },
                                });
                                if (addUsers?.data?.insert_brd_community_users?.affected_rows) {
                                    await client.refetchQueries({ include: "all" });
                                }
                            })
                        );
                    }
                };

                const updateData = async () => {
                    await Promise.all(
                        uniqueUserIDs.map(async (userId) => {
                            const comUserData = await client.query({
                                query: GET_COMMUNTITIES_USER,
                                variables: {
                                    condition: {
                                        _and: [
                                            {
                                                community_id: {
                                                    _eq: communities?.data?.brd_communities?.[0]?.id,
                                                },
                                            },
                                            { user_id: { _eq: userId } },
                                        ],
                                    },
                                },
                                fetchPolicy: "network-only",
                            });

                            if (!comUserData?.data?.brd_community_users?.[0]?.id) {
                                const addUsers = await insertCommunitieUser({
                                    variables: {
                                        data: {
                                            community_id: communities?.data?.brd_communities?.[0]?.id,
                                            user_id: userId,
                                        },
                                    },
                                });
                                if (addUsers?.data?.insert_brd_community_users?.affected_rows) {
                                    await client.refetchQueries({ include: "all" });
                                }
                            }
                        })
                    );
                };

                if (
                    !communities?.data?.brd_communities ||
                    communities.data.brd_communities.length < 1
                ) {
                    await addConv();
                } else {
                    await updateData();
                }

                const newProgress = Math.round(((index + 1) / totalItems) * 98);
                setProgress(newProgress);
            } catch (error) { }
        }

        setGenText("Uploading to Mailjet. Please wait, it will take some time...")

        const result = await addContacts({
            variables: {
                brdId: brands?.[0]?.id
            }
        })

        if (result?.data?.add_mailJet_contactsList?.status === true) {
            setProgress(100)
            toast.success("Contacts Generated Successfully!")
            setGenerating(false);
        } else {
            setGenerating(false);
            toast.error("Re-generate Contacts Again!")
        }
    };

    return (
        <>
            <Button variant="contained" onClick={reGenerateComu}>
                Get Contacts List
            </Button>
        </>
    )
}

export default GetContactsList