import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useApolloClient } from "@apollo/client";
import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { getSocialAuthenticationInfo } from "~/utils/getSocialAuthenticationInfo";
import { getSocialAuthenticationInfoWithPlatform } from "~/utils/getSocialAuthenticationWithBrdIDAndPlatform";
import brandsStore from "~/store/brandsStore/brandsStore";
import { Checkbox, FormGroup, FormControlLabel, TextField, Typography } from "@mui/material";

const SocialMediaPostSchedulerHeader = ({
  setSelectedPlatform,
  selectedPlatform,
  setSelectedBrandId,
  selectedBrandId,
  control,
  setLoading,
  Loading,
}: any) => {
  // const { brands } = useAssigedBrands();
  const [socialMediaPlatforms, setSocialMediaPlatforms] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const client = useApolloClient();
  const { brands } = brandsStore()

  useEffect(() => {

    const brdIds = brands.map((item: any) => item.id)

    const fetchSocialAuthDataWithPlatform = async () => {
      setLoading(true);
      const { accountsData } = await getSocialAuthenticationInfoWithPlatform(
        brdIds,
        selectedPlatform,
        client
      );
      setAccounts(accountsData.length > 0 ? accountsData : []);
      setLoading(false);

    };

    fetchSocialAuthDataWithPlatform();
  }, [selectedPlatform, client, brands]);

  // const handleBrandChange = (
  //   event: React.SyntheticEvent,
  //   newValue: any | null
  // ) => {
  //   if (newValue) {
  //     setSelectedBrandId(newValue.id);
  //   } else {
  //     setSelectedBrandId(null);
  //     setSocialMediaPlatforms([]);
  //     setAccounts([]);
  //     setSelectedPlatform([]);
  //   }
  // };

  useEffect(() => {

    const brdIds = brands.map((item: any) => item.id)

    const runFunc = async () => {
      const res = await getSocialAuthenticationInfo(client, brdIds);
      const uniquePlatform = Array.from(new Set(res?.values));
      setSocialMediaPlatforms(uniquePlatform);
    };
    runFunc();
  }, [brands]);

  return (
    <div className="p-4 pb-2 block sm:flex items-center justify-between lg:mt-1.5 relative">
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column", // Stack items vertically on extra small screens
            sm: "column", // Stack items vertically on small screens
            md: "row", // Align items in rows on medium screens and above
          },
          flexWrap: "wrap", // Allow items to wrap to the next line when they overflow
          gap: 2,
          width: "100%",
        }}
      >
        {/* Brand Selector */}
        {/* <Controller
          control={control}
          name="brandSelect"
          rules={{ required: true }}
          render={({ field: { onChange, value, ...field } }) => (
            <Autocomplete
              disablePortal
              id="brandSelect"
              options={brands}
              onChange={(event, newValue: any) => {
                handleBrandChange(event, newValue);
                onChange(newValue ? newValue?.id : null);
              }}
              getOptionLabel={(option: any) => option?.name}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} {...field} label="Brands" />
              )}
            />
          )}
        /> */}

        {/* Platform Multi-select */}
        {/* <Controller
          control={control}
          name="platform"
          render={({ field: { onChange, value, ...field } }) => (
            <Autocomplete
              multiple
              id="platform-selector"
              options={socialMediaPlatforms}
              onChange={(event, newValue) => {
                const uniquePlatforms = Array.from(new Set(newValue));
                setSelectedPlatform(uniquePlatforms);
                onChange(uniquePlatforms);
                if (uniquePlatforms.length === 0) {
                  setAccounts([]);
                }
              }}
              getOptionLabel={(option: string) => option}
              value={selectedPlatform}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} {...field} label="Select Platforms" />
              )}
            />
          )}
        /> */}

        <Controller
          control={control}
          name="platform"
          render={({ field: { onChange, value = [], ...field } }) => (
            <div>
              <Typography variant="h6" sx={{ color: "gray", marginBottom: "10px" }}>
                Select Platforms
              </Typography>
              {socialMediaPlatforms.length > 0 ? (
                <FormGroup row>
                  {socialMediaPlatforms.map((platform) => (
                    <FormControlLabel
                      key={platform}
                      sx={{ color: "gray", fontWeight: "100" }}
                      control={
                        <Checkbox
                          checked={value.includes(platform)}
                          sx={{ color: "gray" }}
                          onChange={(e) => {
                            const selectedPlatforms = e.target.checked
                              ? [...value, platform]
                              : value.filter((p: string) => p !== platform);

                            // Update the state for selected platforms
                            setSelectedPlatform(selectedPlatforms);
                            onChange(selectedPlatforms);

                            // If no platforms are selected, reset accounts
                            if (selectedPlatforms.length === 0) {
                              setAccounts([]);
                            }
                          }}
                        />
                      }
                      label={platform}
                    />
                  ))}
                </FormGroup>
              ) : (
                <Typography variant="body1" sx={{ color: "gray" }}>
                  No platforms are connected
                </Typography>
              )}
            </div>
          )}
        />
        {/* Accounts Multi-select */}
        <Controller
          control={control}
          name="accounts"
          rules={{ required: true }}
          render={({ field: { onChange, value, ...field } }) => (
            <Autocomplete
              multiple
              id="account-selector"
              options={accounts}
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              getOptionLabel={(option: any) => option?.label}
              value={value || []}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} {...field} label="Select Accounts" />
              )}
            />
          )}
        />
      </Box>
    </div>
  );
};

export default SocialMediaPostSchedulerHeader;
