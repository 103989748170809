import Layout from "~/components/common/Layout";
import React from "react";
import AccountModule from "~/modules/AccountModule";
import GeneratePayroll from "~/modules/AccountModule/segments/GeneratePayroll";
import PaymentRequest from "~/modules/AccountModule/segments/PaymentRequest";
import Payroll from "~/modules/AccountModule/segments/Payroll";
import UsersModule from "~/modules/UsersModule";

export default function ProcessPayrollPage() {
  return (
    <div>
      <Layout>
        <AccountModule>
          <Payroll />
        </AccountModule>
      </Layout>
    </div>
  );
}
