import React from "react";

class TwilioCallButton extends React.Component {
  //     makeTwilioCall = () => {
  //         fetch('https://f99e-2407-aa80-314-956a-edad-b710-5f56-54b0.ngrok-free.app/voice', {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({
  //                 to: 'recipient_phone_number',
  //             }),
  //         });
  //     };
  // makeTwilioCall = () => {
  //     const accountSid = 'ACab98716322f5ab61b2daf439b90e957d';
  //     const authToken = 'b92a169498dcb03db427e36fee5f6067';
  //     fetch(`https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Calls.json`, {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/x-www-form-urlencoded',
  //             'Authorization': 'Basic ' + btoa(`${accountSid}:${authToken}`),
  //         },
  //         body: new URLSearchParams({
  //             'Url': 'https://f99e-2407-aa80-314-956a-edad-b710-5f56-54b0.ngrok-free.app/voice',
  //             'To': '+447700080781',
  //             'From': '+447723440105',
  //         }),
  //     })
  //         .then(response => response.json())
  //         .then(data =>
  //         .catch(error => console.error('Error:', error));
  // };
  render() {
    return (
      <div>
        {/* <button onClick={this.makeTwilioCall}>Make Twilio Call</button> */}
      </div>
    );
  }
}

export default TwilioCallButton;
