import React from "react";
import moment from "moment";
import { splitLocation } from "~/utils/splitLocation";
import vector81 from "../../../images/dottedImg.svg";
import TrainIcon from "@mui/icons-material/Train";
const TrainInvocieDetails = ({ invoice }: any) => {
  const trainSuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "train"
  )?.[0]?.train_inq_suggestion;
  //where to
  // const { cityName: originCity, remainingAddress: originAddress } =
  //   splitLocation(trainSuggestions?.origin);
  // // // hotel name
  // const { cityName: destCity, remainingAddress: destAddress } = splitLocation(
  //   trainSuggestions?.destination
  // );
  // console.log("Filtered stay suggestions:", trainSuggestions);

  if (!trainSuggestions) return <div>No Train suggestions found</div>;

  return (
    <div className="w-full flex flex-col items-start justify-start">
      <div className="self-stretch rounded-xl flex flex-col items-start justify-start max-w-full">
        <div className="self-stretch bg-[#F9FAFC] flex flex-row w-full  items-center justify-between py-[5px] px-2.5 box-border [row-gap:20px]  max-w-full gap-[0px]">
          <b className="w-full relative inline-block max-w-full">Origin</b>
          <div className="flex w-full flex-row items-center justify-end py-0 pr-0 pl-[193px] box-border gap-[5px]  max-w-full text-xs mq450:flex-wrap">
            <div className="relative font-semibold inline-block min-w-[54px] capitalize">
              {trainSuggestions?.cabin_class}
              {/* Destination */}
            </div>
          </div>
        </div>
        <div className="self-stretch bg-white flex flex-row flex-wrap items-center justify-center p-5 box-border gap-[15px] max-w-full text-5xl">
          <div className="flex-1 flex flex-col items-start justify-start gap-[40px] min-w-[442px] max-w-full mq675:min-w-full">
            <div className="self-stretch flex flex-row items-center justify-start [row-gap:20px] mq675:flex-wrap">
              <div className="flex flex-col items-start justify-center gap-[10px]">
                <div className="flex flex-row items-end justify-start py-0 pr-[37px] pl-0">
                  <div className="relative font-medium inline-block shrink-0 mq450:text-lgi text-[24px]">
                    {trainSuggestions?.origin}
                  </div>
                  {/* <h3 className="m-0 relative text-sm font-normal font-inherit inline-block min-w-[75px]">
                    {`(${originAddress})`}
                  </h3> */}
                </div>
                <div className="relative text-xs text-gray-600 whitespace-nowrap">
                  {moment(trainSuggestions?.start_date)
                    .zone(0)
                    .format("DD-MM-YYYY")}{" "}
                  |{" "}
                  {moment(trainSuggestions?.start_date).zone(0).format("HH:mm")}
                </div>
              </div>
              <img
                className="h-px flex-1 relative max-w-full w-full overflow-hidden "
                alt="dotted line"
                src={vector81}
              />
              <div className="flex flex-col items-end justify-start">
                <div className="flex flex-col items-end justify-center gap-[10px]">
                  <div className="flex flex-row items-end justify-end py-0 pr-0 pl-[37px]">
                    <h1 className="m-0 relative text-inherit font-medium font-inherit inline-block  mq450:text-lgi text-[24px]">
                      {trainSuggestions?.destination}
                    </h1>
                    {/* <h3 className="m-0 relative text-sm font-normal font-inherit inline-block min-w-[63px]">
                      {`(${destAddress})`}
                    </h3> */}
                  </div>
                  <div className="relative text-xs text-gray-600 text-right">
                    {moment(trainSuggestions?.end_date)
                      .zone(0)
                      .format("DD-MM-YYYY")}{" "}
                    |{" "}
                    {moment(trainSuggestions?.end_date).zone(0).format("HH:mm")}
                  </div>
                </div>
              </div>
            </div>

            <div className="self-stretch flex flex-row items-center justify-between text-sm">
              <div className="w-[284px] flex flex-row items-center justify-start gap-[41px]">
                <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                  <div className="flex-1 relative flex items-center">
                    <TrainIcon fontSize="medium" style={{ color: "black" }} />
                    <span className="ml-2">{trainSuggestions?.train_name}</span>
                  </div>
                </div>
              </div>

              {/* Pricing details aligned to the right */}
              {Array.isArray(trainSuggestions?.train_inq_suggestion_costs) && (
                <>
                  <div
                    className="self-stretch flex flex-col items-end text-sm gap-1"
                    style={{ width: "100%" }}
                  >
                    <div className="text-right flex flex-row items-center justify-between">
                      <div className="font-medium mx-3">Total Sale Price</div>
                      <b>
                        £{" "}
                        {trainSuggestions.train_inq_suggestion_costs.reduce(
                          (total: number, cost: any) =>
                            total + parseFloat(cost?.sale_price || 0),
                          0
                        )}
                      </b>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainInvocieDetails;
