import { TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import brandsStore from '~/store/brandsStore/brandsStore';
import { useState } from 'react';
import { ADD_MAILJET_SENDER } from '~/graphql/add_mailjet_sender/Mutation';
import { useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const AddSender = ({ setSidePopup, refetchSenders }: any) => {

    const { brands } = brandsStore();
    const { register, handleSubmit } = useForm();
    const [selectedBrand, setSelectedBrand] = useState("");
    const [addSender] = useMutation(ADD_MAILJET_SENDER)
    const [loading, setLoading] = useState(false)

    const handleBrandChange = (event: any) => {
        setSelectedBrand(event.target.value);
    };

    const onSubmit = async (data: any) => {
        setLoading(true)
        const res = await addSender({
            variables: {
                brdId: selectedBrand,
                name: data.senderName,
                email: data.senderEmail
            }
        })

        if (res?.data?.add_mailjet_sender?.Data.length > 0) {
            toast.success("Sender Added Successfully!")
            setSidePopup(false)
            refetchSenders();
            setLoading(false)
        }
    }

    return (
        <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
            <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
                Add Sender
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth variant="outlined" style={{ marginBottom: "16px" }}>
                    <InputLabel>Select Brand</InputLabel>
                    <Select
                        value={selectedBrand}
                        onChange={handleBrandChange}
                        label="Brand"
                        required
                    >
                        {brands.map((brand: any) => (
                            <MenuItem key={brand.id} value={brand.id}>
                                {brand.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Sender Name"
                    variant="outlined"
                    fullWidth
                    {...register("senderName")}
                    required
                    style={{ marginBottom: "16px" }}
                />
                <TextField
                    label="Sender Email"
                    variant="outlined"
                    fullWidth
                    {...register("senderEmail")}
                    type="email"
                    required
                    style={{ marginBottom: "16px" }}
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    {loading ? "Adding..." : "Add"}
                </Button>
            </form>
        </div>
    );
};

export default AddSender;
