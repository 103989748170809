import { gql } from "@apollo/client";

export const INSERT_FINGERPRINT_IDS = gql`
  mutation insertFingerPrintIds($fingerprintID: String!, $userID: uuid!) {
    insert_user_fingerprint_ids(
      objects: { fingerprint_id: $fingerprintID, user_id: $userID }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_FINGERPRINT_IDS_STATUS = gql`
  mutation updateFingerPrintIdsStatus($status: String!, $fingerPrintId: uuid!) {
    update_user_fingerprint_ids(
      where: { id: { _eq: $fingerPrintId } }
      _set: { status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;
