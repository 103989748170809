// src/utils/amadeusAPI.js

export const getAccessToken = async () => {
  try {
    const response = await fetch(
      "https://test.api.amadeus.com/v1/security/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: "cecfKa6H8DmLCwy7JGGGDo0crtZEagLa",
          client_secret: "7YriAAfEw2tS3oH6",
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch access token");
    }

    const data = await response.json();
    console.log("Access token:", data);
    return data.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};
