import { gql } from "@apollo/client";

export const UPDATE_INQ_STATUS = gql`
  mutation EditInquiry($invoiceId: uuid!) {
    update_inq_list(
      where: { invoice_id: { _eq: $invoiceId } }
      _set: { status: "awaiting_for_sign" }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_INQ_UPDATE_USER = gql`
  mutation EditInquiry($id: uuid, $updated_by: uuid) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { updated_by: $updated_by }
    ) {
      returning {
        id
      }
    }
  }
`;
