import React from "react";
import Layout from "~/components/common/Layout";
import EmailModule from "~/modules/EmailModule";
import EmailWindow from "~/modules/EmailModule/segments/EmailWindow";

export default function EmailPage() {
  return (
    <div>
      <Layout>
        <EmailModule>
          <EmailWindow />
        </EmailModule>
      </Layout>
    </div>
  );
}
