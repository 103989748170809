import React from "react";
import { useUserData } from "@nhost/react";
import UserCard from "~/components/UserCard";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_BRANDS } from "~/modules/BrandModule/graphql/Query";
import { Box, Button, Grid } from "@mui/material";
import { UPDATE_THP_LIST } from "~/graphql/thp_list/Mutation";
import { useNavigate } from "react-router-dom";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function Profile() {
  const navigate = useNavigate();
  const { travelHouse, setTravelHouse }: any = travelHouseStore();
  const { setBrands }: any = brandsStore();

  const { loading, data, error } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const userData = useUserData();
  const brandTypes = data?.brd_list
    .map((brand: any) => ({
      name: brand.def_brand_type.name,
      id: brand.def_brand_type.id,
    }))
    .filter(
      (value: any, index: number, self: any[]) =>
        index === self.findIndex((t) => t.name === value.name)
    );

  const [updateThpList] = useMutation(UPDATE_THP_LIST);

  const handleUpdate = (activeBrandId: string) => {
    updateThpList({
      variables: {
        activeBrand: activeBrandId,
        id: travelHouse.id,
      },
    })
      .then((response) => {
        setBrands([]);
        navigate("/verify");
      })
      .catch((error) => {
        console.error("Error updating:", error);
      });
  };

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Profile
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <UserCard key={1} user={userData} editAble={false} />

        <Box mt={4}>
          <Grid container spacing={1}>
            {brandTypes?.map((type: any, index: any) => (
              <Grid item key={index}>
                <Button
                  variant="contained"
                  onClick={() => {
                    // console.log("Brand ID", type.id);
                    // console.log("THP ID", travelHouse.id);
                    handleUpdate(type.id);
                  }}
                >
                  {type.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
}
