import { gql } from "@apollo/client";

export const INSERT_UNIQUE_INQ = gql`
  mutation insertUniqueInq($brdId: uuid!, $userId: uuid!, $inquiryNo: String!) {
    insert_inq_list_unique(
      objects: { brd_id: $brdId, user_id: $userId, inquiry_no: $inquiryNo }
    ) {
      affected_rows
    }
  }
`;
