import React, { useEffect, useState } from "react";
import SingleConversation from "./components/SingleConversation";
import { useApolloClient, useQuery, useSubscription } from "@apollo/client";
import { GET_CONVERSATIONS, GET_CONVERSATIONS_QUERY } from "./graphql/Query";
import Header from "./components/Header";
import SearchBar from "./components/SearchBar";
import SingleConversationSekeleton from "../sekeletons/SingleConversationSekeleton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import { IconButton, Tooltip } from "@mui/material";
import { useUserDefaultRole, useUserId } from "@nhost/react";

export default function ConversationList({ width = null, brd_ids }: any) {
  const [conversationSearchTerm, setConversationSearchTerm] = useState("");

  const userId = useUserId();
  const userRole = useUserDefaultRole();

  const [emailConv, setEmailConv] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState<any>("");

  const [offset, setOffset] = useState<any>(0);

  const client = useApolloClient();

  useEffect(() => {
    if (brd_ids?.length >= 1) {
      client
        .subscribe({
          query: GET_CONVERSATIONS,
          variables: {
            condition: {
              brd_id: { _in: brd_ids },
              ...(userRole !== "admin" && {
                user: { inq_lists: { picked_by: { _eq: userId } } },
              }),
            },
            offset: offset,
          },
        })
        .subscribe({
          next(conv: any) {
            setEmailConv([...emailConv, ...conv?.data?.brd_gmail_conversation]);
          },
        });
    }
  }, [brd_ids, offset]);

  useEffect(() => {
    setOffset(0);

    if (brd_ids?.length >= 1 && conversationSearchTerm?.length > 2) {
      client
        .query({
          query: GET_CONVERSATIONS_QUERY,
          variables: {
            condition: {
              brd_id: { _in: brd_ids },
              ...(conversationSearchTerm
                ? {
                    _or: [
                      { user_name: { _ilike: `%${conversationSearchTerm}%` } },
                      {
                        user_email: {
                          _ilike: `%${conversationSearchTerm}%`,
                        },
                      },
                    ],
                    ...(userRole !== "admin" && {
                      user: { inq_lists: { picked_by: { _eq: userId } } },
                    }),
                  }
                : {}),
            },
            offset: offset,
          },
        })
        .then((result) => {
          setEmailConv([...result?.data?.brd_gmail_conversation]);
        });
    }

    if (brd_ids?.length >= 1 && !conversationSearchTerm) {
      client
        .query({
          query: GET_CONVERSATIONS_QUERY,
          variables: {
            condition: {
              brd_id: { _in: brd_ids },
              ...(userRole !== "admin" && {
                user: { inq_lists: { picked_by: { _eq: userId } } },
              }),
            },
            offset: offset,
          },
        })
        .then((result) => {
          setEmailConv([...result?.data?.brd_gmail_conversation]);
        });
    }
  }, [conversationSearchTerm]);

  useEffect(() => {
    setTimeout(() => {
      if (errorMessage?.length < 1) {
        setErrorMessage("No Conversations Found");
      }
    }, 8000);
  }, [emailConv]);

  return (
    <div className="border dark:border-dark-secondary md:max-w-[450px] w-full relative h-full flex flex-col">
      <div>
        <Header />
        <SearchBar setSearch={setConversationSearchTerm} />
      </div>
      <div className="text-primary dark:text-dark-primary bg-whatsappSecondaryBg dark:bg-dark-secondary overflow-scroll flex-grow">
        {emailConv?.length < 1 ? (
          <>
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
            <SingleConversationSekeleton />
          </>
        ) : (
          emailConv?.map((conversation: any) => {
            return <SingleConversation conversation={conversation} />;
          })
        )}
        {errorMessage && emailConv?.length < 1 && (
          <div className="flex items-center justify-center gap-3 mt-12">
            <ErrorIcon color="error" />
            <p className="font-bold">{errorMessage}</p>
          </div>
        )}
        {!conversationSearchTerm && emailConv?.length > 0 && (
          <div className="flex py-3 justify-center items-center bg-whatsappSecondaryBg dark:bg-dark-secondary">
            <Tooltip title="Load More">
              <IconButton onClick={() => setOffset(offset + 10)}>
                <ExpandMoreIcon sx={{ cursor: "pointer" }} color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}
