export const CabinClasses = [
  {
    id: 1,
    title: "Economy",
    value: "economy",
  },
  {
    id: 2,
    title: "Premium Economy",
    value: "premiumEconomy",
  },
  {
    id: 3,
    title: "Business",
    value: "business",
  },
];
