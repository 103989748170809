import { gql } from "@apollo/client";

// export const INSERT_BRD_SOCIAL_AUTHENTICATION = gql`
//   mutation InsertSocialAuthentication(
//     $access_token: String
//     $profile_id: String
//     $platform: String
//     $accessToken_type: String
//     $brd_id: uuid
//     $user_name: String
//     $profile_picture: String
//   ) {
//     insert_brd_social_authentication(
//       objects: {
//         access_token: $access_token
//         platform: $platform
//         accessToken_type: $accessToken_type
//         brd_id: $brd_id
//         profile_id: $profile_id
//         user_name: $user_name
//         profile_picture: $profile_picture
//       }
//     ) {
//       affected_rows
//     }
//   }
// `;

export const INSERT_BRD_SOCIAL_AUTHENTICATION = gql`
  mutation InsertSocialAuthentication(
    $data: [brd_social_authentication_insert_input!]!
  ) {
    insert_brd_social_authentication(objects: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_SOCIAL_AUTHENTICATION = gql`
  mutation MyMutation(
    $data: brd_social_authentication_set_input!
    $profile_id: String!
    $brd_id: uuid!
  ) {
    update_brd_social_authentication(
      where: { profile_id: { _eq: $profile_id }, brd_id: { _eq: $brd_id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_SOCIAL_AUTHENTICATION = gql`
  mutation MyMutation($profile_id: String, $brd_id: uuid) {
    delete_brd_social_authentication(
      where: { profile_id: { _eq: $profile_id }, brd_id: { _eq: $brd_id } }
    ) {
      affected_rows
    }
  }
`;
