import { gql } from "@apollo/client";

export const GET_SEO_SETTINGS = gql`
  query GetSeoSettings($brdId: uuid!, $pageType: String!) {
    brd_services_seo_settings(
      where: { brd_id: { _eq: $brdId }, page_type: { _eq: $pageType } }
    ) {
      title
      description
      default_keywords
      id
      og_image_url
      canonical_url
      page_type
    }
  }
`;
