import { gql } from "@apollo/client";

export const GET_EMAIL_CAMP_CONVERSATION = gql`
  subscription Mysubscription($condition: brd_email_campaigns_bool_exp!) {
    brd_email_campaigns(where: $condition) {
      id
      user_email
      user_name
      last_email_subject
      last_email_time
      last_view_time
      iata
      emails_length
      brd_list {
        name
        gmail_refresh_token
      }
    }
  }
`;

export const GET_EMAIL_CAMP_MESSAGES = gql`
  subscription Mysubscription($conversation_id: uuid) {
    brd_email_campaign_messages(
      where: { conversation_id: { _eq: $conversation_id } }
      order_by: { timestamp: asc }
    ) {
      id
      from
      content
      subject
      timestamp
      created_at
      updated_at
      to
      message_id
      files
      history_id
    }
  }
`;
