import { FunctionComponent } from "react";
import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { getStyles } from "./styles";

export type InvoicePType = {
  inquiry?: any;
  invoice?: any;
  logo?: any;
  transID?: any;
};

const RecieptPdf: FunctionComponent<InvoicePType> = ({
  inquiry,
  invoice,
  logo,
  transID,
}) => {
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerFirstContainer}>
            {logo && <Image style={styles.logo} src={logo} />}

            <View style={styles.brandDetail}>
              <Text style={styles.brandName}>{inquiry?.brd_list?.name}</Text>
              <Text>www.{inquiry?.brd_list?.domain}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.email}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.phone}</Text>
            </View>
          </View>
          <View style={styles.headerSecondContainer}>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
            </Text>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
              {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
            </Text>
          </View>
        </View>
        <View style={styles.inquiryDetailContainer}>
          {inquiry?.inq_transections?.filter(
            (item: any) => item.status === "paid" || item.id === transID
          )?.length > 0 && (
            <View>
              <View style={styles.passengerHeader}>
                <Text>TRANSACTIONS ID</Text>
                <Text>PAYMENT MODE</Text>
                <Text>AMOUNT</Text>
              </View>
              {inquiry?.inq_transections
                ?.filter(
                  (item: any) => item.status === "paid" || item.id === transID
                )
                .map((item: any) => {
                  return (
                    <View style={styles.passengerBody}>
                      <Text> #{item.transactions_no}</Text>
                      <Text>{item.acc_payment_method.type}</Text>
                      <Text>£{item.amount}</Text>
                    </View>
                  );
                })}
            </View>
          )}
          <View style={styles.totalsContainer}>
            <View style={styles.totals}>
              <Text>Payables</Text>
              <Text>
                £
                {invoice?.inq_invoice_items?.reduce(
                  (sum: any, obj: any) => sum + parseFloat(obj.amount),
                  0
                ) + invoice?.invoice_suggestion?.bookingFee}
              </Text>
            </View>
            <View style={styles.paid}>
              <Text style={styles.boldFont}>Paid</Text>
              <Text style={styles.boldFont}>
                £
                {inquiry?.inq_transections
                  ?.filter(
                    (item: any) => item.status === "paid" || item.id === transID
                  )
                  .reduce((sum: any, item: any) => sum + item.amount, 0)}
              </Text>
            </View>
            <View style={styles.totals}>
              <Text style={styles.boldFont}>Balance Due</Text>
              <Text style={styles.boldFont}>
                £
                {invoice?.inq_invoice_items?.reduce(
                  (sum: any, obj: any) => sum + parseFloat(obj.amount),
                  0
                ) +
                  invoice?.invoice_suggestion?.bookingFee -
                  inquiry?.inq_transections
                    ?.filter(
                      (item: any) =>
                        item.status === "paid" || item.id === transID
                    )
                    .reduce((sum: any, item: any) => sum + item.amount, 0)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default RecieptPdf;
