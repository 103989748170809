import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GetUsers($role: String, $brd_id: uuid!) {
    users(
      where: {
        brd_users: {
          brd_id: { _eq: $brd_id }
          users: { defaultRole: { _eq: $role } }
        }
      }
    ) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query GetUsers($id: uuid) {
    users(where: { id: { _eq: $id } }) {
      email
      id
      displayName
      phoneNumber
      avatarUrl
      metadata
      defaultRole
      disabled

      brd_users {
        user_id
        brd_id
        thp_id
      }
      thp_users_profile {
        pseudo_name
        designation
        landline_number
      }
    }
  }
`;

export const GET_ALL_USER = gql`
  query GetUsers($role: String, $brd_id: uuid!) {
    users(where: { brd_users: { brd_id: { _eq: $brd_id } } }) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      avatarUrl
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;

export const GET_USERS = gql`
  subscription Mysubscription($condition: users_bool_exp!) {
    users(where: $condition) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      avatarUrl
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;

export const GET_BRAND_CONSULTANTS = gql`
  query getBrandConsultants($brdId: uuid!) {
    users(
      where: {
        brd_users: { brd_id: { _eq: $brdId } }
        defaultRole: { _eq: "consultant" }
      }
    ) {
      id
      displayName
    }
  }
`;

export const GET_TRANS_USERS = gql`
  query getTransUsers($brd_ids: [uuid!]!) {
    users(
      where: {
        brd_users: { brd_id: { _in: $brd_ids } }
        defaultRole: { _in: ["user", "vendor"] }
      }
    ) {
      id
      displayName
    }
  }
`;

export const GET_ALL_USER_INQUIRIES = gql`
  query getAllUserInquiries($user_id: uuid!, $brd_id: uuid!) {
    users(where: { id: { _eq: $user_id } }) {
      id
      inq_lists(where: { brd_id: { _eq: $brd_id } }) {
        direct_flight
        flexible_arrival
        flexible_departure
        approx_budget
        from
        inquiry_no
        preferred_airline
        source
        status
        to
        trip_type
        arrival_datetime
        created_at
        departure_datetime
        updated_at
        brd_id
        id
        source
        source_place
        source_position
        source_keyword
        source_add_id
        unique_inq {
          id
        }
        thp_inquiry_flag {
          color
          id
          name
        }
        inq_comments(order_by: { created_at: desc }, limit: 1) {
          comment
          thp_inquiry_flag {
            color
            id
            name
          }
          created_at
          id
          flag_id
          timestamp
          reminder_time
        }
        payment_method_id
        picked_by
        suggestion_id
        thp_id
        user_id
        brd_list {
          name
          domain
          subdomain
        }
        users {
          id
          email
          displayName
          metadata
          phoneNumber
          avatarUrl
          brd_users {
            user_id
            brd_id
            thp_id
          }
          inq_lists {
            picked_user {
              metadata
              thp_users_profile {
                pseudo_name
              }
            }
          }
        }
        picked_user {
          id
          avatarUrl
          metadata
          email
          displayName
          phoneNumber
          thp_users_profile {
            pseudo_name
          }
        }
        inq_passengers {
          type
        }
      }
    }
  }
`;

export const GET_ALL_BRANDS_CONS = gql`
  query getBrandConsultants($brd_ids: [uuid!]!) {
    users(
      where: {
        defaultRole: { _in: ["consultant", "admin"] }
        brd_users: { brd_id: { _in: $brd_ids } }
        disabled: { _eq: false }
      }
    ) {
      id
      displayName
      thp_users_profile {
        pseudo_name
      }
      picked_inqs(where: { brd_id: { _in: $brd_ids } }) {
        created_at
        status
        id
        completed_date
        inq_transections {
          status
          updated_at
          amount
          refund_amount
        }
        inq_tickets {
          status
        }
        inq_invoice {
          inq_invoice_items {
            amount
          }
        }
        selected_suggestion {
          bookingFee
          inq_suggestion_costs {
            basic_fare
            tax_price
            sale_price
            atol_price
          }
        }
      }
    }
  }
`;
