import { Autocomplete, Box, TextField } from "@mui/material";
import _airlineData from "../data/airlines.json";
import { Controller } from "react-hook-form";
import { useState } from "react";

const AirlineBox = ({
  register,
  control,
  errors,
  fieldName,
  label,
  defaultValue,
}: any) => {
  const dataFunction = () => {
    const data = _airlineData?.find(
      (option: any) => option?.iata === defaultValue
    );
    return data;
  };

  const data = dataFunction();

  const index: any = data ? _airlineData?.indexOf(data) : null;

  const [value, setValue] = useState(_airlineData?.[index]);

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        {...register(fieldName, { required: true })}
        render={({ field }: any) => (
          <Autocomplete
            {...field}
            disablePortal
            ref={field.ref}
            value={value}
            onChange={(_, data: any) => {
              field.onChange(data?.iata);
              setValue(data);
            }}
            filterOptions={(options, { inputValue }: any) => {
              // If input is empty, don't show any suggestions
              if (!inputValue.trim()) {
                return [];
              }

              // Filter options to only include those that start with the user's input
              const inputValueLowerCase = inputValue.toLowerCase();
              return options.filter((option) =>
                option.marketing_name
                  .toLowerCase()
                  .startsWith(inputValueLowerCase)
              );
            }}
            options={_airlineData ? _airlineData : []}
            getOptionLabel={(option: any) => option.marketing_name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.marketing_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                label={label}
                ref={field.ref}
                inputRef={(el) => {
                  field.ref(el);
                }}
                error={errors[fieldName] ? true : false}
                {...params}
              />
            )}
            noOptionsText=""
          />
        )}
      />
    </>
  );
};

export default AirlineBox;
