import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { ADD_AD } from "./graphql/Mutation";
import { useMutation } from "@apollo/client";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useRefetch from "~/hooks/useRealtime";

const Add = ({ setSidePopup }: any) => {
  const { brands }: any = useAssigedBrands();

  const [addGoogle] = useMutation(ADD_AD);
  const { travelHouse }: any = travelHouseStore();
  const refetch = useRefetch();

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [formValue, setFormValue] = useState({
    brand: "",
  });

  const foundBrand =
    brands && brands.find((brand: any) => brand.name === formValue.brand);
  const brd_id = foundBrand ? foundBrand.id : null;

  const onSubmit = async (formData: any) => {
    try {
      const res = await addGoogle({
        variables: {
          data: [
            {
              brd_id: brd_id,
              name: formData.name,
              ad_id: formData.addId,
            },
          ],
        },
      });
      //   ;
      await refetch();
      toast.success("Ad added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setSidePopup(false);
    } catch (e) {
      reset();
      console.error("error", e);
    }
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Add Account</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brand", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.name);
                    setFormValue({
                      ...formValue,
                      brand: data?.name,
                    });
                  }}
                  options={brands ? brands : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Brand"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brand"] && el.focus();
                      }}
                      error={errors["brand"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              error={errors["addId"] ? true : false}
              {...register("addId", { required: true })}
              label="Add Id"
              type="number"
            />
            <TextField
              error={errors["name"] ? true : false}
              {...register("name", { required: true })}
              label="Name"
            />
          </div>
          <Button
            type="submit"
            sx={{ width: "100%", marginTop: "25px" }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default Add;
