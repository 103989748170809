import React from "react";
import Layout from "~/components/common/Layout";
import AccountModule from "~/modules/AccountModule";
import PaymentMethodsList from "~/modules/AccountModule/segments/PaymentMethodsList";
export default function PaymentsMethods() {
  return (
    <div>
      <Layout>
        <AccountModule>
          <PaymentMethodsList />
        </AccountModule>
      </Layout>
    </div>
  );
}
