import { gql } from "@apollo/client";

export const ADD_AD= gql`
  mutation MyMutation2($data: [brd_google_ads_insert_input!]!) {
    insert_brd_google_ads(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
