import { useQuery } from "@apollo/client";
import { GET_BRANDS_PAYMENTS_METHODS } from "~/modules/AccountModule/segments/TransactionsList/components/VendorDeposit/graphql/Query";

export default function useBrandPaymentMethodName(brd_id: any) {
  const { data } = useQuery(GET_BRANDS_PAYMENTS_METHODS, {
    skip: !brd_id, // Don't run the query unless brdId is set
    variables: { brd_id: brd_id },
  });

  return { paymentMethodName: data?.acc_payment_methods };
}
