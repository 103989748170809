import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";
import InputField from "~/components/FormInputs/InputField";
import { MonthPicker } from "~/components/FormInputs/MonthPicker";

export default function SearchForm({
  date,
  setDates,
  onSearch,
  setLoadForm,
  control,
  errors,
  setValue,
  register,
}: any) {
  return (
    <>
      <div className="flex gap-2 w-[60%] mt-4">
        <MonthPicker
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="date"
          label="Select Date"
          maxDate={moment(new Date()).subtract(1, "days").format()}
        />
        <InputField
          type="number"
          register={register}
          errors={errors}
          label="Required Hours"
          fieldName="work_hours"
          placeHolder="Required Hours"
          labelClasses="mb-0"
          inputClasses="border-[#cfd4da]"
        />

        <button
          className="mt-[20px] btn bg-basic px-[10px] text-white rounded-md w-[350px] h-[40px] "
          type="button"
          onClick={() => {
            onSearch();
          }}
        >
          Get Record
        </button>
      </div>
    </>
  );
}
