import { gql } from "@apollo/client";

export const GET_FLIGHTS_PAGES = gql`
  query GetBrdFlightsPages($brd_id: uuid!) {
    brd_flights_pages(where: { brd_id: { _eq: $brd_id } }) {
      id
      brd_id
      canonical_url
      origin_iata
      destination_iata
      origin_name
      destination_name
      title
      description
      featured_image
      show_in_footer
      airline_iata
      base_price
      page_content
      created_at
      updated_at
      slug
      show_in_home
      button_text
      footer_text
      show_featured_destination
      blog_heading
      blog_sub_heading
      contact_heading
      contact_sub_heading
      tips_heading
      tips_sub_heading
    }
  }
`;
