// import React from "react";
// import { useMutation } from "@apollo/client";
// import { gql } from "@apollo/client";
// import {
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
// } from "@mui/material";
// import moment from "moment";

// const BreakRecords = ({ selectedRecord }: any) => {
//   console.log("data", selectedRecord);
//   const DELETE_BREAK = gql`
//     mutation deleteBreak($userId: uuid!) {
//       delete_thp_user_breaktime(where: { user_id: { _eq: $userId } }) {
//         affected_rows
//         returning {
//           id
//           user_id
//         }
//       }
//     }
//   `;
//   const [deleteBreak, { data, loading, error }] = useMutation(DELETE_BREAK);

//   const handleDelete = async (breakId: any) => {
//     try {
//       await deleteBreak({
//         variables: {
//           id: breakId,
//         },
//       });
//       // Handle success (e.g., refetch data, show success message)
//     } catch (error) {
//       console.error("Error deleting break:", error);
//     }
//   };

//   return (
//     <div>
//       <Typography id="modal-modal-title" variant="h6" component="h2">
//         Break Records for {selectedRecord?.user.displayName}
//       </Typography>
//       <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 300 }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               {/* <TableCell>Break No</TableCell> */}
//               <TableCell>Start Time</TableCell>
//               <TableCell>End Time</TableCell>
//               <TableCell>Total Minutes</TableCell>
//               <TableCell>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {selectedRecord?.breaks?.length > 0 ? (
//               selectedRecord.breaks.map((breakItem: any, idx: number) => (
//                 <TableRow key={idx}>
//                   {/* <TableCell>{idx + 1}</TableCell> */}
//                   <TableCell>
//                     {moment(breakItem.start_time).format("HH:mm:ss")}
//                   </TableCell>
//                   <TableCell>
//                     {" "}
//                     {moment(breakItem.end_time).format("HH:mm:ss")}
//                   </TableCell>
//                   <TableCell>{breakItem.break_time_minutes}</TableCell>
//                   <TableCell>
//                     <Button>Edit</Button>
//                     <Button onClick={() => handleDelete(breakItem.id)}>
//                       Delete
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={4} align="center">
//                   No breaks recorded.
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// };

// export default BreakRecords;

import React from "react";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import moment from "moment";

const BreakRecords = ({ selectedRecord }: any) => {
  const DELETE_BREAK = gql`
    mutation deleteBreak($id: uuid!) {
      delete_thp_user_breaktime(where: { id: { _eq: $id } }) {
        affected_rows
        returning {
          id
          user_id
        }
      }
    }
  `;

  const [deleteBreak, { data, loading, error }] = useMutation(DELETE_BREAK);

  const handleDelete = async (breakId: any) => {
    try {
      await deleteBreak({
        variables: {
          id: breakId,
        },
      });
      alert("done");
      // Handle success (e.g., refetch data, show success message)
    } catch (error) {
      console.error("Error deleting break:", error);
    }
  };

  return (
    <div>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Break Records for {selectedRecord?.user?.displayName || "User"}
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 300 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Total Minutes</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedRecord?.breaks?.length > 0 ? (
              selectedRecord.breaks.map((breakItem: any, idx: number) => (
                <TableRow key={idx}>
                  <TableCell>
                    {moment(breakItem.start_time).format("HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    {moment(breakItem.end_time).format("HH:mm:ss")}
                  </TableCell>
                  <TableCell>{breakItem.break_time_minutes}</TableCell>
                  <TableCell>
                    <Button>Edit</Button>
                    <Button onClick={() => handleDelete(breakItem.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No breaks recorded.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BreakRecords;
