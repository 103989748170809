import { MenuItem } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import GifBoxOutlinedIcon from "@mui/icons-material/GifBoxOutlined";

const Add = ({ setShowDocSndPrev }: any) => {
  return (
    <>
      <div className="py-[9px] px-1 text-[16px]">
        <MenuItem sx={{ paddingLeft: "8px", paddingRight: "32px" }}>
          <DescriptionOutlinedIcon sx={{ color: "purple" }} />
          <p className="ml-[12px]" onClick={() => setShowDocSndPrev(true)}>
            Document
          </p>
        </MenuItem>
        <MenuItem sx={{ paddingLeft: "8px", paddingRight: "32px" }}>
          <CollectionsOutlinedIcon sx={{ color: "blue" }} />
          <p className="ml-[12px]">Photos & Videos</p>
        </MenuItem>
        <MenuItem sx={{ paddingLeft: "8px", paddingRight: "32px" }}>
          <CameraAltOutlinedIcon sx={{ color: "purple" }} />
          <p className="ml-[12px]">Camera</p>
        </MenuItem>
        <MenuItem sx={{ paddingLeft: "8px", paddingRight: "32px" }}>
          <PermIdentityOutlinedIcon sx={{ color: "red" }} />
          <p className="ml-[12px]">Contact</p>
        </MenuItem>
        <MenuItem sx={{ paddingLeft: "8px", paddingRight: "32px" }}>
          <GifBoxOutlinedIcon sx={{ color: "green" }} />
          <p className="ml-[12px]">New Sticker</p>
        </MenuItem>
      </div>
    </>
  );
};

export default Add;
