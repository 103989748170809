import moment from "moment";
import React from "react";

export default function SingleRecord({ travelHouse, index }: any) {
  return (
    <tr className="">
      <td className=" py-4 text-sm font-medium text-gray-700  ">
        <div className="inline-flex items-center gap-x-3">
          <span>#{index + 1}</span>
        </div>
      </td>
      <td className="py-4 text-sm ">{travelHouse?.name}</td>
      <td className="py-4 text-sm ">
        {travelHouse?.def_subscription_plan?.plan_name || (
          <i className="text-[#c1750b] text-xs">Not Set</i>
        )}
      </td>
      <td className="py-4 text-sm text-left	">
        {travelHouse?.subdomain || (
          <i className="text-[#c1750b] text-xs">Not Set</i>
        )}
      </td>
      <td className=" py-4 text-sm   ">
        {travelHouse?.domain || (
          <i className="text-[#c1750b] text-xs">Not Set</i>
        )}
      </td>
      <td className=" py-4 text-sm   ">
        <span className="capitalize">
          {moment(travelHouse?.activated_at).format("DD MMM, YYYY HH:mm:ss")}
        </span>
      </td>

      <td className=" py-4 text-sm   ">
        {travelHouse?.suspended_at ? (
          moment(travelHouse?.suspended_at).format("DD MMM, YYYY HH:mm:ss")
        ) : (
          <i className="text-[#c1750b] text-xs">Not Yet</i>
        )}
      </td>
      <td className=" py-4 text-sm   ">
        {moment(travelHouse?.expiry).format("DD MMM, YYYY HH:mm:ss")}
      </td>
      <td className=" py-4 text-sm   ">
        {moment(travelHouse?.created_at).format("DD MMM, YYYY HH:mm:ss")}
      </td>

      <td className=" py-4 text-sm   ">
        <div className="flex items-center gap-2">
          <span
          // onClick={() => {
          //   setEdit(true);
          // }}
          >
            Detail
          </span>
        </div>
      </td>
    </tr>
  );
}
