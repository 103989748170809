import React from "react";
import Layout from "../../components/common/Layout";
import DomainsModule from "~/modules/DomainsModule";
import DomainsList from "~/modules/DomainsModule/segments/DomainsList";

export default function Domains() {
  return (
    <div>
      <Layout>
        <DomainsModule>
          <DomainsList />
        </DomainsModule>
      </Layout>
    </div>
  );
}
