import React from "react";
import InputField from "~/components/FormInputs/InputField";

export default function CostRow({
  suggestion_cost_detail,
  register,
  errors,
  index,
  watch,
  inquiry,
  type,
}: any) {
  console.log("asdnasmdas", type);
  const passenger = (() => {
    switch (type) {
      // case "Stay":
      //   return suggestion_cost_detail?.stay_inq_passenger;
      case "Cars":
        return suggestion_cost_detail?.car_inq_passenger;
      case "Train":
        return suggestion_cost_detail?.train_inq_passenger;
      default:
        return suggestion_cost_detail?.inq_passenger;
    }
  })();
  const basicFare = watch(`suggestion_cost.${index}.basic_fare`);
  const tax = watch(`suggestion_cost.${index}.tax_price`);
  const atol = watch(`suggestion_cost.${index}.atol_price`);
  console.log("Asdnabsdnamsbndm", passenger);
  return (
    <div className="flex flex-row gap-4">
      <div className="w-[60%] pt-[30px] text-primary dark:text-dark-primary">
        {passenger?.first_name + " " + passenger?.last_name} (
        {passenger?.type.toUpperCase()})
      </div>
      {/* Flight Offer Price */}
      <InputField
        label="Basic Fare"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Basic Fare"
        fieldName={`suggestion_cost.${index}.basic_fare`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Flight Cost Price */}
      <InputField
        label="Tax"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Tax"
        fieldName={`suggestion_cost.${index}.tax_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      <InputField
        label="Atol"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Atol"
        fieldName={`suggestion_cost.${index}.atol_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Flight Cost Price */}
      <InputField
        label={`Sale Price ( cost - ${
          (basicFare ? parseFloat(basicFare) : 0) +
          (tax ? parseFloat(tax) : 0) +
          (atol ? parseFloat(atol) : 0)
        })`}
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`suggestion_cost.${index}.sale_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
        disabled={inquiry?.invoice_id ? true : false}
      />
    </div>
  );
}
