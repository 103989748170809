import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { getTimeReadableFormatInq } from "~/utils/GetTimeReadable";

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'id', flex: 1 },
  { 
    field: 'lead', 
    headerName: 'Lead No.', 
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">{params.value.leadNo || 'N/A'}</Typography>
        <Typography variant="body2" color="textSecondary">{params.value.date || 'N/A'}</Typography>
        <Typography variant="body2" color="textSecondary">{params.value.time || 'N/A'}</Typography>
      </Box>
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">{params.value.displayName || 'N/A'}</Typography>
        <Typography variant="body2" color="textSecondary">{params.value.email || 'N/A'}</Typography>
        <Typography variant="body2" color="textSecondary">{params.value.phoneNumber || 'N/A'}</Typography>
      </Box>
    ),
  },
  { field: 'source', headerName: 'Source', flex: 1 },
  { field: 'consultant', headerName: 'Consultant', flex: 1 },
  { field: 'service', headerName: 'Service', flex: 1 },
  { field: 'brand', headerName: 'Brand', flex: 1 },
];

const paginationModel = { page: 0, pageSize: 5 };

const LeadsTable = ({ leads }: any) => {  

  const rows = leads?.map((lead: any) => {
    
    const date = moment(lead?.created_at).format("YYYY-MM-DD") 
    const time = getTimeReadableFormatInq(lead?.created_at) 

    return {
      id: lead?.id,
      lead: {
        leadNo: lead?.lead_no,
        date: date,
        time: time,
      },
      user: {
        displayName: lead.user?.displayName,
        email: lead.user?.email,
        phoneNumber: lead.user?.phoneNumber,
      },
      source: lead.source,
      consultant: lead.consultant ? lead.consultant.name : 'N/A',
      service: lead.service.title,
      brand: lead.brand.name,
    };
  }) || [];

  return (
    <Paper sx={{ width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        rowHeight={80}
        sx={{ border: 0 }}
      />
    </Paper>
  );
};

export default LeadsTable;

