import { gql } from "@apollo/client";

export const GET_INQ_FLAGS = gql`
  query MyQuery($id: uuid!) {
    thp_inquiry_flags(where: { thp_id: { _eq: $id } }) {
      id
      name
      thp_id
      color
    }
  }
`;
