import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import SeasonalFares from "../../modules/BrandModule/segments/SeasonalFares";
import BrandPartner from "~/modules/ServicesModule/segments/BrandPartner";
export default function BrandPartnersPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <BrandPartner />
        </BrandModule>
      </Layout>
    </div>
  );
}
