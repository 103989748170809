import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_PAYMENT_METHODS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserDefaultRole } from "@nhost/react";
// Import components
import PaymentMethodCard from "./components/PaymentMethodCard";
import SidePopup from "~/components/common/SidePopup";
import AddPaymentMethod from "./components/PaymentMethodAdd/addPaymentMethod";
import PaymentMethodTable from "./components/paymentMethodTable";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function PaymentMethodsList() {
  // const { travelHouse }: any = travelHouseStore();
  const { brands } = brandsStore()
  const userDefaultRole = useUserDefaultRole();

  const { loading, data, error } = useQuery(GET_PAYMENT_METHODS, {
    variables: { 
      brd_ids: brands.map((brand: any) => brand.id), 
      role: userDefaultRole 
    },
  });

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <div>
        {/* {data.acc_payment_methods.map((payment_method: any, index: any) => (
          <PaymentMethodCard
            key={index}
            payment_method={payment_method}
            payment_methods={data.acc_payment_methods}
          />
        ))} */}

        <PaymentMethodTable data={data?.acc_payment_methods} />

        <SidePopup component={<AddPaymentMethod />} />
        {/* No Record Found */}
        {data.acc_payment_methods.length === 0 && <h2>No record found</h2>}
      </div>
    </>
  );
}
