import React, { useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_SUGGESTION } from "../graphql/Mutation";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import moment from "moment";

const CarSuggestionBox = ({
  suggestion,
  selected_suggestion_id,
  selected,
  active,
  picked_by,
  user_email,
  brand_domain,
  brand_url,
  brand_name,
  user_id,
  inquiry,
  hideActions,
  setActive,
}: any) => {
  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showCostBreakdownModal, setShowCostBreakdownModal] = useState(false);
  const [deleteSuggestion] = useMutation(DELETE_SUGGESTION);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();

  const userRole = useUserDefaultRole();
  const userLoggedIn = useUserId();
  useEffect(() => {
    setActive(suggestion.id);
  }, [suggestion]);
  const totalCost =
    suggestion?.car_inq_suggestion_costs?.reduce((sum: any, obj: any) => {
      return (
        sum +
        (parseFloat(obj.basic_fare) || 0) + // Safely parse basic fare
        (parseFloat(obj.tax_price) || 0) // Safely parse tax price
      );
    }, 0) || 0; // Default to 0 if undefined

  const totalSale =
    suggestion?.car_inq_suggestion_costs?.reduce((sum: any, obj: any) => {
      return sum + (parseFloat(obj.sale_price) || 0); // Safely parse sale price
    }, 0) || 0; // Default to 0 if undefined

  return (
    <>
      {active && (
        <div className="w-full">
          <div
            className={` ${
              active ? `show` : `hide`
            } py-[25px] px-[20px] bg-white dark:bg-dark-secondary overflow-hidden flex flex-col gap-[20px] w-full text-left text-xs text-darkslategray shadow`}
          >
            {suggestion ? (
              <div className="flex flex-col gap-4">
                {/* Car Rental Details */}
                <div className="flex justify-between w-full">
                  <div className="flex flex-col">
                    <div className="text-basic dark:text-dark-primary w-full">
                      <b>From: </b>
                      <span>{suggestion.origin || "N/A"}</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-basic dark:text-dark-primary w-full">
                      <b>To: </b>
                      <span>{suggestion.destination || "N/A"}</span>
                    </div>
                  </div>
                </div>

                {/* Date Section */}
                <div className="flex justify-between w-full">
                  <div className="flex flex-col">
                    <div className="text-basic dark:text-dark-primary w-full">
                      <b>Start Date: </b>
                      <span>{suggestion.start_date || "N/A"}</span>
                    </div>
                    <div className="font-medium text-xl text-basic dark:text-dark-primary">
                      {moment(suggestion.pick_date_time)
                        .zone(0)
                        .format("HH:mm")}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-basic dark:text-dark-primary w-full">
                      <b>End Date: </b>
                      <span>{suggestion.end_date || "N/A"}</span>
                    </div>
                    <div className="font-medium text-xl text-basic dark:text-dark-primary">
                      {moment(suggestion.drop_datae_time)
                        .zone(0)
                        .format("HH:mm")}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="flex flex-col">
                    <div className="font-medium  text-basic dark:text-dark-primary">
                      <b>With Driver: </b>
                      {suggestion.with_driver ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-basic dark:text-dark-primary w-full">
                      <b>Car_Type </b>
                      <span>{suggestion.car_type || "N/A"}</span>
                    </div>
                  </div>
                </div>

                {/* Add any additional information you want to show */}
              </div>
            ) : (
              <p className="text-center">No suggestions available</p>
            )}
          </div>

          {/* Cost and Sale Price */}
          <div className="flex items-center justify-end h-24 bg-basic flex-1 px-5 rounded-b-lg">
            <div className="flex gap-10">
              <div className="text-white flex gap-1 items-center">
                <strong>Cost:</strong>
                <div className="text-2xl font-bold">£{totalCost}</div>
              </div>
              <div className="text-white flex gap-1 items-center">
                <strong>Sale:</strong>
                <div className="text-2xl font-bold">£{totalSale}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CarSuggestionBox;
