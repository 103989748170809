import { gql } from "@apollo/client";

export const GET_LINKEDIN_ORG_DATA = gql`
  query get_Linkedin_Org_Data($access_token: String!) {
    get_Linkedin_Org_Data(arg1: { access_token: $access_token }) {
      LinkedinOrgData {
        id
        logoUrl
        name
      }
    }
  }
`;
