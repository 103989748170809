import { gql } from "@apollo/client";

export const EMAIL_SYNC = gql`
  mutation MyMutation($data: brd_list_set_input!) {
    update_brd_list(where: { id: { _is_null: false } }, _set: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_DATA = gql`
  mutation updateBrdList($data: brd_list_set_input!, $id: uuid!) {
    update_brd_list(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
