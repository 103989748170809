import Layout from '~/components/common/Layout'
import BrandDetailsModule from '~/modules/BrandDetailsModule'
import BrandDetail from "~/modules/BrandDetailsModule/segments/components/BrandDetail/index"


export default function BrandDetails() {
  return (
    <div>
        <Layout>
            <BrandDetailsModule>
                <BrandDetail />
            </BrandDetailsModule>
        </Layout>
    </div>
  )
}
