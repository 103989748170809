import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { INSERT_SOCIAL_MEDIA, UPDATE_SOCIAL_MEDIA } from "./graphql/Mutation";
import { toast } from "react-toastify";

const SocialMediaLinks = ({ brand }: any) => {
  const media = brand?.brd_social_media?.at(0);

  const [insertSocialMedia] = useMutation(INSERT_SOCIAL_MEDIA);
  const [updateSocialMedia] = useMutation(UPDATE_SOCIAL_MEDIA);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    if (media?.id) {
      const res = await updateSocialMedia({
        variables: {
          id: { id: media?.id },
          data: {
            facebook: inpData?.facebook,
            instagram: inpData?.instagram,
            discord: inpData?.discord,
            youtube: inpData?.youtube,
            linkedin: inpData?.linkedin,
            tiktok: inpData?.tiktok,
            pinterest: inpData?.pinterest,
            x: inpData?.x,
          },
        },
      });

      if (res?.data?.update_brd_social_media_by_pk?.id) {
        toast.success("Social Media  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      }
    } else {
      const res = await insertSocialMedia({
        variables: {
          data: {
            brd_id: brand.id,
            facebook: inpData?.facebook,
            instagram: inpData?.instagram,
            discord: inpData?.discord,
            youtube: inpData?.youtube,
            linkedin: inpData?.linkedin,
            tiktok: inpData?.tiktok,
            pinterest: inpData?.pinterest,
            x: inpData?.x,
          },
        },
      });

      if (res?.data?.insert_brd_social_media_one?.id) {
        toast.success("Social Media  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      }
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Social Media Links</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              defaultValue={media?.facebook}
              {...register("facebook")}
              label="Facebook Link"
              type="link"
            />
            <TextField
              defaultValue={media?.instagram}
              {...register("instagram")}
              label="Instagram Link"
              type="link"
            />
            <TextField
              defaultValue={media?.youtube}
              {...register("youtube")}
              label="Youtube Link"
              type="link"
            />
            <TextField
              defaultValue={media?.discord}
              {...register("discord")}
              label="Discord Link"
              type="link"
            />
            <TextField
              defaultValue={media?.tiktok}
              {...register("tiktok")}
              label="Tiktok Link"
              type="link"
            />
            <TextField
              defaultValue={media?.pinterest}
              {...register("pinterest")}
              label="Pinterest Link"
              type="link"
            />
            <TextField
              defaultValue={media?.linkedin}
              {...register("linkedin")}
              label="Linkedin Link"
              type="link"
            />
            <TextField
              defaultValue={media?.x}
              {...register("x")}
              label="X Link"
              type="link"
            />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save Links
          </Button>
        </Box>
      </div>
    </>
  );
};

export default SocialMediaLinks;
