import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import { DELETE_BRD_SERVICES_WITH_ID } from "~/graphql/brd_services/Mutation";
import { GET_SERVICES_WITH_BRD_ID } from "~/graphql/brd_services/Query";
import { nhost } from "~/lib/nhost";
import SettingsIcon from "@mui/icons-material/Settings";

import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";

const ShowServices = ({ brdId }: any) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { data } = useQuery(GET_SERVICES_WITH_BRD_ID, {
    variables: { brd_id: brdId },
  });
  const [deleteServiceMutation] = useMutation(DELETE_BRD_SERVICES_WITH_ID);

  const deleteService = async (img_id: any, serviceId: any) => {
    const { error: imageDeleteError } = await nhost.storage.delete({
      fileId: img_id,
    });
    if (imageDeleteError) {
      toast.error("Error deleting image.");
    }

    try {
      const { data: deleteData } = await deleteServiceMutation({
        variables: { id: serviceId },
      });
      toast.success("Service deleted successfully.");
      await client.refetchQueries({
        include: "all",
      });
    } catch (error) {
      toast.error("Error deleting service.");
    }
  };

  const handleEdit = (id: any) => {
    navigate(`/admin/${brdId}/services/${id}/editservice`);
  };

  const handleRedirect = () => {
    navigate(`/admin/${brdId}/services/headingsettings/single-service-page`);
  };

  const handleAddService = (brd_id: any) => {
    navigate(`/admin/${brd_id}/services/addservice`);
  };

  return (
    <>
      <div className="w-full flex justify-between">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold text-primary dark:text-dark-primary">
            Services
          </h1>
          <Button onClick={handleRedirect} className="flex items-center">
            <SettingsIcon className="text-primary dark:text-dark-primary" />
          </Button>
        </div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleAddService(brdId)}
        >
          Add Service
        </Button>
      </div>
      <div className="mt-5">
        {data && data.brd_services.length > 0 ? (
          <Grid container spacing={2}>
            {data.brd_services.map((service: any) => {
              const limitedDescription =
                service.description.length > 100
                  ? `${service.description.substring(0, 100)}...`
                  : service.description;

              return (
                <Grid item xs={12} sm={6} md={4} key={service.id}>
                  <Card className="shadow-lg rounded-lg max-h-[400px]">
                    <CardMedia
                      component="img"
                      height="140"
                      image={`${process.env.REACT_APP_NHOST_STORAGE_URL}${service.img_id}`}
                      alt={service.title}
                      className="rounded-t-lg h-[140px] object-cover"
                    />
                    <CardContent className="flex flex-col">
                      <Typography
                        variant="h6"
                        component="h2"
                        className="font-bold mb-2"
                        style={{ paddingBottom: 8 }}
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="mb-2"
                        style={{ paddingBottom: 8 }}
                      >
                        {limitedDescription}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className="font-bold pb-2"
                        style={{ paddingBottom: 8 }}
                      >
                        Price:{" "}
                        <span className="font-bold">{service.price}</span>
                      </Typography>
                      <Grid container spacing={1} className="mt-2">
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => handleEdit(service.id)}
                          >
                            Update
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={() =>
                              deleteService(service.img_id, service.id)
                            }
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <p>No services available for the selected brands.</p>
        )}
      </div>
    </>
  );
};

export default ShowServices;
