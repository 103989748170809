import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Footer from "../../../../../../../../components/whatsapp/WhatsAppChattingBox/component/Footer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import SmAttachementBox from "./component/SmAttachementBox";
import { formatFileSize } from "~/utils/FileSizeReadAble";
import { useFileUpload, useNhostClient, useUserId } from "@nhost/react";
import { URLs } from "~/config/enums";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import moment from "moment";
import { useParams } from "react-router-dom";
import { INSERT_MESSAGES } from "../../graphql/Mutation";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";

const AttachemnetPrevSend = ({
  setFiles,
  files,
  setShowEmojis,
  showEmojis,
  attachmentType,
  messageType,
  sendAutoReplyMsg,
}: any) => {
  const [fileId, setFileId] = useState<any>(null);
  const [filesIds, setFilesId] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSending, setIsSending] = useState<any>(false);
  const [inputValue, setInputValue] = useState("");
  const { upload } = useFileUpload();

  const { conversation_id } = useParams();
  const [insertMessages] = useMutation(INSERT_MESSAGES);
  const userId = useUserId();

  const { handleSubmit } = useForm();

  const [fileDataSeparately, setFileDataSeparately] = useState(0);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const handleLogoUpload = async (event: any) => {
    const files = event.target.files;
    uploadFiles(files);
  };

  const uploadFiles = async (files: any) => {
    for (let i = 0; i < files.length; i++) {
      let file = files?.[i];
      file["id"] = generateRandom10DigitCode();
      setFiles((prev: any) => [...prev, file]);
      const res = await upload({ file });
      setFileId(res.id);
      setActiveIndex(activeIndex + 1);
    }
  };

  const removeFile = (filesToRemove: any) => {
    const updatedFiles = files.filter(
      (file: any) => filesToRemove?.id !== file?.id
    );

    // ;

    setFiles(updatedFiles);
  };

  async function sendMessage() {
    if (!isSending) {
      setIsSending(true);

      if (files.length > 0) {
        filesIds.map(async (file: any) => {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
          const payload: any = [
            {
              conversation_id,
              sender_role: "brand",
              sender_id: userId,
              text: file.id,
              type: attachmentType,
              timestamp: formattedTime,
            },
          ];
          const resMessagesInsert = await insertMessages({
            variables: { data: payload },
          });
        });
        setFiles([]);
        setFilesId([]);
      } else {
        const textmsg = inputValue.trim();
        if (textmsg !== "") {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
          const payload: any = [
            {
              conversation_id,
              sender_role: "brand",
              sender_id: userId,
              text: textmsg,
              type: "text",
              timestamp: formattedTime,
            },
          ];
          const resMessagesInsert = await insertMessages({
            variables: { data: payload },
          });
        }
      }
      setInputValue("");
      setIsSending(false);
    }
  }

  const submitAutoReplyMsg = async () => {
    await sendAutoReplyMsg({
      type: attachmentType,
      message: filesIds?.[0]?.id,
    });
    setFiles([]);
  };

  const uploadFile = async () => {
    const file = files?.[0];
    const res = await upload({ file });
    setFileId(res.id);
    const fileName = file.name.slice(0, file.name.lastIndexOf("."));
    setFilesId((prv: any) => [...prv, { id: res.id, name: fileName }]);
  };

  useEffect(() => {
    if (messageType === "autoReply") {
      uploadFile();
    }
  }, []);

  return (
    <form
      className={`flex flex-col transition-all ease-in-out duration-300 ${
        attachmentType === ("audio" || "video") &&
        "bg-whatsappSecondaryBg dark:bg-dark-secondary"
      }  ${files.length > 0 ? "h-full" : "h-0"}`}
      onSubmit={handleSubmit(
        messageType === "autoReply" ? submitAutoReplyMsg : sendMessage
      )}
    >
      <div className="flex items-center bg-black/5 p-4">
        <CloseIcon
          sx={{
            cursor: "pointer",
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
          }}
          onClick={async () => {
            setFiles([]);
          }}
        />
        <p className="text-center text-black dark:text-white w-full">
          {files && files[fileDataSeparately]?.name}
        </p>
      </div>
      <div className="flex-grow md:p-10 p-5 flex flex-col items-center justify-center">
        {attachmentType === "document" && (
          <>
            <InsertDriveFileIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                fontSize: "130px",
              }}
            />
            <div className="mt-2">
              <p className="text-center text-primary dark:text-dark-primary text-[23px]">
                No Preview available
              </p>
              <p className="text-center text-primary dark:text-dark-primary text-[15px]">
                {formatFileSize(files && files[fileDataSeparately]?.size)} -{" "}
                {files &&
                  files[fileDataSeparately]?.name.split(".")[1].toUpperCase()}
              </p>
            </div>
          </>
        )}
        {attachmentType === "image" && (
          <>
            {fileId ? (
              <img
                src={URLs.FILE_URL + fileId}
                className="max-w-[30%] max-h-[80%] object-cover"
              />
            ) : (
              <CircularProgress sx={{ color: "#00a884" }} />
            )}
          </>
        )}
        {attachmentType === "video" && (
          <>
            {fileId ? (
              <video controls>
                <source src={URLs.FILE_URL + fileId} type="video/mp4" />
              </video>
            ) : (
              <CircularProgress sx={{ color: "#00a884" }} />
            )}
          </>
        )}
        {attachmentType === "audio" && (
          <>
            {fileId ? (
              <audio controls>
                <source src={URLs.FILE_URL + fileId} type="audio/mpeg" />
              </audio>
            ) : (
              <CircularProgress sx={{ color: "#00a884" }} />
            )}
          </>
        )}
      </div>
      {/* <div>
        <Footer
          footerType="documentPreview"
          setShowEmojis={setShowEmojis}
          showEmojis={showEmojis}
        />
      </div> */}
      <div
        className={`py-5 mx-5 border-t flex items-center dark:border-dark-secondary ${
          messageType === "autoReply" && "justify-center"
        }`}
      >
        {messageType !== "autoReply" && (
          <>
            <div className="flex items-center justify-center gap-3 flex-grow">
              {files?.map((file: any, index: any) => (
                <SmAttachementBox
                  ind={index}
                  file={file}
                  darkMode={darkMode}
                  setFileDataSeparately={setFileDataSeparately}
                  setFileId={setFileId}
                  setFilesId={setFilesId}
                  fileId={fileId}
                  removeFile={removeFile}
                  attachmentType={attachmentType}
                  filesIds={filesIds}
                  setActiveIndex={setActiveIndex}
                  activeIndex={activeIndex}
                />
              ))}

              <div className="relative p-[7px] flex items-center justify-center rounded-md border-2 dark:border-dark-secondary border-gray-300 cursor-pointer z-10">
                <input
                  className="opacity-0 w-full h-full absolute top-0 left-0 cursor-pointer"
                  type="file"
                  multiple
                  onChange={handleLogoUpload}
                />
                <AddOutlinedIcon
                  sx={{
                    color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </>
        )}
        <button
          disabled={!fileId}
          type="submit"
          className="relative p-[13px] text-white bg-whatsappBasic rounded-full cursor-pointer flex items-center justify-center"
        >
          {messageType !== "autoReply" && (
            <div className="absolute top-[-5px] right-[-10px] px-2 rounded-full bg-black/80 dark:bg-white flex items-center justify-center shadow">
              {files?.length}
            </div>
          )}
          <SendOutlinedIcon />
        </button>
      </div>
    </form>
  );
};

export default AttachemnetPrevSend;
