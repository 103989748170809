import React from "react";
import Layout from "~/components/common/Layout";
import UsersModule from "~/modules/UsersModule";
import TeamList from "~/modules/UsersModule/segments/TeamList";
export default function Teams() {
  return (
    <div>
      <Layout>
        <UsersModule>
          <TeamList />
        </UsersModule>
      </Layout>
    </div>
  );
}
