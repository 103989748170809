import { convertToUTC0 } from "~/utils/convertToUTC0";

export const formatFormData = (suggestion: any) => {
  const generateStops = (suggestionStops: any) => {
    const stops: any = [];
    // if (suggestionStops.length > 0) {
    suggestionStops.map((stop: any) => {
      stops.push({
        id: stop?.id,
        airline: stop?.airline_iata,
        airlineNumber: stop?.flight_no,
        departCity: stop?.depart,
        departDateTime: convertToUTC0(stop?.depart_datetime),
        arrivalCity: stop?.arrival,
        arrivalDateTime: convertToUTC0(stop?.arrival_datetime),
      });
    });
    return stops;
  };
  const generateLeg = (suggestion_leg: any) => {
    return {
      id: suggestion_leg?.id,
      handCary: suggestion_leg?.hand_carry,
      luggage: suggestion_leg?.luggage,
      stops: generateStops(suggestion_leg?.inq_suggestion_leg_stops),
    };
  };

  const generateCost = (inq_suggestion_costs: any) => {
    const inq_suggestion_costs_return: any = [];
    inq_suggestion_costs.map((suggestion_cost: any) => {
      inq_suggestion_costs_return.push({
        id: suggestion_cost?.id,
        atol_price: suggestion_cost?.atol_price,
        tax_price: suggestion_cost?.tax_price,
        basic_fare: suggestion_cost?.basic_fare,
        sale_price: suggestion_cost?.sale_price,
        passenger_id: suggestion_cost?.passenger_id,
      });
    });

    return inq_suggestion_costs_return;
  };

  const defaultValue: any = {
    tripType: suggestion.inq_suggestion_legs.length,
    id: suggestion.id,
    cabinType: "economy",
    departLeg: generateLeg(suggestion.inq_suggestion_legs[0]),
    returnLeg:
      suggestion.inq_suggestion_legs.length > 1
        ? generateLeg(suggestion.inq_suggestion_legs[1])
        : {},
    suggestion_cost: generateCost(suggestion.inq_suggestion_costs),
    fareExpiry: suggestion.fare_expiry,
    invoiceDueDate: suggestion.due_date,
    pnr: suggestion.pnr,
    bookingFee: suggestion.bookingFee,
  };

  return defaultValue;
};
