import { useEffect, useState, useRef } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchBar from "../../../../../SearchBar";
import brandsStore from "~/store/brandsStore/brandsStore";
import { GET_ALL_INQUIRIES_QUERY } from "~/modules/DashboardModule/segments/DashboardStats/components/Charts/graphql/Query";
import {
  ADD_COMMUNITIES,
  ADD_COMMUNITIES_USER,
} from "~/graphql/brd_communities/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  GET_ALL_COMMUNTITIES,
  GET_ALL_COMMUNTITIES_WITHOUT_OFFSET,
  GET_COMMUNTITIES_USER,
} from "~/graphql/brd_communities/Subscription";
import { Button, LinearProgress } from "@mui/material";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import ChatRow from "../../../../../ChatRow";
import ErrorIcon from "@mui/icons-material/Error";
import ChatRowSekeleton from "~/components/whatsapp/SideBarSekeleton/component/ChatRowSekeleton";
import InfiniteScroll from "react-infinite-scroller";

const CommunityComp = ({ showCommunityBar, setShowCommunityBar }: any) => {
  const [brandIDs, setBrandIds] = useState<any>([]);
  const [generating, setGenerating] = useState(false);
  const [progress, setProgress] = useState<any>(0);
  const [inquiries, setInquiries] = useState<any>([]);
  const [uniqueDestAndBrdIds, setUniqueDestAndBrdIds] = useState<any>([]);
  const [insertCommunities] = useMutation(ADD_COMMUNITIES);
  const [insertCommunitieUser] = useMutation(ADD_COMMUNITIES_USER);
  const [conversations, setConversations] = useState<any>([]);
  const [convLoading, setConvLoading] = useState<any>(true);
  const [communitySearchTerm, setCommunitySearchTerm] = useState("");
  const scrollParentRef: any = useRef(null);
  const [hasMoreConv, setHasMoreConv] = useState<any>(true);

  const { brands } = brandsStore();

  const client = useApolloClient();

  const defaultRole = useUserDefaultRole();

  const userId = useUserId();

  useEffect(() => {
    setHasMoreConv(true);
    setConvLoading(true);
    setConversations([]);
    setProgress(0);
    const brdIds = brands.map((item: any) => item.id);

    setBrandIds(brdIds);

    if (brdIds.length < 1) {
      setConvLoading(false);
      setBrandIds([]);
      setHasMoreConv(false);
    }

    client
      .query({
        query: GET_ALL_INQUIRIES_QUERY,
        variables: {
          condition: {
            brd_id: { _in: brdIds },
            ...(defaultRole === "consultant" && { picked_by: { _eq: userId } }),
          },
        },
        fetchPolicy: "network-only",
      })
      .then((inquiryData) => {
        const inquiriesList = inquiryData?.data?.inq_list || [];
        setInquiries(inquiriesList);

        const addedToValues: any = {};
        inquiriesList.forEach(({ to, brd_id }: any) => {
          if (!addedToValues[to]) {
            addedToValues[to] = new Set();
          }
          if (!addedToValues[to].has(brd_id)) {
            setUniqueDestAndBrdIds((prev: any) => [...prev, { to, brd_id }]);
            addedToValues[to].add(brd_id);
          }
        });
      });
  }, [brands, communitySearchTerm]);

  const reGenerateComu = async () => {
    setGenerating(true);
    const totalItems = uniqueDestAndBrdIds.length;

    for (const [index, item] of uniqueDestAndBrdIds.entries()) {
      try {
        const communities = await client.query({
          query: GET_ALL_COMMUNTITIES_WITHOUT_OFFSET,
          variables: {
            condition: {
              _and: [
                { name: { _eq: item?.to } },
                { brd_id: { _eq: item?.brd_id } },
              ],
            },
          },
          fetchPolicy: "network-only",
        });

        const inqData = await client.query({
          query: GET_ALL_INQUIRIES_QUERY,
          variables: {
            condition: {
              to: { _eq: item?.to },
              _and: { brd_id: { _eq: item?.brd_id } },
            },
          },
          fetchPolicy: "network-only",
        });

        const userIDs =
          inqData?.data?.inq_list?.map((item: any) => item?.users?.id) || [];
        const uniqueUserIDs = Array.from(new Set(userIDs));

        const addConv = async () => {
          const communityData = await insertCommunities({
            variables: {
              data: {
                name: item?.to,
                brd_id: item?.brd_id,
              },
            },
          });

          const communityId =
            communityData?.data?.insert_brd_communities?.returning?.[0]?.id;

          if (communityId) {
            await client.refetchQueries({ include: "all" });

            await Promise.all(
              uniqueUserIDs.map(async (userId) => {
                const addUsers = await insertCommunitieUser({
                  variables: {
                    data: {
                      community_id: communityId,
                      user_id: userId,
                    },
                  },
                });
                if (addUsers?.data?.insert_brd_community_users?.affected_rows) {
                  await client.refetchQueries({ include: "all" });
                }
              })
            );
          }
        };

        const updateData = async () => {
          await Promise.all(
            uniqueUserIDs.map(async (userId) => {
              const comUserData = await client.query({
                query: GET_COMMUNTITIES_USER,
                variables: {
                  condition: {
                    _and: [
                      {
                        community_id: {
                          _eq: communities?.data?.brd_communities?.[0]?.id,
                        },
                      },
                      { user_id: { _eq: userId } },
                    ],
                  },
                },
                fetchPolicy: "network-only",
              });

              if (!comUserData?.data?.brd_community_users?.[0]?.id) {
                const addUsers = await insertCommunitieUser({
                  variables: {
                    data: {
                      community_id: communities?.data?.brd_communities?.[0]?.id,
                      user_id: userId,
                    },
                  },
                });
                if (addUsers?.data?.insert_brd_community_users?.affected_rows) {
                  await client.refetchQueries({ include: "all" });
                }
              }
            })
          );
        };

        if (
          !communities?.data?.brd_communities ||
          communities.data.brd_communities.length < 1
        ) {
          await addConv();
        } else {
          await updateData();
        }

        // Update progress
        const newProgress = Math.round(((index + 1) / totalItems) * 100);
        setProgress(newProgress); // Update the state
      } catch (error) {}
    }

    setGenerating(false); // End generating state when complete
  };

  const loadMoreConv = async () => {
    if (!hasMoreConv || brandIDs.length < 1) return;

    client
      .query({
        query: GET_ALL_COMMUNTITIES,
        variables: {
          condition: {
            brd_id: { _in: brandIDs },
            ...(communitySearchTerm.length > 2
              ? {
                  _or: [{ name: { _ilike: `%${communitySearchTerm}%` } }],
                }
              : {}),
          },
          offset: conversations.length,
        },
        fetchPolicy: "network-only",
      })
      .then((communities: any) => {
        if (!communities.loading) {
          setConvLoading(false);
        }

        if (communities?.data?.brd_communities.length === 0) {
          setHasMoreConv(false);
        } else {
          const filteredConversations =
            communities?.data?.brd_communities?.filter((conv: any) =>
              conv.brd_community_users?.some((user: any) =>
                inquiries?.some((inq: any) => user.user_id === inq.users.id)
              )
            );

          if (defaultRole === "admin") {
            const allConv = [
              ...conversations,
              ...communities?.data?.brd_communities,
            ];

            const uniqueAllConv = allConv.reduce((acc, current) => {
              if (!acc.find((item: any) => item.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []);

            setConversations(uniqueAllConv);
          } else {
            const allConv = [...conversations, ...filteredConversations];

            const uniqueAllConv = allConv.reduce((acc, current) => {
              if (!acc.find((item: any) => item.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []);

            setConversations(uniqueAllConv);
          }
        }
      });
  };

  return (
    <div
      className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
        showCommunityBar ? "left-0" : "left-[-800px]"
      }`}
    >
      <div
        className={` px-[23px] text-white text-[19px] pt-[60px] bg-whatsappBasic`}
      >
        <div className="flex items-center mb-5">
          <ArrowBackOutlinedIcon
            onClick={() => {
              setShowCommunityBar(false);
            }}
            sx={{ cursor: "pointer" }}
          />
          <p className="ml-5">Community</p>
        </div>
      </div>
      <SearchBar setSearch={setCommunitySearchTerm} />

      <div
        ref={scrollParentRef}
        className="text-primary dark:text-dark-primary overflow-scroll flex-grow"
      >
        <div className="p-[20px] pb-3 w-full">
          <Button
            disabled={generating || uniqueDestAndBrdIds.length < 1}
            onClick={reGenerateComu}
            fullWidth
            variant="outlined"
          >
            {generating ? "Generating..." : "Re-Generate Communities"}
          </Button>
          {generating && (
            <div className="text-[14px] mt-1 flex gap-3 items-center w-full">
              <LinearProgress
                sx={{ width: "100%" }}
                variant="determinate"
                value={progress}
              />
              <p>{progress}%</p>
            </div>
          )}
        </div>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreConv}
          hasMore={hasMoreConv}
          loader={<ChatRowSekeleton />}
          useWindow={false}
          getScrollParent={() => scrollParentRef.current}
        >
          {conversations?.map((conversation: any) => {
            return <ChatRow conversation={conversation} label={true} />;
          })}
          {convLoading && conversations?.length < 1 && (
            <>
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
            </>
          )}
          {!convLoading && conversations?.length < 1 && (
            <div className="flex items-center justify-center gap-3 mt-12">
              <ErrorIcon color="error" />
              <p className="font-bold">No Conversations Found</p>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CommunityComp;
