import { useParams } from "react-router-dom"
import CoursesData from "./components/CoursesData"
import SidePopup from "~/components/common/SidePopup"
import AddCourse from "./components/AddCourse"

const Courses = () => {

    const { uni_id } = useParams()

    return (
        <div>
            <CoursesData uniId={uni_id} />
            <SidePopup component={<AddCourse uniId={uni_id} />} />
        </div>
    )
}

export default Courses