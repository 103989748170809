import Layout from "~/components/common/Layout";

import EmailCampaigns from "~/modules/BrandModule/modules/MarketingModule/segments/EmailCampaigns";

export default function EmailCampaignPage() {
  return (
    <div>
      <Layout>
        <EmailCampaigns />
      </Layout>
    </div>
  );
}
