import { gql } from "@apollo/client";

export const SEND_READ_STATUS = gql`
  mutation MyMutation2(
    $message_id: String!
    $phoneNumberID: String!
    $token: String!
  ) {
    sendWhatsappReadStatus(
      arg1: {
        message_id: $message_id
        phoneNumberID: $phoneNumberID
        token: $token
      }
    ) {
      status
    }
  }
`;

export const INSERT_MESSAGES = gql`
  mutation MyMutation($data: [brd_whatsapp_messages_insert_input!]!) {
    insert_brd_whatsapp_messages(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_CONVERSATION = gql`
  mutation MyMutation($data: [brd_whatsapp_conversations_insert_input!]!) {
    insert_brd_whatsapp_conversations(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
