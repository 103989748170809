import { gql } from "@apollo/client";

export const INSERT_BRD_SERVICES_CATEGORY_WITH_BRD_ID = gql`
  mutation MyMutation($data: brd_services_category_insert_input!) {
    insert_brd_services_category(objects: [$data]) {
      returning {
        name
        brd_id
        id
        created_at
        updated_at
      }
    }
  }
`;
export const DELETE_BRD_SERVICES_CATEGORY = gql`
  mutation MyMutation($id: uuid!) {
    delete_brd_services_category(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_SERVICES_CATEGORY = gql`
  mutation UpdateBrdServiceCategory($id: uuid!, $name: String!) {
    update_brd_services_category(
      where: { id: { _eq: $id } }
      _set: { name: $name }
    ) {
      affected_rows
    }
  }
`;
