import { gql } from "@apollo/client";

export const GET = gql`
    subscription MySubscription($brd_ids: [uuid!]!) {
        brd_google_campaign(
        where: {
            brd_id: { _in: $brd_ids }
        }
        ) {
            id
            name
            start_date
            end_date
            daily_budget
            comment
            brd_google_ads_aggregate {
            nodes {
                brd_google_ad_updates_aggregate {
                aggregate {
                    sum {
                    click
                    cost
                    impression
                    }
                }
                }
            }
            }
            brd_list{
                name
            }
        }
    }`;