import React, { useEffect, useState } from "react";
import { CalendarBoxProps } from "./types";
import { Controller } from "react-hook-form";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";

export const CalendarBox = ({
  control,
  errors,
  setValue,
  type,
  fieldName,
  label,
  labelClasses,
  containerClasses,
  defaultValue,
  inputClasses,
  minDate,
  maxDate,
  required = false,
  placeholder = "Date Pick",
}: CalendarBoxProps) => {
  const [date, setDate] = useState<any>(type ? [null, null] : null);

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
      if (type) {
        setDate(defaultValue);
      } else {
        setDate(moment(defaultValue).toDate());
      }
    }
  }, []);

  return (
    <div className={`w-full ${containerClasses}`}>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: required }}
        render={({ field: { onChange } }) => (
          <DatePickerInput
            classNames={{
              input: `${inputClasses} ${
                errors[fieldName] ? "border border-red-500" : ""
              }`,
              label: `${labelClasses}`,
            }}
            className="m-0 w-full"
            type={type}
            allowSingleDateInRange
            valueFormat="MMM DD, YYYY"
            label={label}
            // placeholder={placeholder}
            value={date}
            onChange={(e: any) => {
              setDate(e);
              onChange(e.toString());
            }}
            minDate={minDate ? moment(minDate).toDate() : undefined}
            maxDate={maxDate ? moment(maxDate).toDate() : undefined}
          />
        )}
      />
      {errors[fieldName] && (
        <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
          {" "}
          <sup>*</sup> required.
        </p>
      )}
    </div>
  );
};
