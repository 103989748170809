import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useClickOutside } from "@mantine/hooks";
import {
  useUserData,
  useUserDefaultRole,
  useUserDisplayName,
  useUserEmail,
  useUserId,
} from "@nhost/react";
import moment from "moment";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CheckMark from "~/assets/svg/CheckMark";
import { SEND_EMAIL } from "~/config/Mutation";
import useNotifications from "~/hooks/useNotifications";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { getTimeReadableFormatInq } from "~/utils/GetTimeReadable";
import { DELETE_INQUIRY, EDIT_INQUIRY } from "./graphql/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { sendGenericEmail } from "~/utils/sendEmail";
import { toast } from "react-toastify";
import { FaEye, FaTrash } from "react-icons/fa";
import useEmail from "~/hooks/emails/useEmail";
import { getCityName } from "~/utils/utilsFunctions";
import { Tooltip } from "@mantine/core";
import CountDown from "~/components/common/CountDown";
import InquiryConsultant from "./components/InquiryConsultant";
import InquiryStatus from "./components/InquiryStatus";
import { INSERT_UNIQUE_INQ } from "~/graphql/inq_list_unique/Mutation";

export default function InquiryRow({
  inquiry,
  userCol = true,
  hideEyeBtn,
  hide,
}: any) {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));
  const [deleteInquiry] = useMutation(DELETE_INQUIRY);
  const emailSend = useEmail();

  const userDefaultRole = useUserDefaultRole();
  const userDisplayName = useUserDisplayName();
  const userEmail = useUserEmail();

  const userRole: any = useUserDefaultRole();

  const userId = useUserId();

  const [sendEmail] = useMutation(SEND_EMAIL);
  const userData = useUserData();

  const [insertUniqueInq] = useMutation(INSERT_UNIQUE_INQ);

  const [isloading, setIsloading] = useState(false);
  const client = useApolloClient();
  const alert: any = useNotifications();
  // ;
  const [editInquiry] = useMutation(EDIT_INQUIRY);

  // Navigation hooks
  const navigate = useNavigate();

  console.log(hide);

  // Accept Inquiry Button
  const accept = async () => {
    setIsloading(true);
    if (!isloading && !inquiry?.picked_by) {
      try {
        // Create passenger function call
        const res = await editInquiry({
          variables: {
            id: inquiry.id,
            picked_by_id: userId,
            updated_by: userId,
          },
        });
        if (res.data?.update_inq_list?.returning?.length > 0) {
          const variables: any = {
            inquiryNo: inquiry?.inquiry_no,
            brandName: inquiry?.brd_list?.name,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantEmail: userData?.metadata?.alias || userData?.email,
            consultantName: userDisplayName,
          };

          // ;
          // await emailSend(
          //   1,
          //   inquiry.brd_id,
          //   inquiry.users?.id,
          //   inquiry.users?.email,
          //   inquiry.users?.phoneNumber,
          //   variables
          // );

          toast.success("Picked Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          await alert.newNotification(
            inquiry?.users?.id,
            `Your Consultant Picked your Inquiry `,
            "",
            ``
          );

          await client.resetStore();

          navigate(`../inquiry/${inquiry.inquiry_no}`);
        }

        // setIsloading(false); // change button loading state
        setIsloading(false);
      } catch (e: any) {
        setIsloading(false);
        console.error("error", e);
        toast.error(e, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  console.log("hsdgfhsg", inquiry);

  const convertToMain = async () => {
    const res = await insertUniqueInq({
      variables: {
        brdId: inquiry?.brd_id,
        userId: inquiry?.user_id,
        inquiryNo: inquiry?.inquiry_no,
      },
    });

    if (res?.data?.insert_inq_list_unique?.affected_rows) {
      toast.success("Inquiry Updated to Main");
      navigate("../inquiries");
    }
  };

  const removeInquiry = async (id: string) => {
    // ;
    const res = await deleteInquiry({ variables: { id: { id: id } } });
    if (res?.data?.update_inq_list_by_pk?.id) {
      toast.success("Inquiry deleted successfully");
      client.resetStore();
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <tr
      className={`bg-secondary shadow dark:bg-dark-secondary w-full ${
        hideEyeBtn && "rounded-2xl"
      }`}
    >
      {/* <td className="w-4 p-4">
        <div className="flex items-center">
          <input
            id="checkbox-623232"
            aria-describedby="checkbox-1"
            type="checkbox"
            className="w-4 h-4 border-gray-200 dark:border-gray-800 rounded bg-primary"
          />
          <label htmlFor="checkbox-623232" className="sr-only">
            checkbox
          </label>
        </div>
      </td> */}

      {!hideEyeBtn && (
        <>
          <td className="p-4 text-sm font-normal text-primary dark:text-dark-primary whitespace-nowrap ">
            <div className="text-base font-normal text-primary dark:text-dark-primary">
              #{inquiry.inquiry_no}
              <pre className="text-sm font-semibold">
                {moment(inquiry?.created_at).format("YYYY-MM-DD")}
              </pre>
              <pre className="text-sm font-semibold">
                {getTimeReadableFormatInq(inquiry?.created_at)}
              </pre>
              <div
                className={`text-sm font-normal text-primary dark:text-dark-primary ${
                  hideEyeBtn ? "rounded-r-2xl" : ""
                }`}
              >
                {inquiry?.source_position} - {inquiry?.source_place}
              </div>
              <div
                className={`text-sm text-primary dark:text-dark-primary font-normal ${
                  hideEyeBtn ? "rounded-r-2xl" : ""
                }`}
              >
                {inquiry?.source}{" "}
                {inquiry?.source_keyword ? `(${inquiry?.source_keyword})` : ``}
              </div>
              <div
                className={`text-sm font-normal text-primary dark:text-dark-primary ${
                  hideEyeBtn ? "rounded-r-2xl" : ""
                }`}
              >
                {inquiry?.source_add_id ? `Ad #${inquiry?.source_add_id}` : ``}
              </div>
            </div>
          </td>
          {userDefaultRole !== "user" && (
            <>
              {userCol && (
                <td className="items-center p-4 space-x-6 whitespace-nowrap">
                  <div className="text-sm font-normal text-primary dark:text-dark-primary">
                    <div className="text-base font-semibold text-primary dark:text-dark-primary capitalize">
                      <Link
                        to={`/${userRole}/brands/${inquiry.brd_id}/customer/${inquiry?.users?.id}`}
                      >
                        {inquiry?.users?.displayName}
                      </Link>
                    </div>
                    <div className="text-sm font-normal text-primary dark:text-dark-primary">
                      {inquiry?.users?.email}
                    </div>
                    <div className="text-sm font-normal text-primary dark:text-dark-primary">
                      {inquiry?.users?.phoneNumber ||
                        inquiry?.users?.metadata?.phone}
                    </div>

                    <div className="text-sm font-bold text-primary dark:text-dark-primary">
                      {inquiry?.users?.inq_lists?.[0]?.picked_user?.metadata
                        ?.oldConsultant ||
                        inquiry?.users?.inq_lists?.[0]?.picked_user
                          ?.thp_users_profile?.[0]?.pseudo_name}
                    </div>
                  </div>
                </td>
              )}
            </>
          )}
        </>
      )}

      <td
        className={`items-center p-4 space-x-6 whitespace-nowrap ${
          hideEyeBtn && "rounded-l-2xl"
        }`}
      >
        <div className="text-sm font-normal text-primary dark:text-dark-primary">
          <InquiryConsultant inquiry={inquiry} />

          <div className="text-sm font-normal text-primary dark:text-dark-primary">
            {inquiry?.brd_list?.name}
          </div>
          <div className="text-sm font-normal text-primary dark:text-dark-primary">
            {inquiry?.picked_user?.phoneNumber ||
              inquiry?.picked_user?.metadata?.phone}
          </div>
        </div>
      </td>
      <td className="p-4 text-sm font-normal  whitespace-nowrap text-primary dark:text-dark-primary">
        <div className="text-base flex items-center gap-1 font-normal text-primary dark:text-dark-primary ">
          {inquiry.from}
          <pre className="text-sm">({getCityName(inquiry.from)})</pre>
        </div>
        <div className="text-base flex items-center gap-1 font-normal text-primary dark:text-dark-primary ">
          {inquiry.to}
          <pre className="text-sm">({getCityName(inquiry.to)})</pre>
        </div>
        {!hide && (
          <>
            <div className="text-base font-normal text-primary dark:text-dark-primary ">
              {inquiry.inq_passengers.length} Passengers
            </div>
            <div className="text-base text-primary dark:text-dark-primary">
              {inquiry.approx_budget ? `£${inquiry.approx_budget}` : ""}
            </div>
            <div className="text-base font-normal text-primary dark:text-dark-primary ">
              {moment(inquiry.departure_datetime).format("DD-MM-YYYY")}
              <br />
              {inquiry.trip_type === "oneWay" ? (
                <span>-</span>
              ) : (
                moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
              )}
            </div>
          </>
        )}
      </td>

      {hide && (
        <td className="p-4 text-base font-medium text-primary dark:text-dark-primary whitespace-nowrap ">
          <div className="text-base font-normal text-primary dark:text-dark-primary ">
            {moment(inquiry.departure_datetime).format("DD-MM-YYYY")}
            <br />
            {inquiry.trip_type === "oneWay" ? (
              <span>-</span>
            ) : (
              moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
            )}
          </div>
        </td>
      )}

      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        <div className="text-base text-primary dark:text-dark-primary capitalize">
          <span className="flex gap-4">
            <InquiryStatus inquiry={inquiry} />
          </span>
          <span className={` mt-[5px] block rounded-md `}>
            <CountDown endDate={inquiry?.inq_comments?.[0]?.reminder_time} />
          </span>
        </div>
      </td>

      {hide && (
        <td className="p-4 text-base font-medium text-primary dark:text-dark-primary whitespace-nowrap">
          <div className="text-base font-normal text-primary dark:text-dark-primary ">
            {inquiry.inq_passengers.length} Passengers
          </div>
          <div className="text-base text-primary dark:text-dark-primary">
            {inquiry.approx_budget ? `£${inquiry.approx_budget}` : ""}
          </div>
        </td>
      )}

      {hide && (
        <>
          {userDefaultRole !== "user" && (
            <td
              className={`p-4 text-base font-normal text-primary dark:text-dark-primary whitespace-nowrap ${
                hideEyeBtn ? "rounded-r-2xl" : ""
              }`}
            >
              <div
                className={`text-sm font-normal text-primary dark:text-dark-primary ${
                  hideEyeBtn ? "rounded-r-2xl" : ""
                }`}
              >
                {inquiry?.source_position} - {inquiry?.source_place}
              </div>
              <div
                className={`text-sm text-primary dark:text-dark-primary font-normal ${
                  hideEyeBtn ? "rounded-r-2xl" : ""
                }`}
              >
                {inquiry?.source}{" "}
                {inquiry?.source_keyword ? `(${inquiry?.source_keyword})` : ``}
              </div>
              <div
                className={`text-sm font-normal text-primary dark:text-dark-primary ${
                  hideEyeBtn ? "rounded-r-2xl" : ""
                }`}
              >
                {inquiry?.source_add_id ? `Ad #${inquiry?.source_add_id}` : ``}
              </div>
            </td>
          )}
          {inquiry?.unique_inq?.id ? (
            <td className={`${hideEyeBtn && "rounded-r-2xl"}`}>
              <p className="text-green-600 font-bold">Main Inquiry</p>
            </td>
          ) : (
            <td className={`${hideEyeBtn && "rounded-r-2xl"}`}>
              <p
                onClick={convertToMain}
                className="bg-green-600 cursor-pointer text-center font-bold w-[140px] rounded py-[1px] text-white"
              >
                Add to Main
              </p>
            </td>
          )}
        </>
      )}

      {!hideEyeBtn && (
        <>
          {" "}
          <td className="p-4 space-x-2 whitespace-nowrap flex items-center justif-center mt-10">
            {(inquiry.status === "pending" &&
              userDefaultRole === "consultant") ||
            hideEyeBtn ? (
              <></>
            ) : (
              <Link
                to={`../inquiry/${inquiry.inquiry_no}`}
                className="inline-flex items-center p-2 text-sm font-medium text-center text-basic outline outline-1 outline-basic hover:bg-secondary dark:hover:bg-dark-secondary hover:outline-none rounded-lg "
              >
                <FaEye size="25px" />
              </Link>
            )}

            {/* Detail dropdown button */}
            {/* <button
          className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50  dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          type="button"
          ref={ref}
          onClick={() => setOpened(true)}
        >
          Detail
        </button> */}

            {userRole !== "user" && (
              <>
                {inquiry.status === "pending" && (
                  <>
                    <button
                      type="button"
                      onClick={() => removeInquiry(inquiry?.id)}
                      className="text-red-500 hover:text-red-800 font-medium rounded-lg text-sm text-center inline-flex items-center mr-2"
                    >
                      {isloading ? "..." : <FaTrash size={18} />}
                    </button>
                  </>
                )}

                {!inquiry.picked_by && (
                  <button
                    type="button"
                    onClick={() => {
                      accept();
                    }}
                    className="text-white  bg-basic hover:bg-purple-600 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 "
                  >
                    {isloading ? "..." : <CheckMark />}
                  </button>
                )}
              </>
            )}

            {opened && (
              <div
                ref={ref}
                className="absolute z-10 right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                  <li>
                    <Link
                      to={`../inquiry/${inquiry.inquiry_no}`}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Details
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
}
