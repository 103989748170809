import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_CUSTOMERS } from "./graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import SidePopup from "~/components/common/SidePopup";
import CustomerTable from "./component/table";
import UserAdd from "~/components/UserCard/components/UserAdd/UserAdd";
import CustomerFilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/CustomerFilter";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function InquiryList() {
  const { brands } = brandsStore();
  const [brandIds, setBrandIds] = useState<any>([]);
  const { travelHouse }: any = travelHouseStore();
  const [selectedCustomer, setSelectedCustomer] = useState<any>([]);

  const condition = {
    _and: [
      { defaultRole: { _eq: "user" } },
      {
        brd_users: {
          thp_id: { _eq: travelHouse.id },
          brd_id: { _in: selectedCustomer?.id },
        },
      },
    ],
    id: selectedCustomer?.[0]?.id ? { _eq: selectedCustomer?.[0]?.id } : {},
  };

  // get data based on query
  const { data, error } = useQuery(GET_CUSTOMERS, {
    variables: {
      th_id: travelHouse.id,
      brd_ids: brandIds,
      condition: condition,
    },
    skip: !brandIds,
  });

  useEffect(() => {
    const brdIds = brands.map((item: any) => {
      return item.id;
    });

    setBrandIds(brdIds);
  }, [brands]);

  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;
  // if (!brandIds || brandIds?.length < 1) return <div>Loading brands...</div>;

  return (
    <>
      <div className="flex mb-8 w-full justify-between">
        <h1 className="text-primary uppercase text-[18px] dark:text-dark-primary font-bold mb-2">
          Customers ({data?.users?.length})
        </h1>
        <div className="flex gap-3 justify-end">
          <div className="min-w-[300px]">
            <CustomerFilter setSelectedCustomer={setSelectedCustomer} />
          </div>
        </div>
      </div>
      {/*  */}
      <div>
        {data?.users ? (
          <CustomerTable
            data={data?.users.filter((item: any) => item?.brd_users?.[0])}
          />
        ) : (
          "Loading..."
        )}

        <SidePopup
          text={"Add Customer"}
          component={<UserAdd defaultRole="user" heading="Add Customer" />}
        />
      </div>
      {/* No Record Found */}
      {data?.users?.length === 0 && <h2>No record found</h2>}
    </>
  );
}
