import React, { useEffect, useState } from "react";
import { useClickOutside } from "@mantine/hooks";
import { TextField, Typography, Box } from "@mui/material";

const CityBox = ({
  value,
  onChange,
  setValue,
  fieldName,
  defaultValue,
  required,
  countryCode,
  label,
  errors,
}: any) => {
  const [cityList, setCityList] = useState([]);
  const [query, setQuery] = useState("");
  const ref = useClickOutside(() => setQuery(""));

  const searchCity = async (place: string) => {
    const data = await fetch(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${place}&apiKey=e25595083ac7414da2f5612fbbddbef8${
        countryCode ? `&filter=countrycode:${countryCode.toLowerCase()}` : ""
      }`
    );
    const res = await data.json();
    if (!res.error) {
      setCityList(res?.features);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(`${fieldName}.fullName`, defaultValue?.fullName);
      onChange(defaultValue?.fullName); // Set the initial value for the input field
    }
  }, [defaultValue, fieldName, setValue, onChange]);

  useEffect(() => {
    if (query !== "") {
      searchCity(query);
    }
  }, [query]);

  const splitCityAndAddress = (fullName: string) => {
    const parts = fullName.split(", ");
    const cityName = parts.shift() || ""; // Extract the first part as city name
    const remainingAddress = parts.join(", "); // Join the remaining parts as address
    return { cityName, remainingAddress };
  };

  const handleCity = (city: any) => {
    const fullName = city?.properties?.formatted;
    setValue(`${fieldName}.fullName`, fullName); // Store the full formatted name in the form state
    onChange(fullName); // Update the input field value
    setQuery("");
    setCityList([]);
  };

  const renderSearchOptions = () => (
    <div
      ref={ref}
      className="w-[92%] absolute mt-[55px] bg-primary text-primary dark:text-dark-primary dark:bg-dark-primary rounded z-10 shadow-lg transition-all"
    >
      {cityList.length === 0 ? (
        <p className="text-center text-[14px] py-2"></p>
      ) : (
        cityList.map((item: any, i: number) => (
          <div
            onClick={() => handleCity(item)}
            key={i}
            className="p-[15px] border-b border-gray-200 dark:border-gray-700 hover:bg-secondary dark:hover:bg-dark-secondary cursor-pointer transition-all"
          >
            <p>{item?.properties?.formatted}</p>
          </div>
        ))
      )}
    </div>
  );

  const { cityName, remainingAddress } = value
    ? splitCityAndAddress(value)
    : { cityName: "", remainingAddress: "" };

  return (
    <div className="w-full grid grid-cols-1 gap-5">
      <TextField
        sx={{ width: "100%" }}
        value={value || ""} // Controlled component value
        onChange={(e) => {
          setQuery(e.target.value); // For filtering city list
          onChange(e.target.value); // Call onChange from Controller
        }}
        label={label}
        type="text"
        required={required}
      />
      {query !== "" && renderSearchOptions()}
      {errors[fieldName]?.fullName && (
        <p className="text-red-500 text-[12px] mt-1 ml-1">
          <sup>*</sup> required
        </p>
      )}
    </div>
  );
};

export default CityBox;
