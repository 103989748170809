import { gql } from "@apollo/client";

export const INSERT_CAMPUS_COURSE_ADM_REQ = gql`
  mutation InsertCampusCourseAdmReq(
    $campusCourseId: uuid!,
    $gapAllowed: String!,
    $grades: String!,
    $gradeType: String!
    $qualification: String!,
    $scholarshipAvailable: String!
    $elt: String!,
    $moi: String
  ) {
    insert_course_adm_req(
      objects: {
        campus_course_id: $campusCourseId,
        gap_allowed: $gapAllowed,
        grades: $grades,
        grade_type: $gradeType,
        qualification: $qualification,
        scholarship_available: $scholarshipAvailable,
        elt: $elt
        moi: $moi
      }
    ) {
      affected_rows
    }
  }
`;


export const DELETE_CAMPUS_COURSE_ADM_REQ_WITH_ID = gql`
  mutation DeleteCampusCourseAdmReqWithId($reqId: uuid!) {
    delete_course_adm_req(where: { id: { _eq: $reqId } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CAMPUS_COURSE_ADM_REQ_WITH_ID = gql`
  mutation UpdateCampusCourseAdmReqWithId(
    $reqId: uuid!
    $data: course_adm_req_set_input!
  ) {
    update_course_adm_req(
      where: { id: { _eq: $reqId } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;
