import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import { Divider } from "@mui/material";

// Import Components
import InputField from "components/FormInputs/InputField";
import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { STAY_EDIT_ROOM } from "../../../../../graphql/Mutation";

const StayPassengerEdit = ({
  inquiry_id,
  passenger,
  hideForm,
  user_id,
  picked_by,
  tabLabel,
}: any) => {
  const client = useApolloClient();

  const [editStayPassenger] = useMutation(STAY_EDIT_ROOM);

  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  //  Use react hook
  //   const selectedType = PassengersType.filter(
  //     (ps: any) => ps.value === passenger.type
  //   );

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  console.log("asndbasmn", tabLabel, passenger);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      booked_by: passenger?.booked_by,
      no_of_days: passenger?.no_of_days || null,
      room_number: passenger?.room_number || null,
    },
  });

  const onSubmit = async (data: any) => {
    console.log("stayroomdata", data);
    data["id"] = passenger.id;

    try {
      let response;

      // Determine which mutation to call based on the passenger type
      switch (tabLabel) {
        case "Stay":
          response = await editStayPassenger({
            variables: {
              id: passenger.id,
              booked_by: data.booked_by,
              no_of_days: data.no_of_days,
              room_number: data.room_number,
            },
          });
          break;
        default:
          console.log("Unknown passenger type!");
          return;
      }

      // Success alert
      toast.success("Rooms added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      hideForm();
    } catch (error) {
      console.error("Error updating rooms:", error);
      hideForm(false);
      console.error("error", error);
    }
  };

  function isValidDateFormat(dateString: any) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  return (
    <>
      <div className="h-full min-w-[500px] bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini shadow text-darkslategray">
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Edit Rooms
        </h2>
        <Divider />
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="self-stretch flex gap-5 flex-row pt-0  justify-between px-0 items-center"></div>
          <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
            <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[130%]">
                <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
                  <InputField
                    label="BookedBy"
                    register={register}
                    type="text"
                    required={true}
                    errors={errors}
                    placeHolder="Booked By"
                    fieldName={`booked_by`}
                    defaultValue={getValues("booked_by")}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                  />
                  <InputField
                    label="No of Days"
                    register={register}
                    type="number"
                    errors={errors}
                    placeHolder="No of Days"
                    fieldName={`no_of_days`}
                    defaultValue={getValues("no_of_days")}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                  />
                  <InputField
                    label="Room Number"
                    register={register}
                    type="text"
                    errors={errors}
                    placeHolder="Room Number"
                    fieldName={`room_number`}
                    defaultValue={getValues("room_number")}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-5 w-full mt-3">
            {/* <button
              className="w-full button btn bg-basic text-white rounded-md p-2"
              onClick={() => {
                hideForm(false);
              }}
            >
              Close
            </button> */}
            <button
              className="w-full btn bg-basic text-white rounded-md p-2"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default StayPassengerEdit;
