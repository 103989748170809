import AddDefPlanCredits from "./components/AddDefPlanCredits";
import PlanCredits from "./components/PlanCredits";
import { Button, Modal, Box } from "@mui/material";
import { useState } from "react";
import useDefCreditsPlan from "~/hooks/useDefCreditsPlan";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const DefPlanCredits = () => {
  const [openMod, setOpenMod] = useState(false);

  const { defCreditsPlan } = useDefCreditsPlan();

  return (
    <>
      <div className="w-full text-primary dark:text-dark-primary flex justify-between">
        <h1 className="font-bold pl-4 pt-4">Credits Purchases</h1>
        <div className="flex items-center gap-5">
          <Button onClick={() => setOpenMod(true)} variant="contained">
            Add Plan Credits
          </Button>
        </div>
      </div>
      <div className="mt-7 flex items-center gap-[30px]">
        {defCreditsPlan?.map((item: any, ind: any) => {
          return <PlanCredits key={ind} data={item} />;
        })}
      </div>
      <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <AddDefPlanCredits setOpenMod={setOpenMod} />
        </Box>
      </Modal>
    </>
  );
};

export default DefPlanCredits;
