import { gql } from "@apollo/client";

export const ADD_USER = gql`
  mutation MyMutation($data: [users_insert_input!]!) {
    insertUsers(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_FAILED_CONV_USERS = gql`
  mutation MyMutation {
    deleteUsers(
      where: { metadata: { _has_key: "type", _contains: { type: "import" } } }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_LAST_SEEN = gql`
  mutation updateLastSeen($userId: uuid!, $lastSeen: timestamptz!) {
    updateUsers(
      where: { id: { _eq: $userId } }
      _set: { lastSeen: $lastSeen }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvatar($userId: uuid!, $avatarUrl: String!) {
    updateUsers(
      where: { id: { _eq: $userId } }
      _set: { avatarUrl: $avatarUrl }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_USER_WITH_ID = gql`
  mutation deleteUserWithId($id: uuid!) {
    deleteUsers(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
