import { Drawer } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const SideDrawer = ({ setSidePopup, sidePopup, component }: any) => {
  return (
    <>
      <Drawer anchor="right" open={sidePopup}>
        <CancelIcon
          onClick={() => setSidePopup(false)}
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            cursor: "pointer",
          }}
        />

        {component && React.cloneElement(component, { setSidePopup })}
      </Drawer>
    </>
  );
};

export default SideDrawer;
