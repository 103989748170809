import { Autocomplete, Box, Button, FormLabel, TextField } from "@mui/material";
import _airlineData from "../../../components/FormInputs/AirlineBox/data/airlines.json";
import _airportsData from "../../../components/FormInputs/LocationBox/data/airports.json";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ContentEditor from "../ContentEditor";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useEffect } from "react";

const BrandPostDetail = ({
  setLogoFile,
  addPost,
  content,
  setContent,
  register,
  control,
  handleSubmit,
  errors,
  setType,
  pageData,
}: any) => {
  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };

  const onSubmit = async (inpData: any) => {
    addPost(inpData);
  };

  useEffect(() => {
    setContent(pageData?.content);
  }, [pageData]);

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl bg-red-500">Destination Base Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              error={errors["post_title"] ? true : false}
              {...register("post_title", { required: true })}
              label="Post Title"
              type="text"
              defaultValue={pageData?.post_title}
            />
            {setType === "update" && (
              <TextField
                error={errors["slug"] ? true : false}
                {...register("slug", { required: true })}
                label="Post Slug"
                type="text"
                defaultValue={pageData?.slug}
              />
            )}
            <TextField
              error={errors["canonical_url"] ? true : false}
              {...register("canonical_url", { required: true })}
              label="Canoncial Url"
              type="url"
              defaultValue={pageData?.canonical_url}
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("destination", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.city_name
                          .toLowerCase()
                          .startsWith(inputValueLowerCase) ||
                        option.iata_code
                          .toLowerCase()
                          .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airportsData ? _airportsData : []}
                  getOptionLabel={(option: any) =>
                    `${option.city_name || ""}-${option.iata_code}`
                  }
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.city_name}-{option.iata_code}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = _airportsData.find(
                      (option) => option.iata_code === pageData?.iata
                    );
                    field.onChange(data);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Destination"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["destination"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <div className={`${setType === "update" && "md:col-span-2"}`}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                {...register("airline", { required: true })}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    ref={field.ref}
                    onChange={(_, data: any) => {
                      field.onChange(data);
                    }}
                    filterOptions={(options, { inputValue }: any) => {
                      // If input is empty, don't show any suggestions
                      if (!inputValue.trim()) {
                        return [];
                      }

                      // Filter options to only include those that start with the user's input
                      const inputValueLowerCase = inputValue.toLowerCase();
                      return options.filter((option: any) =>
                        option.marketing_name
                          .toLowerCase()
                          .startsWith(inputValueLowerCase)
                      );
                    }}
                    options={_airlineData ? _airlineData : []}
                    getOptionLabel={(option: any) => option.marketing_name}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option.marketing_name}
                      </Box>
                    )}
                    defaultValue={() => {
                      const data = _airlineData.find(
                        (option) => option.iata === pageData?.airline_iata
                      );
                      field.onChange(data);
                      return data;
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Airline"
                        ref={field.ref}
                        inputRef={(el) => {
                          field.ref(el);
                        }}
                        error={errors["airline"] ? true : false}
                        {...params}
                      />
                    )}
                    noOptionsText=""
                  />
                )}
              />
            </div>
            <div>
              <FormLabel>Post Excerpt</FormLabel>
              <textarea
                {...register("excerpt", { required: true })}
                className={`${
                  errors?.excerpt && errors?.excerpt
                    ? "border-red-600 bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-14"
                    : "bg-transparent rounded w-full border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-14"
                }`}
                defaultValue={pageData?.excerpt}
              />
            </div>
            <div>
              <FormLabel className="mb-2">Featured Image</FormLabel>
              <TextField
                sx={{ width: "100%" }}
                // {...register("featuredImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={handleLogoUpload}
                helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
              />
            </div>
          </div>
          <div className="col-span-6 sm:col-full">
            <label className="mb-2 block">Post Content</label>

            <ContentEditor content={content} setContent={setContent} />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save Post
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandPostDetail;
