import { Skeleton } from "@mui/material";

const TemplateRowSekeleton = () => {
  return (
    <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer">
      <div className="py-[13px] px-[20px] border-b dark:border-dark-secondary w-full">
        <div className="flex items-center justify-between">
          <div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "16px", width: "150px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "12px", width: "100px" }}
            />
          </div>
          <div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "12px", width: "100px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "12px", width: "70px", float: "right" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateRowSekeleton;
