import React from "react";
import { GET_SERVICES_WITH_BRD_ID } from "~/graphql/brd_services/Query";
import { useQuery } from "@apollo/client";
import brandsStore from "~/store/brandsStore/brandsStore";
import { Autocomplete, TextField } from "@mui/material";

const ServicesFilter = ({ setSelectedService }: any) => {
  const { brands }: any = brandsStore();

  const brdIds = brands.map((item: any) => item.id);

  const { data } = useQuery(GET_SERVICES_WITH_BRD_ID, {
    variables: { brd_id: brdIds },
  });

  const handleChange = (event: any, newValue: any) => {
    setSelectedService(newValue);
  };

  return (
    <div>
      <Autocomplete
        options={data?.brd_services || []}
        getOptionLabel={(option: any) => option?.title} // Safely access the name property
        fullWidth
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="Service" variant="outlined" fullWidth />
        )}
      />
    </div>
  );
};

export default ServicesFilter;
