import { Avatar, Popover } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useState } from "react";
import MenuItems from "./component/MenuItem";

const Header = ({
  showWhatsappSidebar,
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  setShowUserInfo,
  showUserInfo,
}: any) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="bg-whatsappPrimaryBg dark:bg-dark-primary flex items-center py-[8px] px-[16px] w-full text-primary dark:text-dark-primary cursor-pointer">
      <div className="flex items-center w-full">
        {!showWhatsappSidebar && (
          <ArrowBackIosNewOutlinedIcon
            sx={{
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              marginRight: "15px",
            }}
            onClick={() => {
              setShowUserInfo(false);
              setShowWhatsappSidebar(true);
              setShowWhatsappChatBox(false);
            }}
          />
        )}
        <div
          className="flex items-center w-full"
          onClick={() => {
            setShowUserInfo(true);
            const isSmallScreen = window.innerWidth < 768;
            if (isSmallScreen) {
              setShowWhatsappChatBox(false);
            }
          }}
        >
          <Avatar
            alt="whatsappAvator"
            src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
          />
          <div className="pl-[15px]">
            <h1 className="text-[16px] text-black dark:text-white">M Usman</h1>
            <p className="text-[14px]">Last seen today at 5:01 PM</p>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-5">
          <SearchOutlinedIcon
            sx={{ color: darkMode ? "#E7E3FCDE" : "#3A3541DE" }}
          />
        </div>
        <div className="mx-2">
          <MoreVertOutlinedIcon
            sx={{
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              cursor: "pointer",
            }}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setShowMenu(!showMenu);
            }}
          />
          <Popover
            sx={{
              background: "transparent",
              top: "10px",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowMenu(!showMenu);
            }}
            open={showMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItems
              setShowUserInfo={setShowUserInfo}
              setShowWhatsappChatBox={setShowWhatsappChatBox}
              setShowMenu={setShowMenu}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header;
