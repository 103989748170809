import { gql } from "@apollo/client";

export const GET_MAILJET_SENDERS_LIST = gql`
    query GetMailjetSendersList($brdId: String) {
        get_mailjet_sender_list(arg1: { brdId: $brdId }) {
            Count
            Data {
                CreatedAt
                DNSID
                Email
                EmailType
                Filename
                ID
                IsDefaultSender
                Name
                Status
            }
            Total
        }
    }
`;
