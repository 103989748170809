import { gql } from "@apollo/client";

export const GET_CASESTUDY_PAGE = gql`
  query MyQuery($brd_id: uuid!) {
    serv_main_casestudy_page(where: { brd_id: { _eq: $brd_id } }) {
      brd_id
      id
      heading
      text_color
      sub_heading
      bg_color
      image_id
    }
  }
`;
