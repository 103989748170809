import React from "react";
import Layout from "~/components/common/Layout";
import DataShiftModule from "~/modules/DataShiftModule";
import GetFlightPages from "~/modules/DataShiftModule/segments/GetFlightPages";
import UpdateFaqRelation from "~/modules/DataShiftModule/segments/UpdateFaqRelation";
import UpdateInquiryFlags from "~/modules/DataShiftModule/segments/UpdateInquiryFlags";
import UpdateWhatsappLabelInquiry from "~/modules/DataShiftModule/segments/UpdateWhatsappLabelInquiry";
import UsersModule from "~/modules/UsersModule";
import Attendance from "~/modules/UsersModule/segments/Attendance";

export default function GatherDataPage() {
  return (
    <div>
      <Layout>
        <DataShiftModule>
          {/* <UpdateFaqRelation /> */}
          {/* <UpdateInquiryFlags /> */}
          <UpdateWhatsappLabelInquiry />
        </DataShiftModule>
      </Layout>
    </div>
  );
}
