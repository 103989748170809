import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import TemplateCreate from "~/modules/WhatsappModule/segments/TemplateCreate";

const CreateTemplateBar = ({
  setShowCreateTemplateBar,
  showCreateTemplateBar,
  type,
  setShowTemplateBar,
}: any) => {
  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showCreateTemplateBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] ${
            type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
          }`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowCreateTemplateBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Create Template</p>
          </div>
        </div>
        {showCreateTemplateBar && (
          <div className="flex-grow w-full overflow-scroll">
            <TemplateCreate
              setShowTemplateBar={setShowTemplateBar}
              setShowCreateTemplateBar={setShowCreateTemplateBar}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CreateTemplateBar;
