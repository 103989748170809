import React from "react";
import { useUserDefaultRole } from "@nhost/react";
import SideBar from "./sidebar/SideBar";
import sideMenuList from "../../data/side-menu";
import TopMenuNew from "./header/TopMenuNew";
import Header from "./header";

interface LayoutProps {
  children: JSX.Element;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const userDefaultRole = useUserDefaultRole();
  // Get Menu based on user role
  const menu = sideMenuList.filter(
    (menu: any) => menu?.role === userDefaultRole
  );
  return (
    <div className="flex h-screen bg-primary dark:bg-dark-primary overflow-hidden">
      <div className="lg:flex hidden">
        <SideBar menu={menu[0].menuItems} />
      </div>
      <div className="flex-1 flex flex-col overflow-y-auto bg-primary dark:bg-dark-primary  relative">
        {/* <TopMenuNew /> */}
        <Header />
        <div className="relative p-[10px] lg:p-[20px]  w-full  mx-auto">
          {children}
        </div>
        {/* <GradientBlob /> */}
      </div>
    </div>
  );
};

export default Layout;
