import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Divider, TextField } from "@mui/material";
import { useFileUpload } from "@nhost/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { useParams } from "react-router-dom";
import useBrandDefaultSettings from "~/hooks/useBrandDefaultSettings";
import { useState } from "react";

const BlogSettings = () => {
  const { brd_id } = useParams();
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();

  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const client = useApolloClient();

  const { brdDefaultSettings } = useBrandDefaultSettings(brd_id && brd_id);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };
  const onSubmit = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    }
    if (brdDefaultSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: brdDefaultSettings?.id },
          data: {
            blogs_heading: inpData?.blogs_heading,
            blogs_sub_heading: inpData?.blogs_sub_heading,
            blogs_featured_image: inpData?.image,
            blog_page_meta_title: inpData?.blog_page_meta_title,
            blog_page_meta_description: inpData?.blog_page_meta_description,
            homepage_blog_section_heading:
              inpData?.homepage_blog_section_heading,
            homepage_blog_section_sub_heading:
              inpData?.homepage_blog_section_sub_heading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            blogs_heading: inpData?.blogs_heading,
            blogs_sub_heading: inpData?.blogs_sub_heading,
            blogs_featured_image: inpData?.image,
            blog_page_meta_title: inpData?.blog_page_meta_title,
            blog_page_meta_description: inpData?.blog_page_meta_description,
            homepage_blog_section_heading:
              inpData?.homepage_blog_section_heading,
            homepage_blog_section_sub_heading:
              inpData?.homepage_blog_section_sub_heading,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="lg:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Blogs Page Setting</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <TextField
              {...register("blogs_heading")}
              label="Blogs Page Heading"
              type="text"
              defaultValue={brdDefaultSettings?.blogs_heading}
            />
            <TextField
              {...register("blogs_sub_heading")}
              label="Blogs Page Sub Heading"
              type="text"
              defaultValue={brdDefaultSettings?.blogs_sub_heading}
            />
            <TextField
              {...register("blog_page_meta_title")}
              label="Blogs Page Meta Title"
              type="text"
              defaultValue={brdDefaultSettings?.blog_page_meta_title}
            />
            <TextField
              {...register("blog_page_meta_description")}
              label="Blogs Page Meta Description"
              type="text"
              defaultValue={brdDefaultSettings?.blog_page_meta_description}
            />
            <TextField
              {...register("homepage_blog_section_heading")}
              label="HomePage Blog Section heading"
              type="text"
              defaultValue={brdDefaultSettings?.homepage_blog_section_heading}
            />
            <TextField
              {...register("homepage_blog_section_sub_heading")}
              label="HomePage Blog Section Sub heading"
              type="text"
              defaultValue={
                brdDefaultSettings?.homepage_blog_section_sub_heading
              }
            />
            <div>
              <label className="mb-2">Hero Image For Blogs Page</label>
              <TextField
                sx={{ width: "100%" }}
                // {...register("featuredImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={handleLogoUpload}
                helperText="SVG, PNG, JPG or GIF (MAX. 322x450px)"
              />
            </div>
          </div>
          <Button
            type="submit"
            variant="contained"
            sx={{ marginTop: "20px" }}
            fullWidth
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BlogSettings;
