import { gql } from "@apollo/client";

export const GET_PAGE_DETAILS = gql`
  query GetFlightPageQuery($page_id: uuid) {
    brd_flights_pages(where: { id: { _eq: $page_id } }) {
      id
      brd_id
      offline_fares
      origin_iata
      destination_iata
      origin_name
      destination_name
      featured_image
      show_in_footer
      show_featured_destination
      featured_destination_image
      airline_iata
      base_price
      created_at
      page_content
      secondary_page_content
      updated_at
      slug
      main_heading
      show_in_home
      reserve_fare_text
      better_deal_text
      call_only_fare_text
      request_call_back_text
      share_button_text
      show_exclusive_fares
      better_deal_inquiry_submit_text
      better_deal_heading
      edit_search
      search_with_inquiry
      brd_flights_pages_tips {
        description
        flight_page_id
        heading
        id
      }
      brd_seo_settings {
        canonical_url
        created_at
        default_description
        default_keywords
        default_title
        flight_page_id
        og_image_url
        page_id
        id
        brd_id
      }
    }
  }
`;
