import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Modal,
  Paper,
  Stack,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidePopup from "~/components/common/SidePopup";
import UserEdit from "~/components/UserCard/components/UserEdit";
import SideDrawer from "~/components/common/sideDrawer";
import AdjustPayments from "../AdjustPayment";
import FingerPrintsLogs from "./components/FingerPrintsLogs";
import { GET_FINGERPRINT_LOGS } from "~/graphql/user_fingerprint_ids/Query";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_USER_WITH_ID } from "~/graphql/users/Mutation";
import { toast } from "react-toastify";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${
      name.trim().split(" ").length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name[0]}`
    }`,
  };
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const CustomerTable = ({ data, brand_id = null }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState<any>();

  const [deleteUserWithId] = useMutation(DELETE_USER_WITH_ID);

  const [sidePopup, setSidePopup] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [showPayments, setShowPayments] = useState<any>(false);

  const { data: fingerPrintData, refetch: refetchFPIDs } = useQuery(
    GET_FINGERPRINT_LOGS,
    {
      variables: {
        userId: openModel,
      },
    }
  );

  function getUserStatus(userLastSeen: any) {
    const currentTime: any = new Date();
    const lastSeenTime: any = new Date(userLastSeen);
    const timeDifference = currentTime - lastSeenTime;

    const minutesAgo = Math.floor(timeDifference / 1000 / 60);
    const hoursAgo = Math.floor(timeDifference / 1000 / 60 / 60);
    const daysAgo = Math.floor(timeDifference / 1000 / 60 / 60 / 24);
    const monthsAgo = Math.floor(timeDifference / 1000 / 60 / 60 / 24 / 30);
    const yearsAgo = Math.floor(timeDifference / 1000 / 60 / 60 / 24 / 365);

    if (minutesAgo < 1) {
      return "online";
    } else if (minutesAgo < 60) {
      return `${minutesAgo} minutes ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo} hours ago`;
    } else if (daysAgo < 30) {
      return `${daysAgo} days ago`;
    } else if (monthsAgo < 12) {
      return `${monthsAgo} months ago`;
    } else {
      return `${yearsAgo} years ago`;
    }
  }

  const deleteUser = async (id: any) => {
    const res = await deleteUserWithId({
      variables: { id },
    });

    if (res?.data?.deleteUser?.affected_rows) {
      toast.success("User Deleted Successfully");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "NAME",
      width: 350,
      renderCell: (params) => (
        <Stack
          direction="row"
          spacing={1.5}
          sx={{ display: "flex", alignItems: "center", height: "100px" }}
        >
          <Avatar
            style={{
              fontSize: "13px",
              width: "30px",
              height: "30px",
            }}
            {...stringAvatar(params.row.name.toUpperCase())}
          />
          <div>
            <p className="font-bold">
              <Link to={`/admin/customer/${params.row.uuid}`}>
                {params.row.name}
              </Link>
            </p>
            <p>{params.row.email}</p>
            <p>{params.row.phoneNumber}</p>
            {params?.row?.pseudoName && <p>{params?.row?.pseudoName}</p>}
            {params?.row?.alias && <p>{params?.row?.alias}</p>}
          </div>
        </Stack>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
    },
    {
      field: "brands",
      headerName: "BRANDS",
      width: 300,
      renderCell: (params: any) => (
        <div className="flex items-center flex-wrap gap-x-2 gap-y-1">
          {params?.row?.brands?.map((item: any) => {
            return (
              <div className="flex flex-col items-center gap-[3px]">
                <p
                  className={` ${
                    params.row.disabled ? "text-red-600" : "text-green-600"
                  }`}
                >
                  {params.row.disabled ? "Suspended" : "Active"}
                </p>
                <Chip label={item?.brd_list?.name} />
              </div>
            );
          })}
        </div>
      ),
    },
    {
      field: "lastSeen",
      headerName: "Last Seen",
      width: 150,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      width: 550,
      renderCell: (params: any) => (
        <div className="flex items-center justify-center gap-x-5">
          {brand_id && (
            <Button
              variant="outlined"
              onClick={() => {
                navigate(
                  `/admin/brands/${brand_id}/customer/${params.row.uuid}`
                );
              }}
            >
              TIMELINE
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              setUser(params?.row?.rowData);
              setSidePopup(true);
            }}
          >
            EDIT
          </Button>
          <Button
            onClick={() => {
              setUser(params?.row?.rowData);
              setShowPayments(true);
            }}
            variant="contained"
          >
            Payments
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenModel(params.row.uuid)}
          >
            FingerPrint LOGS
          </Button>
          <Button
            onClick={() => {
              deleteUser(params.row.uuid);
            }}
            variant="contained"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const rows = [
    ...data.map((user: any, index: any) => ({
      uuid: user?.id,
      id: index + 1,
      name: user?.displayName,
      email: user?.email,
      pseudoName: user?.thp_users_profile?.[0]?.pseudo_name,
      alias: user?.metadata?.alias,
      brands: user?.brd_users,
      phoneNumber: user?.phoneNumber,
      address: "N/A",
      role: user?.defaultRole,
      rowData: user,
      disabled: user?.disabled,
      lastSeen: getUserStatus(user?.lastSeen),
    })),
  ];

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <Box>
        <DataGrid
          sx={
            darkMode
              ? {
                  border: "none",
                  backgroundColor: "#312D4B",
                  boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                  borderRadius: "6px",
                  transition:
                    "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  outline: "none",
                }
              : {
                  border: "none",
                  backgroundColor: "white",
                  boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                  borderRadius: "6px",
                  transition:
                    "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  outline: "none",
                }
          }
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          rowHeight={100}
        />
      </Box>
      <SideDrawer
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
        component={
          <UserEdit user={user} brands={true} setEdit={setSidePopup} />
        }
      />
      <SideDrawer
        setSidePopup={setShowPayments}
        sidePopup={showPayments}
        component={
          <AdjustPayments
            heading={
              user?.defaultRole === "vendor"
                ? "Adjust Vendor Balance"
                : "Adjust Customer Balance"
            }
            user={user}
            setSidePopup={setShowPayments}
          />
        }
      />
      <Modal open={openModel} onClose={() => setOpenModel(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <div>
            <h1 className="text-center text-3xl text-basic">
              FingerPrints Logs
            </h1>
            <Divider />
            {fingerPrintData?.user_fingerprint_ids?.map((item: any) => {
              return (
                <FingerPrintsLogs
                  item={item}
                  refetchFPIDs={refetchFPIDs}
                  setOpenModel={setOpenModel}
                />
              );
            })}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerTable;
