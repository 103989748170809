import { useState } from "react";
import { useParams } from "react-router-dom";
import ConversationList from "./components/ConversationList";
import ChatWindow from "./components/ChatWindow";
import DefaultWindow from "./components/DefaultWindow";
import MessageInfoBar from "~/modules/WhatsappModule/segments/WhatsappWindow/components/MessageInfoBar";
import CommunityWindow from "./components/CommunityWindow";
import WhatsappUserInfoBar from "./components/WhatsappUserInfoBar";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import ConsultantList from "./components/ConsultantList";
import LabelBar from "./components/LableBar";

export default function WhatsappWindow({ community = false }: any) {
  const { conversation_id } = useParams();

  const [showWhatsappSidebar, setShowWhatsappSidebar] = useState(true);
  const [showWhatsappChatBox, setShowWhatsappChatBox] = useState(
    conversation_id ? true : false
  );

  const [showUserInfo, setShowUserInfo] = useState(false);

  const [showMessageInfoBar, setShowMessageInfoBar] = useState(false);

  const [showConsultantBar, setShowConsultantBar] = useState(false);

  return (
    <>
      <div className="flex w-full h-[85vh] shadow border dark:border-dark-secondary mb-1 overflow-hidden bg-whatsappPrimaryBg dark:bg-dark-primary">
        {showWhatsappSidebar && (
          <ConversationList
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            community={community}
          />
        )}
        {showUserInfo ? (
          <WhatsappUserInfoBar
            conversation_id={conversation_id}
            showUserInfo={showUserInfo}
            setShowUserInfo={setShowUserInfo}
          />
        ) : showConsultantBar ? (
          <ConsultantList
            showConsultantBar={showConsultantBar}
            setShowConsultantBar={setShowConsultantBar}
          />
        ) : showMessageInfoBar ? (
          <MessageInfoBar
            showMessageInfoBar={showMessageInfoBar}
            setShowMessageInfoBar={setShowMessageInfoBar}
          />
        ) : conversation_id ? (
          <>
            {community ? (
              <CommunityWindow
                conversation_id={conversation_id}
                setShowMessageInfoBar={setShowMessageInfoBar}
                setShowUserInfo={setShowUserInfo}
              />
            ) : (
              showWhatsappChatBox && (
                <ChatWindow
                  setShowConsultantBar={setShowConsultantBar}
                  showWhatsappSidebar={showWhatsappSidebar}
                  setShowWhatsappChatBox={setShowWhatsappChatBox}
                  setShowWhatsappSidebar={setShowWhatsappSidebar}
                  conversation_id={conversation_id}
                  setShowMessageInfoBar={setShowMessageInfoBar}
                  setShowUserInfo={setShowUserInfo}
                />
              )
            )}
          </>
        ) : (
          <DefaultWindow />
        )}
      </div>
    </>
  );
}
