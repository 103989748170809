import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Avatar, Chip, TextField } from "@mui/material";
import { useState } from "react";
import ChatRow from "~/components/whatsapp/WhatsappSideBar/components/ChatRow";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CreateGroup from "./component/CreateGroup";

const NewGroup = ({ showGroupBar, setShowGroupBar }: any) => {
  const [contactNumbers, setContactNumbers] = useState<any>([]);

  const [createGroupBar, setCreateGroupBar] = useState(false);

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-whatsappSecondaryBg dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showGroupBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div className="bg-whatsappBasic px-[23px] text-white text-[19px] pt-[60px]">
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowGroupBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Add group members</p>
          </div>
        </div>
        <div className="px-[34px] pt-[26px]">
          <div className="flex flex-wrap gap-2">
            {contactNumbers?.map((item: any) => {
              return (
                <Chip
                  avatar={
                    <Avatar
                      alt="whatsappAvator"
                      src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
                    />
                  }
                  label={item}
                  onDelete={() => {
                    const updatedContacts = contactNumbers?.filter(
                      (newItem: any) => newItem !== item
                    );
                    setContactNumbers(updatedContacts);
                  }}
                />
              );
            })}
          </div>
          <div className="border-b text-[14px]">
            <input
              type="text"
              placeholder="Search name or number"
              className="bg-transparent border-none focus:ring-0 w-full h-[35px] focus:border-none focus:outline-none dark:text-dark-primary"
            />
          </div>
        </div>
        <div className="mt-3 overflow-y-scroll flex-grow">
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact1"
          />
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact2"
          />
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact3"
          />
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact4"
          />
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact5"
          />
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact6"
          />
          <ChatRow
            setContactNumbers={setContactNumbers}
            contactNumbers={contactNumbers}
            text="contact7"
          />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
          <ChatRow />
        </div>
        <div
          className={`w-full bg-whatsappPrimaryBg dark:bg-dark-primary flex items-center justify-center ${
            contactNumbers?.length > 0 ? "h-[300px]" : "h-[135px]"
          }`}
        >
          {contactNumbers?.length > 0 && (
            <div className="p-[13px] text-white bg-whatsappBasic rounded-full cursor-pointer">
              <ArrowForwardOutlinedIcon
                onClick={() => {
                  setCreateGroupBar(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <CreateGroup
        createGroupBar={createGroupBar}
        setCreateGroupBar={setCreateGroupBar}
      />
    </>
  );
};

export default NewGroup;
