import React from "react";

const SaveFloppy = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.605 3.14501L12.605 0.145008C12.5583 0.0986676 12.5029 0.062005 12.4419 0.0371227C12.381 0.0122404 12.3158 -0.000372089 12.25 8.35714e-06H11.25V4.00001C11.25 4.26522 11.1446 4.51958 10.9571 4.70711C10.7696 4.89465 10.5152 5.00001 10.25 5.00001H6.25C5.98478 5.00001 5.73043 4.89465 5.54289 4.70711C5.35535 4.51958 5.25 4.26522 5.25 4.00001V8.35714e-06H2.25C1.85217 8.35714e-06 1.47064 0.158044 1.18934 0.439348C0.908035 0.720652 0.75 1.10218 0.75 1.50001V13.5C0.75 13.8978 0.908035 14.2794 1.18934 14.5607C1.47064 14.842 1.85217 15 2.25 15H3.25V10.5C3.25 10.1022 3.40803 9.72065 3.68934 9.43934C3.97064 9.15804 4.35217 9 4.75 9H11.75C12.1478 9 12.5293 9.15804 12.8107 9.43934C13.092 9.72065 13.25 10.1022 13.25 10.5V15H14.25C14.6478 15 15.0293 14.842 15.3107 14.5607C15.592 14.2794 15.75 13.8978 15.75 13.5V3.50001C15.7504 3.4342 15.7378 3.36897 15.7129 3.30805C15.688 3.24713 15.6513 3.19173 15.605 3.14501Z"
        fill="#393939"
      />
      <path
        d="M6.25 8.34465e-06H10.25V4.00001H6.25V8.34465e-06ZM11.75 10H4.75C4.61739 10 4.49021 10.0527 4.39644 10.1464C4.30268 10.2402 4.25 10.3674 4.25 10.5V15H12.25V10.5C12.25 10.3674 12.1973 10.2402 12.1035 10.1464C12.0098 10.0527 11.8826 10 11.75 10Z"
        fill="#393939"
      />
    </svg>
  );
};

export default SaveFloppy;
