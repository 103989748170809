import { GET_THP_LIST } from "~/graphql/thp_list/Query";
import { useQuery } from "@apollo/client";

export default function useThpListData() {
  const { data, error } = useQuery(GET_THP_LIST);

  return {
    ThpListData: data?.thp_list,
  };
}
