import React from "react";

interface DataLabelProps {
  label: string;
  value: string;
  col?: boolean;
}

const DataLabel = ({ label, value, col }: DataLabelProps) => {
  return (
    <div className={`flex  ${col ? "flex-col" : "items-center"} gap-[10px] `}>
      <p className="font-bold">{label}:</p>
      <p>{value}</p>
    </div>
  );
};

export default DataLabel;
