import React from "react";
import Layout from "../../components/common/Layout";
import ProfitSlabsModule from "../../modules/ProfitSlabsModule";
import ProfitSlabsList from "../../modules/ProfitSlabsModule/segments/ProfitSlabsList";

export default function ProfitSlabs() {
  return (
    <div>
      <Layout>
        <ProfitSlabsModule>
          <ProfitSlabsList />
        </ProfitSlabsModule>
      </Layout>
    </div>
  );
}
