import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/common/Layout";
import InquiriesModule from "../../modules/InquiriesModule";
import InquiryDetail from "../../modules/InquiriesModule/segments/InquiryDetail/inquiryDetail";

export default function Inquiry() {
  const { inquiry_id }: any = useParams();

  return (
    <div>
      <Layout>
        <InquiriesModule>
          <InquiryDetail inquiry_id={inquiry_id} />
        </InquiriesModule>
      </Layout>
    </div>
  );
}
