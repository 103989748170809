import React from "react";
import Layout from "../../components/common/Layout";

export default function API() {
  return (
    <div>
      <Layout>
        <h1>API </h1>
      </Layout>
    </div>
  );
}
