import { gql } from "@apollo/client";

export const ADD_DOMAIN_THP = gql`
  query AddDomainTHP($domain: String!) {
    addDomainTHP(arg1: { domain: $domain }) {
      vercel {
        apexName
        createdAt
        gitBranch
        name
        projectId
        redirect
        redirectStatusCode
        updatedAt
        verified
      }
    }
  }
`;

export const ADD_DOMAIN_VERCEL = gql`
  query AddDomainTHP($domain: String!) {
    addDomainVercel(arg1: { domain: $domain }) {
      vercel {
        apexName
        createdAt
        gitBranch
        name
        projectId
        redirect
        redirectStatusCode
        updatedAt
        verified
      }
    }
  }
`;

export const UPDATE_DOMAIN = gql`
  mutation UpdateDomain($id: uuid!, $domain: String!) {
    update_thp_list_by_pk(pk_columns: { id: $id }, _set: { domain: $domain }) {
      id
      domain
    }
  }
`;

export const UPDATE_BRAND_WITH_DOMAIN = gql`
  mutation UpdateBrandWithDomain($brandId: uuid!, $domain: String!) {
    update_brd_list_by_pk(
      pk_columns: { id: $brandId }
      _set: { domain: $domain }
    ) {
      id
      domain
    }
  }
`;
