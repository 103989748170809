import { useQuery, useSubscription } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

const SourceFilter = ({
  allBrand,
  filters,
  setFilters,
  label,
  target,
  query,
}: any) => {
  const selectedBrandArrLet: any = [];
  allBrand.map((brand: any) => {
    selectedBrandArrLet.push(brand?.id);
  });

  const { loading, data, error } = useQuery(query, {
    variables: {
      brd_ids: selectedBrandArrLet,
    },
  });

  const handleFlagChange = (event: any, newValue: any) => {
    if (newValue) {
      setFilters({ ...filters, [target]: newValue[target] || "Organic" });
    } else {
      setFilters({ ...filters, [target]: null });
    }
  };

  if (loading) return <>Loading..</>;
  if (error) return <>Err..${error.message}</>;

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={[...data?.inq_list]}
        onChange={handleFlagChange}
        getOptionLabel={(option: any) =>
          target === "source_add_id"
            ? option?.acc_google_ad?.name
            : option[target] || "Organic"
        }
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
};

export default SourceFilter;
