import React, { useEffect, useState } from "react";
import { useUserData } from "@nhost/react";
import UserCard from "~/components/UserCard";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_UNREAD_COUNT } from "./graphql/Query";

export default function UnReadCount({
  conversation_id,
  last_view_time,
  active,
}: any) {
  const [count, setCount] = useState<any>(0);
  const { loading, error, data } = useSubscription(GET_UNREAD_COUNT, {
    variables: {
      conversation_id: conversation_id,
      last_view_time: last_view_time,
    },
  });

  useEffect(() => {
    if (data?.brd_gmail_messages_aggregate?.aggregate?.count > count)
      setCount(
        (prev: any) =>
          prev + data?.brd_gmail_messages_aggregate?.aggregate?.count
      );
    if (data?.brd_gmail_messages_aggregate?.aggregate?.count < count)
      setCount(
        (prev: any) =>
          prev - data?.brd_gmail_messages_aggregate?.aggregate?.count
      );
  }, [data?.brd_gmail_messages_aggregate?.aggregate?.count]);

  let countUnread = 0;
  return (
    <>
      {data?.brd_gmail_messages_aggregate?.aggregate?.count !== undefined ? (
        <>
          <>
            {data?.brd_gmail_messages_aggregate?.aggregate?.count >
              countUnread &&
            countUnread +
              data?.brd_gmail_messages_aggregate?.aggregate?.count !==
              0
              ? (
                  <span className="bg-[#25d366] h-[23px] w-[23px] rounded-full text-[12px] text-whatsappSecondaryBg flex items-center justify-center font-bold">
                    {
                      (countUnread +=
                        data?.brd_gmail_messages_aggregate?.aggregate?.count)
                    }
                  </span>
                ) || ""
              : (countUnread -
                  data?.brd_gmail_messages_aggregate?.aggregate?.count !==
                  0 && (
                  <span className="bg-[#25d366] h-[23px] w-[23px] rounded-full text-[12px] text-whatsappSecondaryBg flex items-center justify-center font-bold">
                    {
                      (countUnread -=
                        data?.brd_gmail_messages_aggregate?.aggregate?.count)
                    }
                  </span>
                )) ||
                ""}
          </>
        </>
      ) : (
        countUnread !== 0 && (
          <span className="leading-[1] bg-[green] text-[11px] font-bold text-[white] rounded-[10px] px-[7px] py-[2px]">
            {countUnread}
          </span>
        )
      )}
    </>
  );
}
