import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FacebookLogIn = () => {
    const navigate = useNavigate();

    const getAuthCode = () => {
        const currentURL = window.location.href; // This will include the entire URL including path
    
        const serverlessFunctionURL =
          "https://local.flygency.com/authenticateFacebookPage";
    
        window.location.href = `${serverlessFunctionURL}?origin=${encodeURIComponent(
          currentURL
        )}&type=page`;
      };

    return (
        <>
          <div className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="mb-2 text-xl font-semibold">Facebook Page</h3>
                <p>Connect Your Facebook Page Data</p>
              </div>
              <div>
                <Button variant="outlined" onClick={getAuthCode}>
                  Connect
                </Button>
              </div>
            </div>
          </div>
        </>
      );
}

export default FacebookLogIn;