import React from "react";
import BrandWise from "./components/BrandWise";
import useAssignedBrands from "~/hooks/useAssignedBrands";
import TravelHouseWise from "./components/TravelHouseWise";

export default function TeamList() {
  const assignedBrands = useAssignedBrands();

  if (assignedBrands) {
    return <BrandWise />;
  } else {
    return <TravelHouseWise />;
  }
  // return <></>;
}
