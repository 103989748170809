import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import AddFlightPage from "./components/AddFlightPage";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_FLIGHT_PAGE } from "./graphql/Query";
import FlightPageCard from "./components/FlightsPageCard";
import SidePopup from "~/components/common/SidePopup";

const FlightsPage = () => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_FLIGHT_PAGE, {
    variables: { id: brd_id },
  });

  if (loading) return <p>Loading...</p>;

  const flightPages = data?.brd_flights_pages;

  return (
    <div className="grid grid-cols-1 items-start md:grid-cols-2 gap-[20px] lg:grid-cols-4">
      {flightPages.length > 0 ? (
        flightPages.map((pg: any, i: number) => (
          <FlightPageCard key={i} item={pg} />
        ))
      ) : (
        <p>No record found</p>
      )}
      <SidePopup component={<AddFlightPage />} />
    </div>
  );
};

export default FlightsPage;
