import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Destinations from "~/modules/BrandModule/segments/Destinations";
export default function BrandDestinationPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <Destinations />
        </BrandModule>
      </Layout>
    </div>
  );
}
