import { gql } from "@apollo/client";

export const INSERT_TIP = gql`
  mutation insertTip($tipData: brd_flights_pages_tips_insert_input!) {
    insert_brd_flights_pages_tips_one(object: $tipData) {
      id
    }
  }
`;

export const DELETE_TIP = gql`
  mutation DeleteTip($id: uuid) {
    delete_brd_flights_pages_tips(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_TIP = gql`
  mutation MyMutation(
    $id: brd_flights_pages_tips_pk_columns_input!
    $tipData: brd_flights_pages_tips_set_input!
  ) {
    update_brd_flights_pages_tips_by_pk(pk_columns: $id, _set: $tipData) {
      id
    }
  }
`;
