import React from "react";
import { useParams } from "react-router-dom";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import InAppAlertsTemplates from "~/modules/BrandModule/modules/TriggersTemplates/segments/InAppAlertsTemplates";

const InAppAlertsTemplatesPage = () => {
  const { brd_id } = useParams();
  return (
    <div>
      <Layout>
        <BrandModule>
          <InAppAlertsTemplates brd_id={brd_id} />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default InAppAlertsTemplatesPage;
