import { MenuItem } from "@mui/material";

const EmailHeaderMenuItems = ({ setShowMenu, setShowDestinations }: any) => {
  return (
    <>
      <div className="shadow py-[9px] ">
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setShowDestinations(true);
          }}
        >
          Destinations
        </MenuItem>
      </div>
    </>
  );
};

export default EmailHeaderMenuItems;
