import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { INSERT_BRAND_SETTING, UPDATE_BRAND_SETTING } from "./graphql/Mutation";
import { toast } from "react-toastify";

const TextChanges = ({ brand }: any) => {
  const { brd_id } = useParams();
  const setting = brand?.brd_default_settings?.at(0);

  const client = useApolloClient();
  const [updateBrandSetting] = useMutation(UPDATE_BRAND_SETTING);
  const [insertBrandSetting] = useMutation(INSERT_BRAND_SETTING);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    if (setting?.id) {
      try {
        const response = await updateBrandSetting({
          variables: {
            id: {
              id: setting.id,
            },
            brdData: inpData,
          },
        });
        toast.success("Brand updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      } catch (e: any) {
        toast.error(e.message);
      }
    } else {
      const response = await insertBrandSetting({
        variables: {
          data: { ...inpData, brd_id: brd_id },
        },
      });
      if (response?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Brand updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Default Button Text</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              defaultValue={setting?.submit_inquiry_button_text}
              error={errors["submit_inquiry_button_text"] ? true : false}
              {...register("submit_inquiry_button_text", { required: true })}
              label="Submit Inquiry Button Text"
              type="text"
            />
          </div>
          <Button type="submit" sx={{ marginTop: "20px" }} variant="contained">
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default TextChanges;
