import { useUserId } from "@nhost/react";
import React from "react";
import UserCard from "~/components/UserCard";

export default function UsersGroup({ users, role }: any) {
  const userId = useUserId();
  return (
    <div className="mb-4">
      <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2 capitalize">
        {role}s ({users.filter((user: any) => user.defaultRole === role).length}
        )
      </h1>
      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        {users
          .filter((user: any) => user.defaultRole === role)
          .map((user: any, index: any) => (
            <UserCard
              key={index}
              user={user}
              brands={true}
              editAble={user.id === userId ? false : true}
            />
          ))}
        {users.filter((user: any) => user.defaultRole === role).length ===
          0 && <h2>No record found</h2>}
      </div>
    </div>
  );
}
