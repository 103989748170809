import React from "react";
import Layout from "../../components/common/Layout";
import UsersModule from "~/modules/UsersModule";
import VendorsList from "~/modules/UsersModule/segments/VendorsList";

export default function Vendors() {
  return (
    <div>
      <Layout>
        <UsersModule>
          <VendorsList />
        </UsersModule>
      </Layout>
    </div>
  );
}
