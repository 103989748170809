import moment from "moment-timezone";
// import airlinesData from "@data/travelingData/airlinesData";
import airlinesData from "../data/travelingData/airlinesData";
import airportData from "~/data/travelingData/airportsData";
import _ from "lodash";
import airportTz from "./airportTimezone.json";

export const getTimeReadableFormat = (timestamp: any) => {
  const currentDate: any = new Date();
  const inputDate: any = new Date(timestamp);
  const timeDiff = currentDate - inputDate;

  const minutes = Math.floor(timeDiff / (1000 * 60));
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  if (minutes < 1) {
    return "Just now";
  } else if (minutes === 1) {
    return "1 minute ago";
  } else if (minutes < 60) {
    return minutes + " minutes ago";
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (hours < 24) {
    const remainingMinutes = minutes - hours * 60;
    return hours + " hours " + remainingMinutes + " minutes ago";
  } else if (days === 1) {
    const formattedTime = formatTime(inputDate);
    return "Yesterday at " + formattedTime;
  } else if (days < 7) {
    const dayOfWeek = getDayOfWeek(inputDate);
    const formattedTime = formatTime(inputDate);
    return dayOfWeek + " at " + formattedTime;
  } else {
    const formattedDate = formatDate(inputDate);
    const formattedTime = formatTime(inputDate);
    return formattedDate + " at " + formattedTime;
  }
};

function formatTime(date: any) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight

  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes + " " + amPm;
}

export function getAirlineName(code: any) {
  const currAirline = airlinesData.filter((item: any) => item.iata === code);
  return currAirline[0];
  // return hours + ":" + minutes + " " + amPm;
}

function getDayOfWeek(date: any) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[date.getDay()];
}

function formatDate(date: any) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

export const getTotalPrice = (data: any) => {
  let totalAmount = 0;

  data.map((singleData: any) => {
    totalAmount += singleData.offer_price;
  });

  return totalAmount;
};

export function checkNullValuesForAll(arr: any) {
  for (let i = 0; i < arr.length; i++) {
    let obj = arr[i];
    // delete obj.created_by;
    // delete obj.updated_by;

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null) {
          return false;
        }
      }
    }
  }
  return true;
}

export function timeDiff(time: any) {
  return moment(time).from(moment());
}

export function timeDuration(
  timestamp1: any,
  iata1: any,
  timestamp2: any,
  iata2: any
) {
  const start = moment(timestamp1).format("YYYY-MM-DDTHH:mm:ss"); // Convert to Kampala time
  const end = moment(timestamp2).format("YYYY-MM-DDTHH:mm:ss"); // Convert to Kigali time

  const timeZone1 = airportTz.filter((item: any) => item.iata === iata1);
  const timeZone2 = airportTz.filter((item: any) => item.iata === iata2);

  const utcTimestamp1 = moment.tz(start, timeZone1?.[0]?.timezone);
  const utcTimestamp2 = moment.tz(end, timeZone2?.[0]?.timezone);
  // Calculate the duration
  const duration = moment.duration(utcTimestamp2.diff(utcTimestamp1)); // Corrected to use moment objects

  // Extract hours and minutes
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return `${hours}h ${minutes}m`;
}

export function overallLegTimeDuration(
  timestamp1: any,
  iata1: any,
  timestamp2: any,
  iata2: any
) {
  const start = moment(timestamp1).format("YYYY-MM-DDTHH:mm:ss"); // Convert to Kampala time
  const end = moment(timestamp2).format("YYYY-MM-DDTHH:mm:ss"); // Convert to Kigali time

  const timeZone1 = airportTz.filter((item: any) => item.iata === iata1);
  const timeZone2 = airportTz.filter((item: any) => item.iata === iata2);

  const utcTimestamp1 = moment.tz(start, timeZone1?.[0]?.timezone);
  const utcTimestamp2 = moment.tz(end, timeZone2?.[0]?.timezone);
  // Calculate the duration
  const duration = moment.duration(utcTimestamp2.diff(utcTimestamp1)); // Corrected to use moment objects

  // Extract hours and minutes
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return { hours, minutes };
}

export const getCityName = (iata: string) => {
  const city: any = _.find(airportData, (item: any) => {
    return item.iata_code === iata || item?.city?.iata_code === iata;
  });
  return city?.city_name;
};
