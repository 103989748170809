import { useApolloClient } from "@apollo/client";
import useAssigedBrands from "../useAssigedBrands";
import { useEffect, useState } from "react";
import { GET_TEMPLATES } from "~/graphql/actions/whatsapp/whatsapp_templates_get_all";

export default function useWhatsappTemplates(brdIds: any) {
  const client = useApolloClient();

  const [templates, setTemplates] = useState([]);
  const runFunction = async (brandID: any) => {
    const res = await client.query({
      query: GET_TEMPLATES,
      variables: { brd_id: brandID },
    });
    // setTemplates((prevTemplates): any => [
    //   ...prevTemplates,
    //   ...res.data?.whatsapp_templates_get_all?.data,
    // ]);
    if (
      res?.data?.whatsapp_templates_get_all?.data &&
      res?.data?.whatsapp_templates_get_all?.data?.length > 0
    ) {
      let templatesAr: any = [
        ...templates,
        ...res?.data?.whatsapp_templates_get_all?.data,
      ];
      setTemplates(templatesAr);
    }
  };

  useEffect(() => {
    setTemplates([]);
    if (brdIds && brdIds?.length > 0) {
      brdIds?.map((brd_id: any) => {
        runFunction(brd_id);
      });
    }
    // let count = 0;
    // while (count < brd_ids?.length) {
    //   runFunction(brd_ids?.[count]);
    //   count++;
    // }
  }, [brdIds]);

  return { templates: templates };
}
