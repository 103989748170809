import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import React from "react";
import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";
import airportData from "~/components/FormInputs/LocationBox/data/airports.json";
import moment from "moment";
import vector81 from "../../../images/Vector8.png";
import luggage from "../../../images/luggage.png";
import handCarry from "../../../images/HandCarry.png";
import { getStyles } from "../../../pdfDocument/styles";
const FlightInvoicePDF = ({ inquiry, invoice }: any) => {
  console.log("snbnsbdfs", invoice);
  const flightSuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "flights"
  )?.[0]?.inq_suggestion;

  const timeDuration = (timestamp1: any, timestamp2: any) => {
    const start = moment(timestamp1);
    const end = moment(timestamp2);
    const duration = moment.duration(end.diff(start));

    // Extract hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    // Output
    // ;
    return { hours, minutes };
  };

  const getHoursAndMinutes = (timestamp1: any, timestamp2: any) => {
    const duration = timeDuration(timestamp1, timestamp2);
    return `${duration?.hours} hours ${duration?.minutes} minutes`;
  };

  function getAirlineNameByIATA(iataCode: any) {
    const airline = airlineData.find(
      (airline: any) => airline.iata === iataCode
    );
    return airline?.marketing_name;
  }

  function getAirportNameByIATA(iataCode: any) {
    const airport = airportData.find(
      (airport: any) => airport.iata_code === iataCode
    );
    return airport?.name;
  }
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  return (
    <View style={styles.inquiryDetailContainer}>
      {flightSuggestions?.inq_suggestion_legs?.map(
        (suggestion: any, ind: number) => (
          <View>
            <View style={styles.flightDetailHeader}>
              <Text style={styles.flightTypeText}>
                {suggestion?.type == "departing"
                  ? "Depart"
                  : suggestion?.type == "returning" && "Return"}
              </Text>
              <View style={styles.flightCabinContainer}>
                <Text style={styles.flightCabinText}>{suggestion?.cabin}</Text>
                <Text>
                  |{" "}
                  {(() => {
                    let totalHours = 0;
                    let totalMinutes = 0;

                    suggestion?.inq_suggestion_leg_stops?.map(
                      (suggestions: any, ind: any) => {
                        const duration = timeDuration(
                          suggestions?.depart_datetime,
                          suggestions?.arrival_datetime
                        );
                        totalHours += duration.hours;
                        totalMinutes += duration.minutes;
                        // Ensure minutes don't exceed 60
                        totalHours += Math.floor(totalMinutes / 60);
                        totalMinutes %= 60;
                      }
                    );

                    return `${totalHours} hours ${totalMinutes} minutes`;
                  })()}
                </Text>
              </View>
            </View>
            <View style={styles.flightDetailBody}>
              {suggestion?.inq_suggestion_leg_stops
                ?.sort((a: any, b: any) => a.ordering - b.ordering)
                ?.map((legs: any, ind: any) => {
                  return (
                    <View style={styles.flightLegsParentContainer}>
                      <View style={styles.flightLegs}>
                        <View style={styles.flightLegContainers}>
                          <View style={styles.flightLegSubContainers}>
                            <Text style={styles.flightLegArrDep}>
                              {legs?.depart}
                            </Text>
                            <Text>
                              {`(${getAirportNameByIATA(legs?.depart)})`}
                            </Text>
                          </View>
                          <Text style={styles.flightLegDateTime}>
                            {moment(legs?.depart_datetime)
                              .zone(0)
                              .format("DD-MM-YYYY")}{" "}
                            |{" "}
                            {moment(legs?.depart_datetime)
                              .zone(0)
                              .format("HH:mm")}
                          </Text>
                        </View>
                        <Image style={styles.dottedImg} src={vector81} />
                        <View style={styles.airlineContainer}>
                          <Text style={styles.flightLegDateTime}>
                            {getHoursAndMinutes(
                              legs?.depart_datetime,
                              legs?.arrival_datetime
                            )}
                          </Text>
                          <View>
                            <Text style={styles.airlineText}>
                              {getAirlineNameByIATA(legs?.airline_iata)}{" "}
                              {`(${legs?.airline_iata})`}
                            </Text>
                          </View>
                        </View>
                        <Image style={styles.dottedImg} src={vector81} />
                        <View style={styles.flightLegContainerRight}>
                          <View style={styles.flightLegSubContainerRight}>
                            <Text style={styles.flightLegArrDep}>
                              {legs?.arrival}
                            </Text>
                            <Text>
                              {`(${getAirportNameByIATA(legs?.arrival)})`}
                            </Text>
                          </View>
                          <Text style={styles.flightLegDateTime}>
                            {moment(legs?.arrival_datetime)
                              .zone(0)
                              .format("DD-MM-YYYY")}{" "}
                            |{" "}
                            {moment(legs?.arrival_datetime)
                              .zone(0)
                              .format("HH:mm")}
                          </Text>
                        </View>
                      </View>
                      {suggestion?.inq_suggestion_leg_stops?.[ind + 1] && (
                        <Text style={styles.stayConatinerText}>
                          {getHoursAndMinutes(
                            legs?.arrival_datetime,
                            suggestion?.inq_suggestion_leg_stops?.[ind + 1]
                              ?.depart_datetime
                          )}{" "}
                          (Stay)
                        </Text>
                      )}
                    </View>
                  );
                })}
              <View style={styles.luggageContainer}>
                <View style={styles.luggageSubContainer}>
                  <Image style={styles.luggageImg} src={luggage} />
                  <Text style={styles.luggageText}>
                    {suggestion?.luggage} Kg
                  </Text>
                </View>
                <View style={styles.luggageSubContainer}>
                  <Image style={styles.handCarryImg} src={handCarry} />
                  <Text style={styles.luggageText}>
                    {suggestion?.hand_carry} Kg
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )
      )}
      <View style={styles.flightLegSubContainerRight}>
        {flightSuggestions?.inq_suggestion_costs?.map((cost: any, idx: any) => (
          <Text key={idx} style={styles.flightCabinText}>
            Sale Price: £ {cost?.sale_price}
          </Text>
        ))}
      </View>
    </View>
  );
};

export default FlightInvoicePDF;
