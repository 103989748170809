import { gql } from "@apollo/client";

export const GET_BRAND_DETAILS = gql`
  query GetBrandsQuery($brd_id: uuid) {
    brd_list(where: { id: { _eq: $brd_id } }) {
      created_at
      domain
      id
      name
      subdomain
      thp_id
      updated_at
      brd_details {
        logo
        primary_color
        secondary_color
        theme_id
        updated_at
        id
        created_at
        fav_icon
        footer_logo
        font_id
        iata
        authority_link
      }
      brd_contact_details {
        id
        country
        email
        phone
        address2
        address1
        city
        whatsapp
        created_at
      }
      brd_social_media {
        facebook
        discord
        instagram
        id
        linkedin
        pinterest
        tiktok
        updated_at
        x
        youtube
      }
      brd_seo_setting {
        canonical_url
        created_at
        default_description
        default_keywords
        default_title
        flight_page_id
        id
        og_image_url
        page_id
        updated_at
      }
    }
  }
`;

export const GET_AIRLINE_PAGE = gql`
  query GetAirlinePage($id: uuid, $airline_iata: String) {
    brd_airlines_pages(
      where: { airline_iata: { _eq: $airline_iata }, brd_id: { _eq: $id } }
    ) {
      id
    }
  }
`;
