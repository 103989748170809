import React, { useEffect, useRef, useState } from "react";
import { useRecorder } from "~/hooks/useRecorder";
import { nhost } from "~/lib/nhost";
import Button from "@mui/material/Button";
import { useStopwatch } from "react-timer-hook";

export default function AudioRecording({ setFileId }: any) {
  const [inputFile, setInputFile] = useState<any>();
  const { audioUrl, recording, fileBlob, startRecording, stopRecording } =
    useRecorder();
  const fileInputRef: any = useRef(null);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const nhostUploadFile = async (fileBlob: any) => {
    // const file: any = await convertToAAC(fileBlob);
    // ;
    // Create a File object from the Blob
    const file = new File([fileBlob], "filename.webm", {
      type: "audio/webm",
    });
    // const form: any = new FormData();
    // form.append("bucketId", "default");
    // form.append("file", fileBlob);
    // // const file = form.file;
    const response: any = await nhost.storage.upload({ file });
    if (response?.fileMetadata?.id) {
      setFileId(response.fileMetadata.id);
    }
  };

  useEffect(() => {
    if (fileBlob) {
      nhostUploadFile(fileBlob);
    }
    if (!audioUrl) {
      setFileId("");
    }
  }, [audioUrl]);

  return (
    <div>
      {!recording ? (
        <div
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          {audioUrl && <audio src={audioUrl} controls />}
          <div className="mt-2">
            <Button
              variant="contained"
              onClick={() => {
                startRecording();
                start();
              }}
            >
              {audioUrl ? "Record Again" : "Record"}
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            placeItems: "center",
          }}
        >
          {" "}
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "100px" }}>
              <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
              <span>{seconds}</span>
            </div>
            {/* <p>{isRunning ? "Recording.." : ""}</p> */}
            {/* <button onClick={start}>Start</button>
            <button onClick={pause}>Pause</button> */}
          </div>
          <Button
            variant="contained"
            onClick={() => {
              stopRecording();
              // stop();
            }}
          >
            Stop
          </Button>
        </div>
      )}
    </div>
  );
}
