import useSubscriptionPlans from "~/hooks/useSubscriptionPlans";
import { ADD_CREDITS_PLANS } from "~/graphql/def_credits_plan/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { Divider, Box, TextField, Autocomplete, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const AddDefPlanCredits = ({ setOpenMod }: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { subsPlans } = useSubscriptionPlans();

  const creditTypeOptions = [{ name: "Invoice", value: "invoice" }];

  const [addCreditsPlan] = useMutation(ADD_CREDITS_PLANS);

  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    const res = await addCreditsPlan({
      variables: {
        data: {
          plan_id: formData?.plan,
          credit_type: formData?.credit_type,
          credits: formData?.credits,
          price: formData?.price,
        },
      },
    });

    if (res?.data?.insert_def_credits_plan?.affected_rows) {
      client.refetchQueries({
        include: "active",
      });
      toast.success("Credits Plan Added Successfully");
      setOpenMod(false);
    }
  };

  return (
    <div className="">
      <h1 className="text-center text-3xl text-basic">Add Credits Plan</h1>
      <Divider />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("plan", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.id);
                }}
                options={
                  subsPlans
                    ? subsPlans.filter(
                        (item: any) =>
                          item.id !== "0833e55d-ef57-4797-859b-0d8b1e407cd9"
                      )
                    : []
                }
                getOptionLabel={(option: any) => option.plan_name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.plan_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Select Plan"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["plan"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("credit_type", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.name);
                }}
                options={creditTypeOptions ? creditTypeOptions : []}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Credit Type"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["credit_type"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <TextField
            label="Credits"
            {...register("credits", { required: true })}
            error={errors["credits"] ? true : false}
          />
          <TextField
            label="Price"
            {...register("price", { required: true })}
            error={errors["price"] ? true : false}
          />
        </div>
        <div className="flex items-center justify-center mt-10">
          <Button type="submit" variant="contained">
            Add Credits Plan
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default AddDefPlanCredits;
