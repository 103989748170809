import AutoModeIcon from "@mui/icons-material/AutoMode";
import { MenuItem } from "@mui/material";
import { useState } from "react";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DialogueBox from "./component/DialogueBox";

const SettingOptions = ({ setShowAutoReplyBar }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const [showDialogue, setShowDialogue] = useState(false);

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <MenuItem
        sx={{
          display: "flex",
          padding: "8px 16px 0 16px",
          color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
        }}
        onClick={() => {
          setShowAutoReplyBar(true);
        }}
      >
        <AutoModeIcon sx={{ margin: "16px" }} />
        <p className="text-[16px] py-[16px] border-b dark:border-dark-secondary flex-grow ml-[16px] text-black dark:text-white">
          Auto Reply
        </p>
      </MenuItem>
      <MenuItem
        sx={{
          display: "flex",
          padding: "8px 16px 0 16px",
          color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
        }}
        onClick={() => {
          setShowDialogue(true);
        }}
      >
        <DeleteSweepIcon sx={{ margin: "16px" }} />
        <p className="text-[16px] w-full py-[16px] border-b dark:border-dark-secondary flex-grow ml-[16px] text-black dark:text-white">
          Delete Failed Conversations
        </p>
      </MenuItem>
      <DialogueBox open={showDialogue} setOpen={setShowDialogue} />
    </>
  );
};

export default SettingOptions;
