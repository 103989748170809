import { gql } from "@apollo/client";

export const ADD_COMMUNITIES = gql`
  mutation MyMutation($data: [brd_communities_insert_input!]!) {
    insert_brd_communities(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_COMMUNITIES_USER = gql`
  mutation MyMutation($data: [brd_community_users_insert_input!]!) {
    insert_brd_community_users(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_COMMUNITIES = gql`
  mutation MyMutation(
    $iata: String
    $brdID: uuid
    $data: brd_communities_set_input
  ) {
    update_brd_communities(
      where: { iata: { _eq: $iata }, brd_id: { _eq: $brdID } }
      _set: $data
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_COMMUNITIES_USER = gql`
  mutation MyMutation($communityId: uuid) {
    delete_brd_community_users(where: { community_id: { _eq: $communityId } }) {
      affected_rows
    }
  }
`;
