import React from "react";

export default function Infant() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.85765 13.7537C3.94396 12.6819 4.71052 11.8735 5.64094 11.8735C5.69441 11.8735 5.7486 11.876 5.80279 11.8818L6.41444 11.948L6.21974 11.602C6.02075 11.311 5.86272 10.9853 5.75152 10.637C5.66262 10.3567 5.60468 10.0645 5.57908 9.76726L4.91599 11.3624L1.27715 12.7151C0.931495 12.8436 0.643992 13.126 0.477888 13.5003C0.311784 13.8745 0.280687 14.31 0.391438 14.7109C0.502188 15.1118 0.745714 15.4452 1.06844 15.6378C1.39117 15.8305 1.76666 15.8665 2.11232 15.7381L4.09998 14.9991C3.90755 14.6243 3.82282 14.1881 3.85765 13.7537ZM19.1369 2.0675C18.5799 0.400576 16.9623 -0.426009 15.5258 0.21943C14.0877 0.865715 13.375 2.74172 13.9328 4.40737C14.4904 6.07387 16.106 6.90152 17.5435 6.25523C18.9797 5.60895 19.6939 3.73485 19.1369 2.0675Z"
        fill="white"
      />
      <path
        d="M14.2495 11.6701L17.2175 13.1379C17.4401 13.2478 17.6912 13.2507 17.9156 13.146C18.1401 13.0414 18.3195 12.8377 18.4146 12.5797C18.5094 12.3216 18.512 12.0303 18.4218 11.77C18.3315 11.5096 18.1557 11.3015 17.9332 11.1915L15.3321 9.90481L14.4155 6.93305C14.3944 6.86468 14.3672 6.79902 14.3345 6.73709C13.7747 5.28242 12.3312 4.56016 11.0245 5.12138L7.69494 6.55192C6.33371 7.13599 5.63886 8.89116 6.14467 10.4684C6.24277 10.7753 6.38301 11.0618 6.55997 11.3167L7.24077 12.5266L5.76513 12.3666C5.58619 12.3472 5.40571 12.3689 5.23399 12.4303C5.06227 12.4918 4.90267 12.592 4.76432 12.725C4.4849 12.9936 4.30894 13.38 4.27516 13.7991C4.24138 14.2182 4.35254 14.6358 4.5842 14.9598C4.81585 15.2839 5.14901 15.4879 5.5104 15.5271L9.80941 15.9931C10.0657 16.0209 10.3236 15.9643 10.5534 15.8298C10.7832 15.6953 10.9757 15.4884 11.1087 15.2328C11.2416 14.9772 11.3098 14.6832 11.3052 14.3847C11.3006 14.0862 11.2236 13.7953 11.0829 13.5453L10.2001 11.9776L12.2547 11.095L12.2056 11.3823C12.1753 11.559 12.1846 11.7421 12.2326 11.9135L13.061 14.884C13.1356 15.151 13.2985 15.3727 13.514 15.5004C13.7295 15.6281 13.9798 15.6513 14.2101 15.565C14.4402 15.4786 14.6314 15.2896 14.7415 15.0398C14.8515 14.7899 14.8715 14.4996 14.7971 14.2326L14.0416 11.5239L14.042 11.5214C14.1055 11.58 14.1736 11.6325 14.2495 11.6701Z"
        fill="white"
      />
    </svg>
  );
}
