import { useQuery, useSubscription } from "@apollo/client";
import { GET_TRAVEL_HOUSE_DETAIL_WITH_SUB } from "../../graphql/thp_list/Query";
import { useUserId } from "@nhost/react";

export default function useThpListDetail(url: any) {
  const userId = useUserId();

  const { data } = useSubscription(GET_TRAVEL_HOUSE_DETAIL_WITH_SUB, {
    variables: { custom_url: url, user_id: userId },
  });

  return { thpHouse: data?.thp_list };
}
