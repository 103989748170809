import { gql } from "@apollo/client";

export const FETCH_USERS = gql`
   query MyQuery($th_id:uuid, $role:String) {
  user_travel_house(where: {th_id: {_eq: $th_id}, user: {defaultRole: {_eq: $role}}}) {
    user {
      id
      email
      displayName
      defaultRole
    }
    th_id
  }
}`
