import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
};

const ConfirmPopup = ({ confirmPopup, setConfirmPopup, onConfirm }: any) => {
    
    const handleClose = () => setConfirmPopup(false);

    return (
        <Modal
            open={confirmPopup}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-title" variant="h6" component="h2" className='text-primary dark:text-dark-primary'>
                    Confirm Deletion
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }} className='text-primary dark:text-dark-primary'>
                    Are you sure you want to delete this admission requirement?
                </Typography>
                <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} color="primary" variant="contained">
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmPopup;
