import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
const TrainCard = ({
  origin,
  destination,
  date,
  adults,
  children,
  infants,
}: any) => {
  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary rounded-2xl dark:text-dark-primary self-stretch flex flex-col h-full">
      <div className="self-stretch h-full flex flex-col pb-2.5 pt-7 px-[30px] mt-5 flex-grow">
        <div className="flex flex-row justify-between items-end h-full w-full">
          <div>
            <div className="bg-basic h-20 w-20 rounded-full flex items-center mb-3 justify-center">
              <DirectionsTransitIcon
                className="text-center text-white"
                fontSize="large"
              />
            </div>
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
              ORIGIN
            </div>
            <div className="relative text-3xl font-medium text-basic dark:text-dark-primary">
              {origin}
            </div>
          </div>

          <div>
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
              DESTINATION
            </div>
            <div className="relative text-3xl font-medium text-basic dark:text-dark-primary text-right">
              {destination}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-[150px] bg-basic gap-2 rounded-b-2xl py-5 px-8 flex-grow">
        <div className="flex-1 text-white">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative font-semibold text-base">Date: {date}</div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative font-semibold text-base">
              Adults: {adults}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between gap-x-3">
            <div className="relative font-semibold text-base">
              Children: {children}
            </div>
            {/* <div className="relative font-semibold text-base">
              Infants: {infants}
            </div> */}
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative font-semibold text-base">
              Infants: {infants}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainCard;
