import Layout from "~/components/common/Layout";
import ServicesModule from "~/modules/ServicesModule";
import Services from "~/modules/ServicesModule/segments/Services";

const ServicesPage = () => {
  return (
    <div>
      <Layout>
        <ServicesModule>
          <Services />
        </ServicesModule>
      </Layout>
    </div>
  );
};

export default ServicesPage;
