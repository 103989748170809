const URLs = {
  GRAPHQL_URL: process.env.REACT_APP_NHOST_GRAPHQL_URL as string,
  FILE_URL: process.env.REACT_APP_NHOST_STORAGE_URL as string,
  THP_DOMAIN: "etravelo.travelhouseportal.com",
  SAMPLE_PDF: "https://mag.wcoomd.org/uploads/2018/05/blank.pdf",
  SERVER_URL: process.env.REACT_APP_NHOST_GRAPHQL_URL as string,
};

const HASURA_KEYS = {
  CLIENT_NAME: "hasura-console",
  SECRET: process.env.REACT_APP_NHOST_ADMIN_SECRET as string,
};

const NHOST = {
  SUB_DOMAIN: process.env.REACT_APP_NHOST_SUBDOMAIN as string,
  REGIION: process.env.REACT_APP_NHOST_REGION as string,
};

const STRIPE_SK = {
  KEY: process.env.REACT_APP_SK as string,
};
const STRIPE_PK = {
  KEY: process.env.REACT_APP_PK as string,
};

const GOOGLE = {
  CLIENT_ID: process.env.REACT_APP_GMAIL_CLIENT_ID as string,
  CLIENT_SECRET: process.env.REACT_APP_GMAIL_CLIENT_SECRET as string,
  REDIRECT_URIS: process.env.REACT_APP_GMAIL_REDIRECT_URIS as string,
};

export { URLs, HASURA_KEYS, NHOST, STRIPE_SK, STRIPE_PK, GOOGLE };
