import React, { useState } from "react";
// import EditFAQs from "./components/EditTestimonial";
// import { GET_FLIGHTS_PAGES } from "./graphql/Query";
import { NavLink, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
// import AddTestimonials from "./components/AddTestimonial";
// import TestimonialRow from "./components/DestinationRow";
// import DestinationRow from "./components/DestinationRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle } from "react-icons/fa";
import Campaigns from "./Campaigns";
// import DestinationSettings from "./components/DestinationSettings";

const Google = () => {
    // const { brd_id } = useParams();
    // const [settings, setSettings] = useState(false);
    // const [addTsm, setAddTsm] = useState(false);
    // const { loading, data } = useQuery(GET_FLIGHTS_PAGES, {
    //     variables: { brd_id: brd_id },
    // });
    // if (loading) return <p>Loading...</p>;
    return (
        <>
            <Campaigns />
        </>
    );
};

export default Google;
