import { gql } from "@apollo/client";

export const GET_CAMPUS_COURSES = gql`
  query GetCampusCourses($campusId: uuid!) {
    brd_university_campuses(where: { id: { _eq: $campusId } }) {
      id
      name
      campus_courses {
        id
        course_id
        course_detail {
          id
          name
          duration
          course_type
          intake
          tuition_fees
        }
      }
    }
  }
`;

export const GET_CAMPUS_COURSES_WITH_NAME_OR_INTAKE_OR_TYPE = gql`
  query SearchCourses(
    $campusId: uuid!,
    $courseName: String,
    $intake: String,
    $courseType: String
  ) {
    uni_campus_courses(
      where: {
        campus_id: { _eq: $campusId },
        _or: [
          {
            _or: [
              { course_detail: { name: { _ilike: $courseName } } },
              { course_detail: { name: { _eq: "" } } }
            ]
          },
          {
            _or: [
              { course_detail: { intake: { _ilike: $intake } } },
              { course_detail: { intake: { _eq: "" } } }
            ]
          },
          {
            _or: [
              { course_detail: { course_type: { _ilike: $courseType } } },
              { course_detail: { course_type: { _eq: "" } } }
            ]
          }
        ]
      }
    ) {
      id
      course_detail {
        name
        course_type
        intake
        tuition_fees
        duration
      }
    }
  }
`;


