import { GET_LINKEDIN_USER_INFO } from "~/graphql/get_linkedin_u_info/Query";

export const getLinkedinProfileData = async (accessToken: any, client: any) => {
  const res = await client.query({
    query: GET_LINKEDIN_USER_INFO,
    variables: { access_token: accessToken },
  });

  const fullName = `${
    res?.data?.get_linkedin_u_info?.firstName?.localized?.en_US ?? ""
  } ${res?.data?.get_linkedin_u_info?.lastName?.localized?.en_US ?? ""}`;

  const profilePictureURL =
    res?.data?.get_linkedin_u_info?.profilePicture?.["displayImage~"]
      ?.elements?.[0]?.identifiers?.[0]?.identifier;

  return {
    name: fullName,
    image: profilePictureURL,
  };
};
