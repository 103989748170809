import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import WhatsappModule from "~/modules/BrandModule/modules/WhatsappModule";

export default function WhatsappPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <WhatsappModule />
        </BrandModule>
      </Layout>
    </div>
  );
}
