import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ADD_TRANSACTIONS } from "../../graphql/Mutations";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useNotifications from "~/hooks/useNotifications";
import {
  useFileUpload,
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import useEmail from "~/hooks/emails/useEmail";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";
import moment from "moment";

export default function BankPaymentForm({
  paymentMethods,
  inquiry_id,
  picked_by,
  inquiry,
  user_id,
  setHideForm,
  payment_method_id,
}: any) {
  const { travelHouse }: any = travelHouseStore();
  const emailSend = useEmail();
  const [addTransactions] = useMutation(ADD_TRANSACTIONS);
  const client = useApolloClient();
  const [isloading, setIsloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const userUserName = useUserDisplayName();
  const { upload, progress } = useFileUpload();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const userId = useUserId();

  const alert = useNotifications();
  const userRole = useUserDefaultRole();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const onSubmit = async (formData: any) => {
    console.log("djfgsfjsd", formData);

    setIsloading(true);
    formData["inq_id"] = inquiry_id; // add inquiry id to formdata
    formData["status"] = "pending"; // add status  to formdata
    formData["brd_id"] = inquiry?.brd_id;
    formData["created_at"] = moment(formData?.created_at)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00");
    const tenDigitCode: any = generateRandom10DigitCode();
    formData["transactions_no"] = tenDigitCode; // add status  to formdata
    if (userRole === "user") {
      formData["payment_method_id"] = payment_method_id; // add status  to formdata
    }
    if (selectedFile) {
      const res = await upload(selectedFile);
      formData["receipt"] = res?.id || "receipt"; // add receipt to formdata
    } else {
      formData["receipt"] = "receipt"; // add receipt to formdata
    }
    try {
      // Create Suggestion function call
      const res = await addTransactions({
        variables: { transactions: [formData] },
      });
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });
      if (res.data?.insert_inq_transection?.returning?.length > 0) {
        // const variables: any = {
        //   inquiryNo: inquiry?.inquiry_no,
        //   userFirstName: inquiry?.users?.displayName,
        //   userLastName: "",
        //   consultantName: userUserName,
        //   brandName: inquiry?.brd_list?.name,
        //   amount: formData.amount,
        //   bankName: paymentMethods.filter(
        //     (pay: any) => pay.id === formData.payment_method_id
        //   )[0].name,
        //   accountTitle: paymentMethods.filter(
        //     (pay: any) => pay.id === formData.payment_method_id
        //   )[0].account_title,
        //   accountNumber: paymentMethods.filter(
        //     (pay: any) => pay.id === formData.payment_method_id
        //   )[0].account_no,
        //   sortCode: paymentMethods.filter(
        //     (pay: any) => pay.id === formData.payment_method_id
        //   )[0].swift_code,
        // };
        // // ;
        // await emailSend(
        //   8,
        //   inquiry.brd_id,
        //   inquiry.users?.id,
        //   inquiry.users?.email,
        //   inquiry.users?.phoneNumber,
        //   variables
        // );
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // await alert.newNotification(
        //   "accountant",
        //   `Pending payment approval. Please check your portal`,
        //   "",
        //   ``
        // );
        // if (userRole === "user") {
        //   await alert.newNotification(
        //     picked_by,
        //     `Customer created the bank payment, please check your portal`,
        //     "",
        //     ``
        //   );
        // } else {
        //   await alert.newNotification(
        //     user_id,
        //     `Your payment is created by consultant, please check your portal`,
        //     "",
        //     ``
        //   );
        // }
        await client.resetStore();
      }
      setIsloading(false);
      setHideForm(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      console.error("error", e);
    }
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    const fileType = file.type.split("/")[1];
    if (!ALLOWED_IMAGE_FORMATS.includes(fileType)) {
      toast.error("Invalid File format");
    } else {
      setSelectedFile({ file });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="w-full">
          {userRole !== "user" ? (
            <div className="mb-4">
              <select
                {...register("payment_method_id", {
                  required: "Type is required",
                })}
                aria-invalid={errors.type ? "true" : "false"}
                className="w-full px-3 py-3 border rounded dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic leading-tight border-gray-300"
              >
                <option value="">-- Select Bank --</option>
                {paymentMethods.map((pay: any) => {
                  return (
                    <option key={`${pay?.id}`} value={`${pay?.id}`}>
                      {pay.name}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <div className="mb-4">
              <div>Bank: {paymentMethods[0].name}</div>
              <div>Account Title: {paymentMethods[0].account_title}</div>
              <div>Account No: #{paymentMethods[0].account_no}</div>
              <div>Sort Code: {paymentMethods[0].swift_code}</div>
            </div>
          )}

          <div className="mb-2">
            <input
              className="border border-slate-300 focus:outline-none focus:border-basic dark:bg-dark-primary dark:text-dark-primary py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full dark:placeholder:text-dark-secondary"
              type="text"
              placeholder="Reference No"
              {...register("ref_no", {
                required: "Reference No is required",
              })}
              aria-invalid={errors.refNo ? "true" : "false"}
            />
          </div>
          <CalendarBox
            control={control}
            setValue={setValue}
            errors={errors}
            fieldName="created_at"
            label="Date"
            labelClasses="font-normal dark:text-dark-primary"
            inputClasses="focus:outline-none focus:border-basic dark:bg-dark-primary dark:text-dark-primary"
          />
          <div className="pt-4 dark:text-dark-primary">
            <label>Receipt File</label>
            <input
              id="fileUpload"
              type="file"
              onChange={handleFileUpload}
              className="w-full px-2 py-2 border rounded dark:bg-dark-primary focus:outline-none focus:border-basic leading-tight border-gray-300"
            />
            {/* <input type="file" onChange={handleFileUpload} /> */}
            {progress && <p>Upload Progress: {progress}%</p>}
            {/* 
            <small className="text-[#b63434]">
              Only PDF file is acceptable.
            </small> */}
          </div>
        </div>
        <br />
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <input
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] dark:bg-dark-primary focus:outline-none focus:border-basic text-[14px] rounded-[5px] w-full"
              type="number"
              step="any"
              placeholder="Amount"
              {...register("amount", {
                required: "Amount is required",
              })}
              aria-invalid={errors.amount ? "true" : "false"}
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-basic block w-full text-white px-5 py-2 rounded hover:cursor-pointer hover:bg-purple-700 transition-all mt-[20px]"
        >
          {isloading ? "Adding.." : "Add Payment"}
        </button>
      </form>
    </>
  );
}
