import { gql } from "@apollo/client";

export const SEND_EMAIL = gql`
  mutation MyMutation($data: sendEmailInput!) {
    sendEmail(arg1: $data) {
      status
      message
    }
  }
`;
