import { gql } from "@apollo/client";

export const WHATASPP_SETTING_INSERT = gql`
  mutation MyMutation(
    $brd_id: uuid
    $data: [brd_whatsapp_setting_insert_input!]!
  ) {
    insert_brd_whatsapp_setting(objects: $data) {
      affected_rows
    }
  }
`;

export const WHATASPP_SETTING_DELETE = gql`
  mutation MyMutation($id: uuid) {
    delete_brd_whatsapp_setting(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
