import React, { useEffect, useState } from "react";
import { DateTimeBoxProps } from "./types";
import { Controller } from "react-hook-form";
import { DateTimePicker } from "@mantine/dates";
import moment from "moment";

export const DateTimeBox = ({
  control,
  errors,
  setValue,
  fieldName,
  label,
  labelClasses,
  containerClasses,
  defaultValue,
  inputClasses,
  minDate,
  maxDate,
  required = false,
}: DateTimeBoxProps) => {
  const [date, setDate] = useState<any>(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
      setDate(moment(defaultValue).toDate());
    }
  }, []);

  const hasError = (errors: any, fieldName: any) => {
    // Split the field name into parts
    const fieldParts = fieldName.split(".");
    // Iterate through the parts to navigate the error object
    let currentObj = errors;
    for (const part of fieldParts) {
      if (currentObj && currentObj[part]) {
        currentObj = currentObj[part];
      } else {
        // If any part is not found, the field has no error
        return { hasError: false, message: null };
      }
    }
    if (currentObj && currentObj.type === "required") {
      return { hasError: true, message: currentObj.message };
    } else {
      return { hasError: false, message: null };
    }
  };

  // Check if the specified field has an error
  const fieldHasError = hasError(errors, fieldName);

  return (
    <div className={`w-full ${containerClasses}`}>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: required }}
        render={({ field: { onChange } }) => (
          <DateTimePicker
            classNames={{
              input: `${inputClasses} ${
                fieldHasError?.hasError ? "border-red-500" : "border-gray-200"
              }`,
              label: `${labelClasses}`,
            }}
            className="m-0 w-full"
            valueFormat="YYYY-MM-DD hh:mm:ss"
            label={label}
            placeholder="Pick date"
            value={date}
            onChange={(e: any) => {
              setDate(e);
              onChange(e.toString());
            }}
            minDate={minDate ? moment(minDate).toDate() : undefined}
            maxDate={maxDate ? moment(maxDate).toDate() : undefined}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
        )}
      />
      {fieldHasError?.hasError && (
        <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
          {" "}
          <sup>*</sup>This is required.
        </p>
      )}
    </div>
  );
};
