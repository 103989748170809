import Layout from "~/components/common/Layout";
import ServicesModule from "~/modules/ServicesModule";
import ServicesFaqs from "~/modules/ServicesModule/segments/Services/ServicesFaqs";
import ServicesTestimonials from "~/modules/ServicesModule/segments/Services/ServicesTestimonials";

import ServicesTips from "~/modules/ServicesModule/segments/Services/ServicesTips";
import ServicesSEOSettings from "~/modules/ServicesModule/segments/Services/components/ServicesSEOSettings";

import EditServices from "~/modules/ServicesModule/segments/Services/components/ShowServices/components/EditServices";

const EditServicesPage = () => {
  return (
    <>
      <Layout>
        <ServicesModule>
          <>
            <EditServices />
            <ServicesTips />
            <ServicesFaqs />
            <ServicesTestimonials />
            <ServicesSEOSettings />
          </>
        </ServicesModule>
      </Layout>
    </>
  );
};

export default EditServicesPage;
