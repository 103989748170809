import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { PieChart } from "@mui/x-charts/PieChart";

export default function Pie({ data }: any) {
  return (
    <PieChart
      series={[
        {
          data: data,
          innerRadius: 30,
          paddingAngle: 1,
          cornerRadius: 5,
          outerRadius: 100,
          startAngle: -180,
          endAngle: 180,
          cx: 100,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -10, color: "gray" },
        },
      ]}
      height={300}
    />
  );
}
