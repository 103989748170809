import { GET_DEF_SUBSCRIPTION_PLANS } from "~/graphql/def_subscription_plans/Query";
import { useQuery } from "@apollo/client";

export default function useSubscriptionPlans() {
  const { data, error } = useQuery(GET_DEF_SUBSCRIPTION_PLANS);

  return {
    subsPlans: data?.def_subscription_plans,
  };
}
