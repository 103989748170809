import { gql } from "@apollo/client";

export const GET_MAILJET_CAMPAIGNS = gql`
  query getMailjetCampaigns($brdId: String!) {
    get_mailjet_campaigns(arg1: { brdId: $brdId }) {
      Count
      Data {
        ClickedCount
        DeliveredCount
        EditMode
        EditType
        ID
        IDType
        OpenedCount
        ProcessedCount
        SendTimeStart
        Starred
        Status
        Subject
        Title
      }
    }
  }
`;