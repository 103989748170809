import { useState } from "react";
import whatsappDisplayImage from "../../../assets/img/whatsapp.png";
import HttpsIcon from "@mui/icons-material/Https";

const WhatsappDisplay = () => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="w-full h-full md:flex flex-col px-5 hidden">
      <div className="flex flex-col items-center justify-center h-full flex-grow text-secondary dark:text-dark-secondary text-[14px]">
        <img
          src={whatsappDisplayImage}
          width={400}
          alt="whatsappDisplayImage"
        />
        <h1 className="mt-7 mb-3 text-primary dark:text-dark-primary text-[32px]">
          Whatsapp Web
        </h1>
        <p className="font-medium">
          Send and receive messages without keeping your phone online.
        </p>
        <p className="font-medium">
          Use WhatsApp on up to 4 linked devices and 1 phone at the same time.
        </p>
      </div>
      <div className="flex my-10 items-center justify-center">
        <HttpsIcon
          sx={{ fontSize: "13px", color: darkMode ? "#FFFFFF" : "#3A354199" }}
        />
        <p className="text-[14px] ml-[3px] text-secondary dark:text-dark-secondary font-medium">
          Your personal messages are end-to-end encrypted
        </p>
      </div>
    </div>
  );
};

export default WhatsappDisplay;
