import React, { useState } from "react";
import PostDetailsForm from "./PostDetailsForm";
import SocialMediaPostSchedulerHeader from "./SocialMediaPostSchedulerHeader";

import { useForm } from "react-hook-form";
import { CREATE_LINKEDIN_POST } from "~/graphql/create_linkedin_post/Mutation";
import { CREATE_FACEBOOK_PAGE_POST } from "~/graphql/create_facebook_page_post/Mutation";
import { CREATE_INSTAGRAM_POST } from "~/graphql/create_ig_post/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { URLs } from "~/config/enums";

const PostScheduler = () => {
  const [selectedPlatform, setSelectedPlatform] = useState<any>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<any>();
  const [Loading, setLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const [createLinkedInPost] = useMutation(CREATE_LINKEDIN_POST);
  const [createFacebookPagePost] = useMutation(CREATE_FACEBOOK_PAGE_POST);
  const [createInstagramPost] = useMutation(CREATE_INSTAGRAM_POST)

  const deleteMediaFromNhost = async (fileId: string) => {
    try {
      const response = await nhost.storage.delete({
        fileId: fileId,
      });
  
      if (response.error) {
        console.error("Error deleting media:", response.error.message);
        throw new Error(response.error.message);
      }
  
      console.log(`Media with ID ${fileId} deleted successfully`);
    } catch (error) {
      console.error("Failed to delete media:", error);
      throw error;
    }
  };

  const submitPostData = async (formData: any) => {
    console.log("Form Data:", formData);
    setLoading(true);
    try {
      const {
        accounts,
        brandSelect,
        platform,
        postContent,
        postStatus,
        postType,
        videoBase64Array,
        visibility,
        imageBase64Array,
      } = formData;

      for (const account of accounts) {

        if (account.platform === "LinkedIn") {
          const response = await createLinkedInPost({
            variables: {
              access_token: account.accessToken,
              id: account?.id,
              author: account?.platformType === "profileLogin" ? "person" : "organization",
              lifecycleState: postStatus,
              shareCommentaryText: postContent,
              shareMediaCategory: postType,
              mediaStatus: "READY",
              visibility: visibility,
              mediaImage: imageBase64Array?.length > 0 ? imageBase64Array : [],
              mediaVideo: videoBase64Array?.length > 0 ? videoBase64Array : [],
            },
          });

          toast.success(
            `Post created successfully: ${response.data.createlinkedinPost.id}`
          );
        }

        if (account.platform === "Facebook") {
          const response = await createFacebookPagePost({
            variables: {
              access_token: account?.accessToken,
              pageId: account?.id,
              mediaType: postType === "IMAGE"
                ? (imageBase64Array?.length > 1 ? "MULTI_IMAGE" : postType)
                : postType,
              mediaData: imageBase64Array?.length > 0 ? imageBase64Array : videoBase64Array?.length > 0 ? videoBase64Array : null,
              message: postContent,
            },
          })

          console.log("Facebook Response: ", response)

          toast.success(
            `Post created successfully!`
          )
        }

        // if (isInstagram) {
        //   Promise.all((imageBase64Array || videoBase64Array)?.map(async (item: any) => {

        //     const mimeType = item.split(",")[0].split(":")[1].split(";")[0];
        //     const onlyType = item.split(",")[0].split("/")[1].split(";")[0];
        //     const signBlob = await fetch(item).then((res) => res.blob());
        //     const file = new File([signBlob], `instagramMedia.${onlyType}`, {
        //       type: mimeType,
        //     });

        //     const uploadMediaToNhost = async () => {
        //       try {
        //         const response = await nhost.storage.upload({
        //           file,
        //         });

        //         if (response.error) {
        //           console.error("Upload error:", response.error.message);
        //           throw new Error(response.error.message);
        //         }

        //         return response.fileMetadata.id;

        //       } catch (error) {
        //         console.error("Failed to upload image:", error);
        //         throw error;
        //       }
        //     }
            
        //     const uploadResponse = await uploadMediaToNhost()
        //     console.log("ajjws", uploadResponse)

        //     return URLs.FILE_URL + uploadResponse;
        //   })).then((data: any) => {

        //     const mediaDataFromNhost = data

        //     createInstagramPost({
        //       variables: {
        //         access_token: account?.accessToken,
        //         caption: postContent,
        //         ig_user_Id: account?.id,
        //         mediaData: mediaDataFromNhost,
        //         mediaType: postType
        //       },
        //     }).then(() => {
        //       console.log("Instagram Response: ", data)
        //       toast.success(
        //         `Post created successfully!`
        //       )
        //     })
        //   })
        // }
        if (account.platform === "Instagram") {
          // Upload media to Nhost
          const mediaIds = await Promise.all((imageBase64Array || videoBase64Array)?.map(async (item: any) => {
            const mimeType = item.split(",")[0].split(":")[1].split(";")[0];
            const onlyType = item.split(",")[0].split("/")[1].split(";")[0];
            const signBlob = await fetch(item).then((res) => res.blob());
            const file = new File([signBlob], `instagramMedia.${onlyType}`, { type: mimeType });
  
            const uploadMediaToNhost = async () => {
              try {
                const response = await nhost.storage.upload({ file });
  
                if (response.error) {
                  console.error("Upload error:", response.error.message);
                  throw new Error(response.error.message);
                }
  
                return response.fileMetadata;
              } catch (error) {
                console.error("Failed to upload media:", error);
                throw error;
              }
            };
            
            return await uploadMediaToNhost();
          }));
  
          const mediaDataFromNhost = mediaIds.map(({ id }) => URLs.FILE_URL + id);
  
          // Create Instagram post
         const res = await createInstagramPost({
            variables: {
              access_token: account?.accessToken,
              caption: postContent,
              ig_user_Id: account?.id,
              mediaData: mediaDataFromNhost,
              mediaType: postType,
            },
          });
  
          toast.success("Post created successfully!");
  
          // Delete media from Nhost after successful Instagram upload
          await Promise.all(mediaIds.map(({ id }) => deleteMediaFromNhost(id)));
        }
      }
    } catch (error) {
      toast.error(`Error creating post: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitPostData)}>
        <SocialMediaPostSchedulerHeader
          control={control}
          setSelectedPlatform={setSelectedPlatform}
          selectedPlatform={selectedPlatform}
          selectedBrandId={selectedBrandId}
          setSelectedBrandId={setSelectedBrandId}
          setLoading={setLoading}
          Loading={Loading}
        />
        <PostDetailsForm
          Loading={Loading}
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          selectedPlatform={selectedPlatform}
        />
      </form>
    </>
  );
};

export default PostScheduler;
