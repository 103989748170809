import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import _airportsData from "../../../../../../../../components/FormInputs/LocationBox/data/airports.json";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { getAmadeusFlights } from "~/utils/flights";

const AddSugFares = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});

  const onSubmit = async (inpData: any) => {
    const seachData = {
      from: inpData?.origin,
      to: inpData?.destination,
      cabinClass: "economy",
      adults: 1,
      infants: 0,
      children: 0,
      tripType: "oneWay",
      start: moment(inpData?.originDate, "ddd MMM DD YYYY HH:mm:ss Z").format(
        "YYYY-MM-DD"
      ),
      end: moment(
        inpData?.destinationDate,
        "ddd MMM DD YYYY HH:mm:ss Z"
      ).format("YYYY-MM-DD"),
    };

    const flights = await getAmadeusFlights(seachData);
  };

  return (
    <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <h1 className="text-center text-3xl text-basic">Add Fares</h1>
        <Divider sx={{ marginBottom: "30px" }} />
        <div className="grid grid-cols-1 gap-5 mt-5">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("origin", {
              required: true,
            })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.iata_code);
                }}
                filterOptions={(options, { inputValue }: any) => {
                  // If input is empty, don't show any suggestions
                  if (!inputValue.trim()) {
                    return [];
                  }

                  // Filter options to only include those that start with the user's input
                  const inputValueLowerCase = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.city_name
                        .toLowerCase()
                        .startsWith(inputValueLowerCase) ||
                      option.iata_code
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                  );
                }}
                options={_airportsData ? _airportsData : []}
                getOptionLabel={(option: any) =>
                  `${option.city_name || ""}-${option.iata_code}`
                }
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.city_name}-{option.iata_code}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Origin"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["origin"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("destination", {
              required: true,
            })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.iata_code);
                }}
                filterOptions={(options, { inputValue }: any) => {
                  // If input is empty, don't show any suggestions
                  if (!inputValue.trim()) {
                    return [];
                  }

                  // Filter options to only include those that start with the user's input
                  const inputValueLowerCase = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.city_name
                        .toLowerCase()
                        .startsWith(inputValueLowerCase) ||
                      option.iata_code
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                  );
                }}
                options={_airportsData ? _airportsData : []}
                getOptionLabel={(option: any) =>
                  `${option.city_name || ""}-${option.iata_code}`
                }
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.city_name}-{option.iata_code}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Destination"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["destination"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register("originDate", { required: true })}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    value={value}
                    inputRef={ref}
                    onChange={(date) => {
                      onChange(date?.$d);
                    }}
                    sx={{ width: "100%" }}
                    label="Origin Date"
                    slotProps={{
                      textField: {
                        error: errors["originDate"] ? true : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register("destinationDate", { required: true })}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    value={value}
                    inputRef={ref}
                    onChange={(date) => {
                      onChange(date?.$d);
                    }}
                    sx={{ width: "100%" }}
                    label="Destination Date"
                    slotProps={{
                      textField: {
                        error: errors["destinationDate"] ? true : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <Button
          type="submit"
          sx={{ marginTop: "25px" }}
          variant="contained"
          fullWidth
        >
          Add Fares
        </Button>
      </Box>
    </div>
  );
};

export default AddSugFares;
