import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { GET_USER } from "./graphql/Query";
import moment from "moment";
import InputField from "~/components/FormInputs/InputField";
import { GET_SALARY } from "./graphql/Query";
import CheckMark from "~/assets/svg/CheckMark";

export default function SingleRecord({
  user,
  date,
  register,
  errors,
  index,
  requiredHours,
}: any) {
  const [isChecked, setIsChecked] = useState(true);

  const { data, error, loading } = useQuery(GET_SALARY, {
    variables: {
      user_id: user.id,
      month: moment(date).format("MM"),
      year: moment(date).format("YYYY"),
    },
  });

  let sendData: any = [];

  const Activity = user.def_user_activities;
  Activity.map((ite: any) => {
    let obj = { ...ite };
    obj["action"] = obj["action"] === "enter" ? "leave" : "enter";
    sendData.push(obj);
  });
  // ;

  let totalHours = 0;
  let enterTime: any = null;

  for (let item of sendData) {
    if (item.action === "enter") {
      // ;
      enterTime = new Date(item.timestamp);
    } else if (item.action === "leave" && enterTime) {
      // ;
      const leaveTime: any = new Date(item.timestamp);
      totalHours += leaveTime - enterTime;
      enterTime = null;
    }
  }

  // Convert time difference to hours and minutes
  const hours = Math.floor(totalHours / (1000 * 60 * 60));
  const minutesS = Math.floor((totalHours % (1000 * 60 * 60)) / (1000 * 60));

  let approvedHours = user?.thp_attendances?.reduce(
    (sum: any, obj: any) => sum + parseFloat(obj.hours),
    0
  );
  function convertMinutesToHoursAndMinutes(minutes: any) {
    if (isNaN(minutes) || minutes < 0) {
      return { error: "Invalid input" };
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return {
      M_hours: hours,
      M_minutes: remainingMinutes,
    };
  }
  const approvedMinutes = user?.thp_attendances?.reduce(
    (sum: any, obj: any) => sum + parseFloat(obj.minutes),
    0
  );
  const { M_hours, M_minutes } =
    convertMinutesToHoursAndMinutes(approvedMinutes);

  approvedHours += M_hours;

  const basic_salary = user?.thp_users_profile?.[0]?.basic_salary || 0;
  const perHousrSalary = requiredHours > 0 ? basic_salary / requiredHours : 0;

  let HoursWorked = 0;
  if (M_minutes && M_minutes > 0) {
    HoursWorked = approvedHours + 1;
  } else {
    HoursWorked = approvedHours;
  }

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <input
        {...register(`user.${index}.user_id`)}
        type="hidden"
        value={user.id}
      />

      <input
        {...register(`user.${index}.amount`)}
        type="hidden"
        value={Math.round(HoursWorked * perHousrSalary)}
      />

      <input
        {...register(`user.${index}.month`)}
        type="hidden"
        value={moment(date).format("MM")}
      />

      <input
        {...register(`user.${index}.year`)}
        type="hidden"
        value={moment(date).format("YYYY")}
      />

      <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              {...register(`user.${index}.process`)}
              id={`user.${index}.process`}
              aria-describedby="checkbox-1"
              type="checkbox"
              checked={
                data.acc_transactions.length > 0
                  ? false
                  : Math.round(HoursWorked * perHousrSalary) > 0
                  ? isChecked
                  : false
              }
              onClick={() => {
                setIsChecked(!isChecked);
              }}
              className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor={`user.${index}.process`} className="sr-only">
              checkbox
            </label>
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900  capitalize">
            {user.displayName}
            <pre className="text-sm font-semibold capitalize">
              {user.defaultRole}
            </pre>
            {data.acc_transactions.length > 0 && (
              <>
                <pre className="text-xs capitalize">
                  #{data.acc_transactions[0].transactions_no} ( Amount:{" "}
                  {data.acc_transactions[0].amount})
                </pre>
              </>
            )}
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900  capitalize">
            {moment(date).format("MMM YYYY")}
          </div>
        </td>

        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            {basic_salary}
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            {requiredHours || 0}
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            {hours}:{minutesS}
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            {approvedHours || 0}:{M_minutes || 0}
          </div>
        </td>

        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            {HoursWorked}
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            {perHousrSalary > 0 ? perHousrSalary.toFixed(2) : 0}
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-normal text-gray-900 ">
            <b>{Math.round(HoursWorked * perHousrSalary)}</b>
          </div>
        </td>
      </tr>
    </>
    // <div className="flex gap-4 mt-4 p-4 border-gray-100 border-t-[5px] w-[100%]">
    //   <div className="flex gap-4">
    //     <div className="">
    //       <h1 className="capitalize">
    //         {user.displayName} ({user.defaultRole})
    //       </h1>
    //     </div>
    //     <div className="flex gap-4">
    //       <span
    //         onClick={() => {}}
    //         className="cursor-pointer text-gray-500 mt-2 text-[12px] text-bold underline"
    //       >
    //         Tracked Time: {hours}:{minutesS}
    //       </span>
    //       <span
    //         onClick={() => {}}
    //         className=" pl-4 cursor-pointer text-gray-500 mt-2 text-[12px] text-bold underline"
    //       >
    //         Approved Time: {approvedHours || 0}:{M_minutes || 0}
    //       </span>
    //       <span
    //         onClick={() => {}}
    //         className=" pl-4 cursor-pointer text-gray-500 mt-2 text-[12px] text-bold underline"
    //       >
    //         Salary Per Hour: {perHousrSalary || 0}
    //       </span>
    //       <span
    //         onClick={() => {}}
    //         className=" pl-4 cursor-pointer text-gray-500 mt-2 text-[12px] text-bold underline"
    //       >
    //         Hours Worked: {HoursWorked}
    //       </span>
    //       <span
    //         onClick={() => {}}
    //         className=" pl-4 cursor-pointer text-gray-500 mt-2 text-[12px] text-bold underline"
    //       >
    //         Salary: {HoursWorked * perHousrSalary}
    //       </span>
    //     </div>
    //   </div>

    //   {data?.thp_attendance?.[0]?.id && (
    //     <input
    //       {...register(`user.${index}.id`)}
    //       type="hidden"
    //       value={data?.thp_attendance?.[0]?.id}
    //     />
    //   )}

    //   <input
    //     {...register(`user.${index}.user_id`)}
    //     type="hidden"
    //     value={user.id}
    //   />

    //   {/* <InputField
    //     register={register}
    //     errors={errors}
    //     type="text"
    //     fieldName={`user.${index}.hours`}
    //     required={true}
    //     placeHolder="Hour"
    //     className="w-full"
    //     defaultValue={100}
    //     readOnly={true}
    //   /> */}
    //   <div>
    //     {data?.thp_attendance?.[0]?.id && (
    //       <span className="pt-2 block">
    //         <CheckMark color={"green"} />
    //       </span>
    //     )}
    //   </div>
    // </div>
  );
}
