import { Box, Button, Divider, TextField, InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GET_DEFAULT_SETTINGS } from "./graphql/Query";
import { typingEffect } from "~/utils/typingEffect";
import { getAiData } from "~/utils/getAiData";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useState } from "react";

const AirlineSettings = ({ setSidePopup, sidePopup }: any) => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brd_id },
  });
  const defSettings = data?.brd_default_setting?.at(0);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const client = useApolloClient();

  const [airlineMainHeading, setAirlineMainHeading] = useState<any>(defSettings?.airline_page_heading)
  const [airlineSubHeading, setAirlineSubHeading] = useState<any>(defSettings?.airline_page_sub_heading)
  const [airlineSubHeading2, setAirlineSubHeading2] = useState<any>(defSettings?.airline_page_sub_heading_2)
  const [airlineTipsHeading, setAirlineTipsHeading] = useState<any>(defSettings?.airline_tips_heading)
  const [airlineTipsSubHeading, setAirlineTipsSubHeading] = useState<any>(defSettings?.airline_tips_sub_heading)
  const [airlineBlogsHeading, setAirlineBlogsHeading] = useState<any>(defSettings?.airline_blog_heading)
  const [airlineBlogsSubHeading, setAirlineBlogsSubHeading] = useState<any>(defSettings?.airline_blog_sub_heading)
  const [airlineReviewsHeading, setAirlineReviewsHeading] = useState<any>(defSettings?.airline_review_heading)
  const [airlineReviewsSubHeading, setAirlineReviewsSubHeading] = useState<any>(defSettings?.airline_review_sub_heading)
  const [airlineBestFlightsHeading, setAirlineBestFlightsHeading] = useState<any>(defSettings?.airline_best_flights_heading)
  const [airlineBestFlightsSubHeading, setAirlineBestFlightsSubHeading] = useState<any>(defSettings?.airline_best_flights_sub_heading)
  const [airlineLinkSectionHeading, setAirlineLinkSectionHeading] = useState<any>(defSettings?.airline_links_heading)
  const [airlineLinkSectionSubHeading, setAirlineLinkSectionSubHeading] = useState<any>(defSettings?.airline_links_sub_heading)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            airline_page_heading: inpData?.airline_page_heading,
            airline_page_sub_heading: inpData?.airline_page_sub_heading,
            airline_page_sub_heading_2: inpData?.airline_page_sub_heading_2,
            airline_tips_heading: inpData?.airline_tips_heading,
            airline_tips_sub_heading: inpData?.airline_tips_sub_heading,
            airline_blog_heading: inpData?.airline_blog_heading,
            airline_blog_sub_heading: inpData?.airline_blog_sub_heading,
            airline_links_heading: inpData?.airline_links_heading,
            airline_links_sub_heading: inpData?.airline_links_sub_heading,
            airline_review_heading: inpData?.airline_review_heading,
            airline_review_sub_heading: inpData?.airline_review_sub_heading,
            airline_best_flights_heading: inpData?.airline_best_flights_heading,
            airline_best_flights_sub_heading:
              inpData?.airline_best_flights_sub_heading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            airline_page_heading: inpData?.airline_page_heading,
            airline_page_sub_heading: inpData?.airline_page_sub_heading,
            airline_page_sub_heading_2: inpData?.airline_page_sub_heading_2,
            airline_tips_heading: inpData?.airline_tips_heading,
            airline_tips_sub_heading: inpData?.airline_tips_sub_heading,
            airline_blog_heading: inpData?.airline_blog_heading,
            airline_blog_sub_heading: inpData?.airline_blog_sub_heading,
            airline_links_heading: inpData?.airline_links_heading,
            airline_links_sub_heading: inpData?.airline_links_sub_heading,
            airline_review_heading: inpData?.airline_review_heading,
            airline_review_sub_heading: inpData?.airline_review_sub_heading,
            airline_best_flights_heading: inpData?.airline_best_flights_heading,
            airline_best_flights_sub_heading:
              inpData?.airline_best_flights_sub_heading,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="lg:w-[1000px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">
          Airline Page Setting
        </h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 mt-10 gap-5">
            <TextField
              error={errors["airline_page_heading"] ? true : false}
              {...register("airline_page_heading", { required: true })}
              label="Airline Main Heading"
              type="text"
              defaultValue={defSettings?.airline_page_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have main section. Write a main heading for Airline Page without quotes, as previous input is ${defSettings?.airline_page_heading}.`);
                        setValue('airline_page_heading', res)
                        await typingEffect(setAirlineMainHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineMainHeading}
              onChange={(e: any) => setAirlineMainHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_page_sub_heading"] ? true : false}
              {...register("airline_page_sub_heading", { required: true })}
              label="Airline Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_page_sub_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have main section. Write a main sub heading for Airline Page without quotes, as previous input is ${defSettings?.airline_page_sub_heading}.`);
                        setValue('airline_page_sub_heading', res)
                        await typingEffect(setAirlineSubHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineSubHeading}
              onChange={(e: any) => setAirlineSubHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_page_sub_heading_2"] ? true : false}
              {...register("airline_page_sub_heading_2")}
              label="Airline Sub Heading 2"
              type="text"
              defaultValue={defSettings?.airline_page_sub_heading_2}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have main section in which I have one main and two sub-heading. Write 2nd sub heading for Airline Page without quotes, as main heading is ${airlineMainHeading} and 1st sub-heading is ${airlineSubHeading}.`);
                        setValue('airline_page_sub_heading_2', res)
                        await typingEffect(setAirlineSubHeading2, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineSubHeading2}
              onChange={(e: any) => setAirlineSubHeading2(e.target.value)}
            />
          </div>
          <h1 className="text-basic text-2xl mt-5">Tips</h1>
          <Divider />
          <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
            <TextField
              error={errors["airline_tips_heading"] ? true : false}
              {...register("airline_tips_heading", { required: true })}
              label="Airline Tips Heading"
              type="text"
              defaultValue={defSettings?.airline_tips_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have tips section. Write tips heading for Airline Page without quotes, as previous input is ${defSettings?.airline_tips_heading}.`);
                        setValue('airlines_tips_heading', res)
                        await typingEffect(setAirlineTipsHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineTipsHeading}
              onChange={(e: any) => setAirlineTipsHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_tips_sub_heading"] ? true : false}
              {...register("airline_tips_sub_heading", { required: true })}
              label="Airline Tips Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_tips_sub_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have tips section. Write tips sub heading for Airline Page without quotes, as previous input is ${defSettings?.airline_tips_sub_heading}.`);
                        setValue('airline_tips_sub_heading', res)
                        await typingEffect(setAirlineTipsSubHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineTipsSubHeading}
              onChange={(e: any) => setAirlineTipsSubHeading(e.target.value)}
            />
          </div>
          <h1 className="text-basic text-2xl mt-5">Blog</h1>
          <Divider />
          <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
            <TextField
              error={errors["airline_blog_heading"] ? true : false}
              {...register("airline_blog_heading", { required: true })}
              label="Airline Blogs Heading"
              type="text"
              defaultValue={defSettings?.airline_blog_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have blogs section. Write blogs main heading for Airline Page without quotes, as previous input is ${defSettings?.airline_blog_heading}.`);
                        setValue('airline_blog_heading', res)
                        await typingEffect(setAirlineBlogsHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineBlogsHeading}
              onChange={(e: any) => setAirlineBlogsHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_blog_sub_heading"] ? true : false}
              {...register("airline_blog_sub_heading", { required: true })}
              label="Airline Blogs Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_blog_sub_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have blogs section. Write blogs sub heading for Airline Page without quotes, as previous input is ${defSettings?.airline_blog_sub_heading}.`);
                        setValue('airline_blog_sub_heading', res)
                        await typingEffect(setAirlineBlogsSubHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineBlogsSubHeading}
              onChange={(e: any) => setAirlineBlogsSubHeading(e.target.value)}
            />
          </div>
          <h1 className="text-basic text-2xl mt-5">Reviews</h1>
          <Divider />
          <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
            <TextField
              error={errors["airline_review_heading"] ? true : false}
              {...register("airline_review_heading", { required: true })}
              label="Airline Reviews Heading"
              type="text"
              defaultValue={defSettings?.airline_review_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have reviews section. Write reviews heading for Airline Page without quotes, as previous input is ${defSettings?.airline_review_heading}.`);
                        setValue('airline_review_heading', res)
                        await typingEffect(setAirlineReviewsHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineReviewsHeading}
              onChange={(e: any) => setAirlineReviewsHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_review_sub_heading"] ? true : false}
              {...register("airline_review_sub_heading", { required: true })}
              label="Airline Reviews Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_review_sub_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have reviews section. Write reviews sub-heading for Airline Page without quotes, as previous input is ${defSettings?.airline_review_sub_heading}.`);
                        setValue('airline_review_sub_heading', res)
                        await typingEffect(setAirlineReviewsSubHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineReviewsSubHeading}
              onChange={(e: any) => setAirlineReviewsSubHeading(e.target.value)}
            />
          </div>
          <h1 className="text-basic text-2xl mt-5">Best Flights</h1>
          <Divider />
          <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
            <TextField
              error={errors["airline_best_flights_heading"] ? true : false}
              {...register("airline_best_flights_heading", { required: true })}
              label="Airline Best Flights Heading"
              type="text"
              defaultValue={defSettings?.airline_best_flights_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have best flight section. Write best flight heading for Airline Page without quotes, as previous input is ${defSettings?.airline_best_flights_heading}.`);
                        setValue('airline_best_flights_heading', res)
                        await typingEffect(setAirlineBestFlightsHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineBestFlightsHeading}
              onChange={(e: any) => setAirlineBestFlightsHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_best_flights_sub_heading"] ? true : false}
              {...register("airline_best_flights_sub_heading", {
                required: true,
              })}
              label="Airline Best Flights Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_best_flights_sub_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have best flight section. Write best flight sub-heading for Airline Page without quotes, as previous input is ${defSettings?.airline_best_flights_sub_heading}.`);
                        setValue('airline_best_flights_sub_heading', res)
                        await typingEffect(setAirlineBestFlightsSubHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineBestFlightsSubHeading}
              onChange={(e: any) => setAirlineBestFlightsSubHeading(e.target.value)}
            />
          </div>
          <h1 className="text-basic text-2xl mt-5">Links</h1>
          <Divider />
          <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
            <TextField
              error={errors["airline_links_heading"] ? true : false}
              {...register("airline_links_heading", {
                required: true,
              })}
              label="Airline Link Section Heading"
              type="text"
              defaultValue={defSettings?.airline_links_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have link section. Write link section heading for Airline Page without quotes, as previous input is ${defSettings?.airline_links_heading}.`);
                        setValue('airline_links_heading', res)
                        await typingEffect(setAirlineLinkSectionHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineLinkSectionHeading}
              onChange={(e: any) => setAirlineLinkSectionHeading(e.target.value)}
            />
            <TextField
              error={errors["airline_links_sub_heading"] ? true : false}
              {...register("airline_links_sub_heading", {
                required: true,
              })}
              label="Airline Link Section Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_links_sub_heading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(`I have airlines page in which I have link section. Write link section sub-heading for Airline Page without quotes, as previous input is ${defSettings?.airline_links_sub_heading}.`);
                        setValue('airline_links_sub_heading', res)
                        await typingEffect(setAirlineLinkSectionSubHeading, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={airlineLinkSectionSubHeading}
              onChange={(e: any) => setAirlineLinkSectionSubHeading(e.target.value)}
            />
          </div>
          <Button
            sx={{ width: "100%", marginTop: "15px" }}
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AirlineSettings;
