import { useState, useEffect } from "react";
import Plan from "./components/Plan";
import { useQuery } from "@apollo/client";
import { GET_DEF_SUBSCRIPTION_PLANS } from "~/graphql/def_subscription_plans/Query";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import { stripe } from "~/lib/stripe";
import PaymentMethod from "../PaymentMethods";
import AddCardIcon from "@mui/icons-material/AddCard";
import { Box, Modal } from "@mui/material";
import PaymentDetail from "~/components/common/PaymentDetail";
import RemainingBalance from "~/modules/DashboardModule/segments/DashboardStats/components/Charts/components/RemainingBalance";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const Subscriptions = () => {
  const customUrl = GetCustomUrl(window.location.host);

  const [openMod, setOpenMod] = useState(false);

  const { thpHouse } = useThpListDetail(customUrl);

  const { data, loading } = useQuery(GET_DEF_SUBSCRIPTION_PLANS);

  const [currSub, setCurrSub] = useState(
    thpHouse?.[0]?.yearly === true ? "yearly" : "monthly"
  );

  const [paymentMeth, setPaymentMeth] = useState([]);
  const [defPaymentMeth, setDefPaymentMeth] = useState();
  const [curPlan, setCurPlan] = useState();

  const getCustomerPayDetail = async (custId: any) => {
    const paymentMethods: any = await stripe.paymentMethods.list({
      customer: custId,
      type: "card",
    });

    setPaymentMeth(paymentMethods?.data);

    const customer: any = await stripe.customers.retrieve(custId);

    const defaultPaymentMethodId =
      customer.invoice_settings.default_payment_method;

    setDefPaymentMeth(defaultPaymentMethodId);
  };

  useEffect(() => {
    if (thpHouse?.[0]?.subscription_user_ref) {
      getCustomerPayDetail(thpHouse?.[0]?.subscription_user_ref);
    }

    const selectedPlanIndex = data?.def_subscription_plans
      ?.filter(
        (item: any) => item.id !== "0833e55d-ef57-4797-859b-0d8b1e407cd9"
      )
      ?.sort((a: any, b: any) => {
        const order = ["Starter", "Growth", "Business"];
        return order.indexOf(a.plan_name) - order.indexOf(b.plan_name);
      })
      ?.findIndex((plan: any) => plan.id === thpHouse?.[0].plan_id);

    setCurPlan(selectedPlanIndex);

    setCurrSub(thpHouse?.[0]?.yearly === true ? "yearly" : "monthly");
  }, [thpHouse?.[0], data?.def_subscription_plans]);

  if (loading) return <div>Loading...</div>;
  return (
    <>
      <div className="flex flex-col gap-10">
        <div>
          <div className="flex items-center justify-between">
            <h1 className="font-bold text-lg">PAYMENT DETAILS</h1>
            <p
              onClick={() => setOpenMod(true)}
              className="text-lg flex items-center font-bold gap-2 cursor-pointer"
            >
              <AddCardIcon />
              <span>Add new payment method</span>
            </p>
          </div>
          <div className="flex flex-col gap-3 mt-5">
            {paymentMeth?.map((item: any) => {
              return (
                <PaymentMethod
                  data={item}
                  defPaymentMeth={defPaymentMeth}
                  getCustomerPayDetail={getCustomerPayDetail}
                />
              );
            })}
          </div>
        </div>
        <div>
          <h1 className="font-bold text-lg">SUBSCRIPTIONS</h1>
          <div className="bg-basic rounded-full w-[200px] mx-auto flex items-center mt-3 h-[50px] cursor-pointer">
            <button
              onClick={() => setCurrSub("monthly")}
              className={`h-[93%] ml-[3px] w-[50%] rounded-full font-bold duration-500 ease-in-out transition-all ${
                currSub === "monthly" ? "bg-white text-black" : "text-white"
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setCurrSub("yearly")}
              className={`h-[93%] mr-[3px] w-[50%] rounded-full font-bold duration-500 ease-in-out transition-all ${
                currSub === "yearly" ? "bg-white text-black" : "text-white"
              }`}
            >
              Yearly
            </button>
          </div>
          <div className="flex justify-center gap-10 mt-7 duration-500 ease-in-out transition-all">
            {data?.def_subscription_plans
              ?.filter(
                (item: any) =>
                  item.id !== "0833e55d-ef57-4797-859b-0d8b1e407cd9"
              )
              ?.sort((a: any, b: any) => {
                const order = ["Starter", "Growth", "Business"];
                return order.indexOf(a.plan_name) - order.indexOf(b.plan_name);
              })
              ?.map((item: any, ind: any) => {
                return (
                  <Plan
                    key={item.id}
                    data={item}
                    currSub={currSub}
                    travelHouse={thpHouse?.[0]}
                    getCustomerPayDetail={getCustomerPayDetail}
                    paymentMeth={paymentMeth}
                    curPlan={curPlan}
                    index={ind}
                  />
                );
              })}
          </div>
        </div>
        <Modal open={openMod} onClose={() => setOpenMod(false)}>
          <Box sx={{ ...style, width: "70%" }}>
            <PaymentDetail
              travelHouse={thpHouse?.[0]}
              getCustomerPayDetail={getCustomerPayDetail}
              setOpenMod={setOpenMod}
            />
          </Box>
        </Modal>
      </div>
      <h1 className="font-bold text-lg mt-16 mb-5">CREDITS</h1>
      <RemainingBalance
        type="account"
        curPlan={thpHouse?.[0]}
        defPaymentMeth={defPaymentMeth}
      />
    </>
  );
};

export default Subscriptions;
