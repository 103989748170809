import { gql } from "@apollo/client";

export const GET_BRAND_NAME = gql`
  query GetBrandsQuery($brd_id: uuid) {
    brd_list(where: { id: { _eq: $brd_id } }) {
      id
      name
    }
  }
`;
