import { useQuery } from "@apollo/client";
import PlanCredits from "~/modules/DefTablesModule/segments/DefPlanCredits/components/PlanCredits";
import { GET_DEF_CREDITS_PLAN_WITH_ID } from "~/graphql/def_credits_plan/Query";

const AddCredits = ({ openMod, setOpenMod, curPlan, defPaymentMeth }: any) => {
  const { data } = useQuery(GET_DEF_CREDITS_PLAN_WITH_ID, {
    variables: {
      planID: curPlan.plan_id,
    },
  });

  return (
    <div>
      <h1 className="text-center font-bold text-[25px] my-5">
        ADD CREDITS ({openMod})
      </h1>
      <div className="mt-7 flex items-center gap-[30px] justify-center">
        {data?.def_credits_plan?.map((item: any, ind: any) => {
          return (
            <PlanCredits
              key={ind}
              data={item}
              type="Add Credit"
              defPaymentMeth={defPaymentMeth}
              curPlan={curPlan}
              setOpenMod={setOpenMod}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddCredits;
