import { gql } from "@apollo/client";

export const GET_DOMAINS_BRANDS = gql`
  query GetDomain($thp_id: uuid!) {
    brd_list(where: { domain: { _is_null: false }, thp_id: { _eq: $thp_id } }) {
      domain
      name
      subdomain
      thp_list {
        domain
        name
        subdomain
        id
        brd_users {
          users {
            email
            defaultRole
          }
        }
      }
      id
    }
  }
`;

export const GET_ALL_BRANDS = gql`
  query GetDomain($thp_id: uuid!) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      domain
      name
      subdomain
      thp_list {
        domain
        name
        subdomain
        id
      }
      brd_users(where: { users: { defaultRole: { _eq: "admin" } } }) {
        users {
          email
        }
      }
      id
    }
  }
`;

export const GET_DOMAINS_THP = gql`
  query GetDomain($id: uuid!) {
    thp_list(where: { domain: { _is_null: false }, id: { _eq: $id } }) {
      domain
      name
      subdomain
      id
    }
  }
`;

export const VERIFY_DOMAIN = gql`
  query MyQuery($domain: String) {
    verifyVercelDomain(arg1: { domain: $domain }) {
      domain {
        aValues
        acceptedChallenges
        cnames
        configuredBy
        conflicts
        misconfigured
        nameservers
        serviceType
      }
    }
  }
`;

export const SEND_GRID_DOMAINS = gql`
  query GetSendGridDomains($domain: Int) {
    sendGridDomainsVerificationCheck(arg1: { domain: $domain }) {
      dns {
        dkim1 {
          data
          host
          reason
          type
          valid
        }
        dkim2 {
          data
          host
          reason
          type
          valid
        }
        mail_cname {
          data
          host
          reason
          type
          valid
        }
      }
      domain
      id
      valid
    }
  }
`;
