import { GET_SOCIAL_AUTHENTICATION_INFO } from "~/graphql/brd_social_authentication/Query";

export const getSocialAuthenticationInfo = async (
  client: any,
  brdIds: any
) => {

  const res = await client.query({
    query: GET_SOCIAL_AUTHENTICATION_INFO,
    variables: { brd_id: brdIds },
  });

  const platforms =
    res?.data?.brd_social_authentication?.map((item: any) => ({
      value: item.platform,
      label: item.platform,
    })) || []; // Default to empty array if no platforms are found

  // Create separate arrays for values and labels
  const values = platforms.map((platform: any) => platform.value);

  return {
    values,
  };
};
