import AddBrandPartner from "./components/AddBrandPartner";
import SidePopup from "~/components/common/SidePopup";
import ShowBrandPartner from "./components/ShowBrandPartner";

const BrandPartner = () => {
  return (
    <>
      <ShowBrandPartner />
      <SidePopup component={<AddBrandPartner />} />
    </>
  );
};

export default BrandPartner;
