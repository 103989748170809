import React, { useEffect } from "react";
import { InputFieldProps } from "./types";

const InputField = ({
  fieldName,
  required,
  onChange,
  defaultValue = "",
  type,
  placeHolder,
  label,
  register,
  errors,
  checked,
  className,
  containerClasses,
  inputClasses,
  labelClasses,
  errorClasses,
  readOnly = false,
  disabled=false
}: InputFieldProps) => {
  const hasError = (errors: any, fieldName: any) => {
    // Split the field name into parts
    const fieldParts = fieldName.split(".");
    // Iterate through the parts to navigate the error object
    let currentObj = errors;
    for (const part of fieldParts) {
      if (currentObj && currentObj[part]) {
        currentObj = currentObj[part];
      } else {
        // If any part is not found, the field has no error
        return { hasError: false, message: null };
      }
    }
    if (currentObj && currentObj.type === "required") {
      return { hasError: true, message: currentObj.message };
    } else {
      return { hasError: false, message: null };
    }
  };

  // Check if the specified field has an error
  const fieldHasError = hasError(errors, fieldName);
  return (
    <div className={`w-full ${containerClasses}`}>
      <div
        className={`${
          type === "radio" || type === "checkbox"
            ? "flex flex-row-reverse justify-end items-center gap-2"
            : ""
        }`}
      >
        {label && (
          <label
            className={`block text-primary dark:text-dark-primary ${
              type === "radio" || type === "checkbox" ? "mb-0" : ""
            } ${labelClasses ? labelClasses : "mb-2"}`}
            htmlFor={
              type === "radio" || type === "checkbox"
                ? `${fieldName}_${defaultValue}`
                : fieldName
            }
          >
            {label}
          </label>
        )}
        <input
          onChange={onChange}
          step={type === "number" && "0.01"}
          id={
            type === "radio" || type === "checkbox"
              ? `${fieldName}_${defaultValue}`
              : fieldName
          }
          type={type || "text"}
          placeholder={placeHolder || ""}
          {...register(fieldName, {
            required: required ? `* This is required` : false,
          })}
          defaultValue={defaultValue}
          defaultChecked={checked || false}
          min={0}
          readOnly={readOnly}
          disabled={disabled}
          aria-invalid={fieldHasError?.hasError ? "true" : "false"}
          className={` ${
            type === "radio" || type === "checkbox"
              ? ""
              : "self-stretch w-full px-[10px] pr-10"
          } border py-2 pl-3 text-sm leading-5 rounded text-primary dark:text-dark-primary bg-primary dark:bg-dark-primary focus:ring-0 ${
            fieldHasError?.hasError ? "border-red-500" : "br-gray"
          } ${
            fieldHasError?.hasError
              ? "focus:outline-none focus:ring-0 focus:ring-red-500"
              : "focus:outline-none focus:ring- focus:ring-basic"
          } ${inputClasses}`}
        />
      </div>
      {fieldHasError?.hasError && (
        <span
          role="alert"
          className={`text-red-500 m-1 text-[12px] normal-case ${errorClasses}`}
        >
          {fieldHasError?.message}
        </span>
      )}
    </div>
  );
};

export default InputField;
