import { gql } from "@apollo/client";

export const INSERT_BRD_PARTNERS = gql`
  mutation MyMutation($data: [brd_partners_insert_input!]!) {
    insert_brd_partners(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_PARTNER = gql`
  mutation DeleteBrdPartner($partnerId: uuid!) {
    delete_brd_partners(where: { id: { _eq: $partnerId } }) {
      affected_rows
    }
  }
`;

const DELETE_PARTNER_MUTATION = gql`
  mutation DeletePartner($id: String!) {
    delete_brd_partners(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_PARTNERS = gql`
  mutation UpdatePartner($id: uuid!, $name: String, $logo_id: uuid!) {
    update_brd_partners(
      where: { id: { _eq: $id } }
      _set: { name: $name, logo_id: $logo_id }
    ) {
      affected_rows
    }
  }
`;
