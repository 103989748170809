import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";

import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import { useRef } from "react";

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Today",
    getValue: () => {
      const today = dayjs();
      return [today, today];
    },
  },
  {
    label: "Yesterday",
    getValue: () => {
      const today = dayjs();
      const yesterday = today.subtract(1, "day");
      return [yesterday, yesterday];
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Prev Month",
    getValue: () => {
      const today = dayjs();
      const startOfPreviousMonth = today.startOf("month").subtract(1, "month");
      const endOfPreviousMonth = today.startOf("month").subtract(1, "day");
      return [startOfPreviousMonth, endOfPreviousMonth];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      const startOfThisYear = today.startOf("year");
      const endOfThisYear = today.endOf("year");
      return [startOfThisYear, endOfThisYear];
    },
  },
  {
    label: "Prev Year",
    getValue: () => {
      const today = dayjs();
      const startOfPreviousYear = today.startOf("year").subtract(1, "year");
      const endOfPreviousYear = today.endOf("year").subtract(1, "year");
      return [startOfPreviousYear, endOfPreviousYear];
    },
  },
];

const isSmallScreen = window.innerWidth <= 500;

const today = dayjs();
const currentMonth = [today.startOf("month"), today.endOf("month")];

export default function SingleInputDateRangePicker({ dates, setDates }: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["SingleInputDateRangeField"]}>
        <DateRangePicker
          slots={{
            field: SingleInputDateRangeField,
          }}
          name="allowedRange"
          label="Date Interval"
          className="pt-0"
          slotProps={{
            shortcuts: {
              items: isSmallScreen ? [] : shortcutsItems,
            },
            actionBar: { actions: [] },
            textField: {
              style: {
                minWidth: "200px",
              },
            },
          }}
          calendars={1}
          value={dates}
          onChange={(newValue) => setDates(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
