import { GET_BRAND_UNIVERSITIES } from "~/graphql/brd_universities/Query";
import { useQuery } from "@apollo/client";

export default function useBrdUniversitiesData(brd_id: any) {
  const { data } = useQuery(GET_BRAND_UNIVERSITIES, {
    variables: { brdId: brd_id },
  });

  return { brdUniversitiesData: data };
}
