import React from "react";

export default function Adult() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.834 14.9356C14.834 11.6368 13.5071 8.74498 11.5155 7.12641C10.6398 8.21875 9.31578 8.915 7.83404 8.915C6.3523 8.915 5.0282 8.21875 4.15252 7.12641C2.16087 8.74498 0.833984 11.6368 0.833984 14.9356C0.833984 14.9356 3.70499 16 7.76949 16C11.834 16 14.834 14.9356 14.834 14.9356Z"
        fill="white"
      />
      <path
        d="M7.83394 7.98311C9.97182 7.98311 11.7049 6.19603 11.7049 3.99155C11.7049 1.78708 9.97182 0 7.83394 0C5.69607 0 3.96298 1.78708 3.96298 3.99155C3.96298 6.19603 5.69607 7.98311 7.83394 7.98311Z"
        fill="white"
      />
    </svg>
  );
}
