export const ALLOWED_IMAGE_FORMATS = [
  "jpeg",
  "jpg",
  "png",
  "svg+xml",
  "webp",
  "pdf",
  "mp3",
];

export const ALLOWED_SCREEN_SHOT_FORMATS = ["jpeg", "jpg", "png", "webp"];
