import { gql } from "@apollo/client";

export const INSERT_SERVICES_FAQ = gql`
  mutation MyMutation($data: services_faqs_insert_input!) {
    insert_services_faqs(objects: [$data]) {
      affected_rows
    }
  }
`;
export const DELETE_SERVICES_FAQS = gql`
  mutation MyMutation2($id: uuid!) {
    delete_services_faqs(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_SERVICES_FAQS = gql`
  mutation UpdateServicesFaqs(
    $id: uuid!
    $description: String!
    $question: String!
  ) {
    update_services_faqs(
      where: { id: { _eq: $id } }
      _set: { description: $description, question: $question }
    ) {
      affected_rows
    }
  }
`;
