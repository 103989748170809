import React from "react";
import { useParams } from "react-router-dom";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import EmailTemplates from "~/modules/BrandModule/modules/TriggersTemplates/segments/EmailTemplates";
import BrandEmailTemplates from "~/modules/BrandModule/segments/BrandEmailTemplates";

const BrandEmailTemplatePage = () => {
  const { brd_id } = useParams();
  return (
    <div>
      <Layout>
        <BrandModule>
          {/* <BrandEmailTemplates brd_id={brd_id} /> */}
          <EmailTemplates brd_id={brd_id} />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default BrandEmailTemplatePage;
