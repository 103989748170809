import { useSubscription } from "@apollo/client";
import React from "react";
import { GET_COMMENTS } from "./graphql/Subscription";
import CommentCard from "./components/CommentCard";

const CommentList = ({ inquiry }: any) => {
  const { loading, data } = useSubscription(GET_COMMENTS, {
    variables: {
      inq_id: inquiry?.id,
    },
  });

  if (loading) return <p>Loading...</p>;

  const comments = data?.inq_comments;
  return (
    <div className="my-5">
      {comments?.length > 0 &&
        comments.map((item: any, i: number) => (
          <CommentCard key={i} comment={item} />
        ))}
    </div>
  );
};

export default CommentList;
