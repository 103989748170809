import { gql } from "@apollo/client";

export const GET_CONVERSATIONS = gql`
  subscription GetConversation(
    $condition: brd_gmail_conversation_bool_exp!
    $offset: Int!
  ) {
    brd_gmail_conversation(
      where: $condition
      order_by: { last_email_time: desc }
      limit: 10
      offset: $offset
    ) {
      id
      user_email
      user_name
      last_email_subject
      last_email_time
      last_view_time
      created_at
      updated_at
      brd_id
      brd_list {
        name
      }
    }
  }
`;

export const GET_CONVERSATIONS_QUERY = gql`
  query GetConversation(
    $condition: brd_gmail_conversation_bool_exp!
    $offset: Int!
  ) {
    brd_gmail_conversation(
      where: $condition
      order_by: { last_email_time: desc }
      limit: 10
      offset: $offset
    ) {
      id
      user_email
      user_name
      last_email_subject
      last_email_time
      last_view_time
      created_at
      updated_at
      brd_id
      brd_list {
        name
      }
    }
  }
`;
