import { useQuery } from "@apollo/client";
import { GET_TRANS_USERS } from "~/graphql/users/Query";
import { GET_ALL_BRANDS_PAYMENTS_METHODS } from "~/modules/AccountModule/segments/TransactionsList/components/VendorDeposit/graphql/Query";

export default function useUsers(brd_ids: any) {
  const { data } = useQuery(GET_TRANS_USERS, {
    skip: !brd_ids, // Don't run the query unless brdId is set
    variables: { brd_ids: brd_ids },
  });

  return { users: data?.users };
}
