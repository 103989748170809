import brandsStore from "~/store/brandsStore/brandsStore";
import Header from "./components/Header";
import SearchBar from "./components/SearchBar";
import { Chip } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import useBrandWhatsappLabels from "~/hooks/useBrandWhatsappLabels";
import InfiniteScroll from "react-infinite-scroller";
import { GET_CONVERSATIONS, GET_QUERY_CONVERSATIONS } from "./graphql/Query";
import ChatRow from "./components/ChatRow";
import { useApolloClient } from "@apollo/client";
import ChatRowSekeleton from "./components/Sekelton/components/ChatRowSekeleton";
import moment from "moment";
import ErrorIcon from "@mui/icons-material/Error";

const ConversationList = ({
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  community,
  setShowLabelBar,
}: any) => {
  const [brdPhones, setBrdPhones] = useState<any>([]);
  const [brdIds, setBrdIds] = useState<any>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [conversations, setConversations] = useState<any>([]);
  const [convTypes, setConvTypes] = useState<any>(["conversation", "assigned"]);
  const [hasMoreConv, setHasMoreConv] = useState<any>(true);
  const scrollParentRef: any = useRef(null);
  const [conversationSearchTerm, setConversationSearchTerm] = useState("");

  const today = dayjs();

  const client = useApolloClient();

  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];
  const [dates, setDates] = useState<any>(currentMonth);
  const startDate = moment(dates?.[0]?.$d).format("YYYY-MM-DD");
  const endDate = moment(dates?.[1]?.$d).format("YYYY-MM-DD");
  const startDateTime = `${startDate}T00:00:00Z`;
  const endDateTime = `${endDate}T23:59:59Z`;

  const { brands } = brandsStore();

  useEffect(() => {
    const brd_Phones = brands?.map((brd: any) => {
      return brd.phone;
    });
    setBrdPhones(brd_Phones);

    const brd_Ids = brands?.map((brd: any) => {
      return brd.id;
    });
    setBrdIds(brd_Ids);
  }, [brands]);

  useEffect(() => {
    if (scrollParentRef.current) {
      scrollParentRef.current.scrollTop = 0;
    }

    let unsubscribe: any;

    setConversations([]);

    if (brdPhones?.length > 0) {
      setHasMoreConv(true);
      const observable = client.subscribe({
        query: GET_CONVERSATIONS,
        variables: {
          offset: 0,
          condition: {
            brand_phone: { _in: brdPhones },
            last_message_time: { _gte: startDateTime, _lte: endDateTime },
            type: { _in: convTypes },
            ...(conversationSearchTerm.length > 2
              ? {
                  _or: [
                    { user_phone: { _ilike: `%${conversationSearchTerm}%` } },
                    {
                      whatsapp_name: {
                        _ilike: `%${conversationSearchTerm}%`,
                      },
                    },
                  ],
                }
              : {}),
          },
        },
      });

      unsubscribe = observable.subscribe({
        next(conv: any) {
          if (conv?.data.brd_whatsapp_conversations.length === 0) {
            setHasMoreConv(false);
          } else {
            const newConversations =
              conv?.data?.brd_whatsapp_conversations || [];

            setConversations((prevConversations: any) => {
              const updatedConversations = prevConversations.map(
                (existingConv: any) => {
                  const updatedConv = newConversations.find(
                    (newConv: any) => newConv.id === existingConv.id
                  );

                  // If a new conversation with the same id exists, update it
                  return updatedConv ? updatedConv : existingConv;
                }
              );

              // Add new conversations that do not already exist
              const newConversationsToAdd = newConversations.filter(
                (newConv: any) =>
                  !prevConversations.some((conv: any) => conv.id === newConv.id)
              );

              return [...updatedConversations, ...newConversationsToAdd];
            });
          }
        },
      });
    } else {
      setHasMoreConv(false);
    }

    // Cleanup previous subscription when effect dependencies change or on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe.unsubscribe();
      }
    };
  }, [brdPhones, dates, convTypes, conversationSearchTerm]);

  const conversationTypesArr = [
    { name: "Conversations", value: "conversation" },
    { name: "Others", value: "others" },
    { name: "Assigned", value: "assigned" },
    { name: "UnAssign", value: "unAssign" },
  ];

  const { whatsappLabels } = useBrandWhatsappLabels(brdIds);

  const loadMoreConv = async () => {
    if (!hasMoreConv || brdPhones.length < 1) return;

    client
      .query({
        query: GET_QUERY_CONVERSATIONS,
        variables: {
          offset: conversations.length,
          condition: {
            brand_phone: { _in: brdPhones },
            last_message_time: { _gte: startDateTime, _lte: endDateTime },
            type: { _in: convTypes },
            ...(conversationSearchTerm
              ? {
                  _or: [
                    { user_phone: { _ilike: `%${conversationSearchTerm}%` } },
                    {
                      whatsapp_name: {
                        _ilike: `%${conversationSearchTerm}%`,
                      },
                    },
                  ],
                }
              : {}),
          },
        },
        fetchPolicy: "network-only",
      })
      .then((conv: any) => {
        if (conv?.data.brd_whatsapp_conversations.length === 0) {
          setHasMoreConv(false);
        } else {
          const newConversations = conv?.data?.brd_whatsapp_conversations || [];

          setConversations((prevConversations: any) => {
            const updatedConversations = prevConversations.map(
              (existingConv: any) => {
                const updatedConv = newConversations.find(
                  (newConv: any) => newConv.id === existingConv.id
                );

                // If a new conversation with the same id exists, update it
                return updatedConv ? updatedConv : existingConv;
              }
            );

            // Add new conversations that do not already exist
            const newConversationsToAdd = newConversations.filter(
              (newConv: any) =>
                !prevConversations.some((conv: any) => conv.id === newConv.id)
            );

            return [...updatedConversations, ...newConversationsToAdd];
          });
        }
      });
  };

  console.log("shfjsdf", conversations, hasMoreConv, dates, brdPhones);

  return (
    <div className="border dark:border-dark-secondary md:max-w-[460px] w-full relative h-full flex flex-col">
      <div>
        <Header
          setShowLabelBar={setShowLabelBar}
          brand_phone={brdPhones}
          community={community}
        />
        <SearchBar
          setSearch={setConversationSearchTerm}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          dates={dates}
          setDates={setDates}
          whatsappLabels={whatsappLabels}
        />
      </div>
      <div className="flex p-[20px] pb-[10px] items-center gap-3 bg-whatsappSecondaryBg dark:bg-dark-secondary">
        {conversationTypesArr?.map((type: any) => (
          <Chip
            sx={{ cursor: "pointer" }}
            label={type.name}
            color="primary"
            variant={convTypes?.[0] === type.value ? "filled" : "outlined"}
            onClick={() => {
              type.value === "conversation"
                ? setConvTypes(["conversation", "assigned"])
                : type.value === "others"
                ? setConvTypes(["others"])
                : type.value === "assigned"
                ? setConvTypes(["assigned"])
                : type.value === "unAssign" &&
                  setConvTypes(["unAssign", "conversation"]);
            }}
          />
        ))}
      </div>
      <div
        ref={scrollParentRef}
        className="text-primary dark:text-dark-primary bg-whatsappSecondaryBg dark:bg-dark-secondary overflow-scroll flex-grow"
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreConv}
          hasMore={hasMoreConv}
          loader={<ChatRowSekeleton />}
          useWindow={false}
          getScrollParent={() => scrollParentRef.current}
        >
          {conversations?.length > 0 ? (
            conversations
              ?.sort(
                (a: any, b: any) =>
                  new Date(b.last_message_time).getTime() -
                  new Date(a.last_message_time).getTime()
              )
              ?.map((conv: any) => {
                return (
                  <ChatRow
                    setShowWhatsappChatBox={setShowWhatsappChatBox}
                    setShowWhatsappSidebar={setShowWhatsappSidebar}
                    conversation={conv}
                  />
                );
              })
          ) : conversations?.length < 1 && hasMoreConv ? (
            <>
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
              <ChatRowSekeleton />
            </>
          ) : (
            <div className="flex items-center justify-center gap-3 mt-12">
              <ErrorIcon color="error" />
              <p className="font-bold">No Conversations Found</p>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ConversationList;
