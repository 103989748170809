import React, { useEffect } from "react";
import { SimpleSelectProps } from "./types";

const SimpleSelect = ({
  register,
  errors,
  fieldName,
  defaultValue,
  setValue,
  label,
  classNames,
  options,
  required = false,
}: SimpleSelectProps) => {
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
    }
  }, [defaultValue]);
  // ;
  return (
    <div className={`w-full ${classNames?.container}`}>
      {label && (
        <label className="mb-2 block text-primary dark:text-dark-primary">
          {label}
        </label>
      )}
      <select
        {...register(fieldName, {
          required: required,
          defaultValue: defaultValue,
        })}
        id=""
        className={`border p-[5px] w-full bg-primary text-primary dark:text-dark-primary dark:bg-dark-primary border-gray-200 rounded ${
          classNames?.input
        } ${errors[fieldName] ? "border border-red-500" : ""}`}
        // defaultValue={defaultValue}
      >
        {options?.length > 0 &&
          options?.map((opt: any, i: number) => (
            <option
              key={i}
              value={opt.value}
              selected={opt.value === defaultValue}
            >
              {opt.title}
            </option>
          ))}
      </select>
      {errors[fieldName] && (
        <span
          role="alert"
          className={`text-red-500 m-1 text-[12px] normal-case`}
        >
          <sup>*</sup>required.
        </span>
      )}
    </div>
  );
};

export default SimpleSelect;
