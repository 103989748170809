import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import Consultantfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Consultantfilter";
import DateRangePickerCustom from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/DateRangePicker";

const Filter = ({
  showFilters,
  setShowFilters,
  setConversations,
  userRole,
  setSelectedConsultant,
  setErrorMessage,
  setOffset,
  dates,
  setDates,
  whatsappLabels,
  setFilterLabel,
}: any) => {
  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showFilters ? "left-0" : "left-[-800px]"
        }`}
      >
        <div className="px-[23px] text-white text-[19px] pt-[60px] bg-whatsappBasic">
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowFilters(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Conversations Filter</p>
          </div>
        </div>
        <div className="p-[20px] w-full bg-whatsappSecondaryBg dark:bg-dark-secondary">
          <Autocomplete
            sx={{ marginBottom: "10px" }}
            disablePortal
            onChange={(_, data: any) => {
              setConversations([]);
              setErrorMessage("");
              setOffset(0);
              setFilterLabel(data?.id);
            }}
            options={whatsappLabels ? whatsappLabels : []}
            getOptionLabel={(option: any) => option?.name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option?.name}
              </Box>
            )}
            renderInput={(params) => <TextField label="Labels" {...params} />}
            noOptionsText=""
          />
          {userRole === "admin" && (
            <Consultantfilter setSelectedConsultant={setSelectedConsultant} />
          )}
          <DateRangePickerCustom dates={dates} setDates={setDates} />
        </div>
        <div className="px-[20px] flex justify-center w-full">
          <Chip
            onClick={() => setShowFilters(false)}
            sx={{ minWidth: "150px" }}
            color="primary"
            label="Apply Filters"
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
