import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TableFooter } from "@mui/material";
import { useState } from "react";
import TableRowComponent from "./component/tableRow";

const TableComponent = ({
  errors,
  register,
  control,
  brdId,
  watch,
  getValues,
  setCredit,
  setDebit,
  debit,
  credit,
  setValue,
}: any) => {
  const [entries, setEntries] = useState([1, 2]);

  const handleAddRow = () => {
    const ent = [...entries, 1];
    setEntries([...ent]);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={{ bgcolor: "#9155FD" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>#</TableCell>
              <TableCell sx={{ color: "white" }}>ACCOUNT</TableCell>
              <TableCell sx={{ color: "white" }}>DEBIT (GBP)</TableCell>
              <TableCell sx={{ color: "white" }}>CREDIT (GBP)</TableCell>
              <TableCell sx={{ color: "white" }}>NOTE</TableCell>
              <TableCell sx={{ color: "white" }}>USER</TableCell>
              <TableCell sx={{ color: "white" }}>PAYMENT METHOD</TableCell>
              <TableCell sx={{ color: "white" }}>INQUIRY NO</TableCell>
              <TableCell sx={{ color: "white" }}>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((_, ind) => (
              <TableRowComponent
                ind={ind}
                errors={errors}
                register={register}
                control={control}
                brdId={brdId}
                watch={watch}
                setDebit={setDebit}
                setCredit={setCredit}
                getValues={getValues}
                debit={debit}
                entries={entries}
                setEntries={setEntries}
                setValue={setValue}
              />
            ))}
          </TableBody>
          <TableFooter sx={{ fontSize: "20px" }}>
            <TableCell colSpan={2} sx={{ border: 0 }} align="right">
              Total (GBP)
            </TableCell>
            <TableCell sx={{ border: 0 }} align="right">
              {debit}
            </TableCell>
            <TableCell sx={{ border: 0 }} align="right">
              {credit}
            </TableCell>
          </TableFooter>
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        sx={{ marginTop: "15px" }}
        onClick={handleAddRow}
      >
        ADD Row
      </Button>
    </>
  );
};

export default TableComponent;
