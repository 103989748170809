import { gql } from "@apollo/client";

export const UPDATE_POST = gql`
  mutation UpdatePost(
    $id: brd_posts_pk_columns_input!
    $data: brd_posts_set_input!
  ) {
    update_brd_posts_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;
