import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UPDATE_CAMPUS_COURSE_ADM_REQ_WITH_ID } from "~/graphql/course_adm_req/Mutation";

const EditAdmissionRequirement = ({ selectedRequirement, courseType, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const [editRequirement] = useMutation(UPDATE_CAMPUS_COURSE_ADM_REQ_WITH_ID);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    const res = await editRequirement({
      variables: {
        reqId: selectedRequirement.id,
        data: {
          gap_allowed: formData.gap_allowed,
          grade_type: formData.grade_type,
          grades: formData.grades,
          qualification: formData.qualification,
          scholarship_available: formData.scholarship_available,
          elt: formData.elt,
          moi: formData.moi
        },
      },
    });
    if (res?.data?.update_course_adm_req?.affected_rows > 0) {
      toast.success("Admission Requirement Updated Successfully");
      client.refetchQueries({
        include: "all",
      });
      setSidePopup(false);
    }
  };
  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Admission Requirement
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Qualification"
          variant="outlined"
          required
          multiline
          rows={3}
          fullWidth
          {...register("qualification")}
          defaultValue={selectedRequirement?.qualification}
          style={{ marginBottom: "16px" }}
        />
        <div className="flex gap-3">
          <FormControl variant="outlined" fullWidth required style={{ marginBottom: "16px" }}>
            <InputLabel id="grades-label">Grade Type</InputLabel>
            <Select
              labelId="grades-label"
              label="Grades"
              defaultValue={selectedRequirement?.grade_type}
              {...register("grade_type")}
            >
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="grade">Grades</MenuItem>
              <MenuItem value="CGPA">CGPA</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Grades"
            variant="outlined"
            fullWidth
            required
            defaultValue={selectedRequirement?.grades}
            {...register("grades")}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <TextField
          label="Gap Allowed"
          variant="outlined"
          fullWidth
          required
          {...register("gap_allowed")}
          defaultValue={selectedRequirement?.gap_allowed}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Scholarship Available"
          variant="outlined"
          required
          defaultValue={selectedRequirement?.scholarship_available}
          fullWidth
          {...register("scholarship_available")}
          style={{ marginBottom: "16px" }}
        />
        {courseType === "UG" &&
          <TextField
            label="ELT"
            variant="outlined"
            defaultValue={selectedRequirement?.elt}
            required
            fullWidth
            multiline
            rows={3}
            {...register("elt")}
            style={{ marginBottom: "16px" }}
          />
        }
        {courseType === "PG" &&
          <>
            <TextField
              label="ELT"
              variant="outlined"
              defaultValue={selectedRequirement?.elt}
              required
              fullWidth
              multiline
              rows={3}
              {...register("elt")}
              style={{ marginBottom: "16px" }}
            />
            <FormControl variant="outlined" fullWidth required style={{ marginBottom: "16px" }}>
              <InputLabel id="moi-label">MOI</InputLabel>
              <Select
                labelId="moi-label"
                defaultValue={selectedRequirement?.moi}
                label="MOI"
                {...register("moi")}
              >
                <MenuItem value="acceptable">Acceptable</MenuItem>
                <MenuItem value="not_acceptable">Not Acceptable</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditAdmissionRequirement;
