import React from "react";
import InputField from "~/components/FormInputs/InputField";

export default function PurchaseDetail({
  ticketPurchase,
  setTicketPurchase,
  register,
  errors,
  inquiry,
  ticekts,
}: any) {
  return (
    <div className="border border-basic rounded-[5px] mt-2">
      <h2
        className={` ${
          ticketPurchase
            ? "border-b border-basic dark:bg-dark-primary rounded-tl-[8px] rounded-tr-[8px]"
            : "rounded-[8px] dark:bg-dark-primary "
        } p-2 cursor-pointer bg-gray-100 `}
        onClick={() => {
          setTicketPurchase(!ticketPurchase);
        }}
      >
        <span>Purchased Detail</span>
      </h2>
      {ticketPurchase && (
        <div className="p-2">
          <InputField
            register={register}
            errors={errors}
            type="text"
            fieldName="ibe_no"
            required={true}
            label="Vendor Reference *"
            placeHolder="Vendor Reference"
            defaultValue={ticekts?.[0]?.inq_tickets[0]?.ibe}
            inputClasses="focus:outline-none focus:border-basic"
          />
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="ticket_cost_price"
            required={true}
            label="Purchase Cost *"
            placeHolder="Purchase Cost"
            defaultValue={
              inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
                (acc: any, item: any) => acc + item.tax_price,
                0
              ) +
              inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
                (acc: any, item: any) => acc + item.basic_fare,
                0
              )
            }
            readOnly={true}
            inputClasses="focus:outline-none focus:border-basic"
          />
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="ticket_attol_price"
            required={true}
            label="ATOL Charges"
            placeHolder="ATOL Charges *"
            defaultValue={inquiry?.selected_suggestion?.inq_suggestion_costs?.reduce?.(
              (acc: any, item: any) => acc + item.atol_price,
              0
            )}
            readOnly={true}
            inputClasses="focus:outline-none focus:border-basic"
          />
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="ticket_mis_cost"
            required={true}
            label="Miscellaneous Cost"
            placeHolder="Miscellaneous Cost *"
            defaultValue={ticekts?.[0]?.inq_tickets[0]?.ticket_mis_cost}
            inputClasses="focus:outline-none focus:border-basic"
          />
        </div>
      )}
    </div>
  );
}
