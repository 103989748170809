import { gql } from "@apollo/client";

export const GET_THP_BRANDS = gql`
  query GetThpBrands($id: uuid) {
    brd_list(where: { thp_id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const GET_FLIGHT_PAGE = gql`
  query GetFlightPages($id: uuid) {
    brd_flights_pages(where: { brd_id: { _eq: $id } }) {
      airline_iata
      base_price
      canonical_url
      created_at
      description
      destination_iata
      destination_name
      featured_image
      id
      origin_iata
      origin_name
      page_content
      show_in_footer
      show_in_home
      button_text
      footer_text
      title
      updated_at
      show_in_home_gallery
      blog_heading
      blog_sub_heading
      contact_heading
      contact_sub_heading
      tips_heading
      tips_sub_heading
    }
  }
`;
