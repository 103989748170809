import { gql } from "@apollo/client";

export const ADD_BRD_SEASONS = gql`
  mutation addDefSeasFares($data: [brd_seasons_insert_input!]!) {
    insert_brd_seasons(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_SEASONS = gql`
  mutation addDefSeasFares($id: uuid!) {
    delete_brd_seasons(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_SEASONS = gql`
  mutation updateBrdSeasons($data: brd_seasons_set_input!, $id: uuid!) {
    update_brd_seasons(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
