const airportData = [
  {
    time_zone: "Europe/Berlin",
    name: "Aachen Merzbrück Airfield",
    longitude: 6.186722,
    latitude: 50.823345,
    id: "arp_aah_de",
    icao_code: "EDKA",
    iata_country_code: "DE",
    iata_code: "AAH",
    city_name: "Aachen",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Aalborg Airport",
    longitude: 9.852853,
    latitude: 57.094838,
    id: "arp_aal_dk",
    icao_code: "EKYT",
    iata_country_code: "DK",
    iata_code: "AAL",
    city_name: "Aalborg",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Aarhus Airport",
    longitude: 10.624079,
    latitude: 56.307566,
    id: "arp_aar_dk",
    icao_code: "EKAH",
    iata_country_code: "DK",
    iata_code: "AAR",
    city_name: "Aarhus",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Aasiaat Airport",
    longitude: -52.785546,
    latitude: 68.721719,
    id: "arp_jeg_gl",
    icao_code: "BGAA",
    iata_country_code: "GL",
    iata_code: "JEG",
    city_name: "Aasiaat",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Abadan Airport",
    longitude: 48.227605,
    latitude: 30.367608,
    id: "arp_abd_ir",
    icao_code: "OIAA",
    iata_country_code: "IR",
    iata_code: "ABD",
    city_name: "Abadan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Aba Hongyuan Airport",
    longitude: 102.35379,
    latitude: 32.530255,
    id: "arp_ahj_cn",
    icao_code: "ZUHY",
    iata_country_code: "CN",
    iata_code: "AHJ",
    city_name: "Aba",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Abaiang Airport",
    longitude: 173.041,
    latitude: 1.798609,
    id: "arp_abf_ki",
    icao_code: "NGAB",
    iata_country_code: "KI",
    iata_code: "ABF",
    city_name: "Abaiang",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Abakan Airport",
    longitude: 91.399502,
    latitude: 53.75095,
    id: "arp_aba_ru",
    icao_code: "UNAA",
    iata_country_code: "RU",
    iata_code: "ABA",
    city_name: "Abakan",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Aba Tenna Dejazmach Yilma International Airport",
    longitude: 41.854186,
    latitude: 9.624085,
    id: "arp_dir_et",
    icao_code: "HADR",
    iata_country_code: "ET",
    iata_code: "DIR",
    city_name: "Dire Dawa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Abau Airport",
    longitude: 148.691,
    latitude: -10.1617,
    id: "arp_abw_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ABW",
    city_name: "Abau",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Abbaye Airport",
    longitude: -14.200001,
    latitude: 16.633314,
    id: "arp_bgh_mr",
    icao_code: "GQNE",
    iata_country_code: "MR",
    iata_code: "BGH",
    city_name: "Bogue",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Abbotsford International Airport",
    longitude: -122.367777,
    latitude: 49.024209,
    id: "arp_yxx_ca",
    icao_code: "CYXX",
    iata_country_code: "CA",
    iata_code: "YXX",
    city_name: "Abbotsford",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Abbse Airport",
    longitude: 43.175057,
    latitude: 16.011094,
    id: "arp_eab_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "EAB",
    city_name: "Abbs",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Abdelhafid Boussouf Bou Chekif Airport",
    longitude: 1.463628,
    latitude: 35.340761,
    id: "arp_tid_dz",
    icao_code: "DAOB",
    iata_country_code: "DZ",
    iata_code: "TID",
    city_name: "Tiaret",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Abdul Rachman Saleh Airport",
    longitude: 112.71332,
    latitude: -7.930832,
    id: "arp_mlg_id",
    icao_code: "WARA",
    iata_country_code: "ID",
    iata_code: "MLG",
    city_name: "Malang",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Abeche Airport",
    longitude: 20.84618,
    latitude: 13.847093,
    id: "arp_aeh_td",
    icao_code: "FTTC",
    iata_country_code: "TD",
    iata_code: "AEH",
    city_name: "Abeche",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Abeid Amani Karume International Airport",
    longitude: 39.222999,
    latitude: -6.220056,
    id: "arp_znz_tz",
    icao_code: "HTZA",
    iata_country_code: "TZ",
    iata_code: "ZNZ",
    city_name: "Zanzibar",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Abel Santamaria Airport",
    longitude: -79.943793,
    latitude: 22.492177,
    id: "arp_snu_cu",
    icao_code: "MUSC",
    iata_country_code: "CU",
    iata_code: "SNU",
    city_name: "Santa Clara",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Abemama Atoll Airport",
    longitude: 173.830027,
    latitude: 0.490786,
    id: "arp_aea_ki",
    icao_code: "NGTB",
    iata_country_code: "KI",
    iata_code: "AEA",
    city_name: "Abemama Atoll",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Abengourou Airport",
    longitude: -3.470309,
    latitude: 6.715113,
    id: "arp_ogo_ci",
    icao_code: "DIAU",
    iata_country_code: "CI",
    iata_code: "OGO",
    city_name: "Abengourou",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Aberdeen International Airport",
    longitude: -2.198971,
    latitude: 57.202799,
    id: "arp_abz_gb",
    icao_code: "EGPD",
    iata_country_code: "GB",
    iata_code: "ABZ",
    city_name: "Aberdeen",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Aberdeen Regional Airport",
    longitude: -98.419762,
    latitude: 45.451279,
    id: "arp_abr_us",
    icao_code: "KABR",
    iata_country_code: "US",
    iata_code: "ABR",
    city_name: "Aberdeen",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Abha Regional Airport",
    longitude: 42.657127,
    latitude: 18.237476,
    id: "arp_ahb_sa",
    icao_code: "OEAB",
    iata_country_code: "SA",
    iata_code: "AHB",
    city_name: "Abha",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Abilene Regional Airport",
    longitude: -99.681822,
    latitude: 32.411237,
    id: "arp_abi_us",
    icao_code: "KABI",
    iata_country_code: "US",
    iata_code: "ABI",
    city_name: "Abilene",
    city: {
      name: "Abilene",
      id: "cit_abi_us",
      iata_country_code: "US",
      iata_code: "ABI",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Abingdon Downs Airport",
    longitude: 143.167007,
    latitude: -17.616699,
    id: "arp_abg_au",
    icao_code: "YABI",
    iata_country_code: "AU",
    iata_code: "ABG",
    city_name: "Abingdon Downs",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Aboisso Airport",
    longitude: -3.2,
    latitude: 5.46667,
    id: "arp_abo_ci",
    icao_code: "DIAO",
    iata_country_code: "CI",
    iata_code: "ABO",
    city_name: "Aboisso",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Abou-Deïa Airport",
    longitude: 19.283333,
    latitude: 11.466667,
    id: "arp_aod_td",
    icao_code: null,
    iata_country_code: "TD",
    iata_code: "AOD",
    city_name: "Abou-Deïa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Abraham Lincoln Capital Airport",
    longitude: -89.677919,
    latitude: 39.8439,
    id: "arp_spi_us",
    icao_code: "KSPI",
    iata_country_code: "US",
    iata_code: "SPI",
    city_name: "Springfield",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Abresso Airport",
    longitude: 9.199833,
    latitude: 45.535978,
    id: "arp_rsk_id",
    icao_code: "WASC",
    iata_country_code: "ID",
    iata_code: "RSK",
    city_name: "Ransiki",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Abruzzo Airport",
    longitude: 14.183883,
    latitude: 42.431276,
    id: "arp_psr_it",
    icao_code: "LIBP",
    iata_country_code: "IT",
    iata_code: "PSR",
    city_name: "Pescara",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Abu Dhabi International Airport",
    longitude: 54.650586,
    latitude: 24.437469,
    id: "arp_auh_ae",
    icao_code: "OMAA",
    iata_country_code: "AE",
    iata_code: "AUH",
    city_name: "Abu Dhabi",
    city: {
      name: "Abu Dhabi",
      id: "cit_auh_ae",
      iata_country_code: "AE",
      iata_code: "AUH",
    },
  },
  {
    time_zone: "Asia/Tehran",
    name: "Abu Musa Island Airport",
    longitude: 55.031069,
    latitude: 25.876502,
    id: "arp_aeu_ir",
    icao_code: "OIBA",
    iata_country_code: "IR",
    iata_code: "AEU",
    city_name: "Abu Musa Island",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Abu Rudeis Airport",
    longitude: 33.198663,
    latitude: 28.901826,
    id: "arp_aue_eg",
    icao_code: null,
    iata_country_code: "EG",
    iata_code: "AUE",
    city_name: "Abu Rudeis",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Abu Simbel Airport",
    longitude: 31.611245,
    latitude: 22.373083,
    id: "arp_abs_eg",
    icao_code: "HEBL",
    iata_country_code: "EG",
    iata_code: "ABS",
    city_name: "Abu Simbel",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Acadiana Regional Airport",
    longitude: -91.883189,
    latitude: 30.037854,
    id: "arp_ara_us",
    icao_code: "KARA",
    iata_country_code: "US",
    iata_code: "ARA",
    city_name: "New Iberia",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Acaricuara Airport",
    longitude: -70.1333,
    latitude: 0.53333,
    id: "arp_arf_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ARF",
    city_name: "Acaricuara",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Accomack County Airport",
    longitude: -75.761117,
    latitude: 37.644864,
    id: "arp_mfv_us",
    icao_code: "KMFV",
    iata_country_code: "US",
    iata_code: "MFV",
    city_name: "Melfa",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Achinsk Airport",
    longitude: 90.570617,
    latitude: 56.269088,
    id: "arp_acs_ru",
    icao_code: "UNKS",
    iata_country_code: "RU",
    iata_code: "ACS",
    city_name: "Achinsk",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Achutupo Airport",
    longitude: -77.994073,
    latitude: 9.188728,
    id: "arp_acu_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "ACU",
    city_name: "Achutupo",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "A Coruña Airport",
    longitude: -8.378186,
    latitude: 43.301777,
    id: "arp_lcg_es",
    icao_code: "LECO",
    iata_country_code: "ES",
    iata_code: "LCG",
    city_name: "A Coruña",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Adado Airport",
    longitude: 46.637562,
    latitude: 6.096533,
    id: "arp_aad_so",
    icao_code: null,
    iata_country_code: "SO",
    iata_code: "AAD",
    city_name: "Adado",
    city: null,
  },
  {
    time_zone: "America/Adak",
    name: "Adak Airport",
    longitude: -176.645996,
    latitude: 51.877998,
    id: "arp_adk_us",
    icao_code: "PADK",
    iata_country_code: "US",
    iata_code: "ADK",
    city_name: "Adak Island",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Adam Airport",
    longitude: 57.42781,
    latitude: 22.492155,
    id: "arp_aom_om",
    icao_code: null,
    iata_country_code: "OM",
    iata_code: "AOM",
    city_name: "Adam",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Adampur Airport",
    longitude: 75.765892,
    latitude: 31.424348,
    id: "arp_aip_in",
    icao_code: "VIAX",
    iata_country_code: "IN",
    iata_code: "AIP",
    city_name: "Adampur",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ada Municipal Airport",
    longitude: -96.670995,
    latitude: 34.803321,
    id: "arp_adt_us",
    icao_code: "KADH",
    iata_country_code: "US",
    iata_code: "ADT",
    city_name: "Ada",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Adana Şakirpaşa Airport",
    longitude: 35.286266,
    latitude: 36.983457,
    id: "arp_ada_tr",
    icao_code: "LTAF",
    iata_country_code: "TR",
    iata_code: "ADA",
    city_name: "Adana",
    city: null,
  },
  {
    time_zone: "Africa/Juba",
    name: "Adareil Airport",
    longitude: 32.959623,
    latitude: 10.053341,
    id: "arp_aee_ss",
    icao_code: null,
    iata_country_code: "SS",
    iata_code: "AEE",
    city_name: "Adareil",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Addis Ababa Bole International Airport",
    longitude: 38.798719,
    latitude: 8.979022,
    id: "arp_add_et",
    icao_code: "HAAB",
    iata_country_code: "ET",
    iata_code: "ADD",
    city_name: "Addis Ababa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Addison Airport",
    longitude: -96.836434,
    latitude: 32.969286,
    id: "arp_ads_us",
    icao_code: "KADS",
    iata_country_code: "US",
    iata_code: "ADS",
    city_name: "Dallas",
    city: {
      name: "Dallas",
      id: "cit_dfw_us",
      iata_country_code: "US",
      iata_code: "DFW",
    },
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Adelaide Airport",
    longitude: 138.532122,
    latitude: -34.945578,
    id: "arp_adl_au",
    icao_code: "YPAD",
    iata_country_code: "AU",
    iata_code: "ADL",
    city_name: "Adelaide",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Aden Adde International Airport",
    longitude: 45.304832,
    latitude: 2.015817,
    id: "arp_mgq_so",
    icao_code: "HCMM",
    iata_country_code: "SO",
    iata_code: "MGQ",
    city_name: "Mogadishu",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Aden International Airport",
    longitude: 45.037201,
    latitude: 12.825875,
    id: "arp_ade_ye",
    icao_code: "OYAA",
    iata_country_code: "YE",
    iata_code: "ADE",
    city_name: "Aden",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Adirondack Regional Airport",
    longitude: -74.20532,
    latitude: 44.384525,
    id: "arp_slk_us",
    icao_code: "KSLK",
    iata_country_code: "US",
    iata_code: "SLK",
    city_name: "Saranac Lake",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Adisumarmo International Airport",
    longitude: 110.754856,
    latitude: -7.515231,
    id: "arp_soc_id",
    icao_code: "WARQ",
    iata_country_code: "ID",
    iata_code: "SOC",
    city_name: "Surakarta",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Adıyaman Airport",
    longitude: 38.470447,
    latitude: 37.732834,
    id: "arp_adf_tr",
    icao_code: "LTCP",
    iata_country_code: "TR",
    iata_code: "ADF",
    city_name: "Adıyaman",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Adnan Menderes Airport",
    longitude: 27.154514,
    latitude: 38.293081,
    id: "arp_adb_tr",
    icao_code: "LTBJ",
    iata_country_code: "TR",
    iata_code: "ADB",
    city_name: "İzmir",
    city: {
      name: "Izmir",
      id: "cit_izm_tr",
      iata_country_code: "TR",
      iata_code: "IZM",
    },
  },
  {
    time_zone: "Europe/Madrid",
    name: "Adolfo Suárez Madrid-Barajas Airport",
    longitude: -3.567169,
    latitude: 40.49923,
    id: "arp_mad_es",
    icao_code: "LEMD",
    iata_country_code: "ES",
    iata_code: "MAD",
    city_name: "Madrid",
    city: {
      name: "Madrid",
      id: "cit_mad_es",
      iata_country_code: "ES",
      iata_code: "MAD",
    },
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Aek Godang Airport",
    longitude: 99.430648,
    latitude: 1.4,
    id: "arp_aeg_id",
    icao_code: "WIME",
    iata_country_code: "ID",
    iata_code: "AEG",
    city_name: "Aek Godang",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Afonso Pena International Airport",
    longitude: -49.17414,
    latitude: -25.530606,
    id: "arp_cwb_br",
    icao_code: "SBCT",
    iata_country_code: "BR",
    iata_code: "CWB",
    city_name: "Curitiba",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Afore Airport",
    longitude: 148.383719,
    latitude: -9.133021,
    id: "arp_afr_pg",
    icao_code: "AYAF",
    iata_country_code: "PG",
    iata_code: "AFR",
    city_name: "Afore",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Afton Municipal Airport",
    longitude: -110.940863,
    latitude: 42.712406,
    id: "arp_afo_us",
    icao_code: "KAFO",
    iata_country_code: "US",
    iata_code: "AFO",
    city_name: "Afton",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Afutara Aerodrome",
    longitude: 160.948611,
    latitude: -9.191388,
    id: "arp_aft_sb",
    icao_code: "AGAF",
    iata_country_code: "SB",
    iata_code: "AFT",
    city_name: "Afutara",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Afyon Airport",
    longitude: 30.6011,
    latitude: 38.726398,
    id: "arp_afy_tr",
    icao_code: "LTAH",
    iata_country_code: "TR",
    iata_code: "AFY",
    city_name: "Afyon",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Agadir – Al Massira Airport",
    longitude: -9.411614,
    latitude: 30.327806,
    id: "arp_aga_ma",
    icao_code: "GMAD",
    iata_country_code: "MA",
    iata_code: "AGA",
    city_name: "Agadir",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Agartala Airport",
    longitude: 91.242226,
    latitude: 23.88987,
    id: "arp_ixa_in",
    icao_code: "VEAT",
    iata_country_code: "IN",
    iata_code: "IXA",
    city_name: "Agartala",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Agatti Airport",
    longitude: 72.176839,
    latitude: 10.824963,
    id: "arp_agx_in",
    icao_code: "VOAT",
    iata_country_code: "IN",
    iata_code: "AGX",
    city_name: "Agatti Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Agaun Airport",
    longitude: 149.387745,
    latitude: -9.928599,
    id: "arp_aup_pg",
    icao_code: "AYAG",
    iata_country_code: "PG",
    iata_code: "AUP",
    city_name: "Agaun",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Agen La Garenne Airport",
    longitude: 0.59355,
    latitude: 44.174811,
    id: "arp_agf_fr",
    icao_code: "LFBA",
    iata_country_code: "FR",
    iata_code: "AGF",
    city_name: "Agen",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Aggeneys Airport",
    longitude: 18.814436,
    latitude: -29.280287,
    id: "arp_agz_za",
    icao_code: "FAAG",
    iata_country_code: "ZA",
    iata_code: "AGZ",
    city_name: "Aggeneys",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Aghajari Airport",
    longitude: 49.676282,
    latitude: 30.74497,
    id: "arp_akw_ir",
    icao_code: "OIAG",
    iata_country_code: "IR",
    iata_code: "AKW",
    city_name: "Aghajari",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Agnew Airport",
    longitude: 142.149318,
    latitude: -12.14565,
    id: "arp_agw_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "AGW",
    city_name: "Agnew",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Agostinho Neto Airport",
    longitude: -25.090589,
    latitude: 17.2028,
    id: "arp_nto_cv",
    icao_code: "GVAN",
    iata_country_code: "CV",
    iata_code: "NTO",
    city_name: "Ponta do Sol",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Agra Airport",
    longitude: 77.960784,
    latitude: 27.155886,
    id: "arp_agr_in",
    icao_code: "VIAG",
    iata_country_code: "IN",
    iata_code: "AGR",
    city_name: "Agra",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Ağrı Airport",
    longitude: 43.024649,
    latitude: 39.654845,
    id: "arp_aji_tr",
    icao_code: "LTCO",
    iata_country_code: "TR",
    iata_code: "AJI",
    city_name: "Agri",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Agrinio Airport",
    longitude: 21.351387,
    latitude: 38.602207,
    id: "arp_agq_gr",
    icao_code: "LGAG",
    iata_country_code: "GR",
    iata_code: "AGQ",
    city_name: "Agrinion",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Agropecuária Castanhais Airport",
    longitude: -51.174168,
    latitude: -8.7,
    id: "arp_iti_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "ITI",
    city_name: "Itambacuri",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Aguaclara Airport",
    longitude: -73.002778,
    latitude: 4.753611,
    id: "arp_acl_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ACL",
    city_name: "Aguaclara",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Aguadilla Rafael Hernandez Airport",
    longitude: -67.13249,
    latitude: 18.495148,
    id: "arp_bqn_pr",
    icao_code: "TJBQ",
    iata_country_code: "PR",
    iata_code: "BQN",
    city_name: "Aguadilla",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Aguascalientes International Airport",
    longitude: -102.317022,
    latitude: 21.702446,
    id: "arp_agu_mx",
    icao_code: "MMAS",
    iata_country_code: "MX",
    iata_code: "AGU",
    city_name: "Aguascalientes",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Aguas Claras Airport",
    longitude: -73.357942,
    latitude: 8.315046,
    id: "arp_ocv_co",
    icao_code: "SKOC",
    iata_country_code: "CO",
    iata_code: "OCV",
    city_name: "Ocaña",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Aguenar - Hadj Bey Akhamok Airport",
    longitude: 5.450956,
    latitude: 22.811305,
    id: "arp_tmr_dz",
    icao_code: "DAAT",
    iata_country_code: "DZ",
    iata_code: "TMR",
    city_name: "Tamanrasset",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Aguni Airport",
    longitude: 127.24013,
    latitude: 26.592664,
    id: "arp_agj_jp",
    icao_code: "RORA",
    iata_country_code: "JP",
    iata_code: "AGJ",
    city_name: "Aguni",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Ahe Airport",
    longitude: -146.256631,
    latitude: -14.427824,
    id: "arp_ahe_pf",
    icao_code: "NTHE",
    iata_country_code: "PF",
    iata_code: "AHE",
    city_name: "Ahe",
    city: null,
  },
  {
    time_zone: "Asia/Kuwait",
    name: "Ahmed Al Jaber Air Base",
    longitude: 47.791901,
    latitude: 28.934799,
    id: "arp_xij_kw",
    icao_code: "OKAJ",
    iata_country_code: "KW",
    iata_code: "XIJ",
    city_name: "Ahmed Al Jaber AB",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Ahuas Airport",
    longitude: -84.541913,
    latitude: 15.761554,
    id: "arp_ahs_hn",
    icao_code: "MHAH",
    iata_country_code: "HN",
    iata_code: "AHS",
    city_name: "Ahuas",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Ahwaz Airport",
    longitude: 48.746267,
    latitude: 31.341581,
    id: "arp_awz_ir",
    icao_code: "OIAW",
    iata_country_code: "IR",
    iata_code: "AWZ",
    city_name: "Ahwaz",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Ai-Ais Airport",
    longitude: 17.596082,
    latitude: -27.995442,
    id: "arp_aiw_na",
    icao_code: null,
    iata_country_code: "NA",
    iata_code: "AIW",
    city_name: "Ai-Ais",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aiambak Airport",
    longitude: 141.267371,
    latitude: -7.343129,
    id: "arp_aih_pg",
    icao_code: "AYAK",
    iata_country_code: "PG",
    iata_code: "AIH",
    city_name: "Aiambak",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Aiken Regional Airport",
    longitude: -81.681462,
    latitude: 33.649429,
    id: "arp_aik_us",
    icao_code: "KAIK",
    iata_country_code: "US",
    iata_code: "AIK",
    city_name: "Aiken",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Ailigandí Airport",
    longitude: -78.023289,
    latitude: 9.222328,
    id: "arp_ail_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "AIL",
    city_name: "Ailigandi",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Ailinglaplap Airok Airport",
    longitude: 168.825405,
    latitude: 7.278975,
    id: "arp_aic_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "AIC",
    city_name: "Airok",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Ailuk Airport",
    longitude: 169.982347,
    latitude: 10.216293,
    id: "arp_aim_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "AIM",
    city_name: "Ailuk Island",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Ain Arnat Airport",
    longitude: 5.327391,
    latitude: 36.176826,
    id: "arp_qsf_dz",
    icao_code: "DAAS",
    iata_country_code: "DZ",
    iata_code: "QSF",
    city_name: "Sétif",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Ain el Beida Airport",
    longitude: 5.41278,
    latitude: 31.9172,
    id: "arp_ogx_dz",
    icao_code: "DAUU",
    iata_country_code: "DZ",
    iata_code: "OGX",
    city_name: "Ouargla",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ainsworth Regional Airport",
    longitude: -99.997975,
    latitude: 42.577185,
    id: "arp_anw_us",
    icao_code: "KANW",
    iata_country_code: "US",
    iata_code: "ANW",
    city_name: "Ainsworth",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aiome Airport",
    longitude: 144.731612,
    latitude: -5.142607,
    id: "arp_aie_pg",
    icao_code: "AYAO",
    iata_country_code: "PG",
    iata_code: "AIE",
    city_name: "Aiome",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Aioun el Atrouss Airport",
    longitude: -9.637573,
    latitude: 16.712056,
    id: "arp_aeo_mr",
    icao_code: "GQNA",
    iata_country_code: "MR",
    iata_code: "AEO",
    city_name: "Aioun El Atrouss",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Air Force Base No. 5 Airport",
    longitude: -8.8875,
    latitude: 39.828335,
    id: "arp_qlr_pt",
    icao_code: "LPMR",
    iata_country_code: "PT",
    iata_code: "QLR",
    city_name: "Leiria",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Aishalton Airport",
    longitude: -59.322245,
    latitude: 2.472585,
    id: "arp_ahl_gy",
    icao_code: "SYAH",
    iata_country_code: "GY",
    iata_code: "AHL",
    city_name: "Aishalton",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aitape Airport",
    longitude: 142.346516,
    latitude: -3.143579,
    id: "arp_atp_pg",
    icao_code: "AYAI",
    iata_country_code: "PG",
    iata_code: "ATP",
    city_name: "Aitape",
    city: {
      name: "Aitape",
      id: "cit_atp_pg",
      iata_country_code: "PG",
      iata_code: "ATP",
    },
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Aitutaki Airport",
    longitude: -159.765361,
    latitude: -18.830215,
    id: "arp_ait_ck",
    icao_code: "NCAI",
    iata_country_code: "CK",
    iata_code: "AIT",
    city_name: "Aitutaki",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aiyura Airport",
    longitude: 145.904741,
    latitude: -6.339403,
    id: "arp_ayu_pg",
    icao_code: "AYAY",
    iata_country_code: "PG",
    iata_code: "AYU",
    city_name: "Aiyura",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Ajaccio Napoleon Bonaparte Airport",
    longitude: 8.798781,
    latitude: 41.921966,
    id: "arp_aja_fr",
    icao_code: "LFKJ",
    iata_country_code: "FR",
    iata_code: "AJA",
    city_name: "Ajaccio",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "A.J. Eisenberg Airport",
    longitude: -122.67502,
    latitude: 48.251239,
    id: "arp_odw_us",
    icao_code: "KOKH",
    iata_country_code: "US",
    iata_code: "ODW",
    city_name: "Oak Harbor",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Akanu Ibiam International Airport",
    longitude: 7.564842,
    latitude: 6.473126,
    id: "arp_enu_ng",
    icao_code: "DNEN",
    iata_country_code: "NG",
    iata_code: "ENU",
    city_name: "Enugu",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Akhiok Airport",
    longitude: -154.182777,
    latitude: 56.938695,
    id: "arp_akk_us",
    icao_code: "PAKH",
    iata_country_code: "US",
    iata_code: "AKK",
    city_name: "Akhiok",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Akiachak Airport",
    longitude: -161.425832,
    latitude: 60.905759,
    id: "arp_kki_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KKI",
    city_name: "Akiachak",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Akiak Airport",
    longitude: -161.231073,
    latitude: 60.902758,
    id: "arp_aki_us",
    icao_code: "PFAK",
    iata_country_code: "US",
    iata_code: "AKI",
    city_name: "Akiak",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Akieni Airport",
    longitude: 13.903466,
    latitude: -1.139696,
    id: "arp_ake_ga",
    icao_code: "FOGA",
    iata_country_code: "GA",
    iata_code: "AKE",
    city_name: "Akieni",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Akita Airport",
    longitude: 140.218864,
    latitude: 39.614474,
    id: "arp_axt_jp",
    icao_code: "RJSK",
    iata_country_code: "JP",
    iata_code: "AXT",
    city_name: "Akita",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Akjoujt Airport",
    longitude: -14.378679,
    latitude: 19.728303,
    id: "arp_ajj_mr",
    icao_code: "GQNJ",
    iata_country_code: "MR",
    iata_code: "AJJ",
    city_name: "Akjoujt",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Aklavik/Freddie Carmichael Airport",
    longitude: -135.005973,
    latitude: 68.223214,
    id: "arp_lak_ca",
    icao_code: "CYKD",
    iata_country_code: "CA",
    iata_code: "LAK",
    city_name: "Aklavik",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Akola Airport",
    longitude: 77.058814,
    latitude: 20.697524,
    id: "arp_akd_in",
    icao_code: "VAAK",
    iata_country_code: "IN",
    iata_code: "AKD",
    city_name: "Akola",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Akron–Canton Airport",
    longitude: -81.440739,
    latitude: 40.915548,
    id: "arp_cak_us",
    icao_code: "KCAK",
    iata_country_code: "US",
    iata_code: "CAK",
    city_name: "Akron",
    city: {
      name: "Akron/Canton",
      id: "cit_cak_us",
      iata_country_code: "US",
      iata_code: "CAK",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Akron Fulton International Airport",
    longitude: -81.467633,
    latitude: 41.035888,
    id: "arp_akc_us",
    icao_code: "KAKR",
    iata_country_code: "US",
    iata_code: "AKC",
    city_name: "Akron",
    city: {
      name: "Akron/Canton",
      id: "cit_cak_us",
      iata_country_code: "US",
      iata_code: "CAK",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Aksu Airport",
    longitude: 80.291922,
    latitude: 41.261871,
    id: "arp_aku_cn",
    icao_code: "ZWAK",
    iata_country_code: "CN",
    iata_code: "AKU",
    city_name: "Aksu",
    city: null,
  },
  {
    time_zone: "Asia/Aqtau",
    name: "Aktau Airport",
    longitude: 51.089813,
    latitude: 43.858315,
    id: "arp_sco_kz",
    icao_code: "UATE",
    iata_country_code: "KZ",
    iata_code: "SCO",
    city_name: "Aktau",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Aktion National Airport",
    longitude: 20.765756,
    latitude: 38.925703,
    id: "arp_pvk_gr",
    icao_code: "LGPZ",
    iata_country_code: "GR",
    iata_code: "PVK",
    city_name: "Preveza",
    city: null,
  },
  {
    time_zone: "Asia/Aqtobe",
    name: "Aktobe Airport",
    longitude: 57.208112,
    latitude: 50.246914,
    id: "arp_akx_kz",
    icao_code: "UATT",
    iata_country_code: "KZ",
    iata_code: "AKX",
    city_name: "Aktobe",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Akulivik Airport",
    longitude: -78.150566,
    latitude: 60.818473,
    id: "arp_akv_ca",
    icao_code: "CYKO",
    iata_country_code: "CA",
    iata_code: "AKV",
    city_name: "Akulivik",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Akure Airport",
    longitude: 5.300582,
    latitude: 7.248162,
    id: "arp_akr_ng",
    icao_code: "DNAK",
    iata_country_code: "NG",
    iata_code: "AKR",
    city_name: "Akure",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Akureyri Airport",
    longitude: -18.073304,
    latitude: 65.658308,
    id: "arp_aey_is",
    icao_code: "BIAR",
    iata_country_code: "IS",
    iata_code: "AEY",
    city_name: "Akureyri",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Akutan Seaplane Base",
    longitude: -165.778807,
    latitude: 54.13387,
    id: "arp_kqa_us",
    icao_code: "PAUT",
    iata_country_code: "US",
    iata_code: "KQA",
    city_name: "Akutan",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Akwa Ibom International Airport",
    longitude: 8.093215,
    latitude: 4.8731,
    id: "arp_quo_ng",
    icao_code: "DNAI",
    iata_country_code: "NG",
    iata_code: "QUO",
    city_name: "Uyo",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Al Abraq International Airport",
    longitude: 21.96525,
    latitude: 32.791035,
    id: "arp_laq_ly",
    icao_code: "HLLQ",
    iata_country_code: "LY",
    iata_code: "LAQ",
    city_name: "Beida",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Al-Ahsa International Airport",
    longitude: 49.485621,
    latitude: 25.288535,
    id: "arp_hof_sa",
    icao_code: "OEAH",
    iata_country_code: "SA",
    iata_code: "HOF",
    city_name: "Hofuf",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Al Ain International Airport",
    longitude: 55.614771,
    latitude: 24.25964,
    id: "arp_aan_ae",
    icao_code: "OMAL",
    iata_country_code: "AE",
    iata_code: "AAN",
    city_name: "Al Ain",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Alakanuk Airport",
    longitude: -164.659963,
    latitude: 62.680021,
    id: "arp_auk_us",
    icao_code: "PAUK",
    iata_country_code: "US",
    iata_code: "AUK",
    city_name: "Alakanuk",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Alamogordo White Sands Regional Airport",
    longitude: -105.9919,
    latitude: 32.840108,
    id: "arp_alm_us",
    icao_code: "KALM",
    iata_country_code: "US",
    iata_code: "ALM",
    city_name: "Alamogordo",
    city: {
      name: "Alamogordo",
      id: "cit_alm_us",
      iata_country_code: "US",
      iata_code: "ALM",
    },
  },
  {
    time_zone: "America/Hermosillo",
    name: "Álamos Airport",
    longitude: -108.947399,
    latitude: 27.036724,
    id: "arp_xal_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "XAL",
    city_name: "Álamos",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Al Asad Airbase Airport",
    longitude: 42.4412,
    latitude: 33.785599,
    id: "arp_iqa_iq",
    icao_code: "ORAA",
    iata_country_code: "IQ",
    iata_code: "IQA",
    city_name: "Al Asad",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Albacete Airport",
    longitude: -1.863229,
    latitude: 38.949313,
    id: "arp_abc_es",
    icao_code: "LEAB",
    iata_country_code: "ES",
    iata_code: "ABC",
    city_name: "Albacete",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Al-Baha Domestic Airport",
    longitude: 41.635733,
    latitude: 20.29742,
    id: "arp_abt_sa",
    icao_code: "OEBA",
    iata_country_code: "SA",
    iata_code: "ABT",
    city_name: "Al Baha",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Albano Machado Airport",
    longitude: 15.749861,
    latitude: -12.803306,
    id: "arp_nov_ao",
    icao_code: "FNHU",
    iata_country_code: "AO",
    iata_code: "NOV",
    city_name: "Huambo",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Albany Airport",
    longitude: 117.806392,
    latitude: -34.944183,
    id: "arp_alh_au",
    icao_code: "YABA",
    iata_country_code: "AU",
    iata_code: "ALH",
    city_name: "Albany",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Albany International Airport",
    longitude: -73.803597,
    latitude: 42.748505,
    id: "arp_alb_us",
    icao_code: "KALB",
    iata_country_code: "US",
    iata_code: "ALB",
    city_name: "Albany",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Al Bayda Airport",
    longitude: 45.440507,
    latitude: 14.105087,
    id: "arp_byd_ye",
    icao_code: "OYBI",
    iata_country_code: "YE",
    iata_code: "BYD",
    city_name: "Al Bayda",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Alberni Valley Regional Airport",
    longitude: -124.930258,
    latitude: 49.320792,
    id: "arp_ypb_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YPB",
    city_name: "Port Alberni",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Albert J. Ellis Airport",
    longitude: -77.610497,
    latitude: 34.829271,
    id: "arp_oaj_us",
    icao_code: "KOAJ",
    iata_country_code: "US",
    iata_code: "OAJ",
    city_name: "Jacksonville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Albert Lea Municipal Airport",
    longitude: -93.367214,
    latitude: 43.681444,
    id: "arp_ael_us",
    icao_code: "KAEL",
    iata_country_code: "US",
    iata_code: "AEL",
    city_name: "Albert Lea",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Alberto Carnevalli Airport",
    longitude: -71.161444,
    latitude: 8.582406,
    id: "arp_mrd_ve",
    icao_code: "SVMD",
    iata_country_code: "VE",
    iata_code: "MRD",
    city_name: "Mérida",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Alberto Delgado Airport",
    longitude: -79.997207,
    latitude: 21.78831,
    id: "arp_tnd_cu",
    icao_code: "MUTD",
    iata_country_code: "CU",
    iata_code: "TND",
    city_name: "Trinidad",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Alberto Lleras Camargo Airport",
    longitude: -72.969377,
    latitude: 5.677171,
    id: "arp_sox_co",
    icao_code: "SKSO",
    iata_country_code: "CO",
    iata_code: "SOX",
    city_name: "Sogamoso",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Albert – Picardie Airport",
    longitude: 2.696333,
    latitude: 49.971864,
    id: "arp_byf_fr",
    icao_code: "LFAQ",
    iata_country_code: "FR",
    iata_code: "BYF",
    city_name: "Albert",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Albertus Airport",
    longitude: -89.581727,
    latitude: 42.246452,
    id: "arp_fep_us",
    icao_code: "KFEP",
    iata_country_code: "US",
    iata_code: "FEP",
    city_name: "Freeport",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Albert Whitted Airport",
    longitude: -82.630173,
    latitude: 27.764901,
    id: "arp_spg_us",
    icao_code: "KSPG",
    iata_country_code: "US",
    iata_code: "SPG",
    city_name: "St. Petersburg",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Albi-Le Séquestre Airport",
    longitude: 2.114411,
    latitude: 43.914577,
    id: "arp_lbi_fr",
    icao_code: "LFCI",
    iata_country_code: "FR",
    iata_code: "LBI",
    city_name: "Albi",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Albina Airport",
    longitude: -54.050118,
    latitude: 5.512478,
    id: "arp_abn_sr",
    icao_code: "SMBN",
    iata_country_code: "SR",
    iata_code: "ABN",
    city_name: "Albina",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Al Buq Airport",
    longitude: 28.622417,
    latitude: -20.015928,
    id: "arp_buk_ye",
    icao_code: "OYBQ",
    iata_country_code: "YE",
    iata_code: "BUK",
    city_name: "Al Buq",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Albuquerque International Sunport",
    longitude: -106.610954,
    latitude: 35.041766,
    id: "arp_abq_us",
    icao_code: "KABQ",
    iata_country_code: "US",
    iata_code: "ABQ",
    city_name: "Albuquerque",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Albury Airport",
    longitude: 146.956265,
    latitude: -36.068399,
    id: "arp_abx_au",
    icao_code: "YMAY",
    iata_country_code: "AU",
    iata_code: "ABX",
    city_name: "Albury",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Alcides Fernández Airport",
    longitude: -77.273665,
    latitude: 8.496198,
    id: "arp_acd_co",
    icao_code: "SKAD",
    iata_country_code: "CO",
    iata_code: "ACD",
    city_name: "Acandí",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Aldan Airport",
    longitude: 125.408283,
    latitude: 58.603385,
    id: "arp_adh_ru",
    icao_code: "UEEA",
    iata_country_code: "RU",
    iata_code: "ADH",
    city_name: "Aldan",
    city: null,
  },
  {
    time_zone: "Europe/Guernsey",
    name: "Alderney Airport",
    longitude: -2.21472,
    latitude: 49.706104,
    id: "arp_aci_gg",
    icao_code: "EGJA",
    iata_country_code: "GG",
    iata_code: "ACI",
    city_name: "Alderney",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Al Dhafra Air Base",
    longitude: 54.548251,
    latitude: 24.249302,
    id: "arp_dhf_ae",
    icao_code: "OMAM",
    iata_country_code: "AE",
    iata_code: "DHF",
    city_name: "Abu Dhabi",
    city: {
      name: "Abu Dhabi",
      id: "cit_auh_ae",
      iata_country_code: "AE",
      iata_code: "AUH",
    },
  },
  {
    time_zone: "Africa/Dakar",
    name: "Aleg Airport",
    longitude: -14.964944,
    latitude: 16.678452,
    id: "arp_leg_mr",
    icao_code: null,
    iata_country_code: "MR",
    iata_code: "LEG",
    city_name: "Aleg",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Alegrete Novo Airport",
    longitude: -55.892987,
    latitude: -29.817475,
    id: "arp_alq_br",
    icao_code: "SSLT",
    iata_country_code: "BR",
    iata_code: "ALQ",
    city_name: "Alegrete",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Alejandro Velasco Astete International Airport",
    longitude: -71.941214,
    latitude: -13.536765,
    id: "arp_cuz_pe",
    icao_code: "SPZO",
    iata_country_code: "PE",
    iata_code: "CUZ",
    city_name: "Cusco",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Aleknagik Airport",
    longitude: -158.617899,
    latitude: 59.282692,
    id: "arp_wkk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WKK",
    city_name: "Aleknagik",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Alenquer Airport",
    longitude: -54.72228,
    latitude: -1.91705,
    id: "arp_alt_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "ALT",
    city_name: "Alenquer",
    city: null,
  },
  {
    time_zone: "Asia/Damascus",
    name: "Aleppo International Airport",
    longitude: 37.225151,
    latitude: 36.181637,
    id: "arp_alp_sy",
    icao_code: "OSAP",
    iata_country_code: "SY",
    iata_code: "ALP",
    city_name: "Aleppo",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Alerta Airport",
    longitude: -69.229493,
    latitude: -11.650036,
    id: "arp_ald_pe",
    icao_code: "SPAR",
    iata_country_code: "PE",
    iata_code: "ALD",
    city_name: "Alerta",
    city: null,
  },
  {
    time_zone: "America/Pangnirtung",
    name: "Alert Airport",
    longitude: -62.327781,
    latitude: 82.511152,
    id: "arp_ylt_ca",
    icao_code: "CYLT",
    iata_country_code: "CA",
    iata_code: "YLT",
    city_name: "Alert",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Alert Bay Airport",
    longitude: -126.917328,
    latitude: 50.582661,
    id: "arp_yal_ca",
    icao_code: "CYAL",
    iata_country_code: "CA",
    iata_code: "YAL",
    city_name: "Alert Bay",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Ålesund Airport, Vigra",
    longitude: 6.117434,
    latitude: 62.560566,
    id: "arp_aes_no",
    icao_code: "ENAL",
    iata_country_code: "NO",
    iata_code: "AES",
    city_name: "Ålesund",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Alexander Bay Airport",
    longitude: 16.530734,
    latitude: -28.572683,
    id: "arp_alj_za",
    icao_code: "FAAB",
    iata_country_code: "ZA",
    iata_code: "ALJ",
    city_name: "Alexander Bay",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Alexandra Airport",
    longitude: 169.371742,
    latitude: -45.213504,
    id: "arp_alr_nz",
    icao_code: "NZLX",
    iata_country_code: "NZ",
    iata_code: "ALR",
    city_name: "Alexandra",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Alexandria Homestead Airport",
    longitude: 136.711479,
    latitude: -19.060687,
    id: "arp_axl_au",
    icao_code: "YALX",
    iata_country_code: "AU",
    iata_code: "AXL",
    city_name: "Alexandria Station",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Alexandria International Airport",
    longitude: -92.546915,
    latitude: 31.326149,
    id: "arp_aex_us",
    icao_code: "KAEX",
    iata_country_code: "US",
    iata_code: "AEX",
    city_name: "Alexandria",
    city: {
      name: "Alexandria",
      id: "cit_aex_us",
      iata_country_code: "US",
      iata_code: "AEX",
    },
  },
  {
    time_zone: "Europe/Athens",
    name: "Alexandroupolis Airport",
    longitude: 25.953504,
    latitude: 40.856259,
    id: "arp_axd_gr",
    icao_code: "LGAL",
    iata_country_code: "GR",
    iata_code: "AXD",
    city_name: "Alexandroupolis",
    city: null,
  },
  {
    time_zone: "America/St_Thomas",
    name: "Alex Hamilton Airport",
    longitude: -64.800116,
    latitude: 17.701154,
    id: "arp_stx_vi",
    icao_code: "TISX",
    iata_country_code: "VI",
    iata_code: "STX",
    city_name: "St Croix Island",
    city: {
      name: "St Croix Island",
      id: "cit_stx_vi",
      iata_country_code: "VI",
      iata_code: "STX",
    },
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Alferez Armando Rodriguez Airport",
    longitude: -60.548791,
    latitude: -24.722042,
    id: "arp_lls_ar",
    icao_code: "SATK",
    iata_country_code: "AR",
    iata_code: "LLS",
    city_name: "Las Lomitas",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
    longitude: -76.46612,
    latitude: -2.796357,
    id: "arp_aop_pe",
    icao_code: "SPAS",
    iata_country_code: "PE",
    iata_code: "AOP",
    city_name: "Andoas",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Alfonso Bonilla Aragón International Airport",
    longitude: -76.383752,
    latitude: 3.541789,
    id: "arp_clo_co",
    icao_code: "SKCL",
    iata_country_code: "CO",
    iata_code: "CLO",
    city_name: "Cali",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Alfonso López Pumarejo Airport",
    longitude: -73.248644,
    latitude: 10.434309,
    id: "arp_vup_co",
    icao_code: "SKVP",
    iata_country_code: "CO",
    iata_code: "VUP",
    city_name: "Valledupar",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Alfredo Vásquez Cobo International Airport",
    longitude: -69.942306,
    latitude: -4.192345,
    id: "arp_let_co",
    icao_code: "SKLT",
    iata_country_code: "CO",
    iata_code: "LET",
    city_name: "Leticia",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Al Ghaydah Airport",
    longitude: 52.174267,
    latitude: 16.193682,
    id: "arp_aay_ye",
    icao_code: "OYGD",
    iata_country_code: "YE",
    iata_code: "AAY",
    city_name: "Al Ghaydah",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Alghero-Fertilia Airport",
    longitude: 8.290451,
    latitude: 40.632485,
    id: "arp_aho_it",
    icao_code: "LIEA",
    iata_country_code: "IT",
    iata_code: "AHO",
    city_name: "Alghero",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Algona Municipal Airport",
    longitude: -94.271972,
    latitude: 43.077838,
    id: "arp_axg_us",
    icao_code: "KAXA",
    iata_country_code: "US",
    iata_code: "AXG",
    city_name: "Algona",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Al Hamra Seaplane Base Airport",
    longitude: 52.463117,
    latitude: 24.073775,
    id: "arp_rhr_ae",
    icao_code: null,
    iata_country_code: "AE",
    iata_code: "RHR",
    city_name: "Ras al Khaimah",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Alicante–Elche Airport",
    longitude: -0.559106,
    latitude: 38.28384,
    id: "arp_alc_es",
    icao_code: "LEAL",
    iata_country_code: "ES",
    iata_code: "ALC",
    city_name: "Alicante",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Alice Arm Airport",
    longitude: -129.488492,
    latitude: 55.468217,
    id: "arp_zaa_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZAA",
    city_name: "Alice Arm",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Alice International Airport",
    longitude: -98.027008,
    latitude: 27.740877,
    id: "arp_ali_us",
    icao_code: "KALI",
    iata_country_code: "US",
    iata_code: "ALI",
    city_name: "Alice",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Alice Springs Airport",
    longitude: 133.902347,
    latitude: -23.805021,
    id: "arp_asp_au",
    icao_code: "YBAS",
    iata_country_code: "AU",
    iata_code: "ASP",
    city_name: "Alice Springs",
    city: null,
  },
  {
    time_zone: "Africa/Djibouti",
    name: "Ali-Sabieh Airport",
    longitude: 43.060818,
    latitude: 11.516854,
    id: "arp_aii_dj",
    icao_code: "HDAS",
    iata_country_code: "DJ",
    iata_code: "AII",
    city_name: "Ali Sabieh",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Alitak Seaplane Base Airport",
    longitude: -154.248873,
    latitude: 56.897616,
    id: "arp_alz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ALZ",
    city_name: "Lazy Bay",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Al-Jawf Domestic Airport",
    longitude: 40.100851,
    latitude: 29.785856,
    id: "arp_ajf_sa",
    icao_code: "OESK",
    iata_country_code: "SA",
    iata_code: "AJF",
    city_name: "Al-Jawf",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Allahabad Airport",
    longitude: 81.733956,
    latitude: 25.440093,
    id: "arp_ixd_in",
    icao_code: "VIAL",
    iata_country_code: "IN",
    iata_code: "IXD",
    city_name: "Allahabad",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Allah Valley Airport",
    longitude: 124.75065,
    latitude: 6.369073,
    id: "arp_aav_ph",
    icao_code: "RPMA",
    iata_country_code: "PH",
    iata_code: "AAV",
    city_name: "Surallah",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Allakaket Airport",
    longitude: -152.624426,
    latitude: 66.551071,
    id: "arp_aet_us",
    icao_code: "PFAL",
    iata_country_code: "US",
    iata_code: "AET",
    city_name: "Allakaket",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Allama Iqbal International Airport",
    longitude: 74.407111,
    latitude: 31.521077,
    id: "arp_lhe_pk",
    icao_code: "OPLA",
    iata_country_code: "PK",
    iata_code: "LHE",
    city_name: "Lahore",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Alldays Airport",
    longitude: 29.0555,
    latitude: -22.679,
    id: "arp_ady_za",
    icao_code: "FAAL",
    iata_country_code: "ZA",
    iata_code: "ADY",
    city_name: "Alldays",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Allegheny County Airport",
    longitude: -79.929429,
    latitude: 40.35391,
    id: "arp_agc_us",
    icao_code: "KAGC",
    iata_country_code: "US",
    iata_code: "AGC",
    city_name: "Pittsburgh",
    city: {
      name: "Pittsburgh",
      id: "cit_pit_us",
      iata_country_code: "US",
      iata_code: "PIT",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Allen Army Airfield",
    longitude: -145.721425,
    latitude: 63.994573,
    id: "arp_big_us",
    icao_code: "PABI",
    iata_country_code: "US",
    iata_code: "BIG",
    city_name: "Delta Junction",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Alliance Municipal Airport",
    longitude: -102.804995,
    latitude: 42.056102,
    id: "arp_aia_us",
    icao_code: "KAIA",
    iata_country_code: "US",
    iata_code: "AIA",
    city_name: "Alliance",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Alma Airport",
    longitude: -71.646963,
    latitude: 48.508851,
    id: "arp_ytf_ca",
    icao_code: "CYTF",
    iata_country_code: "CA",
    iata_code: "YTF",
    city_name: "Alma",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Al Maktoum International Airport",
    longitude: 55.143777,
    latitude: 24.89344,
    id: "arp_dwc_ae",
    icao_code: "OMDW",
    iata_country_code: "AE",
    iata_code: "DWC",
    city_name: "Dubai",
    city: {
      name: "Dubai",
      id: "cit_dxb_ae",
      iata_country_code: "AE",
      iata_code: "DXB",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "A.L. Mangham Jr. Regional Airport",
    longitude: -94.705817,
    latitude: 31.578406,
    id: "arp_och_us",
    icao_code: "KOCH",
    iata_country_code: "US",
    iata_code: "OCH",
    city_name: "Nacogdoches",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Almaty International Airport",
    longitude: 77.023187,
    latitude: 43.350605,
    id: "arp_ala_kz",
    icao_code: "UAAA",
    iata_country_code: "KZ",
    iata_code: "ALA",
    city_name: "Almaty",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Almenara Airport",
    longitude: -40.687691,
    latitude: -16.168404,
    id: "arp_amj_br",
    icao_code: "SNAR",
    iata_country_code: "BR",
    iata_code: "AMJ",
    city_name: "Almenara",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Almería Airport",
    longitude: -2.37144,
    latitude: 36.844992,
    id: "arp_lei_es",
    icao_code: "LEAM",
    iata_country_code: "ES",
    iata_code: "LEI",
    city_name: "Almería",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Al Minhad Air Base",
    longitude: 55.366199,
    latitude: 25.0268,
    id: "arp_nhd_ae",
    icao_code: "OMDM",
    iata_country_code: "AE",
    iata_code: "NHD",
    city_name: "Dubai",
    city: {
      name: "Dubai",
      id: "cit_dxb_ae",
      iata_country_code: "AE",
      iata_code: "DXB",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "Almirante Padilla Airport",
    longitude: -72.921447,
    latitude: 11.527799,
    id: "arp_rch_co",
    icao_code: "SKRH",
    iata_country_code: "CO",
    iata_code: "RCH",
    city_name: "Riohacha",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Al Najaf International Airport",
    longitude: 44.404537,
    latitude: 31.989786,
    id: "arp_njf_iq",
    icao_code: "ORNI",
    iata_country_code: "IQ",
    iata_code: "NJF",
    city_name: "Najaf",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Along Airport",
    longitude: 94.803426,
    latitude: 28.175885,
    id: "arp_ixv_in",
    icao_code: "VEAN",
    iata_country_code: "IN",
    iata_code: "IXV",
    city_name: "Along",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Alor Island Airport",
    longitude: 124.596347,
    latitude: -8.132867,
    id: "arp_ard_id",
    icao_code: "WATM",
    iata_country_code: "ID",
    iata_code: "ARD",
    city_name: "Alor Island",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Alowe Airport",
    longitude: 9.450278,
    latitude: -0.538056,
    id: "arp_awe_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "AWE",
    city_name: "Alowe",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Alpena County Regional Airport",
    longitude: -83.561389,
    latitude: 45.07658,
    id: "arp_apn_us",
    icao_code: "KAPN",
    iata_country_code: "US",
    iata_code: "APN",
    city_name: "Alpena",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Alpes–Isère Airport",
    longitude: 5.330252,
    latitude: 45.362019,
    id: "arp_gnb_fr",
    icao_code: "LFLS",
    iata_country_code: "FR",
    iata_code: "GNB",
    city_name: "Grenoble",
    city: {
      name: "Lyon",
      id: "cit_lys_fr",
      iata_country_code: "FR",
      iata_code: "LYS",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Alpha Airport",
    longitude: 146.583805,
    latitude: -23.646105,
    id: "arp_abh_au",
    icao_code: "YAPH",
    iata_country_code: "AU",
    iata_code: "ABH",
    city_name: "Alpha",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Alpine–Casparis Municipal Airport",
    longitude: -103.682001,
    latitude: 30.385334,
    id: "arp_ale_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ALE",
    city_name: "Alpine",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Al Qaisumah/Hafr Al Batin Airport",
    longitude: 46.126033,
    latitude: 28.335484,
    id: "arp_aqi_sa",
    icao_code: "OEPA",
    iata_country_code: "SA",
    iata_code: "AQI",
    city_name: "Qaisumah",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Alroy Downs Airport",
    longitude: 136.079359,
    latitude: -19.290808,
    id: "arp_ayd_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "AYD",
    city_name: "Alroy Downs",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Alta Airport",
    longitude: 23.36073,
    latitude: 69.977213,
    id: "arp_alf_no",
    icao_code: "ENAT",
    iata_country_code: "NO",
    iata_code: "ALF",
    city_name: "Alta",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Alta Floresta Airport",
    longitude: -56.10508,
    latitude: -9.869374,
    id: "arp_afl_br",
    icao_code: "SBAT",
    iata_country_code: "BR",
    iata_code: "AFL",
    city_name: "Alta Floresta",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Altai Airport",
    longitude: 96.226923,
    latitude: 46.375364,
    id: "arp_lti_mn",
    icao_code: "ZMAT",
    iata_country_code: "MN",
    iata_code: "LTI",
    city_name: "Altai",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Altamira Airport",
    longitude: -52.253215,
    latitude: -3.253061,
    id: "arp_atm_br",
    icao_code: "SBHT",
    iata_country_code: "BR",
    iata_code: "ATM",
    city_name: "Altamira",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Al Taqaddum Air Base",
    longitude: 43.597099,
    latitude: 33.3381,
    id: "arp_tqd_iq",
    icao_code: "ORAT",
    iata_country_code: "IQ",
    iata_code: "TQD",
    city_name: "Al Fallujah",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Altay Airport",
    longitude: 88.085351,
    latitude: 47.750044,
    id: "arp_aat_cn",
    icao_code: "ZWAT",
    iata_country_code: "CN",
    iata_code: "AAT",
    city_name: "Altay",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Altenburg-Nobitz Airport",
    longitude: 12.508916,
    latitude: 50.980573,
    id: "arp_aoc_de",
    icao_code: "EDAC",
    iata_country_code: "DE",
    iata_code: "AOC",
    city_name: "Altenburg",
    city: null,
  },
  {
    time_zone: "Asia/Damascus",
    name: "Al Thaurah Airport",
    longitude: 40.165926,
    latitude: 34.399699,
    id: "arp_sor_sy",
    icao_code: null,
    iata_country_code: "SY",
    iata_code: "SOR",
    city_name: "Al Thaurah",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Alto Molocue Airport",
    longitude: 37.681346,
    latitude: -15.610412,
    id: "arp_ame_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "AME",
    city_name: "Alto Molocue",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Alton Downs Airport",
    longitude: 139.317,
    latitude: -26.4833,
    id: "arp_awn_au",
    icao_code: "YADS",
    iata_country_code: "AU",
    iata_code: "AWN",
    city_name: "Alton Downs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Altoona-Blair County Airport",
    longitude: -78.321569,
    latitude: 40.29617,
    id: "arp_aoo_us",
    icao_code: "KAOO",
    iata_country_code: "US",
    iata_code: "AOO",
    city_name: "Altoona",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Alto Palena Airport",
    longitude: -71.805638,
    latitude: -43.611901,
    id: "arp_wap_cl",
    icao_code: "SCAP",
    iata_country_code: "CL",
    iata_code: "WAP",
    city_name: "Alto Palena",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Alto Parnaíba Airport",
    longitude: -45.955355,
    latitude: -9.085661,
    id: "arp_apy_br",
    icao_code: "SNAI",
    iata_country_code: "BR",
    iata_code: "APY",
    city_name: "Alto Parnaiba",
    city: null,
  },
  {
    time_zone: "America/Catamarca",
    name: "Alto Río Senguer Airport",
    longitude: -70.812394,
    latitude: -45.013418,
    id: "arp_arr_ar",
    icao_code: "SAVR",
    iata_country_code: "AR",
    iata_code: "ARR",
    city_name: "Alto Rio Senguer",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Altus Air Force Base",
    longitude: -99.266701,
    latitude: 34.667099,
    id: "arp_lts_us",
    icao_code: "KLTS",
    iata_country_code: "US",
    iata_code: "LTS",
    city_name: "Altus",
    city: {
      name: "Altus",
      id: "cit_lts_us",
      iata_country_code: "US",
      iata_code: "LTS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Altus/Quartz Mountain Regional Airport",
    longitude: -99.337992,
    latitude: 34.698416,
    id: "arp_axs_us",
    icao_code: "KAXS",
    iata_country_code: "US",
    iata_code: "AXS",
    city_name: "Altus",
    city: {
      name: "Altus",
      id: "cit_lts_us",
      iata_country_code: "US",
      iata_code: "LTS",
    },
  },
  {
    time_zone: "Asia/Qatar",
    name: "Al Udeid Air Base",
    longitude: 51.314999,
    latitude: 25.1173,
    id: "arp_xjd_qa",
    icao_code: "OTBH",
    iata_country_code: "QA",
    iata_code: "XJD",
    city_name: "Al Udeid",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Alula Aba Nega Airport",
    longitude: 39.534014,
    latitude: 13.467208,
    id: "arp_mqx_et",
    icao_code: "HAMK",
    iata_country_code: "ET",
    iata_code: "MQX",
    city_name: "Makale",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Alula Airport",
    longitude: 50.748118,
    latitude: 11.958354,
    id: "arp_alu_so",
    icao_code: "HCMA",
    iata_country_code: "SO",
    iata_code: "ALU",
    city_name: "Alula",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Alverca Airport",
    longitude: -9.0301,
    latitude: 38.883301,
    id: "arp_avr_pt",
    icao_code: "LPAR",
    iata_country_code: "PT",
    iata_code: "AVR",
    city_name: "Alverca",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Al Wajh Domestic Airport",
    longitude: 36.476117,
    latitude: 26.198511,
    id: "arp_ejh_sa",
    icao_code: "OEWJ",
    iata_country_code: "SA",
    iata_code: "EJH",
    city_name: "Al Wajh",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Alxa Left Banner Bayanhot Airport",
    longitude: 105.58858,
    latitude: 38.74831,
    id: "arp_axf_cn",
    icao_code: "ZBAL",
    iata_country_code: "CN",
    iata_code: "AXF",
    city_name: "Alxa Left Banner",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Alxa Right Banner Airport",
    longitude: 101.546,
    latitude: 39.225,
    id: "arp_rht_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "RHT",
    city_name: "Alxa Right Banner",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Alykel Airport",
    longitude: 87.331797,
    latitude: 69.31095,
    id: "arp_nsk_ru",
    icao_code: "UOOO",
    iata_country_code: "RU",
    iata_code: "NSK",
    city_name: "Norilsk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ama Airport",
    longitude: 141.670045,
    latitude: -4.101175,
    id: "arp_amf_pg",
    icao_code: "AYAA",
    iata_country_code: "PG",
    iata_code: "AMF",
    city_name: "Ama",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Amahai Airport",
    longitude: 128.926775,
    latitude: -3.347024,
    id: "arp_ahi_id",
    icao_code: "WAPA",
    iata_country_code: "ID",
    iata_code: "AHI",
    city_name: "Amahai",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Amakusa Airport",
    longitude: 130.15814,
    latitude: 32.482535,
    id: "arp_axj_jp",
    icao_code: "RJDA",
    iata_country_code: "JP",
    iata_code: "AXJ",
    city_name: "Amakusa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Amalfi Airport",
    longitude: -75.066809,
    latitude: 6.916505,
    id: "arp_afi_co",
    icao_code: "SKAM",
    iata_country_code: "CO",
    iata_code: "AFI",
    city_name: "Amalfi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Amami Airport",
    longitude: 129.711769,
    latitude: 28.430872,
    id: "arp_asj_jp",
    icao_code: "RJKA",
    iata_country_code: "JP",
    iata_code: "ASJ",
    city_name: "Amami",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Amanab Airport",
    longitude: 141.215639,
    latitude: -3.585489,
    id: "arp_amu_pg",
    icao_code: "AYAM",
    iata_country_code: "PG",
    iata_code: "AMU",
    city_name: "Amanab",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Amasya Merzifon Airport",
    longitude: 35.521591,
    latitude: 40.829874,
    id: "arp_mzh_tr",
    icao_code: "LTAP",
    iata_country_code: "TR",
    iata_code: "MZH",
    city_name: "Amasya",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Amata Airport",
    longitude: 131.206123,
    latitude: -26.105788,
    id: "arp_amt_au",
    icao_code: "YAMT",
    iata_country_code: "AU",
    iata_code: "AMT",
    city_name: "Amata",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Amazon Bay Airport",
    longitude: 149.336991,
    latitude: -10.299061,
    id: "arp_azb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AZB",
    city_name: "Amazon Bay",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ambalabe Airport",
    longitude: 47.994058,
    latitude: -14.900201,
    id: "arp_wai_mg",
    icao_code: "FMNW",
    iata_country_code: "MG",
    iata_code: "WAI",
    city_name: "Antsohihy",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ambatolahy Airport",
    longitude: 45.533,
    latitude: -20.0,
    id: "arp_ahy_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "AHY",
    city_name: "Ambatolahy",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ambatomainty Airport",
    longitude: 45.626179,
    latitude: -17.684913,
    id: "arp_amy_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "AMY",
    city_name: "Ambatomainty",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ambatondrazaka Airport",
    longitude: 48.442699,
    latitude: -17.795434,
    id: "arp_wam_mg",
    icao_code: "FMMZ",
    iata_country_code: "MG",
    iata_code: "WAM",
    city_name: "Ambatondrazaka",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ambilobe Airport",
    longitude: 48.988026,
    latitude: -13.188433,
    id: "arp_amb_mg",
    icao_code: "FMNE",
    iata_country_code: "MG",
    iata_code: "AMB",
    city_name: "Ambilobe",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ambler Airport",
    longitude: -157.856319,
    latitude: 67.105946,
    id: "arp_abl_us",
    icao_code: "PAFM",
    iata_country_code: "US",
    iata_code: "ABL",
    city_name: "Ambler",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ambohibary Airport",
    longitude: 48.217776,
    latitude: -18.91817,
    id: "arp_ohb_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "OHB",
    city_name: "Moramanga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Amboin Airport",
    longitude: 147.219436,
    latitude: -9.442378,
    id: "arp_amg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AMG",
    city_name: "Amboin",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Amborovy Airport",
    longitude: 46.351032,
    latitude: -15.666615,
    id: "arp_mjn_mg",
    icao_code: "FMNM",
    iata_country_code: "MG",
    iata_code: "MJN",
    city_name: "Mahajanga",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Amboseli Airport",
    longitude: 37.250909,
    latitude: -2.645349,
    id: "arp_asv_ke",
    icao_code: "HKAM",
    iata_country_code: "KE",
    iata_code: "ASV",
    city_name: "Amboseli",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Ambriz Airport",
    longitude: 13.116107,
    latitude: -7.862221,
    id: "arp_azz_ao",
    icao_code: "FNAM",
    iata_country_code: "AO",
    iata_code: "AZZ",
    city_name: "Ambriz",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ambunti Airport",
    longitude: 142.822898,
    latitude: -4.21618,
    id: "arp_auj_pg",
    icao_code: "AYAT",
    iata_country_code: "PG",
    iata_code: "AUJ",
    city_name: "Ambunti",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Amderma Airport",
    longitude: 61.56233,
    latitude: 69.762983,
    id: "arp_amv_ru",
    icao_code: "ULDD",
    iata_country_code: "RU",
    iata_code: "AMV",
    city_name: "Amderma",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Amedee Army Airfield",
    longitude: -120.152664,
    latitude: 40.26614,
    id: "arp_ahc_us",
    icao_code: "KAHC",
    iata_country_code: "US",
    iata_code: "AHC",
    city_name: "Herlong",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "American River Airport",
    longitude: 137.73801,
    latitude: -35.812116,
    id: "arp_rcn_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "RCN",
    city_name: "American River",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Amerigo Vespucci Airport",
    longitude: 11.203161,
    latitude: 43.809327,
    id: "arp_flr_it",
    icao_code: "LIRQ",
    iata_country_code: "IT",
    iata_code: "FLR",
    city_name: "Florence",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Amery Municipal Airport",
    longitude: -92.373877,
    latitude: 45.282075,
    id: "arp_ahh_us",
    icao_code: "KAHH",
    iata_country_code: "US",
    iata_code: "AHH",
    city_name: "Amery",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ames Municipal Airport",
    longitude: -93.622021,
    latitude: 41.994219,
    id: "arp_amw_us",
    icao_code: "KAMW",
    iata_country_code: "US",
    iata_code: "AMW",
    city_name: "Ames",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Amgu Airport",
    longitude: 137.676219,
    latitude: 45.841615,
    id: "arp_aem_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "AEM",
    city_name: "Amgu",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Amílcar Cabral International Airport",
    longitude: -22.946597,
    latitude: 16.738018,
    id: "arp_sid_cv",
    icao_code: "GVAC",
    iata_country_code: "CV",
    iata_code: "SID",
    city_name: "Espargos",
    city: null,
  },
  {
    time_zone: "Asia/Amman",
    name: "Amman Civil Airport",
    longitude: 35.987633,
    latitude: 31.973712,
    id: "arp_adj_jo",
    icao_code: "OJAM",
    iata_country_code: "JO",
    iata_code: "ADJ",
    city_name: "Amman",
    city: {
      name: "Amman",
      id: "cit_amm_jo",
      iata_country_code: "JO",
      iata_code: "AMM",
    },
  },
  {
    time_zone: "Australia/Darwin",
    name: "Ammaroo Airport",
    longitude: 135.243128,
    latitude: -21.738353,
    id: "arp_amx_au",
    icao_code: "YAMM",
    iata_country_code: "AU",
    iata_code: "AMX",
    city_name: "Ammaroo",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Amook Bay Seaplane Base",
    longitude: -153.815182,
    latitude: 57.471497,
    id: "arp_aos_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AOS",
    city_name: "Amook Bay",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Amos/Magny Airport",
    longitude: -78.247219,
    latitude: 48.562569,
    id: "arp_yey_ca",
    icao_code: "CYEY",
    iata_country_code: "CA",
    iata_code: "YEY",
    city_name: "Amos",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ampampamena Airport",
    longitude: 48.63278,
    latitude: -13.484944,
    id: "arp_iva_mg",
    icao_code: "FMNJ",
    iata_country_code: "MG",
    iata_code: "IVA",
    city_name: "Ambanja",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ampanihy Airport",
    longitude: 44.732474,
    latitude: -24.699276,
    id: "arp_amp_mg",
    icao_code: "FMSY",
    iata_country_code: "MG",
    iata_code: "AMP",
    city_name: "Ampanihy",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Ampara Airport",
    longitude: 81.624498,
    latitude: 7.337251,
    id: "arp_adp_lk",
    icao_code: "VCCG",
    iata_country_code: "LK",
    iata_code: "ADP",
    city_name: "Ampara",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Amsterdam Airport Schiphol",
    longitude: 4.765869,
    latitude: 52.308856,
    id: "arp_ams_nl",
    icao_code: "EHAM",
    iata_country_code: "NL",
    iata_code: "AMS",
    city_name: "Amsterdam",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Am Timan Airport",
    longitude: 20.274,
    latitude: 11.034,
    id: "arp_amc_td",
    icao_code: "FTTN",
    iata_country_code: "TD",
    iata_code: "AMC",
    city_name: "Am Timan",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Anaa Airport",
    longitude: -145.509994,
    latitude: -17.3526,
    id: "arp_aaa_pf",
    icao_code: "NTGA",
    iata_country_code: "PF",
    iata_code: "AAA",
    city_name: "Anaa",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Anaco Airport",
    longitude: -64.471038,
    latitude: 9.430335,
    id: "arp_aao_ve",
    icao_code: "SVAN",
    iata_country_code: "VE",
    iata_code: "AAO",
    city_name: "Anaco",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Anacortes Airport",
    longitude: -122.661295,
    latitude: 48.498426,
    id: "arp_ots_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "OTS",
    city_name: "Anacortes",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Anadolu Airport",
    longitude: 30.520975,
    latitude: 39.811218,
    id: "arp_aoe_tr",
    icao_code: "LTBY",
    iata_country_code: "TR",
    iata_code: "AOE",
    city_name: "Eskişehir",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Anahim Lake Airport",
    longitude: -125.303712,
    latitude: 52.452441,
    id: "arp_yaa_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YAA",
    city_name: "Anahim Lake",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Anaktuvuk Pass Airport",
    longitude: -151.743165,
    latitude: 68.133427,
    id: "arp_akp_us",
    icao_code: "PAKP",
    iata_country_code: "US",
    iata_code: "AKP",
    city_name: "Anaktuvuk Pass",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Analalava Airport",
    longitude: 47.763147,
    latitude: -14.629388,
    id: "arp_hva_mg",
    icao_code: "FMNL",
    iata_country_code: "MG",
    iata_code: "HVA",
    city_name: "Analalava",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Anapa Airport",
    longitude: 37.345511,
    latitude: 45.002224,
    id: "arp_aaq_ru",
    icao_code: "URKA",
    iata_country_code: "RU",
    iata_code: "AAQ",
    city_name: "Anapa",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Anápolis Airport",
    longitude: -48.927573,
    latitude: -16.362424,
    id: "arp_aps_br",
    icao_code: "SWNS",
    iata_country_code: "BR",
    iata_code: "APS",
    city_name: "Anápolis",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Anatom Airport",
    longitude: 169.771077,
    latitude: -20.249136,
    id: "arp_auy_vu",
    icao_code: "NVVA",
    iata_country_code: "VU",
    iata_code: "AUY",
    city_name: "Anatom Island",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Ancona Falconara Airport",
    longitude: 13.360881,
    latitude: 43.614059,
    id: "arp_aoi_it",
    icao_code: "LIPY",
    iata_country_code: "IT",
    iata_code: "AOI",
    city_name: "Ancona",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Andahuaylas Airport",
    longitude: -73.351439,
    latitude: -13.709834,
    id: "arp_ans_pe",
    icao_code: "SPHY",
    iata_country_code: "PE",
    iata_code: "ANS",
    city_name: "Andahuaylas",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Andakombe Airport",
    longitude: 145.744642,
    latitude: -7.137255,
    id: "arp_adc_pg",
    icao_code: "AYAN",
    iata_country_code: "PG",
    iata_code: "ADC",
    city_name: "Andakombe",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Andamooka Airport",
    longitude: 137.138268,
    latitude: -30.437741,
    id: "arp_ado_au",
    icao_code: "YAMK",
    iata_country_code: "AU",
    iata_code: "ADO",
    city_name: "Andamooka",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Andapa Airport",
    longitude: 49.620601,
    latitude: -14.6517,
    id: "arp_zwa_mg",
    icao_code: "FMND",
    iata_country_code: "MG",
    iata_code: "ZWA",
    city_name: "Andapa",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Andavadoaka Airport",
    longitude: 43.375489,
    latitude: -21.752928,
    id: "arp_dvd_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "DVD",
    city_name: "Andavadoaka",
    city: null,
  },
  {
    time_zone: "Pacific/Guam",
    name: "Andersen Air Force Base",
    longitude: 144.928145,
    latitude: 13.585167,
    id: "arp_uam_gu",
    icao_code: "PGUA",
    iata_country_code: "GU",
    iata_code: "UAM",
    city_name: "Guam",
    city: {
      name: "Guam",
      id: "cit_gum_gu",
      iata_country_code: "GU",
      iata_code: "GUM",
    },
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Anderson Municipal Airport",
    longitude: -85.613585,
    latitude: 40.109121,
    id: "arp_aid_us",
    icao_code: "KAID",
    iata_country_code: "US",
    iata_code: "AID",
    city_name: "Anderson",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Anderson Regional Airport",
    longitude: -82.710527,
    latitude: 34.494651,
    id: "arp_and_us",
    icao_code: "KAND",
    iata_country_code: "US",
    iata_code: "AND",
    city_name: "Anderson",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Andes Airport",
    longitude: -75.8833,
    latitude: 5.66667,
    id: "arp_adn_co",
    icao_code: "SKAN",
    iata_country_code: "CO",
    iata_code: "ADN",
    city_name: "Andes",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Andi Jemma Airport",
    longitude: 120.324285,
    latitude: -2.556128,
    id: "arp_mxb_id",
    icao_code: "WAWM",
    iata_country_code: "ID",
    iata_code: "MXB",
    city_name: "Masamba",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Andizhan Airport",
    longitude: 72.293497,
    latitude: 40.72745,
    id: "arp_azn_uz",
    icao_code: "UTFA",
    iata_country_code: "UZ",
    iata_code: "AZN",
    city_name: "Andizhan",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Andorra–La Seu d'Urgell Airport",
    longitude: 1.407532,
    latitude: 42.338176,
    id: "arp_leu_es",
    icao_code: "LESU",
    iata_country_code: "ES",
    iata_code: "LEU",
    city_name: "Seo de Urgel",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Andøya Airport",
    longitude: 16.144188,
    latitude: 69.292502,
    id: "arp_anx_no",
    icao_code: "ENAN",
    iata_country_code: "NO",
    iata_code: "ANX",
    city_name: "Andenes",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Andrau Airpark Airport",
    longitude: 117.257525,
    latitude: -0.371188,
    id: "arp_aap_id",
    icao_code: "WALS",
    iata_country_code: "ID",
    iata_code: "AAP",
    city_name: "Samarinda",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Andravida Air Base",
    longitude: 21.292601,
    latitude: 37.9207,
    id: "arp_pyr_gr",
    icao_code: "LGAD",
    iata_country_code: "GR",
    iata_code: "PYR",
    city_name: "Andravida",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Andre Maggi Airport",
    longitude: -58.582213,
    latitude: -9.898992,
    id: "arp_ott_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "OTT",
    city_name: "Cotriguaçu",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Andrés Miguel Salazar Marcano Airport",
    longitude: -63.98159,
    latitude: 10.794432,
    id: "arp_icc_ve",
    icao_code: "SVIE",
    iata_country_code: "VE",
    iata_code: "ICC",
    city_name: "Isla de Coche",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Andriamena Airport",
    longitude: 47.723612,
    latitude: -17.631159,
    id: "arp_wad_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "WAD",
    city_name: "Andriamena",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Andros Town Airport",
    longitude: -77.794337,
    latitude: 24.698165,
    id: "arp_asd_bs",
    icao_code: "MYAF",
    iata_country_code: "BS",
    iata_code: "ASD",
    city_name: "Andros Town",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Andulo Airport",
    longitude: 16.710855,
    latitude: -11.471692,
    id: "arp_anl_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "ANL",
    city_name: "Andulo",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Anfa Airport",
    longitude: -7.661528,
    latitude: 33.554704,
    id: "arp_cas_ma",
    icao_code: "GMMC",
    iata_country_code: "MA",
    iata_code: "CAS",
    city_name: "Casablanca",
    city: {
      name: "Casablanca",
      id: "cit_cas_ma",
      iata_country_code: "MA",
      iata_code: "CAS",
    },
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Angads Airport",
    longitude: -1.924512,
    latitude: 34.786837,
    id: "arp_oud_ma",
    icao_code: "GMFO",
    iata_country_code: "MA",
    iata_code: "OUD",
    city_name: "Oujda",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Angama Airport",
    longitude: 34.954754,
    latitude: -1.271839,
    id: "arp_ana_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "ANA",
    city_name: "Maasai Mara",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Ángel Albino Corzo International Airport",
    longitude: -93.021852,
    latitude: 16.561754,
    id: "arp_tgz_mx",
    icao_code: "MMTG",
    iata_country_code: "MX",
    iata_code: "TGZ",
    city_name: "Tuxtla Gutiérrez",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Angel Fire Airport",
    longitude: -105.29126,
    latitude: 36.42085,
    id: "arp_axx_us",
    icao_code: "KAXX",
    iata_country_code: "US",
    iata_code: "AXX",
    city_name: "Angel Fire",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Ängelholm-Helsingborg Airport",
    longitude: 12.866561,
    latitude: 56.287678,
    id: "arp_agh_se",
    icao_code: "ESTA",
    iata_country_code: "SE",
    iata_code: "AGH",
    city_name: "Ängelholm",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Angelina County Airport",
    longitude: -94.75181,
    latitude: 31.232902,
    id: "arp_lfk_us",
    icao_code: "KLFK",
    iata_country_code: "US",
    iata_code: "LFK",
    city_name: "Lufkin",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Angers - Loire Airport",
    longitude: -0.312167,
    latitude: 47.56033,
    id: "arp_ane_fr",
    icao_code: "LFJR",
    iata_country_code: "FR",
    iata_code: "ANE",
    city_name: "Marcé",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Anggi Airport",
    longitude: 133.867,
    latitude: -1.383333,
    id: "arp_agd_id",
    icao_code: "WASG",
    iata_country_code: "ID",
    iata_code: "AGD",
    city_name: "Anggi",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Anglesey Airport",
    longitude: -4.535258,
    latitude: 53.250363,
    id: "arp_vly_gb",
    icao_code: "EGOV",
    iata_country_code: "GB",
    iata_code: "VLY",
    city_name: "Valley",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Angoche Airport",
    longitude: 39.944612,
    latitude: -16.181899,
    id: "arp_ano_mz",
    icao_code: "FQAG",
    iata_country_code: "MZ",
    iata_code: "ANO",
    city_name: "Angoche",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Angoon Seaplane Base",
    longitude: -134.569527,
    latitude: 57.496613,
    id: "arp_agn_us",
    icao_code: "PAGN",
    iata_country_code: "US",
    iata_code: "AGN",
    city_name: "Angoon",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Angoram Airport",
    longitude: 144.073803,
    latitude: -4.056047,
    id: "arp_agg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AGG",
    city_name: "Angoram",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Angoulême – Brie – Champniers Airport",
    longitude: 0.219268,
    latitude: 45.729586,
    id: "arp_ang_fr",
    icao_code: "LFBU",
    iata_country_code: "FR",
    iata_code: "ANG",
    city_name: "Angouleme",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Anguganak Airport",
    longitude: 142.216352,
    latitude: -3.560279,
    id: "arp_akg_pg",
    icao_code: "AYGU",
    iata_country_code: "PG",
    iata_code: "AKG",
    city_name: "Anguganak",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Angus Downs Airport",
    longitude: 132.275635,
    latitude: -25.033008,
    id: "arp_anz_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "ANZ",
    city_name: "Angus Downs Station",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Aniak Airport",
    longitude: -159.54279,
    latitude: 61.581199,
    id: "arp_ani_us",
    icao_code: "PANI",
    iata_country_code: "US",
    iata_code: "ANI",
    city_name: "Aniak",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Animas Air Park",
    longitude: -107.867052,
    latitude: 37.204956,
    id: "arp_amk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AMK",
    city_name: "Durango",
    city: {
      name: "Durango",
      id: "cit_dro_us",
      iata_country_code: "US",
      iata_code: "DRO",
    },
  },
  {
    time_zone: "Pacific/Efate",
    name: "Aniwa Airport",
    longitude: 169.600613,
    latitude: -19.23557,
    id: "arp_awd_vu",
    icao_code: "NVVB",
    iata_country_code: "VU",
    iata_code: "AWD",
    city_name: "Aniwa",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ankang Wulipu Airport",
    longitude: 108.933708,
    latitude: 32.707881,
    id: "arp_aka_cn",
    icao_code: "ZLAK",
    iata_country_code: "CN",
    iata_code: "AKA",
    city_name: "Ankang",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ankavandra Airport",
    longitude: 45.283,
    latitude: -18.8,
    id: "arp_jva_mg",
    icao_code: "FMMK",
    iata_country_code: "MG",
    iata_code: "JVA",
    city_name: "Ankavandra",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ankazoabo Airport",
    longitude: 44.531611,
    latitude: -22.296522,
    id: "arp_wak_mg",
    icao_code: "FMSZ",
    iata_country_code: "MG",
    iata_code: "WAK",
    city_name: "Ankazoabo",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ankokoambo Airport",
    longitude: 44.466121,
    latitude: -16.567183,
    id: "arp_nko_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "NKO",
    city_name: "Ankokoambo",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Annai Airport",
    longitude: -59.124045,
    latitude: 3.959879,
    id: "arp_nai_gy",
    icao_code: "SYAN",
    iata_country_code: "GY",
    iata_code: "NAI",
    city_name: "Annai",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Ann Airport",
    longitude: 94.0261,
    latitude: 19.769199,
    id: "arp_vba_mm",
    icao_code: "VYAN",
    iata_country_code: "MM",
    iata_code: "VBA",
    city_name: "Ann",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Annanberg Airport",
    longitude: 144.650311,
    latitude: -4.91653,
    id: "arp_aob_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AOB",
    city_name: "Annanberg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ann Arbor Municipal Airport",
    longitude: -83.745442,
    latitude: 42.223654,
    id: "arp_arb_us",
    icao_code: "KARB",
    iata_country_code: "US",
    iata_code: "ARB",
    city_name: "Ann Arbor",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Annecy – Haute-Savoie – Mont Blanc Airport",
    longitude: 6.105093,
    latitude: 45.930829,
    id: "arp_ncy_fr",
    icao_code: "LFLP",
    iata_country_code: "FR",
    iata_code: "NCY",
    city_name: "Annecy",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Annette Island Airport",
    longitude: -131.572077,
    latitude: 55.040489,
    id: "arp_ann_us",
    icao_code: "PANT",
    iata_country_code: "US",
    iata_code: "ANN",
    city_name: "Annette Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Anniston Regional Airport",
    longitude: -85.856331,
    latitude: 33.589088,
    id: "arp_anb_us",
    icao_code: "KANB",
    iata_country_code: "US",
    iata_code: "ANB",
    city_name: "Anniston",
    city: null,
  },
  {
    time_zone: "Africa/Malabo",
    name: "Annobón Airport",
    longitude: 5.622228,
    latitude: -1.410012,
    id: "arp_nbn_gq",
    icao_code: "FGAB",
    iata_country_code: "GQ",
    iata_code: "NBN",
    city_name: "Annobon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Anqing Tianzhushan Airport",
    longitude: 117.050857,
    latitude: 30.582691,
    id: "arp_aqg_cn",
    icao_code: "ZSAQ",
    iata_country_code: "CN",
    iata_code: "AQG",
    city_name: "Anqing",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Anshan Teng'ao Airport",
    longitude: 122.866537,
    latitude: 41.113021,
    id: "arp_aog_cn",
    icao_code: "ZYAS",
    iata_country_code: "CN",
    iata_code: "AOG",
    city_name: "Anshan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Anshun Huangguoshu Airport",
    longitude: 105.874401,
    latitude: 26.258398,
    id: "arp_ava_cn",
    icao_code: "ZUAS",
    iata_country_code: "CN",
    iata_code: "AVA",
    city_name: "Anshun",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "anta Bárbara del Zulia Airport",
    longitude: -71.943134,
    latitude: 8.975289,
    id: "arp_stb_ve",
    icao_code: "SVSZ",
    iata_country_code: "VE",
    iata_code: "STB",
    city_name: "Santa Barbara Zulia",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Antalya Airport",
    longitude: 30.801188,
    latitude: 36.901516,
    id: "arp_ayt_tr",
    icao_code: "LTAI",
    iata_country_code: "TR",
    iata_code: "AYT",
    city_name: "Antalya",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Anthony Lagoon Airport",
    longitude: 135.538511,
    latitude: -18.019781,
    id: "arp_ayl_au",
    icao_code: "YANL",
    iata_country_code: "AU",
    iata_code: "AYL",
    city_name: "Anthony Lagoon",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Anthony Municipal Airport",
    longitude: -98.078177,
    latitude: 37.15561,
    id: "arp_any_us",
    icao_code: "KANY",
    iata_country_code: "US",
    iata_code: "ANY",
    city_name: "Anthony",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Antlers Municipal Airport",
    longitude: -95.649811,
    latitude: 34.192551,
    id: "arp_ate_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ATE",
    city_name: "Antlers",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Antofagasta Airport",
    longitude: -70.442989,
    latitude: -23.446678,
    id: "arp_anf_cl",
    icao_code: "SCFA",
    iata_country_code: "CL",
    iata_code: "ANF",
    city_name: "Antofagasta",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Antoine de Saint Exupéry Airport",
    longitude: -65.031305,
    latitude: -40.752946,
    id: "arp_oes_ar",
    icao_code: "SAVN",
    iata_country_code: "AR",
    iata_code: "OES",
    city_name: "San Antonio Oeste",
    city: null,
  },
  {
    time_zone: "Pacific/Guam",
    name: "Antonio B. Won Pat International Airport",
    longitude: 144.797094,
    latitude: 13.483789,
    id: "arp_gum_gu",
    icao_code: "PGUM",
    iata_country_code: "GU",
    iata_code: "GUM",
    city_name: "Tamuning",
    city: {
      name: "Guam",
      id: "cit_gum_gu",
      iata_country_code: "GU",
      iata_code: "GUM",
    },
  },
  {
    time_zone: "America/Caracas",
    name: "Antonio José de Sucre Airport",
    longitude: -64.131423,
    latitude: 10.451089,
    id: "arp_cum_ve",
    icao_code: "SVCU",
    iata_country_code: "VE",
    iata_code: "CUM",
    city_name: "Cumaná",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Antonio Maceo International Airport",
    longitude: -75.83548,
    latitude: 19.9699,
    id: "arp_scu_cu",
    icao_code: "MUCU",
    iata_country_code: "CU",
    iata_code: "SCU",
    city_name: "Santiago",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Antonio Narino Airport",
    longitude: -77.29101,
    latitude: 1.39674,
    id: "arp_pso_co",
    icao_code: "SKPS",
    iata_country_code: "CO",
    iata_code: "PSO",
    city_name: "Pasto",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Antonio Nery Juarbe Pol Airport",
    longitude: -66.675479,
    latitude: 18.450093,
    id: "arp_are_pr",
    icao_code: "TJAB",
    iata_country_code: "PR",
    iata_code: "ARE",
    city_name: "Arecibo",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Antonio Rivera Rodríguez Airport",
    longitude: -65.490653,
    latitude: 18.135471,
    id: "arp_vqs_pr",
    icao_code: "TJVQ",
    iata_country_code: "PR",
    iata_code: "VQS",
    city_name: "Vieques",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Antonio Roldán Betancourt Airport",
    longitude: -76.717241,
    latitude: 7.814354,
    id: "arp_apo_co",
    icao_code: "SKLC",
    iata_country_code: "CO",
    iata_code: "APO",
    city_name: "Apartadó",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Antrim County Airport",
    longitude: -85.198133,
    latitude: 44.988418,
    id: "arp_acb_us",
    icao_code: "KACB",
    iata_country_code: "US",
    iata_code: "ACB",
    city_name: "Bellaire",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Antsalova Airport",
    longitude: 44.614921,
    latitude: -18.701273,
    id: "arp_waq_mg",
    icao_code: "FMMG",
    iata_country_code: "MG",
    iata_code: "WAQ",
    city_name: "Antsalova",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Antsirabato Airport",
    longitude: 50.320258,
    latitude: -14.999134,
    id: "arp_anm_mg",
    icao_code: "FMNH",
    iata_country_code: "MG",
    iata_code: "ANM",
    city_name: "Antalaha",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Antsirabe Airport",
    longitude: 47.063936,
    latitude: -19.83932,
    id: "arp_atj_mg",
    icao_code: "FMME",
    iata_country_code: "MG",
    iata_code: "ATJ",
    city_name: "Antsirabe",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Antsoa Airport",
    longitude: 45.13617,
    latitude: -21.607712,
    id: "arp_wbo_mg",
    icao_code: "FMSB",
    iata_country_code: "MG",
    iata_code: "WBO",
    city_name: "Beroroha",
    city: null,
  },
  {
    time_zone: "Europe/Brussels",
    name: "Antwerp International Airport",
    longitude: 4.460576,
    latitude: 51.189072,
    id: "arp_anr_be",
    icao_code: "EBAW",
    iata_country_code: "BE",
    iata_code: "ANR",
    city_name: "Antwerp",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Anuradhapura Airport",
    longitude: 80.427434,
    latitude: 8.301447,
    id: "arp_acj_lk",
    icao_code: "VCCA",
    iata_country_code: "LK",
    iata_code: "ACJ",
    city_name: "Anuradhapura",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Anvik Airport",
    longitude: -160.190593,
    latitude: 62.647556,
    id: "arp_anv_us",
    icao_code: "PANV",
    iata_country_code: "US",
    iata_code: "ANV",
    city_name: "Anvik",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Anyang Airport",
    longitude: 114.425129,
    latitude: 36.523596,
    id: "arp_ayn_cn",
    icao_code: "ZHAY",
    iata_country_code: "CN",
    iata_code: "AYN",
    city_name: "Anyang",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Aomori Airport",
    longitude: 140.690831,
    latitude: 40.736132,
    id: "arp_aoj_jp",
    icao_code: "RJSA",
    iata_country_code: "JP",
    iata_code: "AOJ",
    city_name: "Aomori",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Aosta Airport",
    longitude: 7.368044,
    latitude: 45.738617,
    id: "arp_aot_it",
    icao_code: "LIMW",
    iata_country_code: "IT",
    iata_code: "AOT",
    city_name: "Aosta",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Apalachicola Regional Airport",
    longitude: -85.026096,
    latitude: 29.72695,
    id: "arp_aaf_us",
    icao_code: "KAAF",
    iata_country_code: "US",
    iata_code: "AAF",
    city_name: "Apalachicola",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Apalapsili Airport",
    longitude: 139.3108,
    latitude: -3.8832,
    id: "arp_aas_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "AAS",
    city_name: "Apalapsili",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Apataki Airport",
    longitude: -146.414483,
    latitude: -15.574028,
    id: "arp_apk_pf",
    icao_code: "NTGD",
    iata_country_code: "PF",
    iata_code: "APK",
    city_name: "Apataki",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "A.P. Hill Army Airfield",
    longitude: -77.318371,
    latitude: 38.069051,
    id: "arp_aph_us",
    icao_code: "KAPH",
    iata_country_code: "US",
    iata_code: "APH",
    city_name: "Bowling Green",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Apiay Air Base Airport",
    longitude: -73.562733,
    latitude: 4.076707,
    id: "arp_api_co",
    icao_code: "SKAP",
    iata_country_code: "CO",
    iata_code: "API",
    city_name: "Apiay",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Apolo Airport",
    longitude: -68.4117,
    latitude: -14.73641,
    id: "arp_apb_bo",
    icao_code: "SLAP",
    iata_country_code: "BO",
    iata_code: "APB",
    city_name: "Apolo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Appleton International Airport",
    longitude: -88.515119,
    latitude: 44.258977,
    id: "arp_atw_us",
    icao_code: "KATW",
    iata_country_code: "US",
    iata_code: "ATW",
    city_name: "Appleton",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Apple Valley Airport",
    longitude: -117.187235,
    latitude: 34.575699,
    id: "arp_apv_us",
    icao_code: "KAPV",
    iata_country_code: "US",
    iata_code: "APV",
    city_name: "Apple Valley",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "April River Airport",
    longitude: 142.540084,
    latitude: -4.676416,
    id: "arp_apr_pg",
    icao_code: "AYPE",
    iata_country_code: "PG",
    iata_code: "APR",
    city_name: "April River",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Apucarana Airport",
    longitude: -51.386125,
    latitude: -23.609407,
    id: "arp_apu_br",
    icao_code: "SSAP",
    iata_country_code: "BR",
    iata_code: "APU",
    city_name: "Apucarana",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Aracati - Dragão do Mar Regional Airport",
    longitude: -37.804722,
    latitude: -4.618611,
    id: "arp_arx_br",
    icao_code: "SBAC",
    iata_country_code: "BR",
    iata_code: "ARX",
    city_name: "Aracati",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Araçatuba Airport",
    longitude: -50.42505,
    latitude: -21.138829,
    id: "arp_aru_br",
    icao_code: "SBAU",
    iata_country_code: "BR",
    iata_code: "ARU",
    city_name: "Araçatuba",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Arad International Airport",
    longitude: 21.26234,
    latitude: 46.17651,
    id: "arp_arw_ro",
    icao_code: "LRAR",
    iata_country_code: "RO",
    iata_code: "ARW",
    city_name: "Arad",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Aragarças Airport",
    longitude: -52.243162,
    latitude: -15.900371,
    id: "arp_ars_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "ARS",
    city_name: "Aragarcas",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aragip Airport",
    longitude: 149.467,
    latitude: -9.9,
    id: "arp_arp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ARP",
    city_name: "Aragip",
    city: null,
  },
  {
    time_zone: "America/Araguaina",
    name: "Araguaína Airport",
    longitude: -48.241136,
    latitude: -7.22929,
    id: "arp_aux_br",
    icao_code: "SWGN",
    iata_country_code: "BR",
    iata_code: "AUX",
    city_name: "Araguaína",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Arak Airport",
    longitude: 49.845788,
    latitude: 34.136679,
    id: "arp_ajk_ir",
    icao_code: "OIHR",
    iata_country_code: "IR",
    iata_code: "AJK",
    city_name: "Arak",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Aramac Airport",
    longitude: 145.243929,
    latitude: -22.960207,
    id: "arp_axc_au",
    icao_code: "YAMC",
    iata_country_code: "AU",
    iata_code: "AXC",
    city_name: "Aramac",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Arandis Airport",
    longitude: 14.98,
    latitude: -22.462208,
    id: "arp_adi_na",
    icao_code: "FYAR",
    iata_country_code: "NA",
    iata_code: "ADI",
    city_name: "Arandis",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Aransas County Airport",
    longitude: -97.044578,
    latitude: 28.086786,
    id: "arp_rkp_us",
    icao_code: "KRKP",
    iata_country_code: "US",
    iata_code: "RKP",
    city_name: "Rockport",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Aranuka Airport",
    longitude: 173.636993,
    latitude: 0.185278,
    id: "arp_aak_ki",
    icao_code: "NGUK",
    iata_country_code: "KI",
    iata_code: "AAK",
    city_name: "Aranuka",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Arapahoe Municipal Airport",
    longitude: -99.906444,
    latitude: 40.339472,
    id: "arp_ahf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AHF",
    city_name: "Arapahoe",
    city: null,
  },
  {
    time_zone: "America/Maceio",
    name: "Arapiraca Airport",
    longitude: -37.260486,
    latitude: -9.409762,
    id: "arp_apq_br",
    icao_code: "SNAL",
    iata_country_code: "BR",
    iata_code: "APQ",
    city_name: "Arapiraca",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Arapongas Airport",
    longitude: -51.49182,
    latitude: -23.352711,
    id: "arp_apx_br",
    icao_code: "SSOG",
    iata_country_code: "BR",
    iata_code: "APX",
    city_name: "Arapongas",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Arapoti Airport",
    longitude: -49.788874,
    latitude: -24.103154,
    id: "arp_aag_br",
    icao_code: "SSYA",
    iata_country_code: "BR",
    iata_code: "AAG",
    city_name: "Arapoti",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Araracuara Airport",
    longitude: -72.4083,
    latitude: -0.5833,
    id: "arp_acr_co",
    icao_code: "SKAC",
    iata_country_code: "CO",
    iata_code: "ACR",
    city_name: "Araracuara",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Araraquara Airport",
    longitude: -48.139741,
    latitude: -21.805839,
    id: "arp_aqa_br",
    icao_code: "SBAQ",
    iata_country_code: "BR",
    iata_code: "AQA",
    city_name: "Araraquara",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Ararat Airport",
    longitude: 142.987984,
    latitude: -37.308371,
    id: "arp_ary_au",
    icao_code: "YARA",
    iata_country_code: "AU",
    iata_code: "ARY",
    city_name: "Ararat",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Arar Domestic Airport",
    longitude: 41.137932,
    latitude: 30.905679,
    id: "arp_rae_sa",
    icao_code: "OERR",
    iata_country_code: "SA",
    iata_code: "RAE",
    city_name: "Arar",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Arathusa Safari Lodge Airport",
    longitude: 31.522499,
    latitude: -24.744165,
    id: "arp_ass_za",
    icao_code: "FACC",
    iata_country_code: "ZA",
    iata_code: "ASS",
    city_name: "Sabi Sand",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Aratika-Nord Airport",
    longitude: -145.469435,
    latitude: -15.485299,
    id: "arp_rka_pf",
    icao_code: "NTKK",
    iata_country_code: "PF",
    iata_code: "RKA",
    city_name: "Aratika",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Arauca Santiago Perez Quiroz Airport",
    longitude: -70.736105,
    latitude: 7.068522,
    id: "arp_auc_co",
    icao_code: "SKUC",
    iata_country_code: "CO",
    iata_code: "AUC",
    city_name: "Arauca",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Arawa Airport",
    longitude: 155.570429,
    latitude: -6.220977,
    id: "arp_raw_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RAW",
    city_name: "Arawa",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Araxá Airport",
    longitude: -46.963124,
    latitude: -19.561486,
    id: "arp_aax_br",
    icao_code: "SBAX",
    iata_country_code: "BR",
    iata_code: "AAX",
    city_name: "Araxá",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Araxos Airport",
    longitude: 21.425666,
    latitude: 38.151091,
    id: "arp_gpa_gr",
    icao_code: "LGRX",
    iata_country_code: "GR",
    iata_code: "GPA",
    city_name: "Patras",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Arba Minch Airport",
    longitude: 37.590484,
    latitude: 6.039389,
    id: "arp_amh_et",
    icao_code: "HAAM",
    iata_country_code: "ET",
    iata_code: "AMH",
    city_name: "Arba Minch",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Arboletes Airport",
    longitude: -76.4244,
    latitude: 8.85705,
    id: "arp_aro_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ARO",
    city_name: "Arboletes",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Arcata Eureka Airport",
    longitude: -124.108273,
    latitude: 40.976146,
    id: "arp_acv_us",
    icao_code: "KACV",
    iata_country_code: "US",
    iata_code: "ACV",
    city_name: "Arcata/Eureka",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Arctic Bay Airport",
    longitude: -85.031941,
    latitude: 73.005276,
    id: "arp_yab_ca",
    icao_code: "CYAB",
    iata_country_code: "CA",
    iata_code: "YAB",
    city_name: "Arctic Bay",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Arctic Village Airport",
    longitude: -145.578995,
    latitude: 68.1147,
    id: "arp_arc_us",
    icao_code: "PARC",
    iata_country_code: "US",
    iata_code: "ARC",
    city_name: "Arctic Village",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Ardabil Airport",
    longitude: 48.422669,
    latitude: 38.324769,
    id: "arp_adu_ir",
    icao_code: "OITL",
    iata_country_code: "IR",
    iata_code: "ADU",
    city_name: "Ardabil",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Ardmore Airport",
    longitude: 174.973325,
    latitude: -37.032175,
    id: "arp_amz_nz",
    icao_code: "NZAR",
    iata_country_code: "NZ",
    iata_code: "AMZ",
    city_name: "Ardmore",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ardmore Downtown Executive Airport",
    longitude: -97.122742,
    latitude: 34.146973,
    id: "arp_ahd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AHD",
    city_name: "Ardmore",
    city: {
      name: "Ardmore",
      id: "cit_adm_us",
      iata_country_code: "US",
      iata_code: "ADM",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Ardmore Municipal Airport",
    longitude: -97.021157,
    latitude: 34.302418,
    id: "arp_adm_us",
    icao_code: "KADM",
    iata_country_code: "US",
    iata_code: "ADM",
    city_name: "Ardmore",
    city: {
      name: "Ardmore",
      id: "cit_adm_us",
      iata_country_code: "US",
      iata_code: "ADM",
    },
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Arenal Airport",
    longitude: -84.578664,
    latitude: 10.469209,
    id: "arp_fon_cr",
    icao_code: "MRAN",
    iata_country_code: "CR",
    iata_code: "FON",
    city_name: "Fortuna",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Åre Östersund Airport",
    longitude: 14.498227,
    latitude: 63.19645,
    id: "arp_osd_se",
    icao_code: "ESNZ",
    iata_country_code: "SE",
    iata_code: "OSD",
    city_name: "Östersund",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Argyle Airport",
    longitude: 128.450287,
    latitude: -16.636269,
    id: "arp_gyl_au",
    icao_code: "YARG",
    iata_country_code: "AU",
    iata_code: "GYL",
    city_name: "Argyle",
    city: null,
  },
  {
    time_zone: "America/St_Vincent",
    name: "Argyle International Airport",
    longitude: -61.150302,
    latitude: 13.159017,
    id: "arp_svd_vc",
    icao_code: "TVSA",
    iata_country_code: "VC",
    iata_code: "SVD",
    city_name: "St Vincent",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ar Horqin Airport",
    longitude: 120.153164,
    latitude: 43.906166,
    id: "arp_aeq_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "AEQ",
    city_name: "Chifeng",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Aribinda Airport",
    longitude: -0.883,
    latitude: 14.217,
    id: "arp_xar_bf",
    icao_code: "DFOY",
    iata_country_code: "BF",
    iata_code: "XAR",
    city_name: "Aribinda",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Arica Airport",
    longitude: -71.758061,
    latitude: -2.145437,
    id: "arp_acm_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ACM",
    city_name: "Arica",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Aripuanã Airport",
    longitude: -59.457695,
    latitude: -10.185135,
    id: "arp_air_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "AIR",
    city_name: "Aripuanã",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Ariquemes Airport",
    longitude: -63.048944,
    latitude: -9.885423,
    id: "arp_aqm_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "AQM",
    city_name: "Ariquemes",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Aristides Pereira International Airport",
    longitude: -22.890045,
    latitude: 16.136136,
    id: "arp_bvc_cv",
    icao_code: "GVBA",
    iata_country_code: "CV",
    iata_code: "BVC",
    city_name: "Boa Vista",
    city: null,
  },
  {
    time_zone: "Asia/Qyzylorda",
    name: "Arkalyk Airport",
    longitude: 66.952797,
    latitude: 50.3186,
    id: "arp_ayk_kz",
    icao_code: "UAUR",
    iata_country_code: "KZ",
    iata_code: "AYK",
    city_name: "Arkalyk",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Arkansas International Airport",
    longitude: -89.94529,
    latitude: 35.962747,
    id: "arp_byh_us",
    icao_code: "KBYH",
    iata_country_code: "US",
    iata_code: "BYH",
    city_name: "Blytheville",
    city: {
      name: "Blytheville",
      id: "cit_byh_us",
      iata_country_code: "US",
      iata_code: "BYH",
    },
  },
  {
    time_zone: "Africa/Niamey",
    name: "Arlit Airport",
    longitude: 7.366231,
    latitude: 18.790981,
    id: "arp_rlt_ne",
    icao_code: "DRZL",
    iata_country_code: "NE",
    iata_code: "RLT",
    city_name: "Arlit",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Arly Airport",
    longitude: 1.481356,
    latitude: 11.59724,
    id: "arp_arl_bf",
    icao_code: "DFER",
    iata_country_code: "BF",
    iata_code: "ARL",
    city_name: "Arly",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Armando Schwarck Airport",
    longitude: -66.818662,
    latitude: 6.57524,
    id: "arp_lpj_ve",
    icao_code: "SVAS",
    iata_country_code: "VE",
    iata_code: "LPJ",
    city_name: "Los Pijiguaos",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Armidale Airport",
    longitude: 151.615972,
    latitude: -30.53012,
    id: "arp_arm_au",
    icao_code: "YARM",
    iata_country_code: "AU",
    iata_code: "ARM",
    city_name: "Armidale",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Armstrong Airport",
    longitude: -88.909931,
    latitude: 50.292159,
    id: "arp_yyw_ca",
    icao_code: "CYYW",
    iata_country_code: "CA",
    iata_code: "YYW",
    city_name: "Armstrong",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Arnold Palmer Regional Airport",
    longitude: -79.406229,
    latitude: 40.274666,
    id: "arp_lbe_us",
    icao_code: "KLBE",
    iata_country_code: "US",
    iata_code: "LBE",
    city_name: "Latrobe",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aroa Airport",
    longitude: 146.80008,
    latitude: -9.017257,
    id: "arp_aoa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AOA",
    city_name: "Aroa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Arona Airport",
    longitude: 45.967,
    latitude: -6.25,
    id: "arp_aon_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AON",
    city_name: "Arona",
    city: null,
  },
  {
    time_zone: "Pacific/Bougainville",
    name: "Aropa Airport",
    longitude: 155.726138,
    latitude: -6.303904,
    id: "arp_kie_pg",
    icao_code: "AYIQ",
    iata_country_code: "PG",
    iata_code: "KIE",
    city_name: "Kieta",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Arorae Island Airport",
    longitude: 176.802697,
    latitude: -2.616156,
    id: "arp_ais_ki",
    icao_code: "NGTR",
    iata_country_code: "KI",
    iata_code: "AIS",
    city_name: "Arorae Island",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Arrabury Airport",
    longitude: 141.047836,
    latitude: -26.693014,
    id: "arp_aab_au",
    icao_code: "YARY",
    iata_country_code: "AU",
    iata_code: "AAB",
    city_name: "Arrabury",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Arrachart Airport",
    longitude: 49.292932,
    latitude: -12.350255,
    id: "arp_die_mg",
    icao_code: "FMNA",
    iata_country_code: "MG",
    iata_code: "DIE",
    city_name: "Antsiranana",
    city: null,
  },
  {
    time_zone: "America/Araguaina",
    name: "Arraias Airport",
    longitude: -46.858224,
    latitude: -12.96849,
    id: "arp_aai_br",
    icao_code: "SWRA",
    iata_country_code: "BR",
    iata_code: "AAI",
    city_name: "Arraias",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Arroyo Barril International Airport",
    longitude: -69.432519,
    latitude: 19.201595,
    id: "arp_eps_do",
    icao_code: "MDPO",
    iata_country_code: "DO",
    iata_code: "EPS",
    city_name: "Samana",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Arso Airport",
    longitude: 140.514972,
    latitude: -2.574889,
    id: "arp_arj_id",
    icao_code: "WAJA",
    iata_country_code: "ID",
    iata_code: "ARJ",
    city_name: "Arso",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Artesia Municipal Airport",
    longitude: -104.464634,
    latitude: 32.852092,
    id: "arp_ats_us",
    icao_code: "KATS",
    iata_country_code: "US",
    iata_code: "ATS",
    city_name: "Artesia",
    city: null,
  },
  {
    time_zone: "America/Port_of_Spain",
    name: "Arthur Napoleon Raymond Robinson International Airport",
    longitude: -60.834225,
    latitude: 11.150519,
    id: "arp_tab_tt",
    icao_code: "TTCP",
    iata_country_code: "TT",
    iata_code: "TAB",
    city_name: "Tobago",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Arthur N. Neu Airport",
    longitude: -94.787385,
    latitude: 42.046256,
    id: "arp_cin_us",
    icao_code: "KCIN",
    iata_country_code: "US",
    iata_code: "CIN",
    city_name: "Carroll",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Arthur's Town Airport",
    longitude: -75.674195,
    latitude: 24.629359,
    id: "arp_atc_bs",
    icao_code: "MYCA",
    iata_country_code: "BS",
    iata_code: "ATC",
    city_name: "Arthur's Town",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Artigas International Airport",
    longitude: -56.508841,
    latitude: -30.4002,
    id: "arp_ati_uy",
    icao_code: "SUAG",
    iata_country_code: "UY",
    iata_code: "ATI",
    city_name: "Artigas",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Arturo Michelena International Airport",
    longitude: -67.925569,
    latitude: 10.151977,
    id: "arp_vln_ve",
    icao_code: "SVVA",
    iata_country_code: "VE",
    iata_code: "VLN",
    city_name: "Valencia",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Arua Airport",
    longitude: 30.915441,
    latitude: 3.049724,
    id: "arp_rua_ug",
    icao_code: "HUAR",
    iata_country_code: "UG",
    iata_code: "RUA",
    city_name: "Arua",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Arugam Bay Seaplane Base Airport",
    longitude: 81.625045,
    latitude: 7.336921,
    id: "arp_ayy_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "AYY",
    city_name: "Pottuvil",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Arusha Airport",
    longitude: 36.629102,
    latitude: -3.367713,
    id: "arp_ark_tz",
    icao_code: "HTAR",
    iata_country_code: "TZ",
    iata_code: "ARK",
    city_name: "Arusha",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Arutua Airport",
    longitude: -146.617004,
    latitude: -15.248299,
    id: "arp_axr_pf",
    icao_code: "NTGU",
    iata_country_code: "PF",
    iata_code: "AXR",
    city_name: "Arutua",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Arvaikheer Airport",
    longitude: 102.802341,
    latitude: 46.25023,
    id: "arp_avk_mn",
    icao_code: "ZMAH",
    iata_country_code: "MN",
    iata_code: "AVK",
    city_name: "Arvaikheer",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Arviat Airport",
    longitude: -94.071233,
    latitude: 61.096204,
    id: "arp_yek_ca",
    icao_code: "CYEK",
    iata_country_code: "CA",
    iata_code: "YEK",
    city_name: "Arviat",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Arvidsjaur Airport",
    longitude: 19.277487,
    latitude: 65.590761,
    id: "arp_ajr_se",
    icao_code: "ESNX",
    iata_country_code: "SE",
    iata_code: "AJR",
    city_name: "Arvidsjaur",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Arxan Yi'ershi Airport",
    longitude: 119.9117,
    latitude: 47.3106,
    id: "arp_yie_cn",
    icao_code: "ZBES",
    iata_country_code: "CN",
    iata_code: "YIE",
    city_name: "Arxan",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Asaba International Airport",
    longitude: 6.660319,
    latitude: 6.20846,
    id: "arp_abb_ng",
    icao_code: "DNAS",
    iata_country_code: "NG",
    iata_code: "ABB",
    city_name: "Asaba",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Asahikawa Airport",
    longitude: 142.450703,
    latitude: 43.67087,
    id: "arp_akj_jp",
    icao_code: "RJEC",
    iata_country_code: "JP",
    iata_code: "AKJ",
    city_name: "Asahikawa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Asapa Airport",
    longitude: 148.10362,
    latitude: -8.979226,
    id: "arp_app_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "APP",
    city_name: "Asapa",
    city: null,
  },
  {
    time_zone: "Pacific/Apia",
    name: "Asau Airport",
    longitude: -172.258709,
    latitude: -13.742247,
    id: "arp_aau_ws",
    icao_code: "NSAU",
    iata_country_code: "WS",
    iata_code: "AAU",
    city_name: "Asau",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Ascención de Guarayos Airport",
    longitude: -63.155607,
    latitude: -15.932625,
    id: "arp_asc_bo",
    icao_code: "SLAS",
    iata_country_code: "BO",
    iata_code: "ASC",
    city_name: "Ascension",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aseki Airport",
    longitude: 146.193947,
    latitude: -7.350762,
    id: "arp_aek_pg",
    icao_code: "AYAX",
    iata_country_code: "PG",
    iata_code: "AEK",
    city_name: "Aseki",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Asella Airport",
    longitude: 39.00679,
    latitude: 8.715098,
    id: "arp_alk_et",
    icao_code: null,
    iata_country_code: "ET",
    iata_code: "ALK",
    city_name: "Asela",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Ashburton Aerodrome",
    longitude: 171.801044,
    latitude: -43.903487,
    id: "arp_asg_nz",
    icao_code: "NZAS",
    iata_country_code: "NZ",
    iata_code: "ASG",
    city_name: "Ashburton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Asheville Regional Airport",
    longitude: -82.539777,
    latitude: 35.435781,
    id: "arp_avl_us",
    icao_code: "KAVL",
    iata_country_code: "US",
    iata_code: "AVL",
    city_name: "Asheville",
    city: null,
  },
  {
    time_zone: "Asia/Ashgabat",
    name: "Ashgabat International Airport",
    longitude: 58.363872,
    latitude: 37.985574,
    id: "arp_asb_tm",
    icao_code: "UTAA",
    iata_country_code: "TM",
    iata_code: "ASB",
    city_name: "Ashgabat",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Ashland Municipal Airport",
    longitude: -122.658488,
    latitude: 42.189091,
    id: "arp_ahm_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AHM",
    city_name: "Ashland",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ashley Municipal Airport",
    longitude: -99.354501,
    latitude: 46.025839,
    id: "arp_asy_us",
    icao_code: "KASY",
    iata_country_code: "US",
    iata_code: "ASY",
    city_name: "Ashley",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Asirim Airport",
    longitude: 150.368783,
    latitude: -6.010074,
    id: "arp_asz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ASZ",
    city_name: "Asirim",
    city: null,
  },
  {
    time_zone: "Africa/Asmara",
    name: "Asmara International Airport",
    longitude: 38.901227,
    latitude: 15.285445,
    id: "arp_asm_er",
    icao_code: "HHAS",
    iata_country_code: "ER",
    iata_code: "ASM",
    city_name: "Asmara",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Asosa Airport",
    longitude: 34.583682,
    latitude: 10.020027,
    id: "arp_aso_et",
    icao_code: "HASO",
    iata_country_code: "ET",
    iata_code: "ASO",
    city_name: "Asosa",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Aspen-Pitkin County Airport",
    longitude: -106.867239,
    latitude: 39.221696,
    id: "arp_ase_us",
    icao_code: "KASE",
    iata_country_code: "US",
    iata_code: "ASE",
    city_name: "Aspen",
    city: null,
  },
  {
    time_zone: "Africa/Asmera",
    name: "Assab International Airport",
    longitude: 42.645292,
    latitude: 13.071742,
    id: "arp_asa_er",
    icao_code: "HHSB",
    iata_country_code: "ER",
    iata_code: "ASA",
    city_name: "Assab",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Assis Airport",
    longitude: -50.452972,
    latitude: -22.638029,
    id: "arp_aif_br",
    icao_code: "SNAX",
    iata_country_code: "BR",
    iata_code: "AIF",
    city_name: "Assis",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Assiut Airport",
    longitude: 31.014225,
    latitude: 27.048737,
    id: "arp_atz_eg",
    icao_code: "HEAT",
    iata_country_code: "EG",
    iata_code: "ATZ",
    city_name: "Asyut",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Astana International Airport",
    longitude: 71.464578,
    latitude: 51.02507,
    id: "arp_tse_kz",
    icao_code: "UACC",
    iata_country_code: "KZ",
    iata_code: "TSE",
    city_name: "Astana",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Astoria Regional Airport",
    longitude: -123.880454,
    latitude: 46.156891,
    id: "arp_ast_us",
    icao_code: "KAST",
    iata_country_code: "US",
    iata_code: "AST",
    city_name: "Astoria",
    city: null,
  },
  {
    time_zone: "Europe/Astrakhan",
    name: "Astrakhan Airport",
    longitude: 48.003115,
    latitude: 46.285333,
    id: "arp_asf_ru",
    icao_code: "URWA",
    iata_country_code: "RU",
    iata_code: "ASF",
    city_name: "Astrakhan",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Asturias Airport",
    longitude: -6.034451,
    latitude: 43.562649,
    id: "arp_ovd_es",
    icao_code: "LEAS",
    iata_country_code: "ES",
    iata_code: "OVD",
    city_name: "Asturias",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Astypalaia Island National Airport",
    longitude: 26.375071,
    latitude: 36.580764,
    id: "arp_jty_gr",
    icao_code: "LGPL",
    iata_country_code: "GR",
    iata_code: "JTY",
    city_name: "Astypalaia Island",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Aswan International Airport",
    longitude: 32.821404,
    latitude: 23.963915,
    id: "arp_asw_eg",
    icao_code: "HESN",
    iata_country_code: "EG",
    iata_code: "ASW",
    city_name: "Aswan",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Ataq Airport",
    longitude: 46.82453,
    latitude: 14.5522,
    id: "arp_axk_ye",
    icao_code: "OYAT",
    iata_country_code: "YE",
    iata_code: "AXK",
    city_name: "Ataq",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Atar International Airport",
    longitude: -13.044641,
    latitude: 20.504855,
    id: "arp_atr_mr",
    icao_code: "GQPA",
    iata_country_code: "MR",
    iata_code: "ATR",
    city_name: "Atar",
    city: null,
  },
  {
    time_zone: "Asia/Dili",
    name: "Atauro Airport",
    longitude: 125.524577,
    latitude: -8.547865,
    id: "arp_aut_tl",
    icao_code: "WPAT",
    iata_country_code: "TL",
    iata_code: "AUT",
    city_name: "Atauro",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Atbara Airport",
    longitude: 34.057072,
    latitude: 17.709779,
    id: "arp_atb_sd",
    icao_code: "HSAT",
    iata_country_code: "SD",
    iata_code: "ATB",
    city_name: "Atbara",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Atbasar Airport",
    longitude: 68.363693,
    latitude: 51.820494,
    id: "arp_atx_kz",
    icao_code: null,
    iata_country_code: "KZ",
    iata_code: "ATX",
    city_name: "Atbasar",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Athens Ben Epps Airport",
    longitude: -83.325124,
    latitude: 33.949716,
    id: "arp_ahn_us",
    icao_code: "KAHN",
    iata_country_code: "US",
    iata_code: "AHN",
    city_name: "Athens",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Athens International Airport",
    longitude: 23.946458,
    latitude: 37.936023,
    id: "arp_ath_gr",
    icao_code: "LGAV",
    iata_country_code: "GR",
    iata_code: "ATH",
    city_name: "Athens",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Ati Airport",
    longitude: 18.31339,
    latitude: 13.239701,
    id: "arp_atv_td",
    icao_code: "FTTI",
    iata_country_code: "TD",
    iata_code: "ATV",
    city_name: "Ati",
    city: null,
  },
  {
    time_zone: "America/Coral_Harbour",
    name: "Atikokan Municipal Airport",
    longitude: -91.638607,
    latitude: 48.773893,
    id: "arp_yib_ca",
    icao_code: "CYIB",
    iata_country_code: "CA",
    iata_code: "YIB",
    city_name: "Atikokan",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Atizapan De Zaragoza Airport",
    longitude: -99.290733,
    latitude: 19.573377,
    id: "arp_azp_mx",
    icao_code: "MMJC",
    iata_country_code: "MX",
    iata_code: "AZP",
    city_name: "Mexico City",
    city: {
      name: "Mexico City",
      id: "cit_mex_mx",
      iata_country_code: "MX",
      iata_code: "MEX",
    },
  },
  {
    time_zone: "America/Adak",
    name: "Atka Airport",
    longitude: -174.206087,
    latitude: 52.21957,
    id: "arp_akb_us",
    icao_code: "PAAK",
    iata_country_code: "US",
    iata_code: "AKB",
    city_name: "Atka",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Atkamba Airport",
    longitude: 141.1,
    latitude: -6.06667,
    id: "arp_abp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ABP",
    city_name: "Atkamba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Atkinson Municipal Airport",
    longitude: -94.731125,
    latitude: 37.448625,
    id: "arp_pts_us",
    icao_code: "KPTS",
    iata_country_code: "US",
    iata_code: "PTS",
    city_name: "Pittsburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Atlantic City International Airport",
    longitude: -74.574777,
    latitude: 39.455572,
    id: "arp_acy_us",
    icao_code: "KACY",
    iata_country_code: "US",
    iata_code: "ACY",
    city_name: "Atlantic City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Atlantic Municipal Airport",
    longitude: -95.04655,
    latitude: 41.405577,
    id: "arp_aio_us",
    icao_code: "KAIO",
    iata_country_code: "US",
    iata_code: "AIO",
    city_name: "Atlantic",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Atmautluak Airport",
    longitude: -162.273488,
    latitude: 60.864806,
    id: "arp_att_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ATT",
    city_name: "Atmautluak",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Atqasuk Edward Burnell Sr. Memorial Airport",
    longitude: -157.434673,
    latitude: 70.467355,
    id: "arp_atk_us",
    icao_code: "PATQ",
    iata_country_code: "US",
    iata_code: "ATK",
    city_name: "Atqasuk",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Atsinanana Airport",
    longitude: 48.803456,
    latitude: -19.584737,
    id: "arp_ilk_mg",
    icao_code: "FMMQ",
    iata_country_code: "MG",
    iata_code: "ILK",
    city_name: "Ilaka",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Atsugi Naval Air Facility Airport",
    longitude: 139.449997,
    latitude: 35.454601,
    id: "arp_nja_jp",
    icao_code: "RJTA",
    iata_country_code: "JP",
    iata_code: "NJA",
    city_name: "Atsugi",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Attawapiskat Airport",
    longitude: -82.431187,
    latitude: 52.927027,
    id: "arp_yat_ca",
    icao_code: "CYAT",
    iata_country_code: "CA",
    iata_code: "YAT",
    city_name: "Attawapiskat",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Attopeu Airport",
    longitude: 106.821706,
    latitude: 14.814715,
    id: "arp_aou_la",
    icao_code: "VLAP",
    iata_country_code: "LA",
    iata_code: "AOU",
    city_name: "Attopeu",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Atung Bungsu Airport",
    longitude: 103.378825,
    latitude: -4.023128,
    id: "arp_pxa_id",
    icao_code: "WIPY",
    iata_country_code: "ID",
    iata_code: "PXA",
    city_name: "Pagar Alam",
    city: null,
  },
  {
    time_zone: "Pacific/Marquesas",
    name: "Atuona Airport",
    longitude: -139.011117,
    latitude: -9.76892,
    id: "arp_auq_pf",
    icao_code: "NTMN",
    iata_country_code: "PF",
    iata_code: "AUQ",
    city_name: "Atuona",
    city: null,
  },
  {
    time_zone: "Asia/Atyrau",
    name: "Atyrau Airport",
    longitude: 51.822877,
    latitude: 47.121358,
    id: "arp_guw_kz",
    icao_code: "UATG",
    iata_country_code: "KZ",
    iata_code: "GUW",
    city_name: "Atyrau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aua Island Airport",
    longitude: 143.064712,
    latitude: -1.463448,
    id: "arp_aui_pg",
    icao_code: "AYND",
    iata_country_code: "PG",
    iata_code: "AUI",
    city_name: "Aua Island",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Aubenas-Ardèche Méridional Airport",
    longitude: 4.371134,
    latitude: 44.542755,
    id: "arp_obs_fr",
    icao_code: "LFHO",
    iata_country_code: "FR",
    iata_code: "OBS",
    city_name: "Aubenas",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Auburn/Lewiston Municipal Airport",
    longitude: -70.285278,
    latitude: 44.048648,
    id: "arp_lew_us",
    icao_code: "KLEW",
    iata_country_code: "US",
    iata_code: "LEW",
    city_name: "Auburn/Lewiston",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Auburn Municipal Airport",
    longitude: -121.081794,
    latitude: 38.953973,
    id: "arp_aun_us",
    icao_code: "KAUN",
    iata_country_code: "US",
    iata_code: "AUN",
    city_name: "Auburn",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Auburn University Regional Airport",
    longitude: -85.433987,
    latitude: 32.614638,
    id: "arp_auo_us",
    icao_code: "KAUO",
    iata_country_code: "US",
    iata_code: "AUO",
    city_name: "Auburn",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Auckland Airport",
    longitude: 174.789684,
    latitude: -37.008148,
    id: "arp_akl_nz",
    icao_code: "NZAA",
    iata_country_code: "NZ",
    iata_code: "AKL",
    city_name: "Auckland",
    city: {
      name: "Auckland",
      id: "cit_akl_nz",
      iata_country_code: "NZ",
      iata_code: "AKL",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Augsburg Airport",
    longitude: 10.932054,
    latitude: 48.424707,
    id: "arp_agb_de",
    icao_code: "EDMA",
    iata_country_code: "DE",
    iata_code: "AGB",
    city_name: "Augsburg",
    city: {
      name: "Munich",
      id: "cit_muc_de",
      iata_country_code: "DE",
      iata_code: "MUC",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Augusta Regional Airport",
    longitude: -81.96675,
    latitude: 33.370604,
    id: "arp_ags_us",
    icao_code: "KAGS",
    iata_country_code: "US",
    iata_code: "AGS",
    city_name: "Augusta",
    city: {
      name: "Augusta",
      id: "cit_ags_us",
      iata_country_code: "US",
      iata_code: "AGS",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Augusta State Airport",
    longitude: -69.796985,
    latitude: 44.320035,
    id: "arp_aug_us",
    icao_code: "KAUG",
    iata_country_code: "US",
    iata_code: "AUG",
    city_name: "Augusta",
    city: null,
  },
  {
    time_zone: "America/Tortola",
    name: "Auguste George Airport",
    longitude: -64.329829,
    latitude: 18.727303,
    id: "arp_ngd_vg",
    icao_code: "TUPA",
    iata_country_code: "VG",
    iata_code: "NGD",
    city_name: "Anegada",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Augusto Cesar Sandino International Airport",
    longitude: -86.169011,
    latitude: 12.14233,
    id: "arp_mga_ni",
    icao_code: "MNMG",
    iata_country_code: "NI",
    iata_code: "MGA",
    city_name: "Managua",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Augustus Downs Airport",
    longitude: 139.880087,
    latitude: -18.511954,
    id: "arp_aud_au",
    icao_code: "YAGD",
    iata_country_code: "AU",
    iata_code: "AUD",
    city_name: "Augustus Downs",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Auki Gwaunaru'u Airport",
    longitude: 160.681908,
    latitude: -8.702563,
    id: "arp_aks_sb",
    icao_code: "AGGA",
    iata_country_code: "SB",
    iata_code: "AKS",
    city_name: "Auki",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Aumo Airport",
    longitude: 148.445439,
    latitude: -5.728378,
    id: "arp_auv_pg",
    icao_code: "AYUM",
    iata_country_code: "PG",
    iata_code: "AUV",
    city_name: "Aumo",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Aupaluk Airport",
    longitude: -69.599747,
    latitude: 59.296625,
    id: "arp_ypj_ca",
    icao_code: "CYLA",
    iata_country_code: "CA",
    iata_code: "YPJ",
    city_name: "Aupaluk",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Aurangabad Airport",
    longitude: 75.397024,
    latitude: 19.865054,
    id: "arp_ixu_in",
    icao_code: "VAAU",
    iata_country_code: "IN",
    iata_code: "IXU",
    city_name: "Aurangabad",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Aurillac – Tronquières Airport",
    longitude: 2.421305,
    latitude: 44.894597,
    id: "arp_aur_fr",
    icao_code: "LFLW",
    iata_country_code: "FR",
    iata_code: "AUR",
    city_name: "Aurillac",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Aur Island Airport",
    longitude: 171.161087,
    latitude: 8.302438,
    id: "arp_aul_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "AUL",
    city_name: "Aur Atoll",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Aurora Municipal Airport",
    longitude: -88.470758,
    latitude: 41.770618,
    id: "arp_auz_us",
    icao_code: "KARR",
    iata_country_code: "US",
    iata_code: "AUZ",
    city_name: "Aurora",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Aurukun Airport",
    longitude: 141.721139,
    latitude: -13.354701,
    id: "arp_auu_au",
    icao_code: "YAUR",
    iata_country_code: "AU",
    iata_code: "AUU",
    city_name: "Aurukun",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Austin Airport",
    longitude: -97.666525,
    latitude: 30.196905,
    id: "arp_asq_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ASQ",
    city_name: "Austin",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Austin-Bergstrom International Airport",
    longitude: -97.667519,
    latitude: 30.196466,
    id: "arp_aus_us",
    icao_code: "KAUS",
    iata_country_code: "US",
    iata_code: "AUS",
    city_name: "Austin",
    city: {
      name: "Austin",
      id: "cit_aus_us",
      iata_country_code: "US",
      iata_code: "AUS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Austin Municipal Airport",
    longitude: -92.93507,
    latitude: 43.666591,
    id: "arp_aum_us",
    icao_code: "KAUM",
    iata_country_code: "US",
    iata_code: "AUM",
    city_name: "Austin",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Austral Downs Airport",
    longitude: 137.76628,
    latitude: -20.494323,
    id: "arp_awp_au",
    icao_code: "YAUS",
    iata_country_code: "AU",
    iata_code: "AWP",
    city_name: "Austral Downs Station",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Auvergne Airport",
    longitude: 3.167821,
    latitude: 45.786966,
    id: "arp_avg_fr",
    icao_code: "YAUV",
    iata_country_code: "FR",
    iata_code: "AVG",
    city_name: "Auvergne Station",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Auxerre-Branches Airport",
    longitude: 3.497237,
    latitude: 47.849218,
    id: "arp_auf_fr",
    icao_code: "LFLA",
    iata_country_code: "FR",
    iata_code: "AUF",
    city_name: "Auxerre",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Avalon Airport",
    longitude: 144.468824,
    latitude: -38.039198,
    id: "arp_avv_au",
    icao_code: "YMAV",
    iata_country_code: "AU",
    iata_code: "AVV",
    city_name: "Greater Geelong",
    city: {
      name: "Melbourne",
      id: "cit_mel_au",
      iata_country_code: "AU",
      iata_code: "MEL",
    },
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Avaratra Airport",
    longitude: 48.483002,
    latitude: -15.2,
    id: "arp_wbd_mg",
    icao_code: "FMNF",
    iata_country_code: "MG",
    iata_code: "WBD",
    city_name: "Befandriana",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Avenger Field",
    longitude: -100.46715,
    latitude: 32.465136,
    id: "arp_sww_us",
    icao_code: "KSWW",
    iata_country_code: "US",
    iata_code: "SWW",
    city_name: "Sweetwater",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Aviador Carlos Campos Airport",
    longitude: -71.137273,
    latitude: -40.075359,
    id: "arp_cpc_ar",
    icao_code: "SAZY",
    iata_country_code: "AR",
    iata_code: "CPC",
    city_name: "San Martin de Los Andes",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Aviano Air Base",
    longitude: 12.596503,
    latitude: 46.031898,
    id: "arp_avb_it",
    icao_code: "LIPA",
    iata_country_code: "IT",
    iata_code: "AVB",
    city_name: "Aviano",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Avignon Provence Airport",
    longitude: 4.900286,
    latitude: 43.905434,
    id: "arp_avn_fr",
    icao_code: "LFMV",
    iata_country_code: "FR",
    iata_code: "AVN",
    city_name: "Avignon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Avon Park Executive Airport",
    longitude: -81.527691,
    latitude: 27.592146,
    id: "arp_avo_us",
    icao_code: "KAVO",
    iata_country_code: "US",
    iata_code: "AVO",
    city_name: "Avon Park",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Avram Iancu Cluj International Airport",
    longitude: 23.686859,
    latitude: 46.783742,
    id: "arp_clj_ro",
    icao_code: "LRCL",
    iata_country_code: "RO",
    iata_code: "CLJ",
    city_name: "Cluj-Napoca",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Avu Avu Airport",
    longitude: 160.384785,
    latitude: -9.84087,
    id: "arp_avu_sb",
    icao_code: "AGGJ",
    iata_country_code: "SB",
    iata_code: "AVU",
    city_name: "Avu Avu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Awaba Airport",
    longitude: 142.748,
    latitude: -8.00611,
    id: "arp_awb_pg",
    icao_code: "AYAW",
    iata_country_code: "PG",
    iata_code: "AWB",
    city_name: "Awaba",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Awang Airport",
    longitude: 124.212266,
    latitude: 7.164214,
    id: "arp_cbo_ph",
    icao_code: "RPMC",
    iata_country_code: "PH",
    iata_code: "CBO",
    city_name: "Cotabato City",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Awar Airport",
    longitude: 144.836275,
    latitude: -4.118553,
    id: "arp_awr_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AWR",
    city_name: "Awar",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Awasa Airport",
    longitude: 38.397403,
    latitude: 7.094857,
    id: "arp_awa_et",
    icao_code: "HALA",
    iata_country_code: "ET",
    iata_code: "AWA",
    city_name: "Awasa",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Axum Airport",
    longitude: 38.773457,
    latitude: 14.145999,
    id: "arp_axu_et",
    icao_code: "HAAX",
    iata_country_code: "ET",
    iata_code: "AXU",
    city_name: "Axum",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Ayacucho Airport",
    longitude: -73.612091,
    latitude: 8.602266,
    id: "arp_ayc_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "AYC",
    city_name: "Ayacucho",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Ayapel Airport",
    longitude: -75.149573,
    latitude: 8.296053,
    id: "arp_aya_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "AYA",
    city_name: "Ayapel",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Ayawasi Airport",
    longitude: 132.463953,
    latitude: -1.159554,
    id: "arp_ayw_id",
    icao_code: "WASA",
    iata_country_code: "ID",
    iata_code: "AYW",
    city_name: "Ayawasi",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Ayers Rock Airport",
    longitude: 130.976275,
    latitude: -25.188379,
    id: "arp_ayq_au",
    icao_code: "YAYE",
    iata_country_code: "AU",
    iata_code: "AYQ",
    city_name: "Ayers Rock",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Ayr Airport",
    longitude: 147.327668,
    latitude: -19.588681,
    id: "arp_ayr_au",
    icao_code: "YAYR",
    iata_country_code: "AU",
    iata_code: "AYR",
    city_name: "Ayr",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Azaza Airport",
    longitude: 35.30644,
    latitude: 14.130576,
    id: "arp_gsu_sd",
    icao_code: "HSGF",
    iata_country_code: "SD",
    iata_code: "GSU",
    city_name: "Gedaref",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Babo Airport",
    longitude: 133.438596,
    latitude: -2.534693,
    id: "arp_bxb_id",
    icao_code: "WASO",
    iata_country_code: "ID",
    iata_code: "BXB",
    city_name: "Babo",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Bacacheri Airport",
    longitude: -49.231986,
    latitude: -25.403816,
    id: "arp_bfh_br",
    icao_code: "SBBI",
    iata_country_code: "BR",
    iata_code: "BFH",
    city_name: "Curitiba",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Bacău International Airport",
    longitude: 26.908163,
    latitude: 46.53139,
    id: "arp_bcm_ro",
    icao_code: "LRBC",
    iata_country_code: "RO",
    iata_code: "BCM",
    city_name: "Bacău",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Bacha Khan International Airport",
    longitude: 71.515714,
    latitude: 33.992877,
    id: "arp_pew_pk",
    icao_code: "OPPS",
    iata_country_code: "PK",
    iata_code: "PEW",
    city_name: "Peshawar",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Bachigualato Federal International Airport",
    longitude: -107.475843,
    latitude: 24.763761,
    id: "arp_cul_mx",
    icao_code: "MMCL",
    iata_country_code: "MX",
    iata_code: "CUL",
    city_name: "Culiacán",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Baco Airport",
    longitude: 36.562103,
    latitude: 5.783125,
    id: "arp_bco_et",
    icao_code: "HABC",
    iata_country_code: "ET",
    iata_code: "BCO",
    city_name: "Baco",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Bacolod-Silay International Airport",
    longitude: 123.015825,
    latitude: 10.776583,
    id: "arp_bcd_ph",
    icao_code: "RPVB",
    iata_country_code: "PH",
    iata_code: "BCD",
    city_name: "Bacolod",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Badajoz Airport",
    longitude: -6.821012,
    latitude: 38.891896,
    id: "arp_bjz_es",
    icao_code: "LEBZ",
    iata_country_code: "ES",
    iata_code: "BJZ",
    city_name: "Badajoz",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Bade Airport",
    longitude: 139.583444,
    latitude: -7.176094,
    id: "arp_bxd_id",
    icao_code: "WAKE",
    iata_country_code: "ID",
    iata_code: "BXD",
    city_name: "Bade",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Badu Island Airport",
    longitude: 142.1734,
    latitude: -10.149999,
    id: "arp_bdd_au",
    icao_code: "YBAU",
    iata_country_code: "AU",
    iata_code: "BDD",
    city_name: "Badu Island",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Bafoussam Airport",
    longitude: 10.354473,
    latitude: 5.536983,
    id: "arp_bfx_cm",
    icao_code: "FKKU",
    iata_country_code: "CM",
    iata_code: "BFX",
    city_name: "Bafoussam",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Baganga Airport",
    longitude: 126.569007,
    latitude: 7.611886,
    id: "arp_bnq_ph",
    icao_code: "RPUZ",
    iata_country_code: "PH",
    iata_code: "BNQ",
    city_name: "Baganga",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Bagani Airport",
    longitude: 21.622292,
    latitude: -18.119027,
    id: "arp_bqi_ml",
    icao_code: null,
    iata_country_code: "ML",
    iata_code: "BQI",
    city_name: "Bagani",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Bagdad Airport",
    longitude: -113.170937,
    latitude: 34.59393,
    id: "arp_bgt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BGT",
    city_name: "Bagdad",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Bagdogra Airport",
    longitude: 88.328092,
    latitude: 26.682236,
    id: "arp_ixb_in",
    icao_code: "VEBD",
    iata_country_code: "IN",
    iata_code: "IXB",
    city_name: "Bagdogra",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Baghdad International Airport",
    longitude: 44.233734,
    latitude: 33.263638,
    id: "arp_bgw_iq",
    icao_code: "ORBI",
    iata_country_code: "IQ",
    iata_code: "BGW",
    city_name: "Baghdad",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Baglung Airport",
    longitude: 83.666343,
    latitude: 28.213704,
    id: "arp_bgl_np",
    icao_code: "VNBL",
    iata_country_code: "NP",
    iata_code: "BGL",
    city_name: "Baglung",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Bagram Airfield Airport",
    longitude: 69.267777,
    latitude: 34.945455,
    id: "arp_oai_af",
    icao_code: "OAIX",
    iata_country_code: "AF",
    iata_code: "OAI",
    city_name: "Bagram",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Bahawalpur Airport",
    longitude: 71.714577,
    latitude: 29.349086,
    id: "arp_bhv_pk",
    icao_code: "OPBW",
    iata_country_code: "PK",
    iata_code: "BHV",
    city_name: "Bahawalpur",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Bahía Cupica Airport",
    longitude: -77.394932,
    latitude: 6.202222,
    id: "arp_bhf_co",
    icao_code: "SKCP",
    iata_country_code: "CO",
    iata_code: "BHF",
    city_name: "Bahía Cupica",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "Bahía de los Ángeles Airport",
    longitude: -113.56103,
    latitude: 28.977206,
    id: "arp_bhl_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "BHL",
    city_name: "Bahía de los Ángeles",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Bahía Negra Airport",
    longitude: -58.170934,
    latitude: -20.232601,
    id: "arp_bfa_py",
    icao_code: "SGBN",
    iata_country_code: "PY",
    iata_code: "BFA",
    city_name: "Bahía Negra",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Bahía Piña Airport",
    longitude: -78.181203,
    latitude: 7.584632,
    id: "arp_bfq_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "BFQ",
    city_name: "Bahia Pinas",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Bahías de Huatulco International Airport",
    longitude: -96.260746,
    latitude: 15.773976,
    id: "arp_hux_mx",
    icao_code: "MMBT",
    iata_country_code: "MX",
    iata_code: "HUX",
    city_name: "Huatulco",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Bahir Dar Airport",
    longitude: 37.320995,
    latitude: 11.606221,
    id: "arp_bjr_et",
    icao_code: "HABD",
    iata_country_code: "ET",
    iata_code: "BJR",
    city_name: "Bahir Dar",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Bahja Airport",
    longitude: 56.067284,
    latitude: 19.872864,
    id: "arp_bjq_om",
    icao_code: null,
    iata_country_code: "OM",
    iata_code: "BJQ",
    city_name: "Bahja",
    city: null,
  },
  {
    time_zone: "Asia/Bahrain",
    name: "Bahrain International Airport",
    longitude: 50.629557,
    latitude: 26.2699,
    id: "arp_bah_bh",
    icao_code: "OBBI",
    iata_country_code: "BH",
    iata_code: "BAH",
    city_name: "Bahrain",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bahregan Airport",
    longitude: 50.272405,
    latitude: 29.840435,
    id: "arp_iaq_ir",
    icao_code: "OIBH",
    iata_country_code: "IR",
    iata_code: "IAQ",
    city_name: "Bahregan",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Baia Mare Airport",
    longitude: 23.467949,
    latitude: 47.660249,
    id: "arp_bay_ro",
    icao_code: "LRBM",
    iata_country_code: "RO",
    iata_code: "BAY",
    city_name: "Baia Mare",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Baibara Airport",
    longitude: 149.633333,
    latitude: -10.333333,
    id: "arp_bap_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BAP",
    city_name: "Baibara",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Baicheng Chang'an Airport",
    longitude: 123.019722,
    latitude: 45.505278,
    id: "arp_dbc_cn",
    icao_code: "ZYBA",
    iata_country_code: "CN",
    iata_code: "DBC",
    city_name: "Baicheng",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Baidoa Airport",
    longitude: 43.628049,
    latitude: 3.102128,
    id: "arp_bib_so",
    icao_code: "HCMB",
    iata_country_code: "SO",
    iata_code: "BIB",
    city_name: "Baidoa",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Baie-Comeau Airport",
    longitude: -68.203036,
    latitude: 49.133903,
    id: "arp_ybc_ca",
    icao_code: "CYBC",
    iata_country_code: "CA",
    iata_code: "YBC",
    city_name: "Baie-Comeau",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Baie-Johan-Beetz Seaplane Base Airport",
    longitude: -62.809795,
    latitude: 50.283659,
    id: "arp_ybj_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YBJ",
    city_name: "Baie-Johan-Beetz",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Baikal International Airport",
    longitude: 107.440168,
    latitude: 51.806632,
    id: "arp_uud_ru",
    icao_code: "UIUU",
    iata_country_code: "RU",
    iata_code: "UUD",
    city_name: "Ulan-Ude",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Bailing Airport",
    longitude: 113.299004,
    latitude: 23.392401,
    id: "arp_bkv_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "BKV",
    city_name: "Damao Banner",
    city: null,
  },
  {
    time_zone: "America/Guadeloupe",
    name: "Baillif Airport",
    longitude: -61.742595,
    latitude: 16.013508,
    id: "arp_bbr_gp",
    icao_code: "TFFB",
    iata_country_code: "GP",
    iata_code: "BBR",
    city_name: "Basse-Terre",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Baimuru Airport",
    longitude: 144.821601,
    latitude: -7.495653,
    id: "arp_vmu_pg",
    icao_code: "AYBA",
    iata_country_code: "PG",
    iata_code: "VMU",
    city_name: "Baimuru",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Baindoung Airport",
    longitude: 146.95,
    latitude: -6.33333,
    id: "arp_bdz_pg",
    icao_code: "AYBG",
    iata_country_code: "PG",
    iata_code: "BDZ",
    city_name: "Baindoung",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Bairnsdale Airport",
    longitude: 147.567628,
    latitude: -37.884926,
    id: "arp_bsj_au",
    icao_code: "YBNS",
    iata_country_code: "AU",
    iata_code: "BSJ",
    city_name: "Bairnsdale",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Baise Bama Airport",
    longitude: 106.962589,
    latitude: 23.720223,
    id: "arp_aeb_cn",
    icao_code: "ZGBS",
    iata_country_code: "CN",
    iata_code: "AEB",
    city_name: "Baise",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Baitadi Airport",
    longitude: 80.549475,
    latitude: 29.464847,
    id: "arp_bit_np",
    icao_code: "VNBT",
    iata_country_code: "NP",
    iata_code: "BIT",
    city_name: "Baitadi",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Bajawa Soa Airport",
    longitude: 121.057678,
    latitude: -8.708041,
    id: "arp_bjw_id",
    icao_code: "WATB",
    iata_country_code: "ID",
    iata_code: "BJW",
    city_name: "Bajawa",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Bajhang Airport",
    longitude: 81.184468,
    latitude: 29.538717,
    id: "arp_bjh_np",
    icao_code: "VNBG",
    iata_country_code: "NP",
    iata_code: "BJH",
    city_name: "Bajhang",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Bajone Airport",
    longitude: 37.949185,
    latitude: -17.183754,
    id: "arp_bjn_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "BJN",
    city_name: "Bajone",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Bajura Airport",
    longitude: 81.66852,
    latitude: 29.502491,
    id: "arp_bju_np",
    icao_code: "VNBR",
    iata_country_code: "NP",
    iata_code: "BJU",
    city_name: "Bajura",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Bakalalan Airport",
    longitude: 115.618357,
    latitude: 3.974205,
    id: "arp_bkm_my",
    icao_code: "WBGQ",
    iata_country_code: "MY",
    iata_code: "BKM",
    city_name: "Bakalalan",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Bakel Airport",
    longitude: -12.468177,
    latitude: 14.847399,
    id: "arp_bxe_sn",
    icao_code: "GOTB",
    iata_country_code: "SN",
    iata_code: "BXE",
    city_name: "Bakel",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Baker City Municipal Airport",
    longitude: -117.809296,
    latitude: 44.837574,
    id: "arp_bke_us",
    icao_code: "KBKE",
    iata_country_code: "US",
    iata_code: "BKE",
    city_name: "Baker City",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Baker Lake Airport",
    longitude: -96.077325,
    latitude: 64.300275,
    id: "arp_ybk_ca",
    icao_code: "CYBK",
    iata_country_code: "CA",
    iata_code: "YBK",
    city_name: "Baker Lake",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Bakkafjörður Airport",
    longitude: -14.825011,
    latitude: 66.021984,
    id: "arp_bjd_is",
    icao_code: "BIBK",
    iata_country_code: "IS",
    iata_code: "BJD",
    city_name: "Bakkafjörður",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bakouma Airport",
    longitude: 22.80054,
    latitude: 5.694222,
    id: "arp_bmf_cf",
    icao_code: "FEGM",
    iata_country_code: "CF",
    iata_code: "BMF",
    city_name: "Bakouma",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Balakovo Airport",
    longitude: 47.745601,
    latitude: 51.858299,
    id: "arp_bwo_ru",
    icao_code: "UWSB",
    iata_country_code: "RU",
    iata_code: "BWO",
    city_name: "Balakovo",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Balalae Airport",
    longitude: 155.889341,
    latitude: -6.989416,
    id: "arp_bas_sb",
    icao_code: "AGGE",
    iata_country_code: "SB",
    iata_code: "BAS",
    city_name: "Ballalae",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Balcanoona Airport",
    longitude: 139.33621,
    latitude: -30.535561,
    id: "arp_lcn_au",
    icao_code: "YBLC",
    iata_country_code: "AU",
    iata_code: "LCN",
    city_name: "Balcanoona",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Baldwin County Airport",
    longitude: -83.24064,
    latitude: 33.153609,
    id: "arp_mlj_us",
    icao_code: "KMLJ",
    iata_country_code: "US",
    iata_code: "MLJ",
    city_name: "Milledgeville",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Baleela Airport",
    longitude: 28.553991,
    latitude: 11.222374,
    id: "arp_bje_sd",
    icao_code: null,
    iata_country_code: "SD",
    iata_code: "BJE",
    city_name: "Baleela",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Balesin Island Airport",
    longitude: 122.036058,
    latitude: 14.415315,
    id: "arp_bsi_ph",
    icao_code: "RPLE",
    iata_country_code: "PH",
    iata_code: "BSI",
    city_name: "Polilio",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Balgo Hill Airport",
    longitude: 127.974477,
    latitude: -20.148701,
    id: "arp_bqw_au",
    icao_code: "YBGO",
    iata_country_code: "AU",
    iata_code: "BQW",
    city_name: "Balgo Hill",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bali Airport",
    longitude: 149.122883,
    latitude: -4.883852,
    id: "arp_baj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BAJ",
    city_name: "Bali",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Bali Airport",
    longitude: 10.033685,
    latitude: 5.894838,
    id: "arp_blc_cm",
    icao_code: "FKKG",
    iata_country_code: "CM",
    iata_code: "BLC",
    city_name: "Bali",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Balikesir Airport",
    longitude: 27.925881,
    latitude: 39.617562,
    id: "arp_bzi_tr",
    icao_code: "LTBF",
    iata_country_code: "TR",
    iata_code: "BZI",
    city_name: "Balikesir",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Balimo Airport",
    longitude: 142.932998,
    latitude: -8.05,
    id: "arp_opu_pg",
    icao_code: "AYBM",
    iata_country_code: "PG",
    iata_code: "OPU",
    city_name: "Balimo",
    city: null,
  },
  {
    time_zone: "Asia/Ashgabat",
    name: "Balkanabat Airport",
    longitude: 54.366056,
    latitude: 39.480577,
    id: "arp_bkn_tm",
    icao_code: null,
    iata_country_code: "TM",
    iata_code: "BKN",
    city_name: "Balkanabat",
    city: null,
  },
  {
    time_zone: "Asia/Qyzylorda",
    name: "Balkhash Airport",
    longitude: 75.00445,
    latitude: 46.892456,
    id: "arp_bxh_kz",
    icao_code: "UAAH",
    iata_country_code: "KZ",
    iata_code: "BXH",
    city_name: "Balkhash",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Ballera Airport",
    longitude: 141.812039,
    latitude: -27.403954,
    id: "arp_bbl_au",
    icao_code: "YLLE",
    iata_country_code: "AU",
    iata_code: "BBL",
    city_name: "Ballera",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Ballina Byron Gateway Airport",
    longitude: 153.55916,
    latitude: -28.835756,
    id: "arp_bnk_au",
    icao_code: "YBNA",
    iata_country_code: "AU",
    iata_code: "BNK",
    city_name: "Ballina",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Ballykelly Airport",
    longitude: -7.019185,
    latitude: 55.054131,
    id: "arp_bol_gb",
    icao_code: "EGQB",
    iata_country_code: "GB",
    iata_code: "BOL",
    city_name: "Ballykelly",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Balmaceda Airport",
    longitude: -71.690661,
    latitude: -45.915439,
    id: "arp_bba_cl",
    icao_code: "SCBA",
    iata_country_code: "CL",
    iata_code: "BBA",
    city_name: "Balmaceda",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Balranald Airport",
    longitude: 143.577751,
    latitude: -34.622716,
    id: "arp_bzd_au",
    icao_code: "YBRN",
    iata_country_code: "AU",
    iata_code: "BZD",
    city_name: "Balranald",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Balsas Airport",
    longitude: -46.051913,
    latitude: -7.526445,
    id: "arp_bss_br",
    icao_code: "SNBS",
    iata_country_code: "BR",
    iata_code: "BSS",
    city_name: "Balsas",
    city: null,
  },
  {
    time_zone: "Europe/Chisinau",
    name: "Bălți International Airport",
    longitude: 27.777185,
    latitude: 47.844464,
    id: "arp_bzy_md",
    icao_code: "LUBL",
    iata_country_code: "MD",
    iata_code: "BZY",
    city_name: "Balti",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Baltimore Washington International Thurgood Marshall Airport",
    longitude: -76.668345,
    latitude: 39.176402,
    id: "arp_bwi_us",
    icao_code: "KBWI",
    iata_country_code: "US",
    iata_code: "BWI",
    city_name: "Baltimore",
    city: {
      name: "Baltimore",
      id: "cit_bwi_us",
      iata_country_code: "US",
      iata_code: "BWI",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Baltrum Airport",
    longitude: 7.372146,
    latitude: 53.724657,
    id: "arp_bmr_de",
    icao_code: "EDWZ",
    iata_country_code: "DE",
    iata_code: "BMR",
    city_name: "Baltrum",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Balurghat Airport",
    longitude: 88.796313,
    latitude: 25.262478,
    id: "arp_rgh_in",
    icao_code: "VEBG",
    iata_country_code: "IN",
    iata_code: "RGH",
    city_name: "Balurghat",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bam Airport",
    longitude: 58.449902,
    latitude: 29.08281,
    id: "arp_bxr_ir",
    icao_code: "OIKM",
    iata_country_code: "IR",
    iata_code: "BXR",
    city_name: "Bam",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Bamako-Sénou International Airport",
    longitude: -7.948537,
    latitude: 12.537414,
    id: "arp_bko_ml",
    icao_code: "GABS",
    iata_country_code: "ML",
    iata_code: "BKO",
    city_name: "Bamako",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Bamarni Airport",
    longitude: 43.270101,
    latitude: 37.098576,
    id: "arp_bmn_iq",
    icao_code: "ORBB",
    iata_country_code: "IQ",
    iata_code: "BMN",
    city_name: "Bamarni",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bambari Airport",
    longitude: 20.648993,
    latitude: 5.846719,
    id: "arp_bby_cf",
    icao_code: "FEFM",
    iata_country_code: "CF",
    iata_code: "BBY",
    city_name: "Bambari",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bambu Airport",
    longitude: 146.492076,
    latitude: -5.863664,
    id: "arp_bcp_pg",
    icao_code: "AYBC",
    iata_country_code: "PG",
    iata_code: "BCP",
    city_name: "Bambu",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Bamburi Airport",
    longitude: 39.731104,
    latitude: -3.980495,
    id: "arp_bmq_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "BMQ",
    city_name: "Bamburi",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Bamenda Airport",
    longitude: 10.121958,
    latitude: 6.037359,
    id: "arp_bpc_cm",
    icao_code: "FKKV",
    iata_country_code: "CM",
    iata_code: "BPC",
    city_name: "Bamenda",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Bamfield Airport",
    longitude: -125.11938,
    latitude: 48.820662,
    id: "arp_ybf_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YBF",
    city_name: "Bamfield",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bamu Airport",
    longitude: 143.242774,
    latitude: -7.848032,
    id: "arp_bmz_pg",
    icao_code: "AYBF",
    iata_country_code: "PG",
    iata_code: "BMZ",
    city_name: "Bamu",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Bamyan Airport",
    longitude: 7.828122,
    latitude: 34.812737,
    id: "arp_bin_af",
    icao_code: "OABN",
    iata_country_code: "AF",
    iata_code: "BIN",
    city_name: "Bamyan",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Banaina Airport",
    longitude: 117.126,
    latitude: 2.72305,
    id: "arp_naf_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "NAF",
    city_name: "Banaina",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Bancasi Airport",
    longitude: 125.479464,
    latitude: 8.950783,
    id: "arp_bxu_ph",
    icao_code: "RPME",
    iata_country_code: "PH",
    iata_code: "BXU",
    city_name: "Butuan",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Bandanaira Airport",
    longitude: 129.905137,
    latitude: -4.521311,
    id: "arp_nda_id",
    icao_code: "WAPC",
    iata_country_code: "ID",
    iata_code: "NDA",
    city_name: "Banda Island",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bandar Abbas International Airport",
    longitude: 56.369121,
    latitude: 27.208582,
    id: "arp_bnd_ir",
    icao_code: "OIKB",
    iata_country_code: "IR",
    iata_code: "BND",
    city_name: "Bandar Abbas",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Bandaranaike International Airport",
    longitude: 79.884176,
    latitude: 7.180457,
    id: "arp_cmb_lk",
    icao_code: "VCBI",
    iata_country_code: "LK",
    iata_code: "CMB",
    city_name: "Colombo",
    city: {
      name: "Colombo",
      id: "cit_cmb_lk",
      iata_country_code: "LK",
      iata_code: "CMB",
    },
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bandar Lengeh Airport",
    longitude: 54.826274,
    latitude: 26.531643,
    id: "arp_bdh_ir",
    icao_code: "OIBL",
    iata_country_code: "IR",
    iata_code: "BDH",
    city_name: "Bandar Lengeh",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Bandırma Airport",
    longitude: 27.977641,
    latitude: 40.319383,
    id: "arp_bdm_tr",
    icao_code: "LTBG",
    iata_country_code: "TR",
    iata_code: "BDM",
    city_name: "Bandirma",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bandon State Airport",
    longitude: -124.409114,
    latitude: 43.084606,
    id: "arp_bdy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BDY",
    city_name: "Bandon",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Bandundu Airport",
    longitude: 17.381674,
    latitude: -3.311365,
    id: "arp_fdu_cd",
    icao_code: "FZBO",
    iata_country_code: "CD",
    iata_code: "FDU",
    city_name: "Bandundu",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Băneasa International Airport",
    longitude: 26.101912,
    latitude: 44.502897,
    id: "arp_bbu_ro",
    icao_code: "LRBS",
    iata_country_code: "RO",
    iata_code: "BBU",
    city_name: "Bucharest",
    city: {
      name: "Bucharest",
      id: "cit_buh_ro",
      iata_country_code: "RO",
      iata_code: "BUH",
    },
  },
  {
    time_zone: "America/Edmonton",
    name: "Banff Airport",
    longitude: -115.541861,
    latitude: 51.20734,
    id: "arp_yba_ca",
    icao_code: "CYBA",
    iata_country_code: "CA",
    iata_code: "YBA",
    city_name: "Banff",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Banfora Airport",
    longitude: -4.728297,
    latitude: 10.687238,
    id: "arp_bnr_bf",
    icao_code: "DFOB",
    iata_country_code: "BF",
    iata_code: "BNR",
    city_name: "Banfora",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Bangalore Kempegowda International Airport",
    longitude: 77.706719,
    latitude: 13.199732,
    id: "arp_blr_in",
    icao_code: "VOBL",
    iata_country_code: "IN",
    iata_code: "BLR",
    city_name: "Bangalore",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bangassou Airport",
    longitude: 22.780094,
    latitude: 4.785912,
    id: "arp_bgu_cf",
    icao_code: "FEFG",
    iata_country_code: "CF",
    iata_code: "BGU",
    city_name: "Bangassou",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Bangoka International Airport",
    longitude: 25.335324,
    latitude: 0.484306,
    id: "arp_fki_cd",
    icao_code: "FZIC",
    iata_country_code: "CD",
    iata_code: "FKI",
    city_name: "Kisangani",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Bangor International Airport",
    longitude: -68.82404,
    latitude: 44.807425,
    id: "arp_bgr_us",
    icao_code: "KBGR",
    iata_country_code: "US",
    iata_code: "BGR",
    city_name: "Bangor",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bangui M'Poko International Airport",
    longitude: 18.519539,
    latitude: 4.397295,
    id: "arp_bgf_cf",
    icao_code: "FEFF",
    iata_country_code: "CF",
    iata_code: "BGF",
    city_name: "Bangui",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Ban Huoeisay Airport",
    longitude: 100.437022,
    latitude: 20.258431,
    id: "arp_hoe_la",
    icao_code: "VLHS",
    iata_country_code: "LA",
    iata_code: "HOE",
    city_name: "Houeisay",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Baniyala Airport",
    longitude: 136.226966,
    latitude: -13.198068,
    id: "arp_byx_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "BYX",
    city_name: "Baniyala",
    city: null,
  },
  {
    time_zone: "Europe/Sarajevo",
    name: "Banja Luka International Airport",
    longitude: 17.299061,
    latitude: 44.939613,
    id: "arp_bnx_ba",
    icao_code: "LQBK",
    iata_country_code: "BA",
    iata_code: "BNX",
    city_name: "Banja Luka",
    city: null,
  },
  {
    time_zone: "Africa/Banjul",
    name: "Banjul International Airport",
    longitude: -16.652221,
    latitude: 13.340992,
    id: "arp_bjl_gm",
    icao_code: "GBYD",
    iata_country_code: "GM",
    iata_code: "BJL",
    city_name: "Banjul",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Bankstown Airport",
    longitude: 150.991461,
    latitude: -33.922523,
    id: "arp_bwu_au",
    icao_code: "YSBK",
    iata_country_code: "AU",
    iata_code: "BWU",
    city_name: "Sydney",
    city: {
      name: "Sydney",
      id: "cit_syd_au",
      iata_country_code: "AU",
      iata_code: "SYD",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Banning Municipal Airport",
    longitude: -116.852186,
    latitude: 33.922677,
    id: "arp_bng_us",
    icao_code: "KBNG",
    iata_country_code: "US",
    iata_code: "BNG",
    city_name: "Banning",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Bannu Airport",
    longitude: 70.525086,
    latitude: 32.972926,
    id: "arp_bnp_pk",
    icao_code: "OPBN",
    iata_country_code: "PK",
    iata_code: "BNP",
    city_name: "Bannu",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Bantry Aerodrome",
    longitude: -9.486703,
    latitude: 51.677459,
    id: "arp_byt_ie",
    icao_code: "EIBN",
    iata_country_code: "IE",
    iata_code: "BYT",
    city_name: "Bantry",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Banz Airport",
    longitude: 144.617097,
    latitude: -5.783522,
    id: "arp_bnz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BNZ",
    city_name: "Banz",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Baoshan Yunrui Airport",
    longitude: 99.167463,
    latitude: 25.052722,
    id: "arp_bsd_cn",
    icao_code: "ZPBS",
    iata_country_code: "CN",
    iata_code: "BSD",
    city_name: "Baoshan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Baotou Airport",
    longitude: 109.998733,
    latitude: 40.562152,
    id: "arp_bav_cn",
    icao_code: "ZBOW",
    iata_country_code: "CN",
    iata_code: "BAV",
    city_name: "Baotou",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bapi Airport",
    longitude: 147.017429,
    latitude: -7.733266,
    id: "arp_bpd_pg",
    icao_code: "AYBP",
    iata_country_code: "PG",
    iata_code: "BPD",
    city_name: "Bapi",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Baracoa Airport",
    longitude: -74.503208,
    latitude: 20.358792,
    id: "arp_mgn_co",
    icao_code: "SKMG",
    iata_country_code: "CO",
    iata_code: "MGN",
    city_name: "Magangue",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Barakoma Airport",
    longitude: 156.706659,
    latitude: -7.913279,
    id: "arp_vev_sb",
    icao_code: "AGBA",
    iata_country_code: "SB",
    iata_code: "VEV",
    city_name: "Barakoma",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Baramita Airport",
    longitude: -60.490865,
    latitude: 7.369514,
    id: "arp_bmj_gy",
    icao_code: "SYBR",
    iata_country_code: "GY",
    iata_code: "BMJ",
    city_name: "Baramita",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Barcaldine Airport",
    longitude: 145.3045,
    latitude: -23.562862,
    id: "arp_bci_au",
    icao_code: "YBAR",
    iata_country_code: "AU",
    iata_code: "BCI",
    city_name: "Barcaldine",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Barcelona–El Prat Josep Tarradellas Airport",
    longitude: 2.080877,
    latitude: 41.297273,
    id: "arp_bcn_es",
    icao_code: "LEBL",
    iata_country_code: "ES",
    iata_code: "BCN",
    city_name: "Barcelona",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Barcelonnette - Saint-Pons Airport",
    longitude: 6.609796,
    latitude: 44.386409,
    id: "arp_bae_fr",
    icao_code: "LFMR",
    iata_country_code: "FR",
    iata_code: "BAE",
    city_name: "Barcelonnette",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Barcelos Airport",
    longitude: -62.918909,
    latitude: -0.979396,
    id: "arp_baz_br",
    icao_code: "SWBC",
    iata_country_code: "BR",
    iata_code: "BAZ",
    city_name: "Barcelos",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Bardera Airport",
    longitude: 42.309592,
    latitude: 2.332964,
    id: "arp_bsy_so",
    icao_code: "HCMD",
    iata_country_code: "SO",
    iata_code: "BSY",
    city_name: "Bardera",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Bardufoss Airport",
    longitude: 18.54001,
    latitude: 69.056759,
    id: "arp_bdu_no",
    icao_code: "ENDU",
    iata_country_code: "NO",
    iata_code: "BDU",
    city_name: "Bardufoss",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Bareilly Airport",
    longitude: 79.453091,
    latitude: 28.419056,
    id: "arp_bek_in",
    icao_code: "VIBY",
    iata_country_code: "IN",
    iata_code: "BEK",
    city_name: "Bareilly",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Bari Karol Wojtyła Airport",
    longitude: 16.7629,
    latitude: 41.138204,
    id: "arp_bri_it",
    icao_code: "LIBD",
    iata_country_code: "IT",
    iata_code: "BRI",
    city_name: "Bari",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Barimunya Airport",
    longitude: 119.165772,
    latitude: -22.673988,
    id: "arp_byp_au",
    icao_code: "YBRY",
    iata_country_code: "AU",
    iata_code: "BYP",
    city_name: "Barimunya",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Barinas Airport",
    longitude: -70.220722,
    latitude: 8.621646,
    id: "arp_bns_ve",
    icao_code: "SVBI",
    iata_country_code: "VE",
    iata_code: "BNS",
    city_name: "Barinas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Barin Naval Outlying Field Airport",
    longitude: -87.633755,
    latitude: 30.389507,
    id: "arp_nhx_us",
    icao_code: "KNBJ",
    iata_country_code: "US",
    iata_code: "NHX",
    city_name: "Foley",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Bario Airport",
    longitude: 115.479251,
    latitude: 3.734072,
    id: "arp_bbn_my",
    icao_code: "WBGZ",
    iata_country_code: "MY",
    iata_code: "BBN",
    city_name: "Bario",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Barisal Airport",
    longitude: 90.300859,
    latitude: 22.80022,
    id: "arp_bzl_bd",
    icao_code: "VGBR",
    iata_country_code: "BD",
    iata_code: "BZL",
    city_name: "Barisal",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Barking Sands Airport",
    longitude: -159.782419,
    latitude: 22.03489,
    id: "arp_bkh_us",
    icao_code: "PHBK",
    iata_country_code: "US",
    iata_code: "BKH",
    city_name: "Kekaha",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Barkley Regional Airport",
    longitude: -88.77064,
    latitude: 37.061023,
    id: "arp_pah_us",
    icao_code: "KPAH",
    iata_country_code: "US",
    iata_code: "PAH",
    city_name: "Paducah",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Barkly Downs Airport",
    longitude: 139.490397,
    latitude: -20.66889,
    id: "arp_bkp_au",
    icao_code: "YBAW",
    iata_country_code: "AU",
    iata_code: "BKP",
    city_name: "Barkly Downs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Barksdale Air Force Base",
    longitude: -93.660845,
    latitude: 32.50182,
    id: "arp_bad_us",
    icao_code: "KBAD",
    iata_country_code: "US",
    iata_code: "BAD",
    city_name: "Bossier City",
    city: {
      name: "Shreveport",
      id: "cit_shv_us",
      iata_country_code: "US",
      iata_code: "SHV",
    },
  },
  {
    time_zone: "Asia/Barnaul",
    name: "Barnaul Airport",
    longitude: 83.542856,
    latitude: 53.362515,
    id: "arp_bax_ru",
    icao_code: "UNBB",
    iata_country_code: "RU",
    iata_code: "BAX",
    city_name: "Barnaul",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Barnstable Municipal Airport",
    longitude: -70.282597,
    latitude: 41.668365,
    id: "arp_hya_us",
    icao_code: "KHYA",
    iata_country_code: "US",
    iata_code: "HYA",
    city_name: "Hyannis",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Barnwell Regional Airport",
    longitude: -81.385806,
    latitude: 33.258573,
    id: "arp_bnl_us",
    icao_code: "KBNL",
    iata_country_code: "US",
    iata_code: "BNL",
    city_name: "Barnwell",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Barora Airport",
    longitude: 158.4,
    latitude: -7.6,
    id: "arp_rri_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "RRI",
    city_name: "Barora",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Barra Airport",
    longitude: -43.147499,
    latitude: -11.0808,
    id: "arp_bqq_br",
    icao_code: "SNBX",
    iata_country_code: "BR",
    iata_code: "BQQ",
    city_name: "Barra",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Barra Airport",
    longitude: -7.44625,
    latitude: 57.024079,
    id: "arp_brr_gb",
    icao_code: "EGPR",
    iata_country_code: "GB",
    iata_code: "BRR",
    city_name: "Barra",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Barra del Colorado Airport",
    longitude: -83.585551,
    latitude: 10.76848,
    id: "arp_bcl_cr",
    icao_code: "MRBC",
    iata_country_code: "CR",
    iata_code: "BCL",
    city_name: "Pococi",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Barra do Corda Airport",
    longitude: -45.216213,
    latitude: -5.501927,
    id: "arp_bdc_br",
    icao_code: "SNBC",
    iata_country_code: "BR",
    iata_code: "BDC",
    city_name: "Barra do Corda",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Barra do Garças Airport",
    longitude: -52.389454,
    latitude: -15.860426,
    id: "arp_bpg_br",
    icao_code: "SBBW",
    iata_country_code: "BR",
    iata_code: "BPG",
    city_name: "Barra do Garças",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Barranca De Upia Airport",
    longitude: -72.9667,
    latitude: 4.58333,
    id: "arp_bac_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "BAC",
    city_name: "Barranca de Upia",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Barranco Minas Airport",
    longitude: -69.810271,
    latitude: 3.490717,
    id: "arp_nbb_co",
    icao_code: "SKBM",
    iata_country_code: "CO",
    iata_code: "NBB",
    city_name: "Barranco Minas",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Barreiras Airport",
    longitude: -45.009674,
    latitude: -12.078871,
    id: "arp_bra_br",
    icao_code: "SNBR",
    iata_country_code: "BR",
    iata_code: "BRA",
    city_name: "Barreiras",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Barreirinhas Airport",
    longitude: -42.805661,
    latitude: -2.758245,
    id: "arp_brb_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "BRB",
    city_name: "Barreirinhas",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Barriles Airport",
    longitude: -70.0623,
    latitude: -22.141396,
    id: "arp_toq_cl",
    icao_code: "SCBE",
    iata_country_code: "CL",
    iata_code: "TOQ",
    city_name: "Tocopilla",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Bar River Airport",
    longitude: -84.093247,
    latitude: 46.422775,
    id: "arp_yeb_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YEB",
    city_name: "Bar River",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Barrow County Airport",
    longitude: -83.671311,
    latitude: 33.981671,
    id: "arp_wdr_us",
    icao_code: "KWDR",
    iata_country_code: "US",
    iata_code: "WDR",
    city_name: "Winder",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Barrow Island Airport",
    longitude: 115.406226,
    latitude: -20.864978,
    id: "arp_bwb_au",
    icao_code: "YBWX",
    iata_country_code: "AU",
    iata_code: "BWB",
    city_name: "Barrow Island",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Barrow/Walney Island Airport",
    longitude: -3.265665,
    latitude: 54.129155,
    id: "arp_bwf_gb",
    icao_code: "EGNL",
    iata_country_code: "GB",
    iata_code: "BWF",
    city_name: "Barrow-in-Furness",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Barstow-Daggett Airport",
    longitude: -116.787429,
    latitude: 34.855272,
    id: "arp_dag_us",
    icao_code: "KDAG",
    iata_country_code: "US",
    iata_code: "DAG",
    city_name: "Daggett",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Barter Island Airport",
    longitude: -143.583847,
    latitude: 70.134372,
    id: "arp_bti_us",
    icao_code: "PABA",
    iata_country_code: "US",
    iata_code: "BTI",
    city_name: "Barter Island",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Barth Airport",
    longitude: 12.712329,
    latitude: 54.338161,
    id: "arp_bbh_de",
    icao_code: "EDBH",
    iata_country_code: "DE",
    iata_code: "BBH",
    city_name: "Stralsund",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Bartica Airport",
    longitude: -58.628486,
    latitude: 6.398937,
    id: "arp_gfo_gy",
    icao_code: "SYBT",
    iata_country_code: "GY",
    iata_code: "GFO",
    city_name: "Bartica",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bartlesville Municipal Airport",
    longitude: -96.010924,
    latitude: 36.762789,
    id: "arp_bvo_us",
    icao_code: "KBVO",
    iata_country_code: "US",
    iata_code: "BVO",
    city_name: "Bartlesville",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Bartlett Cove Seaplane Base",
    longitude: -135.885112,
    latitude: 58.455205,
    id: "arp_bqv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BQV",
    city_name: "Gustavus",
    city: {
      name: "Gustavus",
      id: "cit_gst_us",
      iata_country_code: "US",
      iata_code: "GST",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Bartow Municipal Airport",
    longitude: -81.783536,
    latitude: 27.943969,
    id: "arp_bow_us",
    icao_code: "KBOW",
    iata_country_code: "US",
    iata_code: "BOW",
    city_name: "Bartow",
    city: null,
  },
  {
    time_zone: "Asia/Choibalsan",
    name: "Baruun-Urt Airport",
    longitude: 113.284902,
    latitude: 46.660052,
    id: "arp_uun_mn",
    icao_code: "ZMBU",
    iata_country_code: "MN",
    iata_code: "UUN",
    city_name: "Baruun-Urt",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Bar Yehuda Airfield",
    longitude: 35.387966,
    latitude: 31.327295,
    id: "arp_mtz_il",
    icao_code: "LLMZ",
    iata_country_code: "IL",
    iata_code: "MTZ",
    city_name: "Masada",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Basango Mboliasa Airport",
    longitude: 19.024,
    latitude: -1.435,
    id: "arp_krz_cd",
    icao_code: "FZBT",
    iata_country_code: "CD",
    iata_code: "KRZ",
    city_name: "Kiri",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Basankusu Airport",
    longitude: 19.788538,
    latitude: 1.224687,
    id: "arp_bsu_cd",
    icao_code: "FZEN",
    iata_country_code: "CD",
    iata_code: "BSU",
    city_name: "Basankusu",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Basco Airport",
    longitude: 121.979185,
    latitude: 20.450824,
    id: "arp_bso_ph",
    icao_code: "RPUO",
    iata_country_code: "PH",
    iata_code: "BSO",
    city_name: "Basco",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Base Aérea de Santos Airport",
    longitude: -46.299721,
    latitude: -23.928057,
    id: "arp_ssz_br",
    icao_code: "SBST",
    iata_country_code: "BR",
    iata_code: "SSZ",
    city_name: "Santos",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Basongo Airport",
    longitude: 20.41434,
    latitude: -4.315545,
    id: "arp_ban_cd",
    icao_code: "FZVR",
    iata_country_code: "CD",
    iata_code: "BAN",
    city_name: "Basongo",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Basrah International Airport",
    longitude: 47.662102,
    latitude: 30.549101,
    id: "arp_bsr_iq",
    icao_code: "ORMM",
    iata_country_code: "IQ",
    iata_code: "BSR",
    city_name: "Basrah",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Bassatine Airport",
    longitude: -5.51512,
    latitude: 33.879101,
    id: "arp_mek_ma",
    icao_code: "GMFM",
    iata_country_code: "MA",
    iata_code: "MEK",
    city_name: "Meknes",
    city: null,
  },
  {
    time_zone: "Asia/Damascus",
    name: "Bassel Al-Assad International Airport",
    longitude: 35.946402,
    latitude: 35.404205,
    id: "arp_ltk_sy",
    icao_code: "OSLK",
    iata_country_code: "SY",
    iata_code: "LTK",
    city_name: "Latakia",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Bastia – Poretta Airport",
    longitude: 9.482788,
    latitude: 42.551331,
    id: "arp_bia_fr",
    icao_code: "LFKB",
    iata_country_code: "FR",
    iata_code: "BIA",
    city_name: "Bastia",
    city: null,
  },
  {
    time_zone: "Africa/Malabo",
    name: "Bata Airport",
    longitude: 9.804205,
    latitude: 1.902792,
    id: "arp_bsg_gq",
    icao_code: "FGBT",
    iata_country_code: "GQ",
    iata_code: "BSG",
    city_name: "Bata",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Batagay Airport",
    longitude: 134.691922,
    latitude: 67.648716,
    id: "arp_bqj_ru",
    icao_code: "UEBB",
    iata_country_code: "RU",
    iata_code: "BQJ",
    city_name: "Batagay",
    city: null,
  },
  {
    time_zone: "Europe/Belgrade",
    name: "Batajnica Air Base",
    longitude: 20.257138,
    latitude: 44.935079,
    id: "arp_bjy_rs",
    icao_code: "LYBT",
    iata_country_code: "RS",
    iata_code: "BJY",
    city_name: "Batajnica",
    city: {
      name: "Belgrade",
      id: "cit_beg_rs",
      iata_country_code: "RS",
      iata_code: "BEG",
    },
  },
  {
    time_zone: "Africa/Bangui",
    name: "Batangafo Airport",
    longitude: 18.287817,
    latitude: 7.300634,
    id: "arp_btg_cf",
    icao_code: "FEGF",
    iata_country_code: "CF",
    iata_code: "BTG",
    city_name: "Batangafo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Batavia Downs Airport",
    longitude: 143.042294,
    latitude: -12.637281,
    id: "arp_bvw_au",
    icao_code: "YBTV",
    iata_country_code: "AU",
    iata_code: "BVW",
    city_name: "Batavia Downs",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Bateen Airport",
    longitude: 54.457014,
    latitude: 24.428556,
    id: "arp_azi_ae",
    icao_code: "OMAD",
    iata_country_code: "AE",
    iata_code: "AZI",
    city_name: "Abu Dhabi",
    city: {
      name: "Abu Dhabi",
      id: "cit_auh_ae",
      iata_country_code: "AE",
      iata_code: "AUH",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Batesville Regional Airport",
    longitude: -91.645355,
    latitude: 35.726912,
    id: "arp_bvx_us",
    icao_code: "KBVX",
    iata_country_code: "US",
    iata_code: "BVX",
    city_name: "Batesville",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Bathinda Airport",
    longitude: 74.757415,
    latitude: 30.269188,
    id: "arp_bup_in",
    icao_code: "VIBT",
    iata_country_code: "IN",
    iata_code: "BUP",
    city_name: "Bhathinda/Bathinda",
    city: null,
  },
  {
    time_zone: "Asia/Thimphu",
    name: "Bathpalathang Airport",
    longitude: 90.746509,
    latitude: 27.562969,
    id: "arp_but_bt",
    icao_code: "VQBT",
    iata_country_code: "BT",
    iata_code: "BUT",
    city_name: "Jakar",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Bathurst Airport",
    longitude: 149.653003,
    latitude: -33.410849,
    id: "arp_bhs_au",
    icao_code: "YBTH",
    iata_country_code: "AU",
    iata_code: "BHS",
    city_name: "Bathurst",
    city: null,
  },
  {
    time_zone: "America/Moncton",
    name: "Bathurst Airport",
    longitude: -65.740044,
    latitude: 47.629965,
    id: "arp_zbf_ca",
    icao_code: "CZBF",
    iata_country_code: "CA",
    iata_code: "ZBF",
    city_name: "Bathurst",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Bathurst Island Airport",
    longitude: 130.619179,
    latitude: -11.768551,
    id: "arp_brt_au",
    icao_code: "YBTI",
    iata_country_code: "AU",
    iata_code: "BRT",
    city_name: "Bathurst Island",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Batman Airport",
    longitude: 41.121658,
    latitude: 37.945713,
    id: "arp_bal_tr",
    icao_code: "LTCJ",
    iata_country_code: "TR",
    iata_code: "BAL",
    city_name: "Batman",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Batom Airport",
    longitude: 140.850006,
    latitude: -4.16667,
    id: "arp_bxm_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "BXM",
    city_name: "Batom",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Baton Rouge Metropolitan Airport",
    longitude: -91.150529,
    latitude: 30.532779,
    id: "arp_btr_us",
    icao_code: "KBTR",
    iata_country_code: "US",
    iata_code: "BTR",
    city_name: "Baton Rouge",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Batouri Airport",
    longitude: 14.363225,
    latitude: 4.477158,
    id: "arp_our_cm",
    icao_code: "FKKI",
    iata_country_code: "CM",
    iata_code: "OUR",
    city_name: "Batouri",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Båtsfjord Airport",
    longitude: 29.691651,
    latitude: 70.601619,
    id: "arp_bjf_no",
    icao_code: "ENBS",
    iata_country_code: "NO",
    iata_code: "BJF",
    city_name: "Båtsfjord",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Battambang Airport",
    longitude: 103.22436,
    latitude: 13.095437,
    id: "arp_bbm_kh",
    icao_code: "VDBG",
    iata_country_code: "KH",
    iata_code: "BBM",
    city_name: "Battambang",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Batticaloa Airport",
    longitude: 81.677689,
    latitude: 7.705454,
    id: "arp_btc_lk",
    icao_code: "VCCB",
    iata_country_code: "LK",
    iata_code: "BTC",
    city_name: "Batticaloa",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Battle Mountain Airport",
    longitude: -116.874168,
    latitude: 40.599022,
    id: "arp_bam_us",
    icao_code: "KBAM",
    iata_country_code: "US",
    iata_code: "BAM",
    city_name: "Battle Mountain",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Batu Licin Airport",
    longitude: 115.997188,
    latitude: -3.412427,
    id: "arp_btw_id",
    icao_code: "WAOC",
    iata_country_code: "ID",
    iata_code: "BTW",
    city_name: "Batu Licin",
    city: null,
  },
  {
    time_zone: "Asia/Tbilisi",
    name: "Batumi International Airport",
    longitude: 41.599826,
    latitude: 41.610341,
    id: "arp_bus_ge",
    icao_code: "UGSB",
    iata_country_code: "GE",
    iata_code: "BUS",
    city_name: "Batumi",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Batuna Airport",
    longitude: 158.119306,
    latitude: -8.562028,
    id: "arp_bpf_sb",
    icao_code: "AGBT",
    iata_country_code: "SB",
    iata_code: "BPF",
    city_name: "Batuna",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Baubau Airport",
    longitude: 122.568868,
    latitude: -5.487169,
    id: "arp_buw_id",
    icao_code: "WAWB",
    iata_country_code: "ID",
    iata_code: "BUW",
    city_name: "Baubau",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Bauchi State Airport",
    longitude: 9.743801,
    latitude: 10.483386,
    id: "arp_bcu_ng",
    icao_code: "DNBC",
    iata_country_code: "NG",
    iata_code: "BCU",
    city_name: "Bauchi",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Baudette International Airport",
    longitude: -94.610873,
    latitude: 48.727536,
    id: "arp_bde_us",
    icao_code: "KBDE",
    iata_country_code: "US",
    iata_code: "BDE",
    city_name: "Baudette",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Bauerfield International Airport",
    longitude: 168.319476,
    latitude: -17.699652,
    id: "arp_vli_vu",
    icao_code: "NVVV",
    iata_country_code: "VU",
    iata_code: "VLI",
    city_name: "Port Vila",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Baures Airport",
    longitude: -63.702316,
    latitude: -13.657969,
    id: "arp_bvl_bo",
    icao_code: null,
    iata_country_code: "BO",
    iata_code: "BVL",
    city_name: "Baures",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Bauru Arealva Airport",
    longitude: -49.070281,
    latitude: -22.165644,
    id: "arp_jtc_br",
    icao_code: "SBAE",
    iata_country_code: "BR",
    iata_code: "JTC",
    city_name: "Bauru",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bawan Airport",
    longitude: 146.881533,
    latitude: -6.39743,
    id: "arp_bwj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BWJ",
    city_name: "Bawan",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Bawean Airport",
    longitude: 112.678974,
    latitude: -5.723647,
    id: "arp_bxw_id",
    icao_code: "WARW",
    iata_country_code: "ID",
    iata_code: "BXW",
    city_name: "Bawean",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Bayankhongor Airport",
    longitude: 100.703845,
    latitude: 46.163971,
    id: "arp_byn_mn",
    icao_code: "ZMBH",
    iata_country_code: "MN",
    iata_code: "BYN",
    city_name: "Bayankhongor",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Bayannur Tianjitai Airport",
    longitude: 107.744199,
    latitude: 40.925375,
    id: "arp_rlk_cn",
    icao_code: "ZBYZ",
    iata_country_code: "CN",
    iata_code: "RLK",
    city_name: "Bayannur",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bay City Municipal Airport",
    longitude: -95.863486,
    latitude: 28.973285,
    id: "arp_bbc_us",
    icao_code: "KBYY",
    iata_country_code: "US",
    iata_code: "BBC",
    city_name: "Bay City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Baytown Airport",
    longitude: -94.953019,
    latitude: 29.786161,
    id: "arp_hpy_us",
    icao_code: "KHPY",
    iata_country_code: "US",
    iata_code: "HPY",
    city_name: "Baytown",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Bazaruto Island Airport",
    longitude: 35.47281,
    latitude: -21.543077,
    id: "arp_bzb_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "BZB",
    city_name: "Bazaruto Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Bazhong Enyang Airport",
    longitude: 106.644912,
    latitude: 31.738516,
    id: "arp_bzx_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "BZX",
    city_name: "Bazhong",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Beagle Bay Airport",
    longitude: 122.646309,
    latitude: -17.016783,
    id: "arp_bee_au",
    icao_code: "YBGB",
    iata_country_code: "AU",
    iata_code: "BEE",
    city_name: "Beagle Bay",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Bealanana Airport",
    longitude: 48.7,
    latitude: -14.5333,
    id: "arp_wbe_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "WBE",
    city_name: "Bealanana",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Beale Air Force Base",
    longitude: -121.434758,
    latitude: 39.152823,
    id: "arp_bab_us",
    icao_code: "KBAB",
    iata_country_code: "US",
    iata_code: "BAB",
    city_name: "Marysville",
    city: {
      name: "Marysville",
      id: "cit_myv_us",
      iata_country_code: "US",
      iata_code: "MYV",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Bear Creek 3 Airport",
    longitude: -156.149239,
    latitude: 63.573062,
    id: "arp_bcc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BCC",
    city_name: "Bear Creek",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Bearskin Lake Airport",
    longitude: -91.029165,
    latitude: 53.963732,
    id: "arp_xbe_ca",
    icao_code: "CNE3",
    iata_country_code: "CA",
    iata_code: "XBE",
    city_name: "Bearskin Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Beatrice Municipal Airport",
    longitude: -96.751279,
    latitude: 40.300354,
    id: "arp_bie_us",
    icao_code: "KBIE",
    iata_country_code: "US",
    iata_code: "BIE",
    city_name: "Beatrice",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Beatty Airport",
    longitude: -116.785271,
    latitude: 36.865753,
    id: "arp_bty_us",
    icao_code: "KBTY",
    iata_country_code: "US",
    iata_code: "BTY",
    city_name: "Beatty",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Beaufort County Airport",
    longitude: -80.63455,
    latitude: 32.41175,
    id: "arp_bft_us",
    icao_code: "KARW",
    iata_country_code: "US",
    iata_code: "BFT",
    city_name: "Beaufort",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Beaumont Municipal Airport",
    longitude: -94.213826,
    latitude: 30.07052,
    id: "arp_bmt_us",
    icao_code: "KBMT",
    iata_country_code: "US",
    iata_code: "BMT",
    city_name: "Beaumont",
    city: {
      name: "Beaumont/Port Arthur",
      id: "cit_bpt_us",
      iata_country_code: "US",
      iata_code: "BPT",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Beauregard Regional Airport",
    longitude: -93.338289,
    latitude: 30.831752,
    id: "arp_dri_us",
    icao_code: "KDRI",
    iata_country_code: "US",
    iata_code: "DRI",
    city_name: "DeRidder",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Beaver Airport",
    longitude: -147.406807,
    latitude: 66.362244,
    id: "arp_wbq_us",
    icao_code: "PAWB",
    iata_country_code: "US",
    iata_code: "WBQ",
    city_name: "Beaver",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Beaver County Airport",
    longitude: -80.391586,
    latitude: 40.773435,
    id: "arp_bfp_us",
    icao_code: "KBVI",
    iata_country_code: "US",
    iata_code: "BFP",
    city_name: "Beaver Falls",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Beaver Creek Airport",
    longitude: -140.865641,
    latitude: 62.409324,
    id: "arp_yxq_ca",
    icao_code: "CYXQ",
    iata_country_code: "CA",
    iata_code: "YXQ",
    city_name: "Beaver Creek",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Béchar Boudghene Ben Ali Lotfi Airport",
    longitude: -2.273963,
    latitude: 31.64549,
    id: "arp_cbh_dz",
    icao_code: "DAOR",
    iata_country_code: "DZ",
    iata_code: "CBH",
    city_name: "Béchar",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Bedford Downs Airport",
    longitude: 127.466902,
    latitude: -17.28736,
    id: "arp_bdw_au",
    icao_code: "YBDF",
    iata_country_code: "AU",
    iata_code: "BDW",
    city_name: "Bedford Downs",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bedourie Airport",
    longitude: 139.463675,
    latitude: -24.352224,
    id: "arp_beu_au",
    icao_code: "YBIE",
    iata_country_code: "AU",
    iata_code: "BEU",
    city_name: "Bedourie",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Beech Factory Airport",
    longitude: -97.218851,
    latitude: 37.694309,
    id: "arp_bec_us",
    icao_code: "KBEC",
    iata_country_code: "US",
    iata_code: "BEC",
    city_name: "Wichita",
    city: {
      name: "Wichita",
      id: "cit_ict_us",
      iata_country_code: "US",
      iata_code: "ICT",
    },
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Beersheba Airport",
    longitude: 34.723662,
    latitude: 31.28681,
    id: "arp_bev_il",
    icao_code: "LLBS",
    iata_country_code: "IL",
    iata_code: "BEV",
    city_name: "Beersheba",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Begishevo Airport",
    longitude: 52.097224,
    latitude: 55.564554,
    id: "arp_nbc_ru",
    icao_code: "UWKE",
    iata_country_code: "RU",
    iata_code: "NBC",
    city_name: "Nizhnekamsk",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Begumpet Airport",
    longitude: 78.468807,
    latitude: 17.451933,
    id: "arp_bpm_in",
    icao_code: "VOHY",
    iata_country_code: "IN",
    iata_code: "BPM",
    city_name: "Hyderabad",
    city: {
      name: "Hyderabad",
      id: "cit_hyd_in",
      iata_country_code: "IN",
      iata_code: "HYD",
    },
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Beica Airport",
    longitude: 34.5219,
    latitude: 9.386389,
    id: "arp_bei_et",
    icao_code: "HABE",
    iata_country_code: "ET",
    iata_code: "BEI",
    city_name: "Beica",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Beihai Fucheng Airport",
    longitude: 109.293692,
    latitude: 21.539943,
    id: "arp_bhy_cn",
    icao_code: "ZGBH",
    iata_country_code: "CN",
    iata_code: "BHY",
    city_name: "Beihai",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Beihan Airport",
    longitude: 45.720009,
    latitude: 14.782276,
    id: "arp_bhn_ye",
    icao_code: "OYBN",
    iata_country_code: "YE",
    iata_code: "BHN",
    city_name: "Beihan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Beijing Capital International Airport",
    longitude: 116.602404,
    latitude: 40.080613,
    id: "arp_pek_cn",
    icao_code: "ZBAA",
    iata_country_code: "CN",
    iata_code: "PEK",
    city_name: "Beijing",
    city: {
      name: "Beijing",
      id: "cit_bjs_cn",
      iata_country_code: "CN",
      iata_code: "BJS",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Beijing Daxing International Airport",
    longitude: 116.41092,
    latitude: 39.509945,
    id: "arp_pkx_cn",
    icao_code: "ZBAD",
    iata_country_code: "CN",
    iata_code: "PKX",
    city_name: "Beijing",
    city: {
      name: "Beijing",
      id: "cit_bjs_cn",
      iata_country_code: "CN",
      iata_code: "BJS",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Beijing Nanyuan Airport",
    longitude: 116.387926,
    latitude: 39.782699,
    id: "arp_nay_cn",
    icao_code: "ZBNY",
    iata_country_code: "CN",
    iata_code: "NAY",
    city_name: "Beijing",
    city: {
      name: "Beijing",
      id: "cit_bjs_cn",
      iata_country_code: "CN",
      iata_code: "BJS",
    },
  },
  {
    time_zone: "Africa/Maputo",
    name: "Beira Airport",
    longitude: 34.907999,
    latitude: -19.796541,
    id: "arp_bew_mz",
    icao_code: "FQBR",
    iata_country_code: "MZ",
    iata_code: "BEW",
    city_name: "Beira",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Beira Lake Seaplane Base Airport",
    longitude: 79.854596,
    latitude: 6.92883,
    id: "arp_byv_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "BYV",
    city_name: "Colombo",
    city: {
      name: "Colombo",
      id: "cit_cmb_lk",
      iata_country_code: "LK",
      iata_code: "CMB",
    },
  },
  {
    time_zone: "Asia/Beirut",
    name: "Beirut-Rafic Hariri International Airport",
    longitude: 35.489801,
    latitude: 33.820137,
    id: "arp_bey_lb",
    icao_code: "OLBA",
    iata_country_code: "LB",
    iata_code: "BEY",
    city_name: "Beirut",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Beja International Airport",
    longitude: -7.938627,
    latitude: 38.0642,
    id: "arp_byj_pt",
    icao_code: "LPBJ",
    iata_country_code: "PT",
    iata_code: "BYJ",
    city_name: "Beja",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Bekily Airport",
    longitude: 45.304596,
    latitude: -24.235887,
    id: "arp_ova_mg",
    icao_code: "FMSL",
    iata_country_code: "MG",
    iata_code: "OVA",
    city_name: "Bekily",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Belaga Airport",
    longitude: 113.766303,
    latitude: 2.65126,
    id: "arp_blg_my",
    icao_code: "WBGC",
    iata_country_code: "MY",
    iata_code: "BLG",
    city_name: "Belaga",
    city: null,
  },
  {
    time_zone: "Asia/Srednekolymsk",
    name: "Belaya Gora Airport",
    longitude: 146.230672,
    latitude: 68.556451,
    id: "arp_bgn_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "BGN",
    city_name: "Belaya Gora",
    city: null,
  },
  {
    time_zone: "Europe/Simferopol",
    name: "Belbek Airport",
    longitude: 33.587374,
    latitude: 44.694989,
    id: "arp_uks_ua",
    icao_code: "UKFB",
    iata_country_code: "UA",
    iata_code: "UKS",
    city_name: "Sevastopol",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Belém/Val de Cans–Júlio Cezar Ribeiro International Airport",
    longitude: -48.476911,
    latitude: -1.380655,
    id: "arp_bel_br",
    icao_code: "SBBE",
    iata_country_code: "BR",
    iata_code: "BEL",
    city_name: "Belém",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Beles Airport",
    longitude: 36.415756,
    latitude: 11.312695,
    id: "arp_pwi_et",
    icao_code: "HAPW",
    iata_country_code: "ET",
    iata_code: "PWI",
    city_name: "Pawe",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Beletwene Airport",
    longitude: 45.234613,
    latitude: 4.76099,
    id: "arp_blw_so",
    icao_code: "HCMN",
    iata_country_code: "SO",
    iata_code: "BLW",
    city_name: "Beledweyne",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Belfast International Airport",
    longitude: -6.217177,
    latitude: 54.658279,
    id: "arp_bfs_gb",
    icao_code: "EGAA",
    iata_country_code: "GB",
    iata_code: "BFS",
    city_name: "Belfast",
    city: {
      name: "Belfast",
      id: "cit_bfs_gb",
      iata_country_code: "GB",
      iata_code: "BFS",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Belgaum Airport",
    longitude: 74.615619,
    latitude: 15.860176,
    id: "arp_ixg_in",
    icao_code: "VOBM",
    iata_country_code: "IN",
    iata_code: "IXG",
    city_name: "Belgaum",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Belgorod International Airport",
    longitude: 36.571377,
    latitude: 50.645499,
    id: "arp_ego_ru",
    icao_code: "UUOB",
    iata_country_code: "RU",
    iata_code: "EGO",
    city_name: "Belgorod",
    city: null,
  },
  {
    time_zone: "Europe/Belgrade",
    name: "Belgrade Nikola Tesla Airport",
    longitude: 20.292378,
    latitude: 44.820559,
    id: "arp_beg_rs",
    icao_code: "LYBE",
    iata_country_code: "RS",
    iata_code: "BEG",
    city_name: "Belgrade",
    city: {
      name: "Belgrade",
      id: "cit_beg_rs",
      iata_country_code: "RS",
      iata_code: "BEG",
    },
  },
  {
    time_zone: "America/Belize",
    name: "Belize City Municipal Airport",
    longitude: -88.194597,
    latitude: 17.516191,
    id: "arp_tza_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "TZA",
    city_name: "Belize City",
    city: {
      name: "Belize City",
      id: "cit_bze_bz",
      iata_country_code: "BZ",
      iata_code: "BZE",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Bella Bella",
    longitude: -128.155419,
    latitude: 52.182447,
    id: "arp_zel_ca",
    icao_code: "CBBC",
    iata_country_code: "CA",
    iata_code: "ZEL",
    city_name: "Bella Bella",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Bella Coola Airport",
    longitude: -126.594023,
    latitude: 52.387628,
    id: "arp_qbc_ca",
    icao_code: "CYBD",
    iata_country_code: "CA",
    iata_code: "QBC",
    city_name: "Bella Coola",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Bellary Airport",
    longitude: 76.884166,
    latitude: 15.161911,
    id: "arp_bep_in",
    icao_code: "VOBI",
    iata_country_code: "IN",
    iata_code: "BEP",
    city_name: "Bellary",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Bella Unión Airport",
    longitude: -57.559422,
    latitude: -30.319889,
    id: "arp_buv_uy",
    icao_code: "SUBU",
    iata_country_code: "UY",
    iata_code: "BUV",
    city_name: "Bella Union",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Bella Yella Airport",
    longitude: -10.0,
    latitude: 7.36667,
    id: "arp_byl_lr",
    icao_code: null,
    iata_country_code: "LR",
    iata_code: "BYL",
    city_name: "Beliyela",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Bellburn Airport",
    longitude: 128.305943,
    latitude: -17.545287,
    id: "arp_bxf_au",
    icao_code: "YBEB",
    iata_country_code: "AU",
    iata_code: "BXF",
    city_name: "Bellburn",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bellingham International Airport",
    longitude: -122.535535,
    latitude: 48.794208,
    id: "arp_bli_us",
    icao_code: "KBLI",
    iata_country_code: "US",
    iata_code: "BLI",
    city_name: "Bellingham",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Bell Island Hot Springs Seaplane Base",
    longitude: -131.571849,
    latitude: 55.929089,
    id: "arp_kbe_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KBE",
    city_name: "Bell Island",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Bellona/Anua Airport",
    longitude: 159.798445,
    latitude: -11.302151,
    id: "arp_bny_sb",
    icao_code: "AGGB",
    iata_country_code: "SB",
    iata_code: "BNY",
    city_name: "Bellona",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Belluno Airport",
    longitude: 12.24915,
    latitude: 46.167408,
    id: "arp_blx_it",
    icao_code: "LIDB",
    iata_country_code: "IT",
    iata_code: "BLX",
    city_name: "Belluno",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Belmonte Airport",
    longitude: -38.872478,
    latitude: -15.872402,
    id: "arp_bvm_br",
    icao_code: "SNBL",
    iata_country_code: "BR",
    iata_code: "BVM",
    city_name: "Belmonte",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Belmullet Aerodrome",
    longitude: -10.031444,
    latitude: 54.222556,
    id: "arp_bly_ie",
    icao_code: "EIBT",
    iata_country_code: "IE",
    iata_code: "BLY",
    city_name: "Belmullet",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Beloretsk Airport",
    longitude: 58.340124,
    latitude: 53.941396,
    id: "arp_bcx_ru",
    icao_code: "UWUB",
    iata_country_code: "RU",
    iata_code: "BCX",
    city_name: "Beloretsk",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Belo sur Tsiribihina Airport",
    longitude: 44.542074,
    latitude: -19.686673,
    id: "arp_bmd_mg",
    icao_code: "FMML",
    iata_country_code: "MG",
    iata_code: "BMD",
    city_name: "Belo sur Tsiribihina",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Beloyarsky Airport",
    longitude: 66.698696,
    latitude: 63.690484,
    id: "arp_eyk_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "EYK",
    city_name: "Beloyarsky",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Beluga Airport",
    longitude: -151.043953,
    latitude: 61.172208,
    id: "arp_bvu_us",
    icao_code: "PABG",
    iata_country_code: "US",
    iata_code: "BVU",
    city_name: "Beluga",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Bembridge Airport",
    longitude: -1.108711,
    latitude: 50.678074,
    id: "arp_bbp_gb",
    icao_code: "EGHJ",
    iata_country_code: "GB",
    iata_code: "BBP",
    city_name: "Bembridge",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Bemichi Airport",
    longitude: -59.16666,
    latitude: 7.7,
    id: "arp_bcg_gy",
    icao_code: "SYBE",
    iata_country_code: "GY",
    iata_code: "BCG",
    city_name: "Bemichi",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bemidji Regional Airport",
    longitude: -94.933479,
    latitude: 47.509302,
    id: "arp_bji_us",
    icao_code: "KBJI",
    iata_country_code: "US",
    iata_code: "BJI",
    city_name: "Bemidji",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Bemolanga Airport",
    longitude: 45.088361,
    latitude: -17.692909,
    id: "arp_bzm_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "BZM",
    city_name: "Bemolanga",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Benalla Airport",
    longitude: 146.004268,
    latitude: -36.552586,
    id: "arp_bln_au",
    icao_code: "YBLA",
    iata_country_code: "AU",
    iata_code: "BLN",
    city_name: "Benalla",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Benbecula Airport",
    longitude: -7.365584,
    latitude: 57.479209,
    id: "arp_beb_gb",
    icao_code: "EGPL",
    iata_country_code: "GB",
    iata_code: "BEB",
    city_name: "Benbecula",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ben Bruce Memorial Airpark",
    longitude: -94.074601,
    latitude: 30.323939,
    id: "arp_eva_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EVA",
    city_name: "Evadale",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Bendigo Airport",
    longitude: 144.328098,
    latitude: -36.739013,
    id: "arp_bxg_au",
    icao_code: "YBDG",
    iata_country_code: "AU",
    iata_code: "BXG",
    city_name: "Bendigo",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Bengbu Airport",
    longitude: 117.320244,
    latitude: 32.847733,
    id: "arp_bfu_cn",
    icao_code: "ZSBB",
    iata_country_code: "CN",
    iata_code: "BFU",
    city_name: "Bengbu",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Benguela Airport",
    longitude: 13.403857,
    latitude: -12.607427,
    id: "arp_bug_ao",
    icao_code: "FNBG",
    iata_country_code: "AO",
    iata_code: "BUG",
    city_name: "Benguela",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Benguerra Island Airport",
    longitude: 35.314798,
    latitude: -22.016475,
    id: "arp_bcw_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "BCW",
    city_name: "Benguerra Island",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Ben Gurion International Airport",
    longitude: 34.886055,
    latitude: 32.008465,
    id: "arp_tlv_il",
    icao_code: "LLBG",
    iata_country_code: "IL",
    iata_code: "TLV",
    city_name: "Tel Aviv",
    city: {
      name: "Tel Aviv-Yafo",
      id: "cit_tlv_il",
      iata_country_code: "IL",
      iata_code: "TLV",
    },
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Beni Airport",
    longitude: 29.471849,
    latitude: 0.576016,
    id: "arp_bnc_cd",
    icao_code: "FZNP",
    iata_country_code: "CD",
    iata_code: "BNC",
    city_name: "Beni",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Beni Mellal Airport",
    longitude: -6.327768,
    latitude: 32.396696,
    id: "arp_bem_ma",
    icao_code: "GMMD",
    iata_country_code: "MA",
    iata_code: "BEM",
    city_name: "Beni Mellal",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Benina International Airport",
    longitude: 20.269944,
    latitude: 32.097034,
    id: "arp_ben_ly",
    icao_code: "HLLB",
    iata_country_code: "LY",
    iata_code: "BEN",
    city_name: "Benghazi",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Benin Airport",
    longitude: 5.600615,
    latitude: 6.31724,
    id: "arp_bni_ng",
    icao_code: "DNBE",
    iata_country_code: "NG",
    iata_code: "BNI",
    city_name: "Benin",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Benito Juarez International Airport",
    longitude: -99.072003,
    latitude: 19.436188,
    id: "arp_mex_mx",
    icao_code: "MMMX",
    iata_country_code: "MX",
    iata_code: "MEX",
    city_name: "Mexico City",
    city: {
      name: "Mexico City",
      id: "cit_mex_mx",
      iata_country_code: "MX",
      iata_code: "MEX",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "Benito Salas Airport",
    longitude: -75.294058,
    latitude: 2.948736,
    id: "arp_nva_co",
    icao_code: "SKNV",
    iata_country_code: "CO",
    iata_code: "NVA",
    city_name: "Neiva",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Benjamín Rivera Noriega Airport",
    longitude: -65.304252,
    latitude: 18.312975,
    id: "arp_cpx_pr",
    icao_code: "TJCP",
    iata_country_code: "PR",
    iata_code: "CPX",
    city_name: "Culebra",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Benjina Airport",
    longitude: 134.275769,
    latitude: -6.064024,
    id: "arp_bjk_id",
    icao_code: "WAPK",
    iata_country_code: "ID",
    iata_code: "BJK",
    city_name: "Nangasuri Aru Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bensbach Airport",
    longitude: 141.260088,
    latitude: -8.858554,
    id: "arp_bsp_pg",
    icao_code: "AYBH",
    iata_country_code: "PG",
    iata_code: "BSP",
    city_name: "Bensbach",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Ben Slimane Airport",
    longitude: -7.222809,
    latitude: 33.654973,
    id: "arp_gmd_ma",
    icao_code: "GMMB",
    iata_country_code: "MA",
    iata_code: "GMD",
    city_name: "Ben Slimane",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Benson Municipal Airport",
    longitude: -95.650069,
    latitude: 45.329052,
    id: "arp_bbb_us",
    icao_code: "KBBB",
    iata_country_code: "US",
    iata_code: "BBB",
    city_name: "Benson",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Bento Gonçalves Airport",
    longitude: -51.518705,
    latitude: -29.165589,
    id: "arp_bgv_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "BGV",
    city_name: "Bento Gonçalves",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Benton Field",
    longitude: -122.407569,
    latitude: 40.57455,
    id: "arp_bzf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BZF",
    city_name: "Redding",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Bentota River Airport",
    longitude: 79.994667,
    latitude: 6.429255,
    id: "arp_bjt_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "BJT",
    city_name: "Bentota",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Ben Ya'akov Airport",
    longitude: 35.571975,
    latitude: 32.980131,
    id: "arp_rpn_il",
    icao_code: "LLIB",
    iata_country_code: "IL",
    iata_code: "RPN",
    city_name: "Rosh Pina",
    city: null,
  },
  {
    time_zone: "America/St_Vincent",
    name: "Bequia Airport",
    longitude: -61.261847,
    latitude: 12.989303,
    id: "arp_bqu_vc",
    icao_code: "TVSB",
    iata_country_code: "VC",
    iata_code: "BQU",
    city_name: "Bequia",
    city: null,
  },
  {
    time_zone: "Europe/Podgorica",
    name: "Berane Airport",
    longitude: 19.862295,
    latitude: 42.841718,
    id: "arp_ivg_me",
    icao_code: "LYBR",
    iata_country_code: "ME",
    iata_code: "IVG",
    city_name: "Berane",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Berbera Airport",
    longitude: 44.942341,
    latitude: 10.390266,
    id: "arp_bbo_so",
    icao_code: "HCMI",
    iata_country_code: "SO",
    iata_code: "BBO",
    city_name: "Berbera",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Berbérati Airport",
    longitude: 15.786744,
    latitude: 4.220272,
    id: "arp_bbt_cf",
    icao_code: "FEFT",
    iata_country_code: "CF",
    iata_code: "BBT",
    city_name: "Berbérati",
    city: null,
  },
  {
    time_zone: "Europe/Zaporozhye",
    name: "Berdyansk Airport",
    longitude: 36.761073,
    latitude: 46.812996,
    id: "arp_erd_ua",
    icao_code: "UKDB",
    iata_country_code: "UA",
    iata_code: "ERD",
    city_name: "Berdyansk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bereina Airport",
    longitude: 146.483,
    latitude: -8.65,
    id: "arp_bea_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BEA",
    city_name: "Bereina",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Berens River Airport",
    longitude: -97.017619,
    latitude: 52.358937,
    id: "arp_ybv_ca",
    icao_code: "CYBV",
    iata_country_code: "CA",
    iata_code: "YBV",
    city_name: "Berens River",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Berezovo Airport",
    longitude: 65.044686,
    latitude: 63.924124,
    id: "arp_ezv_ru",
    icao_code: "USHB",
    iata_country_code: "RU",
    iata_code: "EZV",
    city_name: "Berezovo",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Bergen Airport",
    longitude: 5.220079,
    latitude: 60.292615,
    id: "arp_bgo_no",
    icao_code: "ENBR",
    iata_country_code: "NO",
    iata_code: "BGO",
    city_name: "Bergen",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Bergerac Dordogne Périgord Airport",
    longitude: 0.518619,
    latitude: 44.824873,
    id: "arp_egc_fr",
    icao_code: "LFBE",
    iata_country_code: "FR",
    iata_code: "EGC",
    city_name: "Bergerac",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Beringin Airport",
    longitude: 114.892934,
    latitude: -0.940549,
    id: "arp_gxa_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "GXA",
    city_name: "Muara Teweh",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Berlevåg Airport",
    longitude: 29.03198,
    latitude: 70.870717,
    id: "arp_bvg_no",
    icao_code: "ENBV",
    iata_country_code: "NO",
    iata_code: "BVG",
    city_name: "Berlevåg",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Berlin Brandenburg Airport",
    longitude: 13.504906,
    latitude: 52.362307,
    id: "arp_ber_de",
    icao_code: null,
    iata_country_code: "DE",
    iata_code: "BER",
    city_name: "Berlin",
    city: {
      name: "Berlin",
      id: "cit_ber_de",
      iata_country_code: "DE",
      iata_code: "BER",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Berlin Regional Airport",
    longitude: -71.176198,
    latitude: 44.575435,
    id: "arp_bml_us",
    icao_code: "KBML",
    iata_country_code: "US",
    iata_code: "BML",
    city_name: "Berlin",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Berlin-Schönefeld Airport",
    longitude: 13.522205,
    latitude: 52.381814,
    id: "arp_sxf_de",
    icao_code: "EDDB",
    iata_country_code: "DE",
    iata_code: "SXF",
    city_name: "Berlin",
    city: {
      name: "Berlin",
      id: "cit_ber_de",
      iata_country_code: "DE",
      iata_code: "BER",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Berlin Tegel Airport",
    longitude: 13.288889,
    latitude: 52.558044,
    id: "arp_txl_de",
    icao_code: "EDDT",
    iata_country_code: "DE",
    iata_code: "TXL",
    city_name: "Berlin",
    city: {
      name: "Berlin",
      id: "cit_ber_de",
      iata_country_code: "DE",
      iata_code: "BER",
    },
  },
  {
    time_zone: "America/La_Paz",
    name: "Bermejo Airport",
    longitude: -64.312695,
    latitude: -22.771497,
    id: "arp_bjo_bo",
    icao_code: "SLBJ",
    iata_country_code: "BO",
    iata_code: "BJO",
    city_name: "Bermejo",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bermuda Dunes Airport",
    longitude: -116.272863,
    latitude: 33.747021,
    id: "arp_udd_us",
    icao_code: "KUDD",
    iata_country_code: "US",
    iata_code: "UDD",
    city_name: "Palm Springs",
    city: {
      name: "Palm Springs",
      id: "cit_psp_us",
      iata_country_code: "US",
      iata_code: "PSP",
    },
  },
  {
    time_zone: "Europe/Zurich",
    name: "Bern Airport",
    longitude: 7.498158,
    latitude: 46.913161,
    id: "arp_brn_ch",
    icao_code: "LSZB",
    iata_country_code: "CH",
    iata_code: "BRN",
    city_name: "Bern",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Bert Mooney Airport",
    longitude: -112.498114,
    latitude: 45.954827,
    id: "arp_btm_us",
    icao_code: "KBTM",
    iata_country_code: "US",
    iata_code: "BTM",
    city_name: "Butte",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Bertoua Airport",
    longitude: 13.726799,
    latitude: 4.544721,
    id: "arp_bta_cm",
    icao_code: "FKKO",
    iata_country_code: "CM",
    iata_code: "BTA",
    city_name: "Bertoua",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Beru Airport",
    longitude: 176.00655,
    latitude: -1.354449,
    id: "arp_bez_ki",
    icao_code: "NGBR",
    iata_country_code: "KI",
    iata_code: "BEZ",
    city_name: "Beru",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Besakoa Airport",
    longitude: 47.061961,
    latitude: -15.67278,
    id: "arp_bsv_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "BSV",
    city_name: "Besakoa",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Besalampy Airport",
    longitude: 44.481607,
    latitude: -16.745049,
    id: "arp_bpy_mg",
    icao_code: "FMNQ",
    iata_country_code: "MG",
    iata_code: "BPY",
    city_name: "Besalampy",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Beslan Airport",
    longitude: 44.604411,
    latitude: 43.203008,
    id: "arp_ogz_ru",
    icao_code: "URMO",
    iata_country_code: "RU",
    iata_code: "OGZ",
    city_name: "Vladikavkaz",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Bethel Airport",
    longitude: -161.837887,
    latitude: 60.779761,
    id: "arp_bet_us",
    icao_code: "PABE",
    iata_country_code: "US",
    iata_code: "BET",
    city_name: "Bethel",
    city: {
      name: "Bethel",
      id: "cit_bet_us",
      iata_country_code: "US",
      iata_code: "BET",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Bethel Seaplane Base",
    longitude: -161.743063,
    latitude: 60.782013,
    id: "arp_jbt_us",
    icao_code: "PABE",
    iata_country_code: "US",
    iata_code: "JBT",
    city_name: "Bethel",
    city: {
      name: "Bethel",
      id: "cit_bet_us",
      iata_country_code: "US",
      iata_code: "BET",
    },
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Betioky Airport",
    longitude: 44.388649,
    latitude: -23.730972,
    id: "arp_bku_mg",
    icao_code: "FMSV",
    iata_country_code: "MG",
    iata_code: "BKU",
    city_name: "Betioky",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Betoota Airport",
    longitude: 140.788572,
    latitude: -25.64147,
    id: "arp_btx_au",
    icao_code: "YBEO",
    iata_country_code: "AU",
    iata_code: "BTX",
    city_name: "Betoota",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Betou Airport",
    longitude: 18.516453,
    latitude: 3.057902,
    id: "arp_btb_cg",
    icao_code: "FCOT",
    iata_country_code: "CG",
    iata_code: "BTB",
    city_name: "Betou",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Bettles Airport",
    longitude: -151.524036,
    latitude: 66.916597,
    id: "arp_btt_us",
    icao_code: "PABT",
    iata_country_code: "US",
    iata_code: "BTT",
    city_name: "Bettles",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Beverley Springs Airport",
    longitude: 125.436119,
    latitude: -16.733145,
    id: "arp_bvz_au",
    icao_code: "YBYS",
    iata_country_code: "AU",
    iata_code: "BVZ",
    city_name: "Beverley Springs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Beverly Municipal Airport",
    longitude: -70.915888,
    latitude: 42.584302,
    id: "arp_bvy_us",
    icao_code: "KBVY",
    iata_country_code: "US",
    iata_code: "BVY",
    city_name: "Beverly",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bewani Airport",
    longitude: 141.164403,
    latitude: -3.021195,
    id: "arp_bwp_pg",
    icao_code: "AYBI",
    iata_country_code: "PG",
    iata_code: "BWP",
    city_name: "Bewani",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Béziers Cap d'Agde Airport",
    longitude: 3.354113,
    latitude: 43.322544,
    id: "arp_bzr_fr",
    icao_code: "LFMU",
    iata_country_code: "FR",
    iata_code: "BZR",
    city_name: "Beziers",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Bezmer Air Base",
    longitude: 26.349517,
    latitude: 42.454282,
    id: "arp_jam_bg",
    icao_code: "LBIA",
    iata_country_code: "BG",
    iata_code: "JAM",
    city_name: "Yambol",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Bhadrapur Airport",
    longitude: 88.078408,
    latitude: 26.570013,
    id: "arp_bdp_np",
    icao_code: "VNCG",
    iata_country_code: "NP",
    iata_code: "BDP",
    city_name: "Bhadrapur",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Bhagatanwala Airport",
    longitude: 72.947867,
    latitude: 32.056011,
    id: "arp_bhw_pk",
    icao_code: "OPBG",
    iata_country_code: "PK",
    iata_code: "BHW",
    city_name: "Sargodha",
    city: {
      name: "Sargodha",
      id: "cit_sgi_pk",
      iata_country_code: "PK",
      iata_code: "SGI",
    },
  },
  {
    time_zone: "Asia/Yangon",
    name: "Bhamo Airport",
    longitude: 97.247381,
    latitude: 24.268498,
    id: "arp_bmo_mm",
    icao_code: "VYBM",
    iata_country_code: "MM",
    iata_code: "BMO",
    city_name: "Bhamo",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Bharatpur Airport",
    longitude: 84.429302,
    latitude: 27.677954,
    id: "arp_bhr_np",
    icao_code: "VNBP",
    iata_country_code: "NP",
    iata_code: "BHR",
    city_name: "Bharatpur",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Bhavnagar Airport",
    longitude: 72.18527,
    latitude: 21.752758,
    id: "arp_bhu_in",
    icao_code: "VABV",
    iata_country_code: "IN",
    iata_code: "BHU",
    city_name: "Bhavnagar",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Bhisho Airport",
    longitude: 27.295026,
    latitude: -32.89065,
    id: "arp_biy_za",
    icao_code: "FABE",
    iata_country_code: "ZA",
    iata_code: "BIY",
    city_name: "Bisho",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Bhojpur Airport",
    longitude: 87.050821,
    latitude: 27.147667,
    id: "arp_bhp_np",
    icao_code: "VNBJ",
    iata_country_code: "NP",
    iata_code: "BHP",
    city_name: "Bhojpur",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Bhuj Airport",
    longitude: 69.670324,
    latitude: 23.287074,
    id: "arp_bhj_in",
    icao_code: "VABJ",
    iata_country_code: "IN",
    iata_code: "BHJ",
    city_name: "Bhuj",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Biała Podlaska Airport",
    longitude: 23.132528,
    latitude: 52.00078,
    id: "arp_bxp_pl",
    icao_code: "EPBP",
    iata_country_code: "PL",
    iata_code: "BXP",
    city_name: "Biała Podlaska",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bialla Airport",
    longitude: 151.008046,
    latitude: -5.330706,
    id: "arp_baa_pg",
    icao_code: "AYBL",
    iata_country_code: "PG",
    iata_code: "BAA",
    city_name: "Bialla",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Biangabip Airport",
    longitude: 141.744723,
    latitude: -5.526346,
    id: "arp_bpk_pg",
    icao_code: "AYBQ",
    iata_country_code: "PG",
    iata_code: "BPK",
    city_name: "Biangabip",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Biarritz Pays Basque Airport",
    longitude: -1.530406,
    latitude: 43.469375,
    id: "arp_biq_fr",
    icao_code: "LFBZ",
    iata_country_code: "FR",
    iata_code: "BIQ",
    city_name: "Biarritz",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Biaru Airport",
    longitude: 146.759786,
    latitude: -7.66947,
    id: "arp_brp_pg",
    icao_code: "AYBR",
    iata_country_code: "PG",
    iata_code: "BRP",
    city_name: "Biaru",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Biawonque Airport",
    longitude: 9.45,
    latitude: -0.66667,
    id: "arp_baw_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "BAW",
    city_name: "Biawonque",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Bickerton Island Airport",
    longitude: 136.197707,
    latitude: -13.780576,
    id: "arp_bcz_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "BCZ",
    city_name: "Bickerton Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bicycle Lake Army Airfield",
    longitude: -116.630008,
    latitude: 35.280509,
    id: "arp_bys_us",
    icao_code: "KBYS",
    iata_country_code: "US",
    iata_code: "BYS",
    city_name: "Barstow/Fort Irwin",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Bielefeld Airport",
    longitude: 8.54738,
    latitude: 51.965006,
    id: "arp_bfe_de",
    icao_code: "EDLI",
    iata_country_code: "DE",
    iata_code: "BFE",
    city_name: "Bielefeld",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Big Bay Marina Airport",
    longitude: -125.133,
    latitude: 50.3833,
    id: "arp_yig_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YIG",
    city_name: "Stuart Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Big Bay Yacht Club Airport,Sanhe Airport",
    longitude: 113.277568,
    latitude: 29.313502,
    id: "arp_yya_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "YYA",
    city_name: "Yueyang",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Big Bear City Airport",
    longitude: -116.854828,
    latitude: 34.262761,
    id: "arp_rbf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RBF",
    city_name: "Big Bear City",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Big Creek Airport",
    longitude: -88.409811,
    latitude: 16.52059,
    id: "arp_bgk_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "BGK",
    city_name: "Big Creek",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Biggs Army Airfield",
    longitude: -106.379953,
    latitude: 31.849353,
    id: "arp_bif_us",
    icao_code: "KBIF",
    iata_country_code: "US",
    iata_code: "BIF",
    city_name: "El Paso",
    city: {
      name: "El Paso",
      id: "cit_elp_us",
      iata_country_code: "US",
      iata_code: "ELP",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Big Lake Airport",
    longitude: -149.813934,
    latitude: 61.535745,
    id: "arp_bgq_us",
    icao_code: "PAGQ",
    iata_country_code: "US",
    iata_code: "BGQ",
    city_name: "Big Lake",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Big Mountain Airport",
    longitude: -155.258942,
    latitude: 59.361214,
    id: "arp_bmx_us",
    icao_code: "PABM",
    iata_country_code: "US",
    iata_code: "BMX",
    city_name: "Big Mountain",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Big Spring McMahon–Wrinkle Airport",
    longitude: -101.527449,
    latitude: 32.231335,
    id: "arp_hca_us",
    icao_code: "KBPG",
    iata_country_code: "US",
    iata_code: "HCA",
    city_name: "Big Spring",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Big Trout Lake Airport",
    longitude: -89.895043,
    latitude: 53.816897,
    id: "arp_ytl_ca",
    icao_code: "CYTL",
    iata_country_code: "CA",
    iata_code: "YTL",
    city_name: "Big Trout Lake",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Bijie Feixiong Airport",
    longitude: 105.472096,
    latitude: 27.267065,
    id: "arp_bfj_cn",
    icao_code: "ZUBJ",
    iata_country_code: "CN",
    iata_code: "BFJ",
    city_name: "Bijie",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Biju Patnaik Airport",
    longitude: 85.81684,
    latitude: 20.248554,
    id: "arp_bbi_in",
    icao_code: "VEBS",
    iata_country_code: "IN",
    iata_code: "BBI",
    city_name: "Bhubaneswar",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Bikini Atoll Airport",
    longitude: 165.565625,
    latitude: 11.522925,
    id: "arp_bii_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "BII",
    city_name: "Bikini Atoll",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Bilaspur Airport",
    longitude: 82.112821,
    latitude: 21.991494,
    id: "arp_pab_in",
    icao_code: "VEBU",
    iata_country_code: "IN",
    iata_code: "PAB",
    city_name: "Bilaspur",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Bilbao Airport",
    longitude: -2.910862,
    latitude: 43.301797,
    id: "arp_bio_es",
    icao_code: "LEBB",
    iata_country_code: "ES",
    iata_code: "BIO",
    city_name: "Bilbao",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Bildudalur Airport",
    longitude: -23.54634,
    latitude: 65.641485,
    id: "arp_biu_is",
    icao_code: "BIBD",
    iata_country_code: "IS",
    iata_code: "BIU",
    city_name: "Bildudalur",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Biliau Airport",
    longitude: 146.346363,
    latitude: -5.582137,
    id: "arp_bij_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BIJ",
    city_name: "Biliau",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bill and Hillary Clinton National Airport",
    longitude: -92.222975,
    latitude: 34.730053,
    id: "arp_lit_us",
    icao_code: "KLIT",
    iata_country_code: "US",
    iata_code: "LIT",
    city_name: "Little Rock",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Billiluna Airport",
    longitude: 127.669958,
    latitude: -19.56595,
    id: "arp_biw_au",
    icao_code: "YBIL",
    iata_country_code: "AU",
    iata_code: "BIW",
    city_name: "Billiluna",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Billings Logan International Airport",
    longitude: -108.542345,
    latitude: 45.806167,
    id: "arp_bil_us",
    icao_code: "KBIL",
    iata_country_code: "US",
    iata_code: "BIL",
    city_name: "Billings",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Billund Airport",
    longitude: 9.15219,
    latitude: 55.740553,
    id: "arp_bll_dk",
    icao_code: "EKBI",
    iata_country_code: "DK",
    iata_code: "BLL",
    city_name: "Billund",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Billy Bishop Toronto City Airport",
    longitude: -79.396079,
    latitude: 43.62799,
    id: "arp_ytz_ca",
    icao_code: "CYTZ",
    iata_country_code: "CA",
    iata_code: "YTZ",
    city_name: "Toronto",
    city: {
      name: "Toronto",
      id: "cit_yto_ca",
      iata_country_code: "CA",
      iata_code: "YTO",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Billy Mitchell Airport",
    longitude: -75.617749,
    latitude: 35.23272,
    id: "arp_hnc_us",
    icao_code: "KHSE",
    iata_country_code: "US",
    iata_code: "HNC",
    city_name: "Hatteras",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Biloela Airport",
    longitude: 150.573388,
    latitude: -24.49164,
    id: "arp_zbl_au",
    icao_code: "YTNG",
    iata_country_code: "AU",
    iata_code: "ZBL",
    city_name: "Biloela",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Bilogai-Sugapa Airport",
    longitude: 137.031998,
    latitude: -3.73956,
    id: "arp_ugu_id",
    icao_code: "WABV",
    iata_country_code: "ID",
    iata_code: "UGU",
    city_name: "Bilogai",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Bima Sultan Muhammad Salahudin Airport",
    longitude: 118.688352,
    latitude: -8.540187,
    id: "arp_bmu_id",
    icao_code: "WADB",
    iata_country_code: "ID",
    iata_code: "BMU",
    city_name: "Bima",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bimin Airport",
    longitude: 142.033377,
    latitude: -5.286367,
    id: "arp_biz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BIZ",
    city_name: "Bimin",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Binaka Airport",
    longitude: 97.704693,
    latitude: 1.16559,
    id: "arp_gns_id",
    icao_code: "WIMB",
    iata_country_code: "ID",
    iata_code: "GNS",
    city_name: "Gunungsitoli",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Bindlacher Berg Airport",
    longitude: 11.63963,
    latitude: 49.984908,
    id: "arp_byu_de",
    icao_code: "EDQD",
    iata_country_code: "DE",
    iata_code: "BYU",
    city_name: "Bayreuth",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Bingöl Airport",
    longitude: 40.593803,
    latitude: 38.859786,
    id: "arp_bgg_tr",
    icao_code: "LTCU",
    iata_country_code: "TR",
    iata_code: "BGG",
    city_name: "Bingöl",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Biniguni Airport",
    longitude: 149.303889,
    latitude: -9.6425,
    id: "arp_xbn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "XBN",
    city_name: "Biniguni",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Bintulu Airport",
    longitude: 113.022007,
    latitude: 3.12383,
    id: "arp_btu_my",
    icao_code: "WBGB",
    iata_country_code: "MY",
    iata_code: "BTU",
    city_name: "Bintulu",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Birao Airport",
    longitude: 22.716614,
    latitude: 10.236606,
    id: "arp_iro_cf",
    icao_code: "FEFI",
    iata_country_code: "CF",
    iata_code: "IRO",
    city_name: "Birao",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Biratnagar Airport",
    longitude: 87.264734,
    latitude: 26.482334,
    id: "arp_bir_np",
    icao_code: "VNVT",
    iata_country_code: "NP",
    iata_code: "BIR",
    city_name: "Biratnagar",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Birch Creek Airport",
    longitude: -145.819948,
    latitude: 66.274407,
    id: "arp_kbc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KBC",
    city_name: "Birch Creek",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Birchwood Airport",
    longitude: -88.77829,
    latitude: 17.270336,
    id: "arp_bcv_bz",
    icao_code: "MZBP",
    iata_country_code: "BZ",
    iata_code: "BCV",
    city_name: "Belmopan",
    city: null,
  },
  {
    time_zone: "Indian/Mahe",
    name: "Bird Island Airport",
    longitude: 55.205453,
    latitude: -3.723536,
    id: "arp_bdi_sc",
    icao_code: "FSSB",
    iata_country_code: "SC",
    iata_code: "BDI",
    city_name: "Bird Island",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Birdsville Airport",
    longitude: 139.347431,
    latitude: -25.895823,
    id: "arp_bvi_au",
    icao_code: "YBDV",
    iata_country_code: "AU",
    iata_code: "BVI",
    city_name: "Birdsville",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Birjand Airport",
    longitude: 59.29071,
    latitude: 32.89025,
    id: "arp_xbj_ir",
    icao_code: "OIMB",
    iata_country_code: "IR",
    iata_code: "XBJ",
    city_name: "Birjand",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Birmingham Airport",
    longitude: -1.745768,
    latitude: 52.453141,
    id: "arp_bhx_gb",
    icao_code: "EGBB",
    iata_country_code: "GB",
    iata_code: "BHX",
    city_name: "Birmingham",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Birmingham-Shuttlesworth International Airport",
    longitude: -86.753813,
    latitude: 33.562663,
    id: "arp_bhm_us",
    icao_code: "KBHM",
    iata_country_code: "US",
    iata_code: "BHM",
    city_name: "Birmingham",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Birsa Munda Airport",
    longitude: 85.323489,
    latitude: 23.315744,
    id: "arp_ixr_in",
    icao_code: "VERC",
    iata_country_code: "IN",
    iata_code: "IXR",
    city_name: "Ranchi",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Bisbee-Douglas International Airport",
    longitude: -109.597852,
    latitude: 31.458171,
    id: "arp_dug_us",
    icao_code: "KDUG",
    iata_country_code: "US",
    iata_code: "DUG",
    city_name: "Bisbee/Douglas",
    city: {
      name: "Douglas/Bisbee",
      id: "cit_dug_us",
      iata_country_code: "US",
      iata_code: "DUG",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Bisbee Municipal Airport",
    longitude: -109.883811,
    latitude: 31.366342,
    id: "arp_bsq_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BSQ",
    city_name: "Bisbee",
    city: {
      name: "Douglas/Bisbee",
      id: "cit_dug_us",
      iata_country_code: "US",
      iata_code: "DUG",
    },
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Bisha Domestic Airport",
    longitude: 42.621275,
    latitude: 19.984923,
    id: "arp_bhh_sa",
    icao_code: "OEBH",
    iata_country_code: "SA",
    iata_code: "BHH",
    city_name: "Bisha",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bishe Kola Air Base",
    longitude: 52.349149,
    latitude: 36.655429,
    id: "arp_bsm_ir",
    icao_code: "OINJ",
    iata_country_code: "IR",
    iata_code: "BSM",
    city_name: "Amol",
    city: null,
  },
  {
    time_zone: "Asia/Bishkek",
    name: "Bishkek Manas International Airport",
    longitude: 74.474447,
    latitude: 43.058822,
    id: "arp_fru_kg",
    icao_code: "UCFM",
    iata_country_code: "KG",
    iata_code: "FRU",
    city_name: "Bishkek",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Bishop International Airport",
    longitude: -83.742604,
    latitude: 42.968253,
    id: "arp_fnt_us",
    icao_code: "KFNT",
    iata_country_code: "US",
    iata_code: "FNT",
    city_name: "Flint",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Biskra Airport",
    longitude: 5.735905,
    latitude: 34.792619,
    id: "arp_bsk_dz",
    icao_code: "DAUB",
    iata_country_code: "DZ",
    iata_code: "BSK",
    city_name: "Biskra",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Bislig Airport",
    longitude: 126.322591,
    latitude: 8.196215,
    id: "arp_bph_ph",
    icao_code: "RPMF",
    iata_country_code: "PH",
    iata_code: "BPH",
    city_name: "Bislig",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bismarck Municipal Airport",
    longitude: -100.749906,
    latitude: 46.773221,
    id: "arp_bis_us",
    icao_code: "KBIS",
    iata_country_code: "US",
    iata_code: "BIS",
    city_name: "Bismarck",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Bitam Airport",
    longitude: 11.492252,
    latitude: 2.076732,
    id: "arp_bmm_ga",
    icao_code: "FOOB",
    iata_country_code: "GA",
    iata_code: "BMM",
    city_name: "Bitam",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Bitburg Airport",
    longitude: 6.561619,
    latitude: 49.945509,
    id: "arp_bbj_de",
    icao_code: "EDRB",
    iata_country_code: "DE",
    iata_code: "BBJ",
    city_name: "Bitburg",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bizant Airport",
    longitude: 144.11937,
    latitude: -14.740337,
    id: "arp_bzp_au",
    icao_code: "YBIZ",
    iata_country_code: "AU",
    iata_code: "BZP",
    city_name: "Bizant",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Blackall Airport",
    longitude: 145.431423,
    latitude: -24.428111,
    id: "arp_bkq_au",
    icao_code: "YBCK",
    iata_country_code: "AU",
    iata_code: "BKQ",
    city_name: "Blackall",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Blackbushe Airport",
    longitude: -0.843418,
    latitude: 51.323314,
    id: "arp_bbs_gb",
    icao_code: "EGLK",
    iata_country_code: "GB",
    iata_code: "BBS",
    city_name: "Blackbushe",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Black Hills Airport",
    longitude: -103.783677,
    latitude: 44.480848,
    id: "arp_spf_us",
    icao_code: "KSPF",
    iata_country_code: "US",
    iata_code: "SPF",
    city_name: "Spearfish",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Blackpool Airport",
    longitude: -3.033024,
    latitude: 53.773456,
    id: "arp_blk_gb",
    icao_code: "EGNH",
    iata_country_code: "GB",
    iata_code: "BLK",
    city_name: "Blackpool",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Blackstone Army Airfield",
    longitude: -77.954166,
    latitude: 37.073993,
    id: "arp_bkt_us",
    icao_code: "KBKT",
    iata_country_code: "US",
    iata_code: "BKT",
    city_name: "Ft Pickett/Blackstone",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Black Tickle Airport",
    longitude: -55.785156,
    latitude: 53.46932,
    id: "arp_ybi_ca",
    icao_code: "CCE4",
    iata_country_code: "CA",
    iata_code: "YBI",
    city_name: "Black Tickle",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Blackwater Airport",
    longitude: 148.807471,
    latitude: -23.602794,
    id: "arp_blt_au",
    icao_code: "YBTR",
    iata_country_code: "AU",
    iata_code: "BLT",
    city_name: "Blackwater",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Blackwell–Tonkawa Municipal Airport",
    longitude: -97.315729,
    latitude: 36.794589,
    id: "arp_bwl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BWL",
    city_name: "Blackwell",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Blaise Diagne International Airport",
    longitude: -17.071337,
    latitude: 14.670284,
    id: "arp_dss_sn",
    icao_code: "GOBD",
    iata_country_code: "SN",
    iata_code: "DSS",
    city_name: "Dakar",
    city: {
      name: "Dakar",
      id: "cit_dkr_sn",
      iata_country_code: "SN",
      iata_code: "DKR",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Blakely Island Airport",
    longitude: -122.826193,
    latitude: 48.578994,
    id: "arp_byw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BYW",
    city_name: "Blakely Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Blanding Municipal Airport",
    longitude: -109.481347,
    latitude: 37.582641,
    id: "arp_bdg_us",
    icao_code: "KBDG",
    iata_country_code: "US",
    iata_code: "BDG",
    city_name: "Blanding",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Blangpidie Airport",
    longitude: 96.791111,
    latitude: 3.734444,
    id: "arp_kjx_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "KJX",
    city_name: "Blang Pidie",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Blimbingsari Airport",
    longitude: 114.339863,
    latitude: -8.310889,
    id: "arp_bwx_id",
    icao_code: "WADY",
    iata_country_code: "ID",
    iata_code: "BWX",
    city_name: "Banyuwangi",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Block Island State Airport",
    longitude: -71.578493,
    latitude: 41.16844,
    id: "arp_bid_us",
    icao_code: "KBID",
    iata_country_code: "US",
    iata_code: "BID",
    city_name: "Block Island",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Blönduós Airport",
    longitude: -20.287538,
    latitude: 65.647719,
    id: "arp_blo_is",
    icao_code: "BIBL",
    iata_country_code: "IS",
    iata_code: "BLO",
    city_name: "Blönduós",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Bloodvein River Airport",
    longitude: -96.692906,
    latitude: 51.784376,
    id: "arp_ydv_ca",
    icao_code: "CZTA",
    iata_country_code: "CA",
    iata_code: "YDV",
    city_name: "Bloodvein",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bloomfield Airport",
    longitude: 145.329866,
    latitude: -15.873647,
    id: "arp_bfc_au",
    icao_code: "YBMD",
    iata_country_code: "AU",
    iata_code: "BFC",
    city_name: "Bloomfield",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Blosser Municipal Airport",
    longitude: -97.652247,
    latitude: 39.549212,
    id: "arp_cnk_us",
    icao_code: "KCNK",
    iata_country_code: "US",
    iata_code: "CNK",
    city_name: "Concordia",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Blubber Bay Airport",
    longitude: -124.633054,
    latitude: 49.799758,
    id: "arp_xbb_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "XBB",
    city_name: "Blubber Bay",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Blue Canyon–Nyack Airport",
    longitude: -120.709721,
    latitude: 39.275002,
    id: "arp_blu_us",
    icao_code: "KBLU",
    iata_country_code: "US",
    iata_code: "BLU",
    city_name: "Emigrant Gap",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Bluefields Airport",
    longitude: -83.7733,
    latitude: 11.991472,
    id: "arp_bef_ni",
    icao_code: "MNBL",
    iata_country_code: "NI",
    iata_code: "BEF",
    city_name: "Bluefields",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Blue Grass Airport",
    longitude: -84.604255,
    latitude: 38.03713,
    id: "arp_lex_us",
    icao_code: "KLEX",
    iata_country_code: "US",
    iata_code: "LEX",
    city_name: "Lexington",
    city: {
      name: "Lexington",
      id: "cit_lex_us",
      iata_country_code: "US",
      iata_code: "LEX",
    },
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Blue Lagoon Seaplane Base",
    longitude: 177.370613,
    latitude: -16.965925,
    id: "arp_bxl_fj",
    icao_code: null,
    iata_country_code: "FJ",
    iata_code: "BXL",
    city_name: "Nanuya Lailai",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Blumenau Airport",
    longitude: -49.091321,
    latitude: -26.831241,
    id: "arp_bnu_br",
    icao_code: "SSBL",
    iata_country_code: "BR",
    iata_code: "BNU",
    city_name: "Blumenau",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Blythe Airport",
    longitude: -114.716505,
    latitude: 33.618681,
    id: "arp_blh_us",
    icao_code: "KBLH",
    iata_country_code: "US",
    iata_code: "BLH",
    city_name: "Blythe",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Blytheville Municipal Airport",
    longitude: -89.83193,
    latitude: 35.93796,
    id: "arp_hka_us",
    icao_code: "KHKA",
    iata_country_code: "US",
    iata_code: "HKA",
    city_name: "Blytheville",
    city: {
      name: "Blytheville",
      id: "cit_byh_us",
      iata_country_code: "US",
      iata_code: "BYH",
    },
  },
  {
    time_zone: "Africa/Freetown",
    name: "Bo Airport",
    longitude: -11.761222,
    latitude: 7.94418,
    id: "arp_kbs_sl",
    icao_code: "GFBO",
    iata_country_code: "SL",
    iata_code: "KBS",
    city_name: "Bo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Boana Airport",
    longitude: 146.816002,
    latitude: -6.418703,
    id: "arp_bnv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BNV",
    city_name: "Boana",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Boang Airport",
    longitude: 152.629505,
    latitude: -3.04424,
    id: "arp_bov_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BOV",
    city_name: "Boang Island",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Boa Vista-Atlas Brasil Cantanhede International Airport",
    longitude: -60.691956,
    latitude: 2.841758,
    id: "arp_bvb_br",
    icao_code: "SBBV",
    iata_country_code: "BR",
    iata_code: "BVB",
    city_name: "Boa Vista",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Bob Baker Memorial Airport",
    longitude: -160.436187,
    latitude: 66.975893,
    id: "arp_ian_us",
    icao_code: "PAIK",
    iata_country_code: "US",
    iata_code: "IAN",
    city_name: "Kiana",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Bobo Dioulasso Airport",
    longitude: -4.330366,
    latitude: 11.160297,
    id: "arp_boy_bf",
    icao_code: "DFOO",
    iata_country_code: "BF",
    iata_code: "BOY",
    city_name: "Bobo Dioulasso",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Bob Quinn Lake Airport",
    longitude: -130.25,
    latitude: 56.96669,
    id: "arp_ybo_ca",
    icao_code: "CBW4",
    iata_country_code: "CA",
    iata_code: "YBO",
    city_name: "Bob Quinn Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bob Sikes Airport",
    longitude: -86.523374,
    latitude: 30.778,
    id: "arp_cew_us",
    icao_code: "KCEW",
    iata_country_code: "US",
    iata_code: "CEW",
    city_name: "Crestview",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Boca Raton Airport",
    longitude: -80.104207,
    latitude: 26.380671,
    id: "arp_bct_us",
    icao_code: "KBCT",
    iata_country_code: "US",
    iata_code: "BCT",
    city_name: "Boca Raton",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: 'Bocas del Toro "Isla Colón" International Airport',
    longitude: -82.249205,
    latitude: 9.340767,
    id: "arp_boc_pa",
    icao_code: "MPBO",
    iata_country_code: "PA",
    iata_code: "BOC",
    city_name: "Isla Colón",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Bodaybo Airport",
    longitude: 114.242996,
    latitude: 57.8661,
    id: "arp_odo_ru",
    icao_code: "UIKB",
    iata_country_code: "RU",
    iata_code: "ODO",
    city_name: "Bodaybo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bodinumu Airport",
    longitude: 147.666722,
    latitude: -9.107777,
    id: "arp_bnm_pg",
    icao_code: "AYBD",
    iata_country_code: "PG",
    iata_code: "BNM",
    city_name: "Bodinumu",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Bodø Airport",
    longitude: 14.363768,
    latitude: 67.268756,
    id: "arp_boo_no",
    icao_code: "ENBO",
    iata_country_code: "NO",
    iata_code: "BOO",
    city_name: "Bodø",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Bodrum-Imsik Airport",
    longitude: 27.668477,
    latitude: 37.139332,
    id: "arp_bxn_tr",
    icao_code: "LTBV",
    iata_country_code: "TR",
    iata_code: "BXN",
    city_name: "Bodrum",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Boende Airport",
    longitude: 20.85,
    latitude: -0.216999,
    id: "arp_bnb_cd",
    icao_code: "FZGN",
    iata_country_code: "CD",
    iata_code: "BNB",
    city_name: "Boende",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Bogande Airport",
    longitude: -0.162377,
    latitude: 12.981037,
    id: "arp_xbg_bf",
    icao_code: "DFEB",
    iata_country_code: "BF",
    iata_code: "XBG",
    city_name: "Bogande",
    city: null,
  },
  {
    time_zone: "Asia/Tomsk",
    name: "Bogashevo Airport",
    longitude: 85.20851,
    latitude: 56.383968,
    id: "arp_tof_ru",
    icao_code: "UNTT",
    iata_country_code: "RU",
    iata_code: "TOF",
    city_name: "Tomsk",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Bogorodskoye Airport",
    longitude: 140.448,
    latitude: 52.38,
    id: "arp_bqg_ru",
    icao_code: "UHNB",
    iata_country_code: "RU",
    iata_code: "BQG",
    city_name: "Bogorodskoye",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Boigu Island Airport",
    longitude: 142.217583,
    latitude: -9.23282,
    id: "arp_gic_au",
    icao_code: "YBOI",
    iata_country_code: "AU",
    iata_code: "GIC",
    city_name: "Boigu Island",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Boise Airport",
    longitude: -116.222658,
    latitude: 43.565112,
    id: "arp_boi_us",
    icao_code: "KBOI",
    iata_country_code: "US",
    iata_code: "BOI",
    city_name: "Boise",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bojnord Airport",
    longitude: 57.308242,
    latitude: 37.491381,
    id: "arp_bjb_ir",
    icao_code: "OIMN",
    iata_country_code: "IR",
    iata_code: "BJB",
    city_name: "Bojnord",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Boké Baralande Airport",
    longitude: -14.281243,
    latitude: 10.965442,
    id: "arp_bkj_gn",
    icao_code: "GUOK",
    iata_country_code: "GN",
    iata_code: "BKJ",
    city_name: "Boké",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Bokondini Airport",
    longitude: 138.67699,
    latitude: -3.683059,
    id: "arp_bui_id",
    icao_code: "WAJB",
    iata_country_code: "ID",
    iata_code: "BUI",
    city_name: "Bokondini",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Bokoro Airport",
    longitude: 17.070719,
    latitude: 12.385521,
    id: "arp_bkr_td",
    icao_code: "FTTK",
    iata_country_code: "TD",
    iata_code: "BKR",
    city_name: "Bokoro",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Bokpyin Airport",
    longitude: 98.735901,
    latitude: 11.1494,
    id: "arp_vbp_mm",
    icao_code: "VYBP",
    iata_country_code: "MM",
    iata_code: "VBP",
    city_name: "Bokpyin",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Boku Airport",
    longitude: 155.217,
    latitude: -6.26667,
    id: "arp_boq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BOQ",
    city_name: "Boku",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Bolaang Airport",
    longitude: 122.771378,
    latitude: -1.039597,
    id: "arp_bjg_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "BJG",
    city_name: "Kota Mobagu",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Bol-Bérim Airport",
    longitude: 14.739019,
    latitude: 13.443275,
    id: "arp_otc_td",
    icao_code: "FTTL",
    iata_country_code: "TD",
    iata_code: "OTC",
    city_name: "Bol",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Bole Alashankou Airport",
    longitude: 82.300905,
    latitude: 44.896764,
    id: "arp_bpl_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "BPL",
    city_name: "Bole",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bollon Airport",
    longitude: 147.482057,
    latitude: -28.054421,
    id: "arp_bls_au",
    icao_code: "YBLL",
    iata_country_code: "AU",
    iata_code: "BLS",
    city_name: "Bollon",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Bologna Guglielmo Marconi Airport",
    longitude: 11.288279,
    latitude: 44.53496,
    id: "arp_blq_it",
    icao_code: "LIPE",
    iata_country_code: "IT",
    iata_code: "BLQ",
    city_name: "Bologna",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bolovip Airport",
    longitude: 141.650397,
    latitude: -5.350844,
    id: "arp_bvp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BVP",
    city_name: "Bolovip",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bolwarra Airport",
    longitude: 144.169227,
    latitude: -17.38765,
    id: "arp_bck_au",
    icao_code: "YBWR",
    iata_country_code: "AU",
    iata_code: "BCK",
    city_name: "Bolwarra",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Bolzano Airport",
    longitude: 11.327754,
    latitude: 46.46114,
    id: "arp_bzo_it",
    icao_code: "LIPB",
    iata_country_code: "IT",
    iata_code: "BZO",
    city_name: "Bolzano",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Boma Airport",
    longitude: 13.063897,
    latitude: -5.854018,
    id: "arp_boa_cd",
    icao_code: "FZAJ",
    iata_country_code: "CD",
    iata_code: "BOA",
    city_name: "Boma",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bomai Airport",
    longitude: 144.636818,
    latitude: -6.373753,
    id: "arp_bmh_pg",
    icao_code: "AYBO",
    iata_country_code: "PG",
    iata_code: "BMH",
    city_name: "Bomai",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Bom Jesus da Lapa Airport",
    longitude: -43.409058,
    latitude: -13.261705,
    id: "arp_laz_br",
    icao_code: "SBLP",
    iata_country_code: "BR",
    iata_code: "LAZ",
    city_name: "Bom Jesus da Lapa",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Bonaventure Airport",
    longitude: -65.459302,
    latitude: 48.069588,
    id: "arp_yvb_ca",
    icao_code: "CYVB",
    iata_country_code: "CA",
    iata_code: "YVB",
    city_name: "Bonaventure",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Bongo Airport",
    longitude: 10.2167,
    latitude: -2.16667,
    id: "arp_bgp_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "BGP",
    city_name: "Bongo",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Bongor Airport",
    longitude: -68.816683,
    latitude: 44.807975,
    id: "arp_ogr_td",
    icao_code: "FTTB",
    iata_country_code: "TD",
    iata_code: "OGR",
    city_name: "Bongor",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Bonito Airport",
    longitude: -56.451164,
    latitude: -21.245245,
    id: "arp_byo_br",
    icao_code: "SBDB",
    iata_country_code: "BR",
    iata_code: "BYO",
    city_name: "Bonito",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Bonnyville Airport",
    longitude: -110.744118,
    latitude: 54.304489,
    id: "arp_yby_ca",
    icao_code: "CYBF",
    iata_country_code: "CA",
    iata_code: "YBY",
    city_name: "Bonnyville",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Bonriki International Airport",
    longitude: 173.146999,
    latitude: 1.381603,
    id: "arp_trw_ki",
    icao_code: "NGTA",
    iata_country_code: "KI",
    iata_code: "TRW",
    city_name: "Tarawa",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Bontang Airport",
    longitude: 117.474817,
    latitude: 0.120375,
    id: "arp_bxt_id",
    icao_code: "WALC",
    iata_country_code: "ID",
    iata_code: "BXT",
    city_name: "Bontang",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Boolgeeda Airport",
    longitude: 117.275048,
    latitude: -22.539979,
    id: "arp_ocm_au",
    icao_code: "YBGD",
    iata_country_code: "AU",
    iata_code: "OCM",
    city_name: "Boolgeeda",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Boone County Airport",
    longitude: -93.154508,
    latitude: 36.26132,
    id: "arp_hro_us",
    icao_code: "KHRO",
    iata_country_code: "US",
    iata_code: "HRO",
    city_name: "Harrison",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Boone Municipal Airport",
    longitude: -93.848714,
    latitude: 42.050533,
    id: "arp_bnw_us",
    icao_code: "KBNW",
    iata_country_code: "US",
    iata_code: "BNW",
    city_name: "Boone",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Booue Airport",
    longitude: 11.943002,
    latitude: -0.106869,
    id: "arp_bgb_ga",
    icao_code: "FOGB",
    iata_country_code: "GA",
    iata_code: "BGB",
    city_name: "Booue",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Bora Bora Airport",
    longitude: -151.75165,
    latitude: -16.444129,
    id: "arp_bob_pf",
    icao_code: "NTTB",
    iata_country_code: "PF",
    iata_code: "BOB",
    city_name: "Bora Bora",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Boraldai Airport",
    longitude: 76.882966,
    latitude: 43.351933,
    id: "arp_bxj_kz",
    icao_code: "UAAR",
    iata_country_code: "KZ",
    iata_code: "BXJ",
    city_name: "Almaty",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Borama Airport",
    longitude: 43.152722,
    latitude: 9.944235,
    id: "arp_bxx_so",
    icao_code: null,
    iata_country_code: "SO",
    iata_code: "BXX",
    city_name: "Borama",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Borba Airport",
    longitude: -59.602401,
    latitude: -4.406259,
    id: "arp_rbb_br",
    icao_code: "SWBR",
    iata_country_code: "BR",
    iata_code: "RBB",
    city_name: "Borba",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Bordeaux-Mérignac Airport",
    longitude: -0.71293,
    latitude: 44.829447,
    id: "arp_bod_fr",
    icao_code: "LFBD",
    iata_country_code: "FR",
    iata_code: "BOD",
    city_name: "Bordeaux",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Bordj Mokhtar Airport",
    longitude: 0.924971,
    latitude: 21.374788,
    id: "arp_bmw_dz",
    icao_code: "DATM",
    iata_country_code: "DZ",
    iata_code: "BMW",
    city_name: "Bordj Badji Mokhtar",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Borgarfjörður Eystri Airport",
    longitude: -13.812636,
    latitude: 65.515966,
    id: "arp_bgj_is",
    icao_code: "BIBF",
    iata_country_code: "IS",
    iata_code: "BGJ",
    city_name: "Borgarfjörður",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Borg El Arab Airport",
    longitude: 29.69568,
    latitude: 30.931803,
    id: "arp_hbe_eg",
    icao_code: "HEBA",
    iata_country_code: "EG",
    iata_code: "HBE",
    city_name: "Alexandria",
    city: {
      name: "Alexandria",
      id: "cit_aly_eg",
      iata_country_code: "EG",
      iata_code: "ALY",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Boridi Airport",
    longitude: 147.533,
    latitude: -9.13333,
    id: "arp_bpb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BPB",
    city_name: "Boridi",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Borkum Airport",
    longitude: 6.710234,
    latitude: 53.596092,
    id: "arp_bmk_de",
    icao_code: "EDWR",
    iata_country_code: "DE",
    iata_code: "BMK",
    city_name: "Borkum",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Borlänge Airport",
    longitude: 15.51158,
    latitude: 60.425816,
    id: "arp_ble_se",
    icao_code: "ESSD",
    iata_country_code: "SE",
    iata_code: "BLE",
    city_name: "Falun/Borlange",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Bornholm Airport",
    longitude: 14.760354,
    latitude: 55.063087,
    id: "arp_rnn_dk",
    icao_code: "EKRN",
    iata_country_code: "DK",
    iata_code: "RNN",
    city_name: "Rønne",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Borrego Valley Airport",
    longitude: -116.323324,
    latitude: 33.258414,
    id: "arp_bxs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BXS",
    city_name: "Borrego Springs",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Borroloola Airport",
    longitude: 136.302075,
    latitude: -16.075359,
    id: "arp_box_au",
    icao_code: "YBRL",
    iata_country_code: "AU",
    iata_code: "BOX",
    city_name: "Borroloola",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Boryspil International Airport",
    longitude: 30.894313,
    latitude: 50.341611,
    id: "arp_kbp_ua",
    icao_code: "UKBB",
    iata_country_code: "UA",
    iata_code: "KBP",
    city_name: "Kiev",
    city: {
      name: "Kiev",
      id: "cit_iev_ua",
      iata_country_code: "UA",
      iata_code: "IEV",
    },
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Bosaso Airport",
    longitude: 49.150055,
    latitude: 11.276739,
    id: "arp_bsa_so",
    icao_code: "HCMF",
    iata_country_code: "SO",
    iata_code: "BSA",
    city_name: "Bosaso",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bossangoa Airport",
    longitude: 17.428912,
    latitude: 6.492262,
    id: "arp_bsn_cf",
    icao_code: "FEFS",
    iata_country_code: "CF",
    iata_code: "BSN",
    city_name: "Bossangoa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bosset Airport",
    longitude: 141.092333,
    latitude: -7.240833,
    id: "arp_bot_pg",
    icao_code: "AYET",
    iata_country_code: "PG",
    iata_code: "BOT",
    city_name: "Boset",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Bost Airport",
    longitude: 64.363663,
    latitude: 31.563086,
    id: "arp_bst_af",
    icao_code: "OABT",
    iata_country_code: "AF",
    iata_code: "BST",
    city_name: "Lashkargāh",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Boston Harbor Seaplane Base",
    longitude: -71.025732,
    latitude: 42.352509,
    id: "arp_bnh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BNH",
    city_name: "Boston",
    city: {
      name: "Boston",
      id: "cit_bos_us",
      iata_country_code: "US",
      iata_code: "BOS",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Boswell Bay Airport",
    longitude: -146.145953,
    latitude: 60.423082,
    id: "arp_bsw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BSW",
    city_name: "Boswell Bay",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Botopasi Airport",
    longitude: -55.44691,
    latitude: 4.217051,
    id: "arp_bto_sr",
    icao_code: null,
    iata_country_code: "SR",
    iata_code: "BTO",
    city_name: "Botopasi",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Bouaké Airport",
    longitude: -5.072169,
    latitude: 7.739014,
    id: "arp_byk_ci",
    icao_code: "DIBK",
    iata_country_code: "CI",
    iata_code: "BYK",
    city_name: "Bouaké",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bouar Airport",
    longitude: 15.637391,
    latitude: 5.957948,
    id: "arp_bop_cf",
    icao_code: "FEFO",
    iata_country_code: "CF",
    iata_code: "BOP",
    city_name: "Bouar",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Bouarfa Airport",
    longitude: -1.982715,
    latitude: 32.514423,
    id: "arp_uar_ma",
    icao_code: "GMFB",
    iata_country_code: "MA",
    iata_code: "UAR",
    city_name: "Bouarfa",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bouca Airport",
    longitude: 18.272811,
    latitude: 6.516304,
    id: "arp_bcf_cf",
    icao_code: "FEGU",
    iata_country_code: "CF",
    iata_code: "BCF",
    city_name: "Bouca",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Boulder City Municipal Airport",
    longitude: -114.857679,
    latitude: 35.948028,
    id: "arp_bld_us",
    icao_code: "KBDL",
    iata_country_code: "US",
    iata_code: "BLD",
    city_name: "Boulder City",
    city: {
      name: "Las Vegas",
      id: "cit_las_us",
      iata_country_code: "US",
      iata_code: "LAS",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Boulder Municipal Airport",
    longitude: -105.22691,
    latitude: 40.038724,
    id: "arp_wbu_us",
    icao_code: "KBDU",
    iata_country_code: "US",
    iata_code: "WBU",
    city_name: "Boulder",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Boulia Airport",
    longitude: 139.900948,
    latitude: -22.912972,
    id: "arp_bql_au",
    icao_code: "YBOU",
    iata_country_code: "AU",
    iata_code: "BQL",
    city_name: "Boulia",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Boulsa Airport",
    longitude: -0.567887,
    latitude: 12.654029,
    id: "arp_xbo_bf",
    icao_code: "DFEA",
    iata_country_code: "BF",
    iata_code: "XBO",
    city_name: "Boulsa",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Bouna Airport",
    longitude: -3.03372,
    latitude: 9.255058,
    id: "arp_bqo_ci",
    icao_code: "DIBN",
    iata_country_code: "CI",
    iata_code: "BQO",
    city_name: "Bouna",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Boundary Airport",
    longitude: -141.120035,
    latitude: 64.077759,
    id: "arp_bya_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BYA",
    city_name: "Boundary",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Boundary Bay Airport",
    longitude: -123.010173,
    latitude: 49.07602,
    id: "arp_ydt_ca",
    icao_code: "CZBB",
    iata_country_code: "CA",
    iata_code: "YDT",
    city_name: "Vancouver",
    city: {
      name: "Vancouver",
      id: "cit_yvr_ca",
      iata_country_code: "CA",
      iata_code: "YVR",
    },
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Boundiali Airport",
    longitude: -6.469402,
    latitude: 9.538922,
    id: "arp_bxi_ci",
    icao_code: "DIBI",
    iata_country_code: "CI",
    iata_code: "BXI",
    city_name: "Boundiali",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Boundji Airport",
    longitude: 15.334443,
    latitude: -1.031272,
    id: "arp_boe_cg",
    icao_code: "FCOB",
    iata_country_code: "CG",
    iata_code: "BOE",
    city_name: "Boundji",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Bounneau Airport",
    longitude: 101.899592,
    latitude: 21.64752,
    id: "arp_pcq_la",
    icao_code: "VLFL",
    iata_country_code: "LA",
    iata_code: "PCQ",
    city_name: "Phongsaly",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Bourges Airport",
    longitude: 2.369491,
    latitude: 47.06026,
    id: "arp_bou_fr",
    icao_code: "LFLD",
    iata_country_code: "FR",
    iata_code: "BOU",
    city_name: "Bourges",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Bourke Airport",
    longitude: 145.950737,
    latitude: -30.039456,
    id: "arp_brk_au",
    icao_code: "YBKE",
    iata_country_code: "AU",
    iata_code: "BRK",
    city_name: "Bourke",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Bournemouth Airport",
    longitude: -1.83947,
    latitude: 50.779809,
    id: "arp_boh_gb",
    icao_code: "EGHH",
    iata_country_code: "GB",
    iata_code: "BOH",
    city_name: "Bournemouth",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Bou Saada Airport",
    longitude: 4.20611,
    latitude: 35.331059,
    id: "arp_buj_dz",
    icao_code: "DAAD",
    iata_country_code: "DZ",
    iata_code: "BUJ",
    city_name: "Bou Saada",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Bousso Airport",
    longitude: 16.720058,
    latitude: 10.490998,
    id: "arp_out_td",
    icao_code: "FTTS",
    iata_country_code: "TD",
    iata_code: "OUT",
    city_name: "Bousso",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Boutilimit Airport",
    longitude: -14.684525,
    latitude: 17.534236,
    id: "arp_otl_mr",
    icao_code: "GQNB",
    iata_country_code: "MR",
    iata_code: "OTL",
    city_name: "Boutilimit",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Bovanenkovo Airport",
    longitude: 68.335829,
    latitude: 70.319013,
    id: "arp_bvj_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "BVJ",
    city_name: "Bovanenkovo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bowen Airport",
    longitude: 148.214511,
    latitude: -20.017502,
    id: "arp_zbo_au",
    icao_code: "YBWN",
    iata_country_code: "AU",
    iata_code: "ZBO",
    city_name: "Bowen",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bowerman Airport",
    longitude: -123.936859,
    latitude: 46.97122,
    id: "arp_hqm_us",
    icao_code: "KHQM",
    iata_country_code: "US",
    iata_code: "HQM",
    city_name: "Hoquiam",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bowers Field",
    longitude: -120.53419,
    latitude: 47.030613,
    id: "arp_eln_us",
    icao_code: "KELN",
    iata_country_code: "US",
    iata_code: "ELN",
    city_name: "Ellensburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bowling Green-Warren County Regional Airport",
    longitude: -86.423311,
    latitude: 36.963503,
    id: "arp_bwg_us",
    icao_code: "KBWG",
    iata_country_code: "US",
    iata_code: "BWG",
    city_name: "Bowling Green",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Bowman Field",
    longitude: -85.664128,
    latitude: 38.228229,
    id: "arp_lou_us",
    icao_code: "KLOU",
    iata_country_code: "US",
    iata_code: "LOU",
    city_name: "Louisville",
    city: {
      name: "Louisville",
      id: "cit_sdf_us",
      iata_country_code: "US",
      iata_code: "SDF",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Bowman Municipal Airport",
    longitude: -103.301964,
    latitude: 46.168079,
    id: "arp_bwm_us",
    icao_code: "KBPP",
    iata_country_code: "US",
    iata_code: "BWM",
    city_name: "Bowman",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Bozeman Yellowstone International Airport",
    longitude: -111.156746,
    latitude: 45.777514,
    id: "arp_bzn_us",
    icao_code: "KBZN",
    iata_country_code: "US",
    iata_code: "BZN",
    city_name: "Bozeman",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bozoum Airport",
    longitude: 16.322277,
    latitude: 6.342294,
    id: "arp_boz_cf",
    icao_code: "FEGZ",
    iata_country_code: "CF",
    iata_code: "BOZ",
    city_name: "Bozoum",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Brač Airport",
    longitude: 16.679763,
    latitude: 43.286163,
    id: "arp_bwk_hr",
    icao_code: "LDSB",
    iata_country_code: "HR",
    iata_code: "BWK",
    city_name: "Brac",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Brack Airport",
    longitude: 14.269324,
    latitude: 27.656007,
    id: "arp_bcq_ly",
    icao_code: "HLLQ",
    iata_country_code: "LY",
    iata_code: "BCQ",
    city_name: "Brak",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Brackett Field",
    longitude: -117.781937,
    latitude: 34.091592,
    id: "arp_poc_us",
    icao_code: "KPOC",
    iata_country_code: "US",
    iata_code: "POC",
    city_name: "La Verne",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Bradford Regional Airport",
    longitude: -78.639853,
    latitude: 41.80182,
    id: "arp_bfd_us",
    icao_code: "KBFD",
    iata_country_code: "US",
    iata_code: "BFD",
    city_name: "Bradford",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Bradley International Airport",
    longitude: -72.684614,
    latitude: 41.938886,
    id: "arp_bdl_us",
    icao_code: "KBDL",
    iata_country_code: "US",
    iata_code: "BDL",
    city_name: "Hartford",
    city: {
      name: "Hartford",
      id: "cit_hfd_us",
      iata_country_code: "US",
      iata_code: "HFD",
    },
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Bradshaw Army Airfield",
    longitude: -155.55415,
    latitude: 19.760621,
    id: "arp_bsf_us",
    icao_code: "PHSF",
    iata_country_code: "US",
    iata_code: "BSF",
    city_name: "Camp Pohakuloa",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Braga Airport",
    longitude: -8.444034,
    latitude: 41.587047,
    id: "arp_bgz_pt",
    icao_code: "LPBR",
    iata_country_code: "PT",
    iata_code: "BGZ",
    city_name: "Braga",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Bragança Airport",
    longitude: -6.707195,
    latitude: 41.857493,
    id: "arp_bgc_pt",
    icao_code: "LPBG",
    iata_country_code: "PT",
    iata_code: "BGC",
    city_name: "Bragança",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Bragança Paulista Airport",
    longitude: -46.537508,
    latitude: -22.979162,
    id: "arp_bjp_br",
    icao_code: "SBBP",
    iata_country_code: "BR",
    iata_code: "BJP",
    city_name: "Braganca Paulista",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Brahman Airport",
    longitude: 145.367836,
    latitude: -5.700416,
    id: "arp_brh_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "BRH",
    city_name: "Brahman",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Brainerd Lakes Regional Airport",
    longitude: -94.138671,
    latitude: 46.396856,
    id: "arp_brd_us",
    icao_code: "KBRD",
    iata_country_code: "US",
    iata_code: "BRD",
    city_name: "Brainerd",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Bram Fischer International Airport",
    longitude: 26.300584,
    latitude: -29.092716,
    id: "arp_bfn_za",
    icao_code: "FABL",
    iata_country_code: "ZA",
    iata_code: "BFN",
    city_name: "Bloemfontein",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Brampton Island Airport",
    longitude: 149.270004,
    latitude: -20.8033,
    id: "arp_bmp_au",
    icao_code: "YBPI",
    iata_country_code: "AU",
    iata_code: "BMP",
    city_name: "Brampton Island",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Brandon Municipal Airport",
    longitude: -99.948625,
    latitude: 49.907337,
    id: "arp_ybr_ca",
    icao_code: "CYBR",
    iata_country_code: "CA",
    iata_code: "YBR",
    city_name: "Brandon",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Branson Airport",
    longitude: -93.199796,
    latitude: 36.535329,
    id: "arp_bkg_us",
    icao_code: "KBBG",
    iata_country_code: "US",
    iata_code: "BKG",
    city_name: "Branson",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Brasilia International Airport",
    longitude: -47.919034,
    latitude: -15.869451,
    id: "arp_bsb_br",
    icao_code: "SBBR",
    iata_country_code: "BR",
    iata_code: "BSB",
    city_name: "Brasília",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Bratislava Airport",
    longitude: 17.211612,
    latitude: 48.170232,
    id: "arp_bts_sk",
    icao_code: "LZIB",
    iata_country_code: "SK",
    iata_code: "BTS",
    city_name: "Bratislava",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Bratsk Airport",
    longitude: 101.698109,
    latitude: 56.370401,
    id: "arp_btk_ru",
    icao_code: "UIBB",
    iata_country_code: "RU",
    iata_code: "BTK",
    city_name: "Bratsk",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Braunschweig Wolfsburg Airport",
    longitude: 10.556277,
    latitude: 52.318516,
    id: "arp_bwe_de",
    icao_code: "EDVE",
    iata_country_code: "DE",
    iata_code: "BWE",
    city_name: "Braunschweig",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Brawley Municipal Airport",
    longitude: -115.516971,
    latitude: 32.992978,
    id: "arp_bwc_us",
    icao_code: "KBWC",
    iata_country_code: "US",
    iata_code: "BWC",
    city_name: "Brawley",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Breiðdalsvík Airport",
    longitude: -14.01535,
    latitude: 64.791543,
    id: "arp_bxv_is",
    icao_code: "BIBV",
    iata_country_code: "IS",
    iata_code: "BXV",
    city_name: "Breiðdalsvík",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Bremen Airport",
    longitude: 8.786273,
    latitude: 53.047736,
    id: "arp_bre_de",
    icao_code: "EDDW",
    iata_country_code: "DE",
    iata_code: "BRE",
    city_name: "Bremen",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Bremerhaven Airport",
    longitude: 8.5723,
    latitude: 53.505075,
    id: "arp_brv_de",
    icao_code: "EDWB",
    iata_country_code: "DE",
    iata_code: "BRV",
    city_name: "Bremerhaven",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Bremerton National Airport",
    longitude: -122.765118,
    latitude: 47.491276,
    id: "arp_pwt_us",
    icao_code: "KPWT",
    iata_country_code: "US",
    iata_code: "PWT",
    city_name: "Bremerton",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: 'Brescia "Gabriele D\'Annunzio" Airport',
    longitude: 10.328697,
    latitude: 45.427275,
    id: "arp_vbs_it",
    icao_code: "LIPO",
    iata_country_code: "IT",
    iata_code: "VBS",
    city_name: "Brescia",
    city: {
      name: "Verona",
      id: "cit_vrn_it",
      iata_country_code: "IT",
      iata_code: "VRN",
    },
  },
  {
    time_zone: "Europe/Minsk",
    name: "Brest Airport",
    longitude: 23.898757,
    latitude: 52.109346,
    id: "arp_bqt_by",
    icao_code: "UMBB",
    iata_country_code: "BY",
    iata_code: "BQT",
    city_name: "Brest",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Brest Bretagne Airport",
    longitude: -4.418765,
    latitude: 48.446733,
    id: "arp_bes_fr",
    icao_code: "LFRB",
    iata_country_code: "FR",
    iata_code: "BES",
    city_name: "Brest",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Breves Airport",
    longitude: -50.443116,
    latitude: -1.637453,
    id: "arp_bvs_br",
    icao_code: "SNVS",
    iata_country_code: "BR",
    iata_code: "BVS",
    city_name: "Breves",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Brevig Mission Airport",
    longitude: -166.466387,
    latitude: 65.331591,
    id: "arp_kts_us",
    icao_code: "PFKT",
    iata_country_code: "US",
    iata_code: "KTS",
    city_name: "Brevig Mission",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Brewarrina Airport",
    longitude: 146.815584,
    latitude: -29.972922,
    id: "arp_bwq_au",
    icao_code: "YBRW",
    iata_country_code: "AU",
    iata_code: "BWQ",
    city_name: "Brewarrina",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Brewster Field",
    longitude: -99.337291,
    latitude: 40.451852,
    id: "arp_hde_us",
    icao_code: "KHDE",
    iata_country_code: "US",
    iata_code: "HDE",
    city_name: "Holdrege",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Bria Airport",
    longitude: 21.988896,
    latitude: 6.528627,
    id: "arp_biv_cf",
    icao_code: "FEFR",
    iata_country_code: "CF",
    iata_code: "BIV",
    city_name: "Bria",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Brigadier Hector Eduardo Ruiz Airport",
    longitude: -61.889301,
    latitude: -37.446098,
    id: "arp_csz_ar",
    icao_code: "SAZC",
    iata_country_code: "AR",
    iata_code: "CSZ",
    city_name: "Coronel Suarez",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Brigham City Airport",
    longitude: -112.062796,
    latitude: 41.552808,
    id: "arp_bmc_us",
    icao_code: "KBMC",
    iata_country_code: "US",
    iata_code: "BMC",
    city_name: "Brigham City",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Bright Airport",
    longitude: 146.967,
    latitude: -36.7333,
    id: "arp_brj_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "BRJ",
    city_name: "Bright",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Brighton Downs Airport",
    longitude: 141.563035,
    latitude: -23.364697,
    id: "arp_bht_au",
    icao_code: "YBTD",
    iata_country_code: "AU",
    iata_code: "BHT",
    city_name: "Brighton Downs",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Brig. Lysias Augusto Rodrigues Airport",
    longitude: -47.460333,
    latitude: -7.321535,
    id: "arp_cln_br",
    icao_code: "SBCI",
    iata_country_code: "BR",
    iata_code: "CLN",
    city_name: "Carolina",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Brindisi Airport",
    longitude: 17.944421,
    latitude: 40.657259,
    id: "arp_bds_it",
    icao_code: "LIBR",
    iata_country_code: "IT",
    iata_code: "BDS",
    city_name: "Brindisi",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Brisbane Airport",
    longitude: 153.120039,
    latitude: -27.394376,
    id: "arp_bne_au",
    icao_code: "YBBN",
    iata_country_code: "AU",
    iata_code: "BNE",
    city_name: "Brisbane",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Bristol Airport",
    longitude: -2.716318,
    latitude: 51.383166,
    id: "arp_brs_gb",
    icao_code: "EGGD",
    iata_country_code: "GB",
    iata_code: "BRS",
    city_name: "Bristol",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Bristol Filton Airport",
    longitude: -2.588356,
    latitude: 51.519605,
    id: "arp_fzo_gb",
    icao_code: "EGTG",
    iata_country_code: "GB",
    iata_code: "FZO",
    city_name: "Bristol",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Britton Municipal Airport",
    longitude: -97.742306,
    latitude: 45.814574,
    id: "arp_tto_us",
    icao_code: "KBTN",
    iata_country_code: "US",
    iata_code: "TTO",
    city_name: "Britton",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Brive-Souillac Airport",
    longitude: 1.487137,
    latitude: 45.040271,
    id: "arp_bve_fr",
    icao_code: "LFSL",
    iata_country_code: "FR",
    iata_code: "BVE",
    city_name: "Brive-la-Gaillarde",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Brno-Tuřany Airport",
    longitude: 16.693875,
    latitude: 49.151764,
    id: "arp_brq_cz",
    icao_code: "LKTB",
    iata_country_code: "CZ",
    iata_code: "BRQ",
    city_name: "Brno",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Broadus Airport",
    longitude: -105.45829,
    latitude: 45.470612,
    id: "arp_bdx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BDX",
    city_name: "Broadus",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Brochet Airport",
    longitude: -101.67877,
    latitude: 57.88965,
    id: "arp_ybt_ca",
    icao_code: "CYBT",
    iata_country_code: "CA",
    iata_code: "YBT",
    city_name: "Brochet",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Brockville Regional Tackaberry Airport",
    longitude: -75.749882,
    latitude: 44.637037,
    id: "arp_xbr_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "XBR",
    city_name: "Brockville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Broken Bow Municipal Airport",
    longitude: -99.640338,
    latitude: 41.435317,
    id: "arp_bbw_us",
    icao_code: "KBBW",
    iata_country_code: "US",
    iata_code: "BBW",
    city_name: "Broken Bow",
    city: null,
  },
  {
    time_zone: "Australia/Broken_Hill",
    name: "Broken Hill Airport",
    longitude: 141.471649,
    latitude: -32.001163,
    id: "arp_bhq_au",
    icao_code: "YBHI",
    iata_country_code: "AU",
    iata_code: "BHQ",
    city_name: "Broken Hill",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Brønnøysund Airport",
    longitude: 12.215102,
    latitude: 65.460955,
    id: "arp_bnn_no",
    icao_code: "ENBN",
    iata_country_code: "NO",
    iata_code: "BNN",
    city_name: "Bronnoysund",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Brookhaven Airport",
    longitude: -72.869768,
    latitude: 40.822802,
    id: "arp_wsh_us",
    icao_code: "KHWV",
    iata_country_code: "US",
    iata_code: "WSH",
    city_name: "Shirley",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Brookings Airport",
    longitude: -124.290223,
    latitude: 42.074633,
    id: "arp_bok_us",
    icao_code: "KBOK",
    iata_country_code: "US",
    iata_code: "BOK",
    city_name: "Brookings",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Brookings Regional Airport",
    longitude: -96.814369,
    latitude: 44.3042,
    id: "arp_bkx_us",
    icao_code: "KBKX",
    iata_country_code: "US",
    iata_code: "BKX",
    city_name: "Brookings",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Broome International Airport",
    longitude: 122.233016,
    latitude: -17.948648,
    id: "arp_bme_au",
    icao_code: "YBRM",
    iata_country_code: "AU",
    iata_code: "BME",
    city_name: "Broome",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Brown Field Municipal Airport",
    longitude: -116.980323,
    latitude: 32.571847,
    id: "arp_sdm_us",
    icao_code: "KSDM",
    iata_country_code: "US",
    iata_code: "SDM",
    city_name: "San Diego",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Browns Airport",
    longitude: -116.979976,
    latitude: 32.570776,
    id: "arp_epg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EPG",
    city_name: "Weeping Water",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Brownsville/South Padre Island International Airport",
    longitude: -97.427653,
    latitude: 25.906591,
    id: "arp_bro_us",
    icao_code: "KBRO",
    iata_country_code: "US",
    iata_code: "BRO",
    city_name: "Brownsville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Brownwood Regional Airport",
    longitude: -98.956153,
    latitude: 31.794063,
    id: "arp_bwd_us",
    icao_code: "KBWD",
    iata_country_code: "US",
    iata_code: "BWD",
    city_name: "Brownwood",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Bruce Campbell Field",
    longitude: -90.103083,
    latitude: 32.438565,
    id: "arp_dxe_us",
    icao_code: "KMBO",
    iata_country_code: "US",
    iata_code: "DXE",
    city_name: "Madison",
    city: null,
  },
  {
    time_zone: "Asia/Brunei",
    name: "Brunei International Airport",
    longitude: 114.929,
    latitude: 4.944123,
    id: "arp_bwn_bn",
    icao_code: "WBSB",
    iata_country_code: "BN",
    iata_code: "BWN",
    city_name: "Bandar Seri Begawan",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Brunette Downs Airport",
    longitude: 135.941714,
    latitude: -18.638812,
    id: "arp_btd_au",
    icao_code: "YBRU",
    iata_country_code: "AU",
    iata_code: "BTD",
    city_name: "Brunette Downs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Brunswick Executive Airport",
    longitude: -69.936447,
    latitude: 43.895325,
    id: "arp_nhz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NHZ",
    city_name: "Brunswick",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Brunswick Golden Isles Airport",
    longitude: -81.466759,
    latitude: 31.256802,
    id: "arp_bqk_us",
    icao_code: "KBQK",
    iata_country_code: "US",
    iata_code: "BQK",
    city_name: "Brunswick",
    city: {
      name: "Brunswick",
      id: "cit_ssi_us",
      iata_country_code: "US",
      iata_code: "SSI",
    },
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Brus Laguna Airport",
    longitude: -84.543218,
    latitude: 15.762684,
    id: "arp_bhg_hn",
    icao_code: "MHBL",
    iata_country_code: "HN",
    iata_code: "BHG",
    city_name: "Brus Laguna",
    city: null,
  },
  {
    time_zone: "Europe/Brussels",
    name: "Brussels Airport",
    longitude: 4.484052,
    latitude: 50.90042,
    id: "arp_bru_be",
    icao_code: "EBBR",
    iata_country_code: "BE",
    iata_code: "BRU",
    city_name: "Brussels",
    city: {
      name: "Brussels",
      id: "cit_bru_be",
      iata_country_code: "BE",
      iata_code: "BRU",
    },
  },
  {
    time_zone: "Europe/Brussels",
    name: "Brussels South Charleroi Airport",
    longitude: 4.457859,
    latitude: 50.46129,
    id: "arp_crl_be",
    icao_code: "EBCI",
    iata_country_code: "BE",
    iata_code: "CRL",
    city_name: "Brussels",
    city: {
      name: "Brussels",
      id: "cit_bru_be",
      iata_country_code: "BE",
      iata_code: "BRU",
    },
  },
  {
    time_zone: "Europe/Moscow",
    name: "Bryansk Airport",
    longitude: 34.179529,
    latitude: 53.21383,
    id: "arp_bzk_ru",
    icao_code: "UUBP",
    iata_country_code: "RU",
    iata_code: "BZK",
    city_name: "Bryansk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Bryant Army Heliport",
    longitude: -149.653852,
    latitude: 61.264454,
    id: "arp_frn_us",
    icao_code: "PAFR",
    iata_country_code: "US",
    iata_code: "FRN",
    city_name: "Anchorage",
    city: {
      name: "Anchorage",
      id: "cit_anc_us",
      iata_country_code: "US",
      iata_code: "ANC",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Bryce Canyon Airport",
    longitude: -112.155282,
    latitude: 37.700533,
    id: "arp_bce_us",
    icao_code: "KBCE",
    iata_country_code: "US",
    iata_code: "BCE",
    city_name: "Bryce Canyon",
    city: null,
  },
  {
    time_zone: "Africa/Bissau",
    name: "Bubaque Airport",
    longitude: -15.838304,
    latitude: 11.297971,
    id: "arp_bqe_gw",
    icao_code: "GGBU",
    iata_country_code: "GW",
    iata_code: "BQE",
    city_name: "Bubaque",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Buchanan Airport",
    longitude: -10.049356,
    latitude: 5.868142,
    id: "arp_ucn_lr",
    icao_code: "GLBU",
    iata_country_code: "LR",
    iata_code: "UCN",
    city_name: "Buchanan",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Buchanan Field Airport",
    longitude: -122.058084,
    latitude: 37.988875,
    id: "arp_ccr_us",
    icao_code: "KCCR",
    iata_country_code: "US",
    iata_code: "CCR",
    city_name: "Concord",
    city: null,
  },
  {
    time_zone: "Pacific/Kwajalein",
    name: "Bucholz Army Airfield",
    longitude: 167.731687,
    latitude: 8.720424,
    id: "arp_kwa_mh",
    icao_code: "PKWA",
    iata_country_code: "MH",
    iata_code: "KWA",
    city_name: "Kwajalein",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Buckeye Municipal Airport",
    longitude: -112.685333,
    latitude: 33.420974,
    id: "arp_bxk_us",
    icao_code: "KBXK",
    iata_country_code: "US",
    iata_code: "BXK",
    city_name: "Buckeye",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Buckhorn Ranch Airport",
    longitude: -106.929501,
    latitude: 38.852431,
    id: "arp_cse_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CSE",
    city_name: "Crested Butte",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Buckland Airport",
    longitude: -161.149058,
    latitude: 65.981533,
    id: "arp_bkc_us",
    icao_code: "PABL",
    iata_country_code: "US",
    iata_code: "BKC",
    city_name: "Buckland",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Buckley Air Force Base",
    longitude: -104.751999,
    latitude: 39.701698,
    id: "arp_bfk_us",
    icao_code: "KBKF",
    iata_country_code: "US",
    iata_code: "BFK",
    city_name: "Aurora",
    city: {
      name: "Denver",
      id: "cit_den_us",
      iata_country_code: "US",
      iata_code: "DEN",
    },
  },
  {
    time_zone: "Europe/Budapest",
    name: "Budapest Ferenc Liszt International Airport",
    longitude: 19.25574,
    latitude: 47.437552,
    id: "arp_bud_hu",
    icao_code: "LHBP",
    iata_country_code: "HU",
    iata_code: "BUD",
    city_name: "Budapest",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Buenos Aires Airport",
    longitude: -83.33001,
    latitude: 9.16516,
    id: "arp_bai_cr",
    icao_code: "MRBA",
    iata_country_code: "CR",
    iata_code: "BAI",
    city_name: "Buenos Aires",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Buffalo Narrows Airport",
    longitude: -108.415352,
    latitude: 55.84108,
    id: "arp_yvt_ca",
    icao_code: "CYVT",
    iata_country_code: "CA",
    iata_code: "YVT",
    city_name: "Buffalo Narrows",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Buffalo Niagara International Airport",
    longitude: -78.730853,
    latitude: 42.940102,
    id: "arp_buf_us",
    icao_code: "KBUF",
    iata_country_code: "US",
    iata_code: "BUF",
    city_name: "Buffalo",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Buffalo Range Airport",
    longitude: 31.578481,
    latitude: -21.008481,
    id: "arp_bfo_zw",
    icao_code: "FVCZ",
    iata_country_code: "ZW",
    iata_code: "BFO",
    city_name: "Chiredzi",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Bugulma Airport",
    longitude: 52.797882,
    latitude: 54.638013,
    id: "arp_uua_ru",
    icao_code: "UWKB",
    iata_country_code: "RU",
    iata_code: "UUA",
    city_name: "Bugulma",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Buin Airport",
    longitude: 155.685429,
    latitude: -6.733845,
    id: "arp_ubi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UBI",
    city_name: "Buin",
    city: null,
  },
  {
    time_zone: "Africa/Bujumbura",
    name: "Bujumbura International Airport",
    longitude: 29.319656,
    latitude: -3.321451,
    id: "arp_bjm_bi",
    icao_code: "HBBA",
    iata_country_code: "BI",
    iata_code: "BJM",
    city_name: "Bujumbura",
    city: null,
  },
  {
    time_zone: "Pacific/Bougainville",
    name: "Buka Airport",
    longitude: 154.67364,
    latitude: -5.421589,
    id: "arp_bua_pg",
    icao_code: "AYBK",
    iata_country_code: "PG",
    iata_code: "BUA",
    city_name: "Buka Island",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Bukhara International Airport",
    longitude: 64.483299,
    latitude: 39.775001,
    id: "arp_bhk_uz",
    icao_code: "UTSB",
    iata_country_code: "UZ",
    iata_code: "BHK",
    city_name: "Bukhara",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Bukhovtsi Airfield",
    longitude: 26.700041,
    latitude: 43.306763,
    id: "arp_tgv_bg",
    icao_code: "LBTG",
    iata_country_code: "BG",
    iata_code: "TGV",
    city_name: "Targovishte",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Bukoba Airport",
    longitude: 31.820985,
    latitude: -1.331367,
    id: "arp_bkz_tz",
    icao_code: "HTBU",
    iata_country_code: "TZ",
    iata_code: "BKZ",
    city_name: "Bukoba",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Bulagtai Resort Airport",
    longitude: 104.11432,
    latitude: 43.749653,
    id: "arp_ugt_mn",
    icao_code: "ZMBR",
    iata_country_code: "MN",
    iata_code: "UGT",
    city_name: "Bulagtai",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Bulchi Airport",
    longitude: 36.666667,
    latitude: 6.216667,
    id: "arp_bcy_et",
    icao_code: "HABU",
    iata_country_code: "ET",
    iata_code: "BCY",
    city_name: "Bulchi",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Bulgan Airport",
    longitude: 103.476256,
    latitude: 48.855216,
    id: "arp_uga_mn",
    icao_code: "ZMBN",
    iata_country_code: "MN",
    iata_code: "UGA",
    city_name: "Bulgan",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Bulgan Sum Airport",
    longitude: 91.584088,
    latitude: 46.101451,
    id: "arp_hbu_mn",
    icao_code: "ZMBS",
    iata_country_code: "MN",
    iata_code: "HBU",
    city_name: "Khovd/Bulgan",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Buli Airport",
    longitude: 128.382384,
    latitude: 0.919178,
    id: "arp_pgq_id",
    icao_code: "WAEM",
    iata_country_code: "ID",
    iata_code: "PGQ",
    city_name: "Pekaulang",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bulimba Airport",
    longitude: 143.479004,
    latitude: -16.8808,
    id: "arp_bip_au",
    icao_code: "YBWM",
    iata_country_code: "AU",
    iata_code: "BIP",
    city_name: "Bulimba",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Bullfrog Basin Airport",
    longitude: -110.713097,
    latitude: 37.545811,
    id: "arp_bfg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BFG",
    city_name: "Glen Canyon",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Bull Harbour Airport",
    longitude: -127.917,
    latitude: 50.9,
    id: "arp_ybh_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YBH",
    city_name: "Bull Harbour",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bulolo Airport",
    longitude: 146.649541,
    latitude: -7.216286,
    id: "arp_bul_pg",
    icao_code: "AYBU",
    iata_country_code: "PG",
    iata_code: "BUL",
    city_name: "Bulolo",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Bumba Airport",
    longitude: 22.480928,
    latitude: 2.182989,
    id: "arp_bmb_cd",
    icao_code: "FZFU",
    iata_country_code: "CD",
    iata_code: "BMB",
    city_name: "Bumba",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Bumi Hills Airport",
    longitude: 28.35,
    latitude: -16.816999,
    id: "arp_bzh_zw",
    icao_code: "FVBM",
    iata_country_code: "ZW",
    iata_code: "BZH",
    city_name: "Bumi Hills",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Bunbury Airport",
    longitude: 115.679027,
    latitude: -33.376978,
    id: "arp_buy_au",
    icao_code: "YBUN",
    iata_country_code: "AU",
    iata_code: "BUY",
    city_name: "Bunbury",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Bundaberg Airport",
    longitude: 152.320377,
    latitude: -24.901664,
    id: "arp_bdb_au",
    icao_code: "YBUD",
    iata_country_code: "AU",
    iata_code: "BDB",
    city_name: "Bundaberg",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bundi Airport",
    longitude: 145.217,
    latitude: -5.73333,
    id: "arp_bnt_pg",
    icao_code: "AYDI",
    iata_country_code: "PG",
    iata_code: "BNT",
    city_name: "Bundi",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Bunia Airport",
    longitude: 30.220822,
    latitude: 1.566013,
    id: "arp_bux_cd",
    icao_code: "FZKA",
    iata_country_code: "CD",
    iata_code: "BUX",
    city_name: "Bunia",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Buno Bedelle Airport",
    longitude: 36.3333,
    latitude: 8.45,
    id: "arp_xbl_et",
    icao_code: "HABB",
    iata_country_code: "ET",
    iata_code: "XBL",
    city_name: "Bedele",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Bunsil Airport",
    longitude: 147.8667,
    latitude: -5.7243,
    id: "arp_bxz_pg",
    icao_code: "AYNS",
    iata_country_code: "PG",
    iata_code: "BXZ",
    city_name: "Bunsil",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Bunyu Airport",
    longitude: 117.867637,
    latitude: 3.456848,
    id: "arp_byq_id",
    icao_code: "WALV",
    iata_country_code: "ID",
    iata_code: "BYQ",
    city_name: "Bunyu",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Buochs Airport",
    longitude: 8.398554,
    latitude: 46.973359,
    id: "arp_bxo_ch",
    icao_code: "LSZC",
    iata_country_code: "CH",
    iata_code: "BXO",
    city_name: "Buochs",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Buon Ma Thuot Airport",
    longitude: 108.118706,
    latitude: 12.666503,
    id: "arp_bmv_vn",
    icao_code: "VVBM",
    iata_country_code: "VN",
    iata_code: "BMV",
    city_name: "Buon Ma Thuot",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Buraimi Airport",
    longitude: 55.782711,
    latitude: 24.241774,
    id: "arp_rmb_om",
    icao_code: "OOBR",
    iata_country_code: "OM",
    iata_code: "RMB",
    city_name: "Buraimi",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Burao Airport",
    longitude: 45.554673,
    latitude: 9.527708,
    id: "arp_buo_so",
    icao_code: "HCMV",
    iata_country_code: "SO",
    iata_code: "BUO",
    city_name: "Burao",
    city: null,
  },
  {
    time_zone: "Asia/Ust-Nera",
    name: "Burevestnik Airport",
    longitude: 147.621831,
    latitude: 44.919999,
    id: "arp_bvv_ru",
    icao_code: "UHSB",
    iata_country_code: "RU",
    iata_code: "BVV",
    city_name: "Iturup Island",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Burgas Airport",
    longitude: 27.516153,
    latitude: 42.567773,
    id: "arp_boj_bg",
    icao_code: "LBBG",
    iata_country_code: "BG",
    iata_code: "BOJ",
    city_name: "Burgas",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Burg Feuerstein Airport",
    longitude: 11.133611,
    latitude: 49.794167,
    id: "arp_urd_de",
    icao_code: "EDQE",
    iata_country_code: "DE",
    iata_code: "URD",
    city_name: "Burg Feuerstein",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Burgos Airport",
    longitude: -3.620686,
    latitude: 42.356446,
    id: "arp_rgs_es",
    icao_code: "LEBG",
    iata_country_code: "ES",
    iata_code: "RGS",
    city_name: "Burgos",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Buriram Airport",
    longitude: 103.250444,
    latitude: 15.228775,
    id: "arp_bfv_th",
    icao_code: "VTUO",
    iata_country_code: "TH",
    iata_code: "BFV",
    city_name: "Buriram",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Burketown Airport",
    longitude: 139.533777,
    latitude: -17.749066,
    id: "arp_buc_au",
    icao_code: "YBKT",
    iata_country_code: "AU",
    iata_code: "BUC",
    city_name: "Burketown",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Burley Municipal Airport",
    longitude: -113.770871,
    latitude: 42.541665,
    id: "arp_byi_us",
    icao_code: "KBYI",
    iata_country_code: "US",
    iata_code: "BYI",
    city_name: "Burley",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Burlington International Airport",
    longitude: -73.15245,
    latitude: 44.471297,
    id: "arp_btv_us",
    icao_code: "KBTV",
    iata_country_code: "US",
    iata_code: "BTV",
    city_name: "Burlington",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Burnie Airport",
    longitude: 145.728769,
    latitude: -40.997673,
    id: "arp_bwt_au",
    icao_code: "YWYY",
    iata_country_code: "AU",
    iata_code: "BWT",
    city_name: "Burnie",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Burns Lake Airport",
    longitude: -125.951998,
    latitude: 54.376899,
    id: "arp_ypz_ca",
    icao_code: "CYPZ",
    iata_country_code: "CA",
    iata_code: "YPZ",
    city_name: "Burns Lake",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Burns Municipal Airport",
    longitude: -118.954801,
    latitude: 43.591901,
    id: "arp_bno_us",
    icao_code: "KBNO",
    iata_country_code: "US",
    iata_code: "BNO",
    city_name: "Burns",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Burwash Airport",
    longitude: -139.041,
    latitude: 61.371101,
    id: "arp_ydb_ca",
    icao_code: "CYDB",
    iata_country_code: "CA",
    iata_code: "YDB",
    city_name: "Burwash Landing",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Bushehr Airport",
    longitude: 50.824456,
    latitude: 28.957977,
    id: "arp_buz_ir",
    icao_code: "OIBB",
    iata_country_code: "IR",
    iata_code: "BUZ",
    city_name: "Bushehr",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Busselton Regional Airport",
    longitude: 115.400376,
    latitude: -33.687038,
    id: "arp_bqb_au",
    icao_code: "YBLN",
    iata_country_code: "AU",
    iata_code: "BQB",
    city_name: "Busselton",
    city: null,
  },
  {
    time_zone: "Africa/Kigali",
    name: "Butare Airport",
    longitude: 29.735127,
    latitude: -2.59572,
    id: "arp_btq_rw",
    icao_code: "HRYI",
    iata_country_code: "RW",
    iata_code: "BTQ",
    city_name: "Butare",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Butaritari Atoll Airport",
    longitude: 172.811858,
    latitude: 3.086458,
    id: "arp_bbg_ki",
    icao_code: "NGTU",
    iata_country_code: "KI",
    iata_code: "BBG",
    city_name: "Butaritari Atoll",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Buta Zega Airport",
    longitude: 24.7949,
    latitude: 2.819385,
    id: "arp_bzu_cd",
    icao_code: "FZKJ",
    iata_country_code: "CD",
    iata_code: "BZU",
    city_name: "Buta",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Butembo Airport",
    longitude: -93.0973,
    latitude: 35.260094,
    id: "arp_rue_cg",
    icao_code: null,
    iata_country_code: "CG",
    iata_code: "RUE",
    city_name: "Butembo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Butler County Regional Airport",
    longitude: -84.522819,
    latitude: 39.36314,
    id: "arp_hao_us",
    icao_code: "KHAO",
    iata_country_code: "US",
    iata_code: "HAO",
    city_name: "Hamilton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Butler Memorial Airport",
    longitude: -94.34185,
    latitude: 38.287286,
    id: "arp_bum_us",
    icao_code: "KBUM",
    iata_country_code: "US",
    iata_code: "BUM",
    city_name: "Butler",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Butterworth Airport",
    longitude: 100.391072,
    latitude: 5.465736,
    id: "arp_bwh_my",
    icao_code: "WMKB",
    iata_country_code: "MY",
    iata_code: "BWH",
    city_name: "Butterworth",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Buttonville Municipal Airport",
    longitude: -79.369888,
    latitude: 43.861963,
    id: "arp_ykz_ca",
    icao_code: "CYKZ",
    iata_country_code: "CA",
    iata_code: "YKZ",
    city_name: "Toronto",
    city: {
      name: "Toronto",
      id: "cit_yto_ca",
      iata_country_code: "CA",
      iata_code: "YTO",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Butts Army Airfield",
    longitude: -104.757004,
    latitude: 38.678398,
    id: "arp_fcs_us",
    icao_code: "KFCS",
    iata_country_code: "US",
    iata_code: "FCS",
    city_name: "Colorado Springs",
    city: {
      name: "Colorado Springs",
      id: "cit_cos_us",
      iata_country_code: "US",
      iata_code: "COS",
    },
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Buzwagi Airport",
    longitude: 32.68421,
    latitude: -3.845962,
    id: "arp_kbh_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "KBH",
    city_name: "Kahama",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Bydgoszcz Ignacy Jan Paderewski Airport",
    longitude: 17.97519,
    latitude: 53.097376,
    id: "arp_bzg_pl",
    icao_code: "EPBY",
    iata_country_code: "PL",
    iata_code: "BZG",
    city_name: "Bydgoszcz",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cabaniss Field Naval Outlying Field Airport",
    longitude: -97.440771,
    latitude: 27.703725,
    id: "arp_ngw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NGW",
    city_name: "Corpus Christi",
    city: {
      name: "Corpus Christi",
      id: "cit_crp_us",
      iata_country_code: "US",
      iata_code: "CRP",
    },
  },
  {
    time_zone: "Africa/Luanda",
    name: "Cabinda Airport",
    longitude: 12.188286,
    latitude: -5.596893,
    id: "arp_cab_ao",
    icao_code: "FNCA",
    iata_country_code: "AO",
    iata_code: "CAB",
    city_name: "Cabinda",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Cable Airport",
    longitude: -117.686,
    latitude: 34.111201,
    id: "arp_ccb_us",
    icao_code: "KCCB",
    iata_country_code: "US",
    iata_code: "CCB",
    city_name: "Upland",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Cabo Frio International Airport",
    longitude: -42.076772,
    latitude: -22.923734,
    id: "arp_cfb_br",
    icao_code: "SBCB",
    iata_country_code: "BR",
    iata_code: "CFB",
    city_name: "Cabo Frio",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Cabo Rojo Airport",
    longitude: -71.646583,
    latitude: 17.930215,
    id: "arp_cbj_do",
    icao_code: "MDCR",
    iata_country_code: "DO",
    iata_code: "CBJ",
    city_name: "Pedernales",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Caçador Airport",
    longitude: -50.940661,
    latitude: -26.789679,
    id: "arp_cfc_br",
    icao_code: "SBCD",
    iata_country_code: "BR",
    iata_code: "CFC",
    city_name: "Cacador",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Cáceres Airport",
    longitude: -57.630417,
    latitude: -16.043889,
    id: "arp_ccx_br",
    icao_code: "SWKC",
    iata_country_code: "BR",
    iata_code: "CCX",
    city_name: "Cáceres",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Cache Creek Airport",
    longitude: -121.323088,
    latitude: 50.778021,
    id: "arp_yza_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YZA",
    city_name: "Ashcroft/Cache Creek",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Cachoeiro de Itapemirim Airport",
    longitude: -41.187865,
    latitude: -20.836747,
    id: "arp_cdi_br",
    icao_code: "SNKI",
    iata_country_code: "BR",
    iata_code: "CDI",
    city_name: "Cachoeiro de Itapemirim",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Cacique Aramare Airport",
    longitude: -67.604986,
    latitude: 5.623731,
    id: "arp_pyh_ve",
    icao_code: "SVPA",
    iata_country_code: "VE",
    iata_code: "PYH",
    city_name: "Puerto Ayacucho",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Cacoal Airport",
    longitude: -61.450504,
    latitude: -11.494315,
    id: "arp_oal_br",
    icao_code: "SSKW",
    iata_country_code: "BR",
    iata_code: "OAL",
    city_name: "Cacoal",
    city: null,
  },
  {
    time_zone: "Africa/Porto-Novo",
    name: "Cadjehoun Airport",
    longitude: 2.384215,
    latitude: 6.355936,
    id: "arp_coo_bj",
    icao_code: "DBBB",
    iata_country_code: "BJ",
    iata_code: "COO",
    city_name: "Cotonou",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Caen - Carpiquet Airport",
    longitude: -0.459977,
    latitude: 49.18249,
    id: "arp_cfr_fr",
    icao_code: "LFRK",
    iata_country_code: "FR",
    iata_code: "CFR",
    city_name: "Caen",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Cafunfo Airport",
    longitude: 17.989775,
    latitude: -8.783666,
    id: "arp_cff_ao",
    icao_code: "FNCF",
    iata_country_code: "AO",
    iata_code: "CFF",
    city_name: "Cafunfo",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Cagayan de Sulu Airport",
    longitude: 118.496167,
    latitude: 7.013568,
    id: "arp_cdy_ph",
    icao_code: "RPMU",
    iata_country_code: "PH",
    iata_code: "CDY",
    city_name: "Mapun",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Cagayan North International Airport",
    longitude: 121.746272,
    latitude: 18.181312,
    id: "arp_llc_ph",
    icao_code: "RPLH",
    iata_country_code: "PH",
    iata_code: "LLC",
    city_name: "Lal-lo",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Cagliari Elmas Airport",
    longitude: 9.056303,
    latitude: 39.252091,
    id: "arp_cag_it",
    icao_code: "LIEE",
    iata_country_code: "IT",
    iata_code: "CAG",
    city_name: "Cagliari",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Cahors-Lalbenque Airport",
    longitude: 1.475279,
    latitude: 44.351394,
    id: "arp_zao_fr",
    icao_code: "LFCC",
    iata_country_code: "FR",
    iata_code: "ZAO",
    city_name: "Cahors",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Caia Airport",
    longitude: 32.96685,
    latitude: -25.317592,
    id: "arp_cmz_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "CMZ",
    city_name: "Caia",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Caicara del Orinoco Airport",
    longitude: -66.16247,
    latitude: 7.626079,
    id: "arp_cxa_ve",
    icao_code: "SVCD",
    iata_country_code: "VE",
    iata_code: "CXA",
    city_name: "Caicara de Orinoco",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Caiguna Airport",
    longitude: 125.47636,
    latitude: -32.281557,
    id: "arp_cgv_au",
    icao_code: "YCAG",
    iata_country_code: "AU",
    iata_code: "CGV",
    city_name: "Caiguna",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cairns Airport",
    longitude: 145.74839,
    latitude: -16.877598,
    id: "arp_cns_au",
    icao_code: "YBCS",
    iata_country_code: "AU",
    iata_code: "CNS",
    city_name: "Cairns",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cairns Army Airfield Airport",
    longitude: -85.714465,
    latitude: 31.276487,
    id: "arp_ozr_us",
    icao_code: "KOZR",
    iata_country_code: "US",
    iata_code: "OZR",
    city_name: "Fort Rucker/Ozark",
    city: {
      name: "Fort Rucker/Ozark",
      id: "cit_ozr_us",
      iata_country_code: "US",
      iata_code: "OZR",
    },
  },
  {
    time_zone: "Africa/Cairo",
    name: "Cairo International Airport",
    longitude: 31.40751,
    latitude: 30.121954,
    id: "arp_cai_eg",
    icao_code: "HECA",
    iata_country_code: "EG",
    iata_code: "CAI",
    city_name: "Cairo",
    city: {
      name: "Cairo",
      id: "cit_cai_eg",
      iata_country_code: "EG",
      iata_code: "CAI",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Cairo Regional Airport",
    longitude: -89.22038,
    latitude: 37.063402,
    id: "arp_cir_us",
    icao_code: "KCIR",
    iata_country_code: "US",
    iata_code: "CIR",
    city_name: "Cairo",
    city: null,
  },
  {
    time_zone: "Asia/Dili",
    name: "Cakung Airport",
    longitude: 126.396908,
    latitude: -8.479464,
    id: "arp_bch_tl",
    icao_code: "WPEC",
    iata_country_code: "TL",
    iata_code: "BCH",
    city_name: "Baucau",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Calabozo Airport",
    longitude: -67.416175,
    latitude: 8.924879,
    id: "arp_clz_ve",
    icao_code: "SVCL",
    iata_country_code: "VE",
    iata_code: "CLZ",
    city_name: "Calabozo",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Calais-Dunkerque Airport",
    longitude: 1.954816,
    latitude: 50.961404,
    id: "arp_cqf_fr",
    icao_code: "LFAC",
    iata_country_code: "FR",
    iata_code: "CQF",
    city_name: "Dunkerque/Calais",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Calbayog Airport",
    longitude: 124.544816,
    latitude: 12.073952,
    id: "arp_cyp_ph",
    icao_code: "RPVC",
    iata_country_code: "PH",
    iata_code: "CYP",
    city_name: "Calbayog",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Caldas Novas Airport",
    longitude: -48.609936,
    latitude: -17.725307,
    id: "arp_clv_br",
    icao_code: "SBCN",
    iata_country_code: "BR",
    iata_code: "CLV",
    city_name: "Caldas Novas",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Caledonia County Airport",
    longitude: -72.017717,
    latitude: 44.569417,
    id: "arp_llx_us",
    icao_code: "KCDA",
    iata_country_code: "US",
    iata_code: "LLX",
    city_name: "Lyndonville",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Caleta Olivia Airport",
    longitude: -67.564636,
    latitude: -46.539393,
    id: "arp_cvi_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "CVI",
    city_name: "Caleta Olivia",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Calexico International Airport",
    longitude: -115.512311,
    latitude: 32.668749,
    id: "arp_cxl_us",
    icao_code: "KCXL",
    iata_country_code: "US",
    iata_code: "CXL",
    city_name: "Calexico",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Calgary International Airport",
    longitude: -114.009275,
    latitude: 51.119337,
    id: "arp_yyc_ca",
    icao_code: "CYYC",
    iata_country_code: "CA",
    iata_code: "YYC",
    city_name: "Calgary",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Calgary/Springbank Airport",
    longitude: -114.375575,
    latitude: 51.103834,
    id: "arp_ybw_ca",
    icao_code: "CAB3",
    iata_country_code: "CA",
    iata_code: "YBW",
    city_name: "Bedwell Harbour",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Calicut International Airport",
    longitude: 75.953094,
    latitude: 11.137571,
    id: "arp_ccj_in",
    icao_code: "VOCL",
    iata_country_code: "IN",
    iata_code: "CCJ",
    city_name: "Calicut",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Caloundra Airport",
    longitude: 153.104664,
    latitude: -26.799126,
    id: "arp_cud_au",
    icao_code: "YCDR",
    iata_country_code: "AU",
    iata_code: "CUD",
    city_name: "Caloundra",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Calverton Executive Airpark",
    longitude: -72.785333,
    latitude: 40.916189,
    id: "arp_cto_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CTO",
    city_name: "Calverton",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Calvi – Sainte-Catherine Airport",
    longitude: 8.791572,
    latitude: 42.524878,
    id: "arp_cly_fr",
    icao_code: "LFKC",
    iata_country_code: "FR",
    iata_code: "CLY",
    city_name: "Calvi",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Cà Mau Airport",
    longitude: 105.176796,
    latitude: 9.176993,
    id: "arp_cah_vn",
    icao_code: "VVCM",
    iata_country_code: "VN",
    iata_code: "CAH",
    city_name: "Cà Mau",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Camballin Airport",
    longitude: 124.272025,
    latitude: -18.124487,
    id: "arp_myo_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MYO",
    city_name: "Myroodah Station",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Cambridge Airport",
    longitude: 0.174143,
    latitude: 52.206998,
    id: "arp_cbg_gb",
    icao_code: "EGSC",
    iata_country_code: "GB",
    iata_code: "CBG",
    city_name: "Cambridge",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Cambridge Bay Airport",
    longitude: -105.133131,
    latitude: 69.107831,
    id: "arp_ycb_ca",
    icao_code: "CYCB",
    iata_country_code: "CA",
    iata_code: "YCB",
    city_name: "Cambridge Bay",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cambridge-Dorchester Airport",
    longitude: -76.032515,
    latitude: 38.540381,
    id: "arp_cge_us",
    icao_code: "KCGE",
    iata_country_code: "US",
    iata_code: "CGE",
    city_name: "Cambridge",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Camden Airport",
    longitude: 150.68647,
    latitude: -34.0406,
    id: "arp_cdu_au",
    icao_code: "YSCN",
    iata_country_code: "AU",
    iata_code: "CDU",
    city_name: "Camden",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Cametá Airport",
    longitude: -49.506517,
    latitude: -2.242425,
    id: "arp_cmt_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "CMT",
    city_name: "Cametá",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Camfield Airport",
    longitude: 131.327611,
    latitude: -17.022064,
    id: "arp_cfi_au",
    icao_code: "YCFD",
    iata_country_code: "AU",
    iata_code: "CFI",
    city_name: "Camfield Station",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Camiguin Airport",
    longitude: 124.707071,
    latitude: 9.253195,
    id: "arp_cgm_ph",
    icao_code: "RPMH",
    iata_country_code: "PH",
    iata_code: "CGM",
    city_name: "Camiguin",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Camilo Daza International Airport",
    longitude: -72.510938,
    latitude: 7.928537,
    id: "arp_cuc_co",
    icao_code: "SKCC",
    iata_country_code: "CO",
    iata_code: "CUC",
    city_name: "Cúcuta",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Camiri Airport",
    longitude: -63.52802,
    latitude: -20.00626,
    id: "arp_cam_bo",
    icao_code: "SLCA",
    iata_country_code: "BO",
    iata_code: "CAM",
    city_name: "Camiri",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Camocim Airport",
    longitude: -40.856005,
    latitude: -2.898166,
    id: "arp_cmc_br",
    icao_code: "SNWC",
    iata_country_code: "BR",
    iata_code: "CMC",
    city_name: "Camocim",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Camooweal Airport",
    longitude: 138.125089,
    latitude: -19.912799,
    id: "arp_cml_au",
    icao_code: "YCMW",
    iata_country_code: "AU",
    iata_code: "CML",
    city_name: "Camooweal",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Camp Bastion Airport",
    longitude: 64.224602,
    latitude: 31.8638,
    id: "arp_oaz_af",
    icao_code: "OAZI",
    iata_country_code: "AF",
    iata_code: "OAZ",
    city_name: "Camp Bastion",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Campbell Army Airfield",
    longitude: -87.49282,
    latitude: 36.673144,
    id: "arp_hop_us",
    icao_code: "KHOP",
    iata_country_code: "US",
    iata_code: "HOP",
    city_name: "Hopkinsville",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Campbell River Airport",
    longitude: -125.270212,
    latitude: 49.951375,
    id: "arp_ybl_ca",
    icao_code: "CYBL",
    iata_country_code: "CA",
    iata_code: "YBL",
    city_name: "Campbell River",
    city: {
      name: "Campbell River",
      id: "cit_ybl_ca",
      iata_country_code: "CA",
      iata_code: "YBL",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Campbell River Harbour Airport",
    longitude: -125.269567,
    latitude: 49.949302,
    id: "arp_yhh_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YHH",
    city_name: "Campbell River",
    city: {
      name: "Campbell River",
      id: "cit_ybl_ca",
      iata_country_code: "CA",
      iata_code: "YBL",
    },
  },
  {
    time_zone: "Europe/London",
    name: "Campbeltown Airport",
    longitude: -5.690967,
    latitude: 55.437896,
    id: "arp_cal_gb",
    icao_code: "EGEC",
    iata_country_code: "GB",
    iata_code: "CAL",
    city_name: "Campbeltown",
    city: null,
  },
  {
    time_zone: "America/Merida",
    name: "Campeche International Airport",
    longitude: -90.501347,
    latitude: 19.815656,
    id: "arp_cpe_mx",
    icao_code: "MMCP",
    iata_country_code: "MX",
    iata_code: "CPE",
    city_name: "Campeche",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Campina Grande Airport",
    longitude: -35.89521,
    latitude: -7.269399,
    id: "arp_cpv_br",
    icao_code: "SBKG",
    iata_country_code: "BR",
    iata_code: "CPV",
    city_name: "Campina Grande",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Campo Grande International Airport",
    longitude: -54.673311,
    latitude: -20.468697,
    id: "arp_cgr_br",
    icao_code: "SBCG",
    iata_country_code: "BR",
    iata_code: "CGR",
    city_name: "Campo Grande",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Campo Mourão Airport",
    longitude: -52.357538,
    latitude: -24.009012,
    id: "arp_cbw_br",
    icao_code: "SSKM",
    iata_country_code: "BR",
    iata_code: "CBW",
    city_name: "Campo Mourao",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Campos–Bartolomeu Lysandro Airport",
    longitude: -41.30355,
    latitude: -21.699755,
    id: "arp_caw_br",
    icao_code: "SBCP",
    iata_country_code: "BR",
    iata_code: "CAW",
    city_name: "Campos",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Cam Ranh International Airport",
    longitude: 109.216988,
    latitude: 12.010594,
    id: "arp_cxr_vn",
    icao_code: "VVCR",
    iata_country_code: "VN",
    iata_code: "CXR",
    city_name: "Nha Trang",
    city: {
      name: "Nha Trang",
      id: "cit_nha_vn",
      iata_country_code: "VN",
      iata_code: "NHA",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Canadian Forces Base Bagotville",
    longitude: -70.997982,
    latitude: 48.333802,
    id: "arp_ybg_ca",
    icao_code: "CYBG",
    iata_country_code: "CA",
    iata_code: "YBG",
    city_name: "Bagotville",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Canadian Forces Base Cold Lake Airport",
    longitude: -110.278999,
    latitude: 54.404999,
    id: "arp_yod_ca",
    icao_code: "CYOD",
    iata_country_code: "CA",
    iata_code: "YOD",
    city_name: "Cold Lake",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Canadian Forces Base Greenwood Airport",
    longitude: -64.9169,
    latitude: 44.984401,
    id: "arp_yzx_ca",
    icao_code: "CYZX",
    iata_country_code: "CA",
    iata_code: "YZX",
    city_name: "Greenwood",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Canadian Forces Base Trenton Airport",
    longitude: -77.528099,
    latitude: 44.1189,
    id: "arp_ytr_ca",
    icao_code: "CYTR",
    iata_country_code: "CA",
    iata_code: "YTR",
    city_name: "Trenton",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Canaima Airport",
    longitude: -62.85612,
    latitude: 6.240391,
    id: "arp_caj_ve",
    icao_code: "SVCN",
    iata_country_code: "VE",
    iata_code: "CAJ",
    city_name: "Canaima",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Çanakkale Airport",
    longitude: 26.424395,
    latitude: 40.13766,
    id: "arp_ckz_tr",
    icao_code: "LTBH",
    iata_country_code: "TR",
    iata_code: "CKZ",
    city_name: "Çanakkale",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "Cananea Airport",
    longitude: -110.096923,
    latitude: 31.066962,
    id: "arp_cna_mx",
    icao_code: "MMCA",
    iata_country_code: "MX",
    iata_code: "CNA",
    city_name: "Cananea",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Canarana Airport",
    longitude: -52.270682,
    latitude: -13.572942,
    id: "arp_cqa_br",
    icao_code: "SWEK",
    iata_country_code: "BR",
    iata_code: "CQA",
    city_name: "Canarana",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Canas Airport",
    longitude: -85.0917,
    latitude: 10.414,
    id: "arp_csc_cr",
    icao_code: "MRMJ",
    iata_country_code: "CR",
    iata_code: "CSC",
    city_name: "Canas",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Canberra Airport",
    longitude: 149.193063,
    latitude: -35.306622,
    id: "arp_cbr_au",
    icao_code: "YSCB",
    iata_country_code: "AU",
    iata_code: "CBR",
    city_name: "Canberra",
    city: null,
  },
  {
    time_zone: "America/Cancun",
    name: "Cancún International Airport",
    longitude: -86.875331,
    latitude: 21.038421,
    id: "arp_cun_mx",
    icao_code: "MMUN",
    iata_country_code: "MX",
    iata_code: "CUN",
    city_name: "Cancún",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Candala Airport",
    longitude: 49.91464,
    latitude: 11.496488,
    id: "arp_cxn_so",
    icao_code: "HCMC",
    iata_country_code: "SO",
    iata_code: "CXN",
    city_name: "Candala",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Candilejas Airport",
    longitude: -74.205385,
    latitude: 1.395701,
    id: "arp_cjd_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "CJD",
    city_name: "Candilejas",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Candle 2 Airport",
    longitude: -161.926116,
    latitude: 65.907754,
    id: "arp_cdl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CDL",
    city_name: "Candle",
    city: null,
  },
  {
    time_zone: "America/Dominica",
    name: "Canefield Airport",
    longitude: -61.391959,
    latitude: 15.33743,
    id: "arp_dcf_dm",
    icao_code: "TDCF",
    iata_country_code: "DM",
    iata_code: "DCF",
    city_name: "Canefield",
    city: {
      name: "Dominica",
      id: "cit_dom_dm",
      iata_country_code: "DM",
      iata_code: "DOM",
    },
  },
  {
    time_zone: "Africa/Luanda",
    name: "Cangamba Airport",
    longitude: 19.864088,
    latitude: -13.715577,
    id: "arp_cnz_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "CNZ",
    city_name: "Cangamba",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Cangapara Airport",
    longitude: -43.077263,
    latitude: -6.846093,
    id: "arp_flb_br",
    icao_code: "SNQG",
    iata_country_code: "BR",
    iata_code: "FLB",
    city_name: "Floriano",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Cangyuan Washan Airport",
    longitude: 99.373611,
    latitude: 23.273889,
    id: "arp_cwj_cn",
    icao_code: "ZPCW",
    iata_country_code: "CN",
    iata_code: "CWJ",
    city_name: "Lincang",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Cannes – Mandelieu Airport",
    longitude: 6.953192,
    latitude: 43.544633,
    id: "arp_ceq_fr",
    icao_code: "LFMD",
    iata_country_code: "FR",
    iata_code: "CEQ",
    city_name: "Cannes",
    city: {
      name: "Cannes",
      id: "cit_ceq_fr",
      iata_country_code: "FR",
      iata_code: "CEQ",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Cannon Air Force Base",
    longitude: -103.320767,
    latitude: 34.385129,
    id: "arp_cvs_us",
    icao_code: "KCVS",
    iata_country_code: "US",
    iata_code: "CVS",
    city_name: "Clovis",
    city: {
      name: "Clovis",
      id: "cit_cvn_us",
      iata_country_code: "US",
      iata_code: "CVN",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Canobie Airport",
    longitude: 140.927199,
    latitude: -19.479148,
    id: "arp_cby_au",
    icao_code: "YCBE",
    iata_country_code: "AU",
    iata_code: "CBY",
    city_name: "Canobie",
    city: null,
  },
  {
    time_zone: "America/St_Vincent",
    name: "Canouan Airport",
    longitude: -61.34245,
    latitude: 12.699083,
    id: "arp_ciw_vc",
    icao_code: "TVSC",
    iata_country_code: "VC",
    iata_code: "CIW",
    city_name: "Canouan",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Can Tho International Airport",
    longitude: 105.712057,
    latitude: 10.083561,
    id: "arp_vca_vn",
    icao_code: "VVCT",
    iata_country_code: "VN",
    iata_code: "VCA",
    city_name: "Can Tho",
    city: null,
  },
  {
    time_zone: "Pacific/Enderbury",
    name: "Canton Island Airport",
    longitude: -171.706153,
    latitude: -2.768896,
    id: "arp_cis_ki",
    icao_code: "PCIS",
    iata_country_code: "KI",
    iata_code: "CIS",
    city_name: "Canton Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Canton Municipal Airport",
    longitude: -96.570499,
    latitude: 43.309214,
    id: "arp_ctk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CTK",
    city_name: "Canton",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Canyonlands Field",
    longitude: -109.753438,
    latitude: 38.756152,
    id: "arp_cny_us",
    icao_code: "KCNY",
    iata_country_code: "US",
    iata_code: "CNY",
    city_name: "Moab",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Capanda Airport",
    longitude: 15.455319,
    latitude: -9.76937,
    id: "arp_knp_ao",
    icao_code: "FNCP",
    iata_country_code: "AO",
    iata_code: "KNP",
    city_name: "Capanda",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Cape Barren Island Airport",
    longitude: 148.016026,
    latitude: -40.390871,
    id: "arp_cbi_au",
    icao_code: "YCBN",
    iata_country_code: "AU",
    iata_code: "CBI",
    city_name: "Cape Barren Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cape Cod Coast Guard Airport",
    longitude: -70.520984,
    latitude: 41.655811,
    id: "arp_fmh_us",
    icao_code: "KFMH",
    iata_country_code: "US",
    iata_code: "FMH",
    city_name: "Falmouth",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Cape Dorset Airport",
    longitude: -76.527179,
    latitude: 64.230109,
    id: "arp_yte_ca",
    icao_code: "CYTE",
    iata_country_code: "CA",
    iata_code: "YTE",
    city_name: "Cape Dorset",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cape Flattery Airport",
    longitude: 145.311539,
    latitude: -14.970667,
    id: "arp_cqp_au",
    icao_code: "YCFL",
    iata_country_code: "AU",
    iata_code: "CQP",
    city_name: "Cape Flattery",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cape Girardeau Regional Airport",
    longitude: -89.568026,
    latitude: 37.225719,
    id: "arp_cgi_us",
    icao_code: "KCGI",
    iata_country_code: "US",
    iata_code: "CGI",
    city_name: "Cape Girardeau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Cape Gloucester Airport",
    longitude: 148.422646,
    latitude: -5.455151,
    id: "arp_cgc_pg",
    icao_code: "AYCG",
    iata_country_code: "PG",
    iata_code: "CGC",
    city_name: "Cape Gloucester",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Cape Lisburne LRRS Airport",
    longitude: -166.111086,
    latitude: 68.875292,
    id: "arp_lur_us",
    icao_code: "PALU",
    iata_country_code: "US",
    iata_code: "LUR",
    city_name: "Cape Lisburne",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cape May County Airport",
    longitude: -74.908127,
    latitude: 39.00839,
    id: "arp_wwd_us",
    icao_code: "KWWD",
    iata_country_code: "US",
    iata_code: "WWD",
    city_name: "Wildwood",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Cape Newenham LRRS Airport",
    longitude: -162.062927,
    latitude: 58.646409,
    id: "arp_ehm_us",
    icao_code: "PAEH",
    iata_country_code: "US",
    iata_code: "EHM",
    city_name: "Cape Newenham",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Cape Orford Airport",
    longitude: 152.082459,
    latitude: -5.447789,
    id: "arp_cpi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "CPI",
    city_name: "Cape Orford",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Cape Palmas Airport",
    longitude: -7.696949,
    latitude: 4.37902,
    id: "arp_cpa_lr",
    icao_code: "GLCP",
    iata_country_code: "LR",
    iata_code: "CPA",
    city_name: "Harper",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Cape Pole Seaplane Base",
    longitude: -133.796859,
    latitude: 55.966295,
    id: "arp_czp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CZP",
    city_name: "Cape Pole",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Cape Rodney Airport",
    longitude: 148.367697,
    latitude: -10.167359,
    id: "arp_cpn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "CPN",
    city_name: "Cape Rodney",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Cape Romanzof LRRS Airport",
    longitude: -166.038116,
    latitude: 61.781055,
    id: "arp_czf_us",
    icao_code: "PACZ",
    iata_country_code: "US",
    iata_code: "CZF",
    city_name: "Cape Romanzof",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Cape Sarichef Airport",
    longitude: -164.913218,
    latitude: 54.582467,
    id: "arp_wsf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WSF",
    city_name: "Cape Sarichef",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Cape Town International Airport",
    longitude: 18.601892,
    latitude: -33.968132,
    id: "arp_cpt_za",
    icao_code: "FACT",
    iata_country_code: "ZA",
    iata_code: "CPT",
    city_name: "Cape Town",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Cape Vogel Airport",
    longitude: 150.020034,
    latitude: -9.66994,
    id: "arp_cvl_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "CVL",
    city_name: "Cape Vogel",
    city: null,
  },
  {
    time_zone: "America/Port-au-Prince",
    name: "Cap-Haïtien International Airport",
    longitude: -72.194688,
    latitude: 19.733001,
    id: "arp_cap_ht",
    icao_code: "MTCH",
    iata_country_code: "HT",
    iata_code: "CAP",
    city_name: "Cap-Haïtien",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Capital City Airport",
    longitude: -84.904581,
    latitude: 38.182392,
    id: "arp_fft_us",
    icao_code: "KFFT",
    iata_country_code: "US",
    iata_code: "FFT",
    city_name: "Frankfort",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Capital City Airport",
    longitude: -76.85158,
    latitude: 40.217713,
    id: "arp_har_us",
    icao_code: "KCXY",
    iata_country_code: "US",
    iata_code: "HAR",
    city_name: "Harrisburg",
    city: {
      name: "Harrisburg",
      id: "cit_har_us",
      iata_country_code: "US",
      iata_code: "HAR",
    },
  },
  {
    time_zone: "America/Detroit",
    name: "Capital Region International Airport",
    longitude: -84.588421,
    latitude: 42.776865,
    id: "arp_lan_us",
    icao_code: "KLAN",
    iata_country_code: "US",
    iata_code: "LAN",
    city_name: "Lansing",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Capitán Av. Juan Cochamanidis Airport",
    longitude: -60.962586,
    latitude: -16.384064,
    id: "arp_sng_bo",
    icao_code: "SLSI",
    iata_country_code: "BO",
    iata_code: "SNG",
    city_name: "San Ignacio de Velasco",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Capitán Av. Vidal Villagomez Toledo Airpor",
    longitude: -64.099702,
    latitude: -18.482917,
    id: "arp_vah_bo",
    icao_code: "SLVG",
    iata_country_code: "BO",
    iata_code: "VAH",
    city_name: "Vallegrande",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Capitan D Daniel Vazquez Airport",
    longitude: -67.802659,
    latitude: -49.306755,
    id: "arp_ula_ar",
    icao_code: "SAWJ",
    iata_country_code: "AR",
    iata_code: "ULA",
    city_name: "San Julian",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Capitan FAP Carlos Martinez De Pinillos International Airport",
    longitude: -79.108333,
    latitude: -8.082365,
    id: "arp_tru_pe",
    icao_code: "SPRU",
    iata_country_code: "PE",
    iata_code: "TRU",
    city_name: "Trujillo",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Capitán FAP Pedro Canga Rodríguez Airport",
    longitude: -80.382088,
    latitude: -3.552094,
    id: "arp_tbp_pe",
    icao_code: "SPME",
    iata_country_code: "PE",
    iata_code: "TBP",
    city_name: "Tumbes",
    city: null,
  },
  {
    time_zone: "America/Punta_Arenas",
    name: "Capitán Fuentes Martínez Airport",
    longitude: -70.319198,
    latitude: -53.2537,
    id: "arp_wpr_cl",
    icao_code: "SCFM",
    iata_country_code: "CL",
    iata_code: "WPR",
    city_name: "Porvenir",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Capitán Germán Quiroga Guardia Airport",
    longitude: -66.737491,
    latitude: -14.858707,
    id: "arp_srj_bo",
    icao_code: "SLSB",
    iata_country_code: "BO",
    iata_code: "SRJ",
    city_name: "San Borja",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Capitan Nicolas Rojas Airport",
    longitude: -65.72338,
    latitude: -19.543259,
    id: "arp_poi_bo",
    icao_code: "SLPO",
    iata_country_code: "BO",
    iata_code: "POI",
    city_name: "Potosi",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Capitan Oriel Lea Plaza Airport",
    longitude: -64.700848,
    latitude: -21.556144,
    id: "arp_tja_bo",
    icao_code: "SLTJ",
    iata_country_code: "BO",
    iata_code: "TJA",
    city_name: "Tarija",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Cap Skirring Airport",
    longitude: -16.742054,
    latitude: 12.388166,
    id: "arp_csk_sn",
    icao_code: "GOGS",
    iata_country_code: "SN",
    iata_code: "CSK",
    city_name: "Cap Skirring",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Captain Ramon Xatruch Airport",
    longitude: -78.1417,
    latitude: 8.406669,
    id: "arp_plp_pa",
    icao_code: "MPLP",
    iata_country_code: "PA",
    iata_code: "PLP",
    city_name: "La Palma",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Captain Rogelio Castillo National Airport",
    longitude: -100.88681,
    latitude: 20.545883,
    id: "arp_cyw_mx",
    icao_code: "MMCY",
    iata_country_code: "MX",
    iata_code: "CYW",
    city_name: "Celaya",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Capurganá Airport",
    longitude: -77.35012,
    latitude: 8.632372,
    id: "arp_cpb_co",
    icao_code: "SKCA",
    iata_country_code: "CO",
    iata_code: "CPB",
    city_name: "Capurgana",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Caquetania Airport",
    longitude: -74.2167,
    latitude: 2.03333,
    id: "arp_cqt_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "CQT",
    city_name: "Caquetania",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Carajás Airport",
    longitude: -50.001249,
    latitude: -6.115498,
    id: "arp_cks_br",
    icao_code: "SBCJ",
    iata_country_code: "BR",
    iata_code: "CKS",
    city_name: "Parauapebas",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Caransebeș Airport",
    longitude: 22.253587,
    latitude: 45.420136,
    id: "arp_csb_ro",
    icao_code: "LRCS",
    iata_country_code: "RO",
    iata_code: "CSB",
    city_name: "Caransebes",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Carauari Airport",
    longitude: -66.898053,
    latitude: -4.872846,
    id: "arp_caf_br",
    icao_code: "SWCA",
    iata_country_code: "BR",
    iata_code: "CAF",
    city_name: "Carauari",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Caravelas Airport",
    longitude: -39.248869,
    latitude: -17.651717,
    id: "arp_crq_br",
    icao_code: "SBCV",
    iata_country_code: "BR",
    iata_code: "CRQ",
    city_name: "Caravelas",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Carbon County Airport",
    longitude: -110.751492,
    latitude: 39.613991,
    id: "arp_puc_us",
    icao_code: "KPUC",
    iata_country_code: "US",
    iata_code: "PUC",
    city_name: "Price",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Carcassonne Airport",
    longitude: 2.306889,
    latitude: 43.21542,
    id: "arp_ccf_fr",
    icao_code: "LFMK",
    iata_country_code: "FR",
    iata_code: "CCF",
    city_name: "Carcassonne",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Cardiff Airport",
    longitude: -3.342804,
    latitude: 51.397545,
    id: "arp_cwl_gb",
    icao_code: "EGFF",
    iata_country_code: "GB",
    iata_code: "CWL",
    city_name: "Cardiff",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Caribou Municipal Airport",
    longitude: -68.017857,
    latitude: 46.871461,
    id: "arp_car_us",
    icao_code: "KCAR",
    iata_country_code: "US",
    iata_code: "CAR",
    city_name: "Caribou",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Carimagua Airport",
    longitude: -71.336404,
    latitude: 4.566401,
    id: "arp_cco_co",
    icao_code: "SKCI",
    iata_country_code: "CO",
    iata_code: "CCO",
    city_name: "Carimagua",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Carlisle Lake District Airport",
    longitude: -2.808477,
    latitude: 54.937053,
    id: "arp_cax_gb",
    icao_code: "EGNC",
    iata_country_code: "GB",
    iata_code: "CAX",
    city_name: "Carlisle",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Carlos Manuel de Céspedes Airport",
    longitude: -76.620504,
    latitude: 20.396173,
    id: "arp_bym_cu",
    icao_code: "MUBY",
    iata_country_code: "CU",
    iata_code: "BYM",
    city_name: "Bayamo",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Carlos Miguel Jimenez Airport",
    longitude: -58.318145,
    latitude: -26.880977,
    id: "arp_pil_py",
    icao_code: "SGPI",
    iata_country_code: "PY",
    iata_code: "PIL",
    city_name: "Pilar",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Carlos Ruhl Airport",
    longitude: -53.611265,
    latitude: -28.657338,
    id: "arp_czb_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "CZB",
    city_name: "Cruz Alta",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Carlton Hill Airport",
    longitude: 128.532401,
    latitude: -15.500878,
    id: "arp_cry_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "CRY",
    city_name: "Carlton Hill",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Carmelita Airport",
    longitude: -90.053516,
    latitude: 17.459766,
    id: "arp_cmm_gt",
    icao_code: "MGCR",
    iata_country_code: "GT",
    iata_code: "CMM",
    city_name: "Carmelita",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "Carmen de Patagones Airport",
    longitude: -62.979864,
    latitude: -40.777567,
    id: "arp_cpg_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "CPG",
    city_name: "Carmen de Patagones",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Carnarvon Airport",
    longitude: 113.667792,
    latitude: -24.881729,
    id: "arp_cvq_au",
    icao_code: "YCAR",
    iata_country_code: "AU",
    iata_code: "CVQ",
    city_name: "Carnarvon",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Car Nicobar Air Force Base Airport",
    longitude: 92.81912,
    latitude: 9.151483,
    id: "arp_cbd_in",
    icao_code: "VOCX",
    iata_country_code: "IN",
    iata_code: "CBD",
    city_name: "Car Nicobar Island",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Carnot Airport",
    longitude: 15.893381,
    latitude: 4.93811,
    id: "arp_crf_cf",
    icao_code: "FEFC",
    iata_country_code: "CF",
    iata_code: "CRF",
    city_name: "Carnot",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Carora Airport",
    longitude: -70.064714,
    latitude: 10.175742,
    id: "arp_vcr_ve",
    icao_code: "SVCO",
    iata_country_code: "VE",
    iata_code: "VCR",
    city_name: "Carora",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Carosue Dam Airport",
    longitude: 122.320982,
    latitude: -30.170237,
    id: "arp_wcd_au",
    icao_code: "YSCD",
    iata_country_code: "AU",
    iata_code: "WCD",
    city_name: "Kalgoorlie-Boulder",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Carpentaria Downs Airport",
    longitude: 144.317001,
    latitude: -18.7167,
    id: "arp_cfp_au",
    icao_code: "YCPN",
    iata_country_code: "AU",
    iata_code: "CFP",
    city_name: "Carpentaria Downs",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Carrasco International Airport",
    longitude: -56.030626,
    latitude: -34.838194,
    id: "arp_mvd_uy",
    icao_code: "SUMU",
    iata_country_code: "UY",
    iata_code: "MVD",
    city_name: "Montevideo",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Carson Airport",
    longitude: -119.734552,
    latitude: 39.192462,
    id: "arp_csn_us",
    icao_code: "KCXP",
    iata_country_code: "US",
    iata_code: "CSN",
    city_name: "Carson City",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Carti Airport",
    longitude: -79.101601,
    latitude: 9.31347,
    id: "arp_cte_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "CTE",
    city_name: "Carti",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Cartwright Airport",
    longitude: -57.040484,
    latitude: 53.683054,
    id: "arp_yrf_ca",
    icao_code: "CYCA",
    iata_country_code: "CA",
    iata_code: "YRF",
    city_name: "Cartwright",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Caruaru Airport",
    longitude: -36.013301,
    latitude: -8.282503,
    id: "arp_cau_br",
    icao_code: "SNRU",
    iata_country_code: "BR",
    iata_code: "CAU",
    city_name: "Caruaru",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Carurú Airport",
    longitude: -71.297505,
    latitude: 1.013732,
    id: "arp_cuo_co",
    icao_code: "SKCR",
    iata_country_code: "CO",
    iata_code: "CUO",
    city_name: "Caruru",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Carutapera Airport",
    longitude: -46.01941,
    latitude: -1.252586,
    id: "arp_ctp_br",
    icao_code: "SNCP",
    iata_country_code: "BR",
    iata_code: "CTP",
    city_name: "Carutapera",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Casa Grande Municipal Airport",
    longitude: -111.764058,
    latitude: 32.954849,
    id: "arp_cgz_us",
    icao_code: "KCGZ",
    iata_country_code: "US",
    iata_code: "CGZ",
    city_name: "Casa Grande",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Cascade Airport",
    longitude: -116.017393,
    latitude: 44.494903,
    id: "arp_ics_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ICS",
    city_name: "Cascade",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Cascade Locks State Airport",
    longitude: -121.878426,
    latitude: 45.676918,
    id: "arp_czk_us",
    icao_code: "KCZK",
    iata_country_code: "US",
    iata_code: "CZK",
    city_name: "Cascade Locks",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Cascavel Airport",
    longitude: -53.501359,
    latitude: -25.001057,
    id: "arp_cac_br",
    icao_code: "SBCA",
    iata_country_code: "BR",
    iata_code: "CAC",
    city_name: "Cascavel",
    city: null,
  },
  {
    time_zone: "America/Adak",
    name: "Casco Cove Coast Guard Station",
    longitude: 173.179469,
    latitude: 52.832369,
    id: "arp_atu_us",
    icao_code: "PAAT",
    iata_country_code: "US",
    iata_code: "ATU",
    city_name: "Attu Island",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Casigua El Cubo Airport",
    longitude: -72.537258,
    latitude: 8.756375,
    id: "arp_cuv_ve",
    icao_code: "SVCG",
    iata_country_code: "VE",
    iata_code: "CUV",
    city_name: "Casigua El Cubo",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Casiguran Airport",
    longitude: 122.065012,
    latitude: 16.194159,
    id: "arp_cgg_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "CGG",
    city_name: "Casiguran",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Casino Airport",
    longitude: 153.052197,
    latitude: -28.878526,
    id: "arp_csi_au",
    icao_code: "YCAS",
    iata_country_code: "AU",
    iata_code: "CSI",
    city_name: "Casino",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Casper-Natrona County International Airport",
    longitude: -106.464861,
    latitude: 42.897738,
    id: "arp_cpr_us",
    icao_code: "KCPR",
    iata_country_code: "US",
    iata_code: "CPR",
    city_name: "Casper",
    city: null,
  },
  {
    time_zone: "Pacific/Kiritimati",
    name: "Cassidy International Airport",
    longitude: -157.351311,
    latitude: 1.985473,
    id: "arp_cxi_ki",
    icao_code: "PLCH",
    iata_country_code: "KI",
    iata_code: "CXI",
    city_name: "Kiritimati",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Cassilândia Airport",
    longitude: -51.679004,
    latitude: -19.146338,
    id: "arp_css_br",
    icao_code: "SSCL",
    iata_country_code: "BR",
    iata_code: "CSS",
    city_name: "Cassilândia",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Castaway Island Airport",
    longitude: 177.129331,
    latitude: -17.735709,
    id: "arp_cst_fj",
    icao_code: "NFCS",
    iata_country_code: "FJ",
    iata_code: "CST",
    city_name: "Castaway Island",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Castellón-Costa Azahar Airport",
    longitude: 0.071217,
    latitude: 40.210553,
    id: "arp_cdt_es",
    icao_code: "LECH",
    iata_country_code: "ES",
    iata_code: "CDT",
    city_name: "Castellón de la Plana",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Castle Airport",
    longitude: -120.569696,
    latitude: 37.378153,
    id: "arp_mer_us",
    icao_code: "KMER",
    iata_country_code: "US",
    iata_code: "MER",
    city_name: "Merced",
    city: {
      name: "Merced",
      id: "cit_mce_us",
      iata_country_code: "US",
      iata_code: "MCE",
    },
  },
  {
    time_zone: "Asia/Colombo",
    name: "Castlereigh Reservoir Seaplane Base Airport",
    longitude: 80.586168,
    latitude: 6.856575,
    id: "arp_nuf_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "NUF",
    city_name: "Hatton",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Castres-Mazamet Airport",
    longitude: 2.289841,
    latitude: 43.555675,
    id: "arp_dcm_fr",
    icao_code: "LFCK",
    iata_country_code: "FR",
    iata_code: "DCM",
    city_name: "Castres",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Casuarito Airport",
    longitude: -68.1333,
    latitude: 5.83333,
    id: "arp_csr_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "CSR",
    city_name: "Casuarito",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Catacamas Airport",
    longitude: -85.774704,
    latitude: 14.875231,
    id: "arp_caa_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "CAA",
    city_name: "Catacamas",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Catalão Airport",
    longitude: -47.899433,
    latitude: -18.217011,
    id: "arp_tlz_br",
    icao_code: "SWKT",
    iata_country_code: "BR",
    iata_code: "TLZ",
    city_name: "Catalão",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Catalina Airport",
    longitude: -118.415756,
    latitude: 33.404773,
    id: "arp_avx_us",
    icao_code: "KAVX",
    iata_country_code: "US",
    iata_code: "AVX",
    city_name: "Avalon",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "Catamarca International Airport",
    longitude: -65.752599,
    latitude: -28.594115,
    id: "arp_ctc_ar",
    icao_code: "SANC",
    iata_country_code: "AR",
    iata_code: "CTC",
    city_name: "Catamarca",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Catania-Fontanarossa Airport",
    longitude: 15.066087,
    latitude: 37.467052,
    id: "arp_cta_it",
    icao_code: "LICC",
    iata_country_code: "IT",
    iata_code: "CTA",
    city_name: "Catania",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Cataratas Del Iguazu International Airport",
    longitude: -54.475339,
    latitude: -25.735013,
    id: "arp_igr_ar",
    icao_code: "SARI",
    iata_country_code: "AR",
    iata_code: "IGR",
    city_name: "Iguazu",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Catarman National Airport",
    longitude: 124.636227,
    latitude: 12.50367,
    id: "arp_crm_ph",
    icao_code: "RPVF",
    iata_country_code: "PH",
    iata_code: "CRM",
    city_name: "Catarman",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Cat Bi International Airport",
    longitude: 106.725493,
    latitude: 20.821085,
    id: "arp_hph_vn",
    icao_code: "VVCI",
    iata_country_code: "VN",
    iata_code: "HPH",
    city_name: "Haiphong",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Cat Cay Airport",
    longitude: -79.274867,
    latitude: 25.55407,
    id: "arp_cxy_bs",
    icao_code: "MYCC",
    iata_country_code: "BS",
    iata_code: "CXY",
    city_name: "Cat Cays",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Cat Lake Airport",
    longitude: -91.826347,
    latitude: 51.726869,
    id: "arp_yac_ca",
    icao_code: "CYAC",
    iata_country_code: "CA",
    iata_code: "YAC",
    city_name: "Cat Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cattaraugus County Olean Airport",
    longitude: -78.371638,
    latitude: 42.242231,
    id: "arp_ole_us",
    icao_code: "KOLE",
    iata_country_code: "US",
    iata_code: "OLE",
    city_name: "Olean",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Cattle Creek Airport",
    longitude: 131.549812,
    latitude: -17.607511,
    id: "arp_ctr_au",
    icao_code: "YCAC",
    iata_country_code: "AU",
    iata_code: "CTR",
    city_name: "Cattle Creek",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Catumbela Airport",
    longitude: 13.488571,
    latitude: -12.479468,
    id: "arp_cbt_ao",
    icao_code: "FNCT",
    iata_country_code: "AO",
    iata_code: "CBT",
    city_name: "Catumbela",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Cauayan Airport",
    longitude: 121.754281,
    latitude: 16.928679,
    id: "arp_cyz_ph",
    icao_code: "RPUY",
    iata_country_code: "PH",
    iata_code: "CYZ",
    city_name: "Cauayan",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Caucayá Airport",
    longitude: -74.772865,
    latitude: -0.181902,
    id: "arp_lqm_co",
    icao_code: "SKLG",
    iata_country_code: "CO",
    iata_code: "LQM",
    city_name: "Puerto Leguízamo",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Cauquira Airport",
    longitude: -83.604615,
    latitude: 15.321329,
    id: "arp_cdd_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "CDD",
    city_name: "Cauquira",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Cavern City Air Terminal",
    longitude: -104.263112,
    latitude: 32.337501,
    id: "arp_cnm_us",
    icao_code: "KCNM",
    iata_country_code: "US",
    iata_code: "CNM",
    city_name: "Carlsbad",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Caviahue Airport",
    longitude: -71.009653,
    latitude: -37.850912,
    id: "arp_cvh_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "CVH",
    city_name: "Caviahue",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Caxias do Sul Airport",
    longitude: -51.187462,
    latitude: -29.196296,
    id: "arp_cxj_br",
    icao_code: "SBCX",
    iata_country_code: "BR",
    iata_code: "CXJ",
    city_name: "Caxias Do Sul",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Cayana Airstrip",
    longitude: -55.578006,
    latitude: 3.898639,
    id: "arp_aaj_sr",
    icao_code: "SMCA",
    iata_country_code: "SR",
    iata_code: "AAJ",
    city_name: "Awaradam",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Caye Caulker Airport",
    longitude: -88.028938,
    latitude: 17.734987,
    id: "arp_cuk_bz",
    icao_code: "MZCK",
    iata_country_code: "BZ",
    iata_code: "CUK",
    city_name: "Caye Caulker",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Caye Chapel Airport",
    longitude: -88.044831,
    latitude: 17.683589,
    id: "arp_cyc_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "CYC",
    city_name: "Caye Chapel",
    city: null,
  },
  {
    time_zone: "America/Cayenne",
    name: "Cayenne – Félix Eboué Airport",
    longitude: -52.362842,
    latitude: 4.819628,
    id: "arp_cay_gf",
    icao_code: "SOCA",
    iata_country_code: "GF",
    iata_code: "CAY",
    city_name: "Cayenne",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Cazombo Airport",
    longitude: 22.916311,
    latitude: -11.893411,
    id: "arp_cav_ao",
    icao_code: "FNCZ",
    iata_country_code: "AO",
    iata_code: "CAV",
    city_name: "Cazombo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cecil Airport",
    longitude: -81.878036,
    latitude: 30.219587,
    id: "arp_vqq_us",
    icao_code: "KVQQ",
    iata_country_code: "US",
    iata_code: "VQQ",
    city_name: "Jacksonville",
    city: {
      name: "Jacksonville",
      id: "cit_jax_us",
      iata_country_code: "US",
      iata_code: "JAX",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Cedar City Regional Airport",
    longitude: -113.098201,
    latitude: 37.699527,
    id: "arp_cdc_us",
    icao_code: "KCDC",
    iata_country_code: "US",
    iata_code: "CDC",
    city_name: "Cedar City",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Ceduna Airport",
    longitude: 133.702074,
    latitude: -32.124066,
    id: "arp_ced_au",
    icao_code: "YCDU",
    iata_country_code: "AU",
    iata_code: "CED",
    city_name: "Ceduna",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Cengiz Topel Airport",
    longitude: 30.097249,
    latitude: 40.737663,
    id: "arp_kco_tr",
    icao_code: "LTBQ",
    iata_country_code: "TR",
    iata_code: "KCO",
    city_name: "Kocaeli",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Centennial Airport",
    longitude: -104.848907,
    latitude: 39.57277,
    id: "arp_apa_us",
    icao_code: "KAPA",
    iata_country_code: "US",
    iata_code: "APA",
    city_name: "Denver",
    city: {
      name: "Denver",
      id: "cit_den_us",
      iata_country_code: "US",
      iata_code: "DEN",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Center Island Airport",
    longitude: -122.831512,
    latitude: 48.489875,
    id: "arp_cws_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CWS",
    city_name: "Center Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Centerville Municipal Airport",
    longitude: -87.444501,
    latitude: 35.837296,
    id: "arp_ghm_us",
    icao_code: "KGHM",
    iata_country_code: "US",
    iata_code: "GHM",
    city_name: "Centerville",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Central Airport",
    longitude: -144.783022,
    latitude: 65.573736,
    id: "arp_cem_us",
    icao_code: "PACE",
    iata_country_code: "US",
    iata_code: "CEM",
    city_name: "Central",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Centralia / James T. Field Memorial Aerodrome",
    longitude: -81.506616,
    latitude: 43.284883,
    id: "arp_yce_ca",
    icao_code: "CYCE",
    iata_country_code: "CA",
    iata_code: "YCE",
    city_name: "Centralia",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Centralia Municipal Airport",
    longitude: -89.091826,
    latitude: 38.515702,
    id: "arp_enl_us",
    icao_code: "KENL",
    iata_country_code: "US",
    iata_code: "ENL",
    city_name: "Centralia",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Central Illinois Regional Airport",
    longitude: -88.915858,
    latitude: 40.478836,
    id: "arp_bmi_us",
    icao_code: "KBMI",
    iata_country_code: "US",
    iata_code: "BMI",
    city_name: "Bloomington",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Central Jersey Regional Airport",
    longitude: -74.59636,
    latitude: 40.526308,
    id: "arp_jvi_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "JVI",
    city_name: "Manville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Central Maine Regional Norridgewock Airport",
    longitude: -69.865456,
    latitude: 44.716841,
    id: "arp_owk_us",
    icao_code: "KOWK",
    iata_country_code: "US",
    iata_code: "OWK",
    city_name: "Norridgewock",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Central Nebraska Regional Airport",
    longitude: -98.312602,
    latitude: 40.968667,
    id: "arp_gri_us",
    icao_code: "KGRI",
    iata_country_code: "US",
    iata_code: "GRI",
    city_name: "Grand Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Central Wisconsin Airport",
    longitude: -89.669803,
    latitude: 44.78089,
    id: "arp_cwa_us",
    icao_code: "KCWA",
    iata_country_code: "US",
    iata_code: "CWA",
    city_name: "Wausau",
    city: {
      name: "Wausau",
      id: "cit_auw_us",
      iata_country_code: "US",
      iata_code: "AUW",
    },
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Ceres Airport",
    longitude: -61.928567,
    latitude: -29.872801,
    id: "arp_crr_ar",
    icao_code: "SANW",
    iata_country_code: "AR",
    iata_code: "CRR",
    city_name: "Ceres",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Cerro Largo International Airport",
    longitude: -54.220243,
    latitude: -32.338669,
    id: "arp_mlz_uy",
    icao_code: "SUMO",
    iata_country_code: "UY",
    iata_code: "MLZ",
    city_name: "Melo",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Cesar Lim Rodriguez Airport",
    longitude: 119.518906,
    latitude: 11.05105,
    id: "arp_rzp_ph",
    icao_code: "RPSD",
    iata_country_code: "PH",
    iata_code: "RZP",
    city_name: "Taytay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cessna Aircraft Field",
    longitude: -97.250038,
    latitude: 37.648104,
    id: "arp_cea_us",
    icao_code: "KCEA",
    iata_country_code: "US",
    iata_code: "CEA",
    city_name: "Wichita",
    city: {
      name: "Wichita",
      id: "cit_ict_us",
      iata_country_code: "US",
      iata_code: "ICT",
    },
  },
  {
    time_zone: "Australia/Sydney",
    name: "Cessnock Airport",
    longitude: 151.342061,
    latitude: -32.785451,
    id: "arp_ces_au",
    icao_code: "YCNK",
    iata_country_code: "AU",
    iata_code: "CES",
    city_name: "Cessnock",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Chacalluta Airport",
    longitude: -70.336848,
    latitude: -18.348734,
    id: "arp_ari_cl",
    icao_code: "SCAR",
    iata_country_code: "CL",
    iata_code: "ARI",
    city_name: "Arica",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Chacarita Airport",
    longitude: -84.772697,
    latitude: 9.98141,
    id: "arp_jap_cr",
    icao_code: "MRCH",
    iata_country_code: "CR",
    iata_code: "JAP",
    city_name: "Puntarenas",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Chachapoyas Airport",
    longitude: -77.854276,
    latitude: -6.204331,
    id: "arp_chh_pe",
    icao_code: "SPPY",
    iata_country_code: "PE",
    iata_code: "CHH",
    city_name: "Chachapoyas",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Chachoán Airport",
    longitude: -78.574778,
    latitude: -1.212612,
    id: "arp_atf_ec",
    icao_code: "SEAM",
    iata_country_code: "EC",
    iata_code: "ATF",
    city_name: "Ambato",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Chadron Municipal Airport",
    longitude: -103.095216,
    latitude: 42.835749,
    id: "arp_cdr_us",
    icao_code: "KCDR",
    iata_country_code: "US",
    iata_code: "CDR",
    city_name: "Chadron",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Chafei Amsei Airport",
    longitude: -48.594411,
    latitude: -20.584671,
    id: "arp_bat_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "BAT",
    city_name: "Barretos",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Chaghcharan Airport",
    longitude: 65.274321,
    latitude: 34.527867,
    id: "arp_ccn_af",
    icao_code: "OACC",
    iata_country_code: "AF",
    iata_code: "CCN",
    city_name: "Chaghcharan",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Chaitén Airport",
    longitude: -72.699049,
    latitude: -42.932896,
    id: "arp_wch_cl",
    icao_code: "SCTN",
    iata_country_code: "CL",
    iata_code: "WCH",
    city_name: "Chaiten",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chalkyitsik Airport",
    longitude: -143.7402,
    latitude: 66.644911,
    id: "arp_cik_us",
    icao_code: "PACI",
    iata_country_code: "US",
    iata_code: "CIK",
    city_name: "Chalkyitsik",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Challis Airport",
    longitude: -114.217195,
    latitude: 44.521586,
    id: "arp_chl_us",
    icao_code: "KLLJ",
    iata_country_code: "US",
    iata_code: "CHL",
    city_name: "Challis",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Châlons Vatry Airport",
    longitude: 4.189426,
    latitude: 48.780471,
    id: "arp_xcr_fr",
    icao_code: "LFOK",
    iata_country_code: "FR",
    iata_code: "XCR",
    city_name: "Paris",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Chambéry Airport",
    longitude: 5.882186,
    latitude: 45.638053,
    id: "arp_cmf_fr",
    icao_code: "LFLB",
    iata_country_code: "FR",
    iata_code: "CMF",
    city_name: "Chambery",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Chañaral Airport",
    longitude: -70.607507,
    latitude: -26.332567,
    id: "arp_cnr_cl",
    icao_code: "SCRA",
    iata_country_code: "CL",
    iata_code: "CNR",
    city_name: "Chañaral",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chandalar Lake Airport",
    longitude: -148.483143,
    latitude: 67.504511,
    id: "arp_wcr_us",
    icao_code: "PALR",
    iata_country_code: "US",
    iata_code: "WCR",
    city_name: "Chandalar Lake",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Chandigarh Airport",
    longitude: 76.787373,
    latitude: 30.670797,
    id: "arp_ixc_in",
    icao_code: "VICG",
    iata_country_code: "IN",
    iata_code: "IXC",
    city_name: "Chandigarh",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chandler Field",
    longitude: -95.394702,
    latitude: 45.866058,
    id: "arp_axn_us",
    icao_code: "KAXN",
    iata_country_code: "US",
    iata_code: "AXN",
    city_name: "Alexandria",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changbaishan Airport",
    longitude: 127.602222,
    latitude: 42.066944,
    id: "arp_nbs_cn",
    icao_code: "ZYBS",
    iata_country_code: "CN",
    iata_code: "NBS",
    city_name: "Baishan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changchun Longjia International Airport",
    longitude: 125.689013,
    latitude: 43.995873,
    id: "arp_cgq_cn",
    icao_code: "ZYCC",
    iata_country_code: "CN",
    iata_code: "CGQ",
    city_name: "Changchun",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changde Taohuayuan Airport",
    longitude: 111.641186,
    latitude: 28.919657,
    id: "arp_cgd_cn",
    icao_code: "ZGCD",
    iata_country_code: "CN",
    iata_code: "CGD",
    city_name: "Changde",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changhai Airport",
    longitude: 122.666977,
    latitude: 39.265915,
    id: "arp_cni_cn",
    icao_code: "ZYCH",
    iata_country_code: "CN",
    iata_code: "CNI",
    city_name: "Changhai",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changsha Huanghua International Airport",
    longitude: 113.220417,
    latitude: 28.192933,
    id: "arp_csx_cn",
    icao_code: "ZGHA",
    iata_country_code: "CN",
    iata_code: "CSX",
    city_name: "Changsha",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: 'Changuinola "Capitan Manuel Niño" International Airport',
    longitude: -82.515772,
    latitude: 9.458127,
    id: "arp_chx_pa",
    icao_code: "MPCH",
    iata_country_code: "PA",
    iata_code: "CHX",
    city_name: "Changuinola",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chan Gurney Municipal Airport",
    longitude: -97.385868,
    latitude: 42.916683,
    id: "arp_ykn_us",
    icao_code: "KYKN",
    iata_country_code: "US",
    iata_code: "YKN",
    city_name: "Yankton",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changzhi Wangcun Airport",
    longitude: 113.127283,
    latitude: 36.247196,
    id: "arp_cih_cn",
    icao_code: "ZBCZ",
    iata_country_code: "CN",
    iata_code: "CIH",
    city_name: "Changzhi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Changzhou Benniu Airport",
    longitude: 119.781467,
    latitude: 31.915874,
    id: "arp_czx_cn",
    icao_code: "ZSCG",
    iata_country_code: "CN",
    iata_code: "CZX",
    city_name: "Changzhou",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Chania International Airport",
    longitude: 24.139999,
    latitude: 35.538986,
    id: "arp_chq_gr",
    icao_code: "LGSA",
    iata_country_code: "GR",
    iata_code: "CHQ",
    city_name: "Chania",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chanute Martin Johnson Airport",
    longitude: -95.485946,
    latitude: 37.669965,
    id: "arp_cnu_us",
    icao_code: "KCNU",
    iata_country_code: "US",
    iata_code: "CNU",
    city_name: "Chanute",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Chaoyang Airport",
    longitude: 120.441705,
    latitude: 41.546763,
    id: "arp_chg_cn",
    icao_code: "ZYCY",
    iata_country_code: "CN",
    iata_code: "CHG",
    city_name: "Chaoyang",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Chapecó Airport",
    longitude: -52.658019,
    latitude: -27.133471,
    id: "arp_xap_br",
    icao_code: "SBCH",
    iata_country_code: "BR",
    iata_code: "XAP",
    city_name: "Chapecó",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Chapleau Airport",
    longitude: -83.348841,
    latitude: 47.819919,
    id: "arp_yld_ca",
    icao_code: "CYLD",
    iata_country_code: "CA",
    iata_code: "YLD",
    city_name: "Chapleau",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Charata Airport",
    longitude: -61.209733,
    latitude: -27.216395,
    id: "arp_cnt_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "CNT",
    city_name: "Charata",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Charles B. Wheeler Downtown Airport",
    longitude: -94.592048,
    latitude: 39.122623,
    id: "arp_mkc_us",
    icao_code: "KMKC",
    iata_country_code: "US",
    iata_code: "MKC",
    city_name: "Kansas City",
    city: {
      name: "Kansas City",
      id: "cit_mkc_us",
      iata_country_code: "US",
      iata_code: "MKC",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Charles M. Schulz Sonoma County Airport",
    longitude: -122.813134,
    latitude: 38.508804,
    id: "arp_sts_us",
    icao_code: "KSTS",
    iata_country_code: "US",
    iata_code: "STS",
    city_name: "Santa Rosa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Charleston International Airport",
    longitude: -80.039328,
    latitude: 32.896435,
    id: "arp_chs_us",
    icao_code: "KCHS",
    iata_country_code: "US",
    iata_code: "CHS",
    city_name: "Charleston",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Charleville Airport",
    longitude: 146.261367,
    latitude: -26.41335,
    id: "arp_ctl_au",
    icao_code: "YBCV",
    iata_country_code: "AU",
    iata_code: "CTL",
    city_name: "Charleville",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Charlevoix Airport",
    longitude: -70.228674,
    latitude: 47.598416,
    id: "arp_yml_ca",
    icao_code: "CYML",
    iata_country_code: "CA",
    iata_code: "YML",
    city_name: "Charlevoix",
    city: null,
  },
  {
    time_zone: "America/Moncton",
    name: "Charlo Airport",
    longitude: -66.330714,
    latitude: 47.990787,
    id: "arp_ycl_ca",
    icao_code: "CYCL",
    iata_country_code: "CA",
    iata_code: "YCL",
    city_name: "Charlo",
    city: null,
  },
  {
    time_zone: "America/St_Thomas",
    name: "Charlotte Amalie Harbor Seaplane Base",
    longitude: -64.940367,
    latitude: 18.33855,
    id: "arp_spb_vi",
    icao_code: "VI22",
    iata_country_code: "VI",
    iata_code: "SPB",
    city_name: "St Thomas Island/Charlotte Amalie",
    city: {
      name: "St Thomas Island",
      id: "cit_stt_vi",
      iata_country_code: "VI",
      iata_code: "STT",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Charlotte Douglas International Airport",
    longitude: -80.945207,
    latitude: 35.214201,
    id: "arp_clt_us",
    icao_code: "KCLT",
    iata_country_code: "US",
    iata_code: "CLT",
    city_name: "Charlotte",
    city: {
      name: "Charlotte",
      id: "cit_clt_us",
      iata_country_code: "US",
      iata_code: "CLT",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Charlottesville Albemarle Airport",
    longitude: -78.451674,
    latitude: 38.139017,
    id: "arp_cho_us",
    icao_code: "KCHO",
    iata_country_code: "US",
    iata_code: "CHO",
    city_name: "Charlottesville",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Charlottetown Airport",
    longitude: -63.126057,
    latitude: 46.287463,
    id: "arp_yhg_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YHG",
    city_name: "Charlottetown",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Charlottetown Airport",
    longitude: -63.124202,
    latitude: 46.29028,
    id: "arp_yyg_ca",
    icao_code: "CYYG",
    iata_country_code: "CA",
    iata_code: "YYG",
    city_name: "Charlottetown",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Charters Towers Airport",
    longitude: 146.270444,
    latitude: -20.045478,
    id: "arp_cxt_au",
    icao_code: "YCHT",
    iata_country_code: "AU",
    iata_code: "CXT",
    city_name: "Charters Towers",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chase Field Industrial Airport",
    longitude: -97.657979,
    latitude: 28.359575,
    id: "arp_nir_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NIR",
    city_name: "Beeville",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: 'Châteauroux-Centre "Marcel Dassault" Airport',
    longitude: 1.721962,
    latitude: 46.859173,
    id: "arp_chr_fr",
    icao_code: "LFLX",
    iata_country_code: "FR",
    iata_code: "CHR",
    city_name: "Chateauroux",
    city: null,
  },
  {
    time_zone: "Pacific/Chatham",
    name: "Chatham Islands-Tuuta Airport",
    longitude: -176.457074,
    latitude: -43.810001,
    id: "arp_cht_nz",
    icao_code: "NZCI",
    iata_country_code: "NZ",
    iata_code: "CHT",
    city_name: "Waitangi",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Chatham-Kent Airport",
    longitude: -82.080728,
    latitude: 42.306297,
    id: "arp_xcm_ca",
    icao_code: "CYCK",
    iata_country_code: "CA",
    iata_code: "XCM",
    city_name: "Chatham-Kent",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Chatham Seaplane Base",
    longitude: -134.946143,
    latitude: 57.514925,
    id: "arp_cym_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CYM",
    city_name: "Chatham",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Chattanooga Metropolitan Airport",
    longitude: -85.201587,
    latitude: 35.035967,
    id: "arp_cha_us",
    icao_code: "KCHA",
    iata_country_code: "US",
    iata_code: "CHA",
    city_name: "Chattanooga",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Chaudhary Charan Singh International Airport",
    longitude: 80.887476,
    latitude: 26.761165,
    id: "arp_lko_in",
    icao_code: "VILK",
    iata_country_code: "IN",
    iata_code: "LKO",
    city_name: "Lucknow",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Chautauqua County/Dunkirk Airport",
    longitude: -79.275247,
    latitude: 42.492997,
    id: "arp_dkk_us",
    icao_code: "KDKK",
    iata_country_code: "US",
    iata_code: "DKK",
    city_name: "Dunkirk",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Chautauqua County-Jamestown Airport",
    longitude: -79.258747,
    latitude: 42.15301,
    id: "arp_jhw_us",
    icao_code: "KJHW",
    iata_country_code: "US",
    iata_code: "JHW",
    city_name: "Jamestown",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Chaves Airport",
    longitude: -7.465266,
    latitude: 41.724114,
    id: "arp_chv_pt",
    icao_code: "LPCH",
    iata_country_code: "PT",
    iata_code: "CHV",
    city_name: "Chaves",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Cheadle Airport",
    longitude: -113.631409,
    latitude: 51.05737,
    id: "arp_til_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "TIL",
    city_name: "Cheadle",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Cheboksary Airport",
    longitude: 47.320211,
    latitude: 56.082469,
    id: "arp_csy_ru",
    icao_code: "UWKS",
    iata_country_code: "RU",
    iata_code: "CSY",
    city_name: "Cheboksary",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Cheddi Jagan International Airport",
    longitude: -58.253473,
    latitude: 6.501116,
    id: "arp_geo_gy",
    icao_code: "SYCJ",
    iata_country_code: "GY",
    iata_code: "GEO",
    city_name: "Georgetown",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Chefornak Airport",
    longitude: -164.282678,
    latitude: 60.138041,
    id: "arp_cyf_us",
    icao_code: "PACK",
    iata_country_code: "US",
    iata_code: "CYF",
    city_name: "Chefornak",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Chehalis-Centralia Airport",
    longitude: -122.982919,
    latitude: 46.67697,
    id: "arp_cls_us",
    icao_code: "KCLS",
    iata_country_code: "US",
    iata_code: "CLS",
    city_name: "Chehalis",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Cheikh Larbi Tébessi Airport",
    longitude: 8.118599,
    latitude: 35.427437,
    id: "arp_tee_dz",
    icao_code: "DABS",
    iata_country_code: "DZ",
    iata_code: "TEE",
    city_name: "Tebessa",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Chelinda Airport",
    longitude: 33.793905,
    latitude: -10.558631,
    id: "arp_ceh_mw",
    icao_code: "FWCD",
    iata_country_code: "MW",
    iata_code: "CEH",
    city_name: "Chelinda",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Chelyabinsk Airport",
    longitude: 61.505466,
    latitude: 55.303127,
    id: "arp_cek_ru",
    icao_code: "USCC",
    iata_country_code: "RU",
    iata_code: "CEK",
    city_name: "Chelyabinsk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chena Hot Springs Airport",
    longitude: -146.046509,
    latitude: 65.05217,
    id: "arp_cex_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CEX",
    city_name: "Chena Hot Springs",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chenega Bay Airport",
    longitude: -147.993843,
    latitude: 60.077789,
    id: "arp_ncn_us",
    icao_code: "PFCB",
    iata_country_code: "US",
    iata_code: "NCN",
    city_name: "Chenega",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Chengde Puning Airport",
    longitude: 118.073813,
    latitude: 41.122306,
    id: "arp_cde_cn",
    icao_code: "ZBCD",
    iata_country_code: "CN",
    iata_code: "CDE",
    city_name: "Chengde",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Chengdu Shuangliu International Airport",
    longitude: 103.949368,
    latitude: 30.568416,
    id: "arp_ctu_cn",
    icao_code: "ZUUU",
    iata_country_code: "CN",
    iata_code: "CTU",
    city_name: "Chengdu",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Chennai International Airport",
    longitude: 80.170081,
    latitude: 12.992058,
    id: "arp_maa_in",
    icao_code: "VOMM",
    iata_country_code: "IN",
    iata_code: "MAA",
    city_name: "Chennai",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chennault International Airport",
    longitude: -93.146287,
    latitude: 30.212483,
    id: "arp_cwf_us",
    icao_code: "KCWF",
    iata_country_code: "US",
    iata_code: "CWF",
    city_name: "Lake Charles",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Cheongju International Airport",
    longitude: 127.497599,
    latitude: 36.717837,
    id: "arp_cjj_kr",
    icao_code: "RKTU",
    iata_country_code: "KR",
    iata_code: "CJJ",
    city_name: "Cheongju",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cheraw Municipal Airport",
    longitude: -79.957677,
    latitude: 34.712643,
    id: "arp_hcw_us",
    icao_code: "KCQW",
    iata_country_code: "US",
    iata_code: "HCW",
    city_name: "Cheraw",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Cherbourg – Maupertus Airport",
    longitude: -1.472069,
    latitude: 49.649337,
    id: "arp_cer_fr",
    icao_code: "LFRC",
    iata_country_code: "FR",
    iata_code: "CER",
    city_name: "Cherbourg",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Cherepovets Airport",
    longitude: 38.016133,
    latitude: 59.275857,
    id: "arp_cee_ru",
    icao_code: "ULWC",
    iata_country_code: "RU",
    iata_code: "CEE",
    city_name: "Cherepovets",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Cherif Al Idrissi Airport",
    longitude: -3.840486,
    latitude: 35.178129,
    id: "arp_ahu_ma",
    icao_code: "GMTA",
    iata_country_code: "MA",
    iata_code: "AHU",
    city_name: "Al Hoceima",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Cherkasy International Airport",
    longitude: 32.012176,
    latitude: 49.403205,
    id: "arp_ckc_ua",
    icao_code: "UKKE",
    iata_country_code: "UA",
    iata_code: "CKC",
    city_name: "Cherkasy",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Chernivtsi International Airport",
    longitude: 25.967829,
    latitude: 48.266847,
    id: "arp_cwc_ua",
    icao_code: "UKLN",
    iata_country_code: "UA",
    iata_code: "CWC",
    city_name: "Chernivtsi",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Chernofski Harbor Seaplane Base",
    longitude: -167.5519,
    latitude: 53.3991,
    id: "arp_kcn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KCN",
    city_name: "Chernofski Harbor",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cherokee County Airport",
    longitude: -95.216536,
    latitude: 31.869998,
    id: "arp_jkv_us",
    icao_code: "KJSO",
    iata_country_code: "US",
    iata_code: "JKV",
    city_name: "Jacksonville",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cherrabah Airport",
    longitude: 152.090077,
    latitude: -28.430988,
    id: "arp_crh_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "CRH",
    city_name: "Cherrabah",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Cherrabun Airport",
    longitude: 125.55,
    latitude: -18.917,
    id: "arp_cbc_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "CBC",
    city_name: "Cherrabun",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Cherry Capital Airport",
    longitude: -85.580929,
    latitude: 44.73917,
    id: "arp_tvc_us",
    icao_code: "KTVC",
    iata_country_code: "US",
    iata_code: "TVC",
    city_name: "Traverse City",
    city: null,
  },
  {
    time_zone: "Asia/Srednekolymsk",
    name: "Cherskiy Airport",
    longitude: 161.34226,
    latitude: 68.741293,
    id: "arp_cyx_ru",
    icao_code: "UESS",
    iata_country_code: "RU",
    iata_code: "CYX",
    city_name: "Cherskiy",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Chester County G. O. Carlson Airport",
    longitude: -75.864605,
    latitude: 39.979361,
    id: "arp_cth_us",
    icao_code: "KMQS",
    iata_country_code: "US",
    iata_code: "CTH",
    city_name: "Coatesville",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Chesterfield Inlet Airport",
    longitude: -90.731052,
    latitude: 63.34704,
    id: "arp_ycs_ca",
    icao_code: "CYCS",
    iata_country_code: "CA",
    iata_code: "YCS",
    city_name: "Chesterfield Inlet",
    city: null,
  },
  {
    time_zone: "America/Cancun",
    name: "Chetumal International Airport",
    longitude: -88.325546,
    latitude: 18.505547,
    id: "arp_ctm_mx",
    icao_code: "MMCM",
    iata_country_code: "MX",
    iata_code: "CTM",
    city_name: "Chetumal",
    city: null,
  },
  {
    time_zone: "America/Dawson_Creek",
    name: "Chetwynd Airport",
    longitude: -121.624794,
    latitude: 55.687967,
    id: "arp_ycq_ca",
    icao_code: "CYCQ",
    iata_country_code: "CA",
    iata_code: "YCQ",
    city_name: "Chetwynd",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Chevak Airport",
    longitude: -165.600326,
    latitude: 61.53975,
    id: "arp_vak_us",
    icao_code: "PAVA",
    iata_country_code: "US",
    iata_code: "VAK",
    city_name: "Chevak",
    city: null,
  },
  {
    time_zone: "America/Blanc-Sablon",
    name: "Chevery Airport",
    longitude: -59.632894,
    latitude: 50.469594,
    id: "arp_yhr_ca",
    icao_code: "CYHR",
    iata_country_code: "CA",
    iata_code: "YHR",
    city_name: "Chevery",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Cheyenne Regional Airport",
    longitude: -104.81374,
    latitude: 41.154498,
    id: "arp_cys_us",
    icao_code: "KCYS",
    iata_country_code: "US",
    iata_code: "CYS",
    city_name: "Cheyenne",
    city: {
      name: "Cheyenne",
      id: "cit_cys_us",
      iata_country_code: "US",
      iata_code: "CYS",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Chhatrapati Shivaji International Airport",
    longitude: 72.867536,
    latitude: 19.089283,
    id: "arp_bom_in",
    icao_code: "VABB",
    iata_country_code: "IN",
    iata_code: "BOM",
    city_name: "Mumbai",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Chiang Mai International Airport",
    longitude: 98.963304,
    latitude: 18.767274,
    id: "arp_cnx_th",
    icao_code: "VTCC",
    iata_country_code: "TH",
    iata_code: "CNX",
    city_name: "Chiang Mai",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Chiang Rai International Airport",
    longitude: 99.881965,
    latitude: 19.952797,
    id: "arp_cei_th",
    icao_code: "VTCT",
    iata_country_code: "TH",
    iata_code: "CEI",
    city_name: "Chiang Rai",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Chiayi Airport",
    longitude: 120.396846,
    latitude: 23.458158,
    id: "arp_cyi_tw",
    icao_code: "RCKU",
    iata_country_code: "TW",
    iata_code: "CYI",
    city_name: "Chiayi",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Chibougamau/Chapais Airport",
    longitude: -74.528124,
    latitude: 49.775744,
    id: "arp_ymt_ca",
    icao_code: "CYMT",
    iata_country_code: "CA",
    iata_code: "YMT",
    city_name: "Chibougamau",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chicago Executive Airport",
    longitude: -87.901879,
    latitude: 42.11582,
    id: "arp_pwk_us",
    icao_code: "KPWK",
    iata_country_code: "US",
    iata_code: "PWK",
    city_name: "Chicago",
    city: {
      name: "Chicago",
      id: "cit_chi_us",
      iata_country_code: "US",
      iata_code: "CHI",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Chicago Midway International Airport",
    longitude: -87.750029,
    latitude: 41.786336,
    id: "arp_mdw_us",
    icao_code: "KMDW",
    iata_country_code: "US",
    iata_code: "MDW",
    city_name: "Chicago",
    city: {
      name: "Chicago",
      id: "cit_chi_us",
      iata_country_code: "US",
      iata_code: "CHI",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Chicago Rockford International Airport",
    longitude: -89.097969,
    latitude: 42.19732,
    id: "arp_rfd_us",
    icao_code: "KRFD",
    iata_country_code: "US",
    iata_code: "RFD",
    city_name: "Chicago",
    city: {
      name: "Chicago",
      id: "cit_chi_us",
      iata_country_code: "US",
      iata_code: "CHI",
    },
  },
  {
    time_zone: "America/Mexico_City",
    name: "Chichen Itza International Airport",
    longitude: -88.446584,
    latitude: 20.640794,
    id: "arp_cza_mx",
    icao_code: "MMCT",
    iata_country_code: "MX",
    iata_code: "CZA",
    city_name: "Chichen Itza",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Chichester/Goodwood Airport",
    longitude: -0.758832,
    latitude: 50.860171,
    id: "arp_qug_gb",
    icao_code: "EGHR",
    iata_country_code: "GB",
    iata_code: "QUG",
    city_name: "Chichester",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Chickasha Municipal Airport",
    longitude: -97.966515,
    latitude: 35.093119,
    id: "arp_chk_us",
    icao_code: "KCHK",
    iata_country_code: "US",
    iata_code: "CHK",
    city_name: "Chickasha",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chicken Airport",
    longitude: -141.951519,
    latitude: 64.068865,
    id: "arp_ckx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CKX",
    city_name: "Chicken",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Chiclayo International Airport",
    longitude: -79.828667,
    latitude: -6.778026,
    id: "arp_cix_pe",
    icao_code: "SPHI",
    iata_country_code: "PE",
    iata_code: "CIX",
    city_name: "Chiclayo",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Chico Municipal Airport",
    longitude: -121.856373,
    latitude: 39.797242,
    id: "arp_cic_us",
    icao_code: "KCIC",
    iata_country_code: "US",
    iata_code: "CIC",
    city_name: "Chico",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Chicoutimi/Saint-Honoré Aerodrome",
    longitude: -71.050974,
    latitude: 48.520706,
    id: "arp_yrc_ca",
    icao_code: "CYRC",
    iata_country_code: "CA",
    iata_code: "YRC",
    city_name: "Refuge Cove",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Chifeng Yulong Airport",
    longitude: 118.838928,
    latitude: 42.156063,
    id: "arp_cif_cn",
    icao_code: "ZBCF",
    iata_country_code: "CN",
    iata_code: "CIF",
    city_name: "Chifeng",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chignik Bay Airport",
    longitude: -158.40132,
    latitude: 56.295608,
    id: "arp_kbw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KBW",
    city_name: "Chignik",
    city: {
      name: "Chignik",
      id: "cit_kcl_us",
      iata_country_code: "US",
      iata_code: "KCL",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Chignik Fisheries Airport",
    longitude: -158.373611,
    latitude: 56.310832,
    id: "arp_kcg_us",
    icao_code: "PAJC",
    iata_country_code: "US",
    iata_code: "KCG",
    city_name: "Chignik",
    city: {
      name: "Chignik",
      id: "cit_kcl_us",
      iata_country_code: "US",
      iata_code: "KCL",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Chignik Lagoon Airport",
    longitude: -158.535949,
    latitude: 56.311175,
    id: "arp_kcl_us",
    icao_code: "PAJC",
    iata_country_code: "US",
    iata_code: "KCL",
    city_name: "Chignik",
    city: {
      name: "Chignik",
      id: "cit_kcl_us",
      iata_country_code: "US",
      iata_code: "KCL",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Chignik Lake Airport",
    longitude: -158.772529,
    latitude: 56.254971,
    id: "arp_kcq_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KCQ",
    city_name: "Chignik Lake",
    city: {
      name: "Chignik",
      id: "cit_kcl_us",
      iata_country_code: "US",
      iata_code: "KCL",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "Chigorodó Airport",
    longitude: -76.68687,
    latitude: 7.681285,
    id: "arp_igo_co",
    icao_code: "SKIG",
    iata_country_code: "CO",
    iata_code: "IGO",
    city_name: "Chigorodó",
    city: null,
  },
  {
    time_zone: "America/Chihuahua",
    name: "Chihuahua International Airport",
    longitude: -105.967045,
    latitude: 28.703462,
    id: "arp_cuu_mx",
    icao_code: "MMCU",
    iata_country_code: "MX",
    iata_code: "CUU",
    city_name: "Chihuahua",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Chilas Airport",
    longitude: 74.084754,
    latitude: 35.426997,
    id: "arp_chb_pk",
    icao_code: "OPCL",
    iata_country_code: "PK",
    iata_code: "CHB",
    city_name: "Chilas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Childress Municipal Airport",
    longitude: -100.283307,
    latitude: 34.43433,
    id: "arp_cds_us",
    icao_code: "KCDS",
    iata_country_code: "US",
    iata_code: "CDS",
    city_name: "Childress",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Chile Chico Airport",
    longitude: -71.687757,
    latitude: -46.582143,
    id: "arp_cch_cl",
    icao_code: "SCCC",
    iata_country_code: "CL",
    iata_code: "CCH",
    city_name: "Chile Chico",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Chileka International Airport",
    longitude: 34.97185,
    latitude: -15.679682,
    id: "arp_blz_mw",
    icao_code: "FWCL",
    iata_country_code: "MW",
    iata_code: "BLZ",
    city_name: "Blantyre",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Chilko Lake Airport",
    longitude: -124.14362,
    latitude: 51.626288,
    id: "arp_cjh_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "CJH",
    city_name: "Chilko Lake",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Chillagoe Airport",
    longitude: 144.529001,
    latitude: -17.141085,
    id: "arp_llg_au",
    icao_code: "YCGO",
    iata_country_code: "AU",
    iata_code: "LLG",
    city_name: "Chillagoe",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Chilliwack Airport",
    longitude: -121.941292,
    latitude: 49.153881,
    id: "arp_ycw_ca",
    icao_code: "CYCW",
    iata_country_code: "CA",
    iata_code: "YCW",
    city_name: "Chilliwack",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Chilonzuine Island Airport",
    longitude: 35.5204,
    latitude: -22.1827,
    id: "arp_idc_es",
    icao_code: null,
    iata_country_code: "ES",
    iata_code: "IDC",
    city_name: "Chilonzuine Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Chiloquin State Airport",
    longitude: -121.875482,
    latitude: 42.579367,
    id: "arp_chz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CHZ",
    city_name: "Chiloquin",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Chimbu Airport",
    longitude: 144.970821,
    latitude: -6.024155,
    id: "arp_cmu_pg",
    icao_code: "AYCH",
    iata_country_code: "PG",
    iata_code: "CMU",
    city_name: "Kundiawa",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Chi Mei Airport",
    longitude: 119.417934,
    latitude: 23.212422,
    id: "arp_cmj_tw",
    icao_code: "RCCM",
    iata_country_code: "TW",
    iata_code: "CMJ",
    city_name: "Qimei",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Chimoio Airport",
    longitude: 33.427513,
    latitude: -19.150345,
    id: "arp_vpy_mz",
    icao_code: "FQCH",
    iata_country_code: "MZ",
    iata_code: "VPY",
    city_name: "Chimoio",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Chimore Airport",
    longitude: -65.145568,
    latitude: -16.976834,
    id: "arp_cca_bo",
    icao_code: null,
    iata_country_code: "BO",
    iata_code: "CCA",
    city_name: "Chimore",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "China Bay Airport",
    longitude: 81.181729,
    latitude: 8.538682,
    id: "arp_trr_lk",
    icao_code: "VCCT",
    iata_country_code: "LK",
    iata_code: "TRR",
    city_name: "Trincomalee",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "China Strait Airstrip",
    longitude: 150.690651,
    latitude: -10.563569,
    id: "arp_sqt_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SQT",
    city_name: "Samarai Island",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Chinchilla Airport",
    longitude: 150.616382,
    latitude: -26.770573,
    id: "arp_ccl_au",
    icao_code: "YCCA",
    iata_country_code: "AU",
    iata_code: "CCL",
    city_name: "Chinchilla",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Chinde Airport",
    longitude: 36.4,
    latitude: -18.6167,
    id: "arp_ine_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "INE",
    city_name: "Chinde",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Chinggis Khaan International Airport",
    longitude: 106.768012,
    latitude: 47.842326,
    id: "arp_uln_mn",
    icao_code: "ZMUB",
    iata_country_code: "MN",
    iata_code: "ULN",
    city_name: "Ulaanbaatar",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Chingozi Airport",
    longitude: 33.639749,
    latitude: -16.106776,
    id: "arp_tet_mz",
    icao_code: "FQTT",
    iata_country_code: "MZ",
    iata_code: "TET",
    city_name: "Tete",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Chinguetti Airport",
    longitude: -12.397831,
    latitude: 20.505035,
    id: "arp_cgt_mr",
    icao_code: null,
    iata_country_code: "MR",
    iata_code: "CGT",
    city_name: "Chinguetti",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Chino Airport",
    longitude: -117.637242,
    latitude: 33.976468,
    id: "arp_cno_us",
    icao_code: "KCNO",
    iata_country_code: "US",
    iata_code: "CNO",
    city_name: "Chino",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Chios Island National Airport",
    longitude: 26.141569,
    latitude: 38.344297,
    id: "arp_jkh_gr",
    icao_code: "LGHI",
    iata_country_code: "GR",
    iata_code: "JKH",
    city_name: "Chios",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Chipata Airport",
    longitude: 32.587207,
    latitude: -13.557848,
    id: "arp_cip_zm",
    icao_code: "FLCP",
    iata_country_code: "ZM",
    iata_code: "CIP",
    city_name: "Chipata",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Chipinge Airport",
    longitude: 32.628552,
    latitude: -20.206603,
    id: "arp_chj_zw",
    icao_code: "FVCH",
    iata_country_code: "ZW",
    iata_code: "CHJ",
    city_name: "Chipinge",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Chippewa County International Airport",
    longitude: -84.473385,
    latitude: 46.254278,
    id: "arp_ciu_us",
    icao_code: "KCIU",
    iata_country_code: "US",
    iata_code: "CIU",
    city_name: "Sault Ste Marie",
    city: {
      name: "Sault Ste Marie",
      id: "cit_ssm_us",
      iata_country_code: "US",
      iata_code: "SSM",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Chippewa Valley Regional Airport",
    longitude: -91.48338,
    latitude: 44.865329,
    id: "arp_eau_us",
    icao_code: "KEAU",
    iata_country_code: "US",
    iata_code: "EAU",
    city_name: "Eau Claire",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Chiquimula Airport",
    longitude: -89.520973,
    latitude: 14.830921,
    id: "arp_ciq_gt",
    icao_code: null,
    iata_country_code: "GT",
    iata_code: "CIQ",
    city_name: "Chiquimula",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chisana Airport",
    longitude: -142.048822,
    latitude: 62.07141,
    id: "arp_czn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CZN",
    city_name: "Chisana",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Chisasibi Airport",
    longitude: -78.916813,
    latitude: 53.805639,
    id: "arp_yku_ca",
    icao_code: "CSU2",
    iata_country_code: "CA",
    iata_code: "YKU",
    city_name: "Chisasibi",
    city: null,
  },
  {
    time_zone: "Europe/Chisinau",
    name: "Chișinău International Airport",
    longitude: 28.933121,
    latitude: 46.931503,
    id: "arp_kiv_md",
    icao_code: "LUKK",
    iata_country_code: "MD",
    iata_code: "KIV",
    city_name: "Chisinau",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chistochina Airport",
    longitude: -145.452698,
    latitude: 62.602166,
    id: "arp_czo_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CZO",
    city_name: "Chistochina",
    city: null,
  },
  {
    time_zone: "Asia/Chita",
    name: "Chita-Kadala International Airport",
    longitude: 113.305937,
    latitude: 52.025197,
    id: "arp_hta_ru",
    icao_code: "UIAA",
    iata_country_code: "RU",
    iata_code: "HTA",
    city_name: "Chita",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Chitato Airport",
    longitude: 20.801984,
    latitude: -7.356753,
    id: "arp_pgi_ao",
    icao_code: "FNCH",
    iata_country_code: "AO",
    iata_code: "PGI",
    city_name: "Chitato",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chitina Airport",
    longitude: -144.428941,
    latitude: 61.583343,
    id: "arp_cxc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CXC",
    city_name: "Chitina",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Chitral Airport",
    longitude: 71.80016,
    latitude: 35.886355,
    id: "arp_cjl_pk",
    icao_code: "OPCH",
    iata_country_code: "PK",
    iata_code: "CJL",
    city_name: "Chitral",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Chitre Alonso Valderrama Airport",
    longitude: -80.410577,
    latitude: 7.983422,
    id: "arp_ctd_pa",
    icao_code: "MPCE",
    iata_country_code: "PA",
    iata_code: "CTD",
    city_name: "Chitré",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Chivolo Airport",
    longitude: -74.612708,
    latitude: 10.040467,
    id: "arp_ivo_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "IVO",
    city_name: "Chivolo",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Chkalovsky Airport",
    longitude: 38.058255,
    latitude: 55.880246,
    id: "arp_ckl_ru",
    icao_code: "UUMU",
    iata_country_code: "RU",
    iata_code: "CKL",
    city_name: "Chkalovsky",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Chlef International Airport",
    longitude: 1.329416,
    latitude: 36.214281,
    id: "arp_cfk_dz",
    icao_code: "DAOI",
    iata_country_code: "DZ",
    iata_code: "CFK",
    city_name: "Chlef",
    city: null,
  },
  {
    time_zone: "Asia/Choibalsan",
    name: "Choibalsan Airport",
    longitude: 114.645449,
    latitude: 48.135917,
    id: "arp_coq_mn",
    icao_code: "ZMCD",
    iata_country_code: "MN",
    iata_code: "COQ",
    city_name: "Choibalsan",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Choiseul Bay Airport",
    longitude: 156.395939,
    latitude: -6.710999,
    id: "arp_chy_sb",
    icao_code: "AGGC",
    iata_country_code: "SB",
    iata_code: "CHY",
    city_name: "Choiseul Bay",
    city: null,
  },
  {
    time_zone: "Asia/Srednekolymsk",
    name: "Chokurdakh Airport",
    longitude: 147.906717,
    latitude: 70.62279,
    id: "arp_ckh_ru",
    icao_code: "UESO",
    iata_country_code: "RU",
    iata_code: "CKH",
    city_name: "Chokurdah",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Chokwé Airport",
    longitude: 32.965484,
    latitude: -24.52098,
    id: "arp_tgs_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "TGS",
    city_name: "Chokwe",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Cholet Le Pontreau Airport",
    longitude: -0.877357,
    latitude: 47.081566,
    id: "arp_cet_fr",
    icao_code: "LFOU",
    iata_country_code: "FR",
    iata_code: "CET",
    city_name: "Cholet",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Chongqing Jiangbei International Airport",
    longitude: 106.641832,
    latitude: 29.719183,
    id: "arp_ckg_cn",
    icao_code: "ZUCK",
    iata_country_code: "CN",
    iata_code: "CKG",
    city_name: "Chongqing",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Chongqing Wushan Airport",
    longitude: 110.338915,
    latitude: 31.62,
    id: "arp_wsk_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "WSK",
    city_name: "Wushan",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Chos Malal Airport",
    longitude: -70.224464,
    latitude: -37.443241,
    id: "arp_hos_ar",
    icao_code: "SAHC",
    iata_country_code: "AR",
    iata_code: "HOS",
    city_name: "Chos Malal",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Christchurch International Airport",
    longitude: 172.534433,
    latitude: -43.487898,
    id: "arp_chc_nz",
    icao_code: "NZCH",
    iata_country_code: "NZ",
    iata_code: "CHC",
    city_name: "Christchurch",
    city: null,
  },
  {
    time_zone: "America/St_Thomas",
    name: "Christiansted Harbor Seaplane Base",
    longitude: -64.706644,
    latitude: 17.747019,
    id: "arp_ssb_vi",
    icao_code: null,
    iata_country_code: "VI",
    iata_code: "SSB",
    city_name: "St Croix",
    city: {
      name: "St Croix Island",
      id: "cit_stx_vi",
      iata_country_code: "VI",
      iata_code: "STX",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Christmas Creek Airport",
    longitude: 125.917,
    latitude: -18.883301,
    id: "arp_cxq_au",
    icao_code: "YCRK",
    iata_country_code: "AU",
    iata_code: "CXQ",
    city_name: "Christmas Creek Stn",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Christmas Creek Mine Airport",
    longitude: 119.651066,
    latitude: -22.35649,
    id: "arp_ckw_au",
    icao_code: "YCHK",
    iata_country_code: "AU",
    iata_code: "CKW",
    city_name: "Christmas Creek Mine",
    city: null,
  },
  {
    time_zone: "Indian/Christmas",
    name: "Christmas Island Airport",
    longitude: 105.689236,
    latitude: -10.450956,
    id: "arp_xch_cx",
    icao_code: "YPXM",
    iata_country_code: "CX",
    iata_code: "XCH",
    city_name: "Christmas Island",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Chuathbaluk Airport",
    longitude: -159.216003,
    latitude: 61.579102,
    id: "arp_chu_us",
    icao_code: "PACH",
    iata_country_code: "US",
    iata_code: "CHU",
    city_name: "Chuathbaluk",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Chub Cay International Airport",
    longitude: -77.884462,
    latitude: 25.417443,
    id: "arp_ccz_bs",
    icao_code: "MYBC",
    iata_country_code: "BS",
    iata_code: "CCZ",
    city_name: "Chub Cay",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Chubu Centrair International Airport",
    longitude: 136.809017,
    latitude: 34.85861,
    id: "arp_ngo_jp",
    icao_code: "RJGG",
    iata_country_code: "JP",
    iata_code: "NGO",
    city_name: "Nagoya",
    city: {
      name: "Nagoya",
      id: "cit_ngo_jp",
      iata_country_code: "JP",
      iata_code: "NGO",
    },
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Chu Lai International Airport",
    longitude: 108.705079,
    latitude: 15.407429,
    id: "arp_vcl_vn",
    icao_code: "VVCA",
    iata_country_code: "VN",
    iata_code: "VCL",
    city_name: "Chu Lai",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Chulman Neryungri Airport",
    longitude: 124.914001,
    latitude: 56.913897,
    id: "arp_ner_ru",
    icao_code: "UELL",
    iata_country_code: "RU",
    iata_code: "NER",
    city_name: "Neryungri",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Chumphon Airport",
    longitude: 99.359836,
    latitude: 10.712119,
    id: "arp_cjm_th",
    icao_code: "VTSE",
    iata_country_code: "TH",
    iata_code: "CJM",
    city_name: "Chumphon",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Chungribu Airport",
    longitude: 144.7,
    latitude: -4.8,
    id: "arp_cvb_pg",
    icao_code: "AYCB",
    iata_country_code: "PG",
    iata_code: "CVB",
    city_name: "Chungrebu",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Churchill Airport",
    longitude: -94.066883,
    latitude: 58.738849,
    id: "arp_yyq_ca",
    icao_code: "CYYQ",
    iata_country_code: "CA",
    iata_code: "YYQ",
    city_name: "Churchill",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Churchill Falls Airport",
    longitude: -64.101153,
    latitude: 53.560837,
    id: "arp_zum_ca",
    icao_code: "CZUM",
    iata_country_code: "CA",
    iata_code: "ZUM",
    city_name: "Churchill Falls",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Chute-Des-Passes/Lac Margane Airport",
    longitude: -71.138,
    latitude: 49.9434,
    id: "arp_ywq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YWQ",
    city_name: "Chute-des-Passes",
    city: null,
  },
  {
    time_zone: "Pacific/Chuuk",
    name: "Chuuk International Airport",
    longitude: 151.842533,
    latitude: 7.461003,
    id: "arp_tkk_fm",
    icao_code: "PTKK",
    iata_country_code: "FM",
    iata_code: "TKK",
    city_name: "Chuuk",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Ciampino-G. B. Pastine International Airport",
    longitude: 12.593361,
    latitude: 41.799344,
    id: "arp_cia_it",
    icao_code: "LIRA",
    iata_country_code: "IT",
    iata_code: "CIA",
    city_name: "Rome",
    city: {
      name: "Rome",
      id: "cit_rom_it",
      iata_country_code: "IT",
      iata_code: "ROM",
    },
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Cibao International Airport",
    longitude: -70.603302,
    latitude: 19.404093,
    id: "arp_sti_do",
    icao_code: "MDST",
    iata_country_code: "DO",
    iata_code: "STI",
    city_name: "Santiago",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Cicia Airport",
    longitude: -179.341951,
    latitude: -17.743221,
    id: "arp_ici_fj",
    icao_code: "NFCI",
    iata_country_code: "FJ",
    iata_code: "ICI",
    city_name: "Cicia",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Çiğli Airport",
    longitude: 27.010695,
    latitude: 38.512182,
    id: "arp_igl_tr",
    icao_code: "LTBL",
    iata_country_code: "TR",
    iata_code: "IGL",
    city_name: "Izmir",
    city: {
      name: "Izmir",
      id: "cit_izm_tr",
      iata_country_code: "TR",
      iata_code: "IZM",
    },
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Çıldır Airport",
    longitude: 27.893373,
    latitude: 37.816204,
    id: "arp_cii_tr",
    icao_code: "LTBD",
    iata_country_code: "TR",
    iata_code: "CII",
    city_name: "Aydın",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Cimitarra Airport",
    longitude: -73.972388,
    latitude: 6.370608,
    id: "arp_cim_co",
    icao_code: "SKCM",
    iata_country_code: "CO",
    iata_code: "CIM",
    city_name: "Cimitarra",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cincinnati Municipal Airport",
    longitude: -84.421367,
    latitude: 39.103433,
    id: "arp_luk_us",
    icao_code: "KLUK",
    iata_country_code: "US",
    iata_code: "LUK",
    city_name: "Cincinnati",
    city: {
      name: "Cincinnati",
      id: "cit_cvg_us",
      iata_country_code: "US",
      iata_code: "CVG",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Cincinnati/Northern Kentucky International Airport",
    longitude: -84.665409,
    latitude: 39.051038,
    id: "arp_cvg_us",
    icao_code: "KCVG",
    iata_country_code: "US",
    iata_code: "CVG",
    city_name: "Cincinnati",
    city: {
      name: "Cincinnati",
      id: "cit_cvg_us",
      iata_country_code: "US",
      iata_code: "CVG",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Circle City Airport",
    longitude: -144.075857,
    latitude: 65.830424,
    id: "arp_irc_us",
    icao_code: "PACR",
    iata_country_code: "US",
    iata_code: "IRC",
    city_name: "Circle",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Circle Hot Springs Airport",
    longitude: -144.61951,
    latitude: 65.487459,
    id: "arp_chp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CHP",
    city_name: "Circle Hot Springs",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "City of Derry Airport",
    longitude: -7.159783,
    latitude: 55.04198,
    id: "arp_ldy_gb",
    icao_code: "EGAE",
    iata_country_code: "GB",
    iata_code: "LDY",
    city_name: "Derry",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Ciudad Acuna International Airport",
    longitude: -101.09961,
    latitude: 29.333229,
    id: "arp_acn_mx",
    icao_code: "MMCC",
    iata_country_code: "MX",
    iata_code: "ACN",
    city_name: "Ciudad Acuña",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Ciudad Constitución Airport",
    longitude: -111.614997,
    latitude: 25.0538,
    id: "arp_cua_mx",
    icao_code: "MMDA",
    iata_country_code: "MX",
    iata_code: "CUA",
    city_name: "Ciudad Constitución",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Ciudad de Catamayo Airport",
    longitude: -79.371443,
    latitude: -3.996214,
    id: "arp_loh_ec",
    icao_code: "SETM",
    iata_country_code: "EC",
    iata_code: "LOH",
    city_name: "Catamayo",
    city: null,
  },
  {
    time_zone: "America/Merida",
    name: "Ciudad del Carmen International Airport",
    longitude: -91.800722,
    latitude: 18.652373,
    id: "arp_cme_mx",
    icao_code: "MMCE",
    iata_country_code: "MX",
    iata_code: "CME",
    city_name: "Ciudad del Carmen",
    city: null,
  },
  {
    time_zone: "America/Ojinaga",
    name: "Ciudad Juárez International Airport",
    longitude: -106.432386,
    latitude: 31.635913,
    id: "arp_cjs_mx",
    icao_code: "MMCS",
    iata_country_code: "MX",
    iata_code: "CJS",
    city_name: "Ciudad Juarez",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Ciudad Mante National Airport",
    longitude: -99.017372,
    latitude: 22.745814,
    id: "arp_mmc_mx",
    icao_code: "MMDM",
    iata_country_code: "MX",
    iata_code: "MMC",
    city_name: "Ciudad Mante",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "Ciudad Obregón International Airport",
    longitude: -109.833362,
    latitude: 27.394406,
    id: "arp_cen_mx",
    icao_code: "MMCN",
    iata_country_code: "MX",
    iata_code: "CEN",
    city_name: "Ciudad Obregón",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Ciudad Real Central Airport",
    longitude: -3.9681,
    latitude: 38.855463,
    id: "arp_cqm_es",
    icao_code: "LERL",
    iata_country_code: "ES",
    iata_code: "CQM",
    city_name: "Ciudad Real",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Ciudad Victoria International Airport",
    longitude: -98.95748,
    latitude: 23.706312,
    id: "arp_cvm_mx",
    icao_code: "MMCV",
    iata_country_code: "MX",
    iata_code: "CVM",
    city_name: "Ciudad Victoria",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Claremont Municipal Airport",
    longitude: -72.368233,
    latitude: 43.370735,
    id: "arp_cnh_us",
    icao_code: "KCNH",
    iata_country_code: "US",
    iata_code: "CNH",
    city_name: "Claremont",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Clarence A. Bain Airport",
    longitude: -77.684651,
    latitude: 24.287526,
    id: "arp_may_bs",
    icao_code: "MYAB",
    iata_country_code: "BS",
    iata_code: "MAY",
    city_name: "Mangrove Cay",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Clark International Airport",
    longitude: 120.556649,
    latitude: 15.186192,
    id: "arp_crk_ph",
    icao_code: "RPLC",
    iata_country_code: "PH",
    iata_code: "CRK",
    city_name: "Mabalacat",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Clarks Point Airport",
    longitude: -158.527376,
    latitude: 58.835419,
    id: "arp_clp_us",
    icao_code: "PFCL",
    iata_country_code: "US",
    iata_code: "CLP",
    city_name: "Clarks Point",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Clarksville-Montgomery County Regional Airport",
    longitude: -87.41702,
    latitude: 36.621836,
    id: "arp_ckv_us",
    icao_code: "KCKV",
    iata_country_code: "US",
    iata_code: "CKV",
    city_name: "Clarksville",
    city: null,
  },
  {
    time_zone: "America/Anguilla",
    name: "Clayton J. Lloyd International Airport",
    longitude: -63.054282,
    latitude: 18.205302,
    id: "arp_axa_ai",
    icao_code: "TQPF",
    iata_country_code: "AI",
    iata_code: "AXA",
    city_name: "The Valley",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Clayton Municipal Airpark",
    longitude: -103.156815,
    latitude: 36.446264,
    id: "arp_cao_us",
    icao_code: "KCAO",
    iata_country_code: "US",
    iata_code: "CAO",
    city_name: "Clayton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Clearwater Air Park",
    longitude: -82.758669,
    latitude: 27.976596,
    id: "arp_clw_us",
    icao_code: "KCLW",
    iata_country_code: "US",
    iata_code: "CLW",
    city_name: "Clearwater",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Clermont Airport",
    longitude: 147.621763,
    latitude: -22.774248,
    id: "arp_cmq_au",
    icao_code: "YCMT",
    iata_country_code: "AU",
    iata_code: "CMQ",
    city_name: "Clermont",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Clermont-Ferrand Auvergne Airport",
    longitude: 3.165606,
    latitude: 45.787676,
    id: "arp_cfe_fr",
    icao_code: "LFLC",
    iata_country_code: "FR",
    iata_code: "CFE",
    city_name: "Clermont-Ferrand",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Cleve Airport",
    longitude: 136.504345,
    latitude: -33.709339,
    id: "arp_cvc_au",
    icao_code: "YCEE",
    iata_country_code: "AU",
    iata_code: "CVC",
    city_name: "Cleve",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cleveland Burke Lakefront Airport",
    longitude: -81.686615,
    latitude: 41.514519,
    id: "arp_bkl_us",
    icao_code: "KBKL",
    iata_country_code: "US",
    iata_code: "BKL",
    city_name: "Cleveland",
    city: {
      name: "Cleveland",
      id: "cit_cle_us",
      iata_country_code: "US",
      iata_code: "CLE",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Cleveland Hopkins International Airport",
    longitude: -81.850364,
    latitude: 41.410408,
    id: "arp_cle_us",
    icao_code: "KCLE",
    iata_country_code: "US",
    iata_code: "CLE",
    city_name: "Cleveland",
    city: {
      name: "Cleveland",
      id: "cit_cle_us",
      iata_country_code: "US",
      iata_code: "CLE",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Cliff Hatfield Memorial Airport",
    longitude: -115.521335,
    latitude: 33.13075,
    id: "arp_clr_us",
    icao_code: "KCLR",
    iata_country_code: "US",
    iata_code: "CLR",
    city_name: "Calipatria",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Clifton Hills Airport",
    longitude: 138.893333,
    latitude: -27.017961,
    id: "arp_cfh_au",
    icao_code: "YCFH",
    iata_country_code: "AU",
    iata_code: "CFH",
    city_name: "Clifton Hills",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Clinton Creek Airport",
    longitude: -139.124682,
    latitude: 64.04421,
    id: "arp_ylm_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YLM",
    city_name: "Clinton Creek",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Clinton Municipal Airport",
    longitude: -90.329361,
    latitude: 41.82946,
    id: "arp_cwi_us",
    icao_code: "KCWI",
    iata_country_code: "US",
    iata_code: "CWI",
    city_name: "Clinton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Clinton Regional Airport",
    longitude: -98.932739,
    latitude: 35.538317,
    id: "arp_clk_us",
    icao_code: "KCLK",
    iata_country_code: "US",
    iata_code: "CLK",
    city_name: "Clinton",
    city: {
      name: "Clinton",
      id: "cit_csm_us",
      iata_country_code: "US",
      iata_code: "CSM",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Clinton Sherman Airport",
    longitude: -99.198301,
    latitude: 35.347016,
    id: "arp_csm_us",
    icao_code: "KCSM",
    iata_country_code: "US",
    iata_code: "CSM",
    city_name: "Clinton",
    city: {
      name: "Clinton",
      id: "cit_csm_us",
      iata_country_code: "US",
      iata_code: "CSM",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Clintonville Municipal Airport",
    longitude: -88.73162,
    latitude: 44.614983,
    id: "arp_cli_us",
    icao_code: "KCLI",
    iata_country_code: "US",
    iata_code: "CLI",
    city_name: "Clintonville",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cloncurry Airport",
    longitude: 140.506253,
    latitude: -20.668752,
    id: "arp_cnj_au",
    icao_code: "YCCY",
    iata_country_code: "AU",
    iata_code: "CNJ",
    city_name: "Cloncurry",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Clorinda Airport",
    longitude: -57.734484,
    latitude: -25.303489,
    id: "arp_clx_ar",
    icao_code: "SATC",
    iata_country_code: "AR",
    iata_code: "CLX",
    city_name: "Clorinda",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Clovis Municipal Airport",
    longitude: -103.078707,
    latitude: 34.425868,
    id: "arp_cvn_us",
    icao_code: "KCVN",
    iata_country_code: "US",
    iata_code: "CVN",
    city_name: "Clovis",
    city: {
      name: "Clovis",
      id: "cit_cvn_us",
      iata_country_code: "US",
      iata_code: "CVN",
    },
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Club Makokola Airport",
    longitude: 35.130892,
    latitude: -14.306642,
    id: "arp_cmk_mw",
    icao_code: "FWCM",
    iata_country_code: "MW",
    iata_code: "CMK",
    city_name: "Club Makokola",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Cluff Lake Airport",
    longitude: -109.515908,
    latitude: 58.391529,
    id: "arp_xcl_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "XCL",
    city_name: "Cluff Lake",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cluny Airport",
    longitude: 139.617002,
    latitude: -24.514277,
    id: "arp_czy_au",
    icao_code: "YUNY",
    iata_country_code: "AU",
    iata_code: "CZY",
    city_name: "Cluny",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Clyde River Airport",
    longitude: -68.514795,
    latitude: 70.485475,
    id: "arp_ycy_ca",
    icao_code: "CYCY",
    iata_country_code: "CA",
    iata_code: "YCY",
    city_name: "Clyde River",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Coari Airport",
    longitude: -63.132598,
    latitude: -4.134059,
    id: "arp_ciz_br",
    icao_code: "SWKO",
    iata_country_code: "BR",
    iata_code: "CIZ",
    city_name: "Coari",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Coastal Carolina Regional Airport",
    longitude: -77.041698,
    latitude: 35.074495,
    id: "arp_ewn_us",
    icao_code: "KEWN",
    iata_country_code: "US",
    iata_code: "EWN",
    city_name: "New Bern",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Coatepeque Airport",
    longitude: -91.881743,
    latitude: 14.694486,
    id: "arp_ctf_gt",
    icao_code: "MGCT",
    iata_country_code: "GT",
    iata_code: "CTF",
    city_name: "Coatepeque",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Coban Airport",
    longitude: -90.406835,
    latitude: 15.468951,
    id: "arp_cbv_gt",
    icao_code: "MGCB",
    iata_country_code: "GT",
    iata_code: "CBV",
    city_name: "Coban",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Cobar Airport",
    longitude: 145.79369,
    latitude: -31.536623,
    id: "arp_caz_au",
    icao_code: "YCBA",
    iata_country_code: "AU",
    iata_code: "CAZ",
    city_name: "Cobar",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Cobija Capitan Anibal Arab Airport",
    longitude: -68.782023,
    latitude: -11.040594,
    id: "arp_cij_bo",
    icao_code: "SLCO",
    iata_country_code: "BO",
    iata_code: "CIJ",
    city_name: "Cobija",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Cochin International Airport",
    longitude: 76.397429,
    latitude: 10.151891,
    id: "arp_cok_in",
    icao_code: "VOCI",
    iata_country_code: "IN",
    iata_code: "COK",
    city_name: "Kochi",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Cochise County Airport",
    longitude: -109.894305,
    latitude: 32.244626,
    id: "arp_cwx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CWX",
    city_name: "Willcox",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Cochrane Airport",
    longitude: -72.588411,
    latitude: -47.243831,
    id: "arp_lgr_cl",
    icao_code: "SCHR",
    iata_country_code: "CL",
    iata_code: "LGR",
    city_name: "Cochrane",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Cochrane Airport",
    longitude: -81.013611,
    latitude: 49.105574,
    id: "arp_ycn_ca",
    icao_code: "CYCN",
    iata_country_code: "CA",
    iata_code: "YCN",
    city_name: "Cochrane",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Coconut Island Airport",
    longitude: 143.068434,
    latitude: -10.049749,
    id: "arp_cnc_au",
    icao_code: "YCCT",
    iata_country_code: "AU",
    iata_code: "CNC",
    city_name: "Coconut Island",
    city: null,
  },
  {
    time_zone: "Indian/Cocos",
    name: "Cocos Islands Airport",
    longitude: 96.833495,
    latitude: -12.18963,
    id: "arp_cck_cc",
    icao_code: "YPCC",
    iata_country_code: "CC",
    iata_code: "CCK",
    city_name: "Cocos Keeling Islands",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Codazzi Airport",
    longitude: -73.451738,
    latitude: 9.582936,
    id: "arp_dzi_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "DZI",
    city_name: "Agustin Codazzi",
    city: null,
  },
  {
    time_zone: "America/Antigua",
    name: "Codrington Airport",
    longitude: -61.827709,
    latitude: 17.636069,
    id: "arp_bbq_ag",
    icao_code: "TAPH",
    iata_country_code: "AG",
    iata_code: "BBQ",
    city_name: "Codrington",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Coen Airport",
    longitude: 143.116073,
    latitude: -13.76334,
    id: "arp_cuq_au",
    icao_code: "YCOE",
    iata_country_code: "AU",
    iata_code: "CUQ",
    city_name: "Coen",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Coeur d'Alene Airport",
    longitude: -116.819849,
    latitude: 47.774308,
    id: "arp_coe_us",
    icao_code: "KCOE",
    iata_country_code: "US",
    iata_code: "COE",
    city_name: "Coeur d'Alene",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Coffeyville Municipal Airport",
    longitude: -95.571772,
    latitude: 37.094066,
    id: "arp_cfv_us",
    icao_code: "KCFV",
    iata_country_code: "US",
    iata_code: "CFV",
    city_name: "Coffeyville",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Coffman Cove Airport",
    longitude: 102.786046,
    latitude: 16.465035,
    id: "arp_kcc_us",
    icao_code: "VTUK",
    iata_country_code: "US",
    iata_code: "KCC",
    city_name: "Coffman Cove",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Coffs Harbour Airport",
    longitude: 153.115831,
    latitude: -30.321762,
    id: "arp_cfs_au",
    icao_code: "YCFS",
    iata_country_code: "AU",
    iata_code: "CFS",
    city_name: "Coffs Harbour",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Cognac – Châteaubernard Air Base Airport",
    longitude: -0.316204,
    latitude: 45.658124,
    id: "arp_cng_fr",
    icao_code: "LFBG",
    iata_country_code: "FR",
    iata_code: "CNG",
    city_name: "Cognac",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Coimbatore International Airport",
    longitude: 77.041637,
    latitude: 11.03029,
    id: "arp_cjb_in",
    icao_code: "VOCB",
    iata_country_code: "IN",
    iata_code: "CJB",
    city_name: "Coimbatore",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Coimbra Airport",
    longitude: -8.468962,
    latitude: 40.156918,
    id: "arp_cbp_pt",
    icao_code: "LPCO",
    iata_country_code: "PT",
    iata_code: "CBP",
    city_name: "Coimbra",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Colac Airport",
    longitude: 143.678806,
    latitude: -38.286415,
    id: "arp_xco_au",
    icao_code: "YOLA",
    iata_country_code: "AU",
    iata_code: "XCO",
    city_name: "Colac",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Colby Municipal Airport",
    longitude: -101.046833,
    latitude: 39.427502,
    id: "arp_cbk_us",
    icao_code: "KCBK",
    iata_country_code: "US",
    iata_code: "CBK",
    city_name: "Colby",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Cold Bay Airport",
    longitude: -162.723539,
    latitude: 55.204603,
    id: "arp_cdb_us",
    icao_code: "PACD",
    iata_country_code: "US",
    iata_code: "CDB",
    city_name: "Cold Bay",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Coldfoot Airport",
    longitude: -150.200618,
    latitude: 67.253214,
    id: "arp_cxf_us",
    icao_code: "PACX",
    iata_country_code: "US",
    iata_code: "CXF",
    city_name: "Coldfoot",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Coleman A. Young Municipal Airport",
    longitude: -83.006754,
    latitude: 42.409095,
    id: "arp_det_us",
    icao_code: "KDET",
    iata_country_code: "US",
    iata_code: "DET",
    city_name: "Detroit",
    city: {
      name: "Detroit",
      id: "cit_dtt_us",
      iata_country_code: "US",
      iata_code: "DTT",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Coleman Municipal Airport",
    longitude: -99.405897,
    latitude: 31.843051,
    id: "arp_com_us",
    icao_code: "KCOM",
    iata_country_code: "US",
    iata_code: "COM",
    city_name: "Coleman",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Coles County Memorial Airport",
    longitude: -88.279653,
    latitude: 39.478275,
    id: "arp_mto_us",
    icao_code: "KMTO",
    iata_country_code: "US",
    iata_code: "MTO",
    city_name: "Mattoon/Charleston",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Colima Airport",
    longitude: -103.577002,
    latitude: 19.277,
    id: "arp_clq_mx",
    icao_code: "MMIA",
    iata_country_code: "MX",
    iata_code: "CLQ",
    city_name: "Colima",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Coll Airport",
    longitude: -6.617716,
    latitude: 56.602037,
    id: "arp_col_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "COL",
    city_name: "Coll Island",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Collarenebri Airport",
    longitude: 148.582,
    latitude: -29.521699,
    id: "arp_crb_au",
    icao_code: "YCBR",
    iata_country_code: "AU",
    iata_code: "CRB",
    city_name: "Collarenebri",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "College Park Airport",
    longitude: -76.922646,
    latitude: 38.980352,
    id: "arp_cgs_us",
    icao_code: "KCGS",
    iata_country_code: "US",
    iata_code: "CGS",
    city_name: "College Park",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Collie Airport",
    longitude: 116.194184,
    latitude: -33.358892,
    id: "arp_cie_au",
    icao_code: "YCOI",
    iata_country_code: "AU",
    iata_code: "CIE",
    city_name: "Collie",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Collins Bay Airport",
    longitude: -103.682652,
    latitude: 58.230856,
    id: "arp_ykc_ca",
    icao_code: "CYKC",
    iata_country_code: "CA",
    iata_code: "YKC",
    city_name: "Collins Bay",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Collinsville Airport",
    longitude: 147.854766,
    latitude: -20.598598,
    id: "arp_kce_au",
    icao_code: "YCSV",
    iata_country_code: "AU",
    iata_code: "KCE",
    city_name: "Collinsville",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Colmar-Houssen Airport",
    longitude: 7.361096,
    latitude: 48.109955,
    id: "arp_cmr_fr",
    icao_code: "LFGA",
    iata_country_code: "FR",
    iata_code: "CMR",
    city_name: "Colmar",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Cologne Bonn Airport",
    longitude: 7.141736,
    latitude: 50.868307,
    id: "arp_cgn_de",
    icao_code: "EDDK",
    iata_country_code: "DE",
    iata_code: "CGN",
    city_name: "Cologne",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Colonel Carlos Concha Torres Airport",
    longitude: -79.625704,
    latitude: 0.976251,
    id: "arp_esm_ec",
    icao_code: "SETN",
    iata_country_code: "EC",
    iata_code: "ESM",
    city_name: "Esmeraldas",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Colonel Hill Airport",
    longitude: -74.180671,
    latitude: 22.745686,
    id: "arp_cri_bs",
    icao_code: "MYCI",
    iata_country_code: "BS",
    iata_code: "CRI",
    city_name: "Colonel Hill",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Colonia Catriel Airport",
    longitude: -67.831754,
    latitude: -37.908779,
    id: "arp_cct_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "CCT",
    city_name: "Catriel",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "Colonia Sarmiento Airport",
    longitude: -68.999612,
    latitude: -45.58266,
    id: "arp_oln_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "OLN",
    city_name: "Sarmiento",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Colonsay Airport",
    longitude: -6.243059,
    latitude: 56.057499,
    id: "arp_csa_gb",
    icao_code: "EGEY",
    iata_country_code: "GB",
    iata_code: "CSA",
    city_name: "Colonsay",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Colorado Creek Airport",
    longitude: -156.003,
    latitude: 63.5907,
    id: "arp_kcr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KCR",
    city_name: "Colorado Creek",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Colorado Plains Regional Airport",
    longitude: -103.22208,
    latitude: 40.175473,
    id: "arp_ako_us",
    icao_code: "KAKO",
    iata_country_code: "US",
    iata_code: "AKO",
    city_name: "Akron",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Colorado Springs Airport",
    longitude: -104.701799,
    latitude: 38.803756,
    id: "arp_cos_us",
    icao_code: "KCOS",
    iata_country_code: "US",
    iata_code: "COS",
    city_name: "Colorado Springs",
    city: {
      name: "Colorado Springs",
      id: "cit_cos_us",
      iata_country_code: "US",
      iata_code: "COS",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Columbia Airport",
    longitude: -120.413631,
    latitude: 38.032313,
    id: "arp_coa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "COA",
    city_name: "Columbia",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Columbia County Airport",
    longitude: -73.710289,
    latitude: 42.291345,
    id: "arp_hcc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HCC",
    city_name: "Hudson",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Columbia Gorge Regional Airport",
    longitude: -121.170029,
    latitude: 45.618313,
    id: "arp_dls_us",
    icao_code: "KDLS",
    iata_country_code: "US",
    iata_code: "DLS",
    city_name: "The Dalles",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Columbia Metropolitan Airport",
    longitude: -81.12075,
    latitude: 33.940358,
    id: "arp_cae_us",
    icao_code: "KCAE",
    iata_country_code: "US",
    iata_code: "CAE",
    city_name: "Columbia",
    city: {
      name: "Columbia",
      id: "cit_cae_us",
      iata_country_code: "US",
      iata_code: "CAE",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Columbia Regional Airport",
    longitude: -92.21955,
    latitude: 38.817454,
    id: "arp_cou_us",
    icao_code: "KCOU",
    iata_country_code: "US",
    iata_code: "COU",
    city_name: "Columbia",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Columbus Air Force Base",
    longitude: -88.445894,
    latitude: 33.643024,
    id: "arp_cbm_us",
    icao_code: "KCBM",
    iata_country_code: "US",
    iata_code: "CBM",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_ubs_us",
      iata_country_code: "US",
      iata_code: "UBS",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Columbus Airport",
    longitude: -84.939979,
    latitude: 32.515331,
    id: "arp_csg_us",
    icao_code: "KCSG",
    iata_country_code: "US",
    iata_code: "CSG",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_csg_us",
      iata_country_code: "US",
      iata_code: "CSG",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Columbus-Lowndes County Airport",
    longitude: -88.381321,
    latitude: 33.465032,
    id: "arp_ubs_us",
    icao_code: "KUBS",
    iata_country_code: "US",
    iata_code: "UBS",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_ubs_us",
      iata_country_code: "US",
      iata_code: "UBS",
    },
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Columbus Municipal Airport",
    longitude: -85.896716,
    latitude: 39.258782,
    id: "arp_clu_us",
    icao_code: "KBAK",
    iata_country_code: "US",
    iata_code: "CLU",
    city_name: "Columbus",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Columbus Municipal Airport",
    longitude: -85.897078,
    latitude: 39.256403,
    id: "arp_cus_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CUS",
    city_name: "Columbus",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Columbus Municipal Airport",
    longitude: -85.896402,
    latitude: 39.256013,
    id: "arp_olu_us",
    icao_code: "KOLU",
    iata_country_code: "US",
    iata_code: "OLU",
    city_name: "Columbus",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Colville Lake Airport",
    longitude: -126.083045,
    latitude: 67.039148,
    id: "arp_yck_ca",
    icao_code: "CYVL",
    iata_country_code: "CA",
    iata_code: "YCK",
    city_name: "Colville Lake",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "Comandante Espora Airport",
    longitude: -62.1693,
    latitude: -38.725,
    id: "arp_bhi_ar",
    icao_code: "SAZB",
    iata_country_code: "AR",
    iata_code: "BHI",
    city_name: "Bahia Blanca",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Comandante FAP Germán Arias Graziani Airport",
    longitude: -77.59866,
    latitude: -9.347499,
    id: "arp_ata_pe",
    icao_code: "SPHZ",
    iata_country_code: "PE",
    iata_code: "ATA",
    city_name: "Huaraz",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Comandante Gustavo Kraemer Airport",
    longitude: -54.112207,
    latitude: -31.38978,
    id: "arp_bgx_br",
    icao_code: "SBBG",
    iata_country_code: "BR",
    iata_code: "BGX",
    city_name: "Bagé",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Combolcha Airport",
    longitude: 39.712386,
    latitude: 11.082747,
    id: "arp_dse_et",
    icao_code: "HADC",
    iata_country_code: "ET",
    iata_code: "DSE",
    city_name: "Dessie",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Comilla Airport",
    longitude: 91.189562,
    latitude: 23.437399,
    id: "arp_cla_bd",
    icao_code: "VGCM",
    iata_country_code: "BD",
    iata_code: "CLA",
    city_name: "Comilla",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Comiso Airport",
    longitude: 14.607169,
    latitude: 36.996246,
    id: "arp_ciy_it",
    icao_code: "LICB",
    iata_country_code: "IT",
    iata_code: "CIY",
    city_name: "Comiso",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Comodoro Arturo Merino Benítez International Airport",
    longitude: -70.789912,
    latitude: -33.393196,
    id: "arp_scl_cl",
    icao_code: "SCEL",
    iata_country_code: "CL",
    iata_code: "SCL",
    city_name: "Santiago",
    city: null,
  },
  {
    time_zone: "America/Mendoza",
    name: "Comodoro D.R. Salomón Airport",
    longitude: -69.572875,
    latitude: -35.492551,
    id: "arp_lgs_ar",
    icao_code: "SAMM",
    iata_country_code: "AR",
    iata_code: "LGS",
    city_name: "Malargue",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Comodoro Pedro Zanni Airport",
    longitude: -61.857721,
    latitude: -35.844375,
    id: "arp_peh_ar",
    icao_code: "SAZP",
    iata_country_code: "AR",
    iata_code: "PEH",
    city_name: "Pehuajo",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Comodoro Pierrestegui Airport",
    longitude: -57.998482,
    latitude: -31.298836,
    id: "arp_coc_ar",
    icao_code: "SAAC",
    iata_country_code: "AR",
    iata_code: "COC",
    city_name: "Concordia",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "Comodoro Rivadavia Airport",
    longitude: -67.466318,
    latitude: -45.786802,
    id: "arp_crd_ar",
    icao_code: "SAVC",
    iata_country_code: "AR",
    iata_code: "CRD",
    city_name: "Comodoro Rivadavia",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Comox Valley Airport",
    longitude: -124.90337,
    latitude: 49.707941,
    id: "arp_yqq_ca",
    icao_code: "CYQQ",
    iata_country_code: "CA",
    iata_code: "YQQ",
    city_name: "Comox",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Compton/Woodley Airport",
    longitude: -118.243898,
    latitude: 33.889902,
    id: "arp_cpm_us",
    icao_code: "KCPM",
    iata_country_code: "US",
    iata_code: "CPM",
    city_name: "Compton",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Conakry International Airport",
    longitude: -13.614684,
    latitude: 9.576326,
    id: "arp_cky_gn",
    icao_code: "GUCY",
    iata_country_code: "GN",
    iata_code: "CKY",
    city_name: "Conakry",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Conceição do Araguaia Airport",
    longitude: -49.301498,
    latitude: -8.34835,
    id: "arp_cdj_br",
    icao_code: "SBAA",
    iata_country_code: "BR",
    iata_code: "CDJ",
    city_name: "Conceicao do Araguaia",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Concepción Airport",
    longitude: -73.062901,
    latitude: -36.773081,
    id: "arp_ccp_cl",
    icao_code: "SCIE",
    iata_country_code: "CL",
    iata_code: "CCP",
    city_name: "Concepcion",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Concepción Airport",
    longitude: -62.027503,
    latitude: -16.140382,
    id: "arp_cep_bo",
    icao_code: "SLCP",
    iata_country_code: "BO",
    iata_code: "CEP",
    city_name: "Concepción",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Concórdia Airport",
    longitude: -52.052347,
    latitude: -27.180646,
    id: "arp_cci_br",
    icao_code: "SSCK",
    iata_country_code: "BR",
    iata_code: "CCI",
    city_name: "Concórdia",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Concord Municipal Airport",
    longitude: -71.505779,
    latitude: 43.204196,
    id: "arp_con_us",
    icao_code: "KCON",
    iata_country_code: "US",
    iata_code: "CON",
    city_name: "Concord",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Concord Regional Airport",
    longitude: -80.709099,
    latitude: 35.387798,
    id: "arp_usa_us",
    icao_code: "KJQF",
    iata_country_code: "US",
    iata_code: "USA",
    city_name: "Concord",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Con Dao Airport",
    longitude: 106.630904,
    latitude: 8.731871,
    id: "arp_vcs_vn",
    icao_code: "VVCS",
    iata_country_code: "VN",
    iata_code: "VCS",
    city_name: "Côn Đảo",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Condobolin Airport",
    longitude: 147.211065,
    latitude: -33.067304,
    id: "arp_cbx_au",
    icao_code: "YCDO",
    iata_country_code: "AU",
    iata_code: "CBX",
    city_name: "Condobolin",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Condron Army Airfield",
    longitude: -106.403391,
    latitude: 32.34087,
    id: "arp_wsd_us",
    icao_code: "KWSD",
    iata_country_code: "US",
    iata_code: "WSD",
    city_name: "White Sands",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Confresa Airport",
    longitude: -51.563646,
    latitude: -10.634486,
    id: "arp_cfo_br",
    icao_code: "SJHG",
    iata_country_code: "BR",
    iata_code: "CFO",
    city_name: "Confresa",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Connemara Regional Airport",
    longitude: -9.468641,
    latitude: 53.23127,
    id: "arp_nnr_ie",
    icao_code: "EICA",
    iata_country_code: "IE",
    iata_code: "NNR",
    city_name: "Spiddal",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Conroe-North Houston Regional Airport",
    longitude: -95.414377,
    latitude: 30.351414,
    id: "arp_cxo_us",
    icao_code: "KCXO",
    iata_country_code: "US",
    iata_code: "CXO",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Constanza Dom Re Airport",
    longitude: -70.720672,
    latitude: 18.907639,
    id: "arp_coz_do",
    icao_code: "MDCZ",
    iata_country_code: "DO",
    iata_code: "COZ",
    city_name: "Constanza",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Contadora Airport",
    longitude: -79.034505,
    latitude: 8.628296,
    id: "arp_otd_pa",
    icao_code: "MPRA",
    iata_country_code: "PA",
    iata_code: "OTD",
    city_name: "Contadora Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Converse County Airport",
    longitude: -105.38534,
    latitude: 42.796374,
    id: "arp_dgw_us",
    icao_code: "KDGW",
    iata_country_code: "US",
    iata_code: "DGW",
    city_name: "Douglas",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Coober Pedy Airport",
    longitude: 134.721808,
    latitude: -29.03838,
    id: "arp_cpd_au",
    icao_code: "YCBP",
    iata_country_code: "AU",
    iata_code: "CPD",
    city_name: "Coober Pedy",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Cooch Behar Airport",
    longitude: 89.468293,
    latitude: 26.330332,
    id: "arp_coh_in",
    icao_code: "VECO",
    iata_country_code: "IN",
    iata_code: "COH",
    city_name: "Cooch Behar",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Cooinda Airport",
    longitude: 132.532802,
    latitude: -12.903304,
    id: "arp_cda_au",
    icao_code: "YCOO",
    iata_country_code: "AU",
    iata_code: "CDA",
    city_name: "Cooinda",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cooktown Airport",
    longitude: 145.183972,
    latitude: -15.444435,
    id: "arp_ctn_au",
    icao_code: "YCKN",
    iata_country_code: "AU",
    iata_code: "CTN",
    city_name: "Cooktown",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Coolah Airport",
    longitude: 149.610527,
    latitude: -31.774144,
    id: "arp_clh_au",
    icao_code: "YCAH",
    iata_country_code: "AU",
    iata_code: "CLH",
    city_name: "Coolah",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Coolawanyah Station Airport",
    longitude: 117.800003,
    latitude: -21.7833,
    id: "arp_coy_au",
    icao_code: "YCWY",
    iata_country_code: "AU",
    iata_code: "COY",
    city_name: "Coolawanyah Station",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Coolibah Airport",
    longitude: 130.96093,
    latitude: -15.549544,
    id: "arp_cob_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "COB",
    city_name: "Coolibah",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Cooma Snowy Mountains Airport",
    longitude: 148.972826,
    latitude: -36.29859,
    id: "arp_oom_au",
    icao_code: "YCOM",
    iata_country_code: "AU",
    iata_code: "OOM",
    city_name: "Cooma",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Coonabarabran Airport",
    longitude: 149.268888,
    latitude: -31.332392,
    id: "arp_coj_au",
    icao_code: "YCBB",
    iata_country_code: "AU",
    iata_code: "COJ",
    city_name: "Coonabarabran",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Coonamble Airport",
    longitude: 148.377168,
    latitude: -30.982066,
    id: "arp_cnb_au",
    icao_code: "YCNM",
    iata_country_code: "AU",
    iata_code: "CNB",
    city_name: "Coonamble",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Coondewanna Airport",
    longitude: 118.812851,
    latitude: -22.966365,
    id: "arp_cjf_au",
    icao_code: "YCWA",
    iata_country_code: "AU",
    iata_code: "CJF",
    city_name: "Coondewanna",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cooperstown-Westville Airport",
    longitude: -74.891314,
    latitude: 42.62877,
    id: "arp_cop_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "COP",
    city_name: "Cooperstown",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Coorabie Airport",
    longitude: 132.296278,
    latitude: -31.894445,
    id: "arp_crj_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "CRJ",
    city_name: "Coorabie",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Cootamundra Airport",
    longitude: 148.031315,
    latitude: -34.626307,
    id: "arp_cmd_au",
    icao_code: "YCTM",
    iata_country_code: "AU",
    iata_code: "CMD",
    city_name: "Cootamundra",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Copalar Airport",
    longitude: -92.051086,
    latitude: 16.176711,
    id: "arp_cjt_mx",
    icao_code: "MMCO",
    iata_country_code: "MX",
    iata_code: "CJT",
    city_name: "Comitán",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Copán Ruinas Airport",
    longitude: -89.007184,
    latitude: 14.914855,
    id: "arp_ruy_hn",
    icao_code: "MHRU",
    iata_country_code: "HN",
    iata_code: "RUY",
    city_name: "Copán Ruinas",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Copenhagen Airport",
    longitude: 12.653382,
    latitude: 55.617962,
    id: "arp_cph_dk",
    icao_code: "EKCH",
    iata_country_code: "DK",
    iata_code: "CPH",
    city_name: "Copenhagen",
    city: {
      name: "Copenhagen",
      id: "cit_cph_dk",
      iata_country_code: "DK",
      iata_code: "CPH",
    },
  },
  {
    time_zone: "America/Santiago",
    name: "Copiapo Desierto de Atacama Airport",
    longitude: -70.777484,
    latitude: -27.262242,
    id: "arp_cpo_cl",
    icao_code: "SCAT",
    iata_country_code: "CL",
    iata_code: "CPO",
    city_name: "Copiapo",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Coposa Airport",
    longitude: -68.682265,
    latitude: -20.752138,
    id: "arp_cpp_cl",
    icao_code: null,
    iata_country_code: "CL",
    iata_code: "CPP",
    city_name: "Pica",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Copper Center 2 Airport",
    longitude: -145.298496,
    latitude: 61.94507,
    id: "arp_czc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CZC",
    city_name: "Copper Center",
    city: null,
  },
  {
    time_zone: "America/Atikokan",
    name: "Coral Harbour Airport",
    longitude: -83.359582,
    latitude: 64.193457,
    id: "arp_yzs_ca",
    icao_code: "CYZS",
    iata_country_code: "CA",
    iata_code: "YZS",
    city_name: "Coral Harbour",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Corazón de Jesús Airport",
    longitude: -78.587456,
    latitude: 9.444661,
    id: "arp_czj_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "CZJ",
    city_name: "Corazon de Jesus",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Corcoran Airport",
    longitude: -119.542623,
    latitude: 36.08884,
    id: "arp_cro_us",
    icao_code: "KCRO",
    iata_country_code: "US",
    iata_code: "CRO",
    city_name: "Corcoran",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Cordillo Downs Airport",
    longitude: 140.638137,
    latitude: -26.745757,
    id: "arp_odl_au",
    icao_code: "YCOD",
    iata_country_code: "AU",
    iata_code: "ODL",
    city_name: "Cordillo Downs",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Córdoba Airport",
    longitude: -4.847287,
    latitude: 37.842897,
    id: "arp_odb_es",
    icao_code: "LEBA",
    iata_country_code: "ES",
    iata_code: "ODB",
    city_name: "Cordoba",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Cordova Municipal Airport",
    longitude: -145.726309,
    latitude: 60.543733,
    id: "arp_cku_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CKU",
    city_name: "Cordova",
    city: {
      name: "Cordova",
      id: "cit_cdv_us",
      iata_country_code: "US",
      iata_code: "CDV",
    },
  },
  {
    time_zone: "Europe/Athens",
    name: "Corfu International Airport",
    longitude: 19.912108,
    latitude: 39.603532,
    id: "arp_cfu_gr",
    icao_code: "LGKR",
    iata_country_code: "GR",
    iata_code: "CFU",
    city_name: "Kerkyra",
    city: null,
  },
  {
    time_zone: "Africa/Malabo",
    name: "Corisco International Airport",
    longitude: 9.330105,
    latitude: 0.916645,
    id: "arp_ocs_gq",
    icao_code: null,
    iata_country_code: "GQ",
    iata_code: "OCS",
    city_name: "Corisco Island",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Cork Airport",
    longitude: -8.491224,
    latitude: 51.844147,
    id: "arp_ork_ie",
    icao_code: "EICK",
    iata_country_code: "IE",
    iata_code: "ORK",
    city_name: "Cork",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Cornélio Procópio Airport",
    longitude: -50.603019,
    latitude: -23.152985,
    id: "arp_cko_br",
    icao_code: "SSCP",
    iata_country_code: "BR",
    iata_code: "CKO",
    city_name: "Cornélio Procópio",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Corn Island Airport",
    longitude: -83.060959,
    latitude: 12.172374,
    id: "arp_rni_ni",
    icao_code: "MNCI",
    iata_country_code: "NI",
    iata_code: "RNI",
    city_name: "Corn Island",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Cornwall Airport",
    longitude: -5.00076,
    latitude: 50.44194,
    id: "arp_nqy_gb",
    icao_code: "EGHQ",
    iata_country_code: "GB",
    iata_code: "NQY",
    city_name: "Newquay",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Cornwall Regional Airport",
    longitude: -74.564357,
    latitude: 45.091963,
    id: "arp_ycc_ca",
    icao_code: "CYCC",
    iata_country_code: "CA",
    iata_code: "YCC",
    city_name: "Cornwall",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Coromandel Aerodrome",
    longitude: 175.508741,
    latitude: -36.791677,
    id: "arp_cmv_nz",
    icao_code: "NZCX",
    iata_country_code: "NZ",
    iata_code: "CMV",
    city_name: "Coromandel",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Coron Airport",
    longitude: 120.099267,
    latitude: 12.120442,
    id: "arp_xcn_ph",
    icao_code: "RPVV",
    iata_country_code: "PH",
    iata_code: "XCN",
    city_name: "Coron",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Coronation Airport",
    longitude: -111.445086,
    latitude: 52.075093,
    id: "arp_yct_ca",
    icao_code: "CYCT",
    iata_country_code: "CA",
    iata_code: "YCT",
    city_name: "Coronation",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Coronel FAP Alfredo Mendívil Duarte Airport",
    longitude: -74.204843,
    latitude: -13.154515,
    id: "arp_ayp_pe",
    icao_code: "SPHO",
    iata_country_code: "PE",
    iata_code: "AYP",
    city_name: "Ayacucho",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
    longitude: -70.275703,
    latitude: -18.053179,
    id: "arp_tcq_pe",
    icao_code: "SPTN",
    iata_country_code: "PE",
    iata_code: "TCQ",
    city_name: "Tacna",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Coronel FAP Francisco Secada Vignetta International Airport",
    longitude: -73.307274,
    latitude: -3.784741,
    id: "arp_iqt_pe",
    icao_code: "SPQT",
    iata_country_code: "PE",
    iata_code: "IQT",
    city_name: "Iquitos",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Corowa Airport",
    longitude: 146.356457,
    latitude: -35.991758,
    id: "arp_cww_au",
    icao_code: "YCOR",
    iata_country_code: "AU",
    iata_code: "CWW",
    city_name: "Corowa",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Corozal Airport",
    longitude: -88.411932,
    latitude: 18.381902,
    id: "arp_czh_bz",
    icao_code: "MZCZ",
    iata_country_code: "BZ",
    iata_code: "CZH",
    city_name: "Corozal",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Corpus Christi International Airport",
    longitude: -97.50169,
    latitude: 27.771411,
    id: "arp_crp_us",
    icao_code: "KCRP",
    iata_country_code: "US",
    iata_code: "CRP",
    city_name: "Corpus Christi",
    city: {
      name: "Corpus Christi",
      id: "cit_crp_us",
      iata_country_code: "US",
      iata_code: "CRP",
    },
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Corrientes International Airport",
    longitude: -58.760567,
    latitude: -27.446894,
    id: "arp_cnq_ar",
    icao_code: "SARC",
    iata_country_code: "AR",
    iata_code: "CNQ",
    city_name: "Corrientes",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Corryong Airport",
    longitude: 147.889618,
    latitude: -36.18288,
    id: "arp_cyg_au",
    icao_code: "YCRG",
    iata_country_code: "AU",
    iata_code: "CYG",
    city_name: "Corryong",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Corsicana Airport",
    longitude: -96.398333,
    latitude: 32.028202,
    id: "arp_crs_us",
    icao_code: "KCRS",
    iata_country_code: "US",
    iata_code: "CRS",
    city_name: "Corsicana",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Cortes Bay Airport",
    longitude: -124.984628,
    latitude: 50.024356,
    id: "arp_ycf_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YCF",
    city_name: "Cortes Bay",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Cortez Municipal Airport",
    longitude: -108.628602,
    latitude: 37.303049,
    id: "arp_cez_us",
    icao_code: "KCEZ",
    iata_country_code: "US",
    iata_code: "CEZ",
    city_name: "Cortez",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cortland County Airport",
    longitude: -76.215002,
    latitude: 42.593478,
    id: "arp_ctx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CTX",
    city_name: "Cortland",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Corumbá International Airport",
    longitude: -57.668736,
    latitude: -19.012557,
    id: "arp_cmg_br",
    icao_code: "SBCR",
    iata_country_code: "BR",
    iata_code: "CMG",
    city_name: "Corumbá",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Corvallis Municipal Airport",
    longitude: -123.287839,
    latitude: 44.498733,
    id: "arp_cvo_us",
    icao_code: "KCVO",
    iata_country_code: "US",
    iata_code: "CVO",
    city_name: "Corvallis",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Corvo Airport",
    longitude: -31.113834,
    latitude: 39.671232,
    id: "arp_cvu_pt",
    icao_code: "LPCR",
    iata_country_code: "PT",
    iata_code: "CVU",
    city_name: "Corvo Island",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Costa Esmeralda Airport",
    longitude: -86.032876,
    latitude: 11.429178,
    id: "arp_eci_ni",
    icao_code: "MNCE",
    iata_country_code: "NI",
    iata_code: "ECI",
    city_name: "Tola",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Costa Marques Airport",
    longitude: -64.251602,
    latitude: -12.421099,
    id: "arp_cqs_br",
    icao_code: "SWCQ",
    iata_country_code: "BR",
    iata_code: "CQS",
    city_name: "Costa Marques",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Coto 47 Airport",
    longitude: -82.968663,
    latitude: 8.601709,
    id: "arp_otr_cr",
    icao_code: "MRCC",
    iata_country_code: "CR",
    iata_code: "OTR",
    city_name: "Coto 47",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Cotopaxi International Airport",
    longitude: -78.617463,
    latitude: -0.91836,
    id: "arp_ltx_ec",
    icao_code: "SELT",
    iata_country_code: "EC",
    iata_code: "LTX",
    city_name: "Latacunga",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Cotswold Airport",
    longitude: -2.05403,
    latitude: 51.66891,
    id: "arp_gba_vu",
    icao_code: "EGBP",
    iata_country_code: "VU",
    iata_code: "GBA",
    city_name: "Kemble",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Cottbus-Drewitz Airport",
    longitude: 14.531884,
    latitude: 51.886525,
    id: "arp_cbu_de",
    icao_code: "EDCD",
    iata_country_code: "DE",
    iata_code: "CBU",
    city_name: "Cottbus",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Cottonwood Airport",
    longitude: -112.037238,
    latitude: 34.732695,
    id: "arp_ctw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CTW",
    city_name: "Cottonwood",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cotulla-La Salle County Airport",
    longitude: -99.219258,
    latitude: 28.455344,
    id: "arp_cot_us",
    icao_code: "KCOT",
    iata_country_code: "US",
    iata_code: "COT",
    city_name: "Cotulla",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Coulter Field",
    longitude: -96.332199,
    latitude: 30.715924,
    id: "arp_cfd_us",
    icao_code: "KCFD",
    iata_country_code: "US",
    iata_code: "CFD",
    city_name: "Bryan",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Council Airport",
    longitude: -163.703802,
    latitude: 64.897461,
    id: "arp_cil_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CIL",
    city_name: "Council",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Council Bluffs Municipal Airport",
    longitude: -95.760103,
    latitude: 41.259981,
    id: "arp_cbf_us",
    icao_code: "KCBF",
    iata_country_code: "US",
    iata_code: "CBF",
    city_name: "Council Bluffs",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Courchevel Airport",
    longitude: 6.634076,
    latitude: 45.396447,
    id: "arp_cvf_fr",
    icao_code: "LFLJ",
    iata_country_code: "FR",
    iata_code: "CVF",
    city_name: "Courchevel",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Courtenay Airpark",
    longitude: -124.984245,
    latitude: 49.680259,
    id: "arp_yca_ca",
    icao_code: "CYCA",
    iata_country_code: "CA",
    iata_code: "YCA",
    city_name: "Courtenay",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Coveñas Airport",
    longitude: -75.691507,
    latitude: 9.401018,
    id: "arp_cve_co",
    icao_code: "SKCV",
    iata_country_code: "CO",
    iata_code: "CVE",
    city_name: "Coveñas",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Coventry Airport",
    longitude: -1.482494,
    latitude: 52.368891,
    id: "arp_cvt_gb",
    icao_code: "EGBE",
    iata_country_code: "GB",
    iata_code: "CVT",
    city_name: "Coventry",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Cowarie Airport",
    longitude: 138.327938,
    latitude: -27.71152,
    id: "arp_cwr_au",
    icao_code: "YCWI",
    iata_country_code: "AU",
    iata_code: "CWR",
    city_name: "Cowarie",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Cowell Airport",
    longitude: 136.89157,
    latitude: -33.66825,
    id: "arp_ccw_au",
    icao_code: "YCWL",
    iata_country_code: "AU",
    iata_code: "CCW",
    city_name: "Cowell",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Cowley Airport",
    longitude: -114.094753,
    latitude: 49.636947,
    id: "arp_yym_ca",
    icao_code: "CYYM",
    iata_country_code: "CA",
    iata_code: "YYM",
    city_name: "Cowley",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Cowra Airport",
    longitude: 148.649567,
    latitude: -33.844997,
    id: "arp_cwt_au",
    icao_code: "YCWR",
    iata_country_code: "AU",
    iata_code: "CWT",
    city_name: "Cowra",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cox Field",
    longitude: -95.452901,
    latitude: 33.636602,
    id: "arp_prx_us",
    icao_code: "KPRX",
    iata_country_code: "US",
    iata_code: "PRX",
    city_name: "Paris",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Cox's Bazar Airport",
    longitude: 91.964697,
    latitude: 21.451343,
    id: "arp_cxb_bd",
    icao_code: "VGCB",
    iata_country_code: "BD",
    iata_code: "CXB",
    city_name: "Cox's Bazar",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Coyoles Airport",
    longitude: -86.675905,
    latitude: 15.445465,
    id: "arp_cyl_hn",
    icao_code: "MHCS",
    iata_country_code: "HN",
    iata_code: "CYL",
    city_name: "Coyoles",
    city: null,
  },
  {
    time_zone: "America/Cancun",
    name: "Cozumel International Airport",
    longitude: -86.92723,
    latitude: 20.517825,
    id: "arp_czm_mx",
    icao_code: "MMCZ",
    iata_country_code: "MX",
    iata_code: "CZM",
    city_name: "Cozumel",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Cradock Airport",
    longitude: 25.643999,
    latitude: -32.15502,
    id: "arp_cdo_za",
    icao_code: "FACD",
    iata_country_code: "ZA",
    iata_code: "CDO",
    city_name: "Cradock",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Craig Cove Airport",
    longitude: 167.923996,
    latitude: -16.264999,
    id: "arp_ccv_vu",
    icao_code: "NVSF",
    iata_country_code: "VU",
    iata_code: "CCV",
    city_name: "Craig Cove",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Craig Field",
    longitude: -86.987793,
    latitude: 32.343894,
    id: "arp_sem_us",
    icao_code: "KSEM",
    iata_country_code: "US",
    iata_code: "SEM",
    city_name: "Selma",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Craig-Moffat Airport",
    longitude: -107.522829,
    latitude: 40.493701,
    id: "arp_cig_us",
    icao_code: "KCAG",
    iata_country_code: "US",
    iata_code: "CIG",
    city_name: "Craig",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Craig Seaplane Base",
    longitude: -133.148163,
    latitude: 55.477594,
    id: "arp_cga_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CGA",
    city_name: "Craig",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Craiova Airport",
    longitude: 23.883494,
    latitude: 44.31532,
    id: "arp_cra_ro",
    icao_code: "LRCV",
    iata_country_code: "RO",
    iata_code: "CRA",
    city_name: "Craiova",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cram Field",
    longitude: -99.149051,
    latitude: 41.776368,
    id: "arp_bub_us",
    icao_code: "KBUB",
    iata_country_code: "US",
    iata_code: "BUB",
    city_name: "Burwell",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Cranbrook/Canadian Rockies International Airport",
    longitude: -115.784223,
    latitude: 49.612678,
    id: "arp_yxc_ca",
    icao_code: "CYXC",
    iata_country_code: "CA",
    iata_code: "YXC",
    city_name: "Cranbrook",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Crane County Airport",
    longitude: -102.359883,
    latitude: 31.413123,
    id: "arp_ccg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CCG",
    city_name: "Crane",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Crane Island Airport",
    longitude: -81.46608,
    latitude: 30.616434,
    id: "arp_ckr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CKR",
    city_name: "Crane Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Crater Lake-Klamath Regional Airport",
    longitude: -121.733351,
    latitude: 42.157077,
    id: "arp_lmt_us",
    icao_code: "KLMT",
    iata_country_code: "US",
    iata_code: "LMT",
    city_name: "Klamath Falls",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Cravo Norte Airport",
    longitude: -70.211954,
    latitude: 6.316363,
    id: "arp_rav_co",
    icao_code: "SKCN",
    iata_country_code: "CO",
    iata_code: "RAV",
    city_name: "Cravo Norte",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Creech Air Force Base",
    longitude: -115.673116,
    latitude: 36.587194,
    id: "arp_ins_us",
    icao_code: "KINS",
    iata_country_code: "US",
    iata_code: "INS",
    city_name: "Indian Springs",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Creil Airport",
    longitude: 2.51914,
    latitude: 49.253502,
    id: "arp_csf_fr",
    icao_code: "LFPC",
    iata_country_code: "FR",
    iata_code: "CSF",
    city_name: "Creil",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Cresswell Downs Airport",
    longitude: 135.91459,
    latitude: -17.947806,
    id: "arp_csd_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "CSD",
    city_name: "Cresswell Downs",
    city: null,
  },
  {
    time_zone: "America/Creston",
    name: "Creston Airport",
    longitude: -116.498167,
    latitude: 49.036922,
    id: "arp_cfq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "CFQ",
    city_name: "Creston",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Creston Municipal Airport",
    longitude: -94.361772,
    latitude: 41.020604,
    id: "arp_csq_us",
    icao_code: "KCSQ",
    iata_country_code: "US",
    iata_code: "CSQ",
    city_name: "Creston",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Croker Island Airport",
    longitude: 132.483489,
    latitude: -11.164834,
    id: "arp_cki_au",
    icao_code: "YCKI",
    iata_country_code: "AU",
    iata_code: "CKI",
    city_name: "Croker Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Crooked Creek Airport",
    longitude: -78.244226,
    latitude: 35.932365,
    id: "arp_ckd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CKD",
    city_name: "Crooked Creek",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Crookston Municipal Airport",
    longitude: -96.620074,
    latitude: 47.841247,
    id: "arp_ckn_us",
    icao_code: "KCKN",
    iata_country_code: "US",
    iata_code: "CKN",
    city_name: "Crookston",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cross City Airport",
    longitude: -83.105898,
    latitude: 29.634802,
    id: "arp_cty_us",
    icao_code: "KCTY",
    iata_country_code: "US",
    iata_code: "CTY",
    city_name: "Cross City",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Cross Lake",
    longitude: -97.763316,
    latitude: 54.610007,
    id: "arp_ycr_ca",
    icao_code: "CYCR",
    iata_country_code: "CA",
    iata_code: "YCR",
    city_name: "Cross Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Crossville Memorial Whitson Field",
    longitude: -85.084519,
    latitude: 35.952002,
    id: "arp_csv_us",
    icao_code: "KCSV",
    iata_country_code: "US",
    iata_code: "CSV",
    city_name: "Crossville",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Crotone Airport",
    longitude: 17.080242,
    latitude: 38.996982,
    id: "arp_crv_it",
    icao_code: "LIBC",
    iata_country_code: "IT",
    iata_code: "CRV",
    city_name: "Crotone",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Croydon Airport",
    longitude: 142.25385,
    latitude: -18.218464,
    id: "arp_cdq_au",
    icao_code: "YCRY",
    iata_country_code: "AU",
    iata_code: "CDQ",
    city_name: "Croydon",
    city: null,
  },
  {
    time_zone: "America/Rio_Branco",
    name: "Cruzeiro do Sul International Airport",
    longitude: -72.770809,
    latitude: -7.599389,
    id: "arp_czs_br",
    icao_code: "SBCZ",
    iata_country_code: "BR",
    iata_code: "CZS",
    city_name: "Cruzeiro do Sul",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Crystal Airport",
    longitude: -93.355649,
    latitude: 45.060377,
    id: "arp_mic_us",
    icao_code: "KMIC",
    iata_country_code: "US",
    iata_code: "MIC",
    city_name: "St Paul/Minneapolis",
    city: {
      name: "Minneapolis/St Paul",
      id: "cit_msp_us",
      iata_country_code: "US",
      iata_code: "MSP",
    },
  },
  {
    time_zone: "Africa/Maputo",
    name: "Cuamba Airport",
    longitude: 36.530787,
    latitude: -14.816702,
    id: "arp_fxo_mz",
    icao_code: "FQCB",
    iata_country_code: "MZ",
    iata_code: "FXO",
    city_name: "Cuamba",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Cudal Airport",
    longitude: 148.763216,
    latitude: -33.278262,
    id: "arp_cug_au",
    icao_code: "YCUA",
    iata_country_code: "AU",
    iata_code: "CUG",
    city_name: "Cudal",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cuddihy Field Airport",
    longitude: -97.512269,
    latitude: 27.721676,
    id: "arp_cux_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CUX",
    city_name: "Corpus Christi",
    city: {
      name: "Corpus Christi",
      id: "cit_crp_us",
      iata_country_code: "US",
      iata_code: "CRP",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Cue Airport",
    longitude: 117.919182,
    latitude: -27.446336,
    id: "arp_cuy_au",
    icao_code: "YCUE",
    iata_country_code: "AU",
    iata_code: "CUY",
    city_name: "Cue",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Cuito Cuanavale Airport",
    longitude: 19.155135,
    latitude: -15.159106,
    id: "arp_cti_ao",
    icao_code: "FNCV",
    iata_country_code: "AO",
    iata_code: "CTI",
    city_name: "Cuito Cuanavale",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Culberson County Airport",
    longitude: -104.783588,
    latitude: 31.058646,
    id: "arp_vhn_us",
    icao_code: "KVHN",
    iata_country_code: "US",
    iata_code: "VHN",
    city_name: "Van Horn",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Culion Airport",
    longitude: 119.93751,
    latitude: 11.855023,
    id: "arp_cuj_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "CUJ",
    city_name: "Culion",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Cuneo International Airport",
    longitude: 7.623536,
    latitude: 44.547656,
    id: "arp_cuf_it",
    icao_code: "LIMZ",
    iata_country_code: "IT",
    iata_code: "CUF",
    city_name: "Cuneo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Cunnamulla Airport",
    longitude: 145.620029,
    latitude: -28.030463,
    id: "arp_cma_au",
    icao_code: "YCMU",
    iata_country_code: "AU",
    iata_code: "CMA",
    city_name: "Cunnamulla",
    city: null,
  },
  {
    time_zone: "America/Merida",
    name: "Cupul Airport",
    longitude: -88.173357,
    latitude: 21.155795,
    id: "arp_tzm_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "TZM",
    city_name: "Tizimin",
    city: null,
  },
  {
    time_zone: "America/Curacao",
    name: "Curaçao International Airport",
    longitude: -68.960373,
    latitude: 12.188682,
    id: "arp_cur_cw",
    icao_code: "TNCC",
    iata_country_code: "CW",
    iata_code: "CUR",
    city_name: "Willemstad",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Currillo Airport",
    longitude: -71.999968,
    latitude: 4.665975,
    id: "arp_cui_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "CUI",
    city_name: "Currillo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Curtis Field",
    longitude: -99.322566,
    latitude: 31.17851,
    id: "arp_bbd_us",
    icao_code: "KBBD",
    iata_country_code: "US",
    iata_code: "BBD",
    city_name: "Brady",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Cururupu Airport",
    longitude: -44.864369,
    latitude: -1.820339,
    id: "arp_cpu_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "CPU",
    city_name: "Cururupu",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Curuzu Cuatia Airport",
    longitude: -57.9789,
    latitude: -29.7706,
    id: "arp_uzu_ar",
    icao_code: "SATU",
    iata_country_code: "AR",
    iata_code: "UZU",
    city_name: "Curuzu Cuatia",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Cushing Municipal Airport",
    longitude: -96.773077,
    latitude: 35.949913,
    id: "arp_cuh_us",
    icao_code: "KCUH",
    iata_country_code: "US",
    iata_code: "CUH",
    city_name: "Cushing",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Cut Bank International Airport",
    longitude: -112.372007,
    latitude: 48.608141,
    id: "arp_ctb_us",
    icao_code: "KCTB",
    iata_country_code: "US",
    iata_code: "CTB",
    city_name: "Cut Bank",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Cut Nyak Dhien Airport",
    longitude: 96.250385,
    latitude: 4.047069,
    id: "arp_meq_id",
    icao_code: "WITC",
    iata_country_code: "ID",
    iata_code: "MEQ",
    city_name: "Meulaboh",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Cutral-Co Airport",
    longitude: -69.266812,
    latitude: -38.93972,
    id: "arp_cut_ar",
    icao_code: "SAZW",
    iata_country_code: "AR",
    iata_code: "CUT",
    city_name: "Cutral-Co",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Cuyahoga County Airport",
    longitude: -81.487719,
    latitude: 41.565789,
    id: "arp_cgf_us",
    icao_code: "KCGF",
    iata_country_code: "US",
    iata_code: "CGF",
    city_name: "Cleveland",
    city: {
      name: "Cleveland",
      id: "cit_cle_us",
      iata_country_code: "US",
      iata_code: "CLE",
    },
  },
  {
    time_zone: "Asia/Manila",
    name: "Cuyo Airport",
    longitude: 121.069651,
    latitude: 10.858896,
    id: "arp_cyu_ph",
    icao_code: "RPLO",
    iata_country_code: "PH",
    iata_code: "CYU",
    city_name: "Cuyo",
    city: null,
  },
  {
    time_zone: "America/St_Thomas",
    name: "Cyril E. King Airport",
    longitude: -64.972835,
    latitude: 18.336679,
    id: "arp_stt_vi",
    icao_code: "TIST",
    iata_country_code: "VI",
    iata_code: "STT",
    city_name: "St Thomas Island",
    city: {
      name: "St Thomas Island",
      id: "cit_stt_vi",
      iata_country_code: "VI",
      iata_code: "STT",
    },
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Częstochowa-Rudniki Airport",
    longitude: 19.202795,
    latitude: 50.887467,
    id: "arp_czw_pl",
    icao_code: "EPRU",
    iata_country_code: "PL",
    iata_code: "CZW",
    city_name: "Czestochowa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dabo Airport",
    longitude: 42.1667,
    latitude: 13.1333,
    id: "arp_dao_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "DAO",
    city_name: "Dabo",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Dabo Airport",
    longitude: 104.578416,
    latitude: -0.478486,
    id: "arp_siq_id",
    icao_code: "WIDS",
    iata_country_code: "ID",
    iata_code: "SIQ",
    city_name: "Singkep",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Dabra Airport",
    longitude: 138.613683,
    latitude: -3.270371,
    id: "arp_drh_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "DRH",
    city_name: "Dabra",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dade-Collier Training and Transition Airport",
    longitude: -80.897458,
    latitude: 25.862799,
    id: "arp_tnt_us",
    icao_code: "KTNT",
    iata_country_code: "US",
    iata_code: "TNT",
    city_name: "Miami",
    city: {
      name: "Miami",
      id: "cit_mia_us",
      iata_country_code: "US",
      iata_code: "MIA",
    },
  },
  {
    time_zone: "Asia/Karachi",
    name: "Dadu Airport",
    longitude: 67.664057,
    latitude: 26.740647,
    id: "arp_ddu_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "DDU",
    city_name: "Dadu",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Daegu International Airport",
    longitude: 128.63859,
    latitude: 35.900208,
    id: "arp_tae_kr",
    icao_code: "RKTN",
    iata_country_code: "KR",
    iata_code: "TAE",
    city_name: "Daegu",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Daet Airport",
    longitude: 122.981346,
    latitude: 14.130825,
    id: "arp_dte_ph",
    icao_code: "RPUD",
    iata_country_code: "PH",
    iata_code: "DTE",
    city_name: "Daet",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Dahl Creek Airport",
    longitude: -156.89866,
    latitude: 66.942788,
    id: "arp_dck_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DCK",
    city_name: "Dahl Creek",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dahlgren Naval Surface Warfare Center Airport",
    longitude: -77.037628,
    latitude: 38.332779,
    id: "arp_dgn_us",
    icao_code: "KNDY",
    iata_country_code: "US",
    iata_code: "DGN",
    city_name: "Dahlgren",
    city: null,
  },
  {
    time_zone: "Africa/El_Aaiun",
    name: "Dakhla Airport",
    longitude: -15.93196,
    latitude: 23.71843,
    id: "arp_vil_eh",
    icao_code: "GMMH",
    iata_country_code: "EH",
    iata_code: "VIL",
    city_name: "Dakhla",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Dakhla Oasis Airport",
    longitude: 29.000235,
    latitude: 25.420403,
    id: "arp_dak_eg",
    icao_code: "HEDK",
    iata_country_code: "EG",
    iata_code: "DAK",
    city_name: "Dakhla Oasis",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Dalaman Airport",
    longitude: 28.792908,
    latitude: 36.715615,
    id: "arp_dlm_tr",
    icao_code: "LTBS",
    iata_country_code: "TR",
    iata_code: "DLM",
    city_name: "Dalaman",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Dalanzadgad Airport",
    longitude: 104.429907,
    latitude: 43.59169,
    id: "arp_dlz_mn",
    icao_code: "ZMDZ",
    iata_country_code: "MN",
    iata_code: "DLZ",
    city_name: "Dalanzadgad",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Dalbandin Airport",
    longitude: 64.40039,
    latitude: 28.876956,
    id: "arp_dba_pk",
    icao_code: "OPDB",
    iata_country_code: "PK",
    iata_code: "DBA",
    city_name: "Dalbandin",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dalbertis Airport",
    longitude: 23.891111,
    latitude: 56.741667,
    id: "arp_dlb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "DLB",
    city_name: "Dalbertis",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dalby Airport",
    longitude: 151.264609,
    latitude: -27.158088,
    id: "arp_dby_au",
    icao_code: "YDAY",
    iata_country_code: "AU",
    iata_code: "DBY",
    city_name: "Dalby",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Dalgaranga Gold Mine Airport",
    longitude: 117.315002,
    latitude: -27.829286,
    id: "arp_dgd_au",
    icao_code: "YDGA",
    iata_country_code: "AU",
    iata_code: "DGD",
    city_name: "Dalgaranga Gold Mine",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dalhart Municipal Airport",
    longitude: -102.546044,
    latitude: 36.023776,
    id: "arp_dht_us",
    icao_code: "KDHT",
    iata_country_code: "US",
    iata_code: "DHT",
    city_name: "Dalhart",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dali Airport",
    longitude: 100.319873,
    latitude: 25.650315,
    id: "arp_dlu_cn",
    icao_code: "ZPDL",
    iata_country_code: "CN",
    iata_code: "DLU",
    city_name: "Dali",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dalian Zhoushuizi International Airport",
    longitude: 121.539672,
    latitude: 38.964842,
    id: "arp_dlc_cn",
    icao_code: "ZYTL",
    iata_country_code: "CN",
    iata_code: "DLC",
    city_name: "Dalian",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dallas Executive Airport",
    longitude: -96.867312,
    latitude: 32.680303,
    id: "arp_rbd_us",
    icao_code: "KRBD",
    iata_country_code: "US",
    iata_code: "RBD",
    city_name: "Dallas",
    city: {
      name: "Dallas",
      id: "cit_dfw_us",
      iata_country_code: "US",
      iata_code: "DFW",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Dallas/Fort Worth International Airport",
    longitude: -97.039169,
    latitude: 32.898305,
    id: "arp_dfw_us",
    icao_code: "KDFW",
    iata_country_code: "US",
    iata_code: "DFW",
    city_name: "Dallas",
    city: {
      name: "Dallas",
      id: "cit_dfw_us",
      iata_country_code: "US",
      iata_code: "DFW",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Dallas Love Field",
    longitude: -96.851502,
    latitude: 32.8476,
    id: "arp_dal_us",
    icao_code: "KDAL",
    iata_country_code: "US",
    iata_code: "DAL",
    city_name: "Dallas",
    city: {
      name: "Dallas",
      id: "cit_dfw_us",
      iata_country_code: "US",
      iata_code: "DFW",
    },
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Dalnegorsk Airport",
    longitude: 135.490201,
    latitude: 44.558766,
    id: "arp_dhg_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "DHG",
    city_name: "Dalnegorsk",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Dalnerechensk Airport",
    longitude: 133.734742,
    latitude: 45.878239,
    id: "arp_dlr_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "DLR",
    city_name: "Dalnerechensk",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Daloa Airport",
    longitude: -6.473189,
    latitude: 6.792809,
    id: "arp_djo_ci",
    icao_code: "DIDL",
    iata_country_code: "CI",
    iata_code: "DJO",
    city_name: "Daloa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dalton Municipal Airport",
    longitude: -84.870237,
    latitude: 34.722928,
    id: "arp_dnn_us",
    icao_code: "KDNN",
    iata_country_code: "US",
    iata_code: "DNN",
    city_name: "Dalton",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Daly River Airport",
    longitude: 130.69353,
    latitude: -13.749734,
    id: "arp_dvr_au",
    icao_code: "YDLW",
    iata_country_code: "AU",
    iata_code: "DVR",
    city_name: "Daly River Mission",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Daly Waters Airport",
    longitude: 133.384171,
    latitude: -16.264325,
    id: "arp_dyw_au",
    icao_code: "YDLW",
    iata_country_code: "AU",
    iata_code: "DYW",
    city_name: "Daly Waters",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Dama Airport",
    longitude: 178.622621,
    latitude: -16.859646,
    id: "arp_bvf_fj",
    icao_code: "NFNU",
    iata_country_code: "FJ",
    iata_code: "BVF",
    city_name: "Bua",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Daman Airport",
    longitude: 72.843312,
    latitude: 20.434049,
    id: "arp_nmb_in",
    icao_code: "VADN",
    iata_country_code: "IN",
    iata_code: "NMB",
    city_name: "Daman",
    city: null,
  },
  {
    time_zone: "Asia/Damascus",
    name: "Damascus International Airport",
    longitude: 36.514617,
    latitude: 33.411212,
    id: "arp_dam_sy",
    icao_code: "OSDI",
    iata_country_code: "SY",
    iata_code: "DAM",
    city_name: "Damascus",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Damazin Airport",
    longitude: 34.337071,
    latitude: 11.787989,
    id: "arp_rss_sd",
    icao_code: "HSDZ",
    iata_country_code: "SD",
    iata_code: "RSS",
    city_name: "Ad-Damazin",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Dambulu Oya Tank Seaplane Base",
    longitude: 80.630604,
    latitude: 7.859922,
    id: "arp_dbu_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "DBU",
    city_name: "Dambulla",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Da Nang International Airport",
    longitude: 108.200512,
    latitude: 16.056967,
    id: "arp_dad_vn",
    icao_code: "VVDN",
    iata_country_code: "VN",
    iata_code: "DAD",
    city_name: "Da Nang",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Danbury Municipal Airport",
    longitude: -73.481673,
    latitude: 41.371526,
    id: "arp_dxr_us",
    icao_code: "KDXR",
    iata_country_code: "US",
    iata_code: "DXR",
    city_name: "Danbury",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dandong Langtou Airport",
    longitude: 124.284933,
    latitude: 40.02688,
    id: "arp_ddg_cn",
    icao_code: "ZYDD",
    iata_country_code: "CN",
    iata_code: "DDG",
    city_name: "Dandong",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dane County Regional Airport",
    longitude: -89.336957,
    latitude: 43.139483,
    id: "arp_msn_us",
    icao_code: "KMSN",
    iata_country_code: "US",
    iata_code: "MSN",
    city_name: "Madison",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Dangriga Airport",
    longitude: -88.229748,
    latitude: 16.982362,
    id: "arp_dga_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "DGA",
    city_name: "Dangriga",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Daniel Field",
    longitude: -82.038534,
    latitude: 33.466454,
    id: "arp_dnl_us",
    icao_code: "KDNL",
    iata_country_code: "US",
    iata_code: "DNL",
    city_name: "Augusta",
    city: {
      name: "Augusta",
      id: "cit_ags_us",
      iata_country_code: "US",
      iata_code: "AGS",
    },
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Daniel K. Inouye International Airport",
    longitude: -157.92465,
    latitude: 21.322566,
    id: "arp_hnl_us",
    icao_code: "PHNL",
    iata_country_code: "US",
    iata_code: "HNL",
    city_name: "Honolulu",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Daniel Oduber Quirós International Airport",
    longitude: -85.544071,
    latitude: 10.593701,
    id: "arp_lir_cr",
    icao_code: "MRLB",
    iata_country_code: "CR",
    iata_code: "LIR",
    city_name: "Liberia",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Daniel Z. Romualdez Airport",
    longitude: 125.028462,
    latitude: 11.230338,
    id: "arp_tac_ph",
    icao_code: "RPVA",
    iata_country_code: "PH",
    iata_code: "TAC",
    city_name: "Tacloban",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Danilo Atienza Air Base",
    longitude: 120.90777,
    latitude: 14.49654,
    id: "arp_sgl_ph",
    icao_code: "RPLS",
    iata_country_code: "PH",
    iata_code: "SGL",
    city_name: "Manila",
    city: {
      name: "Manila",
      id: "cit_mnl_ph",
      iata_country_code: "PH",
      iata_code: "MNL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Dansville Municipal Airport",
    longitude: -77.709194,
    latitude: 42.568893,
    id: "arp_dsv_us",
    icao_code: "KDSV",
    iata_country_code: "US",
    iata_code: "DSV",
    city_name: "Dansville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Danville Regional Airport",
    longitude: -79.336153,
    latitude: 36.573561,
    id: "arp_dan_us",
    icao_code: "KDAN",
    iata_country_code: "US",
    iata_code: "DAN",
    city_name: "Danville",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Daocheng Yading Airport",
    longitude: 100.053333,
    latitude: 29.323056,
    id: "arp_dcy_cn",
    icao_code: "ZUDC",
    iata_country_code: "CN",
    iata_code: "DCY",
    city_name: "Daocheng",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Daporijo Airport",
    longitude: 94.222352,
    latitude: 27.985404,
    id: "arp_dep_in",
    icao_code: "VEDZ",
    iata_country_code: "IN",
    iata_code: "DEP",
    city_name: "Daporijo",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Daqing Sartu Airport",
    longitude: 125.138878,
    latitude: 46.747237,
    id: "arp_dqa_cn",
    icao_code: "ZYDQ",
    iata_country_code: "CN",
    iata_code: "DQA",
    city_name: "Daqing",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Darchula Airport",
    longitude: 80.548401,
    latitude: 29.668865,
    id: "arp_dap_np",
    icao_code: "VNDL",
    iata_country_code: "NP",
    iata_code: "DAP",
    city_name: "Darchula",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dare County Regional Airport",
    longitude: -75.696979,
    latitude: 35.918751,
    id: "arp_meo_us",
    icao_code: "KMQI",
    iata_country_code: "US",
    iata_code: "MEO",
    city_name: "Manteo",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Dargaville Aerodrome",
    longitude: 173.893763,
    latitude: -35.939823,
    id: "arp_dgr_nz",
    icao_code: "NZDA",
    iata_country_code: "NZ",
    iata_code: "DGR",
    city_name: "Dargaville",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Darnley Island Airport",
    longitude: 143.780472,
    latitude: -9.579226,
    id: "arp_nlf_au",
    icao_code: "YDNI",
    iata_country_code: "AU",
    iata_code: "NLF",
    city_name: "Darnley Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Daru Airport",
    longitude: 143.207885,
    latitude: -9.086713,
    id: "arp_dau_pg",
    icao_code: "AYDU",
    iata_country_code: "PG",
    iata_code: "DAU",
    city_name: "Daru",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Darwaz Airport",
    longitude: 70.882364,
    latitude: 38.461316,
    id: "arp_daz_af",
    icao_code: "OADZ",
    iata_country_code: "AF",
    iata_code: "DAZ",
    city_name: "Darwaz",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Darwin International Airport",
    longitude: 130.877135,
    latitude: -12.41207,
    id: "arp_drw_au",
    icao_code: "YPDN",
    iata_country_code: "AU",
    iata_code: "DRW",
    city_name: "Darwin",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Dasht-e Naz Airport",
    longitude: 53.194661,
    latitude: 36.635807,
    id: "arp_sry_ir",
    icao_code: "OINZ",
    iata_country_code: "IR",
    iata_code: "SRY",
    city_name: "Sari",
    city: null,
  },
  {
    time_zone: "Asia/Ashgabat",
    name: "Daşoguz Airport",
    longitude: 59.829036,
    latitude: 41.761909,
    id: "arp_taz_tm",
    icao_code: "UTAT",
    iata_country_code: "TM",
    iata_code: "TAZ",
    city_name: "Daşoguz",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Datadawai Airport",
    longitude: 114.530223,
    latitude: 0.809741,
    id: "arp_dtd_id",
    icao_code: "WALJ",
    iata_country_code: "ID",
    iata_code: "DTD",
    city_name: "Datadawai",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Dathina Airport",
    longitude: 46.133767,
    latitude: 13.866935,
    id: "arp_dah_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "DAH",
    city_name: "Dathina",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Datong Yungang Airport",
    longitude: 113.484441,
    latitude: 40.05909,
    id: "arp_dat_cn",
    icao_code: "ZBDT",
    iata_country_code: "CN",
    iata_code: "DAT",
    city_name: "Datong",
    city: null,
  },
  {
    time_zone: "Europe/Riga",
    name: "Daugavpils International Airport",
    longitude: 26.666163,
    latitude: 55.944833,
    id: "arp_dgp_lv",
    icao_code: "EVDA",
    iata_country_code: "LV",
    iata_code: "DGP",
    city_name: "Daugavpils",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Daup Airport",
    longitude: 145.9515,
    latitude: -4.7403,
    id: "arp_daf_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "DAF",
    city_name: "Daup",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Davao International Airport",
    longitude: 125.645751,
    latitude: 7.126669,
    id: "arp_dvo_ph",
    icao_code: "RPMD",
    iata_country_code: "PH",
    iata_code: "DVO",
    city_name: "Davao",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Davenport Downs Airport",
    longitude: 141.108001,
    latitude: -24.149999,
    id: "arp_dvp_au",
    icao_code: "YDPD",
    iata_country_code: "AU",
    iata_code: "DVP",
    city_name: "Davenport Downs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Davenport Municipal Airport",
    longitude: -90.588602,
    latitude: 41.610753,
    id: "arp_dvn_us",
    icao_code: "KDVN",
    iata_country_code: "US",
    iata_code: "DVN",
    city_name: "Davenport",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "David Wayne Hooks Memorial Airport",
    longitude: -95.551982,
    latitude: 30.065988,
    id: "arp_dwh_us",
    icao_code: "KDWH",
    iata_country_code: "US",
    iata_code: "DWH",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Davis-Monthan Air Force Base",
    longitude: -110.883003,
    latitude: 32.1665,
    id: "arp_dma_us",
    icao_code: "KDMA",
    iata_country_code: "US",
    iata_code: "DMA",
    city_name: "Tucson",
    city: {
      name: "Tucson",
      id: "cit_tus_us",
      iata_country_code: "US",
      iata_code: "TUS",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Davison Army Airfield",
    longitude: -77.180783,
    latitude: 38.715085,
    id: "arp_daa_us",
    icao_code: "KDAA",
    iata_country_code: "US",
    iata_code: "DAA",
    city_name: "Fort Belvoir",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Dawadmi Domestic Airport",
    longitude: 44.122445,
    latitude: 24.448557,
    id: "arp_dwd_sa",
    icao_code: "OEDM",
    iata_country_code: "SA",
    iata_code: "DWD",
    city_name: "Dawadmi",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Dawei Airport",
    longitude: 98.203916,
    latitude: 14.102377,
    id: "arp_tvy_mm",
    icao_code: "VYDW",
    iata_country_code: "MM",
    iata_code: "TVY",
    city_name: "Dawei",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Dawson City Airport",
    longitude: -139.126206,
    latitude: 64.044213,
    id: "arp_yda_ca",
    icao_code: "CYDA",
    iata_country_code: "CA",
    iata_code: "YDA",
    city_name: "Dawson City",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Dawson Community Airport",
    longitude: -104.806971,
    latitude: 47.138793,
    id: "arp_gdv_us",
    icao_code: "KGDV",
    iata_country_code: "US",
    iata_code: "GDV",
    city_name: "Glendive",
    city: null,
  },
  {
    time_zone: "America/Dawson_Creek",
    name: "Dawson Creek Airport",
    longitude: -120.184203,
    latitude: 55.741337,
    id: "arp_ydq_ca",
    icao_code: "CYDQ",
    iata_country_code: "CA",
    iata_code: "YDQ",
    city_name: "Dawson Creek",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Dayrestan Airport",
    longitude: 55.897728,
    latitude: 26.755616,
    id: "arp_gsm_ir",
    icao_code: "OIKQ",
    iata_country_code: "IR",
    iata_code: "GSM",
    city_name: "Gheshm Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Daytona Beach International Airport",
    longitude: -81.056392,
    latitude: 29.180639,
    id: "arp_dab_us",
    icao_code: "KDAB",
    iata_country_code: "US",
    iata_code: "DAB",
    city_name: "Daytona Beach",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dayton International Airport",
    longitude: -84.220585,
    latitude: 39.902463,
    id: "arp_day_us",
    icao_code: "KDAY",
    iata_country_code: "US",
    iata_code: "DAY",
    city_name: "Dayton",
    city: {
      name: "Dayton",
      id: "cit_day_us",
      iata_country_code: "US",
      iata_code: "DAY",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Dayton-Wright Brothers Airport",
    longitude: -84.22649,
    latitude: 39.591232,
    id: "arp_mgy_us",
    icao_code: "KMGY",
    iata_country_code: "US",
    iata_code: "MGY",
    city_name: "Dayton",
    city: {
      name: "Dayton",
      id: "cit_day_us",
      iata_country_code: "US",
      iata_code: "DAY",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dazhou Heshi Airport",
    longitude: 107.4307,
    latitude: 31.130474,
    id: "arp_dax_cn",
    icao_code: "ZUDX",
    iata_country_code: "CN",
    iata_code: "DAX",
    city_name: "Dazhou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dazu Airport",
    longitude: 105.773343,
    latitude: 29.636114,
    id: "arp_dzu_cn",
    icao_code: "ZUDZ",
    iata_country_code: "CN",
    iata_code: "DZU",
    city_name: "Dazu",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Deadhorse Airport",
    longitude: -148.462427,
    latitude: 70.195989,
    id: "arp_scc_us",
    icao_code: "PASC",
    iata_country_code: "US",
    iata_code: "SCC",
    city_name: "Deadhorse",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Deadman's Cay Airport",
    longitude: -75.093652,
    latitude: 23.178738,
    id: "arp_lgi_bs",
    icao_code: "MYLD",
    iata_country_code: "BS",
    iata_code: "LGI",
    city_name: "Deadman's Cay",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Dean River Airport",
    longitude: -126.96494,
    latitude: 52.819451,
    id: "arp_yrd_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YRD",
    city_name: "Dean River",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Dease Lake Airport",
    longitude: -130.031368,
    latitude: 58.422427,
    id: "arp_ydl_ca",
    icao_code: "CYDL",
    iata_country_code: "CA",
    iata_code: "YDL",
    city_name: "Dease Lake",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Deauville – Normandie Airport",
    longitude: 0.156691,
    latitude: 49.363817,
    id: "arp_dol_fr",
    icao_code: "LFRG",
    iata_country_code: "FR",
    iata_code: "DOL",
    city_name: "Deauville",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Debepare Airport",
    longitude: 141.903575,
    latitude: -6.307042,
    id: "arp_dbp_pg",
    icao_code: "AYDB",
    iata_country_code: "PG",
    iata_code: "DBP",
    city_name: "Debepare",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Debra Marcos Airport",
    longitude: 37.716999,
    latitude: 10.35,
    id: "arp_dbm_et",
    icao_code: "HADM",
    iata_country_code: "ET",
    iata_code: "DBM",
    city_name: "Debre Markos",
    city: null,
  },
  {
    time_zone: "Europe/Budapest",
    name: "Debrecen International Airport",
    longitude: 21.612845,
    latitude: 47.489846,
    id: "arp_deb_hu",
    icao_code: "LHDC",
    iata_country_code: "HU",
    iata_code: "DEB",
    city_name: "Debrecen",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Debre Tabor Airport",
    longitude: 38.0,
    latitude: 11.967,
    id: "arp_dbt_et",
    icao_code: "HADT",
    iata_country_code: "ET",
    iata_code: "DBT",
    city_name: "Debre Tabor",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Decatur Airport",
    longitude: -88.866044,
    latitude: 39.83466,
    id: "arp_dec_us",
    icao_code: "KDEC",
    iata_country_code: "US",
    iata_code: "DEC",
    city_name: "Decatur",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Decatur County Industrial Air Park",
    longitude: -84.6363,
    latitude: 30.973492,
    id: "arp_bge_us",
    icao_code: "KBGE",
    iata_country_code: "US",
    iata_code: "BGE",
    city_name: "Bainbridge",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Decatur Shores Airport",
    longitude: -122.813705,
    latitude: 48.499716,
    id: "arp_dtr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DTR",
    city_name: "Decatur Island",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Decimomannu Air Base",
    longitude: 8.972487,
    latitude: 39.353542,
    id: "arp_dci_it",
    icao_code: "LIED",
    iata_country_code: "IT",
    iata_code: "DCI",
    city_name: "Decimomannu",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Decorah Municipal Airport",
    longitude: -91.737412,
    latitude: 43.275617,
    id: "arp_deh_us",
    icao_code: "KDEH",
    iata_country_code: "US",
    iata_code: "DEH",
    city_name: "Decorah",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Dédougou Airport",
    longitude: -3.489709,
    latitude: 12.460117,
    id: "arp_dgu_bf",
    icao_code: "DFOD",
    iata_country_code: "BF",
    iata_code: "DGU",
    city_name: "Dedougou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dedu Airport",
    longitude: 126.133,
    latitude: 48.445,
    id: "arp_dtu_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "DTU",
    city_name: "Wudalianchi",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Deer Harbor Seaplane Base Airport",
    longitude: -123.013592,
    latitude: 48.537733,
    id: "arp_dhb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DHB",
    city_name: "Deer Harbor",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Deering Airport",
    longitude: -162.76612,
    latitude: 66.069486,
    id: "arp_drg_us",
    icao_code: "PADE",
    iata_country_code: "US",
    iata_code: "DRG",
    city_name: "Deering",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Deer Lake Airport",
    longitude: -94.060448,
    latitude: 52.654395,
    id: "arp_yvz_ca",
    icao_code: "CYVZ",
    iata_country_code: "CA",
    iata_code: "YVZ",
    city_name: "Deer Lake",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "Deer Lake Regional Airport",
    longitude: -57.393747,
    latitude: 49.210895,
    id: "arp_ydf_ca",
    icao_code: "CYDF",
    iata_country_code: "CA",
    iata_code: "YDF",
    city_name: "Deer Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Defiance Memorial Airport",
    longitude: -84.428405,
    latitude: 41.336516,
    id: "arp_dfi_us",
    icao_code: "KDFI",
    iata_country_code: "US",
    iata_code: "DFI",
    city_name: "Defiance",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Degahbur Airport",
    longitude: 43.5833,
    latitude: 8.23333,
    id: "arp_dgc_et",
    icao_code: "HADB",
    iata_country_code: "ET",
    iata_code: "DGC",
    city_name: "Degeh Bur",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dehong Mangshi Airport",
    longitude: 98.53168,
    latitude: 24.402186,
    id: "arp_lum_cn",
    icao_code: "ZPMS",
    iata_country_code: "CN",
    iata_code: "LUM",
    city_name: "Mangshi",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Dehradun Airport",
    longitude: 78.17885,
    latitude: 30.189638,
    id: "arp_ded_in",
    icao_code: "VIDN",
    iata_country_code: "IN",
    iata_code: "DED",
    city_name: "Dehradun",
    city: null,
  },
  {
    time_zone: "Asia/Damascus",
    name: "Deir ez-Zor Airport",
    longitude: 40.17612,
    latitude: 35.285257,
    id: "arp_dez_sy",
    icao_code: "OSDZ",
    iata_country_code: "SY",
    iata_code: "DEZ",
    city_name: "Deir ez-Zor",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dekalb Peachtree Airport",
    longitude: -84.303619,
    latitude: 33.875583,
    id: "arp_pdk_us",
    icao_code: "KPDK",
    iata_country_code: "US",
    iata_code: "PDK",
    city_name: "Atlanta",
    city: {
      name: "Atlanta",
      id: "cit_atl_us",
      iata_country_code: "US",
      iata_code: "ATL",
    },
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "De Kooy Airport",
    longitude: 4.782354,
    latitude: 52.922134,
    id: "arp_dhr_nl",
    icao_code: "EHKD",
    iata_country_code: "NL",
    iata_code: "DHR",
    city_name: "Den Helder",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Delaware County Regional Airport",
    longitude: -85.39529,
    latitude: 40.241866,
    id: "arp_mie_us",
    icao_code: "KMIE",
    iata_country_code: "US",
    iata_code: "MIE",
    city_name: "Muncie",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Del Bajío International Airport",
    longitude: -101.480786,
    latitude: 20.993509,
    id: "arp_bjx_mx",
    icao_code: "MMLO",
    iata_country_code: "MX",
    iata_code: "BJX",
    city_name: "Leon",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Déline Airport",
    longitude: -123.435953,
    latitude: 65.210446,
    id: "arp_ywj_ca",
    icao_code: "CYWJ",
    iata_country_code: "CA",
    iata_code: "YWJ",
    city_name: "Deline",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Delingha Airport",
    longitude: 97.267452,
    latitude: 37.125145,
    id: "arp_hxd_cn",
    icao_code: "ZLDL",
    iata_country_code: "CN",
    iata_code: "HXD",
    city_name: "Delingha",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Delissaville Airport",
    longitude: 130.688701,
    latitude: -12.552238,
    id: "arp_dlv_au",
    icao_code: "YDLV",
    iata_country_code: "AU",
    iata_code: "DLV",
    city_name: "Delissaville",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Delma Island Airport",
    longitude: 52.334969,
    latitude: 24.506972,
    id: "arp_zdy_ae",
    icao_code: "OMDL",
    iata_country_code: "AE",
    iata_code: "ZDY",
    city_name: "Delma Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Del Norte County Regional Airport",
    longitude: -124.237017,
    latitude: 41.778184,
    id: "arp_cec_us",
    icao_code: "KCEC",
    iata_country_code: "US",
    iata_code: "CEC",
    city_name: "Crescent City",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Del Norte International Airport",
    longitude: -100.238718,
    latitude: 25.86573,
    id: "arp_ntr_mx",
    icao_code: "MMAN",
    iata_country_code: "MX",
    iata_code: "NTR",
    city_name: "Monterrey",
    city: {
      name: "Monterrey",
      id: "cit_mty_mx",
      iata_country_code: "MX",
      iata_code: "MTY",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Del Rio International Airport",
    longitude: -100.923607,
    latitude: 29.372393,
    id: "arp_drt_us",
    icao_code: "KDRT",
    iata_country_code: "US",
    iata_code: "DRT",
    city_name: "Del Rio",
    city: {
      name: "Del Rio",
      id: "cit_drt_us",
      iata_country_code: "US",
      iata_code: "DRT",
    },
  },
  {
    time_zone: "America/Detroit",
    name: "Delta County Airport",
    longitude: -87.091953,
    latitude: 45.720243,
    id: "arp_esc_us",
    icao_code: "KESC",
    iata_country_code: "US",
    iata_code: "ESC",
    city_name: "Escanaba",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Delta Downs Airport",
    longitude: 141.319067,
    latitude: -16.99192,
    id: "arp_ddn_au",
    icao_code: "YDLT",
    iata_country_code: "AU",
    iata_code: "DDN",
    city_name: "Delta Downs",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Delta Junction Airport",
    longitude: -145.725198,
    latitude: 64.050932,
    id: "arp_djn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DJN",
    city_name: "Delta Junction",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Delta Municipal Airport",
    longitude: -112.509206,
    latitude: 39.38306,
    id: "arp_dta_us",
    icao_code: "KDTA",
    iata_country_code: "US",
    iata_code: "DTA",
    city_name: "Delta",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Dembidolo Airport",
    longitude: 34.858604,
    latitude: 8.553064,
    id: "arp_dem_et",
    icao_code: "HADD",
    iata_country_code: "ET",
    iata_code: "DEM",
    city_name: "Dembi Dolo",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Deming Municipal Airport",
    longitude: -107.722978,
    latitude: 32.263992,
    id: "arp_dmn_us",
    icao_code: "KDMN",
    iata_country_code: "US",
    iata_code: "DMN",
    city_name: "Deming",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Denham Airport",
    longitude: 113.576459,
    latitude: -25.894161,
    id: "arp_dnm_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "DNM",
    city_name: "Denham",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Deniliquin Airport",
    longitude: 144.948829,
    latitude: -35.559699,
    id: "arp_dnq_au",
    icao_code: "YDLQ",
    iata_country_code: "AU",
    iata_code: "DNQ",
    city_name: "Deniliquin",
    city: null,
  },
  {
    time_zone: "Indian/Mahe",
    name: "Denis Island Airport",
    longitude: 55.667412,
    latitude: -3.802314,
    id: "arp_dei_sc",
    icao_code: "FSSD",
    iata_country_code: "SC",
    iata_code: "DEI",
    city_name: "Denis Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Denison Municipal Airport",
    longitude: -95.379717,
    latitude: 41.987109,
    id: "arp_dns_us",
    icao_code: "KDNS",
    iata_country_code: "US",
    iata_code: "DNS",
    city_name: "Denison",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Denizli Çardak Airport",
    longitude: 29.701293,
    latitude: 37.785564,
    id: "arp_dnz_tr",
    icao_code: "LTAY",
    iata_country_code: "TR",
    iata_code: "DNZ",
    city_name: "Denizli",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Denver International Airport",
    longitude: -104.673367,
    latitude: 39.858897,
    id: "arp_den_us",
    icao_code: "KDEN",
    iata_country_code: "US",
    iata_code: "DEN",
    city_name: "Denver",
    city: {
      name: "Denver",
      id: "cit_den_us",
      iata_country_code: "US",
      iata_code: "DEN",
    },
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Depati Amir Airport",
    longitude: 106.139347,
    latitude: -2.160825,
    id: "arp_pgk_id",
    icao_code: "WIPK",
    iata_country_code: "ID",
    iata_code: "PGK",
    city_name: "Pangkal Pinang",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Deputatsky Airport",
    longitude: 139.89526,
    latitude: 69.392535,
    id: "arp_dpt_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "DPT",
    city_name: "Deputatsky",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Dera Ghazi Khan International Airport",
    longitude: 70.487209,
    latitude: 29.961034,
    id: "arp_dea_pk",
    icao_code: "OPDG",
    iata_country_code: "PK",
    iata_code: "DEA",
    city_name: "Dera Ghazi Khan",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Dera Ghazi Khan International Airport",
    longitude: 70.490607,
    latitude: 29.960734,
    id: "arp_dgk_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "DGK",
    city_name: "Dugong Beach Lodge",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Dera Ismail Khan Airport",
    longitude: 70.8922,
    latitude: 31.910236,
    id: "arp_dsk_pk",
    icao_code: "OPDI",
    iata_country_code: "PK",
    iata_code: "DSK",
    city_name: "Dera Ismail Khan",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Derby Airport",
    longitude: 123.663033,
    latitude: -17.370275,
    id: "arp_drb_au",
    icao_code: "YDBY",
    iata_country_code: "AU",
    iata_code: "DRB",
    city_name: "Derby",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Derby Field",
    longitude: -118.565055,
    latitude: 40.066399,
    id: "arp_lol_us",
    icao_code: "KLOL",
    iata_country_code: "US",
    iata_code: "LOL",
    city_name: "Lovelock",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Derim Airport",
    longitude: 147.107399,
    latitude: -6.144727,
    id: "arp_der_pg",
    icao_code: "AYDE",
    iata_country_code: "PG",
    iata_code: "DER",
    city_name: "Derim",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Desert Rock Airport",
    longitude: -116.032807,
    latitude: 36.621633,
    id: "arp_dra_us",
    icao_code: "KDRA",
    iata_country_code: "US",
    iata_code: "DRA",
    city_name: "Mercury",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Des Moines International Airport",
    longitude: -93.660948,
    latitude: 41.534066,
    id: "arp_dsm_us",
    icao_code: "KDSM",
    iata_country_code: "US",
    iata_code: "DSM",
    city_name: "Des Moines",
    city: null,
  },
  {
    time_zone: "Indian/Mahe",
    name: "Desroches Airport",
    longitude: 53.655623,
    latitude: -5.696589,
    id: "arp_des_sc",
    icao_code: "FSDR",
    iata_country_code: "SC",
    iata_code: "DES",
    city_name: "Desroches Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Destin Executive Airport",
    longitude: -86.471324,
    latitude: 30.398953,
    id: "arp_dsi_us",
    icao_code: "KDTS",
    iata_country_code: "US",
    iata_code: "DSI",
    city_name: "Destin",
    city: {
      name: "Destin/Ft Walton Beach",
      id: "cit_vps_us",
      iata_country_code: "US",
      iata_code: "VPS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Destin-Fort Walton Beach Airport",
    longitude: -86.550619,
    latitude: 30.494142,
    id: "arp_vps_us",
    icao_code: "KVPS",
    iata_country_code: "US",
    iata_code: "VPS",
    city_name: "Valparaiso",
    city: {
      name: "Destin/Ft Walton Beach",
      id: "cit_vps_us",
      iata_country_code: "US",
      iata_code: "VPS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Detroit Lakes Airport",
    longitude: -95.885323,
    latitude: 46.826972,
    id: "arp_dtl_us",
    icao_code: "KDTL",
    iata_country_code: "US",
    iata_code: "DTL",
    city_name: "Detroit Lakes",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Detroit Metro Airport",
    longitude: -83.354393,
    latitude: 42.214285,
    id: "arp_dtw_us",
    icao_code: "KDTW",
    iata_country_code: "US",
    iata_code: "DTW",
    city_name: "Detroit",
    city: {
      name: "Detroit",
      id: "cit_dtt_us",
      iata_country_code: "US",
      iata_code: "DTT",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Devi Ahilya Bai Holkar Airport",
    longitude: 75.802803,
    latitude: 22.724768,
    id: "arp_idr_in",
    icao_code: "VAID",
    iata_country_code: "IN",
    iata_code: "IDR",
    city_name: "Indore",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Devils Lake Regional Airport",
    longitude: -98.906347,
    latitude: 48.111632,
    id: "arp_dvl_us",
    icao_code: "KDVL",
    iata_country_code: "US",
    iata_code: "DVL",
    city_name: "Devils Lake",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Devonport Airport",
    longitude: 146.42878,
    latitude: -41.170827,
    id: "arp_dpo_au",
    icao_code: "YDPO",
    iata_country_code: "AU",
    iata_code: "DPO",
    city_name: "Devonport",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Dewadaru Airport",
    longitude: 110.478201,
    latitude: -5.801359,
    id: "arp_kwb_id",
    icao_code: "WAHU",
    iata_country_code: "ID",
    iata_code: "KWB",
    city_name: "Karimunjawa",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Dezful Airport",
    longitude: 48.384221,
    latitude: 32.438403,
    id: "arp_def_ir",
    icao_code: "OIAD",
    iata_country_code: "IR",
    iata_code: "DEF",
    city_name: "Dezful",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Dhaalu Atoll Airport",
    longitude: 72.889976,
    latitude: 2.666721,
    id: "arp_ddd_mv",
    icao_code: null,
    iata_country_code: "MV",
    iata_code: "DDD",
    city_name: "Kudahuvadhoo",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Dhaka Shahjalal International Airport",
    longitude: 90.400279,
    latitude: 23.844185,
    id: "arp_dac_bd",
    icao_code: "VGHS",
    iata_country_code: "BD",
    iata_code: "DAC",
    city_name: "Dhaka",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Dhala Airport",
    longitude: 44.138889,
    latitude: 13.685833,
    id: "arp_dhl_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "DHL",
    city_name: "Dhala",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Dhamar Airport",
    longitude: 44.4333,
    latitude: 14.5333,
    id: "arp_dmr_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "DMR",
    city_name: "Dhamar",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Dhanbad Airport",
    longitude: 86.425653,
    latitude: 23.833974,
    id: "arp_dbd_in",
    icao_code: "VEDB",
    iata_country_code: "IN",
    iata_code: "DBD",
    city_name: "Dhanbad",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Dhangadhi Airport",
    longitude: 80.582126,
    latitude: 28.753971,
    id: "arp_dhi_np",
    icao_code: "VNDH",
    iata_country_code: "NP",
    iata_code: "DHI",
    city_name: "Dhangarhi",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Dharamsala Gaggal Kangra Airport",
    longitude: 76.262333,
    latitude: 32.16509,
    id: "arp_dhm_in",
    icao_code: "VIGG",
    iata_country_code: "IN",
    iata_code: "DHM",
    city_name: "Kangra",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Dharavandhoo Airport",
    longitude: 73.131473,
    latitude: 5.155741,
    id: "arp_drv_mv",
    icao_code: "VRMD",
    iata_country_code: "MV",
    iata_code: "DRV",
    city_name: "Roseau",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Diamantina Airport",
    longitude: -43.649999,
    latitude: -18.231554,
    id: "arp_dti_br",
    icao_code: "SNDT",
    iata_country_code: "BR",
    iata_code: "DTI",
    city_name: "Diamantina",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Diamantina Lakes Airport",
    longitude: 141.144771,
    latitude: -23.759648,
    id: "arp_dym_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "DYM",
    city_name: "Diamantina Lakes",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Diamantino Airport",
    longitude: -56.399069,
    latitude: -14.376338,
    id: "arp_dmt_br",
    icao_code: "SWDM",
    iata_country_code: "BR",
    iata_code: "DMT",
    city_name: "Diamantino",
    city: null,
  },
  {
    time_zone: "America/Araguaina",
    name: "Dianópolis Airport",
    longitude: -46.847421,
    latitude: -11.595423,
    id: "arp_dno_br",
    icao_code: "SWDN",
    iata_country_code: "BR",
    iata_code: "DNO",
    city_name: "Dianopolis",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Diapaga Airport",
    longitude: 1.785865,
    latitude: 12.0614,
    id: "arp_dip_bf",
    icao_code: "DFED",
    iata_country_code: "BF",
    iata_code: "DIP",
    city_name: "Diapaga",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Diavik Airport",
    longitude: -110.291214,
    latitude: 64.511524,
    id: "arp_dvk_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "DVK",
    city_name: "Diavik",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Dibba Airport",
    longitude: 56.245267,
    latitude: 25.614476,
    id: "arp_byb_om",
    icao_code: null,
    iata_country_code: "OM",
    iata_code: "BYB",
    city_name: "Dibba Al-Baya",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Dibrugarh Airport",
    longitude: 95.017828,
    latitude: 27.484603,
    id: "arp_dib_in",
    icao_code: "VEMN",
    iata_country_code: "IN",
    iata_code: "DIB",
    city_name: "Dibrugarh",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Dickinson Theodore Roosevelt Regional Airport",
    longitude: -102.797423,
    latitude: 46.79965,
    id: "arp_dik_us",
    icao_code: "KDIK",
    iata_country_code: "US",
    iata_code: "DIK",
    city_name: "Dickinson",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Diebougou Airport",
    longitude: -3.250076,
    latitude: 10.948072,
    id: "arp_xde_bf",
    icao_code: "DFOU",
    iata_country_code: "BF",
    iata_code: "XDE",
    city_name: "Diebougou",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Diego Jimenez Torres Airport",
    longitude: -65.662996,
    latitude: 18.308908,
    id: "arp_faj_pr",
    icao_code: "TJFA",
    iata_country_code: "PR",
    iata_code: "FAJ",
    city_name: "Fajardo",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Dien Bien Phu Airport",
    longitude: 103.006899,
    latitude: 21.398248,
    id: "arp_din_vn",
    icao_code: "VVDB",
    iata_country_code: "VN",
    iata_code: "DIN",
    city_name: "Dien Bien Phu",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Digby / Annapolis Regional Airport",
    longitude: -65.785411,
    latitude: 44.546818,
    id: "arp_ydg_ca",
    icao_code: "CYID",
    iata_country_code: "CA",
    iata_code: "YDG",
    city_name: "Digby",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Dijon-Bourgogne Airport",
    longitude: 5.088056,
    latitude: 47.270798,
    id: "arp_dij_fr",
    icao_code: "LFSD",
    iata_country_code: "FR",
    iata_code: "DIJ",
    city_name: "Dijon",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Dikson Airport",
    longitude: 80.380231,
    latitude: 73.517637,
    id: "arp_dks_ru",
    icao_code: "UODD",
    iata_country_code: "RU",
    iata_code: "DKS",
    city_name: "Dikson",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Dilasag Airport",
    longitude: 121.754411,
    latitude: 16.928722,
    id: "arp_dsg_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "DSG",
    city_name: "Dilasag",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dillant-Hopkins Airport",
    longitude: -72.270967,
    latitude: 42.901056,
    id: "arp_een_us",
    icao_code: "KEEN",
    iata_country_code: "US",
    iata_code: "EEN",
    city_name: "Keene",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Dillingham Airfield",
    longitude: -158.208801,
    latitude: 21.579899,
    id: "arp_hdh_us",
    icao_code: "PHDH",
    iata_country_code: "US",
    iata_code: "HDH",
    city_name: "Mokuleia",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Dillingham Airport",
    longitude: -158.50557,
    latitude: 59.044206,
    id: "arp_dlg_us",
    icao_code: "PADL",
    iata_country_code: "US",
    iata_code: "DLG",
    city_name: "Dillingham",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Dillon Airport",
    longitude: -112.552666,
    latitude: 45.255321,
    id: "arp_dln_us",
    icao_code: "KDLN",
    iata_country_code: "US",
    iata_code: "DLN",
    city_name: "Dillon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dillon County Airport",
    longitude: -79.370338,
    latitude: 34.448739,
    id: "arp_dll_us",
    icao_code: "KDLC",
    iata_country_code: "US",
    iata_code: "DLL",
    city_name: "Dillon",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Dillon's Bay Airport",
    longitude: 169.000932,
    latitude: -18.768985,
    id: "arp_dly_vu",
    icao_code: "NVVD",
    iata_country_code: "VU",
    iata_code: "DLY",
    city_name: "Dillon's Bay",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Dimapur Airport",
    longitude: 93.77222,
    latitude: 25.882465,
    id: "arp_dmu_in",
    icao_code: "VEMR",
    iata_country_code: "IN",
    iata_code: "DMU",
    city_name: "Dimapur",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Dimbokro Airport",
    longitude: -4.643456,
    latitude: 6.656829,
    id: "arp_dim_ci",
    icao_code: "DIDK",
    iata_country_code: "CI",
    iata_code: "DIM",
    city_name: "Dimbokro",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dimmit County Airport",
    longitude: -99.827433,
    latitude: 28.523082,
    id: "arp_czt_us",
    icao_code: "KCZT",
    iata_country_code: "US",
    iata_code: "CZT",
    city_name: "Carrizo Springs",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dinangat Airport",
    longitude: 146.667,
    latitude: -6.15,
    id: "arp_dnu_pg",
    icao_code: "AYDN",
    iata_country_code: "PG",
    iata_code: "DNU",
    city_name: "Dinangat",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Dinard–Pleurtuit–Saint-Malo Airport",
    longitude: -2.079179,
    latitude: 48.588186,
    id: "arp_dnr_fr",
    icao_code: "LFRD",
    iata_country_code: "FR",
    iata_code: "DNR",
    city_name: "Dinard",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dinwiddie County Airport",
    longitude: -77.505666,
    latitude: 37.18418,
    id: "arp_ptb_us",
    icao_code: "KPTB",
    iata_country_code: "US",
    iata_code: "PTB",
    city_name: "Petersburg",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Diomício Freitas Airport",
    longitude: -49.421856,
    latitude: -28.724592,
    id: "arp_ccm_br",
    icao_code: "SBCM",
    iata_country_code: "BR",
    iata_code: "CCM",
    city_name: "Criciúma",
    city: null,
  },
  {
    time_zone: "Africa/Niamey",
    name: "Diori Hamani International Airport",
    longitude: 2.181869,
    latitude: 13.480796,
    id: "arp_nim_ne",
    icao_code: "DRRN",
    iata_country_code: "NE",
    iata_code: "NIM",
    city_name: "Niamey",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dios Airport",
    longitude: 154.967,
    latitude: -5.55,
    id: "arp_dos_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "DOS",
    city_name: "Dios",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Dipolog Airport",
    longitude: 123.3425,
    latitude: 8.601367,
    id: "arp_dpl_ph",
    icao_code: "RPMG",
    iata_country_code: "PH",
    iata_code: "DPL",
    city_name: "Dipolog",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Diqing Shangri-La Airport",
    longitude: 99.67953,
    latitude: 27.793373,
    id: "arp_dig_cn",
    icao_code: "ZPDQ",
    iata_country_code: "CN",
    iata_code: "DIG",
    city_name: "Shangri-La",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Dirico Airport",
    longitude: 20.767944,
    latitude: -17.982021,
    id: "arp_drc_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "DRC",
    city_name: "Dirico",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dirranbandi Airport",
    longitude: 148.219222,
    latitude: -28.588079,
    id: "arp_drn_au",
    icao_code: "YDBI",
    iata_country_code: "AU",
    iata_code: "DRN",
    city_name: "Dirranbandi",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Diu Airport",
    longitude: 70.92262,
    latitude: 20.713307,
    id: "arp_diu_in",
    icao_code: "VA1P",
    iata_country_code: "IN",
    iata_code: "DIU",
    city_name: "Diu",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Divinópolis Airport",
    longitude: -44.87121,
    latitude: -20.178915,
    id: "arp_diq_br",
    icao_code: "SNDV",
    iata_country_code: "BR",
    iata_code: "DIQ",
    city_name: "Divinopolis",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Divo Airport",
    longitude: -5.383397,
    latitude: 5.83268,
    id: "arp_div_ci",
    icao_code: "DIDV",
    iata_country_code: "CI",
    iata_code: "DIV",
    city_name: "Divo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dixie Airport",
    longitude: 143.315543,
    latitude: -15.117345,
    id: "arp_dxd_au",
    icao_code: "YDIX",
    iata_country_code: "AU",
    iata_code: "DXD",
    city_name: "Dixie",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Diyarbakır Airport",
    longitude: 40.18632,
    latitude: 37.900484,
    id: "arp_diy_tr",
    icao_code: "LTCC",
    iata_country_code: "TR",
    iata_code: "DIY",
    city_name: "Diyarbakır",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Diyawanna Oya Seaplane Base Airport",
    longitude: 79.913584,
    latitude: 6.908652,
    id: "arp_dwo_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "DWO",
    city_name: "Sri Jayawardenepura Kotte/Kotte",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Djambala Airport",
    longitude: 14.752638,
    latitude: -2.520023,
    id: "arp_djm_cg",
    icao_code: "FCBD",
    iata_country_code: "CG",
    iata_code: "DJM",
    city_name: "Djambala",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Djanet Inedbirene Airport",
    longitude: 9.458434,
    latitude: 24.288213,
    id: "arp_djg_dz",
    icao_code: "DAAJ",
    iata_country_code: "DZ",
    iata_code: "DJG",
    city_name: "Djanet",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Djerba-Zarzis International Airport",
    longitude: 10.775499,
    latitude: 33.87302,
    id: "arp_dje_tn",
    icao_code: "DTTJ",
    iata_country_code: "TN",
    iata_code: "DJE",
    city_name: "Djerba",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Djibo Airport",
    longitude: -1.624604,
    latitude: 14.125655,
    id: "arp_xdj_bf",
    icao_code: "DFCJ",
    iata_country_code: "BF",
    iata_code: "XDJ",
    city_name: "Djibo",
    city: null,
  },
  {
    time_zone: "Africa/Djibouti",
    name: "Djibouti-Ambouli International Airport",
    longitude: 43.158073,
    latitude: 11.547823,
    id: "arp_jib_dj",
    icao_code: "HDAM",
    iata_country_code: "DJ",
    iata_code: "JIB",
    city_name: "Djibouti",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Djoemoe Airport",
    longitude: -55.479439,
    latitude: 4.008215,
    id: "arp_doe_sr",
    icao_code: null,
    iata_country_code: "SR",
    iata_code: "DOE",
    city_name: "Djoemoe",
    city: null,
  },
  {
    time_zone: "Africa/Porto-Novo",
    name: "Djougou Airport",
    longitude: 1.637688,
    latitude: 9.692041,
    id: "arp_dja_bj",
    icao_code: "DBBD",
    iata_country_code: "BJ",
    iata_code: "DJA",
    city_name: "Djougou",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Djúpivogur Airport",
    longitude: -14.276919,
    latitude: 64.643037,
    id: "arp_dju_is",
    icao_code: "BIDV",
    iata_country_code: "IS",
    iata_code: "DJU",
    city_name: "Djúpivogur",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Dnipropetrovsk International Airport",
    longitude: 35.093605,
    latitude: 48.367512,
    id: "arp_dnk_ua",
    icao_code: "UKDD",
    iata_country_code: "UA",
    iata_code: "DNK",
    city_name: "Dnipropetrovsk",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Doany Airport",
    longitude: 49.510435,
    latitude: -14.367529,
    id: "arp_doa_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "DOA",
    city_name: "Doany",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dobbins Air Reserve Base",
    longitude: -84.516296,
    latitude: 33.915401,
    id: "arp_mge_us",
    icao_code: "KMGE",
    iata_country_code: "US",
    iata_code: "MGE",
    city_name: "Marietta",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Docker River Airport",
    longitude: 129.070007,
    latitude: -24.86,
    id: "arp_dkv_au",
    icao_code: "YDVR",
    iata_country_code: "AU",
    iata_code: "DKV",
    city_name: "Docker River",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dodge City Regional Airport",
    longitude: -99.966032,
    latitude: 37.764225,
    id: "arp_ddc_us",
    icao_code: "KDDC",
    iata_country_code: "US",
    iata_code: "DDC",
    city_name: "Dodge City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dodge County Airport",
    longitude: -88.700639,
    latitude: 43.427735,
    id: "arp_unu_us",
    icao_code: "KUNU",
    iata_country_code: "US",
    iata_code: "UNU",
    city_name: "Juneau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dodoima Airport",
    longitude: 147.849544,
    latitude: -8.166888,
    id: "arp_ddm_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "DDM",
    city_name: "Dodoima",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Dodoma Airport",
    longitude: 35.751723,
    latitude: -6.170066,
    id: "arp_dod_tz",
    icao_code: "HTDO",
    iata_country_code: "TZ",
    iata_code: "DOD",
    city_name: "Dodoma",
    city: null,
  },
  {
    time_zone: "Asia/Qatar",
    name: "Doha International Airport",
    longitude: 51.565102,
    latitude: 25.261101,
    id: "arp_dia_qa",
    icao_code: "OTBD",
    iata_country_code: "QA",
    iata_code: "DIA",
    city_name: "Doha",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Doini Airport",
    longitude: 150.720166,
    latitude: -10.697983,
    id: "arp_doi_pg",
    icao_code: "AYDO",
    iata_country_code: "PG",
    iata_code: "DOI",
    city_name: "Doini Island",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Dolbeau-Saint-Félicien Airport",
    longitude: -72.375279,
    latitude: 48.778416,
    id: "arp_ydo_ca",
    icao_code: "CYDO",
    iata_country_code: "CA",
    iata_code: "YDO",
    city_name: "Dolbeau",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Dole-Jura Airport",
    longitude: 5.429688,
    latitude: 47.043103,
    id: "arp_dle_fr",
    icao_code: "LFGJ",
    iata_country_code: "FR",
    iata_code: "DLE",
    city_name: "Dole",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Dolisie Airport",
    longitude: 12.66,
    latitude: -4.206452,
    id: "arp_dis_cg",
    icao_code: "FCPL",
    iata_country_code: "CG",
    iata_code: "DIS",
    city_name: "Dolisie",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Dolpa Airport",
    longitude: 82.819044,
    latitude: 28.985617,
    id: "arp_dop_np",
    icao_code: "VNDP",
    iata_country_code: "NP",
    iata_code: "DOP",
    city_name: "Dolpa",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Domine Eduard Osok Airport",
    longitude: 131.288776,
    latitude: -0.892559,
    id: "arp_soq_id",
    icao_code: "WASS",
    iata_country_code: "ID",
    iata_code: "SOQ",
    city_name: "Sorong",
    city: null,
  },
  {
    time_zone: "America/Argentina/San_Juan",
    name: "Domingo Faustino Sarmiento Airport",
    longitude: -68.419654,
    latitude: -31.571605,
    id: "arp_uaq_ar",
    icao_code: "SANU",
    iata_country_code: "AR",
    iata_code: "UAQ",
    city_name: "San Juan",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Domodedovo International Airport",
    longitude: 37.904375,
    latitude: 55.409552,
    id: "arp_dme_ru",
    icao_code: "UUDD",
    iata_country_code: "RU",
    iata_code: "DME",
    city_name: "Moscow",
    city: {
      name: "Moscow",
      id: "cit_mow_ru",
      iata_country_code: "RU",
      iata_code: "MOW",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Donaldson Field Airport",
    longitude: -82.376156,
    latitude: 34.758418,
    id: "arp_gdc_us",
    icao_code: "KGYH",
    iata_country_code: "US",
    iata_code: "GDC",
    city_name: "Greenville",
    city: {
      name: "Greenville/Spartanburg",
      id: "cit_gsp_us",
      iata_country_code: "US",
      iata_code: "GSP",
    },
  },
  {
    time_zone: "Europe/London",
    name: "Doncaster Sheffield Airport",
    longitude: -1.009459,
    latitude: 53.480897,
    id: "arp_dsa_gb",
    icao_code: "EGCN",
    iata_country_code: "GB",
    iata_code: "DSA",
    city_name: "Doncaster",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Donegal Airport",
    longitude: -8.341788,
    latitude: 55.041188,
    id: "arp_cfn_ie",
    icao_code: "EIDL",
    iata_country_code: "IE",
    iata_code: "CFN",
    city_name: "Donegal",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Donetsk International Airport",
    longitude: 37.740449,
    latitude: 48.071975,
    id: "arp_dok_ua",
    icao_code: "UKCC",
    iata_country_code: "UA",
    iata_code: "DOK",
    city_name: "Donetsk",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Dongara Airport",
    longitude: 114.927181,
    latitude: -29.299893,
    id: "arp_dox_au",
    icao_code: "YDRA",
    iata_country_code: "AU",
    iata_code: "DOX",
    city_name: "Dongara",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Dong Hoi Airport",
    longitude: 106.590389,
    latitude: 17.514608,
    id: "arp_vdh_vn",
    icao_code: "VVDH",
    iata_country_code: "VN",
    iata_code: "VDH",
    city_name: "Dong Hoi",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Dongola Airport",
    longitude: 30.430066,
    latitude: 19.153433,
    id: "arp_dog_sd",
    icao_code: "HSDN",
    iata_country_code: "SD",
    iata_code: "DOG",
    city_name: "Dongola",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Dongsha Island Airport",
    longitude: 116.720593,
    latitude: 20.706172,
    id: "arp_dsx_tw",
    icao_code: "RCLM",
    iata_country_code: "TW",
    iata_code: "DSX",
    city_name: "Dongsha Island",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Dong Tac Airport",
    longitude: 109.343715,
    latitude: 13.049717,
    id: "arp_tbb_vn",
    icao_code: "VVTH",
    iata_country_code: "VN",
    iata_code: "TBB",
    city_name: "Tuy Hoa",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dongying Shengli Airport",
    longitude: 118.790742,
    latitude: 37.509257,
    id: "arp_doy_cn",
    icao_code: "ZSDY",
    iata_country_code: "CN",
    iata_code: "DOY",
    city_name: "Dongying",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Don Mueang International Airport",
    longitude: 100.6056,
    latitude: 13.912929,
    id: "arp_dmk_th",
    icao_code: "VTBD",
    iata_country_code: "TH",
    iata_code: "DMK",
    city_name: "Bangkok",
    city: {
      name: "Bangkok",
      id: "cit_bkk_th",
      iata_country_code: "TH",
      iata_code: "BKK",
    },
  },
  {
    time_zone: "America/Edmonton",
    name: "Donnelly Airport",
    longitude: -117.093619,
    latitude: 55.709577,
    id: "arp_yoe_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YOE",
    city_name: "Donnelly",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Donoi Airport",
    longitude: 96.526112,
    latitude: 47.709973,
    id: "arp_ulz_mn",
    icao_code: "ZMDN",
    iata_country_code: "MN",
    iata_code: "ULZ",
    city_name: "Uliastai",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Doomadgee Airport",
    longitude: 138.821708,
    latitude: -17.939746,
    id: "arp_dmd_au",
    icao_code: "YDMG",
    iata_country_code: "AU",
    iata_code: "DMD",
    city_name: "Doomadgee",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Doongan Airport",
    longitude: 126.302235,
    latitude: -15.386429,
    id: "arp_dng_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "DNG",
    city_name: "Doongan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Door County Cherryland Airport",
    longitude: -87.4239,
    latitude: 44.841887,
    id: "arp_sue_us",
    icao_code: "KSUE",
    iata_country_code: "US",
    iata_code: "SUE",
    city_name: "Sturgeon Bay",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Do Palmar Airport",
    longitude: -41.188026,
    latitude: -20.836335,
    id: "arp_ctq_br",
    icao_code: "SSVP",
    iata_country_code: "BR",
    iata_code: "CTQ",
    city_name: "Santa Vitoria Palmar",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Dori Airport",
    longitude: -0.030963,
    latitude: 14.019904,
    id: "arp_dor_bf",
    icao_code: "DFEE",
    iata_country_code: "BF",
    iata_code: "DOR",
    city_name: "Dori",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Doris Lake Airport",
    longitude: -106.585281,
    latitude: 68.125275,
    id: "arp_joj_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "JOJ",
    city_name: "Hope Bay",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Dornoch Airport",
    longitude: -4.023222,
    latitude: 57.86915,
    id: "arp_doc_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "DOC",
    city_name: "Dornoch",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dorobisoro Airport",
    longitude: 147.920693,
    latitude: -9.46187,
    id: "arp_doo_pg",
    icao_code: "AYDR",
    iata_country_code: "PG",
    iata_code: "DOO",
    city_name: "Dorobisoro",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Dortmund Airport",
    longitude: 7.61247,
    latitude: 51.517205,
    id: "arp_dtm_de",
    icao_code: "EDLW",
    iata_country_code: "DE",
    iata_code: "DTM",
    city_name: "Dortmund",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dorunda Airport",
    longitude: 141.824007,
    latitude: -16.553759,
    id: "arp_drd_au",
    icao_code: "YDOR",
    iata_country_code: "AU",
    iata_code: "DRD",
    city_name: "Dorunda",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Dos Lagunas Airport",
    longitude: -89.524152,
    latitude: 17.691953,
    id: "arp_don_gt",
    icao_code: null,
    iata_country_code: "GT",
    iata_code: "DON",
    city_name: "Dos Lagunas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dothan Regional Airport",
    longitude: -85.449238,
    latitude: 31.320312,
    id: "arp_dhn_us",
    icao_code: "KDHN",
    iata_country_code: "US",
    iata_code: "DHN",
    city_name: "Dothan",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Douala International Airport",
    longitude: 9.718246,
    latitude: 4.009764,
    id: "arp_dla_cm",
    icao_code: "FKKD",
    iata_country_code: "CM",
    iata_code: "DLA",
    city_name: "Douala",
    city: null,
  },
  {
    time_zone: "America/Dominica",
    name: "Douglas-Charles Airport",
    longitude: -61.300447,
    latitude: 15.546707,
    id: "arp_dom_dm",
    icao_code: "TDPD",
    iata_country_code: "DM",
    iata_code: "DOM",
    city_name: "Dominica",
    city: {
      name: "Dominica",
      id: "cit_dom_dm",
      iata_country_code: "DM",
      iata_code: "DOM",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Douglas Lake Airport",
    longitude: -120.177947,
    latitude: 50.166316,
    id: "arp_dgf_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "DGF",
    city_name: "Douglas Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Douglas Municipal Airport",
    longitude: -82.860614,
    latitude: 31.476542,
    id: "arp_dgl_us",
    icao_code: "KDGL",
    iata_country_code: "US",
    iata_code: "DGL",
    city_name: "Douglas",
    city: {
      name: "Douglas/Bisbee",
      id: "cit_dug_us",
      iata_country_code: "US",
      iata_code: "DUG",
    },
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Dourados Airport",
    longitude: -54.925593,
    latitude: -22.199913,
    id: "arp_dou_br",
    icao_code: "SBDO",
    iata_country_code: "BR",
    iata_code: "DOU",
    city_name: "Dourados",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Dover Air Force Base",
    longitude: -75.468564,
    latitude: 39.127231,
    id: "arp_dov_us",
    icao_code: "KDOV",
    iata_country_code: "US",
    iata_code: "DOV",
    city_name: "Dover",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Doylestown Airport",
    longitude: -75.122464,
    latitude: 40.333325,
    id: "arp_dyl_us",
    icao_code: "KDYL",
    iata_country_code: "US",
    iata_code: "DYL",
    city_name: "Doylestown",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Drake Bay Airport",
    longitude: -83.640183,
    latitude: 8.718877,
    id: "arp_drk_cr",
    icao_code: "MRDK",
    iata_country_code: "CR",
    iata_code: "DRK",
    city_name: "Drake Bay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Drake Field",
    longitude: -94.170714,
    latitude: 36.004539,
    id: "arp_fyv_us",
    icao_code: "KFYV",
    iata_country_code: "US",
    iata_code: "FYV",
    city_name: "Fayetteville",
    city: {
      name: "Fayetteville/Springd.",
      id: "cit_fyv_us",
      iata_country_code: "US",
      iata_code: "FYV",
    },
  },
  {
    time_zone: "America/Caracas",
    name: "Dr. Antonio Nicolás Briceño Airport",
    longitude: -70.583951,
    latitude: 9.34132,
    id: "arp_vlv_ve",
    icao_code: "SVVL",
    iata_country_code: "VE",
    iata_code: "VLV",
    city_name: "Valera",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Dr. Arturo Umberto Illia Airport",
    longitude: -67.617906,
    latitude: -39.001332,
    id: "arp_gnr_ar",
    icao_code: "SAHR",
    iata_country_code: "AR",
    iata_code: "GNR",
    city_name: "General Roca",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Draughon-Miller Central Texas Regional Airport",
    longitude: -97.409635,
    latitude: 31.152283,
    id: "arp_tpl_us",
    icao_code: "KTPL",
    iata_country_code: "US",
    iata_code: "TPL",
    city_name: "Temple",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Dr. Augusto Roberto Fuster International Airport",
    longitude: -55.830844,
    latitude: -22.641213,
    id: "arp_pjc_py",
    icao_code: "SGPJ",
    iata_country_code: "PY",
    iata_code: "PJC",
    city_name: "Pedro Juan Caballero",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Drayton Valley Industrial Airport",
    longitude: -114.959905,
    latitude: 53.265718,
    id: "arp_ydc_ca",
    icao_code: "CYDC",
    iata_country_code: "CA",
    iata_code: "YDC",
    city_name: "Drayton Valley",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Dr. Babasaheb Ambedkar International Airport",
    longitude: 79.050968,
    latitude: 21.09151,
    id: "arp_nag_in",
    icao_code: "VANP",
    iata_country_code: "IN",
    iata_code: "NAG",
    city_name: "Nagpur",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Dresden Airport",
    longitude: 13.766922,
    latitude: 51.130521,
    id: "arp_drs_de",
    icao_code: "EDDC",
    iata_country_code: "DE",
    iata_code: "DRS",
    city_name: "Dresden",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Dr. Ferdinand Lumban Tobing Airport",
    longitude: 98.891449,
    latitude: 1.555209,
    id: "arp_flz_id",
    icao_code: "WIMS",
    iata_country_code: "ID",
    iata_code: "FLZ",
    city_name: "Sibolga",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Drietabbetje Airport",
    longitude: -54.672766,
    latitude: 4.111359,
    id: "arp_drj_sr",
    icao_code: "SMDA",
    iata_country_code: "SR",
    iata_code: "DRJ",
    city_name: "Drietabbetje",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Drift River Airport",
    longitude: -152.16199,
    latitude: 60.588885,
    id: "arp_drf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DRF",
    city_name: "Drift River",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Dr. Juan C. Angara Airport",
    longitude: 121.499967,
    latitude: 15.729528,
    id: "arp_bqa_ph",
    icao_code: "RPUR",
    iata_country_code: "PH",
    iata_code: "BQA",
    city_name: "Baler",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Dr Juan Plate Airport",
    longitude: -57.942495,
    latitude: -22.159264,
    id: "arp_vmi_py",
    icao_code: null,
    iata_country_code: "PY",
    iata_code: "VMI",
    city_name: "Puerto Vallemi",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Dr. Luis María Argaña International Airport",
    longitude: -60.620091,
    latitude: -22.048147,
    id: "arp_esg_py",
    icao_code: "SGME",
    iata_country_code: "PY",
    iata_code: "ESG",
    city_name: "Mariscal Estigarribia",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Drumduff Airport",
    longitude: 143.012637,
    latitude: -16.052552,
    id: "arp_dfp_au",
    icao_code: "YDDF",
    iata_country_code: "AU",
    iata_code: "DFP",
    city_name: "Drumduff",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Drummond Airport",
    longitude: -83.74585,
    latitude: 46.008758,
    id: "arp_dru_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DRU",
    city_name: "Drummond",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Drummond Island Airport",
    longitude: -83.744823,
    latitude: 46.009044,
    id: "arp_dre_us",
    icao_code: "KDRM",
    iata_country_code: "US",
    iata_code: "DRE",
    city_name: "Drummond Island",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Dryden Regional Airport",
    longitude: -92.744277,
    latitude: 49.831702,
    id: "arp_yhd_ca",
    icao_code: "CYHD",
    iata_country_code: "CA",
    iata_code: "YHD",
    city_name: "Dryden",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Drysdale River Airport",
    longitude: 126.383368,
    latitude: -15.71045,
    id: "arp_dry_au",
    icao_code: "YDRD",
    iata_country_code: "AU",
    iata_code: "DRY",
    city_name: "Drysdale River",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Dschang Airport",
    longitude: 10.067394,
    latitude: 5.448603,
    id: "arp_dsc_cm",
    icao_code: "FKKS",
    iata_country_code: "CM",
    iata_code: "DSC",
    city_name: "Dschang",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Dubai Creek Seaplane Base Airport",
    longitude: 55.33081,
    latitude: 25.242552,
    id: "arp_dcg_ae",
    icao_code: null,
    iata_country_code: "AE",
    iata_code: "DCG",
    city_name: "Dubai",
    city: {
      name: "Dubai",
      id: "cit_dxb_ae",
      iata_country_code: "AE",
      iata_code: "DXB",
    },
  },
  {
    time_zone: "Asia/Dubai",
    name: "Dubai International Airport",
    longitude: 55.365035,
    latitude: 25.252987,
    id: "arp_dxb_ae",
    icao_code: "OMDB",
    iata_country_code: "AE",
    iata_code: "DXB",
    city_name: "Dubai",
    city: {
      name: "Dubai",
      id: "cit_dxb_ae",
      iata_country_code: "AE",
      iata_code: "DXB",
    },
  },
  {
    time_zone: "Australia/Sydney",
    name: "Dubbo City Regional Airport",
    longitude: 148.572285,
    latitude: -32.217782,
    id: "arp_dbo_au",
    icao_code: "YSDU",
    iata_country_code: "AU",
    iata_code: "DBO",
    city_name: "Dubbo",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Dublin Airport",
    longitude: -6.249001,
    latitude: 53.425503,
    id: "arp_dub_ie",
    icao_code: "EIDW",
    iata_country_code: "IE",
    iata_code: "DUB",
    city_name: "Dublin",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Dubois Municipal Airport",
    longitude: -109.68783,
    latitude: 43.548372,
    id: "arp_dbs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DBS",
    city_name: "Dubois",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "DuBois Regional Airport",
    longitude: -78.895522,
    latitude: 41.180363,
    id: "arp_duj_us",
    icao_code: "KDUJ",
    iata_country_code: "US",
    iata_code: "DUJ",
    city_name: "DuBois",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Dubrovnik Airport",
    longitude: 18.262343,
    latitude: 42.561272,
    id: "arp_dbk_hr",
    icao_code: null,
    iata_country_code: "HR",
    iata_code: "DBK",
    city_name: "Kalpitiya",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Dubrovnik Airport",
    longitude: 18.266662,
    latitude: 42.561032,
    id: "arp_dbv_hr",
    icao_code: "LDDU",
    iata_country_code: "HR",
    iata_code: "DBV",
    city_name: "Dubrovnik",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dubuque Regional Airport",
    longitude: -90.708013,
    latitude: 42.403435,
    id: "arp_dbq_us",
    icao_code: "KDBQ",
    iata_country_code: "US",
    iata_code: "DBQ",
    city_name: "Dubuque",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Duke Field",
    longitude: -86.523481,
    latitude: 30.648421,
    id: "arp_egi_us",
    icao_code: "KEGI",
    iata_country_code: "US",
    iata_code: "EGI",
    city_name: "Crestview",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Dukuduk Airport",
    longitude: 32.24745,
    latitude: -28.367977,
    id: "arp_duk_za",
    icao_code: "FADK",
    iata_country_code: "ZA",
    iata_code: "DUK",
    city_name: "Mtubatuba",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Dulkaninna Airport",
    longitude: 138.480816,
    latitude: -29.013134,
    id: "arp_dlk_au",
    icao_code: "YDLK",
    iata_country_code: "AU",
    iata_code: "DLK",
    city_name: "Dulkaninna",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Duluth International Airport",
    longitude: -92.180675,
    latitude: 46.838977,
    id: "arp_dlh_us",
    icao_code: "KDLH",
    iata_country_code: "US",
    iata_code: "DLH",
    city_name: "Duluth",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Dumpu Airport",
    longitude: 145.75,
    latitude: -5.833333,
    id: "arp_dpu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "DPU",
    city_name: "Dumpu",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dunbar Airport",
    longitude: 142.396553,
    latitude: -16.048676,
    id: "arp_dnb_au",
    icao_code: "YDBR",
    iata_country_code: "AU",
    iata_code: "DNB",
    city_name: "Dunbar",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Duncan Airport",
    longitude: -123.710959,
    latitude: 48.755534,
    id: "arp_duq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "DUQ",
    city_name: "Duncan",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Duncan Town Airport",
    longitude: -75.729981,
    latitude: 22.182193,
    id: "arp_dct_bs",
    icao_code: "MYRD",
    iata_country_code: "BS",
    iata_code: "DCT",
    city_name: "Duncan Town",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Dundee Airport",
    longitude: -3.023034,
    latitude: 56.452883,
    id: "arp_dnd_gb",
    icao_code: "EGPN",
    iata_country_code: "GB",
    iata_code: "DND",
    city_name: "Dundee",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Dundo Airport",
    longitude: 20.818565,
    latitude: -7.400687,
    id: "arp_due_ao",
    icao_code: "FNDU",
    iata_country_code: "AO",
    iata_code: "DUE",
    city_name: "Dundo",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Dunedin International Airport",
    longitude: 170.200083,
    latitude: -45.926981,
    id: "arp_dud_nz",
    icao_code: "NZDN",
    iata_country_code: "NZ",
    iata_code: "DUD",
    city_name: "Dunedin",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Dunhuang Airport",
    longitude: 94.807122,
    latitude: 40.163607,
    id: "arp_dnh_cn",
    icao_code: "ZLDH",
    iata_country_code: "CN",
    iata_code: "DNH",
    city_name: "Dunhuang",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dunk Island Airport",
    longitude: 146.140126,
    latitude: -17.940193,
    id: "arp_dki_au",
    icao_code: "YDKI",
    iata_country_code: "AU",
    iata_code: "DKI",
    city_name: "Dunk Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Dunwich Airport",
    longitude: -105.532168,
    latitude: 33.461262,
    id: "arp_srr_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "SRR",
    city_name: "Stradbroke Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "DuPage Airport",
    longitude: -88.25084,
    latitude: 41.907279,
    id: "arp_dpa_us",
    icao_code: "KDPA",
    iata_country_code: "US",
    iata_code: "DPA",
    city_name: "Chicago",
    city: {
      name: "Chicago",
      id: "cit_chi_us",
      iata_country_code: "US",
      iata_code: "CHI",
    },
  },
  {
    time_zone: "Asia/Muscat",
    name: "Duqm International Airport",
    longitude: 57.635445,
    latitude: 19.50214,
    id: "arp_dqm_om",
    icao_code: "OODQ",
    iata_country_code: "OM",
    iata_code: "DQM",
    city_name: "Duqm",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Durango International Airport",
    longitude: -104.530169,
    latitude: 24.125308,
    id: "arp_dgo_mx",
    icao_code: "MMDO",
    iata_country_code: "MX",
    iata_code: "DGO",
    city_name: "Durango",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Durango-La Plata County Airport",
    longitude: -107.753511,
    latitude: 37.151304,
    id: "arp_dro_us",
    icao_code: "KDRO",
    iata_country_code: "US",
    iata_code: "DRO",
    city_name: "Durango",
    city: {
      name: "Durango",
      id: "cit_dro_us",
      iata_country_code: "US",
      iata_code: "DRO",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Durant Regional Airport",
    longitude: -96.396954,
    latitude: 33.940664,
    id: "arp_dua_us",
    icao_code: "KDUA",
    iata_country_code: "US",
    iata_code: "DUA",
    city_name: "Durant",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Durham Downs Airport",
    longitude: 141.89975,
    latitude: -27.074578,
    id: "arp_dhd_au",
    icao_code: "YDRH",
    iata_country_code: "AU",
    iata_code: "DHD",
    city_name: "Durham Downs",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Durham Tees Valley Airport",
    longitude: -1.428015,
    latitude: 54.509648,
    id: "arp_mme_gb",
    icao_code: "EGNV",
    iata_country_code: "GB",
    iata_code: "MME",
    city_name: "Durham",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Durrie Airport",
    longitude: 140.227185,
    latitude: -25.68601,
    id: "arp_drr_au",
    icao_code: "YDRI",
    iata_country_code: "AU",
    iata_code: "DRR",
    city_name: "Durrie",
    city: null,
  },
  {
    time_zone: "Asia/Dushanbe",
    name: "Dushanbe International Airport",
    longitude: 68.814498,
    latitude: 38.548047,
    id: "arp_dyu_tj",
    icao_code: "UTDD",
    iata_country_code: "TJ",
    iata_code: "DYU",
    city_name: "Dushanbe",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Düsseldorf Airport",
    longitude: 6.765655,
    latitude: 51.28735,
    id: "arp_dus_de",
    icao_code: "EDDL",
    iata_country_code: "DE",
    iata_code: "DUS",
    city_name: "Düsseldorf",
    city: {
      name: "Duesseldorf",
      id: "cit_dus_de",
      iata_country_code: "DE",
      iata_code: "DUS",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Dutchess County Airport",
    longitude: -73.884182,
    latitude: 41.627048,
    id: "arp_pou_us",
    icao_code: "KPOU",
    iata_country_code: "US",
    iata_code: "POU",
    city_name: "Poughkeepsie",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Dwangwa Airport",
    longitude: 34.131348,
    latitude: -12.51807,
    id: "arp_dwa_mw",
    icao_code: "FWDW",
    iata_country_code: "MW",
    iata_code: "DWA",
    city_name: "Dwangwa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Dyess Air Force Base",
    longitude: -99.854599,
    latitude: 32.420799,
    id: "arp_dys_us",
    icao_code: "KDYS",
    iata_country_code: "US",
    iata_code: "DYS",
    city_name: "Abilene",
    city: {
      name: "Abilene",
      id: "cit_abi_us",
      iata_country_code: "US",
      iata_code: "ABI",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Dysart Airport",
    longitude: 148.364164,
    latitude: -22.621679,
    id: "arp_dya_au",
    icao_code: "YDYS",
    iata_country_code: "AU",
    iata_code: "DYA",
    city_name: "Dysart",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Dywer Airbase Airport",
    longitude: 64.0,
    latitude: 31.0,
    id: "arp_dwr_af",
    icao_code: "OADY",
    iata_country_code: "AF",
    iata_code: "DWR",
    city_name: "Camp Dwyer",
    city: null,
  },
  {
    time_zone: "Indian/Mayotte",
    name: "Dzaoudzi Pamandzi International Airport",
    longitude: 45.281547,
    latitude: -12.804827,
    id: "arp_dza_yt",
    icao_code: "FMCZ",
    iata_country_code: "YT",
    iata_code: "DZA",
    city_name: "Dzaoudzi",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Eagle Airport",
    longitude: -141.150754,
    latitude: 64.776948,
    id: "arp_eaa_us",
    icao_code: "PAEG",
    iata_country_code: "US",
    iata_code: "EAA",
    city_name: "Eagle",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Eagle County Regional Airport",
    longitude: -106.916305,
    latitude: 39.642579,
    id: "arp_ege_us",
    icao_code: "KEGE",
    iata_country_code: "US",
    iata_code: "EGE",
    city_name: "Gypsum",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Eagle Lake Airport",
    longitude: -96.322149,
    latitude: 29.600585,
    id: "arp_ela_us",
    icao_code: "KELA",
    iata_country_code: "US",
    iata_code: "ELA",
    city_name: "Eagle Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Eagle River Union Airport",
    longitude: -89.265068,
    latitude: 45.933199,
    id: "arp_egv_us",
    icao_code: "KEGV",
    iata_country_code: "US",
    iata_code: "EGV",
    city_name: "Eagle River",
    city: null,
  },
  {
    time_zone: "America/Adak",
    name: "Eareckson Air Station",
    longitude: 174.119897,
    latitude: 52.712056,
    id: "arp_sya_us",
    icao_code: "PASY",
    iata_country_code: "US",
    iata_code: "SYA",
    city_name: "Shemya",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Earlton",
    longitude: -79.847722,
    latitude: 47.697461,
    id: "arp_yxr_ca",
    icao_code: "CYXR",
    iata_country_code: "CA",
    iata_code: "YXR",
    city_name: "Earlton",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Eastern Oregon Regional Airport",
    longitude: -118.840208,
    latitude: 45.692316,
    id: "arp_pdt_us",
    icao_code: "KPDT",
    iata_country_code: "US",
    iata_code: "PDT",
    city_name: "Pendleton",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Eastern Sierra Regional Airport",
    longitude: -118.363951,
    latitude: 37.372952,
    id: "arp_bih_us",
    icao_code: "KBIH",
    iata_country_code: "US",
    iata_code: "BIH",
    city_name: "Bishop",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Eastern Slopes Regional Airport",
    longitude: -70.947826,
    latitude: 43.99081,
    id: "arp_fry_us",
    icao_code: "KIZG",
    iata_country_code: "US",
    iata_code: "FRY",
    city_name: "Fryeburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Eastern West Virginia Regional Airport",
    longitude: -77.983543,
    latitude: 39.401655,
    id: "arp_mrb_us",
    icao_code: "KMRB",
    iata_country_code: "US",
    iata_code: "MRB",
    city_name: "Martinsburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Easterwood Airport",
    longitude: -96.364398,
    latitude: 30.590107,
    id: "arp_cll_us",
    icao_code: "KCLL",
    iata_country_code: "US",
    iata_code: "CLL",
    city_name: "College Station",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "East Hampton Airport",
    longitude: -72.252232,
    latitude: 40.959136,
    id: "arp_hto_us",
    icao_code: "KHTO",
    iata_country_code: "US",
    iata_code: "HTO",
    city_name: "East Hampton",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "East Kimberley Regional Airport",
    longitude: 128.710346,
    latitude: -15.780968,
    id: "arp_knx_au",
    icao_code: "YPKU",
    iata_country_code: "AU",
    iata_code: "KNX",
    city_name: "Kununurra",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Eastland Municipal Airport",
    longitude: -98.810161,
    latitude: 32.414144,
    id: "arp_etn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ETN",
    city_name: "Eastland",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "East London Airport",
    longitude: 27.82526,
    latitude: -33.036045,
    id: "arp_els_za",
    icao_code: "FAEL",
    iata_country_code: "ZA",
    iata_code: "ELS",
    city_name: "East London",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Eastmain River Airport",
    longitude: -78.521769,
    latitude: 52.227354,
    id: "arp_zem_ca",
    icao_code: "CZEM",
    iata_country_code: "CA",
    iata_code: "ZEM",
    city_name: "Quebec",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "East Midlands Airport",
    longitude: -1.330097,
    latitude: 52.830237,
    id: "arp_ema_gb",
    icao_code: "EGNX",
    iata_country_code: "GB",
    iata_code: "EMA",
    city_name: "Nottingham",
    city: {
      name: "Nottingham",
      id: "cit_nqt_gb",
      iata_country_code: "GB",
      iata_code: "NQT",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Easton Airport",
    longitude: -76.067939,
    latitude: 38.806262,
    id: "arp_esn_us",
    icao_code: "KESN",
    iata_country_code: "US",
    iata_code: "ESN",
    city_name: "Easton",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Easton State Airport",
    longitude: -121.185799,
    latitude: 47.25416,
    id: "arp_esw_us",
    icao_code: "KESW",
    iata_country_code: "US",
    iata_code: "ESW",
    city_name: "Easton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "East Texas Regional Airport",
    longitude: -94.715305,
    latitude: 32.385817,
    id: "arp_ggg_us",
    icao_code: "KGGG",
    iata_country_code: "US",
    iata_code: "GGG",
    city_name: "Longview",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Ebadon Airport",
    longitude: 166.819653,
    latitude: 9.330528,
    id: "arp_ebn_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "EBN",
    city_name: "Ebadon Island",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Ebolowa Airport",
    longitude: 11.183906,
    latitude: 2.876557,
    id: "arp_ebw_cm",
    icao_code: "FKKW",
    iata_country_code: "CM",
    iata_code: "EBW",
    city_name: "Ebolowa",
    city: null,
  },
  {
    time_zone: "Asia/Anadyr",
    name: "Ebon Airport",
    longitude: 168.752895,
    latitude: 4.598771,
    id: "arp_ebo_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "EBO",
    city_name: "Ebon Atoll",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Echuca Airport",
    longitude: 144.762872,
    latitude: -36.160247,
    id: "arp_ech_au",
    icao_code: "YECH",
    iata_country_code: "AU",
    iata_code: "ECH",
    city_name: "Echuca",
    city: null,
  },
  {
    time_zone: "America/Indiana/Vincennes",
    name: "Ed-Air Airport",
    longitude: -87.49936,
    latitude: 38.851434,
    id: "arp_otn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "OTN",
    city_name: "Oaktown",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Eday Airport",
    longitude: -2.771007,
    latitude: 59.190972,
    id: "arp_eoi_gb",
    icao_code: "EGED",
    iata_country_code: "GB",
    iata_code: "EOI",
    city_name: "Eday",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Ed Daein Airport",
    longitude: 26.117452,
    latitude: 11.404295,
    id: "arp_adv_sd",
    icao_code: null,
    iata_country_code: "SD",
    iata_code: "ADV",
    city_name: "Addeain",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Edinburgh Airport",
    longitude: -3.366976,
    latitude: 55.950392,
    id: "arp_edi_gb",
    icao_code: "EGPH",
    iata_country_code: "GB",
    iata_code: "EDI",
    city_name: "Edinburgh",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Edmonton City Centre",
    longitude: -113.519754,
    latitude: 53.572715,
    id: "arp_yxd_ca",
    icao_code: "CYXD",
    iata_country_code: "CA",
    iata_code: "YXD",
    city_name: "Edmonton",
    city: {
      name: "Edmonton",
      id: "cit_yea_ca",
      iata_country_code: "CA",
      iata_code: "YEA",
    },
  },
  {
    time_zone: "America/Edmonton",
    name: "Edmonton International Airport",
    longitude: -113.580434,
    latitude: 53.308085,
    id: "arp_yeg_ca",
    icao_code: "CYEG",
    iata_country_code: "CA",
    iata_code: "YEG",
    city_name: "Edmonton",
    city: {
      name: "Edmonton",
      id: "cit_yea_ca",
      iata_country_code: "CA",
      iata_code: "YEA",
    },
  },
  {
    time_zone: "America/Sitka",
    name: "Edna Bay Airport",
    longitude: -133.666581,
    latitude: 55.951229,
    id: "arp_eda_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EDA",
    city_name: "Edna Bay",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Edremit Balıkesir Koca Seyit Airport",
    longitude: 27.013125,
    latitude: 39.552721,
    id: "arp_edo_tr",
    icao_code: "LTFD",
    iata_country_code: "TR",
    iata_code: "EDO",
    city_name: "Edremit",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Edson Airport",
    longitude: -116.463074,
    latitude: 53.578884,
    id: "arp_yet_ca",
    icao_code: "CYET",
    iata_country_code: "CA",
    iata_code: "YET",
    city_name: "Edson",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Eduardo Falla Solano Airport",
    longitude: -74.766243,
    latitude: 2.151873,
    id: "arp_svi_co",
    icao_code: "SKSV",
    iata_country_code: "CO",
    iata_code: "SVI",
    city_name: "San Vicente del Caguán",
    city: null,
  },
  {
    time_zone: "America/Manaus",
    name: "Eduardo Gomes International Airport",
    longitude: -60.048013,
    latitude: -3.037228,
    id: "arp_mao_br",
    icao_code: "SBEG",
    iata_country_code: "BR",
    iata_code: "MAO",
    city_name: "Manaus",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Edwaki Airport",
    longitude: 141.792673,
    latitude: -3.882416,
    id: "arp_xyr_pg",
    icao_code: "AYED",
    iata_country_code: "PG",
    iata_code: "XYR",
    city_name: "Yellow River",
    city: null,
  },
  {
    time_zone: "America/Cayman",
    name: "Edward Bodden Airfield",
    longitude: -80.086829,
    latitude: 19.659779,
    id: "arp_lyb_ky",
    icao_code: "MWCL",
    iata_country_code: "KY",
    iata_code: "LYB",
    city_name: "Little Cayman",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Edward F. Knapp State Airport",
    longitude: -72.562945,
    latitude: 44.203309,
    id: "arp_mpv_us",
    icao_code: "KMPV",
    iata_country_code: "US",
    iata_code: "MPV",
    city_name: "Barre/Montpelier",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Edward G. Pitka Sr. Airport",
    longitude: -156.942203,
    latitude: 64.736474,
    id: "arp_gal_us",
    icao_code: "PAGA",
    iata_country_code: "US",
    iata_code: "GAL",
    city_name: "Galena",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Edward River Airport",
    longitude: 141.610177,
    latitude: -14.89746,
    id: "arp_edr_au",
    icao_code: "YPMP",
    iata_country_code: "AU",
    iata_code: "EDR",
    city_name: "Pormpuraaw",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Edwards Air Force Base",
    longitude: -117.893042,
    latitude: 34.900934,
    id: "arp_edw_us",
    icao_code: "KEDW",
    iata_country_code: "US",
    iata_code: "EDW",
    city_name: "Edwards",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Eek Airport",
    longitude: -162.043789,
    latitude: 60.214026,
    id: "arp_eek_us",
    icao_code: "PAEE",
    iata_country_code: "US",
    iata_code: "EEK",
    city_name: "Eek",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Efogi Airport",
    longitude: 147.659501,
    latitude: -9.154423,
    id: "arp_efg_pg",
    icao_code: "AYEF",
    iata_country_code: "PG",
    iata_code: "EFG",
    city_name: "Efogi",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Egegik Airport",
    longitude: -157.375244,
    latitude: 58.185596,
    id: "arp_egx_us",
    icao_code: "PAII",
    iata_country_code: "US",
    iata_code: "EGX",
    city_name: "Egegik",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Egilsstaðir Airport",
    longitude: -14.40552,
    latitude: 65.27987,
    id: "arp_egs_is",
    icao_code: "BIEG",
    iata_country_code: "IS",
    iata_code: "EGS",
    city_name: "Egilsstaðir",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Eielson Air Force Base",
    longitude: -147.102005,
    latitude: 64.665703,
    id: "arp_eil_us",
    icao_code: "PAEI",
    iata_country_code: "US",
    iata_code: "EIL",
    city_name: "Fairbanks",
    city: {
      name: "Fairbanks",
      id: "cit_fai_us",
      iata_country_code: "US",
      iata_code: "FAI",
    },
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Eil Airport",
    longitude: 49.82059,
    latitude: 8.103639,
    id: "arp_hcm_so",
    icao_code: "HCME",
    iata_country_code: "SO",
    iata_code: "HCM",
    city_name: "Eyl",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Eilat Airport",
    longitude: 34.957448,
    latitude: 29.557628,
    id: "arp_eth_il",
    icao_code: "LLET",
    iata_country_code: "IL",
    iata_code: "ETH",
    city_name: "Eilat",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Einasleigh Airport",
    longitude: 144.09399,
    latitude: -18.504232,
    id: "arp_eih_au",
    icao_code: "YEIN",
    iata_country_code: "AU",
    iata_code: "EIH",
    city_name: "Einasleigh",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Eindhoven Airport",
    longitude: 5.380664,
    latitude: 51.450719,
    id: "arp_ein_nl",
    icao_code: "EHEH",
    iata_country_code: "NL",
    iata_code: "EIN",
    city_name: "Eindhoven",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Ein Yahav Airfield",
    longitude: 35.202046,
    latitude: 30.618856,
    id: "arp_eiy_il",
    icao_code: "LLEY",
    iata_country_code: "IL",
    iata_code: "EIY",
    city_name: "Ein Yahav",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Eirunepé Airport",
    longitude: -69.880917,
    latitude: -6.638638,
    id: "arp_ern_br",
    icao_code: "SWEI",
    iata_country_code: "BR",
    iata_code: "ERN",
    city_name: "Eirunepe",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Eisenach-Kindel Airport",
    longitude: 10.47973,
    latitude: 50.991604,
    id: "arp_eib_de",
    icao_code: "EDGE",
    iata_country_code: "DE",
    iata_code: "EIB",
    city_name: "Eisenach",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ejin Banner Taolai Airport",
    longitude: 101.0005,
    latitude: 42.0155,
    id: "arp_ejn_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "EJN",
    city_name: "Ejin Banner",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Ekati Airport",
    longitude: -110.614905,
    latitude: 64.698895,
    id: "arp_yoa_ca",
    icao_code: "CYOA",
    iata_country_code: "CA",
    iata_code: "YOA",
    city_name: "Ekati",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Ekereku Airport",
    longitude: -60.85,
    latitude: 6.66667,
    id: "arp_eke_gy",
    icao_code: "SYEK",
    iata_country_code: "GY",
    iata_code: "EKE",
    city_name: "Ekereku",
    city: null,
  },
  {
    time_zone: "Asia/Qyzylorda",
    name: "Ekibastuz Airport",
    longitude: 75.215943,
    latitude: 51.59161,
    id: "arp_ekb_kz",
    icao_code: "UASB",
    iata_country_code: "KZ",
    iata_code: "EKB",
    city_name: "Ekibastuz",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ekuk Airport",
    longitude: -158.560086,
    latitude: 58.811876,
    id: "arp_kku_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KKU",
    city_name: "Ekuk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ekwok Airport",
    longitude: -157.471298,
    latitude: 59.356618,
    id: "arp_kek_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KEK",
    city_name: "Ekwok",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "El Alamein International Airport",
    longitude: 28.46182,
    latitude: 30.926042,
    id: "arp_dbb_eg",
    icao_code: "HEAL",
    iata_country_code: "EG",
    iata_code: "DBB",
    city_name: "El Alamein",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Alcaraván Airport",
    longitude: -72.385001,
    latitude: 5.31982,
    id: "arp_eyp_co",
    icao_code: "SKYP",
    iata_country_code: "CO",
    iata_code: "EYP",
    city_name: "Yopal",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "El Alto International Airport",
    longitude: -68.190964,
    latitude: -16.512282,
    id: "arp_lpb_bo",
    icao_code: "SLLP",
    iata_country_code: "BO",
    iata_code: "LPB",
    city_name: "La Paz",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "El Arish International Airport",
    longitude: 33.836394,
    latitude: 31.075784,
    id: "arp_aac_eg",
    icao_code: "HEAR",
    iata_country_code: "EG",
    iata_code: "AAC",
    city_name: "El Arish",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "El Arrayán Airport",
    longitude: -86.575799,
    latitude: 15.506841,
    id: "arp_oan_hn",
    icao_code: "MHEA",
    iata_country_code: "HN",
    iata_code: "OAN",
    city_name: "Olanchito",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Elazığ Airport",
    longitude: 39.290097,
    latitude: 38.604203,
    id: "arp_ezs_tr",
    icao_code: "LTCA",
    iata_country_code: "TR",
    iata_code: "EZS",
    city_name: "Elazığ",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Bagre Airport",
    longitude: -74.809217,
    latitude: 7.596637,
    id: "arp_ebg_co",
    icao_code: "SKEB",
    iata_country_code: "CO",
    iata_code: "EBG",
    city_name: "El Bagre",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "El Bayadh Airport",
    longitude: 1.092559,
    latitude: 33.720777,
    id: "arp_ebh_dz",
    icao_code: "DAOY",
    iata_country_code: "DZ",
    iata_code: "EBH",
    city_name: "El Bayadh",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "El Bolson Airport",
    longitude: -71.532597,
    latitude: -41.943414,
    id: "arp_ehl_ar",
    icao_code: "SAVB",
    iata_country_code: "AR",
    iata_code: "EHL",
    city_name: "El Bolson",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "El Borma Airport",
    longitude: 9.254619,
    latitude: 31.704299,
    id: "arp_ebm_tn",
    icao_code: "DTTR",
    iata_country_code: "TN",
    iata_code: "EBM",
    city_name: "El Borma",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "El Calafate International Airport",
    longitude: -72.054061,
    latitude: -50.281641,
    id: "arp_fte_ar",
    icao_code: "SAWC",
    iata_country_code: "AR",
    iata_code: "FTE",
    city_name: "El Calafate",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Caraño Airport",
    longitude: -76.642691,
    latitude: 5.690879,
    id: "arp_uib_co",
    icao_code: "SKUI",
    iata_country_code: "CO",
    iata_code: "UIB",
    city_name: "Quibdó",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "El Centro Naval Air Facility Airport",
    longitude: -115.671996,
    latitude: 32.829201,
    id: "arp_njk_us",
    icao_code: "KNJK",
    iata_country_code: "US",
    iata_code: "NJK",
    city_name: "El Centro",
    city: {
      name: "Imperial/El Centro",
      id: "cit_ipl_us",
      iata_country_code: "US",
      iata_code: "IPL",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "El Charco Airport",
    longitude: -78.094028,
    latitude: 2.449291,
    id: "arp_ecr_co",
    icao_code: "SKEH",
    iata_country_code: "CO",
    iata_code: "ECR",
    city_name: "El Charco",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Elcho Island Airport",
    longitude: 135.571529,
    latitude: -12.01941,
    id: "arp_elc_au",
    icao_code: "YELD",
    iata_country_code: "AU",
    iata_code: "ELC",
    city_name: "Elcho Island",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "El Debba Airport",
    longitude: 30.959422,
    latitude: 18.013695,
    id: "arp_edb_sd",
    icao_code: "HSDB",
    iata_country_code: "SD",
    iata_code: "EDB",
    city_name: "El Debba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "El Dorado Airport",
    longitude: -96.817209,
    latitude: 37.7758,
    id: "arp_edk_us",
    icao_code: "KEQA",
    iata_country_code: "US",
    iata_code: "EDK",
    city_name: "El Dorado",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "El Dorado Airport",
    longitude: -54.5747,
    latitude: -26.397499,
    id: "arp_elo_ar",
    icao_code: "SATD",
    iata_country_code: "AR",
    iata_code: "ELO",
    city_name: "Eldorado",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "El Dorado Airport",
    longitude: -61.639007,
    latitude: 6.715727,
    id: "arp_eor_ve",
    icao_code: "SVED",
    iata_country_code: "VE",
    iata_code: "EOR",
    city_name: "El Dorado",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Dorado International Airport",
    longitude: -74.144964,
    latitude: 4.700907,
    id: "arp_bog_co",
    icao_code: "SKBO",
    iata_country_code: "CO",
    iata_code: "BOG",
    city_name: "Bogota",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Eldoret International Airport",
    longitude: 35.238705,
    latitude: 0.404423,
    id: "arp_edl_ke",
    icao_code: "HKEL",
    iata_country_code: "KE",
    iata_code: "EDL",
    city_name: "Eldoret",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Edén International Airport",
    longitude: -75.766349,
    latitude: 4.452831,
    id: "arp_axm_co",
    icao_code: "SKAR",
    iata_country_code: "CO",
    iata_code: "AXM",
    city_name: "Armenia",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Elelim Airport",
    longitude: 138.952683,
    latitude: -4.099212,
    id: "arp_elr_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "ELR",
    city_name: "Elelim",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Embrujo Airport",
    longitude: -81.358729,
    latitude: 13.35787,
    id: "arp_pva_co",
    icao_code: "SKPV",
    iata_country_code: "CO",
    iata_code: "PVA",
    city_name: "Providencia Island",
    city: null,
  },
  {
    time_zone: "Pacific/Kwajalein",
    name: "Elenak Airport",
    longitude: 166.845344,
    latitude: 9.320278,
    id: "arp_eal_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "EAL",
    city_name: "Elenak",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Encanto Airport",
    longitude: -73.20572,
    latitude: -1.752808,
    id: "arp_eco_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ECO",
    city_name: "El Encanto",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "El Fasher Airport",
    longitude: 25.325782,
    latitude: 13.616634,
    id: "arp_elf_sd",
    icao_code: "HSFS",
    iata_country_code: "SD",
    iata_code: "ELF",
    city_name: "El Fasher",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Elfin Cove Seaplane Base",
    longitude: -136.347196,
    latitude: 58.195193,
    id: "arp_elv_us",
    icao_code: "PAEL",
    iata_country_code: "US",
    iata_code: "ELV",
    city_name: "Elfin Cove",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "El Golea Airport",
    longitude: 2.862777,
    latitude: 30.571037,
    id: "arp_elg_dz",
    icao_code: "DAUE",
    iata_country_code: "DZ",
    iata_code: "ELG",
    city_name: "El Goléa",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "El Hierro Airport",
    longitude: -17.886429,
    latitude: 27.814483,
    id: "arp_vde_es",
    icao_code: "GCHI",
    iata_country_code: "ES",
    iata_code: "VDE",
    city_name: "Valverde",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Elim Airport",
    longitude: -162.271861,
    latitude: 64.614558,
    id: "arp_eli_us",
    icao_code: "PFEL",
    iata_country_code: "US",
    iata_code: "ELI",
    city_name: "Elim",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Eliptamin Airport",
    longitude: 141.677586,
    latitude: -5.040697,
    id: "arp_ept_pg",
    icao_code: "AYEL",
    iata_country_code: "PG",
    iata_code: "EPT",
    city_name: "Eliptamin",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Elista Airport",
    longitude: 44.330936,
    latitude: 46.373899,
    id: "arp_esl_ru",
    icao_code: "URWI",
    iata_country_code: "RU",
    iata_code: "ESL",
    city_name: "Elista",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Eliye Springs Airport",
    longitude: 35.97664,
    latitude: 3.236354,
    id: "arp_eys_ke",
    icao_code: "HKES",
    iata_country_code: "KE",
    iata_code: "EYS",
    city_name: "Eliye Springs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Elizabeth City Regional Airport",
    longitude: -76.174217,
    latitude: 36.258252,
    id: "arp_ecg_us",
    icao_code: "KECG",
    iata_country_code: "US",
    iata_code: "ECG",
    city_name: "Elizabeth City",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Elizabeth Field",
    longitude: -72.031861,
    latitude: 41.251699,
    id: "arp_fid_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FID",
    city_name: "Fishers Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Elizabethtown Regional Airport",
    longitude: -85.925407,
    latitude: 37.688172,
    id: "arp_ekx_us",
    icao_code: "KEKX",
    iata_country_code: "US",
    iata_code: "EKX",
    city_name: "Elizabethtown",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Elk City Regional Business Airport",
    longitude: -99.395969,
    latitude: 35.426478,
    id: "arp_elk_us",
    icao_code: "KELK",
    iata_country_code: "US",
    iata_code: "ELK",
    city_name: "Elk City",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Elkedra Airport",
    longitude: 135.443752,
    latitude: -21.1721,
    id: "arp_ekd_au",
    icao_code: "YELK",
    iata_country_code: "AU",
    iata_code: "EKD",
    city_name: "Elkedra",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "El Kharga Airport",
    longitude: 30.589564,
    latitude: 25.472599,
    id: "arp_uvl_eg",
    icao_code: "HEKG",
    iata_country_code: "EG",
    iata_code: "UVL",
    city_name: "Kharga",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Elkhart Municipal Airport",
    longitude: -85.99948,
    latitude: 41.720129,
    id: "arp_eki_us",
    icao_code: "KEKM",
    iata_country_code: "US",
    iata_code: "EKI",
    city_name: "Elkhart",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Elkins-Randolph Co-Jennings Randolph Field Airport",
    longitude: -79.857142,
    latitude: 38.888123,
    id: "arp_ekn_us",
    icao_code: "KEKN",
    iata_country_code: "US",
    iata_code: "EKN",
    city_name: "Elkins",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Elko Regional Airport",
    longitude: -115.789105,
    latitude: 40.82636,
    id: "arp_eko_us",
    icao_code: "KEKO",
    iata_country_code: "US",
    iata_code: "EKO",
    city_name: "Elko",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ellamar Seaplane Base",
    longitude: -146.703865,
    latitude: 60.893863,
    id: "arp_elw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ELW",
    city_name: "Ellamar",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "El Lencero Airport",
    longitude: -96.796283,
    latitude: 19.474983,
    id: "arp_jal_mx",
    icao_code: "MMJA",
    iata_country_code: "MX",
    iata_code: "JAL",
    city_name: "Xalapa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ellington Airport",
    longitude: -95.164016,
    latitude: 29.599735,
    id: "arp_efd_us",
    icao_code: "KEFD",
    iata_country_code: "US",
    iata_code: "EFD",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "Europe/Athens",
    name: "Ellinikon International Airport",
    longitude: 23.726101,
    latitude: 37.893299,
    id: "arp_hew_gr",
    icao_code: "LGAT",
    iata_country_code: "GR",
    iata_code: "HEW",
    city_name: "Athens",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Elliot Lake Municipal Airport",
    longitude: -82.561393,
    latitude: 46.351392,
    id: "arp_yel_ca",
    icao_code: "CYEL",
    iata_country_code: "CA",
    iata_code: "YEL",
    city_name: "Elliot Lake",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Ellisras Matimba Airport",
    longitude: 27.697121,
    latitude: -23.723218,
    id: "arp_ell_za",
    icao_code: "FAER",
    iata_country_code: "ZA",
    iata_code: "ELL",
    city_name: "Ellisras",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "El Loa Airport",
    longitude: -68.905593,
    latitude: -22.497597,
    id: "arp_cjc_cl",
    icao_code: "SCCF",
    iata_country_code: "CL",
    iata_code: "CJC",
    city_name: "Calama",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Ellsworth Air Force Base",
    longitude: -103.103996,
    latitude: 44.145,
    id: "arp_rca_us",
    icao_code: "KRCA",
    iata_country_code: "US",
    iata_code: "RCA",
    city_name: "Rapid City",
    city: {
      name: "Rapid City",
      id: "cit_rap_us",
      iata_country_code: "US",
      iata_code: "RAP",
    },
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "El Maiten Airport",
    longitude: -71.171666,
    latitude: -42.0296,
    id: "arp_emx_ar",
    icao_code: "SAVD",
    iata_country_code: "AR",
    iata_code: "EMX",
    city_name: "El Maiten",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Elmendorf Air Force Base",
    longitude: -149.808422,
    latitude: 61.251878,
    id: "arp_edf_us",
    icao_code: "PAED",
    iata_country_code: "US",
    iata_code: "EDF",
    city_name: "Anchorage",
    city: {
      name: "Anchorage",
      id: "cit_anc_us",
      iata_country_code: "US",
      iata_code: "ANC",
    },
  },
  {
    time_zone: "Africa/Cairo",
    name: "El Minya Airport",
    longitude: 30.734758,
    latitude: 28.094209,
    id: "arp_emy_eg",
    icao_code: "HEMN",
    iata_country_code: "EG",
    iata_code: "EMY",
    city_name: "El-Minya",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Elmira Corning Regional Airport",
    longitude: -76.891917,
    latitude: 42.16041,
    id: "arp_elm_us",
    icao_code: "KELM",
    iata_country_code: "US",
    iata_code: "ELM",
    city_name: "Elmira",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "El Mirador Airport",
    longitude: -72.947909,
    latitude: -41.347489,
    id: "arp_pux_cl",
    icao_code: "SCPV",
    iata_country_code: "CL",
    iata_code: "PUX",
    city_name: "Puerto Varas",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "El Naranjo Airport",
    longitude: -90.8175,
    latitude: 14.106882,
    id: "arp_enj_gt",
    icao_code: null,
    iata_country_code: "GT",
    iata_code: "ENJ",
    city_name: "El Naranjo",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "El Nido Airport",
    longitude: 119.416334,
    latitude: 11.202236,
    id: "arp_eni_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "ENI",
    city_name: "El Nido",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "El Nouzha Airport",
    longitude: 29.952488,
    latitude: 31.191435,
    id: "arp_aly_eg",
    icao_code: "HEAX",
    iata_country_code: "EG",
    iata_code: "ALY",
    city_name: "Alexandria",
    city: {
      name: "Alexandria",
      id: "cit_aly_eg",
      iata_country_code: "EG",
      iata_code: "ALY",
    },
  },
  {
    time_zone: "Africa/Khartoum",
    name: "El Obeid Airport",
    longitude: 30.233022,
    latitude: 13.155108,
    id: "arp_ebd_sd",
    icao_code: "HSOB",
    iata_country_code: "SD",
    iata_code: "EBD",
    city_name: "El Obeid",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Elorza Airport",
    longitude: -69.496449,
    latitude: 7.060464,
    id: "arp_eoz_ve",
    icao_code: "SVEZ",
    iata_country_code: "VE",
    iata_code: "EOZ",
    city_name: "Elorza",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Eloy Alfaro International Airport",
    longitude: -80.681478,
    latitude: -0.949492,
    id: "arp_mec_ec",
    icao_code: "SEMT",
    iata_country_code: "EC",
    iata_code: "MEC",
    city_name: "Manta",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "El Palomar Airport",
    longitude: -58.599807,
    latitude: -34.605488,
    id: "arp_epa_ar",
    icao_code: "SADP",
    iata_country_code: "AR",
    iata_code: "EPA",
    city_name: "El Palomar",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "El Paso International Airport",
    longitude: -106.380216,
    latitude: 31.806267,
    id: "arp_elp_us",
    icao_code: "KELP",
    iata_country_code: "US",
    iata_code: "ELP",
    city_name: "El Paso",
    city: {
      name: "El Paso",
      id: "cit_elp_us",
      iata_country_code: "US",
      iata_code: "ELP",
    },
  },
  {
    time_zone: "America/Argentina/Mendoza",
    name: "El Plumerillo International Airport",
    longitude: -68.794579,
    latitude: -32.830909,
    id: "arp_mdz_ar",
    icao_code: "SAME",
    iata_country_code: "AR",
    iata_code: "MDZ",
    city_name: "Mendoza",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "El Porvenir Airport",
    longitude: -78.947522,
    latitude: 9.5587,
    id: "arp_pve_pa",
    icao_code: "MPVR",
    iata_country_code: "PA",
    iata_code: "PVE",
    city_name: "El Porvenir",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "El Real Airport",
    longitude: -77.726096,
    latitude: 8.107726,
    id: "arp_ele_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "ELE",
    city_name: "El Real",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "El Recreo Airport",
    longitude: -74.1333,
    latitude: 2.0,
    id: "arp_elj_co",
    icao_code: "SVWX",
    iata_country_code: "CO",
    iata_code: "ELJ",
    city_name: "El Recreo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Elrose Mine Airport",
    longitude: 141.00508,
    latitude: -20.975951,
    id: "arp_erq_au",
    icao_code: "YESE",
    iata_country_code: "AU",
    iata_code: "ERQ",
    city_name: "Elrose Mine",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "El Tajín National Airport",
    longitude: -97.460705,
    latitude: 20.602071,
    id: "arp_paz_mx",
    icao_code: "MMPA",
    iata_country_code: "MX",
    iata_code: "PAZ",
    city_name: "Poza Rica",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "El Tari Airport",
    longitude: 123.669963,
    latitude: -10.170699,
    id: "arp_koe_id",
    icao_code: "WATT",
    iata_country_code: "ID",
    iata_code: "KOE",
    city_name: "Kupang",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "El Tehuelche Airport",
    longitude: -65.102707,
    latitude: -42.759002,
    id: "arp_pmy_ar",
    icao_code: "SAVY",
    iata_country_code: "AR",
    iata_code: "PMY",
    city_name: "Puerto Madryn",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "El Tepual Airport",
    longitude: -73.095443,
    latitude: -41.437074,
    id: "arp_pmc_cl",
    icao_code: "SCTE",
    iata_country_code: "CL",
    iata_code: "PMC",
    city_name: "Puerto Montt",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "El Tigre Airport",
    longitude: -64.210001,
    latitude: 8.832877,
    id: "arp_elx_ve",
    icao_code: null,
    iata_country_code: "VE",
    iata_code: "ELX",
    city_name: "El Tigre",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "El Tor Airport",
    longitude: 33.636048,
    latitude: 28.212508,
    id: "arp_elt_eg",
    icao_code: "HETR",
    iata_country_code: "EG",
    iata_code: "ELT",
    city_name: "El-Tor",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "El Trompillo Airport",
    longitude: -63.174251,
    latitude: -17.807487,
    id: "arp_srz_bo",
    icao_code: "SLET",
    iata_country_code: "BO",
    iata_code: "SRZ",
    city_name: "Santa Cruz",
    city: {
      name: "Santa Cruz",
      id: "cit_srz_bo",
      iata_country_code: "BO",
      iata_code: "SRZ",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "El Troncal Airport",
    longitude: -71.389458,
    latitude: 7.02077,
    id: "arp_arq_co",
    icao_code: "SKAT",
    iata_country_code: "CO",
    iata_code: "ARQ",
    city_name: "Arauquita",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "El Tuqui Airport",
    longitude: -71.176542,
    latitude: -30.559272,
    id: "arp_ovl_cl",
    icao_code: "SCOV",
    iata_country_code: "CL",
    iata_code: "OVL",
    city_name: "Ovalle",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Ely Airport",
    longitude: -114.83962,
    latitude: 39.299558,
    id: "arp_ely_us",
    icao_code: "KELY",
    iata_country_code: "US",
    iata_code: "ELY",
    city_name: "Ely",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ely Municipal Airport",
    longitude: -91.831282,
    latitude: 47.824977,
    id: "arp_lyu_us",
    icao_code: "KELO",
    iata_country_code: "US",
    iata_code: "LYU",
    city_name: "Ely",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Emalamo Airport",
    longitude: 125.965794,
    latitude: -2.099769,
    id: "arp_sqn_id",
    icao_code: "WAPN",
    iata_country_code: "ID",
    iata_code: "SQN",
    city_name: "Sanana",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Embessa Airport",
    longitude: 148.7625,
    latitude: -9.44837,
    id: "arp_ems_pg",
    icao_code: "AYEB",
    iata_country_code: "PG",
    iata_code: "EMS",
    city_name: "Embessa",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Emden Airport",
    longitude: 7.227858,
    latitude: 53.390542,
    id: "arp_eme_de",
    icao_code: "EDWE",
    iata_country_code: "DE",
    iata_code: "EME",
    city_name: "Emden",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Emerald Airport",
    longitude: 148.176675,
    latitude: -23.568228,
    id: "arp_emd_au",
    icao_code: "YEML",
    iata_country_code: "AU",
    iata_code: "EMD",
    city_name: "Emerald",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Emirau Airport",
    longitude: 149.975766,
    latitude: -1.653949,
    id: "arp_emi_pg",
    icao_code: "AYEE",
    iata_country_code: "PG",
    iata_code: "EMI",
    city_name: "Emirau Island",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Emmen Air Base",
    longitude: 8.302777,
    latitude: 47.092245,
    id: "arp_eml_ch",
    icao_code: "LSME",
    iata_country_code: "CH",
    iata_code: "EML",
    city_name: "Emmen",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Emmonak Airport",
    longitude: -164.492525,
    latitude: 62.781986,
    id: "arp_emk_us",
    icao_code: "PAEM",
    iata_country_code: "US",
    iata_code: "EMK",
    city_name: "Emmonak",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Emo River Airstrip",
    longitude: 148.0415,
    latitude: -9.1234,
    id: "arp_emo_pg",
    icao_code: "AYEO",
    iata_country_code: "PG",
    iata_code: "EMO",
    city_name: "Emo",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Empangeni Airport",
    longitude: 31.893776,
    latitude: -28.717419,
    id: "arp_emg_za",
    icao_code: "FAEM",
    iata_country_code: "ZA",
    iata_code: "EMG",
    city_name: "Empangeni",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Emporia Municipal Airport",
    longitude: -96.190228,
    latitude: 38.33213,
    id: "arp_emp_us",
    icao_code: "KEMP",
    iata_country_code: "US",
    iata_code: "EMP",
    city_name: "Emporia",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Enarotali Airport",
    longitude: 136.378693,
    latitude: -3.926042,
    id: "arp_ewi_id",
    icao_code: "WABT",
    iata_country_code: "ID",
    iata_code: "EWI",
    city_name: "Enarotali",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Eneabba Airport",
    longitude: 115.246913,
    latitude: -29.831384,
    id: "arp_enb_au",
    icao_code: "YEEB",
    iata_country_code: "AU",
    iata_code: "ENB",
    city_name: "Eneabba",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Enejit Airport",
    longitude: 171.95,
    latitude: 6.083333,
    id: "arp_ejt_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "EJT",
    city_name: "Enejet Island",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Enewetak Auxiliary Airfield",
    longitude: 162.329052,
    latitude: 11.341951,
    id: "arp_ent_mh",
    icao_code: "PKMA",
    iata_country_code: "MH",
    iata_code: "ENT",
    city_name: "Enewetak Island",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Enfidha – Hammamet International Airport",
    longitude: 10.436796,
    latitude: 36.07622,
    id: "arp_nbe_tn",
    icao_code: "DTNH",
    iata_country_code: "TN",
    iata_code: "NBE",
    city_name: "Enfidha",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Engati Airport",
    longitude: 146.104281,
    latitude: -6.917732,
    id: "arp_ega_pg",
    icao_code: "AYEN",
    iata_country_code: "PG",
    iata_code: "EGA",
    city_name: "Engati",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Enid Woodring Regional Airport",
    longitude: -97.790259,
    latitude: 36.378716,
    id: "arp_wdg_us",
    icao_code: "KWDG",
    iata_country_code: "US",
    iata_code: "WDG",
    city_name: "Enid",
    city: {
      name: "Enid",
      id: "cit_wdg_us",
      iata_country_code: "US",
      iata_code: "WDG",
    },
  },
  {
    time_zone: "Africa/Khartoum",
    name: "En Nahud Airport",
    longitude: 28.437388,
    latitude: 12.704345,
    id: "arp_nud_sd",
    icao_code: "HSNH",
    iata_country_code: "SD",
    iata_code: "NUD",
    city_name: "En Nahud",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Enontekiö Airport",
    longitude: 23.422394,
    latitude: 68.361571,
    id: "arp_enf_fi",
    icao_code: "EFET",
    iata_country_code: "FI",
    iata_code: "ENF",
    city_name: "Enontekio",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Enrique Adolfo Jimenez Airport",
    longitude: -79.865998,
    latitude: 9.360455,
    id: "arp_onx_pa",
    icao_code: "MPEJ",
    iata_country_code: "PA",
    iata_code: "ONX",
    city_name: "Colón",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Enrique Malek International Airport",
    longitude: -82.433323,
    latitude: 8.391214,
    id: "arp_dav_pa",
    icao_code: "MPDA",
    iata_country_code: "PA",
    iata_code: "DAV",
    city_name: "David",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Enrique Olaya Herrera Airport",
    longitude: -75.588946,
    latitude: 6.219715,
    id: "arp_eoh_co",
    icao_code: "SKMD",
    iata_country_code: "CO",
    iata_code: "EOH",
    city_name: "Medellín",
    city: {
      name: "Medellin",
      id: "cit_mde_co",
      iata_country_code: "CO",
      iata_code: "MDE",
    },
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Enschede Twente Airport",
    longitude: 6.88282,
    latitude: 52.277872,
    id: "arp_ens_nl",
    icao_code: "EHTW",
    iata_country_code: "NL",
    iata_code: "ENS",
    city_name: "Enschede",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "Ensenada Airport",
    longitude: -116.601476,
    latitude: 31.795846,
    id: "arp_ese_mx",
    icao_code: "MMES",
    iata_country_code: "MX",
    iata_code: "ESE",
    city_name: "Ensenada",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Enshi Xujiaping Airport",
    longitude: 109.485739,
    latitude: 30.319996,
    id: "arp_enh_cn",
    icao_code: "ZHES",
    iata_country_code: "CN",
    iata_code: "ENH",
    city_name: "Enshi",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Entebbe International Airport",
    longitude: 32.442904,
    latitude: 0.043353,
    id: "arp_ebb_ug",
    icao_code: "HUEN",
    iata_country_code: "UG",
    iata_code: "EBB",
    city_name: "Entebbe",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Enterprise Municipal Airport",
    longitude: -85.899454,
    latitude: 31.299124,
    id: "arp_ets_us",
    icao_code: "KEDN",
    iata_country_code: "US",
    iata_code: "ETS",
    city_name: "Enterprise",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Entrance Island Seaplane Base",
    longitude: -133.438989,
    latitude: 57.412176,
    id: "arp_hbh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HBH",
    city_name: "Entrance Island",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Enua Airport",
    longitude: -158.119003,
    latitude: -19.9678,
    id: "arp_aiu_ck",
    icao_code: "NCAT",
    iata_country_code: "CK",
    iata_code: "AIU",
    city_name: "Atiu Island",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Epena Airport",
    longitude: 17.4833,
    latitude: 1.36667,
    id: "arp_epn_cg",
    icao_code: null,
    iata_country_code: "CG",
    iata_code: "EPN",
    city_name: "Epena",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Ephrata Municipal Airport",
    longitude: -119.517755,
    latitude: 47.309202,
    id: "arp_eph_us",
    icao_code: "KEPH",
    iata_country_code: "US",
    iata_code: "EPH",
    city_name: "Ephrata",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Épinal – Mirecourt Airport",
    longitude: 6.066146,
    latitude: 48.323847,
    id: "arp_epl_fr",
    icao_code: "LFSG",
    iata_country_code: "FR",
    iata_code: "EPL",
    city_name: "Epinal",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Eppley Airfield",
    longitude: -95.895429,
    latitude: 41.301917,
    id: "arp_oma_us",
    icao_code: "KOMA",
    iata_country_code: "US",
    iata_code: "OMA",
    city_name: "Omaha",
    city: {
      name: "Omaha",
      id: "cit_oma_us",
      iata_country_code: "US",
      iata_code: "OMA",
    },
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Erandique Airport",
    longitude: -88.437876,
    latitude: 14.236756,
    id: "arp_edq_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "EDQ",
    city_name: "Erandique",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Erave Airport",
    longitude: 143.89987,
    latitude: -6.606623,
    id: "arp_ere_pg",
    icao_code: "AYEV",
    iata_country_code: "PG",
    iata_code: "ERE",
    city_name: "Erave",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Erbil International Airport",
    longitude: 43.959198,
    latitude: 36.235541,
    id: "arp_ebl_iq",
    icao_code: "ORER",
    iata_country_code: "IQ",
    iata_code: "EBL",
    city_name: "Erbil",
    city: null,
  },
  {
    time_zone: "Asia/Famagusta",
    name: "Ercan International Airport",
    longitude: 33.499815,
    latitude: 35.156573,
    id: "arp_ecn_cy",
    icao_code: "LCEN",
    iata_country_code: "CY",
    iata_code: "ECN",
    city_name: "Nicosia",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Erdenet Airport",
    longitude: 104.149066,
    latitude: 48.985422,
    id: "arp_ert_mn",
    icao_code: null,
    iata_country_code: "MN",
    iata_code: "ERT",
    city_name: "Erdenet",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Erechim Airport",
    longitude: -52.268299,
    latitude: -27.661899,
    id: "arp_erm_br",
    icao_code: "SSER",
    iata_country_code: "BR",
    iata_code: "ERM",
    city_name: "Erechim",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Erenhot Saiwusu International Airport",
    longitude: 112.105161,
    latitude: 43.429088,
    id: "arp_erl_cn",
    icao_code: "ZBER",
    iata_country_code: "CN",
    iata_code: "ERL",
    city_name: "Erenhot",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Erfurt-Weimar Airport",
    longitude: 10.958722,
    latitude: 50.97852,
    id: "arp_erf_de",
    icao_code: "EDDE",
    iata_country_code: "DE",
    iata_code: "ERF",
    city_name: "Erfurt",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Erie International Airport",
    longitude: -80.1736,
    latitude: 42.0832,
    id: "arp_eri_us",
    icao_code: "KERI",
    iata_country_code: "US",
    iata_code: "ERI",
    city_name: "Erie",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Erigavo Airport",
    longitude: 47.38753,
    latitude: 10.641948,
    id: "arp_era_so",
    icao_code: "HCMU",
    iata_country_code: "SO",
    iata_code: "ERA",
    city_name: "Erigavo",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Erldunda Airport",
    longitude: 133.25398,
    latitude: -25.205883,
    id: "arp_edd_au",
    icao_code: "YERL",
    iata_country_code: "AU",
    iata_code: "EDD",
    city_name: "Erldunda",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Ernabella Airport",
    longitude: 132.179179,
    latitude: -26.263749,
    id: "arp_erb_au",
    icao_code: "YERN",
    iata_country_code: "AU",
    iata_code: "ERB",
    city_name: "Ernabella",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Ernest A. Love Field",
    longitude: -112.422585,
    latitude: 34.652239,
    id: "arp_prc_us",
    icao_code: "KPRC",
    iata_country_code: "US",
    iata_code: "PRC",
    city_name: "Prescott",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Ernesto Cortissoz International Airport",
    longitude: -74.778715,
    latitude: 10.888256,
    id: "arp_baq_co",
    icao_code: "SKBQ",
    iata_country_code: "CO",
    iata_code: "BAQ",
    city_name: "Barranquilla",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Eros Airport",
    longitude: 17.080282,
    latitude: -22.60831,
    id: "arp_ers_na",
    icao_code: "FYWE",
    iata_country_code: "NA",
    iata_code: "ERS",
    city_name: "Windhoek",
    city: {
      name: "Windhoek",
      id: "cit_wdh_na",
      iata_country_code: "NA",
      iata_code: "WDH",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Errol Airport",
    longitude: -71.164222,
    latitude: 44.792524,
    id: "arp_err_us",
    icao_code: "KERR",
    iata_country_code: "US",
    iata_code: "ERR",
    city_name: "Errol",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Erume Airport",
    longitude: 146.967,
    latitude: -8.25,
    id: "arp_eru_pg",
    icao_code: "AYER",
    iata_country_code: "PG",
    iata_code: "ERU",
    city_name: "Erume",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Erzincan Airport",
    longitude: 39.525294,
    latitude: 39.713456,
    id: "arp_erc_tr",
    icao_code: "LTCD",
    iata_country_code: "TR",
    iata_code: "ERC",
    city_name: "Erzincan",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Erzurum Airport",
    longitude: 41.173317,
    latitude: 39.957507,
    id: "arp_erz_tr",
    icao_code: "LTCE",
    iata_country_code: "TR",
    iata_code: "ERZ",
    city_name: "Erzurum",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Esa'ala Airport",
    longitude: 150.954766,
    latitude: -9.874951,
    id: "arp_esa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ESA",
    city_name: "Esa'ala",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Esbjerg Airport",
    longitude: 8.553366,
    latitude: 55.525867,
    id: "arp_ebj_dk",
    icao_code: "EKEB",
    iata_country_code: "DK",
    iata_code: "EBJ",
    city_name: "Esbjerg",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Esenboğa International Airport",
    longitude: 32.993385,
    latitude: 40.12627,
    id: "arp_esb_tr",
    icao_code: "LTAC",
    iata_country_code: "TR",
    iata_code: "ESB",
    city_name: "Ankara",
    city: {
      name: "Ankara",
      id: "cit_ank_tr",
      iata_country_code: "TR",
      iata_code: "ANK",
    },
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Eskilstuna Airport",
    longitude: 16.708429,
    latitude: 59.35025,
    id: "arp_ekt_se",
    icao_code: "ESSU",
    iata_country_code: "SE",
    iata_code: "EKT",
    city_name: "Eskilstuna",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Eskişehir Air Base Airport",
    longitude: 30.5821,
    latitude: 39.7841,
    id: "arp_esk_tr",
    icao_code: "LTBI",
    iata_country_code: "TR",
    iata_code: "ESK",
    city_name: "Eskisehir",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Esler Regional Airport",
    longitude: -92.295764,
    latitude: 31.394614,
    id: "arp_esf_us",
    icao_code: "KESF",
    iata_country_code: "US",
    iata_code: "ESF",
    city_name: "Alexandria",
    city: {
      name: "Alexandria",
      id: "cit_aex_us",
      iata_country_code: "US",
      iata_code: "AEX",
    },
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Esperadinha Airport",
    longitude: -24.745257,
    latitude: 14.86496,
    id: "arp_bvr_cv",
    icao_code: "GVBR",
    iata_country_code: "CV",
    iata_code: "BVR",
    city_name: "Brava Island",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Esperance Airport",
    longitude: 121.826716,
    latitude: -33.683563,
    id: "arp_epr_au",
    icao_code: "YESP",
    iata_country_code: "AU",
    iata_code: "EPR",
    city_name: "Esperance",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Espinosa Airport",
    longitude: -42.830551,
    latitude: -14.933601,
    id: "arp_esi_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "ESI",
    city_name: "Espinosa",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "Esquel Airport",
    longitude: -71.141703,
    latitude: -42.906812,
    id: "arp_eqs_ar",
    icao_code: "SAVE",
    iata_country_code: "AR",
    iata_code: "EQS",
    city_name: "Esquel",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Essaouira-Mogador Airport",
    longitude: -9.684937,
    latitude: 31.400339,
    id: "arp_esu_ma",
    icao_code: "GMMI",
    iata_country_code: "MA",
    iata_code: "ESU",
    city_name: "Essaouira",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Essen/Mülheim Airport",
    longitude: 6.938708,
    latitude: 51.403009,
    id: "arp_ess_de",
    icao_code: "EDLE",
    iata_country_code: "DE",
    iata_code: "ESS",
    city_name: "Essen",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Essex County Airport",
    longitude: -74.280229,
    latitude: 40.874857,
    id: "arp_cdw_us",
    icao_code: "KCDW",
    iata_country_code: "US",
    iata_code: "CDW",
    city_name: "Caldwell",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Estevan Airport",
    longitude: -102.964462,
    latitude: 49.208604,
    id: "arp_yen_ca",
    icao_code: "CYEN",
    iata_country_code: "CA",
    iata_code: "YEN",
    city_name: "Estevan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Estherville Municipal Airport",
    longitude: -94.747315,
    latitude: 43.403632,
    id: "arp_est_us",
    icao_code: "KEST",
    iata_country_code: "US",
    iata_code: "EST",
    city_name: "Estherville",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Etadunna Airport",
    longitude: 138.589365,
    latitude: -28.742692,
    id: "arp_etd_au",
    icao_code: "YEDA",
    iata_country_code: "AU",
    iata_code: "ETD",
    city_name: "Etadunna",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Etimesgut Air Base",
    longitude: 32.688598,
    latitude: 39.949798,
    id: "arp_ank_tr",
    icao_code: "LTAD",
    iata_country_code: "TR",
    iata_code: "ANK",
    city_name: "Ankara",
    city: {
      name: "Ankara",
      id: "cit_ank_tr",
      iata_country_code: "TR",
      iata_code: "ANK",
    },
  },
  {
    time_zone: "Australia/Eucla",
    name: "Eucla Airport",
    longitude: 128.88014,
    latitude: -31.703417,
    id: "arp_euc_au",
    icao_code: "YECL",
    iata_country_code: "AU",
    iata_code: "EUC",
    city_name: "Eucla",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Eugene Airport",
    longitude: -123.213949,
    latitude: 44.12319,
    id: "arp_eug_us",
    icao_code: "KEUG",
    iata_country_code: "US",
    iata_code: "EUG",
    city_name: "Eugene",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Eugenio María de Hostos Airport",
    longitude: -67.148445,
    latitude: 18.255523,
    id: "arp_maz_pr",
    icao_code: "TJMZ",
    iata_country_code: "PR",
    iata_code: "MAZ",
    city_name: "Mayaguez",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Eunice Airport",
    longitude: -92.426042,
    latitude: 30.469399,
    id: "arp_uce_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UCE",
    city_name: "Eunice",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Eureka Airport",
    longitude: -116.003858,
    latitude: 39.603267,
    id: "arp_eue_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EUE",
    city_name: "Eureka",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Eureka Airport",
    longitude: -85.821608,
    latitude: 79.994986,
    id: "arp_yeu_ca",
    icao_code: "CYEU",
    iata_country_code: "CA",
    iata_code: "YEU",
    city_name: "Eureka",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Eurico de Aguiar Salles Airport",
    longitude: -40.284961,
    latitude: -20.257852,
    id: "arp_vix_br",
    icao_code: "SBVT",
    iata_country_code: "BR",
    iata_code: "VIX",
    city_name: "Vitória",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "EuroAirport Basel Mulhouse Freiburg",
    longitude: 7.525496,
    latitude: 47.59818,
    id: "arp_mlh_fr",
    icao_code: "LFSB",
    iata_country_code: "FR",
    iata_code: "MLH",
    city_name: "Mulhouse/Basel",
    city: {
      name: "Basel/Mulhouse",
      id: "cit_eap_ch",
      iata_country_code: "CH",
      iata_code: "EAP",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "EuroAirport Basel-Mulhouse-Freiburg",
    longitude: 7.52733,
    latitude: 47.59409,
    id: "arp_bsl_fr",
    icao_code: "LFSB",
    iata_country_code: "FR",
    iata_code: "BSL",
    city_name: "Basel",
    city: {
      name: "Basel/Mulhouse",
      id: "cit_eap_ch",
      iata_country_code: "CH",
      iata_code: "EAP",
    },
  },
  {
    time_zone: "Australia/Darwin",
    name: "Eva Downs Airport",
    longitude: 134.862108,
    latitude: -18.000704,
    id: "arp_evd_au",
    icao_code: "YEVA",
    iata_country_code: "AU",
    iata_code: "EVD",
    city_name: "Eva Downs",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Evans Head Airport",
    longitude: 153.419183,
    latitude: -29.098647,
    id: "arp_evh_au",
    icao_code: "YEVD",
    iata_country_code: "AU",
    iata_code: "EVH",
    city_name: "Evans Head",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Evanston-Uinta County Burns Field",
    longitude: -111.033565,
    latitude: 41.274871,
    id: "arp_evw_us",
    icao_code: "KEVW",
    iata_country_code: "US",
    iata_code: "EVW",
    city_name: "Evanston",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Evansville Regional Airport",
    longitude: -87.530339,
    latitude: 38.041088,
    id: "arp_evv_us",
    icao_code: "KEVV",
    iata_country_code: "US",
    iata_code: "EVV",
    city_name: "Evansville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Eveleth-Virginia Municipal Airport",
    longitude: -92.501158,
    latitude: 47.425861,
    id: "arp_evm_us",
    icao_code: "KEVM",
    iata_country_code: "US",
    iata_code: "EVM",
    city_name: "Eveleth",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Evelio Javier Airport",
    longitude: 121.933001,
    latitude: 10.766972,
    id: "arp_euq_ph",
    icao_code: "RPVS",
    iata_country_code: "PH",
    iata_code: "EUQ",
    city_name: "San Jose de Buenavista",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Everett-Stewart Regional Airport",
    longitude: -88.983903,
    latitude: 36.382269,
    id: "arp_ucy_us",
    icao_code: "KUCY",
    iata_country_code: "US",
    iata_code: "UCY",
    city_name: "Union City",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Évreux-Fauville Air Base Airport",
    longitude: 1.21986,
    latitude: 49.028702,
    id: "arp_evx_fr",
    icao_code: "LFOE",
    iata_country_code: "FR",
    iata_code: "EVX",
    city_name: "Evreux",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Ewer Airport",
    longitude: 138.084708,
    latitude: -5.493125,
    id: "arp_ewe_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "EWE",
    city_name: "Ewer",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Ewo Airport",
    longitude: 14.802724,
    latitude: -0.85555,
    id: "arp_ewo_cg",
    icao_code: "FCOE",
    iata_country_code: "CG",
    iata_code: "EWO",
    city_name: "Ewo",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Excursion Inlet Seaplane Base",
    longitude: -135.449019,
    latitude: 58.420501,
    id: "arp_exi_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EXI",
    city_name: "Excursion Inlet",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Exeter Airport",
    longitude: -3.416285,
    latitude: 50.7336,
    id: "arp_ext_gb",
    icao_code: "EGTE",
    iata_country_code: "GB",
    iata_code: "EXT",
    city_name: "Exeter",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Exmouth Airport",
    longitude: 114.103199,
    latitude: -22.040102,
    id: "arp_exm_au",
    icao_code: "YEXM",
    iata_country_code: "AU",
    iata_code: "EXM",
    city_name: "Exmouth",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Exuma International Airport",
    longitude: -75.876432,
    latitude: 23.562674,
    id: "arp_ggt_bs",
    icao_code: "MYEF",
    iata_country_code: "BS",
    iata_code: "GGT",
    city_name: "George Town",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Faaite Airport",
    longitude: -145.330219,
    latitude: -16.686753,
    id: "arp_fac_pf",
    icao_code: "NTKF",
    iata_country_code: "PF",
    iata_code: "FAC",
    city_name: "Faaite",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Fabio Alberto León Bentley Airport",
    longitude: -70.233862,
    latitude: 1.253492,
    id: "arp_mvp_co",
    icao_code: "SKMU",
    iata_country_code: "CO",
    iata_code: "MVP",
    city_name: "Mitú",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Fada N'gourma Airport",
    longitude: 0.365763,
    latitude: 12.042695,
    id: "arp_fng_bf",
    icao_code: "DFEF",
    iata_country_code: "BF",
    iata_code: "FNG",
    city_name: "Fada N'gourma",
    city: null,
  },
  {
    time_zone: "Pacific/Apia",
    name: "Fagali'i Airport",
    longitude: -171.740968,
    latitude: -13.84844,
    id: "arp_fgi_ws",
    icao_code: "NSFI",
    iata_country_code: "WS",
    iata_code: "FGI",
    city_name: "Apia",
    city: {
      name: "Apia",
      id: "cit_apw_ws",
      iata_country_code: "WS",
      iata_code: "APW",
    },
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Fagurhólsmýri Airport",
    longitude: -16.642602,
    latitude: 63.876998,
    id: "arp_fag_is",
    icao_code: "BIFM",
    iata_country_code: "IS",
    iata_code: "FAG",
    city_name: "Fagurhólsmýri",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Fahud Airport",
    longitude: 56.489516,
    latitude: 22.348444,
    id: "arp_fau_om",
    icao_code: "OOFD",
    iata_country_code: "OM",
    iata_code: "FAU",
    city_name: "Fahud",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Fairbanks International Airport",
    longitude: -147.859759,
    latitude: 64.815758,
    id: "arp_fai_us",
    icao_code: "PAFA",
    iata_country_code: "US",
    iata_code: "FAI",
    city_name: "Fairbanks",
    city: {
      name: "Fairbanks",
      id: "cit_fai_us",
      iata_country_code: "US",
      iata_code: "FAI",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Fairbury Municipal Airport",
    longitude: -97.168461,
    latitude: 40.178521,
    id: "arp_fby_us",
    icao_code: "KFBY",
    iata_country_code: "US",
    iata_code: "FBY",
    city_name: "Fairbury",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fairchild Air Force Base",
    longitude: -117.65195,
    latitude: 47.616155,
    id: "arp_ska_us",
    icao_code: "KSKA",
    iata_country_code: "US",
    iata_code: "SKA",
    city_name: "Spokane",
    city: {
      name: "Spokane",
      id: "cit_geg_us",
      iata_country_code: "US",
      iata_code: "GEG",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Fairfield Municipal Airport",
    longitude: -91.97989,
    latitude: 41.05436,
    id: "arp_ffl_us",
    icao_code: "KFFL",
    iata_country_code: "US",
    iata_code: "FFL",
    city_name: "Fairfield",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Fair Isle Airport",
    longitude: -1.629522,
    latitude: 59.534862,
    id: "arp_fie_gb",
    icao_code: "EGEF",
    iata_country_code: "GB",
    iata_code: "FIE",
    city_name: "Fair Isle",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fairmont Hot Springs Airport",
    longitude: -115.873739,
    latitude: 50.328372,
    id: "arp_ycz_ca",
    icao_code: "CYCZ",
    iata_country_code: "CA",
    iata_code: "YCZ",
    city_name: "Fairmont Hot Springs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fairmont Municipal Airport",
    longitude: -94.416391,
    latitude: 43.644464,
    id: "arp_frm_us",
    icao_code: "KFRM",
    iata_country_code: "US",
    iata_code: "FRM",
    city_name: "Fairmont",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fairview Airport",
    longitude: -118.434841,
    latitude: 56.081175,
    id: "arp_zfw_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZFW",
    city_name: "Fairview",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Faisalabad International Airport",
    longitude: 72.991183,
    latitude: 31.364021,
    id: "arp_lyp_pk",
    icao_code: "OPFA",
    iata_country_code: "PK",
    iata_code: "LYP",
    city_name: "Faisalabad",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Fakahina Airport",
    longitude: -140.164875,
    latitude: -15.992047,
    id: "arp_fhz_pf",
    icao_code: "NTKH",
    iata_country_code: "PF",
    iata_code: "FHZ",
    city_name: "Fakahina",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Fakarava Airport",
    longitude: -145.657243,
    latitude: -16.054225,
    id: "arp_fav_pf",
    icao_code: "NTGF",
    iata_country_code: "PF",
    iata_code: "FAV",
    city_name: "Fakarava",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Fakfak Torea Airport",
    longitude: 132.265279,
    latitude: -2.919269,
    id: "arp_fkq_id",
    icao_code: "WASF",
    iata_country_code: "ID",
    iata_code: "FKQ",
    city_name: "Fak Fak",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Falcone-Borsellino Airport",
    longitude: 13.100469,
    latitude: 38.183488,
    id: "arp_pmo_it",
    icao_code: "LICJ",
    iata_country_code: "IT",
    iata_code: "PMO",
    city_name: "Palermo",
    city: null,
  },
  {
    time_zone: "Pacific/Apia",
    name: "Faleolo International Airport",
    longitude: -172.008052,
    latitude: -13.830224,
    id: "arp_apw_ws",
    icao_code: "NSFA",
    iata_country_code: "WS",
    iata_code: "APW",
    city_name: "Apia",
    city: {
      name: "Apia",
      id: "cit_apw_ws",
      iata_country_code: "WS",
      iata_code: "APW",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fallon Municipal Airport",
    longitude: -118.748655,
    latitude: 39.499326,
    id: "arp_flx_us",
    icao_code: "KFLX",
    iata_country_code: "US",
    iata_code: "FLX",
    city_name: "Fallon",
    city: {
      name: "Fallon",
      id: "cit_nfl_us",
      iata_country_code: "US",
      iata_code: "NFL",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fallon Naval Air Station",
    longitude: -118.700996,
    latitude: 39.416599,
    id: "arp_nfl_us",
    icao_code: "KNFL",
    iata_country_code: "US",
    iata_code: "NFL",
    city_name: "Fallon",
    city: {
      name: "Fallon",
      id: "cit_nfl_us",
      iata_country_code: "US",
      iata_code: "NFL",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Falls International Airport",
    longitude: -93.401715,
    latitude: 48.566226,
    id: "arp_inl_us",
    icao_code: "KINL",
    iata_country_code: "US",
    iata_code: "INL",
    city_name: "International Falls",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "False Island Seaplane Base",
    longitude: -135.213224,
    latitude: 57.532177,
    id: "arp_fak_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FAK",
    city_name: "False Island",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "False Pass Airport",
    longitude: -163.409148,
    latitude: 54.848501,
    id: "arp_kfp_us",
    icao_code: "PAKF",
    iata_country_code: "US",
    iata_code: "KFP",
    city_name: "False Pass",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Fane Airport",
    longitude: 147.085359,
    latitude: -8.550961,
    id: "arp_fne_pg",
    icao_code: "AYFA",
    iata_country_code: "PG",
    iata_code: "FNE",
    city_name: "Fane",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Fangatau Airport",
    longitude: -140.887951,
    latitude: -15.821319,
    id: "arp_fgu_pf",
    icao_code: "NTGB",
    iata_country_code: "PF",
    iata_code: "FGU",
    city_name: "Fangatau",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Farafangana Airport",
    longitude: 47.820603,
    latitude: -22.805295,
    id: "arp_rva_mg",
    icao_code: "FMSG",
    iata_country_code: "MG",
    iata_code: "RVA",
    city_name: "Farafangana",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Farah Airport",
    longitude: 62.161568,
    latitude: 32.368113,
    id: "arp_fah_af",
    icao_code: "OAFR",
    iata_country_code: "AF",
    iata_code: "FAH",
    city_name: "Farah",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Faranah Airport",
    longitude: -10.769175,
    latitude: 10.035855,
    id: "arp_faa_gn",
    icao_code: "GUFH",
    iata_country_code: "GN",
    iata_code: "FAA",
    city_name: "Faranah",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Farewell Airport",
    longitude: -153.894008,
    latitude: 62.507339,
    id: "arp_fwl_us",
    icao_code: "PAFW",
    iata_country_code: "US",
    iata_code: "FWL",
    city_name: "Farewell",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Faribault Municipal Airport",
    longitude: -93.310375,
    latitude: 44.328094,
    id: "arp_fbl_us",
    icao_code: "KFBL",
    iata_country_code: "US",
    iata_code: "FBL",
    city_name: "Faribault",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Farmington Regional Airport",
    longitude: -90.430073,
    latitude: 37.760232,
    id: "arp_fam_us",
    icao_code: "KFAM",
    iata_country_code: "US",
    iata_code: "FAM",
    city_name: "Farmington",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Farnborough Airport",
    longitude: -0.776646,
    latitude: 51.279456,
    id: "arp_fab_gb",
    icao_code: "EGLF",
    iata_country_code: "GB",
    iata_code: "FAB",
    city_name: "Farnborough",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Faro Airport",
    longitude: -7.967814,
    latitude: 37.015998,
    id: "arp_fao_pt",
    icao_code: "LPFR",
    iata_country_code: "PT",
    iata_code: "FAO",
    city_name: "Faro",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Faro Airport",
    longitude: -133.377906,
    latitude: 62.208107,
    id: "arp_zfa_ca",
    icao_code: "CZFA",
    iata_country_code: "CA",
    iata_code: "ZFA",
    city_name: "Faro",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Fasa Airport",
    longitude: 53.721971,
    latitude: 28.895103,
    id: "arp_faz_ir",
    icao_code: "OISF",
    iata_country_code: "IR",
    iata_code: "FAZ",
    city_name: "Fasa",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Fascene Airport",
    longitude: 48.314598,
    latitude: -13.31216,
    id: "arp_nos_mg",
    icao_code: "FMNN",
    iata_country_code: "MG",
    iata_code: "NOS",
    city_name: "Nosy Be",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Fáskrúðsfjörður Airport",
    longitude: -14.051416,
    latitude: 64.932044,
    id: "arp_fas_is",
    icao_code: "BIFF",
    iata_country_code: "IS",
    iata_code: "FAS",
    city_name: "Fáskrúðsfjörður",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Fatmawati Soekarno Airport",
    longitude: 102.338328,
    latitude: -3.86225,
    id: "arp_bks_id",
    icao_code: "WIGG",
    iata_country_code: "ID",
    iata_code: "BKS",
    city_name: "Bengkulu",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Faya-Largeau Airport",
    longitude: 19.110305,
    latitude: 17.917405,
    id: "arp_fyt_td",
    icao_code: "FTTY",
    iata_country_code: "TD",
    iata_code: "FYT",
    city_name: "Faya-Largeau",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fayetteville Municipal Airport",
    longitude: -86.565131,
    latitude: 35.05909,
    id: "arp_fym_us",
    icao_code: "KFYM",
    iata_country_code: "US",
    iata_code: "FYM",
    city_name: "Fayetteville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Fayetteville Regional Airport",
    longitude: -78.883691,
    latitude: 34.990947,
    id: "arp_fay_us",
    icao_code: "KFAY",
    iata_country_code: "US",
    iata_code: "FAY",
    city_name: "Fayetteville",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Fayzabad Airport",
    longitude: 70.518576,
    latitude: 37.121302,
    id: "arp_fbd_af",
    icao_code: "OAFZ",
    iata_country_code: "AF",
    iata_code: "FBD",
    city_name: "Fayzabad",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Fazenda Piraguassu Airport",
    longitude: -51.684777,
    latitude: -10.860756,
    id: "arp_pbx_br",
    icao_code: "SWPQ",
    iata_country_code: "BR",
    iata_code: "PBX",
    city_name: "Porto Alegre do Norte",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Fazenda Tucunaré Airport",
    longitude: -58.867778,
    latitude: -13.472024,
    id: "arp_azl_br",
    icao_code: "SWTU",
    iata_country_code: "BR",
    iata_code: "AZL",
    city_name: "Sapezal",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Fderik Airport",
    longitude: -12.730527,
    latitude: 22.670037,
    id: "arp_fgd_mr",
    icao_code: "GQPF",
    iata_country_code: "MR",
    iata_code: "FGD",
    city_name: "Fderik",
    city: null,
  },
  {
    time_zone: "America/Kralendijk",
    name: "F. D. Roosevelt Airport",
    longitude: -62.979816,
    latitude: 17.495673,
    id: "arp_eux_an",
    icao_code: "TNCE",
    iata_country_code: "AN",
    iata_code: "EUX",
    city_name: "Sint Eustatius",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Federico Fellini International Airport",
    longitude: 12.613552,
    latitude: 44.020909,
    id: "arp_rmi_it",
    icao_code: "LIPR",
    iata_country_code: "IT",
    iata_code: "RMI",
    city_name: "Rimini",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Federico Garcia Lorca Airport",
    longitude: -3.778146,
    latitude: 37.188171,
    id: "arp_grx_es",
    icao_code: "LEGR",
    iata_country_code: "ES",
    iata_code: "GRX",
    city_name: "Granada",
    city: null,
  },
  {
    time_zone: "America/Rio_Branco",
    name: "Feijó Airport",
    longitude: -70.345605,
    latitude: -8.141991,
    id: "arp_fej_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "FEJ",
    city_name: "Feijó",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Feira de Santana Airport",
    longitude: -38.907669,
    latitude: -12.20115,
    id: "arp_fec_br",
    icao_code: "SBFE",
    iata_country_code: "BR",
    iata_code: "FEC",
    city_name: "Feira de Santana",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Felker Army Airfield",
    longitude: -76.608182,
    latitude: 37.132824,
    id: "arp_faf_us",
    icao_code: "KFAF",
    iata_country_code: "US",
    iata_code: "FAF",
    city_name: "Fort Eustis",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Felts Field",
    longitude: -117.321483,
    latitude: 47.681665,
    id: "arp_sff_us",
    icao_code: "KSFF",
    iata_country_code: "US",
    iata_code: "SFF",
    city_name: "Spokane",
    city: {
      name: "Spokane",
      id: "cit_geg_us",
      iata_country_code: "US",
      iata_code: "GEG",
    },
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Fera Island Airport",
    longitude: 159.577124,
    latitude: -8.107732,
    id: "arp_fre_sb",
    icao_code: "AGGF",
    iata_country_code: "SB",
    iata_code: "FRE",
    city_name: "Fera Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Feramin Airport",
    longitude: 141.698746,
    latitude: -5.208107,
    id: "arp_frq_pg",
    icao_code: "AYFE",
    iata_country_code: "PG",
    iata_code: "FRQ",
    city_name: "Feramin",
    city: null,
  },
  {
    time_zone: "Asia/Tashkent",
    name: "Fergana International Airport",
    longitude: 71.744908,
    latitude: 40.358365,
    id: "arp_feg_uz",
    icao_code: null,
    iata_country_code: "UZ",
    iata_code: "FEG",
    city_name: "Fergana",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fergus Falls Municipal Airport",
    longitude: -96.155446,
    latitude: 46.284717,
    id: "arp_ffm_us",
    icao_code: "KFFM",
    iata_country_code: "US",
    iata_code: "FFM",
    city_name: "Fergus Falls",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Ferkessédougou Airport",
    longitude: -5.2,
    latitude: 9.6,
    id: "arp_fek_ci",
    icao_code: "DIFK",
    iata_country_code: "CI",
    iata_code: "FEK",
    city_name: "Ferkessedougou",
    city: null,
  },
  {
    time_zone: "America/Noronha",
    name: "Fernando de Noronha Airport",
    longitude: -32.423844,
    latitude: -3.854772,
    id: "arp_fen_br",
    icao_code: "SBFN",
    iata_country_code: "BR",
    iata_code: "FEN",
    city_name: "Fernando de Noronha",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Fernando Luis Ribas Dominicci Airport",
    longitude: -66.098011,
    latitude: 18.45628,
    id: "arp_sig_pr",
    icao_code: "TJIG",
    iata_country_code: "PR",
    iata_code: "SIG",
    city_name: "San Juan",
    city: {
      name: "San Juan",
      id: "cit_sju_pr",
      iata_country_code: "PR",
      iata_code: "SJU",
    },
  },
  {
    time_zone: null,
    name: "Ferry Port",
    longitude: null,
    latitude: null,
    id: "arp_nsz_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "NSZ",
    city_name: null,
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Fès–Saïss Airport",
    longitude: -4.978832,
    latitude: 33.928287,
    id: "arp_fez_ma",
    icao_code: "GMFF",
    iata_country_code: "MA",
    iata_code: "FEZ",
    city_name: "Fes",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Fetlar Airport",
    longitude: -0.8731,
    latitude: 60.601309,
    id: "arp_fea_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "FEA",
    city_name: "Fetlar",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Fianarantsoa Airport",
    longitude: 47.111683,
    latitude: -21.441355,
    id: "arp_wfi_mg",
    icao_code: "FMSF",
    iata_country_code: "MG",
    iata_code: "WFI",
    city_name: "Fianarantsoa",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Ficksburg Sentraoes Airport",
    longitude: 27.908899,
    latitude: -28.823099,
    id: "arp_fcb_za",
    icao_code: "FAFB",
    iata_country_code: "ZA",
    iata_code: "FCB",
    city_name: "Ficksburg",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Figari-Sud-Corse Airport",
    longitude: 9.098069,
    latitude: 41.500609,
    id: "arp_fsc_fr",
    icao_code: "LFKF",
    iata_country_code: "FR",
    iata_code: "FSC",
    city_name: "Figari",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Filadelfia Airport",
    longitude: -60.053552,
    latitude: -22.360518,
    id: "arp_flm_py",
    icao_code: "SGFI",
    iata_country_code: "PY",
    iata_code: "FLM",
    city_name: "Filadelfia",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Fillmore Municipal Airport",
    longitude: -112.360625,
    latitude: 38.9602,
    id: "arp_fil_us",
    icao_code: "KFOM",
    iata_country_code: "US",
    iata_code: "FIL",
    city_name: "Fillmore",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Fincha Airport",
    longitude: 37.376882,
    latitude: 9.583788,
    id: "arp_fnh_et",
    icao_code: "HAFN",
    iata_country_code: "ET",
    iata_code: "FNH",
    city_name: "Fincha",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Findlay Airport",
    longitude: -83.668924,
    latitude: 41.014828,
    id: "arp_fdy_us",
    icao_code: "KFDY",
    iata_country_code: "US",
    iata_code: "FDY",
    city_name: "Findlay",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Finke Airport",
    longitude: 134.582663,
    latitude: -25.593385,
    id: "arp_fik_au",
    icao_code: "YFNE",
    iata_country_code: "AU",
    iata_code: "FIK",
    city_name: "Finke",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Finley Airport",
    longitude: 145.565538,
    latitude: -35.657173,
    id: "arp_fly_au",
    icao_code: "YFIL",
    iata_country_code: "AU",
    iata_code: "FLY",
    city_name: "Finley",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Finschhafen Airport",
    longitude: 147.854135,
    latitude: -6.622281,
    id: "arp_fin_pg",
    icao_code: "AYFI",
    iata_country_code: "PG",
    iata_code: "FIN",
    city_name: "Finschhafen",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "First Flight Airport",
    longitude: -75.671424,
    latitude: 36.018067,
    id: "arp_ffa_us",
    icao_code: "KFFA",
    iata_country_code: "US",
    iata_code: "FFA",
    city_name: "Kill Devil Hills",
    city: null,
  },
  {
    time_zone: "Pacific/Pago_Pago",
    name: "Fitiuta Airport",
    longitude: -169.424663,
    latitude: -14.216309,
    id: "arp_fti_as",
    icao_code: "NSFQ",
    iata_country_code: "AS",
    iata_code: "FTI",
    city_name: "Fitiuta",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Fitzroy Crossing Airport",
    longitude: 125.560674,
    latitude: -18.180247,
    id: "arp_fiz_au",
    icao_code: "YFTZ",
    iata_country_code: "AU",
    iata_code: "FIZ",
    city_name: "Fitzroy Crossing",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Flabob Airport",
    longitude: -117.410129,
    latitude: 33.989276,
    id: "arp_rir_us",
    icao_code: "KRIR",
    iata_country_code: "US",
    iata_code: "RIR",
    city_name: "Riverside",
    city: {
      name: "Riverside",
      id: "cit_ral_us",
      iata_country_code: "US",
      iata_code: "RAL",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Flagstaff Pulliam Airport",
    longitude: -111.67119,
    latitude: 35.138902,
    id: "arp_flg_us",
    icao_code: "KFLG",
    iata_country_code: "US",
    iata_code: "FLG",
    city_name: "Flagstaff",
    city: {
      name: "Grand Canyon",
      id: "cit_gcn_us",
      iata_country_code: "US",
      iata_code: "GCN",
    },
  },
  {
    time_zone: "America/Kralendijk",
    name: "Flamingo International Airport",
    longitude: -68.27269,
    latitude: 12.132192,
    id: "arp_bon_an",
    icao_code: "TNCB",
    iata_country_code: "AN",
    iata_code: "BON",
    city_name: "Kralendijk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Flat Airport",
    longitude: -157.988564,
    latitude: 62.452724,
    id: "arp_flt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FLT",
    city_name: "Flat",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Flensburg-Schäferhaus Airport",
    longitude: 9.378355,
    latitude: 54.77361,
    id: "arp_flf_de",
    icao_code: "EDXF",
    iata_country_code: "DE",
    iata_code: "FLF",
    city_name: "Flensburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fletcher Field",
    longitude: -90.514844,
    latitude: 34.290924,
    id: "arp_ckm_us",
    icao_code: "KCKM",
    iata_country_code: "US",
    iata_code: "CKM",
    city_name: "Clarksdale",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Flinders Island Airport",
    longitude: 148.004953,
    latitude: -40.091177,
    id: "arp_fls_au",
    icao_code: "YFLI",
    iata_country_code: "AU",
    iata_code: "FLS",
    city_name: "Flinders Island",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Flin Flon Airport",
    longitude: -101.680958,
    latitude: 54.677597,
    id: "arp_yfo_ca",
    icao_code: "CYFO",
    iata_country_code: "CA",
    iata_code: "YFO",
    city_name: "Flin Flon",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Flora Valley Airport",
    longitude: 128.450541,
    latitude: -18.297681,
    id: "arp_fvl_au",
    icao_code: "YFLO",
    iata_country_code: "AU",
    iata_code: "FVL",
    city_name: "Flora Valley",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Florence Municipal Airport",
    longitude: -124.111152,
    latitude: 43.982859,
    id: "arp_fmu_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FMU",
    city_name: "Florence",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Florence Regional Airport",
    longitude: -79.726086,
    latitude: 34.189364,
    id: "arp_flo_us",
    icao_code: "KFLO",
    iata_country_code: "US",
    iata_code: "FLO",
    city_name: "Florence",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Flores Airport",
    longitude: -31.13144,
    latitude: 39.456485,
    id: "arp_flw_pt",
    icao_code: "LPFL",
    iata_country_code: "PT",
    iata_code: "FLW",
    city_name: "Flores Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Florida Keys Marathon International Airport",
    longitude: -81.050912,
    latitude: 24.725644,
    id: "arp_mth_us",
    icao_code: "KMTH",
    iata_country_code: "US",
    iata_code: "MTH",
    city_name: "Marathon",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Florø Airport",
    longitude: 5.02273,
    latitude: 61.584091,
    id: "arp_fro_no",
    icao_code: "ENFL",
    iata_country_code: "NO",
    iata_code: "FRO",
    city_name: "Floro",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Flotta Isle Airport",
    longitude: -3.14278,
    latitude: 58.825802,
    id: "arp_flh_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "FLH",
    city_name: "Flotta",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Floyd Bennett Memorial Airport",
    longitude: -73.60828,
    latitude: 43.341278,
    id: "arp_gfl_us",
    icao_code: "KGFL",
    iata_country_code: "US",
    iata_code: "GFL",
    city_name: "Glens Falls",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Flying Cloud Airport",
    longitude: -93.457686,
    latitude: 44.828011,
    id: "arp_fcm_us",
    icao_code: "KFCM",
    iata_country_code: "US",
    iata_code: "FCM",
    city_name: "St Paul/Minneapolis",
    city: {
      name: "Minneapolis/St Paul",
      id: "cit_msp_us",
      iata_country_code: "US",
      iata_code: "MSP",
    },
  },
  {
    time_zone: "Europe/Rome",
    name: 'Foggia "Gino Lisa" Airport',
    longitude: 15.535292,
    latitude: 41.433236,
    id: "arp_fog_it",
    icao_code: "LIBF",
    iata_country_code: "IT",
    iata_code: "FOG",
    city_name: "Foggia",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Fond-du-Lac Airport",
    longitude: -107.183139,
    latitude: 59.334598,
    id: "arp_zfd_ca",
    icao_code: "CZFD",
    iata_country_code: "CA",
    iata_code: "ZFD",
    city_name: "Chah Bahar",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fond du Lac County Airport",
    longitude: -88.485921,
    latitude: 43.770581,
    id: "arp_fld_us",
    icao_code: "KFLD",
    iata_country_code: "US",
    iata_code: "FLD",
    city_name: "Fond du Lac",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Fontanges Airport",
    longitude: -71.173256,
    latitude: 54.553985,
    id: "arp_yfg_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YFG",
    city_name: "Fontanges",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Fonte Boa Airport",
    longitude: -66.083825,
    latitude: -2.531972,
    id: "arp_fba_br",
    icao_code: "SWOB",
    iata_country_code: "BR",
    iata_code: "FBA",
    city_name: "Fonte Boa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Foothills Regional Airport",
    longitude: -81.612996,
    latitude: 35.820785,
    id: "arp_mrn_us",
    icao_code: "KMRN",
    iata_country_code: "US",
    iata_code: "MRN",
    city_name: "Morganton",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Forbes Airport",
    longitude: 147.932469,
    latitude: -33.364007,
    id: "arp_frb_au",
    icao_code: "YFBS",
    iata_country_code: "AU",
    iata_code: "FRB",
    city_name: "Forbes",
    city: null,
  },
  {
    time_zone: "America/Menominee",
    name: "Ford Airport",
    longitude: -88.116535,
    latitude: 45.816691,
    id: "arp_imt_us",
    icao_code: "KIMT",
    iata_country_code: "US",
    iata_code: "IMT",
    city_name: "Iron Mountain/Kingsford",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Førde Airport",
    longitude: 5.759926,
    latitude: 61.391216,
    id: "arp_fde_no",
    icao_code: "ENBL",
    iata_country_code: "NO",
    iata_code: "FDE",
    city_name: "Førde",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Forest City Municipal Airport",
    longitude: -93.627177,
    latitude: 43.237437,
    id: "arp_fxy_us",
    icao_code: "KFXY",
    iata_country_code: "US",
    iata_code: "FXY",
    city_name: "Forest City",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Forestville Airport",
    longitude: -69.097206,
    latitude: 48.746104,
    id: "arp_yfe_ca",
    icao_code: "CYFE",
    iata_country_code: "CA",
    iata_code: "YFE",
    city_name: "Forestville",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Forlì International Airport",
    longitude: 12.070452,
    latitude: 44.196596,
    id: "arp_frl_it",
    icao_code: "LIPK",
    iata_country_code: "IT",
    iata_code: "FRL",
    city_name: "Forli",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Formosa International Airport",
    longitude: -58.22923,
    latitude: -26.213467,
    id: "arp_fma_ar",
    icao_code: "SARF",
    iata_country_code: "AR",
    iata_code: "FMA",
    city_name: "Formosa",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Forrest Airport",
    longitude: 128.113988,
    latitude: -30.837008,
    id: "arp_fos_au",
    icao_code: "YFRT",
    iata_country_code: "AU",
    iata_code: "FOS",
    city_name: "Forrest",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Forrest City Municipal Airport",
    longitude: -90.773936,
    latitude: 34.943783,
    id: "arp_fcy_us",
    icao_code: "KFCY",
    iata_country_code: "US",
    iata_code: "FCY",
    city_name: "Forrest City",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Forrest River Airport",
    longitude: 128.067,
    latitude: -15.3,
    id: "arp_fvr_au",
    icao_code: "YFRV",
    iata_country_code: "AU",
    iata_code: "FVR",
    city_name: "Forrest River Mission",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Forster Airport",
    longitude: -124.132774,
    latitude: 40.55389,
    id: "arp_fot_au",
    icao_code: "YFST",
    iata_country_code: "AU",
    iata_code: "FOT",
    city_name: "Forster",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Fort Albany Airport",
    longitude: -81.694031,
    latitude: 52.202676,
    id: "arp_yfa_ca",
    icao_code: "CYFA",
    iata_country_code: "CA",
    iata_code: "YFA",
    city_name: "Fort Albany",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fort Bragg Airport",
    longitude: -123.795571,
    latitude: 39.472918,
    id: "arp_fob_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FOB",
    city_name: "Fort Bragg",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Fort Bridger Airport",
    longitude: -110.403035,
    latitude: 41.395009,
    id: "arp_fbr_us",
    icao_code: "KFBR",
    iata_country_code: "US",
    iata_code: "FBR",
    city_name: "Fort Bridger",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort Chipewyan Airport",
    longitude: -111.116966,
    latitude: 58.767429,
    id: "arp_ypy_ca",
    icao_code: "CYPY",
    iata_country_code: "CA",
    iata_code: "YPY",
    city_name: "Fort Chipewyan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Dodge Regional Airport",
    longitude: -94.191409,
    latitude: 42.55181,
    id: "arp_fod_us",
    icao_code: "KFOD",
    iata_country_code: "US",
    iata_code: "FOD",
    city_name: "Fort Dodge",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Fortescue Dave Forrest Airport",
    longitude: 119.435209,
    latitude: -22.290422,
    id: "arp_kfe_au",
    icao_code: "YFDF",
    iata_country_code: "AU",
    iata_code: "KFE",
    city_name: "Cloudbreak",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Fort Frances Municipal Airport",
    longitude: -93.439709,
    latitude: 48.654184,
    id: "arp_yag_ca",
    icao_code: "CYAG",
    iata_country_code: "CA",
    iata_code: "YAG",
    city_name: "Fort Frances",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Fort Good Hope Airport",
    longitude: -128.65028,
    latitude: 66.24072,
    id: "arp_ygh_ca",
    icao_code: "CYGH",
    iata_country_code: "CA",
    iata_code: "YGH",
    city_name: "Fort Good Hope",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Fort Hope Airport",
    longitude: -87.907375,
    latitude: 51.561806,
    id: "arp_yfh_ca",
    icao_code: "CYFH",
    iata_country_code: "CA",
    iata_code: "YFH",
    city_name: "Fort Hope",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Fort Lauderdale Executive Airport",
    longitude: -80.170871,
    latitude: 26.198162,
    id: "arp_fxe_us",
    icao_code: "KFXE",
    iata_country_code: "US",
    iata_code: "FXE",
    city_name: "Fort Lauderdale",
    city: {
      name: "Fort Lauderdale",
      id: "cit_fll_us",
      iata_country_code: "US",
      iata_code: "FLL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Fort Lauderdale-Hollywood International Airport",
    longitude: -80.151652,
    latitude: 26.073416,
    id: "arp_fll_us",
    icao_code: "KFLL",
    iata_country_code: "US",
    iata_code: "FLL",
    city_name: "Fort Lauderdale",
    city: {
      name: "Fort Lauderdale",
      id: "cit_fll_us",
      iata_country_code: "US",
      iata_code: "FLL",
    },
  },
  {
    time_zone: "America/Yellowknife",
    name: "Fort Liard Airport",
    longitude: -123.469389,
    latitude: 60.235976,
    id: "arp_yjf_ca",
    icao_code: "CYJF",
    iata_country_code: "CA",
    iata_code: "YJF",
    city_name: "Fort Liard",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort MacKay/Albian Aerodrome",
    longitude: -111.418962,
    latitude: 57.224021,
    id: "arp_jhl_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "JHL",
    city_name: "Fort MacKay",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort MacKay/Firebag Aerodrome",
    longitude: -110.979301,
    latitude: 57.278513,
    id: "arp_yfi_ca",
    icao_code: "CYFI",
    iata_country_code: "CA",
    iata_code: "YFI",
    city_name: "Fort MacKay",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort Mackay / Horizon Airport",
    longitude: -111.700996,
    latitude: 57.381698,
    id: "arp_hzp_ca",
    icao_code: "CYNR",
    iata_country_code: "CA",
    iata_code: "HZP",
    city_name: "Fort Mackay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Madison Municipal Airport",
    longitude: -91.326918,
    latitude: 40.659372,
    id: "arp_fms_us",
    icao_code: "KFSW",
    iata_country_code: "US",
    iata_code: "FMS",
    city_name: "Fort Madison",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort McMurray International Airport",
    longitude: -111.224563,
    latitude: 56.652928,
    id: "arp_ymm_ca",
    icao_code: "CYMM",
    iata_country_code: "CA",
    iata_code: "YMM",
    city_name: "Fort McMurray",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort McMurray/Mildred Lake Airport",
    longitude: -111.579884,
    latitude: 57.052729,
    id: "arp_nml_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "NML",
    city_name: "Fort McMurray",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Fort McPherson Airport",
    longitude: -134.86065,
    latitude: 67.4076,
    id: "arp_zfm_ca",
    icao_code: "CZFM",
    iata_country_code: "CA",
    iata_code: "ZFM",
    city_name: "Fort McPherson",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Fort Reliance Airport",
    longitude: -109.154272,
    latitude: 62.718302,
    id: "arp_yfl_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YFL",
    city_name: "Fort Reliance",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Fort Resolution Airport",
    longitude: -113.688502,
    latitude: 61.179338,
    id: "arp_yfr_ca",
    icao_code: "CYFR",
    iata_country_code: "CA",
    iata_code: "YFR",
    city_name: "Fort Resolution",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Scott Municipal Airport",
    longitude: -94.769432,
    latitude: 37.798355,
    id: "arp_fsk_us",
    icao_code: "KFSK",
    iata_country_code: "US",
    iata_code: "FSK",
    city_name: "Fort Scott",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Fort Severn Airport",
    longitude: -87.677142,
    latitude: 56.018811,
    id: "arp_yer_ca",
    icao_code: "CYER",
    iata_country_code: "CA",
    iata_code: "YER",
    city_name: "Fort Severn",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Fort Simpson Airport",
    longitude: -121.237,
    latitude: 61.76026,
    id: "arp_yfs_ca",
    icao_code: "CYFS",
    iata_country_code: "CA",
    iata_code: "YFS",
    city_name: "Fort Simpson",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Fort Smith Airport",
    longitude: -111.960754,
    latitude: 60.020038,
    id: "arp_ysm_ca",
    icao_code: "CYSM",
    iata_country_code: "CA",
    iata_code: "YSM",
    city_name: "Fort Smith",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Smith Regional Airport",
    longitude: -94.366287,
    latitude: 35.336819,
    id: "arp_fsm_us",
    icao_code: "KFSM",
    iata_country_code: "US",
    iata_code: "FSM",
    city_name: "Fort Smith",
    city: null,
  },
  {
    time_zone: "America/Dawson_Creek",
    name: "Fort St. John Airport",
    longitude: -120.737571,
    latitude: 56.241867,
    id: "arp_yxj_ca",
    icao_code: "CYXJ",
    iata_country_code: "CA",
    iata_code: "YXJ",
    city_name: "Fort St. John",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Stockton Pecos County Airport",
    longitude: -102.914926,
    latitude: 30.912362,
    id: "arp_fst_us",
    icao_code: "KFST",
    iata_country_code: "US",
    iata_code: "FST",
    city_name: "Fort Stockton",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Fort Sumner Municipal Airport",
    longitude: -104.218043,
    latitude: 34.486103,
    id: "arp_fsu_us",
    icao_code: "KFSU",
    iata_country_code: "US",
    iata_code: "FSU",
    city_name: "Fort Sumner",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Fort Wayne International Airport",
    longitude: -85.191697,
    latitude: 40.981583,
    id: "arp_fwa_us",
    icao_code: "KFWA",
    iata_country_code: "US",
    iata_code: "FWA",
    city_name: "Fort Wayne",
    city: {
      name: "Fort Wayne",
      id: "cit_fwa_us",
      iata_country_code: "US",
      iata_code: "FWA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Worth Alliance Airport",
    longitude: -97.316635,
    latitude: 32.987322,
    id: "arp_afw_us",
    icao_code: "KAFW",
    iata_country_code: "US",
    iata_code: "AFW",
    city_name: "Fort Worth",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fort Worth Meacham International Airport",
    longitude: -97.361097,
    latitude: 32.82018,
    id: "arp_ftw_us",
    icao_code: "KFTW",
    iata_country_code: "US",
    iata_code: "FTW",
    city_name: "Fort Worth",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Fort Yukon Airport",
    longitude: -145.250019,
    latitude: 66.571519,
    id: "arp_fyu_us",
    icao_code: "PFYU",
    iata_country_code: "US",
    iata_code: "FYU",
    city_name: "Fort Yukon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Foshan Shadi Airport",
    longitude: 113.071105,
    latitude: 23.06812,
    id: "arp_fuo_cn",
    icao_code: "ZGFS",
    iata_country_code: "CN",
    iata_code: "FUO",
    city_name: "Foshan",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Fossil Downs Airport",
    longitude: 125.562224,
    latitude: -18.179924,
    id: "arp_fsl_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "FSL",
    city_name: "Fossil Downs Station",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Fougamou Airport",
    longitude: 10.585971,
    latitude: -1.219481,
    id: "arp_fou_ga",
    icao_code: "FOGF",
    iata_country_code: "GA",
    iata_code: "FOU",
    city_name: "Fougamou",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Foula Airport",
    longitude: -2.053491,
    latitude: 60.122222,
    id: "arp_foa_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "FOA",
    city_name: "Foula",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Foumban Nkounja Airport",
    longitude: 10.751926,
    latitude: 5.638709,
    id: "arp_fom_cm",
    icao_code: "FKKM",
    iata_country_code: "CM",
    iata_code: "FOM",
    city_name: "Foumban",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Four Corners Regional Airport",
    longitude: -108.228651,
    latitude: 36.739921,
    id: "arp_fmn_us",
    icao_code: "KFMN",
    iata_country_code: "US",
    iata_code: "FMN",
    city_name: "Farmington",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Foya Airport",
    longitude: -10.226984,
    latitude: 8.351129,
    id: "arp_foy_lr",
    icao_code: null,
    iata_country_code: "LR",
    iata_code: "FOY",
    city_name: "Foya",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Foz do Iguaçu/Cataratas International Airport",
    longitude: -54.486171,
    latitude: -25.599422,
    id: "arp_igu_br",
    icao_code: "SBFI",
    iata_country_code: "BR",
    iata_code: "IGU",
    city_name: "Foz do Iguaçu",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Franca Airport",
    longitude: -47.3829,
    latitude: -20.592199,
    id: "arp_frc_br",
    icao_code: "SIMK",
    iata_country_code: "BR",
    iata_code: "FRC",
    city_name: "Franca",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Francisco Beltrão Airport",
    longitude: -53.064522,
    latitude: -26.059087,
    id: "arp_fbe_br",
    icao_code: "SSFB",
    iata_country_code: "BR",
    iata_code: "FBE",
    city_name: "Francisco Beltrão",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Francisco B. Reyes Airport",
    longitude: 120.099434,
    latitude: 12.120978,
    id: "arp_usu_ph",
    icao_code: "RPVV",
    iata_country_code: "PH",
    iata_code: "USU",
    city_name: "Busuanga",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Francisco Carle Airport",
    longitude: -75.47349,
    latitude: -11.783574,
    id: "arp_jau_pe",
    icao_code: "SPJJ",
    iata_country_code: "PE",
    iata_code: "JAU",
    city_name: "Jauja",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Francisco de Assis Airport",
    longitude: -43.386388,
    latitude: -21.791658,
    id: "arp_jdf_br",
    icao_code: "SBJF",
    iata_country_code: "BR",
    iata_code: "JDF",
    city_name: "Juiz de Fora",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Francisco de Orellana Airport",
    longitude: -76.986509,
    latitude: -0.462315,
    id: "arp_occ_ec",
    icao_code: "SECO",
    iata_country_code: "EC",
    iata_code: "OCC",
    city_name: "Coca",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Francisco Primo de Verdad National Airport",
    longitude: -101.944278,
    latitude: 21.258553,
    id: "arp_lom_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "LOM",
    city_name: "Lagos de Moreno",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Francis S. Gabreski Airport",
    longitude: -72.634513,
    latitude: 40.843143,
    id: "arp_fok_us",
    icao_code: "KFOK",
    iata_country_code: "US",
    iata_code: "FOK",
    city_name: "Westhampton Beach",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Francistown Airport",
    longitude: 27.478222,
    latitude: -21.160077,
    id: "arp_frw_bw",
    icao_code: "FBFT",
    iata_country_code: "BW",
    iata_code: "FRW",
    city_name: "Francistown",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Franco Bianco Airport",
    longitude: -69.33366,
    latitude: -52.73666,
    id: "arp_smb_cl",
    icao_code: "SCSB",
    iata_country_code: "CL",
    iata_code: "SMB",
    city_name: "Cerro Sombrero",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Frankfurt am Main Airport",
    longitude: 8.567755,
    latitude: 50.034866,
    id: "arp_fra_de",
    icao_code: "EDDF",
    iata_country_code: "DE",
    iata_code: "FRA",
    city_name: "Frankfurt",
    city: {
      name: "Frankfurt",
      id: "cit_fra_de",
      iata_country_code: "DE",
      iata_code: "FRA",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Frankfurt-Hahn Airport",
    longitude: 7.263732,
    latitude: 49.946527,
    id: "arp_hhn_de",
    icao_code: "EDFH",
    iata_country_code: "DE",
    iata_code: "HHN",
    city_name: "Frankfurt",
    city: {
      name: "Frankfurt",
      id: "cit_fra_de",
      iata_country_code: "DE",
      iata_code: "FRA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Franklin County Airport",
    longitude: -85.898861,
    latitude: 35.205128,
    id: "arp_uos_us",
    icao_code: "KUOS",
    iata_country_code: "US",
    iata_code: "UOS",
    city_name: "Sewanee",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Franklin Municipal-John Beverly Rose Airport",
    longitude: -76.903302,
    latitude: 36.69807,
    id: "arp_fkn_us",
    icao_code: "KFKN",
    iata_country_code: "US",
    iata_code: "FKN",
    city_name: "Franklin",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Frank Pais International Airport",
    longitude: -76.31505,
    latitude: 20.785439,
    id: "arp_hog_cu",
    icao_code: "MUHG",
    iata_country_code: "CU",
    iata_code: "HOG",
    city_name: "Holguin",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Frans Kaisiepo Airport",
    longitude: 136.108125,
    latitude: -1.189686,
    id: "arp_bik_id",
    icao_code: "WABB",
    iata_country_code: "ID",
    iata_code: "BIK",
    city_name: "Biak",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Frans Sales Lega Airport",
    longitude: 120.478193,
    latitude: -8.597123,
    id: "arp_rtg_id",
    icao_code: "WATG",
    iata_country_code: "ID",
    iata_code: "RTG",
    city_name: "Ruteng",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Frans Xavier Seda Airport",
    longitude: 122.237185,
    latitude: -8.639412,
    id: "arp_mof_id",
    icao_code: "WATC",
    iata_country_code: "ID",
    iata_code: "MOF",
    city_name: "Maumere",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Franz Josef Glacier Airport",
    longitude: 170.133371,
    latitude: -43.362421,
    id: "arp_who_nz",
    icao_code: "NZFJ",
    iata_country_code: "NZ",
    iata_code: "WHO",
    city_name: "Franz Josef Glacier",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Frederick Municipal Airport",
    longitude: -77.37776,
    latitude: 39.416686,
    id: "arp_fdk_us",
    icao_code: "KFDK",
    iata_country_code: "US",
    iata_code: "FDK",
    city_name: "Frederick",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Frederick Regional Airport",
    longitude: -77.377187,
    latitude: 39.415726,
    id: "arp_fdr_us",
    icao_code: "KFDR",
    iata_country_code: "US",
    iata_code: "FDR",
    city_name: "Frederick",
    city: null,
  },
  {
    time_zone: "America/Moncton",
    name: "Fredericton International Airport",
    longitude: -66.533711,
    latitude: 45.870216,
    id: "arp_yfc_ca",
    icao_code: "CYFC",
    iata_country_code: "CA",
    iata_code: "YFC",
    city_name: "Fredericton",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Freeman Municipal Airport",
    longitude: -85.90732,
    latitude: 38.924374,
    id: "arp_ser_us",
    icao_code: "KSER",
    iata_country_code: "US",
    iata_code: "SER",
    city_name: "Seymour",
    city: null,
  },
  {
    time_zone: "Indian/Mahe",
    name: "Frégate Island Airport",
    longitude: 55.945048,
    latitude: -4.583273,
    id: "arp_frk_sc",
    icao_code: "FSSF",
    iata_country_code: "SC",
    iata_code: "FRK",
    city_name: "Frégate Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Fremont County Airport",
    longitude: -105.107265,
    latitude: 38.428867,
    id: "arp_cne_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CNE",
    city_name: "Canon City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Fremont Municipal Airport",
    longitude: -96.517603,
    latitude: 41.449709,
    id: "arp_fet_us",
    icao_code: "KFET",
    iata_country_code: "US",
    iata_code: "FET",
    city_name: "Fremont",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "French Lick Municipal Airport",
    longitude: -86.636801,
    latitude: 38.505855,
    id: "arp_frh_us",
    icao_code: "KFRH",
    iata_country_code: "US",
    iata_code: "FRH",
    city_name: "French Lick",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "French Valley Airport",
    longitude: -117.129233,
    latitude: 33.574342,
    id: "arp_rbk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RBK",
    city_name: "Temecula/Murrieta",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fresno Chandler Executive Airport",
    longitude: -119.81898,
    latitude: 36.730839,
    id: "arp_fch_us",
    icao_code: "KFCH",
    iata_country_code: "US",
    iata_code: "FCH",
    city_name: "Fresno",
    city: {
      name: "Fresno",
      id: "cit_fat_us",
      iata_country_code: "US",
      iata_code: "FAT",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fresno Yosemite International Airport",
    longitude: -119.718055,
    latitude: 36.776024,
    id: "arp_fat_us",
    icao_code: "KFAT",
    iata_country_code: "US",
    iata_code: "FAT",
    city_name: "Fresno",
    city: {
      name: "Fresno",
      id: "cit_fat_us",
      iata_country_code: "US",
      iata_code: "FAT",
    },
  },
  {
    time_zone: "Africa/Conakry",
    name: "Fria Airport",
    longitude: -13.569253,
    latitude: 10.350601,
    id: "arp_fig_gn",
    icao_code: "GUFA",
    iata_country_code: "GN",
    iata_code: "FIG",
    city_name: "Fria",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Friday Harbor Airport",
    longitude: -123.025087,
    latitude: 48.523415,
    id: "arp_frd_us",
    icao_code: "KFHR",
    iata_country_code: "US",
    iata_code: "FRD",
    city_name: "Friday Harbor",
    city: {
      name: "Friday Harbor",
      id: "cit_frd_us",
      iata_country_code: "US",
      iata_code: "FRD",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Friday Harbor Seaplane Base",
    longitude: -123.011675,
    latitude: 48.537625,
    id: "arp_fbs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FBS",
    city_name: "Friday Harbor",
    city: {
      name: "Friday Harbor",
      id: "cit_frd_us",
      iata_country_code: "US",
      iata_code: "FRD",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Frieda River Airport",
    longitude: 145.817,
    latitude: -5.16667,
    id: "arp_faq_pg",
    icao_code: "AYFR",
    iata_country_code: "PG",
    iata_code: "FAQ",
    city_name: "Frieda River",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Friedman Memorial Airport",
    longitude: -114.297655,
    latitude: 43.5056,
    id: "arp_sun_us",
    icao_code: "KSUN",
    iata_country_code: "US",
    iata_code: "SUN",
    city_name: "Hailey",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Friedrichshafen Airport",
    longitude: 9.514812,
    latitude: 47.671997,
    id: "arp_fdh_de",
    icao_code: "EDNY",
    iata_country_code: "DE",
    iata_code: "FDH",
    city_name: "Friedrichshafen",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Fritzlar Airport",
    longitude: 9.286222,
    latitude: 51.114455,
    id: "arp_frz_de",
    icao_code: "ETHF",
    iata_country_code: "DE",
    iata_code: "FRZ",
    city_name: "Fritzlar",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Front Royal-Warren County Airport",
    longitude: -78.253571,
    latitude: 38.917735,
    id: "arp_frr_us",
    icao_code: "KFRR",
    iata_country_code: "US",
    iata_code: "FRR",
    city_name: "Front Royal",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Frutillar Airport",
    longitude: -73.063975,
    latitude: -41.130757,
    id: "arp_frt_cl",
    icao_code: null,
    iata_country_code: "CL",
    iata_code: "FRT",
    city_name: "Frutillar",
    city: null,
  },
  {
    time_zone: "Pacific/Tongatapu",
    name: "Fuaʻamotu International Airport",
    longitude: -175.14591,
    latitude: -21.241577,
    id: "arp_tbu_to",
    icao_code: "NFTF",
    iata_country_code: "TO",
    iata_code: "TBU",
    city_name: "Nuku'alofa",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Fuerte Olimpo Airport",
    longitude: -57.879497,
    latitude: -21.045947,
    id: "arp_olk_py",
    icao_code: "SGOL",
    iata_country_code: "PY",
    iata_code: "OLK",
    city_name: "Fuerte Olimpo",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "Fuerteventura Airport",
    longitude: -13.865202,
    latitude: 28.452215,
    id: "arp_fue_es",
    icao_code: "GCFV",
    iata_country_code: "ES",
    iata_code: "FUE",
    city_name: "Fuerteventura",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Fujairah International Airport",
    longitude: 56.327278,
    latitude: 25.11096,
    id: "arp_fjr_ae",
    icao_code: "OMFJ",
    iata_country_code: "AE",
    iata_code: "FJR",
    city_name: "Fujairah",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Fukue Airport",
    longitude: 128.834535,
    latitude: 32.66681,
    id: "arp_fuj_jp",
    icao_code: "RJFE",
    iata_country_code: "JP",
    iata_code: "FUJ",
    city_name: "Fukue",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Fukui Airport",
    longitude: 136.222982,
    latitude: 36.141419,
    id: "arp_fkj_jp",
    icao_code: "RJNF",
    iata_country_code: "JP",
    iata_code: "FKJ",
    city_name: "Fukui",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Fukuoka Airport",
    longitude: 130.448856,
    latitude: 33.588107,
    id: "arp_fuk_jp",
    icao_code: "RJFF",
    iata_country_code: "JP",
    iata_code: "FUK",
    city_name: "Fukuoka",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Fukushima Airport",
    longitude: 140.431021,
    latitude: 37.227543,
    id: "arp_fks_jp",
    icao_code: "RJSF",
    iata_country_code: "JP",
    iata_code: "FKS",
    city_name: "Fukushima",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Fulleborn Airport",
    longitude: 150.641236,
    latitude: -6.142826,
    id: "arp_fub_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "FUB",
    city_name: "Fulleborn",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Fullerton Municipal Airport",
    longitude: -117.979709,
    latitude: 33.87192,
    id: "arp_ful_us",
    icao_code: "KFUL",
    iata_country_code: "US",
    iata_code: "FUL",
    city_name: "Fullerton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Fulton County Airport",
    longitude: -84.521638,
    latitude: 33.777896,
    id: "arp_fty_us",
    icao_code: "KFTY",
    iata_country_code: "US",
    iata_code: "FTY",
    city_name: "Atlanta",
    city: {
      name: "Atlanta",
      id: "cit_atl_us",
      iata_country_code: "US",
      iata_code: "ATL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Fulton County Airport",
    longitude: -84.523181,
    latitude: 33.77653,
    id: "arp_rcr_us",
    icao_code: "KRCR",
    iata_country_code: "US",
    iata_code: "RCR",
    city_name: "Rochester",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Fuma Airport",
    longitude: 142.438854,
    latitude: -6.393177,
    id: "arp_fum_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "FUM",
    city_name: "Fuma",
    city: null,
  },
  {
    time_zone: "Pacific/Funafuti",
    name: "Funafuti International Airport",
    longitude: 179.195939,
    latitude: -8.524783,
    id: "arp_fun_tv",
    icao_code: "NGFU",
    iata_country_code: "TV",
    iata_code: "FUN",
    city_name: "Funafuti",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Funter Bay Seaplane Base",
    longitude: -134.897966,
    latitude: 58.254394,
    id: "arp_fnr_us",
    icao_code: "PANR",
    iata_country_code: "US",
    iata_code: "FNR",
    city_name: "Funter Bay",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Furnace Creek Airport",
    longitude: -116.881342,
    latitude: 36.464497,
    id: "arp_dth_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DTH",
    city_name: "Death Valley National Park",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Fürstenfeldbruck Air Base",
    longitude: 11.270529,
    latitude: 48.204136,
    id: "arp_fel_de",
    icao_code: "ETSF",
    iata_country_code: "DE",
    iata_code: "FEL",
    city_name: "Fürstenfeldbruck",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Futaleufú Airport",
    longitude: -71.852843,
    latitude: -43.188719,
    id: "arp_ffu_cl",
    icao_code: "SCFT",
    iata_country_code: "CL",
    iata_code: "FFU",
    city_name: "Futaleufu",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Futuna Airport",
    longitude: 170.231994,
    latitude: -19.516399,
    id: "arp_fta_vu",
    icao_code: "NVVF",
    iata_country_code: "VU",
    iata_code: "FTA",
    city_name: "Futuna Island",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Fuvahmulah Airport",
    longitude: 73.434644,
    latitude: -0.309443,
    id: "arp_fvm_mv",
    icao_code: "VRMR",
    iata_country_code: "MV",
    iata_code: "FVM",
    city_name: "Fuvahmulak Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Fuyang Xiguan Airport",
    longitude: 115.736608,
    latitude: 32.879743,
    id: "arp_fug_cn",
    icao_code: "ZSFY",
    iata_country_code: "CN",
    iata_code: "FUG",
    city_name: "Fuyang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Fuyuan Dongji Airport",
    longitude: 134.366704,
    latitude: 48.199533,
    id: "arp_fyj_cn",
    icao_code: "ZYFY",
    iata_country_code: "CN",
    iata_code: "FYJ",
    city_name: "Fuyuan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Fuyun Koktokay Airport",
    longitude: 89.512253,
    latitude: 46.804176,
    id: "arp_fyn_cn",
    icao_code: "ZWFY",
    iata_country_code: "CN",
    iata_code: "FYN",
    city_name: "Fuyun",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Fuzhou Changle International Airport",
    longitude: 119.665399,
    latitude: 25.933613,
    id: "arp_foc_cn",
    icao_code: "ZSFZ",
    iata_country_code: "CN",
    iata_code: "FOC",
    city_name: "Fuzhou",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Gabbs Airport",
    longitude: -117.956914,
    latitude: 38.923073,
    id: "arp_gab_us",
    icao_code: "KGAB",
    iata_country_code: "US",
    iata_code: "GAB",
    city_name: "Gabbs",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Gabès – Matmata International Airport",
    longitude: 9.914662,
    latitude: 33.736082,
    id: "arp_gae_tn",
    icao_code: "DTTG",
    iata_country_code: "TN",
    iata_code: "GAE",
    city_name: "Gabès",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gabriel Vargas Santos Airport",
    longitude: -71.760208,
    latitude: 6.450536,
    id: "arp_tme_co",
    icao_code: "SKTM",
    iata_country_code: "CO",
    iata_code: "TME",
    city_name: "Tame",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Gachsaran Airport",
    longitude: 50.828892,
    latitude: 30.33706,
    id: "arp_gch_ir",
    icao_code: "OIAH",
    iata_country_code: "IR",
    iata_code: "GCH",
    city_name: "Gachsaran",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Gafsa Ksar International Airport",
    longitude: 8.820408,
    latitude: 34.421979,
    id: "arp_gaf_tn",
    icao_code: "DTTF",
    iata_country_code: "TN",
    iata_code: "GAF",
    city_name: "Gafsa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Gage Airport",
    longitude: -99.774552,
    latitude: 36.293859,
    id: "arp_gag_us",
    icao_code: "KGAG",
    iata_country_code: "US",
    iata_code: "GAG",
    city_name: "Gage",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Gag Island Airport",
    longitude: 129.458633,
    latitude: -0.078453,
    id: "arp_gav_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "GAV",
    city_name: "Gag Island",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Gagnoa Airport",
    longitude: -5.986979,
    latitude: 6.103349,
    id: "arp_ggn_ci",
    icao_code: "DIGA",
    iata_country_code: "CI",
    iata_code: "GGN",
    city_name: "Gagnoa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Gainesville Municipal Airport",
    longitude: -97.197211,
    latitude: 33.651463,
    id: "arp_gle_us",
    icao_code: "KGLE",
    iata_country_code: "US",
    iata_code: "GLE",
    city_name: "Gainesville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gainesville Regional Airport",
    longitude: -82.274242,
    latitude: 29.68827,
    id: "arp_gnv_us",
    icao_code: "KGNV",
    iata_country_code: "US",
    iata_code: "GNV",
    city_name: "Gainesville",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Galatina Air Base Airport",
    longitude: 18.300882,
    latitude: 40.363208,
    id: "arp_lcc_it",
    icao_code: "LIBN",
    iata_country_code: "IT",
    iata_code: "LCC",
    city_name: "Lecce",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Galbraith Lake Airport",
    longitude: -149.489271,
    latitude: 68.479606,
    id: "arp_gbh_us",
    icao_code: "PAGB",
    iata_country_code: "US",
    iata_code: "GBH",
    city_name: "Galbraith Lake",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Galcaio Airport",
    longitude: 47.454475,
    latitude: 6.780862,
    id: "arp_glk_so",
    icao_code: "HCMR",
    iata_country_code: "SO",
    iata_code: "GLK",
    city_name: "Galkayo/Galcaio",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Galegu Airport",
    longitude: 35.067,
    latitude: 12.533,
    id: "arp_dnx_sd",
    icao_code: "HSGG",
    iata_country_code: "SD",
    iata_code: "DNX",
    city_name: "Dinder",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Galesburg Municipal Airport",
    longitude: -90.431152,
    latitude: 40.939657,
    id: "arp_gbg_us",
    icao_code: "KGBG",
    iata_country_code: "US",
    iata_code: "GBG",
    city_name: "Galesburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Galion Municipal Airport",
    longitude: -82.723831,
    latitude: 40.753377,
    id: "arp_gqq_us",
    icao_code: "KGQQ",
    iata_country_code: "US",
    iata_code: "GQQ",
    city_name: "Galion",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Gällivare Airport",
    longitude: 20.813738,
    latitude: 67.133621,
    id: "arp_gev_se",
    icao_code: "ESNG",
    iata_country_code: "SE",
    iata_code: "GEV",
    city_name: "Gällivare",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Gallup Municipal Airport",
    longitude: -108.786855,
    latitude: 35.512138,
    id: "arp_gup_us",
    icao_code: "KGUP",
    iata_country_code: "US",
    iata_code: "GUP",
    city_name: "Gallup",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Galway Airport",
    longitude: -8.94102,
    latitude: 53.301068,
    id: "arp_gwy_ie",
    icao_code: "EICM",
    iata_country_code: "IE",
    iata_code: "GWY",
    city_name: "Galway",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Gamar Malamo Airport",
    longitude: 127.786074,
    latitude: 1.837586,
    id: "arp_glx_id",
    icao_code: "WAMA",
    iata_country_code: "ID",
    iata_code: "GLX",
    city_name: "Galela",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gamarra Airport",
    longitude: -73.7833,
    latitude: 8.2,
    id: "arp_gra_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "GRA",
    city_name: "Gamarra",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Gamba Airport",
    longitude: 10.046278,
    latitude: -2.786199,
    id: "arp_gax_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "GAX",
    city_name: "Gamba",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Gambela Airport",
    longitude: 34.56448,
    latitude: 8.130458,
    id: "arp_gmb_et",
    icao_code: "HAGM",
    iata_country_code: "ET",
    iata_code: "GMB",
    city_name: "Gambela",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Gambell Airport",
    longitude: -171.733097,
    latitude: 63.767398,
    id: "arp_gam_us",
    icao_code: "PAGM",
    iata_country_code: "US",
    iata_code: "GAM",
    city_name: "Gambell",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Gamboa Airport",
    longitude: -73.773645,
    latitude: -42.490339,
    id: "arp_wca_cl",
    icao_code: "SCST",
    iata_country_code: "CL",
    iata_code: "WCA",
    city_name: "Castro",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Gamboma Airport",
    longitude: 15.88803,
    latitude: -1.827979,
    id: "arp_gmm_cg",
    icao_code: "FCOG",
    iata_country_code: "CG",
    iata_code: "GMM",
    city_name: "Gamboma",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Gamboola Airport",
    longitude: 143.667007,
    latitude: -16.549999,
    id: "arp_gbp_au",
    icao_code: "YGAM",
    iata_country_code: "AU",
    iata_code: "GBP",
    city_name: "Gamboola",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Gamètì/Rae Lakes Airport",
    longitude: -117.308959,
    latitude: 64.116043,
    id: "arp_yra_ca",
    icao_code: "CYRA",
    iata_country_code: "CA",
    iata_code: "YRA",
    city_name: "Gamètì",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Gandajika Airport",
    longitude: 23.570619,
    latitude: -6.124629,
    id: "arp_gdj_cd",
    icao_code: "FZWC",
    iata_country_code: "CD",
    iata_code: "GDJ",
    city_name: "Gandajika",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "Gander International Airport",
    longitude: -54.569452,
    latitude: 48.940545,
    id: "arp_yqx_ca",
    icao_code: "CYQX",
    iata_country_code: "CA",
    iata_code: "YQX",
    city_name: "Gander",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Gangaw Airport",
    longitude: 94.133772,
    latitude: 22.174305,
    id: "arp_gaw_mm",
    icao_code: "VYGG",
    iata_country_code: "MM",
    iata_code: "GAW",
    city_name: "Gangaw",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Ganges Harbor Airport",
    longitude: -123.496991,
    latitude: 48.854504,
    id: "arp_ygg_ca",
    icao_code: "CAX6",
    iata_country_code: "CA",
    iata_code: "YGG",
    city_name: "Ganges Harbor",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Gangneung Airport",
    longitude: 128.943814,
    latitude: 37.753512,
    id: "arp_kag_kr",
    icao_code: "RKNN",
    iata_country_code: "KR",
    iata_code: "KAG",
    city_name: "Gangneung",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Gan International Airport",
    longitude: 73.15564,
    latitude: -0.693263,
    id: "arp_gan_mv",
    icao_code: "VRMG",
    iata_country_code: "MV",
    iata_code: "GAN",
    city_name: "Addu",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Ganja International Airport",
    longitude: 46.3176,
    latitude: 40.737701,
    id: "arp_kvd_az",
    icao_code: "UBBG",
    iata_country_code: "AZ",
    iata_code: "KVD",
    city_name: "Ganja",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Gannan Xiahe Airport",
    longitude: 102.6447,
    latitude: 34.8105,
    id: "arp_gxh_cn",
    icao_code: "ZLXH",
    iata_country_code: "CN",
    iata_code: "GXH",
    city_name: "Xiahe",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Gansner Field",
    longitude: -120.945139,
    latitude: 39.943896,
    id: "arp_gnf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GNF",
    city_name: "Quincy",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ganzhou Huangjin Airport",
    longitude: 114.777777,
    latitude: 25.8525,
    id: "arp_kow_cn",
    icao_code: "ZSGZ",
    iata_country_code: "CN",
    iata_code: "KOW",
    city_name: "Ganzhou",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Gao International Airport",
    longitude: -0.005469,
    latitude: 16.24798,
    id: "arp_gaq_ml",
    icao_code: "GAGO",
    iata_country_code: "ML",
    iata_code: "GAQ",
    city_name: "Gao",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Gaoua Airport",
    longitude: -3.165532,
    latitude: 10.382435,
    id: "arp_xga_bf",
    icao_code: "DFOG",
    iata_country_code: "BF",
    iata_code: "XGA",
    city_name: "Gaoua",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Gap-Tallard Airport",
    longitude: 6.035956,
    latitude: 44.454999,
    id: "arp_gat_fr",
    icao_code: "LFNA",
    iata_country_code: "FR",
    iata_code: "GAT",
    city_name: "Gap",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Garachiné Airport",
    longitude: -78.36688,
    latitude: 8.065733,
    id: "arp_ghe_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "GHE",
    city_name: "Garachiné",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Garaina Airport",
    longitude: 147.141577,
    latitude: -7.875753,
    id: "arp_gar_pg",
    icao_code: "AYGI",
    iata_country_code: "PG",
    iata_code: "GAR",
    city_name: "Garaina",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Garasa Airport",
    longitude: 147.183,
    latitude: -7.95,
    id: "arp_grl_pg",
    icao_code: "AYGG",
    iata_country_code: "PG",
    iata_code: "GRL",
    city_name: "Garasa",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Garbaharey Airport",
    longitude: 42.214848,
    latitude: 3.326169,
    id: "arp_gbm_so",
    icao_code: null,
    iata_country_code: "SO",
    iata_code: "GBM",
    city_name: "Garbaharey",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Gardabya Airport",
    longitude: 16.594999,
    latitude: 31.063491,
    id: "arp_srx_ly",
    icao_code: "HLGD",
    iata_country_code: "LY",
    iata_code: "SRX",
    city_name: "Sirte",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Garden City Regional Airport",
    longitude: -100.723008,
    latitude: 37.927348,
    id: "arp_gck_us",
    icao_code: "KGCK",
    iata_country_code: "US",
    iata_code: "GCK",
    city_name: "Garden City",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Garden County Airport",
    longitude: -102.352664,
    latitude: 41.400059,
    id: "arp_oks_us",
    icao_code: "KOKS",
    iata_country_code: "US",
    iata_code: "OKS",
    city_name: "Oshkosh",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Garden Point Airport",
    longitude: 130.42205,
    latitude: -11.402882,
    id: "arp_gpn_au",
    icao_code: "YGPT",
    iata_country_code: "AU",
    iata_code: "GPN",
    city_name: "Garden Point",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Gardez Airport",
    longitude: 69.238359,
    latitude: 33.629198,
    id: "arp_grg_af",
    icao_code: "OAGZ",
    iata_country_code: "AF",
    iata_code: "GRG",
    city_name: "Gardez",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gardner Municipal Airport",
    longitude: -72.016088,
    latitude: 42.551426,
    id: "arp_gdm_us",
    icao_code: "KGDM",
    iata_country_code: "US",
    iata_code: "GDM",
    city_name: "Gardner",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Gardo Airport",
    longitude: 49.118666,
    latitude: 9.54404,
    id: "arp_gsr_so",
    icao_code: "HCMG",
    iata_country_code: "SO",
    iata_code: "GSR",
    city_name: "Gardo",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Garfield County Regional Airport",
    longitude: -107.725664,
    latitude: 39.525679,
    id: "arp_ril_us",
    icao_code: "KRIL",
    iata_country_code: "US",
    iata_code: "RIL",
    city_name: "Rifle",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Garissa Airport",
    longitude: 39.648197,
    latitude: -0.463047,
    id: "arp_gas_ke",
    icao_code: "HKGA",
    iata_country_code: "KE",
    iata_code: "GAS",
    city_name: "Garissa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Garner Field",
    longitude: -99.743593,
    latitude: 29.211316,
    id: "arp_uva_us",
    icao_code: "KUVA",
    iata_country_code: "US",
    iata_code: "UVA",
    city_name: "Uvalde",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Garoua International Airport",
    longitude: 13.373675,
    latitude: 9.336148,
    id: "arp_gou_cm",
    icao_code: "FKKR",
    iata_country_code: "CM",
    iata_code: "GOU",
    city_name: "Garoua",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Garowe Airport",
    longitude: 48.568929,
    latitude: 8.456679,
    id: "arp_ggr_so",
    icao_code: "HCMW",
    iata_country_code: "SO",
    iata_code: "GGR",
    city_name: "Garowe",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Garrett County Airport",
    longitude: -79.33861,
    latitude: 39.580779,
    id: "arp_odm_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ODM",
    city_name: "Oakland",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Garuahi Airport",
    longitude: 150.465866,
    latitude: -10.217661,
    id: "arp_grh_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "GRH",
    city_name: "Garuahi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Garze Gesar Airport",
    longitude: 99.554288,
    latitude: 31.757403,
    id: "arp_gzg_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "GZG",
    city_name: "Ganzi",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Gascoyne Junction Airport",
    longitude: 115.201964,
    latitude: -25.054098,
    id: "arp_gsc_au",
    icao_code: "YGSC",
    iata_country_code: "AU",
    iata_code: "GSC",
    city_name: "Gascoyne Junction",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gasmata Island Airport",
    longitude: 150.333602,
    latitude: -6.27147,
    id: "arp_gmi_pg",
    icao_code: "AYGT",
    iata_country_code: "PG",
    iata_code: "GMI",
    city_name: "Gasmata",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gasuke Airport",
    longitude: 141.739078,
    latitude: -6.102956,
    id: "arp_gbc_pg",
    icao_code: "AYGS",
    iata_country_code: "PG",
    iata_code: "GBC",
    city_name: "Gasuke",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Gatineau-Ottawa Executive Airport",
    longitude: -75.562424,
    latitude: 45.520753,
    id: "arp_ynd_ca",
    icao_code: "CYND",
    iata_country_code: "CA",
    iata_code: "YND",
    city_name: "Gatineau",
    city: {
      name: "Ottawa",
      id: "cit_yow_ca",
      iata_country_code: "CA",
      iata_code: "YOW",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Gatlinburg-Pigeon Forge Airport",
    longitude: -83.53359,
    latitude: 35.858865,
    id: "arp_gkt_us",
    icao_code: "KGKT",
    iata_country_code: "US",
    iata_code: "GKT",
    city_name: "Sevierville",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Gatokae Airport",
    longitude: 158.200898,
    latitude: -8.738961,
    id: "arp_gta_sb",
    icao_code: "AGOK",
    iata_country_code: "SB",
    iata_code: "GTA",
    city_name: "Gatokae",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Gatwick Airport",
    longitude: -0.18617,
    latitude: 51.150882,
    id: "arp_lgw_gb",
    icao_code: "EGKK",
    iata_country_code: "GB",
    iata_code: "LGW",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Gau Airport",
    longitude: 179.339996,
    latitude: -18.115601,
    id: "arp_ngi_fj",
    icao_code: "NFNG",
    iata_country_code: "FJ",
    iata_code: "NGI",
    city_name: "Ngau",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Gaua Island Airport",
    longitude: 167.587643,
    latitude: -14.219029,
    id: "arp_zgu_vu",
    icao_code: "NVSQ",
    iata_country_code: "VU",
    iata_code: "ZGU",
    city_name: "Gaua",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Gautam Buddha Airport",
    longitude: 83.417958,
    latitude: 27.505945,
    id: "arp_bwa_np",
    icao_code: "VNBW",
    iata_country_code: "NP",
    iata_code: "BWA",
    city_name: "Bhairawa",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Gävle-Sandviken Airport",
    longitude: 16.95308,
    latitude: 60.593734,
    id: "arp_gvx_se",
    icao_code: "ESSK",
    iata_country_code: "SE",
    iata_code: "GVX",
    city_name: "Gavle",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Gaya Airport",
    longitude: 84.947453,
    latitude: 24.746595,
    id: "arp_gay_in",
    icao_code: "VEGY",
    iata_country_code: "IN",
    iata_code: "GAY",
    city_name: "Gaya",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Gaylord Regional Airport",
    longitude: -84.691471,
    latitude: 45.015604,
    id: "arp_glr_us",
    icao_code: "KGLR",
    iata_country_code: "US",
    iata_code: "GLR",
    city_name: "Gaylord",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Gayndah Airport",
    longitude: 151.618249,
    latitude: -25.614493,
    id: "arp_gah_au",
    icao_code: "YGAY",
    iata_country_code: "AU",
    iata_code: "GAH",
    city_name: "Gayndah",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Gaziantep Oğuzeli International Airport",
    longitude: 37.477146,
    latitude: 36.947174,
    id: "arp_gzt_tr",
    icao_code: "LTAJ",
    iata_country_code: "TR",
    iata_code: "GZT",
    city_name: "Gaziantep",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Gazipaşa-Alanya Airport",
    longitude: 32.301142,
    latitude: 36.298346,
    id: "arp_gzp_tr",
    icao_code: "LTFG",
    iata_country_code: "TR",
    iata_code: "GZP",
    city_name: "Gazipaşa",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Gbadolite Airport",
    longitude: 20.975322,
    latitude: 4.253162,
    id: "arp_bdt_cd",
    icao_code: "FZFD",
    iata_country_code: "CD",
    iata_code: "BDT",
    city_name: "Gbadolite",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Gbangbatok Airport",
    longitude: -12.383,
    latitude: 7.767,
    id: "arp_gbk_sl",
    icao_code: "GFGK",
    iata_country_code: "SL",
    iata_code: "GBK",
    city_name: "Gbangbatok",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Gdańsk Lech Wałęsa Airport",
    longitude: 18.467137,
    latitude: 54.378221,
    id: "arp_gdn_pl",
    icao_code: "EPGD",
    iata_country_code: "PL",
    iata_code: "GDN",
    city_name: "Gdańsk",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Gebe Airport",
    longitude: 129.458962,
    latitude: -0.078591,
    id: "arp_geb_id",
    icao_code: "WAMJ",
    iata_country_code: "ID",
    iata_code: "GEB",
    city_name: "Gebe",
    city: null,
  },
  {
    time_zone: "Asia/Famagusta",
    name: "Gecitkale Airport",
    longitude: 33.727203,
    latitude: 35.235711,
    id: "arp_gec_cy",
    icao_code: "LCGK",
    iata_country_code: "CY",
    iata_code: "GEC",
    city_name: "Geçitkale",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Geelong Airport",
    longitude: 144.332176,
    latitude: -38.224553,
    id: "arp_gex_au",
    icao_code: "YGLG",
    iata_country_code: "AU",
    iata_code: "GEX",
    city_name: "Geelong",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Geilenkirchen Airport",
    longitude: 6.042447,
    latitude: 50.960719,
    id: "arp_gke_de",
    icao_code: "ETNG",
    iata_country_code: "DE",
    iata_code: "GKE",
    city_name: "Geilenkirchen",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Geita Airport",
    longitude: 32.172472,
    latitude: -2.813667,
    id: "arp_git_tz",
    icao_code: "HTRU",
    iata_country_code: "TZ",
    iata_code: "GIT",
    city_name: "Geita",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Geladi Airport",
    longitude: 46.421194,
    latitude: 6.984216,
    id: "arp_glc_et",
    icao_code: "HAGL",
    iata_country_code: "ET",
    iata_code: "GLC",
    city_name: "Geladi",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Gelendzhik Airport",
    longitude: 38.024208,
    latitude: 44.593818,
    id: "arp_gdz_ru",
    icao_code: "URKG",
    iata_country_code: "RU",
    iata_code: "GDZ",
    city_name: "Gelendzhik",
    city: null,
  },
  {
    time_zone: "Asia/Thimphu",
    name: "Gelephu Airport",
    longitude: 90.464621,
    latitude: 26.884301,
    id: "arp_glu_bt",
    icao_code: "VQGP",
    iata_country_code: "BT",
    iata_code: "GLU",
    city_name: "Gelephu",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Gemena Airport",
    longitude: 19.770719,
    latitude: 3.235462,
    id: "arp_gma_cd",
    icao_code: "FZFK",
    iata_country_code: "CD",
    iata_code: "GMA",
    city_name: "Gemena",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Genda Wuha Airport",
    longitude: 36.166849,
    latitude: 12.933149,
    id: "arp_ete_et",
    icao_code: "HAMM",
    iata_country_code: "ET",
    iata_code: "ETE",
    city_name: "Metema",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Geneina Airport",
    longitude: 22.467098,
    latitude: 13.482254,
    id: "arp_egn_sd",
    icao_code: "HSGN",
    iata_country_code: "SD",
    iata_code: "EGN",
    city_name: "Geneina",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "General Bartolomé Salom Airport",
    longitude: -68.072844,
    latitude: 10.479381,
    id: "arp_pbl_ve",
    icao_code: "SVPC",
    iata_country_code: "VE",
    iata_code: "PBL",
    city_name: "Puerto Cabello",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "General Bernardo O'Higgins Airport",
    longitude: -72.033824,
    latitude: -36.58424,
    id: "arp_yai_cl",
    icao_code: "SCCH",
    iata_country_code: "CL",
    iata_code: "YAI",
    city_name: "Chillan",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "General Edward Lawrence Logan International Airport",
    longitude: -71.007381,
    latitude: 42.364956,
    id: "arp_bos_us",
    icao_code: "KBOS",
    iata_country_code: "US",
    iata_code: "BOS",
    city_name: "Boston",
    city: {
      name: "Boston",
      id: "cit_bos_us",
      iata_country_code: "US",
      iata_code: "BOS",
    },
  },
  {
    time_zone: "America/Caracas",
    name: "General José Antonio Anzoátegui International Airport",
    longitude: -64.684271,
    latitude: 10.103767,
    id: "arp_bla_ve",
    icao_code: "SVBC",
    iata_country_code: "VE",
    iata_code: "BLA",
    city_name: "Barcelona",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "General José Francisco Bermúdez Airport",
    longitude: -63.261581,
    latitude: 10.660003,
    id: "arp_cup_ve",
    icao_code: "SVCP",
    iata_country_code: "VE",
    iata_code: "CUP",
    city_name: "Carupano",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "General José María Yáñez International Airport",
    longitude: -110.924043,
    latitude: 27.969379,
    id: "arp_gym_mx",
    icao_code: "MMGM",
    iata_country_code: "MX",
    iata_code: "GYM",
    city_name: "Guaymas",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "General Juan N. Álvarez International Airport",
    longitude: -99.75563,
    latitude: 16.759507,
    id: "arp_aca_mx",
    icao_code: "MMAA",
    iata_country_code: "MX",
    iata_code: "ACA",
    city_name: "Acapulco",
    city: null,
  },
  {
    time_zone: "America/Matamoros",
    name: "General Lucio Blanco International Airport",
    longitude: -98.226535,
    latitude: 26.00775,
    id: "arp_rex_mx",
    icao_code: "MMRX",
    iata_country_code: "MX",
    iata_code: "REX",
    city_name: "Reynosa",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "General Manuel Serrano Airport",
    longitude: -79.961586,
    latitude: -3.268901,
    id: "arp_mch_ec",
    icao_code: "SEMH",
    iata_country_code: "EC",
    iata_code: "MCH",
    city_name: "Machala",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "General Mariano Matamoros Airport",
    longitude: -99.26143,
    latitude: 18.834003,
    id: "arp_cvj_mx",
    icao_code: "MMCB",
    iata_country_code: "MX",
    iata_code: "CVJ",
    city_name: "Cuernavaca",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "General Mitchell International Airport",
    longitude: -87.896622,
    latitude: 42.947376,
    id: "arp_mke_us",
    icao_code: "KMKE",
    iata_country_code: "US",
    iata_code: "MKE",
    city_name: "Milwaukee",
    city: {
      name: "Milwaukee",
      id: "cit_mke_us",
      iata_country_code: "US",
      iata_code: "MKE",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "General Navas Pardo Airport",
    longitude: -75.467341,
    latitude: 3.721459,
    id: "arp_cpl_co",
    icao_code: "SKHA",
    iata_country_code: "CO",
    iata_code: "CPL",
    city_name: "Chaparral",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "General Pico Airport",
    longitude: -63.757944,
    latitude: -35.693714,
    id: "arp_gpo_ar",
    icao_code: "SAZG",
    iata_country_code: "AR",
    iata_code: "GPO",
    city_name: "General Pico",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "General Santos International Airport",
    longitude: 125.097435,
    latitude: 6.057337,
    id: "arp_ges_ph",
    icao_code: "RPMR",
    iata_country_code: "PH",
    iata_code: "GES",
    city_name: "General Santos",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "General Sudirman Airport",
    longitude: 109.416651,
    latitude: -7.460906,
    id: "arp_pwl_id",
    icao_code: "WICP",
    iata_country_code: "ID",
    iata_code: "PWL",
    city_name: "Purwokerto",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "General Ulpiano Paez Airport",
    longitude: -80.989439,
    latitude: -2.203968,
    id: "arp_snc_ec",
    icao_code: "SESA",
    iata_country_code: "EC",
    iata_code: "SNC",
    city_name: "Salinas",
    city: null,
  },
  {
    time_zone: "Pacific/Galapagos",
    name: "General Villamil Airport",
    longitude: -90.953002,
    latitude: -0.942628,
    id: "arp_ibb_ec",
    icao_code: "SEII",
    iata_country_code: "EC",
    iata_code: "IBB",
    city_name: "Isabela",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "General Villegas Airport",
    longitude: -62.992068,
    latitude: -34.994666,
    id: "arp_vgs_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "VGS",
    city_name: "General Villegas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "General Wayne A. Downing Peoria International Airport",
    longitude: -89.693853,
    latitude: 40.664433,
    id: "arp_pia_us",
    icao_code: "KPIA",
    iata_country_code: "US",
    iata_code: "PIA",
    city_name: "Peoria",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "General William J. Fox Airfield",
    longitude: -118.218042,
    latitude: 34.739916,
    id: "arp_wjf_us",
    icao_code: "KWJF",
    iata_country_code: "US",
    iata_code: "WJF",
    city_name: "Lancaster",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Geneva Airport",
    longitude: 6.109053,
    latitude: 46.237553,
    id: "arp_gva_ch",
    icao_code: "LSGG",
    iata_country_code: "CH",
    iata_code: "GVA",
    city_name: "Geneva",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Genoa Cristoforo Colombo Airport",
    longitude: 8.850319,
    latitude: 44.414714,
    id: "arp_goa_it",
    icao_code: "LIMJ",
    iata_country_code: "IT",
    iata_code: "GOA",
    city_name: "Genoa",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "George Airport",
    longitude: 22.379937,
    latitude: -34.004982,
    id: "arp_grj_za",
    icao_code: "FAGG",
    iata_country_code: "ZA",
    iata_code: "GRJ",
    city_name: "George",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "George Best Belfast City Airport",
    longitude: -5.872165,
    latitude: 54.617857,
    id: "arp_bhd_gb",
    icao_code: "EGAC",
    iata_country_code: "GB",
    iata_code: "BHD",
    city_name: "Belfast",
    city: {
      name: "Belfast",
      id: "cit_bfs_gb",
      iata_country_code: "GB",
      iata_code: "BFS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "George Bush Intercontinental Airport",
    longitude: -95.339091,
    latitude: 29.987309,
    id: "arp_iah_us",
    icao_code: "KIAH",
    iata_country_code: "US",
    iata_code: "IAH",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "George Downer Airport",
    longitude: -88.19556,
    latitude: 33.107252,
    id: "arp_aiv_us",
    icao_code: "KAIV",
    iata_country_code: "US",
    iata_code: "AIV",
    city_name: "Aliceville",
    city: null,
  },
  {
    time_zone: "America/St_Lucia",
    name: "George F. L. Charles Airport",
    longitude: -60.992978,
    latitude: 14.0201,
    id: "arp_slu_lc",
    icao_code: "TLPC",
    iata_country_code: "LC",
    iata_code: "SLU",
    city_name: "Castries",
    city: {
      name: "St Lucia",
      id: "cit_slu_lc",
      iata_country_code: "LC",
      iata_code: "SLU",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "George R Carr Memorial Airfield",
    longitude: -89.864977,
    latitude: 30.81369,
    id: "arp_bxa_us",
    icao_code: "KBXA",
    iata_country_code: "US",
    iata_code: "BXA",
    city_name: "Bogalusa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "George T. Lewis Airport",
    longitude: -83.050789,
    latitude: 29.134294,
    id: "arp_cdk_us",
    icao_code: "KCDK",
    iata_country_code: "US",
    iata_code: "CDK",
    city_name: "Cedar Key",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Georgetown Airport",
    longitude: 143.53152,
    latitude: -18.304292,
    id: "arp_gtt_au",
    icao_code: "YGTN",
    iata_country_code: "AU",
    iata_code: "GTT",
    city_name: "Georgetown",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "George Town Airport",
    longitude: 146.840494,
    latitude: -41.079402,
    id: "arp_gee_au",
    icao_code: "YGTO",
    iata_country_code: "AU",
    iata_code: "GEE",
    city_name: "George Town",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Georgetown County Airport",
    longitude: -79.318728,
    latitude: 33.312315,
    id: "arp_gge_us",
    icao_code: "KGGE",
    iata_country_code: "US",
    iata_code: "GGE",
    city_name: "Georgetown",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Gerald R. Ford International Airport",
    longitude: -85.523819,
    latitude: 42.88273,
    id: "arp_grr_us",
    icao_code: "KGRR",
    iata_country_code: "US",
    iata_code: "GRR",
    city_name: "Grand Rapids",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Geraldton Airport",
    longitude: 114.70535,
    latitude: -28.796093,
    id: "arp_get_au",
    icao_code: "YGEL",
    iata_country_code: "AU",
    iata_code: "GET",
    city_name: "Geraldton",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Geraldton Greenstone Regional Airport",
    longitude: -86.936691,
    latitude: 49.779965,
    id: "arp_ygq_ca",
    icao_code: "CYGQ",
    iata_country_code: "CA",
    iata_code: "YGQ",
    city_name: "Geraldton",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gerardo Tobar López Airport",
    longitude: -76.991245,
    latitude: 3.820042,
    id: "arp_bun_co",
    icao_code: "SKBU",
    iata_country_code: "CO",
    iata_code: "BUN",
    city_name: "Buenaventura",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "German Olano Air Base",
    longitude: -74.6574,
    latitude: 5.48361,
    id: "arp_pal_co",
    icao_code: "SKPQ",
    iata_country_code: "CO",
    iata_code: "PAL",
    city_name: "La Dorada",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "German Olano Airport",
    longitude: -67.492353,
    latitude: 6.184943,
    id: "arp_pcr_co",
    icao_code: "SKPC",
    iata_country_code: "CO",
    iata_code: "PCR",
    city_name: "Puerto Carreño",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gettysburg Regional Airport",
    longitude: -77.273381,
    latitude: 39.841534,
    id: "arp_gty_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GTY",
    city_name: "Gettysburg",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Geva Airport",
    longitude: 156.597225,
    latitude: -7.576181,
    id: "arp_gef_sb",
    icao_code: "AGEV",
    iata_country_code: "SB",
    iata_code: "GEF",
    city_name: "Liangia",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Gewayantana Airport",
    longitude: 123.002218,
    latitude: -8.274504,
    id: "arp_lka_id",
    icao_code: "WATL",
    iata_country_code: "ID",
    iata_code: "LKA",
    city_name: "Larantuka",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gewoia Airport",
    longitude: 148.494643,
    latitude: -9.226434,
    id: "arp_gew_pg",
    icao_code: "AYGC",
    iata_country_code: "PG",
    iata_code: "GEW",
    city_name: "Gewoia",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Ghadames Airport",
    longitude: 9.710131,
    latitude: 30.150078,
    id: "arp_ltd_ly",
    icao_code: "HLTD",
    iata_country_code: "LY",
    iata_code: "LTD",
    city_name: "Ghadames",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Ghanzi Airport",
    longitude: 21.655751,
    latitude: -21.69571,
    id: "arp_gnz_bw",
    icao_code: "FBGZ",
    iata_country_code: "BW",
    iata_code: "GNZ",
    city_name: "Ghanzi",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Ghat Airport",
    longitude: 10.142505,
    latitude: 25.1445,
    id: "arp_ght_ly",
    icao_code: "HLGT",
    iata_country_code: "LY",
    iata_code: "GHT",
    city_name: "Ghat",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Ghazni Airport",
    longitude: 68.413324,
    latitude: 33.531355,
    id: "arp_gzi_af",
    icao_code: "OAGN",
    iata_country_code: "AF",
    iata_code: "GZI",
    city_name: "Ghazni",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Ghinnir Airport",
    longitude: 40.7092,
    latitude: 7.1327,
    id: "arp_gnn_et",
    icao_code: "HAGH",
    iata_country_code: "ET",
    iata_code: "GNN",
    city_name: "Ginir",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Ghriss Airport",
    longitude: 0.147486,
    latitude: 35.207013,
    id: "arp_muw_dz",
    icao_code: "DAOV",
    iata_country_code: "DZ",
    iata_code: "MUW",
    city_name: "Ghriss",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Gibb River Airport",
    longitude: 126.445277,
    latitude: -16.420955,
    id: "arp_gbv_au",
    icao_code: "YGIB",
    iata_country_code: "AU",
    iata_code: "GBV",
    city_name: "Gibb River",
    city: null,
  },
  {
    time_zone: "Europe/Gibraltar",
    name: "Gibraltar International Airport",
    longitude: -5.348207,
    latitude: 36.152551,
    id: "arp_gib_gi",
    icao_code: "LXGB",
    iata_country_code: "GI",
    iata_code: "GIB",
    city_name: "Gibraltar",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Giebelstadt Airport",
    longitude: 9.966457,
    latitude: 49.648096,
    id: "arp_ghf_de",
    icao_code: "ETEU",
    iata_country_code: "DE",
    iata_code: "GHF",
    city_name: "Giebelstadt",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gilgal Airport",
    longitude: -77.0833,
    latitude: 8.33333,
    id: "arp_ggl_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "GGL",
    city_name: "Gilgal",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Gilgit Airport",
    longitude: 74.332872,
    latitude: 35.919171,
    id: "arp_gil_pk",
    icao_code: "OPGT",
    iata_country_code: "PK",
    iata_code: "GIL",
    city_name: "Gilgit",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Gillam Airport",
    longitude: -94.708977,
    latitude: 56.357349,
    id: "arp_ygx_ca",
    icao_code: "CYGX",
    iata_country_code: "CA",
    iata_code: "YGX",
    city_name: "Gillam",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Gillespie Field",
    longitude: -116.97537,
    latitude: 32.826451,
    id: "arp_see_us",
    icao_code: "KSEE",
    iata_country_code: "US",
    iata_code: "SEE",
    city_name: "San Diego",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Gillette-Campbell County Airport",
    longitude: -105.539049,
    latitude: 44.348466,
    id: "arp_gcc_us",
    icao_code: "KGCC",
    iata_country_code: "US",
    iata_code: "GCC",
    city_name: "Gillette",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Gillies Bay Airport",
    longitude: -124.518582,
    latitude: 49.696595,
    id: "arp_ygb_ca",
    icao_code: "CYGB",
    iata_country_code: "CA",
    iata_code: "YGB",
    city_name: "Gillies Bay",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Gilze-Rijen Air Base",
    longitude: 4.933004,
    latitude: 51.568714,
    id: "arp_glz_nl",
    icao_code: "EHGR",
    iata_country_code: "NL",
    iata_code: "GLZ",
    city_name: "Breda",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Gimhae International Airport",
    longitude: 128.945396,
    latitude: 35.173685,
    id: "arp_pus_kr",
    icao_code: "RKPK",
    iata_country_code: "KR",
    iata_code: "PUS",
    city_name: "Busan",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Gimli Industrial Park Airport",
    longitude: -97.041566,
    latitude: 50.628131,
    id: "arp_ygm_ca",
    icao_code: "CYGM",
    iata_country_code: "CA",
    iata_code: "YGM",
    city_name: "Gimli",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Gimpo International Airport",
    longitude: 126.792736,
    latitude: 37.558477,
    id: "arp_gmp_kr",
    icao_code: "RKSS",
    iata_country_code: "KR",
    iata_code: "GMP",
    city_name: "Seoul",
    city: {
      name: "Seoul",
      id: "cit_sel_kr",
      iata_country_code: "KR",
      iata_code: "SEL",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Ginbata Airport",
    longitude: 120.038012,
    latitude: -22.578284,
    id: "arp_gbw_au",
    icao_code: "YGIA",
    iata_country_code: "AU",
    iata_code: "GBW",
    city_name: "Ginbata",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Girdwood Airport",
    longitude: -149.120902,
    latitude: 60.968364,
    id: "arp_aqy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AQY",
    city_name: "Girdwood",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Girona-Costa Brava Airport",
    longitude: 2.762533,
    latitude: 41.900733,
    id: "arp_gro_es",
    icao_code: "LEGE",
    iata_country_code: "ES",
    iata_code: "GRO",
    city_name: "Girona",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Girua Airport",
    longitude: 148.309569,
    latitude: -8.804137,
    id: "arp_pnp_pg",
    icao_code: "AYGR",
    iata_country_code: "PG",
    iata_code: "PNP",
    city_name: "Popondetta",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Gisborne Airport",
    longitude: 177.98002,
    latitude: -38.662658,
    id: "arp_gis_nz",
    icao_code: "NZGS",
    iata_country_code: "NZ",
    iata_code: "GIS",
    city_name: "Gisborne",
    city: null,
  },
  {
    time_zone: "Africa/Kigali",
    name: "Gisenyi Airport",
    longitude: 29.259039,
    latitude: -1.677062,
    id: "arp_gyi_rw",
    icao_code: "HRYG",
    iata_country_code: "RW",
    iata_code: "GYI",
    city_name: "Gisenyi",
    city: null,
  },
  {
    time_zone: "Africa/Bujumbura",
    name: "Gitega Airport",
    longitude: 29.911308,
    latitude: -3.417209,
    id: "arp_gid_bi",
    icao_code: "HBBE",
    iata_country_code: "BI",
    iata_code: "GID",
    city_name: "Gitega",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Giyani Airport",
    longitude: 30.652571,
    latitude: -23.288145,
    id: "arp_giy_za",
    icao_code: "FAGI",
    iata_country_code: "ZA",
    iata_code: "GIY",
    city_name: "Giyani",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Gjoa Haven Airport",
    longitude: -95.849471,
    latitude: 68.634893,
    id: "arp_yhk_ca",
    icao_code: "CYHK",
    iata_country_code: "CA",
    iata_code: "YHK",
    city_name: "Gjoa Haven",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Gjögur Airport",
    longitude: -21.328412,
    latitude: 65.995184,
    id: "arp_gjr_is",
    icao_code: "BIGJ",
    iata_country_code: "IS",
    iata_code: "GJR",
    city_name: "Gjögur",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Glacier Creek Airport",
    longitude: -142.380771,
    latitude: 61.454799,
    id: "arp_kgz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KGZ",
    city_name: "Glacier Creek",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Glacier Park International Airport",
    longitude: -114.254692,
    latitude: 48.30994,
    id: "arp_fca_us",
    icao_code: "KGPI",
    iata_country_code: "US",
    iata_code: "FCA",
    city_name: "Kalispell",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Glacier Park International Airport",
    longitude: -77.898273,
    latitude: 2.570076,
    id: "arp_gpi_co",
    icao_code: "SKGP",
    iata_country_code: "CO",
    iata_code: "GPI",
    city_name: "Guapi",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Gladstone Airport",
    longitude: 151.223314,
    latitude: -23.869135,
    id: "arp_glt_au",
    icao_code: "YGLA",
    iata_country_code: "AU",
    iata_code: "GLT",
    city_name: "Gladstone",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gladwin Zettel Memorial Airport",
    longitude: -84.475751,
    latitude: 43.970904,
    id: "arp_gdw_us",
    icao_code: "KGDW",
    iata_country_code: "US",
    iata_code: "GDW",
    city_name: "Gladwin",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Glasgow International Airport",
    longitude: -4.434056,
    latitude: 55.870486,
    id: "arp_gla_gb",
    icao_code: "EGPF",
    iata_country_code: "GB",
    iata_code: "GLA",
    city_name: "Glasgow",
    city: {
      name: "Glasgow",
      id: "cit_gla_gb",
      iata_country_code: "GB",
      iata_code: "GLA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Glasgow Municipal Airport",
    longitude: -85.953891,
    latitude: 37.031379,
    id: "arp_glw_us",
    icao_code: "KGLW",
    iata_country_code: "US",
    iata_code: "GLW",
    city_name: "Glasgow",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Glasgow Prestwick Airport",
    longitude: -4.611032,
    latitude: 55.510005,
    id: "arp_pik_gb",
    icao_code: "EGPK",
    iata_country_code: "GB",
    iata_code: "PIK",
    city_name: "Glasgow",
    city: {
      name: "Glasgow",
      id: "cit_gla_gb",
      iata_country_code: "GB",
      iata_code: "GLA",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Glasgow Valley County Airport",
    longitude: -106.615002,
    latitude: 48.212462,
    id: "arp_ggw_us",
    icao_code: "KGGW",
    iata_country_code: "US",
    iata_code: "GGW",
    city_name: "Glasgow",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Glendale Fokker Field Airport",
    longitude: -80.759762,
    latitude: 39.948277,
    id: "arp_gwv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GWV",
    city_name: "Glendale",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Glengyle Airport",
    longitude: 139.598272,
    latitude: -24.803167,
    id: "arp_glg_au",
    icao_code: "YGLE",
    iata_country_code: "AU",
    iata_code: "GLG",
    city_name: "Glengyle",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Glen Innes Airport",
    longitude: 151.690609,
    latitude: -29.675201,
    id: "arp_gli_au",
    icao_code: "YGLI",
    iata_country_code: "AU",
    iata_code: "GLI",
    city_name: "Glen Innes",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Glenormiston Airport",
    longitude: 138.824675,
    latitude: -22.888688,
    id: "arp_glm_au",
    icao_code: "YGLO",
    iata_country_code: "AU",
    iata_code: "GLM",
    city_name: "Glenormiston",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Glentanner Airport",
    longitude: 170.12817,
    latitude: -43.906683,
    id: "arp_gtn_nz",
    icao_code: "NZGT",
    iata_country_code: "NZ",
    iata_code: "GTN",
    city_name: "Mount Cook",
    city: {
      name: "Mount Cook",
      id: "cit_mon_nz",
      iata_country_code: "NZ",
      iata_code: "MON",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Glenwood Springs Municipal Airport",
    longitude: -107.309867,
    latitude: 39.506696,
    id: "arp_gws_us",
    icao_code: "KGWS",
    iata_country_code: "US",
    iata_code: "GWS",
    city_name: "Glenwood Springs",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Gloucestershire Airport",
    longitude: -2.16418,
    latitude: 51.893764,
    id: "arp_glo_gb",
    icao_code: "EGBJ",
    iata_country_code: "GB",
    iata_code: "GLO",
    city_name: "Cheltenham/Gloucester",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gnarowein Airport",
    longitude: 146.248,
    latitude: -6.555,
    id: "arp_gwn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "GWN",
    city_name: "Gnarowein",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Goa Airport",
    longitude: 73.833197,
    latitude: 15.380574,
    id: "arp_goi_in",
    icao_code: "VOGO",
    iata_country_code: "IN",
    iata_code: "GOI",
    city_name: "Goa",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Gobabis Airport",
    longitude: 18.974612,
    latitude: -22.50461,
    id: "arp_gog_na",
    icao_code: "FYGB",
    iata_country_code: "NA",
    iata_code: "GOG",
    city_name: "Gobabis",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Gobernador Edgardo Castello Airport",
    longitude: -62.999155,
    latitude: -40.869559,
    id: "arp_vdm_ar",
    icao_code: "SAVV",
    iata_country_code: "AR",
    iata_code: "VDM",
    city_name: "Viedma",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Gobernador Gregores Airport",
    longitude: -70.151301,
    latitude: -48.783376,
    id: "arp_ggs_ar",
    icao_code: "SAWR",
    iata_country_code: "AR",
    iata_code: "GGS",
    city_name: "Gobernador Gregores",
    city: null,
  },
  {
    time_zone: "America/Argentina/Jujuy",
    name: "Gobernador Horacio Guzman International Airport",
    longitude: -65.096728,
    latitude: -24.389151,
    id: "arp_juj_ar",
    icao_code: "SASJ",
    iata_country_code: "AR",
    iata_code: "JUJ",
    city_name: "San Salvador de Jujuy",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Gode Airport",
    longitude: 43.577838,
    latitude: 5.934902,
    id: "arp_gde_et",
    icao_code: "HAGO",
    iata_country_code: "ET",
    iata_code: "GDE",
    city_name: "Gode",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Godman Army Airfield",
    longitude: -85.97214,
    latitude: 37.905474,
    id: "arp_ftk_us",
    icao_code: "KFTK",
    iata_country_code: "US",
    iata_code: "FTK",
    city_name: "Fort Knox",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Godofredo P. Ramos Airport",
    longitude: 121.954131,
    latitude: 11.92484,
    id: "arp_mph_ph",
    icao_code: "RPVE",
    iata_country_code: "PH",
    iata_code: "MPH",
    city_name: "Caticlan",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Gods Lake Narrows Airport",
    longitude: -94.488583,
    latitude: 54.557252,
    id: "arp_ygo_ca",
    icao_code: "CYGO",
    iata_country_code: "CA",
    iata_code: "YGO",
    city_name: "Gods Lake Narrows",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Gods River Airport",
    longitude: -94.07755,
    latitude: 54.838511,
    id: "arp_zgi_ca",
    icao_code: "CZGI",
    iata_country_code: "CA",
    iata_code: "ZGI",
    city_name: "Gods River",
    city: null,
  },
  {
    time_zone: "America/Menominee",
    name: "Gogebic–Iron County Airport",
    longitude: -90.131227,
    latitude: 46.526579,
    id: "arp_iwd_us",
    icao_code: "KIWD",
    iata_country_code: "US",
    iata_code: "IWD",
    city_name: "Ironwood",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Gökçeada Airport",
    longitude: 25.886203,
    latitude: 40.204154,
    id: "arp_gkd_tr",
    icao_code: "LTFK",
    iata_country_code: "TR",
    iata_code: "GKD",
    city_name: "Gökçeada",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Gol Airport",
    longitude: 9.050734,
    latitude: 60.790709,
    id: "arp_gll_no",
    icao_code: "ENKL",
    iata_country_code: "NO",
    iata_code: "GLL",
    city_name: "Gol",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Gold Beach Municipal Airport",
    longitude: -124.424084,
    latitude: 42.413366,
    id: "arp_gol_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GOL",
    city_name: "Gold Beach",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Gold Coast Airport",
    longitude: 153.507039,
    latitude: -28.165022,
    id: "arp_ool_au",
    icao_code: "YBCG",
    iata_country_code: "AU",
    iata_code: "OOL",
    city_name: "Gold Coast",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Golden Airport",
    longitude: -116.982867,
    latitude: 51.299028,
    id: "arp_yge_ca",
    icao_code: "CYGE",
    iata_country_code: "CA",
    iata_code: "YGE",
    city_name: "Gorge Harbour",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Golden Horn Lodge Seaplane Base",
    longitude: -158.874864,
    latitude: 59.74702,
    id: "arp_gdh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GDH",
    city_name: "Golden Horn Lodge",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Golden Triangle Regional Airport",
    longitude: -88.590009,
    latitude: 33.451568,
    id: "arp_gtr_us",
    icao_code: "KGTR",
    iata_country_code: "US",
    iata_code: "GTR",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_ubs_us",
      iata_country_code: "US",
      iata_code: "UBS",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Goldsworthy Airport",
    longitude: 119.50971,
    latitude: -20.333795,
    id: "arp_gly_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "GLY",
    city_name: "Goldsworthy",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Golfito Airport",
    longitude: -83.18008,
    latitude: 8.652283,
    id: "arp_glf_cr",
    icao_code: "MRGF",
    iata_country_code: "CR",
    iata_code: "GLF",
    city_name: "Golfito",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Golfo de Morrosquillo Airport",
    longitude: -75.585726,
    latitude: 9.510499,
    id: "arp_tlu_co",
    icao_code: "SKTL",
    iata_country_code: "CO",
    iata_code: "TLU",
    city_name: "Tolu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Golmud Airport",
    longitude: 94.788354,
    latitude: 36.402065,
    id: "arp_goq_cn",
    icao_code: "ZLGM",
    iata_country_code: "CN",
    iata_code: "GOQ",
    city_name: "Golmud",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Golog Maqin Airport",
    longitude: 100.301144,
    latitude: 34.418066,
    id: "arp_gmq_cn",
    icao_code: "ZLGL",
    iata_country_code: "CN",
    iata_code: "GMQ",
    city_name: "Golog",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Goloson International Airport",
    longitude: -86.852474,
    latitude: 15.743386,
    id: "arp_lce_hn",
    icao_code: "MHLC",
    iata_country_code: "HN",
    iata_code: "LCE",
    city_name: "La Ceiba",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Golovin Airport",
    longitude: -163.009451,
    latitude: 64.549669,
    id: "arp_glv_us",
    icao_code: "PAGL",
    iata_country_code: "US",
    iata_code: "GLV",
    city_name: "Golovin",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Goma International Airport",
    longitude: 29.238323,
    latitude: -1.668259,
    id: "arp_gom_cd",
    icao_code: "FZNA",
    iata_country_code: "CD",
    iata_code: "GOM",
    city_name: "Goma",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Gombe Lawanti International Airport",
    longitude: 10.908083,
    latitude: 10.30651,
    id: "arp_gmo_ng",
    icao_code: "DNGO",
    iata_country_code: "NG",
    iata_code: "GMO",
    city_name: "Gombe",
    city: null,
  },
  {
    time_zone: "Europe/Minsk",
    name: "Gomel Airport",
    longitude: 31.016708,
    latitude: 52.525663,
    id: "arp_gme_by",
    icao_code: "UMGG",
    iata_country_code: "BY",
    iata_code: "GME",
    city_name: "Gomel",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gonaili Airport",
    longitude: 151.573584,
    latitude: -5.527403,
    id: "arp_goe_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "GOE",
    city_name: "Gonaili",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Gondar Airport",
    longitude: 37.433141,
    latitude: 12.519168,
    id: "arp_gdq_et",
    icao_code: "HAGN",
    iata_country_code: "ET",
    iata_code: "GDQ",
    city_name: "Gondar",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gonzalo Mejía Airport",
    longitude: -76.740674,
    latitude: 8.072907,
    id: "arp_trb_co",
    icao_code: "SKTU",
    iata_country_code: "CO",
    iata_code: "TRB",
    city_name: "Turbo",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Gooding Municipal Airport",
    longitude: -114.764001,
    latitude: 42.917541,
    id: "arp_gng_us",
    icao_code: "KGNG",
    iata_country_code: "US",
    iata_code: "GNG",
    city_name: "Gooding",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Goodland Municipal Airport",
    longitude: -101.700594,
    latitude: 39.371096,
    id: "arp_gld_us",
    icao_code: "KGLD",
    iata_country_code: "US",
    iata_code: "GLD",
    city_name: "Goodland",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Goodnews Airport",
    longitude: -161.577539,
    latitude: 59.117507,
    id: "arp_gnu_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GNU",
    city_name: "Goodnews Bay",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Goondiwindi Airport",
    longitude: 150.319818,
    latitude: -28.522415,
    id: "arp_goo_au",
    icao_code: "YGDI",
    iata_country_code: "AU",
    iata_code: "GOO",
    city_name: "Goondiwindi",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Goose Bay Airport",
    longitude: -60.419799,
    latitude: 53.317347,
    id: "arp_yyr_ca",
    icao_code: "CYYR",
    iata_country_code: "CA",
    iata_code: "YYR",
    city_name: "Goose Bay",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gora Airport",
    longitude: 15.778167,
    latitude: 52.137578,
    id: "arp_goc_pg",
    icao_code: "AYGX",
    iata_country_code: "PG",
    iata_code: "GOC",
    city_name: "Gora",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Gorakhpur Airport",
    longitude: 83.448057,
    latitude: 26.742767,
    id: "arp_gop_in",
    icao_code: "VEGK",
    iata_country_code: "IN",
    iata_code: "GOP",
    city_name: "Gorakhpur",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Gordil Airport",
    longitude: 21.733,
    latitude: 9.583,
    id: "arp_gdi_cf",
    icao_code: "FEGL",
    iata_country_code: "CF",
    iata_code: "GDI",
    city_name: "Melle",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Gordon Downs Airport",
    longitude: 128.592164,
    latitude: -18.678192,
    id: "arp_gdd_au",
    icao_code: "YGDN",
    iata_country_code: "AU",
    iata_code: "GDD",
    city_name: "Gordon Downs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gordon K. Bush Airport",
    longitude: -82.227826,
    latitude: 39.211059,
    id: "arp_ato_us",
    icao_code: "KUNI",
    iata_country_code: "US",
    iata_code: "ATO",
    city_name: "Athens",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Gordon Municipal Airport",
    longitude: -102.177408,
    latitude: 42.803558,
    id: "arp_grn_us",
    icao_code: "KGRN",
    iata_country_code: "US",
    iata_code: "GRN",
    city_name: "Gordon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gordonsville Municipal Airport",
    longitude: -78.165951,
    latitude: 38.155933,
    id: "arp_gve_us",
    icao_code: "KGVE",
    iata_country_code: "US",
    iata_code: "GVE",
    city_name: "Gordonsville",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Gore Airport",
    longitude: 35.552284,
    latitude: 8.161175,
    id: "arp_gor_et",
    icao_code: "HAGR",
    iata_country_code: "ET",
    iata_code: "GOR",
    city_name: "Gore",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Gore Bay-Manitoulin Airport",
    longitude: -82.568448,
    latitude: 45.884555,
    id: "arp_yze_ca",
    icao_code: "CYZE",
    iata_country_code: "CA",
    iata_code: "YZE",
    city_name: "Gore Bay",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Gorgan Airport",
    longitude: 54.405954,
    latitude: 36.908971,
    id: "arp_gbt_ir",
    icao_code: "OING",
    iata_country_code: "IR",
    iata_code: "GBT",
    city_name: "Gorgan",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Gorna Oryahovitsa Airport",
    longitude: 25.712894,
    latitude: 43.151422,
    id: "arp_goz_bg",
    icao_code: "LBGO",
    iata_country_code: "BG",
    iata_code: "GOZ",
    city_name: "Gorna Oryahovitsa",
    city: null,
  },
  {
    time_zone: "Asia/Barnaul",
    name: "Gorno-Altaysk Airport",
    longitude: 85.833309,
    latitude: 51.96669,
    id: "arp_rgk_ru",
    icao_code: "UNBG",
    iata_country_code: "RU",
    iata_code: "RGK",
    city_name: "Gorno-Altaysk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Goroka Airport",
    longitude: 145.392049,
    latitude: -6.080219,
    id: "arp_gka_pg",
    icao_code: "AYGA",
    iata_country_code: "PG",
    iata_code: "GKA",
    city_name: "Goroka",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Gorom-Gorom Airport",
    longitude: -0.21851,
    latitude: 14.455091,
    id: "arp_xgg_bf",
    icao_code: "DFEG",
    iata_country_code: "BF",
    iata_code: "XGG",
    city_name: "Gorom-Gorom",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Gosford Airport",
    longitude: 151.35,
    latitude: -33.4333,
    id: "arp_gos_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "GOS",
    city_name: "Gosford",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Goshen Municipal Airport",
    longitude: -85.794086,
    latitude: 41.524745,
    id: "arp_gsh_us",
    icao_code: "KGSH",
    iata_country_code: "US",
    iata_code: "GSH",
    city_name: "Goshen",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Göteborg Landvetter Airport",
    longitude: 12.293269,
    latitude: 57.669258,
    id: "arp_got_se",
    icao_code: "ESGG",
    iata_country_code: "SE",
    iata_code: "GOT",
    city_name: "Gothenburg",
    city: {
      name: "Goteborg",
      id: "cit_got_se",
      iata_country_code: "SE",
      iata_code: "GOT",
    },
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Gothenburg City Airport",
    longitude: 11.868476,
    latitude: 57.775784,
    id: "arp_gse_se",
    icao_code: "ESGP",
    iata_country_code: "SE",
    iata_code: "GSE",
    city_name: "Gothenburg",
    city: {
      name: "Goteborg",
      id: "cit_got_se",
      iata_country_code: "SE",
      iata_code: "GOT",
    },
  },
  {
    time_zone: "Australia/Sydney",
    name: "Goulburn Airport",
    longitude: 149.729924,
    latitude: -34.808693,
    id: "arp_gul_au",
    icao_code: "YGLB",
    iata_country_code: "AU",
    iata_code: "GUL",
    city_name: "Goulburn",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Goulimime Airport",
    longitude: -10.050883,
    latitude: 29.022821,
    id: "arp_gln_ma",
    icao_code: "GMAG",
    iata_country_code: "MA",
    iata_code: "GLN",
    city_name: "Guelmim",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Gounda Airport",
    longitude: 21.195372,
    latitude: 9.271447,
    id: "arp_gda_cf",
    icao_code: null,
    iata_country_code: "CF",
    iata_code: "GDA",
    city_name: "Gounda",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Goundam Airport",
    longitude: -3.59938,
    latitude: 16.360981,
    id: "arp_gud_ml",
    icao_code: "GAGM",
    iata_country_code: "ML",
    iata_code: "GUD",
    city_name: "Goundam",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Gov. Dix-Sept Rosado Airport",
    longitude: -37.362926,
    latitude: -5.198094,
    id: "arp_mvf_br",
    icao_code: "SBMS",
    iata_country_code: "BR",
    iata_code: "MVF",
    city_name: "Mossoró",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Gove Airport",
    longitude: 136.820218,
    latitude: -12.26962,
    id: "arp_gov_au",
    icao_code: "YPGV",
    iata_country_code: "AU",
    iata_code: "GOV",
    city_name: "Nhulunbuy",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Governador Valadares Airport",
    longitude: -41.984215,
    latitude: -18.896322,
    id: "arp_gvr_br",
    icao_code: "SBGV",
    iata_country_code: "BR",
    iata_code: "GVR",
    city_name: "Governador Valadares",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Governor's Harbour Airport",
    longitude: -76.329525,
    latitude: 25.28301,
    id: "arp_ghb_bs",
    icao_code: "MYEM",
    iata_country_code: "BS",
    iata_code: "GHB",
    city_name: "Governor's Harbour",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Goya Airport",
    longitude: -59.218736,
    latitude: -29.105397,
    id: "arp_oya_ar",
    icao_code: "SATG",
    iata_country_code: "AR",
    iata_code: "OYA",
    city_name: "Goya",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Gracias Airport",
    longitude: -88.595941,
    latitude: 14.573424,
    id: "arp_gac_hn",
    icao_code: "MHGS",
    iata_country_code: "HN",
    iata_code: "GAC",
    city_name: "Gracias",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Graciosa Airport",
    longitude: -28.030786,
    latitude: 39.092006,
    id: "arp_grw_pt",
    icao_code: "LPGR",
    iata_country_code: "PT",
    iata_code: "GRW",
    city_name: "Graciosa Island",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Grafton Airport",
    longitude: 153.029281,
    latitude: -29.759497,
    id: "arp_gfn_au",
    icao_code: "YGFN",
    iata_country_code: "AU",
    iata_code: "GFN",
    city_name: "Grafton",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "Gran Canaria Airport",
    longitude: -15.387153,
    latitude: 27.932541,
    id: "arp_lpa_es",
    icao_code: "GCLP",
    iata_country_code: "ES",
    iata_code: "LPA",
    city_name: "Las Palmas",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Grand Bahama International Airport",
    longitude: -78.696463,
    latitude: 26.548503,
    id: "arp_fpo_bs",
    icao_code: "MYGF",
    iata_country_code: "BS",
    iata_code: "FPO",
    city_name: "Freeport",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Grand Canyon Caverns Airport",
    longitude: -113.240574,
    latitude: 35.530087,
    id: "arp_pgs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PGS",
    city_name: "Peach Springs",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Grand Canyon National Park Airport",
    longitude: -112.145386,
    latitude: 35.953113,
    id: "arp_gcn_us",
    icao_code: "KGCN",
    iata_country_code: "US",
    iata_code: "GCN",
    city_name: "Grand Canyon",
    city: {
      name: "Grand Canyon",
      id: "cit_gcn_us",
      iata_country_code: "US",
      iata_code: "GCN",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Grand Canyon West Airport",
    longitude: -113.818049,
    latitude: 35.988367,
    id: "arp_gcw_us",
    icao_code: "KGCN",
    iata_country_code: "US",
    iata_code: "GCW",
    city_name: "Peach Springs",
    city: null,
  },
  {
    time_zone: "America/Marigot",
    name: "Grand Case-Espérance Airport",
    longitude: -63.049779,
    latitude: 18.100423,
    id: "arp_cce_gp",
    icao_code: null,
    iata_country_code: "GP",
    iata_code: "CCE",
    city_name: "Cairo",
    city: {
      name: "Cairo",
      id: "cit_cai_eg",
      iata_country_code: "EG",
      iata_code: "CAI",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Grand Central Airport",
    longitude: 28.140448,
    latitude: -25.98778,
    id: "arp_gcj_za",
    icao_code: "FAGC",
    iata_country_code: "ZA",
    iata_code: "GCJ",
    city_name: "Johannesburg",
    city: {
      name: "Johannesburg",
      id: "cit_jnb_za",
      iata_country_code: "ZA",
      iata_code: "JNB",
    },
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Grand Cess Airport",
    longitude: -8.209136,
    latitude: 4.567588,
    id: "arp_grc_lr",
    icao_code: null,
    iata_country_code: "LR",
    iata_code: "GRC",
    city_name: "Grand Cess",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Grand Coulee Dam Airport",
    longitude: -119.083581,
    latitude: 47.921425,
    id: "arp_gcd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GCD",
    city_name: "Electric City",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Grande Cache Airport",
    longitude: -118.874222,
    latitude: 53.916922,
    id: "arp_ygc_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YGC",
    city_name: "Grande Cache",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Grande Prairie Airport",
    longitude: -118.879404,
    latitude: 55.178189,
    id: "arp_yqu_ca",
    icao_code: "CYQU",
    iata_country_code: "CA",
    iata_code: "YQU",
    city_name: "Grande Prairie",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grand Forks Air Force Base Airport",
    longitude: -97.401169,
    latitude: 47.961105,
    id: "arp_rdr_us",
    icao_code: "KRDR",
    iata_country_code: "US",
    iata_code: "RDR",
    city_name: "Grand Forks",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Grand Forks Airport",
    longitude: -118.42965,
    latitude: 49.015855,
    id: "arp_zgf_ca",
    icao_code: "CZGF",
    iata_country_code: "CA",
    iata_code: "ZGF",
    city_name: "Grand Forks",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grand Forks International Airport",
    longitude: -97.176342,
    latitude: 47.94608,
    id: "arp_gfk_us",
    icao_code: "KGFK",
    iata_country_code: "US",
    iata_code: "GFK",
    city_name: "Grand Forks",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grand Geneva Resort Airport",
    longitude: -88.393333,
    latitude: 42.613209,
    id: "arp_xes_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "XES",
    city_name: "Lake Geneva",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Grand Junction Regional Airport",
    longitude: -108.527296,
    latitude: 39.122781,
    id: "arp_gjt_us",
    icao_code: "KGJT",
    iata_country_code: "US",
    iata_code: "GJT",
    city_name: "Grand Junction",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grand Lake Regional Airport",
    longitude: -94.860786,
    latitude: 36.577432,
    id: "arp_nri_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NRI",
    city_name: "Afton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grand Marais/Cook County Airport",
    longitude: -90.383075,
    latitude: 47.837785,
    id: "arp_grm_us",
    icao_code: "KCKC",
    iata_country_code: "US",
    iata_code: "GRM",
    city_name: "Grand Marais",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grand Rapids–Itasca County Airport",
    longitude: -93.51049,
    latitude: 47.211009,
    id: "arp_gpz_us",
    icao_code: "KGPZ",
    iata_country_code: "US",
    iata_code: "GPZ",
    city_name: "Grand Rapids",
    city: null,
  },
  {
    time_zone: "America/Cayenne",
    name: "Grand-Santi Airport",
    longitude: -54.37407,
    latitude: 4.285553,
    id: "arp_gsi_gf",
    icao_code: "SOGS",
    iata_country_code: "GF",
    iata_code: "GSI",
    city_name: "Grand-Santi",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Grand Strand Airport",
    longitude: -78.723895,
    latitude: 33.811683,
    id: "arp_cre_us",
    icao_code: "KCRE",
    iata_country_code: "US",
    iata_code: "CRE",
    city_name: "North Myrtle Beach",
    city: {
      name: "Myrtle Beach",
      id: "cit_myr_us",
      iata_country_code: "US",
      iata_code: "MYR",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Granite Mountain Air Station",
    longitude: -161.281391,
    latitude: 65.402276,
    id: "arp_gmt_us",
    icao_code: "PAGZ",
    iata_country_code: "US",
    iata_code: "GMT",
    city_name: "Granite Mountain",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Grant County Airport",
    longitude: -79.145949,
    latitude: 38.994938,
    id: "arp_pgc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PGC",
    city_name: "Petersburg",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Grant County Airport",
    longitude: -108.15399,
    latitude: 32.63669,
    id: "arp_svc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SVC",
    city_name: "Silver City",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Grant County International Airport",
    longitude: -119.31923,
    latitude: 47.207825,
    id: "arp_mwh_us",
    icao_code: "KMWH",
    iata_country_code: "US",
    iata_code: "MWH",
    city_name: "Moses Lake",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Grant County Regional Airport",
    longitude: -118.962156,
    latitude: 44.403587,
    id: "arp_jda_us",
    icao_code: "KGCD",
    iata_country_code: "US",
    iata_code: "JDA",
    city_name: "John Day",
    city: null,
  },
  {
    time_zone: "America/Barbados",
    name: "Grantley Adams International Airport",
    longitude: -59.491502,
    latitude: 13.076139,
    id: "arp_bgi_bb",
    icao_code: "TBPB",
    iata_country_code: "BB",
    iata_code: "BGI",
    city_name: "Bridgetown",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grantsburg Municipal Airport",
    longitude: -92.664421,
    latitude: 45.798077,
    id: "arp_gtg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GTG",
    city_name: "Grantsburg",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Grants-Milan Municipal Airport",
    longitude: -107.901519,
    latitude: 35.166627,
    id: "arp_gnt_us",
    icao_code: "KGNT",
    iata_country_code: "US",
    iata_code: "GNT",
    city_name: "Grants",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Grants Pass Airport",
    longitude: -123.388481,
    latitude: 42.50968,
    id: "arp_gtp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GTP",
    city_name: "Grants Pass",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Granville Airport",
    longitude: -1.564724,
    latitude: 48.882659,
    id: "arp_gfr_fr",
    icao_code: "LFRF",
    iata_country_code: "FR",
    iata_code: "GFR",
    city_name: "Granville",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Gratiot Community Airport",
    longitude: -84.687716,
    latitude: 43.324055,
    id: "arp_amn_us",
    icao_code: "KAMN",
    iata_country_code: "US",
    iata_code: "AMN",
    city_name: "Alma",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Gray Army Airfield",
    longitude: -122.582793,
    latitude: 47.087137,
    id: "arp_grf_us",
    icao_code: "KGRF",
    iata_country_code: "US",
    iata_code: "GRF",
    city_name: "Tacoma",
    city: {
      name: "Tacoma",
      id: "cit_tiw_us",
      iata_country_code: "US",
      iata_code: "TIW",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Grayling Airport",
    longitude: -160.066824,
    latitude: 62.895556,
    id: "arp_kgx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KGX",
    city_name: "Grayling",
    city: null,
  },
  {
    time_zone: "Europe/Vienna",
    name: "Graz Airport",
    longitude: 15.439865,
    latitude: 46.992573,
    id: "arp_grz_at",
    icao_code: "LOWG",
    iata_country_code: "AT",
    iata_code: "GRZ",
    city_name: "Graz",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Great Barrier Aerodrome",
    longitude: 175.470917,
    latitude: -36.241691,
    id: "arp_gbz_nz",
    icao_code: "NZGB",
    iata_country_code: "NZ",
    iata_code: "GBZ",
    city_name: "Great Barrier Island",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Great Bear Lake Airport",
    longitude: -119.705871,
    latitude: 66.702528,
    id: "arp_das_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "DAS",
    city_name: "Great Bear Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Great Bend Municipal Airport",
    longitude: -98.856477,
    latitude: 38.345617,
    id: "arp_gbd_us",
    icao_code: "KGBD",
    iata_country_code: "US",
    iata_code: "GBD",
    city_name: "Great Bend",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greater Binghamton Airport",
    longitude: -75.980981,
    latitude: 42.208392,
    id: "arp_bgm_us",
    icao_code: "KBGM",
    iata_country_code: "US",
    iata_code: "BGM",
    city_name: "Binghamton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greater Cumberland Regional Airport",
    longitude: -78.76123,
    latitude: 39.615348,
    id: "arp_cbe_us",
    icao_code: "KCBE",
    iata_country_code: "US",
    iata_code: "CBE",
    city_name: "Cumberland",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Greater Kankakee Airport",
    longitude: -87.847172,
    latitude: 41.068489,
    id: "arp_ikk_us",
    icao_code: "KIKK",
    iata_country_code: "US",
    iata_code: "IKK",
    city_name: "Kankakee",
    city: null,
  },
  {
    time_zone: "America/Moncton",
    name: "Greater Moncton Roméo LeBlanc International Airport",
    longitude: -64.681081,
    latitude: 46.113594,
    id: "arp_yqm_ca",
    icao_code: "CYQM",
    iata_country_code: "CA",
    iata_code: "YQM",
    city_name: "Moncton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greater Portsmouth Regional Airport",
    longitude: -82.8474,
    latitude: 38.840528,
    id: "arp_pmh_us",
    icao_code: "KPMH",
    iata_country_code: "US",
    iata_code: "PMH",
    city_name: "Portsmouth",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greater Rochester International Airport",
    longitude: -77.669486,
    latitude: 43.120711,
    id: "arp_roc_us",
    icao_code: "KROC",
    iata_country_code: "US",
    iata_code: "ROC",
    city_name: "Rochester",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Great Falls International Airport",
    longitude: -111.357391,
    latitude: 47.482951,
    id: "arp_gtf_us",
    icao_code: "KGTF",
    iata_country_code: "US",
    iata_code: "GTF",
    city_name: "Great Falls",
    city: {
      name: "Great Falls",
      id: "cit_gtf_us",
      iata_country_code: "US",
      iata_code: "GTF",
    },
  },
  {
    time_zone: "America/Nassau",
    name: "Great Harbour Cay Airport",
    longitude: -77.842206,
    latitude: 25.739908,
    id: "arp_ghc_bs",
    icao_code: "MYBG",
    iata_country_code: "BS",
    iata_code: "GHC",
    city_name: "Great Harbour Cay",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Great Keppel Island Airport",
    longitude: 150.941579,
    latitude: -23.18359,
    id: "arp_gkl_au",
    icao_code: "YGKL",
    iata_country_code: "AU",
    iata_code: "GKL",
    city_name: "Great Keppel Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Greeley-Weld County Airport",
    longitude: -104.636654,
    latitude: 40.427463,
    id: "arp_gxy_us",
    icao_code: "KGXY",
    iata_country_code: "US",
    iata_code: "GXY",
    city_name: "Greeley",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Green Bay–Austin Straubel International Airport",
    longitude: -88.129615,
    latitude: 44.487265,
    id: "arp_grb_us",
    icao_code: "KGRB",
    iata_country_code: "US",
    iata_code: "GRB",
    city_name: "Green Bay",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greenbrier Valley Airport",
    longitude: -80.402122,
    latitude: 37.857291,
    id: "arp_lwb_us",
    icao_code: "KLWB",
    iata_country_code: "US",
    iata_code: "LWB",
    city_name: "Lewisburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greene County Airport",
    longitude: -80.132048,
    latitude: 39.9001,
    id: "arp_way_us",
    icao_code: "KWAY",
    iata_country_code: "US",
    iata_code: "WAY",
    city_name: "Waynesburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greeneville-Greene County Municipal Airport",
    longitude: -82.815035,
    latitude: 36.193036,
    id: "arp_gcy_us",
    icao_code: "KGCY",
    iata_country_code: "US",
    iata_code: "GCY",
    city_name: "Greeneville",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Greenlee County Airport",
    longitude: -109.211634,
    latitude: 32.956665,
    id: "arp_cft_us",
    icao_code: "KCFT",
    iata_country_code: "US",
    iata_code: "CFT",
    city_name: "Clifton/Morenci",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Green River Airport",
    longitude: 141.176973,
    latitude: -3.90155,
    id: "arp_gvi_pg",
    icao_code: "AYGV",
    iata_country_code: "PG",
    iata_code: "GVI",
    city_name: "Green River",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Green River Municipal Airport",
    longitude: -65.644161,
    latitude: 18.253574,
    id: "arp_rvr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RVR",
    city_name: "Green River",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Greenvale Airport",
    longitude: 145.011354,
    latitude: -18.994449,
    id: "arp_gvp_au",
    icao_code: "YGNV",
    iata_country_code: "AU",
    iata_code: "GVP",
    city_name: "Greenvale",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Greenville Airport",
    longitude: -89.377396,
    latitude: 38.835878,
    id: "arp_gre_us",
    icao_code: "KGRE",
    iata_country_code: "US",
    iata_code: "GRE",
    city_name: "Greenville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greenville Downtown Airport",
    longitude: -82.350687,
    latitude: 34.849161,
    id: "arp_gmu_us",
    icao_code: "KGMU",
    iata_country_code: "US",
    iata_code: "GMU",
    city_name: "Greenville",
    city: {
      name: "Greenville/Spartanburg",
      id: "cit_gsp_us",
      iata_country_code: "US",
      iata_code: "GSP",
    },
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Greenville Sinoe Airport",
    longitude: -9.065093,
    latitude: 5.034853,
    id: "arp_sni_lr",
    icao_code: "GLGE",
    iata_country_code: "LR",
    iata_code: "SNI",
    city_name: "Greenville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greenville-Spartanburg International Airport",
    longitude: -82.218068,
    latitude: 34.895799,
    id: "arp_gsp_us",
    icao_code: "KGSP",
    iata_country_code: "US",
    iata_code: "GSP",
    city_name: "Greenville",
    city: {
      name: "Greenville/Spartanburg",
      id: "cit_gsp_us",
      iata_country_code: "US",
      iata_code: "GSP",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Greenway Sound Airport",
    longitude: -126.85,
    latitude: 50.85,
    id: "arp_ygn_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YGN",
    city_name: "Greenway Sound",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Greenwood County Airport",
    longitude: -82.157187,
    latitude: 34.248134,
    id: "arp_grd_us",
    icao_code: "KGRD",
    iata_country_code: "US",
    iata_code: "GRD",
    city_name: "Greenwood",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Greenwood-Leflore Airport",
    longitude: -90.085197,
    latitude: 33.494052,
    id: "arp_gwo_us",
    icao_code: "KGWO",
    iata_country_code: "US",
    iata_code: "GWO",
    city_name: "Greenwood",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Gregorio Luperon International Airport",
    longitude: -70.566742,
    latitude: 19.756525,
    id: "arp_pop_do",
    icao_code: "MDPP",
    iata_country_code: "DO",
    iata_code: "POP",
    city_name: "Puerto Plata",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Gregory Downs Airport",
    longitude: 139.233523,
    latitude: -18.628154,
    id: "arp_ggd_au",
    icao_code: "YGDS",
    iata_country_code: "AU",
    iata_code: "GGD",
    city_name: "Gregory Downs",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Gregory Lake Seaplane Base Airport",
    longitude: 80.779901,
    latitude: 6.956375,
    id: "arp_nua_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "NUA",
    city_name: "Nuwara Eliya",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Grenfell Airport",
    longitude: 148.155139,
    latitude: 33.897737,
    id: "arp_gfe_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "GFE",
    city_name: "Grenfell",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Greymouth Airport",
    longitude: 171.190538,
    latitude: -42.459488,
    id: "arp_gmn_nz",
    icao_code: "NZGM",
    iata_country_code: "NZ",
    iata_code: "GMN",
    city_name: "Greymouth",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Grider Field Airport",
    longitude: -91.936696,
    latitude: 34.176236,
    id: "arp_pbf_us",
    icao_code: "KPBF",
    iata_country_code: "US",
    iata_code: "PBF",
    city_name: "Pine Bluff",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Griffing Sandusky Airport",
    longitude: -82.652774,
    latitude: 41.432316,
    id: "arp_sky_us",
    icao_code: "KSKY",
    iata_country_code: "US",
    iata_code: "SKY",
    city_name: "Sandusky",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Griffiss International Airport",
    longitude: -75.40657,
    latitude: 43.232936,
    id: "arp_rme_us",
    icao_code: "KRME",
    iata_country_code: "US",
    iata_code: "RME",
    city_name: "Rome",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Griffith Airport",
    longitude: 146.06485,
    latitude: -34.253116,
    id: "arp_gff_au",
    icao_code: "YGTH",
    iata_country_code: "AU",
    iata_code: "GFF",
    city_name: "Griffith",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Grímsey Airport",
    longitude: -18.019133,
    latitude: 66.546867,
    id: "arp_gry_is",
    icao_code: "BIGR",
    iata_country_code: "IS",
    iata_code: "GRY",
    city_name: "Grímsey",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Grise Fiord Airport",
    longitude: -82.909202,
    latitude: 76.425554,
    id: "arp_ygz_ca",
    icao_code: "CYGZ",
    iata_country_code: "CA",
    iata_code: "YGZ",
    city_name: "Grise Fiord",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Grissom Air Reserve Base",
    longitude: -86.1521,
    latitude: 40.648102,
    id: "arp_gus_us",
    icao_code: "KGUS",
    iata_country_code: "US",
    iata_code: "GUS",
    city_name: "Peru",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Groningen Airport Eelde",
    longitude: 6.58201,
    latitude: 53.122885,
    id: "arp_grq_nl",
    icao_code: "EHGG",
    iata_country_code: "NL",
    iata_code: "GRQ",
    city_name: "Groningen",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Groote Eylandt Airport",
    longitude: 136.459372,
    latitude: -13.974383,
    id: "arp_gte_au",
    icao_code: "YGTE",
    iata_country_code: "AU",
    iata_code: "GTE",
    city_name: "Groote Eylandt",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Grootfontein Airport",
    longitude: 18.136412,
    latitude: -19.59717,
    id: "arp_gfy_na",
    icao_code: "FYGF",
    iata_country_code: "NA",
    iata_code: "GFY",
    city_name: "Grootfontein",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Grosseto Airport",
    longitude: 11.077544,
    latitude: 42.761285,
    id: "arp_grs_it",
    icao_code: "LIRS",
    iata_country_code: "IT",
    iata_code: "GRS",
    city_name: "Grosseto",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Groton–New London Airport",
    longitude: -72.046153,
    latitude: 41.330616,
    id: "arp_gon_us",
    icao_code: "KGON",
    iata_country_code: "US",
    iata_code: "GON",
    city_name: "New London/Groton",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Grozny Airport",
    longitude: 45.699457,
    latitude: 43.387053,
    id: "arp_grv_ru",
    icao_code: "URMG",
    iata_country_code: "RU",
    iata_code: "GRV",
    city_name: "Grozny",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Grumeti Airstrip Airport",
    longitude: 34.506176,
    latitude: -2.070641,
    id: "arp_gtz_tz",
    icao_code: "HTSN",
    iata_country_code: "TZ",
    iata_code: "GTZ",
    city_name: "Grumeti Game Reserve",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Grundarfjörður Airport",
    longitude: -23.2247,
    latitude: 64.991401,
    id: "arp_guu_is",
    icao_code: "BIGF",
    iata_country_code: "IS",
    iata_code: "GUU",
    city_name: "Grundarfjörður",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Guacamayas Airport",
    longitude: -74.95,
    latitude: 2.283333,
    id: "arp_gca_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "GCA",
    city_name: "Guacamayas",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Guadalupe Airport",
    longitude: -43.250313,
    latitude: -22.813459,
    id: "arp_gdp_br",
    icao_code: "SNGD",
    iata_country_code: "BR",
    iata_code: "GDP",
    city_name: "Guadalupe",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Guajará-Mirim Airport",
    longitude: -65.282919,
    latitude: -10.787207,
    id: "arp_gjm_br",
    icao_code: "SBGM",
    iata_country_code: "BR",
    iata_code: "GJM",
    city_name: "Guajara-Mirim",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Gualeguaychu Airport",
    longitude: -58.612996,
    latitude: -33.010657,
    id: "arp_ghu_ar",
    icao_code: "SAAG",
    iata_country_code: "AR",
    iata_code: "GHU",
    city_name: "Gualeguaychu",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Guamal Airport",
    longitude: -74.2358,
    latitude: 9.14783,
    id: "arp_gaa_co",
    icao_code: "SKGY",
    iata_country_code: "CO",
    iata_code: "GAA",
    city_name: "Guamal",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Guanaja Airport",
    longitude: -85.906565,
    latitude: 16.445383,
    id: "arp_gja_hn",
    icao_code: "MHNJ",
    iata_country_code: "HN",
    iata_code: "GJA",
    city_name: "Guanaja",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Guanambi Airport",
    longitude: -42.747596,
    latitude: -14.208587,
    id: "arp_gnm_br",
    icao_code: "SNGI",
    iata_country_code: "BR",
    iata_code: "GNM",
    city_name: "Guanambi",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Guanare Airport",
    longitude: -69.75477,
    latitude: 9.026891,
    id: "arp_guq_ve",
    icao_code: "SVGU",
    iata_country_code: "VE",
    iata_code: "GUQ",
    city_name: "Guanare",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guanghan Airport",
    longitude: 104.331751,
    latitude: 30.944493,
    id: "arp_ghn_cn",
    icao_code: "ZUGH",
    iata_country_code: "CN",
    iata_code: "GHN",
    city_name: "Guanghan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guangyuan Panlong Airport",
    longitude: 105.702863,
    latitude: 32.39182,
    id: "arp_gys_cn",
    icao_code: "ZUGU",
    iata_country_code: "CN",
    iata_code: "GYS",
    city_name: "Guangyuan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guangzhou Baiyun International Airport",
    longitude: 113.301992,
    latitude: 23.393569,
    id: "arp_can_cn",
    icao_code: "ZGGG",
    iata_country_code: "CN",
    iata_code: "CAN",
    city_name: "Guangzhou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guangzhou MR Air Base Airport",
    longitude: 111.695,
    latitude: 32.3894,
    id: "arp_lhk_cn",
    icao_code: "ZHGH",
    iata_country_code: "CN",
    iata_code: "LHK",
    city_name: "Guanghua",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Guapiles Airport",
    longitude: -83.79796,
    latitude: 10.215928,
    id: "arp_gpl_cr",
    icao_code: "MRGP",
    iata_country_code: "CR",
    iata_code: "GPL",
    city_name: "Guapiles",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Guaraní International Airport",
    longitude: -54.843137,
    latitude: -25.455008,
    id: "arp_agt_py",
    icao_code: "SGES",
    iata_country_code: "PY",
    iata_code: "AGT",
    city_name: "Ciudad del Este",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Guarapari Airport",
    longitude: -40.491558,
    latitude: -20.646666,
    id: "arp_guz_br",
    icao_code: "SNGA",
    iata_country_code: "BR",
    iata_code: "GUZ",
    city_name: "Guarapari",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Guaratinguetá Airport",
    longitude: -45.2048,
    latitude: -22.791599,
    id: "arp_guj_br",
    icao_code: "SBGW",
    iata_country_code: "BR",
    iata_code: "GUJ",
    city_name: "Guaratingueta",
    city: null,
  },
  {
    time_zone: "America/Punta_Arenas",
    name: "Guardiamarina Zañartu Airport",
    longitude: -67.628839,
    latitude: -54.931196,
    id: "arp_wpu_cl",
    icao_code: "SCGZ",
    iata_country_code: "CL",
    iata_code: "WPU",
    city_name: "Puerto Williams",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Guari Airport",
    longitude: 146.8722,
    latitude: -8.1286,
    id: "arp_gug_pg",
    icao_code: "AYGF",
    iata_country_code: "PG",
    iata_code: "GUG",
    city_name: "Guari",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Guasdualito Airport",
    longitude: -70.757567,
    latitude: 7.21093,
    id: "arp_gdo_ve",
    icao_code: "SVGD",
    iata_country_code: "VE",
    iata_code: "GDO",
    city_name: "Guasdualito",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Guasopa Airport",
    longitude: 152.945344,
    latitude: -9.22652,
    id: "arp_gaz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "GAZ",
    city_name: "Guasopa",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Guayaramerín Airport",
    longitude: -65.346208,
    latitude: -10.820737,
    id: "arp_gya_bo",
    icao_code: "SLGY",
    iata_country_code: "BO",
    iata_code: "GYA",
    city_name: "Guayaramerín",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Guemar Airport",
    longitude: 6.779619,
    latitude: 33.511382,
    id: "arp_elu_dz",
    icao_code: "DAUO",
    iata_country_code: "DZ",
    iata_code: "ELU",
    city_name: "El Oued",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Guerima Airport",
    longitude: -70.32361,
    latitude: 3.629148,
    id: "arp_gmc_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "GMC",
    city_name: "Guerima",
    city: null,
  },
  {
    time_zone: "Europe/Guernsey",
    name: "Guernsey Airport",
    longitude: -2.600729,
    latitude: 49.43454,
    id: "arp_gci_gg",
    icao_code: "EGJB",
    iata_country_code: "GG",
    iata_code: "GCI",
    city_name: "Guernsey",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "Guerrero Negro Airport",
    longitude: -114.022885,
    latitude: 28.025782,
    id: "arp_gub_mx",
    icao_code: "MMGR",
    iata_country_code: "MX",
    iata_code: "GUB",
    city_name: "Guerrero Negro",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Guiglo Airport",
    longitude: -7.526523,
    latitude: 6.534186,
    id: "arp_ggo_ci",
    icao_code: "DIGL",
    iata_country_code: "CI",
    iata_code: "GGO",
    city_name: "Guiglo",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guilin Liangjiang International Airport",
    longitude: 110.041689,
    latitude: 25.217359,
    id: "arp_kwl_cn",
    icao_code: "ZGKL",
    iata_country_code: "CN",
    iata_code: "KWL",
    city_name: "Guilin",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Guillermo León Valencia Airport",
    longitude: -76.609398,
    latitude: 2.45379,
    id: "arp_ppn_co",
    icao_code: "SKPP",
    iata_country_code: "CO",
    iata_code: "PPN",
    city_name: "Popayan",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Guimarães Airport",
    longitude: -44.6,
    latitude: -2.13333,
    id: "arp_gms_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "GMS",
    city_name: "Guimaraes",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Guiria Airport",
    longitude: -62.313074,
    latitude: 10.573736,
    id: "arp_gui_ve",
    icao_code: "SVGI",
    iata_country_code: "VE",
    iata_code: "GUI",
    city_name: "Guiria",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guiyang Longdongbao International Airport",
    longitude: 106.800606,
    latitude: 26.539596,
    id: "arp_kwe_cn",
    icao_code: "ZUGY",
    iata_country_code: "CN",
    iata_code: "KWE",
    city_name: "Guiyang",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Gujrat Airport",
    longitude: 74.0833,
    latitude: 32.5667,
    id: "arp_grt_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "GRT",
    city_name: "Gujrat",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Gulfport–Biloxi International Airport",
    longitude: -89.071483,
    latitude: 30.410273,
    id: "arp_gpt_us",
    icao_code: "KGPT",
    iata_country_code: "US",
    iata_code: "GPT",
    city_name: "Gulfport",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gulgubip Airport",
    longitude: 141.5411,
    latitude: -5.2808,
    id: "arp_glp_pg",
    icao_code: "AYUP",
    iata_country_code: "PG",
    iata_code: "GLP",
    city_name: "Gulgubip",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Gulkana Airport",
    longitude: -145.456817,
    latitude: 62.154893,
    id: "arp_gkn_us",
    icao_code: "PAGK",
    iata_country_code: "US",
    iata_code: "GKN",
    city_name: "Gulkana",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Gulu Airport",
    longitude: 32.272839,
    latitude: 2.800398,
    id: "arp_ulu_ug",
    icao_code: "HUGU",
    iata_country_code: "UG",
    iata_code: "ULU",
    city_name: "Gulu",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Guna Airport",
    longitude: 77.347081,
    latitude: 24.654498,
    id: "arp_gux_in",
    icao_code: "VAGN",
    iata_country_code: "IN",
    iata_code: "GUX",
    city_name: "Guna",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Gunnedah Airport",
    longitude: 150.252727,
    latitude: -30.959817,
    id: "arp_guh_au",
    icao_code: "YGDH",
    iata_country_code: "AU",
    iata_code: "GUH",
    city_name: "Gunnedah",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Gunnison-Crested Butte Regional Airport",
    longitude: -106.934198,
    latitude: 38.535574,
    id: "arp_guc_us",
    icao_code: "KGUC",
    iata_country_code: "US",
    iata_code: "GUC",
    city_name: "Gunnison",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Gunsan Airport",
    longitude: 126.615822,
    latitude: 35.926038,
    id: "arp_kuv_kr",
    icao_code: "RKJK",
    iata_country_code: "KR",
    iata_code: "KUV",
    city_name: "Gunsan",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Gunung Batin Airport",
    longitude: 105.228224,
    latitude: -4.612618,
    id: "arp_akq_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "AKQ",
    city_name: "Menggala",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Gurayat Domestic Airport",
    longitude: 37.277048,
    latitude: 31.41119,
    id: "arp_ury_sa",
    icao_code: "OEGT",
    iata_country_code: "SA",
    iata_code: "URY",
    city_name: "Gurayat",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Guriaso Airport",
    longitude: 141.5895,
    latitude: -3.583167,
    id: "arp_gue_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "GUE",
    city_name: "Guriaso",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Guriel Airport",
    longitude: 45.985271,
    latitude: 5.316838,
    id: "arp_guo_so",
    icao_code: null,
    iata_country_code: "SO",
    iata_code: "GUO",
    city_name: "Guriel",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gurney Airport",
    longitude: 150.333952,
    latitude: -10.310974,
    id: "arp_gur_pg",
    icao_code: "AYGN",
    iata_country_code: "PG",
    iata_code: "GUR",
    city_name: "Alotau",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Gurue Airport",
    longitude: 36.533918,
    latitude: -14.818201,
    id: "arp_vjq_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "VJQ",
    city_name: "Gurue",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Gurupi Airport",
    longitude: -49.132198,
    latitude: -11.7396,
    id: "arp_grp_br",
    icao_code: "SWGI",
    iata_country_code: "BR",
    iata_code: "GRP",
    city_name: "Gurupi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Gusap Airport",
    longitude: 145.959272,
    latitude: -6.053281,
    id: "arp_gap_pg",
    icao_code: "AYGP",
    iata_country_code: "PG",
    iata_code: "GAP",
    city_name: "Gusap",
    city: null,
  },
  {
    time_zone: "Asia/Gaza",
    name: "Gush Katif Airport",
    longitude: 34.292482,
    latitude: 31.366299,
    id: "arp_ghk_ps",
    icao_code: null,
    iata_country_code: "PS",
    iata_code: "GHK",
    city_name: "Kennedy Lake",
    city: null,
  },
  {
    time_zone: "America/St_Barthelemy",
    name: "Gustaf III Airport",
    longitude: -62.844327,
    latitude: 17.904293,
    id: "arp_sbh_bl",
    icao_code: "TFFJ",
    iata_country_code: "BL",
    iata_code: "SBH",
    city_name: "Gustavia",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gustavo Artunduaga Paredes Airport",
    longitude: -75.564942,
    latitude: 1.589614,
    id: "arp_fla_co",
    icao_code: "SKFL",
    iata_country_code: "CO",
    iata_code: "FLA",
    city_name: "Florencia",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Gustavo Rizo Airport",
    longitude: -74.505667,
    latitude: 20.363514,
    id: "arp_bca_cu",
    icao_code: "MUBA",
    iata_country_code: "CU",
    iata_code: "BCA",
    city_name: "Baracoa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Gustavo Rojas Pinilla International Airport",
    longitude: -81.702667,
    latitude: 12.586463,
    id: "arp_adz_co",
    icao_code: "SKSP",
    iata_country_code: "CO",
    iata_code: "ADZ",
    city_name: "Santa Catalina/San Andrés",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Gustavus Airport",
    longitude: -135.706029,
    latitude: 58.424513,
    id: "arp_gst_us",
    icao_code: "PAGS",
    iata_country_code: "US",
    iata_code: "GST",
    city_name: "Gustavus",
    city: {
      name: "Gustavus",
      id: "cit_gst_us",
      iata_country_code: "US",
      iata_code: "GST",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Guthrie County Regional Airport",
    longitude: -94.435228,
    latitude: 41.686748,
    id: "arp_gct_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GCT",
    city_name: "Grand Canyon",
    city: {
      name: "Grand Canyon",
      id: "cit_gcn_us",
      iata_country_code: "US",
      iata_code: "GCN",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Guthrie-Edmond Regional Airport",
    longitude: -97.417137,
    latitude: 35.850131,
    id: "arp_gok_us",
    icao_code: "KGOK",
    iata_country_code: "US",
    iata_code: "GOK",
    city_name: "Guthrie",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Guymon Municipal Airport",
    longitude: -101.507649,
    latitude: 36.684789,
    id: "arp_guy_us",
    icao_code: "KGUY",
    iata_country_code: "US",
    iata_code: "GUY",
    city_name: "Guymon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Guyuan Liupanshan Airport",
    longitude: 106.221354,
    latitude: 36.077725,
    id: "arp_gyu_cn",
    icao_code: "ZLGY",
    iata_country_code: "CN",
    iata_code: "GYU",
    city_name: "Guyuan",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Gwa Airport",
    longitude: 94.583285,
    latitude: 17.602052,
    id: "arp_gwa_mm",
    icao_code: "VYGW",
    iata_country_code: "MM",
    iata_code: "GWA",
    city_name: "Gwa",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Gwadar International Airport",
    longitude: 62.329206,
    latitude: 25.232423,
    id: "arp_gwd_pk",
    icao_code: "OPGD",
    iata_country_code: "PK",
    iata_code: "GWD",
    city_name: "Gwadar",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Gwalior Airport",
    longitude: 78.224734,
    latitude: 26.293897,
    id: "arp_gwl_in",
    icao_code: "VIGR",
    iata_country_code: "IN",
    iata_code: "GWL",
    city_name: "Gwalior",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Gwangju Airport",
    longitude: 126.811266,
    latitude: 35.139737,
    id: "arp_kwj_kr",
    icao_code: "RKJJ",
    iata_country_code: "KR",
    iata_code: "KWJ",
    city_name: "Gwangju",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Gwinnett County Airport",
    longitude: -83.962479,
    latitude: 33.976787,
    id: "arp_lzu_us",
    icao_code: "KLZU",
    iata_country_code: "US",
    iata_code: "LZU",
    city_name: "Lawrenceville",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Gympie Airport",
    longitude: 152.701969,
    latitude: -26.282789,
    id: "arp_gyp_au",
    icao_code: "YGYM",
    iata_country_code: "AU",
    iata_code: "GYP",
    city_name: "Gympie",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Habi Airport",
    longitude: 142.488989,
    latitude: -6.320181,
    id: "arp_hbd_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HBD",
    city_name: "Habi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hachijojima Airport",
    longitude: 139.783779,
    latitude: 33.115647,
    id: "arp_hac_jp",
    icao_code: "RJTH",
    iata_country_code: "JP",
    iata_code: "HAC",
    city_name: "Hachijojima",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hachinohe Airport",
    longitude: 141.466212,
    latitude: 40.551648,
    id: "arp_hhe_jp",
    icao_code: "RJSH",
    iata_country_code: "JP",
    iata_code: "HHE",
    city_name: "Hachinohe",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Haelogo Airport",
    longitude: 147.598967,
    latitude: -9.13704,
    id: "arp_heo_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HEO",
    city_name: "Haelogo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hagerstown Regional Airport",
    longitude: -77.730094,
    latitude: 39.70728,
    id: "arp_hgr_us",
    icao_code: "KHGR",
    iata_country_code: "US",
    iata_code: "HGR",
    city_name: "Hagerstown",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Hagfors Airport",
    longitude: 13.579476,
    latitude: 60.020187,
    id: "arp_hfs_se",
    icao_code: "ESOH",
    iata_country_code: "SE",
    iata_code: "HFS",
    city_name: "Hagfors",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Haibei Qilian Airport",
    longitude: 100.644,
    latitude: 38.012,
    id: "arp_hbq_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "HBQ",
    city_name: "Qilian",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Haifa Airport",
    longitude: 35.042642,
    latitude: 32.810431,
    id: "arp_hfa_il",
    icao_code: "LLHA",
    iata_country_code: "IL",
    iata_code: "HFA",
    city_name: "Haifa",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Haikou Meilan International Airport",
    longitude: 110.460565,
    latitude: 19.93672,
    id: "arp_hak_cn",
    icao_code: "ZJHK",
    iata_country_code: "CN",
    iata_code: "HAK",
    city_name: "Haikou",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Ha'il Regional Airport",
    longitude: 41.687499,
    latitude: 27.438444,
    id: "arp_has_sa",
    icao_code: "OEHL",
    iata_country_code: "SA",
    iata_code: "HAS",
    city_name: "Ha'il",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Haines Airport",
    longitude: -135.526436,
    latitude: 59.245473,
    id: "arp_hns_us",
    icao_code: "PAHN",
    iata_country_code: "US",
    iata_code: "HNS",
    city_name: "Haines",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Haines Junction Airport",
    longitude: -137.54255,
    latitude: 60.789215,
    id: "arp_yht_ca",
    icao_code: "CYHT",
    iata_country_code: "CA",
    iata_code: "YHT",
    city_name: "Haines Junction",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Haivaro Airport",
    longitude: 143.059704,
    latitude: -6.940022,
    id: "arp_hit_pg",
    icao_code: "AYHO",
    iata_country_code: "PG",
    iata_code: "HIT",
    city_name: "Haivaro",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Haji Aroeppala Airport",
    longitude: 120.437762,
    latitude: -6.176762,
    id: "arp_ksr_id",
    icao_code: "WAWH",
    iata_country_code: "ID",
    iata_code: "KSR",
    city_name: "Selayar",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Hakai Pass Airport",
    longitude: -128.117,
    latitude: 51.7333,
    id: "arp_yhc_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YHC",
    city_name: "Hakai Passage",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Hakkari Yüksekova Airport",
    longitude: 44.2381,
    latitude: 37.5497,
    id: "arp_yko_tr",
    icao_code: "LTCW",
    iata_country_code: "TR",
    iata_code: "YKO",
    city_name: "Hakkari",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hakodate Airport",
    longitude: 140.818769,
    latitude: 41.772831,
    id: "arp_hkd_jp",
    icao_code: "RJCH",
    iata_country_code: "JP",
    iata_code: "HKD",
    city_name: "Hakodate",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Halali Airport",
    longitude: 16.458172,
    latitude: -19.029217,
    id: "arp_hal_na",
    icao_code: "FYHI",
    iata_country_code: "NA",
    iata_code: "HAL",
    city_name: "Halali",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hale County Airport",
    longitude: -101.716594,
    latitude: 34.168179,
    id: "arp_pvw_us",
    icao_code: "KPVW",
    iata_country_code: "US",
    iata_code: "PVW",
    city_name: "Plainview",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Half Moon Bay Airport",
    longitude: -122.498395,
    latitude: 37.513361,
    id: "arp_haf_us",
    icao_code: "KHAF",
    iata_country_code: "US",
    iata_code: "HAF",
    city_name: "Half Moon Bay",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Halifax County Airport",
    longitude: -77.709369,
    latitude: 36.439471,
    id: "arp_rzz_us",
    icao_code: "KRZZ",
    iata_country_code: "US",
    iata_code: "RZZ",
    city_name: "Roanoke Rapids",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Halifax Stanfield International Airport",
    longitude: -63.509022,
    latitude: 44.883372,
    id: "arp_yhz_ca",
    icao_code: "CYHZ",
    iata_country_code: "CA",
    iata_code: "YHZ",
    city_name: "Halifax",
    city: {
      name: "Halifax",
      id: "cit_yhz_ca",
      iata_country_code: "CA",
      iata_code: "YHZ",
    },
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Halim Perdanakusuma International Airport",
    longitude: 106.889411,
    latitude: -6.26627,
    id: "arp_hlp_id",
    icao_code: "WIHH",
    iata_country_code: "ID",
    iata_code: "HLP",
    city_name: "Jakarta",
    city: {
      name: "Jakarta",
      id: "cit_jkt_id",
      iata_country_code: "ID",
      iata_code: "JKT",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "Haliwen Airport",
    longitude: 124.906032,
    latitude: -9.074709,
    id: "arp_abu_id",
    icao_code: "WATA",
    iata_country_code: "ID",
    iata_code: "ABU",
    city_name: "Atambua",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Hall Beach Airport",
    longitude: -81.238424,
    latitude: 68.774253,
    id: "arp_yux_ca",
    icao_code: "CYUX",
    iata_country_code: "CA",
    iata_code: "YUX",
    city_name: "Hall Beach",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Halli Airport",
    longitude: 24.786716,
    latitude: 61.855998,
    id: "arp_kev_fi",
    icao_code: "EFHA",
    iata_country_code: "FI",
    iata_code: "KEV",
    city_name: "Halli",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Halliburton Field",
    longitude: -97.959949,
    latitude: 34.471561,
    id: "arp_duc_us",
    icao_code: "KDUC",
    iata_country_code: "US",
    iata_code: "DUC",
    city_name: "Duncan",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Halls Creek Airport",
    longitude: 127.668684,
    latitude: -18.234016,
    id: "arp_hcq_au",
    icao_code: "YHLC",
    iata_country_code: "AU",
    iata_code: "HCQ",
    city_name: "Halls Creek",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Halmstad Airport",
    longitude: 12.815128,
    latitude: 56.681352,
    id: "arp_had_se",
    icao_code: "ESMT",
    iata_country_code: "SE",
    iata_code: "HAD",
    city_name: "Halmstad",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Hamadan Airport",
    longitude: 48.538286,
    latitude: 34.867546,
    id: "arp_hdm_ir",
    icao_code: "OIHH",
    iata_country_code: "IR",
    iata_code: "HDM",
    city_name: "Hamadan",
    city: null,
  },
  {
    time_zone: "Asia/Qatar",
    name: "Hamad International Airport",
    longitude: 51.615118,
    latitude: 25.260642,
    id: "arp_doh_qa",
    icao_code: "OTHH",
    iata_country_code: "QA",
    iata_code: "DOH",
    city_name: "Doha",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Hamburg Airport",
    longitude: 9.992821,
    latitude: 53.632011,
    id: "arp_ham_de",
    icao_code: "EDDH",
    iata_country_code: "DE",
    iata_code: "HAM",
    city_name: "Hamburg",
    city: {
      name: "Hamburg",
      id: "cit_ham_de",
      iata_country_code: "DE",
      iata_code: "HAM",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Hamburg-Finkenwerder Airport",
    longitude: 9.834702,
    latitude: 53.534195,
    id: "arp_xfw_de",
    icao_code: "EDHI",
    iata_country_code: "DE",
    iata_code: "XFW",
    city_name: "Hamburg",
    city: {
      name: "Hamburg",
      id: "cit_ham_de",
      iata_country_code: "DE",
      iata_code: "HAM",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hami Airport",
    longitude: 93.669197,
    latitude: 42.8414,
    id: "arp_hmi_cn",
    icao_code: "ZWHM",
    iata_country_code: "CN",
    iata_code: "HMI",
    city_name: "Hami",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Hamid Karzai International Airport",
    longitude: 69.211798,
    latitude: 34.564627,
    id: "arp_kbl_af",
    icao_code: "OAKB",
    iata_country_code: "AF",
    iata_code: "KBL",
    city_name: "Kabul",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Hamilton Airport",
    longitude: 142.063318,
    latitude: -37.648476,
    id: "arp_hlt_au",
    icao_code: "YHML",
    iata_country_code: "AU",
    iata_code: "HLT",
    city_name: "Hamilton",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Hamilton Airport",
    longitude: 175.333756,
    latitude: -37.866156,
    id: "arp_hlz_nz",
    icao_code: "NZHN",
    iata_country_code: "NZ",
    iata_code: "HLZ",
    city_name: "Hamilton",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hamilton Field Airport",
    longitude: -122.522,
    latitude: 38.0153,
    id: "arp_srf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SRF",
    city_name: "San Rafael",
    city: null,
  },
  {
    time_zone: "Australia/Lindeman",
    name: "Hamilton Island Airport",
    longitude: 148.950374,
    latitude: -20.354892,
    id: "arp_hti_au",
    icao_code: "YBHM",
    iata_country_code: "AU",
    iata_code: "HTI",
    city_name: "Hamilton Island",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Hammerfest Airport",
    longitude: 23.668605,
    latitude: 70.679712,
    id: "arp_hft_no",
    icao_code: "ENHF",
    iata_country_code: "NO",
    iata_code: "HFT",
    city_name: "Hammerfest",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hampton Municipal Airport",
    longitude: -92.460172,
    latitude: 33.521909,
    id: "arp_hpt_us",
    icao_code: "KHPT",
    iata_country_code: "US",
    iata_code: "HPT",
    city_name: "Hampton",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Hana Airport",
    longitude: -156.014328,
    latitude: 20.795376,
    id: "arp_hnm_us",
    icao_code: "PHHN",
    iata_country_code: "US",
    iata_code: "HNM",
    city_name: "Hana",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hanamaki Airport",
    longitude: 141.134041,
    latitude: 39.428184,
    id: "arp_hna_jp",
    icao_code: "RJSI",
    iata_country_code: "JP",
    iata_code: "HNA",
    city_name: "Hanamaki",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hancock County-Bar Harbor Airport",
    longitude: -68.361794,
    latitude: 44.447523,
    id: "arp_bhb_us",
    icao_code: "KBHB",
    iata_country_code: "US",
    iata_code: "BHB",
    city_name: "Bar Harbor",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Handan Airport",
    longitude: 114.425948,
    latitude: 36.524779,
    id: "arp_hdg_cn",
    icao_code: "ZBHD",
    iata_country_code: "CN",
    iata_code: "HDG",
    city_name: "Handan",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Haneda Airport",
    longitude: 139.779919,
    latitude: 35.550846,
    id: "arp_hnd_jp",
    icao_code: "RJTT",
    iata_country_code: "JP",
    iata_code: "HND",
    city_name: "Tokyo",
    city: {
      name: "Tokyo",
      id: "cit_tyo_jp",
      iata_country_code: "JP",
      iata_code: "TYO",
    },
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Hang Nadim International Airport",
    longitude: 104.11865,
    latitude: 1.121478,
    id: "arp_bth_id",
    icao_code: "WIDD",
    iata_country_code: "ID",
    iata_code: "BTH",
    city_name: "Batam",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hangzhou Xiaoshan International Airport",
    longitude: 120.436444,
    latitude: 30.232724,
    id: "arp_hgh_cn",
    icao_code: "ZSHC",
    iata_country_code: "CN",
    iata_code: "HGH",
    city_name: "Hangzhou",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Hanimaadhoo International Airport",
    longitude: 73.170504,
    latitude: 6.74676,
    id: "arp_haq_mv",
    icao_code: "VRMH",
    iata_country_code: "MV",
    iata_code: "HAQ",
    city_name: "Haa Dhaalu Atoll",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Hanksville Airport",
    longitude: -110.702048,
    latitude: 38.417339,
    id: "arp_hve_us",
    icao_code: "KHVE",
    iata_country_code: "US",
    iata_code: "HVE",
    city_name: "Hanksville",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Hannover Airport",
    longitude: 9.687045,
    latitude: 52.461492,
    id: "arp_haj_de",
    icao_code: "EDDV",
    iata_country_code: "DE",
    iata_code: "HAJ",
    city_name: "Hannover",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hanzhong Chenggu Airport",
    longitude: 107.206014,
    latitude: 33.134136,
    id: "arp_hzg_cn",
    icao_code: "ZLHZ",
    iata_country_code: "CN",
    iata_code: "HZG",
    city_name: "Hanzhong",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Hao Airport",
    longitude: -140.956034,
    latitude: -18.06516,
    id: "arp_hoi_pf",
    icao_code: "NTTO",
    iata_country_code: "PF",
    iata_code: "HOI",
    city_name: "Hao",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Harare International Airport",
    longitude: 31.096968,
    latitude: -17.919182,
    id: "arp_hre_zw",
    icao_code: "FVRG",
    iata_country_code: "ZW",
    iata_code: "HRE",
    city_name: "Harare",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Harbin Taiping International Airport",
    longitude: 126.24669,
    latitude: 45.622921,
    id: "arp_hrb_cn",
    icao_code: "ZYHB",
    iata_country_code: "CN",
    iata_code: "HRB",
    city_name: "Harbin",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Hargeisa Airport",
    longitude: 44.08576,
    latitude: 9.516722,
    id: "arp_hga_so",
    icao_code: "HCMH",
    iata_country_code: "SO",
    iata_code: "HGA",
    city_name: "Hargeisa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Harrell Field",
    longitude: -92.763366,
    latitude: 33.622787,
    id: "arp_cdh_us",
    icao_code: "KCDH",
    iata_country_code: "US",
    iata_code: "CDH",
    city_name: "Camden",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Harriet Alexander Field",
    longitude: -106.049639,
    latitude: 38.537169,
    id: "arp_slt_us",
    icao_code: "KANK",
    iata_country_code: "US",
    iata_code: "SLT",
    city_name: "Salida",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Harrisburg International Airport",
    longitude: -76.760532,
    latitude: 40.193869,
    id: "arp_mdt_us",
    icao_code: "KMDT",
    iata_country_code: "US",
    iata_code: "MDT",
    city_name: "Harrisburg",
    city: {
      name: "Harrisburg",
      id: "cit_har_us",
      iata_country_code: "US",
      iata_code: "HAR",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Harrisburg-Raleigh Airport",
    longitude: -88.549186,
    latitude: 37.81155,
    id: "arp_hsb_us",
    icao_code: "KHSB",
    iata_country_code: "US",
    iata_code: "HSB",
    city_name: "Harrisburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Harris County Airport",
    longitude: -84.880283,
    latitude: 32.841252,
    id: "arp_pim_us",
    icao_code: "KPIM",
    iata_country_code: "US",
    iata_code: "PIM",
    city_name: "Pine Mountain",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Harrismith Airport",
    longitude: 29.105981,
    latitude: -28.234858,
    id: "arp_hrs_za",
    icao_code: "FAHR",
    iata_country_code: "ZA",
    iata_code: "HRS",
    city_name: "Harrismith",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Harrison County Airport",
    longitude: -94.308184,
    latitude: 32.519054,
    id: "arp_asl_us",
    icao_code: "KASL",
    iata_country_code: "US",
    iata_code: "ASL",
    city_name: "Marshall",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Harry Clever Field",
    longitude: -81.418762,
    latitude: 40.470889,
    id: "arp_phd_us",
    icao_code: "KPHD",
    iata_country_code: "US",
    iata_code: "PHD",
    city_name: "New Philadelphia",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Harry Mwanga Nkumbula International Airport",
    longitude: 25.821338,
    latitude: -17.820829,
    id: "arp_lvi_zm",
    icao_code: "FLHN",
    iata_country_code: "ZM",
    iata_code: "LVI",
    city_name: "Livingstone",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Harry P. Williams Memorial Airport",
    longitude: -91.337296,
    latitude: 29.710632,
    id: "arp_ptn_us",
    icao_code: "KPTN",
    iata_country_code: "US",
    iata_code: "PTN",
    city_name: "Patterson",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Harry Stern Airport",
    longitude: -96.60989,
    latitude: 46.251869,
    id: "arp_wah_us",
    icao_code: "KBWP",
    iata_country_code: "US",
    iata_code: "WAH",
    city_name: "Wahpeton",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Harstad/Narvik Airport, Evenes",
    longitude: 16.679554,
    latitude: 68.490577,
    id: "arp_eve_no",
    icao_code: "ENEV",
    iata_country_code: "NO",
    iata_code: "EVE",
    city_name: "Harstad",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hartford-Brainard Airport",
    longitude: -72.649209,
    latitude: 41.736889,
    id: "arp_hfd_us",
    icao_code: "KHFD",
    iata_country_code: "US",
    iata_code: "HFD",
    city_name: "Hartford",
    city: {
      name: "Hartford",
      id: "cit_hfd_us",
      iata_country_code: "US",
      iata_code: "HFD",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Hartley Bay Airport",
    longitude: -129.251698,
    latitude: 53.423799,
    id: "arp_ytb_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YTB",
    city_name: "Hartley Bay",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hartness State Airport",
    longitude: -72.518129,
    latitude: 43.343061,
    id: "arp_vsf_us",
    icao_code: "KVSF",
    iata_country_code: "US",
    iata_code: "VSF",
    city_name: "Springfield",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hartsfield-Jackson Atlanta International Airport",
    longitude: -84.4279,
    latitude: 33.638714,
    id: "arp_atl_us",
    icao_code: "KATL",
    iata_country_code: "US",
    iata_code: "ATL",
    city_name: "Atlanta",
    city: {
      name: "Atlanta",
      id: "cit_atl_us",
      iata_country_code: "US",
      iata_code: "ATL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Hartsville Regional Airport",
    longitude: -80.117933,
    latitude: 34.404435,
    id: "arp_hvs_us",
    icao_code: "KHVS",
    iata_country_code: "US",
    iata_code: "HVS",
    city_name: "Hartsville",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "H.A.S. Hanandjoeddin Airport",
    longitude: 107.754384,
    latitude: -2.748472,
    id: "arp_tjq_id",
    icao_code: "WIKT",
    iata_country_code: "ID",
    iata_code: "TJQ",
    city_name: "Tanjung Pandan",
    city: null,
  },
  {
    time_zone: "Africa/El_Aaiun",
    name: "Hassan I Airport",
    longitude: -13.220633,
    latitude: 27.150514,
    id: "arp_eun_ma",
    icao_code: "GMML",
    iata_country_code: "MA",
    iata_code: "EUN",
    city_name: "Laayoune",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Hassi Messaoud Airport",
    longitude: 6.140498,
    latitude: 31.673445,
    id: "arp_hme_dz",
    icao_code: "DAUH",
    iata_country_code: "DZ",
    iata_code: "HME",
    city_name: "Hassi Messaoud",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Hassi R'Mel Airport",
    longitude: 3.301306,
    latitude: 32.932916,
    id: "arp_hrm_dz",
    icao_code: "DAFH",
    iata_country_code: "DZ",
    iata_code: "HRM",
    city_name: "Hassi R'mel",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Hastings Airport",
    longitude: -13.129099,
    latitude: 8.39713,
    id: "arp_hgs_sl",
    icao_code: "GFHA",
    iata_country_code: "SL",
    iata_code: "HGS",
    city_name: "Freetown",
    city: {
      name: "Freetown",
      id: "cit_fna_sl",
      iata_country_code: "SL",
      iata_code: "FNA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Hastings Municipal Airport",
    longitude: -98.427371,
    latitude: 40.603523,
    id: "arp_hsi_us",
    icao_code: "KHSI",
    iata_country_code: "US",
    iata_code: "HSI",
    city_name: "Hastings",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Hasvik Airport",
    longitude: 22.13971,
    latitude: 70.486684,
    id: "arp_haa_no",
    icao_code: "ENHK",
    iata_country_code: "NO",
    iata_code: "HAA",
    city_name: "Hasvik",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Hatay Airport",
    longitude: 36.282618,
    latitude: 36.363131,
    id: "arp_hty_tr",
    icao_code: "LTDA",
    iata_country_code: "TR",
    iata_code: "HTY",
    city_name: "Hatay",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Hatchet Lake Airport",
    longitude: -103.538766,
    latitude: 58.66224,
    id: "arp_ydj_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YDJ",
    city_name: "Hatchet Lake",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hateruma Airport",
    longitude: 123.805296,
    latitude: 24.059601,
    id: "arp_htr_jp",
    icao_code: "RORH",
    iata_country_code: "JP",
    iata_code: "HTR",
    city_name: "Hateruma",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Hato Corozal Airport",
    longitude: -71.760982,
    latitude: 6.154403,
    id: "arp_htz_co",
    icao_code: "SKHC",
    iata_country_code: "CO",
    iata_code: "HTZ",
    city_name: "Hato Corozal",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hattiesburg Bobby L Chain Municipal Airport",
    longitude: -89.252881,
    latitude: 31.266653,
    id: "arp_hbg_us",
    icao_code: "KHBG",
    iata_country_code: "US",
    iata_code: "HBG",
    city_name: "Hattiesburg",
    city: {
      name: "Hattiesburg/Laurel",
      id: "cit_lul_us",
      iata_country_code: "US",
      iata_code: "LUL",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Hattiesburg-Laurel Regional Airport",
    longitude: -89.336538,
    latitude: 31.467479,
    id: "arp_pib_us",
    icao_code: "KPIB",
    iata_country_code: "US",
    iata_code: "PIB",
    city_name: "Hattiesburg/Laurel",
    city: {
      name: "Hattiesburg/Laurel",
      id: "cit_lul_us",
      iata_country_code: "US",
      iata_code: "LUL",
    },
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Hat Yai International Airport",
    longitude: 100.393399,
    latitude: 6.934788,
    id: "arp_hdy_th",
    icao_code: "VTSS",
    iata_country_code: "TH",
    iata_code: "HDY",
    city_name: "Hat Yai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Hatzfeldhaven Airport",
    longitude: 145.216212,
    latitude: -4.406977,
    id: "arp_haz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HAZ",
    city_name: "Hatzfeldthaven",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Haugesund Airport, Karmøy",
    longitude: 5.212219,
    latitude: 59.344807,
    id: "arp_hau_no",
    icao_code: "ENHD",
    iata_country_code: "NO",
    iata_code: "HAU",
    city_name: "Haugesund",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Havadarya Airport",
    longitude: 56.17261,
    latitude: 27.158978,
    id: "arp_hdr_ir",
    icao_code: "OIKP",
    iata_country_code: "IR",
    iata_code: "HDR",
    city_name: "Havadarya",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Haverfordwest Airport",
    longitude: -4.96274,
    latitude: 51.832779,
    id: "arp_haw_gb",
    icao_code: "EGFE",
    iata_country_code: "GB",
    iata_code: "HAW",
    city_name: "Haverfordwest",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Havre City-County Airport",
    longitude: -109.761199,
    latitude: 48.543125,
    id: "arp_hvr_us",
    icao_code: "KHVR",
    iata_country_code: "US",
    iata_code: "HVR",
    city_name: "Havre",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Havre St Pierre Airport",
    longitude: -63.60736,
    latitude: 50.282072,
    id: "arp_ygv_ca",
    icao_code: "CYGV",
    iata_country_code: "CA",
    iata_code: "YGV",
    city_name: "Havre-St-Pierre",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Hawabango Airport",
    longitude: 146.003487,
    latitude: -7.392994,
    id: "arp_hwa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HWA",
    city_name: "Hawabango",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Hawarden Airport",
    longitude: -2.97654,
    latitude: 53.179943,
    id: "arp_ceg_gb",
    icao_code: "EGNR",
    iata_country_code: "GB",
    iata_code: "CEG",
    city_name: "Chester",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Hawke's Bay Airport",
    longitude: 176.87116,
    latitude: -39.465848,
    id: "arp_npe_nz",
    icao_code: "NZNR",
    iata_country_code: "NZ",
    iata_code: "NPE",
    city_name: "Napier",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Hawk Inlet Seaplane Base Airport",
    longitude: -134.583469,
    latitude: 58.357547,
    id: "arp_hwi_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HWI",
    city_name: "Hawk Inlet",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hawkins Field",
    longitude: -90.222183,
    latitude: 32.334478,
    id: "arp_hks_us",
    icao_code: "KHKS",
    iata_country_code: "US",
    iata_code: "HKS",
    city_name: "Jackson",
    city: {
      name: "Jackson",
      id: "cit_jan_us",
      iata_country_code: "US",
      iata_code: "JAN",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hawthorne Industrial Airport",
    longitude: -118.633573,
    latitude: 38.544338,
    id: "arp_hth_us",
    icao_code: "KHTH",
    iata_country_code: "US",
    iata_code: "HTH",
    city_name: "Hawthorne",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hawthorne Municipal Airport",
    longitude: -118.334497,
    latitude: 33.922766,
    id: "arp_hhr_us",
    icao_code: "KHHR",
    iata_country_code: "US",
    iata_code: "HHR",
    city_name: "Hawthorne",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Hay Airport",
    longitude: 144.83229,
    latitude: -34.531447,
    id: "arp_hxx_au",
    icao_code: "YHAY",
    iata_country_code: "AU",
    iata_code: "HXX",
    city_name: "Hay",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Hayfields Airport",
    longitude: 143.058374,
    latitude: -3.699038,
    id: "arp_hyf_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HYF",
    city_name: "Hayfield",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Hay River Merlyn Carter Airport",
    longitude: -115.782537,
    latitude: 60.839486,
    id: "arp_yhy_ca",
    icao_code: "CYHY",
    iata_country_code: "CA",
    iata_code: "YHY",
    city_name: "Hay River",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hays Regional Airport",
    longitude: -99.274247,
    latitude: 38.853132,
    id: "arp_hys_us",
    icao_code: "KHYS",
    iata_country_code: "US",
    iata_code: "HYS",
    city_name: "Hays",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hayward Executive Airport",
    longitude: -122.121893,
    latitude: 37.660042,
    id: "arp_hwd_us",
    icao_code: "KHWD",
    iata_country_code: "US",
    iata_code: "HWD",
    city_name: "Hayward",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hazleton Municipal Airport",
    longitude: -75.994128,
    latitude: 40.987291,
    id: "arp_hzl_us",
    icao_code: "KHZL",
    iata_country_code: "US",
    iata_code: "HZL",
    city_name: "Hazleton",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Hazyview Airport",
    longitude: 31.131954,
    latitude: -25.050304,
    id: "arp_hzv_za",
    icao_code: "FAHW",
    iata_country_code: "ZA",
    iata_code: "HZV",
    city_name: "Hazyview",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Headingly Airport",
    longitude: 138.296954,
    latitude: -21.313935,
    id: "arp_hip_au",
    icao_code: "YHDY",
    iata_country_code: "AU",
    iata_code: "HIP",
    city_name: "Headingly",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Healy Lake Airport",
    longitude: -145.729141,
    latitude: 64.050961,
    id: "arp_hkb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HKB",
    city_name: "Healy Lake",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Hearst René Fontaine Municipal Airport",
    longitude: -83.689646,
    latitude: 49.711633,
    id: "arp_yhf_ca",
    icao_code: "CYHF",
    iata_country_code: "CA",
    iata_code: "YHF",
    city_name: "Hearst",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Heathlands Airport",
    longitude: 142.57736,
    latitude: -11.736921,
    id: "arp_hat_au",
    icao_code: "YHTL",
    iata_country_code: "AU",
    iata_code: "HAT",
    city_name: "Heathlands",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Heathrow Airport",
    longitude: -0.458118,
    latitude: 51.470311,
    id: "arp_lhr_gb",
    icao_code: "EGLL",
    iata_country_code: "GB",
    iata_code: "LHR",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hechi Jinchengjiang Airport",
    longitude: 107.6997,
    latitude: 24.805,
    id: "arp_hcj_cn",
    icao_code: "ZGHC",
    iata_country_code: "CN",
    iata_code: "HCJ",
    city_name: "Hechi",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hector International Airport",
    longitude: -96.818821,
    latitude: 46.920915,
    id: "arp_far_us",
    icao_code: "KFAR",
    iata_country_code: "US",
    iata_code: "FAR",
    city_name: "Fargo",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hefei Xinqiao International Airport",
    longitude: 116.964114,
    latitude: 31.990191,
    id: "arp_hfe_cn",
    icao_code: "ZSOF",
    iata_country_code: "CN",
    iata_code: "HFE",
    city_name: "Hefei",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Heho Airport",
    longitude: 96.792588,
    latitude: 20.745157,
    id: "arp_heh_mm",
    icao_code: "VYHH",
    iata_country_code: "MM",
    iata_code: "HEH",
    city_name: "Heho",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Heide-Büsum Airport",
    longitude: 8.898358,
    latitude: 54.153445,
    id: "arp_hei_de",
    icao_code: "EDXB",
    iata_country_code: "DE",
    iata_code: "HEI",
    city_name: "Büsum",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Heidelberg Airport",
    longitude: 8.6525,
    latitude: 49.393333,
    id: "arp_hdb_de",
    icao_code: null,
    iata_country_code: "DE",
    iata_code: "HDB",
    city_name: "Heidelberg",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Heihe Airport",
    longitude: 127.311224,
    latitude: 50.175153,
    id: "arp_hek_cn",
    icao_code: "ZYHE",
    iata_country_code: "CN",
    iata_code: "HEK",
    city_name: "Heihe",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Heiweni Airport",
    longitude: 146.467,
    latitude: -7.4,
    id: "arp_hni_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HNI",
    city_name: "Heiweni",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Helena Regional Airport",
    longitude: -111.986534,
    latitude: 46.608355,
    id: "arp_hln_us",
    icao_code: "KHLN",
    iata_country_code: "US",
    iata_code: "HLN",
    city_name: "Helena",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Helenvale Airport",
    longitude: 145.216834,
    latitude: -15.685977,
    id: "arp_hlv_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "HLV",
    city_name: "Helenvale",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Heligoland Airport",
    longitude: 7.91545,
    latitude: 54.186171,
    id: "arp_hgl_de",
    icao_code: "EDXH",
    iata_country_code: "DE",
    iata_code: "HGL",
    city_name: "Helgoland",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Helmuth Baungartem Airport",
    longitude: -49.542638,
    latitude: -27.15986,
    id: "arp_loi_br",
    icao_code: "SSLN",
    iata_country_code: "BR",
    iata_code: "LOI",
    city_name: "Lontras",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Helsinki Airport",
    longitude: 24.95808,
    latitude: 60.31912,
    id: "arp_hel_fi",
    icao_code: "EFHK",
    iata_country_code: "FI",
    iata_code: "HEL",
    city_name: "Helsinki",
    city: {
      name: "Helsinki",
      id: "cit_hel_fi",
      iata_country_code: "FI",
      iata_code: "HEL",
    },
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Helsinki-Malmi Airport",
    longitude: 25.042857,
    latitude: 60.253238,
    id: "arp_hem_fi",
    icao_code: "EFHF",
    iata_country_code: "FI",
    iata_code: "HEM",
    city_name: "Helsinki",
    city: {
      name: "Helsinki",
      id: "cit_hel_fi",
      iata_country_code: "FI",
      iata_code: "HEL",
    },
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Hemavan Tärnaby Airport",
    longitude: 15.082828,
    latitude: 65.806251,
    id: "arp_hmv_se",
    icao_code: "ESUT",
    iata_country_code: "SE",
    iata_code: "HMV",
    city_name: "Tarnaby/Hemavan",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hemet-Ryan Airport",
    longitude: -117.021665,
    latitude: 33.73196,
    id: "arp_hmt_us",
    icao_code: "KHMT",
    iata_country_code: "US",
    iata_code: "HMT",
    city_name: "Hemet",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Henbury Airport",
    longitude: 133.236064,
    latitude: -24.583955,
    id: "arp_hry_au",
    icao_code: "YHBY",
    iata_country_code: "AU",
    iata_code: "HRY",
    city_name: "Henbury",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Henderson Executive Airport",
    longitude: -115.136403,
    latitude: 35.974547,
    id: "arp_hsh_us",
    icao_code: "KHND",
    iata_country_code: "US",
    iata_code: "HSH",
    city_name: "Las Vegas",
    city: {
      name: "Las Vegas",
      id: "cit_las_us",
      iata_country_code: "US",
      iata_code: "LAS",
    },
  },
  {
    time_zone: "Pacific/Midway",
    name: "Henderson Field",
    longitude: -177.380996,
    latitude: 28.2017,
    id: "arp_mdy_um",
    icao_code: "PMDY",
    iata_country_code: "UM",
    iata_code: "MDY",
    city_name: "Sand Island",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Hendrik Van Eck Airport",
    longitude: 31.155199,
    latitude: -23.936516,
    id: "arp_phw_za",
    icao_code: "FAPH",
    iata_country_code: "ZA",
    iata_code: "PHW",
    city_name: "Phalaborwa",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Hengchun Airport",
    longitude: 120.731519,
    latitude: 22.040364,
    id: "arp_hcn_tw",
    icao_code: "RCKW",
    iata_country_code: "TW",
    iata_code: "HCN",
    city_name: "Hengchun",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hengyang Nanyue Airport",
    longitude: 112.627998,
    latitude: 26.9053,
    id: "arp_hny_cn",
    icao_code: "ZGHY",
    iata_country_code: "CN",
    iata_code: "HNY",
    city_name: "Hengyang",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Henri Coandă International Airport",
    longitude: 26.084706,
    latitude: 44.57092,
    id: "arp_otp_ro",
    icao_code: "LROP",
    iata_country_code: "RO",
    iata_code: "OTP",
    city_name: "Bucharest",
    city: {
      name: "Bucharest",
      id: "cit_buh_ro",
      iata_country_code: "RO",
      iata_code: "BUH",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Henry County Airport",
    longitude: -88.383071,
    latitude: 36.337806,
    id: "arp_pht_us",
    icao_code: "KPHT",
    iata_country_code: "US",
    iata_code: "PHT",
    city_name: "Paris",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Henry Post Army Airfield",
    longitude: -98.402073,
    latitude: 34.649901,
    id: "arp_fsi_us",
    icao_code: "KFSI",
    iata_country_code: "US",
    iata_code: "FSI",
    city_name: "Lawton/Fort Sill",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Henry Tift Myers Airport",
    longitude: -83.488941,
    latitude: 31.428684,
    id: "arp_tma_us",
    icao_code: "KTMA",
    iata_country_code: "US",
    iata_code: "TMA",
    city_name: "Tifton",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Heraklion International Airport",
    longitude: 25.178449,
    latitude: 35.339659,
    id: "arp_her_gr",
    icao_code: "LGIR",
    iata_country_code: "GR",
    iata_code: "HER",
    city_name: "Heraklion",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Herat International Airport",
    longitude: 62.227137,
    latitude: 34.211487,
    id: "arp_hea_af",
    icao_code: "OAHR",
    iata_country_code: "AF",
    iata_code: "HEA",
    city_name: "Herat",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Hercílio Luz International Airport",
    longitude: -48.549233,
    latitude: -27.670198,
    id: "arp_fln_br",
    icao_code: "SBFL",
    iata_country_code: "BR",
    iata_code: "FLN",
    city_name: "Florianópolis",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Herendeen Bay Airport",
    longitude: -160.899128,
    latitude: 55.801397,
    id: "arp_hed_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HED",
    city_name: "Herendeen Bay",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Heriberto Gíl Martínez Airport",
    longitude: -76.224397,
    latitude: 4.092285,
    id: "arp_ulq_co",
    icao_code: "SKUL",
    iata_country_code: "CO",
    iata_code: "ULQ",
    city_name: "Tulua",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Heringsdorf Airport",
    longitude: 14.155208,
    latitude: 53.878145,
    id: "arp_hdf_de",
    icao_code: "EDAH",
    iata_country_code: "DE",
    iata_code: "HDF",
    city_name: "Heringsdorf",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Heritage Field",
    longitude: -75.557125,
    latitude: 40.239726,
    id: "arp_ptw_us",
    icao_code: "KPTW",
    iata_country_code: "US",
    iata_code: "PTW",
    city_name: "Pottstown",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Hermannsburg Airport",
    longitude: 132.805744,
    latitude: -23.929247,
    id: "arp_hmg_au",
    icao_code: "YHMB",
    iata_country_code: "AU",
    iata_code: "HMG",
    city_name: "Hermannsburg",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Hermanos Ameijeiras Airport",
    longitude: -76.937448,
    latitude: 20.986493,
    id: "arp_vtu_cu",
    icao_code: "MUVT",
    iata_country_code: "CU",
    iata_code: "VTU",
    city_name: "Las Tunas",
    city: null,
  },
  {
    time_zone: "America/Argentina/Ushuaia",
    name: "Hermes Quijada International Airport",
    longitude: -67.750709,
    latitude: -53.778961,
    id: "arp_rga_ar",
    icao_code: "SAWE",
    iata_country_code: "AR",
    iata_code: "RGA",
    city_name: "Rio Grande",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hermiston Municipal Airport",
    longitude: -119.259094,
    latitude: 45.828279,
    id: "arp_hes_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HES",
    city_name: "Hermiston",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "Hermosillo International Airport",
    longitude: -111.049943,
    latitude: 29.092978,
    id: "arp_hmo_mx",
    icao_code: "MMHO",
    iata_country_code: "MX",
    iata_code: "HMO",
    city_name: "Hermosillo",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Herrera Airport",
    longitude: -75.590582,
    latitude: 6.220549,
    id: "arp_hrr_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "HRR",
    city_name: "La Herrera",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Hervey Bay Airport",
    longitude: 152.884182,
    latitude: -25.320187,
    id: "arp_hvb_au",
    icao_code: "YHBA",
    iata_country_code: "AU",
    iata_code: "HVB",
    city_name: "Hervey Bay",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Hesa Airport",
    longitude: 51.561289,
    latitude: 32.928805,
    id: "arp_ifh_ir",
    icao_code: "OIFE",
    iata_country_code: "IR",
    iata_code: "IFH",
    city_name: "Hesa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hesler-Noble Field",
    longitude: -89.170845,
    latitude: 31.66797,
    id: "arp_lul_us",
    icao_code: "KLUL",
    iata_country_code: "US",
    iata_code: "LUL",
    city_name: "Laurel",
    city: {
      name: "Hattiesburg/Laurel",
      id: "cit_lul_us",
      iata_country_code: "US",
      iata_code: "LUL",
    },
  },
  {
    time_zone: "Europe/Budapest",
    name: "Hévíz–Balaton Airport",
    longitude: 17.158816,
    latitude: 46.685693,
    id: "arp_sob_hu",
    icao_code: "LHSM",
    iata_country_code: "HU",
    iata_code: "SOB",
    city_name: "Hévíz",
    city: null,
  },
  {
    time_zone: "America/St_Lucia",
    name: "Hewanorra International Airport",
    longitude: -60.95202,
    latitude: 13.734072,
    id: "arp_uvf_lc",
    icao_code: "TLPL",
    iata_country_code: "LC",
    iata_code: "UVF",
    city_name: "St Lucia",
    city: {
      name: "St Lucia",
      id: "cit_slu_lc",
      iata_country_code: "LC",
      iata_code: "SLU",
    },
  },
  {
    time_zone: "Asia/Baku",
    name: "Heydar Aliyev International Airport",
    longitude: 50.049476,
    latitude: 40.466321,
    id: "arp_gyd_az",
    icao_code: "UBBB",
    iata_country_code: "AZ",
    iata_code: "GYD",
    city_name: "Baku",
    city: {
      name: "Baku",
      id: "cit_bak_az",
      iata_country_code: "AZ",
      iata_code: "BAK",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "H. Hasan Aroeboesman Airport",
    longitude: 121.662185,
    latitude: -8.849023,
    id: "arp_ene_id",
    icao_code: "WATE",
    iata_country_code: "ID",
    iata_code: "ENE",
    city_name: "Ende",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "H. H. Coffield Regional Airport",
    longitude: -96.989622,
    latitude: 30.631547,
    id: "arp_rck_us",
    icao_code: "KRCK",
    iata_country_code: "US",
    iata_code: "RCK",
    city_name: "Rockdale",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hickory Regional Airport",
    longitude: -81.3895,
    latitude: 35.741694,
    id: "arp_hky_us",
    icao_code: "KHKY",
    iata_country_code: "US",
    iata_code: "HKY",
    city_name: "Hickory",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Highbury Airport",
    longitude: 143.144675,
    latitude: -16.423824,
    id: "arp_hig_au",
    icao_code: "YHHY",
    iata_country_code: "AU",
    iata_code: "HIG",
    city_name: "Highbury",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "High Level Airport",
    longitude: -117.165159,
    latitude: 58.619851,
    id: "arp_yoj_ca",
    icao_code: "CYOJ",
    iata_country_code: "CA",
    iata_code: "YOJ",
    city_name: "High Level",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "High Prairie Airport",
    longitude: -116.473695,
    latitude: 55.393558,
    id: "arp_zhp_ca",
    icao_code: "CZHP",
    iata_country_code: "CA",
    iata_code: "ZHP",
    city_name: "High Prairie",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Higuerote Airport",
    longitude: -66.09267,
    latitude: 10.462927,
    id: "arp_hge_ve",
    icao_code: "SVHG",
    iata_country_code: "VE",
    iata_code: "HGE",
    city_name: "Higuerote",
    city: null,
  },
  {
    time_zone: "Pacific/Wallis",
    name: "Hihifo Airport",
    longitude: -176.198966,
    latitude: -13.238311,
    id: "arp_wls_wf",
    icao_code: "NLWW",
    iata_country_code: "WF",
    iata_code: "WLS",
    city_name: "Wallis Island",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Hikueru Airport",
    longitude: -142.613644,
    latitude: -17.545906,
    id: "arp_hhz_pf",
    icao_code: "NTGH",
    iata_country_code: "PF",
    iata_code: "HHZ",
    city_name: "Hikueru",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Hill Air Force Base",
    longitude: -111.973086,
    latitude: 41.12403,
    id: "arp_hif_us",
    icao_code: "KHIF",
    iata_country_code: "US",
    iata_code: "HIF",
    city_name: "Ogden",
    city: {
      name: "Ogden",
      id: "cit_ogd_us",
      iata_country_code: "US",
      iata_code: "OGD",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Hill City Municipal Airport",
    longitude: -99.833135,
    latitude: 39.37626,
    id: "arp_hlc_us",
    icao_code: "KHLC",
    iata_country_code: "US",
    iata_code: "HLC",
    city_name: "Hill City",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Hillenbrand Industries Airport",
    longitude: -85.258327,
    latitude: 39.344057,
    id: "arp_hlb_us",
    icao_code: "KHLB",
    iata_country_code: "US",
    iata_code: "HLB",
    city_name: "Batesville",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Hillside Airport",
    longitude: 119.442759,
    latitude: -21.717798,
    id: "arp_hll_au",
    icao_code: "YHIL",
    iata_country_code: "AU",
    iata_code: "HLL",
    city_name: "Hillside",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Hilo International Airport",
    longitude: -155.044845,
    latitude: 19.720116,
    id: "arp_ito_us",
    icao_code: "PHTO",
    iata_country_code: "US",
    iata_code: "ITO",
    city_name: "Hilo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hilton Head Airport",
    longitude: -80.697986,
    latitude: 32.225392,
    id: "arp_hhh_us",
    icao_code: "KHXD",
    iata_country_code: "US",
    iata_code: "HHH",
    city_name: "Hilton Head Island",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Hingurakgoda Airport",
    longitude: 80.981087,
    latitude: 8.050113,
    id: "arp_him_lk",
    icao_code: "VCCH",
    iata_country_code: "LK",
    iata_code: "HIM",
    city_name: "Hingurakgoda",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hinkles Ferry Airport",
    longitude: -95.580201,
    latitude: 28.982294,
    id: "arp_bzt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BZT",
    city_name: "Brazoria",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Hinthada Airport",
    longitude: 95.466698,
    latitude: 17.633329,
    id: "arp_heb_mm",
    icao_code: null,
    iata_country_code: "MM",
    iata_code: "HEB",
    city_name: "Hinthada",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Hinton/Jasper-Hinton Airport",
    longitude: -117.752998,
    latitude: 53.319198,
    id: "arp_yjp_ca",
    icao_code: "CEC4",
    iata_country_code: "CA",
    iata_code: "YJP",
    city_name: "Hinton",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hiroshima Airport",
    longitude: 132.919356,
    latitude: 34.437175,
    id: "arp_hij_jp",
    icao_code: "RJOA",
    iata_country_code: "JP",
    iata_code: "HIJ",
    city_name: "Hiroshima",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Hiroshimanishi Airport",
    longitude: 132.413963,
    latitude: 34.366915,
    id: "arp_hiw_jp",
    icao_code: "RJBH",
    iata_country_code: "JP",
    iata_code: "HIW",
    city_name: "Hiroshima",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Hisar Airport",
    longitude: 75.753992,
    latitude: 29.178495,
    id: "arp_hss_in",
    icao_code: "VIHR",
    iata_country_code: "IN",
    iata_code: "HSS",
    city_name: "Hisar",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Hluhluwe Airport",
    longitude: 32.2755,
    latitude: -28.015662,
    id: "arp_hlw_za",
    icao_code: "FAHL",
    iata_country_code: "ZA",
    iata_code: "HLW",
    city_name: "Hluhluwe",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Hobart International Airport",
    longitude: 147.508727,
    latitude: -42.836227,
    id: "arp_hba_au",
    icao_code: "YMHB",
    iata_country_code: "AU",
    iata_code: "HBA",
    city_name: "Hobart",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hobart Regional Airport",
    longitude: -99.051351,
    latitude: 34.991332,
    id: "arp_hbr_us",
    icao_code: "KHBR",
    iata_country_code: "US",
    iata_code: "HBR",
    city_name: "Hobart",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Hodeidah International Airport",
    longitude: 42.973676,
    latitude: 14.754066,
    id: "arp_hod_ye",
    icao_code: "OYHD",
    iata_country_code: "YE",
    iata_code: "HOD",
    city_name: "Hodeida",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Hoedspruit Airport",
    longitude: 31.047459,
    latitude: -24.369047,
    id: "arp_hds_za",
    icao_code: "FAHS",
    iata_country_code: "ZA",
    iata_code: "HDS",
    city_name: "Hoedspruit",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Hof-Plauen Airport",
    longitude: 11.861363,
    latitude: 50.287513,
    id: "arp_hoq_de",
    icao_code: "EDQM",
    iata_country_code: "DE",
    iata_code: "HOQ",
    city_name: "Hof",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Hog River Airport",
    longitude: -155.669164,
    latitude: 66.216105,
    id: "arp_hgz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HGZ",
    city_name: "Hogatza",
    city: null,
  },
  {
    time_zone: "Europe/Vienna",
    name: "Hohenems-Dornbirn Airport",
    longitude: 9.7,
    latitude: 47.384443,
    id: "arp_hoh_at",
    icao_code: "LOIH",
    iata_country_code: "AT",
    iata_code: "HOH",
    city_name: "Dornbirn/Hohenems",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hohhot Baita International Airport",
    longitude: 111.820924,
    latitude: 40.853246,
    id: "arp_het_cn",
    icao_code: "ZBHH",
    iata_country_code: "CN",
    iata_code: "HET",
    city_name: "Hohhot",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Hokitika Airport",
    longitude: 170.985015,
    latitude: -42.713798,
    id: "arp_hkk_nz",
    icao_code: "NZHK",
    iata_country_code: "NZ",
    iata_code: "HKK",
    city_name: "Hokitika",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Hola Airport",
    longitude: 40.004001,
    latitude: -1.521999,
    id: "arp_hoa_ke",
    icao_code: "HKHO",
    iata_country_code: "KE",
    iata_code: "HOA",
    city_name: "Hola",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Holbrook Municipal Airport",
    longitude: -110.139578,
    latitude: 34.937495,
    id: "arp_hbk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HBK",
    city_name: "Holbrook",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Holešov Airport",
    longitude: 17.57379,
    latitude: 49.313453,
    id: "arp_gtw_cz",
    icao_code: "LKHO",
    iata_country_code: "CZ",
    iata_code: "GTW",
    city_name: "Holešov",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Holingol Huolinhe Airport",
    longitude: 119.407221,
    latitude: 45.487221,
    id: "arp_huo_cn",
    icao_code: "ZBHZ",
    iata_country_code: "CN",
    iata_code: "HUO",
    city_name: "Holingol",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Hollis Clark Bay Seaplane Base",
    longitude: -132.646061,
    latitude: 55.481593,
    id: "arp_hyl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HYL",
    city_name: "Hollis",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hollister Municipal Airport",
    longitude: -121.409118,
    latitude: 36.892187,
    id: "arp_hli_us",
    icao_code: "KCVH",
    iata_country_code: "US",
    iata_code: "HLI",
    city_name: "Hollister",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Holloman Air Force Base",
    longitude: -106.107002,
    latitude: 32.852501,
    id: "arp_hmn_us",
    icao_code: "KHMN",
    iata_country_code: "US",
    iata_code: "HMN",
    city_name: "Alamogordo",
    city: {
      name: "Alamogordo",
      id: "cit_alm_us",
      iata_country_code: "US",
      iata_code: "ALM",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Hollywood Burbank Airport",
    longitude: -118.358202,
    latitude: 34.199505,
    id: "arp_bur_us",
    icao_code: "KBUR",
    iata_country_code: "US",
    iata_code: "BUR",
    city_name: "Burbank",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Hólmavík Airport",
    longitude: -21.690493,
    latitude: 65.703412,
    id: "arp_hvk_is",
    icao_code: "BIHK",
    iata_country_code: "IS",
    iata_code: "HVK",
    city_name: "Hólmavík",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Holt Airport",
    longitude: -23.441727,
    latitude: 66.014206,
    id: "arp_fli_is",
    icao_code: null,
    iata_country_code: "IS",
    iata_code: "FLI",
    city_name: "Flateyri",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Holy Cross Airport",
    longitude: -159.775168,
    latitude: 62.188189,
    id: "arp_hcr_us",
    icao_code: "PAHC",
    iata_country_code: "US",
    iata_code: "HCR",
    city_name: "Holy Cross",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Homalin Airport",
    longitude: 94.914001,
    latitude: 24.899599,
    id: "arp_hox_mm",
    icao_code: "VYHL",
    iata_country_code: "MM",
    iata_code: "HOX",
    city_name: "Homalin",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Homer Airport",
    longitude: -151.485008,
    latitude: 59.644397,
    id: "arp_hom_us",
    icao_code: "PAHO",
    iata_country_code: "US",
    iata_code: "HOM",
    city_name: "Homer",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Homestead Air Reserve Base Airport",
    longitude: -80.383598,
    latitude: 25.4886,
    id: "arp_hst_us",
    icao_code: "KHST",
    iata_country_code: "US",
    iata_code: "HST",
    city_name: "Homestead",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Hon Airport",
    longitude: 15.965471,
    latitude: 29.110242,
    id: "arp_huq_ly",
    icao_code: "HLON",
    iata_country_code: "LY",
    iata_code: "HUQ",
    city_name: "Hon",
    city: null,
  },
  {
    time_zone: "Asia/Hong_Kong",
    name: "Hong Kong International Airport",
    longitude: 113.91732,
    latitude: 22.308331,
    id: "arp_hkg_hk",
    icao_code: "VHHH",
    iata_country_code: "HK",
    iata_code: "HKG",
    city_name: "Hong Kong",
    city: {
      name: "Hong Kong",
      id: "cit_hkg_hk",
      iata_country_code: "HK",
      iata_code: "HKG",
    },
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Honiara International Airport",
    longitude: 160.052207,
    latitude: -9.427895,
    id: "arp_hir_sb",
    icao_code: "AGGH",
    iata_country_code: "SB",
    iata_code: "HIR",
    city_name: "Honiara",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Honinabi Airport",
    longitude: 142.176474,
    latitude: -6.244476,
    id: "arp_hnn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HNN",
    city_name: "Honinabi",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Honningsvåg Airport",
    longitude: 25.984168,
    latitude: 71.00962,
    id: "arp_hvg_no",
    icao_code: "ENHV",
    iata_country_code: "NO",
    iata_code: "HVG",
    city_name: "Honningsvåg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hood Army Airfield",
    longitude: -97.71315,
    latitude: 31.138137,
    id: "arp_hlr_us",
    icao_code: "KHLR",
    iata_country_code: "US",
    iata_code: "HLR",
    city_name: "Fort Hood/Killeen",
    city: {
      name: "Killeen/Fort Hood",
      id: "cit_ile_us",
      iata_country_code: "US",
      iata_code: "ILE",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Hooker County Airport",
    longitude: -101.058941,
    latitude: 42.042091,
    id: "arp_mhn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MHN",
    city_name: "Mullen",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Hooker Creek Airport",
    longitude: 130.637345,
    latitude: -18.336044,
    id: "arp_hok_au",
    icao_code: "YHOO",
    iata_country_code: "AU",
    iata_code: "HOK",
    city_name: "Lajamanu",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Hoonah Airport",
    longitude: -135.410718,
    latitude: 58.096915,
    id: "arp_hnh_us",
    icao_code: "PAOH",
    iata_country_code: "US",
    iata_code: "HNH",
    city_name: "Hoonah",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Hooper Bay Airport",
    longitude: -166.146063,
    latitude: 61.523541,
    id: "arp_hpb_us",
    icao_code: "PAHP",
    iata_country_code: "US",
    iata_code: "HPB",
    city_name: "Hooper Bay",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Hope Airport",
    longitude: -121.496405,
    latitude: 49.368932,
    id: "arp_yhe_ca",
    icao_code: "CYHE",
    iata_country_code: "CA",
    iata_code: "YHE",
    city_name: "Hope",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Hope Bay Airport",
    longitude: -106.616083,
    latitude: 68.160082,
    id: "arp_uzm_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "UZM",
    city_name: "Hope Bay",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Hopedale Airport",
    longitude: -60.228704,
    latitude: 55.44838,
    id: "arp_yho_ca",
    icao_code: "CYHO",
    iata_country_code: "CA",
    iata_code: "YHO",
    city_name: "Hopedale",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Hopetoun Airport",
    longitude: 142.358425,
    latitude: -35.715778,
    id: "arp_htu_au",
    icao_code: "YHPN",
    iata_country_code: "AU",
    iata_code: "HTU",
    city_name: "Hopetoun",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Hope Vale Airport",
    longitude: 145.184933,
    latitude: -15.444335,
    id: "arp_hpe_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "HPE",
    city_name: "Hopevale",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Horizontina Airport",
    longitude: -54.337394,
    latitude: -27.640425,
    id: "arp_hrz_br",
    icao_code: "SSHZ",
    iata_country_code: "BR",
    iata_code: "HRZ",
    city_name: "Horizontina",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Hornafjörður Airport",
    longitude: -15.227738,
    latitude: 64.298944,
    id: "arp_hfn_is",
    icao_code: "BIHN",
    iata_country_code: "IS",
    iata_code: "HFN",
    city_name: "Hornafjordur",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Hornepayne Municipal Airport",
    longitude: -84.761712,
    latitude: 49.190988,
    id: "arp_yhn_ca",
    icao_code: "CYHN",
    iata_country_code: "CA",
    iata_code: "YHN",
    city_name: "Hornepayne",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Horn Island Airport",
    longitude: 142.292245,
    latitude: -10.588313,
    id: "arp_hid_au",
    icao_code: "YHID",
    iata_country_code: "AU",
    iata_code: "HID",
    city_name: "Horn Island",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Horsham Airport",
    longitude: 142.171836,
    latitude: -36.66985,
    id: "arp_hsm_au",
    icao_code: "YHSM",
    iata_country_code: "AU",
    iata_code: "HSM",
    city_name: "Horsham",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Horta Airport",
    longitude: -28.716144,
    latitude: 38.51995,
    id: "arp_hor_pt",
    icao_code: "LPHR",
    iata_country_code: "PT",
    iata_code: "HOR",
    city_name: "Horta",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Hosea Kutako International Airport",
    longitude: 17.470878,
    latitude: -22.479933,
    id: "arp_wdh_na",
    icao_code: "FYWH",
    iata_country_code: "NA",
    iata_code: "WDH",
    city_name: "Windhoek",
    city: {
      name: "Windhoek",
      id: "cit_wdh_na",
      iata_country_code: "NA",
      iata_code: "WDH",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Hoskins Airport",
    longitude: 150.404999,
    latitude: -5.461919,
    id: "arp_hkn_pg",
    icao_code: "AYHK",
    iata_country_code: "PG",
    iata_code: "HKN",
    city_name: "Hoskins",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hotan Airport",
    longitude: 79.868089,
    latitude: 37.038961,
    id: "arp_htn_cn",
    icao_code: "ZWTN",
    iata_country_code: "CN",
    iata_code: "HTN",
    city_name: "Hotan",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Hot Springs County Airport",
    longitude: -108.38165,
    latitude: 43.715433,
    id: "arp_thp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "THP",
    city_name: "Thermopolis",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Houari Boumediene Airport",
    longitude: 3.206193,
    latitude: 36.697131,
    id: "arp_alg_dz",
    icao_code: "DAAG",
    iata_country_code: "DZ",
    iata_code: "ALG",
    city_name: "Algiers",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Houghton County Memorial Airport",
    longitude: -88.489934,
    latitude: 47.168354,
    id: "arp_cmx_us",
    icao_code: "KCMX",
    iata_country_code: "US",
    iata_code: "CMX",
    city_name: "Hancock",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Houlton International Airport",
    longitude: -67.791247,
    latitude: 46.122932,
    id: "arp_hul_us",
    icao_code: "KHUL",
    iata_country_code: "US",
    iata_code: "HUL",
    city_name: "Houlton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Houma-Terrebonne Airport",
    longitude: -90.659523,
    latitude: 29.566981,
    id: "arp_hum_us",
    icao_code: "KHUM",
    iata_country_code: "US",
    iata_code: "HUM",
    city_name: "Houma",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Hoy Island Airport",
    longitude: -3.299979,
    latitude: 58.833288,
    id: "arp_hoy_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "HOY",
    city_name: "Hoy",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Hpa-An Airport",
    longitude: 97.674485,
    latitude: 16.892528,
    id: "arp_paa_mm",
    icao_code: "VYPA",
    iata_country_code: "MM",
    iata_code: "PAA",
    city_name: "Hpa-an",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Hpapun Airport",
    longitude: 97.436114,
    latitude: 18.044459,
    id: "arp_ppu_mm",
    icao_code: "VYPP",
    iata_country_code: "MM",
    iata_code: "PPU",
    city_name: "Papun",
    city: null,
  },
  {
    time_zone: "Europe/Minsk",
    name: "Hrodna Airport",
    longitude: 24.051006,
    latitude: 53.602788,
    id: "arp_gna_by",
    icao_code: "UMMG",
    iata_country_code: "BY",
    iata_code: "GNA",
    city_name: "Grodno",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Hsinchu Air Base",
    longitude: 120.939003,
    latitude: 24.818001,
    id: "arp_hsz_tw",
    icao_code: "RCPO",
    iata_country_code: "TW",
    iata_code: "HSZ",
    city_name: "Hsinchu",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Huacaraje Airport",
    longitude: -63.880956,
    latitude: -13.602573,
    id: "arp_bvk_bo",
    icao_code: "SLHJ",
    iata_country_code: "BO",
    iata_code: "BVK",
    city_name: "Huacaraje",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Hua Hin Airport",
    longitude: 99.951523,
    latitude: 12.631843,
    id: "arp_hhq_th",
    icao_code: "VTPH",
    iata_country_code: "TH",
    iata_code: "HHQ",
    city_name: "Hua Hin",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Huahine – Fare Airport",
    longitude: -151.024099,
    latitude: -16.687617,
    id: "arp_huh_pf",
    icao_code: "NTTH",
    iata_country_code: "PF",
    iata_code: "HUH",
    city_name: "Huahine",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Huai'an Lianshui Airport",
    longitude: 119.128317,
    latitude: 33.789806,
    id: "arp_hia_cn",
    icao_code: "ZSSH",
    iata_country_code: "CN",
    iata_code: "HIA",
    city_name: "Huai'an",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Huaihua Zhijiang Airport",
    longitude: 109.699841,
    latitude: 27.441256,
    id: "arp_hjj_cn",
    icao_code: "ZGCJ",
    iata_country_code: "CN",
    iata_code: "HJJ",
    city_name: "Huaihua",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Hualien Airport",
    longitude: 121.617238,
    latitude: 24.024708,
    id: "arp_hun_tw",
    icao_code: "RCYU",
    iata_country_code: "TW",
    iata_code: "HUN",
    city_name: "Hualien",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Huallaga Airport",
    longitude: -76.582714,
    latitude: -7.060624,
    id: "arp_blp_pe",
    icao_code: "SPBL",
    iata_country_code: "PE",
    iata_code: "BLP",
    city_name: "Bellavista",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Huangshan Tunxi International Airport",
    longitude: 118.257272,
    latitude: 29.730864,
    id: "arp_txn_cn",
    icao_code: "ZSTX",
    iata_country_code: "CN",
    iata_code: "TXN",
    city_name: "Huangshan",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Huanuco Airpor",
    longitude: -76.205172,
    latitude: -9.878617,
    id: "arp_huu_pe",
    icao_code: "SPNC",
    iata_country_code: "PE",
    iata_code: "HUU",
    city_name: "Huánuco",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Huatugou Airport",
    longitude: 90.841495,
    latitude: 38.201984,
    id: "arp_htt_cn",
    icao_code: "ZLHX",
    iata_country_code: "CN",
    iata_code: "HTT",
    city_name: "Huatugou",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Hubli Airport",
    longitude: 75.085214,
    latitude: 15.360164,
    id: "arp_hbx_in",
    icao_code: "VOHB",
    iata_country_code: "IN",
    iata_code: "HBX",
    city_name: "Hubli",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Hudiksvall Airport",
    longitude: 17.081931,
    latitude: 61.7679,
    id: "arp_huv_se",
    icao_code: "ESNH",
    iata_country_code: "SE",
    iata_code: "HUV",
    city_name: "Hudiksvall",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Hudson Bay Airport",
    longitude: -102.308585,
    latitude: 52.816915,
    id: "arp_yhb_ca",
    icao_code: "CYHB",
    iata_country_code: "CA",
    iata_code: "YHB",
    city_name: "Hudson Bay",
    city: null,
  },
  {
    time_zone: "America/Dawson_Creek",
    name: "Hudson's Hope Airport",
    longitude: -121.976831,
    latitude: 56.035592,
    id: "arp_ynh_ca",
    icao_code: "CYNH",
    iata_country_code: "CA",
    iata_code: "YNH",
    city_name: "Hudson's Hope",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Huehuetenango Airport",
    longitude: -91.505987,
    latitude: 15.316495,
    id: "arp_hug_gt",
    icao_code: "MGHT",
    iata_country_code: "GT",
    iata_code: "HUG",
    city_name: "Huehuetenango",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Huesca–Pirineos Airport",
    longitude: -0.319596,
    latitude: 42.078944,
    id: "arp_hsk_es",
    icao_code: "LEHC",
    iata_country_code: "ES",
    iata_code: "HSK",
    city_name: "Huesca",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Hughenden Airport",
    longitude: 144.226058,
    latitude: -20.815802,
    id: "arp_hgd_au",
    icao_code: "YHUG",
    iata_country_code: "AU",
    iata_code: "HGD",
    city_name: "Hughenden",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Hughes Airport",
    longitude: -154.263085,
    latitude: 66.041013,
    id: "arp_hus_us",
    icao_code: "PAHU",
    iata_country_code: "US",
    iata_code: "HUS",
    city_name: "Hughes",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Huizhou Pingtan Airport",
    longitude: 114.600278,
    latitude: 23.048333,
    id: "arp_huz_cn",
    icao_code: "ZGHZ",
    iata_country_code: "CN",
    iata_code: "HUZ",
    city_name: "Huizhou",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Hukuntsi Airport",
    longitude: 21.758108,
    latitude: -23.989487,
    id: "arp_huk_bw",
    icao_code: null,
    iata_country_code: "BW",
    iata_code: "HUK",
    city_name: "Hukuntsi",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Hultsfred Airport",
    longitude: 15.828241,
    latitude: 57.523579,
    id: "arp_hlf_se",
    icao_code: "ESSF",
    iata_country_code: "SE",
    iata_code: "HLF",
    city_name: "Vimmerby/Hultsfred",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Hulunbuir Hailar Airport",
    longitude: 119.824685,
    latitude: 49.207774,
    id: "arp_hld_cn",
    icao_code: "ZBLA",
    iata_country_code: "CN",
    iata_code: "HLD",
    city_name: "Hailar",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Humacao Airport",
    longitude: -65.800446,
    latitude: 18.13831,
    id: "arp_huc_pr",
    icao_code: null,
    iata_country_code: "PR",
    iata_code: "HUC",
    city_name: "Humacao",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Humaitá Airport",
    longitude: -63.071578,
    latitude: -7.534667,
    id: "arp_huw_br",
    icao_code: "SWHT",
    iata_country_code: "BR",
    iata_code: "HUW",
    city_name: "Humaita",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Humberside Airport",
    longitude: -0.35001,
    latitude: 53.577422,
    id: "arp_huy_gb",
    icao_code: "EGNJ",
    iata_country_code: "GB",
    iata_code: "HUY",
    city_name: "Humberside",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Humbert River Airport",
    longitude: 130.630349,
    latitude: -16.489279,
    id: "arp_hub_au",
    icao_code: "YHBR",
    iata_country_code: "AU",
    iata_code: "HUB",
    city_name: "Humbert River",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Humboldt Municipal Airport",
    longitude: -94.245535,
    latitude: 42.735552,
    id: "arp_hud_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HUD",
    city_name: "Humboldt",
    city: null,
  },
  {
    time_zone: null,
    name: "Humen Airport",
    longitude: null,
    latitude: null,
    id: "arp_zti_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "ZTI",
    city_name: null,
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Humera Airport",
    longitude: 36.58311,
    latitude: 14.25,
    id: "arp_hue_et",
    icao_code: "HAHU",
    iata_country_code: "ET",
    iata_code: "HUE",
    city_name: "Humera",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Hunter Army Airfield",
    longitude: -81.146065,
    latitude: 32.010541,
    id: "arp_svn_us",
    icao_code: "KSVN",
    iata_country_code: "US",
    iata_code: "SVN",
    city_name: "Savannah",
    city: {
      name: "Savannah",
      id: "cit_sav_us",
      iata_country_code: "US",
      iata_code: "SAV",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Hunt Field",
    longitude: -108.729887,
    latitude: 42.815095,
    id: "arp_lnd_us",
    icao_code: "KLND",
    iata_country_code: "US",
    iata_code: "LND",
    city_name: "Lander",
    city: null,
  },
  {
    time_zone: "America/Indiana/Vincennes",
    name: "Huntingburg Airport",
    longitude: -86.954299,
    latitude: 38.249379,
    id: "arp_hnb_us",
    icao_code: "KHNB",
    iata_country_code: "US",
    iata_code: "HNB",
    city_name: "Huntingburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Huntsville International Airport",
    longitude: -86.775394,
    latitude: 34.638766,
    id: "arp_hsv_us",
    icao_code: "KHSV",
    iata_country_code: "US",
    iata_code: "HSV",
    city_name: "Huntsville",
    city: {
      name: "Huntsville",
      id: "cit_hsv_us",
      iata_country_code: "US",
      iata_code: "HSV",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Huntsville Regional Airport",
    longitude: -95.587204,
    latitude: 30.746915,
    id: "arp_htv_us",
    icao_code: "KUTS",
    iata_country_code: "US",
    iata_code: "HTV",
    city_name: "Huntsville",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Hurghada International Airport",
    longitude: 33.802122,
    latitude: 27.179031,
    id: "arp_hrg_eg",
    icao_code: "HEGN",
    iata_country_code: "EG",
    iata_code: "HRG",
    city_name: "Hurghada",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Huron Regional Airport",
    longitude: -98.226361,
    latitude: 44.385076,
    id: "arp_hon_us",
    icao_code: "KHON",
    iata_country_code: "US",
    iata_code: "HON",
    city_name: "Huron",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Húsavík Airport",
    longitude: -17.422863,
    latitude: 65.954059,
    id: "arp_hzk_is",
    icao_code: "BIHU",
    iata_country_code: "IS",
    iata_code: "HZK",
    city_name: "Husavik",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Husein Sastranegara International Airport",
    longitude: 107.577936,
    latitude: -6.902046,
    id: "arp_bdo_id",
    icao_code: "WICC",
    iata_country_code: "ID",
    iata_code: "BDO",
    city_name: "Bandung",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Huslia Airport",
    longitude: -156.351034,
    latitude: 65.697908,
    id: "arp_hsl_us",
    icao_code: "PAHL",
    iata_country_code: "US",
    iata_code: "HSL",
    city_name: "Huslia",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Husum Schwesing Airport",
    longitude: 9.13289,
    latitude: 54.50827,
    id: "arp_qhu_de",
    icao_code: "EDXJ",
    iata_country_code: "DE",
    iata_code: "QHU",
    city_name: "Husum",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hutchinson County Airport",
    longitude: -101.394067,
    latitude: 35.700433,
    id: "arp_bgd_us",
    icao_code: "KBGD",
    iata_country_code: "US",
    iata_code: "BGD",
    city_name: "Borger",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Hutchinson Municipal Airport",
    longitude: -97.860603,
    latitude: 38.065498,
    id: "arp_hut_us",
    icao_code: "KHUT",
    iata_country_code: "US",
    iata_code: "HUT",
    city_name: "Hutchinson",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Hwange National Park Airport",
    longitude: 26.519111,
    latitude: -18.36352,
    id: "arp_hwn_zw",
    icao_code: "FVWN",
    iata_country_code: "ZW",
    iata_code: "HWN",
    city_name: "Hwange National Park",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Hwange Town Airport",
    longitude: 26.519851,
    latitude: -18.362987,
    id: "arp_wki_zw",
    icao_code: "FVWT",
    iata_country_code: "ZW",
    iata_code: "WKI",
    city_name: "Hwange",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Hydaburg Seaplane Base",
    longitude: -132.828158,
    latitude: 55.206306,
    id: "arp_hyg_us",
    icao_code: "PAHY",
    iata_country_code: "US",
    iata_code: "HYG",
    city_name: "Hydaburg",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Hyderabad Airport",
    longitude: 68.36566,
    latitude: 25.318314,
    id: "arp_hdd_pk",
    icao_code: "OPKD",
    iata_country_code: "PK",
    iata_code: "HDD",
    city_name: "Hyderabad",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Hyder Seaplane Base",
    longitude: -130.008339,
    latitude: 55.903322,
    id: "arp_whd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WHD",
    city_name: "Hyder",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Hyvinkää Airfield",
    longitude: 24.88101,
    latitude: 60.654322,
    id: "arp_hyv_fi",
    icao_code: "EFHV",
    iata_country_code: "FI",
    iata_code: "HYV",
    city_name: "Hyvinkää",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Iamalele Airport",
    longitude: 150.524569,
    latitude: -9.511095,
    id: "arp_ima_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IMA",
    city_name: "Iamalele",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Iași International Airport",
    longitude: 27.62002,
    latitude: 47.177434,
    id: "arp_ias_ro",
    icao_code: "LRIA",
    iata_country_code: "RO",
    iata_code: "IAS",
    city_name: "Iași",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Iaura Airport",
    longitude: 148.1,
    latitude: -9.783333,
    id: "arp_iau_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IAU",
    city_name: "Iaura",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Ibadan Airport",
    longitude: 3.977249,
    latitude: 7.361112,
    id: "arp_iba_ng",
    icao_code: "DNIB",
    iata_country_code: "NG",
    iata_code: "IBA",
    city_name: "Ibadan",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Ibaraki Airport",
    longitude: 140.413522,
    latitude: 36.180351,
    id: "arp_ibr_jp",
    icao_code: "RJAH",
    iata_country_code: "JP",
    iata_code: "IBR",
    city_name: "Omitama",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Iberia Airport",
    longitude: -69.489658,
    latitude: -11.410466,
    id: "arp_ibp_pe",
    icao_code: "SPBR",
    iata_country_code: "PE",
    iata_code: "IBP",
    city_name: "Iberia",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Ibiza Airport",
    longitude: 1.372171,
    latitude: 38.873878,
    id: "arp_ibz_es",
    icao_code: "LEIB",
    iata_country_code: "ES",
    iata_code: "IBZ",
    city_name: "Ibiza",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Ibo Airport",
    longitude: 40.602146,
    latitude: -12.350128,
    id: "arp_ibo_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "IBO",
    city_name: "Ibo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Iboki Airport",
    longitude: 149.18682,
    latitude: -5.549925,
    id: "arp_ibi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IBI",
    city_name: "Iboki",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Icabarú Airport",
    longitude: -61.741131,
    latitude: 4.336029,
    id: "arp_ica_ve",
    icao_code: "SVIC",
    iata_country_code: "VE",
    iata_code: "ICA",
    city_name: "Icabaru",
    city: null,
  },
  {
    time_zone: "Indian/Comoro",
    name: "Iconi Airport",
    longitude: 43.243728,
    latitude: -11.711059,
    id: "arp_yva_km",
    icao_code: "FMCN",
    iata_country_code: "KM",
    iata_code: "YVA",
    city_name: "Moroni",
    city: {
      name: "Moroni",
      id: "cit_yva_km",
      iata_country_code: "KM",
      iata_code: "YVA",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Icy Bay Airport",
    longitude: -141.661848,
    latitude: 59.969013,
    id: "arp_icy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ICY",
    city_name: "Icy Bay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ida Grove Municipal Airport",
    longitude: -95.447202,
    latitude: 42.333443,
    id: "arp_idg_us",
    icao_code: "KIDG",
    iata_country_code: "US",
    iata_code: "IDG",
    city_name: "Ida Grove",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Idaho County Airport",
    longitude: -116.122272,
    latitude: 45.941833,
    id: "arp_idh_us",
    icao_code: "KGIC",
    iata_country_code: "US",
    iata_code: "IDH",
    city_name: "Grangeville",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Idaho Falls Regional Airport",
    longitude: -112.06944,
    latitude: 43.514884,
    id: "arp_ida_us",
    icao_code: "KIDA",
    iata_country_code: "US",
    iata_code: "IDA",
    city_name: "Idaho Falls",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Idiofa Airport",
    longitude: 19.57988,
    latitude: -4.970513,
    id: "arp_idf_cd",
    icao_code: "FZCB",
    iata_country_code: "CD",
    iata_code: "IDF",
    city_name: "Idiofa",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Idre Airport",
    longitude: 12.837086,
    latitude: 61.156298,
    id: "arp_idb_se",
    icao_code: "ESUE",
    iata_country_code: "SE",
    iata_code: "IDB",
    city_name: "Idre",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Iejima Airport",
    longitude: 127.785185,
    latitude: 26.722378,
    id: "arp_iej_jp",
    icao_code: "RORE",
    iata_country_code: "JP",
    iata_code: "IEJ",
    city_name: "Iejima",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Iffley Airport",
    longitude: 141.208999,
    latitude: -18.8694,
    id: "arp_iff_au",
    icao_code: "YIFY",
    iata_country_code: "AU",
    iata_code: "IFF",
    city_name: "Iffley",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Ifuru Airport",
    longitude: 73.025,
    latitude: 5.7083,
    id: "arp_ifu_mv",
    icao_code: "VREI",
    iata_country_code: "MV",
    iata_code: "IFU",
    city_name: "Ifuru",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Igarka Airport",
    longitude: 86.625711,
    latitude: 67.435912,
    id: "arp_iaa_ru",
    icao_code: "UOII",
    iata_country_code: "RU",
    iata_code: "IAA",
    city_name: "Igarka",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Iğdır Airport",
    longitude: 43.877634,
    latitude: 39.977947,
    id: "arp_igd_tr",
    icao_code: "LTCT",
    iata_country_code: "TR",
    iata_code: "IGD",
    city_name: "Iğdır",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Igiugig Airport",
    longitude: -155.902133,
    latitude: 59.323992,
    id: "arp_igg_us",
    icao_code: "PAIG",
    iata_country_code: "US",
    iata_code: "IGG",
    city_name: "Igiugig",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Igloolik Airport",
    longitude: -81.818442,
    latitude: 69.365576,
    id: "arp_ygt_ca",
    icao_code: "CYGT",
    iata_country_code: "CA",
    iata_code: "YGT",
    city_name: "Igloolik",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Ignace Municipal Airport",
    longitude: -91.717788,
    latitude: 49.42971,
    id: "arp_zuc_ca",
    icao_code: "CZUC",
    iata_country_code: "CA",
    iata_code: "ZUC",
    city_name: "Ignace",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Ignacio Agramonte International Airport",
    longitude: -77.847472,
    latitude: 21.420984,
    id: "arp_cmw_cu",
    icao_code: "MUCM",
    iata_country_code: "CU",
    iata_code: "CMW",
    city_name: "Camaguey",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Ignatyevo Airport",
    longitude: 127.410491,
    latitude: 50.424329,
    id: "arp_bqs_ru",
    icao_code: "UHBB",
    iata_country_code: "RU",
    iata_code: "BQS",
    city_name: "Blagoveschensk",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Igor I Sikorsky Memorial Airport",
    longitude: -73.125121,
    latitude: 41.163897,
    id: "arp_bdr_us",
    icao_code: "KBDR",
    iata_country_code: "US",
    iata_code: "BDR",
    city_name: "Bridgeport",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Igrim Airport",
    longitude: 64.439976,
    latitude: 63.19893,
    id: "arp_irm_ru",
    icao_code: "USHI",
    iata_country_code: "RU",
    iata_code: "IRM",
    city_name: "Igrim",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ihosy Airport",
    longitude: 46.16493,
    latitude: -22.404996,
    id: "arp_iho_mg",
    icao_code: "FMSI",
    iata_country_code: "MG",
    iata_code: "IHO",
    city_name: "Ihosy",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ihu Airport",
    longitude: 145.396849,
    latitude: -7.898062,
    id: "arp_ihu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IHU",
    city_name: "Ihu",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Ijuí Airport",
    longitude: -53.846561,
    latitude: -28.371598,
    id: "arp_iju_br",
    icao_code: "SSIJ",
    iata_country_code: "BR",
    iata_code: "IJU",
    city_name: "Ijuí",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Ikaria Island National Airport",
    longitude: 26.346532,
    latitude: 37.682128,
    id: "arp_jik_gr",
    icao_code: "LGIK",
    iata_country_code: "GR",
    iata_code: "JIK",
    city_name: "Ikaria Island",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Ikela Airport",
    longitude: 23.372501,
    latitude: -1.048109,
    id: "arp_ikl_cd",
    icao_code: "FZGV",
    iata_country_code: "CD",
    iata_code: "IKL",
    city_name: "Ikela",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Iki Airport",
    longitude: 129.78447,
    latitude: 33.749588,
    id: "arp_iki_jp",
    icao_code: "RJDB",
    iata_country_code: "JP",
    iata_code: "IKI",
    city_name: "Iki",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Ilam Airport",
    longitude: 46.402084,
    latitude: 33.586553,
    id: "arp_iil_ir",
    icao_code: "OICI",
    iata_country_code: "IR",
    iata_code: "IIL",
    city_name: "Ilam",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Il Caravaggio International Airport",
    longitude: 9.703901,
    latitude: 45.671735,
    id: "arp_bgy_it",
    icao_code: "LIME",
    iata_country_code: "IT",
    iata_code: "BGY",
    city_name: "Bergamo",
    city: {
      name: "Milan",
      id: "cit_mil_it",
      iata_country_code: "IT",
      iata_code: "MIL",
    },
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Île Art - Waala Airport",
    longitude: 163.660621,
    latitude: -19.720435,
    id: "arp_bmy_nc",
    icao_code: "NWWC",
    iata_country_code: "NC",
    iata_code: "BMY",
    city_name: "Waala",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Ilebo Airport",
    longitude: 20.590754,
    latitude: -4.329677,
    id: "arp_pfr_cd",
    icao_code: "FZVS",
    iata_country_code: "CD",
    iata_code: "PFR",
    city_name: "Ilebo",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Île des Pins Airport",
    longitude: 167.453772,
    latitude: -22.588279,
    id: "arp_ilp_nc",
    icao_code: "NWWE",
    iata_country_code: "NC",
    iata_code: "ILP",
    city_name: "Île des Pins",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Île d'Yeu Airport",
    longitude: -2.390236,
    latitude: 46.717997,
    id: "arp_idy_fr",
    icao_code: "LFEY",
    iata_country_code: "FR",
    iata_code: "IDY",
    city_name: "Île d'Yeu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ileg Airport",
    longitude: 145.832947,
    latitude: -5.48391,
    id: "arp_ilx_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ILX",
    city_name: "Ileg",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Îles-de-la-Madeleine Airport",
    longitude: -61.77665,
    latitude: 47.424128,
    id: "arp_ygr_ca",
    icao_code: "CYGR",
    iata_country_code: "CA",
    iata_code: "YGR",
    city_name: "Iles de La Madeleine",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Ilford Airport",
    longitude: -95.614847,
    latitude: 56.051632,
    id: "arp_ilf_ca",
    icao_code: "CZBD",
    iata_country_code: "CA",
    iata_code: "ILF",
    city_name: "Ilford",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Ilhéus/Bahia-Jorge Amado Airport",
    longitude: -39.03264,
    latitude: -14.815741,
    id: "arp_ios_br",
    icao_code: "SBIL",
    iata_country_code: "BR",
    iata_code: "IOS",
    city_name: "Ilhéus",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Iliamna Airport",
    longitude: -154.911546,
    latitude: 59.753968,
    id: "arp_ili_us",
    icao_code: "PAIL",
    iata_country_code: "US",
    iata_code: "ILI",
    city_name: "Iliamna",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Illaga Airport",
    longitude: 137.6225,
    latitude: -3.97648,
    id: "arp_ila_id",
    icao_code: "WABL",
    iata_country_code: "ID",
    iata_code: "ILA",
    city_name: "Illaga",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Illesheim Air Base Airport",
    longitude: 10.385374,
    latitude: 49.476815,
    id: "arp_ilh_de",
    icao_code: "ETIK",
    iata_country_code: "DE",
    iata_code: "ILH",
    city_name: "Illesheim",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Illinois Valley Regional Airport",
    longitude: -89.152044,
    latitude: 41.35077,
    id: "arp_vys_us",
    icao_code: "KVYS",
    iata_country_code: "US",
    iata_code: "VYS",
    city_name: "Peru",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Ilo Airport",
    longitude: -71.343581,
    latitude: -17.695031,
    id: "arp_ilq_pe",
    icao_code: "SPLO",
    iata_country_code: "PE",
    iata_code: "ILQ",
    city_name: "Ilo",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Iloilo International Airport",
    longitude: 122.494308,
    latitude: 10.832512,
    id: "arp_ilo_ph",
    icao_code: "RPVI",
    iata_country_code: "PH",
    iata_code: "ILO",
    city_name: "Iloilo",
    city: null,
  },
  {
    time_zone: "America/El_Salvador",
    name: "Ilopango International Airport",
    longitude: -89.125556,
    latitude: 13.691667,
    id: "arp_ils_sv",
    icao_code: "MSSS",
    iata_country_code: "SV",
    iata_code: "ILS",
    city_name: "San Salvador",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Ilorin International Airport",
    longitude: 4.494328,
    latitude: 8.439087,
    id: "arp_ilr_ng",
    icao_code: "DNIL",
    iata_country_code: "NG",
    iata_code: "ILR",
    city_name: "Ilorin",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Ilu Airport",
    longitude: 138.199695,
    latitude: -3.702477,
    id: "arp_iul_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "IUL",
    city_name: "Ilu",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Ilulissat Airport",
    longitude: -51.056887,
    latitude: 69.243322,
    id: "arp_jav_gl",
    icao_code: "BGJN",
    iata_country_code: "GL",
    iata_code: "JAV",
    city_name: "Ilulissat",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Imam Ali Air Base Airport",
    longitude: 46.089967,
    latitude: 30.935591,
    id: "arp_xnh_iq",
    icao_code: null,
    iata_country_code: "IQ",
    iata_code: "XNH",
    city_name: "Nasiriyah",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Imane Airport",
    longitude: 145.391004,
    latitude: -6.083012,
    id: "arp_imn_pg",
    icao_code: "AYII",
    iata_country_code: "PG",
    iata_code: "IMN",
    city_name: "Imane",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Imbaimadai Airport",
    longitude: -60.2942,
    latitude: 5.708109,
    id: "arp_imb_gy",
    icao_code: "SYIB",
    iata_country_code: "GY",
    iata_code: "IMB",
    city_name: "Imbaimadai",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Immokalee Regional Airport",
    longitude: -81.401647,
    latitude: 26.433073,
    id: "arp_imm_us",
    icao_code: "KIMM",
    iata_country_code: "US",
    iata_code: "IMM",
    city_name: "Immokalee",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Imonda Airport",
    longitude: 141.159371,
    latitude: -3.327874,
    id: "arp_imd_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IMD",
    city_name: "Imonda",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Imperatriz-Prefeito Renato Moreira Airport",
    longitude: -47.458141,
    latitude: -5.531154,
    id: "arp_imp_br",
    icao_code: "SBIZ",
    iata_country_code: "BR",
    iata_code: "IMP",
    city_name: "Imperatriz",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Imperial Beach Naval Outlying Landing Field Airport",
    longitude: -117.115024,
    latitude: 32.566406,
    id: "arp_nrs_us",
    icao_code: "KNRS",
    iata_country_code: "US",
    iata_code: "NRS",
    city_name: "Imperial Beach",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Imperial County Airport",
    longitude: -115.576297,
    latitude: 32.834181,
    id: "arp_ipl_us",
    icao_code: "KIPL",
    iata_country_code: "US",
    iata_code: "IPL",
    city_name: "Imperial",
    city: {
      name: "Imperial/El Centro",
      id: "cit_ipl_us",
      iata_country_code: "US",
      iata_code: "IPL",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Imperial Municipal Airport",
    longitude: -101.622733,
    latitude: 40.510254,
    id: "arp_iml_us",
    icao_code: "KIML",
    iata_country_code: "US",
    iata_code: "IML",
    city_name: "Imperial",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Impfondo Airport",
    longitude: 18.066999,
    latitude: 1.617,
    id: "arp_ion_cg",
    icao_code: "FCOI",
    iata_country_code: "CG",
    iata_code: "ION",
    city_name: "Impfondo",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Imphal Airport",
    longitude: 93.896171,
    latitude: 24.759615,
    id: "arp_imf_in",
    icao_code: "VEIM",
    iata_country_code: "IN",
    iata_code: "IMF",
    city_name: "Imphal",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Inácio Luís do Nascimento Airport",
    longitude: -57.548067,
    latitude: -11.297294,
    id: "arp_jua_br",
    icao_code: "SIZX",
    iata_country_code: "BR",
    iata_code: "JUA",
    city_name: "Juara",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Inagua Airport",
    longitude: -73.675727,
    latitude: 20.974532,
    id: "arp_iga_bs",
    icao_code: "MYIG",
    iata_country_code: "BS",
    iata_code: "IGA",
    city_name: "Matthew Town",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "In Aménas Airport",
    longitude: 9.640449,
    latitude: 28.051981,
    id: "arp_iam_dz",
    icao_code: "DAUZ",
    iata_country_code: "DZ",
    iata_code: "IAM",
    city_name: "In Amenas",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Inanwatan Airport",
    longitude: 132.160518,
    latitude: -2.127776,
    id: "arp_inx_id",
    icao_code: "WASI",
    iata_country_code: "ID",
    iata_code: "INX",
    city_name: "Inanwatan",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Inca Manco Cápac International Airport",
    longitude: -70.158032,
    latitude: -15.467761,
    id: "arp_jul_pe",
    icao_code: "SPJL",
    iata_country_code: "PE",
    iata_code: "JUL",
    city_name: "Juliaca",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Incheon International Airport",
    longitude: 126.440427,
    latitude: 37.460284,
    id: "arp_icn_kr",
    icao_code: "RKSI",
    iata_country_code: "KR",
    iata_code: "ICN",
    city_name: "Seoul",
    city: {
      name: "Seoul",
      id: "cit_sel_kr",
      iata_country_code: "KR",
      iata_code: "SEL",
    },
  },
  {
    time_zone: "Europe/Istanbul",
    name: "İncirlik Air Base",
    longitude: 35.425582,
    latitude: 37.002096,
    id: "arp_uab_tr",
    icao_code: "LTAG",
    iata_country_code: "TR",
    iata_code: "UAB",
    city_name: "Adana",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Indagen Airport",
    longitude: 147.244075,
    latitude: -6.226918,
    id: "arp_idn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IDN",
    city_name: "Indagen",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Independence Airport",
    longitude: -88.441491,
    latitude: 16.534515,
    id: "arp_inb_bz",
    icao_code: "MZSV",
    iata_country_code: "BZ",
    iata_code: "INB",
    city_name: "Independence",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Independence Municipal Airport",
    longitude: -95.776599,
    latitude: 37.157747,
    id: "arp_idp_us",
    icao_code: "KIDP",
    iata_country_code: "US",
    iata_code: "IDP",
    city_name: "Independence",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Indiana County–Jimmy Stewart Airport",
    longitude: -79.102056,
    latitude: 40.632998,
    id: "arp_idi_us",
    icao_code: "KIDI",
    iata_country_code: "US",
    iata_code: "IDI",
    city_name: "Indiana",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Indianapolis International Airport",
    longitude: -86.295526,
    latitude: 39.716605,
    id: "arp_ind_us",
    icao_code: "KIND",
    iata_country_code: "US",
    iata_code: "IND",
    city_name: "Indianapolis",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Indian Mountain LRRS Airport",
    longitude: -153.704142,
    latitude: 65.992796,
    id: "arp_uto_us",
    icao_code: "PAIM",
    iata_country_code: "US",
    iata_code: "UTO",
    city_name: "Utopia Creek",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Indigo Bay Lodge Airport",
    longitude: 35.450567,
    latitude: -21.704972,
    id: "arp_ibl_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "IBL",
    city_name: "Bazaruto Island",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Indira Gandhi International Airport",
    longitude: 77.100193,
    latitude: 28.558498,
    id: "arp_del_in",
    icao_code: "VIDP",
    iata_country_code: "IN",
    iata_code: "DEL",
    city_name: "New Delhi",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Indulkana Airport",
    longitude: 133.322141,
    latitude: -26.965446,
    id: "arp_idk_au",
    icao_code: "YIDK",
    iata_country_code: "AU",
    iata_code: "IDK",
    city_name: "Indulkana",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Industrial Airpark",
    longitude: -103.208671,
    latitude: 32.766285,
    id: "arp_hbb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HBB",
    city_name: "Hobbs",
    city: {
      name: "Hobbs",
      id: "cit_hob_us",
      iata_country_code: "US",
      iata_code: "HOB",
    },
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Ine Airport",
    longitude: 171.616667,
    latitude: 6.983333,
    id: "arp_imi_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "IMI",
    city_name: "Ine Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ingalls Field Airport",
    longitude: -79.834266,
    latitude: 37.95181,
    id: "arp_hsp_us",
    icao_code: "KHSP",
    iata_country_code: "US",
    iata_code: "HSP",
    city_name: "Hot Springs",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Ingeniero Aeronáutico Ambrosio L.V. Taravella International Airport",
    longitude: -64.20948,
    latitude: -31.319104,
    id: "arp_cor_ar",
    icao_code: "SACO",
    iata_country_code: "AR",
    iata_code: "COR",
    city_name: "Cordoba",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Ingeniero Jacobacci Airport",
    longitude: -69.575504,
    latitude: -41.321006,
    id: "arp_igb_ar",
    icao_code: "SAVJ",
    iata_country_code: "AR",
    iata_code: "IGB",
    city_name: "Ingeniero Jacobacci",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Ingham Airport",
    longitude: 146.149768,
    latitude: -18.662301,
    id: "arp_igh_au",
    icao_code: "YIGM",
    iata_country_code: "AU",
    iata_code: "IGH",
    city_name: "Ingham",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Ingolstadt Manching Airport",
    longitude: 11.533963,
    latitude: 48.715651,
    id: "arp_igs_de",
    icao_code: "ETSI",
    iata_country_code: "DE",
    iata_code: "IGS",
    city_name: "Ingolstadt",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "In Guezzam Airport",
    longitude: 5.750535,
    latitude: 19.565257,
    id: "arp_inf_dz",
    icao_code: "DATG",
    iata_country_code: "DZ",
    iata_code: "INF",
    city_name: "In Guezzam",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Inhaca Airport",
    longitude: 32.929351,
    latitude: -25.997144,
    id: "arp_ihc_mz",
    icao_code: "FQIA",
    iata_country_code: "MZ",
    iata_code: "IHC",
    city_name: "Inhaca",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Inhambane Airport",
    longitude: 35.407582,
    latitude: -23.876481,
    id: "arp_inh_mz",
    icao_code: "FQIN",
    iata_country_code: "MZ",
    iata_code: "INH",
    city_name: "Inhambane",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Inhaminga Airport",
    longitude: 35.0,
    latitude: -18.4,
    id: "arp_img_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "IMG",
    city_name: "Inhaminga",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Inisheer Airport",
    longitude: -9.51135,
    latitude: 53.064834,
    id: "arp_inq_ie",
    icao_code: "EIIR",
    iata_country_code: "IE",
    iata_code: "INQ",
    city_name: "Inisheer",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Inishmaan Aerodrome",
    longitude: -9.568587,
    latitude: 53.093495,
    id: "arp_iia_ie",
    icao_code: "EIMN",
    iata_country_code: "IE",
    iata_code: "IIA",
    city_name: "Inishmaan",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Inishmore Aerodrome",
    longitude: -9.653783,
    latitude: 53.106221,
    id: "arp_ior_ie",
    icao_code: "EIIM",
    iata_country_code: "IE",
    iata_code: "IOR",
    city_name: "Inishmore",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Injune Airport",
    longitude: 148.552172,
    latitude: -25.852165,
    id: "arp_inj_au",
    icao_code: "YINJ",
    iata_country_code: "AU",
    iata_code: "INJ",
    city_name: "Injune",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Inkerman Airport",
    longitude: 141.442001,
    latitude: -16.274999,
    id: "arp_ikp_au",
    icao_code: "YIKM",
    iata_country_code: "AU",
    iata_code: "IKP",
    city_name: "Inkerman",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Innamincka Airport",
    longitude: 140.734901,
    latitude: -27.700654,
    id: "arp_inm_au",
    icao_code: "YINN",
    iata_country_code: "AU",
    iata_code: "INM",
    city_name: "Innamincka",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Innisfail Airport",
    longitude: 146.012225,
    latitude: -17.559741,
    id: "arp_ifl_au",
    icao_code: "YIFL",
    iata_country_code: "AU",
    iata_code: "IFL",
    city_name: "Innisfail",
    city: null,
  },
  {
    time_zone: "Europe/Vienna",
    name: "Innsbruck Airport",
    longitude: 11.345793,
    latitude: 47.260188,
    id: "arp_inn_at",
    icao_code: "LOWI",
    iata_country_code: "AT",
    iata_code: "INN",
    city_name: "Innsbruck",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Inongo Airport",
    longitude: 18.285873,
    latitude: -1.94688,
    id: "arp_ino_cd",
    icao_code: "FZBA",
    iata_country_code: "CD",
    iata_code: "INO",
    city_name: "Inongo",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "In Salah Airport",
    longitude: 2.510911,
    latitude: 27.251318,
    id: "arp_inz_dz",
    icao_code: "DAUI",
    iata_country_code: "DZ",
    iata_code: "INZ",
    city_name: "In Salah",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Inta Airport",
    longitude: 60.118552,
    latitude: 66.053033,
    id: "arp_ina_ru",
    icao_code: "UUYI",
    iata_country_code: "RU",
    iata_code: "INA",
    city_name: "Inta",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Inukjuak Airport",
    longitude: -78.077517,
    latitude: 58.471041,
    id: "arp_yph_ca",
    icao_code: "CYPH",
    iata_country_code: "CA",
    iata_code: "YPH",
    city_name: "Inukjuak",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Inus Airport",
    longitude: 155.15,
    latitude: -5.783333,
    id: "arp_ius_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IUS",
    city_name: "Inus",
    city: null,
  },
  {
    time_zone: "America/Inuvik",
    name: "Inuvik Mike Zubko Airport",
    longitude: -133.486576,
    latitude: 68.304695,
    id: "arp_yev_ca",
    icao_code: "CYEV",
    iata_country_code: "CA",
    iata_code: "YEV",
    city_name: "Inuvik",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Invercargill Airport",
    longitude: 168.317418,
    latitude: -46.413922,
    id: "arp_ivc_nz",
    icao_code: "NZNV",
    iata_country_code: "NZ",
    iata_code: "IVC",
    city_name: "Invercargill",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Inverell Airport",
    longitude: 151.143049,
    latitude: -29.887058,
    id: "arp_ivr_au",
    icao_code: "YIVL",
    iata_country_code: "AU",
    iata_code: "IVR",
    city_name: "Inverell",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Inverness Airport",
    longitude: -4.052822,
    latitude: 57.541485,
    id: "arp_inv_gb",
    icao_code: "EGPE",
    iata_country_code: "GB",
    iata_code: "INV",
    city_name: "Inverness",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Inverway Airport",
    longitude: 129.64275,
    latitude: -17.841344,
    id: "arp_ivw_au",
    icao_code: "YINW",
    iata_country_code: "AU",
    iata_code: "IVW",
    city_name: "Inverway",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Inyati Airport",
    longitude: 31.386219,
    latitude: -24.774704,
    id: "arp_iny_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "INY",
    city_name: "Inyati",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Inyokern Airport",
    longitude: -117.828842,
    latitude: 35.657091,
    id: "arp_iyk_us",
    icao_code: "KIYK",
    iata_country_code: "US",
    iata_code: "IYK",
    city_name: "Inyokern",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Ioannina National Airport",
    longitude: 20.822398,
    latitude: 39.695634,
    id: "arp_ioa_gr",
    icao_code: "LGIO",
    iata_country_code: "GR",
    iata_code: "IOA",
    city_name: "Ioannina",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Iokea Airport",
    longitude: 146.266667,
    latitude: -8.4,
    id: "arp_iok_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IOK",
    city_name: "Iokea",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ioma Airport",
    longitude: 147.84867,
    latitude: -8.359767,
    id: "arp_iop_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "IOP",
    city_name: "Ioma",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Iosco County Airport",
    longitude: -83.422412,
    latitude: 44.31275,
    id: "arp_eca_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ECA",
    city_name: "East Tawas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Iowa City Municipal Airport",
    longitude: -91.544855,
    latitude: 41.639215,
    id: "arp_iow_us",
    icao_code: "KIOW",
    iata_country_code: "US",
    iata_code: "IOW",
    city_name: "Iowa City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Iowa County Airport",
    longitude: -90.236667,
    latitude: 42.886889,
    id: "arp_mrj_us",
    icao_code: "KMRJ",
    iata_country_code: "US",
    iata_code: "MRJ",
    city_name: "Marcala",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Iowa Falls Municipal Airport",
    longitude: -93.267103,
    latitude: 42.470523,
    id: "arp_ifa_us",
    icao_code: "KIFA",
    iata_country_code: "US",
    iata_code: "IFA",
    city_name: "Iowa Falls",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Ipiaú Airport",
    longitude: -39.684012,
    latitude: -14.173535,
    id: "arp_ipu_br",
    icao_code: "SNIU",
    iata_country_code: "BR",
    iata_code: "IPU",
    city_name: "Ipiaú",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Ipil Airport",
    longitude: 122.601622,
    latitude: 7.785578,
    id: "arp_ipe_ph",
    icao_code: "RPMV",
    iata_country_code: "PH",
    iata_code: "IPE",
    city_name: "Ipil",
    city: null,
  },
  {
    time_zone: "America/Manaus",
    name: "Ipiranga Airport",
    longitude: -69.693942,
    latitude: -2.936733,
    id: "arp_ipg_br",
    icao_code: "SWII",
    iata_country_code: "BR",
    iata_code: "IPG",
    city_name: "Ipiranga",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Ipota Airport",
    longitude: 169.283089,
    latitude: -18.856368,
    id: "arp_ipa_vu",
    icao_code: "NVVI",
    iata_country_code: "VU",
    iata_code: "IPA",
    city_name: "Ipota",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Iqaluit Airport",
    longitude: -68.550563,
    latitude: 63.756061,
    id: "arp_yfb_ca",
    icao_code: "CYFB",
    iata_country_code: "CA",
    iata_code: "YFB",
    city_name: "Iqaluit",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Iquique Diego Aracena International Airport",
    longitude: -70.180908,
    latitude: -20.535804,
    id: "arp_iqq_cl",
    icao_code: "SCDA",
    iata_country_code: "CL",
    iata_code: "IQQ",
    city_name: "Iquique",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Iraan Municipal Airport",
    longitude: -101.89398,
    latitude: 30.907769,
    id: "arp_irb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "IRB",
    city_name: "Iraan",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Iranamadu Seaplane Base Airport",
    longitude: 80.490049,
    latitude: 9.308073,
    id: "arp_iru_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "IRU",
    city_name: "Iranamadu",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Iranshahr Airport",
    longitude: 60.719473,
    latitude: 27.233602,
    id: "arp_ihr_ir",
    icao_code: "OIZI",
    iata_country_code: "IR",
    iata_code: "IHR",
    city_name: "Iranshahr",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Irecê Airport",
    longitude: -41.849601,
    latitude: -11.340152,
    id: "arp_ire_br",
    icao_code: "SNIC",
    iata_country_code: "BR",
    iata_code: "IRE",
    city_name: "Irecê",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Ireland West Airport Knock Airport",
    longitude: -8.814867,
    latitude: 53.912018,
    id: "arp_noc_ie",
    icao_code: "EIKN",
    iata_country_code: "IE",
    iata_code: "NOC",
    city_name: "Knock",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Iringa Airport",
    longitude: 35.752034,
    latitude: -7.676313,
    id: "arp_iri_tz",
    icao_code: "HTIR",
    iata_country_code: "TZ",
    iata_code: "IRI",
    city_name: "Iringa",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Iriona Airport",
    longitude: -84.54289,
    latitude: 15.761792,
    id: "arp_irn_hn",
    icao_code: "MHIR",
    iata_country_code: "HN",
    iata_code: "IRN",
    city_name: "Iriona",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Irkutsk International Airport",
    longitude: 104.386168,
    latitude: 52.268251,
    id: "arp_ikt_ru",
    icao_code: "UIII",
    iata_country_code: "RU",
    iata_code: "IKT",
    city_name: "Irkutsk",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Ísafjörður Airport",
    longitude: -23.1388,
    latitude: 66.056755,
    id: "arp_ifj_is",
    icao_code: "BIIS",
    iata_country_code: "IS",
    iata_code: "IFJ",
    city_name: "Ísafjörður",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Iscuande Airport",
    longitude: -77.9667,
    latitude: 2.44167,
    id: "arp_isd_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ISD",
    city_name: "Iscuande",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Isfahan International Airport",
    longitude: 51.875445,
    latitude: 32.747008,
    id: "arp_ifn_ir",
    icao_code: "OIFM",
    iata_country_code: "IR",
    iata_code: "IFN",
    city_name: "Isfahan",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Ishurdi Airport",
    longitude: 89.049112,
    latitude: 24.153004,
    id: "arp_ird_bd",
    icao_code: "VGIS",
    iata_country_code: "BD",
    iata_code: "IRD",
    city_name: "Ishurdi",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Isisford Airport",
    longitude: 144.425505,
    latitude: -24.259291,
    id: "arp_isi_au",
    icao_code: "YISF",
    iata_country_code: "AU",
    iata_code: "ISI",
    city_name: "Isisford",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Iskandar Airport",
    longitude: 111.671158,
    latitude: -2.704678,
    id: "arp_pkn_id",
    icao_code: "WAOI",
    iata_country_code: "ID",
    iata_code: "PKN",
    city_name: "Pangkalan Bun",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Islamabad International Airport",
    longitude: 72.832308,
    latitude: 33.556588,
    id: "arp_isb_pk",
    icao_code: "OPIS",
    iata_country_code: "PK",
    iata_code: "ISB",
    city_name: "Islamabad",
    city: null,
  },
  {
    time_zone: "America/Cancun",
    name: "Isla Mujeres Airport",
    longitude: -86.740127,
    latitude: 21.245453,
    id: "arp_isj_mx",
    icao_code: "MMIM",
    iata_country_code: "MX",
    iata_code: "ISJ",
    city_name: "Isla Mujeres",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Island Lake Airport",
    longitude: -94.653226,
    latitude: 53.857014,
    id: "arp_yiv_ca",
    icao_code: "CYIV",
    iata_country_code: "CA",
    iata_code: "YIV",
    city_name: "Island Lake",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Islay Airport",
    longitude: -6.256668,
    latitude: 55.681922,
    id: "arp_ily_gb",
    icao_code: "EGPI",
    iata_country_code: "GB",
    iata_code: "ILY",
    city_name: "Islay",
    city: null,
  },
  {
    time_zone: "Europe/Isle_of_Man",
    name: "Isle of Man Airport",
    longitude: -4.628915,
    latitude: 54.084949,
    id: "arp_iom_im",
    icao_code: "EGNS",
    iata_country_code: "IM",
    iata_code: "IOM",
    city_name: "Isle Of Man",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Isparta Süleyman Demirel Airport",
    longitude: 30.368253,
    latitude: 37.85758,
    id: "arp_ise_tr",
    icao_code: "LTFC",
    iata_country_code: "TR",
    iata_code: "ISE",
    city_name: "Isparta",
    city: null,
  },
  {
    time_zone: "Asia/Bishkek",
    name: "Issyk-Kul International Airport",
    longitude: 76.710775,
    latitude: 42.587244,
    id: "arp_iku_kg",
    icao_code: "UCFL",
    iata_country_code: "KG",
    iata_code: "IKU",
    city_name: "Tamchy",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Istanbul Airport",
    longitude: 28.751944,
    latitude: 41.275278,
    id: "arp_ist_tr",
    icao_code: "LTFM",
    iata_country_code: "TR",
    iata_code: "IST",
    city_name: "Istanbul",
    city: {
      name: "Istanbul",
      id: "cit_ist_tr",
      iata_country_code: "TR",
      iata_code: "IST",
    },
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Istanbul Atatürk Airport",
    longitude: 28.81659,
    latitude: 40.978594,
    id: "arp_isl_tr",
    icao_code: "LTBA",
    iata_country_code: "TR",
    iata_code: "ISL",
    city_name: "Istanbul",
    city: {
      name: "Istanbul",
      id: "cit_ist_tr",
      iata_country_code: "TR",
      iata_code: "IST",
    },
  },
  {
    time_zone: "America/Bahia",
    name: "Itabuna Airport",
    longitude: -39.291362,
    latitude: -14.810556,
    id: "arp_itn_br",
    icao_code: "SNHA",
    iata_country_code: "BR",
    iata_code: "ITN",
    city_name: "Itabuna",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Itacoatiara Airport",
    longitude: -58.481201,
    latitude: -3.127259,
    id: "arp_ita_br",
    icao_code: "SBIC",
    iata_country_code: "BR",
    iata_code: "ITA",
    city_name: "Itacoatiara",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Itaituba Airport",
    longitude: -56.003774,
    latitude: -4.244041,
    id: "arp_itb_br",
    icao_code: "SBIH",
    iata_country_code: "BR",
    iata_code: "ITB",
    city_name: "Itaituba",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Itaperuna Airport",
    longitude: -41.8759,
    latitude: -21.219299,
    id: "arp_itp_br",
    icao_code: "SDUN",
    iata_country_code: "BR",
    iata_code: "ITP",
    city_name: "Itaperuna",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Itaqui Airport",
    longitude: -56.543864,
    latitude: -29.187964,
    id: "arp_itq_br",
    icao_code: "SSIQ",
    iata_country_code: "BR",
    iata_code: "ITQ",
    city_name: "Itaqui",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ithaca Tompkins Regional Airport",
    longitude: -76.458802,
    latitude: 42.49105,
    id: "arp_ith_us",
    icao_code: "KITH",
    iata_country_code: "US",
    iata_code: "ITH",
    city_name: "Ithaca",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Itokama Airport",
    longitude: 148.263832,
    latitude: -9.201516,
    id: "arp_itk_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ITK",
    city_name: "Itokama",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Ituberá Airport",
    longitude: -39.139818,
    latitude: -13.731137,
    id: "arp_ite_br",
    icao_code: "SNZW",
    iata_country_code: "BR",
    iata_code: "ITE",
    city_name: "Itubera",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Itumbiara Airport",
    longitude: -49.213644,
    latitude: -18.442279,
    id: "arp_itr_br",
    icao_code: "SBIT",
    iata_country_code: "BR",
    iata_code: "ITR",
    city_name: "Itumbiara",
    city: null,
  },
  {
    time_zone: "Asia/Ust-Nera",
    name: "Iturup Airport",
    longitude: 147.953112,
    latitude: 45.257552,
    id: "arp_itu_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "ITU",
    city_name: "Iturup",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Ivalo Airport",
    longitude: 27.408391,
    latitude: 68.607387,
    id: "arp_ivl_fi",
    icao_code: "EFIV",
    iata_country_code: "FI",
    iata_code: "IVL",
    city_name: "Ivalo",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ivanof Bay Seaplane Base",
    longitude: -159.488834,
    latitude: 55.897516,
    id: "arp_kib_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KIB",
    city_name: "Ivanof Bay",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Ivano-Frankivsk International Airport",
    longitude: 24.705628,
    latitude: 48.887399,
    id: "arp_ifo_ua",
    icao_code: "UKLI",
    iata_country_code: "UA",
    iata_code: "IFO",
    city_name: "Ivano-Frankivsk",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Ivanovo Yuzhny Airport",
    longitude: 40.941805,
    latitude: 56.940378,
    id: "arp_iwa_ru",
    icao_code: "UUBI",
    iata_country_code: "RU",
    iata_code: "IWA",
    city_name: "Ivanovo",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Ivato International Airport",
    longitude: 47.477246,
    latitude: -18.797219,
    id: "arp_tnr_mg",
    icao_code: "FMMI",
    iata_country_code: "MG",
    iata_code: "TNR",
    city_name: "Antananarivo",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Ivujivik Airport",
    longitude: -77.925387,
    latitude: 62.416495,
    id: "arp_yik_ca",
    icao_code: "CYIK",
    iata_country_code: "CA",
    iata_code: "YIK",
    city_name: "Ivujivik",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Iwakuni Kintaikyo Airport",
    longitude: 132.235992,
    latitude: 34.143902,
    id: "arp_iwk_jp",
    icao_code: "RJOI",
    iata_country_code: "JP",
    iata_code: "IWK",
    city_name: "Iwakuni",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Iwami Airport",
    longitude: 131.790643,
    latitude: 34.676433,
    id: "arp_iwj_jp",
    icao_code: "RJOW",
    iata_country_code: "JP",
    iata_code: "IWJ",
    city_name: "Masuda",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Iwo Jima Airport",
    longitude: 141.322998,
    latitude: 24.784,
    id: "arp_iwo_jp",
    icao_code: "RJAW",
    iata_country_code: "JP",
    iata_code: "IWO",
    city_name: "Iwoto",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Ixtapa Zihuatanejo International Airport",
    longitude: -101.461841,
    latitude: 17.602782,
    id: "arp_zih_mx",
    icao_code: "MMZH",
    iata_country_code: "MX",
    iata_code: "ZIH",
    city_name: "Ixtapa",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Ixtepec Airport",
    longitude: -95.092778,
    latitude: 16.451056,
    id: "arp_izt_mx",
    icao_code: "MMIT",
    iata_country_code: "MX",
    iata_code: "IZT",
    city_name: "Ixtepec",
    city: null,
  },
  {
    time_zone: "Europe/Samara",
    name: "Izhevsk Airport",
    longitude: 53.455186,
    latitude: 56.829027,
    id: "arp_ijk_ru",
    icao_code: "USII",
    iata_country_code: "RU",
    iata_code: "IJK",
    city_name: "Izhevsk",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Izumo Airport",
    longitude: 132.888716,
    latitude: 35.413925,
    id: "arp_izo_jp",
    icao_code: "RJOC",
    iata_country_code: "JP",
    iata_code: "IZO",
    city_name: "Izumo",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Ja'Aluni Airport",
    longitude: 57.30999,
    latitude: 19.480333,
    id: "arp_jnj_om",
    icao_code: "OOJA",
    iata_country_code: "OM",
    iata_code: "JNJ",
    city_name: "Duqm",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jabalpur Airport",
    longitude: 80.053857,
    latitude: 23.179507,
    id: "arp_jlr_in",
    icao_code: "VAJB",
    iata_country_code: "IN",
    iata_code: "JLR",
    city_name: "Jabalpur",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Jabiru Airport",
    longitude: 132.892794,
    latitude: -12.658622,
    id: "arp_jab_au",
    icao_code: "YJAB",
    iata_country_code: "AU",
    iata_code: "JAB",
    city_name: "Jabiru",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Jabot Airport",
    longitude: 168.977994,
    latitude: 7.749782,
    id: "arp_jat_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "JAT",
    city_name: "Jabot Island",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Jacareacanga Airport",
    longitude: -57.776572,
    latitude: -6.233725,
    id: "arp_jcr_br",
    icao_code: "SBEK",
    iata_country_code: "BR",
    iata_code: "JCR",
    city_name: "Jacareacanga",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Jacinto Lara International Airport",
    longitude: -69.359132,
    latitude: 10.0444,
    id: "arp_brm_ve",
    icao_code: "SVBM",
    iata_country_code: "VE",
    iata_code: "BRM",
    city_name: "Barquisimeto",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jack Brooks Regional Airport",
    longitude: -94.021041,
    latitude: 29.95207,
    id: "arp_bpt_us",
    icao_code: "KBPT",
    iata_country_code: "US",
    iata_code: "BPT",
    city_name: "Beaumont",
    city: {
      name: "Beaumont/Port Arthur",
      id: "cit_bpt_us",
      iata_country_code: "US",
      iata_code: "BPT",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Jack Edwards Airport",
    longitude: -87.674179,
    latitude: 30.289326,
    id: "arp_guf_us",
    icao_code: "KJKA",
    iata_country_code: "US",
    iata_code: "GUF",
    city_name: "Gulf Shores",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Jackpot Airport",
    longitude: -114.658043,
    latitude: 41.976011,
    id: "arp_kpt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KPT",
    city_name: "Jackpot",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Jackson County Airport",
    longitude: -84.459729,
    latitude: 42.25949,
    id: "arp_jxn_us",
    icao_code: "KJXN",
    iata_country_code: "US",
    iata_code: "JXN",
    city_name: "Jackson",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Jackson Hole Airport",
    longitude: -110.737814,
    latitude: 43.606907,
    id: "arp_jac_us",
    icao_code: "KJAC",
    iata_country_code: "US",
    iata_code: "JAC",
    city_name: "Jackson",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jackson–Medgar Wiley Evers International Airport",
    longitude: -90.07616,
    latitude: 32.311714,
    id: "arp_jan_us",
    icao_code: "KJAN",
    iata_country_code: "US",
    iata_code: "JAN",
    city_name: "Jackson",
    city: {
      name: "Jackson",
      id: "cit_jan_us",
      iata_country_code: "US",
      iata_code: "JAN",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Jackson Municipal Airport",
    longitude: -94.986664,
    latitude: 43.650002,
    id: "arp_mjq_us",
    icao_code: "KMJQ",
    iata_country_code: "US",
    iata_code: "MJQ",
    city_name: "Jackson",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Jacksons International Airport",
    longitude: 147.219291,
    latitude: -9.44243,
    id: "arp_pom_pg",
    icao_code: "AYPY",
    iata_country_code: "PG",
    iata_code: "POM",
    city_name: "Port Moresby",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Jacksonville Executive",
    longitude: -81.514447,
    latitude: 30.336338,
    id: "arp_crg_us",
    icao_code: "KCRG",
    iata_country_code: "US",
    iata_code: "CRG",
    city_name: "Jacksonville",
    city: {
      name: "Jacksonville",
      id: "cit_jax_us",
      iata_country_code: "US",
      iata_code: "JAX",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Jacksonville International Airport",
    longitude: -81.687916,
    latitude: 30.494085,
    id: "arp_jax_us",
    icao_code: "KJAX",
    iata_country_code: "US",
    iata_code: "JAX",
    city_name: "Jacksonville",
    city: {
      name: "Jacksonville",
      id: "cit_jax_us",
      iata_country_code: "US",
      iata_code: "JAX",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Jacksonville Municipal Airport",
    longitude: -90.238115,
    latitude: 39.772338,
    id: "arp_ijx_us",
    icao_code: "KIJX",
    iata_country_code: "US",
    iata_code: "IJX",
    city_name: "Jacksonville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Jacksonville Naval Air Station Airport",
    longitude: -81.680683,
    latitude: 30.235999,
    id: "arp_nip_us",
    icao_code: "KNIP",
    iata_country_code: "US",
    iata_code: "NIP",
    city_name: "Jacksonville",
    city: {
      name: "Jacksonville",
      id: "cit_jax_us",
      iata_country_code: "US",
      iata_code: "JAX",
    },
  },
  {
    time_zone: "America/Port-au-Prince",
    name: "Jacmel Airport",
    longitude: -72.518537,
    latitude: 18.240919,
    id: "arp_jak_ht",
    icao_code: "MTJA",
    iata_country_code: "HT",
    iata_code: "JAK",
    city_name: "Jacmel",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Jacobina Airport",
    longitude: -40.553876,
    latitude: -11.161978,
    id: "arp_jcm_br",
    icao_code: "SNJB",
    iata_country_code: "BR",
    iata_code: "JCM",
    city_name: "Jacobina",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Jacqueline Cochran Regional Airport",
    longitude: -116.161366,
    latitude: 33.63103,
    id: "arp_trm_us",
    icao_code: "KTRM",
    iata_country_code: "US",
    iata_code: "TRM",
    city_name: "Palm Springs",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Jacquinot Bay Airport",
    longitude: 151.503502,
    latitude: -5.651256,
    id: "arp_jaq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "JAQ",
    city_name: "Jacquinot Bay",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "JadeWeser Airport",
    longitude: 8.052871,
    latitude: 53.50361,
    id: "arp_wvn_de",
    icao_code: "EDWI",
    iata_country_code: "DE",
    iata_code: "WVN",
    city_name: "Wilhelmshaven",
    city: null,
  },
  {
    time_zone: "America/Glace_Bay",
    name: "JA Douglas McCurdy Sydney Airport",
    longitude: -60.046168,
    latitude: 46.163415,
    id: "arp_yqy_ca",
    icao_code: "CYQY",
    iata_country_code: "CA",
    iata_code: "YQY",
    city_name: "Sydney",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Jaén Airport",
    longitude: -78.774069,
    latitude: -5.593337,
    id: "arp_jae_pe",
    icao_code: "SPJE",
    iata_country_code: "PE",
    iata_code: "JAE",
    city_name: "Jaén",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Jaffrey Silver Ranch Airport",
    longitude: -72.002899,
    latitude: 42.805369,
    id: "arp_afn_us",
    icao_code: "KAFN",
    iata_country_code: "US",
    iata_code: "AFN",
    city_name: "Jaffrey",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jagdalpur Airport",
    longitude: 82.036824,
    latitude: 19.074503,
    id: "arp_jgb_in",
    icao_code: null,
    iata_country_code: "IN",
    iata_code: "JGB",
    city_name: "Jagdalpur",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "JAGS McCartney International Airport",
    longitude: -71.144168,
    latitude: 21.443361,
    id: "arp_gdt_tc",
    icao_code: "MBGT",
    iata_country_code: "TC",
    iata_code: "GDT",
    city_name: "Cockburn Town",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Jaguaruna Regional Airport",
    longitude: -49.061848,
    latitude: -28.675788,
    id: "arp_jjg_br",
    icao_code: "SBJA",
    iata_country_code: "BR",
    iata_code: "JJG",
    city_name: "Jaguaruna",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Jahrom Airport",
    longitude: 53.580049,
    latitude: 28.586837,
    id: "arp_jar_us",
    icao_code: "OISJ",
    iata_country_code: "US",
    iata_code: "JAR",
    city_name: "Jahrom",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Jaime Gonzalez Airport",
    longitude: -80.414043,
    latitude: 22.149999,
    id: "arp_cfg_cu",
    icao_code: "MUCF",
    iata_country_code: "CU",
    iata_code: "CFG",
    city_name: "Cienfuegos",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jaipur International Airport",
    longitude: 75.80897,
    latitude: 26.826288,
    id: "arp_jai_in",
    icao_code: "VIJP",
    iata_country_code: "IN",
    iata_code: "JAI",
    city_name: "Jaipur",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jaisalmer Airport",
    longitude: 70.865021,
    latitude: 26.889052,
    id: "arp_jsa_in",
    icao_code: "VIJR",
    iata_country_code: "IN",
    iata_code: "JSA",
    city_name: "Jaisalmer",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Jajao Airport",
    longitude: 159.266667,
    latitude: -8.214999,
    id: "arp_jja_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "JJA",
    city_name: "Jajao",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Jalalabad Airport",
    longitude: 70.498138,
    latitude: 34.400143,
    id: "arp_jaa_af",
    icao_code: "OAJL",
    iata_country_code: "AF",
    iata_code: "JAA",
    city_name: "Jalalabad",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Jalaluddin Airport",
    longitude: 122.85038,
    latitude: 0.63772,
    id: "arp_gto_id",
    icao_code: "WAMG",
    iata_country_code: "ID",
    iata_code: "GTO",
    city_name: "Gorontalo",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Jales Airport",
    longitude: -50.545781,
    latitude: -20.289563,
    id: "arp_jls_br",
    icao_code: "SDJL",
    iata_country_code: "BR",
    iata_code: "JLS",
    city_name: "Jales",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Jaluit Airport",
    longitude: 169.637126,
    latitude: 5.909249,
    id: "arp_uit_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "UIT",
    city_name: "Jaluit Island",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Jam Airport",
    longitude: 52.354254,
    latitude: 27.818521,
    id: "arp_knr_ir",
    icao_code: "OIBJ",
    iata_country_code: "IR",
    iata_code: "KNR",
    city_name: "Kangan",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Jamba Airport",
    longitude: 16.06743,
    latitude: -14.697863,
    id: "arp_jmb_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "JMB",
    city_name: "Jamba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jamestown Regional Airport",
    longitude: -98.678624,
    latitude: 46.929565,
    id: "arp_jms_us",
    icao_code: "KJMS",
    iata_country_code: "US",
    iata_code: "JMS",
    city_name: "Jamestown",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jammu Airport",
    longitude: 74.839068,
    latitude: 32.68948,
    id: "arp_ixj_in",
    icao_code: "VIJU",
    iata_country_code: "IN",
    iata_code: "IXJ",
    city_name: "Jammu",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jamnagar Airport",
    longitude: 70.014249,
    latitude: 22.463105,
    id: "arp_jga_in",
    icao_code: "VAJM",
    iata_country_code: "IN",
    iata_code: "JGA",
    city_name: "Jamnagar",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Janakpur Airport",
    longitude: 85.923387,
    latitude: 26.709521,
    id: "arp_jkr_np",
    icao_code: "VNJP",
    iata_country_code: "NP",
    iata_code: "JKR",
    city_name: "Janakpur",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Jandakot Airport",
    longitude: 115.880179,
    latitude: -32.096364,
    id: "arp_jad_au",
    icao_code: "YPJT",
    iata_country_code: "AU",
    iata_code: "JAD",
    city_name: "Perth",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Januária Airport",
    longitude: -44.386083,
    latitude: -15.474359,
    id: "arp_jna_br",
    icao_code: "SNJN",
    iata_country_code: "BR",
    iata_code: "JNA",
    city_name: "Januária",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Japura Airport",
    longitude: 102.334999,
    latitude: -0.352612,
    id: "arp_rgt_id",
    icao_code: "WIPR",
    iata_country_code: "ID",
    iata_code: "RGT",
    city_name: "Rengat",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Jaqué Airport",
    longitude: -78.157203,
    latitude: 7.517779,
    id: "arp_jqe_pa",
    icao_code: "MPJE",
    iata_country_code: "PA",
    iata_code: "JQE",
    city_name: "Jaqué",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Jardines del Rey Airport",
    longitude: -78.327856,
    latitude: 22.461279,
    id: "arp_ccc_cu",
    icao_code: "MUCC",
    iata_country_code: "CU",
    iata_code: "CCC",
    city_name: "Cayo Coco",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Jask Airport",
    longitude: 57.79842,
    latitude: 25.653195,
    id: "arp_jsk_ir",
    icao_code: null,
    iata_country_code: "IR",
    iata_code: "JSK",
    city_name: "Jask",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Jasper Airport",
    longitude: -118.059645,
    latitude: 52.996688,
    id: "arp_yja_ca",
    icao_code: "CYJA",
    iata_country_code: "CA",
    iata_code: "YJA",
    city_name: "Jasper",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jasper County Airport",
    longitude: -87.18276,
    latitude: 40.946547,
    id: "arp_rnz_us",
    icao_code: "KRZL",
    iata_country_code: "US",
    iata_code: "RNZ",
    city_name: "Rensselaer",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jasper County-Bell Field Airport",
    longitude: -94.034907,
    latitude: 30.886859,
    id: "arp_jas_us",
    icao_code: "KJAS",
    iata_country_code: "US",
    iata_code: "JAS",
    city_name: "Jasper",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Jataí Airport",
    longitude: -51.77004,
    latitude: -17.831121,
    id: "arp_jti_br",
    icao_code: "SWJW",
    iata_country_code: "BR",
    iata_code: "JTI",
    city_name: "Jatai",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Jebel Ali Seaplane Base Airport",
    longitude: 55.023138,
    latitude: 24.989677,
    id: "arp_djh_ae",
    icao_code: null,
    iata_country_code: "AE",
    iata_code: "DJH",
    city_name: "Dubai",
    city: {
      name: "Dubai",
      id: "cit_dxb_ae",
      iata_country_code: "AE",
      iata_code: "DXB",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Jefferson City Memorial Airport",
    longitude: -92.160561,
    latitude: 38.592172,
    id: "arp_jef_us",
    icao_code: "KJEF",
    iata_country_code: "US",
    iata_code: "JEF",
    city_name: "Jefferson City",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Jefferson County International Airport",
    longitude: -122.810586,
    latitude: 48.055529,
    id: "arp_twd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TWD",
    city_name: "Port Townsend",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jefferson Municipal Airport",
    longitude: -94.342455,
    latitude: 42.011623,
    id: "arp_efw_us",
    icao_code: "KEFW",
    iata_country_code: "US",
    iata_code: "EFW",
    city_name: "Jefferson",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Jeh Airport",
    longitude: 168.962121,
    latitude: 7.565577,
    id: "arp_jej_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "JEJ",
    city_name: "Jeh Island",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Jeju International Airport",
    longitude: 126.492175,
    latitude: 33.510856,
    id: "arp_cju_kr",
    icao_code: "RKPC",
    iata_country_code: "KR",
    iata_code: "CJU",
    city_name: "Jeju",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Jeki Airport",
    longitude: 29.604709,
    latitude: -15.6334,
    id: "arp_jek_zm",
    icao_code: "FLJK",
    iata_country_code: "ZM",
    iata_code: "JEK",
    city_name: "Lower Zambezi National Park/Zambezi",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Jember Airport",
    longitude: 113.695104,
    latitude: -8.240655,
    id: "arp_jbb_id",
    icao_code: "WARE",
    iata_country_code: "ID",
    iata_code: "JBB",
    city_name: "Jember",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Jenpeg Airport",
    longitude: -98.045782,
    latitude: 54.518731,
    id: "arp_zjg_ca",
    icao_code: "CZJG",
    iata_country_code: "CA",
    iata_code: "ZJG",
    city_name: "Jenpeg",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Jeongseok Airport",
    longitude: 126.712258,
    latitude: 33.398715,
    id: "arp_jdg_kr",
    icao_code: "RKPD",
    iata_country_code: "KR",
    iata_code: "JDG",
    city_name: "Jeongseok",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Jeonju Airport",
    longitude: 127.119112,
    latitude: 35.87808,
    id: "arp_chn_kr",
    icao_code: "RKJU",
    iata_country_code: "KR",
    iata_code: "CHN",
    city_name: "Jeonju",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Jequié Airport",
    longitude: -40.069542,
    latitude: -13.877889,
    id: "arp_jeq_br",
    icao_code: "SNJK",
    iata_country_code: "BR",
    iata_code: "JEQ",
    city_name: "Jequié",
    city: null,
  },
  {
    time_zone: "America/Port-au-Prince",
    name: "Jérémie Airport",
    longitude: -74.170182,
    latitude: 18.66283,
    id: "arp_jee_ht",
    icao_code: "MTJE",
    iata_country_code: "HT",
    iata_code: "JEE",
    city_name: "Jeremie",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Jerez Airport",
    longitude: -6.060494,
    latitude: 36.744946,
    id: "arp_xry_es",
    icao_code: "LEJR",
    iata_country_code: "ES",
    iata_code: "XRY",
    city_name: "Jerez",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Jericoacoara Airport",
    longitude: -40.35145,
    latitude: -2.904435,
    id: "arp_jjd_br",
    icao_code: "SBJE",
    iata_country_code: "BR",
    iata_code: "JJD",
    city_name: "Jijoca de Jericoacoara",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Jerry Tyler Memorial Airport",
    longitude: -86.225863,
    latitude: 41.836096,
    id: "arp_nle_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NLE",
    city_name: "Niles",
    city: null,
  },
  {
    time_zone: "Europe/Jersey",
    name: "Jersey Airport",
    longitude: -2.195365,
    latitude: 49.207682,
    id: "arp_jer_je",
    icao_code: "EGJJ",
    iata_country_code: "JE",
    iata_code: "JER",
    city_name: "Jersey",
    city: null,
  },
  {
    time_zone: "Asia/Hebron",
    name: "Jerusalem Airport",
    longitude: 35.218296,
    latitude: 31.863904,
    id: "arp_jrs_il",
    icao_code: null,
    iata_country_code: "IL",
    iata_code: "JRS",
    city_name: "Jerusalem",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Jessore Airport",
    longitude: 89.16079,
    latitude: 23.181951,
    id: "arp_jsr_bd",
    icao_code: "VGJR",
    iata_country_code: "BD",
    iata_code: "JSR",
    city_name: "Jessore",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jeypore Airport",
    longitude: 82.552134,
    latitude: 18.879951,
    id: "arp_pyb_in",
    icao_code: "VEJP",
    iata_country_code: "IN",
    iata_code: "PYB",
    city_name: "Jeypore",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jharsuguda Veer Surendra Sai Airport",
    longitude: 84.048876,
    latitude: 21.91542,
    id: "arp_jrg_in",
    icao_code: "VEJH",
    iata_country_code: "IN",
    iata_code: "JRG",
    city_name: "Jharsuguda",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jiagedaqi Airport",
    longitude: 124.11753,
    latitude: 50.37209,
    id: "arp_jgd_cn",
    icao_code: "ZYJD",
    iata_country_code: "CN",
    iata_code: "JGD",
    city_name: "Jiagedaqi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jiamusi Dongjiao Airport",
    longitude: 130.461801,
    latitude: 46.844064,
    id: "arp_jmu_cn",
    icao_code: "ZYJM",
    iata_country_code: "CN",
    iata_code: "JMU",
    city_name: "Jiamusi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jiansanjiang Airport",
    longitude: 132.660278,
    latitude: 47.11,
    id: "arp_jsj_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "JSJ",
    city_name: "Jiansanjiang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jiayuguan Airport",
    longitude: 98.341126,
    latitude: 39.860745,
    id: "arp_jgn_cn",
    icao_code: "ZLJQ",
    iata_country_code: "CN",
    iata_code: "JGN",
    city_name: "Jiayuguan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jieyang Chaoshan International Airport",
    longitude: 116.501701,
    latitude: 23.552181,
    id: "arp_swa_cn",
    icao_code: "ZGOW",
    iata_country_code: "CN",
    iata_code: "SWA",
    city_name: "Shantou",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Jijel Ferhat Abbas Airport",
    longitude: 5.875692,
    latitude: 36.794247,
    id: "arp_gjl_dz",
    icao_code: "DAAV",
    iata_country_code: "DZ",
    iata_code: "GJL",
    city_name: "Jijel",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Jijiga Airport",
    longitude: 42.910185,
    latitude: 9.332068,
    id: "arp_jij_et",
    icao_code: "HAJJ",
    iata_country_code: "ET",
    iata_code: "JIJ",
    city_name: "Jijiga",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jilin Ertaizi Airport",
    longitude: 126.394805,
    latitude: 44.002168,
    id: "arp_jil_cn",
    icao_code: "ZYJL",
    iata_country_code: "CN",
    iata_code: "JIL",
    city_name: "Jilin",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Jim Hamilton-L.B. Owens Airport",
    longitude: -80.995751,
    latitude: 33.970848,
    id: "arp_cub_us",
    icao_code: "KCUB",
    iata_country_code: "US",
    iata_code: "CUB",
    city_name: "Columbia",
    city: {
      name: "Columbia",
      id: "cit_cae_us",
      iata_country_code: "US",
      iata_code: "CAE",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Jim Kelly Field",
    longitude: -99.774612,
    latitude: 40.790238,
    id: "arp_lxn_us",
    icao_code: "KLXN",
    iata_country_code: "US",
    iata_code: "LXN",
    city_name: "Lexington",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Jimma Airport",
    longitude: 36.817554,
    latitude: 7.665683,
    id: "arp_jim_et",
    icao_code: "HAJM",
    iata_country_code: "ET",
    iata_code: "JIM",
    city_name: "Jimma",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jinan Yaoqiang International Airport",
    longitude: 117.216003,
    latitude: 36.8572,
    id: "arp_tna_cn",
    icao_code: "ZSJN",
    iata_country_code: "CN",
    iata_code: "TNA",
    city_name: "Jinan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jinchang Jinchuan Airport",
    longitude: 102.348264,
    latitude: 38.542083,
    id: "arp_jic_cn",
    icao_code: "ZLJC",
    iata_country_code: "CN",
    iata_code: "JIC",
    city_name: "Jinchang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jingdezhen Luojia Airport",
    longitude: 117.17596,
    latitude: 29.338603,
    id: "arp_jdz_cn",
    icao_code: "ZSJD",
    iata_country_code: "CN",
    iata_code: "JDZ",
    city_name: "Jingdezhen",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jinggangshan Airport",
    longitude: 114.743307,
    latitude: 26.854752,
    id: "arp_jgs_cn",
    icao_code: "ZSJA",
    iata_country_code: "CN",
    iata_code: "JGS",
    city_name: "Ji'an",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Jinhae Airport",
    longitude: 128.696229,
    latitude: 35.140248,
    id: "arp_chf_kr",
    icao_code: "RKPE",
    iata_country_code: "KR",
    iata_code: "CHF",
    city_name: "Jinhae",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jining Qufu Airport",
    longitude: 116.358539,
    latitude: 35.296292,
    id: "arp_jng_cn",
    icao_code: "ZSJG",
    iata_country_code: "CN",
    iata_code: "JNG",
    city_name: "Jining",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Jinja Airport",
    longitude: 33.197271,
    latitude: 0.450104,
    id: "arp_jin_ug",
    icao_code: "HUJI",
    iata_country_code: "UG",
    iata_code: "JIN",
    city_name: "Jinja",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Jinnah International Airport",
    longitude: 67.164464,
    latitude: 24.903559,
    id: "arp_khi_pk",
    icao_code: "OPKC",
    iata_country_code: "PK",
    iata_code: "KHI",
    city_name: "Karachi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jinzhou Bay Airport",
    longitude: 121.061996,
    latitude: 41.101397,
    id: "arp_jnz_cn",
    icao_code: "ZYJZ",
    iata_country_code: "CN",
    iata_code: "JNZ",
    city_name: "Jinzhou",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Ji-Paraná Airport",
    longitude: -61.847252,
    latitude: -10.872086,
    id: "arp_jpr_br",
    icao_code: "SBJI",
    iata_country_code: "BR",
    iata_code: "JPR",
    city_name: "Ji-Paraná",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Jipijapa Airport",
    longitude: -80.468403,
    latitude: -1.045314,
    id: "arp_jip_ec",
    icao_code: "SEJI",
    iata_country_code: "EC",
    iata_code: "JIP",
    city_name: "Jipijapa",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Jiri Airport",
    longitude: 86.2333,
    latitude: 27.6333,
    id: "arp_jir_np",
    icao_code: "VNJI",
    iata_country_code: "NP",
    iata_code: "JIR",
    city_name: "Jiri",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Jiroft Airport",
    longitude: 57.670785,
    latitude: 28.727932,
    id: "arp_jyr_ir",
    icao_code: "OIKJ",
    iata_country_code: "IR",
    iata_code: "JYR",
    city_name: "Jiroft",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jiujiang Lushan Airport",
    longitude: 115.804439,
    latitude: 29.479063,
    id: "arp_jiu_cn",
    icao_code: "ZSJJ",
    iata_country_code: "CN",
    iata_code: "JIU",
    city_name: "Jiujiang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jiuzhai Huanglong Airport",
    longitude: 103.685041,
    latitude: 32.852735,
    id: "arp_jzh_cn",
    icao_code: "ZUJZ",
    iata_country_code: "CN",
    iata_code: "JZH",
    city_name: "Jiuzhaigou",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Jiwani Airport",
    longitude: 61.805053,
    latitude: 25.068591,
    id: "arp_jiw_pk",
    icao_code: "OPJI",
    iata_country_code: "PK",
    iata_code: "JIW",
    city_name: "Jiwani",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Jixi Xingkaihu Airport",
    longitude: 131.193,
    latitude: 45.293,
    id: "arp_jxa_cn",
    icao_code: "ZYJX",
    iata_country_code: "CN",
    iata_code: "JXA",
    city_name: "Jixi",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Jizan Regional Airport",
    longitude: 42.584806,
    latitude: 16.901629,
    id: "arp_giz_sa",
    icao_code: "OEGN",
    iata_country_code: "SA",
    iata_code: "GIZ",
    city_name: "Jizan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "J Lynn Helms Sevier County Airport",
    longitude: -94.400734,
    latitude: 34.046734,
    id: "arp_deq_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DEQ",
    city_name: "Huzhou",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "João Paulo II Airport",
    longitude: -25.710557,
    latitude: 37.7486,
    id: "arp_pdl_pt",
    icao_code: "LPPD",
    iata_country_code: "PT",
    iata_code: "PDL",
    city_name: "Ponta Delgada",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "João Simões Lopes Neto International Airport",
    longitude: -52.327778,
    latitude: -31.718249,
    id: "arp_pet_br",
    icao_code: "SBPK",
    iata_country_code: "BR",
    iata_code: "PET",
    city_name: "Pelotas",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jodhpur Airport",
    longitude: 73.049538,
    latitude: 26.255325,
    id: "arp_jdh_in",
    icao_code: "VIJO",
    iata_country_code: "IN",
    iata_code: "JDH",
    city_name: "Jodhpur",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Joensuu Airport",
    longitude: 29.611183,
    latitude: 62.660298,
    id: "arp_joe_fi",
    icao_code: "EFJO",
    iata_country_code: "FI",
    iata_code: "JOE",
    city_name: "Joensuu",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Johan Adolf Pengel International Airport",
    longitude: -55.200102,
    latitude: 5.455565,
    id: "arp_pbm_sr",
    icao_code: "SMJP",
    iata_country_code: "SR",
    iata_code: "PBM",
    city_name: "Paramaribo",
    city: {
      name: "Paramaribo",
      id: "cit_pbm_sr",
      iata_country_code: "SR",
      iata_code: "PBM",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Johannesburg airport",
    longitude: 28.243572,
    latitude: -26.137936,
    id: "arp_jnb_za",
    icao_code: "FAOR",
    iata_country_code: "ZA",
    iata_code: "JNB",
    city_name: "Johannesburg",
    city: {
      name: "Johannesburg",
      id: "cit_jnb_za",
      iata_country_code: "ZA",
      iata_code: "JNB",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Johan Pienaar Airport",
    longitude: 23.411768,
    latitude: -27.457191,
    id: "arp_kmh_za",
    icao_code: "FAKU",
    iata_country_code: "ZA",
    iata_code: "KMH",
    city_name: "Kuruman",
    city: null,
  },
  {
    time_zone: "America/Montserrat",
    name: "John A. Osborne Airport",
    longitude: -62.193319,
    latitude: 16.79142,
    id: "arp_mni_ms",
    icao_code: "TRPG",
    iata_country_code: "MS",
    iata_code: "MNI",
    city_name: "Montserrat",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "John C. Munro Hamilton International Airport",
    longitude: -79.931289,
    latitude: 43.169869,
    id: "arp_yhm_ca",
    icao_code: "CYHM",
    iata_country_code: "CA",
    iata_code: "YHM",
    city_name: "Hamilton",
    city: {
      name: "Toronto",
      id: "cit_yto_ca",
      iata_country_code: "CA",
      iata_code: "YTO",
    },
  },
  {
    time_zone: "America/New_York",
    name: "John F. Kennedy International Airport",
    longitude: -73.778519,
    latitude: 40.640556,
    id: "arp_jfk_us",
    icao_code: "KJFK",
    iata_country_code: "US",
    iata_code: "JFK",
    city_name: "New York",
    city: {
      name: "New York",
      id: "cit_nyc_us",
      iata_country_code: "US",
      iata_code: "NYC",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "John F. Kennedy Memorial Airport",
    longitude: -90.918945,
    latitude: 46.548555,
    id: "arp_asx_us",
    icao_code: "KASX",
    iata_country_code: "US",
    iata_code: "ASX",
    city_name: "Ashland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "John Glenn Columbus International Airport",
    longitude: -82.889537,
    latitude: 39.99897,
    id: "arp_cmh_us",
    icao_code: "KCMH",
    iata_country_code: "US",
    iata_code: "CMH",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_cmh_us",
      iata_country_code: "US",
      iata_code: "CMH",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "John H. Batten Airport",
    longitude: -87.816953,
    latitude: 42.760043,
    id: "arp_rac_us",
    icao_code: "KRAC",
    iata_country_code: "US",
    iata_code: "RAC",
    city_name: "Racine",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "John Murtha Johnstown–Cambria County Airport",
    longitude: -78.833616,
    latitude: 40.317116,
    id: "arp_jst_us",
    icao_code: "KJST",
    iata_country_code: "US",
    iata_code: "JST",
    city_name: "Johnstown",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Johnson County Airport",
    longitude: -106.721858,
    latitude: 44.381089,
    id: "arp_byg_us",
    icao_code: "KBYG",
    iata_country_code: "US",
    iata_code: "BYG",
    city_name: "Buffalo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Johnson County Executive Airport",
    longitude: -94.737624,
    latitude: 38.847831,
    id: "arp_ojc_us",
    icao_code: "KOJC",
    iata_country_code: "US",
    iata_code: "OJC",
    city_name: "Olathe",
    city: {
      name: "Kansas City",
      id: "cit_mkc_us",
      iata_country_code: "US",
      iata_code: "MKC",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "John Wayne Airport",
    longitude: -117.866359,
    latitude: 33.67646,
    id: "arp_sna_us",
    icao_code: "KSNA",
    iata_country_code: "US",
    iata_code: "SNA",
    city_name: "Santa Ana",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Joint Base Andrews Airport",
    longitude: -76.866997,
    latitude: 38.810799,
    id: "arp_adw_us",
    icao_code: "KADW",
    iata_country_code: "US",
    iata_code: "ADW",
    city_name: "Camp Springs",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Joinville-Lauro Carneiro de Loyola Airport",
    longitude: -48.799398,
    latitude: -26.224213,
    id: "arp_joi_br",
    icao_code: "SBJV",
    iata_country_code: "BR",
    iata_code: "JOI",
    city_name: "Joinville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Joliet Regional Airport",
    longitude: -88.175501,
    latitude: 41.518058,
    id: "arp_jot_us",
    icao_code: "KJOT",
    iata_country_code: "US",
    iata_code: "JOT",
    city_name: "Joliet",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Jolo Airport",
    longitude: 121.010334,
    latitude: 6.053545,
    id: "arp_jol_ph",
    icao_code: "RPMJ",
    iata_country_code: "PH",
    iata_code: "JOL",
    city_name: "Jolo",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Jomo Kenyatta International Airport",
    longitude: 36.926934,
    latitude: -1.320975,
    id: "arp_nbo_ke",
    icao_code: "HKJK",
    iata_country_code: "KE",
    iata_code: "NBO",
    city_name: "Nairobi",
    city: {
      name: "Nairobi",
      id: "cit_nbo_ke",
      iata_country_code: "KE",
      iata_code: "NBO",
    },
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Jomsom Airport",
    longitude: 83.72308,
    latitude: 28.781162,
    id: "arp_jmo_np",
    icao_code: "VNJS",
    iata_country_code: "NP",
    iata_code: "JMO",
    city_name: "Jomsom",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Jonesboro Municipal Airport",
    longitude: -90.644731,
    latitude: 35.833194,
    id: "arp_jbr_us",
    icao_code: "KJBR",
    iata_country_code: "US",
    iata_code: "JBR",
    city_name: "Jonesboro",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Jönköping Airport",
    longitude: 14.069228,
    latitude: 57.756019,
    id: "arp_jkg_se",
    icao_code: "ESGJ",
    iata_country_code: "SE",
    iata_code: "JKG",
    city_name: "Jönköping",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Joplin Regional Airport",
    longitude: -94.499201,
    latitude: 37.153367,
    id: "arp_jln_us",
    icao_code: "KJLN",
    iata_country_code: "US",
    iata_code: "JLN",
    city_name: "Joplin",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Jordan Airport",
    longitude: -106.949543,
    latitude: 47.326682,
    id: "arp_jdn_us",
    icao_code: "KJDN",
    iata_country_code: "US",
    iata_code: "JDN",
    city_name: "Jordan",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Jorge Chávez International Airport",
    longitude: -77.113171,
    latitude: -12.022976,
    id: "arp_lim_pe",
    icao_code: "SPJC",
    iata_country_code: "PE",
    iata_code: "LIM",
    city_name: "Lima",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Jorge Isaacs Airport",
    longitude: -72.491636,
    latitude: 11.231839,
    id: "arp_mcj_co",
    icao_code: "SKLM",
    iata_country_code: "CO",
    iata_code: "MCJ",
    city_name: "Maicao",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "Jorge Newbery Airfield",
    longitude: -58.416304,
    latitude: -34.558615,
    id: "arp_aep_ar",
    icao_code: "SABE",
    iata_country_code: "AR",
    iata_code: "AEP",
    city_name: "Buenos Aires",
    city: {
      name: "Buenos Aires",
      id: "cit_bue_ar",
      iata_country_code: "AR",
      iata_code: "BUE",
    },
  },
  {
    time_zone: "America/La_Paz",
    name: "Jorge Wilstermann International Airport",
    longitude: -66.177636,
    latitude: -17.419749,
    id: "arp_cbb_bo",
    icao_code: "SLCB",
    iata_country_code: "BO",
    iata_code: "CBB",
    city_name: "Cochabamba",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Jorhat Airport",
    longitude: 94.177664,
    latitude: 26.731914,
    id: "arp_jrh_in",
    icao_code: "VEJT",
    iata_country_code: "IN",
    iata_code: "JRH",
    city_name: "Jorhat",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "José Aponte de la Torre Airport",
    longitude: -65.643635,
    latitude: 18.248046,
    id: "arp_nrr_pr",
    icao_code: "TJRV",
    iata_country_code: "PR",
    iata_code: "NRR",
    city_name: "Ceiba",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "José Celestino Mutis Airport",
    longitude: -77.394695,
    latitude: 6.202815,
    id: "arp_bsc_co",
    icao_code: "SKBS",
    iata_country_code: "CO",
    iata_code: "BSC",
    city_name: "Bahía Solano",
    city: null,
  },
  {
    time_zone: "America/Catamarca",
    name: "Jose De San Martin Airport",
    longitude: -70.457407,
    latitude: -44.048792,
    id: "arp_jsm_ar",
    icao_code: "SAWS",
    iata_country_code: "AR",
    iata_code: "JSM",
    city_name: "Jose de San Martin",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Josefa Camejo International Airport",
    longitude: -70.148668,
    latitude: 11.78086,
    id: "arp_lsp_ve",
    icao_code: "SVJC",
    iata_country_code: "VE",
    iata_code: "LSP",
    city_name: "Las Piedras",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "José Joaquín de Olmedo International Airport",
    longitude: -79.884365,
    latitude: -2.154509,
    id: "arp_gye_ec",
    icao_code: "SEGU",
    iata_country_code: "EC",
    iata_code: "GYE",
    city_name: "Guayaquil",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "José Leonardo Chirino Airport",
    longitude: -69.680855,
    latitude: 11.41488,
    id: "arp_cze_ve",
    icao_code: "SVCR",
    iata_country_code: "VE",
    iata_code: "CZE",
    city_name: "Coro",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "José María Velasco Ibarra Airport",
    longitude: -79.941161,
    latitude: -4.378562,
    id: "arp_mrr_ec",
    icao_code: "SEMA",
    iata_country_code: "EC",
    iata_code: "MRR",
    city_name: "Macará",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "José Martí International Airport",
    longitude: -82.409362,
    latitude: 22.989777,
    id: "arp_hav_cu",
    icao_code: "MUHA",
    iata_country_code: "CU",
    iata_code: "HAV",
    city_name: "Havana",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Josephstaal Airport",
    longitude: 145.007083,
    latitude: -4.747468,
    id: "arp_jop_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "JOP",
    city_name: "Josephstaal",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Joshua Mqabuko Nkomo International Airport",
    longitude: 28.620771,
    latitude: -20.017128,
    id: "arp_buq_zw",
    icao_code: "FVBU",
    iata_country_code: "ZW",
    iata_code: "BUQ",
    city_name: "Bulawayo",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Jos Orno Imsula Airport",
    longitude: 127.908594,
    latitude: -8.13999,
    id: "arp_jio_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "JIO",
    city_name: "Tiakur",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Juana Azurduy de Padilla International Airport",
    longitude: -65.289727,
    latitude: -19.008689,
    id: "arp_sre_bo",
    icao_code: "SLAL",
    iata_country_code: "BO",
    iata_code: "SRE",
    city_name: "Sucre",
    city: null,
  },
  {
    time_zone: "America/Kralendijk",
    name: "Juancho E. Yrausquin Airport",
    longitude: -63.220313,
    latitude: 17.64488,
    id: "arp_sab_nl",
    icao_code: "TNCS",
    iata_country_code: "NL",
    iata_code: "SAB",
    city_name: "Saba",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Juanda International Airport",
    longitude: 112.787145,
    latitude: -7.379357,
    id: "arp_sub_id",
    icao_code: "WARR",
    iata_country_code: "ID",
    iata_code: "SUB",
    city_name: "Surabaya",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Juan De Ayolas Airport",
    longitude: -56.854144,
    latitude: -27.370543,
    id: "arp_ayo_py",
    icao_code: "SGAY",
    iata_country_code: "PY",
    iata_code: "AYO",
    city_name: "Ayolas",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Juan Gualberto Gómez Airport",
    longitude: -81.436308,
    latitude: 23.035752,
    id: "arp_vra_cu",
    icao_code: "MUVR",
    iata_country_code: "CU",
    iata_code: "VRA",
    city_name: "Varadero",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Juan H. White Airport",
    longitude: -75.198784,
    latitude: 7.967439,
    id: "arp_caq_co",
    icao_code: "SKCU",
    iata_country_code: "CO",
    iata_code: "CAQ",
    city_name: "Caucasia",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Juan José Rondón Airport",
    longitude: -73.109184,
    latitude: 5.760721,
    id: "arp_ron_co",
    icao_code: "SKPA",
    iata_country_code: "CO",
    iata_code: "RON",
    city_name: "Paipa",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Juanjuí Airport",
    longitude: -76.730872,
    latitude: -7.172865,
    id: "arp_jji_pe",
    icao_code: "SPJI",
    iata_country_code: "PE",
    iata_code: "JJI",
    city_name: "Juanjui",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Juan Manuel Gálvez International Airport",
    longitude: -86.523979,
    latitude: 16.3172,
    id: "arp_rtb_hn",
    icao_code: "MHRO",
    iata_country_code: "HN",
    iata_code: "RTB",
    city_name: "Roatan",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Juan Mendoza Airport",
    longitude: -67.077744,
    latitude: -17.963122,
    id: "arp_oru_bo",
    icao_code: "SLOR",
    iata_country_code: "BO",
    iata_code: "ORU",
    city_name: "Oruro",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Juan Pablo Perez Alfonso Airport",
    longitude: -71.660918,
    latitude: 8.624715,
    id: "arp_vig_ve",
    icao_code: "SVVG",
    iata_country_code: "VE",
    iata_code: "VIG",
    city_name: "El Vigía",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Juan Santamaria International Airport",
    longitude: -84.207967,
    latitude: 9.995738,
    id: "arp_sjo_cr",
    icao_code: "MROC",
    iata_country_code: "CR",
    iata_code: "SJO",
    city_name: "San Jose",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Juan Simons Vela Airport",
    longitude: -77.161344,
    latitude: -6.06672,
    id: "arp_rij_pe",
    icao_code: "SPJA",
    iata_country_code: "PE",
    iata_code: "RIJ",
    city_name: "Rioja",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Juan Vicente Gómez International Airport",
    longitude: -72.439692,
    latitude: 7.840552,
    id: "arp_svz_ve",
    icao_code: "SVSA",
    iata_country_code: "VE",
    iata_code: "SVZ",
    city_name: "San Antonio Tachira",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Juazeiro do Norte Airport",
    longitude: -39.271184,
    latitude: -7.217024,
    id: "arp_jdo_br",
    icao_code: "SBJU",
    iata_country_code: "BR",
    iata_code: "JDO",
    city_name: "Juazeiro Do Norte",
    city: null,
  },
  {
    time_zone: "Africa/Juba",
    name: "Juba International Airport",
    longitude: 31.601892,
    latitude: 4.869764,
    id: "arp_jub_ss",
    icao_code: "HSSJ",
    iata_country_code: "SS",
    iata_code: "JUB",
    city_name: "Juba",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Juína Airport",
    longitude: -58.709956,
    latitude: -11.419062,
    id: "arp_jia_br",
    icao_code: "SWJN",
    iata_country_code: "BR",
    iata_code: "JIA",
    city_name: "Juina",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Juist Airport",
    longitude: 7.055992,
    latitude: 53.680972,
    id: "arp_jui_de",
    icao_code: "EDWJ",
    iata_country_code: "DE",
    iata_code: "JUI",
    city_name: "Juist",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Julia Creek Airport",
    longitude: 141.722776,
    latitude: -20.6678,
    id: "arp_jck_au",
    icao_code: "YJLC",
    iata_country_code: "AU",
    iata_code: "JCK",
    city_name: "Julia Creek",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Julius Nyerere International Airport",
    longitude: 39.204757,
    latitude: -6.875324,
    id: "arp_dar_tz",
    icao_code: "HTDA",
    iata_country_code: "TZ",
    iata_code: "DAR",
    city_name: "Dar es Salaam",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Jumandy Airport",
    longitude: -77.583598,
    latitude: -1.058693,
    id: "arp_tnw_ec",
    icao_code: "SEJD",
    iata_country_code: "EC",
    iata_code: "TNW",
    city_name: "Tena",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Jumla Airport",
    longitude: 82.193306,
    latitude: 29.274279,
    id: "arp_jum_np",
    icao_code: "VNJL",
    iata_country_code: "NP",
    iata_code: "JUM",
    city_name: "Jumla",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Jundah Airport",
    longitude: 143.062385,
    latitude: -24.841409,
    id: "arp_jun_au",
    icao_code: "YJDA",
    iata_country_code: "AU",
    iata_code: "JUN",
    city_name: "Jundah",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Juneau International Airport",
    longitude: -134.579591,
    latitude: 58.35649,
    id: "arp_jnu_us",
    icao_code: "PAJN",
    iata_country_code: "US",
    iata_code: "JNU",
    city_name: "Juneau",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Jungwon Air Base Airport",
    longitude: 127.884291,
    latitude: 37.03018,
    id: "arp_jwo_kr",
    icao_code: null,
    iata_country_code: "KR",
    iata_code: "JWO",
    city_name: "Chungju",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Junin Airport",
    longitude: -60.929844,
    latitude: -34.54588,
    id: "arp_jni_ar",
    icao_code: "SAAJ",
    iata_country_code: "AR",
    iata_code: "JNI",
    city_name: "Junin",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Jurado Airport",
    longitude: -77.728566,
    latitude: 7.072344,
    id: "arp_juo_co",
    icao_code: "SKJU",
    iata_country_code: "CO",
    iata_code: "JUO",
    city_name: "Jurado",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Jurien Bay Airport",
    longitude: 115.055978,
    latitude: -30.301141,
    id: "arp_jur_au",
    icao_code: "YJNB",
    iata_country_code: "AU",
    iata_code: "JUR",
    city_name: "Jurien Bay",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Juruena Airport",
    longitude: -58.489444,
    latitude: -10.305832,
    id: "arp_jrn_br",
    icao_code: "SWJU",
    iata_country_code: "BR",
    iata_code: "JRN",
    city_name: "Juruena",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Juscelino Kubitscheck Airport",
    longitude: -41.513599,
    latitude: -17.892299,
    id: "arp_tfl_br",
    icao_code: "SNTO",
    iata_country_code: "BR",
    iata_code: "TFL",
    city_name: "Teófilo Otoni",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Juticalpa Airport",
    longitude: -85.774934,
    latitude: 14.875461,
    id: "arp_jut_hn",
    icao_code: "MHJU",
    iata_country_code: "HN",
    iata_code: "JUT",
    city_name: "Juticalpa",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Juwata Airport",
    longitude: 117.568092,
    latitude: 3.327223,
    id: "arp_trk_id",
    icao_code: "WAQQ",
    iata_country_code: "ID",
    iata_code: "TRK",
    city_name: "Truckee",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Jwaneng Airport",
    longitude: 24.69099,
    latitude: -24.602311,
    id: "arp_jwa_bw",
    icao_code: "FBJW",
    iata_country_code: "BW",
    iata_code: "JWA",
    city_name: "Jwaneng",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Jyvaskyla Airport",
    longitude: 25.678091,
    latitude: 62.401097,
    id: "arp_jyv_fi",
    icao_code: "EFJY",
    iata_country_code: "FI",
    iata_code: "JYV",
    city_name: "Jyväskylä",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Kaadedhdhoo Airport",
    longitude: 72.99603,
    latitude: 0.488138,
    id: "arp_kdm_mv",
    icao_code: "VRMT",
    iata_country_code: "MV",
    iata_code: "KDM",
    city_name: "Kaadedhdhoo Island",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Kabala Airport",
    longitude: -11.515235,
    latitude: 9.638043,
    id: "arp_kba_sl",
    icao_code: "GFKB",
    iata_country_code: "SL",
    iata_code: "KBA",
    city_name: "Kabala",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Kabalega Falls Airport",
    longitude: 31.499731,
    latitude: 2.328318,
    id: "arp_kbg_ug",
    icao_code: "HUKF",
    iata_country_code: "UG",
    iata_code: "KBG",
    city_name: "Kabalega Falls",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kabalo Airport",
    longitude: 26.916497,
    latitude: -6.081232,
    id: "arp_kbo_cd",
    icao_code: "FZRM",
    iata_country_code: "CD",
    iata_code: "KBO",
    city_name: "Kabalo",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Kaben Airport",
    longitude: 170.844719,
    latitude: 8.900751,
    id: "arp_kbt_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "KBT",
    city_name: "Kaben",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Kabri Dehar Airport",
    longitude: 44.252998,
    latitude: 6.734,
    id: "arp_abk_et",
    icao_code: "HAKD",
    iata_country_code: "ET",
    iata_code: "ABK",
    city_name: "Kebri Dehar",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kabwum Airport",
    longitude: 147.192363,
    latitude: -6.154886,
    id: "arp_kbm_pg",
    icao_code: "AYKB",
    iata_country_code: "PG",
    iata_code: "KBM",
    city_name: "Kabwum",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Kadanwari Airport",
    longitude: 69.153014,
    latitude: 27.200418,
    id: "arp_kcf_pk",
    icao_code: "OPKW",
    iata_country_code: "PK",
    iata_code: "KCF",
    city_name: "Kadanwari",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kadapa Airport",
    longitude: 78.767189,
    latitude: 14.517857,
    id: "arp_cdp_in",
    icao_code: "VOCP",
    iata_country_code: "IN",
    iata_code: "CDP",
    city_name: "Cuddapah",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kadena Air Base",
    longitude: 127.767957,
    latitude: 26.35563,
    id: "arp_dna_jp",
    icao_code: "RODN",
    iata_country_code: "JP",
    iata_code: "DNA",
    city_name: "Okinawa",
    city: {
      name: "Okinawa",
      id: "cit_oka_jp",
      iata_country_code: "JP",
      iata_code: "OKA",
    },
  },
  {
    time_zone: "Indian/Maldives",
    name: "Kadhdhoo Airport",
    longitude: 73.5213,
    latitude: 1.860217,
    id: "arp_kdo_mv",
    icao_code: "VRMK",
    iata_country_code: "MV",
    iata_code: "KDO",
    city_name: "Kadhdhoo Island",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Kadugli Airport",
    longitude: 29.701099,
    latitude: 11.137999,
    id: "arp_kdx_sd",
    icao_code: "HSLI",
    iata_country_code: "SD",
    iata_code: "KDX",
    city_name: "Kadugli",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Kaduna Airport",
    longitude: 7.321017,
    latitude: 10.695297,
    id: "arp_kad_ng",
    icao_code: "DNKA",
    iata_country_code: "NG",
    iata_code: "KAD",
    city_name: "Kaduna",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Kaédi Airport",
    longitude: -13.507599,
    latitude: 16.1595,
    id: "arp_ked_mr",
    icao_code: "GQNK",
    iata_country_code: "MR",
    iata_code: "KED",
    city_name: "Kaédi",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Kaélé Airport",
    longitude: 14.446591,
    latitude: 10.094247,
    id: "arp_kle_cm",
    icao_code: "FKKH",
    iata_country_code: "CM",
    iata_code: "KLE",
    city_name: "Kaélé",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Kaghau Airport",
    longitude: 157.585,
    latitude: -7.3305,
    id: "arp_kge_sb",
    icao_code: "AGKG",
    iata_country_code: "SB",
    iata_code: "KGE",
    city_name: "Kagau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kagi Airport",
    longitude: 147.669444,
    latitude: -9.135916,
    id: "arp_kgw_pg",
    icao_code: "AYKQ",
    iata_country_code: "PG",
    iata_code: "KGW",
    city_name: "Kagi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kagoshima Airport",
    longitude: 130.718508,
    latitude: 31.803126,
    id: "arp_koj_jp",
    icao_code: "RJFK",
    iata_country_code: "JP",
    iata_code: "KOJ",
    city_name: "Kagoshima",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kagua Airport",
    longitude: 143.854062,
    latitude: -6.396378,
    id: "arp_agk_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "AGK",
    city_name: "Kagua",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Kahramanmaraş Airport",
    longitude: 36.958348,
    latitude: 37.538306,
    id: "arp_kcm_tr",
    icao_code: "LTCN",
    iata_country_code: "TR",
    iata_code: "KCM",
    city_name: "Kahramanmaraş",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Kahului Airport",
    longitude: -156.431465,
    latitude: 20.897696,
    id: "arp_ogg_us",
    icao_code: "PHOG",
    iata_country_code: "US",
    iata_code: "OGG",
    city_name: "Kahului",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Kaieteur International Airport",
    longitude: -59.491482,
    latitude: 5.172755,
    id: "arp_kai_gy",
    icao_code: "SYKA",
    iata_country_code: "GY",
    iata_code: "KAI",
    city_name: "Kaieteur",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Kaikohe Airport",
    longitude: 173.816714,
    latitude: -35.451548,
    id: "arp_kko_nz",
    icao_code: "NZKO",
    iata_country_code: "NZ",
    iata_code: "KKO",
    city_name: "Kaikohe",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Kaikoura Airport",
    longitude: 173.603039,
    latitude: -42.425194,
    id: "arp_kbz_nz",
    icao_code: "NZKI",
    iata_country_code: "NZ",
    iata_code: "KBZ",
    city_name: "Kaikoura",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Kailashahar Airport",
    longitude: 92.006742,
    latitude: 24.30828,
    id: "arp_ixh_in",
    icao_code: "VEKR",
    iata_country_code: "IN",
    iata_code: "IXH",
    city_name: "Kailashahar",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Kaili Huangping Airport",
    longitude: 107.988,
    latitude: 26.972,
    id: "arp_kjh_cn",
    icao_code: "ZUKJ",
    iata_country_code: "CN",
    iata_code: "KJH",
    city_name: "Kaili",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kaimana Airport",
    longitude: 133.696503,
    latitude: -3.643179,
    id: "arp_kng_id",
    icao_code: "WASK",
    iata_country_code: "ID",
    iata_code: "KNG",
    city_name: "Kaimana",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kaintiba Airport",
    longitude: 146.033621,
    latitude: -7.500472,
    id: "arp_kzf_pg",
    icao_code: "AYKT",
    iata_country_code: "PG",
    iata_code: "KZF",
    city_name: "Kaintiba",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kairuku Airport",
    longitude: 146.525136,
    latitude: -8.815592,
    id: "arp_rku_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RKU",
    city_name: "Yule Island",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Kaitaia Airport",
    longitude: 173.285254,
    latitude: -35.068161,
    id: "arp_kat_nz",
    icao_code: "NZKT",
    iata_country_code: "NZ",
    iata_code: "KAT",
    city_name: "Kaitaia",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kajaani Airport",
    longitude: 27.686933,
    latitude: 64.284082,
    id: "arp_kaj_fi",
    icao_code: "EFKI",
    iata_country_code: "FI",
    iata_code: "KAJ",
    city_name: "Kajaani",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kakamega Airport",
    longitude: 34.558363,
    latitude: 0.578238,
    id: "arp_ggm_ke",
    icao_code: "HKKG",
    iata_country_code: "KE",
    iata_code: "GGM",
    city_name: "Kakamega",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Kake Airport",
    longitude: -133.94484,
    latitude: 56.973308,
    id: "arp_kae_us",
    icao_code: "PAFE",
    iata_country_code: "US",
    iata_code: "KAE",
    city_name: "Kake",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Kalabo Airport",
    longitude: 22.646413,
    latitude: -14.998353,
    id: "arp_klb_zm",
    icao_code: "FLKL",
    iata_country_code: "ZM",
    iata_code: "KLB",
    city_name: "Kalabo",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Kalaeloa Airport",
    longitude: -158.070292,
    latitude: 21.309004,
    id: "arp_jrf_us",
    icao_code: "PHJR",
    iata_country_code: "US",
    iata_code: "JRF",
    city_name: "Kapolei",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kalakaket Creek Air Station",
    longitude: -156.820393,
    latitude: 64.416626,
    id: "arp_kkk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KKK",
    city_name: "Kalakaket Creek",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Kalaleh Airport",
    longitude: 55.452,
    latitude: 37.383301,
    id: "arp_klm_ir",
    icao_code: "OINE",
    iata_country_code: "IR",
    iata_code: "KLM",
    city_name: "Kalaleh",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kalamata International Airport",
    longitude: 22.031249,
    latitude: 37.055203,
    id: "arp_klx_gr",
    icao_code: "LGKL",
    iata_country_code: "GR",
    iata_code: "KLX",
    city_name: "Kalamata",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Kalamazoo/Battle Creek International Airport",
    longitude: -85.5513,
    latitude: 42.234641,
    id: "arp_azo_us",
    icao_code: "KAZO",
    iata_country_code: "US",
    iata_code: "AZO",
    city_name: "Kalamazoo",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Kalaupapa Airport",
    longitude: -156.973634,
    latitude: 21.211017,
    id: "arp_lup_us",
    icao_code: "PHLU",
    iata_country_code: "US",
    iata_code: "LUP",
    city_name: "Kalaupapa",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Kalaymyo Airport",
    longitude: 94.051388,
    latitude: 23.188969,
    id: "arp_kmv_mm",
    icao_code: "VYKL",
    iata_country_code: "MM",
    iata_code: "KMV",
    city_name: "Kalemyo",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Kalbarri Airport",
    longitude: 114.260492,
    latitude: -27.689135,
    id: "arp_kax_au",
    icao_code: "YKBR",
    iata_country_code: "AU",
    iata_code: "KAX",
    city_name: "Kalbarri",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kalemie Airport",
    longitude: 29.247638,
    latitude: -5.876946,
    id: "arp_fmi_cd",
    icao_code: "FZRF",
    iata_country_code: "CD",
    iata_code: "FMI",
    city_name: "Kalemie",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Kalgoorlie-Boulder Airport",
    longitude: 121.460331,
    latitude: -30.78643,
    id: "arp_kgi_au",
    icao_code: "YPKG",
    iata_country_code: "AU",
    iata_code: "KGI",
    city_name: "Kalgoorlie",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Kalibo International Airport",
    longitude: 122.378598,
    latitude: 11.682917,
    id: "arp_klo_ph",
    icao_code: "RPVK",
    iata_country_code: "PH",
    iata_code: "KLO",
    city_name: "Kalibo",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Kalimarau Airport",
    longitude: 117.432661,
    latitude: 2.150621,
    id: "arp_bej_id",
    icao_code: "WALK",
    iata_country_code: "ID",
    iata_code: "BEJ",
    city_name: "Tanjung Redeb",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Kalkgurung Airport",
    longitude: 130.808206,
    latitude: -17.431329,
    id: "arp_kfg_au",
    icao_code: "YKKG",
    iata_country_code: "AU",
    iata_code: "KFG",
    city_name: "Kalkgurung",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Kalmar Airport",
    longitude: 16.287787,
    latitude: 56.681475,
    id: "arp_klr_se",
    icao_code: "ESMQ",
    iata_country_code: "SE",
    iata_code: "KLR",
    city_name: "Kalmar",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kalokol Airport",
    longitude: 35.839292,
    latitude: 3.489968,
    id: "arp_klk_ke",
    icao_code: "HKFG",
    iata_country_code: "KE",
    iata_code: "KLK",
    city_name: "Kalokol",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Kalpowar Airport",
    longitude: 144.22004,
    latitude: -14.889873,
    id: "arp_kpp_au",
    icao_code: "YKPR",
    iata_country_code: "AU",
    iata_code: "KPP",
    city_name: "Kalpowar",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kalskag Airport",
    longitude: -160.342502,
    latitude: 61.536096,
    id: "arp_klg_us",
    icao_code: "PALG",
    iata_country_code: "US",
    iata_code: "KLG",
    city_name: "Kalskag",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kaltag Airport",
    longitude: -158.738553,
    latitude: 64.320522,
    id: "arp_kal_us",
    icao_code: "PAKV",
    iata_country_code: "US",
    iata_code: "KAL",
    city_name: "Kaltag",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Kaluga Grabtsevo Airport",
    longitude: 36.367672,
    latitude: 54.549832,
    id: "arp_klf_ru",
    icao_code: "UUBC",
    iata_country_code: "RU",
    iata_code: "KLF",
    city_name: "Kaluga",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Kalumburu Airport",
    longitude: 126.633854,
    latitude: -14.288554,
    id: "arp_ubu_au",
    icao_code: "YKAL",
    iata_country_code: "AU",
    iata_code: "UBU",
    city_name: "Kalumburu",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kalymnos Island National Airport",
    longitude: 26.940578,
    latitude: 36.963316,
    id: "arp_jkl_gr",
    icao_code: "LGKY",
    iata_country_code: "GR",
    iata_code: "JKL",
    city_name: "Kalymnos",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kamalpur Airport",
    longitude: 91.812024,
    latitude: 24.134873,
    id: "arp_ixq_in",
    icao_code: "VEKM",
    iata_country_code: "IN",
    iata_code: "IXQ",
    city_name: "Kamalpur",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Kamaran Airport",
    longitude: 42.608298,
    latitude: 15.365416,
    id: "arp_kam_ye",
    icao_code: "OYKM",
    iata_country_code: "YE",
    iata_code: "KAM",
    city_name: "Kamaran",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Kamaran Downs Airport",
    longitude: 139.166667,
    latitude: -24.333333,
    id: "arp_kds_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "KDS",
    city_name: "Kamaran Downs",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Kamarang Airport",
    longitude: -60.614934,
    latitude: 5.865483,
    id: "arp_kar_gy",
    icao_code: "SYKM",
    iata_country_code: "GY",
    iata_code: "KAR",
    city_name: "Kamarang",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Kamarata Airport",
    longitude: -62.337833,
    latitude: 5.716432,
    id: "arp_ktv_ve",
    icao_code: "SVKM",
    iata_country_code: "VE",
    iata_code: "KTV",
    city_name: "Kamarata",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Kambalda Airport",
    longitude: 121.59887,
    latitude: -31.193491,
    id: "arp_kdb_au",
    icao_code: "YKBL",
    iata_country_code: "AU",
    iata_code: "KDB",
    city_name: "Kambalda",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kamberatoro Airport",
    longitude: 141.051861,
    latitude: -3.60081,
    id: "arp_kdq_pg",
    icao_code: "AYTO",
    iata_country_code: "PG",
    iata_code: "KDQ",
    city_name: "Kamberatoro",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kambuaya Airport",
    longitude: 132.285309,
    latitude: -1.317369,
    id: "arp_kbx_id",
    icao_code: "WASU",
    iata_country_code: "ID",
    iata_code: "KBX",
    city_name: "Ayamaru",
    city: null,
  },
  {
    time_zone: "Africa/Kigali",
    name: "Kamembe Airport",
    longitude: 28.907918,
    latitude: -2.461475,
    id: "arp_kme_rw",
    icao_code: "HRZA",
    iata_country_code: "RW",
    iata_code: "KME",
    city_name: "Kamembe",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kamianets-Podilskiy Airport",
    longitude: 26.608126,
    latitude: 48.693751,
    id: "arp_kcp_ua",
    icao_code: null,
    iata_country_code: "UA",
    iata_code: "KCP",
    city_name: "Kamianets-Podilskiy",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Kamileroi Airport",
    longitude: 140.057006,
    latitude: -19.375,
    id: "arp_kml_au",
    icao_code: "YKML",
    iata_country_code: "AU",
    iata_code: "KML",
    city_name: "Kamileroi",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kamina Airport",
    longitude: 24.992517,
    latitude: -8.729294,
    id: "arp_kmf_pg",
    icao_code: "AYKD",
    iata_country_code: "PG",
    iata_code: "KMF",
    city_name: "Kamina",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kamina Airport",
    longitude: 25.253255,
    latitude: -8.642121,
    id: "arp_kmn_cd",
    icao_code: "FZSB",
    iata_country_code: "CD",
    iata_code: "KMN",
    city_name: "Kamina",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kamiraba Airport",
    longitude: 151.25,
    latitude: -2.88333,
    id: "arp_kju_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KJU",
    city_name: "Kamiraba",
    city: null,
  },
  {
    time_zone: "Asia/Damascus",
    name: "Kamishly Airport",
    longitude: 41.203434,
    latitude: 37.03088,
    id: "arp_kac_sy",
    icao_code: "OSKL",
    iata_country_code: "SY",
    iata_code: "KAC",
    city_name: "Kamishly",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Kamloops Airport",
    longitude: -120.442861,
    latitude: 50.7037,
    id: "arp_yka_ca",
    icao_code: "CYKA",
    iata_country_code: "CA",
    iata_code: "YKA",
    city_name: "Kamloops",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Kamphaeng Saen Airport",
    longitude: 99.917198,
    latitude: 14.102,
    id: "arp_kdt_th",
    icao_code: "VTBK",
    iata_country_code: "TH",
    iata_code: "KDT",
    city_name: "Nakhon Pathom",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Kampong Chhnang Airport",
    longitude: 104.563945,
    latitude: 12.255225,
    id: "arp_kzc_kh",
    icao_code: "VDKH",
    iata_country_code: "KH",
    iata_code: "KZC",
    city_name: "Kampong Chhnang",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Kampot Airport",
    longitude: 104.161889,
    latitude: 10.634083,
    id: "arp_kmt_kh",
    icao_code: null,
    iata_country_code: "KH",
    iata_code: "KMT",
    city_name: "Kampot",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kamulai Airport",
    longitude: 146.834324,
    latitude: -8.150899,
    id: "arp_kaq_pg",
    icao_code: "AYKH",
    iata_country_code: "PG",
    iata_code: "KAQ",
    city_name: "Kamulai",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kamur Airport",
    longitude: 75.54936,
    latitude: 11.915126,
    id: "arp_kcd_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "KCD",
    city_name: "Kamur",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kamusi Airport",
    longitude: 143.121661,
    latitude: -7.420453,
    id: "arp_kuy_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KUY",
    city_name: "Kamusi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kanabea Airport",
    longitude: 145.905451,
    latitude: -7.53807,
    id: "arp_kex_pg",
    icao_code: "AYNB",
    iata_country_code: "PG",
    iata_code: "KEX",
    city_name: "Kanabea",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Kanab Municipal Airport",
    longitude: -112.531261,
    latitude: 37.010396,
    id: "arp_knb_us",
    icao_code: "KKNB",
    iata_country_code: "US",
    iata_code: "KNB",
    city_name: "Kanab",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kanainj Airport",
    longitude: 144.7072,
    latitude: -5.2903,
    id: "arp_kne_pg",
    icao_code: "AYKJ",
    iata_country_code: "PG",
    iata_code: "KNE",
    city_name: "Kanainj",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kananga Airport",
    longitude: 22.472269,
    latitude: -5.900638,
    id: "arp_kga_cd",
    icao_code: "FZUA",
    iata_country_code: "CD",
    iata_code: "KGA",
    city_name: "Kananga",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Kanas Airport",
    longitude: 86.999034,
    latitude: 48.222987,
    id: "arp_kji_cn",
    icao_code: "ZWKN",
    iata_country_code: "CN",
    iata_code: "KJI",
    city_name: "Burqin",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Kandahar International Airport",
    longitude: 65.848456,
    latitude: 31.505211,
    id: "arp_kdh_af",
    icao_code: "OAKN",
    iata_country_code: "AF",
    iata_code: "KDH",
    city_name: "Kandahar",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kandep Airport",
    longitude: 143.507308,
    latitude: -5.840451,
    id: "arp_kdp_pg",
    icao_code: "AYNN",
    iata_country_code: "PG",
    iata_code: "KDP",
    city_name: "Kandep",
    city: null,
  },
  {
    time_zone: "Africa/Porto-Novo",
    name: "Kandi Airport",
    longitude: 2.940402,
    latitude: 11.144886,
    id: "arp_kdc_bj",
    icao_code: "DBBK",
    iata_country_code: "BJ",
    iata_code: "KDC",
    city_name: "Kandi",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kandla Airport",
    longitude: 70.102158,
    latitude: 23.112135,
    id: "arp_ixy_in",
    icao_code: "VAKE",
    iata_country_code: "IN",
    iata_code: "IXY",
    city_name: "Kandla",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kandrian Airport",
    longitude: 149.547815,
    latitude: -6.196346,
    id: "arp_kdr_pg",
    icao_code: "AYKC",
    iata_country_code: "PG",
    iata_code: "KDR",
    city_name: "Kandrian",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Kaneohe Bay Marine Corps Air Station",
    longitude: -157.766495,
    latitude: 21.450195,
    id: "arp_ngf_us",
    icao_code: "PHNG",
    iata_country_code: "US",
    iata_code: "NGF",
    city_name: "Kaneohe",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Kangding Airport",
    longitude: 101.751808,
    latitude: 30.129611,
    id: "arp_kgt_cn",
    icao_code: "ZUKD",
    iata_country_code: "CN",
    iata_code: "KGT",
    city_name: "Kangding",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Kangerlussuaq Airport",
    longitude: -50.711688,
    latitude: 67.011445,
    id: "arp_sfj_gl",
    icao_code: "BGSF",
    iata_country_code: "GL",
    iata_code: "SFJ",
    city_name: "Kangerlussuaq",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kangiqsualujjuaq",
    longitude: -65.992787,
    latitude: 58.711394,
    id: "arp_xgr_ca",
    icao_code: "CYLU",
    iata_country_code: "CA",
    iata_code: "XGR",
    city_name: "Kangiqsualujjuaq",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kangiqsujuaq",
    longitude: -71.929305,
    latitude: 61.588555,
    id: "arp_ywb_ca",
    icao_code: "CYKG",
    iata_country_code: "CA",
    iata_code: "YWB",
    city_name: "Kangiqsujuaq",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kangirsuk Airport",
    longitude: -70.000547,
    latitude: 60.026312,
    id: "arp_ykg_ca",
    icao_code: "CYAS",
    iata_country_code: "CA",
    iata_code: "YKG",
    city_name: "Kangirsuk",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kaniama Airport",
    longitude: 24.155855,
    latitude: -7.602277,
    id: "arp_knm_cd",
    icao_code: "FZTK",
    iata_country_code: "CD",
    iata_code: "KNM",
    city_name: "Kaniama",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Kankan Airport",
    longitude: -9.227852,
    latitude: 10.448387,
    id: "arp_knn_gn",
    icao_code: "GUXN",
    iata_country_code: "GN",
    iata_code: "KNN",
    city_name: "Kankan",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Kankesanturai Airport",
    longitude: 80.070471,
    latitude: 9.792229,
    id: "arp_jaf_lk",
    icao_code: "VCCJ",
    iata_country_code: "LK",
    iata_code: "JAF",
    city_name: "Jaffna",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kannur International Airport",
    longitude: 75.547687,
    latitude: 11.917153,
    id: "arp_cnn_in",
    icao_code: "VOKN",
    iata_country_code: "IN",
    iata_code: "CNN",
    city_name: "Kannur",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kanpur Airport",
    longitude: 80.410918,
    latitude: 26.405186,
    id: "arp_knu_in",
    icao_code: null,
    iata_country_code: "IN",
    iata_code: "KNU",
    city_name: "Kanpur",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kansai International Airport",
    longitude: 135.233646,
    latitude: 34.437727,
    id: "arp_kix_jp",
    icao_code: "RJBB",
    iata_country_code: "JP",
    iata_code: "KIX",
    city_name: "Osaka",
    city: {
      name: "Osaka",
      id: "cit_osa_jp",
      iata_country_code: "JP",
      iata_code: "OSA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Kansas City International Airport",
    longitude: -94.712792,
    latitude: 39.29874,
    id: "arp_mci_us",
    icao_code: "KMCI",
    iata_country_code: "US",
    iata_code: "MCI",
    city_name: "Kansas City",
    city: {
      name: "Kansas City",
      id: "cit_mkc_us",
      iata_country_code: "US",
      iata_code: "MKC",
    },
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Kantchari Airport",
    longitude: 1.493304,
    latitude: 12.464387,
    id: "arp_xka_bf",
    icao_code: "DFEL",
    iata_country_code: "BF",
    iata_code: "XKA",
    city_name: "Kantchari",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kao Airport",
    longitude: 127.895503,
    latitude: 1.186014,
    id: "arp_kaz_id",
    icao_code: "WAMK",
    iata_country_code: "ID",
    iata_code: "KAZ",
    city_name: "Kau",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Kaohsiung International Airport",
    longitude: 120.347402,
    latitude: 22.575864,
    id: "arp_khh_tw",
    icao_code: "RCKH",
    iata_country_code: "TW",
    iata_code: "KHH",
    city_name: "Kaohsiung",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Kaolack Airport",
    longitude: -16.051413,
    latitude: 14.147377,
    id: "arp_klc_sn",
    icao_code: "GOOK",
    iata_country_code: "SN",
    iata_code: "KLC",
    city_name: "Kaolack",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Kaoma Airport",
    longitude: 24.808435,
    latitude: -14.797385,
    id: "arp_kmz_zm",
    icao_code: "FLKO",
    iata_country_code: "ZM",
    iata_code: "KMZ",
    city_name: "Kaoma",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kapal Airport",
    longitude: 142.85,
    latitude: -8.63333,
    id: "arp_kpl_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KPL",
    city_name: "Kapal",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Kapalua Airport",
    longitude: -156.672881,
    latitude: 20.963008,
    id: "arp_jhm_us",
    icao_code: "PHJH",
    iata_country_code: "US",
    iata_code: "JHM",
    city_name: "Lahaina",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kapanga Airport",
    longitude: 22.646782,
    latitude: -8.372413,
    id: "arp_kap_cd",
    icao_code: "FZSK",
    iata_country_code: "CD",
    iata_code: "KAP",
    city_name: "Kapanga",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Kapit Airport",
    longitude: 112.93152,
    latitude: 2.010479,
    id: "arp_kpi_my",
    icao_code: "WBGP",
    iata_country_code: "MY",
    iata_code: "KPI",
    city_name: "Kapit",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Kapiti Coast Airport",
    longitude: 174.986761,
    latitude: -40.902679,
    id: "arp_ppq_nz",
    icao_code: "NZPP",
    iata_country_code: "NZ",
    iata_code: "PPQ",
    city_name: "Paraparaumu",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kapuskasing Airport",
    longitude: -82.468556,
    latitude: 49.414406,
    id: "arp_yyu_ca",
    icao_code: "CYYU",
    iata_country_code: "CA",
    iata_code: "YYU",
    city_name: "Kapuskasing",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kar Airport",
    longitude: 143.483333,
    latitude: -6.233333,
    id: "arp_kak_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KAK",
    city_name: "Kar",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Karamay Airport",
    longitude: 84.956226,
    latitude: 45.467396,
    id: "arp_kry_cn",
    icao_code: "ZWKM",
    iata_country_code: "CN",
    iata_code: "KRY",
    city_name: "Karamay",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Karanambo Airport",
    longitude: -59.343367,
    latitude: 3.770705,
    id: "arp_krm_gy",
    icao_code: "SYKR",
    iata_country_code: "GY",
    iata_code: "KRM",
    city_name: "Karanambo",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Karara Airport",
    longitude: 116.686302,
    latitude: -29.217028,
    id: "arp_kqr_au",
    icao_code: "YKAR",
    iata_country_code: "AU",
    iata_code: "KQR",
    city_name: "Karara",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Karasabai Airport",
    longitude: -59.549915,
    latitude: 4.029832,
    id: "arp_krg_gy",
    icao_code: "SYKS",
    iata_country_code: "GY",
    iata_code: "KRG",
    city_name: "Karasabai",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Karasburg Airport",
    longitude: 18.738417,
    latitude: -28.02991,
    id: "arp_kas_na",
    icao_code: "FYKB",
    iata_country_code: "NA",
    iata_code: "KAS",
    city_name: "Karasburg",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Karato Airport",
    longitude: 155.35,
    latitude: -6.283333,
    id: "arp_kaf_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KAF",
    city_name: "Karato",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Karawari Airport",
    longitude: 143.523159,
    latitude: -4.597299,
    id: "arp_krj_pg",
    icao_code: "AYQA",
    iata_country_code: "PG",
    iata_code: "KRJ",
    city_name: "Karawari",
    city: null,
  },
  {
    time_zone: "Europe/Tallinn",
    name: "Kärdla Airport",
    longitude: 22.828908,
    latitude: 58.991706,
    id: "arp_kdl_ee",
    icao_code: "EEKA",
    iata_country_code: "EE",
    iata_code: "KDL",
    city_name: "Kärdla",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Karel Sadsuitubun Airport",
    longitude: 132.731003,
    latitude: -5.66162,
    id: "arp_luv_id",
    icao_code: "WAPL",
    iata_country_code: "ID",
    iata_code: "LUV",
    city_name: "Langgur",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Kariba Airport",
    longitude: 28.885014,
    latitude: -16.519625,
    id: "arp_kab_zw",
    icao_code: "FVKB",
    iata_country_code: "ZW",
    iata_code: "KAB",
    city_name: "Kariba",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Karimui Airport",
    longitude: 144.824867,
    latitude: -6.492968,
    id: "arp_kmr_pg",
    icao_code: "AYRI",
    iata_country_code: "PG",
    iata_code: "KMR",
    city_name: "Karimui",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kar Kar Airport",
    longitude: 145.940493,
    latitude: -4.556955,
    id: "arp_krx_pg",
    icao_code: "AYKR",
    iata_country_code: "PG",
    iata_code: "KRX",
    city_name: "Karkar Island",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Karlovy Vary Airport",
    longitude: 12.915358,
    latitude: 50.202234,
    id: "arp_klv_cz",
    icao_code: "LKKV",
    iata_country_code: "CZ",
    iata_code: "KLV",
    city_name: "Karlovy Vary",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Karlskoga Airport",
    longitude: 14.494464,
    latitude: 59.344104,
    id: "arp_ksk_se",
    icao_code: "ESKK",
    iata_country_code: "SE",
    iata_code: "KSK",
    city_name: "Karlskoga",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Karlsruhe/Baden-Baden Airport",
    longitude: 8.084817,
    latitude: 48.779068,
    id: "arp_fkb_de",
    icao_code: "EDSB",
    iata_country_code: "DE",
    iata_code: "FKB",
    city_name: "Karlsruhe",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Karlstad Airport",
    longitude: 13.341086,
    latitude: 59.444216,
    id: "arp_ksd_se",
    icao_code: "ESOK",
    iata_country_code: "SE",
    iata_code: "KSD",
    city_name: "Karlstad",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Karluk Airport",
    longitude: -154.45194,
    latitude: 57.566593,
    id: "arp_kyk_us",
    icao_code: "PAKY",
    iata_country_code: "US",
    iata_code: "KYK",
    city_name: "Karluk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Karluk Lake Seaplane Base",
    longitude: -154.027795,
    latitude: 57.367018,
    id: "arp_kkl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KKL",
    city_name: "Karluk Lake",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Karonga Airport",
    longitude: 33.89304,
    latitude: -9.953788,
    id: "arp_kgj_mw",
    icao_code: "FWKA",
    iata_country_code: "MW",
    iata_code: "KGJ",
    city_name: "Karonga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Karoola Airport",
    longitude: 154.599,
    latitude: -5.27714,
    id: "arp_kxr_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KXR",
    city_name: "Karoola",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Karpathos Island National Airport",
    longitude: 27.146461,
    latitude: 35.420915,
    id: "arp_aok_gr",
    icao_code: "LGKP",
    iata_country_code: "GR",
    iata_code: "AOK",
    city_name: "Karpathos",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Karratha Airport",
    longitude: 116.77156,
    latitude: -20.71088,
    id: "arp_kta_au",
    icao_code: "YPKA",
    iata_country_code: "AU",
    iata_code: "KTA",
    city_name: "Karratha",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Kars Harakani Airport",
    longitude: 43.113803,
    latitude: 40.561865,
    id: "arp_ksy_tr",
    icao_code: "LTCF",
    iata_country_code: "TR",
    iata_code: "KSY",
    city_name: "Kars",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Karshi Airport",
    longitude: 65.771966,
    latitude: 38.810244,
    id: "arp_ksq_uz",
    icao_code: "UTSK",
    iata_country_code: "UZ",
    iata_code: "KSQ",
    city_name: "Khanabad",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Karubaga Airport",
    longitude: 138.479185,
    latitude: -3.686662,
    id: "arp_kbf_id",
    icao_code: "WABK",
    iata_country_code: "ID",
    iata_code: "KBF",
    city_name: "Karubaga",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Karumba Airport",
    longitude: 140.830947,
    latitude: -17.456147,
    id: "arp_krb_au",
    icao_code: "YKMB",
    iata_country_code: "AU",
    iata_code: "KRB",
    city_name: "Karumba",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Kasaan Seaplane Base",
    longitude: -132.397754,
    latitude: 55.537406,
    id: "arp_kxa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KXA",
    city_name: "Kasaan",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Kasaba Bay Airport",
    longitude: 30.663761,
    latitude: -8.524282,
    id: "arp_zkb_zm",
    icao_code: "FLKY",
    iata_country_code: "ZM",
    iata_code: "ZKB",
    city_name: "Kasaba Bay",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kasabonika Airport",
    longitude: -88.642788,
    latitude: 53.524711,
    id: "arp_xks_ca",
    icao_code: "CYAQ",
    iata_country_code: "CA",
    iata_code: "XKS",
    city_name: "Kasabonika",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Kasama Airport",
    longitude: 31.13331,
    latitude: -10.216686,
    id: "arp_kaa_zm",
    icao_code: "FLKS",
    iata_country_code: "ZM",
    iata_code: "KAA",
    city_name: "Kasama",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Kasane Airport",
    longitude: 25.162839,
    latitude: -17.832132,
    id: "arp_bbk_bw",
    icao_code: "FBKE",
    iata_country_code: "BW",
    iata_code: "BBK",
    city_name: "Kasane",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kasanombe Airport",
    longitude: 146.725972,
    latitude: -6.569807,
    id: "arp_ksb_pg",
    icao_code: "AYNM",
    iata_country_code: "PG",
    iata_code: "KSB",
    city_name: "Kasanombe",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Kasba Lake Airport",
    longitude: -102.503721,
    latitude: 60.291174,
    id: "arp_ydu_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YDU",
    city_name: "Kasba Lake",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kasenga Airport",
    longitude: 28.616506,
    latitude: -10.355292,
    id: "arp_kec_cd",
    icao_code: "FZQG",
    iata_country_code: "CD",
    iata_code: "KEC",
    city_name: "Kasenga",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Kasese Airport",
    longitude: 30.100813,
    latitude: 0.185675,
    id: "arp_kse_ug",
    icao_code: "HUKS",
    iata_country_code: "UG",
    iata_code: "KSE",
    city_name: "Kasese",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Kashan Airport",
    longitude: 51.574719,
    latitude: 33.895086,
    id: "arp_kks_ir",
    icao_code: null,
    iata_country_code: "IR",
    iata_code: "KKS",
    city_name: "Kashan",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kashechewan Airport",
    longitude: -81.676655,
    latitude: 52.28212,
    id: "arp_zke_ca",
    icao_code: "CZKE",
    iata_country_code: "CA",
    iata_code: "ZKE",
    city_name: "Kashechewan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Kashgar Airport",
    longitude: 76.011209,
    latitude: 39.536347,
    id: "arp_khg_cn",
    icao_code: "ZWSH",
    iata_country_code: "CN",
    iata_code: "KHG",
    city_name: "Kashgar",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Kasigluk Airport",
    longitude: -162.523879,
    latitude: 60.874639,
    id: "arp_kuk_us",
    icao_code: "PFKA",
    iata_country_code: "US",
    iata_code: "KUK",
    city_name: "Kasigluk",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Kasiguncu Airport",
    longitude: 120.657833,
    latitude: -1.416751,
    id: "arp_psj_id",
    icao_code: "WAMP",
    iata_country_code: "ID",
    iata_code: "PSJ",
    city_name: "Poso",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Kasompe Airport",
    longitude: 27.893654,
    latitude: -12.572435,
    id: "arp_cgj_zm",
    icao_code: "FLKE",
    iata_country_code: "ZM",
    iata_code: "CGJ",
    city_name: "Chingola",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Kasongo-Lunda Airport",
    longitude: 16.8167,
    latitude: -6.46667,
    id: "arp_kgn_cd",
    icao_code: "FZOK",
    iata_country_code: "CD",
    iata_code: "KGN",
    city_name: "Kasongo Lunda",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kasos Island Public Airport",
    longitude: 26.912175,
    latitude: 35.420929,
    id: "arp_ksj_gr",
    icao_code: "LGKS",
    iata_country_code: "GR",
    iata_code: "KSJ",
    city_name: "Kasos Island",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Kassala Airport",
    longitude: 36.328909,
    latitude: 15.386705,
    id: "arp_ksl_sd",
    icao_code: "HSKA",
    iata_country_code: "SD",
    iata_code: "KSL",
    city_name: "Kassala",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Kassel Airport",
    longitude: 9.385317,
    latitude: 51.416906,
    id: "arp_ksf_de",
    icao_code: "EDVK",
    iata_country_code: "DE",
    iata_code: "KSF",
    city_name: "Kassel",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Kastamonu Airport",
    longitude: 33.796565,
    latitude: 41.315534,
    id: "arp_kfs_tr",
    icao_code: "LTAL",
    iata_country_code: "TR",
    iata_code: "KFS",
    city_name: "Kastamonu",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kastellorizo Island Public Airport",
    longitude: 29.576456,
    latitude: 36.142022,
    id: "arp_kzs_gr",
    icao_code: "LGKJ",
    iata_country_code: "GR",
    iata_code: "KZS",
    city_name: "Megisti",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kastoria National Airport",
    longitude: 21.279254,
    latitude: 40.447775,
    id: "arp_kso_gr",
    icao_code: "LGKA",
    iata_country_code: "GR",
    iata_code: "KSO",
    city_name: "Kastoria",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Kasungu Airport",
    longitude: 33.468015,
    latitude: -13.015007,
    id: "arp_kbq_mw",
    icao_code: "FWKG",
    iata_country_code: "MW",
    iata_code: "KBQ",
    city_name: "Kasungu",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Katanning Airport",
    longitude: 117.655322,
    latitude: -33.699052,
    id: "arp_kni_au",
    icao_code: "YKNG",
    iata_country_code: "AU",
    iata_code: "KNI",
    city_name: "Katanning",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Katherine Tindal Airport",
    longitude: 132.378006,
    latitude: -14.5211,
    id: "arp_ktr_au",
    icao_code: "YPTN",
    iata_country_code: "AU",
    iata_code: "KTR",
    city_name: "Katherine",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Katima Mulilo Airport",
    longitude: 24.17741,
    latitude: -17.632827,
    id: "arp_mpa_na",
    icao_code: "FYKM",
    iata_country_code: "NA",
    iata_code: "MPA",
    city_name: "Katima Mulilo",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Katiola Airport",
    longitude: -5.066355,
    latitude: 8.138288,
    id: "arp_ktc_ci",
    icao_code: null,
    iata_country_code: "CI",
    iata_code: "KTC",
    city_name: "Katiola",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Katiu Airport",
    longitude: -144.403243,
    latitude: -16.340222,
    id: "arp_kxu_pf",
    icao_code: "NTKT",
    iata_country_code: "PF",
    iata_code: "KXU",
    city_name: "Katiu",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Kato Airport",
    longitude: -59.830298,
    latitude: 4.64984,
    id: "arp_kto_gy",
    icao_code: "SYKT",
    iata_country_code: "GY",
    iata_code: "KTO",
    city_name: "Kato",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Katowice International Airport",
    longitude: 19.07794,
    latitude: 50.47355,
    id: "arp_ktw_pl",
    icao_code: "EPKT",
    iata_country_code: "PL",
    iata_code: "KTW",
    city_name: "Katowice",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Katsina Airport",
    longitude: 7.660626,
    latitude: 13.005756,
    id: "arp_dka_ng",
    icao_code: null,
    iata_country_code: "NG",
    iata_code: "DKA",
    city_name: "Katsina",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kattiniq/Donaldson Airport",
    longitude: -73.320785,
    latitude: 61.661985,
    id: "arp_yau_ca",
    icao_code: "CYAU",
    iata_country_code: "CA",
    iata_code: "YAU",
    city_name: "Kattiniq",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Katugastota Airport",
    longitude: 80.642143,
    latitude: 7.325044,
    id: "arp_kdz_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "KDZ",
    city_name: "Kandy",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Katukurunda Airport",
    longitude: 79.976578,
    latitude: 6.553261,
    id: "arp_kty_lk",
    icao_code: "VCCN",
    iata_country_code: "LK",
    iata_code: "KTY",
    city_name: "Kalutara",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Kauehi Airport",
    longitude: -145.124532,
    latitude: -15.780643,
    id: "arp_khz_pf",
    icao_code: "NTKA",
    iata_country_code: "PF",
    iata_code: "KHZ",
    city_name: "Kauehi",
    city: null,
  },
  {
    time_zone: "Pacific/Tongatapu",
    name: "Kaufana Airport",
    longitude: -174.957701,
    latitude: -21.377878,
    id: "arp_eua_to",
    icao_code: "NFTE",
    iata_country_code: "TO",
    iata_code: "EUA",
    city_name: "Eua",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kauhajoki Airport",
    longitude: 22.392637,
    latitude: 62.463021,
    id: "arp_khj_fi",
    icao_code: "EFKJ",
    iata_country_code: "FI",
    iata_code: "KHJ",
    city_name: "Kauhajoki",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kauhava Airport",
    longitude: 23.053412,
    latitude: 63.124867,
    id: "arp_kau_fi",
    icao_code: "EFKA",
    iata_country_code: "FI",
    iata_code: "KAU",
    city_name: "Kauhava",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Kaukura Airport",
    longitude: -146.885551,
    latitude: -15.664983,
    id: "arp_kkr_pf",
    icao_code: "NTGK",
    iata_country_code: "PF",
    iata_code: "KKR",
    city_name: "Kaukura",
    city: null,
  },
  {
    time_zone: "Europe/Vilnius",
    name: "Kaunas International Airport",
    longitude: 24.079525,
    latitude: 54.964848,
    id: "arp_kun_lt",
    icao_code: "EYKA",
    iata_country_code: "LT",
    iata_code: "KUN",
    city_name: "Kaunas",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Kavadja Airport",
    longitude: 23.527809,
    latitude: 7.965031,
    id: "arp_kwd_cf",
    icao_code: null,
    iata_country_code: "CF",
    iata_code: "KWD",
    city_name: "Kavadja",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kavala International Airport",
    longitude: 24.620466,
    latitude: 40.915959,
    id: "arp_kva_gr",
    icao_code: "LGKV",
    iata_country_code: "GR",
    iata_code: "KVA",
    city_name: "Kavala",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Kavalerovo Airport",
    longitude: 135.029,
    latitude: 44.2726,
    id: "arp_kvr_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "KVR",
    city_name: "Kavalerovo",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Kavanayén Airport",
    longitude: -60.61667,
    latitude: 5.883333,
    id: "arp_kav_ve",
    icao_code: "SVKA",
    iata_country_code: "VE",
    iata_code: "KAV",
    city_name: "Kanavayen",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kavieng Airport",
    longitude: 150.807213,
    latitude: -2.579003,
    id: "arp_kvg_pg",
    icao_code: "AYKV",
    iata_country_code: "PG",
    iata_code: "KVG",
    city_name: "Kavieng",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kavumu Airport",
    longitude: 28.808383,
    latitude: -2.308333,
    id: "arp_bky_cd",
    icao_code: "FZMA",
    iata_country_code: "CD",
    iata_code: "BKY",
    city_name: "Bukavu",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Kawama Airport",
    longitude: -81.302545,
    latitude: 23.123753,
    id: "arp_vro_cu",
    icao_code: "MUKW",
    iata_country_code: "CU",
    iata_code: "VRO",
    city_name: "Varadero",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kawito Airport",
    longitude: 142.823138,
    latitude: -7.978686,
    id: "arp_kwo_pg",
    icao_code: "AYKW",
    iata_country_code: "PG",
    iata_code: "KWO",
    city_name: "Kawito",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Kawthaung Airport",
    longitude: 98.53864,
    latitude: 10.050044,
    id: "arp_kaw_mm",
    icao_code: "VYKT",
    iata_country_code: "MM",
    iata_code: "KAW",
    city_name: "Kawthaung",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Kaya Airport",
    longitude: -1.099892,
    latitude: 13.07804,
    id: "arp_xky_bf",
    icao_code: "DFCA",
    iata_country_code: "BF",
    iata_code: "XKY",
    city_name: "Kaya",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Kayenta Airport",
    longitude: -67.478504,
    latitude: 44.702949,
    id: "arp_mvm_us",
    icao_code: "KMVM",
    iata_country_code: "US",
    iata_code: "MVM",
    city_name: "Kayenta",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Kayes Airport",
    longitude: -11.402975,
    latitude: 14.480513,
    id: "arp_kys_ml",
    icao_code: "GAKY",
    iata_country_code: "ML",
    iata_code: "KYS",
    city_name: "Kayes",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Kayseri Erkilet Airport",
    longitude: 35.494668,
    latitude: 38.771071,
    id: "arp_asr_tr",
    icao_code: "LTAU",
    iata_country_code: "TR",
    iata_code: "ASR",
    city_name: "Kayseri",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Kazan International Airport",
    longitude: 49.281206,
    latitude: 55.609823,
    id: "arp_kzn_ru",
    icao_code: "UWKD",
    iata_country_code: "RU",
    iata_code: "KZN",
    city_name: "Kazan",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kazi Nazrul Islam Airport",
    longitude: 87.242046,
    latitude: 23.62096,
    id: "arp_rdp_in",
    icao_code: "VEDG",
    iata_country_code: "IN",
    iata_code: "RDP",
    city_name: "Durgapur",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kearney Regional Airport",
    longitude: -99.006784,
    latitude: 40.727019,
    id: "arp_ear_us",
    icao_code: "KEAR",
    iata_country_code: "US",
    iata_code: "EAR",
    city_name: "Kearney",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kebar Airport",
    longitude: 133.054335,
    latitude: -0.81039,
    id: "arp_keq_id",
    icao_code: "WASE",
    iata_country_code: "ID",
    iata_code: "KEQ",
    city_name: "Kebar",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Kédougou Airport",
    longitude: -12.21876,
    latitude: 12.571566,
    id: "arp_kgg_sn",
    icao_code: "GOTK",
    iata_country_code: "SN",
    iata_code: "KGG",
    city_name: "Kédougou",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Keekorok Airport",
    longitude: 35.258238,
    latitude: -1.585896,
    id: "arp_keu_ke",
    icao_code: "HKKE",
    iata_country_code: "KE",
    iata_code: "KEU",
    city_name: "Masai Mara",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Keesler Air Force Base",
    longitude: -88.924689,
    latitude: 30.410072,
    id: "arp_bix_us",
    icao_code: "KBIX",
    iata_country_code: "US",
    iata_code: "BIX",
    city_name: "Gulfport/Biloxi",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Keetmanshoop Airport",
    longitude: 18.111395,
    latitude: -26.538664,
    id: "arp_kmp_na",
    icao_code: "FYKT",
    iata_country_code: "NA",
    iata_code: "KMP",
    city_name: "Keetmanshoop",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Keewaywin Airport",
    longitude: -92.838617,
    latitude: 52.991939,
    id: "arp_kew_ca",
    icao_code: "CPV8",
    iata_country_code: "CA",
    iata_code: "KEW",
    city_name: "Keewaywin",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kefalonia Cephalonia International Airport",
    longitude: 20.502716,
    latitude: 38.119811,
    id: "arp_efl_gr",
    icao_code: "LGKF",
    iata_country_code: "GR",
    iata_code: "EFL",
    city_name: "Kefallinia",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Keflavík International Airport",
    longitude: -22.634763,
    latitude: 63.977844,
    id: "arp_kef_is",
    icao_code: "BIKF",
    iata_country_code: "IS",
    iata_code: "KEF",
    city_name: "Keflavík",
    city: {
      name: "Reykjavik",
      id: "cit_rek_is",
      iata_country_code: "IS",
      iata_code: "REK",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Kegaska Airport",
    longitude: -61.265816,
    latitude: 50.195816,
    id: "arp_zkg_ca",
    icao_code: "CTK6",
    iata_country_code: "CA",
    iata_code: "ZKG",
    city_name: "Kegaska",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kegelman Air Force Auxiliary Field",
    longitude: -98.124603,
    latitude: 36.740997,
    id: "arp_cka_us",
    icao_code: "KCKA",
    iata_country_code: "US",
    iata_code: "CKA",
    city_name: "Cherokee",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Keglsugl Airport",
    longitude: 145.097222,
    latitude: -5.832777,
    id: "arp_keg_pg",
    icao_code: "AYLG",
    iata_country_code: "PG",
    iata_code: "KEG",
    city_name: "Keglsugl",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Keibane Airport",
    longitude: -7.261336,
    latitude: 15.228088,
    id: "arp_nrm_ml",
    icao_code: "GANK",
    iata_country_code: "ML",
    iata_code: "NRM",
    city_name: "Nara",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Kelafo Airport",
    longitude: 44.349667,
    latitude: 5.65684,
    id: "arp_lfo_et",
    icao_code: "HAKL",
    iata_country_code: "ET",
    iata_code: "LFO",
    city_name: "Kelafo",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Kelaniya River Airport",
    longitude: 80.55,
    latitude: 6.85,
    id: "arp_kez_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "KEZ",
    city_name: "Colombo",
    city: {
      name: "Colombo",
      id: "cit_cmb_lk",
      iata_country_code: "LK",
      iata_code: "CMB",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kelanoa Airport",
    longitude: 147.5,
    latitude: -6.0,
    id: "arp_knl_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KNL",
    city_name: "Kelanoa",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kelila Airport",
    longitude: 138.709071,
    latitude: -3.730066,
    id: "arp_lln_id",
    icao_code: "WAJV",
    iata_country_code: "ID",
    iata_code: "LLN",
    city_name: "Kelila",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Kelle Airport",
    longitude: 14.503949,
    latitude: -0.069019,
    id: "arp_kee_cg",
    icao_code: "FCOK",
    iata_country_code: "CG",
    iata_code: "KEE",
    city_name: "Kelle",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Kelowna International Airport",
    longitude: -119.378364,
    latitude: 49.956514,
    id: "arp_ylw_ca",
    icao_code: "CYLW",
    iata_country_code: "CA",
    iata_code: "YLW",
    city_name: "Kelowna",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Kelsey Airport",
    longitude: -96.509666,
    latitude: 56.037596,
    id: "arp_kes_ca",
    icao_code: "CZEE",
    iata_country_code: "CA",
    iata_code: "KES",
    city_name: "Kelsey",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Keluang Airport",
    longitude: 103.312295,
    latitude: 2.042518,
    id: "arp_klq_id",
    icao_code: "WIPV",
    iata_country_code: "ID",
    iata_code: "KLQ",
    city_name: "Keluang",
    city: null,
  },
  {
    time_zone: "Asia/Novokuznetsk",
    name: "Kemerovo Airport",
    longitude: 86.107413,
    latitude: 55.270089,
    id: "arp_kej_ru",
    icao_code: "UNEE",
    iata_country_code: "RU",
    iata_code: "KEJ",
    city_name: "Kemerovo",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kemi-Tornio Airport",
    longitude: 24.581285,
    latitude: 65.779386,
    id: "arp_kem_fi",
    icao_code: "EFKE",
    iata_country_code: "FI",
    iata_code: "KEM",
    city_name: "Kemi",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Kemmerer Municipal Airport",
    longitude: -110.558029,
    latitude: 41.824523,
    id: "arp_emm_us",
    icao_code: "KEMM",
    iata_country_code: "US",
    iata_code: "EMM",
    city_name: "Kemmerer",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Kempegowda International Airport",
    longitude: 146.268893,
    latitude: -6.273117,
    id: "arp_kia_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KIA",
    city_name: "Kaiapit",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Kempsey Airport",
    longitude: 152.767467,
    latitude: -31.071698,
    id: "arp_kps_au",
    icao_code: "YKMP",
    iata_country_code: "AU",
    iata_code: "KPS",
    city_name: "Kempsey",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kenai Municipal Airport",
    longitude: -151.24631,
    latitude: 60.572064,
    id: "arp_ena_us",
    icao_code: "PAEN",
    iata_country_code: "US",
    iata_code: "ENA",
    city_name: "Kenai",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Kendari Haluoleo Airport",
    longitude: 122.417814,
    latitude: -4.079443,
    id: "arp_kdi_id",
    icao_code: "WAWW",
    iata_country_code: "ID",
    iata_code: "KDI",
    city_name: "Kendari",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Kenema Airport",
    longitude: -11.176128,
    latitude: 7.892154,
    id: "arp_ken_sl",
    icao_code: "GFKE",
    iata_country_code: "SL",
    iata_code: "KEN",
    city_name: "Kenema",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Kengtung Airport",
    longitude: 99.63431,
    latitude: 21.302489,
    id: "arp_ket_mm",
    icao_code: "VYKG",
    iata_country_code: "MM",
    iata_code: "KET",
    city_name: "Kengtung",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Kenieba Airport",
    longitude: -11.252844,
    latitude: 12.839553,
    id: "arp_knz_ml",
    icao_code: "GAKA",
    iata_country_code: "ML",
    iata_code: "KNZ",
    city_name: "Kenieba",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Keningau Airport",
    longitude: 116.164463,
    latitude: 5.355071,
    id: "arp_kgu_my",
    icao_code: "WBKG",
    iata_country_code: "MY",
    iata_code: "KGU",
    city_name: "Keningau",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Kenitra Airport",
    longitude: -6.595494,
    latitude: 34.298804,
    id: "arp_nna_ma",
    icao_code: "GMMY",
    iata_country_code: "MA",
    iata_code: "NNA",
    city_name: "Kenitra",
    city: null,
  },
  {
    time_zone: "America/Jamaica",
    name: "Ken Jones Airport",
    longitude: -76.534855,
    latitude: 18.198729,
    id: "arp_pot_jm",
    icao_code: "MKKJ",
    iata_country_code: "JM",
    iata_code: "POT",
    city_name: "Port Antonio",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Kenmore Air Harbor Airport",
    longitude: -122.258274,
    latitude: 47.756214,
    id: "arp_keh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KEH",
    city_name: "Kenmore",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Kenmore Air Harbor Seaplane Base",
    longitude: -122.339413,
    latitude: 47.628788,
    id: "arp_lke_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "LKE",
    city_name: "Kenmore",
    city: {
      name: "Seattle",
      id: "cit_sea_us",
      iata_country_code: "US",
      iata_code: "SEA",
    },
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Kenneth Kaunda International Airport",
    longitude: 28.45139,
    latitude: -15.329401,
    id: "arp_lun_zm",
    icao_code: "FLKK",
    iata_country_code: "ZM",
    iata_code: "LUN",
    city_name: "Lusaka",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kennett Memorial Airport",
    longitude: -90.036301,
    latitude: 36.228083,
    id: "arp_knt_us",
    icao_code: "KTKX",
    iata_country_code: "US",
    iata_code: "KNT",
    city_name: "Kennett",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Kenora Airport",
    longitude: -94.363691,
    latitude: 49.787952,
    id: "arp_yqk_ca",
    icao_code: "CYQK",
    iata_country_code: "CA",
    iata_code: "YQK",
    city_name: "Kenora",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kenosha Regional Airport",
    longitude: -87.92715,
    latitude: 42.594107,
    id: "arp_enw_us",
    icao_code: "KENW",
    iata_country_code: "US",
    iata_code: "ENW",
    city_name: "Kenosha",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Kent International Airport",
    longitude: 1.349173,
    latitude: 51.344052,
    id: "arp_mse_gb",
    icao_code: "EGMH",
    iata_country_code: "GB",
    iata_code: "MSE",
    city_name: "Manston",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kentland Municipal Airport",
    longitude: -87.432369,
    latitude: 40.759554,
    id: "arp_kkt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KKT",
    city_name: "Kentland",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Keokuk Municipal Airport",
    longitude: -91.428081,
    latitude: 40.460048,
    id: "arp_eok_us",
    icao_code: "KEOK",
    iata_country_code: "US",
    iata_code: "EOK",
    city_name: "Keokuk",
    city: null,
  },
  {
    time_zone: "Asia/Anadyr",
    name: "Keperveyem Airport",
    longitude: 166.160281,
    latitude: 67.845435,
    id: "arp_kpw_ru",
    icao_code: "UHMK",
    iata_country_code: "RU",
    iata_code: "KPW",
    city_name: "Keperveyem",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kepi Airport",
    longitude: 139.332181,
    latitude: -6.543155,
    id: "arp_kei_id",
    icao_code: "WAKP",
    iata_country_code: "ID",
    iata_code: "KEI",
    city_name: "Kepi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kerama Airport",
    longitude: 127.293153,
    latitude: 26.169053,
    id: "arp_kjp_jp",
    icao_code: "ROKR",
    iata_country_code: "JP",
    iata_code: "KJP",
    city_name: "Kerama",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Kerang Airport",
    longitude: 143.93939,
    latitude: -35.751626,
    id: "arp_kra_au",
    icao_code: "YKER",
    iata_country_code: "AU",
    iata_code: "KRA",
    city_name: "Kerang",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kerau Airport",
    longitude: 147.071504,
    latitude: -8.27022,
    id: "arp_kru_pg",
    icao_code: "AYEA",
    iata_country_code: "PG",
    iata_code: "KRU",
    city_name: "Kerau",
    city: null,
  },
  {
    time_zone: "Europe/Simferopol",
    name: "Kerch Airport",
    longitude: 36.400166,
    latitude: 45.373035,
    id: "arp_khc_ua",
    icao_code: "UKFK",
    iata_country_code: "UA",
    iata_code: "KHC",
    city_name: "Kerch",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kerema Airport",
    longitude: 145.771192,
    latitude: -7.963513,
    id: "arp_kma_pg",
    icao_code: "AYKM",
    iata_country_code: "PG",
    iata_code: "KMA",
    city_name: "Kerema",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kericho Airport",
    longitude: 35.244733,
    latitude: -0.386293,
    id: "arp_key_ke",
    icao_code: "HKKR",
    iata_country_code: "KE",
    iata_code: "KEY",
    city_name: "Kericho",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Kerikeri Airport",
    longitude: 173.912052,
    latitude: -35.263071,
    id: "arp_kke_nz",
    icao_code: "NZKK",
    iata_country_code: "NZ",
    iata_code: "KKE",
    city_name: "Kerikeri",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Kerinci Airport",
    longitude: 101.466723,
    latitude: -2.092437,
    id: "arp_krc_id",
    icao_code: "WIPH",
    iata_country_code: "ID",
    iata_code: "KRC",
    city_name: "Kerinci",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kerio Valley Airport",
    longitude: 35.664534,
    latitude: 0.319649,
    id: "arp_krv_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "KRV",
    city_name: "Kimwarer",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Kerman Airport",
    longitude: 56.961012,
    latitude: 30.258055,
    id: "arp_ker_ir",
    icao_code: "OIKK",
    iata_country_code: "IR",
    iata_code: "KER",
    city_name: "Kerman",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kerrville Municipal Airport",
    longitude: -99.086713,
    latitude: 29.976832,
    id: "arp_erv_us",
    icao_code: "KERV",
    iata_country_code: "US",
    iata_code: "ERV",
    city_name: "Kerrville",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Kerry Airport",
    longitude: -9.527097,
    latitude: 52.181005,
    id: "arp_kir_ie",
    icao_code: "EIKY",
    iata_country_code: "IE",
    iata_code: "KIR",
    city_name: "Kerry",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Kertajati International Airport",
    longitude: 108.171846,
    latitude: -6.656283,
    id: "arp_kjt_id",
    icao_code: "WICA",
    iata_country_code: "ID",
    iata_code: "KJT",
    city_name: "Majalengka",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Kerteh Airport",
    longitude: 103.42797,
    latitude: 4.538796,
    id: "arp_kte_my",
    icao_code: "WMKE",
    iata_country_code: "MY",
    iata_code: "KTE",
    city_name: "Kerteh",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Keshod Airport",
    longitude: 70.269045,
    latitude: 21.317148,
    id: "arp_ixk_in",
    icao_code: "VAKS",
    iata_country_code: "IN",
    iata_code: "IXK",
    city_name: "Keshod",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Ketapang Airport",
    longitude: 109.962833,
    latitude: -1.816658,
    id: "arp_ktg_id",
    icao_code: "WIOK",
    iata_country_code: "ID",
    iata_code: "KTG",
    city_name: "Ketapang",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Ketchikan Harbor Seaplane Base",
    longitude: -131.682726,
    latitude: 55.34917,
    id: "arp_wfb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WFB",
    city_name: "Ketchikan",
    city: {
      name: "Ketchikan",
      id: "cit_ktn_us",
      iata_country_code: "US",
      iata_code: "KTN",
    },
  },
  {
    time_zone: "America/Sitka",
    name: "Ketchikan International Airport",
    longitude: -131.712282,
    latitude: 55.355504,
    id: "arp_ktn_us",
    icao_code: "PAKT",
    iata_country_code: "US",
    iata_code: "KTN",
    city_name: "Ketchikan",
    city: {
      name: "Ketchikan",
      id: "cit_ktn_us",
      iata_country_code: "US",
      iata_code: "KTN",
    },
  },
  {
    time_zone: "America/Regina",
    name: "Key Lake Airport",
    longitude: -105.615821,
    latitude: 57.256609,
    id: "arp_ykj_ca",
    icao_code: "CYKJ",
    iata_country_code: "CA",
    iata_code: "YKJ",
    city_name: "Key Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Key West International Airport",
    longitude: -81.757163,
    latitude: 24.554837,
    id: "arp_eyw_us",
    icao_code: "KEYW",
    iata_country_code: "US",
    iata_code: "EYW",
    city_name: "Key West",
    city: {
      name: "Key West",
      id: "cit_eyw_us",
      iata_country_code: "US",
      iata_code: "EYW",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Key West Naval Air Station",
    longitude: -81.688969,
    latitude: 24.576518,
    id: "arp_nqx_us",
    icao_code: "KNQX",
    iata_country_code: "US",
    iata_code: "NQX",
    city_name: "Key West",
    city: {
      name: "Key West",
      id: "cit_eyw_us",
      iata_country_code: "US",
      iata_code: "EYW",
    },
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Khabarovsk-Novy Airport",
    longitude: 135.171463,
    latitude: 48.526154,
    id: "arp_khv_ru",
    icao_code: "UHHH",
    iata_country_code: "RU",
    iata_code: "KHV",
    city_name: "Khabarovsk",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Khajuraho Airport",
    longitude: 79.91665,
    latitude: 24.814949,
    id: "arp_hjr_in",
    icao_code: "VAKJ",
    iata_country_code: "IN",
    iata_code: "HJR",
    city_name: "Khajuraho",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Khamti Airport",
    longitude: 95.674284,
    latitude: 25.988155,
    id: "arp_khm_mm",
    icao_code: "VYKI",
    iata_country_code: "MM",
    iata_code: "KHM",
    city_name: "Khamti",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Khaneh Airport",
    longitude: 60.71803,
    latitude: 27.228474,
    id: "arp_kha_ir",
    icao_code: "OITH",
    iata_country_code: "IR",
    iata_code: "KHA",
    city_name: "Khaneh",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Khanty-Mansiysk Airport",
    longitude: 69.090905,
    latitude: 61.027361,
    id: "arp_hma_ru",
    icao_code: "USHH",
    iata_country_code: "RU",
    iata_code: "HMA",
    city_name: "Khanty-Mansiysk",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Kharkhorin Airport",
    longitude: 102.827762,
    latitude: 47.24514,
    id: "arp_khr_mn",
    icao_code: "ZMHH",
    iata_country_code: "MN",
    iata_code: "KHR",
    city_name: "Kharkhorin",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Khark Island Airport",
    longitude: 50.324129,
    latitude: 29.258764,
    id: "arp_khk_ir",
    icao_code: "OIBQ",
    iata_country_code: "IR",
    iata_code: "KHK",
    city_name: "Khark Island",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kharkiv International Airport",
    longitude: 36.284994,
    latitude: 49.923315,
    id: "arp_hrk_ua",
    icao_code: "UKHH",
    iata_country_code: "UA",
    iata_code: "HRK",
    city_name: "Kharkiv",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Khartoum International Airport",
    longitude: 32.552717,
    latitude: 15.591133,
    id: "arp_krt_sd",
    icao_code: "HSSS",
    iata_country_code: "SD",
    iata_code: "KRT",
    city_name: "Khartoum",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Khasab Airport",
    longitude: 56.238363,
    latitude: 26.168944,
    id: "arp_khs_om",
    icao_code: "OOKB",
    iata_country_code: "OM",
    iata_code: "KHS",
    city_name: "Khasab",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Khashm el Girba Airport",
    longitude: 35.877702,
    latitude: 14.925048,
    id: "arp_gbu_sd",
    icao_code: "HSKG",
    iata_country_code: "SD",
    iata_code: "GBU",
    city_name: "Khashm el Girba",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Khatanga Airport",
    longitude: 102.490997,
    latitude: 71.978103,
    id: "arp_htg_ru",
    icao_code: "UOHH",
    iata_country_code: "RU",
    iata_code: "HTG",
    city_name: "Khatanga",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Khatgal Airport",
    longitude: 100.132856,
    latitude: 50.442273,
    id: "arp_htm_mn",
    icao_code: "ZMHG",
    iata_country_code: "MN",
    iata_code: "HTM",
    city_name: "Khatgal",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kherson International Airport",
    longitude: 32.507446,
    latitude: 46.674,
    id: "arp_khe_ua",
    icao_code: "UKOH",
    iata_country_code: "UA",
    iata_code: "KHE",
    city_name: "Kherson",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Khmelnytskyi Airport",
    longitude: 26.935613,
    latitude: 49.359871,
    id: "arp_hmj_ua",
    icao_code: "UKLH",
    iata_country_code: "UA",
    iata_code: "HMJ",
    city_name: "Khmelnytskyi",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Khoka Moya Airport",
    longitude: 31.41495,
    latitude: -24.593547,
    id: "arp_kho_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "KHO",
    city_name: "Manyeleti",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Khok Kathiam Airport",
    longitude: 100.663776,
    latitude: 14.873838,
    id: "arp_kkm_th",
    icao_code: "VTBL",
    iata_country_code: "TH",
    iata_code: "KKM",
    city_name: "Lop Buri",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Khong Airport",
    longitude: 102.563936,
    latitude: 17.987607,
    id: "arp_kog_la",
    icao_code: "VLKG",
    iata_country_code: "LA",
    iata_code: "KOG",
    city_name: "Khong",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Khon Kaen Airport",
    longitude: 102.785463,
    latitude: 16.465814,
    id: "arp_kkc_th",
    icao_code: "VTUK",
    iata_country_code: "TH",
    iata_code: "KKC",
    city_name: "Khon Kaen",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Khorramabad Airport",
    longitude: 48.283112,
    latitude: 33.436901,
    id: "arp_khd_ir",
    icao_code: "OICK",
    iata_country_code: "IR",
    iata_code: "KHD",
    city_name: "Khorramabad",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Khost Airport",
    longitude: 69.951002,
    latitude: 33.333367,
    id: "arp_kht_af",
    icao_code: "OAKS",
    iata_country_code: "AF",
    iata_code: "KHT",
    city_name: "Khost",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Khovd Airport",
    longitude: 91.626307,
    latitude: 47.957698,
    id: "arp_hvd_mn",
    icao_code: "ZMKD",
    iata_country_code: "MN",
    iata_code: "HVD",
    city_name: "Khovd",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Khowai Airport",
    longitude: 91.603575,
    latitude: 24.062942,
    id: "arp_ixn_in",
    icao_code: "VEKW",
    iata_country_code: "IN",
    iata_code: "IXN",
    city_name: "Khowai",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Khoy Airport",
    longitude: 44.973256,
    latitude: 38.424194,
    id: "arp_khy_ir",
    icao_code: "OITK",
    iata_country_code: "IR",
    iata_code: "KHY",
    city_name: "Khoy",
    city: null,
  },
  {
    time_zone: "Europe/Kaliningrad",
    name: "Khrabrovo Airport",
    longitude: 20.589899,
    latitude: 54.887603,
    id: "arp_kgd_ru",
    icao_code: "UMKK",
    iata_country_code: "RU",
    iata_code: "KGD",
    city_name: "Kaliningrad",
    city: null,
  },
  {
    time_zone: "Asia/Dushanbe",
    name: "Khudzhand Airport",
    longitude: 69.696062,
    latitude: 40.216262,
    id: "arp_lbd_tj",
    icao_code: "UTDL",
    iata_country_code: "TJ",
    iata_code: "LBD",
    city_name: "Khujand",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Khujirt Airport",
    longitude: 102.769674,
    latitude: 46.924028,
    id: "arp_hjt_mn",
    icao_code: "ZMHU",
    iata_country_code: "MN",
    iata_code: "HJT",
    city_name: "Khujirt",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Khuzdar Airport",
    longitude: 66.638578,
    latitude: 27.794918,
    id: "arp_kdd_pk",
    icao_code: "OPKH",
    iata_country_code: "PK",
    iata_code: "KDD",
    city_name: "Khuzdar",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Khwahan Airport",
    longitude: 70.217,
    latitude: 37.883,
    id: "arp_kwh_af",
    icao_code: "OAHN",
    iata_country_code: "AF",
    iata_code: "KWH",
    city_name: "Khwahan",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Khwai River Lodge Airport",
    longitude: 23.782939,
    latitude: -19.150158,
    id: "arp_khw_bw",
    icao_code: "FBKR",
    iata_country_code: "BW",
    iata_code: "KHW",
    city_name: "Khwai River Lodge",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kibuli Airport",
    longitude: 143.207381,
    latitude: -9.087133,
    id: "arp_kii_pg",
    icao_code: "AYLI",
    iata_country_code: "PG",
    iata_code: "KII",
    city_name: "Kibuli",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kichwa Tembo Airport",
    longitude: 35.022861,
    latitude: -1.263772,
    id: "arp_ktj_ke",
    icao_code: "HKTB",
    iata_country_code: "KE",
    iata_code: "KTJ",
    city_name: "Mara Lodges",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kickapoo Downtown Airport",
    longitude: -98.49203,
    latitude: 33.862601,
    id: "arp_kip_us",
    icao_code: "KCWC",
    iata_country_code: "US",
    iata_code: "KIP",
    city_name: "Wichita Falls",
    city: {
      name: "Wichita Falls",
      id: "cit_sps_us",
      iata_country_code: "US",
      iata_code: "SPS",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Kiel Airport",
    longitude: 10.145271,
    latitude: 54.38058,
    id: "arp_kel_de",
    icao_code: "EDHK",
    iata_country_code: "DE",
    iata_code: "KEL",
    city_name: "Kiel",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Kiffa Airport",
    longitude: -11.40601,
    latitude: 16.589839,
    id: "arp_kfa_mr",
    icao_code: "GQNF",
    iata_country_code: "MR",
    iata_code: "KFA",
    city_name: "Kiffa",
    city: null,
  },
  {
    time_zone: "Africa/Kigali",
    name: "Kigali International Airport",
    longitude: 30.137254,
    latitude: -1.965845,
    id: "arp_kgl_rw",
    icao_code: "HRYR",
    iata_country_code: "RW",
    iata_code: "KGL",
    city_name: "Kigali",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Kigoma Airport",
    longitude: 29.671148,
    latitude: -4.887443,
    id: "arp_tkq_tz",
    icao_code: "HTKA",
    iata_country_code: "TZ",
    iata_code: "TKQ",
    city_name: "Kigoma",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Kihihi Airport",
    longitude: 29.69892,
    latitude: -0.717558,
    id: "arp_khx_ug",
    icao_code: null,
    iata_country_code: "UG",
    iata_code: "KHX",
    city_name: "Kihihi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kikai Airport",
    longitude: 129.928239,
    latitude: 28.321493,
    id: "arp_kkx_jp",
    icao_code: "RJKI",
    iata_country_code: "JP",
    iata_code: "KKX",
    city_name: "Kikai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kikinonda Airport",
    longitude: 147.931498,
    latitude: -8.533308,
    id: "arp_kiz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KIZ",
    city_name: "Kikinonda",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kikori Airport",
    longitude: 144.250076,
    latitude: -7.424379,
    id: "arp_kri_pg",
    icao_code: "AYKK",
    iata_country_code: "PG",
    iata_code: "KRI",
    city_name: "Kikori",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Kikwit Airport",
    longitude: 18.785205,
    latitude: -5.036524,
    id: "arp_kkw_cd",
    icao_code: "FZCA",
    iata_country_code: "CD",
    iata_code: "KKW",
    city_name: "Kikwit",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kilaguni Airport",
    longitude: 38.0734,
    latitude: -2.899386,
    id: "arp_ilu_ke",
    icao_code: "HKKL",
    iata_country_code: "KE",
    iata_code: "ILU",
    city_name: "Kilaguni",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Kili Airport",
    longitude: 169.117927,
    latitude: 5.647464,
    id: "arp_kio_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "KIO",
    city_name: "Kili Island",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Kilimanjaro International Airport",
    longitude: 37.074482,
    latitude: -3.429329,
    id: "arp_jro_tz",
    icao_code: "HTKJ",
    iata_country_code: "TZ",
    iata_code: "JRO",
    city_name: "Kilimanjaro",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Kilkenny Airport",
    longitude: -7.295715,
    latitude: 52.651214,
    id: "arp_kky_ie",
    icao_code: "EIKK",
    iata_country_code: "IE",
    iata_code: "KKY",
    city_name: "Kilkenny",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Killeen-Fort Hood Regional Airport",
    longitude: -97.824772,
    latitude: 31.06427,
    id: "arp_grk_us",
    icao_code: "KGRK",
    iata_country_code: "US",
    iata_code: "GRK",
    city_name: "Killeen",
    city: {
      name: "Killeen/Fort Hood",
      id: "cit_ile_us",
      iata_country_code: "US",
      iata_code: "ILE",
    },
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Kilwa Airport",
    longitude: 39.509174,
    latitude: -8.911694,
    id: "arp_kil_cd",
    icao_code: null,
    iata_country_code: "CD",
    iata_code: "KIL",
    city_name: "Kilwa",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Kilwa Masoko Airport",
    longitude: 39.508784,
    latitude: -8.910995,
    id: "arp_kiy_tz",
    icao_code: "HTKI",
    iata_country_code: "TZ",
    iata_code: "KIY",
    city_name: "Kilwa Masoko",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kimam Airport",
    longitude: 138.851378,
    latitude: -7.977796,
    id: "arp_kmm_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "KMM",
    city_name: "Kimam",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Kimberley Airport",
    longitude: 24.765859,
    latitude: -28.802147,
    id: "arp_kim_za",
    icao_code: "FAKM",
    iata_country_code: "ZA",
    iata_code: "KIM",
    city_name: "Kimberley",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Kimberley Downs Airport",
    longitude: 124.356676,
    latitude: -17.394262,
    id: "arp_kbd_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "KBD",
    city_name: "Kimberley Downs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kimble County Airport",
    longitude: -99.763908,
    latitude: 30.511274,
    id: "arp_jct_us",
    icao_code: "KJCT",
    iata_country_code: "US",
    iata_code: "JCT",
    city_name: "Junction",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Kimmirut Airport",
    longitude: -69.880167,
    latitude: 62.849016,
    id: "arp_ylc_ca",
    icao_code: "CYLC",
    iata_country_code: "CA",
    iata_code: "YLC",
    city_name: "Kimmirut",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kincardine Municipal Airport",
    longitude: -81.601941,
    latitude: 44.200152,
    id: "arp_ykd_ca",
    icao_code: "CYKM",
    iata_country_code: "CA",
    iata_code: "YKD",
    city_name: "Kincardine",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Kindamba Airport",
    longitude: 14.5181,
    latitude: -3.9501,
    id: "arp_knj_cg",
    icao_code: "FCBK",
    iata_country_code: "CG",
    iata_code: "KNJ",
    city_name: "Kindamba",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Kindersley Regional Airport",
    longitude: -109.179004,
    latitude: 51.517287,
    id: "arp_yky_ca",
    icao_code: "CYKY",
    iata_country_code: "CA",
    iata_code: "YKY",
    city_name: "Kindersley",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kindu Airport",
    longitude: 25.915207,
    latitude: -2.922701,
    id: "arp_knd_cd",
    icao_code: "FZOA",
    iata_country_code: "CD",
    iata_code: "KND",
    city_name: "Kindu",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Abdulaziz Air Base",
    longitude: 50.151982,
    latitude: 26.265359,
    id: "arp_dha_sa",
    icao_code: "OEDR",
    iata_country_code: "SA",
    iata_code: "DHA",
    city_name: "Dhahran",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Abdulaziz International Airport",
    longitude: 39.178833,
    latitude: 21.65615,
    id: "arp_jed_sa",
    icao_code: "OEJN",
    iata_country_code: "SA",
    iata_code: "JED",
    city_name: "Jeddah",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Kingaroy Airport",
    longitude: 151.841248,
    latitude: -26.579649,
    id: "arp_kgy_au",
    icao_code: "YKRY",
    iata_country_code: "AU",
    iata_code: "KGY",
    city_name: "Kingaroy",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "King County International Airport",
    longitude: -122.302388,
    latitude: 47.531208,
    id: "arp_bfi_us",
    icao_code: "KBFI",
    iata_country_code: "US",
    iata_code: "BFI",
    city_name: "Seattle",
    city: {
      name: "Seattle",
      id: "cit_sea_us",
      iata_country_code: "US",
      iata_code: "SEA",
    },
  },
  {
    time_zone: "America/Nome",
    name: "King Cove Airport",
    longitude: -162.270157,
    latitude: 55.115651,
    id: "arp_kvc_us",
    icao_code: "PAVC",
    iata_country_code: "US",
    iata_code: "KVC",
    city_name: "King Cove",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Fahd International Airport",
    longitude: 49.797683,
    latitude: 26.470242,
    id: "arp_dmm_sa",
    icao_code: "OEDF",
    iata_country_code: "SA",
    iata_code: "DMM",
    city_name: "Dammam",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Kingfisher Lake Airport",
    longitude: -89.854629,
    latitude: 53.013901,
    id: "arp_kif_ca",
    icao_code: "CNM5",
    iata_country_code: "CA",
    iata_code: "KIF",
    city_name: "Kingfisher Lake",
    city: null,
  },
  {
    time_zone: "Asia/Amman",
    name: "King Hussein Air College Airport",
    longitude: 36.256845,
    latitude: 32.356623,
    id: "arp_omf_jo",
    icao_code: "OJMF",
    iata_country_code: "JO",
    iata_code: "OMF",
    city_name: "Mafraq",
    city: null,
  },
  {
    time_zone: "Asia/Amman",
    name: "King Hussein International Airport",
    longitude: 35.020055,
    latitude: 29.614073,
    id: "arp_aqj_jo",
    icao_code: "OJAQ",
    iata_country_code: "JO",
    iata_code: "AQJ",
    city_name: "Aqaba",
    city: null,
  },
  {
    time_zone: "Australia/Currie",
    name: "King Island Airport",
    longitude: 143.879885,
    latitude: -39.878614,
    id: "arp_kns_au",
    icao_code: "YKII",
    iata_country_code: "AU",
    iata_code: "KNS",
    city_name: "King Island",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Khaled Air Base",
    longitude: 42.804424,
    latitude: 18.297921,
    id: "arp_kmx_sa",
    icao_code: "OEKM",
    iata_country_code: "SA",
    iata_code: "KMX",
    city_name: "Khamis Mushait",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Khaled Military City Airport",
    longitude: 45.52202,
    latitude: 27.900351,
    id: "arp_hbt_sa",
    icao_code: null,
    iata_country_code: "SA",
    iata_code: "HBT",
    city_name: "Hambantota",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Khaled Military City Airport",
    longitude: 45.528197,
    latitude: 27.9009,
    id: "arp_kmc_sa",
    icao_code: "OEKK",
    iata_country_code: "SA",
    iata_code: "KMC",
    city_name: "King Khalid Military",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "King Khalid International Airport",
    longitude: 46.699689,
    latitude: 24.959223,
    id: "arp_ruh_sa",
    icao_code: "OERK",
    iata_country_code: "SA",
    iata_code: "RUH",
    city_name: "Riyadh",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Kingman Airport",
    longitude: -113.939839,
    latitude: 35.258461,
    id: "arp_igm_us",
    icao_code: "KIGM",
    iata_country_code: "US",
    iata_code: "IGM",
    city_name: "Kingman",
    city: null,
  },
  {
    time_zone: "Africa/Mbabane",
    name: "King Mswati III International Airport",
    longitude: 31.720799,
    latitude: -26.357901,
    id: "arp_sho_sz",
    icao_code: "FDSK",
    iata_country_code: "SZ",
    iata_code: "SHO",
    city_name: "Manzini",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "King Salmon Airport",
    longitude: -156.650867,
    latitude: 58.675784,
    id: "arp_akn_us",
    icao_code: "PAKN",
    iata_country_code: "US",
    iata_code: "AKN",
    city_name: "King Salmon",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Kings Canyon Airport",
    longitude: 131.489236,
    latitude: -24.259658,
    id: "arp_kbj_au",
    icao_code: "YKCA",
    iata_country_code: "AU",
    iata_code: "KBJ",
    city_name: "Kings Canyon",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Kingscote Airport",
    longitude: 137.522223,
    latitude: -35.712943,
    id: "arp_kgc_au",
    icao_code: "YKSC",
    iata_country_code: "AU",
    iata_code: "KGC",
    city_name: "Kingscote",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Kings Creek Airport",
    longitude: 131.835007,
    latitude: -24.4233,
    id: "arp_kcs_au",
    icao_code: "YKCS",
    iata_country_code: "AU",
    iata_code: "KCS",
    city_name: "Kings Creek Station",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "King Shaka International Airport",
    longitude: 31.118305,
    latitude: -29.612212,
    id: "arp_dur_za",
    icao_code: "FALE",
    iata_country_code: "ZA",
    iata_code: "DUR",
    city_name: "Durban",
    city: {
      name: "Durban",
      id: "cit_dur_za",
      iata_country_code: "ZA",
      iata_code: "DUR",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Kingston Airport",
    longitude: -76.598179,
    latitude: 44.224395,
    id: "arp_ygk_ca",
    icao_code: "CYGK",
    iata_country_code: "CA",
    iata_code: "YGK",
    city_name: "Kingston",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kingsville Naval Air Station",
    longitude: -97.809718,
    latitude: 27.507204,
    id: "arp_nqi_us",
    icao_code: "KNQI",
    iata_country_code: "US",
    iata_code: "NQI",
    city_name: "Kingsville",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kinkungwa Airport",
    longitude: 26.733301,
    latitude: -2.576893,
    id: "arp_kly_cd",
    icao_code: "FZOD",
    iata_country_code: "CD",
    iata_code: "KLY",
    city_name: "Kalima",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Kinmen Airport",
    longitude: 118.360547,
    latitude: 24.429078,
    id: "arp_knh_tw",
    icao_code: "RCBS",
    iata_country_code: "TW",
    iata_code: "KNH",
    city_name: "Kinmen",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Kinshasa N'Djili Airport",
    longitude: 15.445022,
    latitude: -4.386602,
    id: "arp_fih_cd",
    icao_code: "FZAA",
    iata_country_code: "CD",
    iata_code: "FIH",
    city_name: "Kinshasa",
    city: {
      name: "Kinshasa",
      id: "cit_fih_cd",
      iata_country_code: "CD",
      iata_code: "FIH",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Kinston Regional Jetport",
    longitude: -77.610154,
    latitude: 35.32898,
    id: "arp_iso_us",
    icao_code: "KISO",
    iata_country_code: "US",
    iata_code: "ISO",
    city_name: "Kinston",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Kipnuk Airport",
    longitude: -164.030761,
    latitude: 59.932975,
    id: "arp_kpn_us",
    icao_code: "PAKI",
    iata_country_code: "US",
    iata_code: "KPN",
    city_name: "Kipnuk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kira Airport",
    longitude: 147.331406,
    latitude: -8.065196,
    id: "arp_kiq_pg",
    icao_code: "AYRA",
    iata_country_code: "PG",
    iata_code: "KIQ",
    city_name: "Kira",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Kirakira Airport",
    longitude: 161.897467,
    latitude: -10.449371,
    id: "arp_ira_sb",
    icao_code: "AGGK",
    iata_country_code: "SB",
    iata_code: "IRA",
    city_name: "Kirakira",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Kirensk Airport",
    longitude: 108.051277,
    latitude: 57.770322,
    id: "arp_kck_ru",
    icao_code: "UIKK",
    iata_country_code: "RU",
    iata_code: "KCK",
    city_name: "Kirensk",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Kirkenes Airport",
    longitude: 29.889241,
    latitude: 69.724131,
    id: "arp_kkn_no",
    icao_code: "ENKR",
    iata_country_code: "NO",
    iata_code: "KKN",
    city_name: "Kirkenes",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kirk Field",
    longitude: -90.508418,
    latitude: 36.062752,
    id: "arp_pgr_us",
    icao_code: "KPGR",
    iata_country_code: "US",
    iata_code: "PGR",
    city_name: "Paragould",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Kirkimbie Station Airport",
    longitude: 129.208665,
    latitude: -17.778831,
    id: "arp_kbb_au",
    icao_code: "YKIR",
    iata_country_code: "AU",
    iata_code: "KBB",
    city_name: "Kirkimbie Station",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kirkland Lake Airport",
    longitude: -79.981592,
    latitude: 48.21047,
    id: "arp_ykx_ca",
    icao_code: "CYKX",
    iata_country_code: "CA",
    iata_code: "YKX",
    city_name: "Kirkland Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kirksville Regional Airport",
    longitude: -92.543058,
    latitude: 40.093299,
    id: "arp_irk_us",
    icao_code: "KIRK",
    iata_country_code: "US",
    iata_code: "IRK",
    city_name: "Kirksville",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Kirkuk Air Base",
    longitude: 44.355192,
    latitude: 35.46011,
    id: "arp_kik_iq",
    icao_code: "ORKK",
    iata_country_code: "IQ",
    iata_code: "KIK",
    city_name: "Kirkuk",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Kirkwall Airport",
    longitude: -2.903675,
    latitude: 58.95606,
    id: "arp_koi_gb",
    icao_code: "EGPA",
    iata_country_code: "GB",
    iata_code: "KOI",
    city_name: "Kirkwall",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Kirovsk-Apatity Airport",
    longitude: 33.586172,
    latitude: 67.462061,
    id: "arp_kvk_ru",
    icao_code: "ULMK",
    iata_country_code: "RU",
    iata_code: "KVK",
    city_name: "Kirovsk",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Kirsch Municipal Airport",
    longitude: -85.432857,
    latitude: 41.812777,
    id: "arp_irs_us",
    icao_code: "KIRS",
    iata_country_code: "US",
    iata_code: "IRS",
    city_name: "Sturgis",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Kiruna Airport",
    longitude: 20.336443,
    latitude: 67.822261,
    id: "arp_krn_se",
    icao_code: "ESNQ",
    iata_country_code: "SE",
    iata_code: "KRN",
    city_name: "Kiruna",
    city: null,
  },
  {
    time_zone: "Africa/Bujumbura",
    name: "Kirundo Airport",
    longitude: 30.093511,
    latitude: -2.544209,
    id: "arp_kre_bi",
    icao_code: "HBBO",
    iata_country_code: "BI",
    iata_code: "KRE",
    city_name: "Kirundo",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Kiryat Shmona Airport",
    longitude: 35.59781,
    latitude: 33.217105,
    id: "arp_ksw_il",
    icao_code: "LLKS",
    iata_country_code: "IL",
    iata_code: "KSW",
    city_name: "Kiryat Shmona",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kisengam Airport",
    longitude: 146.710472,
    latitude: -6.361998,
    id: "arp_ksg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KSG",
    city_name: "Kisengam",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kishangarh Airport",
    longitude: 74.814146,
    latitude: 26.601473,
    id: "arp_kqh_in",
    icao_code: "VIKG",
    iata_country_code: "IN",
    iata_code: "KQH",
    city_name: "Kishangarh",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Kish International Airport",
    longitude: 53.971373,
    latitude: 26.531156,
    id: "arp_kih_ir",
    icao_code: "OIBK",
    iata_country_code: "IR",
    iata_code: "KIH",
    city_name: "Kish Island",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Kisimayu Airport",
    longitude: 42.460322,
    latitude: -0.376952,
    id: "arp_kmu_so",
    icao_code: "HCMK",
    iata_country_code: "SO",
    iata_code: "KMU",
    city_name: "Kismayo",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Kissidougou Airport",
    longitude: -10.124538,
    latitude: 9.160513,
    id: "arp_ksi_gn",
    icao_code: "GUKU",
    iata_country_code: "GN",
    iata_code: "KSI",
    city_name: "Kissidougou",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Kissimmee Gateway Airport",
    longitude: -81.437089,
    latitude: 28.291411,
    id: "arp_ism_us",
    icao_code: "KISM",
    iata_country_code: "US",
    iata_code: "ISM",
    city_name: "Orlando",
    city: {
      name: "Orlando",
      id: "cit_orl_us",
      iata_country_code: "US",
      iata_code: "ORL",
    },
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kisumu International Airport",
    longitude: 34.728317,
    latitude: -0.086463,
    id: "arp_kis_ke",
    icao_code: "HKKI",
    iata_country_code: "KE",
    iata_code: "KIS",
    city_name: "Kisumu",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kitadaito Airport",
    longitude: 131.326256,
    latitude: 25.944684,
    id: "arp_ktd_jp",
    icao_code: "RORK",
    iata_country_code: "JP",
    iata_code: "KTD",
    city_name: "Kitadaito",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kitakyushu Airport",
    longitude: 131.034273,
    latitude: 33.843455,
    id: "arp_kkj_jp",
    icao_code: "RJFR",
    iata_country_code: "JP",
    iata_code: "KKJ",
    city_name: "Kitakyushu",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kitale Airport",
    longitude: 34.958611,
    latitude: 0.972149,
    id: "arp_ktl_ke",
    icao_code: "HKKT",
    iata_country_code: "KE",
    iata_code: "KTL",
    city_name: "Kitale",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kitava Airport",
    longitude: 151.326531,
    latitude: -8.627799,
    id: "arp_kve_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KVE",
    city_name: "Kitava Island",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kitee Airport",
    longitude: 30.074913,
    latitude: 62.167168,
    id: "arp_ktq_fi",
    icao_code: "EFIT",
    iata_country_code: "FI",
    iata_code: "KTQ",
    city_name: "Kitee",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kithira Island National Airport",
    longitude: 23.015931,
    latitude: 36.272382,
    id: "arp_kit_gr",
    icao_code: "LGKC",
    iata_country_code: "GR",
    iata_code: "KIT",
    city_name: "Kithira",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Kitkatla Airport",
    longitude: -130.648,
    latitude: 53.7267,
    id: "arp_ykk_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YKK",
    city_name: "Kitkatla",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kitoi Bay Seaplane Base",
    longitude: -152.370323,
    latitude: 58.19093,
    id: "arp_kkb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KKB",
    city_name: "Kitoi Bay",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kittilä Airport",
    longitude: 24.850846,
    latitude: 67.69814,
    id: "arp_ktt_fi",
    icao_code: "EFKT",
    iata_country_code: "FI",
    iata_code: "KTT",
    city_name: "Kittilä",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Kitzingen Airport",
    longitude: 10.201233,
    latitude: 49.743085,
    id: "arp_kzg_de",
    icao_code: "ETIN",
    iata_country_code: "DE",
    iata_code: "KZG",
    city_name: "Kitzingen",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kiunga Airport",
    longitude: 40.912949,
    latitude: -2.25234,
    id: "arp_kiu_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "KIU",
    city_name: "Kiunga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kiunga Airport",
    longitude: 141.283239,
    latitude: -6.125599,
    id: "arp_ung_pg",
    icao_code: "AYKI",
    iata_country_code: "PG",
    iata_code: "UNG",
    city_name: "Kiunga",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Kivalina Airport",
    longitude: -164.560646,
    latitude: 67.735214,
    id: "arp_kvl_us",
    icao_code: "PAVL",
    iata_country_code: "US",
    iata_code: "KVL",
    city_name: "Kivalina",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kiwai Island Airport",
    longitude: 143.208239,
    latitude: -9.087324,
    id: "arp_kwx_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KWX",
    city_name: "Kiwai Island",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Kiwayu Airport",
    longitude: 41.296549,
    latitude: -1.96084,
    id: "arp_kwy_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "KWY",
    city_name: "Kiwayu",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Klagenfurt Airport",
    longitude: 4.325341,
    latitude: 46.649659,
    id: "arp_klu_at",
    icao_code: "LOWK",
    iata_country_code: "AT",
    iata_code: "KLU",
    city_name: "Klagenfurt",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Klawock Airport",
    longitude: -133.07302,
    latitude: 55.580275,
    id: "arp_klw_us",
    icao_code: "PAKW",
    iata_country_code: "US",
    iata_code: "KLW",
    city_name: "Klawock",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Kleinsee Airport",
    longitude: 17.093715,
    latitude: -29.686954,
    id: "arp_klz_za",
    icao_code: "FAKZ",
    iata_country_code: "ZA",
    iata_code: "KLZ",
    city_name: "Kleinsee",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Klemtu Water Aerodrome",
    longitude: -128.525405,
    latitude: 52.607576,
    id: "arp_ykt_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YKT",
    city_name: "Klemtu",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Klerksdorp Airport",
    longitude: 26.719013,
    latitude: -26.870478,
    id: "arp_kxe_za",
    icao_code: "FAKD",
    iata_country_code: "ZA",
    iata_code: "KXE",
    city_name: "Klerksdorp",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Knee Lake Airport",
    longitude: -94.79794,
    latitude: 54.915149,
    id: "arp_yke_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YKE",
    city_name: "Knee Lake",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Kneeland Airport",
    longitude: -123.927639,
    latitude: 40.718801,
    id: "arp_nln_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NLN",
    city_name: "Ankokoambo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Knox County Regional Airport",
    longitude: -69.097683,
    latitude: 44.060625,
    id: "arp_rkd_us",
    icao_code: "KRKD",
    iata_country_code: "US",
    iata_code: "RKD",
    city_name: "Rockland",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kobe Airport",
    longitude: 135.224459,
    latitude: 34.63408,
    id: "arp_ukb_jp",
    icao_code: "RJBE",
    iata_country_code: "JP",
    iata_code: "UKB",
    city_name: "Kobe",
    city: {
      name: "Osaka",
      id: "cit_osa_jp",
      iata_country_code: "JP",
      iata_code: "OSA",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Kobuk Airport",
    longitude: -156.900413,
    latitude: 66.912532,
    id: "arp_obu_us",
    icao_code: "PAOB",
    iata_country_code: "US",
    iata_code: "OBU",
    city_name: "Kobuk",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kōchi Airport",
    longitude: 133.671432,
    latitude: 33.546769,
    id: "arp_kcz_jp",
    icao_code: "RJOK",
    iata_country_code: "JP",
    iata_code: "KCZ",
    city_name: "Kochi",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kodiak Airport",
    longitude: -152.493952,
    latitude: 57.749988,
    id: "arp_adq_us",
    icao_code: "PADQ",
    iata_country_code: "US",
    iata_code: "ADQ",
    city_name: "Kodiak",
    city: {
      name: "Kodiak",
      id: "cit_adq_us",
      iata_country_code: "US",
      iata_code: "ADQ",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Kodiak Municipal Airport",
    longitude: -152.373681,
    latitude: 57.806164,
    id: "arp_kdk_us",
    icao_code: "PAKD",
    iata_country_code: "US",
    iata_code: "KDK",
    city_name: "Kodiak",
    city: {
      name: "Kodiak",
      id: "cit_adq_us",
      iata_country_code: "US",
      iata_code: "ADQ",
    },
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Kogalym International Airport",
    longitude: 74.533529,
    latitude: 62.191999,
    id: "arp_kgp_ru",
    icao_code: "USRK",
    iata_country_code: "RU",
    iata_code: "KGP",
    city_name: "Kogalym",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Koggala Airport",
    longitude: 80.320654,
    latitude: 5.993513,
    id: "arp_kct_lk",
    icao_code: "VCCK",
    iata_country_code: "LK",
    iata_code: "KCT",
    city_name: "Koggala",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Kohat Airport",
    longitude: 71.437793,
    latitude: 33.573447,
    id: "arp_oht_pk",
    icao_code: "OPKT",
    iata_country_code: "PK",
    iata_code: "OHT",
    city_name: "Kohat",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Koh Kong Airport",
    longitude: 102.996904,
    latitude: 11.61369,
    id: "arp_kkz_kh",
    icao_code: "VDKK",
    iata_country_code: "KH",
    iata_code: "KKZ",
    city_name: "Koh Kong",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Koinambe Airport",
    longitude: 144.607266,
    latitude: -5.487383,
    id: "arp_kmb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KMB",
    city_name: "Koinambe",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Koingnaas Airport",
    longitude: 17.943478,
    latitude: -29.688532,
    id: "arp_kig_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "KIG",
    city_name: "Koingnaas",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kokhanok Airport",
    longitude: -154.803436,
    latitude: 59.433299,
    id: "arp_knk_us",
    icao_code: "PFKK",
    iata_country_code: "US",
    iata_code: "KNK",
    city_name: "Kokhanok",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kokkola-Pietarsaari Airport",
    longitude: 23.138716,
    latitude: 63.720119,
    id: "arp_kok_fi",
    icao_code: "EFKK",
    iata_country_code: "FI",
    iata_code: "KOK",
    city_name: "Kronoby",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kokoda Airport",
    longitude: 147.731738,
    latitude: -8.884139,
    id: "arp_kkd_pg",
    icao_code: "AYKO",
    iata_country_code: "PG",
    iata_code: "KKD",
    city_name: "Kokoda",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Kokomo Municipal Airport",
    longitude: -86.058498,
    latitude: 40.529479,
    id: "arp_okk_us",
    icao_code: "KOKK",
    iata_country_code: "US",
    iata_code: "OKK",
    city_name: "Kokomo",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kokonao Airport",
    longitude: 136.43515,
    latitude: -4.71075,
    id: "arp_kox_id",
    icao_code: "WABN",
    iata_country_code: "ID",
    iata_code: "KOX",
    city_name: "Kokonao",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kokoro Airport",
    longitude: 146.55,
    latitude: -7.81667,
    id: "arp_kor_pg",
    icao_code: "AYRO",
    iata_country_code: "PG",
    iata_code: "KOR",
    city_name: "Kakoro",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Kokshetau Airport",
    longitude: 69.592407,
    latitude: 53.330083,
    id: "arp_kov_kz",
    icao_code: "UACK",
    iata_country_code: "KZ",
    iata_code: "KOV",
    city_name: "Kokshetau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kol Airport",
    longitude: 147.218921,
    latitude: -9.442113,
    id: "arp_kql_pg",
    icao_code: "AYOL",
    iata_country_code: "PG",
    iata_code: "KQL",
    city_name: "Kol",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Kolda North Airport",
    longitude: -14.967003,
    latitude: 12.899547,
    id: "arp_kda_sn",
    icao_code: "GOGK",
    iata_country_code: "SN",
    iata_code: "KDA",
    city_name: "Kolda",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Kolhapur Airport",
    longitude: 74.286191,
    latitude: 16.664638,
    id: "arp_klh_in",
    icao_code: "VAKP",
    iata_country_code: "IN",
    iata_code: "KLH",
    city_name: "Kolhapur",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Koliganek Airport",
    longitude: -157.259239,
    latitude: 59.726621,
    id: "arp_kgk_us",
    icao_code: "PAJZ",
    iata_country_code: "US",
    iata_code: "KGK",
    city_name: "Koliganek",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Koltsovo Airport",
    longitude: 60.802824,
    latitude: 56.743937,
    id: "arp_svx_ru",
    icao_code: "USSS",
    iata_country_code: "RU",
    iata_code: "SVX",
    city_name: "Yekaterinburg",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kolwezi Airport",
    longitude: 25.506322,
    latitude: -10.766283,
    id: "arp_kwz_cd",
    icao_code: "FZQM",
    iata_country_code: "CD",
    iata_code: "KWZ",
    city_name: "Kolwezi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Komaio Airport",
    longitude: 143.595366,
    latitude: -7.271315,
    id: "arp_kcj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KCJ",
    city_name: "Komaio",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Komako Airport",
    longitude: 145.8827,
    latitude: -7.3984,
    id: "arp_hoc_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "HOC",
    city_name: "Komako",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Komatipoort Airport",
    longitude: 31.930187,
    latitude: -25.440473,
    id: "arp_kof_za",
    icao_code: "FAKP",
    iata_country_code: "ZA",
    iata_code: "KOF",
    city_name: "Komatipoort",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Komatsu Airport",
    longitude: 136.407242,
    latitude: 36.394605,
    id: "arp_kmq_jp",
    icao_code: "RJNK",
    iata_country_code: "JP",
    iata_code: "KMQ",
    city_name: "Komatsu",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Komodo Airport",
    longitude: 119.888901,
    latitude: -8.486725,
    id: "arp_lbj_id",
    icao_code: "WATO",
    iata_country_code: "ID",
    iata_code: "LBJ",
    city_name: "Labuan Bajo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Komo-Manda Airport",
    longitude: 142.860068,
    latitude: -6.068563,
    id: "arp_kom_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KOM",
    city_name: "Komo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kompiam Airport",
    longitude: 143.924819,
    latitude: -5.381944,
    id: "arp_kpm_pg",
    icao_code: "AYAQ",
    iata_country_code: "PG",
    iata_code: "KPM",
    city_name: "Kompiam",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Komsomolsk-on-Amur Airport",
    longitude: 136.932342,
    latitude: 50.407131,
    id: "arp_kxk_ru",
    icao_code: "UHKK",
    iata_country_code: "RU",
    iata_code: "KXK",
    city_name: "Komsomolsk-on-Amur",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Kona International Airport",
    longitude: -156.044264,
    latitude: 19.73785,
    id: "arp_koa_us",
    icao_code: "PHKO",
    iata_country_code: "US",
    iata_code: "KOA",
    city_name: "Kona",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Konarak Airport",
    longitude: 60.381277,
    latitude: 25.441113,
    id: "arp_zbr_ir",
    icao_code: "OIZC",
    iata_country_code: "IR",
    iata_code: "ZBR",
    city_name: "Chah Bahar",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Konawaruk Airport",
    longitude: -58.995215,
    latitude: 5.268485,
    id: "arp_kkg_gy",
    icao_code: "SYKZ",
    iata_country_code: "GY",
    iata_code: "KKG",
    city_name: "Konawaruk",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Kondavattavan Tank Airport",
    longitude: 81.665767,
    latitude: 7.282787,
    id: "arp_afk_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "AFK",
    city_name: "Ampara",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Kondinskoye Airport",
    longitude: 67.424643,
    latitude: 59.651361,
    id: "arp_kxd_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "KXD",
    city_name: "Kondinskoye",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kondubol Airport",
    longitude: 147.599366,
    latitude: -9.13761,
    id: "arp_kpf_pg",
    icao_code: "AYDL",
    iata_country_code: "PG",
    iata_code: "KPF",
    city_name: "Kondubol",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Koné Airport",
    longitude: 164.838622,
    latitude: -21.054109,
    id: "arp_knq_nc",
    icao_code: "NWWD",
    iata_country_code: "NC",
    iata_code: "KNQ",
    city_name: "Kone",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Konge Airport",
    longitude: 147.598878,
    latitude: -9.137225,
    id: "arp_kgb_pg",
    icao_code: "AYOE",
    iata_country_code: "PG",
    iata_code: "KGB",
    city_name: "Konge",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Kongiganak Airport",
    longitude: -162.881414,
    latitude: 59.959977,
    id: "arp_kkh_us",
    icao_code: "PADY",
    iata_country_code: "US",
    iata_code: "KKH",
    city_name: "Kongiganak",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Kongo Boumba Airport",
    longitude: 11.466667,
    latitude: -0.083333,
    id: "arp_gko_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "GKO",
    city_name: "Kongo Boumba",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Kongolo Airport",
    longitude: 26.99,
    latitude: -5.39444,
    id: "arp_koo_cd",
    icao_code: "FZRQ",
    iata_country_code: "CD",
    iata_code: "KOO",
    city_name: "Kongolo",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Kontum Airport",
    longitude: 108.019192,
    latitude: 14.352084,
    id: "arp_kon_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "KON",
    city_name: "Kon Tum",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Konya Airport",
    longitude: 32.561734,
    latitude: 37.980945,
    id: "arp_kya_tr",
    icao_code: "LTAN",
    iata_country_code: "TR",
    iata_code: "KYA",
    city_name: "Konya",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Kooddoo Airport",
    longitude: 73.433698,
    latitude: 0.733271,
    id: "arp_gkk_mv",
    icao_code: "VRMO",
    iata_country_code: "MV",
    iata_code: "GKK",
    city_name: "Kooddoo Island",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Koolatah Airport",
    longitude: 142.440606,
    latitude: -15.89017,
    id: "arp_koh_au",
    icao_code: "YKLA",
    iata_country_code: "AU",
    iata_code: "KOH",
    city_name: "Koolatah",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Koolburra Airport",
    longitude: 143.955003,
    latitude: -15.319025,
    id: "arp_kkp_au",
    icao_code: "YKLB",
    iata_country_code: "AU",
    iata_code: "KKP",
    city_name: "Koolburra",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Kópasker Airport",
    longitude: -16.463365,
    latitude: 66.313314,
    id: "arp_opa_is",
    icao_code: "BIKP",
    iata_country_code: "IS",
    iata_code: "OPA",
    city_name: "Kópasker",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kopiago Airport",
    longitude: 142.498161,
    latitude: -5.388567,
    id: "arp_kpa_pg",
    icao_code: "AYKG",
    iata_country_code: "PG",
    iata_code: "KPA",
    city_name: "Kopiago",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Korhogo Airport",
    longitude: -5.55666,
    latitude: 9.38718,
    id: "arp_hgo_ci",
    icao_code: "DIKO",
    iata_country_code: "CI",
    iata_code: "HGO",
    city_name: "Korhogo",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Korla Airport",
    longitude: 86.128898,
    latitude: 41.697799,
    id: "arp_krl_cn",
    icao_code: "ZWKL",
    iata_country_code: "CN",
    iata_code: "KRL",
    city_name: "Korla",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Kornasoren Airport",
    longitude: 134.869673,
    latitude: -0.936146,
    id: "arp_foo_id",
    icao_code: "WABF",
    iata_country_code: "ID",
    iata_code: "FOO",
    city_name: "Numfoor",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Koroba Airport",
    longitude: 142.744132,
    latitude: -5.695776,
    id: "arp_kde_pg",
    icao_code: "AYOW",
    iata_country_code: "PG",
    iata_code: "KDE",
    city_name: "Koroba",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Koro Island Airport",
    longitude: 179.421997,
    latitude: -17.3458,
    id: "arp_kxf_fj",
    icao_code: "NFNO",
    iata_country_code: "FJ",
    iata_code: "KXF",
    city_name: "Koro Island",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Korolevu Airport",
    longitude: 177.443842,
    latitude: -17.754814,
    id: "arp_kvu_fj",
    icao_code: null,
    iata_country_code: "FJ",
    iata_code: "KVU",
    city_name: "Korolevu",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Kosciusko-Attala County Airport",
    longitude: -89.542457,
    latitude: 33.090681,
    id: "arp_osx_us",
    icao_code: "KOSX",
    iata_country_code: "US",
    iata_code: "OSX",
    city_name: "Kosciusko",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Košice International Airport",
    longitude: 21.238698,
    latitude: 48.672984,
    id: "arp_ksc_sk",
    icao_code: "LZKZ",
    iata_country_code: "SK",
    iata_code: "KSC",
    city_name: "Košice",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kos International Airport",
    longitude: 27.090994,
    latitude: 36.797396,
    id: "arp_kgs_gr",
    icao_code: "LGKO",
    iata_country_code: "GR",
    iata_code: "KGS",
    city_name: "Kos",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kosipe Airport",
    longitude: 147.209762,
    latitude: -8.450952,
    id: "arp_ksp_pg",
    icao_code: "AYOP",
    iata_country_code: "PG",
    iata_code: "KSP",
    city_name: "Kosipe",
    city: null,
  },
  {
    time_zone: "Pacific/Kosrae",
    name: "Kosrae International Airport",
    longitude: 162.956945,
    latitude: 5.355129,
    id: "arp_ksa_fm",
    icao_code: "PTSA",
    iata_country_code: "FM",
    iata_code: "KSA",
    city_name: "Kosrae",
    city: null,
  },
  {
    time_zone: "Asia/Qostanay",
    name: "Kostanay Airport",
    longitude: 63.550478,
    latitude: 53.20673,
    id: "arp_ksn_kz",
    icao_code: "UAUU",
    iata_country_code: "KZ",
    iata_code: "KSN",
    city_name: "Kostanay",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Kostroma Airport",
    longitude: 41.013133,
    latitude: 57.807447,
    id: "arp_kmw_ru",
    icao_code: "UUBA",
    iata_country_code: "RU",
    iata_code: "KMW",
    city_name: "Kostroma",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Koszalin-Zegrze Pomorskie Airport",
    longitude: 16.267814,
    latitude: 54.04219,
    id: "arp_osz_pl",
    icao_code: "EPKZ",
    iata_country_code: "PL",
    iata_code: "OSZ",
    city_name: "Koszalin",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Kota Airport",
    longitude: 75.846585,
    latitude: 25.161621,
    id: "arp_ktu_in",
    icao_code: "VIKO",
    iata_country_code: "IN",
    iata_code: "KTU",
    city_name: "Kota",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Kotabangun Airport",
    longitude: 116.587631,
    latitude: -0.252347,
    id: "arp_kod_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "KOD",
    city_name: "Kotabangun",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Kotabaru Airport",
    longitude: 116.165326,
    latitude: -3.295371,
    id: "arp_kbu_id",
    icao_code: "WAOK",
    iata_country_code: "ID",
    iata_code: "KBU",
    city_name: "Kotabaru",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Kota Kinabalu International Airport",
    longitude: 116.05015,
    latitude: 5.92272,
    id: "arp_bki_my",
    icao_code: "WBKK",
    iata_country_code: "MY",
    iata_code: "BKI",
    city_name: "Kota Kinabalu",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Kotakoli Airport",
    longitude: 21.650899,
    latitude: 4.157639,
    id: "arp_kli_cd",
    icao_code: "FZFP",
    iata_country_code: "CD",
    iata_code: "KLI",
    city_name: "Kotakoli",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Köthen Airport",
    longitude: 11.952927,
    latitude: 51.722921,
    id: "arp_koq_de",
    icao_code: "EDCK",
    iata_country_code: "DE",
    iata_code: "KOQ",
    city_name: "Koethen",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Kotlas Airport",
    longitude: 46.697062,
    latitude: 61.236411,
    id: "arp_ksz_ru",
    icao_code: "ULKK",
    iata_country_code: "RU",
    iata_code: "KSZ",
    city_name: "Kotlas",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Kotlik Airport",
    longitude: -163.532222,
    latitude: 63.030855,
    id: "arp_kot_us",
    icao_code: "PFKO",
    iata_country_code: "US",
    iata_code: "KOT",
    city_name: "Kotlik",
    city: null,
  },
  {
    time_zone: "Africa/Accra",
    name: "Kotoka International Airport",
    longitude: -0.167454,
    latitude: 5.605642,
    id: "arp_acc_gh",
    icao_code: "DGAA",
    iata_country_code: "GH",
    iata_code: "ACC",
    city_name: "Accra",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Koulamoutou Airport",
    longitude: 12.441231,
    latitude: -1.184603,
    id: "arp_kou_ga",
    icao_code: "FOGK",
    iata_country_code: "GA",
    iata_code: "KOU",
    city_name: "Koulamoutou",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Koumac Airport",
    longitude: 164.257138,
    latitude: -20.545486,
    id: "arp_koc_nc",
    icao_code: "NWWK",
    iata_country_code: "NC",
    iata_code: "KOC",
    city_name: "Koumac",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Koumala Airport",
    longitude: 21.257277,
    latitude: 8.496861,
    id: "arp_kol_cf",
    icao_code: null,
    iata_country_code: "CF",
    iata_code: "KOL",
    city_name: "Koumala",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Koutiala Airport",
    longitude: -5.429933,
    latitude: 12.351022,
    id: "arp_ktx_ml",
    icao_code: "GAKO",
    iata_country_code: "ML",
    iata_code: "KTX",
    city_name: "Koutiala",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Kowanyama Airport",
    longitude: 141.750141,
    latitude: -15.48403,
    id: "arp_kwm_au",
    icao_code: "YKOW",
    iata_country_code: "AU",
    iata_code: "KWM",
    city_name: "Kowanyama",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Koyuk Alfred Adams Airport",
    longitude: -161.15455,
    latitude: 64.93928,
    id: "arp_kka_us",
    icao_code: "PAKK",
    iata_country_code: "US",
    iata_code: "KKA",
    city_name: "Koyuk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Koyukuk Airport",
    longitude: -157.727113,
    latitude: 64.875965,
    id: "arp_kyu_us",
    icao_code: "PFKU",
    iata_country_code: "US",
    iata_code: "KYU",
    city_name: "Koyukuk",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Kozani National Airport",
    longitude: 21.84073,
    latitude: 40.2877,
    id: "arp_kzi_gr",
    icao_code: "LGKZ",
    iata_country_code: "GR",
    iata_code: "KZI",
    city_name: "Kozani",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Krabi Airport",
    longitude: 98.984696,
    latitude: 8.0992,
    id: "arp_kbv_th",
    icao_code: "VTSG",
    iata_country_code: "TH",
    iata_code: "KBV",
    city_name: "Krabi",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Krakor Airport",
    longitude: 104.148483,
    latitude: 12.539043,
    id: "arp_kzd_kh",
    icao_code: null,
    iata_country_code: "KH",
    iata_code: "KZD",
    city_name: "Krakor",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Kraków John Paul II International Airport",
    longitude: 19.786459,
    latitude: 50.07734,
    id: "arp_krk_pl",
    icao_code: "EPKK",
    iata_country_code: "PL",
    iata_code: "KRK",
    city_name: "Kraków",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kramatorsk Airport",
    longitude: 37.628898,
    latitude: 48.7056,
    id: "arp_krq_ua",
    icao_code: "UKCK",
    iata_country_code: "UA",
    iata_code: "KRQ",
    city_name: "Kramatorsk",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Kramfors-Sollefteå Airport",
    longitude: 17.767501,
    latitude: 63.048655,
    id: "arp_krf_se",
    icao_code: "ESNK",
    iata_country_code: "SE",
    iata_code: "KRF",
    city_name: "Kramfors/Sollefteå",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Krasnodar International Airport",
    longitude: 39.141728,
    latitude: 45.034209,
    id: "arp_krr_ru",
    icao_code: "URKK",
    iata_country_code: "RU",
    iata_code: "KRR",
    city_name: "Krasnodar",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Krasnoselkup Airport",
    longitude: 82.455032,
    latitude: 65.716916,
    id: "arp_kkq_ru",
    icao_code: "USDP",
    iata_country_code: "RU",
    iata_code: "KKQ",
    city_name: "Krasnoselkup",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Krasnoyarsk International Airport",
    longitude: 92.487349,
    latitude: 56.174843,
    id: "arp_kja_ru",
    icao_code: "UNKL",
    iata_country_code: "RU",
    iata_code: "KJA",
    city_name: "Krasnoyarsk",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Kratie Airport",
    longitude: 106.054944,
    latitude: 12.488844,
    id: "arp_kti_kh",
    icao_code: "VDKT",
    iata_country_code: "KH",
    iata_code: "KTI",
    city_name: "Kratie",
    city: null,
  },
  {
    time_zone: "Asia/Qyzylorda",
    name: "Krayniy Airport",
    longitude: 63.210793,
    latitude: 45.621977,
    id: "arp_bxy_kz",
    icao_code: "UAOL",
    iata_country_code: "KZ",
    iata_code: "BXY",
    city_name: "Baikonur",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kremenchuk Airport",
    longitude: 33.477348,
    latitude: 49.125357,
    id: "arp_khu_ua",
    icao_code: null,
    iata_country_code: "UA",
    iata_code: "KHU",
    city_name: "Kremenchuk",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Kribi Airport",
    longitude: 9.977674,
    latitude: 2.873938,
    id: "arp_kbi_cm",
    icao_code: "FKKB",
    iata_country_code: "CM",
    iata_code: "KBI",
    city_name: "Kribi",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Kristiansand Kjevik Airport",
    longitude: 8.08457,
    latitude: 58.204016,
    id: "arp_krs_no",
    icao_code: "ENCN",
    iata_country_code: "NO",
    iata_code: "KRS",
    city_name: "Kristiansand",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Kristianstad Airport",
    longitude: 14.084364,
    latitude: 55.922008,
    id: "arp_kid_se",
    icao_code: "ESMK",
    iata_country_code: "SE",
    iata_code: "KID",
    city_name: "Kristianstad",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Kristiansund Airport, Kvernberget",
    longitude: 7.825248,
    latitude: 63.112337,
    id: "arp_ksu_no",
    icao_code: "ENKB",
    iata_country_code: "NO",
    iata_code: "KSU",
    city_name: "Kristiansund",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kropyvnytskyi Airport",
    longitude: 32.283602,
    latitude: 48.543708,
    id: "arp_kgo_ua",
    icao_code: "UKKG",
    iata_country_code: "UA",
    iata_code: "KGO",
    city_name: "Kirovograd",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Kruger Mpumalanga International Airport",
    longitude: 31.103765,
    latitude: -25.385259,
    id: "arp_mqp_za",
    icao_code: "FAKN",
    iata_country_code: "ZA",
    iata_code: "MQP",
    city_name: "Nelspruit",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Krymska Airport",
    longitude: 38.001389,
    latitude: 44.964444,
    id: "arp_noi_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "NOI",
    city_name: "Novorossiysk",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kryvyi Rih International Airport",
    longitude: 33.212293,
    latitude: 48.044803,
    id: "arp_kwg_ua",
    icao_code: "UKDR",
    iata_country_code: "UA",
    iata_code: "KWG",
    city_name: "Kryvyi Rih",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Kuala Lumpur International Airport",
    longitude: 101.709052,
    latitude: 2.745585,
    id: "arp_kul_my",
    icao_code: "WMKK",
    iata_country_code: "MY",
    iata_code: "KUL",
    city_name: "Kuala Lumpur",
    city: {
      name: "Kuala Lumpur",
      id: "cit_kul_my",
      iata_country_code: "MY",
      iata_code: "KUL",
    },
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Kualanamu International Airport",
    longitude: 98.87949,
    latitude: 3.636405,
    id: "arp_kno_id",
    icao_code: "WIMM",
    iata_country_code: "ID",
    iata_code: "KNO",
    city_name: "Medan",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Kuala Pembuang Airport",
    longitude: 112.540761,
    latitude: -3.37725,
    id: "arp_klp_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "KLP",
    city_name: "Seruyan",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Kubin Airport",
    longitude: 142.219058,
    latitude: -10.225792,
    id: "arp_kug_au",
    icao_code: "YKUB",
    iata_country_code: "AU",
    iata_code: "KUG",
    city_name: "Kubin Island",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Kuching International Airport",
    longitude: 110.346462,
    latitude: 1.485651,
    id: "arp_kch_my",
    icao_code: "WBGG",
    iata_country_code: "MY",
    iata_code: "KCH",
    city_name: "Kuching",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Kudat Airport",
    longitude: 116.836401,
    latitude: 6.923033,
    id: "arp_kud_my",
    icao_code: "WBKT",
    iata_country_code: "MY",
    iata_code: "KUD",
    city_name: "Kudat",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Kufra Airport",
    longitude: 23.313777,
    latitude: 24.178021,
    id: "arp_akf_ly",
    icao_code: "HLKF",
    iata_country_code: "LY",
    iata_code: "AKF",
    city_name: "Kufra",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Kugaaruk Airport",
    longitude: -89.803571,
    latitude: 68.535784,
    id: "arp_ybb_ca",
    icao_code: "CYBB",
    iata_country_code: "CA",
    iata_code: "YBB",
    city_name: "Kugaaruk",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Kugluktuk Airport",
    longitude: -115.140826,
    latitude: 67.816851,
    id: "arp_yco_ca",
    icao_code: "CYCO",
    iata_country_code: "CA",
    iata_code: "YCO",
    city_name: "Kugluktuk",
    city: null,
  },
  {
    time_zone: "Pacific/Tongatapu",
    name: "Kuini Lavenia Airport",
    longitude: -173.791149,
    latitude: -15.977256,
    id: "arp_ntt_to",
    icao_code: "NFTP",
    iata_country_code: "TO",
    iata_code: "NTT",
    city_name: "Niuatoputapu",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Kuito Airport",
    longitude: 16.948226,
    latitude: -12.404364,
    id: "arp_svp_ao",
    icao_code: "FNKU",
    iata_country_code: "AO",
    iata_code: "SVP",
    city_name: "Kuito",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Kukundu Airport",
    longitude: 156.9482,
    latitude: -8.027174,
    id: "arp_kue_sb",
    icao_code: "AGKU",
    iata_country_code: "SB",
    iata_code: "KUE",
    city_name: "Kolombangara Island",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Kulgera Airport",
    longitude: 133.293636,
    latitude: -25.842166,
    id: "arp_kgr_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "KGR",
    city_name: "Kulgera",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kulik Lake Airport",
    longitude: -155.110135,
    latitude: 58.965678,
    id: "arp_lkk_us",
    icao_code: "PAKL",
    iata_country_code: "US",
    iata_code: "LKK",
    city_name: "Kulik Lake",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kullu Manali Airport",
    longitude: 77.153178,
    latitude: 31.876835,
    id: "arp_kuu_in",
    icao_code: "VIBR",
    iata_country_code: "IN",
    iata_code: "KUU",
    city_name: "Kullu",
    city: null,
  },
  {
    time_zone: "Asia/Dushanbe",
    name: "Kulob Airport",
    longitude: 69.805068,
    latitude: 37.987106,
    id: "arp_tju_tj",
    icao_code: "UTDK",
    iata_country_code: "TJ",
    iata_code: "TJU",
    city_name: "Kulob",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Kulusuk Airport",
    longitude: -37.127826,
    latitude: 65.57368,
    id: "arp_kus_gl",
    icao_code: "BGKK",
    iata_country_code: "GL",
    iata_code: "KUS",
    city_name: "Kulusuk",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kumamoto Airport",
    longitude: 130.856698,
    latitude: 32.836135,
    id: "arp_kmj_jp",
    icao_code: "RJFT",
    iata_country_code: "JP",
    iata_code: "KMJ",
    city_name: "Kumamoto",
    city: null,
  },
  {
    time_zone: "Africa/Accra",
    name: "Kumasi Airport",
    longitude: -1.591332,
    latitude: 6.714014,
    id: "arp_kms_gh",
    icao_code: "DGSI",
    iata_country_code: "GH",
    iata_code: "KMS",
    city_name: "Kumasi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kumejima Airport",
    longitude: 126.71578,
    latitude: 26.364195,
    id: "arp_ueo_jp",
    icao_code: "ROKJ",
    iata_country_code: "JP",
    iata_code: "UEO",
    city_name: "Kumejima",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Kunduz Airport",
    longitude: 68.910391,
    latitude: 36.663845,
    id: "arp_und_af",
    icao_code: "OAUZ",
    iata_country_code: "AF",
    iata_code: "UND",
    city_name: "Kunduz",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kungim Airport",
    longitude: 141.029909,
    latitude: -5.671096,
    id: "arp_kgm_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KGM",
    city_name: "Kungim",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Kunming Changshui International Airport",
    longitude: 102.929166,
    latitude: 25.101944,
    id: "arp_kmg_cn",
    icao_code: "ZPPP",
    iata_country_code: "CN",
    iata_code: "KMG",
    city_name: "Kunming",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Kunovice Airport",
    longitude: 17.440976,
    latitude: 49.032051,
    id: "arp_uhe_cz",
    icao_code: "LKKU",
    iata_country_code: "CZ",
    iata_code: "UHE",
    city_name: "Uherske Hradiste",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kunua Airport",
    longitude: 154.739834,
    latitude: -5.782744,
    id: "arp_ktk_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KTK",
    city_name: "Kunua",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kuopio Airport",
    longitude: 27.797498,
    latitude: 63.006813,
    id: "arp_kuo_fi",
    icao_code: "EFKU",
    iata_country_code: "FI",
    iata_code: "KUO",
    city_name: "Kuopio",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kupiano Airport",
    longitude: 148.216592,
    latitude: -10.072271,
    id: "arp_kup_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KUP",
    city_name: "Kupiano",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Kuqa Qiuci Airport",
    longitude: 82.874176,
    latitude: 41.679458,
    id: "arp_kca_cn",
    icao_code: "ZWKC",
    iata_country_code: "CN",
    iata_code: "KCA",
    city_name: "Kuqa",
    city: null,
  },
  {
    time_zone: "Europe/Tallinn",
    name: "Kuressaare Airport",
    longitude: 22.507343,
    latitude: 58.231004,
    id: "arp_ure_ee",
    icao_code: "EEKE",
    iata_country_code: "EE",
    iata_code: "URE",
    city_name: "Kuressaare",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Kurgan Airport",
    longitude: 65.415603,
    latitude: 55.475299,
    id: "arp_kro_ru",
    icao_code: "USUU",
    iata_country_code: "RU",
    iata_code: "KRO",
    city_name: "Kurgan",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Kuria Airport",
    longitude: 173.442001,
    latitude: 0.218611,
    id: "arp_kuc_ki",
    icao_code: "NGKT",
    iata_country_code: "KI",
    iata_code: "KUC",
    city_name: "Kuria",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kuri Airport",
    longitude: 143.273233,
    latitude: -7.134458,
    id: "arp_kuq_pg",
    icao_code: "AYKU",
    iata_country_code: "PG",
    iata_code: "KUQ",
    city_name: "Kuri",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Kursk Vostochny Airport",
    longitude: 36.291247,
    latitude: 51.750206,
    id: "arp_urs_ru",
    icao_code: "UUOK",
    iata_country_code: "RU",
    iata_code: "URS",
    city_name: "Kursk",
    city: null,
  },
  {
    time_zone: "Europe/Samara",
    name: "Kurumoch International Airport",
    longitude: 50.159999,
    latitude: 53.505375,
    id: "arp_kuf_ru",
    icao_code: "UWWW",
    iata_country_code: "RU",
    iata_code: "KUF",
    city_name: "Samara",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Kurundi Airport",
    longitude: 134.670224,
    latitude: -20.510352,
    id: "arp_krd_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "KRD",
    city_name: "Kurundi Station",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Kurupung Airport",
    longitude: -59.1667,
    latitude: 6.46667,
    id: "arp_kpg_gy",
    icao_code: "SYKP",
    iata_country_code: "GY",
    iata_code: "KPG",
    city_name: "Kurupung",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kurwina Airport",
    longitude: 155.333,
    latitude: -6.18333,
    id: "arp_kwv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KWV",
    city_name: "Kurwina",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Kushiro Airport",
    longitude: 144.193887,
    latitude: 43.042169,
    id: "arp_kuh_jp",
    icao_code: "RJCK",
    iata_country_code: "JP",
    iata_code: "KUH",
    city_name: "Kushiro",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Kushok Bakula Rimpochee Airport",
    longitude: 77.548573,
    latitude: 34.1387,
    id: "arp_ixl_in",
    icao_code: "VILH",
    iata_country_code: "IN",
    iata_code: "IXL",
    city_name: "Leh",
    city: null,
  },
  {
    time_zone: "Asia/Tbilisi",
    name: "Kutaisi International Airport",
    longitude: 42.465602,
    latitude: 42.181409,
    id: "arp_kut_ge",
    icao_code: "UGKO",
    iata_country_code: "GE",
    iata_code: "KUT",
    city_name: "Kutaisi",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kuujjuaq Airport",
    longitude: -68.422394,
    latitude: 58.097707,
    id: "arp_yvp_ca",
    icao_code: "CYVP",
    iata_country_code: "CA",
    iata_code: "YVP",
    city_name: "Kuujjuaq",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Kuujjuarapik Airport",
    longitude: -77.761624,
    latitude: 55.282133,
    id: "arp_ygw_ca",
    icao_code: "CYGW",
    iata_country_code: "CA",
    iata_code: "YGW",
    city_name: "Kuujjuarapik",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Kuusamo Airport",
    longitude: 29.240525,
    latitude: 65.987529,
    id: "arp_kao_fi",
    icao_code: "EFKS",
    iata_country_code: "FI",
    iata_code: "KAO",
    city_name: "Kuusamo",
    city: null,
  },
  {
    time_zone: "Asia/Kuwait",
    name: "Kuwait International Airport",
    longitude: 47.971582,
    latitude: 29.239805,
    id: "arp_kwi_kw",
    icao_code: "OKBK",
    iata_country_code: "KW",
    iata_code: "KWI",
    city_name: "Kuwait City",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Kuyol Airport",
    longitude: 141.624393,
    latitude: -5.372591,
    id: "arp_kux_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "KUX",
    city_name: "Kuyol",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Kwai Harbour Airport",
    longitude: 160.733333,
    latitude: -8.566667,
    id: "arp_kwr_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "KWR",
    city_name: "Kwai Harbour",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Kwailabesi Airport",
    longitude: 160.775127,
    latitude: -8.360508,
    id: "arp_kws_sb",
    icao_code: "AGKW",
    iata_country_code: "SB",
    iata_code: "KWS",
    city_name: "Kwailabesi",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Kwethluk Airport",
    longitude: -161.443794,
    latitude: 60.790303,
    id: "arp_kwt_us",
    icao_code: "PFKW",
    iata_country_code: "US",
    iata_code: "KWT",
    city_name: "Kwethluk",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Kwigillingok Airport",
    longitude: -163.168463,
    latitude: 59.876398,
    id: "arp_kwk_us",
    icao_code: "PAGG",
    iata_country_code: "US",
    iata_code: "KWK",
    city_name: "Kwigillingok",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Kyaukpyu Airport",
    longitude: 93.534794,
    latitude: 19.426565,
    id: "arp_kyp_mm",
    icao_code: "VYKP",
    iata_country_code: "MM",
    iata_code: "KYP",
    city_name: "Kyaukpyu",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Kyauktu Airport",
    longitude: 94.132861,
    latitude: 21.39974,
    id: "arp_kyt_mm",
    icao_code: "VYKU",
    iata_country_code: "MM",
    iata_code: "KYT",
    city_name: "Kyauktu",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Kyiv International Airport",
    longitude: 30.451571,
    latitude: 50.401941,
    id: "arp_iev_ua",
    icao_code: "UKKK",
    iata_country_code: "UA",
    iata_code: "IEV",
    city_name: "Kiev",
    city: {
      name: "Kiev",
      id: "cit_iev_ua",
      iata_country_code: "UA",
      iata_code: "IEV",
    },
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Kyzyl Airport",
    longitude: 94.402732,
    latitude: 51.671546,
    id: "arp_kyz_ru",
    icao_code: "UNKY",
    iata_country_code: "RU",
    iata_code: "KYZ",
    city_name: "Kyzyl",
    city: null,
  },
  {
    time_zone: "Asia/Qyzylorda",
    name: "Kyzylorda Airport",
    longitude: 65.590168,
    latitude: 44.709337,
    id: "arp_kzo_kz",
    icao_code: "UAOO",
    iata_country_code: "KZ",
    iata_code: "KZO",
    city_name: "DescriptionKyzylorda",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "La Araucanía Airport",
    longitude: -72.649951,
    latitude: -38.926321,
    id: "arp_zco_cl",
    icao_code: "SCQP",
    iata_country_code: "CL",
    iata_code: "ZCO",
    city_name: "Temuco",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "La Aurora International Airport",
    longitude: -90.527535,
    latitude: 14.58349,
    id: "arp_gua_gt",
    icao_code: "MGGT",
    iata_country_code: "GT",
    iata_code: "GUA",
    city_name: "Guatemala City",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Labasa Airport",
    longitude: 179.339268,
    latitude: -16.46636,
    id: "arp_lbs_fj",
    icao_code: "NFNL",
    iata_country_code: "FJ",
    iata_code: "LBS",
    city_name: "Labasa",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "La Baule-Escoublac Airport",
    longitude: -2.346653,
    latitude: 47.288421,
    id: "arp_lby_fr",
    icao_code: "LFRE",
    iata_country_code: "FR",
    iata_code: "LBY",
    city_name: "La Baule-Escoublac",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lab Lab Airport",
    longitude: 148.060516,
    latitude: -5.722895,
    id: "arp_lab_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LAB",
    city_name: "Lab Lab",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Labo Airport",
    longitude: 123.843425,
    latitude: 8.179761,
    id: "arp_ozc_ph",
    icao_code: "RPMO",
    iata_country_code: "PH",
    iata_code: "OZC",
    city_name: "Ozamis",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Lábrea Airport",
    longitude: -64.770088,
    latitude: -7.279618,
    id: "arp_lbr_br",
    icao_code: "SWLB",
    iata_country_code: "BR",
    iata_code: "LBR",
    city_name: "Lábrea",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Labuan Airport",
    longitude: 115.249625,
    latitude: 5.297558,
    id: "arp_lbu_my",
    icao_code: "WBKL",
    iata_country_code: "MY",
    iata_code: "LBU",
    city_name: "Labuan",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Lac Brochet Airport",
    longitude: -101.467757,
    latitude: 58.6176,
    id: "arp_xlb_ca",
    icao_code: "CZWH",
    iata_country_code: "CA",
    iata_code: "XLB",
    city_name: "Lac Brochet",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "La Chinita International Airport",
    longitude: -71.726928,
    latitude: 10.5573,
    id: "arp_mar_ve",
    icao_code: "SVMC",
    iata_country_code: "VE",
    iata_code: "MAR",
    city_name: "Maracaibo",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "La Chorrera Airport",
    longitude: -72.802555,
    latitude: -1.458725,
    id: "arp_lcr_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "LCR",
    city_name: "La Chorrera",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lackland Air Force Base",
    longitude: -98.58024,
    latitude: 29.383475,
    id: "arp_skf_us",
    icao_code: "KSKF",
    iata_country_code: "US",
    iata_code: "SKF",
    city_name: "San Antonio",
    city: {
      name: "San Antonio",
      id: "cit_sat_us",
      iata_country_code: "US",
      iata_code: "SAT",
    },
  },
  {
    time_zone: "America/Edmonton",
    name: "Lac La Biche Airport",
    longitude: -112.031758,
    latitude: 54.77012,
    id: "arp_ylb_ca",
    icao_code: "CYLB",
    iata_country_code: "CA",
    iata_code: "YLB",
    city_name: "Lac La Biche",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "La Coloma Airport",
    longitude: -83.641852,
    latitude: 22.337305,
    id: "arp_lcl_cu",
    icao_code: "MULM",
    iata_country_code: "CU",
    iata_code: "LCL",
    city_name: "Pinar del Rio",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Laconia Municipal Airport",
    longitude: -71.420657,
    latitude: 43.572967,
    id: "arp_lci_us",
    icao_code: "KLCI",
    iata_country_code: "US",
    iata_code: "LCI",
    city_name: "Laconia",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "La Crosse Regional Airport",
    longitude: -91.2601,
    latitude: 43.877173,
    id: "arp_lse_us",
    icao_code: "KLSE",
    iata_country_code: "US",
    iata_code: "LSE",
    city_name: "La Crosse",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "La Cumbre Airport",
    longitude: -64.527918,
    latitude: -31.010448,
    id: "arp_lcm_ar",
    icao_code: "SACC",
    iata_country_code: "AR",
    iata_code: "LCM",
    city_name: "La Cumbre",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ladd Army Airfield",
    longitude: -147.613149,
    latitude: 64.838471,
    id: "arp_fbk_us",
    icao_code: "PAFB",
    iata_country_code: "US",
    iata_code: "FBK",
    city_name: "Fairbanks",
    city: {
      name: "Fairbanks",
      id: "cit_fai_us",
      iata_country_code: "US",
      iata_code: "FAI",
    },
  },
  {
    time_zone: "America/Guadeloupe",
    name: "La Désirade Airport",
    longitude: -61.08421,
    latitude: 16.296985,
    id: "arp_dsd_gp",
    icao_code: "TFFA",
    iata_country_code: "GP",
    iata_code: "DSD",
    city_name: "Grande Anse",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Laduani Airport",
    longitude: -55.405931,
    latitude: 4.376592,
    id: "arp_ldo_sr",
    icao_code: "SMDO",
    iata_country_code: "SR",
    iata_code: "LDO",
    city_name: "Aurora",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lady Elliot Island Airport",
    longitude: 152.7153,
    latitude: -24.11315,
    id: "arp_lyt_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LYT",
    city_name: "Lady Elliot Island",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Ladysmith Airport",
    longitude: 29.749748,
    latitude: -28.581464,
    id: "arp_lay_za",
    icao_code: "FALY",
    iata_country_code: "ZA",
    iata_code: "LAY",
    city_name: "Ladysmith",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Lae Island Airport",
    longitude: 165.762222,
    latitude: 8.927892,
    id: "arp_lml_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "LML",
    city_name: "Lae Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lae Nadzab Airport",
    longitude: 146.726044,
    latitude: -6.569762,
    id: "arp_lae_pg",
    icao_code: "AYNZ",
    iata_country_code: "PG",
    iata_code: "LAE",
    city_name: "Lae",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Læsø Airport",
    longitude: 11.001504,
    latitude: 57.277167,
    id: "arp_byr_dk",
    icao_code: "EKLS",
    iata_country_code: "DK",
    iata_code: "BYR",
    city_name: "Læsø",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "La Esperanza Airport",
    longitude: -88.175001,
    latitude: 14.290833,
    id: "arp_lez_hn",
    icao_code: "MHLE",
    iata_country_code: "HN",
    iata_code: "LEZ",
    city_name: "La Esperanza",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lafayette Regional Airport",
    longitude: -91.989146,
    latitude: 30.205888,
    id: "arp_lft_us",
    icao_code: "KLFT",
    iata_country_code: "US",
    iata_code: "LFT",
    city_name: "Lafayette",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "La Florida Airport",
    longitude: -71.200976,
    latitude: -29.916213,
    id: "arp_lsc_cl",
    icao_code: "SCSE",
    iata_country_code: "CL",
    iata_code: "LSC",
    city_name: "La Serena",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "La Florida Airport",
    longitude: -78.750361,
    latitude: 1.813391,
    id: "arp_tco_co",
    icao_code: "SKCO",
    iata_country_code: "CO",
    iata_code: "TCO",
    city_name: "Tumaco",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "La Fria Airport",
    longitude: -72.269837,
    latitude: 8.238246,
    id: "arp_lfr_ve",
    icao_code: "SVLF",
    iata_country_code: "VE",
    iata_code: "LFR",
    city_name: "La Fria",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Lages Airport",
    longitude: -50.280641,
    latitude: -27.782108,
    id: "arp_laj_br",
    icao_code: "SBLJ",
    iata_country_code: "BR",
    iata_code: "LAJ",
    city_name: "Lages",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Lago Agrio Airport",
    longitude: -76.866986,
    latitude: 0.092697,
    id: "arp_lgq_ec",
    icao_code: "SENL",
    iata_country_code: "EC",
    iata_code: "LGQ",
    city_name: "Lago Agrio",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "La Gomera Airport",
    longitude: -17.213293,
    latitude: 28.030403,
    id: "arp_gmz_es",
    icao_code: "GCGM",
    iata_country_code: "ES",
    iata_code: "GMZ",
    city_name: "Alajero, La Gomera Island",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "La Grande-3 Airport",
    longitude: -76.193155,
    latitude: 53.571556,
    id: "arp_yar_ca",
    icao_code: "CYAD",
    iata_country_code: "CA",
    iata_code: "YAR",
    city_name: "La Grande-3",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "La Grande-4 Airport",
    longitude: -73.677278,
    latitude: 53.755178,
    id: "arp_yah_ca",
    icao_code: "CYAH",
    iata_country_code: "CA",
    iata_code: "YAH",
    city_name: "La Grande-4",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "La Grande Rivière Airport",
    longitude: -77.702325,
    latitude: 53.625529,
    id: "arp_ygl_ca",
    icao_code: "CYGL",
    iata_country_code: "CA",
    iata_code: "YGL",
    city_name: "La Grande Riviere",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "La Grande/Union County Airport",
    longitude: -118.007759,
    latitude: 45.289849,
    id: "arp_lgd_us",
    icao_code: "KLGD",
    iata_country_code: "US",
    iata_code: "LGD",
    city_name: "La Grande",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "LaGrange Callaway Airport",
    longitude: -85.072606,
    latitude: 33.00888,
    id: "arp_lgc_us",
    icao_code: "KLGC",
    iata_country_code: "US",
    iata_code: "LGC",
    city_name: "La Grange",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "LaGuardia Airport",
    longitude: -73.873281,
    latitude: 40.777062,
    id: "arp_lga_us",
    icao_code: "KLGA",
    iata_country_code: "US",
    iata_code: "LGA",
    city_name: "New York",
    city: {
      name: "New York",
      id: "cit_nyc_us",
      iata_country_code: "US",
      iata_code: "NYC",
    },
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Lague Airport",
    longitude: 14.533,
    latitude: -2.45,
    id: "arp_lco_cg",
    icao_code: "FCBL",
    iata_country_code: "CG",
    iata_code: "LCO",
    city_name: "Lague",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Laguindingan Airport",
    longitude: 124.457161,
    latitude: 8.610985,
    id: "arp_cgy_ph",
    icao_code: "RPMY",
    iata_country_code: "PH",
    iata_code: "CGY",
    city_name: "Cagayan de Oro",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Laguna Army Airfield",
    longitude: -114.396686,
    latitude: 32.862437,
    id: "arp_lgf_us",
    icao_code: "KLGF",
    iata_country_code: "US",
    iata_code: "LGF",
    city_name: "Yuma",
    city: {
      name: "Yuma",
      id: "cit_yum_us",
      iata_country_code: "US",
      iata_code: "YUM",
    },
  },
  {
    time_zone: "America/Montevideo",
    name: "Laguna de Los Patos International Airport",
    longitude: -57.769433,
    latitude: -34.454584,
    id: "arp_cyr_uy",
    icao_code: "SUCA",
    iata_country_code: "UY",
    iata_code: "CYR",
    city_name: "Colonia del Sacramento",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Lagunillas Airport",
    longitude: -71.725531,
    latitude: 10.556492,
    id: "arp_lgy_ve",
    icao_code: null,
    iata_country_code: "VE",
    iata_code: "LGY",
    city_name: "Lagunillas",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Lahad Datu Airport",
    longitude: 118.326471,
    latitude: 5.030622,
    id: "arp_ldu_my",
    icao_code: "WBKD",
    iata_country_code: "MY",
    iata_code: "LDU",
    city_name: "Lahad Datu",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Lahr Airport",
    longitude: 7.824375,
    latitude: 48.356294,
    id: "arp_lha_de",
    icao_code: "EDTL",
    iata_country_code: "DE",
    iata_code: "LHA",
    city_name: "Lahr",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Laiagam Airport",
    longitude: 143.488496,
    latitude: -5.493059,
    id: "arp_lgm_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LGM",
    city_name: "Laiagam",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Laï Airport",
    longitude: 16.312073,
    latitude: 9.397635,
    id: "arp_ltc_td",
    icao_code: "FTTH",
    iata_country_code: "TD",
    iata_code: "LTC",
    city_name: "Lai",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "La Isabela International Airport",
    longitude: -69.983683,
    latitude: 18.574136,
    id: "arp_jbq_do",
    icao_code: "MDJB",
    iata_country_code: "DO",
    iata_code: "JBQ",
    city_name: "La Isabela",
    city: {
      name: "Santo Domingo",
      id: "cit_sdq_do",
      iata_country_code: "DO",
      iata_code: "SDQ",
    },
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Lajes Airport",
    longitude: -27.088871,
    latitude: 38.75784,
    id: "arp_ter_pt",
    icao_code: "LPLA",
    iata_country_code: "PT",
    iata_code: "TER",
    city_name: "Terceira Island/Praia da Vitória",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Lakeba Island Airport",
    longitude: -178.816973,
    latitude: -18.199182,
    id: "arp_lkb_fj",
    icao_code: "NFNK",
    iata_country_code: "FJ",
    iata_code: "LKB",
    city_name: "Lakeba Island",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Lake Baringo Airport",
    longitude: 36.113482,
    latitude: 0.667423,
    id: "arp_lbn_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "LBN",
    city_name: "Lake Baringo",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Lake Brooks Airport",
    longitude: -155.777275,
    latitude: 58.554375,
    id: "arp_bkf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BKF",
    city_name: "Katmai National Park",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lake Charles Regional Airport",
    longitude: -93.222417,
    latitude: 30.12451,
    id: "arp_lch_us",
    icao_code: "KLCH",
    iata_country_code: "US",
    iata_code: "LCH",
    city_name: "Lake Charles",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lake City Gateway Airport",
    longitude: -82.577514,
    latitude: 30.18221,
    id: "arp_lcq_us",
    icao_code: "KLCQ",
    iata_country_code: "US",
    iata_code: "LCQ",
    city_name: "Lake City",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lake County Airport",
    longitude: -120.398587,
    latitude: 42.162783,
    id: "arp_lkv_us",
    icao_code: "KLKV",
    iata_country_code: "US",
    iata_code: "LKV",
    city_name: "Lakeview",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lake County Airport",
    longitude: -106.316113,
    latitude: 39.222257,
    id: "arp_lxv_us",
    icao_code: "KLXV",
    iata_country_code: "US",
    iata_code: "LXV",
    city_name: "Leadville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lake Cumberland Regional Airport",
    longitude: -84.614376,
    latitude: 37.054282,
    id: "arp_sme_us",
    icao_code: "KSME",
    iata_country_code: "US",
    iata_code: "SME",
    city_name: "Somerset",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Lake Evella Airport",
    longitude: 135.806047,
    latitude: -12.499025,
    id: "arp_lel_au",
    icao_code: "YLEV",
    iata_country_code: "AU",
    iata_code: "LEL",
    city_name: "Gapuwiyak",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lakefield Airport",
    longitude: 144.202929,
    latitude: -14.920705,
    id: "arp_lfp_au",
    icao_code: "YLFD",
    iata_country_code: "AU",
    iata_code: "LFP",
    city_name: "Lakefield",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lakefront Airport",
    longitude: -90.027564,
    latitude: 30.041929,
    id: "arp_new_us",
    icao_code: "KNEW",
    iata_country_code: "US",
    iata_code: "NEW",
    city_name: "New Orleans",
    city: {
      name: "New Orleans",
      id: "cit_msy_us",
      iata_country_code: "US",
      iata_code: "MSY",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Lake Gregory Airport",
    longitude: 127.618767,
    latitude: -20.108619,
    id: "arp_lge_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LGE",
    city_name: "Lake Gregory",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Lake Havasu City Airport",
    longitude: -114.357968,
    latitude: 34.569474,
    id: "arp_hii_us",
    icao_code: "KHII",
    iata_country_code: "US",
    iata_code: "HII",
    city_name: "Lake Havasu City",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lakehurst Maxfield Field Airport",
    longitude: -74.353302,
    latitude: 40.033298,
    id: "arp_nel_us",
    icao_code: "KNEL",
    iata_country_code: "US",
    iata_code: "NEL",
    city_name: "Lakehurst",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lakeland Airport",
    longitude: -89.730881,
    latitude: 45.927673,
    id: "arp_arv_us",
    icao_code: "KARV",
    iata_country_code: "US",
    iata_code: "ARV",
    city_name: "Woodruff/Minocqua",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lakeland Downs Airport",
    longitude: 144.8492,
    latitude: -15.841545,
    id: "arp_lkd_au",
    icao_code: "YLND",
    iata_country_code: "AU",
    iata_code: "LKD",
    city_name: "Lakeland Downs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lakeland Linder International Airport",
    longitude: -82.016393,
    latitude: 27.99155,
    id: "arp_lal_us",
    icao_code: "KLAL",
    iata_country_code: "US",
    iata_code: "LAL",
    city_name: "Lakeland",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Lake Macquarie Airport",
    longitude: 151.64902,
    latitude: -33.066422,
    id: "arp_beo_au",
    icao_code: "YLMQ",
    iata_country_code: "AU",
    iata_code: "BEO",
    city_name: "Newcastle",
    city: {
      name: "Newcastle",
      id: "cit_ntl_au",
      iata_country_code: "AU",
      iata_code: "NTL",
    },
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Lake Manyara Airport",
    longitude: 35.817635,
    latitude: -3.375538,
    id: "arp_lky_tz",
    icao_code: "HTLM",
    iata_country_code: "TZ",
    iata_code: "LKY",
    city_name: "Lake Manyara",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lake Murray Airport",
    longitude: 141.518704,
    latitude: -5.444423,
    id: "arp_lmy_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LMY",
    city_name: "Lake Murray",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Lake Nash Airport",
    longitude: 137.917922,
    latitude: -20.980042,
    id: "arp_lnh_au",
    icao_code: "YLKN",
    iata_country_code: "AU",
    iata_code: "LNH",
    city_name: "Lake Nash",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lake Placid Airport",
    longitude: -73.962865,
    latitude: 44.265135,
    id: "arp_lkp_us",
    icao_code: "KLKP",
    iata_country_code: "US",
    iata_code: "LKP",
    city_name: "Lake Placid",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Lake Simcoe Regional Airport",
    longitude: -79.552463,
    latitude: 44.486251,
    id: "arp_ylk_ca",
    icao_code: "CYLS",
    iata_country_code: "CA",
    iata_code: "YLK",
    city_name: "Barrie-Orillia",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lake Tahoe Airport",
    longitude: -119.99618,
    latitude: 38.89358,
    id: "arp_tvl_us",
    icao_code: "KTVL",
    iata_country_code: "US",
    iata_code: "TVL",
    city_name: "South Lake Tahoe",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Lakselv Banak Airport",
    longitude: 24.977788,
    latitude: 70.067518,
    id: "arp_lkl_no",
    icao_code: "ENNA",
    iata_country_code: "NO",
    iata_code: "LKL",
    city_name: "Lakselv",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Lal Bahadur Shastri Airport",
    longitude: 82.859106,
    latitude: 25.451415,
    id: "arp_vns_in",
    icao_code: "VEBN",
    iata_country_code: "IN",
    iata_code: "VNS",
    city_name: "Varanasi",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Lalibela Airport",
    longitude: 38.981398,
    latitude: 11.975591,
    id: "arp_lli_et",
    icao_code: "HALL",
    iata_country_code: "ET",
    iata_code: "LLI",
    city_name: "Lalibela",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "L'alpe D'huez Airport",
    longitude: 6.084731,
    latitude: 45.08859,
    id: "arp_ahz_fr",
    icao_code: "LFHU",
    iata_country_code: "FR",
    iata_code: "AHZ",
    city_name: "L'Alpe-d'Huez",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "La Macarena Airport",
    longitude: -73.787086,
    latitude: 2.177157,
    id: "arp_lmc_co",
    icao_code: "SKNA",
    iata_country_code: "CO",
    iata_code: "LMC",
    city_name: "La Macarena",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lamar Municipal Airport",
    longitude: -102.689637,
    latitude: 38.071509,
    id: "arp_laa_us",
    icao_code: "KLAA",
    iata_country_code: "US",
    iata_code: "LAA",
    city_name: "Lamar",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lamassa Airport",
    longitude: 152.380016,
    latitude: -4.340846,
    id: "arp_lmg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LMG",
    city_name: "Lamassa",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Lambarene Airport",
    longitude: 10.243928,
    latitude: -0.703441,
    id: "arp_lbq_ga",
    icao_code: "FOGR",
    iata_country_code: "GA",
    iata_code: "LBQ",
    city_name: "Lambarene",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Lamen Bay Airport",
    longitude: 168.159323,
    latitude: -16.584974,
    id: "arp_lnb_vu",
    icao_code: "NVSM",
    iata_country_code: "VU",
    iata_code: "LNB",
    city_name: "Lamen Bay",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Lamerd Airport",
    longitude: 53.189191,
    latitude: 27.372685,
    id: "arp_lfm_ir",
    icao_code: "OISR",
    iata_country_code: "IR",
    iata_code: "LFM",
    city_name: "Lamerd",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Lamezia Terme International Airport",
    longitude: 16.242667,
    latitude: 38.905794,
    id: "arp_suf_it",
    icao_code: "LICA",
    iata_country_code: "IT",
    iata_code: "SUF",
    city_name: "Lamezia Terme",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Lamidanda Airport",
    longitude: 86.671206,
    latitude: 27.252875,
    id: "arp_ldn_np",
    icao_code: "VNLD",
    iata_country_code: "NP",
    iata_code: "LDN",
    city_name: "Lamidanda",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "La Môle Airport",
    longitude: 6.481161,
    latitude: 43.20512,
    id: "arp_ltt_fr",
    icao_code: "LFTZ",
    iata_country_code: "FR",
    iata_code: "LTT",
    city_name: "La Môle",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Lampang Airport",
    longitude: 99.504352,
    latitude: 18.274119,
    id: "arp_lpt_th",
    icao_code: "VTCL",
    iata_country_code: "TH",
    iata_code: "LPT",
    city_name: "Lampang",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Lampedusa Airport",
    longitude: 12.61815,
    latitude: 35.498828,
    id: "arp_lmp_it",
    icao_code: "LICD",
    iata_country_code: "IT",
    iata_code: "LMP",
    city_name: "Lampedusa",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lampson Field",
    longitude: -122.897923,
    latitude: 38.989475,
    id: "arp_cke_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CKE",
    city_name: "Lakeport",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Lanai Airport",
    longitude: -156.951181,
    latitude: 20.785535,
    id: "arp_lny_us",
    icao_code: "PHNY",
    iata_country_code: "US",
    iata_code: "LNY",
    city_name: "Lanai City",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lancang Jingmai Airport",
    longitude: 99.786451,
    latitude: 22.41603,
    id: "arp_jmj_cn",
    icao_code: "ZPJM",
    iata_country_code: "CN",
    iata_code: "JMJ",
    city_name: "Pu'er",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lancaster Airport",
    longitude: -76.297775,
    latitude: 40.120238,
    id: "arp_lns_us",
    icao_code: "KLNS",
    iata_country_code: "US",
    iata_code: "LNS",
    city_name: "Lancaster",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Landivisiau Airport",
    longitude: -4.418487,
    latitude: 48.447004,
    id: "arp_ldv_fr",
    icao_code: "LFRJ",
    iata_country_code: "FR",
    iata_code: "LDV",
    city_name: "Landivisiau",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Land's End Airport",
    longitude: -5.669229,
    latitude: 50.102499,
    id: "arp_leq_gb",
    icao_code: "EGHC",
    iata_country_code: "GB",
    iata_code: "LEQ",
    city_name: "Land's End",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Langebaanweg Airport",
    longitude: 18.157122,
    latitude: -32.97219,
    id: "arp_sdb_za",
    icao_code: "FALW",
    iata_country_code: "ZA",
    iata_code: "SDB",
    city_name: "Langebaanweg",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Langeoog Airport",
    longitude: 7.496937,
    latitude: 53.742267,
    id: "arp_lgo_de",
    icao_code: "EDWL",
    iata_country_code: "DE",
    iata_code: "LGO",
    city_name: "Langeoog",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Langimar Airport",
    longitude: 147.598867,
    latitude: -9.13714,
    id: "arp_lnm_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LNM",
    city_name: "Langimar",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Langkawi International Airport",
    longitude: 99.731555,
    latitude: 6.341065,
    id: "arp_lgk_my",
    icao_code: "WMKL",
    iata_country_code: "MY",
    iata_code: "LGK",
    city_name: "Langkawi",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Langley Air Force Base",
    longitude: -76.360072,
    latitude: 37.083103,
    id: "arp_lfi_us",
    icao_code: "KLFI",
    iata_country_code: "US",
    iata_code: "LFI",
    city_name: "Hampton",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Langley Regional Airport",
    longitude: -122.629903,
    latitude: 49.10127,
    id: "arp_yly_ca",
    icao_code: "CYNJ",
    iata_country_code: "CA",
    iata_code: "YLY",
    city_name: "Langley",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Langtang Airport",
    longitude: 85.359287,
    latitude: 27.697187,
    id: "arp_ltg_np",
    icao_code: "VNLT",
    iata_country_code: "NP",
    iata_code: "LTG",
    city_name: "Langtang",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Lankaran International Airport",
    longitude: 48.818,
    latitude: 38.746398,
    id: "arp_llk_az",
    icao_code: "UBBL",
    iata_country_code: "AZ",
    iata_code: "LLK",
    city_name: "Lankaran",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Lannion Airport",
    longitude: -3.471348,
    latitude: 48.75562,
    id: "arp_lai_fr",
    icao_code: "LFRO",
    iata_country_code: "FR",
    iata_code: "LAI",
    city_name: "Lannion",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Lansdowne Airport",
    longitude: 126.833,
    latitude: -17.5833,
    id: "arp_ldw_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LDW",
    city_name: "Lansdowne Station",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Lansdowne House Airport",
    longitude: -87.934231,
    latitude: 52.196087,
    id: "arp_ylh_ca",
    icao_code: "CYLH",
    iata_country_code: "CA",
    iata_code: "YLH",
    city_name: "Lansdowne House",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Lanseria International Airport",
    longitude: 27.926257,
    latitude: -25.938125,
    id: "arp_hla_za",
    icao_code: "FALA",
    iata_country_code: "ZA",
    iata_code: "HLA",
    city_name: "Johannesburg",
    city: {
      name: "Johannesburg",
      id: "cit_jnb_za",
      iata_country_code: "ZA",
      iata_code: "JNB",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "La Nubia Airport",
    longitude: -75.466199,
    latitude: 5.029437,
    id: "arp_mzl_co",
    icao_code: "SKMZ",
    iata_country_code: "CO",
    iata_code: "MZL",
    city_name: "Manizales",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Lanyu Airport",
    longitude: 121.53539,
    latitude: 22.027407,
    id: "arp_kyd_tw",
    icao_code: "RCLY",
    iata_country_code: "TW",
    iata_code: "KYD",
    city_name: "Orchid Island",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "Lanzarote Airport",
    longitude: -13.605488,
    latitude: 28.947969,
    id: "arp_ace_es",
    icao_code: "GCRR",
    iata_country_code: "ES",
    iata_code: "ACE",
    city_name: "Arrecife",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lanzhou Zhongchuan International Airport",
    longitude: 103.620002,
    latitude: 36.515134,
    id: "arp_lhw_cn",
    icao_code: "ZLLL",
    iata_country_code: "CN",
    iata_code: "LHW",
    city_name: "Lanzhou",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Laoag International Airport",
    longitude: 120.531702,
    latitude: 18.1779,
    id: "arp_lao_ph",
    icao_code: "RPLI",
    iata_country_code: "PH",
    iata_code: "LAO",
    city_name: "Laoag",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "La Palma Airport",
    longitude: -17.755269,
    latitude: 28.624428,
    id: "arp_spc_es",
    icao_code: "GCLA",
    iata_country_code: "ES",
    iata_code: "SPC",
    city_name: "Breña Baja",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "La Paz International Airport",
    longitude: -110.363019,
    latitude: 24.074072,
    id: "arp_lap_mx",
    icao_code: "MMLP",
    iata_country_code: "MX",
    iata_code: "LAP",
    city_name: "La Paz",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "La Pedrera Airport",
    longitude: -69.580328,
    latitude: -1.327369,
    id: "arp_lpd_co",
    icao_code: "SKLP",
    iata_country_code: "CO",
    iata_code: "LPD",
    city_name: "La Pedrera",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "La Plata Airport",
    longitude: -57.89393,
    latitude: -34.969763,
    id: "arp_lpg_ar",
    icao_code: "SADL",
    iata_country_code: "AR",
    iata_code: "LPG",
    city_name: "La Plata",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "La Porte Municipal Airport",
    longitude: -86.734333,
    latitude: 41.572637,
    id: "arp_lpo_us",
    icao_code: "KPPO",
    iata_country_code: "US",
    iata_code: "LPO",
    city_name: "La Porte",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Lappeenranta Airport",
    longitude: 28.150566,
    latitude: 61.044324,
    id: "arp_lpp_fi",
    icao_code: "EFLP",
    iata_country_code: "FI",
    iata_code: "LPP",
    city_name: "Lappeenranta",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "La Primavera Airport",
    longitude: -70.420623,
    latitude: 5.478151,
    id: "arp_lpe_co",
    icao_code: "SKIM",
    iata_country_code: "CO",
    iata_code: "LPE",
    city_name: "La Primavera",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "L'Aquila–Preturo Airport",
    longitude: 13.309257,
    latitude: 42.378791,
    id: "arp_qaq_it",
    icao_code: "LIAP",
    iata_country_code: "IT",
    iata_code: "QAQ",
    city_name: "L'Aquila",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Laramie Regional Airport",
    longitude: -105.673312,
    latitude: 41.314593,
    id: "arp_lar_us",
    icao_code: "KLAR",
    iata_country_code: "US",
    iata_code: "LAR",
    city_name: "Laramie",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Laredo International Airport",
    longitude: -99.460515,
    latitude: 27.544045,
    id: "arp_lrd_us",
    icao_code: "KLRD",
    iata_country_code: "US",
    iata_code: "LRD",
    city_name: "Laredo",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Larestan International Airport",
    longitude: 54.382467,
    latitude: 27.672874,
    id: "arp_lrr_ir",
    icao_code: "OISL",
    iata_country_code: "IR",
    iata_code: "LRR",
    city_name: "Lar",
    city: null,
  },
  {
    time_zone: "America/Argentina/La_Rioja",
    name: "La Rioja Airport",
    longitude: -66.795386,
    latitude: -29.38217,
    id: "arp_irj_ar",
    icao_code: "SANL",
    iata_country_code: "AR",
    iata_code: "IRJ",
    city_name: "La Rioja",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Larissa National Airport",
    longitude: 22.464975,
    latitude: 39.650236,
    id: "arp_lra_gr",
    icao_code: "LGLR",
    iata_country_code: "GR",
    iata_code: "LRA",
    city_name: "Larisa",
    city: null,
  },
  {
    time_zone: "Asia/Nicosia",
    name: "Larnaca International Airport",
    longitude: 33.622626,
    latitude: 34.873719,
    id: "arp_lca_cy",
    icao_code: "LCLK",
    iata_country_code: "CY",
    iata_code: "LCA",
    city_name: "Larnaca",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "La Rochelle-Île de Ré Airport",
    longitude: -1.195323,
    latitude: 46.178293,
    id: "arp_lrh_fr",
    icao_code: "LFBH",
    iata_country_code: "FR",
    iata_code: "LRH",
    city_name: "Île de Ré/La Rochelle",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "La Roche-sur-Yon Airport",
    longitude: -1.379889,
    latitude: 46.701823,
    id: "arp_edm_fr",
    icao_code: "LFRI",
    iata_country_code: "FR",
    iata_code: "EDM",
    city_name: "La Roche-sur-Yon",
    city: null,
  },
  {
    time_zone: "America/Blanc-Sablon",
    name: "La Romaine Airport",
    longitude: -60.679415,
    latitude: 50.259708,
    id: "arp_zgs_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZGS",
    city_name: "La Romaine",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "La Romana International Airport",
    longitude: -68.911857,
    latitude: 18.449576,
    id: "arp_lrm_do",
    icao_code: "MDLR",
    iata_country_code: "DO",
    iata_code: "LRM",
    city_name: "La Romana",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "La Ronge Airport",
    longitude: -105.264431,
    latitude: 55.149735,
    id: "arp_yvc_ca",
    icao_code: "CYVC",
    iata_country_code: "CA",
    iata_code: "YVC",
    city_name: "La Ronge",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Larsen Bay Airport",
    longitude: -153.978289,
    latitude: 57.535254,
    id: "arp_kln_us",
    icao_code: "PALB",
    iata_country_code: "US",
    iata_code: "KLN",
    city_name: "Larsen Bay",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Las Américas International Airport",
    longitude: -69.671596,
    latitude: 18.430393,
    id: "arp_sdq_do",
    icao_code: "MDSD",
    iata_country_code: "DO",
    iata_code: "SDQ",
    city_name: "Santo Domingo",
    city: {
      name: "Santo Domingo",
      id: "cit_sdq_do",
      iata_country_code: "DO",
      iata_code: "SDQ",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "La Sarre Airport",
    longitude: -79.178822,
    latitude: 48.91686,
    id: "arp_ssq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "SSQ",
    city_name: "La Sarre",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Las Breas Airport",
    longitude: -70.376882,
    latitude: -25.562298,
    id: "arp_ttc_cl",
    icao_code: "SCTT",
    iata_country_code: "CL",
    iata_code: "TTC",
    city_name: "Taltal",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Las Brujas Airport",
    longitude: -79.147202,
    latitude: 22.6213,
    id: "arp_bww_cu",
    icao_code: "MUBR",
    iata_country_code: "CU",
    iata_code: "BWW",
    city_name: "Cayo las Brujas",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Las Brujas Airport",
    longitude: -75.284594,
    latitude: 9.332629,
    id: "arp_czu_co",
    icao_code: "SKCZ",
    iata_country_code: "CO",
    iata_code: "CZU",
    city_name: "Corozal",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Las Cruces Airport",
    longitude: -73.504245,
    latitude: 7.382595,
    id: "arp_snt_co",
    icao_code: "SKRU",
    iata_country_code: "CO",
    iata_code: "SNT",
    city_name: "Sabana de Torres",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Las Cruces International Airport",
    longitude: -106.921402,
    latitude: 32.290212,
    id: "arp_lru_us",
    icao_code: "KLRU",
    iata_country_code: "US",
    iata_code: "LRU",
    city_name: "Las Cruces",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Las Flecheras Airport",
    longitude: -67.444,
    latitude: 7.883319,
    id: "arp_sfd_ve",
    icao_code: "SVSR",
    iata_country_code: "VE",
    iata_code: "SFD",
    city_name: "San Fernando De Apure",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Las Flores Airport",
    longitude: -73.974395,
    latitude: 9.045243,
    id: "arp_elb_co",
    icao_code: "SKBC",
    iata_country_code: "CO",
    iata_code: "ELB",
    city_name: "El Banco",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Las Gaviotas Airport",
    longitude: -70.925247,
    latitude: 4.550535,
    id: "arp_lgt_co",
    icao_code: "SKGA",
    iata_country_code: "CO",
    iata_code: "LGT",
    city_name: "Las Gaviotas",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Las Heras Airport",
    longitude: -68.965301,
    latitude: -46.538299,
    id: "arp_lhs_ar",
    icao_code: "SAVH",
    iata_country_code: "AR",
    iata_code: "LHS",
    city_name: "Las Heras",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Las Higueras Airport",
    longitude: -64.261299,
    latitude: -33.085098,
    id: "arp_rcu_ar",
    icao_code: "SAOC",
    iata_country_code: "AR",
    iata_code: "RCU",
    city_name: "Rio Cuarto",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Lashio Airport",
    longitude: 97.752307,
    latitude: 22.978199,
    id: "arp_lsh_mm",
    icao_code: "VYLS",
    iata_country_code: "MM",
    iata_code: "LSH",
    city_name: "Lashio",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Las Khoreh Airport",
    longitude: 48.200933,
    latitude: 11.167174,
    id: "arp_lkr_so",
    icao_code: null,
    iata_country_code: "SO",
    iata_code: "LKR",
    city_name: "Las Khoreh",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Las Malvinas Airport",
    longitude: -72.940575,
    latitude: -11.852414,
    id: "arp_vvn_pe",
    icao_code: "SPWT",
    iata_country_code: "PE",
    iata_code: "VVN",
    city_name: "Las Malvinas",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Lastourville Airport",
    longitude: 12.744703,
    latitude: -0.827697,
    id: "arp_ltl_ga",
    icao_code: "FOOR",
    iata_country_code: "GA",
    iata_code: "LTL",
    city_name: "Lastoursville",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Las Vegas Airport",
    longitude: -88.586055,
    latitude: 15.732941,
    id: "arp_lcf_gt",
    icao_code: "MGRD",
    iata_country_code: "GT",
    iata_code: "LCF",
    city_name: "Rio Dulce",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Las Vegas Municipal Airport",
    longitude: -105.143472,
    latitude: 35.653697,
    id: "arp_lvs_us",
    icao_code: "KLVS",
    iata_country_code: "US",
    iata_code: "LVS",
    city_name: "Las Vegas",
    city: null,
  },
  {
    time_zone: "America/Blanc-Sablon",
    name: "La Tabatière Airport",
    longitude: -58.975791,
    latitude: 50.829904,
    id: "arp_zlt_ca",
    icao_code: "CTU5",
    iata_country_code: "CA",
    iata_code: "ZLT",
    city_name: "La Tabatière",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "La Tontouta International Airport",
    longitude: 166.213867,
    latitude: -22.015385,
    id: "arp_nou_nc",
    icao_code: "NWWW",
    iata_country_code: "NC",
    iata_code: "NOU",
    city_name: "Noumea",
    city: {
      name: "Noumea",
      id: "cit_nou_nc",
      iata_country_code: "NC",
      iata_code: "NOU",
    },
  },
  {
    time_zone: "Australia/Hobart",
    name: "Latrobe Airport",
    longitude: 146.4,
    latitude: -41.2333,
    id: "arp_ltb_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LTB",
    city_name: "Latrobe",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Latrobe Valley Airport",
    longitude: 146.470575,
    latitude: -38.207834,
    id: "arp_tgn_au",
    icao_code: "YLTV",
    iata_country_code: "AU",
    iata_code: "TGN",
    city_name: "Morwell",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "La Tuque Airport",
    longitude: -72.787299,
    latitude: 47.412017,
    id: "arp_ylq_ca",
    icao_code: "CYLQ",
    iata_country_code: "CA",
    iata_code: "YLQ",
    city_name: "La Tuque",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Latur Airport",
    longitude: 76.465717,
    latitude: 18.410242,
    id: "arp_ltu_in",
    icao_code: "VALT",
    iata_country_code: "IN",
    iata_code: "LTU",
    city_name: "Latur",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Laucala Island Airport",
    longitude: -179.668089,
    latitude: -16.746599,
    id: "arp_luc_fj",
    icao_code: "NFNH",
    iata_country_code: "FJ",
    iata_code: "LUC",
    city_name: "Laucala Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Laughlin Air Force Base Airport",
    longitude: -100.779696,
    latitude: 29.35852,
    id: "arp_dlf_us",
    icao_code: "KDLF",
    iata_country_code: "US",
    iata_code: "DLF",
    city_name: "Del Rio",
    city: {
      name: "Del Rio",
      id: "cit_drt_us",
      iata_country_code: "US",
      iata_code: "DRT",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Laughlin/Bullhead International Airport",
    longitude: -114.558975,
    latitude: 35.156672,
    id: "arp_ifp_us",
    icao_code: "KIFP",
    iata_country_code: "US",
    iata_code: "IFP",
    city_name: "Bullhead City",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Launceston Airport",
    longitude: 147.21049,
    latitude: -41.54505,
    id: "arp_lst_au",
    icao_code: "YMLT",
    iata_country_code: "AU",
    iata_code: "LST",
    city_name: "Launceston",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "La Unión Airport",
    longitude: -88.175365,
    latitude: 14.290369,
    id: "arp_lui_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "LUI",
    city_name: "La Unión",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Laura Airport",
    longitude: 144.450182,
    latitude: -15.55586,
    id: "arp_luu_au",
    icao_code: "YLRA",
    iata_country_code: "AU",
    iata_code: "LUU",
    city_name: "Laura",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Laurence G Hanscom Field",
    longitude: -71.289219,
    latitude: 42.467823,
    id: "arp_bed_us",
    icao_code: "KBED",
    iata_country_code: "US",
    iata_code: "BED",
    city_name: "Bedford",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Laurie River Airport",
    longitude: -101.304017,
    latitude: 56.248433,
    id: "arp_lrq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "LRQ",
    city_name: "Laurie River",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Laurinburg–Maxton Airport",
    longitude: -79.365486,
    latitude: 34.791719,
    id: "arp_mxe_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MXE",
    city_name: "Maxton",
    city: null,
  },
  {
    time_zone: "America/Grenada",
    name: "Lauriston Airport",
    longitude: -61.472022,
    latitude: 12.476712,
    id: "arp_cru_gd",
    icao_code: "TGPZ",
    iata_country_code: "GD",
    iata_code: "CRU",
    city_name: "Carriacou",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Lauro Kurtz Airport",
    longitude: -52.327662,
    latitude: -28.243546,
    id: "arp_pfb_br",
    icao_code: "SBPF",
    iata_country_code: "BR",
    iata_code: "PFB",
    city_name: "Passo Fundo",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Laval-Entrammes Airport",
    longitude: -0.742024,
    latitude: 48.031452,
    id: "arp_lva_fr",
    icao_code: "LFOV",
    iata_country_code: "FR",
    iata_code: "LVA",
    city_name: "Laval",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "La Vanguardia Airport",
    longitude: -73.612984,
    latitude: 4.168426,
    id: "arp_vvc_co",
    icao_code: "SKVV",
    iata_country_code: "CO",
    iata_code: "VVC",
    city_name: "Villavicencio",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Lavan Island Airport",
    longitude: 53.355218,
    latitude: 26.810478,
    id: "arp_lvp_ir",
    icao_code: "OIBV",
    iata_country_code: "IR",
    iata_code: "LVP",
    city_name: "Lavan Island",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Laverton Airport",
    longitude: 122.423358,
    latitude: -28.614056,
    id: "arp_lvo_au",
    icao_code: "YLTN",
    iata_country_code: "AU",
    iata_code: "LVO",
    city_name: "Laverton",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Lawas Airport",
    longitude: 115.407565,
    latitude: 4.848323,
    id: "arp_lwy_my",
    icao_code: "WBGW",
    iata_country_code: "MY",
    iata_code: "LWY",
    city_name: "Lawas",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lawn Hill Airport",
    longitude: 138.637254,
    latitude: -18.566197,
    id: "arp_lwh_au",
    icao_code: "YLAH",
    iata_country_code: "AU",
    iata_code: "LWH",
    city_name: "Lawn Hill",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lawrence County Airpark",
    longitude: -82.494114,
    latitude: 38.420007,
    id: "arp_htw_us",
    icao_code: "KHTW",
    iata_country_code: "US",
    iata_code: "HTW",
    city_name: "Chesapeake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lawrence J. Timmerman Airport",
    longitude: -88.033629,
    latitude: 43.111873,
    id: "arp_mwc_us",
    icao_code: "KMWC",
    iata_country_code: "US",
    iata_code: "MWC",
    city_name: "Milwaukee",
    city: {
      name: "Milwaukee",
      id: "cit_mke_us",
      iata_country_code: "US",
      iata_code: "MKE",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Lawrence Municipal Airport",
    longitude: -95.216533,
    latitude: 39.009805,
    id: "arp_lwc_us",
    icao_code: "KLWC",
    iata_country_code: "US",
    iata_code: "LWC",
    city_name: "Lawrence",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lawrence Municipal Airport",
    longitude: -71.122203,
    latitude: 42.716446,
    id: "arp_lwm_us",
    icao_code: "KLWM",
    iata_country_code: "US",
    iata_code: "LWM",
    city_name: "Lawrence",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lawrenceville Brunswick Municipal Airport",
    longitude: -77.794438,
    latitude: 36.772417,
    id: "arp_lvl_us",
    icao_code: "KLVL",
    iata_country_code: "US",
    iata_code: "LVL",
    city_name: "Lawrenceville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lawrenceville-Vincennes International Airport",
    longitude: -87.603925,
    latitude: 38.762896,
    id: "arp_lwv_us",
    icao_code: "KLWV",
    iata_country_code: "US",
    iata_code: "LWV",
    city_name: "Lawrenceville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lawson Army Airfield",
    longitude: -84.991726,
    latitude: 32.337612,
    id: "arp_lsf_us",
    icao_code: "KLSF",
    iata_country_code: "US",
    iata_code: "LSF",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_csg_us",
      iata_country_code: "US",
      iata_code: "CSG",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Lawton-Fort Sill Regional Airport",
    longitude: -98.416256,
    latitude: 34.570183,
    id: "arp_law_us",
    icao_code: "KLAW",
    iata_country_code: "US",
    iata_code: "LAW",
    city_name: "Lawton",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Layang-Layang Airport",
    longitude: 113.841667,
    latitude: 7.372222,
    id: "arp_lac_my",
    icao_code: null,
    iata_country_code: "MY",
    iata_code: "LAC",
    city_name: "Layang-Layang Island",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Lázaro Cárdenas Airport",
    longitude: -102.22143,
    latitude: 18.00049,
    id: "arp_lzc_mx",
    icao_code: "MMLC",
    iata_country_code: "MX",
    iata_code: "LZC",
    city_name: "Lázaro Cárdenas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "LBJ Ranch Airport",
    longitude: -98.622347,
    latitude: 30.251411,
    id: "arp_jcy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "JCY",
    city_name: "Johnson City",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lea County Regional Airport",
    longitude: -103.216123,
    latitude: 32.689116,
    id: "arp_hob_us",
    icao_code: "KHOB",
    iata_country_code: "US",
    iata_code: "HOB",
    city_name: "Hobbs",
    city: {
      name: "Hobbs",
      id: "cit_hob_us",
      iata_country_code: "US",
      iata_code: "HOB",
    },
  },
  {
    time_zone: "America/Winnipeg",
    name: "Leaf Rapids Airport",
    longitude: -99.98489,
    latitude: 56.513369,
    id: "arp_ylr_ca",
    icao_code: "CYLR",
    iata_country_code: "CA",
    iata_code: "YLR",
    city_name: "Leaf Rapids",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Learmonth Airport",
    longitude: 114.090755,
    latitude: -22.237026,
    id: "arp_lea_au",
    icao_code: "YPLM",
    iata_country_code: "AU",
    iata_code: "LEA",
    city_name: "Exmouth",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Lebakeng Airport",
    longitude: 28.655441,
    latitude: -29.890662,
    id: "arp_lef_ls",
    icao_code: "FXLK",
    iata_country_code: "LS",
    iata_code: "LEF",
    city_name: "Lebakeng",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lebanon Municipal Airport",
    longitude: -72.30642,
    latitude: 43.625521,
    id: "arp_leb_us",
    icao_code: "KLEB",
    iata_country_code: "US",
    iata_code: "LEB",
    city_name: "Lebanon",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Lebel-sur-Quevillon Airport",
    longitude: -77.017071,
    latitude: 49.03015,
    id: "arp_yls_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YLS",
    city_name: "Lebel-sur-Quévillon",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Le Castellet Airport",
    longitude: 5.784179,
    latitude: 43.25164,
    id: "arp_ctt_fr",
    icao_code: "LFMQ",
    iata_country_code: "FR",
    iata_code: "CTT",
    city_name: "Le Castellet",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lee Airport",
    longitude: -76.568606,
    latitude: 38.942765,
    id: "arp_anp_us",
    icao_code: "KANP",
    iata_country_code: "US",
    iata_code: "ANP",
    city_name: "Annapolis",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lee C Fine Memorial Airport",
    longitude: -92.549812,
    latitude: 38.097454,
    id: "arp_aiz_us",
    icao_code: "KAIZ",
    iata_country_code: "US",
    iata_code: "AIZ",
    city_name: "Kaiser Lake Ozark",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Leeds Bradford Airport",
    longitude: -1.66105,
    latitude: 53.86692,
    id: "arp_lba_gb",
    icao_code: "EGNM",
    iata_country_code: "GB",
    iata_code: "LBA",
    city_name: "Leeds",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lee Gilmer Memorial Airport",
    longitude: -83.830856,
    latitude: 34.273132,
    id: "arp_gvl_us",
    icao_code: "KGVL",
    iata_country_code: "US",
    iata_code: "GVL",
    city_name: "Gainesville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Leesburg International Airport",
    longitude: -81.808606,
    latitude: 28.823031,
    id: "arp_lee_us",
    icao_code: "KLEE",
    iata_country_code: "US",
    iata_code: "LEE",
    city_name: "Leesburg",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Leeuwarden Air Base",
    longitude: 5.760246,
    latitude: 53.22754,
    id: "arp_lwr_nl",
    icao_code: "EHLW",
    iata_country_code: "NL",
    iata_code: "LWR",
    city_name: "Leeuwarden",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Leeward Point Field",
    longitude: -75.2071,
    latitude: 19.9065,
    id: "arp_nbw_cu",
    icao_code: "MUGM",
    iata_country_code: "CU",
    iata_code: "NBW",
    city_name: "Guantanamo",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Legazpi Airport",
    longitude: 123.732635,
    latitude: 13.155459,
    id: "arp_lgp_ph",
    icao_code: "RPLP",
    iata_country_code: "PH",
    iata_code: "LGP",
    city_name: "Legaspi",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Le Havre Octeville Airport",
    longitude: 0.088241,
    latitude: 49.535564,
    id: "arp_leh_fr",
    icao_code: "LFOH",
    iata_country_code: "FR",
    iata_code: "LEH",
    city_name: "Le Havre",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lehigh Valley International Airport",
    longitude: -75.440551,
    latitude: 40.652196,
    id: "arp_abe_us",
    icao_code: "KABE",
    iata_country_code: "US",
    iata_code: "ABE",
    city_name: "Allentown",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lehu Airport",
    longitude: 147.598792,
    latitude: -9.136759,
    id: "arp_lhp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LHP",
    city_name: "Lehu",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Leigh Creek Airport",
    longitude: 138.423963,
    latitude: -30.597719,
    id: "arp_lgh_au",
    icao_code: "YLEC",
    iata_country_code: "AU",
    iata_code: "LGH",
    city_name: "Leigh Creek",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Leinster Airport",
    longitude: 120.703477,
    latitude: -27.841106,
    id: "arp_ler_au",
    icao_code: "YLST",
    iata_country_code: "AU",
    iata_code: "LER",
    city_name: "Leinster",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Leipzig/Halle Airport",
    longitude: 12.222548,
    latitude: 51.422001,
    id: "arp_lej_de",
    icao_code: "EDDP",
    iata_country_code: "DE",
    iata_code: "LEJ",
    city_name: "Leipzig",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Leirin Airport",
    longitude: 9.291198,
    latitude: 61.011725,
    id: "arp_vdb_no",
    icao_code: "ENFG",
    iata_country_code: "NO",
    iata_code: "VDB",
    city_name: "Fagernes",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Leismer Airport",
    longitude: -111.281592,
    latitude: 55.696463,
    id: "arp_cfm_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "CFM",
    city_name: "Conklin",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Leite Lopes Airport",
    longitude: -47.776621,
    latitude: -21.13801,
    id: "arp_rao_br",
    icao_code: "SBRP",
    iata_country_code: "BR",
    iata_code: "RAO",
    city_name: "Ribeirão Preto",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Leitre Airport",
    longitude: 141.623641,
    latitude: -2.8349,
    id: "arp_ltf_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LTF",
    city_name: "Leitre",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Lekana Airport",
    longitude: 14.6,
    latitude: -2.31667,
    id: "arp_lkc_cg",
    icao_code: null,
    iata_country_code: "CG",
    iata_code: "LKC",
    city_name: "Lekana",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Lekhwair Airport",
    longitude: 55.374678,
    latitude: 22.805347,
    id: "arp_lkw_om",
    icao_code: "OOLK",
    iata_country_code: "OM",
    iata_code: "LKW",
    city_name: "Lekhwair",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Leknes Airport",
    longitude: 13.611679,
    latitude: 68.153242,
    id: "arp_lkn_no",
    icao_code: "ENLK",
    iata_country_code: "NO",
    iata_code: "LKN",
    city_name: "Leknes",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Lekoni Airport",
    longitude: 13.90343,
    latitude: 1.139851,
    id: "arp_leo_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "LEO",
    city_name: "Lekoni",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Lelystad Airport",
    longitude: 5.523792,
    latitude: 52.457768,
    id: "arp_ley_nl",
    icao_code: "EHLE",
    iata_country_code: "NL",
    iata_code: "LEY",
    city_name: "Lelystad",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Le Mans-Arnage Airport",
    longitude: 0.199975,
    latitude: 47.94795,
    id: "arp_lme_fr",
    icao_code: "LFRM",
    iata_country_code: "FR",
    iata_code: "LME",
    city_name: "Le Mans",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Le Mars Municipal Airport",
    longitude: -96.193641,
    latitude: 42.778037,
    id: "arp_lrj_us",
    icao_code: "KLRJ",
    iata_country_code: "US",
    iata_code: "LRJ",
    city_name: "Le Mars",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lemhi County Airport",
    longitude: -113.881138,
    latitude: 45.12372,
    id: "arp_smn_us",
    icao_code: "KSMN",
    iata_country_code: "US",
    iata_code: "SMN",
    city_name: "Salmon",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lemmon Municipal Airport",
    longitude: -102.108241,
    latitude: 45.91846,
    id: "arp_lem_us",
    icao_code: "KLEM",
    iata_country_code: "US",
    iata_code: "LEM",
    city_name: "Lemmon",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Lemnos International Airport",
    longitude: 25.236361,
    latitude: 39.920367,
    id: "arp_lxs_gr",
    icao_code: "LGLM",
    iata_country_code: "GR",
    iata_code: "LXS",
    city_name: "Lemnos",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lemoore Naval Air Station",
    longitude: -119.951756,
    latitude: 36.332464,
    id: "arp_nlc_us",
    icao_code: "KNLC",
    iata_country_code: "US",
    iata_code: "NLC",
    city_name: "Lemoore",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Lemwerder Airport",
    longitude: 8.621757,
    latitude: 53.147761,
    id: "arp_xlw_de",
    icao_code: "EDWD",
    iata_country_code: "DE",
    iata_code: "XLW",
    city_name: "Lemwerder",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Lenawee County Airport",
    longitude: -84.076566,
    latitude: 41.86922,
    id: "arp_adg_us",
    icao_code: "KADG",
    iata_country_code: "US",
    iata_code: "ADG",
    city_name: "Adrian",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Lençóis Airport",
    longitude: -41.275694,
    latitude: -12.483111,
    id: "arp_lec_br",
    icao_code: "SBLE",
    iata_country_code: "BR",
    iata_code: "LEC",
    city_name: "Lençóis",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lengbati Airport",
    longitude: 146.72566,
    latitude: -6.569722,
    id: "arp_lnc_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LNC",
    city_name: "Lengbati",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Lengpui Airport",
    longitude: 92.621146,
    latitude: 23.83964,
    id: "arp_ajl_in",
    icao_code: "VELP",
    iata_country_code: "IN",
    iata_code: "AJL",
    city_name: "Aizawl",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Lensk Airport",
    longitude: 114.828772,
    latitude: 60.721565,
    id: "arp_ulk_ru",
    icao_code: "UERL",
    iata_country_code: "RU",
    iata_code: "ULK",
    city_name: "Lensk",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Leo Airport",
    longitude: -2.097971,
    latitude: 11.09894,
    id: "arp_xlu_bf",
    icao_code: "DFCL",
    iata_country_code: "BF",
    iata_code: "XLU",
    city_name: "Leo",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "León Airport",
    longitude: -5.650962,
    latitude: 42.590108,
    id: "arp_len_es",
    icao_code: "LELN",
    iata_country_code: "ES",
    iata_code: "LEN",
    city_name: "León",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Leonard M. Thompson International Airport",
    longitude: -77.084499,
    latitude: 26.511253,
    id: "arp_mhh_bs",
    icao_code: "MYAM",
    iata_country_code: "BS",
    iata_code: "MHH",
    city_name: "Marsh Harbour",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Leonardo da Vinci-Fiumicino Airport",
    longitude: 12.242563,
    latitude: 41.800082,
    id: "arp_fco_it",
    icao_code: "LIRF",
    iata_country_code: "IT",
    iata_code: "FCO",
    city_name: "Rome",
    city: {
      name: "Rome",
      id: "cit_rom_it",
      iata_country_code: "IT",
      iata_code: "ROM",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Leonora Airport",
    longitude: 121.314844,
    latitude: -28.878545,
    id: "arp_lno_au",
    icao_code: "YLEO",
    iata_country_code: "AU",
    iata_code: "LNO",
    city_name: "Leonora",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Leopoldina Airport",
    longitude: -42.728421,
    latitude: -21.468675,
    id: "arp_lep_br",
    icao_code: "SNDN",
    iata_country_code: "BR",
    iata_code: "LEP",
    city_name: "Leopoldina",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Léopold Sédar Senghor International Airport",
    longitude: -17.490512,
    latitude: 14.742145,
    id: "arp_dkr_sn",
    icao_code: "GOOY",
    iata_country_code: "SN",
    iata_code: "DKR",
    city_name: "Dakar",
    city: {
      name: "Dakar",
      id: "cit_dkr_sn",
      iata_country_code: "SN",
      iata_code: "DKR",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Le Puy-Loudes Airport",
    longitude: 3.763004,
    latitude: 45.078089,
    id: "arp_lpy_fr",
    icao_code: "LFHP",
    iata_country_code: "FR",
    iata_code: "LPY",
    city_name: "Chaspuzac",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Lereh Airport",
    longitude: 139.95229,
    latitude: -3.079564,
    id: "arp_lhi_id",
    icao_code: "WAJL",
    iata_country_code: "ID",
    iata_code: "LHI",
    city_name: "Lereh",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Leribe Airport",
    longitude: 28.052328,
    latitude: -28.856936,
    id: "arp_lrb_ls",
    icao_code: "FXLR",
    iata_country_code: "LS",
    iata_code: "LRB",
    city_name: "Leribe",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Leron Plains Airport",
    longitude: 146.343668,
    latitude: -6.391828,
    id: "arp_lpn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LPN",
    city_name: "Leron Plains",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Leros Municipal Airport",
    longitude: 26.801121,
    latitude: 37.184016,
    id: "arp_lrs_gr",
    icao_code: "LGLE",
    iata_country_code: "GR",
    iata_code: "LRS",
    city_name: "Leros",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Lerwick Tingwall Airport",
    longitude: -1.245061,
    latitude: 60.190741,
    id: "arp_lwk_gb",
    icao_code: "EGET",
    iata_country_code: "GB",
    iata_code: "LWK",
    city_name: "Lerwick",
    city: {
      name: "Shetland Islands",
      id: "cit_sdz_gb",
      iata_country_code: "GB",
      iata_code: "SDZ",
    },
  },
  {
    time_zone: "America/Port-au-Prince",
    name: "Les Cayes Airport",
    longitude: -73.788372,
    latitude: 18.270869,
    id: "arp_cya_ht",
    icao_code: "MTCA",
    iata_country_code: "HT",
    iata_code: "CYA",
    city_name: "Les Cayes",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lese Airport",
    longitude: 146.725532,
    latitude: -6.569914,
    id: "arp_lng_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LNG",
    city_name: "Lese",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Leshukonskoye Airport",
    longitude: 45.73307,
    latitude: 64.896098,
    id: "arp_ldg_ru",
    icao_code: "ULAL",
    iata_country_code: "RU",
    iata_code: "LDG",
    city_name: "Leshukonskoye",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Lesobeng Airport",
    longitude: 28.356955,
    latitude: -29.755882,
    id: "arp_les_ls",
    icao_code: "FXLS",
    iata_country_code: "LS",
    iata_code: "LES",
    city_name: "Lesobeng",
    city: null,
  },
  {
    time_zone: "America/Marigot",
    name: "L'Espérance Airport",
    longitude: -63.047393,
    latitude: 18.100243,
    id: "arp_sfg_gp",
    icao_code: "TFFG",
    iata_country_code: "GP",
    iata_code: "SFG",
    city_name: "St Martin",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Les Sables-d'Olonne Talmont Airport",
    longitude: -1.721971,
    latitude: 46.47658,
    id: "arp_lso_fr",
    icao_code: "LFOO",
    iata_country_code: "FR",
    iata_code: "LSO",
    city_name: "Les Sables-d'Olonne",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Letfotar Airport",
    longitude: -12.5,
    latitude: 17.75,
    id: "arp_mom_mr",
    icao_code: "GQNL",
    iata_country_code: "MR",
    iata_code: "MOM",
    city_name: "Moudjeria",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Lethbridge Airport",
    longitude: -112.794588,
    latitude: 49.631269,
    id: "arp_yql_ca",
    icao_code: "CYQL",
    iata_country_code: "CA",
    iata_code: "YQL",
    city_name: "Lethbridge",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Lethem Airport",
    longitude: -59.789598,
    latitude: 3.372395,
    id: "arp_ltm_gy",
    icao_code: "SYLT",
    iata_country_code: "GY",
    iata_code: "LTM",
    city_name: "Lethem",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Le Touquet – Côte d'Opale Airport",
    longitude: 1.619972,
    latitude: 50.51586,
    id: "arp_ltq_fr",
    icao_code: "LFAT",
    iata_country_code: "FR",
    iata_code: "LTQ",
    city_name: "Le Touquet-Paris-Plage",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Letterkenny Airport",
    longitude: -7.673314,
    latitude: 54.950876,
    id: "arp_ltr_ie",
    icao_code: "EILT",
    iata_country_code: "IE",
    iata_code: "LTR",
    city_name: "Letterkenny",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Letung Airport",
    longitude: 105.754591,
    latitude: 2.96309,
    id: "arp_lmu_id",
    icao_code: "WIDL",
    iata_country_code: "ID",
    iata_code: "LMU",
    city_name: "Letung",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Levelock Airport",
    longitude: -156.859623,
    latitude: 59.127299,
    id: "arp_kll_us",
    icao_code: "WMKK",
    iata_country_code: "US",
    iata_code: "KLL",
    city_name: "Levelock",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Levuka Airfield",
    longitude: 178.758786,
    latitude: -17.711448,
    id: "arp_lev_fj",
    icao_code: "NFNB",
    iata_country_code: "FJ",
    iata_code: "LEV",
    city_name: "Levuka",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lewiston-Nez Perce County Airport",
    longitude: -117.015195,
    latitude: 46.374727,
    id: "arp_lws_us",
    icao_code: "KLWS",
    iata_country_code: "US",
    iata_code: "LWS",
    city_name: "Lewiston",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lewistown Municipal Airport",
    longitude: -109.466482,
    latitude: 47.048603,
    id: "arp_lwt_us",
    icao_code: "KLWT",
    iata_country_code: "US",
    iata_code: "LWT",
    city_name: "Lewistown",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lewis University Airport",
    longitude: -88.094674,
    latitude: 41.606241,
    id: "arp_lot_us",
    icao_code: "KLOT",
    iata_country_code: "US",
    iata_code: "LOT",
    city_name: "Romeoville",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Lewoleba Airport",
    longitude: 123.437172,
    latitude: -8.364673,
    id: "arp_lwe_id",
    icao_code: "WATW",
    iata_country_code: "ID",
    iata_code: "LWE",
    city_name: "Lewoleba",
    city: null,
  },
  {
    time_zone: "Atlantic/Bermuda",
    name: "L.F. Wade International Airport",
    longitude: -64.702302,
    latitude: 32.360172,
    id: "arp_bda_bm",
    icao_code: "TXKF",
    iata_country_code: "BM",
    iata_code: "BDA",
    city_name: "St. George",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lhasa Gonggar Airport",
    longitude: 90.911903,
    latitude: 29.2978,
    id: "arp_lxa_cn",
    icao_code: "ZULS",
    iata_country_code: "CN",
    iata_code: "LXA",
    city_name: "Lhasa",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Lhok Sukon Airport",
    longitude: 97.259405,
    latitude: 5.068989,
    id: "arp_lsx_id",
    icao_code: "WITL",
    iata_country_code: "ID",
    iata_code: "LSX",
    city_name: "Lhok Sukon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Liangping Airport",
    longitude: 107.785746,
    latitude: 30.679048,
    id: "arp_lia_cn",
    icao_code: "ZULP",
    iata_country_code: "CN",
    iata_code: "LIA",
    city_name: "Liangping",
    city: null,
  },
  {
    time_zone: null,
    name: "Lianhuashan Port Airport",
    longitude: null,
    latitude: null,
    id: "arp_ziy_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "ZIY",
    city_name: null,
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Lianshulu Airport",
    longitude: 23.392559,
    latitude: -18.116251,
    id: "arp_lhu_na",
    icao_code: "FYLS",
    iata_country_code: "NA",
    iata_code: "LHU",
    city_name: "Lianshulu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lianyungang Baitabu Airport",
    longitude: 118.881557,
    latitude: 34.565336,
    id: "arp_lyg_cn",
    icao_code: "ZSLG",
    iata_country_code: "CN",
    iata_code: "LYG",
    city_name: "Lianyungang",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Libenge Airport",
    longitude: 18.637971,
    latitude: 3.627558,
    id: "arp_lie_cd",
    icao_code: "FZFA",
    iata_country_code: "CD",
    iata_code: "LIE",
    city_name: "Libenge",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Liberal Mid-America Regional Airport",
    longitude: -100.958449,
    latitude: 37.044859,
    id: "arp_lbl_us",
    icao_code: "KLBL",
    iata_country_code: "US",
    iata_code: "LBL",
    city_name: "Liberal",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Libo Airport",
    longitude: 107.961666,
    latitude: 25.4525,
    id: "arp_llb_cn",
    icao_code: "ZULB",
    iata_country_code: "CN",
    iata_code: "LLB",
    city_name: "Libo",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Liboi Airport",
    longitude: 40.882085,
    latitude: 0.34843,
    id: "arp_lbk_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "LBK",
    city_name: "Liboi",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Libreville Airport",
    longitude: 9.411186,
    latitude: 0.458367,
    id: "arp_lbv_ga",
    icao_code: "FOOL",
    iata_country_code: "GA",
    iata_code: "LBV",
    city_name: "Libreville",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Licenciado Gustavo Díaz Ordaz International Airport",
    longitude: -105.253186,
    latitude: 20.680308,
    id: "arp_pvr_mx",
    icao_code: "MMPR",
    iata_country_code: "MX",
    iata_code: "PVR",
    city_name: "Puerto Vallarta",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Lichinga Airport",
    longitude: 35.265698,
    latitude: -13.274045,
    id: "arp_vxc_mz",
    icao_code: "FQLC",
    iata_country_code: "MZ",
    iata_code: "VXC",
    city_name: "Lichinga",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Lidköping-Hovby Airport",
    longitude: 13.153987,
    latitude: 58.461958,
    id: "arp_ldk_se",
    icao_code: "ESGL",
    iata_country_code: "SE",
    iata_code: "LDK",
    city_name: "Lidköping",
    city: null,
  },
  {
    time_zone: "Europe/Brussels",
    name: "Liège Airport",
    longitude: 5.445566,
    latitude: 50.63928,
    id: "arp_lgg_be",
    icao_code: "EBLG",
    iata_country_code: "BE",
    iata_code: "LGG",
    city_name: "Liège",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Lien Khuong Airport",
    longitude: 108.368687,
    latitude: 11.750599,
    id: "arp_dli_vn",
    icao_code: "VVDL",
    iata_country_code: "VN",
    iata_code: "DLI",
    city_name: "Đà Lạt",
    city: null,
  },
  {
    time_zone: "Europe/Riga",
    name: "Liepāja International Airport",
    longitude: 21.09602,
    latitude: 56.517917,
    id: "arp_lpx_lv",
    icao_code: "EVLA",
    iata_country_code: "LV",
    iata_code: "LPX",
    city_name: "Liepāja",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Lieutenant Colonel Rafael Pabón Airport",
    longitude: -63.405586,
    latitude: -21.255133,
    id: "arp_vlm_bo",
    icao_code: "SLVM",
    iata_country_code: "BO",
    iata_code: "VLM",
    city_name: "Villamontes",
    city: null,
  },
  {
    time_zone: "Pacific/Tongatapu",
    name: "Lifuka Island Airport",
    longitude: -174.341039,
    latitude: -19.777259,
    id: "arp_hpa_to",
    icao_code: "NFTL",
    iata_country_code: "TO",
    iata_code: "HPA",
    city_name: "Lifuka",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Lightning Ridge Airport",
    longitude: 147.982496,
    latitude: -29.454754,
    id: "arp_lhg_au",
    icao_code: "YLRD",
    iata_country_code: "AU",
    iata_code: "LHG",
    city_name: "Lightning Ridge",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lihir Island Airport",
    longitude: 152.629085,
    latitude: -3.043775,
    id: "arp_lnv_pg",
    icao_code: "AYKY",
    iata_country_code: "PG",
    iata_code: "LNV",
    city_name: "Kunaye/Londolovit",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Lihue Airport",
    longitude: -159.341381,
    latitude: 21.977424,
    id: "arp_lih_us",
    icao_code: "PHLI",
    iata_country_code: "US",
    iata_code: "LIH",
    city_name: "Lihue",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lijiang Sanyi Airport",
    longitude: 100.244915,
    latitude: 26.667657,
    id: "arp_ljg_cn",
    icao_code: "ZPLJ",
    iata_country_code: "CN",
    iata_code: "LJG",
    city_name: "Lijiang",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Likiep Airport",
    longitude: 169.304038,
    latitude: 9.822728,
    id: "arp_lik_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "LIK",
    city_name: "Likiep Island",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Likoma Island Airport",
    longitude: 34.737289,
    latitude: -12.075604,
    id: "arp_lix_mw",
    icao_code: "FWLK",
    iata_country_code: "MW",
    iata_code: "LIX",
    city_name: "Likoma Island",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Lilabari Airport",
    longitude: 94.095075,
    latitude: 27.291702,
    id: "arp_ixi_in",
    icao_code: "VELR",
    iata_country_code: "IN",
    iata_code: "IXI",
    city_name: "Lilabari",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Lille Airport",
    longitude: 3.097166,
    latitude: 50.568372,
    id: "arp_lil_fr",
    icao_code: "LFQQ",
    iata_country_code: "FR",
    iata_code: "LIL",
    city_name: "Lille",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Lilongwe International Airport",
    longitude: 33.779249,
    latitude: -13.786366,
    id: "arp_llw_mw",
    icao_code: "FWKI",
    iata_country_code: "MW",
    iata_code: "LLW",
    city_name: "Lilongwe",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lima Allen County Airport",
    longitude: -84.026744,
    latitude: 40.705863,
    id: "arp_aoh_us",
    icao_code: "KAOH",
    iata_country_code: "US",
    iata_code: "AOH",
    city_name: "Lima",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Limbang Airport",
    longitude: 115.009528,
    latitude: 4.808688,
    id: "arp_lmn_my",
    icao_code: "WBGJ",
    iata_country_code: "MY",
    iata_code: "LMN",
    city_name: "Limbang",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Limbunya Airport",
    longitude: 129.882873,
    latitude: -17.235446,
    id: "arp_lib_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LIB",
    city_name: "Limbunya Station",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Lime Acres Finsch Mine Airport",
    longitude: 23.449699,
    latitude: -28.380734,
    id: "arp_lmr_za",
    icao_code: "FALC",
    iata_country_code: "ZA",
    iata_code: "LMR",
    city_name: "Lime Acres",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Lime Village Airport",
    longitude: -155.606398,
    latitude: 62.946684,
    id: "arp_lvd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "LVD",
    city_name: "Lime Village",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Limoges – Bellegarde Airport",
    longitude: 1.17794,
    latitude: 45.862426,
    id: "arp_lig_fr",
    icao_code: "LFBL",
    iata_country_code: "FR",
    iata_code: "LIG",
    city_name: "Limoges",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Limon Airport",
    longitude: -85.55,
    latitude: 15.8667,
    id: "arp_lmh_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "LMH",
    city_name: "Limon",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Limón International Airport",
    longitude: -83.021833,
    latitude: 9.957569,
    id: "arp_lio_cr",
    icao_code: "MRLM",
    iata_country_code: "CR",
    iata_code: "LIO",
    city_name: "Puerto Limon",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Limon Municipal Airport",
    longitude: -103.66597,
    latitude: 39.274072,
    id: "arp_lic_us",
    icao_code: "KLIC",
    iata_country_code: "US",
    iata_code: "LIC",
    city_name: "Limon",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Linate Airport",
    longitude: 9.276524,
    latitude: 45.448637,
    id: "arp_lin_it",
    icao_code: "LIML",
    iata_country_code: "IT",
    iata_code: "LIN",
    city_name: "Milan",
    city: {
      name: "Milan",
      id: "cit_mil_it",
      iata_country_code: "IT",
      iata_code: "MIL",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lincang Airport",
    longitude: 100.025743,
    latitude: 23.739687,
    id: "arp_lnj_cn",
    icao_code: "ZPLC",
    iata_country_code: "CN",
    iata_code: "LNJ",
    city_name: "Lincang",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lincoln Airport",
    longitude: -96.759247,
    latitude: 40.852062,
    id: "arp_lnk_us",
    icao_code: "KLNK",
    iata_country_code: "US",
    iata_code: "LNK",
    city_name: "Lincoln",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Linda Downs Airport",
    longitude: 138.7,
    latitude: -23.016667,
    id: "arp_llp_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LLP",
    city_name: "Linda Downs",
    city: null,
  },
  {
    time_zone: "Australia/Lindeman",
    name: "Lindeman Island Airport",
    longitude: 149.040521,
    latitude: -20.449191,
    id: "arp_ldc_au",
    icao_code: "YLIN",
    iata_country_code: "AU",
    iata_code: "LDC",
    city_name: "Lindeman Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Linden Airport",
    longitude: -74.24398,
    latitude: 40.61656,
    id: "arp_ldj_us",
    icao_code: "KLDJ",
    iata_country_code: "US",
    iata_code: "LDJ",
    city_name: "Linden",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Lindi Airport",
    longitude: 39.758247,
    latitude: -9.850934,
    id: "arp_ldi_tz",
    icao_code: "HTLI",
    iata_country_code: "TZ",
    iata_code: "LDI",
    city_name: "Lindi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Linfen Qiaoli Airport",
    longitude: 111.641236,
    latitude: 36.132629,
    id: "arp_lfq_cn",
    icao_code: "ZBLF",
    iata_country_code: "CN",
    iata_code: "LFQ",
    city_name: "Linfen",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Linga Linga Airport",
    longitude: 149.732667,
    latitude: -5.533384,
    id: "arp_lgn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LGN",
    city_name: "Linga Linga",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Linköping City Airport",
    longitude: 15.658119,
    latitude: 58.408081,
    id: "arp_lpi_se",
    icao_code: "ESSL",
    iata_country_code: "SE",
    iata_code: "LPI",
    city_name: "Linköping",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Lins Airport",
    longitude: -49.731109,
    latitude: -21.664081,
    id: "arp_lip_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "LIP",
    city_name: "Lins",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Linton-on-Ouse Airport",
    longitude: -1.252383,
    latitude: 54.045252,
    id: "arp_hrt_gb",
    icao_code: "EGXU",
    iata_country_code: "GB",
    iata_code: "HRT",
    city_name: "Harrogate",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Linyi Shubuling International Airport",
    longitude: 118.412644,
    latitude: 35.047171,
    id: "arp_lyi_cn",
    icao_code: "ZSLY",
    iata_country_code: "CN",
    iata_code: "LYI",
    city_name: "Linyi",
    city: null,
  },
  {
    time_zone: "Europe/Vienna",
    name: "Linz Airport",
    longitude: 14.188682,
    latitude: 48.235324,
    id: "arp_lnz_at",
    icao_code: "LOWL",
    iata_country_code: "AT",
    iata_code: "LNZ",
    city_name: "Linz",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Lipetsk Airport",
    longitude: 39.537939,
    latitude: 52.703003,
    id: "arp_lpk_ru",
    icao_code: "UUOL",
    iata_country_code: "RU",
    iata_code: "LPK",
    city_name: "Lipetsk",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Liping Airport",
    longitude: 109.152832,
    latitude: 26.322275,
    id: "arp_hzh_cn",
    icao_code: "ZUNP",
    iata_country_code: "CN",
    iata_code: "HZH",
    city_name: "Liping",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Lisala Airport",
    longitude: 21.497334,
    latitude: 2.171138,
    id: "arp_liq_cd",
    icao_code: "FZGA",
    iata_country_code: "CD",
    iata_code: "LIQ",
    city_name: "Lisala",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Lisbon Cascais-Tejo Regional Airport",
    longitude: -9.35523,
    latitude: 38.724998,
    id: "arp_cat_pt",
    icao_code: "LPCS",
    iata_country_code: "PT",
    iata_code: "CAT",
    city_name: "Cascais",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Lisbon Portela Airport",
    longitude: -9.135643,
    latitude: 38.778446,
    id: "arp_lis_pt",
    icao_code: "LPPT",
    iata_country_code: "PT",
    iata_code: "LIS",
    city_name: "Lisbon",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Lismore Airport",
    longitude: 153.261662,
    latitude: -28.833476,
    id: "arp_lsy_au",
    icao_code: "YLIS",
    iata_country_code: "AU",
    iata_code: "LSY",
    city_name: "Lismore",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Lissadell Airport",
    longitude: 128.667,
    latitude: -16.5,
    id: "arp_lll_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LLL",
    city_name: "Lissadell",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Little Grand Rapids Airport",
    longitude: -95.465932,
    latitude: 52.045294,
    id: "arp_zgr_ca",
    icao_code: "CZGR",
    iata_country_code: "CA",
    iata_code: "ZGR",
    city_name: "Little Grand Rapids",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Little Rock Air Force Base",
    longitude: -92.149696,
    latitude: 34.916901,
    id: "arp_lrf_us",
    icao_code: "KLRF",
    iata_country_code: "US",
    iata_code: "LRF",
    city_name: "Jacksonville",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Liupanshui Yuezhao Airport",
    longitude: 104.979,
    latitude: 26.609417,
    id: "arp_lpf_cn",
    icao_code: "ZUPS",
    iata_country_code: "CN",
    iata_code: "LPF",
    city_name: "Liupanshui",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Liuzhou Bailian Airport",
    longitude: 109.391036,
    latitude: 24.2075,
    id: "arp_lzh_cn",
    icao_code: "ZGZH",
    iata_country_code: "CN",
    iata_code: "LZH",
    city_name: "Liuzhou",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Livengood Camp Airport",
    longitude: -148.653587,
    latitude: 65.466633,
    id: "arp_liv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "LIV",
    city_name: "Livengood",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Livermore Municipal Airport",
    longitude: -121.819434,
    latitude: 37.694008,
    id: "arp_lvk_us",
    icao_code: "KLVK",
    iata_country_code: "US",
    iata_code: "LVK",
    city_name: "Livermore",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Liverpool John Lennon Airport",
    longitude: -2.852108,
    latitude: 53.33444,
    id: "arp_lpl_gb",
    icao_code: "EGGP",
    iata_country_code: "GB",
    iata_code: "LPL",
    city_name: "Liverpool",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lizard Island Airport",
    longitude: 145.454601,
    latitude: -14.673221,
    id: "arp_lzr_au",
    icao_code: "YLZI",
    iata_country_code: "AU",
    iata_code: "LZR",
    city_name: "Lizard Island",
    city: null,
  },
  {
    time_zone: "Europe/Ljubljana",
    name: "Ljubljana Jože Pučnik Airport",
    longitude: 14.456757,
    latitude: 46.224822,
    id: "arp_lju_si",
    icao_code: "LJLJ",
    iata_country_code: "SI",
    iata_code: "LJU",
    city_name: "Ljubljana",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Lleida-Alguaire Airport",
    longitude: 0.538306,
    latitude: 41.727954,
    id: "arp_ild_es",
    icao_code: "LEDA",
    iata_country_code: "ES",
    iata_code: "ILD",
    city_name: "Lleida",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Lloydminster Airport",
    longitude: -110.070444,
    latitude: 53.309833,
    id: "arp_yll_ca",
    icao_code: "CYLL",
    iata_country_code: "CA",
    iata_code: "YLL",
    city_name: "Lloydminster",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "L'Mekrareg Airport",
    longitude: 2.927447,
    latitude: 33.764673,
    id: "arp_loo_dz",
    icao_code: "DAUL",
    iata_country_code: "DZ",
    iata_code: "LOO",
    city_name: "Laghouat",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Loakan Airport",
    longitude: 120.619867,
    latitude: 16.375113,
    id: "arp_bag_ph",
    icao_code: "RPUB",
    iata_country_code: "PH",
    iata_code: "BAG",
    city_name: "Baguio",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Loani Airport",
    longitude: 150.33428,
    latitude: -10.310217,
    id: "arp_lnq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LNQ",
    city_name: "Loani",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Lobatse Airport",
    longitude: 25.714207,
    latitude: -25.197069,
    id: "arp_loq_bw",
    icao_code: "FBLO",
    iata_country_code: "BW",
    iata_code: "LOQ",
    city_name: "Lobatse",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Lobito Airport",
    longitude: 13.538541,
    latitude: -12.371416,
    id: "arp_llt_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "LLT",
    city_name: "Lobito",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Lock Airport",
    longitude: 135.692801,
    latitude: -33.543949,
    id: "arp_loc_au",
    icao_code: "YLOK",
    iata_country_code: "AU",
    iata_code: "LOC",
    city_name: "Lock",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lockhart River Airport",
    longitude: 143.305463,
    latitude: -12.786553,
    id: "arp_irg_au",
    icao_code: "YLHR",
    iata_country_code: "AU",
    iata_code: "IRG",
    city_name: "Lockhart River",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Lodar Airport",
    longitude: 45.8667,
    latitude: 13.8833,
    id: "arp_ldr_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "LDR",
    city_name: "Lawdar",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Lodja Airport",
    longitude: 23.45,
    latitude: -3.417,
    id: "arp_lja_cd",
    icao_code: "FZVA",
    iata_country_code: "CD",
    iata_code: "LJA",
    city_name: "Lodja",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Lodwar Airport",
    longitude: 35.608031,
    latitude: 3.121731,
    id: "arp_lok_ke",
    icao_code: "HKLO",
    iata_country_code: "KE",
    iata_code: "LOK",
    city_name: "Lodwar",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Łódź Władysław Reymont Airport",
    longitude: 19.394454,
    latitude: 51.720803,
    id: "arp_lcj_pl",
    icao_code: "EPLL",
    iata_country_code: "PL",
    iata_code: "LCJ",
    city_name: "Lodz",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Loei Airport",
    longitude: 101.724368,
    latitude: 17.442447,
    id: "arp_loe_th",
    icao_code: "VTUL",
    iata_country_code: "TH",
    iata_code: "LOE",
    city_name: "Loei",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Loen Airport",
    longitude: 171.271095,
    latitude: 7.064859,
    id: "arp_lof_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "LOF",
    city_name: "Loen Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Logan-Cache Airport",
    longitude: -111.852994,
    latitude: 41.787429,
    id: "arp_lgu_us",
    icao_code: "KLGU",
    iata_country_code: "US",
    iata_code: "LGU",
    city_name: "Logan",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Logroño-Agoncillo Airport",
    longitude: -2.31965,
    latitude: 42.459025,
    id: "arp_rjl_es",
    icao_code: "LERJ",
    iata_country_code: "ES",
    iata_code: "RJL",
    city_name: "Logroño",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Loikaw Airport",
    longitude: 97.214653,
    latitude: 19.691178,
    id: "arp_liw_mm",
    icao_code: "VYLK",
    iata_country_code: "MM",
    iata_code: "LIW",
    city_name: "Loikaw",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Lokichogio Airport",
    longitude: 34.351415,
    latitude: 4.203714,
    id: "arp_lkg_ke",
    icao_code: "HKLK",
    iata_country_code: "KE",
    iata_code: "LKG",
    city_name: "Lokichoggio",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Lokpriya Gopinath Bordoloi International Airport",
    longitude: 91.585973,
    latitude: 26.106286,
    id: "arp_gau_in",
    icao_code: "VEGT",
    iata_country_code: "IN",
    iata_code: "GAU",
    city_name: "Guwahati",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Lolland Falster Airport",
    longitude: 11.442832,
    latitude: 54.698467,
    id: "arp_mrw_dk",
    icao_code: "EKMB",
    iata_country_code: "DK",
    iata_code: "MRW",
    city_name: "Maribo",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Lombok International Airport",
    longitude: 116.27519,
    latitude: -8.759687,
    id: "arp_lop_id",
    icao_code: "WADL",
    iata_country_code: "ID",
    iata_code: "LOP",
    city_name: "Lombok",
    city: null,
  },
  {
    time_zone: "Africa/Lome",
    name: "Lomé-Tokoin Airport",
    longitude: 1.253108,
    latitude: 6.167217,
    id: "arp_lfw_tg",
    icao_code: "DXXX",
    iata_country_code: "TG",
    iata_code: "LFW",
    city_name: "Lomé",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lompoc Airport",
    longitude: -120.465837,
    latitude: 34.665849,
    id: "arp_lpc_us",
    icao_code: "KLPC",
    iata_country_code: "US",
    iata_code: "LPC",
    city_name: "Lompoc",
    city: {
      name: "Lompoc",
      id: "cit_lpc_us",
      iata_country_code: "US",
      iata_code: "LPC",
    },
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Loncopue Airport",
    longitude: -70.643507,
    latitude: -38.081271,
    id: "arp_lcp_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "LCP",
    city_name: "Loncopue",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Londolozi Airport",
    longitude: 31.474474,
    latitude: -24.749944,
    id: "arp_ldz_za",
    icao_code: "FALD",
    iata_country_code: "ZA",
    iata_code: "LDZ",
    city_name: "Londolozi",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "London Biggin Hill Airport",
    longitude: 0.030782,
    latitude: 51.331399,
    id: "arp_bqh_gb",
    icao_code: "EGKB",
    iata_country_code: "GB",
    iata_code: "BQH",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "Europe/London",
    name: "London City Airport",
    longitude: 0.052398,
    latitude: 51.505071,
    id: "arp_lcy_gb",
    icao_code: "EGLC",
    iata_country_code: "GB",
    iata_code: "LCY",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "London-Corbin Airport",
    longitude: -84.068713,
    latitude: 37.090003,
    id: "arp_loz_us",
    icao_code: "KLOZ",
    iata_country_code: "US",
    iata_code: "LOZ",
    city_name: "London",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "London International Airport",
    longitude: -81.151653,
    latitude: 43.031958,
    id: "arp_yxu_ca",
    icao_code: "CYXU",
    iata_country_code: "CA",
    iata_code: "YXU",
    city_name: "London",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "London Luton Airport",
    longitude: -0.37004,
    latitude: 51.875482,
    id: "arp_ltn_gb",
    icao_code: "EGGW",
    iata_country_code: "GB",
    iata_code: "LTN",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "Europe/London",
    name: "London Southend Airport",
    longitude: 0.697117,
    latitude: 51.571075,
    id: "arp_sen_gb",
    icao_code: "EGMC",
    iata_country_code: "GB",
    iata_code: "SEN",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "Europe/London",
    name: "London Stansted Airport",
    longitude: 0.236933,
    latitude: 51.885508,
    id: "arp_stn_gb",
    icao_code: "EGSS",
    iata_country_code: "GB",
    iata_code: "STN",
    city_name: "London",
    city: {
      name: "London",
      id: "cit_lon_gb",
      iata_country_code: "GB",
      iata_code: "LON",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Londrina Airport",
    longitude: -51.133982,
    latitude: -23.331115,
    id: "arp_ldb_br",
    icao_code: "SBLO",
    iata_country_code: "BR",
    iata_code: "LDB",
    city_name: "Londrina",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lonesome Pine Airport",
    longitude: -82.528124,
    latitude: 36.98674,
    id: "arp_lnp_us",
    icao_code: "KLNP",
    iata_country_code: "US",
    iata_code: "LNP",
    city_name: "Wise",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Akah Airport",
    longitude: 114.785854,
    latitude: 3.313391,
    id: "arp_lkh_my",
    icao_code: "WBGL",
    iata_country_code: "MY",
    iata_code: "LKH",
    city_name: "Long Akah",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Longana Airport",
    longitude: 167.968475,
    latitude: -15.305302,
    id: "arp_lod_vu",
    icao_code: "NVSG",
    iata_country_code: "VU",
    iata_code: "LOD",
    city_name: "Longana",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Long Apung Airport",
    longitude: 114.970297,
    latitude: 1.704486,
    id: "arp_lpu_id",
    icao_code: "WAQL",
    iata_country_code: "ID",
    iata_code: "LPU",
    city_name: "Long Apung",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Banga Airport",
    longitude: 115.40181,
    latitude: 3.201683,
    id: "arp_lbp_my",
    icao_code: null,
    iata_country_code: "MY",
    iata_code: "LBP",
    city_name: "Long Banga",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Long Bawan Airport",
    longitude: 115.691195,
    latitude: 3.901643,
    id: "arp_lbw_id",
    icao_code: "WALB",
    iata_country_code: "ID",
    iata_code: "LBW",
    city_name: "Long Bawan",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Long Beach Airport",
    longitude: -118.150292,
    latitude: 33.817837,
    id: "arp_lgb_us",
    icao_code: "KLGB",
    iata_country_code: "US",
    iata_code: "LGB",
    city_name: "Long Beach",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Long Island Airport",
    longitude: 147.016667,
    latitude: -5.366667,
    id: "arp_lsj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LSJ",
    city_name: "Long Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Long Island MacArthur Airport",
    longitude: -73.100026,
    latitude: 40.794022,
    id: "arp_isp_us",
    icao_code: "KISP",
    iata_country_code: "US",
    iata_code: "ISP",
    city_name: "Islip",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Lama Airport",
    longitude: 114.327015,
    latitude: 4.17904,
    id: "arp_llm_my",
    icao_code: null,
    iata_country_code: "MY",
    iata_code: "LLM",
    city_name: "Long Lama",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Lellang Airport",
    longitude: 115.153315,
    latitude: 3.421272,
    id: "arp_lgl_my",
    icao_code: "WBGF",
    iata_country_code: "MY",
    iata_code: "LGL",
    city_name: "Marudi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Longnan Chengzhou Airport",
    longitude: 105.797,
    latitude: 33.788,
    id: "arp_lnl_cn",
    icao_code: "ZLLN",
    iata_country_code: "CN",
    iata_code: "LNL",
    city_name: "Longnan",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Pasia Airport",
    longitude: 115.724562,
    latitude: 4.410426,
    id: "arp_gsa_my",
    icao_code: "WBKN",
    iata_country_code: "MY",
    iata_code: "GSA",
    city_name: "Long Pasia",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Longreach Airport",
    longitude: 144.277116,
    latitude: -23.435997,
    id: "arp_lre_au",
    icao_code: "YLRE",
    iata_country_code: "AU",
    iata_code: "LRE",
    city_name: "Longreach",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Semado Airport",
    longitude: 115.599999,
    latitude: 4.217,
    id: "arp_lsm_my",
    icao_code: "WBGD",
    iata_country_code: "MY",
    iata_code: "LSM",
    city_name: "Long Semado",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Seridan Airport",
    longitude: 115.068542,
    latitude: 3.977258,
    id: "arp_odn_my",
    icao_code: "WBGI",
    iata_country_code: "MY",
    iata_code: "ODN",
    city_name: "Long Seridan",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Long Sukang Airport",
    longitude: 115.493583,
    latitude: 4.552719,
    id: "arp_lsu_my",
    icao_code: "WBGU",
    iata_country_code: "MY",
    iata_code: "LSU",
    city_name: "Long Sukang",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Long Xuyên Airport",
    longitude: 105.45,
    latitude: 10.4833,
    id: "arp_xlo_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "XLO",
    city_name: "Long Xuyên",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Longyan Guanzhishan Airport",
    longitude: 116.746521,
    latitude: 25.674985,
    id: "arp_lcx_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "LCX",
    city_name: "Longyan",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Lonorore Airport",
    longitude: 168.171502,
    latitude: -15.864191,
    id: "arp_lne_vu",
    icao_code: "NVSO",
    iata_country_code: "VU",
    iata_code: "LNE",
    city_name: "Lonorore",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Lopez de Micay Airport",
    longitude: -77.5527,
    latitude: 3.04322,
    id: "arp_lmx_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "LMX",
    city_name: "El Trapiche",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Lopez Island Airport",
    longitude: -122.937661,
    latitude: 48.48455,
    id: "arp_lps_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "LPS",
    city_name: "Lopez Island",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Loralai Airport",
    longitude: 68.614196,
    latitude: 30.354872,
    id: "arp_lrg_pk",
    icao_code: "OPLL",
    iata_country_code: "PK",
    iata_code: "LRG",
    city_name: "Loralai",
    city: null,
  },
  {
    time_zone: "Australia/Lord_Howe",
    name: "Lord Howe Island Airport",
    longitude: 159.077604,
    latitude: -31.539144,
    id: "arp_ldh_au",
    icao_code: "YLHI",
    iata_country_code: "AU",
    iata_code: "LDH",
    city_name: "Lord Howe Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lordsburg Municipal Airport",
    longitude: -108.692915,
    latitude: 32.33511,
    id: "arp_lsb_us",
    icao_code: "KLSB",
    iata_country_code: "US",
    iata_code: "LSB",
    city_name: "Lordsburg",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Loreto International Airport",
    longitude: -111.349855,
    latitude: 25.989877,
    id: "arp_lto_mx",
    icao_code: "MMLT",
    iata_country_code: "MX",
    iata_code: "LTO",
    city_name: "Loreto",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Lorica Airport",
    longitude: -75.827157,
    latitude: 8.82426,
    id: "arp_lri_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "LRI",
    city_name: "Lorica",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Lorient South Brittany Airport",
    longitude: -3.438644,
    latitude: 47.757351,
    id: "arp_lrt_fr",
    icao_code: "LFRH",
    iata_country_code: "FR",
    iata_code: "LRT",
    city_name: "Lorient",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Loring Airport",
    longitude: -134.584499,
    latitude: 58.357649,
    id: "arp_wlr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WLR",
    city_name: "Loring",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Loring International Airport",
    longitude: -67.885759,
    latitude: 46.950024,
    id: "arp_liz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "LIZ",
    city_name: "Limestone",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lorraine Airport",
    longitude: 139.906359,
    latitude: -18.994107,
    id: "arp_loa_au",
    icao_code: "YLOR",
    iata_country_code: "AU",
    iata_code: "LOA",
    city_name: "Lorraine",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Los Alamos Airport",
    longitude: -106.272167,
    latitude: 35.880795,
    id: "arp_lam_us",
    icao_code: "KLAM",
    iata_country_code: "US",
    iata_code: "LAM",
    city_name: "Los Alamos",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Los Angeles International Airport",
    longitude: -118.408263,
    latitude: 33.942045,
    id: "arp_lax_us",
    icao_code: "KLAX",
    iata_country_code: "US",
    iata_code: "LAX",
    city_name: "Los Angeles",
    city: {
      name: "Los Angeles",
      id: "cit_lax_us",
      iata_country_code: "US",
      iata_code: "LAX",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Los Banos Municipal Airport",
    longitude: -120.86911,
    latitude: 37.063004,
    id: "arp_lsn_us",
    icao_code: "KLSN",
    iata_country_code: "US",
    iata_code: "LSN",
    city_name: "Los Banos",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Los Cabos International Airport",
    longitude: -109.719633,
    latitude: 23.148668,
    id: "arp_sjd_mx",
    icao_code: "MMSD",
    iata_country_code: "MX",
    iata_code: "SJD",
    city_name: "San José del Cabo",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Los Chiles Airport",
    longitude: -84.706102,
    latitude: 11.035292,
    id: "arp_lsl_cr",
    icao_code: "MRLC",
    iata_country_code: "CR",
    iata_code: "LSL",
    city_name: "Los Chiles",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Los Colonizadores Airport",
    longitude: -71.857355,
    latitude: 6.95275,
    id: "arp_rve_co",
    icao_code: "SKSA",
    iata_country_code: "CO",
    iata_code: "RVE",
    city_name: "Saravena",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Los Garzones Airport",
    longitude: -75.825583,
    latitude: 8.82375,
    id: "arp_mtr_co",
    icao_code: "SKMR",
    iata_country_code: "CO",
    iata_code: "MTR",
    city_name: "Montería",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "L.O. Simenstad Municipal Airport",
    longitude: -92.690783,
    latitude: 45.309844,
    id: "arp_oeo_us",
    icao_code: "KOEO",
    iata_country_code: "US",
    iata_code: "OEO",
    city_name: "Osceola",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Lošinj Airport",
    longitude: 14.392966,
    latitude: 44.56633,
    id: "arp_lsz_hr",
    icao_code: "LDLO",
    iata_country_code: "HR",
    iata_code: "LSZ",
    city_name: "Mali Losinj",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Los Menucos Airport",
    longitude: -68.074699,
    latitude: -40.817699,
    id: "arp_lmd_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "LMD",
    city_name: "Los Menucos",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Los Perales Airport",
    longitude: -80.404087,
    latitude: -0.60568,
    id: "arp_bha_ec",
    icao_code: "SESV",
    iata_country_code: "EC",
    iata_code: "BHA",
    city_name: "Bahia de Caraquez",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Los Roques Airport",
    longitude: -66.672913,
    latitude: 11.94827,
    id: "arp_lrv_ve",
    icao_code: "SVRS",
    iata_country_code: "VE",
    iata_code: "LRV",
    city_name: "Los Roques",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Los Tablones Airport",
    longitude: -90.52786,
    latitude: 14.58291,
    id: "arp_lox_gt",
    icao_code: null,
    iata_country_code: "GT",
    iata_code: "LOX",
    city_name: "Los Tablones",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Losuia Airport",
    longitude: 151.081332,
    latitude: -8.506537,
    id: "arp_lsa_pg",
    icao_code: "AYKA",
    iata_country_code: "PG",
    iata_code: "LSA",
    city_name: "Losuia",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lotus Vale Station Airport",
    longitude: 141.375441,
    latitude: -17.048336,
    id: "arp_ltv_au",
    icao_code: "YLOV",
    iata_country_code: "AU",
    iata_code: "LTV",
    city_name: "Lotus Vale Station",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Louisa County Airport",
    longitude: 35.883331,
    latitude: 3.416667,
    id: "arp_lku_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "LKU",
    city_name: "Lake Turkana",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Louisa County Airport",
    longitude: -77.971024,
    latitude: 38.009899,
    id: "arp_low_us",
    icao_code: "KLKU",
    iata_country_code: "US",
    iata_code: "LOW",
    city_name: "Louisa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Louis Armstrong New Orleans International Airport",
    longitude: -90.258507,
    latitude: 29.9928,
    id: "arp_msy_us",
    icao_code: "KMSY",
    iata_country_code: "US",
    iata_code: "MSY",
    city_name: "New Orleans",
    city: {
      name: "New Orleans",
      id: "cit_msy_us",
      iata_country_code: "US",
      iata_code: "MSY",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Louis Trichardt Airport",
    longitude: 29.863134,
    latitude: -23.060102,
    id: "arp_lcd_za",
    icao_code: "FALO",
    iata_country_code: "ZA",
    iata_code: "LCD",
    city_name: "Louis Trichardt",
    city: null,
  },
  {
    time_zone: "America/Kentucky/Louisville",
    name: "Louisville International Airport",
    longitude: -85.736461,
    latitude: 38.175031,
    id: "arp_sdf_us",
    icao_code: "KSDF",
    iata_country_code: "US",
    iata_code: "SDF",
    city_name: "Louisville",
    city: {
      name: "Louisville",
      id: "cit_sdf_us",
      iata_country_code: "US",
      iata_code: "SDF",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Louisville Winston County Airport",
    longitude: -89.062518,
    latitude: 33.146048,
    id: "arp_lms_us",
    icao_code: "KLMS",
    iata_country_code: "US",
    iata_code: "LMS",
    city_name: "Louisville",
    city: null,
  },
  {
    time_zone: "America/Blanc-Sablon",
    name: "Lourdes-de-Blanc-Sablon Airport",
    longitude: -57.186976,
    latitude: 51.443778,
    id: "arp_ybx_ca",
    icao_code: "CYBX",
    iata_country_code: "CA",
    iata_code: "YBX",
    city_name: "Blanc-Sablon",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lowai Airport",
    longitude: 146.6458,
    latitude: -6.3344,
    id: "arp_lwi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LWI",
    city_name: "Lowai",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lowcountry Regional Airport",
    longitude: -80.639962,
    latitude: 32.921433,
    id: "arp_rbw_us",
    icao_code: "KRBW",
    iata_country_code: "US",
    iata_code: "RBW",
    city_name: "Walterboro",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Loyengalani Airport",
    longitude: 36.718913,
    latitude: 2.762995,
    id: "arp_loy_ke",
    icao_code: "HKLY",
    iata_country_code: "KE",
    iata_code: "LOY",
    city_name: "Loyengalani",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Lt Col W.G. Billy Barker Airport",
    longitude: -100.054004,
    latitude: 51.101026,
    id: "arp_ydn_ca",
    icao_code: "CYDN",
    iata_country_code: "CA",
    iata_code: "YDN",
    city_name: "Dauphin",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lt. Warren Eaton Airport",
    longitude: -75.525025,
    latitude: 42.566374,
    id: "arp_oic_us",
    icao_code: "KOIC",
    iata_country_code: "US",
    iata_code: "OIC",
    city_name: "Norwich",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Luabo Airport",
    longitude: 36.869028,
    latitude: -17.856142,
    id: "arp_lbm_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "LBM",
    city_name: "Luabo",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Luang Namtha Airport",
    longitude: 101.401547,
    latitude: 20.953941,
    id: "arp_lxg_la",
    icao_code: "VLLN",
    iata_country_code: "LA",
    iata_code: "LXG",
    city_name: "Luang Namtha",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Luang Prabang International Airport",
    longitude: 102.164656,
    latitude: 19.899235,
    id: "arp_lpq_la",
    icao_code: "VLLB",
    iata_country_code: "LA",
    iata_code: "LPQ",
    city_name: "Luang Prabang",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Luau Airport",
    longitude: 22.231105,
    latitude: -10.715816,
    id: "arp_ual_ao",
    icao_code: "FNUA",
    iata_country_code: "AO",
    iata_code: "UAL",
    city_name: "Luau",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Lubang Airport",
    longitude: 120.104897,
    latitude: 13.855404,
    id: "arp_lbx_ph",
    icao_code: "RPLU",
    iata_country_code: "PH",
    iata_code: "LBX",
    city_name: "Lubang",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Lubango Airport",
    longitude: 13.571559,
    latitude: -14.926494,
    id: "arp_sdd_ao",
    icao_code: "FNUB",
    iata_country_code: "AO",
    iata_code: "SDD",
    city_name: "Lubango",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Lubbock Preston Smith International Airport",
    longitude: -101.822307,
    latitude: 33.66001,
    id: "arp_lbb_us",
    icao_code: "KLBB",
    iata_country_code: "US",
    iata_code: "LBB",
    city_name: "Lubbock",
    city: {
      name: "Lubbock",
      id: "cit_lbb_us",
      iata_country_code: "US",
      iata_code: "LBB",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Lübeck Blankensee Airport",
    longitude: 10.702957,
    latitude: 53.804426,
    id: "arp_lbc_de",
    icao_code: "EDHL",
    iata_country_code: "DE",
    iata_code: "LBC",
    city_name: "Lübeck",
    city: {
      name: "Hamburg",
      id: "cit_ham_de",
      iata_country_code: "DE",
      iata_code: "HAM",
    },
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Lublin Airport",
    longitude: 22.714096,
    latitude: 51.238788,
    id: "arp_luz_pl",
    icao_code: "EPLB",
    iata_country_code: "PL",
    iata_code: "LUZ",
    city_name: "Lublin",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Lubumbashi International Airport",
    longitude: 27.530866,
    latitude: -11.591205,
    id: "arp_fbm_cd",
    icao_code: "FZQA",
    iata_country_code: "CD",
    iata_code: "FBM",
    city_name: "Lubumbashi",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Lucapa Airport",
    longitude: 20.73163,
    latitude: -8.448938,
    id: "arp_lbz_ao",
    icao_code: "FNLK",
    iata_country_code: "AO",
    iata_code: "LBZ",
    city_name: "Lucapa",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Lucas do Rio Verde Airport",
    longitude: -55.944832,
    latitude: -13.035928,
    id: "arp_lvr_br",
    icao_code: "SILC",
    iata_country_code: "BR",
    iata_code: "LVR",
    city_name: "Lucas do Rio Verde",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Lucca-Tassignano Airport",
    longitude: 10.576283,
    latitude: 43.82568,
    id: "arp_lcv_it",
    icao_code: "LIQL",
    iata_country_code: "IT",
    iata_code: "LCV",
    city_name: "Lucca",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Lučenec Airport",
    longitude: 19.735793,
    latitude: 48.339207,
    id: "arp_lue_sk",
    icao_code: "LZLU",
    iata_country_code: "SK",
    iata_code: "LUE",
    city_name: "Lucenec",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Luderitz Airport",
    longitude: 15.243279,
    latitude: -26.686096,
    id: "arp_lud_na",
    icao_code: "FYLZ",
    iata_country_code: "NA",
    iata_code: "LUD",
    city_name: "Luderitz",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Ludhiana Airport",
    longitude: 75.953499,
    latitude: 30.853554,
    id: "arp_luh_in",
    icao_code: "VILD",
    iata_country_code: "IN",
    iata_code: "LUH",
    city_name: "Ludhiana",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Luena Airport",
    longitude: 19.896668,
    latitude: -11.767745,
    id: "arp_luo_ao",
    icao_code: "FNUE",
    iata_country_code: "AO",
    iata_code: "LUO",
    city_name: "Luena",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Lugano Airport",
    longitude: 8.910344,
    latitude: 46.004357,
    id: "arp_lug_ch",
    icao_code: "LSZA",
    iata_country_code: "CH",
    iata_code: "LUG",
    city_name: "Lugano",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Lugh Ganane Airport",
    longitude: 42.5459,
    latitude: 3.8124,
    id: "arp_lgx_so",
    icao_code: "HCMJ",
    iata_country_code: "SO",
    iata_code: "LGX",
    city_name: "Lugh Ganane",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Luhansk International Airport",
    longitude: 39.374099,
    latitude: 48.4174,
    id: "arp_vsg_ua",
    icao_code: "UKCW",
    iata_country_code: "UA",
    iata_code: "VSG",
    city_name: "Luhansk",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Luis Muñoz Marín International Airport",
    longitude: -109.081701,
    latitude: 25.685787,
    id: "arp_lmm_mx",
    icao_code: "MMLM",
    iata_country_code: "MX",
    iata_code: "LMM",
    city_name: "Los Mochis",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Luis Muñoz Marín International Airport",
    longitude: -66.002019,
    latitude: 18.438773,
    id: "arp_sju_pr",
    icao_code: "TJSJ",
    iata_country_code: "PR",
    iata_code: "SJU",
    city_name: "San Juan",
    city: {
      name: "San Juan",
      id: "cit_sju_pr",
      iata_country_code: "PR",
      iata_code: "SJU",
    },
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Luiza Airport",
    longitude: 22.391592,
    latitude: -7.189347,
    id: "arp_lza_cd",
    icao_code: "FZUG",
    iata_country_code: "CD",
    iata_code: "LZA",
    city_name: "Luiza",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Luke Air Force Base",
    longitude: -112.383003,
    latitude: 33.535,
    id: "arp_luf_us",
    icao_code: "KLUF",
    iata_country_code: "US",
    iata_code: "LUF",
    city_name: "Phoenix",
    city: {
      name: "Phoenix",
      id: "cit_phx_us",
      iata_country_code: "US",
      iata_code: "PHX",
    },
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Lukulu Airport",
    longitude: 23.248907,
    latitude: -14.375488,
    id: "arp_lxu_zm",
    icao_code: "FLLK",
    iata_country_code: "ZM",
    iata_code: "LXU",
    city_name: "Lukulu",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Luleå Airport",
    longitude: 22.121927,
    latitude: 65.543174,
    id: "arp_lla_se",
    icao_code: "ESPA",
    iata_country_code: "SE",
    iata_code: "LLA",
    city_name: "Luleå",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Lüliang Airport",
    longitude: 111.142778,
    latitude: 37.683333,
    id: "arp_llv_cn",
    icao_code: "ZBLL",
    iata_country_code: "CN",
    iata_code: "LLV",
    city_name: "Lüliang",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Lumbala Airport",
    longitude: 21.441749,
    latitude: -14.105204,
    id: "arp_ggc_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "GGC",
    city_name: "Lumbala N'guimbo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lumberton Municipal Airport",
    longitude: -79.059422,
    latitude: 34.610439,
    id: "arp_lbt_us",
    icao_code: "KLBT",
    iata_country_code: "US",
    iata_code: "LBT",
    city_name: "Lumberton",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Lumbo Airport",
    longitude: 40.671699,
    latitude: -15.0331,
    id: "arp_lfb_mz",
    icao_code: "FQLU",
    iata_country_code: "MZ",
    iata_code: "LFB",
    city_name: "Lumbo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Lumi Airport",
    longitude: 142.039468,
    latitude: -3.476497,
    id: "arp_lmi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LMI",
    city_name: "Lumi",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Lumid Pau Airport",
    longitude: -59.439755,
    latitude: 2.394355,
    id: "arp_lub_gy",
    icao_code: "SYLP",
    iata_country_code: "GY",
    iata_code: "LUB",
    city_name: "Lumid Pau",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Lungi International Airport",
    longitude: -13.195981,
    latitude: 8.616793,
    id: "arp_fna_sl",
    icao_code: "GFLL",
    iata_country_code: "SL",
    iata_code: "FNA",
    city_name: "Freetown",
    city: {
      name: "Freetown",
      id: "cit_fna_sl",
      iata_country_code: "SL",
      iata_code: "FNA",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "Lunyuk Airport",
    longitude: 117.216172,
    latitude: -8.990321,
    id: "arp_lyk_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "LYK",
    city_name: "Sumbawa",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Luoyang Beijiao Airport",
    longitude: 112.390151,
    latitude: 34.73855,
    id: "arp_lya_cn",
    icao_code: "ZHLY",
    iata_country_code: "CN",
    iata_code: "LYA",
    city_name: "Luoyang",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Luozi Airport",
    longitude: 14.122774,
    latitude: -4.943942,
    id: "arp_lzi_cd",
    icao_code: "FZAL",
    iata_country_code: "CD",
    iata_code: "LZI",
    city_name: "Luozi",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Lusambo Airport",
    longitude: 23.378466,
    latitude: -4.962059,
    id: "arp_lbo_cd",
    icao_code: "FZVI",
    iata_country_code: "CD",
    iata_code: "LBO",
    city_name: "Lusambo",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Lusanga Airport",
    longitude: 18.718447,
    latitude: -4.804135,
    id: "arp_lus_cd",
    icao_code: "FZCE",
    iata_country_code: "CD",
    iata_code: "LUS",
    city_name: "Lusanga",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Lusk Municipal Airport",
    longitude: -104.406055,
    latitude: 42.753494,
    id: "arp_lsk_us",
    icao_code: "KLSK",
    iata_country_code: "US",
    iata_code: "LSK",
    city_name: "Lusk",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Lutselk'e Airport",
    longitude: -110.689609,
    latitude: 62.416121,
    id: "arp_ysg_ca",
    icao_code: "CYLK",
    iata_country_code: "CA",
    iata_code: "YSG",
    city_name: "Lutselk'e",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Lutsk Airport",
    longitude: 25.479042,
    latitude: 50.676617,
    id: "arp_uck_ua",
    icao_code: "UKLC",
    iata_country_code: "UA",
    iata_code: "UCK",
    city_name: "Lutsk",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Luwuk Bubung Airport",
    longitude: 122.771837,
    latitude: -1.039552,
    id: "arp_luw_id",
    icao_code: "WAMW",
    iata_country_code: "ID",
    iata_code: "LUW",
    city_name: "Luwuk",
    city: null,
  },
  {
    time_zone: "Europe/Luxembourg",
    name: "Luxembourg Airport",
    longitude: 6.209594,
    latitude: 49.626117,
    id: "arp_lux_lu",
    icao_code: "ELLX",
    iata_country_code: "LU",
    iata_code: "LUX",
    city_name: "Luxembourg",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Luxor International Airport",
    longitude: 32.704678,
    latitude: 25.671532,
    id: "arp_lxr_eg",
    icao_code: "HELX",
    iata_country_code: "EG",
    iata_code: "LXR",
    city_name: "Luxor",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Luzamba Airport",
    longitude: 18.048452,
    latitude: -9.117234,
    id: "arp_lzm_ao",
    icao_code: "FNLZ",
    iata_country_code: "AO",
    iata_code: "LZM",
    city_name: "Luzamba",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Luzhou Yunlong Airport",
    longitude: 105.46349,
    latitude: 29.025028,
    id: "arp_lzo_cn",
    icao_code: "ZULZ",
    iata_country_code: "CN",
    iata_code: "LZO",
    city_name: "Luzhou",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Lviv Danylo Halytskyi International Airport",
    longitude: 23.957915,
    latitude: 49.812893,
    id: "arp_lwo_ua",
    icao_code: "UKLL",
    iata_country_code: "UA",
    iata_code: "LWO",
    city_name: "Lviv",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Lwbak Airport",
    longitude: 124.058201,
    latitude: 6.673953,
    id: "arp_lwa_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "LWA",
    city_name: "Lebak",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Lyall Harbour Seaplane Base",
    longitude: -123.181482,
    latitude: 48.79503,
    id: "arp_yaj_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YAJ",
    city_name: "Saturna Island",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Lycksele Airport",
    longitude: 18.714316,
    latitude: 64.548614,
    id: "arp_lyc_se",
    icao_code: "ESNL",
    iata_country_code: "SE",
    iata_code: "LYC",
    city_name: "Lycksele",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Lydd Airport",
    longitude: 0.93637,
    latitude: 50.955919,
    id: "arp_lyx_gb",
    icao_code: "EGMD",
    iata_country_code: "GB",
    iata_code: "LYX",
    city_name: "Lydd",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Lynchburg Regional Airport",
    longitude: -79.200768,
    latitude: 37.326439,
    id: "arp_lyh_us",
    icao_code: "KLYH",
    iata_country_code: "US",
    iata_code: "LYH",
    city_name: "Lynchburg",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Lynden Pindling International Airport",
    longitude: -77.465861,
    latitude: 25.041464,
    id: "arp_nas_bs",
    icao_code: "MYNN",
    iata_country_code: "BS",
    iata_code: "NAS",
    city_name: "Nassau",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Lyndhurst Airport",
    longitude: 144.370982,
    latitude: -19.195218,
    id: "arp_ltp_au",
    icao_code: "YLHS",
    iata_country_code: "AU",
    iata_code: "LTP",
    city_name: "Lyndhurst",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Lynn Lake Airport",
    longitude: -101.073647,
    latitude: 56.862851,
    id: "arp_yyl_ca",
    icao_code: "CYYL",
    iata_country_code: "CA",
    iata_code: "YYL",
    city_name: "Lynn Lake",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Lyon-Bron Airport",
    longitude: 4.943274,
    latitude: 45.727883,
    id: "arp_lyn_fr",
    icao_code: "LFLY",
    iata_country_code: "FR",
    iata_code: "LYN",
    city_name: "Lyon",
    city: {
      name: "Lyon",
      id: "cit_lys_fr",
      iata_country_code: "FR",
      iata_code: "LYS",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Lyon-Saint-Exupéry Airport",
    longitude: 5.082626,
    latitude: 45.723723,
    id: "arp_lys_fr",
    icao_code: "LFLL",
    iata_country_code: "FR",
    iata_code: "LYS",
    city_name: "Lyon",
    city: {
      name: "Lyon",
      id: "cit_lys_fr",
      iata_country_code: "FR",
      iata_code: "LYS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Lyons-Rice County Municipal Airport",
    longitude: -98.227139,
    latitude: 38.344208,
    id: "arp_lyo_us",
    icao_code: "KLYO",
    iata_country_code: "US",
    iata_code: "LYO",
    city_name: "Lyons",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Lyudao Airport",
    longitude: 121.466433,
    latitude: 22.673131,
    id: "arp_gni_tw",
    icao_code: "RCGI",
    iata_country_code: "TW",
    iata_code: "GNI",
    city_name: "Green Island",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Maamigili Airport",
    longitude: 72.835474,
    latitude: 3.471167,
    id: "arp_vam_mv",
    icao_code: "VRMV",
    iata_country_code: "MV",
    iata_code: "VAM",
    city_name: "Maamigili",
    city: null,
  },
  {
    time_zone: "Asia/Amman",
    name: "Maan Airport",
    longitude: 35.800007,
    latitude: 30.166496,
    id: "arp_mpq_jo",
    icao_code: null,
    iata_country_code: "JO",
    iata_code: "MPQ",
    city_name: "Maan",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Maastricht Aachen Airport",
    longitude: 5.768934,
    latitude: 50.912019,
    id: "arp_mst_nl",
    icao_code: "EHBK",
    iata_country_code: "NL",
    iata_code: "MST",
    city_name: "Maastricht",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Mabaruma Airport",
    longitude: -59.783298,
    latitude: 8.199999,
    id: "arp_usi_gy",
    icao_code: "SYMB",
    iata_country_code: "GY",
    iata_code: "USI",
    city_name: "Mabaruma",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mabuiag Island Airport",
    longitude: 142.195289,
    latitude: -9.950582,
    id: "arp_ubb_au",
    icao_code: "YMAA",
    iata_country_code: "AU",
    iata_code: "UBB",
    city_name: "Mabuiag Island",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Macaé Airport",
    longitude: -41.765851,
    latitude: -22.342916,
    id: "arp_mea_br",
    icao_code: "SBME",
    iata_country_code: "BR",
    iata_code: "MEA",
    city_name: "Macaé",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Macagua Airport",
    longitude: -62.665887,
    latitude: 8.278747,
    id: "arp_sfx_ve",
    icao_code: null,
    iata_country_code: "VE",
    iata_code: "SFX",
    city_name: "San Felix",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Macanal Airport",
    longitude: -67.5833,
    latitude: 2.56667,
    id: "arp_nad_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "NAD",
    city_name: "Macanal",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Macapa International Airport",
    longitude: -51.069953,
    latitude: 0.050383,
    id: "arp_mcp_br",
    icao_code: "SBMQ",
    iata_country_code: "BR",
    iata_code: "MCP",
    city_name: "Macapá",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Macas Airport",
    longitude: -78.120563,
    latitude: -2.300178,
    id: "arp_xms_ec",
    icao_code: "SEMC",
    iata_country_code: "EC",
    iata_code: "XMS",
    city_name: "Macas",
    city: null,
  },
  {
    time_zone: "Asia/Macau",
    name: "Macau International Airport",
    longitude: 113.573637,
    latitude: 22.157104,
    id: "arp_mfm_mo",
    icao_code: "VMMC",
    iata_country_code: "MO",
    iata_code: "MFM",
    city_name: "Macau",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mac Dill Air Force Base Airport",
    longitude: -82.492516,
    latitude: 27.844834,
    id: "arp_mcf_us",
    icao_code: "KMCF",
    iata_country_code: "US",
    iata_code: "MCF",
    city_name: "Tampa",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "Australia/Darwin",
    name: "Macdonald Downs Airport",
    longitude: 135.201756,
    latitude: -22.446644,
    id: "arp_mnw_au",
    icao_code: "YMDS",
    iata_country_code: "AU",
    iata_code: "MNW",
    city_name: "MacDonald Downs",
    city: null,
  },
  {
    time_zone: "America/Maceio",
    name: "Maceió Zumbi dos Palmares International Airport",
    longitude: -35.791986,
    latitude: -9.511583,
    id: "arp_mcz_br",
    icao_code: "SBMO",
    iata_country_code: "BR",
    iata_code: "MCZ",
    city_name: "Maceió",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Macenta Airport",
    longitude: -9.525225,
    latitude: 8.481817,
    id: "arp_mca_gn",
    icao_code: "GUMA",
    iata_country_code: "GN",
    iata_code: "MCA",
    city_name: "Macenta",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mackall Army Airfield",
    longitude: -79.497584,
    latitude: 35.036355,
    id: "arp_hff_us",
    icao_code: "KHFF",
    iata_country_code: "US",
    iata_code: "HFF",
    city_name: "Camp Mackall",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mackay Airport",
    longitude: 149.18066,
    latitude: -21.172564,
    id: "arp_mky_au",
    icao_code: "YBMK",
    iata_country_code: "AU",
    iata_code: "MKY",
    city_name: "Mackay",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Mackinac Island Airport",
    longitude: -84.6357,
    latitude: 45.86437,
    id: "arp_mcd_us",
    icao_code: "KMCD",
    iata_country_code: "US",
    iata_code: "MCD",
    city_name: "Mackinac Island",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Macksville Airport",
    longitude: 152.917,
    latitude: -30.7167,
    id: "arp_mvh_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MVH",
    city_name: "Macksville",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Macmillan Pass Airport",
    longitude: -130.202191,
    latitude: 63.178762,
    id: "arp_xmp_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "XMP",
    city_name: "Macmillan Pass",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Macomb Municipal Airport",
    longitude: -90.653857,
    latitude: 40.518822,
    id: "arp_mqb_us",
    icao_code: "KMQB",
    iata_country_code: "US",
    iata_code: "MQB",
    city_name: "Macomb",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Macon Downtown Airport",
    longitude: -83.562005,
    latitude: 32.822125,
    id: "arp_mac_us",
    icao_code: "KMAC",
    iata_country_code: "US",
    iata_code: "MAC",
    city_name: "Macon",
    city: {
      name: "Macon",
      id: "cit_mcn_us",
      iata_country_code: "US",
      iata_code: "MCN",
    },
  },
  {
    time_zone: "Asia/Manila",
    name: "Mactan-Cebu International Airport",
    longitude: 123.979405,
    latitude: 10.308552,
    id: "arp_ceb_ph",
    icao_code: "RPVM",
    iata_country_code: "PH",
    iata_code: "CEB",
    city_name: "Cebu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Madang Airport",
    longitude: 145.788593,
    latitude: -5.20759,
    id: "arp_mag_pg",
    icao_code: "AYMD",
    iata_country_code: "PG",
    iata_code: "MAG",
    city_name: "Madang",
    city: null,
  },
  {
    time_zone: "Atlantic/Madeira",
    name: "Madeira Airport",
    longitude: -16.775187,
    latitude: 32.696849,
    id: "arp_fnc_pt",
    icao_code: "LPMA",
    iata_country_code: "PT",
    iata_code: "FNC",
    city_name: "Funchal",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Madera Municipal Airport",
    longitude: -120.111645,
    latitude: 36.988889,
    id: "arp_mae_us",
    icao_code: "KMAE",
    iata_country_code: "US",
    iata_code: "MAE",
    city_name: "Madera",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Madirovalo Airport",
    longitude: 46.566175,
    latitude: -16.451277,
    id: "arp_wmv_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "WMV",
    city_name: "Madirovalo",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Madison Municipal Airport",
    longitude: -85.464797,
    latitude: 38.759817,
    id: "arp_mdn_us",
    icao_code: "KIMS",
    iata_country_code: "US",
    iata_code: "MDN",
    city_name: "Madison",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Madison Municipal Airport",
    longitude: -97.086601,
    latitude: 44.014648,
    id: "arp_xmd_us",
    icao_code: "KMDS",
    iata_country_code: "US",
    iata_code: "XMD",
    city_name: "Madison",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Madras Municipal Airport",
    longitude: -121.1546,
    latitude: 44.667174,
    id: "arp_mdj_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MDJ",
    city_name: "Madras",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Madrid–Torrejón Airport",
    longitude: -3.457359,
    latitude: 40.487654,
    id: "arp_toj_es",
    icao_code: "LETO",
    iata_country_code: "ES",
    iata_code: "TOJ",
    city_name: "Madrid",
    city: {
      name: "Madrid",
      id: "cit_mad_es",
      iata_country_code: "ES",
      iata_code: "MAD",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Madurai Airport",
    longitude: 78.091449,
    latitude: 9.836459,
    id: "arp_ixm_in",
    icao_code: "VOMD",
    iata_country_code: "IN",
    iata_code: "IXM",
    city_name: "Madurai",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Mae Hong Son Airport",
    longitude: 97.975399,
    latitude: 19.301483,
    id: "arp_hgn_th",
    icao_code: "VTCH",
    iata_country_code: "TH",
    iata_code: "HGN",
    city_name: "Mae Hong Son",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Mae Sot Airport",
    longitude: 98.544825,
    latitude: 16.701254,
    id: "arp_maq_th",
    icao_code: "VTPM",
    iata_country_code: "TH",
    iata_code: "MAQ",
    city_name: "Mae Sot",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Maewo-Naone Airport",
    longitude: 168.082993,
    latitude: -15.0,
    id: "arp_mwf_vu",
    icao_code: "NVSN",
    iata_country_code: "VU",
    iata_code: "MWF",
    city_name: "Maewo",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Mafeteng Airport",
    longitude: 27.242745,
    latitude: -29.80133,
    id: "arp_mfc_ls",
    icao_code: "FXMF",
    iata_country_code: "LS",
    iata_code: "MFC",
    city_name: "Mafeteng",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Mafia Airport",
    longitude: 39.668079,
    latitude: -7.916167,
    id: "arp_mfa_tz",
    icao_code: "HTMA",
    iata_country_code: "TZ",
    iata_code: "MFA",
    city_name: "Mafia Island",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Magan Airport",
    longitude: 129.542372,
    latitude: 62.105129,
    id: "arp_gyg_ru",
    icao_code: "UEMM",
    iata_country_code: "RU",
    iata_code: "GYG",
    city_name: "Magan",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Maganja da Costa Airport",
    longitude: 32.2167,
    latitude: -25.35,
    id: "arp_mjs_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "MJS",
    city_name: "Maganja da Costa",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Magaruque Airport",
    longitude: 35.424593,
    latitude: -21.9674,
    id: "arp_mfw_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "MFW",
    city_name: "Magaruque Island",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Magas Airport",
    longitude: 45.012633,
    latitude: 43.32027,
    id: "arp_igt_ru",
    icao_code: "URMS",
    iata_country_code: "RU",
    iata_code: "IGT",
    city_name: "Magas",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Magdagachi Airport",
    longitude: 125.799116,
    latitude: 53.471177,
    id: "arp_gdg_ru",
    icao_code: "UHBI",
    iata_country_code: "RU",
    iata_code: "GDG",
    city_name: "Magdagachi",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Magdalena Airport",
    longitude: -64.06188,
    latitude: -13.259249,
    id: "arp_mgd_bo",
    icao_code: "SLMG",
    iata_country_code: "BO",
    iata_code: "MGD",
    city_name: "Magdalena",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Magdeburg–Cochstedt Airport",
    longitude: 11.420046,
    latitude: 51.857177,
    id: "arp_cso_de",
    icao_code: "EDBC",
    iata_country_code: "DE",
    iata_code: "CSO",
    city_name: "Magdeburg",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Magic Valley Regional Airport",
    longitude: -114.48669,
    latitude: 42.482229,
    id: "arp_twf_us",
    icao_code: "KTWF",
    iata_country_code: "US",
    iata_code: "TWF",
    city_name: "Twin Falls",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Magnitogorsk International Airport",
    longitude: 58.758656,
    latitude: 53.392026,
    id: "arp_mqf_ru",
    icao_code: "USCM",
    iata_country_code: "RU",
    iata_code: "MQF",
    city_name: "Magnitogorsk",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Magnolia Municipal Airport",
    longitude: -93.217758,
    latitude: 33.228496,
    id: "arp_ago_us",
    icao_code: "KAGO",
    iata_country_code: "US",
    iata_code: "AGO",
    city_name: "Magnolia",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Magway Airport",
    longitude: 94.968432,
    latitude: 20.154107,
    id: "arp_mwq_mm",
    icao_code: "VYMW",
    iata_country_code: "MM",
    iata_code: "MWQ",
    city_name: "Magway",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Mahana Airport",
    longitude: -7.672121,
    latitude: 8.295471,
    id: "arp_toz_ci",
    icao_code: "DITM",
    iata_country_code: "CI",
    iata_code: "TOZ",
    city_name: "Touba",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Mahanoro Airport",
    longitude: 48.800054,
    latitude: -19.833926,
    id: "arp_vvb_mg",
    icao_code: "FMMH",
    iata_country_code: "MG",
    iata_code: "VVB",
    city_name: "Mahanoro",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Maharana Pratap Airport",
    longitude: 73.893357,
    latitude: 24.618606,
    id: "arp_udr_in",
    icao_code: "VAUD",
    iata_country_code: "IN",
    iata_code: "UDR",
    city_name: "Udaipur",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Mahdia Airport",
    longitude: -59.1511,
    latitude: 5.27749,
    id: "arp_mha_gy",
    icao_code: "SYMD",
    iata_country_code: "GY",
    iata_code: "MHA",
    city_name: "Mahdia",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Mahendranagar Airport",
    longitude: 80.147796,
    latitude: 28.961591,
    id: "arp_xmg_np",
    icao_code: "VNMN",
    iata_country_code: "NP",
    iata_code: "XMG",
    city_name: "Mahendranagar",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Mahenye Airport",
    longitude: 32.3833,
    latitude: -21.1167,
    id: "arp_mjw_zw",
    icao_code: "FVMH",
    iata_country_code: "ZW",
    iata_code: "MJW",
    city_name: "Mahenye",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Mahshahr Airport",
    longitude: 49.152441,
    latitude: 30.552937,
    id: "arp_mrx_ir",
    icao_code: "OIAM",
    iata_country_code: "IR",
    iata_code: "MRX",
    city_name: "Bandar Mahshahr",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Maiana Airport",
    longitude: 173.031054,
    latitude: 1.003598,
    id: "arp_mnk_ki",
    icao_code: "NGMA",
    iata_country_code: "KI",
    iata_code: "MNK",
    city_name: "Maiana",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Maiduguri International Airport",
    longitude: 13.082394,
    latitude: 11.854733,
    id: "arp_miu_ng",
    icao_code: "DNMA",
    iata_country_code: "NG",
    iata_code: "MIU",
    city_name: "Maiduguri",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Maikwak Airport",
    longitude: -59.2833,
    latitude: 5.55,
    id: "arp_veg_gy",
    icao_code: "SYMK",
    iata_country_code: "GY",
    iata_code: "VEG",
    city_name: "Maikwak",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Maimana Airport",
    longitude: 64.762562,
    latitude: 35.929376,
    id: "arp_mmz_af",
    icao_code: "OAMN",
    iata_country_code: "AF",
    iata_code: "MMZ",
    city_name: "Maimanah",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Maimun Saleh Airport",
    longitude: 95.34035,
    latitude: 5.873243,
    id: "arp_sbg_id",
    icao_code: "WITN",
    iata_country_code: "ID",
    iata_code: "SBG",
    city_name: "Sabang",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mainoru Airport",
    longitude: 134.094094,
    latitude: -14.053694,
    id: "arp_miz_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MIZ",
    city_name: "Mainoru",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Maintirano Airport",
    longitude: 44.033167,
    latitude: -18.049999,
    id: "arp_mxt_mg",
    icao_code: "FMMO",
    iata_country_code: "MG",
    iata_code: "MXT",
    city_name: "Maintirano",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Maio Airport",
    longitude: -23.213597,
    latitude: 15.156046,
    id: "arp_mmo_cv",
    icao_code: "GVMA",
    iata_country_code: "CV",
    iata_code: "MMO",
    city_name: "Vila Do Maio",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Maitland Airport",
    longitude: 151.49161,
    latitude: -32.702442,
    id: "arp_mtl_au",
    icao_code: "YMND",
    iata_country_code: "AU",
    iata_code: "MTL",
    city_name: "Maitland",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Majkin Airport",
    longitude: 168.166667,
    latitude: 7.833333,
    id: "arp_mje_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "MJE",
    city_name: "Majkin",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Major Brigadeiro Trompowsky Airport",
    longitude: -45.4733,
    latitude: -21.590099,
    id: "arp_vag_br",
    icao_code: "SBVG",
    iata_country_code: "BR",
    iata_code: "VAG",
    city_name: "Varginha",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Majors Airport",
    longitude: -96.065288,
    latitude: 33.067789,
    id: "arp_gvt_us",
    icao_code: "KGVT",
    iata_country_code: "US",
    iata_code: "GVT",
    city_name: "Greenville",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Makabana Airport",
    longitude: 12.599427,
    latitude: -3.479637,
    id: "arp_kmk_cg",
    icao_code: "FCPA",
    iata_country_code: "CG",
    iata_code: "KMK",
    city_name: "Makabana",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Makemo Airport",
    longitude: -143.657991,
    latitude: -16.583877,
    id: "arp_mkp_pf",
    icao_code: "NTGM",
    iata_country_code: "PF",
    iata_code: "MKP",
    city_name: "Makemo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Makini Airport",
    longitude: 147.651274,
    latitude: -6.532387,
    id: "arp_mpg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MPG",
    city_name: "Makini",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Makin Island Airport",
    longitude: 172.989703,
    latitude: 3.373455,
    id: "arp_mtk_ki",
    icao_code: "NGMN",
    iata_country_code: "KI",
    iata_code: "MTK",
    city_name: "Makin",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Makkovik Airport",
    longitude: -59.186178,
    latitude: 55.077532,
    id: "arp_ymn_ca",
    icao_code: "CYFT",
    iata_country_code: "CA",
    iata_code: "YMN",
    city_name: "Makkovik",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Makokou Airport",
    longitude: 12.891147,
    latitude: 0.579444,
    id: "arp_mku_ga",
    icao_code: "FOOK",
    iata_country_code: "GA",
    iata_code: "MKU",
    city_name: "Makokou",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Makoua Airport",
    longitude: 15.576308,
    latitude: -0.021461,
    id: "arp_mkj_cg",
    icao_code: "FCOM",
    iata_country_code: "CG",
    iata_code: "MKJ",
    city_name: "Makoua",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Maku International Airport",
    longitude: 44.925278,
    latitude: 39.1925,
    id: "arp_imq_ir",
    icao_code: "OITU",
    iata_country_code: "IR",
    iata_code: "IMQ",
    city_name: "Maku",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Makung Airport",
    longitude: 119.628091,
    latitude: 23.566405,
    id: "arp_mzg_tw",
    icao_code: "RCQC",
    iata_country_code: "TW",
    iata_code: "MZG",
    city_name: "Makung",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Makurdi Airport",
    longitude: 8.613733,
    latitude: 7.702947,
    id: "arp_mdi_ng",
    icao_code: "DNMK",
    iata_country_code: "NG",
    iata_code: "MDI",
    city_name: "Makurdi",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Malabang Airport",
    longitude: 124.091154,
    latitude: 7.634166,
    id: "arp_mlp_ph",
    icao_code: "RPMM",
    iata_country_code: "PH",
    iata_code: "MLP",
    city_name: "Malabang",
    city: null,
  },
  {
    time_zone: "Africa/Malabo",
    name: "Malabo International Airport",
    longitude: 8.711122,
    latitude: 3.756322,
    id: "arp_ssg_gq",
    icao_code: "FGSL",
    iata_country_code: "GQ",
    iata_code: "SSG",
    city_name: "Malabo",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Malacca Airport",
    longitude: 102.2511,
    latitude: 2.262148,
    id: "arp_mkz_my",
    icao_code: "WMKM",
    iata_country_code: "MY",
    iata_code: "MKZ",
    city_name: "Malacca",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Malad City Airport",
    longitude: -112.289269,
    latitude: 42.166162,
    id: "arp_mld_us",
    icao_code: "KMLD",
    iata_country_code: "US",
    iata_code: "MLD",
    city_name: "Malad City",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Málaga Airport",
    longitude: -4.495603,
    latitude: 36.675966,
    id: "arp_agp_es",
    icao_code: "LEMG",
    iata_country_code: "ES",
    iata_code: "AGP",
    city_name: "Málaga",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Malaimbandy Airport",
    longitude: 45.542981,
    latitude: -20.355345,
    id: "arp_wml_mg",
    icao_code: "FMMC",
    iata_country_code: "MG",
    iata_code: "WML",
    city_name: "Malaimbandy",
    city: null,
  },
  {
    time_zone: "Africa/Juba",
    name: "Malakal Airport",
    longitude: 31.652208,
    latitude: 9.558943,
    id: "arp_mak_sd",
    icao_code: "HSSM",
    iata_country_code: "SD",
    iata_code: "MAK",
    city_name: "Malakal",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Malalaua Airport",
    longitude: 146.154678,
    latitude: -8.07106,
    id: "arp_mlq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MLQ",
    city_name: "Malalaua",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Malam Airport",
    longitude: 142.650302,
    latitude: -8.70957,
    id: "arp_mqo_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MQO",
    city_name: "Malam",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Mala Mala Airport",
    longitude: 31.544277,
    latitude: -24.816762,
    id: "arp_aam_za",
    icao_code: "FAMD",
    iata_country_code: "ZA",
    iata_code: "AAM",
    city_name: "Mala Mala",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Malanje Airport",
    longitude: 16.312625,
    latitude: -9.525055,
    id: "arp_meg_ao",
    icao_code: "FNMA",
    iata_country_code: "AO",
    iata_code: "MEG",
    city_name: "Malanje",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Malatya Erhaç Airport",
    longitude: 38.10433,
    latitude: 38.439632,
    id: "arp_mlx_tr",
    icao_code: "LTAT",
    iata_country_code: "TR",
    iata_code: "MLX",
    city_name: "Malatya",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Malcolm McKinnon Airport",
    longitude: -81.390179,
    latitude: 31.152689,
    id: "arp_ssi_us",
    icao_code: "KSSI",
    iata_country_code: "US",
    iata_code: "SSI",
    city_name: "Brunswick",
    city: {
      name: "Brunswick",
      id: "cit_ssi_us",
      iata_country_code: "US",
      iata_code: "SSI",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Malda Airport",
    longitude: 88.125529,
    latitude: 25.010356,
    id: "arp_lda_in",
    icao_code: "VEMH",
    iata_country_code: "IN",
    iata_code: "LDA",
    city_name: "Malda",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Malden Regional Airport",
    longitude: -89.992218,
    latitude: 36.60057,
    id: "arp_maw_us",
    icao_code: "KMAW",
    iata_country_code: "US",
    iata_code: "MAW",
    city_name: "Malden",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Malekolon Airport",
    longitude: -98.599197,
    latitude: 31.9189,
    id: "arp_mkn_pg",
    icao_code: "AYMV",
    iata_country_code: "PG",
    iata_code: "MKN",
    city_name: "Malekolon",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Malekoula Airport",
    longitude: 167.829253,
    latitude: -16.461122,
    id: "arp_lpm_vu",
    icao_code: "NVSL",
    iata_country_code: "VU",
    iata_code: "LPM",
    city_name: "Lamap",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Malelane Airport",
    longitude: 31.567837,
    latitude: -25.473027,
    id: "arp_lle_za",
    icao_code: "FAMN",
    iata_country_code: "ZA",
    iata_code: "LLE",
    city_name: "Malelane",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Malevo Airport",
    longitude: 25.604381,
    latitude: 41.871839,
    id: "arp_hkv_bg",
    icao_code: null,
    iata_country_code: "BG",
    iata_code: "HKV",
    city_name: "Haskovo",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Maliana Airport",
    longitude: 125.009,
    latitude: -8.16774,
    id: "arp_mpt_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "MPT",
    city_name: "Maliana",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Malikus Saleh Airport",
    longitude: 96.94963,
    latitude: 5.227705,
    id: "arp_lsw_id",
    icao_code: "WITM",
    iata_country_code: "ID",
    iata_code: "LSW",
    city_name: "Lhokseumawe",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Malindi Airport",
    longitude: 40.101291,
    latitude: -3.227675,
    id: "arp_myd_ke",
    icao_code: "HKML",
    iata_country_code: "KE",
    iata_code: "MYD",
    city_name: "Malindi",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mal Island Airport",
    longitude: -123.135834,
    latitude: 45.194443,
    id: "arp_mmv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MMV",
    city_name: "Mal Island",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Mallacoota Airport",
    longitude: 149.719496,
    latitude: -37.599623,
    id: "arp_xmc_au",
    icao_code: "YMCO",
    iata_country_code: "AU",
    iata_code: "XMC",
    city_name: "Mallacoota",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Mallam Aminu Kano International Airport",
    longitude: 8.523347,
    latitude: 12.046624,
    id: "arp_kan_ng",
    icao_code: "DNKN",
    iata_country_code: "NG",
    iata_code: "KAN",
    city_name: "Kano",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Malmö Airport",
    longitude: 13.37429,
    latitude: 55.535919,
    id: "arp_mmx_se",
    icao_code: "ESMS",
    iata_country_code: "SE",
    iata_code: "MMX",
    city_name: "Malmö",
    city: {
      name: "Malmo",
      id: "cit_mma_se",
      iata_country_code: "SE",
      iata_code: "MMA",
    },
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Maloelap Airport",
    longitude: 171.231666,
    latitude: 8.706387,
    id: "arp_mav_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "MAV",
    city_name: "Maloelap Atoll",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Malolo Lailai Island Airport",
    longitude: 177.196828,
    latitude: -17.778085,
    id: "arp_ptf_fj",
    icao_code: "NFFO",
    iata_country_code: "FJ",
    iata_code: "PTF",
    city_name: "Malolo Lailai Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Malone-Dufort Airport",
    longitude: -74.328662,
    latitude: 44.852535,
    id: "arp_mal_id",
    icao_code: "WAPE",
    iata_country_code: "ID",
    iata_code: "MAL",
    city_name: "Mangole Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Malta Airport",
    longitude: -107.918948,
    latitude: 48.366993,
    id: "arp_mlk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MLK",
    city_name: "Malta",
    city: null,
  },
  {
    time_zone: "Europe/Malta",
    name: "Malta International Airport",
    longitude: 14.48039,
    latitude: 35.855806,
    id: "arp_mla_mt",
    icao_code: "LMML",
    iata_country_code: "MT",
    iata_code: "MLA",
    city_name: "Malta",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mamai Airport",
    longitude: 149.521227,
    latitude: -10.290537,
    id: "arp_map_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MAP",
    city_name: "Mamai",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Mamburao Airport",
    longitude: 120.605186,
    latitude: 13.207807,
    id: "arp_mbo_ph",
    icao_code: "RPUM",
    iata_country_code: "PH",
    iata_code: "MBO",
    city_name: "Mamburao",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Mamfe Airport",
    longitude: 9.306228,
    latitude: 5.704479,
    id: "arp_mmf_cm",
    icao_code: "FKKF",
    iata_country_code: "CM",
    iata_code: "MMF",
    city_name: "Mamfe",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Mamitupo Airport",
    longitude: -77.983333,
    latitude: 9.183333,
    id: "arp_mpi_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "MPI",
    city_name: "Mamitupo",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mammoth Yosemite Airport",
    longitude: -118.838364,
    latitude: 37.624207,
    id: "arp_mmh_us",
    icao_code: "KMMH",
    iata_country_code: "US",
    iata_code: "MMH",
    city_name: "Mammoth Lakes",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Mampikony Airport",
    longitude: 47.644164,
    latitude: -16.072269,
    id: "arp_wmp_mg",
    icao_code: "FMNP",
    iata_country_code: "MG",
    iata_code: "WMP",
    city_name: "Mampikony",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Man Airport",
    longitude: -7.587584,
    latitude: 7.271724,
    id: "arp_mjc_ci",
    icao_code: "DIMN",
    iata_country_code: "CI",
    iata_code: "MJC",
    city_name: "Man",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Mana Island Airport",
    longitude: 177.098115,
    latitude: -17.673084,
    id: "arp_mnf_fj",
    icao_code: "NFMA",
    iata_country_code: "FJ",
    iata_code: "MNF",
    city_name: "Mana Island",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Manakara Airport",
    longitude: 48.021684,
    latitude: -22.11971,
    id: "arp_wvk_mg",
    icao_code: "FMSK",
    iata_country_code: "MG",
    iata_code: "WVK",
    city_name: "Manakara",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Mananara Nord Airport",
    longitude: 49.772459,
    latitude: -16.163228,
    id: "arp_wmr_mg",
    icao_code: "FMNC",
    iata_country_code: "MG",
    iata_code: "WMR",
    city_name: "Mananara Avaratra",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Manang Airport",
    longitude: 84.088009,
    latitude: 28.6415,
    id: "arp_ngx_np",
    icao_code: "VNMA",
    iata_country_code: "NP",
    iata_code: "NGX",
    city_name: "Manang",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Mananjary Airport",
    longitude: 48.358221,
    latitude: -21.202338,
    id: "arp_mnj_mg",
    icao_code: "FMSM",
    iata_country_code: "MG",
    iata_code: "MNJ",
    city_name: "Mananjary",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Manapouri Airport",
    longitude: 167.646419,
    latitude: -45.532717,
    id: "arp_teu_nz",
    icao_code: "NZMO",
    iata_country_code: "NZ",
    iata_code: "TEU",
    city_name: "Te Anau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Manari Airport",
    longitude: 147.621696,
    latitude: -9.190747,
    id: "arp_mrm_pg",
    icao_code: "AYMA",
    iata_country_code: "PG",
    iata_code: "MRM",
    city_name: "Manari",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Manassas Regional Airport",
    longitude: -77.515503,
    latitude: 38.722114,
    id: "arp_mnz_us",
    icao_code: "KHEF",
    iata_country_code: "US",
    iata_code: "MNZ",
    city_name: "Manassas",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Manatee Airport",
    longitude: -89.133333,
    latitude: 17.25,
    id: "arp_mze_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "MZE",
    city_name: "Manatee",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Manaung Airport",
    longitude: 93.685341,
    latitude: 18.845936,
    id: "arp_mgu_mm",
    icao_code: "VYMN",
    iata_country_code: "MM",
    iata_code: "MGU",
    city_name: "Manaung",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Manchester Airport",
    longitude: -2.27384,
    latitude: 53.35625,
    id: "arp_man_gb",
    icao_code: "EGCC",
    iata_country_code: "GB",
    iata_code: "MAN",
    city_name: "Manchester",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Manchester-Boston Regional Airport",
    longitude: -71.436649,
    latitude: 42.930885,
    id: "arp_mht_us",
    icao_code: "KMHT",
    iata_country_code: "US",
    iata_code: "MHT",
    city_name: "Manchester",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Manda Airport",
    longitude: 40.913078,
    latitude: -2.25232,
    id: "arp_lau_ke",
    icao_code: "HKLU",
    iata_country_code: "KE",
    iata_code: "LAU",
    city_name: "Lamu",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Mandabe Airport",
    longitude: 44.94009,
    latitude: -21.046645,
    id: "arp_wmd_mg",
    icao_code: "FMSC",
    iata_country_code: "MG",
    iata_code: "WMD",
    city_name: "Mandabe",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Mandalay Chanmyathazi Airport",
    longitude: 96.089057,
    latitude: 21.940308,
    id: "arp_vbc_mm",
    icao_code: "VYCZ",
    iata_country_code: "MM",
    iata_code: "VBC",
    city_name: "Mandalay",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Mandalay International Airport",
    longitude: 95.976159,
    latitude: 21.702919,
    id: "arp_mdl_mm",
    icao_code: "VYMD",
    iata_country_code: "MM",
    iata_code: "MDL",
    city_name: "Mandalay",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Mandalgovi Airport",
    longitude: 106.268558,
    latitude: 45.737468,
    id: "arp_mxw_mn",
    icao_code: "ZMMG",
    iata_country_code: "MN",
    iata_code: "MXW",
    city_name: "Mandalgovi",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Mandera Airport",
    longitude: 41.845613,
    latitude: 3.933336,
    id: "arp_nde_ke",
    icao_code: "HKMA",
    iata_country_code: "KE",
    iata_code: "NDE",
    city_name: "Mandera",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Mandinga Airport",
    longitude: -76.67594,
    latitude: 5.072057,
    id: "arp_cog_co",
    icao_code: "SKCD",
    iata_country_code: "CO",
    iata_code: "COG",
    city_name: "Condoto",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mandora Airport",
    longitude: 120.838152,
    latitude: -19.738185,
    id: "arp_mqa_au",
    icao_code: "YMDI",
    iata_country_code: "AU",
    iata_code: "MQA",
    city_name: "Mandora Station",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Mandritsara Airport",
    longitude: 48.833284,
    latitude: -15.833049,
    id: "arp_wma_mg",
    icao_code: "FMNX",
    iata_country_code: "MG",
    iata_code: "WMA",
    city_name: "Mandritsara",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Manega Airport",
    longitude: 10.2167,
    latitude: -1.73333,
    id: "arp_mgo_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "MGO",
    city_name: "Manega",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Manetai Airport",
    longitude: 155.405375,
    latitude: -6.10965,
    id: "arp_mvi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MVI",
    city_name: "Manetai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Manga Airport",
    longitude: 153.016,
    latitude: -4.14729,
    id: "arp_mgp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MGP",
    city_name: "Manga",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Mangaia Island Airport",
    longitude: -157.906745,
    latitude: -21.895768,
    id: "arp_mgs_ck",
    icao_code: "NCMG",
    iata_country_code: "CK",
    iata_code: "MGS",
    city_name: "Mangaia Island",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Mangalore International Airport",
    longitude: 74.888666,
    latitude: 12.958924,
    id: "arp_ixe_in",
    icao_code: "VOML",
    iata_country_code: "IN",
    iata_code: "IXE",
    city_name: "Mangalore",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Mangla Airport",
    longitude: 73.63691,
    latitude: 33.051506,
    id: "arp_xjm_pk",
    icao_code: "OPMA",
    iata_country_code: "PK",
    iata_code: "XJM",
    city_name: "Mangla",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Mangochi Airport",
    longitude: 35.265408,
    latitude: -14.485416,
    id: "arp_mai_mw",
    icao_code: "FWMG",
    iata_country_code: "MW",
    iata_code: "MAI",
    city_name: "Mangochi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Manguna Airport",
    longitude: 151.792417,
    latitude: -5.580145,
    id: "arp_mfo_pg",
    icao_code: "AYNG",
    iata_country_code: "PG",
    iata_code: "MFO",
    city_name: "Manguna",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Manhattan Regional Airport",
    longitude: -96.670363,
    latitude: 39.13906,
    id: "arp_mhk_us",
    icao_code: "KMHK",
    iata_country_code: "US",
    iata_code: "MHK",
    city_name: "Manhattan",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Manicoré Airport",
    longitude: -61.278301,
    latitude: -5.811379,
    id: "arp_mnx_br",
    icao_code: "SBMY",
    iata_country_code: "BR",
    iata_code: "MNX",
    city_name: "Manicoré",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Manihi Airport",
    longitude: -146.07013,
    latitude: -14.436673,
    id: "arp_xmh_pf",
    icao_code: "NTGI",
    iata_country_code: "PF",
    iata_code: "XMH",
    city_name: "Manihi",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Manihiki Island Airport",
    longitude: -161.001998,
    latitude: -10.3767,
    id: "arp_mhx_ck",
    icao_code: "NCMH",
    iata_country_code: "CK",
    iata_code: "MHX",
    city_name: "Manihiki Island",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Maniitsoq Airport",
    longitude: -52.937336,
    latitude: 65.412107,
    id: "arp_jsu_gl",
    icao_code: "BGMQ",
    iata_country_code: "GL",
    iata_code: "JSU",
    city_name: "Maniitsoq",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Manila Municipal Airport",
    longitude: -90.155332,
    latitude: 35.892759,
    id: "arp_mxa_us",
    icao_code: "KMXA",
    iata_country_code: "US",
    iata_code: "MXA",
    city_name: "Manila",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Maningrida Airport",
    longitude: 134.233487,
    latitude: -12.055531,
    id: "arp_mng_au",
    icao_code: "YMGD",
    iata_country_code: "AU",
    iata_code: "MNG",
    city_name: "Maningrida",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Manistee County Blacker Airport",
    longitude: -86.251553,
    latitude: 44.273915,
    id: "arp_mbl_us",
    icao_code: "KMBL",
    iata_country_code: "US",
    iata_code: "MBL",
    city_name: "Manistee",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Manitoulin East Municipal Airport",
    longitude: -81.858001,
    latitude: 45.842247,
    id: "arp_yem_ca",
    icao_code: "CYEM",
    iata_country_code: "CA",
    iata_code: "YEM",
    city_name: "Manitowaning",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Manitouwadge Airport",
    longitude: -85.860603,
    latitude: 49.0839,
    id: "arp_ymg_ca",
    icao_code: "CYMG",
    iata_country_code: "CA",
    iata_code: "YMG",
    city_name: "Manitouwadge",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Manitowoc County Airport",
    longitude: -87.682482,
    latitude: 44.124286,
    id: "arp_mtw_us",
    icao_code: "KMTW",
    iata_country_code: "US",
    iata_code: "MTW",
    city_name: "Manitowoc",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Maniwaki Airport",
    longitude: -75.990493,
    latitude: 46.272824,
    id: "arp_ymw_ca",
    icao_code: "CYMW",
    iata_country_code: "CA",
    iata_code: "YMW",
    city_name: "Maniwaki",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Manja Airport",
    longitude: 44.316829,
    latitude: -21.426265,
    id: "arp_mja_mg",
    icao_code: "FMSJ",
    iata_country_code: "MG",
    iata_code: "MJA",
    city_name: "Manja",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Manjimup Airport",
    longitude: 116.135557,
    latitude: -34.263289,
    id: "arp_mjp_au",
    icao_code: "YMJM",
    iata_country_code: "AU",
    iata_code: "MJP",
    city_name: "Manjimup",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mankato Regional Airport",
    longitude: -93.919673,
    latitude: 44.220755,
    id: "arp_mkt_us",
    icao_code: "KMKT",
    iata_country_code: "US",
    iata_code: "MKT",
    city_name: "Mankato",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Manley Hot Springs Airport",
    longitude: -150.643681,
    latitude: 64.997286,
    id: "arp_mly_us",
    icao_code: "PAML",
    iata_country_code: "US",
    iata_code: "MLY",
    city_name: "Manley Hot Springs",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Manners Creek Airport",
    longitude: 137.98412,
    latitude: -22.099177,
    id: "arp_mfp_au",
    icao_code: "YMCR",
    iata_country_code: "AU",
    iata_code: "MFP",
    city_name: "Manners Creek Station",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Mannheim-City Airport",
    longitude: 8.516889,
    latitude: 49.474374,
    id: "arp_mhg_de",
    icao_code: "EDFM",
    iata_country_code: "DE",
    iata_code: "MHG",
    city_name: "Mannheim",
    city: null,
  },
  {
    time_zone: "Africa/Niamey",
    name: "Mano Dayak International Airport",
    longitude: 7.99887,
    latitude: 16.965782,
    id: "arp_ajy_ne",
    icao_code: "DRZA",
    iata_country_code: "NE",
    iata_code: "AJY",
    city_name: "Agadez",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Manokotak Airport",
    longitude: -159.049993,
    latitude: 58.989788,
    id: "arp_kmo_us",
    icao_code: "PAMB",
    iata_country_code: "US",
    iata_code: "KMO",
    city_name: "Manokotak",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Manono Airport",
    longitude: 27.394077,
    latitude: -7.288811,
    id: "arp_mno_cd",
    icao_code: "FZRA",
    iata_country_code: "CD",
    iata_code: "MNO",
    city_name: "Manono",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Mansa Airport",
    longitude: 28.877166,
    latitude: -11.138785,
    id: "arp_mns_zm",
    icao_code: "FLMA",
    iata_country_code: "ZM",
    iata_code: "MNS",
    city_name: "Mansa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mansfield Lahm Regional Airport",
    longitude: -82.515436,
    latitude: 40.819204,
    id: "arp_mfd_us",
    icao_code: "KMFD",
    iata_country_code: "US",
    iata_code: "MFD",
    city_name: "Mansfield",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Mansons Landing Seaplane Base Airport",
    longitude: -124.983939,
    latitude: 50.068809,
    id: "arp_ymu_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YMU",
    city_name: "Mansons Landing",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Manti-Ephraim Airport",
    longitude: -111.614078,
    latitude: 39.329482,
    id: "arp_ntj_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NTJ",
    city_name: "Manti",
    city: null,
  },
  {
    time_zone: "America/Merida",
    name: "Manuel Crescencio Rejón International Airport",
    longitude: -89.659374,
    latitude: 20.935522,
    id: "arp_mid_mx",
    icao_code: "MMMD",
    iata_country_code: "MX",
    iata_code: "MID",
    city_name: "Mérida",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Manumu Airport",
    longitude: 147.55,
    latitude: -8.08333,
    id: "arp_uuu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UUU",
    city_name: "Manumu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Manzhouli Xijiao Airport",
    longitude: 117.33,
    latitude: 49.566667,
    id: "arp_nzh_cn",
    icao_code: "ZBMZ",
    iata_country_code: "CN",
    iata_code: "NZH",
    city_name: "Manzhouli",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Mao Airport",
    longitude: 15.314444,
    latitude: 14.145556,
    id: "arp_amo_td",
    icao_code: "FTTU",
    iata_country_code: "TD",
    iata_code: "AMO",
    city_name: "Mao",
    city: null,
  },
  {
    time_zone: "Pacific/Apia",
    name: "Maota Airport",
    longitude: -172.257992,
    latitude: -13.742377,
    id: "arp_mxs_ws",
    icao_code: "NSMA",
    iata_country_code: "WS",
    iata_code: "MXS",
    city_name: "Maota",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Maple Bay Airport",
    longitude: -123.614916,
    latitude: 48.816849,
    id: "arp_yaq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YAQ",
    city_name: "Maple Bay",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mapoda Airport",
    longitude: 143.169285,
    latitude: -7.967591,
    id: "arp_mpf_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MPF",
    city_name: "Mapoda",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mapua Airport",
    longitude: 151.417,
    latitude: -2.91667,
    id: "arp_mpu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MPU",
    city_name: "Mabua",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Maputo International Airport",
    longitude: 32.573281,
    latitude: -25.92126,
    id: "arp_mpm_mz",
    icao_code: "FQMA",
    iata_country_code: "MZ",
    iata_code: "MPM",
    city_name: "Maputo",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Maquehue Airport",
    longitude: -72.6371,
    latitude: -38.766799,
    id: "arp_pzs_cl",
    icao_code: "SCTC",
    iata_country_code: "CL",
    iata_code: "PZS",
    city_name: "Temuco",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Maquinchao Airport",
    longitude: -68.708252,
    latitude: -41.242978,
    id: "arp_mqd_ar",
    icao_code: "SAVQ",
    iata_country_code: "AR",
    iata_code: "MQD",
    city_name: "Maquinchao",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Maraba Airport",
    longitude: -49.13814,
    latitude: -5.36893,
    id: "arp_mab_br",
    icao_code: "SBMA",
    iata_country_code: "BR",
    iata_code: "MAB",
    city_name: "Marabá",
    city: null,
  },
  {
    time_zone: "Africa/Niamey",
    name: "Maradi Airport",
    longitude: 7.126896,
    latitude: 13.502488,
    id: "arp_mfq_ne",
    icao_code: "DRRM",
    iata_country_code: "NE",
    iata_code: "MFQ",
    city_name: "Maradi",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Marakei Airport",
    longitude: 173.271669,
    latitude: 2.058651,
    id: "arp_mzk_ki",
    icao_code: "NGMK",
    iata_country_code: "KI",
    iata_code: "MZK",
    city_name: "Marakei",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Maramag Airport",
    longitude: 125.033391,
    latitude: 7.755681,
    id: "arp_xma_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "XMA",
    city_name: "Maramag",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Maramuni Airport",
    longitude: 143.483963,
    latitude: -5.128472,
    id: "arp_mwi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MWI",
    city_name: "Maramuni",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Marana Regional Airport",
    longitude: -111.218264,
    latitude: 32.409693,
    id: "arp_avw_us",
    icao_code: "KAVQ",
    iata_country_code: "US",
    iata_code: "AVW",
    city_name: "Tucson",
    city: {
      name: "Tucson",
      id: "cit_tus_us",
      iata_country_code: "US",
      iata_code: "TUS",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "Maranggo Airport",
    longitude: 123.917798,
    latitude: -5.765575,
    id: "arp_tqq_id",
    icao_code: "WA44",
    iata_country_code: "ID",
    iata_code: "TQQ",
    city_name: "Tomia",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Mara North Airport",
    longitude: 35.123969,
    latitude: -1.145268,
    id: "arp_hkr_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "HKR",
    city_name: "Maasai Mara",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Mara Serena Airport",
    longitude: 35.009464,
    latitude: -1.405011,
    id: "arp_mre_ke",
    icao_code: "HKMS",
    iata_country_code: "KE",
    iata_code: "MRE",
    city_name: "Masai Mara",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Marathon Airport",
    longitude: -86.347015,
    latitude: 48.757111,
    id: "arp_ysp_ca",
    icao_code: "CYSP",
    iata_country_code: "CA",
    iata_code: "YSP",
    city_name: "Marathon",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Marau Airport",
    longitude: 160.824996,
    latitude: -9.861669,
    id: "arp_rus_sb",
    icao_code: "AGGU",
    iata_country_code: "SB",
    iata_code: "RUS",
    city_name: "Marau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Marawaka Airport",
    longitude: 145.885434,
    latitude: -6.972825,
    id: "arp_mwg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MWG",
    city_name: "Marawaka",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Marble Bar Airport",
    longitude: 119.834296,
    latitude: -21.162918,
    id: "arp_mbb_au",
    icao_code: "YMBL",
    iata_country_code: "AU",
    iata_code: "MBB",
    city_name: "Marble Bar",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Marble Canyon Airport",
    longitude: -111.642674,
    latitude: 36.811508,
    id: "arp_myh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MYH",
    city_name: "Marble Canyon",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "March Air Reserve Base Airport",
    longitude: -117.259003,
    latitude: 33.880699,
    id: "arp_riv_us",
    icao_code: "KRIV",
    iata_country_code: "US",
    iata_code: "RIV",
    city_name: "Riverside",
    city: {
      name: "Riverside",
      id: "cit_ral_us",
      iata_country_code: "US",
      iata_code: "RAL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Marco Island Airport",
    longitude: -81.672627,
    latitude: 25.995209,
    id: "arp_mrk_us",
    icao_code: "KMKY",
    iata_country_code: "US",
    iata_code: "MRK",
    city_name: "Marco Island",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "Mar Del Plata Airport",
    longitude: -57.577518,
    latitude: -37.933661,
    id: "arp_mdq_ar",
    icao_code: "SAZM",
    iata_country_code: "AR",
    iata_code: "MDQ",
    city_name: "Mar del Plata",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Mardin Airport",
    longitude: 40.632325,
    latitude: 37.223987,
    id: "arp_mqm_tr",
    icao_code: "LTCR",
    iata_country_code: "TR",
    iata_code: "MQM",
    city_name: "Mardin",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Maré Airport",
    longitude: 168.038527,
    latitude: -21.482474,
    id: "arp_mee_nc",
    icao_code: "NWWR",
    iata_country_code: "NC",
    iata_code: "MEE",
    city_name: "Maré",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Marechal Cunha Machado International Airport",
    longitude: -44.235202,
    latitude: -2.584495,
    id: "arp_slz_br",
    icao_code: "SBSL",
    iata_country_code: "BR",
    iata_code: "SLZ",
    city_name: "São Luís",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Marechal Rondon International Airport",
    longitude: -56.116984,
    latitude: -15.652989,
    id: "arp_cgb_br",
    icao_code: "SBCY",
    iata_country_code: "BR",
    iata_code: "CGB",
    city_name: "Cuiabá",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mareeba Airport",
    longitude: 145.418701,
    latitude: -17.068846,
    id: "arp_mrg_au",
    icao_code: "YMBA",
    iata_country_code: "AU",
    iata_code: "MRG",
    city_name: "Mareeba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marfa Municipal Airport",
    longitude: -104.017349,
    latitude: 30.371026,
    id: "arp_mrf_us",
    icao_code: "KMRF",
    iata_country_code: "US",
    iata_code: "MRF",
    city_name: "Marfa",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Margaret Ekpo International Airport",
    longitude: 8.346978,
    latitude: 4.972686,
    id: "arp_cbq_ng",
    icao_code: "DNCA",
    iata_country_code: "NG",
    iata_code: "CBQ",
    city_name: "Calabar",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Margaret River Airport",
    longitude: 115.099616,
    latitude: -33.931354,
    id: "arp_mqz_au",
    icao_code: "YMGT",
    iata_country_code: "AU",
    iata_code: "MQZ",
    city_name: "Margaret River",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Margaret River  Airport",
    longitude: 126.883003,
    latitude: -18.6217,
    id: "arp_mgv_au",
    icao_code: "YMGR",
    iata_country_code: "AU",
    iata_code: "MGV",
    city_name: "Margaret River Station",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Margarima Airport",
    longitude: 143.367,
    latitude: -6.03333,
    id: "arp_mgg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MGG",
    city_name: "Margarima",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Margate Airport",
    longitude: 30.343439,
    latitude: -30.85877,
    id: "arp_mgh_za",
    icao_code: "FAMG",
    iata_country_code: "ZA",
    iata_code: "MGH",
    city_name: "Margate",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Maria Cristina Airport",
    longitude: 124.214709,
    latitude: 8.130809,
    id: "arp_ign_ph",
    icao_code: "RPMI",
    iata_country_code: "PH",
    iata_code: "IGN",
    city_name: "Iligan",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "María Dolores Airport",
    longitude: -72.424983,
    latitude: -37.402104,
    id: "arp_lsq_cl",
    icao_code: "SCGE",
    iata_country_code: "CL",
    iata_code: "LSQ",
    city_name: "Los Angeles",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Maria Montez International Airport",
    longitude: -71.12189,
    latitude: 18.250309,
    id: "arp_brx_do",
    icao_code: "MDBH",
    iata_country_code: "DO",
    iata_code: "BRX",
    city_name: "Barahona",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Mariana Grajales Airport",
    longitude: -75.158312,
    latitude: 20.085292,
    id: "arp_gao_cu",
    icao_code: "MUGT",
    iata_country_code: "CU",
    iata_code: "GAO",
    city_name: "Guantánamo",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Mariánské Lázně Airport",
    longitude: 12.72391,
    latitude: 49.923431,
    id: "arp_mka_cz",
    icao_code: "LKMR",
    iata_country_code: "CZ",
    iata_code: "MKA",
    city_name: "Mariánské Lázně",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Maria Reiche Neuman Airport",
    longitude: -74.961502,
    latitude: -14.854,
    id: "arp_nzc_pe",
    icao_code: "SPZA",
    iata_country_code: "PE",
    iata_code: "NZC",
    city_name: "Nazca",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Marib Airport",
    longitude: 45.326933,
    latitude: 15.469855,
    id: "arp_myn_ye",
    icao_code: "OYMB",
    iata_country_code: "YE",
    iata_code: "MYN",
    city_name: "Marib",
    city: null,
  },
  {
    time_zone: "Europe/Ljubljana",
    name: "Maribor Edvard Rusjan Airport",
    longitude: 15.685645,
    latitude: 46.479448,
    id: "arp_mbx_si",
    icao_code: "LJMB",
    iata_country_code: "SI",
    iata_code: "MBX",
    city_name: "Maribor",
    city: null,
  },
  {
    time_zone: "America/Guadeloupe",
    name: "Marie-Galante Airport",
    longitude: -61.270306,
    latitude: 15.868793,
    id: "arp_gbj_gp",
    icao_code: "TFFM",
    iata_country_code: "GP",
    iata_code: "GBJ",
    city_name: "Grand Bourg",
    city: null,
  },
  {
    time_zone: "Europe/Mariehamn",
    name: "Mariehamn Airport",
    longitude: 19.902838,
    latitude: 60.123516,
    id: "arp_mhq_fi",
    icao_code: "EFMA",
    iata_country_code: "FI",
    iata_code: "MHQ",
    city_name: "Mariehamn",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Marilia Airport",
    longitude: -49.927603,
    latitude: -22.196924,
    id: "arp_mii_br",
    icao_code: "SBML",
    iata_country_code: "BR",
    iata_code: "MII",
    city_name: "Marília",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Marina di Campo Airport",
    longitude: 10.238909,
    latitude: 42.761409,
    id: "arp_eba_it",
    icao_code: "LIRJ",
    iata_country_code: "IT",
    iata_code: "EBA",
    city_name: "Elba",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Marina Municipal Airport",
    longitude: -121.762693,
    latitude: 36.680581,
    id: "arp_oar_us",
    icao_code: "KOAR",
    iata_country_code: "US",
    iata_code: "OAR",
    city_name: "Marina",
    city: {
      name: "Monterey/Carmel",
      id: "cit_mry_us",
      iata_country_code: "US",
      iata_code: "MRY",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Marin County Airport",
    longitude: -122.556285,
    latitude: 38.143016,
    id: "arp_not_us",
    icao_code: "KDVO",
    iata_country_code: "US",
    iata_code: "NOT",
    city_name: "Novato",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Marinda Airport",
    longitude: 130.772656,
    latitude: -0.430935,
    id: "arp_rjm_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "RJM",
    city_name: "Waisai",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Marinduque Airport",
    longitude: 121.826805,
    latitude: 13.359629,
    id: "arp_mrq_ph",
    icao_code: "RPUW",
    iata_country_code: "PH",
    iata_code: "MRQ",
    city_name: "Gasan",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Maringa Regional Airport",
    longitude: -52.012142,
    latitude: -23.479723,
    id: "arp_mgf_br",
    icao_code: "SBMG",
    iata_country_code: "BR",
    iata_code: "MGF",
    city_name: "Maringá",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Marion County Airport",
    longitude: -82.370084,
    latitude: 29.062925,
    id: "arp_apt_us",
    icao_code: "KAPT",
    iata_country_code: "US",
    iata_code: "APT",
    city_name: "Jasper",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marion County – Rankin Fite Airport",
    longitude: -87.998127,
    latitude: 34.117966,
    id: "arp_hab_us",
    icao_code: "KHAB",
    iata_country_code: "US",
    iata_code: "HAB",
    city_name: "Hamilton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marion County Regional Airport",
    longitude: -92.590039,
    latitude: 36.291067,
    id: "arp_flp_us",
    icao_code: "KFLP",
    iata_country_code: "US",
    iata_code: "FLP",
    city_name: "Flippin",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Marion Downs Airport",
    longitude: 139.650263,
    latitude: -23.366004,
    id: "arp_mxd_au",
    icao_code: "YMWX",
    iata_country_code: "AU",
    iata_code: "MXD",
    city_name: "Marion Downs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Marion Municipal Airport",
    longitude: -83.065361,
    latitude: 40.6165,
    id: "arp_mnn_us",
    icao_code: "KMNN",
    iata_country_code: "US",
    iata_code: "MNN",
    city_name: "Marion",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Marion Municipal Airport",
    longitude: -85.677582,
    latitude: 40.490558,
    id: "arp_mzz_us",
    icao_code: "KMZZ",
    iata_country_code: "US",
    iata_code: "MZZ",
    city_name: "Marion",
    city: null,
  },
  {
    time_zone: "America/Cayenne",
    name: "Maripasoula Airport",
    longitude: -54.037868,
    latitude: 3.656721,
    id: "arp_mpy_gf",
    icao_code: "SOOA",
    iata_country_code: "GF",
    iata_code: "MPY",
    city_name: "Maripasoula",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mariposa-Yosemite Airport",
    longitude: -120.038274,
    latitude: 37.51032,
    id: "arp_rmy_us",
    icao_code: "KMPI",
    iata_country_code: "US",
    iata_code: "RMY",
    city_name: "Mariposa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Mariquita Airport",
    longitude: -74.884254,
    latitude: 5.210637,
    id: "arp_mqu_co",
    icao_code: "SKQU",
    iata_country_code: "CO",
    iata_code: "MQU",
    city_name: "Mariquita",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Mariscal Lamar International Airport",
    longitude: -78.984421,
    latitude: -2.889457,
    id: "arp_cue_ec",
    icao_code: "SECU",
    iata_country_code: "EC",
    iata_code: "CUE",
    city_name: "Cuenca",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Mariscal Sucre Airport",
    longitude: -67.650078,
    latitude: 10.249893,
    id: "arp_myc_ve",
    icao_code: "SVBS",
    iata_country_code: "VE",
    iata_code: "MYC",
    city_name: "Maracay",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Mariscal Sucre International Airport",
    longitude: -78.358797,
    latitude: -0.127557,
    id: "arp_uio_ec",
    icao_code: "SEQM",
    iata_country_code: "EC",
    iata_code: "UIO",
    city_name: "Quito",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Mariupol International Airport",
    longitude: 37.4496,
    latitude: 47.076099,
    id: "arp_mpw_ua",
    icao_code: "UKCM",
    iata_country_code: "UA",
    iata_code: "MPW",
    city_name: "Mariupol",
    city: null,
  },
  {
    time_zone: "Asia/Anadyr",
    name: "Markovo Airport",
    longitude: 170.417007,
    latitude: 64.667,
    id: "arp_kvm_ru",
    icao_code: "UHMO",
    iata_country_code: "RU",
    iata_code: "KVM",
    city_name: "Markovo",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Marla Airport",
    longitude: 133.627315,
    latitude: -27.333692,
    id: "arp_mrp_au",
    icao_code: "YALA",
    iata_country_code: "AU",
    iata_code: "MRP",
    city_name: "Marla",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Marlboro County Jetport",
    longitude: -79.734038,
    latitude: 34.621387,
    id: "arp_btn_us",
    icao_code: "KBBP",
    iata_country_code: "US",
    iata_code: "BTN",
    city_name: "Bennettsville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Marlborough Airport",
    longitude: -71.51033,
    latitude: 42.343521,
    id: "arp_mxg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MXG",
    city_name: "Marlborough",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Marmul Airport",
    longitude: 55.182054,
    latitude: 18.137522,
    id: "arp_omm_om",
    icao_code: "OOMX",
    iata_country_code: "OM",
    iata_code: "OMM",
    city_name: "Marmul",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Maroantsetra Airport",
    longitude: 49.688842,
    latitude: -15.437078,
    id: "arp_wmn_mg",
    icao_code: "FMNR",
    iata_country_code: "MG",
    iata_code: "WMN",
    city_name: "Maroantsetra",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Maron Island Airport",
    longitude: 145.033,
    latitude: -1.55,
    id: "arp_mnp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MNP",
    city_name: "Maron Island",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Marqua Airport",
    longitude: 137.251162,
    latitude: -22.805907,
    id: "arp_mqe_au",
    icao_code: "YMQA",
    iata_country_code: "AU",
    iata_code: "MQE",
    city_name: "Marqua",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Marrakesh Menara Airport",
    longitude: -8.036552,
    latitude: 31.606942,
    id: "arp_rak_ma",
    icao_code: "GMMX",
    iata_country_code: "MA",
    iata_code: "RAK",
    city_name: "Marrakech",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Marree Airport",
    longitude: 138.065935,
    latitude: -29.662288,
    id: "arp_rre_au",
    icao_code: "YMRE",
    iata_country_code: "AU",
    iata_code: "RRE",
    city_name: "Marree",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Marromeu Airport",
    longitude: 35.944583,
    latitude: -18.318547,
    id: "arp_rrm_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "RRM",
    city_name: "Marromeu",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Marsa Alam International Airport",
    longitude: 34.585256,
    latitude: 25.55685,
    id: "arp_rmf_eg",
    icao_code: "HEMA",
    iata_country_code: "EG",
    iata_code: "RMF",
    city_name: "Marsa Alam",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Marsabit Airport",
    longitude: 37.999717,
    latitude: 2.34438,
    id: "arp_rbt_ke",
    icao_code: "HKMB",
    iata_country_code: "KE",
    iata_code: "RBT",
    city_name: "Marsabit",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Marsa Brega Airport",
    longitude: 19.576423,
    latitude: 30.378119,
    id: "arp_lmq_ly",
    icao_code: "HLMB",
    iata_country_code: "LY",
    iata_code: "LMQ",
    city_name: "Marsa Brega",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Marseille Provence Airport",
    longitude: 5.217919,
    latitude: 43.438847,
    id: "arp_mrs_fr",
    icao_code: "LFML",
    iata_country_code: "FR",
    iata_code: "MRS",
    city_name: "Marseille",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marshall Army Airfield",
    longitude: -96.764376,
    latitude: 39.054632,
    id: "arp_fri_us",
    icao_code: "KFRI",
    iata_country_code: "US",
    iata_code: "FRI",
    city_name: "Fort Riley",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Marshall Don Hunter Sr. Airport",
    longitude: -162.029861,
    latitude: 61.865308,
    id: "arp_mll_us",
    icao_code: "PADM",
    iata_country_code: "US",
    iata_code: "MLL",
    city_name: "Marshall",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Marshall Islands International Airport",
    longitude: 171.272247,
    latitude: 7.064903,
    id: "arp_maj_mh",
    icao_code: "PKMJ",
    iata_country_code: "MH",
    iata_code: "MAJ",
    city_name: "Majuro",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marshall Memorial Municipal Airport",
    longitude: -93.200133,
    latitude: 39.097306,
    id: "arp_mhl_us",
    icao_code: "KMHL",
    iata_country_code: "US",
    iata_code: "MHL",
    city_name: "Marshall",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marshalltown Municipal Airport",
    longitude: -92.917778,
    latitude: 42.112778,
    id: "arp_miw_us",
    icao_code: "KMIW",
    iata_country_code: "US",
    iata_code: "MIW",
    city_name: "Marshalltown",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Marshfield Municipal Airport",
    longitude: -90.188981,
    latitude: 44.638024,
    id: "arp_mfi_us",
    icao_code: "KMFI",
    iata_country_code: "US",
    iata_code: "MFI",
    city_name: "Marshfield",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Martha's Vineyard Airport",
    longitude: -70.613609,
    latitude: 41.390953,
    id: "arp_mvy_us",
    icao_code: "KMVY",
    iata_country_code: "US",
    iata_code: "MVY",
    city_name: "Martha's Vineyard",
    city: null,
  },
  {
    time_zone: "America/Martinique",
    name: "Martinique Aimé Césaire International Airport",
    longitude: -61.002863,
    latitude: 14.592363,
    id: "arp_fdf_mq",
    icao_code: "TFFF",
    iata_country_code: "MQ",
    iata_code: "FDF",
    city_name: "Fort-de-France",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Martín Miguel de Güemes International Airport",
    longitude: -65.482333,
    latitude: -24.852299,
    id: "arp_sla_ar",
    icao_code: "SASA",
    iata_country_code: "AR",
    iata_code: "SLA",
    city_name: "Salta",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Martin State Airport",
    longitude: -76.41708,
    latitude: 39.326578,
    id: "arp_mtn_us",
    icao_code: "KMTN",
    iata_country_code: "US",
    iata_code: "MTN",
    city_name: "Baltimore",
    city: {
      name: "Baltimore",
      id: "cit_bwi_us",
      iata_country_code: "US",
      iata_code: "BWI",
    },
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Martubah Airport",
    longitude: 22.744838,
    latitude: 32.541665,
    id: "arp_dnf_ly",
    icao_code: null,
    iata_country_code: "LY",
    iata_code: "DNF",
    city_name: "Derna",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Marudi Airport",
    longitude: 114.327785,
    latitude: 4.178994,
    id: "arp_mur_my",
    icao_code: "WBGM",
    iata_country_code: "MY",
    iata_code: "MUR",
    city_name: "Marudi",
    city: null,
  },
  {
    time_zone: "Asia/Ashgabat",
    name: "Mary Airport",
    longitude: 61.899168,
    latitude: 37.607228,
    id: "arp_myp_tm",
    icao_code: "UTAM",
    iata_country_code: "TM",
    iata_code: "MYP",
    city_name: "Mary",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Maryborough Airport",
    longitude: 152.714348,
    latitude: -25.51527,
    id: "arp_mbh_au",
    icao_code: "YMYB",
    iata_country_code: "AU",
    iata_code: "MBH",
    city_name: "Maryborough",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Mary River Aerodrome",
    longitude: -79.372086,
    latitude: 71.327139,
    id: "arp_ymv_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YMV",
    city_name: "Mary River",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "Mary's Harbour Airport",
    longitude: -55.84721,
    latitude: 52.302788,
    id: "arp_ymh_ca",
    icao_code: "CYMH",
    iata_country_code: "CA",
    iata_code: "YMH",
    city_name: "Mary's Harbour",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Masa Airport",
    longitude: 147.590989,
    latitude: -6.345107,
    id: "arp_mbv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MBV",
    city_name: "Masa",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Masalembo Airport",
    longitude: 114.431605,
    latitude: -5.584537,
    id: "arp_msi_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "MSI",
    city_name: "Masalembo",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Masasi Airport",
    longitude: 38.766987,
    latitude: -10.733327,
    id: "arp_xmi_tz",
    icao_code: "HTMI",
    iata_country_code: "TZ",
    iata_code: "XMI",
    city_name: "Masasi",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Mashhad International Airport",
    longitude: 59.641261,
    latitude: 36.231382,
    id: "arp_mhd_ir",
    icao_code: "OIMM",
    iata_country_code: "IR",
    iata_code: "MHD",
    city_name: "Mashhad",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Masi-Manimba Airport",
    longitude: 17.854238,
    latitude: -4.782711,
    id: "arp_msm_cd",
    icao_code: "FZCV",
    iata_country_code: "CD",
    iata_code: "MSM",
    city_name: "Masi-Manimba",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Masindi Airport",
    longitude: 31.736817,
    latitude: 1.754124,
    id: "arp_kcu_ug",
    icao_code: "HUMI",
    iata_country_code: "UG",
    iata_code: "KCU",
    city_name: "Masindi",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Masirah Air Base",
    longitude: 58.890888,
    latitude: 20.677118,
    id: "arp_msh_om",
    icao_code: "OOMA",
    iata_country_code: "OM",
    iata_code: "MSH",
    city_name: "Masirah",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mason City Municipal Airport",
    longitude: -93.332861,
    latitude: 43.155965,
    id: "arp_mcw_us",
    icao_code: "KMCW",
    iata_country_code: "US",
    iata_code: "MCW",
    city_name: "Mason City",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Mason County Airport",
    longitude: -86.408342,
    latitude: 43.960816,
    id: "arp_ldm_us",
    icao_code: "KLDM",
    iata_country_code: "US",
    iata_code: "LDM",
    city_name: "Ludington",
    city: null,
  },
  {
    time_zone: "Africa/Asmera",
    name: "Massawa International Airport",
    longitude: 39.369599,
    latitude: 15.670343,
    id: "arp_msw_er",
    icao_code: "HHMS",
    iata_country_code: "ER",
    iata_code: "MSW",
    city_name: "Massawa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Massena International Airport",
    longitude: -74.845245,
    latitude: 44.935874,
    id: "arp_mss_us",
    icao_code: "KMSS",
    iata_country_code: "US",
    iata_code: "MSS",
    city_name: "Massena",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Masset Airport",
    longitude: -132.120916,
    latitude: 54.024677,
    id: "arp_zmt_ca",
    icao_code: "CZMT",
    iata_country_code: "CA",
    iata_code: "ZMT",
    city_name: "Masset",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Masterton Airport",
    longitude: 175.632132,
    latitude: -40.974935,
    id: "arp_mro_nz",
    icao_code: "NZMS",
    iata_country_code: "NZ",
    iata_code: "MRO",
    city_name: "Masterton",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Masvingo Airport",
    longitude: 30.859837,
    latitude: -20.058296,
    id: "arp_mvz_zw",
    icao_code: "FVMV",
    iata_country_code: "ZW",
    iata_code: "MVZ",
    city_name: "Masvingo",
    city: null,
  },
  {
    time_zone: "Pacific/Tongatapu",
    name: "Mata'aho Airport",
    longitude: -175.631667,
    latitude: -15.57115,
    id: "arp_nfo_to",
    icao_code: "NFTO",
    iata_country_code: "TO",
    iata_code: "NFO",
    city_name: "Niuafo'ou",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Matagami Airport",
    longitude: -77.804194,
    latitude: 49.763765,
    id: "arp_ynm_ca",
    icao_code: "CYNM",
    iata_country_code: "CA",
    iata_code: "YNM",
    city_name: "Matagami",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Matagorda Island Air Force Base Airport",
    longitude: -96.464525,
    latitude: 28.326723,
    id: "arp_mgi_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MGI",
    city_name: "Matagorda Island",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Matahora Airport",
    longitude: 123.635739,
    latitude: -5.289794,
    id: "arp_wni_id",
    icao_code: "WAWD",
    iata_country_code: "ID",
    iata_code: "WNI",
    city_name: "Wangi Wangi",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Mataiva Airport",
    longitude: -148.715355,
    latitude: -14.870177,
    id: "arp_mvt_pf",
    icao_code: "NTGV",
    iata_country_code: "PF",
    iata_code: "MVT",
    city_name: "Mataiva",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Matamata Airport",
    longitude: 175.739662,
    latitude: -37.735869,
    id: "arp_mta_nz",
    icao_code: "NZMA",
    iata_country_code: "NZ",
    iata_code: "MTA",
    city_name: "Matamata",
    city: null,
  },
  {
    time_zone: "America/Matamoros",
    name: "Matamoros International Airport",
    longitude: -97.527208,
    latitude: 25.769991,
    id: "arp_mam_mx",
    icao_code: "MMMA",
    iata_country_code: "MX",
    iata_code: "MAM",
    city_name: "Matamoros",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Matane Airport",
    longitude: -67.45577,
    latitude: 48.855357,
    id: "arp_yme_ca",
    icao_code: "CYME",
    iata_country_code: "CA",
    iata_code: "YME",
    city_name: "Matane",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Matari Airport",
    longitude: 27.5883,
    latitude: 2.82761,
    id: "arp_irp_cd",
    icao_code: "FZJH",
    iata_country_code: "CD",
    iata_code: "IRP",
    city_name: "Isiro",
    city: null,
  },
  {
    time_zone: "Pacific/Easter",
    name: "Mataveri International Airport",
    longitude: -109.425152,
    latitude: -27.162505,
    id: "arp_ipc_cl",
    icao_code: "SCIP",
    iata_country_code: "CL",
    iata_code: "IPC",
    city_name: "Easter Island",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Matecaña International Airport",
    longitude: -75.737887,
    latitude: 4.815015,
    id: "arp_pei_co",
    icao_code: "SKPE",
    iata_country_code: "CO",
    iata_code: "PEI",
    city_name: "Pereira",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Matei Airport",
    longitude: -179.877088,
    latitude: -16.690643,
    id: "arp_tvu_fj",
    icao_code: "NFNM",
    iata_country_code: "FJ",
    iata_code: "TVU",
    city_name: "Matei",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Mati Airport",
    longitude: 126.273085,
    latitude: 6.949668,
    id: "arp_mxi_ph",
    icao_code: "RPMQ",
    iata_country_code: "PH",
    iata_code: "MXI",
    city_name: "Mati",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Mato Grosso Airport",
    longitude: -59.9458,
    latitude: -14.9942,
    id: "arp_mtg_br",
    icao_code: "SWVB",
    iata_country_code: "BR",
    iata_code: "MTG",
    city_name: "Mato Grosso",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Matsaile Airport",
    longitude: 28.776235,
    latitude: -29.841039,
    id: "arp_msg_ls",
    icao_code: "FXMA",
    iata_country_code: "LS",
    iata_code: "MSG",
    city_name: "Matsaile",
    city: null,
  },
  {
    time_zone: "Africa/Mbabane",
    name: "Matsapha Airport",
    longitude: 31.314572,
    latitude: -26.523799,
    id: "arp_mts_sz",
    icao_code: "FDMS",
    iata_country_code: "SZ",
    iata_code: "MTS",
    city_name: "Manzini",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Matsu Beigan Airport",
    longitude: 120.002011,
    latitude: 26.22428,
    id: "arp_mfk_tw",
    icao_code: "RCMT",
    iata_country_code: "TW",
    iata_code: "MFK",
    city_name: "Matsu",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Matsumoto Airport",
    longitude: 137.92406,
    latitude: 36.16584,
    id: "arp_mmj_jp",
    icao_code: "RJAF",
    iata_country_code: "JP",
    iata_code: "MMJ",
    city_name: "Matsumoto",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Matsu Nangan Airport",
    longitude: 119.957197,
    latitude: 26.15859,
    id: "arp_lzn_tw",
    icao_code: "RCFG",
    iata_country_code: "TW",
    iata_code: "LZN",
    city_name: "Nangan Island",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Matsuyama Airport",
    longitude: 132.701316,
    latitude: 33.826419,
    id: "arp_myj_jp",
    icao_code: "RJOM",
    iata_country_code: "JP",
    iata_code: "MYJ",
    city_name: "Matsuyama",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Mattala Rajapaksa International Airport",
    longitude: 81.123266,
    latitude: 6.288454,
    id: "arp_hri_lk",
    icao_code: "VCRI",
    iata_country_code: "LK",
    iata_code: "HRI",
    city_name: "Mattala",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Matthew Spain Airport",
    longitude: -89.009851,
    latitude: 17.185833,
    id: "arp_sqs_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "SQS",
    city_name: "San Ignacio",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Matthews Ridge Airport",
    longitude: -60.18246,
    latitude: 7.488426,
    id: "arp_mwj_gy",
    icao_code: "SYMR",
    iata_country_code: "GY",
    iata_code: "MWJ",
    city_name: "Matthews Ridge",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Matupá Airport",
    longitude: -54.955542,
    latitude: -10.177891,
    id: "arp_mbk_br",
    icao_code: "SWXM",
    iata_country_code: "BR",
    iata_code: "MBK",
    city_name: "Matupá",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Maturin International Airport",
    longitude: -63.155278,
    latitude: 9.748969,
    id: "arp_mun_ve",
    icao_code: "SVMT",
    iata_country_code: "VE",
    iata_code: "MUN",
    city_name: "Maturín",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Maués Airport",
    longitude: -57.724444,
    latitude: -3.372911,
    id: "arp_mbz_br",
    icao_code: "SWMW",
    iata_country_code: "BR",
    iata_code: "MBZ",
    city_name: "Maués",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Mauke Airport",
    longitude: -157.344876,
    latitude: -20.136337,
    id: "arp_muk_ck",
    icao_code: "NCMK",
    iata_country_code: "CK",
    iata_code: "MUK",
    city_name: "Mauke Island",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Maun Airport",
    longitude: 23.42964,
    latitude: -19.97409,
    id: "arp_mub_bw",
    icao_code: "FBMN",
    iata_country_code: "BW",
    iata_code: "MUB",
    city_name: "Maun",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Maupiti Airport",
    longitude: -152.243469,
    latitude: -16.426482,
    id: "arp_mau_pf",
    icao_code: "NTTP",
    iata_country_code: "PF",
    iata_code: "MAU",
    city_name: "Maupiti",
    city: null,
  },
  {
    time_zone: "America/Grenada",
    name: "Maurice Bishop International Airport",
    longitude: -61.786139,
    latitude: 12.004919,
    id: "arp_gnd_gd",
    icao_code: "TGPY",
    iata_country_code: "GD",
    iata_code: "GND",
    city_name: "Grenada",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Maury County Airport",
    longitude: -87.181234,
    latitude: 35.553096,
    id: "arp_mrc_us",
    icao_code: "KMRC",
    iata_country_code: "US",
    iata_code: "MRC",
    city_name: "Columbia/Mount Pleasant",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Maverick County Memorial International Airport",
    longitude: -100.512735,
    latitude: 28.857147,
    id: "arp_egp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EGP",
    city_name: "Eagle Pass",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Mawella Lagoon Airport",
    longitude: 80.73318,
    latitude: 5.990472,
    id: "arp_diw_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "DIW",
    city_name: "Dickwella",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Mawlamyine Airport",
    longitude: 97.659678,
    latitude: 16.443488,
    id: "arp_mnu_mm",
    icao_code: "VYMM",
    iata_country_code: "MM",
    iata_code: "MNU",
    city_name: "Mawlamyine",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Maximo Gomez Airport",
    longitude: -78.789596,
    latitude: 22.027099,
    id: "arp_avi_cu",
    icao_code: "MUCA",
    iata_country_code: "CU",
    iata_code: "AVI",
    city_name: "Ciego de Avila",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Maxson Airfield",
    longitude: -75.898271,
    latitude: 44.314468,
    id: "arp_axb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "AXB",
    city_name: "Alexandria Bay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Maxwell Air Force Base",
    longitude: -86.364716,
    latitude: 32.381652,
    id: "arp_mxf_us",
    icao_code: "KMXF",
    iata_country_code: "US",
    iata_code: "MXF",
    city_name: "Montgomery",
    city: {
      name: "Montgomery",
      id: "cit_mgm_us",
      iata_country_code: "US",
      iata_code: "MGM",
    },
  },
  {
    time_zone: "America/Nassau",
    name: "Mayaguana Airport",
    longitude: -73.01304,
    latitude: 22.379628,
    id: "arp_myg_bs",
    icao_code: "MYMM",
    iata_country_code: "BS",
    iata_code: "MYG",
    city_name: "Mayaguana",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Maya-Maya Airport",
    longitude: 15.253663,
    latitude: -4.253237,
    id: "arp_bzv_cg",
    icao_code: "FCBB",
    iata_country_code: "CG",
    iata_code: "BZV",
    city_name: "Brazzaville",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "May Creek Airport",
    longitude: -142.686362,
    latitude: 61.336056,
    id: "arp_myk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MYK",
    city_name: "May Creek",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Mayfa'ah Airport",
    longitude: 46.008333,
    latitude: 13.666667,
    id: "arp_mfy_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "MFY",
    city_name: "Mayfa'ah",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Maygatka Airport",
    longitude: 140.033997,
    latitude: 48.926998,
    id: "arp_gvn_ru",
    icao_code: "UHKM",
    iata_country_code: "RU",
    iata_code: "GVN",
    city_name: "Sovetskaya Gavan",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Mayo Airport",
    longitude: -135.870686,
    latitude: 63.616711,
    id: "arp_yma_ca",
    icao_code: "CYMA",
    iata_country_code: "CA",
    iata_code: "YMA",
    city_name: "Mayo",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Mayor Buenaventura Vivas Airport",
    longitude: -72.043993,
    latitude: 7.569744,
    id: "arp_std_ve",
    icao_code: "SVSO",
    iata_country_code: "VE",
    iata_code: "STD",
    city_name: "Santo Domingo",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Mayor General FAP Armando Revoredo Iglesias Airport",
    longitude: -78.489607,
    latitude: -7.139612,
    id: "arp_cja_pe",
    icao_code: "SPJR",
    iata_country_code: "PE",
    iata_code: "CJA",
    city_name: "Cajamarca",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "May River Airport",
    longitude: -1.21667,
    latitude: 61.3833,
    id: "arp_mrh_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MRH",
    city_name: "May River",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Mayumba Airport",
    longitude: 10.674005,
    latitude: -3.458258,
    id: "arp_myb_ga",
    icao_code: "FOOY",
    iata_country_code: "GA",
    iata_code: "MYB",
    city_name: "Mayumba",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Mazar-e Sharif International Airport",
    longitude: 67.209096,
    latitude: 36.708046,
    id: "arp_mzr_af",
    icao_code: "OAMS",
    iata_country_code: "AF",
    iata_code: "MZR",
    city_name: "Mazar-e Sharif",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Mazatlán International Airport",
    longitude: -106.267465,
    latitude: 23.163662,
    id: "arp_mzt_mx",
    icao_code: "MMMZ",
    iata_country_code: "MX",
    iata_code: "MZT",
    city_name: "Mazatlán",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Mbala Airport",
    longitude: 31.336075,
    latitude: -8.858164,
    id: "arp_mmq_zm",
    icao_code: "FLBA",
    iata_country_code: "ZM",
    iata_code: "MMQ",
    city_name: "Mbala",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Mbambanakira Airport",
    longitude: 159.839005,
    latitude: -9.7475,
    id: "arp_mbu_sb",
    icao_code: "AGGI",
    iata_country_code: "SB",
    iata_code: "MBU",
    city_name: "Mbambanakira",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Mbandaka Airport",
    longitude: 18.290103,
    latitude: 0.022698,
    id: "arp_mdk_cd",
    icao_code: "FZEA",
    iata_country_code: "CD",
    iata_code: "MDK",
    city_name: "Mbandaka",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Mbanza Congo Airport",
    longitude: 14.246832,
    latitude: -6.269516,
    id: "arp_ssy_ao",
    icao_code: "FNBC",
    iata_country_code: "AO",
    iata_code: "SSY",
    city_name: "M'banza Congo",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Mbarara Airport",
    longitude: 30.599699,
    latitude: -0.555139,
    id: "arp_mbq_ug",
    icao_code: "HUMA",
    iata_country_code: "UG",
    iata_code: "MBQ",
    city_name: "Mbarara",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Mbeya Airport",
    longitude: 33.273981,
    latitude: -8.919942,
    id: "arp_mbi_tz",
    icao_code: null,
    iata_country_code: "TZ",
    iata_code: "MBI",
    city_name: "Mbeya",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Mbigou Airport",
    longitude: 11.8833,
    latitude: -1.9,
    id: "arp_mbc_ga",
    icao_code: "FOGG",
    iata_country_code: "GA",
    iata_code: "MBC",
    city_name: "Mbigou",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "M'Boki Airport",
    longitude: 25.930665,
    latitude: 5.333393,
    id: "arp_mki_cf",
    icao_code: "FEGE",
    iata_country_code: "CF",
    iata_code: "MKI",
    city_name: "Obo",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Mbout Airport",
    longitude: -12.601336,
    latitude: 16.031149,
    id: "arp_mbr_mr",
    icao_code: "GQNU",
    iata_country_code: "MR",
    iata_code: "MBR",
    city_name: "Mbout",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "MBS International Airport",
    longitude: -84.081593,
    latitude: 43.534332,
    id: "arp_mbs_us",
    icao_code: "KMBS",
    iata_country_code: "US",
    iata_code: "MBS",
    city_name: "Saginaw",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Mbuji Mayi Airport",
    longitude: 23.570201,
    latitude: -6.123084,
    id: "arp_mjm_cd",
    icao_code: "FZWA",
    iata_country_code: "CD",
    iata_code: "MJM",
    city_name: "Mbuji-Mayi",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "McAlester Regional Airport",
    longitude: -95.783423,
    latitude: 34.882454,
    id: "arp_mlc_us",
    icao_code: "KMLC",
    iata_country_code: "US",
    iata_code: "MLC",
    city_name: "McAlester",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mc Allen Miller International Airport",
    longitude: -98.239319,
    latitude: 26.176423,
    id: "arp_mfe_us",
    icao_code: "KMFE",
    iata_country_code: "US",
    iata_code: "MFE",
    city_name: "McAllen",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "McArthur River Mine Airport",
    longitude: 136.081454,
    latitude: -16.442713,
    id: "arp_mcv_au",
    icao_code: "YMHU",
    iata_country_code: "AU",
    iata_code: "MCV",
    city_name: "McArthur River Mine",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "McCall Municipal Airport",
    longitude: -116.101248,
    latitude: 44.889375,
    id: "arp_myl_us",
    icao_code: "KMYL",
    iata_country_code: "US",
    iata_code: "MYL",
    city_name: "McCall",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "McCarran International Airport",
    longitude: -115.152869,
    latitude: 36.08205,
    id: "arp_las_us",
    icao_code: "KLAS",
    iata_country_code: "US",
    iata_code: "LAS",
    city_name: "Las Vegas",
    city: {
      name: "Las Vegas",
      id: "cit_las_us",
      iata_country_code: "US",
      iata_code: "LAS",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "McCarthy Airport",
    longitude: -142.903348,
    latitude: 61.437401,
    id: "arp_mxy_us",
    icao_code: "PAMX",
    iata_country_code: "US",
    iata_code: "MXY",
    city_name: "McCarthy",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "McChord Field Airport",
    longitude: -122.479243,
    latitude: 47.136547,
    id: "arp_tcm_us",
    icao_code: "KTCM",
    iata_country_code: "US",
    iata_code: "TCM",
    city_name: "Tacoma",
    city: {
      name: "Tacoma",
      id: "cit_tiw_us",
      iata_country_code: "US",
      iata_code: "TIW",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mc Clellan Airfield",
    longitude: -121.400004,
    latitude: 38.664554,
    id: "arp_mcc_us",
    icao_code: "KMCC",
    iata_country_code: "US",
    iata_code: "MCC",
    city_name: "Sacramento",
    city: {
      name: "Sacramento",
      id: "cit_sac_us",
      iata_country_code: "US",
      iata_code: "SAC",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "McClellan-Palomar Airport",
    longitude: -117.279519,
    latitude: 33.127501,
    id: "arp_cld_us",
    icao_code: "KCRQ",
    iata_country_code: "US",
    iata_code: "CLD",
    city_name: "Carlsbad",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "McComb–Pike County Airport",
    longitude: -90.471782,
    latitude: 31.178556,
    id: "arp_mcb_us",
    icao_code: "KMCB",
    iata_country_code: "US",
    iata_code: "MCB",
    city_name: "McComb",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "McConnell Air Force Base Airport",
    longitude: -97.265705,
    latitude: 37.62341,
    id: "arp_iab_us",
    icao_code: "KIAB",
    iata_country_code: "US",
    iata_code: "IAB",
    city_name: "Wichita",
    city: {
      name: "Wichita",
      id: "cit_ict_us",
      iata_country_code: "US",
      iata_code: "ICT",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "McCook Ben Nelson Regional Airport",
    longitude: -100.592043,
    latitude: 40.206288,
    id: "arp_mck_us",
    icao_code: "KMCK",
    iata_country_code: "US",
    iata_code: "MCK",
    city_name: "McCook",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "McEntire Joint National Guard Base Airport",
    longitude: -80.801919,
    latitude: 33.9188,
    id: "arp_mmt_us",
    icao_code: "KMMT",
    iata_country_code: "US",
    iata_code: "MMT",
    city_name: "Columbia",
    city: {
      name: "Columbia",
      id: "cit_cae_us",
      iata_country_code: "US",
      iata_code: "CAE",
    },
  },
  {
    time_zone: "America/New_York",
    name: "McGhee Tyson Airport",
    longitude: -83.993946,
    latitude: 35.810916,
    id: "arp_tys_us",
    icao_code: "KTYS",
    iata_country_code: "US",
    iata_code: "TYS",
    city_name: "Knoxville",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "McGrath Airport",
    longitude: -155.605779,
    latitude: 62.952838,
    id: "arp_mcg_us",
    icao_code: "PAMC",
    iata_country_code: "US",
    iata_code: "MCG",
    city_name: "McGrath",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "McGuire Air Force Base Airport",
    longitude: -74.591698,
    latitude: 40.015598,
    id: "arp_wri_us",
    icao_code: "KWRI",
    iata_country_code: "US",
    iata_code: "WRI",
    city_name: "Wrightstown",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "McKellar-Sipes Regional Airport",
    longitude: -88.918561,
    latitude: 35.601171,
    id: "arp_mkl_us",
    icao_code: "KMKL",
    iata_country_code: "US",
    iata_code: "MKL",
    city_name: "Jackson",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "McKinley National Park Airport",
    longitude: -148.911116,
    latitude: 63.732427,
    id: "arp_mcl_us",
    icao_code: "PAIN",
    iata_country_code: "US",
    iata_code: "MCL",
    city_name: "McKinley Park",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "McMinn County Airport",
    longitude: -84.562215,
    latitude: 35.397906,
    id: "arp_mmi_us",
    icao_code: "KMMI",
    iata_country_code: "US",
    iata_code: "MMI",
    city_name: "Athens",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "McNary Field",
    longitude: -123.002667,
    latitude: 44.909354,
    id: "arp_sle_us",
    icao_code: "KSLE",
    iata_country_code: "US",
    iata_code: "SLE",
    city_name: "Salem",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "McPherson Airport",
    longitude: -97.688728,
    latitude: 38.355039,
    id: "arp_mpr_us",
    icao_code: "KMPR",
    iata_country_code: "US",
    iata_code: "MPR",
    city_name: "McPherson",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Meadow Lake Airport",
    longitude: -108.51937,
    latitude: 54.125922,
    id: "arp_ylj_ca",
    icao_code: "CYLJ",
    iata_country_code: "CA",
    iata_code: "YLJ",
    city_name: "Meadow Lake",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Meadows Field",
    longitude: -119.056809,
    latitude: 35.433257,
    id: "arp_bfl_us",
    icao_code: "KBFL",
    iata_country_code: "US",
    iata_code: "BFL",
    city_name: "Bakersfield",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Mecheria Airport",
    longitude: -0.241818,
    latitude: 33.533405,
    id: "arp_mzw_dz",
    icao_code: "DAAY",
    iata_country_code: "DZ",
    iata_code: "MZW",
    city_name: "Mecheria",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Medellin Airport",
    longitude: -75.425609,
    latitude: 6.167808,
    id: "arp_mde_co",
    icao_code: "SKRG",
    iata_country_code: "CO",
    iata_code: "MDE",
    city_name: "Medellín",
    city: {
      name: "Medellin",
      id: "cit_mde_co",
      iata_country_code: "CO",
      iata_code: "MDE",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Medfra Airport",
    longitude: -154.719069,
    latitude: 63.10571,
    id: "arp_mdr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MDR",
    city_name: "Maasai Mara",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Medicine Hat Airport",
    longitude: -110.721493,
    latitude: 50.020439,
    id: "arp_yxh_ca",
    icao_code: "CYXH",
    iata_country_code: "CA",
    iata_code: "YXH",
    city_name: "Medicine Hat",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Medina Airport",
    longitude: -73.2833,
    latitude: 4.51667,
    id: "arp_mnd_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "MND",
    city_name: "Medina",
    city: null,
  },
  {
    time_zone: "Africa/Malabo",
    name: "Médouneu Airport",
    longitude: 10.755116,
    latitude: 1.008071,
    id: "arp_mdv_gq",
    icao_code: null,
    iata_country_code: "GQ",
    iata_code: "MDV",
    city_name: "Medouneu",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Meekatharra Airport",
    longitude: 118.546965,
    latitude: -26.611385,
    id: "arp_mkr_au",
    icao_code: "YMEK",
    iata_country_code: "AU",
    iata_code: "MKR",
    city_name: "Meekatharra",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mefford Field Airport",
    longitude: -119.32703,
    latitude: 36.156253,
    id: "arp_tlr_us",
    icao_code: "KTLR",
    iata_country_code: "US",
    iata_code: "TLR",
    city_name: "Tulare",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Megève Airport",
    longitude: 6.651666,
    latitude: 45.820354,
    id: "arp_mvv_fr",
    icao_code: "LFHM",
    iata_country_code: "FR",
    iata_code: "MVV",
    city_name: "Megeve",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Meghauli Airport",
    longitude: 84.22859,
    latitude: 27.577105,
    id: "arp_mey_np",
    icao_code: "VNMG",
    iata_country_code: "NP",
    iata_code: "MEY",
    city_name: "Meghauli",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Mehamn Airport",
    longitude: 27.826476,
    latitude: 71.029331,
    id: "arp_meh_no",
    icao_code: "ENMH",
    iata_country_code: "NO",
    iata_code: "MEH",
    city_name: "Mehamn",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Mehrabad International Airport",
    longitude: 51.31232,
    latitude: 35.689594,
    id: "arp_thr_ir",
    icao_code: "OIII",
    iata_country_code: "IR",
    iata_code: "THR",
    city_name: "Tehran",
    city: {
      name: "Tehran",
      id: "cit_thr_ir",
      iata_country_code: "IR",
      iata_code: "THR",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Meixian Airport",
    longitude: 116.103441,
    latitude: 24.266392,
    id: "arp_mxz_cn",
    icao_code: "ZGMX",
    iata_country_code: "CN",
    iata_code: "MXZ",
    city_name: "Meizhou",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Mejit Atoll Airport",
    longitude: 170.869,
    latitude: 10.283318,
    id: "arp_mjb_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "MJB",
    city_name: "Mejit Island",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Mekambo Airport",
    longitude: 13.968249,
    latitude: 0.977188,
    id: "arp_mkb_ga",
    icao_code: "FOOE",
    iata_country_code: "GA",
    iata_code: "MKB",
    city_name: "Mekambo",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Mekane Selam Airport",
    longitude: 38.7415,
    latitude: 10.7254,
    id: "arp_mks_et",
    icao_code: "HAMA",
    iata_country_code: "ET",
    iata_code: "MKS",
    city_name: "Mekane Selam",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Mekoryuk Airport",
    longitude: -166.270866,
    latitude: 60.371405,
    id: "arp_myu_us",
    icao_code: "PAMY",
    iata_country_code: "US",
    iata_code: "MYU",
    city_name: "Mekoryuk",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Melangguane Airport",
    longitude: 126.673471,
    latitude: 4.006586,
    id: "arp_mna_id",
    icao_code: "WAMN",
    iata_country_code: "ID",
    iata_code: "MNA",
    city_name: "Melonguane",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Melbourne Airport",
    longitude: 144.842014,
    latitude: -37.671157,
    id: "arp_mel_au",
    icao_code: "YMML",
    iata_country_code: "AU",
    iata_code: "MEL",
    city_name: "Melbourne",
    city: {
      name: "Melbourne",
      id: "cit_mel_au",
      iata_country_code: "AU",
      iata_code: "MEL",
    },
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Melbourne Essendon Airport",
    longitude: 144.901825,
    latitude: -37.727537,
    id: "arp_meb_au",
    icao_code: "YMEN",
    iata_country_code: "AU",
    iata_code: "MEB",
    city_name: "Melbourne",
    city: {
      name: "Melbourne",
      id: "cit_mel_au",
      iata_country_code: "AU",
      iata_code: "MEL",
    },
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Melfi Airport",
    longitude: 18.381156,
    latitude: 9.150042,
    id: "arp_mef_td",
    icao_code: null,
    iata_country_code: "TD",
    iata_code: "MEF",
    city_name: "Melfi",
    city: null,
  },
  {
    time_zone: "Africa/Ceuta",
    name: "Melilla Airport",
    longitude: -2.956421,
    latitude: 35.279519,
    id: "arp_mln_es",
    icao_code: "GEML",
    iata_country_code: "ES",
    iata_code: "MLN",
    city_name: "Melilla",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Melinda Airport",
    longitude: -88.1952,
    latitude: 17.516225,
    id: "arp_mdb_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "MDB",
    city_name: "Melinda",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Memanbetsu Airport",
    longitude: 144.162281,
    latitude: 43.880877,
    id: "arp_mmb_jp",
    icao_code: "RJCM",
    iata_country_code: "JP",
    iata_code: "MMB",
    city_name: "Memambetsu",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Memmingen Airport",
    longitude: 10.235775,
    latitude: 47.988369,
    id: "arp_fmm_de",
    icao_code: "EDJA",
    iata_country_code: "DE",
    iata_code: "FMM",
    city_name: "Memmingen",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Memorial Field Airport",
    longitude: -93.096028,
    latitude: 34.479409,
    id: "arp_hot_us",
    icao_code: "KHOT",
    iata_country_code: "US",
    iata_code: "HOT",
    city_name: "Hot Springs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Memphis International Airport",
    longitude: -89.978523,
    latitude: 35.042894,
    id: "arp_mem_us",
    icao_code: "KMEM",
    iata_country_code: "US",
    iata_code: "MEM",
    city_name: "Memphis",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Mena Airport",
    longitude: 39.7167,
    latitude: 6.35,
    id: "arp_mzx_et",
    icao_code: null,
    iata_country_code: "ET",
    iata_code: "MZX",
    city_name: "Mena",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mena Intermountain Municipal Airport",
    longitude: -94.208204,
    latitude: 34.548054,
    id: "arp_umz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UMZ",
    city_name: "Mena",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Mende Brenoux Airport",
    longitude: 3.533035,
    latitude: 44.501445,
    id: "arp_men_fr",
    icao_code: "LFNB",
    iata_country_code: "FR",
    iata_code: "MEN",
    city_name: "Mende",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Méndez Airport",
    longitude: -78.316135,
    latitude: -2.734305,
    id: "arp_mzd_ec",
    icao_code: null,
    iata_country_code: "EC",
    iata_code: "MZD",
    city_name: "Santiago de Méndez",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mendi Airport",
    longitude: 143.657236,
    latitude: -6.147142,
    id: "arp_mdu_pg",
    icao_code: "AYMN",
    iata_country_code: "PG",
    iata_code: "MDU",
    city_name: "Mendi",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Mendi Airport",
    longitude: 35.098531,
    latitude: 9.767397,
    id: "arp_ndm_et",
    icao_code: "HAMN",
    iata_country_code: "ET",
    iata_code: "NDM",
    city_name: "Mendi",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Menominee–Marinette Twin County Airport",
    longitude: -87.636318,
    latitude: 45.126404,
    id: "arp_mnm_us",
    icao_code: "KMNM",
    iata_country_code: "US",
    iata_code: "MNM",
    city_name: "Menominee",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Menongue Airport",
    longitude: 17.718853,
    latitude: -14.658765,
    id: "arp_spp_ao",
    icao_code: "FNME",
    iata_country_code: "AO",
    iata_code: "SPP",
    city_name: "Menongue",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Menorca Airport",
    longitude: 4.221258,
    latitude: 39.864011,
    id: "arp_mah_es",
    icao_code: "LEMH",
    iata_country_code: "ES",
    iata_code: "MAH",
    city_name: "Menorca",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Menyamya Airport",
    longitude: 146.017,
    latitude: -7.2,
    id: "arp_myx_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MYX",
    city_name: "Menyamya",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Mercedes Airport",
    longitude: -58.087886,
    latitude: -29.22103,
    id: "arp_mdx_ar",
    icao_code: "SATM",
    iata_country_code: "AR",
    iata_code: "MDX",
    city_name: "Mercedes",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Mercedita Airport",
    longitude: -66.563184,
    latitude: 18.009142,
    id: "arp_pse_pr",
    icao_code: "TJPS",
    iata_country_code: "PR",
    iata_code: "PSE",
    city_name: "Ponce",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Merced Regional Airport",
    longitude: -120.514229,
    latitude: 37.285378,
    id: "arp_mce_us",
    icao_code: "KMCE",
    iata_country_code: "US",
    iata_code: "MCE",
    city_name: "Merced",
    city: {
      name: "Merced",
      id: "cit_mce_us",
      iata_country_code: "US",
      iata_code: "MCE",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Mercer County Airport",
    longitude: -81.207692,
    latitude: 37.295032,
    id: "arp_blf_us",
    icao_code: "KBLF",
    iata_country_code: "US",
    iata_code: "BLF",
    city_name: "Bluefield",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Merdey Airport",
    longitude: 133.296809,
    latitude: -1.59052,
    id: "arp_rde_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "RDE",
    city_name: "Merdey",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Meribel Airport",
    longitude: 6.578151,
    latitude: 45.406791,
    id: "arp_mfx_fr",
    icao_code: "LFKX",
    iata_country_code: "FR",
    iata_code: "MFX",
    city_name: "Meribel",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Meridian Regional Airport",
    longitude: -88.750189,
    latitude: 32.332215,
    id: "arp_mei_us",
    icao_code: "KMEI",
    iata_country_code: "US",
    iata_code: "MEI",
    city_name: "Meridian",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Merimbula Airport",
    longitude: 149.901571,
    latitude: -36.909286,
    id: "arp_mim_au",
    icao_code: "YMER",
    iata_country_code: "AU",
    iata_code: "MIM",
    city_name: "Merimbula",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Merle K. (Mudhole) Smith Airport",
    longitude: -145.476862,
    latitude: 60.492617,
    id: "arp_cdv_us",
    icao_code: "PACV",
    iata_country_code: "US",
    iata_code: "CDV",
    city_name: "Cordova",
    city: {
      name: "Cordova",
      id: "cit_cdv_us",
      iata_country_code: "US",
      iata_code: "CDV",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Merluna Airport",
    longitude: 142.45239,
    latitude: -13.06471,
    id: "arp_mlv_au",
    icao_code: "YMEU",
    iata_country_code: "AU",
    iata_code: "MLV",
    city_name: "Merluna",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Merowe Airport",
    longitude: 31.841233,
    latitude: 18.441452,
    id: "arp_mwe_sd",
    icao_code: "HSMN",
    iata_country_code: "SD",
    iata_code: "MWE",
    city_name: "Merowe",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Merrill Field Airport",
    longitude: -149.843405,
    latitude: 61.214603,
    id: "arp_mri_us",
    icao_code: "PAMR",
    iata_country_code: "US",
    iata_code: "MRI",
    city_name: "Anchorage",
    city: {
      name: "Anchorage",
      id: "cit_anc_us",
      iata_country_code: "US",
      iata_code: "ANC",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Merrill Municipal Airport",
    longitude: -89.709328,
    latitude: 45.199782,
    id: "arp_rrl_us",
    icao_code: "KRRL",
    iata_country_code: "US",
    iata_code: "RRL",
    city_name: "Merrill",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Merritt Airport",
    longitude: -120.744618,
    latitude: 50.122658,
    id: "arp_ymb_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YMB",
    city_name: "Merritt",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Merritt Island Airport",
    longitude: -80.687795,
    latitude: 28.342167,
    id: "arp_coi_us",
    icao_code: "KCOI",
    iata_country_code: "US",
    iata_code: "COI",
    city_name: "Merritt Island",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Mersa Matruh Airport",
    longitude: 27.221874,
    latitude: 31.32508,
    id: "arp_muh_eg",
    icao_code: "HEMM",
    iata_country_code: "EG",
    iata_code: "MUH",
    city_name: "Mersa Matruh",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Mersing Airport",
    longitude: 103.875269,
    latitude: 2.388012,
    id: "arp_mep_my",
    icao_code: "WMAU",
    iata_country_code: "MY",
    iata_code: "MEP",
    city_name: "Mersing",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Merty Merty Airport",
    longitude: 140.317,
    latitude: -28.5833,
    id: "arp_rty_au",
    icao_code: "YMYT",
    iata_country_code: "AU",
    iata_code: "RTY",
    city_name: "Merty Merty",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Merville – Calonne Airport",
    longitude: 2.645031,
    latitude: 50.62266,
    id: "arp_hzb_fr",
    icao_code: "LFQT",
    iata_country_code: "FR",
    iata_code: "HZB",
    city_name: "Hazebrouck",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mesa Del Rey Airport",
    longitude: -121.122577,
    latitude: 36.228207,
    id: "arp_kic_us",
    icao_code: "KKIC",
    iata_country_code: "US",
    iata_code: "KIC",
    city_name: "King City",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Mesa Falcon Field Airport",
    longitude: -111.727804,
    latitude: 33.459677,
    id: "arp_msc_us",
    icao_code: "KFFZ",
    iata_country_code: "US",
    iata_code: "MSC",
    city_name: "Phoenix",
    city: {
      name: "Phoenix",
      id: "cit_phx_us",
      iata_country_code: "US",
      iata_code: "PHX",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Meselia Airport",
    longitude: 149.1183,
    latitude: -6.1364,
    id: "arp_mfz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MFZ",
    city_name: "Meselia",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mesquite Airport",
    longitude: -114.056656,
    latitude: 36.833689,
    id: "arp_mfh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MFH",
    city_name: "Mesquite",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Messina Airport",
    longitude: 29.987757,
    latitude: -22.355952,
    id: "arp_mez_za",
    icao_code: "FAMS",
    iata_country_code: "ZA",
    iata_code: "MEZ",
    city_name: "Messina",
    city: null,
  },
  {
    time_zone: "America/Metlakatla",
    name: "Metlakatla Seaplane Base Airport",
    longitude: -131.57514,
    latitude: 55.129368,
    id: "arp_mtm_us",
    icao_code: "PAMM",
    iata_country_code: "US",
    iata_code: "MTM",
    city_name: "Metlakatla",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Metro Field Airport",
    longitude: -147.761747,
    latitude: 64.8077,
    id: "arp_mtx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MTX",
    city_name: "Fairbanks",
    city: {
      name: "Fairbanks",
      id: "cit_fai_us",
      iata_country_code: "US",
      iata_code: "FAI",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Metropolitan Airport",
    longitude: -72.311314,
    latitude: 42.223459,
    id: "arp_pmx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PMX",
    city_name: "Palmer",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Mettel Field",
    longitude: -85.13062,
    latitude: 39.698293,
    id: "arp_cev_us",
    icao_code: "KCEV",
    iata_country_code: "US",
    iata_code: "CEV",
    city_name: "Connersville",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Metz-Frescaty Air Base Airport",
    longitude: 6.132595,
    latitude: 49.071967,
    id: "arp_mzm_fr",
    icao_code: "LFSF",
    iata_country_code: "FR",
    iata_code: "MZM",
    city_name: "Nancy/Metz",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Metz-Nancy-Lorraine Airport",
    longitude: 6.247043,
    latitude: 48.981216,
    id: "arp_etz_fr",
    icao_code: "LFJL",
    iata_country_code: "FR",
    iata_code: "ETZ",
    city_name: "Metz/Nancy",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Mevang Airport",
    longitude: 11.083,
    latitude: 0.083,
    id: "arp_mvg_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "MVG",
    city_name: "Mevang",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "Mexicali International Airport",
    longitude: -115.244103,
    latitude: 32.630115,
    id: "arp_mxl_mx",
    icao_code: "MMML",
    iata_country_code: "MX",
    iata_code: "MXL",
    city_name: "Mexicali",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Meyers Chuck Seaplane Base Airport",
    longitude: -132.533501,
    latitude: 55.687833,
    id: "arp_wmk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WMK",
    city_name: "Meyers Chuck",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Mfuwe Airport",
    longitude: 31.93661,
    latitude: -13.258046,
    id: "arp_mfu_zm",
    icao_code: "FLMF",
    iata_country_code: "ZM",
    iata_code: "MFU",
    city_name: "Mfuwe",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "M. Graham Clark Downtown Airport",
    longitude: -93.228251,
    latitude: 36.623845,
    id: "arp_plk_us",
    icao_code: "KPLK",
    iata_country_code: "US",
    iata_code: "PLK",
    city_name: "Branson",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Miami Executive Airport",
    longitude: -80.43167,
    latitude: 25.647994,
    id: "arp_tmb_us",
    icao_code: "KTMB",
    iata_country_code: "US",
    iata_code: "TMB",
    city_name: "Miami",
    city: {
      name: "Miami",
      id: "cit_mia_us",
      iata_country_code: "US",
      iata_code: "MIA",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Miami International Airport",
    longitude: -80.288826,
    latitude: 25.794534,
    id: "arp_mia_us",
    icao_code: "KMIA",
    iata_country_code: "US",
    iata_code: "MIA",
    city_name: "Miami",
    city: {
      name: "Miami",
      id: "cit_mia_us",
      iata_country_code: "US",
      iata_code: "MIA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Miami Municipal Airport",
    longitude: -94.887497,
    latitude: 36.909168,
    id: "arp_mio_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MIO",
    city_name: "Miami",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Miami-Opa Locka Executive Airport",
    longitude: -80.27678,
    latitude: 25.906626,
    id: "arp_opf_us",
    icao_code: "KOPF",
    iata_country_code: "US",
    iata_code: "OPF",
    city_name: "Miami",
    city: {
      name: "Miami",
      id: "cit_mia_us",
      iata_country_code: "US",
      iata_code: "MIA",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Miami Seaplane Base",
    longitude: -80.174956,
    latitude: 25.781681,
    id: "arp_mpb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MPB",
    city_name: "Miami",
    city: {
      name: "Miami",
      id: "cit_mia_us",
      iata_country_code: "US",
      iata_code: "MIA",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Miami University Airport",
    longitude: -84.784822,
    latitude: 39.504799,
    id: "arp_oxd_us",
    icao_code: "KOXD",
    iata_country_code: "US",
    iata_code: "OXD",
    city_name: "Oxford",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Miandrivazo Airport",
    longitude: 45.450816,
    latitude: -19.562788,
    id: "arp_zva_mg",
    icao_code: "FMMN",
    iata_country_code: "MG",
    iata_code: "ZVA",
    city_name: "Miandrivazo",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Mianwali Airport",
    longitude: 71.570745,
    latitude: 32.563085,
    id: "arp_mwd_pk",
    icao_code: "OPMI",
    iata_country_code: "PK",
    iata_code: "MWD",
    city_name: "Mianwali",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Mianyang Nanjiao Airport",
    longitude: 104.739525,
    latitude: 31.429605,
    id: "arp_mig_cn",
    icao_code: "ZUMY",
    iata_country_code: "CN",
    iata_code: "MIG",
    city_name: "Mianyang",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Michael Army Airfield",
    longitude: -112.937312,
    latitude: 40.199392,
    id: "arp_dpg_us",
    icao_code: "KDPG",
    iata_country_code: "US",
    iata_code: "DPG",
    city_name: "Dugway",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Michel-Pouliot Gaspé Airport",
    longitude: -64.477578,
    latitude: 48.7767,
    id: "arp_ygp_ca",
    icao_code: "CYGP",
    iata_country_code: "CA",
    iata_code: "YGP",
    city_name: "Gaspé",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Michigan City Municipal Airport",
    longitude: -86.821327,
    latitude: 41.703988,
    id: "arp_mgc_us",
    icao_code: "KMGC",
    iata_country_code: "US",
    iata_code: "MGC",
    city_name: "Michigan City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "MidAmerica St. Louis Airport",
    longitude: -89.821083,
    latitude: 38.548839,
    id: "arp_blv_us",
    icao_code: "KBLV",
    iata_country_code: "US",
    iata_code: "BLV",
    city_name: "Belleville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mid-Carolina Regional Airport",
    longitude: -80.519901,
    latitude: 35.642922,
    id: "arp_srw_us",
    icao_code: "KRUQ",
    iata_country_code: "US",
    iata_code: "SRW",
    city_name: "Salisbury",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "MidCoast Regional Airport",
    longitude: -81.56122,
    latitude: 31.886633,
    id: "arp_liy_us",
    icao_code: "KLHW",
    iata_country_code: "US",
    iata_code: "LIY",
    city_name: "Ft Stewart/Hinesville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mid Delta Regional Airport",
    longitude: -90.986716,
    latitude: 33.482026,
    id: "arp_glh_us",
    icao_code: "KGLH",
    iata_country_code: "US",
    iata_code: "GLH",
    city_name: "Greenville",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "Middle Caicos Airport",
    longitude: -71.801525,
    latitude: 21.825749,
    id: "arp_mds_tc",
    icao_code: "MBMC",
    iata_country_code: "TC",
    iata_code: "MDS",
    city_name: "Middle Caicos",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Middle Georgia Regional Airport",
    longitude: -83.650095,
    latitude: 32.69726,
    id: "arp_mcn_us",
    icao_code: "KMCN",
    iata_country_code: "US",
    iata_code: "MCN",
    city_name: "Macon",
    city: {
      name: "Macon",
      id: "cit_mcn_us",
      iata_country_code: "US",
      iata_code: "MCN",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Middlemount Airport",
    longitude: 148.705119,
    latitude: -22.80089,
    id: "arp_mmm_au",
    icao_code: "YMMU",
    iata_country_code: "AU",
    iata_code: "MMM",
    city_name: "Middlemount",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Middleton Island Airport",
    longitude: -146.306505,
    latitude: 59.449879,
    id: "arp_mdo_us",
    icao_code: "PAMD",
    iata_country_code: "US",
    iata_code: "MDO",
    city_name: "Middleton Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Middletown Regional Airport",
    longitude: -84.395336,
    latitude: 39.530981,
    id: "arp_mwo_us",
    icao_code: "KMWO",
    iata_country_code: "US",
    iata_code: "MWO",
    city_name: "Middletown",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Midgard Airport",
    longitude: 17.370107,
    latitude: -22.010928,
    id: "arp_mqg_na",
    icao_code: "FYMG",
    iata_country_code: "NA",
    iata_code: "MQG",
    city_name: "Midgard",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Midland Airpark",
    longitude: -102.101643,
    latitude: 32.035911,
    id: "arp_mdd_us",
    icao_code: "KMDD",
    iata_country_code: "US",
    iata_code: "MDD",
    city_name: "Midland",
    city: {
      name: "Midland",
      id: "cit_maf_us",
      iata_country_code: "US",
      iata_code: "MAF",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Midland International Airport",
    longitude: -102.203377,
    latitude: 31.942119,
    id: "arp_maf_us",
    icao_code: "KMAF",
    iata_country_code: "US",
    iata_code: "MAF",
    city_name: "Midland",
    city: {
      name: "Midland",
      id: "cit_maf_us",
      iata_country_code: "US",
      iata_code: "MAF",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Mid-Ohio Valley Regional Airport",
    longitude: -81.439153,
    latitude: 39.34462,
    id: "arp_pkb_us",
    icao_code: "KPKB",
    iata_country_code: "US",
    iata_code: "PKB",
    city_name: "Parkersburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mid State Regional Airport",
    longitude: -78.085263,
    latitude: 40.885041,
    id: "arp_psb_us",
    icao_code: "KPSB",
    iata_country_code: "US",
    iata_code: "PSB",
    city_name: "Philipsburg",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Midtjyllands Airport",
    longitude: 9.105592,
    latitude: 56.297864,
    id: "arp_krp_dk",
    icao_code: "EKKA",
    iata_country_code: "DK",
    iata_code: "KRP",
    city_name: "Karup",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Miele Mimbale Airport",
    longitude: 11.4,
    latitude: -0.01667,
    id: "arp_gim_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "GIM",
    city_name: "Miele Mimbale",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mifflin County Airport",
    longitude: -77.626541,
    latitude: 40.676928,
    id: "arp_red_us",
    icao_code: "KRVL",
    iata_country_code: "US",
    iata_code: "RED",
    city_name: "Lewistown/Reedsville",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Migalovo Air Base",
    longitude: 35.760995,
    latitude: 56.836951,
    id: "arp_kld_ru",
    icao_code: "UUEM",
    iata_country_code: "RU",
    iata_code: "KLD",
    city_name: "Tver",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Miguel Hidalgo y Costilla Guadalajara International Airport",
    longitude: -103.309311,
    latitude: 20.523878,
    id: "arp_gdl_mx",
    icao_code: "MMGL",
    iata_country_code: "MX",
    iata_code: "GDL",
    city_name: "Guadalajara",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Mihail Kogălniceanu International Airport",
    longitude: 28.488311,
    latitude: 44.362116,
    id: "arp_cnd_ro",
    icao_code: "LRCK",
    iata_country_code: "RO",
    iata_code: "CND",
    city_name: "Constanța",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Miho Yonago Airport",
    longitude: 133.239138,
    latitude: 35.495314,
    id: "arp_ygj_jp",
    icao_code: "RJOH",
    iata_country_code: "JP",
    iata_code: "YGJ",
    city_name: "Yonago",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Mikkeli Airport",
    longitude: 27.203932,
    latitude: 61.686702,
    id: "arp_mik_fi",
    icao_code: "EFMI",
    iata_country_code: "FI",
    iata_code: "MIK",
    city_name: "Mikkeli",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Milan–Malpensa Airport",
    longitude: 8.72682,
    latitude: 45.630331,
    id: "arp_mxp_it",
    icao_code: "LIMC",
    iata_country_code: "IT",
    iata_code: "MXP",
    city_name: "Milan",
    city: {
      name: "Milan",
      id: "cit_mil_it",
      iata_country_code: "IT",
      iata_code: "MIL",
    },
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Milas-Bodrum Airport",
    longitude: 27.664146,
    latitude: 37.249627,
    id: "arp_bjv_tr",
    icao_code: "LTFE",
    iata_country_code: "TR",
    iata_code: "BJV",
    city_name: "Bodrum",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Mildura Airport",
    longitude: 142.085413,
    latitude: -34.229334,
    id: "arp_mql_au",
    icao_code: "YMIA",
    iata_country_code: "AU",
    iata_code: "MQL",
    city_name: "Mildura",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Miles Airport",
    longitude: 150.16229,
    latitude: -26.810161,
    id: "arp_wle_au",
    icao_code: "YMLS",
    iata_country_code: "AU",
    iata_code: "WLE",
    city_name: "Miles",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Miles City Airport",
    longitude: -105.884785,
    latitude: 46.428323,
    id: "arp_mls_us",
    icao_code: "KMLS",
    iata_country_code: "US",
    iata_code: "MLS",
    city_name: "Miles City",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Miley Memorial Field",
    longitude: -110.111076,
    latitude: 42.585083,
    id: "arp_bpi_us",
    icao_code: "KBPI",
    iata_country_code: "US",
    iata_code: "BPI",
    city_name: "Big Piney",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Milford Municipal Airport",
    longitude: -113.014298,
    latitude: 38.426201,
    id: "arp_mlf_us",
    icao_code: "KMLF",
    iata_country_code: "US",
    iata_code: "MLF",
    city_name: "Milford",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Milford Sound Airport",
    longitude: 167.923595,
    latitude: -44.673486,
    id: "arp_mfn_nz",
    icao_code: "NZMF",
    iata_country_code: "NZ",
    iata_code: "MFN",
    city_name: "Milford Sound",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Mili Island Airport",
    longitude: 171.733312,
    latitude: 6.084464,
    id: "arp_mij_mh",
    icao_code: "MLIP",
    iata_country_code: "MH",
    iata_code: "MIJ",
    city_name: "Mili Island",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Milingimbi Airport",
    longitude: 134.893838,
    latitude: -12.094167,
    id: "arp_mgt_au",
    icao_code: "YMGB",
    iata_country_code: "AU",
    iata_code: "MGT",
    city_name: "Milingimbi Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Millard Airport",
    longitude: -96.114244,
    latitude: 41.195081,
    id: "arp_miq_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MIQ",
    city_name: "Omaha",
    city: {
      name: "Omaha",
      id: "cit_oma_us",
      iata_country_code: "US",
      iata_code: "OMA",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Miller Field",
    longitude: -100.547739,
    latitude: 42.857792,
    id: "arp_vtn_us",
    icao_code: "KVTN",
    iata_country_code: "US",
    iata_code: "VTN",
    city_name: "Valentine",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Millicent Airport",
    longitude: 140.367881,
    latitude: -37.584014,
    id: "arp_mlr_au",
    icao_code: "YMCT",
    iata_country_code: "AU",
    iata_code: "MLR",
    city_name: "Millicent",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Millington-Memphis Airport",
    longitude: -89.870007,
    latitude: 35.353039,
    id: "arp_nqa_us",
    icao_code: "KNQA",
    iata_country_code: "US",
    iata_code: "NQA",
    city_name: "Millington",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Millinocket Municipal Airport",
    longitude: -68.689256,
    latitude: 45.64811,
    id: "arp_mlt_us",
    icao_code: "KMLT",
    iata_country_code: "US",
    iata_code: "MLT",
    city_name: "Millinocket",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Millville Executive Airport",
    longitude: -75.073083,
    latitude: 39.367218,
    id: "arp_miv_us",
    icao_code: "KMIV",
    iata_country_code: "US",
    iata_code: "MIV",
    city_name: "Millville",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Milos Island National Airport",
    longitude: 24.47346,
    latitude: 36.697078,
    id: "arp_mlo_gr",
    icao_code: "LGML",
    iata_country_code: "GR",
    iata_code: "MLO",
    city_name: "Milos",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Minaçu Airport",
    longitude: -48.194985,
    latitude: -13.547628,
    id: "arp_mqh_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "MQH",
    city_name: "Minacu",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Minami-Daito Airport",
    longitude: 131.263322,
    latitude: 25.846756,
    id: "arp_mmd_jp",
    icao_code: "ROMD",
    iata_country_code: "JP",
    iata_code: "MMD",
    city_name: "Minamidaitō",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Minami Torishima Airport",
    longitude: 153.979653,
    latitude: 24.288643,
    id: "arp_mus_jp",
    icao_code: "RJAM",
    iata_country_code: "JP",
    iata_code: "MUS",
    city_name: "Minamitorishima",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Minangkabau International Airport",
    longitude: 100.281391,
    latitude: -0.787314,
    id: "arp_pdg_id",
    icao_code: "WIEE",
    iata_country_code: "ID",
    iata_code: "PDG",
    city_name: "Padang",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Minatitlán/Coatzacoalcos International Airport",
    longitude: -94.579342,
    latitude: 18.102939,
    id: "arp_mtt_mx",
    icao_code: "MMMT",
    iata_country_code: "MX",
    iata_code: "MTT",
    city_name: "Minatitlán",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Minchumina Airport",
    longitude: -152.309339,
    latitude: 63.884651,
    id: "arp_lma_us",
    icao_code: "PAMH",
    iata_country_code: "US",
    iata_code: "LMA",
    city_name: "Lake Minchumina",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Minchumina Airport",
    longitude: -152.301944,
    latitude: 63.886111,
    id: "arp_mhm_us",
    icao_code: "PAMH",
    iata_country_code: "US",
    iata_code: "MHM",
    city_name: "Malaita",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Minden-Tahoe Airport",
    longitude: -119.751568,
    latitude: 39.00103,
    id: "arp_mev_us",
    icao_code: "KMEV",
    iata_country_code: "US",
    iata_code: "MEV",
    city_name: "Minden",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mindik Airport",
    longitude: 147.441459,
    latitude: -6.471373,
    id: "arp_mxk_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MXK",
    city_name: "Mindik",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Mindiptana Airport",
    longitude: 140.709675,
    latitude: -5.8793,
    id: "arp_mdp_id",
    icao_code: "WAKD",
    iata_country_code: "ID",
    iata_code: "MDP",
    city_name: "Mindiptana",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Mineralnye Vody Airport",
    longitude: 43.083718,
    latitude: 44.223076,
    id: "arp_mrv_ru",
    icao_code: "URMM",
    iata_country_code: "RU",
    iata_code: "MRV",
    city_name: "Mineralnye Vody",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mineral Wells Airport",
    longitude: -98.060577,
    latitude: 32.781845,
    id: "arp_mwl_us",
    icao_code: "KMWL",
    iata_country_code: "US",
    iata_code: "MWL",
    city_name: "Mineral Wells",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Miners Lake Airport",
    longitude: 145.583,
    latitude: -20.0667,
    id: "arp_mrl_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MRL",
    city_name: "Miners Lake",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mine Site Airport",
    longitude: 120.034388,
    latitude: -22.581802,
    id: "arp_gyz_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "GYZ",
    city_name: "Gruyere",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Mingan Airport",
    longitude: -64.152982,
    latitude: 50.286742,
    id: "arp_ylp_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YLP",
    city_name: "Mingan",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Minhas Airbase",
    longitude: 72.400902,
    latitude: 33.869099,
    id: "arp_atg_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "ATG",
    city_name: "Attock",
    city: null,
  },
  {
    time_zone: "Asia/Hebron",
    name: "Min'hat Hashnayim Airport",
    longitude: 35.382022,
    latitude: 31.150237,
    id: "arp_sed_il",
    icao_code: null,
    iata_country_code: "IL",
    iata_code: "SED",
    city_name: "Sedom",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "Ministro Pistarini International Airport",
    longitude: -58.535314,
    latitude: -34.818602,
    id: "arp_eze_ar",
    icao_code: "SAEZ",
    iata_country_code: "AR",
    iata_code: "EZE",
    city_name: "Buenos Aires",
    city: {
      name: "Buenos Aires",
      id: "cit_bue_ar",
      iata_country_code: "AR",
      iata_code: "BUE",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Minj Airport",
    longitude: 144.667,
    latitude: -5.86667,
    id: "arp_mzn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MZN",
    city_name: "Minj",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Minlaton Airport",
    longitude: 137.528374,
    latitude: -34.747396,
    id: "arp_xml_au",
    icao_code: "YMIN",
    iata_country_code: "AU",
    iata_code: "XML",
    city_name: "Minlaton",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Minna Airport",
    longitude: 6.463593,
    latitude: 9.654871,
    id: "arp_mxj_ng",
    icao_code: "DNMN",
    iata_country_code: "NG",
    iata_code: "MXJ",
    city_name: "Minna",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Minneapolis-Saint Paul International Airport",
    longitude: -93.222043,
    latitude: 44.883378,
    id: "arp_msp_us",
    icao_code: "KMSP",
    iata_country_code: "US",
    iata_code: "MSP",
    city_name: "Minneapolis",
    city: {
      name: "Minneapolis/St Paul",
      id: "cit_msp_us",
      iata_country_code: "US",
      iata_code: "MSP",
    },
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Minnipa Airport",
    longitude: 135.145128,
    latitude: -32.843482,
    id: "arp_min_au",
    icao_code: "YMPA",
    iata_country_code: "AU",
    iata_code: "MIN",
    city_name: "Minnipa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Minot Air Force Base",
    longitude: -101.351437,
    latitude: 48.41588,
    id: "arp_mib_us",
    icao_code: "KMIB",
    iata_country_code: "US",
    iata_code: "MIB",
    city_name: "Minot",
    city: {
      name: "Minot",
      id: "cit_mot_us",
      iata_country_code: "US",
      iata_code: "MOT",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Minot International Airport",
    longitude: -101.284922,
    latitude: 48.25753,
    id: "arp_mot_us",
    icao_code: "KMOT",
    iata_country_code: "US",
    iata_code: "MOT",
    city_name: "Minot",
    city: {
      name: "Minot",
      id: "cit_mot_us",
      iata_country_code: "US",
      iata_code: "MOT",
    },
  },
  {
    time_zone: "Europe/Minsk",
    name: "Minsk-1 Airport",
    longitude: 27.538224,
    latitude: 53.86672,
    id: "arp_mhp_by",
    icao_code: "UMMM",
    iata_country_code: "BY",
    iata_code: "MHP",
    city_name: "Minsk",
    city: {
      name: "Minsk",
      id: "cit_msq_by",
      iata_country_code: "BY",
      iata_code: "MSQ",
    },
  },
  {
    time_zone: "Europe/Minsk",
    name: "Minsk National Airport",
    longitude: 28.032034,
    latitude: 53.884175,
    id: "arp_msq_by",
    icao_code: "UMMS",
    iata_country_code: "BY",
    iata_code: "MSQ",
    city_name: "Minsk",
    city: {
      name: "Minsk",
      id: "cit_msq_by",
      iata_country_code: "BY",
      iata_code: "MSQ",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Minto Al Wright Airport",
    longitude: -149.366113,
    latitude: 65.147806,
    id: "arp_mnt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MNT",
    city_name: "Minto",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Minute Man Airfield",
    longitude: -71.518372,
    latitude: 42.461275,
    id: "arp_mmn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MMN",
    city_name: "Stow",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Minvoul Airport",
    longitude: 12.106268,
    latitude: 2.153185,
    id: "arp_mvx_ga",
    icao_code: "FOGV",
    iata_country_code: "GA",
    iata_code: "MVX",
    city_name: "Minvoul",
    city: null,
  },
  {
    time_zone: "America/Miquelon",
    name: "Miquelon Airport",
    longitude: -56.381315,
    latitude: 47.095595,
    id: "arp_mqc_pm",
    icao_code: "LFVM",
    iata_country_code: "PM",
    iata_code: "MQC",
    city_name: "Miquelon",
    city: null,
  },
  {
    time_zone: "America/Araguaina",
    name: "Miracema do Tocantins Airport",
    longitude: -48.49825,
    latitude: -9.182242,
    id: "arp_ntm_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "NTM",
    city_name: "Miracema do Tocantins",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Miraflores Airport",
    longitude: -71.952756,
    latitude: 1.335897,
    id: "arp_mfs_co",
    icao_code: "SKMF",
    iata_country_code: "CO",
    iata_code: "MFS",
    city_name: "Miraflores",
    city: null,
  },
  {
    time_zone: "America/Argentina/Buenos_Aires",
    name: "Miramar Airport",
    longitude: -57.870078,
    latitude: -38.22677,
    id: "arp_mjr_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "MJR",
    city_name: "Miramar",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Miramar Marine Corps Air Station - Mitscher Field",
    longitude: -117.141969,
    latitude: 32.870477,
    id: "arp_nkx_us",
    icao_code: "KNKX",
    iata_country_code: "US",
    iata_code: "NKX",
    city_name: "San Diego",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "America/Moncton",
    name: "Miramichi Airport",
    longitude: -65.46684,
    latitude: 47.00869,
    id: "arp_ych_ca",
    icao_code: "CYCH",
    iata_country_code: "CA",
    iata_code: "YCH",
    city_name: "Miramichi",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Miranda Downs Airport",
    longitude: 141.886195,
    latitude: -17.329019,
    id: "arp_mwy_au",
    icao_code: "YMIR",
    iata_country_code: "AU",
    iata_code: "MWY",
    city_name: "Miranda Downs",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Miri Airport",
    longitude: 113.985763,
    latitude: 4.322602,
    id: "arp_myy_my",
    icao_code: "WBGR",
    iata_country_code: "MY",
    iata_code: "MYY",
    city_name: "Miri",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Mirití-Paraná Airport",
    longitude: -70.25,
    latitude: -1.13333,
    id: "arp_mix_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "MIX",
    city_name: "Miriti Parana",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Mirny Airport",
    longitude: 114.0291,
    latitude: 62.532701,
    id: "arp_mjz_ru",
    icao_code: "UERR",
    iata_country_code: "RU",
    iata_code: "MJZ",
    city_name: "Mirny",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Misawa Airport",
    longitude: 141.387787,
    latitude: 40.696892,
    id: "arp_msj_jp",
    icao_code: "RJSM",
    iata_country_code: "JP",
    iata_code: "MSJ",
    city_name: "Misawa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Misima Island Airport",
    longitude: 152.838879,
    latitude: -10.688829,
    id: "arp_mis_pg",
    icao_code: "AYMS",
    iata_country_code: "PG",
    iata_code: "MIS",
    city_name: "Misima Island",
    city: null,
  },
  {
    time_zone: "Europe/Budapest",
    name: "Miskolc Airport",
    longitude: 20.790679,
    latitude: 48.136423,
    id: "arp_mcq_hu",
    icao_code: "LHMC",
    iata_country_code: "HU",
    iata_code: "MCQ",
    city_name: "Miskolc",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Misrata International Airport",
    longitude: 15.06041,
    latitude: 32.324875,
    id: "arp_mra_ly",
    icao_code: "HLMS",
    iata_country_code: "LY",
    iata_code: "MRA",
    city_name: "Misurata",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Mission Field",
    longitude: -110.448647,
    latitude: 45.699355,
    id: "arp_lvm_us",
    icao_code: "KLVM",
    iata_country_code: "US",
    iata_code: "LVM",
    city_name: "Livingston",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Missoula International Airport",
    longitude: -114.087904,
    latitude: 46.917503,
    id: "arp_mso_us",
    icao_code: "KMSO",
    iata_country_code: "US",
    iata_code: "MSO",
    city_name: "Missoula",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mitchell Airport",
    longitude: 147.937643,
    latitude: -26.484188,
    id: "arp_mtq_au",
    icao_code: "YMIT",
    iata_country_code: "AU",
    iata_code: "MTQ",
    city_name: "Mitchell",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mitchell Municipal Airport",
    longitude: -98.038602,
    latitude: 43.774773,
    id: "arp_mhe_us",
    icao_code: "KMHE",
    iata_country_code: "US",
    iata_code: "MHE",
    city_name: "Mitchell",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mitchell Plateau Airport",
    longitude: 125.824181,
    latitude: -14.792064,
    id: "arp_mih_au",
    icao_code: "YMIP",
    iata_country_code: "AU",
    iata_code: "MIH",
    city_name: "Mitchell Plateau",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Mitiaro Island Airport",
    longitude: -157.709557,
    latitude: -19.840896,
    id: "arp_moi_ck",
    icao_code: "NCMR",
    iata_country_code: "CK",
    iata_code: "MOI",
    city_name: "Mitiaro Island",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Mitiga International Airport",
    longitude: 13.275982,
    latitude: 32.893928,
    id: "arp_mji_ly",
    icao_code: "HLLM",
    iata_country_code: "LY",
    iata_code: "MJI",
    city_name: "Tripoli",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mittiebah Airport",
    longitude: 137.082165,
    latitude: -18.805491,
    id: "arp_miy_au",
    icao_code: "YMTA",
    iata_country_code: "AU",
    iata_code: "MIY",
    city_name: "Mittiebah",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Mitzic Airport",
    longitude: 11.551701,
    latitude: 0.779509,
    id: "arp_mzc_ga",
    icao_code: "FOOM",
    iata_country_code: "GA",
    iata_code: "MZC",
    city_name: "Mitzic",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Mitzpe Ramon Airport",
    longitude: 34.807594,
    latitude: 30.652112,
    id: "arp_mip_il",
    icao_code: "LLRM",
    iata_country_code: "IL",
    iata_code: "MIP",
    city_name: "Mitzpe Ramon",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Miyakejima Airport",
    longitude: 139.559128,
    latitude: 34.073352,
    id: "arp_mye_jp",
    icao_code: "RJTQ",
    iata_country_code: "JP",
    iata_code: "MYE",
    city_name: "Miyakejima",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Miyako Airport",
    longitude: 125.295861,
    latitude: 24.781604,
    id: "arp_mmy_jp",
    icao_code: "ROMY",
    iata_country_code: "JP",
    iata_code: "MMY",
    city_name: "Miyako",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Miyanmin Airport",
    longitude: 141.619968,
    latitude: -4.902868,
    id: "arp_mpx_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MPX",
    city_name: "Miyanmin",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Miyazaki Airport",
    longitude: 131.446493,
    latitude: 31.875632,
    id: "arp_kmi_jp",
    icao_code: "RJFM",
    iata_country_code: "JP",
    iata_code: "KMI",
    city_name: "Miyazaki",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Mizan Teferi Airport",
    longitude: 35.5547,
    latitude: 6.9571,
    id: "arp_mtf_et",
    icao_code: "HAMT",
    iata_country_code: "ET",
    iata_code: "MTF",
    city_name: "Mizan Teferi",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Mkambati Airport",
    longitude: 29.9667,
    latitude: -31.2833,
    id: "arp_mbm_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "MBM",
    city_name: "Mkambati",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Mkuze Airport",
    longitude: 32.0443,
    latitude: -27.6261,
    id: "arp_mzq_za",
    icao_code: "FAMU",
    iata_country_code: "ZA",
    iata_code: "MZQ",
    city_name: "Mkuze",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Mmabatho International Airport",
    longitude: 25.537864,
    latitude: -25.802832,
    id: "arp_mbd_za",
    icao_code: "FAMM",
    iata_country_code: "ZA",
    iata_code: "MBD",
    city_name: "Mafeking",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Moabi Airport",
    longitude: 10.933387,
    latitude: -2.454338,
    id: "arp_mgx_ga",
    icao_code: "FOGI",
    iata_country_code: "GA",
    iata_code: "MGX",
    city_name: "Moabi",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Moala Airport",
    longitude: 179.950927,
    latitude: -18.56671,
    id: "arp_mfj_fj",
    icao_code: "NFMO",
    iata_country_code: "FJ",
    iata_code: "MFJ",
    city_name: "Moala",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Moanamani Airport",
    longitude: 136.036393,
    latitude: -4.008693,
    id: "arp_oni_id",
    icao_code: "WABD",
    iata_country_code: "ID",
    iata_code: "ONI",
    city_name: "Moanamani",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Moanda Airport",
    longitude: 13.268916,
    latitude: -1.534971,
    id: "arp_mff_ga",
    icao_code: "FOOD",
    iata_country_code: "GA",
    iata_code: "MFF",
    city_name: "Moanda",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Moba Airport",
    longitude: 29.736974,
    latitude: -7.084914,
    id: "arp_bdv_cd",
    icao_code: "FZRB",
    iata_country_code: "CD",
    iata_code: "BDV",
    city_name: "Moba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mobile Downtown Airport",
    longitude: -88.06822,
    latitude: 30.626798,
    id: "arp_bfm_us",
    icao_code: "KBFM",
    iata_country_code: "US",
    iata_code: "BFM",
    city_name: "Mobile",
    city: {
      name: "Mobile",
      id: "cit_mob_us",
      iata_country_code: "US",
      iata_code: "MOB",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Mobile Regional Airport",
    longitude: -88.243251,
    latitude: 30.691435,
    id: "arp_mob_us",
    icao_code: "KMOB",
    iata_country_code: "US",
    iata_code: "MOB",
    city_name: "Mobile",
    city: {
      name: "Mobile",
      id: "cit_mob_us",
      iata_country_code: "US",
      iata_code: "MOB",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Mobridge Municipal Airport",
    longitude: -100.408114,
    latitude: 45.54656,
    id: "arp_mbg_us",
    icao_code: "KMBG",
    iata_country_code: "US",
    iata_code: "MBG",
    city_name: "Mobridge",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Mocímboa da Praia Airport",
    longitude: 40.354584,
    latitude: -11.35882,
    id: "arp_mzb_mz",
    icao_code: "FQMP",
    iata_country_code: "MZ",
    iata_code: "MZB",
    city_name: "Mocimboa da Praia",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Mocopulli Airport",
    longitude: -73.717237,
    latitude: -42.343006,
    id: "arp_mhc_cl",
    icao_code: "SCPQ",
    iata_country_code: "CL",
    iata_code: "MHC",
    city_name: "Castro",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Modesto City–County Airport",
    longitude: -120.955021,
    latitude: 37.626398,
    id: "arp_mod_us",
    icao_code: "KMOD",
    iata_country_code: "US",
    iata_code: "MOD",
    city_name: "Modesto",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Moengo Airstrip",
    longitude: -54.400379,
    latitude: 5.607577,
    id: "arp_moj_sr",
    icao_code: "SMMO",
    iata_country_code: "SR",
    iata_code: "MOJ",
    city_name: "Moengo",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Moenjodaro Airport",
    longitude: 68.142034,
    latitude: 27.333832,
    id: "arp_mjd_pk",
    icao_code: "OPMJ",
    iata_country_code: "PK",
    iata_code: "MJD",
    city_name: "Moenjodaro",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Moffett Federal Airfield",
    longitude: -122.049413,
    latitude: 37.414141,
    id: "arp_nuq_us",
    icao_code: "KNUQ",
    iata_country_code: "US",
    iata_code: "NUQ",
    city_name: "Mountain View",
    city: null,
  },
  {
    time_zone: "Europe/Minsk",
    name: "Mogilev Airport",
    longitude: 30.096486,
    latitude: 53.956629,
    id: "arp_mvq_by",
    icao_code: "UMOO",
    iata_country_code: "BY",
    iata_code: "MVQ",
    city_name: "Mogilev",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Mohamed Boudiaf International Airport",
    longitude: 6.620317,
    latitude: 36.280436,
    id: "arp_czl_dz",
    icao_code: "DABC",
    iata_country_code: "DZ",
    iata_code: "CZL",
    city_name: "Constantine",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Mohammed V International Airport",
    longitude: -7.587202,
    latitude: 33.366389,
    id: "arp_cmn_ma",
    icao_code: "GMMN",
    iata_country_code: "MA",
    iata_code: "CMN",
    city_name: "Casablanca",
    city: {
      name: "Casablanca",
      id: "cit_cas_ma",
      iata_country_code: "MA",
      iata_code: "CAS",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Mohe Gulian Airport",
    longitude: 122.420317,
    latitude: 52.92404,
    id: "arp_ohe_cn",
    icao_code: "ZYMH",
    iata_country_code: "CN",
    iata_code: "OHE",
    city_name: "Mohe",
    city: null,
  },
  {
    time_zone: "Indian/Comoro",
    name: "Mohéli Bandar Es Eslam Airport",
    longitude: 43.765077,
    latitude: -12.297266,
    id: "arp_nwa_km",
    icao_code: "FMCI",
    iata_country_code: "KM",
    iata_code: "NWA",
    city_name: "Moheli",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Moi International Airport",
    longitude: 39.595233,
    latitude: -4.035053,
    id: "arp_mba_ke",
    icao_code: "HKMO",
    iata_country_code: "KE",
    iata_code: "MBA",
    city_name: "Mombasa",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Mo i Rana Airport, Røssvoll",
    longitude: 14.301603,
    latitude: 66.363775,
    id: "arp_mqn_no",
    icao_code: "ENRA",
    iata_country_code: "NO",
    iata_code: "MQN",
    city_name: "Mo i Rana",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Moisés Benzaquén Rengifo Airport",
    longitude: -76.118756,
    latitude: -5.893681,
    id: "arp_yms_pe",
    icao_code: "SPMS",
    iata_country_code: "PE",
    iata_code: "YMS",
    city_name: "Yurimaguas",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Moises R. Espinosa Airport",
    longitude: 123.630002,
    latitude: 12.369462,
    id: "arp_mbt_ph",
    icao_code: "RPVJ",
    iata_country_code: "PH",
    iata_code: "MBT",
    city_name: "Masbate",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mojave Airport",
    longitude: -118.154907,
    latitude: 35.05809,
    id: "arp_mhv_us",
    icao_code: "KMHV",
    iata_country_code: "US",
    iata_code: "MHV",
    city_name: "Mojave",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Mokhotlong Airport",
    longitude: 29.072305,
    latitude: -29.282181,
    id: "arp_mkh_ls",
    icao_code: "FXMK",
    iata_country_code: "LS",
    iata_code: "MKH",
    city_name: "Mokhotlong",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Moki Airport",
    longitude: 145.240111,
    latitude: -5.718177,
    id: "arp_mjj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MJJ",
    city_name: "Moki",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Mokuti Lodge Airport",
    longitude: 17.060288,
    latitude: -18.812314,
    id: "arp_oku_na",
    icao_code: "FYMO",
    iata_country_code: "NA",
    iata_code: "OKU",
    city_name: "Mokuti Lodge",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Molde Airport",
    longitude: 7.261549,
    latitude: 62.745551,
    id: "arp_mol_no",
    icao_code: "ENML",
    iata_country_code: "NO",
    iata_code: "MOL",
    city_name: "Molde",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Molokai Airport",
    longitude: -157.09616,
    latitude: 21.153233,
    id: "arp_mkk_us",
    icao_code: "PHMK",
    iata_country_code: "US",
    iata_code: "MKK",
    city_name: "Kaunakakai",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Moma Airport",
    longitude: 39.2167,
    latitude: -16.75,
    id: "arp_mmw_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "MMW",
    city_name: "Moma",
    city: null,
  },
  {
    time_zone: "Asia/Srednekolymsk",
    name: "Moma Airport",
    longitude: 143.259849,
    latitude: 66.45192,
    id: "arp_mqj_ru",
    icao_code: "UEMA",
    iata_country_code: "RU",
    iata_code: "MQJ",
    city_name: "Khonuu",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Momeik Airport",
    longitude: 96.645302,
    latitude: 23.092501,
    id: "arp_moe_mm",
    icao_code: "VYMO",
    iata_country_code: "MM",
    iata_code: "MOE",
    city_name: "Momeik",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Momote Airport",
    longitude: 147.42457,
    latitude: -2.061496,
    id: "arp_mas_pg",
    icao_code: "AYMO",
    iata_country_code: "PG",
    iata_code: "MAS",
    city_name: "Manus Island",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Mompos Airport",
    longitude: -74.437726,
    latitude: 9.260021,
    id: "arp_mmp_co",
    icao_code: "SKMP",
    iata_country_code: "CO",
    iata_code: "MMP",
    city_name: "Mompos",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Monastir Habib Bourguiba International Airport",
    longitude: 10.754238,
    latitude: 35.758709,
    id: "arp_mir_tn",
    icao_code: "DTMB",
    iata_country_code: "TN",
    iata_code: "MIR",
    city_name: "Monastir",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Monbetsu Airport",
    longitude: 143.405174,
    latitude: 44.304571,
    id: "arp_mbe_jp",
    icao_code: "RJEB",
    iata_country_code: "JP",
    iata_code: "MBE",
    city_name: "Monbetsu",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Mönchengladbach Airport",
    longitude: 6.503223,
    latitude: 51.230695,
    id: "arp_mgl_de",
    icao_code: "EDLN",
    iata_country_code: "DE",
    iata_code: "MGL",
    city_name: "Mönchengladbach",
    city: {
      name: "Duesseldorf",
      id: "cit_dus_de",
      iata_country_code: "DE",
      iata_code: "DUS",
    },
  },
  {
    time_zone: "America/Monterrey",
    name: "Monclova International Airport",
    longitude: -101.466829,
    latitude: 26.955274,
    id: "arp_lov_mx",
    icao_code: "MMMV",
    iata_country_code: "MX",
    iata_code: "LOV",
    city_name: "Monclova",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Mondell Field",
    longitude: -104.317324,
    latitude: 43.885202,
    id: "arp_ecs_us",
    icao_code: "KECS",
    iata_country_code: "US",
    iata_code: "ECS",
    city_name: "Newcastle",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Mondulkiri Airport",
    longitude: 107.187424,
    latitude: 12.463543,
    id: "arp_mwv_kh",
    icao_code: "VDMK",
    iata_country_code: "KH",
    iata_code: "MWV",
    city_name: "Mondulkiri",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Monfort Airport",
    longitude: -69.750365,
    latitude: 0.619832,
    id: "arp_mfb_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "MFB",
    city_name: "Monfort",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Mong Hsat Airport",
    longitude: 99.257196,
    latitude: 20.517372,
    id: "arp_mog_mm",
    icao_code: "VYMS",
    iata_country_code: "MM",
    iata_code: "MOG",
    city_name: "Mong Hsat",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Mongo Airport",
    longitude: 18.674549,
    latitude: 12.170165,
    id: "arp_mvo_td",
    icao_code: "FTTM",
    iata_country_code: "TD",
    iata_code: "MVO",
    city_name: "Mongo",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Mong Tong Airport",
    longitude: 98.899107,
    latitude: 20.295925,
    id: "arp_mgk_mm",
    icao_code: "VYMT",
    iata_country_code: "MM",
    iata_code: "MGK",
    city_name: "Mong Ton",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Mongu Airport",
    longitude: 23.16047,
    latitude: -15.254436,
    id: "arp_mnr_zm",
    icao_code: "FLMG",
    iata_country_code: "ZM",
    iata_code: "MNR",
    city_name: "Mongu",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Monkey Bay Airport",
    longitude: 34.919568,
    latitude: -14.081936,
    id: "arp_myz_mw",
    icao_code: "FWMY",
    iata_country_code: "MW",
    iata_code: "MYZ",
    city_name: "Monkey Bay",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Monkey Mountain Airport",
    longitude: -59.603228,
    latitude: 4.452297,
    id: "arp_mym_gy",
    icao_code: "SYMM",
    iata_country_code: "GY",
    iata_code: "MYM",
    city_name: "Monkey Mountain",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Monkira Airport",
    longitude: 140.552821,
    latitude: -24.819968,
    id: "arp_onr_au",
    icao_code: "YMNK",
    iata_country_code: "AU",
    iata_code: "ONR",
    city_name: "Monkira",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Monmouth Executive Airport",
    longitude: -74.123121,
    latitude: 40.184406,
    id: "arp_blm_us",
    icao_code: "KBLM",
    iata_country_code: "US",
    iata_code: "BLM",
    city_name: "Belmar/Farmingdale",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Mono Airport",
    longitude: 155.564737,
    latitude: -7.417075,
    id: "arp_mny_sb",
    icao_code: "AGGO",
    iata_country_code: "SB",
    iata_code: "MNY",
    city_name: "Stirling Island",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Monroe County Airport",
    longitude: -86.615513,
    latitude: 39.146615,
    id: "arp_bmg_us",
    icao_code: "KBMG",
    iata_country_code: "US",
    iata_code: "BMG",
    city_name: "Bloomington",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Monroe County Airport",
    longitude: -87.349969,
    latitude: 31.46152,
    id: "arp_mvc_us",
    icao_code: "KMVC",
    iata_country_code: "US",
    iata_code: "MVC",
    city_name: "Monroeville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Monroe Regional Airport",
    longitude: -92.040674,
    latitude: 32.510543,
    id: "arp_mlu_us",
    icao_code: "KMLU",
    iata_country_code: "US",
    iata_code: "MLU",
    city_name: "Monroe",
    city: null,
  },
  {
    time_zone: "America/El_Salvador",
    name: "Monseñor Óscar Arnulfo Romero International Airport",
    longitude: -89.055735,
    latitude: 13.441119,
    id: "arp_sal_sv",
    icao_code: "MSLP",
    iata_country_code: "SV",
    iata_code: "SAL",
    city_name: "San Salvador",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Montagne Harbor Airport",
    longitude: -123.20089,
    latitude: 48.816342,
    id: "arp_ymf_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YMF",
    city_name: "Galiano Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Montague Airport",
    longitude: -122.545489,
    latitude: 41.729965,
    id: "arp_rof_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ROF",
    city_name: "Yreka/Montague",
    city: {
      name: "Montague/Yreka",
      id: "cit_siy_us",
      iata_country_code: "US",
      iata_code: "SIY",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Montauk Airport",
    longitude: -71.921449,
    latitude: 41.075677,
    id: "arp_mtp_us",
    icao_code: "KMTP",
    iata_country_code: "US",
    iata_code: "MTP",
    city_name: "Montauk",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Mont-Dauphin - Saint-Crépin Airport",
    longitude: 6.600029,
    latitude: 44.701663,
    id: "arp_scp_fr",
    icao_code: "LFNC",
    iata_country_code: "FR",
    iata_code: "SCP",
    city_name: "Mont-Dauphin",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Monteagudo Airport",
    longitude: -63.964214,
    latitude: -19.822354,
    id: "arp_mhw_bo",
    icao_code: "SLAG",
    iata_country_code: "BO",
    iata_code: "MHW",
    city_name: "Monteagudo",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Monte Alegre Airport",
    longitude: -54.071532,
    latitude: -1.9948,
    id: "arp_mte_br",
    icao_code: "SNMA",
    iata_country_code: "BR",
    iata_code: "MTE",
    city_name: "Monte Alegre",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Monte Caseros Airport",
    longitude: -57.640411,
    latitude: -30.271086,
    id: "arp_mcs_ar",
    icao_code: "SARM",
    iata_country_code: "AR",
    iata_code: "MCS",
    city_name: "Monte Caseros",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Monte Dourado Airport",
    longitude: -52.601481,
    latitude: -0.888261,
    id: "arp_meu_br",
    icao_code: "SBMD",
    iata_country_code: "BR",
    iata_code: "MEU",
    city_name: "Almeirim",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Montelibano Airport",
    longitude: -75.432429,
    latitude: 7.975527,
    id: "arp_mtb_co",
    icao_code: "SKML",
    iata_country_code: "CO",
    iata_code: "MTB",
    city_name: "Montelibano",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Montepuez Airport",
    longitude: 38.99426,
    latitude: -13.11929,
    id: "arp_mtu_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "MTU",
    city_name: "Montepuez",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Monterey Regional Airport",
    longitude: -121.846055,
    latitude: 36.587635,
    id: "arp_mry_us",
    icao_code: "KMRY",
    iata_country_code: "US",
    iata_code: "MRY",
    city_name: "Monterey",
    city: {
      name: "Monterey/Carmel",
      id: "cit_mry_us",
      iata_country_code: "US",
      iata_code: "MRY",
    },
  },
  {
    time_zone: "America/Bogota",
    name: "Monterrey Airport",
    longitude: -72.848918,
    latitude: 4.915014,
    id: "arp_moy_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "MOY",
    city_name: "Monterrey",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Monterrey International Airport",
    longitude: -100.107493,
    latitude: 25.778159,
    id: "arp_mty_mx",
    icao_code: "MMMY",
    iata_country_code: "MX",
    iata_code: "MTY",
    city_name: "Monterrey",
    city: {
      name: "Monterrey",
      id: "cit_mty_mx",
      iata_country_code: "MX",
      iata_code: "MTY",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Montes Claros Airport",
    longitude: -43.819469,
    latitude: -16.705842,
    id: "arp_moc_br",
    icao_code: "SBMK",
    iata_country_code: "BR",
    iata_code: "MOC",
    city_name: "Montes Claros",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Montevideo-Chippewa County Airport",
    longitude: -95.712388,
    latitude: 44.969672,
    id: "arp_mve_us",
    icao_code: "KMVE",
    iata_country_code: "US",
    iata_code: "MVE",
    city_name: "Montevideo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Montgomery County Airpark",
    longitude: -77.163691,
    latitude: 39.167771,
    id: "arp_gai_us",
    icao_code: "KGAI",
    iata_country_code: "US",
    iata_code: "GAI",
    city_name: "Gaithersburg",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Montgomery-Gibbs Executive Airport",
    longitude: -117.139954,
    latitude: 32.81436,
    id: "arp_myf_us",
    icao_code: "KMYF",
    iata_country_code: "US",
    iata_code: "MYF",
    city_name: "San Diego",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Montgomery Regional Airport",
    longitude: -86.392543,
    latitude: 32.301864,
    id: "arp_mgm_us",
    icao_code: "KMGM",
    iata_country_code: "US",
    iata_code: "MGM",
    city_name: "Montgomery",
    city: {
      name: "Montgomery",
      id: "cit_mgm_us",
      iata_country_code: "US",
      iata_code: "MGM",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Monticello Airport",
    longitude: -109.342144,
    latitude: 37.932704,
    id: "arp_mxc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MXC",
    city_name: "Monticello",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Monticello Regional Airport",
    longitude: -91.163412,
    latitude: 42.223319,
    id: "arp_mxo_us",
    icao_code: "KMXO",
    iata_country_code: "US",
    iata_code: "MXO",
    city_name: "Monticello",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Mont-Joli Airport",
    longitude: -68.209807,
    latitude: 48.605457,
    id: "arp_yyy_ca",
    icao_code: "CYYY",
    iata_country_code: "CA",
    iata_code: "YYY",
    city_name: "Mont-Joli",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Montluçon – Guéret Airport",
    longitude: 2.363075,
    latitude: 46.223572,
    id: "arp_mcu_fr",
    icao_code: "LFBK",
    iata_country_code: "FR",
    iata_code: "MCU",
    city_name: "Montlucon",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Monto Airport",
    longitude: 151.100911,
    latitude: -24.890119,
    id: "arp_mnq_au",
    icao_code: "YMTO",
    iata_country_code: "AU",
    iata_code: "MNQ",
    city_name: "Monto",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Montpellier-Méditerranée Airport",
    longitude: 3.962637,
    latitude: 43.577686,
    id: "arp_mpl_fr",
    icao_code: "LFMT",
    iata_country_code: "FR",
    iata_code: "MPL",
    city_name: "Montpellier",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Montréal–Mirabel International Airport",
    longitude: -74.034345,
    latitude: 45.672585,
    id: "arp_ymx_ca",
    icao_code: "CYMX",
    iata_country_code: "CA",
    iata_code: "YMX",
    city_name: "Montreal",
    city: {
      name: "Montreal",
      id: "cit_ymq_ca",
      iata_country_code: "CA",
      iata_code: "YMQ",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Montréal / Saint-Hubert Airport",
    longitude: -73.413765,
    latitude: 45.517183,
    id: "arp_yhu_ca",
    icao_code: "CYHU",
    iata_country_code: "CA",
    iata_code: "YHU",
    city_name: "Montreal",
    city: {
      name: "Montreal",
      id: "cit_ymq_ca",
      iata_country_code: "CA",
      iata_code: "YMQ",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Montréal–Trudeau International Airport",
    longitude: -73.743,
    latitude: 45.469156,
    id: "arp_yul_ca",
    icao_code: "CYUL",
    iata_country_code: "CA",
    iata_code: "YUL",
    city_name: "Montreal",
    city: {
      name: "Montreal",
      id: "cit_ymq_ca",
      iata_country_code: "CA",
      iata_code: "YMQ",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Montrose Regional Airport",
    longitude: -107.895703,
    latitude: 38.505754,
    id: "arp_mtj_us",
    icao_code: "KMTJ",
    iata_country_code: "US",
    iata_code: "MTJ",
    city_name: "Montrose",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Mont Tremblant International Airport",
    longitude: -74.780139,
    latitude: 46.409284,
    id: "arp_ytm_ca",
    icao_code: "CYFJ",
    iata_country_code: "CA",
    iata_code: "YTM",
    city_name: "Mont-Tremblant",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Monument Valley Airport",
    longitude: -110.201185,
    latitude: 37.014338,
    id: "arp_gmv_us",
    icao_code: "UT25",
    iata_country_code: "US",
    iata_code: "GMV",
    city_name: "Monument Valley",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Monywa Airport",
    longitude: 95.093479,
    latitude: 22.221638,
    id: "arp_nyw_mm",
    icao_code: "VYMY",
    iata_country_code: "MM",
    iata_code: "NYW",
    city_name: "Monywa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Moody Air Force Base",
    longitude: -83.193019,
    latitude: 30.96807,
    id: "arp_vad_us",
    icao_code: "KVAD",
    iata_country_code: "US",
    iata_code: "VAD",
    city_name: "Valdosta",
    city: {
      name: "Valdosta",
      id: "cit_vld_us",
      iata_country_code: "US",
      iata_code: "VLD",
    },
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Moolawatana Airport",
    longitude: 139.76456,
    latitude: -29.906574,
    id: "arp_mwt_au",
    icao_code: "YMWT",
    iata_country_code: "AU",
    iata_code: "MWT",
    city_name: "Moolawatana Station",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Moomba Airport",
    longitude: 140.197528,
    latitude: -28.099656,
    id: "arp_moo_au",
    icao_code: "YOOM",
    iata_country_code: "AU",
    iata_code: "MOO",
    city_name: "Moomba",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Moorabbin Airport",
    longitude: 145.099037,
    latitude: -37.976071,
    id: "arp_mbw_au",
    icao_code: "YMMB",
    iata_country_code: "AU",
    iata_code: "MBW",
    city_name: "Melbourne",
    city: {
      name: "Melbourne",
      id: "cit_mel_au",
      iata_country_code: "AU",
      iata_code: "MEL",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mooraberree Airport",
    longitude: 140.982182,
    latitude: -25.24573,
    id: "arp_oor_au",
    icao_code: "YMOO",
    iata_country_code: "AU",
    iata_code: "OOR",
    city_name: "Mooraberree",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Moorea Airport",
    longitude: -149.762618,
    latitude: -17.490095,
    id: "arp_moz_pf",
    icao_code: "NTTM",
    iata_country_code: "PF",
    iata_code: "MOZ",
    city_name: "Moorea",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Moore County Airport",
    longitude: -79.39217,
    latitude: 35.236627,
    id: "arp_sop_us",
    icao_code: "KSOP",
    iata_country_code: "US",
    iata_code: "SOP",
    city_name: "Pinehurst/Southern Pines",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Moore-Murrell Airport",
    longitude: -83.375212,
    latitude: 36.179548,
    id: "arp_mor_us",
    icao_code: "KMOR",
    iata_country_code: "US",
    iata_code: "MOR",
    city_name: "Morristown",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Moose Jaw Airport",
    longitude: -105.558998,
    latitude: 50.330299,
    id: "arp_ymj_ca",
    icao_code: "CYMJ",
    iata_country_code: "CA",
    iata_code: "YMJ",
    city_name: "Moose Jaw",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Moose Lake Airport",
    longitude: -125.410475,
    latitude: 53.073385,
    id: "arp_yad_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YAD",
    city_name: "Moose Lake",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Moosonee Airport",
    longitude: -80.611266,
    latitude: 51.290155,
    id: "arp_ymo_ca",
    icao_code: "CYMO",
    iata_country_code: "CA",
    iata_code: "YMO",
    city_name: "Moosonee",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Mopah Airport",
    longitude: 140.416882,
    latitude: -8.519303,
    id: "arp_mkq_id",
    icao_code: "WAKK",
    iata_country_code: "ID",
    iata_code: "MKQ",
    city_name: "Merauke",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Mopti Airport",
    longitude: -4.080054,
    latitude: 14.512288,
    id: "arp_mzi_ml",
    icao_code: "GAMB",
    iata_country_code: "ML",
    iata_code: "MZI",
    city_name: "Mopti",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Morafenobe Airport",
    longitude: 45.288118,
    latitude: -18.773177,
    id: "arp_tva_mg",
    icao_code: "FMMR",
    iata_country_code: "MG",
    iata_code: "TVA",
    city_name: "Morafenobe",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Moramba Airport",
    longitude: 49.1,
    latitude: -12.4333,
    id: "arp_wor_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "WOR",
    city_name: "Ankorefo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Moranbah Airport",
    longitude: 148.075833,
    latitude: -22.058621,
    id: "arp_mov_au",
    icao_code: "YMRB",
    iata_country_code: "AU",
    iata_code: "MOV",
    city_name: "Moranbah",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Mora–Siljan Airport",
    longitude: 14.51079,
    latitude: 60.95837,
    id: "arp_mxx_se",
    icao_code: "ESKM",
    iata_country_code: "SE",
    iata_code: "MXX",
    city_name: "Mora",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Morawa Airport",
    longitude: 116.022366,
    latitude: -29.202297,
    id: "arp_mwb_au",
    icao_code: "YMRW",
    iata_country_code: "AU",
    iata_code: "MWB",
    city_name: "Morawa",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Moree Airport",
    longitude: 149.847595,
    latitude: -29.497622,
    id: "arp_mrz_au",
    icao_code: "YMOR",
    iata_country_code: "AU",
    iata_code: "MRZ",
    city_name: "Moree",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Morehead Airport",
    longitude: 141.647348,
    latitude: -8.714788,
    id: "arp_mhy_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MHY",
    city_name: "Morehead",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Morelia International Airport",
    longitude: -101.026569,
    latitude: 19.848182,
    id: "arp_mlm_mx",
    icao_code: "MMMM",
    iata_country_code: "MX",
    iata_code: "MLM",
    city_name: "Morelia",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Moreton Airport",
    longitude: 142.637154,
    latitude: -12.442669,
    id: "arp_met_au",
    icao_code: "YMOT",
    iata_country_code: "AU",
    iata_code: "MET",
    city_name: "Moreton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Morgantown Municipal Airport",
    longitude: -79.918309,
    latitude: 39.644097,
    id: "arp_mgw_us",
    icao_code: "KMGW",
    iata_country_code: "US",
    iata_code: "MGW",
    city_name: "Morgantown",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Morichal Airport",
    longitude: -69.9167,
    latitude: 1.75,
    id: "arp_mhf_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "MHF",
    city_name: "Morichal",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Morlaix Ploujean Airport",
    longitude: -3.8162,
    latitude: 48.601525,
    id: "arp_mxn_fr",
    icao_code: "LFRU",
    iata_country_code: "FR",
    iata_code: "MXN",
    city_name: "Morlaix",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Morney Airport",
    longitude: 141.433127,
    latitude: -25.358517,
    id: "arp_oxy_au",
    icao_code: "YMNY",
    iata_country_code: "AU",
    iata_code: "OXY",
    city_name: "Morney",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mornington Island Airport",
    longitude: 139.175312,
    latitude: -16.662587,
    id: "arp_ong_au",
    icao_code: "YMTI",
    iata_country_code: "AU",
    iata_code: "ONG",
    city_name: "Mornington Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Moro Airport",
    longitude: 143.234644,
    latitude: -6.362864,
    id: "arp_mxh_pg",
    icao_code: "AYMR",
    iata_country_code: "PG",
    iata_code: "MXH",
    city_name: "Moro",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Moroak Airport",
    longitude: 133.699539,
    latitude: -14.817496,
    id: "arp_mrt_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MRT",
    city_name: "Moroak",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Morobe Airport",
    longitude: 147.583333,
    latitude: -7.75,
    id: "arp_obm_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OBM",
    city_name: "Morobe",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Morombe Airport",
    longitude: 43.375493,
    latitude: -21.753409,
    id: "arp_mxm_mg",
    icao_code: "FMSR",
    iata_country_code: "MG",
    iata_code: "MXM",
    city_name: "Morombe",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Moron Air Base",
    longitude: -5.615236,
    latitude: 37.174791,
    id: "arp_ozp_es",
    icao_code: "LEMO",
    iata_country_code: "ES",
    iata_code: "OZP",
    city_name: "Moron",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Mörön Airport",
    longitude: 100.097498,
    latitude: 49.663761,
    id: "arp_mxv_mn",
    icao_code: "ZMMN",
    iata_country_code: "MN",
    iata_code: "MXV",
    city_name: "Mörön",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Morondava Airport",
    longitude: 44.317233,
    latitude: -20.285929,
    id: "arp_moq_mg",
    icao_code: "FMMV",
    iata_country_code: "MG",
    iata_code: "MOQ",
    city_name: "Morondava",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Morowali Airport",
    longitude: 121.663881,
    latitude: -2.201577,
    id: "arp_moh_id",
    icao_code: "WAFO",
    iata_country_code: "ID",
    iata_code: "MOH",
    city_name: "Morowali",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Morris Municipal Airport",
    longitude: -88.420318,
    latitude: 41.424725,
    id: "arp_mox_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MOX",
    city_name: "Morris",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Morristown Municipal Airport",
    longitude: -74.416726,
    latitude: 40.798254,
    id: "arp_mmu_us",
    icao_code: "KMMU",
    iata_country_code: "US",
    iata_code: "MMU",
    city_name: "Morristown",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Morrisville–Stowe State Airport",
    longitude: -72.614105,
    latitude: 44.535498,
    id: "arp_mvl_us",
    icao_code: "KMVL",
    iata_country_code: "US",
    iata_code: "MVL",
    city_name: "Morrisville",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Moruya Airport",
    longitude: 150.145478,
    latitude: -35.900168,
    id: "arp_mya_au",
    icao_code: "YMRY",
    iata_country_code: "AU",
    iata_code: "MYA",
    city_name: "Moruya",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Moser Bay Seaplane Base",
    longitude: -154.144492,
    latitude: 57.025732,
    id: "arp_kmy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KMY",
    city_name: "Moser Bay",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Moses Point Airport",
    longitude: -162.267894,
    latitude: 64.616737,
    id: "arp_mos_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MOS",
    city_name: "Moses Point",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Moshoeshoe I International Airport",
    longitude: 27.555778,
    latitude: -29.45888,
    id: "arp_msu_ls",
    icao_code: "FXMM",
    iata_country_code: "LS",
    iata_code: "MSU",
    city_name: "Maseru",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Mosjøen Airport, Kjærstad",
    longitude: 13.214934,
    latitude: 65.78404,
    id: "arp_mjf_no",
    icao_code: "ENMS",
    iata_country_code: "NO",
    iata_code: "MJF",
    city_name: "Mosjøe",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Mosquera Airport",
    longitude: -74.140053,
    latitude: 4.69962,
    id: "arp_mqr_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "MQR",
    city_name: "Mosquera",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Moss Airport, Rygge",
    longitude: 10.788808,
    latitude: 59.378759,
    id: "arp_ryg_no",
    icao_code: "ENRY",
    iata_country_code: "NO",
    iata_code: "RYG",
    city_name: "Oslo",
    city: {
      name: "Oslo",
      id: "cit_osl_no",
      iata_country_code: "NO",
      iata_code: "OSL",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Mossel Bay Airport",
    longitude: 22.059661,
    latitude: -34.158543,
    id: "arp_mzy_za",
    icao_code: "FAMO",
    iata_country_code: "ZA",
    iata_code: "MZY",
    city_name: "Mossel Bay",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Mossendjo Airport",
    longitude: 12.697882,
    latitude: -2.946185,
    id: "arp_msx_cg",
    icao_code: "FCMM",
    iata_country_code: "CG",
    iata_code: "MSX",
    city_name: "Mossendjo",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Mostaganem Airport",
    longitude: 0.148065,
    latitude: 35.908057,
    id: "arp_mqv_dz",
    icao_code: null,
    iata_country_code: "DZ",
    iata_code: "MQV",
    city_name: "Mostaganem",
    city: null,
  },
  {
    time_zone: "Europe/Sarajevo",
    name: "Mostar International Airport",
    longitude: 17.842915,
    latitude: 43.284727,
    id: "arp_omo_ba",
    icao_code: "LQMO",
    iata_country_code: "BA",
    iata_code: "OMO",
    city_name: "Mostar",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Mosteiros Airport",
    longitude: -24.339807,
    latitude: 15.045153,
    id: "arp_mti_cv",
    icao_code: "GVMT",
    iata_country_code: "CV",
    iata_code: "MTI",
    city_name: "Mosteiros",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Mostépha Ben Boulaid Airport",
    longitude: 6.308716,
    latitude: 35.754733,
    id: "arp_blj_dz",
    icao_code: "DABT",
    iata_country_code: "DZ",
    iata_code: "BLJ",
    city_name: "Batna",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Mosul International Airport",
    longitude: 43.1474,
    latitude: 36.305801,
    id: "arp_osm_iq",
    icao_code: "ORBM",
    iata_country_code: "IQ",
    iata_code: "OSM",
    city_name: "Mosul",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Mota Airport",
    longitude: 37.8833,
    latitude: 11.0667,
    id: "arp_ota_et",
    icao_code: null,
    iata_country_code: "ET",
    iata_code: "OTA",
    city_name: "Mota",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Mota Lava Airport",
    longitude: 167.712944,
    latitude: -13.668613,
    id: "arp_mtv_vu",
    icao_code: "NVSA",
    iata_country_code: "VU",
    iata_code: "MTV",
    city_name: "Mota Lava",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Motswari Airport",
    longitude: 31.385974,
    latitude: -24.187995,
    id: "arp_mwr_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "MWR",
    city_name: "Motswari",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Motueka Airport",
    longitude: 172.991445,
    latitude: -41.123268,
    id: "arp_mzp_nz",
    icao_code: "NZMK",
    iata_country_code: "NZ",
    iata_code: "MZP",
    city_name: "Motueka",
    city: null,
  },
  {
    time_zone: "Africa/Djibouti",
    name: "Moucha Airport",
    longitude: 43.158791,
    latitude: 11.547138,
    id: "arp_mhi_dj",
    icao_code: "HDMO",
    iata_country_code: "DJ",
    iata_code: "MHI",
    city_name: "Moucha Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mougulu Airport",
    longitude: 142.420754,
    latitude: -6.280774,
    id: "arp_guv_pg",
    icao_code: "AYML",
    iata_country_code: "PG",
    iata_code: "GUV",
    city_name: "Mougulu",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Mouilla Ville Airport",
    longitude: 11.056699,
    latitude: -1.845139,
    id: "arp_mjl_ga",
    icao_code: "FOGM",
    iata_country_code: "GA",
    iata_code: "MJL",
    city_name: "Mouila",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Moulay Ali Cherif Airport",
    longitude: -4.399038,
    latitude: 31.946293,
    id: "arp_erh_ma",
    icao_code: "GMFK",
    iata_country_code: "MA",
    iata_code: "ERH",
    city_name: "Errachidia",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Mould Bay Airport",
    longitude: -119.328169,
    latitude: 76.237474,
    id: "arp_ymd_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YMD",
    city_name: "Mould Bay",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Moultrie Municipal Airport",
    longitude: -83.80387,
    latitude: 31.085068,
    id: "arp_mgr_us",
    icao_code: "KMGR",
    iata_country_code: "US",
    iata_code: "MGR",
    city_name: "Moultrie",
    city: {
      name: "Moultrie",
      id: "cit_mgr_us",
      iata_country_code: "US",
      iata_code: "MGR",
    },
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Moundou Airport",
    longitude: 16.071308,
    latitude: 8.62252,
    id: "arp_mqq_td",
    icao_code: "FTTD",
    iata_country_code: "TD",
    iata_code: "MQQ",
    city_name: "Moundou",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Mountain Home Air Force Base",
    longitude: -115.868661,
    latitude: 43.04387,
    id: "arp_muo_us",
    icao_code: "KMUO",
    iata_country_code: "US",
    iata_code: "MUO",
    city_name: "Mountain Home",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Mountain Village Airport",
    longitude: -163.682093,
    latitude: 62.095427,
    id: "arp_mou_us",
    icao_code: "PAMO",
    iata_country_code: "US",
    iata_code: "MOU",
    city_name: "Mountain Village",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mount Aue Airport",
    longitude: 144.663593,
    latitude: -6.229789,
    id: "arp_uae_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UAE",
    city_name: "Mount Aue",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mount Barnett Airport",
    longitude: 125.959027,
    latitude: -16.65883,
    id: "arp_mbn_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MBN",
    city_name: "Mount Barnett",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Mount Buffalo Airport",
    longitude: 146.889379,
    latitude: -36.719432,
    id: "arp_mbf_au",
    icao_code: "YPOK",
    iata_country_code: "AU",
    iata_code: "MBF",
    city_name: "Mount Buffalo",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mount Cavenagh Airport",
    longitude: 133.2,
    latitude: -25.9667,
    id: "arp_mkv_au",
    icao_code: "YMVG",
    iata_country_code: "AU",
    iata_code: "MKV",
    city_name: "Mount Cavenagh",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Mount Cook Airport",
    longitude: 170.133944,
    latitude: -43.765586,
    id: "arp_mon_nz",
    icao_code: "NZMC",
    iata_country_code: "NZ",
    iata_code: "MON",
    city_name: "Mount Cook",
    city: {
      name: "Mount Cook",
      id: "cit_mon_nz",
      iata_country_code: "NZ",
      iata_code: "MON",
    },
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Mount Etjo Airport",
    longitude: 16.452911,
    latitude: -21.022732,
    id: "arp_mjo_na",
    icao_code: "FYME",
    iata_country_code: "NA",
    iata_code: "MJO",
    city_name: "Mount Etjo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mount Full Stop Airport",
    longitude: 144.899981,
    latitude: -19.666697,
    id: "arp_mfl_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MFL",
    city_name: "Wando Vale",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Mount Gambier Airport",
    longitude: 140.784312,
    latitude: -37.745353,
    id: "arp_mgb_au",
    icao_code: "YMTG",
    iata_country_code: "AU",
    iata_code: "MGB",
    city_name: "Mount Gambier",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mount Gordon Airport",
    longitude: 139.406398,
    latitude: -19.772971,
    id: "arp_gpd_au",
    icao_code: "YGON",
    iata_country_code: "AU",
    iata_code: "GPD",
    city_name: "Mount Gordon Mine",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Mount Gunson Airport",
    longitude: 137.17357,
    latitude: -31.459939,
    id: "arp_gsn_au",
    icao_code: "YMGN",
    iata_country_code: "AU",
    iata_code: "GSN",
    city_name: "Mount Gunson",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mount Hagen Airport",
    longitude: 144.297753,
    latitude: -5.827444,
    id: "arp_hgu_pg",
    icao_code: "AYMH",
    iata_country_code: "PG",
    iata_code: "HGU",
    city_name: "Mount Hagen",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Mount Hotham Airport",
    longitude: 147.33582,
    latitude: -37.047967,
    id: "arp_mhu_au",
    icao_code: "YHOT",
    iata_country_code: "AU",
    iata_code: "MHU",
    city_name: "Mount Hotham",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mount House Airport",
    longitude: 125.710798,
    latitude: -17.054402,
    id: "arp_mho_au",
    icao_code: "YMHO",
    iata_country_code: "AU",
    iata_code: "MHO",
    city_name: "Mount House",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Mount Isa Airport",
    longitude: 139.490122,
    latitude: -20.666,
    id: "arp_isa_au",
    icao_code: "YBMA",
    iata_country_code: "AU",
    iata_code: "ISA",
    city_name: "Mount Isa",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mount Keith Airport",
    longitude: 120.550449,
    latitude: -27.284873,
    id: "arp_wme_au",
    icao_code: "YMNE",
    iata_country_code: "AU",
    iata_code: "WME",
    city_name: "Mount Keith",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mount Magnet Airport",
    longitude: 117.840993,
    latitude: -28.116579,
    id: "arp_mmg_au",
    icao_code: "YMOG",
    iata_country_code: "AU",
    iata_code: "MMG",
    city_name: "Mount Magnet",
    city: null,
  },
  {
    time_zone: "Atlantic/Stanley",
    name: "Mount Pleasant Airport",
    longitude: -58.451525,
    latitude: -51.822114,
    id: "arp_mpn_fk",
    icao_code: "EGYP",
    iata_country_code: "FK",
    iata_code: "MPN",
    city_name: "Mount Pleasant",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Mount Pleasant Airport",
    longitude: -111.473396,
    latitude: 39.528265,
    id: "arp_msd_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MSD",
    city_name: "Mount Pleasant",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Mount Pleasant Municipal Airport",
    longitude: -84.738021,
    latitude: 43.620353,
    id: "arp_mop_us",
    icao_code: "KMOP",
    iata_country_code: "US",
    iata_code: "MOP",
    city_name: "Mount Pleasant",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mount Pleasant Municipal Airport",
    longitude: -91.511635,
    latitude: 40.947308,
    id: "arp_mpz_us",
    icao_code: "KMPZ",
    iata_country_code: "US",
    iata_code: "MPZ",
    city_name: "Mount Pleasant",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mount Pleasant Regional Airport",
    longitude: -79.782907,
    latitude: 32.898536,
    id: "arp_mps_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MPS",
    city_name: "Mount Pleasant",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mount Sandford Station Airport",
    longitude: 130.555388,
    latitude: -16.977239,
    id: "arp_mtd_au",
    icao_code: "YMSF",
    iata_country_code: "AU",
    iata_code: "MTD",
    city_name: "Mount Sandford Station",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Mount Shasta Airport",
    longitude: -122.317,
    latitude: 41.3167,
    id: "arp_mhs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MHS",
    city_name: "Mount Shasta",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mount Swan Airport",
    longitude: 135.031368,
    latitude: -22.585009,
    id: "arp_msf_au",
    icao_code: "YMNS",
    iata_country_code: "AU",
    iata_code: "MSF",
    city_name: "Mount Swan",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mount Valley Airport",
    longitude: 133.816261,
    latitude: -14.0833,
    id: "arp_mnv_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MNV",
    city_name: "Mount Valley",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Mount Vernon Airport",
    longitude: -88.853496,
    latitude: 38.321935,
    id: "arp_mvn_us",
    icao_code: "KMVN",
    iata_country_code: "US",
    iata_code: "MVN",
    city_name: "Mount Vernon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Mount Washington Regional Airport",
    longitude: -71.545141,
    latitude: 44.36741,
    id: "arp_hie_us",
    icao_code: "KHIE",
    iata_country_code: "US",
    iata_code: "HIE",
    city_name: "Whitefield",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Mouyondzi Airport",
    longitude: 13.966724,
    latitude: -4.014817,
    id: "arp_muy_cg",
    icao_code: "FCBM",
    iata_country_code: "CG",
    iata_code: "MUY",
    city_name: "Mouyondzi",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Moyale Airport",
    longitude: 39.10153,
    latitude: 3.469767,
    id: "arp_oyl_ke",
    icao_code: "HKMY",
    iata_country_code: "KE",
    iata_code: "OYL",
    city_name: "Moyale",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Moyo Airport",
    longitude: 31.764375,
    latitude: 3.648538,
    id: "arp_oyg_ug",
    icao_code: null,
    iata_country_code: "UG",
    iata_code: "OYG",
    city_name: "Moyo",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Moyobamba Airport",
    longitude: -76.9875,
    latitude: -6.020833,
    id: "arp_mbp_pe",
    icao_code: "SPBB",
    iata_country_code: "PE",
    iata_code: "MBP",
    city_name: "Moyobamba",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Mpanda Airport",
    longitude: 31.078648,
    latitude: -6.355392,
    id: "arp_npy_tz",
    icao_code: null,
    iata_country_code: "TZ",
    iata_code: "NPY",
    city_name: "Mpanda",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Mthatha Airport",
    longitude: 28.673154,
    latitude: -31.547023,
    id: "arp_utt_za",
    icao_code: "FAUT",
    iata_country_code: "ZA",
    iata_code: "UTT",
    city_name: "Mthatha",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Mtwara Airport",
    longitude: 40.181872,
    latitude: -10.337597,
    id: "arp_myw_tz",
    icao_code: "HTMT",
    iata_country_code: "TZ",
    iata_code: "MYW",
    city_name: "Mtwara",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Muanda Airport",
    longitude: 12.352289,
    latitude: -5.928902,
    id: "arp_mnb_cd",
    icao_code: "FZAG",
    iata_country_code: "CD",
    iata_code: "MNB",
    city_name: "Muanda",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Muan International Airport",
    longitude: 126.385329,
    latitude: 34.992495,
    id: "arp_mwx_kr",
    icao_code: "RKJB",
    iata_country_code: "KR",
    iata_code: "MWX",
    city_name: "Muan",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Muara Bungo Airport",
    longitude: 102.177591,
    latitude: -1.54306,
    id: "arp_buu_id",
    icao_code: "WIPI",
    iata_country_code: "ID",
    iata_code: "BUU",
    city_name: "Muara Bungo",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Muccan Station Airport",
    longitude: 120.069109,
    latitude: -20.658727,
    id: "arp_muq_au",
    icao_code: "YMUC",
    iata_country_code: "AU",
    iata_code: "MUQ",
    city_name: "Muccan Station",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Mucuri Airport",
    longitude: -39.8642,
    latitude: -18.0489,
    id: "arp_mvs_br",
    icao_code: "SNMU",
    iata_country_code: "BR",
    iata_code: "MVS",
    city_name: "Mucuri",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Mudanjiang Hailang International Airport",
    longitude: 129.568944,
    latitude: 44.524134,
    id: "arp_mdg_cn",
    icao_code: "ZYMD",
    iata_country_code: "CN",
    iata_code: "MDG",
    city_name: "Mudanjiang",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Mudgee Airport",
    longitude: 149.61329,
    latitude: -32.563132,
    id: "arp_dge_au",
    icao_code: "YMDG",
    iata_country_code: "AU",
    iata_code: "DGE",
    city_name: "Mudgee",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Mueda Airport",
    longitude: 39.563098,
    latitude: -11.6729,
    id: "arp_mud_mz",
    icao_code: "FQMD",
    iata_country_code: "MZ",
    iata_code: "MUD",
    city_name: "Mueda",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Mueo Airport",
    longitude: 164.999496,
    latitude: -21.316534,
    id: "arp_pdc_nc",
    icao_code: "NWWQ",
    iata_country_code: "NC",
    iata_code: "PDC",
    city_name: "Mueo",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Mui Airport",
    longitude: 35.748885,
    latitude: 5.86383,
    id: "arp_muj_et",
    icao_code: "HAMR",
    iata_country_code: "ET",
    iata_code: "MUJ",
    city_name: "Mui",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Muir Army Airfield",
    longitude: -76.568502,
    latitude: 40.435245,
    id: "arp_mui_us",
    icao_code: "KMUI",
    iata_country_code: "US",
    iata_code: "MUI",
    city_name: "Fort Indiantown Gap",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Mukah Airport",
    longitude: 112.079638,
    latitude: 2.906654,
    id: "arp_mkm_my",
    icao_code: "WBGK",
    iata_country_code: "MY",
    iata_code: "MKM",
    city_name: "Mukah",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Mukalla International Airport",
    longitude: 49.375489,
    latitude: 14.664558,
    id: "arp_riy_ye",
    icao_code: "OYRN",
    iata_country_code: "YE",
    iata_code: "RIY",
    city_name: "Mukalla",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Mukeiras Airport",
    longitude: 45.656017,
    latitude: 13.936371,
    id: "arp_ukr_ye",
    icao_code: "OYMK",
    iata_country_code: "YE",
    iata_code: "UKR",
    city_name: "Mukayras",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Mukhaizna Airport",
    longitude: 56.400984,
    latitude: 19.389121,
    id: "arp_ukh_om",
    icao_code: "OOMK",
    iata_country_code: "OM",
    iata_code: "UKH",
    city_name: "Mukhaizna",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Muko-Muko Airport",
    longitude: 101.088514,
    latitude: -2.542683,
    id: "arp_mpc_id",
    icao_code: "WIPU",
    iata_country_code: "ID",
    iata_code: "MPC",
    city_name: "Muko-Muko",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Mulatos Airport",
    longitude: -76.718008,
    latitude: 8.636919,
    id: "arp_uls_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "ULS",
    city_name: "Mulatos",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Mulatupo Airport",
    longitude: -77.86667,
    latitude: 9.0,
    id: "arp_mpp_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "MPP",
    city_name: "Mulatupo",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Mulegé Airstrip",
    longitude: -111.969998,
    latitude: 26.904436,
    id: "arp_mug_mx",
    icao_code: "MMMG",
    iata_country_code: "MX",
    iata_code: "MUG",
    city_name: "Mulege",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Mulga Park Airport",
    longitude: 131.650084,
    latitude: -25.85991,
    id: "arp_mup_au",
    icao_code: "YMUP",
    iata_country_code: "AU",
    iata_code: "MUP",
    city_name: "Mulga Park",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Mulia Airport",
    longitude: 137.957,
    latitude: -3.7018,
    id: "arp_lii_id",
    icao_code: "WAJM",
    iata_country_code: "ID",
    iata_code: "LII",
    city_name: "Mulia",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Mulika Lodge Airport",
    longitude: 38.195141,
    latitude: 0.165083,
    id: "arp_jjm_ke",
    icao_code: "HKMK",
    iata_country_code: "KE",
    iata_code: "JJM",
    city_name: "Meru-Kinna",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Mulka Airport",
    longitude: 138.650001,
    latitude: -28.348009,
    id: "arp_mvk_au",
    icao_code: "YMUK",
    iata_country_code: "AU",
    iata_code: "MVK",
    city_name: "Mulka",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Mull Airport",
    longitude: -5.911173,
    latitude: 56.517576,
    id: "arp_ull_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "ULL",
    city_name: "Mull",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Mullewa Airport",
    longitude: 115.517746,
    latitude: -28.474992,
    id: "arp_mxu_au",
    icao_code: "YMWA",
    iata_country_code: "AU",
    iata_code: "MXU",
    city_name: "Mullewa",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Multan International Airport",
    longitude: 71.421492,
    latitude: 30.199644,
    id: "arp_mux_pk",
    icao_code: "OPMT",
    iata_country_code: "PK",
    iata_code: "MUX",
    city_name: "Multan",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Mulu Airport",
    longitude: 114.808001,
    latitude: 4.048739,
    id: "arp_mzv_my",
    icao_code: "WBMU",
    iata_country_code: "MY",
    iata_code: "MZV",
    city_name: "Mulu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Munbil Airport",
    longitude: 147.218707,
    latitude: -9.442579,
    id: "arp_lnf_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "LNF",
    city_name: "Munbil",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Munda Airport",
    longitude: 157.263028,
    latitude: -8.327456,
    id: "arp_mua_sb",
    icao_code: "AGGM",
    iata_country_code: "SB",
    iata_code: "MUA",
    city_name: "Munda",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Mundo Maya International Airport",
    longitude: -89.866267,
    latitude: 16.913768,
    id: "arp_frs_gt",
    icao_code: "MGTK",
    iata_country_code: "GT",
    iata_code: "FRS",
    city_name: "Flores",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Munduku Airport",
    longitude: 143.433,
    latitude: -4.617,
    id: "arp_mdm_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MDM",
    city_name: "Munduku",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Mungeranie Airport",
    longitude: 138.65695,
    latitude: -28.011417,
    id: "arp_mne_au",
    icao_code: "YMUG",
    iata_country_code: "AU",
    iata_code: "MNE",
    city_name: "Mungeranie",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Munich Airport",
    longitude: 11.780563,
    latitude: 48.353732,
    id: "arp_muc_de",
    icao_code: "EDDM",
    iata_country_code: "DE",
    iata_code: "MUC",
    city_name: "Munich",
    city: {
      name: "Munich",
      id: "cit_muc_de",
      iata_country_code: "DE",
      iata_code: "MUC",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Municipal José Figueiredo Airport",
    longitude: -46.662487,
    latitude: -20.732281,
    id: "arp_psw_br",
    icao_code: "SNOS",
    iata_country_code: "BR",
    iata_code: "PSW",
    city_name: "Passos",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Münster Osnabrück International Airport",
    longitude: 7.688513,
    latitude: 52.133243,
    id: "arp_fmo_de",
    icao_code: "EDDG",
    iata_country_code: "DE",
    iata_code: "FMO",
    city_name: "Münster",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Murmansk Airport",
    longitude: 32.754949,
    latitude: 68.783301,
    id: "arp_mmk_ru",
    icao_code: "ULMM",
    iata_country_code: "RU",
    iata_code: "MMK",
    city_name: "Murmansk",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Murray-Calloway County Airport",
    longitude: -88.371475,
    latitude: 36.663897,
    id: "arp_cey_us",
    icao_code: "KCEY",
    iata_country_code: "US",
    iata_code: "CEY",
    city_name: "Murray",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Murray Field",
    longitude: -124.113665,
    latitude: 40.803746,
    id: "arp_eka_us",
    icao_code: "KEKA",
    iata_country_code: "US",
    iata_code: "EKA",
    city_name: "Eureka",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Murray Island Airport",
    longitude: 144.054478,
    latitude: -9.915482,
    id: "arp_myi_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "MYI",
    city_name: "Murray Island",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Murrin Murrin Airport",
    longitude: 121.889058,
    latitude: -28.707625,
    id: "arp_wui_au",
    icao_code: "YMMI",
    iata_country_code: "AU",
    iata_code: "WUI",
    city_name: "Murrin Murrin",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Murtala Muhammed International Airport",
    longitude: 3.321151,
    latitude: 6.578852,
    id: "arp_los_ng",
    icao_code: "DNMM",
    iata_country_code: "NG",
    iata_code: "LOS",
    city_name: "Lagos",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Mururoa Atoll Airport",
    longitude: -138.820339,
    latitude: -21.85901,
    id: "arp_uoa_pf",
    icao_code: "NTTX",
    iata_country_code: "PF",
    iata_code: "UOA",
    city_name: "Moruroa Atoll",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Muş Airport",
    longitude: 41.656339,
    latitude: 38.749659,
    id: "arp_msr_tr",
    icao_code: "LTCK",
    iata_country_code: "TR",
    iata_code: "MSR",
    city_name: "Muş",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Muscatine Municipal Airport",
    longitude: -91.146911,
    latitude: 41.3644,
    id: "arp_mut_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MUT",
    city_name: "Muscatine",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Muscat International Airport",
    longitude: 58.284214,
    latitude: 23.596989,
    id: "arp_mct_om",
    icao_code: "OOMS",
    iata_country_code: "OM",
    iata_code: "MCT",
    city_name: "Muscat",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Musgrave Airport",
    longitude: 143.504743,
    latitude: -14.776033,
    id: "arp_mvu_au",
    icao_code: "YMGV",
    iata_country_code: "AU",
    iata_code: "MVU",
    city_name: "Musgrave",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Mushaf Air Base Airport",
    longitude: 72.665001,
    latitude: 32.048599,
    id: "arp_sgi_pk",
    icao_code: "OPSR",
    iata_country_code: "PK",
    iata_code: "SGI",
    city_name: "Sargodha",
    city: {
      name: "Sargodha",
      id: "cit_sgi_pk",
      iata_country_code: "PK",
      iata_code: "SGI",
    },
  },
  {
    time_zone: "America/Detroit",
    name: "Muskegon County Airport",
    longitude: -86.238109,
    latitude: 43.168254,
    id: "arp_mkg_us",
    icao_code: "KMKG",
    iata_country_code: "US",
    iata_code: "MKG",
    city_name: "Muskegon",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Muskogee-Davis Regional Airport",
    longitude: -95.35978,
    latitude: 35.658259,
    id: "arp_mko_us",
    icao_code: "KMKO",
    iata_country_code: "US",
    iata_code: "MKO",
    city_name: "Muskogee",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Muskoka Airport",
    longitude: -79.30533,
    latitude: 44.971218,
    id: "arp_yqa_ca",
    icao_code: "CYQA",
    iata_country_code: "CA",
    iata_code: "YQA",
    city_name: "Muskoka",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Muskrat Dam Airport",
    longitude: -91.762395,
    latitude: 53.441584,
    id: "arp_msa_ca",
    icao_code: "CZMD",
    iata_country_code: "CA",
    iata_code: "MSA",
    city_name: "Muskrat Dam",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Musoma Airport",
    longitude: 33.801584,
    latitude: -1.501036,
    id: "arp_muz_tz",
    icao_code: "HTMU",
    iata_country_code: "TZ",
    iata_code: "MUZ",
    city_name: "Musoma",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Mussau Island Airport",
    longitude: 149.630243,
    latitude: -1.560623,
    id: "arp_mwu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "MWU",
    city_name: "Mussau Island",
    city: null,
  },
  {
    time_zone: "America/St_Vincent",
    name: "Mustique Airport",
    longitude: -61.180122,
    latitude: 12.887846,
    id: "arp_mqs_vc",
    icao_code: "TVSM",
    iata_country_code: "VC",
    iata_code: "MQS",
    city_name: "Mustique Island",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Mutare Airport",
    longitude: 32.627201,
    latitude: -18.997499,
    id: "arp_uta_zw",
    icao_code: "FVMU",
    iata_country_code: "ZW",
    iata_code: "UTA",
    city_name: "Mutare",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Muting Airport",
    longitude: 140.566703,
    latitude: -7.316541,
    id: "arp_muf_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "MUF",
    city_name: "Muting",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Muttaburra Airport",
    longitude: 144.528769,
    latitude: -22.585151,
    id: "arp_utb_au",
    icao_code: "YMTB",
    iata_country_code: "AU",
    iata_code: "UTB",
    city_name: "Muttaburra",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Muzaffarabad Airport",
    longitude: 73.507217,
    latitude: 34.340194,
    id: "arp_mfg_pk",
    icao_code: "OPMF",
    iata_country_code: "PK",
    iata_code: "MFG",
    city_name: "Muzaffarabad",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Muzaffarnagar Airport",
    longitude: 77.7,
    latitude: 29.4833,
    id: "arp_mza_in",
    icao_code: null,
    iata_country_code: "IN",
    iata_code: "MZA",
    city_name: "Mazamari",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Muzaffarpur Airport",
    longitude: 85.313263,
    latitude: 26.118324,
    id: "arp_mzu_in",
    icao_code: "VEMZ",
    iata_country_code: "IN",
    iata_code: "MZU",
    city_name: "Muzaffarpur",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
    longitude: 13.435138,
    latitude: -1.654129,
    id: "arp_mvb_ga",
    icao_code: "FOON",
    iata_country_code: "GA",
    iata_code: "MVB",
    city_name: "Franceville",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Mvuu Camp Airport",
    longitude: 35.297292,
    latitude: -14.84006,
    id: "arp_vuu_mw",
    icao_code: null,
    iata_country_code: "MW",
    iata_code: "VUU",
    city_name: "Liwonde",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Mwadui Airport",
    longitude: 33.616141,
    latitude: -3.519755,
    id: "arp_mwn_tz",
    icao_code: "HTMD",
    iata_country_code: "TZ",
    iata_code: "MWN",
    city_name: "Mwadui",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Mwanza Airport",
    longitude: 32.929928,
    latitude: -2.443598,
    id: "arp_mwz_tz",
    icao_code: "HTMW",
    iata_country_code: "TZ",
    iata_code: "MWZ",
    city_name: "Mwanza",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Mweka Airport",
    longitude: 21.541334,
    latitude: -4.854882,
    id: "arp_mew_cd",
    icao_code: "FZVM",
    iata_country_code: "CD",
    iata_code: "MEW",
    city_name: "Mweka",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Myeik Airport",
    longitude: 98.620824,
    latitude: 12.441778,
    id: "arp_mgz_mm",
    icao_code: "VYME",
    iata_country_code: "MM",
    iata_code: "MGZ",
    city_name: "Myeik",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Myitkyina Airport",
    longitude: 97.352933,
    latitude: 25.383028,
    id: "arp_myt_mm",
    icao_code: "VYMK",
    iata_country_code: "MM",
    iata_code: "MYT",
    city_name: "Myitkyina",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Mykolaiv International Airport",
    longitude: 31.918592,
    latitude: 47.055612,
    id: "arp_nlv_ua",
    icao_code: "UKON",
    iata_country_code: "UA",
    iata_code: "NLV",
    city_name: "Mykolaiv",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Mykonos International Airport",
    longitude: 25.347368,
    latitude: 37.435345,
    id: "arp_jmk_gr",
    icao_code: "LGMK",
    iata_country_code: "GR",
    iata_code: "JMK",
    city_name: "Mykonos",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Myrhorod Airport",
    longitude: 33.638872,
    latitude: 49.933994,
    id: "arp_mxr_ua",
    icao_code: null,
    iata_country_code: "UA",
    iata_code: "MXR",
    city_name: "Myrhorod",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Myrtle Beach International Airport",
    longitude: -78.928096,
    latitude: 33.68095,
    id: "arp_myr_us",
    icao_code: "KMYR",
    iata_country_code: "US",
    iata_code: "MYR",
    city_name: "Myrtle Beach",
    city: {
      name: "Myrtle Beach",
      id: "cit_myr_us",
      iata_country_code: "US",
      iata_code: "MYR",
    },
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Mys-Kamenny Airport",
    longitude: 73.569812,
    latitude: 68.483206,
    id: "arp_ymk_ru",
    icao_code: "USDK",
    iata_country_code: "RU",
    iata_code: "YMK",
    city_name: "Mys-Kamenny",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Mysore Airport",
    longitude: 76.655419,
    latitude: 12.230262,
    id: "arp_myq_in",
    icao_code: "VOMY",
    iata_country_code: "IN",
    iata_code: "MYQ",
    city_name: "Mysore",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Mytilene International Airport",
    longitude: 26.600655,
    latitude: 39.054895,
    id: "arp_mjt_gr",
    icao_code: "LGMT",
    iata_country_code: "GR",
    iata_code: "MJT",
    city_name: "Mytilene",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Mzuzu Airport",
    longitude: 34.011924,
    latitude: -11.444077,
    id: "arp_zzu_mw",
    icao_code: "FWUU",
    iata_country_code: "MW",
    iata_code: "ZZU",
    city_name: "Mzuzu",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Nabire Airport",
    longitude: 135.496424,
    latitude: -3.366409,
    id: "arp_nbx_id",
    icao_code: "WABI",
    iata_country_code: "ID",
    iata_code: "NBX",
    city_name: "Nabire",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Nacala Airport",
    longitude: 40.711642,
    latitude: -14.490294,
    id: "arp_mnc_mz",
    icao_code: "FQNC",
    iata_country_code: "MZ",
    iata_code: "MNC",
    city_name: "Nacala",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Nachingwea Airport",
    longitude: 38.777555,
    latitude: -10.356393,
    id: "arp_nch_tz",
    icao_code: "HTNA",
    iata_country_code: "TZ",
    iata_code: "NCH",
    city_name: "Nachingwea",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Nadi International Airport",
    longitude: 177.443379,
    latitude: -17.754635,
    id: "arp_nan_fj",
    icao_code: "NFFN",
    iata_country_code: "FJ",
    iata_code: "NAN",
    city_name: "Nadi",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Nador International Airport",
    longitude: -3.028065,
    latitude: 34.988922,
    id: "arp_ndr_ma",
    icao_code: "GMMW",
    iata_country_code: "MA",
    iata_code: "NDR",
    city_name: "Nador",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nadunumu Airport",
    longitude: 147.68425,
    latitude: -9.143555,
    id: "arp_ndn_pg",
    icao_code: "AYNC",
    iata_country_code: "PG",
    iata_code: "NDN",
    city_name: "Nadunumu",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Nadym Airport",
    longitude: 72.695645,
    latitude: 65.480435,
    id: "arp_nym_ru",
    icao_code: "USMM",
    iata_country_code: "RU",
    iata_code: "NYM",
    city_name: "Nadym",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Nafoora Airport",
    longitude: 21.589192,
    latitude: 29.21682,
    id: "arp_nfr_ly",
    icao_code: "HLNR",
    iata_country_code: "LY",
    iata_code: "NFR",
    city_name: "Nafoora",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Naga Airport",
    longitude: 123.268651,
    latitude: 13.584837,
    id: "arp_wnp_ph",
    icao_code: "RPUN",
    iata_country_code: "PH",
    iata_code: "WNP",
    city_name: "Naga",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Nagasaki Airport",
    longitude: 129.917269,
    latitude: 32.915461,
    id: "arp_ngs_jp",
    icao_code: "RJFU",
    iata_country_code: "JP",
    iata_code: "NGS",
    city_name: "Nagasaki",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Nagoya Airport",
    longitude: 136.922518,
    latitude: 35.255317,
    id: "arp_nkm_jp",
    icao_code: "RJNA",
    iata_country_code: "JP",
    iata_code: "NKM",
    city_name: "Nagoya",
    city: {
      name: "Nagoya",
      id: "cit_ngo_jp",
      iata_country_code: "JP",
      iata_code: "NGO",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "Naha Airport",
    longitude: 125.52814,
    latitude: 3.683019,
    id: "arp_nah_id",
    icao_code: "WAMH",
    iata_country_code: "ID",
    iata_code: "NAH",
    city_name: "Tahuna",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Naha Airport",
    longitude: 127.647186,
    latitude: 26.208289,
    id: "arp_oka_jp",
    icao_code: "ROAH",
    iata_country_code: "JP",
    iata_code: "OKA",
    city_name: "Okinawa",
    city: {
      name: "Okinawa",
      id: "cit_oka_jp",
      iata_country_code: "JP",
      iata_code: "OKA",
    },
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Nain Airport",
    longitude: -61.682379,
    latitude: 56.549294,
    id: "arp_ydp_ca",
    icao_code: "CYDP",
    iata_country_code: "CA",
    iata_code: "YDP",
    city_name: "Nain",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Najran Regional Airport",
    longitude: 44.419306,
    latitude: 17.613201,
    id: "arp_eam_sa",
    icao_code: "OENG",
    iata_country_code: "SA",
    iata_code: "EAM",
    city_name: "Nejran",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Nakashibetsu Airport",
    longitude: 144.958021,
    latitude: 43.575395,
    id: "arp_shb_jp",
    icao_code: "RJCN",
    iata_country_code: "JP",
    iata_code: "SHB",
    city_name: "Nakashibetsu",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Nakhchivan International Airport",
    longitude: 45.458168,
    latitude: 39.189207,
    id: "arp_naj_az",
    icao_code: "UBBN",
    iata_country_code: "AZ",
    iata_code: "NAJ",
    city_name: "Nakhchivan",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Nakhon Phanom Airport",
    longitude: 104.643406,
    latitude: 17.385108,
    id: "arp_kop_th",
    icao_code: "VTUW",
    iata_country_code: "TH",
    iata_code: "KOP",
    city_name: "Nakhon Phanom",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Nakhon Ratchasima Airport",
    longitude: 102.311528,
    latitude: 14.951875,
    id: "arp_nak_th",
    icao_code: "VTUQ",
    iata_country_code: "TH",
    iata_code: "NAK",
    city_name: "Nakhon Ratchasima",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Nakhon Si Thammarat Airport",
    longitude: 99.942488,
    latitude: 8.539812,
    id: "arp_nst_th",
    icao_code: "VTSF",
    iata_country_code: "TH",
    iata_code: "NST",
    city_name: "Nakhon Si Thammarat",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Nakina Airport",
    longitude: -86.696842,
    latitude: 50.182926,
    id: "arp_yqn_ca",
    icao_code: "CYQN",
    iata_country_code: "CA",
    iata_code: "YQN",
    city_name: "Nakina",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Naknek Airport",
    longitude: -157.021361,
    latitude: 58.734295,
    id: "arp_nnk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NNK",
    city_name: "Naknek",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Nakuru Airport",
    longitude: 36.156571,
    latitude: -0.301196,
    id: "arp_nuu_ke",
    icao_code: "HKNK",
    iata_country_code: "KE",
    iata_code: "NUU",
    city_name: "Nakuru",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Nal Airport",
    longitude: 73.208316,
    latitude: 28.070737,
    id: "arp_bkb_in",
    icao_code: "VIBK",
    iata_country_code: "IN",
    iata_code: "BKB",
    city_name: "Bikaner",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Nalchik Airport",
    longitude: 43.635434,
    latitude: 43.508689,
    id: "arp_nal_ru",
    icao_code: "URMN",
    iata_country_code: "RU",
    iata_code: "NAL",
    city_name: "Nalchik",
    city: null,
  },
  {
    time_zone: "Asia/Tashkent",
    name: "Namangan Airport",
    longitude: 71.573956,
    latitude: 40.982797,
    id: "arp_nma_uz",
    icao_code: "UTFN",
    iata_country_code: "UZ",
    iata_code: "NMA",
    city_name: "Namangan",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Namatanai Airport",
    longitude: 152.438518,
    latitude: -3.669948,
    id: "arp_atn_pg",
    icao_code: "AYNX",
    iata_country_code: "PG",
    iata_code: "ATN",
    city_name: "Namatanai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nambaiyufa Airport",
    longitude: 145.233333,
    latitude: -6.25,
    id: "arp_nba_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NBA",
    city_name: "Nambaiyufa",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Nambucca Heads Airport",
    longitude: 152.982337,
    latitude: -30.675962,
    id: "arp_nbh_au",
    icao_code: "YNHS",
    iata_country_code: "AU",
    iata_code: "NBH",
    city_name: "Nambucca Heads",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Namdrik Airport",
    longitude: 168.125565,
    latitude: 5.632489,
    id: "arp_ndk_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "NDK",
    city_name: "Namdrik Island",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Namibe Airport",
    longitude: 12.14718,
    latitude: -15.260795,
    id: "arp_msz_ao",
    icao_code: "FNMO",
    iata_country_code: "AO",
    iata_code: "MSZ",
    city_name: "Namibe",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Namlea Airport",
    longitude: 127.100094,
    latitude: -3.236061,
    id: "arp_nam_id",
    icao_code: "WAPR",
    iata_country_code: "ID",
    iata_code: "NAM",
    city_name: "Namlea",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Nampula Airport",
    longitude: 39.2819,
    latitude: -15.104846,
    id: "arp_apl_mz",
    icao_code: "FQNP",
    iata_country_code: "MZ",
    iata_code: "APL",
    city_name: "Nampula",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Namrole Airport",
    longitude: 126.7012,
    latitude: -3.8548,
    id: "arp_nre_id",
    icao_code: "WAPG",
    iata_country_code: "ID",
    iata_code: "NRE",
    city_name: "Namrole",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Namsang Airport",
    longitude: 97.735639,
    latitude: 20.890623,
    id: "arp_nms_mm",
    icao_code: "VYNS",
    iata_country_code: "MM",
    iata_code: "NMS",
    city_name: "Namsang",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Namsos Airport",
    longitude: 11.574709,
    latitude: 64.472331,
    id: "arp_osy_no",
    icao_code: "ENNM",
    iata_country_code: "NO",
    iata_code: "OSY",
    city_name: "Namsos",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Namtu Airport",
    longitude: 97.383003,
    latitude: 23.083,
    id: "arp_nmt_mm",
    icao_code: null,
    iata_country_code: "MM",
    iata_code: "NMT",
    city_name: "Namtu",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Namu Airport",
    longitude: -127.867,
    latitude: 51.85,
    id: "arp_znu_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZNU",
    city_name: "Namu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Namudi Airport",
    longitude: 148.329878,
    latitude: -9.473464,
    id: "arp_ndi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NDI",
    city_name: "Namudi",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Namutoni Airport",
    longitude: 16.922504,
    latitude: -18.808926,
    id: "arp_nni_na",
    icao_code: "FYNA",
    iata_country_code: "NA",
    iata_code: "NNI",
    city_name: "Namutoni",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Nana Airport",
    longitude: 162.204557,
    latitude: -10.6756,
    id: "arp_naz_sb",
    icao_code: "AGNA",
    iata_country_code: "SB",
    iata_code: "NAZ",
    city_name: "Star Harbour",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Nanaimo Airport",
    longitude: -123.871312,
    latitude: 49.054213,
    id: "arp_ycd_ca",
    icao_code: "CYCD",
    iata_country_code: "CA",
    iata_code: "YCD",
    city_name: "Nanaimo",
    city: {
      name: "Nanaimo",
      id: "cit_ycd_ca",
      iata_country_code: "CA",
      iata_code: "YCD",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Nanaimo Harbour Water Airport",
    longitude: -123.935461,
    latitude: 49.169415,
    id: "arp_zna_ca",
    icao_code: "CAC8",
    iata_country_code: "CA",
    iata_code: "ZNA",
    city_name: "Nanaimo",
    city: {
      name: "Nanaimo",
      id: "cit_ycd_ca",
      iata_country_code: "CA",
      iata_code: "YCD",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nanchang Changbei International Airport",
    longitude: 115.904628,
    latitude: 28.861841,
    id: "arp_khn_cn",
    icao_code: "ZSCN",
    iata_country_code: "CN",
    iata_code: "KHN",
    city_name: "Nanchang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nanchong Gaoping Airport",
    longitude: 106.164252,
    latitude: 30.791833,
    id: "arp_nao_cn",
    icao_code: "ZUNC",
    iata_country_code: "CN",
    iata_code: "NAO",
    city_name: "Nanchong",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Nancy-Essey Airport",
    longitude: 6.23046,
    latitude: 48.692101,
    id: "arp_enc_fr",
    icao_code: "LFSN",
    iata_country_code: "FR",
    iata_code: "ENC",
    city_name: "Nancy/Metz",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Nanded Airport",
    longitude: 77.335103,
    latitude: 19.182334,
    id: "arp_ndc_in",
    icao_code: "VAND",
    iata_country_code: "IN",
    iata_code: "NDC",
    city_name: "Nanded",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Nangade Airport",
    longitude: 39.67564,
    latitude: -11.072995,
    id: "arp_nnd_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "NND",
    city_name: "Nangade",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Nanga Pinoh Airport",
    longitude: 111.747978,
    latitude: -0.34916,
    id: "arp_npo_id",
    icao_code: "WIOG",
    iata_country_code: "ID",
    iata_code: "NPO",
    city_name: "Nangapinoh",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nanjing Lukou International Airport",
    longitude: 118.871071,
    latitude: 31.730478,
    id: "arp_nkg_cn",
    icao_code: "ZSNJ",
    iata_country_code: "CN",
    iata_code: "NKG",
    city_name: "Nanjing",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nankina Airport",
    longitude: 146.454674,
    latitude: -5.838663,
    id: "arp_nkn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NKN",
    city_name: "Nankina",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Nanki-Shirahama Airport",
    longitude: 135.362248,
    latitude: 33.66225,
    id: "arp_shm_jp",
    icao_code: "RJBD",
    iata_country_code: "JP",
    iata_code: "SHM",
    city_name: "Shirahama",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Nan Nakhon Airport",
    longitude: 100.783109,
    latitude: 18.807856,
    id: "arp_nnt_th",
    icao_code: "VTCN",
    iata_country_code: "TH",
    iata_code: "NNT",
    city_name: "Nan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nanning Wuxu International Airport",
    longitude: 108.171828,
    latitude: 22.608972,
    id: "arp_nng_cn",
    icao_code: "ZGNN",
    iata_country_code: "CN",
    iata_code: "NNG",
    city_name: "Nanning",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nanping Wuyishan Airport",
    longitude: 118.002283,
    latitude: 27.703559,
    id: "arp_wus_cn",
    icao_code: "ZSWY",
    iata_country_code: "CN",
    iata_code: "WUS",
    city_name: "Wuyishan",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Nantes Atlantique Airport",
    longitude: -1.60848,
    latitude: 47.155308,
    id: "arp_nte_fr",
    icao_code: "LFRS",
    iata_country_code: "FR",
    iata_code: "NTE",
    city_name: "Nantes",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nantong Xingdong Airport",
    longitude: 120.975703,
    latitude: 32.070822,
    id: "arp_ntg_cn",
    icao_code: "ZSNT",
    iata_country_code: "CN",
    iata_code: "NTG",
    city_name: "Nantong",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Nantucket Memorial Airport",
    longitude: -70.061977,
    latitude: 41.255026,
    id: "arp_ack_us",
    icao_code: "KACK",
    iata_country_code: "US",
    iata_code: "ACK",
    city_name: "Nantucket",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Nanuque Airport",
    longitude: -40.3288,
    latitude: -17.823677,
    id: "arp_nnu_br",
    icao_code: "SNNU",
    iata_country_code: "BR",
    iata_code: "NNU",
    city_name: "Nanuque",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nanwalek Airport",
    longitude: -151.924316,
    latitude: 59.352793,
    id: "arp_keb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KEB",
    city_name: "Nanwalek",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nanyang Jiangying Airport",
    longitude: 112.615137,
    latitude: 32.980817,
    id: "arp_nny_cn",
    icao_code: "ZHNY",
    iata_country_code: "CN",
    iata_code: "NNY",
    city_name: "Nanyang",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Nanyuki Airport",
    longitude: 37.023651,
    latitude: 0.026333,
    id: "arp_nyk_ke",
    icao_code: "HKNY",
    iata_country_code: "KE",
    iata_code: "NYK",
    city_name: "Nanyuki",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Naoro Airport",
    longitude: 147.621663,
    latitude: -9.254699,
    id: "arp_noo_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NOO",
    city_name: "Naoro",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Napa County Airport",
    longitude: -122.278339,
    latitude: 38.214552,
    id: "arp_apc_us",
    icao_code: "KAPC",
    iata_country_code: "US",
    iata_code: "APC",
    city_name: "Napa",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Napakiak Airport",
    longitude: -161.978248,
    latitude: 60.691834,
    id: "arp_wna_us",
    icao_code: "PANA",
    iata_country_code: "US",
    iata_code: "WNA",
    city_name: "Napakiak",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Napaskiak Airport",
    longitude: -161.773331,
    latitude: 60.70418,
    id: "arp_pka_us",
    icao_code: "PAPK",
    iata_country_code: "US",
    iata_code: "PKA",
    city_name: "Napaskiak",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Naples International Airport",
    longitude: 14.289355,
    latitude: 40.884936,
    id: "arp_nap_it",
    icao_code: "LIRN",
    iata_country_code: "IT",
    iata_code: "NAP",
    city_name: "Naples",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Naples Municipal Airport",
    longitude: -81.775074,
    latitude: 26.151399,
    id: "arp_apf_us",
    icao_code: "KAPF",
    iata_country_code: "US",
    iata_code: "APF",
    city_name: "Naples",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Nappa Merrie Airport",
    longitude: 141.157827,
    latitude: -27.549251,
    id: "arp_nmr_au",
    icao_code: "YNAP",
    iata_country_code: "AU",
    iata_code: "NMR",
    city_name: "Nappa Merrie",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Napperby Airport",
    longitude: 132.763254,
    latitude: -22.531121,
    id: "arp_npp_au",
    icao_code: "YNPB",
    iata_country_code: "AU",
    iata_code: "NPP",
    city_name: "Napperby",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Napuka Airport",
    longitude: -141.266889,
    latitude: -14.176701,
    id: "arp_nau_pf",
    icao_code: "NTGN",
    iata_country_code: "PF",
    iata_code: "NAU",
    city_name: "Napuka",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Naracoorte Airport",
    longitude: 140.725155,
    latitude: -36.983208,
    id: "arp_nac_au",
    icao_code: "YNRC",
    iata_country_code: "AU",
    iata_code: "NAC",
    city_name: "Naracoorte",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Narathiwat Airport",
    longitude: 101.744901,
    latitude: 6.521773,
    id: "arp_naw_th",
    icao_code: "VTSC",
    iata_country_code: "TH",
    iata_code: "NAW",
    city_name: "Narathiwat",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Narganá Airport",
    longitude: -78.588905,
    latitude: 9.444644,
    id: "arp_ngn_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "NGN",
    city_name: "Nargana",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Narita International Airport",
    longitude: 140.39285,
    latitude: 35.773554,
    id: "arp_nrt_jp",
    icao_code: "RJAA",
    iata_country_code: "JP",
    iata_code: "NRT",
    city_name: "Tokyo",
    city: {
      name: "Tokyo",
      id: "cit_tyo_jp",
      iata_country_code: "JP",
      iata_code: "TYO",
    },
  },
  {
    time_zone: "Australia/Sydney",
    name: "Narrabri Airport",
    longitude: 149.828057,
    latitude: -30.318745,
    id: "arp_naa_au",
    icao_code: "YNBR",
    iata_country_code: "AU",
    iata_code: "NAA",
    city_name: "Narrabri",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Narrandera Airport",
    longitude: 146.511969,
    latitude: -34.703736,
    id: "arp_nra_au",
    icao_code: "YNAR",
    iata_country_code: "AU",
    iata_code: "NRA",
    city_name: "Narrandera",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Narrogin Airport",
    longitude: 117.078061,
    latitude: -32.926827,
    id: "arp_nrg_au",
    icao_code: "YNRG",
    iata_country_code: "AU",
    iata_code: "NRG",
    city_name: "Narrogin",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Narsarsuaq Airport",
    longitude: -45.424084,
    latitude: 61.160658,
    id: "arp_uak_gl",
    icao_code: "BGBW",
    iata_country_code: "GL",
    iata_code: "UAK",
    city_name: "Narsarsuaq",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Nartron Field",
    longitude: -85.516414,
    latitude: 43.900026,
    id: "arp_rct_us",
    icao_code: "KRCT",
    iata_country_code: "US",
    iata_code: "RCT",
    city_name: "Reed City",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Narvik Framnes Airport",
    longitude: 17.387391,
    latitude: 68.436447,
    id: "arp_nvk_no",
    icao_code: "ENNK",
    iata_country_code: "NO",
    iata_code: "NVK",
    city_name: "Narvik",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Naryan-Mar Airport",
    longitude: 53.123615,
    latitude: 67.640744,
    id: "arp_nnm_ru",
    icao_code: "ULAM",
    iata_country_code: "RU",
    iata_code: "NNM",
    city_name: "Naryan-Mar",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "NASA Crows Landing Airport",
    longitude: -121.109288,
    latitude: 37.40802,
    id: "arp_nrc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NRC",
    city_name: "Crows Landing",
    city: null,
  },
  {
    time_zone: "Asia/Saigon",
    name: "Na-San Airport",
    longitude: 104.03393,
    latitude: 21.216296,
    id: "arp_sqh_vn",
    icao_code: "VVNS",
    iata_country_code: "VN",
    iata_code: "SQH",
    city_name: "Son-La",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "NAS Fort Worth JRB/Carswell Field",
    longitude: -97.441498,
    latitude: 32.769199,
    id: "arp_fwh_us",
    icao_code: "KNFW",
    iata_country_code: "US",
    iata_code: "FWH",
    city_name: "Fort Worth",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Nashik International Airport",
    longitude: 73.808918,
    latitude: 19.963616,
    id: "arp_isk_in",
    icao_code: "VAOZ",
    iata_country_code: "IN",
    iata_code: "ISK",
    city_name: "Nasik",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Nashua Airport",
    longitude: -71.516615,
    latitude: 42.781021,
    id: "arp_ash_us",
    icao_code: "KASH",
    iata_country_code: "US",
    iata_code: "ASH",
    city_name: "Nashua",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Nashville International Airport",
    longitude: -86.677785,
    latitude: 36.125408,
    id: "arp_bna_us",
    icao_code: "KBNA",
    iata_country_code: "US",
    iata_code: "BNA",
    city_name: "Nashville",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Natadola Airport",
    longitude: 177.316605,
    latitude: -18.083458,
    id: "arp_nta_fj",
    icao_code: null,
    iata_country_code: "FJ",
    iata_code: "NTA",
    city_name: "Natadola",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Natal International Airport",
    longitude: -35.37269,
    latitude: -5.76735,
    id: "arp_nat_br",
    icao_code: "SBSG",
    iata_country_code: "BR",
    iata_code: "NAT",
    city_name: "Natal",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Natashquan Airport",
    longitude: -61.789086,
    latitude: 50.190076,
    id: "arp_yna_ca",
    icao_code: "CYNA",
    iata_country_code: "CA",
    iata_code: "YNA",
    city_name: "Natashquan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Natchez–Adams County Airport",
    longitude: -91.295862,
    latitude: 31.614648,
    id: "arp_hez_us",
    icao_code: "KHEZ",
    iata_country_code: "US",
    iata_code: "HEZ",
    city_name: "Natchez",
    city: null,
  },
  {
    time_zone: "Africa/Porto-Novo",
    name: "Natitingou Airport",
    longitude: 1.360196,
    latitude: 10.37651,
    id: "arp_nae_bj",
    icao_code: "DBBN",
    iata_country_code: "BJ",
    iata_code: "NAE",
    city_name: "Natitingou",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Natuashish Airport",
    longitude: -61.184399,
    latitude: 55.913898,
    id: "arp_ynp_ca",
    icao_code: "CNH2",
    iata_country_code: "CA",
    iata_code: "YNP",
    city_name: "Natuashish",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Naujaat Airport",
    longitude: -86.226616,
    latitude: 66.521831,
    id: "arp_yut_ca",
    icao_code: "CYUT",
    iata_country_code: "CA",
    iata_code: "YUT",
    city_name: "Repulse Bay",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Naukati Bay Seaplane Base",
    longitude: -133.228584,
    latitude: 55.853106,
    id: "arp_nki_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NKI",
    city_name: "Naukiti Bay",
    city: null,
  },
  {
    time_zone: "Pacific/Nauru",
    name: "Nauru International Airport",
    longitude: 166.918679,
    latitude: -0.546792,
    id: "arp_inu_nr",
    icao_code: "ANYN",
    iata_country_code: "NR",
    iata_code: "INU",
    city_name: "Nauru Island",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Nausori International Airport",
    longitude: 178.557684,
    latitude: -18.043336,
    id: "arp_suv_fj",
    icao_code: "NFNA",
    iata_country_code: "FJ",
    iata_code: "SUV",
    city_name: "Suva",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Naval Air Station Corpus Christi Airport",
    longitude: -97.289779,
    latitude: 27.69407,
    id: "arp_ngp_us",
    icao_code: "KNGP",
    iata_country_code: "US",
    iata_code: "NGP",
    city_name: "Corpus Christi",
    city: {
      name: "Corpus Christi",
      id: "cit_crp_us",
      iata_country_code: "US",
      iata_code: "CRP",
    },
  },
  {
    time_zone: "Europe/Rome",
    name: "Naval Air Station Sigonella Airport",
    longitude: 14.911366,
    latitude: 37.407605,
    id: "arp_nsy_it",
    icao_code: "LICZ",
    iata_country_code: "IT",
    iata_code: "NSY",
    city_name: "Sigonella",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Naval Station Mayport",
    longitude: -81.426023,
    latitude: 30.389442,
    id: "arp_nrb_us",
    icao_code: "KNRB",
    iata_country_code: "US",
    iata_code: "NRB",
    city_name: "Mayport",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Naval Station Norfolk Airport",
    longitude: -76.289299,
    latitude: 36.937599,
    id: "arp_ngu_us",
    icao_code: "KNGU",
    iata_country_code: "US",
    iata_code: "NGU",
    city_name: "Norfolk",
    city: {
      name: "Norfolk",
      id: "cit_orf_us",
      iata_country_code: "US",
      iata_code: "ORF",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Navegantes Airport",
    longitude: -48.650558,
    latitude: -26.879984,
    id: "arp_nvt_br",
    icao_code: "SBNF",
    iata_country_code: "BR",
    iata_code: "NVT",
    city_name: "Navegantes",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Navoi International Airport",
    longitude: 65.170799,
    latitude: 40.117198,
    id: "arp_nvi_uz",
    icao_code: "UTSA",
    iata_country_code: "UZ",
    iata_code: "NVI",
    city_name: "Navoi",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Nawabshah Airport",
    longitude: 68.38903,
    latitude: 26.221358,
    id: "arp_wns_pk",
    icao_code: "OPNH",
    iata_country_code: "PK",
    iata_code: "WNS",
    city_name: "Nawabshah",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Naxos Island National Airport",
    longitude: 25.368913,
    latitude: 37.081078,
    id: "arp_jnx_gr",
    icao_code: "LGNX",
    iata_country_code: "GR",
    iata_code: "JNX",
    city_name: "Naxos",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Naypyidaw Airport",
    longitude: 96.201126,
    latitude: 19.623167,
    id: "arp_nyt_mm",
    icao_code: "VYNT",
    iata_country_code: "MM",
    iata_code: "NYT",
    city_name: "Naypyitaw",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "N'dalatando Airport",
    longitude: 14.984095,
    latitude: -9.280492,
    id: "arp_ndf_ao",
    icao_code: null,
    iata_country_code: "AO",
    iata_code: "NDF",
    city_name: "N'dalatando",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "N'Délé Airport",
    longitude: 20.634682,
    latitude: 8.429069,
    id: "arp_ndl_cf",
    icao_code: "FEFN",
    iata_country_code: "CF",
    iata_code: "NDL",
    city_name: "N'Délé",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Ndende Airport",
    longitude: 11.367,
    latitude: -2.4,
    id: "arp_kdn_ga",
    icao_code: "FOGE",
    iata_country_code: "GA",
    iata_code: "KDN",
    city_name: "Ndende",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "N'Djamena International Airport",
    longitude: 15.034044,
    latitude: 12.131458,
    id: "arp_ndj_td",
    icao_code: "FTTJ",
    iata_country_code: "TD",
    iata_code: "NDJ",
    city_name: "N'Djamena",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Ndjolé Ville Airport",
    longitude: 11.0567,
    latitude: -1.84514,
    id: "arp_kdj_ga",
    icao_code: "FOGJ",
    iata_country_code: "GA",
    iata_code: "KDJ",
    city_name: "N'Djolé",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "N'Dolo Airport",
    longitude: 15.326667,
    latitude: -4.326941,
    id: "arp_nlo_cd",
    icao_code: "FZAB",
    iata_country_code: "CD",
    iata_code: "NLO",
    city_name: "Kinshasa",
    city: {
      name: "Kinshasa",
      id: "cit_fih_cd",
      iata_country_code: "CD",
      iata_code: "FIH",
    },
  },
  {
    time_zone: "Europe/Athens",
    name: "Nea Anchialos National Airport",
    longitude: 22.794941,
    latitude: 39.218406,
    id: "arp_vol_gr",
    icao_code: "LGBL",
    iata_country_code: "GR",
    iata_code: "VOL",
    city_name: "Volos",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Necochea Airport",
    longitude: -58.816378,
    latitude: -38.484718,
    id: "arp_nec_ar",
    icao_code: "SAZO",
    iata_country_code: "AR",
    iata_code: "NEC",
    city_name: "Necochea",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Necocli Airport",
    longitude: -76.778078,
    latitude: 8.452567,
    id: "arp_nci_co",
    icao_code: "SKNC",
    iata_country_code: "CO",
    iata_code: "NCI",
    city_name: "Necocli",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Needles Airport",
    longitude: -114.621986,
    latitude: 34.766796,
    id: "arp_eed_us",
    icao_code: "KEED",
    iata_country_code: "US",
    iata_code: "EED",
    city_name: "Needles",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Neftekamsk Airport",
    longitude: 54.34548,
    latitude: 56.107843,
    id: "arp_nef_ru",
    icao_code: "UWUF",
    iata_country_code: "RU",
    iata_code: "NEF",
    city_name: "Neftekamsk",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Nefteyugansk Airport",
    longitude: 72.648397,
    latitude: 61.107116,
    id: "arp_nfg_ru",
    icao_code: "USRN",
    iata_country_code: "RU",
    iata_code: "NFG",
    city_name: "Nefteyugansk",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Negage Airport",
    longitude: 15.287633,
    latitude: -7.754487,
    id: "arp_gxg_ao",
    icao_code: "FNNG",
    iata_country_code: "AO",
    iata_code: "GXG",
    city_name: "Negage",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Negarbo Airport",
    longitude: 144.703058,
    latitude: -6.567778,
    id: "arp_gbf_pg",
    icao_code: "AYNE",
    iata_country_code: "PG",
    iata_code: "GBF",
    city_name: "Negarbo",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Negele Airport",
    longitude: 39.701749,
    latitude: 5.288904,
    id: "arp_egl_et",
    icao_code: "HANG",
    iata_country_code: "ET",
    iata_code: "EGL",
    city_name: "Negele",
    city: null,
  },
  {
    time_zone: "America/Jamaica",
    name: "Negril Airport",
    longitude: -78.334272,
    latitude: 18.341475,
    id: "arp_neg_jm",
    icao_code: "MKNG",
    iata_country_code: "JM",
    iata_code: "NEG",
    city_name: "Negril",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Neil Armstrong Airport",
    longitude: -84.300583,
    latitude: 40.494744,
    id: "arp_axv_us",
    icao_code: "KAXV",
    iata_country_code: "US",
    iata_code: "AXV",
    city_name: "Wapakoneta",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Nejjo Airport",
    longitude: 35.495318,
    latitude: 9.52158,
    id: "arp_nej_et",
    icao_code: "HANJ",
    iata_country_code: "ET",
    iata_code: "NEJ",
    city_name: "Nejo",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Nekemte Airport",
    longitude: 36.599999,
    latitude: 9.05,
    id: "arp_nek_et",
    icao_code: "HANK",
    iata_country_code: "ET",
    iata_code: "NEK",
    city_name: "Nekemte",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Nellis Air Force Base",
    longitude: -115.033997,
    latitude: 36.236198,
    id: "arp_lsv_us",
    icao_code: "KLSV",
    iata_country_code: "US",
    iata_code: "LSV",
    city_name: "Las Vegas",
    city: {
      name: "Las Vegas",
      id: "cit_las_us",
      iata_country_code: "US",
      iata_code: "LAS",
    },
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Nelson Airport",
    longitude: 173.223884,
    latitude: -41.298498,
    id: "arp_nsn_nz",
    icao_code: "NZNS",
    iata_country_code: "NZ",
    iata_code: "NSN",
    city_name: "Nelson",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nelson Lagoon Airport",
    longitude: -161.160524,
    latitude: 56.00767,
    id: "arp_nlg_us",
    icao_code: "PAOU",
    iata_country_code: "US",
    iata_code: "NLG",
    city_name: "Nelson Lagoon",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Nelson Mandela International Airport",
    longitude: -23.486884,
    latitude: 14.94459,
    id: "arp_rai_cv",
    icao_code: "GVNP",
    iata_country_code: "CV",
    iata_code: "RAI",
    city_name: "Praia",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Nelspruit Airport",
    longitude: 30.913842,
    latitude: -25.500023,
    id: "arp_nlp_za",
    icao_code: "FANS",
    iata_country_code: "ZA",
    iata_code: "NLP",
    city_name: "Nelspruit",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Néma Airport",
    longitude: -7.31653,
    latitude: 16.621981,
    id: "arp_emn_mr",
    icao_code: "GQNI",
    iata_country_code: "MR",
    iata_code: "EMN",
    city_name: "Nema",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Nemiscau Airport",
    longitude: -76.135573,
    latitude: 51.691079,
    id: "arp_yns_ca",
    icao_code: "CYHH",
    iata_country_code: "CA",
    iata_code: "YNS",
    city_name: "Nemiscau",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nenana Municipal Airport",
    longitude: -149.073872,
    latitude: 64.547922,
    id: "arp_enn_us",
    icao_code: "PANN",
    iata_country_code: "US",
    iata_code: "ENN",
    city_name: "Nenana",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nenjiang Melgen Airport",
    longitude: 125.328573,
    latitude: 49.23442,
    id: "arp_njj_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "NJJ",
    city_name: "Heihe",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Neom Bay Airport",
    longitude: 35.292747,
    latitude: 27.927671,
    id: "arp_num_sa",
    icao_code: "OENN",
    iata_country_code: "SA",
    iata_code: "NUM",
    city_name: "Neom Bay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Neosho Hugh Robinson Airport",
    longitude: -94.391768,
    latitude: 36.810394,
    id: "arp_eos_us",
    icao_code: "KEOS",
    iata_country_code: "US",
    iata_code: "EOS",
    city_name: "Neosho",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Nepalgunj Airport",
    longitude: 81.667068,
    latitude: 28.103002,
    id: "arp_kep_np",
    icao_code: "VNNG",
    iata_country_code: "NP",
    iata_code: "KEP",
    city_name: "Nepalgunj",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Nephi Municipal Airport",
    longitude: -111.871258,
    latitude: 39.739576,
    id: "arp_nph_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NPH",
    city_name: "Nephi",
    city: null,
  },
  {
    time_zone: "America/Scoresbysund",
    name: "Nerlerit Inaat Airport",
    longitude: -22.64994,
    latitude: 70.742561,
    id: "arp_cnp_gl",
    icao_code: "BGCO",
    iata_country_code: "GL",
    iata_code: "CNP",
    city_name: "Neerlerit Inaat",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Nero-Mer Airport",
    longitude: -6.922932,
    latitude: 4.644546,
    id: "arp_bbv_ci",
    icao_code: "DIGN",
    iata_country_code: "CI",
    iata_code: "BBV",
    city_name: "Grand-Béréby",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Nervino Airport",
    longitude: -120.35471,
    latitude: 39.820035,
    id: "arp_nvn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NVN",
    city_name: "Beckwourth",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Netaji Subhas Chandra Bose International Airport",
    longitude: 88.445636,
    latitude: 22.653129,
    id: "arp_ccu_in",
    icao_code: "VECC",
    iata_country_code: "IN",
    iata_code: "CCU",
    city_name: "Dum Dum/Kolkata",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Neubrandenburg Airport",
    longitude: 13.305892,
    latitude: 53.602058,
    id: "arp_fnb_de",
    icao_code: null,
    iata_country_code: "DE",
    iata_code: "FNB",
    city_name: "Neubrandenburg",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Neumünster Airport",
    longitude: 9.941497,
    latitude: 54.079307,
    id: "arp_eum_de",
    icao_code: "EDHN",
    iata_country_code: "DE",
    iata_code: "EUM",
    city_name: "Neumuenster",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Nevada Municipal Airport",
    longitude: -94.304513,
    latitude: 37.852993,
    id: "arp_nvd_us",
    icao_code: "KNVD",
    iata_country_code: "US",
    iata_code: "NVD",
    city_name: "Nevada",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Nevatim Airbase",
    longitude: 35.012299,
    latitude: 31.2083,
    id: "arp_vtm_il",
    icao_code: "LLNV",
    iata_country_code: "IL",
    iata_code: "VTM",
    city_name: "Nevatim",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Nevers-Fourchambault Airport",
    longitude: 3.112445,
    latitude: 47.001383,
    id: "arp_nvs_fr",
    icao_code: "LFQG",
    iata_country_code: "FR",
    iata_code: "NVS",
    city_name: "Nevers",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Nevşehir Kapadokya Airport",
    longitude: 34.529359,
    latitude: 38.772707,
    id: "arp_nav_tr",
    icao_code: "LTAZ",
    iata_country_code: "TR",
    iata_code: "NAV",
    city_name: "Nevşehir",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Newark Liberty International Airport",
    longitude: -74.171581,
    latitude: 40.691016,
    id: "arp_ewr_us",
    icao_code: "KEWR",
    iata_country_code: "US",
    iata_code: "EWR",
    city_name: "Newark",
    city: {
      name: "New York",
      id: "cit_nyc_us",
      iata_country_code: "US",
      iata_code: "NYC",
    },
  },
  {
    time_zone: "America/New_York",
    name: "New Bedford Regional Airport",
    longitude: -70.957544,
    latitude: 41.675699,
    id: "arp_ewb_us",
    icao_code: "KEWB",
    iata_country_code: "US",
    iata_code: "EWB",
    city_name: "New Bedford",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "New Bight Airport",
    longitude: -75.45446,
    latitude: 24.314637,
    id: "arp_tbi_bs",
    icao_code: "MYCB",
    iata_country_code: "BS",
    iata_code: "TBI",
    city_name: "The Bight",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Newcastle Airport",
    longitude: -1.692157,
    latitude: 55.038114,
    id: "arp_ncl_gb",
    icao_code: "EGNT",
    iata_country_code: "GB",
    iata_code: "NCL",
    city_name: "Newcastle",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Newcastle Airport",
    longitude: 29.977581,
    latitude: -27.770742,
    id: "arp_ncs_za",
    icao_code: "FANC",
    iata_country_code: "ZA",
    iata_code: "NCS",
    city_name: "Newcastle",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Newcastle Airport",
    longitude: 151.835681,
    latitude: -32.794959,
    id: "arp_ntl_au",
    icao_code: "YWLM",
    iata_country_code: "AU",
    iata_code: "NTL",
    city_name: "Newcastle",
    city: {
      name: "Newcastle",
      id: "cit_ntl_au",
      iata_country_code: "AU",
      iata_code: "NTL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "New Castle Airport",
    longitude: -75.605486,
    latitude: 39.6792,
    id: "arp_ilg_us",
    icao_code: "KILG",
    iata_country_code: "US",
    iata_code: "ILG",
    city_name: "Wilmington",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "New Century Aircenter Airport",
    longitude: -94.891384,
    latitude: 38.832971,
    id: "arp_jci_us",
    icao_code: "KIXD",
    iata_country_code: "US",
    iata_code: "JCI",
    city_name: "Olathe",
    city: {
      name: "Kansas City",
      id: "cit_mkc_us",
      iata_country_code: "US",
      iata_code: "MKC",
    },
  },
  {
    time_zone: "Asia/Tokyo",
    name: "New Chitose Airport",
    longitude: 141.691016,
    latitude: 42.777528,
    id: "arp_cts_jp",
    icao_code: "RJCC",
    iata_country_code: "JP",
    iata_code: "CTS",
    city_name: "Sapporo",
    city: {
      name: "Sapporo",
      id: "cit_spk_jp",
      iata_country_code: "JP",
      iata_code: "SPK",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "New Coalinga Municipal Airport",
    longitude: -120.292864,
    latitude: 36.160946,
    id: "arp_clg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "CLG",
    city_name: "Coalinga",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "New Halfa Airport",
    longitude: 35.727928,
    latitude: 15.355494,
    id: "arp_nhf_sd",
    icao_code: "HSNW",
    iata_country_code: "SD",
    iata_code: "NHF",
    city_name: "New Halfa",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "New Ishigaki Airport",
    longitude: 124.245,
    latitude: 24.396389,
    id: "arp_isg_jp",
    icao_code: "ROIG",
    iata_country_code: "JP",
    iata_code: "ISG",
    city_name: "Ishigaki",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "New Laura Airport",
    longitude: 144.448561,
    latitude: -15.556639,
    id: "arp_lut_au",
    icao_code: "YLRS",
    iata_country_code: "AU",
    iata_code: "LUT",
    city_name: "New Laura",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Newman Airport",
    longitude: 119.802342,
    latitude: -23.417157,
    id: "arp_zne_au",
    icao_code: "YNWN",
    iata_country_code: "AU",
    iata_code: "ZNE",
    city_name: "Newman",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "New Moon Airport",
    longitude: 145.772195,
    latitude: -19.199737,
    id: "arp_nmp_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "NMP",
    city_name: "New Moon",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "New Orleans NAS JRB/Alvin Callender Field",
    longitude: -90.023817,
    latitude: 29.831958,
    id: "arp_nbg_us",
    icao_code: "KNBG",
    iata_country_code: "US",
    iata_code: "NBG",
    city_name: "New Orleans",
    city: {
      name: "New Orleans",
      id: "cit_msy_us",
      iata_country_code: "US",
      iata_code: "MSY",
    },
  },
  {
    time_zone: "Pacific/Auckland",
    name: "New Plymouth Airport",
    longitude: 174.178519,
    latitude: -39.009923,
    id: "arp_npl_nz",
    icao_code: "NZNP",
    iata_country_code: "NZ",
    iata_code: "NPL",
    city_name: "New Plymouth",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Newport Municipal Airport",
    longitude: -124.058114,
    latitude: 44.580277,
    id: "arp_onp_us",
    icao_code: "KONP",
    iata_country_code: "US",
    iata_code: "ONP",
    city_name: "Newport",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Newport News/Williamsburg International Airport",
    longitude: -76.492887,
    latitude: 37.131784,
    id: "arp_phf_us",
    icao_code: "KPHF",
    iata_country_code: "US",
    iata_code: "PHF",
    city_name: "Newport News",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Newport State Airport",
    longitude: -72.228237,
    latitude: 44.889367,
    id: "arp_efk_us",
    icao_code: "KEFK",
    iata_country_code: "US",
    iata_code: "EFK",
    city_name: "Newport",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Newport State Airport",
    longitude: -71.280908,
    latitude: 41.532294,
    id: "arp_npt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NPT",
    city_name: "Newport",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "New Richmond Regional Airport",
    longitude: -92.5397,
    latitude: 45.148763,
    id: "arp_rnh_us",
    icao_code: "KRNH",
    iata_country_code: "US",
    iata_code: "RNH",
    city_name: "New Richmond",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "New River Valley Airport",
    longitude: -80.679354,
    latitude: 37.137647,
    id: "arp_psk_us",
    icao_code: "KPSK",
    iata_country_code: "US",
    iata_code: "PSK",
    city_name: "Dublin",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Newry Airport",
    longitude: 129.20397,
    latitude: -16.056047,
    id: "arp_nry_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "NRY",
    city_name: "Newry",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "New Stuyahok Airport",
    longitude: -157.328026,
    latitude: 59.449613,
    id: "arp_knw_us",
    icao_code: "PANW",
    iata_country_code: "US",
    iata_code: "KNW",
    city_name: "New Stuyahok",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "New Tanegashima Airport",
    longitude: 130.991631,
    latitude: 30.605012,
    id: "arp_tne_jp",
    icao_code: "RJFG",
    iata_country_code: "JP",
    iata_code: "TNE",
    city_name: "Tanegashima",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Newtok Airport",
    longitude: -164.638355,
    latitude: 60.937717,
    id: "arp_wwt_us",
    icao_code: "PAEW",
    iata_country_code: "US",
    iata_code: "WWT",
    city_name: "Newtok",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Newton City-County Airport",
    longitude: -97.275898,
    latitude: 38.056819,
    id: "arp_ewk_us",
    icao_code: "KEWK",
    iata_country_code: "US",
    iata_code: "EWK",
    city_name: "Newton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Newton Municipal Airport",
    longitude: -93.022582,
    latitude: 41.67555,
    id: "arp_tnu_us",
    icao_code: "KTNU",
    iata_country_code: "US",
    iata_code: "TNU",
    city_name: "Newton",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "New Ulaanbaatar International Airport",
    longitude: 106.821191,
    latitude: 47.650875,
    id: "arp_ubn_mn",
    icao_code: "ZMCK",
    iata_country_code: "MN",
    iata_code: "UBN",
    city_name: "Ulaanbaatar",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "New Ulm Municipal Airport",
    longitude: -94.502258,
    latitude: 44.319521,
    id: "arp_ulm_us",
    icao_code: "KULM",
    iata_country_code: "US",
    iata_code: "ULM",
    city_name: "New Ulm",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "New York Skyports Seaplane Base Airport",
    longitude: -73.973717,
    latitude: 40.735,
    id: "arp_nys_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NYS",
    city_name: "New York",
    city: {
      name: "New York",
      id: "cit_nyc_us",
      iata_country_code: "US",
      iata_code: "NYC",
    },
  },
  {
    time_zone: "America/New_York",
    name: "New York Stewart International Airport",
    longitude: -74.102724,
    latitude: 41.501292,
    id: "arp_swf_us",
    icao_code: "KSWF",
    iata_country_code: "US",
    iata_code: "SWF",
    city_name: "Newburgh",
    city: {
      name: "New York",
      id: "cit_nyc_us",
      iata_country_code: "US",
      iata_code: "NYC",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Neyveli Airport",
    longitude: 79.526804,
    latitude: 11.613591,
    id: "arp_nvy_in",
    icao_code: "VONV",
    iata_country_code: "IN",
    iata_code: "NVY",
    city_name: "Neyveli",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Ngala Airport",
    longitude: 31.324232,
    latitude: -24.386944,
    id: "arp_ngl_za",
    icao_code: "FANG",
    iata_country_code: "ZA",
    iata_code: "NGL",
    city_name: "Ngala",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Ngaoundéré Airport",
    longitude: 13.560607,
    latitude: 7.357149,
    id: "arp_nge_cm",
    icao_code: "FKKN",
    iata_country_code: "CM",
    iata_code: "NGE",
    city_name: "Ngaoundere",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ngari Gunsa Airport",
    longitude: 80.055067,
    latitude: 32.101019,
    id: "arp_ngq_cn",
    icao_code: "ZUAL",
    iata_country_code: "CN",
    iata_code: "NGQ",
    city_name: "Shiquanhe",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Ngloram Airport",
    longitude: 111.544166,
    latitude: -7.195536,
    id: "arp_cpf_id",
    icao_code: "WARC",
    iata_country_code: "ID",
    iata_code: "CPF",
    city_name: "Cepu",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Ngoma Airport",
    longitude: 25.933183,
    latitude: -15.965899,
    id: "arp_zgm_zm",
    icao_code: "FLNA",
    iata_country_code: "ZM",
    iata_code: "ZGM",
    city_name: "Ngoma",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Ngukurr Airport",
    longitude: 134.747158,
    latitude: -14.722803,
    id: "arp_rpm_au",
    icao_code: "YNGU",
    iata_country_code: "AU",
    iata_code: "RPM",
    city_name: "Ngukurr",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Ngurah Rai International Airport",
    longitude: 115.166892,
    latitude: -8.747442,
    id: "arp_dps_id",
    icao_code: "WADD",
    iata_country_code: "ID",
    iata_code: "DPS",
    city_name: "Denpasar",
    city: null,
  },
  {
    time_zone: "Asia/Saigon",
    name: "Nha Trang Air Base",
    longitude: 109.192288,
    latitude: 12.227386,
    id: "arp_nha_vn",
    icao_code: "VVNT",
    iata_country_code: "VN",
    iata_code: "NHA",
    city_name: "Nha Trang",
    city: {
      name: "Nha Trang",
      id: "cit_nha_vn",
      iata_country_code: "VN",
      iata_code: "NHA",
    },
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Nhon Co  Airport",
    longitude: 107.566837,
    latitude: 11.979146,
    id: "arp_hoo_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "HOO",
    city_name: "Quang Duc",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Niagara District Airport",
    longitude: -79.171383,
    latitude: 43.190529,
    id: "arp_ycm_ca",
    icao_code: "CYSN",
    iata_country_code: "CA",
    iata_code: "YCM",
    city_name: "St Catharines",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Niagara Falls International Airport",
    longitude: -78.945691,
    latitude: 43.107245,
    id: "arp_iag_us",
    icao_code: "KIAG",
    iata_country_code: "US",
    iata_code: "IAG",
    city_name: "Niagara Falls",
    city: null,
  },
  {
    time_zone: "Africa/Lome",
    name: "Niamtougou International Airport",
    longitude: 1.092013,
    latitude: 9.768473,
    id: "arp_lrl_tg",
    icao_code: "DXNG",
    iata_country_code: "TG",
    iata_code: "LRL",
    city_name: "Niamtougou",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Niau Airport",
    longitude: -146.370775,
    latitude: -16.120544,
    id: "arp_niu_pf",
    icao_code: "NTKN",
    iata_country_code: "PF",
    iata_code: "NIU",
    city_name: "Niau",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Nice-Côte d'Azur Airport",
    longitude: 7.212948,
    latitude: 43.659265,
    id: "arp_nce_fr",
    icao_code: "LFMN",
    iata_country_code: "FR",
    iata_code: "NCE",
    city_name: "Nice",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Nicholson Airport",
    longitude: 128.90265,
    latitude: -18.050969,
    id: "arp_nls_au",
    icao_code: "YNIC",
    iata_country_code: "AU",
    iata_code: "NLS",
    city_name: "Nicholson",
    city: null,
  },
  {
    time_zone: "Asia/Nicosia",
    name: "Nicosia International Airport",
    longitude: 33.27631,
    latitude: 35.150579,
    id: "arp_nic_cy",
    icao_code: "LCNC",
    iata_country_code: "CY",
    iata_code: "NIC",
    city_name: "Nicosia",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Nicoya Guanacaste Airport",
    longitude: -85.447642,
    latitude: 10.13969,
    id: "arp_nct_cr",
    icao_code: "MRNC",
    iata_country_code: "CR",
    iata_code: "NCT",
    city_name: "Nicoya",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Nieuw Nickerie Airport",
    longitude: -57.039981,
    latitude: 5.9556,
    id: "arp_ick_sr",
    icao_code: "SMNI",
    iata_country_code: "SR",
    iata_code: "ICK",
    city_name: "Nieuw Nickerie",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Nifty Airport",
    longitude: 121.590448,
    latitude: -21.672174,
    id: "arp_nif_au",
    icao_code: "YCNF",
    iata_country_code: "AU",
    iata_code: "NIF",
    city_name: "Nifty",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Nightmute Airport",
    longitude: -164.700922,
    latitude: 60.471023,
    id: "arp_nme_us",
    icao_code: "PAGT",
    iata_country_code: "US",
    iata_code: "NME",
    city_name: "Nightmute",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Niigata Airport",
    longitude: 139.116601,
    latitude: 37.954686,
    id: "arp_kij_jp",
    icao_code: "RJSN",
    iata_country_code: "JP",
    iata_code: "KIJ",
    city_name: "Niigata",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nikolai Airport",
    longitude: -154.357833,
    latitude: 63.018674,
    id: "arp_nib_us",
    icao_code: "PAFS",
    iata_country_code: "US",
    iata_code: "NIB",
    city_name: "Nikolai",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Nikolayevsk-on-Amur Airport",
    longitude: 140.649994,
    latitude: 53.154999,
    id: "arp_nli_ru",
    icao_code: "UHNN",
    iata_country_code: "RU",
    iata_code: "NLI",
    city_name: "Khabarovsk",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Nikolski Air Station",
    longitude: -168.85089,
    latitude: 52.941561,
    id: "arp_iko_us",
    icao_code: "PAKO",
    iata_country_code: "US",
    iata_code: "IKO",
    city_name: "Nikolski",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Nikunau Airport",
    longitude: 176.410223,
    latitude: -1.314416,
    id: "arp_nig_ki",
    icao_code: "NGNU",
    iata_country_code: "KI",
    iata_code: "NIG",
    city_name: "Nikunau",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Nimba Airport",
    longitude: -8.583537,
    latitude: 7.492741,
    id: "arp_nia_lr",
    icao_code: "GLNA",
    iata_country_code: "LR",
    iata_code: "NIA",
    city_name: "Nimba",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Nîmes–Alès–Camargue–Cévennes Airport",
    longitude: 4.419087,
    latitude: 43.760218,
    id: "arp_fni_fr",
    icao_code: "LFTW",
    iata_country_code: "FR",
    iata_code: "FNI",
    city_name: "Nimes",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ningbo Lishe International Airport",
    longitude: 121.461971,
    latitude: 29.826683,
    id: "arp_ngb_cn",
    icao_code: "ZSNB",
    iata_country_code: "CN",
    iata_code: "NGB",
    city_name: "Ningbo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ningerum Airport",
    longitude: 141.14348,
    latitude: -5.670279,
    id: "arp_ngr_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NGR",
    city_name: "Ningerum",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ninglang Luguhu Airport",
    longitude: 100.75907,
    latitude: 27.541396,
    id: "arp_nlh_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "NLH",
    city_name: "Ninglang",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ninilchik Airport",
    longitude: -151.59395,
    latitude: 60.021598,
    id: "arp_nin_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NIN",
    city_name: "Ninilchik",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Ninoy Aquino International Airport",
    longitude: 121.019009,
    latitude: 14.511533,
    id: "arp_mnl_ph",
    icao_code: "RPLL",
    iata_country_code: "PH",
    iata_code: "MNL",
    city_name: "Manila",
    city: {
      name: "Manila",
      id: "cit_mnl_ph",
      iata_country_code: "PH",
      iata_code: "MNL",
    },
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Nioki Airport",
    longitude: 17.685304,
    latitude: -2.717503,
    id: "arp_nio_cd",
    icao_code: "FZBI",
    iata_country_code: "CD",
    iata_code: "NIO",
    city_name: "Nioki",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Niokolo-Koba Airport",
    longitude: -12.72734,
    latitude: 13.052048,
    id: "arp_nik_sn",
    icao_code: null,
    iata_country_code: "SN",
    iata_code: "NIK",
    city_name: "Niokolo-Koba",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Nioro du Sahel Airport",
    longitude: -9.576666,
    latitude: 15.238634,
    id: "arp_nix_ml",
    icao_code: "GANR",
    iata_country_code: "ML",
    iata_code: "NIX",
    city_name: "Nioro du Sahel",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Niort-Souché Airport",
    longitude: -0.395078,
    latitude: 46.313251,
    id: "arp_nit_fr",
    icao_code: "LFBN",
    iata_country_code: "FR",
    iata_code: "NIT",
    city_name: "Niort",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nipa Airport",
    longitude: 143.366667,
    latitude: -6.133333,
    id: "arp_npg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NPG",
    city_name: "Nipa",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Niquelândia Airport",
    longitude: -48.491544,
    latitude: -14.435437,
    id: "arp_nql_br",
    icao_code: "SWNQ",
    iata_country_code: "BR",
    iata_code: "NQL",
    city_name: "Niquelândia",
    city: null,
  },
  {
    time_zone: "Europe/Belgrade",
    name: "Niš Constantine the Great Airport",
    longitude: 21.866028,
    latitude: 43.337429,
    id: "arp_ini_rs",
    icao_code: "LYNI",
    iata_country_code: "RS",
    iata_code: "INI",
    city_name: "Nis",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nissan Island Airport",
    longitude: 154.22602,
    latitude: -4.499701,
    id: "arp_iis_pg",
    icao_code: "AYIA",
    iata_country_code: "PG",
    iata_code: "IIS",
    city_name: "Nissan Island",
    city: null,
  },
  {
    time_zone: "Pacific/Niue",
    name: "Niue International Airport",
    longitude: -169.925946,
    latitude: -19.078123,
    id: "arp_iue_nu",
    icao_code: "NIUE",
    iata_country_code: "NU",
    iata_code: "IUE",
    city_name: "Alofi",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Nizhnevartovsk Airport",
    longitude: 76.489351,
    latitude: 60.950386,
    id: "arp_njc_ru",
    icao_code: "USNN",
    iata_country_code: "RU",
    iata_code: "NJC",
    city_name: "Nizhnevartovsk",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Nizhny Novgorod International Airport",
    longitude: 43.784555,
    latitude: 56.227335,
    id: "arp_goj_ru",
    icao_code: "UWGG",
    iata_country_code: "RU",
    iata_code: "GOJ",
    city_name: "Nizhny Novgorod",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Njombe Airport",
    longitude: 34.774493,
    latitude: -9.356042,
    id: "arp_jom_tz",
    icao_code: "HTNJ",
    iata_country_code: "TZ",
    iata_code: "JOM",
    city_name: "Njombe",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Nkan Airport",
    longitude: 9.74921,
    latitude: 0.36581,
    id: "arp_nka_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "NKA",
    city_name: "Nkan",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Nkaus Airport",
    longitude: 28.194842,
    latitude: -30.022193,
    id: "arp_nku_ls",
    icao_code: "FXNK",
    iata_country_code: "LS",
    iata_code: "NKU",
    city_name: "Nkau",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Nkolo Fuma Airport",
    longitude: 14.816667,
    latitude: -5.420833,
    id: "arp_nkl_cd",
    icao_code: "FZAR",
    iata_country_code: "CD",
    iata_code: "NKL",
    city_name: "Kolo",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Nkongsamba Airport",
    longitude: 9.933333,
    latitude: 4.95,
    id: "arp_nks_cm",
    icao_code: "FKAN",
    iata_country_code: "CM",
    iata_code: "NKS",
    city_name: "Nkongsamba",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Nnamdi Azikiwe International Airport",
    longitude: 7.266459,
    latitude: 9.005554,
    id: "arp_abv_ng",
    icao_code: "DNAA",
    iata_country_code: "NG",
    iata_code: "ABV",
    city_name: "Abuja",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Noatak Airport",
    longitude: -162.976661,
    latitude: 67.564391,
    id: "arp_wtk_us",
    icao_code: "PAWN",
    iata_country_code: "US",
    iata_code: "WTK",
    city_name: "Noatak",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "Nogales International Airport",
    longitude: -110.97538,
    latitude: 31.226114,
    id: "arp_nog_mx",
    icao_code: "MMNG",
    iata_country_code: "MX",
    iata_code: "NOG",
    city_name: "Nogales",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Nogales International Airport",
    longitude: -110.848389,
    latitude: 31.417263,
    id: "arp_ols_us",
    icao_code: "KOLS",
    iata_country_code: "US",
    iata_code: "OLS",
    city_name: "Nogales",
    city: null,
  },
  {
    time_zone: "Asia/Sakhalin",
    name: "Nogliki Airport",
    longitude: 143.14498,
    latitude: 51.788845,
    id: "arp_ngk_ru",
    icao_code: "UHSN",
    iata_country_code: "RU",
    iata_code: "NGK",
    city_name: "Nogliki",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Noi Bai International Airport",
    longitude: 105.805585,
    latitude: 21.219957,
    id: "arp_han_vn",
    icao_code: "VVNB",
    iata_country_code: "VN",
    iata_code: "HAN",
    city_name: "Hanoi",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Nojeh Airport",
    longitude: 48.653237,
    latitude: 35.211255,
    id: "arp_nuj_ir",
    icao_code: "OIHS",
    iata_country_code: "IR",
    iata_code: "NUJ",
    city_name: "Nogeh",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nomad River Airport",
    longitude: 142.234714,
    latitude: -6.293584,
    id: "arp_nom_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NOM",
    city_name: "Nomad River",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nomane Airport",
    longitude: 145.070796,
    latitude: -6.320389,
    id: "arp_nmn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NMN",
    city_name: "Nomane",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Nome Airport",
    longitude: -165.444261,
    latitude: 64.511067,
    id: "arp_ome_us",
    icao_code: "PAOM",
    iata_country_code: "US",
    iata_code: "OME",
    city_name: "Nome",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nondalton Airport",
    longitude: -154.839347,
    latitude: 59.979621,
    id: "arp_nnl_us",
    icao_code: "PANO",
    iata_country_code: "US",
    iata_code: "NNL",
    city_name: "Nondalton",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Nonouti Airport",
    longitude: 174.427921,
    latitude: -0.639721,
    id: "arp_non_ki",
    icao_code: "NGTO",
    iata_country_code: "KI",
    iata_code: "NON",
    city_name: "Nonouti",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Noonkanbah Airport",
    longitude: 124.851811,
    latitude: -18.494578,
    id: "arp_nkb_au",
    icao_code: "YNKA",
    iata_country_code: "AU",
    iata_code: "NKB",
    city_name: "Noonkanbah",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Noosa Airport",
    longitude: 153.090774,
    latitude: -26.60413,
    id: "arp_nsv_au",
    icao_code: "YNSH",
    iata_country_code: "AU",
    iata_code: "NSV",
    city_name: "Noosaville",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Nop Goliat Dekai Airport",
    longitude: 139.483199,
    latitude: -4.855166,
    id: "arp_dex_id",
    icao_code: "WAVD",
    iata_country_code: "ID",
    iata_code: "DEX",
    city_name: "Dekai",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Norden-Norddeich Airport",
    longitude: 7.191049,
    latitude: 53.632713,
    id: "arp_nod_de",
    icao_code: "EDWS",
    iata_country_code: "DE",
    iata_code: "NOD",
    city_name: "Norden",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Norderney Airport",
    longitude: 7.230025,
    latitude: 53.706929,
    id: "arp_nrd_de",
    icao_code: "EDWY",
    iata_country_code: "DE",
    iata_code: "NRD",
    city_name: "Norderney",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Norðfjörður Airport",
    longitude: -13.74402,
    latitude: 65.132637,
    id: "arp_nor_is",
    icao_code: "BINF",
    iata_country_code: "IS",
    iata_code: "NOR",
    city_name: "Nordfjordur",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Nordholz Naval Airbase Airport",
    longitude: 8.6584,
    latitude: 53.76757,
    id: "arp_fcn_de",
    icao_code: "ETMN",
    iata_country_code: "DE",
    iata_code: "FCN",
    city_name: "Cuxhaven",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Norfolk International Airport",
    longitude: -76.200609,
    latitude: 36.895141,
    id: "arp_orf_us",
    icao_code: "KORF",
    iata_country_code: "US",
    iata_code: "ORF",
    city_name: "Norfolk",
    city: {
      name: "Norfolk",
      id: "cit_orf_us",
      iata_country_code: "US",
      iata_code: "ORF",
    },
  },
  {
    time_zone: "Pacific/Norfolk",
    name: "Norfolk Island Airport",
    longitude: 167.940551,
    latitude: -29.039376,
    id: "arp_nlk_nf",
    icao_code: "YSNF",
    iata_country_code: "NF",
    iata_code: "NLK",
    city_name: "YSNF",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Norfolk Regional Airport",
    longitude: -97.433679,
    latitude: 41.984999,
    id: "arp_ofk_us",
    icao_code: "KOFK",
    iata_country_code: "US",
    iata_code: "OFK",
    city_name: "Norfolk",
    city: null,
  },
  {
    time_zone: "America/Jamaica",
    name: "Norman Manley International Airport",
    longitude: -76.783935,
    latitude: 17.935928,
    id: "arp_kin_jm",
    icao_code: "MKJP",
    iata_country_code: "JM",
    iata_code: "KIN",
    city_name: "Kingston",
    city: {
      name: "Kingston",
      id: "cit_kin_jm",
      iata_country_code: "JM",
      iata_code: "KIN",
    },
  },
  {
    time_zone: "America/Nassau",
    name: "Norman's Cay Airport",
    longitude: -76.820198,
    latitude: 24.594299,
    id: "arp_nmc_bs",
    icao_code: "MYEN",
    iata_country_code: "BS",
    iata_code: "NMC",
    city_name: "Norman's Cay",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Normanton Airport",
    longitude: 141.071352,
    latitude: -17.685291,
    id: "arp_ntn_au",
    icao_code: "YNTN",
    iata_country_code: "AU",
    iata_code: "NTN",
    city_name: "Normanton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Norman University of Oklahoma Westheimer Airport",
    longitude: -97.470791,
    latitude: 35.244044,
    id: "arp_oun_us",
    icao_code: "KOUN",
    iata_country_code: "US",
    iata_code: "OUN",
    city_name: "Norman",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Norman Wells Airport",
    longitude: -126.796288,
    latitude: 65.280395,
    id: "arp_yvq_ca",
    icao_code: "CYVQ",
    iata_country_code: "CA",
    iata_code: "YVQ",
    city_name: "Norman Wells",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Norrköping Airport",
    longitude: 16.233632,
    latitude: 58.583588,
    id: "arp_nrk_se",
    icao_code: "ESSP",
    iata_country_code: "SE",
    iata_code: "NRK",
    city_name: "Norrköping",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Norseman Airport",
    longitude: 121.754634,
    latitude: -32.211161,
    id: "arp_nsm_au",
    icao_code: "YNSM",
    iata_country_code: "AU",
    iata_code: "NSM",
    city_name: "Norseman",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Norsup Airport",
    longitude: 167.400755,
    latitude: -16.079354,
    id: "arp_nus_vu",
    icao_code: "NVSP",
    iata_country_code: "VU",
    iata_code: "NUS",
    city_name: "Norsup",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "North Battleford Airport",
    longitude: -108.24369,
    latitude: 52.769149,
    id: "arp_yqw_ca",
    icao_code: "CYQW",
    iata_country_code: "CA",
    iata_code: "YQW",
    city_name: "North Battleford",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "North Bay Airport",
    longitude: -79.424329,
    latitude: 46.361865,
    id: "arp_yyb_ca",
    icao_code: "CYYB",
    iata_country_code: "CA",
    iata_code: "YYB",
    city_name: "North Bay",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "North Bimini Airport",
    longitude: -79.249921,
    latitude: 25.766068,
    id: "arp_nsb_bs",
    icao_code: null,
    iata_country_code: "BS",
    iata_code: "NSB",
    city_name: "Bimini",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "North Caicos Airport",
    longitude: -71.939467,
    latitude: 21.918017,
    id: "arp_nca_tc",
    icao_code: "MBNC",
    iata_country_code: "TC",
    iata_code: "NCA",
    city_name: "North Caicos",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "North Central State Airport",
    longitude: -71.49033,
    latitude: 41.920495,
    id: "arp_sfz_us",
    icao_code: "KSFZ",
    iata_country_code: "US",
    iata_code: "SFZ",
    city_name: "Pawtucket",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "North Central West Virginia Airport",
    longitude: -80.22902,
    latitude: 39.296793,
    id: "arp_ckb_us",
    icao_code: "KCKB",
    iata_country_code: "US",
    iata_code: "CKB",
    city_name: "Clarksburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Northeast Alabama Regional Airport",
    longitude: -86.087503,
    latitude: 33.97234,
    id: "arp_gad_us",
    icao_code: "KGAD",
    iata_country_code: "US",
    iata_code: "GAD",
    city_name: "Gadsden",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Northeastern Regional Airport",
    longitude: -76.566784,
    latitude: 36.027232,
    id: "arp_ede_us",
    icao_code: "KEDE",
    iata_country_code: "US",
    iata_code: "EDE",
    city_name: "Edenton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Northeast Florida Regional Airport",
    longitude: -81.34114,
    latitude: 29.956965,
    id: "arp_ust_us",
    icao_code: "KSGJ",
    iata_country_code: "US",
    iata_code: "UST",
    city_name: "St Augustine",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Northeast Iowa Regional Airport",
    longitude: -92.60998,
    latitude: 43.071728,
    id: "arp_ccy_us",
    icao_code: "KCCY",
    iata_country_code: "US",
    iata_code: "CCY",
    city_name: "Charles City",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Northeast Ohio Regional Airport",
    longitude: -80.697681,
    latitude: 41.778654,
    id: "arp_jfn_us",
    icao_code: "KHZY",
    iata_country_code: "US",
    iata_code: "JFN",
    city_name: "Ashtabula",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Northeast Philadelphia Airport",
    longitude: -75.010018,
    latitude: 40.079425,
    id: "arp_pne_us",
    icao_code: "KPNE",
    iata_country_code: "US",
    iata_code: "PNE",
    city_name: "Philadelphia",
    city: {
      name: "Philadelphia",
      id: "cit_phl_us",
      iata_country_code: "US",
      iata_code: "PHL",
    },
  },
  {
    time_zone: "America/Nassau",
    name: "North Eleuthera Airport",
    longitude: -76.683195,
    latitude: 25.475022,
    id: "arp_elh_bs",
    icao_code: "MYEH",
    iata_country_code: "BS",
    iata_code: "ELH",
    city_name: "North Eleuthera",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Northern Aroostook Regional Airport",
    longitude: -68.312745,
    latitude: 47.285509,
    id: "arp_wfk_us",
    icao_code: "KFVE",
    iata_country_code: "US",
    iata_code: "WFK",
    city_name: "Frenchville",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Northern Colorado Regional Airport",
    longitude: -105.007466,
    latitude: 40.450683,
    id: "arp_fnl_us",
    icao_code: "KFNL",
    iata_country_code: "US",
    iata_code: "FNL",
    city_name: "Fort Collins/Loveland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Northern Maine Regional Airport",
    longitude: -68.045181,
    latitude: 46.688019,
    id: "arp_pqi_us",
    icao_code: "KPQI",
    iata_country_code: "US",
    iata_code: "PQI",
    city_name: "Presque Isle",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Northern Peninsula Airport",
    longitude: 142.459,
    latitude: -10.9508,
    id: "arp_abm_au",
    icao_code: "YNPE",
    iata_country_code: "AU",
    iata_code: "ABM",
    city_name: "Bamaga",
    city: null,
  },
  {
    time_zone: "America/Fort_Nelson",
    name: "Northern Rockies Regional Airport",
    longitude: -122.593221,
    latitude: 58.837312,
    id: "arp_yye_ca",
    icao_code: "CYYE",
    iata_country_code: "CA",
    iata_code: "YYE",
    city_name: "Fort Nelson",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "North Fork Valley Airport",
    longitude: -107.642121,
    latitude: 38.832395,
    id: "arp_wpo_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WPO",
    city_name: "Paonia",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "North Island Naval Air Station-Halsey Field Airport",
    longitude: -117.214051,
    latitude: 32.697038,
    id: "arp_nzy_us",
    icao_code: "KNZY",
    iata_country_code: "US",
    iata_code: "NZY",
    city_name: "San Diego",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "North Las Vegas Airport",
    longitude: -115.19609,
    latitude: 36.20996,
    id: "arp_vgt_us",
    icao_code: "KVGT",
    iata_country_code: "US",
    iata_code: "VGT",
    city_name: "Las Vegas",
    city: {
      name: "Las Vegas",
      id: "cit_las_us",
      iata_country_code: "US",
      iata_code: "LAS",
    },
  },
  {
    time_zone: "America/New_York",
    name: "North Perry Airport",
    longitude: -80.240344,
    latitude: 26.00168,
    id: "arp_hwo_us",
    icao_code: "KHWO",
    iata_country_code: "US",
    iata_code: "HWO",
    city_name: "Hollywood",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "North Platte Regional Airport",
    longitude: -100.697399,
    latitude: 41.132404,
    id: "arp_lbf_us",
    icao_code: "KLBF",
    iata_country_code: "US",
    iata_code: "LBF",
    city_name: "North Platte",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "North Ronaldsay Airport",
    longitude: -2.433358,
    latitude: 59.367664,
    id: "arp_nrl_gb",
    icao_code: "EGEN",
    iata_country_code: "GB",
    iata_code: "NRL",
    city_name: "North Ronaldsay",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "North Spirit Lake Airport",
    longitude: -92.972553,
    latitude: 52.490123,
    id: "arp_yno_ca",
    icao_code: "CKQ3",
    iata_country_code: "CA",
    iata_code: "YNO",
    city_name: "North Spirit Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "North Texas Regional Airport",
    longitude: -96.669073,
    latitude: 33.713218,
    id: "arp_pnx_us",
    icao_code: "KGYI",
    iata_country_code: "US",
    iata_code: "PNX",
    city_name: "Sherman/Denison",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Northway Airport",
    longitude: -141.927296,
    latitude: 62.961514,
    id: "arp_ort_us",
    icao_code: "PAOR",
    iata_country_code: "US",
    iata_code: "ORT",
    city_name: "Northway",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Northwest Alabama Regional Airport",
    longitude: -87.610961,
    latitude: 34.745245,
    id: "arp_msl_us",
    icao_code: "KMSL",
    iata_country_code: "US",
    iata_code: "MSL",
    city_name: "Muscle Shoals",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Northwest Arkansas Regional Airport",
    longitude: -94.305548,
    latitude: 36.280278,
    id: "arp_xna_us",
    icao_code: "KXNA",
    iata_country_code: "US",
    iata_code: "XNA",
    city_name: "Fayetteville",
    city: {
      name: "Fayetteville/Springd.",
      id: "cit_fyv_us",
      iata_country_code: "US",
      iata_code: "FYV",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Northwest Florida Beaches International Airport",
    longitude: -85.79604,
    latitude: 30.355599,
    id: "arp_ecp_us",
    icao_code: "KECP",
    iata_country_code: "US",
    iata_code: "ECP",
    city_name: "Panama City",
    city: {
      name: "Panama City",
      id: "cit_ecp_us",
      iata_country_code: "US",
      iata_code: "ECP",
    },
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Northwest Florida Beaches International Airport",
    longitude: 106.763766,
    latitude: -6.339264,
    id: "arp_pcb_id",
    icao_code: "WIHP",
    iata_country_code: "ID",
    iata_code: "PCB",
    city_name: "Pondok Cabe",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Northwest Regional Airport",
    longitude: -128.578884,
    latitude: 54.468626,
    id: "arp_yxt_ca",
    icao_code: "CYXT",
    iata_country_code: "CA",
    iata_code: "YXT",
    city_name: "Kitimat/Terrace",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "North Whale Seaplane Base",
    longitude: -133.121808,
    latitude: 56.116306,
    id: "arp_wwp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WWP",
    city_name: "North Whale Pass",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Norway House Airport",
    longitude: -97.844269,
    latitude: 53.9582,
    id: "arp_yne_ca",
    icao_code: "CYNE",
    iata_country_code: "CA",
    iata_code: "YNE",
    city_name: "Norway House",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Norwich International Airport",
    longitude: 1.281595,
    latitude: 52.674273,
    id: "arp_nwi_gb",
    icao_code: "EGSH",
    iata_country_code: "GB",
    iata_code: "NWI",
    city_name: "Norwich",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Norwood Memorial Airport",
    longitude: -71.174759,
    latitude: 42.191255,
    id: "arp_owd_us",
    icao_code: "KOWD",
    iata_country_code: "US",
    iata_code: "OWD",
    city_name: "Norwood",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Nosara Airport",
    longitude: -85.651671,
    latitude: 9.978374,
    id: "arp_nob_cr",
    icao_code: "MRNS",
    iata_country_code: "CR",
    iata_code: "NOB",
    city_name: "Nosara",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Noshahr Airport",
    longitude: 51.466935,
    latitude: 36.661744,
    id: "arp_nsh_ir",
    icao_code: "OINN",
    iata_country_code: "IR",
    iata_code: "NSH",
    city_name: "Now Shahr",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Noto Airport",
    longitude: 136.959665,
    latitude: 37.29418,
    id: "arp_ntq_jp",
    icao_code: "RJNW",
    iata_country_code: "JP",
    iata_code: "NTQ",
    city_name: "Wajima",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Notodden Airport",
    longitude: 9.215229,
    latitude: 59.565766,
    id: "arp_ntb_no",
    icao_code: "ENNO",
    iata_country_code: "NO",
    iata_code: "NTB",
    city_name: "Notodden",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Nottingham Airport",
    longitude: -1.080012,
    latitude: 52.919358,
    id: "arp_nqt_gb",
    icao_code: "EGBN",
    iata_country_code: "GB",
    iata_code: "NQT",
    city_name: "Nottingham",
    city: {
      name: "Nottingham",
      id: "cit_nqt_gb",
      iata_country_code: "GB",
      iata_code: "NQT",
    },
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Nouadhibou International Airport",
    longitude: -17.032835,
    latitude: 20.923331,
    id: "arp_ndb_mr",
    icao_code: "GQPP",
    iata_country_code: "MR",
    iata_code: "NDB",
    city_name: "Nouadhibou",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Nouakchott–Oumtounsy International Airport",
    longitude: -15.959434,
    latitude: 18.298087,
    id: "arp_nkc_mr",
    icao_code: "GQNO",
    iata_country_code: "MR",
    iata_code: "NKC",
    city_name: "Nouakchott",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Nouméa Magenta Airport",
    longitude: 166.472972,
    latitude: -22.259103,
    id: "arp_gea_nc",
    icao_code: "NWWM",
    iata_country_code: "NC",
    iata_code: "GEA",
    city_name: "Nouméa",
    city: {
      name: "Noumea",
      id: "cit_nou_nc",
      iata_country_code: "NC",
      iata_code: "NOU",
    },
  },
  {
    time_zone: "Africa/Algiers",
    name: "Noumérat - Moufdi Zakaria Airport",
    longitude: 3.796582,
    latitude: 32.382231,
    id: "arp_gha_dz",
    icao_code: "DAUG",
    iata_country_code: "DZ",
    iata_code: "GHA",
    city_name: "Ghardaïa",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Nouna Airport",
    longitude: -3.862363,
    latitude: 12.743392,
    id: "arp_xnu_bf",
    icao_code: "DFON",
    iata_country_code: "BF",
    iata_code: "XNU",
    city_name: "Nouna",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Nova Xavantina Airport",
    longitude: -52.348422,
    latitude: -14.697673,
    id: "arp_nok_br",
    icao_code: "SWXV",
    iata_country_code: "BR",
    iata_code: "NOK",
    city_name: "Nova Xavantina",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Novgorod Airport",
    longitude: 31.37871,
    latitude: 58.624713,
    id: "arp_nvr_ru",
    icao_code: "ULNN",
    iata_country_code: "RU",
    iata_code: "NVR",
    city_name: "Novgorod",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Novo Aripuanã Airport",
    longitude: -60.364898,
    latitude: -5.11803,
    id: "arp_nvp_br",
    icao_code: "SWNA",
    iata_country_code: "BR",
    iata_code: "NVP",
    city_name: "Novo Aripuanã",
    city: null,
  },
  {
    time_zone: "America/Manaus",
    name: "Novo Campo Airport",
    longitude: -67.312637,
    latitude: -8.83543,
    id: "arp_bcr_br",
    icao_code: "SWNK",
    iata_country_code: "BR",
    iata_code: "BCR",
    city_name: "Boca do Acre",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Novo Progresso Airport",
    longitude: -55.400614,
    latitude: -7.125548,
    id: "arp_npr_br",
    icao_code: "SJNP",
    iata_country_code: "BR",
    iata_code: "NPR",
    city_name: "Novo Progresso",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Novy Urengoy Airport",
    longitude: 76.521461,
    latitude: 66.071064,
    id: "arp_nux_ru",
    icao_code: "USMU",
    iata_country_code: "RU",
    iata_code: "NUX",
    city_name: "Novy Urengoy",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nowata Airport",
    longitude: 149.717,
    latitude: -9.983,
    id: "arp_nwt_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NWT",
    city_name: "Nowata",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Nowra Airport",
    longitude: 150.537168,
    latitude: -34.949451,
    id: "arp_noa_au",
    icao_code: "YSNW",
    iata_country_code: "AU",
    iata_code: "NOA",
    city_name: "Nowra",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Noyabrsk Airport",
    longitude: 75.271549,
    latitude: 63.182831,
    id: "arp_noj_ru",
    icao_code: "USRO",
    iata_country_code: "RU",
    iata_code: "NOJ",
    city_name: "Noyabrsk",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Nueva Guinea Airport",
    longitude: -84.449997,
    latitude: 11.666667,
    id: "arp_nvg_ni",
    icao_code: "MNNG",
    iata_country_code: "NI",
    iata_code: "NVG",
    city_name: "Nueva Guinea",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Nueva Hesperides International Airport",
    longitude: -57.984542,
    latitude: -31.438423,
    id: "arp_sty_uy",
    icao_code: "SUSO",
    iata_country_code: "UY",
    iata_code: "STY",
    city_name: "Salto",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Nuevo Casas Grandes Airport",
    longitude: -107.874658,
    latitude: 30.398966,
    id: "arp_ncg_mx",
    icao_code: "MMCG",
    iata_country_code: "MX",
    iata_code: "NCG",
    city_name: "Nuevo Casas Grandes",
    city: null,
  },
  {
    time_zone: "America/Matamoros",
    name: "Nuevo Laredo International Airport",
    longitude: -99.569733,
    latitude: 27.446588,
    id: "arp_nld_mx",
    icao_code: "MMNL",
    iata_country_code: "MX",
    iata_code: "NLD",
    city_name: "Nuevo Laredo",
    city: null,
  },
  {
    time_zone: "Pacific/Bougainville",
    name: "Nuguria Airstrip",
    longitude: 154.738204,
    latitude: -3.407365,
    id: "arp_nug_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NUG",
    city_name: "Nuguria Islands",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nuiqsut Airport",
    longitude: -151.005776,
    latitude: 70.20986,
    id: "arp_nui_us",
    icao_code: "PAQT",
    iata_country_code: "US",
    iata_code: "NUI",
    city_name: "Nuiqsut",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nuku Airport",
    longitude: 142.484842,
    latitude: -3.677795,
    id: "arp_uku_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UKU",
    city_name: "Nuku",
    city: null,
  },
  {
    time_zone: "Pacific/Marquesas",
    name: "Nuku Hiva Airport",
    longitude: -140.227064,
    latitude: -8.795351,
    id: "arp_nhv_pf",
    icao_code: "NTMD",
    iata_country_code: "PF",
    iata_code: "NHV",
    city_name: "Nuku Hiva",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Nukus Airport",
    longitude: 59.621803,
    latitude: 42.48654,
    id: "arp_ncu_uz",
    icao_code: "UTNN",
    iata_country_code: "UZ",
    iata_code: "NCU",
    city_name: "Nukus",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Nukutavake Airport",
    longitude: -138.773634,
    latitude: -19.283602,
    id: "arp_nuk_pf",
    icao_code: "NTGW",
    iata_country_code: "PF",
    iata_code: "NUK",
    city_name: "Nukutavake",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Nukutepipi Airport",
    longitude: -143.046178,
    latitude: -20.701292,
    id: "arp_nkp_pf",
    icao_code: null,
    iata_country_code: "PF",
    iata_code: "NKP",
    city_name: "Nukutepipi",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nulato Airport",
    longitude: -158.075332,
    latitude: 64.728538,
    id: "arp_nul_us",
    icao_code: "PANU",
    iata_country_code: "US",
    iata_code: "NUL",
    city_name: "Nulato",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Nullabor Motel Airport",
    longitude: 130.903114,
    latitude: -31.441724,
    id: "arp_nur_au",
    icao_code: "YNUB",
    iata_country_code: "AU",
    iata_code: "NUR",
    city_name: "Nullarbor",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Nullagine Airport",
    longitude: 120.19993,
    latitude: -21.914623,
    id: "arp_nll_au",
    icao_code: "YNUL",
    iata_country_code: "AU",
    iata_code: "NLL",
    city_name: "Nullagine",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Numbulwar Airport",
    longitude: 135.715896,
    latitude: -14.270719,
    id: "arp_nub_au",
    icao_code: "YNUM",
    iata_country_code: "AU",
    iata_code: "NUB",
    city_name: "Numbulwar",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Nunapitchuk Airport",
    longitude: -162.439959,
    latitude: 60.906523,
    id: "arp_nup_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NUP",
    city_name: "Nunapitchuk",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Nunchia Airport",
    longitude: -72.2,
    latitude: 5.65,
    id: "arp_nuh_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "NUH",
    city_name: "Nunchia",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Nunukan Airport",
    longitude: 117.666149,
    latitude: 4.135016,
    id: "arp_nnx_id",
    icao_code: "WAQA",
    iata_country_code: "ID",
    iata_code: "NNX",
    city_name: "Nunukan",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Nuremberg Airport",
    longitude: 11.078245,
    latitude: 49.497171,
    id: "arp_nue_de",
    icao_code: "EDDN",
    iata_country_code: "DE",
    iata_code: "NUE",
    city_name: "Nuremberg",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Nusatupe Airport",
    longitude: 156.863844,
    latitude: -8.097768,
    id: "arp_gzo_sb",
    icao_code: "AGGN",
    iata_country_code: "SB",
    iata_code: "GZO",
    city_name: "Gizo",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Nusawiru Airport",
    longitude: 108.488653,
    latitude: -7.720194,
    id: "arp_cjn_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "CJN",
    city_name: "Cijulang",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Nushki Airport",
    longitude: 66.022892,
    latitude: 29.538851,
    id: "arp_nhs_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "NHS",
    city_name: "Nushki",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Nutuve Airport",
    longitude: 151.383,
    latitude: -5.63333,
    id: "arp_nut_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "NUT",
    city_name: "Nutuve",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Nutwood Downs Airport",
    longitude: 134.146899,
    latitude: -15.811271,
    id: "arp_utd_au",
    icao_code: "YNUT",
    iata_country_code: "AU",
    iata_code: "UTD",
    city_name: "Nutwood Downs",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Nuuk Airport",
    longitude: -51.676227,
    latitude: 64.191306,
    id: "arp_goh_gl",
    icao_code: "BGGH",
    iata_country_code: "GL",
    iata_code: "GOH",
    city_name: "Nuuk",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Nyac Airport",
    longitude: -159.994405,
    latitude: 60.980717,
    id: "arp_znc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ZNC",
    city_name: "Nyac",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Nyagan Airport",
    longitude: 65.614571,
    latitude: 62.110143,
    id: "arp_nya_ru",
    icao_code: "USHN",
    iata_country_code: "RU",
    iata_code: "NYA",
    city_name: "Nyagan",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Nyala Airport",
    longitude: 24.955203,
    latitude: 12.054326,
    id: "arp_uyl_sd",
    icao_code: "HSNN",
    iata_country_code: "SD",
    iata_code: "UYL",
    city_name: "Nyala",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Nyaung U Airport",
    longitude: 94.929915,
    latitude: 21.178575,
    id: "arp_nyu_mm",
    icao_code: "VYBG",
    iata_country_code: "MM",
    iata_code: "NYU",
    city_name: "Nyaung-U",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Nyeri Airport",
    longitude: 36.978485,
    latitude: -0.364414,
    id: "arp_nye_ke",
    icao_code: "HKNI",
    iata_country_code: "KE",
    iata_code: "NYE",
    city_name: "Nyeri",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Nyingchi Mainling Airport",
    longitude: 94.335317,
    latitude: 29.304398,
    id: "arp_lzy_cn",
    icao_code: "ZUNZ",
    iata_country_code: "CN",
    iata_code: "LZY",
    city_name: "Nyingchi",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Nyngan Airport",
    longitude: 147.198523,
    latitude: -31.55056,
    id: "arp_nyn_au",
    icao_code: "YNYN",
    iata_country_code: "AU",
    iata_code: "NYN",
    city_name: "Nyngan",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Nyurba Airport",
    longitude: 118.342775,
    latitude: 63.295197,
    id: "arp_nyr_ru",
    icao_code: "UENN",
    iata_country_code: "RU",
    iata_code: "NYR",
    city_name: "Nyurba",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Nzagi Airport",
    longitude: 21.356854,
    latitude: -7.716511,
    id: "arp_nza_ao",
    icao_code: "FNZG",
    iata_country_code: "AO",
    iata_code: "NZA",
    city_name: "Nzagi",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Nzérékoré Airport",
    longitude: -8.702124,
    latitude: 7.806791,
    id: "arp_nze_gn",
    icao_code: "GUNZ",
    iata_country_code: "GN",
    iata_code: "NZE",
    city_name: "Nzerekore",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "N'zeto Airport",
    longitude: 12.863964,
    latitude: -7.256678,
    id: "arp_arz_ao",
    icao_code: "FNZE",
    iata_country_code: "AO",
    iata_code: "ARZ",
    city_name: "N'zeto",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Oakdale Airport",
    longitude: -120.800186,
    latitude: 37.756361,
    id: "arp_odc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ODC",
    city_name: "Oakdale",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Oakey Airport",
    longitude: 151.736494,
    latitude: -27.413049,
    id: "arp_oky_au",
    icao_code: "YBOK",
    iata_country_code: "AU",
    iata_code: "OKY",
    city_name: "Oakey",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Oakland County International Airport",
    longitude: -83.420081,
    latitude: 42.665135,
    id: "arp_ptk_us",
    icao_code: "KPTK",
    iata_country_code: "US",
    iata_code: "PTK",
    city_name: "Pontiac",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Oakland International Airport",
    longitude: -122.220371,
    latitude: 37.716933,
    id: "arp_oak_us",
    icao_code: "KOAK",
    iata_country_code: "US",
    iata_code: "OAK",
    city_name: "Oakland",
    city: {
      name: "Oakland",
      id: "cit_oak_us",
      iata_country_code: "US",
      iata_code: "OAK",
    },
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Oamaru Airport",
    longitude: 171.081766,
    latitude: -44.970355,
    id: "arp_oam_nz",
    icao_code: "NZOU",
    iata_country_code: "NZ",
    iata_code: "OAM",
    city_name: "Oamaru",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Oaxaca International Airport",
    longitude: -96.725433,
    latitude: 17.000632,
    id: "arp_oax_mx",
    icao_code: "MMOX",
    iata_country_code: "MX",
    iata_code: "OAX",
    city_name: "Oaxaca",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Oban Airport",
    longitude: 139.03307,
    latitude: -21.233828,
    id: "arp_oba_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "OBA",
    city_name: "Oban",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Oban Airport",
    longitude: -5.398674,
    latitude: 56.464546,
    id: "arp_obn_gb",
    icao_code: "EGEO",
    iata_country_code: "GB",
    iata_code: "OBN",
    city_name: "North Connel",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Obando Airport",
    longitude: -67.906408,
    latitude: 3.853198,
    id: "arp_pda_co",
    icao_code: "SKPD",
    iata_country_code: "CO",
    iata_code: "PDA",
    city_name: "Puerto Inírida",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Obano Airport",
    longitude: 136.230343,
    latitude: -3.9103,
    id: "arp_obd_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "OBD",
    city_name: "Obano",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Obbia Airport",
    longitude: 48.51391,
    latitude: 5.356149,
    id: "arp_cmo_so",
    icao_code: "HCMO",
    iata_country_code: "SO",
    iata_code: "CMO",
    city_name: "Obbia",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Oberpfaffenhofen Airport",
    longitude: 11.284107,
    latitude: 48.082559,
    id: "arp_obf_de",
    icao_code: "EDMO",
    iata_country_code: "DE",
    iata_code: "OBF",
    city_name: "Oberpfaffenhofen",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Óbidos Airport",
    longitude: -55.514765,
    latitude: -1.868178,
    id: "arp_obi_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "OBI",
    city_name: "Óbidos",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Obo Airport",
    longitude: 141.324263,
    latitude: -7.590622,
    id: "arp_obx_pg",
    icao_code: "AYOB",
    iata_country_code: "PG",
    iata_code: "OBX",
    city_name: "Obo",
    city: null,
  },
  {
    time_zone: "Africa/Djibouti",
    name: "Obock Airport",
    longitude: 43.283967,
    latitude: 11.966291,
    id: "arp_obc_dj",
    icao_code: "HDOB",
    iata_country_code: "DJ",
    iata_code: "OBC",
    city_name: "Obock",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Obre Lake/North of Sixty Airport",
    longitude: -103.127253,
    latitude: 60.319304,
    id: "arp_ydw_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YDW",
    city_name: "Obre Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ocala International Airport",
    longitude: -82.223521,
    latitude: 29.171087,
    id: "arp_ocf_us",
    icao_code: "KOCF",
    iata_country_code: "US",
    iata_code: "OCF",
    city_name: "Ocala",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Oceana Naval Air Station",
    longitude: -76.031738,
    latitude: 36.81904,
    id: "arp_ntu_us",
    icao_code: "KNTU",
    iata_country_code: "US",
    iata_code: "NTU",
    city_name: "Virginia Beach",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ocean City Municipal Airport",
    longitude: -75.123981,
    latitude: 38.310428,
    id: "arp_oce_us",
    icao_code: "KOXB",
    iata_country_code: "US",
    iata_code: "OCE",
    city_name: "Ocean City",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ocean County Airport",
    longitude: -74.294425,
    latitude: 39.927208,
    id: "arp_mjx_us",
    icao_code: "KMJX",
    iata_country_code: "US",
    iata_code: "MJX",
    city_name: "Toms River",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Ocean Falls Seaplane Base Airport",
    longitude: -127.695039,
    latitude: 52.352935,
    id: "arp_zof_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZOF",
    city_name: "Ocean Falls",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ocean Reef Club Airport",
    longitude: -80.276588,
    latitude: 25.321988,
    id: "arp_oca_us",
    icao_code: "07FA",
    iata_country_code: "US",
    iata_code: "OCA",
    city_name: "Key Largo",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Oceanside Municipal Airport",
    longitude: -117.353162,
    latitude: 33.217225,
    id: "arp_ocn_us",
    icao_code: "KOKB",
    iata_country_code: "US",
    iata_code: "OCN",
    city_name: "Oceanside",
    city: null,
  },
  {
    time_zone: "America/Jamaica",
    name: "Ocho Rios Airport",
    longitude: -76.968595,
    latitude: 18.404096,
    id: "arp_ocj_jm",
    icao_code: "MKBS",
    iata_country_code: "JM",
    iata_code: "OCJ",
    city_name: "Ocho Rios",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Oconee County Regional Airport",
    longitude: -82.885832,
    latitude: 34.671619,
    id: "arp_ceu_us",
    icao_code: "KCEU",
    iata_country_code: "US",
    iata_code: "CEU",
    city_name: "Clemson",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Odate Noshiro Airport",
    longitude: 140.372246,
    latitude: 40.19319,
    id: "arp_onj_jp",
    icao_code: "RJSR",
    iata_country_code: "JP",
    iata_code: "ONJ",
    city_name: "Odate",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Odense Airport",
    longitude: 10.330151,
    latitude: 55.475771,
    id: "arp_ode_dk",
    icao_code: "EKOD",
    iata_country_code: "DK",
    iata_code: "ODE",
    city_name: "Odense",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Odessa International Airport",
    longitude: 30.67697,
    latitude: 46.428313,
    id: "arp_ods_ua",
    icao_code: "UKOO",
    iata_country_code: "UA",
    iata_code: "ODS",
    city_name: "Odessa",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Odienne Airport",
    longitude: -7.567,
    latitude: 9.5,
    id: "arp_keo_ci",
    icao_code: "DIOD",
    iata_country_code: "CI",
    iata_code: "KEO",
    city_name: "Odienne",
    city: null,
  },
  {
    time_zone: "Asia/Dili",
    name: "Oecussi Airport",
    longitude: 124.346769,
    latitude: -9.200005,
    id: "arp_oec_tl",
    icao_code: "WPOC",
    iata_country_code: "TL",
    iata_code: "OEC",
    city_name: "Oecussi",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Oenpelli Airport",
    longitude: 133.009215,
    latitude: -12.327473,
    id: "arp_opi_au",
    icao_code: "YOEN",
    iata_country_code: "AU",
    iata_code: "OPI",
    city_name: "Oenpelli",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Oesman Sadik Airport",
    longitude: 127.50147,
    latitude: -0.636676,
    id: "arp_lah_id",
    icao_code: "WAPH",
    iata_country_code: "ID",
    iata_code: "LAH",
    city_name: "Labuha",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Offutt Air Force Base",
    longitude: -95.913185,
    latitude: 41.120292,
    id: "arp_off_us",
    icao_code: "KOFF",
    iata_country_code: "US",
    iata_code: "OFF",
    city_name: "Omaha",
    city: {
      name: "Omaha",
      id: "cit_oma_us",
      iata_country_code: "US",
      iata_code: "OMA",
    },
  },
  {
    time_zone: "Pacific/Pago_Pago",
    name: "Ofu Airport",
    longitude: -169.670019,
    latitude: -14.184367,
    id: "arp_ofu_as",
    icao_code: "NSAS",
    iata_country_code: "AS",
    iata_code: "OFU",
    city_name: "Ofu Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Ogden-Hinckley Airport",
    longitude: -112.010492,
    latitude: 41.194641,
    id: "arp_ogd_us",
    icao_code: "KOGD",
    iata_country_code: "US",
    iata_code: "OGD",
    city_name: "Ogden",
    city: {
      name: "Ogden",
      id: "cit_ogd_us",
      iata_country_code: "US",
      iata_code: "OGD",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Ogdensburg International Airport",
    longitude: -75.471152,
    latitude: 44.681089,
    id: "arp_ogs_us",
    icao_code: "KOGS",
    iata_country_code: "US",
    iata_code: "OGS",
    city_name: "Ogdensburg",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ogeranang Airport",
    longitude: 147.364951,
    latitude: -6.467376,
    id: "arp_oge_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OGE",
    city_name: "Ogeramnang",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Ogle Airport",
    longitude: -58.10519,
    latitude: 6.806294,
    id: "arp_ogl_gy",
    icao_code: "SYGO",
    iata_country_code: "GY",
    iata_code: "OGL",
    city_name: "Georgetown",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Ogoki Post Airport",
    longitude: -85.90178,
    latitude: 51.659259,
    id: "arp_yog_ca",
    icao_code: "CYKP",
    iata_country_code: "CA",
    iata_code: "YOG",
    city_name: "Ogoki Post",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "O'Hare International Airport",
    longitude: -87.90606,
    latitude: 41.976381,
    id: "arp_ord_us",
    icao_code: "KORD",
    iata_country_code: "US",
    iata_code: "ORD",
    city_name: "Chicago",
    city: {
      name: "Chicago",
      id: "cit_chi_us",
      iata_country_code: "US",
      iata_code: "CHI",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Ohio State University Airport",
    longitude: -83.073187,
    latitude: 40.079017,
    id: "arp_osu_us",
    icao_code: "KOSU",
    iata_country_code: "US",
    iata_code: "OSU",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_cmh_us",
      iata_country_code: "US",
      iata_code: "CMH",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Ohkay Owingeh Airport",
    longitude: -106.046073,
    latitude: 36.027413,
    id: "arp_eso_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ESO",
    city_name: "Espanola",
    city: null,
  },
  {
    time_zone: "Europe/Skopje",
    name: "Ohrid Airport",
    longitude: 20.743799,
    latitude: 41.18058,
    id: "arp_ohd_mk",
    icao_code: "LWOH",
    iata_country_code: "MK",
    iata_code: "OHD",
    city_name: "Ohrid",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Oiapoque Airport",
    longitude: -51.796916,
    latitude: 3.856217,
    id: "arp_oyk_br",
    icao_code: "SBOI",
    iata_country_code: "BR",
    iata_code: "OYK",
    city_name: "Oiapoque",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Oita Airport",
    longitude: 131.73558,
    latitude: 33.478641,
    id: "arp_oit_jp",
    icao_code: "RJFO",
    iata_country_code: "JP",
    iata_code: "OIT",
    city_name: "Oita",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Okaba Airport",
    longitude: 139.723432,
    latitude: -8.094675,
    id: "arp_okq_id",
    icao_code: "WAKO",
    iata_country_code: "ID",
    iata_code: "OKQ",
    city_name: "Okaba",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Okadama Airport",
    longitude: 141.381607,
    latitude: 43.115068,
    id: "arp_okd_jp",
    icao_code: "RJCO",
    iata_country_code: "JP",
    iata_code: "OKD",
    city_name: "Sapporo",
    city: {
      name: "Sapporo",
      id: "cit_spk_jp",
      iata_country_code: "JP",
      iata_code: "SPK",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Okao Airport",
    longitude: 141.032424,
    latitude: -5.556816,
    id: "arp_okv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OKV",
    city_name: "Okao",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Okaukuejo Airport",
    longitude: 15.916667,
    latitude: -19.148632,
    id: "arp_okf_na",
    icao_code: "FYOO",
    iata_country_code: "NA",
    iata_code: "OKF",
    city_name: "Okaukuejo",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Okayama Airport",
    longitude: 133.854406,
    latitude: 34.757851,
    id: "arp_okj_jp",
    icao_code: "RJOB",
    iata_country_code: "JP",
    iata_code: "OKJ",
    city_name: "Okayama",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Okeechobee County Airport",
    longitude: -80.850564,
    latitude: 27.264767,
    id: "arp_obe_us",
    icao_code: "KOBE",
    iata_country_code: "US",
    iata_code: "OBE",
    city_name: "Okeechobee",
    city: null,
  },
  {
    time_zone: "Asia/Sakhalin",
    name: "Okha Airport",
    longitude: 142.890312,
    latitude: 53.518589,
    id: "arp_ohh_ru",
    icao_code: "UHSH",
    iata_country_code: "RU",
    iata_code: "OHH",
    city_name: "Okha",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Okhotsk Airport",
    longitude: 143.054335,
    latitude: 59.412265,
    id: "arp_oho_ru",
    icao_code: "UHOO",
    iata_country_code: "RU",
    iata_code: "OHO",
    city_name: "Okhotsk",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Oki Airport",
    longitude: 133.326491,
    latitude: 36.180144,
    id: "arp_oki_jp",
    icao_code: "RJNO",
    iata_country_code: "JP",
    iata_code: "OKI",
    city_name: "Oki",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Okierabu Airport",
    longitude: 128.702208,
    latitude: 27.428052,
    id: "arp_oke_jp",
    icao_code: "RJKB",
    iata_country_code: "JP",
    iata_code: "OKE",
    city_name: "Okierabu",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Okmulgee Regional Airport",
    longitude: -95.950796,
    latitude: 35.667447,
    id: "arp_okm_us",
    icao_code: "KOKM",
    iata_country_code: "US",
    iata_code: "OKM",
    city_name: "Okmulgee",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Okondja Airport",
    longitude: 13.67372,
    latitude: -0.665334,
    id: "arp_okn_ga",
    icao_code: "FOGQ",
    iata_country_code: "GA",
    iata_code: "OKN",
    city_name: "Okondja",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Okoyo Airport",
    longitude: 15.071954,
    latitude: -1.450247,
    id: "arp_okg_cg",
    icao_code: null,
    iata_country_code: "CG",
    iata_code: "OKG",
    city_name: "Okoyo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Oksapmin Airport",
    longitude: 142.222513,
    latitude: -5.225342,
    id: "arp_okp_pg",
    icao_code: "AYOJ",
    iata_country_code: "PG",
    iata_code: "OKP",
    city_name: "Oksapmin",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Oksibil Airport",
    longitude: 140.628226,
    latitude: -4.907278,
    id: "arp_okl_id",
    icao_code: "WAJO",
    iata_country_code: "ID",
    iata_code: "OKL",
    city_name: "Oksibil",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Oktyabrsky Airport",
    longitude: 53.386989,
    latitude: 54.439608,
    id: "arp_okt_ru",
    icao_code: "UWUK",
    iata_country_code: "RU",
    iata_code: "OKT",
    city_name: "Kzyl-Yar",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Okushiri Airport",
    longitude: 139.43303,
    latitude: 42.071643,
    id: "arp_oir_jp",
    icao_code: "RJEO",
    iata_country_code: "JP",
    iata_code: "OIR",
    city_name: "Okushiri",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Ólafsfjörður Airport",
    longitude: -18.667364,
    latitude: 66.07338,
    id: "arp_ofj_is",
    icao_code: "BIOF",
    iata_country_code: "IS",
    iata_code: "OFJ",
    city_name: "Ólafsfjörður",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Olare Airport",
    longitude: 35.247367,
    latitude: -1.324397,
    id: "arp_olg_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "OLG",
    city_name: "Maasai Mara",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Olavarria Airport",
    longitude: -60.218895,
    latitude: -36.891131,
    id: "arp_ovr_ar",
    icao_code: "SAZF",
    iata_country_code: "AR",
    iata_code: "OVR",
    city_name: "Olavarria",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Olbia Costa Smeralda Airport",
    longitude: 9.517108,
    latitude: 40.899898,
    id: "arp_olb_it",
    icao_code: "LIEO",
    iata_country_code: "IT",
    iata_code: "OLB",
    city_name: "Olbia",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Old Crow Airport",
    longitude: -139.839073,
    latitude: 67.570601,
    id: "arp_yoc_ca",
    icao_code: "CYOC",
    iata_country_code: "CA",
    iata_code: "YOC",
    city_name: "Old Crow",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Old Harbor Airport",
    longitude: -153.270538,
    latitude: 57.218019,
    id: "arp_olh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "OLH",
    city_name: "Old Harbor",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Old Town Municipal Airport",
    longitude: -68.674157,
    latitude: 44.952368,
    id: "arp_old_us",
    icao_code: "KOLD",
    iata_country_code: "US",
    iata_code: "OLD",
    city_name: "Old Town",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Olenyok Airport",
    longitude: 112.480003,
    latitude: 68.514999,
    id: "arp_onk_ru",
    icao_code: "UERO",
    iata_country_code: "RU",
    iata_code: "ONK",
    city_name: "Olenyok",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Olga Bay Seaplane Base",
    longitude: -154.229861,
    latitude: 57.161468,
    id: "arp_koy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KOY",
    city_name: "Olga Bay",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Ölgii Airport",
    longitude: 89.921389,
    latitude: 48.992491,
    id: "arp_ulg_mn",
    icao_code: "ZMUL",
    iata_country_code: "MN",
    iata_code: "ULG",
    city_name: "Ölgii",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Olive Branch Airport",
    longitude: -89.787544,
    latitude: 34.978813,
    id: "arp_olv_us",
    icao_code: "KOLV",
    iata_country_code: "US",
    iata_code: "OLV",
    city_name: "Olive Branch",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Olkiombo Airport",
    longitude: 35.10974,
    latitude: -1.408876,
    id: "arp_olx_ke",
    icao_code: "HKOL",
    iata_country_code: "KE",
    iata_code: "OLX",
    city_name: "Olkiombo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Olney Municipal Airport",
    longitude: -98.819139,
    latitude: 33.349751,
    id: "arp_ony_us",
    icao_code: "KONY",
    iata_country_code: "US",
    iata_code: "ONY",
    city_name: "Olney",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Olney-Noble Airport",
    longitude: -88.175729,
    latitude: 38.720807,
    id: "arp_oly_us",
    icao_code: "KOLY",
    iata_country_code: "US",
    iata_code: "OLY",
    city_name: "Noble/Olney",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Olomouc Airport",
    longitude: 17.214826,
    latitude: 49.588487,
    id: "arp_olo_cz",
    icao_code: "LKOL",
    iata_country_code: "CZ",
    iata_code: "OLO",
    city_name: "Olomouc",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Olpoi Airport",
    longitude: 166.558093,
    latitude: -14.881463,
    id: "arp_olj_vu",
    icao_code: "NVSZ",
    iata_country_code: "VU",
    iata_code: "OLJ",
    city_name: "Olpoi",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Ol Seki Airport",
    longitude: 35.376663,
    latitude: -1.378348,
    id: "arp_osj_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "OSJ",
    city_name: "Maasai Mara",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Olsobip Airport",
    longitude: 141.515214,
    latitude: -5.389814,
    id: "arp_olq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OLQ",
    city_name: "Olsobip",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Olsztyn-Mazury Airport",
    longitude: 20.940639,
    latitude: 53.483904,
    id: "arp_szy_pl",
    icao_code: "EPSY",
    iata_country_code: "PL",
    iata_code: "SZY",
    city_name: "Szymany",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Olympia Regional Airport",
    longitude: -122.901045,
    latitude: 46.971372,
    id: "arp_olm_us",
    icao_code: "KOLM",
    iata_country_code: "US",
    iata_code: "OLM",
    city_name: "Olympia",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Olympic Dam Airport",
    longitude: 136.880711,
    latitude: -30.484275,
    id: "arp_olp_au",
    icao_code: "YOLD",
    iata_country_code: "AU",
    iata_code: "OLP",
    city_name: "Olympic Dam",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Olyokminsk Airport",
    longitude: 120.471001,
    latitude: 60.397498,
    id: "arp_olz_ru",
    icao_code: "UEMO",
    iata_country_code: "RU",
    iata_code: "OLZ",
    city_name: "Olyokminsk",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Omak Airport",
    longitude: -119.517416,
    latitude: 48.463259,
    id: "arp_omk_us",
    icao_code: "KOMK",
    iata_country_code: "US",
    iata_code: "OMK",
    city_name: "Omak",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Omar N. Bradley Airport",
    longitude: -92.428674,
    latitude: 39.464355,
    id: "arp_mby_us",
    icao_code: "KMBY",
    iata_country_code: "US",
    iata_code: "MBY",
    city_name: "Moberly",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Omboue-Hopital Airport",
    longitude: 9.2627,
    latitude: -1.574856,
    id: "arp_omb_ga",
    icao_code: "FOOH",
    iata_country_code: "GA",
    iata_code: "OMB",
    city_name: "Omboue",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Omega Airport",
    longitude: 22.189296,
    latitude: -18.030388,
    id: "arp_omg_na",
    icao_code: "FYOE",
    iata_country_code: "NA",
    iata_code: "OMG",
    city_name: "Omega",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Omidiyeh Airport",
    longitude: 49.536322,
    latitude: 30.83541,
    id: "arp_omi_ir",
    icao_code: "OIAJ",
    iata_country_code: "IR",
    iata_code: "OMI",
    city_name: "Omidiyeh",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Omkalai Airport",
    longitude: 144.965793,
    latitude: -6.18238,
    id: "arp_oml_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OML",
    city_name: "Omkalai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Omora Airport",
    longitude: 147.081777,
    latitude: -7.829312,
    id: "arp_ose_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OSE",
    city_name: "Omora",
    city: null,
  },
  {
    time_zone: "Asia/Omsk",
    name: "Omsk Tsentralny Airport",
    longitude: 73.314128,
    latitude: 54.96351,
    id: "arp_oms_ru",
    icao_code: "UNOO",
    iata_country_code: "RU",
    iata_code: "OMS",
    city_name: "Omsk",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Ōmura Airport",
    longitude: 140.1,
    latitude: 35.0833,
    id: "arp_omj_jp",
    icao_code: "RJDU",
    iata_country_code: "JP",
    iata_code: "OMJ",
    city_name: "Nagasaki",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Ondangwa Airport",
    longitude: 15.944365,
    latitude: -17.882092,
    id: "arp_ond_na",
    icao_code: "FYOA",
    iata_country_code: "NA",
    iata_code: "OND",
    city_name: "Ondangwa",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Ondjiva Pereira Airport",
    longitude: 15.685695,
    latitude: -17.045002,
    id: "arp_vpe_ao",
    icao_code: "FNGI",
    iata_country_code: "AO",
    iata_code: "VPE",
    city_name: "Ondjiva",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Öndörkhaan Airport",
    longitude: 110.609038,
    latitude: 47.305007,
    id: "arp_unr_mn",
    icao_code: "ZMUH",
    iata_country_code: "MN",
    iata_code: "UNR",
    city_name: "Öndörkhaan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "O'Neill Municipal Airport",
    longitude: -98.687283,
    latitude: 42.468036,
    id: "arp_onl_us",
    icao_code: "KONL",
    iata_country_code: "US",
    iata_code: "ONL",
    city_name: "O'Neill",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Oneonta Municipal Airport",
    longitude: -75.067987,
    latitude: 42.522071,
    id: "arp_onh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ONH",
    city_name: "Oneonta",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Onepusu Airport",
    longitude: 161.067,
    latitude: -9.16667,
    id: "arp_one_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "ONE",
    city_name: "Onepusu",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Ongava Game Reserve Airport",
    longitude: 18.54161,
    latitude: -22.173394,
    id: "arp_ogv_na",
    icao_code: "FYNG",
    iata_country_code: "NA",
    iata_code: "OGV",
    city_name: "Ongava",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Ono-i-Lau Airport",
    longitude: -178.741714,
    latitude: -20.658432,
    id: "arp_onu_fj",
    icao_code: "NFOL",
    iata_country_code: "FJ",
    iata_code: "ONU",
    city_name: "Ono-i-Lau",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ononge Airport",
    longitude: 147.262287,
    latitude: -8.674256,
    id: "arp_onb_pg",
    icao_code: "AYQQ",
    iata_country_code: "PG",
    iata_code: "ONB",
    city_name: "Ononge",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Onotoa Airport",
    longitude: 176.004853,
    latitude: -1.353781,
    id: "arp_oot_ki",
    icao_code: "NGON",
    iata_country_code: "KI",
    iata_code: "OOT",
    city_name: "Onotoa",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Onslow Airport",
    longitude: 115.112334,
    latitude: -21.667363,
    id: "arp_ons_au",
    icao_code: "YOLW",
    iata_country_code: "AU",
    iata_code: "ONS",
    city_name: "Onslow",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Ontario International Airport",
    longitude: -117.599527,
    latitude: 34.055988,
    id: "arp_ont_us",
    icao_code: "KONT",
    iata_country_code: "US",
    iata_code: "ONT",
    city_name: "Ontario",
    city: {
      name: "Los Angeles",
      id: "cit_lax_us",
      iata_country_code: "US",
      iata_code: "LAX",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Ontario Municipal Airport",
    longitude: -117.013805,
    latitude: 44.020528,
    id: "arp_ono_us",
    icao_code: "KONO",
    iata_country_code: "US",
    iata_code: "ONO",
    city_name: "Ontario",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Ontong Java Airport",
    longitude: 159.527563,
    latitude: -5.515085,
    id: "arp_otv_sb",
    icao_code: "AGGQ",
    iata_country_code: "SB",
    iata_code: "OTV",
    city_name: "Ontong Java Atoll",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Oodnadatta Airport",
    longitude: 135.443773,
    latitude: -27.559254,
    id: "arp_odd_au",
    icao_code: "YOOD",
    iata_country_code: "AU",
    iata_code: "ODD",
    city_name: "Oodnadatta",
    city: null,
  },
  {
    time_zone: "Europe/Brussels",
    name: "Oostmalle Airfield Airport",
    longitude: 4.754633,
    latitude: 51.265298,
    id: "arp_obl_be",
    icao_code: "EBZR",
    iata_country_code: "BE",
    iata_code: "OBL",
    city_name: "Zoersel",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Opapimiskan Lake Airport",
    longitude: -90.376899,
    latitude: 52.6067,
    id: "arp_ybs_ca",
    icao_code: "CKM8",
    iata_country_code: "CA",
    iata_code: "YBS",
    city_name: "Opapimiskan Lake",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Open Bay Airport",
    longitude: 151.696701,
    latitude: -4.793435,
    id: "arp_opb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OPB",
    city_name: "Open Bay",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Opinaca Aerodrome",
    longitude: -76.610929,
    latitude: 52.221821,
    id: "arp_yoi_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YOI",
    city_name: "Opinaca",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Opuwo Airport",
    longitude: 13.850178,
    latitude: -18.061031,
    id: "arp_opw_na",
    icao_code: "FYOP",
    iata_country_code: "NA",
    iata_code: "OPW",
    city_name: "Opuwo",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Oradea International Airport",
    longitude: 21.902595,
    latitude: 47.025481,
    id: "arp_omr_ro",
    icao_code: "LROD",
    iata_country_code: "RO",
    iata_code: "OMR",
    city_name: "Oradea",
    city: null,
  },
  {
    time_zone: "Asia/Oral",
    name: "Oral Ak Zhol Airport",
    longitude: 51.540917,
    latitude: 51.152296,
    id: "arp_ura_kz",
    icao_code: "UARR",
    iata_country_code: "KZ",
    iata_code: "URA",
    city_name: "Uralsk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Oram Airport",
    longitude: 148.049043,
    latitude: -9.633012,
    id: "arp_rax_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RAX",
    city_name: "Oram",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Orán Airport",
    longitude: -64.329201,
    latitude: -23.1528,
    id: "arp_ora_ar",
    icao_code: "SASO",
    iata_country_code: "AR",
    iata_code: "ORA",
    city_name: "Oran",
    city: null,
  },
  {
    time_zone: "Asia/Pyongyang",
    name: "Orang Airport",
    longitude: 129.648178,
    latitude: 41.429119,
    id: "arp_rgo_kp ",
    icao_code: "ZKHM",
    iata_country_code: "KP",
    iata_code: "RGO",
    city_name: "Chongjin",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Orange Airport",
    longitude: 149.130229,
    latitude: -33.380795,
    id: "arp_oag_au",
    icao_code: "YORG",
    iata_country_code: "AU",
    iata_code: "OAG",
    city_name: "Orange",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Orangeburg Municipal Airport",
    longitude: -80.859131,
    latitude: 33.456853,
    id: "arp_ogb_us",
    icao_code: "KOGB",
    iata_country_code: "US",
    iata_code: "OGB",
    city_name: "Orangeburg",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Orange County Airport",
    longitude: -74.263195,
    latitude: 41.511091,
    id: "arp_mgj_us",
    icao_code: "KMGJ",
    iata_country_code: "US",
    iata_code: "MGJ",
    city_name: "Montgomery",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Orange Walk Airport",
    longitude: -88.582083,
    latitude: 18.046528,
    id: "arp_orz_bz",
    icao_code: "MZTH",
    iata_country_code: "BZ",
    iata_code: "ORZ",
    city_name: "Orange Walk",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Oran International Airport",
    longitude: -0.607131,
    latitude: 35.620143,
    id: "arp_orn_dz",
    icao_code: "DAOO",
    iata_country_code: "DZ",
    iata_code: "ORN",
    city_name: "Oran",
    city: {
      name: "Oran",
      id: "cit_orn_dz",
      iata_country_code: "DZ",
      iata_code: "ORN",
    },
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Oranjemund Airport",
    longitude: 16.446389,
    latitude: -28.585019,
    id: "arp_omd_na",
    icao_code: "FYOG",
    iata_country_code: "NA",
    iata_code: "OMD",
    city_name: "Oranjemund",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Orapa Airport",
    longitude: 25.317638,
    latitude: -21.266626,
    id: "arp_orp_bw",
    icao_code: "FBOR",
    iata_country_code: "BW",
    iata_code: "ORP",
    city_name: "Orapa",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Orbost Airport",
    longitude: 148.609631,
    latitude: -37.790804,
    id: "arp_rbs_au",
    icao_code: "YORB",
    iata_country_code: "AU",
    iata_code: "RBS",
    city_name: "Orbost",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Orcas Island Airport",
    longitude: -122.910192,
    latitude: 48.707906,
    id: "arp_esd_us",
    icao_code: "KORS",
    iata_country_code: "US",
    iata_code: "ESD",
    city_name: "Eastsound",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Orchid Beach Airport",
    longitude: 153.314779,
    latitude: -24.959839,
    id: "arp_okb_au",
    icao_code: "YORC",
    iata_country_code: "AU",
    iata_code: "OKB",
    city_name: "Fraser Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ordos Ejin Horo Airport",
    longitude: 109.870806,
    latitude: 39.495463,
    id: "arp_dsn_cn",
    icao_code: "ZBDS",
    iata_country_code: "CN",
    iata_code: "DSN",
    city_name: "Ordos",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Ord River Airport",
    longitude: 128.911999,
    latitude: -17.341247,
    id: "arp_odr_au",
    icao_code: "YORV",
    iata_country_code: "AU",
    iata_code: "ODR",
    city_name: "Ord River",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Ordu-Giresun Airport",
    longitude: 38.080811,
    latitude: 40.965708,
    id: "arp_ogu_tr",
    icao_code: "LTCB",
    iata_country_code: "TR",
    iata_code: "OGU",
    city_name: "Ordu",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Örebro Airport",
    longitude: 15.040561,
    latitude: 59.225735,
    id: "arp_orb_se",
    icao_code: "ESOE",
    iata_country_code: "SE",
    iata_code: "ORB",
    city_name: "Örebro",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Orenburg Tsentralny Airport",
    longitude: 55.45688,
    latitude: 51.794713,
    id: "arp_ren_ru",
    icao_code: "UWOO",
    iata_country_code: "RU",
    iata_code: "REN",
    city_name: "Orenburg",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Orestes Acosta Airport",
    longitude: -74.925456,
    latitude: 20.652135,
    id: "arp_moa_cu",
    icao_code: "MUMO",
    iata_country_code: "CU",
    iata_code: "MOA",
    city_name: "Moa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Oria Airport",
    longitude: 155.683333,
    latitude: -6.583333,
    id: "arp_oty_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OTY",
    city_name: "Oria",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Orientos Airport",
    longitude: 141.533,
    latitude: -28.1333,
    id: "arp_oxo_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "OXO",
    city_name: "Orientos",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Orinduik Airport",
    longitude: -60.033333,
    latitude: 4.727361,
    id: "arp_orj_gy",
    icao_code: "SYOR",
    iata_country_code: "GY",
    iata_code: "ORJ",
    city_name: "Orinduik",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Oristano-Fenosu Airport",
    longitude: 8.640497,
    latitude: 39.895154,
    id: "arp_fnu_it",
    icao_code: "LIER",
    iata_country_code: "IT",
    iata_code: "FNU",
    city_name: "Oristano",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Oriximiná Airport",
    longitude: -55.835501,
    latitude: -1.714641,
    id: "arp_orx_br",
    icao_code: "SNOX",
    iata_country_code: "BR",
    iata_code: "ORX",
    city_name: "Oriximina",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Ørland Airport",
    longitude: 9.608222,
    latitude: 63.699266,
    id: "arp_ola_no",
    icao_code: "ENOL",
    iata_country_code: "NO",
    iata_code: "OLA",
    city_name: "Ørland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Orlando Executive Airport",
    longitude: -81.332513,
    latitude: 28.546493,
    id: "arp_orl_us",
    icao_code: "KORL",
    iata_country_code: "US",
    iata_code: "ORL",
    city_name: "Orlando",
    city: {
      name: "Orlando",
      id: "cit_orl_us",
      iata_country_code: "US",
      iata_code: "ORL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Orlando International Airport",
    longitude: -81.308493,
    latitude: 28.43026,
    id: "arp_mco_us",
    icao_code: "KMCO",
    iata_country_code: "US",
    iata_code: "MCO",
    city_name: "Orlando",
    city: {
      name: "Orlando",
      id: "cit_orl_us",
      iata_country_code: "US",
      iata_code: "ORL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Orlando Melbourne International Airport",
    longitude: -80.645227,
    latitude: 28.102289,
    id: "arp_mlb_us",
    icao_code: "KMLB",
    iata_country_code: "US",
    iata_code: "MLB",
    city_name: "Melbourne",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Orlando Sanford International Airport",
    longitude: -81.238486,
    latitude: 28.777518,
    id: "arp_sfb_us",
    icao_code: "KSFB",
    iata_country_code: "US",
    iata_code: "SFB",
    city_name: "Orlando",
    city: {
      name: "Orlando",
      id: "cit_orl_us",
      iata_country_code: "US",
      iata_code: "ORL",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Orléans-Bricy",
    longitude: 1.776749,
    latitude: 47.983262,
    id: "arp_ore_fr",
    icao_code: "LFOZ",
    iata_country_code: "FR",
    iata_code: "ORE",
    city_name: "Orleans",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Ormara Airport",
    longitude: 64.586054,
    latitude: 25.274711,
    id: "arp_orw_pk",
    icao_code: "OPOR",
    iata_country_code: "PK",
    iata_code: "ORW",
    city_name: "Ormara Raik",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Ormoc Airport",
    longitude: 124.564955,
    latitude: 11.057725,
    id: "arp_omc_ph",
    icao_code: "RPVO",
    iata_country_code: "PH",
    iata_code: "OMC",
    city_name: "Ormoc",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Örnsköldsvik Airport",
    longitude: 18.99125,
    latitude: 63.408038,
    id: "arp_oer_se",
    icao_code: "ESNO",
    iata_country_code: "SE",
    iata_code: "OER",
    city_name: "Örnsköldsvik",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Orocue Airport",
    longitude: -96.062584,
    latitude: 42.989622,
    id: "arp_orc_co",
    icao_code: "SKOE",
    iata_country_code: "CO",
    iata_code: "ORC",
    city_name: "Orocue",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Oro Negro Airport",
    longitude: -71.320396,
    latitude: 10.33109,
    id: "arp_cbs_ve",
    icao_code: "SVON",
    iata_country_code: "VE",
    iata_code: "CBS",
    city_name: "Cabimas",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Oroville Municipal Airport",
    longitude: -121.622001,
    latitude: 39.487767,
    id: "arp_ove_us",
    icao_code: "KOVE",
    iata_country_code: "US",
    iata_code: "OVE",
    city_name: "Oroville",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Orsk Airport",
    longitude: 58.594653,
    latitude: 51.073597,
    id: "arp_osw_ru",
    icao_code: "UWOR",
    iata_country_code: "RU",
    iata_code: "OSW",
    city_name: "Orsk",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Ørsta-Volda Airport, Hovden",
    longitude: 6.076717,
    latitude: 62.180007,
    id: "arp_hov_no",
    icao_code: "ENOV",
    iata_country_code: "NO",
    iata_code: "HOV",
    city_name: "Ørsta",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Oryol Yuzhny Airport",
    longitude: 35.988978,
    latitude: 52.927951,
    id: "arp_oel_ru",
    icao_code: "UUOR",
    iata_country_code: "RU",
    iata_code: "OEL",
    city_name: "Orel",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Osage Beach Airport",
    longitude: -92.681758,
    latitude: 38.111484,
    id: "arp_osb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "OSB",
    city_name: "Osage Beach",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Osaka International Airport",
    longitude: 135.437966,
    latitude: 34.786722,
    id: "arp_itm_jp",
    icao_code: "RJOO",
    iata_country_code: "JP",
    iata_code: "ITM",
    city_name: "Osaka",
    city: {
      name: "Osaka",
      id: "cit_osa_jp",
      iata_country_code: "JP",
      iata_code: "OSA",
    },
  },
  {
    time_zone: "Asia/Seoul",
    name: "Osan Air Base",
    longitude: 127.029605,
    latitude: 37.088436,
    id: "arp_osn_kr",
    icao_code: "RKSO",
    iata_country_code: "KR",
    iata_code: "OSN",
    city_name: "Osan",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Osborne Mine Airport",
    longitude: 140.555791,
    latitude: -22.081406,
    id: "arp_oso_au",
    icao_code: "YOSB",
    iata_country_code: "AU",
    iata_code: "OSO",
    city_name: "Osborne Mine",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Oscoda-Wurtsmith Airport",
    longitude: -83.374723,
    latitude: 44.456017,
    id: "arp_osc_us",
    icao_code: "KOSC",
    iata_country_code: "US",
    iata_code: "OSC",
    city_name: "Oscoda",
    city: null,
  },
  {
    time_zone: "Asia/Bishkek",
    name: "Osh Airport",
    longitude: 72.791632,
    latitude: 40.60863,
    id: "arp_oss_kg",
    icao_code: "UCFO",
    iata_country_code: "KG",
    iata_code: "OSS",
    city_name: "Osh",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Oshakati Airport",
    longitude: 15.698743,
    latitude: -17.795786,
    id: "arp_ohi_na",
    icao_code: "FYOS",
    iata_country_code: "NA",
    iata_code: "OHI",
    city_name: "Oshakati",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Oshawa Executive Airport",
    longitude: -78.895953,
    latitude: 43.924192,
    id: "arp_yoo_ca",
    icao_code: "CYOO",
    iata_country_code: "CA",
    iata_code: "YOO",
    city_name: "Oshawa",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Oshima Airport",
    longitude: 139.361667,
    latitude: 34.781057,
    id: "arp_oim_jp",
    icao_code: "RJTO",
    iata_country_code: "JP",
    iata_code: "OIM",
    city_name: "Oshima",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Osijek Airport",
    longitude: 18.809228,
    latitude: 45.463906,
    id: "arp_osi_hr",
    icao_code: "LDOS",
    iata_country_code: "HR",
    iata_code: "OSI",
    city_name: "Osijek",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Oskaloosa Municipal Airport",
    longitude: -92.494647,
    latitude: 41.225463,
    id: "arp_ooa_us",
    icao_code: "KOOA",
    iata_country_code: "US",
    iata_code: "OOA",
    city_name: "Oskaloosa",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Oskarshamn Airport",
    longitude: 16.498059,
    latitude: 57.350661,
    id: "arp_osk_se",
    icao_code: "ESMO",
    iata_country_code: "SE",
    iata_code: "OSK",
    city_name: "Oskarshamn",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Oskemen Airport",
    longitude: 82.50647,
    latitude: 50.026383,
    id: "arp_ukk_kz",
    icao_code: "UASK",
    iata_country_code: "KZ",
    iata_code: "UKK",
    city_name: "Ust-Kut",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Oslo Airport, Gardermoen",
    longitude: 11.10041,
    latitude: 60.196333,
    id: "arp_osl_no",
    icao_code: "ENGM",
    iata_country_code: "NO",
    iata_code: "OSL",
    city_name: "Oslo",
    city: {
      name: "Oslo",
      id: "cit_osl_no",
      iata_country_code: "NO",
      iata_code: "OSL",
    },
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Osmani International Airport",
    longitude: 91.868926,
    latitude: 24.961462,
    id: "arp_zyl_bd",
    icao_code: "VGSY",
    iata_country_code: "BD",
    iata_code: "ZYL",
    city_name: "Sylhet",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Osorno Airport",
    longitude: -73.061018,
    latitude: -40.607915,
    id: "arp_zos_cl",
    icao_code: "SCJO",
    iata_country_code: "CL",
    iata_code: "ZOS",
    city_name: "Osorno",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ossima Airport",
    longitude: 141.297607,
    latitude: -2.915605,
    id: "arp_osg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "OSG",
    city_name: "Ossima",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Ostafyevo International Business Airport",
    longitude: 37.508143,
    latitude: 55.511385,
    id: "arp_osf_ru",
    icao_code: "UUMO",
    iata_country_code: "RU",
    iata_code: "OSF",
    city_name: "Podolsk",
    city: null,
  },
  {
    time_zone: "Europe/Brussels",
    name: "Ostend-Bruges International Airport",
    longitude: 2.866598,
    latitude: 51.200388,
    id: "arp_ost_be",
    icao_code: "EBOS",
    iata_country_code: "BE",
    iata_code: "OST",
    city_name: "Ostend",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Ostrava Leos Janáček Airport",
    longitude: 18.112453,
    latitude: 49.696418,
    id: "arp_osr_cz",
    icao_code: "LKMT",
    iata_country_code: "CZ",
    iata_code: "OSR",
    city_name: "Ostrava",
    city: null,
  },
  {
    time_zone: "Africa/Bissau",
    name: "Osvaldo Vieira International Airport",
    longitude: -15.655134,
    latitude: 11.89019,
    id: "arp_oxb_gw",
    icao_code: "GGOV",
    iata_country_code: "GW",
    iata_code: "OXB",
    city_name: "Bissau",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Oswaldo Guevara Mujica Airport",
    longitude: -69.23606,
    latitude: 9.551291,
    id: "arp_agv_ve",
    icao_code: "SVAC",
    iata_country_code: "VE",
    iata_code: "AGV",
    city_name: "Acarigua",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Otjiwarongo Airport",
    longitude: 16.660801,
    latitude: -20.4347,
    id: "arp_otj_na",
    icao_code: "FYOW",
    iata_country_code: "NA",
    iata_code: "OTJ",
    city_name: "Otjiwarongo",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Ottawa Macdonald-Cartier International Airport",
    longitude: -75.669181,
    latitude: 45.320855,
    id: "arp_yow_ca",
    icao_code: "CYOW",
    iata_country_code: "CA",
    iata_code: "YOW",
    city_name: "Ottawa",
    city: {
      name: "Ottawa",
      id: "cit_yow_ca",
      iata_country_code: "CA",
      iata_code: "YOW",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Ottawa / Rockcliffe Airport",
    longitude: -75.645492,
    latitude: 45.461349,
    id: "arp_yro_ca",
    icao_code: "CYRO",
    iata_country_code: "CA",
    iata_code: "YRO",
    city_name: "Ottawa",
    city: {
      name: "Ottawa",
      id: "cit_yow_ca",
      iata_country_code: "CA",
      iata_code: "YOW",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Ottumwa Regional Airport",
    longitude: -92.447123,
    latitude: 41.106677,
    id: "arp_otm_us",
    icao_code: "KOTM",
    iata_country_code: "US",
    iata_code: "OTM",
    city_name: "Ottumwa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Otu Airport",
    longitude: -74.715179,
    latitude: 7.010503,
    id: "arp_otu_co",
    icao_code: "SKOT",
    iata_country_code: "CO",
    iata_code: "OTU",
    city_name: "Remedios",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Ouadda Airport",
    longitude: 22.398537,
    latitude: 8.010531,
    id: "arp_oda_cf",
    icao_code: "FEFW",
    iata_country_code: "CF",
    iata_code: "ODA",
    city_name: "Ouadda",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Ouagadougou Airport",
    longitude: -1.512694,
    latitude: 12.353751,
    id: "arp_oua_bf",
    icao_code: "DFFD",
    iata_country_code: "BF",
    iata_code: "OUA",
    city_name: "Ouagadougou",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Ouahigouya Airport",
    longitude: -2.421433,
    latitude: 13.564634,
    id: "arp_oug_bf",
    icao_code: "DFCC",
    iata_country_code: "BF",
    iata_code: "OUG",
    city_name: "Ouahigouya",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Ouanaham Airport",
    longitude: 167.239907,
    latitude: -20.774806,
    id: "arp_lif_nc",
    icao_code: "NWWL",
    iata_country_code: "NC",
    iata_code: "LIF",
    city_name: "Lifou",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Ouanda Djallé Airport",
    longitude: 22.799216,
    latitude: 8.90449,
    id: "arp_odj_cf",
    icao_code: "FEGO",
    iata_country_code: "CF",
    iata_code: "ODJ",
    city_name: "Ouanda Djallé",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Ouanga Airport",
    longitude: 10.3,
    latitude: -2.98333,
    id: "arp_ouu_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "OUU",
    city_name: "Ouanga",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Ouango Fitini Airport",
    longitude: -4.050618,
    latitude: 9.598616,
    id: "arp_ofi_ci",
    icao_code: "DIOF",
    iata_country_code: "CI",
    iata_code: "OFI",
    city_name: "Ouango Fitini",
    city: null,
  },
  {
    time_zone: "Indian/Comoro",
    name: "Ouani Airport",
    longitude: 44.430081,
    latitude: -12.132398,
    id: "arp_ajn_km",
    icao_code: "FMCV",
    iata_country_code: "KM",
    iata_code: "AJN",
    city_name: "Anjouan",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Ouarzazate Airport",
    longitude: -6.908288,
    latitude: 30.937462,
    id: "arp_ozz_ma",
    icao_code: "GMMZ",
    iata_country_code: "MA",
    iata_code: "OZZ",
    city_name: "Ouarzazate",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Oudomsay Airport",
    longitude: 101.993884,
    latitude: 20.682704,
    id: "arp_ody_la",
    icao_code: "VLOS",
    iata_country_code: "LA",
    iata_code: "ODY",
    city_name: "Muang Xay",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Oudtshoorn Airport",
    longitude: 22.187694,
    latitude: -33.605304,
    id: "arp_ouh_za",
    icao_code: "FAOH",
    iata_country_code: "ZA",
    iata_code: "OUH",
    city_name: "Oudtshoorn",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Ouesso Airport",
    longitude: 16.038524,
    latitude: 1.613694,
    id: "arp_oue_cg",
    icao_code: "FCOU",
    iata_country_code: "CG",
    iata_code: "OUE",
    city_name: "Ouesso",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Oulu Airport",
    longitude: 25.355219,
    latitude: 64.930487,
    id: "arp_oul_fi",
    icao_code: "EFOU",
    iata_country_code: "FI",
    iata_code: "OUL",
    city_name: "Oulu",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Oum Hadjer Airport",
    longitude: 19.708752,
    latitude: 13.276213,
    id: "arp_oum_td",
    icao_code: null,
    iata_country_code: "TD",
    iata_code: "OUM",
    city_name: "Oum Hadjer",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Ourilândia do Norte Airport",
    longitude: -51.049872,
    latitude: -6.761338,
    id: "arp_oia_br",
    icao_code: "SDOW",
    iata_country_code: "BR",
    iata_code: "OIA",
    city_name: "Ourilandia do Norte",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Ourinhos Airport",
    longitude: -49.913143,
    latitude: -22.966424,
    id: "arp_ous_br",
    icao_code: "SDOU",
    iata_country_code: "BR",
    iata_code: "OUS",
    city_name: "Ourinhos",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Ouro Sogui Airport",
    longitude: -13.322948,
    latitude: 15.593572,
    id: "arp_max_sn",
    icao_code: "GOSM",
    iata_country_code: "SN",
    iata_code: "MAX",
    city_name: "Matam",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Outer Skerries Airport",
    longitude: -0.753958,
    latitude: 60.424354,
    id: "arp_ouk_gb",
    icao_code: "EGOU",
    iata_country_code: "GB",
    iata_code: "OUK",
    city_name: "Out Skerries",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Ouvéa Airport",
    longitude: 166.573011,
    latitude: -20.640718,
    id: "arp_uve_nc",
    icao_code: "NWWV",
    iata_country_code: "NC",
    iata_code: "UVE",
    city_name: "Ouvéa",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Ouyen Airport",
    longitude: 142.348581,
    latitude: -35.089406,
    id: "arp_oyn_au",
    icao_code: "YOUY",
    iata_country_code: "AU",
    iata_code: "OYN",
    city_name: "Ouyen",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ouzinkie Airport",
    longitude: -152.497311,
    latitude: 57.925177,
    id: "arp_koz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KOZ",
    city_name: "Ouzinkie",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Ovda Airport",
    longitude: 34.938026,
    latitude: 29.948261,
    id: "arp_vda_il",
    icao_code: "LLOV",
    iata_country_code: "IL",
    iata_code: "VDA",
    city_name: "Ovda",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Overberg Airport",
    longitude: 20.2507,
    latitude: -34.554901,
    id: "arp_ovg_za",
    icao_code: "FAOB",
    iata_country_code: "ZA",
    iata_code: "OVG",
    city_name: "Overberg",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Owando Airport",
    longitude: 15.950067,
    latitude: -0.531363,
    id: "arp_ftx_cg",
    icao_code: "FCOO",
    iata_country_code: "CG",
    iata_code: "FTX",
    city_name: "Owando",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Owatonna Degner Regional Airport",
    longitude: -93.251317,
    latitude: 44.120915,
    id: "arp_owa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "OWA",
    city_name: "Owatonna",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Owendo Airport",
    longitude: 9.509072,
    latitude: 0.284523,
    id: "arp_owe_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "OWE",
    city_name: "Owendo",
    city: null,
  },
  {
    time_zone: "America/Cayman",
    name: "Owen Roberts International Airport",
    longitude: -81.357917,
    latitude: 19.293761,
    id: "arp_gcm_ky",
    icao_code: "MWCR",
    iata_country_code: "KY",
    iata_code: "GCM",
    city_name: "Grand Cayman",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Owensboro-Daviess County Regional Airport",
    longitude: -87.16588,
    latitude: 37.741859,
    id: "arp_owb_us",
    icao_code: "KOWB",
    iata_country_code: "US",
    iata_code: "OWB",
    city_name: "Owensboro",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Owen Sound Billy Bishop Regional Airport",
    longitude: -80.837225,
    latitude: 44.589944,
    id: "arp_yos_ca",
    icao_code: "CYOS",
    iata_country_code: "CA",
    iata_code: "YOS",
    city_name: "Owen Sound",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Oxford Airport",
    longitude: -1.317822,
    latitude: 51.83505,
    id: "arp_oxf_gb",
    icao_code: "EGTK",
    iata_country_code: "GB",
    iata_code: "OXF",
    city_name: "Oxford",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Oxford House Airport",
    longitude: -95.280543,
    latitude: 54.932587,
    id: "arp_yoh_ca",
    icao_code: "CYOH",
    iata_country_code: "CA",
    iata_code: "YOH",
    city_name: "Oxford House",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Oxnard Airport",
    longitude: -119.206738,
    latitude: 34.19966,
    id: "arp_oxr_us",
    icao_code: "KOXR",
    iata_country_code: "US",
    iata_code: "OXR",
    city_name: "Oxnard",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Oyem Airport",
    longitude: 11.578573,
    latitude: 1.537614,
    id: "arp_oye_ga",
    icao_code: "FOGO",
    iata_country_code: "GA",
    iata_code: "OYE",
    city_name: "Oyem",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Oyo Ollombo Airport",
    longitude: 15.911393,
    latitude: -1.224071,
    id: "arp_oll_cg",
    icao_code: "FCOD",
    iata_country_code: "CG",
    iata_code: "OLL",
    city_name: "Oyo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ozark Regional Airport",
    longitude: -92.470517,
    latitude: 36.368897,
    id: "arp_wmh_us",
    icao_code: "KBPK",
    iata_country_code: "US",
    iata_code: "WMH",
    city_name: "Mountain Home",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ozona Municipal Airport",
    longitude: -101.203052,
    latitude: 30.734271,
    id: "arp_oza_us",
    icao_code: "KOZA",
    iata_country_code: "US",
    iata_code: "OZA",
    city_name: "Ozona",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Paama Airport",
    longitude: 168.236033,
    latitude: -16.432187,
    id: "arp_pbj_vu",
    icao_code: "NVSI",
    iata_country_code: "VU",
    iata_code: "PBJ",
    city_name: "Paama Island",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Paamiut Airport",
    longitude: -49.669787,
    latitude: 62.012116,
    id: "arp_jfr_gl",
    icao_code: "BGPT",
    iata_country_code: "GL",
    iata_code: "JFR",
    city_name: "Paamiut",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Pablo L. Sidar Airport",
    longitude: -102.396862,
    latitude: 19.092554,
    id: "arp_azg_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "AZG",
    city_name: "Apatzingán",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Pacific City State Airport",
    longitude: -123.961776,
    latitude: 45.198984,
    id: "arp_pfc_us",
    icao_code: "KPFC",
    iata_country_code: "US",
    iata_code: "PFC",
    city_name: "Pacific City",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Pacific Harbour Airport",
    longitude: 178.233,
    latitude: -18.1833,
    id: "arp_phr_fj",
    icao_code: null,
    iata_country_code: "FJ",
    iata_code: "PHR",
    city_name: "Pacific Harbour",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Paderborn Lippstadt Airport",
    longitude: 8.617578,
    latitude: 51.613019,
    id: "arp_pad_de",
    icao_code: "EDLP",
    iata_country_code: "DE",
    iata_code: "PAD",
    city_name: "Paderborn",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Padre Aldamiz International Airport",
    longitude: -69.228651,
    latitude: -12.613157,
    id: "arp_pem_pe",
    icao_code: "SPTU",
    iata_country_code: "PE",
    iata_code: "PEM",
    city_name: "Puerto Maldonado",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Pagadian Airport",
    longitude: 123.461582,
    latitude: 7.830667,
    id: "arp_pag_ph",
    icao_code: "RPMP",
    iata_country_code: "PH",
    iata_code: "PAG",
    city_name: "Pagadian",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Page Field",
    longitude: -81.863392,
    latitude: 26.586133,
    id: "arp_fmy_us",
    icao_code: "KFMY",
    iata_country_code: "US",
    iata_code: "FMY",
    city_name: "Fort Myers",
    city: {
      name: "Fort Myers",
      id: "cit_fmy_us",
      iata_country_code: "US",
      iata_code: "FMY",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Page Municipal Airport",
    longitude: -111.448857,
    latitude: 36.925687,
    id: "arp_pga_us",
    icao_code: "KPGA",
    iata_country_code: "US",
    iata_code: "PGA",
    city_name: "Page",
    city: null,
  },
  {
    time_zone: "Pacific/Pago_Pago",
    name: "Pago Pago International Airport",
    longitude: -170.710697,
    latitude: -14.331194,
    id: "arp_ppg_as",
    icao_code: "NSTU",
    iata_country_code: "AS",
    iata_code: "PPG",
    city_name: "Pago Pago",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Pai Airport",
    longitude: 98.436437,
    latitude: 19.371323,
    id: "arp_pyy_th",
    icao_code: "VTCI",
    iata_country_code: "TH",
    iata_code: "PYY",
    city_name: "Pai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Paiela Airport",
    longitude: 142.975639,
    latitude: -5.372746,
    id: "arp_ple_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PLE",
    city_name: "Paiela",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Paine Field Airport",
    longitude: -122.281605,
    latitude: 47.906802,
    id: "arp_pae_us",
    icao_code: "KPAE",
    iata_country_code: "US",
    iata_code: "PAE",
    city_name: "Everett",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Pajala Airport",
    longitude: 23.068823,
    latitude: 67.245682,
    id: "arp_pja_se",
    icao_code: "ESUP",
    iata_country_code: "SE",
    iata_code: "PJA",
    city_name: "Pajala",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Pakokku Airport",
    longitude: 95.112077,
    latitude: 21.404797,
    id: "arp_pkk_mm",
    icao_code: "VYPU",
    iata_country_code: "MM",
    iata_code: "PKK",
    city_name: "Pakhokku",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Pakse International Airport",
    longitude: 105.780528,
    latitude: 15.133365,
    id: "arp_pkz_la",
    icao_code: "VLPS",
    iata_country_code: "LA",
    iata_code: "PKZ",
    city_name: "Pakse",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Pakuba Airport",
    longitude: 31.497213,
    latitude: 2.326117,
    id: "arp_paf_ug",
    icao_code: "HUPA",
    iata_country_code: "UG",
    iata_code: "PAF",
    city_name: "Pakuba",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Pakyong Airport",
    longitude: 88.587324,
    latitude: 27.226665,
    id: "arp_pyg_in",
    icao_code: "VEPY",
    iata_country_code: "IN",
    iata_code: "PYG",
    city_name: "Gangtok",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Pala Airport",
    longitude: 14.925908,
    latitude: 9.379689,
    id: "arp_plf_td",
    icao_code: "FTTP",
    iata_country_code: "TD",
    iata_code: "PLF",
    city_name: "Pala",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Palacios Airport",
    longitude: -84.939977,
    latitude: 15.954136,
    id: "arp_pch_hn",
    icao_code: "MHPC",
    iata_country_code: "HN",
    iata_code: "PCH",
    city_name: "Palacios",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Palacios Municipal Airport",
    longitude: -96.252564,
    latitude: 28.725896,
    id: "arp_psx_us",
    icao_code: "KPSX",
    iata_country_code: "US",
    iata_code: "PSX",
    city_name: "Palacios",
    city: null,
  },
  {
    time_zone: "Europe/Vilnius",
    name: "Palanga International Airport",
    longitude: 21.094658,
    latitude: 55.97271,
    id: "arp_plq_lt",
    icao_code: "EYPA",
    iata_country_code: "LT",
    iata_code: "PLQ",
    city_name: "Palanga",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Palenque International Airport",
    longitude: -92.016006,
    latitude: 17.533236,
    id: "arp_pqm_mx",
    icao_code: "MMPQ",
    iata_country_code: "MX",
    iata_code: "PQM",
    city_name: "Palenque",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Palestine Municipal Airport",
    longitude: -95.705637,
    latitude: 31.780548,
    id: "arp_psn_us",
    icao_code: "KPSN",
    iata_country_code: "US",
    iata_code: "PSN",
    city_name: "Palestine",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Palma Airport",
    longitude: 22.380384,
    latitude: -34.004729,
    id: "arp_lmz_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "LMZ",
    city_name: "Palma",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Palma de Mallorca Airport",
    longitude: 2.737487,
    latitude: 39.55172,
    id: "arp_pmi_es",
    icao_code: "LEPA",
    iata_country_code: "ES",
    iata_code: "PMI",
    city_name: "Palma de Mallorca",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Palmarito Airport",
    longitude: -70.175526,
    latitude: 7.574872,
    id: "arp_ptm_ve",
    icao_code: "SVPT",
    iata_country_code: "VE",
    iata_code: "PTM",
    city_name: "Palmarito",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Palmar Sur Airport",
    longitude: -83.467226,
    latitude: 8.952735,
    id: "arp_pmz_cr",
    icao_code: "MRPM",
    iata_country_code: "CR",
    iata_code: "PMZ",
    city_name: "Palmar Sur",
    city: null,
  },
  {
    time_zone: "America/Araguaina",
    name: "Palmas Airport",
    longitude: -48.357408,
    latitude: -10.293246,
    id: "arp_pmw_br",
    icao_code: "SBPJ",
    iata_country_code: "BR",
    iata_code: "PMW",
    city_name: "Palmas",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Palm Beach County Glades Airport",
    longitude: -80.693386,
    latitude: 26.786422,
    id: "arp_phk_us",
    icao_code: "KPHK",
    iata_country_code: "US",
    iata_code: "PHK",
    city_name: "Pahokee",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Palm Beach County Park Airport",
    longitude: -80.085159,
    latitude: 26.592925,
    id: "arp_lna_us",
    icao_code: "KLNA",
    iata_country_code: "US",
    iata_code: "LNA",
    city_name: "West Palm Beach",
    city: {
      name: "West Palm Beach",
      id: "cit_pbi_us",
      iata_country_code: "US",
      iata_code: "PBI",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Palm Beach International Airport",
    longitude: -80.094206,
    latitude: 26.684474,
    id: "arp_pbi_us",
    icao_code: "KPBI",
    iata_country_code: "US",
    iata_code: "PBI",
    city_name: "West Palm Beach",
    city: {
      name: "West Palm Beach",
      id: "cit_pbi_us",
      iata_country_code: "US",
      iata_code: "PBI",
    },
  },
  {
    time_zone: "Australia/Sydney",
    name: "Palm Beach Seaplane Base Airport",
    longitude: 151.323889,
    latitude: -33.5875,
    id: "arp_lbh_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "LBH",
    city_name: "Sydney",
    city: {
      name: "Sydney",
      id: "cit_syd_au",
      iata_country_code: "AU",
      iata_code: "SYD",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Palmdale Regional Airport",
    longitude: -118.084157,
    latitude: 34.628449,
    id: "arp_pmd_us",
    icao_code: "KPMD",
    iata_country_code: "US",
    iata_code: "PMD",
    city_name: "Palmdale",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Palmer Municipal Airport",
    longitude: -149.09105,
    latitude: 61.598011,
    id: "arp_paq_us",
    icao_code: "PAAQ",
    iata_country_code: "US",
    iata_code: "PAQ",
    city_name: "Palmer",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Palmerston North Airport",
    longitude: 175.618419,
    latitude: -40.321504,
    id: "arp_pmr_nz",
    icao_code: "NZPM",
    iata_country_code: "NZ",
    iata_code: "PMR",
    city_name: "Palmerston North",
    city: null,
  },
  {
    time_zone: "America/St_Vincent",
    name: "Palm Island Airport",
    longitude: -61.395695,
    latitude: 12.586223,
    id: "arp_pli_vc",
    icao_code: null,
    iata_country_code: "VC",
    iata_code: "PLI",
    city_name: "Palm Island",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Palm Island Airport",
    longitude: 146.581191,
    latitude: -18.755289,
    id: "arp_pmk_au",
    icao_code: "YPAM",
    iata_country_code: "AU",
    iata_code: "PMK",
    city_name: "Palm Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Palm Springs International Airport",
    longitude: -116.507025,
    latitude: 33.830009,
    id: "arp_psp_us",
    icao_code: "KPSP",
    iata_country_code: "US",
    iata_code: "PSP",
    city_name: "Palm Springs",
    city: {
      name: "Palm Springs",
      id: "cit_psp_us",
      iata_country_code: "US",
      iata_code: "PSP",
    },
  },
  {
    time_zone: "Asia/Damascus",
    name: "Palmyra Airport",
    longitude: 38.317099,
    latitude: 34.557804,
    id: "arp_pms_sy",
    icao_code: "OSPR",
    iata_country_code: "SY",
    iata_code: "PMS",
    city_name: "Palmyra",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Palo Alto Airport",
    longitude: -122.112892,
    latitude: 37.458044,
    id: "arp_pao_us",
    icao_code: "KPAO",
    iata_country_code: "US",
    iata_code: "PAO",
    city_name: "Palo Alto",
    city: null,
  },
  {
    time_zone: "Africa/Juba",
    name: "Paloich Airport",
    longitude: 32.501008,
    latitude: 10.529067,
    id: "arp_hgi_ss",
    icao_code: "HSFA",
    iata_country_code: "SS",
    iata_code: "HGI",
    city_name: "Higlieg",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Palonegro International Airport",
    longitude: -73.183035,
    latitude: 7.127294,
    id: "arp_bga_co",
    icao_code: "SKBG",
    iata_country_code: "CO",
    iata_code: "BGA",
    city_name: "Bucaramanga",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Palopo Lagaligo Airport",
    longitude: 120.437376,
    latitude: -6.179349,
    id: "arp_llo_id",
    icao_code: "WAFD",
    iata_country_code: "ID",
    iata_code: "LLO",
    city_name: "Palopo",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Palo Verde Airport",
    longitude: -112.098685,
    latitude: 27.092887,
    id: "arp_srl_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "SRL",
    city_name: "Santa Rosalia",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Palu Mutiara Airport",
    longitude: 119.908818,
    latitude: -0.918565,
    id: "arp_plw_id",
    icao_code: "WAFF",
    iata_country_code: "ID",
    iata_code: "PLW",
    city_name: "Palu",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Palungtar Airport",
    longitude: 83.978837,
    latitude: 28.198511,
    id: "arp_gkh_np",
    icao_code: "VNGK",
    iata_country_code: "NP",
    iata_code: "GKH",
    city_name: "Gorkha",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Pama Airport",
    longitude: 0.697074,
    latitude: 11.255212,
    id: "arp_xpa_bf",
    icao_code: "DFEP",
    iata_country_code: "BF",
    iata_code: "XPA",
    city_name: "Pama",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pambwa Airport",
    longitude: 151.083,
    latitude: -10.5833,
    id: "arp_paw_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PAW",
    city_name: "Pambwa",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Pamol Airport",
    longitude: 117.40481,
    latitude: 5.997273,
    id: "arp_pay_my",
    icao_code: "WBKP",
    iata_country_code: "MY",
    iata_code: "PAY",
    city_name: "Pamol",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Pampa Guanaco Airport",
    longitude: -68.809955,
    latitude: -54.050068,
    id: "arp_dpb_cl",
    icao_code: null,
    iata_country_code: "CL",
    iata_code: "DPB",
    city_name: "Cameron",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Pamplona Airport",
    longitude: -1.646107,
    latitude: 42.770242,
    id: "arp_pna_es",
    icao_code: "LEPP",
    iata_country_code: "ES",
    iata_code: "PNA",
    city_name: "Pamplona",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Panama City International Airport",
    longitude: -79.557289,
    latitude: 8.971642,
    id: "arp_pac_pa",
    icao_code: "MPMG",
    iata_country_code: "PA",
    iata_code: "PAC",
    city_name: "Panama City",
    city: {
      name: "Panama City",
      id: "cit_pty_pa",
      iata_country_code: "PA",
      iata_code: "PTY",
    },
  },
  {
    time_zone: "America/Panama",
    name: "Panama Pacifico International Airport",
    longitude: -79.597906,
    latitude: 8.916466,
    id: "arp_blb_pa",
    icao_code: "MPPA",
    iata_country_code: "PA",
    iata_code: "BLB",
    city_name: "Balboa",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Pandie Pandie Airport",
    longitude: 139.402161,
    latitude: -26.120178,
    id: "arp_pde_au",
    icao_code: "YPDI",
    iata_country_code: "AU",
    iata_code: "PDE",
    city_name: "Pandie Pandie",
    city: null,
  },
  {
    time_zone: "Europe/Vilnius",
    name: "Panevėžys Air Base",
    longitude: 24.459781,
    latitude: 55.729521,
    id: "arp_pnv_lt",
    icao_code: "EYPP",
    iata_country_code: "LT",
    iata_code: "PNV",
    city_name: "Panevėžys",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Pangborn Memorial Airport",
    longitude: -120.206747,
    latitude: 47.398925,
    id: "arp_eat_us",
    icao_code: "KEAT",
    iata_country_code: "US",
    iata_code: "EAT",
    city_name: "Wenatchee",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pangia Airport",
    longitude: 144.108625,
    latitude: -6.386224,
    id: "arp_pgn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PGN",
    city_name: "Pangia",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Pangkor Airport",
    longitude: 100.553724,
    latitude: 4.245541,
    id: "arp_pkg_my",
    icao_code: "WMPA",
    iata_country_code: "MY",
    iata_code: "PKG",
    city_name: "Pangkor Island",
    city: null,
  },
  {
    time_zone: "America/Pangnirtung",
    name: "Pangnirtung Airport",
    longitude: -65.713605,
    latitude: 66.144998,
    id: "arp_yxp_ca",
    icao_code: "CYXP",
    iata_country_code: "CA",
    iata_code: "YXP",
    city_name: "Pangnirtung",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pangoa Airport",
    longitude: 141.55,
    latitude: -7.016667,
    id: "arp_pgb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PGB",
    city_name: "Pangoa",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Pangsuma Airport",
    longitude: 112.935776,
    latitude: 0.83483,
    id: "arp_psu_id",
    icao_code: "WIOP",
    iata_country_code: "ID",
    iata_code: "PSU",
    city_name: "Putussibau",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Panguilemo Airport",
    longitude: -71.601326,
    latitude: -35.377694,
    id: "arp_tlx_cl",
    icao_code: "SCTL",
    iata_country_code: "CL",
    iata_code: "TLX",
    city_name: "Talca",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Panguitch Municipal Airport",
    longitude: -112.392075,
    latitude: 37.845228,
    id: "arp_pnu_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PNU",
    city_name: "Panguitch",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Panjgur Airport",
    longitude: 64.133713,
    latitude: 26.954567,
    id: "arp_pjg_pk",
    icao_code: "OPPG",
    iata_country_code: "PK",
    iata_code: "PJG",
    city_name: "Panjgur",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Pantelleria Airport",
    longitude: 11.967974,
    latitude: 36.815767,
    id: "arp_pnl_it",
    icao_code: "LICG",
    iata_country_code: "IT",
    iata_code: "PNL",
    city_name: "Pantelleria",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Pantnagar Airport",
    longitude: 79.473569,
    latitude: 29.032621,
    id: "arp_pgh_in",
    icao_code: "VIPT",
    iata_country_code: "IN",
    iata_code: "PGH",
    city_name: "Pantnagar",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Panzhihua Bao'anying Airport",
    longitude: 101.796932,
    latitude: 26.536922,
    id: "arp_pzi_cn",
    icao_code: "ZUZH",
    iata_country_code: "CN",
    iata_code: "PZI",
    city_name: "Panzhihua",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Papa Stour Airport",
    longitude: -1.693997,
    latitude: 60.326155,
    id: "arp_psv_gb",
    icao_code: null,
    iata_country_code: "GB",
    iata_code: "PSV",
    city_name: "Papa Stour",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Papa Westray Airport",
    longitude: -2.900537,
    latitude: 59.351209,
    id: "arp_ppw_gb",
    icao_code: "EGEP",
    iata_country_code: "GB",
    iata_code: "PPW",
    city_name: "Papa Westray",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Papeete Airport",
    longitude: -149.608941,
    latitude: -17.555982,
    id: "arp_ppt_pf",
    icao_code: "NTAA",
    iata_country_code: "PF",
    iata_code: "PPT",
    city_name: "Papeete",
    city: null,
  },
  {
    time_zone: "Asia/Nicosia",
    name: "Paphos International Airport",
    longitude: 32.48722,
    latitude: 34.715181,
    id: "arp_pfo_cy",
    icao_code: "LCPH",
    iata_country_code: "CY",
    iata_code: "PFO",
    city_name: "Paphos",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Paraburdoo Airport",
    longitude: 117.746443,
    latitude: -23.172441,
    id: "arp_pbo_au",
    icao_code: "YPBO",
    iata_country_code: "AU",
    iata_code: "PBO",
    city_name: "Paraburdoo",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Parachinar Airport",
    longitude: 70.071564,
    latitude: 33.902234,
    id: "arp_paj_pk",
    icao_code: "OPPC",
    iata_country_code: "PK",
    iata_code: "PAJ",
    city_name: "Parachinar",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Paradise River Airport",
    longitude: -57.230872,
    latitude: 53.42808,
    id: "arp_yde_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YDE",
    city_name: "Paradise River",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Paradise Skypark Airport",
    longitude: -121.616389,
    latitude: 39.710556,
    id: "arp_pys_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PYS",
    city_name: "Paradise",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Parai-tepuí Airport",
    longitude: -61.520335,
    latitude: 4.570139,
    id: "arp_pph_ve",
    icao_code: null,
    iata_country_code: "VE",
    iata_code: "PPH",
    city_name: "Peraitepuy",
    city: null,
  },
  {
    time_zone: "Africa/Porto-Novo",
    name: "Parakou Airport",
    longitude: 2.610277,
    latitude: 9.357514,
    id: "arp_pko_bj",
    icao_code: "DBBP",
    iata_country_code: "BJ",
    iata_code: "PKO",
    city_name: "Parakou",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Param Airport",
    longitude: 149.592,
    latitude: -9.99583,
    id: "arp_ppx_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PPX",
    city_name: "Param",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Paramakatoi Airport",
    longitude: -59.713036,
    latitude: 4.700013,
    id: "arp_pmt_gy",
    icao_code: "SYPM",
    iata_country_code: "GY",
    iata_code: "PMT",
    city_name: "Paramakatoi",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Paramillo Airport",
    longitude: -72.203375,
    latitude: 7.801114,
    id: "arp_sci_ve",
    icao_code: "SVPM",
    iata_country_code: "VE",
    iata_code: "SCI",
    city_name: "San Cristobal",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Paraná Airport",
    longitude: -60.481563,
    latitude: -31.79354,
    id: "arp_pra_ar",
    icao_code: "SAAP",
    iata_country_code: "AR",
    iata_code: "PRA",
    city_name: "Parana",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Paranaguá Airport",
    longitude: -48.530592,
    latitude: -25.53885,
    id: "arp_png_br",
    icao_code: "SSPG",
    iata_country_code: "BR",
    iata_code: "PNG",
    city_name: "Paranaguá",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Paranaíba Airport",
    longitude: -51.200736,
    latitude: -19.64982,
    id: "arp_pbb_br",
    icao_code: "SSPN",
    iata_country_code: "BR",
    iata_code: "PBB",
    city_name: "Paranaíba",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Paranavaí Airport",
    longitude: -52.488963,
    latitude: -23.091116,
    id: "arp_pvi_br",
    icao_code: "SSPI",
    iata_country_code: "BR",
    iata_code: "PVI",
    city_name: "Paranavaí",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Parasi Airport",
    longitude: 161.425243,
    latitude: -9.641492,
    id: "arp_prs_sb",
    icao_code: "AGGP",
    iata_country_code: "SB",
    iata_code: "PRS",
    city_name: "Parasi",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Paratebueno Airport",
    longitude: -73.199836,
    latitude: 4.383006,
    id: "arp_euo_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "EUO",
    city_name: "Paratebueno",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Pardoo Airport",
    longitude: 119.571902,
    latitude: -20.119393,
    id: "arp_prd_au",
    icao_code: "YPDO",
    iata_country_code: "AU",
    iata_code: "PRD",
    city_name: "Pardoo Station",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Pardubice Airport",
    longitude: 15.740247,
    latitude: 50.014872,
    id: "arp_ped_cz",
    icao_code: "LKPD",
    iata_country_code: "CZ",
    iata_code: "PED",
    city_name: "Pardubice",
    city: null,
  },
  {
    time_zone: "America/Manaus",
    name: "Parintins Airport",
    longitude: -56.774689,
    latitude: -2.67185,
    id: "arp_pin_br",
    icao_code: "SWPI",
    iata_country_code: "BR",
    iata_code: "PIN",
    city_name: "Parintins",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Paris-Beauvais Airport",
    longitude: 2.111972,
    latitude: 49.455688,
    id: "arp_bva_fr",
    icao_code: "LFOB",
    iata_country_code: "FR",
    iata_code: "BVA",
    city_name: "Beauvais",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Paris Charles de Gaulle Airport",
    longitude: 2.548962,
    latitude: 49.011244,
    id: "arp_cdg_fr",
    icao_code: "LFPG",
    iata_country_code: "FR",
    iata_code: "CDG",
    city_name: "Paris",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Paris–Le Bourget Airport",
    longitude: 2.439296,
    latitude: 48.965436,
    id: "arp_lbg_fr",
    icao_code: "LFPB",
    iata_country_code: "FR",
    iata_code: "LBG",
    city_name: "Paris",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Paris-Orly Airport",
    longitude: 2.362898,
    latitude: 48.726427,
    id: "arp_ory_fr",
    icao_code: "LFPO",
    iata_country_code: "FR",
    iata_code: "ORY",
    city_name: "Paris",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Parker County Airport",
    longitude: -97.682096,
    latitude: 32.747066,
    id: "arp_wea_us",
    icao_code: "KWEA",
    iata_country_code: "US",
    iata_code: "WEA",
    city_name: "Weatherford",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Parkes Airport",
    longitude: 148.235956,
    latitude: -33.134967,
    id: "arp_pke_au",
    icao_code: "YPKS",
    iata_country_code: "AU",
    iata_code: "PKE",
    city_name: "Parkes",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Park Falls Municipal Airport",
    longitude: -90.424546,
    latitude: 45.955556,
    id: "arp_pkf_us",
    icao_code: "KPKF",
    iata_country_code: "US",
    iata_code: "PKF",
    city_name: "Park Falls",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Park Rapids Municipal Konshok Field Airport",
    longitude: -95.068712,
    latitude: 46.899124,
    id: "arp_pkd_us",
    icao_code: "KPKD",
    iata_country_code: "US",
    iata_code: "PKD",
    city_name: "Park Rapids",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Park Township Airport",
    longitude: -86.162525,
    latitude: 42.7957,
    id: "arp_hlm_us",
    icao_code: "KHLM",
    iata_country_code: "US",
    iata_code: "HLM",
    city_name: "Holland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Parlin Field",
    longitude: -72.186144,
    latitude: 43.388922,
    id: "arp_nwh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NWH",
    city_name: "Newport",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Parma Airport",
    longitude: 10.296535,
    latitude: 44.824627,
    id: "arp_pmf_it",
    icao_code: "LIMP",
    iata_country_code: "IT",
    iata_code: "PMF",
    city_name: "Parma",
    city: {
      name: "Milan",
      id: "cit_mil_it",
      iata_country_code: "IT",
      iata_code: "MIL",
    },
  },
  {
    time_zone: "America/Fortaleza",
    name: "Parnaíba International Airport",
    longitude: -41.730897,
    latitude: -2.894499,
    id: "arp_phb_br",
    icao_code: "SBPB",
    iata_country_code: "BR",
    iata_code: "PHB",
    city_name: "Parnaíba",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Parndana Airport",
    longitude: 137.264161,
    latitude: -35.807757,
    id: "arp_pdn_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "PDN",
    city_name: "Parndana",
    city: null,
  },
  {
    time_zone: "Europe/Tallinn",
    name: "Pärnu Airport",
    longitude: 24.469324,
    latitude: 58.420458,
    id: "arp_epu_ee",
    icao_code: "EEPU",
    iata_country_code: "EE",
    iata_code: "EPU",
    city_name: "Pärnu",
    city: null,
  },
  {
    time_zone: "Asia/Thimphu",
    name: "Paro International Airport",
    longitude: 89.42355,
    latitude: 27.403846,
    id: "arp_pbh_bt",
    icao_code: "VQPR",
    iata_country_code: "BT",
    iata_code: "PBH",
    city_name: "Paro",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Paros National Airport",
    longitude: 25.11725,
    latitude: 37.020758,
    id: "arp_pas_gr",
    icao_code: "LGPA",
    iata_country_code: "GR",
    iata_code: "PAS",
    city_name: "Paros",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Parry Sound Area Municipal Airport",
    longitude: -79.828903,
    latitude: 45.255928,
    id: "arp_ypd_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YPD",
    city_name: "Parry Sound",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Parsabade Moghan Airport",
    longitude: 47.88066,
    latitude: 39.604421,
    id: "arp_pfq_ir",
    icao_code: "OITP",
    iata_country_code: "IR",
    iata_code: "PFQ",
    city_name: "Parsabad",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Paruima Airport",
    longitude: -61.056003,
    latitude: 5.816153,
    id: "arp_prr_gy",
    icao_code: "SYPR",
    iata_country_code: "GY",
    iata_code: "PRR",
    city_name: "Paruima",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Pasighat Airport",
    longitude: 95.335223,
    latitude: 28.06712,
    id: "arp_ixt_in",
    icao_code: "VEPG",
    iata_country_code: "IN",
    iata_code: "IXT",
    city_name: "Pasighat",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Pasir Gudang Port Airport",
    longitude: 102.083752,
    latitude: 5.95024,
    id: "arp_zlw_my",
    icao_code: null,
    iata_country_code: "MY",
    iata_code: "ZLW",
    city_name: "Pasir Gudang",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Pasir Pangaraan Airport",
    longitude: 100.369065,
    latitude: 0.845505,
    id: "arp_ppr_id",
    icao_code: "WIDE",
    iata_country_code: "ID",
    iata_code: "PPR",
    city_name: "Pasir Pangarayan",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Pasni Airport",
    longitude: 63.344463,
    latitude: 25.29047,
    id: "arp_psi_pk",
    icao_code: "OPPI",
    iata_country_code: "PK",
    iata_code: "PSI",
    city_name: "Pasni",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Paso de los Libres Airport",
    longitude: -57.152612,
    latitude: -29.688786,
    id: "arp_aol_ar",
    icao_code: "SARL",
    iata_country_code: "AR",
    iata_code: "AOL",
    city_name: "Paso de los Libres",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Paso Robles Municipal Airport",
    longitude: -120.633217,
    latitude: 35.671988,
    id: "arp_prb_us",
    icao_code: "KPRB",
    iata_country_code: "US",
    iata_code: "PRB",
    city_name: "Paso Robles",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Passikudah Helipad Airport",
    longitude: 81.57134,
    latitude: 7.924488,
    id: "arp_pqd_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "PQD",
    city_name: "Batticaloa",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Pathankot Airport",
    longitude: 75.633509,
    latitude: 32.234409,
    id: "arp_ixp_in",
    icao_code: "VIPK",
    iata_country_code: "IN",
    iata_code: "IXP",
    city_name: "Pathankot",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Pathein Airport",
    longitude: 94.777624,
    latitude: 16.814659,
    id: "arp_bsx_mm",
    icao_code: "VYPN",
    iata_country_code: "MM",
    iata_code: "BSX",
    city_name: "Pathein",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Patna Airport",
    longitude: 85.088938,
    latitude: 25.59234,
    id: "arp_pat_in",
    icao_code: "VEPT",
    iata_country_code: "IN",
    iata_code: "PAT",
    city_name: "Patna",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Pato Branco Airport",
    longitude: -52.693037,
    latitude: -26.216785,
    id: "arp_pto_br",
    icao_code: "SSPB",
    iata_country_code: "BR",
    iata_code: "PTO",
    city_name: "Pato Branco",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Patos de Minas Airport",
    longitude: -46.491797,
    latitude: -18.672047,
    id: "arp_poj_br",
    icao_code: "SNPD",
    iata_country_code: "BR",
    iata_code: "POJ",
    city_name: "Patos de Minas",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Patreksfjörður Airport",
    longitude: -23.965,
    latitude: 65.555801,
    id: "arp_pfj_is",
    icao_code: "BIPA",
    iata_country_code: "IS",
    iata_code: "PFJ",
    city_name: "Patreksfjörður",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Patrick Air Force Base",
    longitude: -80.609889,
    latitude: 28.23673,
    id: "arp_cof_us",
    icao_code: "KCOF",
    iata_country_code: "US",
    iata_code: "COF",
    city_name: "Cocoa Beach",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Pattani Airport",
    longitude: 101.153245,
    latitude: 6.785651,
    id: "arp_pan_th",
    icao_code: "VTSK",
    iata_country_code: "TH",
    iata_code: "PAN",
    city_name: "Pattani",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Pattimura Airport",
    longitude: 128.088885,
    latitude: -3.70779,
    id: "arp_amq_id",
    icao_code: "WAPP",
    iata_country_code: "ID",
    iata_code: "AMQ",
    city_name: "Ambon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Patuxent River Naval Air Station",
    longitude: -76.411797,
    latitude: 38.285999,
    id: "arp_nhk_us",
    icao_code: "KNHK",
    iata_country_code: "US",
    iata_code: "NHK",
    city_name: "Patuxent River",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Pauk Airport",
    longitude: 94.48875,
    latitude: 21.449557,
    id: "arp_pau_mm",
    icao_code: "VYPK",
    iata_country_code: "MM",
    iata_code: "PAU",
    city_name: "Pauk",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Paulatuk",
    longitude: -124.076451,
    latitude: 69.36106,
    id: "arp_ypc_ca",
    icao_code: "CYPC",
    iata_country_code: "CA",
    iata_code: "YPC",
    city_name: "Paulatuk",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Paulo Afonso Airport",
    longitude: -38.249677,
    latitude: -9.40061,
    id: "arp_pav_br",
    icao_code: "SBUF",
    iata_country_code: "BR",
    iata_code: "PAV",
    city_name: "Paulo Afonso",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Pau Pyrénées Airport",
    longitude: -0.417319,
    latitude: 43.380684,
    id: "arp_puf_fr",
    icao_code: "LFBP",
    iata_country_code: "FR",
    iata_code: "PUF",
    city_name: "Pau",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Pavlodar Airport",
    longitude: 77.070131,
    latitude: 52.195185,
    id: "arp_pwq_kz",
    icao_code: "UASP",
    iata_country_code: "KZ",
    iata_code: "PWQ",
    city_name: "Pavlodar",
    city: null,
  },
  {
    time_zone: "Asia/Singapore",
    name: "Paya Lebar Air Base",
    longitude: 103.910629,
    latitude: 1.359288,
    id: "arp_qpg_sg",
    icao_code: "WSAP",
    iata_country_code: "SG",
    iata_code: "QPG",
    city_name: "Singapore",
    city: {
      name: "Singapore",
      id: "cit_sin_sg",
      iata_country_code: "SG",
      iata_code: "SIN",
    },
  },
  {
    time_zone: "Asia/Tehran",
    name: "Payam International Airport",
    longitude: 50.826698,
    latitude: 35.7761,
    id: "arp_pyk_ir",
    icao_code: "OIIP",
    iata_country_code: "IR",
    iata_code: "PYK",
    city_name: "Karaj",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Payán Airport",
    longitude: -78.1667,
    latitude: 1.8,
    id: "arp_pyn_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "PYN",
    city_name: "Payan",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Payson Airport",
    longitude: -111.339138,
    latitude: 34.256438,
    id: "arp_pjb_us",
    icao_code: "KPAN",
    iata_country_code: "US",
    iata_code: "PJB",
    city_name: "Payson",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Paz de Ariporo Airport",
    longitude: -71.888704,
    latitude: 5.877646,
    id: "arp_pza_co",
    icao_code: "SKPZ",
    iata_country_code: "CO",
    iata_code: "PZA",
    city_name: "Paz De Ariporo",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Peace River Airport",
    longitude: -117.448046,
    latitude: 56.227448,
    id: "arp_ype_ca",
    icao_code: "CYPE",
    iata_country_code: "CA",
    iata_code: "YPE",
    city_name: "Peace River",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pearl River County Airport",
    longitude: -89.504255,
    latitude: 30.786101,
    id: "arp_pcu_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PCU",
    city_name: "Poplarville",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Peawanuck Airport",
    longitude: -85.442475,
    latitude: 54.988303,
    id: "arp_ypo_ca",
    icao_code: "CYPO",
    iata_country_code: "CA",
    iata_code: "YPO",
    city_name: "Peawanuck",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Pebane Airport",
    longitude: 38.1833,
    latitude: -17.25,
    id: "arp_peb_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "PEB",
    city_name: "Pebane",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Pechora Airport",
    longitude: 57.131369,
    latitude: 65.119894,
    id: "arp_pex_ru",
    icao_code: "UUYP",
    iata_country_code: "RU",
    iata_code: "PEX",
    city_name: "Pechora",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pecos Municipal Airport",
    longitude: -103.511622,
    latitude: 31.384365,
    id: "arp_peq_us",
    icao_code: "KPEQ",
    iata_country_code: "US",
    iata_code: "PEQ",
    city_name: "Pecos",
    city: null,
  },
  {
    time_zone: "Europe/Budapest",
    name: "Pécs-Pogány International Airport",
    longitude: 18.238736,
    latitude: 45.991826,
    id: "arp_pev_hu",
    icao_code: "LHPP",
    iata_country_code: "HU",
    iata_code: "PEV",
    city_name: "Pécs-Pogány",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Pedasí Airport",
    longitude: -80.022869,
    latitude: 7.556139,
    id: "arp_pdm_pa",
    icao_code: "MPPD",
    iata_country_code: "PA",
    iata_code: "PDM",
    city_name: "Pedasi",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Pedernales Airport",
    longitude: -62.233859,
    latitude: 9.977778,
    id: "arp_pdz_ve",
    icao_code: "SVPE",
    iata_country_code: "VE",
    iata_code: "PDZ",
    city_name: "Pedernales",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Pedro Bay Airport",
    longitude: -154.126052,
    latitude: 59.792157,
    id: "arp_pdb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PDB",
    city_name: "Pedro Bay",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Peenemünde Airport",
    longitude: 13.775458,
    latitude: 54.156045,
    id: "arp_pef_de",
    icao_code: "EDCP",
    iata_country_code: "DE",
    iata_code: "PEF",
    city_name: "Peenemuende",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Pelaneng Airport",
    longitude: 28.49763,
    latitude: -29.08814,
    id: "arp_pel_ls",
    icao_code: "FXPG",
    iata_country_code: "LS",
    iata_code: "PEL",
    city_name: "Pelaneng",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Pelican Seaplane Base Airport",
    longitude: -136.236133,
    latitude: 57.955186,
    id: "arp_pec_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PEC",
    city_name: "Pelican",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Pellston Regional Airport",
    longitude: -84.791441,
    latitude: 45.571195,
    id: "arp_pln_us",
    icao_code: "KPLN",
    iata_country_code: "US",
    iata_code: "PLN",
    city_name: "Pellston",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Pemba Airport",
    longitude: 39.811682,
    latitude: -5.257848,
    id: "arp_pma_tz",
    icao_code: "HTPE",
    iata_country_code: "TZ",
    iata_code: "PMA",
    city_name: "Pemba",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Pemba Airport",
    longitude: 40.524123,
    latitude: -12.991738,
    id: "arp_pol_mz",
    icao_code: "FQPB",
    iata_country_code: "MZ",
    iata_code: "POL",
    city_name: "Pemba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pembina Municipal Airport",
    longitude: -97.2406,
    latitude: 48.944525,
    id: "arp_pmb_us",
    icao_code: "KPMB",
    iata_country_code: "US",
    iata_code: "PMB",
    city_name: "Pembina",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Pembroke Airport",
    longitude: -77.251717,
    latitude: 45.86448,
    id: "arp_yta_ca",
    icao_code: "CYTA",
    iata_country_code: "CA",
    iata_code: "YTA",
    city_name: "Pembroke",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Penang International Airport",
    longitude: 100.273363,
    latitude: 5.295929,
    id: "arp_pen_my",
    icao_code: "WMKP",
    iata_country_code: "MY",
    iata_code: "PEN",
    city_name: "Penang",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Pender Harbour Water Airport",
    longitude: -124.02488,
    latitude: 49.623787,
    id: "arp_ypt_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YPT",
    city_name: "Pender Harbour",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Pendopo Airport",
    longitude: 103.879997,
    latitude: -3.28607,
    id: "arp_pdo_id",
    icao_code: "WIPQ",
    iata_country_code: "ID",
    iata_code: "PDO",
    city_name: "Pendopo",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Penggung Airport",
    longitude: 108.539964,
    latitude: -6.75551,
    id: "arp_cbn_id",
    icao_code: "WICD",
    iata_country_code: "ID",
    iata_code: "CBN",
    city_name: "Cirebon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Penglai Shahekou Airport",
    longitude: 120.986246,
    latitude: 37.657604,
    id: "arp_pnj_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "PNJ",
    city_name: "Penglai",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Penneshaw Airport",
    longitude: 137.963366,
    latitude: -35.755679,
    id: "arp_pea_au",
    icao_code: "YPSH",
    iata_country_code: "AU",
    iata_code: "PEA",
    city_name: "Penneshaw",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Penn Valley Airport",
    longitude: -76.861195,
    latitude: 40.81753,
    id: "arp_seg_us",
    icao_code: "KSEG",
    iata_country_code: "US",
    iata_code: "SEG",
    city_name: "Selinsgrove",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Penong Airport",
    longitude: 133.00118,
    latitude: -31.922669,
    id: "arp_pey_au",
    icao_code: "YPNG",
    iata_country_code: "AU",
    iata_code: "PEY",
    city_name: "Penong",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pensacola International Airport",
    longitude: -87.186652,
    latitude: 30.473608,
    id: "arp_pns_us",
    icao_code: "KPNS",
    iata_country_code: "US",
    iata_code: "PNS",
    city_name: "Pensacola",
    city: {
      name: "Pensacola",
      id: "cit_pns_us",
      iata_country_code: "US",
      iata_code: "PNS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Pensacola Naval Air Station/Forrest Sherman Field",
    longitude: -87.318604,
    latitude: 30.352699,
    id: "arp_npa_us",
    icao_code: "KNPA",
    iata_country_code: "US",
    iata_code: "NPA",
    city_name: "Pensacola",
    city: {
      name: "Pensacola",
      id: "cit_pns_us",
      iata_country_code: "US",
      iata_code: "PNS",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Penticton Regional Airport",
    longitude: -119.602893,
    latitude: 49.462462,
    id: "arp_yyf_ca",
    icao_code: "CYYF",
    iata_country_code: "CA",
    iata_code: "YYF",
    city_name: "Penticton",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Penza Airport",
    longitude: 45.02207,
    latitude: 53.11446,
    id: "arp_pez_ru",
    icao_code: "UWPP",
    iata_country_code: "RU",
    iata_code: "PEZ",
    city_name: "Penza",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Peppimenarti Airport",
    longitude: 130.090906,
    latitude: -14.14442,
    id: "arp_pep_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "PEP",
    city_name: "Peppimenarti",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Perales Airport",
    longitude: -75.136207,
    latitude: 4.423145,
    id: "arp_ibe_co",
    icao_code: "SKIB",
    iata_country_code: "CO",
    iata_code: "IBE",
    city_name: "Ibagué",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Périgueux-Bassillac Airport",
    longitude: 0.814587,
    latitude: 45.197509,
    id: "arp_pgx_fr",
    icao_code: "LFBX",
    iata_country_code: "FR",
    iata_code: "PGX",
    city_name: "Perigueux",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Perito Moreno Airport",
    longitude: -70.979606,
    latitude: -46.537373,
    id: "arp_pmq_ar",
    icao_code: "SAWP",
    iata_country_code: "AR",
    iata_code: "PMQ",
    city_name: "Perito Moreno",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Perm International Airport",
    longitude: 56.018073,
    latitude: 57.916218,
    id: "arp_pee_ru",
    icao_code: "USPP",
    iata_country_code: "RU",
    iata_code: "PEE",
    city_name: "Perm",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Perpignan–Rivesaltes Airport",
    longitude: 2.870354,
    latitude: 42.74073,
    id: "arp_pgf_fr",
    icao_code: "LFMP",
    iata_country_code: "FR",
    iata_code: "PGF",
    city_name: "Perpignan",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Perry-Foley Airport",
    longitude: -83.578266,
    latitude: 30.074833,
    id: "arp_fpy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "FPY",
    city_name: "Perry",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Perry Island Seaplane Base",
    longitude: -147.918871,
    latitude: 60.685293,
    id: "arp_pyl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PYL",
    city_name: "Perry Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Perry Lefors Field",
    longitude: -100.996131,
    latitude: 35.613017,
    id: "arp_ppa_us",
    icao_code: "KPPA",
    iata_country_code: "US",
    iata_code: "PPA",
    city_name: "Pampa",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Perry Municipal Airport",
    longitude: -94.15995,
    latitude: 41.828027,
    id: "arp_pro_us",
    icao_code: "KPRO",
    iata_country_code: "US",
    iata_code: "PRO",
    city_name: "Perry",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Perry Stokes Airport",
    longitude: -104.337993,
    latitude: 37.259651,
    id: "arp_tad_us",
    icao_code: "KTAD",
    iata_country_code: "US",
    iata_code: "TAD",
    city_name: "Trinidad",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Perryville Airport",
    longitude: -159.158083,
    latitude: 55.907748,
    id: "arp_kpv_us",
    icao_code: "PAPE",
    iata_country_code: "US",
    iata_code: "KPV",
    city_name: "Perryville",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Persian Gulf Airport",
    longitude: 52.736202,
    latitude: 27.382536,
    id: "arp_pgu_ir",
    icao_code: "OIBP",
    iata_country_code: "IR",
    iata_code: "PGU",
    city_name: "Asaloyeh",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Perth Airport",
    longitude: 115.967126,
    latitude: -31.939424,
    id: "arp_per_au",
    icao_code: "YPPH",
    iata_country_code: "AU",
    iata_code: "PER",
    city_name: "Perth",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Perth/Scone Airport",
    longitude: -3.372594,
    latitude: 56.438849,
    id: "arp_psl_gb",
    icao_code: "EGPT",
    iata_country_code: "GB",
    iata_code: "PSL",
    city_name: "Perth",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Perugia San Francesco d'Assisi – Umbria International Airport",
    longitude: 12.50804,
    latitude: 43.095456,
    id: "arp_peg_it",
    icao_code: "LIRZ",
    iata_country_code: "IT",
    iata_code: "PEG",
    city_name: "Perugia",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Petawawa Airport",
    longitude: -77.3186,
    latitude: 45.951952,
    id: "arp_ywa_ca",
    icao_code: "CYWA",
    iata_country_code: "CA",
    iata_code: "YWA",
    city_name: "Petawawa",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Peterborough Airport",
    longitude: -78.361231,
    latitude: 44.231921,
    id: "arp_ypq_ca",
    icao_code: "CYPQ",
    iata_country_code: "CA",
    iata_code: "YPQ",
    city_name: "Peterborough",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Peter O. Knight Airport",
    longitude: -82.449349,
    latitude: 27.915406,
    id: "arp_tpf_us",
    icao_code: "KTPF",
    iata_country_code: "US",
    iata_code: "TPF",
    city_name: "Tampa",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "America/Sitka",
    name: "Petersburg James A. Johnson Airport",
    longitude: -132.945874,
    latitude: 56.801686,
    id: "arp_psg_us",
    icao_code: "PAPG",
    iata_country_code: "US",
    iata_code: "PSG",
    city_name: "Petersburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Petit Jean Park Airport",
    longitude: -92.909063,
    latitude: 35.139068,
    id: "arp_mpj_us",
    icao_code: "KMPJ",
    iata_country_code: "US",
    iata_code: "MPJ",
    city_name: "Morrilton",
    city: null,
  },
  {
    time_zone: "America/Recife",
    name: "Petrolina Airport",
    longitude: -40.567315,
    latitude: -9.364,
    id: "arp_pnz_br",
    icao_code: "SBPL",
    iata_country_code: "BR",
    iata_code: "PNZ",
    city_name: "Petrolina",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Petropavl Airport",
    longitude: 69.184568,
    latitude: 54.775551,
    id: "arp_ppk_kz",
    icao_code: "UACP",
    iata_country_code: "KZ",
    iata_code: "PPK",
    city_name: "Petropavlosk",
    city: null,
  },
  {
    time_zone: "Asia/Kamchatka",
    name: "Petropavlovsk-Kamchatsky Airport",
    longitude: 158.427631,
    latitude: 53.170067,
    id: "arp_pkc_ru",
    icao_code: "UHPP",
    iata_country_code: "RU",
    iata_code: "PKC",
    city_name: "Petropavlovsk-Kamchatsky",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Petrozavodsk Airport",
    longitude: 34.155193,
    latitude: 61.882741,
    id: "arp_pes_ru",
    icao_code: "ULPB",
    iata_country_code: "RU",
    iata_code: "PES",
    city_name: "Petrozavodsk",
    city: null,
  },
  {
    time_zone: "Asia/Anadyr",
    name: "Pevek Airport",
    longitude: 170.596967,
    latitude: 69.783223,
    id: "arp_pwe_ru",
    icao_code: "UHMP",
    iata_country_code: "RU",
    iata_code: "PWE",
    city_name: "Pevek",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Phan Rang Airport",
    longitude: 109.216662,
    latitude: 12.006345,
    id: "arp_pha_vn",
    icao_code: "VVPR",
    iata_country_code: "VN",
    iata_code: "PHA",
    city_name: "Phan Rang",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Phaplu Airport",
    longitude: 86.585095,
    latitude: 27.518095,
    id: "arp_ppl_np",
    icao_code: "VNPL",
    iata_country_code: "NP",
    iata_code: "PPL",
    city_name: "Phaplu",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Phetchabun Airport",
    longitude: 101.192625,
    latitude: 16.676043,
    id: "arp_phy_th",
    icao_code: "VTPB",
    iata_country_code: "TH",
    iata_code: "PHY",
    city_name: "Phetchabun",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Phifer Airfield",
    longitude: -104.934921,
    latitude: 42.054223,
    id: "arp_ean_us",
    icao_code: "KEAN",
    iata_country_code: "US",
    iata_code: "EAN",
    city_name: "Wheatland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Philadelphia International Airport",
    longitude: -75.241759,
    latitude: 39.873147,
    id: "arp_phl_us",
    icao_code: "KPHL",
    iata_country_code: "US",
    iata_code: "PHL",
    city_name: "Philadelphia",
    city: {
      name: "Philadelphia",
      id: "cit_phl_us",
      iata_country_code: "US",
      iata_code: "PHL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Philadelphia Seaplane Base",
    longitude: -75.299528,
    latitude: 39.859034,
    id: "arp_psq_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PSQ",
    city_name: "Philadelphia",
    city: {
      name: "Philadelphia",
      id: "cit_phl_us",
      iata_country_code: "US",
      iata_code: "PHL",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Philip Airport",
    longitude: -101.6001,
    latitude: 44.049538,
    id: "arp_php_us",
    icao_code: "KPHP",
    iata_country_code: "US",
    iata_code: "PHP",
    city_name: "Philip",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Philip Billard Municipal Airport",
    longitude: -95.622366,
    latitude: 39.06909,
    id: "arp_top_us",
    icao_code: "KTOP",
    iata_country_code: "US",
    iata_code: "TOP",
    city_name: "Topeka",
    city: {
      name: "Topeka",
      id: "cit_top_us",
      iata_country_code: "US",
      iata_code: "TOP",
    },
  },
  {
    time_zone: "America/Belize",
    name: "Philip S. W. Goldson International Airport",
    longitude: -88.308539,
    latitude: 17.537595,
    id: "arp_bze_bz",
    icao_code: "MZBZ",
    iata_country_code: "BZ",
    iata_code: "BZE",
    city_name: "Belize City",
    city: {
      name: "Belize City",
      id: "cit_bze_bz",
      iata_country_code: "BZ",
      iata_code: "BZE",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Phillips Army Airfield",
    longitude: -76.169262,
    latitude: 39.466156,
    id: "arp_apg_us",
    icao_code: "KAPG",
    iata_country_code: "US",
    iata_code: "APG",
    city_name: "Aberdeen",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Phitsanulok Airport",
    longitude: 100.279055,
    latitude: 16.782951,
    id: "arp_phs_th",
    icao_code: "VTPP",
    iata_country_code: "TH",
    iata_code: "PHS",
    city_name: "Phitsanulok",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Phnom Penh International Airport",
    longitude: 104.842643,
    latitude: 11.547841,
    id: "arp_pnh_kh",
    icao_code: "VDPP",
    iata_country_code: "KH",
    iata_code: "PNH",
    city_name: "Phnom Penh",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Phoenix Deer Valley Airport",
    longitude: -112.082768,
    latitude: 33.687314,
    id: "arp_dvt_us",
    icao_code: "KDVT",
    iata_country_code: "US",
    iata_code: "DVT",
    city_name: "Phoenix",
    city: {
      name: "Phoenix",
      id: "cit_phx_us",
      iata_country_code: "US",
      iata_code: "PHX",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Phoenix Goodyear Airport",
    longitude: -112.374536,
    latitude: 33.423516,
    id: "arp_gyr_us",
    icao_code: "KGYR",
    iata_country_code: "US",
    iata_code: "GYR",
    city_name: "Goodyear",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Phoenix-Mesa-Gateway Airport",
    longitude: -111.656194,
    latitude: 33.308273,
    id: "arp_aza_us",
    icao_code: "KIWA",
    iata_country_code: "US",
    iata_code: "AZA",
    city_name: "Phoenix",
    city: {
      name: "Phoenix",
      id: "cit_phx_us",
      iata_country_code: "US",
      iata_code: "PHX",
    },
  },
  {
    time_zone: "America/Phoenix",
    name: "Phoenix Sky Harbor International Airport",
    longitude: -112.009894,
    latitude: 33.435784,
    id: "arp_phx_us",
    icao_code: "KPHX",
    iata_country_code: "US",
    iata_code: "PHX",
    city_name: "Phoenix",
    city: {
      name: "Phoenix",
      id: "cit_phx_us",
      iata_country_code: "US",
      iata_code: "PHX",
    },
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Phrae Airport",
    longitude: 100.163992,
    latitude: 18.131967,
    id: "arp_prh_th",
    icao_code: "VTCP",
    iata_country_code: "TH",
    iata_code: "PRH",
    city_name: "Phrae",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Phu Bai International Airport",
    longitude: 107.701819,
    latitude: 16.399578,
    id: "arp_hui_vn",
    icao_code: "VVPB",
    iata_country_code: "VN",
    iata_code: "HUI",
    city_name: "Hue",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Phu Cat Airport",
    longitude: 109.045199,
    latitude: 13.953878,
    id: "arp_uih_vn",
    icao_code: "VVPC",
    iata_country_code: "VN",
    iata_code: "UIH",
    city_name: "Qui Nhơn",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Phuket International Airport",
    longitude: 98.309737,
    latitude: 8.112327,
    id: "arp_hkt_th",
    icao_code: "VTSP",
    iata_country_code: "TH",
    iata_code: "HKT",
    city_name: "Phuket",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Phuoclong Airport",
    longitude: 105.467,
    latitude: 9.43333,
    id: "arp_vso_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "VSO",
    city_name: "Phuoc Long",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Phuoc Vinh Airport",
    longitude: 106.794495,
    latitude: 11.300717,
    id: "arp_phu_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "PHU",
    city_name: "Phuoc Vinh",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Phu Quoc International Airport",
    longitude: 103.995614,
    latitude: 10.166478,
    id: "arp_pqc_vn",
    icao_code: "VVPQ",
    iata_country_code: "VN",
    iata_code: "PQC",
    city_name: "Phu Quoc Island",
    city: null,
  },
  {
    time_zone: "America/Port_of_Spain",
    name: "Piarco International Airport",
    longitude: -61.338363,
    latitude: 10.596548,
    id: "arp_pos_tt",
    icao_code: "TTPP",
    iata_country_code: "TT",
    iata_code: "POS",
    city_name: "Port of Spain",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Pichoy Airport",
    longitude: -73.085003,
    latitude: -39.649953,
    id: "arp_zal_cl",
    icao_code: "SCVD",
    iata_country_code: "CL",
    iata_code: "ZAL",
    city_name: "Valdivia",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pickens County Airport",
    longitude: -82.70253,
    latitude: 34.810041,
    id: "arp_lqk_us",
    icao_code: "KLQK",
    iata_country_code: "US",
    iata_code: "LQK",
    city_name: "Pickens",
    city: null,
  },
  {
    time_zone: "America/Coral_Harbour",
    name: "Pickle Lake Airport",
    longitude: -90.214243,
    latitude: 51.446593,
    id: "arp_ypl_ca",
    icao_code: "CYPL",
    iata_country_code: "CA",
    iata_code: "YPL",
    city_name: "Pickle Lake",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Pico Airport",
    longitude: -28.442312,
    latitude: 38.553313,
    id: "arp_pix_pt",
    icao_code: "LPPI",
    iata_country_code: "PT",
    iata_code: "PIX",
    city_name: "Pico Island",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Picos Airport",
    longitude: -41.522165,
    latitude: -7.062703,
    id: "arp_pcs_br",
    icao_code: "SNPC",
    iata_country_code: "BR",
    iata_code: "PCS",
    city_name: "Picos",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Picton Aerodrome",
    longitude: 173.956956,
    latitude: -41.345166,
    id: "arp_pcn_nz",
    icao_code: "NZPN",
    iata_country_code: "NZ",
    iata_code: "PCN",
    city_name: "Picton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Piedmont Triad International Airport",
    longitude: -79.936241,
    latitude: 36.101084,
    id: "arp_gso_us",
    icao_code: "KGSO",
    iata_country_code: "US",
    iata_code: "GSO",
    city_name: "Greensboro",
    city: null,
  },
  {
    time_zone: "America/Matamoros",
    name: "Piedras Negras International Airport",
    longitude: -100.539778,
    latitude: 28.628311,
    id: "arp_pds_mx",
    icao_code: "MMPG",
    iata_country_code: "MX",
    iata_code: "PDS",
    city_name: "Piedras Negras",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Pierce County Airport",
    longitude: -43.952745,
    latitude: -19.850524,
    id: "arp_plu_br",
    icao_code: "SBBH",
    iata_country_code: "BR",
    iata_code: "PLU",
    city_name: "Belo Horizonte",
    city: {
      name: "Belo Horizonte",
      id: "cit_bhz_br",
      iata_country_code: "BR",
      iata_code: "BHZ",
    },
  },
  {
    time_zone: "Indian/Reunion",
    name: "Pierrefonds Airport",
    longitude: 55.424314,
    latitude: -21.320823,
    id: "arp_zse_re",
    icao_code: "FMEP",
    iata_country_code: "RE",
    iata_code: "ZSE",
    city_name: "Saint-Pierre",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pierre Regional Airport",
    longitude: -100.285918,
    latitude: 44.3826,
    id: "arp_pir_us",
    icao_code: "KPIR",
    iata_country_code: "US",
    iata_code: "PIR",
    city_name: "Pierre",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Piešťany Airport",
    longitude: 17.83205,
    latitude: 48.611286,
    id: "arp_pzy_sk",
    icao_code: "LZPP",
    iata_country_code: "SK",
    iata_code: "PZY",
    city_name: "Piestany",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Pietermaritzburg Airport",
    longitude: 30.398096,
    latitude: -29.647449,
    id: "arp_pzb_za",
    icao_code: "FAPM",
    iata_country_code: "ZA",
    iata_code: "PZB",
    city_name: "Pietermaritzburg",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Pikangikum Airport",
    longitude: -93.973705,
    latitude: 51.819854,
    id: "arp_ypm_ca",
    icao_code: "CYPM",
    iata_country_code: "CA",
    iata_code: "YPM",
    city_name: "Pikangikum",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pike County Airport",
    longitude: -82.565916,
    latitude: 37.560823,
    id: "arp_pvl_us",
    icao_code: "KPBX",
    iata_country_code: "US",
    iata_code: "PVL",
    city_name: "Pikeville",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Pikwitonei Airport",
    longitude: -97.164135,
    latitude: 55.589095,
    id: "arp_piw_ca",
    icao_code: "CZMN",
    iata_country_code: "CA",
    iata_code: "PIW",
    city_name: "Pikwitonei",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Pilanesberg International Airport",
    longitude: 27.172473,
    latitude: -25.334649,
    id: "arp_nty_za",
    icao_code: "FAPN",
    iata_country_code: "ZA",
    iata_code: "NTY",
    city_name: "Sun City",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Piloto Civil Norberto Fernández International Airport",
    longitude: -69.30884,
    latitude: -51.610246,
    id: "arp_rgl_ar",
    icao_code: "SAWG",
    iata_country_code: "AR",
    iata_code: "RGL",
    city_name: "Rio Gallegos",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Pilot Point Airport",
    longitude: -157.571052,
    latitude: 57.579992,
    id: "arp_pip_us",
    icao_code: "PAPN",
    iata_country_code: "US",
    iata_code: "PIP",
    city_name: "Pilot Point",
    city: {
      name: "Pilot Point",
      id: "cit_pip_us",
      iata_country_code: "US",
      iata_code: "PIP",
    },
  },
  {
    time_zone: "America/Nome",
    name: "Pilot Station Airport",
    longitude: -162.899936,
    latitude: 61.934559,
    id: "arp_pqs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PQS",
    city_name: "Pilot Station",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pimaga Airport",
    longitude: 143.509838,
    latitude: -6.498612,
    id: "arp_pmp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PMP",
    city_name: "Pimaga",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Pimenta Bueno Airport",
    longitude: -61.1791,
    latitude: -11.641599,
    id: "arp_pbq_br",
    icao_code: "SWPM",
    iata_country_code: "BR",
    iata_code: "PBQ",
    city_name: "Pimenta Bueno",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Pinal Airpark",
    longitude: -111.326635,
    latitude: 32.510638,
    id: "arp_mzj_us",
    icao_code: "KMZJ",
    iata_country_code: "US",
    iata_code: "MZJ",
    city_name: "Marana",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Pinang Kampai Airport",
    longitude: 101.433872,
    latitude: 1.610026,
    id: "arp_dum_id",
    icao_code: "WIBD",
    iata_country_code: "ID",
    iata_code: "DUM",
    city_name: "Dumai",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Pincher Creek Airport",
    longitude: -113.997075,
    latitude: 49.520584,
    id: "arp_wpc_ca",
    icao_code: "CZPC",
    iata_country_code: "CA",
    iata_code: "WPC",
    city_name: "Pincher Creek",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pindiu Airport",
    longitude: 147.514973,
    latitude: -6.445244,
    id: "arp_pdi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PDI",
    city_name: "Pindiu",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "Pine Cay Airport",
    longitude: -72.092726,
    latitude: 21.875392,
    id: "arp_pic_tc",
    icao_code: "MBPI",
    iata_country_code: "TC",
    iata_code: "PIC",
    city_name: "Pine Cay",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Pinehouse Lake Airport",
    longitude: -106.582225,
    latitude: 55.528087,
    id: "arp_zpo_ca",
    icao_code: "CZPO",
    iata_country_code: "CA",
    iata_code: "ZPO",
    city_name: "Pinehouse",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pine Island Airport",
    longitude: -75.786495,
    latitude: 36.253522,
    id: "arp_duf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DUF",
    city_name: "Corolla",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Pine Ridge Airport",
    longitude: -102.511055,
    latitude: 43.022499,
    id: "arp_xpr_us",
    icao_code: "KIEN",
    iata_country_code: "US",
    iata_code: "XPR",
    city_name: "Pine Ridge",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Pingtung Airport",
    longitude: 120.476984,
    latitude: 22.697723,
    id: "arp_pif_tw",
    icao_code: "RCSQ",
    iata_country_code: "TW",
    iata_code: "PIF",
    city_name: "Pingtung",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Pinheiro Airport",
    longitude: -45.098811,
    latitude: -2.472766,
    id: "arp_phi_br",
    icao_code: "SNYE",
    iata_country_code: "BR",
    iata_code: "PHI",
    city_name: "Pinheiro",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Pinto Martins International Airport",
    longitude: -38.532765,
    latitude: -3.776572,
    id: "arp_for_br",
    icao_code: "SBFZ",
    iata_country_code: "BR",
    iata_code: "FOR",
    city_name: "Fortaleza",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Pipillipai Airport",
    longitude: -60.3333,
    latitude: 5.33333,
    id: "arp_piq_gy",
    icao_code: "SYPL",
    iata_country_code: "GY",
    iata_code: "PIQ",
    city_name: "Pipillipai",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Pirapora Airport",
    longitude: -44.859329,
    latitude: -17.312476,
    id: "arp_piv_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "PIV",
    city_name: "Pirapora",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Pisa International Airport",
    longitude: 10.395292,
    latitude: 43.686491,
    id: "arp_psa_it",
    icao_code: "LIRP",
    iata_country_code: "IT",
    iata_code: "PSA",
    city_name: "Pisa",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Pisco Airport",
    longitude: -76.22015,
    latitude: -13.745366,
    id: "arp_pio_pe",
    icao_code: "SPSO",
    iata_country_code: "PE",
    iata_code: "PIO",
    city_name: "Pisco",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Pitalito Airport",
    longitude: -76.086194,
    latitude: 1.857478,
    id: "arp_ptx_co",
    icao_code: "SKPI",
    iata_country_code: "CO",
    iata_code: "PTX",
    city_name: "Pitalito",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pitt-Greenville Airport",
    longitude: -77.382932,
    latitude: 35.634016,
    id: "arp_pgv_us",
    icao_code: "KPGV",
    iata_country_code: "US",
    iata_code: "PGV",
    city_name: "Greenville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pittsburgh-Butler Regional Airport",
    longitude: -79.950504,
    latitude: 40.778188,
    id: "arp_btp_us",
    icao_code: "KBTP",
    iata_country_code: "US",
    iata_code: "BTP",
    city_name: "Butler",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pittsburgh International Airport",
    longitude: -80.237106,
    latitude: 40.493636,
    id: "arp_pit_us",
    icao_code: "KPIT",
    iata_country_code: "US",
    iata_code: "PIT",
    city_name: "Pittsburgh",
    city: {
      name: "Pittsburgh",
      id: "cit_pit_us",
      iata_country_code: "US",
      iata_code: "PIT",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Pittsfield Municipal Airport",
    longitude: -73.292244,
    latitude: 42.426363,
    id: "arp_psf_us",
    icao_code: "KPSF",
    iata_country_code: "US",
    iata_code: "PSF",
    city_name: "Pittsfield",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Pitts Town Airport",
    longitude: -74.346876,
    latitude: 22.82971,
    id: "arp_pwn_bs",
    icao_code: "MYCP",
    iata_country_code: "BS",
    iata_code: "PWN",
    city_name: "Pitts Town",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Pitu Airport",
    longitude: 128.323056,
    latitude: 2.043964,
    id: "arp_oti_id",
    icao_code: "WAMR",
    iata_country_code: "ID",
    iata_code: "OTI",
    city_name: "Morotai Island",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Piura Airport",
    longitude: -80.617219,
    latitude: -5.206085,
    id: "arp_piu_pe",
    icao_code: "SPUR",
    iata_country_code: "PE",
    iata_code: "PIU",
    city_name: "Piura",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Placencia Airport",
    longitude: -88.361588,
    latitude: 16.535168,
    id: "arp_plj_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "PLJ",
    city_name: "Placencia",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Placerville Airport",
    longitude: -120.754355,
    latitude: 38.724165,
    id: "arp_pvf_us",
    icao_code: "KPVF",
    iata_country_code: "US",
    iata_code: "PVF",
    city_name: "Placerville",
    city: null,
  },
  {
    time_zone: "Indian/Mauritius",
    name: "Plaine Corail Airport",
    longitude: 63.359933,
    latitude: -19.756109,
    id: "arp_rrg_mu",
    icao_code: "FIMR",
    iata_country_code: "MU",
    iata_code: "RRG",
    city_name: "Rodrigues Island",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Planadas Airport",
    longitude: -75.7,
    latitude: 3.3,
    id: "arp_pla_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "PLA",
    city_name: "Planadas",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Plan de Guadalupe International Airport",
    longitude: -100.930598,
    latitude: 25.549316,
    id: "arp_slw_mx",
    icao_code: "MMIO",
    iata_country_code: "MX",
    iata_code: "SLW",
    city_name: "Saltillo",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Planeta Rica Airport",
    longitude: -75.6,
    latitude: 8.4,
    id: "arp_plc_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "PLC",
    city_name: "Planeta Rica",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Plastun Airport",
    longitude: 136.289236,
    latitude: 44.81422,
    id: "arp_tly_ru",
    icao_code: "UHWP",
    iata_country_code: "RU",
    iata_code: "TLY",
    city_name: "Plastun",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Platinum Airport",
    longitude: -161.821172,
    latitude: 59.01263,
    id: "arp_ptu_us",
    icao_code: "PAPM",
    iata_country_code: "US",
    iata_code: "PTU",
    city_name: "Platinum",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Plato Airport",
    longitude: -74.784653,
    latitude: 9.799553,
    id: "arp_plt_co",
    icao_code: "SKPL",
    iata_country_code: "CO",
    iata_code: "PLT",
    city_name: "Plato",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Plattsburgh International Airport",
    longitude: -73.467285,
    latitude: 44.65336,
    id: "arp_pbg_us",
    icao_code: "KPBG",
    iata_country_code: "US",
    iata_code: "PBG",
    city_name: "Plattsburgh",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Playa Baracoa Airport",
    longitude: -82.580622,
    latitude: 23.032565,
    id: "arp_upb_cu",
    icao_code: "MUPB",
    iata_country_code: "CU",
    iata_code: "UPB",
    city_name: "Havana",
    city: null,
  },
  {
    time_zone: "America/Cancun",
    name: "Playa del Carmen Airport",
    longitude: -87.080459,
    latitude: 20.622236,
    id: "arp_pcm_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "PCM",
    city_name: "Playa del Carmen",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Playa de Oro International Airport",
    longitude: -104.559628,
    latitude: 19.145307,
    id: "arp_zlo_mx",
    icao_code: "MMZO",
    iata_country_code: "MX",
    iata_code: "ZLO",
    city_name: "Manzanillo",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Playa Grande Airport",
    longitude: -90.742291,
    latitude: 15.998874,
    id: "arp_pkj_gt",
    icao_code: "MGPG",
    iata_country_code: "GT",
    iata_code: "PKJ",
    city_name: "Playa Grande",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Playa Samara/Carrillo Airport",
    longitude: -85.481045,
    latitude: 9.870506,
    id: "arp_pld_cr",
    icao_code: "MRCR",
    iata_country_code: "CR",
    iata_code: "PLD",
    city_name: "Carrillo/Playa Samara",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Playón Chico Airport",
    longitude: -78.23419,
    latitude: 9.309061,
    id: "arp_pyc_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "PYC",
    city_name: "Ukupseni",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Pleiku Airport",
    longitude: 108.016931,
    latitude: 14.004493,
    id: "arp_pxu_vn",
    icao_code: "VVPK",
    iata_country_code: "VN",
    iata_code: "PXU",
    city_name: "Pleiku",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Plettenberg Bay Airport",
    longitude: 23.327859,
    latitude: -34.089023,
    id: "arp_pbz_za",
    icao_code: "FAPG",
    iata_country_code: "ZA",
    iata_code: "PBZ",
    city_name: "Plettenberg Bay",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Plovdiv Airport",
    longitude: 24.850811,
    latitude: 42.067792,
    id: "arp_pdv_bg",
    icao_code: "LBPD",
    iata_country_code: "BG",
    iata_code: "PDV",
    city_name: "Plovdiv",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Plymouth City Airport",
    longitude: -4.10807,
    latitude: 50.423479,
    id: "arp_plh_gb",
    icao_code: "EGHD",
    iata_country_code: "GB",
    iata_code: "PLH",
    city_name: "Plymouth",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Plymouth Municipal Airport",
    longitude: -86.303685,
    latitude: 41.365696,
    id: "arp_ply_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PLY",
    city_name: "Plymouth",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Plymouth Municipal Airport",
    longitude: -70.729287,
    latitude: 41.909335,
    id: "arp_pym_us",
    icao_code: "KPYM",
    iata_country_code: "US",
    iata_code: "PYM",
    city_name: "Plymouth",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Po Airport",
    longitude: -1.148816,
    latitude: 11.178897,
    id: "arp_pup_bf",
    icao_code: "DFCP",
    iata_country_code: "BF",
    iata_code: "PUP",
    city_name: "Po",
    city: null,
  },
  {
    time_zone: "Europe/Kirov",
    name: "Pobedilovo Airport",
    longitude: 49.347421,
    latitude: 58.500387,
    id: "arp_kvx_ru",
    icao_code: "USKK",
    iata_country_code: "RU",
    iata_code: "KVX",
    city_name: "Kirov",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pocahontas Municipal Airport",
    longitude: -90.955157,
    latitude: 36.245376,
    id: "arp_poh_us",
    icao_code: "KPOH",
    iata_country_code: "US",
    iata_code: "POH",
    city_name: "Pocahontas",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Pocatello Regional Airport",
    longitude: -112.592375,
    latitude: 42.907752,
    id: "arp_pih_us",
    icao_code: "KPIH",
    iata_country_code: "US",
    iata_code: "PIH",
    city_name: "Pocatello",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pocono Mountains Municipal Airport",
    longitude: -75.378567,
    latitude: 41.137732,
    id: "arp_mpo_us",
    icao_code: "KMPO",
    iata_country_code: "US",
    iata_code: "MPO",
    city_name: "Mount Pocono",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Poços de Caldas Airport",
    longitude: -46.567902,
    latitude: -21.843,
    id: "arp_poo_br",
    icao_code: "SBPC",
    iata_country_code: "BR",
    iata_code: "POO",
    city_name: "Pocos de Caldas",
    city: null,
  },
  {
    time_zone: "Europe/Podgorica",
    name: "Podgorica Airport",
    longitude: 19.251822,
    latitude: 42.359323,
    id: "arp_tgd_me",
    icao_code: "LYPG",
    iata_country_code: "ME",
    iata_code: "TGD",
    city_name: "Podgorica",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Podkamennaya Tunguska Airport",
    longitude: 89.994003,
    latitude: 61.589699,
    id: "arp_tgp_ru",
    icao_code: "UNIP",
    iata_country_code: "RU",
    iata_code: "TGP",
    city_name: "Bor",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Podor Airport",
    longitude: -14.96572,
    latitude: 16.680315,
    id: "arp_pod_sn",
    icao_code: "GOSP",
    iata_country_code: "SN",
    iata_code: "POD",
    city_name: "Podor",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Pogogul Airport",
    longitude: 121.414488,
    latitude: 1.103482,
    id: "arp_uol_id",
    icao_code: "WAMY",
    iata_country_code: "ID",
    iata_code: "UOL",
    city_name: "Buol",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Pohang Airport",
    longitude: 129.434412,
    latitude: 35.984939,
    id: "arp_kpo_kr",
    icao_code: "RKTH",
    iata_country_code: "KR",
    iata_code: "KPO",
    city_name: "Pohang",
    city: null,
  },
  {
    time_zone: "Pacific/Pohnpei",
    name: "Pohnpei International Airport",
    longitude: 158.207696,
    latitude: 6.984437,
    id: "arp_pni_fm",
    icao_code: "PTPN",
    iata_country_code: "FM",
    iata_code: "PNI",
    city_name: "Pohnpei",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Point Baker Seaplane Base",
    longitude: -133.622794,
    latitude: 56.351879,
    id: "arp_kpb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KPB",
    city_name: "Point Baker",
    city: null,
  },
  {
    time_zone: "America/Guadeloupe",
    name: "Pointe-à-Pitre International Airport",
    longitude: -61.529524,
    latitude: 16.266457,
    id: "arp_ptp_gp",
    icao_code: "TFFR",
    iata_country_code: "GP",
    iata_code: "PTP",
    city_name: "Pointe-à-Pitre",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Pointe Noire Airport",
    longitude: 11.885474,
    latitude: -4.813462,
    id: "arp_pnr_cg",
    icao_code: "FCPP",
    iata_country_code: "CG",
    iata_code: "PNR",
    city_name: "Pointe-Noire",
    city: null,
  },
  {
    time_zone: "Pacific/Wallis",
    name: "Pointe Vele Airport",
    longitude: -178.06667,
    latitude: -14.311681,
    id: "arp_fut_wf",
    icao_code: "NLWF",
    iata_country_code: "WF",
    iata_code: "FUT",
    city_name: "Futuna Island",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Point Hope Airport",
    longitude: -166.79881,
    latitude: 68.348852,
    id: "arp_pho_us",
    icao_code: "PAPO",
    iata_country_code: "US",
    iata_code: "PHO",
    city_name: "Point Hope",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Point Lay LRRS Airport",
    longitude: -163.005173,
    latitude: 69.732888,
    id: "arp_piz_us",
    icao_code: "PPIZ",
    iata_country_code: "US",
    iata_code: "PIZ",
    city_name: "Point Lay",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Point Lonely Short Range Radar Site Airport",
    longitude: -153.24211,
    latitude: 70.910721,
    id: "arp_lni_us",
    icao_code: "PALN",
    iata_country_code: "US",
    iata_code: "LNI",
    city_name: "Lonely",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Point Mugu Naval Air Station",
    longitude: -119.122441,
    latitude: 34.120251,
    id: "arp_ntd_us",
    icao_code: "KNTD",
    iata_country_code: "US",
    iata_code: "NTD",
    city_name: "Point Mugu",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Points North Landing Airport",
    longitude: -104.082111,
    latitude: 58.27696,
    id: "arp_ynl_ca",
    icao_code: "CYNL",
    iata_country_code: "CA",
    iata_code: "YNL",
    city_name: "Points North Landing",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Poitiers Biard Airport",
    longitude: 0.308386,
    latitude: 46.587269,
    id: "arp_pis_fr",
    icao_code: "LFBI",
    iata_country_code: "FR",
    iata_code: "PIS",
    city_name: "Poitiers",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Pokhara Airport",
    longitude: 83.981069,
    latitude: 28.200297,
    id: "arp_pkr_np",
    icao_code: "VNPK",
    iata_country_code: "NP",
    iata_code: "PKR",
    city_name: "Pokhara",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Polacca Airport",
    longitude: -110.424469,
    latitude: 35.791196,
    id: "arp_pxl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PXL",
    city_name: "Polacca",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Polk Army Airfield",
    longitude: -93.191004,
    latitude: 31.045535,
    id: "arp_poe_us",
    icao_code: "KPOE",
    iata_country_code: "US",
    iata_code: "POE",
    city_name: "Fort Polk",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Polokwane International Airport",
    longitude: 29.454046,
    latitude: -23.85821,
    id: "arp_ptg_za",
    icao_code: "FAPP",
    iata_country_code: "ZA",
    iata_code: "PTG",
    city_name: "Polokwane",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Poltava International Airport",
    longitude: 34.39768,
    latitude: 49.571484,
    id: "arp_plv_ua",
    icao_code: "UKHP",
    iata_country_code: "UA",
    iata_code: "PLV",
    city_name: "Poltava",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Polyarny Airport",
    longitude: 112.030152,
    latitude: 66.400186,
    id: "arp_pyj_ru",
    icao_code: "UERP",
    iata_country_code: "RU",
    iata_code: "PYJ",
    city_name: "Polyarnyj",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Pomala Airport",
    longitude: 121.522665,
    latitude: -4.341933,
    id: "arp_pum_id",
    icao_code: "WAWP",
    iata_country_code: "ID",
    iata_code: "PUM",
    city_name: "Kolaka",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pompano Beach Airpark",
    longitude: -80.110961,
    latitude: 26.24648,
    id: "arp_ppm_us",
    icao_code: "KPMP",
    iata_country_code: "US",
    iata_code: "PPM",
    city_name: "Pompano Beach",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ponca City Regional Airport",
    longitude: -97.099943,
    latitude: 36.730936,
    id: "arp_pnc_us",
    icao_code: "KPNC",
    iata_country_code: "US",
    iata_code: "PNC",
    city_name: "Ponca City",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Pondicherry Airport",
    longitude: 79.812954,
    latitude: 11.967972,
    id: "arp_pny_in",
    icao_code: "VOPC",
    iata_country_code: "IN",
    iata_code: "PNY",
    city_name: "Pondicherry",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Pond Inlet Airport",
    longitude: -77.95642,
    latitude: 72.695043,
    id: "arp_yio_ca",
    icao_code: "CYIO",
    iata_country_code: "CA",
    iata_code: "YIO",
    city_name: "Pond Inlet",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Pongtiku Airport",
    longitude: 119.821171,
    latitude: -3.044248,
    id: "arp_ttr_id",
    icao_code: "WAWT",
    iata_country_code: "ID",
    iata_code: "TTR",
    city_name: "Makale",
    city: null,
  },
  {
    time_zone: "Europe/Belgrade",
    name: "Ponikve Airport",
    longitude: 19.712718,
    latitude: 43.895328,
    id: "arp_uzc_rs",
    icao_code: "LYUZ",
    iata_country_code: "RS",
    iata_code: "UZC",
    city_name: "Užice",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Ponta do Ouro Airport",
    longitude: 32.838215,
    latitude: -26.826734,
    id: "arp_pdd_mz",
    icao_code: "FQPO",
    iata_country_code: "MZ",
    iata_code: "PDD",
    city_name: "Ponta do Ouro",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Ponta Grossa Airport",
    longitude: -50.143634,
    latitude: -25.185782,
    id: "arp_pgz_br",
    icao_code: "SSZW",
    iata_country_code: "BR",
    iata_code: "PGZ",
    city_name: "Ponta Grossa",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Ponta Pelada Airport",
    longitude: -59.986128,
    latitude: -3.145536,
    id: "arp_pll_br",
    icao_code: "SBMN",
    iata_country_code: "BR",
    iata_code: "PLL",
    city_name: "Manaus",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Ponta Porã International Airport",
    longitude: -55.703816,
    latitude: -22.550508,
    id: "arp_pmg_br",
    icao_code: "SBPP",
    iata_country_code: "BR",
    iata_code: "PMG",
    city_name: "Ponta Porã",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Pontes e Lacerda Airport",
    longitude: -59.383646,
    latitude: -15.196077,
    id: "arp_lcb_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "LCB",
    city_name: "Pontes e Lacerda",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Pontoise - Cormeilles-en-Vexin Airport",
    longitude: 2.039917,
    latitude: 49.09937,
    id: "arp_pox_fr",
    icao_code: "LFPT",
    iata_country_code: "FR",
    iata_code: "POX",
    city_name: "Paris",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Pope Field",
    longitude: -79.014671,
    latitude: 35.169989,
    id: "arp_gfd_us",
    icao_code: "KGFD",
    iata_country_code: "US",
    iata_code: "GFD",
    city_name: "Greenfield",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Pope Field",
    longitude: -79.014503,
    latitude: 35.170892,
    id: "arp_pob_us",
    icao_code: "KPOB",
    iata_country_code: "US",
    iata_code: "POB",
    city_name: "Fort Bragg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Poplar Bluff Municipal Airport",
    longitude: -90.324289,
    latitude: 36.771345,
    id: "arp_pof_us",
    icao_code: "KPOF",
    iata_country_code: "US",
    iata_code: "POF",
    city_name: "Poplar Bluff",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Poplar Hill Airport",
    longitude: -94.255578,
    latitude: 52.113317,
    id: "arp_yhp_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YHP",
    city_name: "Poplar Hill",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Poplar River Airport",
    longitude: -97.274193,
    latitude: 52.996525,
    id: "arp_xpp_ca",
    icao_code: "CZNG",
    iata_country_code: "CA",
    iata_code: "XPP",
    city_name: "Poplar River",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Popondetta Airport",
    longitude: 148.309642,
    latitude: -8.804011,
    id: "arp_eia_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "EIA",
    city_name: "Eia",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Poprad-Tatry Airport",
    longitude: 20.244511,
    latitude: 49.071043,
    id: "arp_tat_sk",
    icao_code: "LZTT",
    iata_country_code: "SK",
    iata_code: "TAT",
    city_name: "Poprad",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Poptún Airport",
    longitude: -89.417724,
    latitude: 16.327003,
    id: "arp_pon_gt",
    icao_code: "MGPP",
    iata_country_code: "GT",
    iata_code: "PON",
    city_name: "Poptún",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Porbandar Airport",
    longitude: 69.659084,
    latitude: 21.648179,
    id: "arp_pbd_in",
    icao_code: "VAPR",
    iata_country_code: "IN",
    iata_code: "PBD",
    city_name: "Porbandar",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Pore Airport",
    longitude: -71.98182,
    latitude: 5.716424,
    id: "arp_pre_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "PRE",
    city_name: "Pore",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Porgera Airport",
    longitude: 143.083,
    latitude: -5.5,
    id: "arp_rge_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RGE",
    city_name: "Porgera",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Pori Airport",
    longitude: 21.79749,
    latitude: 61.463443,
    id: "arp_por_fi",
    icao_code: "EFPO",
    iata_country_code: "FI",
    iata_code: "POR",
    city_name: "Pori",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Porlamar International Airport",
    longitude: -63.967756,
    latitude: 10.914533,
    id: "arp_pmv_ve",
    icao_code: "SVMG",
    iata_country_code: "VE",
    iata_code: "PMV",
    city_name: "Porlamar",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Portage Creek Airport",
    longitude: -157.714055,
    latitude: 58.906493,
    id: "arp_pca_us",
    icao_code: "PAOC",
    iata_country_code: "US",
    iata_code: "PCA",
    city_name: "Portage Creek",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Portage la Prairie/Southport Airport",
    longitude: -98.274802,
    latitude: 49.912384,
    id: "arp_ypg_ca",
    icao_code: "CYPG",
    iata_country_code: "CA",
    iata_code: "YPG",
    city_name: "Portage la Prairie",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Port Alexander Seaplane Base",
    longitude: -134.648024,
    latitude: 56.246733,
    id: "arp_ptd_us",
    icao_code: "PAAP",
    iata_country_code: "US",
    iata_code: "PTD",
    city_name: "Port Alexander",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Port Alfred Airport",
    longitude: 26.88024,
    latitude: -33.555569,
    id: "arp_afd_za",
    icao_code: "FAPA",
    iata_country_code: "ZA",
    iata_code: "AFD",
    city_name: "Port Alfred",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Port Alice Seaplane Base",
    longitude: -127.375894,
    latitude: 50.683745,
    id: "arp_ptc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PTC",
    city_name: "Port Alice",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Port Allen Airport",
    longitude: -159.602747,
    latitude: 21.896792,
    id: "arp_pak_us",
    icao_code: "PHPA",
    iata_country_code: "US",
    iata_code: "PAK",
    city_name: "Hanapepe",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Alsworth Airport",
    longitude: -154.323703,
    latitude: 60.202224,
    id: "arp_pta_us",
    icao_code: "PALJ",
    iata_country_code: "US",
    iata_code: "PTA",
    city_name: "Port Alsworth",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Port Augusta Airport",
    longitude: 137.717594,
    latitude: -32.508064,
    id: "arp_pug_au",
    icao_code: "YPAG",
    iata_country_code: "AU",
    iata_code: "PUG",
    city_name: "Port Augusta",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Bailey Seaplane Base",
    longitude: -153.040706,
    latitude: 57.930107,
    id: "arp_kpy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KPY",
    city_name: "Port Bailey",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Port Bergé Airport",
    longitude: 47.619692,
    latitude: -15.584542,
    id: "arp_wpb_mg",
    icao_code: "FMNG",
    iata_country_code: "MG",
    iata_code: "WPB",
    city_name: "Port Bergé",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Port Bouet Airport",
    longitude: -3.928806,
    latitude: 5.258032,
    id: "arp_abj_ci",
    icao_code: "DIAP",
    iata_country_code: "CI",
    iata_code: "ABJ",
    city_name: "Abidjan",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Clarence Coast Guard Station",
    longitude: -166.858737,
    latitude: 65.253675,
    id: "arp_kpc_us",
    icao_code: "PAPC",
    iata_country_code: "US",
    iata_code: "KPC",
    city_name: "Port Clarence",
    city: null,
  },
  {
    time_zone: "America/Port-au-Prince",
    name: "Port-de-Paix Airport",
    longitude: -72.848199,
    latitude: 19.933944,
    id: "arp_pax_ht",
    icao_code: "MTPX",
    iata_country_code: "HT",
    iata_code: "PAX",
    city_name: "Port-de-Paix",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Port Elizabeth Airport",
    longitude: 25.615797,
    latitude: -33.986086,
    id: "arp_plz_za",
    icao_code: "FAPE",
    iata_country_code: "ZA",
    iata_code: "PLZ",
    city_name: "Port Elizabeth",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Porter County Regional Airport",
    longitude: -87.007804,
    latitude: 41.452322,
    id: "arp_vpz_us",
    icao_code: "KVPZ",
    iata_country_code: "US",
    iata_code: "VPZ",
    city_name: "Valparaiso",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Porterville Municipal Airport",
    longitude: -119.060693,
    latitude: 36.029585,
    id: "arp_ptv_us",
    icao_code: "KPTV",
    iata_country_code: "US",
    iata_code: "PTV",
    city_name: "Porterville",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Port-Gentil International Airport",
    longitude: 8.753693,
    latitude: -0.716146,
    id: "arp_pog_ga",
    icao_code: "FOOG",
    iata_country_code: "GA",
    iata_code: "POG",
    city_name: "Port Gentil",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Graham Airport",
    longitude: -151.83102,
    latitude: 59.348551,
    id: "arp_pgm_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PGM",
    city_name: "Port Graham",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Port Harcourt City Airport",
    longitude: 6.94966,
    latitude: 5.014001,
    id: "arp_phg_ng",
    icao_code: "DNPO",
    iata_country_code: "NG",
    iata_code: "PHG",
    city_name: "Port Harcourt",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Port Harcourt International Airport",
    longitude: 6.949946,
    latitude: 5.013196,
    id: "arp_phc_ng",
    icao_code: "DNPO",
    iata_country_code: "NG",
    iata_code: "PHC",
    city_name: "Port Harcourt",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Port Hardy Airport",
    longitude: -127.372115,
    latitude: 50.682211,
    id: "arp_yzt_ca",
    icao_code: "CYZT",
    iata_country_code: "CA",
    iata_code: "YZT",
    city_name: "Port Hardy",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Port Hawkesbury Airport",
    longitude: -61.370313,
    latitude: 45.65711,
    id: "arp_yps_ca",
    icao_code: "CYPD",
    iata_country_code: "CA",
    iata_code: "YPS",
    city_name: "Port Hawkesbury",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Port Hedland International Airport",
    longitude: 118.628823,
    latitude: -20.377933,
    id: "arp_phe_au",
    icao_code: "YPPD",
    iata_country_code: "AU",
    iata_code: "PHE",
    city_name: "Port Hedland",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Heiden Airport",
    longitude: -158.635897,
    latitude: 56.958811,
    id: "arp_pth_us",
    icao_code: "PAPH",
    iata_country_code: "US",
    iata_code: "PTH",
    city_name: "Port Heiden",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "Port Hope Simpson Airport",
    longitude: -56.286531,
    latitude: 52.528138,
    id: "arp_yha_ca",
    icao_code: "CCP4",
    iata_country_code: "CA",
    iata_code: "YHA",
    city_name: "Port Hope Simpson",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Portimão Airport",
    longitude: -8.582632,
    latitude: 37.148769,
    id: "arp_prm_pt",
    icao_code: "LPPM",
    iata_country_code: "PT",
    iata_code: "PRM",
    city_name: "Portimão",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Port Kaituma Airport",
    longitude: -59.64857,
    latitude: 8.303385,
    id: "arp_pkm_gy",
    icao_code: null,
    iata_country_code: "GY",
    iata_code: "PKM",
    city_name: "Port Kaituma",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Port Keats Airport",
    longitude: 129.526911,
    latitude: -14.248196,
    id: "arp_pkt_au",
    icao_code: "YPKT",
    iata_country_code: "AU",
    iata_code: "PKT",
    city_name: "Port Keats",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Portland Airport",
    longitude: 141.471052,
    latitude: -38.318077,
    id: "arp_ptj_au",
    icao_code: "YPOD",
    iata_country_code: "AU",
    iata_code: "PTJ",
    city_name: "Portland",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Portland–Hillsboro Airport",
    longitude: -122.948639,
    latitude: 45.536617,
    id: "arp_hio_us",
    icao_code: "KHIO",
    iata_country_code: "US",
    iata_code: "HIO",
    city_name: "Portland",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Portland International Airport",
    longitude: -122.596546,
    latitude: 45.589234,
    id: "arp_pdx_us",
    icao_code: "KPDX",
    iata_country_code: "US",
    iata_code: "PDX",
    city_name: "Portland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Portland International Jetport Airport",
    longitude: -70.3095,
    latitude: 43.646338,
    id: "arp_pwm_us",
    icao_code: "KPWM",
    iata_country_code: "US",
    iata_code: "PWM",
    city_name: "Portland",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Portland–Troutdale Airport",
    longitude: -122.398879,
    latitude: 45.54914,
    id: "arp_ttd_us",
    icao_code: "KTTD",
    iata_country_code: "US",
    iata_code: "TTD",
    city_name: "Portland",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Port Lincoln Airport",
    longitude: 135.877672,
    latitude: -34.604418,
    id: "arp_plo_au",
    icao_code: "YPLC",
    iata_country_code: "AU",
    iata_code: "PLO",
    city_name: "Port Lincoln",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Lions Airport",
    longitude: -152.848121,
    latitude: 57.884816,
    id: "arp_ori_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ORI",
    city_name: "Port Lions",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Port Macquarie Airport",
    longitude: 152.865,
    latitude: -31.433706,
    id: "arp_pqq_au",
    icao_code: "YPMQ",
    iata_country_code: "AU",
    iata_code: "PQQ",
    city_name: "Port Macquarie",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Port McNeill Airport",
    longitude: -127.027264,
    latitude: 50.573802,
    id: "arp_ymp_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YMP",
    city_name: "Port McNeill",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Port Meadville Airport",
    longitude: -80.214374,
    latitude: 41.626383,
    id: "arp_mej_us",
    icao_code: "KGKJ",
    iata_country_code: "US",
    iata_code: "MEJ",
    city_name: "Meadville",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Port-Menier Airport",
    longitude: -64.289776,
    latitude: 49.837189,
    id: "arp_ypn_ca",
    icao_code: "CYPN",
    iata_country_code: "CA",
    iata_code: "YPN",
    city_name: "Port-Menier",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Moller Airport",
    longitude: -160.560898,
    latitude: 56.005993,
    id: "arp_pml_us",
    icao_code: "PAAL",
    iata_country_code: "US",
    iata_code: "PML",
    city_name: "Cold Bay",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Port Nelson Airport",
    longitude: -74.836293,
    latitude: 23.684099,
    id: "arp_rcy_bs",
    icao_code: "MYRP",
    iata_country_code: "BS",
    iata_code: "RCY",
    city_name: "Rum Cay",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Porto Airport",
    longitude: -8.680444,
    latitude: 41.246106,
    id: "arp_opo_pt",
    icao_code: "LPPR",
    iata_country_code: "PT",
    iata_code: "OPO",
    city_name: "Porto",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Porto Amboim Airport",
    longitude: 13.764232,
    latitude: -10.722401,
    id: "arp_pbn_ao",
    icao_code: "FNPA",
    iata_country_code: "AO",
    iata_code: "PBN",
    city_name: "Porto Amboim",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Porto Cheli Airport",
    longitude: 23.1478,
    latitude: 37.297501,
    id: "arp_pkh_gr",
    icao_code: "LGHL",
    iata_country_code: "GR",
    iata_code: "PKH",
    city_name: "Porto Cheli",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Porto de Moz Airport",
    longitude: -52.238319,
    latitude: -1.745778,
    id: "arp_ptq_br",
    icao_code: "SNMZ",
    iata_country_code: "BR",
    iata_code: "PTQ",
    city_name: "Porto de Moz",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Porto dos Gaúchos Airport",
    longitude: -57.379053,
    latitude: -11.540884,
    id: "arp_pbv_br",
    icao_code: "SWPG",
    iata_country_code: "BR",
    iata_code: "PBV",
    city_name: "Porto Dos Gaúchos",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Port of Poulsbo Marina Moorage Seaplane Base",
    longitude: -122.647254,
    latitude: 47.733897,
    id: "arp_pul_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PUL",
    city_name: "Poulsbo",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Porto Nacional Airport",
    longitude: -48.3997,
    latitude: -10.719401,
    id: "arp_pnb_br",
    icao_code: "SBPN",
    iata_country_code: "BR",
    iata_code: "PNB",
    city_name: "Porto Nacional",
    city: null,
  },
  {
    time_zone: "Europe/Ljubljana",
    name: "Portoroz Airport",
    longitude: 13.615775,
    latitude: 45.473426,
    id: "arp_pow_si",
    icao_code: "LJPZ",
    iata_country_code: "SI",
    iata_code: "POW",
    city_name: "Portoroz",
    city: null,
  },
  {
    time_zone: "Atlantic/Madeira",
    name: "Porto Santo Airport",
    longitude: -16.350016,
    latitude: 33.074168,
    id: "arp_pxo_pt",
    icao_code: "LPPS",
    iata_country_code: "PT",
    iata_code: "PXO",
    city_name: "Vila Baleira",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Porto Seguro Airport",
    longitude: -39.081132,
    latitude: -16.439227,
    id: "arp_bps_br",
    icao_code: "SBPS",
    iata_country_code: "BR",
    iata_code: "BPS",
    city_name: "Porto Seguro",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Porto Velho Airport",
    longitude: -63.901031,
    latitude: -8.711835,
    id: "arp_pvh_br",
    icao_code: "SBPV",
    iata_country_code: "BR",
    iata_code: "PVH",
    city_name: "Porto Velho",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Port Pirie Airport",
    longitude: 137.99618,
    latitude: -33.238958,
    id: "arp_ppi_au",
    icao_code: "YPIR",
    iata_country_code: "AU",
    iata_code: "PPI",
    city_name: "Port Pirie",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Port Protection Seaplane Base",
    longitude: -133.609318,
    latitude: 56.32886,
    id: "arp_ppv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PPV",
    city_name: "Port Protection",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Port Said Airport",
    longitude: 32.240994,
    latitude: 31.279875,
    id: "arp_psd_eg",
    icao_code: "HEPS",
    iata_country_code: "EG",
    iata_code: "PSD",
    city_name: "Port Said",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Port Simpson Airport",
    longitude: -130.432662,
    latitude: 54.564794,
    id: "arp_ypi_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YPI",
    city_name: "Port Simpson",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Portsmouth International Airport",
    longitude: -70.822681,
    latitude: 43.079053,
    id: "arp_psm_us",
    icao_code: "KPSM",
    iata_country_code: "US",
    iata_code: "PSM",
    city_name: "Portsmouth",
    city: {
      name: "Boston",
      id: "cit_bos_us",
      iata_country_code: "US",
      iata_code: "BOS",
    },
  },
  {
    time_zone: "Atlantic/Stanley",
    name: "Port Stanley Airport",
    longitude: -57.777948,
    latitude: -51.686186,
    id: "arp_psy_fk",
    icao_code: "SFAL",
    iata_country_code: "FK",
    iata_code: "PSY",
    city_name: "Stanley",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Port St Johns Airport",
    longitude: 29.5198,
    latitude: -31.6059,
    id: "arp_joh_za",
    icao_code: "FAPJ",
    iata_country_code: "ZA",
    iata_code: "JOH",
    city_name: "Port Saint John's",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Port Sudan New International Airport",
    longitude: 37.23512,
    latitude: 19.433803,
    id: "arp_pzu_sd",
    icao_code: "HSPN",
    iata_country_code: "SD",
    iata_code: "PZU",
    city_name: "Port Sudan",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Port Walter Seaplane Base Airport",
    longitude: -134.651391,
    latitude: 56.381094,
    id: "arp_pwr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PWR",
    city_name: "Port Walter",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Port Williams Seaplane Base",
    longitude: -152.582083,
    latitude: 58.490119,
    id: "arp_kpr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KPR",
    city_name: "Port Williams",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Posadas Airport",
    longitude: -55.970005,
    latitude: -27.387403,
    id: "arp_pss_ar",
    icao_code: "SARP",
    iata_country_code: "AR",
    iata_code: "PSS",
    city_name: "Posadas",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Postville Airport",
    longitude: -59.78507,
    latitude: 54.9105,
    id: "arp_yso_ca",
    icao_code: "CCD4",
    iata_country_code: "CA",
    iata_code: "YSO",
    city_name: "Postville",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Potchefstroom Airport",
    longitude: 27.082647,
    latitude: -26.670092,
    id: "arp_pcf_za",
    icao_code: "FAPS",
    iata_country_code: "ZA",
    iata_code: "PCF",
    city_name: "Potchefstroom",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Poum Airport",
    longitude: 164.099201,
    latitude: -20.289217,
    id: "arp_puv_nc",
    icao_code: "NWWP",
    iata_country_code: "NC",
    iata_code: "PUV",
    city_name: "Poum",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Pouso Alegre Airport",
    longitude: -45.919809,
    latitude: -22.288501,
    id: "arp_ppy_br",
    icao_code: "SNZA",
    iata_country_code: "BR",
    iata_code: "PPY",
    city_name: "Pouso Alegre",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Powell Lake Seaplane Base Airport",
    longitude: -124.530862,
    latitude: 49.884614,
    id: "arp_wpl_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "WPL",
    city_name: "Powell River",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Powell Municipal Airport",
    longitude: -108.789532,
    latitude: 44.866663,
    id: "arp_poy_us",
    icao_code: "KPOY",
    iata_country_code: "US",
    iata_code: "POY",
    city_name: "Powell",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Powell River Airport",
    longitude: -124.499793,
    latitude: 49.834055,
    id: "arp_ypw_ca",
    icao_code: "CYPW",
    iata_country_code: "CA",
    iata_code: "YPW",
    city_name: "Powell River",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Poznań-Ławica Airport",
    longitude: 16.827458,
    latitude: 52.420476,
    id: "arp_poz_pl",
    icao_code: "EPPO",
    iata_country_code: "PL",
    iata_code: "POZ",
    city_name: "Poznan",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Prado Airport",
    longitude: -39.272263,
    latitude: -17.296791,
    id: "arp_pdf_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "PDF",
    city_name: "Prado",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Prairie du Chien Municipal Airport",
    longitude: -91.122511,
    latitude: 43.020175,
    id: "arp_pcd_us",
    icao_code: "KPDC",
    iata_country_code: "US",
    iata_code: "PCD",
    city_name: "Prairie du Chien",
    city: null,
  },
  {
    time_zone: "Indian/Mahe",
    name: "Praslin Island Airport",
    longitude: 55.691673,
    latitude: -4.320433,
    id: "arp_pri_sc",
    icao_code: "FSPP",
    iata_country_code: "SC",
    iata_code: "PRI",
    city_name: "Praslin Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pratt Regional Airport",
    longitude: -98.745914,
    latitude: 37.702913,
    id: "arp_ptt_us",
    icao_code: "KPTT",
    iata_country_code: "US",
    iata_code: "PTT",
    city_name: "Pratt",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Preah Vinhear Airport",
    longitude: 104.971459,
    latitude: 13.759618,
    id: "arp_omy_kh",
    icao_code: null,
    iata_country_code: "KH",
    iata_code: "OMY",
    city_name: "Thbeng Meanchey",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Prefeito Octávio de Almeida Neves Airport",
    longitude: -44.226049,
    latitude: -21.085883,
    id: "arp_jdr_br",
    icao_code: "SNJR",
    iata_country_code: "BR",
    iata_code: "JDR",
    city_name: "Sao Joao del Rei",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Preguiça Airport",
    longitude: -24.284498,
    latitude: 16.588417,
    id: "arp_sne_cv",
    icao_code: "GVSN",
    iata_country_code: "CV",
    iata_code: "SNE",
    city_name: "São Nicolau",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Prentice Airport",
    longitude: -90.279836,
    latitude: 45.540343,
    id: "arp_prw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PRW",
    city_name: "Prentice",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Přerov Airport",
    longitude: 17.405816,
    latitude: 49.425777,
    id: "arp_prv_cz",
    icao_code: "LKPO",
    iata_country_code: "CZ",
    iata_code: "PRV",
    city_name: "Přerov",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Pres. Gral. Óscar D. Gestido International Airport",
    longitude: -55.476433,
    latitude: -30.974522,
    id: "arp_rvy_uy",
    icao_code: "SURV",
    iata_country_code: "UY",
    iata_code: "RVY",
    city_name: "Rivera",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Presidente Castro Pinto International Airport",
    longitude: -34.949154,
    latitude: -7.146184,
    id: "arp_jpa_br",
    icao_code: "SBJP",
    iata_country_code: "BR",
    iata_code: "JPA",
    city_name: "João Pessoa",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Presidente José Sarney Airport",
    longitude: -44.467274,
    latitude: -5.300807,
    id: "arp_pdr_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "PDR",
    city_name: "Presidente Dutra",
    city: null,
  },
  {
    time_zone: "Asia/Dili",
    name: "Presidente Nicolau Lobato International Airport",
    longitude: 125.52542,
    latitude: -8.547253,
    id: "arp_dil_tl",
    icao_code: "WPDL",
    iata_country_code: "TL",
    iata_code: "DIL",
    city_name: "Dili",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Presidente Perón International Airport",
    longitude: -68.140404,
    latitude: -38.951624,
    id: "arp_nqn_ar",
    icao_code: "SAZN",
    iata_country_code: "AR",
    iata_code: "NQN",
    city_name: "Neuquen",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Presidente Prudente Airport",
    longitude: -51.420903,
    latitude: -22.176731,
    id: "arp_ppb_br",
    icao_code: "SBDN",
    iata_country_code: "BR",
    iata_code: "PPB",
    city_name: "Presidente Prudente",
    city: null,
  },
  {
    time_zone: "Africa/Malabo",
    name: "President Obiang Nguema International Airport",
    longitude: 11.024823,
    latitude: 1.681311,
    id: "arp_gem_gq",
    icao_code: "FGMY",
    iata_country_code: "GQ",
    iata_code: "GEM",
    city_name: "Mengomeyén",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Prešov Air Base",
    longitude: 21.303029,
    latitude: 49.027849,
    id: "arp_pov_sk",
    icao_code: "LZPW",
    iata_country_code: "SK",
    iata_code: "POV",
    city_name: "Prešov",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Prieska Airport",
    longitude: 22.767235,
    latitude: -29.682429,
    id: "arp_prk_za",
    icao_code: "FAPK",
    iata_country_code: "ZA",
    iata_code: "PRK",
    city_name: "Prieska",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Primrose Airport",
    longitude: -111.124137,
    latitude: 55.393813,
    id: "arp_pfm_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "PFM",
    city_name: "Primrose",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Prince Abdul Majeed bin Abdulaziz Domestic Airport",
    longitude: 38.116603,
    latitude: 26.477259,
    id: "arp_ulh_sa",
    icao_code: "OEAO",
    iata_country_code: "SA",
    iata_code: "ULH",
    city_name: "Al Ula",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Prince Albert Glass Field Airpor",
    longitude: -105.677545,
    latitude: 53.214049,
    id: "arp_ypa_ca",
    icao_code: "CYPA",
    iata_country_code: "CA",
    iata_code: "YPA",
    city_name: "Prince Albert",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Prince George Airport",
    longitude: -122.67664,
    latitude: 53.886501,
    id: "arp_yxs_ca",
    icao_code: "CYXS",
    iata_country_code: "CA",
    iata_code: "YXS",
    city_name: "Prince George",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Prince Mangosuthu Buthelezi Airport",
    longitude: 31.416463,
    latitude: -28.320585,
    id: "arp_uld_za",
    icao_code: "FAUL",
    iata_country_code: "ZA",
    iata_code: "ULD",
    city_name: "Ulundi",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Prince Mohammad Bin Abdulaziz Airport",
    longitude: 39.712887,
    latitude: 24.553323,
    id: "arp_med_sa",
    icao_code: "OEMA",
    iata_country_code: "SA",
    iata_code: "MED",
    city_name: "Medina",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Prince Nayef bin Abdulaziz International Airport",
    longitude: 43.772799,
    latitude: 26.303377,
    id: "arp_elq_sa",
    icao_code: "OEGS",
    iata_country_code: "SA",
    iata_code: "ELQ",
    city_name: "Gassim",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Prince Rupert Airport",
    longitude: -130.445186,
    latitude: 54.287801,
    id: "arp_ypr_ca",
    icao_code: "CYPR",
    iata_country_code: "CA",
    iata_code: "YPR",
    city_name: "Prince Rupert",
    city: {
      name: "Prince Rupert",
      id: "cit_ypr_ca",
      iata_country_code: "CA",
      iata_code: "YPR",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Prince Rupert/Seal Cove Seaplane Base",
    longitude: -130.28221,
    latitude: 54.332303,
    id: "arp_zsw_ca",
    icao_code: "CZSW",
    iata_country_code: "CA",
    iata_code: "ZSW",
    city_name: "Prince Rupert",
    city: {
      name: "Prince Rupert",
      id: "cit_ypr_ca",
      iata_country_code: "CA",
      iata_code: "YPR",
    },
  },
  {
    time_zone: "Indian/Comoro",
    name: "Prince Said Ibrahim International Airport",
    longitude: 43.273234,
    latitude: -11.534886,
    id: "arp_hah_km",
    icao_code: "FMCH",
    iata_country_code: "KM",
    iata_code: "HAH",
    city_name: "Moroni",
    city: {
      name: "Moroni",
      id: "cit_yva_km",
      iata_country_code: "KM",
      iata_code: "YVA",
    },
  },
  {
    time_zone: "America/Lower_Princes",
    name: "Princess Juliana International Airport",
    longitude: -63.11099,
    latitude: 18.041435,
    id: "arp_sxm_sx",
    icao_code: "TNCM",
    iata_country_code: "SX",
    iata_code: "SXM",
    city_name: "Saint Martin",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Prince Sultan Air Base",
    longitude: 47.581168,
    latitude: 24.062912,
    id: "arp_akh_sa",
    icao_code: "OEPS",
    iata_country_code: "SA",
    iata_code: "AKH",
    city_name: "Al Kharj",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Princeton Airport",
    longitude: -74.658646,
    latitude: 40.398961,
    id: "arp_pct_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PCT",
    city_name: "Princeton/Rocky Hill",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Princeton Municipal Airport",
    longitude: -67.563814,
    latitude: 45.20016,
    id: "arp_pnn_us",
    icao_code: "KPNN",
    iata_country_code: "US",
    iata_code: "PNN",
    city_name: "Princeton",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Princeville Airport",
    longitude: -159.444048,
    latitude: 22.210426,
    id: "arp_hpv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "HPV",
    city_name: "Hanalei",
    city: null,
  },
  {
    time_zone: "Africa/Sao_Tome",
    name: "Principe Airport",
    longitude: 7.41199,
    latitude: 1.664021,
    id: "arp_pcp_st",
    icao_code: "FPPR",
    iata_country_code: "ST",
    iata_code: "PCP",
    city_name: "Principe Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Prineville Airport",
    longitude: -120.902794,
    latitude: 44.286286,
    id: "arp_prz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "PRZ",
    city_name: "Prineville",
    city: null,
  },
  {
    time_zone: "Europe/Belgrade",
    name: "Pristina International Airport",
    longitude: 21.033062,
    latitude: 42.573996,
    id: "arp_prn_yu",
    icao_code: "BKPR",
    iata_country_code: "XK",
    iata_code: "PRN",
    city_name: "Pristina",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "P.R. Mphephu Airport",
    longitude: 30.38298,
    latitude: -23.078997,
    id: "arp_thy_za",
    icao_code: "FATH",
    iata_country_code: "ZA",
    iata_code: "THY",
    city_name: "Thohoyandou",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Prominent Hill Airport",
    longitude: 135.525425,
    latitude: -29.714079,
    id: "arp_pxh_au",
    icao_code: "YPMH",
    iata_country_code: "AU",
    iata_code: "PXH",
    city_name: "Prominent Hill",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Propriano Airport",
    longitude: 8.891927,
    latitude: 41.660025,
    id: "arp_prp_fr",
    icao_code: "LFKO",
    iata_country_code: "FR",
    iata_code: "PRP",
    city_name: "Propriano",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Prospect Creek Airport",
    longitude: -150.643983,
    latitude: 66.813707,
    id: "arp_ppc_us",
    icao_code: "PAPR",
    iata_country_code: "US",
    iata_code: "PPC",
    city_name: "Prospect Creek",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "Providenciales International Airport",
    longitude: -72.26708,
    latitude: 21.774296,
    id: "arp_pls_tc",
    icao_code: "MBPV",
    iata_country_code: "TC",
    iata_code: "PLS",
    city_name: "Providenciales",
    city: null,
  },
  {
    time_zone: "Asia/Anadyr",
    name: "Provideniya Bay Airport",
    longitude: -173.238242,
    latitude: 64.38098,
    id: "arp_pvs_ru",
    icao_code: "UHMD",
    iata_country_code: "RU",
    iata_code: "PVS",
    city_name: "Provideniya",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Provincetown Municipal Airport",
    longitude: -70.220551,
    latitude: 42.073082,
    id: "arp_pvc_us",
    icao_code: "KPVC",
    iata_country_code: "US",
    iata_code: "PVC",
    city_name: "Provincetown",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Provo Municipal Airport",
    longitude: -111.722736,
    latitude: 40.218083,
    id: "arp_pvu_us",
    icao_code: "KPVU",
    iata_country_code: "US",
    iata_code: "PVU",
    city_name: "Provo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Pryor Field Regional Airport",
    longitude: -86.946001,
    latitude: 34.654304,
    id: "arp_dcu_us",
    icao_code: "KDCU",
    iata_country_code: "US",
    iata_code: "DCU",
    city_name: "Decatur",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Pskov Airport",
    longitude: 28.391944,
    latitude: 57.79714,
    id: "arp_pkv_ru",
    icao_code: "ULOO",
    iata_country_code: "RU",
    iata_code: "PKV",
    city_name: "Pskov",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Puas Airport",
    longitude: 150.233333,
    latitude: -2.4,
    id: "arp_pua_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PUA",
    city_name: "Puas",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Pucallpa International Airport",
    longitude: -74.574885,
    latitude: -8.382295,
    id: "arp_pcl_pe",
    icao_code: "SPCL",
    iata_country_code: "PE",
    iata_code: "PCL",
    city_name: "Pucallpa",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Pucón Airport",
    longitude: -71.918986,
    latitude: -39.292037,
    id: "arp_zpc_cl",
    icao_code: "SCPC",
    iata_country_code: "CL",
    iata_code: "ZPC",
    city_name: "Pucon",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Puebla International Airport",
    longitude: -98.372831,
    latitude: 19.16105,
    id: "arp_pbc_mx",
    icao_code: "MMPB",
    iata_country_code: "MX",
    iata_code: "PBC",
    city_name: "Puebla",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Pueblo Memorial Airport",
    longitude: -104.496446,
    latitude: 38.288047,
    id: "arp_pub_us",
    icao_code: "KPUB",
    iata_country_code: "US",
    iata_code: "PUB",
    city_name: "Pueblo",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Pu'er Simao Airport",
    longitude: 100.960944,
    latitude: 22.793412,
    id: "arp_sym_cn",
    icao_code: "ZPSM",
    iata_country_code: "CN",
    iata_code: "SYM",
    city_name: "Simao",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Puerto Aisén Airport",
    longitude: -72.670718,
    latitude: -45.399307,
    id: "arp_wpa_cl",
    icao_code: "SCAS",
    iata_country_code: "CL",
    iata_code: "WPA",
    city_name: "Puerto Aisen",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Puerto Armuelles Airport",
    longitude: -82.864811,
    latitude: 8.268058,
    id: "arp_aml_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "AML",
    city_name: "Puerto Armuelles",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Puerto Barrios Airport",
    longitude: -88.584289,
    latitude: 15.731352,
    id: "arp_pbr_gt",
    icao_code: "MGPB",
    iata_country_code: "GT",
    iata_code: "PBR",
    city_name: "Puerto Barrios",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Puerto Berrio Airport",
    longitude: -74.411006,
    latitude: 6.462319,
    id: "arp_pbe_co",
    icao_code: "SKPR",
    iata_country_code: "CO",
    iata_code: "PBE",
    city_name: "Puerto Berrio",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Puerto Cabezas Airport",
    longitude: -83.384648,
    latitude: 14.045464,
    id: "arp_puz_ni",
    icao_code: "MNPC",
    iata_country_code: "NI",
    iata_code: "PUZ",
    city_name: "Puerto Cabezas",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Puerto Deseado Airport",
    longitude: -65.926154,
    latitude: -47.719811,
    id: "arp_pud_ar",
    icao_code: "SAWD",
    iata_country_code: "AR",
    iata_code: "PUD",
    city_name: "Puerto Deseado",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Puerto Escondido International Airport",
    longitude: -97.090637,
    latitude: 15.876165,
    id: "arp_pxm_mx",
    icao_code: "MMPS",
    iata_country_code: "MX",
    iata_code: "PXM",
    city_name: "Puerto Escondido",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Puerto Jimenez Airport",
    longitude: -83.300634,
    latitude: 8.534719,
    id: "arp_pjm_cr",
    icao_code: "MRPJ",
    iata_country_code: "CR",
    iata_code: "PJM",
    city_name: "Puerto Jimenez",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Puerto La Victoria Airport",
    longitude: -57.942785,
    latitude: -22.159549,
    id: "arp_pcj_py",
    icao_code: null,
    iata_country_code: "PY",
    iata_code: "PCJ",
    city_name: "Puerto La Victoria",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Puerto Leda Airport",
    longitude: -57.99123,
    latitude: -20.71506,
    id: "arp_pbt_py",
    icao_code: null,
    iata_country_code: "PY",
    iata_code: "PBT",
    city_name: "Puerto Leda",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Puerto Lempira Airport",
    longitude: -83.775676,
    latitude: 15.262371,
    id: "arp_peu_hn",
    icao_code: "MHPL",
    iata_country_code: "HN",
    iata_code: "PEU",
    city_name: "Puerto Lempira",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Puerto Nare Airport",
    longitude: -74.590827,
    latitude: 6.210027,
    id: "arp_nar_co",
    icao_code: "SKPN",
    iata_country_code: "CO",
    iata_code: "NAR",
    city_name: "Puerto Nare",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Puerto Obaldia Airport",
    longitude: -77.41866,
    latitude: 8.666916,
    id: "arp_pue_pa",
    icao_code: "MPOA",
    iata_country_code: "PA",
    iata_code: "PUE",
    city_name: "Puerto Obaldia",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Puerto Ordaz International Airport",
    longitude: -62.760406,
    latitude: 8.287908,
    id: "arp_pzo_ve",
    icao_code: "SVPR",
    iata_country_code: "VE",
    iata_code: "PZO",
    city_name: "Bolívar",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Puerto Paez Airport",
    longitude: -67.451067,
    latitude: 6.221996,
    id: "arp_ppz_ve",
    icao_code: null,
    iata_country_code: "VE",
    iata_code: "PPZ",
    city_name: "Puerto Paez",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "Puerto Peñasco International Airport",
    longitude: -113.303861,
    latitude: 31.351645,
    id: "arp_ppe_mx",
    icao_code: "MMPE",
    iata_country_code: "MX",
    iata_code: "PPE",
    city_name: "Puerto Penasco",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Puerto Princesa International Airport",
    longitude: 118.756761,
    latitude: 9.742942,
    id: "arp_pps_ph",
    icao_code: "RPVP",
    iata_country_code: "PH",
    iata_code: "PPS",
    city_name: "Puerto Princesa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Puerto Rico Airport",
    longitude: -75.1667,
    latitude: 1.91667,
    id: "arp_pcc_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "PCC",
    city_name: "Puerto Rico",
    city: null,
  },
  {
    time_zone: "America/Puerto_Rico",
    name: "Puerto Rico Airport",
    longitude: -66.676361,
    latitude: 18.450511,
    id: "arp_pur_bo",
    icao_code: "SLPR",
    iata_country_code: "BO",
    iata_code: "PUR",
    city_name: "Puerto Rico/Manuripi",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Puerto Suarez Airport",
    longitude: -57.819182,
    latitude: -18.975955,
    id: "arp_psz_bo",
    icao_code: "SLPS",
    iata_country_code: "BO",
    iata_code: "PSZ",
    city_name: "Puerto Suarez",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Pukaki Airport",
    longitude: 170.117473,
    latitude: -44.23537,
    id: "arp_twz_nz",
    icao_code: "NZUK",
    iata_country_code: "NZ",
    iata_code: "TWZ",
    city_name: "Mount Cook",
    city: {
      name: "Mount Cook",
      id: "cit_mon_nz",
      iata_country_code: "NZ",
      iata_code: "MON",
    },
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Puka Puka Airport",
    longitude: -138.812122,
    latitude: -14.809703,
    id: "arp_pkp_pf",
    icao_code: "NTGP",
    iata_country_code: "PF",
    iata_code: "PKP",
    city_name: "Puka Puka",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Pukapuka Island Airport",
    longitude: -165.8393,
    latitude: -10.9145,
    id: "arp_pzk_ck",
    icao_code: "NCPK",
    iata_country_code: "CK",
    iata_code: "PZK",
    city_name: "Pukapuka Island",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Pukarua Airport",
    longitude: -137.016998,
    latitude: -18.2956,
    id: "arp_puk_pf",
    icao_code: "NTGQ",
    iata_country_code: "PF",
    iata_code: "PUK",
    city_name: "Pukarua",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Pukatawagan Airport",
    longitude: -101.265988,
    latitude: 55.748993,
    id: "arp_xpk_ca",
    icao_code: "CZFG",
    iata_country_code: "CA",
    iata_code: "XPK",
    city_name: "Pukatawagan",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Pula Airport",
    longitude: 13.922776,
    latitude: 44.896401,
    id: "arp_puy_hr",
    icao_code: "LDPL",
    iata_country_code: "HR",
    iata_code: "PUY",
    city_name: "Pula",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Pulau Panjang Airport",
    longitude: 106.56158,
    latitude: -5.644613,
    id: "arp_ppj_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "PPJ",
    city_name: "Panjang Island",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Pulkovo Airport",
    longitude: 30.26517,
    latitude: 59.801607,
    id: "arp_led_ru",
    icao_code: "ULLI",
    iata_country_code: "RU",
    iata_code: "LED",
    city_name: "St. Petersburg",
    city: {
      name: "St Petersburg",
      id: "cit_led_ru",
      iata_country_code: "RU",
      iata_code: "LED",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Pullman-Moscow Regional Airport",
    longitude: -117.110911,
    latitude: 46.744029,
    id: "arp_puw_us",
    icao_code: "KPUW",
    iata_country_code: "US",
    iata_code: "PUW",
    city_name: "Pullman",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pumani Airport",
    longitude: 149.4766,
    latitude: -9.7469,
    id: "arp_pmn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PMN",
    city_name: "Pumani",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Pune Airport",
    longitude: 73.909174,
    latitude: 18.580085,
    id: "arp_pnq_in",
    icao_code: "VAPO",
    iata_country_code: "IN",
    iata_code: "PNQ",
    city_name: "Pune",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Punia Airport",
    longitude: 26.347624,
    latitude: -1.391066,
    id: "arp_pun_cd",
    icao_code: "FZOP",
    iata_country_code: "CD",
    iata_code: "PUN",
    city_name: "Punia",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Punta Abreojos Airfield",
    longitude: -113.559451,
    latitude: 26.727307,
    id: "arp_ajs_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "AJS",
    city_name: "Punta Abreojos",
    city: null,
  },
  {
    time_zone: "America/Punta_Arenas",
    name: "Punta Arenas Airport",
    longitude: -70.84883,
    latitude: -53.003845,
    id: "arp_puq_cl",
    icao_code: "SCCI",
    iata_country_code: "CL",
    iata_code: "PUQ",
    city_name: "Punta Arenas",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Punta Cana International Airport",
    longitude: -68.364433,
    latitude: 18.565001,
    id: "arp_puj_do",
    icao_code: "MDPC",
    iata_country_code: "DO",
    iata_code: "PUJ",
    city_name: "Punta Cana",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Punta Chivato Airport",
    longitude: -111.963894,
    latitude: 27.069818,
    id: "arp_pcv_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "PCV",
    city_name: "Punta Chivato",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Punta Colorada Airport",
    longitude: -109.536048,
    latitude: 23.574793,
    id: "arp_pco_mx",
    icao_code: "MMPL",
    iata_country_code: "MX",
    iata_code: "PCO",
    city_name: "La Ribera",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Punta del Este Airport",
    longitude: -55.095378,
    latitude: -34.856607,
    id: "arp_pdp_uy",
    icao_code: "SULS",
    iata_country_code: "UY",
    iata_code: "PDP",
    city_name: "Punta del Este",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Punta de Maisi Airport",
    longitude: -74.149713,
    latitude: 20.241462,
    id: "arp_uma_cu",
    icao_code: "MUMA",
    iata_country_code: "CU",
    iata_code: "UMA",
    city_name: "Maisi",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Punta Gorda Airport",
    longitude: -81.993855,
    latitude: 26.918714,
    id: "arp_pgd_us",
    icao_code: "KPGD",
    iata_country_code: "US",
    iata_code: "PGD",
    city_name: "Punta Gorda",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Punta Gorda Airport",
    longitude: -88.807618,
    latitude: 16.102328,
    id: "arp_pnd_bz",
    icao_code: "MZPG",
    iata_country_code: "BZ",
    iata_code: "PND",
    city_name: "Punta Gorda",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Punta Islita Airport",
    longitude: -85.370709,
    latitude: 9.856429,
    id: "arp_pbp_cr",
    icao_code: "MRIA",
    iata_country_code: "CR",
    iata_code: "PBP",
    city_name: "Islita",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Pupelde Airport",
    longitude: -73.796446,
    latitude: -41.904776,
    id: "arp_zud_cl",
    icao_code: "SCAC",
    iata_country_code: "CL",
    iata_code: "ZUD",
    city_name: "Ancud",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Purdue University Airport",
    longitude: -86.934633,
    latitude: 40.413647,
    id: "arp_laf_us",
    icao_code: "KLAF",
    iata_country_code: "US",
    iata_code: "LAF",
    city_name: "Lafayette",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Pureni Airport",
    longitude: 142.817,
    latitude: -5.833,
    id: "arp_pui_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PUI",
    city_name: "Pureni",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Putao Airport",
    longitude: 97.426494,
    latitude: 27.329697,
    id: "arp_pbu_mm",
    icao_code: "VYPT",
    iata_country_code: "MM",
    iata_code: "PBU",
    city_name: "Putao",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Putumayo Airport",
    longitude: -75.849973,
    latitude: 0.114908,
    id: "arp_pyo_ec",
    icao_code: "SEPT",
    iata_country_code: "EC",
    iata_code: "PYO",
    city_name: "Putumayo",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Puvirnituq Airport",
    longitude: -77.284857,
    latitude: 60.048848,
    id: "arp_ypx_ca",
    icao_code: "CYPX",
    iata_country_code: "CA",
    iata_code: "YPX",
    city_name: "Puvirnituq",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Pweto Airport",
    longitude: 28.887477,
    latitude: -8.468052,
    id: "arp_pwo_cd",
    icao_code: "FZQC",
    iata_country_code: "CD",
    iata_code: "PWO",
    city_name: "Pweto",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Pyay Airport",
    longitude: 95.265991,
    latitude: 18.824388,
    id: "arp_pru_mm",
    icao_code: "VYPY",
    iata_country_code: "MM",
    iata_code: "PRU",
    city_name: "Pyay",
    city: null,
  },
  {
    time_zone: "Asia/Pyongyang",
    name: "Pyongyang International Airport",
    longitude: 125.672088,
    latitude: 39.224785,
    id: "arp_fnj_kp",
    icao_code: "ZKPY",
    iata_country_code: "KP",
    iata_code: "FNJ",
    city_name: "Pyongyang",
    city: null,
  },
  {
    time_zone: "America/Thule",
    name: "Qaanaaq Airport",
    longitude: -69.38182,
    latitude: 77.487077,
    id: "arp_naq_gl",
    icao_code: "BGQQ",
    iata_country_code: "GL",
    iata_code: "NAQ",
    city_name: "Qaanaaq",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Qaarsut Airport",
    longitude: -52.695758,
    latitude: 70.734029,
    id: "arp_jqa_gl",
    icao_code: "BGUQ",
    iata_country_code: "GL",
    iata_code: "JQA",
    city_name: "Qaarsut",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Qabala International Airport",
    longitude: 47.7125,
    latitude: 40.826667,
    id: "arp_gbb_az",
    icao_code: "UBBQ",
    iata_country_code: "AZ",
    iata_code: "GBB",
    city_name: "Tindouf",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Qacha's Nek Airport",
    longitude: 28.670817,
    latitude: -30.11047,
    id: "arp_une_ls",
    icao_code: "FXQN",
    iata_country_code: "LS",
    iata_code: "UNE",
    city_name: "Qacha's Nek",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Qala-I-Naw Airport",
    longitude: 63.118537,
    latitude: 34.985917,
    id: "arp_lqn_af",
    icao_code: "OAQN",
    iata_country_code: "AF",
    iata_code: "LQN",
    city_name: "Qalah-ye Now",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qamdo Bamda Airport",
    longitude: 97.107405,
    latitude: 30.551689,
    id: "arp_bpx_cn",
    icao_code: "ZUBD",
    iata_country_code: "CN",
    iata_code: "BPX",
    city_name: "Bangda",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Qarn Alam Airport",
    longitude: 57.052345,
    latitude: 21.374744,
    id: "arp_rnm_om",
    icao_code: "OOGB",
    iata_country_code: "OM",
    iata_code: "RNM",
    city_name: "Qarn Alam",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Qasigiannguit Heliport",
    longitude: -51.176228,
    latitude: 68.822535,
    id: "arp_jch_gl",
    icao_code: "BGCH",
    iata_country_code: "GL",
    iata_code: "JCH",
    city_name: "Qasigiannguit",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Qayyarah West Airport",
    longitude: 43.125099,
    latitude: 35.7672,
    id: "arp_rqw_iq",
    icao_code: "ORQW",
    iata_country_code: "IQ",
    iata_code: "RQW",
    city_name: "Qayyarah",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Qazvin Airport",
    longitude: 50.046057,
    latitude: 36.24189,
    id: "arp_gzw_ir",
    icao_code: "OIIK",
    iata_country_code: "IR",
    iata_code: "GZW",
    city_name: "Ghazvin",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qianjiang Wulingshan Airport",
    longitude: 108.83128,
    latitude: 29.514695,
    id: "arp_jiq_cn",
    icao_code: "ZUQJ",
    iata_country_code: "CN",
    iata_code: "JIQ",
    city_name: "Qianjiang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qiemo Airport",
    longitude: 85.465556,
    latitude: 38.233611,
    id: "arp_iqm_cn",
    icao_code: "ZWCM",
    iata_country_code: "CN",
    iata_code: "IQM",
    city_name: "Qiemo",
    city: null,
  },
  {
    time_zone: "America/Pangnirtung",
    name: "Qikiqtarjuaq Airport",
    longitude: -64.029115,
    latitude: 67.546763,
    id: "arp_yvm_ca",
    icao_code: "CYVM",
    iata_country_code: "CA",
    iata_code: "YVM",
    city_name: "Qikiqtarjuaq",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qingdao Liuting International Airport",
    longitude: 120.378371,
    latitude: 36.266191,
    id: "arp_tao_cn",
    icao_code: "ZSQD",
    iata_country_code: "CN",
    iata_code: "TAO",
    city_name: "Qingdao",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qingyang Airport",
    longitude: 107.602887,
    latitude: 35.799712,
    id: "arp_iqn_cn",
    icao_code: "ZLQY",
    iata_country_code: "CN",
    iata_code: "IQN",
    city_name: "Qingyang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qinhuangdao Beidaihe Airport",
    longitude: 119.058889,
    latitude: 39.666389,
    id: "arp_bpe_cn",
    icao_code: "ZBDH",
    iata_country_code: "CN",
    iata_code: "BPE",
    city_name: "Qinhuangdao",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qionghai Bo'ao Airport",
    longitude: 110.454775,
    latitude: 19.13824,
    id: "arp_bar_cn",
    icao_code: "ZJQH",
    iata_country_code: "CN",
    iata_code: "BAR",
    city_name: "Qionghai",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Qiqihar Sanjiazi Airport",
    longitude: 123.914129,
    latitude: 47.242065,
    id: "arp_ndg_cn",
    icao_code: "ZYQQ",
    iata_country_code: "CN",
    iata_code: "NDG",
    city_name: "Qiqihar",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Qishn Airport",
    longitude: 52.173159,
    latitude: 16.195913,
    id: "arp_ihn_ye",
    icao_code: "OYQN",
    iata_country_code: "YE",
    iata_code: "IHN",
    city_name: "Qishn",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Quad City International Airport",
    longitude: -90.507885,
    latitude: 41.449067,
    id: "arp_mli_us",
    icao_code: "KMLI",
    iata_country_code: "US",
    iata_code: "MLI",
    city_name: "Moline",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Quadra Island Airport",
    longitude: -125.228814,
    latitude: 50.063674,
    id: "arp_yqj_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YQJ",
    city_name: "Quadra Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Quakertown Airport",
    longitude: -75.381881,
    latitude: 40.435184,
    id: "arp_ukt_us",
    icao_code: "KUKT",
    iata_country_code: "US",
    iata_code: "UKT",
    city_name: "Quakertown",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Qualicum Beach Airport",
    longitude: -124.393251,
    latitude: 49.337796,
    id: "arp_xqu_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "XQU",
    city_name: "Qualicum Beach",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Quang Ngai Airport",
    longitude: 108.770818,
    latitude: 15.115635,
    id: "arp_xng_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "XNG",
    city_name: "Quang Ngai",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Quantico Marine Corps Airfield",
    longitude: -77.305557,
    latitude: 38.501667,
    id: "arp_nyg_us",
    icao_code: "KNYG",
    iata_country_code: "US",
    iata_code: "NYG",
    city_name: "Quantico",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Quanzhou Jinjiang International Airport",
    longitude: 118.592319,
    latitude: 24.797886,
    id: "arp_jjn_cn",
    icao_code: "ZSQZ",
    iata_country_code: "CN",
    iata_code: "JJN",
    city_name: "Quanzhou",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Quaqtaq Airport",
    longitude: -69.619563,
    latitude: 61.046829,
    id: "arp_yqc_ca",
    icao_code: "CYHA",
    iata_country_code: "CA",
    iata_code: "YQC",
    city_name: "Quaqtaq",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Quartz Creek Airport",
    longitude: -149.722152,
    latitude: 60.483137,
    id: "arp_jla_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "JLA",
    city_name: "Cooper Landing",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Quartz Hill Airport",
    longitude: -118.001371,
    latitude: 34.666145,
    id: "arp_rzh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RZH",
    city_name: "Preobrazheniye",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Quatro de Fevereiro Airport",
    longitude: 13.23575,
    latitude: -8.848363,
    id: "arp_lad_ao",
    icao_code: "FNLU",
    iata_country_code: "AO",
    iata_code: "LAD",
    city_name: "Luanda",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Québec City Jean-Lesage International Airport",
    longitude: -71.390936,
    latitude: 46.790936,
    id: "arp_yqb_ca",
    icao_code: "CYQB",
    iata_country_code: "CA",
    iata_code: "YQB",
    city_name: "Quebec",
    city: null,
  },
  {
    time_zone: "Asia/Amman",
    name: "Queen Alia International Airport",
    longitude: 35.992224,
    latitude: 31.72266,
    id: "arp_amm_jo",
    icao_code: "OJAI",
    iata_country_code: "JO",
    iata_code: "AMM",
    city_name: "Amman",
    city: {
      name: "Amman",
      id: "cit_amm_jo",
      iata_country_code: "JO",
      iata_code: "AMM",
    },
  },
  {
    time_zone: "America/Aruba",
    name: "Queen Beatrix International Airport",
    longitude: -70.013595,
    latitude: 12.501497,
    id: "arp_aua_aw",
    icao_code: "TNCA",
    iata_country_code: "AW",
    iata_code: "AUA",
    city_name: "Aruba",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Queen Charlotte Island Airport",
    longitude: -132.073857,
    latitude: 53.251801,
    id: "arp_zqs_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZQS",
    city_name: "Queen Charlotte",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Queenstown Airport",
    longitude: 145.530418,
    latitude: -42.075232,
    id: "arp_uee_au",
    icao_code: "YQNS",
    iata_country_code: "AU",
    iata_code: "UEE",
    city_name: "Queenstown",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Queenstown Airport",
    longitude: 26.88269,
    latitude: -31.919874,
    id: "arp_utw_za",
    icao_code: "FAQT",
    iata_country_code: "ZA",
    iata_code: "UTW",
    city_name: "Queenstown",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Queenstown Airport",
    longitude: 168.73946,
    latitude: -45.021035,
    id: "arp_zqn_nz",
    icao_code: "NZQN",
    iata_country_code: "NZ",
    iata_code: "ZQN",
    city_name: "Queenstown",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Quelimane Airport",
    longitude: 36.869695,
    latitude: -17.856106,
    id: "arp_uel_mz",
    icao_code: "FQQL",
    iata_country_code: "MZ",
    iata_code: "UEL",
    city_name: "Quelimane",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Quepos La Managua Airport",
    longitude: -84.131374,
    latitude: 9.441428,
    id: "arp_xqp_cr",
    icao_code: "MRQP",
    iata_country_code: "CR",
    iata_code: "XQP",
    city_name: "Quepos",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Querétaro Intercontinental Airport",
    longitude: -100.186949,
    latitude: 20.619168,
    id: "arp_qro_mx",
    icao_code: "MMQT",
    iata_country_code: "MX",
    iata_code: "QRO",
    city_name: "Querétaro",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Quesnel Airport",
    longitude: -122.507933,
    latitude: 53.026129,
    id: "arp_yqz_ca",
    icao_code: "CYQZ",
    iata_country_code: "CA",
    iata_code: "YQZ",
    city_name: "Quesnel",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Quetta International Airport",
    longitude: 66.948004,
    latitude: 30.248896,
    id: "arp_uet_pk",
    icao_code: "OPQT",
    iata_country_code: "PK",
    iata_code: "UET",
    city_name: "Quetta",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Quetzaltenango Airport",
    longitude: -91.503217,
    latitude: 14.864636,
    id: "arp_aaz_gt",
    icao_code: "MGQZ",
    iata_country_code: "GT",
    iata_code: "AAZ",
    city_name: "Quetzaltenango",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Quiché Airport",
    longitude: -91.150576,
    latitude: 15.011517,
    id: "arp_aqb_gt",
    icao_code: "MGQC",
    iata_country_code: "GT",
    iata_code: "AQB",
    city_name: "Quiche",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Quillayute Airport",
    longitude: -124.562496,
    latitude: 47.936312,
    id: "arp_uil_us",
    icao_code: "KUIL",
    iata_country_code: "US",
    iata_code: "UIL",
    city_name: "Forks",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Quilpie Airport",
    longitude: 144.255058,
    latitude: -26.611212,
    id: "arp_ulp_au",
    icao_code: "YQLP",
    iata_country_code: "AU",
    iata_code: "ULP",
    city_name: "Quilpie",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Quimper–Bretagne Airport",
    longitude: -4.170975,
    latitude: 47.975037,
    id: "arp_uip_fr",
    icao_code: "LFRQ",
    iata_country_code: "FR",
    iata_code: "UIP",
    city_name: "Quimper",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Quince Mil Airport",
    longitude: -70.754147,
    latitude: -13.232185,
    id: "arp_umi_pe",
    icao_code: "SPIL",
    iata_country_code: "PE",
    iata_code: "UMI",
    city_name: "Quince Mil",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Quincy Regional Airport",
    longitude: -91.195751,
    latitude: 39.939991,
    id: "arp_uin_us",
    icao_code: "KUIN",
    iata_country_code: "US",
    iata_code: "UIN",
    city_name: "Quincy",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Quinhagak Airport",
    longitude: -161.880007,
    latitude: 59.757395,
    id: "arp_kwn_us",
    icao_code: "PAQH",
    iata_country_code: "US",
    iata_code: "KWN",
    city_name: "Quinhagak",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Quirindi Airport",
    longitude: 150.516349,
    latitude: -31.500302,
    id: "arp_uir_au",
    icao_code: "YQDI",
    iata_country_code: "AU",
    iata_code: "UIR",
    city_name: "Quirindi",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Quoin Hill Airport",
    longitude: 168.442311,
    latitude: -17.540061,
    id: "arp_uiq_vu",
    icao_code: "NVVQ",
    iata_country_code: "VU",
    iata_code: "UIQ",
    city_name: "Quoin Hill",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Quonset State Airport",
    longitude: -71.412546,
    latitude: 41.596073,
    id: "arp_nco_us",
    icao_code: "KOQU",
    iata_country_code: "US",
    iata_code: "NCO",
    city_name: "North Kingstown",
    city: null,
  },
  {
    time_zone: "Asia/Dushanbe",
    name: "Qurghonteppa International Airport",
    longitude: 68.863932,
    latitude: 37.864703,
    id: "arp_kqt_tj",
    icao_code: "UTDT",
    iata_country_code: "TJ",
    iata_code: "KQT",
    city_name: "Qurgonteppa",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Quthing Airport",
    longitude: 27.692594,
    latitude: -30.407407,
    id: "arp_utg_ls",
    icao_code: "FXQG",
    iata_country_code: "LS",
    iata_code: "UTG",
    city_name: "Quthing",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Quzhou Airport",
    longitude: 118.900177,
    latitude: 28.966784,
    id: "arp_juz_cn",
    icao_code: "ZSJU",
    iata_country_code: "CN",
    iata_code: "JUZ",
    city_name: "Quzhou",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Rabah Bitat Airport",
    longitude: 7.821001,
    latitude: 36.824719,
    id: "arp_aae_dz",
    icao_code: "DABB",
    iata_country_code: "DZ",
    iata_code: "AAE",
    city_name: "Annaba",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Rabak Airport",
    longitude: 32.7333,
    latitude: 13.1833,
    id: "arp_kst_sd",
    icao_code: "HSKI",
    iata_country_code: "SD",
    iata_code: "KST",
    city_name: "Kosti",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Raba Raba Airport",
    longitude: 149.83253,
    latitude: -9.970393,
    id: "arp_rbp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RBP",
    city_name: "Rabaraba",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Rabat-Salé Airport",
    longitude: -6.74875,
    latitude: 34.03711,
    id: "arp_rba_ma",
    icao_code: "GMME",
    iata_country_code: "MA",
    iata_code: "RBA",
    city_name: "Rabat",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Rabaul Airport",
    longitude: 152.379502,
    latitude: -4.340546,
    id: "arp_rab_pg",
    icao_code: "AYTK",
    iata_country_code: "PG",
    iata_code: "RAB",
    city_name: "Rabaul",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Rabi Island Airport",
    longitude: 179.976118,
    latitude: -16.533957,
    id: "arp_rbi_fj",
    icao_code: "NFFR",
    iata_country_code: "FJ",
    iata_code: "RBI",
    city_name: "Rabi Island",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Rach Gia Airport",
    longitude: 105.133095,
    latitude: 9.958736,
    id: "arp_vkg_vn",
    icao_code: "VVRG",
    iata_country_code: "VN",
    iata_code: "VKG",
    city_name: "Rach Gia",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Radin Inten II Airport",
    longitude: 105.175906,
    latitude: -5.241648,
    id: "arp_tkg_id",
    icao_code: "WILL",
    iata_country_code: "ID",
    iata_code: "TKG",
    city_name: "Bandar Lampung",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Radom Airport",
    longitude: 21.2133,
    latitude: 51.389198,
    id: "arp_rdo_pl",
    icao_code: "EPRA",
    iata_country_code: "PL",
    iata_code: "RDO",
    city_name: "Radom",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Raduzhny Airport",
    longitude: 77.328903,
    latitude: 62.1586,
    id: "arp_rat_ru",
    icao_code: "USNR",
    iata_country_code: "RU",
    iata_code: "RAT",
    city_name: "Raduzhny",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Rafaela Airport",
    longitude: -61.504052,
    latitude: -31.281682,
    id: "arp_raf_ar",
    icao_code: "SAFR",
    iata_country_code: "AR",
    iata_code: "RAF",
    city_name: "Rafaela",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Rafael Cabrera Mustelier Airport",
    longitude: -82.781899,
    latitude: 21.8361,
    id: "arp_ger_cu",
    icao_code: "MUNG",
    iata_country_code: "CU",
    iata_code: "GER",
    city_name: "Nueva Gerona",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Rafael Núñez International Airport",
    longitude: -75.514137,
    latitude: 10.444248,
    id: "arp_ctg_co",
    icao_code: "SKCG",
    iata_country_code: "CO",
    iata_code: "CTG",
    city_name: "Cartagena",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Rafaï Airport",
    longitude: 23.928054,
    latitude: 4.989439,
    id: "arp_rfa_cf",
    icao_code: "FEGR",
    iata_country_code: "CF",
    iata_code: "RFA",
    city_name: "Rafaï",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Rafha Domestic Airport",
    longitude: 43.490578,
    latitude: 29.626255,
    id: "arp_rah_sa",
    icao_code: "OERF",
    iata_country_code: "SA",
    iata_code: "RAH",
    city_name: "Rafha",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Rafsanjan Airport",
    longitude: 56.052336,
    latitude: 30.298303,
    id: "arp_rjn_ir",
    icao_code: "OIKR",
    iata_country_code: "IR",
    iata_code: "RJN",
    city_name: "Rafsanjan",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Raglan Airfield",
    longitude: 174.860001,
    latitude: -37.804699,
    id: "arp_rag_nz",
    icao_code: "NZRA",
    iata_country_code: "NZ",
    iata_code: "RAG",
    city_name: "Raglan",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Raiatea Airport",
    longitude: -151.466474,
    latitude: -16.722978,
    id: "arp_rfp_pf",
    icao_code: "NTTR",
    iata_country_code: "PF",
    iata_code: "RFP",
    city_name: "Raiatea",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Rainbow Lake Airport",
    longitude: -119.410056,
    latitude: 58.492346,
    id: "arp_yop_ca",
    icao_code: "CYOP",
    iata_country_code: "CA",
    iata_code: "YOP",
    city_name: "Rainbow Lake",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Raivavae Airport",
    longitude: -147.661979,
    latitude: -23.885214,
    id: "arp_rvv_pf",
    icao_code: "NTAV",
    iata_country_code: "PF",
    iata_code: "RVV",
    city_name: "Raivavae",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Raja Bhoj Airport",
    longitude: 77.33652,
    latitude: 23.289191,
    id: "arp_bho_in",
    icao_code: "VABP",
    iata_country_code: "IN",
    iata_code: "BHO",
    city_name: "Bhopal",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Raja Haji Fisabilillah International Airport",
    longitude: 104.53102,
    latitude: 0.921572,
    id: "arp_tnj_id",
    icao_code: "WIDN",
    iata_country_code: "ID",
    iata_code: "TNJ",
    city_name: "Tanjung Pinang",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Rajahmundry Airport",
    longitude: 81.818883,
    latitude: 17.109784,
    id: "arp_rja_in",
    icao_code: "VORY",
    iata_country_code: "IN",
    iata_code: "RJA",
    city_name: "Rajahmundry",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Rajbiraj Airport",
    longitude: 86.734954,
    latitude: 26.509657,
    id: "arp_rjb_np",
    icao_code: "VNRB",
    iata_country_code: "NP",
    iata_code: "RJB",
    city_name: "Rajbiraj",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Rajiv Gandhi International Airport",
    longitude: 78.42962,
    latitude: 17.23579,
    id: "arp_hyd_in",
    icao_code: "VOHS",
    iata_country_code: "IN",
    iata_code: "HYD",
    city_name: "Hyderabad",
    city: {
      name: "Hyderabad",
      id: "cit_hyd_in",
      iata_country_code: "IN",
      iata_code: "HYD",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Rajkot Airport",
    longitude: 70.780861,
    latitude: 22.309015,
    id: "arp_raj_in",
    icao_code: "VARK",
    iata_country_code: "IN",
    iata_code: "RAJ",
    city_name: "Rajkot",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Rajouri Airport",
    longitude: 74.315344,
    latitude: 33.377418,
    id: "arp_rji_in",
    icao_code: null,
    iata_country_code: "IN",
    iata_code: "RJI",
    city_name: "Rajauri",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Rakanda Airport",
    longitude: 152.467,
    latitude: -4.16667,
    id: "arp_raa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RAA",
    city_name: "Rakanda",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Raleigh County Memorial Airport",
    longitude: -81.123824,
    latitude: 37.789484,
    id: "arp_bkw_us",
    icao_code: "KBKW",
    iata_country_code: "US",
    iata_code: "BKW",
    city_name: "Beckley",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Raleigh–Durham International Airpor",
    longitude: -78.787747,
    latitude: 35.87884,
    id: "arp_rdu_us",
    icao_code: "KRDU",
    iata_country_code: "US",
    iata_code: "RDU",
    city_name: "Raleigh/Durham",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ralph M. Calhoun Memorial Airport",
    longitude: -152.10908,
    latitude: 65.174283,
    id: "arp_tal_us",
    icao_code: "PATA",
    iata_country_code: "US",
    iata_code: "TAL",
    city_name: "Tanana",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Ralph Wenz Field",
    longitude: -109.80703,
    latitude: 42.795502,
    id: "arp_pwy_us",
    icao_code: "KPNA",
    iata_country_code: "US",
    iata_code: "PWY",
    city_name: "Pinedale",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Ralph Wien Memorial Airport",
    longitude: -162.60017,
    latitude: 66.8879,
    id: "arp_otz_us",
    icao_code: "PAOT",
    iata_country_code: "US",
    iata_code: "OTZ",
    city_name: "Kotzebue",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Ramagundam Airport",
    longitude: 79.392049,
    latitude: 18.701098,
    id: "arp_rmd_in",
    icao_code: "VORG",
    iata_country_code: "IN",
    iata_code: "RMD",
    city_name: "Ramagundam",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Ramata Airport",
    longitude: 157.643005,
    latitude: -8.16806,
    id: "arp_rbv_sb",
    icao_code: "AGRM",
    iata_country_code: "SB",
    iata_code: "RBV",
    city_name: "Ramata",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Ramechhap Airport",
    longitude: 86.059213,
    latitude: 27.392926,
    id: "arp_rhp_np",
    icao_code: "VNRC",
    iata_country_code: "NP",
    iata_code: "RHP",
    city_name: "Ramechhap",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Ramingining Airport",
    longitude: 134.895622,
    latitude: -12.356542,
    id: "arp_ram_au",
    icao_code: "YRNG",
    iata_country_code: "AU",
    iata_code: "RAM",
    city_name: "Ramingining",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Ramon Airport",
    longitude: 35.009096,
    latitude: 29.723211,
    id: "arp_etm_il",
    icao_code: "LLER",
    iata_country_code: "IL",
    iata_code: "ETM",
    city_name: "Eilat",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Ramón Villeda Morales International Airport",
    longitude: -87.925193,
    latitude: 15.453764,
    id: "arp_sap_hn",
    icao_code: "MHLM",
    iata_country_code: "HN",
    iata_code: "SAP",
    city_name: "San Pedro Sula",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Rampart Airport",
    longitude: -150.140863,
    latitude: 65.507814,
    id: "arp_rmp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RMP",
    city_name: "Rampart",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Ramsar Airport",
    longitude: 50.680985,
    latitude: 36.907324,
    id: "arp_rzr_ir",
    icao_code: "OINR",
    iata_country_code: "IR",
    iata_code: "RZR",
    city_name: "Ramsar",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Ramstein Air Base",
    longitude: 7.598704,
    latitude: 49.438471,
    id: "arp_rms_de",
    icao_code: "ETAR",
    iata_country_code: "DE",
    iata_code: "RMS",
    city_name: "Ramstein",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Ranai Airport",
    longitude: 108.388698,
    latitude: 3.907899,
    id: "arp_ntx_id",
    icao_code: "WION",
    iata_country_code: "ID",
    iata_code: "NTX",
    city_name: "Ranai",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Ranau Airport",
    longitude: 116.678839,
    latitude: 5.956897,
    id: "arp_rnu_my",
    icao_code: "WBKR",
    iata_country_code: "MY",
    iata_code: "RNU",
    city_name: "Ranau",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Rand Airport",
    longitude: 28.151623,
    latitude: -26.242023,
    id: "arp_qra_za",
    icao_code: "FAGM",
    iata_country_code: "ZA",
    iata_code: "QRA",
    city_name: "Johannesburg",
    city: {
      name: "Johannesburg",
      id: "cit_jnb_za",
      iata_country_code: "ZA",
      iata_code: "JNB",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Randolph Air Force Base",
    longitude: -98.279345,
    latitude: 29.530606,
    id: "arp_rnd_us",
    icao_code: "KRND",
    iata_country_code: "US",
    iata_code: "RND",
    city_name: "San Antonio",
    city: {
      name: "San Antonio",
      id: "cit_sat_us",
      iata_country_code: "US",
      iata_code: "SAT",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Rangely Airport",
    longitude: -108.76408,
    latitude: 40.09315,
    id: "arp_rng_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RNG",
    city_name: "Rangely",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Range Regional Airport",
    longitude: -92.840571,
    latitude: 47.389378,
    id: "arp_hib_us",
    icao_code: "KHIB",
    iata_country_code: "US",
    iata_code: "HIB",
    city_name: "Hibbing",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ranger Municipal Airport",
    longitude: -98.68144,
    latitude: 32.45254,
    id: "arp_rgr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RGR",
    city_name: "Ranger",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Rangiroa Airport",
    longitude: -147.659922,
    latitude: -14.954625,
    id: "arp_rgi_pf",
    icao_code: "NTTG",
    iata_country_code: "PF",
    iata_code: "RGI",
    city_name: "Rangiroa",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Rankin Inlet Airport",
    longitude: -92.111343,
    latitude: 62.810617,
    id: "arp_yrt_ca",
    icao_code: "CYRT",
    iata_country_code: "CA",
    iata_code: "YRT",
    city_name: "Rankin Inlet",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Ranong Airport",
    longitude: 98.586333,
    latitude: 9.775773,
    id: "arp_unn_th",
    icao_code: "VTSR",
    iata_country_code: "TH",
    iata_code: "UNN",
    city_name: "Ranong",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Rapid City Regional Airport",
    longitude: -103.058795,
    latitude: 44.041832,
    id: "arp_rap_us",
    icao_code: "KRAP",
    iata_country_code: "US",
    iata_code: "RAP",
    city_name: "Rapid City",
    city: {
      name: "Rapid City",
      id: "cit_rap_us",
      iata_country_code: "US",
      iata_code: "RAP",
    },
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Rar Gwamar Airport",
    longitude: 134.210683,
    latitude: -5.773833,
    id: "arp_dob_id",
    icao_code: "WAPD",
    iata_country_code: "ID",
    iata_code: "DOB",
    city_name: "Dobo",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Raroia Airport",
    longitude: -142.476944,
    latitude: -16.045,
    id: "arp_rrr_pf",
    icao_code: "NTKO",
    iata_country_code: "PF",
    iata_code: "RRR",
    city_name: "Raroia",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Rarotonga International Airport",
    longitude: -159.806138,
    latitude: -21.202632,
    id: "arp_rar_ck",
    icao_code: "NCRG",
    iata_country_code: "CK",
    iata_code: "RAR",
    city_name: "Avarua",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Ras Al Khaimah International Airport",
    longitude: 55.939239,
    latitude: 25.613714,
    id: "arp_rkt_ae",
    icao_code: "OMRK",
    iata_country_code: "AE",
    iata_code: "RKT",
    city_name: "Ras Al Khaimah",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Rasht Airport",
    longitude: 49.617219,
    latitude: 37.323178,
    id: "arp_ras_ir",
    icao_code: "OIGG",
    iata_country_code: "IR",
    iata_code: "RAS",
    city_name: "Rasht",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Ratanakiri Airport",
    longitude: 106.986999,
    latitude: 13.729999,
    id: "arp_rbe_kh",
    icao_code: "VDRK",
    iata_country_code: "KH",
    iata_code: "RBE",
    city_name: "Ratanakiri",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Ratmalana Airport",
    longitude: 79.88773,
    latitude: 6.822141,
    id: "arp_rml_lk",
    icao_code: "VCCC",
    iata_country_code: "LK",
    iata_code: "RML",
    city_name: "Colombo",
    city: {
      name: "Colombo",
      id: "cit_cmb_lk",
      iata_country_code: "LK",
      iata_code: "CMB",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Ratnagiri Airport",
    longitude: 73.328145,
    latitude: 17.013617,
    id: "arp_rtc_in",
    icao_code: "VARG",
    iata_country_code: "IN",
    iata_code: "RTC",
    city_name: "Ratnagiri",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Raton Municipal Airport",
    longitude: -104.502884,
    latitude: 36.742204,
    id: "arp_rtn_us",
    icao_code: "KRTN",
    iata_country_code: "US",
    iata_code: "RTN",
    city_name: "Raton",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Raudha Airport",
    longitude: 47.279664,
    latitude: 14.447673,
    id: "arp_rxa_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "RXA",
    city_name: "Rawdah",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Raufarhöfn Airport",
    longitude: -15.924549,
    latitude: 66.407288,
    id: "arp_rfn_is",
    icao_code: "BIRG",
    iata_country_code: "IS",
    iata_code: "RFN",
    city_name: "Raufarhöfn",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Ravenna Airport",
    longitude: 12.222,
    latitude: 44.36273,
    id: "arp_ran_it",
    icao_code: "LIDR",
    iata_country_code: "IT",
    iata_code: "RAN",
    city_name: "Ravenna",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Ravensthorpe Airport",
    longitude: 120.208479,
    latitude: -33.797075,
    id: "arp_rvt_au",
    icao_code: "YNRV",
    iata_country_code: "AU",
    iata_code: "RVT",
    city_name: "Ravensthorpe",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Rawalakot Airport",
    longitude: 73.798604,
    latitude: 33.848889,
    id: "arp_raz_pk",
    icao_code: "OPRT",
    iata_country_code: "PK",
    iata_code: "RAZ",
    city_name: "Rawala Kot",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Rawlins Municipal Airport",
    longitude: -107.199966,
    latitude: 41.805597,
    id: "arp_rwl_us",
    icao_code: "KRWL",
    iata_country_code: "US",
    iata_code: "RWL",
    city_name: "Rawlins",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Razer Airport",
    longitude: 70.761213,
    latitude: 36.017086,
    id: "arp_kur_af",
    icao_code: "OARZ",
    iata_country_code: "AF",
    iata_code: "KUR",
    city_name: "Kiran wa Munjan",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Reading Regional Airport",
    longitude: -75.966476,
    latitude: 40.37902,
    id: "arp_rdg_us",
    icao_code: "KRDG",
    iata_country_code: "US",
    iata_code: "RDG",
    city_name: "Reading",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Reales Tamarindos Airport",
    longitude: -80.471144,
    latitude: -1.043043,
    id: "arp_pvo_ec",
    icao_code: "SEPV",
    iata_country_code: "EC",
    iata_code: "PVO",
    city_name: "Portoviejo",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Reao Airport",
    longitude: -136.441272,
    latitude: -18.46535,
    id: "arp_rea_pf",
    icao_code: "NTGE",
    iata_country_code: "PF",
    iata_code: "REA",
    city_name: "Reao",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Rebun Airport",
    longitude: 141.040193,
    latitude: 45.45367,
    id: "arp_rbj_jp",
    icao_code: "RJCR",
    iata_country_code: "JP",
    iata_code: "RBJ",
    city_name: "Rebun",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Rechlin–Lärz Airfield",
    longitude: 12.752222,
    latitude: 53.306389,
    id: "arp_reb_de",
    icao_code: "EDAX",
    iata_country_code: "DE",
    iata_code: "REB",
    city_name: "Rechlin",
    city: null,
  },
  {
    time_zone: "America/Recife",
    name: "Recife Guararapes International Airport",
    longitude: -34.923807,
    latitude: -8.126211,
    id: "arp_rec_br",
    icao_code: "SBRF",
    iata_country_code: "BR",
    iata_code: "REC",
    city_name: "Recife",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Reconquista Airport",
    longitude: -59.679663,
    latitude: -29.210059,
    id: "arp_rcq_ar",
    icao_code: "SATR",
    iata_country_code: "AR",
    iata_code: "RCQ",
    city_name: "Reconquista",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Redang Airport",
    longitude: 103.00635,
    latitude: 5.76655,
    id: "arp_rdn_my",
    icao_code: "WMPR",
    iata_country_code: "MY",
    iata_code: "RDN",
    city_name: "Redang",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Red Bluff Municipal Airport",
    longitude: -122.252048,
    latitude: 40.150384,
    id: "arp_rbl_us",
    icao_code: "KRBL",
    iata_country_code: "US",
    iata_code: "RBL",
    city_name: "Red Bluff",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Redcliffe Airport",
    longitude: 167.835006,
    latitude: -15.472,
    id: "arp_rcl_vu",
    icao_code: "NVSR",
    iata_country_code: "VU",
    iata_code: "RCL",
    city_name: "Redcliffe",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Red Deer Regional Airport",
    longitude: -113.889549,
    latitude: 52.179972,
    id: "arp_yqf_ca",
    icao_code: "CYQF",
    iata_country_code: "CA",
    iata_code: "YQF",
    city_name: "Red Deer",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Red Devil Airport",
    longitude: -157.348939,
    latitude: 61.787562,
    id: "arp_rdv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RDV",
    city_name: "Red Devil",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Redding Municipal Airport",
    longitude: -122.294999,
    latitude: 40.508198,
    id: "arp_rdd_us",
    icao_code: "KRDD",
    iata_country_code: "US",
    iata_code: "RDD",
    city_name: "Redding",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Red Dog Airport",
    longitude: -162.899101,
    latitude: 68.032039,
    id: "arp_rdb_us",
    icao_code: "PADG",
    iata_country_code: "US",
    iata_code: "RDB",
    city_name: "Red Dog",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Redenção Airport",
    longitude: -49.985209,
    latitude: -8.040585,
    id: "arp_rdc_br",
    icao_code: "SNDC",
    iata_country_code: "BR",
    iata_code: "RDC",
    city_name: "Redencao",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Red Lake Airport",
    longitude: -93.793339,
    latitude: 51.066931,
    id: "arp_yrl_ca",
    icao_code: "CYRL",
    iata_country_code: "CA",
    iata_code: "YRL",
    city_name: "Red Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Redstone Army Airfield",
    longitude: -86.684761,
    latitude: 34.678656,
    id: "arp_hua_us",
    icao_code: "KHUA",
    iata_country_code: "US",
    iata_code: "HUA",
    city_name: "Huntsville",
    city: {
      name: "Huntsville",
      id: "cit_hsv_us",
      iata_country_code: "US",
      iata_code: "HSV",
    },
  },
  {
    time_zone: "America/Winnipeg",
    name: "Red Sucker Lake Airport",
    longitude: -93.558591,
    latitude: 54.167173,
    id: "arp_yrs_ca",
    icao_code: "CYRS",
    iata_country_code: "CA",
    iata_code: "YRS",
    city_name: "Red Sucker Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Redwood Falls Municipal Airport",
    longitude: -95.082272,
    latitude: 44.547206,
    id: "arp_rwf_us",
    icao_code: "KRWF",
    iata_country_code: "US",
    iata_code: "RWF",
    city_name: "Redwood Falls",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Reese Airpark",
    longitude: -102.037636,
    latitude: 33.590391,
    id: "arp_ree_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "REE",
    city_name: "Lubbock",
    city: {
      name: "Lubbock",
      id: "cit_lbb_us",
      iata_country_code: "US",
      iata_code: "LBB",
    },
  },
  {
    time_zone: "Europe/Rome",
    name: "Reggio Calabria Airport",
    longitude: 15.651408,
    latitude: 38.071806,
    id: "arp_reg_it",
    icao_code: "LICR",
    iata_country_code: "IT",
    iata_code: "REG",
    city_name: "Reggio Calabria",
    city: null,
  },
  {
    time_zone: "America/Cayenne",
    name: "Regina Airport",
    longitude: -52.132092,
    latitude: 4.314242,
    id: "arp_rei_gf",
    icao_code: "SOOR",
    iata_country_code: "GF",
    iata_code: "REI",
    city_name: "Regina",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Regina International Airport",
    longitude: -104.662288,
    latitude: 50.432731,
    id: "arp_yqr_ca",
    icao_code: "CYQR",
    iata_country_code: "CA",
    iata_code: "YQR",
    city_name: "Regina",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Reginaldo Hammer Airport",
    longitude: -87.898536,
    latitude: 15.442787,
    id: "arp_llh_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "LLH",
    city_name: "Las Limas",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Región de Murcia International Airport",
    longitude: -1.12711,
    latitude: 37.803492,
    id: "arp_rmu_es",
    icao_code: "LEMI",
    iata_country_code: "ES",
    iata_code: "RMU",
    city_name: "Corvera",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Region of Waterloo International Airport",
    longitude: -80.382218,
    latitude: 43.458296,
    id: "arp_ykf_ca",
    icao_code: "CYKF",
    iata_country_code: "CA",
    iata_code: "YKF",
    city_name: "Toronto",
    city: {
      name: "Toronto",
      id: "cit_yto_ca",
      iata_country_code: "CA",
      iata_code: "YTO",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Reid–Hillview Airport",
    longitude: -121.81941,
    latitude: 37.334459,
    id: "arp_rhv_us",
    icao_code: "KRHV",
    iata_country_code: "US",
    iata_code: "RHV",
    city_name: "San Jose",
    city: {
      name: "San Jose",
      id: "cit_sjc_us",
      iata_country_code: "US",
      iata_code: "SJC",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Reims – Champagne Air Base Airport",
    longitude: 4.053098,
    latitude: 49.30955,
    id: "arp_rhe_fr",
    icao_code: "LFSR",
    iata_country_code: "FR",
    iata_code: "RHE",
    city_name: "Reims",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Reivilo Airport",
    longitude: 24.1333,
    latitude: -27.6,
    id: "arp_rvo_za",
    icao_code: "FARI",
    iata_country_code: "ZA",
    iata_code: "RVO",
    city_name: "Reivilo",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Reko Diq Airport",
    longitude: 62.197126,
    latitude: 29.044781,
    id: "arp_req_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "REQ",
    city_name: "Reko Diq",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Relais de la Reine Airport",
    longitude: 45.33056,
    latitude: -22.643745,
    id: "arp_rlr_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "RLR",
    city_name: "Isalo",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Rendani Airport",
    longitude: 134.04931,
    latitude: -0.89235,
    id: "arp_mkw_id",
    icao_code: "WASR",
    iata_country_code: "ID",
    iata_code: "MKW",
    city_name: "Manokwari",
    city: null,
  },
  {
    time_zone: "Asia/Beirut",
    name: "Rene Mouawad Air Base",
    longitude: 36.011299,
    latitude: 34.589298,
    id: "arp_kye_lb",
    icao_code: "OLKA",
    iata_country_code: "LB",
    iata_code: "KYE",
    city_name: "Tripoli",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Renmark Airport",
    longitude: 140.677107,
    latitude: -34.197599,
    id: "arp_rmk_au",
    icao_code: "YREN",
    iata_country_code: "AU",
    iata_code: "RMK",
    city_name: "Renmark",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Rennell/Tingoa Airport",
    longitude: 160.063003,
    latitude: -11.5339,
    id: "arp_rnl_sb",
    icao_code: "AGGR",
    iata_country_code: "SB",
    iata_code: "RNL",
    city_name: "Rennell Island",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Rennes-Saint-Jacques Airport",
    longitude: -1.732593,
    latitude: 48.069293,
    id: "arp_rns_fr",
    icao_code: "LFRN",
    iata_country_code: "FR",
    iata_code: "RNS",
    city_name: "Rennes",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Reno–Tahoe International Airport",
    longitude: -119.768046,
    latitude: 39.499345,
    id: "arp_rno_us",
    icao_code: "KRNO",
    iata_country_code: "US",
    iata_code: "RNO",
    city_name: "Reno",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Renton Municipal Airport",
    longitude: -122.216315,
    latitude: 47.492813,
    id: "arp_rnt_us",
    icao_code: "KRNT",
    iata_country_code: "US",
    iata_code: "RNT",
    city_name: "Renton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Republic Airport",
    longitude: -73.414503,
    latitude: 40.728291,
    id: "arp_frg_us",
    icao_code: "KFRG",
    iata_country_code: "US",
    iata_code: "FRG",
    city_name: "Farmingdale",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Resende Airport",
    longitude: -44.4803,
    latitude: -22.4785,
    id: "arp_rez_br",
    icao_code: "SDRS",
    iata_country_code: "BR",
    iata_code: "REZ",
    city_name: "Resende",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Resistencia International Airport",
    longitude: -59.052817,
    latitude: -27.447646,
    id: "arp_res_ar",
    icao_code: "SARE",
    iata_country_code: "AR",
    iata_code: "RES",
    city_name: "Resistencia",
    city: null,
  },
  {
    time_zone: "America/Resolute",
    name: "Resolute Bay Airport",
    longitude: -94.972161,
    latitude: 74.716839,
    id: "arp_yrb_ca",
    icao_code: "CYRB",
    iata_country_code: "CA",
    iata_code: "YRB",
    city_name: "Resolute",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Retalhuleu Airport",
    longitude: -91.696503,
    latitude: 14.521918,
    id: "arp_rer_gt",
    icao_code: "MGRT",
    iata_country_code: "GT",
    iata_code: "RER",
    city_name: "Retalhuleu",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Reus Airport",
    longitude: 1.167543,
    latitude: 41.147685,
    id: "arp_reu_es",
    icao_code: "LERS",
    iata_country_code: "ES",
    iata_code: "REU",
    city_name: "Reus",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Revelstoke Airport",
    longitude: -118.181232,
    latitude: 50.963596,
    id: "arp_yrv_ca",
    icao_code: "CYRV",
    iata_country_code: "CA",
    iata_code: "YRV",
    city_name: "Revelstoke",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Rewa Airport",
    longitude: 81.219062,
    latitude: 24.502348,
    id: "arp_rew_in",
    icao_code: null,
    iata_country_code: "IN",
    iata_code: "REW",
    city_name: "Rewa",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Rexburg Madison County Airport",
    longitude: -111.806037,
    latitude: 43.83329,
    id: "arp_rxe_us",
    icao_code: "KRXE",
    iata_country_code: "US",
    iata_code: "RXE",
    city_name: "Rexburg",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Reyes Airport",
    longitude: -67.353621,
    latitude: -14.304787,
    id: "arp_rey_bo",
    icao_code: "SLRY",
    iata_country_code: "BO",
    iata_code: "REY",
    city_name: "Reyes",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Reyes Murillo Airport",
    longitude: -77.262951,
    latitude: 5.709363,
    id: "arp_nqu_co",
    icao_code: "SKNQ",
    iata_country_code: "CO",
    iata_code: "NQU",
    city_name: "Nuquí",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Reykhólar Airport",
    longitude: -22.755794,
    latitude: 65.059841,
    id: "arp_rha_is",
    icao_code: "BIRE",
    iata_country_code: "IS",
    iata_code: "RHA",
    city_name: "Reykhólar",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Reykjahlíð Airport",
    longitude: -16.918909,
    latitude: 65.654914,
    id: "arp_mva_is",
    icao_code: "BIRL",
    iata_country_code: "IS",
    iata_code: "MVA",
    city_name: "Myvatn",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Reykjavík Airport",
    longitude: -21.93791,
    latitude: 64.130054,
    id: "arp_rkv_is",
    icao_code: "BIRK",
    iata_country_code: "IS",
    iata_code: "RKV",
    city_name: "Reykjavik",
    city: {
      name: "Reykjavik",
      id: "cit_rek_is",
      iata_country_code: "IS",
      iata_code: "REK",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Rhinelander-Oneida County Airport",
    longitude: -89.466185,
    latitude: 45.629106,
    id: "arp_rhi_us",
    icao_code: "KRHI",
    iata_country_code: "US",
    iata_code: "RHI",
    city_name: "Rhinelander",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Rhodes International Airport",
    longitude: 28.087403,
    latitude: 36.404674,
    id: "arp_rho_gr",
    icao_code: "LGRP",
    iata_country_code: "GR",
    iata_code: "RHO",
    city_name: "Rhodes",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Riberalta Airport",
    longitude: -66.075414,
    latitude: -11.006739,
    id: "arp_rib_bo",
    icao_code: "SLRI",
    iata_country_code: "BO",
    iata_code: "RIB",
    city_name: "Riberalta",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Ricardo García Posada Airport",
    longitude: -69.76817,
    latitude: -26.311162,
    id: "arp_esr_cl",
    icao_code: "SCES",
    iata_country_code: "CL",
    iata_code: "ESR",
    city_name: "El Salvador",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rice Lake Regional Airport",
    longitude: -91.77222,
    latitude: 45.418907,
    id: "arp_rie_us",
    icao_code: "KRPD",
    iata_country_code: "US",
    iata_code: "RIE",
    city_name: "Rice Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Richard B. Russell Airport",
    longitude: -85.159616,
    latitude: 34.351316,
    id: "arp_rmg_us",
    icao_code: "KRMG",
    iata_country_code: "US",
    iata_code: "RMG",
    city_name: "Rome",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Richard I Bong Airport",
    longitude: -92.094636,
    latitude: 46.687332,
    id: "arp_suw_us",
    icao_code: "KSUW",
    iata_country_code: "US",
    iata_code: "SUW",
    city_name: "Superior",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Richard Lloyd Jones Jr Airport",
    longitude: -95.985692,
    latitude: 36.039723,
    id: "arp_rvs_us",
    icao_code: "KRVS",
    iata_country_code: "US",
    iata_code: "RVS",
    city_name: "Tulsa",
    city: {
      name: "Tulsa",
      id: "cit_tul_us",
      iata_country_code: "US",
      iata_code: "TUL",
    },
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Richard Pearse Airport",
    longitude: 171.225443,
    latitude: -44.303015,
    id: "arp_tiu_nz",
    icao_code: "NZTU",
    iata_country_code: "NZ",
    iata_code: "TIU",
    city_name: "Timaru",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Richards Bay Airport",
    longitude: 32.093166,
    latitude: -28.7393,
    id: "arp_rcb_za",
    icao_code: "FARB",
    iata_country_code: "ZA",
    iata_code: "RCB",
    city_name: "Richards Bay",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Richard Toll Airport",
    longitude: -15.65361,
    latitude: 16.435111,
    id: "arp_rdt_sn",
    icao_code: "GOSR",
    iata_country_code: "SN",
    iata_code: "RDT",
    city_name: "Richard Toll",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Richfield Municipal Airport",
    longitude: -112.097373,
    latitude: 38.74433,
    id: "arp_rif_us",
    icao_code: "KRIF",
    iata_country_code: "US",
    iata_code: "RIF",
    city_name: "Richfield",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Richland Airport",
    longitude: -119.30264,
    latitude: 46.303216,
    id: "arp_rld_us",
    icao_code: "KRLD",
    iata_country_code: "US",
    iata_code: "RLD",
    city_name: "Richland",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Richmond Airport",
    longitude: 143.115224,
    latitude: -20.702059,
    id: "arp_rcm_au",
    icao_code: "YRMD",
    iata_country_code: "AU",
    iata_code: "RCM",
    city_name: "Richmond",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Richmond International Airport",
    longitude: -77.320256,
    latitude: 37.505889,
    id: "arp_ric_us",
    icao_code: "KRIC",
    iata_country_code: "US",
    iata_code: "RIC",
    city_name: "Richmond",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Richmond Municipal Airport",
    longitude: -84.846753,
    latitude: 39.756765,
    id: "arp_rid_us",
    icao_code: "KRID",
    iata_country_code: "US",
    iata_code: "RID",
    city_name: "Richmond",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Rickenbacker International Airport",
    longitude: -82.93181,
    latitude: 39.815038,
    id: "arp_lck_us",
    icao_code: "KLCK",
    iata_country_code: "US",
    iata_code: "LCK",
    city_name: "Columbus",
    city: {
      name: "Columbus",
      id: "cit_cmh_us",
      iata_country_code: "US",
      iata_code: "CMH",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Rick Husband Amarillo International Airport",
    longitude: -101.706679,
    latitude: 35.219956,
    id: "arp_ama_us",
    icao_code: "KAMA",
    iata_country_code: "US",
    iata_code: "AMA",
    city_name: "Amarillo",
    city: {
      name: "Amarillo",
      id: "cit_ama_us",
      iata_country_code: "US",
      iata_code: "AMA",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Riesa-Göhlis Airport",
    longitude: 13.356666,
    latitude: 51.293609,
    id: "arp_ies_de",
    icao_code: "EDAU",
    iata_country_code: "DE",
    iata_code: "IES",
    city_name: "Riesa",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Rif Airport",
    longitude: -23.819794,
    latitude: 64.912461,
    id: "arp_oli_is",
    icao_code: "BIRF",
    iata_country_code: "IS",
    iata_code: "OLI",
    city_name: "Olafsvik",
    city: null,
  },
  {
    time_zone: "Europe/Riga",
    name: "Riga International Airport",
    longitude: 23.972206,
    latitude: 56.923127,
    id: "arp_rix_lv",
    icao_code: "EVRA",
    iata_country_code: "LV",
    iata_code: "RIX",
    city_name: "Riga",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Rigolet Airport",
    longitude: -58.455437,
    latitude: 54.179352,
    id: "arp_yrg_ca",
    icao_code: "CCZ2",
    iata_country_code: "CA",
    iata_code: "YRG",
    city_name: "Rigolet",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Rijeka Airport",
    longitude: 14.569678,
    latitude: 45.21705,
    id: "arp_rjk_hr",
    icao_code: "LDRI",
    iata_country_code: "HR",
    iata_code: "RJK",
    city_name: "Rijeka",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Rimatara Airport",
    longitude: -152.805687,
    latitude: -22.63761,
    id: "arp_rmt_pf",
    icao_code: "NTAM",
    iata_country_code: "PF",
    iata_code: "RMT",
    city_name: "Rimatara",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Rimouski Airport",
    longitude: -68.501219,
    latitude: 48.477409,
    id: "arp_yxk_ca",
    icao_code: "CYXK",
    iata_country_code: "CA",
    iata_code: "YXK",
    city_name: "Rimouski",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Rincon De Los Sauces Airport",
    longitude: -68.903715,
    latitude: -37.391033,
    id: "arp_rds_ar",
    icao_code: "SAHS",
    iata_country_code: "AR",
    iata_code: "RDS",
    city_name: "Rincon de los Sauces",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Ringi Cove Airport",
    longitude: 157.143005,
    latitude: -8.12639,
    id: "arp_rin_sb",
    icao_code: "AGRC",
    iata_country_code: "SB",
    iata_code: "RIN",
    city_name: "Ringi Cove",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rinkenberger Airport",
    longitude: -89.617976,
    latitude: 41.232221,
    id: "arp_bdf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BDF",
    city_name: "Bradford",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Rio Amazonas Airport",
    longitude: -78.063979,
    latitude: -1.503926,
    id: "arp_ptz_ec",
    icao_code: "SESM",
    iata_country_code: "EC",
    iata_code: "PTZ",
    city_name: "Shell Mera",
    city: null,
  },
  {
    time_zone: "America/Rio_Branco",
    name: "Rio Branco International Airport",
    longitude: -67.897667,
    latitude: -9.8676,
    id: "arp_rbr_br",
    icao_code: "SBRB",
    iata_country_code: "BR",
    iata_code: "RBR",
    city_name: "Rio Branco",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Rio de Janeiro-Galeão International Airport",
    longitude: -43.251792,
    latitude: -22.809668,
    id: "arp_gig_br",
    icao_code: "SBGL",
    iata_country_code: "BR",
    iata_code: "GIG",
    city_name: "Rio de Janeiro",
    city: {
      name: "Rio de Janeiro",
      id: "cit_rio_br",
      iata_country_code: "BR",
      iata_code: "RIO",
    },
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Rio Frio / Progreso Airport",
    longitude: -83.886638,
    latitude: 10.326661,
    id: "arp_rfr_cr",
    icao_code: "MRRF",
    iata_country_code: "CR",
    iata_code: "RFR",
    city_name: "Rio Frio/Progreso",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Rio Grande Regional Airport",
    longitude: -52.164437,
    latitude: -32.0817,
    id: "arp_rig_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "RIG",
    city_name: "Rio Grande",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "Rio Mayo Airport",
    longitude: -70.24502,
    latitude: -45.703894,
    id: "arp_roy_ar",
    icao_code: "SAWM",
    iata_country_code: "AR",
    iata_code: "ROY",
    city_name: "Rio Mayo",
    city: null,
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Rio Turbio Airport",
    longitude: -72.220316,
    latitude: -51.604357,
    id: "arp_ryo_ar",
    icao_code: "SAWT",
    iata_country_code: "AR",
    iata_code: "RYO",
    city_name: "Rio Turbio",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Rio Verde Airport",
    longitude: -50.956957,
    latitude: -17.835409,
    id: "arp_rvd_br",
    icao_code: "SWLC",
    iata_country_code: "BR",
    iata_code: "RVD",
    city_name: "Rio Verde",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Rishiri Airport",
    longitude: 141.185378,
    latitude: 45.242664,
    id: "arp_ris_jp",
    icao_code: "RJER",
    iata_country_code: "JP",
    iata_code: "RIS",
    city_name: "Rishiri",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "River Cess Airport",
    longitude: -9.585554,
    latitude: 5.468896,
    id: "arp_rvc_lr",
    icao_code: null,
    iata_country_code: "LR",
    iata_code: "RVC",
    city_name: "River Cess",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Rivers Airport",
    longitude: -100.200097,
    latitude: 50.032341,
    id: "arp_yyi_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YYI",
    city_name: "Rivers",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Riverside Municipal Airport",
    longitude: -117.445466,
    latitude: 33.951221,
    id: "arp_ral_us",
    icao_code: "KRAL",
    iata_country_code: "US",
    iata_code: "RAL",
    city_name: "Riverside",
    city: {
      name: "Riverside",
      id: "cit_ral_us",
      iata_country_code: "US",
      iata_code: "RAL",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Rivers Inlet Airport",
    longitude: -127.190787,
    latitude: 51.682736,
    id: "arp_yrn_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YRN",
    city_name: "Rivers Inlet",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Riverton Regional Airport",
    longitude: -108.459889,
    latitude: 43.063782,
    id: "arp_riw_us",
    icao_code: "KRIW",
    iata_country_code: "US",
    iata_code: "RIW",
    city_name: "Riverton",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Riviera Airport",
    longitude: 8.126495,
    latitude: 44.048763,
    id: "arp_all_it",
    icao_code: "LIMG",
    iata_country_code: "IT",
    iata_code: "ALL",
    city_name: "Albenga",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Rivière-du-Loup Airport",
    longitude: -69.585532,
    latitude: 47.762447,
    id: "arp_yri_ca",
    icao_code: "CYRI",
    iata_country_code: "CA",
    iata_code: "YRI",
    city_name: "Rivière-du-Loup",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Rivne International Airport",
    longitude: 26.155049,
    latitude: 50.596239,
    id: "arp_rwn_ua",
    icao_code: "UKLR",
    iata_country_code: "UA",
    iata_code: "RWN",
    city_name: "Rivne",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Rizhao Shanzihe Airport",
    longitude: 119.324403,
    latitude: 35.405033,
    id: "arp_riz_cn",
    icao_code: "ZSRZ",
    iata_country_code: "CN",
    iata_code: "RIZ",
    city_name: "Rizhao",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "RNAS Yeovilton Airport",
    longitude: -2.639852,
    latitude: 51.009692,
    id: "arp_yeo_gb",
    icao_code: "EGDY",
    iata_country_code: "GB",
    iata_code: "YEO",
    city_name: "Yeovilton",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "RNZAF Base Ohakea Airport",
    longitude: 175.387929,
    latitude: -40.206123,
    id: "arp_oha_nz",
    icao_code: "NZOH",
    iata_country_code: "NZ",
    iata_code: "OHA",
    city_name: "Ohakea",
    city: null,
  },
  {
    time_zone: "America/Tortola",
    name: "Road Town Airport",
    longitude: -64.6,
    latitude: 18.45,
    id: "arp_rad_vg",
    icao_code: null,
    iata_country_code: "VG",
    iata_code: "RAD",
    city_name: "Tortola",
    city: {
      name: "Tortola",
      id: "cit_tov_vg",
      iata_country_code: "VG",
      iata_code: "TOV",
    },
  },
  {
    time_zone: "Europe/Paris",
    name: "Roanne-Renaison Airport",
    longitude: 4.001204,
    latitude: 46.056644,
    id: "arp_rne_fr",
    icao_code: "LFLO",
    iata_country_code: "FR",
    iata_code: "RNE",
    city_name: "Roanne",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Roanoke-Blacksburg Regional Airport",
    longitude: -79.972779,
    latitude: 37.322911,
    id: "arp_roa_us",
    icao_code: "KROA",
    iata_country_code: "US",
    iata_code: "ROA",
    city_name: "Roanoke",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Robe Airport",
    longitude: 40.046475,
    latitude: 7.116466,
    id: "arp_gob_et",
    icao_code: "HAGB",
    iata_country_code: "ET",
    iata_code: "GOB",
    city_name: "Goba",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Roben-Hood Airport",
    longitude: -85.506488,
    latitude: 43.723559,
    id: "arp_wbr_us",
    icao_code: "KRQB",
    iata_country_code: "US",
    iata_code: "WBR",
    city_name: "Big Rapids",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Robert",
    longitude: -161.020955,
    latitude: 66.817689,
    id: "arp_orv_us",
    icao_code: "PFNO",
    iata_country_code: "US",
    iata_code: "ORV",
    city_name: "Noorvik",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Robert Atty Bessing Airport",
    longitude: 116.619722,
    latitude: 3.575278,
    id: "arp_lnu_id",
    icao_code: "WALM",
    iata_country_code: "ID",
    iata_code: "LNU",
    city_name: "Malinau",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Robert F. Swinnie Airport",
    longitude: -79.526234,
    latitude: 33.451344,
    id: "arp_adr_us",
    icao_code: "KPHH",
    iata_country_code: "US",
    iata_code: "ADR",
    city_name: "Andrews",
    city: null,
  },
  {
    time_zone: "America/St_Kitts",
    name: "Robert L. Bradshaw International Airport",
    longitude: -62.717654,
    latitude: 17.311101,
    id: "arp_skb_kn",
    icao_code: "TKPK",
    iata_country_code: "KN",
    iata_code: "SKB",
    city_name: "St Kitts",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Roberts Field",
    longitude: -121.15362,
    latitude: 44.253802,
    id: "arp_rdm_us",
    icao_code: "KRDM",
    iata_country_code: "US",
    iata_code: "RDM",
    city_name: "Redmond",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Roberts International Airport",
    longitude: -10.359068,
    latitude: 6.237135,
    id: "arp_rob_lr",
    icao_code: "GLRB",
    iata_country_code: "LR",
    iata_code: "ROB",
    city_name: "Monrovia",
    city: {
      name: "Monrovia",
      id: "cit_mlw_lr",
      iata_country_code: "LR",
      iata_code: "MLW",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Robert S. Kerr Airport",
    longitude: -94.621148,
    latitude: 35.01921,
    id: "arp_rkr_us",
    icao_code: "KRKR",
    iata_country_code: "US",
    iata_code: "RKR",
    city_name: "Poteau",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Robertson Airport",
    longitude: 19.902687,
    latitude: -33.812337,
    id: "arp_rod_za",
    icao_code: "FARS",
    iata_country_code: "ZA",
    iata_code: "ROD",
    city_name: "Robertson",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Roberval Airport",
    longitude: -72.266093,
    latitude: 48.520156,
    id: "arp_yrj_ca",
    icao_code: "CYRJ",
    iata_country_code: "CA",
    iata_code: "YRJ",
    city_name: "Roberval",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Robinhood Airport",
    longitude: -1.008149,
    latitude: 53.480745,
    id: "arp_roh_au",
    icao_code: "YROB",
    iata_country_code: "AU",
    iata_code: "ROH",
    city_name: "Robinhood",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Robins Air Force Base",
    longitude: -83.591891,
    latitude: 32.638048,
    id: "arp_wrb_us",
    icao_code: "KWRB",
    iata_country_code: "US",
    iata_code: "WRB",
    city_name: "Warner Robins",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Robinson River Airport",
    longitude: 148.820283,
    latitude: -10.169735,
    id: "arp_rnr_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RNR",
    city_name: "Robinson River",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Robinson River Airport",
    longitude: 136.945758,
    latitude: -16.718639,
    id: "arp_rrv_au",
    icao_code: "YRBR",
    iata_country_code: "AU",
    iata_code: "RRV",
    city_name: "Robinson River",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Robinvale Airport",
    longitude: 142.772161,
    latitude: -34.64314,
    id: "arp_rbc_au",
    icao_code: "YROI",
    iata_country_code: "AU",
    iata_code: "RBC",
    city_name: "Robinvale",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Roboré Airport",
    longitude: -97.689949,
    latitude: 27.778775,
    id: "arp_rbo_bo",
    icao_code: "SLRB",
    iata_country_code: "BO",
    iata_code: "RBO",
    city_name: "Roboré",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Rochefort – Saint-Agnant Airpor",
    longitude: -0.983148,
    latitude: 45.888069,
    id: "arp_rco_fr",
    icao_code: "LFDN",
    iata_country_code: "FR",
    iata_code: "RCO",
    city_name: "Rochefort",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Roche Harbor Airport",
    longitude: -123.14192,
    latitude: 48.611565,
    id: "arp_rce_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RCE",
    city_name: "Roche Harbor",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Rochester Airport",
    longitude: 0.504137,
    latitude: 51.349716,
    id: "arp_rcs_gb",
    icao_code: "EGTO",
    iata_country_code: "GB",
    iata_code: "RCS",
    city_name: "Rochester",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rochester International Airport",
    longitude: -92.496792,
    latitude: 43.909463,
    id: "arp_rst_us",
    icao_code: "KRST",
    iata_country_code: "US",
    iata_code: "RST",
    city_name: "Rochester",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Rockhampton Airport",
    longitude: 150.476164,
    latitude: -23.38042,
    id: "arp_rok_au",
    icao_code: "YBRK",
    iata_country_code: "AU",
    iata_code: "ROK",
    city_name: "Rockhampton",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Rockhampton Downs Airport",
    longitude: 135.200531,
    latitude: -18.953308,
    id: "arp_rda_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "RDA",
    city_name: "Rockhampton Downs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Rock Hill - York County Airport",
    longitude: -81.057947,
    latitude: 34.98762,
    id: "arp_rkh_us",
    icao_code: "KUZA",
    iata_country_code: "US",
    iata_code: "RKH",
    city_name: "Rock Hill",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Rock Sound International Airport",
    longitude: -76.174239,
    latitude: 24.893709,
    id: "arp_rsd_bs",
    icao_code: "MYER",
    iata_country_code: "BS",
    iata_code: "RSD",
    city_name: "Rock Sound",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Rock Springs-Sweetwater County Airport",
    longitude: -109.064856,
    latitude: 41.594649,
    id: "arp_rks_us",
    icao_code: "KRKS",
    iata_country_code: "US",
    iata_code: "RKS",
    city_name: "Rock Springs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rockwood Municipal Airport",
    longitude: -84.692631,
    latitude: 35.920949,
    id: "arp_rkw_us",
    icao_code: "KRKW",
    iata_country_code: "US",
    iata_code: "RKW",
    city_name: "Rockwood",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Rocky Mountain House Airport",
    longitude: -114.906245,
    latitude: 52.430194,
    id: "arp_yrm_ca",
    icao_code: "CYRM",
    iata_country_code: "CA",
    iata_code: "YRM",
    city_name: "Rocky Mountain House",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Rocky Mountain Metropolitan Airport",
    longitude: -105.114425,
    latitude: 39.909265,
    id: "arp_bjc_us",
    icao_code: "KBJC",
    iata_country_code: "US",
    iata_code: "BJC",
    city_name: "Denver",
    city: {
      name: "Denver",
      id: "cit_den_us",
      iata_country_code: "US",
      iata_code: "DEN",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Rocky Mount-Wilson Regional Airport",
    longitude: -77.893277,
    latitude: 35.855197,
    id: "arp_rwi_us",
    icao_code: "KRWI",
    iata_country_code: "US",
    iata_code: "RWI",
    city_name: "Rocky Mount",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Rodelillo Airport",
    longitude: -71.557698,
    latitude: -33.066862,
    id: "arp_vap_cl",
    icao_code: "SCRD",
    iata_country_code: "CL",
    iata_code: "VAP",
    city_name: "Valparaiso",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Rodez–Aveyron Airport",
    longitude: 2.483105,
    latitude: 44.409304,
    id: "arp_rdz_fr",
    icao_code: "LFCR",
    iata_country_code: "FR",
    iata_code: "RDZ",
    city_name: "Rodez",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Rodríguez Ballón International Airport",
    longitude: -71.567425,
    latitude: -16.344037,
    id: "arp_aqp_pe",
    icao_code: "SPQU",
    iata_country_code: "PE",
    iata_code: "AQP",
    city_name: "Arequipa",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Roebourne Airport",
    longitude: 117.156932,
    latitude: -20.760836,
    id: "arp_rbu_au",
    icao_code: "YROE",
    iata_country_code: "AU",
    iata_code: "RBU",
    city_name: "Roebourne",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rogers Executive Airport",
    longitude: -94.106928,
    latitude: 36.372248,
    id: "arp_rog_us",
    icao_code: "KROG",
    iata_country_code: "US",
    iata_code: "ROG",
    city_name: "Rogers",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Rogue Valley International–Medford Airport",
    longitude: -122.873574,
    latitude: 42.371332,
    id: "arp_mfr_us",
    icao_code: "KMFR",
    iata_country_code: "US",
    iata_code: "MFR",
    city_name: "Medford",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Roi Et Airport",
    longitude: 103.77537,
    latitude: 16.116791,
    id: "arp_roi_th",
    icao_code: "VTUV",
    iata_country_code: "TH",
    iata_code: "ROI",
    city_name: "Roi Et",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Rokeby Airport",
    longitude: 142.639767,
    latitude: -13.645329,
    id: "arp_rky_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "RKY",
    city_name: "Rokeby",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Rokot Airport",
    longitude: 99.705323,
    latitude: -2.098828,
    id: "arp_rki_id",
    icao_code: "WIBR",
    iata_country_code: "ID",
    iata_code: "RKI",
    city_name: "Rokot",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Roland-Désourdy Airport",
    longitude: -72.743028,
    latitude: 45.28947,
    id: "arp_zbm_ca",
    icao_code: "CZBM",
    iata_country_code: "CA",
    iata_code: "ZBM",
    city_name: "Bromont",
    city: null,
  },
  {
    time_zone: "Indian/Reunion",
    name: "Roland Garros Airport",
    longitude: 55.512641,
    latitude: -20.889368,
    id: "arp_run_re",
    icao_code: "FMEE",
    iata_country_code: "RE",
    iata_code: "RUN",
    city_name: "St Denis",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rolla National Airport",
    longitude: -91.767202,
    latitude: 38.130375,
    id: "arp_vih_us",
    icao_code: "KVIH",
    iata_country_code: "US",
    iata_code: "VIH",
    city_name: "Rolla/Vichy",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rollang Field",
    longitude: -90.845933,
    latitude: 32.954293,
    id: "arp_rfk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RFK",
    city_name: "Rolling Fork",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Rolpa Airport",
    longitude: 82.65,
    latitude: 28.7,
    id: "arp_rpa_np",
    icao_code: "VNRP",
    iata_country_code: "NP",
    iata_code: "RPA",
    city_name: "Rolpa",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Roma Airport",
    longitude: 148.777157,
    latitude: -26.544383,
    id: "arp_rma_au",
    icao_code: "YROM",
    iata_country_code: "AU",
    iata_code: "RMA",
    city_name: "Roma",
    city: null,
  },
  {
    time_zone: "Pacific/Palau",
    name: "Roman Tmetuchl International Airport",
    longitude: 134.540045,
    latitude: 7.366569,
    id: "arp_ror_pw",
    icao_code: "PTRO",
    iata_country_code: "PW",
    iata_code: "ROR",
    city_name: "Koror",
    city: null,
  },
  {
    time_zone: "America/Boise",
    name: "Rome State Airport",
    longitude: -117.885358,
    latitude: 42.577748,
    id: "arp_reo_us",
    icao_code: "KREO",
    iata_country_code: "US",
    iata_code: "REO",
    city_name: "Rome",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ronald Reagan Washington National Airport",
    longitude: -77.038964,
    latitude: 38.851671,
    id: "arp_dca_us",
    icao_code: "KDCA",
    iata_country_code: "US",
    iata_code: "DCA",
    city_name: "Washington",
    city: {
      name: "Washington",
      id: "cit_was_us",
      iata_country_code: "US",
      iata_code: "WAS",
    },
  },
  {
    time_zone: "America/Cuiaba",
    name: "Rondonopolis Airport",
    longitude: -54.723546,
    latitude: -16.58707,
    id: "arp_roo_br",
    icao_code: "SBRD",
    iata_country_code: "BR",
    iata_code: "ROO",
    city_name: "Rondonópolis",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Rongelap Island Airport",
    longitude: 166.887023,
    latitude: 11.157242,
    id: "arp_rnp_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "RNP",
    city_name: "Rongelap Island",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Ronneby Airport",
    longitude: 15.266364,
    latitude: 56.255266,
    id: "arp_rnb_se",
    icao_code: "ESDF",
    iata_country_code: "SE",
    iata_code: "RNB",
    city_name: "Ronneby",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rooke Field",
    longitude: -97.321687,
    latitude: 28.2911,
    id: "arp_rfg_us",
    icao_code: "KRFG",
    iata_country_code: "US",
    iata_code: "RFG",
    city_name: "Refugio",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Roosevelt Municipal Airport",
    longitude: -110.049558,
    latitude: 40.278155,
    id: "arp_rol_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ROL",
    city_name: "Roosevelt",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Roper Bar Airport",
    longitude: 134.525485,
    latitude: -14.734814,
    id: "arp_rpb_au",
    icao_code: "YRRB",
    iata_country_code: "AU",
    iata_code: "RPB",
    city_name: "Roper Bar",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Roper Valley Airport",
    longitude: 134.049238,
    latitude: -14.920141,
    id: "arp_rpv_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "RPV",
    city_name: "Roper Valley",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Røros Airport",
    longitude: 11.347543,
    latitude: 62.577599,
    id: "arp_rrs_no",
    icao_code: "ENRO",
    iata_country_code: "NO",
    iata_code: "RRS",
    city_name: "Røros",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Rørvik Airport, Ryum",
    longitude: 11.145606,
    latitude: 64.838321,
    id: "arp_rvk_no",
    icao_code: "ENRM",
    iata_country_code: "NO",
    iata_code: "RVK",
    city_name: "Rørvik",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Rosario Islas Malvinas International Airport",
    longitude: -60.781156,
    latitude: -32.916702,
    id: "arp_ros_ar",
    icao_code: "SAAR",
    iata_country_code: "AR",
    iata_code: "ROS",
    city_name: "Rosario",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Rosario Seaplane Base",
    longitude: -122.86947,
    latitude: 48.645914,
    id: "arp_rsj_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RSJ",
    city_name: "Rosario",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Roscoe Turner Airport",
    longitude: -88.603557,
    latitude: 34.915,
    id: "arp_crx_us",
    icao_code: "KCRX",
    iata_country_code: "US",
    iata_code: "CRX",
    city_name: "Corinth",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Roscommon County–Blodgett Memorial Airport",
    longitude: -84.671264,
    latitude: 44.359881,
    id: "arp_htl_us",
    icao_code: "KHTL",
    iata_country_code: "US",
    iata_code: "HTL",
    city_name: "Houghton Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Roseau Municipal Airport",
    longitude: -95.697046,
    latitude: 48.855996,
    id: "arp_rox_us",
    icao_code: "KROX",
    iata_country_code: "US",
    iata_code: "ROX",
    city_name: "Roseau",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Rose Bay Water Airport",
    longitude: 151.258458,
    latitude: -33.869877,
    id: "arp_rse_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "RSE",
    city_name: "Sydney",
    city: {
      name: "Sydney",
      id: "cit_syd_au",
      iata_country_code: "AU",
      iata_code: "SYD",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Roseberth Airport",
    longitude: 139.628314,
    latitude: -25.826922,
    id: "arp_rsb_au",
    icao_code: "YRSB",
    iata_country_code: "AU",
    iata_code: "RSB",
    city_name: "Roseberth",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Roseburg Regional Airport",
    longitude: -123.357723,
    latitude: 43.236217,
    id: "arp_rbg_us",
    icao_code: "KRBG",
    iata_country_code: "US",
    iata_code: "RBG",
    city_name: "Roseburg",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Rosecrans Memorial Airport",
    longitude: -94.907229,
    latitude: 39.771468,
    id: "arp_stj_us",
    icao_code: "KSTJ",
    iata_country_code: "US",
    iata_code: "STJ",
    city_name: "St Joseph",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Rosella Plains Airport",
    longitude: 144.465498,
    latitude: -18.432328,
    id: "arp_rlp_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "RLP",
    city_name: "Rosella Plains",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Roshchino International Airport",
    longitude: 65.348455,
    latitude: 57.181383,
    id: "arp_tjm_ru",
    icao_code: "USTR",
    iata_country_code: "RU",
    iata_code: "TJM",
    city_name: "Tyumen",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Rosita Airport",
    longitude: -84.40967,
    latitude: 13.889305,
    id: "arp_rfs_ni",
    icao_code: "MNRT",
    iata_country_code: "NI",
    iata_code: "RFS",
    city_name: "Rosita",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Roskilde Airport",
    longitude: 12.130394,
    latitude: 55.587971,
    id: "arp_rke_dk",
    icao_code: "EKRK",
    iata_country_code: "DK",
    iata_code: "RKE",
    city_name: "Copenhagen",
    city: {
      name: "Copenhagen",
      id: "cit_cph_dk",
      iata_country_code: "DK",
      iata_code: "CPH",
    },
  },
  {
    time_zone: "America/Whitehorse",
    name: "Ross River Airport",
    longitude: -132.422608,
    latitude: 61.970495,
    id: "arp_xrr_ca",
    icao_code: "CYDM",
    iata_country_code: "CA",
    iata_code: "XRR",
    city_name: "Ross River",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Røst Airport",
    longitude: 12.10431,
    latitude: 67.527604,
    id: "arp_ret_no",
    icao_code: "ENRS",
    iata_country_code: "NO",
    iata_code: "RET",
    city_name: "Røst",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Rostock-Laage Airport",
    longitude: 12.280967,
    latitude: 53.916801,
    id: "arp_rlg_de",
    icao_code: "ETNL",
    iata_country_code: "DE",
    iata_code: "RLG",
    city_name: "Rostock",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Rostov-on-Don Airport",
    longitude: 39.80612,
    latitude: 47.252049,
    id: "arp_rov_ru",
    icao_code: "URRP",
    iata_country_code: "RU",
    iata_code: "ROV",
    city_name: "Rostov-on-Don",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Roswell International Air Center Airport",
    longitude: -104.526417,
    latitude: 33.302315,
    id: "arp_row_us",
    icao_code: "KROW",
    iata_country_code: "US",
    iata_code: "ROW",
    city_name: "Roswell",
    city: null,
  },
  {
    time_zone: "Pacific/Saipan",
    name: "Rota International Airport",
    longitude: 145.243379,
    latitude: 14.173154,
    id: "arp_rop_mp",
    icao_code: "PGRO",
    iata_country_code: "MP",
    iata_code: "ROP",
    city_name: "Rota",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Rota Naval Station Airport",
    longitude: -6.34946,
    latitude: 36.645199,
    id: "arp_roz_es",
    icao_code: "LERT",
    iata_country_code: "ES",
    iata_code: "ROZ",
    city_name: "Rota",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Roti Airport",
    longitude: 123.072611,
    latitude: -10.767408,
    id: "arp_rti_id",
    icao_code: "WATR",
    iata_country_code: "ID",
    iata_code: "RTI",
    city_name: "Roti",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Rotorua Regional Airport",
    longitude: 176.317159,
    latitude: -38.109359,
    id: "arp_rot_nz",
    icao_code: "NZRO",
    iata_country_code: "NZ",
    iata_code: "ROT",
    city_name: "Rotorua",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Rotterdam The Hague Airport",
    longitude: 4.438551,
    latitude: 51.956205,
    id: "arp_rtm_nl",
    icao_code: "EHRD",
    iata_country_code: "NL",
    iata_code: "RTM",
    city_name: "Rotterdam",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Rottnest Island Airport",
    longitude: 115.53889,
    latitude: -32.0067,
    id: "arp_rts_au",
    icao_code: "YRTI",
    iata_country_code: "AU",
    iata_code: "RTS",
    city_name: "Rottnest Island",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Rotuma Airport",
    longitude: 177.070999,
    latitude: -12.4825,
    id: "arp_rta_fj",
    icao_code: "NFNR",
    iata_country_code: "FJ",
    iata_code: "RTA",
    city_name: "Rotuma",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Rouen Airport",
    longitude: 1.186985,
    latitude: 49.389179,
    id: "arp_uro_fr",
    icao_code: "LFOP",
    iata_country_code: "FR",
    iata_code: "URO",
    city_name: "Rouen",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Round Lake",
    longitude: -91.312415,
    latitude: 52.943715,
    id: "arp_zrj_ca",
    icao_code: "CZRJ",
    iata_country_code: "CA",
    iata_code: "ZRJ",
    city_name: "Round Lake",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Roundup Airport",
    longitude: -108.543998,
    latitude: 46.474913,
    id: "arp_rpx_us",
    icao_code: "KRPX",
    iata_country_code: "US",
    iata_code: "RPX",
    city_name: "Roundup",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Rourkela Airport",
    longitude: 84.815339,
    latitude: 22.255726,
    id: "arp_rrk_in",
    icao_code: "VERK",
    iata_country_code: "IN",
    iata_code: "RRK",
    city_name: "Rourkela",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Rouses Point Seaplane Base",
    longitude: -73.363673,
    latitude: 44.991212,
    id: "arp_rsx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RSX",
    city_name: "Rouses Point",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Rouyn-Noranda Airport",
    longitude: -78.832431,
    latitude: 48.209003,
    id: "arp_yuy_ca",
    icao_code: "CYUY",
    iata_country_code: "CA",
    iata_code: "YUY",
    city_name: "Rouyn-Noranda",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Rovaniemi Airport",
    longitude: 25.830239,
    latitude: 66.56395,
    id: "arp_rvn_fi",
    icao_code: "EFRO",
    iata_country_code: "FI",
    iata_code: "RVN",
    city_name: "Rovaniemi",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Roxas Airport",
    longitude: 122.750541,
    latitude: 11.599447,
    id: "arp_rxs_ph",
    icao_code: "RPVR",
    iata_country_code: "PH",
    iata_code: "RXS",
    city_name: "Roxas City",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Akrotiri Airport",
    longitude: 32.9879,
    latitude: 34.590401,
    id: "arp_akt_cy",
    icao_code: "LCRA",
    iata_country_code: "CY",
    iata_code: "AKT",
    city_name: "Akrotiri",
    city: null,
  },
  {
    time_zone: "Atlantic/St_Helena",
    name: "Royal Air Forces Ascension Island Airport",
    longitude: -14.3958,
    latitude: -7.968622,
    id: "arp_asi_sh",
    icao_code: "FHAW",
    iata_country_code: "SH",
    iata_code: "ASI",
    city_name: "Ascension Island",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Benson Airport",
    longitude: -1.093209,
    latitude: 51.615267,
    id: "arp_bex_gb",
    icao_code: "EGUB",
    iata_country_code: "GB",
    iata_code: "BEX",
    city_name: "Benson",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Brize Norton Airport",
    longitude: -1.582105,
    latitude: 51.752311,
    id: "arp_bzz_gb",
    icao_code: "EGVN",
    iata_country_code: "GB",
    iata_code: "BZZ",
    city_name: "Brize Norton",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Coningsby Airport",
    longitude: -0.168992,
    latitude: 53.094689,
    id: "arp_qcy_gb",
    icao_code: "EGXC",
    iata_country_code: "GB",
    iata_code: "QCY",
    city_name: "Coningsby",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Cottesmore Airport",
    longitude: -0.654059,
    latitude: 52.729449,
    id: "arp_okh_gb",
    icao_code: "EGXJ",
    iata_country_code: "GB",
    iata_code: "OKH",
    city_name: "Cottesmore",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Fairford Airport",
    longitude: -1.786983,
    latitude: 51.683402,
    id: "arp_ffd_gb",
    icao_code: "EGVA",
    iata_country_code: "GB",
    iata_code: "FFD",
    city_name: "Fairford",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Honington Airport",
    longitude: 0.773989,
    latitude: 52.340056,
    id: "arp_beq_gb",
    icao_code: "EGXH",
    iata_country_code: "GB",
    iata_code: "BEQ",
    city_name: "Thetford",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Kinloss Airport",
    longitude: -3.56064,
    latitude: 57.649399,
    id: "arp_fss_gb",
    icao_code: "EGQK",
    iata_country_code: "GB",
    iata_code: "FSS",
    city_name: "Forres",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Lakenheath Airport",
    longitude: 0.558735,
    latitude: 52.408855,
    id: "arp_lkz_gb",
    icao_code: "EGUL",
    iata_country_code: "GB",
    iata_code: "LKZ",
    city_name: "Lakenheath",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Leuchars Airport",
    longitude: -2.864223,
    latitude: 56.373526,
    id: "arp_adx_gb",
    icao_code: "EGQL",
    iata_country_code: "GB",
    iata_code: "ADX",
    city_name: "St Andrews",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Lossiemouth Airport",
    longitude: -3.320073,
    latitude: 57.712476,
    id: "arp_lmo_gb",
    icao_code: "EGQS",
    iata_country_code: "GB",
    iata_code: "LMO",
    city_name: "Lossiemouth",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Lyneham Airport",
    longitude: -1.99343,
    latitude: 51.5051,
    id: "arp_lye_gb",
    icao_code: "EGDL",
    iata_country_code: "GB",
    iata_code: "LYE",
    city_name: "Lyneham",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Marham Airport",
    longitude: 0.545593,
    latitude: 52.646345,
    id: "arp_knf_gb",
    icao_code: "EGYM",
    iata_country_code: "GB",
    iata_code: "KNF",
    city_name: "Marham",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Mildenhall Airport",
    longitude: 0.486485,
    latitude: 52.361721,
    id: "arp_mhz_gb",
    icao_code: "EGUN",
    iata_country_code: "GB",
    iata_code: "MHZ",
    city_name: "Mildenhall",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Northolt Airport",
    longitude: -0.417395,
    latitude: 51.554141,
    id: "arp_nht_gb",
    icao_code: "EGWU",
    iata_country_code: "GB",
    iata_code: "NHT",
    city_name: "Northolt",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Odiham Airport",
    longitude: -0.942806,
    latitude: 51.235764,
    id: "arp_odh_gb",
    icao_code: "EGVO",
    iata_country_code: "GB",
    iata_code: "ODH",
    city_name: "Odiham",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Scampton Airport",
    longitude: -0.551018,
    latitude: 53.307237,
    id: "arp_sqz_gb",
    icao_code: "EGXP",
    iata_country_code: "GB",
    iata_code: "SQZ",
    city_name: "Scampton",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Royal Air Force Station Gütersloh Airport",
    longitude: 8.306664,
    latitude: 51.922565,
    id: "arp_gut_de",
    icao_code: "ETUO",
    iata_country_code: "DE",
    iata_code: "GUT",
    city_name: "Gütersloh",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Waddington Airport",
    longitude: -0.527418,
    latitude: 53.16939,
    id: "arp_wtn_gb",
    icao_code: "EGXW",
    iata_country_code: "GB",
    iata_code: "WTN",
    city_name: "Waddington",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Royal Air Forces Wyton Airport",
    longitude: -0.110552,
    latitude: 52.355902,
    id: "arp_quy_gb",
    icao_code: "EGUY",
    iata_country_code: "GB",
    iata_code: "QUY",
    city_name: "Wyton",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Royal Airstrip",
    longitude: 29.2967,
    latitude: -15.7267,
    id: "arp_ryl_zw",
    icao_code: null,
    iata_country_code: "ZW",
    iata_code: "RYL",
    city_name: "Lower Zambezi National Park",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Royal Australian Air Force Base Curtin Airport",
    longitude: 123.828003,
    latitude: -17.5814,
    id: "arp_dcn_au",
    icao_code: "YCIN",
    iata_country_code: "AU",
    iata_code: "DCN",
    city_name: "Derby",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Royal Australian Air Force Base Richmond Airport",
    longitude: 150.781006,
    latitude: -33.600601,
    id: "arp_xrh_au",
    icao_code: "YSRI",
    iata_country_code: "AU",
    iata_code: "XRH",
    city_name: "Richmond",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Royan-Médis Airport",
    longitude: -0.975447,
    latitude: 45.632768,
    id: "arp_ryn_fr",
    icao_code: "LFCY",
    iata_country_code: "FR",
    iata_code: "RYN",
    city_name: "Royan",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Roy Hill Station Airport",
    longitude: 119.958863,
    latitude: -22.625711,
    id: "arp_rhl_au",
    icao_code: "YRYH",
    iata_country_code: "AU",
    iata_code: "RHL",
    city_name: "Roy Hill Station",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Roy Hurd Memorial Airport",
    longitude: -102.909027,
    latitude: 31.582716,
    id: "arp_mif_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MIF",
    city_name: "Monahans",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Roy Otten Memorial Airfield",
    longitude: -92.874248,
    latitude: 38.428003,
    id: "arp_vrs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "VRS",
    city_name: "Versailles",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Rubelsanto Airport",
    longitude: -90.445929,
    latitude: 15.991989,
    id: "arp_ruv_gt",
    icao_code: "MGRB",
    iata_country_code: "GT",
    iata_code: "RUV",
    city_name: "Rubelsanto",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Rubem Berta International Airport",
    longitude: -57.036968,
    latitude: -29.783106,
    id: "arp_urg_br",
    icao_code: "SBUG",
    iata_country_code: "BR",
    iata_code: "URG",
    city_name: "Uruguaiana",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Ruben Cantu Airport",
    longitude: -80.945275,
    latitude: 8.085598,
    id: "arp_syp_pa",
    icao_code: "MPSA",
    iata_country_code: "PA",
    iata_code: "SYP",
    city_name: "Santiago",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ruby Airport",
    longitude: -155.469284,
    latitude: 64.727355,
    id: "arp_rby_us",
    icao_code: "PARY",
    iata_country_code: "US",
    iata_code: "RBY",
    city_name: "Ruby",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Rugao Air Base",
    longitude: 120.501655,
    latitude: 32.257884,
    id: "arp_rug_cn",
    icao_code: "ZSRG",
    iata_country_code: "CN",
    iata_code: "RUG",
    city_name: "Rugao",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Rügen Airport",
    longitude: 13.327607,
    latitude: 54.383626,
    id: "arp_gti_de",
    icao_code: "EDCG",
    iata_country_code: "DE",
    iata_code: "GTI",
    city_name: "Rügen",
    city: null,
  },
  {
    time_zone: "Africa/Kigali",
    name: "Ruhengeri Airport",
    longitude: 29.633166,
    latitude: -1.5,
    id: "arp_rhg_rw",
    icao_code: "HRYU",
    iata_country_code: "RW",
    iata_code: "RHG",
    city_name: "Ruhengeri",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Rukumkot Airport",
    longitude: 82.194424,
    latitude: 28.627159,
    id: "arp_ruk_np",
    icao_code: "VNRK",
    iata_country_code: "NP",
    iata_code: "RUK",
    city_name: "Rukumkot",
    city: null,
  },
  {
    time_zone: "Africa/Juba",
    name: "Rumbek Airport",
    longitude: 29.669062,
    latitude: 6.826101,
    id: "arp_rbx_ss",
    icao_code: "HSMK",
    iata_country_code: "SS",
    iata_code: "RBX",
    city_name: "Rumbek",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Rumginae Airport",
    longitude: 141.271745,
    latitude: -5.897432,
    id: "arp_rmn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RMN",
    city_name: "Rumginae",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Rumjatar Airport",
    longitude: 86.550477,
    latitude: 27.302984,
    id: "arp_rum_np",
    icao_code: "VNRT",
    iata_country_code: "NP",
    iata_code: "RUM",
    city_name: "Rumjatar",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Rundu Airport",
    longitude: 19.719938,
    latitude: -17.958208,
    id: "arp_ndu_na",
    icao_code: "FYRU",
    iata_country_code: "NA",
    iata_code: "NDU",
    city_name: "Rundu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ruoqiang Loulan Airport",
    longitude: 88.008148,
    latitude: 38.974695,
    id: "arp_rqa_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "RQA",
    city_name: "Ruoqiang",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Rupsi Airport",
    longitude: 89.907869,
    latitude: 26.141841,
    id: "arp_rup_in",
    icao_code: "VERU",
    iata_country_code: "IN",
    iata_code: "RUP",
    city_name: "Rupsi",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Rurrenabaque Airport",
    longitude: -67.497429,
    latitude: -14.4277,
    id: "arp_rbq_bo",
    icao_code: "SLRQ",
    iata_country_code: "BO",
    iata_code: "RBQ",
    city_name: "Rurrenabaque",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Rurutu Airport",
    longitude: -151.360669,
    latitude: -22.434003,
    id: "arp_rur_pf",
    icao_code: "NTAR",
    iata_country_code: "PF",
    iata_code: "RUR",
    city_name: "Rurutu",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Ruse Airport",
    longitude: 26.051009,
    latitude: 43.692762,
    id: "arp_rou_bg",
    icao_code: "LBRS",
    iata_country_code: "BG",
    iata_code: "ROU",
    city_name: "Ruse",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Russell Municipal Airport",
    longitude: -98.811289,
    latitude: 38.874489,
    id: "arp_rsl_us",
    icao_code: "KRSL",
    iata_country_code: "US",
    iata_code: "RSL",
    city_name: "Russell",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Russian Mission Airport",
    longitude: -161.325066,
    latitude: 61.780862,
    id: "arp_rsh_us",
    icao_code: "PARS",
    iata_country_code: "US",
    iata_code: "RSH",
    city_name: "Russian Mission",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Rustaq Airport",
    longitude: 57.493085,
    latitude: 23.636441,
    id: "arp_mnh_om",
    icao_code: "OORQ",
    iata_country_code: "OM",
    iata_code: "MNH",
    city_name: "Al Masna'ah",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Ruston Regional Airport",
    longitude: -92.590362,
    latitude: 32.512471,
    id: "arp_rsn_us",
    icao_code: "KRSN",
    iata_country_code: "US",
    iata_code: "RSN",
    city_name: "Ruston",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Ruti Airport",
    longitude: 144.256538,
    latitude: -5.335032,
    id: "arp_ruu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "RUU",
    city_name: "Kawbenaberi",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Rutland Plains Airport",
    longitude: 141.844831,
    latitude: -15.645013,
    id: "arp_rtp_au",
    icao_code: "YRTP",
    iata_country_code: "AU",
    iata_code: "RTP",
    city_name: "Rutland Plains",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Rutland–Southern Vermont Regional Airport",
    longitude: -72.950037,
    latitude: 43.528721,
    id: "arp_rut_us",
    icao_code: "KRUT",
    iata_country_code: "US",
    iata_code: "RUT",
    city_name: "Rutland",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Rzeszów-Jasionka Airport",
    longitude: 22.02361,
    latitude: 50.111666,
    id: "arp_rze_pl",
    icao_code: "EPRZ",
    iata_country_code: "PL",
    iata_code: "RZE",
    city_name: "Rzeszów",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Rzhevka Airport",
    longitude: 30.585339,
    latitude: 59.98029,
    id: "arp_rvh_ru",
    icao_code: "ULSS",
    iata_country_code: "RU",
    iata_code: "RVH",
    city_name: "St. Petersburg",
    city: {
      name: "St Petersburg",
      id: "cit_led_ru",
      iata_country_code: "RU",
      iata_code: "LED",
    },
  },
  {
    time_zone: "Europe/Berlin",
    name: "Saarbrücken Airport",
    longitude: 7.11088,
    latitude: 49.216391,
    id: "arp_scn_de",
    icao_code: "EDDR",
    iata_country_code: "DE",
    iata_code: "SCN",
    city_name: "Saarbrücken",
    city: {
      name: "Saarbruecken",
      id: "cit_scn_de",
      iata_country_code: "DE",
      iata_code: "SCN",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sabah Airport",
    longitude: 155.05,
    latitude: -5.58333,
    id: "arp_sbv_pg",
    icao_code: "AYSH",
    iata_country_code: "PG",
    iata_code: "SBV",
    city_name: "Sabah",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Sabetta International Airport",
    longitude: 72.053699,
    latitude: 71.218999,
    id: "arp_sbt_us",
    icao_code: "USDA",
    iata_country_code: "US",
    iata_code: "SBT",
    city_name: "Sabetta",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Sabha Airport",
    longitude: 14.464239,
    latitude: 26.998292,
    id: "arp_seb_ly",
    icao_code: "HLLS",
    iata_country_code: "LY",
    iata_code: "SEB",
    city_name: "Sabha",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Sabiha Gökçen International Airport",
    longitude: 29.31303,
    latitude: 40.901986,
    id: "arp_saw_tr",
    icao_code: "LTFJ",
    iata_country_code: "TR",
    iata_code: "SAW",
    city_name: "Istanbul",
    city: {
      name: "Istanbul",
      id: "cit_ist_tr",
      iata_country_code: "TR",
      iata_code: "IST",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Sabi Sabi Airport",
    longitude: 31.44476,
    latitude: -24.941801,
    id: "arp_gss_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "GSS",
    city_name: "Sabie",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Sable Island Airport",
    longitude: -59.999939,
    latitude: 43.925407,
    id: "arp_ysa_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YSA",
    city_name: "Sable Island",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Sabzevar Airport",
    longitude: 57.599262,
    latitude: 36.169738,
    id: "arp_afz_ir",
    icao_code: "OIMS",
    iata_country_code: "IR",
    iata_code: "AFZ",
    city_name: "Sabzevar",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Sacheon Airport",
    longitude: 128.08658,
    latitude: 35.092397,
    id: "arp_hin_kr",
    icao_code: "RKPS",
    iata_country_code: "KR",
    iata_code: "HIN",
    city_name: "Sacheon",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Sachigo Lake Airport",
    longitude: -92.196306,
    latitude: 53.891012,
    id: "arp_zpb_ca",
    icao_code: "CZPB",
    iata_country_code: "CA",
    iata_code: "ZPB",
    city_name: "Sachigo Lake",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Sachs Harbour",
    longitude: -125.243104,
    latitude: 71.993382,
    id: "arp_ysy_ca",
    icao_code: "CYSY",
    iata_country_code: "CA",
    iata_code: "YSY",
    city_name: "Sachs Harbour",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Sacramento Executive Airport",
    longitude: -121.494927,
    latitude: 38.512632,
    id: "arp_sac_us",
    icao_code: "KSAC",
    iata_country_code: "US",
    iata_code: "SAC",
    city_name: "Sacramento",
    city: {
      name: "Sacramento",
      id: "cit_sac_us",
      iata_country_code: "US",
      iata_code: "SAC",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Sacramento International Airport",
    longitude: -121.590534,
    latitude: 38.695242,
    id: "arp_smf_us",
    icao_code: "KSMF",
    iata_country_code: "US",
    iata_code: "SMF",
    city_name: "Sacramento",
    city: {
      name: "Sacramento",
      id: "cit_sac_us",
      iata_country_code: "US",
      iata_code: "SAC",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Sacramento Mather Airport",
    longitude: -121.296814,
    latitude: 38.562854,
    id: "arp_mhr_us",
    icao_code: "KMHR",
    iata_country_code: "US",
    iata_code: "MHR",
    city_name: "Sacramento",
    city: {
      name: "Sacramento",
      id: "cit_sac_us",
      iata_country_code: "US",
      iata_code: "SAC",
    },
  },
  {
    time_zone: "Asia/Aden",
    name: "Sadah Airport",
    longitude: 43.730801,
    latitude: 16.967029,
    id: "arp_sye_ye",
    icao_code: "OYSH",
    iata_country_code: "YE",
    iata_code: "SYE",
    city_name: "Sadah",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Sadiq Abubakar III International Airport",
    longitude: 5.207795,
    latitude: 12.917005,
    id: "arp_sko_ng",
    icao_code: "DNSO",
    iata_country_code: "NG",
    iata_code: "SKO",
    city_name: "Sokoto",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Sado Airport",
    longitude: 138.41267,
    latitude: 38.060925,
    id: "arp_sds_jp",
    icao_code: "RJSD",
    iata_country_code: "JP",
    iata_code: "SDS",
    city_name: "Sado",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Safford Regional Airport",
    longitude: -109.635406,
    latitude: 32.854424,
    id: "arp_sad_us",
    icao_code: "KSAD",
    iata_country_code: "US",
    iata_code: "SAD",
    city_name: "Safford",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Safia Airport",
    longitude: 148.636111,
    latitude: -9.593056,
    id: "arp_sfu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SFU",
    city_name: "Safia",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Saga Airport",
    longitude: 130.302165,
    latitude: 33.151012,
    id: "arp_hsg_jp",
    icao_code: "RJFS",
    iata_country_code: "JP",
    iata_code: "HSG",
    city_name: "Saga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sagarai Airport",
    longitude: 149.833,
    latitude: -10.3833,
    id: "arp_sgj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SGJ",
    city_name: "Sagarai",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Saginaw Seaplane Base",
    longitude: -134.15815,
    latitude: 56.886223,
    id: "arp_sgw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SGW",
    city_name: "Saginaw Bay",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sahabat Airport",
    longitude: 119.093786,
    latitude: 5.087243,
    id: "arp_sxs_my",
    icao_code: "WBKH",
    iata_country_code: "MY",
    iata_code: "SXS",
    city_name: "Sahabat",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Sahand Airport",
    longitude: 46.144591,
    latitude: 37.347915,
    id: "arp_acp_ir",
    icao_code: "OITM",
    iata_country_code: "IR",
    iata_code: "ACP",
    city_name: "Maragheh",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sahiwal Airport",
    longitude: 72.3917,
    latitude: 31.8894,
    id: "arp_swn_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "SWN",
    city_name: "Sahiwal",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Saibai Island Airport",
    longitude: 142.624489,
    latitude: -9.377934,
    id: "arp_sbr_au",
    icao_code: "YSII",
    iata_country_code: "AU",
    iata_code: "SBR",
    city_name: "Saibai Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Saidor Airport",
    longitude: 146.466818,
    latitude: -5.61856,
    id: "arp_sdi_pg",
    icao_code: "AYSD",
    iata_country_code: "PG",
    iata_code: "SDI",
    city_name: "Saidor",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Saidpur Airport",
    longitude: 88.909206,
    latitude: 25.760007,
    id: "arp_spd_bd",
    icao_code: "VGSD",
    iata_country_code: "BD",
    iata_code: "SPD",
    city_name: "Saidpur",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Saidu Sharif Airport",
    longitude: 72.352092,
    latitude: 34.813465,
    id: "arp_sdt_pk",
    icao_code: "OPSS",
    iata_country_code: "PK",
    iata_code: "SDT",
    city_name: "Saidu Sharif",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Saih Rawl Airport",
    longitude: 48.74668,
    latitude: 31.34292,
    id: "arp_ahw_om",
    icao_code: null,
    iata_country_code: "OM",
    iata_code: "AHW",
    city_name: "Saih Rawl",
    city: null,
  },
  {
    time_zone: "America/Blanc-Sablon",
    name: "Saint-Augustin Airport",
    longitude: -58.658268,
    latitude: 51.211687,
    id: "arp_yif_ca",
    icao_code: "CYIF",
    iata_country_code: "CA",
    iata_code: "YIF",
    city_name: "St-Augustin",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Saint-Brieuc – Armor Airport",
    longitude: -2.854622,
    latitude: 48.536264,
    id: "arp_sbk_fr",
    icao_code: "LFRT",
    iata_country_code: "FR",
    iata_code: "SBK",
    city_name: "St-Brieuc",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Sainte Marie Airport",
    longitude: 49.816746,
    latitude: -17.089814,
    id: "arp_sms_mg",
    icao_code: "FMMS",
    iata_country_code: "MG",
    iata_code: "SMS",
    city_name: "Sainte Marie",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Saint-Étienne-Bouthéon Airport",
    longitude: 4.295949,
    latitude: 45.538599,
    id: "arp_ebu_fr",
    icao_code: "LFMH",
    iata_country_code: "FR",
    iata_code: "EBU",
    city_name: "Saint-Étienne",
    city: null,
  },
  {
    time_zone: "America/Cayenne",
    name: "Saint-Georges-de-l'Oyapock Airport",
    longitude: -51.803654,
    latitude: 3.898742,
    id: "arp_oyp_gf",
    icao_code: "SOOG",
    iata_country_code: "GF",
    iata_code: "OYP",
    city_name: "St-Georges",
    city: null,
  },
  {
    time_zone: "Atlantic/St_Helena",
    name: "Saint Helena Airport",
    longitude: -5.646946,
    latitude: -15.959473,
    id: "arp_hle_sh",
    icao_code: "FHSH",
    iata_country_code: "SH",
    iata_code: "HLE",
    city_name: "Jamestown",
    city: null,
  },
  {
    time_zone: "America/Moncton",
    name: "Saint John Airport",
    longitude: -65.890333,
    latitude: 45.328846,
    id: "arp_ysj_ca",
    icao_code: "CYSJ",
    iata_country_code: "CA",
    iata_code: "YSJ",
    city_name: "Saint John",
    city: null,
  },
  {
    time_zone: "America/Cayenne",
    name: "Saint-Laurent-du-Maroni Airport",
    longitude: -54.033374,
    latitude: 5.483328,
    id: "arp_ldx_gf",
    icao_code: "SOOM",
    iata_country_code: "GF",
    iata_code: "LDX",
    city_name: "Saint-Laurent-du-Maroni",
    city: null,
  },
  {
    time_zone: "America/Moncton",
    name: "Saint-Léonard Airport",
    longitude: -67.834702,
    latitude: 47.157501,
    id: "arp_ysl_ca",
    icao_code: "CYSL",
    iata_country_code: "CA",
    iata_code: "YSL",
    city_name: "St Leonard",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Saint Louis Airport",
    longitude: -16.462016,
    latitude: 16.050261,
    id: "arp_xls_sn",
    icao_code: "GOSS",
    iata_country_code: "SN",
    iata_code: "XLS",
    city_name: "St-Louis",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Saint-Nazaire-Montoir Airport",
    longitude: -2.157295,
    latitude: 47.311833,
    id: "arp_snr_fr",
    icao_code: "LFRZ",
    iata_country_code: "FR",
    iata_code: "SNR",
    city_name: "St-Nazaire",
    city: null,
  },
  {
    time_zone: "America/Miquelon",
    name: "Saint-Pierre Airport",
    longitude: -56.174678,
    latitude: 46.763686,
    id: "arp_fsp_pm",
    icao_code: "LFVP",
    iata_country_code: "PM",
    iata_code: "FSP",
    city_name: "Saint-Pierre",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Saint-Yan Airport",
    longitude: 4.013562,
    latitude: 46.412683,
    id: "arp_syt_fr",
    icao_code: "LFLN",
    iata_country_code: "FR",
    iata_code: "SYT",
    city_name: "Saint-Yan",
    city: null,
  },
  {
    time_zone: "Pacific/Saipan",
    name: "Saipan International Airport",
    longitude: 145.72864,
    latitude: 15.119371,
    id: "arp_spn_mp",
    icao_code: "PGSN",
    iata_country_code: "MP",
    iata_code: "SPN",
    city_name: "Saipan",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Sakkyryr Airport",
    longitude: 130.393523,
    latitude: 67.792111,
    id: "arp_suk_ru",
    icao_code: "UEBS",
    iata_country_code: "RU",
    iata_code: "SUK",
    city_name: "Batagay-Alyta",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Sakon Nakhon Airport",
    longitude: 104.117466,
    latitude: 17.196124,
    id: "arp_sno_th",
    icao_code: "VTUI",
    iata_country_code: "TH",
    iata_code: "SNO",
    city_name: "Sakon Nakhon",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Salak Airport",
    longitude: 14.256586,
    latitude: 10.452435,
    id: "arp_mvr_cm",
    icao_code: "FKKL",
    iata_country_code: "CM",
    iata_code: "MVR",
    city_name: "Maroua",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Salalah Airport",
    longitude: 54.090076,
    latitude: 17.046069,
    id: "arp_sll_om",
    icao_code: "OOSA",
    iata_country_code: "OM",
    iata_code: "SLL",
    city_name: "Salalah",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Salamanca Airport",
    longitude: -5.500505,
    latitude: 40.951237,
    id: "arp_slm_es",
    icao_code: "LESA",
    iata_country_code: "ES",
    iata_code: "SLM",
    city_name: "Salamanca",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Salamo Airport",
    longitude: 150.791445,
    latitude: -9.671819,
    id: "arp_sam_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SAM",
    city_name: "Salamo",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Salekhard Airport",
    longitude: 66.587572,
    latitude: 66.590601,
    id: "arp_sly_ru",
    icao_code: "USDD",
    iata_country_code: "RU",
    iata_code: "SLY",
    city_name: "Salekhard",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Salem Airport",
    longitude: 78.064165,
    latitude: 11.782659,
    id: "arp_sxv_in",
    icao_code: "VOSM",
    iata_country_code: "IN",
    iata_code: "SXV",
    city_name: "Salem",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Salem-Leckrone Airport",
    longitude: -88.964463,
    latitude: 38.643366,
    id: "arp_slo_us",
    icao_code: "KSLO",
    iata_country_code: "US",
    iata_code: "SLO",
    city_name: "Salem",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Salerno Costa d'Amalfi Airport",
    longitude: 14.915944,
    latitude: 40.620346,
    id: "arp_qsr_it",
    icao_code: "LIRI",
    iata_country_code: "IT",
    iata_code: "QSR",
    city_name: "Salerno",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Salerno Landing Zone Airport",
    longitude: 69.956015,
    latitude: 33.363415,
    id: "arp_olr_af",
    icao_code: "OASL",
    iata_country_code: "AF",
    iata_code: "OLR",
    city_name: "Salerno",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Salgado Filho International Airport",
    longitude: -51.173389,
    latitude: -29.993936,
    id: "arp_poa_br",
    icao_code: "SBPA",
    iata_country_code: "BR",
    iata_code: "POA",
    city_name: "Porto Alegre",
    city: null,
  },
  {
    time_zone: "Africa/Blantyre",
    name: "Salima Airport",
    longitude: 34.585206,
    latitude: -13.759233,
    id: "arp_lmb_mw",
    icao_code: "FWSM",
    iata_country_code: "MW",
    iata_code: "LMB",
    city_name: "Salima",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Salina Cruz Airport",
    longitude: -95.201866,
    latitude: 16.210153,
    id: "arp_scx_mx",
    icao_code: "MMSZ",
    iata_country_code: "MX",
    iata_code: "SCX",
    city_name: "Salina Cruz",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Salina-Gunnison Airport",
    longitude: -111.83817,
    latitude: 39.029119,
    id: "arp_sbo_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SBO",
    city_name: "Salina",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Salina Regional Airport",
    longitude: -97.639774,
    latitude: 38.778846,
    id: "arp_sln_us",
    icao_code: "KSLN",
    iata_country_code: "US",
    iata_code: "SLN",
    city_name: "Salina",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Salinas Municipal Airport",
    longitude: -121.608063,
    latitude: 36.66329,
    id: "arp_sns_us",
    icao_code: "KSNS",
    iata_country_code: "US",
    iata_code: "SNS",
    city_name: "Salinas",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Salisbury-Ocean City-Wicomico Regional Airport",
    longitude: -75.510288,
    latitude: 38.340388,
    id: "arp_sby_us",
    icao_code: "KSBY",
    iata_country_code: "US",
    iata_code: "SBY",
    city_name: "Salisbury",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Salluit Airport",
    longitude: -75.66786,
    latitude: 62.180152,
    id: "arp_yzg_ca",
    icao_code: "CYZG",
    iata_country_code: "CA",
    iata_code: "YZG",
    city_name: "Salluit",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Salmon Arm Airport",
    longitude: -119.230836,
    latitude: 50.683655,
    id: "arp_ysn_ca",
    icao_code: "CZAM",
    iata_country_code: "CA",
    iata_code: "YSN",
    city_name: "Salmon Arm",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "Salt Cay Airport",
    longitude: -71.2005,
    latitude: 21.332991,
    id: "arp_slx_tc",
    icao_code: "MBSY",
    iata_country_code: "TC",
    iata_code: "SLX",
    city_name: "Salt Cay",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Salt Lake City International Airport",
    longitude: -111.978533,
    latitude: 40.789169,
    id: "arp_slc_us",
    icao_code: "KSLC",
    iata_country_code: "US",
    iata_code: "SLC",
    city_name: "Salt Lake City",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Salton Sea Airport",
    longitude: -115.950874,
    latitude: 33.241779,
    id: "arp_sas_us",
    icao_code: "KSAS",
    iata_country_code: "US",
    iata_code: "SAS",
    city_name: "Salton City",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Salvador-Deputado Luís Eduardo Magalhães International Airport",
    longitude: -38.32687,
    latitude: -12.909855,
    id: "arp_ssa_br",
    icao_code: "SBSV",
    iata_country_code: "BR",
    iata_code: "SSA",
    city_name: "Salvador",
    city: null,
  },
  {
    time_zone: "Europe/Vienna",
    name: "Salzburg Airport",
    longitude: 13.003613,
    latitude: 47.792973,
    id: "arp_szg_at",
    icao_code: "LOWS",
    iata_country_code: "AT",
    iata_code: "SZG",
    city_name: "Salzburg",
    city: null,
  },
  {
    time_zone: "America/Santo_Domingo",
    name: "Samaná El Catey International Airport",
    longitude: -69.730408,
    latitude: 19.267646,
    id: "arp_azs_do",
    icao_code: "MDCY",
    iata_country_code: "DO",
    iata_code: "AZS",
    city_name: "Samana",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Samangoky Airport",
    longitude: 43.733002,
    latitude: -21.700001,
    id: "arp_tdv_mg",
    icao_code: "FMSN",
    iata_country_code: "MG",
    iata_code: "TDV",
    city_name: "Tanandava",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Samarinda Temindung Airport",
    longitude: 117.157054,
    latitude: -0.484541,
    id: "arp_sri_id",
    icao_code: "WALS",
    iata_country_code: "ID",
    iata_code: "SRI",
    city_name: "Samarinda",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Samarkand International Airport",
    longitude: 66.987392,
    latitude: 39.698446,
    id: "arp_skd_uz",
    icao_code: "UTSS",
    iata_country_code: "UZ",
    iata_code: "SKD",
    city_name: "Samarkand",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Sambailo Airport",
    longitude: -13.358293,
    latitude: 12.572846,
    id: "arp_sbi_gn",
    icao_code: "GUSB",
    iata_country_code: "GN",
    iata_code: "SBI",
    city_name: "Koundara",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Sambava Airport",
    longitude: 50.174952,
    latitude: -14.279123,
    id: "arp_svb_mg",
    icao_code: "FMNS",
    iata_country_code: "MG",
    iata_code: "SVB",
    city_name: "Sambava",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Sambu Airport",
    longitude: -78.199997,
    latitude: 8.017,
    id: "arp_sax_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "SAX",
    city_name: "Boca de Sábalo",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Samburu Airport",
    longitude: 37.534195,
    latitude: 0.530583,
    id: "arp_uas_ke",
    icao_code: "HKSB",
    iata_country_code: "KE",
    iata_code: "UAS",
    city_name: "Samburu",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Samedan Airport",
    longitude: 9.882913,
    latitude: 46.533836,
    id: "arp_smv_ch",
    icao_code: "LSZS",
    iata_country_code: "CH",
    iata_code: "SMV",
    city_name: "St Moritz",
    city: null,
  },
  {
    time_zone: "Asia/Pyongyang",
    name: "Samjiyon Airport",
    longitude: 128.409862,
    latitude: 41.90715,
    id: "arp_yjs_kp",
    icao_code: "ZKSE",
    iata_country_code: "KP",
    iata_code: "YJS",
    city_name: "Samjiyon",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Sam Mbakwe International Airport",
    longitude: 7.204987,
    latitude: 5.427157,
    id: "arp_qow_ng",
    icao_code: "DNIM",
    iata_country_code: "NG",
    iata_code: "QOW",
    city_name: "Owerri",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Sam Neua Airport",
    longitude: 104.067156,
    latitude: 20.41864,
    id: "arp_neu_la",
    icao_code: "VLSN",
    iata_country_code: "LA",
    iata_code: "NEU",
    city_name: "Sam Neua",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Samos International Airport",
    longitude: 26.912409,
    latitude: 37.689801,
    id: "arp_smi_gr",
    icao_code: "LGSM",
    iata_country_code: "GR",
    iata_code: "SMI",
    city_name: "Samos",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Sampit Airport",
    longitude: 112.975571,
    latitude: -2.500365,
    id: "arp_smq_id",
    icao_code: "WAOS",
    iata_country_code: "ID",
    iata_code: "SMQ",
    city_name: "Sampit",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sampson County Airport",
    longitude: -78.365114,
    latitude: 34.975255,
    id: "arp_ctz_us",
    icao_code: "KCTZ",
    iata_country_code: "US",
    iata_code: "CTZ",
    city_name: "Clinton",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sam Ratulangi International Airport",
    longitude: 124.925045,
    latitude: 1.547764,
    id: "arp_mdc_id",
    icao_code: "WAMM",
    iata_country_code: "ID",
    iata_code: "MDC",
    city_name: "Manado",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Samsun-Çarşamba Airport",
    longitude: 36.561687,
    latitude: 41.256487,
    id: "arp_szf_tr",
    icao_code: "LTFH",
    iata_country_code: "TR",
    iata_code: "SZF",
    city_name: "Samsun",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Samuels Field",
    longitude: -85.499904,
    latitude: 37.812165,
    id: "arp_bry_us",
    icao_code: "KBRY",
    iata_country_code: "US",
    iata_code: "BRY",
    city_name: "Bardstown",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Samui Airport",
    longitude: 100.062512,
    latitude: 9.548113,
    id: "arp_usm_th",
    icao_code: "VTSM",
    iata_country_code: "TH",
    iata_code: "USM",
    city_name: "Ko Samui",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Sana'a International Airport",
    longitude: 44.218935,
    latitude: 15.477824,
    id: "arp_sah_ye",
    icao_code: "OYSN",
    iata_country_code: "YE",
    iata_code: "SAH",
    city_name: "Sana'a",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Sanandaj Airport",
    longitude: 47.010664,
    latitude: 35.247819,
    id: "arp_sdg_ir",
    icao_code: "OICS",
    iata_country_code: "IR",
    iata_code: "SDG",
    city_name: "Sanandaj",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "San Andros Airport",
    longitude: -78.04703,
    latitude: 25.053287,
    id: "arp_saq_bs",
    icao_code: "MYAN",
    iata_country_code: "BS",
    iata_code: "SAQ",
    city_name: "San Andros",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "San Angelo Regional Airport",
    longitude: -100.499524,
    latitude: 31.357383,
    id: "arp_sjt_us",
    icao_code: "KSJT",
    iata_country_code: "US",
    iata_code: "SJT",
    city_name: "San Angelo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "San Antonio International Airport",
    longitude: -98.469075,
    latitude: 29.532449,
    id: "arp_sat_us",
    icao_code: "KSAT",
    iata_country_code: "US",
    iata_code: "SAT",
    city_name: "San Antonio",
    city: {
      name: "San Antonio",
      id: "cit_sat_us",
      iata_country_code: "US",
      iata_code: "SAT",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Bernardino International Airport",
    longitude: -117.235472,
    latitude: 34.095029,
    id: "arp_sbd_us",
    icao_code: "KSBD",
    iata_country_code: "US",
    iata_code: "SBD",
    city_name: "San Bernardino",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "San Blas Airport",
    longitude: -78.97906,
    latitude: 9.452712,
    id: "arp_nbl_pa",
    icao_code: "MPWN",
    iata_country_code: "PA",
    iata_code: "NBL",
    city_name: "San Blas",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "San Carlos Airport",
    longitude: -84.769558,
    latitude: 11.13411,
    id: "arp_ncr_ni",
    icao_code: "MNSC",
    iata_country_code: "NI",
    iata_code: "NCR",
    city_name: "San Carlos",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Carlos Airport",
    longitude: -122.250213,
    latitude: 37.513208,
    id: "arp_sql_us",
    icao_code: "KSQL",
    iata_country_code: "US",
    iata_code: "SQL",
    city_name: "San Carlos",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "San Carlos Apache Airport",
    longitude: -110.662901,
    latitude: 33.353694,
    id: "arp_glb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "GLB",
    city_name: "Globe",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "San Carlos de Bariloche Airport",
    longitude: -71.159083,
    latitude: -41.149536,
    id: "arp_brc_ar",
    icao_code: "SAZS",
    iata_country_code: "AR",
    iata_code: "BRC",
    city_name: "San Carlos de Bariloche",
    city: null,
  },
  {
    time_zone: "Pacific/Galapagos",
    name: "San Cristóbal Airport",
    longitude: -89.616818,
    latitude: -0.909453,
    id: "arp_scy_ec",
    icao_code: "SEST",
    iata_country_code: "EC",
    iata_code: "SCY",
    city_name: "San Cristóbal",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Sancti Spiritus Airport",
    longitude: -79.44274,
    latitude: 21.9702,
    id: "arp_uss_cu",
    icao_code: "MUSS",
    iata_country_code: "CU",
    iata_code: "USS",
    city_name: "Sancti Spiritus",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sandakan Airport",
    longitude: 118.060349,
    latitude: 5.899051,
    id: "arp_sdk_my",
    icao_code: "WBKS",
    iata_country_code: "MY",
    iata_code: "SDK",
    city_name: "Sandakan",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Sandane Airport",
    longitude: 6.106848,
    latitude: 61.829747,
    id: "arp_sdn_no",
    icao_code: "ENSD",
    iata_country_code: "NO",
    iata_code: "SDN",
    city_name: "Sandane",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Sanday Airport",
    longitude: -2.576927,
    latitude: 59.250306,
    id: "arp_ndy_gb",
    icao_code: "EGES",
    iata_country_code: "GB",
    iata_code: "NDY",
    city_name: "Sanday",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Sand Creek Airport",
    longitude: -59.516569,
    latitude: 2.999585,
    id: "arp_sdc_gy",
    icao_code: null,
    iata_country_code: "GY",
    iata_code: "SDC",
    city_name: "Sand Creek",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Sandefjord Airport, Torp",
    longitude: 10.257756,
    latitude: 59.184571,
    id: "arp_trf_no",
    icao_code: "ENTO",
    iata_country_code: "NO",
    iata_code: "TRF",
    city_name: "Oslo",
    city: {
      name: "Oslo",
      id: "cit_osl_no",
      iata_country_code: "NO",
      iata_code: "OSL",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Sanderson Field",
    longitude: -123.146922,
    latitude: 47.235407,
    id: "arp_shn_us",
    icao_code: "KSHN",
    iata_country_code: "US",
    iata_code: "SHN",
    city_name: "Shelton",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Diego International Airport",
    longitude: -117.191653,
    latitude: 32.7337,
    id: "arp_san_us",
    icao_code: "KSAN",
    iata_country_code: "US",
    iata_code: "SAN",
    city_name: "San Diego",
    city: {
      name: "San Diego",
      id: "cit_san_us",
      iata_country_code: "US",
      iata_code: "SAN",
    },
  },
  {
    time_zone: "Europe/Oslo",
    name: "Sandnessjøen Airport",
    longitude: 12.470422,
    latitude: 65.958123,
    id: "arp_ssj_no",
    icao_code: "ENST",
    iata_country_code: "NO",
    iata_code: "SSJ",
    city_name: "Sandnessjøen",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Sand Point Airport",
    longitude: -160.522921,
    latitude: 55.31644,
    id: "arp_sdp_us",
    icao_code: "PASD",
    iata_country_code: "US",
    iata_code: "SDP",
    city_name: "Sand Point",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Sandringham Airport",
    longitude: 139.06657,
    latitude: -24.050196,
    id: "arp_srm_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "SRM",
    city_name: "Sandringham Station",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Sandspit Airport",
    longitude: -131.815603,
    latitude: 53.253722,
    id: "arp_yzp_ca",
    icao_code: "CYZP",
    iata_country_code: "CA",
    iata_code: "YZP",
    city_name: "Sandspit",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Sandstone Airport",
    longitude: 119.298128,
    latitude: -27.980029,
    id: "arp_nds_au",
    icao_code: "YSAN",
    iata_country_code: "AU",
    iata_code: "NDS",
    city_name: "Sandstone",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Sandy Lake Airport",
    longitude: -93.344417,
    latitude: 53.064183,
    id: "arp_zsj_ca",
    icao_code: "CZSJ",
    iata_country_code: "CA",
    iata_code: "ZSJ",
    city_name: "Sandy Lake",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "San Esteban Airport",
    longitude: -85.8,
    latitude: 15.1667,
    id: "arp_set_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "SET",
    city_name: "Serra Talhada",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Sanfebagar Airport",
    longitude: 81.215268,
    latitude: 29.2367,
    id: "arp_feb_np",
    icao_code: "VNSR",
    iata_country_code: "NP",
    iata_code: "FEB",
    city_name: "Sanfebagar",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "San Felipe International Airport",
    longitude: -114.808855,
    latitude: 30.931041,
    id: "arp_sfh_mx",
    icao_code: "MMSF",
    iata_country_code: "MX",
    iata_code: "SFH",
    city_name: "San Felipe",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "San Fernando Airport",
    longitude: 120.303099,
    latitude: 16.595223,
    id: "arp_sfe_ph",
    icao_code: "RPUS",
    iata_country_code: "PH",
    iata_code: "SFE",
    city_name: "San Fernando",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sanford Seacoast Regional Airport",
    longitude: -70.709566,
    latitude: 43.398731,
    id: "arp_sfm_us",
    icao_code: "KSFM",
    iata_country_code: "US",
    iata_code: "SFM",
    city_name: "Sanford",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Francisco International Airport",
    longitude: -122.376977,
    latitude: 37.620156,
    id: "arp_sfo_us",
    icao_code: "KSFO",
    iata_country_code: "US",
    iata_code: "SFO",
    city_name: "San Francisco",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Gabriel Valley Airport",
    longitude: -118.034008,
    latitude: 34.085473,
    id: "arp_emt_us",
    icao_code: "KEMT",
    iata_country_code: "US",
    iata_code: "EMT",
    city_name: "El Monte",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sangapi Airport",
    longitude: 144.323333,
    latitude: -5.125417,
    id: "arp_sgk_pg",
    icao_code: "AYSK",
    iata_country_code: "PG",
    iata_code: "SGK",
    city_name: "Sangapi",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Sanga-Sanga Airport",
    longitude: 119.74299,
    latitude: 5.046416,
    id: "arp_twt_ph",
    icao_code: "RPMN",
    iata_country_code: "PH",
    iata_code: "TWT",
    city_name: "Bongao",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sanggata Airport",
    longitude: 117.543,
    latitude: 0.3847,
    id: "arp_sgq_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "SGQ",
    city_name: "Sangata",
    city: null,
  },
  {
    time_zone: "America/Jamaica",
    name: "Sangster International Airport",
    longitude: -77.913916,
    latitude: 18.502981,
    id: "arp_mbj_jm",
    icao_code: "MKJS",
    iata_country_code: "JM",
    iata_code: "MBJ",
    city_name: "Montego Bay",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Sania Ramel Airport",
    longitude: -5.323269,
    latitude: 35.593619,
    id: "arp_ttu_ma",
    icao_code: "GMTN",
    iata_country_code: "MA",
    iata_code: "TTU",
    city_name: "Tétouan",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "San Ignacio Airfield",
    longitude: -112.938071,
    latitude: 27.296442,
    id: "arp_sgm_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "SGM",
    city_name: "San Ignacio",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "San Ignacio de Moxos Airport",
    longitude: -65.633487,
    latitude: -14.966377,
    id: "arp_snm_bo",
    icao_code: "SLSM",
    iata_country_code: "BO",
    iata_code: "SNM",
    city_name: "San Ignacio de Moxos",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "San Ignacio Town Airstrip",
    longitude: -89.101603,
    latitude: 17.104753,
    id: "arp_cyd_gt",
    icao_code: null,
    iata_country_code: "GT",
    iata_code: "CYD",
    city_name: "San Ignacio",
    city: null,
  },
  {
    time_zone: "America/Iqaluit",
    name: "Sanikiluaq Airport",
    longitude: -79.246513,
    latitude: 56.537195,
    id: "arp_ysk_ca",
    icao_code: "CYSK",
    iata_country_code: "CA",
    iata_code: "YSK",
    city_name: "Sanikiluaq",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "San Isidro de El General Airport",
    longitude: -83.7125,
    latitude: 9.348611,
    id: "arp_ipz_cr",
    icao_code: "MRSI",
    iata_country_code: "CR",
    iata_code: "IPZ",
    city_name: "San Isidro de El Gener",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "San Javier Airport",
    longitude: -0.814213,
    latitude: 37.776068,
    id: "arp_mjv_es",
    icao_code: "LELC",
    iata_country_code: "ES",
    iata_code: "MJV",
    city_name: "Murcia",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "San Javier Airport",
    longitude: -62.470299,
    latitude: -16.2708,
    id: "arp_sjv_bo",
    icao_code: "SLJV",
    iata_country_code: "BO",
    iata_code: "SJV",
    city_name: "San Javier",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "San Joaquín Airport",
    longitude: -64.662008,
    latitude: -13.052874,
    id: "arp_sjb_bo",
    icao_code: "SLJO",
    iata_country_code: "BO",
    iata_code: "SJB",
    city_name: "San Joaquín",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "San Jose Airport",
    longitude: 121.046274,
    latitude: 12.360828,
    id: "arp_sji_ph",
    icao_code: "RPUH",
    iata_country_code: "PH",
    iata_code: "SJI",
    city_name: "San Jose",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "San José Airport",
    longitude: -90.835956,
    latitude: 13.935739,
    id: "arp_gsj_gt",
    icao_code: "MGSJ",
    iata_country_code: "GT",
    iata_code: "GSJ",
    city_name: "Puerto San José",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "San José de Chiquitos Airport",
    longitude: -60.743525,
    latitude: -17.829942,
    id: "arp_sjs_bo",
    icao_code: "SLJE",
    iata_country_code: "BO",
    iata_code: "SJS",
    city_name: "San José de Chiquitos",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San José del Guaviare Airport",
    longitude: -72.639902,
    latitude: 2.575643,
    id: "arp_sje_co",
    icao_code: "SKSJ",
    iata_country_code: "CO",
    iata_code: "SJE",
    city_name: "San José del Guaviare",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Jose International Airport",
    longitude: -121.928969,
    latitude: 37.363273,
    id: "arp_sjc_us",
    icao_code: "KSJC",
    iata_country_code: "US",
    iata_code: "SJC",
    city_name: "San Jose",
    city: {
      name: "San Jose",
      id: "cit_sjc_us",
      iata_country_code: "US",
      iata_code: "SJC",
    },
  },
  {
    time_zone: "America/Panama",
    name: "San José Island Airport",
    longitude: -79.078271,
    latitude: 8.262955,
    id: "arp_sic_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "SIC",
    city_name: "San José Island",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "San Juan de Marcona Airport",
    longitude: -75.13684,
    latitude: -15.353686,
    id: "arp_sja_pe",
    icao_code: "SPJN",
    iata_country_code: "PE",
    iata_code: "SJA",
    city_name: "San Juan de Marcona",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San Juan De Uraba Airport",
    longitude: -76.533333,
    latitude: 8.766667,
    id: "arp_sjr_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SJR",
    city_name: "San Juan de Uraba",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "San Julián Air Base",
    longitude: -84.152433,
    latitude: 22.096428,
    id: "arp_snj_cu",
    icao_code: "MUSJ",
    iata_country_code: "CU",
    iata_code: "SNJ",
    city_name: "San Julian",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Sankt Peter-Ording Airport",
    longitude: 8.684251,
    latitude: 54.308731,
    id: "arp_psh_de",
    icao_code: "EDXO",
    iata_country_code: "DE",
    iata_code: "PSH",
    city_name: "Sankt Peter-Ording",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Şanlıurfa Airport",
    longitude: 38.846988,
    latitude: 37.09435,
    id: "arp_sfq_tr",
    icao_code: "LTCH",
    iata_country_code: "TR",
    iata_code: "SFQ",
    city_name: "Sanliurfa",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Şanlıurfa GAP Airport",
    longitude: 38.898675,
    latitude: 37.445082,
    id: "arp_gny_tr",
    icao_code: "LTCS",
    iata_country_code: "TR",
    iata_code: "GNY",
    city_name: "Şanlıurfa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San Luis Airport",
    longitude: -77.6737,
    latitude: 0.860253,
    id: "arp_ipi_co",
    icao_code: "SKIP",
    iata_country_code: "CO",
    iata_code: "IPI",
    city_name: "Ipiales",
    city: null,
  },
  {
    time_zone: "America/Argentina/San_Luis",
    name: "San Luis Airport",
    longitude: -66.354513,
    latitude: -33.273813,
    id: "arp_luq_ar",
    icao_code: "SAOU",
    iata_country_code: "AR",
    iata_code: "LUQ",
    city_name: "San Luis",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San Luis de Palenque Airport",
    longitude: -71.728761,
    latitude: 5.412169,
    id: "arp_sqe_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SQE",
    city_name: "San Luis de Palenque",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "San Luis Obispo County Regional Airport",
    longitude: -120.641297,
    latitude: 35.237159,
    id: "arp_sbp_us",
    icao_code: "KSBP",
    iata_country_code: "US",
    iata_code: "SBP",
    city_name: "San Luis Obispo",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "San Luis Potosí International Airport",
    longitude: -100.932575,
    latitude: 22.255593,
    id: "arp_slp_mx",
    icao_code: "MMSP",
    iata_country_code: "MX",
    iata_code: "SLP",
    city_name: "San Luis Potosí",
    city: null,
  },
  {
    time_zone: "America/Hermosillo",
    name: "San Luis Río Colorado Airport",
    longitude: -114.798549,
    latitude: 32.444824,
    id: "arp_uac_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "UAC",
    city_name: "San Luis Río Colorado",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "San Luis Valley Regional Airport",
    longitude: -105.865054,
    latitude: 37.43873,
    id: "arp_als_us",
    icao_code: "KALS",
    iata_country_code: "US",
    iata_code: "ALS",
    city_name: "Alamosa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San Marcos Airport",
    longitude: -75.160098,
    latitude: 8.692346,
    id: "arp_srs_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SRS",
    city_name: "San Marcos",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "San Matías Airport",
    longitude: -58.401781,
    latitude: -16.339344,
    id: "arp_mqk_bo",
    icao_code: "SLTI",
    iata_country_code: "BO",
    iata_code: "MQK",
    city_name: "San Matías",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "San Miguel Airport",
    longitude: -78.934679,
    latitude: 8.455569,
    id: "arp_nmg_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "NMG",
    city_name: "San Miguel",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Sanming Shaxian Airport",
    longitude: 117.832889,
    latitude: 26.423721,
    id: "arp_sqj_cn",
    icao_code: "ZSSM",
    iata_country_code: "CN",
    iata_code: "SQJ",
    city_name: "Sanming",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "San Nicolas Airport",
    longitude: -77.505316,
    latitude: -6.390888,
    id: "arp_rim_pe",
    icao_code: "SPLN",
    iata_country_code: "PE",
    iata_code: "RIM",
    city_name: "Rodriguez de Mendoza",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "San Pedro Airport",
    longitude: -84.624689,
    latitude: 14.035539,
    id: "arp_bza_ni",
    icao_code: "MNBZ",
    iata_country_code: "NI",
    iata_code: "BZA",
    city_name: "Bonanza",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "San Pedro Airport",
    longitude: -87.969101,
    latitude: 17.915042,
    id: "arp_spr_bz",
    icao_code: "MZSP",
    iata_country_code: "BZ",
    iata_code: "SPR",
    city_name: "San Pedro",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "San Pedro Airport",
    longitude: -6.660458,
    latitude: 4.746766,
    id: "arp_spy_ci",
    icao_code: "DISP",
    iata_country_code: "CI",
    iata_code: "SPY",
    city_name: "San Pedro",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San Pedro de Jagua Airport",
    longitude: -73.333333,
    latitude: 4.65,
    id: "arp_sjg_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SJG",
    city_name: "SJG",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "San Pedro de Uraba Airport",
    longitude: -76.7275,
    latitude: 4.672222,
    id: "arp_npu_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "NPU",
    city_name: "San Pedro de Urabá",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "San Quintín Military Airstrip",
    longitude: -115.946408,
    latitude: 30.528482,
    id: "arp_snq_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "SNQ",
    city_name: "San Quintin",
    city: null,
  },
  {
    time_zone: "America/Argentina/Mendoza",
    name: "San Rafael Airport",
    longitude: -68.40379,
    latitude: -34.587732,
    id: "arp_afa_ar",
    icao_code: "SAMR",
    iata_country_code: "AR",
    iata_code: "AFA",
    city_name: "San Rafael",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "San Rafael Airport",
    longitude: -70.645101,
    latitude: -32.813057,
    id: "arp_lob_cl",
    icao_code: "SCAN",
    iata_country_code: "CL",
    iata_code: "LOB",
    city_name: "Los Andes",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "San Ramón Airport",
    longitude: -64.604146,
    latitude: -13.263848,
    id: "arp_srd_bo",
    icao_code: "SLRA",
    iata_country_code: "BO",
    iata_code: "SRD",
    city_name: "San Ramon",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "San Salvador Airport",
    longitude: -74.526619,
    latitude: 24.063576,
    id: "arp_zsa_bs",
    icao_code: "MYSM",
    iata_country_code: "BS",
    iata_code: "ZSA",
    city_name: "San Salvador",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "San Salvador de Paul Airport",
    longitude: -62.0,
    latitude: 7.0,
    id: "arp_svv_ve",
    icao_code: null,
    iata_country_code: "VE",
    iata_code: "SVV",
    city_name: "San Salvador de Paul",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "San Sebastián Airport",
    longitude: -1.790859,
    latitude: 43.356545,
    id: "arp_eas_es",
    icao_code: "LESO",
    iata_country_code: "ES",
    iata_code: "EAS",
    city_name: "San Sebastián",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Sans Souci Airport",
    longitude: -80.0,
    latitude: 45.2667,
    id: "arp_ysi_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YSI",
    city_name: "Frying Pan Island",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santa Ana Airport",
    longitude: -75.954714,
    latitude: 4.759601,
    id: "arp_crc_co",
    icao_code: "SKGO",
    iata_country_code: "CO",
    iata_code: "CRC",
    city_name: "Cartago",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Santa Ana Airport",
    longitude: 162.454108,
    latitude: -10.847994,
    id: "arp_nnb_sb",
    icao_code: "AGGT",
    iata_country_code: "SB",
    iata_code: "NNB",
    city_name: "Santa Ana Island",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santa Ana Airport",
    longitude: -75.956233,
    latitude: 4.751369,
    id: "arp_sqb_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SQB",
    city_name: "Piedras",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Santa Ana del Yacuma Airport",
    longitude: -65.43431,
    latitude: -13.762997,
    id: "arp_sbl_bo",
    icao_code: "SLSA",
    iata_country_code: "BO",
    iata_code: "SBL",
    city_name: "Santa Ana del Yacuma",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Santa Bárbara de Barinas Airport",
    longitude: -71.165718,
    latitude: 7.803514,
    id: "arp_sbb_ve",
    icao_code: "SVSB",
    iata_country_code: "VE",
    iata_code: "SBB",
    city_name: "Santa Bárbara",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Barbara Municipal Airport",
    longitude: -119.839939,
    latitude: 34.426697,
    id: "arp_sba_us",
    icao_code: "KSBA",
    iata_country_code: "US",
    iata_code: "SBA",
    city_name: "Santa Barbara",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Santa Bernardina International Airport",
    longitude: -56.499183,
    latitude: -33.358949,
    id: "arp_dzo_uy",
    icao_code: "SUDU",
    iata_country_code: "UY",
    iata_code: "DZO",
    city_name: "Durazno",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Santa Carolina Airport",
    longitude: 35.3417,
    latitude: 21.6183,
    id: "arp_ntc_mz",
    icao_code: null,
    iata_country_code: "MZ",
    iata_code: "NTC",
    city_name: "Santa Carolina",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santa Catalina Airport",
    longitude: -118.415769,
    latitude: 33.404997,
    id: "arp_sca_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SCA",
    city_name: "Santa Catalina",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santa Cruz Air Force Base",
    longitude: -43.719101,
    latitude: -22.9324,
    id: "arp_snz_br",
    icao_code: "SBSC",
    iata_country_code: "BR",
    iata_code: "SNZ",
    city_name: "Rio de Janeiro",
    city: {
      name: "Rio de Janeiro",
      id: "cit_rio_br",
      iata_country_code: "BR",
      iata_code: "RIO",
    },
  },
  {
    time_zone: "America/Argentina/Rio_Gallegos",
    name: "Santa Cruz Airport",
    longitude: -68.580014,
    latitude: -50.016922,
    id: "arp_rza_ar",
    icao_code: "SAWU",
    iata_country_code: "AR",
    iata_code: "RZA",
    city_name: "Santa Cruz",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santa Cruz do Sul Airport",
    longitude: -52.411461,
    latitude: -29.684052,
    id: "arp_csu_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "CSU",
    city_name: "Santa Cruz do Sul",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Santa Cruz/Graciosa Bay/Luova Airport",
    longitude: 165.794998,
    latitude: -10.7203,
    id: "arp_scz_sb",
    icao_code: "AGGL",
    iata_country_code: "SB",
    iata_code: "SCZ",
    city_name: "Santa Cruz Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Cruz Island Airport",
    longitude: -119.915065,
    latitude: 34.060579,
    id: "arp_szn_us",
    icao_code: "KSZN",
    iata_country_code: "US",
    iata_code: "SZN",
    city_name: "Santa Cruz Island",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Santa Elena de Uairen Airport",
    longitude: -61.140828,
    latitude: 4.554241,
    id: "arp_snv_ve",
    icao_code: "SVSE",
    iata_country_code: "VE",
    iata_code: "SNV",
    city_name: "Santa Elena de Uairen",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santa Fe Do Sul Airport",
    longitude: -50.967357,
    latitude: -20.22607,
    id: "arp_sfv_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "SFV",
    city_name: "Santa Fe do Sul",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Santa Fe Municipal Airport",
    longitude: -106.086381,
    latitude: 35.617464,
    id: "arp_saf_us",
    icao_code: "KSAF",
    iata_country_code: "US",
    iata_code: "SAF",
    city_name: "Santa Fe",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santa Genoveva Airport",
    longitude: -49.219965,
    latitude: -16.632197,
    id: "arp_gyn_br",
    icao_code: "SBGO",
    iata_country_code: "BR",
    iata_code: "GYN",
    city_name: "Goiânia",
    city: null,
  },
  {
    time_zone: "America/Araguaina",
    name: "Santa Isabel do Morro Airport",
    longitude: -50.667614,
    latitude: -11.572332,
    id: "arp_ido_br",
    icao_code: "SWIY",
    iata_country_code: "BR",
    iata_code: "IDO",
    city_name: "Santa Isabel do Morro",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Santa Lucía Airport",
    longitude: -99.01683,
    latitude: 19.754122,
    id: "arp_nlu_mx",
    icao_code: "MMSM",
    iata_country_code: "MX",
    iata_code: "NLU",
    city_name: "Mexico City",
    city: {
      name: "Mexico City",
      id: "cit_mex_mx",
      iata_country_code: "MX",
      iata_code: "MEX",
    },
  },
  {
    time_zone: "America/Maceio",
    name: "Santa Maria Airport",
    longitude: -37.071073,
    latitude: -10.984996,
    id: "arp_aju_br",
    icao_code: "SBAR",
    iata_country_code: "BR",
    iata_code: "AJU",
    city_name: "Aracaju",
    city: null,
  },
  {
    time_zone: "America/Maceio",
    name: "Santa Maria Airport",
    longitude: -37.073049,
    latitude: -10.985608,
    id: "arp_ria_br",
    icao_code: "SBSM",
    iata_country_code: "BR",
    iata_code: "RIA",
    city_name: "Santa Maria",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "Santa Maria Airport",
    longitude: -25.170287,
    latitude: 36.97064,
    id: "arp_sma_pt",
    icao_code: "LPAZ",
    iata_country_code: "PT",
    iata_code: "SMA",
    city_name: "Santa Maria",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santa Maria Airport",
    longitude: -77.0558,
    latitude: 8.17218,
    id: "arp_smc_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SMC",
    city_name: "Santa Maria",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Maria Airport",
    longitude: -120.453228,
    latitude: 34.899732,
    id: "arp_smg_pe",
    icao_code: "SPMR",
    iata_country_code: "PE",
    iata_code: "SMG",
    city_name: "Santa Maria",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Maria Public Airport",
    longitude: -120.452386,
    latitude: 34.898974,
    id: "arp_smx_us",
    icao_code: "KSMX",
    iata_country_code: "US",
    iata_code: "SMX",
    city_name: "Santa Maria",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Monica Municipal Airport",
    longitude: -118.450834,
    latitude: 34.016464,
    id: "arp_smo_us",
    icao_code: "KSMO",
    iata_country_code: "US",
    iata_code: "SMO",
    city_name: "Santa Monica",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Santana do Araguaia Airport",
    longitude: -50.327556,
    latitude: -9.322553,
    id: "arp_cmp_br",
    icao_code: "SNKE",
    iata_country_code: "BR",
    iata_code: "CMP",
    city_name: "Santana do Araguaia",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santana do Livramento Airport",
    longitude: -55.625371,
    latitude: -30.836062,
    id: "arp_lvb_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "LVB",
    city_name: "Santana do Livramento",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santana Ramos Airport",
    longitude: -75.25,
    latitude: 2.21667,
    id: "arp_sro_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SRO",
    city_name: "Santana Ramos",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Santander Airport",
    longitude: -3.821894,
    latitude: 43.426581,
    id: "arp_sdr_es",
    icao_code: "LEXJ",
    iata_country_code: "ES",
    iata_code: "SDR",
    city_name: "Santander",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Paula Airport",
    longitude: -119.061214,
    latitude: 34.347286,
    id: "arp_szp_us",
    icao_code: "KSZP",
    iata_country_code: "US",
    iata_code: "SZP",
    city_name: "Santa Paula",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Santarem Maestro Wilson Fonseca Airport",
    longitude: -54.785968,
    latitude: -2.424758,
    id: "arp_stm_br",
    icao_code: "SBSN",
    iata_country_code: "BR",
    iata_code: "STM",
    city_name: "Santarém",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Santa Rosa Airport",
    longitude: -64.27584,
    latitude: -36.58827,
    id: "arp_rsa_ar",
    icao_code: "SAZR",
    iata_country_code: "AR",
    iata_code: "RSA",
    city_name: "Santa Rosa",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santa Rosa Airport",
    longitude: -54.520401,
    latitude: -27.9067,
    id: "arp_sra_br",
    icao_code: "SSZR",
    iata_country_code: "BR",
    iata_code: "SRA",
    city_name: "Santa Rosa",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Santa Rosa Airport",
    longitude: -66.787811,
    latitude: -14.072722,
    id: "arp_srb_bo",
    icao_code: "SLSR",
    iata_country_code: "BO",
    iata_code: "SRB",
    city_name: "Santa Rosa",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Santa Rosa de Copán Airport",
    longitude: -88.775128,
    latitude: 14.777267,
    id: "arp_sdh_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "SDH",
    city_name: "Santa Rosa de Copán",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Santa Rosa International Airport",
    longitude: -79.996515,
    latitude: -3.443036,
    id: "arp_etr_ec",
    icao_code: "SERO",
    iata_country_code: "EC",
    iata_code: "ETR",
    city_name: "Santa Rosa",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santa Rosalia Airport",
    longitude: -70.868162,
    latitude: 5.130777,
    id: "arp_ssl_co",
    icao_code: "SKSL",
    iata_country_code: "CO",
    iata_code: "SSL",
    city_name: "Santa Rosalia",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Santa Teresita Airport",
    longitude: -56.721303,
    latitude: -36.542323,
    id: "arp_sst_ar",
    icao_code: "SAZL",
    iata_country_code: "AR",
    iata_code: "SST",
    city_name: "Santa Teresita",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santa Terezinha Airport",
    longitude: -51.554775,
    latitude: -27.170772,
    id: "arp_jcb_br",
    icao_code: "SSJA",
    iata_country_code: "BR",
    iata_code: "JCB",
    city_name: "Joaçaba",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Santa Terezinha Airport",
    longitude: -50.518611,
    latitude: -10.464721,
    id: "arp_stz_br",
    icao_code: "SWST",
    iata_country_code: "BR",
    iata_code: "STZ",
    city_name: "Santa Terezinha",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Santa Ynez Airport",
    longitude: -120.075756,
    latitude: 34.606881,
    id: "arp_sqa_us",
    icao_code: "KIZA",
    iata_country_code: "US",
    iata_code: "SQA",
    city_name: "Santa Ynez",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Santiago de Compostela Airport",
    longitude: -8.415492,
    latitude: 42.896439,
    id: "arp_scq_es",
    icao_code: "LEST",
    iata_country_code: "ES",
    iata_code: "SCQ",
    city_name: "Santiago de Compostela",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Santiago Vila Airport",
    longitude: -74.797386,
    latitude: 4.276564,
    id: "arp_gir_co",
    icao_code: "SKGI",
    iata_country_code: "CO",
    iata_code: "GIR",
    city_name: "Girardot",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "San Tomé Airport",
    longitude: -64.148843,
    latitude: 8.945477,
    id: "arp_som_ve",
    icao_code: "SVST",
    iata_country_code: "VE",
    iata_code: "SOM",
    city_name: "San Tomé",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Santo-Pekoa International Airport",
    longitude: 167.219893,
    latitude: -15.505137,
    id: "arp_son_vu",
    icao_code: "NVSS",
    iata_country_code: "VU",
    iata_code: "SON",
    city_name: "Luganville",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Santorini National Airport",
    longitude: 117.685956,
    latitude: 30.740292,
    id: "arp_juh_cn",
    icao_code: "ZSJH",
    iata_country_code: "CN",
    iata_code: "JUH",
    city_name: "Chizhou",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Santorini (Thira) International Airport",
    longitude: 25.478474,
    latitude: 36.39976,
    id: "arp_jtr_gr",
    icao_code: "LGSR",
    iata_country_code: "GR",
    iata_code: "JTR",
    city_name: "Thira",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Santos Dumont Airport",
    longitude: -43.163988,
    latitude: -22.910822,
    id: "arp_sdu_br",
    icao_code: "SBRJ",
    iata_country_code: "BR",
    iata_code: "SDU",
    city_name: "Rio de Janeiro",
    city: {
      name: "Rio de Janeiro",
      id: "cit_rio_br",
      iata_country_code: "BR",
      iata_code: "RIO",
    },
  },
  {
    time_zone: "Asia/Manila",
    name: "San Vicente Airport",
    longitude: 119.273863,
    latitude: 10.523204,
    id: "arp_swl_ph",
    icao_code: "RPSV",
    iata_country_code: "PH",
    iata_code: "SWL",
    city_name: "San Vicente",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "San Vito De Java Airport",
    longitude: -82.9589,
    latitude: 8.82611,
    id: "arp_too_cr",
    icao_code: "MRSV",
    iata_country_code: "CR",
    iata_code: "TOO",
    city_name: "San Vito",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Sanya Phoenix International Airport",
    longitude: 109.412332,
    latitude: 18.3033,
    id: "arp_syx_cn",
    icao_code: "ZJSY",
    iata_country_code: "CN",
    iata_code: "SYX",
    city_name: "Sanya",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Carlos International Airport",
    longitude: -47.904734,
    latitude: -21.87612,
    id: "arp_qsc_br",
    icao_code: "SDSC",
    iata_country_code: "BR",
    iata_code: "QSC",
    city_name: "São Carlos",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "São Félix do Araguaia Airport",
    longitude: -50.6878,
    latitude: -11.632411,
    id: "arp_sxo_br",
    icao_code: "SWFX",
    iata_country_code: "BR",
    iata_code: "SXO",
    city_name: "Sao Felix do Araguaia",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "São Félix do Xingu Airport",
    longitude: -51.9523,
    latitude: -6.6413,
    id: "arp_sxx_br",
    icao_code: "SNFX",
    iata_country_code: "BR",
    iata_code: "SXX",
    city_name: "Sao Felix do Xingu",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "São Filipe Airport",
    longitude: -24.482269,
    latitude: 14.886749,
    id: "arp_sfl_cv",
    icao_code: "GVSF",
    iata_country_code: "CV",
    iata_code: "SFL",
    city_name: "São Filipe",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "São Gabriel da Cachoeira Airport",
    longitude: -66.988125,
    latitude: -0.149707,
    id: "arp_sjl_br",
    icao_code: "SBUA",
    iata_country_code: "BR",
    iata_code: "SJL",
    city_name: "Sao Gabriel Cachoeira",
    city: null,
  },
  {
    time_zone: "Atlantic/Azores",
    name: "São Jorge Airport",
    longitude: -28.175733,
    latitude: 38.66556,
    id: "arp_sjz_pt",
    icao_code: "LPSJ",
    iata_country_code: "PT",
    iata_code: "SJZ",
    city_name: "Velas",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Sao Jose Do Rio Preto Airport",
    longitude: -49.40565,
    latitude: -20.81438,
    id: "arp_sjp_br",
    icao_code: "SBSR",
    iata_country_code: "BR",
    iata_code: "SJP",
    city_name: "São José do Rio Preto",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São José dos Campos Airport",
    longitude: -45.861525,
    latitude: -23.227902,
    id: "arp_sjk_br",
    icao_code: "SBSJ",
    iata_country_code: "BR",
    iata_code: "SJK",
    city_name: "Sao Jose Dos Campos",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Lourenço Airport",
    longitude: -45.044553,
    latitude: -22.090949,
    id: "arp_sso_br",
    icao_code: "SNLO",
    iata_country_code: "BR",
    iata_code: "SSO",
    city_name: "São Lourenço",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Lourenço do Sul Airport",
    longitude: -52.033271,
    latitude: -31.383658,
    id: "arp_sqy_br",
    icao_code: "SSRU",
    iata_country_code: "BR",
    iata_code: "SQY",
    city_name: "São Lourenço Do Sul",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Mateus Airport",
    longitude: -39.835045,
    latitude: -18.721672,
    id: "arp_sbj_br",
    icao_code: "SNMX",
    iata_country_code: "BR",
    iata_code: "SBJ",
    city_name: "São Mateus",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Miguel do Araguaia Airport",
    longitude: -50.197827,
    latitude: -13.331166,
    id: "arp_sqm_br",
    icao_code: "SWUA",
    iata_country_code: "BR",
    iata_code: "SQM",
    city_name: "Sao Miguel do Araguaia",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Miguel do Oeste Airport",
    longitude: -53.504195,
    latitude: -26.780472,
    id: "arp_sqx_br",
    icao_code: "SSOE",
    iata_country_code: "BR",
    iata_code: "SQX",
    city_name: "Sao Miguel do Oeste",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Paulo – Congonhas Airport",
    longitude: -46.656486,
    latitude: -23.626717,
    id: "arp_cgh_br",
    icao_code: "SBSP",
    iata_country_code: "BR",
    iata_code: "CGH",
    city_name: "São Paulo",
    city: {
      name: "Sao Paulo",
      id: "cit_sao_br",
      iata_country_code: "BR",
      iata_code: "SAO",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "São Paulo – Guarulhos International Airport",
    longitude: -46.474321,
    latitude: -23.43406,
    id: "arp_gru_br",
    icao_code: "SBGR",
    iata_country_code: "BR",
    iata_code: "GRU",
    city_name: "São Paulo",
    city: {
      name: "Sao Paulo",
      id: "cit_sao_br",
      iata_country_code: "BR",
      iata_code: "SAO",
    },
  },
  {
    time_zone: "Africa/Sao_Tome",
    name: "São Tomé International Airport",
    longitude: 6.712491,
    latitude: 0.377913,
    id: "arp_tms_st",
    icao_code: "FPST",
    iata_country_code: "ST",
    iata_code: "TMS",
    city_name: "São Tomé",
    city: null,
  },
  {
    time_zone: "Atlantic/Cape_Verde",
    name: "Sao Vicente Cesaria Evora Airport",
    longitude: -25.054871,
    latitude: 16.834014,
    id: "arp_vxe_cv",
    icao_code: "GVSV",
    iata_country_code: "CV",
    iata_code: "VXE",
    city_name: "São Vicente",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sapmanga Airport",
    longitude: 146.799238,
    latitude: -6.067693,
    id: "arp_smh_pg",
    icao_code: "AYSP",
    iata_country_code: "PG",
    iata_code: "SMH",
    city_name: "Sapmanga",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Saposoa Airport",
    longitude: -76.769326,
    latitude: -6.956849,
    id: "arp_squ_pe",
    icao_code: "SPOA",
    iata_country_code: "PE",
    iata_code: "SQU",
    city_name: "Saposoa",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Saqani Airport",
    longitude: 179.35,
    latitude: -16.75,
    id: "arp_aqs_fj",
    icao_code: null,
    iata_country_code: "FJ",
    iata_code: "AQS",
    city_name: "Saqani",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Sara Airport",
    longitude: 168.152157,
    latitude: -15.470818,
    id: "arp_ssr_vu",
    icao_code: "NVSH",
    iata_country_code: "VU",
    iata_code: "SSR",
    city_name: "Sara",
    city: null,
  },
  {
    time_zone: "Europe/Sarajevo",
    name: "Sarajevo International Airport",
    longitude: 18.334105,
    latitude: 43.825342,
    id: "arp_sjj_ba",
    icao_code: "LQSA",
    iata_country_code: "BA",
    iata_code: "SJJ",
    city_name: "Sarajevo",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Sarakhs Airport",
    longitude: 61.067089,
    latitude: 36.498248,
    id: "arp_ckt_ir",
    icao_code: "OIMC",
    iata_country_code: "IR",
    iata_code: "CKT",
    city_name: "Sarakhs",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Saransk Airport",
    longitude: 45.219288,
    latitude: 54.135275,
    id: "arp_skx_ru",
    icao_code: "UWPS",
    iata_country_code: "RU",
    iata_code: "SKX",
    city_name: "Saransk",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sarasota–Bradenton International Airport",
    longitude: -82.554251,
    latitude: 27.39534,
    id: "arp_srq_us",
    icao_code: "KSRQ",
    iata_country_code: "US",
    iata_code: "SRQ",
    city_name: "Sarasota",
    city: null,
  },
  {
    time_zone: "Europe/Saratov",
    name: "Saratov Gagarin Airport",
    longitude: 46.168868,
    latitude: 51.717058,
    id: "arp_gsv_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "GSV",
    city_name: "Saratov",
    city: null,
  },
  {
    time_zone: "Europe/Saratov",
    name: "Saratov Tsentralny Airport",
    longitude: 46.034065,
    latitude: 51.559409,
    id: "arp_rtw_ru",
    icao_code: "UWSS",
    iata_country_code: "RU",
    iata_code: "RTW",
    city_name: "Saratov",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Saravane Airport",
    longitude: 106.411044,
    latitude: 15.709303,
    id: "arp_vna_la",
    icao_code: "VLSV",
    iata_country_code: "LA",
    iata_code: "VNA",
    city_name: "Saravane",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Sardar Vallabhbhai Patel International Airport",
    longitude: 72.630633,
    latitude: 23.075313,
    id: "arp_amd_in",
    icao_code: "VAAH",
    iata_country_code: "IN",
    iata_code: "AMD",
    city_name: "Ahmedabad",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Sardeh Band Airport",
    longitude: 68.63622,
    latitude: 33.320273,
    id: "arp_sbf_af",
    icao_code: "OADS",
    iata_country_code: "AF",
    iata_code: "SBF",
    city_name: "Sardeh Band",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Sarh Airport",
    longitude: 18.374399,
    latitude: 9.14444,
    id: "arp_srh_td",
    icao_code: "FTTA",
    iata_country_code: "TD",
    iata_code: "SRH",
    city_name: "Sarh",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Sarmi Orai Airport",
    longitude: 138.753718,
    latitude: -1.873462,
    id: "arp_zrm_id",
    icao_code: "WAJI",
    iata_country_code: "ID",
    iata_code: "ZRM",
    city_name: "Sarmi",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Sarnia Chris Hadfield Airport",
    longitude: -82.308239,
    latitude: 42.997867,
    id: "arp_yzr_ca",
    icao_code: "CYZR",
    iata_country_code: "CA",
    iata_code: "YZR",
    city_name: "Sarnia",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Sarteneja Airport",
    longitude: -88.129216,
    latitude: 18.355646,
    id: "arp_sjx_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "SJX",
    city_name: "Sarteneja",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Sary-Arka Airport",
    longitude: 73.33442,
    latitude: 49.670816,
    id: "arp_kgf_kz",
    icao_code: "UAKK",
    iata_country_code: "KZ",
    iata_code: "KGF",
    city_name: "Karaganda",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Sary Su Airport",
    longitude: 70.191017,
    latitude: 48.397065,
    id: "arp_hrc_kz",
    icao_code: null,
    iata_country_code: "KZ",
    iata_code: "HRC",
    city_name: "Zhayrem",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sasereme Airport",
    longitude: 142.868,
    latitude: -7.6217,
    id: "arp_tds_pg",
    icao_code: "AYSS",
    iata_country_code: "PG",
    iata_code: "TDS",
    city_name: "Sasereme",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Saskatoon John G. Diefenbaker International Airport",
    longitude: -106.700239,
    latitude: 52.172694,
    id: "arp_yxe_ca",
    icao_code: "CYXE",
    iata_country_code: "CA",
    iata_code: "YXE",
    city_name: "Saskatoon",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Saskylakh Airport",
    longitude: 114.080001,
    latitude: 71.927902,
    id: "arp_sys_ru",
    icao_code: "UERS",
    iata_country_code: "RU",
    iata_code: "SYS",
    city_name: "Saskylakh",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Sassandra Airport",
    longitude: -6.131352,
    latitude: 4.933209,
    id: "arp_zss_ci",
    icao_code: "DISS",
    iata_country_code: "CI",
    iata_code: "ZSS",
    city_name: "Sassandra",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Sasstown Airport",
    longitude: -8.436562,
    latitude: 4.680222,
    id: "arp_saz_lr",
    icao_code: "GLST",
    iata_country_code: "LR",
    iata_code: "SAZ",
    city_name: "Sasstown",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Satna Airport",
    longitude: 80.850958,
    latitude: 24.563254,
    id: "arp_tni_in",
    icao_code: "VIST",
    iata_country_code: "IN",
    iata_code: "TNI",
    city_name: "Satna",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Satu Mare International Airport",
    longitude: 22.883766,
    latitude: 47.704799,
    id: "arp_suj_ro",
    icao_code: "LRSM",
    iata_country_code: "RO",
    iata_code: "SUJ",
    city_name: "Satu Mare",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Satwag Airport",
    longitude: 147.278212,
    latitude: -6.139518,
    id: "arp_swg_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SWG",
    city_name: "Satwag",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Sauce Viejo Airport",
    longitude: -60.809335,
    latitude: -31.710391,
    id: "arp_sfn_ar",
    icao_code: "SAAV",
    iata_country_code: "AR",
    iata_code: "SFN",
    city_name: "Santa Fe",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Sauðárkrókur Airport",
    longitude: -19.573145,
    latitude: 65.731775,
    id: "arp_sak_is",
    icao_code: "BIKR",
    iata_country_code: "IS",
    iata_code: "SAK",
    city_name: "Sauðárkrókur",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Saufley Field Airport",
    longitude: -87.338097,
    latitude: 30.4694,
    id: "arp_nun_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NUN",
    city_name: "Pensacola",
    city: {
      name: "Pensacola",
      id: "cit_pns_us",
      iata_country_code: "US",
      iata_code: "PNS",
    },
  },
  {
    time_zone: "America/Cayenne",
    name: "Saül Airport",
    longitude: -53.203323,
    latitude: 3.615923,
    id: "arp_xau_gf",
    icao_code: "SOOS",
    iata_country_code: "GF",
    iata_code: "XAU",
    city_name: "Saul",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Sault Ste. Marie Airport",
    longitude: -84.505909,
    latitude: 46.482846,
    id: "arp_ssm_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "SSM",
    city_name: "Sault Ste Marie",
    city: {
      name: "Sault Ste Marie",
      id: "cit_ssm_us",
      iata_country_code: "US",
      iata_code: "SSM",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Sault Ste. Marie Airport",
    longitude: -84.50766,
    latitude: 46.484226,
    id: "arp_yam_ca",
    icao_code: "CYAM",
    iata_country_code: "CA",
    iata_code: "YAM",
    city_name: "Sault Ste. Marie",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Saumlaki Olilit Airport",
    longitude: 131.305615,
    latitude: -7.988378,
    id: "arp_sxk_id",
    icao_code: "WAPI",
    iata_country_code: "ID",
    iata_code: "SXK",
    city_name: "Saumlaki",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sauren Airport",
    longitude: 148.853688,
    latitude: -5.970249,
    id: "arp_sxw_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SXW",
    city_name: "Sauren",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Saurimo Airport",
    longitude: 20.431661,
    latitude: -9.689389,
    id: "arp_vhc_ao",
    icao_code: "FNSA",
    iata_country_code: "AO",
    iata_code: "VHC",
    city_name: "Saurimo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Savannah/Hilton Head International Airport",
    longitude: -81.201977,
    latitude: 32.128514,
    id: "arp_sav_us",
    icao_code: "KSAV",
    iata_country_code: "US",
    iata_code: "SAV",
    city_name: "Savannah",
    city: {
      name: "Savannah",
      id: "cit_sav_us",
      iata_country_code: "US",
      iata_code: "SAV",
    },
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Savannakhet Airport",
    longitude: 104.761535,
    latitude: 16.556694,
    id: "arp_zvk_la",
    icao_code: "VLSK",
    iata_country_code: "LA",
    iata_code: "ZVK",
    city_name: "Savannakhet",
    city: null,
  },
  {
    time_zone: "Africa/Porto-Novo",
    name: "Savé Airport",
    longitude: 2.464378,
    latitude: 8.017981,
    id: "arp_svf_bj",
    icao_code: "DBBS",
    iata_country_code: "BJ",
    iata_code: "SVF",
    city_name: "Savé",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Savo Airport",
    longitude: 159.833333,
    latitude: -9.166667,
    id: "arp_svy_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "SVY",
    city_name: "Savo Island",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Savonlinna Airport",
    longitude: 28.938228,
    latitude: 61.943497,
    id: "arp_svl_fi",
    icao_code: "EFSA",
    iata_country_code: "FI",
    iata_code: "SVL",
    city_name: "Savonlinna",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Savoonga Airport",
    longitude: -170.489547,
    latitude: 63.688529,
    id: "arp_sva_us",
    icao_code: "PASA",
    iata_country_code: "US",
    iata_code: "SVA",
    city_name: "Savoonga",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Savusavu Airport",
    longitude: 179.340772,
    latitude: -16.802869,
    id: "arp_svu_fj",
    icao_code: "NFNS",
    iata_country_code: "FJ",
    iata_code: "SVU",
    city_name: "Savusavu",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Savuti Airport",
    longitude: 24.075135,
    latitude: -18.52345,
    id: "arp_svt_bw",
    icao_code: "FBSV",
    iata_country_code: "BW",
    iata_code: "SVT",
    city_name: "Savuti",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sawan Airport",
    longitude: 68.878646,
    latitude: 26.96617,
    id: "arp_rzs_pk",
    icao_code: "OPSW",
    iata_country_code: "PK",
    iata_code: "RZS",
    city_name: "Sawan",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sawu Airport",
    longitude: 121.84567,
    latitude: -10.493443,
    id: "arp_sau_id",
    icao_code: "WATS",
    iata_country_code: "ID",
    iata_code: "SAU",
    city_name: "Savu",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sawyer County Airport",
    longitude: -91.444253,
    latitude: 46.026183,
    id: "arp_hyr_us",
    icao_code: "KHYR",
    iata_country_code: "US",
    iata_code: "HYR",
    city_name: "Hayward",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Sawyer International Airport",
    longitude: -87.393321,
    latitude: 46.351417,
    id: "arp_mqt_us",
    icao_code: "KSAW",
    iata_country_code: "US",
    iata_code: "MQT",
    city_name: "Marquette",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Sayaboury Airport",
    longitude: 101.709366,
    latitude: 19.243502,
    id: "arp_zby_la",
    icao_code: "VLSB",
    iata_country_code: "LA",
    iata_code: "ZBY",
    city_name: "Sainyabuli",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Scammon Bay Airport",
    longitude: -165.572341,
    latitude: 61.844896,
    id: "arp_scm_us",
    icao_code: "PACM",
    iata_country_code: "US",
    iata_code: "SCM",
    city_name: "Scammon Bay",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Scandinavian Mountains Airport",
    longitude: 12.837907,
    latitude: 61.156526,
    id: "arp_scr_us",
    icao_code: "ESKS",
    iata_country_code: "US",
    iata_code: "SCR",
    city_name: "Salen",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Scarlett Martinez International Airport",
    longitude: -80.130425,
    latitude: 8.385024,
    id: "arp_rih_pa",
    icao_code: "MPSM",
    iata_country_code: "PA",
    iata_code: "RIH",
    city_name: "Rio Hato",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Scatsta Airport",
    longitude: -1.295754,
    latitude: 60.434249,
    id: "arp_scs_gb",
    icao_code: "EGPM",
    iata_country_code: "GB",
    iata_code: "SCS",
    city_name: "Shetland Islands",
    city: {
      name: "Shetland Islands",
      id: "cit_sdz_gb",
      iata_country_code: "GB",
      iata_code: "SDZ",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Schefferville Airport",
    longitude: -66.805823,
    latitude: 54.804195,
    id: "arp_ykl_ca",
    icao_code: "CYKL",
    iata_country_code: "CA",
    iata_code: "YKL",
    city_name: "Schefferville",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Schenck Field",
    longitude: -95.028299,
    latitude: 40.723001,
    id: "arp_icl_us",
    icao_code: "KICL",
    iata_country_code: "US",
    iata_code: "ICL",
    city_name: "Clarinda",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Schenectady County Airport",
    longitude: -73.928874,
    latitude: 42.853293,
    id: "arp_sch_us",
    icao_code: "KSCH",
    iata_country_code: "US",
    iata_code: "SCH",
    city_name: "Schenectady",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Schleswig Air Base",
    longitude: 9.515424,
    latitude: 54.458701,
    id: "arp_wbg_de",
    icao_code: "ETNS",
    iata_country_code: "DE",
    iata_code: "WBG",
    city_name: "Schleswig",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Scholes International Airport",
    longitude: -94.857812,
    latitude: 29.266775,
    id: "arp_gls_us",
    icao_code: "KGLS",
    iata_country_code: "US",
    iata_code: "GLS",
    city_name: "Galveston",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Schoolcraft County Airport",
    longitude: -86.173579,
    latitude: 45.971321,
    id: "arp_isq_us",
    icao_code: "KISQ",
    iata_country_code: "US",
    iata_code: "ISQ",
    city_name: "Manistique",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Schwerin Parchim International Airport",
    longitude: 11.782725,
    latitude: 53.429571,
    id: "arp_szw_de",
    icao_code: "EDOP",
    iata_country_code: "DE",
    iata_code: "SZW",
    city_name: "Schwerin",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Scone Airport",
    longitude: 150.831597,
    latitude: -32.03662,
    id: "arp_nso_au",
    icao_code: "YSCO",
    iata_country_code: "AU",
    iata_code: "NSO",
    city_name: "Scone",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Scottsdale Airport",
    longitude: -111.912433,
    latitude: 33.622075,
    id: "arp_scf_us",
    icao_code: "KSDL",
    iata_country_code: "US",
    iata_code: "SCF",
    city_name: "Scottsdale",
    city: {
      name: "Phoenix",
      id: "cit_phx_us",
      iata_country_code: "US",
      iata_code: "PHX",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Scribner State Airport",
    longitude: -96.629873,
    latitude: 41.610321,
    id: "arp_scb_us",
    icao_code: "KSCB",
    iata_country_code: "US",
    iata_code: "SCB",
    city_name: "Scribner",
    city: null,
  },
  {
    time_zone: "Africa/Mogadishu",
    name: "Scusciuban Airport",
    longitude: 50.233215,
    latitude: 10.299578,
    id: "arp_cms_so",
    icao_code: "HCMS",
    iata_country_code: "SO",
    iata_code: "CMS",
    city_name: "Scusciuban",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Sde Dov Airport",
    longitude: 34.783986,
    latitude: 32.105466,
    id: "arp_sdv_il",
    icao_code: "LLSD",
    iata_country_code: "IL",
    iata_code: "SDV",
    city_name: "Tel Aviv",
    city: {
      name: "Tel Aviv-Yafo",
      id: "cit_tlv_il",
      iata_country_code: "IL",
      iata_code: "TLV",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Seal Bay Seaplane Base",
    longitude: -152.2018,
    latitude: 58.3733,
    id: "arp_syb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SYB",
    city_name: "Seal Bay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Searcy Municipal Airport",
    longitude: -91.737589,
    latitude: 35.209994,
    id: "arp_src_us",
    icao_code: "KSRC",
    iata_country_code: "US",
    iata_code: "SRC",
    city_name: "Searcy",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Searle Field",
    longitude: -101.766325,
    latitude: 41.120163,
    id: "arp_oga_us",
    icao_code: "KOGA",
    iata_country_code: "US",
    iata_code: "OGA",
    city_name: "Ogallala",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Seattle-Tacoma International Airport",
    longitude: -122.308907,
    latitude: 47.449625,
    id: "arp_sea_us",
    icao_code: "KSEA",
    iata_country_code: "US",
    iata_code: "SEA",
    city_name: "Seattle",
    city: {
      name: "Seattle",
      id: "cit_sea_us",
      iata_country_code: "US",
      iata_code: "SEA",
    },
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Sebba Airport",
    longitude: 0.503271,
    latitude: 13.457074,
    id: "arp_xse_bf",
    icao_code: "DFES",
    iata_country_code: "BF",
    iata_code: "XSE",
    city_name: "Sebba",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sebring Regional Airport",
    longitude: -81.343583,
    latitude: 27.456068,
    id: "arp_sef_us",
    icao_code: "KSEF",
    iata_country_code: "US",
    iata_code: "SEF",
    city_name: "Sebring",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Sechelt Airport",
    longitude: -123.718419,
    latitude: 49.460597,
    id: "arp_yhs_ca",
    icao_code: "CAP3",
    iata_country_code: "CA",
    iata_code: "YHS",
    city_name: "Sechelt",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Secunda Airport",
    longitude: 29.170656,
    latitude: -26.523903,
    id: "arp_zec_za",
    icao_code: "FASC",
    iata_country_code: "ZA",
    iata_code: "ZEC",
    city_name: "Secunda",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sedalia Regional Airport",
    longitude: -93.174667,
    latitude: 38.707581,
    id: "arp_dmo_us",
    icao_code: "KDMO",
    iata_country_code: "US",
    iata_code: "DMO",
    city_name: "Sedalia",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Sedona Airport",
    longitude: -111.78838,
    latitude: 34.849066,
    id: "arp_sdx_us",
    icao_code: "KSEZ",
    iata_country_code: "US",
    iata_code: "SDX",
    city_name: "Sedona",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Seghe Airport",
    longitude: 157.87575,
    latitude: -8.578789,
    id: "arp_egm_sb",
    icao_code: "AGGS",
    iata_country_code: "SB",
    iata_code: "EGM",
    city_name: "Sege",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Ségou Airport",
    longitude: -6.282883,
    latitude: 13.431925,
    id: "arp_szu_ml",
    icao_code: "GASG",
    iata_country_code: "ML",
    iata_code: "SZU",
    city_name: "Segou",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Seguela Airport",
    longitude: -6.644403,
    latitude: 7.959469,
    id: "arp_seo_ci",
    icao_code: "DISG",
    iata_country_code: "CI",
    iata_code: "SEO",
    city_name: "Seguela",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Sehonghong Airport",
    longitude: 28.770038,
    latitude: -29.728493,
    id: "arp_shk_ls",
    icao_code: "FXSH",
    iata_country_code: "LS",
    iata_code: "SHK",
    city_name: "Sehonghong",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sehulea Airport",
    longitude: 151.161647,
    latitude: -9.964792,
    id: "arp_sxh_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SXH",
    city_name: "Sehulea",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sehwan Sharif Airport",
    longitude: 67.717343,
    latitude: 26.473387,
    id: "arp_syw_pk",
    icao_code: "OPSN",
    iata_country_code: "PK",
    iata_code: "SYW",
    city_name: "Sehwan Sharif",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sei Bati Airport",
    longitude: 103.39305,
    latitude: 1.052656,
    id: "arp_tjb_id",
    icao_code: "WIBT",
    iata_country_code: "ID",
    iata_code: "TJB",
    city_name: "Tanjung Balai",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Seinäjoki Airport",
    longitude: 22.830242,
    latitude: 62.692401,
    id: "arp_sjy_fi",
    icao_code: "EFSI",
    iata_country_code: "FI",
    iata_code: "SJY",
    city_name: "Seinäjoki/Ilmajoki",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sei Pakning Airport",
    longitude: 102.140013,
    latitude: 1.370612,
    id: "arp_seq_id",
    icao_code: "WIBS",
    iata_country_code: "ID",
    iata_code: "SEQ",
    city_name: "Bengkalis",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Seiyun Hadhramaut Airport",
    longitude: 48.787172,
    latitude: 15.96458,
    id: "arp_gxf_ye",
    icao_code: "OYSY",
    iata_country_code: "YE",
    iata_code: "GXF",
    city_name: "Seiyun/Sayun",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Sekake Airport",
    longitude: 28.369527,
    latitude: -30.038378,
    id: "arp_skq_ls",
    icao_code: "FXSK",
    iata_country_code: "LS",
    iata_code: "SKQ",
    city_name: "Sekake",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Selaparang Airport",
    longitude: 116.096905,
    latitude: -8.561152,
    id: "arp_ami_id",
    icao_code: "WADA",
    iata_country_code: "ID",
    iata_code: "AMI",
    city_name: "Mataram",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Selawik Airport",
    longitude: -159.984603,
    latitude: 66.600003,
    id: "arp_wlk_us",
    icao_code: "PASK",
    iata_country_code: "US",
    iata_code: "WLK",
    city_name: "Selawik",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Selbang Airport",
    longitude: 141.758029,
    latitude: -5.319326,
    id: "arp_sbc_pg",
    icao_code: "AYSO",
    iata_country_code: "PG",
    iata_code: "SBC",
    city_name: "Selbang",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Seldovia Airport",
    longitude: -151.704113,
    latitude: 59.442562,
    id: "arp_sov_us",
    icao_code: "PASO",
    iata_country_code: "US",
    iata_code: "SOV",
    city_name: "Seldovia",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Selebi-Phikwe Airport",
    longitude: 27.828819,
    latitude: -22.058631,
    id: "arp_pkw_bw",
    icao_code: "FBSP",
    iata_country_code: "BW",
    iata_code: "PKW",
    city_name: "Selebi-Phikwe",
    city: null,
  },
  {
    time_zone: "Asia/Singapore",
    name: "Seletar Airport",
    longitude: 103.86819,
    latitude: 1.416952,
    id: "arp_xsp_sg",
    icao_code: "WSSL",
    iata_country_code: "SG",
    iata_code: "XSP",
    city_name: "Singapore",
    city: {
      name: "Singapore",
      id: "cit_sin_sg",
      iata_country_code: "SG",
      iata_code: "SIN",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Selfridge Air National Guard Base Airport",
    longitude: -82.835196,
    latitude: 42.612462,
    id: "arp_mtc_us",
    icao_code: "KMTC",
    iata_country_code: "US",
    iata_code: "MTC",
    city_name: "Mount Clemens",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Selfs Airport",
    longitude: -90.289805,
    latitude: 34.231679,
    id: "arp_mms_us",
    icao_code: "KMMS",
    iata_country_code: "US",
    iata_code: "MMS",
    city_name: "Marks",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Sélibaby Airport",
    longitude: -12.20549,
    latitude: 15.180626,
    id: "arp_sey_mr",
    icao_code: "GQNS",
    iata_country_code: "MR",
    iata_code: "SEY",
    city_name: "Sélibaby",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Semarang Airport",
    longitude: 110.375779,
    latitude: -6.972687,
    id: "arp_srg_id",
    icao_code: "WAHS",
    iata_country_code: "ID",
    iata_code: "SRG",
    city_name: "Semarang",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sematan Airport",
    longitude: 109.766166,
    latitude: 1.812601,
    id: "arp_bse_my",
    icao_code: "WBGN",
    iata_country_code: "MY",
    iata_code: "BSE",
    city_name: "Sematan",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Semera Airport",
    longitude: 40.991906,
    latitude: 11.788997,
    id: "arp_sze_et",
    icao_code: "HASM",
    iata_country_code: "ET",
    iata_code: "SZE",
    city_name: "Semera",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Semey Airport",
    longitude: 80.234503,
    latitude: 50.351485,
    id: "arp_plx_kz",
    icao_code: "UASS",
    iata_country_code: "KZ",
    iata_code: "PLX",
    city_name: "Semey",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Semnan Municipal Airport",
    longitude: 53.49524,
    latitude: 35.593272,
    id: "arp_snx_ir",
    icao_code: "OIIS",
    iata_country_code: "IR",
    iata_code: "SNX",
    city_name: "Semnan",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Semonkong Airport",
    longitude: 28.058263,
    latitude: -29.838531,
    id: "arp_sok_ls",
    icao_code: "FXSM",
    iata_country_code: "LS",
    iata_code: "SOK",
    city_name: "Semonkong",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Semporna Airport",
    longitude: 118.594857,
    latitude: 4.456509,
    id: "arp_smm_my",
    icao_code: "WBKA",
    iata_country_code: "MY",
    iata_code: "SMM",
    city_name: "Semporna",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Senadora Eunice Micheles Airport",
    longitude: -68.921417,
    latitude: -3.468523,
    id: "arp_olc_br",
    icao_code: "SDCG",
    iata_country_code: "BR",
    iata_code: "OLC",
    city_name: "Sao Paulo de Olivenca",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Senai International Airport",
    longitude: 103.668761,
    latitude: 1.639172,
    id: "arp_jhb_my",
    icao_code: "WMKJ",
    iata_country_code: "MY",
    iata_code: "JHB",
    city_name: "Johor Bahru",
    city: null,
  },
  {
    time_zone: "America/Rio_Branco",
    name: "Sena Madureira Airport",
    longitude: -68.632882,
    latitude: -9.133106,
    id: "arp_zmd_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "ZMD",
    city_name: "Sena Madureira",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Senanga Airport",
    longitude: 23.298233,
    latitude: -16.11283,
    id: "arp_sxg_zm",
    icao_code: "FLSN",
    iata_country_code: "ZM",
    iata_code: "SXG",
    city_name: "Senanga",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Sendai Airport",
    longitude: 140.918712,
    latitude: 38.13867,
    id: "arp_sdj_jp",
    icao_code: "RJSS",
    iata_country_code: "JP",
    iata_code: "SDJ",
    city_name: "Sendai",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Senggeh Airport",
    longitude: 140.779,
    latitude: -3.45,
    id: "arp_seh_id",
    icao_code: "WAJS",
    iata_country_code: "ID",
    iata_code: "SEH",
    city_name: "Senggeh",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Senggo Airport",
    longitude: 139.349577,
    latitude: -5.690619,
    id: "arp_zeg_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "ZEG",
    city_name: "Senggo",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Sentani Airport",
    longitude: 140.515491,
    latitude: -2.575924,
    id: "arp_djj_id",
    icao_code: "WAJJ",
    iata_country_code: "ID",
    iata_code: "DJJ",
    city_name: "Jayapura",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Seosan Air Base",
    longitude: 126.486,
    latitude: 36.703999,
    id: "arp_hmy_kr",
    icao_code: "RKTP",
    iata_country_code: "KR",
    iata_code: "HMY",
    city_name: "Seosan",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Seoul Air Base",
    longitude: 127.111551,
    latitude: 37.444696,
    id: "arp_ssn_kr",
    icao_code: "RKSM",
    iata_country_code: "KR",
    iata_code: "SSN",
    city_name: "Seoul",
    city: {
      name: "Seoul",
      id: "cit_sel_kr",
      iata_country_code: "KR",
      iata_code: "SEL",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Sepé Tiaraju Airport",
    longitude: -54.169102,
    latitude: -28.2817,
    id: "arp_gel_br",
    icao_code: "SBNM",
    iata_country_code: "BR",
    iata_code: "GEL",
    city_name: "Santo Ângelo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sepik Plains Airport",
    longitude: 143.683333,
    latitude: -3.9,
    id: "arp_spv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SPV",
    city_name: "Sepik Plains",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Sept-Îles Airport",
    longitude: -66.265023,
    latitude: 50.221509,
    id: "arp_yzv_ca",
    icao_code: "CYZV",
    iata_country_code: "CA",
    iata_code: "YZV",
    city_name: "Sept-Îles",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sepulot Airport",
    longitude: 116.467,
    latitude: 4.733,
    id: "arp_spe_my",
    icao_code: "WBKO",
    iata_country_code: "MY",
    iata_code: "SPE",
    city_name: "Sepulot",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Sequim Valley Airport",
    longitude: -123.18706,
    latitude: 48.098123,
    id: "arp_sqv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SQV",
    city_name: "Sequim",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Seronera Airstrip",
    longitude: 34.821675,
    latitude: -2.457912,
    id: "arp_seu_tz",
    icao_code: "HTSN",
    iata_country_code: "TZ",
    iata_code: "SEU",
    city_name: "Seronera",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Serui Airport",
    longitude: 136.240308,
    latitude: -1.87487,
    id: "arp_zri_id",
    icao_code: "WABO",
    iata_country_code: "ID",
    iata_code: "ZRI",
    city_name: "Serui",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Sesheke Airport",
    longitude: 24.305509,
    latitude: -17.476552,
    id: "arp_sjq_zm",
    icao_code: "FLSS",
    iata_country_code: "ZM",
    iata_code: "SJQ",
    city_name: "Sesheke",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Seshutes Airport",
    longitude: 28.551724,
    latitude: -29.268508,
    id: "arp_shz_ls",
    icao_code: "FXSS",
    iata_country_code: "LS",
    iata_code: "SHZ",
    city_name: "Seshote",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Sesriem Airport",
    longitude: 15.749169,
    latitude: -24.513667,
    id: "arp_szm_na",
    icao_code: null,
    iata_country_code: "NA",
    iata_code: "SZM",
    city_name: "Sesriem",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Severodonetsk Airport",
    longitude: 38.541135,
    latitude: 48.901326,
    id: "arp_sev_ua",
    icao_code: null,
    iata_country_code: "UA",
    iata_code: "SEV",
    city_name: "Severodonetsk",
    city: null,
  },
  {
    time_zone: "Asia/Magadan",
    name: "Severo-Evensk Airport",
    longitude: 159.227581,
    latitude: 61.92165,
    id: "arp_swv_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "SWV",
    city_name: "Shikarpur",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Seville Airport",
    longitude: -5.89308,
    latitude: 37.419083,
    id: "arp_svq_es",
    icao_code: "LEZL",
    iata_country_code: "ES",
    iata_code: "SVQ",
    city_name: "Sevilla",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Seward Airport",
    longitude: -149.418482,
    latitude: 60.128553,
    id: "arp_swd_us",
    icao_code: "PAWD",
    iata_country_code: "US",
    iata_code: "SWD",
    city_name: "Seward",
    city: null,
  },
  {
    time_zone: "Indian/Mahe",
    name: "Seychelles International Airport",
    longitude: 55.512259,
    latitude: -4.670167,
    id: "arp_sez_sc",
    icao_code: "FSIA",
    iata_country_code: "SC",
    iata_code: "SEZ",
    city_name: "Seychelles",
    city: null,
  },
  {
    time_zone: "Pacific/Galapagos",
    name: "Seymour Airport",
    longitude: -90.267285,
    latitude: -0.450655,
    id: "arp_gps_ec",
    icao_code: "SEGS",
    iata_country_code: "EC",
    iata_code: "GPS",
    city_name: "Baltra Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Seymour Johnson Air Force Base",
    longitude: -77.963927,
    latitude: 35.339868,
    id: "arp_gsb_us",
    icao_code: "KGSB",
    iata_country_code: "US",
    iata_code: "GSB",
    city_name: "Goldsboro",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Sfax-Thyna International Airport",
    longitude: 10.690203,
    latitude: 34.718851,
    id: "arp_sfa_tn",
    icao_code: "DTTX",
    iata_country_code: "TN",
    iata_code: "SFA",
    city_name: "Sfax",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Shafter Airport",
    longitude: -119.191697,
    latitude: 35.506617,
    id: "arp_mit_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "MIT",
    city_name: "Shafter",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Shageluk Airport",
    longitude: -159.569115,
    latitude: 62.691826,
    id: "arp_shx_us",
    icao_code: "PAHX",
    iata_country_code: "US",
    iata_code: "SHX",
    city_name: "Shageluk",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Shah Amanat International Airport",
    longitude: 91.81376,
    latitude: 22.248299,
    id: "arp_cgp_bd",
    icao_code: "VGEG",
    iata_country_code: "BD",
    iata_code: "CGP",
    city_name: "Chittagong",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Shahbaz Air Base Airport",
    longitude: 68.451908,
    latitude: 28.284443,
    id: "arp_jag_pk",
    icao_code: "OPJA",
    iata_country_code: "PK",
    iata_code: "JAG",
    city_name: "Jacobabad",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Shahid Ashrafi Esfahani Airport",
    longitude: 47.14383,
    latitude: 34.35474,
    id: "arp_ksh_ir",
    icao_code: "OICC",
    iata_country_code: "IR",
    iata_code: "KSH",
    city_name: "Kermanshah",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Shahid Sadooghi Airport",
    longitude: 54.279529,
    latitude: 31.903773,
    id: "arp_azd_ir",
    icao_code: "OIYY",
    iata_country_code: "IR",
    iata_code: "AZD",
    city_name: "Yazd",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Shah Makhdum Airport",
    longitude: 88.615877,
    latitude: 24.439183,
    id: "arp_rjh_bd",
    icao_code: "VGRJ",
    iata_country_code: "BD",
    iata_code: "RJH",
    city_name: "Rajshahi",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Shahrekord Airport",
    longitude: 50.84109,
    latitude: 32.296556,
    id: "arp_cqd_ir",
    icao_code: "OIFS",
    iata_country_code: "IR",
    iata_code: "CQD",
    city_name: "Shahre Kord",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Shahroud International Airport",
    longitude: 55.104198,
    latitude: 36.425301,
    id: "arp_rud_ir",
    icao_code: "OIMJ",
    iata_country_code: "IR",
    iata_code: "RUD",
    city_name: "Shahrud",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Shaikh Zayed International Airport",
    longitude: 70.286096,
    latitude: 28.391785,
    id: "arp_ryk_pk",
    icao_code: "OPRK",
    iata_country_code: "PK",
    iata_code: "RYK",
    city_name: "Rahim Yar Khan",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Shakawe Airport",
    longitude: 21.835413,
    latitude: -18.373118,
    id: "arp_swx_bw",
    icao_code: "FBSW",
    iata_country_code: "BW",
    iata_code: "SWX",
    city_name: "Shakawe",
    city: null,
  },
  {
    time_zone: "Asia/Sakhalin",
    name: "Shakhtyorsk Airport",
    longitude: 142.082993,
    latitude: 49.1903,
    id: "arp_eks_ru",
    icao_code: "UHSK",
    iata_country_code: "RU",
    iata_code: "EKS",
    city_name: "Shakhtyorsk",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Shakiso Airport",
    longitude: 38.97427,
    latitude: 5.691827,
    id: "arp_skr_et",
    icao_code: "HASK",
    iata_country_code: "ET",
    iata_code: "SKR",
    city_name: "Shakiso",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Shaktoolik Airport",
    longitude: -161.224094,
    latitude: 64.37123,
    id: "arp_skk_us",
    icao_code: "PFSH",
    iata_country_code: "US",
    iata_code: "SKK",
    city_name: "Shaktoolik",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Shamattawa Airport",
    longitude: -92.081742,
    latitude: 55.86251,
    id: "arp_ztm_ca",
    icao_code: "CZTM",
    iata_country_code: "CA",
    iata_code: "ZTM",
    city_name: "Shamattawa",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Shamshernagar Airport",
    longitude: 91.913587,
    latitude: 24.400849,
    id: "arp_zhm_bd",
    icao_code: "VGSH",
    iata_country_code: "BD",
    iata_code: "ZHM",
    city_name: "Shamshernagar",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shanghai Hongqiao International Airport",
    longitude: 121.335825,
    latitude: 31.194976,
    id: "arp_sha_cn",
    icao_code: "ZSSS",
    iata_country_code: "CN",
    iata_code: "SHA",
    city_name: "Shanghai",
    city: {
      name: "Shanghai",
      id: "cit_sha_cn",
      iata_country_code: "CN",
      iata_code: "SHA",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shanghai Pudong International Airport",
    longitude: 121.806636,
    latitude: 31.143872,
    id: "arp_pvg_cn",
    icao_code: "ZSPD",
    iata_country_code: "CN",
    iata_code: "PVG",
    city_name: "Shanghai",
    city: {
      name: "Shanghai",
      id: "cit_sha_cn",
      iata_country_code: "CN",
      iata_code: "SHA",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shangjie Airport",
    longitude: 113.276418,
    latitude: 34.838911,
    id: "arp_hsj_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "HSJ",
    city_name: "Zhengzhou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shangrao Sanqingshan Airport",
    longitude: 118.259032,
    latitude: 29.728739,
    id: "arp_sqd_cn",
    icao_code: "ZSSR",
    iata_country_code: "CN",
    iata_code: "SQD",
    city_name: "Shangrao",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Shank Air Base",
    longitude: 69.077222,
    latitude: 33.9225,
    id: "arp_oaa_af",
    icao_code: "OASH",
    iata_country_code: "AF",
    iata_code: "OAA",
    city_name: "Shank",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Shannon Airport",
    longitude: -8.919755,
    latitude: 52.700828,
    id: "arp_snn_ie",
    icao_code: "EINN",
    iata_country_code: "IE",
    iata_code: "SNN",
    city_name: "Shannon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shanshan Airport",
    longitude: 90.247497,
    latitude: 42.911701,
    id: "arp_sxj_cn",
    icao_code: "ZWSS",
    iata_country_code: "CN",
    iata_code: "SXJ",
    city_name: "Shanshan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shaoguan Guitou Airport",
    longitude: 113.420403,
    latitude: 24.978232,
    id: "arp_hsc_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "HSC",
    city_name: "Shaoguan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shaoyang Wugang Airport",
    longitude: 110.642,
    latitude: 26.802,
    id: "arp_wgn_cn",
    icao_code: "ZGSY",
    iata_country_code: "CN",
    iata_code: "WGN",
    city_name: "Shaoyang",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Sharana Airstrip",
    longitude: 68.838516,
    latitude: 33.125752,
    id: "arp_oas_af",
    icao_code: "OASA",
    iata_country_code: "AF",
    iata_code: "OAS",
    city_name: "Sharana",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Sharjah International Airport",
    longitude: 55.514729,
    latitude: 25.328517,
    id: "arp_shj_ae",
    icao_code: "OMSJ",
    iata_country_code: "AE",
    iata_code: "SHJ",
    city_name: "Sharjah",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Sharjah International Airport",
    longitude: 55.513373,
    latitude: 25.328794,
    id: "arp_sjh_ae",
    icao_code: null,
    iata_country_code: "AE",
    iata_code: "SJH",
    city_name: "San Juan del Cesar",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Shark Bay Airport",
    longitude: 113.576882,
    latitude: -25.891403,
    id: "arp_mjk_au",
    icao_code: "YSHK",
    iata_country_code: "AU",
    iata_code: "MJK",
    city_name: "Perth",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Sharm El Sheikh International Airport",
    longitude: 34.394215,
    latitude: 27.97751,
    id: "arp_ssh_eg",
    icao_code: "HESH",
    iata_country_code: "EG",
    iata_code: "SSH",
    city_name: "Sharm el-Sheikh",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sharp County Regional Airport",
    longitude: -91.563633,
    latitude: 36.263673,
    id: "arp_ckk_us",
    icao_code: "KCVK",
    iata_country_code: "US",
    iata_code: "CKK",
    city_name: "Ash Flat",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sharpe Field",
    longitude: -85.776236,
    latitude: 32.492243,
    id: "arp_tge_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TGE",
    city_name: "Tuskegee",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Sharq El Owainat Airport",
    longitude: 28.716463,
    latitude: 22.584643,
    id: "arp_gsq_eg",
    icao_code: "HEOW",
    iata_country_code: "EG",
    iata_code: "GSQ",
    city_name: "Sharq Al-Owainat",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Sharurah Domestic Airport",
    longitude: 47.112318,
    latitude: 17.469045,
    id: "arp_shw_sa",
    icao_code: "OESH",
    iata_country_code: "SA",
    iata_code: "SHW",
    city_name: "Sharurah",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shashi Airport",
    longitude: 112.278016,
    latitude: 30.321897,
    id: "arp_shs_cn",
    icao_code: "ZHSS",
    iata_country_code: "CN",
    iata_code: "SHS",
    city_name: "Jingzhou",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Shaw Air Force Base",
    longitude: -80.470145,
    latitude: 33.973088,
    id: "arp_ssc_us",
    icao_code: "KSSC",
    iata_country_code: "US",
    iata_code: "SSC",
    city_name: "Sumter",
    city: {
      name: "Sumter",
      id: "cit_ssc_us",
      iata_country_code: "US",
      iata_code: "SSC",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Shawnee Regional Airport",
    longitude: -96.941922,
    latitude: 35.35748,
    id: "arp_snl_us",
    icao_code: "KSNL",
    iata_country_code: "US",
    iata_code: "SNL",
    city_name: "Shawnee",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Shaw River Airport",
    longitude: 119.3623,
    latitude: -21.510036,
    id: "arp_swb_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "SWB",
    city_name: "Shaw River",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Shay Gap Airport",
    longitude: 120.136792,
    latitude: -20.424913,
    id: "arp_sgp_au",
    icao_code: "YSHG",
    iata_country_code: "AU",
    iata_code: "SGP",
    city_name: "Shay Gap",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Shearwater Airport",
    longitude: -128.083028,
    latitude: 52.149956,
    id: "arp_ysx_ca",
    icao_code: "CYAW",
    iata_country_code: "CA",
    iata_code: "YSX",
    city_name: "Bella Bella",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sheboygan County Memorial Airport",
    longitude: -87.851345,
    latitude: 43.76701,
    id: "arp_sbm_us",
    icao_code: "KSBM",
    iata_country_code: "US",
    iata_code: "SBM",
    city_name: "Sheboygan",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Sheep Mountain Airport",
    longitude: -147.504629,
    latitude: 61.812461,
    id: "arp_smu_us",
    icao_code: "PASP",
    iata_country_code: "US",
    iata_code: "SMU",
    city_name: "Sheep Mountain",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Sheghnan Airport",
    longitude: 71.5,
    latitude: 37.567,
    id: "arp_sga_af",
    icao_code: "OASN",
    iata_country_code: "AF",
    iata_code: "SGA",
    city_name: "Sheghnan",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Shelby Airport",
    longitude: -111.870786,
    latitude: 48.54092,
    id: "arp_sbx_us",
    icao_code: "KSBX",
    iata_country_code: "US",
    iata_code: "SBX",
    city_name: "Shelby",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Shelbyville Municipal Airport",
    longitude: -86.44317,
    latitude: 35.56071,
    id: "arp_syi_us",
    icao_code: "KSYI",
    iata_country_code: "US",
    iata_code: "SYI",
    city_name: "Shelbyville",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Sheldon Point Airport",
    longitude: -164.847895,
    latitude: 62.52038,
    id: "arp_sxp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SXP",
    city_name: "Nunam Iqua",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Shellharbour Airport",
    longitude: 150.788727,
    latitude: -34.559643,
    id: "arp_wol_au",
    icao_code: "YWOL",
    iata_country_code: "AU",
    iata_code: "WOL",
    city_name: "Wollongong",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Shenandoah Valley Regional Airport",
    longitude: -78.897015,
    latitude: 38.263616,
    id: "arp_shd_us",
    icao_code: "KSHD",
    iata_country_code: "US",
    iata_code: "SHD",
    city_name: "Staunton",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shennongjia Hongping Airport",
    longitude: 110.339463,
    latitude: 31.624136,
    id: "arp_hpg_cn",
    icao_code: "ZHSN",
    iata_country_code: "CN",
    iata_code: "HPG",
    city_name: "Shennongjia",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shenyang Taoxian International Airport",
    longitude: 123.489346,
    latitude: 41.640524,
    id: "arp_she_cn",
    icao_code: "ZYTX",
    iata_country_code: "CN",
    iata_code: "SHE",
    city_name: "Shenyang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shenzhen Bao'an International Airport",
    longitude: 113.811816,
    latitude: 22.638671,
    id: "arp_szx_cn",
    icao_code: "ZGSZ",
    iata_country_code: "CN",
    iata_code: "SZX",
    city_name: "Shenzhen",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Shepparton Airport",
    longitude: 145.392167,
    latitude: -36.428756,
    id: "arp_sht_au",
    icao_code: "YSHT",
    iata_country_code: "AU",
    iata_code: "SHT",
    city_name: "Shepparton",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Sherbro International Airport",
    longitude: -12.518794,
    latitude: 7.532331,
    id: "arp_bte_sl",
    icao_code: "GFBN",
    iata_country_code: "SL",
    iata_code: "BTE",
    city_name: "Bonthe",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Sherbrooke Airport",
    longitude: -71.690691,
    latitude: 45.43958,
    id: "arp_ysc_ca",
    icao_code: "CYSC",
    iata_country_code: "CA",
    iata_code: "YSC",
    city_name: "Sherbrooke",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Sheremetyevo International Airport",
    longitude: 37.413551,
    latitude: 55.973123,
    id: "arp_svo_ru",
    icao_code: "UUEE",
    iata_country_code: "RU",
    iata_code: "SVO",
    city_name: "Moscow",
    city: {
      name: "Moscow",
      id: "cit_mow_ru",
      iata_country_code: "RU",
      iata_code: "MOW",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Sheridan County Airport",
    longitude: -106.980606,
    latitude: 44.770502,
    id: "arp_shr_us",
    icao_code: "KSHR",
    iata_country_code: "US",
    iata_code: "SHR",
    city_name: "Sheridan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sherman Army Airfield",
    longitude: -94.914933,
    latitude: 39.368407,
    id: "arp_flv_us",
    icao_code: "KFLV",
    iata_country_code: "US",
    iata_code: "FLV",
    city_name: "Fort Leavenworth",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Sher-Wood Airport",
    longitude: -104.526619,
    latitude: 48.789639,
    id: "arp_pwd_us",
    icao_code: "KPWD",
    iata_country_code: "US",
    iata_code: "PWD",
    city_name: "Plentywood",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shigatse Peace Airport",
    longitude: 89.301732,
    latitude: 29.350715,
    id: "arp_rkz_cn",
    icao_code: "ZURK",
    iata_country_code: "CN",
    iata_code: "RKZ",
    city_name: "Shigatse",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shihezi Huayuan Airport",
    longitude: 86.118668,
    latitude: 44.331814,
    id: "arp_shf_cn",
    icao_code: "ZWHZ",
    iata_country_code: "CN",
    iata_code: "SHF",
    city_name: "Shihezi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shijiazhuang Zhengding International Airport",
    longitude: 114.696887,
    latitude: 38.280744,
    id: "arp_sjw_cn",
    icao_code: "ZBSJ",
    iata_country_code: "CN",
    iata_code: "SJW",
    city_name: "Shijiazhuang",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Shilavo Airport",
    longitude: 44.765021,
    latitude: 6.080611,
    id: "arp_hil_et",
    icao_code: "HASL",
    iata_country_code: "ET",
    iata_code: "HIL",
    city_name: "Shilabo",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Shillong Airport",
    longitude: 91.977492,
    latitude: 25.704469,
    id: "arp_shl_in",
    icao_code: "VEBI",
    iata_country_code: "IN",
    iata_code: "SHL",
    city_name: "Shillong",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Shimla Airport",
    longitude: 77.067078,
    latitude: 31.083013,
    id: "arp_slv_in",
    icao_code: "VISM",
    iata_country_code: "IN",
    iata_code: "SLV",
    city_name: "Shimla",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Shimojishima Airport",
    longitude: 125.146633,
    latitude: 24.826946,
    id: "arp_shi_jp",
    icao_code: "RORS",
    iata_country_code: "JP",
    iata_code: "SHI",
    city_name: "Shimojishima",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Shindand Air Base Airport",
    longitude: 62.259601,
    latitude: 33.391813,
    id: "arp_oah_af",
    icao_code: "OASD",
    iata_country_code: "AF",
    iata_code: "OAH",
    city_name: "Shindand",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Shinyanga Airport",
    longitude: 33.503967,
    latitude: -3.609384,
    id: "arp_shy_tz",
    icao_code: "HTSY",
    iata_country_code: "TZ",
    iata_code: "SHY",
    city_name: "Shinyanga",
    city: null,
  },
  {
    time_zone: "Asia/Yerevan",
    name: "Shirak Airport",
    longitude: 43.85794,
    latitude: 40.750478,
    id: "arp_lwn_am",
    icao_code: "UDSG",
    iata_country_code: "AM",
    iata_code: "LWN",
    city_name: "Gyumri",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Shiraz International Airport",
    longitude: 52.590038,
    latitude: 29.542746,
    id: "arp_syz_ir",
    icao_code: "OISS",
    iata_country_code: "IR",
    iata_code: "SYZ",
    city_name: "Shiraz",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Shirdi Airport",
    longitude: 74.393176,
    latitude: 19.692019,
    id: "arp_sag_in",
    icao_code: "VASD",
    iata_country_code: "IN",
    iata_code: "SAG",
    city_name: "Shirdi",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Shire Airport",
    longitude: 38.271967,
    latitude: 14.078534,
    id: "arp_shc_et",
    icao_code: null,
    iata_country_code: "ET",
    iata_code: "SHC",
    city_name: "Shire Indasilase",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Shiringayoc Airport",
    longitude: -69.167,
    latitude: -11.9,
    id: "arp_syc_pe",
    icao_code: null,
    iata_country_code: "PE",
    iata_code: "SYC",
    city_name: "Shiringayoc",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Shishmaref Airport",
    longitude: -166.081857,
    latitude: 66.252149,
    id: "arp_shh_us",
    icao_code: "PASH",
    iata_country_code: "US",
    iata_code: "SHH",
    city_name: "Shishmaref",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Shively Field Airport",
    longitude: -106.823838,
    latitude: 41.444887,
    id: "arp_saa_us",
    icao_code: "KSAA",
    iata_country_code: "US",
    iata_code: "SAA",
    city_name: "Saratoga",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Shiyan Wudangshan Airport",
    longitude: 110.907208,
    latitude: 32.590999,
    id: "arp_wds_cn",
    icao_code: "ZHSY",
    iata_country_code: "CN",
    iata_code: "WDS",
    city_name: "Shiyan",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Shizuoka Airport",
    longitude: 138.18272,
    latitude: 34.796062,
    id: "arp_fsz_jp",
    icao_code: "RJNS",
    iata_country_code: "JP",
    iata_code: "FSZ",
    city_name: "Shizuoka",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Shonai Airport",
    longitude: 139.788021,
    latitude: 38.811798,
    id: "arp_syo_jp",
    icao_code: "RJSY",
    iata_country_code: "JP",
    iata_code: "SYO",
    city_name: "Shonai",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Shoreham Airport",
    longitude: -0.294872,
    latitude: 50.835085,
    id: "arp_esh_gb",
    icao_code: "EGKA",
    iata_country_code: "GB",
    iata_code: "ESH",
    city_name: "Shoreham-by-Sea",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Show Low Regional Airport",
    longitude: -110.006713,
    latitude: 34.264685,
    id: "arp_sow_us",
    icao_code: "KSOW",
    iata_country_code: "US",
    iata_code: "SOW",
    city_name: "Show Low",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Shreveport Downtown Airport",
    longitude: -93.747083,
    latitude: 32.540481,
    id: "arp_dtn_us",
    icao_code: "KDTN",
    iata_country_code: "US",
    iata_code: "DTN",
    city_name: "Shreveport",
    city: {
      name: "Shreveport",
      id: "cit_shv_us",
      iata_country_code: "US",
      iata_code: "SHV",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Shreveport Regional Airport",
    longitude: -93.827087,
    latitude: 32.45056,
    id: "arp_shv_us",
    icao_code: "KSHV",
    iata_country_code: "US",
    iata_code: "SHV",
    city_name: "Shreveport",
    city: {
      name: "Shreveport",
      id: "cit_shv_us",
      iata_country_code: "US",
      iata_code: "SHV",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Shungnak Airport",
    longitude: -157.162051,
    latitude: 66.888006,
    id: "arp_shg_us",
    icao_code: "PAGH",
    iata_country_code: "US",
    iata_code: "SHG",
    city_name: "Shungnak",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Shymkent International Airport",
    longitude: 69.477756,
    latitude: 42.364679,
    id: "arp_cit_kz",
    icao_code: "UAII",
    iata_country_code: "KZ",
    iata_code: "CIT",
    city_name: "Shymkent",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sialkot International Airport",
    longitude: 74.363705,
    latitude: 32.535084,
    id: "arp_skt_pk",
    icao_code: "OPST",
    iata_country_code: "PK",
    iata_code: "SKT",
    city_name: "Sialkot",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sialum Airport",
    longitude: 147.600805,
    latitude: -6.094997,
    id: "arp_sxa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SXA",
    city_name: "Sialum",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Siargao Airport",
    longitude: 126.014554,
    latitude: 9.858411,
    id: "arp_iao_ph",
    icao_code: "RPNS",
    iata_country_code: "PH",
    iata_code: "IAO",
    city_name: "Del Carmen",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Siasi Airport",
    longitude: 120.817535,
    latitude: 5.551295,
    id: "arp_ssv_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "SSV",
    city_name: "Siasi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Siassi Airport",
    longitude: 147.95,
    latitude: -5.9,
    id: "arp_sss_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SSS",
    city_name: "Siassi",
    city: null,
  },
  {
    time_zone: "Europe/Vilnius",
    name: "Šiauliai International Airport",
    longitude: 23.395006,
    latitude: 55.893896,
    id: "arp_sqq_lt",
    icao_code: "EYSA",
    iata_country_code: "LT",
    iata_code: "SQQ",
    city_name: "Siauliai",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sibi Airport",
    longitude: 67.844701,
    latitude: 29.573387,
    id: "arp_sbq_pk",
    icao_code: "OPSB",
    iata_country_code: "PK",
    iata_code: "SBQ",
    city_name: "Sibi",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sibisa Airport",
    longitude: 98.963769,
    latitude: 2.600188,
    id: "arp_siw_id",
    icao_code: "WIMP",
    iata_country_code: "ID",
    iata_code: "SIW",
    city_name: "Parapat",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Sibiti Airport",
    longitude: 13.372121,
    latitude: -3.686928,
    id: "arp_sib_cg",
    icao_code: "FCBS",
    iata_country_code: "CG",
    iata_code: "SIB",
    city_name: "Sibiti",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Sibiu International Airport",
    longitude: 24.09144,
    latitude: 45.786464,
    id: "arp_sbz_ro",
    icao_code: "LRSB",
    iata_country_code: "RO",
    iata_code: "SBZ",
    city_name: "Sibiu",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sibu Airport",
    longitude: 111.985407,
    latitude: 2.260451,
    id: "arp_sbw_my",
    icao_code: "WBGS",
    iata_country_code: "MY",
    iata_code: "SBW",
    city_name: "Sibu",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Sibulan Airport",
    longitude: 123.299123,
    latitude: 9.333691,
    id: "arp_dgt_ph",
    icao_code: "RPVD",
    iata_country_code: "PH",
    iata_code: "DGT",
    city_name: "Dumaguete",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Sicogon Airport",
    longitude: 123.25108,
    latitude: 11.459867,
    id: "arp_ico_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "ICO",
    city_name: "Sicogon Island",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Sidi Barrani Airport",
    longitude: 25.879236,
    latitude: 31.465476,
    id: "arp_sqk_eg",
    icao_code: null,
    iata_country_code: "EG",
    iata_code: "SQK",
    city_name: "Sidi Barrani",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Sidi Bel Abbes Airport",
    longitude: -0.593275,
    latitude: 35.171799,
    id: "arp_bfw_dz",
    icao_code: "DAOS",
    iata_country_code: "DZ",
    iata_code: "BFW",
    city_name: "Sidi Bel Abbes",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Sidi Ifni Airport",
    longitude: -10.184317,
    latitude: 29.367517,
    id: "arp_sii_ma",
    icao_code: "GMMF",
    iata_country_code: "MA",
    iata_code: "SII",
    city_name: "Sidi Ifni",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Sidi Mahdi Airport",
    longitude: 6.089698,
    latitude: 33.067322,
    id: "arp_tgr_dz",
    icao_code: "DAUK",
    iata_country_code: "DZ",
    iata_code: "TGR",
    city_name: "Touggourt",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Sidney Municipal Airport",
    longitude: -102.985001,
    latitude: 41.101299,
    id: "arp_sny_us",
    icao_code: "KSNY",
    iata_country_code: "US",
    iata_code: "SNY",
    city_name: "Sidney",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sidney Municipal Airport",
    longitude: -84.154233,
    latitude: 40.236921,
    id: "arp_sxy_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SXY",
    city_name: "Sidney",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Sidney-Richland Regional Airport",
    longitude: -104.189961,
    latitude: 47.70778,
    id: "arp_sdy_us",
    icao_code: "KSDY",
    iata_country_code: "US",
    iata_code: "SDY",
    city_name: "Sidney",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Siegerland Airport",
    longitude: 8.079488,
    latitude: 50.707497,
    id: "arp_sge_de",
    icao_code: "EDGS",
    iata_country_code: "DE",
    iata_code: "SGE",
    city_name: "Siegen",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Siem Reap International Airport",
    longitude: 103.813785,
    latitude: 13.409019,
    id: "arp_rep_kh",
    icao_code: "VDSR",
    iata_country_code: "KH",
    iata_code: "REP",
    city_name: "Siem Reap",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Siena-Ampugnano Airport",
    longitude: 11.253433,
    latitude: 43.257599,
    id: "arp_say_it",
    icao_code: "LIQS",
    iata_country_code: "IT",
    iata_code: "SAY",
    city_name: "Siena",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Sierra Blanca Regional Airport",
    longitude: -105.534058,
    latitude: 33.462287,
    id: "arp_rui_us",
    icao_code: "KSRR",
    iata_country_code: "US",
    iata_code: "RUI",
    city_name: "Ruidoso",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Sierra Grande Airport",
    longitude: -65.340514,
    latitude: -41.592983,
    id: "arp_sgv_ar",
    icao_code: "SAVS",
    iata_country_code: "AR",
    iata_code: "SGV",
    city_name: "Sierra Grande",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Sierra Maestra Airport",
    longitude: -77.091669,
    latitude: 20.286198,
    id: "arp_mzo_cu",
    icao_code: "MUMZ",
    iata_country_code: "CU",
    iata_code: "MZO",
    city_name: "Manzanillo",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Sierra Vista Municipal Airport",
    longitude: -110.344129,
    latitude: 31.588482,
    id: "arp_fhu_us",
    icao_code: "KFHU",
    iata_country_code: "US",
    iata_code: "FHU",
    city_name: "Sierra Vista",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Sigiriya Airport",
    longitude: 80.728608,
    latitude: 7.956639,
    id: "arp_giu_lk",
    icao_code: "VCCS",
    iata_country_code: "LK",
    iata_code: "GIU",
    city_name: "Sigiriya",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Siglufjörður Airport",
    longitude: -18.916598,
    latitude: 66.133909,
    id: "arp_sij_is",
    icao_code: "BISI",
    iata_country_code: "IS",
    iata_code: "SIJ",
    city_name: "Siglufjörður",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Siguanea Airport",
    longitude: -82.956393,
    latitude: 21.640942,
    id: "arp_szj_cu",
    icao_code: "MUSN",
    iata_country_code: "CU",
    iata_code: "SZJ",
    city_name: "Isla de la Juventud",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Siguiri Airport",
    longitude: -9.166999,
    latitude: 11.432999,
    id: "arp_gii_gn",
    icao_code: "GUSI",
    iata_country_code: "GN",
    iata_code: "GII",
    city_name: "Siguiri",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Sihanoukville International Airport",
    longitude: 103.636982,
    latitude: 10.5798,
    id: "arp_kos_kh",
    icao_code: "VDSV",
    iata_country_code: "KH",
    iata_code: "KOS",
    city_name: "Sihanoukville",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Siirt Airport",
    longitude: 41.840817,
    latitude: 37.978599,
    id: "arp_sxz_tr",
    icao_code: "LTCL",
    iata_country_code: "TR",
    iata_code: "SXZ",
    city_name: "Siirt",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Sikasso Airport",
    longitude: -5.802658,
    latitude: 11.597152,
    id: "arp_kss_ml",
    icao_code: "GASK",
    iata_country_code: "ML",
    iata_code: "KSS",
    city_name: "Sikasso",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sikeston Memorial Municipal Airport",
    longitude: -89.565187,
    latitude: 36.886087,
    id: "arp_sik_us",
    icao_code: "KSIK",
    iata_country_code: "US",
    iata_code: "SIK",
    city_name: "Sikeston",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sila Airport",
    longitude: 148.4,
    latitude: -9.117,
    id: "arp_sil_pg",
    icao_code: "AYSG",
    iata_country_code: "PG",
    iata_code: "SIL",
    city_name: "Sila",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Silampari Airport",
    longitude: 89.43268,
    latitude: 25.888609,
    id: "arp_llj_id",
    icao_code: "WIPB",
    iata_country_code: "ID",
    iata_code: "LLJ",
    city_name: "Lalmonirhat",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Silangit Airport",
    longitude: 98.990532,
    latitude: 2.260578,
    id: "arp_dtb_id",
    icao_code: "WIMN",
    iata_country_code: "ID",
    iata_code: "DTB",
    city_name: "Siborong-Borong",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Silchar Airport",
    longitude: 92.978955,
    latitude: 24.913602,
    id: "arp_ixs_in",
    icao_code: "VEKU",
    iata_country_code: "IN",
    iata_code: "IXS",
    city_name: "Silchar",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Silgadi Doti Airport",
    longitude: 80.934967,
    latitude: 29.262856,
    id: "arp_sih_np",
    icao_code: "VNDT",
    iata_country_code: "NP",
    iata_code: "SIH",
    city_name: "Dipayal Silgadhi",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Silistra Polkovnik Lambrinovo Airfield",
    longitude: 27.180029,
    latitude: 44.055228,
    id: "arp_sls_bg",
    icao_code: "LBSS",
    iata_country_code: "BG",
    iata_code: "SLS",
    city_name: "Silistra",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Silur Airport",
    longitude: 153.054055,
    latitude: -4.530583,
    id: "arp_swr_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SWR",
    city_name: "Silur",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Silva Bay Seaplane Base Airport",
    longitude: -101.797845,
    latitude: 39.763994,
    id: "arp_syf_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "SYF",
    city_name: "Gabriola Island",
    city: null,
  },
  {
    time_zone: "America/Belize",
    name: "Silver Creek Airport",
    longitude: -88.369809,
    latitude: 16.728426,
    id: "arp_svk_bz",
    icao_code: null,
    iata_country_code: "BZ",
    iata_code: "SVK",
    city_name: "Silver Creek",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Silver Plains Airport",
    longitude: 143.552189,
    latitude: -13.976243,
    id: "arp_ssp_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "SSP",
    city_name: "Silver Plains",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Silvio Pettirossi International Airport",
    longitude: -57.518337,
    latitude: -25.240308,
    id: "arp_asu_py",
    icao_code: "SGAS",
    iata_country_code: "PY",
    iata_code: "ASU",
    city_name: "Asunción",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sim Airport",
    longitude: 146.927498,
    latitude: -7.750043,
    id: "arp_smj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SMJ",
    city_name: "Sim",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Simara Airport",
    longitude: 84.980162,
    latitude: 27.161454,
    id: "arp_sif_np",
    icao_code: "VNSI",
    iata_country_code: "NP",
    iata_code: "SIF",
    city_name: "Simara",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Simbai Airport",
    longitude: 144.544847,
    latitude: -5.278589,
    id: "arp_sim_pg",
    icao_code: "AYSJ",
    iata_country_code: "PG",
    iata_code: "SIM",
    city_name: "Simbai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Simberi Airport",
    longitude: 151.997777,
    latitude: -2.662222,
    id: "arp_nis_pg",
    icao_code: "AYSE",
    iata_country_code: "PG",
    iata_code: "NIS",
    city_name: "Simberi Island",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Simenti Airport",
    longitude: -13.295513,
    latitude: 13.046593,
    id: "arp_smy_sn",
    icao_code: "GOTS",
    iata_country_code: "SN",
    iata_code: "SMY",
    city_name: "Simenti",
    city: null,
  },
  {
    time_zone: "Europe/Simferopol",
    name: "Simferopol International Airport",
    longitude: 33.996726,
    latitude: 45.019189,
    id: "arp_sip_ua",
    icao_code: "UKFF",
    iata_country_code: "UA",
    iata_code: "SIP",
    city_name: "Simferopol",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Simikot Airport",
    longitude: 81.818781,
    latitude: 29.971089,
    id: "arp_imk_np",
    icao_code: "VNST",
    iata_country_code: "NP",
    iata_code: "IMK",
    city_name: "Simikot",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Simmons Army Airfield",
    longitude: -78.936496,
    latitude: 35.131871,
    id: "arp_fbg_us",
    icao_code: "KFBG",
    iata_country_code: "US",
    iata_code: "FBG",
    city_name: "Fort Bragg",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Simón Bolívar International Airport",
    longitude: -66.99266,
    latitude: 10.601504,
    id: "arp_ccs_ve",
    icao_code: "SVMI",
    iata_country_code: "VE",
    iata_code: "CCS",
    city_name: "Caracas",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Simón Bolívar International Airport",
    longitude: -74.231222,
    latitude: 11.118681,
    id: "arp_smr_co",
    icao_code: "SKSM",
    iata_country_code: "CO",
    iata_code: "SMR",
    city_name: "Santa Marta",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Simon Mwansa Kapwepwe International Airport",
    longitude: 28.663119,
    latitude: -12.99643,
    id: "arp_nla_zm",
    icao_code: "FLND",
    iata_country_code: "ZM",
    iata_code: "NLA",
    city_name: "Ndola",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Sinak Airport",
    longitude: 137.839328,
    latitude: -3.82252,
    id: "arp_nkd_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "NKD",
    city_name: "Sinak",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Sindal Airport",
    longitude: 10.226051,
    latitude: 57.504066,
    id: "arp_cnl_dk",
    icao_code: "EKSN",
    iata_country_code: "DK",
    iata_code: "CNL",
    city_name: "Sindal",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sindhri Airport",
    longitude: 69.075225,
    latitude: 25.684799,
    id: "arp_mpd_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "MPD",
    city_name: "Mirpur Khas",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Sines Airport",
    longitude: -9.135592,
    latitude: 38.774899,
    id: "arp_sie_pt",
    icao_code: "LPSI",
    iata_country_code: "PT",
    iata_code: "SIE",
    city_name: "Sines",
    city: null,
  },
  {
    time_zone: "Asia/Singapore",
    name: "Singapore Changi Airport",
    longitude: 103.985127,
    latitude: 1.356115,
    id: "arp_sin_sg",
    icao_code: "WSSS",
    iata_country_code: "SG",
    iata_code: "SIN",
    city_name: "Singapore",
    city: {
      name: "Singapore",
      id: "cit_sin_sg",
      iata_country_code: "SG",
      iata_code: "SIN",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Singaua Airport",
    longitude: 147.133,
    latitude: -6.7,
    id: "arp_sgb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SGB",
    city_name: "Singaua",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Singita Safari Lodge Airport",
    longitude: 31.422145,
    latitude: -24.803148,
    id: "arp_ssx_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "SSX",
    city_name: "Mpumalanga/Singita Safari Lodge",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Singleton Airport",
    longitude: 151.193272,
    latitude: -32.600604,
    id: "arp_six_au",
    icao_code: "YSGT",
    iata_country_code: "AU",
    iata_code: "SIX",
    city_name: "Singleton",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Sinop Airport",
    longitude: 35.066398,
    latitude: 42.0158,
    id: "arp_nop_tr",
    icao_code: "LTCM",
    iata_country_code: "TR",
    iata_code: "NOP",
    city_name: "Sinop",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Sinop Airport",
    longitude: -55.584651,
    latitude: -11.882867,
    id: "arp_ops_br",
    icao_code: "SWSI",
    iata_country_code: "BR",
    iata_code: "OPS",
    city_name: "Sinop",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Sintang Airport",
    longitude: 111.474385,
    latitude: 0.064042,
    id: "arp_sqg_id",
    icao_code: "WIOS",
    iata_country_code: "ID",
    iata_code: "SQG",
    city_name: "Sintang-Borneo Island",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Siocon Airport",
    longitude: 122.160587,
    latitude: 7.710566,
    id: "arp_xso_ph",
    icao_code: "RPNO",
    iata_country_code: "PH",
    iata_code: "XSO",
    city_name: "Siocon",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Sion Airport",
    longitude: 7.33938,
    latitude: 46.222015,
    id: "arp_sir_ch",
    icao_code: "LSGS",
    iata_country_code: "CH",
    iata_code: "SIR",
    city_name: "Sion",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sioux Falls Regional Airport",
    longitude: -96.739086,
    latitude: 43.581146,
    id: "arp_fsd_us",
    icao_code: "KFSD",
    iata_country_code: "US",
    iata_code: "FSD",
    city_name: "Sioux Falls",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sioux Gateway Airport",
    longitude: -96.384283,
    latitude: 42.402549,
    id: "arp_sux_us",
    icao_code: "KSUX",
    iata_country_code: "US",
    iata_code: "SUX",
    city_name: "Sioux City",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Sioux Lookout Airport",
    longitude: -91.904004,
    latitude: 50.114501,
    id: "arp_yxl_ca",
    icao_code: "CYXL",
    iata_country_code: "CA",
    iata_code: "YXL",
    city_name: "Sioux Lookout",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sipitang Airport",
    longitude: 115.539046,
    latitude: 5.021663,
    id: "arp_spt_my",
    icao_code: null,
    iata_country_code: "MY",
    iata_code: "SPT",
    city_name: "Sipitang",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Sir Bani Yas Airport",
    longitude: 52.581151,
    latitude: 24.2835,
    id: "arp_xsb_ae",
    icao_code: "OMBY",
    iata_country_code: "AE",
    iata_code: "XSB",
    city_name: "Sir Bani Yas Island",
    city: null,
  },
  {
    time_zone: "America/Cayman",
    name: "Sir Captain Charles Kirkconnell International Airport",
    longitude: -79.881107,
    latitude: 19.688534,
    id: "arp_cyb_ky",
    icao_code: "MWCB",
    iata_country_code: "KY",
    iata_code: "CYB",
    city_name: "Cayman Brac",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Sirjan Airport",
    longitude: 55.668843,
    latitude: 29.55075,
    id: "arp_syj_ir",
    icao_code: "OIKY",
    iata_country_code: "IR",
    iata_code: "SYJ",
    city_name: "Sirjan",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Şırnak Airport",
    longitude: 42.058708,
    latitude: 37.364394,
    id: "arp_nkt_tr",
    icao_code: "LTCV",
    iata_country_code: "TR",
    iata_code: "NKT",
    city_name: "Şırnak",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Sirri Island Airport",
    longitude: 54.542661,
    latitude: 25.905714,
    id: "arp_sxi_ir",
    icao_code: "OIBS",
    iata_country_code: "IR",
    iata_code: "SXI",
    city_name: "Sirri Island",
    city: null,
  },
  {
    time_zone: "Indian/Mauritius",
    name: "Sir Seewoosagur Ramgoolam International Airport",
    longitude: 57.681267,
    latitude: -20.431749,
    id: "arp_mru_mu",
    icao_code: "FIMP",
    iata_country_code: "MU",
    iata_code: "MRU",
    city_name: "Port Louis",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Sir Seretse Khama International Airport",
    longitude: 25.918544,
    latitude: -24.555517,
    id: "arp_gbe_bw",
    icao_code: "FBSK",
    iata_country_code: "BW",
    iata_code: "GBE",
    city_name: "Gaborone",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Sishen Airport",
    longitude: 22.999255,
    latitude: -27.649344,
    id: "arp_sis_za",
    icao_code: "FASS",
    iata_country_code: "ZA",
    iata_code: "SIS",
    city_name: "Sishen",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Sisimiut Airport",
    longitude: -53.726289,
    latitude: 66.951429,
    id: "arp_jhs_gl",
    icao_code: "BGSS",
    iata_country_code: "GL",
    iata_code: "JHS",
    city_name: "Sisimiut",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Siskiyou County Airport",
    longitude: -122.472423,
    latitude: 41.780307,
    id: "arp_siy_us",
    icao_code: "KSIY",
    iata_country_code: "US",
    iata_code: "SIY",
    city_name: "Yreka/Montague",
    city: {
      name: "Montague/Yreka",
      id: "cit_siy_us",
      iata_country_code: "US",
      iata_code: "SIY",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sissano Airport",
    longitude: 142.028129,
    latitude: -2.991107,
    id: "arp_siz_pg",
    icao_code: "AYZN",
    iata_country_code: "PG",
    iata_code: "SIZ",
    city_name: "Sissano",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Sitia Public Airport",
    longitude: 26.098979,
    latitude: 35.21659,
    id: "arp_jsh_gr",
    icao_code: "LGST",
    iata_country_code: "GR",
    iata_code: "JSH",
    city_name: "Sitia",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sitiawan Airport",
    longitude: 100.698008,
    latitude: 4.221278,
    id: "arp_swy_my",
    icao_code: "WMBA",
    iata_country_code: "MY",
    iata_code: "SWY",
    city_name: "Sitiawan",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Sitka Rocky Gutierrez Airport",
    longitude: -135.362687,
    latitude: 57.048514,
    id: "arp_sit_us",
    icao_code: "PASI",
    iata_country_code: "US",
    iata_code: "SIT",
    city_name: "Sitka",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Sittwe Airport",
    longitude: 92.872822,
    latitude: 20.133239,
    id: "arp_aky_mm",
    icao_code: "VYSW",
    iata_country_code: "MM",
    iata_code: "AKY",
    city_name: "Sittwe",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Siuna Airport",
    longitude: -84.777355,
    latitude: 13.728742,
    id: "arp_siu_ni",
    icao_code: "MNSI",
    iata_country_code: "NI",
    iata_code: "SIU",
    city_name: "Siuna",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Sivas Nuri Demirag Airport",
    longitude: 36.893803,
    latitude: 39.802141,
    id: "arp_vas_tr",
    icao_code: "LTAR",
    iata_country_code: "TR",
    iata_code: "VAS",
    city_name: "Sivas",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Siwa Oasis North Airport",
    longitude: 25.506593,
    latitude: 29.34508,
    id: "arp_sew_eg",
    icao_code: null,
    iata_country_code: "EG",
    iata_code: "SEW",
    city_name: "Siwa Oasis",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Siwea Airport",
    longitude: 147.56774,
    latitude: -6.284845,
    id: "arp_swe_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SWE",
    city_name: "Siwea",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Siwo Airport",
    longitude: 168.343002,
    latitude: -17.090299,
    id: "arp_eae_vu",
    icao_code: "NVSE",
    iata_country_code: "VU",
    iata_code: "EAE",
    city_name: "Emae Island",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Skagit Regional Airport",
    longitude: -122.420884,
    latitude: 48.470432,
    id: "arp_mvw_us",
    icao_code: "KBVS",
    iata_country_code: "US",
    iata_code: "MVW",
    city_name: "Mt Vernon/Burlington",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Skagway Airport",
    longitude: -135.319094,
    latitude: 59.458204,
    id: "arp_sgy_us",
    icao_code: "PAGY",
    iata_country_code: "US",
    iata_code: "SGY",
    city_name: "Skagway",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Skardu Airport",
    longitude: 75.538696,
    latitude: 35.337471,
    id: "arp_kdu_pk",
    icao_code: "OPSD",
    iata_country_code: "PK",
    iata_code: "KDU",
    city_name: "Skardu",
    city: null,
  },
  {
    time_zone: "America/Guyana",
    name: "Skeldon Airport",
    longitude: -57.149643,
    latitude: 5.858633,
    id: "arp_skm_gy",
    icao_code: "SYSK",
    iata_country_code: "GY",
    iata_code: "SKM",
    city_name: "Skeldon",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Skellefteå Airport",
    longitude: 21.074687,
    latitude: 64.624405,
    id: "arp_sft_se",
    icao_code: "ESNS",
    iata_country_code: "SE",
    iata_code: "SFT",
    city_name: "Skellefteå",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Skiathos Island National Airport",
    longitude: 23.50241,
    latitude: 39.176828,
    id: "arp_jsi_gr",
    icao_code: "LGSK",
    iata_country_code: "GR",
    iata_code: "JSI",
    city_name: "Skiathos",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Skien Airport",
    longitude: 9.567005,
    latitude: 59.184885,
    id: "arp_ske_no",
    icao_code: "ENSN",
    iata_country_code: "NO",
    iata_code: "SKE",
    city_name: "Skien",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Skikda Airport",
    longitude: 6.950933,
    latitude: 36.862216,
    id: "arp_ski_dz",
    icao_code: "DABP",
    iata_country_code: "DZ",
    iata_code: "SKI",
    city_name: "Skikda",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Skive Airport",
    longitude: 9.168632,
    latitude: 56.55186,
    id: "arp_sqw_dk",
    icao_code: "EKSV",
    iata_country_code: "DK",
    iata_code: "SQW",
    city_name: "Skive",
    city: null,
  },
  {
    time_zone: "Europe/Skopje",
    name: "Skopje International Airport",
    longitude: 21.623086,
    latitude: 41.96181,
    id: "arp_skp_mk",
    icao_code: "LWSK",
    iata_country_code: "MK",
    iata_code: "SKP",
    city_name: "Skopje",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Skorpion Mine Airport",
    longitude: 16.647369,
    latitude: -27.875332,
    id: "arp_rhn_na",
    icao_code: "FYSA",
    iata_country_code: "NA",
    iata_code: "RHN",
    city_name: "Rosh Pinah",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Skövde Airport",
    longitude: 13.971308,
    latitude: 58.455288,
    id: "arp_kvb_se",
    icao_code: "ESGR",
    iata_country_code: "SE",
    iata_code: "KVB",
    city_name: "Skövde",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Skukuza Airport",
    longitude: 31.589303,
    latitude: -24.962006,
    id: "arp_szk_za",
    icao_code: "FASZ",
    iata_country_code: "ZA",
    iata_code: "SZK",
    city_name: "Skukuza",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Skwentna Airport",
    longitude: -151.18873,
    latitude: 61.965739,
    id: "arp_skw_us",
    icao_code: "PASW",
    iata_country_code: "US",
    iata_code: "SKW",
    city_name: "Skwentna",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Skye Bridge Ashaig Airport",
    longitude: -5.828211,
    latitude: 57.2533,
    id: "arp_skl_gb",
    icao_code: "EGEI",
    iata_country_code: "GB",
    iata_code: "SKL",
    city_name: "Skye",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Skylark Field",
    longitude: -97.685985,
    latitude: 31.086572,
    id: "arp_ile_us",
    icao_code: "KILE",
    iata_country_code: "US",
    iata_code: "ILE",
    city_name: "Killeen",
    city: {
      name: "Killeen/Fort Hood",
      id: "cit_ile_us",
      iata_country_code: "US",
      iata_code: "ILE",
    },
  },
  {
    time_zone: "America/Denver",
    name: "Skypark Airport",
    longitude: -111.928072,
    latitude: 40.868654,
    id: "arp_btf_us",
    icao_code: "KBTF",
    iata_country_code: "US",
    iata_code: "BTF",
    city_name: "Bountiful",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Skyros Island National Airport",
    longitude: 24.486124,
    latitude: 38.966611,
    id: "arp_sku_gr",
    icao_code: "LGSY",
    iata_country_code: "GR",
    iata_code: "SKU",
    city_name: "Skyros",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Slave Lake Airport",
    longitude: -114.776354,
    latitude: 55.29319,
    id: "arp_yzh_ca",
    icao_code: "CYZH",
    iata_country_code: "CA",
    iata_code: "YZH",
    city_name: "Slave Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Slayton Municipal Airport",
    longitude: -101.66171,
    latitude: 33.483602,
    id: "arp_nsl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "NSL",
    city_name: "Slayton",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Sleetmute Airport",
    longitude: -157.166077,
    latitude: 61.700565,
    id: "arp_slq_us",
    icao_code: "PASL",
    iata_country_code: "US",
    iata_code: "SLQ",
    city_name: "Sleetmute",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Sliač Airport",
    longitude: 19.134133,
    latitude: 48.637927,
    id: "arp_sld_sk",
    icao_code: "LZSL",
    iata_country_code: "SK",
    iata_code: "SLD",
    city_name: "Sliac",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Sligo Airport",
    longitude: -8.599088,
    latitude: 54.279707,
    id: "arp_sxl_ie",
    icao_code: "EISG",
    iata_country_code: "IE",
    iata_code: "SXL",
    city_name: "Sligo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sloulin Field International Airport",
    longitude: -103.639366,
    latitude: 48.177634,
    id: "arp_isn_us",
    icao_code: "KISN",
    iata_country_code: "US",
    iata_code: "ISN",
    city_name: "Williston",
    city: null,
  },
  {
    time_zone: "Africa/El_Aaiun",
    name: "Smara Airport",
    longitude: -11.684668,
    latitude: 26.734911,
    id: "arp_smw_eh",
    icao_code: "GMMA",
    iata_country_code: "EH",
    iata_code: "SMW",
    city_name: "Smara",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Smithers Airport",
    longitude: -127.183596,
    latitude: 54.823991,
    id: "arp_yyd_ca",
    icao_code: "CYYD",
    iata_country_code: "CA",
    iata_code: "YYD",
    city_name: "Smithers",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Smith Field",
    longitude: -94.491248,
    latitude: 36.190315,
    id: "arp_slg_us",
    icao_code: "KSLG",
    iata_country_code: "US",
    iata_code: "SLG",
    city_name: "Siloam Springs",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Smith Field",
    longitude: -85.152431,
    latitude: 41.142623,
    id: "arp_smd_us",
    icao_code: "KSMD",
    iata_country_code: "US",
    iata_code: "SMD",
    city_name: "Fort Wayne",
    city: {
      name: "Fort Wayne",
      id: "cit_fwa_us",
      iata_country_code: "US",
      iata_code: "FWA",
    },
  },
  {
    time_zone: "Australia/Darwin",
    name: "Smith Point Airport",
    longitude: 132.157273,
    latitude: -11.132201,
    id: "arp_shu_au",
    icao_code: "YSMP",
    iata_country_code: "AU",
    iata_code: "SHU",
    city_name: "Smith Point",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Smith Reynolds Airport",
    longitude: -80.223613,
    latitude: 36.134945,
    id: "arp_int_us",
    icao_code: "KINT",
    iata_country_code: "US",
    iata_code: "INT",
    city_name: "Winston-Salem",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Smiths Falls-Montague Airport",
    longitude: -75.939669,
    latitude: 44.945971,
    id: "arp_ysh_ca",
    icao_code: "CYSH",
    iata_country_code: "CA",
    iata_code: "YSH",
    city_name: "Smiths Falls",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "Smithton Airport",
    longitude: 145.083223,
    latitude: -40.835277,
    id: "arp_sio_au",
    icao_code: "YSMI",
    iata_country_code: "AU",
    iata_code: "SIO",
    city_name: "Smithton",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Smolensk North Airport",
    longitude: 32.025584,
    latitude: 54.82379,
    id: "arp_lnx_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "LNX",
    city_name: "Smolensk",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Smyrna Airport",
    longitude: -86.522976,
    latitude: 36.006659,
    id: "arp_mqy_us",
    icao_code: "KMQY",
    iata_country_code: "US",
    iata_code: "MQY",
    city_name: "Smyrna",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Snake Bay Airport",
    longitude: 130.653417,
    latitude: -11.422779,
    id: "arp_snb_au",
    icao_code: "YSNB",
    iata_country_code: "AU",
    iata_code: "SNB",
    city_name: "Milikapiti",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Snap Lake Airport",
    longitude: -110.905792,
    latitude: 63.593624,
    id: "arp_ynx_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YNX",
    city_name: "Snap Lake",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Soalala Airport",
    longitude: 45.35993,
    latitude: -16.102003,
    id: "arp_dwb_mg",
    icao_code: "FMNO",
    iata_country_code: "MG",
    iata_code: "DWB",
    city_name: "Soalala",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Sochi International Airport",
    longitude: 39.941688,
    latitude: 43.447763,
    id: "arp_aer_ru",
    icao_code: "URSS",
    iata_country_code: "RU",
    iata_code: "AER",
    city_name: "Sochi",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Socorro Municipal Airport",
    longitude: -106.901401,
    latitude: 34.022947,
    id: "arp_onm_us",
    icao_code: "KONM",
    iata_country_code: "US",
    iata_code: "ONM",
    city_name: "Socorro",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Socotra Airport",
    longitude: 53.906917,
    latitude: 12.629678,
    id: "arp_sct_ye",
    icao_code: "OYSQ",
    iata_country_code: "YE",
    iata_code: "SCT",
    city_name: "Socotra",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Sócrates Mariani Bittencourt Airport",
    longitude: -41.819685,
    latitude: -14.25695,
    id: "arp_bms_br",
    icao_code: "SNBU",
    iata_country_code: "BR",
    iata_code: "BMS",
    city_name: "Brumado",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Sócrates Rezende Airport",
    longitude: -38.957398,
    latitude: -15.665304,
    id: "arp_cnv_br",
    icao_code: "SNED",
    iata_country_code: "BR",
    iata_code: "CNV",
    city_name: "Canavieiras",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Sóc Trăng Airport",
    longitude: 105.959047,
    latitude: 9.579849,
    id: "arp_soa_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "SOA",
    city_name: "Sóc Trăng",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Sodankyla Airport",
    longitude: 26.618752,
    latitude: 67.395332,
    id: "arp_sot_fi",
    icao_code: "EFSO",
    iata_country_code: "FI",
    iata_code: "SOT",
    city_name: "Sodankyla",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Soddu Airport",
    longitude: 37.590453,
    latitude: 6.039389,
    id: "arp_sxu_et",
    icao_code: "HASD",
    iata_country_code: "ET",
    iata_code: "SXU",
    city_name: "Sodo",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Söderhamn Airport",
    longitude: 17.0991,
    latitude: 61.261501,
    id: "arp_soo_se",
    icao_code: "ESNY",
    iata_country_code: "SE",
    iata_code: "SOO",
    city_name: "Soderhamn",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Soekarno-Hatta International Airport",
    longitude: 106.654847,
    latitude: -6.126544,
    id: "arp_cgk_id",
    icao_code: "WIII",
    iata_country_code: "ID",
    iata_code: "CGK",
    city_name: "Jakarta",
    city: {
      name: "Jakarta",
      id: "cit_jkt_id",
      iata_country_code: "ID",
      iata_code: "JKT",
    },
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Soesterberg Air Base",
    longitude: 5.274939,
    latitude: 52.127999,
    id: "arp_utc_nl",
    icao_code: "EHSB",
    iata_country_code: "NL",
    iata_code: "UTC",
    city_name: "Utrecht",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Soewondo Air Force Base",
    longitude: 98.672737,
    latitude: 3.560397,
    id: "arp_mes_id",
    icao_code: "WIMK",
    iata_country_code: "ID",
    iata_code: "MES",
    city_name: "Medan",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Sofia Airport",
    longitude: 23.411529,
    latitude: 42.693719,
    id: "arp_sof_bg",
    icao_code: "LBSF",
    iata_country_code: "BG",
    iata_code: "SOF",
    city_name: "Sofia",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Sogndal Haukasen Airport",
    longitude: 7.137778,
    latitude: 61.156106,
    id: "arp_sog_no",
    icao_code: "ENSG",
    iata_country_code: "NO",
    iata_code: "SOG",
    city_name: "Sogndal",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Sohag International Airport",
    longitude: 31.741092,
    latitude: 26.341542,
    id: "arp_hmb_eg",
    icao_code: "HESG",
    iata_country_code: "EG",
    iata_code: "HMB",
    city_name: "Sohag",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Sohar Airport",
    longitude: 56.623782,
    latitude: 24.387384,
    id: "arp_ohs_om",
    icao_code: "OOSH",
    iata_country_code: "OM",
    iata_code: "OHS",
    city_name: "Sohar",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Soko Airport",
    longitude: -2.764037,
    latitude: 8.012262,
    id: "arp_bdk_ci",
    icao_code: "DIBU",
    iata_country_code: "CI",
    iata_code: "BDK",
    city_name: "Bondoukou",
    city: null,
  },
  {
    time_zone: "Asia/Magadan",
    name: "Sokol Airport",
    longitude: 150.725075,
    latitude: 59.912082,
    id: "arp_gdx_ru",
    icao_code: "UHMM",
    iata_country_code: "RU",
    iata_code: "GDX",
    city_name: "Magadan",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Sola Airport",
    longitude: -84.51977,
    latitude: 45.653165,
    id: "arp_slh_us",
    icao_code: "NVSC",
    iata_country_code: "US",
    iata_code: "SLH",
    city_name: "Cheboygan",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Solano Airport",
    longitude: -75.95346,
    latitude: 4.76084,
    id: "arp_sqf_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SQF",
    city_name: "Solano",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Solapur Airport",
    longitude: 75.934105,
    latitude: 17.626871,
    id: "arp_sse_in",
    icao_code: "VASL",
    iata_country_code: "IN",
    iata_code: "SSE",
    city_name: "Solapur",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Soldotna Airport",
    longitude: -151.03384,
    latitude: 60.476761,
    id: "arp_sxq_us",
    icao_code: "PASX",
    iata_country_code: "US",
    iata_code: "SXQ",
    city_name: "Soldotna",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Solenzara Airport",
    longitude: 9.406,
    latitude: 41.9244,
    id: "arp_soz_fr",
    icao_code: "LFKS",
    iata_country_code: "FR",
    iata_code: "SOZ",
    city_name: "Solenzara",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Solidarity Szczecin–Goleniów Airport",
    longitude: 14.90249,
    latitude: 53.585289,
    id: "arp_szz_pl",
    icao_code: "EPSC",
    iata_country_code: "PL",
    iata_code: "SZZ",
    city_name: "Szczecin",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Solita Airport",
    longitude: -71.75,
    latitude: 34.2333,
    id: "arp_soh_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "SOH",
    city_name: "Solita",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Solomon Airport",
    longitude: 117.848963,
    latitude: -22.25424,
    id: "arp_slj_au",
    icao_code: "YSOL",
    iata_country_code: "AU",
    iata_code: "SLJ",
    city_name: "Solomon",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Solomon State Field",
    longitude: -164.446097,
    latitude: 64.560136,
    id: "arp_sol_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SOL",
    city_name: "Solomon",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Solovki Airport",
    longitude: 35.73004,
    latitude: 65.029966,
    id: "arp_csh_ru",
    icao_code: "ULAS",
    iata_country_code: "RU",
    iata_code: "CSH",
    city_name: "Solovetsky Islands",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Solwezi Airport",
    longitude: 26.367176,
    latitude: -12.173339,
    id: "arp_sli_zm",
    icao_code: "FLSW",
    iata_country_code: "ZM",
    iata_code: "SLI",
    city_name: "Solwezi",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Sonari Airport",
    longitude: 86.169716,
    latitude: 22.813718,
    id: "arp_ixw_in",
    icao_code: "VEJS",
    iata_country_code: "IN",
    iata_code: "IXW",
    city_name: "Jamshedpur",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Sønderborg Airport",
    longitude: 9.790265,
    latitude: 54.964066,
    id: "arp_sgd_dk",
    icao_code: "EKSB",
    iata_country_code: "DK",
    iata_code: "SGD",
    city_name: "Sønderborg",
    city: null,
  },
  {
    time_zone: "Asia/Pyongyang",
    name: "Sondok Airport",
    longitude: 127.474003,
    latitude: 39.748905,
    id: "arp_dso_kp",
    icao_code: "ZKSD",
    iata_country_code: "KP",
    iata_code: "DSO",
    city_name: "Sondok",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Songea Airport",
    longitude: 35.583048,
    latitude: -10.682779,
    id: "arp_sgx_tz",
    icao_code: "HTSO",
    iata_country_code: "TZ",
    iata_code: "SGX",
    city_name: "Songea",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Songkhla Airport",
    longitude: 100.607427,
    latitude: 7.186509,
    id: "arp_sgz_th",
    icao_code: "VTSH",
    iata_country_code: "TH",
    iata_code: "SGZ",
    city_name: "Songkhla",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Songyuan Chaganhu Airport",
    longitude: 124.550178,
    latitude: 44.938114,
    id: "arp_ysq_cn",
    icao_code: "ZYSQ",
    iata_country_code: "CN",
    iata_code: "YSQ",
    city_name: "Songyuan",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sopu Airport",
    longitude: 147.166007,
    latitude: -8.303766,
    id: "arp_sph_pg",
    icao_code: "AYQO",
    iata_country_code: "PG",
    iata_code: "SPH",
    city_name: "Sopu",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Sørkjosen Airport",
    longitude: 20.952516,
    latitude: 69.785564,
    id: "arp_soj_no",
    icao_code: "ENSR",
    iata_country_code: "NO",
    iata_code: "SOJ",
    city_name: "Sørkjosen",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Soroako Airport",
    longitude: 121.357916,
    latitude: -2.531129,
    id: "arp_sqr_id",
    icao_code: "WAWS",
    iata_country_code: "ID",
    iata_code: "SQR",
    city_name: "Soroako",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Sorocaba Airport",
    longitude: -47.489597,
    latitude: -23.479849,
    id: "arp_sod_br",
    icao_code: "SDCO",
    iata_country_code: "BR",
    iata_code: "SOD",
    city_name: "Sorocaba",
    city: null,
  },
  {
    time_zone: "Africa/Kampala",
    name: "Soroti Airport",
    longitude: 33.621576,
    latitude: 1.725549,
    id: "arp_srt_ug",
    icao_code: "HUSO",
    iata_country_code: "UG",
    iata_code: "SRT",
    city_name: "Soroti",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Sorriso Airport",
    longitude: -55.673357,
    latitude: -12.479403,
    id: "arp_smt_br",
    icao_code: "SBSO",
    iata_country_code: "BR",
    iata_code: "SMT",
    city_name: "Sorriso",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Soto Cano Air Base Airport",
    longitude: -87.621169,
    latitude: 14.382217,
    id: "arp_xpl_hn",
    icao_code: "MHSC",
    iata_country_code: "HN",
    iata_code: "XPL",
    city_name: "Comayagua",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Souanké Airport",
    longitude: 14.132541,
    latitude: 2.062869,
    id: "arp_soe_cg",
    icao_code: "FCOS",
    iata_country_code: "CG",
    iata_code: "SOE",
    city_name: "Souanke",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Soummam – Abane Ramdane Airport",
    longitude: 5.070376,
    latitude: 36.713127,
    id: "arp_bja_dz",
    icao_code: "DAAE",
    iata_country_code: "DZ",
    iata_code: "BJA",
    city_name: "Béjaïa",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Soure Airport",
    longitude: -48.521614,
    latitude: -0.6987,
    id: "arp_sfk_br",
    icao_code: "SNSW",
    iata_country_code: "BR",
    iata_code: "SFK",
    city_name: "Soure",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Southampton Airport",
    longitude: -1.357251,
    latitude: 50.950877,
    id: "arp_sou_gb",
    icao_code: "EGHI",
    iata_country_code: "GB",
    iata_code: "SOU",
    city_name: "Southampton",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "South Andros Airport",
    longitude: -77.583817,
    latitude: 24.157182,
    id: "arp_tzn_bs",
    icao_code: "MYAK",
    iata_country_code: "BS",
    iata_code: "TZN",
    city_name: "South Andros",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "South Arkansas Regional Airport",
    longitude: -92.812497,
    latitude: 33.220422,
    id: "arp_eld_us",
    icao_code: "KELD",
    iata_country_code: "US",
    iata_code: "ELD",
    city_name: "El Dorado",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "South Bend International Airport",
    longitude: -86.317329,
    latitude: 41.706259,
    id: "arp_sbn_us",
    icao_code: "KSBN",
    iata_country_code: "US",
    iata_code: "SBN",
    city_name: "South Bend",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "South Big Horn County Airport",
    longitude: -108.081187,
    latitude: 44.516891,
    id: "arp_gey_us",
    icao_code: "KGEY",
    iata_country_code: "US",
    iata_code: "GEY",
    city_name: "Greybull",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "South Bimini Airport",
    longitude: -79.267334,
    latitude: 25.700387,
    id: "arp_bim_bs",
    icao_code: "MYBS",
    iata_country_code: "BS",
    iata_code: "BIM",
    city_name: "Bimini",
    city: null,
  },
  {
    time_zone: "America/Grand_Turk",
    name: "South Caicos Airport",
    longitude: -71.531179,
    latitude: 21.515412,
    id: "arp_xsc_tc",
    icao_code: "MBSC",
    iata_country_code: "TC",
    iata_code: "XSC",
    city_name: "South Caicos",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "South Cariboo Regional Airport",
    longitude: -121.332926,
    latitude: 51.736292,
    id: "arp_zmh_ca",
    icao_code: "CZML",
    iata_country_code: "CA",
    iata_code: "ZMH",
    city_name: "108 Mile Ranch",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Southdowns Airport",
    longitude: 28.149811,
    latitude: -12.900528,
    id: "arp_kiw_zm",
    icao_code: "FLSO",
    iata_country_code: "ZM",
    iata_code: "KIW",
    city_name: "Kitwe",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Southeast Iowa Regional Airport",
    longitude: -91.123847,
    latitude: 40.783523,
    id: "arp_brl_us",
    icao_code: "KBRL",
    iata_country_code: "US",
    iata_code: "BRL",
    city_name: "Burlington",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Southern California Logistics Airport",
    longitude: -117.380021,
    latitude: 34.584817,
    id: "arp_vcv_us",
    icao_code: "KVCV",
    iata_country_code: "US",
    iata_code: "VCV",
    city_name: "Victorville",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Southern Cross Airport",
    longitude: 119.359032,
    latitude: -31.239181,
    id: "arp_sqc_au",
    icao_code: "YSCR",
    iata_country_code: "AU",
    iata_code: "SQC",
    city_name: "Southern Cross",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Southern Illinois Airport",
    longitude: -89.252681,
    latitude: 37.777411,
    id: "arp_mdh_us",
    icao_code: "KMDH",
    iata_country_code: "US",
    iata_code: "MDH",
    city_name: "Carbondale/Murphysboro",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Southern Seaplane Airport",
    longitude: -90.021488,
    latitude: 29.866394,
    id: "arp_bcs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BCS",
    city_name: "Belle Chasse",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Southern Wisconsin Regional Airport",
    longitude: -89.041593,
    latitude: 42.619107,
    id: "arp_jvl_us",
    icao_code: "KJVL",
    iata_country_code: "US",
    iata_code: "JVL",
    city_name: "Janesville",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "South Galway Airport",
    longitude: 142.091274,
    latitude: -25.674998,
    id: "arp_zgl_au",
    icao_code: "YSGW",
    iata_country_code: "AU",
    iata_code: "ZGL",
    city_name: "South Galway",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "South Goulburn Island Airport",
    longitude: 133.378961,
    latitude: -11.650274,
    id: "arp_gbl_au",
    icao_code: "YGBI",
    iata_country_code: "AU",
    iata_code: "GBL",
    city_name: "South Goulburn Island",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "South Indian Lake Airport",
    longitude: -98.907611,
    latitude: 56.793197,
    id: "arp_xsi_ca",
    icao_code: "CZSN",
    iata_country_code: "CA",
    iata_code: "XSI",
    city_name: "South Indian Lake",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "South Jersey Regional Airport",
    longitude: -74.845204,
    latitude: 39.94287,
    id: "arp_lly_us",
    icao_code: "KVAY",
    iata_country_code: "US",
    iata_code: "LLY",
    city_name: "Mount Holly",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "South Lewis County Airport",
    longitude: -122.806725,
    latitude: 46.477094,
    id: "arp_tdo_us",
    icao_code: "KTDO",
    iata_country_code: "US",
    iata_code: "TDO",
    city_name: "Toledo",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "South Naknek Airport",
    longitude: -157.006484,
    latitude: 58.702718,
    id: "arp_wsn_us",
    icao_code: "PFWS",
    iata_country_code: "US",
    iata_code: "WSN",
    city_name: "South Naknek",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Southport Airport",
    longitude: 153.371452,
    latitude: -27.922995,
    id: "arp_shq_au",
    icao_code: "YSPT",
    iata_country_code: "AU",
    iata_code: "SHQ",
    city_name: "Southport",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "South West Bay Airport",
    longitude: 167.4472,
    latitude: -16.4864,
    id: "arp_swj_vu",
    icao_code: "NVSX",
    iata_country_code: "VU",
    iata_code: "SWJ",
    city_name: "South West Bay,",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Southwest Florida International Airport",
    longitude: -81.755255,
    latitude: 26.534952,
    id: "arp_rsw_us",
    icao_code: "KRSW",
    iata_country_code: "US",
    iata_code: "RSW",
    city_name: "Fort Myers",
    city: {
      name: "Fort Myers",
      id: "cit_fmy_us",
      iata_country_code: "US",
      iata_code: "FMY",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Southwest Georgia Regional Airport",
    longitude: -84.194198,
    latitude: 31.535197,
    id: "arp_aby_us",
    icao_code: "KABY",
    iata_country_code: "US",
    iata_code: "ABY",
    city_name: "Albany",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Southwest Michigan Regional Airport",
    longitude: -86.427814,
    latitude: 42.128336,
    id: "arp_beh_us",
    icao_code: "KBEH",
    iata_country_code: "US",
    iata_code: "BEH",
    city_name: "Benton Harbor",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Southwest Minnesota Regional Airport",
    longitude: -95.820945,
    latitude: 44.447003,
    id: "arp_mml_us",
    icao_code: "KMML",
    iata_country_code: "US",
    iata_code: "MML",
    city_name: "Marshall",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Southwest Oregon Regional Airport",
    longitude: -124.247031,
    latitude: 43.417462,
    id: "arp_oth_us",
    icao_code: "KOTH",
    iata_country_code: "US",
    iata_code: "OTH",
    city_name: "North Bend",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Southwest Washington Regional Airport",
    longitude: -122.897546,
    latitude: 46.118603,
    id: "arp_kls_us",
    icao_code: "KKLS",
    iata_country_code: "US",
    iata_code: "KLS",
    city_name: "Kelso",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "South Wood County Airport",
    longitude: -89.837503,
    latitude: 44.360709,
    id: "arp_isw_us",
    icao_code: "KISW",
    iata_country_code: "US",
    iata_code: "ISW",
    city_name: "Wisconsin Rapids",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Sovetsky Airport",
    longitude: 63.600308,
    latitude: 61.327408,
    id: "arp_ovs_ru",
    icao_code: "USHS",
    iata_country_code: "RU",
    iata_code: "OVS",
    city_name: "Sovetsky",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Soyo Airport",
    longitude: 12.3715,
    latitude: -6.141041,
    id: "arp_sza_ao",
    icao_code: "FNSO",
    iata_country_code: "AO",
    iata_code: "SZA",
    city_name: "Soyo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Space Coast Regional Airport",
    longitude: -80.797191,
    latitude: 28.513252,
    id: "arp_tix_us",
    icao_code: "KTIX",
    iata_country_code: "US",
    iata_code: "TIX",
    city_name: "Titusville",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Spangdahlem Air Base",
    longitude: 6.694722,
    latitude: 49.973373,
    id: "arp_spm_de",
    icao_code: "ETAD",
    iata_country_code: "DE",
    iata_code: "SPM",
    city_name: "Spangdahlem",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Sparrevohn LRRS Airport",
    longitude: -155.57501,
    latitude: 61.100225,
    id: "arp_svw_us",
    icao_code: "PASV",
    iata_country_code: "US",
    iata_code: "SVW",
    city_name: "Sparrevohn",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sparta Community-Hunter Field Airport",
    longitude: -89.700148,
    latitude: 38.147464,
    id: "arp_sar_us",
    icao_code: "KSAR",
    iata_country_code: "US",
    iata_code: "SAR",
    city_name: "Sparta",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sparta/Fort McCoy Airport",
    longitude: -90.735681,
    latitude: 43.95601,
    id: "arp_cmy_us",
    icao_code: "KCMY",
    iata_country_code: "US",
    iata_code: "CMY",
    city_name: "Sparta",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Spartanburg Downtown Memorial Airport",
    longitude: -81.956802,
    latitude: 34.916395,
    id: "arp_spa_us",
    icao_code: "KSPA",
    iata_country_code: "US",
    iata_code: "SPA",
    city_name: "Spartanburg",
    city: {
      name: "Greenville/Spartanburg",
      id: "cit_gsp_us",
      iata_country_code: "US",
      iata_code: "GSP",
    },
  },
  {
    time_zone: "Europe/Athens",
    name: "Sparti Airport",
    longitude: 22.525983,
    latitude: 36.973693,
    id: "arp_spj_gr",
    icao_code: "LGSP",
    iata_country_code: "GR",
    iata_code: "SPJ",
    city_name: "Sparta",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Spence Airport",
    longitude: -83.713106,
    latitude: 31.139945,
    id: "arp_mul_us",
    icao_code: "KMUL",
    iata_country_code: "US",
    iata_code: "MUL",
    city_name: "Moultrie",
    city: {
      name: "Moultrie",
      id: "cit_mgr_us",
      iata_country_code: "US",
      iata_code: "MGR",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Spencer Municipal Airport",
    longitude: -95.202937,
    latitude: 43.165195,
    id: "arp_spw_us",
    icao_code: "KSPW",
    iata_country_code: "US",
    iata_code: "SPW",
    city_name: "Spencer",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Sphinx International Airport",
    longitude: 30.910862,
    latitude: 30.117338,
    id: "arp_spx_eg",
    icao_code: null,
    iata_country_code: "EG",
    iata_code: "SPX",
    city_name: "Giza",
    city: null,
  },
  {
    time_zone: "Asia/Novokuznetsk",
    name: "Spichenkovo Airport",
    longitude: 86.864944,
    latitude: 53.803909,
    id: "arp_noz_ru",
    icao_code: "UNWW",
    iata_country_code: "RU",
    iata_code: "NOZ",
    city_name: "Novokuznetsk",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Spirit Lake Municipal Airport",
    longitude: -95.139334,
    latitude: 43.387354,
    id: "arp_rtl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "RTL",
    city_name: "Spirit Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Spirit of St Louis Airport",
    longitude: -90.650823,
    latitude: 38.663194,
    id: "arp_sus_us",
    icao_code: "KSUS",
    iata_country_code: "US",
    iata_code: "SUS",
    city_name: "St Louis",
    city: {
      name: "St Louis",
      id: "cit_stl_us",
      iata_country_code: "US",
      iata_code: "STL",
    },
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Split Airport",
    longitude: 16.298067,
    latitude: 43.538807,
    id: "arp_spu_hr",
    icao_code: "LDSP",
    iata_country_code: "HR",
    iata_code: "SPU",
    city_name: "Split",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Spokane International Airport",
    longitude: -117.534404,
    latitude: 47.620823,
    id: "arp_geg_us",
    icao_code: "KGEG",
    iata_country_code: "US",
    iata_code: "GEG",
    city_name: "Spokane",
    city: {
      name: "Spokane",
      id: "cit_geg_us",
      iata_country_code: "US",
      iata_code: "GEG",
    },
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Spriggs Payne Airport",
    longitude: -10.758577,
    latitude: 6.289188,
    id: "arp_mlw_lr",
    icao_code: "GLMR",
    iata_country_code: "LR",
    iata_code: "MLW",
    city_name: "Monrovia",
    city: {
      name: "Monrovia",
      id: "cit_mlw_lr",
      iata_country_code: "LR",
      iata_code: "MLW",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Springbok Airport",
    longitude: 17.941884,
    latitude: -29.68979,
    id: "arp_sbu_za",
    icao_code: "FASB",
    iata_country_code: "ZA",
    iata_code: "SBU",
    city_name: "Springbok",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Spring Creek Airport",
    longitude: 144.546674,
    latitude: -18.638608,
    id: "arp_scg_au",
    icao_code: "YSPK",
    iata_country_code: "AU",
    iata_code: "SCG",
    city_name: "Spring Creek",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Springdale Municipal Airport",
    longitude: -94.120556,
    latitude: 36.175218,
    id: "arp_spz_us",
    icao_code: "KASG",
    iata_country_code: "US",
    iata_code: "SPZ",
    city_name: "Springdale",
    city: {
      name: "Fayetteville/Springd.",
      id: "cit_fyv_us",
      iata_country_code: "US",
      iata_code: "FYV",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Springfield-Beckley Municipal Airport",
    longitude: -83.841088,
    latitude: 39.841005,
    id: "arp_sgh_us",
    icao_code: "KSGH",
    iata_country_code: "US",
    iata_code: "SGH",
    city_name: "Springfield",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Springfield-Branson National Airport",
    longitude: -93.388678,
    latitude: 37.24524,
    id: "arp_sgf_us",
    icao_code: "KSGF",
    iata_country_code: "US",
    iata_code: "SGF",
    city_name: "Springfield",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Spring Point Airport",
    longitude: -73.97078,
    latitude: 22.441923,
    id: "arp_axp_bs",
    icao_code: "MYAP",
    iata_country_code: "BS",
    iata_code: "AXP",
    city_name: "Spring Point",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Springvale Airport",
    longitude: 140.701768,
    latitude: -23.546297,
    id: "arp_ksv_au",
    icao_code: "YSPV",
    iata_country_code: "AU",
    iata_code: "KSV",
    city_name: "Springvale",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Springvale Airport",
    longitude: 127.669999,
    latitude: -17.786929,
    id: "arp_zvg_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "ZVG",
    city_name: "Springvale",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Squamish Airport",
    longitude: -123.162002,
    latitude: 49.7817,
    id: "arp_yse_ca",
    icao_code: "CYSE",
    iata_country_code: "CA",
    iata_code: "YSE",
    city_name: "Squamish",
    city: null,
  },
  {
    time_zone: "Asia/Srednekolymsk",
    name: "Srednekolymsk Airport",
    longitude: 153.7364,
    latitude: 67.4805,
    id: "arp_sek_ru",
    icao_code: "UESK",
    iata_country_code: "RU",
    iata_code: "SEK",
    city_name: "Srednekolymsk",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Sri Guru Ram Dass Jee International Airport",
    longitude: 74.802036,
    latitude: 31.707601,
    id: "arp_atq_in",
    icao_code: "VIAR",
    iata_country_code: "IN",
    iata_code: "ATQ",
    city_name: "Amritsar",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Srinagar Airport",
    longitude: 74.761995,
    latitude: 34.001277,
    id: "arp_sxr_in",
    icao_code: "VISR",
    iata_country_code: "IN",
    iata_code: "SXR",
    city_name: "Srinagar",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Sri Sathya Sai Airport",
    longitude: 77.791099,
    latitude: 14.149299,
    id: "arp_put_in",
    icao_code: "VOPN",
    iata_country_code: "IN",
    iata_code: "PUT",
    city_name: "Puttaparthi",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Stafsberg Airport",
    longitude: 11.068834,
    latitude: 60.817768,
    id: "arp_hmr_no",
    icao_code: "ENHA",
    iata_country_code: "NO",
    iata_code: "HMR",
    city_name: "Hamar",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "St Angelo Airport",
    longitude: -7.651024,
    latitude: 54.398351,
    id: "arp_enk_gb",
    icao_code: "EGAB",
    iata_country_code: "GB",
    iata_code: "ENK",
    city_name: "Enniskillen",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Staniel Cay Airport",
    longitude: -76.439686,
    latitude: 24.169155,
    id: "arp_tym_bs",
    icao_code: "MYES",
    iata_country_code: "BS",
    iata_code: "TYM",
    city_name: "Staniel Cay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stan Stamper Municipal Airport",
    longitude: -95.540502,
    latitude: 34.033063,
    id: "arp_huj_us",
    icao_code: "KHHW",
    iata_country_code: "US",
    iata_code: "HUJ",
    city_name: "Hugo",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "St. Anthony Airport",
    longitude: -56.085122,
    latitude: 51.390557,
    id: "arp_yay_ca",
    icao_code: "CYAY",
    iata_country_code: "CA",
    iata_code: "YAY",
    city_name: "St. Anthony",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stanthorpe Airport",
    longitude: -88.215836,
    latitude: 35.170277,
    id: "arp_snh_au",
    icao_code: "YSPE",
    iata_country_code: "AU",
    iata_code: "SNH",
    city_name: "Stanthorpe",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stanton Airfield",
    longitude: -93.015837,
    latitude: 44.475526,
    id: "arp_syn_us",
    icao_code: "KSYN",
    iata_country_code: "US",
    iata_code: "SYN",
    city_name: "Stanton",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Stara Zagora Airport",
    longitude: 25.652842,
    latitude: 42.386074,
    id: "arp_szr_bg",
    icao_code: "LBSZ",
    iata_country_code: "BG",
    iata_code: "SZR",
    city_name: "Stara Zagora",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Staroselye Airport",
    longitude: 38.929401,
    latitude: 58.104198,
    id: "arp_ryb_ru",
    icao_code: "UUBK",
    iata_country_code: "RU",
    iata_code: "RYB",
    city_name: "Rybinsk",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Statesboro Bulloch County Airport",
    longitude: -81.738254,
    latitude: 32.482413,
    id: "arp_tbr_us",
    icao_code: "KTBR",
    iata_country_code: "US",
    iata_code: "TBR",
    city_name: "Statesboro",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Statesville Regional Airport",
    longitude: -80.952576,
    latitude: 35.765501,
    id: "arp_svh_us",
    icao_code: "KSVH",
    iata_country_code: "US",
    iata_code: "SVH",
    city_name: "Statesville",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "St Aubin Airport",
    longitude: 1.186225,
    latitude: 49.389812,
    id: "arp_dpe_fr",
    icao_code: "LFAB",
    iata_country_code: "FR",
    iata_code: "DPE",
    city_name: "Dieppe",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Stauning Airport",
    longitude: 8.354475,
    latitude: 55.990023,
    id: "arp_sta_dk",
    icao_code: "EKVJ",
    iata_country_code: "DK",
    iata_code: "STA",
    city_name: "Stauning",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Stavanger Airport, Sola",
    longitude: 5.634591,
    latitude: 58.878571,
    id: "arp_svg_no",
    icao_code: "ENZV",
    iata_country_code: "NO",
    iata_code: "SVG",
    city_name: "Stavanger",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Stavropol Shpakovskoye Airport",
    longitude: 42.110212,
    latitude: 45.110358,
    id: "arp_stw_ru",
    icao_code: "URMT",
    iata_country_code: "RU",
    iata_code: "STW",
    city_name: "Stavropol",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Stawell Airport",
    longitude: 142.741361,
    latitude: -37.069868,
    id: "arp_swc_au",
    icao_code: "YSWL",
    iata_country_code: "AU",
    iata_code: "SWC",
    city_name: "Stawell",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "St. Catherine International Airport",
    longitude: 34.062625,
    latitude: 28.684818,
    id: "arp_skv_eg",
    icao_code: "HESC",
    iata_country_code: "EG",
    iata_code: "SKV",
    city_name: "St Catherine",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "St. Clair County Airport",
    longitude: -86.249402,
    latitude: 33.55698,
    id: "arp_plr_us",
    icao_code: "KPLR",
    iata_country_code: "US",
    iata_code: "PLR",
    city_name: "Pell City",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "St Clair County International Airport",
    longitude: -82.52849,
    latitude: 42.911154,
    id: "arp_phn_us",
    icao_code: "KPHN",
    iata_country_code: "US",
    iata_code: "PHN",
    city_name: "Port Huron",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "St. Cloud Regional Airport",
    longitude: -94.063866,
    latitude: 45.546859,
    id: "arp_stc_us",
    icao_code: "KSTC",
    iata_country_code: "US",
    iata_code: "STC",
    city_name: "Saint Cloud",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Steamboat Bay Seaplane Base Airport",
    longitude: -133.641998,
    latitude: 55.529598,
    id: "arp_wsb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WSB",
    city_name: "Steamboat Bay",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Steamboat Springs Airport",
    longitude: -106.865868,
    latitude: 40.516744,
    id: "arp_sbs_us",
    icao_code: "KSBS",
    iata_country_code: "US",
    iata_code: "SBS",
    city_name: "Steamboat Springs",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Stebbins Airport",
    longitude: -162.280844,
    latitude: 63.515393,
    id: "arp_wbb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WBB",
    city_name: "Stebbins",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Stella Maris Airport",
    longitude: -75.268523,
    latitude: 23.582433,
    id: "arp_sml_bs",
    icao_code: "MYLS",
    iata_country_code: "BS",
    iata_code: "SML",
    city_name: "Stella Maris",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Stenkol Airport",
    longitude: 133.51658,
    latitude: -2.103423,
    id: "arp_nti_id",
    icao_code: "WASB",
    iata_country_code: "ID",
    iata_code: "NTI",
    city_name: "Bintuni",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stephens County Airport",
    longitude: -98.890998,
    latitude: 32.719001,
    id: "arp_bkd_us",
    icao_code: "KBKD",
    iata_country_code: "US",
    iata_code: "BKD",
    city_name: "Breckenridge",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Stephens Island Airport",
    longitude: 143.543348,
    latitude: -9.507418,
    id: "arp_stf_au",
    icao_code: "YSTI",
    iata_country_code: "AU",
    iata_code: "STF",
    city_name: "Stephens Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stephenville Clark Regional Airport",
    longitude: -98.177879,
    latitude: 32.215284,
    id: "arp_sep_us",
    icao_code: "KSEP",
    iata_country_code: "US",
    iata_code: "SEP",
    city_name: "Stephenville",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "Stephenville International Airport",
    longitude: -58.556557,
    latitude: 48.545038,
    id: "arp_yjt_ca",
    icao_code: "CYJT",
    iata_country_code: "CA",
    iata_code: "YJT",
    city_name: "Stephenville",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Sterling Municipal Airport",
    longitude: -103.264194,
    latitude: 40.616468,
    id: "arp_stk_us",
    icao_code: "KSTK",
    iata_country_code: "US",
    iata_code: "STK",
    city_name: "Sterling",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Stevens Field",
    longitude: -107.056086,
    latitude: 37.285431,
    id: "arp_pgo_us",
    icao_code: "KPSO",
    iata_country_code: "US",
    iata_code: "PGO",
    city_name: "Pagosa Springs",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stevens Point Municipal Airport",
    longitude: -89.532214,
    latitude: 44.545138,
    id: "arp_ste_us",
    icao_code: "KSTE",
    iata_country_code: "US",
    iata_code: "STE",
    city_name: "Stevens Point",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Stevens Village Airport",
    longitude: -149.052073,
    latitude: 66.015588,
    id: "arp_svs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SVS",
    city_name: "Stevens Village",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Stewart Airport",
    longitude: -129.981942,
    latitude: 55.935986,
    id: "arp_zst_ca",
    icao_code: "CZST",
    iata_country_code: "CA",
    iata_code: "ZST",
    city_name: "Stewart",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Stewart Island Airport",
    longitude: 168.101332,
    latitude: -46.899711,
    id: "arp_szs_nz",
    icao_code: "NZRC",
    iata_country_code: "NZ",
    iata_code: "SZS",
    city_name: "Stewart Island",
    city: null,
  },
  {
    time_zone: "America/Guadeloupe",
    name: "St-François Airport",
    longitude: -61.262553,
    latitude: 16.25786,
    id: "arp_sfc_gp",
    icao_code: "TFFC",
    iata_country_code: "GP",
    iata_code: "SFC",
    city_name: "St-François",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "St. Gallen–Altenrhein Airport",
    longitude: 9.560875,
    latitude: 47.485219,
    id: "arp_ach_ch",
    icao_code: "LSZR",
    iata_country_code: "CH",
    iata_code: "ACH",
    city_name: "Altenrhein",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "St George Airport",
    longitude: 148.594672,
    latitude: -28.048742,
    id: "arp_sgo_au",
    icao_code: "YSGE",
    iata_country_code: "AU",
    iata_code: "SGO",
    city_name: "St George",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "St. George Airport",
    longitude: -113.508877,
    latitude: 37.030364,
    id: "arp_stg_us",
    icao_code: "PAPB",
    iata_country_code: "US",
    iata_code: "STG",
    city_name: "St George Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "St. George Regional Airport",
    longitude: -113.509883,
    latitude: 37.034254,
    id: "arp_sgu_us",
    icao_code: "KSGU",
    iata_country_code: "US",
    iata_code: "SGU",
    city_name: "Saint George",
    city: null,
  },
  {
    time_zone: "Australia/Hobart",
    name: "St Helens Airport",
    longitude: 148.281777,
    latitude: -41.336889,
    id: "arp_hls_au",
    icao_code: "YSTH",
    iata_country_code: "AU",
    iata_code: "HLS",
    city_name: "St Helens",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stillwater Regional Airport",
    longitude: -97.08475,
    latitude: 36.15949,
    id: "arp_swo_us",
    icao_code: "KSWO",
    iata_country_code: "US",
    iata_code: "SWO",
    city_name: "Stillwater",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stinson Municipal Airport",
    longitude: -98.469557,
    latitude: 29.337968,
    id: "arp_ssf_us",
    icao_code: "KSSF",
    iata_country_code: "US",
    iata_code: "SSF",
    city_name: "San Antonio",
    city: {
      name: "San Antonio",
      id: "cit_sat_us",
      iata_country_code: "US",
      iata_code: "SAT",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "St-Jean Airport",
    longitude: -73.280346,
    latitude: 45.294656,
    id: "arp_yjn_ca",
    icao_code: "CYJN",
    iata_country_code: "CA",
    iata_code: "YJN",
    city_name: "St-Jean",
    city: null,
  },
  {
    time_zone: "America/St_Thomas",
    name: "St John Island Airport",
    longitude: -64.981295,
    latitude: 18.336093,
    id: "arp_sjf_vi",
    icao_code: null,
    iata_country_code: "VI",
    iata_code: "SJF",
    city_name: "St John Island",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "St. Johns Industrial Air Park",
    longitude: -109.378491,
    latitude: 34.51878,
    id: "arp_sjn_us",
    icao_code: "KSJN",
    iata_country_code: "US",
    iata_code: "SJN",
    city_name: "St Johns",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "St. John's International Airport",
    longitude: -52.747171,
    latitude: 47.619904,
    id: "arp_yyt_ca",
    icao_code: "CYYT",
    iata_country_code: "CA",
    iata_code: "YYT",
    city_name: "St. John's",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "St. Landry Parish Airport",
    longitude: -92.099569,
    latitude: 30.557707,
    id: "arp_opl_us",
    icao_code: "KOPL",
    iata_country_code: "US",
    iata_code: "OPL",
    city_name: "Opelousas",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "St. Lewis",
    longitude: -55.673989,
    latitude: 52.372789,
    id: "arp_yfx_ca",
    icao_code: "CCK4",
    iata_country_code: "CA",
    iata_code: "YFX",
    city_name: "St. Lewis",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "St. Louis Downtown Airport",
    longitude: -90.156805,
    latitude: 38.574187,
    id: "arp_cps_us",
    icao_code: "KCPS",
    iata_country_code: "US",
    iata_code: "CPS",
    city_name: "St Louis",
    city: {
      name: "St Louis",
      id: "cit_stl_us",
      iata_country_code: "US",
      iata_code: "STL",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "St. Louis Lambert International Airport",
    longitude: -90.36998,
    latitude: 38.747616,
    id: "arp_stl_us",
    icao_code: "KSTL",
    iata_country_code: "US",
    iata_code: "STL",
    city_name: "St. Louis",
    city: {
      name: "St Louis",
      id: "cit_stl_us",
      iata_country_code: "US",
      iata_code: "STL",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "St. Louis Regional Airport",
    longitude: -90.05008,
    latitude: 38.890664,
    id: "arp_aln_us",
    icao_code: "KALN",
    iata_country_code: "US",
    iata_code: "ALN",
    city_name: "Alton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "St Lucie County International Airport",
    longitude: -80.367785,
    latitude: 27.493543,
    id: "arp_fpr_us",
    icao_code: "KFPR",
    iata_country_code: "US",
    iata_code: "FPR",
    city_name: "Fort Pierce",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "St. Mary's Airport",
    longitude: -6.292507,
    latitude: 49.913563,
    id: "arp_isc_gb",
    icao_code: "EGHE",
    iata_country_code: "GB",
    iata_code: "ISC",
    city_name: "Isles of Scilly",
    city: {
      name: "Isles of Scilly",
      id: "cit_isc_gb",
      iata_country_code: "GB",
      iata_code: "ISC",
    },
  },
  {
    time_zone: "America/Nome",
    name: "St. Mary's Airport",
    longitude: -163.301563,
    latitude: 62.060868,
    id: "arp_ksm_us",
    icao_code: "PASM",
    iata_country_code: "US",
    iata_code: "KSM",
    city_name: "St Marys",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "St. Mary's County Regional Airport",
    longitude: -76.550845,
    latitude: 38.315431,
    id: "arp_ltw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "LTW",
    city_name: "Leonardtown",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "St. Marys Municipal Airport",
    longitude: -78.504,
    latitude: 41.412325,
    id: "arp_stq_us",
    icao_code: "KOYM",
    iata_country_code: "US",
    iata_code: "STQ",
    city_name: "St Marys",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "St. Michael Airport",
    longitude: -162.110103,
    latitude: 63.490086,
    id: "arp_smk_us",
    icao_code: "PAMK",
    iata_country_code: "US",
    iata_code: "SMK",
    city_name: "St. Michael",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Stockholm Airport",
    longitude: 151.550054,
    latitude: -4.350449,
    id: "arp_smp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SMP",
    city_name: "Stockholm",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Stockholm-Arlanda Airport",
    longitude: 17.92119,
    latitude: 59.650831,
    id: "arp_arn_se",
    icao_code: "ESSA",
    iata_country_code: "SE",
    iata_code: "ARN",
    city_name: "Stockholm",
    city: {
      name: "Stockholm",
      id: "cit_sto_se",
      iata_country_code: "SE",
      iata_code: "STO",
    },
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Stockholm-Bromma Airport",
    longitude: 17.94221,
    latitude: 59.354606,
    id: "arp_bma_se",
    icao_code: "ESSB",
    iata_country_code: "SE",
    iata_code: "BMA",
    city_name: "Stockholm",
    city: {
      name: "Stockholm",
      id: "cit_sto_se",
      iata_country_code: "SE",
      iata_code: "STO",
    },
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Stockholm Skavsta Airport",
    longitude: 16.913783,
    latitude: 58.78881,
    id: "arp_nyo_se",
    icao_code: "ESKN",
    iata_country_code: "SE",
    iata_code: "NYO",
    city_name: "Stockholm",
    city: {
      name: "Stockholm",
      id: "cit_sto_se",
      iata_country_code: "SE",
      iata_code: "STO",
    },
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Stockholm Västerås Airport",
    longitude: 16.628687,
    latitude: 59.601572,
    id: "arp_vst_se",
    icao_code: "ESOW",
    iata_country_code: "SE",
    iata_code: "VST",
    city_name: "Stockholm/Västerås",
    city: {
      name: "Stockholm",
      id: "cit_sto_se",
      iata_country_code: "SE",
      iata_code: "STO",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Stockton Metropolitan Airport",
    longitude: -121.24406,
    latitude: 37.895449,
    id: "arp_sck_us",
    icao_code: "KSCK",
    iata_country_code: "US",
    iata_code: "SCK",
    city_name: "Stockton",
    city: {
      name: "Sacramento",
      id: "cit_sac_us",
      iata_country_code: "US",
      iata_code: "SAC",
    },
  },
  {
    time_zone: "America/Paramaribo",
    name: "Stoelmanseiland Airport",
    longitude: -54.417183,
    latitude: 4.350909,
    id: "arp_smz_sr",
    icao_code: "SMST",
    iata_country_code: "SR",
    iata_code: "SMZ",
    city_name: "Stoelmanseiland",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Stokmarknes Skagen Airport",
    longitude: 15.027375,
    latitude: 68.579799,
    id: "arp_skn_no",
    icao_code: "ENSK",
    iata_country_code: "NO",
    iata_code: "SKN",
    city_name: "Stokmarknes",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Stony Rapids Airport",
    longitude: -105.841217,
    latitude: 59.250657,
    id: "arp_ysf_ca",
    icao_code: "CYSF",
    iata_country_code: "CA",
    iata_code: "YSF",
    city_name: "Stony Rapids",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Stony River Airport",
    longitude: -156.588592,
    latitude: 61.789787,
    id: "arp_srv_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SRV",
    city_name: "Stony River",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Stord Sørstokken Airport",
    longitude: 5.341004,
    latitude: 59.792155,
    id: "arp_srp_no",
    icao_code: "ENSO",
    iata_country_code: "NO",
    iata_code: "SRP",
    city_name: "Stord",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Storm Lake Municipal Airport",
    longitude: -95.240361,
    latitude: 42.599336,
    id: "arp_slb_us",
    icao_code: "KSLB",
    iata_country_code: "US",
    iata_code: "SLB",
    city_name: "Storm Lake",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Stornoway Airport",
    longitude: -6.327225,
    latitude: 58.215483,
    id: "arp_syy_gb",
    icao_code: "EGPO",
    iata_country_code: "GB",
    iata_code: "SYY",
    city_name: "Stornoway",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Storuman Airport",
    longitude: 17.696666,
    latitude: 64.960846,
    id: "arp_sqo_se",
    icao_code: "ESUD",
    iata_country_code: "SE",
    iata_code: "SQO",
    city_name: "Storuman",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "St. Paul Downtown Airport",
    longitude: -93.060061,
    latitude: 44.933985,
    id: "arp_stp_us",
    icao_code: "KSTP",
    iata_country_code: "US",
    iata_code: "STP",
    city_name: "St Paul/Minneapolis",
    city: {
      name: "Minneapolis/St Paul",
      id: "cit_msp_us",
      iata_country_code: "US",
      iata_code: "MSP",
    },
  },
  {
    time_zone: "America/Nome",
    name: "St. Paul Island Airport",
    longitude: -170.229076,
    latitude: 57.157658,
    id: "arp_snp_us",
    icao_code: "PASN",
    iata_country_code: "US",
    iata_code: "SNP",
    city_name: "St. Paul",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "St. Paul's Mission Airport",
    longitude: 142.116667,
    latitude: -10.366667,
    id: "arp_svm_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "SVM",
    city_name: "St Pauls",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "St. Pete–Clearwater International Airport",
    longitude: -82.688843,
    latitude: 27.909259,
    id: "arp_pie_us",
    icao_code: "KPIE",
    iata_country_code: "US",
    iata_code: "PIE",
    city_name: "Tampa",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Strahan Airport",
    longitude: 145.291017,
    latitude: -42.155319,
    id: "arp_srn_au",
    icao_code: "YSRN",
    iata_country_code: "AU",
    iata_code: "SRN",
    city_name: "Strahan",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Strasbourg Airport",
    longitude: 7.628039,
    latitude: 48.540226,
    id: "arp_sxb_fr",
    icao_code: "LFST",
    iata_country_code: "FR",
    iata_code: "SXB",
    city_name: "Strasbourg",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Strathmore Airport",
    longitude: 142.567001,
    latitude: -17.85,
    id: "arp_sth_au",
    icao_code: "YSMR",
    iata_country_code: "AU",
    iata_code: "STH",
    city_name: "Strathmore",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Straubing Wallmühle Airport",
    longitude: 12.516256,
    latitude: 48.901285,
    id: "arp_rbm_de",
    icao_code: "EDMS",
    iata_country_code: "DE",
    iata_code: "RBM",
    city_name: "Straubing",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Streaky Bay Airport",
    longitude: 134.292888,
    latitude: -32.835816,
    id: "arp_kby_au",
    icao_code: "YKBY",
    iata_country_code: "AU",
    iata_code: "KBY",
    city_name: "Streaky Bay",
    city: null,
  },
  {
    time_zone: "Asia/Tomsk",
    name: "Strezhevoy Airport",
    longitude: 77.650913,
    latitude: 60.717323,
    id: "arp_swt_ru",
    icao_code: "UNSS",
    iata_country_code: "RU",
    iata_code: "SWT",
    city_name: "Strezhevoy",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Stronsay Airport",
    longitude: -2.64139,
    latitude: 59.1553,
    id: "arp_soy_gb",
    icao_code: "EGER",
    iata_country_code: "GB",
    iata_code: "SOY",
    city_name: "Stronsay",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Strother Field",
    longitude: -97.03618,
    latitude: 37.168362,
    id: "arp_wld_us",
    icao_code: "KWLD",
    iata_country_code: "US",
    iata_code: "WLD",
    city_name: "Winfield/Arkansas City",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stroud Municipal Airport",
    longitude: -96.657165,
    latitude: 35.788583,
    id: "arp_sud_us",
    icao_code: "KSUD",
    iata_country_code: "US",
    iata_code: "SUD",
    city_name: "Stroud",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Stroudsburg-Pocono Airport",
    longitude: -75.158186,
    latitude: 41.035951,
    id: "arp_esp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "ESP",
    city_name: "East Stroudsburg",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "St. Theresa Point Airport",
    longitude: -94.852338,
    latitude: 53.845348,
    id: "arp_yst_ca",
    icao_code: "CYST",
    iata_country_code: "CA",
    iata_code: "YST",
    city_name: "St. Theresa Point",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "St. Thomas Municipal Airport",
    longitude: -81.107691,
    latitude: 42.772279,
    id: "arp_yqs_ca",
    icao_code: "CYQS",
    iata_country_code: "CA",
    iata_code: "YQS",
    city_name: "St Thomas",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Stuart Island Airpark",
    longitude: -123.176861,
    latitude: 48.673501,
    id: "arp_ssw_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SSW",
    city_name: "Stuart Island",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Stuart Island Airport",
    longitude: -125.167,
    latitude: 50.4167,
    id: "arp_yrr_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YRR",
    city_name: "Stuart Island",
    city: null,
  },
  {
    time_zone: "Asia/Phnom_Penh",
    name: "Stung Treng Airport",
    longitude: 106.014627,
    latitude: 13.531266,
    id: "arp_tnx_kh",
    icao_code: "VDST",
    iata_country_code: "KH",
    iata_code: "TNX",
    city_name: "Stung Treng",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Sturt Creek Airport",
    longitude: 128.174314,
    latitude: -19.166337,
    id: "arp_ssk_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "SSK",
    city_name: "Sturt Creek",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Stuttgart Airport",
    longitude: 9.204025,
    latitude: 48.688717,
    id: "arp_str_de",
    icao_code: "EDDS",
    iata_country_code: "DE",
    iata_code: "STR",
    city_name: "Stuttgart",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Stuttgart Municipal Airport",
    longitude: -91.57482,
    latitude: 34.598463,
    id: "arp_sgt_us",
    icao_code: "KSGT",
    iata_country_code: "US",
    iata_code: "SGT",
    city_name: "Stuttgart",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Stykkishólmur Airport",
    longitude: -22.755231,
    latitude: 65.060025,
    id: "arp_syk_is",
    icao_code: "BIST",
    iata_country_code: "IS",
    iata_code: "SYK",
    city_name: "Stykkishólmur",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Suabi Airport",
    longitude: 142.280666,
    latitude: -6.104381,
    id: "arp_sbe_pg",
    icao_code: "AYSA",
    iata_country_code: "PG",
    iata_code: "SBE",
    city_name: "Suabi",
    city: null,
  },
  {
    time_zone: "Asia/Dili",
    name: "Suai Airport",
    longitude: 125.286517,
    latitude: -9.302495,
    id: "arp_uai_tl",
    icao_code: "WPDB",
    iata_country_code: "TL",
    iata_code: "UAI",
    city_name: "Suai",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Sua Pan Airport",
    longitude: 26.115719,
    latitude: -20.5536,
    id: "arp_sxn_bw",
    icao_code: "FBSN",
    iata_country_code: "BW",
    iata_code: "SXN",
    city_name: "Sowa",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Suavanao Airport",
    longitude: 158.729584,
    latitude: -7.586288,
    id: "arp_vao_sb",
    icao_code: "AGGV",
    iata_country_code: "SB",
    iata_code: "VAO",
    city_name: "Suavanao",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Subic Bay International Airport",
    longitude: 120.271637,
    latitude: 14.794734,
    id: "arp_sfs_ph",
    icao_code: "RPLB",
    iata_country_code: "PH",
    iata_code: "SFS",
    city_name: "Olongapo City",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Sub Teniente Nestor Arias Airport",
    longitude: -68.755008,
    latitude: 10.279086,
    id: "arp_snf_ve",
    icao_code: "SVSP",
    iata_country_code: "VE",
    iata_code: "SNF",
    city_name: "San Felipe",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Suceava International Airport",
    longitude: 26.353393,
    latitude: 47.687148,
    id: "arp_scv_ro",
    icao_code: "LRSV",
    iata_country_code: "RO",
    iata_code: "SCV",
    city_name: "Suceava",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Sucua Airport",
    longitude: -78.169713,
    latitude: -2.471058,
    id: "arp_suq_ec",
    icao_code: "SESC",
    iata_country_code: "EC",
    iata_code: "SUQ",
    city_name: "Sucua",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Sudbury Airport",
    longitude: -80.7979,
    latitude: 46.624919,
    id: "arp_ysb_ca",
    icao_code: "CYSB",
    iata_country_code: "CA",
    iata_code: "YSB",
    city_name: "Sudbury",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sugar Land Regional Airport",
    longitude: -95.654696,
    latitude: 29.624783,
    id: "arp_sgr_us",
    icao_code: "KSGR",
    iata_country_code: "US",
    iata_code: "SGR",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sugimanuru Airport",
    longitude: 122.569208,
    latitude: -4.759273,
    id: "arp_raq_id",
    icao_code: "WAWR",
    iata_country_code: "ID",
    iata_code: "RAQ",
    city_name: "Raha",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Sugraly Airport",
    longitude: 64.2332,
    latitude: 41.613899,
    id: "arp_afs_uz",
    icao_code: "UTSN",
    iata_country_code: "UZ",
    iata_code: "AFS",
    city_name: "Zarafshan",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sui Airport",
    longitude: 69.176907,
    latitude: 28.645113,
    id: "arp_sul_pk",
    icao_code: "OPSU",
    iata_country_code: "PK",
    iata_code: "SUL",
    city_name: "Sui",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Suia-Missu Airport",
    longitude: -51.435977,
    latitude: -11.671363,
    id: "arp_swm_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "SWM",
    city_name: "Alto Boa Vista",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Sukhothai Airport",
    longitude: 99.818266,
    latitude: 17.23775,
    id: "arp_ths_th",
    icao_code: "VTPO",
    iata_country_code: "TH",
    iata_code: "THS",
    city_name: "Sukhothai",
    city: null,
  },
  {
    time_zone: "Asia/Tbilisi",
    name: "Sukhumi Babushara Airport",
    longitude: 41.128179,
    latitude: 42.858152,
    id: "arp_sui_ge",
    icao_code: "UGSS",
    iata_country_code: "GE",
    iata_code: "SUI",
    city_name: "Sukhumi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Suki Airport",
    longitude: 141.722159,
    latitude: -8.046564,
    id: "arp_skc_pg",
    icao_code: "AYSU",
    iata_country_code: "PG",
    iata_code: "SKC",
    city_name: "Suki",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Sukkur Airport",
    longitude: 68.793779,
    latitude: 27.722343,
    id: "arp_skz_pk",
    icao_code: "OPSK",
    iata_country_code: "PK",
    iata_code: "SKZ",
    city_name: "Sukkur",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Sulaco Airport",
    longitude: -87.263336,
    latitude: 14.907283,
    id: "arp_scd_hn",
    icao_code: "MHUL",
    iata_country_code: "HN",
    iata_code: "SCD",
    city_name: "Sulaco",
    city: null,
  },
  {
    time_zone: "Asia/Baghdad",
    name: "Sulaimaniyah International Airport",
    longitude: 45.318463,
    latitude: 35.561021,
    id: "arp_isu_iq",
    icao_code: "ORSU",
    iata_country_code: "IQ",
    iata_code: "ISU",
    city_name: "Sulaymaniyah",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Sulayel Airport",
    longitude: 45.619352,
    latitude: 20.464464,
    id: "arp_slf_sa",
    icao_code: "OESL",
    iata_country_code: "SA",
    iata_code: "SLF",
    city_name: "Sulayel",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Sule Airport",
    longitude: 151.29935,
    latitude: -4.97531,
    id: "arp_ule_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ULE",
    city_name: "Sule",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Sullivan Bay Airport",
    longitude: -126.828382,
    latitude: 50.886063,
    id: "arp_ytg_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YTG",
    city_name: "Sullivan Bay",
    city: null,
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Sullivan County Airport",
    longitude: -87.447726,
    latitude: 39.11389,
    id: "arp_siv_us",
    icao_code: "KSIV",
    iata_country_code: "US",
    iata_code: "SIV",
    city_name: "Sullivan",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sullivan County International Airport",
    longitude: -74.797306,
    latitude: 41.701976,
    id: "arp_msv_us",
    icao_code: "KMSV",
    iata_country_code: "US",
    iata_code: "MSV",
    city_name: "Monticello",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Sulphur Springs Municipal Airport",
    longitude: -95.619985,
    latitude: 33.156875,
    id: "arp_slr_us",
    icao_code: "KSLR",
    iata_country_code: "US",
    iata_code: "SLR",
    city_name: "Sulphur Springs",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Sultan Abdul Aziz Shah Airport",
    longitude: 101.549802,
    latitude: 3.132291,
    id: "arp_szb_my",
    icao_code: "WMSA",
    iata_country_code: "MY",
    iata_code: "SZB",
    city_name: "Kuala Lumpur",
    city: {
      name: "Kuala Lumpur",
      id: "cit_kul_my",
      iata_country_code: "MY",
      iata_code: "KUL",
    },
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Sultan Abdul Halim Airport",
    longitude: 100.398248,
    latitude: 6.191356,
    id: "arp_aor_my",
    icao_code: "WMKA",
    iata_country_code: "MY",
    iata_code: "AOR",
    city_name: "Alor Setar",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sultan Aji Muhammad Sulaiman Sepinggan International Airport",
    longitude: 116.895862,
    latitude: -1.267043,
    id: "arp_bpn_id",
    icao_code: "WALL",
    iata_country_code: "ID",
    iata_code: "BPN",
    city_name: "Balikpapan",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Sultan Azlan Shah Airport",
    longitude: 101.094085,
    latitude: 4.568888,
    id: "arp_iph_my",
    icao_code: "WMKI",
    iata_country_code: "MY",
    iata_code: "IPH",
    city_name: "Ipoh",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sultan Bantilan Airport",
    longitude: 120.793575,
    latitude: 1.122564,
    id: "arp_tli_id",
    icao_code: "WAMI",
    iata_country_code: "ID",
    iata_code: "TLI",
    city_name: "Tolitoli",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Sultan Haji Ahmad Shah Airport",
    longitude: 103.21099,
    latitude: 3.777167,
    id: "arp_kua_my",
    icao_code: "WMKD",
    iata_country_code: "MY",
    iata_code: "KUA",
    city_name: "Kuantan",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sultan Hasanuddin International Airport",
    longitude: 119.548261,
    latitude: -5.077234,
    id: "arp_upg_id",
    icao_code: "WAAA",
    iata_country_code: "ID",
    iata_code: "UPG",
    city_name: "Makassar",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sultan Iskandar Muda International Airport",
    longitude: 95.419529,
    latitude: 5.521236,
    id: "arp_btj_id",
    icao_code: "WITT",
    iata_country_code: "ID",
    iata_code: "BTJ",
    city_name: "Banda Aceh",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Sultan Ismail Petra Airport",
    longitude: 102.29309,
    latitude: 6.168311,
    id: "arp_kbr_my",
    icao_code: "WMKC",
    iata_country_code: "MY",
    iata_code: "KBR",
    city_name: "Kota Bharu",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Sultan Mahmud Airport",
    longitude: 103.104207,
    latitude: 5.38089,
    id: "arp_tgg_my",
    icao_code: "WMKN",
    iata_country_code: "MY",
    iata_code: "TGG",
    city_name: "Kuala Terengganu",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sultan Mahmud Badaruddin II International Airport",
    longitude: 104.700942,
    latitude: -2.897095,
    id: "arp_plm_id",
    icao_code: "WIPP",
    iata_country_code: "ID",
    iata_code: "PLM",
    city_name: "Palembang",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Sultan Muhammad Kaharuddin III Airport",
    longitude: 117.412571,
    latitude: -8.489255,
    id: "arp_swq_id",
    icao_code: "WADS",
    iata_country_code: "ID",
    iata_code: "SWQ",
    city_name: "Sumbawa",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sultan Syarif Kasim II International Airport",
    longitude: 101.446669,
    latitude: 0.462513,
    id: "arp_pku_id",
    icao_code: "WIBB",
    iata_country_code: "ID",
    iata_code: "PKU",
    city_name: "Pekanbaru",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Sultan Thaha Airport",
    longitude: 103.64274,
    latitude: -1.635365,
    id: "arp_djb_id",
    icao_code: "WIPA",
    iata_country_code: "ID",
    iata_code: "DJB",
    city_name: "Jambi",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Sumbawanga Airport",
    longitude: 31.610278,
    latitude: -7.948889,
    id: "arp_sut_tz",
    icao_code: "HTSU",
    iata_country_code: "TZ",
    iata_code: "SUT",
    city_name: "Sumbawanga",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Sumbe Airport",
    longitude: 13.848049,
    latitude: -11.1677,
    id: "arp_ndd_ao",
    icao_code: "FNSU",
    iata_country_code: "AO",
    iata_code: "NDD",
    city_name: "Sumbe",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Sumburgh Airport",
    longitude: -1.294371,
    latitude: 59.878896,
    id: "arp_lsi_gb",
    icao_code: "EGPB",
    iata_country_code: "GB",
    iata_code: "LSI",
    city_name: "Shetland",
    city: {
      name: "Shetland Islands",
      id: "cit_sdz_gb",
      iata_country_code: "GB",
      iata_code: "SDZ",
    },
  },
  {
    time_zone: "America/Toronto",
    name: "Summer Beaver Airport",
    longitude: -88.541915,
    latitude: 52.708603,
    id: "arp_sur_ca",
    icao_code: "CJV7",
    iata_country_code: "CA",
    iata_code: "SUR",
    city_name: "Summer Beaver",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Summerside Airport",
    longitude: -63.83221,
    latitude: 46.439974,
    id: "arp_ysu_ca",
    icao_code: "CYSU",
    iata_country_code: "CA",
    iata_code: "YSU",
    city_name: "Summerside",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Summit Airport",
    longitude: -75.720608,
    latitude: 39.520813,
    id: "arp_umm_us",
    icao_code: "PAST",
    iata_country_code: "US",
    iata_code: "UMM",
    city_name: "Summit",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sumter Airport",
    longitude: -80.36157,
    latitude: 33.993946,
    id: "arp_sum_us",
    icao_code: "KSMS",
    iata_country_code: "US",
    iata_code: "SUM",
    city_name: "Sumter",
    city: {
      name: "Sumter",
      id: "cit_ssc_us",
      iata_country_code: "US",
      iata_code: "SSC",
    },
  },
  {
    time_zone: "Europe/Kiev",
    name: "Sumy Airport",
    longitude: 34.758685,
    latitude: 50.860218,
    id: "arp_umy_ua",
    icao_code: "UKHS",
    iata_country_code: "UA",
    iata_code: "UMY",
    city_name: "Sumy",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Sunan Shuofang International Airport",
    longitude: 120.432134,
    latitude: 31.496512,
    id: "arp_wux_cn",
    icao_code: "ZSWX",
    iata_country_code: "CN",
    iata_code: "WUX",
    city_name: "Wuxi",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Sunchales Aeroclub Airport",
    longitude: -61.529519,
    latitude: -30.957414,
    id: "arp_ncj_ar",
    icao_code: "SAFS",
    iata_country_code: "AR",
    iata_code: "NCJ",
    city_name: "Sunchales",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Sundsvall-Timrå Airport",
    longitude: 17.44154,
    latitude: 62.527483,
    id: "arp_sdl_se",
    icao_code: "ESNN",
    iata_country_code: "SE",
    iata_code: "SDL",
    city_name: "Sundsvall",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Sungai Tiang Airport",
    longitude: 102.394449,
    latitude: 4.329796,
    id: "arp_sxt_my",
    icao_code: "WMAN",
    iata_country_code: "MY",
    iata_code: "SXT",
    city_name: "Taman Negara",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Sunriver Airport",
    longitude: -121.453954,
    latitude: 43.875866,
    id: "arp_suo_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "SUO",
    city_name: "Sunriver",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Sunshine Coast Airport",
    longitude: 153.090175,
    latitude: -26.603988,
    id: "arp_mcy_au",
    icao_code: "YBSU",
    iata_country_code: "AU",
    iata_code: "MCY",
    city_name: "Sunshine Coast",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Suntar Airport",
    longitude: 117.636899,
    latitude: 62.184658,
    id: "arp_suy_ru",
    icao_code: "UENS",
    iata_country_code: "RU",
    iata_code: "SUY",
    city_name: "Suntar",
    city: null,
  },
  {
    time_zone: "Africa/Accra",
    name: "Sunyani Airport",
    longitude: -2.32915,
    latitude: 7.361477,
    id: "arp_nyi_gh",
    icao_code: "DGSN",
    iata_country_code: "GH",
    iata_code: "NYI",
    city_name: "Sunyani",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Supadio Airport",
    longitude: 109.404179,
    latitude: -0.148739,
    id: "arp_pnk_id",
    icao_code: "WIOO",
    iata_country_code: "ID",
    iata_code: "PNK",
    city_name: "Pontianak",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Sur Airport",
    longitude: 59.483167,
    latitude: 22.533166,
    id: "arp_suh_om",
    icao_code: "OOSR",
    iata_country_code: "OM",
    iata_code: "SUH",
    city_name: "Sur",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Surat Airport",
    longitude: 72.742284,
    latitude: 21.117302,
    id: "arp_stv_in",
    icao_code: "VASU",
    iata_country_code: "IN",
    iata_code: "STV",
    city_name: "Surat",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Surat Thani Airport",
    longitude: 99.136787,
    latitude: 9.13377,
    id: "arp_urt_th",
    icao_code: "VTSB",
    iata_country_code: "TH",
    iata_code: "URT",
    city_name: "Surat Thani",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Surgut International Airport",
    longitude: 73.404816,
    latitude: 61.342006,
    id: "arp_sgc_ru",
    icao_code: "USRR",
    iata_country_code: "RU",
    iata_code: "SGC",
    city_name: "Surgut",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Suria Airport",
    longitude: 143.916667,
    latitude: -7.166667,
    id: "arp_suz_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "SUZ",
    city_name: "Suria",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Surigao Airport",
    longitude: 125.481334,
    latitude: 9.758007,
    id: "arp_sug_ph",
    icao_code: "RPMS",
    iata_country_code: "PH",
    iata_code: "SUG",
    city_name: "Surigao City",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Surin Airport",
    longitude: 103.498091,
    latitude: 14.869086,
    id: "arp_pxr_th",
    icao_code: "VTUJ",
    iata_country_code: "TH",
    iata_code: "PXR",
    city_name: "Surin",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Surkhet Airport",
    longitude: 81.635686,
    latitude: 28.585603,
    id: "arp_skh_np",
    icao_code: "VNSK",
    iata_country_code: "NP",
    iata_code: "SKH",
    city_name: "Surkhet",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Susanville Municipal Airport",
    longitude: -120.576568,
    latitude: 40.37625,
    id: "arp_sve_us",
    icao_code: "KSVE",
    iata_country_code: "US",
    iata_code: "SVE",
    city_name: "Susanville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sussex County Airport",
    longitude: -75.359514,
    latitude: 38.689449,
    id: "arp_ged_us",
    icao_code: "KGED",
    iata_country_code: "US",
    iata_code: "GED",
    city_name: "Georgetown",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Suvarnabhumi Airport",
    longitude: 100.748128,
    latitude: 13.691187,
    id: "arp_bkk_th",
    icao_code: "VTBS",
    iata_country_code: "TH",
    iata_code: "BKK",
    city_name: "Bangkok",
    city: {
      name: "Bangkok",
      id: "cit_bkk_th",
      iata_country_code: "TH",
      iata_code: "BKK",
    },
  },
  {
    time_zone: "Asia/Seoul",
    name: "Suwon Airport",
    longitude: 127.007004,
    latitude: 37.239399,
    id: "arp_swu_kr",
    icao_code: "RKSW",
    iata_country_code: "KR",
    iata_code: "SWU",
    city_name: "Suwon",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Suzhou Guangfu Airport",
    longitude: 120.401001,
    latitude: 31.2631,
    id: "arp_szv_cn",
    icao_code: "ZSSZ",
    iata_country_code: "CN",
    iata_code: "SZV",
    city_name: "Suzhou",
    city: null,
  },
  {
    time_zone: "Arctic/Longyearbyen",
    name: "Svalbard Airport, Longyear",
    longitude: 15.465581,
    latitude: 78.246093,
    id: "arp_lyr_no ",
    icao_code: "ENSB",
    iata_country_code: "NO",
    iata_code: "LYR",
    city_name: "Longyearbyen",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Sveg Airport",
    longitude: 14.420515,
    latitude: 62.047155,
    id: "arp_evg_se",
    icao_code: "ESND",
    iata_country_code: "SE",
    iata_code: "EVG",
    city_name: "Sveg",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Svetlaya Airport",
    longitude: 138.320621,
    latitude: 46.541897,
    id: "arp_etl_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "ETL",
    city_name: "Svetlaya",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Svolvær Airport",
    longitude: 14.668628,
    latitude: 68.243752,
    id: "arp_svj_no",
    icao_code: "ENSH",
    iata_country_code: "NO",
    iata_code: "SVJ",
    city_name: "Svolvær",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Swakopmund Airport",
    longitude: 14.567542,
    latitude: -22.660963,
    id: "arp_swp_na",
    icao_code: "FYSM",
    iata_country_code: "NA",
    iata_code: "SWP",
    city_name: "Swakopmund",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Swami Vivekananda Airport",
    longitude: 81.739598,
    latitude: 21.183183,
    id: "arp_rpr_in",
    icao_code: "VARP",
    iata_country_code: "IN",
    iata_code: "RPR",
    city_name: "Raipur",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Swan Hill Airport",
    longitude: 143.535529,
    latitude: -35.376636,
    id: "arp_swh_au",
    icao_code: "YSWH",
    iata_country_code: "AU",
    iata_code: "SWH",
    city_name: "Swan Hill",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Swan River Airport",
    longitude: -101.235029,
    latitude: 52.118633,
    id: "arp_zjn_ca",
    icao_code: "CZJN",
    iata_country_code: "CA",
    iata_code: "ZJN",
    city_name: "Swan River",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Swansea Airport",
    longitude: -4.068409,
    latitude: 51.604257,
    id: "arp_sws_gb",
    icao_code: "EGFH",
    iata_country_code: "GB",
    iata_code: "SWS",
    city_name: "Swansea",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Swift Current Airport",
    longitude: -107.690237,
    latitude: 50.291008,
    id: "arp_yyn_ca",
    icao_code: "CYYN",
    iata_country_code: "CA",
    iata_code: "YYN",
    city_name: "Swift Current",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Syamsudin Noor Airport",
    longitude: 114.763,
    latitude: -3.442359,
    id: "arp_bdj_id",
    icao_code: "WAOO",
    iata_country_code: "ID",
    iata_code: "BDJ",
    city_name: "Banjarmasin",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Syangboche Airport",
    longitude: 86.712041,
    latitude: 27.811475,
    id: "arp_syh_np",
    icao_code: "VNSB",
    iata_country_code: "NP",
    iata_code: "SYH",
    city_name: "Syangboche",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Sydney Airport",
    longitude: 151.176138,
    latitude: -33.943011,
    id: "arp_syd_au",
    icao_code: "YSSY",
    iata_country_code: "AU",
    iata_code: "SYD",
    city_name: "Sydney",
    city: {
      name: "Sydney",
      id: "cit_syd_au",
      iata_country_code: "AU",
      iata_code: "SYD",
    },
  },
  {
    time_zone: "Europe/Moscow",
    name: "Syktyvkar Airport",
    longitude: 50.852838,
    latitude: 61.663398,
    id: "arp_scw_ru",
    icao_code: "UUYY",
    iata_country_code: "RU",
    iata_code: "SCW",
    city_name: "Syktyvkar",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Sylt Airport",
    longitude: 8.337783,
    latitude: 54.913127,
    id: "arp_gwt_de",
    icao_code: "EDXW",
    iata_country_code: "DE",
    iata_code: "GWT",
    city_name: "Westerland",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Sylvester Airport",
    longitude: -83.894854,
    latitude: 31.557517,
    id: "arp_syv_us",
    icao_code: "KSYV",
    iata_country_code: "US",
    iata_code: "SYV",
    city_name: "Sylvester",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Syracuse Hancock International Airport",
    longitude: -76.108244,
    latitude: 43.112564,
    id: "arp_syr_us",
    icao_code: "KSYR",
    iata_country_code: "US",
    iata_code: "SYR",
    city_name: "Syracuse",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Syros Island National Airport",
    longitude: 24.949279,
    latitude: 37.42247,
    id: "arp_jsy_gr",
    icao_code: "LGSO",
    iata_country_code: "GR",
    iata_code: "JSY",
    city_name: "Syros Island",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Sywell Aerodrome",
    longitude: -0.790035,
    latitude: 52.305335,
    id: "arp_orm_gb",
    icao_code: "EGBK",
    iata_country_code: "GB",
    iata_code: "ORM",
    city_name: "Northampton",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Taba International Airport",
    longitude: 34.777795,
    latitude: 29.590488,
    id: "arp_tcp_eg",
    icao_code: "HETB",
    iata_country_code: "EG",
    iata_code: "TCP",
    city_name: "Taba",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Tabal Airport",
    longitude: 169.947,
    latitude: 10.0389,
    id: "arp_tbv_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "TBV",
    city_name: "Tabal Island",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Tabarka–Aïn Draham International Airport",
    longitude: 8.876459,
    latitude: 36.979084,
    id: "arp_tbj_tn",
    icao_code: "DTKA",
    iata_country_code: "TN",
    iata_code: "TBJ",
    city_name: "Tabarka",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Tabas Airport",
    longitude: 56.89488,
    latitude: 33.668528,
    id: "arp_tcx_ir",
    icao_code: "OIMT",
    iata_country_code: "IR",
    iata_code: "TCX",
    city_name: "Tabas",
    city: null,
  },
  {
    time_zone: "America/Eirunepe",
    name: "Tabatinga International Airport",
    longitude: -69.937227,
    latitude: -4.254818,
    id: "arp_tbt_br",
    icao_code: "SBTT",
    iata_country_code: "BR",
    iata_code: "TBT",
    city_name: "Tabatinga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tabibuga Airport",
    longitude: 144.970445,
    latitude: -6.024644,
    id: "arp_tba_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TBA",
    city_name: "Tabibuga",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Tabiteuea North Airport",
    longitude: 174.776053,
    latitude: -1.224087,
    id: "arp_tbf_ki",
    icao_code: "NGTE",
    iata_country_code: "KI",
    iata_code: "TBF",
    city_name: "Tabiteuea North",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Tabiteuea South Airport",
    longitude: 175.063995,
    latitude: -1.474439,
    id: "arp_tsu_ki",
    icao_code: "NGTS",
    iata_country_code: "KI",
    iata_code: "TSU",
    city_name: "Tabiteuea South",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Tableland Homestead Airport",
    longitude: 126.910997,
    latitude: -17.294327,
    id: "arp_tbl_au",
    icao_code: "YTAB",
    iata_country_code: "AU",
    iata_code: "TBL",
    city_name: "Tablelands",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Tablón de Tamará Airport",
    longitude: -72.100638,
    latitude: 5.721884,
    id: "arp_ttm_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "TTM",
    city_name: "Tablon de Tamara",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Tabora Airport",
    longitude: 32.834767,
    latitude: -5.075341,
    id: "arp_tbo_tz",
    icao_code: "HTTB",
    iata_country_code: "TZ",
    iata_code: "TBO",
    city_name: "Tabora",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Tabou Airport",
    longitude: -7.361931,
    latitude: 4.43688,
    id: "arp_txu_ci",
    icao_code: "DITB",
    iata_country_code: "CI",
    iata_code: "TXU",
    city_name: "Tabou",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Tabriz International Airport",
    longitude: 46.24377,
    latitude: 38.124406,
    id: "arp_tbz_ir",
    icao_code: "OITT",
    iata_country_code: "IR",
    iata_code: "TBZ",
    city_name: "Tabriz",
    city: null,
  },
  {
    time_zone: "Pacific/Kiritimati",
    name: "Tabuaeran Island Airport",
    longitude: -159.389598,
    latitude: 3.89989,
    id: "arp_tnv_ki",
    icao_code: "PLFA",
    iata_country_code: "KI",
    iata_code: "TNV",
    city_name: "Tabuaeran",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tabubil Airport",
    longitude: 141.226464,
    latitude: -5.279144,
    id: "arp_tbg_pg",
    icao_code: "AYTB",
    iata_country_code: "PG",
    iata_code: "TBG",
    city_name: "Tabubil",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Tabuk Regional Airport",
    longitude: 36.599531,
    latitude: 28.372328,
    id: "arp_tuu_sa",
    icao_code: "OETB",
    iata_country_code: "SA",
    iata_code: "TUU",
    city_name: "Tabuk",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tacheng Airport",
    longitude: 83.341714,
    latitude: 46.67167,
    id: "arp_tcg_cn",
    icao_code: "ZWTC",
    iata_country_code: "CN",
    iata_code: "TCG",
    city_name: "Tacheng",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Tachilek Airport",
    longitude: 99.935192,
    latitude: 20.48455,
    id: "arp_thl_mm",
    icao_code: "VYTL",
    iata_country_code: "MM",
    iata_code: "THL",
    city_name: "Tachilek",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Tacoma Narrows Airport",
    longitude: -122.57788,
    latitude: 47.267888,
    id: "arp_tiw_us",
    icao_code: "KTIW",
    iata_country_code: "US",
    iata_code: "TIW",
    city_name: "Tacoma",
    city: {
      name: "Tacoma",
      id: "cit_tiw_us",
      iata_country_code: "US",
      iata_code: "TIW",
    },
  },
  {
    time_zone: "America/Montevideo",
    name: "Tacuarembo Airport",
    longitude: -55.925526,
    latitude: -31.749154,
    id: "arp_taw_uy",
    icao_code: "SUTB",
    iata_country_code: "UY",
    iata_code: "TAW",
    city_name: "Tacuarembo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tadji Airport",
    longitude: 142.429805,
    latitude: -3.197688,
    id: "arp_taj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TAJ",
    city_name: "Aitape",
    city: {
      name: "Aitape",
      id: "cit_atp_pg",
      iata_country_code: "PG",
      iata_code: "ATP",
    },
  },
  {
    time_zone: "Africa/Djibouti",
    name: "Tadjoura Airport",
    longitude: 42.916999,
    latitude: 11.782999,
    id: "arp_tdj_dj",
    icao_code: "HDTJ",
    iata_country_code: "DJ",
    iata_code: "TDJ",
    city_name: "Tadjoura",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Tadoule Lake Airport",
    longitude: -98.51069,
    latitude: 58.70686,
    id: "arp_xtl_ca",
    icao_code: "CYBQ",
    iata_country_code: "CA",
    iata_code: "XTL",
    city_name: "Tadoule Lake",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Tafaraoui Airport",
    longitude: -0.542987,
    latitude: 35.549298,
    id: "arp_taf_dz",
    icao_code: "DAOL",
    iata_country_code: "DZ",
    iata_code: "TAF",
    city_name: "Oran",
    city: {
      name: "Oran",
      id: "cit_orn_dz",
      iata_country_code: "DZ",
      iata_code: "ORN",
    },
  },
  {
    time_zone: "Asia/Karachi",
    name: "Taftan Airport",
    longitude: 61.6,
    latitude: 28.9667,
    id: "arp_tft_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "TFT",
    city_name: "Taftan",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Taganrog Yuzhny Airport",
    longitude: 38.849167,
    latitude: 47.198333,
    id: "arp_tgk_ru",
    icao_code: "URRT",
    iata_country_code: "RU",
    iata_code: "TGK",
    city_name: "Taganrog",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Tagbilaran Airport",
    longitude: 123.853462,
    latitude: 9.66291,
    id: "arp_tag_ph",
    icao_code: "RPVT",
    iata_country_code: "PH",
    iata_code: "TAG",
    city_name: "Tagbilaran",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Tagbita Airport",
    longitude: 117.351742,
    latitude: 8.714959,
    id: "arp_tgb_ph",
    icao_code: null,
    iata_country_code: "PH",
    iata_code: "TGB",
    city_name: "Rizal",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tagula Airport",
    longitude: 153.203113,
    latitude: -11.331718,
    id: "arp_tgl_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TGL",
    city_name: "Tagula",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Taharoa Aerodrome",
    longitude: 174.707796,
    latitude: -38.180365,
    id: "arp_thh_nz",
    icao_code: null,
    iata_country_code: "NZ",
    iata_code: "THH",
    city_name: "Taharoa",
    city: null,
  },
  {
    time_zone: "Africa/Niamey",
    name: "Tahoua Airport",
    longitude: 5.264735,
    latitude: 14.874948,
    id: "arp_thz_ne",
    icao_code: "DRRT",
    iata_country_code: "NE",
    iata_code: "THZ",
    city_name: "Tahoua",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Tahsis Seaplane Base Airport",
    longitude: -126.653355,
    latitude: 49.921151,
    id: "arp_zts_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "ZTS",
    city_name: "Tahsis",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Taichung International Airport",
    longitude: 120.62214,
    latitude: 24.26382,
    id: "arp_rmq_tw",
    icao_code: "RCMQ",
    iata_country_code: "TW",
    iata_code: "RMQ",
    city_name: "Taichung",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Ta’if Regional Airport",
    longitude: 40.548065,
    latitude: 21.481754,
    id: "arp_tif_sa",
    icao_code: "OETF",
    iata_country_code: "SA",
    iata_code: "TIF",
    city_name: "Taif",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Tainan Airport",
    longitude: 120.205728,
    latitude: 22.950144,
    id: "arp_tnn_tw",
    icao_code: "RCNN",
    iata_country_code: "TW",
    iata_code: "TNN",
    city_name: "Tainan",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Taipei Songshan Airport",
    longitude: 121.552234,
    latitude: 25.068789,
    id: "arp_tsa_tw",
    icao_code: "RCSS",
    iata_country_code: "TW",
    iata_code: "TSA",
    city_name: "Taipei",
    city: {
      name: "Taipei",
      id: "cit_tpe_tw",
      iata_country_code: "TW",
      iata_code: "TPE",
    },
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Taiping Airport",
    longitude: 100.718724,
    latitude: 4.86501,
    id: "arp_tpg_my",
    icao_code: "WMBI",
    iata_country_code: "MY",
    iata_code: "TPG",
    city_name: "Taiping",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Taisha Airport",
    longitude: -77.503336,
    latitude: -2.382265,
    id: "arp_tsc_ec",
    icao_code: "SETH",
    iata_country_code: "EC",
    iata_code: "TSC",
    city_name: "Taisha",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Taitung Airport",
    longitude: 121.10429,
    latitude: 22.755845,
    id: "arp_ttt_tw",
    icao_code: "RCFN",
    iata_country_code: "TW",
    iata_code: "TTT",
    city_name: "Taitung",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Taiwan Taoyuan International Airport",
    longitude: 121.233609,
    latitude: 25.078675,
    id: "arp_tpe_tw",
    icao_code: "RCTP",
    iata_country_code: "TW",
    iata_code: "TPE",
    city_name: "Taipei",
    city: {
      name: "Taipei",
      id: "cit_tpe_tw",
      iata_country_code: "TW",
      iata_code: "TPE",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Taiyuan Wusu International Airport",
    longitude: 112.628166,
    latitude: 37.746921,
    id: "arp_tyn_cn",
    icao_code: "ZBYN",
    iata_country_code: "CN",
    iata_code: "TYN",
    city_name: "Taiyuan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Taizhou Luqiao Airport",
    longitude: 121.428871,
    latitude: 28.562208,
    id: "arp_hyn_cn",
    icao_code: "ZSLQ",
    iata_country_code: "CN",
    iata_code: "HYN",
    city_name: "Taizhou",
    city: null,
  },
  {
    time_zone: "Asia/Aden",
    name: "Ta'izz International Airport",
    longitude: 44.138994,
    latitude: 13.685916,
    id: "arp_tai_ye",
    icao_code: "OYTZ",
    iata_country_code: "YE",
    iata_code: "TAI",
    city_name: "Ta'izz",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tajima Airport",
    longitude: 134.787556,
    latitude: 35.514423,
    id: "arp_tjh_jp",
    icao_code: "RJBT",
    iata_country_code: "JP",
    iata_code: "TJH",
    city_name: "Toyooka",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Tak Airport",
    longitude: 99.253315,
    latitude: 16.895994,
    id: "arp_tkt_th",
    icao_code: "VTPT",
    iata_country_code: "TH",
    iata_code: "TKT",
    city_name: "Tak",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Takaka Airport",
    longitude: 172.775524,
    latitude: -40.815299,
    id: "arp_ktf_nz",
    icao_code: "NZTK",
    iata_country_code: "NZ",
    iata_code: "KTF",
    city_name: "Takaka",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Takamatsu Airport",
    longitude: 134.017207,
    latitude: 34.215655,
    id: "arp_tak_jp",
    icao_code: "RJOT",
    iata_country_code: "JP",
    iata_code: "TAK",
    city_name: "Takamatsu",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Takapoto Airport",
    longitude: -145.247183,
    latitude: -14.709577,
    id: "arp_tkp_pf",
    icao_code: "NTGT",
    iata_country_code: "PF",
    iata_code: "TKP",
    city_name: "Takapoto",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Takaroa Airport",
    longitude: -145.024936,
    latitude: -14.455831,
    id: "arp_tkx_pf",
    icao_code: "NTKR",
    iata_country_code: "PF",
    iata_code: "TKX",
    city_name: "Takaroa",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Takengon Rembele Airport",
    longitude: 96.849698,
    latitude: 4.724612,
    id: "arp_txe_id",
    icao_code: "WITK",
    iata_country_code: "ID",
    iata_code: "TXE",
    city_name: "Takengon",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Takhamalt Airport",
    longitude: 8.620485,
    latitude: 26.721075,
    id: "arp_vvz_dz",
    icao_code: "DAAP",
    iata_country_code: "DZ",
    iata_code: "VVZ",
    city_name: "Illizi",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Takhli Airport",
    longitude: 100.295998,
    latitude: 15.2773,
    id: "arp_tkh_th",
    icao_code: "VTPI",
    iata_country_code: "TH",
    iata_code: "TKH",
    city_name: "Takhli",
    city: null,
  },
  {
    time_zone: "Africa/Accra",
    name: "Takoradi Airport",
    longitude: -1.773428,
    latitude: 4.895778,
    id: "arp_tkd_gh",
    icao_code: "DGTK",
    iata_country_code: "GH",
    iata_code: "TKD",
    city_name: "Sekondi-Takoradi",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Takotna Airport",
    longitude: -156.029219,
    latitude: 62.993681,
    id: "arp_tct_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TCT",
    city_name: "Takotna",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Taku Lodge Seaplane Base Airport",
    longitude: -133.942972,
    latitude: 58.491133,
    id: "arp_tkl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TKL",
    city_name: "Taku Lodge",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Takume Airport",
    longitude: -142.26802,
    latitude: -15.855135,
    id: "arp_tjn_pf",
    icao_code: "NTKM",
    iata_country_code: "PF",
    iata_code: "TJN",
    city_name: "Takume",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Talagi Airport",
    longitude: 40.714963,
    latitude: 64.597879,
    id: "arp_arh_ru",
    icao_code: "ULAA",
    iata_country_code: "RU",
    iata_code: "ARH",
    city_name: "Arkhangelsk",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Talakan Airport",
    longitude: 111.042474,
    latitude: 59.873663,
    id: "arp_tlk_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "TLK",
    city_name: "Talakan",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Talara International Airport",
    longitude: -81.256824,
    latitude: -4.577554,
    id: "arp_tyl_pe",
    icao_code: "SPYL",
    iata_country_code: "PE",
    iata_code: "TYL",
    city_name: "Talara",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Talasea Airport",
    longitude: 150.008107,
    latitude: -5.271395,
    id: "arp_tlw_pg",
    icao_code: "AYVL",
    iata_country_code: "PG",
    iata_code: "TLW",
    city_name: "Talasea",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Taldykorgan Airport",
    longitude: 78.447395,
    latitude: 45.125665,
    id: "arp_tdk_kz",
    icao_code: "UAAT",
    iata_country_code: "KZ",
    iata_code: "TDK",
    city_name: "Taldykorgan",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Talhar Airport",
    longitude: 68.364447,
    latitude: 25.318195,
    id: "arp_bdn_pk",
    icao_code: "OPTH",
    iata_country_code: "PK",
    iata_code: "BDN",
    city_name: "Badin",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Taliabu Airport",
    longitude: 124.559406,
    latitude: -1.643038,
    id: "arp_tax_id",
    icao_code: "WAPT",
    iata_country_code: "ID",
    iata_code: "TAX",
    city_name: "Taliabu",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Talkeetna Airport",
    longitude: -150.093886,
    latitude: 62.321124,
    id: "arp_tka_us",
    icao_code: "PATK",
    iata_country_code: "US",
    iata_code: "TKA",
    city_name: "Talkeetna",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Talladega Municipal Airport",
    longitude: -86.051723,
    latitude: 33.570705,
    id: "arp_asn_us",
    icao_code: "KASN",
    iata_country_code: "US",
    iata_code: "ASN",
    city_name: "Talladega",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tallahassee International Airport",
    longitude: -84.347676,
    latitude: 30.395919,
    id: "arp_tlh_us",
    icao_code: "KTLH",
    iata_country_code: "US",
    iata_code: "TLH",
    city_name: "Tallahassee",
    city: null,
  },
  {
    time_zone: "Europe/Tallinn",
    name: "Tallinn Airport",
    longitude: 24.832857,
    latitude: 59.413576,
    id: "arp_tll_ee",
    icao_code: "EETN",
    iata_country_code: "EE",
    iata_code: "TLL",
    city_name: "Tallinn",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Taloqan Airport",
    longitude: 69.530812,
    latitude: 36.770532,
    id: "arp_tqn_af",
    icao_code: "OATQ",
    iata_country_code: "AF",
    iata_code: "TQN",
    city_name: "Taloqan",
    city: null,
  },
  {
    time_zone: "America/Cambridge_Bay",
    name: "Taloyoak Airport",
    longitude: -93.57028,
    latitude: 69.544807,
    id: "arp_yyh_ca",
    icao_code: "CYYH",
    iata_country_code: "CA",
    iata_code: "YYH",
    city_name: "Taloyoak",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Taltheilei Narrows Airport",
    longitude: -111.541785,
    latitude: 62.597009,
    id: "arp_gsl_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "GSL",
    city_name: "Taltheilei Narrows",
    city: null,
  },
  {
    time_zone: "Africa/Accra",
    name: "Tamale Airport",
    longitude: -0.862839,
    latitude: 9.555791,
    id: "arp_tml_gh",
    icao_code: "DGLE",
    iata_country_code: "GH",
    iata_code: "TML",
    city_name: "Tamale",
    city: null,
  },
  {
    time_zone: "Pacific/Tarawa",
    name: "Tamana Island Airport",
    longitude: 175.968906,
    latitude: -2.485964,
    id: "arp_tmn_ki",
    icao_code: "NGTM",
    iata_country_code: "KI",
    iata_code: "TMN",
    city_name: "Tamana",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Tamarindo Airport",
    longitude: -85.815284,
    latitude: 10.313963,
    id: "arp_tno_cr",
    icao_code: "MRTM",
    iata_country_code: "CR",
    iata_code: "TNO",
    city_name: "Tamarindo",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Tambacounda Airport",
    longitude: -13.654307,
    latitude: 13.736569,
    id: "arp_tud_sn",
    icao_code: "GOTT",
    iata_country_code: "SN",
    iata_code: "TUD",
    city_name: "Tambacounda",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Tambao Airport",
    longitude: 0.083333,
    latitude: 14.783333,
    id: "arp_tmq_bf",
    icao_code: "DFEM",
    iata_country_code: "BF",
    iata_code: "TMQ",
    city_name: "Tambao",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Tambillos Airport",
    longitude: -71.247223,
    latitude: -30.198648,
    id: "arp_cow_cl",
    icao_code: null,
    iata_country_code: "CL",
    iata_code: "COW",
    city_name: "Coquimbo",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Tambohorano Airport",
    longitude: 43.972801,
    latitude: -17.4761,
    id: "arp_wta_mg",
    icao_code: "FMMU",
    iata_country_code: "MG",
    iata_code: "WTA",
    city_name: "Tambohorano",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Tambolaka Airport",
    longitude: 119.244682,
    latitude: -9.409707,
    id: "arp_tmc_id",
    icao_code: "WADT",
    iata_country_code: "ID",
    iata_code: "TMC",
    city_name: "Tambolaka",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Tambor Airport",
    longitude: -85.015806,
    latitude: 9.739402,
    id: "arp_tmu_cr",
    icao_code: "MRTR",
    iata_country_code: "CR",
    iata_code: "TMU",
    city_name: "Tambor",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Tambov Donskoye Airport",
    longitude: 41.485667,
    latitude: 52.805296,
    id: "arp_tbw_ru",
    icao_code: "UUOT",
    iata_country_code: "RU",
    iata_code: "TBW",
    city_name: "Tambov",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Tamchakett Airport",
    longitude: -10.817,
    latitude: 17.233,
    id: "arp_tht_mr",
    icao_code: "GQNT",
    iata_country_code: "MR",
    iata_code: "THT",
    city_name: "Tamchakett",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Tam Kỳ Airport",
    longitude: 108.483579,
    latitude: 15.565749,
    id: "arp_tmk_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "TMK",
    city_name: "Tam Ky",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tampa International Airport",
    longitude: -82.53514,
    latitude: 27.979488,
    id: "arp_tpa_us",
    icao_code: "KTPA",
    iata_country_code: "US",
    iata_code: "TPA",
    city_name: "Tampa",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Tampa North Aero Park",
    longitude: -82.374556,
    latitude: 28.221278,
    id: "arp_kyo_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KYO",
    city_name: "Tampa",
    city: {
      name: "Tampa",
      id: "cit_tpa_us",
      iata_country_code: "US",
      iata_code: "TPA",
    },
  },
  {
    time_zone: "Asia/Makassar",
    name: "Tampa Padang Airport",
    longitude: 119.029152,
    latitude: -2.585671,
    id: "arp_mju_id",
    icao_code: "WAFJ",
    iata_country_code: "ID",
    iata_code: "MJU",
    city_name: "Mamuju",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Tampere-Pirkkala Airport",
    longitude: 23.604515,
    latitude: 61.414478,
    id: "arp_tmp_fi",
    icao_code: "EFTP",
    iata_country_code: "FI",
    iata_code: "TMP",
    city_name: "Tampere",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Tampico International Airport",
    longitude: -97.866932,
    latitude: 22.29327,
    id: "arp_tam_mx",
    icao_code: "MMTM",
    iata_country_code: "MX",
    iata_code: "TAM",
    city_name: "Tampico",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Tamuín National Airport",
    longitude: -98.805963,
    latitude: 22.038905,
    id: "arp_tsl_mx",
    icao_code: "MMTN",
    iata_country_code: "MX",
    iata_code: "TSL",
    city_name: "Tamuín",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Tamworth Airport",
    longitude: 150.84773,
    latitude: -31.084151,
    id: "arp_tmw_au",
    icao_code: "YSTW",
    iata_country_code: "AU",
    iata_code: "TMW",
    city_name: "Tamworth",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tanacross Airport",
    longitude: -143.335536,
    latitude: 63.37255,
    id: "arp_tsg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TSG",
    city_name: "Tanacross",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Tanah Grogot Airport",
    longitude: 116.253459,
    latitude: -1.892516,
    id: "arp_tnb_id",
    icao_code: "WRLH",
    iata_country_code: "ID",
    iata_code: "TNB",
    city_name: "Tanah Grogot",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Tanah Merah Airport",
    longitude: 140.29968,
    latitude: -6.098563,
    id: "arp_tmh_id",
    icao_code: "WAKT",
    iata_country_code: "ID",
    iata_code: "TMH",
    city_name: "Tanahmerah",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Tanbar Airport",
    longitude: 141.928131,
    latitude: -25.849056,
    id: "arp_txr_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "TXR",
    city_name: "Tanbar",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Tancredo Neves International Airport",
    longitude: -43.964763,
    latitude: -19.634271,
    id: "arp_cnf_br",
    icao_code: "SBCF",
    iata_country_code: "BR",
    iata_code: "CNF",
    city_name: "Belo Horizonte",
    city: {
      name: "Belo Horizonte",
      id: "cit_bhz_br",
      iata_country_code: "BR",
      iata_code: "BHZ",
    },
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Tancredo Thomas de Faria Airport",
    longitude: -51.520481,
    latitude: -25.387608,
    id: "arp_gpb_br",
    icao_code: "SBGU",
    iata_country_code: "BR",
    iata_code: "GPB",
    city_name: "Guarapuava",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Tandag Airport",
    longitude: 126.170824,
    latitude: 9.072326,
    id: "arp_tdg_ph",
    icao_code: "RPMW",
    iata_country_code: "PH",
    iata_code: "TDG",
    city_name: "Tandag",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Tanda Tula Airport",
    longitude: 31.299999,
    latitude: -24.5336,
    id: "arp_tdt_za",
    icao_code: "FATD",
    iata_country_code: "ZA",
    iata_code: "TDT",
    city_name: "Timbavati",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Tandil Airport",
    longitude: -59.228114,
    latitude: -37.236273,
    id: "arp_tdl_ar",
    icao_code: "SAZT",
    iata_country_code: "AR",
    iata_code: "TDL",
    city_name: "Tandil",
    city: null,
  },
  {
    time_zone: "Africa/Dar_es_Salaam",
    name: "Tanga Airport",
    longitude: 39.071725,
    latitude: -5.090903,
    id: "arp_tgt_tz",
    icao_code: "HTTG",
    iata_country_code: "TZ",
    iata_code: "TGT",
    city_name: "Tanga",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Tangalooma Airport",
    longitude: 153.363983,
    latitude: -27.132482,
    id: "arp_tan_au",
    icao_code: "YTGA",
    iata_country_code: "AU",
    iata_code: "TAN",
    city_name: "Tangalooma",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Tangará da Serra Airport",
    longitude: -57.444945,
    latitude: -14.659852,
    id: "arp_tgq_br",
    icao_code: "SWTS",
    iata_country_code: "BR",
    iata_code: "TGQ",
    city_name: "Tangara da Serra",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Tangier Ibn Battuta Airport",
    longitude: -5.915979,
    latitude: 35.726746,
    id: "arp_tng_ma",
    icao_code: "GMTT",
    iata_country_code: "MA",
    iata_code: "TNG",
    city_name: "Tangier",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tangshan Sannühe Airport",
    longitude: 118.000631,
    latitude: 39.722039,
    id: "arp_tvs_cn",
    icao_code: "ZBTS",
    iata_country_code: "CN",
    iata_code: "TVS",
    city_name: "Tangshan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tangwangcheng Airport",
    longitude: 78.989346,
    latitude: 39.880119,
    id: "arp_twc_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "TWC",
    city_name: "Tumushuke",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Tanjung Api Airport",
    longitude: 121.622802,
    latitude: -0.865295,
    id: "arp_oju_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "OJU",
    city_name: "Tojo Una Una",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Tanjung Harapan Airport",
    longitude: 117.373556,
    latitude: 2.836729,
    id: "arp_tjs_id",
    icao_code: "WAGD",
    iata_country_code: "ID",
    iata_code: "TJS",
    city_name: "Tanjung Selor",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Tanjung Manis Airport",
    longitude: 111.20263,
    latitude: 2.177142,
    id: "arp_tgc_my",
    icao_code: "WBTM",
    iata_country_code: "MY",
    iata_code: "TGC",
    city_name: "Tanjung Manis",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Tanjung Pelepas Port Airport",
    longitude: 110.483,
    latitude: 1.58333,
    id: "arp_zjt_my",
    icao_code: null,
    iata_country_code: "MY",
    iata_code: "ZJT",
    city_name: "Tanjung Pelepas",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Tanjung Santan Airport",
    longitude: 117.480048,
    latitude: 0.035016,
    id: "arp_tsx_id",
    icao_code: "WALT",
    iata_country_code: "ID",
    iata_code: "TSX",
    city_name: "Tanjung Santan",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Tanna Airport",
    longitude: 169.223302,
    latitude: -19.453739,
    id: "arp_tah_vu",
    icao_code: "NVVW",
    iata_country_code: "VU",
    iata_code: "TAH",
    city_name: "Tanna",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Tan Son Nhat International Airport",
    longitude: 106.655412,
    latitude: 10.818631,
    id: "arp_sgn_vn",
    icao_code: "VVTS",
    iata_country_code: "VN",
    iata_code: "SGN",
    city_name: "Ho Chi Minh City",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Tan Tan Airport",
    longitude: -11.160703,
    latitude: 28.447428,
    id: "arp_tta_ma",
    icao_code: "GMAT",
    iata_country_code: "MA",
    iata_code: "TTA",
    city_name: "Tan-Tan",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Taos Regional Airport",
    longitude: -105.672322,
    latitude: 36.458213,
    id: "arp_tsm_us",
    icao_code: "KSKX",
    iata_country_code: "US",
    iata_code: "TSM",
    city_name: "Taos",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Tapachula International Airport",
    longitude: -92.369371,
    latitude: 14.79238,
    id: "arp_tap_mx",
    icao_code: "MMTP",
    iata_country_code: "MX",
    iata_code: "TAP",
    city_name: "Tapachula",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Tapeta Airport",
    longitude: -8.872168,
    latitude: 6.494801,
    id: "arp_tpt_lr",
    icao_code: null,
    iata_country_code: "LR",
    iata_code: "TPT",
    city_name: "Tapeta",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tapini Airport",
    longitude: 146.988705,
    latitude: -8.356624,
    id: "arp_tpi_pg",
    icao_code: "AYTI",
    iata_country_code: "PG",
    iata_code: "TPI",
    city_name: "Tapini",
    city: null,
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Taplejung Airport",
    longitude: 87.695779,
    latitude: 27.351087,
    id: "arp_tpj_np",
    icao_code: "VNTJ",
    iata_country_code: "NP",
    iata_code: "TPJ",
    city_name: "Taplejung",
    city: null,
  },
  {
    time_zone: "America/Boa_Vista",
    name: "Tapuruquara Airport",
    longitude: -64.993634,
    latitude: -0.379029,
    id: "arp_irz_br",
    icao_code: "SWTP",
    iata_country_code: "BR",
    iata_code: "IRZ",
    city_name: "Santa Isabel Rio Negro",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Tara Airport",
    longitude: 150.47556,
    latitude: -27.159598,
    id: "arp_xtr_au",
    icao_code: "YTAA",
    iata_country_code: "AU",
    iata_code: "XTR",
    city_name: "Tara",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tarabo Airport",
    longitude: 22.75,
    latitude: 12.3,
    id: "arp_tbq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TBQ",
    city_name: "Tarabo",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tarakbits Airport",
    longitude: 141.042824,
    latitude: -5.614235,
    id: "arp_trj_pg",
    icao_code: "AYTT",
    iata_country_code: "PG",
    iata_code: "TRJ",
    city_name: "Tarakbits",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tarama Airport",
    longitude: 124.67525,
    latitude: 24.653867,
    id: "arp_tra_jp",
    icao_code: "RORT",
    iata_country_code: "JP",
    iata_code: "TRA",
    city_name: "Tarama",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Taranto-Grottaglie Airport",
    longitude: 17.402933,
    latitude: 40.518475,
    id: "arp_tar_it",
    icao_code: "LIBG",
    iata_country_code: "IT",
    iata_code: "TAR",
    city_name: "Grottaglie",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Tarapacá Airport",
    longitude: -69.749629,
    latitude: -2.895771,
    id: "arp_tcd_co",
    icao_code: "SKRA",
    iata_country_code: "CO",
    iata_code: "TCD",
    city_name: "Tarapacá",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Tarapaina Airport",
    longitude: 161.383548,
    latitude: -9.43327,
    id: "arp_taa_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "TAA",
    city_name: "Tarapaina",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Tarapoa Airport",
    longitude: -76.339255,
    latitude: -0.121102,
    id: "arp_tpc_ec",
    icao_code: "SETR",
    iata_country_code: "EC",
    iata_code: "TPC",
    city_name: "Tarapoa",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Tarapoto Airport",
    longitude: -76.371809,
    latitude: -6.509843,
    id: "arp_tpp_pe",
    icao_code: "SPST",
    iata_country_code: "PE",
    iata_code: "TPP",
    city_name: "Tarapoto",
    city: null,
  },
  {
    time_zone: "America/Rio_Branco",
    name: "Tarauacá Airport",
    longitude: -70.782151,
    latitude: -8.156099,
    id: "arp_trq_br",
    icao_code: "SBTK",
    iata_country_code: "BR",
    iata_code: "TRQ",
    city_name: "Tarauacá",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Taraz Airport",
    longitude: 71.303026,
    latitude: 42.854062,
    id: "arp_dmb_kz",
    icao_code: "UADD",
    iata_country_code: "KZ",
    iata_code: "DMB",
    city_name: "Taraz",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Tarbela Dam Airport",
    longitude: 72.609998,
    latitude: 33.982995,
    id: "arp_tlb_pk",
    icao_code: "OPTA",
    iata_country_code: "PK",
    iata_code: "TLB",
    city_name: "Tarbela Dam",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Tarbes–Lourdes–Pyrénées Airport",
    longitude: -0.003711,
    latitude: 43.180688,
    id: "arp_lde_fr",
    icao_code: "LFBT",
    iata_country_code: "FR",
    iata_code: "LDE",
    city_name: "Lourdes",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Tarcoola Airport",
    longitude: 134.583716,
    latitude: -30.703008,
    id: "arp_taq_au",
    icao_code: "YTAR",
    iata_country_code: "AU",
    iata_code: "TAQ",
    city_name: "Tarcoola",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Taree Airport",
    longitude: 152.511683,
    latitude: -31.889507,
    id: "arp_tro_au",
    icao_code: "YTRE",
    iata_country_code: "AU",
    iata_code: "TRO",
    city_name: "Taree",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Tarempa Airport",
    longitude: 106.257685,
    latitude: 3.346672,
    id: "arp_mwk_id",
    icao_code: "WIOM",
    iata_country_code: "ID",
    iata_code: "MWK",
    city_name: "Matak",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Tarfaya Airport",
    longitude: -12.917309,
    latitude: 27.948475,
    id: "arp_tfy_ma",
    icao_code: null,
    iata_country_code: "MA",
    iata_code: "TFY",
    city_name: "Krui",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Târgu Mureș International Airport",
    longitude: 24.416237,
    latitude: 46.468037,
    id: "arp_tgm_ro",
    icao_code: "LRTM",
    iata_country_code: "RO",
    iata_code: "TGM",
    city_name: "Tirgu Mures",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tari Airport",
    longitude: 142.947846,
    latitude: -5.8447,
    id: "arp_tiz_pg",
    icao_code: "AYTA",
    iata_country_code: "PG",
    iata_code: "TIZ",
    city_name: "Tari",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Tarin Kowt Airport",
    longitude: 65.865798,
    latitude: 32.604198,
    id: "arp_tii_af",
    icao_code: "OATN",
    iata_country_code: "AF",
    iata_code: "TII",
    city_name: "Tarin Kowt",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Tarko-Sale Airport",
    longitude: 77.8181,
    latitude: 64.930801,
    id: "arp_tql_ru",
    icao_code: "USDS",
    iata_country_code: "RU",
    iata_code: "TQL",
    city_name: "Tarko-Sale",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Taroom Airport",
    longitude: 149.897992,
    latitude: -25.801872,
    id: "arp_xto_au",
    icao_code: "YTAM",
    iata_country_code: "AU",
    iata_code: "XTO",
    city_name: "Taroom",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Tartagal Airport",
    longitude: -63.79429,
    latitude: -22.618678,
    id: "arp_ttg_ar",
    icao_code: "SAST",
    iata_country_code: "AR",
    iata_code: "TTG",
    city_name: "Tartagal",
    city: null,
  },
  {
    time_zone: "Europe/Tallinn",
    name: "Tartu Airport",
    longitude: 26.689866,
    latitude: 58.308502,
    id: "arp_tay_ee",
    icao_code: "EETU",
    iata_country_code: "EE",
    iata_code: "TAY",
    city_name: "Tartu",
    city: null,
  },
  {
    time_zone: "Asia/Tashkent",
    name: "Tashkent International Airport",
    longitude: 69.280718,
    latitude: 41.258522,
    id: "arp_tas_uz",
    icao_code: "UTTT",
    iata_country_code: "UZ",
    iata_code: "TAS",
    city_name: "Tashkent",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Tasikmalaya Wiriadinata Airport",
    longitude: 108.24608,
    latitude: -7.346703,
    id: "arp_tsy_id",
    icao_code: "WICM",
    iata_country_code: "ID",
    iata_code: "TSY",
    city_name: "Tasikmalaya",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Tasiujaq Airport",
    longitude: -69.954143,
    latitude: 58.668568,
    id: "arp_ytq_ca",
    icao_code: "CYTQ",
    iata_country_code: "CA",
    iata_code: "YTQ",
    city_name: "Tasiujaq",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Taskul Airport",
    longitude: 150.4553,
    latitude: -2.546,
    id: "arp_tsk_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TSK",
    city_name: "Taskul",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Tasu Water Aerodrome",
    longitude: -132.03915,
    latitude: 52.761138,
    id: "arp_ytu_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YTU",
    city_name: "Tasu",
    city: null,
  },
  {
    time_zone: "Africa/Conakry",
    name: "Tata Airport",
    longitude: -12.2868,
    latitude: 11.3261,
    id: "arp_lek_gn",
    icao_code: "GULB",
    iata_country_code: "GN",
    iata_code: "LEK",
    city_name: "Labé",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Tatakoto Airport",
    longitude: -138.445888,
    latitude: -17.355593,
    id: "arp_tkv_pf",
    icao_code: "NTGO",
    iata_country_code: "PF",
    iata_code: "TKV",
    city_name: "Tatakoto",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tatalina LRRS Airport",
    longitude: -155.977008,
    latitude: 62.894716,
    id: "arp_tlj_us",
    icao_code: "PATL",
    iata_country_code: "US",
    iata_code: "TLJ",
    city_name: "Tatalina",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tatitlek Airport",
    longitude: -146.690732,
    latitude: 60.871919,
    id: "arp_tek_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TEK",
    city_name: "Tatitlek",
    city: null,
  },
  {
    time_zone: "Pacific/Pago_Pago",
    name: "Tau Airport",
    longitude: -169.511055,
    latitude: -14.229184,
    id: "arp_tav_as",
    icao_code: null,
    iata_country_code: "AS",
    iata_code: "TAV",
    city_name: "Tau",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Taupo Airport",
    longitude: 176.083568,
    latitude: -38.73979,
    id: "arp_tuo_nz",
    icao_code: "NZAP",
    iata_country_code: "NZ",
    iata_code: "TUO",
    city_name: "Taupo",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Tauramena Airport",
    longitude: -72.7333,
    latitude: 5.0,
    id: "arp_tau_co",
    icao_code: "SKTA",
    iata_country_code: "CO",
    iata_code: "TAU",
    city_name: "Tauramena",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Tauranga Airport",
    longitude: 176.196865,
    latitude: -37.671444,
    id: "arp_trg_nz",
    icao_code: "NZTG",
    iata_country_code: "NZ",
    iata_code: "TRG",
    city_name: "Tauranga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tauta Airport",
    longitude: 145.917,
    latitude: -5.85,
    id: "arp_tut_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TUT",
    city_name: "Tauta",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tawa Airport",
    longitude: 146.106955,
    latitude: -7.445982,
    id: "arp_twy_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TWY",
    city_name: "Tawa",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Tawau Airport",
    longitude: 118.11464,
    latitude: 4.31383,
    id: "arp_twu_my",
    icao_code: "WBKW",
    iata_country_code: "MY",
    iata_code: "TWU",
    city_name: "Tawau",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Taylor Airport",
    longitude: -97.443881,
    latitude: 30.572312,
    id: "arp_twe_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TWE",
    city_name: "Taylor",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Taylor Airport",
    longitude: -97.443291,
    latitude: 30.572127,
    id: "arp_tyz_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TYZ",
    city_name: "Taylor",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Taylor County Airport",
    longitude: -85.313601,
    latitude: 37.356616,
    id: "arp_mdf_us",
    icao_code: "KMDZ",
    iata_country_code: "US",
    iata_code: "MDF",
    city_name: "Medford",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Tazadit Airport",
    longitude: -12.47973,
    latitude: 22.755276,
    id: "arp_ouz_mr",
    icao_code: "GQPZ",
    iata_country_code: "MR",
    iata_code: "OUZ",
    city_name: "Zouérat",
    city: null,
  },
  {
    time_zone: "Asia/Tbilisi",
    name: "Tbilisi International Airport",
    longitude: 44.957642,
    latitude: 41.669428,
    id: "arp_tbs_ge",
    icao_code: "UGTB",
    iata_country_code: "GE",
    iata_code: "TBS",
    city_name: "Tbilisi",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Tchibanga Airport",
    longitude: 11.017,
    latitude: -2.849999,
    id: "arp_tch_ga",
    icao_code: "FOOT",
    iata_country_code: "GA",
    iata_code: "TCH",
    city_name: "Tchibanga",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Tchien Airport",
    longitude: -8.138927,
    latitude: 6.049545,
    id: "arp_thc_lr",
    icao_code: "GLTN",
    iata_country_code: "LR",
    iata_code: "THC",
    city_name: "Tchien",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Tchongorove Airport",
    longitude: 9.316667,
    latitude: -1.916667,
    id: "arp_ige_ga",
    icao_code: "FOOI",
    iata_country_code: "GA",
    iata_code: "IGE",
    city_name: "Iguela",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ted Stevens Anchorage International Airport",
    longitude: -149.99304,
    latitude: 61.175143,
    id: "arp_anc_us",
    icao_code: "PANC",
    iata_country_code: "US",
    iata_code: "ANC",
    city_name: "Anchorage",
    city: {
      name: "Anchorage",
      id: "cit_anc_us",
      iata_country_code: "US",
      iata_code: "ANC",
    },
  },
  {
    time_zone: "America/Manaus",
    name: "Tefé Airport",
    longitude: -64.724596,
    latitude: -3.381671,
    id: "arp_tff_br",
    icao_code: "SBTF",
    iata_country_code: "BR",
    iata_code: "TFF",
    city_name: "Tefé",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Tehachapi Municipal Airport",
    longitude: -118.440345,
    latitude: 35.13507,
    id: "arp_tsp_us",
    icao_code: "KTSP",
    iata_country_code: "US",
    iata_code: "TSP",
    city_name: "Tehachapi",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Tehran Imam Khomeini International Airport",
    longitude: 51.153727,
    latitude: 35.412583,
    id: "arp_ika_ir",
    icao_code: "OIIE",
    iata_country_code: "IR",
    iata_code: "IKA",
    city_name: "Tehran",
    city: {
      name: "Tehran",
      id: "cit_thr_ir",
      iata_country_code: "IR",
      iata_code: "THR",
    },
  },
  {
    time_zone: "America/Mexico_City",
    name: "Tehuacán Airport",
    longitude: -97.418339,
    latitude: 18.495112,
    id: "arp_tcn_mx",
    icao_code: "MMHC",
    iata_country_code: "MX",
    iata_code: "TCN",
    city_name: "Tehuacan",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Teixeira de Freitas Airport",
    longitude: -39.668694,
    latitude: -17.523983,
    id: "arp_txf_br",
    icao_code: "SNTF",
    iata_country_code: "BR",
    iata_code: "TXF",
    city_name: "Teixeira de Freitas",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tekadu Airport",
    longitude: 146.550557,
    latitude: -7.680757,
    id: "arp_tkb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TKB",
    city_name: "Tekadu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tekin Airport",
    longitude: 142.164926,
    latitude: -5.24356,
    id: "arp_tkw_pg",
    icao_code: "AYTN",
    iata_country_code: "PG",
    iata_code: "TKW",
    city_name: "Tekin",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Tekirdağ Çorlu Airport",
    longitude: 27.916584,
    latitude: 41.1366,
    id: "arp_teq_tr",
    icao_code: "LTBU",
    iata_country_code: "TR",
    iata_code: "TEQ",
    city_name: "Çorlu",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Tela Airport",
    longitude: -87.474427,
    latitude: 15.777075,
    id: "arp_tea_hn",
    icao_code: "MHTE",
    iata_country_code: "HN",
    iata_code: "TEA",
    city_name: "Tela",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Telefomin Airport",
    longitude: 141.641192,
    latitude: -5.126719,
    id: "arp_tfm_pg",
    icao_code: "AYTE",
    iata_country_code: "PG",
    iata_code: "TFM",
    city_name: "Telefomin",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Telegraph Creek Airport",
    longitude: -131.11978,
    latitude: 57.915567,
    id: "arp_ytx_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YTX",
    city_name: "Telegraph Creek",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Telegraph Harbour Seaplane Base",
    longitude: -123.662412,
    latitude: 48.970711,
    id: "arp_ybq_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YBQ",
    city_name: "Thetis Island",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Telêmaco Borba Airport",
    longitude: -50.652567,
    latitude: -24.319526,
    id: "arp_tec_br",
    icao_code: "SBTL",
    iata_country_code: "BR",
    iata_code: "TEC",
    city_name: "Telêmaco Borba",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Telfair-Wheeler Airport",
    longitude: -82.882248,
    latitude: 32.094617,
    id: "arp_mqw_us",
    icao_code: "KMQW",
    iata_country_code: "US",
    iata_code: "MQW",
    city_name: "McRae",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Telfer Airport",
    longitude: 122.22731,
    latitude: -21.713147,
    id: "arp_tef_au",
    icao_code: "YTEF",
    iata_country_code: "AU",
    iata_code: "TEF",
    city_name: "Telfer",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Telida Airport",
    longitude: -153.284673,
    latitude: 63.378889,
    id: "arp_tlf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TLF",
    city_name: "Telida",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Teller Airport",
    longitude: -166.339047,
    latitude: 65.240385,
    id: "arp_tla_us",
    icao_code: "PATE",
    iata_country_code: "US",
    iata_code: "TLA",
    city_name: "Teller",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Telluride Regional Airport",
    longitude: -107.906524,
    latitude: 37.953773,
    id: "arp_tex_us",
    icao_code: "KTEX",
    iata_country_code: "US",
    iata_code: "TEX",
    city_name: "Telluride",
    city: null,
  },
  {
    time_zone: "Asia/Kuching",
    name: "Telupid Airport",
    longitude: 117.125238,
    latitude: 5.628909,
    id: "arp_tel_my",
    icao_code: "WBKE",
    iata_country_code: "MY",
    iata_code: "TEL",
    city_name: "Telupid",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Teminabuan Airport",
    longitude: 132.019952,
    latitude: -1.444338,
    id: "arp_txm_id",
    icao_code: "WAST",
    iata_country_code: "ID",
    iata_code: "TXM",
    city_name: "Teminabuan",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Temora Airport",
    longitude: 147.513409,
    latitude: -34.424223,
    id: "arp_tem_au",
    icao_code: "YTEM",
    iata_country_code: "AU",
    iata_code: "TEM",
    city_name: "Temora",
    city: null,
  },
  {
    time_zone: "America/Juneau",
    name: "Tenakee Seaplane Base",
    longitude: -135.218209,
    latitude: 57.779542,
    id: "arp_tke_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TKE",
    city_name: "Tenakee Springs",
    city: null,
  },
  {
    time_zone: "Atlantic/Canary",
    name: "Tenerife North Airport",
    longitude: -16.34246,
    latitude: 28.483605,
    id: "arp_tfn_es",
    icao_code: "GCXO",
    iata_country_code: "ES",
    iata_code: "TFN",
    city_name: "Tenerife",
    city: {
      name: "Tenerife",
      id: "cit_tci_es",
      iata_country_code: "ES",
      iata_code: "TCI",
    },
  },
  {
    time_zone: "Atlantic/Canary",
    name: "Tenerife South Airport",
    longitude: -16.573436,
    latitude: 28.045382,
    id: "arp_tfs_es",
    icao_code: "GCTS",
    iata_country_code: "ES",
    iata_code: "TFS",
    city_name: "Tenerife",
    city: {
      name: "Tenerife",
      id: "cit_tci_es",
      iata_country_code: "ES",
      iata_code: "TCI",
    },
  },
  {
    time_zone: "Asia/Singapore",
    name: "Tengah Air Base",
    longitude: 103.708617,
    latitude: 1.385789,
    id: "arp_tga_sg",
    icao_code: "WSAT",
    iata_country_code: "SG",
    iata_code: "TGA",
    city_name: "Singapore",
    city: {
      name: "Singapore",
      id: "cit_sin_sg",
      iata_country_code: "SG",
      iata_code: "SIN",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tengchong Tuofeng Airport",
    longitude: 98.484806,
    latitude: 24.937509,
    id: "arp_tcz_cn",
    icao_code: "ZUTC",
    iata_country_code: "CN",
    iata_code: "TCZ",
    city_name: "Tengchong",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Teniente Amin Ayub Gonzalez Airport",
    longitude: -55.837353,
    latitude: -27.227349,
    id: "arp_eno_py",
    icao_code: "SGEN",
    iata_country_code: "PY",
    iata_code: "ENO",
    city_name: "Encarnacion",
    city: null,
  },
  {
    time_zone: "America/Asuncion",
    name: "Teniente Coronel Carmelo Peralta Airport",
    longitude: -57.427294,
    latitude: -23.442308,
    id: "arp_cio_py",
    icao_code: "SGCO",
    iata_country_code: "PY",
    iata_code: "CIO",
    city_name: "Concepcion",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Teniente Coronel Luis A. Mantilla International Airport",
    longitude: -77.707123,
    latitude: 0.811146,
    id: "arp_tua_ec",
    icao_code: "SETU",
    iata_country_code: "EC",
    iata_code: "TUA",
    city_name: "Tulcán",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Teniente FAP Jaime Montreuil Morales Airport",
    longitude: -78.52546,
    latitude: -9.148927,
    id: "arp_chm_pe",
    icao_code: "SPEO",
    iata_country_code: "PE",
    iata_code: "CHM",
    city_name: "Chimbote",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Teniente Jorge Henrich Arauz Airport",
    longitude: -64.918654,
    latitude: -14.820656,
    id: "arp_tdd_bo",
    icao_code: "SLTR",
    iata_country_code: "BO",
    iata_code: "TDD",
    city_name: "Trinidad",
    city: null,
  },
  {
    time_zone: "America/Punta_Arenas",
    name: "Teniente Julio Gallardo Airport",
    longitude: -72.527116,
    latitude: -51.671115,
    id: "arp_pnt_cl",
    icao_code: "SCNT",
    iata_country_code: "CL",
    iata_code: "PNT",
    city_name: "Puerto Natales",
    city: null,
  },
  {
    time_zone: "Antarctica/South_Pole",
    name: "Teniente Rodolfo Marsh Martin Airport",
    longitude: -58.986462,
    latitude: -62.190917,
    id: "arp_tnm_aq",
    icao_code: "SCRM",
    iata_country_code: "AQ",
    iata_code: "TNM",
    city_name: "King George Island",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Teniente Vidal Airport",
    longitude: -72.105829,
    latitude: -45.593974,
    id: "arp_gxq_cl",
    icao_code: "SCCY",
    iata_country_code: "CL",
    iata_code: "GXQ",
    city_name: "Coyhaique",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Tenkodogo Airport",
    longitude: -0.369968,
    latitude: 11.801448,
    id: "arp_teg_bf",
    icao_code: "DFET",
    iata_country_code: "BF",
    iata_code: "TEG",
    city_name: "Tenkodogo",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Tennant Creek Airport",
    longitude: 134.182855,
    latitude: -19.636981,
    id: "arp_tca_au",
    icao_code: "YTNK",
    iata_country_code: "AU",
    iata_code: "TCA",
    city_name: "Tennant Creek",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Tenzing-Hillary Airport",
    longitude: 86.730744,
    latitude: 27.687215,
    id: "arp_lua_np",
    icao_code: "VNLK",
    iata_country_code: "NP",
    iata_code: "LUA",
    city_name: "Lukla",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Tepic Airport",
    longitude: -104.841258,
    latitude: 21.419511,
    id: "arp_tpq_mx",
    icao_code: "MMEP",
    iata_country_code: "MX",
    iata_code: "TPQ",
    city_name: "Tepic",
    city: null,
  },
  {
    time_zone: "Asia/Khandyga",
    name: "Teply Klyuch Airport",
    longitude: 136.853681,
    latitude: 62.788553,
    id: "arp_kdy_ru",
    icao_code: "UEMH",
    iata_country_code: "RU",
    iata_code: "KDY",
    city_name: "Khandyga",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Tepoe Airstrip",
    longitude: -55.716283,
    latitude: 3.156827,
    id: "arp_kcb_sr",
    icao_code: "SMTP",
    iata_country_code: "SR",
    iata_code: "KCB",
    city_name: "Kasikasima",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Teptep Airport",
    longitude: 146.567,
    latitude: -5.95,
    id: "arp_tep_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TEP",
    city_name: "Teptep",
    city: null,
  },
  {
    time_zone: "Pacific/Kiritimati",
    name: "Teraina Airport",
    longitude: -160.393878,
    latitude: 4.698528,
    id: "arp_tnq_ki",
    icao_code: null,
    iata_country_code: "KI",
    iata_code: "TNQ",
    city_name: "Teraina",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Terapo Airport",
    longitude: 146.194262,
    latitude: -8.16917,
    id: "arp_teo_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TEO",
    city_name: "Terapo",
    city: null,
  },
  {
    time_zone: "America/Fortaleza",
    name: "Teresina–Senador Petrônio Portella Airport",
    longitude: -42.822731,
    latitude: -5.061114,
    id: "arp_the_br",
    icao_code: "SBTE",
    iata_country_code: "BR",
    iata_code: "THE",
    city_name: "Teresina",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Termal Airport",
    longitude: -60.49385,
    latitude: -26.754923,
    id: "arp_prq_ar",
    icao_code: "SARS",
    iata_country_code: "AR",
    iata_code: "PRQ",
    city_name: "Presidencia Roque Sáenz Peña",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Termas de Rio Hondo Airport",
    longitude: -64.933281,
    latitude: -27.506983,
    id: "arp_rhd_ar",
    icao_code: "SANR",
    iata_country_code: "AR",
    iata_code: "RHD",
    city_name: "Termas de Río Hondo",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Termez Airport",
    longitude: 67.309997,
    latitude: 37.286701,
    id: "arp_tmj_uz",
    icao_code: "UTST",
    iata_country_code: "UZ",
    iata_code: "TMJ",
    city_name: "Termez",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Ternate Airport",
    longitude: 127.380452,
    latitude: 0.831766,
    id: "arp_tte_id",
    icao_code: "WAEE",
    iata_country_code: "ID",
    iata_code: "TTE",
    city_name: "Ternate",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Terney Airport",
    longitude: 136.58957,
    latitude: 45.082053,
    id: "arp_nei_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "NEI",
    city_name: "Terney",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Ternopil International Airport",
    longitude: 25.670747,
    latitude: 49.538347,
    id: "arp_tnl_ua",
    icao_code: "UKLT",
    iata_country_code: "UA",
    iata_code: "TNL",
    city_name: "Ternopil",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Terrace Bay Airport",
    longitude: -121.436701,
    latitude: 37.689199,
    id: "arp_tcy_ca",
    icao_code: "FYTE",
    iata_country_code: "CA",
    iata_code: "TCY",
    city_name: "Terrace Bay",
    city: null,
  },
  {
    time_zone: "America/Tortola",
    name: "Terrance B. Lettsome International Airport",
    longitude: -64.540895,
    latitude: 18.444547,
    id: "arp_eis_vg",
    icao_code: "TUPJ",
    iata_country_code: "VG",
    iata_code: "EIS",
    city_name: "Tortola",
    city: {
      name: "Tortola",
      id: "cit_tov_vg",
      iata_country_code: "VG",
      iata_code: "TOV",
    },
  },
  {
    time_zone: "America/Guadeloupe",
    name: "Terre-de-Haut Airport",
    longitude: -61.580475,
    latitude: 15.864505,
    id: "arp_lss_gp",
    icao_code: "TFFS",
    iata_country_code: "GP",
    iata_code: "LSS",
    city_name: "Les Saintes",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Terre Haute Regional Airport",
    longitude: -87.308037,
    latitude: 39.451623,
    id: "arp_huf_us",
    icao_code: "KHUF",
    iata_country_code: "US",
    iata_code: "HUF",
    city_name: "Terre Haute",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Terrell Municipal Airport",
    longitude: -96.268913,
    latitude: 32.710373,
    id: "arp_trl_us",
    icao_code: "KTRL",
    iata_country_code: "US",
    iata_code: "TRL",
    city_name: "Terrell",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Teruel Airport",
    longitude: -1.2183,
    latitude: 40.403,
    id: "arp_tev_es",
    icao_code: "LETL",
    iata_country_code: "ES",
    iata_code: "TEV",
    city_name: "Teruel",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Teseney Airport",
    longitude: 36.329402,
    latitude: 15.386925,
    id: "arp_tes_er",
    icao_code: "HHTS",
    iata_country_code: "ER",
    iata_code: "TES",
    city_name: "Teseney",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Teslin Airport",
    longitude: -132.740723,
    latitude: 60.173308,
    id: "arp_yzw_ca",
    icao_code: "CYZW",
    iata_country_code: "CA",
    iata_code: "YZW",
    city_name: "Teslin",
    city: null,
  },
  {
    time_zone: "America/Blanc-Sablon",
    name: "Tête-à-la-Baleine Airport",
    longitude: -59.383605,
    latitude: 50.674447,
    id: "arp_ztb_ca",
    icao_code: "CTB6",
    iata_country_code: "CA",
    iata_code: "ZTB",
    city_name: "Tête-à-la-Baleine",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tetebedi Airport",
    longitude: 148.068579,
    latitude: -9.158822,
    id: "arp_tdb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TDB",
    city_name: "Tetebedi",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Teterboro Airport",
    longitude: -74.061519,
    latitude: 40.851889,
    id: "arp_teb_us",
    icao_code: "KTEB",
    iata_country_code: "US",
    iata_code: "TEB",
    city_name: "Teterboro",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Tetiaroa Airport",
    longitude: -149.587005,
    latitude: -17.013299,
    id: "arp_tti_pf",
    icao_code: "NTTE",
    iata_country_code: "PF",
    iata_code: "TTI",
    city_name: "Tetiaroa",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tetlin Airport",
    longitude: -142.52244,
    latitude: 63.133786,
    id: "arp_teh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TEH",
    city_name: "Tetlin",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Teuku Cut Ali Airport",
    longitude: 97.286972,
    latitude: 3.170629,
    id: "arp_tpk_id",
    icao_code: "WITA",
    iata_country_code: "ID",
    iata_code: "TPK",
    city_name: "Tapaktuan",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Tewantin Airport",
    longitude: 153.033,
    latitude: -26.3833,
    id: "arp_twn_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "TWN",
    city_name: "Tewantin",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Texarkana Regional Airport",
    longitude: -93.991951,
    latitude: 33.455147,
    id: "arp_txk_us",
    icao_code: "KTXK",
    iata_country_code: "US",
    iata_code: "TXK",
    city_name: "Texarkana",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Texas Gulf Coast Regional Airport",
    longitude: -95.460996,
    latitude: 29.1098,
    id: "arp_ljn_us",
    icao_code: "KLBX",
    iata_country_code: "US",
    iata_code: "LJN",
    city_name: "Lake Jackson/Angleton",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Tezpur Airport",
    longitude: 92.782373,
    latitude: 26.708412,
    id: "arp_tez_in",
    icao_code: "VETZ",
    iata_country_code: "IN",
    iata_code: "TEZ",
    city_name: "Tezpur",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Tezu Airport",
    longitude: 96.134316,
    latitude: 27.940889,
    id: "arp_tei_in",
    icao_code: "VETJ",
    iata_country_code: "IN",
    iata_code: "TEI",
    city_name: "Tezu",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "T. F. Green International Airport",
    longitude: -71.432123,
    latitude: 41.724975,
    id: "arp_pvd_us",
    icao_code: "KPVD",
    iata_country_code: "US",
    iata_code: "PVD",
    city_name: "Providence",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Thaba Nchu Airport",
    longitude: 26.82071,
    latitude: -29.316455,
    id: "arp_tcu_za",
    icao_code: "FATN",
    iata_country_code: "ZA",
    iata_code: "TCU",
    city_name: "Thaba Nchu",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Thaba-Tseka Airport",
    longitude: 28.617857,
    latitude: -29.522401,
    id: "arp_thb_ls",
    icao_code: "FXTA",
    iata_country_code: "LS",
    iata_code: "THB",
    city_name: "Thaba-Tseka",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Thakhek Airport",
    longitude: 104.643747,
    latitude: 17.384845,
    id: "arp_thk_la",
    icao_code: "VLTK",
    iata_country_code: "LA",
    iata_code: "THK",
    city_name: "Thakhek",
    city: null,
  },
  {
    time_zone: "Asia/Dhaka",
    name: "Thakurgaon Airport",
    longitude: 88.407091,
    latitude: 26.017302,
    id: "arp_tkr_bd",
    icao_code: "VGSG",
    iata_country_code: "BD",
    iata_code: "TKR",
    city_name: "Thakurgaon",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Thames Airport",
    longitude: 175.551077,
    latitude: -37.157705,
    id: "arp_tmz_nz",
    icao_code: "NZTH",
    iata_country_code: "NZ",
    iata_code: "TMZ",
    city_name: "Thames",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Thandwe Airport",
    longitude: 94.299801,
    latitude: 18.460122,
    id: "arp_snw_mm",
    icao_code: "VYTD",
    iata_country_code: "MM",
    iata_code: "SNW",
    city_name: "Thandwe",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Thangool Airport",
    longitude: 150.574835,
    latitude: -24.492812,
    id: "arp_thg_au",
    icao_code: "YTNG",
    iata_country_code: "AU",
    iata_code: "THG",
    city_name: "Thangool",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Thanjavur Air Force Station Airport",
    longitude: 79.102123,
    latitude: 10.722798,
    id: "arp_tjv_in",
    icao_code: "VOTJ",
    iata_country_code: "IN",
    iata_code: "TJV",
    city_name: "Thanjavur",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Thargomindah Airport",
    longitude: 143.812699,
    latitude: -27.987955,
    id: "arp_xtg_au",
    icao_code: "YTGM",
    iata_country_code: "AU",
    iata_code: "XTG",
    city_name: "Thargomindah",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Theda Station Airport",
    longitude: 126.497341,
    latitude: -14.791994,
    id: "arp_tdn_au",
    icao_code: "YTHD",
    iata_country_code: "AU",
    iata_code: "TDN",
    city_name: "Theda Station",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "The Eastern Iowa Airport",
    longitude: -91.708887,
    latitude: 41.885543,
    id: "arp_cid_us",
    icao_code: "KCID",
    iata_country_code: "US",
    iata_code: "CID",
    city_name: "Cedar Rapids",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "The Granites Airport",
    longitude: 130.347581,
    latitude: -20.547306,
    id: "arp_gts_au",
    icao_code: "YTGT",
    iata_country_code: "AU",
    iata_code: "GTS",
    city_name: "The Granites",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "The Monument Airport",
    longitude: 139.923848,
    latitude: -21.811776,
    id: "arp_phq_au",
    icao_code: "YTMO",
    iata_country_code: "AU",
    iata_code: "PHQ",
    city_name: "Phosphate Hill",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Theodore Airport",
    longitude: 150.093448,
    latitude: -24.992642,
    id: "arp_tdr_au",
    icao_code: "YTDR",
    iata_country_code: "AU",
    iata_code: "TDR",
    city_name: "Theodore",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "The Pas Airport",
    longitude: -101.090591,
    latitude: 53.972096,
    id: "arp_yqd_ca",
    icao_code: "CYQD",
    iata_country_code: "CA",
    iata_code: "YQD",
    city_name: "The Pas",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: 'Thessaloniki Airport "Makedonia"',
    longitude: 22.971288,
    latitude: 40.520061,
    id: "arp_skg_gr",
    icao_code: "LGTS",
    iata_country_code: "GR",
    iata_code: "SKG",
    city_name: "Thessaloniki",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Thicket Portage Airport",
    longitude: -97.707786,
    latitude: 55.319068,
    id: "arp_ytd_ca",
    icao_code: "CZLQ",
    iata_country_code: "CA",
    iata_code: "YTD",
    city_name: "Thicket Portage",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Thief River Falls Regional Airport",
    longitude: -96.183201,
    latitude: 48.065806,
    id: "arp_tvf_us",
    icao_code: "KTVF",
    iata_country_code: "US",
    iata_code: "TVF",
    city_name: "Thief River Falls",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Thimarafushi Airport",
    longitude: 73.153258,
    latitude: 2.211404,
    id: "arp_tmf_mv",
    icao_code: "VRNT",
    iata_country_code: "MV",
    iata_code: "TMF",
    city_name: "Thimarafushi",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Thisted Airport",
    longitude: 8.705779,
    latitude: 57.068749,
    id: "arp_ted_dk",
    icao_code: "EKTS",
    iata_country_code: "DK",
    iata_code: "TED",
    city_name: "Thisted",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Thomas C Russell Field",
    longitude: -85.961341,
    latitude: 32.915516,
    id: "arp_alx_us",
    icao_code: "KALX",
    iata_country_code: "US",
    iata_code: "ALX",
    city_name: "Alexander City",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Thomasville Regional Airport",
    longitude: -83.880966,
    latitude: 30.900985,
    id: "arp_tvi_us",
    icao_code: "KTVI",
    iata_country_code: "US",
    iata_code: "TVI",
    city_name: "Thomasville",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Thompson Airport",
    longitude: -97.863565,
    latitude: 55.800704,
    id: "arp_yth_ca",
    icao_code: "CYTH",
    iata_country_code: "CA",
    iata_code: "YTH",
    city_name: "Thompson",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Thompson Falls Airport",
    longitude: -115.282095,
    latitude: 47.574221,
    id: "arp_thm_us",
    icao_code: "KTHM",
    iata_country_code: "US",
    iata_code: "THM",
    city_name: "Thompson Falls",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Thompson-Robbins Airport",
    longitude: -90.674893,
    latitude: 34.574452,
    id: "arp_hee_us",
    icao_code: "KHEE",
    iata_country_code: "US",
    iata_code: "HEE",
    city_name: "Helena-West Helena",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Thorne Bay Seaplane Base",
    longitude: -132.536839,
    latitude: 55.687981,
    id: "arp_ktb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KTB",
    city_name: "Thorne Bay",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Thornhill Air Base",
    longitude: 29.861783,
    latitude: -19.436532,
    id: "arp_gwe_zw",
    icao_code: "FVTL",
    iata_country_code: "ZW",
    iata_code: "GWE",
    city_name: "Gweru",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Thorshofn Airport",
    longitude: -15.335163,
    latitude: 66.218689,
    id: "arp_tho_is",
    icao_code: "BITN",
    iata_country_code: "IS",
    iata_code: "THO",
    city_name: "Thorshofn",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Tho Xuan Airport",
    longitude: 105.475305,
    latitude: 19.892439,
    id: "arp_thd_vn",
    icao_code: "VVTX",
    iata_country_code: "VN",
    iata_code: "THD",
    city_name: "Thanh Hóa",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Three Rivers Municipal Dr Haines Airport",
    longitude: -85.592256,
    latitude: 41.95804,
    id: "arp_hai_us",
    icao_code: "KHAI",
    iata_country_code: "US",
    iata_code: "HAI",
    city_name: "Three Rivers",
    city: null,
  },
  {
    time_zone: "America/Thule",
    name: "Thule Air Base",
    longitude: -68.702134,
    latitude: 76.531908,
    id: "arp_thu_gl",
    icao_code: "BGTL",
    iata_country_code: "GL",
    iata_code: "THU",
    city_name: "Pituffik",
    city: null,
  },
  {
    time_zone: "Asia/Muscat",
    name: "Thumrait Air Base Airport",
    longitude: 54.024696,
    latitude: 17.665945,
    id: "arp_tth_om",
    icao_code: "OOTH",
    iata_country_code: "OM",
    iata_code: "TTH",
    city_name: "Thumrait",
    city: null,
  },
  {
    time_zone: "America/Thunder_Bay",
    name: "Thunder Bay International Airport",
    longitude: -89.321698,
    latitude: 48.373079,
    id: "arp_yqt_ca",
    icao_code: "CYQT",
    iata_country_code: "CA",
    iata_code: "YQT",
    city_name: "Thunder Bay",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Thylungra Airport",
    longitude: 143.451029,
    latitude: -26.09467,
    id: "arp_tyg_au",
    icao_code: "YTHY",
    iata_country_code: "AU",
    iata_code: "TYG",
    city_name: "Thylungra",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tianjin Binhai International Airport",
    longitude: 117.359082,
    latitude: 39.130319,
    id: "arp_tsn_cn",
    icao_code: "ZBTJ",
    iata_country_code: "CN",
    iata_code: "TSN",
    city_name: "Tianjin",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tianshui Maijishan Airport",
    longitude: 105.864397,
    latitude: 34.557235,
    id: "arp_thq_cn",
    icao_code: "ZLTS",
    iata_country_code: "CN",
    iata_code: "THQ",
    city_name: "Tianshui",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Tibooburra Airport",
    longitude: 142.057676,
    latitude: -29.45026,
    id: "arp_tyb_au",
    icao_code: "YTIB",
    iata_country_code: "AU",
    iata_code: "TYB",
    city_name: "Tibooburra",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Tibú Airport",
    longitude: -72.732416,
    latitude: 8.631219,
    id: "arp_tib_co",
    icao_code: "SKTB",
    iata_country_code: "CO",
    iata_code: "TIB",
    city_name: "Tibú",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Ticantiquí Airport",
    longitude: -78.489771,
    latitude: 9.418444,
    id: "arp_tjc_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "TJC",
    city_name: "Ticantiqui",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Tichitt Airport",
    longitude: -9.517,
    latitude: 18.45,
    id: "arp_thi_mr",
    icao_code: "GQNC",
    iata_country_code: "MR",
    iata_code: "THI",
    city_name: "Tichit",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Tidjikja Airport",
    longitude: -11.424286,
    latitude: 18.569809,
    id: "arp_tiy_mr",
    icao_code: "GQND",
    iata_country_code: "MR",
    iata_code: "TIY",
    city_name: "Tidjikja",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tifalmin Airport",
    longitude: 141.419,
    latitude: -5.1173,
    id: "arp_tfb_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TFB",
    city_name: "Tifalmin",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Tiga Airport",
    longitude: 167.803923,
    latitude: -21.094795,
    id: "arp_tgj_nc",
    icao_code: "NWWA",
    iata_country_code: "NC",
    iata_code: "TGJ",
    city_name: "Tiga",
    city: null,
  },
  {
    time_zone: "America/Tijuana",
    name: "Tijuana International Airport",
    longitude: -116.970851,
    latitude: 32.541677,
    id: "arp_tij_mx",
    icao_code: "MMTJ",
    iata_country_code: "MX",
    iata_code: "TIJ",
    city_name: "Tijuana",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Tikapur Airport",
    longitude: 81.123199,
    latitude: 28.522006,
    id: "arp_tpu_np",
    icao_code: "VNTP",
    iata_country_code: "NP",
    iata_code: "TPU",
    city_name: "Tikapur",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tikchik Lodge Seaplane Base",
    longitude: -158.47712,
    latitude: 59.96318,
    id: "arp_kth_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KTH",
    city_name: "Tikchik",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Tikehau Airport",
    longitude: -148.232042,
    latitude: -15.119957,
    id: "arp_tih_pf",
    icao_code: "NTGC",
    iata_country_code: "PF",
    iata_code: "TIH",
    city_name: "Tikehau",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Tiko Airport",
    longitude: 9.360445,
    latitude: 4.089182,
    id: "arp_tkc_cm",
    icao_code: "FKKC",
    iata_country_code: "CM",
    iata_code: "TKC",
    city_name: "Tiko",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Tiksi Airport",
    longitude: 128.899347,
    latitude: 71.699541,
    id: "arp_iks_ru",
    icao_code: "UEST",
    iata_country_code: "RU",
    iata_code: "IKS",
    city_name: "Tiksi",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Tilin Airport",
    longitude: 94.097631,
    latitude: 21.69968,
    id: "arp_tio_mm",
    icao_code: "VYHN",
    iata_country_code: "MM",
    iata_code: "TIO",
    city_name: "Tilin",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Tillamook Airport",
    longitude: -123.814202,
    latitude: 45.418204,
    id: "arp_otk_us",
    icao_code: "KTMK",
    iata_country_code: "US",
    iata_code: "OTK",
    city_name: "Tillamook",
    city: null,
  },
  {
    time_zone: "Africa/Nouakchott",
    name: "Timbedra Airport",
    longitude: -8.166778,
    latitude: 16.233222,
    id: "arp_tmd_mr",
    icao_code: "GQNH",
    iata_country_code: "MR",
    iata_code: "TMD",
    city_name: "Timbedra",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Timber Creek Airport",
    longitude: 130.445758,
    latitude: -15.62047,
    id: "arp_tbk_au",
    icao_code: "YTBR",
    iata_country_code: "AU",
    iata_code: "TBK",
    city_name: "Timber Creek",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Timbiqui Airport",
    longitude: -77.666991,
    latitude: 2.776008,
    id: "arp_tbd_co",
    icao_code: "SKMB",
    iata_country_code: "CO",
    iata_code: "TBD",
    city_name: "Timbiqui",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Timbuktu Airport",
    longitude: -3.006366,
    latitude: 16.730278,
    id: "arp_tom_ml",
    icao_code: "GATB",
    iata_country_code: "ML",
    iata_code: "TOM",
    city_name: "Tombouctou",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Timbunke Airport",
    longitude: 143.518084,
    latitude: -4.190162,
    id: "arp_tbe_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TBE",
    city_name: "Timbunke",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Timika Airport",
    longitude: 136.887887,
    latitude: -4.52747,
    id: "arp_tim_id",
    icao_code: "WABP",
    iata_country_code: "ID",
    iata_code: "TIM",
    city_name: "Tembagapura",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Timimoun Airport",
    longitude: 0.275794,
    latitude: 29.237022,
    id: "arp_tmx_dz",
    icao_code: "DAUT",
    iata_country_code: "DZ",
    iata_code: "TMX",
    city_name: "Timimoun",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Timișoara Traian Vuia International Airport",
    longitude: 21.332228,
    latitude: 45.809901,
    id: "arp_tsr_ro",
    icao_code: "LRTR",
    iata_country_code: "RO",
    iata_code: "TSR",
    city_name: "Timișoara",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Timmins/Victor M. Power Airport",
    longitude: -81.374372,
    latitude: 48.568105,
    id: "arp_yts_ca",
    icao_code: "CYTS",
    iata_country_code: "CA",
    iata_code: "YTS",
    city_name: "Timmins",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Tinak Airport",
    longitude: 171.916842,
    latitude: 7.129471,
    id: "arp_tic_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "TIC",
    city_name: "Tinak Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tinboli Airport",
    longitude: 143.385855,
    latitude: -4.093055,
    id: "arp_tck_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TCK",
    city_name: "Tinboli",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Tin City Airport",
    longitude: -167.920958,
    latitude: 65.563365,
    id: "arp_tnc_us",
    icao_code: "PATC",
    iata_country_code: "US",
    iata_code: "TNC",
    city_name: "Tin City",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Tindouf Airport",
    longitude: -8.166352,
    latitude: 27.701288,
    id: "arp_tin_dz",
    icao_code: "DAOF",
    iata_country_code: "DZ",
    iata_code: "TIN",
    city_name: "Tindouf",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Tingo Maria Airport",
    longitude: -76.005099,
    latitude: -9.286962,
    id: "arp_tgi_pe",
    icao_code: "SPGM",
    iata_country_code: "PE",
    iata_code: "TGI",
    city_name: "Tingo Maria",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tingwon Airport",
    longitude: 149.702317,
    latitude: -2.601801,
    id: "arp_tig_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TIG",
    city_name: "Tingwon",
    city: null,
  },
  {
    time_zone: "Pacific/Saipan",
    name: "Tinian International Airport",
    longitude: 145.621904,
    latitude: 14.999206,
    id: "arp_tiq_mp",
    icao_code: "PGWT",
    iata_country_code: "MP",
    iata_code: "TIQ",
    city_name: "Tinian",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tinker Air Force Base",
    longitude: -97.38687,
    latitude: 35.415641,
    id: "arp_tik_us",
    icao_code: "KTIK",
    iata_country_code: "US",
    iata_code: "TIK",
    city_name: "Oklahoma City",
    city: {
      name: "Oklahoma City",
      id: "cit_okc_us",
      iata_country_code: "US",
      iata_code: "OKC",
    },
  },
  {
    time_zone: "America/Jamaica",
    name: "Tinson Pen Airport",
    longitude: -76.824334,
    latitude: 17.988398,
    id: "arp_ktp_jm",
    icao_code: "MKTP",
    iata_country_code: "JM",
    iata_code: "KTP",
    city_name: "Kingston",
    city: {
      name: "Kingston",
      id: "cit_kin_jm",
      iata_country_code: "JM",
      iata_code: "KIN",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Tioga Municipal Airport",
    longitude: -102.948177,
    latitude: 48.432816,
    id: "arp_vex_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "VEX",
    city_name: "Tioga",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Tiom Airport",
    longitude: 138.45353,
    latitude: -3.924182,
    id: "arp_tmy_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "TMY",
    city_name: "Tiom",
    city: null,
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Tioman Airport",
    longitude: 104.159743,
    latitude: 2.818422,
    id: "arp_tod_my",
    icao_code: "WMBT",
    iata_country_code: "MY",
    iata_code: "TOD",
    city_name: "Tioman Island",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Tippi Airport",
    longitude: 35.415,
    latitude: 7.2024,
    id: "arp_tie_et",
    icao_code: "HATP",
    iata_country_code: "ET",
    iata_code: "TIE",
    city_name: "Tippi",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tipton Airport",
    longitude: -76.75937,
    latitude: 39.086674,
    id: "arp_fme_us",
    icao_code: "KFME",
    iata_country_code: "US",
    iata_code: "FME",
    city_name: "Odenton/Fort Meade",
    city: null,
  },
  {
    time_zone: "America/Guayaquil",
    name: "Tiputini Airport",
    longitude: -75.526397,
    latitude: -0.776111,
    id: "arp_tpn_ec",
    icao_code: "SETI",
    iata_country_code: "EC",
    iata_code: "TPN",
    city_name: "Tiputini",
    city: null,
  },
  {
    time_zone: "Europe/Tirane",
    name: "Tirana International Airport",
    longitude: 19.719376,
    latitude: 41.414729,
    id: "arp_tia_al",
    icao_code: "LATI",
    iata_country_code: "AL",
    iata_code: "TIA",
    city_name: "Tirana",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Tiree Airport",
    longitude: -6.870141,
    latitude: 56.499328,
    id: "arp_tre_gb",
    icao_code: "EGPU",
    iata_country_code: "GB",
    iata_code: "TRE",
    city_name: "Tiree",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Tiruchirappalli International Airport",
    longitude: 78.709369,
    latitude: 10.76315,
    id: "arp_trz_in",
    icao_code: "VOTR",
    iata_country_code: "IN",
    iata_code: "TRZ",
    city_name: "Tiruchirappalli",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Tirupati Airport",
    longitude: 79.5443,
    latitude: 13.634456,
    id: "arp_tir_in",
    icao_code: "VOTP",
    iata_country_code: "IN",
    iata_code: "TIR",
    city_name: "Tirupati",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Tisdale Airport",
    longitude: -104.066248,
    latitude: 52.83616,
    id: "arp_ytt_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YTT",
    city_name: "Tisdale",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Tissa Tank Waterdrome Airport",
    longitude: 81.29045,
    latitude: 6.287835,
    id: "arp_ttw_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "TTW",
    city_name: "Tissamaharama",
    city: null,
  },
  {
    time_zone: "Europe/Podgorica",
    name: "Tivat Airport",
    longitude: 18.722972,
    latitude: 42.40487,
    id: "arp_tiv_me",
    icao_code: "LYTV",
    iata_country_code: "ME",
    iata_code: "TIV",
    city_name: "Tivat",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Tjilik Riwut Airport",
    longitude: 113.943862,
    latitude: -2.224761,
    id: "arp_pky_id",
    icao_code: "WAGG",
    iata_country_code: "ID",
    iata_code: "PKY",
    city_name: "Palangkaraya",
    city: null,
  },
  {
    time_zone: "Africa/Maseru",
    name: "Tlokoeng Airport",
    longitude: 28.883166,
    latitude: -29.233166,
    id: "arp_tko_ls",
    icao_code: "FXTK",
    iata_country_code: "LS",
    iata_code: "TKO",
    city_name: "Tlokoeng",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Toamasina Airport",
    longitude: 49.392489,
    latitude: -18.109386,
    id: "arp_tmm_mg",
    icao_code: "FMMT",
    iata_country_code: "MG",
    iata_code: "TMM",
    city_name: "Toamasina",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Tobermorey Airport",
    longitude: 137.953648,
    latitude: -22.255846,
    id: "arp_typ_au",
    icao_code: "YTMY",
    iata_country_code: "AU",
    iata_code: "TYP",
    city_name: "Tobermorey",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Tobías Bolaños International Airport",
    longitude: -84.139814,
    latitude: 9.957251,
    id: "arp_syq_cr",
    icao_code: "MRPV",
    iata_country_code: "CR",
    iata_code: "SYQ",
    city_name: "San Jose",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Tobolsk Airport",
    longitude: 68.218883,
    latitude: 58.138183,
    id: "arp_tox_ru",
    icao_code: "USTO",
    iata_country_code: "RU",
    iata_code: "TOX",
    city_name: "Tobolsk",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Tobruk Airport",
    longitude: 23.917842,
    latitude: 31.861674,
    id: "arp_tob_ly",
    icao_code: "HLGN",
    iata_country_code: "LY",
    iata_code: "TOB",
    city_name: "Tobruk",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Toccoa Airport",
    longitude: -83.296328,
    latitude: 34.592499,
    id: "arp_toc_us",
    icao_code: "KTOC",
    iata_country_code: "US",
    iata_code: "TOC",
    city_name: "Toccoa",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Tocoa Airport",
    longitude: -86.0167,
    latitude: 15.7,
    id: "arp_tcf_hn",
    icao_code: null,
    iata_country_code: "HN",
    iata_code: "TCF",
    city_name: "Tocoa",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Tocumen International Airport",
    longitude: -79.38536,
    latitude: 9.069099,
    id: "arp_pty_pa",
    icao_code: "MPTO",
    iata_country_code: "PA",
    iata_code: "PTY",
    city_name: "Panama City",
    city: {
      name: "Panama City",
      id: "cit_pty_pa",
      iata_country_code: "PA",
      iata_code: "PTY",
    },
  },
  {
    time_zone: "Australia/Sydney",
    name: "Tocumwal Airport",
    longitude: 145.593811,
    latitude: -35.812386,
    id: "arp_tcw_au",
    icao_code: "YTOC",
    iata_country_code: "AU",
    iata_code: "TCW",
    city_name: "Tocumwal",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Tofino Harbour Seaplane Base Airport",
    longitude: -125.90959,
    latitude: 49.15545,
    id: "arp_ytp_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YTP",
    city_name: "Tofino",
    city: {
      name: "Tofino",
      id: "cit_yaz_ca",
      iata_country_code: "CA",
      iata_code: "YAZ",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Tofino-Long Beach Airport",
    longitude: -125.773595,
    latitude: 49.078796,
    id: "arp_yaz_ca",
    icao_code: "CYAZ",
    iata_country_code: "CA",
    iata_code: "YAZ",
    city_name: "Tofino",
    city: {
      name: "Tofino",
      id: "cit_yaz_ca",
      iata_country_code: "CA",
      iata_code: "YAZ",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Togiak Airport",
    longitude: -160.397499,
    latitude: 59.053008,
    id: "arp_tog_us",
    icao_code: "PATG",
    iata_country_code: "US",
    iata_code: "TOG",
    city_name: "Togiak",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tokachi-Obihiro Airport",
    longitude: 143.215896,
    latitude: 42.732572,
    id: "arp_obo_jp",
    icao_code: "RJCB",
    iata_country_code: "JP",
    iata_code: "OBO",
    city_name: "Obihiro",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tok Airport",
    longitude: -142.955741,
    latitude: 63.329155,
    id: "arp_tkj_us",
    icao_code: "PFTO",
    iata_country_code: "US",
    iata_code: "TKJ",
    city_name: "Tok",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Tokat Airport",
    longitude: 36.365197,
    latitude: 40.305958,
    id: "arp_tjk_tr",
    icao_code: "LTAW",
    iata_country_code: "TR",
    iata_code: "TJK",
    city_name: "Tokat",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Tokeen Seaplane Base",
    longitude: -133.325998,
    latitude: 55.9373,
    id: "arp_tki_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TKI",
    city_name: "Tokeen",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Tokoroa Airfield",
    longitude: 175.893039,
    latitude: -38.237214,
    id: "arp_tkz_nz",
    icao_code: "NZTO",
    iata_country_code: "NZ",
    iata_code: "TKZ",
    city_name: "Tokoroa",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Toksook Bay Airport",
    longitude: -165.087811,
    latitude: 60.540219,
    id: "arp_ook_us",
    icao_code: "PAOO",
    iata_country_code: "US",
    iata_code: "OOK",
    city_name: "Toksook Bay",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tokunoshima Airport",
    longitude: 128.881743,
    latitude: 27.835403,
    id: "arp_tkn_jp",
    icao_code: "RJKN",
    iata_country_code: "JP",
    iata_code: "TKN",
    city_name: "Tokunoshima",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tokushima Airport",
    longitude: 134.609099,
    latitude: 34.132426,
    id: "arp_tks_jp",
    icao_code: "RJOS",
    iata_country_code: "JP",
    iata_code: "TKS",
    city_name: "Tokushima",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tol Airport",
    longitude: 152.011064,
    latitude: -4.979708,
    id: "arp_tlo_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TLO",
    city_name: "Tol",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Tôlanaro Airport",
    longitude: 46.955612,
    latitude: -25.037686,
    id: "arp_ftu_mg",
    icao_code: "FMSD",
    iata_country_code: "MG",
    iata_code: "FTU",
    city_name: "Tôlanaro",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Toledo Airport",
    longitude: -53.697498,
    latitude: -24.6863,
    id: "arp_tow_br",
    icao_code: "SBTD",
    iata_country_code: "BR",
    iata_code: "TOW",
    city_name: "Toledo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Toledo Executive Airport",
    longitude: -83.481795,
    latitude: 41.565179,
    id: "arp_tdz_us",
    icao_code: "KTDZ",
    iata_country_code: "US",
    iata_code: "TDZ",
    city_name: "Toledo",
    city: {
      name: "Toledo",
      id: "cit_tol_us",
      iata_country_code: "US",
      iata_code: "TOL",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Toledo Express Airport",
    longitude: -83.807689,
    latitude: 41.588449,
    id: "arp_tol_us",
    icao_code: "KTOL",
    iata_country_code: "US",
    iata_code: "TOL",
    city_name: "Toledo",
    city: {
      name: "Toledo",
      id: "cit_tol_us",
      iata_country_code: "US",
      iata_code: "TOL",
    },
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Toliara Airport",
    longitude: 43.725841,
    latitude: -23.385624,
    id: "arp_tle_mg",
    icao_code: "FMST",
    iata_country_code: "MG",
    iata_code: "TLE",
    city_name: "Toliara",
    city: null,
  },
  {
    time_zone: "Asia/Novosibirsk",
    name: "Tolmachevo Airport",
    longitude: 82.651189,
    latitude: 55.012184,
    id: "arp_ovb_ru",
    icao_code: "UNNT",
    iata_country_code: "RU",
    iata_code: "OVB",
    city_name: "Novosibirsk",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Toluca International Airport",
    longitude: -99.567918,
    latitude: 19.338024,
    id: "arp_tlc_mx",
    icao_code: "MMTO",
    iata_country_code: "MX",
    iata_code: "TLC",
    city_name: "Toluca",
    city: {
      name: "Mexico City",
      id: "cit_mex_mx",
      iata_country_code: "MX",
      iata_code: "MEX",
    },
  },
  {
    time_zone: "Asia/Kuala_Lumpur",
    name: "Tomanggong Airport",
    longitude: 118.657526,
    latitude: 5.402559,
    id: "arp_tmg_my",
    icao_code: "WBKM",
    iata_country_code: "MY",
    iata_code: "TMG",
    city_name: "Tomanggong",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Tomás de Heres Airport",
    longitude: -63.536997,
    latitude: 8.123155,
    id: "arp_cbl_ve",
    icao_code: "SVCB",
    iata_country_code: "VE",
    iata_code: "CBL",
    city_name: "Ciudad Bolivar",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Tom Price Airport",
    longitude: 117.868854,
    latitude: -22.746089,
    id: "arp_tpr_au",
    icao_code: "YTMP",
    iata_country_code: "AU",
    iata_code: "TPR",
    city_name: "Tom Price",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Toncontín International Airport",
    longitude: -87.21737,
    latitude: 14.06082,
    id: "arp_tgu_hn",
    icao_code: "MHTG",
    iata_country_code: "HN",
    iata_code: "TGU",
    city_name: "Tegucigalpa",
    city: null,
  },
  {
    time_zone: "Pacific/Rarotonga",
    name: "Tongareva Airport",
    longitude: -158.032409,
    latitude: -9.014369,
    id: "arp_pye_ck",
    icao_code: "NCPY",
    iata_country_code: "CK",
    iata_code: "PYE",
    city_name: "Penrhyn Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tonghua Sanyuanpu Airport",
    longitude: 125.703333,
    latitude: 42.253888,
    id: "arp_tnh_cn",
    icao_code: "ZYTN",
    iata_country_code: "CN",
    iata_code: "TNH",
    city_name: "Tonghua",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tongliao Airport",
    longitude: 122.204501,
    latitude: 43.558127,
    id: "arp_tgo_cn",
    icao_code: "ZBTL",
    iata_country_code: "CN",
    iata_code: "TGO",
    city_name: "Tongliao",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Tongoa Airport",
    longitude: 168.551174,
    latitude: -16.891068,
    id: "arp_tgh_vu",
    icao_code: "NVST",
    iata_country_code: "VU",
    iata_code: "TGH",
    city_name: "Tongoa Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Tongren Fenghuang Airport",
    longitude: 109.309407,
    latitude: 27.88288,
    id: "arp_ten_cn",
    icao_code: "ZUTR",
    iata_country_code: "CN",
    iata_code: "TEN",
    city_name: "Tongren",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Tonopah Airport",
    longitude: -117.085103,
    latitude: 38.058302,
    id: "arp_tph_us",
    icao_code: "KTPH",
    iata_country_code: "US",
    iata_code: "TPH",
    city_name: "Tonopah",
    city: {
      name: "Tonopah",
      id: "cit_tph_us",
      iata_country_code: "US",
      iata_code: "TPH",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Tonopah Test Range Airport",
    longitude: -116.777223,
    latitude: 37.796971,
    id: "arp_xsd_us",
    icao_code: "KTNX",
    iata_country_code: "US",
    iata_code: "XSD",
    city_name: "Tonopah",
    city: {
      name: "Tonopah",
      id: "cit_tph_us",
      iata_country_code: "US",
      iata_code: "TPH",
    },
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tonu Airport",
    longitude: 155.432139,
    latitude: -6.655349,
    id: "arp_ton_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TON",
    city_name: "Tonu",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Toowoomba Airport",
    longitude: 151.916734,
    latitude: -27.543376,
    id: "arp_twb_au",
    icao_code: "YTWB",
    iata_country_code: "AU",
    iata_code: "TWB",
    city_name: "Toowoomba",
    city: {
      name: "Toowoomba",
      id: "cit_twb_au",
      iata_country_code: "AU",
      iata_code: "TWB",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Toowoomba Wellcamp Airport",
    longitude: 151.794765,
    latitude: -27.558284,
    id: "arp_wtb_au",
    icao_code: "YBWW",
    iata_country_code: "AU",
    iata_code: "WTB",
    city_name: "Toowoomba",
    city: {
      name: "Toowoomba",
      id: "cit_twb_au",
      iata_country_code: "AU",
      iata_code: "TWB",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Topeka Regional Airport",
    longitude: -95.665013,
    latitude: 38.949622,
    id: "arp_foe_us",
    icao_code: "KFOE",
    iata_country_code: "US",
    iata_code: "FOE",
    city_name: "Topeka",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Torembi Airport",
    longitude: 143.134106,
    latitude: -4.01596,
    id: "arp_tcj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TCJ",
    city_name: "Torembi",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Torokina Airport",
    longitude: 155.061687,
    latitude: -6.201365,
    id: "arp_tok_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TOK",
    city_name: "Torokina",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Toronto Pearson International Airport",
    longitude: -79.62771,
    latitude: 43.677459,
    id: "arp_yyz_ca",
    icao_code: "CYYZ",
    iata_country_code: "CA",
    iata_code: "YYZ",
    city_name: "Toronto",
    city: {
      name: "Toronto",
      id: "cit_yto_ca",
      iata_country_code: "CA",
      iata_code: "YTO",
    },
  },
  {
    time_zone: "Africa/Kampala",
    name: "Tororo Airport",
    longitude: 34.16783,
    latitude: 0.681967,
    id: "arp_try_ug",
    icao_code: "HUTO",
    iata_country_code: "UG",
    iata_code: "TRY",
    city_name: "Tororo",
    city: null,
  },
  {
    time_zone: "America/Monterrey",
    name: "Torreón Airport",
    longitude: -103.406424,
    latitude: 25.566404,
    id: "arp_trc_mx",
    icao_code: "MMTC",
    iata_country_code: "MX",
    iata_code: "TRC",
    city_name: "Torreón",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Torres Airport",
    longitude: 166.636684,
    latitude: -13.327592,
    id: "arp_toh_vu",
    icao_code: "NVSD",
    iata_country_code: "VU",
    iata_code: "TOH",
    city_name: "Torres",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Torres Airport",
    longitude: -49.807193,
    latitude: -29.413876,
    id: "arp_tsq_br",
    icao_code: "SBTR",
    iata_country_code: "BR",
    iata_code: "TSQ",
    city_name: "Torres",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Torrington Municipal Airport",
    longitude: -104.155256,
    latitude: 42.063913,
    id: "arp_tor_us",
    icao_code: "KTOR",
    iata_country_code: "US",
    iata_code: "TOR",
    city_name: "Torrington",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Torsby Airport",
    longitude: 12.991544,
    latitude: 60.155619,
    id: "arp_tyf_se",
    icao_code: "ESST",
    iata_country_code: "SE",
    iata_code: "TYF",
    city_name: "Torsby",
    city: null,
  },
  {
    time_zone: "America/Tortola",
    name: "Tortola West End Seaplane Base Airport",
    longitude: -64.583333,
    latitude: 18.45,
    id: "arp_tov_vg",
    icao_code: null,
    iata_country_code: "VG",
    iata_code: "TOV",
    city_name: "Tortola",
    city: {
      name: "Tortola",
      id: "cit_tov_vg",
      iata_country_code: "VG",
      iata_code: "TOV",
    },
  },
  {
    time_zone: "Europe/Rome",
    name: "Tortolì Airport",
    longitude: 9.681586,
    latitude: 39.919686,
    id: "arp_ttb_it",
    icao_code: "LIET",
    iata_country_code: "IT",
    iata_code: "TTB",
    city_name: "Tortoli",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Tortuguero Airport",
    longitude: -83.516168,
    latitude: 10.571558,
    id: "arp_ttq_cr",
    icao_code: "MRBT",
    iata_country_code: "CR",
    iata_code: "TTQ",
    city_name: "Tortuguero",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Torwood Airport",
    longitude: 143.747271,
    latitude: -17.362956,
    id: "arp_twp_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "TWP",
    city_name: "Torwood",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Tosontsengel Airport",
    longitude: 98.291704,
    latitude: 48.739115,
    id: "arp_tnz_mn",
    icao_code: "ZMTL",
    iata_country_code: "MN",
    iata_code: "TNZ",
    city_name: "Tosontsengel",
    city: null,
  },
  {
    time_zone: "Pacific/Gambier",
    name: "Totegegie Airport",
    longitude: -134.890389,
    latitude: -23.079619,
    id: "arp_gmr_pf",
    icao_code: "NTGJ",
    iata_country_code: "PF",
    iata_code: "GMR",
    city_name: "Totegegie",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Totness Airport",
    longitude: -56.3281,
    latitude: 5.866475,
    id: "arp_tot_sr",
    icao_code: "SMCO",
    iata_country_code: "SR",
    iata_code: "TOT",
    city_name: "Totness",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tottori Airport",
    longitude: 134.167376,
    latitude: 35.528169,
    id: "arp_ttj_jp",
    icao_code: "RJOR",
    iata_country_code: "JP",
    iata_code: "TTJ",
    city_name: "Tottori",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Touat Cheikh Sidi Mohamed Belkebir Airport",
    longitude: -0.187251,
    latitude: 27.839422,
    id: "arp_azr_dz",
    icao_code: "DAUA",
    iata_country_code: "DZ",
    iata_code: "AZR",
    city_name: "Adrar",
    city: null,
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Tougan Airport",
    longitude: -3.07697,
    latitude: 13.059076,
    id: "arp_tuq_bf",
    icao_code: "DFOT",
    iata_country_code: "BF",
    iata_code: "TUQ",
    city_name: "Tougan",
    city: null,
  },
  {
    time_zone: "Pacific/Noumea",
    name: "Touho Airport",
    longitude: 165.259105,
    latitude: -20.790193,
    id: "arp_tou_nc",
    icao_code: "NWWU",
    iata_country_code: "NC",
    iata_code: "TOU",
    city_name: "Touho",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Toulon-Hyères Airport",
    longitude: 6.150671,
    latitude: 43.094643,
    id: "arp_tln_fr",
    icao_code: "LFTH",
    iata_country_code: "FR",
    iata_code: "TLN",
    city_name: "Toulon",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Toulouse-Blagnac Airport",
    longitude: 1.365751,
    latitude: 43.629243,
    id: "arp_tls_fr",
    icao_code: "LFBO",
    iata_country_code: "FR",
    iata_code: "TLS",
    city_name: "Toulouse",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Tours Val de Loire Airport",
    longitude: 0.72495,
    latitude: 47.430102,
    id: "arp_tuf_fr",
    icao_code: "LFOT",
    iata_country_code: "FR",
    iata_code: "TUF",
    city_name: "Tours",
    city: null,
  },
  {
    time_zone: "America/Port-au-Prince",
    name: "Toussaint Louverture International Airport",
    longitude: -72.293588,
    latitude: 18.577829,
    id: "arp_pap_ht",
    icao_code: "MTPP",
    iata_country_code: "HT",
    iata_code: "PAP",
    city_name: "Port-au-Prince",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Toussus-le-Noble Airport",
    longitude: 2.107332,
    latitude: 48.750323,
    id: "arp_tnf_fr",
    icao_code: "LFPN",
    iata_country_code: "FR",
    iata_code: "TNF",
    city_name: "Toussus-le-Noble",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Townsville Airport",
    longitude: 146.767856,
    latitude: -19.254377,
    id: "arp_tsv_au",
    icao_code: "YBTL",
    iata_country_code: "AU",
    iata_code: "TSV",
    city_name: "Townsville",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Toyama Airport",
    longitude: 137.188485,
    latitude: 36.64635,
    id: "arp_toy_jp",
    icao_code: "RJNT",
    iata_country_code: "JP",
    iata_code: "TOY",
    city_name: "Toyama",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Tozeur-Nefta International Airport",
    longitude: 8.108493,
    latitude: 33.938407,
    id: "arp_toe_tn",
    icao_code: "DTTZ",
    iata_country_code: "TN",
    iata_code: "TOE",
    city_name: "Tozeur",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Trabzon Airport",
    longitude: 39.785667,
    latitude: 40.994752,
    id: "arp_tzx_tr",
    icao_code: "LTCG",
    iata_country_code: "TR",
    iata_code: "TZX",
    city_name: "Trabzon",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tradewind Airport",
    longitude: -101.826954,
    latitude: 35.169989,
    id: "arp_tdw_us",
    icao_code: "KTDW",
    iata_country_code: "US",
    iata_code: "TDW",
    city_name: "Amarillo",
    city: {
      name: "Amarillo",
      id: "cit_ama_us",
      iata_country_code: "US",
      iata_code: "AMA",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Trail Airport",
    longitude: -117.608249,
    latitude: 49.056907,
    id: "arp_yzz_ca",
    icao_code: "CAD4",
    iata_country_code: "CA",
    iata_code: "YZZ",
    city_name: "Trail",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Trang Airport",
    longitude: 99.616533,
    latitude: 7.509074,
    id: "arp_tst_th",
    icao_code: "VTST",
    iata_country_code: "TH",
    iata_code: "TST",
    city_name: "Trang",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Trat Airport",
    longitude: 102.321697,
    latitude: 12.277932,
    id: "arp_tdx_th",
    icao_code: "VTBO",
    iata_country_code: "TH",
    iata_code: "TDX",
    city_name: "Trat",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Travis Air Force Base",
    longitude: -121.927002,
    latitude: 38.262699,
    id: "arp_suu_us",
    icao_code: "KSUU",
    iata_country_code: "US",
    iata_code: "SUU",
    city_name: "Fairfield",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Treasure Cay Airport",
    longitude: -77.382509,
    latitude: 26.741065,
    id: "arp_tcb_bs",
    icao_code: "MYAT",
    iata_country_code: "BS",
    iata_code: "TCB",
    city_name: "Treasure Cay",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Treinta y Tres Airport",
    longitude: -54.348467,
    latitude: -33.197723,
    id: "arp_tyt_uy",
    icao_code: "SUTR",
    iata_country_code: "UY",
    iata_code: "TYT",
    city_name: "Treinta y Tres",
    city: null,
  },
  {
    time_zone: "America/Argentina/Catamarca",
    name: "Trelew International Airport",
    longitude: -65.271852,
    latitude: -43.210229,
    id: "arp_rel_ar",
    icao_code: "SAVT",
    iata_country_code: "AR",
    iata_code: "REL",
    city_name: "Trelew",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Trent Lott International Airport",
    longitude: -88.528613,
    latitude: 30.462812,
    id: "arp_pgl_us",
    icao_code: "KPQL",
    iata_country_code: "US",
    iata_code: "PGL",
    city_name: "Pascagoula",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Trenton-Mercer Airport",
    longitude: -74.814691,
    latitude: 40.27706,
    id: "arp_ttn_us",
    icao_code: "KTTN",
    iata_country_code: "US",
    iata_code: "TTN",
    city_name: "Trenton",
    city: {
      name: "Philadelphia",
      id: "cit_phl_us",
      iata_country_code: "US",
      iata_code: "PHL",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Trenton Municipal Airport",
    longitude: -93.590836,
    latitude: 40.083611,
    id: "arp_trx_us",
    icao_code: "KTRX",
    iata_country_code: "US",
    iata_code: "TRX",
    city_name: "Trenton",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Trepell Airport",
    longitude: 140.892306,
    latitude: -21.839878,
    id: "arp_tqp_au",
    icao_code: "YTEE",
    iata_country_code: "AU",
    iata_code: "TQP",
    city_name: "Trepell",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Tres Arroyos Airport",
    longitude: -60.3297,
    latitude: -38.3869,
    id: "arp_oyo_ar",
    icao_code: "SAZH",
    iata_country_code: "AR",
    iata_code: "OYO",
    city_name: "Tres Arroyos",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Tres de Mayo Airport",
    longitude: -76.501123,
    latitude: 0.504417,
    id: "arp_puu_co",
    icao_code: "SKAS",
    iata_country_code: "CO",
    iata_code: "PUU",
    city_name: "Puerto Asís",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Tres Esquinas Airport",
    longitude: -75.234321,
    latitude: 0.745483,
    id: "arp_tqs_co",
    icao_code: "SKTQ",
    iata_country_code: "CO",
    iata_code: "TQS",
    city_name: "Tres Esquinas",
    city: null,
  },
  {
    time_zone: "America/Campo_Grande",
    name: "Tres Lagoas Airport",
    longitude: -51.683504,
    latitude: -20.754023,
    id: "arp_tjl_br",
    icao_code: "SBTG",
    iata_country_code: "BR",
    iata_code: "TJL",
    city_name: "Três Lagoas",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Treviso Airport",
    longitude: 12.198241,
    latitude: 45.650838,
    id: "arp_tsf_it",
    icao_code: "LIPH",
    iata_country_code: "IT",
    iata_code: "TSF",
    city_name: "Treviso",
    city: {
      name: "Venice",
      id: "cit_vce_it",
      iata_country_code: "IT",
      iata_code: "VCE",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Triangle North Executive Airport",
    longitude: -78.331161,
    latitude: 36.023531,
    id: "arp_lfn_us",
    icao_code: "KLHZ",
    iata_country_code: "US",
    iata_code: "LFN",
    city_name: "Louisburg",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Tribhuvan International Airport",
    longitude: 85.359161,
    latitude: 27.697344,
    id: "arp_ktm_np",
    icao_code: "VNKT",
    iata_country_code: "NP",
    iata_code: "KTM",
    city_name: "Kathmandu",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Tri-Cities Airport",
    longitude: -119.117561,
    latitude: 46.263016,
    id: "arp_psc_us",
    icao_code: "KPSC",
    iata_country_code: "US",
    iata_code: "PSC",
    city_name: "Pasco",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tri-Cities Regional Airport",
    longitude: -82.407763,
    latitude: 36.478103,
    id: "arp_tri_us",
    icao_code: "KTRI",
    iata_country_code: "US",
    iata_code: "TRI",
    city_name: "Bristol/Johnson/Kingsport",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tri-City Airport",
    longitude: -95.507097,
    latitude: 37.330395,
    id: "arp_ppf_us",
    icao_code: "KPPF",
    iata_country_code: "US",
    iata_code: "PPF",
    city_name: "Parsons",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tri-County Regional Airport",
    longitude: -90.183856,
    latitude: 43.211697,
    id: "arp_lnr_us",
    icao_code: "KLNR",
    iata_country_code: "US",
    iata_code: "LNR",
    city_name: "Lone Rock",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Trieste–Friuli Venezia Giulia Airport",
    longitude: 13.471813,
    latitude: 45.827272,
    id: "arp_trs_it",
    icao_code: "LIPQ",
    iata_country_code: "IT",
    iata_code: "TRS",
    city_name: "Trieste",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Trincomalee Harbour Seaplane Base Airport",
    longitude: 81.219828,
    latitude: 8.561337,
    id: "arp_thw_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "THW",
    city_name: "Trincomalee",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Trinidad Airport",
    longitude: -71.729915,
    latitude: 5.411456,
    id: "arp_tda_co",
    icao_code: "SKTD",
    iata_country_code: "CO",
    iata_code: "TDA",
    city_name: "Trinidad",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Tripoli International Airport",
    longitude: 13.157833,
    latitude: 32.666472,
    id: "arp_tip_ly",
    icao_code: "HLLT",
    iata_country_code: "LY",
    iata_code: "TIP",
    city_name: "Tripoli",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tri-State Airport",
    longitude: -82.558083,
    latitude: 38.367532,
    id: "arp_hts_us",
    icao_code: "KHTS",
    iata_country_code: "US",
    iata_code: "HTS",
    city_name: "Huntington",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tri-State Steuben County Airport",
    longitude: -85.083438,
    latitude: 41.639635,
    id: "arp_anq_us",
    icao_code: "KANQ",
    iata_country_code: "US",
    iata_code: "ANQ",
    city_name: "Angola",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Trivandrum International Airport",
    longitude: 76.919771,
    latitude: 8.480946,
    id: "arp_trv_in",
    icao_code: "VOTV",
    iata_country_code: "IN",
    iata_code: "TRV",
    city_name: "Thiruvananthapuram",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Trois-Rivières Airport",
    longitude: -72.680277,
    latitude: 46.351304,
    id: "arp_yrq_ca",
    icao_code: "CYRQ",
    iata_country_code: "CA",
    iata_code: "YRQ",
    city_name: "Trois-Rivières",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Trollhättan–Vänersborg Airport",
    longitude: 12.34763,
    latitude: 58.317311,
    id: "arp_thn_se",
    icao_code: "ESGT",
    iata_country_code: "SE",
    iata_code: "THN",
    city_name: "Trollhättan",
    city: null,
  },
  {
    time_zone: "America/Santarem",
    name: "Trombetas Airport",
    longitude: -56.390916,
    latitude: -1.486415,
    id: "arp_tmt_br",
    icao_code: "SBTB",
    iata_country_code: "BR",
    iata_code: "TMT",
    city_name: "Oriximiná",
    city: null,
  },
  {
    time_zone: "America/Lima",
    name: "Trompeteros Airport",
    longitude: -75.040235,
    latitude: -3.804607,
    id: "arp_tdp_pe",
    icao_code: "SPDR",
    iata_country_code: "PE",
    iata_code: "TDP",
    city_name: "Trompeteros",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Tromsø Airport",
    longitude: 18.917582,
    latitude: 69.682616,
    id: "arp_tos_no",
    icao_code: "ENTC",
    iata_country_code: "NO",
    iata_code: "TOS",
    city_name: "Tromsø",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Trona Airport",
    longitude: -117.330995,
    latitude: 35.81372,
    id: "arp_trh_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TRH",
    city_name: "Trona",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Trondheim Airport",
    longitude: 10.923299,
    latitude: 63.458036,
    id: "arp_trd_no",
    icao_code: "ENVA",
    iata_country_code: "NO",
    iata_code: "TRD",
    city_name: "Trondheim",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Troy Municipal Airport",
    longitude: -86.012161,
    latitude: 31.860477,
    id: "arp_toi_us",
    icao_code: "KTOI",
    iata_country_code: "US",
    iata_code: "TOI",
    city_name: "Troy",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Truckee Tahoe Airport",
    longitude: -120.142466,
    latitude: 39.319469,
    id: "arp_tkf_us",
    icao_code: "KTRK",
    iata_country_code: "US",
    iata_code: "TKF",
    city_name: "Truckee",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Trujillo Airport",
    longitude: -85.938202,
    latitude: 15.9268,
    id: "arp_tji_hn",
    icao_code: "MHTJ",
    iata_country_code: "HN",
    iata_code: "TJI",
    city_name: "Trujillo",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Trunojoyo Airport",
    longitude: 113.89044,
    latitude: -7.024081,
    id: "arp_sup_id",
    icao_code: "WART",
    iata_country_code: "ID",
    iata_code: "SUP",
    city_name: "Sumenep",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Truscott-Mungalalu Airport",
    longitude: 126.380962,
    latitude: -14.089629,
    id: "arp_ttx_au",
    icao_code: "YTST",
    iata_country_code: "AU",
    iata_code: "TTX",
    city_name: "Truscott-Mungalalu",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Truth or Consequences Municipal Airport",
    longitude: -107.271614,
    latitude: 33.236637,
    id: "arp_tcs_us",
    icao_code: "KTCS",
    iata_country_code: "US",
    iata_code: "TCS",
    city_name: "Truth or Consequences",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Tsaratanana Airport",
    longitude: 47.61889,
    latitude: -16.751145,
    id: "arp_tts_mg",
    icao_code: "FMNT",
    iata_country_code: "MG",
    iata_code: "TTS",
    city_name: "Tsaratanana",
    city: null,
  },
  {
    time_zone: "Asia/Ulaanbaatar",
    name: "Tsetserleg Airport",
    longitude: 101.47892,
    latitude: 47.465203,
    id: "arp_tsz_mn",
    icao_code: "ZMTG",
    iata_country_code: "MN",
    iata_code: "TSZ",
    city_name: "Tsetserleg",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tsewi Airport",
    longitude: 146.032477,
    latitude: -6.966979,
    id: "arp_tsw_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TSW",
    city_name: "Tsewi",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Tshabong Airport",
    longitude: 22.402543,
    latitude: -26.034154,
    id: "arp_tby_bw",
    icao_code: "FBTS",
    iata_country_code: "BW",
    iata_code: "TBY",
    city_name: "Tshabong",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Tshikapa Airport",
    longitude: 20.794533,
    latitude: -6.438932,
    id: "arp_tsh_cd",
    icao_code: "FZUK",
    iata_country_code: "CD",
    iata_code: "TSH",
    city_name: "Tshikapa",
    city: null,
  },
  {
    time_zone: "Africa/Kinshasa",
    name: "Tshimpi Airport",
    longitude: 13.4404,
    latitude: -5.79961,
    id: "arp_mat_cd",
    icao_code: "FZAM",
    iata_country_code: "CD",
    iata_code: "MAT",
    city_name: "Matadi",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Tshipise Airport",
    longitude: 30.177839,
    latitude: -22.618792,
    id: "arp_tsd_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "TSD",
    city_name: "Tshipise",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tsile Tsile Airport",
    longitude: 146.355561,
    latitude: -6.851867,
    id: "arp_tsi_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TSI",
    city_name: "Tsile Tsile",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Tsimiroro Airport",
    longitude: 44.3176,
    latitude: -20.2847,
    id: "arp_tzo_mg",
    icao_code: null,
    iata_country_code: "MG",
    iata_code: "TZO",
    city_name: "Ankisatra",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Tsiroanomandidy Airport",
    longitude: 46.054196,
    latitude: -18.759463,
    id: "arp_wts_mg",
    icao_code: "FMMX",
    iata_country_code: "MG",
    iata_code: "WTS",
    city_name: "Tsiroanomandidy",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "TSTC Waco Airport",
    longitude: -97.073556,
    latitude: 31.639718,
    id: "arp_cnw_us",
    icao_code: "KCNW",
    iata_country_code: "US",
    iata_code: "CNW",
    city_name: "Waco",
    city: {
      name: "Waco",
      id: "cit_act_us",
      iata_country_code: "US",
      iata_code: "ACT",
    },
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Tsumeb Airport",
    longitude: 17.730666,
    latitude: -19.262037,
    id: "arp_tsb_na",
    icao_code: "FYTM",
    iata_country_code: "NA",
    iata_code: "TSB",
    city_name: "Tsumeb",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Tsushima Airport",
    longitude: 129.328622,
    latitude: 34.28556,
    id: "arp_tsj_jp",
    icao_code: "RJDT",
    iata_country_code: "JP",
    iata_code: "TSJ",
    city_name: "Tsushima",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Tuba City Airport",
    longitude: -111.381371,
    latitude: 36.088691,
    id: "arp_tbc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TBC",
    city_name: "Tuba City",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Tubuai - Mataura Airport",
    longitude: -149.523424,
    latitude: -23.364813,
    id: "arp_tub_pf",
    icao_code: "NTAT",
    iata_country_code: "PF",
    iata_code: "TUB",
    city_name: "Tubuai",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Tubuala Airport",
    longitude: -77.708709,
    latitude: 8.918183,
    id: "arp_tuw_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "TUW",
    city_name: "Tubuala",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Tucson International Airport",
    longitude: -110.940114,
    latitude: 32.115305,
    id: "arp_tus_us",
    icao_code: "KTUS",
    iata_country_code: "US",
    iata_code: "TUS",
    city_name: "Tucson",
    city: {
      name: "Tucson",
      id: "cit_tus_us",
      iata_country_code: "US",
      iata_code: "TUS",
    },
  },
  {
    time_zone: "America/Belem",
    name: "Tucumã Airport",
    longitude: -51.191531,
    latitude: -6.762427,
    id: "arp_tuz_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "TUZ",
    city_name: "Tucumã",
    city: null,
  },
  {
    time_zone: "America/Argentina/Tucuman",
    name: "Tucuman Airport",
    longitude: -65.104897,
    latitude: -26.8409,
    id: "arp_tuc_ar",
    icao_code: "SANT",
    iata_country_code: "AR",
    iata_code: "TUC",
    city_name: "San Miguel de Tucumán",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Tucumcari Municipal Airport",
    longitude: -103.603017,
    latitude: 35.182792,
    id: "arp_tcc_us",
    icao_code: "KTCC",
    iata_country_code: "US",
    iata_code: "TCC",
    city_name: "Tucumcari",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Tucupita Airport",
    longitude: -62.100413,
    latitude: 9.086008,
    id: "arp_tuv_ve",
    icao_code: "SVTC",
    iata_country_code: "VE",
    iata_code: "TUV",
    city_name: "Tucupita",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Tucuruí Airport",
    longitude: -49.720298,
    latitude: -3.78601,
    id: "arp_tur_br",
    icao_code: "SBTU",
    iata_country_code: "BR",
    iata_code: "TUR",
    city_name: "Tucuruí",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tufi Airport",
    longitude: 149.320291,
    latitude: -9.075837,
    id: "arp_tfi_pg",
    icao_code: "AYTU",
    iata_country_code: "PG",
    iata_code: "TFI",
    city_name: "Tufi",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Tugdan Airport",
    longitude: 122.082965,
    latitude: 12.313421,
    id: "arp_tbh_ph",
    icao_code: "RPVU",
    iata_country_code: "PH",
    iata_code: "TBH",
    city_name: "Tablas Island",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Tuguegarao Airport",
    longitude: 121.733246,
    latitude: 17.643477,
    id: "arp_tug_ph",
    icao_code: "RPUT",
    iata_country_code: "PH",
    iata_code: "TUG",
    city_name: "Tuguegarao",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Tuktoyaktuk Airport",
    longitude: -133.025337,
    latitude: 69.43379,
    id: "arp_yub_ca",
    icao_code: "CYUB",
    iata_country_code: "CA",
    iata_code: "YUB",
    city_name: "Tuktoyaktuk",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Tulagi Airport",
    longitude: 160.15,
    latitude: -9.1,
    id: "arp_tlg_sb",
    icao_code: "AGTI",
    iata_country_code: "SB",
    iata_code: "TLG",
    city_name: "Tulagi Island",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Tula Klokovo Air Base Airport",
    longitude: 37.60648,
    latitude: 54.238549,
    id: "arp_tya_ru",
    icao_code: "UUBT",
    iata_country_code: "RU",
    iata_code: "TYA",
    city_name: "Tula",
    city: null,
  },
  {
    time_zone: "Europe/Bucharest",
    name: "Tulcea Airport",
    longitude: 28.715125,
    latitude: 45.062516,
    id: "arp_tce_ro",
    icao_code: "LRTC",
    iata_country_code: "RO",
    iata_code: "TCE",
    city_name: "Tulcea",
    city: null,
  },
  {
    time_zone: "Africa/Gaborone",
    name: "Tuli Lodge Airport",
    longitude: 29.126764,
    latitude: -22.18914,
    id: "arp_tld_bw",
    icao_code: "FBLV",
    iata_country_code: "BW",
    iata_code: "TLD",
    city_name: "Tuli Lodge",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Tulita Airport",
    longitude: -125.568926,
    latitude: 64.90915,
    id: "arp_zfn_ca",
    icao_code: "CZFN",
    iata_country_code: "CA",
    iata_code: "ZFN",
    city_name: "Tulita",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tullahoma Regional Airport",
    longitude: -86.244603,
    latitude: 35.37995,
    id: "arp_tha_us",
    icao_code: "KTHA",
    iata_country_code: "US",
    iata_code: "THA",
    city_name: "Tullahoma",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tulsa International Airport",
    longitude: -95.885982,
    latitude: 36.198588,
    id: "arp_tul_us",
    icao_code: "KTUL",
    iata_country_code: "US",
    iata_code: "TUL",
    city_name: "Tulsa",
    city: {
      name: "Tulsa",
      id: "cit_tul_us",
      iata_country_code: "US",
      iata_code: "TUL",
    },
  },
  {
    time_zone: "Asia/Katmandu",
    name: "Tulsipur Airport",
    longitude: 82.294253,
    latitude: 28.111241,
    id: "arp_dnp_np",
    icao_code: "VNDG",
    iata_country_code: "NP",
    iata_code: "DNP",
    city_name: "Dang",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Tuluksak Airport",
    longitude: -160.969111,
    latitude: 61.0969,
    id: "arp_tlt_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TLT",
    city_name: "Tuluksak",
    city: null,
  },
  {
    time_zone: "America/Cancun",
    name: "Tulum Airport",
    longitude: -87.438098,
    latitude: 20.227077,
    id: "arp_tuy_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "TUY",
    city_name: "Tulum",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Tum Airport",
    longitude: 35.519577,
    latitude: 6.259061,
    id: "arp_tuj_et",
    icao_code: "HAMJ",
    iata_country_code: "ET",
    iata_code: "TUJ",
    city_name: "Tum",
    city: null,
  },
  {
    time_zone: "Asia/Pontianak",
    name: "Tumbang Samba Airport",
    longitude: 113.08233,
    latitude: -1.468707,
    id: "arp_tbm_id",
    icao_code: "WAOW",
    iata_country_code: "ID",
    iata_code: "TBM",
    city_name: "Tumbang Samba",
    city: null,
  },
  {
    time_zone: "America/Dawson_Creek",
    name: "Tumbler Ridge Airport",
    longitude: -120.933674,
    latitude: 55.02636,
    id: "arp_tux_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "TUX",
    city_name: "Tumbler Ridge",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Tumeremo Airport",
    longitude: -61.526241,
    latitude: 7.250528,
    id: "arp_tmo_ve",
    icao_code: "SVTM",
    iata_country_code: "VE",
    iata_code: "TMO",
    city_name: "Tumeremo",
    city: null,
  },
  {
    time_zone: "Asia/Kathmandu",
    name: "Tumlingtar Airport",
    longitude: 87.194068,
    latitude: 27.315371,
    id: "arp_tmi_np",
    icao_code: "VNTR",
    iata_country_code: "NP",
    iata_code: "TMI",
    city_name: "Tumlingtar",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Tumolbil Airport",
    longitude: 141.013497,
    latitude: -4.775554,
    id: "arp_tlp_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "TLP",
    city_name: "Tumolbil",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Tumut Airport",
    longitude: 148.241664,
    latitude: -35.264832,
    id: "arp_tum_au",
    icao_code: "YTMU",
    iata_country_code: "AU",
    iata_code: "TUM",
    city_name: "Tumut",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Tunggul Wulung Airport",
    longitude: 109.0339,
    latitude: -7.645113,
    id: "arp_cxp_id",
    icao_code: "WIHL",
    iata_country_code: "ID",
    iata_code: "CXP",
    city_name: "Cilacap",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Tungsten Airport",
    longitude: -128.201481,
    latitude: 61.956332,
    id: "arp_tns_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "TNS",
    city_name: "Tungsten",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tunica Municipal Airport",
    longitude: -90.349057,
    latitude: 34.682149,
    id: "arp_utm_us",
    icao_code: "KUTA",
    iata_country_code: "US",
    iata_code: "UTM",
    city_name: "Tunica",
    city: null,
  },
  {
    time_zone: "Africa/Tunis",
    name: "Tunis-Carthage International Airport",
    longitude: 10.225104,
    latitude: 36.849743,
    id: "arp_tun_tn",
    icao_code: "DTTA",
    iata_country_code: "TN",
    iata_code: "TUN",
    city_name: "Tunis",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Tunoshna Airport",
    longitude: 40.163827,
    latitude: 57.561509,
    id: "arp_iar_ru",
    icao_code: "UUDL",
    iata_country_code: "RU",
    iata_code: "IAR",
    city_name: "Yaroslavl",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Tunta Airport",
    longitude: 24.484874,
    latitude: -6.134452,
    id: "arp_kbn_cd",
    icao_code: "FZWT",
    iata_country_code: "CD",
    iata_code: "KBN",
    city_name: "Kabinda",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Tuntutuliak Airport",
    longitude: -162.664962,
    latitude: 60.336585,
    id: "arp_wtl_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WTL",
    city_name: "Tuntutuliak",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Tununak Airport",
    longitude: -165.271775,
    latitude: 60.575534,
    id: "arp_tnk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TNK",
    city_name: "Tununak",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Tupai Airport",
    longitude: -151.8338,
    latitude: -16.2833,
    id: "arp_tpx_pf",
    icao_code: "NTTU",
    iata_country_code: "PF",
    iata_code: "TPX",
    city_name: "Tupai",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tupelo Regional Airport",
    longitude: -88.768812,
    latitude: 34.267335,
    id: "arp_tup_us",
    icao_code: "KTUP",
    iata_country_code: "US",
    iata_code: "TUP",
    city_name: "Tupelo",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Turaif Domestic Airport",
    longitude: 38.732294,
    latitude: 31.691086,
    id: "arp_tui_sa",
    icao_code: "OETR",
    iata_country_code: "SA",
    iata_code: "TUI",
    city_name: "Turaif",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Turbat International Airport",
    longitude: 63.029102,
    latitude: 25.987328,
    id: "arp_tuk_pk",
    icao_code: "OPTU",
    iata_country_code: "PK",
    iata_code: "TUK",
    city_name: "Turbat",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Tureia Airport",
    longitude: -138.570007,
    latitude: -20.789699,
    id: "arp_zta_pf",
    icao_code: "NTGY",
    iata_country_code: "PF",
    iata_code: "ZTA",
    city_name: "Tureia",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Turin Airport",
    longitude: 7.647544,
    latitude: 45.197399,
    id: "arp_trn_it",
    icao_code: "LIMF",
    iata_country_code: "IT",
    iata_code: "TRN",
    city_name: "Turin",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Turkey Creek Airport",
    longitude: 128.207342,
    latitude: -17.038242,
    id: "arp_tky_au",
    icao_code: "YTKY",
    iata_country_code: "AU",
    iata_code: "TKY",
    city_name: "Turkey Creek",
    city: null,
  },
  {
    time_zone: "Asia/Ashgabat",
    name: "Turkmenabat Airport",
    longitude: 63.612663,
    latitude: 39.083497,
    id: "arp_crz_tm",
    icao_code: "UTAV",
    iata_country_code: "TM",
    iata_code: "CRZ",
    city_name: "Türkmenabat",
    city: null,
  },
  {
    time_zone: "Asia/Ashgabat",
    name: "Turkmenbashi Airport",
    longitude: 53.00031,
    latitude: 40.049745,
    id: "arp_krw_tm",
    icao_code: "UTAK",
    iata_country_code: "TM",
    iata_code: "KRW",
    city_name: "Turkmenistan",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Turku Airport",
    longitude: 22.263042,
    latitude: 60.512798,
    id: "arp_tku_fi",
    icao_code: "EFTU",
    iata_country_code: "FI",
    iata_code: "TKU",
    city_name: "Turku",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Turlatovo Airport",
    longitude: 39.852641,
    latitude: 54.554615,
    id: "arp_rzn_ru",
    icao_code: "UUWR",
    iata_country_code: "RU",
    iata_code: "RZN",
    city_name: "Ryazan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Turpan Jiaohe Airport",
    longitude: 89.101079,
    latitude: 43.029673,
    id: "arp_tlq_cn",
    icao_code: "ZWTP",
    iata_country_code: "CN",
    iata_code: "TLQ",
    city_name: "Turpan",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Turtle Island Seaplane Base",
    longitude: 177.368,
    latitude: -16.966,
    id: "arp_ttl_fj",
    icao_code: "NFUL",
    iata_country_code: "FJ",
    iata_code: "TTL",
    city_name: "Nanuya Levu",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Turukhansk Airport",
    longitude: 87.935142,
    latitude: 65.797491,
    id: "arp_thx_ru",
    icao_code: "UOTT",
    iata_country_code: "RU",
    iata_code: "THX",
    city_name: "Turukhansk",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tuscaloosa Regional Airport",
    longitude: -87.610915,
    latitude: 33.220203,
    id: "arp_tcl_us",
    icao_code: "KTCL",
    iata_country_code: "US",
    iata_code: "TCL",
    city_name: "Tuscaloosa",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Tuscola Area Airport",
    longitude: -83.446625,
    latitude: 43.458215,
    id: "arp_tzc_us",
    icao_code: "KCFS",
    iata_country_code: "US",
    iata_code: "TZC",
    city_name: "Caro",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Tuticorin Airport",
    longitude: 78.026546,
    latitude: 8.725349,
    id: "arp_tcr_in",
    icao_code: "VOTK",
    iata_country_code: "IN",
    iata_code: "TCR",
    city_name: "Thoothukudi",
    city: null,
  },
  {
    time_zone: "Europe/Sarajevo",
    name: "Tuzla International Airport",
    longitude: 18.724986,
    latitude: 44.458664,
    id: "arp_tzl_ba",
    icao_code: "LQTZ",
    iata_country_code: "BA",
    iata_code: "TZL",
    city_name: "Tuzla",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Tweed New Haven Airport",
    longitude: -72.886837,
    latitude: 41.265795,
    id: "arp_hvn_us",
    icao_code: "KHVN",
    iata_country_code: "US",
    iata_code: "HVN",
    city_name: "New Haven",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Twentynine Palms Airport",
    longitude: -115.946279,
    latitude: 34.13273,
    id: "arp_tnp_us",
    icao_code: "KTNP",
    iata_country_code: "US",
    iata_code: "TNP",
    city_name: "Twentynine Palms",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Twin Hills Airport",
    longitude: -160.274001,
    latitude: 59.075325,
    id: "arp_twa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TWA",
    city_name: "Twin Hills",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Tydeo Larre Borges Airport",
    longitude: -58.065294,
    latitude: -32.362745,
    id: "arp_pdu_uy",
    icao_code: "SUPU",
    iata_country_code: "UY",
    iata_code: "PDU",
    city_name: "Paysandu",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tyler Pounds Regional Airport",
    longitude: -95.406013,
    latitude: 32.352612,
    id: "arp_tyr_us",
    icao_code: "KTYR",
    iata_country_code: "US",
    iata_code: "TYR",
    city_name: "Tyler",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Tynda Airport",
    longitude: 124.760166,
    latitude: 55.281799,
    id: "arp_tyd_ru",
    icao_code: "UHBW",
    iata_country_code: "RU",
    iata_code: "TYD",
    city_name: "Tynda",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Tyndall Air Force Base",
    longitude: -85.580964,
    latitude: 30.068417,
    id: "arp_pam_us",
    icao_code: "KPAM",
    iata_country_code: "US",
    iata_code: "PAM",
    city_name: "Panama City",
    city: {
      name: "Panama City",
      id: "cit_ecp_us",
      iata_country_code: "US",
      iata_code: "ECP",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Tyonek Airport",
    longitude: -151.136968,
    latitude: 61.07432,
    id: "arp_tye_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "TYE",
    city_name: "Tyonek",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Tzaneen Airport",
    longitude: 30.327416,
    latitude: -23.824869,
    id: "arp_lta_za",
    icao_code: "FATZ",
    iata_country_code: "ZA",
    iata_code: "LTA",
    city_name: "Tzaneen",
    city: null,
  },
  {
    time_zone: "Pacific/Marquesas",
    name: "Ua Huka Airport",
    longitude: -139.552652,
    latitude: -8.936169,
    id: "arp_uah_pf",
    icao_code: "NTMU",
    iata_country_code: "PF",
    iata_code: "UAH",
    city_name: "Ua Huka",
    city: null,
  },
  {
    time_zone: "Pacific/Marquesas",
    name: "Ua Pou Airport",
    longitude: -140.078871,
    latitude: -9.34943,
    id: "arp_uap_pf",
    icao_code: "NTMP",
    iata_country_code: "PF",
    iata_code: "UAP",
    city_name: "Ua Pou",
    city: null,
  },
  {
    time_zone: "America/Guatemala",
    name: "Uaxactun Airport",
    longitude: -89.634447,
    latitude: 17.393316,
    id: "arp_uax_gt",
    icao_code: null,
    iata_country_code: "GT",
    iata_code: "UAX",
    city_name: "Uaxactun",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Ubari Airport",
    longitude: 12.822194,
    latitude: 26.567945,
    id: "arp_qub_ly",
    icao_code: "HLUB",
    iata_country_code: "LY",
    iata_code: "QUB",
    city_name: "Ubari",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Ubatuba Airport",
    longitude: -45.0745,
    latitude: -23.440886,
    id: "arp_ubt_br",
    icao_code: "SDUB",
    iata_country_code: "BR",
    iata_code: "UBT",
    city_name: "Ubatuba",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Uberaba Airport",
    longitude: -47.964863,
    latitude: -19.765164,
    id: "arp_uba_br",
    icao_code: "SBUR",
    iata_country_code: "BR",
    iata_code: "UBA",
    city_name: "Uberaba",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Uberlândia Airport",
    longitude: -48.226163,
    latitude: -18.884506,
    id: "arp_udi_br",
    icao_code: "SBUL",
    iata_country_code: "BR",
    iata_code: "UDI",
    city_name: "Uberlândia",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Ubon Ratchathani Airport",
    longitude: 104.870315,
    latitude: 15.249156,
    id: "arp_ubp_th",
    icao_code: "VTUU",
    iata_country_code: "TH",
    iata_code: "UBP",
    city_name: "Ubon Ratchathani",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Ubrub Airport",
    longitude: 140.85,
    latitude: -3.66667,
    id: "arp_ubr_id",
    icao_code: "WAJU",
    iata_country_code: "ID",
    iata_code: "UBR",
    city_name: "Ubrub",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Udine-Campoformido Air Base Airport",
    longitude: 13.185067,
    latitude: 46.032767,
    id: "arp_udn_it",
    icao_code: "LIPD",
    iata_country_code: "IT",
    iata_code: "UDN",
    city_name: "Udine",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Udon Thani International Airport",
    longitude: 102.776719,
    latitude: 17.387286,
    id: "arp_uth_th",
    icao_code: "VTUD",
    iata_country_code: "TH",
    iata_code: "UTH",
    city_name: "Udon Thani",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Ufa International Airport",
    longitude: 55.882816,
    latitude: 54.562431,
    id: "arp_ufa_ru",
    icao_code: "UWUU",
    iata_country_code: "RU",
    iata_code: "UFA",
    city_name: "Ufa",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Uganik Island Airport",
    longitude: -153.320395,
    latitude: 57.730478,
    id: "arp_ugi_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UGI",
    city_name: "Uganik",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ugashik Airport",
    longitude: -157.39393,
    latitude: 57.526434,
    id: "arp_ugs_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UGS",
    city_name: "Ugashik",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Ugashik Bay Airport",
    longitude: -157.742377,
    latitude: 57.424927,
    id: "arp_ugb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UGB",
    city_name: "Pilot Point",
    city: {
      name: "Pilot Point",
      id: "cit_pip_us",
      iata_country_code: "US",
      iata_code: "PIP",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Ugnu-Kuparuk Airport",
    longitude: -149.596416,
    latitude: 70.331098,
    id: "arp_uuk_us",
    icao_code: "PAKU",
    iata_country_code: "US",
    iata_code: "UUK",
    city_name: "Kuparuk",
    city: null,
  },
  {
    time_zone: "Asia/Anadyr",
    name: "Ugolny Airport",
    longitude: 177.738372,
    latitude: 64.713802,
    id: "arp_dyr_ru",
    icao_code: "UHMA",
    iata_country_code: "RU",
    iata_code: "DYR",
    city_name: "Anadyr",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Uige Airport",
    longitude: 15.027783,
    latitude: -7.602972,
    id: "arp_ugo_ao",
    icao_code: "FNUG",
    iata_country_code: "AO",
    iata_code: "UGO",
    city_name: "Uige",
    city: null,
  },
  {
    time_zone: "Asia/Pyongyang",
    name: "Uiju Airfield",
    longitude: 124.504078,
    latitude: 40.15486,
    id: "arp_uju_kp",
    icao_code: "ZKUJ",
    iata_country_code: "KP",
    iata_code: "UJU",
    city_name: "Uiju",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Ujae Airport",
    longitude: 165.761993,
    latitude: 8.92806,
    id: "arp_uje_mh",
    icao_code: "UJAP",
    iata_country_code: "MH",
    iata_code: "UJE",
    city_name: "Ujae Atoll",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Ukhta Airport",
    longitude: 53.804668,
    latitude: 63.555503,
    id: "arp_uct_ru",
    icao_code: "UUYH",
    iata_country_code: "RU",
    iata_code: "UCT",
    city_name: "Ukhta",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Ukiah Municipal Airport",
    longitude: -123.201179,
    latitude: 39.126833,
    id: "arp_uki_us",
    icao_code: "KUKI",
    iata_country_code: "US",
    iata_code: "UKI",
    city_name: "Ukiah",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Ukunda Airport",
    longitude: 39.571543,
    latitude: -4.294236,
    id: "arp_uka_ke",
    icao_code: "HKUK",
    iata_country_code: "KE",
    iata_code: "UKA",
    city_name: "Ukunda",
    city: null,
  },
  {
    time_zone: "Asia/Hovd",
    name: "Ulaangom Airport",
    longitude: 91.936694,
    latitude: 50.066249,
    id: "arp_ulo_mn",
    icao_code: "ZMUG",
    iata_country_code: "MN",
    iata_code: "ULO",
    city_name: "Ulaangom",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ulanhot Airport",
    longitude: 122.008125,
    latitude: 46.19538,
    id: "arp_hlh_cn",
    icao_code: "ZBUL",
    iata_country_code: "CN",
    iata_code: "HLH",
    city_name: "Ulanhot",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ulanqab Jining Airport",
    longitude: 113.108056,
    latitude: 41.129721,
    id: "arp_ucb_cn",
    icao_code: "ZBWL",
    iata_country_code: "CN",
    iata_code: "UCB",
    city_name: "Ulanqab",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Ulawa Airport",
    longitude: 161.979546,
    latitude: -9.860543,
    id: "arp_rna_sb",
    icao_code: "AGAR",
    iata_country_code: "SB",
    iata_code: "RNA",
    city_name: "Arona",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Ulei Airport",
    longitude: 168.30094,
    latitude: -16.32978,
    id: "arp_ulb_vu",
    icao_code: "NVSU",
    iata_country_code: "VU",
    iata_code: "ULB",
    city_name: "Ambryn Island",
    city: null,
  },
  {
    time_zone: "Pacific/Chuuk",
    name: "Ulithi Airport",
    longitude: 139.789802,
    latitude: 10.01989,
    id: "arp_uli_fm",
    icao_code: null,
    iata_country_code: "FM",
    iata_code: "ULI",
    city_name: "Ulithi",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Uljin Airport",
    longitude: 129.464178,
    latitude: 36.778245,
    id: "arp_ujn_kr",
    icao_code: "RKTL",
    iata_country_code: "KR",
    iata_code: "UJN",
    city_name: "Uljin",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Ulsan Airport",
    longitude: 129.353896,
    latitude: 35.593461,
    id: "arp_usn_kr",
    icao_code: "RKPU",
    iata_country_code: "KR",
    iata_code: "USN",
    city_name: "Ulsan",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Ulukhaktok Holman Airport",
    longitude: -117.806033,
    latitude: 70.762798,
    id: "arp_yhi_ca",
    icao_code: "CYHI",
    iata_country_code: "CA",
    iata_code: "YHI",
    city_name: "Ulukhaktok",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Ulusaba Airport",
    longitude: 31.354652,
    latitude: -24.783713,
    id: "arp_ulx_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "ULX",
    city_name: "Ulusaba",
    city: null,
  },
  {
    time_zone: "Europe/Ulyanovsk",
    name: "Ulyanovsk Baratayevka Airport",
    longitude: 48.231956,
    latitude: 54.28632,
    id: "arp_ulv_ru",
    icao_code: "UWLL",
    iata_country_code: "RU",
    iata_code: "ULV",
    city_name: "Ulyanovsk",
    city: null,
  },
  {
    time_zone: "Europe/Ulyanovsk",
    name: "Ulyanovsk Vostochny Airport",
    longitude: 48.801293,
    latitude: 54.413571,
    id: "arp_uly_ru",
    icao_code: "UWLW",
    iata_country_code: "RU",
    iata_code: "ULY",
    city_name: "Ulyanovsk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Umba Airport",
    longitude: 145.965766,
    latitude: -7.02127,
    id: "arp_umc_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UMC",
    city_name: "Umba",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Umberto Modiano Airport",
    longitude: -41.962387,
    latitude: -22.770089,
    id: "arp_bzc_br",
    icao_code: "SBBZ",
    iata_country_code: "BR",
    iata_code: "BZC",
    city_name: "Buzios",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Umbu Mehang Kunda Airport",
    longitude: 120.301346,
    latitude: -9.669232,
    id: "arp_wgp_id",
    icao_code: "WADW",
    iata_country_code: "ID",
    iata_code: "WGP",
    city_name: "Waingapu",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Umeå Airport",
    longitude: 20.286169,
    latitude: 63.792545,
    id: "arp_ume_se",
    icao_code: "ESNU",
    iata_country_code: "SE",
    iata_code: "UME",
    city_name: "Umea",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Umiat Airport",
    longitude: -152.136481,
    latitude: 69.370848,
    id: "arp_umt_us",
    icao_code: "PAUM",
    iata_country_code: "US",
    iata_code: "UMT",
    city_name: "Umiat",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Umiujaq Airport",
    longitude: -76.518852,
    latitude: 56.536899,
    id: "arp_yud_ca",
    icao_code: "CYMU",
    iata_country_code: "CA",
    iata_code: "YUD",
    city_name: "Umiujaq",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Umuarama Airport",
    longitude: -53.314232,
    latitude: -23.798418,
    id: "arp_umu_br",
    icao_code: "SSUM",
    iata_country_code: "BR",
    iata_code: "UMU",
    city_name: "Umuarama",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Una-Comandatuba Airport",
    longitude: -38.99735,
    latitude: -15.35645,
    id: "arp_una_br",
    icao_code: "SBTC",
    iata_country_code: "BR",
    iata_code: "UNA",
    city_name: "Una",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Unalakleet Airport",
    longitude: -160.799053,
    latitude: 63.88841,
    id: "arp_unk_us",
    icao_code: "PAUN",
    iata_country_code: "US",
    iata_code: "UNK",
    city_name: "Unalakleet",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Unalaska Airport",
    longitude: -166.544227,
    latitude: 53.899368,
    id: "arp_dut_us",
    icao_code: "PADU",
    iata_country_code: "US",
    iata_code: "DUT",
    city_name: "Unalaska",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Undara Airport",
    longitude: 144.611209,
    latitude: -18.190896,
    id: "arp_uda_au",
    icao_code: "YUDA",
    iata_country_code: "AU",
    iata_code: "UDA",
    city_name: "Undara",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Unguia Airport",
    longitude: -77.085189,
    latitude: 8.023491,
    id: "arp_unc_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "UNC",
    city_name: "Unguia",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Union County Airport",
    longitude: -83.353014,
    latitude: 40.224672,
    id: "arp_usc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "USC",
    city_name: "Union",
    city: null,
  },
  {
    time_zone: "America/St_Vincent",
    name: "Union Island Airport",
    longitude: -61.41303,
    latitude: 12.599701,
    id: "arp_uni_vc",
    icao_code: "TVSU",
    iata_country_code: "VC",
    iata_code: "UNI",
    city_name: "Union Island",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "United States Air Force Academy Airfield",
    longitude: -104.814704,
    latitude: 38.970442,
    id: "arp_aff_us",
    icao_code: "KAFF",
    iata_country_code: "US",
    iata_code: "AFF",
    city_name: "Colorado Springs",
    city: {
      name: "Colorado Springs",
      id: "cit_cos_us",
      iata_country_code: "US",
      iata_code: "COS",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "University of Illinois Willard Airport",
    longitude: -88.263602,
    latitude: 40.036127,
    id: "arp_cmi_us",
    icao_code: "KCMI",
    iata_country_code: "US",
    iata_code: "CMI",
    city_name: "Champaign",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "University-Oxford Airport",
    longitude: -89.537865,
    latitude: 34.384124,
    id: "arp_uox_us",
    icao_code: "KUOX",
    iata_country_code: "US",
    iata_code: "UOX",
    city_name: "Oxford",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "University Park Airport",
    longitude: -77.847483,
    latitude: 40.851117,
    id: "arp_sce_us",
    icao_code: "KUNV",
    iata_country_code: "US",
    iata_code: "SCE",
    city_name: "State College",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Unst Airport",
    longitude: -0.853196,
    latitude: 60.746817,
    id: "arp_unt_gb",
    icao_code: "EGPW",
    iata_country_code: "GB",
    iata_code: "UNT",
    city_name: "Unst",
    city: null,
  },
  {
    time_zone: "America/Costa_Rica",
    name: "Upala Airport",
    longitude: -85.015142,
    latitude: 10.893251,
    id: "arp_upl_cr",
    icao_code: "MRUP",
    iata_country_code: "CR",
    iata_code: "UPL",
    city_name: "Upala",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Upavon Airport",
    longitude: -1.778333,
    latitude: 51.290321,
    id: "arp_upv_gb",
    icao_code: "EGDJ",
    iata_country_code: "GB",
    iata_code: "UPV",
    city_name: "Upavon",
    city: null,
  },
  {
    time_zone: "America/Godthab",
    name: "Upernavik Airport",
    longitude: -56.130585,
    latitude: 72.790132,
    id: "arp_juv_gl",
    icao_code: "BGUK",
    iata_country_code: "GL",
    iata_code: "JUV",
    city_name: "Upernavik",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Upiara Airport",
    longitude: 142.650525,
    latitude: -8.541471,
    id: "arp_upr_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UPR",
    city_name: "Upiara",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Upington Airport",
    longitude: 21.257648,
    latitude: -28.408296,
    id: "arp_utn_za",
    icao_code: "FAUP",
    iata_country_code: "ZA",
    iata_code: "UTN",
    city_name: "Upington",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Upolu Airport",
    longitude: -155.859905,
    latitude: 20.26529,
    id: "arp_upp_us",
    icao_code: "PHUP",
    iata_country_code: "US",
    iata_code: "UPP",
    city_name: "Hawi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Urad Middle Banner Airport",
    longitude: 108.53454,
    latitude: 41.45958,
    id: "arp_wzq_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "WZQ",
    city_name: "Urad Middle Banner",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Uranium City Airport",
    longitude: -108.481701,
    latitude: 59.561,
    id: "arp_ybe_ca",
    icao_code: "CYBE",
    iata_country_code: "CA",
    iata_code: "YBE",
    city_name: "Uranium City",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Uray Airport",
    longitude: 64.825323,
    latitude: 60.103637,
    id: "arp_urj_ru",
    icao_code: "USHU",
    iata_country_code: "RU",
    iata_code: "URJ",
    city_name: "Uray",
    city: null,
  },
  {
    time_zone: "Asia/Yekaterinburg",
    name: "Urengoy Airport",
    longitude: 78.429961,
    latitude: 65.959791,
    id: "arp_uen_ru",
    icao_code: "USDU",
    iata_country_code: "RU",
    iata_code: "UEN",
    city_name: "Urengoy",
    city: null,
  },
  {
    time_zone: "Asia/Samarkand",
    name: "Urgench International Airport",
    longitude: 60.642066,
    latitude: 41.584092,
    id: "arp_ugc_uz",
    icao_code: "UTNU",
    iata_country_code: "UZ",
    iata_code: "UGC",
    city_name: "Urgench",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Urgun Airport",
    longitude: 69.156132,
    latitude: 32.932377,
    id: "arp_urn_af",
    icao_code: "OAOG",
    iata_country_code: "AF",
    iata_code: "URN",
    city_name: "Urgun",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Uribe Airport",
    longitude: -74.346782,
    latitude: 3.237179,
    id: "arp_uri_co",
    icao_code: "SKUB",
    iata_country_code: "CO",
    iata_code: "URI",
    city_name: "Uribe",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Uriman Airport",
    longitude: -62.671975,
    latitude: 5.358259,
    id: "arp_urm_ve",
    icao_code: "SVUM",
    iata_country_code: "VE",
    iata_code: "URM",
    city_name: "Uriman",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Urmia Airport",
    longitude: 45.06042,
    latitude: 37.661899,
    id: "arp_omh_ir",
    icao_code: "OITR",
    iata_country_code: "IR",
    iata_code: "OMH",
    city_name: "Urumiyeh",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Uroubi Airport",
    longitude: 148.559712,
    latitude: -9.51601,
    id: "arp_uru_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "URU",
    city_name: "Uroubi",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Urrao Airport",
    longitude: -76.140871,
    latitude: 6.326154,
    id: "arp_urr_co",
    icao_code: "SKUR",
    iata_country_code: "CO",
    iata_code: "URR",
    city_name: "Urrao",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Uruapan International Airport",
    longitude: -102.038584,
    latitude: 19.397941,
    id: "arp_upn_mx",
    icao_code: "MMPN",
    iata_country_code: "MX",
    iata_code: "UPN",
    city_name: "Uruapan",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Urubupunga Airport",
    longitude: -51.565598,
    latitude: -20.777097,
    id: "arp_urb_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "URB",
    city_name: "Castilho",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Uru Harbour Airport",
    longitude: 161.008637,
    latitude: -8.872155,
    id: "arp_atd_sb",
    icao_code: "AGAT",
    iata_country_code: "SB",
    iata_code: "ATD",
    city_name: "Atoifi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Ürümqi Diwopu International Airport",
    longitude: 87.474182,
    latitude: 43.907161,
    id: "arp_urc_cn",
    icao_code: "ZWWW",
    iata_country_code: "CN",
    iata_code: "URC",
    city_name: "Urumqi",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Uruzgan Airport",
    longitude: 65.865302,
    latitude: 32.610562,
    id: "arp_urz_af",
    icao_code: "OARG",
    iata_country_code: "AF",
    iata_code: "URZ",
    city_name: "Uruzgan",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Urzhar Airport",
    longitude: 81.66521,
    latitude: 47.09115,
    id: "arp_uzr_kz",
    icao_code: "UASU",
    iata_country_code: "KZ",
    iata_code: "UZR",
    city_name: "Urdzhar",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Uşak Airport",
    longitude: 29.473383,
    latitude: 38.681006,
    id: "arp_usq_tr",
    icao_code: "LTBO",
    iata_country_code: "TR",
    iata_code: "USQ",
    city_name: "Usak",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Useless Loop Airport",
    longitude: 113.39482,
    latitude: -26.15678,
    id: "arp_usl_au",
    icao_code: "YUSL",
    iata_country_code: "AU",
    iata_code: "USL",
    city_name: "Useless Loop",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Usharal Airport",
    longitude: 80.831093,
    latitude: 46.190785,
    id: "arp_usj_kz",
    icao_code: null,
    iata_country_code: "KZ",
    iata_code: "USJ",
    city_name: "Usharal",
    city: null,
  },
  {
    time_zone: "America/Argentina/Ushuaia",
    name: "Ushuaia - Malvinas Argentinas International Airport",
    longitude: -68.303143,
    latitude: -54.842043,
    id: "arp_ush_ar",
    icao_code: "SAWH",
    iata_country_code: "AR",
    iata_code: "USH",
    city_name: "Ushuaia",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Usiminas Airport",
    longitude: -42.487672,
    latitude: -19.472554,
    id: "arp_ipn_br",
    icao_code: "SBIP",
    iata_country_code: "BR",
    iata_code: "IPN",
    city_name: "Ipatinga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Usino Airport",
    longitude: 145.442817,
    latitude: -5.505254,
    id: "arp_uso_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "USO",
    city_name: "Usino",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Usinsk Airport",
    longitude: 57.371739,
    latitude: 66.003643,
    id: "arp_usk_ru",
    icao_code: "UUYS",
    iata_country_code: "RU",
    iata_code: "USK",
    city_name: "Usinsk",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Ust-Ilimsk Airport",
    longitude: 102.560567,
    latitude: 58.135788,
    id: "arp_uik_ru",
    icao_code: "UIBS",
    iata_country_code: "RU",
    iata_code: "UIK",
    city_name: "Ust-Ilimsk",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Ust-Kut Airport",
    longitude: 105.725331,
    latitude: 56.85747,
    id: "arp_ukx_ru",
    icao_code: "UITT",
    iata_country_code: "RU",
    iata_code: "UKX",
    city_name: "Ust-Kut",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Ust-Kuyga Airport",
    longitude: 135.639178,
    latitude: 70.009376,
    id: "arp_ukg_ru",
    icao_code: "UEBT",
    iata_country_code: "RU",
    iata_code: "UKG",
    city_name: "Ust-Kuyga",
    city: null,
  },
  {
    time_zone: "Asia/Khandyga",
    name: "Ust-Maya Airport",
    longitude: 134.434997,
    latitude: 60.356998,
    id: "arp_ums_ru",
    icao_code: "UEMU",
    iata_country_code: "RU",
    iata_code: "UMS",
    city_name: "Ust-Maya",
    city: null,
  },
  {
    time_zone: "Asia/Ust-Nera",
    name: "Ust-Nera Airport",
    longitude: 143.115005,
    latitude: 64.550003,
    id: "arp_usr_ru",
    icao_code: "UEMT",
    iata_country_code: "RU",
    iata_code: "USR",
    city_name: "Ust-Nera",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Ust-Tsilma Airport",
    longitude: 52.200336,
    latitude: 65.437294,
    id: "arp_uts_ru",
    icao_code: "UUYX",
    iata_country_code: "RU",
    iata_code: "UTS",
    city_name: "Ust-Tsilma",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Ustupo Airport",
    longitude: -77.933369,
    latitude: 9.137784,
    id: "arp_utu_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "UTU",
    city_name: "Ustupo",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Ustupo Ogobsucum Airport",
    longitude: -77.933841,
    latitude: 9.138155,
    id: "arp_ogm_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "OGM",
    city_name: "Ustupo",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "U-Tapao International Airport",
    longitude: 101.001521,
    latitude: 12.677584,
    id: "arp_utp_th",
    icao_code: "VTBU",
    iata_country_code: "TH",
    iata_code: "UTP",
    city_name: "Rayong",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Utila Airport",
    longitude: -86.881037,
    latitude: 16.112522,
    id: "arp_uii_hn",
    icao_code: "MHUT",
    iata_country_code: "HN",
    iata_code: "UII",
    city_name: "Utila",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Utirik Airport",
    longitude: 169.853238,
    latitude: 11.222306,
    id: "arp_utk_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "UTK",
    city_name: "Utirik Island",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Utti Airport",
    longitude: 26.939084,
    latitude: 60.895947,
    id: "arp_uti_fi",
    icao_code: "EFUT",
    iata_country_code: "FI",
    iata_code: "UTI",
    city_name: "Utti",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Uvol Airport",
    longitude: 150.955164,
    latitude: -6.01812,
    id: "arp_uvo_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "UVO",
    city_name: "Uvol",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Uytash Airport",
    longitude: 47.653887,
    latitude: 42.818872,
    id: "arp_mcx_ru",
    icao_code: "URML",
    iata_country_code: "RU",
    iata_code: "MCX",
    city_name: "Makhachkala",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Uyuni Airport",
    longitude: -66.844675,
    latitude: -20.447365,
    id: "arp_uyu_bo",
    icao_code: "SLUY",
    iata_country_code: "BO",
    iata_code: "UYU",
    city_name: "Uyuni",
    city: null,
  },
  {
    time_zone: "Europe/Uzhgorod",
    name: "Uzhhorod International Airport",
    longitude: 22.267871,
    latitude: 48.635708,
    id: "arp_udj_ua",
    icao_code: "UKLU",
    iata_country_code: "UA",
    iata_code: "UDJ",
    city_name: "Uzhhorod",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Vaasa Airport",
    longitude: 21.762456,
    latitude: 63.050974,
    id: "arp_vaa_fi",
    icao_code: "EFVA",
    iata_country_code: "FI",
    iata_code: "VAA",
    city_name: "Vaasa",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Václav Havel Airport Prague",
    longitude: 14.26159,
    latitude: 50.101295,
    id: "arp_prg_cz",
    icao_code: "LKPR",
    iata_country_code: "CZ",
    iata_code: "PRG",
    city_name: "Prague",
    city: {
      name: "Prague",
      id: "cit_prg_cz",
      iata_country_code: "CZ",
      iata_code: "PRG",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Vadodara Airport",
    longitude: 73.218308,
    latitude: 22.329921,
    id: "arp_bdq_in",
    icao_code: "VABO",
    iata_country_code: "IN",
    iata_code: "BDQ",
    city_name: "Vadodara",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Vadsø Airport",
    longitude: 29.842781,
    latitude: 70.065066,
    id: "arp_vds_no",
    icao_code: "ENVD",
    iata_country_code: "NO",
    iata_code: "VDS",
    city_name: "Vadsø",
    city: null,
  },
  {
    time_zone: "Atlantic/Faroe",
    name: "Vágar Airport",
    longitude: -7.276339,
    latitude: 62.063464,
    id: "arp_fae_fo",
    icao_code: "EKVG",
    iata_country_code: "FO",
    iata_code: "FAE",
    city_name: "Faroe Islands",
    city: null,
  },
  {
    time_zone: "Pacific/Tahiti",
    name: "Vahitahi Airport",
    longitude: -138.852996,
    latitude: -18.78,
    id: "arp_vhz_pf",
    icao_code: "NTUV",
    iata_country_code: "PF",
    iata_code: "VHZ",
    city_name: "Vahitahi",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Valcheta Airport",
    longitude: -66.155633,
    latitude: -40.698877,
    id: "arp_vcf_ar",
    icao_code: null,
    iata_country_code: "AR",
    iata_code: "VCF",
    city_name: "Valcheta",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Valdez Airport",
    longitude: -146.248167,
    latitude: 61.133894,
    id: "arp_vdz_us",
    icao_code: "PAVD",
    iata_country_code: "US",
    iata_code: "VDZ",
    city_name: "Valdez",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Val-d'Or Airport",
    longitude: -77.782987,
    latitude: 48.053393,
    id: "arp_yvo_ca",
    icao_code: "CYVO",
    iata_country_code: "CA",
    iata_code: "YVO",
    city_name: "Val-d'Or",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Valdosta Regional Airport",
    longitude: -83.277184,
    latitude: 30.784816,
    id: "arp_vld_us",
    icao_code: "KVLD",
    iata_country_code: "US",
    iata_code: "VLD",
    city_name: "Valdosta",
    city: {
      name: "Valdosta",
      id: "cit_vld_us",
      iata_country_code: "US",
      iata_code: "VLD",
    },
  },
  {
    time_zone: "America/Bahia",
    name: "Valença Airport",
    longitude: -38.991991,
    latitude: -13.298758,
    id: "arp_val_br",
    icao_code: "SNVB",
    iata_country_code: "BR",
    iata_code: "VAL",
    city_name: "Valença",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Valence-Chabeuil Airport",
    longitude: 4.96886,
    latitude: 44.919856,
    id: "arp_vaf_fr",
    icao_code: "LFLU",
    iata_country_code: "FR",
    iata_code: "VAF",
    city_name: "Valence",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Valencia Airport",
    longitude: -0.479825,
    latitude: 39.489266,
    id: "arp_vlc_es",
    icao_code: "LEVC",
    iata_country_code: "ES",
    iata_code: "VLC",
    city_name: "Valencia",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Valesdir Airport",
    longitude: 168.177143,
    latitude: -16.796296,
    id: "arp_vls_vu",
    icao_code: "NVSV",
    iata_country_code: "VU",
    iata_code: "VLS",
    city_name: "Valesdir",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Valladolid Airport",
    longitude: -4.849624,
    latitude: 41.706109,
    id: "arp_vll_es",
    icao_code: "LEVD",
    iata_country_code: "ES",
    iata_code: "VLL",
    city_name: "Valladolid",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Valle Airport",
    longitude: -112.144365,
    latitude: 35.649681,
    id: "arp_vle_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "VLE",
    city_name: "Grand Canyon",
    city: {
      name: "Grand Canyon",
      id: "cit_gcn_us",
      iata_country_code: "US",
      iata_code: "GCN",
    },
  },
  {
    time_zone: "America/Caracas",
    name: "Valle de La Pascua Airport",
    longitude: -65.993336,
    latitude: 9.222141,
    id: "arp_vdp_ve",
    icao_code: "SVVP",
    iata_country_code: "VE",
    iata_code: "VDP",
    city_name: "Valle de La Pascua",
    city: null,
  },
  {
    time_zone: "America/Argentina/San_Luis",
    name: "Valle del Conlara Airport",
    longitude: -65.183331,
    latitude: -32.382287,
    id: "arp_rlo_ar",
    icao_code: "SAOS",
    iata_country_code: "AR",
    iata_code: "RLO",
    city_name: "Merlo",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Vallenar Airport",
    longitude: -70.757158,
    latitude: -28.596508,
    id: "arp_vlr_cl",
    icao_code: "SCLL",
    iata_country_code: "CL",
    iata_code: "VLR",
    city_name: "Vallenar",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Valley International Airport",
    longitude: -97.658296,
    latitude: 26.226095,
    id: "arp_hrl_us",
    icao_code: "KHRL",
    iata_country_code: "US",
    iata_code: "HRL",
    city_name: "Harlingen",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Vance Air Force Base",
    longitude: -97.91146,
    latitude: 36.33977,
    id: "arp_end_us",
    icao_code: "KEND",
    iata_country_code: "US",
    iata_code: "END",
    city_name: "Enid",
    city: {
      name: "Enid",
      id: "cit_wdg_us",
      iata_country_code: "US",
      iata_code: "WDG",
    },
  },
  {
    time_zone: "America/St_Kitts",
    name: "Vance W. Amory International Airport",
    longitude: -62.591108,
    latitude: 17.205472,
    id: "arp_nev_kn",
    icao_code: "TKPN",
    iata_country_code: "KN",
    iata_code: "NEV",
    city_name: "Nevis",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Vancouver Harbour Flight Centre Airport",
    longitude: -123.114235,
    latitude: 49.292149,
    id: "arp_cxh_ca",
    icao_code: "CYHC",
    iata_country_code: "CA",
    iata_code: "CXH",
    city_name: "Vancouver",
    city: {
      name: "Vancouver",
      id: "cit_yvr_ca",
      iata_country_code: "CA",
      iata_code: "YVR",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Vancouver International Airport",
    longitude: -123.182755,
    latitude: 49.195296,
    id: "arp_yvr_ca",
    icao_code: "CYVR",
    iata_country_code: "CA",
    iata_code: "YVR",
    city_name: "Vancouver",
    city: {
      name: "Vancouver",
      id: "cit_yvr_ca",
      iata_country_code: "CA",
      iata_code: "YVR",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Vandalia Municipal Airport",
    longitude: -89.167266,
    latitude: 38.990888,
    id: "arp_vla_us",
    icao_code: "KVLA",
    iata_country_code: "US",
    iata_code: "VLA",
    city_name: "Vandalia",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Vandenberg Air Force Base",
    longitude: -120.573819,
    latitude: 34.735719,
    id: "arp_vbg_us",
    icao_code: "KVBG",
    iata_country_code: "US",
    iata_code: "VBG",
    city_name: "Lompoc",
    city: {
      name: "Lompoc",
      id: "cit_lpc_us",
      iata_country_code: "US",
      iata_code: "LPC",
    },
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Van Don International Airport",
    longitude: 107.420761,
    latitude: 21.114634,
    id: "arp_vdo_vn",
    icao_code: "VVVD",
    iata_country_code: "VN",
    iata_code: "VDO",
    city_name: "Cẩm Phả/Hạ Long",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Van Ferit Melen Airport",
    longitude: 43.331782,
    latitude: 38.467696,
    id: "arp_van_tr",
    icao_code: "LTCI",
    iata_country_code: "TR",
    iata_code: "VAN",
    city_name: "Van",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Vangaindrano Airport",
    longitude: 47.582023,
    latitude: -23.350008,
    id: "arp_vnd_mg",
    icao_code: "FMSU",
    iata_country_code: "MG",
    iata_code: "VND",
    city_name: "Vangaindrano",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Vanimo Airport",
    longitude: 141.302511,
    latitude: -2.692272,
    id: "arp_vai_pg",
    icao_code: "AYVN",
    iata_country_code: "PG",
    iata_code: "VAI",
    city_name: "Vanimo",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Vannes-Meucon Airport",
    longitude: -2.723092,
    latitude: 47.721987,
    id: "arp_vne_fr",
    icao_code: "LFRV",
    iata_country_code: "FR",
    iata_code: "VNE",
    city_name: "Vannes",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Van Nuys Airport",
    longitude: -118.489719,
    latitude: 34.208582,
    id: "arp_vny_us",
    icao_code: "KVNY",
    iata_country_code: "US",
    iata_code: "VNY",
    city_name: "Los Angeles",
    city: {
      name: "Los Angeles",
      id: "cit_lax_us",
      iata_country_code: "US",
      iata_code: "LAX",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Vanrook Station Airport",
    longitude: 141.95103,
    latitude: -16.962667,
    id: "arp_vnr_au",
    icao_code: "YVRS",
    iata_country_code: "AU",
    iata_code: "VNR",
    city_name: "Vanrook Station",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Vanuabalavu Airport",
    longitude: -178.975998,
    latitude: -17.268998,
    id: "arp_vbv_fj",
    icao_code: "NFVB",
    iata_country_code: "FJ",
    iata_code: "VBV",
    city_name: "Vanua Balavu",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Varandey Airport",
    longitude: 58.202271,
    latitude: 68.847376,
    id: "arp_vri_ru",
    icao_code: "ULDW",
    iata_country_code: "RU",
    iata_code: "VRI",
    city_name: "Varandey",
    city: null,
  },
  {
    time_zone: "Europe/Oslo",
    name: "Vardø Airport, Svartnes",
    longitude: 31.043828,
    latitude: 70.355601,
    id: "arp_vaw_no",
    icao_code: "ENSS",
    iata_country_code: "NO",
    iata_code: "VAW",
    city_name: "Vardø",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Varkaus Airport",
    longitude: 27.865938,
    latitude: 62.171904,
    id: "arp_vrk_fi",
    icao_code: "EFVR",
    iata_country_code: "FI",
    iata_code: "VRK",
    city_name: "Varkaus",
    city: null,
  },
  {
    time_zone: "Europe/Sofia",
    name: "Varna Airport",
    longitude: 27.825647,
    latitude: 43.233235,
    id: "arp_var_bg",
    icao_code: "LBWN",
    iata_country_code: "BG",
    iata_code: "VAR",
    city_name: "Varna",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Varrelbusch Airport",
    longitude: 8.041094,
    latitude: 52.908261,
    id: "arp_vac_de",
    icao_code: "EDWU",
    iata_country_code: "DE",
    iata_code: "VAC",
    city_name: "Cloppenburg",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Västervik Airport",
    longitude: 16.523524,
    latitude: 57.779879,
    id: "arp_vvk_se",
    icao_code: "ESSW",
    iata_country_code: "SE",
    iata_code: "VVK",
    city_name: "Västervik",
    city: null,
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Vatomandry Airport",
    longitude: 48.94132,
    latitude: -19.372038,
    id: "arp_vat_mg",
    icao_code: "FMMY",
    iata_country_code: "MG",
    iata_code: "VAT",
    city_name: "Vatomandry",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Vatukoula Airport",
    longitude: 177.835,
    latitude: -17.4993,
    id: "arp_vau_fj",
    icao_code: "NFNV",
    iata_country_code: "FJ",
    iata_code: "VAU",
    city_name: "Vatukoula",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Vatulele Airport",
    longitude: 177.638771,
    latitude: -18.512339,
    id: "arp_vtf_fj",
    icao_code: "NFVL",
    iata_country_code: "FJ",
    iata_code: "VTF",
    city_name: "Vatulele",
    city: null,
  },
  {
    time_zone: "Pacific/Tongatapu",
    name: "Vava'u International Airport",
    longitude: -173.963007,
    latitude: -18.585232,
    id: "arp_vav_to",
    icao_code: "NFTV",
    iata_country_code: "TO",
    iata_code: "VAV",
    city_name: "Vavaʻu",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Växjö Småland Airport",
    longitude: 14.729923,
    latitude: 56.927044,
    id: "arp_vxo_se",
    icao_code: "ESMX",
    iata_country_code: "SE",
    iata_code: "VXO",
    city_name: "Växjö",
    city: null,
  },
  {
    time_zone: "America/Antigua",
    name: "V. C. Bird International Airport",
    longitude: -61.791426,
    latitude: 17.138537,
    id: "arp_anu_ag",
    icao_code: "TAPA",
    iata_country_code: "AG",
    iata_code: "ANU",
    city_name: "Antigua",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Veer Savarkar International Airport",
    longitude: 92.731558,
    latitude: 11.645363,
    id: "arp_ixz_in",
    icao_code: "VOPB",
    iata_country_code: "IN",
    iata_code: "IXZ",
    city_name: "Port Blair",
    city: null,
  },
  {
    time_zone: "Indian/Maldives",
    name: "Velana International Airport",
    longitude: 73.528839,
    latitude: 4.190307,
    id: "arp_mle_mv",
    icao_code: "VRMM",
    iata_country_code: "MV",
    iata_code: "MLE",
    city_name: "Malé",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Velasquez Airport",
    longitude: -74.456623,
    latitude: 5.938494,
    id: "arp_pya_co",
    icao_code: "SKVL",
    iata_country_code: "CO",
    iata_code: "PYA",
    city_name: "Puerto Boyacá",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Velikiye Luki Airport",
    longitude: 30.603356,
    latitude: 56.380665,
    id: "arp_vlu_ru",
    icao_code: "ULOL",
    iata_country_code: "RU",
    iata_code: "VLU",
    city_name: "Velikiye Luki",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Velikiy Ustyug Airport",
    longitude: 46.260005,
    latitude: 60.788326,
    id: "arp_vus_ru",
    icao_code: "ULWU",
    iata_country_code: "RU",
    iata_code: "VUS",
    city_name: "Veliky Ustyug",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Vélizy – Villacoublay Air Base Airport",
    longitude: 2.191667,
    latitude: 48.774167,
    id: "arp_viy_fr",
    icao_code: "LFPV",
    iata_country_code: "FR",
    iata_code: "VIY",
    city_name: "Paris",
    city: {
      name: "Paris",
      id: "cit_par_fr",
      iata_country_code: "FR",
      iata_code: "PAR",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Venango Regional Airport",
    longitude: -79.860295,
    latitude: 41.377795,
    id: "arp_fkl_us",
    icao_code: "KFKL",
    iata_country_code: "US",
    iata_code: "FKL",
    city_name: "Franklin",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Venetie Airport",
    longitude: -146.368915,
    latitude: 67.008263,
    id: "arp_vee_us",
    icao_code: "PAVE",
    iata_country_code: "US",
    iata_code: "VEE",
    city_name: "Venetie",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Venice Marco Polo Airport",
    longitude: 12.347639,
    latitude: 45.50502,
    id: "arp_vce_it",
    icao_code: "LIPZ",
    iata_country_code: "IT",
    iata_code: "VCE",
    city_name: "Venice",
    city: {
      name: "Venice",
      id: "cit_vce_it",
      iata_country_code: "IT",
      iata_code: "VCE",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Venice Municipal Airport",
    longitude: -82.440443,
    latitude: 27.072405,
    id: "arp_vnc_us",
    icao_code: "KVNC",
    iata_country_code: "US",
    iata_code: "VNC",
    city_name: "Venice",
    city: null,
  },
  {
    time_zone: "Europe/Riga",
    name: "Ventspils International Airport",
    longitude: 21.545613,
    latitude: 57.359679,
    id: "arp_vnt_lv",
    icao_code: "EVVA",
    iata_country_code: "LV",
    iata_code: "VNT",
    city_name: "Ventspils",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Veracruz International Airport",
    longitude: -96.186851,
    latitude: 19.145356,
    id: "arp_ver_mx",
    icao_code: "MMVR",
    iata_country_code: "MX",
    iata_code: "VER",
    city_name: "Veracruz",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Verkhnevilyuysk Airport",
    longitude: 120.272739,
    latitude: 63.457984,
    id: "arp_vhv_ru",
    icao_code: "UENI",
    iata_country_code: "RU",
    iata_code: "VHV",
    city_name: "Verkhnevilyuysk",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Vermilion Airport",
    longitude: -110.825649,
    latitude: 53.356025,
    id: "arp_yvg_ca",
    icao_code: "CYVG",
    iata_country_code: "CA",
    iata_code: "YVG",
    city_name: "Vermilion",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Vermilion Regional Airport",
    longitude: -87.5978,
    latitude: 40.199355,
    id: "arp_dnv_us",
    icao_code: "KDNV",
    iata_country_code: "US",
    iata_code: "DNV",
    city_name: "Danville",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Vernal Regional Airport",
    longitude: -109.511185,
    latitude: 40.44168,
    id: "arp_vel_us",
    icao_code: "KVEL",
    iata_country_code: "US",
    iata_code: "VEL",
    city_name: "Vernal",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Vernon Regional Airport",
    longitude: -119.328453,
    latitude: 50.248239,
    id: "arp_yve_ca",
    icao_code: "CYVK",
    iata_country_code: "CA",
    iata_code: "YVE",
    city_name: "Vernon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Vero Beach Regional Airport",
    longitude: -80.41756,
    latitude: 27.655412,
    id: "arp_vrb_us",
    icao_code: "KVRB",
    iata_country_code: "US",
    iata_code: "VRB",
    city_name: "Vero Beach",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Verona Villafranca Airport",
    longitude: 10.88932,
    latitude: 45.39785,
    id: "arp_vrn_it",
    icao_code: "LIPX",
    iata_country_code: "IT",
    iata_code: "VRN",
    city_name: "Verona",
    city: {
      name: "Verona",
      id: "cit_vrn_it",
      iata_country_code: "IT",
      iata_code: "VRN",
    },
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Vestmannaeyjar Airport",
    longitude: -20.273953,
    latitude: 63.424722,
    id: "arp_vey_is",
    icao_code: "BIVM",
    iata_country_code: "IS",
    iata_code: "VEY",
    city_name: "Vestmannaeyjar",
    city: null,
  },
  {
    time_zone: "America/Argentina/Cordoba",
    name: "Vicecomodoro Ángel de la Paz Aragonés Airport",
    longitude: -64.299139,
    latitude: -27.756481,
    id: "arp_sde_ar",
    icao_code: "SANE",
    iata_country_code: "AR",
    iata_code: "SDE",
    city_name: "Santiago del Estero",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Vicenza Airport",
    longitude: 11.530184,
    latitude: 45.570006,
    id: "arp_vic_it",
    icao_code: "LIPT",
    iata_country_code: "IT",
    iata_code: "VIC",
    city_name: "Vicenza",
    city: null,
  },
  {
    time_zone: "America/Montevideo",
    name: "Vichadero Airport",
    longitude: -54.589491,
    latitude: -31.742552,
    id: "arp_vch_uy",
    icao_code: "SUVO",
    iata_country_code: "UY",
    iata_code: "VCH",
    city_name: "Vichadero",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Vichy-Charmeil Airport",
    longitude: 3.402345,
    latitude: 46.16837,
    id: "arp_vhy_fr",
    icao_code: "LFLV",
    iata_country_code: "FR",
    iata_code: "VHY",
    city_name: "Vichy",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Vicksburg Municipal Airport",
    longitude: -90.927051,
    latitude: 32.239083,
    id: "arp_vks_us",
    icao_code: "KVKS",
    iata_country_code: "US",
    iata_code: "VKS",
    city_name: "Vicksburg",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Victoria Airport",
    longitude: -72.348938,
    latitude: -38.246003,
    id: "arp_zic_cl",
    icao_code: "SCTO",
    iata_country_code: "CL",
    iata_code: "ZIC",
    city_name: "Victoria",
    city: null,
  },
  {
    time_zone: "Africa/Harare",
    name: "Victoria Falls International Airport",
    longitude: 25.839333,
    latitude: -18.095322,
    id: "arp_vfa_zw",
    icao_code: "FVFA",
    iata_country_code: "ZW",
    iata_code: "VFA",
    city_name: "Victoria Falls",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Victoria Inner Harbour Airport",
    longitude: -123.3875,
    latitude: 48.422778,
    id: "arp_ywh_ca",
    icao_code: "CYWH",
    iata_country_code: "CA",
    iata_code: "YWH",
    city_name: "Victoria",
    city: {
      name: "Victoria",
      id: "cit_yyj_ca",
      iata_country_code: "CA",
      iata_code: "YYJ",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "Victoria International Airport",
    longitude: -123.427331,
    latitude: 48.647501,
    id: "arp_yyj_ca",
    icao_code: "CYYJ",
    iata_country_code: "CA",
    iata_code: "YYJ",
    city_name: "Victoria",
    city: {
      name: "Victoria",
      id: "cit_yyj_ca",
      iata_country_code: "CA",
      iata_code: "YYJ",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Victoria Regional Airport",
    longitude: -96.919637,
    latitude: 28.852618,
    id: "arp_vct_us",
    icao_code: "KVCT",
    iata_country_code: "US",
    iata_code: "VCT",
    city_name: "Victoria",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Victoria Resevour Kandy Airport",
    longitude: 80.764861,
    latitude: 7.265366,
    id: "arp_kdw_lk",
    icao_code: null,
    iata_country_code: "LK",
    iata_code: "KDW",
    city_name: "Kandy",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Victoria River Downs Airport",
    longitude: 131.007416,
    latitude: -16.402095,
    id: "arp_vcd_au",
    icao_code: "YVRD",
    iata_country_code: "AU",
    iata_code: "VCD",
    city_name: "Victoria River Downs",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Víctor Lafón Airport",
    longitude: -70.705318,
    latitude: -32.744812,
    id: "arp_ssd_cl",
    icao_code: "SCSF",
    iata_country_code: "CL",
    iata_code: "SSD",
    city_name: "San Felipe",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Vidalia Regional Airport",
    longitude: -82.371195,
    latitude: 32.192652,
    id: "arp_vdi_us",
    icao_code: "KVDI",
    iata_country_code: "US",
    iata_code: "VDI",
    city_name: "Vidalia",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Videira Airport",
    longitude: -51.141875,
    latitude: -27.000271,
    id: "arp_via_br",
    icao_code: "SSVI",
    iata_country_code: "BR",
    iata_code: "VIA",
    city_name: "Videira",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Vidyanagar Airport",
    longitude: 76.631838,
    latitude: 15.176692,
    id: "arp_vdy_in",
    icao_code: "VOJV",
    iata_country_code: "IN",
    iata_code: "VDY",
    city_name: "Toranagallu",
    city: null,
  },
  {
    time_zone: "Europe/Vienna",
    name: "Vienna International Airport",
    longitude: 16.568137,
    latitude: 48.113065,
    id: "arp_vie_at",
    icao_code: "LOWW",
    iata_country_code: "AT",
    iata_code: "VIE",
    city_name: "Vienna",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Vigo Airport",
    longitude: -8.627478,
    latitude: 42.230102,
    id: "arp_vgo_es",
    icao_code: "LEVX",
    iata_country_code: "ES",
    iata_code: "VGO",
    city_name: "Vigo",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Vijayawada Airport",
    longitude: 80.795172,
    latitude: 16.527957,
    id: "arp_vga_in",
    icao_code: "VOBZ",
    iata_country_code: "IN",
    iata_code: "VGA",
    city_name: "Vijayawada",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Vilankulo Airport",
    longitude: 35.312815,
    latitude: -22.017,
    id: "arp_vnx_mz",
    icao_code: "FQVL",
    iata_country_code: "MZ",
    iata_code: "VNX",
    city_name: "Vilankulos",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Vila Real Airport",
    longitude: -7.720126,
    latitude: 41.274804,
    id: "arp_vrl_pt",
    icao_code: "LPVR",
    iata_country_code: "PT",
    iata_code: "VRL",
    city_name: "Vila Real",
    city: null,
  },
  {
    time_zone: "America/Cuiaba",
    name: "Vila Rica Airport",
    longitude: -51.140271,
    latitude: -9.979681,
    id: "arp_vlp_br",
    icao_code: "SWVC",
    iata_country_code: "BR",
    iata_code: "VLP",
    city_name: "Vila Rica",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Vilhelmina Airport",
    longitude: 16.83552,
    latitude: 64.579036,
    id: "arp_vhm_se",
    icao_code: "ESNV",
    iata_country_code: "SE",
    iata_code: "VHM",
    city_name: "Vilhelmina",
    city: null,
  },
  {
    time_zone: "America/Porto_Velho",
    name: "Vilhena Airport",
    longitude: -60.099394,
    latitude: -12.692089,
    id: "arp_bvh_br",
    icao_code: "SBVH",
    iata_country_code: "BR",
    iata_code: "BVH",
    city_name: "Vilhena",
    city: null,
  },
  {
    time_zone: "America/Mazatlan",
    name: "Villa Constitución Airport",
    longitude: -111.614711,
    latitude: 25.053193,
    id: "arp_vib_mx",
    icao_code: null,
    iata_country_code: "MX",
    iata_code: "VIB",
    city_name: "Ciudad Constitución",
    city: null,
  },
  {
    time_zone: "America/Cordoba",
    name: "Villa Dolores Airport",
    longitude: -65.147518,
    latitude: -31.946798,
    id: "arp_vdr_ar",
    icao_code: "SAOD",
    iata_country_code: "AR",
    iata_code: "VDR",
    city_name: "Villa Dolores",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Villa Garzón Airport",
    longitude: -76.605027,
    latitude: 0.980062,
    id: "arp_vgz_co",
    icao_code: "SKVG",
    iata_country_code: "CO",
    iata_code: "VGZ",
    city_name: "Villa Garzón",
    city: null,
  },
  {
    time_zone: "America/Buenos_Aires",
    name: "Villa Gesell Airport",
    longitude: -57.025108,
    latitude: -37.234952,
    id: "arp_vlg_ar",
    icao_code: "SAZV",
    iata_country_code: "AR",
    iata_code: "VLG",
    city_name: "Villa Gesell",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Villahermosa International Airport",
    longitude: -92.817568,
    latitude: 17.995642,
    id: "arp_vsa_mx",
    icao_code: "MMVA",
    iata_country_code: "MX",
    iata_code: "VSA",
    city_name: "Villahermosa",
    city: null,
  },
  {
    time_zone: "America/Argentina/San_Luis",
    name: "Villa Reynolds Airport",
    longitude: -65.387397,
    latitude: -33.7299,
    id: "arp_vme_ar",
    icao_code: "SAOR",
    iata_country_code: "AR",
    iata_code: "VME",
    city_name: "Villa Mercedes",
    city: null,
  },
  {
    time_zone: "Europe/Vilnius",
    name: "Vilnius International Airport",
    longitude: 25.286179,
    latitude: 54.636069,
    id: "arp_vno_lt",
    icao_code: "EYVI",
    iata_country_code: "LT",
    iata_code: "VNO",
    city_name: "Vilnius",
    city: null,
  },
  {
    time_zone: "America/Havana",
    name: "Vilo Acuña International Airport",
    longitude: -81.553814,
    latitude: 21.623106,
    id: "arp_cyo_cu",
    icao_code: "MUCL",
    iata_country_code: "CU",
    iata_code: "CYO",
    city_name: "Cayo Largo del Sur",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Vilyuysk Airport",
    longitude: 121.69315,
    latitude: 63.756505,
    id: "arp_vyi_ru",
    icao_code: "UENW",
    iata_country_code: "RU",
    iata_code: "VYI",
    city_name: "Vilyuysk",
    city: null,
  },
  {
    time_zone: "America/Santiago",
    name: "Viña del Mar Airport",
    longitude: -71.479974,
    latitude: -32.949143,
    id: "arp_kna_cl",
    icao_code: "SCVM",
    iata_country_code: "CL",
    iata_code: "KNA",
    city_name: "Vina del Mar",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Vincent Fayks Airport",
    longitude: -55.442501,
    latitude: 3.345279,
    id: "arp_oem_sr",
    icao_code: "SMPA",
    iata_country_code: "SR",
    iata_code: "OEM",
    city_name: "Paloemeu",
    city: null,
  },
  {
    time_zone: "Europe/Rome",
    name: "Vincenzo Florio Airport Trapani-Birgi",
    longitude: 12.489507,
    latitude: 37.910772,
    id: "arp_tps_it",
    icao_code: "LICT",
    iata_country_code: "IT",
    iata_code: "TPS",
    city_name: "Trapani",
    city: null,
  },
  {
    time_zone: "Asia/Bangkok",
    name: "Vinh Airport",
    longitude: 105.669444,
    latitude: 18.728299,
    id: "arp_vii_vn",
    icao_code: "VVVH",
    iata_country_code: "VN",
    iata_code: "VII",
    city_name: "Vinh",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Vinh Long Airport",
    longitude: 105.948741,
    latitude: 10.251819,
    id: "arp_xvl_vn",
    icao_code: null,
    iata_country_code: "VN",
    iata_code: "XVL",
    city_name: "Vinh Long",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Vinnytsia International Airport",
    longitude: 28.609838,
    latitude: 49.24229,
    id: "arp_vin_ua",
    icao_code: "UKWW",
    iata_country_code: "UA",
    iata_code: "VIN",
    city_name: "Vinnytsia",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Vipingo Airport",
    longitude: 39.797263,
    latitude: -3.812483,
    id: "arp_vpg_ke",
    icao_code: null,
    iata_country_code: "KE",
    iata_code: "VPG",
    city_name: "Vipingo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Viqueque Airport",
    longitude: -90.512222,
    latitude: 44.558056,
    id: "arp_viq_tl",
    icao_code: "WPVQ",
    iata_country_code: "TL",
    iata_code: "VIQ",
    city_name: "Viqueque",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Virac Airport",
    longitude: 124.208604,
    latitude: 13.578056,
    id: "arp_vrc_ph",
    icao_code: "RPUV",
    iata_country_code: "PH",
    iata_code: "VRC",
    city_name: "Virac",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Viracopos International Airport",
    longitude: -47.136033,
    latitude: -23.007802,
    id: "arp_vcp_br",
    icao_code: "SBKP",
    iata_country_code: "BR",
    iata_code: "VCP",
    city_name: "Campinas",
    city: {
      name: "Sao Paulo",
      id: "cit_sao_br",
      iata_country_code: "BR",
      iata_code: "SAO",
    },
  },
  {
    time_zone: "America/Indiana/Indianapolis",
    name: "Virgil I. Grissom Municipal Airport",
    longitude: -86.439534,
    latitude: 38.838903,
    id: "arp_bfr_us",
    icao_code: "KBFR",
    iata_country_code: "US",
    iata_code: "BFR",
    city_name: "Bedford",
    city: null,
  },
  {
    time_zone: "America/Tortola",
    name: "Virgin Gorda Airport",
    longitude: -64.427806,
    latitude: 18.445929,
    id: "arp_vij_vg",
    icao_code: "TUPW",
    iata_country_code: "VG",
    iata_code: "VIJ",
    city_name: "Virgin Gorda",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Virginia Airport",
    longitude: 31.058033,
    latitude: -29.770871,
    id: "arp_vir_za",
    icao_code: "FAVG",
    iata_country_code: "ZA",
    iata_code: "VIR",
    city_name: "Durban",
    city: {
      name: "Durban",
      id: "cit_dur_za",
      iata_country_code: "ZA",
      iata_code: "DUR",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Virginia Highlands Airport",
    longitude: -82.033987,
    latitude: 36.686231,
    id: "arp_vji_us",
    icao_code: "KVJI",
    iata_country_code: "US",
    iata_code: "VJI",
    city_name: "Abingdon",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Virginia Tech Montgomery Executive Airport",
    longitude: -80.407955,
    latitude: 37.207079,
    id: "arp_bcb_us",
    icao_code: "KBCB",
    iata_country_code: "US",
    iata_code: "BCB",
    city_name: "Blacksburg",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Viru Harbour Airstrip",
    longitude: 157.682999,
    latitude: -8.5,
    id: "arp_viu_sb",
    icao_code: null,
    iata_country_code: "SB",
    iata_code: "VIU",
    city_name: "Viru Harbour",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Viru Viru International Airport",
    longitude: -63.13801,
    latitude: -17.645238,
    id: "arp_vvi_bo",
    icao_code: "SLVR",
    iata_country_code: "BO",
    iata_code: "VVI",
    city_name: "Santa Cruz",
    city: {
      name: "Santa Cruz",
      id: "cit_srz_bo",
      iata_country_code: "BO",
      iata_code: "SRZ",
    },
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Visakhapatnam Airport",
    longitude: 83.224068,
    latitude: 17.724632,
    id: "arp_vtz_in",
    icao_code: "VOVZ",
    iata_country_code: "IN",
    iata_code: "VTZ",
    city_name: "Visakhapatnam",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Visalia Municipal Airport",
    longitude: -119.392924,
    latitude: 36.318669,
    id: "arp_vis_us",
    icao_code: "KVIS",
    iata_country_code: "US",
    iata_code: "VIS",
    city_name: "Visalia",
    city: null,
  },
  {
    time_zone: "Europe/Stockholm",
    name: "Visby Airport",
    longitude: 18.344675,
    latitude: 57.661882,
    id: "arp_vby_se",
    icao_code: "ESSV",
    iata_country_code: "SE",
    iata_code: "VBY",
    city_name: "Visby",
    city: null,
  },
  {
    time_zone: "Europe/Lisbon",
    name: "Viseu Airport",
    longitude: -7.889386,
    latitude: 40.724675,
    id: "arp_vse_pt",
    icao_code: "LPVZ",
    iata_country_code: "PT",
    iata_code: "VSE",
    city_name: "Viseu",
    city: null,
  },
  {
    time_zone: "Europe/Minsk",
    name: "Vitebsk Vostochny Airport",
    longitude: 30.352029,
    latitude: 55.127278,
    id: "arp_vtb_by",
    icao_code: "UMII",
    iata_country_code: "BY",
    iata_code: "VTB",
    city_name: "Vitebsk",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Vitoria Airport",
    longitude: -2.727871,
    latitude: 42.883165,
    id: "arp_vit_es",
    icao_code: "LEVT",
    iata_country_code: "ES",
    iata_code: "VIT",
    city_name: "Vitoria",
    city: null,
  },
  {
    time_zone: "America/Bahia",
    name: "Vitória da Conquista Airport",
    longitude: -40.863078,
    latitude: -14.864521,
    id: "arp_vdc_br",
    icao_code: "SBQV",
    iata_country_code: "BR",
    iata_code: "VDC",
    city_name: "Vitória da Conquista",
    city: null,
  },
  {
    time_zone: "Europe/Paris",
    name: "Vittel Champ De Course Airport",
    longitude: 5.93528,
    latitude: 48.2239,
    id: "arp_vtl_fr",
    icao_code: "LFSZ",
    iata_country_code: "FR",
    iata_code: "VTL",
    city_name: "Vittel",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Viveros Island Airport",
    longitude: -78.935008,
    latitude: 8.455345,
    id: "arp_ivi_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "IVI",
    city_name: "Viveros Island",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Vivigani Airfield",
    longitude: 150.32059,
    latitude: -9.305722,
    id: "arp_viv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "VIV",
    city_name: "Vivigani",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Vladivostok International Airport",
    longitude: 132.147868,
    latitude: 43.396925,
    id: "arp_vvo_ru",
    icao_code: "UHWW",
    iata_country_code: "RU",
    iata_code: "VVO",
    city_name: "Vladivostok",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Vnukovo International Airport",
    longitude: 37.273487,
    latitude: 55.601406,
    id: "arp_vko_ru",
    icao_code: "UUWW",
    iata_country_code: "RU",
    iata_code: "VKO",
    city_name: "Moscow",
    city: {
      name: "Moscow",
      id: "cit_mow_ru",
      iata_country_code: "RU",
      iata_code: "MOW",
    },
  },
  {
    time_zone: "Europe/Prague",
    name: "Vodochody Airport",
    longitude: 14.395841,
    latitude: 50.216601,
    id: "arp_vod_cz",
    icao_code: "LKVO",
    iata_country_code: "CZ",
    iata_code: "VOD",
    city_name: "Prague",
    city: {
      name: "Prague",
      id: "cit_prg_cz",
      iata_country_code: "CZ",
      iata_code: "PRG",
    },
  },
  {
    time_zone: "Indian/Antananarivo",
    name: "Vohimarina Airport",
    longitude: 50.002788,
    latitude: -13.375817,
    id: "arp_voh_mg",
    icao_code: "FMNV",
    iata_country_code: "MG",
    iata_code: "VOH",
    city_name: "Vohimarina",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Voinjama Airport",
    longitude: -9.768233,
    latitude: 8.326719,
    id: "arp_voi_lr",
    icao_code: "GLVA",
    iata_country_code: "LR",
    iata_code: "VOI",
    city_name: "Voinjama",
    city: null,
  },
  {
    time_zone: "Europe/Copenhagen",
    name: "Vojens Airport",
    longitude: 9.266439,
    latitude: 55.220513,
    id: "arp_sks_dk",
    icao_code: "EKSP",
    iata_country_code: "DK",
    iata_code: "SKS",
    city_name: "Vojens",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Volgodonsk Airport",
    longitude: 42.0728,
    latitude: 47.682098,
    id: "arp_vlk_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "VLK",
    city_name: "Volgodonsk",
    city: null,
  },
  {
    time_zone: "Europe/Volgograd",
    name: "Volgograd International Airport",
    longitude: 44.347835,
    latitude: 48.785688,
    id: "arp_vog_ru",
    icao_code: "URWW",
    iata_country_code: "RU",
    iata_code: "VOG",
    city_name: "Volgograd",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Volkel Air Base",
    longitude: 5.705331,
    latitude: 51.659566,
    id: "arp_ude_nl",
    icao_code: "EHVK",
    iata_country_code: "NL",
    iata_code: "UDE",
    city_name: "Uden",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Volk Field",
    longitude: -90.254705,
    latitude: 43.938873,
    id: "arp_vok_us",
    icao_code: "KVOK",
    iata_country_code: "US",
    iata_code: "VOK",
    city_name: "Camp Douglas",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Vologda Airport",
    longitude: 39.941065,
    latitude: 59.283544,
    id: "arp_vgd_ru",
    icao_code: "ULWW",
    iata_country_code: "RU",
    iata_code: "VGD",
    city_name: "Vologda",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Vopnafjörður Airport",
    longitude: -14.850585,
    latitude: 65.720582,
    id: "arp_vpn_is",
    icao_code: "BIVO",
    iata_country_code: "IS",
    iata_code: "VPN",
    city_name: "Vopnafjörður",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Vorkuta Airport",
    longitude: 63.996485,
    latitude: 67.488452,
    id: "arp_vkt_ru",
    icao_code: "UUYW",
    iata_country_code: "RU",
    iata_code: "VKT",
    city_name: "Vorkuta",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Voronezh International Airport",
    longitude: 39.227168,
    latitude: 51.813823,
    id: "arp_voz_ru",
    icao_code: "UUOO",
    iata_country_code: "RU",
    iata_code: "VOZ",
    city_name: "Voronezh",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Votuporanga Airport",
    longitude: -50.002798,
    latitude: -20.459085,
    id: "arp_vot_br",
    icao_code: "SDVG",
    iata_country_code: "BR",
    iata_code: "VOT",
    city_name: "Votuporanga",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Vredendal Airport",
    longitude: 18.489928,
    latitude: -31.684209,
    id: "arp_vre_za",
    icao_code: "FAVR",
    iata_country_code: "ZA",
    iata_code: "VRE",
    city_name: "Vredendal",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Vryburg Airport",
    longitude: 24.72916,
    latitude: -26.981821,
    id: "arp_vru_za",
    icao_code: "FAVB",
    iata_country_code: "ZA",
    iata_code: "VRU",
    city_name: "Vryburg",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Vryheid Airport",
    longitude: 30.793612,
    latitude: -27.786625,
    id: "arp_vyd_za",
    icao_code: "FAVY",
    iata_country_code: "ZA",
    iata_code: "VYD",
    city_name: "Vryheid",
    city: null,
  },
  {
    time_zone: "Asia/Ho_Chi_Minh",
    name: "Vung Tau Airport",
    longitude: 107.092132,
    latitude: 10.372878,
    id: "arp_vtg_vn",
    icao_code: "VVVT",
    iata_country_code: "VN",
    iata_code: "VTG",
    city_name: "Vung Tau",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Vunisea Airport",
    longitude: 178.156703,
    latitude: -19.056913,
    id: "arp_kdv_fj",
    icao_code: "NFKD",
    iata_country_code: "FJ",
    iata_code: "KDV",
    city_name: "Vunisea",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wabag Airport",
    longitude: 143.667,
    latitude: -5.48333,
    id: "arp_wab_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WAB",
    city_name: "Wabag",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wabo Airport",
    longitude: 145.074972,
    latitude: -6.98954,
    id: "arp_wao_pg",
    icao_code: "AYWB",
    iata_country_code: "PG",
    iata_code: "WAO",
    city_name: "Wabo",
    city: null,
  },
  {
    time_zone: "America/Goose_Bay",
    name: "Wabush Airport",
    longitude: -66.866648,
    latitude: 52.922971,
    id: "arp_ywk_ca",
    icao_code: "CYWK",
    iata_country_code: "CA",
    iata_code: "YWK",
    city_name: "Wabush",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Waca Airport",
    longitude: 37.167011,
    latitude: 7.166702,
    id: "arp_wac_et",
    icao_code: "HAWC",
    iata_country_code: "ET",
    iata_code: "WAC",
    city_name: "Waca",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Waco Kungo Airport",
    longitude: 15.101395,
    latitude: -11.426395,
    id: "arp_ceo_ao",
    icao_code: "FNWK",
    iata_country_code: "AO",
    iata_code: "CEO",
    city_name: "Waku-Kungo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Waco Regional Airport",
    longitude: -97.229033,
    latitude: 31.612359,
    id: "arp_act_us",
    icao_code: "KACT",
    iata_country_code: "US",
    iata_code: "ACT",
    city_name: "Waco",
    city: {
      name: "Waco",
      id: "cit_act_us",
      iata_country_code: "US",
      iata_code: "ACT",
    },
  },
  {
    time_zone: "Asia/Aden",
    name: "Wadi Ain Airport",
    longitude: 45.551137,
    latitude: 14.865279,
    id: "arp_wda_ye",
    icao_code: null,
    iata_country_code: "YE",
    iata_code: "WDA",
    city_name: "Wadi Ain",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Wadi al-Dawasir Domestic Airport",
    longitude: 45.199265,
    latitude: 20.503159,
    id: "arp_wae_sa",
    icao_code: "OEWD",
    iata_country_code: "SA",
    iata_code: "WAE",
    city_name: "Wadi Al Dawasir",
    city: null,
  },
  {
    time_zone: "Africa/Cairo",
    name: "Wadi al Jandali Airport",
    longitude: 31.839132,
    latitude: 30.049295,
    id: "arp_tfr_eg",
    icao_code: null,
    iata_country_code: "EG",
    iata_code: "TFR",
    city_name: "Ramadan City",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Wadi Halfa Airport",
    longitude: 31.521577,
    latitude: 21.802698,
    id: "arp_whf_sd",
    icao_code: "HSSW",
    iata_country_code: "SD",
    iata_code: "WHF",
    city_name: "Wadi Halfa",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Wad Medani Airport",
    longitude: 33.545723,
    latitude: 14.295783,
    id: "arp_dni_sd",
    icao_code: "HSWD",
    iata_country_code: "SD",
    iata_code: "DNI",
    city_name: "Wad Madani",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wagau Airport",
    longitude: 146.8,
    latitude: -6.8,
    id: "arp_wgu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WGU",
    city_name: "Wagau",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Wageningen Airstrip",
    longitude: -56.671692,
    latitude: 5.841976,
    id: "arp_agi_sr",
    icao_code: "SMWA",
    iata_country_code: "SR",
    iata_code: "AGI",
    city_name: "Wageningen",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Wagga Wagga Airport",
    longitude: 147.463989,
    latitude: -35.162202,
    id: "arp_wga_au",
    icao_code: "YSWG",
    iata_country_code: "AU",
    iata_code: "WGA",
    city_name: "Wagga Wagga",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Waghete Airport",
    longitude: 136.281573,
    latitude: -4.058383,
    id: "arp_wet_id",
    icao_code: "WABG",
    iata_country_code: "ID",
    iata_code: "WET",
    city_name: "Waghete",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Wagny Airport",
    longitude: 10.433333,
    latitude: -0.516667,
    id: "arp_wgy_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "WGY",
    city_name: "Wagny",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Wahai Airport",
    longitude: 129.48469,
    latitude: -2.810883,
    id: "arp_wba_id",
    icao_code: "WAPV",
    iata_country_code: "ID",
    iata_code: "WBA",
    city_name: "Seram Island",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Waiheke Island Airport",
    longitude: 175.085914,
    latitude: -36.808731,
    id: "arp_wik_nz",
    icao_code: "NZKE",
    iata_country_code: "NZ",
    iata_code: "WIK",
    city_name: "Waiheke Island",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Waimea-Kohala Airport",
    longitude: -155.671248,
    latitude: 19.999887,
    id: "arp_mue_us",
    icao_code: "PHMU",
    iata_country_code: "US",
    iata_code: "MUE",
    city_name: "Kamuela",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Wainwright Airport",
    longitude: -160.013119,
    latitude: 70.637606,
    id: "arp_ain_us",
    icao_code: "PAWI",
    iata_country_code: "US",
    iata_code: "AIN",
    city_name: "Wainwright",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Wairoa Airport",
    longitude: 177.406997,
    latitude: -39.0069,
    id: "arp_wir_nz",
    icao_code: "NZWO",
    iata_country_code: "NZ",
    iata_code: "WIR",
    city_name: "Wairoa",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Wajir Airport",
    longitude: 40.091675,
    latitude: 1.733273,
    id: "arp_wjr_ke",
    icao_code: "HKWJ",
    iata_country_code: "KE",
    iata_code: "WJR",
    city_name: "Wajir",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Wakaya Island Airport",
    longitude: 179.010352,
    latitude: -17.629263,
    id: "arp_kay_fj",
    icao_code: "NFNW",
    iata_country_code: "FJ",
    iata_code: "KAY",
    city_name: "Wakaya Island",
    city: null,
  },
  {
    time_zone: "Pacific/Wake",
    name: "Wake Island Airfield",
    longitude: 166.642789,
    latitude: 19.281327,
    id: "arp_awk_um",
    icao_code: "PWAK",
    iata_country_code: "UM",
    iata_code: "AWK",
    city_name: "Wake Island",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Wakkanai Airport",
    longitude: 141.80006,
    latitude: 45.40301,
    id: "arp_wkj_jp",
    icao_code: "RJCW",
    iata_country_code: "JP",
    iata_code: "WKJ",
    city_name: "Wakkanai",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wakunai Airport",
    longitude: 155.233,
    latitude: -5.86667,
    id: "arp_wkn_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WKN",
    city_name: "Wakunai",
    city: null,
  },
  {
    time_zone: "Pacific/Efate",
    name: "Walaha Airport",
    longitude: 167.690994,
    latitude: -15.411999,
    id: "arp_wlh_vu",
    icao_code: "NVSW",
    iata_country_code: "VU",
    iata_code: "WLH",
    city_name: "Walaha",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Walcha Airport",
    longitude: 151.548215,
    latitude: -31.009214,
    id: "arp_wlc_au",
    icao_code: "YWCH",
    iata_country_code: "AU",
    iata_code: "WLC",
    city_name: "Walcha",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Waldronaire Airport",
    longitude: -123.017998,
    latitude: 48.7118,
    id: "arp_wdn_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "WDN",
    city_name: "Waldron Island",
    city: null,
  },
  {
    time_zone: "America/Nome",
    name: "Wales Airport",
    longitude: -168.095198,
    latitude: 65.622908,
    id: "arp_waa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WAA",
    city_name: "Wales",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Walgett Airport",
    longitude: 148.124513,
    latitude: -30.031742,
    id: "arp_wge_au",
    icao_code: "YWLG",
    iata_country_code: "AU",
    iata_code: "WGE",
    city_name: "Walgett",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "Walker's Cay Airport",
    longitude: -78.402179,
    latitude: 27.259892,
    id: "arp_wkr_bs",
    icao_code: "MYAW",
    iata_country_code: "BS",
    iata_code: "WKR",
    city_name: "Walker's Cay",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Wallal Airport",
    longitude: 120.649506,
    latitude: -19.774171,
    id: "arp_wla_au",
    icao_code: "YWAL",
    iata_country_code: "AU",
    iata_code: "WLA",
    city_name: "Wallal Downs",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Walla Walla Regional Airport",
    longitude: -118.283788,
    latitude: 46.093882,
    id: "arp_alw_us",
    icao_code: "KALW",
    iata_country_code: "US",
    iata_code: "ALW",
    city_name: "Walla Walla",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wallops Flight Facility Airport",
    longitude: -75.467561,
    latitude: 37.939034,
    id: "arp_wal_us",
    icao_code: "KWAL",
    iata_country_code: "US",
    iata_code: "WAL",
    city_name: "Wallops Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Walnut Ridge Regional Airport",
    longitude: -90.924851,
    latitude: 36.125074,
    id: "arp_arg_us",
    icao_code: "KARG",
    iata_country_code: "US",
    iata_code: "ARG",
    city_name: "Walnut Ridge",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Walt Disney World Airport",
    longitude: -81.570976,
    latitude: 28.398411,
    id: "arp_dws_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "DWS",
    city_name: "Hulunbuir",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Walter J. Koladza Airport",
    longitude: -73.403534,
    latitude: 42.184231,
    id: "arp_gbr_us",
    icao_code: "KGBR",
    iata_country_code: "US",
    iata_code: "GBR",
    city_name: "Great Barrington",
    city: null,
  },
  {
    time_zone: "Africa/Windhoek",
    name: "Walvis Bay Airport",
    longitude: 14.645289,
    latitude: -22.97981,
    id: "arp_wvb_na",
    icao_code: "FYWB",
    iata_country_code: "NA",
    iata_code: "WVB",
    city_name: "Walvis Bay",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Wamena Airport",
    longitude: 138.953808,
    latitude: -4.099905,
    id: "arp_wmx_id",
    icao_code: "WAJW",
    iata_country_code: "ID",
    iata_code: "WMX",
    city_name: "Wamena",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Wana Airport",
    longitude: 69.570038,
    latitude: 32.305028,
    id: "arp_waf_pk",
    icao_code: "OPWN",
    iata_country_code: "PK",
    iata_code: "WAF",
    city_name: "Wana",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Wanaka Airport",
    longitude: 169.244925,
    latitude: -44.722676,
    id: "arp_wka_nz",
    icao_code: "NZWF",
    iata_country_code: "NZ",
    iata_code: "WKA",
    city_name: "Wanaka",
    city: null,
  },
  {
    time_zone: "Asia/Taipei",
    name: "Wang-an Airport",
    longitude: 119.502655,
    latitude: 23.367359,
    id: "arp_wot_tw",
    icao_code: "RCWA",
    iata_country_code: "TW",
    iata_code: "WOT",
    city_name: "Wang'an",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Wanganui Airport",
    longitude: 175.023715,
    latitude: -39.962152,
    id: "arp_wag_nz",
    icao_code: "NZWU",
    iata_country_code: "NZ",
    iata_code: "WAG",
    city_name: "Wanganui",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Wangaratta Airport",
    longitude: 146.306975,
    latitude: -36.415815,
    id: "arp_wgt_au",
    icao_code: "YWGT",
    iata_country_code: "AU",
    iata_code: "WGT",
    city_name: "Wangaratta",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Wangerooge Airport",
    longitude: 7.913822,
    latitude: 53.783982,
    id: "arp_age_de",
    icao_code: "EDWG",
    iata_country_code: "DE",
    iata_code: "AGE",
    city_name: "Wangerooge",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wanigela Airport",
    longitude: 149.157337,
    latitude: -9.337161,
    id: "arp_agl_pg",
    icao_code: "AYWG",
    iata_country_code: "PG",
    iata_code: "AGL",
    city_name: "Wanigela",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wantoat Airport",
    longitude: 146.468143,
    latitude: -6.132671,
    id: "arp_wtt_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WTT",
    city_name: "Wantoat",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wanuma Airport",
    longitude: 145.321181,
    latitude: -4.896236,
    id: "arp_wnu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WNU",
    city_name: "Wanuma",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wanzhou Wuqiao Airport",
    longitude: 108.432272,
    latitude: 30.799653,
    id: "arp_wxn_cn",
    icao_code: "ZUWX",
    iata_country_code: "CN",
    iata_code: "WXN",
    city_name: "Wanzhou",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Wapekeka Airport",
    longitude: -89.57293,
    latitude: 53.845489,
    id: "arp_yax_ca",
    icao_code: "CKB6",
    iata_country_code: "CA",
    iata_code: "YAX",
    city_name: "Angling Lake",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wapenamanda Airport",
    longitude: 143.891609,
    latitude: -5.633844,
    id: "arp_wbm_pg",
    icao_code: "AYWD",
    iata_country_code: "PG",
    iata_code: "WBM",
    city_name: "Wapenamanda",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wapolu Airport",
    longitude: 150.509515,
    latitude: -9.337759,
    id: "arp_wbc_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WBC",
    city_name: "Wapolu",
    city: null,
  },
  {
    time_zone: "Asia/Kolkata",
    name: "Warangal Airport",
    longitude: 79.60117,
    latitude: 17.91549,
    id: "arp_wgc_in",
    icao_code: "VOWA",
    iata_country_code: "IN",
    iata_code: "WGC",
    city_name: "Warangal",
    city: null,
  },
  {
    time_zone: "Africa/Addis_Ababa",
    name: "Warder Airport",
    longitude: 45.331197,
    latitude: 6.969961,
    id: "arp_wra_et",
    icao_code: "HAWR",
    iata_country_code: "ET",
    iata_code: "WRA",
    city_name: "Warder",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Ware Airport",
    longitude: -72.215033,
    latitude: 42.281734,
    id: "arp_uwa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UWA",
    city_name: "Ware",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Waris Airport",
    longitude: 140.994,
    latitude: -3.235,
    id: "arp_war_id",
    icao_code: "WAJR",
    iata_country_code: "ID",
    iata_code: "WAR",
    city_name: "Waris",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Warm Spring Bay Seaplane Base Airport",
    longitude: -134.833144,
    latitude: 57.088813,
    id: "arp_bnf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "BNF",
    city_name: "Baranof",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Warraber Island Airport",
    longitude: 142.823946,
    latitude: -10.207422,
    id: "arp_syu_au",
    icao_code: "YWBS",
    iata_country_code: "AU",
    iata_code: "SYU",
    city_name: "Sue Islet",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Warracknabeal Airport",
    longitude: 142.419152,
    latitude: -36.321102,
    id: "arp_wkb_au",
    icao_code: "YWKB",
    iata_country_code: "AU",
    iata_code: "WKB",
    city_name: "Warracknabeal",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Warrawagine Airport",
    longitude: 120.702322,
    latitude: -20.843376,
    id: "arp_wrw_au",
    icao_code: "YWWG",
    iata_country_code: "AU",
    iata_code: "WRW",
    city_name: "Warrawagine Station",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Warren County Memorial Airport",
    longitude: -85.843866,
    latitude: 35.698644,
    id: "arp_rnc_us",
    icao_code: "KRNC",
    iata_country_code: "US",
    iata_code: "RNC",
    city_name: "McMinnville",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Warren Field",
    longitude: -77.047992,
    latitude: 35.570447,
    id: "arp_ocw_us",
    icao_code: "KOCW",
    iata_country_code: "US",
    iata_code: "OCW",
    city_name: "Washington",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Warri Airport",
    longitude: 5.818531,
    latitude: 5.595298,
    id: "arp_qrw_ng",
    icao_code: "DNSU",
    iata_country_code: "NG",
    iata_code: "QRW",
    city_name: "Warri",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Warrnambool Airport",
    longitude: 142.448006,
    latitude: -38.296391,
    id: "arp_wmb_au",
    icao_code: "YWBL",
    iata_country_code: "AU",
    iata_code: "WMB",
    city_name: "Warrnambool",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Warroad International Memorial Airport",
    longitude: -95.344143,
    latitude: 48.936011,
    id: "arp_rrt_us",
    icao_code: "KRRT",
    iata_country_code: "US",
    iata_code: "RRT",
    city_name: "Warroad",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Warsaw Chopin Airport",
    longitude: 20.967363,
    latitude: 52.166211,
    id: "arp_waw_pl",
    icao_code: "EPWA",
    iata_country_code: "PL",
    iata_code: "WAW",
    city_name: "Warsaw",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Warsaw Modlin Airport",
    longitude: 20.651519,
    latitude: 52.450182,
    id: "arp_wmi_pl",
    icao_code: "EPMO",
    iata_country_code: "PL",
    iata_code: "WMI",
    city_name: "Warsaw",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Warton Airport",
    longitude: -2.886172,
    latitude: 53.746715,
    id: "arp_wrt_gb",
    icao_code: "EGNO",
    iata_country_code: "GB",
    iata_code: "WRT",
    city_name: "Warton",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "Warukin Airport",
    longitude: 115.437609,
    latitude: -2.215501,
    id: "arp_tjg_id",
    icao_code: "WAON",
    iata_country_code: "ID",
    iata_code: "TJG",
    city_name: "Tanjung Warukin",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Warwick Airport",
    longitude: 151.942734,
    latitude: -28.149373,
    id: "arp_waz_au",
    icao_code: "YWCK",
    iata_country_code: "AU",
    iata_code: "WAZ",
    city_name: "Warwick",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Washabo Airport",
    longitude: -57.185302,
    latitude: 5.215277,
    id: "arp_wso_sr",
    icao_code: "SMWS",
    iata_country_code: "SR",
    iata_code: "WSO",
    city_name: "Washabo",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Washington County Airport",
    longitude: -80.289335,
    latitude: 40.135596,
    id: "arp_wsg_us",
    icao_code: "KAFJ",
    iata_country_code: "US",
    iata_code: "WSG",
    city_name: "Washington",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Washington Dulles International Airport",
    longitude: -77.45617,
    latitude: 38.948808,
    id: "arp_iad_us",
    icao_code: "KIAD",
    iata_country_code: "US",
    iata_code: "IAD",
    city_name: "Washington",
    city: {
      name: "Washington",
      id: "cit_was_us",
      iata_country_code: "US",
      iata_code: "WAS",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Wasilla Airport",
    longitude: -149.542546,
    latitude: 61.572022,
    id: "arp_wwa_us",
    icao_code: "PAWS",
    iata_country_code: "US",
    iata_code: "WWA",
    city_name: "Wasilla",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Wasior Airport",
    longitude: 134.506069,
    latitude: -2.720885,
    id: "arp_wsr_id",
    icao_code: "WASW",
    iata_country_code: "ID",
    iata_code: "WSR",
    city_name: "Wasior",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Waskaganish Airport",
    longitude: -78.756121,
    latitude: 51.474091,
    id: "arp_ykq_ca",
    icao_code: "CYKQ",
    iata_country_code: "CA",
    iata_code: "YKQ",
    city_name: "Waskaganish",
    city: null,
  },
  {
    time_zone: "America/Managua",
    name: "Waspam Airport",
    longitude: -83.969398,
    latitude: 14.739199,
    id: "arp_wsp_ni",
    icao_code: "MNWP",
    iata_country_code: "NI",
    iata_code: "WSP",
    city_name: "Waspam",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wasua Airport",
    longitude: 142.869134,
    latitude: -8.28379,
    id: "arp_wsa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WSA",
    city_name: "Wasua",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wasu Airport",
    longitude: 147.196637,
    latitude: -5.962062,
    id: "arp_wsu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WSU",
    city_name: "Wasu",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wasum Airport",
    longitude: 149.336813,
    latitude: -6.049422,
    id: "arp_wum_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WUM",
    city_name: "Wasum",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Waterbury-Oxford Airport",
    longitude: -73.135564,
    latitude: 41.479056,
    id: "arp_oxc_us",
    icao_code: "KOXC",
    iata_country_code: "US",
    iata_code: "OXC",
    city_name: "Oxford",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Waterfall Seaplane Base",
    longitude: -133.243223,
    latitude: 55.296315,
    id: "arp_kwf_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KWF",
    city_name: "Waterfall",
    city: null,
  },
  {
    time_zone: "Europe/Dublin",
    name: "Waterford Airport",
    longitude: -7.085294,
    latitude: 52.18742,
    id: "arp_wat_ie",
    icao_code: "EIWF",
    iata_country_code: "IE",
    iata_code: "WAT",
    city_name: "Waterford",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Waterkloof Air Force Base",
    longitude: 28.2225,
    latitude: -25.83,
    id: "arp_wkf_za",
    icao_code: "FAWK",
    iata_country_code: "ZA",
    iata_code: "WKF",
    city_name: "Pretoria",
    city: {
      name: "Pretoria",
      id: "cit_pry_za",
      iata_country_code: "ZA",
      iata_code: "PRY",
    },
  },
  {
    time_zone: "Australia/Darwin",
    name: "Waterloo Airport",
    longitude: 129.320202,
    latitude: -16.62976,
    id: "arp_wlo_au",
    icao_code: "YWTL",
    iata_country_code: "AU",
    iata_code: "WLO",
    city_name: "Waterloo",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Waterloo Regional Airport",
    longitude: -92.399726,
    latitude: 42.556625,
    id: "arp_alo_us",
    icao_code: "KALO",
    iata_country_code: "US",
    iata_code: "ALO",
    city_name: "Waterloo",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Waterport Airport",
    longitude: 146.499571,
    latitude: -18.632506,
    id: "arp_ors_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "ORS",
    city_name: "Orpheus Island",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Watertown International Airport",
    longitude: -76.021854,
    latitude: 43.992783,
    id: "arp_art_us",
    icao_code: "KART",
    iata_country_code: "US",
    iata_code: "ART",
    city_name: "Watertown",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Watertown Regional Airport",
    longitude: -97.157937,
    latitude: 44.917672,
    id: "arp_aty_us",
    icao_code: "KATY",
    iata_country_code: "US",
    iata_code: "ATY",
    city_name: "Watertown",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Waterville Robert Lafleur Airport",
    longitude: -69.67704,
    latitude: 44.533349,
    id: "arp_wvl_us",
    icao_code: "KWVL",
    iata_country_code: "US",
    iata_code: "WVL",
    city_name: "Waterville",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Watson Lake Airport",
    longitude: -128.822242,
    latitude: 60.115605,
    id: "arp_yqh_ca",
    icao_code: "CYQH",
    iata_country_code: "CA",
    iata_code: "YQH",
    city_name: "Watson Lake",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Watsonville Municipal Airport",
    longitude: -121.788909,
    latitude: 36.93498,
    id: "arp_wvi_us",
    icao_code: "KWVI",
    iata_country_code: "US",
    iata_code: "WVI",
    city_name: "Watsonville",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Wattay International Airport",
    longitude: 102.563021,
    latitude: 17.988218,
    id: "arp_vte_la",
    icao_code: "VLVT",
    iata_country_code: "LA",
    iata_code: "VTE",
    city_name: "Vientiane",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wau Airport",
    longitude: 146.717751,
    latitude: -7.333223,
    id: "arp_wug_pg",
    icao_code: "AYWU",
    iata_country_code: "PG",
    iata_code: "WUG",
    city_name: "Wau",
    city: null,
  },
  {
    time_zone: "Africa/Juba",
    name: "Wau Airport",
    longitude: 27.974409,
    latitude: 7.725818,
    id: "arp_wuu_sd",
    icao_code: "HSWW",
    iata_country_code: "SD",
    iata_code: "WUU",
    city_name: "Wau",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Wauchope Airport",
    longitude: 152.733,
    latitude: -31.45,
    id: "arp_wau_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "WAU",
    city_name: "Wauchope",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Waukegan National Airport",
    longitude: -87.867748,
    latitude: 42.420419,
    id: "arp_ugn_us",
    icao_code: "KUGN",
    iata_country_code: "US",
    iata_code: "UGN",
    city_name: "Waukegan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Waukesha County Airport",
    longitude: -88.237433,
    latitude: 43.039571,
    id: "arp_ues_us",
    icao_code: "KUES",
    iata_country_code: "US",
    iata_code: "UES",
    city_name: "Waukesha",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Waukon Municipal Airport",
    longitude: -91.491476,
    latitude: 43.269324,
    id: "arp_ukn_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UKN",
    city_name: "Waukon",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Wausau Downtown Airport",
    longitude: -89.627376,
    latitude: 44.927736,
    id: "arp_auw_us",
    icao_code: "KAUW",
    iata_country_code: "US",
    iata_code: "AUW",
    city_name: "Wausau",
    city: {
      name: "Wausau",
      id: "cit_auw_us",
      iata_country_code: "US",
      iata_code: "AUW",
    },
  },
  {
    time_zone: "Australia/Darwin",
    name: "Wave Hill Airport",
    longitude: 131.118213,
    latitude: -17.393462,
    id: "arp_wav_au",
    icao_code: "YWAV",
    iata_country_code: "AU",
    iata_code: "WAV",
    city_name: "Wave Hill",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Wawa Airport",
    longitude: -84.786698,
    latitude: 47.9667,
    id: "arp_yxz_ca",
    icao_code: "CYXZ",
    iata_country_code: "CA",
    iata_code: "YXZ",
    city_name: "Wawa",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wawoi Falls Airport",
    longitude: 142.656881,
    latitude: -6.953325,
    id: "arp_waj_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WAJ",
    city_name: "Wawoi Falls",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Waycross-Ware County Airport",
    longitude: -82.39549,
    latitude: 31.24933,
    id: "arp_ays_us",
    icao_code: "KAYS",
    iata_country_code: "US",
    iata_code: "AYS",
    city_name: "Waycross",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wayne County Airport",
    longitude: -81.884708,
    latitude: 40.874245,
    id: "arp_bjj_us",
    icao_code: "KBJJ",
    iata_country_code: "US",
    iata_code: "BJJ",
    city_name: "Wooster",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Waynesville-St. Robert Regional Airport",
    longitude: -92.140616,
    latitude: 37.741685,
    id: "arp_tbn_us",
    icao_code: "KTBN",
    iata_country_code: "US",
    iata_code: "TBN",
    city_name: "Fort Leonard Wood",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Weam Airport",
    longitude: 141.136932,
    latitude: -8.618701,
    id: "arp_wep_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WEP",
    city_name: "Weam",
    city: null,
  },
  {
    time_zone: "Africa/Monrovia",
    name: "Weasua Airport",
    longitude: -10.4833,
    latitude: 7.28333,
    id: "arp_wes_lr",
    icao_code: null,
    iata_country_code: "LR",
    iata_code: "WES",
    city_name: "Weasua",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Webequie Airport",
    longitude: -87.37438,
    latitude: 52.960745,
    id: "arp_ywp_ca",
    icao_code: "CYWP",
    iata_country_code: "CA",
    iata_code: "YWP",
    city_name: "Webequie",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Webster City Municipal Airport",
    longitude: -93.868891,
    latitude: 42.436645,
    id: "arp_ebs_us",
    icao_code: "KEBS",
    iata_country_code: "US",
    iata_code: "EBS",
    city_name: "Webster City",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wedau Airport",
    longitude: 150.083,
    latitude: -10.1,
    id: "arp_wed_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WED",
    city_name: "Wedau",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Weedon Field",
    longitude: -85.128894,
    latitude: 31.951344,
    id: "arp_euf_us",
    icao_code: "KEUF",
    iata_country_code: "US",
    iata_code: "EUF",
    city_name: "Eufaula",
    city: null,
  },
  {
    time_zone: "Asia/Colombo",
    name: "Weerawila Airport",
    longitude: 81.235557,
    latitude: 6.255298,
    id: "arp_wrz_lk",
    icao_code: "VCCW",
    iata_country_code: "LK",
    iata_code: "WRZ",
    city_name: "Weerawila",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Wee Waa Airport",
    longitude: 149.408751,
    latitude: -30.256724,
    id: "arp_wew_au",
    icao_code: "YWWA",
    iata_country_code: "AU",
    iata_code: "WEW",
    city_name: "Wee Waa",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Weeze Airport",
    longitude: 6.141343,
    latitude: 51.603101,
    id: "arp_nrn_de",
    icao_code: "EDLV",
    iata_country_code: "DE",
    iata_code: "NRN",
    city_name: "Weeze",
    city: {
      name: "Duesseldorf",
      id: "cit_dus_de",
      iata_country_code: "DE",
      iata_code: "DUS",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Weifang Airport",
    longitude: 119.118779,
    latitude: 36.64669,
    id: "arp_wef_cn",
    icao_code: "ZSWF",
    iata_country_code: "CN",
    iata_code: "WEF",
    city_name: "Weifang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Weihai Dashuibo Airport",
    longitude: 122.228996,
    latitude: 37.187098,
    id: "arp_weh_cn",
    icao_code: "ZSWH",
    iata_country_code: "CN",
    iata_code: "WEH",
    city_name: "Weihai",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Weipa Airport",
    longitude: 141.92429,
    latitude: -12.678834,
    id: "arp_wei_au",
    icao_code: "YBWP",
    iata_country_code: "AU",
    iata_code: "WEI",
    city_name: "Weipa",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Wekweètì Airport",
    longitude: -114.076881,
    latitude: 64.19158,
    id: "arp_yfj_ca",
    icao_code: "CYWE",
    iata_country_code: "CA",
    iata_code: "YFJ",
    city_name: "Wekweeti",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Welkom Airport",
    longitude: 26.66869,
    latitude: -27.999678,
    id: "arp_wel_za",
    icao_code: "FAWM",
    iata_country_code: "ZA",
    iata_code: "WEL",
    city_name: "Welkom",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Wellington International Airport",
    longitude: 174.807318,
    latitude: -41.327743,
    id: "arp_wlg_nz",
    icao_code: "NZWN",
    iata_country_code: "NZ",
    iata_code: "WLG",
    city_name: "Wellington",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Wells Municipal Airport",
    longitude: -114.923958,
    latitude: 41.11616,
    id: "arp_lwl_us",
    icao_code: "KLWL",
    iata_country_code: "US",
    iata_code: "LWL",
    city_name: "Wells",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wellsville Municipal Airport",
    longitude: -77.991295,
    latitude: 42.109463,
    id: "arp_elz_us",
    icao_code: "KELZ",
    iata_country_code: "US",
    iata_code: "ELZ",
    city_name: "Wellsville",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "Welshpool Airport",
    longitude: 146.448664,
    latitude: -38.68225,
    id: "arp_whl_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "WHL",
    city_name: "Welshpool",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Wemindji Airport",
    longitude: -78.829096,
    latitude: 53.01049,
    id: "arp_ync_ca",
    icao_code: "CYNC",
    iata_country_code: "CA",
    iata_code: "YNC",
    city_name: "Wemindji",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Wendover Airport",
    longitude: -114.032034,
    latitude: 40.719104,
    id: "arp_env_us",
    icao_code: "KENV",
    iata_country_code: "US",
    iata_code: "ENV",
    city_name: "Wendover",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wenshan Puzhehei Airport",
    longitude: 104.333611,
    latitude: 23.56361,
    id: "arp_wnh_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "WNH",
    city_name: "Wenshan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wenzhou Longwan International Airport",
    longitude: 120.853703,
    latitude: 27.911612,
    id: "arp_wnz_cn",
    icao_code: "ZSWZ",
    iata_country_code: "CN",
    iata_code: "WNZ",
    city_name: "Wenzhou",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "West Angelas Airport",
    longitude: 118.706632,
    latitude: -23.136552,
    id: "arp_wlp_au",
    icao_code: "YANG",
    iata_country_code: "AU",
    iata_code: "WLP",
    city_name: "West Angelas",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "West Bend Municipal Airport",
    longitude: -88.127829,
    latitude: 43.422214,
    id: "arp_etb_us",
    icao_code: "KETB",
    iata_country_code: "US",
    iata_code: "ETB",
    city_name: "West Bend",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "West Branch Community Airport",
    longitude: -84.187062,
    latitude: 44.245165,
    id: "arp_wbk_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WBK",
    city_name: "West Branch",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Westchester County Airport",
    longitude: -73.708133,
    latitude: 41.067667,
    id: "arp_hpn_us",
    icao_code: "KHPN",
    iata_country_code: "US",
    iata_code: "HPN",
    city_name: "White Plains",
    city: null,
  },
  {
    time_zone: "America/Nassau",
    name: "West End Airport",
    longitude: -78.975772,
    latitude: 26.685656,
    id: "arp_wtd_bs",
    icao_code: "MYGW",
    iata_country_code: "BS",
    iata_code: "WTD",
    city_name: "West End",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Westerly State Airport",
    longitude: -71.803601,
    latitude: 41.349639,
    id: "arp_wst_us",
    icao_code: "KWST",
    iata_country_code: "US",
    iata_code: "WST",
    city_name: "Westerly",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Western Nebraska Regional Airport",
    longitude: -103.595511,
    latitude: 41.873773,
    id: "arp_bff_us",
    icao_code: "KBFF",
    iata_country_code: "US",
    iata_code: "BFF",
    city_name: "Scottsbluff",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Westfield-Barnes Regional Airport",
    longitude: -72.71667,
    latitude: 42.158364,
    id: "arp_baf_us",
    icao_code: "KBAF",
    iata_country_code: "US",
    iata_code: "BAF",
    city_name: "Springfield",
    city: {
      name: "Springfield",
      id: "cit_sfy_us",
      iata_country_code: "US",
      iata_code: "SFY",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "West Houston Airport",
    longitude: -95.673149,
    latitude: 29.816906,
    id: "arp_iws_us",
    icao_code: "KIWS",
    iata_country_code: "US",
    iata_code: "IWS",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "America/Vancouver",
    name: "West Kootenay Regional Airport",
    longitude: -117.63358,
    latitude: 49.297571,
    id: "arp_ycg_ca",
    icao_code: "CYCG",
    iata_country_code: "CA",
    iata_code: "YCG",
    city_name: "Castlegar",
    city: null,
  },
  {
    time_zone: "Asia/Makassar",
    name: "West Kutai Melalan Airport",
    longitude: 115.760049,
    latitude: -0.205389,
    id: "arp_ghs_id",
    icao_code: null,
    iata_country_code: "ID",
    iata_code: "GHS",
    city_name: "West Kutai",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "West Memphis Municipal Airport",
    longitude: -90.234171,
    latitude: 35.135685,
    id: "arp_awm_us",
    icao_code: "KAWM",
    iata_country_code: "US",
    iata_code: "AWM",
    city_name: "West Memphis",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Westover Metropolitan Airport",
    longitude: -72.533687,
    latitude: 42.195136,
    id: "arp_cef_us",
    icao_code: "KCEF",
    iata_country_code: "US",
    iata_code: "CEF",
    city_name: "Springfield",
    city: {
      name: "Springfield",
      id: "cit_sfy_us",
      iata_country_code: "US",
      iata_code: "SFY",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "West Point Village Seaplane Base Airport",
    longitude: -153.548925,
    latitude: 57.77015,
    id: "arp_kwp_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KWP",
    city_name: "West Point",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Westport Airport",
    longitude: 171.57974,
    latitude: -41.738996,
    id: "arp_wsz_nz",
    icao_code: "NZWS",
    iata_country_code: "NZ",
    iata_code: "WSZ",
    city_name: "Westport",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Westray Airport",
    longitude: -2.949954,
    latitude: 59.350376,
    id: "arp_wry_gb",
    icao_code: "EGEW",
    iata_country_code: "GB",
    iata_code: "WRY",
    city_name: "Westray",
    city: null,
  },
  {
    time_zone: "Australia/Melbourne",
    name: "West Sale Airport",
    longitude: 146.965705,
    latitude: -38.091627,
    id: "arp_sxe_au",
    icao_code: "YWSL",
    iata_country_code: "AU",
    iata_code: "SXE",
    city_name: "Sale",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Westsound Seaplane Base Airport",
    longitude: -122.951382,
    latitude: 48.61669,
    id: "arp_wsx_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WSX",
    city_name: "Westsound",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "West Woodward Airport",
    longitude: -99.521867,
    latitude: 36.437375,
    id: "arp_wwr_us",
    icao_code: "KWWR",
    iata_country_code: "US",
    iata_code: "WWR",
    city_name: "Woodward",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "West Wyalong Airport",
    longitude: 147.191082,
    latitude: -33.937195,
    id: "arp_wwy_au",
    icao_code: "YWWL",
    iata_country_code: "AU",
    iata_code: "WWY",
    city_name: "West Wyalong",
    city: null,
  },
  {
    time_zone: "Europe/Brussels",
    name: "Wevelgem Airport",
    longitude: 3.209329,
    latitude: 50.818368,
    id: "arp_kjk_be",
    icao_code: "EBKT",
    iata_country_code: "BE",
    iata_code: "KJK",
    city_name: "Kortrijk",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wewak Airport",
    longitude: 143.669063,
    latitude: -3.583632,
    id: "arp_wwk_pg",
    icao_code: "AYWK",
    iata_country_code: "PG",
    iata_code: "WWK",
    city_name: "Wewak",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Wexford County Airport",
    longitude: -85.417663,
    latitude: 44.277584,
    id: "arp_cad_us",
    icao_code: "KCAD",
    iata_country_code: "US",
    iata_code: "CAD",
    city_name: "Cadillac",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Whakatane Airport",
    longitude: 176.915818,
    latitude: -37.922556,
    id: "arp_whk_nz",
    icao_code: "NZWK",
    iata_country_code: "NZ",
    iata_code: "WHK",
    city_name: "Whakatane",
    city: null,
  },
  {
    time_zone: "America/Rankin_Inlet",
    name: "Whale Cove Airport",
    longitude: -92.598011,
    latitude: 62.24036,
    id: "arp_yxn_ca",
    icao_code: "CYXN",
    iata_country_code: "CA",
    iata_code: "YXN",
    city_name: "Whale Cove",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Whalsay Airport",
    longitude: -0.926243,
    latitude: 60.377175,
    id: "arp_whs_gb",
    icao_code: "EGEH",
    iata_country_code: "GB",
    iata_code: "WHS",
    city_name: "Whalsay",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Whangarei Airport",
    longitude: 174.364525,
    latitude: -35.767946,
    id: "arp_wre_nz",
    icao_code: "NZWR",
    iata_country_code: "NZ",
    iata_code: "WRE",
    city_name: "Whangarei",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Wharton Regional Airport",
    longitude: -96.154017,
    latitude: 29.254708,
    id: "arp_wht_us",
    icao_code: "KARM",
    iata_country_code: "US",
    iata_code: "WHT",
    city_name: "Wharton",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Whatì Airport",
    longitude: -117.245272,
    latitude: 63.131608,
    id: "arp_yle_ca",
    icao_code: "CEM3",
    iata_country_code: "CA",
    iata_code: "YLE",
    city_name: "Whatì",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "W.H. Bud Barron Airport",
    longitude: -82.98557,
    latitude: 32.563581,
    id: "arp_dbn_us",
    icao_code: "KDBN",
    iata_country_code: "US",
    iata_code: "DBN",
    city_name: "Dublin",
    city: null,
  },
  {
    time_zone: "Pacific/Honolulu",
    name: "Wheeler Army Airfield",
    longitude: -158.037437,
    latitude: 21.480137,
    id: "arp_hhi_us",
    icao_code: "PHHI",
    iata_country_code: "US",
    iata_code: "HHI",
    city_name: "Wahiawa",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wheeling Ohio County Airport",
    longitude: -80.646733,
    latitude: 40.176945,
    id: "arp_hlg_us",
    icao_code: "KHLG",
    iata_country_code: "US",
    iata_code: "HLG",
    city_name: "Wheeling",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Whidbey Island Naval Air Station Airport",
    longitude: -122.668904,
    latitude: 48.344455,
    id: "arp_nuw_us",
    icao_code: "KNUW",
    iata_country_code: "US",
    iata_code: "NUW",
    city_name: "Oak Harbor",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Whistler Airport",
    longitude: -122.948967,
    latitude: 50.143603,
    id: "arp_yws_ca",
    icao_code: "CAE5",
    iata_country_code: "CA",
    iata_code: "YWS",
    city_name: "Whistler",
    city: null,
  },
  {
    time_zone: "America/Edmonton",
    name: "Whitecourt Airport",
    longitude: -115.785916,
    latitude: 54.142872,
    id: "arp_yzu_ca",
    icao_code: "CYZU",
    iata_country_code: "CA",
    iata_code: "YZU",
    city_name: "Whitecourt",
    city: null,
  },
  {
    time_zone: "America/Whitehorse",
    name: "Whitehorse International Airport",
    longitude: -135.071549,
    latitude: 60.711864,
    id: "arp_yxy_ca",
    icao_code: "CYXY",
    iata_country_code: "CA",
    iata_code: "YXY",
    city_name: "Whitehorse",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Whitehouse Naval Outlying Field Airport",
    longitude: -81.875175,
    latitude: 30.350554,
    id: "arp_nen_us",
    icao_code: "KNEN",
    iata_country_code: "US",
    iata_code: "NEN",
    city_name: "Jacksonville",
    city: {
      name: "Jacksonville",
      id: "cit_jax_us",
      iata_country_code: "US",
      iata_code: "JAX",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Whiteman Air Force Base",
    longitude: -93.551167,
    latitude: 38.730404,
    id: "arp_szl_us",
    icao_code: "KSZL",
    iata_country_code: "US",
    iata_code: "SZL",
    city_name: "Knob Noster",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Whiteman Airport",
    longitude: -118.412713,
    latitude: 34.25989,
    id: "arp_whp_us",
    icao_code: "KWHP",
    iata_country_code: "US",
    iata_code: "WHP",
    city_name: "Los Angeles",
    city: {
      name: "Los Angeles",
      id: "cit_lax_us",
      iata_country_code: "US",
      iata_code: "LAX",
    },
  },
  {
    time_zone: "America/Nome",
    name: "White Mountain Airport",
    longitude: -163.411876,
    latitude: 64.688387,
    id: "arp_wmo_us",
    icao_code: "PAWM",
    iata_country_code: "US",
    iata_code: "WMO",
    city_name: "White Mountain",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Whiteriver Airport",
    longitude: -109.985835,
    latitude: 33.813041,
    id: "arp_wtr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WTR",
    city_name: "Whiteriver",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "White River Airport",
    longitude: -85.222557,
    latitude: 48.627855,
    id: "arp_ywr_ca",
    icao_code: null,
    iata_country_code: "CA",
    iata_code: "YWR",
    city_name: "White River",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Whiteside County Airport",
    longitude: -89.677785,
    latitude: 41.742792,
    id: "arp_sqi_us",
    icao_code: "KSQI",
    iata_country_code: "US",
    iata_code: "SQI",
    city_name: "Rock Falls/Sterling",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Whitianga Airport",
    longitude: 175.677023,
    latitude: -36.831845,
    id: "arp_wtz_nz",
    icao_code: "NZWT",
    iata_country_code: "NZ",
    iata_code: "WTZ",
    city_name: "Whitianga",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Whiting Field Naval Air Station - North",
    longitude: -87.022104,
    latitude: 30.72418,
    id: "arp_nse_us",
    icao_code: "KNSE",
    iata_country_code: "US",
    iata_code: "NSE",
    city_name: "Milton",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Whitsunday Airport",
    longitude: 148.755244,
    latitude: -20.277447,
    id: "arp_wsy_au",
    icao_code: "YSHR",
    iata_country_code: "AU",
    iata_code: "WSY",
    city_name: "Shute Hbr/Airlie Beach",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Whitsunday Coast Airport",
    longitude: 148.553631,
    latitude: -20.492978,
    id: "arp_ppp_au",
    icao_code: "YBPN",
    iata_country_code: "AU",
    iata_code: "PPP",
    city_name: "Proserpine",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Whyalla Airport",
    longitude: 137.520282,
    latitude: -33.052775,
    id: "arp_wya_au",
    icao_code: "YWHA",
    iata_country_code: "AU",
    iata_code: "WYA",
    city_name: "Whyalla",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Wiarton Airport",
    longitude: -81.109474,
    latitude: 44.746716,
    id: "arp_yvv_ca",
    icao_code: "CYVV",
    iata_country_code: "CA",
    iata_code: "YVV",
    city_name: "Wiarton",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Wichita Dwight D. Eisenhower National Airport",
    longitude: -97.430898,
    latitude: 37.650375,
    id: "arp_ict_us",
    icao_code: "KICT",
    iata_country_code: "US",
    iata_code: "ICT",
    city_name: "Wichita",
    city: {
      name: "Wichita",
      id: "cit_ict_us",
      iata_country_code: "US",
      iata_code: "ICT",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Wichita Falls Regional Airport",
    longitude: -98.491873,
    latitude: 33.964628,
    id: "arp_sps_us",
    icao_code: "KSPS",
    iata_country_code: "US",
    iata_code: "SPS",
    city_name: "Wichita Falls",
    city: {
      name: "Wichita Falls",
      id: "cit_sps_us",
      iata_country_code: "US",
      iata_code: "SPS",
    },
  },
  {
    time_zone: "Europe/London",
    name: "Wick Airport",
    longitude: -3.091328,
    latitude: 58.457029,
    id: "arp_wic_gb",
    icao_code: "EGPC",
    iata_country_code: "GB",
    iata_code: "WIC",
    city_name: "Wick",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Wiesbaden Army Airfield",
    longitude: 8.324767,
    latitude: 50.04859,
    id: "arp_wie_de",
    icao_code: "ETOU",
    iata_country_code: "DE",
    iata_code: "WIE",
    city_name: "Wiesbaden",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Wilbarger County Airport",
    longitude: -99.283844,
    latitude: 34.225048,
    id: "arp_wib_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WIB",
    city_name: "Vernon",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Wilcannia Airport",
    longitude: 143.377648,
    latitude: -31.523604,
    id: "arp_wio_au",
    icao_code: "YWCA",
    iata_country_code: "AU",
    iata_code: "WIO",
    city_name: "Wilcannia",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Wild Coast Sun Airport",
    longitude: 29.833333,
    latitude: -31.25,
    id: "arp_mzf_za",
    icao_code: null,
    iata_country_code: "ZA",
    iata_code: "MZF",
    city_name: "Mzamba",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Wiley Post Airport",
    longitude: -97.644929,
    latitude: 35.532449,
    id: "arp_pwa_us",
    icao_code: "KPWA",
    iata_country_code: "US",
    iata_code: "PWA",
    city_name: "Oklahoma City",
    city: {
      name: "Oklahoma City",
      id: "cit_okc_us",
      iata_country_code: "US",
      iata_code: "OKC",
    },
  },
  {
    time_zone: "America/Anchorage",
    name: "Wiley Post-Will Rogers Memorial Airport",
    longitude: -156.766052,
    latitude: 71.285247,
    id: "arp_brw_us",
    icao_code: "PABR",
    iata_country_code: "US",
    iata_code: "BRW",
    city_name: "Barrow",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wilgrove Air Park",
    longitude: -80.669294,
    latitude: 35.212095,
    id: "arp_qwg_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "QWG",
    city_name: "Charlotte",
    city: {
      name: "Charlotte",
      id: "cit_clt_us",
      iata_country_code: "US",
      iata_code: "CLT",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Wilkes-Barre/Scranton International Airport",
    longitude: -75.725645,
    latitude: 41.337795,
    id: "arp_avp_us",
    icao_code: "KAVP",
    iata_country_code: "US",
    iata_code: "AVP",
    city_name: "Wilkes-Barre/Scranton",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wilkes-Barre Wyoming Valley Airport",
    longitude: -75.853741,
    latitude: 41.297734,
    id: "arp_wbw_us",
    icao_code: "KWBW",
    iata_country_code: "US",
    iata_code: "WBW",
    city_name: "Wilkes-Barre",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wilkes County Airport",
    longitude: -81.099593,
    latitude: 36.223237,
    id: "arp_ikb_us",
    icao_code: "KUKF",
    iata_country_code: "US",
    iata_code: "IKB",
    city_name: "North Wilkesboro",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "William P. Hobby Airport",
    longitude: -95.277841,
    latitude: 29.647729,
    id: "arp_hou_us",
    icao_code: "KHOU",
    iata_country_code: "US",
    iata_code: "HOU",
    city_name: "Houston",
    city: {
      name: "Houston",
      id: "cit_hou_us",
      iata_country_code: "US",
      iata_code: "HOU",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "William R Fairchild International Airport",
    longitude: -123.498288,
    latitude: 48.12028,
    id: "arp_clm_us",
    icao_code: "KCLM",
    iata_country_code: "US",
    iata_code: "CLM",
    city_name: "Port Angeles",
    city: null,
  },
  {
    time_zone: "America/St_Johns",
    name: "Williams Harbour Airport",
    longitude: -55.784698,
    latitude: 52.566898,
    id: "arp_ywm_ca",
    icao_code: "CCA6",
    iata_country_code: "CA",
    iata_code: "YWM",
    city_name: "Williams Harbour",
    city: null,
  },
  {
    time_zone: "America/Vancouver",
    name: "Williams Lake Airport",
    longitude: -122.06109,
    latitude: 52.184519,
    id: "arp_ywl_ca",
    icao_code: "CYWL",
    iata_country_code: "CA",
    iata_code: "YWL",
    city_name: "Williams Lake",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Williamson County Regional Airport",
    longitude: -89.012148,
    latitude: 37.752298,
    id: "arp_mwa_us",
    icao_code: "KMWA",
    iata_country_code: "US",
    iata_code: "MWA",
    city_name: "Marion",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Williamsport Regional Airport",
    longitude: -76.920347,
    latitude: 41.242958,
    id: "arp_ipt_us",
    icao_code: "KIPT",
    iata_country_code: "US",
    iata_code: "IPT",
    city_name: "Williamsport",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "William T. Piper Memorial Airport",
    longitude: -77.421519,
    latitude: 41.136281,
    id: "arp_lhv_us",
    icao_code: "KLHV",
    iata_country_code: "US",
    iata_code: "LHV",
    city_name: "Lock Haven",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Williston Basin International Airport",
    longitude: -103.751138,
    latitude: 48.260864,
    id: "arp_xwa_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "XWA",
    city_name: "Williston",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Willmar Municipal Airport",
    longitude: -95.131704,
    latitude: 45.113873,
    id: "arp_ill_us",
    icao_code: "KBDH",
    iata_country_code: "US",
    iata_code: "ILL",
    city_name: "Willmar",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Willoughby Lost Nation Municipal Airport",
    longitude: -81.394104,
    latitude: 41.68364,
    id: "arp_lnn_us",
    icao_code: "KLNN",
    iata_country_code: "US",
    iata_code: "LNN",
    city_name: "Willoughby",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Willow Airport",
    longitude: -150.051718,
    latitude: 61.75447,
    id: "arp_wow_us",
    icao_code: "PAUO",
    iata_country_code: "US",
    iata_code: "WOW",
    city_name: "Willow",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "Willow Run Airport",
    longitude: -83.527345,
    latitude: 42.237851,
    id: "arp_yip_us",
    icao_code: "KYIP",
    iata_country_code: "US",
    iata_code: "YIP",
    city_name: "Detroit",
    city: {
      name: "Detroit",
      id: "cit_dtt_us",
      iata_country_code: "US",
      iata_code: "DTT",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Willows-Glenn County Airport",
    longitude: -122.217874,
    latitude: 39.518798,
    id: "arp_wlw_us",
    icao_code: "KWLW",
    iata_country_code: "US",
    iata_code: "WLW",
    city_name: "Willows",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Will Rogers World Airport",
    longitude: -97.600628,
    latitude: 35.393078,
    id: "arp_okc_us",
    icao_code: "KOKC",
    iata_country_code: "US",
    iata_code: "OKC",
    city_name: "Oklahoma City",
    city: {
      name: "Oklahoma City",
      id: "cit_okc_us",
      iata_country_code: "US",
      iata_code: "OKC",
    },
  },
  {
    time_zone: "America/New_York",
    name: "Wilmington Air Park Airport",
    longitude: -83.788885,
    latitude: 39.429949,
    id: "arp_iln_us",
    icao_code: "KILN",
    iata_country_code: "US",
    iata_code: "ILN",
    city_name: "Wilmington",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wilmington International Airport",
    longitude: -77.906524,
    latitude: 34.268723,
    id: "arp_ilm_us",
    icao_code: "KILM",
    iata_country_code: "US",
    iata_code: "ILM",
    city_name: "Wilmington",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Wilpena Pound Airport",
    longitude: 138.469396,
    latitude: -31.851401,
    id: "arp_hwk_au",
    icao_code: "YHAW",
    iata_country_code: "AU",
    iata_code: "HWK",
    city_name: "Hawker",
    city: null,
  },
  {
    time_zone: "Africa/Nairobi",
    name: "Wilson Airport",
    longitude: 36.814806,
    latitude: -1.321234,
    id: "arp_wil_ke",
    icao_code: "HKNW",
    iata_country_code: "KE",
    iata_code: "WIL",
    city_name: "Nairobi",
    city: {
      name: "Nairobi",
      id: "cit_nbo_ke",
      iata_country_code: "KE",
      iata_code: "NBO",
    },
  },
  {
    time_zone: "Australia/Perth",
    name: "Wiluna Airport",
    longitude: 120.219706,
    latitude: -26.630909,
    id: "arp_wun_au",
    icao_code: "YWLU",
    iata_country_code: "AU",
    iata_code: "WUN",
    city_name: "Wiluna",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Winchester Regional Airport",
    longitude: -78.146565,
    latitude: 39.143111,
    id: "arp_wgo_us",
    icao_code: "KOKV",
    iata_country_code: "US",
    iata_code: "WGO",
    city_name: "Winchester",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Windarling Airport",
    longitude: 119.387984,
    latitude: -30.032798,
    id: "arp_wrn_au",
    icao_code: "YWDG",
    iata_country_code: "AU",
    iata_code: "WRN",
    city_name: "Windarling",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Windarra Airport",
    longitude: 122.243855,
    latitude: -28.478427,
    id: "arp_wnd_au",
    icao_code: "YWDA",
    iata_country_code: "AU",
    iata_code: "WND",
    city_name: "Windarra",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Windom Municipal Airport",
    longitude: -95.109078,
    latitude: 43.908769,
    id: "arp_mwm_us",
    icao_code: "KMWH",
    iata_country_code: "US",
    iata_code: "MWM",
    city_name: "Windom",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Windorah Airport",
    longitude: 142.665443,
    latitude: -25.412529,
    id: "arp_wnr_au",
    icao_code: "YWDH",
    iata_country_code: "AU",
    iata_code: "WNR",
    city_name: "Windorah",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Windsor International Airport",
    longitude: -82.954272,
    latitude: 42.274966,
    id: "arp_yqg_ca",
    icao_code: "CYQG",
    iata_country_code: "CA",
    iata_code: "YQG",
    city_name: "Windsor",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wings Field",
    longitude: -75.264827,
    latitude: 40.136628,
    id: "arp_bbx_us",
    icao_code: "KLOM",
    iata_country_code: "US",
    iata_code: "BBX",
    city_name: "Philadelphia",
    city: {
      name: "Philadelphia",
      id: "cit_phl_us",
      iata_country_code: "US",
      iata_code: "PHL",
    },
  },
  {
    time_zone: "America/Chicago",
    name: "Winkler County Airport",
    longitude: -103.199995,
    latitude: 31.780337,
    id: "arp_ink_us",
    icao_code: "KINK",
    iata_country_code: "US",
    iata_code: "INK",
    city_name: "Wink",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Winnemucca Municipal Airport",
    longitude: -117.803731,
    latitude: 40.900544,
    id: "arp_wmc_us",
    icao_code: "KWMC",
    iata_country_code: "US",
    iata_code: "WMC",
    city_name: "Winnemucca",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Winnipeg James Armstrong Richardson International Airport",
    longitude: -97.238222,
    latitude: 49.909914,
    id: "arp_ywg_ca",
    icao_code: "CYWG",
    iata_country_code: "CA",
    iata_code: "YWG",
    city_name: "Winnipeg",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "Winnipeg/St. Andrews Airport",
    longitude: -97.034105,
    latitude: 50.057376,
    id: "arp_yav_ca",
    icao_code: "CYAV",
    iata_country_code: "CA",
    iata_code: "YAV",
    city_name: "Mayne Island",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Winona Municipal Airport",
    longitude: -91.707534,
    latitude: 44.075367,
    id: "arp_ona_us",
    icao_code: "KONA",
    iata_country_code: "US",
    iata_code: "ONA",
    city_name: "Winona",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Winslow-Lindbergh Regional Airport",
    longitude: -110.717907,
    latitude: 35.021245,
    id: "arp_inw_us",
    icao_code: "KINW",
    iata_country_code: "US",
    iata_code: "INW",
    city_name: "Winslow",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Winston Field",
    longitude: -100.950566,
    latitude: 32.694315,
    id: "arp_snk_us",
    icao_code: "KSNK",
    iata_country_code: "US",
    iata_code: "SNK",
    city_name: "Snyder",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Winter Haven's Gilbert Airport",
    longitude: -81.753096,
    latitude: 28.06369,
    id: "arp_gif_us",
    icao_code: "KGIF",
    iata_country_code: "US",
    iata_code: "GIF",
    city_name: "Winter Haven",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Winton Airport",
    longitude: 143.084845,
    latitude: -22.363575,
    id: "arp_win_au",
    icao_code: "YWTN",
    iata_country_code: "AU",
    iata_code: "WIN",
    city_name: "Winton",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wipim Airport",
    longitude: 142.882454,
    latitude: -8.788394,
    id: "arp_wpm_pg",
    icao_code: "AYXP",
    iata_country_code: "PG",
    iata_code: "WPM",
    city_name: "Wipim",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wiscasset Airport",
    longitude: -69.712552,
    latitude: 43.962369,
    id: "arp_iss_us",
    icao_code: "KIWI",
    iata_country_code: "US",
    iata_code: "ISS",
    city_name: "Wiscasset",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Wiseman Airport",
    longitude: -150.123239,
    latitude: 67.404312,
    id: "arp_wsm_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WSM",
    city_name: "Wiseman",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Witham Field",
    longitude: -80.22335,
    latitude: 27.180287,
    id: "arp_sua_us",
    icao_code: "KSUA",
    iata_country_code: "US",
    iata_code: "SUA",
    city_name: "Stuart",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Wittenoom Airport",
    longitude: 118.349288,
    latitude: -22.222006,
    id: "arp_wit_au",
    icao_code: "YWIT",
    iata_country_code: "AU",
    iata_code: "WIT",
    city_name: "Wittenoom",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Wittman Regional Airport",
    longitude: -88.552114,
    latitude: 43.993474,
    id: "arp_osh_us",
    icao_code: "KOSH",
    iata_country_code: "US",
    iata_code: "OSH",
    city_name: "Oshkosh",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Witu Airport",
    longitude: 149.440445,
    latitude: -4.689543,
    id: "arp_wiu_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WIU",
    city_name: "Witu",
    city: null,
  },
  {
    time_zone: "America/Detroit",
    name: "W. K. Kellogg Airport",
    longitude: -85.249987,
    latitude: 42.309202,
    id: "arp_btl_us",
    icao_code: "KBTL",
    iata_country_code: "US",
    iata_code: "BTL",
    city_name: "Battle Creek",
    city: null,
  },
  {
    time_zone: "Europe/Amsterdam",
    name: "Woensdrecht Air Base",
    longitude: 4.340787,
    latitude: 51.4494,
    id: "arp_woe_nl",
    icao_code: "EHWO",
    iata_country_code: "NL",
    iata_code: "WOE",
    city_name: "Bergen op Zoom",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Woitape Airport",
    longitude: 147.253208,
    latitude: -8.546151,
    id: "arp_wtp_pg",
    icao_code: "AYWT",
    iata_country_code: "PG",
    iata_code: "WTP",
    city_name: "Woitape",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Woja Airport",
    longitude: 168.55122,
    latitude: 7.452048,
    id: "arp_wja_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "WJA",
    city_name: "Woja",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Wolf Point L. M. Clayton Airport",
    longitude: -105.574979,
    latitude: 48.094464,
    id: "arp_olf_us",
    icao_code: "KOLF",
    iata_country_code: "US",
    iata_code: "OLF",
    city_name: "Wolf Point",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Wollaston Lake Airport",
    longitude: -103.172248,
    latitude: 58.106917,
    id: "arp_zwl_ca",
    icao_code: "CZWL",
    iata_country_code: "CA",
    iata_code: "ZWL",
    city_name: "Wollaston Lake",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Wollogorang Airport",
    longitude: 137.93453,
    latitude: -17.2199,
    id: "arp_wll_au",
    icao_code: "YWOR",
    iata_country_code: "AU",
    iata_code: "WLL",
    city_name: "Wollogorang",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Wondai Airport",
    longitude: 151.860384,
    latitude: -26.283742,
    id: "arp_wdi_au",
    icao_code: "YWND",
    iata_country_code: "AU",
    iata_code: "WDI",
    city_name: "Wondai",
    city: null,
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Wonderboom Airport",
    longitude: 28.224168,
    latitude: -25.65386,
    id: "arp_pry_za",
    icao_code: "FAWB",
    iata_country_code: "ZA",
    iata_code: "PRY",
    city_name: "Pretoria",
    city: {
      name: "Pretoria",
      id: "cit_pry_za",
      iata_country_code: "ZA",
      iata_code: "PRY",
    },
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Wondoola Airport",
    longitude: 140.891469,
    latitude: -18.571547,
    id: "arp_won_au",
    icao_code: "YWDL",
    iata_country_code: "AU",
    iata_code: "WON",
    city_name: "Wondoola",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wonenara Airport",
    longitude: 145.891623,
    latitude: -6.796733,
    id: "arp_woa_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WOA",
    city_name: "Wonenara",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Wonju Airport",
    longitude: 127.965714,
    latitude: 37.442831,
    id: "arp_wju_kr",
    icao_code: "RKNW",
    iata_country_code: "KR",
    iata_code: "WJU",
    city_name: "Wonju",
    city: null,
  },
  {
    time_zone: "America/Caracas",
    name: "Wonken Airport",
    longitude: -61.733004,
    latitude: 4.988335,
    id: "arp_wok_ve",
    icao_code: "SVUQ",
    iata_country_code: "VE",
    iata_code: "WOK",
    city_name: "Wonken",
    city: null,
  },
  {
    time_zone: "Asia/Pyongyang",
    name: "Wonsan Kalma International Airport",
    longitude: 127.484566,
    latitude: 39.167107,
    id: "arp_wos_kp",
    icao_code: "ZKWS",
    iata_country_code: "KP",
    iata_code: "WOS",
    city_name: "Wonsan",
    city: null,
  },
  {
    time_zone: "Pacific/Auckland",
    name: "Woodbourne Airport",
    longitude: 173.869213,
    latitude: -41.516728,
    id: "arp_bhe_nz",
    icao_code: "NZWB",
    iata_country_code: "NZ",
    iata_code: "BHE",
    city_name: "Blenheim",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Woodford Airport",
    longitude: -2.151101,
    latitude: 53.336509,
    id: "arp_wfd_gb",
    icao_code: "EGCD",
    iata_country_code: "GB",
    iata_code: "WFD",
    city_name: "Woodford",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Woodgreen Airport",
    longitude: 134.25,
    latitude: -22.5,
    id: "arp_wog_au",
    icao_code: null,
    iata_country_code: "AU",
    iata_code: "WOG",
    city_name: "Woodgreen",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Woodie Woodie Airport",
    longitude: 121.234834,
    latitude: -21.664783,
    id: "arp_wwi_au",
    icao_code: "YWWI",
    iata_country_code: "AU",
    iata_code: "WWI",
    city_name: "Woodie Woodie",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Woodward Field",
    longitude: -80.564842,
    latitude: 34.283603,
    id: "arp_cdn_us",
    icao_code: "KCDN",
    iata_country_code: "US",
    iata_code: "CDN",
    city_name: "Camden",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Woomera Airfield Airport",
    longitude: 136.817001,
    latitude: -31.144199,
    id: "arp_umr_au",
    icao_code: "YPWR",
    iata_country_code: "AU",
    iata_code: "UMR",
    city_name: "Woomera",
    city: null,
  },
  {
    time_zone: "Africa/Libreville",
    name: "Wora na Yeno Airport",
    longitude: 9.55,
    latitude: -1.783,
    id: "arp_wne_ga",
    icao_code: null,
    iata_country_code: "GA",
    iata_code: "WNE",
    city_name: "Wora na Yeno",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Worcester Regional Airport",
    longitude: -71.874606,
    latitude: 42.267661,
    id: "arp_orh_us",
    icao_code: "KORH",
    iata_country_code: "US",
    iata_code: "ORH",
    city_name: "Worcester",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Worland Municipal Airport",
    longitude: -107.951385,
    latitude: 43.969277,
    id: "arp_wrl_us",
    icao_code: "KWRL",
    iata_country_code: "US",
    iata_code: "WRL",
    city_name: "Worland",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Worthington Municipal Airport",
    longitude: -95.5841,
    latitude: 43.656806,
    id: "arp_otg_us",
    icao_code: "KOTG",
    iata_country_code: "US",
    iata_code: "OTG",
    city_name: "Worthington",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Wotho Island Airport",
    longitude: 166.002394,
    latitude: 10.173469,
    id: "arp_wto_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "WTO",
    city_name: "Wotho Island",
    city: null,
  },
  {
    time_zone: "Pacific/Majuro",
    name: "Wotje Airport",
    longitude: 170.235247,
    latitude: 9.461604,
    id: "arp_wte_mh",
    icao_code: null,
    iata_country_code: "MH",
    iata_code: "WTE",
    city_name: "Wotje Atoll",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Wrangell Airport",
    longitude: -132.374814,
    latitude: 56.484691,
    id: "arp_wrg_us",
    icao_code: "PAWG",
    iata_country_code: "US",
    iata_code: "WRG",
    city_name: "Wrangell",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Wright-Patterson Air Force Base",
    longitude: -84.048302,
    latitude: 39.826099,
    id: "arp_ffo_us",
    icao_code: "KFFO",
    iata_country_code: "US",
    iata_code: "FFO",
    city_name: "Dayton",
    city: {
      name: "Dayton",
      id: "cit_day_us",
      iata_country_code: "US",
      iata_code: "DAY",
    },
  },
  {
    time_zone: "America/Edmonton",
    name: "Wrigley Airport",
    longitude: -123.437138,
    latitude: 63.210036,
    id: "arp_ywy_ca",
    icao_code: "CYWY",
    iata_country_code: "CA",
    iata_code: "YWY",
    city_name: "Wrigley",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Wrocław Copernicus Airport",
    longitude: 16.883366,
    latitude: 51.103432,
    id: "arp_wro_pl",
    icao_code: "EPWR",
    iata_country_code: "PL",
    iata_code: "WRO",
    city_name: "Wrocław",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Wrotham Park Airport",
    longitude: 144.001299,
    latitude: -16.658496,
    id: "arp_wpk_au",
    icao_code: "YWMP",
    iata_country_code: "AU",
    iata_code: "WPK",
    city_name: "Wrotham Park",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Wudinna Airport",
    longitude: 135.446935,
    latitude: -33.042738,
    id: "arp_wud_au",
    icao_code: "YWUD",
    iata_country_code: "AU",
    iata_code: "WUD",
    city_name: "Wudinna",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wuhai Airport",
    longitude: 106.804106,
    latitude: 39.79351,
    id: "arp_wua_cn",
    icao_code: "ZBUH",
    iata_country_code: "CN",
    iata_code: "WUA",
    city_name: "Wuhai",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wuhan Tianhe International Airport",
    longitude: 114.209483,
    latitude: 30.781405,
    id: "arp_wuh_cn",
    icao_code: "ZHHH",
    iata_country_code: "CN",
    iata_code: "WUH",
    city_name: "Wuhan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wuhu Wanli Airport",
    longitude: 118.408997,
    latitude: 31.3906,
    id: "arp_whu_cn",
    icao_code: "ZSWU",
    iata_country_code: "CN",
    iata_code: "WHU",
    city_name: "Wuhu",
    city: null,
  },
  {
    time_zone: "America/Toronto",
    name: "Wunnumin Lake Airport",
    longitude: -89.289485,
    latitude: 52.894173,
    id: "arp_wnn_ca",
    icao_code: "CKL3",
    iata_country_code: "CA",
    iata_code: "WNN",
    city_name: "Wunnumin Lake",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Wuvulu Island Airport",
    longitude: 142.836645,
    latitude: -1.736717,
    id: "arp_wuv_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "WUV",
    city_name: "Wuvulu Island",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Wuzhou Xijiang Airport",
    longitude: 111.252473,
    latitude: 23.455291,
    id: "arp_wuz_cn",
    icao_code: "ZGWZ",
    iata_country_code: "CN",
    iata_code: "WUZ",
    city_name: "Wuzhou",
    city: null,
  },
  {
    time_zone: "Europe/London",
    name: "Wycombe Air Park",
    longitude: -0.806389,
    latitude: 51.611327,
    id: "arp_hyc_gb",
    icao_code: "EGTB",
    iata_country_code: "GB",
    iata_code: "HYC",
    city_name: "High Wycombe",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Wyk auf Föhr Airport",
    longitude: 8.529454,
    latitude: 54.683935,
    id: "arp_ohr_de",
    icao_code: "EDXY",
    iata_country_code: "DE",
    iata_code: "OHR",
    city_name: "Wyk auf Föhr",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Wyndham Airport",
    longitude: 128.151684,
    latitude: -15.509568,
    id: "arp_wyn_au",
    icao_code: "YWYM",
    iata_country_code: "AU",
    iata_code: "WYN",
    city_name: "Wyndham",
    city: null,
  },
  {
    time_zone: "Africa/Maputo",
    name: "Xai-Xai Airport",
    longitude: 33.627399,
    latitude: -25.038432,
    id: "arp_vjb_mz",
    icao_code: "FQXA",
    iata_country_code: "MZ",
    iata_code: "VJB",
    city_name: "Xai-Xai",
    city: null,
  },
  {
    time_zone: "Africa/Luanda",
    name: "Xangongo Airport",
    longitude: 14.964869,
    latitude: -16.756724,
    id: "arp_xgn_ao",
    icao_code: "FNXA",
    iata_country_code: "AO",
    iata_code: "XGN",
    city_name: "Xangongo",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Xanxerê Airport",
    longitude: -52.372673,
    latitude: -26.876077,
    id: "arp_axe_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "AXE",
    city_name: "Xanxere",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xiamen Gaoqi International Airport",
    longitude: 118.130549,
    latitude: 24.540424,
    id: "arp_xmn_cn",
    icao_code: "ZSAM",
    iata_country_code: "CN",
    iata_code: "XMN",
    city_name: "Xiamen",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xiangyang Liuji Airport",
    longitude: 112.292009,
    latitude: 32.150045,
    id: "arp_xfn_cn",
    icao_code: "ZHXF",
    iata_country_code: "CN",
    iata_code: "XFN",
    city_name: "Xiangyang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xi'an Xianyang International Airport",
    longitude: 108.758129,
    latitude: 34.435531,
    id: "arp_xiy_cn",
    icao_code: "ZLXY",
    iata_country_code: "CN",
    iata_code: "XIY",
    city_name: "Xi'an",
    city: {
      name: "Xi'an",
      id: "cit_sia_cn",
      iata_country_code: "CN",
      iata_code: "SIA",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xi'an Xiguan Airport",
    longitude: 108.756299,
    latitude: 34.437412,
    id: "arp_sia_cn",
    icao_code: "ZLSN",
    iata_country_code: "CN",
    iata_code: "SIA",
    city_name: "Xi'an",
    city: {
      name: "Xi'an",
      id: "cit_sia_cn",
      iata_country_code: "CN",
      iata_code: "SIA",
    },
  },
  {
    time_zone: "Africa/Algiers",
    name: "Xichang Qingshan Airport",
    longitude: 2.186174,
    latitude: 27.978949,
    id: "arp_xic_cn",
    icao_code: "ZUXC",
    iata_country_code: "CN",
    iata_code: "XIC",
    city_name: "Xichang",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Xieng Khouang Airport",
    longitude: 103.160966,
    latitude: 19.449323,
    id: "arp_xkh_la",
    icao_code: "VLXK",
    iata_country_code: "LA",
    iata_code: "XKH",
    city_name: "Phonsavan",
    city: null,
  },
  {
    time_zone: "Asia/Vientiane",
    name: "Xienglom Airport",
    longitude: 100.815257,
    latitude: 19.620189,
    id: "arp_xie_la",
    icao_code: null,
    iata_country_code: "LA",
    iata_code: "XIE",
    city_name: "Xienglom",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xilinhot Airport",
    longitude: 115.965574,
    latitude: 43.91759,
    id: "arp_xil_cn",
    icao_code: "ZBXH",
    iata_country_code: "CN",
    iata_code: "XIL",
    city_name: "Xilinhot",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xinbarag Youqi Baogede Airport",
    longitude: 116.939382,
    latitude: 48.575585,
    id: "arp_xrq_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "XRQ",
    city_name: "Hulunbuir",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xingcheng Airport",
    longitude: 120.697998,
    latitude: 40.580299,
    id: "arp_xen_cn",
    icao_code: "ZYXC",
    iata_country_code: "CN",
    iata_code: "XEN",
    city_name: "Xingcheng",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xingning Airport",
    longitude: 116.101531,
    latitude: 24.267851,
    id: "arp_xin_cn",
    icao_code: "ZGXN",
    iata_country_code: "CN",
    iata_code: "XIN",
    city_name: "Xingning",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xingtai Dalian Airport",
    longitude: 114.428759,
    latitude: 36.883456,
    id: "arp_xnt_cn",
    icao_code: "ZBXT",
    iata_country_code: "CN",
    iata_code: "XNT",
    city_name: "Xingtai",
    city: null,
  },
  {
    time_zone: "America/Belem",
    name: "Xinguara Municipal Airport",
    longitude: -49.97574,
    latitude: -7.091347,
    id: "arp_xig_br",
    icao_code: null,
    iata_country_code: "BR",
    iata_code: "XIG",
    city_name: "Xinguara",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xingyi Wanfenglin Airport",
    longitude: 104.959235,
    latitude: 25.086667,
    id: "arp_acx_cn",
    icao_code: "ZUYI",
    iata_country_code: "CN",
    iata_code: "ACX",
    city_name: "Xingyi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xining Caojiabao International Airport",
    longitude: 102.041944,
    latitude: 36.527711,
    id: "arp_xnn_cn",
    icao_code: "ZLXN",
    iata_country_code: "CN",
    iata_code: "XNN",
    city_name: "Xining",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xinyang Minggang Airport",
    longitude: 114.075587,
    latitude: 32.541905,
    id: "arp_xai_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "XAI",
    city_name: "Xinyang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xinyuan Nalati Airport",
    longitude: 83.3786,
    latitude: 43.4318,
    id: "arp_nlt_cn",
    icao_code: "ZWNL",
    iata_country_code: "CN",
    iata_code: "NLT",
    city_name: "Xinyuan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xinzhou Wutaishan Airport",
    longitude: 112.969173,
    latitude: 38.597456,
    id: "arp_wut_cn",
    icao_code: "ZBXZ",
    iata_country_code: "CN",
    iata_code: "WUT",
    city_name: "Xinzhou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xishuangbanna Gasa Airport",
    longitude: 100.762353,
    latitude: 21.972465,
    id: "arp_jhg_cn",
    icao_code: "ZPJH",
    iata_country_code: "CN",
    iata_code: "JHG",
    city_name: "Jinghong",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Xuzhou Guanyin Airport",
    longitude: 117.555278,
    latitude: 34.059056,
    id: "arp_xuz_cn",
    icao_code: "ZSXZ",
    iata_country_code: "CN",
    iata_code: "XUZ",
    city_name: "Xuzhou",
    city: null,
  },
  {
    time_zone: "America/La_Paz",
    name: "Yacuiba Airport",
    longitude: -63.653017,
    latitude: -21.962462,
    id: "arp_byc_bo",
    icao_code: "SLYA",
    iata_country_code: "BO",
    iata_code: "BYC",
    city_name: "Yacuiba",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Yagoua Airport",
    longitude: 15.237173,
    latitude: 10.351912,
    id: "arp_gxx_cm",
    icao_code: "FKKJ",
    iata_country_code: "CM",
    iata_code: "GXX",
    city_name: "Yagoua",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Yaguara Airport",
    longitude: -75.295013,
    latitude: 2.947007,
    id: "arp_ayg_co",
    icao_code: "SKYA",
    iata_country_code: "CO",
    iata_code: "AYG",
    city_name: "San Vicente Del Caguán",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Yakataga Airport",
    longitude: -142.490385,
    latitude: 60.08068,
    id: "arp_cyt_us",
    icao_code: "PACY",
    iata_country_code: "US",
    iata_code: "CYT",
    city_name: "Yakataga",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Yakima Air Terminal Airport",
    longitude: -120.541942,
    latitude: 46.569188,
    id: "arp_ykm_us",
    icao_code: "KYKM",
    iata_country_code: "US",
    iata_code: "YKM",
    city_name: "Yakima",
    city: {
      name: "Yakima",
      id: "cit_ykm_us",
      iata_country_code: "US",
      iata_code: "YKM",
    },
  },
  {
    time_zone: "Africa/Lagos",
    name: "Yakubu Gowon Airport",
    longitude: 8.86905,
    latitude: 9.639829,
    id: "arp_jos_ng",
    icao_code: "DNJO",
    iata_country_code: "NG",
    iata_code: "JOS",
    city_name: "Jos",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Yakushima Airport",
    longitude: 130.659027,
    latitude: 30.385631,
    id: "arp_kum_jp",
    icao_code: "RJFC",
    iata_country_code: "JP",
    iata_code: "KUM",
    city_name: "Yakushima",
    city: null,
  },
  {
    time_zone: "America/Yakutat",
    name: "Yakutat Airport",
    longitude: -139.662092,
    latitude: 59.506426,
    id: "arp_yak_us",
    icao_code: "PAYA",
    iata_country_code: "US",
    iata_code: "YAK",
    city_name: "Yakutat",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Yakutsk Airport",
    longitude: 129.751504,
    latitude: 62.086755,
    id: "arp_yks_ru",
    icao_code: "UEEE",
    iata_country_code: "RU",
    iata_code: "YKS",
    city_name: "Yakutsk",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Yalata Mission Airport",
    longitude: 131.826546,
    latitude: -31.471358,
    id: "arp_kyi_au",
    icao_code: "YYTA",
    iata_country_code: "AU",
    iata_code: "KYI",
    city_name: "Yalata Mission",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Yalgoo Airport",
    longitude: 116.684957,
    latitude: -28.354717,
    id: "arp_ylg_au",
    icao_code: "YYAL",
    iata_country_code: "AU",
    iata_code: "YLG",
    city_name: "Yalgoo",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Yalinga Airport",
    longitude: 23.260237,
    latitude: 6.520694,
    id: "arp_aig_cf",
    icao_code: "FEFY",
    iata_country_code: "CF",
    iata_code: "AIG",
    city_name: "Yalinga",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yalumet Airport",
    longitude: 146.599635,
    latitude: -6.124636,
    id: "arp_kyx_pg",
    icao_code: "AYYE",
    iata_country_code: "PG",
    iata_code: "KYX",
    city_name: "Yalumet",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Yamagata Airport",
    longitude: 140.369793,
    latitude: 38.411591,
    id: "arp_gaj_jp",
    icao_code: "RJSC",
    iata_country_code: "JP",
    iata_code: "GAJ",
    city_name: "Yamagata",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Yamaguchi Ube Airport",
    longitude: 131.277536,
    latitude: 33.930891,
    id: "arp_ubj_jp",
    icao_code: "RJDC",
    iata_country_code: "JP",
    iata_code: "UBJ",
    city_name: "Ube",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Yam Island Airport",
    longitude: 142.776012,
    latitude: -9.90052,
    id: "arp_xmy_au",
    icao_code: "YYMI",
    iata_country_code: "AU",
    iata_code: "XMY",
    city_name: "Yam Island",
    city: null,
  },
  {
    time_zone: "Africa/Abidjan",
    name: "Yamoussoukro Airport",
    longitude: -5.364506,
    latitude: 6.903651,
    id: "arp_ask_ci",
    icao_code: "DIYO",
    iata_country_code: "CI",
    iata_code: "ASK",
    city_name: "Yamoussoukro",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Yampa Valley Airport",
    longitude: -107.217308,
    latitude: 40.481302,
    id: "arp_hdn_us",
    icao_code: "KHDN",
    iata_country_code: "US",
    iata_code: "HDN",
    city_name: "Hayden",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yan'an Ershilipu Airport",
    longitude: 109.553203,
    latitude: 36.637459,
    id: "arp_eny_cn",
    icao_code: "ZLYA",
    iata_country_code: "CN",
    iata_code: "ENY",
    city_name: "Yan'an",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Yanbu Airport",
    longitude: 38.063193,
    latitude: 24.146993,
    id: "arp_ynb_sa",
    icao_code: "OEYN",
    iata_country_code: "SA",
    iata_code: "YNB",
    city_name: "Yanbu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yancheng Nanyang International Airport",
    longitude: 120.205885,
    latitude: 33.427703,
    id: "arp_ynz_cn",
    icao_code: "ZSYN",
    iata_country_code: "CN",
    iata_code: "YNZ",
    city_name: "Yancheng",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Yandicoogina Airport",
    longitude: 119.165623,
    latitude: -22.673074,
    id: "arp_ynn_au",
    icao_code: "LFGO",
    iata_country_code: "AU",
    iata_code: "YNN",
    city_name: "Yandicoogina",
    city: null,
  },
  {
    time_zone: "Pacific/Guadalcanal",
    name: "Yandina Airport",
    longitude: 159.21841,
    latitude: -9.092816,
    id: "arp_xya_sb",
    icao_code: "AGGY",
    iata_country_code: "SB",
    iata_code: "XYA",
    city_name: "Yandina",
    city: null,
  },
  {
    time_zone: "Africa/Lubumbashi",
    name: "Yangambi Airport",
    longitude: 24.45,
    latitude: 0.8,
    id: "arp_yan_cd",
    icao_code: "FZIR",
    iata_country_code: "CD",
    iata_code: "YAN",
    city_name: "Yangambi",
    city: null,
  },
  {
    time_zone: "Asia/Yangon",
    name: "Yangon International Airport",
    longitude: 96.134209,
    latitude: 16.906757,
    id: "arp_rgn_mm",
    icao_code: "VYYY",
    iata_country_code: "MM",
    iata_code: "RGN",
    city_name: "Yangon",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Yangyang International Airport",
    longitude: 128.665982,
    latitude: 38.060055,
    id: "arp_yny_kr",
    icao_code: "RKNY",
    iata_country_code: "KR",
    iata_code: "YNY",
    city_name: "Yangyang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yangzhou Taizhou Airport",
    longitude: 119.717755,
    latitude: 32.563132,
    id: "arp_yty_cn",
    icao_code: "ZSYA",
    iata_country_code: "CN",
    iata_code: "YTY",
    city_name: "Yangzhou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yanji Chaoyangchuan Airport",
    longitude: 129.44883,
    latitude: 42.885404,
    id: "arp_ynj_cn",
    icao_code: "ZYYJ",
    iata_country_code: "CN",
    iata_code: "YNJ",
    city_name: "Yanji",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yankisa Airport",
    longitude: 143.91808,
    latitude: -5.110331,
    id: "arp_yeq_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "YEQ",
    city_name: "Yankisa",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yantai Penglai International Airport",
    longitude: 121.37189,
    latitude: 37.401688,
    id: "arp_ynt_cn",
    icao_code: "ZSYT",
    iata_country_code: "CN",
    iata_code: "YNT",
    city_name: "Yantai",
    city: null,
  },
  {
    time_zone: "Africa/Douala",
    name: "Yaoundé Airport",
    longitude: 11.548562,
    latitude: 3.714542,
    id: "arp_yao_cm",
    icao_code: "FKKY",
    iata_country_code: "CM",
    iata_code: "YAO",
    city_name: "Yaoundé",
    city: {
      name: "Yaounde",
      id: "cit_yao_cm",
      iata_country_code: "CM",
      iata_code: "YAO",
    },
  },
  {
    time_zone: "Africa/Douala",
    name: "Yaoundé Nsimalen International Airport",
    longitude: 11.548761,
    latitude: 3.714536,
    id: "arp_nsi_cm",
    icao_code: "FKYS",
    iata_country_code: "CM",
    iata_code: "NSI",
    city_name: "Yaoundé",
    city: {
      name: "Yaounde",
      id: "cit_yao_cm",
      iata_country_code: "CM",
      iata_code: "YAO",
    },
  },
  {
    time_zone: "Pacific/Chuuk",
    name: "Yap International Airport",
    longitude: 138.083792,
    latitude: 9.498706,
    id: "arp_yap_fm",
    icao_code: "PTYA",
    iata_country_code: "FM",
    iata_code: "YAP",
    city_name: "Yap",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yapsiei Airport",
    longitude: 141.093813,
    latitude: -4.628034,
    id: "arp_kpe_pg",
    icao_code: "AYYP",
    iata_country_code: "PG",
    iata_code: "KPE",
    city_name: "Yapsiei",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Yari Airport",
    longitude: -72.2667,
    latitude: -0.38333,
    id: "arp_ayi_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "AYI",
    city_name: "Yari",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Yariguíes Airport",
    longitude: -73.80702,
    latitude: 7.024581,
    id: "arp_eja_co",
    icao_code: "SKEJ",
    iata_country_code: "CO",
    iata_code: "EJA",
    city_name: "Barrancabermeja",
    city: null,
  },
  {
    time_zone: "America/Halifax",
    name: "Yarmouth Airport",
    longitude: -66.087683,
    latitude: 43.828407,
    id: "arp_yqi_ca",
    icao_code: "CYQI",
    iata_country_code: "CA",
    iata_code: "YQI",
    city_name: "Yarmouth",
    city: null,
  },
  {
    time_zone: "Pacific/Fiji",
    name: "Yasawa Island Airport",
    longitude: 177.545202,
    latitude: -16.759018,
    id: "arp_yas_fj",
    icao_code: "NFSW",
    iata_country_code: "FJ",
    iata_code: "YAS",
    city_name: "Yasawa Island",
    city: null,
  },
  {
    time_zone: "Asia/Dubai",
    name: "Yas Island Seaplane Base Airport",
    longitude: 54.610182,
    latitude: 24.464725,
    id: "arp_aym_ae",
    icao_code: null,
    iata_country_code: "AE",
    iata_code: "AYM",
    city_name: "Abu Dhabi",
    city: {
      name: "Abu Dhabi",
      id: "cit_auh_ae",
      iata_country_code: "AE",
      iata_code: "AUH",
    },
  },
  {
    time_zone: "Asia/Gaza",
    name: "Yasser Arafat International Airport",
    longitude: 34.2761,
    latitude: 31.246401,
    id: "arp_gza_ps",
    icao_code: "LVGZ",
    iata_country_code: "PS",
    iata_code: "GZA",
    city_name: "Gaza",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Yasuj Airport",
    longitude: 51.548931,
    latitude: 30.700137,
    id: "arp_yes_ir",
    icao_code: "OISY",
    iata_country_code: "IR",
    iata_code: "YES",
    city_name: "Yes Bay",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yasuru Airport",
    longitude: 146.182316,
    latitude: -6.6015,
    id: "arp_ksx_pg",
    icao_code: "AYYR",
    iata_country_code: "PG",
    iata_code: "KSX",
    city_name: "Yasuru",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Yavarate Airport",
    longitude: -69.207513,
    latitude: 0.612526,
    id: "arp_vab_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "VAB",
    city_name: "Yavarate",
    city: null,
  },
  {
    time_zone: "America/Panama",
    name: "Yaviza Airport",
    longitude: -77.687393,
    latitude: 8.15281,
    id: "arp_pyv_pa",
    icao_code: null,
    iata_country_code: "PA",
    iata_code: "PYV",
    city_name: "Yaviza",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Yeager Airport",
    longitude: -81.594149,
    latitude: 38.372033,
    id: "arp_crw_us",
    icao_code: "KCRW",
    iata_country_code: "US",
    iata_code: "CRW",
    city_name: "Charleston",
    city: null,
  },
  {
    time_zone: "Asia/Rangoon",
    name: "Ye Airport",
    longitude: 97.866997,
    latitude: 15.3,
    id: "arp_xye_mm",
    icao_code: "VYYE",
    iata_country_code: "MM",
    iata_code: "XYE",
    city_name: "Ye",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Yecheon Airbase Airport",
    longitude: 128.351473,
    latitude: 36.628582,
    id: "arp_yec_kr",
    icao_code: "RKTY",
    iata_country_code: "KR",
    iata_code: "YEC",
    city_name: "Yecheon",
    city: null,
  },
  {
    time_zone: "Asia/Vladivostok",
    name: "Yedinka Airport",
    longitude: 138.648333,
    latitude: 47.18,
    id: "arp_edn_ru",
    icao_code: null,
    iata_country_code: "RU",
    iata_code: "EDN",
    city_name: "Yedinka",
    city: null,
  },
  {
    time_zone: "Australia/Perth",
    name: "Yeelirrie Airport",
    longitude: 120.095901,
    latitude: -27.276669,
    id: "arp_kyf_au",
    icao_code: "YYLR",
    iata_country_code: "AU",
    iata_code: "KYF",
    city_name: "Yeelirrie",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yeerqiang Airport",
    longitude: 77.075192,
    latitude: 38.281055,
    id: "arp_qsz_cn",
    icao_code: "ZWSC",
    iata_country_code: "CN",
    iata_code: "QSZ",
    city_name: "Shache",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yegepa Airport",
    longitude: -2.901201,
    latitude: 58.954519,
    id: "arp_pge_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "PGE",
    city_name: "Yegepa",
    city: null,
  },
  {
    time_zone: "Africa/Bamako",
    name: "Yélimané Airport",
    longitude: -10.56918,
    latitude: 15.122299,
    id: "arp_eyl_ml",
    icao_code: "GAYE",
    iata_country_code: "ML",
    iata_code: "EYL",
    city_name: "Yélimané",
    city: null,
  },
  {
    time_zone: "America/Yellowknife",
    name: "Yellowknife Airport",
    longitude: -114.438958,
    latitude: 62.466814,
    id: "arp_yzf_ca",
    icao_code: "CYZF",
    iata_country_code: "CA",
    iata_code: "YZF",
    city_name: "Yellowknife",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Yellowstone Airport",
    longitude: -111.116659,
    latitude: 44.687719,
    id: "arp_wys_us",
    icao_code: "KWYS",
    iata_country_code: "US",
    iata_code: "WYS",
    city_name: "West Yellowstone",
    city: null,
  },
  {
    time_zone: "America/Denver",
    name: "Yellowstone Regional Airport",
    longitude: -109.02329,
    latitude: 44.52032,
    id: "arp_cod_us",
    icao_code: "KCOD",
    iata_country_code: "US",
    iata_code: "COD",
    city_name: "Cody",
    city: null,
  },
  {
    time_zone: "Africa/Freetown",
    name: "Yengema Airport",
    longitude: -11.046095,
    latitude: 8.612257,
    id: "arp_wye_sl",
    icao_code: "GFYE",
    iata_country_code: "SL",
    iata_code: "WYE",
    city_name: "Yengema",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Yenişehir Airport",
    longitude: 29.559716,
    latitude: 40.254897,
    id: "arp_yei_tr",
    icao_code: "LTBR",
    iata_country_code: "TR",
    iata_code: "YEI",
    city_name: "Bursa",
    city: null,
  },
  {
    time_zone: "Asia/Krasnoyarsk",
    name: "Yeniseysk Airport",
    longitude: 92.099043,
    latitude: 58.464422,
    id: "arp_eie_ru",
    icao_code: "UNII",
    iata_country_code: "RU",
    iata_code: "EIE",
    city_name: "Yeniseysk",
    city: null,
  },
  {
    time_zone: "Asia/Seoul",
    name: "Yeosu Airport",
    longitude: 127.615174,
    latitude: 34.841663,
    id: "arp_rsu_kr",
    icao_code: "RKJY",
    iata_country_code: "KR",
    iata_code: "RSU",
    city_name: "Yeosu",
    city: null,
  },
  {
    time_zone: "Asia/Irkutsk",
    name: "Yerbogachen Airport",
    longitude: 108.031881,
    latitude: 61.275553,
    id: "arp_erg_ru",
    icao_code: "UIKE",
    iata_country_code: "RU",
    iata_code: "ERG",
    city_name: "Yerbogachen",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Yerington Municipal Airport",
    longitude: -119.159016,
    latitude: 39.000855,
    id: "arp_eyr_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "EYR",
    city_name: "Yerington",
    city: null,
  },
  {
    time_zone: "America/Sitka",
    name: "Yes Bay Lodge Seaplane Base Airport",
    longitude: -131.71129,
    latitude: 55.355697,
    id: "arp_wyb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "WYB",
    city_name: "Yes Bay",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yeva Airport",
    longitude: 146.168191,
    latitude: -7.550394,
    id: "arp_yvd_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "YVD",
    city_name: "Yeva",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Yevlakh Airport",
    longitude: 47.141505,
    latitude: 40.6322,
    id: "arp_ylv_az",
    icao_code: "UBEE",
    iata_country_code: "AZ",
    iata_code: "YLV",
    city_name: "Yevlakh",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Yeysk Airport",
    longitude: 38.213619,
    latitude: 46.680403,
    id: "arp_eik_ru",
    icao_code: "URKE",
    iata_country_code: "RU",
    iata_code: "EIK",
    city_name: "Yeysk",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yibin Caiba Airport",
    longitude: 104.545,
    latitude: 28.800555,
    id: "arp_ybp_cn",
    icao_code: "ZUYB",
    iata_country_code: "CN",
    iata_code: "YBP",
    city_name: "Yibin",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yichang Sanxia Airport",
    longitude: 111.481026,
    latitude: 30.552783,
    id: "arp_yih_cn",
    icao_code: "ZHYC",
    iata_country_code: "CN",
    iata_code: "YIH",
    city_name: "Yichang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yichun Lindu Airport",
    longitude: 129.019125,
    latitude: 47.752056,
    id: "arp_lds_cn",
    icao_code: "ZYLD",
    iata_country_code: "CN",
    iata_code: "LDS",
    city_name: "Yichun",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yichun Mingyueshan Airport",
    longitude: 114.306833,
    latitude: 27.802766,
    id: "arp_yic_cn",
    icao_code: "ZSYC",
    iata_country_code: "CN",
    iata_code: "YIC",
    city_name: "Yichun",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yilan Airport",
    longitude: 129.563531,
    latitude: 46.323521,
    id: "arp_yln_cn",
    icao_code: "ZYYL",
    iata_country_code: "CN",
    iata_code: "YLN",
    city_name: "Yilan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yinchuan Hedong International Airport",
    longitude: 106.390127,
    latitude: 38.322669,
    id: "arp_inc_cn",
    icao_code: "ZLIC",
    iata_country_code: "CN",
    iata_code: "INC",
    city_name: "Yinchuan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yinchuan Helanshan Airport",
    longitude: 106.00897,
    latitude: 38.48262,
    id: "arp_yeh_ir",
    icao_code: null,
    iata_country_code: "IR",
    iata_code: "YEH",
    city_name: "Yinchuan/Yueyahu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yingkou Lanqi Airport",
    longitude: 122.3586,
    latitude: 40.542524,
    id: "arp_ykh_cn",
    icao_code: "ZYYK",
    iata_country_code: "CN",
    iata_code: "YKH",
    city_name: "Yingkou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yining Airport",
    longitude: 81.331771,
    latitude: 43.954496,
    id: "arp_yin_cn",
    icao_code: "ZWYN",
    iata_country_code: "CN",
    iata_code: "YIN",
    city_name: "Yining",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yiwu Airport",
    longitude: 120.03259,
    latitude: 29.342608,
    id: "arp_yiw_cn",
    icao_code: "ZSYW",
    iata_country_code: "CN",
    iata_code: "YIW",
    city_name: "Yiwu",
    city: null,
  },
  {
    time_zone: "Europe/Helsinki",
    name: "Ylivieska Airfield",
    longitude: 24.723968,
    latitude: 64.054718,
    id: "arp_yli_fi",
    icao_code: "EFYL",
    iata_country_code: "FI",
    iata_code: "YLI",
    city_name: "Ylivieska",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Yogyakarta International Airport",
    longitude: 110.43188,
    latitude: -7.787932,
    id: "arp_jog_id",
    icao_code: "WAHI",
    iata_country_code: "ID",
    iata_code: "JOG",
    city_name: "Yogyakarta",
    city: null,
  },
  {
    time_zone: "Asia/Jakarta",
    name: "Yogyakarta International Airport",
    longitude: 110.054252,
    latitude: -7.898232,
    id: "arp_yia_id",
    icao_code: "WAHI",
    iata_country_code: "ID",
    iata_code: "YIA",
    city_name: "Yogyakarta",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Yokangassi Airport",
    longitude: 13.286695,
    latitude: -4.2225,
    id: "arp_nky_cg",
    icao_code: "FCBY",
    iata_country_code: "CG",
    iata_code: "NKY",
    city_name: "Nkayi",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Yokota Air Base",
    longitude: 139.348007,
    latitude: 35.748501,
    id: "arp_oko_jp",
    icao_code: "RJTY",
    iata_country_code: "JP",
    iata_code: "OKO",
    city_name: "Fussa",
    city: {
      name: "Tokyo",
      id: "cit_tyo_jp",
      iata_country_code: "JP",
      iata_code: "TYO",
    },
  },
  {
    time_zone: "Africa/Lagos",
    name: "Yola Airport",
    longitude: 12.429724,
    latitude: 9.262098,
    id: "arp_yol_ng",
    icao_code: "DNYO",
    iata_country_code: "NG",
    iata_code: "YOL",
    city_name: "Yola",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Yonaguni Airport",
    longitude: 122.978036,
    latitude: 24.465214,
    id: "arp_ogn_jp",
    icao_code: "ROYN",
    iata_country_code: "JP",
    iata_code: "OGN",
    city_name: "Yonaguni",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Yongai Airport",
    longitude: 147.4603,
    latitude: -8.5289,
    id: "arp_kgh_pg",
    icao_code: "AYJO",
    iata_country_code: "PG",
    iata_code: "KGH",
    city_name: "Yongai",
    city: null,
  },
  {
    time_zone: "Asia/Thimphu",
    name: "Yongphulla Airport",
    longitude: 91.514855,
    latitude: 27.256111,
    id: "arp_yon_bt",
    icao_code: "VQTY",
    iata_country_code: "BT",
    iata_code: "YON",
    city_name: "Trashigang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yongzhou Lingling Airport",
    longitude: 111.614291,
    latitude: 26.340038,
    id: "arp_llf_cn",
    icao_code: "ZGLG",
    iata_country_code: "CN",
    iata_code: "LLF",
    city_name: "Yongzhou",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "York Airport",
    longitude: -76.87528,
    latitude: 39.920136,
    id: "arp_thv_us",
    icao_code: "KTHV",
    iata_country_code: "US",
    iata_code: "THV",
    city_name: "York",
    city: null,
  },
  {
    time_zone: "Australia/Brisbane",
    name: "Yorke Island Airport",
    longitude: 143.405506,
    latitude: -9.752238,
    id: "arp_okr_au",
    icao_code: "YYKI",
    iata_country_code: "AU",
    iata_code: "OKR",
    city_name: "Yorke Island",
    city: null,
  },
  {
    time_zone: "Australia/Adelaide",
    name: "Yorketown Airport",
    longitude: 137.618076,
    latitude: -35.003405,
    id: "arp_orr_au",
    icao_code: "YYOR",
    iata_country_code: "AU",
    iata_code: "ORR",
    city_name: "Yorketown",
    city: null,
  },
  {
    time_zone: "America/Winnipeg",
    name: "York Landing Airport",
    longitude: -96.093186,
    latitude: 56.08931,
    id: "arp_zac_ca",
    icao_code: "CZAC",
    iata_country_code: "CA",
    iata_code: "ZAC",
    city_name: "York Landing",
    city: null,
  },
  {
    time_zone: "America/Regina",
    name: "Yorkton Municipal Airport",
    longitude: -102.461728,
    latitude: 51.263121,
    id: "arp_yqv_ca",
    icao_code: "CYQV",
    iata_country_code: "CA",
    iata_code: "YQV",
    city_name: "Yorkton",
    city: null,
  },
  {
    time_zone: "America/Tegucigalpa",
    name: "Yoro Airport",
    longitude: -87.13489,
    latitude: 15.127488,
    id: "arp_oro_hn",
    icao_code: "MHYR",
    iata_country_code: "HN",
    iata_code: "ORO",
    city_name: "Yoro",
    city: null,
  },
  {
    time_zone: "Asia/Tokyo",
    name: "Yoron Airport",
    longitude: 128.401833,
    latitude: 27.043833,
    id: "arp_rnj_jp",
    icao_code: "RORY",
    iata_country_code: "JP",
    iata_code: "RNJ",
    city_name: "Yoron",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Yoshkar-Ola Airport",
    longitude: 47.905797,
    latitude: 56.700446,
    id: "arp_jok_ru",
    icao_code: "UWKJ",
    iata_country_code: "RU",
    iata_code: "JOK",
    city_name: "Yoshkar-Ola",
    city: null,
  },
  {
    time_zone: "Asia/Jerusalem",
    name: "Yotvata Airfield",
    longitude: 35.067701,
    latitude: 29.898596,
    id: "arp_yot_il",
    icao_code: "LLYO",
    iata_country_code: "IL",
    iata_code: "YOT",
    city_name: "Yotvata",
    city: null,
  },
  {
    time_zone: "Australia/Sydney",
    name: "Young Airport",
    longitude: 148.249,
    latitude: -34.252799,
    id: "arp_nga_au",
    icao_code: "YYNG",
    iata_country_code: "AU",
    iata_code: "NGA",
    city_name: "Young",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Youngstown–Warren Regional Airport",
    longitude: -80.667618,
    latitude: 41.257111,
    id: "arp_yng_us",
    icao_code: "KYNG",
    iata_country_code: "US",
    iata_code: "YNG",
    city_name: "Youngstown",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yuanmou Airport",
    longitude: 101.882004,
    latitude: 25.737499,
    id: "arp_yua_cn",
    icao_code: "ZPYM",
    iata_country_code: "CN",
    iata_code: "YUA",
    city_name: "Yuanmou",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Yuba County Airport",
    longitude: -121.569488,
    latitude: 39.097699,
    id: "arp_myv_us",
    icao_code: "KMYV",
    iata_country_code: "US",
    iata_code: "MYV",
    city_name: "Marysville",
    city: {
      name: "Marysville",
      id: "cit_myv_us",
      iata_country_code: "US",
      iata_code: "MYV",
    },
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Yucca Airstrip",
    longitude: -116.038088,
    latitude: 36.94509,
    id: "arp_ucc_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "UCC",
    city_name: "Mercury",
    city: null,
  },
  {
    time_zone: "Australia/Darwin",
    name: "Yuendumu Airport",
    longitude: 131.784001,
    latitude: -22.255693,
    id: "arp_yue_au",
    icao_code: "YYND",
    iata_country_code: "AU",
    iata_code: "YUE",
    city_name: "Yuendumu",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yulin Yuyang Airport",
    longitude: 109.596479,
    latitude: 38.36086,
    id: "arp_uyn_cn",
    icao_code: "ZLYL",
    iata_country_code: "CN",
    iata_code: "UYN",
    city_name: "Yulin",
    city: null,
  },
  {
    time_zone: "America/Phoenix",
    name: "Yuma International Airport",
    longitude: -114.599101,
    latitude: 32.668163,
    id: "arp_yum_us",
    icao_code: "KNYL",
    iata_country_code: "US",
    iata_code: "YUM",
    city_name: "Yuma",
    city: {
      name: "Yuma",
      id: "cit_yum_us",
      iata_country_code: "US",
      iata_code: "YUM",
    },
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yuncheng Guangong Airport",
    longitude: 111.038519,
    latitude: 35.115097,
    id: "arp_ycu_cn",
    icao_code: "ZBYC",
    iata_country_code: "CN",
    iata_code: "YCU",
    city_name: "Yuncheng",
    city: null,
  },
  {
    time_zone: "Asia/Jayapura",
    name: "Yuruf Airport",
    longitude: 140.958,
    latitude: -3.6333,
    id: "arp_ruf_id",
    icao_code: "WAJE",
    iata_country_code: "ID",
    iata_code: "RUF",
    city_name: "Yuruf",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Yushu Batang Airport",
    longitude: 97.124893,
    latitude: 32.825649,
    id: "arp_yus_cn",
    icao_code: "ZLYS",
    iata_country_code: "CN",
    iata_code: "YUS",
    city_name: "Yushu",
    city: null,
  },
  {
    time_zone: "Asia/Ust-Nera",
    name: "Yuzhno-Kurilsk Mendeleyevo Airport",
    longitude: 145.684174,
    latitude: 43.960524,
    id: "arp_dee_ru",
    icao_code: "UHSM",
    iata_country_code: "RU",
    iata_code: "DEE",
    city_name: "Yuzhno-Kurilsk",
    city: null,
  },
  {
    time_zone: "Asia/Sakhalin",
    name: "Yuzhno-Sakhalinsk Airport",
    longitude: 142.72094,
    latitude: 46.887833,
    id: "arp_uus_ru",
    icao_code: "UHSS",
    iata_country_code: "RU",
    iata_code: "UUS",
    city_name: "Yuzhno-Sakhalinsk",
    city: null,
  },
  {
    time_zone: "Europe/Podgorica",
    name: "Žabljak Airport",
    longitude: 19.233334,
    latitude: 43.116669,
    id: "arp_zbk_me",
    icao_code: null,
    iata_country_code: "ME",
    iata_code: "ZBK",
    city_name: "Zabljak",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Zabol Airport",
    longitude: 61.543634,
    latitude: 31.08758,
    id: "arp_acz_ir",
    icao_code: "OIZB",
    iata_country_code: "IR",
    iata_code: "ACZ",
    city_name: "Zabol",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Zabrat Airport",
    longitude: 49.97472,
    latitude: 40.495968,
    id: "arp_zxt_az",
    icao_code: "UBTT",
    iata_country_code: "AZ",
    iata_code: "ZXT",
    city_name: "Baku",
    city: {
      name: "Baku",
      id: "cit_bak_az",
      iata_country_code: "AZ",
      iata_code: "BAK",
    },
  },
  {
    time_zone: "Africa/Ouagadougou",
    name: "Zabré Airport",
    longitude: -0.622362,
    latitude: 11.169181,
    id: "arp_xza_bf",
    icao_code: "DFEZ",
    iata_country_code: "BF",
    iata_code: "XZA",
    city_name: "Zabré",
    city: null,
  },
  {
    time_zone: "Europe/Prague",
    name: "Zabreh Ostrava Airport",
    longitude: 18.077678,
    latitude: 49.928375,
    id: "arp_zbe_cz",
    icao_code: "LKZA",
    iata_country_code: "CZ",
    iata_code: "ZBE",
    city_name: "Zabreh",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Zacatecas International Airport",
    longitude: -102.68429,
    latitude: 22.898759,
    id: "arp_zcl_mx",
    icao_code: "MMZC",
    iata_country_code: "MX",
    iata_code: "ZCL",
    city_name: "Zacatecas",
    city: null,
  },
  {
    time_zone: "America/Anchorage",
    name: "Zachar Bay Seaplane Base",
    longitude: -153.74667,
    latitude: 57.55323,
    id: "arp_kzb_us",
    icao_code: null,
    iata_country_code: "US",
    iata_code: "KZB",
    city_name: "Zachar Bay",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Zadar Airport",
    longitude: 15.346689,
    latitude: 44.108218,
    id: "arp_zad_hr",
    icao_code: "LDZD",
    iata_country_code: "HR",
    iata_code: "ZAD",
    city_name: "Zadar",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Zafer Airport",
    longitude: 30.1282,
    latitude: 39.113113,
    id: "arp_kzr_tr",
    icao_code: "LTBZ",
    iata_country_code: "TR",
    iata_code: "KZR",
    city_name: "Kutahya",
    city: null,
  },
  {
    time_zone: "Africa/Casablanca",
    name: "Zagora Airport",
    longitude: -5.852902,
    latitude: 30.266589,
    id: "arp_ozg_ma",
    icao_code: "GMAZ",
    iata_country_code: "MA",
    iata_code: "OZG",
    city_name: "Zagora",
    city: null,
  },
  {
    time_zone: "Europe/Zagreb",
    name: "Zagreb Airport",
    longitude: 16.068118,
    latitude: 45.741825,
    id: "arp_zag_hr",
    icao_code: "LDZA",
    iata_country_code: "HR",
    iata_code: "ZAG",
    city_name: "Zagreb",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Zahedan International Airport",
    longitude: 60.903393,
    latitude: 29.475593,
    id: "arp_zah_ir",
    icao_code: "OIZH",
    iata_country_code: "IR",
    iata_code: "ZAH",
    city_name: "Zahedan",
    city: null,
  },
  {
    time_zone: "Africa/Ndjamena",
    name: "Zakouma Airport",
    longitude: 19.817124,
    latitude: 10.890349,
    id: "arp_akm_td",
    icao_code: null,
    iata_country_code: "TD",
    iata_code: "AKM",
    city_name: "Zakouma",
    city: null,
  },
  {
    time_zone: "Europe/Athens",
    name: "Zakynthos International Airport",
    longitude: 20.885506,
    latitude: 37.752671,
    id: "arp_zth_gr",
    icao_code: "LGZA",
    iata_country_code: "GR",
    iata_code: "ZTH",
    city_name: "Zakynthos",
    city: null,
  },
  {
    time_zone: "Africa/Khartoum",
    name: "Zalingei Airport",
    longitude: 23.563463,
    latitude: 12.94388,
    id: "arp_zlx_sd",
    icao_code: "HSZA",
    iata_country_code: "SD",
    iata_code: "ZLX",
    city_name: "Zalengei",
    city: null,
  },
  {
    time_zone: "Africa/Lusaka",
    name: "Zambezi Airport",
    longitude: 23.109323,
    latitude: -13.537234,
    id: "arp_bbz_zm",
    icao_code: "FLZB",
    iata_country_code: "ZM",
    iata_code: "BBZ",
    city_name: "Zambezi",
    city: null,
  },
  {
    time_zone: "Asia/Manila",
    name: "Zamboanga International Airport",
    longitude: 122.060271,
    latitude: 6.921736,
    id: "arp_zam_ph",
    icao_code: "RPMZ",
    iata_country_code: "PH",
    iata_code: "ZAM",
    city_name: "Zamboanga City",
    city: null,
  },
  {
    time_zone: "America/Mexico_City",
    name: "Zamora Airport",
    longitude: -102.276001,
    latitude: 20.045,
    id: "arp_zmm_mx",
    icao_code: "MMZM",
    iata_country_code: "MX",
    iata_code: "ZMM",
    city_name: "Zamora",
    city: null,
  },
  {
    time_zone: "America/Los_Angeles",
    name: "Zamperini Field",
    longitude: -118.340107,
    latitude: 33.802684,
    id: "arp_toa_us",
    icao_code: "KTOA",
    iata_country_code: "US",
    iata_code: "TOA",
    city_name: "Torrance",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Zamzama Heliport",
    longitude: 67.66725,
    latitude: 26.710944,
    id: "arp_ziz_pk",
    icao_code: null,
    iata_country_code: "PK",
    iata_code: "ZIZ",
    city_name: "Zamzama",
    city: null,
  },
  {
    time_zone: "Africa/Brazzaville",
    name: "Zanaga Airport",
    longitude: 13.821754,
    latitude: -2.846422,
    id: "arp_anj_cg",
    icao_code: "FCBZ",
    iata_country_code: "CG",
    iata_code: "ANJ",
    city_name: "Zanaga",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Zanesville Municipal Airport",
    longitude: -81.892147,
    latitude: 39.9444,
    id: "arp_zzv_us",
    icao_code: "KZZV",
    iata_country_code: "US",
    iata_code: "ZZV",
    city_name: "Zanesville",
    city: null,
  },
  {
    time_zone: "Asia/Tehran",
    name: "Zanjan Airport",
    longitude: 48.359402,
    latitude: 36.773701,
    id: "arp_jwn_ir",
    icao_code: "OITZ",
    iata_country_code: "IR",
    iata_code: "JWN",
    city_name: "Zanjan",
    city: null,
  },
  {
    time_zone: "America/Argentina/Salta",
    name: "Zapala Airport",
    longitude: -70.11112,
    latitude: -38.975838,
    id: "arp_apz_ar",
    icao_code: "SAHZ",
    iata_country_code: "AR",
    iata_code: "APZ",
    city_name: "Zapala",
    city: null,
  },
  {
    time_zone: "America/Bogota",
    name: "Zapatoca Airport",
    longitude: -73.2833,
    latitude: 6.81667,
    id: "arp_azt_co",
    icao_code: null,
    iata_country_code: "CO",
    iata_code: "AZT",
    city_name: "Zapatoca",
    city: null,
  },
  {
    time_zone: "Europe/Zaporozhye",
    name: "Zaporizhia International Airport",
    longitude: 35.302243,
    latitude: 47.872551,
    id: "arp_ozh_ua",
    icao_code: "UKDE",
    iata_country_code: "UA",
    iata_code: "OZH",
    city_name: "Zaporizhia",
    city: null,
  },
  {
    time_zone: "Asia/Baku",
    name: "Zaqatala International Airport",
    longitude: 46.666621,
    latitude: 41.562087,
    id: "arp_ztu_az",
    icao_code: "UBBY",
    iata_country_code: "AZ",
    iata_code: "ZTU",
    city_name: "Zaqatala",
    city: null,
  },
  {
    time_zone: "Europe/Madrid",
    name: "Zaragoza Airport",
    longitude: -1.042449,
    latitude: 41.664563,
    id: "arp_zaz_es",
    icao_code: "LEZG",
    iata_country_code: "ES",
    iata_code: "ZAZ",
    city_name: "Zaragoza",
    city: null,
  },
  {
    time_zone: "Asia/Kabul",
    name: "Zaranj Airport",
    longitude: 61.865717,
    latitude: 30.972959,
    id: "arp_zaj_af",
    icao_code: "OAZJ",
    iata_country_code: "AF",
    iata_code: "ZAJ",
    city_name: "Zaranj",
    city: null,
  },
  {
    time_zone: "Africa/Lagos",
    name: "Zaria Airport",
    longitude: 7.685878,
    latitude: 11.131052,
    id: "arp_zar_ng",
    icao_code: "DNZA",
    iata_country_code: "NG",
    iata_code: "ZAR",
    city_name: "Zaria",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Zaysan Airport",
    longitude: 84.88628,
    latitude: 47.486618,
    id: "arp_szi_kz",
    icao_code: "UASZ",
    iata_country_code: "KZ",
    iata_code: "SZI",
    city_name: "Zaysan",
    city: null,
  },
  {
    time_zone: "Africa/Bangui",
    name: "Zemio Airport",
    longitude: 25.101863,
    latitude: 5.002239,
    id: "arp_imo_cf",
    icao_code: "FEFZ",
    iata_country_code: "CF",
    iata_code: "IMO",
    city_name: "Zemio",
    city: null,
  },
  {
    time_zone: "Pacific/Port_Moresby",
    name: "Zenag Airport",
    longitude: 146.616041,
    latitude: -6.951269,
    id: "arp_zen_pg",
    icao_code: null,
    iata_country_code: "PG",
    iata_code: "ZEN",
    city_name: "Zenag",
    city: null,
  },
  {
    time_zone: "Africa/Algiers",
    name: "Zenata - Messali El Hadj Airport",
    longitude: -1.448968,
    latitude: 35.014502,
    id: "arp_tlm_dz",
    icao_code: "DAON",
    iata_country_code: "DZ",
    iata_code: "TLM",
    city_name: "Tlemcen",
    city: null,
  },
  {
    time_zone: "America/New_York",
    name: "Zephyrhills Municipal Airport",
    longitude: -82.156043,
    latitude: 28.227473,
    id: "arp_zph_us",
    icao_code: "KZPH",
    iata_country_code: "US",
    iata_code: "ZPH",
    city_name: "Zephyrhills",
    city: null,
  },
  {
    time_zone: "Asia/Calcutta",
    name: "Zero Airport",
    longitude: 93.828175,
    latitude: 27.588317,
    id: "arp_zer_in",
    icao_code: "VEZO",
    iata_country_code: "IN",
    iata_code: "ZER",
    city_name: "Ziro",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhalantun Chengjisihan Airport",
    longitude: 122.767503,
    latitude: 47.865833,
    id: "arp_nzl_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "NZL",
    city_name: "Zhalantun",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhangjiajie Hehua International Airport",
    longitude: 110.447096,
    latitude: 29.103328,
    id: "arp_dyg_cn",
    icao_code: "ZGDY",
    iata_country_code: "CN",
    iata_code: "DYG",
    city_name: "Dayong",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhangjiakou Ningyuan Airport",
    longitude: 114.93,
    latitude: 40.738601,
    id: "arp_zqz_cn",
    icao_code: "ZBZJ",
    iata_country_code: "CN",
    iata_code: "ZQZ",
    city_name: "Zhangjiakou",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhangye Ganzhou Airport",
    longitude: 100.655277,
    latitude: 38.812903,
    id: "arp_yzy_cn",
    icao_code: "ZLZY",
    iata_country_code: "CN",
    iata_code: "YZY",
    city_name: "Zhangye",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhanjiang Airport",
    longitude: 110.359978,
    latitude: 21.214574,
    id: "arp_zha_cn",
    icao_code: "ZGZJ",
    iata_country_code: "CN",
    iata_code: "ZHA",
    city_name: "Zhanjiang",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhaotong Airport",
    longitude: 103.754814,
    latitude: 27.324994,
    id: "arp_zat_cn",
    icao_code: "ZPZT",
    iata_country_code: "CN",
    iata_code: "ZAT",
    city_name: "Zhaotong",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhengzhou Xinzheng International Airport",
    longitude: 113.843579,
    latitude: 34.52252,
    id: "arp_cgo_cn",
    icao_code: "ZHCC",
    iata_country_code: "CN",
    iata_code: "CGO",
    city_name: "Zhengzhou",
    city: null,
  },
  {
    time_zone: "Asia/Almaty",
    name: "Zhezkazgan Airport",
    longitude: 67.734198,
    latitude: 47.707625,
    id: "arp_dzn_kz",
    icao_code: "UAKD",
    iata_country_code: "KZ",
    iata_code: "DZN",
    city_name: "Zhezkazgan",
    city: null,
  },
  {
    time_zone: "Asia/Yakutsk",
    name: "Zhigansk Airport",
    longitude: 123.362114,
    latitude: 66.79392,
    id: "arp_zix_ru",
    icao_code: "UEVV",
    iata_country_code: "RU",
    iata_code: "ZIX",
    city_name: "Zhigansk",
    city: null,
  },
  {
    time_zone: "Asia/Karachi",
    name: "Zhob Airport",
    longitude: 69.46352,
    latitude: 31.357122,
    id: "arp_pzh_pk",
    icao_code: "OPZB",
    iata_country_code: "PK",
    iata_code: "PZH",
    city_name: "Zhob",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhongwei Shapotou Airport",
    longitude: 105.154835,
    latitude: 37.572307,
    id: "arp_zhy_cn",
    icao_code: "ZLZW",
    iata_country_code: "CN",
    iata_code: "ZHY",
    city_name: "Zhongwei",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhoushan Putuoshan Airport",
    longitude: 122.360762,
    latitude: 29.933341,
    id: "arp_hsn_cn",
    icao_code: "ZSZS",
    iata_country_code: "CN",
    iata_code: "HSN",
    city_name: "Zhoushan",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zhuhai Jinwan Airport",
    longitude: 113.377296,
    latitude: 22.007235,
    id: "arp_zuh_cn",
    icao_code: "ZGSD",
    iata_country_code: "CN",
    iata_code: "ZUH",
    city_name: "Zhuhai",
    city: null,
  },
  {
    time_zone: "Europe/Moscow",
    name: "Zhukovsky International Airport",
    longitude: 38.119057,
    latitude: 55.562065,
    id: "arp_zia_ru",
    icao_code: "UUBW",
    iata_country_code: "RU",
    iata_code: "ZIA",
    city_name: "Moscow",
    city: null,
  },
  {
    time_zone: "Europe/Kiev",
    name: "Zhytomyr Airport",
    longitude: 28.737273,
    latitude: 50.272544,
    id: "arp_ztr_ua",
    icao_code: "UKKV",
    iata_country_code: "UA",
    iata_code: "ZTR",
    city_name: "Zhytomyr",
    city: null,
  },
  {
    time_zone: "Europe/Warsaw",
    name: "Zielona Gora Babimost Airport",
    longitude: 15.778548,
    latitude: 52.137021,
    id: "arp_ieg_pl",
    icao_code: "EPZG",
    iata_country_code: "PL",
    iata_code: "IEG",
    city_name: "Zielona Góra",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zigong Fengming General Airport",
    longitude: 104.62608,
    latitude: 29.37785,
    id: "arp_zkl_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "ZKL",
    city_name: "Zigong",
    city: null,
  },
  {
    time_zone: "Africa/Dakar",
    name: "Ziguinchor Airport",
    longitude: -16.281071,
    latitude: 12.556081,
    id: "arp_zig_sn",
    icao_code: "GOGG",
    iata_country_code: "SN",
    iata_code: "ZIG",
    city_name: "Ziguinchor",
    city: null,
  },
  {
    time_zone: "Asia/Riyadh",
    name: "Zilfi Airport",
    longitude: 44.833322,
    latitude: 26.349846,
    id: "arp_zul_sa",
    icao_code: "OEZL",
    iata_country_code: "SA",
    iata_code: "ZUL",
    city_name: "Zilfi",
    city: null,
  },
  {
    time_zone: "Europe/Bratislava",
    name: "Žilina Airport",
    longitude: 18.614436,
    latitude: 49.232235,
    id: "arp_ilz_sk",
    icao_code: "LZZI",
    iata_country_code: "SK",
    iata_code: "ILZ",
    city_name: "Žilina",
    city: null,
  },
  {
    time_zone: "Africa/Niamey",
    name: "Zinder Airport",
    longitude: 8.983581,
    latitude: 13.779078,
    id: "arp_znd_ne",
    icao_code: "DRZR",
    iata_country_code: "NE",
    iata_code: "ZND",
    city_name: "Zinder",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Zintan Airport",
    longitude: 12.250101,
    latitude: 31.775023,
    id: "arp_zis_ly",
    icao_code: "HLZN",
    iata_country_code: "LY",
    iata_code: "ZIS",
    city_name: "Zintan",
    city: null,
  },
  {
    time_zone: "America/Chicago",
    name: "Z.M. Jack Stell Field Airport",
    longitude: -91.880242,
    latitude: 33.178316,
    id: "arp_crt_us",
    icao_code: "KCRT",
    iata_country_code: "US",
    iata_code: "CRT",
    city_name: "Crossett",
    city: null,
  },
  {
    time_zone: "America/Sao_Paulo",
    name: "Zona da Mata Regional Airport",
    longitude: -43.172502,
    latitude: -21.513123,
    id: "arp_iza_br",
    icao_code: "SBZM",
    iata_country_code: "BR",
    iata_code: "IZA",
    city_name: "Juiz de Fora",
    city: null,
  },
  {
    time_zone: "Asia/Sakhalin",
    name: "Zonalnoye Airport",
    longitude: 142.756764,
    latitude: 50.669724,
    id: "arp_zzo_ru",
    icao_code: "UHSO",
    iata_country_code: "RU",
    iata_code: "ZZO",
    city_name: "Tymovskoye",
    city: null,
  },
  {
    time_zone: "Europe/Istanbul",
    name: "Zonguldak Airport",
    longitude: 32.089596,
    latitude: 41.506473,
    id: "arp_onq_tr",
    icao_code: "LTAS",
    iata_country_code: "TR",
    iata_code: "ONQ",
    city_name: "Çaycuma/Zonguldak",
    city: null,
  },
  {
    time_zone: "America/Paramaribo",
    name: "Zorg en Hoop Airport",
    longitude: -55.190927,
    latitude: 5.811213,
    id: "arp_org_sr",
    icao_code: "SMZO",
    iata_country_code: "SR",
    iata_code: "ORG",
    city_name: "Paramaribo",
    city: {
      name: "Paramaribo",
      id: "cit_pbm_sr",
      iata_country_code: "SR",
      iata_code: "PBM",
    },
  },
  {
    time_zone: "Africa/Johannesburg",
    name: "Zulu Inyala Airport",
    longitude: 32.3097,
    latitude: -27.8494,
    id: "arp_pzl_za",
    icao_code: "FADQ",
    iata_country_code: "ZA",
    iata_code: "PZL",
    city_name: "Phinda",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zunyi Maotai Airport",
    longitude: 106.33268,
    latitude: 27.81638,
    id: "arp_wmt_cn",
    icao_code: null,
    iata_country_code: "CN",
    iata_code: "WMT",
    city_name: "Zunyi",
    city: null,
  },
  {
    time_zone: "Asia/Shanghai",
    name: "Zunyi Xinzhou Airport",
    longitude: 107.248814,
    latitude: 27.803665,
    id: "arp_zyi_cn",
    icao_code: "ZUZY",
    iata_country_code: "CN",
    iata_code: "ZYI",
    city_name: "Zunyi",
    city: null,
  },
  {
    time_zone: "Europe/Zurich",
    name: "Zürich Airport",
    longitude: 8.552323,
    latitude: 47.461458,
    id: "arp_zrh_ch",
    icao_code: "LSZH",
    iata_country_code: "CH",
    iata_code: "ZRH",
    city_name: "Zürich",
    city: null,
  },
  {
    time_zone: "Africa/Tripoli",
    name: "Zuwarah Airport",
    longitude: 12.016208,
    latitude: 32.951735,
    id: "arp_wax_ly",
    icao_code: "HLZW",
    iata_country_code: "LY",
    iata_code: "WAX",
    city_name: "Zuwara",
    city: null,
  },
  {
    time_zone: "Asia/Yerevan",
    name: "Zvartnots International Airport",
    longitude: 44.397378,
    latitude: 40.148239,
    id: "arp_evn_am",
    icao_code: "UDYZ",
    iata_country_code: "AM",
    iata_code: "EVN",
    city_name: "Yerevan",
    city: null,
  },
  {
    time_zone: "Europe/Berlin",
    name: "Zweibrücken Airport",
    longitude: 7.400714,
    latitude: 49.210896,
    id: "arp_zqw_de",
    icao_code: "EDRZ",
    iata_country_code: "DE",
    iata_code: "ZQW",
    city_name: "Zweibrücken",
    city: {
      name: "Saarbruecken",
      id: "cit_scn_de",
      iata_country_code: "DE",
      iata_code: "SCN",
    },
  },
  {
    time_zone: "Asia/Srednekolymsk",
    name: "Zyryanka Airport",
    longitude: 150.897895,
    latitude: 65.740939,
    id: "arp_zkp_ru",
    icao_code: "UESU",
    iata_country_code: "RU",
    iata_code: "ZKP",
    city_name: "Zyryanka",
    city: null,
  },
  {
    time_zone: "Atlantic/Reykjavik",
    name: "Þingeyri Airport",
    longitude: -23.557293,
    latitude: 65.869743,
    id: "arp_tey_is",
    icao_code: "BITE",
    iata_country_code: "IS",
    iata_code: "TEY",
    city_name: "Þingeyri",
    city: null,
  },
];

export default airportData;
