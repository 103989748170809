import { useQuery } from "@apollo/client";
import { GET_USER_DETAIL } from "~/graphql/users/Query";

export default function useUserDetail(userId: any) {
  const { data, error } = useQuery(GET_USER_DETAIL, {
    skip: !userId, // Don't run the query unless brdId is set
    variables: { id: userId },
  });

  console.log("sdhfhdsf", error);

  return { users: data?.users };
}
