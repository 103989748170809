import { gql } from "@apollo/client";

export const GET_FLIGHT_PAGES = gql`
  query GetFlightPages($brd_id: uuid, $slug: String) {
    brd_flights_pages(
      where: { slug: { _eq: $slug }, brd_id: { _eq: $brd_id } }
    ) {
      id
    }
  }
`;
