import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  CAR_EDIT_INQUIRY,
  EDIT_INQUIRY,
  STAY_EDIT_INQUIRY,
  TRAIN_EDIT_INQUIRY,
} from "../../../../graphql/Mutation";
import { toast } from "react-toastify";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import useNotifications from "hooks/useNotifications";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import Unselect from "../../../../../../../../assets/img/Unselect.svg";
import UnselectDark from "../../../../../../../../assets/img/Unselect-dark.svg";

export default function NewSuggestionUnSelect({
  inquiry_id,
  suggestion_id,
  picked_by,
  user_id,
  inquiry,
  tab, // The selected tab
}: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  const emailSend = useEmail();
  const userName = useUserDisplayName();
  const userId = useUserId();

  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [editStaySuggestion] = useMutation(STAY_EDIT_INQUIRY);
  const [editCarSuggestion] = useMutation(CAR_EDIT_INQUIRY);
  const [editTrainSuggestion] = useMutation(TRAIN_EDIT_INQUIRY);
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Handle different mutation types dynamically based on the selected tab
  const handleConfirm = async (event: any) => {
    event.stopPropagation();
    setIsLoading(true);

    try {
      // Update the inquiry user before handling suggestion unselection
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      // Select the appropriate mutation based on the tab
      let mutation;
      let successMessage = ""; // For storing the success message
      let selectedId = suggestion_id; // Use the suggestion_id passed as a prop
      let dynamicId;
      if (tab === "Stay") {
        mutation = editStaySuggestion;
        successMessage = "Stay suggestion unselected successfully"; // Custom success message
        dynamicId = inquiry?.stay_inq_details[0]?.id;
      } else if (tab === "Cars") {
        mutation = editCarSuggestion;
        successMessage = "Car suggestion unselected successfully"; // Custom success message
        dynamicId = inquiry?.car_inq_details[0]?.id;
      } else if (tab === "Train") {
        mutation = editTrainSuggestion;
        successMessage = "Train suggestion unselected successfully"; // Custom success message
        dynamicId = inquiry?.train_inq_details[0]?.id;
      } else {
        mutation = editInquiry; // Default fallback
        successMessage = "Inquiry suggestion unselected successfully"; // Custom success message
        dynamicId = inquiry_id;
      }

      // Dynamically set the selected_suggestion_id variable
      const res = await mutation({
        variables: { id: dynamicId, selected_suggestion_id: null },
      });
      console.log("asdasnmasnd", res);

      // Check the response based on the mutation type
      let isSuccess = false;
      if (
        tab === "Stay" &&
        res.data?.update_stay_inq_detail?.returning?.length > 0
      ) {
        isSuccess = true;
      } else if (
        tab === "Cars" &&
        res.data?.update_car_inq_detail?.returning?.length > 0
      ) {
        isSuccess = true;
      } else if (
        tab === "Train" &&
        res.data?.update_train_inq_detail?.returning?.length > 0
      ) {
        isSuccess = true;
      } else if (res.data?.update_inq_list?.returning?.length > 0) {
        // Fallback for default inquiry
        isSuccess = true;
      }

      if (isSuccess) {
        toast.success(successMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        // Notify based on user role
        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has unselected fare options for you.`,
            "",
            ``
          );
        } else {
          const variables: any = {
            inquiryNo: inquiry?.inquiry_no,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userName,
            brandName: inquiry?.brd_list?.name,
          };

          // Send email notification to the user
          await emailSend(
            6,
            inquiry.brd_id,
            inquiry.users?.id,
            inquiry.users?.email,
            inquiry.users?.phoneNumber,
            variables
          );

          await alert.newNotification(
            user_id,
            `Your consultant has unselected fare options for you.`,
            "",
            ``
          );
        }
        await client.resetStore();
      }
      setModalOpen(false);
      setIsLoading(false);
    } catch (e) {
      console.error("error", e);
      setModalOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <img
        src={darkMode ? UnselectDark : Unselect}
        alt="Unselect Suggestion"
        title={`Unselect ${tab} Suggestion`} // Dynamic tooltip based on tab
        className="cursor-pointer"
        onClick={() => setModalOpen(true)}
      />

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white dark:bg-dark-primary rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700 dark:text-dark-primary">
              {`Are you sure you want to unselect this ${tab} suggestion?`}
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="px-5 py-1 font-semibold text-basic rounded-lg border border-basic"
              >
                No
              </button>
              <button
                onClick={handleConfirm}
                className="px-5 py-1 font-semibold text-white bg-basic rounded-lg"
              >
                {isLoading ? "Unselecting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
