import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UpdateSeasFaresTable from "./components/UpdateSeasFaresTable";
import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { DELETE_BRD_SEAS_FARES } from "~/graphql/brd_seasonal_fares/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

export default function SeasonalFaresTable({
  seasonalFares,
  reftechBrdSeasFares,
}: any) {
  const [openMod, setOpenMod] = useState<any>(false);

  const [deleteDefSeasFares] = useMutation(DELETE_BRD_SEAS_FARES);

  const deleteFunc = async (id: any) => {
    const res = await deleteDefSeasFares({
      variables: {
        id,
      },
    });

    if (res?.data?.delete_brd_seasonal_fares?.affected_rows) {
      toast.success("Season Deleted SuccessFully");
      reftechBrdSeasFares();
    }
  };

  return (
    <div>
      <h1 className="ml-2 mb-2 font-bold">SEASONAL FARES</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>SEASON</TableCell>
              <TableCell align="center">TYPE</TableCell>
              <TableCell align="center">STOPS</TableCell>
              <TableCell align="center">DEPARTURE-DESTINATION</TableCell>
              <TableCell align="center">AIRLINE</TableCell>
              <TableCell align="center">FARE</TableCell>
              <TableCell align="center">ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seasonalFares?.map((row: any, ind: any) => (
              <TableRow key={ind}>
                <TableCell>{row?.brd_season?.name}</TableCell>
                <TableCell align="center">
                  {row?.type === "oneWay" ? "One Way" : "Round Trip"}
                </TableCell>
                <TableCell align="center">{row?.stops}</TableCell>
                <TableCell align="center">
                  ({row?.departure}) - ({row?.destination})
                </TableCell>
                <TableCell align="center">{row?.airline}</TableCell>
                <TableCell align="center">£{row?.fares}</TableCell>
                <TableCell align="center">
                  <div className="flex items-center justify-center gap-5">
                    <Button
                      variant="outlined"
                      onClick={() => setOpenMod(row.id)}
                    >
                      EDIT
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        deleteFunc(row.id);
                      }}
                    >
                      DELETE
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <UpdateSeasFaresTable
            setOpenMod={setOpenMod}
            id={openMod}
            refetch={reftechBrdSeasFares}
          />
        </Box>
      </Modal>
    </div>
  );
}
