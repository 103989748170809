import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_ACTIVE_MEMBERS_WITH_THP } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import UserAdd from "components/UserCard/components/UserAdd";
import SidePopup from "~/components/common/SidePopup";
import UsersGroup from "./components/UsersGroup";
import CustomerTable from "../../../CustomerList/component/table";

export default function TravelHouseWise() {
  const { travelHouse }: any = travelHouseStore();
  // get data based on query
  const { data, error, loading } = useQuery(GET_ACTIVE_MEMBERS_WITH_THP, {
    variables: { thp_id: travelHouse?.id },
  });

  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <UsersGroup users={data?.users} role="admin" />
      <UsersGroup users={data?.users} role="consultant" />
      <UsersGroup users={data?.users} role="accountant" />
      <UsersGroup users={data?.users} role="editor" />
      <SidePopup
        text="Add Team Member"
        component={<UserAdd defaultRole={"team"} />}
      />
    </>
  );
}
