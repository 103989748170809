import { useApolloClient, useMutation } from "@apollo/client";
import { capitalize } from "lodash";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { DELETE_FARE } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const FareCard = ({ sg }: any) => {
  const [deleteFare] = useMutation(DELETE_FARE);
  const client = useApolloClient();
  const cost = sg?.inq_suggestion_costs?.at(0);
  const leg = sg?.inq_suggestion_legs?.at(0);
  const stops = leg?.inq_suggestion_leg_stops;

  const removeFare = async (id: string) => {
    const res: any = await deleteFare({
      variables: {
        id: id,
      },
    });

    if (res?.data?.delete_inq_suggestion_by_pk?.id) {
      toast.success("Fare deleted successfuly");
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
  };
  return (
    <div className="p-[10px] rounded-lg shadow mb-2 flex justify-evenly items-start bg-primary dark:bg-dark-primary">
      <div className="flex flex-col items-center">
        <p>Destination</p>
        <div className="flex items-center gap-2">
          <p>{stops?.at(0)?.depart}</p>
          <FaArrowRight />
          <p>{stops?.at(-1)?.arrival}</p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <p>Cabin</p>
        <div className="flex items-center gap-2">
          <p className="font-bold">{capitalize(leg?.cabin)}</p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <p>Trip Type</p>
        <div className="flex items-center gap-2">
          <p className="font-bold">
            {sg?.inq_suggestion_legs?.length === 1 ? "One Way" : "Round Trip"}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <p>Price</p>
        <div className="flex items-center gap-2">
          <p className="font-bold">£{cost?.sale_price}</p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <p>Action</p>
        <div className="flex items-center gap-2">
          <Button
            sx={{ bgcolor: "red" }}
            onClick={() => removeFare(sg?.id)}
            variant="contained"
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FareCard;
