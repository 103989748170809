import { Box, Button, Switch, TextField, InputAdornment } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";

const FareCardSetting = ({ insertFareCard, pageData }: any) => {
  const [exclusiveFares, setExclusiveFares] = useState(
    pageData.show_exclusive_fares ? true : false
  );

  const [reserveFareText, setReserveFareText] = useState(
    pageData.reserve_fare_text || ""
  );

  const [reqCallBackText, setReqCallBackText] = useState(
    pageData.request_call_back_text || ""
  );

  const [betterDealText, setBetterDealText] = useState(
    pageData.better_deal_text || ""
  );

  const [betterDealHeadText, setBetterDealHeadText] = useState(
    pageData.better_deal_heading || ""
  );

  const [inqSubText, setInqSubText] = useState(
    pageData.better_deal_inquiry_submit_text || ""
  );

  const [callFareText, setCallFareText] = useState(
    pageData.call_only_fare_text || ""
  );

  const [shareBtnText, setShareBtnText] = useState(
    pageData.share_button_text || ""
  );

  const [searchBoxOpen, setSearchBoxOpen] = useState(
    pageData.edit_search ? true : false
  );

  const [searchFlights, setSearchFlights] = useState(
    pageData.search_with_inquiry ? true : false
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (inpData: any) => {
    insertFareCard(inpData);
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Fare Card Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              error={errors["reserve_fare_text"] ? true : false}
              {...register("reserve_fare_text", { required: true })}
              label="Reserve Fare Button Text"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an best Reserve Fare Button Text 1-3 words : without quotes, always show different and only one`
                        );
                        setValue('reserve_fare_text', res)
                        await typingEffect(setReserveFareText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={reserveFareText}
              onChange={(e: any) => setReserveFareText(e.target.value)}
            />
            <TextField
              error={errors["request_call_back_text"] ? true : false}
              {...register("request_call_back_text", { required: true })}
              label="Request Call Back Button Text"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an best Request Call Back Button Text for Fare Card 1-3 words : without quotes, always show different and only one`
                        );
                        setValue('request_call_back_text', res)
                        await typingEffect(setReqCallBackText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={reqCallBackText}
              onChange={(e: any) => setReqCallBackText(e.target.value)}
            />
            <TextField
              error={errors["better_deal_text"] ? true : false}
              {...register("better_deal_text", { required: true })}
              label="Better Deal Button Text"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an best Better Deal Button Text for Fare Card 1-3 words : without quotes, always show different and only one`
                        );
                        setValue('better_deal_text', res)
                        await typingEffect(setBetterDealText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={betterDealText}
              onChange={(e: any) => setBetterDealText(e.target.value)}
            />
            <TextField
              error={errors["better_deal_heading"] ? true : false}
              {...register("better_deal_heading", { required: true })}
              label="Better Deals Heading"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an best Better Deals Heading Text for Fare Card 2-5 words : without quotes, always show different and only one`
                        );
                        setValue('better_deal_heading', res)
                        await typingEffect(setBetterDealHeadText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={betterDealHeadText}
              onChange={(e: any) => setBetterDealHeadText(e.target.value)}
            />
            <TextField
              error={errors["better_deal_inquiry_submit_text"] ? true : false}
              {...register("better_deal_inquiry_submit_text", {
                required: true,
              })}
              label="Better Deals Inquiry Submit Text"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an best Better Deals Inquiry Submit Text for Fare Card 1-3 words : without quotes, always show different and only one`
                        );
                        setValue('better_deal_inquiry_submit_text', res)
                        await typingEffect(setInqSubText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={inqSubText}
              onChange={(e: any) => setInqSubText(e.target.value)}
            />
            <TextField
              error={errors["call_only_fare_text"] ? true : false}
              {...register("call_only_fare_text", { required: true })}
              label="Call Only Fare Button Text"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an best Call Only Fare Button Text 1-3 words : without quotes, always show different and only one`
                        );
                        setValue('call_only_fare_text', res)
                        await typingEffect(setCallFareText, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={callFareText}
              onChange={(e: any) => setCallFareText(e.target.value)}
            />
            <div className="md:col-span-2 w-full">
              <TextField
                fullWidth
                error={errors["share_button_text"] ? true : false}
                {...register("share_button_text", { required: true })}
                label="Share Button Text"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write an best Share Button Text for Fare Card 1-3 words : without quotes, always show different and only one`
                          );
                          setValue('share_button_text', res)
                          await typingEffect(setShareBtnText, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={shareBtnText}
                onChange={(e: any) => setShareBtnText(e.target.value)}
              />
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:col-span-2 xl:gap-28 gap-5 w-full">
              <div className="flex justify-between items-center ">
                <label>Show Exclusive Fares</label>
                <Switch
                  {...register("show_exclusive_fares")}
                  value={exclusiveFares}
                  checked={exclusiveFares}
                  onChange={() => setExclusiveFares(!exclusiveFares)}
                />
              </div>
              <div className="flex justify-between items-center ">
                <label>Search Box Open</label>
                <Switch
                  {...register("edit_search")}
                  value={searchBoxOpen}
                  checked={searchBoxOpen}
                  onChange={() => setSearchBoxOpen(!searchBoxOpen)}
                />
              </div>
              <div className="flex justify-between items-center ">
                <label>Search Flights with Inquiry</label>
                <Switch
                  {...register("search_with_inquiry")}
                  value={searchFlights}
                  checked={searchFlights}
                  onChange={() => setSearchFlights(!searchFlights)}
                />
              </div>
            </div>
          </div>
          <Button type="submit" sx={{ marginTop: "25px" }} variant="contained">
            Save Destination
          </Button>
        </Box>
      </div>
    </>
  );
};

export default FareCardSetting;
