export const generateRandomId = (length = 8) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomId = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }
  return randomId;
};

export function generateRandom10DigitCode() {
  const min = 10000000; // Minimum 10-digit number
  const max = 99999999; // Maximum 10-digit number
  const randomCode = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomCode.toString();
}

export function generateRandomNumberString() {
  let result = "";
  const length = 7;

  for (let i = 0; i < length; i++) {
    const digit = Math.floor(Math.random() * 10);
    result += digit.toString();
  }

  return result;
}
