import { gql } from "@apollo/client";

export const ADD_EMAIL_TEMPLATE = gql`
  mutation addDefEmailTemp($data: [def_emails_templates_insert_input!]!) {
    insert_def_emails_templates(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation deleteDefEmailTemp($temp_id: Int!) {
    delete_def_emails_templates(where: { id: { _eq: $temp_id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateDefEmailTemp(
    $temp_id: Int!
    $data: def_emails_templates_set_input!
  ) {
    update_def_emails_templates(where: { id: { _eq: $temp_id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
