import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import SettingsIcon from "@mui/icons-material/Settings";
import { GET_CASE_STUDIES_BY_BRD_ID } from "~/graphql/services_casestudy/Query";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import EditCaseStudy from "./components/EditCaseStudy";
import { DELETE_CASESTUDY } from "~/graphql/services_casestudy/Mutation";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { useNavigate } from "react-router-dom";

const ShowCaseStudy = ({ brdId }: any) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [openPopup, setOpenPopup] = useState<any>(false);
  const [caseStudy, setCaseStudy] = useState<any>();
  const [deleteCaseStudyMutation] = useMutation(DELETE_CASESTUDY);
  const { data, loading, error, refetch } = useQuery(
    GET_CASE_STUDIES_BY_BRD_ID,
    {
      variables: { brd_id: brdId },
    }
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleEditCaseStudy = (caseStudyId: any) => {
    navigate(`/admin/${brdId}/casestudy/${caseStudyId}/edit`);
  };

  const handleAddCaseStudy = (brd_id: any) => {
    navigate(`/admin/${brd_id}/casestudy/add`);
  };

  const deleteCaseStudy = async (caseStudy: any) => {
    console.log(caseStudy);
    try {
      const { error: imageDeleteError } = await nhost.storage.delete({
        fileId: caseStudy.image_id,
      });
      if (imageDeleteError) {
        toast.error("Error deleting image.");
        console.log(imageDeleteError);
        return;
      }
      console.log("Image deleted successfully!");

      const result = await deleteCaseStudyMutation({
        variables: { id: caseStudy.id },
      });
      if (result?.data?.delete_services_casestudy?.affected_rows > 0) {
        toast.success("Case study and image deleted successfully!");
        await refetch();
      } else {
        toast.error("Error deleting case study.");
      }
    } catch (error) {
      console.error("Error during delete:", error);
      toast.error("Error occurred during deletion.");
    }
  };

  const handleRedirect = () => {
    navigate(`/admin/${brdId}/services/headingsettings/case-study`);
  };
  
  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="w-full flex justify-between">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-primary dark:text-dark-primary">
              Case Studies
            </h1>
            <Button onClick={handleRedirect} className="flex items-center">
              <SettingsIcon className="text-primary dark:text-dark-primary" />
            </Button>
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleAddCaseStudy(brdId)}
          >
            Add Case Study
          </Button>
        </div>
        <div>
          {data && data.services_casestudy.length > 0 ? (
            <Grid container spacing={2}>
              {data.services_casestudy.map((caseStudy: any) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={caseStudy.id}>
                    <Card className="shadow-lg rounded-lg max-h-[400px]">
                      <CardMedia
                        component="img"
                        height="140"
                        image={`${process.env.REACT_APP_NHOST_STORAGE_URL}${caseStudy.image_id}`}
                        alt={caseStudy.title}
                        className="rounded-t-lg h-[140px] object-cover"
                      />
                      <CardContent className="flex flex-col">
                        <Typography
                          variant="h6"
                          component="h2"
                          className="font-bold mb-2"
                          style={{ paddingBottom: 8 }}
                        >
                          {caseStudy.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="mb-2"
                          style={{ paddingBottom: 8 }}
                        >
                          {caseStudy.description.length > 100
                            ? `${caseStudy.description.substring(0, 100)}...`
                            : caseStudy.description}
                        </Typography>
                        <Grid container spacing={1} className="mt-2">
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={() => handleEditCaseStudy(caseStudy.id)}
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="error"
                              fullWidth
                              onClick={() => deleteCaseStudy(caseStudy)} // Pass both id and img_id
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <p>No case studies available for the selected brand.</p>
          )}
        </div>
      </div>
      {/* {openPopup && (
        <SideDrawer
          component={<EditCaseStudy caseStudy={caseStudy} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )} */}
    </>
  );
};

export default ShowCaseStudy;
