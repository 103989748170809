import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useEffect, useState } from "react";
import { useFileUpload, useNhostClient } from "@nhost/react";
import { URLs } from "~/config/enums";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import { CircularProgress } from "@mui/material";

const SmAttachementBox = ({
  darkMode,
  ind,
  setFileDataSeparately,
  setFileId,
  setFilesId,
  file,
  fileId,
  removeFile,
  attachmentType,
  filesIds,
  setActiveIndex,
  activeIndex,
}: any) => {
  const [showCrossIcon, setShowCrossIcon] = useState(false);
  const { upload } = useFileUpload();
  const nhost = useNhostClient();
  const [currentFile, setCurrentFile] = useState<any>();

  const uploadFile = async () => {
    const res = await upload({ file });
    setFileId(res.id);
    const fileName = file.name.slice(0, file.name.lastIndexOf("."));
    setFilesId((prv: any) => [...prv, { id: res.id, name: fileName }]);
  };

  const deleteFile = async () => {
    let file_id: any = "";
    if (file?.file_id) {
      file_id = file?.file_id;
    } else {
      file_id = fileId;
    }
    const res = await nhost.storage.delete({ fileId: file_id });

    setFilesId((prv: any) => [...prv.filter((file: any) => file !== file_id)]);

    // ;
    let order_file_id: any = "";
    if (file?.file_id) {
      order_file_id = file?.id;
    }

    removeFile(file);
  };

  const getFileId = async () => {
    const res = await upload({ file });
    setCurrentFile(res);
    setFileId(res.id);
  };

  useEffect(() => {
    uploadFile();
    getFileId();
  }, []);

  return (
    <div
      className={`relative rounded-md cursor-pointer border-2 ${
        attachmentType === "audio" && "bg-[#F8AD20]"
      } ${
        activeIndex === ind
          ? "border-whatsappBasic"
          : "dark:border-dark-secondary border-gray-300 "
      }`}
      onMouseEnter={() => setShowCrossIcon(true)}
      onMouseLeave={() => setShowCrossIcon(false)}
    >
      {attachmentType === "image" && (
        <>
          {currentFile?.id ? (
            <img
              src={URLs.FILE_URL + currentFile?.id}
              className="w-[35px] h-[35px] object-cover p-[5px]"
              onClick={() => {
                setFileId(currentFile?.id);
                setFileDataSeparately(ind);
                setActiveIndex(ind);
              }}
            />
          ) : (
            <CircularProgress
              sx={{
                color: "#00a884",
                padding: "5px",
              }}
              size="30px"
            />
          )}
        </>
      )}
      {attachmentType === "document" && (
        <InsertDriveFileIcon
          sx={{
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            padding: "5px",
            zIndex: 1,
          }}
          fontSize="large"
          onClick={() => {
            setFileDataSeparately(ind);
            setActiveIndex(ind);
          }}
        />
      )}
      {attachmentType === "video" && (
        <PlayCircleOutlinedIcon
          sx={{
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            padding: "5px",
            zIndex: 1,
          }}
          fontSize="large"
          onClick={() => {
            setFileId(currentFile?.id);
            setFileDataSeparately(ind);
            setActiveIndex(ind);
          }}
        />
      )}

      {attachmentType === "audio" && (
        <HeadphonesIcon
          sx={{
            color: "white",
            padding: "5px",
            zIndex: 1,
          }}
          fontSize="large"
          onClick={() => {
            setFileId(currentFile?.id);
            setFileDataSeparately(ind);
            setActiveIndex(ind);
          }}
        />
      )}

      <div
        className={`absolute top-[-10px] right-[-10px] p-1 rounded-full bg-black/80 dark:bg-white items-center justify-center transition-all ease-in-out duration-300  ${
          showCrossIcon ? "flex" : "hidden"
        }`}
        onClick={() => {
          deleteFile();
        }}
      >
        <CloseOutlinedIcon
          sx={{ color: darkMode ? "black" : "white", fontSize: "10px" }}
        />
      </div>
    </div>
  );
};

export default SmAttachementBox;
