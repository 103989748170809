import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry(
    $id: uuid
    $vendor_id: uuid
    $ticket_url: String
    $pnr_no: String
    $ibe_no: String
    $status: String
    $void_time: timestamptz
    $cancelation_charges: String
  ) {
    update_inquiries(
      where: { id: { _eq: $id } }
      _set: {
        ticket_url: $ticket_url
        pnr_no: $pnr_no
        ibe_no: $ibe_no
        vendor_id: $vendor_id
        status: $status
        void_time: $void_time
        cancelation_charges: $cancelation_charges
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const TRAIN_ADD_TICKET = gql`
  mutation MyMutation($ticket: [train_inq_ticket_insert_input!]!) {
    insert_train_inq_ticket(objects: $ticket) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation MyMutation($transactions: [acc_transactions_insert_input!]!) {
    insert_acc_transactions(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
