import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_BRAND_EMAIL_TEMPLATES } from "./graphql/Query";
import SingleTemplate from "./components/SingleTemplate";
import RoleCategory from "../../component/RoleCategory";
import HeaderSection from "../../component/HeaderSection";

export default function EmailTemplates({ brd_id }: any) {
  const [templates, setTemplate] = useState<any>([]);
  const [role, setRole] = useState<any>("user");

  const [dropdown, setDropdown] = useState<any>(null);
  const { loading, error, data } = useQuery(GET_BRAND_EMAIL_TEMPLATES, {
    variables: { brd_id },
  });

  useEffect(() => {
    if (data?.brd_emails_templates.length > 0) {
      const filterTemplates = data?.brd_emails_templates.filter(
        (temp: any) => temp.def_emails_template.for_role === role
      );
      setTemplate([...filterTemplates]);
    }
  }, [data, role]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div>
      <div></div>
      <HeaderSection
        heading="Email Templates"
        brd_id={brd_id}
        templates={data?.brd_emails_templates}
      />
      <div className="flex">
        <div className="w-[20%]">
          <RoleCategory
            role={role}
            setRole={setRole}
            roles={["user", "consultant"]}
            setDropdown={setDropdown}
          />
        </div>
        <div className="w-[80%] mt-[10px]  rounded-[6px] p-4">
          {templates.map((template: any, index: any) => (
            <SingleTemplate
              key={index}
              template={template}
              setDropdown={setDropdown}
              dropdown={dropdown}
            />
          ))}
          {templates.length <= 0 && <div> No template found !!</div>}
        </div>
      </div>
    </div>
  );
}
