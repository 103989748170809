import React, { useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const roomTypeOptions = [
  { value: "single", label: "Single" },
  { value: "double", label: "Double" },
  { value: "suite", label: "Suite" },
  { value: "deluxe", label: "Deluxe" },
];

export const RoomTypeSelector = ({
  name,
  register,
  errors,
  value, // The current value of the select input
  onChange,
  setValue,
  fieldName,
  defaultValue,
  required,
  label,
}: any) => {
  // Set the default value when the component mounts or when defaultValue changes
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue); // Set the initial value for the room type select
      onChange(defaultValue); // Set the initial value for the input field
    }
  }, [defaultValue, fieldName, setValue, onChange]);

  return (
    <FormControl fullWidth error={Boolean(errors[name])}>
      <InputLabel id="roomType-label">Room Type</InputLabel>
      <Select
        labelId="roomType-label"
        id="roomType"
        label="Room Type"
        value={value || ""}
        {...register(name, { required: "This is required" })}
        onChange={(event) => {
          onChange(event.target.value); // Update the value on change
        }}
      >
        <MenuItem value="">
          <em>Select a room type</em>
        </MenuItem>
        {roomTypeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl>
  );
};
