import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchBar from "../../../SearchBar";
import ChatRow from "../../../ChatRow";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { useState } from "react";
import NewGroup from "./component/NewGroup";
import CreateGroup from "./component/NewGroup/component/CreateGroup";

const Contacts = ({
  setShowContactBar,
  showContactBar,
  createGroupBar,
  setCreateGroupBar,
  type,
}: any) => {
  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showContactBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] bg-whatsappBasic `}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowContactBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">New Community</p>
          </div>
        </div>
        <SearchBar searchType="contacts" />

        <div className="overflow-scroll flex-grow">
          <div
            className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer"
            onClick={() => {
              setCreateGroupBar(true);
            }}
          >
            <div className="py-[13px] ml-[15px]">
              <div
                className={`p-[13px] text-white rounded-full bg-whatsappBasic`}
              >
                <PeopleOutlinedIcon />
              </div>
            </div>
            <div className="py-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
              <h1 className="text-[16px] text-black dark:text-white py-[13px]">
                Create Community
              </h1>
            </div>
          </div>

          {/* <h1 className="text-whatsappBasic py-[30px] px-[15px] text-[16px]">
            CONTACTS ON WHATSAPP
          </h1> */}

          {/* <ChatRow setShowContactBar={setShowContactBar} /> */}
        </div>
      </div>

      <CreateGroup
        createGroupBar={createGroupBar}
        setCreateGroupBar={setCreateGroupBar}
        type={type}
      />
    </>
  );
};

export default Contacts;
