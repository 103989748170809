import { gql } from "@apollo/client";

export const GET_MESSAGE = gql`
  query users($brd_phone: String!) {
    brd_whatsapp_chat(where: { brand_phone: { _eq: $brd_phone } }) {
      id
      text
      user_phone
      user_name
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($brd_phone: String!) {
    brd_whatsapp_chat(
      distinct_on: user_phone
      where: { brand_phone: { _eq: $brd_phone } }
    ) {
      user_name
      user_phone
      timestamp
      text
    }
  }
`;

export const GET_STREAM_MESSAGES = gql`
  subscription MySubscription($brd_phone: String!, $cursor: timestamptz) {
    brd_whatsapp_chat_stream(
      batch_size: 100
      cursor: { initial_value: { timestamp: $cursor } }
      where: { brand_phone: { _eq: $brd_phone } }
    ) {
      user_name
      user_phone
      text
      sender
      timestamp
    }
  }
`;

export const GET_BRAND = gql`
  query GetBrandsQuery($brd_id: uuid) {
    brd_list(where: { id: { _eq: $brd_id } }) {
      whatsapp_token
      phoneNumberId
    }
  }
`;
