import Layout from "~/components/common/Layout";
import React from "react";
import AccountModule from "~/modules/AccountModule";
import PaymentRequest from "~/modules/AccountModule/segments/PaymentRequest";
import UsersModule from "~/modules/UsersModule";
import Attendance from "~/modules/UsersModule/segments/Attendance";

export default function AttendancePage() {
  return (
    <div>
      <Layout>
        <AccountModule>
          <Attendance />
        </AccountModule>
      </Layout>
    </div>
  );
}
