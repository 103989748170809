import React from "react";
// Import Components
import LocationBox from "components/FormInputs/LocationBox";
import { DateTimeBox } from "components/FormInputs/DateTimeBox";

export default function SingleFlight({
  direction,
  control,
  setValue,
  register,
  errors,
  leg,
  index,
  getValues,
}: any) {
  return (
    <div className="flex gap-2">
      {/* Select Airport or city */}
      <LocationBox
        control={control}
        errors={errors}
        setValue={setValue}
        fieldName={`${leg}.stops.${index}.${direction}City`}
        label={direction}
        placeholder="Choose your location"
        defaultValue={getValues(`${leg}.stops.${index}.${direction}City`) || ""}
        required={true}
        labelClasses="capitalize text-primary dark:text-dark-primary"
        inputClasses="text-primary dark:text-dark-primary dark:bg-dark-primary focus:outline-none focus:border-basic"
        optionBoxClasses="dark:bg-dark-secondary"
        optionClasses="text-primary dark:text-dark-primary"
      />
      {/* Select DateTime */}
      <DateTimeBox
        control={control}
        setValue={setValue}
        errors={errors}
        defaultValue={
          getValues(`${leg}.stops.${index}.${direction}DateTime`) || ""
        }
        fieldName={`${leg}.stops.${index}.${direction}DateTime`}
        label="Date and Time"
        inputClasses={"text-primary dark:text-dark-primary bg-white dark:bg-dark-primary rounded-[5px] py-2 mt-[2px] focus:outline-none focus:border-basic"}
        labelClasses="text-primary dark:text-dark-primary text-base font-normal"
      />
    </div>
  );
}
