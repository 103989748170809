import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { INSERT_CAMPUS_COURSE_ADM_REQ } from "~/graphql/course_adm_req/Mutation";

const AddAdmissionRequirement = ({ campusCourseId, courseType, setSidePopup }: any) => {

  const client = useApolloClient();
  const { register, handleSubmit } = useForm<any>();
  const [insertRequirements] = useMutation(INSERT_CAMPUS_COURSE_ADM_REQ);

  const onSubmit = async (data: any) => {
    const result = await insertRequirements({
      variables: {
        campusCourseId: campusCourseId,
        gapAllowed: data.gap_allowed,
        gradeType: data.grade_type,
        grades: data.grades,
        qualification: data.qualification,
        scholarshipAvailable: data.scholarship_available,
        elt: data.elt,
        moi: data.moi
      },
    });
    if (result?.data.insert_course_adm_req?.affected_rows > 0) {
      toast.success("Admission Requirement Added Successfully!");
      client.refetchQueries({
        include: "all",
      });
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Admission Requirement
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Qualification"
          variant="outlined"
          fullWidth
          required
          multiline
          rows={3}
          {...register("qualification")}
          style={{ marginBottom: "16px" }}
        />
        <div className="flex gap-3">
          <FormControl variant="outlined" fullWidth required style={{ marginBottom: "16px" }}>
            <InputLabel id="grades-label">Grade Type</InputLabel>
            <Select
              labelId="grades-label"
              label="Grades"
              {...register("grade_type")}
            >
              <MenuItem value="Percentage">Percentage</MenuItem>
              <MenuItem value="Grade">Grades</MenuItem>
              <MenuItem value="CGPA">CGPA</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Value"
            variant="outlined"
            fullWidth
            required
            {...register("grades")}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <TextField
          label="Gap Allowed"
          variant="outlined"
          required
          fullWidth
          {...register("gap_allowed")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Scholarship Available"
          variant="outlined"
          required
          fullWidth
          {...register("scholarship_available")}
          style={{ marginBottom: "16px" }}
        />
        {courseType === "UG" &&
          <TextField
            label="ELT"
            variant="outlined"
            required
            fullWidth
            multiline
            rows={3}
            {...register("elt")}
            style={{ marginBottom: "16px" }}
          />
        }
        {courseType === "PG" &&
          <>
            <TextField
              label="ELT"
              variant="outlined"
              required
              fullWidth
              multiline
              rows={3}
              {...register("elt")}
              style={{ marginBottom: "16px" }}
            />
            <FormControl variant="outlined" fullWidth required style={{ marginBottom: "16px" }}>
              <InputLabel id="moi-label">MOI</InputLabel>
              <Select
                labelId="moi-label"
                label="MOI"
                {...register("moi")}
              >
                <MenuItem value="acceptable">Acceptable</MenuItem>
                <MenuItem value="not_acceptable">Not Acceptable</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddAdmissionRequirement;
