import React, { useState } from "react";
import PaymentDetail from "~/components/common/PaymentDetail";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddCardIcon from "@mui/icons-material/AddCard";
import CancelIcon from "@mui/icons-material/Cancel";
import { stripe } from "~/lib/stripe";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

const UpdatePlan = ({
  travelHouse,
  getCustomerPayDetail,
  paymentMeth,
  curretnPlan,
  currSub,
  setOpenMod,
}: any) => {
  const [addPayMeth, setAddPayMeth] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const updateSub = async (formData: any) => {
    setLoading(true);
    try {
      if (
        (currSub === "yearly" &&
          curretnPlan?.yearly_plan_seats <
            travelHouse?.brd_users?.filter(
              (item: any) => item?.users?.defaultRole !== "user"
            ).length) ||
        (currSub === "monthly" &&
          curretnPlan?.monthly_plan_seats <
            travelHouse?.brd_users?.filter(
              (item: any) => item?.users?.defaultRole !== "user"
            ).length) ||
        (currSub === "yearly" &&
          curretnPlan?.yearly_plan_brands < travelHouse?.brd_lists?.length) ||
        (currSub === "monthly" &&
          curretnPlan?.monthly_plan_brands < travelHouse?.brd_lists?.length)
      ) {
        setOpenMod(false);
        setLoading(false);
        toast.error(
          "Your Brands or Seats are not suitable for current plan! Please upgrade your plan"
        );
      } else {
        const currentSubscription = await stripe.subscriptions.retrieve(
          travelHouse?.subscription_ref
        );

        const res = await stripe.customers.update(
          formData.paymentMethod.customer,
          {
            invoice_settings: {
              default_payment_method: formData.paymentMethod.id,
            },
          }
        );

        const res2: any = await stripe.subscriptions.update(
          travelHouse?.subscription_ref,
          {
            items: [
              {
                id: currentSubscription.items.data[0].id,
                price:
                  currSub === "monthly"
                    ? curretnPlan?.metadata?.monthly_price_id
                    : curretnPlan?.metadata?.yearly_price_id, // The new plan's price ID
              },
            ],
            proration_behavior: "always_invoice", // Calculate any prorated charges
            expand: ["latest_invoice.payment_intent"], // Expand to get the payment intent details
          }
        );

        // await stripe.subscriptions.create({
        //   customer: formData.paymentMethod.customer,
        //   items: [
        //     {
        //       price:
        //         currSub === "monthly"
        //           ? curretnPlan?.metadata?.monthly_price_id
        //           : curretnPlan?.metadata?.yearly_price_id,
        //     },
        //   ],
        //   expand: ["latest_invoice.payment_intent"],
        // });

        const paymentIntent = res2?.latest_invoice;

        if (paymentIntent?.status === "paid") {
          setTimeout(() => {
            setOpenMod(false);
            toast.success("Subscription Updated");
            setLoading(false);
          }, 3000);
        } else {
          setOpenMod(false);
          toast.error("Payment failed, please try again.");
          setLoading(false);
        }
      }
    } catch (err: any) {
      setOpenMod(false);
      toast.error("Error Found");
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="text-center font-bold text-[25px] my-5">
        UPDATE SUBSCRIPTION ({curretnPlan?.plan_name})
      </h1>
      <form onSubmit={handleSubmit(updateSub)}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register("paymentMethod", { required: true })}
          render={({ field }: any) => (
            <Autocomplete
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data);
              }}
              options={paymentMeth ? paymentMeth : []}
              getOptionLabel={(option: any) => `****${option.card.last4}`}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  <div className="flex items-center items-center gap-5">
                    <CreditCardIcon sx={{ fontSize: "40px" }} color="primary" />
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <p className="font-bold text-[20px]">
                          {option?.card?.display_brand}
                        </p>
                        <p className="font-semibold text-[15px]">
                          ****{option?.card?.last4}
                        </p>
                      </div>
                      <p className="text-[12px] font-semibold mt-[-2px]">
                        Expiry {option?.card?.exp_month} /{" "}
                        {option?.card?.exp_year}
                      </p>
                    </div>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Payment Method"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                    el && errors["paymentMethod"] && el.focus();
                  }}
                  error={errors["paymentMethod"] ? true : false}
                  {...params}
                />
              )}
              noOptionsText=""
            />
          )}
        />
        <p
          onClick={() => setAddPayMeth(true)}
          className="text-lg flex items-center font-bold gap-2 cursor-pointer my-3 ml-1"
        >
          <AddCardIcon />
          <span>Add new payment method</span>
        </p>
        {!addPayMeth && (
          <div className="flex justify-center mt-7">
            <Button type="submit" variant="contained">
              {loading ? "updating..." : "Update Subscription"}
            </Button>
          </div>
        )}
      </form>

      {addPayMeth && (
        <div className="bg-primary shadow rounded p-5 relative">
          <CancelIcon
            onClick={() => setAddPayMeth(false)}
            color="primary"
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
          />
          <PaymentDetail
            travelHouse={travelHouse}
            getCustomerPayDetail={getCustomerPayDetail}
            setOpenMod={setAddPayMeth}
          />
        </div>
      )}
    </div>
  );
};

export default UpdatePlan;
