import { MenuItem } from "@mui/material";

const MenuItems = ({
  setCreateGroupBar,
  setShowMenu,
  setShowSettingsBar,
  setShowTemplateBar,
  setShowCreateTemplateBar,
  setSelectedConversations,
  selectedConversations,
  setShowLabelBar,
}: any) => {
  return (
    <>
      <div className="shadow py-[9px] ">
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setCreateGroupBar(true);
          }}
        >
          New group
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Starred messages
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Select chats
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setShowCreateTemplateBar(true);
          }}
        >
          Create Template
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setShowTemplateBar(true);
          }}
        >
          Templates
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setSelectedConversations(selectedConversations ? null : []);
          }}
        >
          {selectedConversations
            ? "UnSelect Conversations"
            : "Select Conversations"}
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setShowLabelBar(true);
          }}
        >
          Labels
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setShowSettingsBar(true);
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Logout
        </MenuItem>
      </div>
    </>
  );
};

export default MenuItems;
