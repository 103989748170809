import {
  FormLabel,
  TextField,
  Button,
  Modal,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DELETE_TIP } from "~/modules/BrandModule/segments/FlightTips/graphql/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { INSERT_TIP } from "~/modules/BrandModule/segments/FlightTips/graphql/Mutation";
import { typingEffect } from "~/utils/typingEffect";
import { getAiData } from "~/utils/getAiData";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const TipsSectionCard = ({
  ft,
  index,
  register,
  setValue,
  errors,
  cardType,
  type,
  page
}: any) => {

  const [title, setTitle] = useState<any>(
    ft?.heading ? ft?.heading : ft?.title && ft?.title
  );
  const [description, setDescription] = useState<any>(
    ft?.description ? ft?.description : ft?.content && ft?.content
  );

  console.log("ncsjds", page)

  useEffect(() => {
    setValue(`tips.${index}.id`, ft?.id);
  }, []);

  const [open, setOpen] = useState(false);
  const client = useApolloClient();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteTip] = useMutation(DELETE_TIP);

  const handleDelete = async () => {
    const res = await deleteTip({
      variables: {
        id: ft?.id,
      },
    });
    if (res?.data?.delete_brd_flights_pages_tips?.affected_rows > 0) {
      toast.success("Tip Deleted Successfully!");
      await client.refetchQueries({
        include: "all",
      });
    }
    handleClose();
  };

  return (
    <div className="bg-primary dark:bg-dark-primary rounded-lg shadow p-5">
      {cardType === "featuredCard" && (
        <div className="mb-5">
          <h1 className="mb-5">{index + 1}.</h1>
          <label className="mb-2">Icon</label>
          <TextField
            sx={{ width: "100%" }}
            {...register(`tips.${index}.icon`)}
            type="file"
            inputProps={{
              style: {
                paddingTop: "7px",
                paddingBottom: "27px",
                paddingLeft: "23px",
              },
            }}
            variant="outlined"
            helperText="SVG, PNG, JPG or GIF (MAX. 400x400px)"
          />
        </div>
      )}
      {cardType === "defaultCard" && <h1 className="mb-5">{index + 1}.</h1>}
      <TextField
        sx={{ width: "100%" }}
        error={errors?.tips && errors?.tips[index]?.title ? true : false}
        {...register(`tips.${index}.title`, { required: true })}
        label="Title"
        type="text"
        defaultValue={ft?.heading ? ft?.heading : ft?.title && ft?.title}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AutoFixHighIcon
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={async () => {
                  const res = await getAiData(
                    `I have webpage in which I have tips section. Write a tip title in 4 to 5 words for "${page?.brd_list?.def_brand_type?.name}" type page without quotes.${title ? `As, previous input is ${title}`: ""}`
                  );
                  await typingEffect(setTitle, res);
                }}
              />
            </InputAdornment>
          ),
        }}
        value={title}
        onChange={(e: any) => setTitle(e.target.value)}
      />
      <div className="mt-3">
        <div className="flex justify-between">
          <FormLabel>Description</FormLabel>
          <AutoFixHighIcon
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={async () => {
              const res = await getAiData(
                `I have a webpage in which I have tips section. Write a tip description which have title:tips.${index || page?.brd_list?.def_brand_type?.name}.title as in 1 line for Page without quotes, as previous input is ${
                  ft?.description ? ft?.description : ft?.content && ft?.content
                }.`
              );
              await typingEffect(setDescription, res);
            }}
          />
        </div>
        <textarea
          {...register(`tips.${index}.content`, { required: true })}
          className={`${
            errors?.tips && errors?.tips[index]?.content
              ? "border-red-600 bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-40"
              : "bg-transparent rounded w-full border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-40"
          }`}
          defaultValue={
            ft?.description ? ft?.description : ft?.content && ft?.content
          }
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </div>
      <Button onClick={handleOpen} color="error">
        Delete
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this tip?
          </Typography>
          <Box mt={3} display="flex" justifyContent="space-between">
            <Button variant="contained" onClick={handleDelete} color="error">
              Delete
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TipsSectionCard;
