import { gql } from "@apollo/client";

export const UPDATE_INQ_INVOICE_PDF = gql`
  mutation updateInvoicePdf($invoiceId: uuid!, $pdfId: uuid!) {
    update_inq_invoices(
      where: { id: { _eq: $invoiceId } }
      _set: { invoice_pdf: $pdfId }
    ) {
      affected_rows
    }
  }
`;
