import { gql } from "@apollo/client";

export const GET_DEFAULT_SETTINGS = gql`
  query GetDefaultSettings($id: uuid) {
    brd_default_setting(where: { brd_id: { _eq: $id } }) {
      brd_id
      faq_heading
      faq_sub_heading
      id
      gallery_heading
      gallery_sub_heading
      testimonial_heading
      testimonial_sub_heading
      destinations_main_heading
      destinations_button_text
      destinations_footer_text
      hot_destinations_heading
      hot_destinations_sub_heading
      destinations_main_heading
      destinations_button_text
      destination_tips_heading
      destination_tips_sub_heading
      destination_contact_heading
      destination_contact_sub_heading
      destination_blogs_heading
      destination_blogs_sub_heading
      flight_links_heading
      flight_links_sub_heading
      created_at
      updated_at
      better_deal_inquiry_submit_text
      better_deal_heading
      reserve_fare_text
      better_deal_text
      call_only_fare_text
      request_call_back_text
      share_button_text
      blogs_heading
      blogs_sub_heading
      blogs_featured_image
      blog_page_meta_title
      blog_page_meta_description
      homepage_blog_section_heading
      homepage_blog_section_sub_heading
    }
  }
`;
