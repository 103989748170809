import { gql } from "@apollo/client";

export const GET_SERVICES_WITH_BRD_ID = gql`
  query MyQuery($brd_id: [uuid!]!) {
    brd_services(where: { brd_id: { _in: $brd_id } }) {
      title
      description
      price
      img_id
      id
    }
  }
`;

export const GET_SERVICE_BY_ID = gql`
  query MyQuery($id: uuid!) {
    brd_services(where: { id: { _eq: $id } }) {
      title
      title_color
      description
      price
      bg_color
      img_id
      slug
      service_category_id
      id
      detailed_description
      brd_service_category {
        name
      }
    }
  }
`;
