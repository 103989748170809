class ConsultantEmailTemplates {
  static sendCommissionEmail(from: any, to: any, variables: any) {
    const subject = `Congratulations! Your Estimated Commission`;
    const body = `
                      Dear Consultant,

                      Congratulations! Your estimated commission for this ticket is ${variables.amount}. We appreciate your dedication and hard work.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }

  static sendFareSelectionEmail(from: any, to: any, variables: any) {
    const subject = `Fare Selection Confirmation from Customer`;
    const body = `
                      Dear Consultant,

                      We are pleased to inform you that ${variables.customer_name} has selected a fare from your suggested options. Please proceed to the next step in the booking process.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }

  static sendInvitationEmail(data: any) {
    const { from, to, variables } = data;

    const subject = `Invitation to Join ${from.th_name} as a Consultant`;
    const body = `
                      Dear Consultant,

                      We are delighted to invite you to join our team at ${from.th_name} as a consultant. Click the following link to set up your account and start the journey with us: ${variables.url}

                      We look forward to working with you.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }

  static sendNewInquiryNotification(data: any) {
    const { from, to, variables } = data;

    const subject = `New Inquiry Available in the Portal`;
    const body = `
                      Dear Consultant,

                      A new customer inquiry has been submitted in our portal. We invite you to review the details and provide the best possible travel options.

                      Thank you for your attention to this matter.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }

  static sendPassengerInfoNotification(data: any) {
    const { from, to, variables } = data;

    const subject = `${variables.customer_name} Passenger Information Received`;
    const body = `
                      Dear Consultant,

                      ${variables.customer_name} has successfully filled out the passenger information. Please proceed to issue the ticket.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }

  static sendPaymentConfirmation(data: any) {
    const { from, to, variables } = data;

    const subject = `Payment Confirmation for ${variables.customer_name}`;
    const body = `
                      Dear Consultant,

                      Our accountant, ${variables.accountant_name}, has confirmed the receipt of payment from ${variables.customer_name}. You may now proceed to the ticket issuance process.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }

  static sendPaymentInfoNotification(data: any) {
    const { from, to, variables } = data;

    const subject = `${variables.customer_name} Provided Payment information for Invoice Number ${variables.invoice_no}`;
    const body = `
                      Dear Consultant,

                      We have received a payment details from ${variables.customer_name} for Invoice Number ${variables.invoice_no}. Please wait for the accountant confirmation.

                      Best Regards,
                      ${from.th_name}
                  `;

    return { emails: to.email, subject, body };
  }
}

class AdminTemplates {
  static AddDnsRecordsSG(data: any) {
    const { to, variables } = data;
    const subject = "Important: Add CNAME Record to Your DNS Configuration";
    const body = `Dear User,
  
    To ensure proper functionality and accessibility of your services, please add the following CNAME record to your DNS configuration:

    - Type: A
    - Name: @
    - Target: 76.76.21.21

    - Type: CNAME
    - Name: ${variables.portalDomain}
    - Target: cname.vercel-dns.com

    Please also add the following DNS records for Email Authentication:

    ${variables.sendGridDnsDetails}

    After adding the record, it might take some time for the DNS changes to propagate. Once done, you should be able to access your services using the provided domain.

    If you face any issues or need assistance, please contact our support team.

    Thank you for your cooperation!

    Best regards,
    Team THP`;
    return { emails: to.email, subject, body };
  }
  static AddDnsRecords(data: any) {
    const { to, variables } = data;
    const subject = "Important: Add CNAME Record to Your DNS Configuration";
    const body = `Dear User,

    To ensure proper functionality and accessibility of your services, please add the following CNAME record to your DNS configuration:

    - Type: A
    - Name: @
    - Target: 76.76.21.21

    - Type: CNAME
    - Name: ${variables.portalDomain}
    - Target: cname.vercel-dns.com

    After adding the record, it might take some time for the DNS changes to propagate. Once done, you should be able to access your services using the provided domain.

    If you face any issues or need assistance, please contact our support team.

    Thank you for your cooperation!

    Best regards,
    Team THP`;
    return { emails: to.email, subject, body };
  }
}

class CustomerTemplates {
  static sendDedicatedConsultantEmail(data: any) {
    const { from, to, variables } = data;
    const subject = `Your Dedicated Consultant is on the Task`;
    const body = `Dear Customer,

        We're pleased to inform you that your dedicated consultant, ${variables.consultant_name}, is now working on your booking request. In the next few minutes, they will find the best and cheapest fares for you and add 2 to 3 options. You'll receive another email as soon as these options are ready. Meanwhile, please check and verify your passenger information in your portal.
        
        Please use this magic link to access your portal: ${variables.url}
        
        Best Regards,
        ${from.th_name}`;
    return { emails: to.email, subject, body };
  }

  static sendNewFareOptionsNotification(data: any) {
    const { from, to, variables } = data;
    const subject = `New Fare Options Available in Your Portal`;
    const body = `Dear Customer,

    Your consultant has added new fare option for your travel in your portal. Please access your portal to choose one or call your dedicated consultant any time on this direct line: ${variables.phone}.
    
    Please use this magic link to access your portal: ${variables.url}
    
    Safe travels!
    
    Best Regards,
    ${from.th_name}`;

    return { emails: to.email, subject, body };
  }

  static sendFareSelectionConfirmation(data: any) {
    const { from, to, variables } = data;
    const subject = `Fare Selection Confirmation`;
    const body = `Dear Customer,

    Thank you for selecting your preferred fare. If your passenger information is correct, our consultant will issue an invoice shortly.
    
    Please use this magic link to access your portal: ${variables.url}
    
    Best Regards,
    ${from.th_name}`;

    return { emails: to.email, subject, body };
  }

  static sendBookingRequestNotification(data: any) {
    const { from, to, variables } = data;
    const subject = `Booking Request Received – Welcome to Your Dedicated Portal`;
    const body = `Dear Customer,

    We have received your booking request. Congratulations on your free dedicated portal, in which you can track the complete booking process, download your tickets and invoices, earn travel points with each issuance (redeemable on future tickets), and unlock account-level benefits.
    
    Here is your magic link to access your portal: ${variables.url}
    
    Welcome aboard!
    
    Best Regards,
    ${from.th_name}`;

    return { emails: to.email, subject, body };
  }

  static sendInvoiceReceivedNotification(data: any) {
    const { from, to, variables } = data;
    const subject = `Invoice Payment Received`;
    const body = `Dear Customer,

    We've received an invoice Payment for your selected fare. You will be notified when our accountant will confirm your payment.
    
    Please use this magic link to access your portal and upload the screenshot: ${variables.url}
  
    Best Regards,
    ${from.th_name}`;

    return { emails: to.email, subject, body };
  }

  static sendPaymentConfirmationNotification(data: any) {
    const { from, to, variables } = data;
    const subject = `Payment Confirmed – Ticket Issuance in Process`;
    const body = `Dear Customer,

    Congratulations! Your payment has been confirmed. You will receive your ticket within the next 30 minutes.
    
    Please use this magic link to access your portal: ${variables.url}
    
    Best Regards,
    ${from.th_name}`;

    return { emails: to.email, subject, body };
  }

  static sendTicketReadyNotification(data: any) {
    const { from, to, variables } = data;
    const subject = `Your Ticket is Ready – Congratulations on Your Savings!`;
    const body = `Dear Customer,

    Attached to this email is your ticket. Congratulations, you saved ${
      variables?.saved_gbp ?? ""
    } GBP because your dedicated consultant found the best fare class for you. Also, you earned ${
      variables?.points ?? ""
    }  Travel Points which can be redeemed by leaving feedback for your consultant.
    
    In case of any issue until you fly, feel free to call your consultant anytime.
    
    Please use this link to leave your feedback and redeem your points: ${
      variables?.feedback_url ?? ""
    }
  
    Best Regards,
    ${from.th_name}`;

    return { emails: to.email, subject, body };
  }
}

// ConsultantTemplates.sendCommissionEmail(from, to, variables);
export { ConsultantEmailTemplates, CustomerTemplates, AdminTemplates };
