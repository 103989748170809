import React from "react";
import Layout from "~/components/common/Layout";
import UsersModule from "~/modules/UsersModule";
import Profile from "~/modules/UsersModule/segments/Profile";

export default function ProfilePage() {
  return (
    <div>
      <Layout>
        <UsersModule>
          <Profile />
        </UsersModule>
      </Layout>
    </div>
  );
}
