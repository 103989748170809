import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { useEffect, useState, useRef } from "react";
import { SEND_WHATSAPP_MESSAGE } from "./Query";
import { MdSend } from "react-icons/md";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import { SEND_WHATSAPP_CHAT } from "./Mutation";
import { GET_USER_MESSAGES } from "./Subscription";
import moment from "moment";
import { useForm } from "react-hook-form";
import DotLoader from "~/components/common/DotLoader";

const WhatsappNew = ({ brd_phone, toNumber, token, user, name }: any) => {
  const { loading, data } = useSubscription(GET_USER_MESSAGES, {
    variables: { user_phone: toNumber, brd_phone },
  });

  const { handleSubmit } = useForm();

  const messagesContainerRef: any = useRef(null); // Create a ref for the messages container

  const [messages, updateMessages] = useState<any>([]);
  const [isSending, setIsSending] = useState<any>(false);

  const [sendWhatsappChat] = useMutation(SEND_WHATSAPP_CHAT);

  const [messageData, setMessageData] = useState("");
  const client = useApolloClient();

  async function sendEmail() {
    if (!isSending) {
      setIsSending(true);
      const sendEmailResp = await client.query({
        query: SEND_WHATSAPP_MESSAGE,
        variables: {
          templateName: "",
          type: "text",
          toNumber: toNumber,
          textMessage: messageData,
          phoneNumberId: brd_phone,
          token: token,
          parameters: "",
          filename: "",
          url: "",
        },
      });

      if (
        sendEmailResp?.data?.whatsappSendMessage?.messaging_product !== null
      ) {
        const payloadDB: any = [
          {
            brand_phone: brd_phone,
            user_phone: toNumber,
            text: messageData,
            sender: "brand",
            timestamp: moment(new Date()).toISOString(),
          },
        ];
        try {
          const res = await sendWhatsappChat({
            variables: {
              data: payloadDB,
            },
          });
          setMessageData("");
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
          setIsSending(false);
        } catch (e) {
          console.error("error", e);
        }
      }
      setIsSending(false);
    }
  }

  useEffect(() => {
    if (data?.brd_whatsapp_chat) {
      const updatedMessages = [...data.brd_whatsapp_chat];
      updateMessages(updatedMessages);

      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }
  }, [data]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, []);

  if (loading) return <p>Loading...</p>;

  // onClick={() => {
  //   sendEmail();
  // }}

  return (
    <div>
      {/* Right */}
      <div className="w-[900px] border flex flex-col h-[80vh] ">
        {/* Header */}
        <div className="py-2 px-3 bg-grey-lighter flex flex-row justify-between items-center">
          <div className="flex items-center">
            <div>
              <img
                className="w-10 h-10 rounded-full"
                src="https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo="
                alt="User Avatar"
              />
            </div>
            <div className="ml-4">
              <p className="text-grey-darkest capitalize">{name || toNumber}</p>
              <p className="text-grey-darker text-xs mt-1">
                {name ? toNumber : ""}
              </p>
            </div>
          </div>

          <div className="flex">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".5"
                  d="M15.9 14.3H15l-.3-.3c1-1.1 1.6-2.7 1.6-4.3 0-3.7-3-6.7-6.7-6.7S3 6 3 9.7s3 6.7 6.7 6.7c1.6 0 3.2-.6 4.3-1.6l.3.3v.8l5.1 5.1 1.5-1.5-5-5.2zm-6.2 0c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z"
                />
              </svg>
            </div>
            <div className="ml-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".5"
                  d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                />
              </svg>
            </div>
            <div className="ml-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="#263238"
                  fillOpacity=".6"
                  d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Messages */}
        <div
          className="flex-1 overflow-auto"
          ref={messagesContainerRef}
          style={{ backgroundColor: "#DAD3CC" }}
        >
          <div className="py-2 px-3">
            <div className="flex justify-center mb-4">
              <div
                className="rounded py-2 px-4"
                style={{ backgroundColor: "#FCF4CB" }}
              >
                <p className="text-xs">
                  Messages to this chat and calls are now secured with
                  end-to-end encryption. Tap for more info.
                </p>
              </div>
            </div>

            {messages.map((message: any, index: any) => (
              <div key={index} className="py-2 px-3">
                {message.sender === "user" ? ( // If 'from' key exists, it's a received message
                  <div className="flex mb-2">
                    <div
                      className="rounded py-2 px-3"
                      style={{ backgroundColor: "#F2F2F2" }} // Customize the received message background color
                    >
                      <p className="text-sm mt-1">{message.text}</p>
                      <p className="text-xs text-grey-darkest">
                        {getTimeReadableFormat(message.timestamp)}
                      </p>
                    </div>
                  </div>
                ) : (
                  // If 'from' key is not present, it's a sent message
                  <div className="flex justify-end mb-2">
                    <div
                      className="rounded py-2 px-3"
                      style={{ backgroundColor: "#E2F7CB" }} // Customize the sent message background color
                    >
                      <p className="text-sm mt-1">{message.text}</p>
                      <p className="text-right text-xs text-grey-dark mt-1">
                        {getTimeReadableFormat(message.timestamp)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Input */}
        <div className="bg-grey-lighter px-4 py-4 flex items-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                opacity=".45"
                fill="#263238"
                d="M9.153 11.603c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962zm-3.204 1.362c-.026-.307-.131 5.218 6.063 5.551 6.066-.25 6.066-5.551 6.066-5.551-6.078 1.416-12.129 0-12.129 0zm11.363 1.108s-.669 1.959-5.051 1.959c-3.505 0-5.388-1.164-5.607-1.959 0 0 5.912 1.055 10.658 0zM11.804 1.011C5.609 1.011.978 6.033.978 12.228s4.826 10.761 11.021 10.761S23.02 18.423 23.02 12.228c.001-6.195-5.021-11.217-11.216-11.217zM12 21.354c-5.273 0-9.381-3.886-9.381-9.159s3.942-9.548 9.215-9.548 9.548 4.275 9.548 9.548c-.001 5.272-4.109 9.159-9.382 9.159zm3.108-9.751c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962z"
              ></path>
            </svg>
          </div>
          <form onSubmit={handleSubmit(sendEmail)} className="flex w-full">
            <div className="flex-1 mx-4">
              <input
                onChange={(e: any) => setMessageData(e.target.value)}
                className="w-full border rounded px-2 py-2"
                type="text"
                placeholder="Write Message ..."
                value={messageData}
              />
            </div>
            <button className="cursor-pointer w-[40px]" type="submit">
              {isSending ? <DotLoader /> : <MdSend />}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WhatsappNew;
