import {
  Autocomplete,
  Box,
  Button,
  Switch,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import _airportsData from "../../../../../../components/FormInputs/LocationBox/data/airports.json";
import _airlineData from "../../../../../../components/FormInputs/AirlineBox/data/airlines.json";
import { useEffect, useState } from "react";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { toast } from "react-toastify";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";

const DestinationDetail = ({
  insertDestinationData,
  setLogoFile,
  setFeatureFile,
  pageData,
}: any) => {
  const [homePageHotDestination, setHomePageHotDestination] = useState(
    pageData?.show_in_home ? true : false
  );
  const [footerFlightLinks, setFooterFlightLinks] = useState(
    pageData?.show_in_footer ? true : false
  );
  const [featureDestination, setFeatureDestination] = useState(
    pageData?.show_featured_destination ? true : false
  );

  const [showOfflineFares, setShowOfflineFares] = useState<any>(
    pageData?.offline_fares
  );

  const [seoFriendlySlug, setSeoFriendlySlug] = useState<any>(
    pageData?.slug || ""
  );

  const [mainHead, setMainHead] = useState<any>(pageData?.main_heading || "");

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file?.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };

  const handleFeatureUpload = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setFeatureFile({ file });
    }
  };

  const depVal = watch("departure");
  const arrVal = watch("arrival");

  const onSubmit = (inpData: any) => {
    insertDestinationData(inpData);
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Destination Base Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("departure", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.city_name
                          .toLowerCase()
                          .startsWith(inputValueLowerCase) ||
                        option.iata_code
                          .toLowerCase()
                          .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airportsData ? _airportsData : []}
                  getOptionLabel={(option: any) =>
                    `${option.city_name || ""}-${option.iata_code}`
                  }
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.city_name}-{option.iata_code}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = _airportsData.find(
                      (option) => option.iata_code === pageData?.origin_iata
                    );
                    field.onChange(data);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Departure"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["departure"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("arrival", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.city_name
                          .toLowerCase()
                          .startsWith(inputValueLowerCase) ||
                        option.iata_code
                          .toLowerCase()
                          .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airportsData ? _airportsData : []}
                  getOptionLabel={(option: any) =>
                    `${option.city_name || ""}-${option.iata_code}`
                  }
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.city_name}-{option.iata_code}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = _airportsData.find(
                      (option) =>
                        option.iata_code === pageData?.destination_iata
                    );
                    field.onChange(data);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Arrival"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["arrival"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("airline", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option: any) =>
                      option.marketing_name
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airlineData ? _airlineData : []}
                  getOptionLabel={(option: any) => option.marketing_name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.marketing_name}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = _airlineData.find(
                      (option) => option.iata === pageData?.airline_iata
                    );
                    field.onChange(data);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Airline"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["airline"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              error={errors["slug"] ? true : false}
              {...register("slug", { required: true })}
              label="SEO Friendly Slug"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo friendly slug for ${
                            depVal?.name || "departure"
                          }  ${
                            arrVal?.name || "arrival"
                          } : without quotes and short`
                        );
                        setValue('slug', res)
                        await typingEffect(setSeoFriendlySlug, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={seoFriendlySlug}
              onChange={(e: any) => setSeoFriendlySlug(e.target.value)}
            />
            <TextField
              {...register("mainHeading")}
              label="Main Heading"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an seo freindly heading ${
                            depVal?.name || "departure"
                          }  ${
                            arrVal?.name || "arrival"
                          } : without quotes, 4-6 words and without quotes`
                        );
                        setValue('mainHeading', res)
                        await typingEffect(setMainHead, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={mainHead}
              onChange={(e: any) => setMainHead(e.target.value)}
            />
            <TextField
              defaultValue={pageData?.base_price}
              error={errors["basePrice"] ? true : false}
              {...register("basePrice", { required: true })}
              label="Base Price ( Starting From)"
              type="number"
            />
            <div className="md:col-span-2">
              <label className="mb-2">Background Image for Hero Section</label>
              <TextField
                sx={{ width: "100%" }}
                // {...register("backgroundImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={handleLogoUpload}
                helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
              />
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:col-span-2 xl:gap-28 gap-5 w-full">
              <div className="flex justify-between items-center ">
                <label>Home Page Hot Destination</label>
                <Switch
                  {...register("homePageHotDestination")}
                  value={homePageHotDestination}
                  checked={homePageHotDestination}
                  onChange={() =>
                    setHomePageHotDestination(!homePageHotDestination)
                  }
                />
              </div>
              <div className="flex justify-between items-center">
                <label>Offline Fares</label>
                <Switch
                  {...register("offline_fares")}
                  value={showOfflineFares}
                  checked={showOfflineFares}
                  onChange={() => setShowOfflineFares(!showOfflineFares)}
                />
              </div>
              <div className="flex justify-between items-center">
                <label>Featured Destination</label>
                <Switch
                  {...register("featureDestination")}
                  value={featureDestination}
                  checked={featureDestination}
                  onChange={() => setFeatureDestination(!featureDestination)}
                />
              </div>
            </div>
            {featureDestination && (
              <div>
                <label className="mb-2">Featured Image</label>
                <TextField
                  sx={{ width: "100%" }}
                  // {...register("featuredImage")}
                  type="file"
                  inputProps={{
                    style: {
                      paddingTop: "7px",
                      paddingBottom: "27px",
                      paddingLeft: "23px",
                    },
                  }}
                  variant="outlined"
                  onChange={handleFeatureUpload}
                  helperText="SVG, PNG, JPG or GIF (MAX. 322x450px)"
                />
              </div>
            )}
          </div>
          <Button sx={{ marginTop: "25px" }} type="submit" variant="contained">
            Save Destination
          </Button>
        </Box>
      </div>
    </>
  );
};

export default DestinationDetail;
