import { useState } from "react";
import Footer from "./component/Footer";
import Header from "./component/Header";
import ReplyMessage from "./component/ReplyMessage";
import TextMessage from "./component/TextMessage";
import Recording from "./component/Recording";
import VoiceMessage from "./component/VoiceMessage";
import CloseIcon from "@mui/icons-material/Close";
import VideoMessage from "./component/VideoMessage";
import DocumentMessage from "./component/DocumentMessage";
import DocPrevSend from "../../../modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/AttachemnetPrevSend";

const WhatsappChattingBox = ({
  showWhatsappSidebar,
  showUserInfo,
  setShowWhatsappSidebar,
  setShowUserInfo,
  setShowWhatsappChatBox,
  showMessageInfo,
  setShowMessageInfo,
  setShowReplyMessage,
  showReplyMessage,
}: any) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const [showRecording, setShowRecording] = useState(false);
  const [showDocSndPrev, setShowDocSndPrev] = useState(false);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="border dark:border-dark-secondary w-full flex flex-col">
      <Header
        setShowUserInfo={setShowUserInfo}
        showWhatsappSidebar={showWhatsappSidebar}
        setShowWhatsappChatBox={setShowWhatsappChatBox}
        setShowWhatsappSidebar={setShowWhatsappSidebar}
        showUserInfo={showUserInfo}
      />
      {showDocSndPrev ? (
        <div className="flex-grow bg-whatsappPrimaryBg dark:bg-dark-secondary">
          <DocPrevSend
            setShowDocSndPrev={setShowDocSndPrev}
            showDocSndPrev={showDocSndPrev}
            setShowEmojis={setShowEmojis}
            showEmojis={showEmojis}
          />
        </div>
      ) : (
        <>
          <div className="bg-[#EFEAE2] dark:bg-[#38344e] overflow-scroll flex-grow md:px-[63px] px-[15px] py-[20px]">
            <div className="flex items-center justify-center">
              <p className="bg-[#FFFFFFF2] text-[13px] px-2 py-1 text-primary rounded shadow">
                12/14/2023
              </p>
            </div>
            <TextMessage
              setShowReplyMessage={setShowReplyMessage}
              showMessageInfo={showMessageInfo}
              setShowMessageInfo={setShowMessageInfo}
              setShowUserInfo={setShowUserInfo}
              setShowWhatsappChatBox={setShowWhatsappChatBox}
            />
            <TextMessage setShowReplyMessage={setShowReplyMessage} />
            <TextMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
            />
            <TextMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
            />
            <VoiceMessage setShowReplyMessage={setShowReplyMessage} />
            <VoiceMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
            />
            <TextMessage
              setShowReplyMessage={setShowReplyMessage}
              messageType="messageReply"
            />
            <TextMessage
              setShowReplyMessage={setShowReplyMessage}
              messageType="messageReply"
              type="recieve"
            />
            <VoiceMessage
              setShowReplyMessage={setShowReplyMessage}
              messageType="messageReply"
            />
            <VoiceMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
              messageType="messageReply"
            />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              video={true}
            />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
              video={true}
            />
            <VideoMessage setShowReplyMessage={setShowReplyMessage} />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
            />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              messageType="messageReply"
              message="photoMessage"
            />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
              messageType="messageReply"
              message="photoMessage"
            />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              messageType="messageReply"
              message="videoMessage"
              video={true}
            />
            <VideoMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
              messageType="messageReply"
              message="videoMessage"
              video={true}
            />
            <DocumentMessage
              messageType="messageReply"
              setShowReplyMessage={setShowReplyMessage}
            />
            <DocumentMessage
              messageType="messageReply"
              type="recieve"
              setShowReplyMessage={setShowReplyMessage}
            />
            <DocumentMessage setShowReplyMessage={setShowReplyMessage} />
            <DocumentMessage
              setShowReplyMessage={setShowReplyMessage}
              type="recieve"
            />
          </div>
          {showReplyMessage && (
            <div className="bg-whatsappPrimaryBg dark:bg-dark-primary pt-[7px] flex items-center px-[22px] pl-[60px]">
              <ReplyMessage
                setShowReplyMessage={setShowReplyMessage}
                message="textMessage"
                type="recieve"
              />
              <CloseIcon
                sx={{
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                  marginLeft: "20px",
                }}
                fontSize="small"
                onClick={() => setShowReplyMessage(false)}
              />
            </div>
          )}
          {showRecording ? (
            <Recording setShowRecording={setShowRecording} />
          ) : (
            <Footer
              setShowEmojis={setShowEmojis}
              showEmojis={showEmojis}
              setShowRecording={setShowRecording}
              setShowDocSndPrev={setShowDocSndPrev}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WhatsappChattingBox;
