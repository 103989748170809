import React from "react";

const RingSVG1 = () => {
  return (
    <svg
      width="246"
      height="193"
      viewBox="0 0 246 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_143_7126)">
        <path
          d="M75.6252 163.704C69.5537 163.704 63.0527 162.813 55.9912 160.884C17.6775 150.375 -3.39987 135.606 -16.7596 109.888C-30.1909 84.0356 -36.2743 47.5152 -43.4193 -15.0893C-46.6161 -43.0654 -36.6679 -68.1487 -14.6602 -87.6173C19.7053 -117.998 80.0267 -130.35 128.754 -116.985C152.873 -110.369 178.411 -93.2931 198.833 -70.1261C219.003 -47.2521 231.791 -21.3021 233.914 1.05923C236.192 24.9464 226.447 43.06 205.751 53.4473C181.716 65.5069 165.111 87.8316 149.068 109.424C128.408 137.217 108.702 163.704 75.6133 163.704H75.6252ZM87.3388 -121.294C49.4783 -121.294 10.8902 -108.819 -13.9922 -86.8117C-35.7375 -67.5873 -45.5545 -42.8213 -42.4054 -15.2114C-35.2723 47.2589 -29.2008 83.6816 -15.853 109.387C-2.64839 134.812 18.2381 149.435 56.2536 159.859C63.172 161.751 69.5656 162.63 75.5178 162.63C108.213 162.63 127.752 136.35 148.245 108.789C164.372 87.0992 181.048 64.6768 205.298 52.5075C225.576 42.3277 235.107 24.5802 232.888 1.16908C230.777 -20.9726 218.085 -46.7028 198.057 -69.4182C177.767 -92.4387 152.408 -109.405 128.468 -115.96C115.346 -119.56 101.39 -121.281 87.3269 -121.281L87.3388 -121.294Z"
          fill="url(#paint0_linear_143_7126)"
        />
        <path
          d="M84.4766 164.572C80.8265 164.572 76.9856 164.291 72.9419 163.717C33.662 158.066 10.9743 146.031 -5.33166 122.18C-21.7331 98.1955 -32.1226 62.7126 -46.6752 1.46297C-53.1761 -25.9151 -46.2935 -52.0481 -26.7668 -74.1043C3.72193 -108.562 62.111 -128.348 112.067 -121.158C136.794 -117.606 164.182 -103.838 187.215 -83.3931C209.962 -63.2043 225.744 -39.0365 230.515 -17.1145C235.62 6.30876 228.105 25.5088 208.805 38.3983C186.38 53.3751 172.567 77.604 159.207 101.027C140.623 133.617 122.969 164.572 84.4766 164.572ZM73.085 162.668C119.081 169.271 138.142 135.875 158.313 100.502C171.732 76.9693 185.617 52.6305 208.233 37.5195C227.151 24.8863 234.499 6.07684 229.501 -16.8826C224.765 -38.5971 209.104 -62.5452 186.523 -82.5997C163.645 -102.91 136.448 -116.593 111.912 -120.121C62.278 -127.261 4.27063 -107.622 -26.0153 -73.4086C-45.3034 -51.6209 -52.1026 -25.8174 -45.6851 1.20665C-31.1684 62.3221 -20.8027 97.7194 -4.49668 121.57C11.6304 145.164 34.1153 157.065 73.085 162.668Z"
          fill="url(#paint1_linear_143_7126)"
        />
        <path
          d="M91.7284 164.413C91.1916 164.413 90.6548 164.413 90.1061 164.401C50.4445 163.693 26.4925 154.575 7.4668 132.934C-11.6781 111.171 -26.2068 77.2505 -47.9521 18.2711C-57.6737 -8.0938 -53.9521 -34.8859 -37.1928 -59.2125C-11.034 -97.2097 44.5877 -124.112 95.0087 -123.221C119.975 -122.769 148.793 -112.528 174.093 -95.0981C199.071 -77.8876 217.62 -55.8803 224.968 -34.7151C232.816 -12.0974 227.651 7.88383 210.033 23.0925C189.564 40.7424 178.733 66.5214 168.26 91.4461C152.491 128.967 137.593 164.438 91.7284 164.438V164.413ZM90.13 163.352C136.579 164.157 151.513 128.638 167.318 91.0188C177.839 65.9843 188.717 40.0954 209.365 22.2869C226.638 7.3956 231.695 -12.195 223.99 -34.3611C216.713 -55.3188 198.308 -77.1431 173.509 -94.2315C148.376 -111.54 119.76 -121.732 94.9848 -122.171C44.862 -123.062 -10.3899 -96.3309 -36.3578 -58.6022C-52.9024 -34.5686 -56.5882 -8.10601 -46.9979 17.905C-25.3122 76.75 -10.8074 110.597 8.21828 132.238C27.0412 153.636 50.7666 162.656 90.1181 163.364L90.13 163.352Z"
          fill="url(#paint2_linear_143_7126)"
        />
        <path
          d="M85.1549 164.242C57.9345 164.242 38.6106 157.309 21.4458 142.027C-0.144436 122.815 -18.6095 90.9576 -47.2136 35.1274C-60.0007 10.1783 -59.4878 -16.8824 -45.7583 -43.1253C-24.3231 -84.1008 27.6605 -117.753 77.8309 -123.148C102.666 -125.821 132.498 -119.242 159.671 -105.107C186.51 -91.1436 207.539 -71.614 217.356 -51.5108C227.841 -30.0404 225.098 -9.55878 209.412 7.7249C191.198 27.8038 183.516 54.7302 176.096 80.7778C164.788 120.447 154.101 157.907 107.21 162.948C99.3019 163.803 91.9779 164.23 85.143 164.23L85.1549 164.242ZM89.0435 -122.672C85.2504 -122.672 81.5406 -122.476 77.9383 -122.098C28.0779 -116.74 -23.5597 -83.3196 -44.8517 -42.6248C-58.4142 -16.6993 -58.9152 10.0196 -46.2951 34.6391C-17.7506 90.3473 0.666687 122.119 22.1257 141.234C43.3581 160.129 67.9901 166.11 107.115 161.911C153.313 156.943 163.906 119.8 175.118 80.4848C182.573 54.3274 190.291 27.2789 208.673 7.01695C224.036 -9.92496 226.72 -29.9916 216.45 -51.0347C206.728 -70.9305 185.866 -90.3014 159.218 -104.167C136.16 -116.166 111.194 -122.684 89.0555 -122.684L89.0435 -122.672Z"
          fill="url(#paint3_linear_143_7126)"
        />
        <path
          d="M85.8842 164.499C66.7869 164.499 51.2444 159.543 36.4175 149.302C12.704 132.922 -9.42303 103.603 -44.4565 51.7642C-60.1183 28.5973 -62.8499 1.67086 -52.3411 -26.0856C-35.9397 -69.429 11.6424 -109.306 60.7989 -120.926C85.1327 -126.675 115.514 -123.868 144.178 -113.224C172.484 -102.715 195.672 -85.9437 207.815 -67.2197C220.781 -47.2263 220.495 -26.5494 206.992 -7.4348C191.307 14.7679 186.893 42.4511 182.623 69.2188C176.122 109.999 169.979 148.521 124.043 159.372C109.693 162.766 97.1684 164.487 85.8842 164.487V164.499ZM87.4826 -122.794C78.1666 -122.794 69.2561 -121.842 61.0375 -119.901C12.1791 -108.354 -35.0928 -68.7455 -51.3868 -25.7072C-61.7645 1.70748 -59.0806 28.2921 -43.6215 51.1662C-8.65962 102.895 13.4078 132.141 36.99 148.423C60.3099 164.535 85.4667 167.404 123.816 158.347C169.084 147.655 175.168 109.474 181.609 69.0479C185.891 42.1581 190.328 14.3407 206.157 -8.0573C219.386 -26.7813 219.672 -47.0432 206.957 -66.6461C194.933 -85.187 171.923 -101.799 143.82 -112.235C125.033 -119.205 105.518 -122.806 87.4706 -122.806L87.4826 -122.794Z"
          fill="url(#paint4_linear_143_7126)"
        />
        <path
          d="M90.8095 164.364C76.9727 164.364 64.5315 161.13 52.138 154.66C26.6472 141.356 1.19215 115.015 -39.7577 67.9368C-58.0677 46.8815 -63.9722 20.5044 -56.851 -8.3506C-45.7338 -53.4151 -3.25711 -98.9313 44.1579 -116.593C67.6209 -125.333 98.1216 -126.334 127.835 -119.34C157.179 -112.431 182.204 -98.6871 196.482 -81.6232C211.739 -63.3874 213.91 -42.8325 202.781 -22.18C189.85 1.80478 188.777 29.8419 187.727 56.9514C186.129 98.2443 184.626 137.242 140.324 153.733C121.274 160.824 105.291 164.364 90.8215 164.364H90.8095ZM88.7817 -122.904C72.7382 -122.904 57.5296 -120.463 44.5038 -115.605C-2.61298 -98.0646 -44.8153 -52.8414 -55.861 -8.09428C-62.8987 20.4189 -57.0657 46.4665 -38.9943 67.2411C1.87206 114.222 27.2555 140.501 52.5912 153.733C77.6526 166.805 102.964 166.525 139.942 152.756C183.6 136.498 185.091 97.8415 186.677 56.9148C187.727 29.6832 188.824 1.52404 201.862 -22.6804C212.765 -42.918 210.629 -63.0579 195.683 -80.9274C181.548 -97.8327 156.725 -111.455 127.596 -118.314C114.583 -121.378 101.426 -122.892 88.7817 -122.892V-122.904Z"
          fill="url(#paint5_linear_143_7126)"
        />
        <path
          d="M97.5254 163.485C87.6249 163.485 78.0942 161.63 68.3607 158.005C41.4743 147.984 13.073 125.012 -33.1731 83.3896C-53.8329 64.7877 -62.8507 39.3382 -59.2126 9.81195C-53.5466 -36.3023 -16.8074 -86.7863 28.1504 -110.222C50.3967 -121.817 80.5514 -126.614 110.873 -123.392C140.825 -120.194 167.294 -109.672 183.493 -94.5127C200.801 -78.3153 205.417 -58.1876 196.829 -36.3023C186.857 -10.8772 189.123 17.0745 191.306 44.1108C194.634 85.2938 197.783 124.182 155.783 146.092C133.286 157.822 114.857 163.485 97.5374 163.485H97.5254ZM92.3485 -123.319C68.7066 -123.319 46.174 -118.436 28.6156 -109.282C-16.056 -85.9929 -52.5685 -35.8507 -58.1987 9.93401C-61.7891 39.1063 -52.8906 64.2385 -32.4932 82.5963C13.6575 124.133 41.9872 147.056 68.7186 157.016C95.1636 166.866 120.249 163.437 155.306 145.152C196.697 123.572 193.584 85.013 190.28 44.1962C188.085 17.0379 185.807 -11.0481 195.874 -36.6929C204.284 -58.1388 199.763 -77.8637 182.801 -93.7315C166.757 -108.745 140.503 -119.169 110.766 -122.342C104.599 -123.001 98.432 -123.319 92.3485 -123.319Z"
          fill="url(#paint6_linear_143_7126)"
        />
        <path
          d="M104.718 161.727C98.1814 161.727 91.6209 160.909 84.8933 159.322C57.0168 152.731 26.0867 133.47 -24.7756 97.9263C-47.5109 82.0341 -59.487 57.8906 -59.3915 28.1324C-59.2603 -18.348 -28.8073 -73.0308 13.0252 -101.898C33.7209 -116.191 63.0764 -124.711 93.5652 -125.285C123.672 -125.846 151.203 -118.706 169.083 -105.67C188.192 -91.7548 195.171 -72.3473 189.254 -49.5587C182.383 -23.0839 187.954 4.37958 193.345 30.9398C201.552 71.3905 209.318 109.607 170.228 136.583C145.322 153.769 125.104 161.715 104.718 161.715V161.727ZM96.8813 -124.259C95.7839 -124.259 94.6864 -124.259 93.589 -124.223C63.3031 -123.649 34.1503 -115.202 13.6097 -101.019C-27.9724 -72.3351 -58.2345 -18.0184 -58.3657 28.1324C-58.4492 57.5366 -46.6282 81.3627 -24.1911 97.0474C26.5639 132.518 57.4105 151.73 85.1199 158.297C112.531 164.778 137.02 158.236 169.644 135.728C208.16 109.144 200.467 71.2684 192.332 31.1717C186.916 4.48943 181.31 -23.0961 188.252 -49.815C194.049 -72.152 187.214 -91.1689 168.487 -104.803C151.429 -117.229 125.473 -124.247 96.8813 -124.247V-124.259Z"
          fill="url(#paint7_linear_143_7126)"
        />
        <path
          d="M111.29 159.103C108.058 159.103 104.778 158.92 101.438 158.554C72.9886 155.49 39.9949 140.232 -14.7203 111.292C-39.1733 98.3538 -53.9286 75.8947 -57.3998 46.344C-62.8033 0.193184 -39.0898 -57.8829 -1.00272 -91.7545C17.844 -108.513 45.959 -120.634 76.1496 -125.004C105.958 -129.325 134.133 -125.663 153.445 -114.958C174.069 -103.533 183.302 -85.1389 180.141 -61.7766C176.479 -34.6427 185.282 -8.07026 193.799 17.6111C206.765 56.7435 219.015 93.7033 183.433 125.353C157.024 148.838 135.433 159.091 111.29 159.091V159.103ZM100.233 -125.724C92.4795 -125.724 84.4517 -125.138 76.2927 -123.966C46.293 -119.621 18.3689 -107.598 -0.334728 -90.9611C-38.1713 -57.297 -61.7417 0.388481 -56.3739 46.2098C-52.9505 75.4065 -38.386 97.5848 -14.2431 110.352C40.3647 139.232 73.263 154.452 101.557 157.504C129.541 160.519 153.063 150.974 182.765 124.56C217.822 93.3737 205.679 56.7313 192.832 17.9529C184.28 -7.85055 175.429 -34.5451 179.139 -61.9231C182.228 -84.8215 173.186 -102.838 152.968 -114.043C139.119 -121.72 120.63 -125.736 100.245 -125.736L100.233 -125.724Z"
          fill="url(#paint8_linear_143_7126)"
        />
        <path
          d="M115.894 155.747C87.6482 155.747 53.3543 144.725 -3.15006 123.303C-28.9629 113.514 -46.2828 93.0569 -53.237 64.1532C-64.1037 19.0398 -47.4757 -41.5629 -13.7185 -79.9386C2.99302 -98.9311 29.4619 -114.469 58.901 -122.562C87.9822 -130.557 116.372 -130.447 136.817 -122.232C158.646 -113.456 170.001 -96.3556 169.655 -72.7736C169.25 -45.3834 181.154 -20.117 192.665 4.31938C210.2 41.5354 226.756 76.7009 195.206 112.55C168.665 142.723 146.228 155.222 117.779 155.722C117.159 155.722 116.527 155.735 115.894 155.735V155.747ZM100.543 -127.42C87.5051 -127.42 73.4178 -125.455 59.1634 -121.537C29.9033 -113.481 3.62522 -98.0645 -12.9551 -79.2184C-46.4975 -41.0991 -63.0302 19.1008 -52.247 63.909C-45.3763 92.4588 -28.283 112.66 -2.79221 122.315C53.5929 143.7 87.8033 154.697 115.894 154.697C116.515 154.697 117.135 154.697 117.755 154.685C145.894 154.197 168.105 141.783 194.442 111.854C225.528 76.53 209.114 41.6819 191.735 4.7832C180.176 -19.763 168.212 -45.1515 168.63 -72.7859C168.975 -95.8918 157.846 -112.651 136.447 -121.244C126.201 -125.357 113.926 -127.42 100.555 -127.42H100.543Z"
          fill="url(#paint9_linear_143_7126)"
        />
        <path
          d="M116.122 152.073C90.4999 152.073 58.0192 145.494 9.76914 133.788C-17.0218 127.295 -36.6439 109.157 -46.9977 81.327C-63.1486 37.8981 -53.8684 -24.328 -24.9303 -66.6218C-10.6044 -87.555 13.8128 -106.279 42.071 -117.985C69.9833 -129.544 98.1818 -132.974 119.45 -127.371C142.161 -121.39 155.473 -105.827 157.931 -82.3797C160.793 -55.1481 175.608 -31.5417 189.946 -8.72875C211.775 26.0339 232.399 58.8558 205.358 98.3788C182.598 131.628 161.831 146.849 133.645 150.889C128.122 151.682 122.325 152.073 116.122 152.073ZM97.4899 -128.958C80.5637 -128.958 61.4545 -124.881 42.4646 -117.008C14.3854 -105.376 -9.86486 -86.786 -24.0834 -66.0115C-52.8426 -23.9862 -62.0751 37.8249 -46.0315 80.9609C-35.8089 108.437 -16.4374 126.355 10.0077 132.763C69.8162 147.276 105.339 153.892 133.513 149.851C161.378 145.848 181.954 130.761 204.523 97.7807C231.171 58.8436 210.725 26.3024 189.087 -8.15507C174.69 -31.0779 159.792 -54.7819 156.917 -82.2698C154.507 -105.254 141.47 -120.487 119.199 -126.358C112.579 -128.103 105.267 -128.958 97.5019 -128.958H97.4899Z"
          fill="url(#paint10_linear_143_7126)"
        />
        <path
          d="M106.233 148.887C84.7267 148.887 58.2459 146.494 23.8447 142.588C-3.5189 139.476 -25.1569 123.925 -38.7432 97.597C-59.9517 56.4994 -58.1506 -6.42246 -34.4609 -51.9996C-22.7354 -74.5563 -0.727676 -96.1853 25.9202 -111.333C52.2459 -126.297 79.8242 -133.218 101.593 -130.301C124.854 -127.201 139.907 -113.408 145.144 -90.4363C151.227 -63.754 168.75 -42.186 185.688 -21.326C211.501 10.4462 235.882 40.4607 213.743 83.0596C195.123 118.909 176.312 136.595 148.818 144.126C136.71 147.446 122.969 148.899 106.21 148.899L106.233 148.887ZM91.4185 -129.91C71.7487 -129.91 48.6436 -123.051 26.4212 -110.43C-0.0596924 -95.3797 -21.9243 -73.9094 -33.5544 -51.5236C-57.1009 -6.20275 -58.902 56.2919 -37.8366 97.1087C-24.4173 123.108 -3.0537 138.475 23.952 141.539C85.0369 148.484 121.096 150.62 148.567 143.101C175.752 135.655 194.372 118.115 212.849 82.5592C234.654 40.5828 210.499 10.8368 184.913 -20.6546C167.891 -41.6123 150.285 -63.278 144.154 -90.2044C139.024 -112.712 124.269 -126.212 101.474 -129.264C98.2534 -129.691 94.8897 -129.91 91.4305 -129.91H91.4185Z"
          fill="url(#paint11_linear_143_7126)"
        />
        <path
          d="M37.825 149.57C10.7477 149.57 -12.2143 136.839 -28.6157 112.72C-54.5717 74.5641 -60.2735 11.8986 -42.1901 -36.3029C-33.2439 -60.1534 -13.9678 -84.3701 10.6761 -102.728C35.0218 -120.866 61.5384 -131.204 83.5342 -130.997C106.985 -130.814 123.578 -119.011 131.51 -96.869C140.719 -71.151 160.687 -51.9265 179.987 -33.3246C209.39 -5.00671 237.159 21.7488 220.268 66.7889C206.05 104.689 189.493 124.596 163.108 135.496C136.579 146.457 100.449 148.838 38.8627 149.57C38.5168 149.57 38.1709 149.57 37.825 149.57ZM82.9855 -129.947C61.3476 -129.947 35.2484 -119.743 11.2725 -101.886C-13.2163 -83.6377 -32.3612 -59.6041 -41.2359 -35.9367C-59.1999 11.9718 -53.5459 74.2224 -27.7807 112.11C-11.3674 136.241 11.6542 148.874 38.8389 148.508C100.305 147.776 136.329 145.42 162.714 134.52C188.802 123.742 205.203 104.005 219.302 66.4105C235.954 22.0296 208.424 -4.48186 179.283 -32.5556C159.887 -51.2308 139.836 -70.5529 130.544 -96.5028C122.766 -118.205 106.508 -129.764 83.5223 -129.947C83.3434 -129.947 83.1645 -129.947 82.9855 -129.947Z"
          fill="url(#paint12_linear_143_7126)"
        />
        <path
          d="M43.2873 155.393C20.4923 155.393 -0.0959206 145.518 -16.7717 126.452C-47.0696 91.8237 -60.2027 30.33 -48 -19.7634C-41.9643 -44.5538 -25.7179 -70.992 -3.43585 -92.2914C18.5719 -113.322 43.7048 -126.859 65.5098 -129.422C88.8177 -132.168 106.686 -122.513 117.195 -101.531C129.398 -77.156 151.501 -60.5558 172.877 -44.505C205.429 -20.0564 236.18 3.02516 224.777 49.8351C215.174 89.2239 201.111 111.048 176.216 125.158C151.191 139.342 115.609 146.214 54.5715 154.611C50.7545 155.136 46.9851 155.405 43.2754 155.405L43.2873 155.393ZM73.0485 -128.824C70.639 -128.824 68.1698 -128.677 65.6291 -128.372C44.0269 -125.833 19.1087 -112.395 -2.73208 -91.5102C-24.871 -70.3572 -40.9981 -44.1144 -46.998 -19.5071C-59.1291 30.2689 -46.1034 91.3599 -16.0202 125.756C3.14858 147.666 27.5062 157.284 54.4403 153.574C115.358 145.188 150.845 138.341 175.727 124.243C200.335 110.291 214.268 88.6624 223.787 49.591C235.023 3.46458 204.546 -19.4216 172.268 -43.6505C150.797 -59.7746 128.599 -76.4358 116.277 -101.043C107.128 -119.315 92.2769 -128.824 73.0485 -128.824Z"
          fill="url(#paint13_linear_143_7126)"
        />
        <path
          d="M50.1342 160.458C30.9893 160.458 12.7747 153.085 -3.38814 138.609C-37.5747 108.021 -57.9363 48.6266 -51.7813 -2.61413C-48.7396 -27.9782 -35.7616 -56.235 -16.1872 -80.1588C3.14857 -103.777 26.4803 -120.353 47.8201 -125.614C70.6151 -131.241 89.5095 -123.893 102.44 -104.375C117.458 -81.6967 141.374 -67.9894 164.503 -54.7215C199.727 -34.5206 232.995 -15.4426 227.258 32.4415C222.427 72.7334 211.059 96.1445 188.037 113.257C164.885 130.456 130.388 141.71 70.8059 157.663C63.8398 159.53 56.9333 160.458 50.1461 160.458H50.1342ZM62.1341 -126.395C57.6371 -126.395 52.9373 -125.797 48.0587 -124.589C26.9217 -119.377 3.7927 -102.923 -15.4 -79.4752C-34.855 -55.7102 -47.7376 -27.6486 -50.7555 -2.47986C-56.8628 48.4435 -36.6562 107.447 -2.70823 137.828C18.9178 157.187 44.2535 163.692 70.5435 156.65C130.006 140.721 164.407 129.516 187.429 112.403C210.2 95.4854 221.437 72.2818 226.232 32.3072C231.898 -14.8812 198.916 -33.7882 164.002 -53.806C140.765 -67.1228 116.742 -80.9033 101.593 -103.79C91.6924 -118.742 78.2254 -126.407 62.146 -126.407L62.1341 -126.395Z"
          fill="url(#paint14_linear_143_7126)"
        />
        <path
          d="M57.9228 164.596C42.0105 164.596 26.2651 159.371 11.3547 149.021C-26.2314 122.912 -53.4995 66.496 -53.4995 14.8647C-53.4995 -10.6825 -43.9926 -40.3553 -27.4123 -66.5371C-11.0347 -92.3894 10.15 -111.748 30.7024 -119.645C52.6625 -128.08 72.2845 -123.136 87.4454 -105.377C105.052 -84.7484 130.423 -74.117 154.96 -63.8396C192.331 -48.1793 227.627 -33.3978 227.627 14.8524C227.627 55.4496 219.134 80.1057 198.319 99.9526C177.385 119.909 144.499 135.387 87.2545 158.651C77.5211 162.606 67.6921 164.571 57.9228 164.571V164.596ZM50.8136 -122.575C44.5035 -122.575 37.9071 -121.269 31.0722 -118.644C10.7106 -110.833 -10.2832 -91.6204 -26.5415 -65.9512C-43.0265 -39.9525 -52.4737 -10.4872 -52.4737 14.8647C-52.4737 66.1664 -25.3845 122.216 11.9273 148.142C35.7004 164.657 61.6206 167.952 86.8728 157.687C143.998 134.471 176.812 119.043 197.615 99.1959C218.204 79.5686 226.589 55.1322 226.589 14.8647C226.589 -32.6777 191.603 -47.3249 154.566 -62.8509C129.922 -73.1772 104.443 -83.8574 86.6701 -104.681C76.531 -116.557 64.3522 -122.587 50.8016 -122.587L50.8136 -122.575Z"
          fill="url(#paint15_linear_143_7126)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_143_7126"
          x="-71"
          y="-131"
          width="316.239"
          height="323.595"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="17" />
          <feGaussianBlur stdDeviation="5.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_143_7126"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_143_7126"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_143_7126"
          x1="2.61199"
          y1="-149.856"
          x2="214.236"
          y2="226.985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_143_7126"
          x1="4.86705"
          y1="-151.124"
          x2="216.479"
          y2="225.717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_143_7126"
          x1="6.82267"
          y1="-152.222"
          x2="218.447"
          y2="224.618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_143_7126"
          x1="7.45389"
          y1="-152.589"
          x2="219.078"
          y2="224.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_143_7126"
          x1="4.70009"
          y1="-151.026"
          x2="216.324"
          y2="225.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_143_7126"
          x1="1.55"
          y1="-149.269"
          x2="213.174"
          y2="227.584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_143_7126"
          x1="-1.81356"
          y1="-147.377"
          x2="209.81"
          y2="229.464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_143_7126"
          x1="-5.35628"
          y1="-145.388"
          x2="206.268"
          y2="231.453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_143_7126"
          x1="-9.04239"
          y1="-143.313"
          x2="202.581"
          y2="233.528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_143_7126"
          x1="-12.8597"
          y1="-141.176"
          x2="198.764"
          y2="235.677"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_143_7126"
          x1="-16.7713"
          y1="-138.979"
          x2="194.853"
          y2="237.874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_143_7126"
          x1="-17.7613"
          y1="-138.418"
          x2="193.863"
          y2="238.423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_143_7126"
          x1="-14.2421"
          y1="-140.396"
          x2="197.382"
          y2="236.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_143_7126"
          x1="-10.7956"
          y1="-142.324"
          x2="200.816"
          y2="234.517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_143_7126"
          x1="-7.71811"
          y1="-144.057"
          x2="203.906"
          y2="232.783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_143_7126"
          x1="-5.22563"
          y1="-145.461"
          x2="206.398"
          y2="231.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#27AE60" />
          <stop offset="1" stop-color="#2980B9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RingSVG1;
