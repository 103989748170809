import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { GET_DEF_EMAIL_TEMPLATES } from "./graphql/Query";
import { RESTORE_EMAIL_TEMPLATE } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

export default function HeaderSection({ brd_id, templates, heading }: any) {
  const template_ids: any = [];
  templates.map((temp: any) => {
    template_ids.push(temp.template_id);
  });
  const [restoreEmailTemplate] = useMutation(RESTORE_EMAIL_TEMPLATE);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const restoreTemplates = async () => {
    setIsLoading(true);
    const defEamil = await client.query({
      query: GET_DEF_EMAIL_TEMPLATES,
      variables: {
        template_ids,
      },
    });

    const templates: any = [];
    defEamil?.data?.def_emails_templates.map((temp: any) => {
      const template = {
        brd_id,
        subject: temp.subject,
        content: temp.content,
        notification_content: temp.notification_content,
        template_id: temp.id,
      };
      templates.push(template);
    });

    const restoreRes = await restoreEmailTemplate({
      variables: {
        templates,
      },
    });

    await client.resetStore();

    toast.success("Templates Updates successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 100,
    });

    setIsLoading(false);
  };

  return (
    <div className="w-full text-primary dark:text-dark-primary flex justify-between">
      <h1 className="font-bold pl-4 pt-4">{heading}</h1>
      <Button
        onClick={() => {
          restoreTemplates();
        }}
        variant="contained"
      >
        {isLoading ? "Updating.." : "Get New Templates"}
      </Button>
    </div>
  );
}
