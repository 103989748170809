import React from "react";
import FlightInvoicePDF from "../FlightInvoice";
import CarInvoicePDF from "../CarInvoice";
import StayInvoicePDF from "../StayInvoice";
import TrainInvoicePDF from "../TrainInvoice";
import { View } from "@react-pdf/renderer";

const InvoiceDownloadPDF = ({ invoice, inquiry }: any) => {
  const staySuggestions =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "stay"
    ) || [];
  const carSuggestions =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "car"
    ) || [];
  const trainSuggestions =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "train"
    ) || [];
  const flightSuggestions =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "flights"
    ) || [];

  return (
    <>
      {staySuggestions.length > 0 && (
        <>
          {staySuggestions.map((suggestion: any, index: number) => (
            <StayInvoicePDF
              key={suggestion.id || suggestion.type + Math.random()}
              invoice={invoice}
              inquiry={inquiry}
              index={index}
            />

            // <></>
          ))}
        </>
      )}

      {carSuggestions.length > 0 && (
        <>
          {carSuggestions.map((suggestion: any, index: number) => (
            <CarInvoicePDF key={index} invoice={invoice} inquiry={inquiry} />
          ))}
        </>
      )}

      {trainSuggestions.length > 0 && (
        <>
          {trainSuggestions.map((suggestion: any, index: number) => (
            <TrainInvoicePDF key={index} invoice={invoice} inquiry={inquiry} />
          ))}
        </>
      )}

      {flightSuggestions.length > 0 && (
        <>
          {flightSuggestions.map((suggestion: any, index: number) => (
            <FlightInvoicePDF key={index} invoice={invoice} inquiry={inquiry} />
          ))}
        </>
      )}
    </>
  );
};

export default InvoiceDownloadPDF;
