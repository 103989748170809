import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { INSERT_MAILJET_CREDENTIALS } from "./graphql/Mutation";
import { useMutation } from "@apollo/client";

const MailjetIntegrate = ({ brand }: any) => {

    const { brd_id } = useParams()
    const [insertMailjetCredentials] = useMutation(INSERT_MAILJET_CREDENTIALS)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data: any) => {
        const res = await insertMailjetCredentials({
            variables: {
                brdId: brd_id,
                apiKey: data.api_key,
                apiSecret: data.api_secret
            }
        })

        if (res?.data?.update_brd_detail?.affected_rows > 0) {
            toast.success("Mailjet Credentials Added Successfully!")
        }
    }

    return (
        <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
            <h1 className="text-xl">Integrate Mailjet</h1>
            <div className="mt-10">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-5">
                        <TextField
                            defaultValue={brand?.brd_details?.[0]?.mailjet_api_key}
                            placeholder="Enter your Mailjet API Key here"
                            error={errors["api_key"] ? true : false}
                            {...register("api_key", { required: true })}
                            label="API KEY"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            defaultValue={brand?.brd_details?.[0]?.mailjet_api_secret}
                            placeholder="Enter your Mailjet API Secret here"
                            error={errors["api_secret"] ? true : false}
                            {...register("api_secret", { required: true })}
                            label="API SECRET"
                            type="text"
                            fullWidth
                        />
                    </div>
                    <Button variant="contained" type="submit" sx={{ marginTop: "20px" }}>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    )
}

export default MailjetIntegrate