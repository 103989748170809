import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";

const chartSetting = {
  yAxis: [
    {
      label: "No. of Inquiries",
    },
  ],
  height: 500,
  sx: {
    [`.${axisClasses.right} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
  },
};

const valueFormatter = (value: number) => `${value}`;

export default function Bar({ dataset, keysArr }: any) {
  const Arr: any = keysArr.map((item: any) => {
    return {
      ...item,
      valueFormatter,
    };
  });

  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: "band", dataKey: "month" }]}
      series={[...Arr]}
      {...chartSetting}
    />
  );
}
