import moment from "moment";
import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { splitLocation } from "~/utils/splitLocation";
import vector81 from "../../../images/dottedImg.svg";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getStyles } from "../../../pdfDocument/styles";
import room from "../../../images/room.png";
const StayInvoicePDF = ({ invoice, inquiry }: any) => {
  const staySuggestion = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "stay"
  )?.[0]?.stay_inq_suggestion;

  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(staySuggestion?.where_to);
  const { cityName: hotelcity, remainingAddress: hotelAddres } = splitLocation(
    staySuggestion?.hotel_name
  );

  console.log("Filtered stay suggestions:", staySuggestion);

  if (!staySuggestion) return <div>No stay suggestions found</div>;
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  return (
    <View style={styles.inquiryDetailContainer}>
      <View>
        <View style={styles.flightDetailHeader}>
          <Text style={styles.flightTypeText}>Stay Invoice</Text>
          <View style={styles.luggageSubContainer}>
            <Image style={styles.iconSize} src={room} />
            <Text style={styles.flightCabinText}>
              {staySuggestion?.room_type}
            </Text>
          </View>
        </View>
        <View style={styles.flightDetailBody}>
          <View style={styles.flightLegsParentContainer}>
            <View style={styles.flightLegs}>
              <View style={styles.flightLegContainers}>
                <View style={styles.flightLegSubContainers}>
                  <Text style={styles.flightLegArrDep}>{originCity}</Text>
                  <Text>({originAddress})</Text>
                </View>
                <Text style={styles.flightLegDateTime}>
                  {moment(staySuggestion?.start_date).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </Text>
              </View>

              <Image style={styles.dottedImg} src={vector81} />
              <View style={styles.flightLegContainerRight}>
                <View style={styles.flightLegSubContainerRight}>
                  <Text style={styles.flightLegArrDep}>Hotel Details</Text>
                  <Text style={styles.flightLegArrDep}>{hotelcity}</Text>
                  <Text>({hotelAddres})</Text>
                </View>
                <Text style={styles.flightLegDateTime}>
                  {moment(staySuggestion?.end_date).format("DD-MM-YYYY HH:mm")}
                </Text>
              </View>
            </View>
          </View>
          {/* );
                  })} */}
          {/* <View style={styles.luggageContainer}>
            <View style={styles.luggageSubContainer}>
              <DirectionsCarIcon fontSize="medium" style={{ color: "black" }} />
              <Text style={styles.luggageText}>{staySuggestion?.car_type}</Text>
            </View>
          </View> */}
          <View style={styles.flightLegSubContainerRight}>
            {staySuggestion?.stay_inq_suggestion_costs?.map(
              (cost: any, idx: any) => (
                <Text key={idx} style={styles.flightCabinText}>
                  Sale Price: £ {cost?.sale_price}
                </Text>
              )
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default StayInvoicePDF;
