import { gql } from "@apollo/client";

export const GET_BRD_SERVICES_CATEGORY = gql`
  query MyQuery($brdIds: [uuid!]!) {
    brd_services_category(where: { brd_id: { _in: $brdIds } }) {
      name
      brd_id
      id
      created_at
      updated_at
    }
  }
`;
