import React, { useEffect, useState } from "react";
import SearchForm from "./components/SearchForm";
import ResultsForm from "./components/ResultsForm";
import moment from "moment";
import { useForm } from "react-hook-form";

export default function Attendance() {
  const [date, setDates] = useState(moment(new Date()).subtract(1, "days"));
  const [loadForm, setLoadForm] = useState(false);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const dateChagne = watch("date");

  useEffect(() => {
    setLoadForm(false);
  }, [dateChagne]);

  const onSearch = (selectedDate: any) => {
    setDates(selectedDate); // Update the date with selectedDate from SearchForm
    setLoadForm(true);
  };

  return (
    <div>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 border-gray-200 mb-2">
        Mark & Update Attendance
      </h1>
      <SearchForm
        onSearch={onSearch} // Pass onSearch to SearchForm
      />
      {loadForm && <ResultsForm date={date} />}
    </div>
  );
}
