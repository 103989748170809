import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { DateTimeBox } from "components/FormInputs/DateTimeBox";
import useNotifications from "hooks/useNotifications";
import InputField from "~/components/FormInputs/InputField";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import CarPurchaseDetails from "../../../components/SelectedPurchaseDetails/components/carPurchaseDetails";
import { CAR_ADD_TICKET, ADD_TRANSACTION } from "../../../graphql/CarMutation";

export default function CarRequestForm({
  refundable,
  paidAmount,
  inquiry,
  fareExpiry,
  vendors,
  ticekts,
  tabs,
}: any) {
  const [isloading, setIsloading] = useState(false);
  const [addTicket] = useMutation(CAR_ADD_TICKET);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const client = useApolloClient();

  const alert = useNotifications();
  const [ticketPurchase, setTicketPurchase] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  }: any = useForm();

  // Custom validation function
  const validatecharge = (value: any) => {
    if (Number(value) <= paidAmount) {
      return true;
    } else {
      return "Paid amount must be greater then and equal to cancelation charges";
    }
  };

  const onSubmit = async (formData: any) => {
    try {
      const passengers =
        inquiry?.car_inq_details?.[0]?.car_inq_passengers || [];

      if (passengers.length === 0) {
        toast.error("No ticket can be added since no passengers were found.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return; // Stop execution if no passengers are found.
      }

      formData["car_inq_id"] = inquiry?.car_inq_details?.id;
      formData["status"] = "pending_approval";
      // formData["fair_expiry"] = moment(formData["fair_expiry"]).format(
      //   "YYYY-MM-DD HH:mm:ss"
      // );

      formData["void_time"] = moment(formData["void_time"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      formData["issue_date"] = moment(formData["issue_date"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      setIsloading(true);

      const payload = {
        car_inq_id: inquiry?.car_inq_details[0]?.id,
        //   pnr: formData["pnr_no"],
        //   ibe: formData["ibe_no"] || null,
        ticket_cost_price: formData["ticket_cost_price"] || null,
        ticket_attol_price: formData["ticket_attol_price"] || null,
        //   ticket_mis_cost: formData["ticket_mis_cost"] || null,
        void_time: formData["void_time"],
        vendor_id: formData["vendor_id"],
        created_at: formData["issue_date"],
        refundable: refundable,
        status: "pending_approval",
        //   cancelation_charges: formData["cancelation_charges"],
        //   status: formData["ibe_no"]
        //     ? formData["ibe_no"] === ""
        //       ? "pending_approval"
        //       : "awaiting_vendor"
        //     : "pending_approval",
        car_inq_ticket_files: {
          data: inquiry?.car_inq_details?.[0]?.car_inq_passengers?.map(
            (item: any) => ({
              car_passenger_id: item?.id,
            })
          ),
        },
      };

      // if (formData["ibe_no"] && formData["ibe_no"] !== "") {
      let ticket_attol_price = formData["ticket_attol_price"];
      let ticket_mis_cost = formData["ticket_mis_cost"];
      let ticket_cost_price = formData["ticket_cost_price"];
      //   const tenDigitCode: any = generateRandom10DigitCode();

      //   if (ticket_attol_price) {
      //     ticket_attol_price = parseFloat(ticket_attol_price);
      //   } else {
      //     ticket_attol_price = 0;
      //   }
      //   if (ticket_mis_cost) {
      //     ticket_mis_cost = parseFloat(ticket_mis_cost);
      //   } else {
      //     ticket_mis_cost = 0;
      //   }

      const rawTransactions: any = [
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 6,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: formData["issue_date"],
          created_at: formData["issue_date"],
          type: "debit",
          amount: parseFloat(ticket_cost_price),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 7,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: formData["issue_date"],
          created_at: formData["issue_date"],
          type: "debit",
          amount: parseFloat(ticket_attol_price),
        },
        // {
        //   brd_id: inquiry.brd_id,
        //   def_acc_id: 8,
        //   inq_id: inquiry.id,
        //   transactions_no: "randomNumber",
        //   date: formData["issue_date"],
        //   created_at: formData["issue_date"],
        //   type: "debit",
        //   amount: parseFloat(ticket_mis_cost),
        // },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 2,
          user_id: formData["vendor_id"],
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: formData["issue_date"],
          created_at: formData["issue_date"],
          type: "credit",
          amount: -(
            (parseFloat(ticket_cost_price) + parseFloat(ticket_attol_price))
            // parseFloat(ticket_mis_cost)
          ),
        },
      ];

      const transactions = rawTransactions.filter(
        (obj: any) => obj.amount !== 0
      );

      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      } catch (e) {
        console.error("error", e);
      }
      try {
        // Create Suggestion function call
        const res = await addTicket({
          variables: { ticket: { ...payload } },
        });
        console.log("sdasndmsandjdhfjsd", res);
        if (res.data?.insert_car_inq_ticket?.returning?.length > 0) {
          toast.success("Ticket Request generated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // await alert.newNotification(
          //   "admin",
          //   `Early ticket issuance request initiated by consultant`,
          //   "",
          //   ``
          // );
          await client.resetStore();
        }
        setIsloading(false);
        //   setHideForm(false); // hide form modal
      } catch (e) {
        setIsloading(false);
        //   setHideForm(false);
        console.error("error", e);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An error occurred during submission.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsloading(false);
    }
  };

  console.log("kshfsfhsdh", inquiry);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <label htmlFor="" className="text-[14px] mb-2 block">
              Select Vendor {"*"}
            </label>
            <select
              {...register("vendor_id", {
                required: "Vendor is required",
              })}
              aria-invalid={errors.vendor_id ? "true" : "false"}
              className="w-full px-3 py-3 border rounded focus:outline-none focus:border-basic leading-tight border-gray-200 dark:bg-dark-primary"
            >
              <option value="">--Select Vendor--</option>
              {vendors.map((vendor: any) => {
                return (
                  <option key={`${vendor?.id}`} value={`${vendor?.id}`}>
                    {vendor.displayName}
                  </option>
                );
              })}
            </select>
            {errors.vendor_id && (
              <p role="alert" className="text-red-500 text-[12px] m-1">
                {errors.vendor_id.message}
              </p>
            )}
          </div>
        </div>
        {/* <div className="grid grid-cols-1 gap-4">
          <div className="">
            <label htmlFor="" className="text-[14px] mb-2 block">
              Cancelation charges {"*"}
            </label>
            <input
              className="border border-gray-200 py-[5px] h-[44px] px-[15px] focus:outline-none focus:border-basic dark:bg-dark-primary text-[14px] rounded-[5px] w-full"
              type="number"
              placeholder="Cancelation charges"
              {...register("cancelation_charges", {
                required: "Charges are required",
                validate: validatecharge,
              })}
              aria-invalid={errors.cancelation_charges ? "true" : "false"}
            />
            {errors.cancelation_charges && (
              <p role="alert" className="text-red-500 text-[12px] m-1">
                {errors.cancelation_charges.message}
              </p>
            )}
          </div>
        </div> */}
        {/* 
        <div className="grid grid-cols-1 gap-4">
          <InputField
            register={register}
            errors={errors}
            type="text"
            fieldName="pnr_no"
            required={true}
            label=" PNR *"
            placeHolder="PNR"
            inputClasses="bg-white dark:bg-dark-primary focus:outline-none focus:border-basic"
          />
        </div> */}

        <div className="grid grid-cols-1 gap-4">
          <DateTimeBox
            control={control}
            setValue={setValue}
            errors={errors}
            fieldName={`void_time`}
            label="Void Time *"
            labelClasses="text-primary dark:text-dark-primary font-normal"
            inputClasses={
              "bg-white dark:bg-dark-primary focus:outline-none focus:border-basic py-2 mt-[2px]"
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-4">
          <DateTimeBox
            control={control}
            setValue={setValue}
            errors={errors}
            fieldName={`issue_date`}
            label="Issued Date *"
            labelClasses="text-primary dark:text-dark-primary font-normal"
            inputClasses={
              "bg-white dark:bg-dark-primary focus:outline-none focus:border-basic py-2 mt-[2px]"
            }
          />
        </div>

        <CarPurchaseDetails
          ticketPurchase={ticketPurchase}
          setTicketPurchase={setTicketPurchase}
          register={register}
          errors={errors}
          inquiry={inquiry}
          ticekts={ticekts}
        />

        {/* {fareExpiry ? ( */}
        <button
          type="submit"
          className={`text-white px-5 py-2 rounded hover:cursor-pointer bg-teal-700 transition-all mt-[20px]`}
        >
          {isloading ? "Requesting ..." : "Request"}
        </button>
        {/* ) : (
          <p role="alert" className="text-red-500 text-[12px] m-1">
            Fare expiry not found in selected suggestion!
          </p>
        )} */}
      </>
    </form>
  );
}
