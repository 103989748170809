import { gql } from "@apollo/client";

export const GET_INSTAGRAM_USER_INFO = gql`
  query GetInstagramUserInfo($accessToken: String!) {
    get_ig_profile_info(arg1: {access_token: $accessToken}) {
      id
      name
      profile_picture_url
      user_id
    }
  }
`;
