import useDefEmailTemplates from "~/hooks/useDefEmailTemplates";
import DefaultTemplates from "./components/DefaultTemplates";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const EmailTemplates = () => {

  const { defaultEmailTemplates } = useDefEmailTemplates();
  const navigate = useNavigate()

  const handleAddTemplate = () => {
    navigate('/super-admin/email-templates/add')
  }

  return (
    <div className="text-primary dark:text-dark-primary">
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-xl">All Email Templates</h1>
        <Button variant="contained" onClick={handleAddTemplate}>
          Add Template
        </Button>
      </div>
      {defaultEmailTemplates?.def_emails_templates?.map(
        (template: any, index: any) => (
          <DefaultTemplates key={template.id} template={template} />
        )
      )}
    </div>
  );
};

export default EmailTemplates;
