import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

const ReplyMessage = ({ replyType, message, type, scrollToMessage }: any) => {
  return (
    <>
      <div
        className="bg-black/5 border-l-2 border-[#06cf9c] rounded-lg flex items-center w-full cursor-pointer"
        onClick={() => {
          scrollToMessage(message?.id);
        }}
      >
        <div
          className={`px-[12px]  ${
            replyType === "messageReply" ? "my-1" : "my-5"
          }`}
        >
          <h1
            className={`text-[13px]  ${
              type === "send" ? "text-[#06cf9c]" : "text-[#009de2]"
            }`}
          >
            {type === "send" ? "You" : "User"}
          </h1>
          <p className="text-[12px] text-primary flex items-center">
            {(message.type === "text" || message.type === "template") && (
              <p
                dangerouslySetInnerHTML={{
                  __html: message.text,
                }}
              />
            )}
            {message.type === "audio" && (
              <>
                <MicOutlinedIcon sx={{ fontSize: "17px", color: "#009de2" }} />
                0.12
              </>
            )}
            {message.type === "image" && (
              <>
                <CameraAltIcon
                  sx={{
                    fontSize: "17px",
                    marginRight: "3px",
                  }}
                />
                Photo
              </>
            )}
            {message.type === "video" && (
              <>
                <VideocamIcon
                  sx={{
                    fontSize: "17px",
                    marginRight: "3px",
                  }}
                />
                Video
              </>
            )}
            {message.type === "document" && (
              <>
                <ArticleOutlinedIcon
                  sx={{
                    fontSize: "17px",
                    marginRight: "3px",
                  }}
                />
                Document
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReplyMessage;
