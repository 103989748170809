import { gql } from "@apollo/client";

export const GET_AIRLINE_PAGES = gql`
  query getAirlinePages($brd_id: uuid!) {
    brd_airlines_pages(where: { brd_id: { _eq: $brd_id } }) {
      id
      airline_iata
      airline_name
      brd_id
      created_at
    }
  }
`;
