import { gql } from "@apollo/client";

export const GET = gql`
    subscription MySubscription($ad_id: bigint) {
        brd_google_ad_update(
        where: {
            ad_id: { _eq: $ad_id }
        }
        ) {
            id
            ad_id
            date
            impression
            click
            cost
        }
    }`;