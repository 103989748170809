import React, { useEffect, useState } from "react";
import { useUserData, useUserId } from "@nhost/react";
import { useParams } from "react-router-dom";
import ConversationList from "./components/ConversationList";
import ChatWindow from "./components/ChatWindow";
import { GET_ASSIGNED_BRANDS } from "~/components/common/header/graphql/Query";
import { useQuery } from "@apollo/client";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import DefaultWindow from "./components/defaultWindow";
import UserInfoBar from "./components/ChatWindow/components/Header/component/UserInfoBar";

export default function EmailWindow() {
  const userData = useUserData();
  const { conversation_id } = useParams();

  const [showUserInfo, setShowUserInfo] = useState(false);

  const [brands, setBrands] = useState([]);

  const { travelHouse }: any = travelHouseStore();
  const userID = useUserId();

  const { loading, data, error } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: { id: userID, thp_id: travelHouse.id },
  });

  useEffect(() => {
    const brdRec: any = [];
    const brdPhone: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push({
            id: brd?.brd_id,
            name: brd.brd_list.name,
            phone: brd.brd_list.phoneNumberId,
            phoneNumberId: brd.brd_list.phoneNumberId,
            whatsapp_token: brd.brd_list.whatsapp_token,
          });
        });
      } else {
        data?.thp_list?.[0].brd_lists?.map((brd: any) => {
          brdRec.push({
            id: brd?.id,
            name: brd.name,
            phone: brd.brd_list.phoneNumberId,
            phoneNumberId: brd.brd_list.phoneNumberId,
            whatsapp_token: brd.brd_list.whatsapp_token,
          });
        });
      }
      setBrands(brdRec);
    }
  }, [loading]);

  const brd_ids: any = [];
  const brand_phone: any = [];

  brands.map((brd: any) => {
    brd_ids.push(brd.id);
    brand_phone.push(brd.phone);
  });

  if (loading) return <>Loading..</>;
  if (error) return <>{error.message}</>;

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold mb-2">
        Emails
      </h1>
      <div className="flex h-[85vh] shadow border dark:border-dark-secondary mb-1 bg-whatsappPrimaryBg dark:bg-dark-primary overflow-hidden">
        <ConversationList brd_ids={brd_ids} />
        <div className="flex-grow flex flex-col w-0">
          {showUserInfo ? (
            <UserInfoBar
              conversation_id={conversation_id}
              showUserInfo={showUserInfo}
              setShowUserInfo={setShowUserInfo}
            />
          ) : conversation_id ? (
            <ChatWindow
              conversation_id={conversation_id}
              setShowUserInfo={setShowUserInfo}
            />
          ) : (
            <DefaultWindow />
          )}
        </div>
      </div>
    </>
  );
}
