import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useThpListData from "~/hooks/useThpListData";

const SuperAdmDashboard = () => {
  const { ThpListData } = useThpListData();
  const navigate = useNavigate();

  const showDetails = (item: any) => {
    const travelHouse = Array.isArray(item.brands)
      ? item.brands
      : [item.brands];
    navigate("/super-admin/brands-details", { state: { THP: travelHouse } });
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "thpName",
      headerName: "THP Name",
      width: 150,
      editable: false,
    },
    {
      field: "activatedAt",
      headerName: "Activated Date",
      width: 150,
      editable: false,
    },
    {
      field: "expiry",
      headerName: "Expiry Date",
      width: 150,
      editable: false,
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 150,
      editable: false,
    },
    {
      field: "planTime",
      headerName: "Plan Time",
      width: 150,
      editable: false,
    },
    {
      field: "brand_credits",
      headerName: "Brands Available",
      width: 150,
      editable: false,
    },
    {
      field: "seat_credits",
      headerName: "Seats Available",
      width: 150,
      editable: false,
    },
    {
      field: "invoice_credits",
      headerName: "Invoices Available",
      width: 150,
      editable: false,
    },    
    {
      field: "brands",
      headerName: "Brands Details",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <button className="underline" onClick={() => showDetails(params.row)}>
          Show
        </button>
      ),
    },
  ];

  const rows = ThpListData?.map((item: any, index: any) => {
    return {
      id: index,
      thpName: item.name,
      activatedAt: item.activated_at,
      expiry: item.expiry,
      brands: item,
      planTime: item.yearly ? "Yearly" : "Monthly",
      plan: item.def_subscription_plan.plan_name,
      invoice_credits: item.invoice_credits,
      brand_credits: item.brand_credits,
      seat_credits: item.seat_credits,
    };
  });

  return (
    <div>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default SuperAdmDashboard;
