import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
// import AutoBotMessages from "~/modules/BrandModule/modules/FlightsPages/segments/AutoBotMessages";
// import Destinations from "~/modules/BrandModule/segments/Destinations";
export default function AutoBotMessagesPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <h1>Test</h1>
          {/* <AutoBotMessages /> */}
          {/* test */}
        </BrandModule>
      </Layout>
    </div>
  );
}
