import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { INSERT_UNIVERSITY_CAMPUS } from "~/graphql/brd_university_campuses/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

const AddCampus = ({ uniId, setSidePopup }: any) => {

  const { register, handleSubmit } = useForm();
  const client = useApolloClient()
  const [insertUniCampus] = useMutation(INSERT_UNIVERSITY_CAMPUS)

  const onSubmit = async (data: any) => {
    const res = await insertUniCampus({
      variables: {
        universityId: uniId,
        campusName: data.campusName,
        city: data.city,
        country: data.country,
        address: data.address
      },
    });

    if(res?.data?.insert_brd_university_campuses?.affected_rows > 0) {
      toast.success("University Campus Added Successfully!")
      client.refetchQueries({
        include: "all"
      })
      setSidePopup(false)
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Campus
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Campus Name"
          variant="outlined"
          fullWidth
          required
          className="mb-5"
          style={{
            marginBottom: "20px",
          }}
          {...register("campusName")}
        />
        <TextField
          label="Address"
          variant="outlined"
          fullWidth
          required
          className="mb-5"
          style={{
            marginBottom: "20px",
          }}
          {...register("address")}
        />
        <TextField
          label="City"
          variant="outlined"
          fullWidth
          required
          className="mb-5"
          style={{
            marginBottom: "20px",
          }}
          {...register("city")}
        />
        <TextField
          label="Country"
          variant="outlined"
          fullWidth
          required
          className="mb-5"
          style={{
            marginBottom: "20px",
          }}
          {...register("country")}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddCampus;
