import { gql } from "@apollo/client";

export const ADD_TWITTER_USER_DETAIL = gql`
  mutation addTwitterUserDetail($access_token: String!) {
    add_twitter_user_detail(arg1: { access_token: $access_token }) {
      data {
        name
        profile_image_url
        username
        id
      }
    }
  }
`;
