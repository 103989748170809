import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import ImportFlightsPages from "~/modules/BrandModule/modules/FlightsPages/segments/ImportFlightsPages";

export default function ImportFlightPages() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <ImportFlightsPages />
        </BrandModule>
      </Layout>
    </div>
  );
}
