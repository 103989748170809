import React from "react";
import Layout from "~/components/common/Layout";
import InquiriesModule from "~/modules/InquiriesModule";
import TicketRequest from "~/modules/InquiriesModule/segments/TicketRequest";

export default function TicketRequests() {
  return (
    <div>
      <Layout>
        <InquiriesModule>
          <TicketRequest />
        </InquiriesModule>
      </Layout>
    </div>
  );
}
