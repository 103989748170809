import React, { useEffect, useRef, useState } from "react";
import { useUserData, useUserId } from "@nhost/react";
import UserCard from "~/components/UserCard";
import ComposeEmail from "../../../../../../components/SideForm/ComposeEmail";
import { Drawer } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  FETCH_UPDATE,
  GET_CONVERSATION_DETAIL,
  GET_CONVERSATION_MESSAGES,
  GET_MESSAGES,
  UPDATE_CONVERSATION,
} from "./graphql/Query";
// import { useOptimistic } from "@nhost/react";

import SingleMessage from "./components/SingleMessage";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  INSERT_MESSAGES,
  UPDATE_CONVERSATION_MESSAGE,
} from "./graphql/Mutation";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import DotLoader from "~/components/common/DotLoader";
import { SEND_EMAIL } from "~/graphql/actions/gmail/Mutation";
import { TbRuler2 } from "react-icons/tb";
import { toast } from "react-toastify";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ChatWindowSekeleton from "../sekeletons/ChatWindowSekeleton";
import useGmailAliases from "~/hooks/useGmailAliases";
import {
  GET_EMAIL_CAMP_CONVERSATION,
  GET_EMAIL_CAMP_MESSAGES,
} from "~/graphql/brd_email_campaigns/Query";
// import { MdSend } from "react-icons/md";

export default function ChatWindow({ conversation_id, setShowUserInfo }: any) {
  const { handleSubmit, register, reset } = useForm();
  const [isSending, setIsSending] = useState<any>(false);
  const [compose, setCompose] = useState<any>(false);
  const [showEmojis, setShowEmojis] = useState(false);

  const { aliases } = useGmailAliases();

  const {
    loading: cl,
    data: cd,
    error: ce,
  } = useSubscription(GET_CONVERSATION_DETAIL, {
    variables: { id: conversation_id },
  });

  const {
    loading: ecl,
    data: ecd,
    error: ece,
  } = useSubscription(GET_EMAIL_CAMP_CONVERSATION, {
    variables: {
      condition: {
        id: { _eq: conversation_id },
      },
    },
  });

  const client = useApolloClient();
  const userId = useUserId();

  const userData = useUserData();

  // Get the current time in UTC
  const currentTime = moment().utc();

  // Format the current time in the desired format
  const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
  const [timestamp, setTimestamp] = useState<any>(formattedTime);

  const [update] = useMutation(UPDATE_CONVERSATION);
  // const [updateMessage] = useMutation(UPDATE_CONVERSATION_MESSAGE);
  const [insertMessages] = useMutation(INSERT_MESSAGES);

  const chatContainerRef: any = useRef(null);

  // formRef

  const { loading, error, data } = useSubscription(GET_MESSAGES, {
    variables: {
      conversation_id,
    },
  });

  const {
    loading: ecml,
    error: ecme,
    data: ecmd,
  } = useSubscription(GET_EMAIL_CAMP_MESSAGES, {
    variables: {
      conversation_id,
    },
  });

  const [sendEmail] = useMutation(SEND_EMAIL);

  const updateLastViewTime = async () => {
    const currentTime = moment().utc();
    const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
    const resSuggestion = await update({
      variables: { conversation_id: conversation_id, time: formattedTime },
    });
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
      updateLastViewTime();
    }
  }, [data?.brd_gmail_messages]);

  useEffect(() => {
    // Get the reference to the scrollable div
    const scrollableDiv = document.getElementById("scrollableDiv");

    if (scrollableDiv) {
      // Add a scroll event listener
      const handleScroll = () => {
        // This function will be called whenever the scroll height changes
        console.log(
          "Scroll height changed!",
          scrollableDiv?.scrollHeight,
          scrollableDiv?.scrollTop
        );
        if (scrollableDiv?.scrollHeight <= scrollableDiv?.scrollTop + 820) {
          updateLastViewTime();
        }
      };

      scrollableDiv.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      };
    } else {
      console.warn("Element with ID 'scrollableDiv' not found");
    }
  }, [chatContainerRef.current]); // Empty dependency array ensures that the effect runs only once (on mount)

  const sendMessage = async (data: any) => {
    setIsSending(true);
    try {
      const response = await sendEmail({
        variables: {
          data: {
            toEmails: cd?.brd_gmail_conversation?.[0]?.user_email
              ? [cd?.brd_gmail_conversation?.[0]?.user_email]
              : ecd?.brd_email_campaigns?.[0]?.user_email,
            subject:
              cd?.brd_gmail_conversation?.[0]?.last_email_subject ||
              ecd?.brd_email_campaigns?.[0]?.last_email_subject ||
              "Subject",
            content: data?.message,
            from: userData?.metadata?.alias,
            refresh_token:
              cd?.brd_gmail_conversation?.[0]?.brd_list?.gmail_refresh_token ||
              ecd?.brd_email_campaigns?.[0]?.brd_list?.gmail_refresh_token,
            attachments: [],
            iata: ecd?.brd_email_campaigns?.[0]?.iata || "No iata",
          },
        },
      });
      toast.success(
        "Email Sent Successfully, It will take few seconds to fetch"
      );
      reset();
      setIsSending(false);
    } catch (error) {
      console.error("Error sending and/or refetching emails:", error);
      setIsSending(false);
    }
  };

  if (loading) return <ChatWindowSekeleton />;
  if (error) return <p>ERR: {error.message}</p>;

  if (cl) return <p>Loading</p>;
  if (ce) return <p>ERR: {ce.message}</p>;

  return (
    <>
      {/* Right */}
      {/* Header */}
      <Header
        data={cd?.brd_gmail_conversation?.[0] || ecd?.brd_email_campaigns?.[0]}
        setShowUserInfo={setShowUserInfo}
      />

      {/* Messages */}
      <div
        className="bg-[#EFEAE2] dark:bg-[#38344e] overflow-scroll flex-grow px-[63px] py-[20px] overflow-x-hidden"
        ref={chatContainerRef}
        id="scrollableDiv"
      >
        {data?.brd_gmail_messages?.length > 0
          ? data?.brd_gmail_messages?.map((message: any, index: any) => {
              return (
                <SingleMessage
                  message={message}
                  user_email={cd?.brd_gmail_conversation?.[0]?.user_email}
                  token={
                    cd?.brd_gmail_conversation?.[0]?.brd_list
                      ?.gmail_refresh_token
                  }
                />
              );
            })
          : ecmd?.brd_email_campaign_messages?.map(
              (message: any, index: any) => {
                return (
                  <SingleMessage
                    message={message}
                    user_email={ecd?.brd_email_campaigns?.[0]?.user_email}
                    token={
                      ecd?.brd_email_campaigns?.[0]?.brd_list
                        ?.gmail_refresh_token
                    }
                  />
                );
              }
            )}
      </div>

      {/* Input */}
      <Footer
        handleSendEmail={sendMessage}
        isSending={isSending}
        register={register}
        handleSubmit={handleSubmit}
        setCompose={setCompose}
        data={cd?.brd_gmail_conversation?.[0] || ecd?.brd_email_campaigns?.[0]}
      />
      {compose && (
        <Drawer anchor="right" open={compose}>
          <CancelIcon
            onClick={() => setCompose(!compose)}
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
          />
          <div className="w-[750px] p-[40px]">
            <ComposeEmail
              setCompose={setCompose}
              gmail_refresh_token={
                cd?.brd_gmail_conversation?.[0]?.brd_list
                  ?.gmail_refresh_token ||
                ecd?.brd_email_campaigns?.[0]?.brd_list?.gmail_refresh_token
              }
              user_email={
                cd?.brd_gmail_conversation?.[0]?.user_email
                  ? [cd?.brd_gmail_conversation?.[0]?.user_email]
                  : ecd?.brd_email_campaigns?.[0]?.user_email
              }
              iata={ecd?.brd_email_campaigns?.[0]?.iata || "No iata"}
              brd_detail={cd?.brd_gmail_conversation?.[0]?.brd_list}
            />
          </div>
        </Drawer>
      )}
    </>
  );
}
