import { gql } from "@apollo/client";

export const GET_BRAND_PAYMENT_METHODS = gql`
  query GetBrandPaymentMethods($brdId: uuid!) {
    acc_payment_methods(where: { brd_id: { _eq: $brdId } }) {
      account_no
      name
      account_title
      stripe_pub
      stripe_secret
      default
      swift_code
      type
      created_at
      updated_at
      id
      thp_id
      brd_id
      brd_list {
        name
      }
    }
  }
`;

export const GET_TH_PAYMENT_METHODS = gql`
  query GetBrandPaymentMethods($thpId: uuid!) {
    acc_payment_methods(where: { thp_id: { _eq: $thpId } }) {
      account_no
      name
      account_title
      stripe_pub
      stripe_secret
      default
      swift_code
      type
      created_at
      updated_at
      id
      thp_id
      brd_id
      brd_list {
        name
      }
    }
  }
`;
