import { useApolloClient, useMutation } from "@apollo/client";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";

import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import { DELETE_BRD_PAGES_HEADINGS } from "~/graphql/brd_pages_headings/Mutation";
import EditPageHedings from "../AddPageHeadings/EditPageHedings";

const ShowPageHeadings = ({ data, SectionTypes, pageHeadings }: any) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState(null);

  const [deleteHeadings] = useMutation(DELETE_BRD_PAGES_HEADINGS);
  const client = useApolloClient();

  const handleEdit = async (id: any) => {
    setOpenPopup(true);
    setSelectedHeading(id);
  };

  const handleDelete = async (id: any) => {
    try {
      const res = await deleteHeadings({
        variables: { id: id },
      });
      if (res?.data?.delete_brd_pages_headings?.affected_rows > 0) {
        toast.success("Headings deleted successfully.");
        await client.refetchQueries({
          include: "all",
        });
      } else {
        toast.error("Error deleting Headings");
      }
    } catch (error) {
      toast.error("Error deleting Headings.");
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Heading</TableCell>
              <TableCell>Subheading</TableCell>
              <TableCell>Section Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.brd_pages_headings.map((dataHeadings: any) => (
              <TableRow key={dataHeadings.id}>
                <TableCell>{dataHeadings.heading}</TableCell>
                <TableCell>{dataHeadings.subheading}</TableCell>
                <TableCell>{dataHeadings.section_name}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(dataHeadings)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(dataHeadings.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditPageHedings
              headings={selectedHeading}
              setSidePopup={setOpenPopup}
              SectionTypes={SectionTypes}
              pageHeadings={pageHeadings}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowPageHeadings;
