import React from "react";
import Layout from "../../components/common/Layout";
import InboxModule from "../../modules/InboxModule";
import Inbox from "../../modules/InboxModule/segments/Inbox";

export default function InboxPage() {
  return (
    <div>
      <Layout>
        <InboxModule>
          <Inbox />
        </InboxModule>
      </Layout>
    </div>
  );
}
