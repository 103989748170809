import { gql } from "@apollo/client";

export const GET_AIRLINE_DETAILS = gql`
  query getAirlineDetails($page_id: uuid) {
    brd_airlines_pages(where: { id: { _eq: $page_id } }) {
      id
      content
      content_heading
      airline_iata
      airline_name
      airline_image
      airline_primary_color
      airline_secondary_color
      airline_header_color
      custom_logo
      airline_heading
      airline_heading_2
      airline_sub_heading
      hero_image
      brd_id
      created_at
      search_with_inquiry
      flights_pages_tips {
        created_at
        airlines_page_id
        description
        flight_page_id
        heading
        icon
        id
      }
      show_header_airline_name
      seo_settings {
        id
        canonical_url
        default_keywords
        default_description
        default_title
        og_image_url
        page_id
        flight_page_id
        created_at
      }
    }
  }
`;
