import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  GET_FLIGHT,
  GET_FLIGHT_PAGE,
  GET_INQUIRY_COMMENT,
  GET_INQUIRY_LIST,
  GET_WHATSAPP_COMMUNITY,
} from "./graphql/Query";
import { useEffect } from "react";
import { ADD_FLIGHT_PAGE, EDIT_INQ } from "./graphql/Mutation";
import {
  ADD_COMMUNITIES,
  ADD_COMMUNITIES_USER,
} from "~/graphql/brd_communities/Mutation";

function UpdateWhatsappLabelInquiry() {
  const { loading, data, error } = useQuery(GET_INQUIRY_LIST);
  const [addCommunity] = useMutation(ADD_COMMUNITIES);
  const [addCommunityUser] = useMutation(ADD_COMMUNITIES_USER);
  const client = useApolloClient();

  const updateInq = async () => {
    if (data?.inq_list.length > 0) {
      // const community = await client.query({
      //   query: GET_WHATSAPP_COMMUNITY,
      //   variables: {
      //     name: "LHR",
      //   },
      // });
      // const community_id = community?.data?.brd_whatsapp_communities?.[0]?.id;
      // ;
      data?.inq_list.map(async (inq: any) => {
        const community = await client.query({
          query: GET_WHATSAPP_COMMUNITY,
          variables: {
            name: inq.to,
          },
        });
        const community_id = community?.data?.brd_whatsapp_communities?.[0]?.id;
        try {
          const resUser = await addCommunityUser({
            variables: {
              data: [{ user_id: inq.user_id, community_id: community_id }],
            },
          });
        } catch (err: any) {}

        // if (community_id) {
        //   try {
        //     const resUser = await addCommunityUser({
        //       variables: {
        //         data: [{ user_id: inq.user_id, community_id: community_id }],
        //       },
        //     });

        //     ;
        //   } catch (err: any) {}
        // } else {
        //   try {
        //     const res = await addCommunity({
        //       variables: {
        //         data: [
        //           {
        //             name: inq.to,
        //             image: null,
        //             brd_id: inq.brd_id,
        //             brd_whatsapp_community_users: {
        //               data: [{ user_id: inq.user_id }],
        //             },
        //           },
        //         ],
        //       },
        //     });
        //     ;
        //   } catch (err: any) {}
        // }
      });
    }
  };

  return (
    <>
      <button
        onClick={() => {
          updateInq();
        }}
      >
        Update Inq
      </button>
    </>
  );
}

export default UpdateWhatsappLabelInquiry;
