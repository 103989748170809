import { useQuery } from "@apollo/client";
import { Autocomplete, Box, TextField } from "@mui/material";
import { GET_CONSULTANTS, GET_CUSTOMER, GET_INQ_FLAGS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useEffect, useState } from "react";
import brandsStore from "~/store/brandsStore/brandsStore";

const CustomerFilter = ({ setSelectedCustomer }: any) => {
  const [searchTerm, setSearchTerm] = useState<any>();

  const { travelHouse }: any = travelHouseStore();
  const { brands }: any = brandsStore();

  const brdIds = brands.map((item: any) => item.id);

  const condition = {
    brd_users: {
      users: { defaultRole: { _eq: "user" } },
      thp_id: { _eq: travelHouse?.id },
      brd_id: { _in: brdIds },
    },
    _or: [
      { displayName: { _ilike: `%${searchTerm}%` } },
      { phoneNumber: { _ilike: `%${searchTerm}%` } },
      { email: { _ilike: `%${searchTerm}%` } },
    ],
  };
  const { loading, data, error } = useQuery(GET_CUSTOMER, {
    variables: {
      condition,
    },
    skip: !searchTerm,
  });

  const handleFlagChange = (event: any, newValue: any) => {
    setSelectedCustomer([newValue]);
  };

  // if (loading) return <>Loading..</>;
  if (error) return <>Err..${error.message}</>;

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data?.users || []}
        onInputChange={(e: any, newInputValue: any) =>
          setSearchTerm(newInputValue)
        }
        onChange={handleFlagChange}
        getOptionLabel={(option: any) =>
          `${option.displayName} - ${option.email} - ${option.phoneNumber}`
        }
        renderOption={(props, option: any) => (
          <Box component="li" {...props}>
            <div className="flex flex-col">
              <p className="text-wrap font-bold">{option?.displayName}</p>
              <p className="text-wrap">{option?.email}</p>
              <p className="text-wrap">{option?.phoneNumber}</p>
            </div>
          </Box>
        )}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Customer" />}
      />
    </>
  );
};

export default CustomerFilter;
