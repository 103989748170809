import React, { useState } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import useBrdUniCampusesData from "~/hooks/useBrdUniCampusesData";
import { DELETE_UNIVERSITY_CAMPUS_WITH_ID } from "~/graphql/brd_university_campuses/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditCampus from "../EditCampus";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import ConfirmPopup from "../ConfirmPopup";

const CampusesData = ({ uniId }: any) => {

    const { brdUniCampusesData } = useBrdUniCampusesData(uniId)
    const [selectedCampus, setSelectedCampus] = useState<any>(null);
    const [openPopup, setOpenPopup] = useState(false);
    const client = useApolloClient()
    const [DeleteUniCampus] = useMutation(DELETE_UNIVERSITY_CAMPUS_WITH_ID)
    const navigate = useNavigate();
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [campusToDelete, setCampusToDelete] = useState(null);

    const handleDeleteUniversityCampus = async (campus_id: any) => {
        setConfirmPopup(true)
        setCampusToDelete(campus_id)
    }

    const confirmCampusDelete = async () => {
        if (campusToDelete) {
            const res = await DeleteUniCampus({
                variables: {
                    campusId: campusToDelete,
                }
            })

            if (res?.data?.delete_brd_university_campuses?.affected_rows > 0) {
                toast.success("University Campus Deleted Successfully!")
                client.refetchQueries({
                    include: "all"
                })
            }
            setConfirmPopup(false);
            setCampusToDelete(null);
        }
    };

    const handleEditUniversityCampus = (campus: any) => {
        setSelectedCampus(campus);
        setOpenPopup(true);
    }

    const handleViewCourses = (campus_id: any) => {
        navigate(`/admin/brands/universities/${uniId}/campus/${campus_id}/courses`);
    };

    return (
        <div>
            <h1 className="text-xl font-semibold mb-4 dark:text-dark-primary">{brdUniCampusesData?.brd_universities?.[0]?.name}</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Campus Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {brdUniCampusesData?.brd_universities?.[0]?.brd_uni_campuses?.map((campus: any) => (
                            <TableRow key={campus.id}>
                                <TableCell>{campus.name}</TableCell>
                                <TableCell>{campus.address}</TableCell>
                                <TableCell>{campus.city}</TableCell>
                                <TableCell>{campus.country}</TableCell>
                                <TableCell>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Button
                                                color="info"
                                                onClick={() => handleViewCourses(campus.id)}
                                            >
                                                Campus Courses
                                            </Button>
                                        </div>
                                        <div className="flex gap-5">
                                            <FaEdit
                                                onClick={() => {
                                                    handleEditUniversityCampus(campus);
                                                }}
                                                className="cursor-pointer"
                                                size={18}
                                            />
                                            <FaTrash
                                                onClick={() => {
                                                    handleDeleteUniversityCampus(campus.id);
                                                }}
                                                className="cursor-pointer"
                                                size={18}
                                            />
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {openPopup && (
                <SideDrawer
                    component={
                        <EditCampus
                            selectedCampus={selectedCampus}
                            setSidePopup={setOpenPopup}
                        />
                    }
                    sidePopup={openPopup}
                    setSidePopup={setOpenPopup}
                />
            )}
            {confirmPopup && (
                <ConfirmPopup confirmPopup={confirmPopup} setConfirmPopup={setConfirmPopup} onConfirm={confirmCampusDelete} />
            )}
        </div>
    );
};

export default CampusesData;
