import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { MonthPickerInput } from "@mantine/dates";
import moment from "moment";
import { MonthPickerProps } from "./type";

export const MonthPicker = ({
  control,
  errors,
  setValue,
  type,
  fieldName,
  label,
  labelClasses,
  containerClasses,
  defaultValue,
  inputClasses,
  minDate,
  maxDate,
  required = false,
  placeholder = "Month Pick",
}: MonthPickerProps) => {
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
      setDate(defaultValue);
    }
  }, []);

  return (
    <div className={`w-full ${containerClasses}`}>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: required }}
        render={({ field: { onChange } }) => (
          //   <DatePickerInput
          //     classNames={{
          //       input: `${inputClasses} ${
          //         errors[fieldName] ? "border border-red-500" : ""
          //       }`,
          //       label: `${labelClasses}`,
          //     }}
          //     className="m-0 w-full"
          //     type={type}
          //     allowSingleDateInRange
          //     valueFormat="MMM DD, YYYY"
          //     label={label}
          //     placeholder={placeholder}
          //     value={date}
          //     onChange={(e: any) => {
          //       setDate(e);
          //       onChange(e.toString());
          //     }}
          //     minDate={minDate ? moment(minDate).toDate() : undefined}
          //     maxDate={maxDate ? moment(maxDate).toDate() : undefined}
          //   />
          <MonthPickerInput
            value={date}
            label="Salary Month"
            // placeholder="Select Salary Month"
            onChange={(e: any) => {
              setDate(e);
              onChange(e.toString());
            }}
          />
        )}
      />
      {errors[fieldName] && (
        <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
          {" "}
          <sup>*</sup> required.
        </p>
      )}
    </div>
  );
};
