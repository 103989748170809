import { gql } from "@apollo/client";

export const UPDDATE_CONTACT_DETAILS = gql`
  mutation UpdateContact(
    $id: brd_contact_detail_pk_columns_input!
    $data: brd_contact_detail_set_input!
  ) {
    update_brd_contact_detail_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;

export const INSERT_CONTACT_DETAILS = gql`
  mutation InsertContactDetails($data: brd_contact_detail_insert_input!) {
    insert_brd_contact_detail_one(object: $data) {
      id
    }
  }
`;
