const UnreadCount = ({ messagesStatuses }: any) => {
  // const unreadMessages = messagesStatuses?.filter((item: any) => {
  //   return item[0].key === "delivered";
  // });

  if (messagesStatuses && messagesStatuses.length > 0) {
    return (
      <span className="bg-[#25d366] h-[23px] w-[23px] rounded-full text-[12px] text-whatsappSecondaryBg flex items-center justify-center font-bold">
        {messagesStatuses.length}
      </span>
    );
  } else {
    return null; // Render null if there are no unread messages
  }
};

export default UnreadCount;
