import { useQuery } from "@apollo/client";
import { GET_ALL_BRANDS_PAYMENTS_METHODS } from "~/modules/AccountModule/segments/TransactionsList/components/VendorDeposit/graphql/Query";

export default function useAllBrandPaymentMethodName(brd_ids: any) {
  const { data } = useQuery(GET_ALL_BRANDS_PAYMENTS_METHODS, {
    skip: !brd_ids, // Don't run the query unless brdId is set
    variables: { brd_ids: brd_ids },
  });

  return { paymentMethodName: data?.acc_payment_methods };
}
