import React, { useState } from "react";
import EditFAQs from "./components/EditTestimonial";
import { GET_FLIGHTS_PAGES } from "./graphql/Query";
import { Link, NavLink, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
import AddTestimonials from "./components/AddTestimonial";
import TestimonialRow from "./components/DestinationRow";
import DestinationRow from "./components/DestinationRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle } from "react-icons/fa";
import DestinationSettings from "./components/DestinationSettings";
import { Button } from "@mui/material";
import SideDrawer from "~/components/common/sideDrawer";
import DestinationSetting from "./components/DestinationSettings/DestinationSetting";
import useBrandDefaultSettings from "~/hooks/useBrandDefaultSettings";

const Destinations = () => {
  const { brd_id } = useParams();
  const [sidePopup, setSidePopup] = useState(false);
  const [addTsm, setAddTsm] = useState(false);
  const { loading, data } = useQuery(GET_FLIGHTS_PAGES, {
    variables: { brd_id: brd_id },
  });

  const { brdDefaultSettings } = useBrandDefaultSettings(brd_id && brd_id);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between border-b border-gray-200 dark:border-gray-600 text-primary dark:text-dark-primary lg:mt-1.5  ">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold sm:text-2xl ">
              All destinations
            </h1>
          </div>
          <div className="items-center justify-between block sm:flex">
            <div className="flex items-center mb-4 sm:mb-0">
              <form className="sm:pr-3" action="#" method="GET">
                <label htmlFor="faqs-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                  <input
                    type="text"
                    name="email"
                    id="faqs-search"
                    className="bg-secondary dark:bg-dark-secondary border-none sm:text-sm shadow rounded-lg block w-full p-2.5 "
                    placeholder="Search for destinations"
                  />
                </div>
              </form>
              <div className="flex items-center w-full sm:justify-end">
                <div className="flex pl-2 space-x-1">
                  <button
                    onClick={() => setSidePopup(!sidePopup)}
                    className="inline-flex justify-center p-1 text-primary dark:text-dark-primary rounded cursor-pointer"
                    type="button"
                  >
                    <AiFillSetting fontSize={25} />
                  </button>
                </div>
              </div>
            </div>
            {/* <button onClick={() => setAddTsm(true)} type="button"></button> */}
            <Button
              component={Link}
              to={`/admin/brands/${brd_id}/destinations/add`}
              variant="contained"
            >
              Add New Destination
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow rounded-lg">
              {data?.brd_flights_pages?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 rounded-lg table-fixed dark:divide-gray-600">
                  <thead className="bg-basic text-white shadow">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all"
                            aria-describedby="checkbox-1"
                            type="checkbox"
                            className="w-4 h-4 border-gray-200 rounded bg-primary dark:bg-dark-primary dark:border-gray-600"
                          />
                          <label htmlFor="checkbox-all" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left uppercase"
                      >
                        DEPARTURE
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left uppercase"
                      >
                        ARRIVAL
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left uppercase"
                      >
                        MAIN AIRLINE
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left uppercase"
                      >
                        STARTING PRICE
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left uppercase"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-secondary dark:bg-dark-secondary divide-y shadow divide-gray-200  dark:divide-gray-600">
                    {data?.brd_flights_pages?.length > 0 &&
                      data?.brd_flights_pages?.map(
                        (destination: any, i: number) => (
                          <DestinationRow key={i} destination={destination} />
                        )
                      )}
                  </tbody>
                </table>
              ) : (
                <NoDataFound
                  icon={
                    <FaExclamationCircle className="text-[30px] text-red-500" />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {addTsm && <AddTestimonials setAddTsm={setAddTsm} addTsm={addTsm} />}
      {/* {settings && (
        <DestinationSettings settings={settings} setSettings={setSettings} />
      )} */}
      <SideDrawer
        component={
          <DestinationSetting
            defSettings={brdDefaultSettings}
            brd_id={brd_id}
          />
        }
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
      />
    </>
  );
};

export default Destinations; //
