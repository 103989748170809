import React from "react";
// Import components
import InputField from "components/FormInputs/InputField";
import SelectBox from "components/FormInputs/SelectBox";
// Import intial data
// import { legDefaultValue } from "../../../../data/InitalDefaultValue";
import { CabinClasses } from "~/data/cabinClasses";
import { legDefaultValue } from "~/data/InitalDefaultValue";

export default function FlightType({
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
}: any) {
  return (
    <div>
      <div className="flex w-full bg-gray-100 rounded-lg p-2 flex-col dark:bg-[#28243D]">
        {/* <h1 className="w-full font-bold text-left text-primary dark:text-dark-primary border-b border-gray-300 mb-4">
          Trip Type
        </h1> */}
        <div className="flex">
          {/* Trip Type Type */}
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px] text-primary dark:text-dark-primary">
            Trip Type: <p className="font-medium"> {getValues("tripType") === 1 ? "One Way" : "Round Trip"}</p>
          </div>
          {/* Cabin Class Select box*/}
          <div className="flex-1">
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"cabinType"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={
                CabinClasses.filter(
                  (cabin: any) => cabin.value === getValues("cabinType")
                )[0]
              }
              required={true}
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="text-primary dark:text-dark-primary text-base"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
