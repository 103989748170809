import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React from "react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import SingleRecord from "./components/SingleRecord";
import { useForm } from "react-hook-form";
import { GET_USERS } from "./graphql/Query";
import moment from "moment";
import { ADD_TRANSACTION } from "./graphql/Mutation";
import { toast } from "react-toastify";
import TableHead from "./components/TableHead";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

export default function ResultsForm({ date, requiredHours }: any) {
  const { travelHouse }: any = travelHouseStore();

  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const client = useApolloClient();

  const startDate = moment(date).format("YYYY-MM-DD");
  const endDate = moment(date).endOf("month").format("YYYY-MM-DD");

  const timestart = `${startDate}T00:00:00.000+00:00`;
  const timeend = `${endDate}T00:00:00.000+00:00`;

  const { data, error, loading } = useQuery(GET_USERS, {
    variables: {
      thp_id: travelHouse?.id,
      timestart,
      timeend,
      datestart: startDate,
      dateend: endDate,
    },
  });

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    // const addAttendanceData: any = [];
    // const updateAttendanceData: any = [];
    let process = 0;
    const brandId =
      data?.users?.[0]?.brd_users?.[0].thp_list?.brd_lists?.[0].id;
    formData.user.map(async (item: any) => {
      if (item.process && item.amount > 0) {
        const tenDigitCode: any = generateRandom10DigitCode();
        const transactions: any = [
          {
            brd_id: brandId,
            def_acc_id: 10,
            user_id: item.user_id,
            transactions_no: tenDigitCode,
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: "debit",
            amount: parseFloat(item.amount),
            month: item.month,
            year: item.year,
          },
          {
            brd_id: brandId,
            def_acc_id: 2,
            user_id: item.user_id,
            transactions_no: tenDigitCode,
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: "credit",
            amount: -item.amount,
          },
        ];

        try {
          const res = await addTransaction({
            variables: {
              transactions: transactions,
            },
          });
          process += 1;
        } catch (e) {
          console.error("error", e);
        }
      }
    });

    await client.resetStore();
    toast.success(`${process} Payroll Processed successfully`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-4">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow">
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                  <TableHead />
                  <tbody className="bg-white divide-y divide-gray-200  dark:divide-gray-700">
                    {data?.users.map((user: any, index: any) => {
                      return (
                        <SingleRecord
                          user={user}
                          date={date}
                          errors={errors}
                          index={index}
                          register={register}
                          requiredHours={
                            (requiredHours = "" ? 0 : requiredHours)
                          }
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-full mt-4">
          <button
            className="w-full btn bg-green-500 text-white rounded-md p-2"
            type="submit"
          >
            Process Payroll
          </button>
        </div>
      </form>
    </div>
  );
}
