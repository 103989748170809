import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $picked_by_id: uuid) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { picked_by: $picked_by_id, status: "Assigned" }
    ) {
      returning {
        id
      }
    }
  }
`;
