import React from "react";

import { splitLocation } from "~/utils/splitLocation";
import moment from "moment-timezone";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
const CarCard = ({
  origin,
  destination,
  Pick_Date_Time,
  Drop_Date_Time,
  withDriver,
  height,
}: any) => {
  // Split origin into city and remaining address
  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(origin);

  // Split destination into city and remaining address
  const { cityName: destinationCity, remainingAddress: destinationAddress } =
    splitLocation(destination);

  return (
    <div
      className={`h-full w-full rounded-2xl  bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow flex flex-col pt-0 px-0 box-border items-end justify-between text-left text-xs
  `}
    >
      <div className="w-full text-primary dark:text-dark-primary flex flex-col gap-3.5 justify-between rounded-2xl">
        <div className="self-stretch flex flex-row pb-2.5 pt-10 px-[30px] items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-[1px]">
            <div className="bg-basic h-20 w-20 rounded-full flex items-center mb-3 justify-center">
              <DirectionsCarIcon
                className="text-center text-white"
                fontSize="large"
              />
            </div>
            <div className="flex justify-between flex-col w-full gap-1 my-3">
              <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                <>
                  <span className="font-bold mr-2">Origin:</span> {originCity}{" "}
                  {originAddress}
                </>
              </div>
              <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                <span className="font-bold mr-2">Destination :</span>{" "}
                {destinationCity} {destinationAddress}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex  md:flex-row px-8 py-5 items-center justify-between bg-basic rounded-b-2xl h-[150px]">
        <div className="flex flex-col items-start justify-center w-full">
          <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-base">
              {" "}
              {moment(Pick_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
            </div>

            <div className="flex flex-row items-center justify-start gap-1 mt-2">
              <div className="relative inline-block text-base shrink-0">
                <span>With Driver: </span>
                <b> {withDriver}</b>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-1 mt-2">
              <div className="relative inline-block text-base shrink-0">
                {moment(Drop_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col items-end justify-center w-full">
          <div className="flex flex-col items-end justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-end text-base">
              {moment(Drop_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CarCard;
