import React, { useEffect, useState } from "react";
import SearchForm from "./components/SearchForm";
import ResultsForm from "./components/ResultsForm";
import moment from "moment";
import { useForm } from "react-hook-form";

export default function Payroll() {
  const [date, setDates] = useState(null);
  const [requiredHours, setRequiredHours] = useState(150);
  const [loadForm, setLoadForm] = useState(false);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    register,
  } = useForm();

  const dateChagne = watch("date");
  const work_hours = watch("work_hours");

  useEffect(() => {
    setLoadForm(false);
  }, [dateChagne, work_hours]);

  const onSearch = () => {
    setDates(dateChagne);
    setRequiredHours(work_hours);
    setLoadForm(true);
  };

  return (
    <div>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200 dark:divide-gray-700 mb-2">
        Process Payroll
      </h1>
      <SearchForm
        date={date}
        setDates={setDates}
        onSearch={onSearch}
        setLoadForm={setLoadForm}
        control={control}
        errors={errors}
        setValue={setValue}
        register={register}
      />
      {/* {loadForm} */}
      {loadForm && <ResultsForm date={date} requiredHours={requiredHours} />}
    </div>
  );
}
