import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query GetTransactions($brd_ids: [uuid!]) {
    inq_transection(
      where: {
        status: { _eq: "pending" }
        inq_list: { brd_id: { _in: $brd_ids } }
      }
      order_by: { created_at: desc }
    ) {
      amount
      receipt
      ref_no
      status
      created_at
      updated_at
      id
      inq_id
      payment_method_id
      transactions_no
      inq_list {
        picked_by
        inquiry_no
        users {
          id
          displayName
          email
          phoneNumber
        }
        picked_user {
          id
          displayName
          email
          phoneNumber
        }
        id
        picked_by
        user_id
        brd_id
        brd_list {
          name
          domain
        }
      }
      acc_payment_method {
        id
        name
        type
        card_terminal_type
      }
    }
  }
`;
