import { useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Divider, TextField, Button } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UPDATE_FINGERPRINT_IDS_STATUS } from "~/graphql/user_fingerprint_ids/Mutation";
import { GET_FINGERPRINT_LOGS } from "~/graphql/user_fingerprint_ids/Query";

const FingerPrintsLogs = ({ item, refetchFPIDs, setOpenModel }: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("fingerPrintId", item.id);
    setValue("status", item.status);
  }, [item]);

  const [updateFingerPrintStatus] = useMutation(UPDATE_FINGERPRINT_IDS_STATUS);

  const statusOptions = [
    { label: "NEW", value: "new" },
    { label: "Allowed", value: "allowed" },
    { label: "Black List", value: "blackList" },
  ];

  const updateStatus = async (formData: any) => {
    const res = await updateFingerPrintStatus({
      variables: {
        fingerPrintId: formData?.fingerPrintId,
        status: formData?.status,
      },
    });
    if (res?.data?.update_user_fingerprint_ids?.returning?.[0]?.id) {
      refetchFPIDs();
      toast.success("FingerPrint Status Updated SuccessFully");
      setOpenModel(false);
    }
  };

  return (
    <form
      className="flex items-center justify-between mt-10 gap-5 px-3"
      onSubmit={handleSubmit(updateStatus)}
    >
      <TextField sx={{ width: "500px" }} value={item.fingerprint_id} />
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        {...register("status", { required: true })}
        render={({ field }: any) => (
          <Autocomplete
            sx={{ width: "300px" }}
            {...field}
            disablePortal
            ref={field.ref}
            onChange={(_, data: any) => {
              field.onChange(data?.value);
            }}
            defaultValue={
              item.status === "new"
                ? { label: "NEW", value: "new" }
                : item.status === "allowed"
                ? { label: "Allowed", value: "allowed" }
                : item.status === "blackList" && {
                    label: "Black List",
                    value: "blackList",
                  }
            }
            options={statusOptions}
            getOptionLabel={(option: any) => option.label}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                label="Status"
                ref={field.ref}
                inputRef={(el) => {
                  field.ref(el);
                }}
                error={errors["status"] ? true : false}
                {...params}
              />
            )}
            noOptionsText=""
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ width: "200px", padding: "15px 0px" }}
      >
        Update Status
      </Button>
    </form>
  );
};

export default FingerPrintsLogs;
