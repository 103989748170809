import { gql } from "@apollo/client";

export const GET_BRAND_UNIVERSITIES = gql`
  query GetBrandUniversities($brdId: uuid!) {
    brd_universities(where: { brd_id: { _eq: $brdId } }) {
      id
      name
    }
  }
`;

export const GET_BRAND_UNIVERSITIES_WITH_NAME = gql`
  query SearchBrandUniversities($name: String!, $brdId: uuid!) {
    brd_universities(where: { name: { _ilike: $name }, brd_id: { _eq: $brdId } }) {
      id
      name
    }
  }
`;

// export const GET_FILTERED_UNIVERSITY = gql`
//   query SearchFilteredUniversity(
//     $uniName: String
//     $campusCourseName: String
//     $intake: String
//   ) {
//     def_universities(
//       where: {
//         _or: [
//           { name: { _ilike: $uniName } }
//           {
//             def_university_campuses: {
//               def_uni_campus_courses: {
//                 _or: [
//                   { name: { _eq: $campusCourseName } }
//                   { intake: { _eq: $intake } }
//                 ]
//               }
//             }
//           }
//         ]
//       }
//     ) {
//       name
//       def_university_campuses(
//         where: {
//           def_uni_campus_courses: {
//             _or: [
//               { name: { _eq: $campusCourseName } }
//               { intake: { _eq: $intake } }
//             ]
//           }
//         }
//       ) {
//         name
//         id
//         def_uni_campus_courses(
//           where: {
//             _or: [
//               { name: { _eq: $campusCourseName } }
//               { intake: { _eq: $intake } }
//             ]
//           }
//         ) {
//           intake
//           name
//         }
//       }
//     }
//   }
// `;

