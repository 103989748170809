import { gql } from "@apollo/client";

export const GET_MAILJET_CONTACT_LIST = gql`
  query GetMailjetContactList($brdId: String!) {
    get_mailjet_contact_list(arg1: { brdId: $brdId }) {
      Count
      Total
      Data {
        Name
        SubscriberCount
        ID
      }
    }
  }
`;
