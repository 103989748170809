import { gql } from "@apollo/client";

export const GET_CAMPUS_COURSE_ADM_REQ = gql`
  query GetAdmissionRequirementsOfCourse($campusCourseId: uuid!) {
    uni_campus_courses(where: { id: { _eq: $campusCourseId } }) {
      id
      course_detail {
        name
        course_type
      }
      adm_req {
        id
        qualification
        grades
        grade_type
        gap_allowed
        scholarship_available
        elt
        moi
      }
    }
  }
`;

