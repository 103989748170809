import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { Button, TextField, Grid, Typography } from "@mui/material";
import { GET_SEO_SETTINGS } from "~/graphql/brd_services_seo_settings/Query";
import {
  INSERT_SEO_SETTINGS_WITH_ID,
  UPDATE_SEO_SETTINGS,
} from "~/graphql/brd_services_seo_settings/Mutation";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ShowSEOHomepage = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { brd_id, service_id } = useParams();

  const client = useApolloClient();

  const { data } = useQuery(GET_SEO_SETTINGS, {
    variables: { brdId: brd_id, pageType: "home-page" },
  });

  // Mutation for updating SEO settings
  const [updateSEOSettings] = useMutation(UPDATE_SEO_SETTINGS);
  const [insertSEOSettings] = useMutation(INSERT_SEO_SETTINGS_WITH_ID);

  useEffect(() => {
    if (data) {
      setValue("title", data.brd_services_seo_settings[0]?.title || "");
      setValue(
        "keywords",
        data.brd_services_seo_settings[0]?.default_keywords || ""
      );
      setValue(
        "canonical_url",
        data.brd_services_seo_settings[0]?.canonical_url || ""
      );
      setValue(
        "og_image_url",
        data.brd_services_seo_settings[0]?.og_image_url || ""
      );
      setValue(
        "description",
        data.brd_services_seo_settings[0]?.description || ""
      );
    }
  }, [data, setValue]);

  const onSubmit = async (formData: any) => {
    try {
      const pageType = "home-page";

      if (data && data.brd_services_seo_settings.length > 0) {
        // If data exists, update the SEO settings
        const { data: updateData } = await updateSEOSettings({
          variables: {
            brd_id: brd_id,
            page_type: pageType, // Pass page_type to the update mutation
            data: {
              title: formData.title,
              default_keywords: formData.keywords,
              canonical_url: formData.canonical_url,
              og_image_url: formData.og_image_url,
              description: formData.description,
            },
          },
        });

        toast.success("SEO settings updated successfully!");
      } else {
        const { data: insertData } = await insertSEOSettings({
          variables: {
            data: {
              title: formData.title,
              default_keywords: formData.keywords,
              canonical_url: formData.canonical_url,
              og_image_url: formData.og_image_url,
              description: formData.description,
              brd_id: brd_id,
              page_type: pageType,
            },
          },
        });

        toast.success("SEO settings inserted successfully!");
      }

      await client.refetchQueries({ include: "all" });
    } catch (error: any) {
      console.error("Error processing SEO settings:", error);
      toast.error("Failed to process SEO settings. Please try again.");
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow"
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Title</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("title", { required: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Keywords</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("keywords", { required: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Canonical Url</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("canonical_url")}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Og Image Url</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("og_image_url")}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Description</Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              {...register("description", { required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save SEO Settings
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ShowSEOHomepage;
