import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

const FlightDates = ({ register, errors, control, defData, setValue }: any) => {
  const [fareExpiry, setFareExpiry] = useState(
    defData?.fareExpiry ? dayjs(defData?.fareExpiry) : null
  );

  const [invoiceDate, setInvoiceDate] = useState(
    defData?.invoiceDueDate ? dayjs(defData?.invoiceDueDate) : null
  );

  useEffect(() => {
    setValue &&
      setValue(`fareExpiry`, defData?.fareExpiry && defData?.fareExpiry);

    setValue &&
      setValue(
        `invoiceDueDate`,
        defData?.invoiceDueDate && defData?.invoiceDueDate
      );
  }, []);

  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-5">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <Controller
              rules={{
                required: true,
              }}
              {...register("fareExpiry", {
                required: true,
              })}
              control={control}
              render={({ field: { onChange, ref } }) => (
                <DatePicker
                  value={fareExpiry}
                  inputRef={ref}
                  onChange={(data: any) => {
                    onChange(data?.$d.toString());
                    setFareExpiry(data);
                  }}
                  sx={{ width: "100%" }}
                  label="Fare Expiry"
                  slotProps={{
                    textField: {
                      error: errors["fareExpiry"] ? true : false,
                    },
                  }}
                />
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <Controller
              rules={{
                required: true,
              }}
              {...register("invoiceDueDate", {
                required: true,
              })}
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <DatePicker
                  value={invoiceDate}
                  inputRef={ref}
                  onChange={(data: any) => {
                    onChange(data?.$d.toString());
                    setInvoiceDate(data);
                  }}
                  sx={{ width: "100%" }}
                  label="Invoice Due Date"
                  slotProps={{
                    textField: {
                      error: errors["invoiceDueDate"] ? true : false,
                    },
                  }}
                />
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </>
  );
};

export default FlightDates;
