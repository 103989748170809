import { useApolloClient, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import InputField from "~/components/FormInputs/InputField";
import LocationBox from "~/components/FormInputs/LocationBox";
import { UPDATE_FLIGHT_PAGE } from "../../graphql/Mutation";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const EditFlightPage = ({ setEdit, data }: any) => {
  const [editFlightPage] = useMutation(UPDATE_FLIGHT_PAGE);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
  } = useForm();

  // ;

  const onSubmit = async (inpData: any) => {
    const res = await editFlightPage({
      variables: {
        id: { id: data?.id },
        pageData: {
          slug: `${
            inpData?.origin?.city_name
              ? inpData?.origin?.city_name.toLowerCase()
              : inpData?.origin?.name.toLowerCase()
          }-to-${
            inpData?.destination?.city_name
              ? inpData?.destination?.city_name.toLowerCase()
              : inpData?.destination?.name.toLowerCase()
          }`,
          canonical_url: inpData?.canonicalUrl,
          origin_iata: inpData?.origin?.iata_code,
          origin_name: inpData?.origin?.city_name
            ? inpData?.origin?.city_name
            : inpData?.origin?.name,
          destination_iata: inpData?.destination?.iata_code,
          destination_name: inpData?.destination?.city_name
            ? inpData?.destination?.city_name
            : inpData?.destination?.name,
          title: inpData?.title,
          description: inpData?.description,
          airline_iata: inpData?.airline?.iata,
          base_price: inpData?.basePrice,
          show_in_footer: inpData?.showInFooter ? true : false,
          show_in_home: inpData?.showInHome ? true : false,
          show_in_home_gallery: inpData?.showInHomeGallery ? true : false,
          button_text: inpData?.buttonText,
          footer_text: inpData?.footerText,
          blog_heading: inpData?.blogHeading,
          blog_sub_heading: inpData?.blogSubHeading,
          contact_heading: inpData?.contactHeading,
          contact_sub_heading: inpData?.contactSubHeading,
          tips_heading: inpData?.tipsHeading,
          tips_sub_heading: inpData?.tipsSubHeading,
        },
      },
    });

    if (res?.data?.update_brd_flights_pages_by_pk?.id) {
      toast.success("Flight Page updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
      setEdit(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold	">Edit Page</h2>
      <div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="title"
            placeHolder="Title"
            defaultValue={data?.title}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="buttonText"
            placeHolder="Button Text"
            defaultValue={data?.button_text}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="footerText"
            placeHolder="Footer text"
            defaultValue={data?.footer_text}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="canonicalUrl"
            required={true}
            placeHolder="Canonical URL"
            defaultValue={data?.canonical_url}
          />
        </div>
        <div className="mt-2 mb-2">
          <LocationBox
            control={control}
            errors={errors}
            setValue={setValue}
            fieldName="origin"
            required={true}
            placeholder="Select origin"
            defaultValue={data?.origin_iata}
          />
        </div>
        <div className="mt-2 mb-2">
          <LocationBox
            control={control}
            errors={errors}
            setValue={setValue}
            fieldName="destination"
            required={true}
            placeholder="Select destination"
            defaultValue={data?.destination_iata}
          />
        </div>
        <div className="mt-2 mb-2">
          <AirlineBox
            control={control}
            errors={errors}
            setValue={setValue}
            fieldName="airline"
            required={true}
            placeholder="Select airline"
            defaultValue={data?.airline_iata}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            type="number"
            errors={errors}
            fieldName="basePrice"
            required={true}
            placeHolder="Select price"
            defaultValue={data?.base_price}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="blogHeading"
            placeHolder="Blog Heading"
            defaultValue={data?.blog_heading}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="blogSubHeading"
            placeHolder="Blog Sub Heading"
            defaultValue={data?.blog_sub_heading}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="contactHeading"
            placeHolder="Contact Heading"
            defaultValue={data?.contact_heading}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="contactSubHeading"
            placeHolder="Contact Sub Heading"
            defaultValue={data?.contact_sub_heading}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="tipsHeading"
            placeHolder="Tips Heading"
            defaultValue={data?.tips_heading}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="tipsSubHeading"
            placeHolder="Tips Sub Heading"
            defaultValue={data?.tips_sub_heading}
          />
        </div>
        <div className="mt-2 mb-2">
          <textarea
            className="border py-2 pl-3 pr-10 text-sm leading-5 w-full rounded text-gray-900 focus:ring-0 border-gray-200"
            {...register("description", { required: true })}
            rows={8}
            placeholder="Description"
            defaultValue={data?.description}
          />
        </div>
        <div className="flex-1 m-2">
          <label className="">Show in footer</label>
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="Yes"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={true}
                fieldName="showInFooter"
                containerClasses="flex"
                checked={data?.show_in_footer === true ? true : false}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="No"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={false}
                fieldName="showInFooter"
                containerClasses="flex"
                checked={data?.show_in_footer === false ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 m-2">
          <label className="">Show in home</label>
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="Yes"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={true}
                fieldName="showInHome"
                containerClasses="flex"
                checked={data?.show_in_home === true ? true : false}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="No"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={false}
                fieldName="showInHome"
                containerClasses="flex"
                checked={data?.show_in_home === false ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 m-2">
          <label className="">Show in home gallery</label>
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="Yes"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={true}
                fieldName="showInHomeGallery"
                containerClasses="flex"
                checked={data?.show_in_home_gallery === true ? true : false}
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="No"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={false}
                fieldName="showInHomeGallery"
                containerClasses="flex"
                checked={data?.show_in_home_gallery === false ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[10px]">
        <button className="bg-blue-500 text-white w-full rounded">Save</button>
        <button
          onClick={() => setEdit(false)}
          className="bg-red-500 text-white w-full rounded"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default EditFlightPage;
