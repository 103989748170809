import React, { useState } from "react";
import { GET_TESTIMONIALS } from "./graphql/Query";
import { useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
import TestimonialRow from "./components/TestimonialRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle } from "react-icons/fa";
import SideDrawer from "~/components/common/sideDrawer";
import BrandSettings from "~/components/common/BrandSettings";
import { useForm } from "react-hook-form";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./components/TestimonialSettings/graphql/Mutation";
import { toast } from "react-toastify";
import BrandAddUpdateSec from "~/components/common/BrandAddUpdateSec";
import { INSERT_TESTIMONIAL } from "../Testimonials/graphql/Mutation";
import { GET_DEFAULT_SETTINGS } from "./components/TestimonialSettings/graphql/Query";

const ManageTestimonials = () => {
  const { brd_id } = useParams();
  const [settings, setSettings] = useState(false);
  const [addTestimonial, setAddTestimonial] = useState(false);
  const [addTsm, setAddTsm] = useState(false);

  const { loading: defTestimonialsLoading, data: defTestimonials } = useQuery(
    GET_TESTIMONIALS,
    {
      variables: { id: brd_id },
    }
  );

  const { loading: defSettingsLoading, data: defSettingsData } = useQuery(
    GET_DEFAULT_SETTINGS,
    {
      variables: { id: brd_id },
    }
  );

  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertTestimonial] = useMutation(INSERT_TESTIMONIAL);
  const client = useApolloClient();

  const defSettings = defSettingsData?.brd_default_setting?.at(0);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const updateSettings = async (inpData: any) => {
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            testimonial_heading: inpData?.heading,
            testimonial_sub_heading: inpData?.subHeading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
        setSettings(!settings);
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            testimonial_heading: inpData?.heading,
            testimonial_sub_heading: inpData?.subHeading,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Social Media  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
      setSettings(!settings);
    }
  };

  const addTestimonials = async (inpData: any) => {
    const res = await insertTestimonial({
      variables: {
        tsData: {
          name: inpData?.name,
          feedback: inpData?.feedback,
          rating: inpData?.rating,
          iata: inpData?.destination?.iata_code,
          airline_iata: inpData?.airline?.iata || null,
          brd_id: brd_id,
        },
      },
    });

    if (res?.data?.insert_brd_testimonials_one?.id) {
      toast.success("Testimonial added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
      setAddTestimonial(!addTestimonial);
    }
  };

  if (defTestimonialsLoading) return <p>Loading...</p>;
  const testimonials = defTestimonials?.brd_testimonials;
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between lg:mt-1.5  ">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-primary dark:text-dark-primary sm:text-2xl ">
              All testimonials
            </h1>
          </div>
          <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-100 dark:divide-gray-700">
            <div className="flex items-center mb-4 sm:mb-0">
              <form className="sm:pr-3" action="#" method="GET">
                <label htmlFor="faqs-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                  <input
                    type="text"
                    name="email"
                    id="faqs-search"
                    className="bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary sm:text-sm rounded-lg shadow block w-full p-2.5"
                    placeholder="Search for faqs"
                  />
                </div>
              </form>
              <div className="flex items-center w-full sm:justify-end">
                <div className="flex pl-2 space-x-1">
                  <button
                    onClick={() => setSettings(true)}
                    className="inline-flex justify-center p-1 text-primary dark:text-dark-primary rounded cursor-pointerhover:text-dark-primary"
                    type="button"
                  >
                    <AiFillSetting fontSize={25} />
                  </button>
                </div>
              </div>
            </div>
            <button
              onClick={() => setAddTestimonial(!addTestimonial)}
              className="text-white bg-basic hover:bg-purple-600 font-medium rounded-lg text-sm px-5 py-2.5"
              type="button"
            >
              Add New Testimonial
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden">
              {testimonials?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600 rounded-lg shadow">
                  <thead className="bg-secondary dark:bg-dark-secondary rounded-t-lg shadow">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all"
                            aria-describedby="checkbox-1"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded bg-primary dark:bg-dark-primary dark:border-gray-600"
                          />
                          <label htmlFor="checkbox-all" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Rating
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Customer
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Feedback
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-secondary shadow rounded-b-lg dark:bg-dark-secondary divide-y divide-gray-200  dark:divide-gray-700">
                    {testimonials?.length > 0 &&
                      testimonials?.map((tsm: any, i: number) => (
                        <TestimonialRow key={i} tsm={tsm} />
                      ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound
                  icon={
                    <FaExclamationCircle className="text-[30px] text-red-500" />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <SideDrawer
        setSidePopup={setAddTestimonial}
        sidePopup={addTestimonial}
        component={
          <BrandAddUpdateSec
            mainHeading="Add New Testimonial"
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            addData={addTestimonials}
            field1={{ label: "Name", name: "name" }}
            field2={{
              label: "Rating",
              name: "rating",
            }}
            field3={{
              label: "Destination",
              name: "destination",
            }}
            field4={{
              label: "Airline",
              name: "airline",
            }}
            field5={{
              label: "Feedback",
              name: "feedback",
            }}
          />
        }
      />
      <SideDrawer
        setSidePopup={setSettings}
        sidePopup={settings}
        component={
          <BrandSettings
            mainHeading="Testimonial Settings"
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            updateSettings={updateSettings}
            defHeading={defSettings?.testimonial_heading}
            defSubHeading={defSettings?.testimonial_sub_heading}
          />
        }
      />
    </>
  );
};

export default ManageTestimonials;
