import { useState } from "react";
import Consultantfilter from "../../../InquiryFilterButtons/components/Consultantfilter";
import { UPDATE_CONSULTANT } from "./graphql/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Tooltip } from "@mantine/core";
import { filterStatus } from "~/modules/InquiriesModule/data/filters";
import { getTimeReadableFormatInq } from "~/utils/GetTimeReadable";

export default function InquiryStatus({ inquiry }: any) {
  const [edit, setEdit] = useState<any>(false);
  const userRole = useUserDefaultRole();
  const userId = useUserId();
  const [status, setStatus] = useState<any>({});

  const [updateCon] = useMutation(UPDATE_CONSULTANT);

  const changeStatus = async (event: any, newValue: any) => {
    setStatus(newValue);
  };

  const updateStatus = async () => {
    await updateCon({
      variables: {
        id: inquiry?.id,
        data: {
          status: status.value,
        },
      },
    });
    toast.success("Status Updated");
    setEdit(false);
  };

  const role = useUserDefaultRole();
  const statusFiltered = [...filterStatus];
  const currentStatus = statusFiltered.find(
    (status) => status.value === inquiry?.status
  );

  return (
    <div className="text-base font-semibold text-primary dark:text-dark-primary  capitalize">
      {edit ? (
        <div className="mb-2">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              role !== "admin" ? statusFiltered.splice(2, 5) : statusFiltered
            }
            onChange={changeStatus}
            getOptionLabel={(option: any) => option.title}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Status" />}
          />
        </div>
      ) : (
        <span className="block">
          <Tooltip
            label={`${inquiry?.thp_inquiry_flag?.name} | ${
              inquiry?.inq_comments?.[0]?.comment || "No Comment Added!"
            } ${
              inquiry?.inq_comments?.length > 0
                ? ` | ${getTimeReadableFormatInq(
                    inquiry?.inq_comments?.[0]?.created_at
                  )} `
                : ``
            }`}
          >
            <span>
              {inquiry?.status === "pending"
                ? "Unassign"
                : currentStatus?.title}
            </span>
          </Tooltip>
        </span>
      )}

      {(userId === inquiry?.picked_by || userRole === "admin") && (
        <>
          {!edit ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setEdit(true);
              }}
            >
              Change
            </Button>
          ) : (
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  updateStatus();
                }}
              >
                Update
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
