import { MenuItem } from "@mui/material";

const MenuItems = () => {
  return (
    <>
      <div className="shadow py-[9px] ">
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Contact info
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Select messages
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Close chat
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Mute notifications
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Disapperaing messages
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Clear Chat
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Delete Chat
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Report
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Block
        </MenuItem>
      </div>
    </>
  );
};

export default MenuItems;
