import Layout from "~/components/common/Layout";
import ServicesLeads from "~/modules/InquiriesModule/segments/ServicesLeads";
import InquiriesModule from "~/modules/InquiriesModule";

const ServicesLeadsPage = () => {
  return (
    <Layout>
      <InquiriesModule>
        <ServicesLeads />
      </InquiriesModule>
    </Layout>
  );
};

export default ServicesLeadsPage;
