import { useQuery } from "@apollo/client";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { WHATSAPP_TEMPLATE_CREATE } from "~/graphql/actions/whatsapp/whatsapp_templates_create";
import { GET_TEMPLATES } from "~/graphql/actions/whatsapp/whatsapp_templates_get_all";

import { INSERT_MESSAGES } from "../../graphql/Mutation";
import moment from "moment";
import { useUserId } from "@nhost/react";
import { useParams } from "react-router-dom";
import { INSERT_COMMUNITY_MESSAGE } from "~/graphql/brd_whatsapp_community_messages/Mutation";
import useWhatsappTemplates from "~/hooks/useWhatsappTemplates";

const startOfQ12022 = dayjs("2022-01-01T00:00:00.000");
const endOfQ12022 = dayjs("2022-03-31T23:59:59.999");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function SelectTemplate({
  setAttachmentType,
  setShowModal,
  community = false,
  brdId,
}: any) {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [loading, setIsLoading] = useState(false);
  const [headerVariable, setHeaderVariable] = useState(false);
  const [bodyVariable, setBodyVariable] = useState(0);
  const [header, setHeader] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateLanguage, setTemplateLanguage] = useState("");
  const [headerVariables, setHeaderVariables] = useState<any>([]);
  const [headerVariablesType, setHeaderVariablesType] = useState<any>();
  const [body, setBody] = useState("");
  const [bodyO, setBodyO] = useState("");
  const [headerO, setHeaderO] = useState("");
  const [bodyVariables, setBodyVariables] = useState<any>([]);
  const [footer, setFooter] = useState("");
  const [createTemplate] = useMutation(WHATSAPP_TEMPLATE_CREATE);
  const userId = useUserId();

  const { conversation_id } = useParams();

  const [insertMessages] = useMutation(INSERT_MESSAGES);
  const [insertComunityMessages] = useMutation(INSERT_COMMUNITY_MESSAGE);

  const { templates }: any = useWhatsappTemplates(brdId && brdId);

  const template = watch("template");

  useEffect(() => {
    setBody("");
    setHeader("");
    setFooter("");
    setTemplateName(template?.name);
    setTemplateLanguage(template?.language);
    setBodyVariables([]);
    setHeaderVariables([]);
    if (template?.components) {
      template?.components.map((component: any) => {
        if (component?.type === "BODY") {
          let text = component?.text;
          const variables: any = component?.example?.body_text?.[0];
          variables && setBodyVariables([...variables]);
          setBody(text);
          setBodyO(text);
        }
        if (component?.type === "HEADER") {
          let text = component?.text;
          const variables: any = component?.example?.header_text;
          variables
            ? setHeaderVariables([...variables])
            : setHeaderVariables(["URL"]);
          text && setHeader(text);
          text && setHeaderO(text);
          setHeaderVariablesType(component?.format);
        }
        if (component?.type === "FOOTER") {
          setFooter(component?.text);
        }
      });
    }
  }, [template]);

  useEffect(() => {
    headerVariables.map(() => {
      if (headerVariables) {
        let text = headerO;
        headerVariables.map((variable: any, index: any) => {
          setValue(`header.var.${index + 1}`, variable);
          const regex = new RegExp(`\\{\\{${index + 1}}\\}`, "g");
          while (text.includes(`{{${index + 1}}}`)) {
            text = text.replace(regex, variable);
          }
        });
        setHeader(text);
      }
    });
  }, [headerVariables]);

  useEffect(() => {
    bodyVariables.map(() => {
      if (bodyVariables) {
        let text = bodyO;
        bodyVariables.map((variable: any, index: any) => {
          const regex = new RegExp(`\\{\\{${index + 1}}\\}`, "g");
          while (text.includes(`{{${index + 1}}}`)) {
            text = text.replace(regex, variable);
          }
        });
        setBody(text);
      }
    });
  }, [bodyVariables]);

  async function sendMessage() {
    const textmsg = `
    ${header?.trim()}
    ${body?.trim()}
    ${footer?.trim()}
    `;
    if (textmsg !== "") {
      const currentTime = moment().utc();
      const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
      const payload: any = [
        {
          conversation_id,
          sender_role: "brand",
          sender_id: userId,
          text: textmsg,
          type: "template",
          timestamp: formattedTime,
          template_name: templateName,
          bodyVariables: bodyVariables.join(","),
          headerVariables: headerVariables.join(","),
          template_language: templateLanguage,
          headerVariablesType: headerVariablesType,
        },
      ];
      const resMessagesInsert = await insertMessages({
        variables: { data: payload },
      });
    }
  }

  async function sendCommunityMessage() {
    const textmsg = `
    ${header?.trim()}
    ${body?.trim()}
    ${footer?.trim()}
    `;
    if (textmsg !== "") {
      const currentTime = moment().utc();
      const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
      const payload: any = [
        {
          community_id: conversation_id,
          sender_id: userId,
          text: textmsg,
          type: "template",
          timestamp: formattedTime,
          template_name: templateName,
          bodyVariables: bodyVariables.join(","),
          headerVariables: headerVariables.join(","),
          headerVariablesType: headerVariablesType,
          template_language: templateLanguage,
        },
      ];
      const resMessagesInsert = await insertComunityMessages({
        variables: { data: payload },
      });
    }
  }

  const onSubmit = async () => {
    setIsLoading(true);
    !community ? await sendMessage() : await sendCommunityMessage();

    setIsLoading(false);
    setAttachmentType(null);
    setShowModal(false);
  };

  const filteredTemplates = templates?.filter(
    (item: any) => item.status.toLowerCase() === "approved"
  );

  if (templates?.length <= 0)
    return (
      <div className="h-full w-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  // if (error) return <>{error.message}</>;

  return (
    <form className="flex w-full gap-8 p-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-[30%]">
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px] mt-[15px]">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("template", { required: true })}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                id="combo-box-demo"
                options={filteredTemplates}
                onChange={(_, data) => field.onChange(data)}
                getOptionLabel={(option: any) => option?.name}
                sx={{ width: "100%" }}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["template"] && el.focus();
                    }}
                    error={errors["template"] ? true : false}
                    {...params}
                    label="Template"
                  />
                )}
              />
            )}
          />
        </div>

        {headerVariables.map((num: any, index: any) => {
          return (
            <>
              <div>
                <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px] mt-2">
                  <TextField
                    id="outlined-basic"
                    label={`Header: {{${index + 1}}}`}
                    {...register(`header.var.${index + 1}`, {
                      required: true,
                    })}
                    variant="outlined"
                    className="w-full"
                    onChange={(val: any) => {
                      let newArr = [...headerVariables];
                      newArr[index] = val.target.value;
                      setHeaderVariables(newArr);
                    }}
                    value={headerVariables[index]}
                  />
                </div>
              </div>
            </>
          );
        })}

        {bodyVariables.map((num: any, index: any) => {
          return (
            <>
              <div>
                <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px] mt-2">
                  <TextField
                    id="outlined-basic"
                    label={`Body: {{${index + 1}}}`}
                    {...register(`body.var.${index + 1}`, {
                      required: true,
                    })}
                    onChange={(val: any) => {
                      let newArr = [...bodyVariables];
                      newArr[index] = val.target.value;
                      setBodyVariables(newArr);
                    }}
                    value={bodyVariables[index]}
                    variant="outlined"
                    className="w-full"
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      {(header || body || footer) && (
        <div className="w-[70%]">
          <div className="flex flex-col items-end pt-[15px]">
            <p className="text-[10px] mr-1 text-gray-600">{`In community replaced Name with "{{userName}}"`}</p>
            <div className="flex flex-col p-4  space-x-1 w-full overflow-x-auto rounded-sm mt-2 border border-secondary dark:border-dark-secondary">
              {header && (
                <div className="font-bold p-0 text-black dark:text-white">
                  {header}
                </div>
              )}
              {body && (
                <div className="font-normal p-0 text text-black dark:text-white">
                  {body}
                </div>
              )}
              {footer && (
                <div className="font-[300] p-0 text-[13px] text-primary dark:text-dark-primary">
                  {footer}
                </div>
              )}
            </div>
          </div>

          <div className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px]  mt-[15px] justify-end">
            <LoadingButton
              color="secondary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
            >
              <span>Send Template</span>
            </LoadingButton>
          </div>
        </div>
      )}
    </form>
  );
}
