import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useState } from "react";

const Header = ({ data, setShowUserInfo }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="bg-whatsappPrimaryBg dark:bg-dark-primary flex items-center justify-between border-l dark:border-dark-secondary py-[8px] px-[16px] w-full text-primary dark:text-dark-primary">
      <div
        className="flex items-center w-full overflow-hidden cursor-pointer"
        onClick={() => setShowUserInfo(true)}
      >
        <AccountCircleIcon
          sx={{
            color: "#9155FD",
            fontSize: "50px",
          }}
        />
        <div className="pl-[15px]">
          <h1 className="text-[16px] text-black dark:text-white">
            {data?.user_name}
          </h1>
          <p className="text-[14px] truncate overflow-hidden w-[150px]">
            {data?.user_email}
          </p>
        </div>
      </div>
      <div className="flex items-center w-full justify-end">
        <div className="mr-5">
          <SearchOutlinedIcon
            sx={{ color: darkMode ? "#E7E3FCDE" : "#3A3541DE" }}
          />
        </div>
        <div className="mx-2">
          <MoreVertOutlinedIcon
            sx={{
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
