import { gql } from "@apollo/client";

export const GET_BRANDS_WITH_BRD = gql`
  query GetUserBrand($brands: [uuid!]!) {
    brd_list(where: { id: { _in: $brands } }) {
      id
      name
    }
  }
`;
export const GET_BRANDS_WITH_THP = gql`
  query GetUserBrand($thp_id: uuid!) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      id
      name
    }
  }
`;

export const GET_ALIASES = gql`
  query MyQuery2($refresh_token: String!) {
    gmail_get_aliases(arg1: { refresh_token: $refresh_token }) {
      aliases {
        displayName
        isDefault
        isPrimary
        replyToAddress
        sendAsEmail
        signature
      }
    }
  }
`;
