import { gql } from "@apollo/client";

export const ADD_BRAND = gql`
  mutation AddBrand($brand: [brd_list_insert_input!]!) {
    insert_brd_list(objects: $brand) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_BRAND_DETAIL = gql`
  mutation AddBrandDetail($brandDetail: [brd_detail_insert_input!]!) {
    insert_brd_detail(objects: $brandDetail) {
      affected_rows
    }
  }
`;

export const ADD_DOMAINS = gql`
  mutation AddDomains($domain: String!) {
    addDomains(arg1: { domain: $domain }) {
      cloudflare {
        errors
        messages
        result {
          comment
          content
          id
          created_on
          locked
          meta {
            auto_added
            managed_by_apps
            managed_by_argo_tunnel
            source
          }
          name
          modified_on
          proxiable
          proxied
          tags
          ttl
          type
          zone_id
          zone_name
        }
        success
      }
      vercel {
        apexName
        createdAt
        gitBranch
        name
        projectId
        redirect
        updatedAt
        redirectStatusCode
        verified
      }
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation updateBrand(
    $id: brd_list_pk_columns_input!
    $brdData: brd_list_set_input!
  ) {
    update_brd_list_by_pk(pk_columns: $id, _set: $brdData) {
      id
    }
  }
`;

export const UPDATE_BRAND_DETAIL = gql`
  mutation updateBrandDetail(
    $id: brd_detail_pk_columns_input!
    $brdData: brd_detail_set_input!
  ) {
    update_brd_detail_by_pk(pk_columns: $id, _set: $brdData) {
      id
    }
  }
`;
