import { gql } from "@apollo/client";

export const GET = gql`
    subscription MySubscription($campaign_id: bigint) {
        brd_google_ads(
        where: {
            campaign_id: { _eq: $campaign_id }
        }
        ) {
            id
            name
            status
            start_date
            end_date
            brd_google_ad_updates_aggregate {
                aggregate {
                    sum {
                        click
                        cost
                        impression
                    }
                }
            }
        }
    }`;