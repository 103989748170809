import { gql } from "@apollo/client";

export const INSERT_SOCIAL_MEDIA = gql`
  mutation InsertSocialMedia($data: brd_social_media_insert_input!) {
    insert_brd_social_media_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_SOCIAL_MEDIA = gql`
  mutation UpdateSocialMedia(
    $id: brd_social_media_pk_columns_input!
    $data: brd_social_media_set_input
  ) {
    update_brd_social_media_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;
