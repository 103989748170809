import { gql } from "@apollo/client";

export const GET_ACTIVE_MEMBERS_WITH_BRANDS = gql`
  query CustomerQuery($brands: [uuid!]!) {
    users(
      where: {
        brd_users: {
          brd_id: { _in: $brands }
          users: { defaultRole: { _neq: "user" } }
        }
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
      thp_users_profile {
        x
        instagram
        facebook
        linkedin
        skype
        id
        basic_salary
        commission
        company_phone
        discord
        pseudo_name
        youtube
        bio
      }
    }
  }
`;

export const GET_ACTIVE_MEMBERS_WITH_THP = gql`
  query CustomerQuery($thp_id: uuid!) {
    users(
      where: {
        _or: [
          { thp_list: { id: { _eq: $thp_id } } }
          {
            brd_users: {
              thp_id: { _eq: $thp_id }
              users: { defaultRole: { _neq: "user" } }
            }
          }
        ]
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      thp_list {
        id
      }
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
      thp_users_profile {
        x
        instagram
        facebook
        linkedin
        id
        skype
        basic_salary
        company_phone
        discord
        commission
        pseudo_name
        youtube
        bio
      }
    }
  }
`;
