import { gql } from "@apollo/client";

export const ADD_ACCOUNT = gql`
  mutation AddAccount($acc_list: [acc_list_insert_input!]!) {
    insert_acc_list(objects: $acc_list) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
