export const userTypes = [
    {
        title: 'Choose type',
        value: ''
    },
    {
        title: 'Consultant',
        value: 'consultant'
    },
    {
        title: 'Accountant',
        value: 'accountant'
    },
    {
        title: 'Vendor',
        value: 'vendor'
    },
    {
        title: 'User',
        value: 'user'
    },
]


export const userReportOptions:any = {
    consultant: [
        {
            title: "Choose report",
            value: '',
        },
        {
            title: "Pick Inquiry",
            value: 'pick_inquiry',
        },
        {
            title: "Issued Tickets",
            value: 'issued_tickets',
        },
        {
            title: "Sales",
            value: 'sales',
        },
        {
            title: "Disputes",
            value: 'disputes',
        },
    ],
    accountant: [
        {
            title: "Choose report",
            value: '',
        },
        {
            title: "No. of paid confimations",
            value: "paid_confirmations"
        },
        {
            title: "Deposits made to vendor",
            value: "deposits_made_to_vendor"
        },
    ],
    vendor: [
        {
            title: "Choose report",
            value: '',
        },
        {
            title: "Total Deposits",
            value: "total_deposits",
        },
        {
            title: "No. of Issued Tickets",
            value: "vendor_issued_tickets",
        },
        {
            title: "Ledger",
            value: "ledger",
        },
    ],
    user: [
        {
            title: "Choose report",
            value: '',
        },
        {
            title: "Opened Inquiries",
            value: "opened_inquiries"
        },
        {
            title: "Picked Inquiries",
            value: "picked_inquiries"
        },
        {
            title: "Canceled Inquiries",
            value: "canceled_inquiries"
        },
        {
            title: "Disputed Inquiries",
            value: "disputed_inquiries"
        },
        {
            title: "Amount Receieved",
            value: "amount_received"
        },
        {
            title: "Profit",
            value: "profit"
        },
    ]
}