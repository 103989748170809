import React from "react";
import { URLs } from "~/config/enums";
import GetFile from "./components/GetFile";

export default function WhatsappMessage({ payload }: any) {
  return (
    <div className="">
      {payload?.type === "text" ? (
        <>{payload.text}</>
      ) : payload?.type === "image" ? (
        <img width="100%" src={URLs.FILE_URL + payload?.text} />
      ) : payload?.type === "video" ? (
        <>
          <video width="100%" height="240" controls>
            <source src={URLs.FILE_URL + payload?.text} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : payload?.type === "audio" ? (
        <>
          <audio controls style={{ width: "100%" }}>
            <source src={URLs.FILE_URL + payload?.text} type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
        </>
      ) : payload?.type === "document" ? (
        <a href={URLs.FILE_URL + payload?.text} target="_blank">
          Download File
        </a>
      ) : (
        <>{payload?.text}</>
      )}
    </div>
  );
}
