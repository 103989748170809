import { useQuery } from "@apollo/client";
import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_TEMPLATES } from "~/graphql/actions/whatsapp/whatsapp_templates_get_all";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useWhatsappTemplates from "~/hooks/useWhatsappTemplates";
import TemplateRow from "~/modules/WhatsappModule/segments/WhatsappTemplates/components/TemplateRow";
import TemplateRowSekeleton from "~/modules/WhatsappModule/segments/WhatsappTemplates/components/TemplateRowSekeleton";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function TemplateList({ templateSearchText }: any) {
  const [brdIds, setBrdIds] = useState<any>();

  const { brands }: any = brandsStore();

  useEffect(() => {
    const brd_ids = brands.map((item: any) => item.id);

    setBrdIds(brd_ids);
  }, [brands]);

  const { templates }: any = useWhatsappTemplates(brdIds && brdIds);

  return (
    <>
      <div className="text-primary dark:text-dark-primary overflow-scroll flex-grow">
        {templates?.length <= 0 ? (
          <>
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
            <TemplateRowSekeleton />
          </>
        ) : (
          templates?.map((template: any) => {
            return <TemplateRow template={template} />;
          })
        )}
      </div>
    </>
  );
}
