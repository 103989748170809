import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TagFacesOutlinedIcon from "@mui/icons-material/TagFacesOutlined";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import { Popover, TextField, makeStyles } from "@mui/material";
import Add from "./Add";
import { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import EmojiPicker from "emoji-picker-react";

const Footer = ({
  setShowEmojis,
  showEmojis,
  setShowRecording,
  type,
  setShowDocSndPrev,
}: any) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOnInputChange = (e: any) => {
    setIsTyping(e.target.value.trim() !== "");
  };

  const handleInputChange = (event: any): any => {
    setInputValue(event.target.value);
  };

  const handleEmojiClick = (event: any) => {
    // ;
    setInputValue(inputValue + event.emoji);
  };

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div
      className={`px-[16px] py-[10px] flex items-center text-primary dark:text-dark-primary z-10 ${
        type === "documentPreview"
          ? "justify-center bg-transparent"
          : "bg-whatsappPrimaryBg dark:bg-dark-primary"
      }`}
    >
      {!(type === "documentPreview") && (
        <>
          <div onClick={(e: any) => setAnchorEl(e.currentTarget)}>
            {showMenu ? (
              <CloseOutlinedIcon
                sx={{
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
                onClick={(e: any) => {
                  setShowMenu(true);
                }}
              />
            ) : (
              <AddOutlinedIcon
                sx={{
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
                onClick={() => {
                  setShowMenu(true);
                }}
              />
            )}
          </div>

          <Popover
            sx={{
              backgroundColor: "transparent",
              top: "-20px",
              cursor: "pointer",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowMenu(!showMenu);
            }}
            open={showMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Add setShowDocSndPrev={setShowDocSndPrev} />
          </Popover>
        </>
      )}

      <div
        className={`relative flex items-end w-full mx-[16px] ${
          type === "documentPreview" && "max-w-[60%]"
        }`}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
      >
        <TagFacesOutlinedIcon
          sx={{
            position: "absolute",
            left: "10px",
            bottom: "10px",
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={() => {
            setShowEmojis(!showEmojis);
          }}
        />
        <Popover
          sx={{
            background: "transparent",
            top: "-15px",
            cursor: "pointer",
          }}
          anchorEl={anchorEl}
          onClose={() => {
            setShowEmojis(!showEmojis);
          }}
          open={showEmojis}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            searchDisabled
            style={{
              backgroundColor: darkMode && "#28183D",
            }}
          />
        </Popover>
        <TextField
          fullWidth
          multiline
          onChange={handleInputChange}
          onInput={handleOnInputChange}
          value={inputValue}
          maxRows={4}
          sx={{
            "& fieldset": { border: "none" },
            "& .MuiOutlinedInput-root": {
              backgroundColor: darkMode
                ? type === "documentPreview"
                  ? "#28183D"
                  : "#312D4B"
                : "#FFFFFF",
              padding: "10px 45px",
              borderRadius: "10px",
            },
          }}
        />
      </div>
      {!(type === "documentPreview") && (
        <>
          {isTyping ? (
            <SendOutlinedIcon
              sx={{ color: "#3A3541DE", cursor: "pointer", fontSize: "30px" }}
            />
          ) : (
            <MicOutlinedIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
                fontSize: "30px",
              }}
              onClick={() => setShowRecording(true)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Footer;
