import React from "react";
import { FaTimes } from "react-icons/fa";
// Import Components
import SingleFlight from "./SingleFlight";
import AirlineBox from "components/FormInputs/AirlineBox";
import InputField from "components/FormInputs/InputField";

export default function StopDetail({
  register,
  errors,
  setValue,
  stops,
  setStops,
  control,
  index,
  leg,
  unregister,
  getValues,
}: any) {
  // Remove Stop Function
  const removeStop = () => {
    let arr = [...stops];
    arr.splice(index, 1);
    setValue(`${leg}.stops`, [...arr]);
    setStops([...arr]);
    unregister(`${leg}.stops.${index}`);
  };

  return (
    <div className="bg-gray-300 relative p-2 rounded-lg dark:bg-[#343143] mt-[2px]">
      {/* Remove Stop Button */}
      {index !== 0 && stops.length === index + 1 && (
        <button
          type="button"
          className="absolute bg-basic right-0  m-[2px] rounded-sm  py-[2px] px-[6px] text-white "
          onClick={() => {
            removeStop();
          }}
        >
          <FaTimes />
        </button>
      )}

      <div className="grid grid-cols-3">
        {/* Loop Single Flight Input like. CitySelect and Datetime */}
        <div className="col-span-2">
          {["depart", "arrival"].map((direction: any, indexKey: any) => {
            return (
              <SingleFlight
                key={indexKey}
                direction={direction}
                setValue={setValue}
                control={control}
                errors={errors}
                register={register}
                leg={leg}
                index={index}
                getValues={getValues}
              />
            );
          })}
        </div>
        <div className="px-2">
          {/* Select Airline */}
          <AirlineBox
            control={control}
            errors={errors}
            setValue={setValue}
            fieldName={`${leg}.stops.${index}.airline`}
            defaultValue={getValues(`${leg}.stops.${index}.airline`) || ""}
            label="Airline"
            labelClasses="text-primary dark:text-dark-primary mb-0"
            placeholder="Choose your location"
            required={true}
            inputClasses={"text-primary dark:text-dark-primary focus:outline-none focus:border-basic h-9"}
            optionBoxClasses="dark:bg-dark-secondary"
            optionClasses="text-primary dark:text-dark-primary"
          />
          {/* Enter airline number */}
          <InputField
            label="Airline Number"
            register={register}
            errors={errors}
            placeHolder="Airline Number"
            fieldName={`${leg}.stops.${index}.airlineNumber`}
            labelClasses="mb-[5px]"
            inputClasses="focus:outline-none focus:border-basic"
            required={true}
          />
        </div>
      </div>
    </div>
  );
}
