import React from "react";
import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import ManageFAQs from "~/modules/BrandModule/segments/ManageFAQs";
export default function EditFAQs() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <ManageFAQs />
        </BrandModule>
      </Layout>
    </div>
  );
}
