import { gql } from "@apollo/client";

export const INSERT_CASE_STUDY = gql`
  mutation InsertCaseStudy($data: services_casestudy_insert_input!) {
    insert_services_casestudy(objects: [$data]) {
      affected_rows
    }
  }
`;

export const DELETE_CASESTUDY = gql`
  mutation MyMutation2($id: uuid!) {
    delete_services_casestudy(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_SERVICE_CASESTUDY = gql`
  mutation MyMutation(
    $data: services_casestudy_set_input!
    $caseStudyId: uuid!
  ) {
    update_services_casestudy(
      where: { id: { _eq: $caseStudyId } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;
