import { gql } from "@apollo/client";

export const ADD_PASSENGER = gql`
  mutation MyMutation($passengers: [inq_passengers_insert_input!]!) {
    insert_inq_passengers(objects: $passengers) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_STAY_ROOMS = gql`
  mutation MyMutation($rooms: [stay_inq_rooms_insert_input!]!) {
    insert_stay_inq_rooms(objects: $rooms) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const CAR_ADD_PASSENGER = gql`
  mutation MyMutation($passengers: [car_inq_passengers_insert_input!]!) {
    insert_car_inq_passengers(objects: $passengers) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const TRAIN_ADD_PASSENGER = gql`
  mutation MyMutation($passengers: [train_inq_passengers_insert_input!]!) {
    insert_train_inq_passengers(objects: $passengers) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const EDIT_PASSENGER = gql`
  mutation EditPassenger(
    $id: uuid
    $gender: String
    $type: String
    $first_name: String
    $last_name: String
    $dob: date
    $nationality: String
    $passport_no: String
    $passport_expiry: date
  ) {
    update_inq_passengers(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        gender: $gender
        first_name: $first_name
        last_name: $last_name
        dob: $dob
        nationality: $nationality
        passport_no: $passport_no
        passport_expiry: $passport_expiry
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const STAY_EDIT_ROOM = gql`
  mutation EditPassenger(
    $id: uuid
    $booked_by: String
    $no_of_days: Int
    $room_number: String
  ) {
    update_stay_inq_rooms(
      where: { id: { _eq: $id } }
      _set: {
        booked_by: $booked_by
        no_of_days: $no_of_days
        room_number: $room_number
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CAR_EDIT_PASSENGER = gql`
  mutation EditPassenger(
    $id: uuid
    $gender: String
    $type: String
    $first_name: String
    $last_name: String
    $dob: date
    $nationality: String
  ) {
    update_car_inq_passengers(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        gender: $gender
        first_name: $first_name
        last_name: $last_name
        dob: $dob
        nationality: $nationality
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const TRAIN_EDIT_PASSENGER = gql`
  mutation EditPassenger(
    $id: uuid
    $gender: String
    $type: String
    $first_name: String
    $last_name: String
    $dob: date
    $nationality: String
  ) {
    update_train_inq_passengers(
      where: { id: { _eq: $id } }
      _set: {
        type: $type
        gender: $gender
        first_name: $first_name
        last_name: $last_name
        dob: $dob
        nationality: $nationality
      }
    ) {
      returning {
        id
      }
    }
  }
`;
