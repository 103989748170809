import { gql } from "@apollo/client";

export const EDIT_ACCOUNT = gql`
  mutation EditAccount($id: uuid, $name: String!) {
    update_acc_list(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
