import { useUserDefaultRole } from "@nhost/react";
import React from "react";

export default function InquiresHead({ userCol = true, hide }: any) {
  const role = useUserDefaultRole();
  return (
    <thead className="bg-secondary rounded-t-lg shadow dark:bg-dark-secondary text-primary dark:text-dark-primary">
      <tr>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          ID & Received Time <br />
          {!hide && (
            <>
              {role !== "user" && (
                <>
                  Source
                </>
              )}

            </>
          )}
        </th>

        {role !== "user" && (
          <>
            {userCol && (
              <th
                scope="col"
                className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
              >
                User
              </th>
            )}
          </>
        )}

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Consultant
        </th>

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Origin / Destination
          {!hide && (
            <>
              <br />
              Passenger & Budget
            </>
          )}
        </th>


        {hide && (<th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase "
        >
          Date
        </th>)}

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase "
        >
          Status
        </th>

        {hide && (
          <th
            scope="col"
            className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
          >
            Passenger <br /> & Budget
          </th>
        )}

        {hide && (
          <>
            {role !== "user" && (
              <th
                scope="col"
                className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
              >
                Source <br />
              </th>
            )}
          </>
        )}
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
        >
          Action
        </th>
      </tr>
    </thead>
  );
}
