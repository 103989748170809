import { gql } from "@apollo/client";

export const SEND_MAILJET_EMAILS = gql`
  mutation SendMailjetEmails(
    $brdId: String!
    $campaignName: String!
    $from: From!
    $subject: String!
    $body: String!
    $listId: String!
    $replyEmail: String!
    $previewText: String!
  ) {
    send_mailJet_emails(
      arg1: {
        brdId: $brdId
        campaignName: $campaignName
        from: $from
        subject: $subject
        body: $body
        listId: $listId
        previewText: $previewText
        replyEmail: $replyEmail
      }
    ) {
      status
    }
  }
`;
