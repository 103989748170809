import { useQuery } from "@apollo/client";
import { GET_DEFAULT_SETTINGS } from "~/modules/BrandModule/segments/Destinations/components/DestinationSettings/graphql/Query";

export default function useBrandDefaultSettings(brdID: any) {
  const { data } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brdID },
  });
  const defSettings = data?.brd_default_setting?.at(0);

  return { brdDefaultSettings: defSettings };
}
