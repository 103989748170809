import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Avatar } from "@mui/material";
import { URLs } from "~/config/enums";

const DialogueBox = ({
  showDialogueBox,
  setShowDialogueBox,
  video,
  setShowReactions,
  setAnchorEl,
  showReaction,
  reactionEmoji,
  setReactionEmoji,
  message,
}: any) => {
  return (
    <>
      <Dialog fullScreen open={showDialogueBox}>
        <div className="h-screen w-screen flex flex-col">
          <header className="flex items-center justify-between px-5 py-3">
            <div className="flex items-center">
              <Avatar
                alt="whatsappAvator"
                src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
              />
              <div className="pl-[15px]">
                <h1 className="text-[15px] text-black">M Usman</h1>
                <p className="text-[13px]">1/7/2024 at 5:01 PM</p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <EmojiEmotionsIcon
                sx={{ cursor: "pointer" }}
                onClick={(e: any) => {
                  setShowReactions(!showReaction);
                  setAnchorEl(e.currentTarget);
                }}
              />
              <DownloadIcon sx={{ cursor: "pointer" }} />
              <CloseIcon
                sx={{ cursor: "pointer", fontSize: "27px" }}
                onClick={() => setShowDialogueBox(false)}
              />
            </div>
          </header>
          <div className="flex items-center justify-center flex-grow">
            <div className="relative max-w-[700px] max-h-screen mb-3">
              {video ? (
                <video controls>
                  <source
                    src={URLs.FILE_URL + message?.text}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={URLs.FILE_URL + message?.text}
                  className="h-[80vh] w-full object-cover"
                />
              )}
              {reactionEmoji.length > 0 && (
                <div
                  className="absolute left-[18px] bottom-[-20px] bg-whatsappSecondaryBg h-[25px] w-[28px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer text-sm shadow"
                  onClick={() => {
                    setReactionEmoji("");
                  }}
                >
                  {reactionEmoji}
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DialogueBox;
