import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_HEADINGS } from "~/graphql/brd_pages_headings/Mutation";

const EditPageHeadings = ({
  headings,
  setSidePopup,
  SectionTypes,
  pageHeadings,
}: any) => {
  const [selectedSection, setSelectedSection] = useState<any>();
  const client = useApolloClient();
  const { brd_id, pageType } = useParams();

  const selectedSections = pageHeadings?.map((item: any) => item?.section_name);

  const [updateHeadings, { data, loading, error }] =
    useMutation(UPDATE_HEADINGS);

  const { register, handleSubmit, setValue, reset, getValues } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const response = await updateHeadings({
        variables: {
          brd_id,
          id: headings.id,
          heading: formData.heading,
          sectionname: formData.sectionType,
          subheading: formData.subheading,
          pagename: pageType,
        },
      });

      if (response.data.update_brd_pages_headings.affected_rows > 0) {
        await client.refetchQueries({
          include: "all",
        });
        toast.success("Headings updated successfully.");

        setSidePopup(false);

        await client.refetchQueries({ include: "all" });
      } else {
        toast.error("No headings updated.");
      }
    } catch (e) {
      console.error("Error updating headings:", e);
    }

    console.log("form Data:", formData, pageType);
  };

  const helperTextHeading =
    pageType === "single-service-page"
      ? "Variable Allowed {{service_name}}"
      : pageType === "case-study"
      ? "Variable Allowed {{case_study_name}}"
      : "Variable Allowed {{service_name}}";
  return (
    <>
      <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
          Edit Headings
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <Autocomplete
              defaultValue={headings.page_name}
              options={SectionTypes.filter(
                (item: any) =>
                  selectedSections.includes(
                    item.value === headings.section_name
                      ? "dummytest"
                      : item.value
                  ) === false
              )}
              getOptionLabel={(option: any) => option.name}
              value={
                SectionTypes.find(
                  (section: any) => section.value === selectedSection
                ) || null
              }
              onChange={(event, newValue: any) => {
                if (newValue) {
                  setValue("sectionType", newValue.value);
                  setSelectedSection(newValue.value);
                } else {
                  setValue("sectionType", null);
                  setSelectedSection(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Section"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              label="Heading"
              variant="outlined"
              defaultValue={headings.heading}
              fullWidth
              helperText={helperTextHeading}
              {...register("heading", { required: true })}
              className="dark:text-dark-primary"
            />

            <TextField
              label="Sub Heading"
              variant="outlined"
              defaultValue={headings.subheading}
              fullWidth
              multiline
              rows={3}
              helperText={helperTextHeading}
              {...register("subheading", { required: true })}
              className="dark:text-dark-primary"
            />

            <Button type="submit" variant="contained" color="primary">
              {loading ? "Updating..." : "Update"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditPageHeadings;
