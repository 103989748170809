import { useEffect, useState } from "react";
import Papa from "papaparse";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { useApolloClient, useMutation } from "@apollo/client";
import { GET_EMAIL_CAMP_CONVERSATION } from "~/graphql/brd_email_campaigns/Query";
import {
  INSERT_EMAIL_CAMP_CONVERSATION,
  UPDATE_EMAIL_CAMP_CONVERSATION,
} from "~/graphql/brd_email_campaigns/Mutation";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CsvComp = ({ brdId }: any) => {
  const [data, setData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>("");
  const [cityCodes, setCityCodes] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const client = useApolloClient();

  const [insertConversations] = useMutation(INSERT_EMAIL_CAMP_CONVERSATION);
  const [updateConversations] = useMutation(UPDATE_EMAIL_CAMP_CONVERSATION);

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    setFileName(file.name);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setData(results.data);
      },
    });
  };

  useEffect(() => {
    const uniqueCityCodes = Array.from(
      new Set(data?.map((item: any) => item.cityCode))
    );
    setCityCodes(uniqueCityCodes);
  }, [data]);

  const generateDestinations = () => {
    setLoading(true);
    if (brdId?.length < 0 || brdId?.length > 1) {
      toast.error("Please Select a Brand");
      setLoading(false);
    } else {
      if (!data?.some((obj: any) => obj.hasOwnProperty("name"))) {
        toast.error(`The CSV file does not contain a column named "name"`);
        setLoading(false);
      } else if (!data?.some((obj: any) => obj.hasOwnProperty("cityCode"))) {
        toast.error(`The CSV file does not contain a column named "cityCode"`);
        setLoading(false);
      } else if (!data?.some((obj: any) => obj.hasOwnProperty("email"))) {
        toast.error(`The CSV file does not contain a column named "email"`);
        setLoading(false);
      } else {
        Promise.all(
          cityCodes?.map((cityCode: any) => {
            return new Promise((resolve, reject) => {
              client
                .subscribe({
                  query: GET_EMAIL_CAMP_CONVERSATION,
                  variables: {
                    condition: {
                      iata: { _eq: cityCode },
                      _and: { brd_id: { _eq: brdId?.[0] } },
                    },
                  },
                })
                .subscribe({
                  next(emailCamp) {
                    const toEmails = data
                      ?.filter((obj: any) => obj.cityCode === cityCode)
                      ?.map((item: any) => item.email);

                    const uniqueEmails = Array.from(new Set(toEmails));

                    const payload = {
                      user_email: uniqueEmails,
                      user_name: `${cityCode} - ${uniqueEmails?.length}`,
                      brd_id: brdId?.[0],
                      iata: cityCode,
                      emails_length: uniqueEmails?.length,
                    };

                    const addConv = async () => {
                      await insertConversations({
                        variables: { data: payload },
                      });
                    };

                    const updateConv = async () => {
                      await updateConversations({
                        variables: {
                          iata: cityCode,
                          brdID: brdId?.[0],
                          data: payload,
                        },
                      });
                    };

                    if (
                      emailCamp?.data?.brd_email_campaigns &&
                      emailCamp?.data?.brd_email_campaigns?.length < 1
                    ) {
                      addConv().then(resolve).catch(reject);
                    }
                    updateConv().then(resolve).catch(reject);
                  },
                });
            });
          })
        )
          .then(() => {
            toast.success("Destinations Generated Successfully");
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <div className="w-full items-center">
        <p className="text-[10px] mb-1 text-center text-red-500">{`Columns name should be in camelCase { name, cityCode, email } `}</p>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ minWidth: "200px" }}
        >
          Upload CSV file
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </Button>
        {fileName && (
          <>
            <p className="text-[14px] mt-1 mb-3 text-center text-nowrap overflow-hidden truncate">
              {fileName}
            </p>
            <Button variant="outlined" fullWidth onClick={generateDestinations}>
              {loading ? "Generating..." : "Generate Destinations"}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default CsvComp;
