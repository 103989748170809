// import { GET_LINKEDIN_ORGANIZATION_INFO } from "~/graphql/get_Linkedin_Org_Data/Query";
import { GET_LINKEDIN_ORG_DATA } from "~/graphql/get_Linkedin_Org_Data/Query";

export const getLinkedinOrgData = async (accessToken: any, client: any) => {
  const res = await client.query({
    query: GET_LINKEDIN_ORG_DATA,
    variables: { access_token: accessToken },
  });

  return {
    pagesData: res?.data?.get_Linkedin_Org_Data?.LinkedinOrgData,
  };
};
