import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import { useState } from "react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Popover } from "@mui/material";
import MenuItems from "./component/MenuItems";
import Reactions from "../Reactions";
import ReplyMessage from "../ReplyMessage";

const TextMessage = ({
  setShowReplyMessage,
  type,
  messageType,
  showMessageInfo,
  setShowMessageInfo,
  setShowUserInfo,
  setShowWhatsappChatBox,
}: any) => {
  const [showAdditional, setShowAdditional] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showReaction, setShowReactions] = useState(false);
  const [reactionEmoji, setReactionEmoji] = useState("");
  return (
    <div
      className={`flex items-center w-full my-1 ${
        reactionEmoji.length > 0 && "mb-6"
      } ${type === "recieve" ? "justify-start" : "justify-end"}`}
      onMouseEnter={() => setShowAdditional(true)}
      onMouseLeave={() => setShowAdditional(false)}
    >
      <div className="md:max-w-[70%] relative flex items-center">
        <div
          className={`rounded-lg shadow ${
            messageType === "messageReply" && "px-1 pt-1"
          }  ${
            type === "recieve"
              ? "bg-whatsappSecondaryBg dark:bg-dark-primary dark:text-white"
              : "bg-whatsappMessagebg "
          }`}
        >
          {messageType === "messageReply" && (
            <ReplyMessage
              replyType="messageReply"
              message="textMessage"
              type={type}
            />
          )}

          <div className="pl-2 px-1 flex items-end ">
            <h1 className="text-[14px] font-medium py-1 w-full">
              Lorem ipsum dolor sit amet consectetur Lorem ipsum.
            </h1>
            <p
              className={`text-[11px] m-[1px] ml-1 text-primary float-right  ${
                type === "recieve"
                  ? "min-w-12 dark:text-dark-primary"
                  : "min-w-16"
              }`}
            >
              1:17 PM{" "}
              {!(type === "recieve") && (
                <DoneAllOutlinedIcon
                  sx={{ fontSize: "15px", color: "#009de2" }}
                />
              )}
            </p>
          </div>
        </div>
        {showReaction && (
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                backgroundColor: "transparent",
                borderRadius: "30px",
              },
              background: "transparent",
              cursor: "pointer",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowReactions(!showReaction);
            }}
            open={showReaction}
            anchorOrigin={{
              vertical: "center",
              horizontal: type === "recieve" ? "left" : "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: type === "recieve" ? "left" : "right",
            }}
          >
            <Reactions
              setShowReactions={setShowReactions}
              setReactionEmoji={setReactionEmoji}
            />
          </Popover>
        )}
        {reactionEmoji.length > 0 && (
          <div
            className="absolute right-[18px] bottom-[-20px] bg-whatsappSecondaryBg h-[25px] w-[28px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer text-sm shadow"
            onClick={() => {
              setReactionEmoji("");
            }}
          >
            {reactionEmoji}
          </div>
        )}
        {showAdditional && (
          <>
            <div
              className={`absolute  bg-black/30 h-[22px] w-[22px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer ${
                type === "recieve" ? " right-[-30px]" : "left-[-30px]"
              }`}
              onClick={(e: any) => {
                setShowReactions(!showReaction);
                setAnchorEl(e.currentTarget);
              }}
            >
              <EmojiEmotionsIcon sx={{ color: "white", fontSize: "16px" }} />
            </div>
            <div
              className={`absolute h-[22px] w-[22px] rounded-full  flex items-center justify-center font-bold cursor-pointer ${
                type === "recieve"
                  ? messageType === "messageReply"
                    ? "top-1 right-1 text-gray-200 "
                    : "top-0 right-0 bg-whatsappSecondaryBg dark:bg-dark-primary text-primary"
                  : messageType === "messageReply"
                  ? "top-1 right-1 text-gray-200 "
                  : "top-0 right-0 bg-whatsappMessagebg text-whatsappSecondaryBg"
              }`}
              onClick={(e: any) => {
                setAnchorEl(e.currentTarget);
                setShowMenu(!showMenu);
              }}
            >
              <KeyboardArrowDownIcon sx={{ color: "gray", fontSize: "25px" }} />
            </div>
            <Popover
              sx={{
                background: "transparent",
              }}
              anchorEl={anchorEl}
              onClose={() => {
                setShowMenu(!showMenu);
              }}
              open={showMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItems
                setShowReplyMessage={setShowReplyMessage}
                setShowReactions={setShowReactions}
                setShowMenu={setShowMenu}
                showMessageInfo={showMessageInfo}
                setShowMessageInfo={setShowMessageInfo}
                setShowUserInfo={setShowUserInfo}
                setShowWhatsappChatBox={setShowWhatsappChatBox}
              />
            </Popover>
          </>
        )}
      </div>
    </div>
  );
};

export default TextMessage;
