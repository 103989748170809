import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_BRD_SERVICES_CATEGORY } from "~/graphql/brd_services_category/Query";
import brandsStore from "~/store/brandsStore/brandsStore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { DELETE_BRD_SERVICES_CATEGORY } from "~/graphql/brd_services_category/Mutation";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditCategory from "./components/EditCategory";

const ShowCategory = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const client = useApolloClient();
  const { brands }: any = brandsStore();
  const brdIds = brands.length > 0 ? brands.map((brand: any) => brand.id) : [];

  const { data, loading, error } = useQuery(GET_BRD_SERVICES_CATEGORY, {
    variables: { brdIds },
  });

  const [deleteCategory, { loading: deleteLoading }] = useMutation(
    DELETE_BRD_SERVICES_CATEGORY
  );
  const handleEdit = (category: any) => {
    setOpenPopup(true);
    setSelectedCategory(category);
  };

  // Handle Delete
  const handleDelete = async (id: any) => {
    try {
      await deleteCategory({
        variables: { id: id },
      });
      toast.success("Service deleted successfully.");

      await client.refetchQueries({
        include: "all",
      });
    } catch (error) {
      toast.error("Error deleting service.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading categories.</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.brd_services_category.map((category: any) => (
              <TableRow key={category.id}>
                <TableCell>{category.name}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(category)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(category.id)}
                    disabled={deleteLoading}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditCategory
              category={selectedCategory}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowCategory;
