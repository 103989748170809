import React from "react";
import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import ManageFAQs from "~/modules/BrandModule/segments/ManageFAQs";
import Universities from "~/modules/BrandModule/segments/Universities";
export default function UniversitiesPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <Universities />
        </BrandModule>
      </Layout>
    </div>
  );
}
