import React, { useEffect, useRef } from "react";
import { GOOGLE } from "../../../config/enums";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

declare global {
  interface Window {
    google: any;
  }
}

interface AuthenticateProps {
  onCodeReceived: (code: string) => void;
}

const Authenticate: React.FC<AuthenticateProps> = ({ onCodeReceived }) => {
  const { code } = useParams();

  const getAuthCode = () => {
    const currentURL = window.location.href; // This will include the entire URL including path

    const serverlessFunctionURL =
      "https://local.flygency.com/api/gmail/authenticate";

    window.location.href = `${serverlessFunctionURL}?origin=${encodeURIComponent(
      currentURL
    )}`;
  };

  useEffect(() => {
    if (code) {
      const decodedCode = atob(code);
      onCodeReceived(decodedCode);
    } else {
      console.error("Code is undefined");
    }
  }, [code]);

  return (
    <Button onClick={getAuthCode} variant="outlined">
      Authenticate with Google
    </Button>
  );
};

export default Authenticate;
