import { useSubscription } from "@apollo/client";
import { GET_BRAND_DETAILS } from "../EmailModule/segments/EmailSetting/graphql/Query";

import SidePopup from "~/components/common/SidePopup";
import SocialPopup from "./components/SocialPopup";
import ProfileConnectSocial from "./components/ProfileConnectSocial";

const SocialConnectModule = ({ brd_id }: any) => {
  const { data, loading, error } = useSubscription(GET_BRAND_DETAILS, {
    variables: {
      brd_id: brd_id,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }
  return (
    <>
      <ProfileConnectSocial />
      <SidePopup component={<SocialPopup />} />
    </>
  );
};

export default SocialConnectModule;
