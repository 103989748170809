import React, { useEffect, useState } from "react";
import TemplateList from "./components/TemplateList";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES } from "~/graphql/actions/whatsapp/whatsapp_templates_get_all";
import TemplateRow from "./components/TemplateRow";
import TemplateRowSekeleton from "./components/TemplateRowSekeleton";

export default function WhatsappTemplates() {
  const [templates, setTemplates] = useState<any>([]);

  const { loading, data, error } = useQuery(GET_TEMPLATES, {
    variables: {
      brd_id: "43d895e7-d090-41c2-a261-508642f43d47",
    },
  });

  useEffect(() => {
    setTemplates(data?.whatsapp_templates_get_all?.data);
  }, [data?.whatsapp_templates_get_all?.data]);

  if (loading) return <div>loading..</div>;
  if (error) return <div>ER: ${error.message}</div>;

  return (
    <>
      {loading ? (
        <TemplateRowSekeleton />
      ) : (
        templates?.map((template: any) => {
          return <TemplateRow template={template} />;
        })
      )}
    </>
  );
}
