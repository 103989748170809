import { gql } from "@apollo/client";

export const GET_BRAND_WHATSAPP_LABELS = gql`
  subscription getWhatsappLabels($brd_ids: [uuid!]!) {
    brd_whatsapp_labels(where: { brd_id: { _in: $brd_ids } }) {
      id
      name
    }
  }
`;
