// import InputField from "components/FormInputs/InputField";
import React from "react";
import InputField from "components/FormInputs/InputField";

export default function FlightPrices({ register, errors }: any) {
  return (
    <div className="flex flex-row gap-4">
      {/* Flight Offer Price */}
      <InputField
        label="Offer Price"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Offer Price"
        fieldName="offerPrice"
        required={true}
      />
      {/* Flight Cost Price */}
      <InputField
        label="Cost Price"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Cost Price"
        fieldName="costPrice"
        required={true}
      />
    </div>
  );
}
