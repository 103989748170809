import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $picked_by_id: uuid, $updated_by: uuid) {
    update_inq_list(
      where: { id: { _eq: $id }, picked_by: { _is_null: true } }
      _set: {
        picked_by: $picked_by_id
        status: "assigned"
        updated_by: $updated_by
      }
    ) {
      returning {
        id
      }
    }
  }
`;

// export const DELETE_INQUIRY = gql`
//   mutation DeleteInquiry($id: uuid!) {
//     delete_inq_list_by_pk(id: $id) {
//       id
//     }
//   }
// `;

export const DELETE_INQUIRY = gql`
  mutation UpdateInquiry($id: inq_list_pk_columns_input!) {
    update_inq_list_by_pk(pk_columns: $id, _set: { status: "deleted" }) {
      id
    }
  }
`;
