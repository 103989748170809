import { gql } from "@apollo/client";

export const ADD_MAILJET_SENDER = gql`
  mutation addMailjetSender($brdId: String!, $name: String!, $email: String!) {
    add_mailjet_sender(
      arg1: { brdId: $brdId, email: $email, name: $name }
    ) {
      Count
      Data {
        CreatedAt
        DNSID
        Email
        EmailType
        Filename
        ID
        IsDefaultSender
        Name
        Status
      }
      Total
    }
  }
`;
