import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_FLIGHT_PAGES } from "./graphql/Query";
import SingleFlightPage from "./components/SingleFlightPage";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";

export default function ImportFlightsPages() {
  const { brd_id } = useParams();

  const { loading, data, error } = useQuery(GET_FLIGHT_PAGES);
  const [importPage, setImportPage] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();

  // const allChecked = watch("all_checked");

  const handleCheckAll = (fieldName: any, isChecked: any) => {
    const totalCheckboxes = getValues(fieldName).length;
    for (let i = 0; i < totalCheckboxes; i++) {
      setValue(`${fieldName}.${i}.value`, isChecked);
    }
  };

  const importFlightPageContent = () => {
    setImportPage(!importPage);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
          Import Flight Page
        </h1>
        <button
          className="text-primary dark:text-dark-primary"
          onClick={() => {
            importFlightPageContent();
          }}
        >
          {" "}
          Import Page{" "}
        </button>
      </div>
      <br />
      <div className="p-4 border-gray-200 dark:border-gray-900 border-b-[1px] bg-secondary dark:bg-dark-secondary rounded-t-lg shadow">
        <div className="flex flex-row gap-4 flex-start w-full">
          <div className="border-gray-200 dark:border-gray-900 border-r-[1px] pr-[30px] pt-[5px]">
            <input
              className="bg-primary dark:bg-dark-primary"
              type="checkbox"
              onChange={(e: any) =>
                handleCheckAll("checkbox", e.target.checked)
              }
            />
          </div>
          <div className="font-bold border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[350px]">
            ORIGIN
          </div>
          <div className="font-bold border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[350px]">
            DESTINATION
          </div>
          <div className="font-bold border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[350px]">
            SLUG
          </div>
          <div className="font-bold flex gap-[5px] border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[180px]">
            <input
              id="home"
              type="checkbox"
              className="mt-[3px] bg-primary dark:bg-dark-primary"
              onChange={(e: any) => handleCheckAll("home", e.target.checked)}
            />
            <label htmlFor="home">In Home</label>
          </div>
          <div className="font-bold border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[180px] flex gap-[5px]">
            <input
              id="featured"
              type="checkbox"
              className="mt-[3px] bg-primary dark:bg-dark-primary"
              onChange={(e: any) =>
                handleCheckAll("featured", e.target.checked)
              }
            />
            <label htmlFor="featured">Is Featured</label>
          </div>
          <div className="font-bold text-primary dark:text-dark-primary pr-[30px] pt-[5px] w-[250px]">
            STATUS
          </div>
        </div>
      </div>
      {data?.def_flights_pages.map((page: any, index: any) => {
        return (
          <SingleFlightPage
            page={page}
            key={index}
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
            importPage={importPage}
            getValues={getValues}
            watch={watch}
          />
        );
      })}
    </>
  );
}
