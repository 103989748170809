import React, { useEffect, useState } from "react";
import { useClickOutside } from "@mantine/hooks";
import { TextField } from "@mui/material";

const CityName = ({
  value,
  onChange,
  setValue,
  fieldName,
  defaultValue,
  required,
  countryCode,
  label,
  errors,
}: any) => {
  const [cityList, setCityList] = useState([]);
  const [query, setQuery] = useState("");
  const ref = useClickOutside(() => setQuery(""));

  // Fetch cities based on the query
  const searchCity = async (place: string) => {
    const data = await fetch(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${place}&apiKey=e25595083ac7414da2f5612fbbddbef8${
        countryCode ? `&filter=countrycode:${countryCode.toLowerCase()}` : ""
      }`
    );
    const res = await data.json();
    if (!res.error) {
      setCityList(res?.features);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(`${fieldName}.cityName`, defaultValue?.fullName);
      onChange(defaultValue?.fullName); // Set initial value for input field
    }
  }, [defaultValue, fieldName, setValue, onChange]);

  useEffect(() => {
    if (query !== "") {
      searchCity(query);
    }
  }, [query]);

  const handleCity = (city: any) => {
    const fullName = city?.properties?.formatted;

    // Extract only the city name from the full name
    const cityName = fullName.split(/[,\s]/)[0];

    setValue(`${fieldName}.cityName`, cityName); // Store only the city name
    onChange(cityName); // Update the input field value with city name
    setQuery("");
    setCityList([]);
  };

  const renderSearchOptions = () => (
    <div
      ref={ref}
      className="w-[92%] absolute mt-[55px] bg-primary text-primary dark:text-dark-primary dark:bg-dark-primary rounded z-10 shadow-lg transition-all"
    >
      {cityList.length === 0 ? (
        <p className="text-center text-[14px] py-2">No cities found</p>
      ) : (
        cityList.map((item: any, i: number) => (
          <div
            onClick={() => handleCity(item)}
            key={i}
            className="p-[15px] border-b border-gray-200 dark:border-gray-700 hover:bg-secondary dark:hover:bg-dark-secondary cursor-pointer transition-all"
          >
            <p>{item?.properties?.formatted}</p>
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="w-full grid grid-cols-1 gap-5">
      <TextField
        sx={{ width: "100%" }}
        value={value || ""} // Controlled component value
        onChange={(e) => {
          setQuery(e.target.value); // For filtering city list
          onChange(e.target.value); // Call `onChange` from `Controller`
        }}
        label={label}
        type="text"
        required={required}
      />
      {query !== "" && renderSearchOptions()}
      {errors[fieldName]?.cityName && (
        <p className="text-red-500 text-[12px] mt-1 ml-1">
          <sup>*</sup> required
        </p>
      )}
    </div>
  );
};

export default CityName;
