import { gql } from "@apollo/client";

export const ADD_TRANSACTIONS = gql`
  mutation MyMutation($transactions: [inq_transection_insert_input!]!) {
    insert_inq_transection(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
