import { gql } from "@apollo/client";

export const GET_BRANDS = gql`
  query GetBrandsQuery($thp_id: uuid) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      created_at
      domain
      id
      name
      subdomain
      thp_id
      updated_at
      brand_type
      brd_details {
        logo
        fav_icon
        primary_color
        secondary_color
        theme_id
        updated_at
        id
        created_at
      }
      def_brand_type {
        id
        name
        updated_at
        created_at
      }
    }
  }
`;
