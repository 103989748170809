import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FlightDetail from "./components/FlightDetail";
import { useEffect, useState } from "react";
import WeightDetail from "./components/WeightDetail";
import FlightDates from "./components/FlightDates";
import PassengersCost from "./components/PassengersCost";
import { formatFormData } from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/AddSuggestion/utils/formatFormData";

const AddManually = ({
  submitSugesstion,
  mainHeading,
  passengers,
  type,
  suggestion,
  isLoading,
  isLoadingText,
  defaultValue,
}: any) => {
  // const defaultValue = suggestion ? formatFormData(suggestion) : null;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});

  const depStopsLength = defaultValue?.departLeg?.stops?.length;
  const arrStopsLength = defaultValue?.returnLeg?.stops?.length;

  const [depEntries, setDepEntries] = useState(
    Array.from({ length: depStopsLength }, (_, index) => index + 1)
  );
  const [arrEntries, setArrEntries] = useState(
    Array.from({ length: arrStopsLength }, (_, index) => index + 1)
  );

  const onSubmit = (inpData: any) => {
    submitSugesstion(inpData);
  };

  const checkTripType = watch("tripType");

  const tripTypeOptions = [
    {
      key: 1,
      text: "One Way",
    },
    {
      key: 2,
      text: "Round Trip",
    },
  ];

  const CabinTypeOptions = [
    {
      value: "economy",
      text: "Economy",
    },
    {
      value: "business",
      text: "Business",
    },
  ];

  useEffect(() => {
    defaultValue && setValue("id", defaultValue?.id);
  }, []);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-5">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("tripType", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.key);
                }}
                options={tripTypeOptions ? tripTypeOptions : []}
                getOptionLabel={(option: any) => option?.text}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.text}
                  </Box>
                )}
                defaultValue={() => {
                  const data = tripTypeOptions?.find(
                    (option) => option?.key === defaultValue?.tripType
                  );
                  field.onChange(data?.key);
                  return data;
                }}
                renderInput={(params) => (
                  <TextField
                    label="Trip Type"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["tripType"] ? true : false}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("cabinType", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.value);
                }}
                options={CabinTypeOptions ? CabinTypeOptions : []}
                getOptionLabel={(option: any) => option?.text}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.text}
                  </Box>
                )}
                defaultValue={() => {
                  const data = CabinTypeOptions?.find(
                    (option) => option?.value === defaultValue?.cabinType
                  );
                  field.onChange(data?.value);
                  return data;
                }}
                renderInput={(params) => (
                  <TextField
                    label="Cabin Class"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["cabinType"] ? true : false}
                    {...params}
                  />
                )}
              />
            )}
          />
        </div>
        {mainHeading === "Update Suggestion"
          ? suggestion.inq_suggestion_legs?.map((_: any, ind: any) => {
              return (
                <>
                  <FlightDetail
                    defData={defaultValue?.departLeg}
                    ind={ind}
                    register={register}
                    control={control}
                    errors={errors}
                    name="departLeg"
                    setEntries={setDepEntries}
                    entries={depEntries}
                    mainHeading={mainHeading}
                    setValue={setValue}
                  />
                </>
              );
            })
          : depEntries?.map((_: any, ind: any) => {
              return (
                <>
                  <FlightDetail
                    defData={defaultValue?.departLeg}
                    ind={ind}
                    register={register}
                    control={control}
                    errors={errors}
                    name="departLeg"
                    setEntries={setDepEntries}
                    entries={depEntries}
                    setValue={setValue}
                  />
                </>
              );
            })}
        {type === "createUpdate" && (
          <WeightDetail
            defData={defaultValue?.departLeg}
            register={register}
            errors={errors}
            name="departLeg"
          />
        )}
        {!(mainHeading === "Update Suggestion") && (
          <Button
            sx={{ marginTop: "20px" }}
            onClick={() => {
              const newValue = depEntries.length + 1;
              setDepEntries([...depEntries, newValue]);
            }}
            variant="contained"
          >
            Add Stop
          </Button>
        )}

        {checkTripType === 2 && (
          <>
            {mainHeading === "Update Suggestion"
              ? suggestion.inq_suggestion_legs?.map((_: any, ind: any) => {
                  return (
                    <FlightDetail
                      defData={defaultValue?.returnLeg}
                      ind={ind}
                      register={register}
                      control={control}
                      errors={errors}
                      name="returnLeg"
                      setEntries={setArrEntries}
                      entries={arrEntries}
                      mainHeading={mainHeading}
                      setValue={setValue}
                    />
                  );
                })
              : arrEntries?.map((_: any, ind: any) => {
                  return (
                    <FlightDetail
                      defData={defaultValue?.returnLeg}
                      ind={ind}
                      register={register}
                      control={control}
                      errors={errors}
                      name="returnLeg"
                      setEntries={setArrEntries}
                      entries={arrEntries}
                      setValue={setValue}
                    />
                  );
                })}
            {type === "createUpdate" && (
              <WeightDetail
                defData={defaultValue?.returnLeg}
                register={register}
                errors={errors}
                name="returnLeg"
              />
            )}
            {!(mainHeading === "Update Suggestion") && (
              <Button
                sx={{ marginTop: "20px" }}
                onClick={() => {
                  const newValue = arrEntries.length + 1;
                  setArrEntries([...arrEntries, newValue]);
                }}
                variant="contained"
              >
                Add Stop
              </Button>
            )}
          </>
        )}
        {type === "createUpdate" && (
          <>
            <Divider sx={{ marginTop: "25px" }} />
            <FlightDates
              setValue={setValue}
              defData={defaultValue}
              register={register}
              errors={errors}
              control={control}
            />
          </>
        )}
        {type === "createUpdate" ? (
          passengers?.map((passenger: any, index: any) => {
            return (
              <PassengersCost
                defData={defaultValue?.suggestion_cost}
                register={register}
                errors={errors}
                index={index}
                control={control}
                setValue={setValue}
                passenger={passenger}
                type={type}
              />
            );
          })
        ) : (
          <PassengersCost register={register} errors={errors} />
        )}

        <Button
          type="submit"
          sx={{ marginTop: "25px" }}
          variant="contained"
          fullWidth
        >
          {isLoading ? isLoadingText : mainHeading}
        </Button>
      </Box>
    </>
  );
};

export default AddManually;
