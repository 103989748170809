import React from "react";
import DomainVerification from "./components/DomainVerification";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_BRAND_DETAILS } from "./graphql/Query";
import Loader from "~/components/common/loader/Loader";
import BrandGeneralInfo from "./components/BrandGeneralInfo";
import BrandStylingInfo from "./components/BrandStylingInfo";
import LegalInformation from "./components/LegalInformation";
import TextChanges from "./components/TextChanges";
import SocialMediaLinks from "./components/SocialMediaLinks";
import GoogleTagInfo from "./components/GoogleTagInfo";
import SeoSettings from "./components/SeoSettings/SeoSettings";
import MailjetIntegrate from "./components/MailjetIntegrate";

const UpdateBrand = () => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_BRAND_DETAILS, {
    variables: { brd_id: brd_id },
  });
  if (loading) return <Loader w="200px" />;
  // ;
  const brandData = data?.brd_list?.at(0);
  if (brandData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 ">
        <div className="mb-4 col-span-full xl:mb-2">
          <BrandGeneralInfo brand={brandData} />
          <BrandStylingInfo brand={brandData} />
          <DomainVerification brand={brandData} />
          <MailjetIntegrate brand={brandData} />
          <LegalInformation brand={brandData} />
          <TextChanges brand={brandData} />
          <SocialMediaLinks brand={brandData} />
          <GoogleTagInfo brand={brandData} />
          <SeoSettings brand={brandData} />
        </div>
      </div>
    );
  } else {
    return <p>No brand details found</p>;
  }
};

export default UpdateBrand;
