import { gql } from "@apollo/client";

export const GET_ACTIVITY = gql`
  subscription GetBrandsQuery($user_id: uuid, $brd_id: uuid) {
    brd_notifications(
      where: { activity_user_id: { _eq: $user_id }, brd_id: { _eq: $brd_id } }
      distinct_on: unique_trigger
    ) {
      created_at
      content
      payload
      user_id
      send_by
      template {
        id
        name
      }
      sender {
        displayName
        defaultRole
        thp_users_profile {
          pseudo_name
        }
      }
      activity_user {
        email
        phoneNumber
        displayName
        thp_users_profile {
          pseudo_name
        }
      }
    }
  }
`;
