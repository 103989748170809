import React, { useEffect } from "react";
import InputField from "~/components/FormInputs/InputField";

export default function StayCostRowAdd({
  passenger,
  register,
  errors,
  setValue,
  index,
  watch,
  type,
}: any) {
  // Set the appropriate passenger ID based on the type of service
  console.log("sdasmnd", type, passenger);
  useEffect(() => {
    if (type === "Stay") {
      setValue(`suggestion_cost_add.${index}.stay_passenger_id`, passenger.id);
      // setValue(`suggestion_cost_add.${index}.stay_suggestion_id`, passenger.id)
    }
  }, [passenger.id, index, setValue, type]); // Dependencies to run the effect

  const basicFare = watch(`suggestion_cost_add.${index}.basic_fare`);
  const tax = watch(`suggestion_cost_add.${index}.tax_price`);
  const atol = watch(`suggestion_cost_add.${index}.atol_price`);

  return (
    <div className="flex flex-row gap-4">
      <div className="w-[60%] pt-[30px]">{passenger.booked_by}</div>

      {/* Basic Fare Input */}
      <InputField
        label="Basic Fare"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Basic Fare"
        fieldName={`suggestion_cost_add.${index}.basic_fare`}
        required={true}
      />

      {/* Tax Input */}
      <InputField
        label="Tax"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Tax"
        fieldName={`suggestion_cost_add.${index}.tax_price`}
        required={true}
      />

      {/* Atol Input */}
      <InputField
        label="Atol"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Atol"
        fieldName={`suggestion_cost_add.${index}.atol_price`}
        required={true}
      />

      {/* Sale Price Input */}
      <InputField
        label={`Sale Price (cost - ${
          (basicFare ? parseFloat(basicFare) : 0) +
          (tax ? parseFloat(tax) : 0) +
          (atol ? parseFloat(atol) : 0)
        })`}
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`suggestion_cost_add.${index}.sale_price`}
        required={true}
      />
    </div>
  );
}
