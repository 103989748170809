import { Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_SERVICES_FAQS } from "~/graphql/services_faqs/Mutation"; // Your mutation file path
import { toast } from "react-toastify";

const EditServicesFaqs = ({ setSidePopup, faq }: any) => {
  const [updateFAQ] = useMutation(UPDATE_SERVICES_FAQS);

  const { register, handleSubmit, reset } = useForm();
  const client = useApolloClient();

  // Reset form fields with FAQ data when the component mounts
  useEffect(() => {
    reset({ question: faq.question, description: faq.description });
  }, [faq, reset]);

  const onSubmit = async (formData: any) => {
    try {
      const { data } = await updateFAQ({
        variables: {
          id: faq.id,
          description: formData.description,
          question: formData.question,
        },
      });
      if (data.update_services_faqs.affected_rows > 0) {
        await client.refetchQueries({ include: "all" });
        toast.success("FAQ updated successfully!");
        setSidePopup(false);
      } else {
        toast.error("No changes made.");
      }
    } catch (error) {
      toast.error("Error updating FAQ");
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Edit Services FAQ
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <TextField
              label="Question"
              variant="outlined"
              fullWidth
              {...register("question", { required: true })} // Added validation
              className="dark:text-dark-primary"
            />

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              {...register("description", { required: true })} // Added validation
              className="dark:text-dark-primary"
            />

            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditServicesFaqs;
