import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const trainTypeOptions = [
  { value: "express", label: "Express Train" },
  { value: "intercity", label: "Intercity Train" },
  { value: "local", label: "Local Train" },
  { value: "regional", label: "Regional Train" },
  { value: "high_speed", label: "High-Speed Train" },
  { value: "bullet", label: "Bullet Train" },
  { value: "metro", label: "Metro Train" },
  { value: "light_rail", label: "Light Rail" },
  { value: "monorail", label: "Monorail" },
  { value: "commuter", label: "Commuter Train" },
  { value: "sleeper", label: "Sleeper Train" },
  { value: "freight", label: "Freight Train" },
  { value: "heritage", label: "Heritage Train" },
  { value: "tourist", label: "Tourist Train" },
  { value: "shuttle", label: "Shuttle Train" },
  { value: "luxury", label: "Luxury Train" },
  { value: "suburban", label: "Suburban Train" },
];

export const TrainNameSelector = ({
  name,
  register,
  errors,
  value, // The current value of the select input
  onChange,
  setValue,
  fieldName,
  defaultValue,
  required,
  label,
}: any) => {
  // Set the default value when the component mounts or when defaultValue changes
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue); // Set the initial value for the room type select
      onChange(defaultValue); // Set the initial value for the input field
    }
  }, [defaultValue, fieldName, setValue, onChange]);
  return (
    <>
      <FormControl fullWidth error={Boolean(errors.trainName)}>
        <InputLabel id="trainName-label">Train Name</InputLabel>
        <Select
          labelId="trainName-label"
          id="trainName"
          label="Train Name"
          defaultValue={value || ""}
          {...register(name, {
            required: "This is required",
          })}
          onChange={(event) => {
            onChange(event.target.value); // Update the value on change
          }}
        >
          <MenuItem value="">
            <em>Select a Train Name</em>
          </MenuItem>
          {trainTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {errors.trainName && (
          <FormHelperText>{errors.trainName.message}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};
