import { gql } from "@apollo/client";

// export const GET_USER = gql`
//   query GetUser($email: citext!) {
//     users(where: { email: { _eq: $email } }) {
//       disabled
//     }
//   }
// `;

export const GET_USER = gql`
  query GetUser($email: citext!, $custom_url: String) {
    thp_list(
      where: {
        _or: [
          { domain: { _eq: $custom_url } }
          { subdomain: { _eq: $custom_url } }
        ]
        _and: {
          _or: [
            { brd_users: { users: { email: { _eq: $email } } } }
            { primary_user: { email: { _eq: $email } } }
          ]
        }
      }
    ) {
      primary_user {
        disabled
      }
      brd_users {
        users {
          disabled
        }
      }
    }
  }
`;
