export const CarFormatFormData = (carSuggestions: any) => {
  const generateCost = (car_inq_suggestion_costs: any) => {
    const car_inq_suggestion_costs_return: any = [];
    car_inq_suggestion_costs.map((suggestion_cost: any) => {
      car_inq_suggestion_costs_return.push({
        id: suggestion_cost?.id,
        atol_price: suggestion_cost?.atol_price,
        tax_price: suggestion_cost?.tax_price,
        basic_fare: suggestion_cost?.basic_fare,
        sale_price: suggestion_cost?.sale_price,
        passenger_id: suggestion_cost?.passenger_id,
      });
    });

    return car_inq_suggestion_costs_return;
  };
  const defaultValue: any = {
    id: carSuggestions.id,
    car_type: carSuggestions.car_type,
    origin: carSuggestions.origin,
    destination: carSuggestions.destination,
    start_date: carSuggestions.start_date,
    end_date: carSuggestions.end_date,
    fareExpiry: carSuggestions.fare_expiry,
    invoiceDueDate: carSuggestions.due_date,
    pnr: carSuggestions.pnr,
    bookingFee: carSuggestions.bookingFee,

    suggestion_cost: generateCost(
      carSuggestions.car_inq_suggestion_costs || []
    ), // Keep this as an array
  };

  return defaultValue;
};
