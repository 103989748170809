import { useSignOut } from "@nhost/react";
import React from "react";
import RingSVG1 from "~/assets/svg/RingSVG1";

export default function NoTravelHouse() {
  const { signOut } = useSignOut();
  return (
    <div className="text-center flex h-[100vh] w-full justify-around	">
      <div className="absolute top-0 left-0">
        <RingSVG1 />
      </div>{" "}
      <div className="flex flex-col justify-around">
        <div>
          <h1 className="text-[30px] font-bold">Something Went Wrong {":("}</h1>
          <p>No travel house assign contact your admin!</p>
          <div className="flex gap-4 pt-6 justify-center">
            <button
              onClick={() => window.location.reload()}
              className="block border border-primary rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
            >
              Refresh
            </button>
            <button
              onClick={() => {
                sessionStorage.clear();
                signOut();
              }}
              className="block border border-[#e74c3c] rounded-full px-3 py-1 text-[#e74c3c] cursor-pointer hover:bg-[#e74c3c] hover:text-white"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
