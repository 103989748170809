import React, { useEffect, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import {
  GET_BRANDS_LEADS,
  GET_SERVICE_LEADS_LIVE_COUNT,
} from "~/graphql/services_leads_list/Query";
import LeadsTable from "./components/LeadsTable";
import moment from "moment";
import { Chip, Grow } from "@mui/material";
import brandsStore from "~/store/brandsStore/brandsStore";


const AllLeads = ({ selectedDates, selectedLeadNo, selectedCustomer, selectedService }: any) => {

  const { brands: selectedBrands } = brandsStore();
  const startDateTime = moment(selectedDates?.[0]?.$d)
    .startOf("day")
    .toISOString();
  const endDateTime = moment(selectedDates?.[1]?.$d)
    .endOf("day")
    .toISOString();

  const [newLeadsCount, setNewLeadsCount] = useState(0);

  const condition = {
    _and: [
      ...(selectedLeadNo ? [
        { lead_no: { _eq: selectedLeadNo } }
      ] : []),
      ...(selectedCustomer ? [
        { user: { id: { _eq: selectedCustomer?.[0]?.id } } }
      ] : []),
      ...(selectedService ? [
        { service: { title: { _ilike: `%${selectedService?.title}%` } } }
      ] : []),
      ...(startDateTime && endDateTime ? [
        { created_at: { _gte: startDateTime, _lte: endDateTime } }
      ] : []),
      ...(selectedBrands?.length > 0 ? [
        { brd_id: { _in: selectedBrands.map((brand: any) => brand.id) } }
      ] : []),
    ],
  };

  const { data: leadsData, refetch: refetchLeads } = useQuery(
    GET_BRANDS_LEADS,
    {
      variables: {
        condition: condition,
      },
      skip: !selectedDates?.[1]?.$d,
    }
  );

  const { data: liveCountData } = useSubscription(
    GET_SERVICE_LEADS_LIVE_COUNT,
    {
      variables: { condition },
      skip: !selectedDates?.[1]?.$d,
    }
  );

  useEffect(() => {
    const initialCount = leadsData?.services_leads_list?.length
    const liveCount = liveCountData?.services_leads_list_aggregate?.aggregate?.count || 0;

    setNewLeadsCount(Math.max(0, liveCount - initialCount));
  }, [liveCountData]);

  const leads = leadsData?.services_leads_list || [];

  return (
    <>
      <div
        style={
          newLeadsCount > 0
            ? { height: "25px", marginTop: "15px" }
            : { height: "0px" }
        }
        className="transition-all ease-in-out w-full flex justify-center"
      >
        <Grow in={newLeadsCount > 0}>
          <Chip
            onClick={() => {
              refetchLeads();
              setNewLeadsCount(0);
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#6A0DAD",
              },
            }}
            color="primary"
            label={`${newLeadsCount} New Leads`}
          />
        </Grow>
      </div>

      <LeadsTable leads={leads} />
    </>
  );
}

export default AllLeads