import { gql } from "@apollo/client";

export const GET_USER_INQ_LIST = gql`
  query GetInquiries($user_id: uuid) {
    inq_list(where: { user_id: { _eq: $user_id } }) {
      id
      inquiry_no
    }
  }
`;

export const GET_INQ_LIST = gql`
  query GetInquiries($condition: inq_list_bool_exp!) {
    inq_list(where: $condition) {
      brd_id
      status
      created_at
      from
      to
      source
      source_place
      source_position
      source_add_id
      users {
        email
        displayName
        phoneNumber
        id
      }
      inq_transections {
        status
      }
      thp_inquiry_flag {
        name
        id
      }
      picked_by
      picked_user {
        displayName
        thp_users_profile {
          pseudo_name
        }
        id
      }
    }
  }
`;

export const GET_INQ = gql`
  subscription GetInquiries($id: uuid!) {
    inq_list(where: { id: { _eq: $id } }) {
      brd_list {
        name
        domain
        brd_contact_details {
          email
          phone
          address1
          city
          country
        }
        brd_details {
          fav_icon
          secondary_color
        }
      }
      inq_invoice {
        inq_invoice_items {
          amount
        }
        invoice_suggestion {
          bookingFee
        }
      }
      inq_transections {
        id
        status
        transactions_no
        amount
        acc_payment_method {
          type
        }
      }
    }
  }
`;

export const GET_BRD_INQ_LIST = gql`
  query GetInquiries($brd_id: uuid) {
    inq_list(where: { brd_id: { _eq: $brd_id } }) {
      id
      inquiry_no
    }
  }
`;
