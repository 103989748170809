import { gql } from "@apollo/client";

export const UPDATE_SEO_SETTINGS = gql`
  mutation MyMutation(
    $brd_id: uuid!
    $data: brd_services_seo_settings_set_input
    $page_type: String!
  ) {
    update_brd_services_seo_settings(
      where: { brd_id: { _eq: $brd_id }, page_type: { _eq: $page_type } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;

export const INSERT_SEO_SETTINGS_WITH_ID = gql`
  mutation InsertSEOSettings($data: brd_services_seo_settings_insert_input!) {
    insert_brd_services_seo_settings(objects: [$data]) {
      affected_rows
    }
  }
`;
