import { gql } from "@apollo/client";

export const SEND_WHATSAPP_MESSAGE = gql`
query SendWhatsapp(
  $phoneNumberId: String
  $templateName: String
  $toNumber: String
  $token: String!
  $type: String
  $filename: String
  $textMessage: String
  $url: String
  $parameters: [String]
) {
  whatsappSendMessage(
    arg1: {
      phoneNumberID: $phoneNumberId
      templateName: $templateName
      toNumber: $toNumber
      token: $token
      type: $type
      filename: $filename
      textMessage: $textMessage
      url: $url
      parameters: $parameters
    }
  ) {
    messaging_product
    timestamp
  }
}
`;
