import moment from "moment";

export const formatData = (formData: any, pdfId: any) => {
  let inq_suggestion_legs: any = [];

  const suggestionStopObj = (stop: any, ordering: any) => {
    return {
      airline_iata: stop?.airline?.iata,
      flight_no: stop?.airlineNumber,
      arrival_datetime: moment(stop?.arrivalDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      depart_datetime: moment(stop?.departDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      depart: stop?.departCity?.iata_code,
      arrival: stop?.arrivalCity?.iata_code,
      ordering: ordering + 1,
      created_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
  };

  const suggestionObj = (legType: any, legDetail: any) => {
    let stops: any = [];
    legDetail.stops.forEach((stop: any, index: any) => {
      const stopObj = suggestionStopObj(stop, index);
      stops.push(stopObj);
    });

    let returnObj: any = {
      type: legType || null,
      luggage: legDetail?.luggage,
      cabin: formData?.cabinType,
      hand_carry: legDetail?.handCary,
      inq_suggestion_leg_stops: {
        data: stops,
      },
    };

    return returnObj;
  };

  Array.from({ length: formData.tripType }, (item: any, index: any) =>
    index === 0 ? "departing" : "returning"
  ).forEach((legType: any, index: any) => {
    const sugestion = suggestionObj(
      legType,
      index === 0 ? formData?.departLeg : formData?.returnLeg
    );

    inq_suggestion_legs.push(sugestion);
  });

  return [
    {
      inq_id: formData?.inq_id,
      due_date: moment(formData?.invoiceDueDate).format("YYYY-MM-DD"),
      fare_expiry: moment(formData?.fareExpiry).format("YYYY-MM-DD"),
      pnr: formData?.pnr,
      bookingFee: parseFloat(formData?.bookingFee) || 0,
      suggestion_pdf: pdfId,
      inq_suggestion_legs: {
        data: inq_suggestion_legs,
      },
      inq_suggestion_costs: {
        data: [...formData?.suggestion_cost],
      },
    },
  ];
};

export const getDate = (dateInp: any) => {
  // const newDate = new Date();

  const dateString = dateInp;
  const dateParts = dateString.split("/");
  const timeParts = dateParts[2].split(" ");

  // Extract the date components
  const day = parseFloat(dateParts[0]);
  const month = parseFloat(dateParts[1]) - 1; // Months are zero-based (0-11)
  const year = parseFloat(timeParts[0]);

  // Extract the time components
  const time = timeParts[1].split(":");
  const hour = parseFloat(time[0]);
  const minute = parseFloat(time[1]);

  // Create the Date object
  const dateObj = new Date(year, month, day, hour, minute);
  return dateObj;
};
