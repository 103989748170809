import { ADD_TWITTER_USER_DETAIL } from "~/graphql/add_twitter_user_detail/Mutation";

export const getTwitterProfileData = async (accessToken: any, client: any) => {
  try {
    const res = await client.mutate({
      mutation: ADD_TWITTER_USER_DETAIL,
      variables: { access_token: accessToken },
    });

    const profile_picture =
      res?.data?.add_twitter_user_detail?.data?.profile_image_url;
    const display_name = res?.data?.add_twitter_user_detail?.data?.name;

    return {
      profile_picture,
      display_name,
    };
  } catch (error) {
    console.error("Error fetching Twitter profile data:", error);
    throw error;
  }
};
