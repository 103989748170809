import React, { useRef, useState } from "react";
import { UPDATE_CONTENT } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import ContentEditor from "~/components/common/ContentEditor";
import { Button, TextField } from "@mui/material";

const RichContentEditor = ({ page }: any) => {
  const editorRef: any = useRef(null);
  const [updateHeadings] = useMutation(UPDATE_CONTENT);
  const client = useApolloClient();
  const [title, setTitle] = useState(page?.content_heading);
  const [content, setContent] = useState(page?.content_description);
  const saveData = async () => {
    const res = await updateHeadings({
      variables: {
        id: { id: page.id },
        data: {
          content_heading: title,
          content_description: content,
        },
      },
    });
    if (res?.data?.update_brd_pages_by_pk?.id) {
      toast.success("Content updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  return (
    <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary border-none rounded-lg shadow 2xl:col-span-2  sm:p-6 ">
      <h3 className="mb-4 text-xl font-semibold ">Edit Content</h3>
      <div className="mb-3">
        <TextField label="Main Title" type="text" fullWidth />
      </div>
      <ContentEditor content={content} setContent={setContent} />
      <Button sx={{ marginTop: "20px" }} onClick={saveData} variant="contained">
        Save Data
      </Button>
    </div>
  );
};

export default RichContentEditor;
