import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { toast } from "react-toastify";
import CountryBox from "~/components/FormInputs/CountryBox";
import { EDIT_EMAIL_TEMPLATE } from "./graphql/Mutation";
import ContentEditor from "~/components/common/ContentEditor";
import { FaCircle, FaRecycle, FaRegStopCircle } from "react-icons/fa";
import { GET_TEMPLATE } from "./graphql/Query";
import TemplateInputs from "./components/TemplateInputs";
import { useClickOutside } from "@mantine/hooks";
import { GET_BRAND_TEMPLATE } from "../../graphql/Query";

const SingleTemplate = ({ template, key, setDropdown, dropdown }: any) => {
  const [content, setContent] = useState(template.notification_content);
  // const dropDonwRef = useClickOutside(() => setDropdown(false));
  const [editEmailTemplate] = useMutation(EDIT_EMAIL_TEMPLATE);

  const [isLoading, setIsLoading] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const client = useApolloClient();
  const [active, setActive] = useState<any>(template.notification_active);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      notification_content: template.notification_content,
    },
  });

  const onActive = async () => {
    // setActive(!active);
    // setIsLoading(true);
    const res = await editEmailTemplate({
      variables: {
        id: template.id,
        data: {
          notification_active: !active,
        },
      },
    });
    if (res?.data?.update_brd_emails_templates?.affected_rows > 0) {
      toast.success("Update successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 100,
      });
      setActive(!active);
    } else {
      setActive(active);
    }
    await client.refetchQueries({
      include: "all",
    });
    // setIsLoading(false);
  };

  const onSubmit = async (inpData: any) => {
    setIsLoading(true);

    const res = await editEmailTemplate({
      variables: {
        id: template.id,
        data: {
          notification_content: inpData.notification_content,
        },
      },
    });
    if (res?.data?.update_brd_emails_templates?.affected_rows > 0) {
      toast.success("Update successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 100,
      });
    }
    await client.refetchQueries({
      include: "all",
    });
    setIsLoading(false);
  };

  const RestoreTemplates = async () => {
    setIsRestore(true);
    const templateRes: any = await client.query({
      query: GET_TEMPLATE,
      variables: {
        id: template.template_id,
      },
    });

    const notification_content =
      templateRes?.data?.def_emails_templates?.[0]?.notification_content;

    setContent(notification_content);

    const res = await editEmailTemplate({
      variables: {
        id: template.id,
        data: {
          notification_content: notification_content,
        },
      },
    });

    if (res?.data?.update_brd_emails_templates?.affected_rows > 0) {
      toast.success("Restored successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 100,
      });
    }
    await client.refetchQueries({
      include: "all",
    });

    setIsRestore(false);
  };

  return (
    <div
      id={key}
      className={` mt-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  `}
    >
      <div className={` flex flex-col md:flex-row w-full justify-between`}>
        <h3
          onClick={() => {
            !dropdown && setDropdown(template.id);
          }}
          className={`${
            dropdown === template.id || dropdown === null
              ? "cursor-pointer"
              : "cursor-no-drop"
          } text-xl p-4 font-semibold  w-[85%] flex gap-2 `}
        >
          {template.def_emails_template.name}
          <span className="text-[12px] mt-[7px]">
            - ID#{template.template_id}
          </span>
        </h3>
        <div>
          <h3 className={`text-xl p-4 font-semibold  w-[85%] flex gap-2 `}>
            <span className="text-[12px] mt-[7px]">
              {template.def_emails_template.is_activity && "Activity"}
            </span>
          </h3>
        </div>
        {/* <div className="p-4">
          <div className="flex gap-2">
            <div className="mb-4">
              <input
                className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault01"
                defaultChecked={active}
                onClick={() => {
                  onActive();
                }}
              />
            </div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              {active ? "Active" : "In Active"}
            </span>
          </div>
        </div> */}
      </div>

      {dropdown === template.id && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={key}
          className="pt-4 border-t-2 border-gray-200 dark:border-gray-800 p-4 "
        >
          <div className="w-full">
            <TemplateInputs
              register={register}
              errors={errors}
              template={template}
              content={content}
              setContent={setContent}
              setValue={setValue}
              RestoreTemplates={RestoreTemplates}
              isRestore={isRestore}
              setDropdown={setDropdown}
              isLoading={isLoading}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default SingleTemplate;
