import { GET_FACEBOOK_PROFILE_PAGES } from "~/graphql/get_facebook_profile_pages/Query";

export const getFacebookProfilePages = async (accessToken: any, client: any) => {
    const res = await client.query({
        query: GET_FACEBOOK_PROFILE_PAGES,
        variables: { access_token: accessToken },
    });

    return {
        pagesData: res?.data?.get_fb_profile_pages?.facebookOrgData,
      };    
};