import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_DEFAULT_ACCOUNTS } from "~/modules/AccountModule/segments/AccountsList/graphql/Query";
import { ACC_LIST } from "~/modules/AccountModule/segments/TransactionsList/components/VendorDeposit/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

export default function useAccountList() {
  const { travelHouse }: any = travelHouseStore();

  const [accList, setAccList] = useState<any>([]);

  const { data: defData } = useQuery(GET_DEFAULT_ACCOUNTS);

  const { data: cusData } = useQuery(ACC_LIST, {
    variables: { thp_id: travelHouse.id },
  });

  useEffect(() => {
    if (defData?.def_acc_list && cusData?.acc_list) {
      setAccList([...defData?.def_acc_list, ...cusData?.acc_list]);
    }
  }, [defData, cusData]);

  return { accountList: accList };
}
