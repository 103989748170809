import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import BrandList from "~/modules/BrandModule/segments/BrandList";
export default function Brands() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <BrandList />
        </BrandModule>
      </Layout>
    </div>
  );
}
