import { gql } from "@apollo/client";

export const INSERT_SERVICE_TESTIMONIALS = gql`
  mutation MyMutation($data: [brd_services_testimonials_insert_input]!) {
    insert_brd_services_testimonials(objects: $data) {
      affected_rows
      returning {
        name
        rating
        feedback
        brd_id
        id
      }
    }
  }
`;
export const UPDATE_BRD_SERVICES_TESTIMONIALS = gql`
  mutation UpdateBrdServicesTestimonials(
    $id: uuid!
    $name: String!
    $rating: Int!
    $feedback: String!
  ) {
    update_brd_services_testimonials(
      where: { id: { _eq: $id } }
      _set: { name: $name, rating: $rating, feedback: $feedback }
    ) {
      affected_rows
      returning {
        name
        rating
        feedback
      }
    }
  }
`;
export const DELETE_BRD_SERVICES_TESTIMONIAL = gql`
  mutation DeleteTestimonial($id: uuid!) {
    delete_brd_services_testimonials(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
