import React, { useState } from "react";
import { GET_BRNAD_FAQs } from "./graphql/Query";
import { Link, useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
import FAQSettings from "./components/FAQSettings";
import FAQRow from "./components/FAQRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle, FaNotEqual } from "react-icons/fa";
import SeoSettings from "./components/SEOSettings";
import SideDrawer from "~/components/common/sideDrawer";
import BrandSettings from "~/components/common/BrandSettings";
import { useForm } from "react-hook-form";
import { GET_DEFAULT_SETTINGS } from "./components/FAQSettings/graphql/Query";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./components/FAQSettings/graphql/Mutation";
import { toast } from "react-toastify";
import BrandAddUpdateSec from "~/components/common/BrandAddUpdateSec";
import { faqDestinations } from "./components/EditFAQs/data/SelectOptions";
import { INSERT_FAQ } from "../FAQs/graphql/Mutation";

const ManageFAQs = () => {
  const { brd_id } = useParams();
  const [addFAQ, setAddFAQ] = useState(false);
  const [setting, setSetting] = useState(false);

  const { loading: brandFaqLoading, data: brandFaq } = useQuery(
    GET_BRNAD_FAQs,
    {
      variables: { id: brd_id },
    }
  );
  const faqs = brandFaq?.brd_faq;

  const { loading: defSettingLoading, data: defaultSetting } = useQuery(
    GET_DEFAULT_SETTINGS,
    {
      variables: { id: brd_id },
    }
  );
  const defSettings = defaultSetting?.brd_default_setting?.at(0);

  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertFaq] = useMutation(INSERT_FAQ);
  const client = useApolloClient();

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const updateSettings = async (inpData: any) => {
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            faq_heading: inpData?.heading,
            faq_sub_heading: inpData?.subHeading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
        setSetting(!setting);
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            faq_heading: inpData?.heading,
            faq_sub_heading: inpData?.subHeading,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Social Media  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
        setSetting(!setting);
      }
    }
  };

  const addFaq = async (inpData: any) => {
    const res = await insertFaq({
      variables: {
        faqData: {
          destination_iata: inpData?.destination?.iata_code
            ? inpData?.destination?.iata_code
            : null,
          category: inpData?.category,
          question: inpData?.question,
          answer: inpData?.description,
          airline_iata: inpData?.airline?.iata || null,
          ordering: 1,
          brd_id: brd_id,
        },
      },
    });

    if (res?.data?.insert_brd_faq_one?.id) {
      toast.success("FAQ added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
      setAddFAQ(false);
    }
  };

  if (brandFaqLoading) return <p>Loading...</p>;
  // ;
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between lg:mt-1.5 ">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-primary dark:text-dark-primary sm:text-2xl ">
              All Faqs
            </h1>
          </div>
          <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-200 dark:divide-gray-700">
            <div className="flex items-center mb-4 sm:mb-0">
              <form className="sm:pr-3" action="#" method="GET">
                <label htmlFor="faqs-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                  <input
                    type="text"
                    name="email"
                    id="faqs-search"
                    className="bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary sm:text-sm rounded-lg block w-full p-2.5 shadow"
                    placeholder="Search for faqs"
                  />
                </div>
              </form>
              <div className="flex items-center w-full sm:justify-end">
                <div className="flex pl-2 space-x-1">
                  <button
                    onClick={() => setSetting(true)}
                    id="faqSettingButton"
                    className="inline-flex justify-center p-1 text-primary rounded cursor-pointer hover:text-primary hover:bg-primary dark:text-dark-primary dark:hover:bg-dark-primary dark:hover:text-dark-primary"
                    type="button"
                    data-drawer-target="drawer-faq-setting-default"
                    data-drawer-show="drawer-faq-setting-default"
                    aria-controls="drawer-faq-setting-default"
                    data-drawer-placement="right"
                  >
                    <AiFillSetting fontSize={25} />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-[10px]">
              {/*  */}
              {/* <a
                href={`/admin/brands/${brd_id}/page/faqs`}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                
              </a> */}
              <Link
                to={`/admin/brands/${brd_id}/page/faqs`}
                className="text-white bg-basic hover:bg-purple-600 font-medium outline-none border-none rounded-lg text-sm px-5 py-2.5"
              >
                Page Settings
              </Link>
              <button
                onClick={() => setAddFAQ(true)}
                className="text-white bg-basic hover:bg-purple-600 font-medium rounded-lg text-sm px-5 py-2.5"
                type="button"
              >
                Add New FAQ
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {faqs?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600 shadow rounded-lg">
                  <thead className="bg-secondary dark:bg-dark-secondary rounded-t-lg shadow">
                    <tr>
                      <th scope="col" className="p-4 ">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all"
                            aria-describedby="checkbox-1"
                            type="checkbox"
                            className="w-4 h-4 rounded bg-primary dark:bg-dark-primary border-gray-300 dark:border-gray-600"
                          />
                          <label htmlFor="checkbox-all" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Destination Name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Question
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-primary dark:text-dark-primary uppercase"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-secondary dark:bg-dark-secondary divide-y divide-gray-200  dark:divide-gray-700 rounded-b-lg shadow">
                    {faqs?.length > 0 &&
                      faqs?.map((faq: any, i: number) => (
                        <FAQRow key={i} faq={faq} />
                      ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound
                  icon={
                    <FaExclamationCircle className="text-[30px] text-red-500" />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <SideDrawer
        setSidePopup={setAddFAQ}
        sidePopup={addFAQ}
        component={
          <BrandAddUpdateSec
            mainHeading="Add New FAQ"
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            addData={addFaq}
            field1={{
              label: "Question",
              name: "question",
            }}
            field2={{
              label: "Category",
              name: "category",
              options: faqDestinations,
            }}
            field3={{
              label: "Destination",
              name: "destination",
            }}
            field4={{
              label: "Airline",
              name: "airline",
            }}
            field5={{
              label: "Description",
              name: "description",
            }}
          />
        }
      />
      <SideDrawer
        setSidePopup={setSetting}
        sidePopup={setting}
        component={
          <BrandSettings
            mainHeading="FAQ Settings"
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            updateSettings={updateSettings}
            defHeading={defSettings?.faq_heading}
            defSubHeading={defSettings?.faq_sub_heading}
          />
        }
      />
    </>
  );
};

export default ManageFAQs;
