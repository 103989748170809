
const IconRenderer = ({ iconComponent: IconComponent }:any) => {
    if (!IconComponent) {
      return null; // Handle cases where the icon component is not provided
    }
  
    return <IconComponent />;
  };
  
  export default IconRenderer;
  