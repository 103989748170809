import Layout from "~/components/common/Layout";

import EmailContactList from "~/modules/BrandModule/modules/MarketingModule/segments/EmailCampaigns/components/EmailContactList";

export default function EmailContactListPage() {
  return (
    <div>
      <Layout>
        <EmailContactList />
      </Layout>
    </div>
  );
}
