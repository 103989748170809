import { Avatar } from "@mui/material";

const ChatRow = ({
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  setShowContactBar,
  setContactNumbers,
  contactNumbers,
  text,
}: any) => {
  return (
    <>
      <div
        className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer"
        onClick={() => {
          const isSmallScreen = window.innerWidth < 768;
          if (isSmallScreen) {
            setShowWhatsappSidebar && setShowWhatsappSidebar(false);
          }
          if (setShowContactBar) {
            setShowContactBar && setShowContactBar(false);
          }
          setShowWhatsappChatBox && setShowWhatsappChatBox(true);

          if (setContactNumbers && !contactNumbers.includes(text)) {
            setContactNumbers((prevNumbers: any) => [...prevNumbers, text]);
          }
        }}
      >
        <div className="pl-[15px] py-[13px]">
          <Avatar
            alt="whatsappAvator"
            src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
          />
        </div>
        <div className="py-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
          <div className="flex items-center justify-between">
            <h1 className="text-[16px] text-black dark:text-white">M Usman</h1>
            <p className="text-[12px] dark:text-dark-primary">1/22/2024</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-[14px] dark:text-dark-primary">Last Message</p>
            <p className="bg-[#25d366] h-[23px] w-[23px] rounded-full text-[12px] text-whatsappSecondaryBg flex items-center justify-center font-bold">
              3
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRow;
