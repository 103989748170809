import CreditCardIcon from "@mui/icons-material/CreditCard";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { toast } from "react-toastify";
import { stripe } from "~/lib/stripe";

const PaymentMethod = ({ data, defPaymentMeth, getCustomerPayDetail }: any) => {
  const [loading, setLoading] = useState(false);

  const updateDefPayMeth = async () => {
    setLoading(true);
    const defPayMeth = await stripe.customers.update(data?.customer, {
      invoice_settings: {
        default_payment_method: data?.id,
      },
    });

    if (defPayMeth?.invoice_settings?.default_payment_method === data?.id) {
      await getCustomerPayDetail(data?.customer);
      setLoading(false);
      toast.success("Default payment method updated successfully");
    }
  };

  const removePayMeth = async () => {
    try {
      const paymentMethod = await stripe.paymentMethods.detach(data?.id);

      if (paymentMethod?.id === data?.id) {
        await getCustomerPayDetail(data?.customer);
        toast.success("Payment method Deleted successfully");
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <div className="bg-white flex items-center justify-between rounded shadow px-3 py-2">
      <div className="flex items-center gap-5">
        <CreditCardIcon sx={{ fontSize: "50px" }} color="primary" />
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <p className="font-bold text-xl">{data?.card?.display_brand}</p>
            <p className="font-semibold">****{data?.card?.last4}</p>
          </div>
          <p className="text-sm font-semibold mt-[-2px]">
            Expiry {data?.card?.exp_month} / {data?.card?.exp_year}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-7">
        {defPaymentMeth === data?.id ? (
          <p className="bg-green-300 font-semibold px-3 py-[1px] rounded-full">
            Default Payment Method
          </p>
        ) : (
          <p
            onClick={updateDefPayMeth}
            className="bg-basic text-white font-semibold px-3 py-[1px] rounded-md cursor-pointer"
          >
            {loading ? "processing..." : "Set as Default"}
          </p>
        )}

        <DeleteIcon
          onClick={removePayMeth}
          sx={{ fontSize: "30px", cursor: "pointer", color: "red" }}
        />
      </div>
    </div>
  );
};

export default PaymentMethod;
