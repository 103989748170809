import { Autocomplete, Button, TextField, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { nhost } from "~/lib/nhost";
import { toast } from "react-toastify";
import { useApolloClient, useMutation, useQuery, gql } from "@apollo/client";
import { UPDATE_SERVICE_CASESTUDY } from "~/graphql/services_casestudy/Mutation";
import { useParams } from "react-router-dom";
import ContentEditor from "~/components/common/ContentEditor";
import { GET_CASE_STUDY } from "~/graphql/services_casestudy/Query";
import { GET_SERVICES_WITH_BRD_ID } from "~/graphql/brd_services/Query";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

interface FormData {
  title: string;
  title_color: string;
  slug: string;
  description: string;
  img_id: any;
  bg_color: string;
  service_id: any;
}

const EditCaseStudy = ({ setting, setSidePopup }: any) => {
  const client = useApolloClient();
  const { register, handleSubmit, setValue, reset } = useForm<FormData>();
  const { case_study_id } = useParams<{ case_study_id: string }>();
  const [selectedService, setSelectedService] = useState<any>("");
  const [Loading, setLoading] = useState(false);
  const [updateServiceCaseStudy] = useMutation(UPDATE_SERVICE_CASESTUDY);
  const [NewImageId, setNewImageId] = useState<string | null>(null);
  const [content, setContent] = useState<any>("");
  const { brd_id } = useParams();
  const [title, setTitle] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  const { data: caseStudyData } = useQuery(GET_CASE_STUDY, {
    variables: { caseStudyId: case_study_id },
  });

  useEffect(() => {
    if (caseStudyData) {
      reset({
        // title: caseStudyData?.services_casestudy[0].title,
        title_color: caseStudyData?.services_casestudy[0].title_color,
        slug: caseStudyData?.services_casestudy[0].slug,
        // description: caseStudyData?.services_casestudy[0].description,
        bg_color: caseStudyData?.services_casestudy[0].bg_color,
      });
      setContent(
        caseStudyData?.services_casestudy[0].detailed_description || ""
      );
      setTitle(caseStudyData?.services_casestudy[0].title || "");
      setDescription(caseStudyData?.services_casestudy[0].description || "");
    }
  }, [caseStudyData, reset]);

  const { data: servicesData } = useQuery(GET_SERVICES_WITH_BRD_ID, {
    variables: { brd_id: brd_id },
  });

  console.log("dnjds", servicesData);

  const serviceFunc = () => {
    const data = servicesData?.brd_services.find(
      (item: any) =>
        item.id === caseStudyData?.services_casestudy?.[0]?.brd_services_id
    );

    return data;
  };

  const defService = serviceFunc();

  const defServiceInd: any = defService
    ? servicesData?.brd_services.indexOf(defService)
    : -1;

  useEffect(() => {
    setSelectedService(
      defServiceInd !== -1 ? servicesData?.brd_services?.[defServiceInd] : null
    );
    setValue("service_id", defService?.id);
  }, [defServiceInd]);

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };

  const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const slugValue = inputValue.replace(/\s+/g, "-");
    setValue("slug", slugValue);
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const webpFile = (await convertImageToWebP(file)) as File;
      const response = await nhost.storage.upload({ file: webpFile });
      if (response.error) {
        console.error("Upload error:", response.error.message);
        toast.error("Failed to upload image. Please try again.");
      } else {
        setNewImageId(response.fileMetadata.id);
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
      toast.error("Failed to upload image. Please try again.");
    }
  };

  const onSubmit = async (formData: FormData) => {
    // console.log("ansd", formData)
    setLoading(true);
    try {
      const { title_color, slug, bg_color, service_id } = formData;
      const image_id =
        NewImageId || caseStudyData?.services_casestudy[0].image_id;

      const result = await updateServiceCaseStudy({
        variables: {
          caseStudyId: caseStudyData?.services_casestudy[0].id,
          data: {
            title: title,
            title_color,
            slug,
            bg_color,
            description: description,
            detailed_description: content,
            image_id,
            brd_services_id: service_id,
          },
        },
      });

      if (result?.data?.update_services_casestudy?.affected_rows) {
        await client.refetchQueries({ include: "all" });
        toast.success("Case study updated successfully!");
        reset();
      } else {
        toast.error("Failed to update case study.");
      }
    } catch (error) {
      console.error("Update error:", error);
      toast.error("Failed to update case study. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const imgUrl = NewImageId
    ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${NewImageId}`
    : `${process.env.REACT_APP_NHOST_STORAGE_URL}${caseStudyData?.services_casestudy[0].image_id}`;

  return (
    <div className="w-full rounded-lg p-5 bg-secondary dark:bg-dark-secondary">
      <h1 className="text-xl text-primary dark:text-dark-primary mb-6">
        Edit Case Study
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <Autocomplete
            options={servicesData?.brd_services || []}
            getOptionLabel={(option: any) => option?.title}
            fullWidth
            value={selectedService}
            onChange={(event, newValue) => {
              setSelectedService(newValue || "");
              setValue("service_id", newValue?.id || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Service"
                InputLabelProps={{
                  className: "dark:text-dark-primary",
                }}
                variant="outlined"
                fullWidth
              />
            )}
            className="dark:text-dark-primary"
          />
          <TextField
            {...register("title", { required: true })}
            label="Title"
            type="text"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AutoFixHighIcon
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={async () => {
                      const res = await getAiData(
                        `Write an alternative title for ${title} without qoutes for case study that I want to show on my website.`
                      );
                      await typingEffect(setTitle, res);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
          />
          <TextField
            label="Slug"
            variant="outlined"
            fullWidth
            required
            {...register("slug")}
            onChange={handleSlugChange}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <TextField
            label="Heading Color"
            variant="outlined"
            fullWidth
            {...register("title_color")}
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="Background Color"
            variant="outlined"
            fullWidth
            helperText="Add opacity adjusted color"
            {...register("bg_color")}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <TextField
          {...register("description", { required: true })}
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={3}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AutoFixHighIcon
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={async () => {
                    const res = await getAiData(
                      `Write an alternative description like previous description as "${description}" without qoutes and to the point that I want to show on my website.`
                    );
                    await typingEffect(setDescription, res);
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <div className="col-span-6 sm:col-full ck-editor relative">
          <label htmlFor="" className="text-primary dark:text-dark-primary">
            Detail Content
          </label>
          <ContentEditor content={content} setContent={setContent} />
          <AutoFixHighIcon
            color="primary"
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "100px",
              right: "20px",
            }}
            onClick={async () => {
              setLoading(true);
              setContent("Generating...");
              const res = await getAiData(
                `Write best alternative detail content like previous content which is "${content}": Proper formatted: with proper spacing and headings in inner HTML.`
              );
              setContent(res);
              setLoading(false);
              setContent(res);
              // await typingEffect(setContent, res);
            }}
          />
        </div>
        <Button
          variant="outlined"
          component="label"
          sx={{ marginTop: "16px" }}
          style={{ marginBottom: "16px" }}
        >
          Replace Image
          <input
            type="file"
            onChange={handleImageChange}
            hidden
            accept="image/*"
          />
        </Button>
        {imgUrl && (
          <img
            src={imgUrl}
            alt="CaseStudy Image"
            className="max-h-[200px] rounded-lg shadow-md mb-4"
          />
        )}
        <div className="flex justify-end">
          <Button type="submit" variant="contained" color="primary">
            {Loading ? "Updating..." : "Update"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditCaseStudy;
