import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type State = {
  pushPermissions: string;
  sidePopup: boolean;
  lastActive: any;
};

type Actions = {
  setPushPermissions: (data: any) => void;
  setSidePopup: (data: any) => void;
  setLastActive: (data: any) => void;
};

const commonStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      pushPermissions: "",
      setPushPermissions: (data: any) =>
        set((state: any) => ({ pushPermissions: data })),
      sidePopup: false,
      setSidePopup: (data: any) => set((state: any) => ({ sidePopup: data })),

      lastActive: null,
      setLastActive: (data: any) => set((state: any) => ({ lastActive: data })),
    }),
    {
      name: "common-storage",
      storage: createJSONStorage(() => sessionStorage),
      onRehydrateStorage: () => (state: any) => {
        if (typeof state !== "undefined") {
          state.setHasHydrated(true);
        }
      },
    }
  )
);

export default commonStore;
