import { TextField } from "@mui/material";

const PassengersCost = ({
  register,
  errors,
  type,
  passenger,
  index,
  setValue,
  defData,
}: any) => {
  defData &&
    setValue &&
    setValue(`suggestion_cost.${index}.passenger_id`, passenger?.id);
  defData &&
    setValue &&
    setValue(`suggestion_cost.${index}.id`, defData[0]?.id);
  return (
    <>
      {type === "createUpdate" ? (
        <>
          <h2 className="text-center text-xl text-basic mt-5">
            PASSENGER COST
          </h2>
          <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 bg-secondary dark:bg-dark-secondary p-6 shadow rounded-lg relative mt-5">
            <p className="text-basic flex items-center justify-center text-center">
              {passenger?.first_name + " " + passenger?.last_name} (
              {passenger?.type.toUpperCase()})
            </p>
            <TextField
              sx={{ marginTop: "10px" }}
              label="Cost Price"
              type="number"
              {...register(`suggestion_cost.${index}.basic_fare`, {
                required: true,
              })}
              error={
                errors[`suggestion_cost.${index}.basic_fare`] ? true : false
              }
              defaultValue={defData && defData[0]?.basic_fare}
            />
            <TextField
              sx={{ marginTop: "10px" }}
              label="Tax Price"
              type="number"
              {...register(`suggestion_cost.${index}.tax_price`, {
                required: true,
              })}
              error={
                errors[`suggestion_cost.${index}.tax_price`] ? true : false
              }
              defaultValue={defData && defData[0]?.tax_price}
            />
            <TextField
              sx={{ marginTop: "10px" }}
              label="Sale Price"
              type="number"
              {...register(`suggestion_cost.${index}.sale_price`, {
                required: true,
              })}
              error={
                errors[`suggestion_cost.${index}.sale_price`] ? true : false
              }
              defaultValue={defData && defData[0]?.sale_price}
            />
          </div>
        </>
      ) : (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-3">
          <TextField
            sx={{ marginTop: "10px" }}
            label="Offer Price"
            type="number"
            {...register("offerPrice", { required: true })}
            error={errors["offerPrice"] ? true : false}
          />
          <TextField
            sx={{ marginTop: "10px" }}
            label="Cost Price"
            type="number"
            {...register("costPrice", { required: true })}
            error={errors["costPrice"] ? true : false}
          />
        </div>
      )}
    </>
  );
};

export default PassengersCost;
