import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";
import { GET_BRD_SERVICES_TIPS_WITH_SERVICE_ID } from "~/graphql/brd_services_tips/Query";
import {
  DELETE_SERVICE_TIP,
  UPDATE_SERVICE_TIP,
} from "~/graphql/brd_services_tips/Mutation";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditServicesTips from "../EditServicesTips";

const ShowServicesTips = () => {
  const { service_id } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const client = useApolloClient();
  const { data, loading, error } = useQuery(
    GET_BRD_SERVICES_TIPS_WITH_SERVICE_ID,
    {
      variables: { brd_service_id: service_id },
      fetchPolicy: "network-only",
    }
  );

  const [updateServiceTip] = useMutation(UPDATE_SERVICE_TIP);
  const [deleteTip] = useMutation(DELETE_SERVICE_TIP);
  const [selectedTip, setSelectedTip] = useState<any>(null);

  const handleEdit = (tip: any) => {
    setSelectedTip(tip);
    setIsPopupOpen(true);
  };

  const handleSaveEdit = async () => {
    if (selectedTip) {
      try {
        const response = await updateServiceTip({
          variables: {
            id: selectedTip.id,
            title: selectedTip.title,
            description: selectedTip.description,
          },
        });
        if (response?.data?.update_brd_services_tips?.affected_rows > 0) {
          await client.refetchQueries({
            include: "all",
          });
          toast.success("Service tip updated successfully!");
        }
      } catch (error) {
        toast.error("Error updating service tip.");
      }
      setSelectedTip(null);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      const res = await deleteTip({
        variables: {
          id: id,
        },
      });
      if (res?.data?.delete_brd_services_tips?.affected_rows > 0) {
        await client.refetchQueries({
          include: "all",
        });
        toast.success("Tip deleted successfully!");
      }
    } catch (error) {
      toast.error("Error deleting tip.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading tips</div>;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.brd_services_tips.map((tip: any) => (
              <TableRow key={tip.id}>
                <TableCell className=" min-w-[200px]">{tip.title}</TableCell>
                <TableCell className=" min-w-[400px]">
                  {tip.description.length > 60
                    ? `${tip.description.slice(0, 60)}...`
                    : tip.description}
                </TableCell>
                <TableCell className=" min-w-[200px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(tip)}
                    style={{ marginRight: 8 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(tip.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPopupOpen && (
        <SideDrawer
          component={
            <EditServicesTips tip={selectedTip} setSidePopup={setIsPopupOpen} />
          }
          sidePopup={isPopupOpen}
          setSidePopup={setIsPopupOpen}
        />
      )}
    </div>
  );
};

export default ShowServicesTips;
