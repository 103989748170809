import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Campuses from "~/modules/BrandModule/segments/Universities/Campuses";

export default function CampusesPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <Campuses />
        </BrandModule>
      </Layout>
    </div>
  );
}