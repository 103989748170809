import { Autocomplete, TextField, Checkbox, Chip } from "@mui/material";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useEffect } from "react";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Brandfilter = ({}: any) => {
  const { brands: selectedBrands, setBrands }: any = brandsStore();
  const { brands } = useAssigedBrands();

  function getUniqueById(arr: any) {
    const uniqueMap = new Map();
    arr.forEach((obj: any) => {
      uniqueMap.set(obj.id, obj);
    });
    return Array.from(uniqueMap.values());
  }

  useEffect(() => {
    if (selectedBrands.length < 1 && brands.length > 0) {
      setBrands(brands);
    }
  }, [brands]);

  const handleFlagChange = (event: any, newValue: any) => {
    const uniqueArray = getUniqueById(newValue);
    setBrands(uniqueArray);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        id="brand-filter"
        options={brands || []}
        multiple
        disableCloseOnSelect
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            padding: "5px",
          },
        }}
        onChange={handleFlagChange}
        value={selectedBrands}
        getOptionLabel={(option: any) => option?.name}
        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
        renderOption={(props, option: any) => {
          const isSelected = selectedBrands.some((brand: any) => brand.id === option.id);
          return (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={isSelected}
              />
              {option.name}
            </li>
          );
        }}
        renderTags={(selected, getTagProps) => {
          if (selected.length > 2) {
            return [
              <Chip
                label={selected[0]?.name}
                {...getTagProps({ index: 0 })}
              />,
              <Chip
                label={selected[1]?.name}
                {...getTagProps({ index: 1 })}
              />,
              <Chip
                key="more"
                label={`+${selected.length - 2} more`}
              />
            ];
          }

          return selected.map((option: any, index: number) => (
            <Chip
              label={option?.name}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={selectedBrands.length === 0 ? "Choose brands" : ""}
          />
        )}
      />
    </>
  );
};

export default Brandfilter;

