import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchBar from "../../../SearchBar";
import WhatsappTemplates from "~/modules/WhatsappModule/segments/WhatsappTemplates";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES } from "~/graphql/actions/whatsapp/whatsapp_templates_get_all";
import TemplateRowSekeleton from "~/modules/WhatsappModule/segments/WhatsappTemplates/components/TemplateRowSekeleton";
import TemplateRow from "~/modules/WhatsappModule/segments/WhatsappTemplates/components/TemplateRow";
import TemplateList from "./components/TemplateList";

const TemplateBar = ({ type, showTemplateBar, setShowTemplateBar }: any) => {
  const [templateSearchText, setTemplateSearchText] = useState("");

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showTemplateBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div className="px-[23px] text-white text-[19px] pt-[60px] bg-whatsappBasic">
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowTemplateBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Templates</p>
          </div>
        </div>
        <SearchBar setSearch={setTemplateSearchText} />
        {showTemplateBar && (
          <TemplateList templateSearchText={templateSearchText} />
        )}
      </div>
    </>
  );
};

export default TemplateBar;
