export const StayFormatFormData = (staySuggestions: any) => {
  const generateCost = (stay_inq_suggestion_costs: any) => {
    const stay_inq_suggestion_costs_return: any = [];
    stay_inq_suggestion_costs.map((suggestion_cost: any) => {
      stay_inq_suggestion_costs_return.push({
        id: suggestion_cost?.id,
        atol_price: suggestion_cost?.atol_price,
        tax_price: suggestion_cost?.tax_price,
        basic_fare: suggestion_cost?.basic_fare,
        sale_price: suggestion_cost?.sale_price,
        passenger_id: suggestion_cost?.passenger_id,
      });
    });

    return stay_inq_suggestion_costs_return;
  };
  const defaultValue: any = {
    // fareExpiry: staySuggestions.fare_expiry,
    // invoiceDueDate: staySuggestions.due_date,
    // pnr: staySuggestions.pnr,
    // bookingFee: staySuggestions.bookingFee,
    id: staySuggestions.id,
    where_to: staySuggestions.where_to || "", // Default to empty string if undefined
    start_date: staySuggestions.start_date || "", // Default to empty string if undefined
    end_date: staySuggestions.end_date || "", // Default to empty string if undefined
    hotel_name: staySuggestions.hotel_name || "", // Default to empty string if undefined
    room_type: staySuggestions.room_type || "", // Default to empty string if undefined
    suggestion_cost: generateCost(
      staySuggestions.stay_inq_suggestion_costs || []
    ), // Keep this as an array
  };

  return defaultValue;
};
