import { gql } from "@apollo/client";

export const INSERT_SERVICE = gql`
  mutation insertService($data: brd_services_insert_input!) {
    insert_brd_services(objects: [$data]) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_SERVICES_WITH_ID = gql`
  mutation DELETE_BRD_SERVICES_WITH_ID($id: uuid!) {
    delete_brd_services(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_SERVICES = gql`
  mutation updateBrdServices($data: brd_services_set_input!, $_eq: uuid!) {
    update_brd_services(where: { id: { _eq: $_eq } }, _set: $data) {
      affected_rows
    }
  }
`;
