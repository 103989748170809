import { gql } from "@apollo/client";

export const UPDATE_FAQ = gql`
  mutation MyMutation(
    $id: brd_faq_pk_columns_input!
    $faqData: brd_faq_set_input!
  ) {
    update_brd_faq_by_pk(pk_columns: $id, _set: $faqData) {
      id
    }
  }
`;
