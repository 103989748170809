import React from "react";
import CountDown from "~/components/common/CountDown";
import { updateCountdown } from "~/utils";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";

interface CommentCardProps {
  comment: any;
}

const CommentCard = ({ comment }: CommentCardProps) => {
  const flag = comment?.thp_inquiry_flag;
  return (
    <div
      className="p-[20px] rounded-lg mb-2 bg-white dark:bg-dark-secondary text-basic dark:text-dark-primary"
      style={{ borderLeft: `2px solid ${flag?.color ?? "#000"}` }}
    >
      <div className="flex justify-between items-center mb-5">
        <span
          className="text-[12px]  px-[10px] py-[3px] rounded-full"
          style={{
            color: `#000`,
            background: `${flag?.color ? `${flag?.color}20` : "#00000070"}`,
          }}
        >
          {flag?.name}
        </span>
        {comment?.reminder_time && (
          <div className="flex items-center gap-[5px] text-basic dark:text-dark-primary">
            <label htmlFor="">Reminder -</label>
            <div className="w-20">
              <CountDown endDate={comment?.reminder_time} />
            </div>
          </div>
        )}
      </div>
      <p className="mb-3">{comment?.comment}</p>
      <div className="flex items-center gap-[7px]">
        <h4 className="font-bold text-lg m-0">{comment?.user?.displayName}</h4>
        <span className="text-[14px]">
          {getTimeReadableFormat(comment?.created_at)}
        </span>
      </div>
    </div>
  );
};

export default CommentCard;
