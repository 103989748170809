import { Box, TextField, Divider, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation } from "@apollo/client";
import { ADD_BRD_SEASONS } from "~/graphql/brd_seasons/Mutation";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddSeasons = ({ setOpenSeasMod, refetch }: any) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [addSeasons] = useMutation(ADD_BRD_SEASONS);

  const { brd_id } = useParams();

  const onSubmit = async (formData: any) => {
    const payload = {
      name: formData?.seasonName,
      start_date: moment(formData?.startDate)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[+00:00]"),
      end_date: moment(formData?.endDate)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[+00:00]"),
      brd_id,
    };

    const resInsertSeas = await addSeasons({
      variables: {
        data: [payload],
      },
    });

    if (resInsertSeas?.data?.insert_brd_seasons?.affected_rows) {
      toast.success("Season Added Successfully");
      setOpenSeasMod(false);
      refetch();
    }
  };

  return (
    <div>
      <h1 className="text-center text-3xl text-basic">Add Seasons</h1>
      <Divider />
      <Box onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <div className="col-span-2">
            <TextField
              fullWidth
              error={errors["seasonName"] ? true : false}
              {...register("seasonName", { required: true })}
              label="Name"
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register("startDate", { required: true })}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    views={["month", "day"]}
                    value={value}
                    inputRef={ref}
                    onChange={(date) => {
                      onChange(date?.$d);
                    }}
                    sx={{ width: "100%" }}
                    label="Start Date"
                    slotProps={{
                      textField: {
                        error: errors["startDate"] ? true : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register("endDate", { required: true })}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    views={["month", "day"]}
                    value={value}
                    inputRef={ref}
                    onChange={(date) => {
                      onChange(date?.$d);
                    }}
                    sx={{ width: "100%" }}
                    label="End Date"
                    slotProps={{
                      textField: {
                        error: errors["endDate"] ? true : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="flex items-center justify-center mt-10">
          <Button type="submit" variant="contained">
            Add Seasons
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default AddSeasons;
