import { Document, Page, View, Image, Text } from "@react-pdf/renderer";

import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";
import airportData from "~/components/FormInputs/LocationBox/data/airports.json";
import moment from "moment";
import luggage from "../images/luggage.png";
import handCarry from "../images/HandCarry.png";
import vector81 from "../../../components/images/Vector8.png";
import { getStyles } from "./style";
import { splitLocation } from "~/utils/splitLocation";

const TrainSuggestionPDF = ({ inquiry, logo, suggestions }: any) => {
  console.log("damnamdnas", suggestions);

  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerFirstContainer}>
            {logo && <Image style={styles.logo} src={logo} />}

            <View style={styles.brandDetail}>
              <Text style={styles.brandName}>{inquiry?.brd_list?.name}</Text>
              <Text>www.{inquiry?.brd_list?.domain}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.email}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.phone}</Text>
            </View>
          </View>
          <View style={styles.headerSecondContainer}>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
            </Text>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
              {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
            </Text>
          </View>
        </View>
        <View style={styles.inquiryDetailContainer}>
          {/* {suggestions.map((suggestion: any, ind: any) => { */}
          {/* return ( */}
          <Text>TRAIN SUGGESTION</Text>
          <View>
            <View style={styles.flightDetailHeader}>
              <Text style={styles.flightTypeText}>Origin</Text>
              <View style={styles.flightCabinContainer}>
                <Text style={styles.flightCabinText}>Destination</Text>
              </View>
            </View>
            <View style={styles.flightDetailBody}>
              <>
                <View style={styles.flightLegsParentContainer}>
                  <View style={styles.flightLegs}>
                    <View style={styles.flightLegContainers}>
                      <View style={styles.flightLegSubContainers}>
                        <Text style={styles.flightLegArrDep}>
                          {suggestions[0]?.origin}
                        </Text>
                      </View>
                      <Text style={styles.flightLegDateTime}>
                        {moment(suggestions[0]?.start_date)
                          .tz("UTC")
                          .format("YYYY-MM-DD")}
                        |{" "}
                        {moment(suggestions[0]?.time).tz("UTC").format("HH:mm")}
                      </Text>
                    </View>

                    <View style={styles.airlineContainer}></View>
                    <Image style={styles.dottedImg} src={vector81} />
                    <View style={styles.flightLegContainerRight}>
                      <View style={styles.flightLegSubContainerRight}>
                        <Text style={styles.flightLegArrDep}>
                          {suggestions[0]?.destination}
                        </Text>
                      </View>
                      <Text style={styles.flightLegDateTime}>
                        {moment(suggestions[0]?.end_date)
                          .tz("UTC")
                          .format("YYYY-MM-DD")}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
              <View style={styles.priceLuggageContainer}>
                <View style={styles.luggageContainer}>
                  <View style={styles.luggageSubContainer}>
                    {/* <Image style={styles.luggageImg} src={luggage} /> */}
                    <Text style={styles.luggageText}>
                      Cabin Class: {suggestions[0]?.cabin_class}
                    </Text>
                    {"\n"}
                    <Text style={styles.luggageText}>
                      Train Name: {suggestions[0]?.train_name}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* );
          })} */}
          <View style={styles.priceLuggageContainer}>
            <View style={styles.luggageContainer}>
              <View style={styles.luggageSubContainer}>
                <Text style={styles.luggageText}>Total Price:</Text>
                <Text style={styles.luggageText}>
                  £
                  {suggestions[0]?.train_inq_suggestion_costs?.data?.reduce(
                    (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TrainSuggestionPDF;
