import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import InputField from "~/components/FormInputs/InputField";
import { GET_FLIGHT_PAGES } from "./graphql/Query";
import SimpleSelect from "~/components/FormInputs/SimpleSelect";
import { faqDestinations } from "./data/SelectOptions";
import { INSERT_TESTIMONIAL } from "./graphql/Mutation";
import { toast } from "react-toastify";

const AddTestimonial = ({ setAddTsm, addTsm }: any) => {
  const { brd_id } = useParams();
  const { loading: flightpagesLoading, data: flightPagesData } = useQuery(
    GET_FLIGHT_PAGES,
    { variables: { id: brd_id } }
  );
  const [insertTestimonial] = useMutation(INSERT_TESTIMONIAL);
  const client = useApolloClient();
  const [flightPageOptions, setFlightPageOptions] = useState<any>([]);
  useEffect(() => {
    const defaultOpt = [
      {
        title: "Choose Destination",
        value: "",
      },
    ];
    if (flightPagesData) {
      const flObj = flightPagesData?.brd_flights_pages?.map((flt: any) => {
        return {
          title: `${flt?.destination_name} (${flt?.destination_iata})`,
          value: flt?.id,
        };
      });

      setFlightPageOptions([...defaultOpt, ...flObj]);
    }
  }, [flightpagesLoading]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    const res = await insertTestimonial({
      variables: {
        tsData: {
          name: inpData?.name,
          feedback: inpData?.feedback,
          rating: inpData?.rating,
          brd_id: brd_id,
        },
      },
    });

    if (res?.data?.insert_brd_testimonials_one?.id) {
      toast.success("Testimonial added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
      setAddTsm(false);
    }
  };
  return (
    <>
      <div
        onClick={() => setAddTsm(false)}
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30"
      ></div>
      <div
        id="drawer-update-faq-default"
        className={`fixed top-0 right-0 z-40 w-full h-screen max-w-md p-4 overflow-y-auto transition-transform bg-white  ${
          addTsm ? `translate-x-0` : `translate-x-full`
        }`}
        aria-labelledby="drawer-label"
        aria-hidden="true"
      >
        <h5
          id="drawer-label"
          className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          Add New Testimonial
        </h5>
        <button
          onClick={() => setAddTsm(false)}
          type="button"
          aria-controls="drawer-update-faq-default"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="rating"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Rating"
                defaultValue={1}
                type="number"
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="name"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Name"
                placeHolder="Name"
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Feedback
              </label>
              <textarea
                {...register("feedback")}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Feedback"
              ></textarea>
            </div>
          </div>
          <div className="bottom-0 left-0 flex justify-center w-full pb-4 mt-4 space-x-4 sm:px-4 sm:mt-0">
            <button
              type="submit"
              className="w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              ADD
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTestimonial;
