import React from "react";
import AccountDetailRow from "./AccountDetailRow";

export default function AccountDetail({ accounts }: any) {
  return (
    <main className="w-full pt-2">
      <section className="container">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden rounded-lg">
                <table className="min-w-full divide-y divide-gray-200  dark:divide-gray-700">
                  <thead className="bg-secondary dark:bg-dark-secondary ">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                      >
                        <div className="flex items-center gap-x-3">
                          <button className="flex items-center gap-x-2">
                            <span>SR.</span>
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="w-[100px] px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary  "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-secondary dark:bg-dark-secondary divide-y divide-gray-200  dark:divide-gray-700 ">
                    {accounts.length > 0 ? (
                      accounts.map((account: any, i: number) => (
                        <AccountDetailRow key={i} account={account} index={i} />
                      ))
                    ) : (
                      <tr className="">
                        <td
                          colSpan={10}
                          className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap"
                        >
                          <div className="inline-flex items-center gap-x-3">
                            <span>No Record Found</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
