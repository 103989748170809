import { gql } from "@apollo/client";

export const UPDATE_BRAND_SETTING = gql`
  mutation updateBrandSetting(
    $id: brd_default_setting_pk_columns_input!
    $brdData: brd_default_setting_set_input!
  ) {
    update_brd_default_setting_by_pk(pk_columns: $id, _set: $brdData) {
      id
    }
  }
`;

export const INSERT_BRAND_SETTING = gql`
  mutation InsertBrandSetting($data: brd_default_setting_insert_input!) {
    insert_brd_default_setting_one(object: $data) {
      id
    }
  }
`;
