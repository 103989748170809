import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Button, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const GoogleTagInfo = ({ brand }: any) => {
  const { brd_id } = useParams();
  const settings = brand?.brd_default_settings?.at(0);

  const client = useApolloClient();
  const [updateSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertSettings] = useMutation(INSERT_DEFAULT_SETTING);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();
  const onSubmit = async (inpData: any) => {
    // ;
    if (settings?.id) {
      try {
        const response = await updateSettings({
          variables: {
            id: {
              id: settings.id,
            },
            data: inpData,
          },
        });
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      } catch (e: any) {
        toast.error(e.message);
      }
    } else {
      const response = await insertSettings({
        variables: {
          data: { ...inpData, brd_id: brd_id },
        },
      });
      if (response?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Insertion on Heads</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              defaultValue={settings?.gtag_id}
              error={errors["gtag_id"] ? true : false}
              {...register("gtag_id", { required: true })}
              label="Google Tag Manager ID"
              type="text"
            />
            <TextField
              defaultValue={settings?.clarity_id}
              {...register("clarity_id")}
              label="Clarity ID"
              type="text"
            />
          </div>
          <div className="mt-5 ">
            <TextField
              sx={{ width: "100%" }}
              defaultValue={settings?.website_head}
              {...register("website_head")}
              multiline
              minRows={3}
              maxRows={5}
              label="Website Head"
            />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save Settings
          </Button>
        </Box>
      </div>
    </>
  );
};

export default GoogleTagInfo;
