import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import AdmissionRequirements from "~/modules/BrandModule/segments/Universities/Campuses/CampusCourses/AdmissionRequirements";

export default function AdmissionRequirementsPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <AdmissionRequirements />
        </BrandModule>
      </Layout>
    </div>
  );
}
