import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import UniCourses from "~/modules/BrandModule/segments/Universities/Courses";


export default function UniCoursesPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <UniCourses />
        </BrandModule>
      </Layout>
    </div>
  );
}