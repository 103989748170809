import { gql } from "@apollo/client";

export const GET_SERVICE_LEADS_LIVE_COUNT = gql`
  subscription getServiceLiveLeadsCount(
    $condition: services_leads_list_bool_exp!
  ) {
    services_leads_list_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BRANDS_LEADS = gql`
  query getLeads($condition: services_leads_list_bool_exp) {
    services_leads_list(
      where: $condition
      order_by: { created_at: desc }
      ) {
      id
      lead_no
      source
      created_at
      service {
        title
      }
      user {
        id
        displayName
        email
        phoneNumber
      }
      brand {
        name
      }
    }
  }
`;

