import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import PhoneBox from "~/components/FormInputs/PhoneBox";
import { INSERT_AIRLINE } from "./graphql/Mutation";
import { toast } from "react-toastify";
import LocationBox from "~/components/FormInputs/LocationBox";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useFileUpload } from "@nhost/react";
import { useNavigate } from "react-router-dom";
import { GET_AIRLINE_PAGE } from "../../graphql/Query";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import _airlineData from "../../../../../../components/FormInputs/AirlineBox/data/airlines.json";

const AirlineDetail = ({ brand }: any) => {
  const navigate = useNavigate();
  const contact = brand?.brd_contact_details?.at(0);
  const [insertAirline] = useMutation(INSERT_AIRLINE);
  const [getAirlinePage] = useLazyQuery(GET_AIRLINE_PAGE);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [featuredFile, setFeatureFile] = useState<any | null>(null);
  const [featured, setFeatured] = useState<any | null>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    // return 0;
    const rzt = await getAirlinePage({
      variables: {
        airline_iata: inpData?.airline?.iata,
        id: brand.id,
      },
    });
    if (rzt?.data?.brd_airlines_pages?.at(0)?.id) {
      toast.error("Airline page already exists!");
      return 0;
    }

    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    }

    if (featuredFile) {
      const featuredFileRes = await uploadLogo(featuredFile);
      inpData["feature"] = featuredFileRes?.id || null;
    }

    const res = await insertAirline({
      variables: {
        data: {
          brd_id: brand.id,
          airline_iata: inpData?.airline?.iata,
          airline_name: inpData?.airline?.marketing_name,
          slug: inpData?.airline?.marketing_name
            ?.replaceAll(" ", "-")
            ?.toLowerCase(),
        },
      },
    });
    if (res?.data?.insert_brd_airlines_pages_one?.id) {
      reset();
      toast.success("Airline added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
      navigate(
        `/admin/brands/${brand.id}/airlines/update/${res?.data?.insert_brd_airlines_pages_one?.id}`
      );
    }
  };

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
    }
  };

  const handleFeatureUpload = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setFeatureFile({ file });
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Airline Base Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("airline", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option: any) =>
                      option.marketing_name
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airlineData ? _airlineData : []}
                  getOptionLabel={(option: any) => option.marketing_name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.marketing_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Airline"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["airline"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
          </div>
          <Button type="submit" sx={{ marginTop: "20px" }} variant="contained">
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AirlineDetail;
