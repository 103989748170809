import { Avatar, Popover } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useState } from "react";
import MenuItems from "./component/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Header = ({
  conversation,
  showWhatsappSidebar,
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  setShowUserInfo,
  setShowConsultantBar,
}: any) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="bg-whatsappPrimaryBg dark:bg-dark-primary flex items-center justify-between border-l dark:border-dark-secondary py-[8px] px-[16px] w-full text-primary dark:text-dark-primary">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setShowUserInfo(true)}
      >
        {!showWhatsappSidebar && (
          <ArrowBackIosNewOutlinedIcon
            sx={{ color: "#3A3541DE", marginRight: "15px" }}
            onClick={() => {
              setShowWhatsappChatBox(false);
              setShowWhatsappSidebar(true);
            }}
          />
        )}
        <AccountCircleIcon
          sx={{
            color: "#DFE5E7",
            fontSize: "50px",
          }}
        />
        <div className="pl-[15px]">
          <h1 className="text-[16px] text-black dark:text-white">
            {conversation?.whatsapp_name}
          </h1>
          <p className="text-[14px]">{conversation?.user_phone}</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-5">
          <SearchOutlinedIcon
            sx={{ color: darkMode ? "#E7E3FCDE" : "#3A3541DE" }}
          />
        </div>
        <div className="mx-2">
          <MoreVertOutlinedIcon
            sx={{
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              cursor: "pointer",
            }}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setShowMenu(!showMenu);
            }}
          />
          <Popover
            sx={{
              background: "transparent",
              top: "10px",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowMenu(!showMenu);
            }}
            open={showMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItems
              setShowConsultantBar={setShowConsultantBar}
              brdId={conversation?.brd_list?.id}
              convId={conversation?.id}
              consultant={conversation?.assigned_consultant}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header;
