import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import Papa from "papaparse";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ADD_BRD_USERS, ADD_INQ, ADD_USER } from "./Mutation";
import { displayName } from "react-quill";
import { generateRandomNumberString } from "~/utils/generateRandomId";
import { v4 as uuidv4 } from "uuid";
import { GET_CONSULTANT } from "./Query";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddPrevDataComp = () => {
  const [passengerData, setPassengerData] = useState<any>([]);
  const [details, setDetails] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const [addUser] = useMutation(ADD_USER);

  const handleFileUpload = async (e: any) => {
    const file1 = e.target.files[0];
    // const file2 = e.target.files[1];
    // const file3 = e.target.files[2];
    Papa.parse(file1, {
      header: true,
      complete: (results) => {
        setPassengerData(results.data);
      },
    });
    // Papa.parse(file2, {
    //   header: true,
    //   complete: (results) => {
    //     setBookingData(results.data);
    //   },
    // });
    // Papa.parse(file3, {
    //   header: true,
    //   complete: (results) => {
    //     setTransactionData(results.data);
    //   },
    // });
  };
  // const client = useApolloClient();

  const handleDownload = () => {
    // Convert the array to a string
    const arrayString = JSON.stringify(details);

    // Create a Blob from the string
    const blob = new Blob([arrayString], { type: "application/json" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a hidden link element
    const link = document.createElement("a");
    link.href = url;
    link.download = "array.json";

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically trigger a click event on the link
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url);
  };

  const generateData = async () => {
    setLoading(true);
    await Promise.all(
      passengerData.map(async (passenger: any) => {
        setLoading(true);
        const hasContactInfo =
          passenger?.cst_email || passenger?.cst_mobile || passenger?.cst_phone;
        try {
          if (hasContactInfo) {
            const userResponse = await addUser({
              variables: {
                data: {
                  displayName: passenger?.cst_name,
                  locale: "en",
                  email: passenger?.cst_email,
                  phoneNumber: passenger?.cst_mobile || passenger?.cst_phone,
                  metadata: {
                    type: "oldDB",
                    postalCode: passenger?.cst_postcode,
                    addressLine1: passenger?.cst_address,
                    oldConsultant: passenger?.bkg_agent,
                  },
                  brd_users: {
                    data: [
                      {
                        brd_id: "a27f3403-18db-4f32-a541-130ba6016934",
                        thp_id: "8e531553-0e11-4f9d-96e2-f230f904e292",
                      },
                    ],
                  },
                },
              },
            });
            if (userResponse?.data?.insertUsers?.returning?.[0]?.id) {
              details.push({
                displayName: passenger?.cst_name,
                email: passenger?.cst_email,
                phoneNumber: passenger?.cst_mobile || passenger?.cst_phone,
                status: "Success",
              });
              setLoading(false);
            } else {
              details.push({
                displayName: passenger?.cst_name,
                email: passenger?.cst_email,
                phoneNumber: passenger?.cst_mobile || passenger?.cst_phone,
                status: "Failed",
              });
              setLoading(false);
            }
          }
        } catch (error) {
          details.push({
            displayName: passenger?.cst_name,
            email: passenger?.cst_email,
            phoneNumber: passenger?.cst_mobile || passenger?.cst_phone,
            status: "Failed",
          });
          setLoading(false);
        }
      })
    );
  };

  return (
    <div className="flex flex-col gap-10 h-full">
      <div className="flex items-center justify-center gap-5">
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ minWidth: "200px" }}
        >
          Upload CSV file
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            generateData();
          }}
        >
          {loading ? "ADD..." : "ADD"}
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "300px", color: "green" }}
          onClick={handleDownload}
        >
          Download Details
        </Button>
      </div>
      <div className="flex flex-col gap-5 overflow-y-scroll flex-grow p-5 ">
        <p className="text-lg font-bold py-1 px-3 text-center mb-5 border-2 border-green-700">
          Total Results: {details?.length}
        </p>
        {details?.map((detail: any) => {
          return (
            <div className="flex justify-between items-center text-lg">
              <p className="font-bold">{detail?.displayName}</p>
              <p>{detail?.email}</p>
              <p>{detail?.phoneNumber}</p>
              <p
                style={
                  detail?.status === "success"
                    ? { color: "green" }
                    : { color: "red" }
                }
              >
                {detail?.status}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddPrevDataComp;

// const suggUUID = uuidv4();
//         console.log("Suggestion Uuuid", suggUUID);

// const res = await client.query({
//   query: GET_CONSULTANT,
//   variables: {
//     consName: passenger?.bkg_agent,
//   },
// });

// inq_lists: {
//   data: [
//     {
//       thp_id: "8e531553-0e11-4f9d-96e2-f230f904e292",
//       brd_id: "a27f3403-18db-4f32-a541-130ba6016934",
//       picked_by: res?.data?.users?.[0]?.id
//         ? res?.data?.users?.[0]?.id
//         : "c98de7a8-7ebc-4519-a80d-24f73fe2d9a1",
//       inquiry_no: generateRandomNumberString(),
//       source: passenger?.cst_source,
//       trip_type:
//         passenger?.flt_flighttype === "Return"
//           ? "roundTrip"
//           : "oneWay",
//       direct_flight: passenger?.flt_via === "direct" && true,
//       status:
//         passenger?.bkg_status === "Cleared" && "completed",
//       from: passenger?.flt_departureairport
//         ?.split("-")[1]
//         ?.trim()
//         ?.replace(/\s+/g, ""),
//       to: passenger?.flt_destinationairport
//         ?.split("-")[1]
//         ?.trim()
//         ?.replace(/\s+/g, ""),
//       departure_datetime: passenger?.flt_departuredate,
//       arrival_datetime: passenger?.flt_returningdate,
//       created_at: passenger?.bkg_date,
//       cabin_class: passenger?.flt_class.toLowerCase(),
//       inq_passengers: {
//         data: bookingData
//           ?.filter(
//             (booking: any) =>
//               booking?.bkg_no === passenger?.bkg_no
//           )
//           .map((bkg: any) => ({
//             type: bkg?.p_catagory.toLowerCase(),
//             first_name: bkg?.p_firstname,
//             last_name: bkg?.p_lastname,
//             inq_suggestion_costs: {
//               data: [
//                 {
//                   suggestion_id: suggUUID,
//                   cost_price: (
//                     passenger?.cost_basic /
//                     bookingData?.filter(
//                       (booking: any) =>
//                         booking?.bkg_no === passenger?.bkg_no
//                     )?.length
//                   )?.toFixed(2),
//                   sale_price: bkg?.p_basic,
//                 },
//               ],
//             },
//           })),
//       },
//       inq_suggestions: {
//         data: [
//           {
//             id: suggUUID,
//             fare_expiry: passenger?.flt_fare_expiry,
//             airline_iata: passenger?.flt_airline
//               ?.split("-")[1]
//               ?.trim()
//               ?.replace(/\s+/g, ""),
//             brd_id: "a27f3403-18db-4f32-a541-130ba6016934",
//             pnr: passenger?.flt_pnr,
//             inq_suggestion_legs: {
//               data: [
//                 {
//                   type: "departing",
//                   cabin: passenger?.flt_class.toLowerCase(),
//                   luggage:
//                     passenger?.flt_going_side_baggage_weight,
//                 },
//                 passenger?.flt_flighttype === "Return" && {
//                   type: "returning",
//                   cabin: passenger?.flt_class.toLowerCase(),
//                   luggage:
//                     passenger?.flt_return_side_baggage_weight,
//                 },
//               ],
//             },
//           },
//         ],
//       },
//       inq_transections: {
//         data: [
//           {
//             payment_method_id:
//               "b37f8801-56e9-4967-a3b0-5cdbf838429e",
//           },
//         ],
//       },
//     },
//   ],
// },
