"use client";
import React, { useState } from "react";
import SidePopupButton from "./components/SidePopupButton";
import SidePopupBar from "./components/SidePopupBar";
import { SidePopupProps } from "./types";
import commonStore from "~/store/commonStore/commonStore";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Drawer, Fab, SwipeableDrawer } from "@mui/material";

import { Link } from "react-router-dom";
import SideDrawer from "../sideDrawer";

const SidePopup = ({ component, popupType }: any) => {
  const [sidePopup, setSidePopup] = useState(false);
  const [cuurentAddress, setCuurentAddress] = useState();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {popupType === "speedDial" && (
        <Box
          sx={{
            position: "fixed",
            bottom: "30px",
            right: "30px",
            transform: "translateZ(0px)",
            flexGrow: 1,
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            icon={<SpeedDialIcon />}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            {component.map((action: any) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.tooltip}
                onClick={() => {
                  setCuurentAddress(action.address);
                  setSidePopup(!sidePopup);
                }}
              />
            ))}
          </SpeedDial>
          <SideDrawer
            setSidePopup={setSidePopup}
            sidePopup={sidePopup}
            component={cuurentAddress}
          />
        </Box>
      )}
      {!(popupType === "speedDial") && (
        <Box
          sx={{
            position: "fixed",
            bottom: "30px",
            right: "30px",
          }}
        >
          <Fab
            onClick={() => setSidePopup(!sidePopup)}
            sx={{ boxShadow: 3 }}
            color="primary"
            aria-label="open"
          >
            <AddIcon />
          </Fab>
          <SideDrawer
            setSidePopup={setSidePopup}
            sidePopup={sidePopup}
            component={component}
          />
        </Box>
      )}

      {/* <SidePopupButton
        className={className}
        icon={buttonIcon}
        text={text}
        setShow={setSidePopup}
      />
      {sidePopup && (
        <SidePopupBar setShow={setSidePopup} width={width}>
          {Component && React.cloneElement(Component, { setSidePopup })}
        </SidePopupBar>
        // <SidePopupBar
        //   component={<Component setClose={setSidePopup} />}
        //   setShow={setSidePopup}
        // />
      )} */}
    </>
  );
};

// SidePopup.defaultProps = {
//   className: "",
//   buttonIcon: "",
//   text: "",
//   width: "600px",
// };

export default SidePopup;
