import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query GetTransaction($condition: acc_transactions_bool_exp!) {
    acc_transactions(where: $condition, order_by: { created_at: desc }) {
      brd_id
      acc_id
      inq_id
      payment_method_id
      user_id
      transactions_no
      transactions_seq
      detailed_desc
      date
      id
      type
      amount
      note
      paid
      def_acc_id
      inq_ticket {
        ibe
      }
      brd_list {
        name
      }
      inq_list {
        inquiry_no
        picked_user {
          thp_users_profile {
            pseudo_name
          }
        }
      }
      acc_list {
        type
        name
      }
      def_acc_list {
        type
        name
      }
      acc_list {
        name
      }
      users {
        displayName
        defaultRole
      }
      users {
        displayName
      }
      acc_payment_method {
        name
      }
    }
  }
`;
