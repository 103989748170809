import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import EmailTemplatesInputs from "../EmailTemplatesInputs";
import Box from "@mui/material/Box";
import ConfirmAction from "./components/ConfirmAction/index";
import { Modal } from "@mui/material";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_EMAIL_TEMPLATE } from "~/graphql/def_email_templates/Mutation";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const DefaultTemplates = ({ template }: any) => {
  const [selectedRole, setSelectedRole] = useState(template.for_role);
  const [selectedDynamicFrom, setSelectedDynamicFrom] = useState(
    template.dynamic_from
  );
  const [selectedIsActivity, setSelectedIsActivity] = useState(
    template.is_activity
  );
  const [content, setContent] = useState(template?.content);

  const [openConfirmMod, setOpenConfirmMod] = useState<{
    isOpen: boolean;
    buttonName?: string;
  }>({ isOpen: false });
  const [isLoading, setIsLoading] = useState(false);

  const client = useApolloClient();
  const [updateTemp] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      subject: template.subject,
      dynamic_from: template.dynamic_from,
      from: template.from,
      content: "",
    },
  });

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleDynamicFromChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDynamicFrom(event.target.value);
  };

  const handleActivityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedIsActivity(event.target.value);
  };

  const openConfirmModal = (buttonName: string) => {
    setOpenConfirmMod({ isOpen: true, buttonName });
  };

  const onSubmit = async (inpData: any) => {
    setIsLoading(true);

    const res = await updateTemp({
      variables: {
        temp_id: template.id,
        data: {
          for_role: selectedRole,
          variables_allowed: inpData?.variables_allowed,
          subject: inpData?.subject,
          // content: inpData?.content,
          whatsapp_template_name: inpData?.whatsapp_template_name,
          notification_content: inpData?.notification_content,
          dynamic_from: selectedDynamicFrom,
          dynamic_from_label: inpData?.dynamic_from_label,
          is_activity: selectedIsActivity,
        },
      },
    });
    if (res?.data?.update_def_emails_templates?.affected_rows > 0) {
      toast.success("Template Updated Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    setIsLoading(false);
  };

  

  return (
    <div className="mt-2">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-bold"
        >
          <div className="flex items-center">
            {template.name}
            <span className="ml-3 text-[12px] text-gray-500">
              - ID#{template.id}
            </span>
          </div>
        </AccordionSummary>
        <form>
          <AccordionDetails>
            <EmailTemplatesInputs
              register={register}
              errors={errors}
              template={template}
              content={content}
              setContent={setContent}
              setValue={setValue}
              selectedRole={selectedRole}
              handleRoleChange={handleRoleChange}
              selectedDynamicFrom={selectedDynamicFrom}
              handleDynamicFromChange={handleDynamicFromChange}
              selectedIsActivity={selectedIsActivity}
              handleActivityChange={handleActivityChange}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button
              variant="contained"
              onClick={() => openConfirmModal("Delete")}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => openConfirmModal("Update")}
            >
              Update
            </Button>
          </AccordionActions>
        </form>
      </Accordion>
      <Modal
        open={openConfirmMod.isOpen}
        onClose={() => setOpenConfirmMod({ isOpen: false })}
      >
        <Box sx={{ ...style, width: "30%" }}>
          <ConfirmAction
            setOpenConfirmMod={setOpenConfirmMod}
            buttonName={openConfirmMod.buttonName}
            templateId={template.id}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default DefaultTemplates;
