import React from "react";

export default function Child() {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9406 2.51572C11.9406 3.89937 13.11 5.03145 14.5393 5.03145C15.9685 5.03145 17.1379 3.89937 17.1379 2.51572C17.1379 1.13208 15.9685 0 14.5393 0C13.11 0 11.9406 1.10692 11.9406 2.51572ZM16.6962 5.93711H13.7077C12.7202 5.93711 11.9146 6.71698 11.9146 7.67296V15.1698C11.9146 15.6226 12.3044 16 12.7722 16C13.24 16 13.6298 15.6226 13.6298 15.1698V13.4843C13.6298 13.0314 14.0196 12.6541 14.4873 12.6541C14.9551 12.6541 15.3449 13.0314 15.3449 13.4843V15.1698C15.3449 15.6226 15.7347 16 16.2024 16C16.6702 16 17.06 15.6226 17.06 15.1698V8.75472C17.06 8.60377 17.2679 8.5283 17.3718 8.62893L18.6971 9.91195C19.035 10.239 19.5807 10.239 19.9185 9.91195C20.2563 9.5849 20.2563 9.0566 19.9185 8.72956L17.4498 6.23899C17.2419 6.06289 16.982 5.93711 16.6962 5.93711ZM0.42859 9.88679C0.766415 10.2138 1.31213 10.2138 1.64996 9.88679L2.19568 9.35849C2.29962 9.25786 2.48153 9.35849 2.45554 9.48428L1.59798 13.2075C1.572 13.3585 1.67594 13.4843 1.83186 13.4843H2.87133C3.10521 13.4843 3.28711 13.6604 3.28711 13.8868V15.1698C3.28711 15.6226 3.67691 16 4.14467 16C4.61243 16 5.00222 15.6226 5.00222 15.1698V14.3396C5.00222 13.8868 5.39202 13.5094 5.85978 13.5094C6.32754 13.5094 6.71734 13.8868 6.71734 14.3396V15.1698C6.71734 15.6226 7.10714 16 7.57489 16C8.04265 16 8.43245 15.6226 8.43245 15.1698V13.8868C8.43245 13.6604 8.61435 13.4843 8.84823 13.4843H9.8877C10.0436 13.4843 10.1476 13.3585 10.1216 13.2075L8.7183 7.1195C8.56238 6.41509 7.91272 5.93711 7.18509 5.93711H4.69038C3.75487 5.93711 2.87133 6.28931 2.22166 6.91824L0.402603 8.67924C0.0907645 9.03145 0.0907644 9.55975 0.42859 9.88679ZM3.26112 2.51572C3.26112 3.89937 4.43052 5.03145 5.85978 5.03145C7.28904 5.03145 8.45844 3.89937 8.45844 2.51572C8.45844 1.13208 7.28904 0 5.85978 0C4.43052 0 3.26112 1.10692 3.26112 2.51572Z"
        fill="white"
      />
    </svg>
  );
}
