import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormLabel,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import Form from "./component/form";

const BrandAddUpdateSec = ({
  mainHeading,
  register,
  errors,
  field1,
  field2,
  field3,
  field4,
  field5,
  handleSubmit,
  control,
  addData,
  update,
}: any) => {
  const onSubmit = async (inpData: any) => {
    addData(inpData);
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{mainHeading}</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form
            register={register}
            errors={errors}
            field1={field1}
            field2={field2}
            field3={field3}
            field4={field4}
            field5={field5}
            control={control}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ marginTop: "20px" }}
          >
            {mainHeading}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandAddUpdateSec;
