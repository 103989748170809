import React, { useState } from "react";
import TransactionRow from "./components/TransactionRow";
import { useUserDefaultRole } from "@nhost/react";
export default function TransactionsTable({
  transactions,
  compType,
  refetchTrans,
}: any) {
  const role = useUserDefaultRole();

  return (
    <main className="w-full pt-2">
      <section className="w-full">
        <div className="flex flex-col">
          <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden w-full shadow rounded-lg">
                <table className="min-w-full text-center divide-y shadow divide-gray-200  dark:divide-gray-700">
                  <thead className="bg-secondary shadow dark:bg-dark-secondary w-full">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        <div className="flex items-center justify-center gap-x-3">
                          <button className="flex items-center gap-x-2">
                            <span>Transaction No.</span>
                          </button>
                        </div>
                      </th>
                      {compType !== "inquirSection" && (
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Brand
                        </th>
                      )}

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Date
                      </th>

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Account
                      </th>
                      {compType !== "inquirSection" && (
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Inquiry
                        </th>
                      )}

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Vender Reference
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Payment Method
                      </th>
                      {compType !== "inquirSection" && (
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                        >
                          User
                        </th>
                      )}

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Note
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                      >
                        Detailed Description
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal items-center text-primary dark:text-dark-primary"
                      >
                        <p>Debit</p>
                        <p>
                          (
                          {transactions
                            ?.filter(
                              (transaction: any) => transaction.type === "debit"
                            )
                            ?.reduce(
                              (sum: any, transaction: any) =>
                                sum + transaction.amount,
                              0
                            )
                            ?.toFixed(2)}
                          )
                        </p>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-sm font-normal text-center text-primary dark:text-dark-primary "
                      >
                        <p>Credit</p>
                        <p>
                          (
                          {transactions
                            ?.filter(
                              (transaction: any) =>
                                transaction.type === "credit"
                            )
                            ?.reduce(
                              (sum: any, transaction: any) =>
                                sum - transaction.amount,
                              0
                            )
                            ?.toFixed(2)}
                          )
                        </p>
                      </th>
                      {compType !== "inquirSection" && (
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Consultant
                        </th>
                      )}

                      {compType === "inquirSection" && role === "admin" && (
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-secondary shadow dark:bg-dark-secondary divide-y divide-gray-200 dark:divide-gray-700 ">
                    {transactions?.length > 0 ? (
                      transactions?.map((transaction: any, i: number) => (
                        <TransactionRow
                          key={i}
                          transaction={transaction}
                          compType={compType}
                          role={role}
                          refetchTrans={refetchTrans}
                        />
                      ))
                    ) : (
                      <tr className="">
                        <td
                          colSpan={13}
                          className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary whitespace-nowrap"
                        >
                          <div className="inline-flex items-center gap-x-3">
                            <span>No Record Found</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
