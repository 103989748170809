import { Quill } from "react-quill";
import { useFileUpload } from "@nhost/react";
import { URLs } from "~/config/enums";
import { BsCardImage } from "react-icons/bs";

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Modules object for setting up the Quill editor
export const modules = (props: any) => ({
  toolbar: {
    container: "#" + props,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
});

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
];

// Quill Toolbar component
export const QuillToolbar = (props: any) => {
  const { upload: uploadImage, progress: logoUploadProgress } = useFileUpload();
  const handleUploadImage = async (e: any) => {
    if (e?.target?.files?.length > 0) {
      const file = e.target.files[0];
      const editor = props?.quillRef?.current?.getEditor();
      // const quill = props?.quillRef?.current;
      const index = editor.getSelection(true).index;
      // ;
      // return 0;
      const res = await uploadImage({ file });
      // ;
      if (res?.id) {
        // const updatedValue = `${props.quillRef.current?.value}<img class="editor-img" alt="${file?.name}" src="${URLs?.FILE_URL}${res?.id}" />`;
        // editor.clipboard.dangerouslyPasteHTML(updatedValue);

        editor.insertEmbed(index, "image", `${URLs?.FILE_URL}${res?.id}`);
        // ;
      }
    }
  };
  return (
    <>
      {props.toolbarId !== undefined && (
        <div className="h-10">
          <div id={props.toolbarId} className="bg-white sticky top-0 z-10">
            <span className="ql-formats">
              <button className="ql-bold" />
              <button className="ql-italic" />
              <button className="ql-underline" />
              <button className="ql-strike" />
            </span>
            <span className="ql-formats">
              <select className="ql-size">
                <option value="extra-small">Extra Small</option>
                <option value="small">Small</option>
                <option value="medium" selected>
                  Medium
                </option>
                <option value="large">Large</option>
              </select>
              <select className="ql-header">
                <option value="1">Heading 1</option>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option value="4">Heading 4</option>
                <option value="5">Heading 5</option>
                <option value="6">Heading 6</option>
                <option value="" selected>
                  Normal
                </option>
              </select>
            </span>
            <span className="ql-formats">
              <button className="ql-list" value="ordered" />
              <button className="ql-list" value="bullet" />
              <button className="ql-indent" value="-1" />
              <button className="ql-indent" value="+1" />
            </span>
            <span className="ql-formats">
              <button className="ql-script" value="super" />
              <button className="ql-script" value="sub" />
              <button className="ql-blockquote" />
              <button className="ql-direction" />
            </span>
            <span className="ql-formats">
              <select className="ql-align" />
              <select className="ql-color" />
              <select className="ql-background" />
            </span>
            <span className="ql-formats">
              <button className="ql-link" />
              {/* <button className="ql-image" /> */}
              <button className="ql-video" />
            </span>
            <span className="ql-formats">
              <button className="ql-formula" />
              <button className="ql-code-block" />
              <button className="ql-clean" />
            </span>
            <span className="ql-formats">
              <label htmlFor="img">
                <BsCardImage className="text-black" />
              </label>
              <input
                onChange={handleUploadImage}
                id="img"
                type="file"
                accept="image/*"
                className="hidden"
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
export default QuillToolbar;
