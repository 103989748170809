import { gql } from "@apollo/client";

export const GET_TEMPLATE = gql`
  query GetTemplate($id: Int!) {
    def_emails_templates(where: { id: { _eq: $id } }) {
      id
      subject
      content
      dynamic_from
      dynamic_from_label
    }
  }
`;
