import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, TextField, InputAdornment, Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_SERVICES_WITH_BRD_ID } from "~/graphql/brd_services/Query";
import { INSERT_CASE_STUDY } from "~/graphql/services_casestudy/Mutation";
import { nhost } from "~/lib/nhost";
import ContentEditor from "~/components/common/ContentEditor";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const AddCaseStudy = () => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const client = useApolloClient();
  const { brd_id } = useParams();
  const [insertServicesCaseStudy] = useMutation(INSERT_CASE_STUDY);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [Loading, setLoading] = useState(false);
  const [content, setContent] = useState<any>("");
  const [previewImage, setPreviewImage] = useState("");
  const [title, setTitle] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  const { data } = useQuery(GET_SERVICES_WITH_BRD_ID, {
    variables: { brd_id: brd_id },
  });

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };

  const uploadImagetoNhost = async (file: any) => {
    try {
      const response = await nhost.storage.upload({ file });
      if (response.error) {
        console.error("Upload error:", response.error.message);
        throw new Error(response.error.message);
      }
      return response.fileMetadata.id;
    } catch (error) {
      console.error("Failed to upload Image:", error);
      throw error;
    }
  };

  const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const slugValue = inputValue.replace(/\s+/g, "-");
    setValue("slug", slugValue);
  };

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     setPreviewImage(URL.createObjectURL(file));
  //     setValue("image", file);
  //   }
  // };

  const handleImageChange = async (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const webpFile: any = await convertImageToWebP(file);
        // console.log("sdjcs", webpFile)
        setPreviewImage(URL.createObjectURL(webpFile));
        setValue("image", webpFile);
      } catch (error) {
        console.error("Error converting image:", error);
        toast.error("Failed to convert image to WebP format.");
      }
    }
  };

  const handleImageDelete = () => {
    setPreviewImage("");
    setValue("image", null);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (!data.image) {
        // Now, data.image should be the actual file
        toast.error("Please upload an image.");
        return;
      }

      const fileId = await uploadImagetoNhost(data.image); // Pass data.image directly
      const caseStudyData = {
        brd_id: brd_id,
        brd_services_id: data.service_id,
        image_id: fileId,
        slug: data.slug,
        title: title,
        title_color: data.title_color,
        bg_color: data.bg_color,
        description: description,
        detailed_description: content,
      };

      const response = await insertServicesCaseStudy({
        variables: { data: caseStudyData },
      });

      if (response?.data?.insert_services_casestudy?.affected_rows > 0) {
        toast.success("Service Added Successfully!");
        reset();
        setPreviewImage("");
        setTitle("");
        setDescription("");
        setContent("");
        await client.refetchQueries({ include: "all" });
      } else {
        toast.error("Error adding service, please try again.");
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full rounded-lg p-5 bg-secondary dark:bg-dark-secondary">
      <h1 className="text-xl text-primary dark:text-dark-primary mb-6">
        Add Case Study
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="text-primary dark:text-dark-primary flex-col"
      >
        <div className="flex flex-col space-y-4">
          <div className="grid grid-cols-2 gap-3">
            {/* <Autocomplete
              options={brands}
              getOptionLabel={(option: any) => option.name}
              fullWidth
              onChange={(event, newValue) => {
                setSelectedBrandId(newValue?.id || "");
                setValue("brd_id", newValue?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Brand"
                  InputLabelProps={{
                    className: "dark:text-dark-primary",
                  }}
                  variant="outlined"
                  fullWidth
                />
              )}
              className="dark:text-dark-primary"
            /> */}
            <Autocomplete
              options={data?.brd_services || []}
              getOptionLabel={(option: any) => option?.title}
              fullWidth
              onChange={(event, newValue) => {
                setSelectedCategoryId(newValue?.id || "");
                setValue("service_id", newValue?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Service"
                  InputLabelProps={{
                    className: "dark:text-dark-primary",
                  }}
                  variant="outlined"
                  fullWidth
                />
              )}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Title"
              type="text"
              fullWidth
              InputProps={{
                endAdornment: title && (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an alternative title for ${title} without quotes for Case Study.`
                        );
                        await typingEffect(setTitle, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-3 gap-3">
            <TextField
              label="Heading Color"
              variant="outlined"
              fullWidth
              {...register("title_color")}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Background Color"
              variant="outlined"
              helperText="Add opacity adjusted color"
              fullWidth
              {...register("bg_color")}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Slug"
              variant="outlined"
              fullWidth
              {...register("slug")}
              onChange={handleSlugChange}
              className="dark:text-dark-primary"
            />
          </div>
          <TextField
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            InputProps={{
              endAdornment: title && (
                <InputAdornment position="end">
                  <AutoFixHighIcon
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={async () => {
                      const res = await getAiData(
                        `Write a short description for case study as its title is "${title}" without qoutes and to the point.`
                      );
                      await typingEffect(setDescription, res);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <div className="col-span-6 sm:col-full ck-editor relative">
            <label htmlFor="" className="text-primary dark:text-dark-primary">
              Detail Content
            </label>
            <ContentEditor content={content} setContent={setContent} />
            {title && (
              <AutoFixHighIcon
                color="primary"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "100px",
                  right: "20px",
                }}
                onClick={async () => {
                  setLoading(true);
                  setContent("Generating...");
                  const res = await getAiData(
                    `Write best long and detailed content for my case study as its title is  "${title}": Proper formatted: with proper spacing and headings in inner HTML.`
                  );
                  setContent(res);
                  setLoading(false);
                  setContent(res);
                  // await typingEffect(setContent, res);
                }}
              />
            )}
          </div>
          <Button
            variant="outlined"
            component="label"
            className="dark:text-dark-primary w-[10%]"
          >
            Upload Image
            <input
              type="file"
              hidden
              {...register("image")}
              onChange={handleImageChange}
            />
          </Button>
          {previewImage && (
            <div className="mt-4 flex items-center">
              <img
                src={previewImage}
                alt="Preview"
                className="w-[300px] h-auto rounded mr-2" // Set a fixed width
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleImageDelete}
                className="ml-2"
              >
                Remove Image {/* Cross symbol for deletion */}
              </Button>
            </div>
          )}
          <div className="flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-[10%]"
            >
              {Loading ? "Adding..." : "Add"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCaseStudy;
