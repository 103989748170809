// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import { useMutation } from "@apollo/client";
// import { INSERT_BRD_SERVICES_CATEGORY_WITH_BRD_ID } from "~/graphql/brd_services_category/Mutation";
// import brandsStore from "~/store/brandsStore/brandsStore";
// import { toast } from "react-toastify";

// const AddCategory = () => {
//   const [insertCategory, { loading, error }] = useMutation(
//     INSERT_BRD_SERVICES_CATEGORY_WITH_BRD_ID
//   );
//   const { register, handleSubmit, setValue, reset } = useForm();
//   const [selectedBrandId, setSelectedBrandId] = useState("");
//   const { brands }: { brands: { id: string; name: string }[] } = brandsStore();

//   const onSubmit = async (data: any) => {
//     console.log("datadfjasdf", data);
//     const categoryData = {
//       brd_id: data.brd_id,
//       name: data.categoryName,
//     };

//     try {
//       await insertCategory({ variables: { data: categoryData } });
//       toast.success("Category added successfully!");
//       reset();
//     } catch (err) {
//       console.error("Error inserting category:", err);
//       toast.error("Error adding category: " + (err || "Please try again."));
//     }
//   };

//   return (
//     <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
//       <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
//         Add Category
//       </h1>
//       <form
//         onSubmit={handleSubmit(onSubmit)}
//         className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow"
//       >
//         <div className="flex flex-col space-y-4">
//           <Select
//             label="Select Brand"
//             fullWidth
//             value={selectedBrandId}
//             onChange={(e) => {
//               const brandId = e.target.value;
//               setSelectedBrandId(brandId);
//               setValue("brd_id", brandId);
//             }}
//             className="dark:text-dark-primary"
//           >
//             {brands.map((brand) => (
//               <MenuItem key={brand.id} value={brand.id}>
//                 {brand.name}
//               </MenuItem>
//             ))}
//           </Select>
//           <TextField
//             label="Category Name"
//             variant="outlined"
//             fullWidth
//             {...register("categoryName", { required: true })}
//             className="dark:text-dark-primary"
//           />

//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             disabled={loading}
//           >
//             {loading ? "Adding..." : "Add"}
//           </Button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AddCategory;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useApolloClient, useMutation } from "@apollo/client";
import { INSERT_BRD_SERVICES_CATEGORY_WITH_BRD_ID } from "~/graphql/brd_services_category/Mutation";
import brandsStore from "~/store/brandsStore/brandsStore";
import { toast } from "react-toastify";

const AddCategory = () => {
  const client = useApolloClient();
  const [insertCategory, { loading, error }] = useMutation(
    INSERT_BRD_SERVICES_CATEGORY_WITH_BRD_ID
  );
  const { register, handleSubmit, setValue, reset } = useForm();
  const [selectedBrandId, setSelectedBrandId] = useState<any>("");
  const { brands } = brandsStore();

  const onSubmit = async (data: any) => {
    const categoryData = {
      brd_id: data.brd_id,
      name: data.categoryName,
    };

    try {
      await insertCategory({ variables: { data: categoryData } });
      toast.success("Category added successfully!");
      await client.refetchQueries({
        include: "all",
      });
      reset({ brd_id: "", categoryName: "" });
    } catch (err) {
      console.error("Error inserting category:", err);
      toast.error("Error adding category: " + (err || "Please try again."));
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
        Add Category
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow"
      >
        <div className="flex flex-col space-y-4">
          <Select
            label="Select Brand"
            fullWidth
            {...register("brd_id", { required: true })}
            onChange={(e) => {
              const brandId = e.target.value;
              setSelectedBrandId(brandId);
              setValue("brd_id", brandId);
            }}
            className="dark:text-dark-primary"
          >
            {brands.map((brand: any) => (
              <MenuItem key={brand.id} value={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Category Name"
            variant="outlined"
            fullWidth
            {...register("categoryName", { required: true })}
            className="dark:text-dark-primary"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? "Adding..." : "Add"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCategory;
