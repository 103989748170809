import Layout from '~/components/common/Layout'
import AddServices from '~/modules/ServicesModule/segments/Services/components/AddServices'

const AddServicePage = () => {
  return (
    <div>
        <Layout>
            <AddServices />
        </Layout>
    </div>
  )
}

export default AddServicePage