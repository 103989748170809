import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { TextField } from "@mui/material";
import SentimentSatisfiedAltOutlinedIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useState } from "react";

const CreateGroup = ({ createGroupBar, setCreateGroupBar }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-whatsappPrimaryBg dark:bg-dark-primary transition-all ease-in-out duration-300 h-full flex flex-col ${
          createGroupBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div className="bg-whatsappBasic px-[23px] text-white text-[19px] pt-[60px]">
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setCreateGroupBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">New Group</p>
          </div>
        </div>
        <div className="px-[2rem] flex flex-col items-center justify-center gap-8 py-[1.75rem] bg-whatsappSecondaryBg dark:bg-dark-secondary">
          <div className="h-[180px] w-[180px] rounded-full bg-[#54656FCC] flex items-center justify-center cursor-pointer">
            <div className="w-[80px] flex flex-col  items-center justify-center text-whatsappSecondaryBg gap-2">
              <CameraAltOutlinedIcon />
              <p className="text-[13px] text-center">ADD GROUP ICON</p>
            </div>
          </div>
          <div className="w-full">
            <TextField
              fullWidth
              placeholder="Group Subject (Optional)"
              variant="standard"
              color="success"
            />
            <SentimentSatisfiedAltOutlinedIcon
              sx={{
                position: "absolute",
                right: "30px",
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center mt-10">
          <div className="p-[13px] text-white bg-whatsappBasic rounded-full cursor-pointer">
            <CheckOutlinedIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateGroup;
