import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import {
  DELETE_FAILED_CONV,
  DELETE_FAILED_IMPORT_CONV,
} from "~/graphql/brd_whatsapp_conversations/Mutation";
import { DELETE_FAILED_CONV_USERS } from "~/graphql/users/Mutation";

const DialogueBox = ({ open, setOpen }: any) => {
  const [deleteFailedConv] = useMutation(DELETE_FAILED_CONV);
  const [deleteFailedConvUsers] = useMutation(DELETE_FAILED_CONV_USERS);
  const [deleteFailedImportConv] = useMutation(DELETE_FAILED_IMPORT_CONV);

  const deleteConvOrUser = async () => {
    setOpen(false);
    const res = await deleteFailedConv();
    if (res?.data?.delete_brd_whatsapp_conversations?.affected_rows) {
      toast.success(
        `${res?.data?.delete_brd_whatsapp_conversations?.affected_rows} Conversations are Deleted `
      );
    }
    const res1 = await deleteFailedImportConv();
    const res2 = await deleteFailedConvUsers();
    if (res?.data?.deleteUsers?.affected_rows) {
      toast.success(
        `${res?.data?.deleteUsers?.affected_rows} Users are Deleted`
      );
    }

    if (
      !res?.data?.delete_brd_whatsapp_conversations?.affected_rows &&
      !res?.data?.deleteUsers?.affected_rows
    ) {
      toast.error("No Failed Messages");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are You Sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action will delete all conversations containing messages that
          have failed. Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ marginRight: "10px" }}
          onClick={() => setOpen(false)}
        >
          No
        </Button>
        <Button onClick={deleteConvOrUser} variant="outlined" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBox;
