import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import UpdatePlan from "./components/UpdatePlan";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const Plan = ({
  data,
  currSub,
  travelHouse,
  getCustomerPayDetail,
  paymentMeth,
  curPlan,
  index,
}: any) => {
  const [openMod, setOpenMod] = useState(false);

  return (
    <div
      className={`w-[350px]  shadow rounded justify-center px-10 py-7 duration-500 ease-in-out transition-all ${
        (travelHouse?.yearly === true &&
          currSub === "yearly" &&
          travelHouse?.plan_id === data.id) ||
        (travelHouse?.yearly === false &&
          currSub === "monthly" &&
          travelHouse?.plan_id === data.id)
          ? "bg-black/10"
          : "bg-white"
      }`}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <h1 className="font-bold text-2xl">{data.plan_name}</h1>
        <p className="text-5xl font-bold text-basic">
          £{currSub === "monthly" ? data.price_monthly : data.price_yearly}
        </p>
        <p>Per user, Per {currSub === "monthly" ? "month" : "year"}</p>
      </div>
      <div className="mt-7 flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">Unlimited landing pages</p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">Unlimited Customers</p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">Unlimited Inquiries</p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">Unlimited Vendors</p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">
            {currSub === "monthly"
              ? data.monthly_plan_brands
              : data.yearly_plan_brands}{" "}
            Brand Websites
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">Email Support</p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">
            {currSub === "monthly"
              ? data.monthly_plan_invoices
              : data.yearly_plan_invoices}{" "}
            Invoices
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">
            {currSub === "monthly"
              ? data.monthly_plan_seats
              : data.yearly_plan_seats}{" "}
            Seats
          </p>
        </div>

        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">Payroll</p>
        </div>
        <div className="flex gap-2 items-center">
          <CheckCircleIcon color="primary" />
          <p className="text-lg font-semibold">KPIs</p>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button
          onClick={() => {
            if (
              (travelHouse?.yearly === true &&
                currSub === "yearly" &&
                travelHouse?.plan_id === data.id) ||
              (travelHouse?.yearly === false &&
                currSub === "monthly" &&
                travelHouse?.plan_id === data.id)
            ) {
              setOpenMod(false);
            } else {
              setOpenMod(true);
            }
          }}
          variant="contained"
          sx={{ marginTop: "30px" }}
        >
          {index < curPlan ||
          (currSub === "monthly" && travelHouse?.yearly === true)
            ? "Downgrade Subscription"
            : index > curPlan ||
              (currSub === "yearly" && travelHouse?.yearly === false)
            ? "Upgrade Subscription"
            : "Current Plan"}
        </Button>
      </div>
      <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <UpdatePlan
            paymentMeth={paymentMeth}
            travelHouse={travelHouse}
            getCustomerPayDetail={getCustomerPayDetail}
            curretnPlan={data}
            currSub={currSub}
            setOpenMod={setOpenMod}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Plan;
