import React from "react";

const Card = ({ children }: any) => {
  return (
    <div className="w-full p-[15px] rounded border-gray-200 shadow-md">
      {children}
    </div>
  );
};

export default Card;
