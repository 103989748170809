import { gql } from "@apollo/client";

export const GET_DEF_EMAIL_TEMP = gql`
  query GetTemplates {
    def_emails_templates {
      subject
      content
      id
    }
  }
`;
