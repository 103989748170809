import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import useAccountList from "~/hooks/useAccountList";
import useAssignedUsers from "~/hooks/useAssignedUsers";
import useBrandPaymentMethodName from "~/hooks/useBrandPaymentMethodName";
import useBrdInqList from "~/hooks/useBrdInqList";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function createData({
  id,
  account,
  debit,
  credit,
  description,
  user,
  paymentMethodName,
  inquiryNo,
  action,
}: any) {
  return {
    id,
    account,
    debit,
    credit,
    description,
    user,
    paymentMethodName,
    inquiryNo,
    action,
  };
}

const TableRowComponent = ({
  ind,
  errors,
  register,
  control,
  brdId,
  watch,
  getValues,
  setCredit,
  setDebit,
  entries,
  setEntries,
  setValue,
}: any) => {
  const { accountList } = useAccountList();

  const { allUsers } = useAssignedUsers(brdId ? brdId : null);

  const { paymentMethodName } = useBrandPaymentMethodName(brdId ? brdId : null);

  const { inqsListWithBrdId } = useBrdInqList(brdId);

  const accountData = watch(`row.${ind}.account`);

  const row = [
    createData({
      id: ind + 1,
      account: (
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register(`row.${ind}.account`, {
            required: true,
          })}
          render={({ field }: any) => (
            <Autocomplete
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data?.id);
              }}
              options={accountList ? accountList : []}
              getOptionLabel={(option: any) => option?.name}
              renderOption={(props, option: any) => (
                <Box
                  sx={{ width: "100%", textAlign: "left" }}
                  component="li"
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Account"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={
                    errors.row && errors.row[`${ind}`]?.account ? true : false
                  }
                  {...params}
                  variant="standard"
                />
              )}
              noOptionsText=""
            />
          )}
        />
      ),
      debit: (
        <TextField
          error={errors.row && errors.row[`${ind}`]?.debit ? true : false}
          {...register(`row.${ind}.debit`, { required: true })}
          label="Debit"
          type="number"
          variant="standard"
        />
      ),
      credit: (
        <TextField
          error={errors.row && errors.row[`${ind}`]?.credit ? true : false}
          {...register(`row.${ind}.credit`, { required: true })}
          label="Credit"
          type="number"
          variant="standard"
        />
      ),
      description: (
        <TextField
          {...register(`row.${ind}.note`)}
          label="Note"
          variant="standard"
        />
      ),
      user: (
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register(`row.${ind}.user`, {
            required: accountData === 4 || accountData === 2 ? true : false,
          })}
          render={({ field }: any) => (
            <Autocomplete
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data?.id);
              }}
              options={allUsers ? allUsers : []}
              getOptionLabel={(option: any) => option?.displayName}
              renderOption={(props, option: any) => (
                <Box
                  sx={{ width: "100%", textAlign: "left" }}
                  component="li"
                  {...props}
                >
                  {option?.displayName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="User"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={
                    errors.row &&
                    errors.row[`${ind}`]?.user &&
                    (accountData === 4 || accountData === 2)
                      ? true
                      : false
                  }
                  {...params}
                  variant="standard"
                />
              )}
              noOptionsText=""
            />
          )}
        />
      ),
      paymentMethodName: (
        <Controller
          control={control}
          rules={{
            required: accountData === 4 || accountData === 2 ? false : true,
          }}
          {...register(`row.${ind}.paymentMethod`, {
            required: accountData === 4 || accountData === 2 ? false : true,
          })}
          render={({ field }: any) => (
            <Autocomplete
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                if (
                  ((accountData === 3 || accountData === 12) &&
                    (data?.type === "card" || data?.type === "stripe-thp")) ||
                  ((accountData === 13 || accountData === 14) &&
                    data?.type === "bank")
                ) {
                  field.onChange(null);
                } else {
                  field.onChange(data);
                }
              }}
              options={paymentMethodName ? paymentMethodName : []}
              getOptionLabel={(option: any) => option?.name}
              renderOption={(props, option: any) => (
                <Box
                  sx={{ width: "100%", textAlign: "left" }}
                  component="li"
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Payment Method Name"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={
                    errors.row && errors.row[`${ind}`]?.paymentMethod
                      ? true
                      : false
                  }
                  {...params}
                  variant="standard"
                />
              )}
              noOptionsText=""
            />
          )}
        />
      ),
      inquiryNo: (
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          {...register(`row.${ind}.inquiryNo`, { required: true })}
          render={({ field }: any) => (
            <Autocomplete
              {...field}
              disablePortal
              ref={field.ref}
              onChange={(_, data: any) => {
                field.onChange(data?.id);
              }}
              options={inqsListWithBrdId ? inqsListWithBrdId : []}
              getOptionLabel={(option: any) => option?.inquiry_no}
              renderOption={(props, option: any) => (
                <Box
                  sx={{ width: "100%", textAlign: "left" }}
                  component="li"
                  {...props}
                >
                  {option?.inquiry_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Inquiry No"
                  ref={field.ref}
                  inputRef={(el) => {
                    field.ref(el);
                  }}
                  error={
                    errors.row && errors.row[`${ind}`]?.inquiryNo ? true : false
                  }
                  {...params}
                  variant="standard"
                />
              )}
              noOptionsText=""
            />
          )}
        />
      ),
    }),
  ];

  const debitW = watch(`row.${ind}.debit`);
  const creditW = watch(`row.${ind}.credit`);

  const updateDebitCredit = () => {
    // ;
    setDebit(
      getValues("row")?.reduce(
        (total: any, entry: any) => total + parseFloat(entry.debit || 0),
        0
      )
    );
    setCredit(
      getValues("row")?.reduce(
        (total: any, entry: any) => total + parseFloat(entry.credit || 0),
        0
      )
    );
  };
  useEffect(() => {
    updateDebitCredit();
  }, [debitW, creditW]);

  useEffect(() => {
    setValue(`row.${ind}.credit`, 0);
    updateDebitCredit();
  }, [debitW]);

  useEffect(() => {
    setValue(`row.${ind}.debit`, 0);
    updateDebitCredit();
  }, [creditW]);

  const handleDeleteRow = (ind: any) => {
    let arr = [...entries];
    arr.splice(ind, 1);
    setEntries([...arr]);
  };

  return (
    <>
      {row.map((row) => (
        <TableRow key={ind}>
          <TableCell component="th" scope="row">
            {ind + 1}
          </TableCell>
          <TableCell sx={{ maxWidth: "130px" }}>{row.account}</TableCell>
          <TableCell sx={{ maxWidth: "30px" }}>{row.debit}</TableCell>
          <TableCell sx={{ maxWidth: "30px" }}>{row.credit}</TableCell>
          <TableCell sx={{ maxWidth: "130px" }}>{row.description}</TableCell>
          <TableCell sx={{ maxWidth: "130px" }}>{row.user}</TableCell>
          <TableCell sx={{ maxWidth: "130px" }}>
            {row.paymentMethodName}
          </TableCell>
          <TableCell sx={{ maxWidth: "130px" }}>{row.inquiryNo}</TableCell>
          {ind > 1 && (
            <TableCell sx={{ maxWidth: "30px" }}>
              <Button onClick={() => handleDeleteRow(ind)} variant="text">
                Remove
              </Button>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default TableRowComponent;
