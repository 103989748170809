import { gql } from "@apollo/client";

export const ADD_AD_UPDATE= gql`
  mutation MyMutation2($data: [brd_google_ad_update_insert_input!]!) {
    insert_brd_google_ad_update(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
