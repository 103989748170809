import { gql } from "@apollo/client";

export const GET_THP_USER_ATTENDANCE = gql`
  query getThpUserAttendance($userId: uuid!, $attendanceDate: date!) {
    thp_user_attendance(
      where: {
        user_id: { _eq: $userId }
        attendance_date: { _eq: $attendanceDate }
      }
    ) {
      current_breaktime
      out_time
      start_time
    }
  }
`;

export const GET_THP_USER_ATTENDANCE_USING_DATE = gql`
  query getThpUserAttendance($attendanceDate: date!) {
    thp_user_attendance(where: { attendance_date: { _eq: $attendanceDate } }) {
      current_breaktime
      out_time
      start_time
      user {
        displayName
      }
      breaks {
        break_time_minutes
        start_time
        end_time
      }
    }
  }
`;
