import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, TextField } from "@mui/material";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { INSERT_SERVICE_TESTIMONIALS } from "~/graphql/brd_services_testimonials/Mutation";

const AddTestimonial = ({ setSidePopup }: any) => {
  const { brd_id, service_id } = useParams();

  const { register, handleSubmit, setValue, reset } = useForm();

  const client = useApolloClient();

  const [insertTestimonials] = useMutation(INSERT_SERVICE_TESTIMONIALS);

  const onSubmit = async (data: any) => {
    try {
      const response = await insertTestimonials({
        variables: {
          data: {
            brd_id: brd_id,
            name: data.name,
            rating: data.rating,
            feedback: data.feedback,
            service_id: service_id,
          },
        },
      });

      if (response?.data?.insert_brd_services_testimonials?.affected_rows > 0) {
        toast.success("Testimonial submitted successfully!");

        reset();
        setSidePopup(false);
        await client.refetchQueries({
          include: "all",
        });
      }
    } catch (err) {
      toast.error("Error submitting testimonial. Please try again.");
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
        Add Testimonial
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow"
      >
        <div className="flex flex-col space-y-4">
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            {...register("name", { required: true })}
            className="dark:text-dark-primary"
          />
          <TextField
            label="Feedback"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            {...register("feedback", { required: true })}
            className="dark:text-dark-primary"
          />
          <TextField
            label="Rating"
            variant="outlined"
            fullWidth
            type="number"
            inputProps={{ min: 1, max: 5 }}
            {...register("rating", { required: true })}
            className="dark:text-dark-primary"
          />
          <Button type="submit" variant="contained" color="primary">
            Add
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTestimonial;
