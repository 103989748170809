import React, { useEffect, useState } from "react";
import CostRow from "../../components/PassengersCost/components/CostRow";
import CostRowAdd from "../../components/PassengersCost/components/CostRowAdd";

export default function NewPassengerEditCost({
  passengers,
  errors,
  register,
  control,
  setValue,
  suggestion_cost,
  watch,
  inquiry,
  type,
}: any) {
  const [passengerIdsArr1, setPassengerIdsArr1] = useState<string[]>([]);

  useEffect(() => {
    let ids: string[] = [];
    if (type === "Stay") {
      ids = suggestion_cost.map((obj: any) => obj.stay_passenger_id);
    } else if (type === "Cars") {
      ids = suggestion_cost.map((obj: any) => obj.car_passenger_id);
    } else if (type === "Train") {
      ids = suggestion_cost.map((obj: any) => obj.train_passenger_id);
    } else {
      ids = suggestion_cost.map((obj: any) => obj.passenger_id);
    }
    setPassengerIdsArr1(ids);
  }, [suggestion_cost, type]); // Dependencies to run the effect

  const filteredPassengers = passengers.filter(
    (obj: any) => !passengerIdsArr1.includes(obj.id)
  );

  return (
    <div>
      <div className="bg-gray-100 rounded-lg dark:bg-[#28243D] p-2 mt-4">
        <div className="flex flex-col border-b border-gray-300 w-full">
          {/* Section Heading */}
          <h1 className="flex-1 w-full font-bold text-primary dark:text-dark-primary text-left">
            Passenger Cost
          </h1>
        </div>
        <div className="mt-2">
          {suggestion_cost &&
            suggestion_cost.length > 0 &&
            suggestion_cost.map(
              (suggestion_cost_detail: any, index: number) => (
                <React.Fragment key={index}>
                  <CostRow
                    suggestion_cost_detail={suggestion_cost_detail}
                    register={register}
                    errors={errors}
                    index={index}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    inquiry={inquiry}
                    type={type}
                  />
                  <hr className="border-gray-300 border-dashed my-2" />
                </React.Fragment>
              )
            )}
        </div>
        <div className="mt-2">
          {filteredPassengers &&
            filteredPassengers.length > 0 &&
            filteredPassengers.map((passenger: any, index: number) => (
              <React.Fragment key={index}>
                <CostRowAdd
                  passenger={passenger}
                  register={register}
                  errors={errors}
                  index={index}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  type={type}
                />
                <hr className="border-gray-300 border-dashed my-2" />
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
