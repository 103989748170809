import { gql } from "@apollo/client";

export const INSERT_USER_CARD_DETAILS = gql`
  mutation MyMutation($objects: [user_card_details_insert_input!]!) {
    insert_user_card_details(objects: $objects) {
      returning {
        id
        name
        card_num
        billing_address
        cvv
        exp
        user_id
      }
    }
  }
`;
