import { URLs } from "~/config/enums";

export const emailSignatureTemp = (thpUserDetail: any, brd_detail: any) => {
  return `<br><br><br><br><br> <table
      style="
        width: 650px;
        margin: 50px auto;
        border-collapse: collapse;
        font-family: arial;
      "
    >
      <tbody>
        <tr style="vertical-align: top">
          <td style="padding: 5px 15px" width="100">
          <img
            style="width: 100px; height: 100px; border-radius: 100%; object-fit: cover"
            src=${URLs.FILE_URL + thpUserDetail?.users?.[0]?.avatarUrl}
            alt="Consultant Image"
          />
          </td>
          <td style="padding: 5px 15px">
            <p
              style="font-size: 16px; font-weight: bold; color: #000; margin: 0"
            >
              ${thpUserDetail?.users?.[0]?.thp_users_profile?.[0]?.pseudo_name}
            </p>
            <p style="font-size: 14px; color: #979797; margin: 0">
              ${thpUserDetail?.users?.[0]?.thp_users_profile?.[0]?.designation}
            </p>
            <table style="width: 100%; margin-top: 15px">
              <tr>
                <td style="padding: 5px 0"><b>Mobile Number:</b> ${
                  thpUserDetail?.users?.[0]?.phoneNumber
                }</td>
              </tr>
              <tr>
                <td style="padding: 5px 0"><b>Landline Number:</b> ${
                  thpUserDetail?.users?.[0]?.thp_users_profile?.[0]
                    ?.landline_number
                }</td>
              </tr>
               <tr>
                <td style="padding: 5px 0"><b>Email:</b> ${
                  thpUserDetail?.users?.[0]?.email
                }</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style="vertical-align: top">
          <td style="padding: 5px 15px">
            <img
              style="width: 100px;"
              src=${URLs.FILE_URL + brd_detail?.brd_details?.[0]?.logo}
              alt="Brand Logo"
            />
          </td>
          <td style="padding: 5px 15px">
           <p
              style="font-size: 16px; font-weight: bold; color: #000; margin: 0"
            >
              ${brd_detail?.name}
            </p>
            <p style="font-size: 14px; color: #979797; margin: 0">
              ${brd_detail?.brd_details?.[0]?.tagline}
            </p>
             <p style="font-size: 16px; line-height: 1.5; color: #000">
               ${brd_detail?.brd_contact_details?.[0]?.address1}<br />
               ${brd_detail?.brd_contact_details?.[0]?.country}
            </p>
              <table style="width: 100%; margin-top: 15px">
               <tr>
                <td style="padding: 5px 0"><b>Email:</b> ${
                  brd_detail?.brd_contact_details?.[0]?.email
                }</td>
              </tr>
            </table>
            <a
              style="
                background: #008fdc;
                border-radius: 60px;
                color: #fff;
                text-decoration: none;
                padding: 14px 35px;
                margin-right: 15px;
                display: inline-block;
              "
              href=${`https://www.${brd_detail?.domain}/`}
              >Visit Website</a
            >
            <p style="color: #979797; font-size: 16px; line-height: 1.5">
              This message is intended for the personal and CONFIDENTIAL use of
              the designated recipient(s) named above. If you are not the
              intended recipient of this message, you are hereby notified that
              any review, dissemination, distribution, or copying is strictly
              prohibited. If you have received this communication in error,
              please destroy all electronic and paper copies and notify the
              sender immediately. Erroneous transmission is not intended to
              waive confidentiality or privilege.
            </p>
          </td>
        </tr>
      </tbody>
    </table>`;
};
