import { useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { DELETE_ACC_TRANS } from "../../../../graphql/Mutation";
import { toast } from "react-toastify";
import moment from "moment";

export default function TransactionRow({
  transaction,
  compType,
  role,
  refetchTrans,
}: any) {
  const [deleteAccTrans] = useMutation(DELETE_ACC_TRANS);
  return (
    <tr className="">
      <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-dark-primary">
        <div className="inline-flex gap-x-3">
          <span>
            #{transaction?.transactions_seq || transaction?.transactions_no}
          </span>
        </div>
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          {transaction?.brd_list?.name}
        </td>
      )}
      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {moment(transaction?.date).zone(0).format("YYYY-MM-DD")}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500 capitalize dark:text-dark-primary">
        {transaction?.acc_list?.type} {transaction?.def_acc_list?.type}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.acc_list?.name} {transaction?.def_acc_list?.name}
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          {(
            <Link
              to={`../inquiry/${transaction?.inq_list?.inquiry_no}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transaction?.inq_list?.inquiry_no}
            </Link>
          ) || (
            <i className="text-[#ff9b00] text-[12px] dark:text-dark-primary">
              Not linked
            </i>
          )}
        </td>
      )}

      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.users?.defaultRole === "vendor" ? (
          transaction?.inq_ticket?.ibe
        ) : (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.acc_payment_method?.name || (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          {transaction?.users?.displayName || (
            <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">
              -
            </i>
          )}
        </td>
      )}

      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.note || (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>

      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.detailed_desc || (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      {/* <div className="inline-flex  px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 ">
          <h2 className="text-sm font-normal">{transaction?.type}</h2>
        </div> */}
      <td className="px-4 py-4 text-sm dark:text-dark-primary">
        {transaction?.type === "debit" ? (
          transaction?.amount
        ) : (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm dark:text-dark-primary">
        {transaction?.type === "credit" ? (
          -transaction?.amount
        ) : (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500  dark:text-dark-primary">
          {transaction?.inq_list?.picked_user?.thp_users_profile?.[0]
            ?.pseudo_name || (
            <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">
              -
            </i>
          )}
        </td>
      )}
      {compType === "inquirSection" && role === "admin" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          <DeleteIcon
            onClick={async () => {
              const res = await deleteAccTrans({
                variables: {
                  id: transaction?.id,
                },
              });

              refetchTrans();

              if (res?.data?.delete_acc_transactions?.returning?.[0]?.id) {
                toast.success("Transaction Deleted Successfully");
              }
            }}
            sx={{ color: "#9254F8", cursor: "pointer" }}
          />
        </td>
      )}
    </tr>
  );
}
