import React from "react";
import Layout from "~/components/common/Layout";
import ProfileAccountModule from "~/modules/ProfileAccountModule/";
import ProfileAccComp from "~/modules/ProfileAccountModule/segments/ProfileAccComp";

export default function ProfileAccountPage() {
  return (
    <div>
      <Layout>
        <ProfileAccountModule>
          <ProfileAccComp />
        </ProfileAccountModule>
      </Layout>
    </div>
  );
}
