import CommunityComp from "./component/CommunityComp";

const CommunityBar = ({ showCommunityBar, setShowCommunityBar }: any) => {
  return (
    <>
      {showCommunityBar && (
        <CommunityComp
          showCommunityBar={showCommunityBar}
          setShowCommunityBar={setShowCommunityBar}
        />
      )}
    </>
  );
};

export default CommunityBar;
