import { gql } from "@apollo/client";

export const SEND_EMAIL = gql`
  mutation sendGridEmail(
    $body: String!
    $emails: String
    $subject: String
    $from: String
  ) {
    sendGridEmail(
      arg1: { body: $body, emails: $emails, subject: $subject, from: $from }
    ) {
      status
    }
  }
`;

export const SEND_WHATSAPP_CHAT = gql`
  mutation MyMutation($data: [brd_whatsapp_chat_insert_input!]!) {
    insert_brd_whatsapp_chat(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation MyMutation($data: [brd_notifications_insert_input!]!) {
    insert_brd_notifications(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
