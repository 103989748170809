import { gql } from "@apollo/client";

export const GET_UNREAD_COUNT = gql`
  subscription MySubscription(
    $conversation_id: uuid
    $last_view_time: timestamptz
  ) {
    brd_gmail_messages_aggregate(
      where: {
        timestamp: { _gt: $last_view_time }
        conversation_id: { _eq: $conversation_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
