const DetailRow = ({ phone, name }: any) => {
  return (
    <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer">
      <div className="py-[13px] px-[20px] border-b dark:border-dark-secondary w-full">
        <div className="flex items-center justify-between">
          <h1 className="text-[16px] text-black dark:text-white">
            {`${name ? `${name} - ${phone}` : phone}`}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default DetailRow;
