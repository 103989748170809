import { gql } from "@apollo/client";

export const GET_FINGERPRINT_LOGS = gql`
  query getFingerPrintLogs($userId: uuid!) {
    user_fingerprint_ids(where: { user_id: { _eq: $userId } }) {
      id
      fingerprint_id
      user_id
      status
    }
  }
`;
