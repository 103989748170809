import { gql } from "@apollo/client";

export const INSERT_EMAIL_CAMP_CONVERSATION = gql`
  mutation MyMutation($data: [brd_email_campaigns_insert_input!]!) {
    insert_brd_email_campaigns(objects: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_EMAIL_CAMP_CONVERSATION = gql`
  mutation MyMutation(
    $iata: String
    $brdID: uuid
    $data: brd_email_campaigns_set_input
  ) {
    update_brd_email_campaigns(
      where: { iata: { _eq: $iata }, brd_id: { _eq: $brdID } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;
