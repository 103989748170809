import React from "react";
import Pie from "../Pie";

export default function Flags({ inquiries }: any) {
  const uniqueTagSet: any = new Set(
    inquiries?.map((item: any) => item?.thp_inquiry_flag?.id || null)
  );

  const uniqueTag: any[] = [...uniqueTagSet];

  const dataSet: any = [];
  let totalCount: number = 0;

  uniqueTag.map((label: any, index: any) => {
    const count = inquiries?.filter(
      (inq: any) => inq?.thp_inquiry_flag?.id === label
    )?.length;
    totalCount += count;
    const name = inquiries?.filter(
      (inq: any) => inq?.thp_inquiry_flag?.id === label
    )[0]?.thp_inquiry_flag?.name;

    dataSet.push({
      label: `${count} - ${name}`,
      value: count,
    });
  });

  dataSet.sort(function (a: any, b: any) {
    return b.value - a.value;
  });

  return (
    <>
      <div className="pt-4 w-full border-b-[1px] border-[#ccc] flex justify-between">
        <h2>Flags - {totalCount}</h2>
      </div>
      {dataSet.length > 0 ? (
        <Pie data={dataSet} />
      ) : (
        <div className="w-full flex items-center justify-center h-[100%]">
          <span>No record found!</span>
        </div>
      )}
    </>
  );
}
