import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
// Import types
import { ActionTypes, StateType } from "./travelHouseTypes";
// Import all queries

const travelHouseStore = create<StateType & ActionTypes>()(
  persist(
    (set, get) => ({
      travelHouse: {},
      setTravelHouse: (travelHouse: any) =>
        set((state: any) => ({ travelHouse: travelHouse })),
    }),
    {
      name: "travel-house",
      storage: createJSONStorage(() => sessionStorage),
      onRehydrateStorage: () => (state: any) => {
        if (typeof state !== "undefined") {
          state.setHasHydrated(true);
        }
      },
    }
  )
);

export default travelHouseStore;
