import React, { useEffect, useRef, useState } from "react";
import { useUserData, useUserId } from "@nhost/react";
import UserCard from "~/components/UserCard";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  FETCH_UPDATE,
  GET_CONVERSATION_DETAIL,
  GET_CONVERSATION_MESSAGES,
  GET_LABEL_MESSAGES,
  GET_MESSAGES,
  UPDATE_CONVERSATION,
} from "./graphql/Query";
// import { useOptimistic } from "@nhost/react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import SingleMessage from "./components/SingleMessage";
import moment from "moment";
import DotLoader from "~/components/common/DotLoader";
import { MdSend } from "react-icons/md";
import { useForm } from "react-hook-form";
import { SEND_WHATSAPP_MESSAGE } from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/Query";
import { INSERT_MESSAGES, SEND_READ_STATUS } from "./graphql/Mutation";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import WhatsappSend from "~/components/SideForm/WhatsappSend";
import SidePopup from "~/components/common/SidePopup";
import Header from "./components/Header";
// import Messages from "./components/Messages";
// import Footer from "./components/Footer";
import PopUpModal from "./components/PopUpModal";
import FilesUpload from "./components/FilesUpload";
import ChatBoxSekeleton from "./components/Sekeleton/ChatBoxSekeleton";
import AttachemnetPrevSend from "./components/AttachemnetPrevSend";
import { GET_COMMUNITY } from "~/graphql/brd_communities/Subscription";
import Footer from "../ChatWindow/components/Footer";
import SelectTemplate from "../ChatWindow/components/SelectTemplate";
import Messages from "../ChatWindow/components/Messages";
// import { GET_LABEL } from "~/graphql/brd_whatsapp_communities/Subscription";

export default function CommunityWindow({
  conversation_id,
  setShowMessageInfoBar,
  setShowUserInfo,
}: any) {
  const [messages, setMessages] = useState<any>([]);
  const [activeId, setActiveId] = useState(generateRandom10DigitCode());
  const [messageData, setMessageData] = useState("");
  const [user_phone, setUserPhone] = useState("");
  const [brand_phone, setBrandPhone] = useState("");
  const [brand_token, setBrandToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { handleSubmit } = useForm();

  const [showEmojis, setShowEmojis] = useState(false);

  const [files, setFiles] = useState<any>([]);
  const [attachmentType, setAttachmentType] = useState(null);

  const [isSending, setIsSending] = useState<any>(false);

  const {
    loading: cl,
    data: cd,
    error: ce,
  } = useSubscription(GET_COMMUNITY, {
    variables: { id: conversation_id },
  });

  const client = useApolloClient();
  const userId = useUserId();

  // Get the current time in UTC
  const currentTime = moment().utc();

  // Format the current time in the desired format
  const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
  const [timestamp, setTimestamp] = useState<any>(formattedTime);

  const [update] = useMutation(UPDATE_CONVERSATION);
  // const [updateMessage] = useMutation(UPDATE_CONVERSATION_MESSAGE);
  const [insertMessages] = useMutation(INSERT_MESSAGES);

  const chatContainerRef: any = useRef(null);

  // formRef

  // const { loading, error, data } = useSubscription(GET_LABEL_MESSAGES, {
  //   variables: {
  //     conversation_id,
  //   },
  // });

  // const {
  //   loading: lf,
  //   error: ef,
  //   data: df,
  // } = useSubscription(FETCH_UPDATE, {
  //   variables: {
  //     conversation_id: conversation_id,
  //     timestamp: timestamp,
  //   },
  // });

  useEffect(() => {
    if (cd?.brd_communities?.length > 0) {
      setBrandToken(cd?.brd_communities[0]?.brd_list?.whatsapp_token);
      setBrandPhone(cd?.brd_communities[0]?.brd_list?.phoneNumberId);
      setUserPhone("");
    }
  }, [cd]);

  // const

  // if (loading) return <ChatBoxSekeleton />;
  // if (error) return <p>ERR: {error.message}</p>;

  if (cl) return <ChatBoxSekeleton />;
  if (ce) return <p>{ce.message}</p>;

  return (
    <>
      {/* Right */}
      <div className="w-full flex flex-col h-full">
        <Header
          conversation={cd.brd_communities[0]}
          setShowUserInfo={setShowUserInfo}
        />
        {files.length > 0 ? (
          <>
            <div className="flex-grow bg-whatsappPrimaryBg dark:bg-dark-secondary">
              <AttachemnetPrevSend
                attachmentType={attachmentType}
                setFiles={setFiles}
                files={files}
                setShowEmojis={setShowEmojis}
                showEmojis={showEmojis}
              />
            </div>
          </>
        ) : (
          <>
            {showModal ? (
              <div className="flex-grow bg-whatsappPrimaryBg dark:bg-dark-secondary">
                <PopUpModal
                  setShowModal={setShowModal}
                  showModal={setShowModal}
                >
                  <SelectTemplate
                    setAttachmentType={setAttachmentType}
                    setShowModal={setShowModal}
                    community={true}
                    brdId={[cd.brd_communities[0].brd_id]}
                  />
                </PopUpModal>
              </div>
            ) : (
              <>
                <Messages
                  setShowMessageInfoBar={setShowMessageInfoBar}
                  messages={cd.brd_communities[0].brd_community_messages}
                />
                <Footer
                  setShowEmojis={setShowEmojis}
                  showEmojis={showEmojis}
                  setShowModal={setShowModal}
                  setFiles={setFiles}
                  files={files}
                  attachmentType={attachmentType}
                  setAttachmentType={setAttachmentType}
                  community={true}
                />
                {/* <Footer
                  setShowEmojis={setShowEmojis}
                  showEmojis={showEmojis}
                  setShowModal={setShowModal}
                  setFiles={setFiles}
                  files={files}
                  label_id={conversation_id}
                  attachmentType={attachmentType}
                  setAttachmentType={setAttachmentType}
                  brand_phone={
                    cd.brd_whatsapp_communities[0].brd_list?.phoneNumberId
                  }
                  users={
                    cd.brd_whatsapp_communities[0]?.brd_whatsapp_community_users
                  }
                /> */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
