import DocMessage from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Messages/component/DocMessage";
import ImageMessage from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Messages/component/ImageMessage";
import TextMessage from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Messages/component/TextMessage";
import VoiceMessage from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Messages/component/VoiceMessage";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation } from "@apollo/client";
import { WHATASPP_SETTING_DELETE } from "~/graphql/brd_whatsapp_setting/Mutation";

const Message = ({ message }: any) => {
  const [deleteWhatsappAutoReply] = useMutation(WHATASPP_SETTING_DELETE);

  const deleteMessage = async () => {
    await deleteWhatsappAutoReply({
      variables: {
        id: message.id,
      },
    });
  };

  return (
    <>
      {message?.auto_reply_type === "text" ? (
        <div className="flex items-center relative">
          <TextMessage
            message={{
              text: message.auto_reply,
            }}
            messageType="autoReply"
          />
          <CancelIcon
            onClick={deleteMessage}
            fontSize="small"
            className="text-[5px] text-whatsappBasic/50 cursor-pointer hover:text-green-600 absolute right-5"
          />
        </div>
      ) : message?.auto_reply_type === "audio" ? (
        <div className="flex items-center mr-[110px] relative">
          <CancelIcon
            onClick={deleteMessage}
            fontSize="small"
            className="text-[5px] text-whatsappBasic/50 cursor-pointer hover:text-green-600 absolute right-[-90px] z-30"
          />
          <VoiceMessage
            message={{
              text: message.auto_reply,
            }}
            messageType="autoReply"
          />
        </div>
      ) : message?.auto_reply_type === "image" ? (
        <div className="flex items-center relative">
          <CancelIcon
            onClick={deleteMessage}
            fontSize="small"
            className="text-[5px] text-whatsappBasic/50 cursor-pointer hover:text-green-600 absolute right-5"
          />
          <ImageMessage
            message={{
              text: message.auto_reply,
            }}
            messageType="autoReply"
          />
        </div>
      ) : message?.auto_reply_type === "video" ? (
        <div className="flex items-center relative">
          <CancelIcon
            onClick={deleteMessage}
            fontSize="small"
            className="text-[5px] text-whatsappBasic/50 cursor-pointer hover:text-green-600 absolute right-5"
          />
          <ImageMessage
            message={{
              text: message.auto_reply,
            }}
            video={true}
            messageType="autoReply"
          />
        </div>
      ) : (
        message?.auto_reply_type === "document" && (
          <div className="flex items-center relative">
            <CancelIcon
              onClick={deleteMessage}
              fontSize="small"
              className="text-[5px] text-whatsappBasic/50 cursor-pointer hover:text-green-600 absolute right-5"
            />
            <DocMessage
              message={{
                text: message.auto_reply,
              }}
              video={true}
              messageType="autoReply"
            />
          </div>
        )
      )}
    </>
  );
};

export default Message;
