import { gql } from "@apollo/client";

export const INSERT_SEO = gql`
  mutation InsertSeoSettings($data: brd_seo_setting_insert_input!) {
    insert_brd_seo_setting_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_SEO = gql`
  mutation UpdateSeo(
    $id: brd_seo_setting_pk_columns_input!
    $data: brd_seo_setting_set_input!
  ) {
    update_brd_seo_setting_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;
