import { useQuery } from "@apollo/client";
import { GET_BRD_SEASONS } from "~/graphql/brd_seasons/Query";

export default function useBrdSeasons(brdId: any) {
  const { data, refetch } = useQuery(GET_BRD_SEASONS, {
    variables: {
      brdId: brdId,
    },
    skip: !brdId,
  });

  return {
    refetch,
    brdSeasons: data?.brd_seasons || [],
  };
}
