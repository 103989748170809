import { useQuery } from "@apollo/client";
import WAMessenger from "./segments/WAMessenger";
import { GET_BRAND } from "./graphql/Query";
import { useParams } from "react-router-dom";
import { GET_USER_LAST_ACTIVITY } from "~/components/common/header/graphql/Query";
import { useUserDefaultRole, useUserId } from "@nhost/react";

function BrandModule() {
  const { brd_id } = useParams();
  // useUserDefaultRole();
  const userId = useUserId();
  const { loading, data, error } = useQuery(GET_BRAND, {
    variables: { brd_id: brd_id },
  });
  const {
    loading: ll,
    data: ld,
    error: le,
  } = useQuery(GET_USER_LAST_ACTIVITY, {
    variables: { id: userId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>errro...${error.message}</p>;

  if (ll) return <p>Loading...</p>;
  if (le) return <p>errro...${le.message}</p>;
  return (
    <div>
      <WAMessenger
        brand={data?.brd_list?.[0]}
        lastTime={data?.def_user_activity?.[0]?.timestamp}
      />
    </div>
  );
}

export default BrandModule;
