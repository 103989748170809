import { gql } from "@apollo/client";

export const GET_TICKET = gql`
  subscription GetTicket($inquiry_no: String!) {
    inq_list(where: { inquiry_no: { _eq: $inquiry_no } }) {
      inq_tickets {
        cancelation_charges
        ibe
        id
        inq_id
        pnr
        refundable
        status
        updated_at
        url
        vendor_id
        void_time
        created_at
        ticket_cost_price
        ticket_attol_price
        ticket_mis_cost
        vendor {
          displayName
        }

        inq_ticket_files {
          id
          file_url
          inq_passengers {
            first_name
            last_name
          }
        }
      }
      stay_inq_details {
        stay_inq_tickets {
          id
          stay_inq_id
          refundable
          status
          updated_at
          vendor_id
          void_time
          created_at
          ticket_cost_price
          ticket_attol_price
          vendor {
            displayName
          }
          stay_inq_ticket_files {
            id
            file_url
            stay_inq_room {
              room_number
              no_of_days
              booked_by
              stay_inq_id
              id
              created_at
              updated_at
            }
          }
        }
      }
      car_inq_details {
        car_inq_tickets {
          id
          car_inq_id
          refundable
          status
          updated_at
          vendor_id
          void_time
          created_at
          ticket_cost_price
          ticket_attol_price
          vendor {
            displayName
          }
          car_inq_ticket_files {
            id
            file_url
            car_inq_passenger {
              first_name
              last_name
            }
          }
        }
      }
      train_inq_details {
        train_inq_tickets {
          id
          train_inq_id
          refundable
          status
          updated_at
          vendor_id
          void_time
          created_at
          ticket_cost_price
          ticket_attol_price
          vendor {
            displayName
          }
          train_inq_ticket_files {
            id
            file_url
            train_inq_passenger {
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;
