import { Checkbox, FormControlLabel, Box } from "@mui/material";
import { styled } from "@mui/system";

const ActiveLabel = styled(FormControlLabel)(
  ({ isActive }: { isActive: boolean }) => ({
    borderRadius: "4px",
    padding: "5px 10px",
    margin: "5px",
    transition: "background-color 0.3s",
  })
);


const InquirySelector = ({ options, selectedOptions, onOptionChange, defaultTabs=[]}: any) => {

  const defaultTabLabels = defaultTabs.map((tab: any) => tab.label);
  const filteredOptions = options.filter(
    (option: any) => !defaultTabLabels.includes(option.name)
  );
  
  return (
    <Box display="flex" flexDirection="row" gap="10px">
      {filteredOptions.map((option: any) => (
        <ActiveLabel
          key={option.name}
          isActive={selectedOptions.includes(option.name)}
          control={
            <Checkbox
              checked={selectedOptions.includes(option.name)}
              onChange={onOptionChange}
              value={option.name}
            />
          }
          label={option.name}
        />
      ))}
    </Box>
  );
};

export default InquirySelector;
