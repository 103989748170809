import { gql } from "@apollo/client";

export const UPDATE_WHATSAPP_MESSAGE_STATUS = gql`
  mutation MyMutation(
    $id: uuid
    $data: brd_whatsapp_messages_status_set_input!
  ) {
    update_brd_whatsapp_messages_status(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;
