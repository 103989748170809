import { gql } from "@apollo/client";

export const GET_BRAND_TYPES = gql`
  query getBrandTypes {
    def_brand_types {
      id
      name
    }
  }
`;
