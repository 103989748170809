import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, Modal, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_BRD_SEASONAL_FARES } from "~/graphql/brd_seasonal_fares/Query";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddSeasFares from "./components/AddSeasFares";
import AddSeasons from "./components/AddSeasons";
import SeasonsTable from "./components/SeasonsTable";
import SeasonalFaresTable from "./components/SeasonalFaresTable";
import { CSVLink } from "react-csv";
import useBrdSeasons from "~/hooks/useBrdSeasons";
import Papa from "papaparse";
import { ADD_BRD_SEAS_FARES } from "~/graphql/brd_seasonal_fares/Mutation";
import { toast } from "react-toastify";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SeasonalFares = () => {
  const [openMod, setOpenMod] = useState(false);
  const [openSeasMod, setOpenSeasMod] = useState(false);

  const { brd_id } = useParams();

  const { brdSeasons, refetch } = useBrdSeasons(brd_id);

  const [addBrdSeasFares] = useMutation(ADD_BRD_SEAS_FARES);

  const { data: brdSeasonalFares, refetch: reftechBrdSeasFares } = useQuery(
    GET_BRD_SEASONAL_FARES,
    {
      variables: {
        brdId: brd_id,
      },
    }
  );

  const headers = [
    { label: "Type", key: "type" },
    { label: "Destination", key: "destination" },
    { label: "Departure", key: "departure" },
    { label: "Airline", key: "airline" },
    { label: "Fares", key: "fares" },
    { label: "Stops", key: "stops" },
    { label: "SeasonId", key: "seasonId" },
    {
      label: `Allowed ids {${brdSeasons
        ?.map((item: any) => `${item.int_id}-(${item.name})`)
        .join(", ")}}`,
      key: "allowedIds",
    },
  ];

  const data: any = [
    {
      type: "oneWay",
      destination: "DXB",
      departure: "LHR",
      airline: "PIA",
      fares: 400,
      seasonId: 5,
      stops: 5,
    },
    {
      type: "roundTrip",
      destination: "DXB",
      departure: "LHR",
      airline: "PIA",
      fares: 500,
      seasonId: 3,
      stops: 3,
    },
  ];

  const handleFileUpload = (e: any) => {
    const file1 = e.target.files[0];

    Papa.parse(file1, {
      header: true,
      complete: async (results) => {
        const payload = [
          ...results?.data?.map((item: any) => ({
            brd_season_id: item.SeasonId,
            destination: item.Destination,
            departure: item.Departure,
            fares: parseFloat(item.Fares),
            airline: item.Airline,
            type: item.Type,
            stops: item.Stops,
            brd_id,
          })),
        ];
        const res = await addBrdSeasFares({
          variables: {
            data: payload,
          },
        });

        if (res?.data?.insert_brd_seasonal_fares?.affected_rows) {
          toast.success(
            `${res?.data?.insert_brd_seasonal_fares?.affected_rows} Seasonal Fares Added SuccessFully`
          );
          reftechBrdSeasFares();
        } else {
          toast.error("File is not Correct");
        }
      },
    });
  };

  return (
    <>
      <div className="w-full text-primary dark:text-dark-primary flex justify-between">
        <h1 className="font-bold pl-4 pt-4">Seasons / Seasonal Fares</h1>
        <div className="flex items-center gap-5">
          <CSVLink
            data={data}
            headers={headers}
            filename={"faresSample.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            <Button variant="text">Download Sample</Button>
          </CSVLink>
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload CSV file
            <VisuallyHiddenInput
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
            />
          </Button>
          <Button onClick={() => setOpenSeasMod(true)} variant="contained">
            Add Seasons
          </Button>
          <Button onClick={() => setOpenMod(true)} variant="contained">
            Add New Fares
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-10 mt-10">
        <SeasonsTable brdSeasons={brdSeasons} refetch={refetch} />
        <SeasonalFaresTable
          seasonalFares={brdSeasonalFares?.brd_seasonal_fares}
          reftechBrdSeasFares={reftechBrdSeasFares}
        />
      </div>
      <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <AddSeasFares
            setOpenMod={setOpenMod}
            refetchFares={reftechBrdSeasFares}
          />
        </Box>
      </Modal>
      <Modal open={openSeasMod} onClose={() => setOpenSeasMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <AddSeasons setOpenSeasMod={setOpenSeasMod} refetch={refetch} />
        </Box>
      </Modal>
    </>
  );
};

export default SeasonalFares;
