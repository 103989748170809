import { useQuery, useSubscription } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { GET_CONSULTANTS, GET_INQ_FLAGS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useEffect } from "react";

const Consultantfilter = ({
  setSelectedConsultant,
  unset = false,
  selectedConsultant,
}: any) => {
  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_CONSULTANTS, {
    variables: {
      thp_id: travelHouse?.id,
    },
  });

  const handleFlagChange = (event: any, newValue: any) => {
    setSelectedConsultant(newValue);
  };

  if (loading) return <>Loading..</>;
  if (error) return <>Err..${error.message}</>;

  const unsetObj = { id: null, displayName: "unset" };
  let options = [...data?.users];
  if (unset) {
    options = [unsetObj, ...data?.users];
  }
  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        onChange={handleFlagChange}
        getOptionLabel={(option: any) =>
          option?.thp_users_profile?.[0]?.pseudo_name
            ? `${option?.thp_users_profile?.[0]?.pseudo_name} (${option?.defaultRole})`
            : `${option?.displayName} (${option?.defaultRole})`
        }
        sx={{ width: "100%" }}
        value={selectedConsultant}
        renderInput={(params) => <TextField {...params} label="Consultants" />}
      />
    </>
  );
};

export default Consultantfilter;
