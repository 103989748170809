import { gql } from "@apollo/client";

export const UPDATE_TESTIMONIAL = gql`
  mutation MyMutation(
    $id: brd_testimonials_pk_columns_input!
    $tsData: brd_testimonials_set_input!
  ) {
    update_brd_testimonials_by_pk(pk_columns: $id, _set: $tsData) {
      id
    }
  }
`;
