import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_ALL_USER, GET_USER } from "~/graphql/users/Query";

export default function useAssignedUsers(brd_id: any) {
  const { data: users } = useQuery(GET_USER, {
    variables: {
      brd_id: brd_id,
      role: "user",
    },
    skip: !brd_id,
  });

  const { data: admin } = useQuery(GET_USER, {
    variables: {
      brd_id: brd_id,
      role: "admin",
    },
    skip: !brd_id,
  });

  const { data: vendor } = useQuery(GET_USER, {
    variables: {
      brd_id: brd_id,
      role: "vendor",
    },
    skip: !brd_id,
  });

  const { data: allUsers } = useQuery(GET_ALL_USER, {
    variables: {
      brd_id: brd_id,
    },
    skip: !brd_id,
  });

  // ;

  return {
    users: users?.users,
    admins: admin?.users,
    allUsers: allUsers?.users,
    vendors: vendor?.users,
  };
}
