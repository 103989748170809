import Layout from "~/components/common/Layout";
import DefTablesModule from "~/modules/DefTablesModule";
import DefPlanCredits from "~/modules/DefTablesModule/segments/DefPlanCredits/index";

export default function DefPlanCreditsPage() {
  return (
    <div>
      <Layout>
        <DefTablesModule>
          <DefPlanCredits />
        </DefTablesModule>
      </Layout>
    </div>
  );
}
