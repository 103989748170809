import SendIcon from "@mui/icons-material/Send";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import Audio from "./Audio";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";

const Recording = ({ setShowRecording }: any) => {
  const [showAudio, setShowAudio] = useState(false);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex items-center text-primary dark:text-dark-primary z-10 justify-end gap-5">
      <DeleteIcon
        sx={{
          color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
          cursor: "pointer",
          fontSize: "30px",
        }}
        onClick={() => setShowRecording(false)}
      />
      {showAudio ? (
        <>
          <Audio />
          <MicOutlinedIcon
            sx={{ color: "#ff3b30", cursor: "pointer", fontSize: "30px" }}
            onClick={() => setShowAudio(false)}
          />
        </>
      ) : (
        <>
          <h1 className="text-primary text-[20px] font-medium dark:text-dark-primary">
            1:15
          </h1>
          <div className="w-[180px] border-[#8696a0] border-t-2 border-dotted"></div>
          <div className="p-[3px] text-[#ff3b30] border-[#ff3b30] border-2 rounded-full cursor-pointer flex items-center justify-center">
            <PauseOutlinedIcon onClick={() => setShowAudio(true)} />
          </div>
        </>
      )}

      <div className="p-[7px] text-white bg-whatsappBasic rounded-full cursor-pointer flex items-center justify-center">
        <SendIcon />
      </div>
    </div>
  );
};

export default Recording;
