import React from "react";
import Layout from "~/components/common/Layout";
import AccountModule from "~/modules/AccountModule";
import TransactionsList from "~/modules/AccountModule/segments/TransactionsList";

export default function TransactionsPage() {
  return (
    <div>
      <Layout>
        <AccountModule>
          <TransactionsList />
        </AccountModule>
      </Layout>
    </div>
  );
}
