import { gql } from "@apollo/client";

export const EDIT_PAY = gql`
  mutation MyMutation($id: uuid, $data: inq_transection_set_input!) {
    update_inq_transection(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
