import { useQuery, useSubscription } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Chip } from "@mui/material";
import { useState } from "react";
import DetailRow from "./component/DetailRow";
import { GET_CONVERSATION_DETAIL } from "../ChatWindow/graphql/Query";
import { GET_COMMUNITY } from "~/graphql/brd_communities/Subscription";
import { Link } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";

const WhatsappUserInfoBar = ({
  showUserInfo,
  setShowUserInfo,
  conversation_id,
}: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const {
    loading: cl,
    data: cd,
    error: ce,
  } = useQuery(GET_CONVERSATION_DETAIL, {
    variables: { id: conversation_id },
  });

  const {
    loading: wcl,
    data: wcd,
    error: wce,
  } = useSubscription(GET_COMMUNITY, {
    variables: { id: conversation_id },
  });

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const userRole = useUserDefaultRole();

  console.log("jsdhfjd", cd);

  return (
    <div
      className={`flex flex-col h-full transition-all ease-in-out duration-300 ${
        showUserInfo ? "w-full" : "w-0"
      }`}
    >
      {showUserInfo && (
        <>
          <div className="flex items-center text-black dark:text-white px-[25px] text-[16px] font-medium py-[15px]">
            <CloseIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowUserInfo(false);
              }}
            />
            <p className="ml-5">Contact info</p>
          </div>
          <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary p-[30px] flex flex-col items-center justify-center">
            <Avatar
              sx={{ width: "150px", height: "150px", marginBottom: "10px" }}
              alt="whatsappAvator"
              src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
            />
            <p className="text-[25px] text-black dark:text-white mt-1">
              {cd?.brd_whatsapp_conversations?.[0]?.whatsapp_name ||
                wcd?.brd_communities?.[0]?.name}
            </p>
          </div>
          {cd?.brd_whatsapp_conversations?.[0]?.whatsapp_name && (
            <div className="flex items-center gap-3 p-3 pt-0 bg-whatsappSecondaryBg dark:bg-dark-secondary">
              <Link
                to={`/${userRole}/brands/${cd?.brd_whatsapp_conversations?.[0]?.brd_list?.id}/customer/${cd?.brd_whatsapp_conversations?.[0]?.user?.id}`}
              >
                <Chip
                  sx={{ cursor: "pointer" }}
                  label="Activity"
                  variant="filled"
                  color="primary"
                />
              </Link>
              <Link
                to={`../inquiry/${cd?.brd_whatsapp_conversations?.[0]?.user?.inq_lists?.[0]?.inquiry_no}`}
              >
                <Chip
                  sx={{ cursor: "pointer" }}
                  label="Details"
                  variant="filled"
                  color="primary"
                />
              </Link>
            </div>
          )}

          <h1 className="p-3 text-lg font-bold">
            {cd?.brd_whatsapp_conversations?.[0]?.whatsapp_name
              ? "Phone Number:"
              : "Members:"}
          </h1>
          <div className="bg-whatsappSecondaryBg flex-grow overflow-scroll">
            {cd?.brd_whatsapp_conversations?.[0]?.user_phone && (
              <DetailRow
                phone={cd?.brd_whatsapp_conversations?.[0]?.user_phone}
              />
            )}
            {wcd?.brd_communities?.[0]?.brd_community_users?.length > 0 &&
              wcd?.brd_communities?.[0]?.brd_community_users?.map(
                (item: any, ind: any) => (
                  <DetailRow
                    key={ind}
                    name={item?.user?.displayName}
                    phone={item?.user?.phoneNumber}
                  />
                )
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default WhatsappUserInfoBar;
