import { gql } from "@apollo/client";

export const INSERT_COMMENT = gql`
  mutation InsertComment($data: inq_comments_insert_input!) {
    insert_inq_comments_one(object: $data) {
      id
    }
  }
`;

export const EDIT_INQ = gql`
  mutation MyMutation($id: uuid, $flag_id: Int) {
    update_inq_list(where: { id: { _eq: $id } }, _set: { flag_id: $flag_id }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
