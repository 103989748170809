import { Navigate, useSearchParams } from "react-router-dom";
import { useAuthenticationStatus } from "@nhost/react";
import VerifyUser from "../modules/AuthModule/segments/VerifyUser";
import AuthModule from "../modules/AuthModule";

function Verify() {
  const { isAuthenticated, isLoading } = useAuthenticationStatus(); //get nhost authentication status
  const [searchParams] = useSearchParams();

  let params = searchParams.get("error")
    ? `?error=${searchParams.get("error")}&errorDescription=${searchParams.get(
        "errorDescription"
      )}`
    : "";

  if (isLoading) return <div>Loading...</div>;

  if (!isAuthenticated) return <Navigate to={`../login${params}`} />;

  // If authenticate true redirect user to verify page
  return (
    <AuthModule>
      <VerifyUser />
    </AuthModule>
  );
}

export default Verify;
