import CloseIcon from "@mui/icons-material/Close";
import { Avatar } from "@mui/material";
import { useState } from "react";

const WhatsappUserInfoBar = ({
  showUserInfo,
  setShowUserInfo,
  setShowWhatsappChatBox,
}: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div
      className={` h-full transition-all ease-in-out duration-300 ${
        showUserInfo ? "md:w-[900px] w-full" : "w-[0]"
      }`}
    >
      {showUserInfo && (
        <>
          <div className="flex items-center text-black dark:text-white px-[25px] text-[16px] font-medium py-[19px]">
            <CloseIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowUserInfo(false);
                const isSmallScreen = window.innerWidth < 768;
                if (isSmallScreen) {
                  setShowWhatsappChatBox(true);
                }
              }}
            />
            <p className="ml-5">Contact info</p>
          </div>
          <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary p-[30px] flex flex-col items-center justify-center">
            <Avatar
              sx={{ width: "200px", height: "200px", marginBottom: "10px" }}
              alt="whatsappAvator"
              src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
            />
            <p className="text-[24px] text-black dark:text-white">M Usman</p>
            <p className="text-primary dark:text-dark-primary text-[16px]">
              0333567368
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default WhatsappUserInfoBar;
