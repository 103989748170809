import { gql } from "@apollo/client"

export const GET_DEFAULT_EMAIL_TEMPLATES = gql`
query Def_email_temps {
  def_emails_templates(order_by: { id: asc }) {
    id
    name
    for_role
    variables_allowed
    subject
    content
    whatsapp_template_name
    notification_content
    sort
    dynamic_from
    dynamic_from_label
    is_activity
  }
}
`