import { Skeleton } from "@mui/material";

const ChatRowSekeleton = () => {
  return (
    <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer">
      <div className="pl-[15px] py-[13px]">
        <Skeleton variant="circular" width={40} height={40} />
      </div>
      <div className="py-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
        <div className="flex items-center justify-between">
          <Skeleton variant="text" sx={{ fontSize: "16px", width: "100px" }} />
          <Skeleton variant="text" sx={{ fontSize: "12px", width: "100px" }} />
        </div>
        <Skeleton variant="text" sx={{ fontSize: "14px", width: "150px" }} />
      </div>
    </div>
  );
};

export default ChatRowSekeleton;
