export const convertToUTC0 = (stringDate: any) => {
  const departureDatetime = new Date(stringDate);

  // Convert to UTC
  const utcYear = departureDatetime.getUTCFullYear();
  const utcMonth = departureDatetime.getUTCMonth();
  const utcDate = departureDatetime.getUTCDate();
  const utcHours = departureDatetime.getUTCHours();
  const utcMinutes = departureDatetime.getUTCMinutes();
  const utcSeconds = departureDatetime.getUTCSeconds();
  const utcDatetime = new Date(
    utcYear,
    utcMonth,
    utcDate,
    utcHours,
    utcMinutes,
    utcSeconds
  );

  return new Date(utcDatetime);
};
