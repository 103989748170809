import { gql } from "@apollo/client";

export const CREATE_FACEBOOK_PAGE_POST = gql`
  mutation CreateFacebookPagePost (
    $access_token: String!,
    $mediaData: [String],
    $mediaType: String!,
    $message: String!,
    $pageId: String!
  ) {
    create_fb_profile_page_post(
      arg1: {
        access_token: $access_token,
        mediaData: $mediaData,
        mediaType: $mediaType,
        message: $message,
        pageId: $pageId
      }
    ) {
      id
    }
  }
`;