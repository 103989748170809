import { useApolloClient, useMutation } from "@apollo/client";
import { useState } from "react";
import DataLabel from "~/components/common/DataLabel";
import { DELETE_FLIGHT_PAGE } from "../../graphql/Mutation";
import { toast } from "react-toastify";
import EditFlightPage from "../EditFlightPage";

const FlightPageCard = ({ item }: any) => {
  const [deleteFlightPage] = useMutation(DELETE_FLIGHT_PAGE);
  const client = useApolloClient();
  const [edit, setEdit] = useState(false);

  const deleteCard = async (id: any) => {
    const res = await deleteFlightPage({ variables: { id } });
    if (res?.data?.delete_brd_flights_pages?.affected_rows > 0) {
      toast.success("Flight page deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
  };

  if (edit) return <EditFlightPage setEdit={setEdit} data={item} />;
  return (
    <div className="rounded w-full p-[10px] grid gap-[10px] shadow-md border-gray-200">
      <DataLabel label="Title" value={item?.title} />
      <DataLabel label="Button Text" value={item?.button_text} />
      <DataLabel label="Footer Text" value={item?.footer_text} />
      <DataLabel label="Canonical" value={item?.canonical_url} />
      <DataLabel
        label="Origin"
        value={`${item?.origin_name} (${item?.origin_iata})`}
      />
      <DataLabel
        label="Destination"
        value={`${item?.destination_name} (${item?.destination_iata})`}
      />
      <DataLabel label="Base Price" value={item?.base_price} />
      <DataLabel label="Airline Iata" value={item?.airline_iata} />
      <DataLabel
        label="Show in Footer"
        value={item?.show_in_footer ? "Yes" : "No"}
      />
      <DataLabel
        label="Show in Home"
        value={item?.show_in_home ? "Yes" : "No"}
      />
      <div className="grid grid-cols-2 gap-[10px]">
        <button
          onClick={() => setEdit(true)}
          className="bg-blue-500 text-white w-full rounded"
        >
          Edit
        </button>
        <button
          onClick={() => deleteCard(item.id)}
          className="bg-red-500 text-white w-full rounded"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default FlightPageCard;
