import { gql } from "@apollo/client";

export const GET_FARES = gql`
  query GetFares($id: uuid) {
    inq_suggestion(where: { airline_page_id: { _eq: $id } }) {
      brd_id
      id
      inq_suggestion_costs {
        sale_price
        basic_fare
      }
      inq_suggestion_legs {
        cabin
        id
        type
        inq_suggestion_leg_stops {
          airline_iata
          arrival
          arrival_datetime
          depart
          depart_datetime
          flight_no
          id
        }
      }
    }
  }
`;
