import { MenuItem } from "@mui/material";

const MenuItems = ({
  setShowReplyMessage,
  setShowReactions,
  setShowMenu,
  showMessageInfo,
  setShowMessageInfo,
  setShowUserInfo,
  setShowWhatsappChatBox,
}: any) => {
  return (
    <>
      <div className="shadow py-[9px] ">
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowMenu(false);
            setShowUserInfo(false);
            setShowMessageInfo(true);
            const isSmallScreen = window.innerWidth < 768;
            if (isSmallScreen) {
              setShowWhatsappChatBox(false);
            }
          }}
        >
          Message info
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowReplyMessage(true);
            setShowMenu(false);
          }}
        >
          Reply
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowReactions(true);
            setShowMenu(false);
          }}
        >
          React
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Forward
        </MenuItem>
      </div>
    </>
  );
};

export default MenuItems;
