import React from "react";
import Layout from "~/components/common/Layout";
import CaseStudySEOSettings from "~/modules/ServicesModule/segments/CaseStudy/components/ShowCaseStudy/components/CaseStudySEOSettings";
import EditCaseStudy from "~/modules/ServicesModule/segments/CaseStudy/components/ShowCaseStudy/components/EditCaseStudy";

const EditCaseStudyPage = () => {
  return (
    <div>
      <Layout>
        <>
          <EditCaseStudy />
          <CaseStudySEOSettings />
        </>
      </Layout>
    </div>
  );
};

export default EditCaseStudyPage;
