import React from "react";
import { useParams } from "react-router-dom";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import SocialConnectModule from "~/modules/BrandModule/modules/SocialConnectModule";

const SocialConnectPage = () => {
  const { brd_id } = useParams();
  return (
    <div>
      <Layout>
        <BrandModule>
          <SocialConnectModule brd_id={brd_id} />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default SocialConnectPage;
