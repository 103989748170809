import { gql } from "@apollo/client";

export const GET_PAYMENT_METHODS = gql`
  query MyQuery($th_id: uuid!) {
    profit_slabs(
      where: { th_id: { _eq: $th_id } }
      order_by: { created_at: desc }
    ) {
      id
      max
      percentage
      min
      th_id
    }
  }
`;
