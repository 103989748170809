import BlankProfile from "../assets/img/BlankProfile.png";
import moment from "moment";
import { useUserDefaultRole } from "@nhost/react";
import { Link } from "react-router-dom";
import { useState } from "react";
interface State {
  min: number;
  max: number;
  percentage: number;
}
interface ProfitSlab {
  id: string;
  min: number;
  max: number;
  percentage: number;
  th_id: string;
}

const ProfitSlabsCard = ({ profit_slab, index }: any) => {
  const [addFormError, setAddFormError] = useState<string | null>(null);
  const [editFormErrors, setEditFormErrors] = useState<
    Record<string, string | null>
  >({});
  const [value, setValue] = useState<State>({ min: 0, max: 0, percentage: 0 });
  const [temp, setTemp] = useState<State>({ min: 0, max: 0, percentage: 0 });
  const [profitSlabs, setProfitSlabs] = useState<ProfitSlab[]>([]);
  const [loading, setLoading] = useState(false);
  const [editSlabId, setEditSlabId] = useState<string | null>(null);
  const [editingSlab, setEditingSlab] = useState<State>({
    min: 0,
    max: 0,
    percentage: 0,
  });
  const [isSaving, setIsSaving] = useState(false);
  const userDefaultRole = useUserDefaultRole();

  return (
    <>
      <div
        key={index}
        className="mb-4 border rounded-lg p-4 shadow-md bg-white"
      >
        <div className="flex justify-between mb-2">
          <div className="w-full">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 border p-2"
              htmlFor={`min-${index}`}
            >
              Min:
            </label>
            {editSlabId === profit_slab.id ? (
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={editingSlab.min}
                onChange={(e) =>
                  setEditingSlab((prev) => ({
                    ...prev,
                    min: parseFloat(e.target.value),
                  }))
                }
              />
            ) : (
              <p className="border p-2">{profit_slab.min}</p>
            )}
          </div>
          <div className="w-full ml-10">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 border p-2"
              htmlFor={`max-${index}`}
            >
              Max:
            </label>
            {editSlabId === profit_slab.id ? (
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={editingSlab.max}
                onChange={(e) =>
                  setEditingSlab((prev) => ({
                    ...prev,
                    max: parseFloat(e.target.value),
                  }))
                }
              />
            ) : (
              <p className="border p-2">{profit_slab.max}</p>
            )}
          </div>
          <div className="w-full ml-10">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 border p-2"
              htmlFor={`percentage-${index}`}
            >
              Percentage:
            </label>
            {editSlabId === profit_slab.id ? (
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={editingSlab.percentage}
                onChange={(e) =>
                  setEditingSlab((prev) => ({
                    ...prev,
                    percentage: parseFloat(e.target.value),
                  }))
                }
              />
            ) : (
              <p className="border p-2">{profit_slab.percentage}</p>
            )}
          </div>
        </div>
        {editFormErrors[profit_slab.id] && (
          <p className="text-red-500 text-center p-5">
            {editFormErrors[profit_slab.id]}
          </p>
        )}

        {editSlabId === profit_slab.id ? (
          <div className="flex items-center justify-center">
            {isSaving ? (
              <button
                className="bg-[#0e5732] w-[50%] hover:bg-[#23708d] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                disabled
              >
                Saving...
              </button>
            ) : (
              <button
                className="bg-[#0e5732] w-[50%] hover:bg-[#23708d] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              // onClick={() => saveEditing(profit_slab.id)}
              >
                Save
              </button>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <button
              className="bg-[#0e5732] w-[50%] hover:bg-[#23708d] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            // onClick={() => startEditing(profit_slab)}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfitSlabsCard;
