import { gql } from "@apollo/client";

export const INSERT_UNIVERSITY_COURSE = gql`
  mutation InsertUniversityCourse(
    $tuitionFees: String!
    $name: String!
    $courseType: String!
    $universityId: uuid!
    $duration: String!
    $intake: String!
  ) {
    insert_brd_university_courses(
      objects: {
        tuition_fees: $tuitionFees
        name: $name
        def_uni_id: $universityId
        intake: $intake
        course_type: $courseType
        duration: $duration
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_COURSE_WITH_ID = gql`
  mutation DeleteUniCourse($courseId: uuid!) {
    delete_brd_university_courses(where: { id: { _eq: $courseId } }) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_COURSE_WITH_ID = gql`
  mutation UpdateCoursesWithId(
    $courseId: uuid!
    $data: brd_university_courses_set_input!
  ) {
    update_brd_university_courses(
      where: { id: { _eq: $courseId } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;

