import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_VENDORS } from "./graphql/Query";
import SidePopup from "~/components/common/SidePopup";
import UserAdd from "~/components/UserCard/components/UserAdd/UserAdd";
import CustomerTable from "../CustomerList/component/table";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function VendorsList() {
  const [brdIds, setBrdIds] = useState();

  const { brands }: any = brandsStore();
  // get data based on query

  useEffect(() => {
    const brandIds = brands.map((item: any) => {
      return item.id;
    });

    setBrdIds(brandIds);
  }, [brands]);

  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { brdIds: brdIds },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Vendors ({data.users.length})
      </h1>
      <div>
        <CustomerTable data={data?.users} />

        <SidePopup
          text="Add Vendor"
          component={<UserAdd defaultRole="vendor" heading="Add Vendor" />}
        />
      </div>
      <div className="text-primary dark:text-dark-primary">
        {data.users.length === 0 && <h2>No record found</h2>}
      </div>
    </>
  );
}
