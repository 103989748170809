import SidePopup from "~/components/common/SidePopup";
import AddCaseStudy from "./components/AddCaseStudy";
import ShowCaseStudy from "./components/ShowCaseStudy";
import { useParams } from "react-router-dom";

const CaseStudy = () => {

  const { brd_id } = useParams()

  return (
    <>
      <ShowCaseStudy brdId={brd_id} />
      {/* <SidePopup component={<AddCaseStudy />} /> */}
    </>
  );
};

export default CaseStudy;
