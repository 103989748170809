import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { ADD_BRAND } from "../../graphql/Mutation";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import { useUserDefaultRole } from "@nhost/react";
import BrandCard from "./components/BrandCard";
import BrandAdd from "./components/BrandAdd";
import { GET_BRANDS } from "../../graphql/Query";
import SidePopup from "~/components/common/SidePopup";

// flow bite code
import InsertBrandInformation from "./components/InsertBrandInformation";

export default function BrandList() {
  const { travelHouse }: any = travelHouseStore();
  const userDefaultRole = useUserDefaultRole();

  // get data based on query
  const { loading, data, error } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  // ;
  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        Brands
      </h1>
      <div className="mt-4 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        {data.brd_list.map((brand: any, index: any) => (
          <BrandCard key={index} brand={brand} />
        ))}{" "}
        {data.brd_list.length === 0 && <h2>No record found</h2>}
      </div>
      <SidePopup component={<InsertBrandInformation />} />
    </>
  );
}
