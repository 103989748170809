import moment from "moment";
import { FunctionComponent } from "react";
import vector81 from "../images/Vector8.png";
import luggage from "../images/luggage.png";
import handCarry from "../images/HandCarry.png";
import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";
import airportData from "~/components/FormInputs/LocationBox/data/airports.json";
import { Document, Page, View, Image, Text } from "@react-pdf/renderer";

import { URLs } from "~/config/enums";
import { useEffect, useState } from "react";
import { getStyles } from "../pdfDocument/styles";
import FlightInvoice from "./components/FlightInvoice";
import InvoiceDownloadPDF from "./components/InvoiceDownloadPDF";
import PassengerComponent from "./components/passengers";

export type InvoicePType = {
  className?: string;
  inquiry?: any;
  invoice?: any;
  signedImage?: any;
  logo?: any;
};

const NewPDFComponent: FunctionComponent<InvoicePType> = ({
  className = "",
  inquiry,
  invoice,
  signedImage,
  logo,
}) => {
  console.log("asdnasmdnansm", inquiry, invoice);
  const timeDuration = (timestamp1: any, timestamp2: any) => {
    const start = moment(timestamp1);
    const end = moment(timestamp2);
    const duration = moment.duration(end.diff(start));

    // Extract hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    // Output
    // ;
    return { hours, minutes };
  };

  const getHoursAndMinutes = (timestamp1: any, timestamp2: any) => {
    const duration = timeDuration(timestamp1, timestamp2);
    return `${duration?.hours} hours ${duration?.minutes} minutes`;
  };

  function getAirlineNameByIATA(iataCode: any) {
    const airline = airlineData.find(
      (airline: any) => airline.iata === iataCode
    );
    return airline?.marketing_name;
  }

  function getAirportNameByIATA(iataCode: any) {
    const airport = airportData.find(
      (airport: any) => airport.iata_code === iataCode
    );
    return airport?.name;
  }

  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );

  const flightCosts =
    invoice?.invoice_suggestions
      ?.filter((suggestion: any) => suggestion.type === "flights") // Filter for flight suggestions
      ?.flatMap(
        (suggestion: any) => suggestion?.inq_suggestion || [] // Map to costs, ensuring it's an array
      ) || []; // Return an empty array if no costs are found

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerFirstContainer}>
            {logo && <Image style={styles.logo} src={logo} />}

            <View style={styles.brandDetail}>
              <Text style={styles.brandName}>{inquiry?.brd_list?.name}</Text>
              <Text>www.{inquiry?.brd_list?.domain}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.email}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.phone}</Text>
            </View>
          </View>
          <View style={styles.headerSecondContainer}>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
            </Text>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
              {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
            </Text>
          </View>
        </View>
        <View style={styles.inquiryDetailContainer}>
          <View style={styles.inquirySecondContainer}>
            <View style={styles.inquiryColumnContainer}>
              <View style={styles.brandDetail}>
                <Text>Billed to</Text>
                <Text style={styles.boldFont}>
                  {inquiry?.users?.displayName}
                </Text>
                {(inquiry?.users?.metadata?.addressLine1 ||
                  inquiry?.users?.metadata?.addressLine2 ||
                  inquiry?.users?.metadata?.postalCode ||
                  inquiry?.users?.metadata?.city) && (
                  <Text>
                    {inquiry?.users?.metadata?.addressLine1}{" "}
                    {inquiry?.users?.metadata?.addressLine2}{" "}
                    {inquiry?.users?.metadata?.postalCode}{" "}
                    {inquiry?.users?.metadata?.city}{" "}
                  </Text>
                )}
                <Text> {inquiry?.users?.phoneNumber}</Text>
                <Text> {inquiry?.users?.email}</Text>
              </View>
              <View style={styles.brandDetail}>
                <Text>Subject</Text>
                <Text style={styles.boldFont}>
                  Booking Confirmation Invoice
                </Text>
              </View>
            </View>
            <View style={styles.twoContainerGroup}>
              <View style={styles.brandDetail}>
                <Text>Invoice number</Text>
                <Text style={styles.boldFont}> #{invoice?.invoice_no}</Text>
              </View>
              {invoice?.invoice_suggestion?.pnr && (
                <View style={styles.brandDetail}>
                  <Text>Booking Confirmation Number (PNR)</Text>
                  <Text style={styles.boldFont}>
                    {invoice?.invoice_suggestion?.pnr}
                  </Text>
                </View>
              )}

              <View style={styles.brandDetail}>
                <Text>Invoice Date</Text>
                <Text style={styles.boldFont}>
                  {moment(invoice?.created_at).format("DD MMM YYYY")}
                </Text>
              </View>
            </View>
            <View style={styles.inquiryColumnContainer}>
              <View style={styles.brandDetail}>
                <Text>Invoice of (GBP)</Text>
                <Text style={styles.invoiceGbp}>
                  £{invoice?.amount + flightCosts[0]?.bookingFee}
                </Text>
              </View>
              <View style={styles.brandDetail}>
                <Text>Due Date</Text>
                <Text style={styles.boldFont}>
                  {moment(invoice?.due_date).format("DD MMM YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.passengerHeader}>
              <Text>PASSENGER(S)</Text>
              <Text>AMOUNT</Text>
            </View>
            <PassengerComponent inquiry={inquiry} invoice={invoice} />
          </View>
          <View style={styles.totalsContainer}>
            <View style={styles.totals}>
              <Text>Subtotal</Text>
              <Text>£{invoice?.amount}</Text>
            </View>
            <View style={styles.totals}>
              <Text>Booking Fee</Text>
              <Text>£{flightCosts[0]?.bookingFee}</Text>
            </View>
            <View style={styles.totals}>
              <Text>Grand Total</Text>
              <Text>£{invoice?.amount + flightCosts[0]?.bookingFee}</Text>
            </View>
            <View style={styles.paid}>
              <Text style={styles.boldFont}>Paid</Text>
              <Text style={styles.boldFont}>
                £
                {inquiry?.inq_transections
                  ?.filter((transaction: any) =>
                    ["paid", "partiallyRefunded"].includes(transaction.status)
                  )
                  ?.map((transaction: any) =>
                    transaction.status === "partiallyRefunded"
                      ? transaction.amount - transaction.refund_amount
                      : transaction.amount
                  )
                  ?.reduce((sum: number, amount: number) => sum + amount, 0)}
              </Text>
            </View>
            <View style={styles.totals}>
              <Text style={styles.boldFont}>Balance Due</Text>
              <Text style={styles.boldFont}>
                £
                {invoice?.amount +
                  flightCosts[0]?.bookingFee -
                  inquiry?.inq_transections
                    ?.filter((transaction: any) =>
                      ["paid", "partiallyRefunded"].includes(transaction.status)
                    )
                    ?.map((transaction: any) =>
                      transaction.status === "partiallyRefunded"
                        ? transaction.amount - transaction.refund_amount
                        : transaction.amount
                    )
                    ?.reduce((sum: number, amount: number) => sum + amount, 0)}
              </Text>
            </View>
          </View>
          {inquiry?.inq_transections?.filter((transaction: any) =>
            ["paid", "partiallyRefunded"].includes(transaction.status)
          )?.length > 0 && (
            <View>
              <View style={styles.passengerHeader}>
                <Text>TRANSACTIONS ID</Text>
                <Text>PAYMENT MODE</Text>
                <Text>AMOUNT</Text>
              </View>
              {inquiry?.inq_transections
                ?.filter((transaction: any) =>
                  ["paid", "partiallyRefunded"].includes(transaction.status)
                )
                .map((item: any) => {
                  return (
                    <View style={styles.passengerBody}>
                      <Text> #{item.transactions_no}</Text>
                      <Text>{item.acc_payment_method.type}</Text>
                      <Text>
                        £
                        {item.status === "partiallyRefunded"
                          ? item.amount - item.refund_amount
                          : item.amount}
                      </Text>
                    </View>
                  );
                })}
            </View>
          )}
        </View>
      </Page>
      {/* Flights  */}
      <Page size="A4" style={styles.page}>
        <InvoiceDownloadPDF invoice={invoice} inquiry={inquiry} />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.inquiryDetailContainer}>
          <View style={styles.termConditionContainer}>
            <Text style={styles.termConditionMainHeading}>
              Terms & Conditions
            </Text>
            <Text style={styles.termConditionText}>
              Please read these carefully as the person making this booking
              (either for him selves or for any other passenger) accepts all the
              below terms and conditions.
            </Text>
            <Text style={styles.termConditioHeadings}>
              DEPOSITS AND TICKETS ARE NEITHER REFUNDABLE NOR CHANGEABLE (Terms
              & Conditions May Apply).
            </Text>
            <Text style={styles.termConditionText}>
              Unless Specified, All the deposits paid and tickets purchased /
              issued are non refundable in case of cancellation or no show
              (Failure to arrive at departure airport on time) and non
              changeable before or after departure (date change is not
              permitted). Once flights reserved, bookings / tickets are
              non-transferable to any other person means that name changes are
              not permitted. Issued Ticket are also not re-routable.
            </Text>
            <Text style={styles.termConditionText}>
              If you are reserving the flight by making the advance partial
              payment (Initial deposit) then please note that fare/taxes may
              increase at any time without the prior notice. Its means the price
              is not guaranteed unless ticket is issued because airline /
              consolidator has right to increase the price due to any reason. In
              that case we will not be liable and passenger has to pay the
              fare/tax difference. We always recommend you to pay ASAP and get
              issue your ticket to avoid this situation. Further more if you
              will cancel your reservation due to any reason, then the paid
              deposit(s) will not be refunded.
            </Text>
            <Text style={styles.termConditionText}>
              Regardless of any reason, 75GBP per person will be charged, if you
              will cancel your reservation before ticket issuance. After
              issuance all payments are non-refundable.
            </Text>
            <Text style={styles.termConditioHeadings}>
              CHECKING ALL FLIGHT DETAILS & PASSENGER NAME(S)
            </Text>
            <Text style={styles.termConditionText}>
              It is your responsibility to check all the details are correct
              i.e. Passenger names (are same as appearing on passport / travel
              docs), Travelling dates, Transit Time, Origin & Destination, Stop
              Over, Baggage Allowance and other flight information. Once the
              ticket is issued then no changes can be made.
            </Text>
            <Text style={styles.termConditioHeadings}>
              PASSPORT, VISA AND IMMIGRATION REQUIREMENTS
            </Text>
            <Text style={styles.termConditionText}>
              You are responsible for checking all these items like Passport,
              Visa (including Transit Visa) and other immigration requirements.
              You must consult with the relevant Embassy / Consulate, well
              before the departure time for the up to date information as
              requirements may change time to time. We can not accept any
              liability of any transit visa and if you are refused the entry
              onto the flight or into any country due to failure on your part to
              carry the correct passport, visa or other documents required by
              any airline, authority or country.
            </Text>
            <Text style={styles.termConditioHeadings}>
              RECONFIRMING RETURN/ONWARD FLIGHTS
            </Text>
            <Text style={styles.termConditionText}>
              It is your responsibility to RECONFIRM your flights at least 72
              hours before your departure time either with your travel agent or
              the relevant Airline directly. The company will not be liable for
              any additional costs due to your failure to reconfirm your
              flights.
            </Text>
            <Text style={styles.termConditioHeadings}>
              INSURANCE AND BAGGAGE LOSS
            </Text>
            <Text style={styles.termConditionText}>
              We recommend that you purchase travel insurance. It is your
              responsibility to ensure you have valid travel insurance that
              covers your needs and also ensure that you have complied with all
              the health and vaccination requirements for the countries you are
              travelling. Advice can be obtained from your GP or travel clinic.
              We don not accept any claim for the lost / Stolen / Damaged
              Baggage. you have to contact the relevant airline directly in that
              case.
            </Text>
            <Text style={styles.termConditioHeadings}>
              SPECIAL REQUESTS AND MEDICAL PROBLEMS
            </Text>
            <Text style={styles.termConditionText}>
              If you have any special requests like meal preference, Seat
              Allocation and wheel chair request etc, please advise us at time
              of issuance of ticket.We will try our best to ful fill these by
              passing this request to relevant airline but we cannot guarantee
              and failure to meet any special request will not held us liable
              for any claim.
            </Text>
            <Text style={styles.termConditioHeadings}>VERY IMPORTANT</Text>
            <Text style={styles.termConditionText}>
              We do not accept responsibility for any financial loss if the
              airline fail to operate. Passengers will be solely responsible for
              that so it is highly recommended that separate travel insurance
              must be arranged to protect yourself.
            </Text>
            <Text style={styles.termConditionText}>
              We advise you to read our complete terms and conditions mentioned
              at on our website.
            </Text>
          </View>
        </View>

        {invoice?.invoice_sign && signedImage && (
          <View style={styles.signContainer}>
            <Image style={styles.signImage} src={signedImage} />
          </View>
        )}
      </Page>
    </Document>
  );
};

export default NewPDFComponent;
