import { gql } from "@apollo/client";

export const GET_MESSAGES = gql`
  subscription GetMessages($conversation_id: String!) {
    brd_whatsapp_messages(
      where: { conversation_id: { _eq: $conversation_id } }
      order_by: { created_at: asc }
    ) {
      id
      conversation_id
      sender_role
      message_id
      sender {
        displayName
      }
      text
      sender_id
      reply {
        id
        sender_id
        conversation_id
        sender_role
        text
        reaction
        sender {
          displayName
        }
        type
        timestamp
        created_at
        updated_at
        brd_whatsapp_conversation {
          id
          user_phone
          brand_phone
          whatsapp_name
          last_view_time
          created_at
          updated_at
          user_id
          last_message
          last_message_time
        }
      }
      brd_whatsapp_messages_statuses {
        id
        key
        timestamp
        created_at
        updated_at
        message_id
      }
      timestamp
      created_at
      reaction
      updated_at
      type
    }
  }
`;

export const GET_CONVERSATION_MESSAGES = gql`
  subscription GetConversation($id: String!) {
    brd_whatsapp_conversations(where: { id: { _eq: $id } }) {
      id
      user_phone
      brand_phone
      whatsapp_name
      last_view_time
      created_at
      updated_at
      user_id
      last_message
      last_message_time
      messages {
        id
        conversation_id
        sender_role
        text
        reaction
        timestamp
        reply {
          id
          conversation_id
          sender_role
          text
          reaction
          timestamp
          created_at
          updated_at
          brd_whatsapp_conversation {
            id
            user_phone
            brand_phone
            whatsapp_name
            last_view_time
            created_at
            updated_at
            user_id
            last_message
            last_message_time
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const FETCH_UPDATE = gql`
  subscription MySubscription(
    $timestamp: timestamptz!
    $conversation_id: String!
  ) {
    brd_whatsapp_messages_stream(
      batch_size: 10
      cursor: { initial_value: { timestamp: $timestamp } }
      where: { conversation_id: { _eq: $conversation_id } }
    ) {
      id
      timestamp
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  mutation MyMutation($conversation_id: String!, $time: timestamptz) {
    update_brd_whatsapp_conversations(
      where: { id: { _eq: $conversation_id } }
      _set: { last_view_time: $time }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GET_CONVERSATION_DETAIL = gql`
  query MyQuery($id: String!) {
    brd_whatsapp_conversations(where: { id: { _eq: $id } }) {
      brand_phone
      id
      user_phone
      whatsapp_name
      brd_list {
        id
        whatsapp_token
      }
      user_phone
      user {
        id
        inq_lists {
          inquiry_no
        }
      }
      assigned_consultant
    }
  }
`;
