import { useApolloClient, useQuery, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_CONVERSATIONS } from "~/modules/EmailModule/segments/EmailWindow/components/ConversationList/graphql/Query";
import { GET_INQUIRY } from "~/modules/InquiriesModule/segments/InquiryDetail/graphql/Query";

export default function useInquiryDetail(inquiry_id: any) {
  const [inquiryData, setInquiryData] = useState();

  const client = useApolloClient();

  const runFunc = async () => {
    const res = await client.query({
      query: GET_INQUIRY,
      variables: { inquiry_no: inquiry_id },
    });
    setInquiryData(res?.data);
  };

  useEffect(() => {
    if (inquiry_id) {
      runFunc();
    }
  }, [inquiry_id]);

  return { inquiryDetail: inquiryData };
}
