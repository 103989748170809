import { gql } from "@apollo/client";

export const DELETE_TESTIMONAIAL = gql`
  mutation DeleteTestimonials($id: uuid) {
    delete_brd_testimonials(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_TESTIMONIAL = gql`
  mutation insertTestmonial($tsData: brd_testimonials_insert_input!) {
    insert_brd_testimonials_one(object: $tsData) {
      id
    }
  }
`;

export const UPDATE_TESTIMONIAL = gql`
  mutation MyMutation(
    $id: brd_testimonials_pk_columns_input!
    $tsData: brd_testimonials_set_input!
  ) {
    update_brd_testimonials_by_pk(pk_columns: $id, _set: $tsData) {
      id
    }
  }
`;
