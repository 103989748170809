import { gql } from "@apollo/client";
export const EDIT_CAR_INQUIRY = gql`
  mutation MyMutation($id: uuid, $inquiry: car_inq_ticket_set_input!) {
    update_car_inq_ticket(where: { id: { _eq: $id } }, _set: $inquiry) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation MyMutation($transactions: [acc_transactions_insert_input!]!) {
    insert_acc_transactions(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CAR_TICKET_FILe = gql`
  mutation MyMutation($id: uuid!, $ticketFile: String!) {
    update_car_inq_ticket_files(
      where: { id: { _eq: $id } }
      _set: { file_url: $ticketFile }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
