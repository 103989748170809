import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query GetAccounts($thp_id: uuid!) {
    acc_list(where: { thp_id: { _eq: $thp_id } }) {
      name
      target
      type
      created_at
      updated_at
      id
      thp_id
    }
  }
`;

export const GET_DEFAULT_ACCOUNTS = gql`
  query GetDefAccount {
    def_acc_list {
      id
      name
      type
      created_at
      updated_at
    }
  }
`;
