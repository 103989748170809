import Layout from '~/components/common/Layout'
import SeasonalFaresModule from '~/modules/SeasonalFaresModule'
import SeasonalFares from '~/modules/BrandModule/segments/SeasonalFares'

export default function Seasonal() {
  return (
    <div>
        <Layout>
            <SeasonalFaresModule>
                <SeasonalFares />
            </SeasonalFaresModule>
        </Layout>
    </div>
  )
}