import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_INQ_LIST } from "~/graphql/inq_list/Query";
import useInquiryDetail from "~/hooks/useInquiryDetail";
import BankPayment from "./BankPayment";
import StripePayment from "./StripePayment";
import useBrandPaymentMethod from "~/hooks/useBrandPaymentMethod";
import useDefaultPaymentMehtods from "~/hooks/useDefaultPaymentMehtods";

const CreatePaymentRequest = ({
  mainHeading,
  userID,
  setSidePopup,
  showUser,
}: any) => {
  const [inqID, setInqId] = useState<any>();
  const [paymentType, setPaymentType] = useState<any>();
  const [types, setTypes] = useState<any>([]);

  const [stripe, setStripe] = useState<any>({});

  const { inquiryDetail }: any = useInquiryDetail(inqID && inqID);

  const { data } = useQuery(GET_USER_INQ_LIST, {
    variables: { user_id: userID },
  });

  const { paymentMethod } = useBrandPaymentMethod(
    inquiryDetail?.inq_list[0]?.brd_id
  );

  const { defaultPaymentMethods } = useDefaultPaymentMehtods();

  useEffect(() => {
    paymentMethod?.filter((pay: any) => pay.type === "bank")?.length > 0 &&
      setTypes((prevTypes: any) => [
        ...prevTypes,
        {
          id: 1,
          title: "Bank",
          value: "bank",
        },
      ]);

    paymentMethod?.filter(
      (pay: any) => pay.type === "stripe" && pay.default === true
    )?.length > 0 &&
      setTypes((prevTypes: any) => [
        ...prevTypes,
        { id: 2, title: "Stripe", value: "stripe" },
      ]);

    paymentMethod?.filter(
      (pay: any) => pay.type === "stripe-thp" && pay.default === true
    )?.length > 0 &&
      setTypes((prevTypes: any) => [
        ...prevTypes,
        { id: 3, title: "Stripe THP", value: "stripe" },
      ]);
  }, [paymentMethod]);

  useEffect(() => {
    paymentType?.title === "Stripe" &&
      setStripe(
        paymentMethod?.filter(
          (pay: any) => pay.type === "stripe" && pay.default === true
        )[0]
      );

    paymentType?.title === "Stripe THP" &&
      setStripe({
        id: paymentMethod?.filter(
          (pay: any) => pay.type === "stripe-thp" && pay.default === true
        )[0].id,
        stripe_pub: defaultPaymentMethods.def_stripe[0].public_key,
        stripe_secret: defaultPaymentMethods.def_stripe[0].secret_key,
      });
  }, [paymentType]);

  return (
    <>
      <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{mainHeading}</h1>
        <Divider />
        <div className="grid grid-cols-1 gap-5 mt-10">
          {showUser && (
            <Autocomplete
              disablePortal
              onChange={(_, data: any) => {
                setInqId(data?.inquiry_no);
              }}
              options={data?.inq_list ? data?.inq_list : []}
              getOptionLabel={(option: any) => option?.inquiry_no}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option?.inquiry_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField label="Inquiry Number" {...params} />
              )}
            />
          )}
          <Autocomplete
            disablePortal
            onChange={(_, data: any) => {
              setPaymentType(data);
            }}
            options={types ? types : []}
            getOptionLabel={(option: any) => option?.title}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option?.title}
              </Box>
            )}
            renderInput={(params) => (
              <TextField label="Payment Type" {...params} />
            )}
            noOptionsText=""
          />
        </div>
        {paymentType?.value === "bank" ? (
          <BankPayment
            inquiry={inquiryDetail?.inq_list[0]}
            setSidePopup={setSidePopup}
            mainHeading={mainHeading}
            paymentMethod={paymentMethod}
          />
        ) : (
          paymentType?.value === "stripe" && (
            <StripePayment
              mainHeading={mainHeading}
              inquiry={inquiryDetail?.inq_list[0]}
              setSidePopup={setSidePopup}
              paymentMethod={paymentMethod}
              stripe={stripe}
            />
          )
        )}
      </div>
    </>
  );
};

export default CreatePaymentRequest;
