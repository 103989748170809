export const getPaidAmount = (inquiry: any) => {
  let sum = 0;
  inquiry?.inq_transections.map((item: any) => {
    if (item?.status === "paid") {
      sum += item?.amount;
    }
  });
  return sum;
};

export const getSalePrice = (inquiry: any) => {
  return (
    inquiry?.selected_suggestion?.inq_suggestion_costs.reduce(
      (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
      0
    ) + inquiry?.selected_suggestion?.bookingFee
  );
};

export const getProfitAmount = (inquiry: any) => {
  let paid = 0;
  inquiry?.inq_transections.map((item: any) => {
    if (item?.status === "paid") {
      paid += item?.amount;
    }
  });

  let cost =
    inquiry?.inq_tickets?.[0]?.ticket_cost_price +
    inquiry?.inq_tickets?.[0]?.ticket_attol_price +
    inquiry?.inq_tickets?.[0]?.ticket_mis_cost;

  return paid - cost;
};
