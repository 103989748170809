import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
// Import types
import { ActionTypes, StateType } from "./brandsTypes";
// Import all queries

const brandsStore = create<StateType & ActionTypes>()(
  persist(
    (set, get) => ({
      brands: [],
      setBrands: (brands: any) => set((state: any) => ({ brands: brands })),
    }),
    {
      name: "brands",
      storage: createJSONStorage(() => sessionStorage),
      onRehydrateStorage: () => (state: any) => {
        if (typeof state !== "undefined") {
          state.setHasHydrated(true);
        }
      },
    }
  )
);

export default brandsStore;
