import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAIL } from "~/graphql/users/Query";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";

export default function Header() {
  const { customerId } = useParams();
  const { data, error, loading } = useQuery(GET_USER_DETAIL, {
    variables: {
      id: customerId,
    },
  });

  if (loading) return <>Loading</>;
  if (error) return <>{error.message}</>;
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name.trim().split(" ").length > 1
          ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
          : `${name[0]}`
      }`,
    };
  }

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  return (
    <div className="py-4 flex w-full justify-between">
      <div className="flex">
        <Avatar
          style={{
            fontSize: "15px",
            width: "40px",
            height: "40px",
          }}
          {...stringAvatar(data?.users?.[0]?.displayName.toUpperCase())}
        />
        {/* <img
          src={
            data?.users?.[0]?.avatarUrl ||
            "https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1095249842.jpg"
          }
          srcSet={
            data?.users?.[0]?.avatarUrl ||
            "https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1095249842.jpg"
          }
          loading="lazy"
          alt=""
          className="rounded-[20px] h-[100px]"
        /> */}
        <div className="px-4 flex flex-col gap-2">
          <h2>{data?.users?.[0]?.displayName}</h2>
          <h5>{data?.users?.[0]?.phoneNumber}</h5>
          <h2>{data?.users?.[0]?.email}</h2>
        </div>
      </div>
      <div className="flex flex-end flex-col items-center gap-4">
        <Button
          component="label"
          role={undefined}
          variant="contained"
          className="w-[130px]"
          tabIndex={-1}
          startIcon={<WhatsAppIcon />}
        >
          Whatsapp
        </Button>
        <Button
          component="label"
          role={undefined}
          className="w-[130px]"
          variant="contained"
          tabIndex={-1}
          startIcon={<MailOutlineIcon />}
        >
          Emails
        </Button>
      </div>
    </div>
  );
}
