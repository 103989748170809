import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SearchBar from "../../../SearchBar";
import ChatRow from "../../../ChatRow";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { useState } from "react";
import NewGroup from "./component/NewGroup";

const Contacts = ({
  setShowContactBar,
  showContactBar,
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  showGroupBar,
  setShowGroupBar,
}: any) => {
  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showContactBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div className="bg-whatsappBasic px-[23px] text-white text-[19px] pt-[60px]">
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowContactBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">New chat</p>
          </div>
        </div>
        <SearchBar searchType="contacts" />

        <div className="overflow-scroll flex-grow">
          <div
            className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer"
            onClick={() => {
              setShowGroupBar(true);
            }}
          >
            <div className="py-[13px] ml-[15px]">
              <div className="p-[13px] text-white bg-whatsappBasic rounded-full">
                <PeopleOutlinedIcon />
              </div>
            </div>
            <div className="py-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
              <h1 className="text-[16px] text-black dark:text-white py-[13px]">
                New group
              </h1>
            </div>
          </div>

          <h1 className="text-whatsappBasic py-[30px] px-[15px] text-[16px]">
            CONTACTS ON WHATSAPP
          </h1>

          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
          <ChatRow
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowWhatsappSidebar={setShowWhatsappSidebar}
            setShowContactBar={setShowContactBar}
          />
        </div>
      </div>
      <NewGroup showGroupBar={showGroupBar} setShowGroupBar={setShowGroupBar} />
    </>
  );
};

export default Contacts;
