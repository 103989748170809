import { Skeleton } from "@mui/material";

const ChatBoxSekeleton = () => {
  return (
    <div className="border dark:border-dark-secondary w-full flex flex-col h-full">
      <div className="bg-whatsappPrimaryBg dark:bg-dark-primary flex items-center py-[8px] px-[16px] w-full text-primary dark:text-dark-primary cursor-pointer">
        <div className=" flex items-center justify-between w-full">
          <div className="flex items-center">
            <Skeleton variant="circular" width={40} height={40} />
            <div className="pl-[15px]">
              <Skeleton
                variant="text"
                sx={{ fontSize: "16px", width: "100px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "14px", width: "150px" }}
              />
            </div>
          </div>
          <div className="flex items-center gap-5">
            <Skeleton variant="text" sx={{ fontSize: "25px", width: "25px" }} />
            <Skeleton variant="text" sx={{ fontSize: "25px", width: "25px" }} />
          </div>
        </div>
      </div>
      <div className="bg-[#EFEAE2] dark:bg-[#38344e] overflow-scroll w-full flex flex-col flex-grow md:px-[63px] px-[15px] py-[10px]">
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "50%", float: "right" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "30%", float: "right" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "60%", float: "left" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "30%", float: "left" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "40%", float: "right" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "50%", float: "right" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "40%", float: "left" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "50%", float: "left" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "30%", float: "right" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "60%", float: "right" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "40%", float: "left" }}
          />
        </div>
        <div className="w-full my-[3px]">
          <Skeleton
            variant="text"
            sx={{ fontSize: "35px", width: "60%", float: "left" }}
          />
        </div>
      </div>
      <div className="px-[16px] py-[10px] flex items-center text-primary dark:text-dark-primary z-10 bg-whatsappPrimaryBg dark:bg-dark-primary gap-5">
        <Skeleton variant="text" sx={{ fontSize: "25px", width: "25px" }} />
        <div className="flex-grow">
          <Skeleton variant="rounded" height={35} />
        </div>
        <Skeleton variant="text" sx={{ fontSize: "25px", width: "25px" }} />
      </div>
    </div>
  );
};

export default ChatBoxSekeleton;
