import React from "react";

export default function BreadArrow() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      stroke-width="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      className="mx-1 h-6 w-6 text-gray-400 group-first:hidden md:mx-2"
      data-testid="flowbite-breadcrumb-separator"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5l7 7-7 7"
      ></path>
    </svg>
  );
}
