import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  CAR_EDIT_INQUIRY,
  EDIT_INQUIRY,
  STAY_EDIT_INQUIRY,
  TRAIN_EDIT_INQUIRY,
} from "../../../../../graphql/Mutation";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import Select from "../../../../../../../../../assets/img/Select.svg";
import SelectDark from "../../../../../../../../../assets/img/Select-dark.svg";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { SEND_EMAIL } from "~/config/Mutation";

export default function NewSuggestionSelect({
  inquiry_id,
  user_id,
  picked_by,
  suggestion_id,
  suggestion,
  inquiry,
  tab, // Add this to dynamically handle the type of suggestion
}: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailSend = useEmail();
  const userUserName = useUserDisplayName();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const client = useApolloClient();
  const userRole = useUserDefaultRole();
  const { travelHouse }: any = travelHouseStore();
  const userId = useUserId();

  const [darkMode, setDarkMode] = useState(false);
  console.log("sdnsamdbmas", suggestion);
  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const { data: travelHouseData } = useQuery(GET_TRAVEL_HOUSE_BY_ID, {
    variables: { id: travelHouse?.id },
    fetchPolicy: "network-only",
  });
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [editStaySuggestion] = useMutation(STAY_EDIT_INQUIRY);
  const [editCarSuggestion] = useMutation(CAR_EDIT_INQUIRY);
  const [editTrainSuggestion] = useMutation(TRAIN_EDIT_INQUIRY);
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const alert = useNotifications();

  const handleConfirm = async (event: any) => {
    event.stopPropagation();
    setIsLoading(true);

    const invoiceItems: any = [];
    console.log("asdbasmndbas", suggestion);

    // Generate invoice items based on the selected tab
    try {
      if (tab === "Stay") {
        suggestion.stay_inq_suggestion_costs.forEach((cost: any) => {
          invoiceItems.push({
            amount: cost.sale_price,
            description: `${cost?.stay_inq_room?.booked_by} `,
          });
        });
      } else if (tab === "Cars") {
        suggestion.car_inq_suggestion_costs.forEach((cost: any) => {
          invoiceItems.push({
            amount: cost.sale_price,
            description: `${cost?.car_inq_passenger?.first_name} ${cost?.car_inq_passenger?.last_name} (${cost?.car_inq_passenger?.type})`,
          });
        });
      } else if (tab === "Train") {
        suggestion.train_inq_suggestion_costs.forEach((cost: any) => {
          invoiceItems.push({
            amount: cost.sale_price,
            description: `${cost?.train_inq_passenger?.first_name} ${cost?.train_inq_passenger?.last_name} (${cost?.train_inq_passenger?.type})`,
          });
        });
      } else {
        suggestion.inq_suggestion_costs.forEach((cost: any) => {
          invoiceItems.push({
            amount: cost.sale_price,
            description: `${cost?.inq_passenger?.first_name} ${cost?.inq_passenger?.last_name} (${cost?.inq_passenger?.type})`,
          });
        });
      }

      // Dynamic Mutation and Notification Selection
      let mutation;
      let suggestionType;
      let dynamicId;

      if (tab === "Stay") {
        mutation = editStaySuggestion;
        suggestionType = "Stay Suggestion";
        dynamicId = inquiry?.stay_inq_details[0]?.id;
      } else if (tab === "Cars") {
        mutation = editCarSuggestion;
        suggestionType = "Car Suggestion";
        dynamicId = inquiry?.car_inq_details[0]?.id;
      } else if (tab === "Train") {
        mutation = editTrainSuggestion;
        suggestionType = "Train Suggestion";
        dynamicId = inquiry?.train_inq_details[0]?.id;
      } else {
        mutation = editInquiry;
        suggestionType = "Suggestion";
        dynamicId = inquiry_id;
      }
      console.log("Updating inquiry list...");
      // Update inquiry list
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });
      console.log("Executing mutation...");
      // Execute the dynamic mutation
      const res = await mutation({
        variables: { id: dynamicId, selected_suggestion_id: suggestion_id },
      });
      console.log("Mutation response:", res);
      let isSuccess = false;
      if (
        tab === "Stay" &&
        res.data?.update_stay_inq_detail?.returning?.length > 0
      ) {
        isSuccess = true;
      } else if (
        tab === "Cars" &&
        res.data?.update_car_inq_detail?.returning?.length > 0
      ) {
        isSuccess = true;
      } else if (
        tab === "Train" &&
        res.data?.update_train_inq_detail?.returning?.length > 0
      ) {
        isSuccess = true;
      } else if (res.data?.update_inq_list?.returning?.length > 0) {
        // Fallback for default inquiry
        isSuccess = true;
      }
      if (isSuccess) {
        // Dynamic notification based on role
        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has selected ${suggestionType} for you.`,
            "",
            ""
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your consultant has selected ${suggestionType} for you.`,
            "",
            ""
          );

          const variables: any = {
            inquiryNo: inquiry?.inquiry_no,
            userFirstName: inquiry?.users?.displayName,
            consultantName: userUserName,
            brandName: inquiry?.brd_list?.name,
          };

          // Send dynamic email
          await emailSend(
            5,
            inquiry.brd_id,
            inquiry.users?.id,
            inquiry.users?.email,
            inquiry.users?.phoneNumber,
            variables
          );
        }

        // Success toast notification
        toast.success(`${suggestionType} selected successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        // Reset Apollo client store
        await client.resetStore();
      }

      // Close modal and stop loading
      setModalOpen(false);
    } catch (error) {
      console.error("Error occurred during confirmation:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };

  return (
    <>
      <img
        src={darkMode ? SelectDark : Select}
        alt=""
        title="Select Suggestion"
        onClick={() => {
          setModalOpen(true);
        }}
        className="cursor-pointer"
      />

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white dark:bg-dark-primary rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700 dark:text-dark-primary">
              Are you sure you want to select this suggestion?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={(event: any) => setModalOpen(false)}
                className="px-5 py-1 font-semibold text-basic border border-basic rounded-lg"
              >
                No
              </button>
              <button
                onClick={(event: any) => handleConfirm(event)}
                className="px-5 py-1 font-semibold text-white bg-basic rounded-lg"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
