import React from "react";
import InquiresHead from "./components/InquiresPagination/InquiresHead";
import InquiryRow from "./components/InquiryRow";
import InquiresPagination from "./components/InquiresPagination";
import NoDataFound from "~/components/common/NoDataFound";
import { useQuery } from "@apollo/client";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";

export default function InquiryTable({
  inquires,
  page,
  total,
  setPage,
  userCol = true,
  travelHouse,
  hide
}: any) {
  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      {inquires.length > 0 ? (
        <>
          {/* <InquiresPagination
            count={inquires.length}
            page={page}
            total={total}
            setPage={setPage}
          /> */}
          <div className="flex flex-col min-h-[50vh]">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle ">
                <div className="overflow-hidden ">
                  <table className="min-w-full mt-5 rounded-t-lg bg-secondary shadow divide-y divide-gray-200  dark:divide-gray-700">
                    <InquiresHead userCol={userCol} hide={hide} />
                    <tbody className="bg-secondary shadow dark:bg-dark-secondary text-primary dark:text-dark-primary divide-y divide-gray-200  dark:divide-gray-700">
                      {inquires?.map((inquiry: any, index: any) => (
                        <InquiryRow
                          key={index}
                          inquiry={inquiry?.main_inq}
                          userCol={userCol}
                          hide={hide}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <InquiresPagination
            count={inquires.length}
            page={page}
            total={total}
            setPage={setPage}
          />
        </>
      ) : (
        <NoDataFound />
      )}
    </>
  );
}
