import { gql } from "@apollo/client";

export const GET_UNIVERSITY_COURSES = gql`
  query GetUniCourses($universityId: uuid!) {
    brd_universities(where: { id: { _eq: $universityId } }) {
      name
      brd_uni_courses {
        id
        name
        tuition_fees
        course_type
        duration
        intake
      }
    }
  }
`;

export const GET_FILTERED_UNIVERSITY_COURSES = gql`
  query SearchUniCourses(
    $universityId: uuid!
    $courseName: String
    $intake: String
    $courseType: String
  ) {
    brd_university_courses(
      where: {
        def_uni_id: { _eq: $universityId }
        _or: [
          {
            _or: [
              { name: { _ilike: $courseName } }
              { name: { _eq: "" } }
            ]
          }
          {
            _or: [
              { intake: { _ilike: $intake } }
              { intake: { _eq: "" } }
            ]
          }
          {
            _or: [
              { course_type: { _ilike: $courseType } }
              { course_type: { _eq: "" } }
            ]
          }
        ]
      }
    ) {
      id
      name
      course_type
      intake
      tuition_fees
      duration
    }
  }
`;

