import { TextField, Button, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_UNIVERSITY_WITH_ID } from "~/graphql/brd_universities/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

const EditUniversity = ({ selectedUniversity, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const [editUniversity] = useMutation(UPDATE_UNIVERSITY_WITH_ID);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    const res = await editUniversity({
      variables: {
        universityId: selectedUniversity?.id,
        name: formData?.uniName,
      },
    });

    if (res?.data?.update_brd_universities?.affected_rows > 0) {
      toast.success("University Updated Successfully!");
      client.refetchQueries({
        include: "all",
      });
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit University
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="University Name"
          variant="outlined"
          required
          fullWidth
          {...register("uniName")} // Registering the university input
          defaultValue={selectedUniversity?.name}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditUniversity;
