import { TextField, Button, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_UNIVERSITY_CAMPUS_WITH_ID } from "~/graphql/brd_university_campuses/Mutation";


const EditCampus = ({ selectedCampus, setSidePopup }: any) => {

  const { register, handleSubmit }: any = useForm<FormData>();
  const [editCampus] = useMutation(UPDATE_UNIVERSITY_CAMPUS_WITH_ID);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    const res = await editCampus({
      variables: {
        campusId: selectedCampus.id,
        name: formData.campusName,
        country: formData.country,
        city: formData.city,
        address: formData.address
      },
    });

    if (res?.data?.update_brd_university_campuses?.affected_rows > 0) {
      toast.success("University Campus Updated Successfully!");
      client.refetchQueries({
        include: "active",
      });
      setSidePopup(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Campus
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Campus Name"
          variant="outlined"
          fullWidth
          required
          {...register("campusName")}
          defaultValue={selectedCampus?.name}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Address"
          variant="outlined"
          fullWidth
          required
          {...register("address")}
          defaultValue={selectedCampus?.address}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="City"
          variant="outlined"
          fullWidth
          required
          {...register("city")}
          defaultValue={selectedCampus?.city}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Country"
          variant="outlined"
          fullWidth
          required
          {...register("country")}
          defaultValue={selectedCampus?.country}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditCampus;