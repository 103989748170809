import React, { useState } from "react";
import { useApolloClient, useQuery, useMutation } from "@apollo/client";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import { GET_BRD_PARTNERS } from "~/graphql/brd_partners/Query";
import { DELETE_BRD_PARTNER } from "~/graphql/brd_partners/Mutation";
import { nhost } from "~/lib/nhost";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SideDrawer from "~/components/common/sideDrawer";
import EditBrandPartner from "./EditBrandPartner";

const ShowBrandPartner = () => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const { brd_id } = useParams();
  const client = useApolloClient();
  const [partnerData, setPartnerData] = useState<any>(null);

  const { data, loading, error } = useQuery(GET_BRD_PARTNERS, {
    variables: { brdId: brd_id },
    skip: !brd_id, // Skip the query if brd_id is not available
  });

  const [deletePartnerMutation] = useMutation(DELETE_BRD_PARTNER);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const deletePartner = async (logo_id: any, partnerId: any) => {
    const { error: imageDeleteError } = await nhost.storage.delete({
      fileId: logo_id,
    });
    if (imageDeleteError) {
      toast.error("Error deleting logo.");
    }

    // Delete the partner data using the mutation
    try {
      const response = await deletePartnerMutation({
        variables: { partnerId },
      });
      if (response.data.delete_brd_partners.affected_rows > 0) {
        toast.success("Partner deleted successfully.");
        await client.refetchQueries({
          include: "all",
        });
      } else {
        toast.error("Error deleting partner.");
      }
    } catch (error) {
      toast.error("Error deleting partner.");
    }
  };

  const handleEdit = (partner: any) => {
    setOpenPopup(true);
    setPartnerData(partner);
  };

  return (
    <>
      <div className="flex gap-x-4 items-start justify-start">
        <h1 className="text-2xl font-bold mb-4 text-primary dark:text-dark-primary">
          Show Brand Partners
        </h1>
      </div>
      <>
        {data && data.brd_partners.length > 0 ? (
          <Grid container spacing={2}>
            {data.brd_partners.map((partner: any) => (
              <Grid item xs={12} sm={6} md={3} key={partner.id}>
                <Card className="shadow-lg rounded-lg max-h-[400px]">
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${process.env.REACT_APP_NHOST_STORAGE_URL}${partner.logo_id}`}
                    alt={partner.name}
                    className="rounded-t-lg h-[140px] object-cover"
                  />
                  <CardContent className="flex flex-col">
                    <Typography
                      variant="h6"
                      component="h2"
                      className="font-bold mb-2"
                      style={{ paddingBottom: 8 }}
                    >
                      {partner.name}
                    </Typography>
                    <Grid container spacing={1} className="mt-2">
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={() => handleEdit(partner)}
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={() =>
                            deletePartner(partner.logo_id, partner.id)
                          }
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <p>No partners available for the selected brand.</p>
        )}
      </>
      <>
        {openPopup && (
          <SideDrawer
            component={
              <EditBrandPartner
                partnerData={partnerData}
                setSidePopup={setOpenPopup}
              />
            }
            sidePopup={openPopup}
            setSidePopup={setOpenPopup}
          />
        )}
      </>
    </>
  );
};

export default ShowBrandPartner;
