import Layout from "~/components/common/Layout";
import ServicesModule from "~/modules/ServicesModule";
import CaseStudy from "~/modules/ServicesModule/segments/CaseStudy";

const CaseStudyPage = () => {
  return (
    <div>
      <Layout>
        {/* <ServicesModule> */}
          <CaseStudy />
        {/* </ServicesModule> */}
      </Layout>
    </div>
  );
};

export default CaseStudyPage;
