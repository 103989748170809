import { useMutation } from "@apollo/client";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Box
} from "@mui/material";
import { ADD_EMAIL_TEMPLATE } from "~/graphql/def_email_templates/Mutation";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState } from "react";
import ContentEditor from "~/components/common/ContentEditor";

const AddTemplates = () => {
  const [role, setRole] = useState(null);
  const [dynamicFrom, setDynamicFrom] = useState(null);
  const [activity, setActivity] = useState(null);
  const [content, setContent] = useState("")
  const [variables, setVariables] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [addTemplate] = useMutation(ADD_EMAIL_TEMPLATE);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      if (inputValue.trim() !== '') {
        setVariables((prev: any) => [...prev, inputValue.trim()]);
        setInputValue('');
      }
    }
  };

  const handleDelete = (chipToDelete: any) => {
    setVariables((chips: any) => chips.filter((chip: any) => chip !== chipToDelete));
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };


  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const handleDynamicFromChange = (event: any) => {
    setDynamicFrom(event.target.value);
  };

  const handleActivityChange = (event: any) => {
    const value = event.target.value;
    setActivity(value === "" ? null : value);
  };

  const onSubmit = async (formData: any) => {
    const payload = {
      name: formData?.templateName,
      for_role: role,
      variables_allowed: variables.join(', '),
      subject: formData?.subject,
      content: content,
      whatsapp_template_name: formData?.whatsappTempName,
      notification_content: formData?.notificationContent,
      dynamic_from: dynamicFrom,
      dynamic_from_label: formData?.dynamicFromLabel,
      is_activity: activity,
    };

    const resInsertTemp = await addTemplate({
      variables: {
        data: [payload],
      },
    });

    if (resInsertTemp?.data?.insert_def_emails_templates?.affected_rows > 0) {
      toast.success("Template Added Successfully!");
    }
  };

  return (
    <div className="w-full rounded-lg p-5 bg-secondary dark:bg-dark-secondary">
      <h1 className="text-xl text-primary dark:text-dark-primary mb-6">Add Email Template</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="text-primary dark:text-dark-primary flex-col">
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-2 gap-3">
            <TextField
              fullWidth
              error={errors["templateName"] ? true : false}
              {...register("templateName", { required: true })}
              label="Name"
            />
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select label="Role" value={role} onChange={handleRoleChange}>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="consultant">Consultant</MenuItem>
                <MenuItem value="accountant">Accountant</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            fullWidth
            label="Variables"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  {variables.map((variable: any, index: any) => (
                    <Chip
                      key={index}
                      label={variable}
                      onDelete={() => handleDelete(variable)}
                      size="small"
                    />
                  ))}
                </Box>
              ),
            }}
          />
          <div className="flex flex-col gap-1">
            <label htmlFor="" className="text-primary dark:text-dark-primary">Email Content</label>
            <ContentEditor content={content} setContent={setContent} />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <TextField
              fullWidth
              error={errors["subject"] ? true : false}
              {...register("subject", { required: true })}
              label="Subject"
            />
            <TextField
              fullWidth
              error={errors["whatsappTempName"] ? true : false}
              {...register("whatsappTempName", { required: true })}
              label="WhatsApp Template Name"
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <TextField
              fullWidth
              error={errors["notificationContent"] ? true : false}
              {...register("notificationContent", { required: true })}
              label="Notification Content"
            />
            <FormControl fullWidth>
              <InputLabel>Dynamic From</InputLabel>
              <Select
                label="Dynamic From"
                value={dynamicFrom}
                onChange={handleDynamicFromChange}
              >
                <MenuItem value="true">true</MenuItem>
                <MenuItem value="false">false</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <TextField
              fullWidth
              error={errors["dynamicFromLabel"] ? true : false}
              {...register("dynamicFromLabel", { required: true })}
              label="Dynamic From Label"
            />
            <FormControl fullWidth>
              <InputLabel>Activity</InputLabel>
              <Select
                label="Activity"
                value={activity}
                onChange={handleActivityChange}
              >
                <MenuItem value="true">true</MenuItem>
                <MenuItem value="false">false</MenuItem>
                <MenuItem value="">null</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <Button type="submit" variant="contained">
            Add Template
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTemplates;
