// import { Button } from "@mui/material";

// const LinkedInOrganizationLogIn = () => {
//   return (
//     <>
//       <div className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary  flex-col rounded-lg shadow ">
//         <div className="flex justify-between items-center">
//           <div>
//             <h3 className="mb-2 text-xl font-semibold">LinkedIn Page</h3>
//             <p>Connect Your LinkedIn Page Data</p>
//           </div>
//           <div>
//             <Button variant="outlined">Connect</Button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default LinkedInOrganizationLogIn;

import React from "react";
import { Button } from "@mui/material";

const LinkedInOrganizationLogIn: React.FC = () => {
  const getAuthCode = () => {
    const currentURL = window.location.href; // This will include the entire URL including path

    const serverlessFunctionURL =
      "http://localhost:5642/api/socialmedia/linkedin/authenticate";
    const organizationLogin = "page";

    window.location.href = `${serverlessFunctionURL}?origin=${encodeURIComponent(
      currentURL
    )}&type=${organizationLogin}`;
  };

  return (
    <>
      <div className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="mb-2 text-xl font-semibold">LinkedIn Page</h3>
            <p>Connect Your LinkedIn Page Data</p>
          </div>
          <div>
            <Button variant="outlined" onClick={getAuthCode}>
              Connect
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedInOrganizationLogIn;
