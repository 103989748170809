import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import useNotifications from "hooks/useNotifications";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EDIT_INQUIRY } from "./graphql/Mutation";

import BlankProfile from "../../../../assets/img/BlankProfile.png";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { SEND_EMAIL } from "~/config/Mutation";
import moment from "moment";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData } from "@nhost/react";

export default function InquiryCardHead({ inquiry, show }: any) {
  const userDefaultRole = useUserDefaultRole();
  const userDisplayName = useUserDisplayName();
  const userId = useUserId();

  const { travelHouse }: any = travelHouseStore();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const userData = useUserData();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  const [isloading, setIsloading] = useState(false);
  const client = useApolloClient();
  const alert: any = useNotifications();
  // ;
  const [editInquiry] = useMutation(EDIT_INQUIRY);

  // Navigation hooks
  const navigate = useNavigate();

  // Accept Inquiry Button
  const accept = async () => {
    setIsloading(true);
    try {
      // Create passenger function call
      const res = await editInquiry({
        variables: { id: inquiry.id, picked_by_id: userId },
      });
      if (res.data?.update_inq_list?.returning?.length > 0) {
        const userEmail =
          userDefaultRole === "user"
            ? inquiry.picked_user?.email
            : inquiry.users?.email;

        const variables = {
          from: {
            th_name: inquiry.brd_list.name,
          },
          to: {
            email: userEmail,
          },
          variables: {
            consultant_name: userDisplayName,
            url: inquiry?.brd_list?.subdomain ?? "",
            th_name: inquiry.brd_list.name,
          },
        };
        // ;
        const { emails, body, subject } =
          CustomerTemplates.sendDedicatedConsultantEmail(variables);
        // const emailResp = await sendEmail({
        //   variables: {
        //     emails: emails,
        //     body: body,
        //     subject,
        //     from: inquiry?.brd_list?.domain ?? null,
        //   },
        // });
        try {
          const emailData = {
            emails: emails,
            body: body,
            subject,
            from: inquiry?.brd_list?.domain ?? null,
          };
          if (travelHouseData?.thp_list[0]?.email_refresh_token) {
            // await sendEmailGmail({
            //   variables: {
            //     refresh_token:
            //       travelHouseData?.thp_list[0]?.email_refresh_token,
            //     sendEmail: true,
            //     to: emails,
            //     subject: subject,
            //     body: body,
            //     from: userData?.metadata?.alias,
            //     th_id: travelHouse?.id,
            //   },
            // });
            const response = await client.query({
              query: GET_EMAILS,
              variables: {
                refresh_token:
                  travelHouseData?.thp_list[0]?.email_refresh_token,
                sendEmail: true,
                to: emails,
                subject: subject,
                body: body,
                from: userData?.metadata?.alias,
                th_id: travelHouse?.id,
                alias: false,
              },
            });
          } else {
            const emailResp = await sendGenericEmail(emailData, sendEmail);
          }
          // Handle success or do any additional logic here
        } catch (error) {
          // Handle error
          console.error("Error sending email:", error);
        }
        toast.success("Picked Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await alert.newNotification(
          inquiry?.users?.id,
          `Your Consultant Picked your Inquiry `,
          "",
          ``
        );

        await client.resetStore();

        navigate(`../inquiry/${inquiry.inquiry_no}`);
      }

      // setIsloading(false); // change button loading state
      // setIsloading(false);
    } catch (e: any) {
      // setIsloading(false);
      console.error("error", e);
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      {show && (
        <div className="self-stretch  bg-[#D4EFDF] p-[15px] items-center justify-start text-sm">
          <div>
            {getTimeReadableFormat(inquiry?.created_at)}
            {/* {moment(inquiry?.created_at).format("DD MMM, YYYY hh:mm A")} */}
          </div>
          <div className=" flex flex-row ">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              <img
                className="relative rounded-45xl w-[24.97px] h-[25px] overflow-hidden shrink-0 object-cover"
                alt=""
                src={BlankProfile}
              />
              <div className="relative leading-[20px]  flex items-center  shrink-0">
                {userDefaultRole === "user"
                  ? inquiry.picked_user?.displayName
                  : inquiry.users?.displayName}
              </div>
              {userDefaultRole === "user" && !inquiry?.picked_by && (
                <>Consultant not assign</>
              )}
              <div className="relative leading-[20px]  flex items-center w-[7.99px] shrink-0">
                |
              </div>
              {userDefaultRole === "user"
                ? inquiry.picked_user?.email
                : inquiry.users?.email}

              <div> | {inquiry.brd_list.name}</div>
            </div>
            <div className="flex-1 flex flex-row items-center justify-center gap-[20px]">
              <div className="flex-1 flex flex-row items-center justify-end gap-[13px]">
                <img
                  className="relative w-[12.49px] h-[12.5px]"
                  alt=""
                  src="/vector13.svg"
                />
                {userDefaultRole === "user" ? (
                  <div className="relative leading-[20px] ">
                    {inquiry?.picked_user?.phoneNumber ||
                      inquiry?.picked_user?.metadata?.phone}
                  </div>
                ) : (
                  <>
                    {userDefaultRole !== "consultant" ? (
                      <>
                        {inquiry?.picked_by ? (
                          <div className="relative leading-[20px] ">
                            <strong>PICKED BY: </strong>
                            {inquiry.picked_user?.displayName}
                          </div>
                        ) : (
                          <div className="relative leading-[20px] ">
                            {inquiry?.users?.phoneNumber ||
                              inquiry?.users?.metadata?.phone}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="relative leading-[20px] ">
                        {inquiry?.users?.phoneNumber ||
                          inquiry?.users?.metadata?.phone}
                      </div>
                    )}
                  </>
                )}
              </div>

              {inquiry.picked_by ? (
                <Link
                  to={`../inquiry/${inquiry.inquiry_no}`}
                  className="rounded-[50px] cursor-pointer bg-mediumseagreen w-[114px] flex flex-row py-[7px] px-[15px] box-border items-center justify-center text-white-100"
                >
                  <div className="relative font-medium">Detail</div>
                </Link>
              ) : userDefaultRole !== "user" ? (
                <div className="rounded-[50px] cursor-pointer bg-mediumseagreen w-[114px] flex flex-row py-[7px] px-[15px] box-border items-center justify-center text-white-100">
                  <div
                    className="relative font-medium"
                    onClick={() => {
                      accept();
                    }}
                  >
                    {isloading ? "Accepting.." : "Accept"}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
