// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected route
import { ProtectedRoute } from "./ProtectedRoute";
// Import pages
import ProfilePage from "../pages/accountant/ProfilePage";
import Dashboard from "../pages/accountant/Dashboard";
import CustomerPaymentsPage from "~/pages/accountant/CustomerPaymentsPage";
import InboxPage from "~/pages/admin/Inbox";
import VendorsPage from "~/pages/accountant/VendorsPage";
import PaymentMethodsPage from "~/pages/accountant/PaymentMethodsPage";
import TransactionsPage from "~/pages/accountant/TransactionsPage";
import AccountsPage from "~/pages/accountant/AccountsPage";
import AttendancePage from "~/pages/accountant/AttendancePage";
import ProcessPayrollPage from "~/pages/accountant/ProcessPayrollPage";

export default function AccountantRoutes() {
  return (
    <Routes>
      <Route path="/accountant" element={<ProtectedRoute role="accountant" />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="inbox" element={<InboxPage />} />
        <Route path="vendors" element={<VendorsPage />} />
        <Route path="customer-payments" element={<CustomerPaymentsPage />} />
        <Route path="payment-methods" element={<PaymentMethodsPage />} />
        <Route path="transactions" element={<TransactionsPage />} />
        <Route path="accounts" element={<AccountsPage />} />
        <Route path="attendance" element={<AttendancePage />} />
        <Route path="process-payroll" element={<ProcessPayrollPage />} />
      </Route>
    </Routes>
  );
}
