import { useMutation } from "@apollo/client";
import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { WHATASPP_SETTING_INSERT } from "~/graphql/brd_whatsapp_setting/Mutation";
import useAutoReplyDetails from "~/hooks/useAutoReplyDetails";
import Message from "./component/Message";
import Footer from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Footer";
import AttachemnetPrevSend from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/AttachemnetPrevSend";

const AutoReplyMessage = ({ brandID }: any) => {
  const { autoReplyDetail } = useAutoReplyDetails(brandID);

  const [showEmojis, setShowEmojis] = useState(false);
  const [attachmentType, setAttachmentType] = useState(null);
  const [files, setFiles] = useState<any>([]);

  const [whatsappSettingUpdate] = useMutation(WHATASPP_SETTING_INSERT);

  const sendAutoReplyMsg = async ({ message, type }: any) => {
    await whatsappSettingUpdate({
      variables: {
        brd_id: brandID,
        data: {
          auto_reply: message,
          auto_reply_type: type,
          brd_id: brandID,
          auto_reply_active: true,
        },
      },
    });
  };

  return (
    <>
      {brandID &&
        (files.length > 0 ? (
          <div className="flex-grow bg-whatsappPrimaryBg dark:bg-dark-secondary max-h-full overflow-scroll">
            <AttachemnetPrevSend
              attachmentType={attachmentType}
              setFiles={setFiles}
              files={files}
              setShowEmojis={setShowEmojis}
              showEmojis={showEmojis}
              messageType="autoReply"
              sendAutoReplyMsg={sendAutoReplyMsg}
            />
          </div>
        ) : (
          <>
            <div className="bg-[#EFEAE2] dark:bg-[#38344e] py-[20px] max-h-full overflow-scroll">
              {autoReplyDetail?.map((message: any, ind: any) => (
                <Message
                  key={ind}
                  files={files}
                  message={message}
                  brandID={brandID}
                  attachmentType={attachmentType}
                  setFiles={setFiles}
                  setShowEmojis={setShowEmojis}
                  showEmojis={showEmojis}
                />
              ))}
            </div>
            <Footer
              footerType="autoReply"
              brandID={brandID}
              sendMessage={sendAutoReplyMsg}
              setShowEmojis={setShowEmojis}
              showEmojis={showEmojis}
              attachmentType={attachmentType}
              setAttachmentType={setAttachmentType}
              setFiles={setFiles}
              files={files}
            />
          </>
        ))}
    </>
  );
};

export default AutoReplyMessage;
