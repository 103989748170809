import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query CustomerQuery(
    $thp_id: uuid!
    $timestart: timestamptz
    $timeend: timestamptz
    $datestart: date
    $dateend: date
  ) {
    users(
      where: {
        _or: [
          { thp_list: { id: { _eq: $thp_id } } }
          {
            brd_users: {
              thp_id: { _eq: $thp_id }
              users: {
                _and: [
                  { defaultRole: { _neq: "user" } }
                  { defaultRole: { _neq: "vendor" } }
                ]
              }
            }
          }
        ]
      } # order_by: { brd_users: { users: { defaultRole: "desc" } } }
    ) {
      id
      email
      brd_users {
        thp_list {
          brd_lists {
            id
          }
        }
      }
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      def_user_activities(
        where: { timestamp: { _gte: $timestart, _lt: $timeend } }
        order_by: { timestamp: asc }
      ) {
        id
        timestamp
        action
        activity_id
        connection_id
        user_id
      }
      thp_attendances(
        where: { date: { _gte: $datestart, _lt: $dateend } }
        order_by: { date: asc }
      ) {
        hours
        minutes
      }
      thp_users_profile {
        basic_salary
      }
    }
  }
`;
