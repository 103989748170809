// ModalWrapper.js
import React from "react";
import { FaTimes } from "react-icons/fa";

const SuggestionModelWraper = ({ isVisible, onClose, children }: any) => {
  if (!isVisible) return null;

  return (
    <div className="fixed left-0 top-0 w-full h-screen overflow-y-scroll flex justify-end items-start z-50 bg-[#00000070]">
      <div className="modal-container min-h-screen relative bg-white dark:bg-dark-secondary shadow-lg p-6 w-full overflow-y-auto py-4 max-w-[1024px] z-[60] justify-center items-start text-primary">
        <div>
          <h2 className="text-xl font-bold mb-4 text-primary dark:text-dark-primary text-center w-full">
            Create Suggestion
          </h2>
          <hr className="border-gray-300  border-dashed my-4" />
          <span
            onClick={onClose}
            className="absolute text-primary dark:text-dark-primary right-[10px] top-[5px] cursor-pointer "
          >
            <FaTimes />
          </span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SuggestionModelWraper;
