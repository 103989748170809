import { gql } from "@apollo/client";

export const INSERT_CAMPUS_COURSE = gql`
  mutation insertCampusCourse($campusId: uuid!, $courseId: uuid!) {
    insert_uni_campus_courses(objects: { campus_id: $campusId, course_id: $courseId }) {
      affected_rows
    }
  }
`;

export const DELETE_CAMPUS_COURSE_WITH_ID = gql`
  mutation deleteCampusCourse($courseId: uuid!) {
    delete_uni_campus_courses(where: { id: { _eq: $courseId } }) {
      affected_rows
    }
  }
`;

