import { Button, TextField, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { INSERT_UNIVERSITY_COURSE } from "~/graphql/brd_uni_courses/Mutation";
import { toast } from "react-toastify";

const AddCourse = ({ uniId, setSidePopup }: any) => {

    const client = useApolloClient();
    const { register, handleSubmit } = useForm();
    const [insertUniCourse] = useMutation(INSERT_UNIVERSITY_COURSE)

    const onSubmit = async (data: any) => {
        const res = await insertUniCourse({
            variables: {
                tuitionFees: data.tuitionFee,
                name: data.course,
                courseType: data.course_type,
                duration: data.duration,
                intake: data.intake,
                universityId: uniId,
            },
        });

        if (res?.data?.insert_brd_university_courses?.affected_rows > 0) {
            client.refetchQueries({
                include: "all",
            });
            toast.success("University Course Added Successfully!");
            setSidePopup(false);
        }
    };

    return (
        <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
            <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
                Add University Course
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Course Name"
                    variant="outlined"
                    fullWidth
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                    {...register("course")}
                />
                <FormControl variant="outlined" fullWidth required style={{ marginBottom: "20px" }}>
                    <InputLabel id="courseType-label">Course Type</InputLabel>
                    <Select
                        labelId="courseType-label"
                        label="CourseType"
                        {...register("course_type")}
                    >
                        <MenuItem value="UG">UG</MenuItem>
                        <MenuItem value="PG">PG</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Tuition Fee"
                    variant="outlined"
                    fullWidth
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                    {...register("tuitionFee")}
                />
                <TextField
                    label="Duration"
                    variant="outlined"
                    fullWidth
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                    {...register("duration")}
                />
                <TextField
                    label="Intake"
                    variant="outlined"
                    fullWidth
                    required
                    style={{
                        marginBottom: "20px",
                    }}
                    {...register("intake")}
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Add
                </Button>
            </form>
        </div>
    )
}

export default AddCourse