import { useApolloClient, useQuery, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_AUTO_REPLY_SETTING } from "~/graphql/brd_whatsapp_setting/Query";

export default function useAutoReplyDetails(brandID: any) {
  const { loading, error, data } = useSubscription(GET_AUTO_REPLY_SETTING, {
    variables: {
      brd_id: brandID,
    },
  });

  return {
    autoReplyDetail: data?.brd_whatsapp_setting,
  };
}
