// import React, { useState } from "react";
// import { Box, FormControl, Tab, Tabs, Typography } from "@mui/material";
// import add from "../../assets/img/Add.svg";
// import SidePopup from "~/components/common/SidePopup";
// import ExtendInquiry from "../../../InquiryAdd/NewInquiryAdd/ExtendInquiry";
// import GeneralModelWrapper from "~/components/common/GeneralModelWrapper";


// const InquiryTabs = ({ value, tabs, handleChange, inquiryId}: any) => {

//   console.log("asncnjs", tabs)
//   const [showAddModal, setShowAddModal] = useState(false);

//   const toggleModal = () => {
//     setShowAddModal(!showAddModal);
//   };

//   const closeModal = () => setShowAddModal(false);
//   console.log("asnmbdasndbams", tabs);
//   return (
//     <>
//       <FormControl fullWidth>
//         <Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             backgroundColor: "white",
//             boxShadow: 1,
//             mt: 4,
//             p: 1,
//           }}
//         >
//           {tabs.length === 0 ? (
//             <Typography className="p-3">No Details Available</Typography>
//           ) : (
//             <>
//               <Tabs
//                 value={value}
//                 onChange={handleChange}
//                 aria-label="inquiry tabs"
//                 sx={{ flexGrow: 1 }}
//               >
//                 {tabs.map((tab: any, index: any) => (
//                   <Tab key={index} label={tab.label} />
//                 ))}
//               </Tabs>
//               <img
//                 src={add}
//                 alt="Add Suggestion"
//                 title="Add Suggestion"
//                 className="cursor-pointer"
//                 style={{
//                   marginLeft: "auto",
//                   width: "30px",
//                   height: "30px",
//                   borderRadius: "50%",
//                 }}
//                 onClick={toggleModal}
//               />
//             </>
//           )}
//         </Box>
//       </FormControl>

//       {showAddModal && (
//         <>
//           <GeneralModelWrapper isVisible={showAddModal} onClose={closeModal}>
//             <ExtendInquiry defaultTabs={tabs} inquiryId={inquiryId} />
//           </GeneralModelWrapper>
//         </>
//       )}
//     </>
//   );
// };

// export default InquiryTabs;

import React, { useState } from "react";
import { Box, FormControl, Tab, Tabs, Typography } from "@mui/material";
import add from "../../assets/img/Add.svg";
import SidePopup from "~/components/common/SidePopup";
import ExtendInquiry from "../../../InquiryAdd/NewInquiryAdd/ExtendInquiry";
import GeneralModelWrapper from "~/components/common/GeneralModelWrapper";
import { options } from "../../../InquiryAdd/data/options";

const InquiryTabs = ({ value, tabs, handleChange, inquiryId }: any) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const toggleModal = () => {
    setShowAddModal(!showAddModal);
  };

  const closeModal = () => setShowAddModal(false);

  const optionNames = options.map((option) => option.name);

  const allOptionsInTabs = optionNames.every((name) =>
    tabs.some((tab: any) => tab.label === name)
  );

  return (
    <>
      <FormControl fullWidth>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: 1,
            mt: 4,
            p: 1,
          }}
        >
          {tabs.length === 0 ? (
            <Typography className="p-3">No Details Available</Typography>
          ) : (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="inquiry tabs"
                sx={{ flexGrow: 1 }}
              >
                {tabs.map((tab: any, index: any) => (
                  <Tab key={index} label={tab.label} />
                ))}
              </Tabs>
              {!allOptionsInTabs && (
                <img
                  src={add}
                  alt="Add Suggestion"
                  title="Add Suggestion"
                  className="cursor-pointer"
                  style={{
                    marginLeft: "auto",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                  }}
                  onClick={toggleModal}
                />
              )}
            </>
          )}
        </Box>
      </FormControl>

      {showAddModal && (
        <GeneralModelWrapper isVisible={showAddModal} onClose={closeModal}>
          <ExtendInquiry defaultTabs={tabs} inquiryId={inquiryId} />
        </GeneralModelWrapper>
      )}
    </>
  );
};

export default InquiryTabs;
