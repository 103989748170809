import { Box, Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import Stripe from "stripe";
import {
  createCustomer,
  createInvoice,
  createInvoiceItems,
  sendInvoice,
} from "~/utils/Stripe/stripeFunctions";
import { ADD_TRANSACTIONS } from "~/modules/InquiriesModule/segments/InquiryDetail/components/PaymentsCard/components/PaymentAdd/graphql/Mutations";
import { toast } from "react-toastify";
import useNotifications from "~/hooks/useNotifications";
import { useUserDefaultRole } from "@nhost/react";

const StripePayment = ({
  mainHeading,
  inquiry,
  setSidePopup,
  paymentMethod,
  stripe,
}: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isloading, setIsloading] = useState(false);

  const [addTransactions] = useMutation(ADD_TRANSACTIONS);
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    setIsloading(true);
    formData["inq_id"] = inquiry.id; // add inquiry id to formdata
    formData["payment_method_id"] = stripe.id || stripe.id; // add travelhouse id to formdata
    formData["status"] = "pending"; // add status  to formdata

    const tenDigitCode: any = generateRandom10DigitCode();
    formData["transactions_no"] = tenDigitCode; // add status  to formdata
    // const customer = await createCustomer();
    const stripeHelper = new Stripe(stripe.stripe_secret, {
      apiVersion: "2022-11-15",
    });
    createCustomer(
      stripeHelper,
      inquiry.users.email,
      inquiry.users.displayName
    ).then((customer: any) => {
      createInvoice(
        stripeHelper,
        customer.id,
        new Date(formData["due_date"])
      ).then((invoice: any) => {
        createInvoiceItems(
          stripeHelper,
          customer.id,
          formData["amount"] * 100,
          "gbp",
          invoice.id
        ).then((invoiceItem: any) => {
          sendInvoice(stripeHelper, invoice?.id).then(
            async (invoiceRes: any) => {
              // Invoice Crated
              formData["receipt"] = invoiceRes.hosted_invoice_url;
              formData["ref_no"] = invoice?.id; // add receipt to formdata
              delete formData["due_date"];

              try {
                // Create Suggestion function call
                const res = await addTransactions({
                  variables: { transactions: [formData] },
                });
                if (res.data?.insert_inq_transection?.returning?.length > 0) {
                  toast.success("Transaction added successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });

                  if (userRole === "user") {
                    await alert.newNotification(
                      inquiry.picked_by,
                      `Customer created the stripe payment, please check your portal`,
                      "",
                      ``
                    );
                  } else {
                    await alert.newNotification(
                      inquiry?.users?.id,
                      `Consultant created the stripe payment,, please check your portal`,
                      "",
                      ``
                    );
                  }

                  await client.refetchQueries({
                    include: "all", // Consider using "active" instead!
                  });
                }
                setIsloading(false);
                setSidePopup(false); // hide form modal
              } catch (e) {
                setIsloading(false);
                setSidePopup(false);
                console.error("error", e);
              }
            }
          );
        });
      });
    });
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid grid-cols-1 gap-5 mt-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Controller
                rules={{
                  required: true,
                }}
                {...register("due_date", {
                  required: true,
                })}
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <DatePicker
                    value={value}
                    inputRef={ref}
                    onChange={(date: any) => {
                      onChange(date?.$d.toString());
                    }}
                    sx={{ width: "100%" }}
                    label="Due Date"
                    slotProps={{
                      textField: {
                        error: errors["due_date"] ? true : false,
                      },
                    }}
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
          <TextField
            error={errors["amount"] ? true : false}
            {...register("amount", {
              required: true,
            })}
            label="Amount"
            type="number"
          />
        </div>
        <Button
          type="submit"
          sx={{ marginTop: "25px" }}
          variant="contained"
          fullWidth
        >
          {isloading ? "Adding..." : mainHeading}
        </Button>
      </Box>
    </>
  );
};

export default StripePayment;
