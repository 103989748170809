import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { GET_BRAND_DETAILS, GET_FLIGHT_PAGES_BY_SLUG } from "./graphql/Query";
import SeoSettings from "./components/SeoSettings";
import DestinationDetail from "./components/DestinationDetail/DestinationDetail";
import { useFileUpload } from "@nhost/react";
import { INSERT_DESTINATION } from "./components/DestinationDetail/graphql/Mutation";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const DestinationAdd = () => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_BRAND_DETAILS, {
    variables: { brd_id: brd_id },
  });

  const [getFlightPages] = useLazyQuery(GET_FLIGHT_PAGES_BY_SLUG);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const [insertDestination] = useMutation(INSERT_DESTINATION);
  const navigate = useNavigate();
  const client = useApolloClient();
  const { reset } = useForm();

  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [featuredFile, setFeatureFile] = useState<any | null>(null);

  if (loading) return <p>Loading ...</p>;
  // ;
  const brand = data?.brd_list?.at(0);

  const insertDestinationData = async (inpData: any) => {
    const rzt = await getFlightPages({
      variables: {
        slug: inpData?.slug,
        id: brand.id,
      },
    });
    if (rzt?.data?.brd_flights_pages?.at(0)?.id) {
      toast.error("Destination already exists!");
      return 0;
    }
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["backgroundImage"] = logoUploadRes?.id || null;
    }
    if (featuredFile) {
      const featuredFileRes = await uploadLogo(featuredFile);
      inpData["featuredImage"] = featuredFileRes?.id || null;
    }
    const res = await insertDestination({
      variables: {
        data: {
          brd_id: brand.id,
          origin_name: inpData?.departure?.name,
          destination_name: inpData?.arrival?.name,
          origin_iata: inpData?.departure?.iata_code,
          destination_iata: inpData?.arrival?.iata_code,
          featured_image: inpData?.backgroundImage,
          featured_destination_image: inpData?.featuredImage,
          show_in_footer: inpData?.footerFlightLinks,
          main_heading: inpData?.mainHeading,
          airline_iata: inpData?.airline.iata,
          base_price: inpData?.basePrice ? inpData?.basePrice : 0,
          slug: inpData?.slug
            ? inpData?.slug
            : `${inpData?.departure?.city_name
                ?.toLowerCase()
                ?.replaceAll(" ", "-")}-to-${inpData?.arrival?.city_name
                ?.toLowerCase()
                ?.replaceAll(" ", "-")}`,
          show_in_home: inpData?.homePageHotDestination,
          show_featured_destination: inpData?.featureDestination,
        },
      },
    });
    if (res?.data?.insert_brd_flights_pages_one?.id) {
      reset();
      toast.success("Destination added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
      navigate(
        `/admin/brands/${brand.id}/destinations/update/${res?.data?.insert_brd_flights_pages_one?.id}`
      );
    }
  };

  if (brand) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 ">
        <div className="mb-4 col-span-full xl:mb-2">
          <DestinationDetail
            insertDestinationData={insertDestinationData}
            setLogoFile={setLogoFile}
            setFeatureFile={setFeatureFile}
          />
          {/* <DestinationDetail brand={brandData} /> */}
          {/* <SeoSettings brand={brandData} /> */}
        </div>
      </div>
    );
  } else {
    return <p>No brand details found</p>;
  }
};

export default DestinationAdd;
