import Layout from "~/components/common/Layout";

import AddEmailComp from "~/modules/BrandModule/modules/MarketingModule/segments/EmailCampaigns/components/AddEmailCampaign";

export default function NewEmailCampaign() {
  return (
    <div>
      <Layout>
        <AddEmailComp />
      </Layout>
    </div>
  );
}
