import React from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { useState } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useParams } from "react-router-dom";
import { getTimeReadableFormatInq } from "~/utils/GetTimeReadable";
import InquiryCard from "~/modules/InquiriesModule/components/InquiryCard";
import SuggestionBox from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/SuggestionBox";
import WhatsappMessage from "./components/WhatsappMessage";
import UserName from "./components/UserName";
import { GET_ACTIVITY } from "~/graphql/brd_notifications/Subscription";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
export default function Timeline() {
  const { customerId } = useParams();
  const { data, loading, error } = useSubscription(GET_ACTIVITY, {
    variables: { user_id: customerId },
  });

  if (loading) return <>loading</>;
  if (error) return <>Er</>;
  const dataList = data.brd_notifications;
  const dataListNew = dataList.sort((a: any, b: any) =>
    b.created_at.localeCompare(a.created_at)
  );

  return (
    <div className="flex-1">
      <h2 className="mb-2 text-[16px] font-[500]">Recent Activity</h2>
      <hr />
      <div className="align-left text-left ">
        {dataListNew.map((noti: any, index: any) => {
          return (
            <TimelineItem position="right">
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {getTimeReadableFormatInq(noti?.created_at)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                {/* {index + 1 < dataListNew.length && <TimelineConnector />} */}
                <TimelineConnector />
                <TimelineDot
                  color="primary"
                  variant={`${
                    noti.user_id === customerId ? "outlined" : "filled"
                  }`}
                >
                  {/* <HotelIcon /> */}
                  <WhatsAppIcon />
                </TimelineDot>
                {/* {index !== 0 && <TimelineConnector />} */}
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                {/* <Typography variant="h6" component="span">
                  <span className="text-[gray] text-sm w-full">
                    {noti?.template?.name || "not set"}
                  </span>
                </Typography> */}
                <Typography variant="h6" component="span">
                  {noti?.template?.name || "not set"}
                </Typography>
                {/* <Typography>Because this is the life you love!</Typography> */}

                {(noti?.template?.id === 38 ||
                  noti?.template?.id === 37 ||
                  noti?.template?.id === 41 ||
                  noti?.template?.id === 42) && (
                  <Typography>
                    <div className="flex flex-col">
                      <p className="w-[370px] truncate text-[gray] text-sm">
                        {noti.payload?.text}
                      </p>{" "}
                      {/* {noti.payload?.sender_id && (
                        <UserName user_id={noti.payload?.sender_id} />
                      )} */}
                    </div>
                  </Typography>
                )}
                {/* <div className={`flex  justify-end`}>
                  {(noti?.template?.id === 35 ||
                    noti?.template?.id === 34 ||
                    noti?.template?.id === 15 ||
                    noti?.template?.id === 36) && (
                    <div className="w-[500px]">
                      <InquiryCard
                        inquiry={noti?.payload}
                        header={false}
                        height="auto"
                      />
                    </div>
                  )}

                  {noti?.template?.id === 39 && (
                    <div className="w-[auto]">
                      <SuggestionBox
                        active={true}
                        suggestion={noti?.payload}
                        hideAction={true}
                      />
                    </div>
                  )}

                  {(noti?.template?.id === 38 ||
                    noti?.template?.id === 37 ||
                    noti?.template?.id === 41 ||
                    noti?.template?.id === 42) && (
                    <div className="w-[auto]">
                      <div className="bg-[white] w-[400px] p-4 border-[lgihtgray] border-[1px] rounded-md justify-start flex flex-col">
                        
                        <WhatsappMessage payload={noti.payload} />
                        {noti.payload?.sender_id && (
                          <UserName user_id={noti.payload?.sender_id} />
                        )}
                      </div>
                    </div>
                  )}
                </div> */}
              </TimelineContent>
            </TimelineItem>
          );
        })}
        {dataListNew.length < 1 && <div>No data found</div>}
      </div>
    </div>
  );
}
