import { gql } from "@apollo/client";

export const DEFAULT_PAYMENT_METHODS = gql`
  query DefaultQuery {
    def_stripe {
      secret_key
      public_key
      id
    }
  }
`;
