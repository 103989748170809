import { gql } from "@apollo/client";

export const UPDATE_CONVERSATION_MESSAGE = gql`
  mutation MyMutation(
    $brand_phone: String
    $user_phone: String
    $timestamp: timestamptz
    $text: String
  ) {
    update_brd_whatsapp_conversations(
      where: {
        brand_phone: { _eq: $brand_phone }
        user_phone: { _eq: $user_phone }
      }
      _set: { last_message_time: $timestamp, last_message: $text }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_CONVERSATION = gql`
  mutation MyMutation($data: [brd_whatsapp_conversations_insert_input!]!) {
    insert_brd_whatsapp_conversations(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_MESSAGES = gql`
  mutation MyMutation($data: [brd_whatsapp_messages_insert_input!]!) {
    insert_brd_whatsapp_messages(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_FAILED_CONV = gql`
  mutation MyMutation {
    delete_brd_whatsapp_conversations(
      where: {
        brd_whatsapp_messages: {
          brd_whatsapp_messages_statuses: {
            brd_whatsapp_message: {
              brd_whatsapp_messages_statuses: { key: { _eq: "failed" } }
            }
          }
        }
        user: { metadata: { _has_key: "type", _contains: { type: "inquiry" } } }
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_FAILED_IMPORT_CONV = gql`
  mutation MyMutation {
    delete_brd_whatsapp_conversations(
      where: {
        brd_whatsapp_messages: {
          brd_whatsapp_messages_statuses: {
            brd_whatsapp_message: {
              brd_whatsapp_messages_statuses: { key: { _eq: "failed" } }
            }
          }
        }
        user: { metadata: { _has_key: "type", _contains: { type: "import" } } }
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CONV_CONSULTANT = gql`
  mutation MyMutation($id: String!, $type: String!, $ConsID: uuid) {
    update_brd_whatsapp_conversations(
      where: { id: { _eq: $id } }
      _set: { assigned_consultant: $ConsID, type: $type }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_CONV_LABEL = gql`
  mutation MyMutation($labelId: uuid!) {
    delete_brd_whatsapp_Conv_label(where: { label_id: { _eq: $labelId } }) {
      affected_rows
    }
  }
`;
