import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { IconButton, Popover, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import EmailHeaderMenuItems from "./components/EmailHeaderMenuItems";
import Destinations from "./components/Destinations";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EMAIL_SYNC } from "~/graphql/brd_list/Mutation";
import { useMutation } from "@apollo/client";
import moment from "moment";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [showDestinations, setShowDestinations] = useState(false);

  const [emailSync] = useMutation(EMAIL_SYNC);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const emailSyncFun = async () => {
    const res = await emailSync({
      variables: {
        data: {
          email_sync_timestamp: moment(new Date()).toISOString(),
        },
      },
    });
  };

  useEffect(() => {
    emailSyncFun();
  }, []);

  return (
    <>
      <div className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex justify-between items-center">
        <AccountCircleIcon
          sx={{
            color: "#9155FD",
            fontSize: "50px",
          }}
        />
        <div className="flex items-center">
          <Tooltip title="Refresh">
            <IconButton onClick={emailSyncFun}>
              <RefreshIcon
                sx={{
                  cursor: "pointer",
                  color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                }}
              />
            </IconButton>
          </Tooltip>
          <IconButton>
            <MoreVertOutlinedIcon
              sx={{
                cursor: "pointer",
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              }}
              onClick={(e: any) => {
                setAnchorEl(e.currentTarget);
                setShowMenu(!showMenu);
              }}
            />
          </IconButton>
        </div>
        <Popover
          sx={{
            background: "transparent",
            top: "10px",
          }}
          anchorEl={anchorEl}
          onClose={() => {
            setShowMenu(!showMenu);
          }}
          open={showMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <EmailHeaderMenuItems
            setShowMenu={setShowMenu}
            setShowDestinations={setShowDestinations}
          />
        </Popover>
      </div>
      <Destinations
        showDestinations={showDestinations}
        setShowDestinations={setShowDestinations}
      />
    </>
  );
};

export default Header;
