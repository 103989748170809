import React, { useEffect, useState } from "react";
// import EditFAQs from "./components/EditTestimonial";
// import { GET_FLIGHTS_PAGES } from "./graphql/Query";
import { NavLink, useParams } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
// import AddTestimonials from "./components/AddTestimonial";
// import TestimonialRow from "./components/DestinationRow";
// import DestinationRow from "./components/DestinationRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle } from "react-icons/fa";
import SidePopup from "~/components/common/SidePopup";
import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import AdUpdatesAdd from "./components/AdUpdatesAdd";
import { GET } from "~/graphql/brd_google_ad_update/Query";

// import DestinationSettings from "./components/DestinationSettings";

const AdUpdates = () => {
  const { ad_id } = useParams();
  const [rows, setRows] = useState<any>([]);
  // const [settings, setSettings] = useState(false);
  // const [addTsm, setAddTsm] = useState(false);
  const { loading, data, error } = useSubscription(GET, {
    variables: {
      ad_id: ad_id,
    },
  });
  const columns: GridColDef[] = [
    { field: "sr", headerName: "SR.", width: 90 },
    {
      field: "id",
      headerName: "Trasaction Id",
      width: 150,
      editable: true,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: true,
    },
    {
      field: "impression",
      headerName: "impression",
      width: 150,
      editable: true,
    },
    {
      field: "click",
      headerName: "click",
      width: 90,
      editable: true,
    },
    {
      field: "cost",
      headerName: "cost",
      width: 90,
      editable: true,
    },
  ];

  useEffect(() => {
    if (data?.brd_google_ad_update?.length > 0) {
      let dataRows: any = [];
      data.brd_google_ad_update.map((item: any, index: number) => {
        dataRows.push({
          id: item.id,
          sr: index + 1,
          date: item.date,
          impression: item.impression,
          click: item.click,
          cost: item.cost,
        });
      });
      setRows([...dataRows]);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>error...{error.message}</p>;
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5  ">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl ">
              All Ad Update
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <Box sx={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <SidePopup
        component={<AdUpdatesAdd ad_id={ad_id} />}
        text="Add Ad Update"
      />
    </>
  );
};

export default AdUpdates;
