import { gql } from "@apollo/client";

export const SEND_WHATSAPP_CHAT = gql`
  mutation MyMutation($data: [brd_whatsapp_chat_insert_input!]!) {
    insert_brd_whatsapp_chat(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
