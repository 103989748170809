import { gql } from "@apollo/client";

export const CREATE_INSTAGRAM_POST = gql`
  mutation CreateIGPost(
    $access_token: String!
    $caption: String!
    $ig_user_Id: String!
    $mediaData: [String]!
    $mediaType: String!
  ) {
    create_ig_post(
      arg1: {
        access_token: $access_token
        caption: $caption
        ig_user_Id: $ig_user_Id
        mediaData: $mediaData
        mediaType: $mediaType
      }
    ) {
      id
    }
  }
`;
