import { Avatar, Popover } from "@mui/material";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import MenuItems from "./component/MenuItems";
import Contacts from "./component/Contacts";
import Settings from "./component/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import CommunityBar from "./component/CommunityBar";
import TemplateBar from "./component/TemplateBar";
import CreateTemplateBar from "./component/CreateTemplateBar";
import LabelBar from "../../../LableBar";

const Header = ({
  brand_phone,
  brd_ids,
  community = false,
  type,
  setSelectedConversations,
  selectedConversations,
}: any) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showContactBar, setShowContactBar] = useState(false);
  const [showSettingsBar, setShowSettingsBar] = useState(false);
  const [showCommunityBar, setShowCommunityBar] = useState(community);
  const [createGroupBar, setCreateGroupBar] = useState(false);
  const [showTemplateBar, setShowTemplateBar] = useState(false);
  const [showCreateTemplateBar, setShowCreateTemplateBar] = useState(false);
  const [showLabelBar, setShowLabelBar] = useState(false);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex justify-between items-center">
        <AccountCircleIcon
          sx={{
            color: "#DFE5E7",
            fontSize: "50px",
          }}
        />
        <div>
          <GroupsOutlinedIcon
            sx={{
              marginRight: "20px",
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCommunityBar(true);
            }}
          />
          <AddCommentOutlinedIcon
            sx={{
              marginRight: "20px",
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowContactBar(true);
            }}
          />
          <MoreVertOutlinedIcon
            sx={{
              cursor: "pointer",
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            }}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setShowMenu(!showMenu);
            }}
          />
          <Popover
            sx={{
              background: "transparent",
              top: "10px",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowMenu(!showMenu);
            }}
            open={showMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItems
              setShowLabelBar={setShowLabelBar}
              setCreateGroupBar={setCreateGroupBar}
              setShowMenu={setShowMenu}
              setShowSettingsBar={setShowSettingsBar}
              setShowTemplateBar={setShowTemplateBar}
              setShowCreateTemplateBar={setShowCreateTemplateBar}
              setSelectedConversations={setSelectedConversations}
              selectedConversations={selectedConversations}
            />
          </Popover>
        </div>
      </div>
      <Contacts
        setShowContactBar={setShowContactBar}
        showContactBar={showContactBar}
        setCreateGroupBar={setCreateGroupBar}
        createGroupBar={createGroupBar}
        type={type}
      />
      <Settings
        setShowSettingsBar={setShowSettingsBar}
        showSettingsBar={showSettingsBar}
        type={type}
      />
      <CommunityBar
        brand_phone={brand_phone}
        brd_ids={brd_ids}
        showCommunityBar={showCommunityBar}
        setShowCommunityBar={setShowCommunityBar}
      />

      <TemplateBar
        setShowTemplateBar={setShowTemplateBar}
        showTemplateBar={showTemplateBar}
        type={type}
      />
      <CreateTemplateBar
        type={type}
        showCreateTemplateBar={showCreateTemplateBar}
        setShowCreateTemplateBar={setShowCreateTemplateBar}
        setShowTemplateBar={setShowTemplateBar}
      />
      <LabelBar showLabelBar={showLabelBar} setShowLabelBar={setShowLabelBar} />
    </>
  );
};

export default Header;
