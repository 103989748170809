import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Avatar, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { GET_USER } from "~/graphql/users/Query";
import { useMutation, useQuery } from "@apollo/client";
import ChatRow from "../../../../../ChatRow";
// import { ADD_LABELS } from "~/graphql/brd_whatsapp_communities/Mutation";
import { useNavigate } from "react-router-dom";
import { ADD_COMMUNITIES } from "~/graphql/brd_communities/Mutation";

const NewGroup = ({
  showGroupBar,
  setShowGroupBar,
  groupDetails,
  setCreateGroupBar,
  type,
}: any) => {
  const [contactNumbers, setContactNumbers] = useState<any>([]);
  const navigate = useNavigate();

  const [searchContactText, setSearchContactText] = useState("");

  const [createCommunities] = useMutation(ADD_COMMUNITIES);

  const { loading, data, error } = useQuery(GET_USER, {
    variables: { role: "user", brd_id: groupDetails?.brandID },
  });

  const onSubmit = async () => {
    const users: any = [];

    contactNumbers.map((selectedUser: any) => {
      users.push({ user_id: selectedUser.id });
    });

    const payload = [
      {
        name: groupDetails?.label,
        image: groupDetails?.imageId || null,
        brd_id: groupDetails?.brandID,
        brd_whatsapp_community_users: {
          data: users,
        },
      },
    ];

    const res: any = await createCommunities({
      variables: { data: payload },
    });
    navigate(
      `/admin/whatsapp/community/` +
        res?.data.insert_brd_whatsapp_labels?.returning[0]?.id
    );
    setShowGroupBar(false);
    setCreateGroupBar(false);
  };

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-whatsappSecondaryBg dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showGroupBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] ${
            type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
          }`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowGroupBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Add Community members</p>
          </div>
        </div>
        <div className="px-[34px] pt-[26px]">
          <div className="flex flex-wrap gap-2">
            {contactNumbers?.map((item: any) => {
              return (
                <Chip
                  avatar={
                    <Avatar
                      alt="whatsappAvator"
                      src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
                    />
                  }
                  label={item?.displayName}
                  onDelete={() => {
                    const updatedContacts = contactNumbers?.filter(
                      (newItem: any) => newItem?.id !== item?.id
                    );
                    setContactNumbers(updatedContacts);
                  }}
                />
              );
            })}
          </div>
          <div className="border-b text-[14px]">
            <input
              type="text"
              placeholder="Search name or number"
              className="bg-transparent border-none focus:ring-0 w-full h-[35px] focus:border-none focus:outline-none dark:text-dark-primary"
              onChange={(e: any) => {
                setSearchContactText(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="mt-3 overflow-y-scroll flex-grow">
          {data?.users
            ?.filter(
              (item: any) =>
                item.displayName
                  ?.toLowerCase()
                  ?.includes(searchContactText?.toLocaleLowerCase()) ||
                item.phoneNumber
                  ?.toLowerCase()
                  ?.includes(searchContactText?.toLocaleLowerCase())
            )
            ?.map((user: any) => {
              return (
                <ChatRow user={user} setContactNumbers={setContactNumbers} />
              );
            })}
        </div>
        {contactNumbers?.length > 0 && (
          <div className="w-full bg-whatsappPrimaryBg dark:bg-dark-primary flex items-center justify-center p-5">
            <div
              className={`p-[13px] text-white rounded-full cursor-pointer ${
                type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
              }`}
              onClick={() => {
                onSubmit();
              }}
            >
              <CheckOutlinedIcon />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewGroup;
