import { gql } from "@apollo/client";

export const ADD_CREDITS_PLANS = gql`
  mutation addDefCreditsPlan($data: [def_credits_plan_insert_input!]!) {
    insert_def_credits_plan(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_CREDITS_PLANS = gql`
  mutation addDefCreditsPlan($id: uuid!) {
    delete_def_credits_plan(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
