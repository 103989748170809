import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { useClickOutside } from "@mantine/hooks";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useMutation } from "@apollo/client";
import { ADD_CAMPAIGN } from "./graphql/Mutation";

const startOfQ12022 = dayjs("2022-01-01T00:00:00.000");
const endOfQ12022 = dayjs("2022-03-31T23:59:59.999");

export default function AddCampaign({ setSidePopup }: any) {
  const [selectedBrand, setSelectedBrand] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [cost, setCost] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const { brands } = useAssigedBrands();
  const [addCampaign] = useMutation(ADD_CAMPAIGN);

  function handleClick() {
    setLoading(true);
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    await addCampaign({
      variables: {
        data: {
          id: data.id,
          name: data.name,
          brd_id: data.brd_id,
          start_date: data.start_date,
          daily_budget: data.daily_budget,
          cost: data.cost,
        },
      },
    });
    setLoading(false);
    setSidePopup(false);
  };

  if (!brands) return <></>;
  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Add Campaign</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brd_id", { required: true })}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  id="combo-box-demo"
                  options={brands}
                  onChange={(_, data) => field.onChange(data?.id)}
                  getOptionLabel={(option: any) => option?.name}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brd_id"] && el.focus();
                      }}
                      error={errors["brd_id"] ? true : false}
                      {...params}
                      label="Brands"
                    />
                  )}
                />
              )}
            />
            <TextField
              error={errors["id"] ? true : false}
              id="outlined-basic"
              label="Campaign Id"
              {...register("id", { required: true })}
              variant="outlined"
              name="id"
              type="number"
              className="w-full"
            />
            <TextField
              error={errors["name"] ? true : false}
              id="outlined-basic"
              label="Name"
              {...register("name", { required: true })}
              variant="outlined"
              name="name"
              className="w-full"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                {...register("start_date", { required: true })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DatePicker
                    value={value}
                    inputRef={ref}
                    onChange={(date) => {
                      onChange(date?.$d);
                    }}
                    label="Start Date"
                    className="w-full"
                    slotProps={{
                      textField: {
                        error: errors["start_date"] ? true : false,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              id="outlined-basic"
              error={errors["daily_budget"] ? true : false}
              label="Daily Budget"
              type="number"
              variant="outlined"
              {...register("daily_budget", { required: true })}
              className="w-full"
            />
          </div>
          <Button
            type="submit"
            sx={{ width: "100%", marginTop: "25px" }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </div>
    </>
  );
}
