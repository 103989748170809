import { gql } from "@apollo/client";

export const SEND_EMAIL = gql`
  mutation sendGridEmail(
    $body: String!
    $emails: String
    $subject: String
    $from: String
  ) {
    sendGridEmail(
      arg1: { body: $body, emails: $emails, subject: $subject, from: $from }
    ) {
      status
    }
  }
`;
