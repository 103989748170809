import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InputField from "~/components/FormInputs/InputField";
import ContentEditor from "~/components/common/ContentEditor";
import { GET_BRAND_TEMPLATE } from "../../../../graphql/Query";

export default function TemplateInputs({
  register,
  errors,
  template,
  content,
  setContent,
  setValue,
}: any) {
  const { loading, error, data } = useQuery(GET_BRAND_TEMPLATE, {
    variables: { id: template.id },
  });

  const [fromDynamic, setFromDynamic] = useState(template.dynamic_from);

  // const [fromDynamic,setFromDynamic] = useState(template.def_emails_template.dynamic_from);

  useEffect(() => {
    if (data?.brd_emails_templates.length > 0) {
      setValue("dynamic_from", template.def_emails_template.dynamic_from);
      setValue("subject", data.brd_emails_templates[0].subject);
      setContent(data.brd_emails_templates[0].content);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      {template.def_emails_template.dynamic_from && (
        <div className="flex gap-2">
          <input
            className="mt-[5px]"
            type="checkbox"
            {...register("fromDynamic")}
            id="fromDynamic"
            value={fromDynamic}
            onChange={(e: any) => {
              setFromDynamic(e.target.checked);
              setValue("dynamic_from", e.target.checked);
            }}
            defaultChecked={fromDynamic ? "checked" : ""}
          />
          <label htmlFor="fromDynamic">
            {template.def_emails_template.dynamic_from_label}
          </label>
        </div>
      )}

      {!fromDynamic && (
        <div className="col-span-6 sm:col-full">
          <InputField
            register={register}
            errors={errors}
            fieldName={`from`}
            type="text"
            inputClasses="shadow border-none"
            label="From"
            placeHolder="Enter From"
            defaultValue={data.brd_emails_templates[0].from}
          />
        </div>
      )}

      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName={`subject`}
          type="text"
          inputClasses="shadow border-none"
          label="Subject"
          placeHolder="Enter Post Title"
          defaultValue={data.brd_emails_templates[0].subject}
        />
      </div>

      <div className="col-span-6 sm:col-full mt-2">
        <label className="mb-2 block">Content</label>
        <ContentEditor content={content} setContent={setContent} />
      </div>
    </>
  );
}
