import React, { useEffect, useState } from "react";

import { getSubscriptions } from "~/utils/Stripe/stripeFunctions";

type PlanFeature = {
  name: string;
  enabled: boolean;
};

type Plan = {
  id: string;
  features: PlanFeature[];
  amount: number;
  nickname: string;
  currency: string;
};

const SubscriptionsCard = ({ plan }: any) => {
  const [currentPlan, setCurrentPlan] = useState<Plan | null>(null);
  useEffect(() => {
    async function fetch() {
      const sub: any = await getSubscriptions("sub_1NorvqG7sNvxSo7yAKdbhB02");
      setCurrentPlan(sub.plan);
    }
    fetch();
  }, []);
  return (
    <div>
      {currentPlan && (
        <div className="hover:scale-[1.02] transition-all bg-primary-gradient p-[25px] rounded-[24px] shadow-lg text-white text-montserrat">
          <div className="flex flex-col lg:flex-row justify-between gap-[20px]">
            <div className="flex items-center gap-3">
              <div className="max-w-[70px] w-full">{/* <IconStarter /> */}</div>
              <div className="">
                <h2 className="font-bold text-[22px]">{plan?.plan_name}</h2>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-[45px] font-bold whitespace-nowrap">
                {currentPlan && currentPlan?.currency.toUpperCase()}{" "}
                {currentPlan && currentPlan?.amount / 100}
              </p>
            </div>
          </div>
          {/* <h3 className="font-bold text-[18px] mb-2">What&apos; s Included</h3>

          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 font-light mb-4">
              <div className="flex items-center gap-2 font-[400]">
                {subscription?.metadata?.free_trial ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>10 days free trial</p>
              </div>
              <div className="flex items-center gap-2 text-[14px] font-[400]">
                {subscription?.metadata?.support ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>Priority Support 24/7</p>
              </div>
              <div className="flex items-center gap-2 text-[14px] font-[400]">
                {subscription?.metadata?.manual_inquiries ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>Add manual inquiries</p>
              </div>
              <div className="flex items-center gap-2 text-[14px] font-[400]">
                {subscription?.metadata?.users ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>{plan?.metadata?.users} Users</p>
              </div>
              <div className="flex items-center gap-2 text-[14px] font-[400]">
                {subscription?.metadata?.custom_url ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>Custom URL</p>
              </div>
              <div className="flex items-center gap-2 text-[14px] font-[400]">
                {subscription?.metadata?.custom_logo ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>Custom logo and branding</p>
              </div>
              <div className="flex items-center gap-2 text-[14px] font-[400]">
                {subscription?.metadata?.white_labled_domain ? (
                  <CheckGradient />
                ) : (
                  <GradientCross />
                )}
                <p>White labeled domain</p>
              </div>
            </div>
          </> */}

          {/* <button
              onClick={() => {
                setModalText(
                  "Your Subscription will be cancelled and you will logged out and your team will not able to login in portal"
                );
                setConfirmAction(() => cancelSubscription);
                setModalOpen(true);
              }}
            className="py-2 px-6 rounded-full bg-white text-secondary cursor-pointer block w-fit sm:ml-auto font-[600] text-[18px]"
          >
            {cancelLoading ? "Canceling" : "Cancel Subscription"}
            Cancel Subscription
          </button> */}
        </div>
      )}
    </div>
  );
};

export default SubscriptionsCard;
