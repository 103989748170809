import { gql } from "@apollo/client";

export const GET_ACTIVE_MEMBERS_WITH_BRANDS = gql`
  subscription Customers($thpId: uuid!, $brands: [uuid!]!) {
    users(
      where: {
        _and: [
          { brd_users: { thp_id: { _eq: $thpId }, brd_id: { _in: $brands } } }
          { defaultRole: { _neq: "user" } }
        ]
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      lastSeen
      thp_list {
        id
      }
      brd_users(
        where: {
          _and: [{ thp_id: { _eq: $thpId } }, { brd_id: { _in: $brands } }]
        }
      ) {
        user_id
        brd_id
        thp_id
        brd_list {
          name
        }
      }
      thp_users_profile {
        x
        instagram
        facebook
        linkedin
        skype
        id
        basic_salary
        company_phone
        discord
        pseudo_name
        youtube
        commission
        bio
        designation
        landline_number
      }
    }
  }
`;

export const GET_ACTIVE_MEMBERS_WITH_THP = gql`
  query CustomerQuery($thp_id: uuid!) {
    users(
      where: {
        brd_users: {
          thp_id: { _eq: $thp_id }
          users: { defaultRole: { _neq: "user" } }
        }
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
        brd_list {
          name
        }
      }
      thp_users_profile {
        x
        instagram
        facebook
        linkedin
        skype
        basic_salary
        commission
        company_phone
        discord
        id
        pseudo_name
        youtube
        bio
        designation
        landline_number
      }
    }
  }
`;
