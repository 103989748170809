import { gql } from "@apollo/client";

export const EDIT_TICKET_REQUEST = gql`
  mutation EditTicketRequest($id: uuid!, $status: String!) {
    update_ticket_issuance_request(
      where: { id: { _eq: $id } }
      _set: { status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_TRAIN_TICKET = gql`
  mutation deleteTicket($id: uuid!) {
    delete_train_inq_ticket(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
