import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
// import TextMessage from "~/components/whatsappOld/WhatsAppChattingBox/component/TextMessage";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import TextMessage from "../../../../../../components/whatsapp/WhatsAppChattingBox/component/TextMessage";
import { Chip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MessageStatus from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Messages/component/MessageStatus";
const MessageInfoBar = ({
  showMessageInfoBar,
  setShowMessageInfoBar,
  setShowUserInfo,
  setShowWhatsappChatBox,
  setShowReplyMessage,
}: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const [selectedStatus, setSelectedStatus] = useState<any>("read");

  const statusAr = [
    { name: "Read", value: "read" },
    { name: "Delivered", value: "delivered" },
    { name: "Sent", value: "sent" },
    { name: "Failed", value: "failed" },
    { name: "Sending", value: "sending" },
  ];

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);
  return (
    <div
      className={` h-full transition-all flex flex-col ease-in-out duration-300 bg-whatsappSecondaryBg dark:bg-dark-secondary border-l dark:border-dark-secondary ${
        showMessageInfoBar ? "w-full z-20" : "w-[0]"
      }`}
    >
      {showMessageInfoBar && (
        <>
          <div className="flex items-center text-black dark:text-white bg-whatsappPrimaryBg dark:bg-dark-primary px-[25px] text-[16px] font-medium py-[19px]">
            <CloseIcon
              sx={{
                color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                cursor: "pointer",
                fontWeight: "bolder",
              }}
              onClick={() => {
                setShowMessageInfoBar(false);
              }}
            />
            <p className="ml-5 font-bold">Message info</p>
          </div>
          <div className="flex items-center gap-3 my-3 ml-3">
            {statusAr.map((status: any) => {
              return (
                <Chip
                  sx={{ cursor: "pointer" }}
                  label={`${status.name} (${
                    showMessageInfoBar?.brd_whatsapp_messages?.filter(
                      (item: any) => {
                        const latestStatus =
                          item?.brd_whatsapp_messages_statuses.length > 0
                            ? item?.brd_whatsapp_messages_statuses?.reduce(
                                (latest: any, status: any) => {
                                  return new Date(status.timestamp) >
                                    new Date(latest.timestamp)
                                    ? status
                                    : latest;
                                }
                              )
                            : { key: "sending" };
                        return latestStatus.key === status.value;
                      }
                    ).length
                  })`}
                  color="primary"
                  variant={
                    selectedStatus === status.value ? "outlined" : "filled"
                  }
                  onClick={() => setSelectedStatus(status.value)}
                />
              );
            })}
          </div>

          <div className="overflow-y-scroll flex-grow">
            {showMessageInfoBar?.brd_whatsapp_messages
              ?.filter((item: any) => {
                const latestStatus =
                  item?.brd_whatsapp_messages_statuses.length > 0
                    ? item?.brd_whatsapp_messages_statuses?.reduce(
                        (latest: any, status: any) => {
                          return new Date(status.timestamp) >
                            new Date(latest.timestamp)
                            ? status
                            : latest;
                        }
                      )
                    : { key: "sending" };
                return latestStatus.key === selectedStatus;
              })
              ?.map((msg: any) => {
                return (
                  <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary flex items-center hover:bg-whatsappPrimaryBg cursor-pointer">
                    <div className="pl-[15px] py-[13px]">
                      <AccountCircleIcon
                        sx={{
                          color: "#DFE5E7",
                          fontSize: "50px",
                        }}
                      />
                    </div>
                    <div className="py-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
                      <div className="flex items-center justify-between">
                        <h1 className="text-[16px] text-black dark:text-white font-semibold">
                          {msg.brd_whatsapp_conversation.user.displayName}
                        </h1>
                      </div>
                      <div className="flex items-center justify-between w-full">
                        <p className="text-[14px] h-[18px] overflow-hidden truncate w-[150px] dark:text-dark-primary">
                          {msg.brd_whatsapp_conversation.user.phoneNumber}
                        </p>
                        <span className="text-[12px] flex ">
                          <span>
                            <MessageStatus message={msg} />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default MessageInfoBar;
