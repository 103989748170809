import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import AddCaseStudy from "~/modules/ServicesModule/segments/CaseStudy/components/AddCaseStudy";

const AddCaseStudyPage = () => {
    return (
        <div>
            <Layout>
                {/* <BrandModule> */}
                    <AddCaseStudy />
                {/* </BrandModule> */}
            </Layout>
        </div>

    )
}

export default AddCaseStudyPage