import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { DEFAULT_PAYMENT_METHODS } from "../../../PaymentAdd/components/SelectPayType/graphql/query";
import { useMutation, useQuery } from "@apollo/client";
import Stripe from "stripe";
import {
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
} from "~/modules/AccountModule/segments/PaymentRequest/components/PaymentRecord/graphql/Mutation";
import moment from "moment";
import { GET_PAYMENT_METHODS } from "../../../PaymentAdd/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Divider } from "@mantine/core";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const RefundModal = ({ open, setOpen, transaction, inquiry }: any) => {
  const [showChildMdl, setShowChildMdl] = useState(false);
  const [amount, setAmount] = useState<any>(null);
  const [remIncAmt, setRemIncAmt] = useState<any>(false);
  const [desc, setDesc] = useState<any>("");

  const [date, setDate] = useState<any>(new Date());

  const [selectedPaymentMeth, setSelectedPaymentMeth] = useState<any>();

  const { travelHouse }: any = travelHouseStore();

  const { data: paymentMethods } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, brd_id: inquiry?.brd_id },
  });

  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const [editTransaction] = useMutation(UPDATE_TRANSACTION);

  const { loading, data, error } = useQuery(DEFAULT_PAYMENT_METHODS);

  const createCardRefund = async () => {
    const stripeHelper = new Stripe(
      transaction?.acc_payment_method?.stripe_secret ||
        data.def_stripe[0].secret_key,
      {
        apiVersion: "2022-11-15",
      }
    );

    const refund = await stripeHelper.refunds.create({
      charge: transaction?.charge_id,
      amount: amount * 100,
    });

    const charge: any = await stripeHelper?.charges?.retrieve(
      transaction?.charge_id
    );

    const balance_transaction =
      await stripeHelper?.balanceTransactions?.retrieve(
        charge?.balance_transaction
      );

    if (refund.status === "succeeded") {
      const transactions: any = [
        {
          brd_id: transaction?.brd_id,
          def_acc_id: 4,
          inq_id: transaction?.inq_id,
          payment_method_id: transaction?.acc_payment_method?.id,
          transactions_no: `${transaction.transactions_no}-RF`,
          date: moment(date).format("YYYY-MM-DD"),
          created_at: moment(date).format("YYYY-MM-DD"),
          user_id: inquiry?.users?.id,
          type: "debit",
          amount: !remIncAmt
            ? parseFloat(amount)
            : parseFloat(transaction.amount),
        },
        {
          brd_id: transaction?.brd_id,
          def_acc_id: transaction?.acc_payment_method?.type === "bank" ? 3 : 14,
          inq_id: transaction?.inq_id,
          payment_method_id: transaction?.acc_payment_method?.id,
          transactions_no: `${transaction.transactions_no}-RF`,
          date: moment(date).format("YYYY-MM-DD"),
          created_at: moment(date).format("YYYY-MM-DD"),
          user_id: inquiry?.users?.id,
          note: "Refund",
          detailed_desc: desc,
          type: "credit",
          amount: -parseFloat(amount),
        },
      ];

      if (amount < transaction?.amount && remIncAmt) {
        transactions.push({
          brd_id: transaction?.brd_id,
          def_acc_id: 16,
          inq_id: transaction?.inq_id,
          payment_method_id: transaction?.acc_payment_method?.id,
          transactions_no: `${transaction.transactions_no}-RF`,
          date: moment(date).format("YYYY-MM-DD"),
          created_at: moment(date).format("YYYY-MM-DD"),
          type: "credit",
          amount: -(
            parseFloat(transaction.amount) -
            parseFloat(amount) -
            balance_transaction?.fee / 100
          ),
        });
      }

      const res = await addTransaction({
        variables: {
          transactions: transactions,
        },
      });

      const payload = {
        id: transaction.id,
        status:
          amount < transaction?.amount && remIncAmt
            ? "refunded"
            : "partiallyRefunded",
        refundAmount: parseFloat(amount),
      };

      if (res.data?.insert_acc_transactions?.returning?.length > 0) {
        const res1 = await editTransaction({ variables: payload });

        if (res1.data?.update_inq_transection?.returning?.length > 0) {
          toast.success("Transaction Refunded Successfully");
          setShowChildMdl(false);
          setOpen(false);
        }
      }
    }
  };

  const createBankRefund = async () => {
    const transactions: any = [
      {
        brd_id: transaction?.brd_id,
        def_acc_id: 4,
        inq_id: transaction?.inq_id,
        user_id: inquiry?.users?.id,
        payment_method_id: transaction?.acc_payment_method?.id,
        transactions_no: `${transaction.transactions_no}-RF`,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        amount: !remIncAmt
          ? parseFloat(amount)
          : parseFloat(transaction.amount),
      },
      {
        brd_id: transaction?.brd_id,
        def_acc_id: transaction?.acc_payment_method?.type === "bank" ? 3 : 14,
        inq_id: transaction?.inq_id,
        payment_method_id: transaction?.acc_payment_method?.id,
        transactions_no: `${transaction.transactions_no}-RF`,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        user_id: inquiry?.users?.id,
        note: "Refund",
        type: "credit",
        amount: -parseFloat(amount),
      },
    ];
    if (amount < transaction?.amount && remIncAmt) {
      transactions.push({
        brd_id: transaction?.brd_id,
        def_acc_id: 16,
        inq_id: transaction?.inq_id,
        payment_method_id: transaction?.acc_payment_method?.id,
        transactions_no: `${transaction.transactions_no}-RF`,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -(parseFloat(transaction.amount) - parseFloat(amount)),
      });
    }

    const res = await addTransaction({
      variables: {
        transactions: transactions,
      },
    });

    const payload = {
      id: transaction.id,
      status:
        amount < transaction?.amount && remIncAmt
          ? "refunded"
          : "partiallyRefunded",
      refundAmount: parseFloat(amount),
    };

    if (res.data?.insert_acc_transactions?.returning?.length > 0) {
      const res1 = await editTransaction({ variables: payload });

      if (res1.data?.update_inq_transection?.returning?.length > 0) {
        toast.success("Transaction Refunded Successfully");
        setShowChildMdl(false);
        setOpen(false);
      }
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
        Refund Your Amount
      </h2>
      <Divider />
      <Box>
        <div className="grid grid-cols-1 gap-5 mt-10">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                defaultValue={dayjs(new Date())}
                onChange={(date: any) => {
                  setDate(date?.$d);
                }}
                sx={{ width: "100%" }}
              />
            </DemoContainer>
          </LocalizationProvider>
          {(transaction?.acc_payment_method?.type === "bank" ||
            transaction?.acc_payment_method?.card_terminal_type ===
              "Manual") && (
            <Autocomplete
              disablePortal
              onChange={(_, data) => {
                setSelectedPaymentMeth(data);
              }}
              options={paymentMethods?.acc_payment_methods?.filter(
                (pay: any) =>
                  pay.type === "bank" || pay.card_terminal_type === "Manual"
              )}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params: any) => (
                <TextField {...params} label="Select Payment Method" />
              )}
            />
          )}

          <TextField
            onChange={(e: any) => setAmount(e.target.value)}
            value={amount}
            label="Amount"
          />

          <TextField
            onChange={(e: any) => setDesc(e.target.value)}
            value={desc}
            label="Detailed Description"
          />

          {amount < transaction?.amount && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={remIncAmt}
                  onChange={() => setRemIncAmt(!remIncAmt)}
                />
              }
              label="Remaining amount is your income?"
            />
          )}
          <Button
            variant="contained"
            onClick={() => {
              if (!amount) {
                toast.error("Enter your Refund Amount");
                return;
              }
              setShowChildMdl(true);
            }}
          >
            Refund
          </Button>
        </div>
      </Box>
      <Modal
        open={showChildMdl}
        onClose={() => setShowChildMdl(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, width: 500 }}
          className="rounded-lg bg-white dark:bg-dark-primary"
        >
          <h2
            className="text-primary dark:text-dark-primary text-2xl font-bold"
            id="parent-modal-title"
          >
            Are you sure you want to refund your amount?
          </h2>
          <div className="flex gap-3 justify-end">
            <Button variant="outlined" onClick={() => setShowChildMdl(false)}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (amount > transaction?.amount) {
                  toast.error("Refund amount is greater then actual amount");
                } else if (
                  transaction?.acc_payment_method?.type === "bank" ||
                  transaction?.acc_payment_method?.card_terminal_type ===
                    "Manual"
                ) {
                  createBankRefund();
                } else {
                  createCardRefund();
                }
              }}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default RefundModal;
