import React, { useEffect, useState } from "react";
// import EditFAQs from "./components/EditTestimonial";
// import { GET_FLIGHTS_PAGES } from "./graphql/Query";
import { NavLink, useParams } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
// import AddTestimonials from "./components/AddTestimonial";
// import TestimonialRow from "./components/DestinationRow";
// import DestinationRow from "./components/DestinationRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle } from "react-icons/fa";
import SidePopup from "~/components/common/SidePopup";
import AddCampaign from "./components/AddCampaign";
import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { GET } from "~/graphql/brd_google_campaign/Query";
import useAssigedBrands from "~/hooks/useAssigedBrands";

// import DestinationSettings from "./components/DestinationSettings";

const columns: GridColDef[] = [
  { field: "sr", headerName: "SR.", width: 90 },
  {
    field: "id",
    headerName: "Campaign Id",
    // type: 'number',
    width: 150,
    editable: true,
  },
  {
    field: "brd_id",
    headerName: "Brand Name",
    width: 150,
    editable: true,
  },
  {
    field: "name",
    headerName: "Campaign Name",
    width: 150,
    editable: true,
  },
  {
    field: "start_date",
    headerName: "Start Date",
    width: 150,
    editable: true,
  },
  {
    field: "end_date",
    headerName: "Stop Date",
    width: 150,
    editable: true,
  },
  {
    field: "daily_budget",
    headerName: "Daily Budget",
    width: 150,
    editable: true,
  },
  {
    field: "cost",
    headerName: "Cost",
    width: 90,
    editable: true,
  },
  {
    field: "click",
    headerName: "Click",
    width: 90,
    editable: true,
  },
  {
    field: "cpc",
    headerName: "CPC",
    width: 90,
    editable: true,
  },
  {
    field: "cpi",
    headerName: "CPI",
    width: 90,
    editable: true,
  },
  {
    field: "cpb",
    headerName: "CPB",
    width: 90,
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 120,
    renderCell: (params: any) => (
      <NavLink to={`/admin/google-campaigns/${params.row.id}`}>
        <Button variant="contained">Detail</Button>
      </NavLink>
    ),
  },
];

// { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
// { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
// { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
// { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
// { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
// { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
// { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
// { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
// { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },

const Campaigns = () => {
  const [rows, setRows] = useState<any>([]);
  const { brd_ids } = useAssigedBrands();
  // const { brd_id } = useParams();
  // const [settings, setSettings] = useState(false);
  // const [addTsm, setAddTsm] = useState(false);
  const { loading, data } = useSubscription(GET, {
    variables: {
      brd_ids: brd_ids,
    },
    skip: !brd_ids,
  });

  useEffect(() => {
    if (data?.brd_google_campaign?.length > 0) {
      let dataRows: any = [];
      data.brd_google_campaign.map((item: any, index: number) => {
        dataRows.push({
          id: item.id,
          sr: index + 1,
          brd_id: item.brd_list.name,
          name: item.name,
          start_date: item.start_date,
          end_date: item.end_date || "N/A",
          daily_budget: item.daily_budget,
          click: item.brd_google_ads_aggregate.nodes.reduce(
            (sum: any, node: any) =>
              sum + node.brd_google_ad_updates_aggregate.aggregate.sum.click,
            0
          ),
          cost: item.brd_google_ads_aggregate.nodes.reduce(
            (sum: any, node: any) =>
              sum + node.brd_google_ad_updates_aggregate.aggregate.sum.cost,
            0
          ),
          cpc: (
            item.brd_google_ads_aggregate.nodes.reduce(
              (sum: any, node: any) =>
                sum + node.brd_google_ad_updates_aggregate.aggregate.sum.cost,
              0
            ) /
            item.brd_google_ads_aggregate.nodes.reduce(
              (sum: any, node: any) =>
                sum + node.brd_google_ad_updates_aggregate.aggregate.sum.click,
              0
            )
          ).toFixed(2),
          cpi: 0,
          cpb: 0,
        });
      });
      setRows([...dataRows]);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between border-b divide-gray-200  dark:divide-gray-700 lg:mt-1.5  ">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-primary dark:text-dark-primary sm:text-2xl ">
              All Compaigns
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <Box sx={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <SidePopup component={<AddCampaign />} text="Add Campaign" />
    </>
  );
};

export default Campaigns;
