import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_INQ_LIST } from "~/graphql/inq_list/Query";
import useInquiryDetail from "~/hooks/useInquiryDetail";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import countries from "../../FormInputs/CountryBox/data/countries.json";
import {
  PassengersGenders,
  PassengersType,
} from "~/modules/InquiriesModule/segments/InquiryDetail/components/PassengersCard/data/InitalDefaultValue";

const AddPassenger = ({
  submitPassengers,
  mainHeading,
  userID,
  setSidePopup,
  showUser,
}: any) => {
  const [inqID, setInqId] = useState<any>();

  const { inquiryDetail }: any = useInquiryDetail(inqID && inqID);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});

  const onSubmit = (inpData: any) => {
    const inquiry: any = inquiryDetail?.inq_list[0];
    submitPassengers(inpData, inquiry, setSidePopup);
  };

  const { data } = useQuery(GET_USER_INQ_LIST, {
    variables: { user_id: userID },
  });

  return (
    <>
      <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{mainHeading}</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            {showUser && (
              <div className="md:col-span-2">
                <Autocomplete
                  disablePortal
                  onChange={(_, data: any) => {
                    setInqId(data?.inquiry_no);
                  }}
                  options={data?.inq_list ? data?.inq_list : []}
                  getOptionLabel={(option: any) => option?.inquiry_no}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.inquiry_no}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField label="Inquiry Number" {...params} />
                  )}
                />
              </div>
            )}
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("type", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.value);
                  }}
                  options={PassengersType ? PassengersType : []}
                  getOptionLabel={(option: any) => option?.title}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.title}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Type"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["type"] ? true : false}
                      {...params}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("gender", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.value);
                  }}
                  options={PassengersGenders ? PassengersGenders : []}
                  getOptionLabel={(option: any) => option?.title}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.title}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Gender"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["gender"] ? true : false}
                      {...params}
                    />
                  )}
                />
              )}
            />
            <TextField
              error={errors["first_name"] ? true : false}
              {...register("first_name", {
                required: true,
              })}
              label="First Name"
              type="text"
            />
            <TextField
              error={errors["last_name"] ? true : false}
              {...register("last_name", {
                required: true,
              })}
              label="Last Name"
              type="text"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("dob", {
                    required: true,
                  })}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date: any) => {
                        onChange(date?.$d.toString());
                      }}
                      sx={{ width: "100%" }}
                      label="Date of Birth"
                      slotProps={{
                        textField: {
                          error: errors["dob"] ? true : false,
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("nationality", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  sx={{ marginTop: "8px" }}
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.label.toLowerCase().startsWith(inputValueLowerCase)
                    );
                  }}
                  options={countries ? countries : []}
                  getOptionLabel={(option: any) => option?.label}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Nationality"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["nationality"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              sx={{ marginTop: "8px" }}
              error={errors["passport_no"] ? true : false}
              {...register("passport_no", {
                required: true,
              })}
              label="Passport Number"
              type="text"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("passport_expiry", {
                    required: true,
                  })}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date: any) => {
                        onChange(date?.$d.toString());
                      }}
                      sx={{ width: "100%" }}
                      label="Passport Expiry"
                      slotProps={{
                        textField: {
                          error: errors["passport_expiry"] ? true : false,
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <Button
            type="submit"
            sx={{ marginTop: "25px" }}
            variant="contained"
            fullWidth
          >
            {mainHeading}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AddPassenger;
