import React from "react";

export default function TableHead() {
  return (
    <thead className="bg-gray-100 dark:bg-gray-700">
      <tr>
        <th scope="col" className="p-4">
          {/* <div className="flex items-center">
            <input
              id="checkbox-all"
              aria-describedby="checkbox-1"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-all" className="sr-only">
              checkbox
            </label>
          </div> */}
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Name
        </th>

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Month
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Salary Per Month
        </th>

        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Required Hours
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Track Time
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Approved Time
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Approved Hours
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Salary per Hours
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Total Salary
        </th>
      </tr>
    </thead>
  );
}
