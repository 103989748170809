import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_TRAVEL_HOUSE } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import SubscriptionsCard from "./components/SubscriptionsCard";

export default function SubscriptionList() {
  const { travelHouse }: any = travelHouseStore();
  // get data based on query
  const { loading, data, error } = useQuery(GET_TRAVEL_HOUSE, {
    variables: { thp_id: travelHouse.id },
  });

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        My Subscription
      </h1>
      <div className="w-[50%]">
        <SubscriptionsCard plan={data?.thp_list[0].def_subscription_plan} />
      </div>
      {/* No Record Found */}
      {data.thp_list.length === 0 && <h2>No record found</h2>}
    </>
  );
}
