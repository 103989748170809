import React from "react";

import { splitLocation } from "~/utils/splitLocation";
import HotelIcon from "@mui/icons-material/Hotel";
import LoginIcon from "@mui/icons-material/Login";
const StayCard = ({
  location,
  startDate,
  endDate,
  rooms,
  adults,
  children,
  height,
  detail,
}: any) => {
  const { cityName: locationName, remainingAddress: locationAddress } =
    splitLocation(location);
  console.log("dfssdfdfsdfsadfsdfsfds", location);
  const locationWords = locationName.split(" ");
  const firstWord = locationWords[0];
  const remainingWords = locationWords.slice(1).join(" ");

  return (
    <div
      // min-h-[${height}]
      className={`flex-1 w-full rounded-2xl bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow  flex flex-col pt-0 px-0 box-border items-end justify-between  text-left text-xs  h-full
    `}
    >
      <div className="w-full  text-primary dark:text-dark-primary flex flex-col justify-between rounded-2xl ">
        <div className="self-stretch flex flex-row pb-2.5 pt-10 px-[30px] h-full  items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-[1px]">
            <div className="bg-basic h-20 w-20 rounded-full flex items-center mb-3 justify-center">
              <HotelIcon className="text-center text-white" fontSize="large" />
            </div>
            {/* {remainingWords && (
              <div className="self-stretch relative text-[20px] font-semibold leading-[20px] text-basic dark:text-dark-primary">
                {location}
              </div>
            )} */}

            <div className="flex justify-between flex-col w-full gap-1 my-3">
              {remainingWords && (
                <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                  <span className="font-bold mr-2">Location:</span> {location}
                </div>
              )}
              <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                <span className="font-bold mr-2">Arrival:</span> {startDate}
              </div>

              <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                <span className="font-bold mr-2">Departure:</span> {endDate}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-1 md:flex-row px-[30px] py-7 items-center justify-between bg-basic rounded-b-2xl h-[150px]">
        <div className="flex flex-col items-start justify-center w-full">
          <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-base">
              Rooms: {rooms}
            </div>
            <div className="self-stretch relative text-base">
              Adults:{adults}
            </div>
            <div className="self-stretch relative text-base">
              Children: {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayCard;
