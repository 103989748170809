import { gql } from "@apollo/client";

export const GET_EMAIL_DETAIL = gql`
  query MyQuery($id: String!, $refresh_token: String!) {
    getGmailMessages(arg1: { id: $id, refresh_token: $refresh_token }) {
      snippet
      id
      historyId
      internalDate
      labelIds
      sizeEstimate
      threadId
      payload {
        filename
        mimeType
        partId
        body {
          data
          size
        }
        headers {
          name
          value
        }
        parts {
          filename
          mimeType
          partId
          parts {
            filename
            mimeType
            partId
            headers {
              name
              value
            }
            body {
              data
              size
              attachmentId
            }
          }
          headers {
            name
            value
          }
          body {
            data
            size
            attachmentId
          }
        }
      }
    }
  }
`;

export const GET_EMAIL_DETAIL_FILE = gql`
  query MyQuery($id: String!, $refresh_token: String!, $messageId: String!) {
    getGmailFile(
      arg1: { id: $id, refresh_token: $refresh_token, messageId: $messageId }
    ) {
      size
      data
    }
  }
`;
