import React from "react";
import ReportForm from "./components/ReportForm";
import ReportDetails from "./components/ReportDetails";
import { useForm } from "react-hook-form";
import { useApolloClient } from "@apollo/client";
import { REPORT_QUERIES } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

const ReportsSegment = () => {
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();
  const {
    watch,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    const { data } = await client.query({
      query: REPORT_QUERIES["picked_inquiry"].query,
      variables: {
        th_id: travelHouse.id,
        user_id: inpData?.userID,
      },
    });
  };
  return (
    <div>
      <ReportForm onSubmit={onSubmit} />
      <ReportDetails />
    </div>
  );
};

export default ReportsSegment;
