import { gql } from "@apollo/client";

export const ADD_AD = gql`
  mutation AddAccount($data: [acc_google_ads_insert_input!]!) {
    insert_acc_google_ads(objects: $data) {
      affected_rows
      returning {
        ad_id
      }
    }
  }
`;
