import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $status: String) {
    update_inq_list(where: { id: { _eq: $id } }, _set: { status: $status }) {
      returning {
        id
      }
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation MyMutation($transactions: [acc_transactions_insert_input!]!) {
    insert_acc_transactions(objects: $transactions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const COMPLETE_INQ = gql`
  mutation EditInquiry(
    $id: uuid!
    $status: String!
    $completedAt: timestamptz!
  ) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { status: $status, completed_date: $completedAt }
    ) {
      returning {
        id
      }
    }
  }
`;
