import { useMutation, useQuery } from "@apollo/client";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  ADD_LABELS,
  DELETE_LABEL,
} from "~/graphql/brd_whatsapp_labels/Mutation";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useBrandWhatsappLabels from "~/hooks/useBrandWhatsappLabels";
import DeleteIcon from "@mui/icons-material/Delete";
import { DELETE_CONV_LABEL } from "~/graphql/brd_whatsapp_conversations/Mutation";

const LabelBar = ({ showLabelBar, setShowLabelBar }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const [labelValue, setLabelValue] = useState<any>("");
  const [selectedBrand, setSelectedBrand] = useState<any>("");

  const { brands, brd_ids } = useAssigedBrands();

  const { whatsappLabels } = useBrandWhatsappLabels(brd_ids);

  const [addWhatsappLabels] = useMutation(ADD_LABELS);
  const [deleteWhatsappLabels] = useMutation(DELETE_LABEL);
  const [deleteWhatsappConvLabels] = useMutation(DELETE_CONV_LABEL);

  const addLabels = async () => {
    if (!selectedBrand) {
      toast.error("Brand not Selected");
    } else {
      const res = await addWhatsappLabels({
        variables: {
          data: {
            name: labelValue,
            brd_id: selectedBrand,
          },
        },
      });
      if (res?.data?.insert_brd_whatsapp_labels?.affected_rows) {
        toast.success("Label Added Successfully");
      }
    }
  };

  const deleteLabels = async (id: any) => {
    await deleteWhatsappConvLabels({
      variables: {
        labelId: id,
      },
    });

    const res = await deleteWhatsappLabels({
      variables: {
        id: id,
      },
    });

    if (res?.data?.delete_brd_whatsapp_labels?.affected_rows) {
      toast.success("Label Deleted Successfully");
    }
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div
      className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
        showLabelBar ? "left-0" : "left-[-800px]"
      }`}
    >
      <div className="px-[23px] text-white text-[19px] pt-[60px] bg-whatsappBasic">
        <div className="flex items-center mb-5">
          <ArrowBackOutlinedIcon
            onClick={() => {
              setShowLabelBar(false);
            }}
            sx={{ cursor: "pointer" }}
          />
          <p className="ml-5">Whatsapp Labels</p>
        </div>
      </div>
      <div className="flex flex-col gap-3 p-[20px] w-full">
        <Autocomplete
          disablePortal
          onChange={(_, data: any) => {
            setSelectedBrand(data?.id);
          }}
          options={brands ? brands : []}
          getOptionLabel={(option: any) => option?.name}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option?.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField color="success" label="Brand" {...params} />
          )}
          noOptionsText=""
        />
        <TextField
          fullWidth
          label="Add New Label"
          onChange={(e) => setLabelValue(e.target.value)}
        />
        <Button onClick={addLabels} fullWidth variant="contained">
          Add Label
        </Button>
      </div>
      <h1 className="text-lg font-bold px-[20px] py-2">All Labels:</h1>
      <div className="flex-grow overflow-y-scroll">
        {whatsappLabels?.map((item: any) => {
          return (
            <div className="border-b px-5 py-3 gap-2 flex items-center justify-between bg-whatsappSecondaryBg dark:bg-dark-secondary  hover:bg-whatsappPrimaryBg">
              <h1 className="font-semibold">{item.name}</h1>
              <DeleteIcon
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={() => deleteLabels(item.id)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LabelBar;
