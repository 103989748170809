import { gql } from "@apollo/client";

export const GET_ADS = gql`
  query GetAds($brd_ids: [uuid!]!) {
    acc_google_ads(where: { brd_id: { _in: $brd_ids } }) {
      name
      ad_id
      brd_list {
        name
      }
    }
  }
`;

