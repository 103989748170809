import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import FlightsPage from "~/modules/BrandModule/segments/FlightsPages";

const FlightsPages = () => {
  return (
    <Layout>
      <BrandModule>
        <FlightsPage />
      </BrandModule>
    </Layout>
  );
};

export default FlightsPages;
