import React from "react";
import moment from "moment";
import {
  getCityName,
  timeDuration,
} from "../../../../../../../../../../../../utils/utilsFunctions";
import { useState, useEffect } from "react";
import To from "../../../../../../../../assets/img/To.svg";
import ToDark from "../../../../../../../../assets/img/To-dark.svg";
import From from "../../../../../../../../assets/img/From.svg";
import FromDark from "../../../../../../../../assets/img/From-dark.svg";
import Time from "../../../../../../../../assets/img/Time.svg";
import TimeDark from "../../../../../../../../assets/img/Time-dark.svg";
import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";

export default function SuggestionStop({ stop, suggestion, legs, ind }: any) {
  const timeDuration1 = (timestamp1: any, timestamp2: any) => {
    const start = moment(timestamp1);
    const end = moment(timestamp2);
    const duration = moment.duration(end.diff(start));

    // Extract hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    // Output
    // ;
    return { hours, minutes };
  };

  function getAirlineNameByIATA(iataCode: any) {
    const airline = airlineData.find(
      (airline: any) => airline.iata === iataCode
    );
    return airline?.marketing_name;
  }

  const getHoursAndMinutes = (timestamp1: any, timestamp2: any) => {
    const duration: any = timeDuration1(timestamp1, timestamp2);
    return `${duration?.hours}h ${duration?.minutes}m`;
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="self-stretch flex flex-col">
      <div className="self-stretch flex flex-row items-center justify-start">
        <div className="flex flex-col items-start justify-start gap-[1px] min-w-[100px]">
          {/* <div className="self-stretch relative text-3xs ">From</div> */}
          <img src={darkMode ? FromDark : From} alt="" />
          <div className="font-medium text-xl text-basic dark:text-dark-primary">
            {moment(stop.depart_datetime).zone(0).format("HH:mm")}
          </div>
          <div className="flex flex-col 2xl:flex-row  text-[15px] 2xl:gap-1 text-basic dark:text-dark-primary">
            <div className="self-stretch relative">{`${getCityName(
              stop.depart
            )} (${stop.depart})`}</div>
            <div className="self-stretch relative">
              {moment(stop.depart_datetime).zone(0).format("DD-MM-YYYY")}
            </div>
          </div>
          {/* <div className="self-stretch relative text-13xl text-tomato">
          {stop.depart}
        </div>
        <div className="self-stretch relative">{getCityName(stop.depart)}</div>
        <div className="self-stretch relative">
          {moment(stop.depart_datetime).zone(0).format("DD-MM-YYYY")} |{" "}
          {moment(stop.depart_datetime).zone(0).format("HH:mm")}{" "}
        </div> */}
          {/* <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
      <img
        className="relative w-[11px] h-[11px]"
        alt=""
        src="/img/vector19.svg"
      />
      <div className="relative">Flexible</div>
    </div> */}
        </div>
        <img
          className="flex-1 relative max-w-full overflow-hidden h-px"
          alt=""
          src="/img/vector-8.svg"
        />
        <div className="flex flex-col items-center justify-center gap-[6px] text-basic dark:text-dark-primary font-semibold">
          <div className="relative inline-block w-fit mx-3 h-[15px] shrink-0">
            {timeDuration(
              stop?.depart_datetime,
              stop?.depart,
              stop?.arrival_datetime,
              stop?.arrival
            )}
          </div>
          <img src={darkMode ? TimeDark : Time} alt="" />
          <div className="flex flex-row items-center justify-start gap-[6px]">
            {/* <img
          className="relative w-3 h-3"
          alt=""
          src="/img/vector20.svg"
        />
        
         */}

            {/* <AirlineLogoImage
              src={`${stop.airline_name}`}
              className="relative w-3 h-3"
              alt="air"
            /> */}
            <div className="relative inline-block text-center w-[120px] h-3.5 shrink-0">
              {getAirlineNameByIATA(stop?.airline_iata)}{" "}
              {`(${stop.airline_iata})`} - {stop?.flight_no}
            </div>
          </div>
        </div>
        <img
          className="flex-1 relative max-w-full overflow-hidden h-px"
          alt=""
          src="/img/vector-686.svg"
        />
        <div className="flex flex-col items-end justify-start text-right min-w-[100px]">
          {/* <div className="self-stretch relative text-3xs ">To</div> */}
          <img src={darkMode ? ToDark : To} alt="" />
          <div className="font-medium text-xl text-basic dark:text-dark-primary">
            {moment(stop.arrival_datetime).zone(0).format("HH:mm")}
          </div>
          <div className="flex flex-col 2xl:flex-row text-[15px] 2xl:gap-1 text-basic dark:text-dark-primary">
            <div className="self-stretch relative">{`${getCityName(
              stop?.arrival
            )} (${stop?.arrival})`}</div>
            <div className="self-stretch relative">
              {moment(stop.arrival_datetime).zone(0).format("DD-MM-YYYY")}
            </div>
          </div>
          {/* <div className="self-stretch relative text-13xl  text-tomato">
          {stop?.arrival}
        </div>
        <div className="self-stretch relative">
          {getCityName(stop?.arrival)}
        </div>
        <div className="self-stretch relative">
          {moment(stop.arrival_datetime).zone(0).format("DD-MM-YYYY")} |{" "}
          {moment(stop.arrival_datetime).zone(0).format("HH:mm")}
        </div> */}
          {/* <div className="flex flex-row items-center justify-center gap-[6px]">
      <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray" />
      <div className="relative">Flexible</div>
    </div> */}
        </div>
      </div>
      {legs?.inq_suggestion_leg_stops?.sort(
        (a: any, b: any) => a.ordering - b.ordering
      )?.[ind + 1] && (
        <div className="w-full h-6 rounded-sm bg-basic bg-opacity-[75%] dark:bg-opacity-[100%] dark:bg-[#D0CCE5] flex justify-center items-center text-white font-medium dark:text-[#312D4B]">
          {timeDuration(
            stop?.arrival_datetime,
            stop?.arrival,
            legs?.inq_suggestion_leg_stops?.sort(
              (a: any, b: any) => a.ordering - b.ordering
            )?.[ind + 1]?.depart_datetime,
            legs?.inq_suggestion_leg_stops?.sort(
              (a: any, b: any) => a.ordering - b.ordering
            )?.[ind + 1]?.depart
          )}
          {/* {getHoursAndMinutes(
            stop?.arrival_datetime,
            legs?.inq_suggestion_leg_stops?.sort(
              (a: any, b: any) => a.ordering - b.ordering
            )?.[ind + 1]?.depart_datetime
          )} */}
          {` `}
          {`Change planes in ${getCityName(stop?.arrival)} (${stop?.arrival})`}
        </div>
      )}
    </div>
  );
}
