import { gql } from "@apollo/client";

export const GET_CAMPUSES_WITH_UNIVERSITY_ID = gql`
  query GetCampusesWithUniId($universityId: uuid!) {
    brd_universities(where: {id: {_eq: $universityId}}) {
      name
      brd_uni_campuses {
        id
        name
        city
        country
        address
      }
    }
  }
`;
