import { Autocomplete, Box, TextField } from "@mui/material";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useState } from "react";
import useAccTransactions from "~/hooks/useAccTransactions";
import useAccountList from "~/hooks/useAccountList";
import useAllBrandPaymentMethodName from "~/hooks/useAllBrandPaymentMethodName";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useUsers from "~/hooks/useUsers";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import Consultantfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Consultantfilter";
import DateRangePickerCustom from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/DateRangePicker";

const TransactionFilters = ({
  selectedBrand,
  setSelectedBrand,
  dates,
  setDates,
  selectedTransType,
  selectedTransAcc,
  selectedPayMeth,
  setSelectedTransType,
  setSelectedTransAcc,
  setSelectedPayMeth,
  setSelectedUser,
  setInquiryNo,
  setTransactionNo,
  setSelectedConsultant,
  travelHouse,
}: any) => {
  const { brd_ids } = useAssigedBrands();
  const { brands = [] }: { brands: any[] } = useAssigedBrands();

  const { accountList }: any = useAccountList();

  const { paymentMethodName = [] }: { paymentMethodName: any[] } =
    useAllBrandPaymentMethodName(brd_ids);

  const { users }: any = useUsers(brd_ids);

  const accListTypes: any = Array.from(
    new Set(accountList.map((account: any) => account.type))
  );

  const accListNames: any = Array.from(
    new Set(accountList.map((account: any) => account.name))
  );

  const handleBrands = (event: any) => {
    const {
      target: { value },
    } = event;
    //set Selected Brands
    setSelectedBrand(typeof value === "string" ? value.split(",") : value);
  };
  const selectedBrandsNames = selectedBrand
    .map((id: any) => brands.find((method) => method.id === id)?.name)
    .filter((name: any) => name);

  const handleTransTypeChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedTransType(typeof value === "string" ? value.split(",") : value);
  };
  const handleTransAccChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedTransAcc(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const selectedNames = selectedPayMeth
    .map(
      (id: any) => paymentMethodName.find((method) => method.id === id)?.name
    )
    .filter((name: any) => name);
  const handleTransPaymentChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedPayMeth(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-3">
      <DateRangePickerCustom dates={dates} setDates={setDates} />
      {/* <div className="mt-[8px]"> */}
        {/* <Brandfilter
          setSelectedBrand={setSelectedBrand}
          selectedBrand={selectedBrand}
        /> */}
        {/* <FormControl sx={{ width: "100%" }}>
          <InputLabel id="transaction-type-label">Brands</InputLabel>
          <Select
            labelId="transaction-type-label"
            id="transaction-type"
            multiple
            value={selectedBrand || []}
            onChange={handleBrands}
            input={<OutlinedInput label="Brands" />}
            renderValue={(selected: any) => selectedBrandsNames.join(", ")}
          >
            {brands.length > 0 &&
              brands.map((brand: any) => (
                <MenuItem key={brand.id} value={brand.id}>
                  <Checkbox checked={selectedBrand.indexOf(brand.id) > -1} />
                  <ListItemText primary={brand.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl> */}
      {/* </div> */}
      <div className="mt-[8px]">
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="transaction-type-label">Transaction Type</InputLabel>
          <Select
            labelId="transaction-type-label"
            id="transaction-type"
            multiple
            value={selectedTransType || []}
            onChange={handleTransTypeChange}
            input={<OutlinedInput label="Transaction Type" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {accListTypes.map((type: any) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={selectedTransType.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-[8px]">
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="transaction-type-label">
            Transaction Account
          </InputLabel>
          <Select
            labelId="transaction-type-label"
            id="transaction-type"
            multiple
            value={selectedTransAcc || []}
            onChange={handleTransAccChange}
            input={<OutlinedInput label="Transaction Account" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {accListNames.map((type: any) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={selectedTransAcc.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-[8px]">
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="transaction-type-label">Payment Method</InputLabel>
          <Select
            labelId="transaction-type-label"
            id="transaction-type"
            multiple
            value={selectedPayMeth || []}
            onChange={handleTransPaymentChange}
            input={<OutlinedInput label="Payment Method" />}
            renderValue={(selected) => selectedNames.join(", ")}
          >
            {paymentMethodName.map((method: any) => (
              <MenuItem key={method.id} value={method.id}>
                <Checkbox checked={selectedPayMeth.includes(method.id)} />
                <ListItemText primary={method.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-[8px]">
        <Autocomplete
          sx={{ marginBottom: "10px" }}
          disablePortal
          onChange={(_, data: any) => {
            setSelectedUser(data?.id);
          }}
          options={users || []}
          getOptionLabel={(option: any) => option.displayName}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.displayName}
            </Box>
          )}
          renderInput={(params) => <TextField label="User" {...params} />}
          noOptionsText=""
        />
      </div>
      <div className="mt-[8px]">
        <TextField
          fullWidth
          label="Transaction No"
          onChange={(e) => setTransactionNo(e.target.value)}
        />
      </div>
      <div className="mt-[8px]">
        <TextField
          fullWidth
          label="Inquiry No"
          onChange={(e: any) => setInquiryNo(e.target.value)}
        />
      </div>
      <div className="mt-[8px]">
        <Consultantfilter
          setSelectedConsultant={setSelectedConsultant}
          travelHouse={travelHouse}
        />
      </div>
    </div>
  );
};

export default TransactionFilters;
