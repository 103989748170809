import { useEffect, useState } from "react";
import WhatsappSideBar from "~/components/whatsapp/WhatsappSideBar";
import Layout from "~/components/common/Layout";
import WhatsappChattingBox from "~/components/whatsapp/WhatsAppChattingBox";
import SideBarSekeleton from "~/components/whatsapp/SideBarSekeleton";
import WhatsappUserInfoBar from "~/components/whatsapp/WhatsappUserInfoBar";
import WhatsappDisplay from "~/components/whatsapp/WhatsappDisplay";
import ChatBoxSekeleton from "~/modules/WhatsappModule/segments/WhatsappWindow/components/ChatWindow/components/Sekeleton/ChatBoxSekeleton";
import MessageInfoBar from "~/modules/WhatsappModule/segments/WhatsappWindow/components/MessageInfoBar";

export default function WhatsappMainPage() {
  const [showWhatsappSidebar, setShowWhatsappSidebar] = useState(true);
  const [showWhatsappChatBox, setShowWhatsappChatBox] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showMessageInfo, setShowMessageInfo] = useState(false);
  const [showReplyMessage, setShowReplyMessage] = useState(false);
  const [showSideSekeleton, setShowSideSekeleton] = useState(false);
  const [showBoxSekeleton, setShowBoxSekeleton] = useState(false);

  const handleKeyDown = (event: any) => {
    if (event.key === "Escape") {
      setShowWhatsappChatBox(false);
      setShowWhatsappSidebar(true);
      setShowUserInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div>
      <Layout>
        <div className="relative flex w-full h-[85vh] shadow border dark:border-dark-secondary mb-1 overflow-hidden bg-whatsappPrimaryBg dark:bg-dark-primary">
          {showSideSekeleton ? (
            <SideBarSekeleton />
          ) : (
            <>
              {showWhatsappSidebar && (
                <WhatsappSideBar
                  setShowWhatsappChatBox={setShowWhatsappChatBox}
                  setShowWhatsappSidebar={setShowWhatsappSidebar}
                />
              )}
            </>
          )}

          {showBoxSekeleton ? (
            <>
              <ChatBoxSekeleton />
            </>
          ) : (
            <>
              {showWhatsappChatBox && (
                <WhatsappChattingBox
                  setShowUserInfo={setShowUserInfo}
                  showWhatsappSidebar={showWhatsappSidebar}
                  setShowWhatsappChatBox={setShowWhatsappChatBox}
                  setShowWhatsappSidebar={setShowWhatsappSidebar}
                  showUserInfo={showUserInfo}
                  showMessageInfo={showMessageInfo}
                  setShowMessageInfo={setShowMessageInfo}
                  showReplyMessage={showReplyMessage}
                  setShowReplyMessage={setShowReplyMessage}
                />
              )}
            </>
          )}

          {!showWhatsappChatBox && !showBoxSekeleton && <WhatsappDisplay />}
          <WhatsappUserInfoBar
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            showUserInfo={showUserInfo}
            setShowUserInfo={setShowUserInfo}
          />
          <MessageInfoBar
            showMessageInfo={showMessageInfo}
            setShowMessageInfo={setShowMessageInfo}
            setShowUserInfo={setShowUserInfo}
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            setShowReplyMessage={setShowReplyMessage}
          />
        </div>
      </Layout>
    </div>
  );
}
