import { gql } from "@apollo/client";

export const ADD_LABELS = gql`
  mutation MyMutation($data: [brd_whatsapp_labels_insert_input!]!) {
    insert_brd_whatsapp_labels(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const DELETE_LABEL = gql`
  mutation MyMutation($id: uuid!) {
    delete_brd_whatsapp_labels(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
