import React from "react";
import Layout from "../../components/common/Layout";

export default function Dashboard() {
  return (
    <div>
      <Layout>
        <h1>Dashboard </h1>
      </Layout>
    </div>
  );
}
