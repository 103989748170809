import { Button } from "@mui/material";
import React from "react";

const TwitterLogin: React.FC = () => {
  const handleTwitterLogin = () => {
    const currentURL = window.location.href; // This will include the entire URL including path

    const serverlessFunctionURL =
      "https://16d1-95-181-234-27.ngrok-free.app/api/socialmedia/twitter/authenticate";

    window.location.href = `${serverlessFunctionURL}?origin=${encodeURIComponent(
      currentURL
    )}`;
  };

  return (
    <div className="mb-4 sm:p-6 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="mb-2 text-xl font-semibold">Twitter Authenticate</h3>
          <p>Connect Your Twitter Acccount</p>
        </div>
        <div>
          <Button variant="outlined" onClick={handleTwitterLogin}>
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TwitterLogin;
