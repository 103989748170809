import { useQuery, useSubscription } from "@apollo/client";
import SendGridVerify from "./components/SendGridVerify";
import { GET_BRAND_DETAILS } from "./graphql/Query";
import GoogleAuthenticate from "./components/GoogleAuthenticate";
import { useState } from "react";
import { getAlias } from "~/apiCall/gmail";

const EmailSetting = ({ brd_id }: any) => {
  const { data, loading, error } = useSubscription(GET_BRAND_DETAILS, {
    variables: {
      brd_id: brd_id,
    },
  });

  const [activeService, setActiveService] = useState();

  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }

  return (
    <>
      <div className="flex w-full gap-4">
        <div className="w-[50%]">
          <button
            onClick={() => {
              getAlias();
            }}
          >
            test for aliases
          </button>
          <GoogleAuthenticate brand={data?.brd_list?.[0]} />
        </div>
        <div className="w-[50%]">
          <SendGridVerify brand={data?.brd_list?.[0]} />
        </div>
      </div>
    </>
  );
};

export default EmailSetting;
