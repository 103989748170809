import { useApolloClient, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_USER_INQ_LIST } from "~/graphql/inq_list/Query";

export default function useUserInquiryList(userID: any) {
  const [inquiryData, setInquiryData] = useState();

  const client = useApolloClient();

  const runFunc = async () => {
    const res = await client.query({
      query: GET_USER_INQ_LIST,
      variables: { user_id: userID },
    });
    setInquiryData(res?.data?.inq_list);
  };

  useEffect(() => {
    if (userID) {
      runFunc();
    }
  }, [userID]);

  return { inquiryList: inquiryData };
}
