import { Controller } from "react-hook-form";
import { Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { LocationBoxProps } from "./types";
import _airportsData from "./data/airports.json";
import * as JsSearch from "js-search";
import _ from "lodash";
import { customSearch } from "./utils";
const search = new JsSearch.Search("iata_code");
search.addIndex("city_name");
search.addIndex("iata_code");
search.addIndex("city_iata");
// search.addIndex(["city", "name"]);

const defaultSearch = new JsSearch.Search("iata_code");
defaultSearch.addIndex("iata_code");
// search.addIndex(["city", "name"]);
// defaultSearch.addIndex("city_iata");
defaultSearch.addDocuments(_airportsData);
search.addDocuments(_airportsData);

// search.addDocuments(_airportsData);

const LocationBox = ({
  control,
  errors,
  setValue,
  fieldName,
  defaultValue,
  label,
  labelClasses,
  optionBoxClasses,
  optionClasses,
  inputClasses,
  containerClasses,
  placeholder,
  required,
}: LocationBoxProps) => {
  const [selected, setSelected] = useState<any>({});
  const [query, setQuery] = useState("");
  const [filteredAirports, setFilteredAirports] = useState([]);

  const hasError = (errors: any, fieldName: any) => {
    // Split the field name into parts
    const fieldParts = fieldName.split(".");
    // Iterate through the parts to navigate the error object
    let currentObj = errors;
    for (const part of fieldParts) {
      if (currentObj && currentObj[part]) {
        currentObj = currentObj[part];
      } else {
        // If any part is not found, the field has no error
        return { hasError: false, message: null };
      }
    }
    if (currentObj && currentObj.type === "required") {
      return { hasError: true, message: currentObj.message };
    } else {
      return { hasError: false, message: null };
    }
  };

  // Check if the specified field has an error
  const fieldHasError = hasError(errors, fieldName);

  useEffect(() => {
    if (defaultValue) {
      // ;
      const airports = defaultSearch.search(defaultValue);
      // ;
      if (airports?.length > 0) {
        setTimeout(() => {
          setSelected(airports[0]);
          setValue(fieldName, airports[0]);
        }, 200);
      } else {
        const airports = _airportsData?.filter(
          (item) => item?.city_iata === defaultValue
        );
        const obj = {
          name: airports[0]?.city_name,
          city_name: airports[0]?.city_name,
          iata_code: airports[0]?.city_iata || airports[0].iata_code,
        };
        setTimeout(() => {
          setSelected(obj);
          setValue(fieldName, obj);
        }, 200);
      }
    }
  }, []);

  function searchAirports(input: string) {
    // const matchedAirports: any = search.search(input);
    const matchedAirports = customSearch(input);
    const groupedHits: any = _.groupBy(
      matchedAirports.slice(0, 20),
      "city_iata"
    );

    if (Object.keys(groupedHits)?.length > 0) {
      setFilteredAirports(groupedHits);
    }

    return matchedAirports;
  }

  useEffect(() => {
    if (query !== "") {
      searchAirports(query);
    } else {
      setSelected("");
      setValue(fieldName, "");
    }
  }, [query]);

  const resetSelection = () => {
    setQuery("");
    setSelected("");
    setValue(fieldName, "");
  };

  const handleCity = (city: string, airports: any) => {
    const obj = {
      name: airports[0]?.city_name,
      city_name: airports[0]?.city_name,
      iata_code: airports[0]?.city_iata || airports[0].iata_code,
    };

    setValue(fieldName, obj);
    setSelected(obj);
    setFilteredAirports([]);
  };

  return (
    <div className={`w-full ${containerClasses}`}>
      {label && (
        <label htmlFor="fruit" className={`text-[16px] ${labelClasses}`}>
          {label}:
        </label>
      )}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: required || false,
        }}
        defaultValue={defaultValue || ""}
        render={({ field: { onChange } }) => (
          <Combobox value={selected} onChange={onChange}>
            <div className="relative mt-1">
              <div
                className={`relative w-full cursor-default overflow-hidden text-left sm:text-sm`}
              >
                <Combobox.Input
                  className={`w-full border py-2 pl-3 pr-10 text-sm leading-5 rounded text-gray-900 focus:ring-0 ${
                    fieldHasError?.hasError
                      ? "border-red-500"
                      : "border-gray-200"
                  } ${inputClasses}`}
                  displayValue={(item: any) => item.iata_code}
                  defaultValue={selected?.iata_code}
                  placeholder={placeholder}
                  onChange={(event: any) => setQuery(event.target.value)}
                />
                {selected !== "" && (
                  <button
                    onClick={() => resetSelection()}
                    className="absolute h-[20px] w-[20px] bg-basic text-white text-[12px] flex justify-center items-center rounded-full right-2 cursor-pointer  top-[50%] translate-y-[-50%]"
                  >
                    x
                  </button>
                )}
              </div>
              {filteredAirports.length !== 0 && (
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  // afterLeave={() => setQuery('')}
                >
                  <Combobox.Options
                    className={`absolute z-[100] mt-1 max-h-60  w-full max-w-[400px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionBoxClasses}`}
                  >
                    {filteredAirports.length === 0 && query !== "" ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      Object.entries(filteredAirports).map(
                        ([city, airports]: any, index: number) => (
                          <div key={index}>
                            {city !== "undefined" && (
                              <h2
                                onClick={() => handleCity(city, airports)}
                                className="relative cursor-default select-none py-2 bg-gray-300 pl-10 pr-4"
                              >
                                {city}
                              </h2>
                            )}
                            {airports.map((airport: any, i: number) => (
                              <Combobox.Option
                                key={i}
                                className={({ active }: any) =>
                                  `relative select-none py-2 cursor-pointer px-4 ${
                                    active
                                      ? "bg-basic text-white"
                                      : "text-gray-900"
                                  } ${optionClasses}`
                                }
                                value={airport}
                                onClick={() => setSelected(airport)}
                              >
                                {({ selected, active }: any) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {airport.city_name || airport?.name} (
                                      {airport?.iata_code})
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active
                                            ? "text-white"
                                            : "text-teal-600"
                                        }`}
                                      ></span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        )
                      )
                    )}
                  </Combobox.Options>
                </Transition>
              )}
            </div>
          </Combobox>
        )}
      />
      {fieldHasError?.hasError && (
        <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
          {" "}
          <sup>*</sup>This is required.
        </p>
      )}
    </div>
  );
};

export default LocationBox;
