import React from "react";
import SuggestionStop from "./components/SuggestionStop";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function FlightDetail({ suggestion_leg, index ,suggestion}: any) {
  return (
    <div className="flex-1 w-full flex flex-col items-start gap-5 flex-end">
      <div className=" w-full">
        <div className="flex justify-between w-full text-basic dark:text-dark-primary">
          <b>{capitalizeFirstLetter(suggestion_leg.type)}</b>
          <b>{capitalizeFirstLetter(suggestion_leg?.cabin) || "N/A"}</b>
        </div>

        {/* <hr className="h-px mb-2 bg-gray-500 border-0 w-full" /> */}
      </div>
      <div className="self-stretch flex flex-col gap-2 items-center justify-between">
        {suggestion_leg?.inq_suggestion_leg_stops
          .sort((a: any, b: any) => a.ordering - b.ordering) // Sort stops by ordering
          .map((stop: any, i: any) => (
            <>
              <SuggestionStop stop={stop} suggestion={suggestion} legs={suggestion_leg} ind={i}/>
              {/* {suggestion?.suggestion_stops.length !== i + 1 && (
        <hr className="h-px bg-gray-400 my-[5px] border-0 w-full" />
      )} */}
            </>
          ))}
      </div>
      <div className="w-full">
        <div className="self-stretch flex flex-row items-start justify-start">
          <div className="flex flex-row items-start justify-start gap-[41px]">
            <div className="flex flex-row items-center justify-start gap-[5px] text-basic dark:text-dark-primary">
              <strong>Hand Carry:</strong>
              <div className="relative inline-block w-[39px] h-[15px] shrink-0 font-medium">
                {suggestion_leg.hand_carry} kg
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[5px] text-basic dark:text-dark-primary">
              <strong>Luggage:</strong>
              <div className="relative inline-block w-[39px] h-[15px] shrink-0 font-medium">
                {suggestion_leg.luggage} kg
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
