import { gql } from "@apollo/client";

export const GET_COMMUNITIES = gql`
  subscription getLabels($brd_ids: [uuid!]!) {
    brd_communities(
      where: { brd_id: { _in: $brd_ids } }
      order_by: { last_message_time: desc }
    ) {
      id
      name
      image
      last_message
      brd_id
      last_message_time
      brd_community_users {
        community_id
        user_id
      }
    }
  }
`;

export const GET_COMMUNITY = gql`
  subscription getLabels($id: uuid!) {
    brd_communities(where: { id: { _eq: $id } }) {
      id
      name
      image
      last_message
      brd_id
      last_message_time
      brd_list {
        phoneNumberId
      }
      brd_community_messages {
        text
        timestamp
        sender_id
        type
        file_name
        headerVariables
        bodyVariables
        template_name
        template_language
        brd_whatsapp_messages {
          brd_whatsapp_conversation {
            user {
              displayName
              phoneNumber
            }
          }
          brd_whatsapp_messages_statuses {
            key
            timestamp
            message_id
            created_at
          }
        }
        community_id
      }
      brd_community_users {
        community_id
        user_id
        user {
          displayName
          phoneNumber
        }
      }
    }
  }
`;

export const GET_ALL_COMMUNTITIES = gql`
  query getCommunitites($condition: brd_communities_bool_exp!, $offset: Int!) {
    brd_communities(where: $condition, limit: 20, offset: $offset) {
      id
      name
      image
      last_message
      brd_id
      last_message_time
      brd_community_users {
        community_id
        user_id
      }
      brd_list {
        name
      }
    }
  }
`;

export const GET_ALL_COMMUNTITIES_WITHOUT_OFFSET = gql`
  query getCommunitites($condition: brd_communities_bool_exp!) {
    brd_communities(where: $condition) {
      id
      name
      image
      last_message
      brd_id
      last_message_time
      brd_community_users {
        community_id
        user_id
      }
      brd_list {
        name
      }
    }
  }
`;

export const GET_COMMUNTITIES_USER = gql`
  query getCommunityUsers($condition: brd_community_users_bool_exp!) {
    brd_community_users(where: $condition) {
      id
      community_id
      user_id
    }
  }
`;
