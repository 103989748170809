import React from "react";

export default function BrandsAssign({ brd_list, register }: any) {
  return (
    <table>
      <thead>
        <th colSpan={2}>
          <h1>Brands</h1>
        </th>
      </thead>
      <tbody>
        {brd_list.length > 0 &&
          brd_list.map((brd: any, i: number) => {
            let checked = false;
            return (
              <tr key={i}>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    {...register("brands")}
                    id={brd.id}
                    value={brd.id}
                    defaultChecked={checked}
                  />
                </td>
                <td>
                  <label className="w-full " htmlFor={brd.id}>
                    {brd.name}
                  </label>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
