import { useQuery } from "@apollo/client";
import { GET_BRD_INQ_LIST } from "~/graphql/inq_list/Query";

export default function useBrdInqList(brdId: any) {
  const { data } = useQuery(GET_BRD_INQ_LIST, {
    variables: {
      brd_id: brdId,
    },
  });

  return { inqsListWithBrdId: data?.inq_list };
}
