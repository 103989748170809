import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UPDATE_BRD_SERVICES_CATEGORY } from "~/graphql/brd_services_category/Mutation";

interface FormData {
  name: string;
}

const EditCategory = ({ setSidePopup, category }: any) => {
  const client = useApolloClient();
  const { register, handleSubmit, reset } = useForm<FormData>();
  const [updateCategory] = useMutation(UPDATE_BRD_SERVICES_CATEGORY);
  const onSubmit = async (data: FormData) => {
    try {
      const variables = {
        id: category?.id,
        name: data.name,
      };

      const response = await updateCategory({ variables });
      console.log(response);

      if (response.data.update_brd_services_category.affected_rows > 0) {
        toast.success("Category updated.");
        await client.refetchQueries({
          include: "all",
        });
        reset();
        setSidePopup(false);
      } else {
        toast.error("No service updated.");
      }
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };

  return (
    <>
      <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
          Edit Category
        </h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            {...register("name")}
            defaultValue={category?.name}
            style={{ marginBottom: "16px" }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Update
          </Button>
        </form>
      </div>
    </>
  );
};

export default EditCategory;
