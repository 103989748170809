import { gql } from "@apollo/client";

export const INSERT_DEFAULT_SETTING = gql`
  mutation InsertDefaultSetting($data: brd_default_setting_insert_input!) {
    insert_brd_default_setting_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_DEFAULT_SETTING = gql`
  mutation UpdateDefaultSetting(
    $id: brd_default_setting_pk_columns_input!
    $data: brd_default_setting_set_input!
  ) {
    update_brd_default_setting_by_pk(pk_columns: $id, _set: $data) {
      id
      features_heading
      features_sub_heading
    }
  }
`;

export const UPDATED_FEATURES = gql`
  mutation MyMutation(
    $id: brd_features_pk_columns_input!
    $ftData: brd_features_set_input!
  ) {
    update_brd_features_by_pk(pk_columns: $id, _set: $ftData) {
      id
    }
  }
`;

export const INSERT_FEATURES = gql`
  mutation InsertFeature($data: brd_features_insert_input!) {
    insert_brd_features_one(object: $data) {
      id
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation DeleteFeature($id: uuid!) {
    delete_brd_features_by_pk(id: $id) {
      id
    }
  }
`;
