import { gql } from "@apollo/client";

export const EDIT_USER = gql`
  mutation EditUser(
    $id: uuid!
    $newUser: users_set_input!
    $newUserBrands: [brd_users_insert_input!]!
    $prevPhoneNumber: String!
  ) {
    delete_brd_whatsapp_conversations(
      where: { user_phone: { _eq: $prevPhoneNumber } }
    ) {
      affected_rows
    }

    updateUsers(where: { id: { _eq: $id } }, _set: $newUser) {
      affected_rows
    }

    delete_brd_users(where: { user_id: { _eq: $id } }) {
      affected_rows
    }

    # Insert new user_brands records
    insert_brd_users(objects: $newUserBrands) {
      affected_rows
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $id: thp_users_profile_pk_columns_input!
    $data: thp_users_profile_set_input!
  ) {
    update_thp_users_profile_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;

export const INSERT_PROFILE = gql`
  mutation InsertProfile($data: thp_users_profile_insert_input!) {
    insert_thp_users_profile_one(object: $data) {
      id
    }
  }
`;
