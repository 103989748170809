import { Avatar, Popover } from "@mui/material";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useState } from "react";
import MenuItems from "./component/MenuItems";
import Contacts from "./component/Contacts";

const Header = ({ setShowWhatsappChatBox, setShowWhatsappSidebar }: any) => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showContactBar, setShowContactBar] = useState(false);
  const [showGroupBar, setShowGroupBar] = useState(false);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex justify-between items-center">
        <Avatar
          alt="whatsappAvator"
          src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp"
        />
        <div>
          <AddCommentOutlinedIcon
            sx={{
              marginRight: "20px",
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowContactBar(true);
            }}
          />
          <MoreVertOutlinedIcon
            sx={{
              cursor: "pointer",
              color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            }}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setShowMenu(!showMenu);
            }}
          />
          <Popover
            sx={{
              background: "transparent",
              top: "10px",
            }}
            anchorEl={anchorEl}
            onClose={() => {
              setShowMenu(!showMenu);
            }}
            open={showMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItems
              setShowGroupBar={setShowGroupBar}
              setShowMenu={setShowMenu}
            />
          </Popover>
        </div>
      </div>
      <Contacts
        setShowContactBar={setShowContactBar}
        showContactBar={showContactBar}
        setShowWhatsappChatBox={setShowWhatsappChatBox}
        setShowWhatsappSidebar={setShowWhatsappSidebar}
        showGroupBar={showGroupBar}
        setShowGroupBar={setShowGroupBar}
      />
    </>
  );
};

export default Header;
