import { MenuItem } from "@mui/material";

const MenuItems = ({ setShowGroupBar, setShowMenu }: any) => {
  return (
    <>
      <div className="shadow py-[9px] ">
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
          onClick={() => {
            setShowGroupBar(true);
            setShowMenu(false);
          }}
        >
          New group
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Starred messages
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Select chats
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Settings
        </MenuItem>
        <MenuItem
          sx={{ paddingRight: "58px", paddingLeft: "24px", fontSize: "15px" }}
        >
          Logout
        </MenuItem>
      </div>
    </>
  );
};

export default MenuItems;
