import { StyleSheet } from "@react-pdf/renderer";

export const getStyles = (secondaryColor: any) => {
  return StyleSheet.create({
    page: {
      padding: "20px",
      backgroundColor: "#F9FAFC",
      display: "flex",
      flexDirection: "column",
      gap: "19px",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerFirstContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
    },
    brandDetail: {
      display: "flex",
      flexDirection: "column",
      gap: "3px",
      fontSize: "10px",
      fontWeight: 400,
      color: "#5E6470",
    },
    logo: {
      width: "48px",
      height: "48px",
      color: "#5E6470",
    },
    brandName: {
      color: secondaryColor,
      fontSize: "18px",
      fontWeight: 600,
    },
    headerSecondContainer: {
      display: "flex",
      flexDirection: "column",
      fontSize: "10px",
      fontWeight: 400,
      color: "#5E6470",
      width: "200px",
      gap: "3px",
    },
    secondContainerText: {
      textAlign: "right",
    },
    inquiryDetailContainer: {
      display: "flex",
      flexDirection: "column",
      border: "0.5px",
      borderRadius: "12px",
      padding: "15px",
      gap: "26px",
      borderColor: "#D7DAE0",
      backgroundColor: "#FFFFFF",
    },
    inquirySecondContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "150px",
    },
    boldFont: {
      fontWeight: 600,
      color: "black",
    },
    invoiceGbp: {
      fontWeight: 700,
      fontSize: "20px",
      textAlign: "right",
      color: secondaryColor,
    },
    twoContainerGroup: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    inquiryColumnContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    passengerHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#5E6470",
      borderTop: "0.5px",
      borderBottom: "0.5px",
      borderColor: "#D7DAE0",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontSize: "8px",
    },
    passengerBody: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#5E6470",
      fontSize: "10px",
      borderBottom: "0.5px",
      borderColor: "#D7DAE0",
      paddingBottom: "5px",
      paddingTop: "5px",
    },
    totalsContainer: {
      width: "250px",
      display: "flex",
      flexDirection: "column",
      alignSelf: "flex-end",
      marginTop: "-15px",
    },
    totals: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#5E6470",
      fontSize: "10px",
      paddingBottom: "5px",
      paddingTop: "5px",
    },
    paid: {
      borderBottom: "0.5px",
      borderColor: "#D7DAE0",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#5E6470",
      fontSize: "10px",
      paddingBottom: "5px",
      paddingTop: "5px",
    },
    flightDetailHeader: {
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: "10px",
      paddingVertical: "5px",
      backgroundColor: "#F9FAFC",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flightTypeText: {
      color: "black",
      fontWeight: 700,
      fontSize: "12px",
    },
    flightCabinContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "3px",
      fontSize: "10px",
      color: "#5E6470",
      fontWeight: 300,
    },
    flightCabinText: {
      textTransform: "capitalize",
      color: "black",
      fontWeight: 700,
      fontSize: "12px",
    },
    flightDetailBody: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      paddingBottom: "0px",
      backgroundColor: "#FFFFFF",
    },
    flightLegs: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "3px",
    },
    flightLegContainers: {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
    },
    flightLegContainerRight: {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
      alignItems: "flex-end",
    },
    flightLegSubContainers: {
      display: "flex",
      flexDirection: "column",
      gap: "3px",
      color: "#1A1C21",
      fontSize: "10px",
    },
    flightLegSubContainerRight: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "3px",
      color: "#1A1C21",
      fontSize: "10px",
    },
    flightLegDateTime: {
      color: "#5E6470",
      fontSize: "10px",
      fontWeight: 400,
    },
    flightLegArrDep: {
      color: "black",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "right",
    },
    dottedImg: {
      height: "1px",
    },
    airlineText: {
      fontWeight: 400,
      fontSize: "12px",
      color: "#1A1C21",
    },
    airlineContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      alignItems: "center",
    },
    flightLegsParentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      alignItems: "center",
    },
    parallelContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      justifyContent: "space-between",
    },
    stayConatinerText: {
      fontWeight: 500,
      color: secondaryColor,
      textAlign: "center",
      fontSize: "12px",
    },
    luggageContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "40px",
      marginTop: "10px",
    },
    luggageSubContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "7px",
      alignItems: "center",
    },
    luggageText: {
      color: "#1A1C21",
      fontSize: "12px",
      fontWeight: 400,
    },
    luggageImg: {
      width: "20px",
    },
    handCarryImg: {
      width: "12px",
    },
    termConditionContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      fontSize: "10px",
    },
    termConditionMainHeading: {
      color: "#5E6470",
    },
    termConditioHeadings: {
      fontWeight: 700,
      color: "black",
    },
    termConditionText: {
      fontWeight: 400,
      color: "#5E6470",
      lineHeight: "1.3px",
    },
    signContainer: {
      height: "40px",
      alignSelf: "flex-end",
      backgroundColor: "#EDEDED",
      paddingVertical: "20px",
      paddingHorizontal: "5px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100px",
    },
    signImage: {
      height: "40px",
      width: "100px",
    },
    iconSize: {
      width: "25px",
      height: "25px",
    },
  });
};
