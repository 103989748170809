import { gql } from "@apollo/client";

export const DELETE_FAQ = gql`
  mutation DeleteFAQ($id: uuid) {
    delete_brd_faq(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_FAQ = gql`
  mutation insertFAQ($faqData: brd_faq_insert_input!) {
    insert_brd_faq_one(object: $faqData) {
      id
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation MyMutation(
    $id: brd_faq_pk_columns_input!
    $faqData: brd_faq_set_input!
  ) {
    update_brd_faq_by_pk(pk_columns: $id, _set: $faqData) {
      id
    }
  }
`;
