const Modal = ({ isOpen, onClose, children, title }: any) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        id="drawer-email-setting-default"
        className={`fixed top-0 right-0 z-50 w-full h-screen max-w-md p-4 overflow-y-auto transition-transform bg-white  ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
        aria-labelledby="drawer-label"
        aria-hidden="true"
      >
        <h5
          id="drawer-label"
          className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          {title}
        </h5>

        <div className="space-y-4">
          <button
            onClick={onClose}
            type="button"
            data-drawer-dismiss="drawer-email-setting-default"
            aria-controls="drawer-email-setting-default"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
