import { gql } from "@apollo/client";

export const CREATE_LINKEDIN_POST = gql`
  mutation CREATE_LINKEDIN_POST(
    $access_token: String!
    $id: String!
    $author: String!
    $lifecycleState: String!
    $shareCommentaryText: String!
    $shareMediaCategory: String!
    $mediaStatus: String!
    $visibility: String!
    $mediaImage: [String]!
    $mediaVideo: [String]!
  ) {
    createlinkedinPost(
      arg1: {
        access_token: $access_token
        id: $id
        author: $author
        lifecycleState: $lifecycleState
        shareCommentaryText: $shareCommentaryText
        shareMediaCategory: $shareMediaCategory
        mediaStatus: $mediaStatus
        visibility: $visibility
        mediaImage: $mediaImage
        mediaVideo: $mediaVideo
      }
    ) {
      id
    }
  }
`;
