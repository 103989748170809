import { useQuery } from "@apollo/client";
import { Autocomplete, TextField, Typography } from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { useClickOutside } from "@mantine/hooks";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useMutation } from "@apollo/client";
import { ADD_AD } from "~/graphql/brd_google_ads/Mutation";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { WHATSAPP_TEMPLATE_CREATE } from "~/graphql/actions/whatsapp/whatsapp_templates_create";
import { toast } from "react-toastify";
import brandsStore from "~/store/brandsStore/brandsStore";

const startOfQ12022 = dayjs("2022-01-01T00:00:00.000");
const endOfQ12022 = dayjs("2022-03-31T23:59:59.999");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function TemplateCreate({
  setShowTemplateBar,
  setShowCreateTemplateBar,
}: any) {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { brands }: any = brandsStore();

  const [loading, setIsLoading] = useState(false);
  const [headerVariable, setHeaderVariable] = useState(false);
  const [bodyVariable, setBodyVariable] = useState(0);
  const [createTemplate] = useMutation(WHATSAPP_TEMPLATE_CREATE);

  const brandID = watch("brand");

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const payload = {
        brd_id: brandID || brands?.[0]?.id,
        template: {
          category: data.category,
          language: data.lan,
          name: data.name,
          body: {
            text: data.body_text,
            variables: data.body.variable,
          },
          header: {
            type: data.header.type || "NONE",
            text: data.header.text,
            variables: [data.header_1],
          },
          footer: data.footer || "footer data",
        },
      };
      const response = await createTemplate({
        variables: {
          data: payload,
        },
      });

      if (response?.data?.whatsapp_templates_create?.id) {
        toast.success("Template Created Successfully");
        setIsLoading(false);
        setShowCreateTemplateBar(false);
        setShowTemplateBar(true);
      } else {
        toast.error("Failed to Create Template");
        setIsLoading(false);
      }
    } catch (err: any) {
      toast.error("Failed to Create Template");
      setIsLoading(false);
    }
  };
  const categories = [
    { name: "Marketing", value: "MARKETING" },
    { name: "Utility", value: "UTILITY" },
  ];
  const lan = [
    { name: "English (US)", value: "en_US" },
    { name: "English (UK)", value: "en_GB" },
  ];
  const headerType = watch("header.type");
  const headerText = watch("header.text");
  const addHeaderVariable = () => {
    !headerVariable
      ? setValue("header.text", `${getValues("header.text")}{{1}}`)
      : setValue("header.text", getValues("header.text").replace("{{1}}", ""));
    setHeaderVariable(!headerVariable);
  };

  const addBodyVariable = () => {
    setBodyVariable((prv: any) => prv + 1);
    setValue("body_text", `${getValues("body_text")}{{${bodyVariable + 1}}}`);
  };

  useEffect(() => {
    headerText
      ? headerText.includes("{{1}}")
        ? setHeaderVariable(true)
        : setHeaderVariable(false)
      : setHeaderVariable(false);
  }, [headerText]);

  const bodyText = watch("body_text");
  function replaceWithSequentialNumbers(inputString: any) {
    let nextNumber: any = 1;
    const replacedString = inputString.replace(/\{\{\d+\}\}/g, (match: any) => {
      const replacement = `{{${nextNumber}}}`;
      nextNumber++;
      return replacement;
    });

    return replacedString;
  }

  useEffect(() => {
    bodyText
      ? Array.from({ length: bodyVariable }, (_, index) => index + 1).map(
          (num: any) => {
            if (!bodyText.includes(`{{${num}}}`)) {
              setBodyVariable((prv: any) => prv - 1);
              const modifiedString = replaceWithSequentialNumbers(
                getValues("body_text")
              );
              setValue("body_text", modifiedString);
            }
          }
        )
      : setBodyVariable(0);
  }, [bodyText]);

  return (
    <div>
      <form className="w-full px-[20px]" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-[20px] font-semibold text-black dark:text-white pt-[8px] pl-2 mt-[15px]">
          Create Template
        </h1>
        {brands.length > 1 && (
          <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brand", { required: true })}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  id="combo-box-demo"
                  options={brands ? brands : []}
                  onChange={(_, data) => field.onChange(data?.id)}
                  getOptionLabel={(option: any) => option?.name}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brand"] && el.focus();
                      }}
                      error={errors["brand"] ? true : false}
                      {...params}
                      label="Brand"
                    />
                  )}
                />
              )}
            />
          </div>
        )}

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("category", { required: true })}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                id="combo-box-demo"
                options={categories}
                onChange={(_, data) => field.onChange(data?.value)}
                getOptionLabel={(option: any) => option?.name}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["category"] && el.focus();
                    }}
                    error={errors["category"] ? true : false}
                    {...params}
                    label="Category"
                  />
                )}
              />
            )}
          />
        </div>
        <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
          <TextField
            error={errors["name"] ? true : false}
            id="outlined-basic"
            label="Name"
            {...register("name", { required: true })}
            variant="outlined"
            name="name"
            className="w-full"
          />
        </div>

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px]">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("lan", { required: true })}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                id="combo-box-demo"
                options={lan}
                onChange={(_, data) => field.onChange(data?.value)}
                getOptionLabel={(option: any) => option?.name}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["lan"] && el.focus();
                    }}
                    error={errors["lan"] ? true : false}
                    {...params}
                    label="Language"
                  />
                )}
              />
            )}
          />
        </div>
        <div className="pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[15px] flex">
          <div className="min-w-[180px]  pt-[8px]">
            <Controller
              control={control}
              {...register("header.type")}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  id="combo-box-demo"
                  defaultValue={{ value: "none", name: "None" }}
                  options={[
                    { value: "none", name: "None" },
                    { value: "text", name: "Text" },
                    { value: "image", name: "Image" },
                    { value: "video", name: "Video" },
                    { value: "document", name: "Document" },
                    { value: "location", name: "Location" },
                  ]}
                  onChange={(_, data) => field.onChange(data?.value)}
                  getOptionLabel={(option: any) => option?.name}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["header"] && el.focus();
                      }}
                      error={errors["header"] ? true : false}
                      {...params}
                      label="Header - Optional"
                    />
                  )}
                />
              )}
            />
          </div>
          <div className="w-full flex  justify-end">
            {headerType === "text" ? (
              <div className="flex flex-col justify-end items-end space-x-1 w-full overflow-x-auto  pt-[8px]  ">
                <TextField
                  error={errors["text"] ? true : false}
                  id="outlined-basic"
                  label="Text"
                  {...register("header.text", { required: true })}
                  variant="outlined"
                  className="w-full"
                />
                {!headerVariable && (
                  <div>
                    <Button onClick={() => addHeaderVariable()}>
                      {headerVariable ? `- Remove` : `+ Add`} Variable
                    </Button>
                  </div>
                )}
              </div>
            ) : headerType === "image" ||
              headerType === "video" ||
              headerType === "document" ? (
              <div className="flex  space-x-1 w-full overflow-x-auto  pt-[8px]  ">
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    {...register("header.file", { required: true })}
                  />
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {headerType === "text" && headerVariable && (
          <div>
            <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px] mt-2">
              <TextField
                error={errors["header_1"] ? true : false}
                id="outlined-basic"
                label="Variable: {{1}}"
                {...register("header_1", { required: true })}
                variant="outlined"
                name="header_1"
                className="w-full"
              />
            </div>
          </div>
        )}
        <div className="flex flex-col items-end">
          <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px] mt-2">
            <TextField
              id="outlined-multiline-static"
              label="Body"
              sx={{
                width: "100%",
              }}
              {...register("body_text", { required: true })}
              multiline
              rows={4}
            />
          </div>
          <div>
            <Button onClick={() => addBodyVariable()}>+ Add Variable</Button>
          </div>
        </div>

        {Array.from({ length: bodyVariable }, (_, index) => index + 1).map(
          (num: any, index) => {
            return (
              <>
                <div>
                  <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px] mt-2">
                    <TextField
                      // error={errors["header_1"] ? true : false}
                      id="outlined-basic"
                      label={`Variable: {{${num}}}`}
                      {...register(`body.variable.${num}`, { required: true })}
                      variant="outlined"
                      className="w-full"
                    />
                  </div>
                </div>
              </>
            );
          }
        )}

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto  pt-[8px]  mt-[5px]">
          <TextField
            error={errors["footer"] ? true : false}
            id="outlined-basic"
            label="Footer - Optional"
            {...register("footer")}
            variant="outlined"
            name="footer"
            className="w-full"
          />
        </div>

        <div className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px]  mt-[15px] justify-end">
          <LoadingButton
            color="secondary"
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type="submit"
          >
            <span>Save</span>
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}
