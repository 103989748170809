import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { INSERT_BRAND_DETAILS, UPDATE_BRAND_DETAIL } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const LegalInformation = ({ brand }: any) => {
  const { brd_id } = useParams();
  const details = brand?.brd_details?.at(0);

  const client = useApolloClient();
  const [updateBrandDetail] = useMutation(UPDATE_BRAND_DETAIL);
  const [insertBrandDetail] = useMutation(INSERT_BRAND_DETAILS);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    if (details?.id) {
      try {
        const response = await updateBrandDetail({
          variables: {
            id: {
              id: details.id,
            },
            brdData: inpData,
          },
        });
        toast.success("Brand updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      } catch (e: any) {
        toast.error(e.message);
      }
    } else {
      const response = await insertBrandDetail({
        variables: {
          data: { ...inpData, brd_id: brd_id },
        },
      });
      if (response?.data?.insert_brd_detail_one?.id) {
        toast.success("Brand updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Legal Information</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              defaultValue={details?.authority_link}
              error={errors["authority_link"] ? true : false}
              {...register("authority_link", { required: true })}
              label="Authority Link"
              type="url"
            />
            <TextField
              defaultValue={details?.authority_number}
              error={errors["authority_number"] ? true : false}
              {...register("authority_number", { required: true })}
              label="Authority Number"
              type="number"
            />
            <TextField
              defaultValue={details?.iata}
              error={errors["iata"] ? true : false}
              {...register("iata", { required: true })}
              label="Iata"
              type="text"
            />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
            Save Legal Information
          </Button>
        </Box>
      </div>
    </>
  );
};

export default LegalInformation;
