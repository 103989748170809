import React from "react";
import { useParams } from "react-router-dom";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import WhatsappTemplates from "~/modules/BrandModule/modules/TriggersTemplates/segments/WhatsappTemplates";

const WhatsappTemplatesPage = () => {
  const { brd_id } = useParams();
  return (
    <div>
      <Layout>
        <BrandModule>
          <WhatsappTemplates brd_id={brd_id} />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default WhatsappTemplatesPage;
