import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { IMPORT_PAGE, IMPORT_PAGE_TIPS } from "./graphql/Mutation";
import { useParams } from "react-router-dom";
import { GET_FLIGHT_PAGES } from "./graphql/Query";

export default function SingleFlightPage({
  page,
  register,
  errors,
  control,
  key,
  importPage,
  setValue,
  getValues,
  watch,
}: any) {
  const { brd_id } = useParams();

  const { loading, data, error } = useQuery(GET_FLIGHT_PAGES, {
    variables: {
      slug: page.slug,
      brd_id: brd_id,
    },
  });

  const checkBoxRef = useRef(null);
  const [status, setStatus] = useState("Loading..");
  const [isDisable, setIsDisable] = useState(false);
  const [checkBox, setCheckBox] = useState<any>(
    getValues(`checkbox.${page.id}.value`)
  );

  const [importPageMutation] = useMutation(IMPORT_PAGE);
  const [importPageTips] = useMutation(IMPORT_PAGE_TIPS);

  const importPageContent = async () => {
    const data: any = [
      {
        brd_id: brd_id,
        origin_iata: page.origin_iata,
        destination_iata: page.destination_iata,
        origin_name: page.origin_name,
        destination_name: page.destination_name,
        show_in_footer: false,
        page_content: page.data.content,
        slug: page.slug,
        show_in_home: getValues(`home.${page.id}.value`) || false,
        show_featured_destination:
          getValues(`featured.${page.id}.value`) || false,
        featured_destination_image: page.featured_image,
        secondary_page_content: page.data.summary,
        main_heading: page.data.heading,
      },
    ];

    try {
      const res = await importPageMutation({
        variables: {
          data,
        },
      });

      const pageId = res?.data?.insert_brd_flights_pages?.returning?.[0]?.id;

      const tips: any = [
        {
          flight_page_id: pageId,
          heading: page.data.tip_1_heading,
          description: page.data.tip_1_description,
        },
        {
          flight_page_id: pageId,
          heading: page.data.tip_2_heading,
          description: page.data.tip_2_description,
        },
        {
          flight_page_id: pageId,
          heading: page.data.tip_3_heading,
          description: page.data.tip_3_description,
        },
        {
          flight_page_id: pageId,
          heading: page.data.tip_4_heading,
          description: page.data.tip_4_description,
        },
      ];

      const res2 = await importPageTips({
        variables: {
          tips,
        },
      });

      setStatus("Successfully Imported");
      setIsDisable(true);
    } catch (e) {
      console.error("error", e);
      setStatus("Error");
    }
  };

  useEffect(() => {
    if (!isDisable) {
      if (getValues(`checkbox.${page.id}.value`)) {
        if (importPage) {
          setStatus("Importing..");
          importPageContent();
        } else {
          setStatus("Available for import");
        }
      }
    }
  }, [importPage]);

  const activeValue = (fieldName: any) => {
    if (!isDisable) {
      setValue(fieldName, !getValues(fieldName));
    }
  };

  useEffect(() => {
    if (data?.brd_flights_pages?.length > 0) {
      setValue(`checkbox.${page.id}.value`, false);
      setStatus("Slug already in used");
      setIsDisable(true);
    } else {
      setIsDisable(false);
      setStatus("Available for import");
    }
  }, [data]);

  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div className="p-4 border-gray-200 dark:border-gray-900 bg-secondary dark:bg-dark-secondary shadow border-b-[1px]">
      <div
        className={`flex flex-row gap-4 flex-start w-full ${
          isDisable ? "cursor-not-allowed" : ""
        }`}
      >
        <div className="border-gray-200 dark:border-gray-900 border-r-[1px] pr-[30px] pt-[5px] relative">
          <Controller
            control={control}
            name={`checkbox.${page.id}.value`}
            render={({ field }) => (
              <>
                <input
                  type="checkbox"
                  className="bg-primary dark:bg-dark-primary"
                  {...field}
                  value={field.value} // Set the value explicitly
                  defaultChecked={field.value}
                />
                <label></label>
              </>
            )}
          />
          <div
            className="h-[30px] w-[40px] absolute top-0 cursor-pointer"
            onClick={() => {
              activeValue(`checkbox.${page.id}.value`);
            }}
          ></div>
        </div>
        <div className="border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[350px]">
          {page.origin_name} <span>({page.origin_iata})</span>
        </div>
        <div className="border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[350px]">
          {page.destination_name} <span>({page.destination_iata})</span>
        </div>
        <div className="border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] w-[350px]">
          {page.slug}
        </div>
        <div className="border-gray-200 dark:border-gray-900 text-primary dark:text-dark-primary border-r-[1px] pr-[30px] pt-[5px] relative w-[180px]">
          <Controller
            control={control}
            name={`home.${page.id}.value`}
            render={({ field }) => (
              <>
                <input
                  type="checkbox"
                  {...field}
                  value={field.value} // Set the value explicitly
                  defaultChecked={field.value}
                />
                <label></label>
              </>
            )}
          />
          <div
            className="h-[30px] w-[40px] absolute top-0 cursor-pointer"
            onClick={() => {
              activeValue(`home.${page.id}.value`);
            }}
          ></div>
        </div>
        <div className="border-gray-200 dark:border-gray-900 border-r-[1px] pr-[30px] pt-[5px] relative w-[180px]">
          <Controller
            control={control}
            name={`featured.${page.id}.value`}
            render={({ field }) => (
              <>
                <input
                  type="checkbox"
                  {...field}
                  value={field.value} // Set the value explicitly
                  defaultChecked={field.value}
                />
                <label></label>
              </>
            )}
          />
          <div
            className="h-[30px] w-[40px] absolute top-0 cursor-pointer"
            onClick={() => {
              activeValue(`featured.${page.id}.value`);
            }}
          ></div>
        </div>
        <div
          className={`border-gray-200 dark:border-gray-900 pr-[30px] pt-[5px] w-[250px] ${
            isDisable ? "text-[red]" : "text-[green]"
          }`}
        >
          {status}
        </div>
      </div>
    </div>
  );
}
