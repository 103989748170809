import Layout from "~/components/common/Layout";
import EmailSendersList from "~/modules/BrandModule/modules/MarketingModule/segments/EmailCampaigns/components/EmailSenderList";

export default function EmailSenderListPage() {
  return (
    <div>
      <Layout>
        <EmailSendersList />
      </Layout>
    </div>
  );
}