import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { DELETE_TESTIMONAIAL } from "./graphql/Mutation";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import BrandAddUpdateSec from "~/components/common/BrandAddUpdateSec";
import { useForm } from "react-hook-form";
import { UPDATE_TESTIMONIAL } from "../EditTestimonial/graphql/Mutation";

const TestimonialRow = ({ tsm }: any) => {
  const [deleteTestimonial] = useMutation(DELETE_TESTIMONAIAL);
  const [updateTestimonial] = useMutation(UPDATE_TESTIMONIAL);
  const client = useApolloClient();
  const [edit, setEdit] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const deleteCard = async (id: any) => {
    const res = await deleteTestimonial({ variables: { id } });
    if (res?.data?.delete_brd_testimonials?.affected_rows > 0) {
      toast.success("Testimonial deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
  };

  const updateTestimonials = async (inpData: any) => {
    const res = await updateTestimonial({
      variables: {
        id: { id: tsm.id },
        tsData: {
          name: inpData?.name,
          feedback: inpData?.feedback,
          rating: inpData?.rating,
          iata: inpData?.destination?.iata_code || null,
          airline_iata: inpData?.airline?.iata || null,
        },
      },
    });

    if (res?.data?.update_brd_testimonials_by_pk?.id) {
      toast.success("Testimonial updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
      setEdit(false);
    }
  };

  return (
    <>
      <tr className="hover:bg-primary dark:hover:bg-dark-primary">
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id="checkbox-194556"
              aria-describedby="checkbox-1"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded bg-primary dark:bg-dark-primary dark:border-gray-600"
            />
            <label htmlFor="checkbox-194556" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-primary dark:text-dark-primary whitespace-nowrap">
          <div className="text-base font-semibold text-primary dark:text-dark-primary ">
            {tsm?.rating}
          </div>
        </td>
        <td className="p-4 text-base font-medium text-primary dark:text-dark-primary whitespace-nowrap ">
          {tsm?.name}
        </td>
        <td className="max-w-sm p-4 overflow-hidden text-base font-normal text-primary dark:text-dark-primary truncate xl:max-w-xs">
          {tsm?.feedback}
        </td>

        <td className="p-4 space-x-2 whitespace-nowrap">
          <button
            type="button"
            onClick={() => setEdit(true)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-basic hover:bg-purple-600"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fill-rule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Update
          </button>
          <button
            type="button"
            onClick={() => deleteCard(tsm.id)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-basic dark:text-white hover:text-white outline-1 outline outline-basic hover:bg-purple-600 hover:outline-none rounded-lg"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Delete item
          </button>
          <SideDrawer
            setSidePopup={setEdit}
            sidePopup={edit}
            component={
              <BrandAddUpdateSec
                mainHeading="Add New Testimonial"
                errors={errors}
                register={register}
                handleSubmit={handleSubmit}
                control={control}
                addData={updateTestimonials}
                field1={{
                  label: "Name",
                  name: "name",
                  defaultValue: tsm?.name,
                }}
                field2={{
                  label: "Rating",
                  name: "rating",
                  defaultValue: tsm?.rating,
                }}
                field3={{
                  label: "Destination",
                  name: "destination",
                  defaultValue: tsm?.iata,
                }}
                field4={{
                  label: "Airline",
                  name: "airline",
                  defaultValue: tsm?.airline_iata,
                }}
                field5={{
                  label: "Feedback",
                  name: "feedback",
                  defaultValue: tsm?.feedback,
                }}
              />
            }
          />
        </td>
      </tr>
    </>
  );
};

export default TestimonialRow;
