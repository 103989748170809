import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InputField from "~/components/FormInputs/InputField";
import ContentEditor from "~/components/common/ContentEditor";
import { GET_BRAND_TEMPLATE } from "../../../../graphql/Query";
import { FaRecycle } from "react-icons/fa";
import { toast } from "react-toastify";
import { EDIT_EMAIL_TEMPLATE } from "../../graphql/Mutation";
import { Button } from "@mui/material";

export default function TemplateInputs({
  register,
  errors,
  template,
  content,
  setContent,
  setValue,
  RestoreTemplates,
  isRestore,
  setDropdown,
  isLoading,
}: any) {
  const { loading, error, data } = useQuery(GET_BRAND_TEMPLATE, {
    variables: { id: template.id },
  });

  const [active, setActive] = useState<any>(false);

  const [editEmailTemplate] = useMutation(EDIT_EMAIL_TEMPLATE);
  const client = useApolloClient();

  const onActive = async () => {
    setActive(!active);
    const res = await editEmailTemplate({
      variables: {
        id: template.id,
        data: {
          notification_active: !active,
        },
      },
    });
    if (res?.data?.update_brd_emails_templates?.affected_rows > 0) {
      toast.success("Update successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 100,
      });
    } else {
    }
  };

  useEffect(() => {
    if (data?.brd_emails_templates.length > 0) {
      setValue(
        "notification_content",
        data.brd_emails_templates[0].notification_content
      );
      setActive(template?.notification_active);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <div className="col-span-6 sm:col-full text-primary dark:text-dark-primary">
        <InputField
          register={register}
          errors={errors}
          fieldName={`notification_content`}
          type="text"
          inputClasses="shadow border-none"
          label="Content"
          defaultValue={data.brd_emails_templates[0].notification_content}
        />
      </div>

      <div className="col-span-6 sm:col-full mt-2 w-full flex justify-between pt-4">
        <div>
          <p className="text-[13px] pt-2">
            <b>Variables: </b>
            {template?.def_emails_template?.variables_allowed
              ?.split(",")
              .map((variable: any) => {
                return (
                  <span className="text-primary dark:text-dark-primary p-2 pl-0 font-">{`{{${variable}}}`}</span>
                );
              })}
          </p>
        </div>
        <div className="flex gap-4">
          {/* <div className="pt-2 w-[100px]">
            <div className="flex ">
              <div className="mb-4">
                <input
                  className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault01"
                  // value={active}
                  checked={active}
                  onClick={() => {
                    onActive();
                  }}
                />
              </div>
              <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 mt-[3px]">
                {active ? "Active" : "In Active"}
              </span>
            </div>
          </div> */}
          <Button
            title="Restore"
            onClick={() => {
              RestoreTemplates();
            }}
            variant="text"
          >
            <FaRecycle />{" "}
            <span className="text-[10px] ml-1">
              {isRestore ? "Restoring..." : "Restore"}
            </span>
          </Button>
          <Button
            onClick={() => {
              setDropdown(null);
            }}
            variant="outlined"
          >
            {isLoading ? "Updating.." : "Update"}
          </Button>
          <Button type="submit" variant="contained">
            Contained
          </Button>
        </div>
      </div>
    </>
  );
}
