import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { Popover } from "@mui/material";
import Reactions from "../Reactions";
// import MenuItems from "../TextMessage/component/MenuItems";
import ReplyMessage from "../../../ReplyMessage";
import { URLs } from "~/config/enums";
import MenuItems from "../TextMessage/component/MenuItems";
import DialogueBox from "../DialogueBox";

const DocMessage = ({
  messageType,
  message,
  type,
  setShowMessageInfoBar,
  setShowReplyMessage,
  scrollToMessage,
}: any) => {
  const [showAdditional, setShowAdditional] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showReaction, setShowReactions] = useState(false);
  const [reactionEmoji, setReactionEmoji] = useState("");

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div
        id={message?.id}
        className={`flex items-center w-full py-[2px] px-[60px] ${
          reactionEmoji.length > 0 && "mb-6"
        } ${type === "recieve" ? "justify-start" : "justify-end"}`}
        onMouseEnter={() => setShowAdditional(true)}
        onMouseLeave={() => setShowAdditional(false)}
      >
        <div className="md:max-w-[70%] relative flex items-center">
          <div
            className={`rounded-lg shadow p-1 ${
              messageType === "messageReply" && "px-1 pt-1"
            }  ${
              type === "recieve"
                ? "bg-whatsappSecondaryBg dark:bg-dark-primary"
                : "bg-whatsappMessagebg"
            }`}
          >
            {messageType === "messageReply" && (
              <ReplyMessage
                scrollToMessage={scrollToMessage}
                replyType="messageReply"
                message={message?.reply}
                type={type}
              />
            )}
            <div
              className={`px-[19px] py-[13px] flex items-center rounded-md ${
                type === "recieve" ? "bg-white/5" : "bg-black/5"
              } ${messageType === "messageReply" && "mt-1"}`}
            >
              <InsertDriveFileIcon
                fontSize="large"
                sx={{
                  color:
                    darkMode && type === "recieve" ? "#E7E3FCDE" : "#3A354199",
                }}
              />
              <div className="ml-1">
                <p
                  className={`text-[14px] ${
                    type === "recieve" && "dark:text-white"
                  }`}
                >
                  Attachment File
                </p>
                <p
                  className={`text-[11px] text-secondary ${
                    type === "recieve" && "dark:text-dark-primary"
                  }`}
                >
                  {/* JPG . 203 KB */}
                </p>
              </div>
              <div
                onClick={() => window.open(URLs.FILE_URL + message.text)}
                className={`border border-[#3A354199] rounded-full p-1 ml-10 cursor-pointer ${
                  type === "recieve" && "dark:border-dark-primary"
                }`}
              >
                <DownloadIcon
                  sx={{
                    color:
                      darkMode && type === "recieve"
                        ? "#E7E3FCDE"
                        : "#3A354199",
                  }}
                />
              </div>
            </div>
            {messageType !== "autoReply" && (
              <p
                className={`text-[11px] ml-1 mt-1 text-primary float-right  ${
                  type === "recieve"
                    ? "min-w-12 dark:text-dark-primary"
                    : "min-w-16"
                }`}
              >
                1:17 PM {type === "send" && `(${message?.sender?.displayName})`}{" "}
                {!(type === "recieve") && (
                  <DoneAllOutlinedIcon
                    sx={{ fontSize: "15px", color: "#009de2" }}
                  />
                )}
              </p>
            )}
          </div>
          {showReaction && (
            <Popover
              sx={{
                "& .MuiPopover-paper": {
                  backgroundColor: "transparent",
                  borderRadius: "30px",
                },
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              anchorEl={anchorEl}
              onClose={() => {
                setShowReactions(!showReaction);
              }}
              open={showReaction}
              anchorOrigin={{
                vertical: "center",
                horizontal: type === "recieve" ? "left" : "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: type === "recieve" ? "left" : "right",
              }}
            >
              <Reactions
                setShowReactions={setShowReactions}
                setReactionEmoji={setReactionEmoji}
              />
            </Popover>
          )}
          {reactionEmoji.length > 0 && (
            <div
              className="absolute right-[18px] bottom-[-20px] bg-whatsappSecondaryBg h-[25px] w-[28px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer text-sm shadow"
              onClick={() => {
                setReactionEmoji("");
              }}
            >
              {reactionEmoji}
            </div>
          )}
          {showAdditional && messageType !== "autoReply" && (
            <>
              <div
                className={`absolute  bg-black/30 h-[22px] w-[22px] rounded-full text-whatsappSecondaryBg flex items-center justify-center font-bold cursor-pointer ${
                  type === "recieve" ? " right-[-30px]" : "left-[-30px]"
                }`}
                onClick={(e: any) => {
                  setShowReactions(!showReaction);
                  setAnchorEl(e.currentTarget);
                }}
              >
                <EmojiEmotionsIcon sx={{ color: "white", fontSize: "16px" }} />
              </div>
              <div
                className={`absolute h-[22px] w-[22px] rounded-full  flex items-center justify-center font-bold cursor-pointer ${
                  type === "recieve"
                    ? messageType === "messageReply"
                      ? "top-1 right-1 text-gray-200 "
                      : "top-1 right-1  text-primary"
                    : messageType === "messageReply"
                    ? "top-1 right-1 text-gray-200 "
                    : "top-1 right-1 text-whatsappSecondaryBg"
                }`}
                onClick={(e: any) => {
                  setAnchorEl(e.currentTarget);
                  setShowMenu(!showMenu);
                }}
              >
                <KeyboardArrowDownIcon
                  sx={{ color: "gray", fontSize: "25px" }}
                />
              </div>
              <Popover
                sx={{
                  background: "transparent",
                }}
                anchorEl={anchorEl}
                onClose={() => {
                  setShowMenu(!showMenu);
                }}
                open={showMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItems
                  setShowMessageInfoBar={setShowMessageInfoBar}
                  setShowReplyMessage={setShowReplyMessage}
                  setShowReactions={setShowReactions}
                  setShowMenu={setShowMenu}
                  message={message}
                />
              </Popover>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DocMessage;
