import { gql } from "@apollo/client";

export const GET_DEF_CREDITS_PLAN = gql`
  query getDefCreditsPlan {
    def_credits_plan {
      id
      plan_id
      credit_type
      credits
      price
      user_id
      def_subscription_plan {
        plan_name
      }
    }
  }
`;

export const GET_DEF_CREDITS_PLAN_WITH_ID = gql`
  query getDefCreditsPlanWithId($planID: uuid!) {
    def_credits_plan(where: { plan_id: { _eq: $planID } }) {
      id
      plan_id
      credit_type
      credits
      price
      user_id
      def_subscription_plan {
        plan_name
      }
    }
  }
`;
