import { gql } from "@apollo/client";

export const GET_EMAILS = gql`
  query GetEmails(
    $refresh_token: String
    $code: String
    $th_id: String
    $page: String
    $pageSize: String
    $nextPageToken: String
    $sendEmail: Boolean
    $to: String
    $body: String
    $subject: String
    $historyId: String
    $getNewEmailsFlag: Boolean
    $aliasDisplayName: String
    $aliasEmail: String
    $from: String
    $createAlias: Boolean
    $getAlias: Boolean
    $alias: Boolean
    $replyFlag: Boolean
    $messageId: String
    $query: Boolean
    $queryData: String
  ) {
    getGmailMessages(
      arg1: {
        refresh_token: $refresh_token
        code: $code
        th_id: $th_id
        page: $page
        pageSize: $pageSize
        nextPageToken: $nextPageToken
        sendEmail: $sendEmail
        to: $to
        body: $body
        subject: $subject
        historyId: $historyId
        getNewEmailsFlag: $getNewEmailsFlag
        aliasDisplayName: $aliasDisplayName
        aliasEmail: $aliasEmail
        createAlias: $createAlias
        getAlias: $getAlias
        from: $from
        alias: $alias
        replyFlag: $replyFlag
        messageId: $messageId
        query: $query
        queryData: $queryData
      }
    ) {
      status
      nextPageToken
      emails {
        nextPageToken
        alias
        messages {
          historyId
          id
          internalDate
          labelIds
          sizeEstimate
          snippet
          threadId
          payload {
            filename
            partId
            body {
              data
              size
            }
            mimeType
            headers {
              name
              value
            }
            parts {
              body {
                data
                size
              }
              filename
              headers {
                name
                value
              }
              mimeType
              partId
            }
          }
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  query SendEmail(
    $refresh_token: String
    $code: String
    $th_id: String
    $page: String
    $pageSize: String
    $nextPageToken: String
    $sendEmail: Boolean
    $to: String
    $body: String
    $subject: String
    $historyId: String
    $getNewEmailsFlag: Boolean
    $aliasDisplayName: String
    $aliasEmail: String
    $from: String
    $createAlias: Boolean
    $getAlias: Boolean
    $alias: Boolean
    $replyFlag: Boolean
    $messageId: String
    $query: Boolean
    $queryData: String
  ) {
    getGmailMessages(
      arg1: {
        refresh_token: $refresh_token
        code: $code
        th_id: $th_id
        page: $page
        pageSize: $pageSize
        nextPageToken: $nextPageToken
        sendEmail: $sendEmail
        to: $to
        body: $body
        subject: $subject
        historyId: $historyId
        getNewEmailsFlag: $getNewEmailsFlag
        aliasDisplayName: $aliasDisplayName
        aliasEmail: $aliasEmail
        createAlias: $createAlias
        getAlias: $getAlias
        from: $from
        alias: $alias
        replyFlag: $replyFlag
        messageId: $messageId
        query: $query
        queryData: $queryData
      }
    ) {
      status
      nextPageToken
      emails {
        nextPageToken
        alias
        messages {
          historyId
          id
          internalDate
          labelIds
          sizeEstimate
          snippet
          threadId
          payload {
            filename
            partId
            body {
              data
              size
            }
            mimeType
            headers {
              name
              value
            }
            parts {
              body {
                data
                size
              }
              filename
              headers {
                name
                value
              }
              mimeType
              partId
            }
          }
        }
      }
    }
  }
`;

export const GET_TRAVEL_HOUSE_BY_ID = gql`
  query GetTravelHouse($id: uuid!) {
    thp_list(where: { id: { _eq: $id } }) {
      id
      email_refresh_token
    }
  }
`;
