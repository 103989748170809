import React from "react";
//Import Components
import InputField from "components/FormInputs/InputField";

export default function WeightDetail({ register, errors, leg }: any) {
  return (
    <div className="flex flex-row gap-4">
      {/* Hand Carry Field */}
      <InputField
        label="Hand Carry (KG)"
        register={register}
        type="number"
        errors={errors}
        placeHolder="Hand Carry (KG)"
        fieldName={`${leg}.handCary`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Luggage Field */}
      <InputField
        label="Luggage (KG)"
        register={register}
        type="number"
        errors={errors}
        placeHolder="Luggage (KG)"
        fieldName={`${leg}.luggage`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
    </div>
  );
}
