import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ASSIGNED_BRANDS } from "~/modules/DashboardModule/segments/DashboardStats/graphql/Query";
import { useEffect, useState } from "react";
import { GET_ALIASES } from "~/components/UserCard/components/UserAdd/graphql/Query";

export default function useGmailAliases() {
  const { travelHouse }: any = travelHouseStore();
  const [aliases, setAliases] = useState([]);
  const userId = useUserId();
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const client = useApolloClient();
  const { loading, data, error } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: {
      id: userId,
      thp_id: travelHouse?.id,
    },
  });

  const [brands, setBrands] = useState(null);
  const [brd_ids, setBrdIds] = useState(null);
  const [gmailRefreshTokens, setGmailRefreshTokens] = useState<any>([]);

  useEffect(() => {
    const brdRec: any = [];
    const brdIds: any = [];
    const refreshToken: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push({ id: brd?.brd_id, name: brd.brd_list.name });
          brdIds.push(brd?.brd_id);

          if (brd.brd_list.gmail_refresh_token) {
            refreshToken.push(brd.brd_list.gmail_refresh_token);
          }
        });
      } else {
        data?.thp_list?.[0].brd_lists?.map((brd: any) => {
          brdRec.push({ id: brd?.id, name: brd.name });
          brdIds.push(brd?.id);
          if (brd.gmail_refresh_token) {
            refreshToken.push(brd.gmail_refresh_token);
          }
        });
      }
      setBrands(brdRec);
      setBrdIds(brdIds);
      setGmailRefreshTokens(refreshToken);
    }
  }, [data]);

  const getAliasesList = async () => {
    const aliases: any = [];
    gmailRefreshTokens?.map(async (token: any) => {
      if (token) {
        const QueryResponse = await client.query({
          query: GET_ALIASES,
          variables: {
            refresh_token: token,
          },
        });
        QueryResponse.data.gmail_get_aliases.aliases.map(
          async (aliase: any) => {
            aliases.push(aliase);
          }
        );
      }
    });
    setAliases(aliases);
    setIsLoading(false);
  };

  useEffect(() => {
    if (gmailRefreshTokens.length > 0) {
      getAliasesList();
    }
  }, [gmailRefreshTokens]);

  return { loading, isLoading, brands, error, brd_ids, aliases };
}
