export const filterStatus = [
  { title: "Un Assign", value: "pending" },
  { title: "Assigned", value: "assigned" },
  { title: "Follow Up", value: "follow_up" },
  { title: "No Answer", value: "no_answer" },
  { title: "Not Interested", value: "not_interested" },
  { title: "Duplicate", value: "duplicate" },
  { title: "Fake Inquiry", value: "fake_inquiry" },
  { title: "In Process", value: "inprocess" },
  { title: "Completed", value: "completed" },
  { title: "Deleted", value: "deleted" },
  { title: "Closed", value: "closed" },
  { title: "Invoice Signed", value: "invoice_signed" },
  { title: "Awaiting for Sign", value: "awaiting_for_sign" },
  { title: "Refunded", value: "refunded" },
];
