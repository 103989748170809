import { gql } from "@apollo/client";

export const INSERT_BRD_PAGES_HEADINGS = gql`
  mutation MyMutation($data: [brd_pages_headings_insert_input!]!) {
    insert_brd_pages_headings(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_PAGES_HEADINGS = gql`
  mutation MyMutation2($id: uuid!) {
    delete_brd_pages_headings(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// Define the GraphQL mutation
export const UPDATE_HEADINGS = gql`
  mutation UpdateHeadings(
    $brd_id: uuid!
    $id: uuid!
    $heading: String!
    $pagename: String!
    $subheading: String!
    $sectionname: String!
  ) {
    update_brd_pages_headings(
      where: { brd_id: { _eq: $brd_id }, id: { _eq: $id } }
      _set: {
        heading: $heading
        page_name: $pagename
        section_name: $sectionname
        subheading: $subheading
      }
    ) {
      affected_rows
    }
  }
`;
