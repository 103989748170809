import React from "react";
import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import ManageTestimonials from "~/modules/BrandModule/segments/ManageTestimonials";
export default function EditTestimonials() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <ManageTestimonials />
        </BrandModule>
      </Layout>
    </div>
  );
}
