import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AirplaneTicketOutlinedIcon from "@mui/icons-material/AirplaneTicketOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";

import CampaignIcon from "@mui/icons-material/Campaign";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import EmailIcon from "@mui/icons-material/Email";

import CustomList from "../CustomList";

const TravelingSideBar = ({ role, activeDropdown, setActiveDropdown }: any) => {
  return (
    <>
      {role === "admin" ? (
        <div className="text-primary mt-[70px] z-30">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            heading="ADMIN"
            listItems={[
              {
                name: "Dashboard",
                icon: <DashboardOutlinedIcon />,
                link: "/admin",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Inquiries",
                icon: <DescriptionOutlinedIcon />,
                link: "/admin/inquiries",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Customers",
                icon: <GroupOutlinedIcon />,
                link: "/admin/customers",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Vendors",
                icon: <GroupOutlinedIcon />,
                link: "/admin/vendors",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Tickets Request",
                icon: <AirplaneTicketOutlinedIcon />,
                link: "/admin/ticket-requests",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            heading="ACCOUNTS"
            listItems={[
              {
                name: "Payments Request",
                icon: <RequestQuoteOutlinedIcon />,
                link: "/admin/customer-payments",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Payments Methods",
                icon: <PaymentOutlinedIcon />,
                link: "/admin/payment-methods",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Journal Transactions",
                icon: <AccountBalanceOutlinedIcon />,
                link: "/admin/transactions",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Accounts List",
                icon: <RecentActorsOutlinedIcon />,
                link: "/admin/accounts",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Process Payroll",
                icon: <PaidOutlinedIcon />,
                link: "/admin/process-payroll",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            heading="HR"
            listItems={[
              {
                name: "Mark Attendance",
                icon: <ScheduleOutlinedIcon />,
                link: "/admin/attendance",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            heading="MARKETING"
            listItems={[
              {
                name: "Create Posts",
                icon: <CampaignIcon />,
                link: "/admin/post-schedule",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Google Ads",
                icon: <AdsClickIcon />,
                link: "/admin/google-ads",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Google Campaigns",
                icon: <CampaignIcon />,
                link: "/admin/google-campaigns",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Email Campaigns",
                icon: <EmailIcon />,
                link: "/admin/email-campaigns",
              },
            ]}
          />
        </div>
      ) : role === "consultant" ? (
        <div className="text-primary mt-[70px] z-30">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            heading="CONSULTANT"
            listItems={[
              {
                name: "Dashboard",
                icon: <DashboardOutlinedIcon />,
                link: "/consultant",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Inquiries",
                icon: <DescriptionOutlinedIcon />,
                link: "/consultant/inquiries",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Customers",
                icon: <GroupOutlinedIcon />,
                link: "/consultant/customers",
              },
            ]}
          />
        </div>
      ) : (
        role === "super-admin" && (
          <div className="text-primary mt-[70px] z-30">
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              heading="ADMIN"
              listItems={[
                {
                  name: "Dashboard",
                  icon: <DashboardOutlinedIcon />,
                  link: "/super-admin",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Seasonal",
                  icon: <DashboardOutlinedIcon />,
                  link: "/super-admin/seasonal",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Email Templates",
                  icon: <DashboardOutlinedIcon />,
                  link: "/super-admin/email-templates",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Credits Purchases",
                  icon: <DashboardOutlinedIcon />,
                  link: "/super-admin/credits-purchases",
                },
              ]}
            />
          </div>
        )
      )}
    </>
  );
};

export default TravelingSideBar;
