import { Document, Page, View, Image, Text } from "@react-pdf/renderer";

import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";
import airportData from "~/components/FormInputs/LocationBox/data/airports.json";
import moment from "moment";
import luggage from "../images/luggage.png";
import handCarry from "../images/HandCarry.png";
import vector81 from "../../../components/images/Vector8.png";
import { getStyles } from "./styles";
import { splitLocation } from "~/utils/splitLocation";

const StaySuggestionPDF = ({ inquiry, logo, suggestions }: any) => {
  console.log("damnamdnas", suggestions);

  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  //where to
  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(suggestions[0]?.where_to);
  // hotel name
  const { cityName: hotelcity, remainingAddress: hotelAddres } = splitLocation(
    suggestions[0]?.hotel_name
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerFirstContainer}>
            {logo && <Image style={styles.logo} src={logo} />}

            <View style={styles.brandDetail}>
              <Text style={styles.brandName}>{inquiry?.brd_list?.name}</Text>
              <Text>www.{inquiry?.brd_list?.domain}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.email}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.phone}</Text>
            </View>
          </View>
          <View style={styles.headerSecondContainer}>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
            </Text>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
              {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
            </Text>
          </View>
        </View>
        <View style={styles.inquiryDetailContainer}>
          {/* {suggestions.map((suggestion: any, ind: any) => { */}
          {/* return ( */}
          <Text>STAY SUGGESTION</Text>
          <View>
            <View style={styles.flightDetailHeader}>
              <Text style={styles.flightTypeText}>Where TO:</Text>
              <View style={styles.flightCabinContainer}>
                <Text style={styles.flightCabinText}>Hotel Details</Text>
              </View>
            </View>
            <View style={styles.flightDetailBody}>
              {/* {suggestion?.inq_suggestion_leg_stops?.data?.map(
                      (legs: any, ind: any) => { */}
              {/* return ( */}
              <View style={styles.flightLegsParentContainer}>
                <View style={styles.flightLegs}>
                  <View style={styles.flightLegContainers}>
                    <View style={styles.flightLegSubContainers}>
                      <Text style={styles.flightLegArrDep}>{originCity}</Text>
                      {/* <Text>{"sadma,msdna"}</Text> */}
                    </View>
                    <View
                      style={[styles.flightLegSubContainers, { width: "100%" }]}
                    >
                      <Text style={styles.flightLegDateTime}>
                        {originAddress
                          .split("\n")
                          .map((line: any, index: any) => (
                            <Text key={index}>{line}</Text>
                          ))}
                      </Text>
                    </View>

                    <Text style={styles.flightLegDateTime}>
                      {moment(suggestions[0]?.start_date)
                        .tz("UTC")
                        .format("YYYY-MM-DD HH:mm")}

                      {/* {suggestions[0]?.end_date} */}
                    </Text>
                    <Text style={styles.flightLegDateTime}>
                      {moment(suggestions[0]?.end_date)
                        .tz("UTC")
                        .format("YYYY-MM-DD HH:mm")}
                    </Text>
                  </View>

                  <Image style={styles.dottedImg} src={vector81} />
                  <View style={styles.flightLegContainerRight}>
                    <View
                      style={[
                        styles.flightLegSubContainerRight,
                        { width: "100%" },
                      ]}
                    >
                      <Text style={styles.flightLegArrDep}>
                        {" "}
                        Name: {hotelcity}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.flightLegSubContainerRight,
                        { width: "100%" },
                      ]}
                    >
                      <Text style={styles.flightLegDateTime}>
                        {hotelAddres
                          .split("\n")
                          .map((line: any, index: any) => (
                            <Text key={index}>{line}</Text>
                          ))}
                      </Text>

                      {/* <Text>{"adba"}</Text> */}
                    </View>
                    <Text style={styles.flightLegDateTime}>
                      Room Type: {suggestions[0]?.room_type}{" "}
                    </Text>
                  </View>
                </View>
              </View>
              {/* ); */}
              {/* }
                    )} */}
              <View style={styles.priceLuggageContainer}>
                <View style={styles.luggageContainer}>
                  <View style={styles.luggageSubContainer}>
                    {/* <Image style={styles.luggageImg} src={luggage} /> */}
                    <Text style={styles.luggageText}>
                      {suggestions[0]?.car_type}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* );
          })} */}
          <View style={styles.priceLuggageContainer}>
            <View style={styles.luggageContainer}>
              <View style={styles.luggageSubContainer}>
                <Text style={styles.luggageText}>Total Price:</Text>
                <Text style={styles.luggageText}>
                  £
                  {suggestions[0]?.stay_inq_suggestion_costs?.data?.reduce(
                    (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
                    0
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StaySuggestionPDF;
