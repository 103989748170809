import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
  query MyQuery($brd_id: String!) {
    whatsapp_templates_get_all(arg1: { brd_id: $brd_id }) {
      data {
        name
        status
        category
        language
        components {
          example {
            header_handle
            body_text
            header_text
          }
          format
          text
          type
        }
        id
      }
    }
  }
`;
