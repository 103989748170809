import { TextField } from "@mui/material";
import { useState } from "react";
import { MdSend } from "react-icons/md";
import DotLoader from "~/components/common/DotLoader";

const Footer = ({
  handleSendEmail,
  isSending,
  register,
  handleSubmit,
  setCompose,
  data,
}: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <form
        onSubmit={handleSubmit(handleSendEmail)}
        className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[30px] py-[10px] flex flex-col justify-center text-primary dark:text-dark-primary z-10"
      >
        <h1
          className="text-[16px] font-bold my-1"
          onClick={() => {
            setCompose(true);
          }}
        >
          {data?.last_email_subject
            ? data?.last_email_subject
            : "Click on it add subject"}
        </h1>
        <div className="flex items-center">
          {/* <textarea
            className="w-full border rounded px-2 py-2"
            placeholder="Write Message ..."
            {...register("message")}
          ></textarea> */}
          <TextField
            placeholder="Write Message ..."
            fullWidth
            {...register("message")}
            multiline
            maxRows={4}
            sx={{
              "& fieldset": { border: "none" },
              "& .MuiOutlinedInput-root": {
                backgroundColor: darkMode ? "#312D4B" : "#FFFFFF",
                padding: "10px",
                borderRadius: "10px",
              },
            }}
          />
          {/* <input /> */}
          <button type="submit" className="text-[#3A3541DE] text-[30px] ml-5">
            {isSending ? <DotLoader /> : <MdSend />}
          </button>
        </div>
      </form>
    </>
  );
};

export default Footer;
