import { gql } from "@apollo/client";

export const GET_BRAND_EMAIL_TEMPLATES = gql`
  query GetBrandEamilTemplates($brd_id: uuid!) {
    brd_emails_templates(
      where: { brd_id: { _eq: $brd_id } }
      order_by: { def_emails_template: { sort: asc } }
    ) {
      id
      content
      subject
      template_id
      whatsapp_parameters
      whatsapp_template_name
      whatsapp_notifications
      def_emails_template {
        name
        variables_allowed
        whatsapp_template_name
        for_role
        sort
        is_activity
      }
      created_at
      updated_at
      notification_content
      notification_active
    }
  }
`;

export const GET_BRAND_TEMPLATE = gql`
  query GetBrandEamilTemplates($id: uuid!) {
    brd_emails_templates(where: { id: { _eq: $id } }) {
      id
      content
      subject
      template_id
      whatsapp_parameters
      whatsapp_template_name
      whatsapp_notifications
      def_emails_template {
        name
        variables_allowed
        whatsapp_template_name
        for_role
        sort
        is_activity
      }
      created_at
      updated_at
      notification_content
      email_active
      notification_active
    }
  }
`;
