import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { VERIFY_DOMAIN } from "../graphql/Query";
import AddBrand from "./components/AddBrand";
import { TextField } from "@mui/material";

const DomainVerificationBrand = ({ brand, adminUsers }: any) => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [isCopyTrue, setCopyIsTrue] = useState(false);
  const [isCopyTrue1, setCopyIsTrue1] = useState(false);
  function getDomainsWithWWW(domain: any) {
    const domainWithoutWWW = domain?.startsWith("www.")
      ? domain.substr(4)
      : domain;
    const domainWithWWW = domain?.startsWith("www.") ? domain : "www." + domain;

    return { domainWithoutWWW, domainWithWWW };
  }

  const { domainWithoutWWW, domainWithWWW } = getDomainsWithWWW(brand?.domain);

  const { loading, data, error, refetch } = useQuery(VERIFY_DOMAIN, {
    variables: { domain: domainWithoutWWW },
  });

  const { data: wData, refetch: wRefetch } = useQuery(VERIFY_DOMAIN, {
    variables: { domain: domainWithWWW },
  });
  // ;
  const handleRefresh = async () => {
    setRefreshLoading(true);
    await refetch();
    await wRefetch();
    setRefreshLoading(false);
  };

  const copyToClipboard = (text: any, setIsTrue: any) => {
    setIsTrue(true);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // The text has been copied to the clipboard
      })
      .catch((error: any) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  useEffect(() => {
    if (isCopyTrue) {
      const timer = setTimeout(() => {
        setCopyIsTrue(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
    if (isCopyTrue1) {
      const timer = setTimeout(() => {
        setCopyIsTrue1(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isCopyTrue, isCopyTrue1]);

  return (
    <>
      <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  sm:p-6 ">
        <h3 className="mb-4 text-xl font-semibold ">
          {brand?.domain === null
            ? "Add Whitlable Domain"
            : "Domain Verification"}
        </h3>
        {brand?.domain === null ? (
          <AddBrand brand={brand} adminUsers={adminUsers} />
        ) : (
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="domain_verified"
                className={`block mb-2 text-sm font-medium ${
                  wData?.verifyVercelDomain?.domain?.misconfigured === false
                    ? `text-green-700 dark:text-green-500`
                    : `text-red-700 dark:text-red-500`
                }`}
              >
                Brand Domain
              </label>

              {wData?.verifyVercelDomain?.domain?.misconfigured === false && (
                <>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-green-900 bg-green-500 border border-r-0 border-green-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-900 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                      >
                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="domain_verified"
                      disabled
                      className="bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-none rounded-r-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-green-100 dark:border-green-400"
                      placeholder={domainWithWWW}
                    />
                  </div>
                  <p className="mt-2 text-sm text-green-600 dark:text-green-500">
                    <span className="font-medium">Domain Verified!</span>{" "}
                    Successfully
                  </p>
                </>
              )}
              {wData?.verifyVercelDomain?.domain?.misconfigured === true && (
                <>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-red-900 bg-red-500 border border-r-0 border-red-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-red-900 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                      >
                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
                      </svg>
                    </span>
                    <TextField
                      error
                      fullWidth
                      value={domainWithoutWWW}
                      type="text"
                      InputProps={{
                        style: {
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                        },
                      }}
                    />
                  </div>
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className="font-medium">Domain Not Verified!</span>{" "}
                    Please Copy Below Given A record and CNAME and paste in Your
                    DNS settings
                  </p>
                  <div className="bg-primary dark:bg-dark-primary shadow p-4 rounded-lg">
                    <p className="text-sm mb-4">
                      Set the following record on your DNS provider to continue:
                    </p>
                    <div className="flex items-center space-x-4">
                      <div>
                        <h6 className="text-xs mb-2">Type</h6>
                        <pre className="bg-transparent p-2 text-xs">CNAME</pre>
                      </div>
                      <div>
                        <h6 className="text-xs mb-2">Name</h6>
                        <pre className="bg-transparent p-2 text-xs">www</pre>
                      </div>
                      <div className="flex-grow">
                        <h6 className="text-xs mb-2">Value</h6>
                        <pre className="bg-transparent p-2 text-xs w-full">
                          cname.vercel-dns.com
                        </pre>
                      </div>
                      <button
                        type="button"
                        aria-label="Copy to clipboard"
                        onClick={() =>
                          copyToClipboard("cname.vercel-dns.com", setCopyIsTrue)
                        }
                        className={`p-2 ${
                          isCopyTrue
                            ? " bg-[#125635] "
                            : "bg-secondary dark:bg-dark-secondary shadow "
                        }rounded-full`}
                      >
                        <svg
                          className="w-5 h-5 text-primary dark:text-dark-primary"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                        >
                          <path d="M6 17C4.89543 17 4 16.1046 4 15V5C4 3.89543 4.89543 3 6 3H13C13.7403 3 14.3866 3.4022 14.7324 4M11 21H18C19.1046 21 20 20.1046 20 19V9C20 7.89543 19.1046 7 18 7H11C9.89543 7 9 7.89543 9 9V19C9 20.1046 9.89543 21 11 21Z"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="mt-4 text-sm text-primary dark:text-dark-primary flex items-center space-x-2">
                      <span>
                        Depending on your provider, it might take some time for
                        the DNS records to apply.
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="domain_not_verified"
                className={`block mb-2 text-sm font-medium ${
                  wData?.verifyVercelDomain?.domain?.misconfigured === false
                    ? `text-green-700 dark:text-green-500`
                    : `text-red-700 dark:text-red-500`
                }`}
              >
                Brand Domain
              </label>
              {/* text-red-700 dark:text-red-500 */}

              {data?.verifyVercelDomain?.domain?.misconfigured === false && (
                <>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-green-900 bg-green-500 border border-r-0 border-green-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-900 transition duration-75 dark:text-dark-primary group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                      >
                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="domain_verified"
                      disabled
                      className="bg-primary dark:bg-dark-primary border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-none rounded-r-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:border-green-400"
                      placeholder={domainWithoutWWW}
                    />
                  </div>
                  <p className="mt-2 text-sm text-green-600 dark:text-green-500">
                    <span className="font-medium">Domain Verified!</span>{" "}
                    Successfully
                  </p>
                </>
              )}
              {data?.verifyVercelDomain?.domain?.misconfigured === true && (
                <>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 text-sm text-red-900 bg-red-500 border border-r-0 border-red-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-red-900 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                      >
                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
                      </svg>
                    </span>
                    <TextField
                      error
                      fullWidth
                      value={domainWithoutWWW}
                      type="text"
                      InputProps={{
                        style: {
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                        },
                      }}
                    />
                  </div>
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className="font-medium">Domain Not Verified!</span>{" "}
                    Please Copy Below Given A record and CNAME and paste in Your
                    DNS settings
                  </p>
                  <div className="bg-primary dark:bg-dark-primary shadow p-4 rounded-lg">
                    <p className="text-sm mb-4">
                      Set the following record on your DNS provider to continue:
                    </p>
                    <div className="flex items-center space-x-4">
                      <div>
                        <h6 className="text-xs mb-2">Type</h6>
                        <pre className="bg-transparent p-2 text-xs">A</pre>
                      </div>
                      <div>
                        <h6 className="text-xs mb-2">Name</h6>
                        <pre className="bg-transparent p-2 text-xs">@</pre>
                      </div>
                      <div className="flex-grow">
                        <h6 className="text-xs mb-2">Value</h6>
                        <pre className="bg-transparent p-2 text-xs w-full">
                          76.76.21.21
                        </pre>
                      </div>
                      <button
                        type="button"
                        aria-label="Copy to clipboard"
                        onClick={() =>
                          copyToClipboard("76.76.21.21", setCopyIsTrue1)
                        }
                        className={`p-2 ${
                          isCopyTrue1
                            ? "bg-[#125635] shadow"
                            : "bg-secondary shadow dark:bg-dark-secondary"
                        } rounded-full`}
                      >
                        <svg
                          className="w-5 h-5 text-secondary dark:text-dark-secondary"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                        >
                          <path d="M6 17C4.89543 17 4 16.1046 4 15V5C4 3.89543 4.89543 3 6 3H13C13.7403 3 14.3866 3.4022 14.7324 4M11 21H18C19.1046 21 20 20.1046 20 19V9C20 7.89543 19.1046 7 18 7H11C9.89543 7 9 7.89543 9 9V19C9 20.1046 9.89543 21 11 21Z"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="mt-4 text-sm text-primary dark:text-dark-primary flex items-center space-x-2">
                      <span>
                        Depending on your provider, it might take some time for
                        the DNS records to apply.
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            {(data?.verifyVercelDomain?.domain?.misconfigured === true ||
              wData?.verifyVercelDomain?.domain?.misconfigured === true) && (
              <div className="col-span-6 sm:col-full">
                <button
                  className="text-white bg-basic cursor-pointer focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  onClick={handleRefresh}
                >
                  {refreshLoading ? "Refreshing..." : "Refresh"}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DomainVerificationBrand;
