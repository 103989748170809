import { Button, TextField, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_UNIVERSITY_COURSE_WITH_ID } from "~/graphql/brd_uni_courses/Mutation";


const EditCourse = ({ selectedCourse, setSidePopup }: any) => {

    const { register, handleSubmit }: any = useForm<FormData>();
    const [editUniCourse] = useMutation(UPDATE_UNIVERSITY_COURSE_WITH_ID)
    const client = useApolloClient();

    const onSubmit = async (formData: any) => {
        const res = await editUniCourse({
          variables: {
            courseId: selectedCourse.id,
            data: {
              name: formData.courseName,
              course_type: formData.course_type,
              tuition_fees: formData.fees,
              duration: formData.duration,
              intake: formData.intake,
            },
          },
        });
        if (res?.data?.update_brd_university_courses?.affected_rows > 0) {
          toast.success("University Course Updated Successfully!");
          client.refetchQueries({
            include: "all",
          });
          setSidePopup(false);
        }
      };

    return (
        <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
            <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
                Edit University Course
            </h1>
            
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Course Name"
                    variant="outlined"
                    fullWidth
                    required
                      {...register("courseName")}
                    defaultValue={selectedCourse?.name}
                    style={{ marginBottom: "16px" }}
                />
                <FormControl variant="outlined" fullWidth required style={{ marginBottom: "20px" }}>
                    <InputLabel id="courseType-label">Grade Type</InputLabel>
                    <Select
                        labelId="courseType-label"
                        label="CourseType"
                        defaultValue={selectedCourse?.course_type}
                    {...register("course_type")}
                    >
                        <MenuItem value="UG">UG</MenuItem>
                        <MenuItem value="PG">PG</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Tuition Fees"
                    variant="outlined"
                    fullWidth
                    required
                      {...register("fees")}
                    defaultValue={selectedCourse?.tuition_fees}
                    style={{ marginBottom: "16px" }}
                />
                <TextField
                    label="Duration"
                    variant="outlined"
                    fullWidth
                    required
                    style={{
                        marginBottom: "16px",
                    }}
                      {...register("duration")}
                    defaultValue={selectedCourse?.duration}
                />
                <TextField
                    label="Intake"
                    variant="outlined"
                    fullWidth
                    required
                    style={{
                        marginBottom: "16px",
                    }}
                      {...register("intake")}
                    defaultValue={selectedCourse?.intake}
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Update
                </Button>
            </form>
        </div>
    )
}

export default EditCourse