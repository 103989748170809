import { useWavesurfer } from "@wavesurfer/react";
import React, { useEffect, useRef } from "react";
import { URLs } from "~/config/enums";

export default function RecordedMessage({
  fileId,
  onPlay,
  onPlayPause,
  containerRef,
}: any) {
  // const containerRef = useRef(null);

  // const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
  //   container: containerRef,
  //   url: URLs.FILE_URL + fileId,
  //   waveColor: "darkgray",
  //   progressColor: "darkgray",
  //   height: 40,
  // });

  // const onPlayPause = () => {
  //   wavesurfer && wavesurfer.playPause();
  // };

  useEffect(() => {
    if (onPlay) {
      onPlayPause();
    }
  }, [onPlay]);

  return <div className="w-[150px] " id="waveform" ref={containerRef}></div>;
}
