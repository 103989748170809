import { gql } from "@apollo/client";

export const UPDATE_BRAND_DETAIL = gql`
  mutation updateBrandDetail(
    $id: brd_detail_pk_columns_input!
    $brdData: brd_detail_set_input!
  ) {
    update_brd_detail_by_pk(pk_columns: $id, _set: $brdData) {
      id
    }
  }
`;
