import { gql } from "@apollo/client";

export const SEND_GRID_DOMAINS = gql`
  query GetSendGridDomains($domain: Int) {
    sendGridDomainsVerificationCheck(arg1: { domain: $domain }) {
      dns {
        dkim1 {
          data
          host
          reason
          type
          valid
        }
        dkim2 {
          data
          host
          reason
          type
          valid
        }
        mail_cname {
          data
          host
          reason
          type
          valid
        }
      }
      domain
      id
      valid
    }
  }
`;
