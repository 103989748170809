import { gql } from "@apollo/client";

export const INSERT_COMMUNITY_MESSAGE = gql`
  mutation MyMutation($data: [brd_community_messages_insert_input!]!) {
    insert_brd_community_messages(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
