import { gql } from "@apollo/client";

export const GET_FLIGHT = gql`
  query GetBrdFlightsPages {
    brd_flights_pages(
      distinct_on: destination_iata
      where: {
        _and: { brd_id: { _neq: "39d77887-9784-4dbd-9674-57f9586b3156" } }
        featured_destination_image: { _is_null: true }
      }
    ) {
      origin_iata
      destination_iata
      slug
      destination_name
      origin_name
      featured_destination_image
    }
  }
`;

export const GET_INQUIRY_COMMENT = gql`
  query GetInquiries {
    inq_list {
      id
      inq_comments(order_by: { created_at: desc }, limit: 1) {
        thp_inquiry_flag {
          id
        }
      }
    }
  }
`;

export const GET_INQUIRY_LIST = gql`
  query GetInquiries {
    inq_list(
      where: { brd_id: { _eq: "43d895e7-d090-41c2-a261-508642f43d47" } }
    ) {
      id
      user_id
      to
      brd_id
    }
  }
`;

export const GET_FLIGHT_PAGE = gql`
  query GetBrdFlightsPages {
    brd_flights_pages {
      id
      destination_iata
    }
  }
`;

export const GET_WHATSAPP_COMMUNITY = gql`
  query getCommunity($name: String!) {
    brd_whatsapp_communities(where: { name: { _eq: $name } }) {
      id
    }
  }
`;
