import SidePopup from "~/components/common/SidePopup";
import AddAdmissionRequirement from "./components/AddAdmissionRequirement";
import AdmissionRequirementData from "./components/AdmissionRequirementData";
import { GET_CAMPUS_COURSE_ADM_REQ } from "~/graphql/course_adm_req/Query";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const AdmissionRequirements = () => {

  const { campus_course_id } = useParams();

  const { data } = useQuery(GET_CAMPUS_COURSE_ADM_REQ, {
    variables: { campusCourseId: campus_course_id },
  });
  
  return (
    <>
      <AdmissionRequirementData campusCoursesData={data} courseType={data?.uni_campus_courses?.[0]?.course_detail?.course_type} />
      <SidePopup
        component={<AddAdmissionRequirement campusCourseId={campus_course_id} courseType={data?.uni_campus_courses?.[0]?.course_detail?.course_type} />}
      />
    </>
  );
};

export default AdmissionRequirements;
