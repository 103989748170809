import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import InputField from "~/components/FormInputs/InputField";
import ContentEditor from "~/components/common/ContentEditor";
import { GET_BRAND_TEMPLATE } from "../../../../graphql/Query";

export default function TemplateInputs({
  register,
  errors,
  template,
  setValue,
}: any) {
  const { loading, error, data } = useQuery(GET_BRAND_TEMPLATE, {
    variables: { id: template.id },
  });

  useEffect(() => {
    if (data?.brd_emails_templates.length > 0) {
      setValue(
        "whatsapp_parameters",
        data.brd_emails_templates[0].whatsapp_parameters
      );
      setValue(
        "whatsapp_template_name",
        data.brd_emails_templates[0].whatsapp_template_name
      );
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <div className="col-span-6 sm:col-full">
        <InputField
          register={register}
          errors={errors}
          fieldName="whatsapp_parameters"
          type="text"
          inputClasses="shadow border-none"
          label="Whatsapp Parameters"
          placeHolder={template?.def_emails_template?.variables_allowed}
          defaultValue={template.whatsapp_parameters}
        />
      </div>
      <div className="col-span-6 sm:col-full mt-2">
        <InputField
          register={register}
          errors={errors}
          fieldName="whatsapp_template_name"
          type="text"
          inputClasses="shadow border-none"
          label="Whatsapp Template Name"
          placeHolder={template?.def_emails_template?.whatsapp_template_name}
          defaultValue={template.whatsapp_template_name}
        />
      </div>
    </>
  );
}
