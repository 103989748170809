import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useState } from "react";

import useAssigedBrands from "~/hooks/useAssigedBrands";
import AutoReplyMessage from "./components/AutoReplyMessage";

const AutoReply = ({ showAutoReplyBar, setShowAutoReplyBar, type }: any) => {
  const [brandID, setBrandId] = useState<any>("");

  const { brands }: any = useAssigedBrands();

  return (
    <>
      <div
        className={`absolute top-0 w-full z-10 bg-white dark:bg-dark-secondary transition-all ease-in-out duration-300 h-full flex flex-col ${
          showAutoReplyBar ? "left-0" : "left-[-800px]"
        }`}
      >
        <div
          className={` px-[23px] text-white text-[19px] pt-[60px] ${
            type === "email" ? "bg-[#9155FD]" : "bg-whatsappBasic"
          }`}
        >
          <div className="flex items-center mb-5">
            <ArrowBackOutlinedIcon
              onClick={() => {
                setShowAutoReplyBar(false);
              }}
              sx={{ cursor: "pointer" }}
            />
            <p className="ml-5">Auto Reply</p>
          </div>
        </div>
        <div className="p-[20px] w-full">
          <Autocomplete
            disablePortal
            onChange={(_, data: any) => {
              setBrandId(data?.id);
            }}
            options={brands ? brands : []}
            getOptionLabel={(option: any) => option?.name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option?.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField label="Brand" {...params} color="success" />
            )}
            noOptionsText=""
          />
        </div>
        {brandID && <AutoReplyMessage brandID={brandID} />}
      </div>
    </>
  );
};

export default AutoReply;
