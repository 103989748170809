import { gql } from "@apollo/client";

export const GET_INQ_FLAGS = gql`
  query MyQuery($id: uuid!) {
    thp_inquiry_flags(
      where: { _or: [{ thp_id: { _eq: $id } }, { thp_id: { _is_null: true } }] }
    ) {
      id
      name
      thp_id
      color
    }
  }
`;

export const GET_CUSTOMER = gql`
  query CustomerQuery($condition: users_bool_exp!) {
    users(where: $condition) {
      id
      email
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;

export const GET_CONSULTANTS = gql`
  query CustomerQuery($thp_id: uuid!) {
    users(
      where: {
        _or: [
          { thp_list: { id: { _eq: $thp_id } } }
          {
            brd_users: {
              thp_id: { _eq: $thp_id }
              users: { defaultRole: { _neq: "user" } }
            }
          }
        ]
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      thp_list {
        id
      }
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
      thp_users_profile {
        x
        instagram
        facebook
        linkedin
        id
        skype
        basic_salary
        company_phone
        discord
        commission
        pseudo_name
        youtube
        bio
      }
    }
  }
`;

export const GET_INQUIRIES_SOURCES = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(where: { brd_id: { _in: $brd_ids } }, distinct_on: source) {
      source
    }
  }
`;

export const GET_INQUIRIES_SOURCE_PLACE = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(where: { brd_id: { _in: $brd_ids } }, distinct_on: source_place) {
      source_place
    }
  }
`;

export const GET_INQUIRIES_SOURCE_POSITION = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(
      where: { brd_id: { _in: $brd_ids } }
      distinct_on: source_position
    ) {
      source_position
    }
  }
`;

export const GET_INQUIRIES_SOURCE_KEYWORD = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(
      where: { brd_id: { _in: $brd_ids } }
      distinct_on: source_keyword
    ) {
      source_keyword
    }
  }
`;

export const GET_INQUIRIES_SOURCE_AD_ID = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(where: { brd_id: { _in: $brd_ids } }, distinct_on: source_add_id) {
      source_add_id
      acc_google_ad {
        name
        ad_id
      }
    }
  }
`;

export const GET_INQUIRIES_ORIGIN = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(where: { brd_id: { _in: $brd_ids } }, distinct_on: from) {
      from
    }
  }
`;

export const GET_INQUIRIES_DESTINATION = gql`
  query GetInquiries($brd_ids: [uuid!]!) {
    inq_list(where: { brd_id: { _in: $brd_ids } }, distinct_on: to) {
      to
    }
  }
`;
