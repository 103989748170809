import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import MangePages from "~/modules/BrandModule/segments/ManagePages";
export default function PageSettings() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <MangePages />
        </BrandModule>
      </Layout>
    </div>
  );
}
