import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { EDIT_ACCOUNT } from "./graphql/Mutation";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useRefetch from "~/hooks/useRealtime";

export default function EditAccount({ setHideForm, account }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [editAccount] = useMutation(EDIT_ACCOUNT);
  const { travelHouse }: any = travelHouseStore();
  const refetch = useRefetch();

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();

  //   const onSubmit = async (formData: any) => {};

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    try {
      const res = await editAccount({
        variables: {
          id: account.id,
          name: formData?.name,
        },
      });
      await refetch();
      toast.success("Account update successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      setHideForm(false);
    } catch (e) {
      setIsLoading(false);
      reset();
      console.error("error", e);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold">Add Account</h2>

      <div className="mt-2 mb-2">
        <InputField
          register={register}
          errors={errors}
          type="text"
          fieldName="name"
          required={true}
          label="Name"
          placeHolder="Enter Name"
          defaultValue={account.name}
        />
      </div>
      <div className="flex gap-2 w-full">
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          {isLoading ? "Saving.." : "Save"}
        </button>
      </div>
    </form>
  );
}
