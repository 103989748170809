import { gql } from "@apollo/client";

export const RESTORE_EMAIL_TEMPLATE = gql`
  mutation RestoreEmailTemplates(
    $templates: [brd_emails_templates_insert_input!]!
  ) {
    insert_brd_emails_templates(objects: $templates) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
