import { gql } from "@apollo/client";

export const INSERT_CASESTUDY_PAGE = gql`
  mutation InsertCaseStudyPage($data: serv_main_casestudy_page_insert_input!) {
    insert_serv_main_casestudy_page(objects: [$data]) {
      affected_rows
    }
  }
`;

export const UPDATE_CASESTUDY_PAGE = gql`
  mutation MyMutation(
    $brd_id: uuid!
    $data: serv_main_casestudy_page_set_input!
  ) {
    update_serv_main_casestudy_page(
      where: { brd_id: { _eq: $brd_id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;
