import { useState } from "react";
import SingleFile from "./componets/SingleFile";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

export default function Files({ setFilesId }: any) {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const uploadFiles = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      let file = files?.[i];
      file["id"] = generateRandom10DigitCode();
      setFiles((prev: any) => [...prev, file]);
    }
  };

  const handleLogoUpload = async (event: any) => {
    const files = event.target.files;
    uploadFiles(files);
  };

  const removeFile = (filesToRemove: any) => {
    const updatedFiles = files.filter(
      (file: any) => filesToRemove?.id !== file?.id
    );

    setFiles(updatedFiles);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      uploadFiles(files);
      const selectedFile = files[0];
      // handleFileUpload(selectedFile);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };
  return (
    <>
      <div className="">
        <input
          style={{ opacity: 0, position: "absolute" }}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-[#ccc] dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="hero_image_help"
          id="uploadFile"
          type="file"
          multiple
          onChange={handleLogoUpload}
        />
        <label
          className={`h-[100px] bg-[#f7f7f7] rounded-md border-2 border-dotted flex flex-col justify-center items-center w-full block ${
            isDragging ? "border-blue-300 " : " border-gray-100 "
          } hover:border-blue-300 `}
          htmlFor="uploadFile"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <span className="w-[300px] text-center text-gray-400 text-[12px]">
            Click or drag and drop multiple files here.
          </span>
        </label>
        {files.map((file: any, index: any) => {
          return (
            <SingleFile
              removeFile={removeFile}
              file={file}
              key={index}
              setFilesId={setFilesId}
            />
          );
        })}
      </div>
    </>
  );
}
