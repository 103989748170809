import React, { useState, useEffect } from "react";

const DotLoader = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return ".";
        } else {
          return prevDots + ".";
        }
      });
    }, 500); // Adjust the interval duration (in milliseconds) as needed

    return () => clearInterval(interval);
  }, []);

  return <div className="text-[20px] mt-[-9px]">{dots}</div>;
};

export default DotLoader;
