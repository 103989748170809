import { gql } from "@apollo/client";

export const GET_BRANDS = gql`
  query GetUserBrand($thp_id: uuid) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      id
      name
    }
  }
`;
