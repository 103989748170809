import { useMutation } from "@apollo/client";
import { Divider, Box, TextField, Autocomplete, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AirlineBox from "~/components/FormInputs/AirlineBox/AirlineBox";
import LocationBox from "~/components/FormInputs/LocationBox/LocationBox";
import useBrdSeasons from "~/hooks/useBrdSeasons";
import { ADD_BRD_SEAS_FARES } from "~/graphql/brd_seasonal_fares/Mutation";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const AddSeasFares = ({ setOpenMod, refetchFares }: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { brd_id } = useParams();

  const { brdSeasons } = useBrdSeasons(brd_id);

  const [addDefSeasFares] = useMutation(ADD_BRD_SEAS_FARES);

  const onSubmit = async (formData: any) => {
    const payload = {
      brd_season_id: formData?.season,
      destination: formData?.destination,
      departure: formData?.departure,
      fares: formData?.fares,
      airline: formData?.airline,
      type: formData?.type,
      stops: formData?.stops,
      brd_id,
    };
    const res = await addDefSeasFares({
      variables: {
        data: payload,
      },
    });

    if (res?.data?.insert_brd_seasonal_fares?.affected_rows) {
      toast.success("Seasonal Fares Added SuccessFully");
      setOpenMod(false);
      refetchFares();
    }
  };

  return (
    <div className="">
      <h1 className="text-center text-3xl text-basic">Add Seasonal Fares</h1>
      <Divider />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <div className="md:col-span-2">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("season", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.int_id);
                  }}
                  options={brdSeasons ? brdSeasons : []}
                  getOptionLabel={(option: any) => option.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Season"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["season"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("type", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.value);
                }}
                options={[
                  { value: "oneWay", name: "One Way" },
                  { value: "roundTrip", name: "Round Trip" },
                ]}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Type"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["type"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <LocationBox
            control={control}
            register={register}
            errors={errors}
            fieldName="destination"
            label="Destination"
          />
          <LocationBox
            control={control}
            register={register}
            errors={errors}
            fieldName="departure"
            label="Departure"
          />
          <AirlineBox
            control={control}
            register={register}
            errors={errors}
            fieldName="airline"
            label="Airline"
          />
          <TextField
            error={errors["fares"] ? true : false}
            {...register("fares", { required: true })}
            label="Fares"
            type="number"
          />
          <TextField
            error={errors["stops"] ? true : false}
            {...register("stops", { required: true })}
            label="Stops"
            type="number"
          />
        </div>
        <div className="flex items-center justify-center mt-10">
          <Button type="submit" variant="contained">
            Add Seaonal Fares
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default AddSeasFares;
