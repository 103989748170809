import { gql } from "@apollo/client";

export const GET_RECENT_MESSAGE = gql`
  subscription MySubscription($brd_phone: String!, $user_phone: String!) {
    brd_whatsapp_chat(
      order_by: { timestamp: desc }
      where: {
        brand_phone: { _eq: $brd_phone }
        user_phone: { _eq: $user_phone }
      }
      limit: 1
    ) {
      text
      user_name
      user_phone
      timestamp
    }
  }
`;
