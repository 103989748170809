import { gql } from "@apollo/client";

export const UPDATE_FLIGHT_PAGE_CONTENT = gql`
  mutation UpdateFlightPageContent(
    $id: brd_flights_pages_pk_columns_input!
    $data: brd_flights_pages_set_input!
  ) {
    update_brd_flights_pages_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;
