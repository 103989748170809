import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Divider, FormLabel, TextField } from "@mui/material";

const BrandSettings = ({
  mainHeading,
  errors,
  register,
  handleSubmit,
  updateSettings,
  defHeading,
  defSubHeading,
}: any) => {
  const onSubmit = async (inpData: any) => {
    updateSettings(inpData);
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{mainHeading}</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <TextField
              {...register("heading", { required: true })}
              error={errors["heading"] ? true : false}
              label="Title"
              type="text"
              defaultValue={defHeading}
            />
            <div>
              <FormLabel>Description</FormLabel>
              <textarea
                {...register("subHeading", { required: true })}
                className={`${
                  errors["subHeading"]
                    ? "border-red-600 bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-40"
                    : "bg-transparent rounded w-full border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-40"
                }`}
                defaultValue={defSubHeading}
              />
            </div>
          </div>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ marginTop: "20px" }}
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandSettings;
