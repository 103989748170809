import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useState } from "react";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";

const Audio = () => {
  const [showPauseIcon, setShowPauseIcon] = useState(false);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary px-[13px] shadow rounded-full py-[7px] flex  items-center gap-3">
      {showPauseIcon ? (
        <PauseOutlinedIcon
          sx={{
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            cursor: "pointer",
            fontSize: "30px",
          }}
          onClick={() => setShowPauseIcon(false)}
        />
      ) : (
        <PlayArrowIcon
          sx={{
            color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
            cursor: "pointer",
            fontSize: "30px",
          }}
          onClick={() => setShowPauseIcon(true)}
        />
      )}

      <div className="flex items-center">
        <div className="w-3 h-3 bg-whatsappBasic rounded-full"></div>
        <div className="w-[150px] border-[#8696a0] border-t-2 border-dotted"></div>
      </div>

      <h1 className="text-primary text-[20px] font-medium dark:text-dark-primary">
        1:15
      </h1>
    </div>
  );
};

export default Audio;
