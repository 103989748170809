import SearchBar from "./components/SearchBar";
import ChatRow from "./components/ChatRow";
import Header from "./components/Header";

const WhatsappSideBar = ({
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
}: any) => {
  return (
    <div className="border dark:border-dark-secondary md:max-w-[460px] w-full relative h-full flex flex-col">
      <div>
        <Header
          setShowWhatsappChatBox={setShowWhatsappChatBox}
          setShowWhatsappSidebar={setShowWhatsappSidebar}
        />
        <SearchBar />
      </div>
      <div className="text-primary dark:text-dark-primary overflow-scroll flex-grow">
        <ChatRow
          setShowWhatsappChatBox={setShowWhatsappChatBox}
          setShowWhatsappSidebar={setShowWhatsappSidebar}
        />
      </div>
    </div>
  );
};

export default WhatsappSideBar;
