import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useState } from "react";

const ReplyMessage = ({ replyType, message, type }: any) => {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <>
      <div
        className={`bg-black/5 rounded-lg flex items-center w-full ${
          type === "recieve" && "dark:bg-white/5"
        }`}
      >
        <div
          className={`bg-[#06cf9c]  w-[5px] rounded-tl-lg rounded-bl-lg ${
            replyType === "messageReply" ? "h-[50px]" : "h-[80px]"
          }`}
        ></div>
        <div
          className={`px-[12px]  ${
            replyType === "messageReply" ? "my-0" : "my-5"
          }`}
        >
          <h1
            className={`text-[13px]  ${
              type === "recieve" ? "text-[#06cf9c]" : "text-[#009de2]"
            }`}
          >
            {type === "recieve" ? "You" : "Sender name"}
          </h1>
          <p
            className={`text-[12px] text-primary flex items-center ${
              type === "recieve" && "dark:text-dark-primary"
            }`}
          >
            {message === "textMessage" &&
              "Lorem ipsum dolor sit amet consectetur"}
            {message === "voiceMessage" && (
              <>
                <MicOutlinedIcon sx={{ fontSize: "17px", color: "#009de2" }} />
                0.12
              </>
            )}
            {message === "photoMessage" && (
              <>
                <CameraAltIcon
                  sx={{
                    fontSize: "17px",
                    marginRight: "3px",
                    color:
                      type === "recieve" && darkMode
                        ? "#E7E3FCDE"
                        : "#3A354199",
                  }}
                />
                Photo
              </>
            )}
            {message === "videoMessage" && (
              <>
                <VideocamIcon
                  sx={{
                    fontSize: "17px",
                    marginRight: "3px",
                    color:
                      type === "recieve" && darkMode
                        ? "#E7E3FCDE"
                        : "#3A354199",
                  }}
                />
                Video
              </>
            )}
            {message === "documentMessage" && (
              <>
                <TextSnippetIcon
                  sx={{
                    fontSize: "17px",
                    marginRight: "3px",
                    color:
                      type === "recieve" && darkMode
                        ? "#E7E3FCDE"
                        : "#3A354199",
                  }}
                />
                Document-name.jpg
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReplyMessage;
