import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_BRAND_PAYMENT_METHODS,
  GET_TH_PAYMENT_METHODS,
} from "~/graphql/acc_payment_methods/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

export default function useBrandPaymentMethod(brd_id: any) {
  const { travelHouse }: any = travelHouseStore();

  const { data } = useQuery(GET_BRAND_PAYMENT_METHODS, {
    variables: {
      brdId: brd_id,
    },
    skip: !brd_id,
  });

  const { data: thData } = useQuery(GET_TH_PAYMENT_METHODS, {
    variables: {
      thpId: travelHouse?.id,
    },
  });

  return {
    paymentMethod: data?.acc_payment_methods,
    thPaymentMethods: thData?.acc_payment_methods,
  };
}
