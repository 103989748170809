import { gql } from "@apollo/client";

export const GET_TIKTOK_USER_INFO = gql`
  query GetTiktokUserInfo($access_token: String!) {
    get_tiktok_userInfo(arg1: { access_token: $access_token }) {
      data {
        user {
          display_name
          avatar_url
          open_id
          union_id
        }
      }
    }
  }
`;
