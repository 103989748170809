import { useState } from "react";
import LeadsFilter from "./components/LeadsFilter";
import AllLeads from "./components/AllLeads";
import dayjs from "dayjs";

const ServicesLeads = () => {
  const today = dayjs();

  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];

  const [selectedLeadNo, setSelectedLeadNo] = useState<any>();
  const [selectedDates, setSelectedDates] = useState<any>(currentMonth);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedService, setSelectedService] = useState<any>();

  return (
    <div>
      <LeadsFilter
        selectedLeadNo={selectedLeadNo}
        setSelectedLeadNo={setSelectedLeadNo}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        setSelectedCustomer={setSelectedCustomer}
        setSelectedService={setSelectedService}
      />
      <AllLeads
        selectedLeadNo={selectedLeadNo}
        selectedDates={selectedDates}
        selectedCustomer={selectedCustomer}
        selectedService={selectedService}
      />
    </div>
  );
};

export default ServicesLeads;
