import React from "react";
// import Image from "next/image";
// Define Interface
interface PropsType {
  src: any;
  alt: any;
  className: any;
  title: any;
  onClick: any;
  isDark: boolean;
}

const AirlineLogoImage = (props: PropsType) => {
  // Import Props
  const { src, alt, className, title, onClick } = props;

  // const [image, setImage] = useState("");
  // ;

  let image = "";
  try {
    image = require(`./images/${src}_i.svg`);
  } catch (e) {
    image = require(`./images/fallback.svg`).default.src;
  }

  return (
    // Image Tag
    <img
      className={className}
      src={image}
      alt={alt}
      title={title}
      onClick={() => onClick()}
      width={40}
      height={40}
      sizes="40px"
    />
  );
};

// Default Props
AirlineLogoImage.defaultProps = {
  alt: "image",
  title: "image",
  className: "",
  onClick: () => {},
  isDark: false,
};

export default AirlineLogoImage;
