import { gql } from "@apollo/client";

export const GET_ACC_TRANS = gql`
  query getAccTrans($brdIds: [uuid!]!) {
    acc_transactions(where: { brd_id: { _in: $brdIds } }) {
      transactions_no
    }
  }
`;

export const GET_ACC_TRANS_WITH_INQ_NO = gql`
  query getAccTrans($inq_id: [uuid!]!) {
    acc_transactions(where: { inq_id: { _in: $inq_id } }) {
      type
      def_acc_list {
        type
      }
      amount
    }
  }
`;
