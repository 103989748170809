import React from "react";
import Layout from "~/components/common/Layout";
import AccountModule from "~/modules/AccountModule";
import GeneratePayroll from "~/modules/AccountModule/segments/GeneratePayroll";
import Payroll from "~/modules/AccountModule/segments/Payroll";

export default function GeneratePayrollPage() {
  return (
    <div>
      <Layout>
        <AccountModule>
          <Payroll />
        </AccountModule>
      </Layout>
    </div>
  );
}
