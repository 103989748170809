import { gql } from "@apollo/client";

export const GET_CASE_STUDIES_BY_BRD_ID = gql`
  query MyQuery($brd_id: uuid!) {
    services_casestudy(where: { brd_id: { _eq: $brd_id } }) {
      image_id
      title
      slug
      id
      description
      brd_services_id
      brd_id
    }
  }
`;
export const GET_CASE_STUDY = gql`
  query getServicesCaseStudyWithId($caseStudyId: uuid!) {
    services_casestudy(where: { id: { _eq: $caseStudyId } }) {
      id
      title
      title_color
      bg_color
      slug
      image_id
      description
      brd_services_id
      detailed_description
      service {
        title
      }
    }
  }
`;
